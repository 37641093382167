import React, { useRef, useState } from "react";
import About from "./About";
import home from "../home.module.scss";
import Parallax from "parallax-js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./home.css";

function Home() {
  const homeContainer = useRef(null);
  const [state, setState] = useState({
    x: 0,
    y: 0,
  });
  function mouseIn(e) {
    // console.log("hello");
    const width = homeContainer.current.clientWidth;
    const height = homeContainer.current.clientHeight;
    // console.log(width, height);
    const offX = (e.nativeEvent.offsetX / width) * 100;
    const offY = (e.nativeEvent.offsetY / height) * 100;
    //   console.log(Math.floor(offX), Math.floor(offY));
    setState({
      x: offX,
      y: offY,
    });
  }

  const maskStyle = {
    "--maskX": state.x,
    "--maskY": state.y,
  };

  function mouseAway() {
    setState({
      x: 0,
      y: 0,
    });
  }
  return (
    <div>
      <div
        className="homeContainer"
        onMouseMove={mouseIn}
        onMouseOut={mouseAway}
        ref={homeContainer}
        style={maskStyle}
      >
        <div className="homeWrapper justThis">
          <div className={home.yannieHome}>
            <div className={home.name}>
              <p className={home.title}>software engineer</p>
              <h1 className={home.yannie}>YANNIE</h1>
              <h1 className={home.yeung}>yeung</h1>
            </div>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 800 640"
              style={{ enableBackground: "new 0 0 800 640;" }}
              xmlSpace="preserve"
            >
              <path
                d="M498.99,437.93c-0.12-1.08-0.24-2.17-0.34-3.03c-1.07-0.33-2.1-0.52-3-0.98c-0.36-0.19-0.58-0.95-0.61-1.46
	c-0.07-1.5,1.09-2.21,3.12-2.09c0.12-1.04,0.09-2.12,0.43-3.07c0.16-0.45,0.97-0.83,1.54-0.94c1.37-0.27,2.24,0.79,2.78,3.2
	c0.67-0.17,1.34-0.34,2.08-0.53c0.05-0.81,0.09-1.59,0.14-2.37c0.28-0.26,0.56-0.52,0.84-0.78c0.8-0.26,1.6-0.52,2.33-0.76
	c0-0.74,0-1.35,0-1.92c-1-0.2-1.91-0.39-2.82-0.57c-0.08-0.07-0.16-0.13-0.25-0.18c-0.97-2.8-0.43-3.69,2.4-4.1
	c0.27-1.05,0.29-2.25,0.87-3.03c0.46-0.62,1.6-0.74,2.46-1.08c0.28-1.06,0.61-2.3,0.93-3.54c0,0-0.07,0.08-0.07,0.08
	c0.84-0.09,1.68-0.18,2.58-0.28c-0.11-0.84-0.21-1.61-0.31-2.36c-2.49-0.19-3.32-0.72-3.4-2.14c-0.07-1.22,0.76-2.19,1.94-2.3
	c1.42-0.13,2.18,0.75,2.6,3.04c0.38-0.06,0.75-0.14,1.14-0.18c0.39-0.04,0.78-0.05,1.26-0.08c-0.04-0.46-0.08-0.83-0.09-1.2
	c-0.01-0.39,0-0.79,0-1.3c-0.5,0.04-1.03,0.16-1.53,0.1c-1.33-0.17-2.19-1.24-2.09-2.47c0.1-1.22,1.15-2.13,2.46-2.12
	c0.62,0.01,1.24,0.07,1.86,0.11c0.09,1.07,0.18,2.14,0.28,3.24c0.84-0.08,1.61-0.16,2.44-0.24c-0.04-0.82-0.08-1.58-0.12-2.31
	c-0.96-0.16-2.04,0.54-2.6-0.68c-1.04-0.37-1.2-1.22-1-2.1c0.36-1.57,1.64-1.99,3.04-2.01c0.1-3.1,0.37-3.41,3.31-3.97
	c0.1-1.04,0.21-2.06,0.31-3.09c0,0-0.08,0.08-0.08,0.08c1.05-0.26,2.11-0.51,3.16-0.77c0,0-0.06-0.05-0.06-0.05
	c0.68,1.02,1.35,2.03,2.16,3.24c0.28-0.01,0.99-0.05,1.47-0.07c0.37-1.08,0.51-2.23,1.1-3.05c0.76-1.05,2.03-0.58,3.13-0.44
	c0.15,0.12,0.29,0.25,0.44,0.37c0.22,0.91,0.43,1.82,0.65,2.72c0.75-0.07,1.45-0.13,2.06-0.19c0.25-1,0.24-2.15,0.79-2.87
	c0.49-0.64,1.6-0.82,2.42-1.2c0.27-0.88,0.46-1.94,0.93-2.86c0.19-0.37,0.96-0.59,1.48-0.62c1.45-0.09,2.18,1.01,2.29,3.34
	c0.83-0.07,1.66-0.15,2.36-0.21c0.24-1,0.32-2.02,0.75-2.86c0.23-0.45,1.03-0.76,1.61-0.85c1.41-0.22,2.24,0.72,2.77,3.08
	c0.66-0.09,1.33-0.19,2.06-0.29c0-0.75,0-1.45,0-2c-1.03-0.35-2.03-0.55-2.87-1.02c-0.37-0.21-0.56-0.96-0.6-1.48
	c-0.08-1.35,0.75-1.99,2.86-2.38c0.13-0.94,0.26-1.86,0.39-2.78c0.09-0.11,0.17-0.22,0.25-0.34c0.9-0.35,1.8-0.71,2.77-1.09
	c-0.06-0.6-0.13-1.34-0.18-1.88c-1.14-0.17-2.12-0.32-3.1-0.46c0,0,0.09,0.07,0.09,0.07c-0.31-0.96-0.62-1.92-0.94-2.89
	c-2.97-0.09-3.37-0.49-3.58-3.65c-0.84,0.14-1.68,0.28-2.58,0.43c0.05,0.46,0.17,0.92,0.13,1.37c-0.15,1.54-1.28,2.42-2.8,2.21
	c-0.47-0.07-0.91-0.26-1.37-0.39c0,0,0.09,0.05,0.09,0.05c-0.66-1.31-0.64-2.62,0.01-3.93l-0.08,0.09c0.9-0.22,1.8-0.44,2.7-0.66
	c-0.11-0.8-0.21-1.48-0.29-2.04c-1-0.26-2.11-0.25-2.73-0.79c-0.69-0.59-0.94-1.69-1.37-2.55c-0.68,0.03-1.38,0.07-2.03,0.1
	c-0.09,0.87-0.18,1.72-0.27,2.58c-0.1,0.15-0.2,0.29-0.29,0.44c-0.97,0.92-2.11,0.76-3.26,0.52c-0.18-0.15-0.36-0.29-0.55-0.44
	c-0.39-1.23-0.65-2.44,0.26-3.57c0,0,0.17-0.07,0.17-0.07c0.88-0.19,1.76-0.39,2.65-0.58c-0.09-0.82-0.18-1.63-0.25-2.28
	c-1.08-0.11-1.99-0.21-2.9-0.3c-0.13-0.12-0.26-0.24-0.4-0.37c-0.8-2.94-0.36-3.67,2.68-4.31c0-0.75,0-1.52,0-2.3
	c-3.37-0.05-3.8-0.49-3.63-3.68c0.13-0.12,0.27-0.24,0.4-0.35c0.88-0.28,1.77-0.56,2.57-0.81c0.28-1.18,0.57-2.38,0.86-3.58
	l-0.08,0.09c0.81-0.17,1.63-0.35,2.53-0.54c-0.04-0.8-0.08-1.47-0.11-2.01c-1.01-0.13-1.92-0.24-2.82-0.35
	c-0.17-0.16-0.34-0.31-0.51-0.47c-0.18-0.9-0.37-1.8-0.55-2.68c-0.89-0.08-1.88-0.18-2.86-0.27l0.09,0.07
	c-0.39-0.92-0.78-1.85-1.16-2.73c-0.88-0.27-1.93-0.48-2.85-0.94c-0.39-0.2-0.62-0.93-0.69-1.45c-0.19-1.38,0.82-2.23,3.04-2.68
	c0.07-0.93,0.14-1.87,0.22-2.8c0.15-0.15,0.29-0.3,0.44-0.45c0.94-0.25,1.89-0.51,2.88-0.77c-0.17-0.71-0.32-1.37-0.47-1.99
	c-0.89-0.09-1.67-0.17-2.44-0.25c-0.16-0.12-0.32-0.25-0.48-0.37c-1.15-2.9-0.61-3.86,2.61-4.57c-0.11-0.67-0.22-1.35-0.32-2
	c-2.57-0.25-3.66-1.06-3.6-2.61c0.07-1.54,1.22-2.29,3.48-2.26c-0.06-0.99-0.12-1.98-0.18-2.97c0.16-0.18,0.31-0.36,0.47-0.54
	c0.86-0.37,1.71-0.73,2.68-1.15c-0.1-0.5-0.2-1.03-0.29-1.54c-0.87-0.08-1.66-0.16-2.45-0.24c-0.16-0.12-0.32-0.25-0.47-0.37
	c-0.73-1.12-0.57-2.33-0.39-3.54c0.05-0.09,0.1-0.17,0.16-0.25c1.03-0.25,2.06-0.49,3.07-0.73c-0.1-0.86-0.18-1.62-0.26-2.33
	c-0.94,0.05-1.79,0.09-2.65,0.13c-0.14-0.18-0.29-0.37-0.43-0.55c-0.79-0.93-1.12-1.94-0.4-3.04c0.75-1.13,1.83-1.4,3.19-0.92
	c-0.18-0.95-0.34-1.77-0.46-2.4c-1.03-0.36-2.03-0.54-2.84-1.05c-0.43-0.27-0.68-1.08-0.71-1.66c-0.06-1.4,1.01-2.14,3.06-2.36
	c0.21-1.17,0.42-2.3,0.62-3.42c0,0-0.07,0.11-0.07,0.11c0.94-0.28,1.88-0.56,2.85-0.85c-0.16-0.7-0.3-1.3-0.44-1.89
	c-0.84-0.09-1.62-0.17-2.39-0.25c-0.18-0.15-0.36-0.3-0.53-0.44c-0.25-0.97-0.5-1.95-0.74-2.87c-0.81-0.18-1.79-0.28-2.65-0.66
	c-0.4-0.18-0.69-0.88-0.79-1.39c-0.28-1.43,0.68-2.29,2.77-2.67c0.18-1.18,0.35-2.32,0.53-3.46l-0.03,0.07
	c0.97-0.26,1.94-0.52,2.93-0.78c-0.03-0.71-0.06-1.32-0.09-1.82c-1.23-0.3-2.29-0.55-3.34-0.81c0,0,0.05,0.07,0.05,0.07
	c-0.5-1.31-0.46-2.62,0-3.93c0,0-0.04,0.08-0.04,0.08c0.9-0.24,1.81-0.47,2.76-0.72c-0.07-0.67-0.14-1.36-0.22-2.2
	c-0.87,0-1.73,0-2.59,0c-0.14-0.16-0.27-0.32-0.41-0.48c-0.78-1.1-0.57-2.31-0.4-3.52c0.05-0.09,0.11-0.18,0.17-0.26
	c0.97-0.23,1.94-0.47,2.98-0.71c-0.05-0.7-0.1-1.45-0.14-2.1c-0.94-0.13-1.78-0.25-2.61-0.37l-0.12-0.07
	c-1.63-2.57-1.13-3.79,2.09-4.71c-0.04-0.64-0.07-1.31-0.1-1.83c-1.31-0.35-2.42-0.65-3.53-0.94l0.08,0.07
	c-0.13-0.9-0.25-1.81-0.37-2.68c-2.48-0.12-3.37-0.69-3.45-2.13c-0.08-1.5,0.79-2.18,3.18-2.43c-0.13-0.8-0.26-1.61-0.42-2.59
	c-1.1-0.17-2.51,0.24-3.27-1.12c-0.59-1.04-0.27-1.98,0.23-2.92c0,0-0.08,0.08-0.08,0.08c1.3-1.08,2.71-0.33,4.08-0.24
	c0.05,0.87,0.1,1.74,0.16,2.66c0.96-0.07,1.64-0.12,2.37-0.18c-0.09-0.79-0.17-1.47-0.24-2.13c-0.83-0.11-1.59-0.2-2.36-0.3
	c-0.58-0.88-1.17-1.76-1.88-2.84c-0.6-0.09-1.6-0.24-2.61-0.4c-0.14-0.09-0.29-0.18-0.43-0.27c-0.08-0.98-0.17-1.95-0.26-3.01
	c-0.87,0.18-1.61,0.33-2.16,0.44c-0.19,1.08-0.35,1.99-0.51,2.9c-0.08,0.12-0.16,0.23-0.25,0.33c-0.08,0.01-0.16,0.01-0.24,0.03
	c-2.93,0.57-3.65,0.04-4.01-3.03c-0.83,0.12-1.66,0.23-2.56,0.36c0,0.86,0,1.72,0,2.59c0,0.01,0.05-0.07,0.05-0.07
	c-1.19,1.3-2.58,1.3-4.06,0.59l0.08,0.09c-0.81-1.31-0.25-2.72-0.25-4.07c0.97-0.08,1.93-0.16,2.88-0.25
	c-0.13-0.95-0.22-1.63-0.33-2.42c-0.78,0.05-1.46,0.09-1.98,0.12c-0.19,0.96-0.36,1.78-0.52,2.6c-0.72,1.12-1.71,1.4-2.87,0.8
	c-1.16-0.59-1.45-1.6-1.15-2.84c0.11-0.45,0.11-0.92,0.17-1.38c0.96-0.06,1.92-0.12,2.9-0.18c-0.14-0.93-0.24-1.68-0.33-2.24
	c-1.05-0.28-2.12-0.29-2.76-0.81c-0.65-0.53-0.89-1.57-1.34-2.44c-0.61,0.09-1.28,0.18-1.95,0.28c0.05,0.82,0.09,1.5,0.13,2.18
	c3.15-0.04,3.73,0.54,3.4,3.28c-0.57,1.34-1.72,1.42-2.88,1.35c-0.14,1.08-0.27,2.13-0.41,3.19l0.08-0.08
	c-0.43,0.19-0.85,0.45-1.3,0.56c-1.5,0.37-2.67-0.27-2.98-1.6c-0.32-1.34,0.4-2.46,1.87-2.81c0.38-0.09,0.79-0.06,1.27-0.08
	c-0.08-0.78-0.15-1.54-0.24-2.44c-0.72,0.06-1.48,0.12-2.23,0.18c-0.34,3.41-1.05,3.97-4.15,3.28l0.08,0.08
	c-0.37-0.93-0.75-1.87-1.2-3.01c-1.07,0.28-2.34,0.25-3.09-1.06c-0.45-0.78-0.74-1.64,0.23-2.32c0.48-1.66,1.89-1.14,3.05-1.15
	c-0.22-0.88-0.41-1.62-0.61-2.39c-0.77,0-1.53,0-2.21,0c-0.08,1.25-0.16,2.4-0.23,3.55c-0.45-0.01-0.9-0.08-1.35-0.03
	c-1.68,0.19-2.82-0.54-2.98-1.96c-0.14-1.31,0.71-2.29,2.26-2.56c0.3-0.05,0.62,0.02,1.08,0.05c-0.07-0.48-0.17-0.92-0.2-1.36
	c-0.03-0.45-0.01-0.91-0.01-1.26c-1.06,0-2,0-2.93,0c-0.15-0.14-0.3-0.28-0.45-0.41c-0.25-0.9-0.51-1.79-0.72-2.53
	c-1.02-0.34-2.08-0.56-2.99-1.04c-0.36-0.19-0.54-0.96-0.57-1.48c-0.07-1.17,0.88-2.11,2.06-2.2c1.56-0.12,2.28,0.75,2.46,3.1
	c0.77-0.08,1.53-0.16,2.46-0.25c-0.07-0.74-0.14-1.46-0.21-2.18c-3.06-0.17-3.41-0.48-3.67-3.29c-1.05-0.08-2.11-0.15-3.17-0.23
	c-0.13-0.1-0.25-0.2-0.38-0.3c-0.42-1.32-0.82-2.64,0.44-3.76c0,0-0.04,0.02-0.04,0.02c0.88-0.2,1.76-0.39,2.65-0.59
	c-0.13-0.72-0.26-1.49-0.38-2.17c-0.96-0.14-1.87-0.27-2.78-0.4c-0.13-0.12-0.26-0.24-0.39-0.36c-0.2-0.91-0.4-1.83-0.62-2.86
	c-0.79-0.05-1.79-0.11-2.79-0.17l0.09,0.09c-0.01-0.08-0.01-0.17-0.04-0.23c-1.41-2.73-0.92-3.75,2.2-4.42c0-0.75,0-1.52,0-2.25
	c-0.97-0.21-2.15-0.14-2.82-0.68c-0.68-0.56-0.87-1.72-1.21-2.48c-0.99-0.2-2.04-0.41-3.08-0.62c0,0,0.09,0.08,0.09,0.08
	c-0.23-0.98-0.46-1.97-0.67-2.86c-0.94-0.23-1.98-0.35-2.9-0.76c-0.38-0.17-0.65-0.9-0.73-1.41c-0.23-1.49,0.76-2.32,3.09-2.74
	c-0.04-0.78-0.09-1.56-0.15-2.53c-1.23,0.27-2.62,0.46-3.42-0.96c-0.44-0.78-0.74-1.63,0.21-2.32c0.41-1.62,1.86-1.08,2.96-1.31
	c-0.8-2.68,0.51-3.78,2.92-4.01c-0.1-0.78-0.19-1.45-0.27-2.09c-0.84,0-1.55,0-2.26,0c0.36,2.38,0.02,3.43-3.09,3.91
	c-0.09,1.17-0.17,2.34-0.25,3.51c-0.45-0.01-0.9-0.07-1.34-0.02c-1.58,0.18-2.62-0.4-2.94-1.7c-0.33-1.29,0.35-2.34,1.88-2.83
	c0.36-0.11,0.74-0.13,1.13-0.2c0-0.86,0-1.64,0-2.42c-2.99,0.21-3.5-0.19-3.98-3.16c-1.27,0.04-2.77,0.32-3.42-1.24
	c-0.37-0.88-0.58-1.85,0.58-2.43c0.38-1.45,1.84-0.43,2.4-0.93c0.12-1.05-0.07-2.13,0.38-2.7c0.64-0.78,1.76-1.17,2.77-1.8
	c-0.1-0.48-0.22-1.07-0.34-1.68c-0.75,0.04-1.37,0.08-1.83,0.1c-0.28,1.04-0.27,2.18-0.83,2.77c-0.61,0.64-1.75,0.77-2.79,1.18
	c0.08,0.91,0.18,1.98,0.27,3.05c-0.54,0.07-1.07,0.11-1.61,0.2c-1.59,0.26-2.69-0.34-2.98-1.64c-0.31-1.38,0.54-2.43,2.23-2.73
	c0.3-0.05,0.6-0.06,0.86-0.08c-0.07-0.93-0.13-1.78-0.2-2.63c-3.05,0.16-3.6-0.29-3.71-2.93c-1.06-0.26-2.13-0.36-3.05-0.81
	c-0.42-0.21-0.7-1.07-0.73-1.65c-0.09-1.46,1-2.19,3.27-2.3c-0.13-0.76-0.26-1.53-0.4-2.4c-0.72,0-1.49,0-2.05,0
	c-0.27,1.04-0.4,2.02-0.82,2.87c-0.19,0.39-0.91,0.64-1.43,0.72c-1.12,0.17-2.18-0.77-2.31-1.93c-0.19-1.57,0.67-2.31,3.05-2.56
	c-0.05-0.45-0.11-0.91-0.14-1.37c-0.02-0.39,0-0.79,0-1.05c-1.17-0.26-2.27-0.5-3.37-0.74l0.08,0.08c-0.22-0.98-0.44-1.96-0.61-2.72
	c-1.02-0.27-2.1-0.36-2.94-0.86c-0.46-0.27-0.67-1.21-0.71-1.86c-0.03-0.58,0.33-1.19,0.51-1.79c0,0-0.09,0.11-0.09,0.11
	c0.87-0.23,1.75-0.47,2.62-0.7c-0.11-0.7-0.2-1.31-0.28-1.83c-0.97-0.13-1.86-0.26-2.75-0.38c-0.17-0.15-0.33-0.31-0.5-0.46
	c-0.21-0.9-0.43-1.81-0.61-2.56c-0.97-0.12-1.88-0.24-2.8-0.36c-0.13-0.12-0.27-0.25-0.4-0.37c-0.2-0.9-0.4-1.8-0.56-2.56
	c-0.95-0.23-2.1-0.2-2.76-0.76c-0.68-0.56-0.88-1.69-1.31-2.62c-0.8,0-1.79,0-2.78,0c-0.1-0.11-0.2-0.22-0.28-0.35
	c-0.27-0.94-0.54-1.88-0.8-2.8c-0.86-0.17-1.81-0.37-2.76-0.56c0,0,0.05,0.06,0.05,0.06c-0.41-0.98-0.83-1.96-1.24-2.95
	c-0.72-0.16-1.86-0.11-2.56-0.64c-0.65-0.49-0.83-1.59-1.21-2.42c-0.87-0.24-1.93-0.42-2.87-0.86c-0.37-0.18-0.59-0.93-0.65-1.45
	c-0.17-1.58,0.71-2.28,3.18-2.64c-0.15-0.69-0.29-1.38-0.45-2.13c-0.66,0-1.28,0-2.07,0c0.13,2.84-1.02,4.25-4,3.39
	c-0.15-0.13-0.3-0.25-0.45-0.38c-0.2-0.91-0.4-1.83-0.6-2.75c-2.7,0.18-3.24-0.23-3.82-2.86c-1.14-0.29-2.27-0.57-3.39-0.85
	c0,0,0.08,0.08,0.08,0.08c-0.2-0.9-0.39-1.8-0.56-2.54c-1.19-0.32-2.3-0.62-3.41-0.92l0.08,0.08c-0.18-0.84-0.35-1.69-0.55-2.62
	c-0.81,0.07-1.62,0.15-2.44,0.22c0.14,2.3-0.47,3.33-1.98,3.42c-1.21,0.07-2.17-0.75-2.27-1.96c-0.13-1.46,0.62-2.12,2.92-2.51
	c-0.06-0.75-0.12-1.52-0.19-2.42c-0.69,0.07-1.37,0.13-2.08,0.2c-0.04,0.64,0.01,1.21-0.12,1.74c-0.29,1.2-1.31,1.85-2.51,1.72
	c-1.16-0.13-2.05-1.07-2.06-2.26c-0.01-0.61,0.11-1.23,0.17-1.84c0.96-0.21,1.91-0.42,2.93-0.64c-0.1-0.79-0.2-1.52-0.29-2.26
	c-0.83,0.06-1.52,0.11-1.97,0.14c-0.23,1.05-0.42,1.94-0.61,2.82c-2.72,1.47-3.88,0.89-4.51-2.19c-0.94-0.17-1.85-0.34-2.76-0.51
	c0,0-0.12-0.04-0.12-0.04c-0.35-0.92-0.7-1.85-1.1-2.87c-0.71-0.07-1.63-0.17-2.55-0.27c-0.12-0.1-0.24-0.2-0.37-0.28
	c-0.29-0.86-0.57-1.73-0.86-2.59c-0.96-0.1-2.01-0.21-3.05-0.32l0.06,0.05c-1.15-2.65-0.83-4.53,2.6-4.72
	c-0.15-0.77-0.28-1.44-0.42-2.15c-0.76,0.04-1.39,0.08-1.89,0.11c-0.26,0.99-0.25,2.12-0.8,2.72c-0.6,0.66-1.73,0.83-2.52,1.16
	c-0.2,0.93-0.41,1.9-0.62,2.87l-0.02-0.02c-0.28,0.15-0.54,0.37-0.84,0.44c-1.69,0.39-2.89-0.25-3.16-1.65
	c-0.28-1.41,0.56-2.42,2.26-2.72c0.16-0.03,0.32,0.01,0.48-0.01c0.14-0.02,0.27-0.1,0.4-0.16c-0.09-0.78-0.17-1.56-0.26-2.38
	c-0.82,0-1.65,0-2.56,0c0.05,0.38,0.13,0.69,0.14,1c0.03,2.1-0.81,2.86-2.95,2.68c-0.32-0.03-0.63-0.1-0.94-0.15
	c0,0,0.09,0.09,0.09,0.09c-0.36-0.99-0.72-1.98-1.05-2.89c-0.79-0.08-1.62-0.17-2.46-0.26c-0.12-0.08-0.24-0.15-0.36-0.21
	c-0.36-0.97-0.72-1.94-1.13-3.05c-0.64-0.1-1.77,0.8-2.44-0.51c-1.44-0.27-0.88-1.67-1.39-2.46c-0.76,0.07-1.51,0.14-2.27,0.21
	c0.06,0.8,0.11,1.49,0.16,2.25c1.2,0,2.35,0,3.5,0c0.03,0.46,0.03,0.92,0.09,1.37c0.23,1.66-0.46,2.82-1.84,3.05
	c-1.33,0.22-2.36-0.71-2.63-2.39c-0.04-0.23,0.03-0.47,0.04-0.68c-1.09-0.08-2.16-0.15-3.23-0.23c-0.12-0.09-0.23-0.18-0.33-0.29
	c-0.19-0.91-0.38-1.82-0.58-2.79c-0.68,0.04-1.43,0.09-2.06,0.12c-0.36,1.04-0.51,2.24-1.14,3.07c-0.76,1.01-2.03,0.46-3.1,0.28
	l0.08,0.09c-0.35-0.87-0.7-1.74-1.07-2.66c-1.02-0.21-2.15-0.43-3.29-0.66c0,0,0.08,0.04,0.08,0.04c-0.22-0.95-0.44-1.9-0.67-2.89
	c-0.82,0.08-1.49,0.14-2.06,0.19c-0.15,1.01-0.27,1.85-0.4,2.69c-0.08,0.11-0.15,0.22-0.22,0.34c-2.79,1.08-3.53,0.69-4.57-2.46
	c-0.61,0.05-1.23,0.1-2.02,0.16c0.2,2.81-1,4.16-3.93,3.48c-0.17-0.19-0.35-0.39-0.52-0.58c-0.22-0.89-0.44-1.78-0.65-2.66
	c-0.78,0.08-1.53,0.16-2.12,0.22c-0.06,1.08-0.11,2.02-0.15,2.96c-0.12,0.13-0.24,0.26-0.36,0.39c-0.89,0.28-1.78,0.55-2.7,0.83
	c0.21,3.06-0.89,3.99-3.82,3.11c0,0-0.07-0.03-0.07-0.03c-0.36-1-0.72-2-1.11-3.07c-0.51,0.09-1.26,0.22-1.93,0.33
	c-0.2,1.13-0.39,2.18-0.58,3.24l0.07-0.06c-1.07,0.25-2.14,0.5-3.21,0.75c0.47,2.97-0.3,3.89-3.08,3.8
	c-0.27,1.06-0.29,2.27-0.88,3.03c-0.47,0.61-1.64,0.69-2.46,0.99c-0.19,0.81-0.31,1.87-0.72,2.81c-0.16,0.37-0.92,0.62-1.43,0.67
	c-1.53,0.17-2.22-0.76-2.41-3.19c-0.82,0.11-1.64,0.22-2.48,0.33c0,0.82,0,1.6,0,2.18c1.01,0.29,2,0.45,2.85,0.89
	c0.38,0.2,0.62,0.93,0.68,1.45c0.11,1.01-0.84,2-1.89,2.13c-1.35,0.17-2.11-0.67-2.53-2.96c-0.36,0.06-0.72,0.13-1.09,0.17
	c-0.4,0.05-0.79,0.07-1.13,0.09c-0.27,0.99-0.3,2.12-0.87,2.85c-0.5,0.65-1.59,0.86-2.48,1.29c-0.04,2.74-0.47,3.21-3.41,3.57
	c0.11,2.94-0.47,3.57-3.35,3.74c-0.18,1.05-0.1,2.23-0.62,3.04c-0.42,0.66-1.56,0.86-2.42,1.29c-0.24,0.8-0.31,2.01-0.94,2.76
	c-0.49,0.59-1.66,0.62-2.49,0.89c-0.2,0.85-0.18,2.1-0.78,2.83c-0.53,0.65-1.76,0.72-2.76,1.07c0.21,2.94-0.3,3.54-3.09,3.54
	c-0.12,1.06,0.09,2.33-0.43,3.07c-0.53,0.76-1.79,1-2.85,1.53c0.26,2.81-0.76,3.35-3.37,3.53c0.17,2.74-0.25,3.3-3.05,3.98
	c-0.08,2.48-0.68,3.45-2.21,3.58c-1.56,0.14-2.32-0.71-2.84-3.17c-0.69,0.06-1.38,0.11-2.11,0.17c0,0.78,0,1.54,0,2.4
	c0.42-0.05,0.72-0.12,1.02-0.12c2.2,0.04,3.2,1.39,2.55,3.46c-0.14,0.45-0.37,0.87-0.56,1.3l0.06-0.06
	c-0.75,0.09-1.5,0.18-2.34,0.28c0,0.91,0,1.69,0,2.46c-0.28,0.3-0.57,0.61-0.85,0.91c-0.84,0.17-1.68,0.35-2.59,0.54
	c0.04,0.66,0.08,1.36,0.12,2.05c0.97,0.25,2.01,0.31,2.83,0.78c0.99,0.56,1.23,2.13,0.43,2.94c-0.66,0.67-1.67,0.98-2.64,1.52
	c0.04,3.23-1.02,3.54-3.4,3.56c0.07,3.12-0.3,3.58-3.33,4c0,0.77,0,1.54,0,2.18c1.03,0.25,2.03,0.37,2.9,0.77
	c0.39,0.18,0.67,0.89,0.75,1.4c0.24,1.49-0.71,2.24-3.22,2.63c0.43,1.2,0.54,2.4-0.59,3.26c-0.77,0.58-1.66,1.02-2.54,0.11
	c-1.58-0.19-1.1-1.69-1.56-2.54c-0.74,0-1.42,0-2.22,0c0.07,0.8,0.12,1.48,0.18,2.17c1.28,0.13,2.44,0.25,3.61,0.38
	c-0.15,1.43-0.31,2.86-0.46,4.29l0.07-0.08c-0.84,0.17-1.68,0.34-2.65,0.53c0.04,0.67,0.09,1.35,0.13,2.01
	c2.61,0.18,3.56,0.83,3.57,2.35c0.01,1.56-1.02,2.25-3.37,2.28c-0.06,1.04,0.21,2.28-0.28,3.05c-0.49,0.78-1.73,1.09-2.83,1.72
	c-0.12,0.54-0.08,1.61-0.58,2.28c-0.49,0.67-1.52,0.93-2.59,1.53c0.02,0.37,0.06,1.04,0.09,1.58c1.27,0.3,2.41,0.58,3.55,0.85
	l-0.07-0.05c0.21,1.32,0.41,2.64-0.31,3.88c0,0,0.08-0.08,0.08-0.08c-0.95,0.25-1.9,0.5-2.67,0.7c-0.24,1.12-0.46,2.15-0.68,3.18
	c0,0,0.09-0.1,0.09-0.1c-0.9,0.28-1.8,0.55-2.79,0.85c0.06,0.62,0.12,1.31,0.19,2.1c0.98,0,1.85,0,2.72,0
	c0.1,0.15,0.21,0.3,0.31,0.46c1.31,2.94,0.59,4.15-2.52,4.46c-0.22,0.83-0.42,1.61-0.63,2.39c-0.2,1.71-1.87,1.08-2.77,1.68
	c0.06,0.66,0.12,1.28,0.18,1.91c0.86,0,1.63,0,2.37,0c0.08-1.27,0.15-2.43,0.22-3.6c0.36,0.06,0.74,0.22,1.09,0.18
	c1.91-0.26,2.99,0.29,3.24,1.73c0.27,1.52-0.69,2.46-2.74,2.7c-0.15,0.02-0.29,0.06-0.26,0.06c-0.22,1.19-0.42,2.31-0.62,3.42
	c0,0,0.08-0.1,0.08-0.1c-0.97,0.27-1.95,0.55-2.98,0.83c0.03,0.7,0.07,1.47,0.1,2.23c1.02,0,1.95,0,2.88,0
	c0.14,0.11,0.28,0.22,0.43,0.33c1.24,2.95,0.59,4.09-2.44,4.44c-0.28,1.04-0.31,2.23-0.89,3c-0.48,0.63-1.62,0.75-2.71,1.2
	c-0.06,0.59-0.15,1.51-0.24,2.44c-0.13,0.13-0.26,0.26-0.4,0.39c-0.29,0.13-0.57,0.3-0.87,0.37c-1.65,0.37-2.81-0.27-3.13-1.69
	c-0.31-1.43,0.43-2.47,2.08-2.89c0.15-0.04,0.32,0,0.47-0.03c0.15-0.03,0.28-0.1,0.42-0.16c0.04-0.15,0.12-0.3,0.11-0.44
	c-0.03-0.55-0.08-1.1-0.14-1.78c-0.47-0.03-0.93,0-1.38-0.09c-1.28-0.27-1.91-1.16-1.75-2.38c0.17-1.22,1.04-1.9,2.36-1.78
	c0.47,0.04,0.92,0.2,1.38,0.3c0.16,0.83,0.33,1.66,0.51,2.55c0.91-0.1,1.67-0.19,2.45-0.27c-0.09-0.81-0.16-1.48-0.23-2.21
	c-1.05,0-1.91,0-2.78,0c-0.31-0.46-0.72-0.88-0.91-1.39c-0.57-1.54,0.14-2.91,1.71-3.36c0.44-0.13,0.92-0.12,1.4-0.18
	c0-0.67,0.01-1.21-0.01-1.76c0-0.14-0.09-0.29-0.14-0.43c-2.47-0.16-3.24-0.64-3.39-2.13c-0.14-1.5,0.57-2.25,2.87-2.96
	c-0.04-0.67-0.08-1.36-0.12-2.04c-2.33-0.37-2.83-0.73-2.74-1.94c0.11-1.41,0.71-2.29,1.62-2.35c1.11-0.06,1.73,0.74,2.09,2.81
	c0.75-0.07,1.51-0.14,2.37-0.23c0-0.7,0-1.39,0-2.07c-2.77-0.3-3.51-0.77-3.63-2.31c-0.12-1.49,0.7-2.3,2.96-2.89
	c0-0.75,0-1.52,0-2.28c-0.75-0.15-1.6-0.22-2.33-0.53c-0.3-0.13-0.5-0.79-0.54-1.23c-0.15-1.85,0.5-2.51,2.62-2.79
	c-0.17-3.17,0.11-3.57,2.91-4.21c0-0.75,0-1.52,0-2.28c-2.35-0.05-2.89-0.37-3-1.8c-0.14-1.81,0.49-2.47,2.47-2.8
	c0.22-1.04,0.17-2.2,0.7-2.91c0.54-0.71,1.67-0.97,2.63-1.48c0.15-0.86,0.34-1.93,0.53-3c0,0-0.07,0.11-0.07,0.11
	c0.95-0.28,1.9-0.56,2.84-0.84c-0.05-0.72-0.09-1.33-0.13-1.89c-1.02-0.15-1.86-0.28-2.7-0.41c-0.09-0.07-0.18-0.13-0.28-0.19
	c-1.25-2.86-0.65-3.94,2.5-4.58c0.23-3.03,0.44-3.28,3.38-3.9c-0.13-0.68-0.26-1.36-0.39-2.02c-2.49-0.27-3.28-0.82-3.35-2.29
	c-0.08-1.58,0.68-2.26,2.9-2.66c0.25-0.99,0.25-2.11,0.79-2.84c0.49-0.66,1.56-0.9,2.53-1.4c0-0.55,0-1.32,0-1.98
	c-0.95-0.26-1.85-0.41-2.65-0.78c-0.32-0.15-0.51-0.79-0.56-1.23c-0.14-1.26,0.69-2.24,1.86-2.32c1.29-0.09,1.83,0.56,2.28,2.78
	c0.78-0.08,1.56-0.16,2.4-0.24c0-0.78,0-1.56,0-2.34c-2.47,0.1-3.38-0.37-3.6-1.84c-0.23-1.52,0.54-2.36,2.55-2.91
	c0.38-1.08,0.53-2.23,1.14-3.04c0.44-0.59,1.49-0.72,2.32-1.08c0.25-0.81,0.33-2,0.94-2.78c0.49-0.63,1.62-0.76,2.41-1.08
	c0.3-0.86,0.44-2.02,1.05-2.8c0.44-0.57,1.51-0.64,2.48-1c-0.06-0.55-0.15-1.38-0.23-2.21c-2.45,0.08-3.37-0.41-3.57-1.87
	c-0.22-1.55,0.73-2.42,2.95-2.79c0.31-1.07,0.4-2.23,0.97-3.07c0.42-0.61,1.47-0.79,2.2-1.14c0.19-0.87,0.15-2.04,0.69-2.74
	c0.55-0.71,1.69-0.95,2.62-1.43c0.2-0.87,0.44-1.93,0.68-2.99c0,0-0.08,0.08-0.08,0.08c0.89-0.25,1.79-0.5,2.67-0.74
	c0.25-0.87,0.29-1.98,0.85-2.71c0.5-0.65,1.55-0.89,2.35-1.3c0.21-0.82,0.43-1.72,0.66-2.62c0,0,0.09-0.13,0.09-0.13
	c0.92-0.35,1.85-0.7,2.86-1.08c-0.07-0.63-0.15-1.4-0.23-2.17c-2.1-0.07-2.84-0.46-2.96-1.57c-0.11-1,1.07-2.33,2.16-2.45
	c0.97-0.1,1.41,0.47,1.93,2.53c0.16,0.01,0.32,0.05,0.46,0.02c0.61-0.12,1.23-0.25,1.77-0.37c0.14-0.99,0.26-1.92,0.39-2.84
	l-0.09,0.09c0.93-0.4,1.86-0.8,2.8-1.21c0.33-2.75,0.58-3.04,3.53-3.77c-0.35-2.35,0.91-3.49,3.15-3.69
	c0.31-3.19,0.57-3.49,3.22-3.91c0.41-1.05,0.69-2.1,1.22-3c0.22-0.38,0.95-0.59,1.46-0.63c1.3-0.1,1.99,0.82,2.42,3.06
	c0.67,0,1.37,0,2.04,0c0.12-0.96,0.24-1.88,0.35-2.8c0.15-0.14,0.3-0.28,0.44-0.42c0.9-0.26,1.8-0.51,2.65-0.75
	c0.11-1.02,0.23-2.1,0.35-3.17l-0.09,0.08c0.94-0.38,1.88-0.76,2.81-1.14c0.28-2.72,0.56-3.03,3.38-3.76
	c0.34-2.91,0.72-3.31,3.29-3.5c0.25-1,0.23-2.16,0.8-2.86c0.56-0.69,1.69-0.92,2.64-1.38c-0.23-3.29,0.89-4.27,3.87-3.17
	c0,0-0.05-0.04-0.05-0.04c0.44,0.96,0.88,1.91,1.36,2.96c0.62-0.07,1.29-0.15,1.88-0.22c0.09-1.03,0.16-1.89,0.23-2.74
	c0.13-0.14,0.26-0.28,0.39-0.42c0.88-0.24,1.77-0.48,2.73-0.74c0-0.76,0-1.59,0-2.2c-1.16-0.18-2.2-0.34-3.25-0.49l0.08,0.09
	c-0.97-3-0.6-3.69,2.26-4.46c0.23-1.19,0.45-2.32,0.67-3.44l-0.06,0.07c1.06-0.19,2.12-0.38,2.8-0.5c0.31-1.22,0.57-2.29,0.84-3.35
	c0,0-0.07,0.1-0.07,0.1c0.8-0.31,1.6-0.62,2.51-0.98c0-0.62,0-1.4,0-2.19c-2.58,0-3.55-0.56-3.62-2.06
	c-0.06-1.24,0.94-2.28,2.23-2.32c1.48-0.05,2.18,0.85,2.43,3.17c0.54-0.05,1.08-0.12,1.63-0.16c0.23-0.02,0.47,0.03,0.63,0.04
	c0.25-1,0.22-2.19,0.8-2.84c0.59-0.65,1.78-0.77,2.81-1.16c0.18-0.61,0.34-1.5,0.73-2.29c0.16-0.33,0.78-0.52,1.23-0.61
	c1.5-0.31,1.95,0.06,2.83,2.23c0.67,0,1.37,0,2.24,0c0.04-1.67,0.14-3.35,2.25-3.62c1.53-0.2,2.3,0.65,2.9,3.2
	c0.67-0.03,1.36-0.07,2.06-0.1c-0.18-2.9,0.1-3.78,3.42-4.19c0.15-2.13,0.8-3.13,2.09-3.2c1.3-0.07,2.08,1,2.21,3.03
	c0.92-0.06,1.83-0.11,2.66-0.17c0.26-0.9,0.45-1.88,0.85-2.76c0.15-0.33,0.79-0.53,1.23-0.6c1.35-0.22,2.06,0.52,2.71,2.66
	c3.2-0.02,3.74,0.43,3.97,3.47c0.61-0.06,1.23-0.13,1.84-0.19c0.3-1.16,0.6-2.28,0.89-3.4l-0.08,0.08c0.08,0.01,0.16,0.04,0.24,0.02
	c2.98-0.77,3.6-0.42,4.46,2.62c0.58-0.03,1.18-0.06,1.9-0.1c0.02-2.16,0.56-3.96,3.2-3.5c1.66,0.3,1.89,1.72,1.83,3.21
	c0.86,0,1.71,0,2.4,0c0.16-0.98,0.18-2.06,0.55-2.99c0.18-0.44,0.99-0.8,1.57-0.89c1.49-0.25,2.33,0.8,2.68,3.2
	c0.91,0,1.84,0,2.77,0c0.28,0.26,0.57,0.51,0.85,0.77c0.09,0.85,0.18,1.69,0.27,2.58c0.87-0.04,1.64-0.08,2.16-0.11
	c0.22-1.05,0.4-1.93,0.58-2.82c0.12-0.1,0.23-0.2,0.33-0.31c2.6-1.09,3.55-0.52,4.16,2.59c0.76,0,1.53,0,2.03,0
	c0.32-1.19,0.61-2.26,0.91-3.33c0,0-0.07,0.09-0.07,0.09c0.95-0.27,1.91-0.53,2.91-0.81c0.08-2.2,0.5-2.97,1.81-3.19
	c1.33-0.23,2.14,0.47,2.83,2.39c1.04,0.24,2.21,0.22,2.93,0.77c0.71,0.54,0.95,1.68,1.45,2.66c0.42,0,1.03,0,1.64,0
	c0.23-2.52,0.86-3.47,2.33-3.62c1.47-0.14,2.27,0.65,2.88,2.84c0.99,0.12,1.99,0.24,2.98,0.35c0.15,0.18,0.29,0.35,0.44,0.53
	c0.16,0.9,0.32,1.81,0.49,2.74c0.72-0.11,1.34-0.21,1.92-0.3c0.12-0.87,0.23-1.65,0.34-2.44c0.18-0.29,0.36-0.58,0.54-0.87
	c1.2,0,2.39,0,3.59,0c0,0-0.09-0.09-0.09-0.09c0.3,0.89,0.6,1.78,0.94,2.78c0.63,0,1.31,0,1.99,0c0.06-2.45,0.67-3.45,2.16-3.63
	c1.53-0.19,2.4,0.73,2.86,3.03c0.96,0.24,2.09,0.25,2.83,0.8c0.66,0.49,0.88,1.57,1.36,2.52c0.49,0,1.19,0,1.73,0
	c0.23-1.12,0.44-2.17,0.65-3.23c0,0-0.08,0.08-0.08,0.08c0.08-0.01,0.16-0.02,0.24-0.04c2.96-0.95,3.84-0.39,4.23,2.63
	c1.09,0.15,2.15,0.29,3.22,0.43c0,0-0.09-0.09-0.09-0.09c0.31,1.01,0.63,2.02,0.93,2.98c0.97,0.16,2.02,0.33,3.07,0.5l-0.09-0.09
	c0.28,0.89,0.57,1.78,0.91,2.86c0.61-0.03,1.35-0.07,2.1-0.12c-0.06-2.39,0.64-3.52,2.18-3.65c1.57-0.12,2.43,1,2.61,3.22
	c1.33,0.24,2.58,0.48,3.83,0.71l-0.06-0.07c0.18,0.84,0.35,1.68,0.53,2.56c0.71,0,1.4,0,2.21,0c-0.06-2.05,0.05-4.14,2.98-3.19
	c0.59,0.19,1.12,1.34,1.2,2.1c0.11,1.12-0.84,1.66-2.9,1.97c0.1,0.81,0.21,1.63,0.31,2.44c0.82,0,1.59,0,2.35,0
	c-0.04-2.43,0.46-3.34,1.92-3.5c1.55-0.17,2.29,0.65,2.6,2.84c0.98,0.19,2.13,0.1,2.83,0.62c0.72,0.54,1.02,1.66,1.56,2.65
	c2.76,0.22,3.05,0.46,3.75,3.3c2.92,0.25,3.24,0.52,3.8,3.28c0.82-0.04,1.66-0.08,2.49-0.12c0.01-2.06,0.24-2.52,1.27-2.57
	c1.05-0.05,2.28,0.8,2.3,1.71c0.04,1.59-1.3,1.54-2.4,1.76c0.1,0.83,0.2,1.61,0.31,2.49c0.72,0,1.48,0,2.34,0
	c0.08-0.93-0.6-2.35,0.93-2.73c1.41-0.35,2.08,0.86,2.8,1.74c-0.1,1.72-1.38,1.76-2.64,1.83c0.17,0.82,0.31,1.51,0.45,2.18
	c3.1,0.32,3.25,0.45,3.88,3.26c1.06,0.29,2.04,0.56,3.02,0.84c0,0-0.07-0.08-0.07-0.08c0.28,0.96,0.57,1.92,0.89,2.99
	c0.66-0.04,1.55-0.09,2.41-0.15c0.2-0.83,0.37-1.71,0.66-2.54c0.07-0.2,0.52-0.36,0.81-0.39c0.95-0.12,2.24,1.07,2.27,2.03
	c0.02,0.92-0.55,1.29-2.61,1.61c0.1,0.43,0.21,0.85,0.29,1.28c0.07,0.38,0.12,0.77,0.18,1.14c0.98,0.09,1.9,0.18,2.83,0.27
	l-0.08-0.08c0.4,1,0.8,2,1.24,3.09c0.71,0.17,1.87,0.13,2.55,0.68c0.69,0.57,0.9,1.71,1.33,2.63c0.77,0.21,1.89,0.25,2.6,0.8
	c0.64,0.51,0.83,1.59,1.28,2.54c0.76,0,1.7,0,2.63,0c0.15,0.13,0.3,0.26,0.44,0.39c0.26,0.89,0.53,1.78,0.83,2.81
	c0.53,0,1.22,0,2.08,0c0-0.38-0.03-0.75,0-1.11c0.15-1.63,1-2.45,2.38-2.28c0.95,0.12,1.67,0.6,1.84,1.58c0.1,0.61,0,1.26,0,1.89
	c0,0,0.09-0.07,0.09-0.07c-0.9,0.42-1.81,0.84-2.84,1.32c0.06,0.55,0.14,1.23,0.21,1.89c0.95,0.16,1.78,0.3,2.62,0.45
	c0.11,0.08,0.23,0.15,0.34,0.22c0.37,0.84,0.74,1.67,1.14,2.57c0.6,0,1.29,0,1.92,0c0.15-1.11,0.29-2.17,0.44-3.23
	c0,0,0.05-0.09,0.05-0.09c0.55-0.05,1.11-0.16,1.66-0.15c1.44,0.02,2.36,0.79,2.5,2.03c0.14,1.25-0.57,2.12-2.04,2.47
	c-0.3,0.07-0.63,0.01-1.03,0.01c0,0.79,0,1.61,0,2.51c0.9-0.14,1.66-0.26,2.41-0.38c0.15-2.28,0.71-3.16,2.03-3.22
	c1.03-0.04,2.2,0.95,2.34,2c0.16,1.23-0.64,1.96-2.77,2.45c0.06,0.72,0.12,1.48,0.18,2.18c0.98,0.17,1.89,0.32,2.79,0.48l-0.08-0.07
	c0.46,0.89,0.92,1.79,1.42,2.77c2.78,0.35,3.09,0.63,3.53,3.27c0.95,0.31,2.12,0.38,2.78,0.99c0.66,0.61,0.81,1.76,1.22,2.75
	c2.04-0.05,3.2,0.66,3.35,2.18c0.14,1.41-0.58,2.1-2.95,2.69c0.11,0.56,0.22,1.13,0.35,1.77c0.79,0,1.48,0,2.18,0
	c-0.02-2.37,0.55-3.3,2.04-3.32c1.13-0.01,2.25,1.11,2.32,2.3c0.06,1.19-0.75,1.79-3.07,2.16c0.08,0.66,0.17,1.33,0.26,2.1
	c1,0.18,2,0.36,2.99,0.54c0.11,0.14,0.23,0.29,0.34,0.43c0.15,0.86,0.31,1.71,0.46,2.55c0.89,0.16,2.04,0.07,2.77,0.58
	c0.75,0.52,1.09,1.63,1.67,2.59c2.63,0.35,2.95,0.65,3.41,3.26c0.92,0.38,1.95,0.54,2.54,1.13c0.61,0.61,0.79,1.65,1.22,2.65
	c0.55,0.27,1.43,0.51,2.03,1.05c0.53,0.47,1.12,1.33,1.03,1.91c-0.09,0.57-0.99,1.03-1.56,1.5c-0.16,0.13-0.45,0.11-0.82,0.19
	c0.06,0.69,0.13,1.36,0.19,2.03c0.92,0.26,1.98,0.29,2.63,0.83c0.67,0.54,0.93,1.58,1.42,2.5c2.73,0.47,2.91,0.66,3.39,3.85
	c1.27,0.09,2.23,0.56,2.3,2.1c0.05,1.15,0.04,2.14-1.56,2.17c0.06,0.9,0.12,1.67,0.17,2.42c2.81,0.19,3.24,0.57,3.81,3.45
	c0.55,0,1.14,0,1.69,0c1.01,3.6,0.94,3.89-1.27,4.71c0.13,0.67,0.26,1.36,0.39,2.03c3.22,0.65,3.47,1.01,3.27,4.57
	c0,0,0.03-0.08,0.03-0.08c-0.97,0.26-1.94,0.52-3.01,0.8c0.1,0.66,0.2,1.33,0.31,2.13c0.97,0,1.83,0,2.69,0l0.11,0.21
	c0.31,1.05,0.62,2.1,0.93,3.17c0.7,0.18,1.66,0.25,2.4,0.69c0.42,0.25,0.6,1.08,0.7,1.68c0.28,1.69-0.06,2-2.58,2.65
	c0.04,0.61,0.09,1.23,0.14,1.9c0.22,0.07,0.42,0.16,0.64,0.21c0.61,0.14,1.24,0.21,1.83,0.4c0.27,0.09,0.67,0.42,0.66,0.64
	c-0.07,2.13,2.44,2.49,2.86,4.28c0.38,1.59,0.15,2-1.79,2.77c0.11,0.66,0.23,1.35,0.36,2.12c3.3,0.12,3.72,2.07,3,4.72
	c0,0,0.09-0.08,0.09-0.08c-0.91,0.19-1.83,0.38-2.76,0.57c0.04,0.78,0.08,1.47,0.11,2.03c1.13,0.12,2.13,0.23,3.13,0.34
	c0,0-0.06-0.07-0.06-0.07c0.33,1.04,0.67,2.07,1.1,3.43c1.33,0.08,1.7,1.28,1.8,2.75c0.09,1.28-0.72,1.63-1.73,1.63
	c0.1,1.03,0.18,1.81,0.28,2.72c0.72,0.08,1.48,0.17,2.17,0.25c1.89,3.59,1.8,3.83-1.71,4.47c0.07,0.61,0.15,1.22,0.24,1.97
	c2.21,0.13,4.07,0.75,3.27,3.55c-0.41,1.43-1.75,1.4-3.1,1.41c0.09,0.81,0.17,1.56,0.28,2.51c0.36-0.05,0.71-0.13,1.06-0.13
	c2.11-0.02,2.99,1.01,2.73,3.16c-0.05,0.39-0.1,0.78-0.15,1.18l0.08-0.08c-1.06,0.23-2.11,0.46-3.31,0.72
	c0.14,0.68,0.29,1.39,0.44,2.08c3.09,0.24,3.74,0.92,3.45,3.48c-0.04,0.38,0.04,0.76,0.06,1.14c-1.06,0.13-2.11,0.27-3.23,0.42
	c0.15,0.83,0.28,1.57,0.42,2.33c0.91-0.07,1.67-0.13,2.08-0.16c0.26-1.04,0.47-1.84,0.67-2.64c0.53-0.64,1.18-1.27,2.01-0.7
	c1.03,0.7,1.11,1.91,1.03,3.03c-0.09,1.27-1.24,1.23-2.26,1.35c0.05,0.9,0.09,1.67,0.13,2.44c2.33,0.11,3.15,0.7,3.27,2.31
	c0.12,1.61-0.43,2.13-2.83,2.59c0,0.42,0,0.87,0,1.32c0,0.39,0,0.78,0,1.19c2.55-0.27,3.46,0.21,3.69,1.9
	c0.23,1.71-0.48,2.4-3.01,2.87c0.06,0.67,0.13,1.35,0.18,1.9c1.02,0.37,2.01,0.6,2.86,1.09c0.37,0.22,0.55,0.95,0.6,1.47
	c0.16,1.57-0.48,2.17-2.91,2.84c0.04,0.6,0.08,1.23,0.13,1.91c2.08-0.06,4.07,0.08,3.57,2.97c-0.24,1.39-1.2,1.84-3.42,1.78
	c0.11,0.84,0.23,1.68,0.35,2.58c0.83-0.08,1.66-0.17,2.62-0.26c0.16-1.13-0.48-2.77,1.64-3.17c0.25,1.47,1.03,2.69-0.29,4.17
	c-0.58,0.65-0.27,2.09-0.36,3.16c0,0,0.08-0.07,0.08-0.07c-1.12,0.35-2.23,0.7-3.36,1.06c0.17,0.64,0.37,1.38,0.54,2.03
	c1.07,0.12,2.06,0.23,3.05,0.34l0.01,0c0.23,1.07,0.46,2.14,0.72,3.34c0.41,0.16,1.18,0.26,1.6,0.7c0.39,0.41,0.43,1.17,0.57,1.79
	c0.32,1.41-0.7,1.74-1.8,1.96c0.06,0.91,0.1,1.69,0.16,2.67c1.7-0.03,2.14,1.12,2.22,2.61c0.07,1.26-0.4,2.02-1.73,1.84
	c0,1.1,0,1.96,0,2.82c0.69,0.08,1.29,0.16,2.04,0.25c0.23,1.19,0.45,2.34,0.71,3.66c-0.65,0.26-1.28,0.51-2.02,0.81
	c0.03,0.64,0.07,1.34,0.12,2.25c1.49-0.03,2.5,0.46,2.5,2.26c0,1.46-0.24,2.52-1.94,2.78c0.08,0.66,0.14,1.19,0.2,1.69
	c2.54,0.63,3.04,1.15,2.98,3.01c-0.05,1.55-0.27,1.73-2.76,2.19c0,0.22-0.03,0.45,0.01,0.66c0.07,0.47,0.18,0.93,0.26,1.36
	c2.41,0.55,2.65,0.77,2.72,2.6c0.07,1.76-0.09,1.94-2.34,2.65c0.13,0.68,0.26,1.37,0.38,2.02c2.52,0.41,2.95,0.82,2.93,2.65
	c-0.02,1.66-0.36,1.98-2.61,2.38c0,0.83,0,1.66,0,2.5c2.42-0.31,3.27,0.2,3.53,2.03c0.21,1.5-0.51,2.2-2.89,2.75
	c0,0.69,0,1.39,0,1.95c1.03,0.36,2.02,0.57,2.84,1.06c0.35,0.21,0.45,0.99,0.49,1.53c0.13,1.7-0.33,2.11-2.84,2.72
	c0,0.78,0,1.56,0,2.35c2.54-0.26,3.49,0.21,3.73,1.8c0.26,1.78-0.45,2.48-3.14,3.02c0.1,0.68,0.19,1.36,0.29,2.03
	c2.46,0.3,3.18,0.81,3.35,2.33c0.18,1.56-0.48,2.24-2.8,2.88c0,0.69,0,1.39,0,1.99c0.96,0.23,1.91,0.31,2.7,0.69
	c1,0.49,0.88,1.57,1,2.53c0.06,0.5,0.63,0.92,0.74,1.43c0.17,0.81,0.16,1.66,0.23,2.58c-1.81-0.22-1.29-1.6-1.72-2.5
	c-0.83,0.11-1.65,0.21-2.49,0.32c0,0.9,0,1.68,0,2.46c3.09-0.34,3.35-0.15,4.02,2.84c0.09,0.38,0.35,0.72,0.43,1.1
	c0.26,1.2,0.78,2.27,0.03,3.65c-0.4,0.74-0.75,2.29,0.77,3.24c0.7,0.43,0.83,1.78,1.27,2.86c-1.89,0.79-0.9,2.8-1.79,4.07
	c0,0,0.01,0.01,0.01,0.01c-0.87,1.18-1.93,1.34-3.18,0.62c-0.52-0.97-1.02-1.95-1.53-2.94c-0.58,0.11-1.25,0.24-1.94,0.37
	c0,0.83,0,1.6,0,2.26c1.25,0.09,2.39,0.17,3.54,0.26c0.15,0.97,0.29,1.94,0.41,2.75c1.04,0.21,1.88,0.37,2.71,0.54
	c0.07,0.06,0.14,0.12,0.21,0.17c1.39,2.69,0.83,3.72-2.4,4.45c0.04,0.69,0.09,1.39,0.13,2.09c0.98,0.14,2.02,0.29,3.07,0.44
	c0,0,0.07,0.15,0.07,0.15c0.26,0.97,0.53,1.95,0.83,3.07c1.12-0.02,2.31,0.03,2.47,1.7c0.16,1.67-0.09,2.13-2.04,2.97
	c0,0.35-0.01,0.74,0,1.12c0.01,0.39,0.03,0.78,0.05,1.24c0.67,0.11,1.27,0.21,1.93,0.32c0.06,0.65,0.04,1.2,0.17,1.73
	c0.49,2.05,0.51,2.05-1.66,2.65c0.04,0.73,0.08,1.5,0.12,2.41c1.1,0.34,2.8-0.07,2.76,2.03c-0.03,1.85-0.08,2.09-2.17,2.91
	c0.07,0.66,0.14,1.34,0.21,2.02c2.18,0.28,3.15,0.97,3.17,2.23c0.02,1.47-0.81,2.21-3.05,2.68c0.16,0.84,0.31,1.69,0.42,2.27
	c0.87,0.24,2.02,0.25,2.2,0.67c0.42,0.96,0.46,2.12,0.42,3.2c-0.01,0.3-0.78,0.75-1.24,0.83c-0.68,0.1-1.41-0.07-2.11-0.14
	c-0.08-1.03-0.18-2.06-0.27-3.14c-0.96,0.23-1.63,0.39-2.22,0.54c0,0.83,0,1.51,0,2.02c0.93,0.2,1.73,0.37,2.54,0.54
	c0.53,0.76,1.07,1.53,1.85,2.64c0.39,0.15,1.72,0.25,2.11,0.91c0.53,0.9,0.36,2.22,0.49,3.37c-0.88,0.26-1.76,0.51-2.78,0.81
	c0.04,0.77,0.09,1.58,0.13,2.4c0.96-0.14,1.78-0.26,2.58-0.38c0-1.06,0-1.97,0-2.89c0.34,0.37,0.9,0.71,0.98,1.13
	c0.17,0.84,0.05,1.73,0.09,2.6c0.07,1.31-0.03,2.59,0.84,3.82c0.7,0.98,0.81,2.17,0.06,3.51c-0.53,0.95-0.17,2.39-0.22,3.61
	c-0.98,0.18-1.95,0.36-2.9,0.54c0,0.8,0,1.48,0,2.15c0.85,0,1.61,0,2.21,0c0.23-1.01,0.43-1.88,0.63-2.74
	c0.41-0.07,0.97-0.35,1.22-0.18c0.7,0.48,0.97,3.27,0.25,3.67c-1.21,0.67-0.36,1.52-0.56,2.23c1.88,1.08,2.51,2.14,2.21,3.69
	c-0.27,1.36-1.32,1.09-2.25,1.06c-0.16-0.11-0.31-0.22-0.47-0.33c-0.21-0.81-0.42-1.62-0.64-2.44c-0.73,0-1.4,0-2.05,0
	c-0.18,1-0.34,1.89-0.5,2.78c-0.06,0.09-0.12,0.18-0.18,0.27c-0.88,0.32-1.76,0.63-2.46,0.89c-0.2,0.91-0.4,1.8-0.59,2.69
	c-0.11,0.1-0.21,0.2-0.31,0.31c-0.89,0.81-1.89,0.72-2.92,0.36c-0.09-0.06-0.18-0.13-0.28-0.18c-0.86-1-0.74-2.19-0.69-3.37
	c0.16-0.14,0.33-0.28,0.49-0.42c0.89-0.26,1.78-0.53,2.67-0.79c-0.11-0.75-0.21-1.43-0.28-1.93c-1.06-0.28-2.17-0.27-2.79-0.81
	c-0.68-0.6-0.9-1.71-1.27-2.48c-0.89-0.1-1.74-0.2-2.6-0.29c-0.17-0.15-0.34-0.31-0.51-0.46c-0.17-0.95-0.35-1.91-0.52-2.85
	c-0.83,0.08-1.64,0.15-2.3,0.21c-0.08,0.93-0.15,1.78-0.22,2.63c-0.12,0.16-0.23,0.33-0.35,0.49c-1.01,0.89-2.15,0.68-3.31,0.43
	c-0.4-0.91-0.8-1.82-1.26-2.88c-0.62,0.04-1.36,0.09-2.12,0.14c0.05,0.94,0.1,1.7,0.13,2.25c1.19,0.16,2.25,0.3,3.31,0.44
	c0.23,0.94,0.45,1.88,0.66,2.73c0.96,0.1,1.8,0.19,2.63,0.28c0.15,0.14,0.31,0.27,0.46,0.41c1.13,2.88,0.35,4.01-2.89,4.07
	c0.19,0.83,0.37,1.66,0.58,2.6c0.91,0,1.84,0,2.77,0c0.17,0.18,0.33,0.35,0.5,0.53c0.37,1.12,0.46,2.24,0.01,3.36
	c-0.1,0.15-0.2,0.3-0.31,0.44c-0.99,0.1-1.98,0.2-2.68,0.28c-0.16,1.18-0.29,2.17-0.42,3.16c0,0,0.08-0.09,0.08-0.09
	c-1.08,0.61-2.18,1.03-3.43,0.5l0.07,0.06c-0.49-0.95-0.98-1.89-1.55-3c-0.43,0.05-1.1,0.13-1.85,0.22c0,1.01,0,1.88,0,2.75
	c-0.24,0.31-0.49,0.62-0.73,0.94c-0.85,0-1.69,0-2.54,0c0,0.99,0,1.76,0,2.35c1.14,0.19,2.18,0.37,3.22,0.54
	c0.17,0.08,0.33,0.15,0.5,0.23c0,0.94,0,1.89,0,2.6c1.29,0.22,2.36,0.4,3.43,0.58l-0.09-0.08c0.17,1.13,0.35,2.27,0.52,3.4
	c-0.15,0.14-0.3,0.27-0.46,0.41c-0.88,0.26-1.75,0.52-2.69,0.79c0.1,0.65,0.2,1.32,0.31,2.08c0.95,0,1.88,0,2.8,0
	c0.18,0.19,0.35,0.37,0.53,0.56c0,1.11,0,2.22-0.01,3.33c-0.11,0.16-0.23,0.31-0.34,0.47c-1-0.04-2.23,0.27-2.93-0.2
	c-0.77-0.52-1.05-1.76-1.58-2.75c-0.58,0.05-1.35,0.12-2.13,0.19c0.09,0.89,0.18,1.71,0.26,2.52c2.75-0.29,3.48,0.26,3.72,2.72
	c0.02,0.23,0.06,0.47,0.09,0.7c-0.07,0.06-0.15,0.11-0.22,0.17c-0.97,0.46-1.93,0.91-2.99,1.41c0,0.6,0,1.37,0,1.89
	c1.18,0.22,2.23,0.42,3.27,0.62l-0.09-0.08c0.3,0.92,0.59,1.85,0.87,2.72c0.88,0.08,1.71,0.16,2.55,0.24
	c0.16,0.15,0.32,0.29,0.48,0.44c0.24,0.89,0.49,1.77,0.75,2.71c0.75,0,1.57,0,2.26,0c0.07-1.12,0.14-2.12,0.21-3.12
	c0.2-0.17,0.4-0.35,0.6-0.52c0.99,0.07,2.22-0.2,2.9,0.3c0.73,0.53,0.95,1.77,1.3,2.49c1.04,0.19,2.02,0.36,3.01,0.54
	c0.16,0.07,0.31,0.14,0.47,0.21c0,0.99,0,1.98,0,3.03c1.02-0.18,1.85-0.33,2.53-0.45c0.13-0.97,0.24-1.87,0.36-2.77
	c0.15-0.16,0.3-0.32,0.45-0.48c1.15-0.71,2.3-0.42,3.44,0.03c0,0-0.1-0.08-0.1-0.08c0.28,0.94,0.56,1.88,0.81,2.73
	c0.93,0.09,1.77,0.18,2.61,0.27c0.15,0.13,0.3,0.27,0.45,0.4c0.58,0.94,0.8,1.91,0.23,2.93c-0.68,1.22-1.92,1.13-3.13,1.13
	c0.23,0.95,0.43,1.76,0.64,2.6c0.97,0,1.85,0,2.72,0c0.17,0.18,0.35,0.36,0.52,0.54c0.16,0.95,0.31,1.91,0.47,2.86
	c0.84-0.07,1.67-0.14,2.32-0.19c0.09-1.01,0.16-1.86,0.23-2.7c0.11-0.15,0.22-0.31,0.33-0.46c1.03-0.83,2.21-0.58,3.36-0.43
	c0.12,0.14,0.25,0.28,0.37,0.43c0.23,0.8,0.46,1.59,0.68,2.34c0.82,0.13,1.57,0.24,2.31,0.35c0.11,0.06,0.22,0.1,0.34,0.14
	c0.46,0.96,0.91,1.92,1.37,2.87c0.8,0.18,1.7,0.39,2.6,0.59l-0.07-0.07c1.45,2.77,0.95,3.85-2.24,4.52c0,0.34,0,0.71,0,1.08
	c0,0.39,0,0.78,0,1.23c0.92-0.05,1.69-0.09,2.24-0.12c0.33-1.05,0.53-2.01,0.95-2.87c0.16-0.33,0.79-0.53,1.24-0.6
	c0.71-0.11,1.54,1.09,1.83,2.5c0.22,1.09-0.34,1.56-2.47,2.03c0.03,0.67,0.06,1.36,0.1,2.09c0.29,0.09,0.5,0.16,0.72,0.21
	c0.62,0.12,1.35,0.06,1.82,0.38c0.83,0.57,1.22,1.49,0.93,2.53c-0.26,0.93-0.92,1.51-1.89,1.59c-0.55,0.05-1.11-0.09-1.66-0.14
	c-0.17-0.12-0.33-0.23-0.5-0.35c-0.2-0.82-0.4-1.63-0.6-2.45c-0.76,0-1.43,0-2.09,0c-0.17,1-0.33,1.9-0.49,2.8
	c-0.06,0.08-0.12,0.17-0.18,0.25c-0.86,0.32-1.73,0.63-2.63,0.96c0.05,0.69,0.11,1.5,0.16,2.15c0.99,0,1.84,0,2.69,0
	c0.19,0.3,0.37,0.61,0.56,0.91c0.23,0.87,0.45,1.74,0.68,2.63c2.7-0.34,3.21,0.04,3.89,2.94c0.95,0.13,1.89,0.26,2.82,0.39
	c0.07,0.09,0.16,0.17,0.24,0.25c0.49,1.13,0.67,2.28,0.14,3.46c0,0,0.09-0.08,0.09-0.08c-0.94,0.33-1.88,0.66-2.86,1.01
	c-0.03,0.71-0.07,1.56-0.11,2.42c-0.16,0.2-0.31,0.4-0.47,0.6c-1.02,0.8-2.14,0.58-3.26,0.33c-0.41-0.91-0.81-1.83-1.28-2.89
	c-0.61,0.04-1.35,0.09-2.11,0.14c0.05,0.95,0.09,1.71,0.12,2.27c1.2,0.15,2.26,0.29,3.32,0.42c0.22,0.94,0.44,1.88,0.65,2.77
	c0.94,0.07,1.78,0.12,2.62,0.18c0.18,0.17,0.37,0.33,0.55,0.5c0.49,0.95,0.89,2.1,0.1,2.88c-0.74,0.73-1.89,1.03-2.96,1.57
	c0.13,0.54,0.32,1.35,0.52,2.16c0.98,0,1.84,0,2.71,0c0.18,0.19,0.36,0.38,0.54,0.57c0.15,0.91,0.29,1.83,0.45,2.82
	c0.72-0.06,1.55-0.12,2.31-0.18c0.07-0.96,0.13-1.81,0.19-2.67c0.14-0.18,0.29-0.36,0.43-0.54c1.04-0.85,2.19-0.56,3.34-0.32
	l-0.09-0.09c0.39,0.92,0.78,1.83,1.16,2.74c0.85,0.32,2.11,0.44,2.69,1.13c0.54,0.63,0.35,1.88,0.48,2.86c0,0,0.08-0.05,0.08-0.05
	c-1.04,0.46-2.07,0.92-3.01,1.33c-0.13,0.8-0.28,1.69-0.42,2.59l0.06-0.07c-0.94,1.21-2.27,0.98-3.55,0.98
	c-0.1-0.08-0.19-0.16-0.28-0.24c-0.26-0.97-0.53-1.93-0.81-2.97c-0.65,0.07-1.34,0.14-2.12,0.22c0,0.87,0,1.73,0,2.6
	c-0.19,0.16-0.39,0.33-0.58,0.49c-0.91,0.29-1.82,0.59-2.79,0.9c0.07,0.67,0.16,1.49,0.23,2.19c1,0.1,1.85,0.18,2.69,0.26
	c0.16,0.14,0.32,0.29,0.48,0.43c0.25,0.88,0.49,1.77,0.75,2.68c3.24,0.1,3.67,0.53,3.53,3.65c-0.16,0.13-0.31,0.26-0.47,0.39
	c-0.86,0.31-1.72,0.63-2.63,0.96c0.08,0.6,0.17,1.29,0.26,1.94c0.92,0,1.79,0,2.65,0c0.16,0.31,0.31,0.61,0.47,0.92
	c0.24,0.85,0.49,1.71,0.74,2.6c0.77-0.05,1.7-0.1,2.63-0.16c0.17,0.3,0.33,0.61,0.5,0.91c0.54,0.77,0.93,1.72,0.26,2.43
	c-0.75,0.8-1.81,1.3-2.9,2.04c0.02,0.46,0.07,1.22,0.09,1.73c1.16,0.19,2.16,0.36,3.16,0.53c0.17,0.08,0.34,0.16,0.52,0.23
	c0.05,0.93,0.09,1.87,0.13,2.57c1.18,0.22,2.24,0.42,3.3,0.62l-0.09-0.07c0.28,0.93,0.57,1.87,0.81,2.66
	c0.93,0.11,1.77,0.22,2.61,0.32c0.15,0.14,0.31,0.28,0.46,0.41c1.14,2.89,0.38,4.02-2.83,4.16c0.15,0.76,0.29,1.52,0.46,2.38
	c0.73,0,1.49,0,2.38,0c-0.03-0.38-0.08-0.75-0.08-1.12c0.03-2,0.81-2.74,2.76-2.63c0.39,0.02,0.78,0.12,1.17,0.18l-0.08-0.08
	c0.7,1.22,1.07,2.47,0.48,3.85l0.09-0.06c-1.06,0.35-2.12,0.7-3.19,1.05c0.07,0.63,0.16,1.39,0.25,2.14
	c0.98,0.08,1.96,0.17,2.94,0.25l-0.1-0.08c0.36,1.08,0.71,2.16,1.11,3.37c0.61,0.14,1.61,0.24,2.48,0.63
	c0.38,0.17,0.63,0.9,0.7,1.41c0.21,1.5-0.47,2.09-2.82,2.54c0.06,0.75,0.11,1.52,0.16,2.11c1.02,0.3,2,0.45,2.84,0.88
	c0.39,0.2,0.65,0.91,0.74,1.42c0.21,1.19-0.85,2.4-2.05,2.49c-1.41,0.1-2.19-0.82-2.59-3.11c-0.76,0.04-1.53,0.08-2.39,0.12
	c0,0.73,0,1.5,0,2.21c1.09,0.25,2.33,0.22,3.06,0.81c0.67,0.55,0.79,1.77,1.17,2.74c2.16-0.4,3.51,0.27,3.66,1.95
	c0.13,1.52-0.97,2.38-3.43,2.6c0.13,0.73,0.25,1.46,0.37,2.17c2.64,0.29,3.67,1.03,3.67,2.6c0,1.23-0.98,2.3-2.21,2.41
	c-1.57,0.14-2.32-0.67-2.86-3.1c-0.77,0-1.54,0-2.31,0c0.07,2.48-0.49,3.5-2.02,3.64c-1.62,0.15-2.39-0.8-2.57-3.12
	c-1.05-0.24-2.3-0.19-3.03-0.79c-0.67-0.54-0.77-1.78-1.14-2.76c-2.15,0.19-3.09,0.34-3.77-3.08c-0.71,0.03-1.42,0.07-2.28,0.11
	c0.04,0.8,0.07,1.62,0.11,2.44c2.92-0.19,3.46,0.24,3.89,2.93c1.07,0.38,2.13,0.64,3.03,1.15c0.34,0.19,0.47,0.99,0.47,1.52
	c0.01,1.12-0.84,1.94-1.96,2.03c-1.5,0.12-2.2-0.7-2.6-3.09c-0.75,0.08-1.51,0.16-2.4,0.26c0.03,0.51,0.14,1.03,0.08,1.54
	c-0.14,1.22-1.1,2.1-2.22,2.12c-1.23,0.02-2.17-0.94-2.24-2.32c-0.02-0.47,0.1-0.95,0.16-1.42c0.93-0.37,1.85-0.73,2.9-1.15
	c-0.06-0.73-0.12-1.54-0.18-2.36c-0.92,0.1-1.69,0.19-2.25,0.25c-0.16,1.19-0.29,2.25-0.43,3.3c-1.13,0.15-2.25,0.3-3.38,0.45
	l0.09,0.09c-0.53-1.02-1.05-2.04-1.66-3.23c-0.43,0.06-1.1,0.15-1.86,0.25c0,0.58,0.07,1.06-0.01,1.51
	c-0.29,1.54-1.35,2.32-2.91,2.13c-0.6-0.07-1.18-0.3-1.77-0.46c0.01,0-0.03-0.03-0.03-0.03c-0.07-0.94-0.15-1.88-0.23-2.9
	c-0.73,0.1-1.41,0.18-2.01,0.26c-0.16,1.03-0.3,1.95-0.45,2.87l0-0.05c-1.82,1.25-2.99,1.29-3.88,0.02
	c-0.56-0.8-0.97-1.69,0.05-2.51c0.31-1.52,1.7-1.07,2.7-1.48c-0.12-0.68-0.24-1.36-0.38-2.15c-0.75,0.07-1.41,0.13-1.99,0.18
	c-0.12,1.28-0.23,2.37-0.33,3.46c-1.07,0.08-2.14,0.17-3.24,0.25c0,0.61,0.1,1.22-0.02,1.79c-0.23,1.08-1.23,1.77-2.26,1.69
	c-1.08-0.08-1.84-0.84-1.95-2.01c-0.05-0.54,0.02-1.09,0.03-1.64c0.94-0.28,1.87-0.56,2.91-0.87c-0.12-0.7-0.26-1.5-0.41-2.36
	c-0.71,0.09-1.31,0.17-1.82,0.24c-0.22,1.08-0.43,2.06-0.63,3.04c-0.44,0.16-0.88,0.37-1.34,0.46c-1.53,0.31-2.44-0.15-3.07-1.56
	c-0.19-0.42-0.28-0.87-0.45-1.38c-0.58,0.04-1.05,0.06-1.64,0.1c-0.19,2.05-0.47,4.22-3.4,3.37c-1.59-0.46-1.65-1.91-1.54-3.03
	c-1.22-0.15-2.35-0.29-3.48-0.42c-0.05-0.15-0.11-0.29-0.18-0.43c-0.24-0.74-0.48-1.47-0.75-2.33c-0.64,0.06-1.3,0.12-1.75,0.15
	c-0.26,1.21-0.48,2.19-0.69,3.17l0.08-0.08c-1.14,0.17-2.28,0.34-3.41,0.5c-0.16-0.18-0.33-0.36-0.49-0.53
	c-0.31-0.88-0.61-1.76-0.92-2.65c-0.55,0-1.17,0-1.62,0c-0.29,1.33-0.56,2.53-0.82,3.73l0.07-0.07c-0.08-0.01-0.16-0.05-0.23-0.03
	c-3.05,0.67-3.97-0.05-4.11-3.22c-0.76,0.14-1.52,0.29-2.03,0.38c-0.48,1.09-0.78,2.14-1.38,2.96c-0.27,0.37-1.16,0.45-1.74,0.38
	c-1.42-0.18-1.84-1.21-1.35-3.01c0.21-1.59,1.63-1.14,2.64-1.53c-0.13-0.75-0.26-1.49-0.39-2.27c-0.81,0.06-1.49,0.11-2.06,0.16
	c-0.07,1.32-0.13,2.48-0.19,3.64c-1.18,0.05-2.36,0.09-3.54,0.14l0.08,0.08c-0.54-1-1.07-2.01-1.74-3.26c-0.39,0-1.16,0-1.83,0
	c-0.3,0.97-0.5,2.01-0.96,2.93c-0.19,0.37-0.95,0.57-1.47,0.61c-1.32,0.09-2.06-0.9-2.48-3.14c-3.06-0.04-3.3-0.22-4.04-2.99
	c-1.04-0.17-2.04-0.34-3.04-0.51c-0.13-0.07-0.25-0.14-0.37-0.22c-0.09-0.91-0.18-1.82-0.28-2.75c-0.82,0-1.57,0-2.28,0
	c-0.13,1.13-0.25,2.17-0.37,3.22c0,0-0.04,0.08-0.04,0.08c-0.08,0.01-0.16,0.03-0.24,0.04c-3.11,0.57-3.67,0.2-4.25-2.76
	c-3.32,0.22-3.71-0.97-3.77-3.38c-0.92,0-1.86,0-2.8,0c-0.13-0.15-0.26-0.29-0.38-0.44c-0.28-0.89-0.56-1.78-0.87-2.74
	c-0.67,0.1-1.44,0.22-2.18,0.34c-0.11,1.02-0.23,2.03-0.34,3.03c0,0-0.08,0.17-0.08,0.17c-0.39,0.06-0.78,0.14-1.17,0.18
	c-2.13,0.24-2.82-0.33-3.06-2.52c-0.02-0.23-0.05-0.46-0.08-0.69c-3.59,0.26-3.76-0.8-3.92-3.22c-1.11-0.12-2.18-0.23-3.24-0.34
	c0,0,0.08,0.07,0.08,0.07c-0.42-0.92-0.83-1.84-1.28-2.82c-0.61,0-1.3,0-1.77,0c-0.39,1.13-0.62,2.17-1.12,3.08
	c-0.2,0.37-0.96,0.58-1.47,0.61c-1.59,0.09-2.36-1.11-2.29-3.23c-1-0.17-2.17-0.06-2.89-0.57c-0.73-0.52-1.03-1.66-1.51-2.51
	c-0.79-0.11-1.71-0.24-2.63-0.37c-0.12-0.11-0.24-0.22-0.37-0.33c-0.21-0.91-0.42-1.82-0.64-2.75c-0.7,0-1.47,0-2.14,0
	c-0.25,1-0.39,2.05-0.8,2.98c-0.16,0.37-0.91,0.62-1.42,0.69c-1.45,0.2-2.1-0.52-2.78-2.87c-3-0.21-3.34-0.5-3.83-3.22
	c-1.17-0.28-2.29-0.56-3.42-0.83l0.08,0.08c-0.11-0.43-0.34-0.87-0.29-1.28c0.07-0.6,0.34-1.18,0.53-1.77
	c0.22-1.57,1.79-0.94,2.61-1.53c-0.05-0.74-0.09-1.44-0.13-2.18c-0.86,0.06-1.63,0.11-2.48,0.17c0,1.24,0,2.4,0,3.56
	c-0.23-0.05-0.47-0.19-0.68-0.15c-3.12,0.57-3.64,0.21-4.07-2.84c-2.78,0.15-3.47-0.11-3.88-3.36c-2.52-0.03-3.47-0.58-3.61-2.1
	c-0.14-1.57,0.75-2.32,3.15-2.66c-0.08-0.78-0.16-1.56-0.25-2.42c-0.75,0.05-1.51,0.09-2.27,0.14c0.05,2.55-0.49,3.49-2.06,3.55
	c-1.2,0.05-2.2-0.77-2.33-1.93c-0.17-1.44,0.76-2.25,3.1-2.62c-0.1-0.66-0.21-1.33-0.31-1.97c-1.15-0.28-2.29-0.56-3.42-0.83
	c0,0,0.08,0.08,0.08,0.08c-0.16-0.98-0.32-1.96-0.49-2.95c-2.25,0.15-3.36-0.42-3.64-1.91c-0.27-1.46,0.67-2.4,3.02-2.95
	c0-0.67,0-1.35,0-2.16c-0.97-0.05-1.9-0.09-2.82-0.13c-0.13-0.15-0.25-0.29-0.38-0.44c-0.27-0.89-0.55-1.78-0.85-2.76
	c-0.74-0.17-1.91-0.12-2.59-0.67c-0.69-0.55-0.91-1.7-1.38-2.67c-2.3-0.07-3.28-0.68-3.33-2.19c-0.05-1.42,0.8-2.1,3.13-2.51
	c-0.07-0.38-0.17-0.76-0.2-1.14c-0.04-0.39-0.01-0.78-0.01-1.36c-0.45,0.03-0.81,0.09-1.16,0.07c-1.99-0.14-2.75-1.15-2.41-3.17
	c0.05-0.3,0.06-0.61,0.09-0.92c1.03-0.26,2.06-0.52,3.07-0.78c-0.07-0.85-0.13-1.63-0.2-2.41c-0.9,0.07-1.73,0.13-2.32,0.17
	c-0.18,1.12-0.34,2.09-0.5,3.07c-0.44,0.16-0.87,0.41-1.32,0.47c-1.31,0.19-2.25-0.42-2.54-1.56c-0.31-1.26,0.29-2.3,1.64-2.71
	c0.37-0.11,0.78-0.07,1.05-0.1c0.26-1.14,0.51-2.23,0.76-3.33l-0.05,0.06c0.91-0.34,1.83-0.68,2.77-1.03
	c-0.05-0.65-0.11-1.4-0.16-1.98c-1.12-0.14-2.11-0.27-3.1-0.4l0.04,0.07c-0.27-1.06-0.55-2.11-0.83-3.2
	c-2.44-0.13-3.36-0.68-3.5-2.13c-0.14-1.51,0.74-2.25,3.16-2.67c-0.12-0.7-0.23-1.4-0.37-2.23c-0.58,0-1.22,0.11-1.79-0.02
	c-1.17-0.28-1.89-1.41-1.76-2.51c0.15-1.21,1.25-2.12,2.52-2.07c0.61,0.02,1.22,0.1,1.83,0.16c0.13,1.02,0.26,2.03,0.39,3.04
	c0.89-0.11,1.57-0.2,2.34-0.29c-0.08-0.82-0.15-1.58-0.18-1.97c-1.02-0.29-1.81-0.51-2.59-0.73c-1.34-0.74-1.05-2.02-1.07-3.2
	c0.14-0.16,0.28-0.32,0.42-0.49c0.87-0.33,1.74-0.65,2.67-1c0-0.63,0-1.32,0-1.77c-1.14-0.29-2.16-0.56-3.19-0.82l0.04-0.04
	c-0.29-0.81-0.58-1.62-0.94-2.62c-0.07,0-0.36,0.01-0.65,0c-2.31-0.1-3.16-1.25-2.52-3.42c0.09-0.3,0.21-0.59,0.32-0.89
	c0,0-0.09,0.09-0.09,0.09c0.87-0.23,1.75-0.47,2.59-0.7c-0.08-0.87-0.16-1.63-0.22-2.25c-0.91,0-1.69,0-2.47,0
	c-0.13-0.17-0.25-0.33-0.38-0.5c-1.47-2.73-0.69-4.04,2.61-4.26c-0.06-0.82-0.12-1.65-0.19-2.47c-2.38,0.1-3.49-0.51-3.69-2.04
	c-0.19-1.43,0.71-2.28,2.99-2.78c0.06-0.91,0.12-1.84,0.18-2.78c0.18-0.18,0.35-0.37,0.53-0.55c0.88-0.23,1.77-0.46,2.67-0.69
	c-0.1-0.69-0.2-1.36-0.3-2.05c-0.86-0.05-1.64-0.09-2.41-0.14c-0.28-0.29-0.57-0.58-0.85-0.88c-0.1-0.92-0.2-1.84-0.3-2.76
	c-3.18,0.66-4.31-0.42-3.64-3.5c0.13-0.15,0.26-0.3,0.39-0.45c0.91-0.21,1.82-0.41,2.72-0.61c-0.07-0.82-0.15-1.67-0.23-2.51
	c-3.04,0.33-3.89-0.59-3.38-3.64l-0.07,0.08c0.94-0.44,1.89-0.88,2.88-1.34c0.07-0.81,0.15-1.72,0.23-2.63
	c0.12-0.11,0.23-0.22,0.33-0.34c0.94-0.27,1.88-0.55,2.74-0.8c0-0.83,0-1.59,0-2.11c-1.16-0.2-2.2-0.39-3.25-0.57l0.08,0.08
	c-0.78-1.42-0.63-2.82,0.15-4.19l-0.05,0.07c0.85-0.08,1.69-0.16,2.47-0.23c0.23-1.01,0.21-2.15,0.74-2.89
	c0.47-0.66,1.56-0.87,2.45-1.32c0-0.59,0-1.36,0-2.14c-0.67,0-1.3,0.1-1.87-0.02c-1.14-0.25-1.91-1.37-1.8-2.47
	c0.11-1.11,1.06-2.05,2.22-2.11c0.7-0.03,1.4,0.07,2.1,0.11c0.13,1.07,0.26,2.13,0.39,3.25c0.85-0.1,1.59-0.2,2.38-0.29
	c-0.06-0.81-0.11-1.48-0.14-1.82c-1.06-0.45-1.85-0.79-2.64-1.12c-1.13-0.44-1.19-1.35-0.93-2.32c0.29-1.07,0.99-1.48,3.12-1.97
	c-0.21-1.34-0.24-2.76,1.31-3.39C497.52,437.15,498.44,436.88,498.99,437.93c1.47,0.22,0.89,1.68,1.49,2.44
	c0.62-0.11,1.24-0.21,1.91-0.33c-0.07-0.74-0.14-1.42-0.21-2.11C501.03,437.93,500.01,437.93,498.99,437.93z M452.47,23.4
	c0.23-1.56,1.89-1.12,2.72-1.88c-0.05-0.65-0.09-1.25-0.15-1.94c-0.83,0.09-1.49,0.17-2.1,0.24c-0.16,1.3-0.3,2.47-0.45,3.62
	c-0.93,0.03-1.86,0.05-2.83,0.08c0.12,1.01,0.2,1.68,0.28,2.34c0.82-0.07,1.51-0.14,2.13-0.19
	C452.21,24.89,452.34,24.14,452.47,23.4z M545.13,335.79c-1.41-0.28-0.88-1.64-1.36-2.45c-0.74,0.07-1.48,0.15-2.22,0.22
	c0.04,0.81,0.08,1.5,0.12,2.23C542.86,335.79,544,335.79,545.13,335.79c0.16,1.12,0.31,2.24,0.47,3.37
	c0.86-0.17,1.61-0.32,2.35-0.46c-0.09-0.8-0.17-1.47-0.21-1.87C546.73,336.42,545.93,336.11,545.13,335.79z M572.35,286.79
	c1.58,0.17,1.16,1.65,1.67,2.65c0.67-0.06,1.34-0.13,1.92-0.18c0-0.78,0-1.4,0-2.03C574.67,287.08,573.51,286.94,572.35,286.79
	c0-0.98,0-1.96,0-3.07c-1.03,0.16-1.78,0.28-2.55,0.4c0.07,0.88,0.12,1.64,0.16,2.14C570.89,286.47,571.62,286.63,572.35,286.79z
	 M602.23,288.25c0.31-1.4,1.62-0.96,2.52-1.36c0-0.77,0-1.55,0-2.39c-0.92,0.12-1.74,0.23-2.41,0.32c0,0.72,0.01,1.27,0,1.82
	c-0.02,0.54-0.06,1.09-0.1,1.63c-1.03,0.12-2.06,0.25-3.18,0.38c0.18,0.86,0.32,1.52,0.45,2.18c0.8,0,1.48,0,1.9,0
	C601.72,289.87,601.97,289.06,602.23,288.25z M572.23,192.94c0.25,0.9,0.51,1.8,0.78,2.79c0.58-0.08,1.27-0.17,2.09-0.27
	c-0.03-0.74-0.06-1.43-0.09-2c-1.03-0.16-1.94-0.3-2.85-0.44c-0.4-0.99-0.8-1.97-1.23-3.02c-0.58,0.08-1.25,0.18-1.96,0.28
	c0,0.83,0,1.6,0,2.18C570.12,192.63,571.18,192.79,572.23,192.94z M581.61,176.93c-0.29,1.6-1.79,1.24-2.81,1.73
	c0.06,0.7,0.11,1.39,0.18,2.19c0.81-0.1,1.54-0.2,2.11-0.27c0.18-1.36,0.33-2.53,0.5-3.69c0.95,0,1.89,0,2.88,0
	c-0.17-0.98-0.28-1.67-0.39-2.34c-0.75,0.05-1.45,0.1-2.11,0.15C581.85,175.44,581.73,176.19,581.61,176.93z M618.56,318.94
	c-0.11-0.97-0.23-1.95-0.34-2.9c-0.91,0.05-1.65,0.09-2.38,0.13c0.07,0.91,0.13,1.69,0.18,2.3c0.93,0.15,1.75,0.29,2.58,0.43
	c0.56,0.86,1.13,1.72,1.81,2.75c0.37-0.02,1.06-0.06,1.91-0.12c-0.1-0.83-0.2-1.56-0.28-2.17
	C620.73,319.21,619.64,319.08,618.56,318.94z M608.95,240.75c0.25-1.44,1.63-0.94,2.55-1.46c-0.06-0.71-0.12-1.45-0.19-2.24
	c-0.92,0.1-1.68,0.19-2.36,0.26c0,1.27,0,2.36,0,3.46c-1.04,0.12-2.08,0.25-3.11,0.37c0.06,0.95,0.1,1.65,0.14,2.37
	c0.85-0.07,1.62-0.13,2.12-0.17C608.42,242.36,608.68,241.56,608.95,240.75z M328.07,44.38c-1.01,0.27-2.02,0.53-3.06,0.81
	c0.13,0.75,0.26,1.49,0.39,2.22c0.81-0.05,1.5-0.09,2.1-0.13c0.17-1,0.33-1.98,0.5-2.96c0.93-0.36,1.85-0.72,2.74-1.06
	c0-0.73,0-1.44,0-2.16c-0.9,0.07-1.73,0.14-2.36,0.19C328.26,42.4,328.16,43.39,328.07,44.38z M447.23,45.59
	c-1.02-0.03-2.04-0.07-3.09-0.1c0.2,0.97,0.35,1.73,0.49,2.42c0.82,0,1.57,0,2.17,0C446.97,47.05,447.1,46.32,447.23,45.59
	c0.18-1.68,1.66-1.33,2.75-1.74c-0.05-0.66-0.11-1.35-0.18-2.15c-0.75,0.06-1.44,0.11-2.13,0.17
	C447.52,43.14,447.38,44.36,447.23,45.59z M429.99,60.78c-0.12-0.9-0.24-1.81-0.37-2.8c-0.82,0.08-1.48,0.14-2.15,0.2
	c0,0.83,0,1.52,0,2.09c0.92,0.17,1.74,0.32,2.56,0.47c0.56,0.84,1.13,1.69,1.84,2.75c0.3-0.02,0.99-0.06,1.81-0.11
	c-0.08-0.82-0.15-1.54-0.21-2.15C432.16,61.07,431.07,60.93,429.99,60.78z M616.15,406.3c0.29-1.41,1.61-0.98,2.51-1.37
	c0-0.78,0-1.55,0-2.34c-0.87,0.08-1.64,0.14-2.49,0.22c0,1.21,0,2.36,0,3.51c-1.04,0.12-2.09,0.24-3.13,0.36
	c0.05,0.92,0.1,1.61,0.14,2.34c0.87-0.06,1.64-0.11,2.17-0.15C615.63,407.92,615.89,407.11,616.15,406.3z M488.75,62.89
	c0.26,0.95,0.51,1.9,0.77,2.86c0.79-0.05,1.53-0.11,2.3-0.16c-0.11-0.87-0.21-1.63-0.28-2.17c-1.06-0.17-1.96-0.32-2.86-0.46
	c-0.4-0.92-0.79-1.84-1.27-2.94c-0.55,0.09-1.28,0.2-1.93,0.3c0,0.89,0,1.59,0,2.14C486.63,62.61,487.69,62.75,488.75,62.89z
	 M653.52,591.03c0.25-1.59,1.86-1.15,2.77-1.81c-0.03-0.69-0.06-1.38-0.1-2.08c-0.83,0.06-1.58,0.12-2.19,0.16
	c-0.16,1.32-0.3,2.55-0.46,3.76c-0.96,0-1.92,0-2.94,0c0.17,0.99,0.3,1.73,0.43,2.47c0.79-0.08,1.47-0.15,2.06-0.21
	C653.25,592.52,653.38,591.77,653.52,591.03z M652.14,529.63c0.26-1.52,1.84-0.85,2.61-1.5c-0.06-0.8-0.11-1.54-0.16-2.28
	c-0.91,0.1-1.68,0.19-2.44,0.27c0,1.24,0,2.39,0,3.53c-1.04,0.11-2.08,0.22-3.1,0.33c0.05,0.96,0.09,1.66,0.13,2.38
	c0.85-0.06,1.62-0.11,2.16-0.14C651.64,531.27,651.89,530.45,652.14,529.63z M569.48,337.66c1.68,0.19,1.08,1.82,1.67,2.72
	c0.72-0.06,1.39-0.12,2.13-0.18c-0.07-0.84-0.13-1.51-0.19-2.15C571.79,337.91,570.64,337.79,569.48,337.66c0-0.96,0-1.92,0-2.79
	c-1.17-0.12-2.08-0.21-2.99-0.3c-0.17-0.15-0.33-0.3-0.5-0.45c-0.5-1.15-0.47-2.3,0.03-3.44c0,0-0.08,0.09-0.08,0.09
	c1-0.32,2-0.63,3.03-0.96c-0.1-0.7-0.23-1.51-0.33-2.21c-0.97-0.08-1.81-0.16-2.66-0.23c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c0.95-0.28,1.9-0.57,2.68-0.8c0.22-0.96,0.18-2.14,0.74-2.81
	c0.55-0.66,1.72-0.81,2.66-1.2c-0.12-0.63-0.26-1.39-0.4-2.13c-0.96-0.08-1.89-0.16-2.82-0.25c-0.15-0.13-0.3-0.27-0.45-0.4
	c-0.04-1.13-0.08-2.25-0.12-3.38c0.19-0.16,0.37-0.32,0.56-0.48c1,0.08,2.2-0.16,2.93,0.32c0.71,0.46,0.94,1.67,1.41,2.61
	c0.55-0.07,1.24-0.15,1.99-0.24c-0.05-0.79-0.1-1.47-0.15-2.14c-3.14-0.23-3.73-0.83-3.51-3.5c0.08-0.17,0.15-0.34,0.23-0.52
	c0.91-0.42,1.81-0.84,2.76-1.28c-0.04-0.55-0.09-1.17-0.14-1.76c-0.91-0.09-1.77-0.17-2.63-0.25c-0.18-0.19-0.35-0.37-0.53-0.56
	c-0.25-0.89-0.49-1.79-0.74-2.7c-0.76,0.04-1.6,0.09-2.24,0.12c-0.12,1.13-0.23,2.13-0.33,3.12c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.84,0.12-1.69,0.24-2.57,0.36c0,0.88,0,1.75,0,2.61c-0.19,0.16-0.38,0.32-0.57,0.48c-0.97,0.95-2.14,0.54-3.26,0.45
	c-0.37-1.01-0.74-2.01-1.13-3.08c-0.64,0.12-1.38,0.26-2.07,0.38c0,0.85,0,1.55,0,2.32c0.59-0.03,1.13-0.13,1.65-0.08
	c0.54,0.06,1.07,0.26,1.6,0.4c0.25,0.93,0.5,1.87,0.73,2.71c0.94,0.1,1.77,0.18,2.6,0.27c0.15,0.13,0.3,0.27,0.45,0.4
	c0.16,0.98,0.31,1.96,0.47,2.94c-0.19,0.16-0.38,0.32-0.57,0.48c-0.85,0.29-1.69,0.58-2.43,0.83c-0.11,0.97-0.23,1.95-0.34,2.94
	c-0.11,0.15-0.22,0.31-0.33,0.46c-0.47,0.08-0.93,0.21-1.4,0.23c-1.83,0.09-2.47-0.69-2.78-3.32c-0.81,0.14-1.62,0.28-2.4,0.42
	c0.07,0.89,0.14,1.66,0.18,2.14c0.99,0.2,2.05,0.1,2.58,0.58c0.74,0.67,1.08,1.77,1.65,2.8c0.74,0,1.61,0,2.48,0
	c0.17,0.18,0.34,0.36,0.5,0.53c0.36,1.12,0.46,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45c-0.31,0.04-0.63,0.13-0.94,0.1
	c-0.76-0.08-1.77,0.03-2.2-0.41c-0.65-0.67-0.9-1.73-1.33-2.66c-0.62,0.06-1.4,0.14-2.2,0.23c0.07,0.44,0.15,0.82,0.18,1.2
	c0.03,0.44,0.01,0.89,0.01,1.35c2.77-0.35,3.52,0.22,3.78,2.68c0.02,0.23,0.08,0.46,0.12,0.7c-0.07,0.06-0.13,0.12-0.2,0.18
	c-0.98,0.44-1.96,0.88-3.13,1.4c0.05,0.48,0.11,1.14,0.18,1.77c1.01,0.11,1.86,0.2,2.71,0.29c0.15,0.13,0.3,0.27,0.45,0.4
	c0.27,0.93,0.55,1.86,0.79,2.7c0.91,0.09,1.75,0.17,2.59,0.26c0.15,0.13,0.3,0.27,0.45,0.4c0.57,0.93,0.95,2.03,0.2,2.87
	c-0.66,0.73-1.73,1.09-2.75,1.69c0.05,0.51,0.13,1.27,0.2,2.03c0.86-0.04,1.62-0.08,2.37-0.12c-0.16-3.07,0.17-3.48,2.94-3.83
	c0.13-1.04,0.25-2.04,0.38-3.03c0.13-0.18,0.27-0.36,0.4-0.53C567.71,337.59,568.67,336.73,569.48,337.66z M660.72,590.56
	c0.35-1.71,1.88-1.23,3.01-1.6c-0.14-0.74-0.27-1.5-0.41-2.26c-0.78,0.05-1.52,0.1-2.11,0.13c-0.16,1.34-0.3,2.56-0.46,3.77
	c-0.94,0.03-1.88,0.06-2.81,0.1c0.09,0.99,0.15,1.67,0.22,2.36c0.85-0.06,1.59-0.11,2.15-0.15
	C660.45,592.03,660.59,591.29,660.72,590.56z M512.94,57.49c-0.16-1.04-0.32-2.07-0.47-3.08c-0.89,0-1.59,0-2.29,0
	c0,0.87,0,1.69,0,2.6c1.04-0.12,2.1-0.73,2.78,0.47c0.52,0.78,1.04,1.57,1.61,2.44c0.47,0,1.16,0,1.98,0
	c-0.07-0.92-0.13-1.75-0.19-2.44C515.11,57.5,514.02,57.5,512.94,57.49z M481.29,60.4c-1.08,0.09-2.15,0.17-3.26,0.26
	c0.12,0.92,0.23,1.74,0.33,2.5c0.89-0.07,1.68-0.14,2.47-0.2C480.92,62.08,480.13,61.05,481.29,60.4c0.22-1.52,1.74-0.96,2.6-1.52
	c-0.1-0.74-0.19-1.41-0.27-2.02c-0.85,0-1.54,0-2.32,0C481.29,58.09,481.29,59.24,481.29,60.4z M628.33,382.94
	c0.95-0.3,1.9-0.6,2.82-0.88c0-0.79,0-1.49,0-2.26c-0.89,0.06-1.72,0.11-2.43,0.16c-0.12,1.07-0.22,2.06-0.33,3.05
	c-1.06,0.53-2.11,1.15-3.33,0.39c-0.51-0.91-1.01-1.83-1.58-2.86c-0.52,0.04-1.29,0.1-2.06,0.15c0.23,2.81-0.18,3.35-3.01,4.03
	c0,0.77,0,1.53,0,2.3c0.93-0.05,1.78-0.09,2.63-0.13c-0.52-3.07,0.53-4.09,3.61-3.58c0.16,0.03,0.32,0.01,0.47,0.03
	c0.2,0.97,0.4,1.94,0.6,2.95c0.79-0.04,1.56-0.08,2.21-0.11C628.07,385.06,628.2,384,628.33,382.94z M524.48,67.04
	c-0.1-1.02-0.2-2.03-0.3-3.09c-0.95,0.22-1.6,0.38-2.21,0.53c0,0.81,0,1.49,0,2.12c0.92,0.15,1.74,0.28,2.56,0.4
	c1.38,0.52,1.09,1.88,1.31,2.92c0.91-0.12,1.66-0.22,2.42-0.33c-0.1-0.8-0.19-1.49-0.28-2.17
	C526.78,67.29,525.63,67.17,524.48,67.04z M545.38,97.56c-0.21-0.9-0.41-1.79-0.61-2.64c-0.94-0.09-1.85-0.18-2.76-0.27
	c-0.16-0.15-0.33-0.3-0.49-0.44c-0.46-1.12-0.51-2.25-0.01-3.37c0.94-0.31,1.87-0.62,2.9-0.96c-0.14-0.66-0.29-1.4-0.43-2.07
	c-0.84,0-1.57,0-2.11,0c-0.11,1.12-0.2,2.1-0.3,3.09c-0.97,0.27-1.93,0.54-2.88,0.8c-0.07,0.78-0.15,1.64-0.23,2.5
	c-0.11,0.16-0.22,0.31-0.32,0.47c-1.01,0.97-2.21,0.62-3.37,0.49c-0.37-0.94-0.75-1.88-1.16-2.93c-0.7,0-1.49,0-2.27,0
	c-0.15-0.2-0.3-0.39-0.46-0.59c-0.87-0.92-1.08-1.96-0.37-3.03c0.77-1.15,1.87-1.37,3.15-0.9c0.22,0.08,0.47,0.07,0.7,0.11
	c0.24,0.97,0.48,1.94,0.72,2.94c0.73-0.07,1.42-0.14,2.12-0.21c-0.05-0.9-0.09-1.68-0.12-2.38c-0.98-0.1-1.89-0.19-2.8-0.28
	c-0.47-0.93-0.93-1.85-1.44-2.87c-0.74,0-1.66,0-2.58,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.18-0.87-0.36-1.75-0.51-2.44
	c-1.02,0-1.94,0-2.85,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.08-1.06-0.17-2.12-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.18
	c0.99-0.23,1.98-0.45,2.96-0.68c-0.12-0.79-0.23-1.47-0.35-2.25c-0.86,0-1.65,0-2.44,0c-0.15-0.19-0.3-0.39-0.45-0.58
	c-0.36-0.84-0.72-1.69-1.09-2.55c-0.84-0.06-1.77-0.14-2.7-0.21c-0.14-0.13-0.29-0.26-0.43-0.38c-0.14-0.98-0.28-1.95-0.4-2.82
	c-0.99-0.05-2-0.1-3-0.15c-0.14-0.13-0.29-0.26-0.43-0.38c-0.03-1.16-0.05-2.32-0.08-3.48c0.96-0.29,1.91-0.59,2.82-0.87
	c0-0.8,0-1.51,0-2.26c-0.88,0.04-1.72,0.08-2.39,0.11c-0.14,1.09-0.26,2.08-0.38,3.07c-1.08,0.56-2.17,0.99-3.39,0.43
	c0,0,0.07,0.06,0.07,0.06c-0.5-1-1-1.99-1.52-3.02c-0.55,0.07-1.21,0.16-1.91,0.26c0,1.04,0,1.9,0,2.75
	c-0.15,0.16-0.3,0.32-0.46,0.49c-0.95,0.23-1.91,0.45-2.89,0.69c0,0.95,0,1.8,0,2.66c-0.16,0.17-0.31,0.33-0.47,0.5
	c-0.9,0.22-1.81,0.44-2.79,0.68c0.04,0.75,0.08,1.52,0.12,2.23c0.97,0,1.84,0,2.71,0c0.31,0.21,0.61,0.43,0.92,0.64
	c0.04,0.99,0.09,1.98,0.13,2.87c0.99,0,1.89,0,2.8,0c0.15,0.09,0.3,0.18,0.46,0.27c0.39,0.89,0.78,1.78,1.24,2.82
	c0.73,0,1.65,0,2.56,0c0.17,0.18,0.34,0.36,0.51,0.53c0.36,1.12,0.46,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45
	c-2.61,0.37-3.46,0.09-3.97-1.41c-0.17-0.5-0.17-1.06-0.25-1.57c-0.88,0.08-1.66,0.15-2.46,0.23c0.07,0.44,0.16,0.82,0.19,1.2
	c0.03,0.43,0.01,0.86,0.01,1.31c2.85-0.29,3.57,0.26,3.82,2.71c0.02,0.24,0.06,0.47,0.09,0.7c-0.16,0.07-0.32,0.15-0.49,0.22
	c-0.88,0.44-1.76,0.88-2.85,1.42c0.04,0.43,0.11,1.09,0.17,1.71c1.02,0.11,1.87,0.2,2.72,0.29c0.16,0.14,0.31,0.28,0.47,0.42
	c0.15,0.97,0.31,1.94,0.46,2.92c-0.2,0.17-0.4,0.34-0.6,0.51c-0.97,1-2.14,0.5-3.25,0.44c-0.37-0.94-0.75-1.87-1.17-2.94
	c-0.69,0-1.47,0-2.26,0c-0.16-0.2-0.32-0.4-0.47-0.6c-0.72-0.75-0.97-1.58-0.59-2.6c0.53-1.43,1.86-1.5,3.07-1.72
	c-0.05-0.85-0.09-1.54-0.14-2.28c-1.01,0-1.94,0-2.87,0c-0.2-0.3-0.4-0.6-0.6-0.91c-0.17-0.83-0.33-1.66-0.47-2.33
	c-0.97-0.12-1.82-0.22-2.67-0.32c-0.12-0.09-0.24-0.16-0.37-0.24c-0.32-0.9-0.65-1.81-0.96-2.7c-0.76,0.04-1.51,0.08-2.1,0.11
	c-0.12,1.05-0.24,2.02-0.35,3c-0.13,0.17-0.25,0.34-0.38,0.5c-1,0.01-2,0.02-2.99,0.03c-0.17-0.18-0.35-0.37-0.52-0.55
	c-0.24-0.88-0.47-1.75-0.7-2.62c-0.81-0.06-1.72-0.13-2.62-0.19c-0.17-0.18-0.34-0.36-0.5-0.54c-0.14-0.97-0.29-1.94-0.43-2.91
	c0.14-0.13,0.28-0.25,0.42-0.38c0.88-0.29,1.77-0.59,2.68-0.89c-0.07-0.71-0.16-1.55-0.22-2.18c-1.09-0.13-2.02-0.23-2.94-0.34
	c0,0,0.09,0.08,0.09,0.08c-0.44-0.97-0.88-1.94-1.35-2.97c-0.68,0.07-1.35,0.14-2.08,0.21c0,1,0,1.87,0,2.73
	c-0.23,0.31-0.45,0.62-0.68,0.93c-0.81,0-1.62,0-2.13,0c-0.28,1.21-0.49,2.07-0.69,2.93c-0.1,0.09-0.2,0.19-0.3,0.3
	c-1.04,0.2-2.08,0.4-3.12,0.6c-0.16-0.31-0.32-0.61-0.48-0.92c-0.1-0.96-0.21-1.92-0.31-2.88c0.17-0.14,0.33-0.28,0.5-0.43
	c0.89-0.26,1.79-0.52,2.7-0.79c-0.1-0.73-0.2-1.41-0.28-1.98c-1-0.25-2.1-0.23-2.73-0.76c-0.69-0.6-0.95-1.7-1.43-2.64
	c-0.61,0.03-1.37,0.07-2.03,0.1c-0.07,0.94-0.13,1.81-0.19,2.67c-0.12,0.17-0.25,0.34-0.37,0.51c-0.94,0.28-1.88,0.57-2.69,0.81
	c-0.17,0.88-0.09,2.06-0.63,2.77c-0.53,0.69-1.69,0.91-2.46,1.28c-0.13,0.87-0.27,1.77-0.41,2.68c-0.14,0.18-0.28,0.36-0.42,0.54
	c-0.99-0.03-2.2,0.28-2.9-0.19c-0.76-0.51-1.05-1.74-1.56-2.7c-0.77,0-1.63,0-2.49,0c-0.19-0.3-0.38-0.61-0.56-0.91
	c-0.17-0.9-0.33-1.8-0.5-2.7c-0.83,0.16-1.65,0.31-2.22,0.42c-0.16,1.03-0.28,1.87-0.41,2.7c-0.17,0.17-0.34,0.34-0.51,0.52
	c-1.14,0.77-2.27,0.42-3.39-0.05l0.09,0.08c-0.26-0.97-0.52-1.94-0.79-2.95c-0.7,0.11-1.53,0.23-2.23,0.34
	c-0.13,1.04-0.25,2.02-0.37,3.01c-0.13,0.17-0.25,0.34-0.38,0.5c-0.92,0.11-1.84,0.23-2.64,0.32c-0.09,1.03-0.18,2.02-0.27,3.01
	c-0.18,0.15-0.36,0.3-0.55,0.44c-0.88,0.14-1.77,0.28-2.53,0.41c-0.14,1.02-0.28,2.05-0.41,3.08c0,0,0.08-0.09,0.08-0.09
	c-1,0.34-2,0.67-3.04,1.02c0.11,0.66,0.24,1.43,0.37,2.16c0.93,0.1,1.79,0.19,2.65,0.29c0.16,0.14,0.32,0.28,0.48,0.42
	c0.27,0.93,0.55,1.86,0.79,2.69c0.9,0.1,1.74,0.19,2.58,0.27c0.15,0.13,0.29,0.26,0.44,0.39c0.59,0.93,0.96,2.05,0.2,2.88
	c-0.67,0.73-1.76,1.07-2.79,1.66c0.04,0.52,0.11,1.33,0.18,2.19c0.41-0.09,0.72-0.19,1.03-0.21c0.47-0.03,0.94-0.01,1.48-0.01
	c-0.04-0.32-0.1-0.62-0.11-0.93c-0.04-2.03,0.66-2.78,2.62-2.83c0.4-0.01,0.79,0.03,1.19,0.05c0.15,0.18,0.31,0.36,0.46,0.54
	c0.19,0.83,0.39,1.67,0.6,2.59c0.73-0.11,1.47-0.22,2.13-0.31c0-0.88,0-1.58,0-2.28c0.23-0.31,0.45-0.62,0.68-0.93
	c1.08-0.67,2.19-0.42,3.31-0.12c0.15,0.19,0.3,0.38,0.45,0.57c-0.06,0.97-0.11,1.95-0.17,2.92c-0.06,0.06-0.13,0.12-0.19,0.19
	c-0.9,0.36-1.79,0.71-2.65,1.05c0.04,0.77,0.08,1.52,0.12,2.27c0.93-0.05,1.85-0.1,2.78-0.15c0.16,0.31,0.31,0.61,0.47,0.92
	c0.25,0.86,0.5,1.72,0.7,2.4c0.9,0.16,2.04,0.04,2.63,0.56c0.74,0.65,1.03,1.81,1.55,2.81c0.59-0.08,1.29-0.17,1.96-0.26
	c0.09-0.93,0.17-1.78,0.25-2.64c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.17,2.22-0.35,3.32-0.52c0.17,0.18,0.34,0.36,0.51,0.54
	c0.16,0.95,0.32,1.9,0.45,2.75c1.06,0.05,2.06,0.11,3.07,0.16c0.1,0.06,0.2,0.12,0.29,0.18c0.15,0.99,0.31,1.97,0.47,3.02
	c0.76-0.17,1.57-0.35,2.41-0.54c0-0.95,0-1.82,0-2.68c0.17-0.18,0.35-0.35,0.52-0.53c1.04-0.14,2.09-0.28,3.13-0.42
	c0.17,0.3,0.34,0.61,0.5,0.91c0.53,0.75,0.93,1.69,0.28,2.38c-0.78,0.83-1.86,1.38-2.89,2.11c0,0.46,0,1.16,0,1.69
	c1.13,0.19,2.17,0.37,3.2,0.54c0.18,0.08,0.35,0.16,0.53,0.25c0.05,0.94,0.1,1.87,0.13,2.6c1.17,0.21,2.23,0.39,3.3,0.58
	c0,0-0.1-0.08-0.1-0.08c0.29,0.93,0.57,1.87,0.84,2.73c0.92,0.1,1.76,0.19,2.59,0.28c0.14,0.13,0.29,0.26,0.43,0.38
	c1.11,2.78,0.5,3.77-2.49,4.17c-0.11,1.03-0.21,2.02-0.32,3.01c-0.17,0.13-0.33,0.25-0.5,0.38c-2.87,0.69-3.55,0.24-4.17-2.83
	c-0.74,0.06-1.49,0.12-2.28,0.18c0.11,0.86,0.19,1.54,0.25,2.07c1.03,0.28,2.13,0.27,2.74,0.81c0.68,0.6,0.91,1.69,1.41,2.7
	c0.74,0,1.61,0,2.47,0c0.17,0.18,0.34,0.36,0.52,0.54c0.42,1.14,0.46,2.27-0.05,3.4c0,0,0.08-0.09,0.08-0.09
	c-0.99,0.34-1.97,0.69-2.99,1.04c0.11,0.67,0.24,1.42,0.36,2.14c0.93,0.11,1.77,0.21,2.62,0.3c0.15,0.13,0.3,0.27,0.45,0.4
	c0.28,0.93,0.56,1.86,0.81,2.7c0.89,0.09,1.74,0.17,2.58,0.26c0.15,0.13,0.3,0.27,0.45,0.4c0.27,0.88,0.55,1.75,0.73,2.33
	c1.13,0.2,2.12,0.37,3.11,0.54c0.16,0.07,0.31,0.14,0.47,0.21c0,0.99,0,1.98,0,3.04c1-0.19,1.82-0.34,2.68-0.5c0-0.78,0-1.53,0-2.28
	c0.23-0.31,0.45-0.62,0.68-0.93c1.08-0.67,2.19-0.42,3.31-0.12c0.15,0.19,0.3,0.38,0.45,0.57c-0.06,0.97-0.11,1.95-0.17,2.92
	c-0.06,0.06-0.13,0.12-0.19,0.19c-0.85,0.34-1.7,0.68-2.53,1.01c-0.1,0.79-0.22,1.68-0.34,2.58c-0.18,0.18-0.35,0.36-0.53,0.54
	c-0.88,0.24-1.76,0.47-2.59,0.69c0.08,0.81,0.16,1.57,0.23,2.32c0.94-0.05,1.8-0.09,2.66-0.13c0.16,0.31,0.32,0.61,0.47,0.92
	c0.57,0.76,0.95,1.7,0.29,2.41c-0.74,0.8-1.8,1.31-2.88,2.05c0.03,0.47,0.07,1.22,0.1,1.73c1.16,0.2,2.16,0.37,3.15,0.54
	c0.16,0.07,0.33,0.15,0.49,0.22c0,0.94,0,1.88,0,2.59c1.29,0.22,2.36,0.41,3.43,0.6c0,0-0.1-0.08-0.09-0.08
	c0.29,0.93,0.57,1.87,0.84,2.73c0.92,0.09,1.75,0.18,2.59,0.27c0.15,0.13,0.3,0.27,0.45,0.4c0.58,0.94,0.96,2.06,0.18,2.89
	c-0.68,0.73-1.77,1.08-2.77,1.64c0.04,0.53,0.11,1.3,0.18,2.12c0.83-0.06,1.66-0.11,2.49-0.17c-0.26-3.05,0.1-3.49,2.92-3.87
	c0.12-1.05,0.24-2.06,0.36-3.07c0.11-0.16,0.23-0.32,0.34-0.48c1.01,0.06,2.24-0.22,2.96,0.26c0.75,0.51,1.01,1.76,1.5,2.73
	c0.65-0.06,1.42-0.13,2.22-0.2c-0.12-0.83-0.23-1.58-0.34-2.31c-2.91-0.09-3.29-0.41-3.87-3.16c-0.94-0.08-1.87-0.16-2.81-0.24
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.42-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09c1.11-0.5,2.21-1.08,3.43-0.3
	c0.09,0.08,0.18,0.16,0.28,0.23c0.33,0.86,0.66,1.71,1.01,2.61c0.68-0.07,1.52-0.16,2.08-0.21c0.16-0.99,0.03-2.04,0.49-2.58
	c0.65-0.75,1.74-1.12,2.77-1.72c-0.03-0.48-0.07-1.17-0.11-1.93c-1,0-1.93,0-2.86,0c-0.19-0.3-0.37-0.61-0.56-0.91
	c-0.17-0.75-0.35-1.5-0.51-2.18c-0.89-0.08-1.75-0.16-2.6-0.24c-0.18-0.19-0.36-0.38-0.54-0.57c-1.03-2.75-0.27-3.88,2.6-3.87
	c0.24,0,0.48-0.02,0.71-0.03c0.18,0.2,0.36,0.4,0.53,0.61c0.19,0.82,0.39,1.65,0.6,2.53c0.78-0.08,1.52-0.16,2.34-0.25
	c0-1.06,0-2,0-2.94c0.06-0.09,0.12-0.18,0.18-0.27c0.96-0.22,1.93-0.45,2.92-0.68c0-0.75,0-1.5,0-2.25
	c0.24-0.31,0.47-0.62,0.71-0.94c1.13-0.76,2.26-0.32,3.4-0.02c0.25,0.93,0.5,1.86,0.73,2.71c0.94,0.08,1.77,0.16,2.6,0.23
	c0.16,0.15,0.33,0.3,0.49,0.45c0.55,0.95,0.77,1.92,0.2,2.94c-0.69,1.22-1.92,1.12-3.12,1.13c0.23,0.95,0.42,1.76,0.62,2.58
	c0.98,0,1.85,0,2.72,0c0.17,0.18,0.35,0.37,0.52,0.55c0.36,1.12,0.46,2.23,0,3.35c-0.11,0.15-0.22,0.31-0.32,0.46
	c-0.92,0.1-1.84,0.2-2.61,0.28c-0.16,1.06-0.31,2.11-0.47,3.16c0,0,0.08-0.09,0.08-0.09c-0.99,0.33-1.97,0.67-3.05,1.03
	c0.11,0.62,0.25,1.43,0.37,2.15c0.98,0.1,1.82,0.19,2.66,0.28c0.16,0.14,0.32,0.28,0.48,0.42c0.61,0.91,0.69,1.88,0.33,2.9
	c-0.12,0.17-0.24,0.33-0.36,0.5c-0.99,0.29-1.98,0.57-2.96,0.86c0.04,0.79,0.09,1.85,0.14,2.91c-0.17,0.16-0.35,0.32-0.52,0.48
	c-0.89,0.27-1.78,0.53-2.66,0.8c0.11,0.72,0.21,1.4,0.28,1.9c1.06,0.28,2.15,0.27,2.77,0.81c0.68,0.59,0.92,1.69,1.41,2.68
	c0.75,0,1.62,0,2.48,0c0.18,0.18,0.35,0.37,0.53,0.55c0.36,1.1,0.5,2.21,0,3.31c-0.12,0.16-0.24,0.33-0.37,0.49
	c-0.82,0.13-1.64,0.27-2.42,0.4c-0.08,0.88-0.15,1.71-0.22,2.54c-0.13,0.18-0.26,0.35-0.39,0.53c-1.04,0.91-2.2,0.67-3.38,0.37
	c0,0,0.09,0.08,0.09,0.08c-0.41-1.04-0.83-2.08-1.24-3.13c-0.66,0.13-1.39,0.28-2.15,0.43c0,0.96,0,1.81,0,2.66
	c-0.25,0.31-0.5,0.63-0.75,0.94c-0.84,0-1.69,0-2.54,0c0,0.98,0,1.75,0,2.35c1.13,0.19,2.17,0.36,3.21,0.54
	c0.18,0.09,0.35,0.17,0.53,0.26c0.04,0.98,0.09,1.97,0.12,2.82c1,0,1.9,0,2.81,0c0.16,0.11,0.33,0.22,0.49,0.33
	c0.39,0.91,0.79,1.81,1.2,2.74c0.58-0.06,1.19-0.13,1.72-0.18c0.14-1.04,0.27-2.03,0.39-3.02c0.11-0.16,0.23-0.32,0.34-0.48
	c1.02,0.01,2.03,0.03,3.05,0.04c0.15,0.14,0.31,0.27,0.46,0.41c0.58,0.93,0.95,2.05,0.19,2.88c-0.66,0.73-1.75,1.08-2.77,1.67
	c0.04,0.49,0.11,1.26,0.17,1.98c0.85,0,1.62,0,2.47,0c-0.04-0.36-0.09-0.67-0.09-0.98c-0.02-2.13,0.66-2.79,2.86-2.8
	c0.32,0,0.63,0.01,0.95,0.01c0.16,0.19,0.33,0.38,0.49,0.57c0.2,0.83,0.4,1.67,0.58,2.43c0.9,0,1.76,0,2.62,0
	c0.16,0.31,0.32,0.61,0.48,0.92c0.24,0.85,0.48,1.7,0.74,2.58c0.78-0.04,1.7-0.08,2.62-0.13c0.17,0.3,0.33,0.61,0.5,0.91
	c0.24,0.86,0.48,1.73,0.66,2.36c0.99,0.23,2.16,0.2,2.83,0.75c0.68,0.57,0.89,1.72,1.32,2.67c0.61-0.08,1.3-0.18,1.98-0.27
	c0.08-0.92,0.16-1.78,0.23-2.63c0.13-0.17,0.26-0.35,0.39-0.52c0.94-0.28,1.89-0.55,2.78-0.81c0.07-0.81,0.13-1.66,0.2-2.51
	c0.13-0.17,0.25-0.34,0.38-0.5c1.04-0.26,2.08-0.53,2.98-0.76c-0.06-0.95-0.13-1.97-0.2-2.98c0.18-0.16,0.37-0.33,0.55-0.49
	c0.89-0.25,1.79-0.5,2.68-0.76c-0.13-0.7-0.26-1.37-0.35-1.85c-1.04-0.11-1.89-0.2-2.75-0.29c-0.17-0.16-0.35-0.31-0.52-0.47
	c-0.16-0.9-0.32-1.8-0.48-2.68c-0.87-0.07-1.86-0.14-2.85-0.21c-0.17-0.15-0.34-0.31-0.51-0.46c-0.5-1.15-0.47-2.29,0.03-3.44
	l-0.08,0.09c0.94-0.32,1.88-0.64,2.83-0.96c0.05-0.76,0.11-1.61,0.16-2.46c0.15-0.19,0.29-0.37,0.44-0.56
	c1.02-0.24,2.03-0.48,3.05-0.72c-0.15-0.76-0.31-1.58-0.45-2.31c-0.92-0.08-1.77-0.15-2.62-0.22c-0.18-0.19-0.36-0.38-0.55-0.57
	c-0.11-0.94-0.21-1.88-0.32-2.83c0.13-0.17,0.25-0.33,0.38-0.5c0.88-0.27,1.76-0.55,2.55-0.79c0.07-0.95,0.14-1.94,0.21-2.94
	c0.19-0.16,0.37-0.32,0.56-0.48c0.99,0.07,2.24-0.2,2.91,0.31c0.73,0.55,0.93,1.82,1.39,2.81c0.64-0.14,1.45-0.32,2.19-0.48
	c0-0.84,0-1.54,0-2.25c0.24-0.31,0.49-0.63,0.73-0.94c0.89-0.22,1.77-0.43,2.63-0.64c0-0.73,0-1.49,0-2.26
	c0.24-0.31,0.48-0.63,0.72-0.94c1.11-0.71,2.21-0.47,3.32,0.02c0.27,0.88,0.55,1.76,0.82,2.64c0.8,0,1.6,0,2.37,0
	c-0.07-0.95-0.14-1.77-0.2-2.57c-1.05,0-2.05,0-3.05,0c-0.47-1.3-1.38-2.6-0.11-3.95c0.06-0.06,0.13-0.12,0.2-0.18
	c0.78-0.25,1.56-0.49,2.38-0.75c-0.06-0.69-0.11-1.28-0.17-1.94c-1.05,0-1.97,0-2.88,0c-0.16-0.31-0.32-0.61-0.49-0.92
	c-0.15-0.84-0.31-1.69-0.43-2.35c-1.08-0.18-2.13-0.35-3.18-0.52c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.06-0.17-2.11-0.25-3.17
	c0.06-0.06,0.13-0.12,0.19-0.18c0.97-0.22,1.95-0.44,2.9-0.66c-0.05-0.83-0.1-1.59-0.14-2.27c-0.88,0-1.74,0-2.59,0
	c-0.14-0.19-0.29-0.37-0.43-0.56c-0.75-0.81-0.94-1.74-0.64-2.79c0.22-0.31,0.45-0.62,0.67-0.93c0.9-0.22,1.8-0.45,2.66-0.66
	c-0.11-0.74-0.22-1.44-0.34-2.25c-0.8,0-1.59,0-2.38,0c-0.16-0.2-0.32-0.41-0.48-0.61c-1.41-2.53-0.85-3.61,2.41-4.38
	c-0.07-0.71-0.15-1.44-0.22-2.11c-0.87,0-1.57,0-2.28,0c0.19,3.05-0.53,3.77-3.59,3.71c-0.08,0-0.16,0.02-0.24,0.03
	c-0.1-0.07-0.19-0.15-0.28-0.23c-0.28-0.99-0.55-1.99-0.79-2.82c-0.94-0.1-1.77-0.19-2.59-0.27c-0.18-0.19-0.36-0.38-0.54-0.57
	c-0.24-0.87-0.47-1.74-0.71-2.6c-0.8-0.06-1.71-0.12-2.61-0.19c-0.17-0.18-0.34-0.36-0.51-0.54c-0.25-0.79-0.51-1.58-0.76-2.36
	c-0.93,0-1.85,0-2.78,0c-0.16-0.3-0.32-0.61-0.49-0.91c-0.08-1.05-0.17-2.1-0.25-3.15c0.06-0.07,0.13-0.14,0.19-0.21
	c1.02-0.23,2.05-0.46,3.07-0.69c-0.19-0.8-0.34-1.46-0.5-2.11c-0.82-0.13-1.58-0.25-2.33-0.37c-0.16-0.07-0.33-0.15-0.49-0.22
	c-0.38-0.92-0.77-1.84-1.18-2.82c-0.63-0.09-1.4-0.21-2.17-0.32c-0.15-0.07-0.31-0.14-0.46-0.21c-0.67-0.82-1.09-1.68-0.63-2.79
	c0.56-1.35,1.83-1.49,3-1.65c0-1.04,0-1.89,0-2.74c0.18-0.18,0.35-0.36,0.53-0.54c0.89-0.2,1.79-0.4,2.77-0.61
	c-0.07-0.75-0.13-1.49-0.2-2.25c-1,0-1.86,0-2.72,0c-0.2-0.3-0.4-0.61-0.6-0.91c-0.17-0.83-0.35-1.66-0.51-2.45
	c-0.95,0-1.88,0-2.81,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.09-0.97-0.18-1.93-0.27-2.9c0.15-0.13,0.3-0.26,0.46-0.39
	c0.8-0.25,1.6-0.5,2.54-0.8c-0.06-0.68-0.13-1.4-0.18-1.93c-1.28-0.29-2.32-0.53-3.36-0.76L545.38,97.56z M669.41,603.98
	c0.33-1.63,1.95-0.84,2.79-1.38c-0.14-0.79-0.25-1.48-0.39-2.25c-0.79,0.05-1.55,0.09-2.39,0.14c0,1.22,0,2.37-0.01,3.51
	c-1.04,0.13-2.07,0.27-3.11,0.4c0.06,0.93,0.11,1.63,0.15,2.34c0.85-0.07,1.63-0.12,2.11-0.16
	C668.89,605.6,669.15,604.79,669.41,603.98z M542.26,354.46c-0.11-0.99-0.22-1.97-0.34-3c-0.86,0.18-1.53,0.32-2.19,0.45
	c0,0.79,0,1.48,0,2c0.93,0.19,1.74,0.36,2.56,0.52c1.53,0.36,1.02,1.87,1.45,2.82c0.87-0.07,1.62-0.14,2.36-0.2
	c-0.13-0.82-0.24-1.51-0.34-2.18C544.56,354.73,543.41,354.6,542.26,354.46z M431.4,35.37c-0.1-1.02-0.21-2.03-0.32-3.1
	c-0.96,0.23-1.61,0.39-2.19,0.53c0,0.84,0,1.52,0,2.09c0.92,0.16,1.73,0.31,2.55,0.45c1.5,0.44,1.01,1.9,1.41,2.87
	c0.83-0.1,1.6-0.18,2.36-0.27c-0.11-0.8-0.21-1.49-0.3-2.18C433.71,35.63,432.55,35.5,431.4,35.37z M625.67,358.33
	c1.53,0.26,0.78,1.84,1.46,2.62c0.72-0.12,1.4-0.24,2.08-0.36c0-0.83,0-1.59,0-2.26C627.96,358.33,626.81,358.33,625.67,358.33
	c-0.15-1.06-0.3-2.11-0.45-3.2c-0.94,0.06-1.7,0.12-2.41,0.16c0.06,0.91,0.11,1.67,0.16,2.45
	C623.87,357.95,625.03,356.96,625.67,358.33z M601.21,244.59c-0.06-0.98-0.12-1.96-0.17-2.9c-0.9,0-1.67,0-2.45,0
	c0.06,0.87,0.11,1.66,0.16,2.39c0.85,0.17,1.68,0.33,2.5,0.49c1.52,0.4,1.05,1.86,1.45,2.82c0.83-0.08,1.6-0.15,2.4-0.23
	c-0.12-0.81-0.22-1.54-0.32-2.25C603.5,244.8,602.35,244.7,601.21,244.59z M620.55,502.8c1.03-0.21,2.07-0.43,3.14-0.65
	c-0.12-0.78-0.24-1.59-0.35-2.39c-0.89,0.05-1.65,0.09-2.19,0.12c-0.19,1.07-0.36,2.03-0.52,3c-0.89,0.42-1.78,0.83-2.69,1.26
	c0,0.62,0,1.31,0,2.08c0.88-0.05,1.7-0.09,2.38-0.13C620.39,504.92,620.47,503.86,620.55,502.8z M555,118.55
	c0.24,0.96,0.48,1.93,0.72,2.88c0.84-0.05,1.59-0.1,2.33-0.14c-0.12-0.87-0.22-1.65-0.3-2.29c-0.98-0.13-1.89-0.26-2.8-0.38
	c-0.41-1.04-0.83-2.08-1.24-3.11c-0.7,0.16-1.42,0.33-2.14,0.49c0.05,0.89,0.1,1.65,0.13,2.19
	C552.89,118.31,553.95,118.43,555,118.55z M608.26,359.59c-0.95,0.31-1.9,0.62-2.89,0.95c0.04,0.72,0.09,1.42,0.13,2.14
	c0.89,0,1.71,0,2.31,0c0.14-1.15,0.26-2.15,0.38-3.15c0.94-0.33,1.87-0.66,2.86-1.01c-0.05-0.74-0.1-1.49-0.14-2.24
	c-0.94,0.06-1.76,0.12-2.31,0.16C608.47,357.61,608.36,358.6,608.26,359.59z M597.49,237.45c0.96-0.21,1.93-0.43,2.91-0.65
	c0-0.82,0-1.58,0-2.3c-0.9,0-1.73,0-2.31,0c-0.2,1.11-0.37,2.06-0.53,3.03c-0.88,0.41-1.77,0.83-2.65,1.24
	c0.04,0.68,0.09,1.36,0.13,1.99c0.85,0,1.65,0,2.29,0C597.39,239.56,597.44,238.5,597.49,237.45z M618,514.71
	c0.42-1.64,2-1.15,3.06-1.57c-0.18-0.82-0.34-1.57-0.5-2.3c-0.79,0.06-1.48,0.12-2.1,0.16c-0.14,1.27-0.28,2.52-0.43,3.75
	c-0.88,0.04-1.76,0.09-2.72,0.13c0.05,0.95,0.08,1.62,0.12,2.33c0.88-0.04,1.62-0.08,2.2-0.11
	C617.77,516.22,617.88,515.47,618,514.71z M490.89,466.81c-0.36,1.53-1.8,0.91-2.71,1.31c0.04,0.8,0.08,1.57,0.12,2.41
	c0.86-0.12,1.66-0.23,2.36-0.32c0.07-1.26,0.14-2.34,0.21-3.42c0.99-0.14,1.98-0.28,3.02-0.43c-0.08-0.79-0.14-1.47-0.22-2.24
	c-0.86,0.06-1.61,0.1-1.91,0.12C491.41,465.3,491.15,466.05,490.89,466.81z M277.34,88.11c1.05-0.15,2.09-0.3,3.16-0.46
	c-0.06-0.9-0.12-1.66-0.17-2.43c-0.85,0.11-1.61,0.2-2.1,0.26c-0.32,0.99-0.58,1.83-0.83,2.68c-0.83,0.5-1.66,1.01-2.61,1.58
	c0.04,0.54,0.08,1.23,0.13,1.9c0.93,0,1.72,0,2.43,0C277.34,90.43,277.34,89.27,277.34,88.11z M402.39,27.04
	c-1.09,0.44-2.15,1.07-3.35,0.33c-0.5-0.93-1-1.86-1.53-2.86c-0.6,0.05-1.38,0.11-2.23,0.17c0.84,2.73-0.73,3.66-2.96,3.96
	c0.09,0.8,0.15,1.42,0.22,2.08c0.83,0,1.6,0,2.37,0c-0.22-3.11,0.73-3.97,3.71-3.46c0.16,0.03,0.32,0.03,0.47,0.04
	c0.21,0.97,0.42,1.93,0.63,2.93c0.85-0.05,1.66-0.09,2.5-0.14c-0.1-0.52-0.26-0.9-0.23-1.27c0.05-0.61,0.21-1.22,0.33-1.83
	c0.94-0.32,1.88-0.64,2.8-0.94c0-0.77,0-1.47,0-2.21c-0.88,0.05-1.71,0.1-2.37,0.14C402.62,25.08,402.51,26.06,402.39,27.04z
	 M602.81,215.4c1.01-0.24,2.02-0.49,3.05-0.74c-0.12-0.78-0.24-1.53-0.34-2.22c-0.84,0-1.59,0-2.15,0c-0.18,1.1-0.34,2.06-0.49,3.03
	c-0.91,0.39-1.83,0.78-2.82,1.2c0.04,0.65,0.09,1.41,0.14,2.16c0.89-0.07,1.66-0.13,2.36-0.18
	C602.65,217.54,602.73,216.47,602.81,215.4z M564.11,265.99c-0.44-0.97-0.89-1.95-1.34-2.94c-0.72,0-1.44,0-2.15,0
	c-0.16-0.21-0.33-0.41-0.49-0.62c-1.4-2.54-0.82-3.63,2.42-4.36c-0.03-0.74-0.07-1.51-0.11-2.28c-0.89,0.06-1.68,0.12-2.27,0.16
	c-0.2,1.02-0.11,2.11-0.61,2.73c-0.57,0.7-1.67,0.98-2.7,1.52c0,0.66,0,1.52,0,2.39c-0.23,0.31-0.47,0.63-0.7,0.94
	c-3.21,0-3.54-0.25-4.21-3.21c-0.73,0.07-1.46,0.13-2.25,0.21c0.08,0.86,0.15,1.62,0.21,2.36c3.08,0.05,3.37,0.31,3.82,3.07
	c1.06,0.12,2.06,0.23,3.06,0.34c0.16,0.2,0.31,0.39,0.47,0.59c-0.06,0.97-0.12,1.95-0.18,2.92c-0.06,0.06-0.13,0.12-0.19,0.18
	c-0.91,0.36-1.81,0.71-2.76,1.09c0.12,0.65,0.27,1.4,0.4,2.09c0.93,0,1.77,0,2.62,0c0.08,0.18,0.16,0.36,0.25,0.53
	c0.39,0.9,0.78,1.8,1.23,2.84c0.59-0.05,1.33-0.11,2.07-0.17c0-1.03,0-1.89,0-2.74c0.17-0.17,0.34-0.34,0.51-0.52
	c2.98-0.79,3.68-0.36,4.45,2.82c0.75-0.04,1.52-0.08,2.29-0.12c-0.06-0.9-0.12-1.69-0.18-2.48c-3.25,0.11-3.82-0.46-3.69-3.6
	c0.16-0.15,0.33-0.3,0.49-0.44c0.85-0.31,1.7-0.62,2.55-0.94c-0.07-0.76-0.14-1.52-0.19-2.09c-1.07-0.13-1.99-0.24-2.91-0.35
	C564.02,265.91,564.11,265.99,564.11,265.99z M555.92,338.89c-0.39-0.93-0.78-1.87-1.21-2.92c-0.64,0.03-1.33,0.07-2.04,0.11
	c0,0.92,0,1.7,0,2.31c1.17,0.16,2.24,0.31,3.31,0.45c0.22,0.97,0.44,1.94,0.67,2.95c0.82-0.16,1.63-0.32,2.34-0.46
	c0-0.88,0-1.63,0-2.44C557.93,338.89,556.92,338.89,555.92,338.89z M638.74,504.7c1.03-0.21,2.05-0.41,3.07-0.62
	c-0.05-0.88-0.09-1.65-0.13-2.43c-0.91,0.05-1.74,0.1-2.31,0.13c-0.21,1.09-0.4,2.04-0.57,3c-0.9,0.38-1.81,0.77-2.83,1.2
	c0.07,0.51,0.17,1.28,0.28,2.07c0.84,0,1.66,0,2.48,0C638.74,506.85,638.74,505.77,638.74,504.7z M608.26,316.37
	c-0.42-0.96-0.83-1.92-1.28-2.95c-0.71,0-1.47,0-2.23,0c-0.15-0.19-0.29-0.38-0.44-0.57c-0.84-0.93-1.09-1.96-0.38-3.04
	c0.77-1.16,1.88-1.36,3.15-0.91c0.22,0.08,0.47,0.07,0.71,0.1c0.23,0.97,0.47,1.94,0.71,2.96c0.72-0.07,1.4-0.14,2.11-0.21
	c-0.05-0.9-0.09-1.67-0.12-2.35c-0.97-0.11-1.87-0.22-2.78-0.32c-0.48-0.93-0.96-1.87-1.48-2.89c-0.61,0.05-1.38,0.1-1.89,0.14
	c-0.21,1.04-0.14,2.13-0.64,2.74c-0.57,0.69-1.65,0.96-2.68,1.51c0,0.66,0,1.52,0,2.38c-0.17,0.18-0.34,0.37-0.52,0.55
	c-0.9,0.21-1.8,0.43-2.75,0.65c0.05,0.71,0.1,1.46,0.14,2.05c1.1,0.21,2.08,0.39,3.06,0.57c0.17,0.08,0.34,0.16,0.51,0.23
	c0.04,0.93,0.08,1.85,0.12,2.66c1.09,0.12,2.14,0.24,3.19,0.36c0.15,0.19,0.3,0.38,0.45,0.58c0.02,0.97,0.05,1.95,0.07,2.92
	c-0.14,0.13-0.28,0.26-0.42,0.38c-0.9,0.26-1.79,0.53-2.68,0.79c0.06,0.79,0.13,1.62,0.19,2.44c2.76-0.42,3.31-0.01,4.04,3
	c0.75-0.04,1.51-0.08,2.17-0.11c0.08-0.99,0.14-1.84,0.21-2.7c0.13-0.18,0.26-0.35,0.4-0.53c0.94-0.27,1.89-0.55,2.86-0.83
	c-0.19-2.86,0.15-3.29,3.06-3.78c-0.04-0.83-0.09-1.65-0.12-2.29c-1.05-0.08-1.99-0.15-2.92-0.21c-0.16-0.15-0.33-0.3-0.49-0.44
	c-0.15-0.96-0.3-1.92-0.43-2.73c-1.02-0.08-2.01-0.15-2.99-0.23C608.16,316.29,608.26,316.37,608.26,316.37z M609.63,290.9
	c0.27,0.9,0.54,1.79,0.82,2.71c0.76,0,1.52,0,2.31,0c-0.09-0.92-0.18-1.75-0.25-2.47c-1.06-0.06-2-0.12-2.93-0.18
	c-0.34-1.07-0.67-2.14-1.02-3.24c-0.62,0.13-1.38,0.29-2.17,0.46c0,0.79,0,1.57,0,2.15C607.53,290.54,608.58,290.72,609.63,290.9z
	 M609.61,334.13l0.08-0.09c-1.08,0.63-2.18,1.04-3.43,0.5l0.07,0.05c-0.48-1.01-0.97-2.01-1.45-3.02c-0.71,0.04-1.53,0.09-2.36,0.14
	c0.55,2.7,0.09,3.35-2.79,4.07c0,0.77,0,1.53,0,2.07c1.12,0.19,2.11,0.35,3.1,0.52c0.19,0.09,0.37,0.18,0.56,0.27
	c0.06,0.83,0.13,1.67,0.18,2.42c1.05,0.1,1.89,0.19,2.73,0.27c0.16,0.15,0.33,0.3,0.49,0.44c0.56,0.94,0.78,1.92,0.22,2.92
	c-0.68,1.21-1.9,1.16-3.12,1.14c0.22,0.93,0.42,1.74,0.61,2.57c0.97,0,1.83,0,2.7,0c0.18,0.19,0.36,0.38,0.54,0.57
	c0.15,0.95,0.31,1.89,0.46,2.84c0.81-0.08,1.62-0.15,2.29-0.22c0.07-0.97,0.13-1.81,0.2-2.66c0.14-0.19,0.29-0.38,0.43-0.57
	c1.07-0.17,2.15-0.35,3.22-0.52c0.19,0.21,0.38,0.42,0.56,0.63c0.21,0.82,0.42,1.63,0.65,2.49c0.75-0.05,1.44-0.09,2.06-0.13
	c0.16-1.01,0.31-1.93,0.45-2.85c0.06-0.08,0.12-0.16,0.18-0.23c0.86-0.31,1.73-0.62,2.67-0.95c0-0.66,0-1.41,0-2.17
	c0.24-0.31,0.48-0.63,0.71-0.94c0.89-0.23,1.78-0.46,2.64-0.68c-0.12-0.74-0.22-1.43-0.35-2.22c-0.81,0-1.6,0-2.39,0
	c-0.16-0.2-0.32-0.4-0.48-0.61c-1.41-2.54-0.84-3.62,2.43-4.39c-0.06-0.73-0.13-1.48-0.2-2.21c-1.03,0-1.9,0-2.77,0
	c-0.18-0.3-0.37-0.61-0.55-0.91c-0.17-0.81-0.33-1.62-0.49-2.37c-0.9-0.1-1.78-0.21-2.67-0.31c-0.11-0.07-0.23-0.13-0.35-0.19
	c-0.32-0.92-0.63-1.84-0.95-2.76c-0.76,0.04-1.51,0.08-2.12,0.11c-0.12,1.05-0.24,2.02-0.35,2.99c-0.15,0.19-0.3,0.38-0.45,0.56
	c-0.9,0.11-1.79,0.23-2.54,0.33C609.91,332.06,609.76,333.1,609.61,334.13z M676.42,602.89c-1.02,0.26-2.05,0.53-3.12,0.8
	c0.14,0.74,0.3,1.56,0.44,2.25c0.93,0,1.68,0,2.21,0c0.15-1.14,0.28-2.13,0.41-3.12c0.93-0.34,1.86-0.68,2.84-1.03
	c-0.05-0.75-0.1-1.5-0.15-2.23c-0.9,0.05-1.66,0.09-2.38,0.13C676.58,600.77,676.5,601.83,676.42,602.89z M615.02,312.14
	c-0.97,0.26-1.93,0.53-2.97,0.81c0.04,0.7,0.08,1.48,0.12,2.25c0.9,0,1.73,0,2.33,0c0.17-1.14,0.32-2.13,0.46-3.13
	c0.93-0.34,1.87-0.68,2.88-1.05c-0.11-0.65-0.25-1.4-0.37-2.14c-0.87,0.04-1.62,0.08-2.16,0.11
	C615.19,310.14,615.11,311.14,615.02,312.14z M626.95,408.52c-0.93,0.3-1.87,0.6-2.83,0.91c-0.06,0.73-0.13,1.57-0.21,2.41
	c-0.12,0.17-0.25,0.34-0.37,0.51c-1.01,0.28-2.01,0.56-3.05,0.84c0.13,0.64,0.29,1.46,0.45,2.26c0.92,0.09,1.78,0.17,2.63,0.25
	c0.16,0.15,0.32,0.29,0.48,0.44c1.08,2.87,0.37,3.91-2.87,4.17c0.2,0.82,0.39,1.63,0.59,2.46c0.83-0.04,1.59-0.08,2.36-0.12
	c-0.28-3.09,0.09-3.54,2.92-3.87c0.08-1.03,0.15-2.04,0.22-3.04c0.2-0.17,0.4-0.35,0.6-0.52c0.89-0.1,1.78-0.2,2.66-0.3
	c-0.11-0.86-0.21-1.63-0.3-2.36c-0.96-0.09-1.88-0.17-2.79-0.25c-0.17-0.15-0.33-0.3-0.5-0.45c-0.48-1.12-0.44-2.25-0.04-3.39
	c0.95-0.33,1.9-0.65,2.78-0.96c0-0.81,0-1.51,0-2.26c-0.91,0.06-1.74,0.11-2.35,0.15C627.19,406.53,627.07,407.53,626.95,408.52z
	 M673.48,610.5c-0.39-0.93-0.78-1.86-1.18-2.82c-0.65,0-1.34,0-2.07,0c0,0.88,0,1.65,0,2.25c1.17,0.18,2.24,0.34,3.31,0.51
	c0.26,0.95,0.51,1.89,0.74,2.72c0.88,0,1.62,0,2.33,0c-0.05-0.92-0.1-1.75-0.15-2.52C675.46,610.59,674.47,610.54,673.48,610.5z
	 M499.03,61.72c1.2-0.04,2.39-0.08,3.59-0.12c0.48,0.94,0.95,1.89,1.48,2.93c0.53,0,1.36,0,2.2,0c-0.37-2.85,0.06-3.46,3.05-4.21
	c-0.04-0.76-0.08-1.53-0.12-2.3c-0.94,0.11-1.77,0.2-2.61,0.29c0.39,3.06-0.66,4.01-3.6,3.4c-0.15-0.03-0.31-0.03-0.47-0.05
	c-0.22-0.97-0.43-1.93-0.65-2.89c-0.82,0.04-1.58,0.08-2.06,0.1c-0.28,1.04-0.53,1.95-0.76,2.89c-0.82,0.5-1.64,1-2.49,1.51
	c0,0.57,0,1.24,0,1.9c0.86,0,1.63,0,2.43,0C499.03,63.99,499.03,62.85,499.03,61.72z M473.86,60.61c-0.94,0.31-1.88,0.62-2.89,0.95
	c0.03,0.67,0.07,1.44,0.11,2.26c0.89,0,1.73,0,2.43,0c0.1-1.14,0.2-2.2,0.3-3.26c1.04-0.28,2.07-0.56,3.09-0.83
	c-0.14-0.7-0.29-1.51-0.45-2.3c-0.86,0.06-1.61,0.11-2.18,0.15C474.12,58.66,473.99,59.64,473.86,60.61z M657.38,553.56
	c1.05-0.16,2.1-0.33,3.15-0.49c-0.06-0.94-0.12-1.7-0.17-2.47c-0.88,0.06-1.65,0.11-2.17,0.15c-0.28,1.01-0.53,1.93-0.76,2.87
	c-0.82,0.49-1.65,0.98-2.61,1.56c0.05,0.46,0.13,1.13,0.21,1.84c0.82,0,1.58,0,2.35,0C657.38,555.82,657.38,554.69,657.38,553.56z
	 M621.44,430.29c1.03-0.19,2.06-0.37,3.08-0.56c-0.04-0.91-0.08-1.68-0.12-2.46c-0.89,0.05-1.72,0.09-2.26,0.12
	c-0.24,1.09-0.44,2.02-0.63,2.97c-0.93,0.42-1.85,0.84-2.84,1.29c0.1,0.59,0.22,1.34,0.35,2.1c0.88,0,1.65,0,2.42,0
	C621.44,432.56,621.44,431.43,621.44,430.29z M592.36,216.57c-0.34-1.07-0.69-2.14-1.04-3.23c-0.61,0.14-1.36,0.31-2.19,0.49
	c0.05,0.78,0.09,1.54,0.13,2.1c1.16,0.21,2.16,0.4,3.15,0.58c0.24,0.89,0.48,1.78,0.73,2.69c0.8,0,1.61,0,2.38,0
	c-0.06-0.94-0.11-1.77-0.17-2.64C594.36,216.57,593.36,216.57,592.36,216.57z M545.35,217.03c-0.41-0.98-0.81-1.95-1.24-2.97
	c-0.72,0.07-1.4,0.14-2.09,0.21c0.04,0.89,0.07,1.66,0.1,2.23c1.17,0.17,2.22,0.33,3.27,0.48c0.13,1.13,0.26,2.27,0.39,3.4
	c-0.14,0.13-0.28,0.26-0.42,0.38c-0.87,0.28-1.75,0.56-2.61,0.83c0.07,0.78,0.14,1.54,0.19,2.03c0.99,0.2,2.05,0.11,2.58,0.6
	c0.73,0.67,1.07,1.77,1.64,2.8c0.74,0,1.61,0,2.47,0c0.17,0.18,0.34,0.35,0.5,0.53c0.16,0.95,0.32,1.9,0.47,2.85
	c0.83-0.07,1.66-0.14,2.31-0.2c0.1-1,0.18-1.84,0.26-2.69c0.11-0.15,0.21-0.3,0.32-0.46c1.01-0.28,2.02-0.56,2.87-0.8
	c0-0.94,0-1.98,0-3.03c0.16-0.13,0.31-0.26,0.47-0.4c0.89-0.28,1.77-0.55,2.64-0.82c-0.1-0.76-0.2-1.45-0.29-2.11
	c-2.9-0.1-3.26-0.42-3.85-3.16c-0.94-0.08-1.87-0.16-2.81-0.23c-0.15-0.13-0.3-0.27-0.45-0.4c-0.14-0.96-0.28-1.93-0.43-2.89
	c-0.8,0.05-1.64,0.11-2.49,0.17c0,0.88,0,1.79,0,2.71c-0.19,0.16-0.38,0.32-0.57,0.48C547.63,217.53,546.47,217.12,545.35,217.03z
	 M466.72,57.57c0.23,0.97,0.47,1.95,0.71,2.96c0.71-0.07,1.4-0.13,2.11-0.2c-0.04-0.91-0.08-1.68-0.12-2.36
	c-0.97-0.11-1.87-0.22-2.78-0.32c-0.48-0.94-0.96-1.89-1.48-2.9c-0.61,0.05-1.39,0.12-1.91,0.16c-0.17,0.99-0.04,2.04-0.51,2.57
	c-0.66,0.75-1.76,1.11-2.76,1.68c0.02,0.54,0.05,1.25,0.09,2.03c0.88-0.07,1.71-0.14,2.54-0.21c-0.33-3.07,0.68-4.01,3.62-3.47
	C466.4,57.53,466.56,57.54,466.72,57.57z M604.34,399.28c0.17,0.18,0.34,0.36,0.51,0.54c0.15,0.95,0.31,1.9,0.46,2.86
	c0.83-0.07,1.65-0.14,2.31-0.2c0.09-1,0.16-1.85,0.24-2.69c0.12-0.16,0.24-0.33,0.36-0.49c1.03-0.27,2.07-0.54,2.94-0.77
	c-0.05-0.96-0.1-1.97-0.16-2.98c0.18-0.16,0.37-0.33,0.55-0.49c0.89-0.26,1.77-0.51,2.66-0.77c-0.11-0.73-0.21-1.41-0.28-1.91
	c-1.05-0.27-2.15-0.25-2.77-0.79c-0.68-0.6-0.92-1.7-1.38-2.63c-0.62,0.03-1.36,0.07-2,0.1c-0.07,0.94-0.13,1.79-0.19,2.65
	c-0.12,0.17-0.25,0.34-0.37,0.51c-0.85,0.3-1.7,0.59-2.48,0.86c-0.09,0.91-0.2,1.89-0.3,2.87c-0.18,0.15-0.36,0.29-0.54,0.44
	c-0.87,0.17-1.74,0.34-2.62,0.51c0.08,0.76,0.16,1.52,0.25,2.39C602.49,399.28,603.42,399.28,604.34,399.28z M674.62,617.92
	c0.09,1,0.16,1.81,0.23,2.61c0.86-0.07,1.62-0.13,2.19-0.18c0.24-1.07,0.19-2.24,0.74-2.93c0.54-0.68,1.69-0.89,2.59-1.31
	c-0.07-0.69-0.14-1.44-0.21-2.17c-0.89,0.05-1.66,0.1-2.42,0.14C677.98,617.18,677.6,617.67,674.62,617.92z M570.51,291.12
	c-0.21,1.02-0.15,2.21-0.7,2.91c-0.53,0.68-1.7,0.86-2.64,1.28c0.06,0.68,0.13,1.49,0.2,2.3c0.86-0.07,1.63-0.14,2.2-0.19
	c0.25-1.09,0.2-2.27,0.76-2.96c0.54-0.67,1.7-0.85,2.62-1.26c-0.12-0.66-0.26-1.42-0.39-2.09
	C571.73,291.12,571.02,291.12,570.51,291.12z M602.69,371.53c0.13,0.76,0.25,1.51,0.38,2.23c0.82,0,1.53,0,2.07,0
	c0.22-1.04,0.15-2.23,0.7-2.93c0.53-0.68,1.69-0.85,2.62-1.27c-0.06-0.69-0.13-1.51-0.19-2.28c-0.86,0.06-1.64,0.11-2.42,0.16
	C606.01,370.57,605.72,370.94,602.69,371.53z M493.75,73.83c0.06,0.97,0.12,1.78,0.17,2.58c0.86-0.08,1.63-0.15,2.39-0.22
	c-0.06-3.18,0.28-3.61,3.28-4.02c-0.14-0.76-0.27-1.52-0.41-2.27c-0.8,0.06-1.54,0.12-2.27,0.18
	C497.02,73.12,496.69,73.52,493.75,73.83z M597.41,227.43c0.05,0.82,0.09,1.64,0.14,2.49c0.83-0.07,1.6-0.13,2.38-0.19
	c0.01-3.2,0.37-3.64,3.34-3.95c-0.14-0.77-0.27-1.53-0.4-2.27c-0.8,0-1.55,0-2.3,0C600.67,226.64,600.33,227.06,597.41,227.43z
	 M548.72,248.5c0.93,0,1.63,0,2.33,0c-0.03-3.27,0.34-3.7,3.39-3.87c-0.17-0.83-0.34-1.66-0.5-2.48c-0.81,0-1.58,0-2.2,0
	c-0.15,1.01,0,2.13-0.49,2.8c-0.53,0.74-1.63,1.08-2.52,1.61C548.72,247.11,548.72,247.73,548.72,248.5z M629.25,400.55
	c-0.09-3.13,0.09-3.38,3.09-4.04c-0.05-0.75-0.1-1.52-0.16-2.29c-0.88,0.08-1.63,0.14-2.15,0.19c-0.23,1.07-0.17,2.2-0.7,2.82
	c-0.59,0.69-1.72,0.91-2.62,1.34c0.03,0.65,0.07,1.41,0.11,2.17C627.69,400.67,628.47,400.61,629.25,400.55z M643.34,507.46
	c1.14,0.25,2.26,0.19,2.87,0.71c0.67,0.57,0.88,1.69,1.32,2.63c0.62-0.06,1.39-0.13,2.2-0.2c-0.12-0.84-0.22-1.59-0.29-2.11
	c-1.09-0.31-2.19-0.31-2.79-0.87c-0.66-0.62-0.86-1.73-1.29-2.69c-0.68,0.11-1.42,0.23-2.13,0.34
	C643.27,506.14,643.31,506.85,643.34,507.46z M598.08,205.59c-3.02,0.48-3.83-0.35-4.01-3.23c-0.82,0.15-1.64,0.29-2.42,0.43
	c0.05,0.86,0.1,1.62,0.14,2.16c1.03,0.22,2.19,0.15,2.88,0.69c0.7,0.54,0.93,1.68,1.37,2.56c0.62,0,1.32,0,2.05,0
	C598.08,207.35,598.08,206.59,598.08,205.59z M627.33,376.72c-1.19-0.31-2.38-0.29-3.03-0.87c-0.65-0.58-0.77-1.77-1.13-2.71
	c-0.67,0.14-1.49,0.3-2.25,0.46c0.04,0.88,0.08,1.64,0.11,2.19c1.01,0.21,2.17,0.14,2.86,0.67c0.7,0.54,0.95,1.67,1.39,2.55
	c0.61,0,1.3,0,2.04,0C627.33,378.16,627.33,377.39,627.33,376.72z M616.54,365.44c0.95-0.06,1.79-0.1,2.62-0.15
	c-0.16-0.84-0.3-1.6-0.45-2.35c-2.96-0.13-3.25-0.38-3.95-3.37c-0.74,0.14-1.49,0.29-2.15,0.42c0,0.83,0,1.54,0,2.24
	C615.77,362.15,616.2,362.5,616.54,365.44z M405.26,12.89c0.08,3.04-0.14,3.33-3.05,3.99c0.07,0.76,0.15,1.52,0.22,2.26
	c0.83-0.05,1.53-0.08,2.03-0.11c0.26-1.03,0.24-2.21,0.82-2.9c0.56-0.67,1.72-0.83,2.61-1.21c-0.07-0.71-0.14-1.47-0.21-2.17
	C406.83,12.8,406.04,12.84,405.26,12.89z M449.48,65.56c0.99,0.22,2.21,0.14,2.87,0.71c0.7,0.61,0.87,1.83,1.27,2.8
	c0.7-0.16,1.46-0.34,2.23-0.52c-0.09-0.75-0.18-1.44-0.25-2.01c-0.99-0.25-2.09-0.24-2.71-0.77c-0.69-0.6-0.96-1.7-1.44-2.64
	c-0.59,0.03-1.35,0.06-2.11,0.09C449.39,64.07,449.44,64.86,449.48,65.56z M551.09,235.08c0.05,0.88,0.1,1.65,0.14,2.41
	c3.18-0.03,3.5,0.23,3.92,3.19c0.84-0.09,1.68-0.17,2.49-0.26c-0.13-0.82-0.23-1.52-0.34-2.2c-3.02-0.21-3.34-0.49-3.89-3.4
	C552.72,234.9,552.02,234.98,551.09,235.08z M625.84,401.83c-2.94,0.3-3.43-0.08-4.02-3.05c-0.78,0.05-1.55,0.1-2.33,0.15
	c0.08,0.91,0.15,1.74,0.2,2.26c1.01,0.19,2.07,0.08,2.61,0.55c0.74,0.66,1.09,1.77,1.63,2.73c0.58,0,1.29,0,2.05,0
	C625.93,403.63,625.89,402.84,625.84,401.83z M518.46,580.03c-0.14-3.12,0.18-3.52,3.13-4.04c-0.13-0.68-0.25-1.36-0.4-2.16
	c-0.74,0.07-1.41,0.13-1.89,0.18c-0.29,1.08-0.28,2.21-0.84,2.81c-0.61,0.65-1.75,0.82-2.59,1.17c0,0.73,0,1.49,0,2.24
	C516.73,580.16,517.52,580.1,518.46,580.03z M441.5,67.6c-0.86,0.13-1.63,0.25-2.39,0.37c0.07,0.83,0.14,1.59,0.19,2.1
	c0.99,0.19,2.05,0.09,2.59,0.57c0.74,0.66,1.09,1.77,1.66,2.77c0.57-0.03,1.31-0.07,2.05-0.12c-0.07-0.94-0.14-1.72-0.2-2.49
	C442.45,71.1,441.85,70.63,441.5,67.6z M607.27,355.54c-0.05-0.97-0.09-1.75-0.13-2.38c-1.05-0.22-2.22-0.16-2.9-0.7
	c-0.68-0.55-0.87-1.71-1.29-2.64c-0.73,0.09-1.47,0.19-2.23,0.28c0.15,0.85,0.27,1.53,0.41,2.31c2.71-0.58,3.65,0.97,3.86,3.14
	C605.81,355.54,606.44,355.54,607.27,355.54z M517.53,180.11c-0.24,1.1-0.16,2.25-0.7,2.8c-0.68,0.7-1.86,0.92-2.81,1.34
	c0.11,0.68,0.23,1.45,0.35,2.23c0.85-0.09,1.68-0.18,2.2-0.24c0.19-1.03,0.08-2.09,0.56-2.61c0.68-0.74,1.78-1.08,2.75-1.61
	c-0.03-0.61-0.07-1.32-0.11-2.07C518.95,180.01,518.18,180.06,517.53,180.11z M612.01,410.52c-3.05-0.25-3.38-0.53-3.91-3.47
	c-0.75,0.11-1.5,0.22-2.3,0.34c0.06,0.85,0.11,1.61,0.16,2.21c1.05,0.21,2.17,0.13,2.8,0.65c0.69,0.57,0.92,1.68,1.37,2.61
	c0.62-0.06,1.32-0.13,2.07-0.2C612.13,411.88,612.07,411.19,612.01,410.52z M643.87,514.82c3.09-0.13,3.55,0.26,3.92,3.33
	c0.82-0.16,1.63-0.32,2.44-0.48c-0.12-0.8-0.22-1.48-0.29-2c-1.04-0.26-2.15-0.24-2.76-0.78c-0.68-0.6-0.92-1.7-1.38-2.63
	c-0.61,0.04-1.36,0.08-2.1,0.12C643.77,513.27,643.82,514.05,643.87,514.82z M603.39,420.76c0.94,0,1.64,0,2.34,0
	c-0.01-3.27,0.31-3.66,3.31-3.95c-0.15-0.83-0.31-1.65-0.46-2.46c-0.83,0.05-1.57,0.09-2.1,0.12c-0.18,1.05-0.07,2.16-0.57,2.81
	c-0.55,0.72-1.66,1.02-2.52,1.49C603.39,419.41,603.39,420.03,603.39,420.76z M608.7,456.45c0.19-1.07,0.07-2.12,0.55-2.65
	c0.67-0.74,1.77-1.09,2.79-1.67c-0.04-0.58-0.09-1.33-0.15-2.06c-0.91,0.09-1.69,0.17-2.53,0.25c0.75,2.8-0.72,3.8-3.16,3.9
	c0.15,0.85,0.27,1.54,0.39,2.24C607.37,456.45,608.07,456.45,608.7,456.45z M556.94,301.96c2.72-0.51,3.81,0.94,3.91,3.36
	c0.87-0.12,1.56-0.22,2.29-0.32c-0.05-0.85-0.1-1.63-0.15-2.41c-2.94,0.22-3.29-0.04-4.11-3.01c-0.66,0.06-1.34,0.12-2.04,0.19
	C556.87,300.55,556.9,301.17,556.94,301.96z M557.93,187.67c0,0.79,0,1.56,0,2.33c3.08-0.12,3.48,0.19,4.03,3.1
	c0.75-0.05,1.5-0.1,2.37-0.16c-0.11-0.84-0.22-1.58-0.28-2.08c-1.12-0.3-2.23-0.3-2.83-0.85c-0.66-0.61-0.86-1.73-1.29-2.69
	C559.31,187.42,558.63,187.54,557.93,187.67z M603.56,259.3c0.37,2.85-0.12,3.45-3.12,3.99c0.13,0.75,0.26,1.5,0.39,2.28
	c0.87-0.07,1.64-0.13,2.09-0.16c0.2-1.03,0.11-2.09,0.59-2.61c0.67-0.73,1.78-1.07,2.74-1.6c-0.03-0.63-0.07-1.34-0.11-2.06
	C605.31,259.18,604.54,259.23,603.56,259.3z M565.93,214.54c0.21-1.07,0.11-2.15,0.6-2.67c0.68-0.73,1.81-1.04,2.84-1.58
	c-0.07-0.46-0.16-1.15-0.26-1.85c-0.83,0-1.6,0-2.37,0c0.25,2.86-0.11,3.32-3.12,4.06c0.06,0.73,0.11,1.48,0.17,2.21
	C564.64,214.65,565.35,214.59,565.93,214.54z M614.96,428.21c-0.21,1.09-0.11,2.16-0.59,2.68c-0.68,0.73-1.79,1.05-2.76,1.56
	c0.03,0.64,0.07,1.35,0.1,2.1c0.89-0.1,1.71-0.2,2.53-0.29c-0.31-2.84,0.16-3.4,3.2-3.92c-0.13-0.75-0.26-1.49-0.39-2.24
	C616.22,428.14,615.51,428.18,614.96,428.21z M625.2,505.3c0.78,0,1.59,0,2.17,0c0.18-1,0.07-2.06,0.55-2.59
	c0.67-0.74,1.78-1.08,2.73-1.62c0-0.55,0-1.25,0-2.04c-0.91,0.07-1.74,0.14-2.59,0.21c0.77,2.89-0.78,3.81-3.16,3.88
	C625,503.93,625.1,504.56,625.2,505.3z M447.41,52.49c-2.43,0.43-3.53,0.28-4.11-3.04c-0.76,0.07-1.52,0.13-2.32,0.2
	c0.11,0.82,0.2,1.51,0.29,2.18c3.17,0.28,3.29,0.46,3.92,3.44c0.75-0.1,1.5-0.2,2.21-0.29C447.41,54.14,447.41,53.43,447.41,52.49z
	 M613.65,495.62c0.75-0.04,1.49-0.07,2.25-0.11c-0.06-0.89-0.11-1.67-0.16-2.45c-3.04,0.26-3.55-0.17-3.91-3.25
	c-0.76,0.13-1.51,0.27-2.34,0.41c0.06,0.73,0.12,1.41,0.18,2.07C612.76,492.54,613.01,492.75,613.65,495.62z M610.87,438.15
	c-0.12-0.91-0.22-1.59-0.33-2.43c-2.65,0.58-3.73-0.79-3.87-3.12c-0.84,0.08-1.52,0.15-2.25,0.22c0.05,0.86,0.1,1.63,0.14,2.21
	c1.03,0.2,2.16,0.11,2.78,0.63c0.69,0.57,0.92,1.7,1.37,2.64C609.29,438.26,610,438.21,610.87,438.15z M470.4,112.14
	c-0.77,0-1.52,0-2.34,0c0.09,0.92,0.16,1.74,0.22,2.3c1.09,0.22,2.25,0.11,2.82,0.64c0.69,0.65,0.88,1.83,1.3,2.8
	c0.68-0.14,1.43-0.29,2.15-0.44c-0.11-0.83-0.2-1.5-0.28-2.14C471.3,115.24,470.97,114.96,470.4,112.14z M410.74,32.88
	c0.96-0.1,1.71-0.18,2.47-0.26c-0.31-2.73,0.1-3.26,3.02-3.97c-0.06-0.67-0.13-1.35-0.19-2.02c-0.87,0-1.63,0-2.13,0
	c-0.24,1.05-0.17,2.21-0.71,2.77c-0.67,0.7-1.86,0.9-2.81,1.31C410.49,31.34,410.6,32.03,410.74,32.88z M541.46,207.01
	c-0.19,1-0.09,2.09-0.59,2.72c-0.57,0.71-1.66,0.99-2.67,1.53c0.04,0.55,0.1,1.3,0.15,2.04c0.92-0.09,1.7-0.16,2.22-0.21
	c0.18-0.98,0.07-2.04,0.55-2.57c0.67-0.74,1.78-1.09,2.78-1.65c-0.04-0.61-0.1-1.37-0.15-2.1
	C542.84,206.87,542.06,206.95,541.46,207.01z M560.67,236.59c0.2-1.02,0.12-2.13,0.63-2.74c0.58-0.7,1.68-0.98,2.66-1.49
	c-0.03-0.53-0.07-1.22-0.12-1.97c-0.86,0.05-1.63,0.09-2.41,0.13c0.34,2.36,0.25,3.31-3.09,4.04c0.03,0.76,0.07,1.53,0.1,2.3
	C559.31,236.76,560.09,236.66,560.67,236.59z M572.54,204.21c-3.05,0.23-3.55-0.2-3.91-3.33c-0.82,0.18-1.63,0.36-2.42,0.54
	c0.13,0.84,0.23,1.51,0.33,2.16c2.93,0.06,3.22,0.31,3.91,3.19c0.69-0.05,1.38-0.11,2.08-0.16
	C572.54,205.78,572.54,205,572.54,204.21z M565.61,127.62c-0.07-0.57-0.15-1.17-0.24-1.91c-0.78,0.05-1.45,0.09-2.1,0.14
	c-0.15,3.05-0.38,3.31-3.23,3.81c0,0.77,0,1.54,0,2.39c0.86-0.09,1.68-0.17,2.49-0.26C562.49,128.71,562.56,128.6,565.61,127.62z
	 M667.03,584.98c-0.13-0.92-0.22-1.6-0.34-2.43c-2.69,0.58-3.78-0.79-3.84-3.26c-0.85,0.19-1.52,0.34-2.19,0.49c0,0.8,0,1.49,0,2.06
	c1.01,0.17,2.15,0.05,2.79,0.56c0.71,0.56,0.96,1.7,1.47,2.68C665.44,585.06,666.15,585.03,667.03,584.98z M603.41,270.32
	c-3.03-0.1-3.33-0.35-3.95-3.21c-0.68,0.07-1.37,0.14-2.13,0.21c0.06,0.8,0.11,1.54,0.16,2.28c2.96-0.12,3.42,0.3,3.77,3.36
	c0.76-0.15,1.51-0.3,2.27-0.45c0.06-0.01,0.11-0.11,0.14-0.14C603.58,271.71,603.51,271.08,603.41,270.32z M354.23,20.87
	c-3.08,0.32-3.37-0.3-4.04-2.86c-0.69,0-1.37,0-2.19,0c0.06,0.78,0.11,1.46,0.16,2.12c3.06,0.28,3.33,0.5,4.01,3.39
	c0.67-0.12,1.34-0.25,2.07-0.38C354.23,22.4,354.23,21.7,354.23,20.87z M429.42,39.67c-0.17,1.09-0.04,2.21-0.53,2.86
	c-0.54,0.73-1.65,1.03-2.59,1.57c0.08,0.54,0.17,1.14,0.26,1.75c0.75-0.06,1.38-0.11,1.76-0.14c0.37-1.09,0.45-2.26,1.08-2.9
	c0.6-0.61,1.76-0.66,2.68-0.96c-0.17-0.73-0.35-1.48-0.51-2.19C430.8,39.67,430.1,39.67,429.42,39.67z M595.83,223.8
	c-0.91,0.04-1.81,0.08-2.72,0.12c0.17,0.95,0.33,1.85,0.48,2.74c0.85-0.12,1.63-0.23,2.38-0.34
	C595.93,225.55,595.88,224.77,595.83,223.8z M620.45,492.78c0.06,0.82,0.11,1.64,0.16,2.37c0.98,0,1.88,0,2.72,0
	c-0.17-1-0.33-1.89-0.48-2.75C621.99,492.54,621.23,492.66,620.45,492.78z M606.72,377.24c0.84-0.07,1.66-0.14,2.48-0.21
	c-0.07-0.93-0.12-1.72-0.18-2.53c-0.87,0.04-1.71,0.08-2.54,0.12C606.56,375.52,606.64,376.35,606.72,377.24z M509.57,173.51
	c0.06,0.92,0.12,1.77,0.18,2.61c0.9,0,1.83,0,2.79,0c-0.19-0.96-0.36-1.83-0.51-2.61C511.12,173.51,510.35,173.51,509.57,173.51z
	 M448.22,62.35c-0.06-0.92-0.11-1.76-0.16-2.63c-0.84,0.06-1.69,0.12-2.56,0.18c0.09,0.86,0.17,1.69,0.25,2.45
	C446.68,62.35,447.44,62.35,448.22,62.35z M558.09,205.69c-0.9,0-1.68,0-2.46,0c0.08,0.93,0.14,1.76,0.21,2.52c0.95,0,1.87,0,2.72,0
	C558.39,207.32,558.24,206.54,558.09,205.69z M470.14,64.98c-0.74,0.04-1.63,0.09-2.56,0.14c0.07,0.87,0.15,1.71,0.22,2.56
	c0.9-0.06,1.73-0.11,2.49-0.15C470.24,66.66,470.19,65.9,470.14,64.98z M611.48,308.13c0.88-0.06,1.63-0.12,2.46-0.18
	c-0.05-0.88-0.1-1.7-0.15-2.53c-0.91,0.07-1.74,0.12-2.49,0.18C611.36,306.46,611.42,307.23,611.48,308.13z M564.53,348.4
	c0.94-0.04,1.82-0.08,2.64-0.12c-0.18-0.95-0.34-1.76-0.48-2.48c-0.88,0-1.64,0-2.4,0C564.37,346.73,564.45,347.56,564.53,348.4z
	 M443.35,88.98c-0.99,0.05-1.85,0.09-2.74,0.14c0.13,0.85,0.28,1.75,0.42,2.66c0.83-0.11,1.6-0.21,2.32-0.3
	C443.35,90.64,443.35,89.86,443.35,88.98z M612.76,373.31c-0.18-1.04-0.32-1.91-0.47-2.77c-0.87,0.15-1.63,0.28-2.34,0.4
	c0,0.83,0,1.64,0,2.37C610.92,373.31,611.77,373.31,612.76,373.31z M606.57,388.17c-0.07-0.99-0.13-1.83-0.19-2.67
	c-0.85,0.07-1.68,0.14-2.5,0.21c0.07,0.85,0.12,1.63,0.19,2.46C604.89,388.17,605.67,388.17,606.57,388.17z M568.94,287.63
	c-0.83,0.06-1.66,0.12-2.51,0.19c0.1,0.88,0.18,1.71,0.26,2.44c0.93,0,1.68,0,2.42,0C569.06,289.33,569,288.49,568.94,287.63z
	 M599.34,222.63c-0.08-0.99-0.14-1.83-0.21-2.66c-0.88,0.07-1.71,0.14-2.54,0.2c0.1,0.88,0.19,1.71,0.28,2.46
	C597.76,222.63,598.47,222.63,599.34,222.63z M566.35,298.66c-0.95,0.08-1.78,0.15-2.57,0.21c0.11,0.93,0.2,1.75,0.3,2.56
	c0.88-0.07,1.65-0.13,2.45-0.19C566.46,300.41,566.41,299.64,566.35,298.66z M448.12,48.85c0.11,0.97,0.2,1.78,0.29,2.57
	c0.85-0.08,1.61-0.14,2.42-0.22c-0.07-0.88-0.13-1.7-0.18-2.5C449.77,48.76,449.01,48.81,448.12,48.85z M480.16,168.88
	c0.12,0.74,0.25,1.59,0.4,2.52c0.79-0.08,1.53-0.15,2.33-0.23c-0.05-0.91-0.09-1.72-0.13-2.53
	C481.85,168.72,481.09,168.79,480.16,168.88z M573.86,300.88c-0.21-0.98-0.38-1.78-0.54-2.55c-0.82,0-1.57,0-2.33,0
	c0.05,0.9,0.09,1.73,0.14,2.55C571.95,300.88,572.79,300.88,573.86,300.88z M408.77,11.45c0.89,0,1.7,0,2.53,0
	c-0.19-0.95-0.36-1.77-0.51-2.54c-0.8,0-1.48,0-2.25,0C408.62,9.81,408.7,10.63,408.77,11.45z M499.16,54.2c0.96,0,1.87,0,2.78,0
	c-0.2-0.97-0.38-1.84-0.56-2.73c-0.86,0.19-1.58,0.35-2.22,0.49C499.16,52.81,499.16,53.51,499.16,54.2z M452.16,54.68
	c0.89,0,1.79,0,2.71,0c-0.16-0.93-0.31-1.75-0.45-2.59c-0.86,0.05-1.67,0.11-2.43,0.16C452.05,53.15,452.11,53.91,452.16,54.68z
	 M599.28,209.11c0.07,0.94,0.12,1.71,0.18,2.53c0.82-0.05,1.66-0.09,2.54-0.14c-0.07-0.87-0.13-1.71-0.2-2.52
	C600.92,209.03,600.14,209.07,599.28,209.11z M619.2,520.66c0.96-0.04,1.8-0.08,2.65-0.12c-0.15-0.85-0.3-1.68-0.44-2.51
	c-0.81,0.05-1.58,0.09-2.35,0.13C619.1,518.96,619.14,519.73,619.2,520.66z M466.77,108.6c-0.89,0.1-1.71,0.19-2.53,0.28
	c0.07,0.85,0.13,1.64,0.19,2.46c0.87-0.05,1.7-0.11,2.48-0.15C466.87,110.32,466.82,109.55,466.77,108.6z M442.07,63.71
	c0.13,0.82,0.26,1.71,0.4,2.62c0.82-0.12,1.58-0.23,2.29-0.34c0-0.85,0-1.66,0-2.42C443.8,63.63,442.96,63.67,442.07,63.71z
	 M563.06,291.67c0.1,0.79,0.22,1.63,0.34,2.57c0.78-0.07,1.55-0.15,2.37-0.23c-0.08-0.84-0.15-1.67-0.22-2.46
	C564.71,291.6,563.93,291.63,563.06,291.67z M611.96,355.06c0.97,0,1.82,0,2.72,0c-0.14-0.84-0.28-1.68-0.43-2.56
	c-0.82,0.04-1.65,0.07-2.45,0.11C611.85,353.45,611.9,354.21,611.96,355.06z M639.83,509c0.09,0.91,0.17,1.73,0.24,2.52
	c0.88-0.05,1.65-0.09,2.46-0.13c-0.05-0.87-0.1-1.74-0.15-2.57C641.54,508.88,640.78,508.93,639.83,509z M602.42,411.15
	c0.08,0.87,0.16,1.7,0.24,2.51c0.91-0.05,1.68-0.1,2.45-0.15c-0.05-0.88-0.1-1.71-0.15-2.56
	C604.13,411.02,603.29,411.08,602.42,411.15z M547.92,241.37c0.87-0.04,1.7-0.08,2.49-0.11c-0.05-0.9-0.09-1.72-0.14-2.58
	c-0.79,0.05-1.62,0.1-2.5,0.15C547.83,239.67,547.87,240.51,547.92,241.37z M680.98,621.19c-0.96,0.04-1.72,0.08-2.44,0.11
	c0,0.92,0,1.8,0,2.71c0.9-0.1,1.72-0.19,2.56-0.28C681.05,622.84,681.02,622.07,680.98,621.19z M629.26,429.43c0.85,0,1.7,0,2.6,0
	c-0.14-0.88-0.28-1.72-0.41-2.52c-0.82,0-1.59,0-2.4,0C629.13,427.81,629.19,428.63,629.26,429.43z M611.68,363.43
	c-0.95,0.09-1.76,0.16-2.58,0.24c0.09,0.87,0.17,1.69,0.25,2.49c0.88-0.05,1.65-0.1,2.45-0.15
	C611.76,365.2,611.72,364.38,611.68,363.43z M625.17,423.49c0.08,1.01,0.15,1.84,0.21,2.68c0.84-0.06,1.68-0.12,2.53-0.17
	c-0.06-0.89-0.12-1.73-0.18-2.51C626.85,423.49,626.07,423.49,625.17,423.49z M565.74,280.5c0.11,0.99,0.2,1.81,0.3,2.75
	c0.82-0.12,1.56-0.22,2.33-0.33c-0.04-0.89-0.08-1.66-0.11-2.42C567.44,280.5,566.67,280.5,565.74,280.5z M617.1,496.56
	c0.07,0.82,0.14,1.64,0.22,2.51c0.82-0.06,1.63-0.11,2.47-0.17c-0.08-0.9-0.16-1.72-0.23-2.52
	C618.67,496.44,617.91,496.5,617.1,496.56z M677.23,606.9c0.09,0.86,0.17,1.69,0.25,2.46c0.92,0,1.69,0,2.47,0
	c-0.1-0.93-0.19-1.77-0.28-2.59C678.81,606.81,678.03,606.85,677.23,606.9z M357.78,19.57c-0.07-0.84-0.14-1.67-0.21-2.48
	c-0.87,0.1-1.63,0.19-2.39,0.28c0,0.76,0,1.52,0,2.33C356.05,19.65,356.88,19.61,357.78,19.57z M512.48,180.61
	c-0.73,0.08-1.42,0.15-2.19,0.23c0.07,0.86,0.14,1.68,0.21,2.5c0.94-0.05,1.71-0.08,2.47-0.12
	C512.8,182.33,512.65,181.5,512.48,180.61z M403.27,31.08c0.08,0.92,0.15,1.7,0.21,2.5c0.87-0.06,1.71-0.12,2.5-0.17
	c-0.07-0.91-0.14-1.73-0.2-2.51C404.95,30.96,404.17,31.01,403.27,31.08z M487.48,141.8c0-0.94,0-1.69,0-2.49
	c-0.87,0.09-1.69,0.18-2.53,0.27c0.05,0.89,0.09,1.72,0.13,2.57C485.92,142.03,486.68,141.92,487.48,141.8z M453.04,119.5
	c0-0.83,0-1.6,0-2.42c-0.91,0.11-1.73,0.21-2.46,0.31c0,0.88,0,1.65,0,2.48C451.4,119.75,452.22,119.63,453.04,119.5z
	 M469.97,104.74c-0.91,0.13-1.64,0.23-2.38,0.34c0.05,0.87,0.1,1.63,0.15,2.52c0.83-0.12,1.55-0.23,2.23-0.33
	C469.97,106.4,469.97,105.71,469.97,104.74z M615.89,358.4c0.81-0.16,1.61-0.33,2.42-0.49c-0.07-0.91-0.13-1.67-0.18-2.39
	c-0.95,0.09-1.79,0.18-2.59,0.25C615.67,356.72,615.78,357.55,615.89,358.4z M594.59,198.54c0.14,0.94,0.27,1.78,0.41,2.67
	c0.78-0.14,1.54-0.27,2.35-0.41c-0.06-0.81-0.12-1.58-0.17-2.26C596.3,198.54,595.51,198.54,594.59,198.54z M571.56,276.65
	c-0.95,0.05-1.71,0.08-2.51,0.12c0.07,0.88,0.14,1.74,0.22,2.6c0.86-0.09,1.62-0.18,2.41-0.26
	C571.64,278.31,571.6,277.53,571.56,276.65z M540.39,86.77c0-0.79,0-1.62,0-2.48c-0.89,0.05-1.71,0.09-2.52,0.14
	c0.08,0.9,0.16,1.71,0.23,2.52C538.87,86.89,539.57,86.83,540.39,86.77z M605.03,205.05c-0.85,0.17-1.59,0.31-2.28,0.45
	c0.04,0.85,0.08,1.6,0.12,2.34c0.9-0.07,1.72-0.14,2.54-0.21C605.28,206.81,605.17,206.04,605.03,205.05z M559.36,208.83
	c0.14,1.05,0.25,1.89,0.35,2.72c0.77-0.15,1.53-0.3,2.4-0.47c-0.04-0.77-0.07-1.54-0.11-2.26
	C561.09,208.83,560.3,208.83,559.36,208.83z M549.62,231.42c-0.9,0.12-1.59,0.22-2.33,0.32c0.04,0.8,0.08,1.58,0.12,2.54
	c0.81-0.12,1.53-0.22,2.21-0.32C549.62,233.09,549.62,232.37,549.62,231.42z M604.26,403.73c-0.9,0.07-1.72,0.14-2.54,0.2
	c0.11,0.93,0.2,1.76,0.3,2.63c0.87-0.13,1.61-0.24,2.36-0.35C604.34,405.31,604.3,404.53,604.26,403.73z M358.98,20.54
	c0,0.9,0,1.8,0,2.69c0.98-0.19,1.86-0.36,2.72-0.53c-0.12-0.82-0.24-1.57-0.35-2.31C360.5,20.45,359.74,20.49,358.98,20.54z
	 M600.46,389.48c0.12,0.9,0.24,1.78,0.36,2.66c0.86-0.11,1.61-0.21,2.35-0.3c-0.06-0.83-0.11-1.58-0.17-2.35
	C602.18,389.48,601.36,389.48,600.46,389.48z M339.19,15.15c-0.92,0-1.68,0-2.47,0c0.14,0.92,0.27,1.75,0.4,2.57
	c0.82-0.06,1.58-0.11,2.4-0.17C339.42,16.79,339.31,16.03,339.19,15.15z M274.11,95.48c-0.12-1.01-0.22-1.83-0.31-2.54
	c-0.92,0-1.75,0-2.65,0c0.16,0.86,0.31,1.68,0.47,2.54C272.44,95.48,273.22,95.48,274.11,95.48z M550.25,270.3
	c1.03-0.11,1.87-0.19,2.77-0.29c-0.18-0.83-0.35-1.65-0.54-2.51c-0.8,0.08-1.53,0.15-2.23,0.21
	C550.25,268.61,550.25,269.4,550.25,270.3z M472.84,56.51c-0.05-0.91-0.1-1.66-0.14-2.41c-0.85,0.06-1.61,0.11-2.42,0.16
	c0.06,0.82,0.11,1.63,0.16,2.42C471.23,56.62,471.93,56.57,472.84,56.51z M505.5,57.18c-0.05-0.83-0.1-1.65-0.14-2.47
	c-1.01,0.08-1.82,0.15-2.65,0.22c0.19,0.94,0.33,1.69,0.48,2.44C503.96,57.31,504.66,57.25,505.5,57.18z M455.7,55.35
	c0.18,0.95,0.33,1.78,0.49,2.61c0.82-0.17,1.55-0.33,2.3-0.48c-0.05-0.82-0.1-1.52-0.15-2.25C457.49,55.27,456.72,55.31,455.7,55.35
	z M448.89,56.17c0.07,0.83,0.14,1.64,0.21,2.5c0.84-0.14,1.51-0.24,2.18-0.35c-0.05-0.9-0.09-1.66-0.13-2.49
	C450.36,55.95,449.68,56.05,448.89,56.17z M625.89,390.96c0.09,0.92,0.17,1.74,0.25,2.56c0.92-0.07,1.68-0.13,2.45-0.19
	c-0.1-0.89-0.19-1.71-0.28-2.48C627.48,390.89,626.78,390.92,625.89,390.96z M570.18,330.83c0.11,0.76,0.23,1.55,0.35,2.37
	c0.83-0.07,1.52-0.13,2.25-0.19c-0.05-0.9-0.09-1.71-0.13-2.44C571.79,330.66,571.09,330.73,570.18,330.83z M560.21,229.4
	c-0.06-0.86-0.11-1.67-0.17-2.47c-1,0.1-1.82,0.18-2.66,0.26c0.2,0.92,0.37,1.67,0.53,2.42C558.7,229.53,559.39,229.47,560.21,229.4
	z M632.89,401.44c-0.85,0.06-1.65,0.11-2.41,0.17c0.11,0.93,0.2,1.68,0.28,2.37c0.9,0,1.73,0,2.59,0
	C633.2,403.08,633.05,402.26,632.89,401.44z M596.19,213.02c0.06,0.84,0.11,1.66,0.17,2.54c0.85-0.12,1.53-0.22,2.23-0.33
	c-0.04-0.89-0.08-1.66-0.12-2.49C597.68,212.84,596.98,212.93,596.19,213.02z M666.17,577.75c0-0.94,0-1.71,0-2.48
	c-0.95,0.09-1.79,0.18-2.66,0.26c0.15,0.87,0.29,1.71,0.43,2.54C664.69,577.96,665.37,577.86,666.17,577.75z M451.65,47.47
	c0.83,0,1.72,0,2.66,0c-0.16-0.92-0.31-1.75-0.48-2.65c-0.79,0.18-1.52,0.35-2.19,0.5C451.65,46.1,451.65,46.73,451.65,47.47z
	 M370,23.22c0.07,0.85,0.14,1.61,0.21,2.46c0.85-0.12,1.67-0.23,2.48-0.35c-0.12-0.81-0.21-1.5-0.32-2.24
	C371.53,23.14,370.77,23.18,370,23.22z M552.57,212.21c0.91-0.1,1.6-0.18,2.38-0.27c-0.06-0.88-0.11-1.7-0.16-2.55
	c-0.85,0.12-1.53,0.22-2.22,0.32C552.57,210.5,552.57,211.22,552.57,212.21z M545.31,210.45c0.1,0.77,0.18,1.45,0.26,2.09
	c0.91,0,1.68,0,2.45,0c-0.05-0.89-0.1-1.72-0.15-2.6C547.12,210.09,546.36,210.24,545.31,210.45z M639.07,515.05
	c-0.05-0.83-0.09-1.59-0.13-2.32c-0.93,0.06-1.74,0.12-2.55,0.17c0.16,0.9,0.3,1.71,0.44,2.54
	C637.67,515.29,638.36,515.17,639.07,515.05z M601.23,230.74c0.06,0.82,0.12,1.65,0.18,2.42c0.89,0,1.75,0,2.66,0
	c-0.14-0.85-0.29-1.7-0.43-2.53C602.85,230.67,602.07,230.71,601.23,230.74z M483.1,131.44c-0.12-0.8-0.24-1.55-0.35-2.3
	c-0.82,0.06-1.52,0.11-2.29,0.16c0.06,0.89,0.12,1.71,0.18,2.49C481.52,131.66,482.28,131.55,483.1,131.44z M550.46,325.52
	c-0.93,0-1.76,0-2.61,0c0.17,0.94,0.31,1.77,0.46,2.6c0.79-0.15,1.53-0.3,2.28-0.44C550.54,326.86,550.5,326.18,550.46,325.52z
	 M401.21,20.55c-0.91,0-1.75,0-2.66,0c0.18,0.83,0.36,1.65,0.56,2.52c0.75-0.06,1.45-0.12,2.22-0.19
	C401.28,22.05,401.25,21.28,401.21,20.55z M321.22,44.52c0.96-0.17,1.78-0.32,2.59-0.46c-0.14-0.81-0.26-1.5-0.39-2.24
	c-0.83,0.05-1.6,0.09-2.35,0.14C321.12,42.82,321.16,43.59,321.22,44.52z M383.18,21c-0.06-0.84-0.11-1.62-0.17-2.44
	c-0.85,0.1-1.66,0.19-2.38,0.28c0,0.84,0,1.54,0,2.3C381.51,21.09,382.33,21.04,383.18,21z M616.88,383.37
	c-0.09-0.9-0.16-1.63-0.23-2.34c-0.97,0.06-1.75,0.12-2.55,0.17c0.16,0.9,0.3,1.73,0.44,2.56
	C615.29,383.63,615.98,383.52,616.88,383.37z M448.69,69.45c-0.05-0.96-0.09-1.79-0.13-2.63c-0.8,0.12-1.55,0.23-2.28,0.34
	c0.06,0.86,0.11,1.63,0.17,2.47C447.15,69.58,447.85,69.52,448.69,69.45z M459.1,133.48c0.93-0.05,1.71-0.09,2.5-0.13
	c-0.1-0.88-0.2-1.73-0.29-2.57c-0.89,0.06-1.72,0.12-2.59,0.19C458.83,131.74,458.95,132.51,459.1,133.48z M558.84,201.7
	c0.18,0.92,0.33,1.67,0.49,2.46c0.8-0.07,1.53-0.14,2.32-0.21c-0.07-0.91-0.12-1.66-0.18-2.39
	C560.58,201.61,559.8,201.65,558.84,201.7z M603.47,381.1c0.8-0.1,1.55-0.2,2.26-0.29c0-0.87,0-1.65,0-2.49
	c-0.89,0.09-1.69,0.18-2.49,0.26C603.32,379.46,603.39,380.23,603.47,381.1z M610.28,377.97c0.11,0.93,0.21,1.7,0.29,2.43
	c0.89,0,1.76,0,2.6,0c-0.15-0.91-0.27-1.69-0.39-2.43C612,377.97,611.23,377.97,610.28,377.97z M572.92,153.78
	c0.05,0.94,0.08,1.71,0.12,2.5c0.84-0.1,1.67-0.2,2.53-0.31c-0.08-0.92-0.15-1.7-0.22-2.5
	C574.57,153.57,573.78,153.67,572.92,153.78z M565.04,353.16c0.05,0.84,0.09,1.6,0.14,2.45c0.85-0.07,1.59-0.14,2.34-0.2
	c-0.12-0.88-0.23-1.65-0.35-2.5C566.45,352.99,565.78,353.07,565.04,353.16z M427.85,36.24c-0.88,0.05-1.67,0.09-2.5,0.13
	c0.06,0.83,0.13,1.67,0.2,2.51c0.88-0.06,1.66-0.12,2.49-0.17C427.98,37.95,427.92,37.18,427.85,36.24z M569.06,358.59
	c0.82-0.05,1.51-0.09,2.19-0.13c0-0.87,0-1.63,0-2.44c-0.92,0.11-1.74,0.21-2.52,0.31C568.84,357.1,568.93,357.72,569.06,358.59z
	 M420.49,32.22c-0.2-0.97-0.37-1.75-0.53-2.52c-0.82,0.14-1.49,0.25-2.24,0.37c0.05,0.85,0.1,1.6,0.15,2.33
	C418.76,32.34,419.53,32.28,420.49,32.22z M657.86,564.05c-0.05-0.8-0.1-1.56-0.15-2.31c-0.86,0-1.7,0-2.6,0
	c0.13,0.9,0.26,1.78,0.39,2.68C656.34,564.29,657.1,564.17,657.86,564.05z M424.24,35.47c-0.1-0.91-0.19-1.74-0.27-2.5
	c-0.9,0-1.73,0-2.6,0c0.15,0.88,0.29,1.71,0.42,2.5C422.62,35.47,423.4,35.47,424.24,35.47z M502.01,430.65
	c-0.95,0.16-1.84,0.3-2.72,0.45c0.08,0.83,0.14,1.52,0.21,2.26c0.85-0.1,1.67-0.2,2.51-0.29
	C502.01,432.26,502.01,431.49,502.01,430.65z M513.22,176.74c0.18,0.93,0.33,1.68,0.5,2.5c0.78-0.07,1.53-0.13,2.37-0.21
	c-0.07-0.92-0.13-1.73-0.18-2.48C514.97,176.62,514.19,176.67,513.22,176.74z M506.51,180.1c0.92-0.16,1.66-0.28,2.4-0.4
	c-0.06-0.9-0.12-1.65-0.17-2.42c-0.86,0.08-1.62,0.15-2.4,0.23C506.4,178.32,506.45,179.09,506.51,180.1z M549.21,255.77
	c0.85,0,1.71,0,2.61,0c-0.15-0.92-0.29-1.76-0.43-2.65c-0.78,0.06-1.55,0.12-2.35,0.18C549.1,254.11,549.15,254.9,549.21,255.77z
	 M621.67,311.85c-0.94,0.14-1.71,0.26-2.51,0.38c0.11,0.77,0.22,1.46,0.33,2.23c0.85-0.06,1.6-0.12,2.32-0.18
	C621.76,313.43,621.72,312.72,621.67,311.85z M390.17,17.94c-0.95,0-1.72,0-2.59,0c0.17,0.89,0.32,1.69,0.47,2.48
	c0.79-0.08,1.47-0.15,2.23-0.23C390.25,19.46,390.21,18.77,390.17,17.94z M493.29,457.03c-0.89,0-1.69,0-2.5,0
	c0.09,0.88,0.16,1.62,0.24,2.39c0.82-0.09,1.57-0.16,2.38-0.25C493.36,458.39,493.32,457.71,493.29,457.03z M605.74,251.73
	c-1.03,0.18-1.86,0.33-2.73,0.48c0.15,0.8,0.3,1.55,0.45,2.36c0.81-0.08,1.55-0.16,2.28-0.23
	C605.74,253.42,605.74,252.64,605.74,251.73z M601.85,364.05c0.15,0.9,0.27,1.68,0.4,2.5c0.77,0,1.55,0,2.4,0
	c-0.08-0.88-0.16-1.72-0.23-2.5C603.59,364.05,602.82,364.05,601.85,364.05z M560.8,183.47c0.15,0.9,0.28,1.73,0.42,2.58
	c0.79-0.13,1.52-0.25,2.23-0.37c0-0.84,0-1.59,0-2.36C562.5,183.38,561.66,183.43,560.8,183.47z M397.26,17.33
	c-0.76,0.1-1.5,0.19-2.26,0.29c0.08,0.88,0.15,1.62,0.22,2.36c0.91-0.12,1.65-0.22,2.38-0.31C397.48,18.8,397.38,18.11,397.26,17.33
	z M568.1,182.95c0.1,0.93,0.17,1.7,0.26,2.55c0.83-0.09,1.66-0.18,2.5-0.27c-0.14-0.86-0.26-1.56-0.37-2.27
	C569.72,182.95,569.01,182.95,568.1,182.95z M662.68,574.79c-0.18-0.93-0.33-1.74-0.49-2.55c-0.79,0.05-1.55,0.11-2.39,0.16
	c0.1,0.83,0.2,1.64,0.29,2.39C660.99,574.79,661.77,574.79,662.68,574.79z M509.61,187.06c-0.04-0.9-0.08-1.73-0.12-2.61
	c-0.82,0.08-1.63,0.15-2.45,0.23c0.06,0.87,0.11,1.63,0.17,2.38C508.06,187.06,508.82,187.06,509.61,187.06z M663.21,610.57
	c0.89-0.1,1.63-0.18,2.36-0.26c-0.14-0.89-0.24-1.57-0.33-2.16c-0.89,0-1.66,0-2.44,0C662.92,608.94,663.04,609.64,663.21,610.57z
	 M458.88,102.13c-0.98,0.12-1.74,0.22-2.49,0.31c0.09,0.9,0.18,1.72,0.26,2.5c0.82-0.14,1.5-0.26,2.23-0.38
	C458.88,103.77,458.88,103.01,458.88,102.13z M634.02,497.66c0-0.95,0-1.72,0-2.52c-0.93,0.1-1.75,0.19-2.56,0.28
	c0.1,0.88,0.18,1.64,0.26,2.4C632.49,497.77,633.19,497.72,634.02,497.66z M564.29,179.67c0.08,0.87,0.16,1.69,0.23,2.5
	c0.91-0.13,1.66-0.23,2.43-0.34c-0.07-0.92-0.12-1.67-0.17-2.41C565.87,179.52,565.08,179.6,564.29,179.67z M606.41,427.86
	c-0.11-0.86-0.21-1.64-0.32-2.46c-0.78,0.07-1.54,0.13-2.37,0.2c0.08,0.85,0.16,1.67,0.24,2.49
	C604.81,428.01,605.52,427.95,606.41,427.86z M599.36,248.99c0.07,0.95,0.12,1.71,0.18,2.53c0.84-0.06,1.64-0.13,2.46-0.19
	c-0.23-0.95-0.43-1.75-0.63-2.55C600.67,248.85,600.06,248.92,599.36,248.99z M659.34,565.22c0.06,0.87,0.11,1.63,0.17,2.47
	c0.74-0.07,1.48-0.14,2.17-0.2c0-0.92,0-1.74,0-2.58C660.88,565.01,660.18,565.11,659.34,565.22z M562.04,248.38
	c-0.97,0.17-1.74,0.31-2.53,0.44c0.06,0.81,0.12,1.62,0.18,2.44c0.86-0.12,1.61-0.21,2.34-0.31
	C562.04,250.05,562.04,249.27,562.04,248.38z M267.57,103.23c-0.09-0.96-0.16-1.7-0.23-2.45c-0.93,0.09-1.69,0.17-2.44,0.24
	c0.09,0.87,0.17,1.64,0.25,2.4C265.96,103.35,266.66,103.3,267.57,103.23z M605.55,280.88c0.08,0.95,0.14,1.71,0.21,2.51
	c0.81-0.08,1.57-0.16,2.33-0.24c0-0.85,0-1.62,0-2.42C607.22,280.79,606.44,280.83,605.55,280.88z M530.28,80.49
	c0.93-0.12,1.7-0.22,2.51-0.33c0-0.88,0-1.74,0-2.61c-0.9,0.16-1.74,0.3-2.51,0.44C530.28,78.81,530.28,79.59,530.28,80.49z
	 M672.11,592.13c1.01-0.2,1.83-0.36,2.71-0.53c-0.2-0.81-0.38-1.53-0.56-2.3c-0.81,0.07-1.49,0.13-2.15,0.19
	C672.11,590.41,672.11,591.18,672.11,592.13z M589.6,194.39c0-0.88,0-1.69,0-2.55c-0.85,0.14-1.6,0.26-2.36,0.39
	c0,0.83,0,1.59,0,2.37C588.09,194.52,588.85,194.45,589.6,194.39z M490.78,499.26c0.95-0.12,1.7-0.21,2.47-0.3
	c-0.16-0.83-0.3-1.5-0.42-2.1c-0.86,0-1.56,0-2.27,0C490.63,497.65,490.69,498.36,490.78,499.26z M261.5,104.81
	c0.1,0.9,0.2,1.71,0.29,2.5c0.88-0.12,1.62-0.23,2.4-0.33c-0.08-0.88-0.15-1.61-0.22-2.34C263.12,104.71,262.42,104.75,261.5,104.81
	z M563.04,176.14c-1.07,0.18-1.88,0.31-2.68,0.45c0.15,0.79,0.27,1.46,0.4,2.17c0.83-0.09,1.57-0.17,2.28-0.25
	C563.04,177.68,563.04,176.98,563.04,176.14z M659.52,583.29c-0.79,0.07-1.54,0.13-2.34,0.2c0.04,0.82,0.08,1.59,0.13,2.4
	c0.87-0.08,1.69-0.15,2.47-0.22C659.69,584.81,659.61,584.05,659.52,583.29z M556.13,252.6c0.05,0.86,0.09,1.69,0.14,2.54
	c0.88-0.13,1.69-0.25,2.53-0.37c-0.07-0.91-0.13-1.67-0.18-2.4C557.7,252.46,556.91,252.53,556.13,252.6z M564.11,356.73
	c-0.89,0.12-1.63,0.23-2.37,0.33c0.07,0.87,0.14,1.62,0.2,2.36c0.9-0.05,1.66-0.1,2.41-0.15
	C564.27,358.43,564.2,357.66,564.11,356.73z M567.08,376.9c0.79-0.11,1.41-0.19,2.11-0.29c-0.03-0.76-0.06-1.45-0.1-2.24
	c-0.9,0.04-1.71,0.08-2.54,0.12C566.74,375.37,566.89,376.05,567.08,376.9z M567.72,178.3c0.85-0.09,1.67-0.17,2.43-0.25
	c0-0.96,0-1.79,0-2.61c-0.9,0.16-1.67,0.29-2.43,0.43C567.72,176.65,567.72,177.43,567.72,178.3z M554.72,278.01
	c0.1,0.96,0.18,1.71,0.27,2.5c0.83-0.15,1.56-0.29,2.21-0.41c0-0.87,0-1.56,0-2.27C556.35,277.89,555.59,277.95,554.72,278.01z
	 M434.91,75.32c-0.93,0.08-1.7,0.15-2.53,0.23c0.05,0.9,0.1,1.76,0.14,2.6c0.88-0.18,1.62-0.34,2.39-0.5
	C434.91,76.91,434.91,76.23,434.91,75.32z M597.68,356.34c0.78-0.12,1.51-0.23,2.34-0.36c-0.06-0.83-0.12-1.56-0.18-2.32
	c-0.9,0.09-1.64,0.16-2.35,0.23C597.55,354.78,597.61,355.54,597.68,356.34z M620.14,377.11c-0.92,0.09-1.76,0.17-2.57,0.25
	c0.08,0.86,0.16,1.64,0.24,2.46c0.8-0.07,1.56-0.14,2.34-0.21C620.14,378.74,620.14,377.96,620.14,377.11z M604.37,279.77
	c-0.06-0.84-0.11-1.65-0.17-2.45c-0.93,0.09-1.71,0.16-2.49,0.23c0.1,0.87,0.19,1.69,0.28,2.47
	C602.84,279.94,603.54,279.86,604.37,279.77z M394.05,21.22c-0.89,0.04-1.71,0.08-2.51,0.12c0.06,0.9,0.12,1.72,0.17,2.54
	c0.88-0.13,1.69-0.25,2.5-0.37C394.15,22.68,394.1,21.99,394.05,21.22z M627.64,492.09c0.07,0.84,0.14,1.6,0.21,2.39
	c0.82-0.07,1.58-0.14,2.35-0.21c0-0.94,0-1.79,0-2.64C629.35,491.79,628.58,491.92,627.64,492.09z M653.4,553.72
	c-0.12-0.82-0.22-1.49-0.32-2.17c-0.84,0-1.54,0-2.27,0c0.05,0.86,0.1,1.64,0.14,2.42C651.72,553.89,652.42,553.82,653.4,553.72z
	 M621.56,351.68c-0.92,0.09-1.76,0.17-2.58,0.24c0.08,0.89,0.15,1.71,0.22,2.54c0.83-0.11,1.59-0.21,2.37-0.31
	C621.56,353.3,621.56,352.52,621.56,351.68z M379.66,24.55c0-0.82,0-1.5,0-2.23c-0.91,0.08-1.73,0.15-2.51,0.22
	c0.08,0.93,0.16,1.74,0.23,2.56C378.16,24.91,378.85,24.75,379.66,24.55z M390.72,27.34c0-0.79,0-1.61,0-2.45
	c-0.89,0.11-1.72,0.2-2.51,0.3c0.08,0.93,0.15,1.75,0.21,2.52C389.23,27.58,389.92,27.47,390.72,27.34z M537.6,206.14
	c0.83-0.09,1.59-0.16,2.47-0.26c-0.05-0.87-0.1-1.68-0.15-2.48c-0.91,0.09-1.69,0.16-2.5,0.24
	C537.49,204.5,537.54,205.31,537.6,206.14z M516.84,175.47c0.94-0.11,1.7-0.21,2.51-0.3c0-0.89,0-1.74,0-2.61
	c-0.9,0.16-1.74,0.3-2.51,0.44C516.84,173.79,516.84,174.57,516.84,175.47z M646.47,503.93c0.95-0.26,1.74-0.47,2.52-0.68
	c-0.06-0.75-0.1-1.37-0.15-2.01c-0.86,0-1.69,0-2.53,0C646.36,502.14,646.41,502.96,646.47,503.93z M617.51,417.18
	c0,0.99,0,1.81,0,2.65c0.95-0.18,1.78-0.34,2.65-0.51c-0.19-0.78-0.36-1.45-0.56-2.25C618.87,417.1,618.18,417.14,617.51,417.18z
	 M248.56,133.75c-0.07-0.99-0.12-1.8-0.18-2.64c-0.82,0.19-1.56,0.37-2.42,0.57c0.09,0.7,0.18,1.38,0.27,2.07
	C247.03,133.75,247.72,133.75,248.56,133.75z M231.14,172.44c0.09,0.98,0.16,1.72,0.23,2.48c0.92-0.13,1.67-0.23,2.51-0.34
	c-0.11-0.8-0.2-1.47-0.3-2.14C232.71,172.44,231.99,172.44,231.14,172.44z M615.42,448.7c-0.05-0.86-0.1-1.68-0.15-2.48
	c-0.9,0.08-1.67,0.16-2.5,0.24c0.06,0.84,0.12,1.65,0.18,2.48C613.77,448.85,614.53,448.78,615.42,448.7z M588.33,206.52
	c0.11,0.95,0.19,1.71,0.28,2.47c0.83-0.08,1.52-0.15,2.24-0.23c0-0.84,0-1.62,0-2.47C590.01,206.38,589.24,206.44,588.33,206.52z
	 M622.35,348.13c0.05,0.87,0.1,1.64,0.15,2.45c0.77-0.08,1.55-0.16,2.38-0.25c0-0.88,0-1.74,0-2.61
	C624.05,347.85,623.27,347.98,622.35,348.13z M607.25,381.92c0.05,0.94,0.08,1.65,0.12,2.48c0.73-0.09,1.41-0.17,2.14-0.25
	c0-0.86,0-1.62,0-2.47C608.74,381.77,608.04,381.84,607.25,381.92z M580.54,173.57c-0.07-0.83-0.13-1.6-0.2-2.43
	c-0.81,0-1.58,0-2.4,0c0.06,0.88,0.12,1.74,0.18,2.61C578.97,173.69,579.74,173.63,580.54,173.57z M435.71,71.69
	c0.09,0.96,0.16,1.71,0.23,2.48c0.81-0.08,1.56-0.15,2.39-0.24c-0.04-0.85-0.07-1.61-0.11-2.39
	C437.34,71.6,436.57,71.64,435.71,71.69z M534.11,593.07c-0.13-0.97-0.24-1.79-0.36-2.69c-0.84,0.17-1.58,0.32-2.37,0.49
	c0.1,0.86,0.19,1.6,0.27,2.33C532.5,593.15,533.21,593.11,534.11,593.07z M666.18,617.62c0-0.87,0-1.72,0-2.6
	c-0.87,0.15-1.69,0.3-2.5,0.44c0.07,0.83,0.14,1.59,0.21,2.34C664.72,617.73,665.42,617.68,666.18,617.62z M601.61,414.83
	c-1,0.08-1.8,0.14-2.62,0.21c0.16,0.88,0.3,1.68,0.45,2.48c0.83-0.15,1.5-0.27,2.17-0.39C601.61,416.37,601.61,415.68,601.61,414.83
	z M320.47,48.03c-0.05-0.95-0.1-1.72-0.15-2.53c-0.81,0.14-1.55,0.26-2.33,0.4c0.05,0.81,0.1,1.51,0.15,2.24
	C318.93,48.1,319.64,48.07,320.47,48.03z M602.41,342.83c-0.75,0-1.49,0-2.22,0c0,0.96,0,1.79,0,2.62c0.92-0.18,1.75-0.34,2.64-0.51
	C602.68,344.23,602.55,343.56,602.41,342.83z M542.5,387.28c0.13,0.91,0.25,1.67,0.37,2.53c0.68-0.08,1.36-0.17,2.09-0.26
	c0-0.91,0-1.68,0-2.46C544.15,387.16,543.38,387.21,542.5,387.28z M618.89,489.15c-0.68,0.07-1.48,0.15-2.29,0.23
	c0.09,0.92,0.16,1.73,0.24,2.54c0.8-0.09,1.49-0.18,2.17-0.26C618.96,490.85,618.93,490.08,618.89,489.15z M629.45,389.65
	c0.93-0.08,1.73-0.14,2.51-0.2c-0.09-0.91-0.16-1.68-0.23-2.47c-0.85,0.09-1.64,0.18-2.42,0.26
	C629.36,388.06,629.4,388.76,629.45,389.65z M465.29,49.99c-0.15-0.91-0.28-1.64-0.41-2.43c-0.82,0.09-1.51,0.17-2.21,0.25
	c0.05,0.83,0.1,1.58,0.15,2.33C463.63,50.09,464.32,50.05,465.29,49.99z M617.73,390.79c-0.2-0.99-0.34-1.73-0.5-2.55
	c-0.82,0.08-1.55,0.16-2.21,0.22c0,0.9,0,1.6,0,2.33C615.9,390.79,616.73,390.79,617.73,390.79z M555.91,126.09
	c0.18,0.82,0.35,1.64,0.55,2.55c0.7-0.05,1.38-0.1,2.05-0.15c-0.06-0.93-0.12-1.76-0.17-2.56
	C557.49,125.99,556.72,126.04,555.91,126.09z M572.28,146.58c0,0.96,0,1.67,0,2.44c0.94-0.06,1.83-0.12,2.67-0.18
	c-0.18-0.83-0.34-1.53-0.5-2.26C573.76,146.58,573.07,146.58,572.28,146.58z M611.49,445.36c0-0.96,0-1.8,0-2.65
	c-0.9,0.17-1.72,0.32-2.53,0.47c0.07,0.81,0.14,1.56,0.21,2.34C609.92,445.47,610.6,445.42,611.49,445.36z M626.03,528.19
	c-0.94,0.16-1.69,0.28-2.54,0.42c0.13,0.74,0.24,1.41,0.36,2.13c0.78,0,1.46,0,2.18,0C626.03,529.89,626.03,529.13,626.03,528.19z
	 M618.9,391.7c0,1.05,0,1.88,0,2.72c0.94-0.18,1.77-0.34,2.68-0.52c-0.2-0.8-0.38-1.52-0.55-2.2
	C620.27,391.7,619.66,391.7,618.9,391.7z M466.18,50.79c0.22,0.92,0.41,1.72,0.6,2.5c0.79-0.06,1.47-0.11,2.2-0.16
	c-0.04-0.83-0.08-1.6-0.11-2.35C468.01,50.79,467.17,50.79,466.18,50.79z M508.71,53.32c0-0.84,0-1.67,0-2.51
	c-0.88,0.14-1.65,0.26-2.42,0.38c0.06,0.8,0.13,1.56,0.19,2.33C507.27,53.45,507.97,53.39,508.71,53.32z M602.79,302.82
	c-0.86,0.07-1.63,0.13-2.44,0.19c0.1,0.88,0.18,1.68,0.27,2.45c0.86-0.09,1.54-0.16,2.28-0.24
	C602.86,304.37,602.83,303.6,602.79,302.82z M459.42,54.04c0.93-0.11,1.67-0.2,2.46-0.3c-0.07-0.87-0.13-1.62-0.19-2.36
	c-0.9,0.07-1.67,0.13-2.44,0.18C459.3,52.38,459.35,53.14,459.42,54.04z M606.89,295.27c0,0.98,0,1.81,0,2.7
	c0.95-0.19,1.82-0.37,2.7-0.55c-0.2-0.81-0.37-1.48-0.56-2.25C608.3,295.21,607.62,295.24,606.89,295.27z M491.23,477.34
	c-0.1-0.84-0.19-1.51-0.26-2.1c-0.86,0-1.55,0-2.32,0c0.07,0.94,0.13,1.8,0.2,2.66C489.68,477.69,490.36,477.54,491.23,477.34z
	 M617.12,510c-0.13-0.99-0.23-1.78-0.33-2.52c-0.86,0.07-1.55,0.12-2.24,0.17c0,0.78,0,1.55,0,2.34
	C615.39,510,616.15,510,617.12,510z M493.17,69.15c0.94-0.06,1.71-0.11,2.51-0.17c-0.1-0.86-0.19-1.67-0.28-2.46
	c-0.88,0.1-1.62,0.18-2.34,0.25C493.1,67.6,493.13,68.3,493.17,69.15z M626.91,527.16c0.79-0.13,1.53-0.25,2.33-0.38
	c-0.05-0.82-0.09-1.51-0.13-2.24c-0.89,0.06-1.67,0.11-2.41,0.15C626.78,525.57,626.84,526.34,626.91,527.16z M615.66,395.66
	c0.09,0.87,0.16,1.61,0.24,2.39c0.84-0.09,1.58-0.17,2.29-0.25c0-0.94,0-1.76,0-2.59C617.32,395.36,616.56,395.5,615.66,395.66z
	 M546.52,237.89c0-0.85,0-1.68,0-2.53c-0.95,0.05-1.84,0.1-2.73,0.14c0.16,0.81,0.31,1.57,0.47,2.38
	C545,237.89,545.76,237.89,546.52,237.89z M440.25,55.64c-0.07-0.89-0.13-1.62-0.19-2.34c-0.85,0.07-1.55,0.13-2.31,0.2
	c0.05,0.86,0.09,1.67,0.14,2.49C438.68,55.87,439.36,55.77,440.25,55.64z M608.06,475.75c0.07,0.92,0.13,1.75,0.2,2.58
	c0.88-0.2,1.62-0.36,2.35-0.53c-0.05-0.83-0.09-1.54-0.13-2.27C609.62,475.61,608.84,475.68,608.06,475.75z M594.35,233.7
	c0.89-0.05,1.63-0.09,2.39-0.13c-0.08-0.94-0.14-1.75-0.21-2.56c-0.88,0.07-1.63,0.13-2.41,0.19
	C594.19,232.01,594.26,232.77,594.35,233.7z M606.22,298.77c-0.93,0.18-1.75,0.33-2.54,0.48c0.1,0.88,0.18,1.63,0.26,2.36
	c0.85-0.09,1.54-0.17,2.28-0.25C606.22,300.54,606.22,299.75,606.22,298.77z M598.9,328.41c0.09,0.96,0.16,1.71,0.24,2.5
	c0.84-0.1,1.59-0.19,2.37-0.28c-0.04-0.89-0.07-1.67-0.11-2.44C600.55,328.26,599.78,328.33,598.9,328.41z M535.26,232.77
	c-1.05,0.07-1.9,0.13-2.76,0.19c0.19,0.86,0.35,1.61,0.53,2.45c0.8-0.12,1.54-0.23,2.23-0.33
	C535.26,234.29,535.26,233.65,535.26,232.77z M601.21,407.39c-0.93,0.25-1.73,0.46-2.57,0.68c0.08,0.76,0.15,1.43,0.22,2.1
	c0.86,0,1.61,0,2.35,0C601.21,409.26,601.21,408.43,601.21,407.39z M484.34,63.86c-0.88,0.08-1.61,0.15-2.34,0.22
	c0.05,0.95,0.1,1.7,0.14,2.45c0.93-0.09,1.76-0.18,2.59-0.26C484.6,65.48,484.49,64.8,484.34,63.86z M622.93,368.6
	c-0.16-0.9-0.29-1.63-0.43-2.43c-0.81,0.04-1.5,0.07-2.18,0.11c0,0.93,0,1.74,0,2.55C621.2,368.75,621.97,368.68,622.93,368.6z
	 M556.72,231.11c-0.86,0-1.7,0-2.56,0c0.09,0.9,0.17,1.72,0.25,2.55c0.82-0.17,1.63-0.35,2.44-0.52
	C556.8,232.39,556.76,231.77,556.72,231.11z M566.57,369.65c0.79-0.06,1.46-0.12,2.07-0.16c0-0.96,0-1.76,0-2.56
	c-0.93,0.18-1.75,0.34-2.55,0.49C566.25,368.15,566.38,368.76,566.57,369.65z M650.99,511.72c0,0.87,0,1.64,0,2.47
	c0.89-0.08,1.71-0.15,2.59-0.23c-0.13-0.84-0.25-1.57-0.37-2.37C652.39,511.64,651.71,511.68,650.99,511.72z M655.81,582.43
	c0-0.88,0-1.76,0-2.69c-0.89,0.21-1.64,0.38-2.47,0.58c0.07,0.73,0.13,1.41,0.19,2.11C654.33,582.43,655.03,582.43,655.81,582.43z
	 M564.94,366.39c-0.18-0.82-0.36-1.63-0.53-2.42c-0.82,0.1-1.49,0.17-2.11,0.25c0,0.88,0,1.65,0,2.49
	C563.2,366.6,564.03,366.5,564.94,366.39z M426.37,61.7c-0.82,0.11-1.57,0.21-2.23,0.29c0,0.88,0,1.65,0,2.46
	c0.88-0.19,1.63-0.35,2.37-0.51C426.46,63.16,426.42,62.46,426.37,61.7z M429.59,81.58c0.77,0,1.47,0,2.09,0c0-0.91,0-1.73,0-2.56
	c-0.85,0.16-1.6,0.31-2.51,0.48C429.32,80.23,429.45,80.89,429.59,81.58z M608.9,403.38c0.06,0.81,0.13,1.66,0.2,2.54
	c0.83-0.18,1.63-0.35,2.44-0.52c-0.05-0.75-0.09-1.38-0.13-2.02C610.61,403.38,609.84,403.38,608.9,403.38z M534.34,83.42
	c0.83,0,1.59,0,2.41,0c-0.1-0.81-0.19-1.49-0.29-2.25c-0.86,0.04-1.62,0.08-2.46,0.12C534.13,82.09,534.23,82.75,534.34,83.42z
	 M441.17,62.93c-0.22-0.92-0.38-1.59-0.57-2.37c-0.73,0.05-1.42,0.1-2.16,0.16c0.06,0.79,0.11,1.47,0.16,2.22
	C439.42,62.93,440.18,62.93,441.17,62.93z M436.62,81.34c0.84-0.09,1.61-0.17,2.38-0.25c-0.16-0.89-0.29-1.64-0.43-2.43
	c-0.79,0.13-1.45,0.24-2.11,0.35C436.52,79.89,436.57,80.59,436.62,81.34z M626.9,498.1c-0.05-0.88-0.09-1.64-0.13-2.42
	c-0.95,0.07-1.78,0.14-2.7,0.21c0.22,0.85,0.4,1.58,0.6,2.37C625.47,498.2,626.15,498.15,626.9,498.1z M660.2,619.11
	c0.16,0.85,0.3,1.62,0.45,2.44c0.86-0.06,1.54-0.11,2.29-0.17c-0.09-0.86-0.16-1.6-0.23-2.27
	C661.83,619.11,661.13,619.11,660.2,619.11z M526.14,167.52c-0.24-0.9-0.43-1.62-0.64-2.39c-0.81,0.07-1.5,0.12-2.21,0.18
	c0.05,0.89,0.1,1.67,0.14,2.41C524.3,167.66,525.08,167.6,526.14,167.52z M493.24,153.3c0.17,0.87,0.33,1.67,0.48,2.45
	c0.82-0.12,1.49-0.22,2.22-0.33c-0.07-0.86-0.13-1.6-0.19-2.33C494.84,153.17,494.07,153.23,493.24,153.3z M597.31,309.26
	c0.84-0.09,1.52-0.16,2.26-0.24c-0.05-0.84-0.09-1.53-0.13-2.31c-0.87,0.05-1.64,0.1-2.44,0.15
	C597.1,307.65,597.19,308.34,597.31,309.26z M624.17,372.13c0.98-0.19,1.74-0.34,2.52-0.49c-0.12-0.72-0.22-1.34-0.35-2.06
	c-0.81,0.05-1.56,0.09-2.33,0.13C624.08,370.59,624.12,371.3,624.17,372.13z M551.23,361.54c0,0.99,0,1.81,0,2.66
	c0.91-0.19,1.73-0.36,2.55-0.53c-0.16-0.77-0.3-1.43-0.45-2.13C552.64,361.54,552.03,361.54,551.23,361.54z M634.94,509.68
	c-0.74,0.05-1.43,0.09-2.23,0.14c0.08,0.85,0.16,1.59,0.23,2.35c0.94-0.07,1.71-0.12,2.52-0.18
	C635.28,511.16,635.12,510.48,634.94,509.68z M605.46,236.29c0.89-0.1,1.57-0.17,2.26-0.24c-0.06-0.84-0.1-1.54-0.15-2.31
	c-0.92,0.06-1.74,0.11-2.61,0.17C605.13,234.73,605.27,235.41,605.46,236.29z M429.12,92.67c-0.08-0.88-0.14-1.57-0.22-2.36
	c-0.82,0.07-1.64,0.15-2.47,0.22c0.15,0.87,0.28,1.62,0.41,2.38C427.61,92.83,428.29,92.75,429.12,92.67z M610.04,428.48
	c-0.92,0.1-1.68,0.19-2.47,0.28c0.17,0.87,0.31,1.62,0.46,2.37c0.74-0.08,1.35-0.15,2.01-0.22
	C610.04,430.13,610.04,429.44,610.04,428.48z M409.66,23.24c0.18,0.83,0.34,1.57,0.51,2.4c0.76-0.06,1.43-0.11,2.21-0.17
	c-0.08-0.81-0.15-1.5-0.22-2.23C411.29,23.24,410.51,23.24,409.66,23.24z M598.28,402.97c0.84,0,1.53,0,2.29,0
	c-0.06-0.86-0.11-1.7-0.17-2.54c-0.91,0.11-1.73,0.2-2.53,0.3C598.01,401.49,598.14,402.18,598.28,402.97z M590.41,190.86
	c0.8-0.14,1.53-0.28,2.38-0.43c-0.07-0.74-0.14-1.43-0.22-2.2c-0.83,0.05-1.56,0.09-2.27,0.14
	C590.34,189.3,590.38,190.06,590.41,190.86z M527.62,587.55c0.06,0.99,0.11,1.73,0.16,2.48c0.82-0.1,1.51-0.18,2.3-0.28
	c-0.12-0.79-0.23-1.53-0.35-2.34C529.01,587.45,528.4,587.49,527.62,587.55z M669.09,611.41c-0.82,0.06-1.55,0.11-2.23,0.16
	c0,0.98,0,1.76,0,2.59c0.88-0.18,1.69-0.34,2.53-0.51C669.29,612.88,669.2,612.21,669.09,611.41z M598.38,363.38
	c0.91-0.04,1.67-0.07,2.54-0.11c-0.2-0.86-0.37-1.57-0.56-2.34c-0.8,0.08-1.47,0.15-2.14,0.22
	C598.28,361.92,598.32,362.54,598.38,363.38z M614.42,520.89c-0.05-0.8-0.1-1.57-0.15-2.35c-0.88,0.07-1.7,0.13-2.53,0.19
	c0.17,0.83,0.32,1.57,0.46,2.31C612.98,520.99,613.67,520.94,614.42,520.89z M661.05,600.05c-0.1-0.79-0.19-1.56-0.28-2.35
	c-0.81,0.06-1.57,0.12-2.34,0.18c0.07,0.86,0.14,1.63,0.2,2.46C659.46,600.25,660.22,600.15,661.05,600.05z M612.07,399.43
	c0.22,0.87,0.38,1.54,0.57,2.33c0.77-0.04,1.51-0.08,2.3-0.12c-0.09-0.9-0.16-1.65-0.23-2.38
	C613.81,399.32,613.04,399.37,612.07,399.43z M559.67,599.28c-0.8,0.07-1.47,0.12-2.19,0.18c0,0.87,0,1.64,0,2.47
	c0.82-0.16,1.57-0.3,2.39-0.46C559.81,600.75,559.75,600.07,559.67,599.28z M444.4,58.97c-0.11-0.9-0.2-1.65-0.3-2.44
	c-0.8,0.13-1.54,0.26-2.35,0.4c0.07,0.76,0.12,1.42,0.18,2.05C442.81,58.97,443.51,58.97,444.4,58.97z M622.62,521.22
	c0.26,0.98,0.45,1.7,0.66,2.47c0.8-0.08,1.48-0.15,2.17-0.22c-0.05-0.83-0.1-1.53-0.15-2.25
	C624.45,521.22,623.68,521.22,622.62,521.22z M645.59,576.15c0.91-0.17,1.59-0.29,2.38-0.44c-0.08-0.74-0.16-1.42-0.25-2.17
	c-0.8,0.04-1.48,0.08-2.13,0.12C645.59,574.52,645.59,575.23,645.59,576.15z M426.72,21.89c-1,0.08-1.76,0.14-2.56,0.2
	c0.14,0.79,0.27,1.55,0.39,2.26c0.76,0,1.45,0,2.17,0C426.72,23.53,426.72,22.76,426.72,21.89z M619.21,410.01
	c-0.75,0-1.51,0-2.34,0c0.08,0.9,0.15,1.65,0.22,2.4c0.93-0.06,1.7-0.11,2.57-0.17C619.5,411.45,619.36,410.76,619.21,410.01z
	 M614.67,441.45c-0.09-0.73-0.19-1.5-0.3-2.35c-0.89,0.08-1.57,0.15-2.3,0.21c0.04,0.95,0.08,1.77,0.12,2.6
	C613.05,441.75,613.75,441.62,614.67,441.45z M683.14,605.4c-0.04-0.83-0.08-1.67-0.12-2.59c-0.85,0.17-1.59,0.31-2.36,0.46
	c0.06,0.84,0.11,1.58,0.17,2.32C681.68,605.52,682.38,605.46,683.14,605.4z M510.15,566.24c-0.17-0.85-0.32-1.6-0.48-2.38
	c-0.79,0.08-1.46,0.15-2.12,0.22c0,0.89,0,1.65,0,2.42C508.4,566.41,509.17,566.34,510.15,566.24z M604.45,269.02
	c0.98-0.1,1.74-0.18,2.54-0.27c-0.1-0.81-0.2-1.55-0.31-2.38c-0.84,0.07-1.57,0.13-2.23,0.19
	C604.45,267.47,604.45,268.17,604.45,269.02z M460.51,68.58c0.94-0.19,1.81-0.37,2.69-0.55c-0.21-0.82-0.37-1.49-0.56-2.21
	c-0.8,0.07-1.49,0.13-2.13,0.18C460.51,66.93,460.51,67.71,460.51,68.58z M641.35,525.79c0.85,0,1.66,0,2.5,0
	c-0.13-0.85-0.25-1.62-0.37-2.46c-0.8,0.07-1.6,0.13-2.38,0.2C641.19,524.36,641.27,525.05,641.35,525.79z M434.13,23.93
	c-0.14-0.9-0.27-1.73-0.41-2.64c-0.78,0.18-1.51,0.35-2.23,0.51c0.06,0.83,0.12,1.51,0.16,2.13
	C432.54,23.93,433.3,23.93,434.13,23.93z M318.56,53.04c0,0.93,0,1.74,0,2.59c0.83-0.18,1.57-0.35,2.35-0.52
	c-0.07-0.77-0.13-1.44-0.19-2.07C319.92,53.04,319.25,53.04,318.56,53.04z M437.01,19.61c-0.05-0.72-0.1-1.39-0.15-2.08
	c-0.86,0-1.61,0-2.42,0c0.12,0.86,0.22,1.62,0.33,2.42C435.52,19.84,436.2,19.74,437.01,19.61z M365.5,55.03
	c0.84-0.08,1.66-0.17,2.53-0.26c-0.11-0.8-0.21-1.53-0.32-2.34c-0.8,0.04-1.48,0.07-2.21,0.11C365.5,53.43,365.5,54.2,365.5,55.03z
	 M591.78,220.79c-0.74,0.05-1.42,0.09-2.21,0.14c0.07,0.87,0.12,1.62,0.17,2.28c0.9,0,1.67,0,2.59,0
	C592.14,222.37,591.98,221.64,591.78,220.79z M623.16,397.39c0.79-0.12,1.46-0.22,2.06-0.3c-0.05-0.91-0.1-1.68-0.15-2.45
	c-0.83,0.06-1.65,0.12-2.47,0.18C622.78,395.66,622.95,396.41,623.16,397.39z M644.81,526.59c0.18,0.88,0.34,1.68,0.5,2.48
	c0.79-0.12,1.46-0.22,2.22-0.34c-0.07-0.84-0.12-1.57-0.18-2.3C646.43,526.49,645.66,526.54,644.81,526.59z M406.69,27.46
	c0.04,0.89,0.08,1.57,0.11,2.26c0.91,0,1.68,0,2.49,0c-0.17-0.89-0.32-1.72-0.49-2.61C408.12,27.23,407.51,27.33,406.69,27.46z
	 M522.8,158.08c0.07,0.99,0.12,1.73,0.17,2.51c0.9-0.14,1.65-0.25,2.39-0.36c-0.16-0.84-0.28-1.53-0.42-2.3
	C524.24,157.98,523.62,158.03,522.8,158.08z M614.04,376.23c0.73,0,1.48,0,2.33,0c-0.04-0.87-0.07-1.63-0.11-2.42
	c-0.94,0.06-1.77,0.12-2.68,0.18C613.74,374.79,613.88,375.47,614.04,376.23z M612.67,499.58c-0.16-0.83-0.3-1.56-0.46-2.38
	c-0.85,0.08-1.59,0.14-2.33,0.21c0.13,0.86,0.23,1.54,0.32,2.18C611.08,499.58,611.84,499.58,612.67,499.58z M471.28,147.99
	c0,0.88,0,1.7,0,2.54c0.86-0.11,1.62-0.2,2.49-0.31c-0.1-0.78-0.19-1.51-0.27-2.23C472.63,147.99,471.93,147.99,471.28,147.99z
	 M684.71,619.84c-0.14-0.91-0.27-1.74-0.4-2.63c-0.8,0.15-1.56,0.3-2.38,0.45c0.06,0.77,0.12,1.52,0.17,2.18
	C683.05,619.84,683.88,619.84,684.71,619.84z M595.3,411.92c0.09,0.91,0.16,1.59,0.23,2.19c0.87,0,1.63,0,2.5,0
	c-0.2-0.84-0.36-1.55-0.51-2.19C596.74,411.92,596.12,411.92,595.3,411.92z M460.63,107.85c0.92,0,1.69,0,2.46,0
	c-0.06-0.9-0.11-1.72-0.17-2.54c-0.88,0.22-1.68,0.42-2.46,0.61C460.52,106.62,460.57,107.18,460.63,107.85z M603.1,429.04
	c-0.89,0.24-1.68,0.45-2.45,0.65c0.05,0.81,0.1,1.47,0.14,2.13c0.88-0.06,1.63-0.12,2.3-0.17
	C603.1,430.76,603.1,429.94,603.1,429.04z M406.24,22.54c0.92-0.05,1.67-0.08,2.46-0.12c-0.15-0.88-0.29-1.68-0.43-2.46
	c-0.83,0.06-1.51,0.1-2.27,0.15C406.08,20.87,406.15,21.63,406.24,22.54z M462.91,136.84c0.88-0.09,1.63-0.18,2.42-0.26
	c-0.08-0.85-0.15-1.61-0.22-2.35c-0.86,0.06-1.6,0.12-2.33,0.17C462.82,135.21,462.86,135.91,462.91,136.84z M548.97,598.96
	c-0.15-0.89-0.28-1.62-0.42-2.4c-0.82,0.07-1.5,0.13-2.14,0.19c0,0.89,0,1.66,0,2.44C547.23,599.12,548,599.05,548.97,598.96z
	 M651.62,561.1c0.92-0.05,1.68-0.09,2.56-0.13c-0.2-0.85-0.37-1.56-0.55-2.33c-0.8,0.09-1.47,0.16-2.14,0.23
	C651.54,559.64,651.57,560.26,651.62,561.1z M619.39,452.01c-0.12-0.88-0.23-1.64-0.34-2.41c-0.86,0.07-1.62,0.13-2.49,0.21
	c0.12,0.79,0.22,1.5,0.33,2.2C617.76,452.01,618.46,452.01,619.39,452.01z M555.38,256.13c-0.95,0.15-1.76,0.27-2.54,0.39
	c0.17,0.92,0.3,1.68,0.44,2.45c0.77-0.13,1.44-0.24,2.1-0.35C555.38,257.79,555.38,257.09,555.38,256.13z M595.48,248.17
	c0.86-0.11,1.62-0.2,2.41-0.3c-0.04-0.82-0.08-1.64-0.12-2.49c-0.76,0.09-1.52,0.19-2.29,0.28
	C595.48,246.49,595.48,247.31,595.48,248.17z M504.51,47.74c-0.93,0-1.63,0-2.4,0c0.09,0.91,0.17,1.75,0.25,2.6
	c0.8-0.18,1.56-0.35,2.31-0.52C504.61,49.08,504.57,48.46,504.51,47.74z M522.14,164.18c-0.13-0.86-0.24-1.56-0.37-2.37
	c-0.78,0.04-1.54,0.08-2.34,0.12c0.05,0.9,0.09,1.66,0.12,2.44C520.42,164.3,521.19,164.25,522.14,164.18z M442,74.88
	c-0.96,0.07-1.72,0.13-2.52,0.19c0.13,0.79,0.24,1.46,0.35,2.14c0.83,0,1.59,0,2.4,0C442.15,76.44,442.08,75.75,442,74.88z
	 M590.23,201.5c-0.13-0.85-0.23-1.52-0.35-2.3c-0.77,0.04-1.45,0.08-2.17,0.12c0,0.98,0,1.8,0,2.66
	C588.54,201.83,589.3,201.68,590.23,201.5z M553.43,303.54c0.06,0.91,0.11,1.67,0.16,2.43c0.82-0.15,1.49-0.27,2.29-0.41
	c-0.08-0.73-0.16-1.41-0.23-2.13C554.91,303.47,554.28,303.5,553.43,303.54z M648.04,533.83c-0.77,0-1.6,0-2.53,0
	c0.18,0.78,0.35,1.51,0.54,2.29c0.83-0.06,1.52-0.1,2.22-0.15C648.19,535.22,648.12,534.6,648.04,533.83z M522.59,171.26
	c-0.14-0.85-0.26-1.53-0.39-2.29c-0.79,0.04-1.47,0.08-2.17,0.12c0,0.97,0,1.8,0,2.65C520.88,171.58,521.64,171.44,522.59,171.26z
	 M675.2,596.42c-0.94,0.1-1.7,0.17-2.49,0.26c0.13,0.78,0.24,1.45,0.35,2.11c0.85,0,1.61,0,2.39,0
	C675.37,598,675.29,597.3,675.2,596.42z M260.82,110.89c-0.08-0.98-0.13-1.71-0.19-2.45c-0.85,0.09-1.54,0.16-2.31,0.25
	c0.12,0.82,0.23,1.56,0.35,2.36C259.39,111,260.01,110.95,260.82,110.89z M553.27,343.39c0.12,0.76,0.23,1.47,0.33,2.09
	c0.87,0,1.56,0,2.32,0c-0.08-0.92-0.15-1.75-0.23-2.59C554.87,343.07,554.12,343.22,553.27,343.39z M369.58,16.07
	c0.05,0.94,0.09,1.63,0.13,2.41c0.8-0.07,1.53-0.14,2.38-0.22c-0.09-0.79-0.16-1.48-0.24-2.2
	C371.08,16.07,370.38,16.07,369.58,16.07z M635.46,432.32c-0.06-0.83-0.11-1.52-0.17-2.28c-0.87,0.05-1.69,0.09-2.52,0.13
	c0.17,0.91,0.31,1.67,0.46,2.45C633.93,432.53,634.6,432.43,635.46,432.32z M236.31,161.45c-0.93,0-1.63,0-2.45,0
	c0.06,0.83,0.12,1.58,0.18,2.4c0.91-0.11,1.66-0.19,2.43-0.28C236.41,162.81,236.36,162.18,236.31,161.45z M533.45,76.74
	c0.9-0.18,1.73-0.34,2.57-0.51c-0.17-0.76-0.32-1.42-0.48-2.17c-0.77,0.06-1.45,0.11-2.09,0.15
	C533.45,75.16,533.45,75.94,533.45,76.74z M548.68,345.98c-0.06-0.87-0.11-1.62-0.16-2.39c-0.95,0.09-1.77,0.17-2.61,0.25
	c0.22,0.85,0.39,1.52,0.58,2.28C547.23,346.07,547.91,346.02,548.68,345.98z M557.86,314.01c0.04,0.86,0.08,1.61,0.12,2.41
	c0.92-0.15,1.74-0.28,2.6-0.41c-0.17-0.76-0.31-1.42-0.47-2.14C559.3,313.92,558.62,313.96,557.86,314.01z M596.09,379.3
	c0.08,0.94,0.15,1.69,0.22,2.43c0.83-0.13,1.5-0.24,2.32-0.37c-0.13-0.73-0.25-1.39-0.37-2.06
	C597.53,379.3,596.91,379.3,596.09,379.3z M501.27,173.25c-0.11-0.94-0.2-1.72-0.29-2.54c-0.8,0.17-1.54,0.33-2.28,0.48
	c0.07,0.76,0.13,1.38,0.19,2.05C499.66,173.25,500.36,173.25,501.27,173.25z M662.38,600.96c0.11,0.8,0.22,1.58,0.33,2.38
	c0.77-0.06,1.38-0.1,2.1-0.15c-0.07-0.88-0.13-1.62-0.19-2.36C663.83,600.88,663.21,600.91,662.38,600.96z M438.76,39.02
	c-0.76,0.04-1.51,0.07-2.21,0.11c0,0.96,0,1.78,0,2.65c0.91-0.23,1.71-0.43,2.46-0.62C438.92,40.39,438.85,39.77,438.76,39.02z
	 M592.07,209.86c0.15,0.9,0.27,1.63,0.39,2.37c0.83-0.1,1.52-0.19,2.33-0.29c-0.07-0.77-0.13-1.5-0.2-2.23
	C593.72,209.76,593.02,209.8,592.07,209.86z M608.24,449.21c-0.1-0.96-0.17-1.71-0.24-2.45c-0.93,0.07-1.7,0.13-2.57,0.2
	c0.21,0.85,0.39,1.57,0.55,2.25C606.73,449.21,607.35,449.21,608.24,449.21z M649.66,595.26c-0.75,0-1.43,0-2.18,0
	c0.06,0.81,0.11,1.49,0.15,2.13c0.91,0,1.66,0,2.45,0C649.92,596.6,649.79,595.92,649.66,595.26z M570.7,351.24
	c-0.08-0.84-0.14-1.57-0.2-2.21c-0.91,0-1.67,0-2.55,0c0.19,0.83,0.36,1.55,0.55,2.37C569.26,351.35,569.93,351.3,570.7,351.24z
	 M428.9,74.54c0.74-0.04,1.42-0.07,2.23-0.11c-0.05-0.89-0.1-1.65-0.14-2.45c-0.96,0.09-1.79,0.17-2.64,0.25
	C428.54,73.07,428.71,73.75,428.9,74.54z M657.78,601.32c-0.85,0.2-1.59,0.38-2.34,0.56c0,0.74,0,1.42,0,2.18
	c0.84-0.05,1.59-0.1,2.34-0.15C657.78,603.06,657.78,602.23,657.78,601.32z M570.88,174.63c0.95-0.19,1.83-0.37,2.72-0.55
	c-0.2-0.81-0.37-1.48-0.56-2.23c-0.76,0.03-1.43,0.06-2.16,0.1C570.88,172.93,570.88,173.76,570.88,174.63z M628.29,430.58
	c-0.91,0.15-1.63,0.26-2.36,0.38c0.07,0.91,0.14,1.65,0.2,2.46c0.81-0.14,1.48-0.26,2.16-0.38
	C628.29,432.24,628.29,431.54,628.29,430.58z M433.68,45.24c0.77-0.04,1.51-0.09,2.26-0.13c-0.05-1-0.09-1.8-0.12-2.62
	c-1,0.2-1.82,0.36-2.65,0.53C433.35,43.78,433.5,44.45,433.68,45.24z M536.62,209.74c0-0.91,0-1.67,0-2.48
	c-0.84,0.1-1.59,0.18-2.4,0.28c0.16,0.85,0.3,1.59,0.44,2.34C535.4,209.82,536,209.78,536.62,209.74z M634.74,504.82
	c-0.14-0.87-0.25-1.56-0.38-2.39c-0.7,0.06-1.36,0.11-2.11,0.17c0.04,0.89,0.08,1.64,0.11,2.41
	C633.17,504.95,633.86,504.89,634.74,504.82z M652.82,546.55c-0.05-0.86-0.09-1.61-0.14-2.41c-0.86,0.1-1.61,0.19-2.4,0.29
	c0.1,0.82,0.19,1.48,0.29,2.25C651.36,546.63,652.05,546.6,652.82,546.55z M634.79,491.55c0.09,0.93,0.16,1.74,0.24,2.53
	c0.83-0.12,1.59-0.22,2.4-0.33c-0.17-0.85-0.33-1.65-0.48-2.43C636.21,491.4,635.59,491.47,634.79,491.55z M313.95,42.46
	c0.19,0.93,0.33,1.6,0.49,2.33c0.75-0.09,1.35-0.17,1.97-0.24c-0.04-0.82-0.08-1.51-0.12-2.26
	C315.52,42.34,314.84,42.39,313.95,42.46z M602.66,382.01c-0.95,0.24-1.7,0.43-2.54,0.64c0.09,0.69,0.19,1.37,0.28,2.09
	c0.8,0,1.54,0,2.25,0C602.66,383.83,602.66,383,602.66,382.01z M648.8,543.18c0-0.89,0-1.67,0-2.47c-0.8,0.15-1.48,0.28-2.29,0.43
	c0.06,0.71,0.11,1.37,0.16,2.04C647.45,543.18,648.13,543.18,648.8,543.18z M585.06,416.4c0.09,0.77,0.16,1.42,0.23,2.01
	c0.83,0,1.43,0,2.17,0c-0.05-0.82-0.09-1.55-0.14-2.35C586.51,416.18,585.84,416.28,585.06,416.4z M604.7,492.81
	c0-0.93,0-1.68,0-2.49c-0.84,0.17-1.59,0.32-2.35,0.48c0.09,0.73,0.17,1.34,0.25,2.01C603.31,492.81,603.92,492.81,604.7,492.81z
	 M561.43,243.8c-0.15-0.9-0.27-1.63-0.4-2.4c-0.87,0.09-1.55,0.16-2.24,0.23c0.05,0.9,0.1,1.66,0.15,2.42
	C559.76,243.97,560.46,243.9,561.43,243.8z M599.48,348.9c0-1.04,0-1.82,0-2.64c-0.82,0.16-1.58,0.31-2.44,0.48
	c0.06,0.74,0.12,1.42,0.18,2.16C597.98,348.9,598.67,348.9,599.48,348.9z M453.87,113.53c0,0.95,0,1.66,0,2.41
	c0.75-0.13,1.44-0.25,2.28-0.39c-0.05-0.71-0.1-1.38-0.15-2.13C455.24,113.46,454.62,113.49,453.87,113.53z M492.38,489.55
	c-0.79,0.07-1.47,0.14-2.13,0.2c0,0.82,0,1.52,0,2.32c0.83-0.08,1.57-0.15,2.43-0.24C492.58,491.04,492.49,490.37,492.38,489.55z
	 M520.02,209.07c0.1,0.88,0.17,1.52,0.25,2.21c0.85-0.06,1.52-0.11,2.25-0.16c-0.14-0.81-0.25-1.47-0.38-2.26
	C521.45,208.93,520.85,208.99,520.02,209.07z M607.01,255.46c0.1,0.79,0.21,1.57,0.32,2.42c0.87-0.08,1.56-0.14,2.3-0.2
	c-0.06-0.86-0.11-1.62-0.16-2.37C608.63,255.36,607.92,255.4,607.01,255.46z M606.17,464.84c-0.97,0.19-1.73,0.34-2.49,0.5
	c0.14,0.77,0.27,1.44,0.38,2.03c0.82,0,1.43,0,2.11,0C606.17,466.49,606.17,465.72,606.17,464.84z M621.32,546.73
	c0.17,0.91,0.3,1.6,0.45,2.43c0.71-0.09,1.39-0.17,2.2-0.28c-0.09-0.79-0.17-1.48-0.24-2.16
	C622.9,546.73,622.19,546.73,621.32,546.73z M623.73,491.3c0.9-0.17,1.72-0.32,2.56-0.47c-0.14-0.75-0.27-1.43-0.39-2.1
	c-0.86,0.05-1.61,0.09-2.39,0.13C623.58,489.7,623.65,490.47,623.73,491.3z M563.18,207.48c0.81-0.07,1.55-0.12,2.3-0.19
	c-0.05-0.88-0.09-1.58-0.14-2.38c-0.88,0.08-1.69,0.16-2.51,0.24C562.96,205.99,563.06,206.67,563.18,207.48z M515.24,211.65
	c-0.07-0.82-0.14-1.5-0.2-2.19c-0.8,0-1.49,0-2.24,0c0,0.86,0,1.62,0,2.43C513.58,211.8,514.28,211.74,515.24,211.65z M661.37,604.9
	c-0.77,0.08-1.5,0.15-2.22,0.23c0.04,0.87,0.07,1.58,0.1,2.22c0.89,0,1.73,0,2.6,0C661.67,606.48,661.53,605.72,661.37,604.9z
	 M620.85,539.66c0.19,0.83,0.34,1.48,0.51,2.22c0.78-0.07,1.45-0.14,2.03-0.19c0-0.92,0-1.67,0-2.43
	C622.54,539.39,621.78,539.51,620.85,539.66z M563.58,351.97c-0.05-0.81-0.09-1.57-0.14-2.43c-0.8,0.13-1.48,0.24-2.14,0.35
	c0,0.83,0,1.58,0,2.4C562.13,352.17,562.82,352.08,563.58,351.97z M607.01,503.51c0.74-0.04,1.39-0.08,2.09-0.13
	c-0.04-0.89-0.08-1.57-0.12-2.37c-0.75,0.06-1.41,0.1-2.13,0.16C606.92,502.04,606.96,502.72,607.01,503.51z M479.27,30.53
	c0.84-0.07,1.52-0.13,2.3-0.2c-0.09-0.86-0.17-1.6-0.25-2.37c-0.92,0.07-1.67,0.12-2.43,0.17
	C479.02,28.93,479.13,29.63,479.27,30.53z M616.34,502.59c-0.06-0.88-0.1-1.62-0.15-2.34c-0.97,0.08-1.79,0.14-2.6,0.2
	c0.19,0.89,0.36,1.69,0.53,2.49C614.87,502.82,615.49,502.72,616.34,502.59z M438.69,20.97c0.08,0.81,0.14,1.42,0.21,2.14
	c0.77-0.04,1.45-0.07,2.14-0.11c0-0.9,0-1.66,0-2.43C440.27,20.7,439.58,20.82,438.69,20.97z M584.5,203.3
	c0.18,0.86,0.33,1.52,0.44,2.06c0.82,0,1.44,0,2.09,0c0-0.89,0-1.67,0-2.52C586.22,202.99,585.46,203.12,584.5,203.3z
	 M587.59,212.54c-0.08-0.92-0.15-1.61-0.21-2.34c-0.84,0.12-1.51,0.23-2.24,0.33c0.08,0.84,0.15,1.53,0.22,2.22
	C586.14,212.68,586.76,212.62,587.59,212.54z M541.39,346.67c0-0.88,0-1.58,0-2.29c-0.9,0.05-1.72,0.1-2.57,0.15
	c0.07,0.79,0.13,1.54,0.2,2.35C539.78,346.81,540.54,346.74,541.39,346.67z M593.63,350.45c0.15,0.92,0.25,1.58,0.37,2.33
	c0.81-0.04,1.48-0.08,2.18-0.11c-0.05-0.82-0.1-1.52-0.14-2.22C595.26,350.45,594.56,350.45,593.63,350.45z M587.52,172.75
	c0-0.71,0-1.43,0-2.26c-0.9,0.06-1.63,0.11-2.42,0.16c0.15,0.8,0.27,1.41,0.4,2.1C586.17,172.75,586.79,172.75,587.52,172.75z
	 M667.54,550.17c-0.93,0.06-1.75,0.1-2.6,0.16c0.17,0.83,0.33,1.56,0.47,2.26c0.84-0.1,1.52-0.17,2.3-0.26
	C667.65,551.57,667.6,550.89,667.54,550.17z M261.41,99.84c0.85,0,1.47,0,2.14,0c0-0.86,0-1.62,0-2.46
	c-0.9,0.15-1.71,0.29-2.52,0.43C261.18,98.55,261.29,99.17,261.41,99.84z M548.94,353.2c0-0.76,0-1.51,0-2.33
	c-0.75,0.05-1.36,0.08-2.11,0.13c0.08,0.88,0.15,1.62,0.22,2.38C547.77,353.31,548.33,353.25,548.94,353.2z M550.78,317.09
	c0.94-0.18,1.7-0.33,2.48-0.48c-0.12-0.78-0.22-1.46-0.33-2.2c-0.83,0.1-1.51,0.18-2.15,0.25
	C550.78,315.53,550.78,316.24,550.78,317.09z M603.08,291.94c0,0.87,0,1.64,0,2.46c0.92-0.1,1.75-0.2,2.56-0.29
	c-0.14-0.85-0.26-1.53-0.39-2.31C604.46,291.85,603.77,291.9,603.08,291.94z M651.85,537c-0.85,0.08-1.57,0.14-2.21,0.2
	c0,0.99,0,1.74,0,2.53c0.89-0.17,1.71-0.33,2.51-0.48C652.05,538.53,651.97,537.91,651.85,537z M531.85,70.67
	c-0.87,0.05-1.56,0.09-2.23,0.13c0,0.9,0,1.68,0,2.48c0.84-0.13,1.6-0.24,2.38-0.36C531.95,72.18,531.91,71.56,531.85,70.67z
	 M629.07,353.59c-0.13-0.87-0.24-1.6-0.35-2.36c-0.94,0.08-1.71,0.15-2.61,0.23c0.15,0.77,0.3,1.48,0.43,2.13
	C627.4,353.59,628.09,353.59,629.07,353.59z M593.44,614.56c-0.61,0-1.28,0-2,0c0,0.87,0,1.68,0,2.48c0.86-0.08,1.61-0.15,2.36-0.23
	C593.68,616.06,593.57,615.37,593.44,614.56z M601.02,201.73c-0.82,0.16-1.49,0.29-2.06,0.41c0,0.85,0,1.55,0,2.38
	c0.81-0.11,1.55-0.22,2.26-0.32C601.15,203.36,601.1,202.66,601.02,201.73z M626.38,535.63c-0.81,0.06-1.54,0.12-2.27,0.18
	c0.05,0.96,0.09,1.69,0.13,2.44c0.81-0.15,1.49-0.27,2.32-0.42C626.51,537.11,626.45,536.44,626.38,535.63z M439.79,48.53
	c-0.06-0.97-0.1-1.74-0.15-2.52c-0.88,0.1-1.65,0.2-2.5,0.3c0.14,0.84,0.26,1.57,0.38,2.32C438.31,48.59,438.94,48.56,439.79,48.53z
	 M251.59,127.4c-0.84,0.14-1.57,0.26-2.29,0.38c0,0.85,0,1.53,0,2.33c0.84-0.1,1.59-0.18,2.41-0.27
	C251.67,129.04,251.63,128.28,251.59,127.4z M413.48,39.72c-0.06-0.73-0.11-1.41-0.17-2.19c-0.8,0.05-1.49,0.09-2.16,0.12
	c0,0.91,0,1.67,0,2.47C411.94,39.98,412.64,39.86,413.48,39.72z M650.44,504.52c0.05,0.94,0.08,1.7,0.12,2.49
	c0.77-0.11,1.44-0.21,2.23-0.32c-0.05-0.82-0.09-1.56-0.14-2.33C651.9,504.41,651.28,504.45,650.44,504.52z M495.37,145.65
	c-0.93,0.19-1.8,0.37-2.67,0.54c0.19,0.8,0.35,1.46,0.51,2.1c0.76,0,1.43,0,2.16,0C495.37,147.36,495.37,146.53,495.37,145.65z
	 M519.1,214.99c-0.09-0.86-0.16-1.52-0.24-2.28c-0.82,0.05-1.5,0.09-2.26,0.14c0.04,0.87,0.07,1.62,0.11,2.38
	C517.51,215.14,518.2,215.08,519.1,214.99z M559.1,215.04c-0.16-0.87-0.29-1.6-0.43-2.35c-0.88,0.15-1.61,0.27-2.34,0.4
	c0.08,0.83,0.15,1.52,0.21,2.19C557.38,215.2,558.14,215.13,559.1,215.04z M551.96,386.96c-0.75,0-1.44,0-2.25,0
	c0.07,0.85,0.13,1.59,0.2,2.4c0.84-0.14,1.53-0.25,2.29-0.37C552.11,388.24,552.04,387.62,551.96,386.96z M418.23,37.15
	c0.13,0.9,0.23,1.57,0.33,2.31c0.89-0.05,1.64-0.1,2.44-0.15c-0.21-0.84-0.37-1.52-0.55-2.27C419.75,37.08,419.13,37.1,418.23,37.15
	z M616.16,416.06c-0.16-0.9-0.29-1.62-0.43-2.37c-0.86,0.09-1.53,0.17-2.22,0.24c0.06,0.84,0.12,1.54,0.17,2.26
	C614.47,416.15,615.18,416.12,616.16,416.06z M607.82,470.87c0.81-0.04,1.56-0.08,2.32-0.12c-0.06-0.92-0.11-1.67-0.16-2.45
	c-0.87,0.11-1.61,0.2-2.4,0.3C607.67,469.41,607.74,470.08,607.82,470.87z M609.78,246.91c0.93-0.09,1.76-0.17,2.61-0.26
	c-0.18-0.82-0.33-1.49-0.48-2.19c-0.75,0-1.43,0-2.14,0C609.78,245.33,609.78,246.1,609.78,246.91z M502.37,174.15
	c0.14,0.79,0.28,1.55,0.41,2.27c0.75,0,1.45,0,2.17,0c0-0.83,0-1.6,0-2.44C504.06,174.04,503.24,174.09,502.37,174.15z
	 M550.11,320.58c-0.05-0.98-0.08-1.74-0.12-2.5c-0.85,0.13-1.61,0.26-2.49,0.4c0.15,0.75,0.29,1.46,0.42,2.11
	C548.69,320.58,549.3,320.58,550.11,320.58z M622.27,390.14c0.98-0.05,1.75-0.09,2.61-0.13c-0.18-0.79-0.34-1.51-0.52-2.26
	c-0.85,0.08-1.52,0.14-2.24,0.21C622.17,388.7,622.21,389.31,622.27,390.14z M336.42,50.12c0.81-0.05,1.5-0.09,2.26-0.14
	c-0.1-0.85-0.18-1.6-0.27-2.4c-0.84,0.09-1.52,0.16-2.22,0.23C336.27,48.62,336.33,49.24,336.42,50.12z M437.98,13.68
	c0.05,0.78,0.09,1.46,0.14,2.19c0.74,0,1.42,0,2.24,0c-0.05-0.79-0.1-1.47-0.15-2.19C439.45,13.68,438.76,13.68,437.98,13.68z
	 M628.65,422.3c0.78-0.06,1.59-0.12,2.49-0.19c-0.18-0.86-0.34-1.68-0.51-2.49c-0.74,0.07-1.36,0.13-1.98,0.19
	C628.65,420.61,628.65,421.38,628.65,422.3z M627.5,531.91c0.04,0.79,0.08,1.47,0.12,2.28c0.75-0.04,1.44-0.07,2.27-0.11
	c-0.05-0.76-0.1-1.45-0.15-2.17C628.98,531.91,628.29,531.91,627.5,531.91z M564.49,237.62c-0.86,0.04-1.54,0.08-2.32,0.12
	c0.04,0.88,0.08,1.64,0.12,2.43c0.84-0.1,1.59-0.19,2.36-0.28C564.59,239.12,564.55,238.5,564.49,237.62z M432.71,93.82
	c-0.86,0-1.56,0-2.23,0c0,0.86,0,1.66,0,2.51c0.91-0.14,1.7-0.26,2.49-0.39C432.87,95.16,432.8,94.55,432.71,93.82z M649.96,555.23
	c-0.73,0.07-1.47,0.14-2.29,0.22c0.07,0.78,0.13,1.51,0.2,2.28c0.83-0.06,1.51-0.11,2.19-0.16
	C650.03,556.76,650,556.07,649.96,555.23z M478.96,125.77c-0.91,0.09-1.58,0.16-2.33,0.24c0.07,0.8,0.13,1.46,0.2,2.24
	c0.83-0.05,1.51-0.1,2.25-0.14C479.04,127.31,479.01,126.63,478.96,125.77z M657,596.69c0-0.83,0-1.6,0-2.37
	c-0.87,0.07-1.63,0.14-2.43,0.21c0.09,0.84,0.17,1.6,0.25,2.36C655.64,596.82,656.33,596.75,657,596.69z M562.71,247.51
	c0.89-0.16,1.71-0.31,2.55-0.47c-0.15-0.76-0.27-1.43-0.42-2.2c-0.77,0.04-1.45,0.08-2.13,0.12
	C562.71,245.89,562.71,246.67,562.71,247.51z M520.55,218.54c0.87-0.16,1.57-0.28,2.39-0.43c-0.09-0.71-0.17-1.36-0.26-2.08
	c-0.89,0.1-1.56,0.17-2.23,0.24C520.48,217.07,520.51,217.7,520.55,218.54z M560.21,216.1c0.06,0.96,0.11,1.72,0.16,2.5
	c0.87-0.1,1.64-0.18,2.45-0.28c-0.08-0.8-0.15-1.48-0.23-2.22C561.83,216.1,561.14,216.1,560.21,216.1z M455.69,50.51
	c0.72-0.03,1.45-0.07,2.3-0.1c-0.08-0.85-0.15-1.53-0.22-2.2c-0.88,0-1.66,0-2.52,0C455.4,48.98,455.53,49.65,455.69,50.51z
	 M525.96,154.11c0.07,0.79,0.14,1.52,0.21,2.28c0.83,0,1.6,0,2.47,0c-0.1-0.81-0.19-1.56-0.27-2.28
	C527.56,154.11,526.86,154.11,525.96,154.11z M368.96,51.04c0.93-0.07,1.69-0.12,2.47-0.18c-0.17-0.86-0.31-1.54-0.46-2.29
	c-0.73,0.08-1.34,0.15-2.01,0.22C368.96,49.52,368.96,50.14,368.96,51.04z M602.01,198.22c0.06,0.88,0.1,1.62,0.15,2.35
	c0.94-0.05,1.71-0.09,2.53-0.14c-0.17-0.83-0.32-1.59-0.47-2.34C603.53,198.14,602.9,198.17,602.01,198.22z M668.21,586.07
	c0.06,0.96,0.11,1.73,0.16,2.5c0.88-0.1,1.65-0.18,2.5-0.28c-0.14-0.84-0.26-1.58-0.38-2.33
	C669.69,585.99,669.07,586.02,668.21,586.07z M577.16,174.86c-0.89,0.07-1.66,0.12-2.49,0.19c0.17,0.79,0.34,1.62,0.53,2.5
	c0.71-0.12,1.38-0.23,1.96-0.32C577.16,176.39,577.16,175.69,577.16,174.86z M616.7,423.28c0-0.95,0-1.76,0-2.59
	c-0.87,0.17-1.64,0.32-2.44,0.48c0.09,0.76,0.17,1.44,0.26,2.22C615.32,423.35,616.01,423.32,616.7,423.28z M648.45,580.75
	c-0.8,0.05-1.53,0.1-2.28,0.15c0.05,0.89,0.1,1.58,0.14,2.33c0.81-0.07,1.56-0.14,2.37-0.22
	C648.6,582.22,648.53,581.55,648.45,580.75z M245.8,142.23c-0.87,0.15-1.71,0.3-2.55,0.45c0.16,0.86,0.29,1.54,0.42,2.23
	c0.74-0.04,1.43-0.07,2.14-0.11C245.8,143.97,245.8,143.2,245.8,142.23z M442,35.26c-0.81,0.04-1.42,0.07-2,0.1
	c0,0.82,0,1.51,0,2.25c0.87-0.06,1.62-0.11,2.4-0.16C442.26,36.66,442.14,36.05,442,35.26z M552.97,354.24
	c-0.77,0.07-1.5,0.14-2.16,0.19c0,0.85,0,1.53,0,2.26c0.79-0.06,1.47-0.11,2.28-0.17C553.05,355.78,553.01,355.09,552.97,354.24z
	 M321.77,49.16c0.09,0.92,0.15,1.6,0.22,2.32c0.84-0.04,1.6-0.08,2.45-0.12c-0.18-0.85-0.34-1.56-0.47-2.2
	C323.18,49.16,322.57,49.16,321.77,49.16z M606.9,463.94c0.87-0.16,1.7-0.32,2.54-0.47c-0.11-0.78-0.21-1.45-0.32-2.25
	c-0.8,0.06-1.53,0.11-2.22,0.16C606.9,462.35,606.9,463.13,606.9,463.94z M638.08,500.91c-0.13-0.9-0.24-1.64-0.35-2.37
	c-0.94,0.1-1.7,0.17-2.48,0.25c0.14,0.78,0.27,1.46,0.39,2.12C636.41,500.91,637.12,500.91,638.08,500.91z M364.13,51.41
	c-0.1-0.87-0.18-1.6-0.28-2.4c-0.86,0.11-1.53,0.2-2.27,0.3c0.1,0.8,0.18,1.48,0.27,2.23C362.64,51.49,363.32,51.45,364.13,51.41z
	 M602.3,255.99c-0.89,0.04-1.66,0.08-2.53,0.13c0.14,0.77,0.28,1.5,0.42,2.27c0.82-0.06,1.5-0.12,2.28-0.18
	C602.41,257.39,602.35,256.71,602.3,255.99z M591.03,198c1.01-0.12,1.84-0.23,2.69-0.33c-0.21-0.83-0.38-1.51-0.57-2.27
	c-0.77,0.05-1.46,0.1-2.12,0.15C591.03,196.38,591.03,197.09,591.03,198z M432.15,70.68c0.75-0.03,1.49-0.07,2.2-0.1
	c0-0.95,0-1.76,0-2.6c-0.91,0.2-1.7,0.38-2.47,0.55C431.98,69.29,432.05,69.91,432.15,70.68z M316.9,49.53
	c-0.84,0.06-1.51,0.11-2.24,0.17c0.05,0.78,0.09,1.46,0.13,2.11c0.81,0,1.54,0,2.29,0C317.03,51.04,316.98,50.42,316.9,49.53z
	 M605.15,497.72c-0.9,0.04-1.6,0.07-2.37,0.11c0.13,0.82,0.24,1.56,0.36,2.35c0.8-0.07,1.45-0.13,2.16-0.19
	C605.24,499.15,605.2,498.54,605.15,497.72z M656.33,531.72c0.86-0.09,1.55-0.15,2.3-0.23c-0.07-0.79-0.12-1.47-0.19-2.24
	c-0.82,0.08-1.49,0.15-2.25,0.23C656.24,530.23,656.28,530.85,656.33,531.72z M608.32,272.33c0.92-0.09,1.74-0.17,2.59-0.25
	c-0.17-0.8-0.31-1.47-0.46-2.16c-0.74,0-1.42,0-2.13,0C608.32,270.76,608.32,271.52,608.32,272.33z M356.67,49.68
	c-0.8,0.18-1.41,0.32-2.05,0.46c0,0.71,0,1.4,0,2.11c0.86,0,1.63,0,2.41,0C356.91,51.4,356.8,50.63,356.67,49.68z M665.63,597.13
	c0.07,0.74,0.13,1.48,0.21,2.34c0.85-0.06,1.58-0.12,2.34-0.17c-0.07-0.9-0.12-1.6-0.17-2.31
	C667.22,597.03,666.51,597.08,665.63,597.13z M511.52,195.15c0.14,0.71,0.26,1.36,0.41,2.14c0.75-0.06,1.42-0.12,2.02-0.17
	c0-0.91,0-1.65,0-2.4C513.11,194.87,512.35,195,511.52,195.15z M504.85,76.61c0.09,0.77,0.18,1.5,0.28,2.28
	c0.87-0.06,1.56-0.11,2.2-0.15c0-0.97,0-1.78,0-2.62C506.47,76.29,505.71,76.44,504.85,76.61z M434.12,52.31c0.76,0,1.44,0,2.27,0
	c-0.05-0.84-0.09-1.59-0.13-2.36c-0.8,0.07-1.49,0.14-2.32,0.21C434,50.91,434.06,51.59,434.12,52.31z M604.56,474.7
	c0.84-0.07,1.51-0.13,2.21-0.2c-0.08-0.85-0.14-1.54-0.21-2.27c-0.89,0.08-1.65,0.15-2.41,0.23
	C604.29,473.23,604.4,473.85,604.56,474.7z M349.84,52.79c-0.15-0.9-0.26-1.59-0.4-2.43c-0.78,0.07-1.5,0.14-2.3,0.22
	c0.12,0.89,0.22,1.56,0.3,2.21C348.28,52.79,348.99,52.79,349.84,52.79z M565.5,194.03c0.07,0.98,0.13,1.74,0.18,2.5
	c0.9-0.1,1.67-0.19,2.47-0.28c-0.11-0.81-0.2-1.49-0.3-2.22C567.1,194.03,566.41,194.03,565.5,194.03z M628.87,506.43
	c0.06,0.93,0.11,1.63,0.17,2.4c0.83-0.06,1.58-0.12,2.4-0.18c-0.09-0.82-0.16-1.5-0.23-2.22
	C630.44,506.43,629.75,506.43,628.87,506.43z M518.83,194.65c0.07,0.73,0.13,1.41,0.2,2.08c0.78,0,1.46,0,2.22,0
	c0-0.92,0-1.74,0-2.59C520.42,194.32,519.67,194.47,518.83,194.65z M455.04,101.26c0-0.88,0-1.65,0-2.48
	c-0.91,0.12-1.74,0.23-2.56,0.34c0.14,0.83,0.26,1.5,0.39,2.26C453.66,101.34,454.35,101.3,455.04,101.26z M613.96,294.6
	c0.05,0.82,0.1,1.63,0.16,2.47c0.9-0.11,1.63-0.21,2.39-0.3c-0.1-0.86-0.18-1.6-0.26-2.29C615.41,294.53,614.73,294.56,613.96,294.6
	z M630.87,528.09c0.07,0.75,0.15,1.47,0.21,2.15c0.91,0,1.61,0,2.35,0c-0.15-0.83-0.28-1.58-0.42-2.38
	C632.28,527.94,631.66,528.01,630.87,528.09z M642.15,590.6c-0.05-0.89-0.09-1.57-0.13-2.31c-0.83,0.15-1.51,0.27-2.23,0.39
	c0.1,0.77,0.18,1.37,0.25,1.92C640.82,590.6,641.37,590.6,642.15,590.6z M564.17,222.12c1.03-0.21,1.84-0.37,2.65-0.54
	c-0.19-0.79-0.34-1.46-0.52-2.2c-0.78,0.06-1.46,0.11-2.13,0.16C564.17,220.45,564.17,221.23,564.17,222.12z M478.58,118.36
	c-0.87,0.17-1.63,0.32-2.48,0.49c0.07,0.73,0.14,1.4,0.21,2.21c0.82-0.06,1.56-0.11,2.26-0.15
	C478.58,119.98,478.58,119.28,478.58,118.36z M416.71,33.69c-0.83,0.05-1.57,0.09-2.35,0.13c0.07,0.86,0.12,1.56,0.18,2.32
	c0.85-0.05,1.6-0.1,2.42-0.15C416.87,35.16,416.79,34.49,416.71,33.69z M523.13,176.1c-0.89,0.09-1.71,0.17-2.55,0.25
	c0.13,0.84,0.24,1.52,0.37,2.34c0.82-0.07,1.55-0.13,2.3-0.2C523.21,177.59,523.17,176.89,523.13,176.1z M478.02,158.46
	c-0.87,0.05-1.54,0.09-2.26,0.13c0.04,0.88,0.07,1.65,0.11,2.44c0.89-0.09,1.71-0.17,2.57-0.26
	C478.3,160,478.18,159.33,478.02,158.46z M648.18,565.13c0.93-0.19,1.67-0.34,2.41-0.48c-0.08-0.75-0.14-1.37-0.22-2.14
	c-0.77,0.06-1.5,0.11-2.2,0.16C648.18,563.58,648.18,564.28,648.18,565.13z M414.9,14.42c-0.1-0.86-0.19-1.52-0.26-2.11
	c-0.89,0-1.63,0-2.39,0c0.16,0.86,0.29,1.6,0.43,2.35C413.41,14.58,414.03,14.51,414.9,14.42z M438.41,102.82
	c0.79-0.15,1.48-0.29,2.2-0.43c-0.08-0.77-0.14-1.45-0.21-2.14c-0.86,0-1.63,0-2.44,0C438.09,101.02,438.23,101.79,438.41,102.82z
	 M607.68,486.63c-0.83,0.07-1.5,0.13-2.12,0.18c0,0.94,0,1.73,0,2.53c0.87-0.17,1.61-0.32,2.34-0.47
	C607.84,488.14,607.77,487.52,607.68,486.63z M622.15,527.55c-0.08-0.78-0.15-1.54-0.23-2.39c-0.72,0.12-1.38,0.22-2.12,0.34
	c0.04,0.81,0.07,1.54,0.11,2.31C620.73,527.72,621.41,527.64,622.15,527.55z M612.57,486.26c0.04,0.84,0.08,1.57,0.13,2.35
	c0.93-0.13,1.7-0.23,2.54-0.35c-0.13-0.68-0.25-1.36-0.37-2.01C614.07,486.26,613.37,486.26,612.57,486.26z M535.82,224.22
	c0.93-0.11,1.59-0.19,2.27-0.27c-0.04-0.87-0.07-1.56-0.11-2.29c-0.78,0.14-1.47,0.27-2.27,0.41
	C535.74,222.77,535.77,223.39,535.82,224.22z M343.29,20.6c-0.11-0.87-0.2-1.52-0.28-2.14c-0.89,0-1.6,0-2.39,0
	c0.15,0.81,0.3,1.56,0.45,2.36C341.8,20.75,342.42,20.68,343.29,20.6z M547.33,360.67c0.88-0.11,1.64-0.2,2.51-0.3
	c-0.1-0.82-0.19-1.54-0.29-2.32c-0.87,0.06-1.56,0.11-2.22,0.16C547.33,359.02,547.33,359.72,547.33,360.67z M466.52,21.9
	c-0.8,0.17-1.54,0.32-2.36,0.49c0.07,0.75,0.13,1.41,0.2,2.19c0.82-0.08,1.49-0.14,2.16-0.2C466.52,23.47,466.52,22.7,466.52,21.9z
	 M609.97,302.33c-0.96,0.06-1.72,0.11-2.52,0.16c0.11,0.78,0.21,1.45,0.31,2.17c0.8,0,1.55,0,2.38,0
	C610.09,303.9,610.04,303.2,609.97,302.33z M609.5,508.25c-0.72,0.05-1.4,0.09-2.17,0.14c0.09,0.83,0.17,1.56,0.25,2.32
	c0.91-0.07,1.66-0.12,2.43-0.18C609.83,509.72,609.68,509.05,609.5,508.25z M554,323.96c-0.05-0.94-0.09-1.64-0.14-2.44
	c-0.88,0.09-1.69,0.18-2.51,0.26c0.13,0.84,0.23,1.51,0.33,2.18C552.47,323.96,553.15,323.96,554,323.96z M521.79,396.58
	c0.07,0.82,0.14,1.57,0.21,2.35c0.74-0.08,1.36-0.14,2.11-0.21c-0.05-0.84-0.1-1.58-0.15-2.36
	C523.18,396.43,522.57,396.5,521.79,396.58z M585.43,423.45c0.15,0.85,0.27,1.54,0.42,2.34c0.73-0.07,1.39-0.14,2.11-0.22
	c-0.06-0.82-0.11-1.44-0.17-2.12C587.01,423.45,586.32,423.45,585.43,423.45z M619.81,359.18c0,0.86,0,1.64,0,2.51
	c0.82-0.15,1.55-0.27,2.3-0.41c-0.05-0.91-0.09-1.68-0.14-2.46C621.19,358.95,620.5,359.07,619.81,359.18z M386.21,14.6
	c-0.77,0.19-1.43,0.35-2,0.49c0,0.82,0,1.48,0,2.13c0.87,0,1.62,0,2.44,0C386.51,16.37,386.38,15.62,386.21,14.6z M563.47,225.59
	c0-1.03,0-1.81,0-2.61c-0.89,0.16-1.72,0.32-2.57,0.47c0.12,0.78,0.22,1.46,0.32,2.14C562,225.59,562.68,225.59,563.47,225.59z
	 M610.29,225.07c-0.07-0.8-0.13-1.48-0.2-2.29c-0.81,0.04-1.55,0.09-2.32,0.13c0.05,0.86,0.09,1.56,0.13,2.34
	C608.71,225.19,609.45,225.14,610.29,225.07z M551.05,332.63c-0.82,0.05-1.57,0.09-2.35,0.13c0.07,0.92,0.13,1.67,0.19,2.43
	c0.91-0.11,1.67-0.19,2.47-0.29C551.25,334.11,551.16,333.43,551.05,332.63z M650.93,525.02c-0.06-0.96-0.12-1.72-0.17-2.49
	c-0.89,0.09-1.65,0.18-2.46,0.26c0.1,0.8,0.18,1.48,0.27,2.23C649.31,525.02,650,525.02,650.93,525.02z M365.25,25.89
	c0-0.98,0-1.68,0-2.43c-0.82,0.12-1.57,0.23-2.36,0.34c0.19,0.84,0.33,1.51,0.49,2.2C364.06,25.96,364.6,25.93,365.25,25.89z
	 M517.01,191.03c-0.84,0.06-1.53,0.1-2.3,0.15c0.04,0.93,0.08,1.72,0.12,2.53c0.91-0.17,1.67-0.3,2.43-0.44
	C517.18,192.54,517.12,191.92,517.01,191.03z M444.78,24.08c-0.83,0.08-1.51,0.15-2.2,0.22c0.06,0.76,0.12,1.38,0.17,2.03
	c0.82,0,1.57,0,2.37,0C445.01,25.6,444.91,24.98,444.78,24.08z M546.01,93.23c0.72-0.04,1.45-0.08,2.28-0.13
	c-0.08-0.88-0.15-1.57-0.22-2.31c-0.84,0.08-1.58,0.15-2.33,0.22C545.84,91.81,545.92,92.44,546.01,93.23z M618.34,426.48
	c0.88,0,1.62,0,2.46,0c-0.13-0.85-0.25-1.58-0.36-2.29c-0.92,0.09-1.68,0.16-2.43,0.23C618.13,425.15,618.23,425.76,618.34,426.48z
	 M452.34,112.3c-0.07-0.87-0.11-1.48-0.16-2.08c-0.84,0-1.58,0-2.4,0c0.16,0.8,0.29,1.47,0.41,2.08
	C450.94,112.3,451.54,112.3,452.34,112.3z M608.96,250.43c-0.14-0.83-0.25-1.5-0.38-2.26c-0.78,0.04-1.46,0.07-2.17,0.11
	c0,0.96,0,1.78,0,2.62C607.25,250.74,608.01,250.6,608.96,250.43z M287.16,66.48c0.16,0.76,0.3,1.44,0.47,2.22
	c0.72-0.06,1.37-0.11,2.04-0.17c-0.07-0.91-0.13-1.64-0.18-2.34C288.69,66.29,288.07,66.37,287.16,66.48z M613.31,424.7
	c-0.87,0.08-1.67,0.15-2.48,0.22c0.12,0.84,0.22,1.51,0.33,2.31c0.81-0.06,1.55-0.12,2.3-0.18
	C613.41,426.2,613.36,425.51,613.31,424.7z M588.4,177.5c-0.9,0.14-1.67,0.25-2.52,0.38c0.12,0.7,0.24,1.37,0.35,2.03
	c0.76,0,1.44,0,2.17,0C588.4,179.11,588.4,178.41,588.4,177.5z M539.68,227.58c0.92-0.06,1.61-0.11,2.31-0.16
	c-0.04-0.83-0.08-1.53-0.12-2.33c-0.82,0.07-1.56,0.14-2.34,0.22C539.58,226.11,539.62,226.74,539.68,227.58z M604.05,193.18
	c0-0.88,0-1.71,0-2.6c-0.86,0.23-1.6,0.43-2.34,0.63c0,0.72,0,1.4,0,2.08C602.56,193.25,603.32,193.21,604.05,193.18z M416.36,18.14
	c0.87-0.18,1.56-0.32,2.38-0.48c-0.06-0.7-0.12-1.37-0.18-2.13c-0.83,0.08-1.52,0.14-2.2,0.21
	C416.36,16.58,416.36,17.29,416.36,18.14z M489.41,192.28c0.83-0.18,1.5-0.32,2.21-0.48c-0.06-0.76-0.11-1.37-0.16-1.94
	c-0.89,0-1.63,0-2.37,0C489.2,190.68,489.29,191.38,489.41,192.28z M647.39,566.15c-0.8,0.15-1.55,0.29-2.33,0.44
	c0.05,0.75,0.1,1.37,0.14,2.02c0.75,0,1.44,0,2.18,0C647.39,567.74,647.39,566.97,647.39,566.15z M614.87,304.17
	c0.73-0.04,1.52-0.09,2.33-0.14c-0.06-0.93-0.11-1.73-0.16-2.57c-0.92,0.2-1.64,0.36-2.3,0.51
	C614.79,302.74,614.82,303.37,614.87,304.17z M561.6,122.24c-0.84,0.07-1.54,0.12-2.25,0.18c0,0.77,0,1.55,0,2.38
	c0.85-0.08,1.6-0.16,2.41-0.24C561.71,123.8,561.66,123.11,561.6,122.24z M598.32,281.4c0.2,0.88,0.37,1.59,0.55,2.36
	c0.81-0.08,1.47-0.15,2.16-0.22c-0.08-0.89-0.15-1.62-0.21-2.33C599.96,281.27,599.26,281.33,598.32,281.4z M284.04,83.95
	c-0.18-0.78-0.35-1.53-0.55-2.38c-0.71,0.07-1.37,0.13-2.12,0.2c0.06,0.83,0.11,1.5,0.16,2.18
	C282.45,83.95,283.22,83.95,284.04,83.95z M547.01,324.78c-0.2-0.89-0.36-1.64-0.55-2.46c-0.74,0.11-1.41,0.2-2.04,0.29
	c0,0.83,0,1.47,0,2.16C545.24,324.78,546.02,324.78,547.01,324.78z M478.55,165.62c-0.82,0.05-1.49,0.09-2.04,0.12
	c0,1.03,0,1.85,0,2.67c0.89-0.18,1.65-0.32,2.49-0.49C478.85,167.21,478.72,166.53,478.55,165.62z M635.99,437.23
	c-0.86,0.05-1.55,0.1-2.22,0.14c0,0.9,0,1.67,0,2.47c0.86-0.12,1.61-0.23,2.39-0.34C636.11,438.76,636.06,438.13,635.99,437.23z
	 M565.23,286.84c0-0.97,0-1.8,0-2.73c-0.84,0.21-1.66,0.4-2.53,0.62c0.11,0.73,0.22,1.41,0.33,2.11
	C563.79,286.84,564.49,286.84,565.23,286.84z M553.92,284.07c0-0.95,0-1.71,0-2.47c-0.84,0.14-1.57,0.26-2.38,0.4
	c0.18,0.78,0.34,1.44,0.48,2.07C552.72,284.07,553.26,284.07,553.92,284.07z M475.2,124.73c-0.04-0.78-0.08-1.46-0.12-2.15
	c-0.83,0-1.59,0-2.47,0c0.19,0.84,0.34,1.55,0.48,2.15C473.89,124.73,474.51,124.73,475.2,124.73z M524.63,150.64
	c-0.95,0.1-1.72,0.18-2.59,0.27c0.13,0.83,0.25,1.56,0.37,2.35c0.86-0.07,1.55-0.13,2.21-0.18
	C524.63,152.26,524.63,151.55,524.63,150.64z M434.68,59.63c0.87-0.04,1.55-0.07,2.38-0.11c-0.07-0.83-0.13-1.56-0.2-2.41
	c-0.85,0.13-1.52,0.23-2.18,0.32C434.68,58.14,434.68,58.76,434.68,59.63z M613.19,300.67c-0.07-0.85-0.13-1.67-0.19-2.47
	c-0.88,0.07-1.63,0.13-2.41,0.19c0.22,0.89,0.42,1.69,0.62,2.52C611.9,300.82,612.51,300.75,613.19,300.67z M669.19,595.54
	c0.85,0,1.53,0,2.27,0c0-0.94,0-1.78,0-2.65c-0.88,0.19-1.7,0.36-2.5,0.53C669.04,594.16,669.11,594.78,669.19,595.54z
	 M626.3,517.54c0.05,0.68,0.1,1.41,0.16,2.28c0.83-0.06,1.57-0.11,2.21-0.16c0-0.97,0-1.73,0-2.49
	C627.89,517.28,627.19,517.4,626.3,517.54z M368.8,22.15c-0.09-0.93-0.16-1.67-0.24-2.43c-0.91,0.08-1.67,0.15-2.47,0.22
	c0.11,0.8,0.2,1.48,0.3,2.21C367.14,22.15,367.83,22.15,368.8,22.15z M577.09,159.31c0.81-0.07,1.5-0.13,2.24-0.19
	c-0.07-0.83-0.13-1.57-0.19-2.29c-0.92,0-1.74,0-2.59,0C576.72,157.63,576.89,158.39,577.09,159.31z M552.09,328.92
	c0.04,0.93,0.08,1.69,0.12,2.49c0.79-0.12,1.47-0.22,2.26-0.34c-0.05-0.81-0.09-1.57-0.14-2.35
	C553.59,328.78,552.97,328.84,552.09,328.92z M554.8,249.28c-0.94,0.04-1.77,0.08-2.61,0.12c0.15,0.82,0.29,1.56,0.43,2.33
	c0.81-0.09,1.55-0.18,2.34-0.27C554.9,250.67,554.85,250,554.8,249.28z M452.73,59.52c0.06,0.8,0.11,1.49,0.16,2.2
	c0.92-0.1,1.73-0.18,2.53-0.26c-0.12-0.81-0.23-1.5-0.34-2.24C454.34,59.31,453.66,59.4,452.73,59.52z M607.43,481.81
	c-0.06-0.9-0.11-1.71-0.17-2.52c-0.8,0.14-1.49,0.26-2.27,0.39c0.06,0.74,0.12,1.47,0.18,2.23
	C606,481.88,606.69,481.85,607.43,481.81z M435.18,64.51c0,1.01,0,1.8,0,2.61c0.87-0.19,1.61-0.35,2.36-0.52
	c-0.09-0.78-0.17-1.44-0.24-2.1C436.53,64.51,435.91,64.51,435.18,64.51z M567.43,189.05c-0.07-0.92-0.13-1.67-0.19-2.43
	c-0.81,0.11-1.49,0.2-2.2,0.3c0.07,0.93,0.13,1.68,0.18,2.42C565.96,189.25,566.58,189.17,567.43,189.05z M545.68,228.36
	c-0.96,0.11-1.65,0.19-2.44,0.29c0.11,0.8,0.21,1.52,0.32,2.25c0.86-0.1,1.55-0.19,2.31-0.28
	C545.81,229.87,545.75,229.18,545.68,228.36z M607.88,265.09c0.79-0.07,1.48-0.14,2.27-0.21c-0.11-0.81-0.2-1.49-0.31-2.29
	c-0.75,0.04-1.41,0.08-2.12,0.12C607.79,263.6,607.83,264.3,607.88,265.09z M610.62,517.76c-0.1-0.84-0.18-1.59-0.27-2.42
	c-0.75,0.1-1.43,0.2-2.21,0.31c0.07,0.79,0.12,1.46,0.18,2.11C609.15,517.76,609.84,517.76,610.62,517.76z M596.7,299.74
	c0.06,0.69,0.12,1.39,0.19,2.21c0.85-0.07,1.5-0.13,2.17-0.19c-0.05-0.9-0.09-1.63-0.13-2.36
	C598.14,299.52,597.52,299.62,596.7,299.74z M594.02,370.96c0-0.78,0-1.55,0-2.43c-0.84,0.16-1.52,0.3-2.21,0.43
	c0.08,0.77,0.15,1.37,0.22,2C592.78,370.96,593.33,370.96,594.02,370.96z M526.98,179.5c-0.94,0.07-1.71,0.12-2.55,0.18
	c0.14,0.84,0.27,1.57,0.39,2.29c0.87-0.11,1.56-0.2,2.3-0.29C527.08,180.99,527.04,180.36,526.98,179.5z M415.62,19.01
	c-0.9,0.2-1.65,0.37-2.38,0.54c0.1,0.8,0.18,1.48,0.26,2.18c0.83-0.04,1.44-0.07,2.12-0.11C415.62,20.71,415.62,19.93,415.62,19.01z
	 M485.16,31.23c-0.88,0.07-1.68,0.13-2.51,0.19c0.22,0.85,0.39,1.51,0.54,2.08c0.73,0,1.33,0,1.96,0
	C485.16,32.71,485.16,32.03,485.16,31.23z M307.4,63.79c-0.11-0.88-0.2-1.68-0.3-2.49c-0.8,0.13-1.46,0.24-2.18,0.35
	c0.06,0.85,0.1,1.52,0.15,2.14C305.89,63.79,306.58,63.79,307.4,63.79z M629.43,516.19c0.81-0.15,1.57-0.3,2.42-0.46
	c-0.07-0.75-0.13-1.43-0.21-2.22c-0.83,0.06-1.52,0.11-2.22,0.16C629.43,514.59,629.43,515.37,629.43,516.19z M586.71,187.25
	c0.7-0.04,1.44-0.07,2.27-0.12c-0.13-0.86-0.23-1.53-0.34-2.25c-0.88,0.06-1.62,0.11-2.38,0.16
	C586.42,185.82,586.55,186.44,586.71,187.25z M636.53,428.53c0.88,0,1.57,0,2.39,0c-0.04-0.86-0.08-1.61-0.12-2.42
	c-0.91,0.09-1.68,0.17-2.48,0.25C636.4,427.16,636.46,427.78,636.53,428.53z M569.45,366.01c0.8-0.16,1.55-0.31,2.35-0.47
	c-0.05-0.77-0.1-1.43-0.15-2.17c-0.87,0.07-1.54,0.12-2.19,0.17C569.45,364.44,569.45,365.2,569.45,366.01z M608.48,230.16
	c0.11,0.82,0.2,1.49,0.3,2.28c0.79-0.06,1.46-0.11,2.09-0.15c0-0.95,0-1.75,0-2.55C610.07,229.88,609.38,230,608.48,230.16z
	 M497.9,163.8c0.07,0.87,0.13,1.58,0.18,2.27c0.82,0,1.58,0,2.45,0c-0.13-0.87-0.24-1.61-0.35-2.37
	C499.39,163.73,498.77,163.76,497.9,163.8z M475.29,111.51c0.06,0.93,0.11,1.7,0.17,2.48c0.88-0.09,1.66-0.17,2.51-0.26
	c-0.14-0.85-0.26-1.58-0.38-2.34C476.79,111.43,476.16,111.47,475.29,111.51z M459.27,64.8c-0.16-0.9-0.28-1.58-0.42-2.33
	c-0.8,0.05-1.49,0.09-2.18,0.13c0,0.89,0,1.67,0,2.46C457.54,64.97,458.31,64.89,459.27,64.8z M444.41,19.12
	c-0.2-0.84-0.35-1.5-0.51-2.16c-0.79,0.05-1.4,0.08-2.02,0.12c0,0.93,0,1.7,0,2.48C442.71,19.41,443.49,19.28,444.41,19.12z
	 M622.93,326.17c-0.84,0.15-1.61,0.29-2.5,0.45c0.12,0.78,0.22,1.5,0.34,2.3c0.87-0.1,1.55-0.18,2.16-0.24
	C622.93,327.73,622.93,326.96,622.93,326.17z M479.16,114.87c0.05,1,0.09,1.82,0.13,2.64c0.92-0.18,1.68-0.33,2.52-0.49
	c-0.13-0.79-0.25-1.51-0.38-2.26C480.65,114.8,480.03,114.83,479.16,114.87z M628.04,512.48c-0.07-0.78-0.13-1.46-0.2-2.28
	c-0.81,0.04-1.56,0.08-2.33,0.12c0.05,0.87,0.09,1.57,0.14,2.36C626.46,512.61,627.21,512.55,628.04,512.48z M615.36,366.85
	c-0.92,0.07-1.65,0.13-2.41,0.19c0.09,0.86,0.17,1.62,0.26,2.39c0.91-0.18,1.71-0.34,2.51-0.5
	C615.6,368.2,615.49,367.59,615.36,366.85z M592.26,183.3c-0.04-0.79-0.07-1.47-0.11-2.21c-0.76,0-1.44,0-2.28,0
	c0.06,0.78,0.11,1.47,0.17,2.21C590.79,183.3,591.48,183.3,592.26,183.3z M622.45,405.93c-0.85,0.09-1.51,0.16-2.27,0.25
	c0.1,0.85,0.18,1.51,0.27,2.23c0.79-0.08,1.46-0.16,2.17-0.23C622.55,407.42,622.51,406.79,622.45,405.93z M611.39,485.17
	c-0.16-0.92-0.29-1.63-0.42-2.38c-0.86,0.08-1.53,0.14-2.25,0.21c0.07,0.85,0.14,1.59,0.2,2.32
	C609.76,485.27,610.45,485.22,611.39,485.17z M608.65,496.23c-0.06-0.91-0.11-1.61-0.16-2.4c-0.81,0.05-1.56,0.1-2.37,0.16
	c0.12,0.9,0.2,1.58,0.29,2.25C607.17,496.23,607.79,496.23,608.65,496.23z M612.79,251.35c-0.79,0.12-1.56,0.24-2.4,0.37
	c0.09,0.76,0.17,1.45,0.25,2.14c0.84,0,1.6,0,2.39,0C612.94,252.95,612.87,252.17,612.79,251.35z M420.63,19.01c0,0.7,0,1.39,0,2.19
	c0.84-0.04,1.59-0.07,2.4-0.11c-0.12-0.85-0.23-1.54-0.34-2.31C421.98,18.87,421.36,18.93,420.63,19.01z M251.06,120.1
	c-0.85,0.18-1.61,0.35-2.39,0.52c0.03,0.75,0.07,1.44,0.1,2.21c0.82-0.08,1.57-0.16,2.29-0.23
	C251.06,121.74,251.06,120.96,251.06,120.1z M652.58,572.9c0.12,0.72,0.25,1.53,0.38,2.34c0.75-0.06,1.43-0.11,2.14-0.16
	c0-0.79,0-1.55,0-2.3C654.26,572.82,653.49,572.86,652.58,572.9z M613.53,511.39c-0.98,0-1.74,0-2.58,0
	c0.17,0.89,0.32,1.72,0.49,2.62c0.8-0.2,1.47-0.37,2.09-0.52C613.53,512.72,613.53,512.09,613.53,511.39z M542.43,232.21
	c-0.82,0.14-1.51,0.26-2.23,0.38c0.11,0.75,0.2,1.43,0.31,2.16c0.85-0.04,1.58-0.07,2.33-0.11
	C542.69,233.77,542.57,233.09,542.43,232.21z M653.58,600.38c0-0.73,0-1.46,0-2.18c-0.87,0.05-1.62,0.09-2.47,0.14
	c0.16,0.82,0.32,1.62,0.47,2.43C652.32,600.62,652.93,600.5,653.58,600.38z M576.39,500.21c-0.82,0.18-1.43,0.31-2.07,0.46
	c0,0.73,0,1.41,0,2.12c0.83-0.04,1.59-0.08,2.44-0.13C576.65,501.89,576.53,501.14,576.39,500.21z M580.83,162.67
	c0.87-0.12,1.6-0.22,2.33-0.32c-0.08-0.82-0.15-1.49-0.22-2.16c-0.79,0.06-1.47,0.11-2.22,0.16
	C580.75,161.09,580.79,161.77,580.83,162.67z M544.36,242.8c0.16,0.83,0.3,1.59,0.45,2.42c0.73-0.13,1.41-0.25,2.13-0.37
	c-0.05-0.79-0.1-1.53-0.14-2.24C545.97,242.67,545.27,242.73,544.36,242.8z M578.29,318.02c0-0.73,0-1.32,0-2.08
	c-0.78,0.04-1.45,0.07-2.09,0.1c0,0.82,0,1.52,0,2.35C576.96,318.26,577.61,318.14,578.29,318.02z M580.32,503.51
	c-0.86,0.04-1.55,0.08-2.36,0.12c0.15,0.8,0.27,1.46,0.38,2.09c0.74,0,1.35,0,2.08,0C580.39,505,580.36,504.39,580.32,503.51z
	 M491.02,182.57c-0.92,0.04-1.61,0.07-2.41,0.11c0.19,0.81,0.34,1.47,0.51,2.2c0.76-0.12,1.36-0.22,1.9-0.3
	C491.02,183.88,491.02,183.33,491.02,182.57z M610.81,506.67c0.87-0.05,1.66-0.1,2.46-0.15c-0.13-0.84-0.23-1.52-0.35-2.26
	c-0.79,0.13-1.51,0.25-2.27,0.38C610.71,505.38,610.76,505.99,610.81,506.67z M464.34,69.07c0.17,0.82,0.34,1.63,0.51,2.48
	c0.72-0.08,1.39-0.16,2.05-0.24c-0.08-0.88-0.15-1.64-0.22-2.42C465.89,68.95,465.15,69.01,464.34,69.07z M353.66,13.84
	c-0.92,0.1-1.66,0.18-2.4,0.26c0.15,0.84,0.27,1.52,0.39,2.21c0.74-0.04,1.43-0.07,2.17-0.11
	C353.76,15.42,353.72,14.73,353.66,13.84z M427.03,68.85c-0.87,0.19-1.53,0.33-2.19,0.47c0.04,0.8,0.07,1.43,0.1,2.09
	c0.88,0,1.71,0,2.57,0C427.35,70.57,427.21,69.81,427.03,68.85z M385.06,29.1c0.18,0.79,0.32,1.4,0.49,2.12
	c0.72-0.04,1.38-0.09,1.93-0.12c0-0.94,0-1.7,0-2.43C386.67,28.82,385.97,28.94,385.06,29.1z M621.85,509.34
	c0.97-0.1,1.71-0.18,2.46-0.25c-0.15-0.8-0.27-1.46-0.38-2.03c-0.83,0-1.51,0-2.29,0C621.7,507.79,621.77,508.47,621.85,509.34z
	 M536.73,236.39c0.09,0.9,0.16,1.51,0.23,2.19c0.79-0.06,1.48-0.11,2.21-0.16c-0.05-0.77-0.09-1.39-0.13-2.03
	C538.31,236.39,537.63,236.39,536.73,236.39z M361.02,15.8c-0.08-1-0.13-1.75-0.2-2.59c-0.77,0.18-1.44,0.33-2.19,0.51
	c0.06,0.77,0.12,1.45,0.17,2.08C359.59,15.8,360.23,15.8,361.02,15.8z M575.35,319.5c-0.98,0.15-1.79,0.28-2.62,0.41
	c0.22,0.83,0.4,1.49,0.58,2.2c0.75-0.04,1.42-0.08,2.04-0.11C575.35,321.1,575.35,320.4,575.35,319.5z M651.57,576.88
	c-0.79,0.1-1.38,0.17-2,0.25c0.05,0.8,0.09,1.48,0.14,2.22c0.81-0.07,1.47-0.12,2.22-0.18C651.8,578.35,651.71,577.75,651.57,576.88
	z M502.99,68.23c-0.16-0.89-0.29-1.57-0.41-2.24c-0.83,0.05-1.58,0.09-2.39,0.14c0.12,0.78,0.22,1.46,0.31,2.1
	C501.35,68.23,502.05,68.23,502.99,68.23z M413.26,77.4c-0.78,0.12-1.43,0.21-2.17,0.32c0.07,0.79,0.13,1.45,0.19,2.13
	c0.78-0.07,1.39-0.12,2.13-0.18C413.36,78.89,413.32,78.21,413.26,77.4z M301.8,67.87c0.88-0.09,1.63-0.17,2.34-0.25
	c-0.11-0.92-0.21-1.69-0.32-2.52c-0.7,0.13-1.37,0.26-2.02,0.39C301.8,66.26,301.8,66.96,301.8,67.87z M434.22,28.72
	c-0.86,0-1.6,0-2.37,0c0.11,0.79,0.21,1.47,0.32,2.3c0.78-0.14,1.51-0.27,2.25-0.4C434.35,29.87,434.28,29.27,434.22,28.72z
	 M600.82,323.45c-0.07-0.91-0.12-1.67-0.18-2.51c-0.74,0.12-1.47,0.24-2.2,0.36c0.1,0.88,0.18,1.63,0.26,2.38
	C599.44,323.6,600.06,323.54,600.82,323.45z M584.77,495.9c0,0.77,0,1.51,0,2.39c0.82-0.1,1.55-0.2,2.35-0.3
	c-0.16-0.81-0.29-1.47-0.41-2.09C585.98,495.9,585.43,495.9,584.77,495.9z M577.59,166.56c0.75-0.08,1.44-0.16,2.22-0.24
	c-0.04-0.81-0.08-1.57-0.13-2.45c-0.77,0.13-1.42,0.24-2.09,0.36C577.59,165.06,577.59,165.75,577.59,166.56z M490.95,166.66
	c0.68-0.06,1.37-0.12,2.18-0.19c-0.05-0.75-0.09-1.43-0.14-2.13c-0.81,0-1.5,0-2.31,0C490.77,165.12,490.85,165.81,490.95,166.66z
	 M644.18,572.45c-0.15-0.92-0.25-1.57-0.35-2.17c-0.86,0-1.56,0-2.35,0c0.17,0.8,0.33,1.52,0.47,2.17
	C642.71,572.45,643.31,572.45,644.18,572.45z M536.28,231.49c0.81-0.14,1.48-0.25,2.21-0.37c-0.04-0.71-0.07-1.32-0.11-1.94
	c-0.8,0-1.48,0-2.28,0C536.16,229.97,536.21,230.64,536.28,231.49z M596.18,252.98c0.04,0.84,0.07,1.51,0.1,2.16
	c0.87,0,1.6,0,2.41,0c-0.1-0.92-0.2-1.73-0.29-2.54C597.62,252.73,597.01,252.83,596.18,252.98z M656.71,618.37
	c0.87,0,1.68,0,2.54,0c-0.18-0.91-0.34-1.71-0.51-2.57c-0.81,0.23-1.46,0.42-2.02,0.58C656.71,617.15,656.71,617.77,656.71,618.37z
	 M648.86,569.8c0.08,0.81,0.14,1.49,0.21,2.28c0.84-0.05,1.58-0.09,2.41-0.14c-0.18-0.87-0.31-1.54-0.45-2.24
	C650.23,569.73,649.61,569.76,648.86,569.8z M276.6,84.29c-0.1-0.85-0.18-1.52-0.26-2.24c-0.79,0.05-1.48,0.09-2.28,0.14
	c0.12,0.8,0.24,1.53,0.34,2.22C275.16,84.37,275.78,84.34,276.6,84.29z M521.26,407.52c-0.9,0.08-1.56,0.14-2.24,0.2
	c0.07,0.86,0.12,1.55,0.19,2.33c0.77-0.17,1.43-0.32,2.05-0.45C521.26,408.89,521.26,408.35,521.26,407.52z M523.01,577.05
	c0.1,0.72,0.2,1.44,0.32,2.3c0.79-0.05,1.47-0.09,2.22-0.13c-0.05-0.85-0.1-1.59-0.15-2.38
	C524.61,576.91,523.9,576.97,523.01,577.05z M602.02,446.16c0.98,0,1.74,0,2.53,0c-0.15-0.92-0.3-1.75-0.45-2.64
	c-0.77,0.19-1.44,0.35-2.08,0.51C602.02,444.81,602.02,445.44,602.02,446.16z M484.94,179.63c0,0.78,0,1.47,0,2.23
	c0.87-0.07,1.69-0.13,2.54-0.2c-0.14-0.85-0.26-1.59-0.39-2.4C486.31,179.4,485.64,179.51,484.94,179.63z M602.47,424.53
	c-0.09-0.95-0.17-1.7-0.25-2.51c-0.83,0.17-1.5,0.31-2.14,0.45c0,0.76,0,1.39,0,2.07C600.84,424.53,601.54,424.53,602.47,424.53z
	 M453.61,108.43c0.83,0,1.45,0,2.06,0c0-0.83,0-1.59,0-2.44c-0.83,0.14-1.57,0.27-2.3,0.39
	C453.46,107.12,453.53,107.74,453.61,108.43z M237.42,157.63c0.07,0.83,0.13,1.56,0.18,2.23c0.92,0,1.69,0,2.63,0
	c-0.2-0.83-0.37-1.55-0.56-2.33C238.88,157.56,238.2,157.59,237.42,157.63z M421.18,86.19c-0.07-0.71-0.15-1.4-0.23-2.21
	c-0.77,0.06-1.44,0.11-2.09,0.15c0,0.84,0,1.52,0,2.26C419.66,86.33,420.36,86.27,421.18,86.19z M658.63,571.39
	c-0.09-1-0.16-1.75-0.24-2.6c-0.85,0.18-1.57,0.34-2.3,0.49c0.05,0.82,0.1,1.49,0.14,2.11C657.1,571.39,657.79,571.39,658.63,571.39
	z M632.3,423.02c0.17,0.79,0.31,1.46,0.49,2.27c0.72-0.04,1.41-0.08,2.14-0.12c0-0.85,0-1.63,0-2.49
	C634.04,422.8,633.2,422.9,632.3,423.02z M595.12,325.09c0.11,0.81,0.21,1.48,0.32,2.26c0.8-0.04,1.47-0.07,2.23-0.11
	c-0.08-0.86-0.14-1.54-0.21-2.26C596.63,325.01,595.93,325.05,595.12,325.09z M567.48,228.93c-0.14-0.87-0.25-1.54-0.38-2.32
	c-0.82,0.04-1.57,0.07-2.35,0.1c0.12,0.81,0.23,1.48,0.34,2.22C565.82,228.93,566.5,228.93,567.48,228.93z M513.17,187.88
	c-0.86,0.04-1.61,0.08-2.42,0.13c0.11,0.77,0.2,1.45,0.3,2.18c0.89-0.05,1.66-0.09,2.44-0.14
	C513.38,189.3,513.29,188.69,513.17,187.88z M474.16,110.43c-0.14-0.88-0.24-1.57-0.36-2.33c-0.79,0.11-1.53,0.22-2.38,0.34
	c0.06,0.71,0.12,1.39,0.19,2.13C472.47,110.53,473.23,110.48,474.16,110.43z M647,519.25c-0.98,0.22-1.72,0.38-2.53,0.56
	c0.11,0.68,0.21,1.28,0.3,1.84c0.79,0,1.48,0,2.23,0C647,520.86,647,520.17,647,519.25z M548.43,256.75
	c-0.94,0.28-1.68,0.49-2.41,0.71c0.07,0.74,0.14,1.41,0.2,2c0.84,0,1.53,0,2.21,0C548.43,258.59,548.43,257.76,548.43,256.75z
	 M637.46,522.51c0.96-0.06,1.65-0.1,2.4-0.14c-0.06-0.79-0.11-1.56-0.18-2.48c-0.83,0.17-1.56,0.31-2.22,0.44
	C637.46,521.14,637.46,521.77,637.46,522.51z M538.74,258.03c0.07,0.79,0.13,1.44,0.19,2.15c0.86-0.07,1.55-0.12,2.29-0.17
	c-0.14-0.79-0.27-1.45-0.4-2.18C540.07,257.89,539.47,257.95,538.74,258.03z M615.83,287.14c-0.9,0.19-1.56,0.33-2.21,0.47
	c0,0.79,0,1.46,0,2.24c0.82-0.11,1.56-0.2,2.32-0.3C615.9,288.74,615.87,288.04,615.83,287.14z M371.54,55.78
	c-0.84,0.04-1.52,0.07-2.32,0.1c0.07,0.84,0.13,1.51,0.2,2.26c0.91-0.05,1.72-0.1,2.54-0.15C371.82,57.23,371.7,56.61,371.54,55.78z
	 M501.68,180.33c-0.14-0.84-0.24-1.44-0.36-2.14c-0.73,0.06-1.39,0.12-2.1,0.18c0.07,0.82,0.13,1.48,0.19,2.19
	C500.23,180.48,500.83,180.42,501.68,180.33z M498.4,57.98c-0.1-0.95-0.19-1.77-0.28-2.63c-0.84,0.17-1.52,0.31-2.27,0.47
	c0.04,0.78,0.07,1.47,0.11,2.16C496.75,57.98,497.45,57.98,498.4,57.98z M522.6,406.01c0.83-0.06,1.43-0.1,2.15-0.16
	c-0.08-0.78-0.15-1.43-0.22-2c-0.81,0-1.41,0-2.11,0C522.48,404.59,522.53,405.19,522.6,406.01z M439.21,85.98
	c-0.75,0.04-1.47,0.08-2.2,0.13c0.07,0.9,0.12,1.58,0.18,2.34c0.88-0.05,1.69-0.09,2.53-0.14
	C439.53,87.45,439.39,86.79,439.21,85.98z M376.56,57.64c0.92-0.09,1.66-0.16,2.43-0.24c-0.11-0.83-0.2-1.51-0.3-2.26
	c-0.8,0.08-1.46,0.14-2.13,0.2C376.56,56.15,376.56,56.78,376.56,57.64z M565.73,362.51c0.84-0.07,1.58-0.14,2.36-0.21
	c-0.12-0.83-0.21-1.49-0.3-2.15c-0.84,0.05-1.57,0.09-2.33,0.13C565.55,361.07,565.63,361.75,565.73,362.51z M436.14,34.24
	c0.82-0.09,1.52-0.17,2.29-0.26c-0.11-0.88-0.2-1.54-0.29-2.23c-0.77,0.09-1.38,0.16-2,0.23C436.14,32.74,436.14,33.41,436.14,34.24
	z M632.79,523.06c-0.19-0.91-0.32-1.58-0.47-2.31c-0.83,0.05-1.52,0.1-2.25,0.14c0.11,0.8,0.21,1.48,0.3,2.17
	C631.14,523.06,631.83,523.06,632.79,523.06z M575.88,329.07c-0.1-0.76-0.18-1.43-0.26-2.07c-0.81,0-1.51,0-2.21,0
	c0.09,0.89,0.18,1.69,0.26,2.48C574.43,329.34,575.05,329.22,575.88,329.07z M572.97,361.91c0.85-0.05,1.46-0.08,2.17-0.12
	c-0.05-0.8-0.09-1.46-0.14-2.24c-0.81,0.07-1.48,0.13-2.2,0.2C572.86,360.5,572.91,361.11,572.97,361.91z M566.61,261.47
	c-0.1-0.87-0.17-1.55-0.27-2.37c-0.82,0.13-1.55,0.24-2.28,0.35c0.06,0.78,0.1,1.39,0.15,2.01
	C564.99,261.47,565.68,261.47,566.61,261.47z M565.71,373.71c-0.2-0.94-0.37-1.74-0.55-2.56c-0.76,0.14-1.37,0.25-2.02,0.37
	c0,0.75,0,1.45,0,2.19C563.97,373.71,564.74,373.71,565.71,373.71z M551.36,86.99c-0.86,0.08-1.53,0.15-2.25,0.22
	c0.06,0.8,0.11,1.47,0.17,2.25c0.87-0.17,1.52-0.29,2.18-0.41C551.43,88.34,551.4,87.8,551.36,86.99z M593.64,155.49
	c-0.77,0.14-1.43,0.25-2.1,0.37c0,0.73,0,1.32,0,2.06c0.79-0.05,1.46-0.09,2.1-0.13C593.64,156.99,593.64,156.31,593.64,155.49z
	 M604.6,450.82c-0.79,0.16-1.49,0.3-2.19,0.44c0.04,0.87,0.07,1.54,0.1,2.24c0.95-0.14,1.69-0.25,2.44-0.36
	C604.83,452.34,604.73,451.65,604.6,450.82z M599.24,262.26c-0.13-0.81-0.24-1.56-0.37-2.4c-0.76,0.12-1.43,0.22-2.06,0.31
	c0,0.84,0,1.57,0,2.3C597.7,262.4,598.44,262.34,599.24,262.26z M501.71,167.13c0.06,0.9,0.11,1.59,0.17,2.38
	c0.87-0.14,1.67-0.28,2.51-0.41c-0.12-0.71-0.23-1.32-0.34-1.97C503.31,167.13,502.63,167.13,501.71,167.13z M549.83,593.06
	c0.04,0.79,0.06,1.39,0.1,2.09c0.79-0.05,1.45-0.1,2.21-0.15c-0.12-0.89-0.2-1.54-0.3-2.25
	C551.13,592.85,550.59,592.94,549.83,593.06z M652.41,568.07c0.92-0.06,1.6-0.1,2.37-0.15c-0.1-0.82-0.18-1.56-0.27-2.29
	c-0.85,0.16-1.54,0.3-2.29,0.44C652.28,566.73,652.34,567.35,652.41,568.07z M633.75,523.88c0.21,0.98,0.36,1.73,0.53,2.54
	c0.76-0.17,1.42-0.32,2.05-0.46c0-0.79,0-1.47,0-2.09C635.46,523.88,634.69,523.88,633.75,523.88z M547.22,329.29
	c-0.85,0.18-1.53,0.32-2.29,0.48c0.05,0.69,0.1,1.3,0.15,1.9c0.88,0,1.65,0,2.46,0C547.43,330.89,547.34,330.2,547.22,329.29z
	 M581.45,169.8c0.86-0.05,1.69-0.1,2.54-0.14c-0.14-0.84-0.27-1.59-0.41-2.42c-0.75,0.13-1.42,0.25-2.13,0.37
	C581.45,168.38,581.45,169.08,581.45,169.8z M430.85,16.75c0.97-0.05,1.72-0.09,2.54-0.14c-0.19-0.81-0.35-1.54-0.52-2.27
	c-0.74,0.06-1.35,0.12-2.02,0.17C430.85,15.27,430.85,15.96,430.85,16.75z M540.06,329.9c-0.74,0.11-1.48,0.23-2.2,0.34
	c0,0.82,0,1.55,0,2.29c0.89-0.09,1.64-0.17,2.37-0.24C540.18,331.48,540.13,330.77,540.06,329.9z M492.26,54.6
	c0.73-0.06,1.41-0.13,2.19-0.19c-0.06-0.82-0.11-1.55-0.16-2.27c-0.94,0.11-1.7,0.2-2.46,0.29C492,53.22,492.13,53.91,492.26,54.6z
	 M344.21,14.59c0.06,0.9,0.11,1.57,0.16,2.36c0.86-0.09,1.53-0.15,2.27-0.23c-0.12-0.83-0.22-1.49-0.31-2.13
	C345.59,14.59,345.03,14.59,344.21,14.59z M595.81,374.46c0.91-0.13,1.57-0.22,2.26-0.32c-0.1-0.81-0.18-1.43-0.27-2.12
	c-0.79,0.06-1.48,0.12-2.18,0.17C595.67,372.96,595.73,373.59,595.81,374.46z M577.86,310.94c0-0.85,0-1.53,0-2.34
	c-0.78,0.07-1.44,0.14-2.23,0.21c0.04,0.76,0.07,1.44,0.11,2.23C576.49,311,577.09,310.97,577.86,310.94z M629.27,566.71
	c-0.08-0.76-0.16-1.5-0.26-2.35c-0.77,0.1-1.43,0.18-2.15,0.27c0.05,0.79,0.08,1.4,0.12,2.08
	C627.75,566.71,628.44,566.71,629.27,566.71z M606.51,221.74c-0.14-0.88-0.25-1.54-0.34-2.14c-0.87,0.04-1.55,0.07-2.31,0.11
	c0.09,0.73,0.17,1.41,0.26,2.15C604.92,221.82,605.61,221.78,606.51,221.74z M376,21.53c-0.08-0.75-0.16-1.43-0.24-2.12
	c-0.89,0-1.66,0-2.48,0c0.11,0.76,0.21,1.44,0.31,2.12C374.4,21.53,375.16,21.53,376,21.53z M562.69,297.91
	c-0.09-0.81-0.17-1.57-0.25-2.39c-0.84,0.17-1.52,0.31-2.24,0.46c0.04,0.71,0.08,1.32,0.11,1.93
	C561.17,297.91,561.88,297.91,562.69,297.91z M267.77,92.23c0.89,0,1.65,0,2.54,0c-0.2-0.85-0.36-1.58-0.54-2.33
	c-0.8,0.04-1.46,0.08-2.1,0.11C267.71,90.85,267.73,91.48,267.77,92.23z M594.3,397.61c0.08,0.84,0.14,1.49,0.21,2.18
	c0.86-0.04,1.53-0.07,2.27-0.11c-0.14-0.82-0.26-1.49-0.39-2.2C595.69,397.52,595.15,397.56,594.3,397.61z M571.6,529.6
	c-0.82,0.12-1.48,0.22-2.07,0.3c0,0.84,0,1.52,0,2.35c0.89-0.18,1.68-0.33,2.46-0.49C571.84,530.99,571.74,530.38,571.6,529.6z
	 M596.72,357.49c-0.72,0.09-1.47,0.17-2.27,0.27c0.09,0.76,0.16,1.44,0.25,2.23c0.76-0.04,1.46-0.07,2.13-0.1
	C596.8,359.07,596.76,358.36,596.72,357.49z M641.97,533.02c0.97-0.05,1.78-0.09,2.6-0.13c-0.19-0.9-0.35-1.65-0.53-2.47
	c-0.74,0.17-1.39,0.32-2.07,0.48C641.97,531.62,641.97,532.24,641.97,533.02z M329.05,48.44c0.04,0.8,0.08,1.56,0.12,2.4
	c0.78-0.05,1.52-0.11,2.29-0.16c-0.07-0.87-0.13-1.65-0.19-2.42C330.49,48.33,329.8,48.38,329.05,48.44z M451.42,18.56
	c-0.07-0.88-0.13-1.64-0.2-2.45c-0.81,0.1-1.53,0.2-2.23,0.28c0.05,0.85,0.08,1.54,0.12,2.27C449.81,18.64,450.5,18.6,451.42,18.56z
	 M538.12,268.91c-0.77,0-1.46,0-2.21,0c0,0.84,0,1.61,0,2.46c0.91-0.11,1.74-0.2,2.68-0.31
	C538.42,270.27,538.27,269.6,538.12,268.91z M618.12,271.43c-0.07-0.86-0.14-1.65-0.21-2.47c-0.85,0.15-1.59,0.27-2.38,0.41
	c0.04,0.76,0.08,1.42,0.11,2.06C616.51,271.43,617.27,271.43,618.12,271.43z M575.45,116.04c0-0.84,0-1.58,0-2.36
	c-0.81,0.1-1.49,0.19-2.3,0.29c0.04,0.78,0.08,1.45,0.12,2.27C574.08,116.16,574.75,116.1,575.45,116.04z M609.32,284.22
	c0.16,0.91,0.29,1.63,0.42,2.39c0.84-0.15,1.51-0.27,2.25-0.4c-0.07-0.72-0.13-1.33-0.19-1.99
	C611,284.22,610.29,284.22,609.32,284.22z M600.07,395.75c-0.07-0.8-0.14-1.55-0.21-2.33c-0.79,0.13-1.46,0.25-2.26,0.38
	c0.07,0.7,0.13,1.36,0.18,1.95C598.61,395.75,599.29,395.75,600.07,395.75z M494.33,471.38c-0.8,0.05-1.47,0.1-2.18,0.15
	c0.06,0.79,0.11,1.41,0.16,2.17c0.83-0.06,1.56-0.12,2.31-0.18C494.52,472.78,494.44,472.22,494.33,471.38z M367.03,45.24
	c-0.84,0.07-1.51,0.12-2.21,0.18c0.08,0.9,0.14,1.63,0.2,2.39c0.83-0.15,1.51-0.27,2.17-0.39C367.15,46.7,367.1,46.07,367.03,45.24z
	 M607.54,441.9c-0.14-0.76-0.26-1.44-0.38-2.1c-0.83,0.07-1.56,0.13-2.33,0.19c0.11,0.87,0.2,1.6,0.29,2.31
	C605.94,442.16,606.63,442.05,607.54,441.9z M498.55,44.72c0,0.79,0,1.53,0,2.22c0.89,0,1.65,0,2.51,0
	c-0.19-0.85-0.35-1.59-0.52-2.35C499.8,44.64,499.18,44.68,498.55,44.72z M594.82,172.27c-0.08-0.81-0.14-1.47-0.22-2.24
	c-0.8,0.08-1.45,0.14-2.17,0.21c0.1,0.82,0.18,1.48,0.26,2.17C593.47,172.37,594.07,172.33,594.82,172.27z M598.25,197.22
	c0.93-0.08,1.63-0.13,2.42-0.2c-0.07-0.78-0.15-1.55-0.22-2.33c-0.78,0.08-1.47,0.14-2.2,0.21
	C598.25,195.68,598.25,196.39,598.25,197.22z M287.69,73.69c0.14,0.86,0.25,1.61,0.37,2.38c0.75-0.11,1.37-0.19,2.11-0.3
	c-0.06-0.74-0.11-1.42-0.17-2.08C289.18,73.69,288.49,73.69,287.69,73.69z M500.59,210.59c-0.82,0.08-1.56,0.16-2.33,0.23
	c0.09,0.86,0.16,1.53,0.24,2.27c0.86-0.07,1.61-0.14,2.38-0.2C500.77,212.07,500.68,211.39,500.59,210.59z M341.74,45.97
	c-0.13-0.83-0.24-1.49-0.34-2.15c-0.76,0.05-1.36,0.08-2.01,0.12c0.07,0.86,0.12,1.61,0.18,2.37
	C340.33,46.2,340.94,46.1,341.74,45.97z M596.47,341.89c0.94-0.08,1.69-0.14,2.46-0.21c-0.16-0.86-0.3-1.59-0.44-2.35
	c-0.81,0.05-1.47,0.09-2.14,0.13C596.4,340.34,596.43,341.03,596.47,341.89z M549.61,271.18c-0.91,0.24-1.57,0.42-2.36,0.63
	c0.04,0.72,0.08,1.38,0.11,2.05c0.83,0,1.53,0,2.25,0C549.61,273.03,549.61,272.25,549.61,271.18z M549.79,96.6
	c0.86-0.06,1.53-0.1,2.32-0.15c-0.1-0.85-0.18-1.51-0.26-2.18c-0.79,0.04-1.4,0.07-2.06,0.1C549.79,95.09,549.79,95.71,549.79,96.6z
	 M542.39,340.86c0.05,0.81,0.08,1.48,0.12,2.09c0.94,0,1.75,0,2.56,0c-0.18-0.93-0.34-1.74-0.51-2.58
	C543.84,340.53,543.24,340.67,542.39,340.86z M407.39,36.65c0.94-0.07,1.69-0.13,2.45-0.19c-0.14-0.81-0.26-1.47-0.38-2.13
	c-0.84,0.06-1.57,0.11-2.33,0.16C407.22,35.21,407.29,35.83,407.39,36.65z M427.14,29.03c-0.79,0.15-1.44,0.27-2.1,0.39
	c0,0.81,0,1.5,0,2.29c0.75-0.08,1.43-0.16,2.1-0.23C427.14,30.66,427.14,29.91,427.14,29.03z M574.82,341.46c0,0.88,0,1.63,0,2.42
	c0.77-0.16,1.44-0.3,2.08-0.43c0-0.73,0-1.33,0-2.1C576.12,341.39,575.46,341.42,574.82,341.46z M368.19,41.48
	c0.07,0.89,0.12,1.56,0.17,2.31c0.91-0.07,1.6-0.13,2.38-0.19c-0.17-0.81-0.31-1.47-0.45-2.11
	C369.56,41.48,369.01,41.48,368.19,41.48z M624.84,542.95c0.12,0.84,0.22,1.55,0.32,2.28c0.8-0.04,1.41-0.07,2.17-0.11
	c-0.08-0.83-0.14-1.5-0.2-2.16C626.35,542.95,625.67,542.95,624.84,542.95z M419.56,24.99c-0.1-0.89-0.18-1.56-0.27-2.31
	c-0.81,0.06-1.49,0.11-2.21,0.16c0.08,0.81,0.15,1.49,0.22,2.26C418.07,25.07,418.68,25.04,419.56,24.99z M460.75,112.63
	c0.25,0.95,0.45,1.68,0.64,2.38c0.8-0.07,1.47-0.12,2.1-0.18c-0.07-0.86-0.12-1.55-0.17-2.21
	C462.5,112.63,461.74,112.63,460.75,112.63z M493.7,171.47c-0.84,0.05-1.57,0.1-2.33,0.14c0.16,0.77,0.28,1.37,0.44,2.12
	c0.7-0.05,1.37-0.1,2.08-0.15C493.82,172.8,493.77,172.18,493.7,171.47z M619.14,560.18c0.8-0.11,1.41-0.19,2.1-0.28
	c-0.05-0.78-0.1-1.45-0.14-2.08c-0.86,0-1.54,0-2.32,0C618.91,558.62,619.01,559.3,619.14,560.18z M451.33,97.95
	c-0.07-0.89-0.13-1.56-0.18-2.19c-0.96,0-1.73,0-2.61,0c0.18,0.82,0.35,1.54,0.49,2.19C449.85,97.95,450.47,97.95,451.33,97.95z
	 M628.19,557.29c-0.8,0.04-1.4,0.07-2.13,0.1c0.12,0.85,0.21,1.5,0.31,2.21c0.86-0.06,1.55-0.11,2.25-0.16
	C628.47,558.72,628.35,558.1,628.19,557.29z M643.37,547.13c0.82-0.08,1.49-0.14,2.18-0.21c-0.08-0.8-0.14-1.42-0.2-2.04
	c-0.87,0-1.64,0-2.42,0C643.08,545.62,643.2,546.24,643.37,547.13z M444,96.19c-0.81,0.09-1.57,0.17-2.44,0.26
	c0.1,0.79,0.19,1.53,0.29,2.28c0.81-0.1,1.48-0.18,2.16-0.26C444,97.66,444,96.96,444,96.19z M430.51,25.16
	c-0.89,0.16-1.56,0.28-2.26,0.4c0,0.78,0,1.47,0,2.18c0.83,0,1.59,0,2.4,0C430.61,26.91,430.57,26.14,430.51,25.16z M369.15,29.3
	c0-0.82,0-1.62,0-2.48c-0.76,0.13-1.48,0.26-2.23,0.39c0.12,0.8,0.22,1.46,0.31,2.1C367.92,29.3,368.47,29.3,369.15,29.3z
	 M569.42,199.84c0.99-0.22,1.72-0.38,2.49-0.56c-0.1-0.72-0.19-1.32-0.29-1.98c-0.81,0.04-1.5,0.08-2.2,0.11
	C569.42,198.21,569.42,198.9,569.42,199.84z M424.49,40.14c-0.88,0-1.7,0-2.58,0c0.18,0.88,0.34,1.68,0.5,2.52
	c0.74-0.14,1.41-0.26,2.08-0.39C424.49,41.51,424.49,40.83,424.49,40.14z M629.96,436.41c0.92-0.09,1.67-0.16,2.45-0.23
	c-0.11-0.82-0.2-1.5-0.3-2.25c-0.84,0.16-1.58,0.3-2.31,0.44C629.86,435.06,629.9,435.61,629.96,436.41z M631.28,548.45
	c-0.16-0.88-0.27-1.49-0.38-2.11c-0.73,0-1.41,0-2.18,0c0.07,0.79,0.13,1.45,0.19,2.11C629.72,548.45,630.4,548.45,631.28,548.45z
	 M574.28,352.35c-0.78,0.05-1.51,0.1-2.22,0.14c0.07,0.88,0.12,1.58,0.18,2.37c0.79-0.14,1.52-0.26,2.26-0.39
	C574.41,353.7,574.35,353.08,574.28,352.35z M489.23,145.35c0.86-0.07,1.6-0.13,2.34-0.19c-0.18-0.86-0.33-1.62-0.5-2.44
	c-0.7,0.08-1.36,0.17-2.04,0.25C489.09,143.76,489.15,144.45,489.23,145.35z M654.03,547.7c0.15,0.84,0.27,1.51,0.38,2.12
	c0.83,0,1.52,0,2.34,0c-0.09-0.8-0.17-1.54-0.25-2.29C655.63,547.59,654.93,547.63,654.03,547.7z M515.53,198.42
	c0.06,0.78,0.11,1.44,0.16,2.21c0.77-0.05,1.44-0.09,2.2-0.14c-0.09-0.81-0.16-1.47-0.23-2.18
	C516.91,198.34,516.3,198.38,515.53,198.42z M569.65,131.04c-0.07-0.82-0.14-1.51-0.21-2.34c-0.77,0.09-1.52,0.17-2.33,0.26
	c0.19,0.85,0.33,1.52,0.48,2.18C568.36,131.1,568.98,131.07,569.65,131.04z M530.48,206.84c0.98-0.13,1.73-0.22,2.54-0.32
	c-0.16-0.85-0.29-1.6-0.44-2.38c-0.83,0.18-1.49,0.32-2.1,0.45C530.48,205.41,530.48,206.04,530.48,206.84z M647.13,550.51
	c0.86-0.06,1.52-0.12,2.29-0.17c-0.08-0.82-0.15-1.49-0.22-2.22c-0.79,0.04-1.47,0.08-2.2,0.12
	C647.04,549.01,647.07,549.62,647.13,550.51z M611.29,276.85c-0.79,0.1-1.54,0.19-2.38,0.3c0.14,0.84,0.25,1.56,0.37,2.3
	c0.85-0.05,1.53-0.09,2.28-0.14C611.46,278.44,611.38,277.68,611.29,276.85z M564.08,135.1c0.86-0.08,1.55-0.15,2.36-0.23
	c-0.07-0.73-0.13-1.4-0.19-2.05c-0.87,0-1.63,0-2.48,0C563.87,133.59,563.97,134.27,564.08,135.1z M467.55,147.15
	c0.81-0.09,1.56-0.18,2.45-0.29c-0.09-0.73-0.17-1.39-0.25-2.03c-0.91,0-1.66,0-2.49,0C467.35,145.64,467.45,146.38,467.55,147.15z
	 M556.15,99.82c-0.21-0.86-0.36-1.52-0.53-2.23c-0.77,0.11-1.37,0.19-2.02,0.28c0.09,0.75,0.17,1.36,0.24,1.95
	C554.62,99.82,555.25,99.82,556.15,99.82z M386.96,10.7c0.15,0.98,0.26,1.73,0.37,2.51c0.78-0.11,1.46-0.2,2.21-0.31
	c-0.06-0.78-0.11-1.47-0.17-2.21C388.58,10.7,387.88,10.7,386.96,10.7z M559.62,346.24c-0.9,0.08-1.72,0.15-2.55,0.22
	c0.16,0.79,0.31,1.48,0.46,2.21c0.73-0.06,1.41-0.11,2.1-0.16C559.62,347.69,559.62,346.99,559.62,346.24z M561.85,277.54
	c0.16,0.82,0.28,1.49,0.41,2.15c0.83-0.05,1.57-0.09,2.38-0.14c-0.12-0.82-0.22-1.55-0.32-2.23
	C563.45,277.4,562.75,277.46,561.85,277.54z M379.18,15.11c-0.95,0.19-1.7,0.33-2.47,0.48c0.13,0.79,0.24,1.46,0.36,2.16
	c0.79-0.07,1.48-0.13,2.12-0.19C379.18,16.76,379.18,16.05,379.18,15.11z M569.2,110.63c0.11,0.78,0.2,1.44,0.28,2.06
	c0.8,0,1.4,0,2.18,0c-0.06-0.77-0.1-1.44-0.15-2.06C570.7,110.63,570,110.63,569.2,110.63z M499.06,552.65
	c0.83-0.12,1.66-0.24,2.55-0.36c-0.1-0.73-0.18-1.41-0.28-2.12c-0.84,0.11-1.6,0.21-2.38,0.31
	C498.99,551.21,499.02,551.91,499.06,552.65z M327.03,40.18c-0.14-0.84-0.24-1.44-0.34-2.04c-0.8,0-1.49,0-2.28,0
	c0.09,0.75,0.16,1.41,0.25,2.16C325.41,40.26,326.1,40.23,327.03,40.18z M447.72,99.6c-0.79,0.09-1.53,0.17-2.28,0.25
	c0.08,0.85,0.15,1.54,0.22,2.34c0.83-0.18,1.55-0.33,2.27-0.48C447.86,100.94,447.79,100.32,447.72,99.6z M364.52,40.44
	c0.9-0.09,1.56-0.16,2.24-0.23c-0.07-0.85-0.13-1.53-0.2-2.32c-0.78,0.18-1.44,0.33-2.05,0.47
	C364.52,39.07,364.52,39.61,364.52,40.44z M536.25,202.55c-0.05-0.77-0.1-1.46-0.15-2.22c-0.91,0.06-1.74,0.11-2.59,0.16
	c0.17,0.8,0.31,1.48,0.46,2.2C534.74,202.64,535.43,202.6,536.25,202.55z M624.6,556.14c-0.06-0.89-0.11-1.51-0.15-2.11
	c-0.76,0-1.44,0-2.24,0c0.09,0.81,0.16,1.47,0.24,2.11C623.19,556.14,623.8,556.14,624.6,556.14z M496.04,200.36
	c-0.82,0-1.53,0-2.23,0c0.06,0.88,0.11,1.69,0.16,2.48c0.84-0.16,1.53-0.29,2.27-0.43C496.18,201.77,496.12,201.16,496.04,200.36z
	 M596.84,193.64c-0.07-0.7-0.15-1.37-0.23-2.15c-0.77,0.08-1.45,0.16-2.26,0.24c0.04,0.75,0.08,1.41,0.12,2.08
	C595.35,193.75,596.03,193.7,596.84,193.64z M567.11,503.28c0.82-0.07,1.61-0.13,2.4-0.19c-0.09-0.86-0.16-1.55-0.23-2.27
	c-0.74,0.07-1.43,0.13-2.17,0.2C567.11,501.74,567.11,502.43,567.11,503.28z M484.29,143.09c-0.96,0.26-1.69,0.45-2.42,0.65
	c0.12,0.75,0.21,1.37,0.31,2c0.76-0.07,1.44-0.14,2.11-0.2C484.29,144.72,484.29,144.02,484.29,143.09z M577.63,290.65
	c0.06,0.74,0.11,1.34,0.17,2.06c0.73-0.06,1.33-0.12,2.03-0.18c-0.04-0.73-0.08-1.33-0.11-1.88
	C578.95,290.65,578.34,290.65,577.63,290.65z M634.05,415.93c-0.88,0-1.57,0-2.37,0c0.07,0.74,0.14,1.42,0.21,2.21
	c0.84-0.06,1.58-0.11,2.38-0.16C634.19,417.22,634.12,416.61,634.05,415.93z M407.29,76.56c0.79-0.13,1.45-0.24,2.13-0.35
	c-0.13-0.78-0.23-1.44-0.33-2.01c-0.84,0-1.53,0-2.22,0C407.02,74.97,407.13,75.65,407.29,76.56z M554.02,352.79
	c0.76-0.05,1.49-0.09,2.35-0.15c-0.15-0.85-0.28-1.5-0.4-2.18c-0.79,0.05-1.39,0.08-2.06,0.13
	C553.95,351.38,553.98,352,554.02,352.79z M494.29,162.81c0.81-0.04,1.56-0.08,2.4-0.12c-0.14-0.88-0.26-1.61-0.38-2.34
	c-0.84,0.05-1.51,0.09-2.2,0.14C494.18,161.35,494.23,162.03,494.29,162.81z M617.94,300.45c0.99-0.19,1.75-0.34,2.52-0.49
	c-0.13-0.71-0.24-1.33-0.37-2.05c-0.73,0.06-1.42,0.12-2.15,0.18C617.94,298.87,617.94,299.58,617.94,300.45z M644.83,579.71
	c-0.1-0.94-0.17-1.62-0.25-2.4c-0.8,0.08-1.49,0.15-2.31,0.23c0.13,0.79,0.24,1.47,0.36,2.18
	C643.38,579.71,644.01,579.71,644.83,579.71z M617.51,290.87c0,0.86,0,1.54,0,2.22c0.88-0.05,1.64-0.09,2.49-0.13
	c-0.14-0.73-0.26-1.41-0.41-2.18C618.91,290.81,618.28,290.84,617.51,290.87z M474.83,146.38c0.73,0,1.42,0,2.21,0
	c-0.07-0.8-0.13-1.54-0.2-2.34c-0.75,0.12-1.36,0.22-2.1,0.33C474.77,145.06,474.8,145.68,474.83,146.38z M555.93,247.81
	c0.66-0.06,1.37-0.13,2.18-0.2c-0.14-0.89-0.25-1.61-0.37-2.36c-0.84,0.05-1.5,0.09-2.23,0.14
	C555.65,246.25,555.77,246.93,555.93,247.81z M479.56,161.83c0.09,0.82,0.17,1.54,0.24,2.26c0.87-0.07,1.55-0.13,2.35-0.19
	c-0.09-0.76-0.17-1.49-0.25-2.26C481.05,161.71,480.38,161.76,479.56,161.83z M594.99,367.33c0.81-0.11,1.54-0.21,2.32-0.32
	c-0.07-0.83-0.13-1.5-0.19-2.21c-0.86,0.05-1.52,0.08-2.13,0.12C594.99,365.81,594.99,366.5,594.99,367.33z M603.62,438.93
	c-0.06-0.8-0.12-1.62-0.18-2.5c-0.81,0.12-1.55,0.23-2.28,0.34c0.07,0.81,0.12,1.49,0.17,2.15
	C602.12,438.93,602.82,438.93,603.62,438.93z M600.95,183.94c0.04,0.77,0.07,1.38,0.1,2.05c0.81,0,1.56,0,2.44,0
	c-0.05-0.79-0.1-1.54-0.15-2.38C602.54,183.72,601.87,183.81,600.95,183.94z M505.63,183.55c0-0.83,0-1.68,0-2.57
	c-0.9,0.25-1.65,0.45-2.45,0.67c0.15,0.67,0.29,1.28,0.43,1.9C504.33,183.55,504.96,183.55,505.63,183.55z M511.99,202.3
	c0.15,0.84,0.28,1.58,0.42,2.37c0.77-0.12,1.43-0.22,2.12-0.33c-0.12-0.79-0.22-1.45-0.31-2.04
	C513.38,202.3,512.69,202.3,511.99,202.3z M590.42,412.34c-0.89,0.06-1.55,0.1-2.19,0.15c0.05,0.91,0.08,1.59,0.12,2.34
	c0.77-0.1,1.45-0.2,2.2-0.3C590.51,413.79,590.47,413.16,590.42,412.34z M408.81,66.75c-0.77,0.14-1.49,0.27-2.25,0.4
	c0.11,0.77,0.19,1.36,0.27,1.89c0.83,0,1.43,0,2.1,0C408.88,68.26,408.85,67.57,408.81,66.75z M596.27,186.71
	c-0.18-0.95-0.31-1.61-0.43-2.19c-0.8,0-1.41,0-2.15,0c0.06,0.78,0.12,1.46,0.18,2.19C594.67,186.71,595.37,186.71,596.27,186.71z
	 M413.97,68.7c0.85-0.06,1.57-0.1,2.35-0.15c-0.17-0.9-0.3-1.57-0.44-2.27c-0.75,0.1-1.35,0.19-1.91,0.27
	C413.97,67.33,413.97,67.95,413.97,68.7z M572.75,605.52c0,0.84,0,1.51,0,2.2c0.8-0.08,1.49-0.14,2.27-0.22
	c-0.12-0.82-0.23-1.54-0.35-2.31C573.98,605.31,573.45,605.4,572.75,605.52z M446.18,107.1c0,0.87,0,1.55,0,2.28
	c0.85-0.07,1.61-0.13,2.44-0.2c-0.13-0.7-0.25-1.37-0.37-2.08C447.56,107.1,446.94,107.1,446.18,107.1z M577.65,498.78
	c0.79-0.12,1.45-0.23,2.12-0.33c-0.08-0.79-0.15-1.41-0.21-2.02c-0.82,0-1.59,0-2.37,0C577.34,497.19,577.47,497.88,577.65,498.78z
	 M516.2,413.67c0.75-0.04,1.37-0.07,2-0.1c0-0.79,0-1.54,0-2.34c-0.85,0.1-1.61,0.18-2.41,0.27
	C515.92,412.22,516.03,412.83,516.2,413.67z M323.8,30.93c0.07,0.98,0.12,1.72,0.18,2.47c0.79-0.13,1.46-0.24,2.23-0.37
	c-0.11-0.75-0.2-1.41-0.29-2.1C325.13,30.93,324.52,30.93,323.8,30.93z M386.88,21.85c-0.94,0.07-1.74,0.13-2.61,0.19
	c0.18,0.77,0.33,1.42,0.49,2.12c0.8-0.05,1.49-0.09,2.25-0.14C386.96,23.25,386.92,22.58,386.88,21.85z M443.83,40.81
	c0.94-0.13,1.62-0.21,2.31-0.31c-0.08-0.75-0.15-1.37-0.22-2c-0.75,0-1.44,0-2.28,0C443.7,39.28,443.76,39.96,443.83,40.81z
	 M549.94,347.12c0.12,0.85,0.22,1.58,0.33,2.32c0.79-0.04,1.41-0.07,2.11-0.1c-0.05-0.8-0.1-1.54-0.15-2.33
	C551.43,347.05,550.74,347.08,549.94,347.12z M578.88,510.87c0.04,0.82,0.08,1.43,0.11,2.16c0.81-0.05,1.48-0.09,2.16-0.13
	c-0.04-0.86-0.08-1.55-0.12-2.29C580.32,510.7,579.69,510.77,578.88,510.87z M589.19,176.49c0.94-0.19,1.61-0.33,2.35-0.48
	c-0.06-0.75-0.12-1.42-0.18-2.18c-0.82,0.04-1.49,0.08-2.17,0.11C589.19,174.83,589.19,175.59,589.19,176.49z M390.65,64.81
	c-0.86,0.11-1.54,0.2-2.36,0.3c0.06,0.75,0.11,1.44,0.17,2.27c0.79-0.05,1.48-0.09,2.19-0.14
	C390.65,66.47,390.65,65.77,390.65,64.81z M589.45,233.99c-0.11-0.71-0.2-1.31-0.31-2c-0.69,0.07-1.28,0.13-1.99,0.2
	c0.08,0.71,0.15,1.29,0.21,1.8C588.13,233.99,588.73,233.99,589.45,233.99z M545.32,250.15c0.04,0.78,0.07,1.4,0.1,2.02
	c0.8,0,1.54,0,2.36,0c-0.08-0.87-0.15-1.62-0.22-2.37C546.78,249.92,546.16,250.02,545.32,250.15z M632.39,549.47
	c0.2,0.92,0.35,1.65,0.5,2.38c0.75-0.17,1.35-0.3,1.98-0.43c0-0.72,0-1.38,0-2.11C634.04,549.36,633.29,549.41,632.39,549.47z
	 M388,38.33c-0.07-0.85-0.12-1.45-0.17-1.99c-0.84,0-1.5,0-2.25,0c0.12,0.8,0.23,1.48,0.35,2.27
	C386.61,38.52,387.22,38.43,388,38.33z M412.01,65.19c-0.04-0.74-0.08-1.4-0.12-2.16c-0.82,0.05-1.48,0.08-2.14,0.12
	c0.04,0.84,0.07,1.52,0.11,2.32C410.55,65.38,411.22,65.3,412.01,65.19z M463.77,62.09c0.07,0.84,0.12,1.51,0.17,2.14
	c0.96,0,1.72,0,2.61,0c-0.18-0.82-0.33-1.54-0.49-2.27C465.26,62,464.64,62.04,463.77,62.09z M292.71,62.39
	c-0.98,0.11-1.7,0.19-2.43,0.27c0.16,0.93,0.27,1.61,0.4,2.34c0.77-0.12,1.43-0.22,2.03-0.31
	C292.71,63.84,292.71,63.22,292.71,62.39z M536.93,322.95c0.11,0.77,0.22,1.51,0.33,2.32c0.75-0.07,1.43-0.13,2.19-0.2
	c-0.06-0.73-0.11-1.41-0.16-2.12C538.51,322.95,537.8,322.95,536.93,322.95z M568.69,493.68c-0.8,0.06-1.56,0.11-2.41,0.17
	c0.12,0.81,0.23,1.54,0.36,2.36c0.77-0.16,1.43-0.3,2.05-0.43C568.69,494.98,568.69,494.37,568.69,493.68z M393.87,63.24
	c-0.11-0.73-0.2-1.38-0.29-2c-0.8,0-1.41,0-1.99,0c0,0.8,0,1.54,0,2.33C392.39,63.45,393.06,63.35,393.87,63.24z M540.95,589.96
	c-0.91,0.22-1.57,0.38-2.16,0.53c0,0.79,0,1.4,0,2.12c0.8-0.09,1.54-0.17,2.31-0.25C541.05,591.54,541.01,590.85,540.95,589.96z
	 M251.79,116.75c0.04,0.79,0.09,1.54,0.13,2.37c0.82-0.19,1.5-0.34,2.24-0.51c-0.04-0.76-0.07-1.45-0.1-2.15
	C253.25,116.57,252.56,116.65,251.79,116.75z M436.96,99.44c-0.17-0.9-0.3-1.57-0.43-2.22c-0.8,0-1.48,0-2.29,0
	c0.13,0.84,0.25,1.56,0.35,2.22C435.4,99.44,436.03,99.44,436.96,99.44z M268.43,99.67c0.91-0.13,1.65-0.24,2.45-0.36
	c-0.24-0.88-0.43-1.61-0.63-2.33c-0.75,0.07-1.35,0.13-1.94,0.19C268.36,98.04,268.39,98.75,268.43,99.67z M574,347.47
	c-0.05-0.97-0.09-1.71-0.13-2.51c-0.87,0.15-1.61,0.27-2.35,0.4c0.11,0.82,0.19,1.48,0.28,2.11
	C572.6,347.47,573.22,347.47,574,347.47z M515.23,572.94c0.91-0.07,1.6-0.13,2.45-0.19c-0.09-0.76-0.18-1.5-0.27-2.29
	c-0.85,0.1-1.51,0.18-2.17,0.26C515.23,571.52,515.23,572.15,515.23,572.94z M471.55,121.68c-0.17-1.01-0.3-1.75-0.44-2.58
	c-0.82,0.24-1.48,0.43-2.13,0.63c0.06,0.75,0.11,1.35,0.16,1.95C469.98,121.68,470.68,121.68,471.55,121.68z M606.94,421.64
	c0.14,0.77,0.27,1.44,0.41,2.22c0.78-0.05,1.52-0.1,2.29-0.15c-0.07-0.83-0.12-1.49-0.17-2.07
	C608.51,421.64,607.77,421.64,606.94,421.64z M492.72,61.91c0.84-0.13,1.46-0.23,2.09-0.32c0-0.75,0-1.44,0-2.29
	c-0.78,0.14-1.43,0.26-2.09,0.38C492.72,60.47,492.72,61.11,492.72,61.91z M572.16,325.84c-0.06-0.77-0.12-1.44-0.17-2.05
	c-0.93,0-1.72,0-2.58,0c0.19,0.81,0.34,1.46,0.52,2.21C570.71,325.95,571.39,325.9,572.16,325.84z M640.68,516.37
	c0.07,0.82,0.14,1.58,0.21,2.39c0.77-0.15,1.43-0.28,2.05-0.4c-0.05-0.83-0.1-1.52-0.15-2.31
	C642.09,516.17,641.42,516.26,640.68,516.37z M635.83,516.74c-0.92,0.15-1.61,0.26-2.34,0.37c0.08,0.71,0.15,1.33,0.22,2.02
	c0.82-0.08,1.55-0.15,2.29-0.22C635.94,518.12,635.89,517.5,635.83,516.74z M464.85,116.13c0.05,0.81,0.09,1.57,0.14,2.47
	c0.78-0.19,1.51-0.37,2.25-0.55c-0.04-0.81-0.08-1.48-0.11-2.15C466.27,115.98,465.59,116.05,464.85,116.13z M382.6,40.53
	c0.06,0.82,0.1,1.42,0.14,2.03c0.86-0.04,1.55-0.08,2.36-0.12c-0.13-0.79-0.26-1.54-0.39-2.34C384,40.25,383.39,40.37,382.6,40.53z
	 M560.4,355.82c-0.04-0.87-0.08-1.68-0.12-2.5c-0.85,0.21-1.6,0.4-2.4,0.6c0.12,0.65,0.23,1.26,0.35,1.91
	C558.96,355.82,559.65,355.82,560.4,355.82z M355.88,24.57c0.1,0.78,0.19,1.49,0.28,2.23c0.84-0.15,1.44-0.26,2.02-0.36
	c0-0.81,0-1.5,0-2.29C357.39,24.29,356.7,24.42,355.88,24.57z M308.34,59.91c0.92,0,1.67,0,2.52,0c-0.16-0.81-0.31-1.55-0.48-2.38
	c-0.71,0.07-1.37,0.13-2.04,0.19C308.34,58.52,308.34,59.13,308.34,59.91z M573.95,181.18c0-0.65,0-1.32,0-2.02
	c-0.89,0-1.65,0-2.5,0c0.12,0.77,0.23,1.43,0.35,2.14C572.54,181.26,573.23,181.22,573.95,181.18z M595.29,425.16
	c-0.18-0.92-0.3-1.58-0.44-2.26c-0.78,0.04-1.38,0.06-2.12,0.1c0.06,0.78,0.12,1.45,0.17,2.16
	C593.71,425.16,594.4,425.16,595.29,425.16z M554.24,313.05c0.84-0.05,1.6-0.1,2.41-0.15c-0.1-0.81-0.18-1.49-0.26-2.14
	c-0.81,0-1.54,0-2.29,0C554.16,311.6,554.19,312.29,554.24,313.05z M574.64,301.54c0.22,0.88,0.38,1.56,0.57,2.34
	c0.73-0.05,1.39-0.1,2.12-0.16c-0.1-0.81-0.19-1.48-0.28-2.18C576.26,301.54,575.57,301.54,574.64,301.54z M595.01,407.06
	c0.85-0.05,1.51-0.09,2.27-0.14c-0.14-0.84-0.25-1.51-0.38-2.25c-0.71,0.05-1.29,0.1-1.89,0.14
	C595.01,405.57,595.01,406.18,595.01,407.06z M393.08,38.07c0.81-0.1,1.48-0.18,2.21-0.27c-0.09-0.79-0.17-1.39-0.24-1.98
	c-0.78,0-1.44,0-2.11,0C392.99,36.57,393.03,37.19,393.08,38.07z M604.98,521.69c0.88-0.07,1.48-0.11,2.18-0.16
	c-0.1-0.81-0.18-1.47-0.26-2.19c-0.73,0.07-1.33,0.14-1.92,0.2C604.98,520.27,604.98,520.87,604.98,521.69z M597.01,388.7
	c0.79,0,1.55,0,2.44,0c-0.18-0.82-0.34-1.53-0.48-2.19c-0.78,0-1.41,0-2.12,0C596.9,387.26,596.95,387.95,597.01,388.7z
	 M645.91,591.77c-0.82,0.06-1.43,0.1-2.18,0.16c0.08,0.82,0.14,1.48,0.19,2.04c0.79,0,1.39,0,2.1,0
	C645.99,593.24,645.95,592.64,645.91,591.77z M658.98,576.09c-0.82,0.12-1.59,0.24-2.39,0.35c0.04,0.87,0.07,1.54,0.1,2.35
	c0.85-0.15,1.58-0.28,2.29-0.41C658.98,577.59,658.98,576.97,658.98,576.09z M410.33,41.24c-0.83,0.11-1.59,0.22-2.41,0.33
	c0.13,0.78,0.25,1.44,0.36,2.12c0.77,0,1.39,0,2.05,0C410.33,42.87,410.33,42.1,410.33,41.24z M630.92,569.94
	c0.78-0.09,1.4-0.15,2.08-0.23c0-0.72,0-1.42,0-2.19c-0.87,0.1-1.67,0.18-2.48,0.27C630.66,568.56,630.78,569.18,630.92,569.94z
	 M637.96,569.47c0.96-0.04,1.7-0.08,2.48-0.11c-0.16-0.8-0.3-1.48-0.46-2.26c-0.72,0.07-1.37,0.13-2.03,0.19
	C637.96,568.1,637.96,568.72,637.96,569.47z M326.85,26.73c0.19,0.77,0.38,1.51,0.58,2.3c0.72-0.06,1.32-0.11,1.93-0.16
	c0-0.82,0-1.45,0-2.14C328.55,26.73,327.78,26.73,326.85,26.73z M558.56,360.97c0,0.74,0,1.41,0,2.14c0.87-0.07,1.62-0.12,2.37-0.18
	c-0.09-0.79-0.17-1.48-0.25-2.2C559.94,360.81,559.25,360.89,558.56,360.97z M333.53,19.13c0.1,0.84,0.19,1.59,0.28,2.44
	c0.77-0.17,1.44-0.31,2.12-0.45c0-0.76,0-1.43,0-2.13C335.08,19.03,334.33,19.08,333.53,19.13z M511.22,567.28
	c0.11,0.84,0.21,1.57,0.31,2.31c0.86-0.11,1.54-0.19,2.26-0.28c-0.08-0.79-0.15-1.47-0.23-2.19
	C512.75,567.17,512.07,567.22,511.22,567.28z M620.27,545.92c-0.14-0.8-0.29-1.59-0.44-2.43c-0.79,0.13-1.39,0.23-2.1,0.35
	c0.05,0.76,0.09,1.43,0.13,2.08C618.72,545.92,619.42,545.92,620.27,545.92z M536.22,329c-0.14-0.85-0.24-1.44-0.33-1.97
	c-0.72,0-1.32,0-2.06,0c0.07,0.8,0.13,1.46,0.2,2.19C534.76,329.15,535.36,329.09,536.22,329z M589.67,486.98
	c-0.12-0.77-0.22-1.43-0.32-2.07c-0.78,0-1.39,0-2.07,0c0.05,0.81,0.09,1.55,0.14,2.35C588.2,487.16,588.87,487.07,589.67,486.98z
	 M498.68,189.29c-0.78,0.04-1.39,0.07-2.05,0.1c0.07,0.82,0.13,1.49,0.19,2.22c0.76-0.11,1.37-0.19,2.06-0.29
	C498.81,190.64,498.76,190.09,498.68,189.29z M456.74,120.72c-0.79,0-1.55,0-2.37,0c0.11,0.8,0.2,1.48,0.31,2.31
	c0.75-0.05,1.45-0.09,2.22-0.14C456.85,122.15,456.8,121.45,456.74,120.72z M616.19,445.18c0.95-0.14,1.7-0.25,2.54-0.37
	c-0.21-0.87-0.39-1.61-0.57-2.35c-0.75,0.05-1.38,0.1-1.97,0.14C616.19,443.46,616.19,444.24,616.19,445.18z M611.94,273
	c0.13,0.87,0.24,1.62,0.37,2.52c0.79-0.21,1.49-0.39,2.21-0.58c-0.07-0.76-0.12-1.36-0.18-1.94C613.51,273,612.81,273,611.94,273z
	 M423.68,54.92c0,0.73,0,1.4,0,2.16c0.82-0.1,1.56-0.19,2.3-0.27c-0.07-0.83-0.12-1.52-0.19-2.31
	C425.04,54.65,424.38,54.78,423.68,54.92z M615.88,522.16c0,0.87,0,1.56,0,2.37c0.83-0.14,1.56-0.26,2.39-0.4
	c-0.08-0.85-0.14-1.58-0.2-2.32C617.25,521.94,616.63,522.04,615.88,522.16z M402.47,37.05c-0.05-0.88-0.09-1.55-0.13-2.23
	c-0.81,0.08-1.48,0.15-2.28,0.23c0.09,0.74,0.18,1.46,0.28,2.22C401.08,37.19,401.68,37.13,402.47,37.05z M639.34,541.96
	c0.04,0.71,0.09,1.37,0.13,2.06c0.87-0.05,1.6-0.09,2.4-0.13c-0.17-0.87-0.32-1.6-0.47-2.38
	C640.68,541.67,640.08,541.8,639.34,541.96z M368.02,12.55c-0.8,0.1-1.49,0.19-2.32,0.3c0.1,0.74,0.2,1.41,0.28,1.99
	c0.84,0,1.5,0,2.16,0C368.1,14.06,368.06,13.44,368.02,12.55z M478.07,142.67c0.76-0.08,1.5-0.16,2.3-0.25
	c-0.18-0.81-0.32-1.46-0.47-2.12c-0.74,0.04-1.34,0.07-2.01,0.11C477.95,141.18,478,141.86,478.07,142.67z M645.36,586.77
	c-0.12-0.89-0.21-1.55-0.3-2.23c-0.84,0.05-1.5,0.09-2.17,0.12c0.06,0.93,0.11,1.67,0.16,2.43
	C643.85,586.98,644.53,586.89,645.36,586.77z M646.57,559.27c-0.89,0-1.57,0-2.4,0c0.13,0.79,0.25,1.46,0.36,2.1
	c0.78,0,1.45,0,2.16,0C646.65,560.62,646.61,560,646.57,559.27z M585.9,441.51c-0.78,0.11-1.45,0.21-2.25,0.32
	c0.13,0.77,0.24,1.41,0.32,1.93c0.79,0,1.38,0,1.92,0C585.9,442.97,585.9,442.29,585.9,441.51z M372.62,51.94
	c0.07,0.79,0.13,1.47,0.2,2.24c0.79-0.06,1.48-0.12,2.29-0.18c-0.07-0.73-0.13-1.42-0.21-2.18
	C374.11,51.86,373.43,51.9,372.62,51.94z M656.1,542.77c-0.16-0.82-0.32-1.62-0.49-2.43c-0.74,0.06-1.36,0.11-1.99,0.17
	c0.06,0.86,0.11,1.62,0.16,2.43C654.55,542.87,655.24,542.83,656.1,542.77z M568.03,264.91c0.83-0.13,1.57-0.24,2.32-0.36
	c-0.07-0.77-0.12-1.39-0.19-2.12c-0.77,0.06-1.45,0.11-2.13,0.16C568.03,263.41,568.03,264.1,568.03,264.91z M598.94,425.97
	c-0.98,0.11-1.72,0.2-2.47,0.28c0.14,0.81,0.26,1.49,0.4,2.27c0.75-0.16,1.4-0.3,2.07-0.44
	C598.94,427.34,598.94,426.72,598.94,425.97z M491.82,149.96c-0.88,0.18-1.53,0.31-2.19,0.44c0.05,0.77,0.09,1.38,0.13,2.01
	c0.83,0,1.58,0,2.44,0C492.08,151.64,491.96,150.9,491.82,149.96z M369.66,34.27c-0.79,0.04-1.45,0.08-2.13,0.11
	c0,0.83,0,1.52,0,2.38c0.79-0.13,1.52-0.25,2.27-0.38C369.75,35.59,369.71,34.98,369.66,34.27z M435.58,27
	c0.73-0.05,1.41-0.09,2.16-0.14c-0.08-0.79-0.15-1.48-0.22-2.14c-0.85,0-1.62,0-2.43,0C435.26,25.52,435.41,26.2,435.58,27z
	 M595.69,390.26c-0.77,0.05-1.44,0.09-2.13,0.14c0.06,0.83,0.11,1.51,0.17,2.29c0.76-0.09,1.41-0.17,2.1-0.25
	C595.78,391.61,595.74,391,595.69,390.26z M525.46,147.05c0.06,0.89,0.12,1.71,0.18,2.53c0.89-0.14,1.63-0.26,2.41-0.38
	c-0.23-0.88-0.41-1.61-0.6-2.34C526.71,146.92,526.09,146.98,525.46,147.05z M449.84,105.19c0.69-0.12,1.26-0.21,1.84-0.31
	c-0.05-0.79-0.08-1.4-0.12-2.11c-0.77,0.06-1.44,0.12-2.2,0.18C449.52,103.74,449.67,104.41,449.84,105.19z M562,375.12
	c-0.91,0.1-1.61,0.17-2.39,0.25c0.05,0.8,0.1,1.54,0.16,2.36c0.85-0.2,1.52-0.36,2.23-0.52C562,376.51,562,375.89,562,375.12z
	 M546.47,264.51c0.08,0.78,0.15,1.5,0.22,2.15c0.85,0,1.54,0,2.31,0c-0.1-0.87-0.19-1.68-0.29-2.49
	C547.92,264.29,547.23,264.4,546.47,264.51z M309.98,52.36c-0.16-0.83-0.29-1.48-0.42-2.13c-0.79,0.1-1.45,0.19-2.13,0.28
	c0.07,0.86,0.13,1.59,0.19,2.33C308.47,52.67,309.14,52.53,309.98,52.36z M538.81,220.56c0.82-0.12,1.63-0.24,2.47-0.36
	c-0.1-0.79-0.19-1.47-0.29-2.26c-0.76,0.04-1.45,0.07-2.18,0.11C538.81,218.86,538.81,219.64,538.81,220.56z M578.94,531.11
	c-0.08-0.84-0.14-1.44-0.19-1.98c-0.75,0-1.35,0-2.09,0c0.09,0.78,0.17,1.42,0.25,2.11C577.6,531.19,578.14,531.16,578.94,531.11z
	 M364.71,16.8c-0.81,0-1.58,0-2.47,0c0.14,0.81,0.26,1.52,0.37,2.19c0.87-0.1,1.54-0.17,2.31-0.26
	C364.85,18.02,364.78,17.41,364.71,16.8z M371.91,44.67c0.13,0.82,0.25,1.57,0.37,2.37c0.76-0.19,1.41-0.34,2.06-0.5
	c-0.04-0.71-0.08-1.32-0.12-1.99C373.44,44.59,372.7,44.63,371.91,44.67z M480.71,147.64c-0.69,0-1.36,0-2.13,0
	c0.05,0.79,0.09,1.45,0.13,2.16c0.89-0.04,1.57-0.07,2.32-0.11C480.91,148.92,480.81,148.3,480.71,147.64z M363.9,9.39
	c-0.87,0-1.54,0-2.27,0c0.09,0.93,0.18,1.75,0.27,2.67c0.77-0.19,1.43-0.35,2.13-0.52C363.99,10.79,363.95,10.17,363.9,9.39z
	 M483.31,51.84c-0.05-0.82-0.1-1.55-0.15-2.37c-0.85,0.12-1.58,0.23-2.34,0.35c0.17,0.82,0.31,1.47,0.43,2.03
	C482.02,51.84,482.63,51.84,483.31,51.84z M507.56,191.95c0.05,0.86,0.09,1.61,0.13,2.42c0.84-0.15,1.58-0.28,2.39-0.43
	c-0.13-0.77-0.24-1.49-0.36-2.19C508.98,191.82,508.37,191.88,507.56,191.95z M448.68,29.42c0-0.87,0-1.54,0-2.23
	c-0.86,0.06-1.62,0.11-2.45,0.17c0.18,0.77,0.34,1.43,0.49,2.06C447.41,29.42,447.96,29.42,448.68,29.42z M531.25,213.81
	c0.88,0,1.63,0,2.47,0c-0.17-0.84-0.32-1.59-0.47-2.36c-0.75,0.09-1.44,0.17-2.14,0.26C531.16,212.4,531.2,213.03,531.25,213.81z
	 M534.62,586.94c0.08,0.91,0.14,1.64,0.21,2.44c0.83-0.2,1.5-0.36,2.21-0.53c-0.08-0.73-0.16-1.4-0.23-2.06
	C536.08,586.85,535.46,586.89,534.62,586.94z M444.49,103.41c-0.92,0.22-1.59,0.38-2.17,0.52c0,0.81,0,1.43,0,2.15
	c0.81-0.09,1.56-0.17,2.33-0.26C444.6,105,444.55,104.3,444.49,103.41z M574.37,336.53c0.91-0.12,1.52-0.2,2.04-0.27
	c0-0.75,0-1.36,0-2.11c-0.8,0.05-1.47,0.1-2.18,0.15C574.27,335.07,574.31,335.69,574.37,336.53z M537.16,214.55
	c-0.9,0.08-1.62,0.14-2.41,0.2c0.18,0.88,0.32,1.53,0.47,2.24c0.83-0.07,1.49-0.12,2.16-0.17
	C537.3,216.04,537.24,215.42,537.16,214.55z M617.02,538.96c0.9-0.17,1.71-0.32,2.51-0.48c-0.17-0.83-0.3-1.5-0.44-2.17
	c-0.8,0.11-1.47,0.2-2.08,0.28C617.02,537.44,617.02,538.14,617.02,538.96z M563.5,602.52c-0.71,0.11-1.36,0.22-2,0.32
	c0,0.88,0,1.63,0,2.45c0.79-0.11,1.55-0.21,2.3-0.31C563.7,604.15,563.61,603.39,563.5,602.52z M343.64,47.41c0,0.85,0,1.5,0,2.24
	c0.87-0.1,1.61-0.18,2.36-0.26c-0.2-0.84-0.35-1.51-0.52-2.21C344.84,47.26,344.3,47.33,343.64,47.41z M603.74,356.98
	c-0.9,0.15-1.55,0.26-2.2,0.37c0.08,0.77,0.14,1.37,0.21,2.05c0.78-0.09,1.44-0.16,2.16-0.25
	C603.85,358.44,603.8,357.83,603.74,356.98z M266.53,93.79c-0.77,0-1.45,0-2.12,0c0,0.89,0,1.7,0,2.54
	c0.86-0.15,1.59-0.28,2.37-0.42C266.69,95.16,266.61,94.49,266.53,93.79z M525.26,395.03c0.79-0.04,1.52-0.08,2.36-0.12
	c-0.15-0.85-0.26-1.52-0.4-2.3c-0.83,0.05-1.57,0.09-2.34,0.13C525.01,393.57,525.12,394.24,525.26,395.03z M437.96,95.36
	c0.88-0.07,1.55-0.12,2.29-0.18c-0.05-0.76-0.1-1.45-0.15-2.19c-0.83,0.17-1.56,0.32-2.3,0.47
	C437.85,94.12,437.9,94.67,437.96,95.36z M606.01,536.22c0.93-0.13,1.68-0.23,2.47-0.34c-0.17-0.79-0.32-1.45-0.45-2.04
	c-0.77,0-1.38,0-2.02,0C606.01,534.6,606.01,535.29,606.01,536.22z M568.69,272.15c0.68-0.09,1.4-0.19,2.16-0.3
	c-0.05-0.84-0.09-1.54-0.13-2.34c-0.73,0.09-1.41,0.17-2.03,0.25C568.69,270.54,568.69,271.24,568.69,272.15z M576.25,608.71
	c0.17,0.92,0.29,1.57,0.41,2.21c0.87-0.07,1.54-0.12,2.28-0.17c-0.14-0.79-0.25-1.4-0.36-2.04
	C577.82,608.71,577.13,608.71,576.25,608.71z M522.16,580.89c-0.93,0.06-1.73,0.11-2.59,0.17c0.18,0.77,0.33,1.42,0.5,2.14
	c0.79-0.05,1.46-0.1,2.23-0.15C522.24,582.26,522.2,581.6,522.16,580.89z M599.69,295.74c0.05,1,0.09,1.82,0.12,2.65
	c0.9-0.2,1.64-0.36,2.39-0.52c-0.11-0.78-0.21-1.45-0.31-2.14C601.16,295.74,600.54,295.74,599.69,295.74z M582.69,181.95
	c0.05,0.88,0.09,1.56,0.13,2.22c0.88,0,1.64,0,2.46,0c-0.17-0.84-0.32-1.59-0.49-2.4C584.16,181.82,583.54,181.88,582.69,181.95z
	 M347.49,29.09c-0.83,0-1.43,0-2.12,0c0.11,0.79,0.19,1.45,0.29,2.18c0.81-0.06,1.49-0.1,2.19-0.15
	C347.72,30.36,347.61,29.75,347.49,29.09z M486.21,45.81c-0.79,0.03-1.45,0.06-2.11,0.09c0.04,0.88,0.07,1.55,0.11,2.32
	c0.76-0.12,1.42-0.22,2.13-0.33C486.29,47.09,486.25,46.49,486.21,45.81z M459.82,111.79c-0.2-0.84-0.38-1.56-0.55-2.26
	c-0.76,0.04-1.38,0.07-2.08,0.1c0.07,0.75,0.13,1.44,0.2,2.16C458.2,111.79,458.96,111.79,459.82,111.79z M578.62,357.95
	c-0.17-0.92-0.29-1.58-0.41-2.26c-0.74,0.07-1.34,0.12-2.02,0.19c0.09,0.8,0.16,1.46,0.23,2.18
	C577.17,358.01,577.78,357.99,578.62,357.95z M423.21,26.06c-0.89,0.06-1.61,0.1-2.36,0.15c0.13,0.83,0.23,1.49,0.34,2.21
	c0.79-0.16,1.46-0.29,2.24-0.44C423.36,27.39,423.3,26.87,423.21,26.06z M596.05,432.36c-0.22-0.91-0.39-1.62-0.56-2.34
	c-0.77,0.09-1.36,0.16-2.03,0.24c0.09,0.83,0.17,1.48,0.23,2.1C594.52,432.36,595.14,432.36,596.05,432.36z M568.64,533.3
	c-0.76,0.18-1.42,0.34-2.19,0.52c0.05,0.72,0.09,1.38,0.14,2.08c0.84-0.09,1.51-0.16,2.2-0.24
	C568.73,534.8,568.69,534.11,568.64,533.3z M346.97,23.99c0-0.74,0-1.48,0-2.32c-0.73,0.07-1.4,0.14-2.12,0.21
	c0.09,0.78,0.18,1.46,0.27,2.23C345.75,24.07,346.29,24.04,346.97,23.99z M664.18,593.88c-0.94,0.07-1.68,0.13-2.47,0.19
	c0.12,0.73,0.23,1.34,0.33,1.93c0.77,0,1.46,0,2.25,0C664.25,595.27,664.22,594.65,664.18,593.88z M414.25,47.01
	c-0.14-0.91-0.25-1.56-0.35-2.23c-0.74,0.04-1.34,0.08-2.02,0.12c0.05,0.83,0.09,1.49,0.14,2.21
	C412.84,47.08,413.46,47.05,414.25,47.01z M573.22,213.66c-0.04-0.84-0.08-1.58-0.12-2.36c-0.87,0.18-1.54,0.32-2.36,0.49
	c0.1,0.74,0.19,1.41,0.29,2.17C571.78,213.86,572.45,213.77,573.22,213.66z M580.22,337.63c-0.8,0-1.42,0-2.11,0
	c0.07,0.74,0.13,1.41,0.2,2.17c0.78-0.07,1.45-0.14,2.14-0.2C580.37,338.88,580.3,338.34,580.22,337.63z M569.73,215.67
	c-0.76,0-1.45,0-2.27,0c0.05,0.76,0.09,1.43,0.14,2.17c0.88-0.08,1.62-0.14,2.4-0.21C569.9,216.89,569.81,216.28,569.73,215.67z
	 M569.04,456.3c-0.12-0.77-0.23-1.42-0.34-2.09c-0.75,0-1.36,0-2.05,0c0.07,0.73,0.13,1.41,0.2,2.09
	C567.59,456.3,568.26,456.3,569.04,456.3z M605.15,537.43c-0.85,0.14-1.52,0.24-2.24,0.36c0.06,0.81,0.11,1.42,0.14,1.93
	c0.81,0,1.47,0,2.09,0C605.15,538.94,605.15,538.31,605.15,537.43z M529.2,580.27c-0.82,0.06-1.57,0.12-2.32,0.18
	c0.12,0.84,0.22,1.51,0.33,2.25c0.8-0.05,1.47-0.1,2.22-0.14C529.35,581.73,529.28,581.06,529.2,580.27z M408.37,48.73
	c0.18,0.84,0.33,1.54,0.49,2.28c0.78-0.11,1.35-0.19,1.96-0.27c-0.07-0.83-0.13-1.43-0.19-2.11
	C409.86,48.66,409.18,48.69,408.37,48.73z M602.15,530.48c0.07,0.75,0.13,1.43,0.2,2.22c0.7-0.03,1.31-0.06,2.09-0.1
	c-0.05-0.76-0.1-1.44-0.15-2.13C603.51,530.48,602.88,530.48,602.15,530.48z M586.05,219.77c0.76,0,1.45,0,2.24,0
	c-0.09-0.78-0.16-1.45-0.25-2.27c-0.74,0.04-1.43,0.08-2.2,0.12C585.91,218.38,585.98,219.06,586.05,219.77z M588.47,430.56
	c-0.78,0.05-1.44,0.09-2.18,0.13c0.1,0.79,0.19,1.46,0.28,2.12c0.69,0,1.29,0,2.05,0C588.56,432.04,588.52,431.37,588.47,430.56z
	 M490.29,48.99c-0.84,0.18-1.43,0.3-2.06,0.43c0.04,0.73,0.08,1.32,0.11,1.91c0.84,0,1.51,0,2.31,0
	C490.54,50.55,490.43,49.88,490.29,48.99z M611.52,265.99c0.16,0.85,0.28,1.5,0.41,2.19c0.77-0.13,1.37-0.23,1.97-0.33
	c0-0.78,0-1.47,0-2.24C613.13,265.73,612.46,265.84,611.52,265.99z M550.61,309.63c0.84-0.08,1.44-0.15,2.06-0.21
	c-0.08-0.83-0.14-1.5-0.21-2.27c-0.85,0.13-1.52,0.23-2.27,0.34C550.33,308.21,550.45,308.82,550.61,309.63z M359.74,27.91
	c0.12,0.81,0.21,1.47,0.3,2.13c0.82-0.07,1.5-0.13,2.19-0.2c-0.13-0.82-0.23-1.49-0.34-2.16C361.18,27.76,360.57,27.83,359.74,27.91
	z M573.69,220.82c-0.05-0.75-0.1-1.41-0.15-2.12c-0.81,0.07-1.49,0.13-2.24,0.2c0.06,0.79,0.11,1.47,0.16,2.26
	C572.23,221.04,572.9,220.94,573.69,220.82z M553.44,92.69c0.72-0.06,1.26-0.1,1.85-0.15c0-0.79,0-1.47,0-2.28
	c-0.75,0.09-1.42,0.18-2.12,0.26C553.26,91.25,553.34,91.87,553.44,92.69z M636.36,563.47c-0.87,0.22-1.53,0.39-2.29,0.58
	c0.05,0.72,0.09,1.37,0.13,2.07c0.81-0.08,1.47-0.15,2.16-0.22C636.36,565.14,636.36,564.45,636.36,563.47z M589.2,479.85
	c-0.16-0.92-0.28-1.59-0.41-2.32c-0.73,0.1-1.32,0.18-1.95,0.26c0.08,0.8,0.14,1.41,0.2,2.06
	C587.73,479.85,588.35,479.85,589.2,479.85z M626.63,577.73c-0.13-0.85-0.23-1.51-0.35-2.29c-0.73,0.08-1.37,0.15-2.04,0.22
	c0.09,0.82,0.15,1.42,0.22,2.06C625.14,577.73,625.76,577.73,626.63,577.73z M505.13,553.54c-0.76,0-1.45,0-2.23,0
	c0.09,0.78,0.17,1.46,0.26,2.29c0.76-0.05,1.44-0.1,2.2-0.15C505.28,554.92,505.2,554.23,505.13,553.54z M649.99,584.19
	c0.05,0.76,0.09,1.42,0.13,2.13c0.89-0.05,1.64-0.09,2.48-0.13c-0.13-0.73-0.24-1.38-0.37-2.12
	C651.45,584.12,650.76,584.15,649.99,584.19z M245.2,137.49c-0.15-0.8-0.25-1.38-0.37-2.04c-0.78,0.04-1.47,0.08-2.23,0.13
	c0.05,0.77,0.08,1.42,0.12,2.08C243.63,137.59,244.32,137.55,245.2,137.49z M386.63,45.49c0.68,0,1.39,0,2.18,0
	c-0.11-0.81-0.21-1.47-0.3-2.16c-0.84,0.04-1.53,0.07-2.24,0.11C386.39,44.16,386.5,44.77,386.63,45.49z M576.15,539.96
	c-0.88,0.06-1.6,0.12-2.35,0.17c0.23,0.87,0.39,1.53,0.56,2.18c0.74-0.04,1.34-0.08,1.98-0.11
	C576.28,541.43,576.23,540.81,576.15,539.96z M614,542.6c0.88-0.09,1.55-0.16,2.18-0.22c0-0.83,0-1.52,0-2.38
	c-0.84,0.21-1.55,0.4-2.18,0.56C614,541.28,614,541.83,614,542.6z M570.62,127.34c0.74-0.13,1.38-0.24,2.1-0.36
	c-0.09-0.78-0.16-1.38-0.23-1.99c-0.73,0.04-1.33,0.07-2,0.11C570.54,125.87,570.58,126.54,570.62,127.34z M605.68,528.76
	c0.89,0,1.57,0,2.34,0c-0.18-0.84-0.33-1.55-0.48-2.29c-0.73,0.08-1.33,0.15-1.99,0.23C605.59,527.45,605.63,528.06,605.68,528.76z
	 M567.02,543.21c0.87-0.21,1.53-0.37,2.2-0.53c-0.05-0.77-0.09-1.38-0.13-2.05c-0.78,0.12-1.44,0.22-2.06,0.31
	C567.02,541.74,567.02,542.35,567.02,543.21z M570.68,602.04c-0.82,0.05-1.49,0.1-2.26,0.14c0.08,0.77,0.16,1.45,0.24,2.22
	c0.75-0.12,1.41-0.23,2.12-0.35C570.74,603.38,570.72,602.84,570.68,602.04z M421.87,53.35c-0.06-0.67-0.11-1.28-0.17-2
	c-0.79,0.04-1.53,0.08-2.28,0.13c0.13,0.84,0.23,1.52,0.35,2.3C420.5,53.63,421.16,53.49,421.87,53.35z M485.88,149.09
	c0.86-0.07,1.54-0.13,2.29-0.2c-0.05-0.79-0.09-1.48-0.14-2.28c-0.76,0.11-1.43,0.21-2.15,0.31
	C485.88,147.63,485.88,148.25,485.88,149.09z M614.93,264.44c0.88-0.16,1.56-0.29,2.27-0.42c0-0.79,0-1.53,0-2.34
	c-0.87,0.19-1.6,0.35-2.27,0.5C614.93,262.97,614.93,263.58,614.93,264.44z M493.31,528c0.76-0.21,1.47-0.4,2.21-0.61
	c-0.06-0.82-0.11-1.48-0.15-2.15c-0.86,0.1-1.52,0.18-2.17,0.26C493.23,526.37,493.27,527.12,493.31,528z M599.44,375.45
	c0.09,0.83,0.16,1.44,0.23,2.07c0.81-0.05,1.54-0.09,2.33-0.13c-0.07-0.7-0.14-1.3-0.2-1.94
	C601.01,375.45,600.33,375.45,599.44,375.45z M447.95,19.96c-0.86,0.14-1.54,0.25-2.21,0.36c0.15,0.8,0.28,1.49,0.41,2.21
	c0.65-0.1,1.25-0.19,1.92-0.28C448.04,21.5,448,20.81,447.95,19.96z M567.73,468.45c0.13,0.78,0.25,1.49,0.37,2.17
	c0.79-0.09,1.4-0.15,2.08-0.22c-0.07-0.72-0.14-1.39-0.22-2.1C569.19,468.35,568.51,468.4,567.73,468.45z M619.89,567.24
	c0.84-0.04,1.44-0.06,2.13-0.1c-0.08-0.81-0.15-1.48-0.21-2.13c-0.82,0-1.51,0-2.31,0C619.63,565.77,619.74,566.42,619.89,567.24z
	 M442.54,42.32c-0.81,0.05-1.42,0.1-2.02,0.14c0.04,0.91,0.08,1.68,0.12,2.48c0.79-0.1,1.55-0.2,2.36-0.31
	C442.84,43.85,442.71,43.17,442.54,42.32z M568.73,121.63c-0.83,0.06-1.53,0.1-2.28,0.15c0.17,0.83,0.28,1.42,0.42,2.11
	c0.71-0.05,1.3-0.09,1.86-0.12C568.73,122.97,568.73,122.37,568.73,121.63z M489.56,41.97c-0.74,0.04-1.35,0.07-2.05,0.11
	c0.06,0.71,0.13,1.38,0.18,2.03c0.8,0,1.48,0,2.22,0C489.8,43.42,489.7,42.82,489.56,41.97z M486.98,52.93
	c-0.85,0.07-1.51,0.12-2.22,0.18c0.09,0.85,0.16,1.51,0.24,2.21c0.84-0.05,1.5-0.09,2.17-0.13
	C487.1,54.37,487.05,53.75,486.98,52.93z M389.31,34.61c0.76-0.03,1.36-0.06,2.08-0.09c-0.04-0.8-0.08-1.47-0.12-2.26
	c-0.81,0.05-1.48,0.09-2.17,0.13C389.17,33.15,389.23,33.77,389.31,34.61z M634.43,544.33c0-0.8,0-1.55,0-2.42
	c-0.89,0.2-1.68,0.38-2.45,0.56c0.13,0.77,0.23,1.38,0.34,2.04C633.03,544.45,633.71,544.39,634.43,544.33z M361.26,44.24
	c0.85-0.04,1.45-0.07,2.14-0.1c-0.09-0.82-0.16-1.49-0.24-2.23c-0.81,0.06-1.49,0.1-2.22,0.15
	C361.05,42.83,361.14,43.45,361.26,44.24z M391.06,54.09c0.08,0.73,0.15,1.4,0.22,2.11c0.77-0.04,1.39-0.08,2.12-0.11
	c-0.16-0.81-0.29-1.46-0.43-2.12C392.26,54.01,391.71,54.05,391.06,54.09z M432.81,53.92c-0.78,0.04-1.39,0.08-2.14,0.12
	c0.14,0.82,0.26,1.49,0.39,2.23c0.69-0.06,1.23-0.1,1.89-0.15C432.9,55.42,432.86,54.81,432.81,53.92z M463.2,28.34
	c-0.13-0.92-0.22-1.58-0.32-2.27c-0.75,0.04-1.36,0.08-2.08,0.12c0.05,0.81,0.1,1.48,0.14,2.14
	C461.76,28.34,462.39,28.34,463.2,28.34z M582.22,467.25c0.09,0.78,0.16,1.38,0.22,1.99c0.8,0,1.41,0,2.11,0
	c-0.06-0.73-0.11-1.4-0.17-2.13C583.68,467.17,583.07,467.2,582.22,467.25z M590.93,166.66c-0.79,0.06-1.51,0.12-2.25,0.18
	c0.05,0.83,0.1,1.45,0.14,2.18c0.74-0.03,1.42-0.07,2.11-0.1C590.93,168.11,590.93,167.48,590.93,166.66z M470.03,133.72
	c0.15,0.76,0.28,1.41,0.43,2.15c0.7-0.05,1.29-0.09,1.83-0.13c0-0.79,0-1.38,0-2.03C471.51,133.72,470.85,133.72,470.03,133.72z
	 M384.07,56.72c0.76-0.05,1.29-0.09,1.96-0.13c-0.05-0.76-0.1-1.43-0.14-2.01c-0.82,0-1.48,0-2.22,0
	C383.8,55.33,383.92,55.94,384.07,56.72z M432,88.7c0-0.7,0-1.38,0-2.02c-0.79,0-1.46,0-2.14,0c0.05,0.83,0.08,1.51,0.13,2.3
	C430.7,88.88,431.32,88.79,432,88.7z M594.68,378.15c-0.08-0.86-0.14-1.53-0.2-2.31c-0.74,0.04-1.41,0.07-2.16,0.1
	c0.05,0.84,0.1,1.51,0.14,2.2C593.23,378.15,593.84,378.15,594.68,378.15z M570.09,370.62c0.1,0.88,0.18,1.55,0.27,2.34
	c0.77-0.04,1.46-0.08,2.23-0.12c-0.07-0.79-0.13-1.52-0.18-2.22C571.57,370.62,570.95,370.62,570.09,370.62z M598.25,168.44
	c-0.13-0.86-0.22-1.53-0.33-2.29c-0.75,0.1-1.35,0.17-2,0.25c0.04,0.74,0.07,1.36,0.11,2.04
	C596.71,168.44,597.33,168.44,598.25,168.44z M574.44,560.41c-0.09-0.77-0.17-1.45-0.26-2.22c-0.7,0.1-1.31,0.19-1.99,0.29
	c0.06,0.74,0.12,1.33,0.17,1.93C573.14,560.41,573.75,560.41,574.44,560.41z M494.68,43.65c0.89-0.1,1.57-0.18,2.33-0.26
	c-0.07-0.76-0.14-1.44-0.21-2.19c-0.82,0.1-1.48,0.18-2.12,0.26C494.68,42.21,494.68,42.83,494.68,43.65z M416.62,57.57
	c0.89,0,1.58,0,2.35,0c-0.18-0.84-0.33-1.57-0.49-2.31c-0.74,0.08-1.34,0.15-2.01,0.23C416.52,56.24,416.57,56.86,416.62,57.57z
	 M619.22,369.97c-0.74,0.07-1.41,0.14-2.08,0.2c0.04,0.9,0.08,1.67,0.12,2.51c0.8-0.12,1.54-0.23,2.28-0.35
	C619.42,371.48,619.33,370.79,619.22,369.97z M662.2,614.16c-0.13-0.73-0.25-1.4-0.36-2.06c-0.77,0.08-1.45,0.16-2.19,0.24
	c0.06,0.75,0.11,1.44,0.16,2.15C660.64,614.38,661.4,614.27,662.2,614.16z M566.46,467.33c-0.15-0.77-0.29-1.43-0.44-2.18
	c-0.69,0.06-1.28,0.11-1.82,0.15c0,0.79,0,1.39,0,2.03C564.98,467.33,565.64,467.33,566.46,467.33z M420.01,13.88
	c0.84,0,1.51,0,2.24,0c-0.14-0.77-0.25-1.43-0.36-2.07c-0.75,0-1.4,0-2.08,0C419.88,12.55,419.94,13.16,420.01,13.88z M598.42,547.4
	c-0.09-0.84-0.15-1.43-0.22-2.12c-0.78,0.06-1.45,0.12-2.19,0.18c0.18,0.83,0.32,1.49,0.46,2.17
	C597.17,547.55,597.72,547.48,598.42,547.4z M597.4,187.59c0.13,0.85,0.26,1.66,0.39,2.52c0.8-0.22,1.45-0.4,2.09-0.57
	c-0.05-0.75-0.09-1.36-0.13-1.95C598.95,187.59,598.25,187.59,597.4,187.59z M543.31,326.14c-0.9,0.14-1.57,0.24-2.28,0.35
	c0,0.78,0,1.46,0,2.26c0.82-0.14,1.55-0.27,2.28-0.4C543.31,327.61,543.31,327,543.31,326.14z M611.02,467.2
	c0.8-0.11,1.48-0.2,2.26-0.3c-0.1-0.8-0.19-1.55-0.29-2.39c-0.79,0.08-1.45,0.14-2.09,0.2C610.94,465.59,610.97,466.29,611.02,467.2
	z M636.05,547.89c0.88-0.1,1.55-0.17,2.3-0.25c-0.07-0.76-0.13-1.44-0.19-2.2c-0.75,0.1-1.42,0.18-2.11,0.27
	C636.05,546.46,636.05,547.07,636.05,547.89z M581.41,517.94c-0.71,0.06-1.36,0.12-2.04,0.18c0.04,0.78,0.07,1.37,0.11,2.08
	c0.8-0.05,1.45-0.09,2.13-0.14C581.54,519.27,581.48,518.67,581.41,517.94z M484.35,174.68c0.82-0.14,1.57-0.26,2.32-0.38
	c-0.1-0.82-0.18-1.49-0.25-2.04c-0.87,0-1.56,0-2.27,0C484.22,173.12,484.29,173.88,484.35,174.68z M477.57,135.39
	c0.83-0.04,1.44-0.07,2.21-0.1c-0.11-0.86-0.19-1.6-0.29-2.34c-0.78,0.11-1.4,0.19-2.07,0.29
	C477.47,133.96,477.52,134.58,477.57,135.39z M551.21,277.03c0.85-0.05,1.54-0.09,2.3-0.14c-0.05-0.79-0.09-1.46-0.13-2.18
	c-0.9,0.06-1.66,0.11-2.48,0.17C550.99,275.56,551.08,276.17,551.21,277.03z M625.69,568.4c-0.68,0-1.36,0-2.17,0
	c0.04,0.78,0.08,1.45,0.12,2.22c0.84-0.04,1.51-0.07,2.19-0.11C625.78,569.7,625.74,569.08,625.69,568.4z M586.44,488.55
	c-0.75,0.11-1.43,0.21-2.11,0.31c0,0.74,0,1.34,0,1.99c0.76,0,1.42,0,2.11,0C586.44,490.05,586.44,489.35,586.44,488.55z
	 M522.61,197.91c0.12,0.75,0.22,1.41,0.32,2.06c0.76-0.08,1.44-0.14,2.23-0.22c-0.08-0.71-0.15-1.36-0.23-2.02
	C524.09,197.79,523.41,197.85,522.61,197.91z M574.19,186.11c-0.87,0-1.66,0-2.47,0c0.23,0.93,0.43,1.74,0.64,2.6
	c0.73-0.21,1.37-0.4,1.99-0.58C574.3,187.37,574.25,186.76,574.19,186.11z M571.91,139.39c0,0.76,0,1.52,0,2.4
	c0.7-0.09,1.38-0.17,2.1-0.26c-0.1-0.76-0.18-1.44-0.28-2.23C573.12,139.33,572.57,139.36,571.91,139.39z M642.98,558.3
	c-0.12-0.68-0.24-1.41-0.38-2.22c-0.77,0.08-1.44,0.15-2.13,0.23c0.06,0.81,0.11,1.48,0.16,2.12
	C641.46,558.38,642.15,558.34,642.98,558.3z M529.7,569.36c0.1,0.86,0.16,1.47,0.25,2.2c0.74-0.03,1.42-0.07,2.18-0.1
	c-0.18-0.84-0.32-1.5-0.45-2.1C530.96,569.36,530.4,569.36,529.7,569.36z M611.22,387.48c0.93-0.08,1.7-0.15,2.52-0.22
	c-0.11-0.78-0.21-1.45-0.33-2.25c-0.81,0.16-1.54,0.3-2.3,0.45C611.15,386.15,611.18,386.69,611.22,387.48z M415.27,59.38
	c-0.74,0-1.46,0-2.2,0c0.08,0.77,0.14,1.38,0.2,2c0.7,0,1.37,0,2.12,0C415.35,60.63,415.31,60.02,415.27,59.38z M603.09,516.02
	c-0.8,0.1-1.46,0.19-2.17,0.28c0.1,0.72,0.17,1.26,0.25,1.79c0.75,0,1.41,0,2.08,0C603.2,517.39,603.15,516.84,603.09,516.02z
	 M563.22,515.98c-0.77,0.16-1.34,0.28-1.94,0.4c0.05,0.69,0.09,1.22,0.13,1.8c0.79,0,1.4,0,2.13,0
	C563.45,517.51,563.36,516.89,563.22,515.98z M433.19,82.74c0.06,0.78,0.11,1.51,0.16,2.17c0.87,0,1.55,0,2.3,0
	c-0.08-0.79-0.15-1.47-0.23-2.28C434.67,82.66,433.98,82.7,433.19,82.74z M542.15,256.2c0.81-0.18,1.41-0.31,2.11-0.47
	c-0.04-0.65-0.07-1.24-0.12-1.94c-0.84,0.08-1.57,0.15-2.31,0.22C541.95,254.8,542.04,255.41,542.15,256.2z M592.75,227.78
	c-0.8,0.17-1.56,0.33-2.36,0.49c0.08,0.73,0.14,1.31,0.19,1.86c0.88,0,1.55,0,2.27,0C592.82,229.37,592.79,228.68,592.75,227.78z
	 M561.54,288.55c-0.81,0.06-1.42,0.1-2.1,0.15c0.09,0.78,0.18,1.45,0.25,2.1c0.83,0,1.52,0,2.31,0
	C561.85,290.05,561.71,289.39,561.54,288.55z M405.71,59.76c0.18,0.9,0.32,1.55,0.46,2.22c0.74-0.05,1.27-0.08,1.95-0.13
	c-0.05-0.76-0.1-1.43-0.15-2.1C407.19,59.76,406.57,59.76,405.71,59.76z M564.63,490.57c-0.69,0.09-1.28,0.16-1.95,0.25
	c0.09,0.79,0.15,1.38,0.22,1.96c0.77,0,1.38,0,2.12,0C564.88,492.03,564.77,491.35,564.63,490.57z M342.92,42.3
	c0.78-0.06,1.44-0.1,2.15-0.15c-0.1-0.81-0.18-1.42-0.26-2.11c-0.7,0.05-1.3,0.08-1.99,0.13C342.85,40.93,342.88,41.54,342.92,42.3z
	 M584.06,546.65c-0.77,0.13-1.36,0.23-2.02,0.34c0.06,0.73,0.1,1.33,0.15,2.02c0.78-0.04,1.44-0.07,2.16-0.11
	C584.26,548.12,584.18,547.52,584.06,546.65z M400.72,15.47c-0.11-0.67-0.21-1.27-0.31-1.84c-0.77,0-1.46,0-2.32,0
	c0.11,0.84,0.2,1.55,0.3,2.27C399.26,15.73,399.94,15.61,400.72,15.47z M577.6,514.56c-0.81,0.04-1.56,0.07-2.34,0.11
	c0.2,0.81,0.36,1.48,0.51,2.12c0.72,0,1.33,0,2.02,0C577.73,516.05,577.67,515.36,577.6,514.56z M653.37,622.29
	c0.81-0.08,1.54-0.15,2.31-0.22c-0.07-0.83-0.12-1.52-0.19-2.26c-0.76,0.11-1.43,0.2-2.24,0.32
	C653.29,620.88,653.33,621.55,653.37,622.29z M415.03,80.92c0,0.79,0,1.39,0,2.07c0.78-0.08,1.46-0.15,2.27-0.23
	c-0.08-0.65-0.15-1.25-0.22-1.83C416.37,80.92,415.76,80.92,415.03,80.92z M582.91,492.5c-0.74,0.05-1.4,0.1-2.1,0.15
	c0.04,0.8,0.07,1.41,0.11,2.16c0.78-0.04,1.46-0.07,2.2-0.11C583.05,493.94,582.99,493.27,582.91,492.5z M392.1,41.76
	c-0.09-0.91-0.16-1.58-0.24-2.32c-0.71,0.11-1.32,0.2-1.9,0.29c0,0.76,0,1.37,0,2.03C390.68,41.76,391.3,41.76,392.1,41.76z
	 M593.79,408.67c-0.73,0-1.45,0-2.24,0c0.09,0.81,0.16,1.47,0.25,2.2c0.8-0.04,1.46-0.07,2.16-0.11
	C593.89,409.95,593.84,409.34,593.79,408.67z M579.58,312.23c0.05,0.68,0.1,1.35,0.16,2.1c0.76-0.05,1.37-0.1,2.1-0.15
	c-0.07-0.7-0.13-1.3-0.19-1.95C580.91,312.23,580.29,312.23,579.58,312.23z M586.93,440.2c0.68-0.09,1.35-0.17,2.14-0.27
	c-0.08-0.81-0.15-1.47-0.22-2.15c-0.79,0.04-1.39,0.06-2.05,0.09C586.85,438.69,586.88,439.39,586.93,440.2z M616.3,276.62
	c0.04,0.69,0.08,1.3,0.12,1.98c0.82-0.06,1.55-0.1,2.31-0.16c-0.04-0.87-0.07-1.56-0.11-2.36
	C617.8,276.27,617.08,276.44,616.3,276.62z M571.05,229.77c-0.99,0-1.74,0-2.55,0c0.2,0.84,0.37,1.57,0.55,2.33
	c0.75-0.11,1.41-0.21,2.01-0.3C571.05,231.04,571.05,230.42,571.05,229.77z M561.65,370.13c-0.08-0.93-0.14-1.64-0.21-2.42
	c-0.86,0.23-1.52,0.4-2.23,0.59c0.03,0.68,0.07,1.29,0.1,1.96C560.07,370.22,560.75,370.18,561.65,370.13z M639.93,551.36
	c0.84-0.18,1.55-0.34,2.33-0.5c-0.15-0.74-0.27-1.33-0.41-1.98c-0.7,0.07-1.31,0.13-1.92,0.19
	C639.93,549.86,639.93,550.54,639.93,551.36z M341.78,27.91c0.77-0.03,1.37-0.06,2.1-0.09c-0.05-0.8-0.09-1.46-0.13-2.23
	c-0.81,0.04-1.47,0.08-2.16,0.12C341.66,26.46,341.71,27.08,341.78,27.91z M593.19,385.41c0.77-0.06,1.44-0.11,2.16-0.16
	c-0.08-0.87-0.14-1.55-0.2-2.27c-0.8,0.09-1.42,0.16-2.12,0.23C593.08,383.94,593.13,384.62,593.19,385.41z M572.1,410.57
	c-0.67,0.03-1.32,0.06-2.08,0.1c0.1,0.8,0.19,1.44,0.27,2.13c0.78-0.12,1.37-0.22,2.01-0.32
	C572.23,411.8,572.17,411.26,572.1,410.57z M591.29,421.8c-0.06-0.79-0.12-1.52-0.19-2.29c-0.82,0.04-1.43,0.07-2.22,0.11
	c0.1,0.82,0.18,1.54,0.27,2.27C589.96,421.86,590.58,421.84,591.29,421.8z M571.28,134.5c0.9-0.05,1.56-0.09,2.24-0.13
	c-0.11-0.85-0.19-1.46-0.28-2.19c-0.7,0.04-1.36,0.07-2.08,0.11C571.2,133.07,571.23,133.68,571.28,134.5z M562.4,463.98
	c-0.05-0.76-0.09-1.49-0.14-2.3c-0.73,0.12-1.32,0.22-1.89,0.31c0,0.74,0,1.33,0,1.98C561.07,463.98,561.68,463.98,562.4,463.98z
	 M579.5,188.13c0.84-0.18,1.49-0.33,2.26-0.49c-0.06-0.75-0.11-1.43-0.16-2.14c-0.77,0.14-1.43,0.27-2.1,0.39
	C579.5,186.63,579.5,187.24,579.5,188.13z M403.93,38.46c0.06,0.87,0.11,1.56,0.17,2.32c0.82-0.16,1.57-0.31,2.35-0.47
	c-0.15-0.78-0.28-1.44-0.4-2.1C405.3,38.3,404.68,38.38,403.93,38.46z M636.95,573.09c-0.09-0.79-0.17-1.51-0.26-2.27
	c-0.83,0.16-1.49,0.29-2.16,0.42c0.05,0.77,0.1,1.38,0.14,2.03C635.45,573.21,636.13,573.16,636.95,573.09z M549.4,300.15
	c0.19,0.86,0.33,1.52,0.47,2.16c0.74,0,1.36,0,2.05,0c-0.07-0.74-0.13-1.43-0.19-2.16C551,300.15,550.31,300.15,549.4,300.15z
	 M421.03,65.95c0.03,0.76,0.06,1.37,0.09,2.08c0.8-0.08,1.52-0.15,2.31-0.22c-0.07-0.88-0.14-1.61-0.2-2.37
	C422.45,65.62,421.85,65.76,421.03,65.95z M562.83,384.46c-0.15-0.78-0.28-1.47-0.4-2.14c-0.8,0.08-1.42,0.14-2.11,0.21
	c0.08,0.71,0.15,1.38,0.22,2.07C561.32,384.56,562,384.51,562.83,384.46z M296.62,65.87c-0.73,0.1-1.38,0.18-2.06,0.27
	c0.08,0.8,0.14,1.4,0.2,1.96c0.8,0,1.48,0,2.23,0C296.86,67.32,296.74,66.66,296.62,65.87z M570.47,499.54
	c0.8-0.15,1.4-0.26,2.01-0.37c0-0.75,0-1.43,0-2.22c-0.75,0.12-1.39,0.22-2.01,0.31C570.47,498.06,570.47,498.67,570.47,499.54z
	 M596.9,278.17c-0.73,0.1-1.39,0.2-2.11,0.3c0.05,0.77,0.09,1.37,0.13,1.98c0.86,0,1.61,0,2.39,0
	C597.17,279.66,597.05,278.97,596.9,278.17z M593.95,162.85c-0.78,0.05-1.38,0.1-1.96,0.14c0,0.8,0,1.47,0,2.27
	c0.76-0.09,1.37-0.17,2.05-0.25C594.02,164.35,593.99,163.72,593.95,162.85z M591.11,237.45c0.84-0.14,1.45-0.24,2.16-0.36
	c-0.04-0.76-0.07-1.44-0.11-2.19c-0.79,0.14-1.47,0.26-2.25,0.41C590.98,236.02,591.04,236.63,591.11,237.45z M574.66,507.77
	c0.09,0.79,0.17,1.39,0.24,2.06c0.81-0.12,1.49-0.22,2.23-0.33c-0.11-0.76-0.2-1.36-0.28-1.94
	C576.1,507.64,575.48,507.7,574.66,507.77z M598.58,420.95c-0.1-0.81-0.17-1.41-0.25-2.07c-0.83,0.14-1.51,0.26-2.25,0.38
	c0.12,0.75,0.22,1.34,0.31,1.91C597.16,421.1,597.78,421.04,598.58,420.95z M547.8,281.2c0.86-0.17,1.53-0.3,2.36-0.47
	c-0.1-0.72-0.2-1.39-0.3-2.11c-0.76,0.11-1.42,0.21-2.06,0.3C547.8,279.72,547.8,280.34,547.8,281.2z M519.39,201.79
	c0.09,0.86,0.16,1.52,0.21,2.06c0.83,0,1.48,0,2.24,0c-0.16-0.8-0.29-1.46-0.44-2.18C520.73,201.71,520.19,201.74,519.39,201.79z
	 M439.21,28.06c0.06,0.88,0.1,1.56,0.15,2.35c0.8-0.11,1.41-0.19,2.13-0.29c-0.05-0.73-0.1-1.4-0.14-2.05
	C440.6,28.06,440.04,28.06,439.21,28.06z M338.76,36.75c0.18,0.87,0.32,1.54,0.47,2.22c0.76-0.04,1.36-0.07,2.05-0.11
	c-0.11-0.8-0.2-1.47-0.29-2.11C340.22,36.75,339.6,36.75,338.76,36.75z M584.15,506.88c-0.87,0.05-1.48,0.09-2.13,0.13
	c0,0.79,0,1.48,0,2.3c0.75-0.1,1.43-0.18,2.13-0.27C584.15,508.29,584.15,507.68,584.15,506.88z M427.6,76.05
	c-0.82,0.13-1.49,0.23-2.28,0.35c0.07,0.86,0.12,1.58,0.18,2.35c0.88-0.19,1.54-0.33,2.28-0.49
	C427.73,77.56,427.68,76.94,427.6,76.05z M381.51,28.23c0.83-0.16,1.49-0.29,2.14-0.42c-0.08-0.79-0.13-1.4-0.2-2.14
	c-0.72,0.1-1.39,0.2-2.1,0.31C381.39,26.72,381.44,27.34,381.51,28.23z M392.34,70.57c0.83-0.07,1.51-0.13,2.29-0.19
	c-0.14-0.75-0.26-1.4-0.39-2.08c-0.86,0.05-1.54,0.09-2.21,0.13C392.13,69.16,392.22,69.78,392.34,70.57z M372.96,30.53
	c-0.66-0.03-1.34-0.06-2.1-0.1c0.08,0.79,0.15,1.48,0.21,2.18c0.75,0,1.44,0,2.19,0C373.16,31.9,373.07,31.29,372.96,30.53z
	 M417.44,69.63c0.18,0.81,0.33,1.47,0.49,2.16c0.73-0.06,1.34-0.12,1.96-0.17c0-0.75,0-1.43,0-2.11
	C419.1,69.54,418.4,69.58,417.44,69.63z M603.54,505.04c0.14,0.83,0.25,1.54,0.37,2.26c0.79-0.04,1.39-0.07,2.07-0.1
	c-0.08-0.83-0.14-1.5-0.22-2.29C604.99,504.96,604.32,505,603.54,505.04z M553.68,598.35c0.9-0.11,1.57-0.2,2.33-0.29
	c-0.09-0.69-0.18-1.27-0.26-1.89c-0.82,0.07-1.44,0.12-2.07,0.17C553.68,597,553.68,597.55,553.68,598.35z M293.35,69.75
	c-0.78,0.04-1.45,0.07-2.2,0.11c0.09,0.8,0.17,1.47,0.26,2.24c0.77-0.08,1.43-0.15,2.1-0.21C293.45,71.1,293.4,70.49,293.35,69.75z
	 M528.85,200.79c-0.8,0.13-1.47,0.24-2.28,0.38c0.06,0.75,0.12,1.43,0.18,2.15c0.82-0.08,1.5-0.14,2.23-0.21
	C528.94,202.29,528.9,201.6,528.85,200.79z M558.71,381.12c0-0.74,0-1.49,0-2.34c-0.85,0.16-1.59,0.31-2.41,0.46
	c0.05,0.79,0.09,1.48,0.13,2.23C557.24,381.35,557.92,381.24,558.71,381.12z M606.45,554.19c-0.13-0.84-0.22-1.45-0.32-2.09
	c-0.73,0-1.35,0-2.05,0c0.07,0.73,0.14,1.4,0.21,2.09C605,554.19,605.62,554.19,606.45,554.19z M573.2,506.39
	c-0.03-0.73-0.07-1.46-0.1-2.22c-0.87,0.09-1.55,0.16-2.27,0.23c0.08,0.84,0.14,1.53,0.21,2.27
	C571.78,506.58,572.4,506.5,573.2,506.39z M645.87,554.19c0-0.72,0-1.41,0-2.22c-0.78,0.06-1.43,0.11-2.21,0.17
	c0.23,0.89,0.41,1.55,0.57,2.19C644.88,554.27,645.36,554.23,645.87,554.19z M489.46,163.38c-0.15-0.78-0.27-1.45-0.42-2.22
	c-0.74,0.07-1.4,0.14-2.1,0.21c0.06,0.79,0.1,1.46,0.15,2.12C487.88,163.45,488.57,163.42,489.46,163.38z M543.32,241.84
	c-0.21-0.86-0.37-1.53-0.53-2.18c-0.74,0-1.37,0-2.08,0c0.07,0.75,0.14,1.44,0.21,2.18C541.68,241.84,542.38,241.84,543.32,241.84z
	 M457.29,30c0.18,0.87,0.32,1.55,0.47,2.27c0.72-0.08,1.33-0.15,2.03-0.22c-0.08-0.8-0.14-1.47-0.2-2.15
	C458.75,29.94,458.13,29.97,457.29,30z M590.06,159.5c-0.82,0.04-1.51,0.08-2.25,0.12c0.16,0.78,0.3,1.45,0.43,2.07
	c0.74,0,1.36,0,2.04,0C590.2,160.99,590.15,160.37,590.06,159.5z M585.56,505.38c0.81-0.06,1.55-0.11,2.31-0.16
	c-0.2-0.84-0.35-1.51-0.48-2.08c-0.77,0-1.38,0-2.07,0C585.39,503.91,585.47,504.59,585.56,505.38z M565.43,457.95
	c-0.84,0.05-1.45,0.09-2.12,0.14c0.06,0.79,0.11,1.46,0.17,2.25c0.69-0.1,1.3-0.19,1.96-0.29
	C565.43,459.38,565.43,458.77,565.43,457.95z M583.81,501.93c-0.05-0.91-0.09-1.58-0.13-2.34c-0.85,0.1-1.51,0.17-2.19,0.25
	c0.07,0.83,0.11,1.43,0.17,2.09C582.34,501.93,582.96,501.93,583.81,501.93z M423.93,75.03c-0.08-0.81-0.15-1.54-0.22-2.3
	c-0.84,0.16-1.5,0.29-2.19,0.42c0.05,0.77,0.09,1.38,0.13,2.03C422.42,75.13,423.1,75.09,423.93,75.03z M613.15,282.58
	c0.71-0.09,1.39-0.18,2.09-0.27c-0.07-0.75-0.13-1.43-0.2-2.11c-0.88,0-1.68,0-2.49,0C612.77,281.07,612.96,281.82,613.15,282.58z
	 M640.52,574.27c-0.75,0.08-1.36,0.14-2.11,0.22c0.05,0.81,0.09,1.53,0.14,2.34c0.88-0.21,1.54-0.38,2.26-0.55
	C640.71,575.64,640.63,575.1,640.52,574.27z M467.46,36.55c-0.79,0.16-1.38,0.28-2.01,0.41c0.08,0.77,0.15,1.37,0.22,1.99
	c0.78-0.04,1.39-0.07,2.07-0.11C467.64,38.06,467.57,37.45,467.46,36.55z M605.1,273.7c0.05,0.87,0.1,1.7,0.16,2.55
	c0.77-0.13,1.45-0.24,2.2-0.37c-0.1-0.82-0.19-1.56-0.29-2.32C606.39,273.61,605.76,273.65,605.1,273.7z M374.01,39.55
	c-0.1-0.75-0.18-1.4-0.27-2.11c-0.83,0.14-1.49,0.24-2.21,0.36c0.09,0.76,0.17,1.36,0.24,1.94
	C372.5,39.68,373.12,39.63,374.01,39.55z M572.88,280.19c0.14,0.86,0.27,1.59,0.39,2.3c0.84-0.17,1.51-0.3,2.25-0.44
	c-0.13-0.71-0.24-1.31-0.34-1.86C574.39,280.19,573.75,280.19,572.88,280.19z M526.39,573.22c0,0.89,0,1.58,0,2.37
	c0.77-0.12,1.44-0.23,2.18-0.35c-0.03-0.76-0.06-1.36-0.09-2.02C527.72,573.22,527.1,573.22,526.39,573.22z M416.71,75.74
	c-0.17-0.87-0.3-1.51-0.43-2.17c-0.73,0.05-1.26,0.09-1.92,0.13c0.05,0.78,0.09,1.46,0.13,2.2
	C415.23,75.85,415.85,75.81,416.71,75.74z M604.99,229.13c0.8-0.13,1.39-0.23,1.97-0.33c-0.1-0.81-0.18-1.48-0.27-2.24
	c-0.83,0.07-1.51,0.13-2.2,0.19C604.65,227.55,604.8,228.24,604.99,229.13z M453.57,26.94c0.11,0.87,0.2,1.53,0.29,2.19
	c0.85-0.11,1.51-0.19,2.22-0.29c-0.12-0.81-0.21-1.43-0.32-2.13C455.07,26.78,454.45,26.84,453.57,26.94z M501.33,75.54
	c0.8-0.1,1.42-0.17,2.05-0.24c-0.11-0.78-0.21-1.47-0.33-2.28c-0.77,0.06-1.46,0.12-2.19,0.17
	C501.02,73.97,501.15,74.65,501.33,75.54z M496.22,182.22c0.04,0.68,0.08,1.34,0.13,2.11c0.73-0.06,1.31-0.11,2.01-0.16
	c-0.08-0.86-0.14-1.51-0.21-2.23C497.43,182.05,496.89,182.13,496.22,182.22z M577.74,182.14c-0.82,0.14-1.54,0.26-2.31,0.4
	c0.11,0.76,0.2,1.36,0.29,1.99c0.78-0.08,1.44-0.16,2.12-0.23C577.8,183.48,577.78,182.87,577.74,182.14z M622.3,572.13
	c-0.73,0.09-1.39,0.18-2.1,0.27c0.08,0.8,0.14,1.41,0.21,2.08c0.73-0.07,1.34-0.14,2.02-0.21
	C622.38,573.52,622.34,572.9,622.3,572.13z M589.78,447.13c-0.08-0.84-0.14-1.44-0.22-2.15c-0.67,0.04-1.27,0.07-2.01,0.11
	c0.09,0.82,0.15,1.47,0.22,2.14C588.52,447.19,589.07,447.16,589.78,447.13z M537.79,292.81c0.86-0.23,1.52-0.4,2.27-0.6
	c-0.04-0.65-0.07-1.24-0.1-1.91c-0.84,0.04-1.51,0.07-2.32,0.11C537.69,291.19,537.73,291.87,537.79,292.81z M551.38,587.72
	c-0.11-0.85-0.19-1.45-0.28-2.15c-0.7,0.1-1.28,0.18-1.95,0.27c0.1,0.77,0.18,1.36,0.26,1.98
	C550.1,587.79,550.66,587.76,551.38,587.72z M673.32,616.91c-0.14-0.76-0.27-1.44-0.41-2.19c-0.8,0.1-1.46,0.18-2.12,0.26
	c0.12,0.77,0.22,1.39,0.34,2.15C671.87,617.05,672.56,616.98,673.32,616.91z M610.14,417.65c0.26,0.96,0.46,1.69,0.65,2.39
	c0.7-0.06,1.24-0.1,1.92-0.16c-0.1-0.85-0.19-1.57-0.27-2.24C611.63,417.65,611.01,417.65,610.14,417.65z M412.74,72.44
	c-0.05-0.74-0.09-1.43-0.14-2.24c-0.74,0.08-1.4,0.15-2.12,0.23c0.07,0.78,0.12,1.38,0.18,2.01
	C411.41,72.44,412.03,72.44,412.74,72.44z M493.86,505.95c-0.13-0.66-0.25-1.31-0.39-2.01c-0.84,0.06-1.51,0.11-2.2,0.16
	c0.12,0.82,0.21,1.43,0.32,2.12C492.33,506.13,493.02,506.04,493.86,505.95z M286.1,70.52c-0.8,0.06-1.42,0.1-2.11,0.15
	c0.07,0.8,0.14,1.47,0.2,2.13c0.81-0.06,1.5-0.1,2.23-0.16C286.32,71.95,286.23,71.33,286.1,70.52z M600.92,449.87
	c-0.05-0.79-0.09-1.52-0.14-2.29c-0.84,0.14-1.5,0.25-2.2,0.37c0.07,0.75,0.12,1.34,0.17,1.92
	C599.51,449.87,600.13,449.87,600.92,449.87z M596.96,320.06c-0.12-0.83-0.22-1.51-0.33-2.28c-0.8,0.06-1.5,0.12-2.26,0.18
	c0.16,0.76,0.3,1.44,0.47,2.24C595.51,320.15,596.12,320.11,596.96,320.06z M604.38,239.92c-0.09-0.76-0.18-1.43-0.27-2.17
	c-0.78,0.14-1.45,0.26-2.18,0.39c0.12,0.8,0.2,1.4,0.29,2.01C602.98,240.07,603.59,240,604.38,239.92z M524.74,571.96
	c-0.05-0.85-0.09-1.45-0.13-2.12c-0.84,0.05-1.52,0.09-2.31,0.13c0.16,0.77,0.3,1.42,0.45,2.11
	C523.45,572.03,523.99,572,524.74,571.96z M572.22,119.88c-0.04-0.86-0.08-1.47-0.12-2.22c-0.76,0.16-1.4,0.29-2.07,0.43
	c0.09,0.74,0.16,1.32,0.23,1.95C570.96,119.98,571.49,119.94,572.22,119.88z M539.77,77.07c-0.86,0.14-1.62,0.27-2.4,0.4
	c0.14,0.79,0.26,1.47,0.38,2.16c0.75-0.07,1.43-0.13,2.14-0.19C539.85,78.65,539.81,77.95,539.77,77.07z M483.53,120.49
	c0.74-0.07,1.42-0.13,2.17-0.2c-0.13-0.75-0.25-1.41-0.4-2.22c-0.74,0.08-1.41,0.15-2.12,0.22
	C483.3,119.04,483.41,119.7,483.53,120.49z M492.74,186.03c0.09,0.75,0.16,1.41,0.25,2.15c0.74-0.08,1.32-0.14,1.99-0.22
	c-0.07-0.78-0.12-1.38-0.18-2.03C494.06,185.97,493.45,185.99,492.74,186.03z M601.41,370.4c-0.21-0.82-0.39-1.54-0.57-2.26
	c-0.67,0.05-1.2,0.09-1.83,0.14c0.05,0.82,0.09,1.48,0.14,2.29C599.97,370.51,600.66,370.46,601.41,370.4z M667.2,589.77
	c-0.9,0.1-1.56,0.17-2.26,0.25c0.2,0.82,0.36,1.5,0.54,2.25c0.68-0.04,1.29-0.08,1.98-0.12C667.37,591.35,667.3,590.67,667.2,589.77
	z M565.55,233.92c0.06,0.9,0.11,1.58,0.17,2.37c0.81-0.13,1.46-0.24,2.17-0.35c-0.17-0.77-0.3-1.38-0.43-2.02
	C566.86,233.92,566.33,233.92,565.55,233.92z M476.13,139.1c0-0.68,0-1.35,0-1.97c-0.76,0-1.42,0-2.12,0
	c0.1,0.78,0.18,1.38,0.28,2.09C474.92,139.18,475.46,139.15,476.13,139.1z M593.27,361.48c-0.8,0.08-1.47,0.14-2.21,0.22
	c0.17,0.76,0.31,1.42,0.44,1.98c0.72,0,1.25,0,1.94,0C593.38,362.93,593.33,362.27,593.27,361.48z M564.88,118.57
	c-0.79,0.04-1.44,0.08-2.15,0.12c0.05,0.83,0.1,1.44,0.14,2.14c0.77-0.1,1.45-0.19,2.25-0.3
	C565.04,119.84,564.96,119.25,564.88,118.57z M594.61,249.24c-0.82,0.22-1.53,0.41-2.29,0.61c0.12,0.68,0.22,1.21,0.32,1.78
	c0.74-0.03,1.35-0.06,1.96-0.09C594.61,250.74,594.61,250.04,594.61,249.24z M503.81,188.76c0.08,0.76,0.14,1.37,0.2,1.96
	c0.75,0,1.42,0,2.18,0c-0.09-0.84-0.17-1.57-0.25-2.3C505.19,188.54,504.58,188.64,503.81,188.76z M554.35,104.94
	c0.06,0.8,0.1,1.46,0.16,2.22c0.89-0.07,1.58-0.13,2.34-0.19c-0.15-0.78-0.29-1.46-0.44-2.24
	C555.66,104.81,555.06,104.87,554.35,104.94z M571.66,511.7c0.05,0.73,0.11,1.4,0.15,2.04c0.86,0,1.55,0,2.32,0
	c-0.1-0.85-0.2-1.61-0.3-2.49C573.14,511.39,572.47,511.53,571.66,511.7z M581.24,288.93c0.76-0.08,1.29-0.14,1.94-0.21
	c-0.05-0.75-0.09-1.4-0.13-2.14c-0.77,0.15-1.36,0.27-1.96,0.39C581.14,287.67,581.18,288.2,581.24,288.93z M564.67,514.23
	c0.83-0.08,1.49-0.14,2.18-0.2c-0.07-0.84-0.13-1.51-0.19-2.21c-0.8,0.14-1.47,0.26-2.2,0.39
	C564.54,512.86,564.59,513.41,564.67,514.23z M386.44,63.87c0-0.73,0-1.45,0-2.3c-0.82,0.09-1.48,0.16-2.18,0.23
	c0.19,0.84,0.35,1.52,0.5,2.19C385.36,63.95,385.84,63.92,386.44,63.87z M464.79,31.75c0.88-0.07,1.56-0.12,2.34-0.18
	c-0.19-0.79-0.35-1.45-0.49-2.01c-0.73,0-1.26,0-1.96,0C464.72,30.27,464.75,30.89,464.79,31.75z M538.19,252.99
	c0.91-0.07,1.58-0.13,2.36-0.19c-0.2-0.8-0.36-1.46-0.53-2.14c-0.8,0.05-1.41,0.09-2.04,0.13
	C538.06,251.54,538.12,252.17,538.19,252.99z M496.99,444.55c0.89-0.13,1.55-0.23,2.28-0.35c-0.18-0.8-0.32-1.46-0.47-2.15
	c-0.7,0.03-1.23,0.06-1.81,0.09C496.99,442.93,496.99,443.6,496.99,444.55z M565.02,452.93c-0.05-0.84-0.09-1.5-0.14-2.25
	c-0.74,0.13-1.33,0.23-2.04,0.35c0.06,0.72,0.12,1.31,0.18,2.01C563.66,453,564.2,452.97,565.02,452.93z M557.87,287.35
	c-0.07-0.87-0.12-1.48-0.16-1.99c-0.78,0-1.43,0-2.15,0c0.14,0.81,0.24,1.46,0.35,2.13C556.61,287.45,557.13,287.41,557.87,287.35z
	 M541.47,82.99c0.89-0.1,1.57-0.17,2.33-0.25c-0.1-0.72-0.19-1.38-0.29-2.12c-0.74,0.06-1.4,0.12-2.03,0.17
	C541.47,81.56,541.47,82.17,541.47,82.99z M624.71,516.32c-0.08-0.82-0.15-1.5-0.24-2.33c-0.76,0.04-1.45,0.09-2.23,0.13
	c0.18,0.84,0.33,1.58,0.49,2.37C623.44,516.43,624.04,516.38,624.71,516.32z M563.64,254.46c0.78-0.05,1.52-0.09,2.34-0.14
	c-0.17-0.83-0.31-1.5-0.46-2.26c-0.81,0.04-1.5,0.08-2.2,0.11C563.42,252.96,563.52,253.64,563.64,254.46z M619,325.33
	c-0.13-0.86-0.23-1.48-0.34-2.15c-0.73,0.1-1.4,0.2-2.1,0.3c0.13,0.77,0.24,1.45,0.35,2.12C617.65,325.5,618.26,325.43,619,325.33z
	 M585.43,230.62c-0.06-0.7-0.12-1.3-0.18-2.02c-0.69,0.1-1.28,0.19-1.97,0.29c0.1,0.72,0.19,1.3,0.27,1.91
	C584.24,230.73,584.77,230.68,585.43,230.62z M483.59,167.62c0.87-0.22,1.54-0.39,2.3-0.59c-0.03-0.7-0.06-1.31-0.09-2.03
	c-0.81,0.07-1.48,0.13-2.2,0.19C483.59,165.96,483.59,166.66,483.59,167.62z M568.32,515.53c0.05,0.71,0.1,1.38,0.15,2.09
	c0.81-0.08,1.48-0.15,2.18-0.22c-0.04-0.78-0.07-1.47-0.11-2.21C569.82,515.3,569.14,515.4,568.32,515.53z M577.5,137.71
	c-0.22-0.85-0.39-1.51-0.56-2.16c-0.69,0.03-1.22,0.06-1.89,0.09c0.04,0.77,0.08,1.42,0.12,2.08
	C575.96,137.71,576.58,137.71,577.5,137.71z M464.02,119.7c-0.84,0.19-1.58,0.35-2.33,0.52c0.13,0.73,0.24,1.33,0.35,1.94
	c0.79-0.07,1.41-0.12,1.98-0.17C464.02,121.17,464.02,120.49,464.02,119.7z M422.61,60.59c-0.05-0.86-0.08-1.48-0.12-2.08
	c-0.83,0-1.59,0-2.44,0c0.16,0.82,0.31,1.56,0.46,2.33C421.22,60.75,421.84,60.68,422.61,60.59z M387.84,60.08
	c0.71-0.06,1.36-0.12,2.07-0.19c-0.06-0.78-0.11-1.38-0.15-1.92c-0.79,0-1.43,0-2.11,0C387.72,58.73,387.77,59.34,387.84,60.08z
	 M300.92,58.2c0.15,0.77,0.28,1.43,0.41,2.08c0.77-0.08,1.38-0.14,2.02-0.2c-0.07-0.74-0.13-1.35-0.19-2.01
	C302.39,58.11,301.71,58.15,300.92,58.2z M493.63,193.33c0,0.75,0,1.35,0,2.04c0.75-0.04,1.37-0.08,2.07-0.12
	c-0.06-0.73-0.12-1.42-0.18-2.25C494.8,193.13,494.21,193.23,493.63,193.33z M618.31,564.08c-0.16-0.94-0.27-1.63-0.39-2.39
	c-0.7,0.16-1.36,0.31-2.06,0.47c0.05,0.72,0.08,1.32,0.12,1.92C616.79,564.08,617.47,564.08,618.31,564.08z M574.01,374.05
	c0.09,0.87,0.16,1.53,0.24,2.24c0.87-0.08,1.53-0.14,2.26-0.21c-0.19-0.81-0.34-1.46-0.47-2.04
	C575.32,374.05,574.78,374.05,574.01,374.05z M488.76,58.54c0.74-0.12,1.45-0.24,2.28-0.39c-0.09-0.64-0.16-1.16-0.24-1.74
	c-0.76,0-1.43,0-2.19,0C488.67,57.12,488.71,57.73,488.76,58.54z M327.8,34.31c0.11,0.77,0.21,1.44,0.31,2.14
	c0.78-0.11,1.45-0.2,2.13-0.3c-0.08-0.81-0.15-1.48-0.22-2.2C329.23,34.08,328.55,34.19,327.8,34.31z M579.14,285.42
	c-0.04-0.72-0.08-1.39-0.12-2.17c-0.69,0.06-1.21,0.11-1.87,0.17c0.06,0.75,0.1,1.42,0.16,2.18
	C577.97,285.54,578.5,285.48,579.14,285.42z M409.29,56c0.07,0.72,0.13,1.39,0.2,2.16c0.74-0.05,1.33-0.1,2-0.15
	c-0.05-0.81-0.09-1.42-0.13-2.12C410.62,55.93,410.01,55.96,409.29,56z M592.83,256.9c0.08,0.83,0.13,1.42,0.19,2.03
	c0.81-0.05,1.43-0.09,2.18-0.14c-0.06-0.75-0.11-1.43-0.16-2.15C594.28,256.73,593.66,256.8,592.83,256.9z M565.88,486.79
	c0.1,0.77,0.17,1.36,0.26,2.03c0.82-0.09,1.47-0.16,2.04-0.23c0-0.86,0-1.54,0-2.3C567.42,486.46,566.75,486.6,565.88,486.79z
	 M539.43,283.35c-0.8,0-1.48,0-2.29,0c0.07,0.73,0.13,1.38,0.2,2.12c0.86-0.16,1.52-0.28,2.27-0.43
	C539.54,284.43,539.49,283.9,539.43,283.35z M637.1,560.1c0.07,0.86,0.12,1.58,0.18,2.41c0.85-0.24,1.5-0.43,2.18-0.62
	c-0.05-0.74-0.1-1.35-0.15-2C638.59,559.96,637.98,560.02,637.1,560.1z M553.12,298.42c0.87,0,1.47,0,2.16,0
	c-0.09-0.77-0.16-1.44-0.24-2.19c-0.72,0.06-1.31,0.11-1.92,0.15C553.12,297.16,553.12,297.76,553.12,298.42z M496.49,521.69
	c0.06,0.79,0.11,1.51,0.16,2.28c0.78-0.16,1.38-0.28,2.1-0.42c-0.04-0.64-0.08-1.23-0.12-1.86
	C497.85,521.69,497.24,521.69,496.49,521.69z M610.81,258.84c0.13,0.77,0.25,1.45,0.37,2.13c0.76-0.07,1.44-0.13,2.26-0.21
	c-0.12-0.7-0.23-1.35-0.33-1.92C612.24,258.84,611.55,258.84,610.81,258.84z M497.69,177.07c-0.05-0.75-0.1-1.45-0.16-2.27
	c-0.74,0.08-1.4,0.15-2.13,0.23c0.07,0.79,0.13,1.41,0.18,2.04C496.34,177.07,496.97,177.07,497.69,177.07z M573.87,121.25
	c0.08,0.78,0.14,1.39,0.21,2.1c0.64-0.03,1.23-0.06,1.97-0.09c-0.06-0.8-0.11-1.45-0.16-2.12
	C575.17,121.18,574.62,121.21,573.87,121.25z M586.08,195.84c-0.91,0.08-1.58,0.14-2.33,0.21c0.18,0.79,0.33,1.45,0.51,2.24
	c0.74-0.04,1.41-0.07,2.12-0.1C586.28,197.39,586.2,196.71,586.08,195.84z M486.84,34.81c0.03,0.75,0.07,1.48,0.11,2.29
	c0.81-0.12,1.4-0.2,2.05-0.29c-0.07-0.82-0.13-1.42-0.19-2.12C488.13,34.73,487.58,34.76,486.84,34.81z M604.1,485.6
	c-0.12-0.89-0.21-1.56-0.31-2.28c-0.85,0.1-1.51,0.17-2.22,0.25c0.18,0.78,0.31,1.38,0.46,2.03
	C602.69,485.6,603.3,485.6,604.1,485.6z M506.77,556.93c0.14,0.91,0.25,1.57,0.35,2.24c0.81-0.06,1.44-0.1,2.15-0.15
	c-0.17-0.77-0.33-1.44-0.47-2.09C508.11,556.93,507.57,556.93,506.77,556.93z M600.7,555.96c0.11,0.78,0.19,1.44,0.29,2.18
	c0.74-0.1,1.32-0.19,1.97-0.28c-0.08-0.77-0.15-1.37-0.22-2C602.02,555.9,601.42,555.93,600.7,555.96z M608.78,524.96
	c0.81-0.15,1.46-0.28,2.23-0.42c-0.1-0.66-0.19-1.23-0.28-1.88c-0.8,0.08-1.45,0.15-2.12,0.22
	C608.67,523.64,608.72,524.24,608.78,524.96z M533.19,585.72c-0.05-0.62-0.11-1.29-0.17-1.98c-0.8,0-1.49,0-2.28,0
	c0.1,0.77,0.19,1.44,0.29,2.11C531.74,585.8,532.37,585.76,533.19,585.72z M260.13,101.64c-0.98,0.06-1.73,0.11-2.54,0.16
	c0.15,0.75,0.27,1.35,0.4,2.03c0.77-0.04,1.47-0.07,2.3-0.1C260.24,102.99,260.19,102.38,260.13,101.64z M561.1,114.97
	c-0.81,0.18-1.52,0.33-2.13,0.46c0,0.79,0,1.4,0,2.12c0.76-0.12,1.42-0.22,2.13-0.33C561.1,116.47,561.1,115.79,561.1,114.97z
	 M561.2,283.55c-0.13-0.86-0.23-1.52-0.34-2.26c-0.72,0.05-1.31,0.1-1.98,0.15c0.1,0.82,0.18,1.49,0.28,2.24
	C559.82,283.64,560.36,283.6,561.2,283.55z M570.88,377.94c0.09,0.78,0.17,1.45,0.25,2.22c0.68-0.07,1.22-0.13,1.85-0.2
	c-0.07-0.74-0.14-1.42-0.22-2.18C572.1,377.83,571.57,377.87,570.88,377.94z M585.56,481.5c-0.76,0.06-1.4,0.11-2.1,0.16
	c0.11,0.83,0.18,1.42,0.27,2.11c0.69-0.05,1.28-0.09,1.94-0.14C585.64,482.82,585.6,482.21,585.56,481.5z M580.08,525.41
	c0.09,0.79,0.16,1.33,0.23,1.97c0.64-0.05,1.24-0.09,1.85-0.13c0-0.79,0-1.4,0-2.13C581.46,525.21,580.87,525.29,580.08,525.41z
	 M612.71,526.06c0,0.73,0,1.42,0,2.2c0.8-0.12,1.53-0.24,2.31-0.36c-0.06-0.87-0.1-1.54-0.15-2.29
	C614.09,525.78,613.41,525.92,612.71,526.06z M565.93,378.36c-0.74,0.12-1.44,0.24-2.29,0.38c0.14,0.69,0.25,1.25,0.37,1.86
	c0.76-0.03,1.36-0.06,2.06-0.09C566.03,379.78,565.99,379.17,565.93,378.36z M482.3,150.74c0.14,0.72,0.27,1.43,0.41,2.14
	c0.78-0.08,1.39-0.14,2.07-0.21c-0.08-0.78-0.16-1.44-0.22-2.06C483.76,150.66,483.13,150.69,482.3,150.74z M333.89,37.45
	c-0.71,0.05-1.38,0.11-2.16,0.17c0.05,0.8,0.08,1.47,0.12,2.19c0.79-0.06,1.48-0.11,2.27-0.17
	C334.04,38.91,333.97,38.24,333.89,37.45z M642.61,597.71c-0.09-0.83-0.15-1.41-0.22-2.02c-0.78,0.08-1.39,0.14-2.1,0.21
	c0.12,0.73,0.23,1.39,0.34,2.08C641.34,597.88,641.88,597.81,642.61,597.71z M535.97,310.83c0.75-0.1,1.42-0.2,2.19-0.3
	c-0.04-0.71-0.07-1.31-0.1-1.9c-0.83,0-1.51,0-2.37,0C535.79,309.41,535.87,310.08,535.97,310.83z M575.89,202.68
	c-0.06-0.88-0.11-1.61-0.17-2.38c-0.79,0.16-1.46,0.29-2.22,0.44c0.07,0.79,0.13,1.51,0.19,2.23
	C574.48,202.86,575.09,202.78,575.89,202.68z M542.58,593.62c0.05,0.73,0.09,1.39,0.14,2.1c0.86-0.08,1.55-0.14,2.33-0.21
	c-0.11-0.7-0.21-1.29-0.3-1.88C543.99,593.62,543.36,593.62,542.58,593.62z M638.87,554.81c0-0.76,0-1.45,0-2.28
	c-0.81,0.17-1.52,0.32-2.22,0.47c0.04,0.79,0.06,1.4,0.1,2.09C637.47,555,638.15,554.91,638.87,554.81z M640.2,529.37
	c0-0.76,0-1.47,0-2.27c-0.75,0.14-1.43,0.27-2.12,0.4c0.05,0.79,0.09,1.47,0.13,2.23C638.95,529.59,639.57,529.48,640.2,529.37z
	 M389.39,52.7c-0.05-0.87-0.08-1.48-0.12-2.23c-0.77,0.14-1.42,0.26-2.11,0.39c0.11,0.76,0.19,1.35,0.29,2
	C388.12,52.8,388.66,52.76,389.39,52.7z M473.77,50.57c0.05,0.77,0.09,1.44,0.13,2.15c0.82-0.06,1.51-0.12,2.26-0.17
	c-0.06-0.79-0.12-1.49-0.18-2.26C475.27,50.38,474.58,50.47,473.77,50.57z M598.06,332.05c-0.87,0.12-1.55,0.21-2.31,0.32
	c0.18,0.82,0.32,1.49,0.48,2.21c0.7-0.03,1.24-0.05,1.83-0.08C598.06,333.7,598.06,333.01,598.06,332.05z M466.4,132.85
	c0.87-0.15,1.54-0.26,2.26-0.38c-0.1-0.78-0.18-1.39-0.27-2.08c-0.75,0.14-1.42,0.27-2.09,0.4
	C466.33,131.51,466.36,132.06,466.4,132.85z M618.78,529.11c-0.81,0.08-1.55,0.16-2.3,0.23c0.05,0.83,0.08,1.53,0.13,2.31
	c0.76-0.14,1.5-0.27,2.18-0.39C618.78,530.45,618.78,529.83,618.78,529.11z M578.4,149.86c-0.75,0-1.47,0-2.34,0
	c0.07,0.79,0.13,1.53,0.2,2.36c0.79-0.18,1.46-0.33,2.14-0.48C578.4,151.07,578.4,150.53,578.4,149.86z M575.62,350.88
	c0.88-0.06,1.56-0.11,2.3-0.17c-0.16-0.76-0.31-1.42-0.46-2.15c-0.72,0.03-1.31,0.06-1.97,0.09
	C575.54,349.42,575.57,350.03,575.62,350.88z M574.99,383.47c0.82-0.13,1.41-0.22,2.08-0.32c-0.12-0.75-0.22-1.34-0.32-2.01
	c-0.73,0.1-1.32,0.17-1.92,0.25C574.88,382.15,574.93,382.7,574.99,383.47z M580.77,550.58c-0.83,0.04-1.44,0.08-2.16,0.12
	c0.11,0.8,0.21,1.46,0.31,2.21c0.76-0.12,1.35-0.21,1.85-0.29C580.77,551.89,580.77,551.34,580.77,550.58z M494.76,460.43
	c0.15,0.92,0.26,1.58,0.38,2.28c0.85-0.09,1.51-0.16,2.24-0.24c-0.18-0.8-0.32-1.4-0.47-2.04
	C496.23,460.43,495.61,460.43,494.76,460.43z M609.59,532.06c0.79-0.13,1.38-0.23,2.06-0.34c-0.07-0.76-0.13-1.41-0.18-2.04
	c-0.88,0.04-1.57,0.07-2.33,0.1C609.28,530.52,609.42,531.2,609.59,532.06z M569.86,383.83c-0.07-0.9-0.12-1.51-0.16-2.04
	c-0.8,0-1.47,0-2.16,0c0.08,0.77,0.14,1.39,0.21,2.04C568.4,383.83,569.01,383.83,569.86,383.83z M545.25,349.8
	c-0.06-0.69-0.12-1.39-0.19-2.18c-0.89,0.18-1.54,0.32-2.23,0.46c0.09,0.76,0.16,1.36,0.24,1.97
	C543.79,349.97,544.41,349.9,545.25,349.8z M634.52,531.18c0.19,0.83,0.35,1.56,0.52,2.31c0.77-0.12,1.36-0.22,2.03-0.33
	c-0.04-0.72-0.07-1.34-0.1-1.98C636.14,531.18,635.39,531.18,634.52,531.18z M473.77,131.98c0.6-0.04,1.18-0.08,1.88-0.12
	c-0.09-0.82-0.17-1.47-0.25-2.18c-0.7,0.09-1.22,0.15-1.8,0.22C473.66,130.62,473.71,131.24,473.77,131.98z M417.73,48.17
	c-0.77,0.08-1.36,0.14-1.98,0.21c0.04,0.77,0.07,1.38,0.11,2.12c0.85-0.05,1.54-0.09,2.31-0.14
	C418.03,49.65,417.91,49.03,417.73,48.17z M625.56,550.17c0.09,0.8,0.16,1.47,0.25,2.22c0.71-0.06,1.3-0.11,1.94-0.16
	c-0.06-0.81-0.1-1.44-0.16-2.16C626.95,550.1,626.34,550.13,625.56,550.17z M530.06,217.44c-0.08-0.77-0.14-1.38-0.21-2.02
	c-0.75,0.06-1.38,0.11-2.01,0.16c0,0.8,0,1.54,0,2.36C528.6,217.78,529.21,217.64,530.06,217.44z M330.41,23.19
	c0.06,0.84,0.1,1.45,0.15,2.12c0.76-0.12,1.43-0.22,2.11-0.33c0-0.76,0-1.44,0-2.2C331.91,22.92,331.24,23.04,330.41,23.19z
	 M620.5,534.72c0.94-0.09,1.59-0.15,2.28-0.22c-0.11-0.77-0.19-1.38-0.28-2c-0.75,0.06-1.42,0.12-2.23,0.19
	C620.34,533.31,620.41,533.91,620.5,534.72z M574.71,215.05c0.05,0.74,0.1,1.41,0.16,2.2c0.75-0.1,1.4-0.19,2.15-0.28
	c-0.03-0.73-0.06-1.34-0.09-2.02C576.18,214.97,575.5,215.01,574.71,215.05z M485.72,38.44c-0.77,0.19-1.41,0.34-2.04,0.49
	c0.07,0.72,0.12,1.26,0.18,1.89c0.63-0.03,1.23-0.06,1.96-0.09C485.79,39.93,485.76,39.26,485.72,38.44z M602.37,548.56
	c-0.77,0.11-1.38,0.2-2.1,0.31c0.06,0.72,0.11,1.32,0.16,2.03c0.67-0.03,1.27-0.06,1.93-0.08
	C602.37,550.07,602.37,549.45,602.37,548.56z M592.52,394.02c-0.74,0.19-1.37,0.34-2.04,0.51c0.07,0.7,0.12,1.22,0.17,1.74
	c0.75,0,1.35,0,2.02,0C592.63,395.53,592.58,394.86,592.52,394.02z M472.3,49.16c-0.05-0.9-0.1-1.63-0.15-2.41
	c-0.82,0.2-1.49,0.37-2.2,0.54c0.04,0.76,0.07,1.41,0.1,2.07C470.9,49.28,471.51,49.23,472.3,49.16z M614.52,549.94
	c0.88-0.21,1.54-0.37,2.22-0.54c-0.05-0.76-0.08-1.38-0.13-2.05c-0.78,0.12-1.45,0.23-2.09,0.33
	C614.52,548.45,614.52,549.07,614.52,549.94z M392.48,48.81c-0.06-0.73-0.11-1.39-0.16-2.12c-0.78,0.09-1.36,0.17-1.98,0.24
	c0.04,0.8,0.07,1.42,0.11,2.13C391.16,48.97,391.76,48.9,392.48,48.81z M583.3,436.59c0.65,0,1.23,0,1.93,0
	c-0.04-0.76-0.08-1.41-0.12-2.16c-0.86,0.09-1.51,0.15-2.23,0.22C583.04,435.39,583.17,435.98,583.3,436.59z M600.96,534.25
	c-0.9,0.07-1.57,0.13-2.35,0.19c0.2,0.82,0.36,1.47,0.54,2.18c0.74-0.13,1.26-0.23,1.81-0.33
	C600.96,535.63,600.96,535.08,600.96,534.25z M574.79,547.34c0.07,0.81,0.12,1.42,0.18,2.16c0.72-0.08,1.37-0.16,2.06-0.24
	c-0.04-0.79-0.06-1.4-0.09-2.13C576.2,547.2,575.59,547.26,574.79,547.34z M523.12,204.98c0.1,0.8,0.19,1.48,0.29,2.24
	c0.73-0.13,1.39-0.25,2.12-0.38c-0.06-0.71-0.12-1.37-0.18-2.01C524.59,204.89,523.97,204.93,523.12,204.98z M640.92,536.57
	c-0.05-0.81-0.08-1.42-0.12-2.11c-0.76,0.06-1.45,0.11-2.22,0.16c0.05,0.79,0.09,1.51,0.13,2.26
	C639.48,536.77,640.1,536.68,640.92,536.57z M518.37,207.75c-0.13-0.88-0.23-1.53-0.33-2.21c-0.69,0.07-1.21,0.13-1.85,0.2
	c0.07,0.8,0.13,1.45,0.2,2.17C517.09,207.85,517.63,207.81,518.37,207.75z M573.61,387.07c-0.06-0.79-0.11-1.39-0.15-1.91
	c-0.75,0-1.35,0-2.06,0c0.13,0.78,0.24,1.44,0.35,2.11C572.41,387.2,572.88,387.15,573.61,387.07z M311,48.61
	c0.9-0.08,1.57-0.14,2.28-0.2c-0.12-0.79-0.22-1.4-0.32-2.02c-0.78,0.09-1.46,0.17-2.23,0.26C310.83,47.29,310.9,47.83,311,48.61z
	 M512.86,169.78c0.13,0.85,0.24,1.54,0.36,2.31c0.77-0.11,1.5-0.22,2.24-0.33c-0.17-0.82-0.3-1.49-0.43-2.14
	C514.28,169.68,513.66,169.72,512.86,169.78z M618.84,307.21c0.78-0.05,1.51-0.09,2.34-0.14c-0.14-0.76-0.24-1.35-0.35-1.97
	c-0.87,0.09-1.54,0.15-2.25,0.23C618.67,306.01,618.74,306.55,618.84,307.21z M511.17,206.08c-0.79,0.19-1.45,0.35-2.16,0.52
	c0.11,0.7,0.2,1.29,0.29,1.89c0.86-0.07,1.55-0.13,2.21-0.18C511.4,207.56,511.3,206.93,511.17,206.08z M592.23,436.17
	c0-0.73,0-1.46,0-2.04c-0.81,0-1.47,0-2.17,0c0.12,0.81,0.21,1.46,0.31,2.16C591.06,436.24,591.6,436.2,592.23,436.17z
	 M377.68,40.57c-0.89,0.15-1.56,0.27-2.28,0.4c0.05,0.77,0.09,1.39,0.14,2.09c0.79-0.09,1.46-0.17,2.14-0.25
	C377.68,42.05,377.68,41.43,377.68,40.57z M462.02,40.65c0.08,0.76,0.15,1.42,0.22,2.17c0.72-0.04,1.32-0.08,2.05-0.12
	c-0.08-0.83-0.15-1.49-0.21-2.18C463.32,40.57,462.72,40.61,462.02,40.65z M523.74,212.21c0.14,0.81,0.26,1.47,0.39,2.21
	c0.72-0.09,1.33-0.17,2.07-0.27c-0.04-0.7-0.08-1.31-0.12-2.05C525.28,212.14,524.6,212.17,523.74,212.21z M570.3,539.15
	c0.94,0,1.68,0,2.5,0c-0.2-0.87-0.36-1.6-0.53-2.36c-0.73,0.11-1.33,0.2-1.97,0.29C570.3,537.79,570.3,538.41,570.3,539.15z
	 M592.91,343.36c0.23,0.92,0.39,1.58,0.56,2.27c0.73-0.05,1.35-0.1,2.02-0.15c-0.1-0.78-0.18-1.44-0.28-2.24
	C594.48,343.28,593.8,343.32,592.91,343.36z M658.09,608.77c-0.78,0.07-1.46,0.14-2.22,0.21c0.04,0.77,0.08,1.5,0.12,2.3
	c0.76-0.12,1.43-0.23,2.22-0.35C658.17,610.17,658.13,609.5,658.09,608.77z M642.54,540.06c0.86-0.12,1.54-0.21,2.26-0.3
	c-0.07-0.74-0.13-1.34-0.19-1.96c-0.78,0.07-1.46,0.12-2.21,0.19C642.44,538.64,642.49,539.24,642.54,540.06z M558.86,465.78
	c-2.03,0.05-2.03,0.05-1.63,1.97c0.6,0,1.2,0,1.84,0C558.99,467.01,558.93,466.39,558.86,465.78z M539.86,393.41
	c0.71-0.14,1.29-0.26,1.87-0.38c0-0.76,0-1.37,0-1.96c-0.91,0-1.73,0-2.58,0C539.39,391.84,539.59,392.51,539.86,393.41z
	 M588.22,470.39c-0.73,0.08-1.39,0.15-2.11,0.23c0.07,0.79,0.12,1.4,0.18,2.03c0.75,0,1.38,0,2.09,0
	C588.33,471.91,588.28,471.21,588.22,470.39z M580.2,545.47c-0.12-0.87-0.2-1.46-0.3-2.12c-0.73,0.1-1.32,0.18-1.95,0.27
	c0.05,0.79,0.09,1.41,0.13,2.1C578.8,545.63,579.41,545.56,580.2,545.47z M574.16,210.31c0.87-0.26,1.52-0.45,2.15-0.64
	c-0.07-0.77-0.12-1.38-0.18-2.02c-0.78,0.07-1.46,0.14-2.14,0.2C574.04,208.66,574.09,209.36,574.16,210.31z M599.57,541.58
	c0.05,0.82,0.09,1.43,0.12,2.05c0.76,0,1.38,0,2.11,0c-0.08-0.75-0.15-1.44-0.23-2.2C600.91,541.48,600.36,541.52,599.57,541.58z
	 M482.54,44.59c-0.09-0.85-0.15-1.46-0.22-2.16c-0.8,0.07-1.45,0.13-2.21,0.21c0.19,0.77,0.33,1.37,0.47,1.95
	C481.2,44.59,481.73,44.59,482.54,44.59z M562.95,471.1c-0.1-0.85-0.18-1.45-0.26-2.12c-0.72,0.08-1.31,0.14-1.87,0.2
	c0,0.81,0,1.42,0,2.14C561.54,471.24,562.15,471.18,562.95,471.1z M600.64,471.5c0.91-0.09,1.58-0.16,2.35-0.24
	c-0.19-0.77-0.34-1.36-0.49-2c-0.73,0.08-1.33,0.14-1.97,0.2C600.57,470.19,600.6,470.73,600.64,471.5z M551.59,339.79
	c-0.81,0.06-1.43,0.1-2.15,0.16c0.11,0.85,0.21,1.59,0.31,2.37c0.77-0.05,1.39-0.09,2.09-0.14
	C551.76,341.38,551.69,340.7,551.59,339.79z M393.78,45.16c0.89-0.06,1.55-0.11,2.31-0.16c-0.18-0.75-0.32-1.35-0.48-2.04
	c-0.65,0.07-1.24,0.13-1.83,0.18C393.78,43.81,393.78,44.35,393.78,45.16z M473.12,43.32c0,0.85,0,1.52,0,2.32
	c0.75-0.16,1.41-0.31,2.19-0.48c-0.04-0.65-0.07-1.25-0.1-1.94C474.48,43.25,473.88,43.28,473.12,43.32z M575.03,275.17
	c-0.19-0.86-0.35-1.54-0.51-2.25c-0.7,0.11-1.31,0.2-1.89,0.29c0,0.77,0,1.46,0,2.19C573.43,275.32,574.13,275.25,575.03,275.17z
	 M381.53,43.94c-0.91,0.17-1.56,0.3-2.26,0.43c0.07,0.76,0.12,1.37,0.18,2.02c0.77-0.1,1.44-0.18,2.08-0.26
	C381.53,45.37,381.53,44.75,381.53,43.94z M630.59,541.28c-0.19-0.88-0.34-1.57-0.5-2.28c-0.63,0.04-1.17,0.07-1.84,0.11
	c0.05,0.81,0.09,1.49,0.14,2.28C629.15,541.35,629.77,541.32,630.59,541.28z M498.41,545.64c0.84-0.16,1.51-0.3,2.32-0.46
	c-0.15-0.69-0.27-1.27-0.38-1.79c-0.74,0-1.33,0-1.94,0C498.41,544.13,498.41,544.75,498.41,545.64z M536.47,315.98
	c0.13,0.71,0.26,1.37,0.38,2.03c0.73-0.1,1.34-0.18,2.05-0.27c-0.05-0.77-0.09-1.48-0.14-2.21
	C537.92,315.69,537.24,315.83,536.47,315.98z M356.72,9.69c-0.77,0.05-1.39,0.09-2.09,0.13c0.13,0.89,0.25,1.71,0.39,2.61
	c0.63-0.13,1.14-0.24,1.7-0.36C356.72,11.32,356.72,10.63,356.72,9.69z M649.4,590.26c-0.23-0.92-0.41-1.65-0.6-2.38
	c-0.69,0.06-1.22,0.1-1.83,0.16c0.06,0.83,0.11,1.51,0.17,2.22C647.89,590.26,648.5,590.26,649.4,590.26z M591.89,468.81
	c-0.19-0.88-0.33-1.54-0.5-2.31c-0.74,0.17-1.31,0.31-1.95,0.46c0.09,0.73,0.16,1.32,0.22,1.85
	C590.43,468.81,591.04,468.81,591.89,468.81z M550.66,538.72c-0.7,0-1.38,0-2.15,0c0.17,0.78,0.32,1.44,0.47,2.11
	c0.7-0.04,1.24-0.07,1.89-0.11C550.79,540,550.73,539.39,550.66,538.72z M454.74,41.4c0.09,0.75,0.16,1.39,0.24,2.06
	c0.78-0.12,1.39-0.22,2.11-0.33c-0.08-0.65-0.16-1.23-0.23-1.82C456.12,41.33,455.51,41.36,454.74,41.4z M591.81,386.89
	c-0.88,0.08-1.54,0.14-2.27,0.21c0.2,0.79,0.35,1.38,0.53,2.06c0.6-0.03,1.13-0.06,1.74-0.09
	C591.81,388.34,591.81,387.75,591.81,386.89z M334.64,46.62c-0.09-0.73-0.17-1.41-0.27-2.2c-0.73,0.12-1.33,0.22-1.94,0.32
	c0,0.78,0,1.48,0,2.27C333.2,46.87,333.88,46.75,334.64,46.62z M637.45,538.28c-0.86,0.1-1.54,0.18-2.19,0.25c0,0.8,0,1.49,0,2.34
	c0.81-0.2,1.53-0.37,2.19-0.53C637.45,539.62,637.45,539.08,637.45,538.28z M600.33,274.12c-0.77,0.18-1.45,0.34-2.24,0.53
	c0.08,0.67,0.15,1.25,0.22,1.92c0.82-0.07,1.5-0.12,2.24-0.18C600.47,275.56,600.41,274.88,600.33,274.12z M501.85,207.03
	c0.06,0.7,0.11,1.36,0.17,2.05c0.73-0.09,1.34-0.16,2-0.24c0-0.73,0-1.41,0-2.17C503.26,206.8,502.59,206.91,501.85,207.03z
	 M338.1,29.56c0.18,0.87,0.31,1.52,0.46,2.25c0.74-0.13,1.26-0.22,1.76-0.3c0-0.71,0-1.31,0-1.94
	C339.59,29.56,338.98,29.56,338.1,29.56z M603.51,547.2c0.85-0.13,1.5-0.24,2.25-0.36c-0.18-0.77-0.32-1.36-0.47-2.02
	c-0.65,0.04-1.18,0.08-1.78,0.11C603.51,545.68,603.51,546.28,603.51,547.2z M461.15,46.55c-0.26-0.85-0.35-1.6-0.73-2.18
	c-0.12-0.18-0.97,0.12-1.4,0.19c0,0.78,0,1.39,0,2.17C459.72,46.67,460.31,46.62,461.15,46.55z M499.68,536.03
	c-0.72,0.03-1.37,0.06-1.95,0.09c0,0.88,0,1.57,0,2.37c0.81-0.22,1.53-0.42,2.26-0.63C499.88,537.19,499.79,536.66,499.68,536.03z
	 M583.91,129.66c0-0.84,0-1.52,0-2.29c-0.84,0.16-1.59,0.31-2.37,0.46c0.04,0.74,0.08,1.41,0.12,2.17
	C582.44,129.88,583.13,129.78,583.91,129.66z M564.57,387.81c0.7-0.17,1.26-0.31,1.76-0.44c0-0.67,0-1.19,0-1.88
	c-0.77,0.04-1.44,0.07-2.21,0.11C564.27,386.34,564.41,386.99,564.57,387.81z M633.69,537.04c-0.09-0.67-0.16-1.24-0.25-1.9
	c-0.8,0.09-1.45,0.17-2.19,0.25c0.15,0.75,0.26,1.32,0.38,1.94C632.37,537.23,632.97,537.14,633.69,537.04z M578.96,307.29
	c0.8-0.13,1.35-0.22,2-0.33c-0.03-0.7-0.06-1.3-0.09-2.02c-0.72,0.04-1.33,0.07-1.91,0.1C578.96,305.82,578.96,306.44,578.96,307.29
	z M502.42,548.66c0.74-0.11,1.33-0.2,2.03-0.31c-0.1-0.66-0.17-1.17-0.25-1.72c-0.71,0-1.3,0-1.96,0
	C502.3,547.35,502.35,547.95,502.42,548.66z M546.1,275.34c-0.86,0.14-1.53,0.25-2.2,0.36c0.04,0.71,0.07,1.26,0.1,1.92
	c0.8-0.05,1.47-0.1,2.2-0.15C546.17,276.74,546.15,276.19,546.1,275.34z M409.3,16.26c0.11,0.69,0.22,1.36,0.34,2.14
	c0.76-0.08,1.42-0.15,2.12-0.23c-0.15-0.81-0.27-1.47-0.39-2.13C410.61,16.13,410,16.19,409.3,16.26z M564.94,474.65
	c0.69-0.06,1.33-0.12,2.05-0.18c-0.18-0.84-0.31-1.49-0.46-2.18c-0.71,0.11-1.22,0.2-1.77,0.28
	C564.83,473.31,564.88,473.91,564.94,474.65z M577.83,269.1c-0.76,0-1.43,0-2.22,0c0.14,0.76,0.27,1.41,0.41,2.17
	c0.73-0.19,1.3-0.33,1.91-0.48C577.89,270.13,577.86,269.6,577.83,269.1z M545.49,584.3c0.86,0,1.45,0,2.1,0
	c-0.13-0.76-0.23-1.36-0.34-2.01c-0.71,0.1-1.31,0.18-1.97,0.26C545.37,583.22,545.43,583.77,545.49,584.3z M432.53,48.91
	c-0.08-0.75-0.15-1.41-0.22-2.1c-0.77,0.12-1.38,0.22-2.12,0.33c0.07,0.64,0.14,1.22,0.2,1.77
	C431.16,48.91,431.78,48.91,432.53,48.91z M581.9,214.48c0.17,0.81,0.29,1.33,0.4,1.88c0.64,0,1.23,0,1.87,0c0-0.76,0-1.43,0-2.22
	C583.4,214.26,582.74,214.36,581.9,214.48z M553.23,549.23c0.09,0.72,0.17,1.39,0.26,2.12c0.69-0.1,1.23-0.18,1.86-0.27
	c-0.05-0.69-0.09-1.28-0.14-1.85C554.47,549.23,553.92,549.23,553.23,549.23z M615.37,535.17c0-0.71,0-1.39,0-2.16
	c-0.78,0.08-1.45,0.15-2.17,0.22c0,0.74,0,1.43,0,2.2C613.93,535.34,614.62,535.26,615.37,535.17z M507.31,204.94
	c-0.08-0.72-0.14-1.31-0.21-1.99c-0.74,0.08-1.34,0.14-1.95,0.2c0,0.77,0,1.46,0,2.2C505.85,205.22,506.46,205.1,507.31,204.94z
	 M582.62,532.42c-0.73,0.07-1.38,0.13-2.06,0.19c0.1,0.78,0.17,1.38,0.26,2.09c0.72-0.15,1.31-0.27,1.97-0.4
	C582.74,533.63,582.69,533.09,582.62,532.42z M568.76,477.76c0.69-0.09,1.34-0.17,2.06-0.26c-0.06-0.83-0.11-1.51-0.16-2.22
	c-0.8,0.16-1.48,0.3-2.24,0.45C568.54,476.4,568.64,477.02,568.76,477.76z M495.47,48.64c0,0.71,0,1.41,0,2.22
	c0.73-0.08,1.41-0.15,2.13-0.23c-0.11-0.9-0.21-1.65-0.3-2.41C496.61,48.38,496.07,48.5,495.47,48.64z M590,347.65
	c0.07,0.75,0.11,1.28,0.17,1.91c0.84-0.07,1.5-0.13,2.25-0.19c-0.17-0.78-0.29-1.37-0.43-2.01
	C591.29,347.46,590.75,347.54,590,347.65z M561.65,478.47c0.79-0.07,1.37-0.13,1.98-0.19c-0.04-0.76-0.07-1.37-0.11-2.12
	c-0.76,0.11-1.34,0.19-1.99,0.28C561.58,477.11,561.61,477.66,561.65,478.47z M553.16,591.32c0.86-0.14,1.44-0.24,2.14-0.35
	c-0.16-0.71-0.29-1.29-0.44-1.96c-0.63,0.06-1.14,0.11-1.7,0.16C553.16,589.9,553.16,590.49,553.16,591.32z M627.12,584.82
	c-0.15-0.77-0.29-1.43-0.42-2.1c-0.69,0.05-1.23,0.09-1.87,0.14c0.05,0.8,0.09,1.46,0.14,2.21
	C625.8,584.97,626.41,584.9,627.12,584.82z M570.64,435.89c-0.76,0.06-1.29,0.11-1.94,0.16c0.08,0.81,0.14,1.47,0.22,2.22
	c0.77-0.15,1.35-0.26,1.97-0.37C570.8,437.2,570.73,436.65,570.64,435.89z M624.57,595.88c-0.27-0.86-0.47-1.48-0.69-2.15
	c-0.68,0.15-1.17,0.27-1.7,0.39c0.03,0.66,0.06,1.19,0.09,1.77C623.03,595.88,623.63,595.88,624.57,595.88z M618.42,551.06
	c0.1,0.64,0.18,1.22,0.25,1.71c0.83,0,1.43,0,2.15,0c-0.07-0.78-0.14-1.45-0.21-2.2C619.81,550.75,619.14,550.9,618.42,551.06z
	 M574.23,267.79c-0.16-0.76-0.27-1.28-0.39-1.87c-0.7,0.04-1.31,0.07-2.02,0.11c0.07,0.8,0.13,1.45,0.2,2.14
	C572.79,268.04,573.4,267.93,574.23,267.79z M451.06,40.27c0.75-0.11,1.42-0.21,2.19-0.32c-0.04-0.7-0.07-1.3-0.11-2.03
	c-0.78,0.06-1.45,0.1-2.08,0.15C451.06,38.85,451.06,39.52,451.06,40.27z M573.58,551.21c-0.72,0-1.44,0-2.25,0
	c0.16,0.7,0.31,1.35,0.47,2.07c0.7-0.07,1.21-0.12,1.78-0.17C573.58,552.44,573.58,551.91,573.58,551.21z M541.54,266.82
	c-0.1-0.67-0.17-1.18-0.23-1.63c-0.77,0-1.37,0-2.1,0c0.06,0.7,0.11,1.3,0.16,2C540.07,267.07,540.67,266.97,541.54,266.82z
	 M551.61,529.87c0.65-0.13,1.17-0.24,1.81-0.37c-0.08-0.68-0.15-1.27-0.22-1.91c-0.75,0.07-1.35,0.13-2.05,0.19
	C551.32,528.55,551.45,529.14,551.61,529.87z M493.03,480.8c0.73-0.04,1.41-0.07,2.17-0.11c-0.14-0.78-0.25-1.45-0.37-2.13
	c-0.68,0.06-1.21,0.11-1.8,0.16C493.03,479.44,493.03,480.05,493.03,480.8z M458.5,39.44c0.76-0.07,1.28-0.12,1.78-0.17
	c0-0.74,0-1.34,0-2.09c-0.79,0.05-1.46,0.09-2.22,0.13C458.21,38.04,458.34,38.65,458.5,39.44z M598.99,552.51
	c-0.87,0.04-1.49,0.07-2.24,0.11c0.16,0.75,0.3,1.4,0.46,2.13c0.65-0.04,1.17-0.07,1.78-0.11
	C598.99,553.9,598.99,553.31,598.99,552.51z M598.63,529.34c0.75-0.07,1.28-0.12,1.78-0.17c0-0.74,0-1.34,0-2.1
	c-0.79,0.06-1.46,0.1-2.22,0.16C598.35,527.95,598.47,528.56,598.63,529.34z M603.34,460.29c0.8-0.05,1.53-0.09,2.28-0.13
	c-0.14-0.79-0.25-1.41-0.36-2.05c-0.73,0.07-1.41,0.14-2.22,0.21C603.13,458.99,603.23,459.58,603.34,460.29z M591.14,379.65
	c-0.85,0.16-1.41,0.26-2.04,0.38c0.17,0.76,0.31,1.34,0.46,2.01c0.62-0.07,1.13-0.13,1.72-0.19
	C591.23,381.1,591.19,380.5,591.14,379.65z M379.95,51.55c0.08,0.82,0.15,1.42,0.22,2.1c0.8-0.14,1.46-0.25,2.14-0.37
	c-0.09-0.8-0.16-1.41-0.23-2.05C381.33,51.33,380.71,51.43,379.95,51.55z M575.55,556.78c0.77-0.18,1.34-0.3,1.99-0.45
	c-0.1-0.73-0.18-1.31-0.27-1.98c-0.74,0.13-1.32,0.24-1.9,0.34C575.43,555.39,575.47,555.93,575.55,556.78z M617.16,554.63
	c-0.72,0.1-1.38,0.19-2.05,0.28c0.09,0.75,0.16,1.36,0.23,1.93c0.78,0,1.46,0,2.24,0C617.44,556.09,617.32,555.42,617.16,554.63z
	 M598.84,574.29c0.1,0.81,0.17,1.34,0.25,1.99c0.64-0.09,1.22-0.17,1.88-0.27c-0.07-0.74-0.12-1.33-0.18-1.99
	C600.12,574.12,599.59,574.19,598.84,574.29z M497.06,169.85c-0.11-0.91-0.19-1.59-0.27-2.28c-0.7,0.06-1.24,0.1-1.73,0.15
	c0,0.83,0,1.51,0,2.27C495.75,169.94,496.29,169.9,497.06,169.85z M582.81,223.75c0.79-0.16,1.32-0.27,1.93-0.4
	c-0.06-0.69-0.11-1.29-0.17-1.96c-0.82,0.07-1.48,0.13-2.24,0.19C582.5,222.35,582.63,222.95,582.81,223.75z M575.64,142.95
	c0.11,0.66,0.2,1.23,0.3,1.86c0.75-0.11,1.34-0.2,2.06-0.31c-0.1-0.68-0.19-1.24-0.28-1.86
	C576.96,142.75,576.36,142.85,575.64,142.95z M382.15,13.53c0-0.66,0-1.34,0-2.1c-0.78,0.08-1.45,0.15-2.15,0.22
	c0.04,0.82,0.07,1.54,0.1,2.32C380.86,13.81,381.46,13.68,382.15,13.53z M575.3,222.3c0,0.77,0,1.45,0,2.24
	c0.74-0.15,1.42-0.28,2.21-0.44c-0.05-0.68-0.1-1.29-0.15-1.93C576.62,222.21,575.99,222.25,575.3,222.3z M564.82,200.14
	c-0.34-2.45-0.34-2.45-2.46-2.09c0.12,0.74,0.24,1.48,0.36,2.24C563.47,200.24,564.08,200.19,564.82,200.14z M591.14,401.58
	c0,0.79,0,1.39,0,2.08c0.73-0.06,1.33-0.12,2.05-0.18c-0.07-0.75-0.13-1.42-0.2-2.14C592.3,401.43,591.76,401.5,591.14,401.58z
	 M346.33,38.44c0.86-0.04,1.48-0.08,2.19-0.11c-0.14-0.76-0.25-1.42-0.39-2.22c-0.7,0.14-1.27,0.26-1.79,0.37
	C346.33,37.16,346.33,37.69,346.33,38.44z M571.18,482.63c-0.85,0.1-1.47,0.17-2.16,0.25c0.05,0.8,0.1,1.53,0.15,2.34
	c0.83-0.24,1.48-0.43,2.02-0.58C571.18,483.9,571.18,483.35,571.18,482.63z M311.39,53.55c0.18,0.82,0.34,1.54,0.51,2.28
	c0.71-0.08,1.25-0.14,1.82-0.2c-0.08-0.81-0.14-1.49-0.21-2.23C312.72,53.45,312.1,53.5,311.39,53.55z M635.53,556.49
	c-0.84,0.11-1.52,0.2-2.23,0.3c0.07,0.79,0.12,1.46,0.18,2.16c0.75-0.1,1.37-0.19,2.04-0.28C635.53,558,635.53,557.38,635.53,556.49
	z M552.62,103.68c-0.05-0.86-0.1-1.52-0.15-2.3c-0.82,0.08-1.46,0.15-2.12,0.22c0.12,0.84,0.22,1.51,0.32,2.24
	C551.31,103.79,551.84,103.74,552.62,103.68z M578.98,198.64c-0.12-0.75-0.21-1.27-0.3-1.85c-0.73,0.06-1.34,0.11-2.03,0.17
	c0.05,0.81,0.1,1.47,0.14,2.18C577.56,198.97,578.17,198.83,578.98,198.64z M562.17,483.54c0,0.82,0,1.42,0,2.17
	c0.73-0.08,1.34-0.14,2.06-0.22c-0.14-0.77-0.26-1.43-0.38-2.11C563.23,483.44,562.76,483.49,562.17,483.54z M499.3,196.46
	c-0.74,0.12-1.32,0.21-1.98,0.31c0.11,0.75,0.19,1.34,0.26,1.86c0.78,0,1.4,0,2.13,0C499.58,197.94,499.47,197.34,499.3,196.46z
	 M304.43,54.42c0.06,0.77,0.11,1.39,0.16,2.06c0.77-0.11,1.45-0.2,2.13-0.29c-0.04-0.82-0.07-1.49-0.1-2.23
	C305.88,54.12,305.27,54.25,304.43,54.42z M571.3,524.59c-0.04-0.76-0.08-1.49-0.12-2.3c-0.83,0.19-1.49,0.35-2.16,0.5
	c0.06,0.77,0.11,1.42,0.16,2.1C569.95,524.78,570.56,524.69,571.3,524.59z M425.83,14.81c-0.9,0.09-1.58,0.16-2.33,0.24
	c0.13,0.83,0.25,1.54,0.37,2.31c0.76-0.18,1.35-0.32,2.05-0.49C425.9,16.22,425.87,15.62,425.83,14.81z M576.39,524.09
	c0.73-0.09,1.26-0.16,1.83-0.24c-0.06-0.73-0.11-1.35-0.18-2.11c-0.66,0.05-1.28,0.1-1.95,0.15
	C576.18,522.61,576.27,523.24,576.39,524.09z M504.54,195.99c0.04,0.78,0.07,1.32,0.1,1.98c0.78-0.07,1.45-0.12,2.11-0.18
	c0-0.8,0-1.48,0-2.25C506.01,195.69,505.35,195.82,504.54,195.99z M559.24,454.71c0.27,0.9,0.46,1.53,0.66,2.2
	c0.69-0.11,1.21-0.2,1.79-0.29c-0.07-0.73-0.13-1.33-0.19-1.91C560.75,454.71,560.14,454.71,559.24,454.71z M583.25,521.46
	c0.07,0.84,0.12,1.45,0.18,2.13c0.73-0.09,1.34-0.17,2.09-0.27c-0.15-0.71-0.29-1.35-0.43-2
	C584.42,521.38,583.97,521.41,583.25,521.46z M565.14,521.61c0.85-0.2,1.5-0.36,2.15-0.51c-0.06-0.75-0.11-1.35-0.16-2.02
	c-0.77,0.11-1.43,0.2-2.16,0.3C565.02,520.14,565.07,520.75,565.14,521.61z M616.9,286c0.78-0.16,1.47-0.3,2.2-0.46
	c-0.04-0.67-0.08-1.28-0.13-2.01c-0.74,0.08-1.42,0.15-2.22,0.24C616.81,284.57,616.85,285.25,616.9,286z M374.57,33.72
	c0.11,0.87,0.19,1.55,0.29,2.36c0.74-0.17,1.4-0.32,2.11-0.48c-0.07-0.76-0.13-1.36-0.19-1.99
	C376.03,33.64,375.41,33.68,374.57,33.72z M494.28,453.22c0.11,0.89,0.2,1.57,0.29,2.29c0.77-0.07,1.39-0.12,2.05-0.18
	c-0.14-0.76-0.26-1.43-0.38-2.11C495.6,453.22,495.07,453.22,494.28,453.22z M337.1,33.51c-0.65,0.12-1.29,0.24-1.96,0.36
	c0.07,0.78,0.12,1.36,0.18,1.98c0.83-0.09,1.5-0.16,2.21-0.23C337.37,34.87,337.25,34.27,337.1,33.51z M426.29,85.48
	c0.71-0.04,1.38-0.07,2.1-0.11c-0.09-0.76-0.17-1.37-0.24-2.01c-0.79,0.1-1.46,0.19-2.2,0.28
	C426.08,84.31,426.17,84.84,426.29,85.48z M471.08,143.15c0.71-0.09,1.22-0.15,1.84-0.23c-0.08-0.79-0.14-1.44-0.21-2.15
	c-0.76,0.08-1.36,0.15-1.98,0.21C470.84,141.7,470.94,142.31,471.08,143.15z M601.64,565.25c0.76-0.04,1.41-0.08,2.15-0.13
	c-0.17-0.79-0.31-1.43-0.47-2.16c-0.71,0.18-1.21,0.31-1.67,0.43C601.64,564.04,601.64,564.57,601.64,565.25z M594.44,565.59
	c0.65,0,1.29,0,2,0c-0.08-0.76-0.15-1.34-0.22-2.02c-0.76,0.07-1.34,0.13-1.99,0.19C594.3,564.43,594.36,564.97,594.44,565.59z
	 M554.55,357.77c0.09,0.69,0.16,1.29,0.25,1.98c0.77-0.11,1.42-0.2,2.16-0.31c-0.12-0.72-0.21-1.23-0.28-1.68
	C555.91,357.77,555.29,357.77,554.55,357.77z M472,31.02c0.83-0.1,1.5-0.18,2.19-0.26c-0.05-0.79-0.09-1.44-0.14-2.17
	c-0.8,0.15-1.46,0.28-2.15,0.41C471.94,29.67,471.96,30.2,472,31.02z M593.29,443.33c-0.2-0.88-0.34-1.49-0.5-2.22
	c-0.65,0.11-1.23,0.21-1.9,0.32c0.08,0.75,0.14,1.35,0.2,1.9C591.86,443.33,592.48,443.33,593.29,443.33z M539.86,312.06
	c0,0.79,0,1.47,0,2.25c0.77-0.18,1.44-0.33,2.17-0.5c-0.08-0.71-0.15-1.3-0.22-1.91C541.05,311.96,540.43,312.01,539.86,312.06z
	 M579.17,325.24c-0.1-0.87-0.17-1.46-0.25-2.11c-0.78,0.07-1.38,0.12-2,0.17c0.05,0.75,0.08,1.36,0.13,2.11
	C577.78,325.35,578.38,325.31,579.17,325.24z M576.55,296.51c-0.07-0.86-0.12-1.47-0.18-2.21c-0.78,0.07-1.43,0.12-2.14,0.19
	c0.22,0.84,0.39,1.5,0.57,2.19C575.39,296.63,575.84,296.58,576.55,296.51z M483.17,160.2c0.86-0.12,1.49-0.21,2.15-0.31
	c0-0.75,0-1.45,0-2.21c-0.78,0.15-1.46,0.28-2.15,0.42C483.17,158.79,483.17,159.43,483.17,160.2z M503.48,452.62
	c-0.77,0-1.39,0-2.08,0c0.04,0.77,0.07,1.45,0.1,2.24c0.76-0.18,1.42-0.33,2.08-0.49C503.54,453.66,503.51,453.12,503.48,452.62z
	 M595.47,179.43c-0.17-0.82-0.32-1.53-0.47-2.26c-0.73,0.08-1.25,0.14-1.81,0.21c0.07,0.81,0.13,1.48,0.2,2.23
	C594.16,179.54,594.77,179.49,595.47,179.43z M582.9,516.35c0.79-0.04,1.47-0.07,2.26-0.11c-0.17-0.77-0.32-1.43-0.48-2.14
	c-0.71,0.06-1.24,0.11-1.77,0.16C582.9,514.99,582.9,515.59,582.9,516.35z M574.84,567.55c-0.09-0.87-0.15-1.45-0.21-2.12
	c-0.78,0.08-1.36,0.15-2.02,0.22c0.1,0.82,0.18,1.48,0.27,2.17C573.58,567.72,574.11,567.65,574.84,567.55z M498.16,514.19
	c-0.84,0.19-1.49,0.34-2.16,0.49c0.06,0.77,0.11,1.38,0.16,2.02c0.73-0.13,1.34-0.23,2-0.35
	C498.16,515.68,498.16,515.07,498.16,514.19z M376.42,26.09c-0.82,0.12-1.55,0.23-2.41,0.36c0.19,0.74,0.36,1.39,0.54,2.09
	c0.69-0.06,1.28-0.12,1.87-0.17C376.42,27.55,376.42,26.87,376.42,26.09z M582.63,155.13c-0.08-0.84-0.14-1.51-0.2-2.24
	c-0.78,0.18-1.44,0.33-2.12,0.49c0.05,0.74,0.09,1.34,0.13,1.98C581.21,155.28,581.83,155.21,582.63,155.13z M391.34,16.49
	c0.79-0.06,1.32-0.1,2.01-0.16c-0.09-0.85-0.16-1.58-0.24-2.31c-0.72,0.05-1.25,0.08-1.9,0.13
	C391.25,14.94,391.29,15.62,391.34,16.49z M584.13,422.46c-0.09-0.72-0.17-1.37-0.26-2.1c-0.75,0.04-1.35,0.08-2.07,0.12
	c0.05,0.76,0.09,1.36,0.14,1.98C582.69,422.46,583.31,422.46,584.13,422.46z M578.38,189.42c-0.78,0.13-1.44,0.25-2.21,0.38
	c0.06,0.66,0.12,1.25,0.18,1.91c0.79-0.09,1.45-0.16,2.15-0.24C578.46,190.69,578.42,190.09,578.38,189.42z M481.11,136.49
	c0.15,0.77,0.27,1.42,0.39,2.02c0.83,0,1.53,0,2.28,0c-0.13-0.68-0.25-1.35-0.4-2.13C482.65,136.42,481.97,136.45,481.11,136.49z
	 M647.17,608.34c-0.08-0.78-0.14-1.38-0.21-2.11c-0.73,0.06-1.32,0.12-1.99,0.17c0.08,0.71,0.15,1.3,0.22,1.94
	C645.84,608.34,646.37,608.34,647.17,608.34z M567.02,600.85c-0.17-0.78-0.28-1.29-0.41-1.89c-0.68,0.05-1.27,0.09-1.95,0.14
	c0.07,0.79,0.13,1.43,0.19,2.12C565.61,601.09,566.21,600.99,567.02,600.85z M600.39,613.78c-0.68,0.34-1.38,0.48-1.7,0.92
	c-0.23,0.31,0.06,1,0.12,1.58c0.8-0.08,1.45-0.15,2.2-0.23C600.8,615.26,600.63,614.67,600.39,613.78z M470.39,27.46
	c-0.08-0.66-0.16-1.24-0.24-1.9c-0.79,0.06-1.44,0.1-2.18,0.15c0.11,0.72,0.21,1.3,0.3,1.91C469.06,27.57,469.67,27.52,470.39,27.46
	z M499.67,449.4c-0.88,0-1.49,0-2.22,0c0.11,0.68,0.21,1.26,0.31,1.91c0.69-0.06,1.3-0.11,1.99-0.17
	C499.72,450.54,499.7,450.03,499.67,449.4z M609.72,616.71c0,0.9,0,1.58,0,2.36c0.84-0.14,1.57-0.27,2.36-0.4
	c-0.19-0.75-0.34-1.35-0.49-1.96C610.95,616.71,610.41,616.71,609.72,616.71z M398.48,31.73c-0.83,0.04-1.52,0.08-2.34,0.12
	c0.12,0.75,0.22,1.35,0.33,2.02c0.8-0.05,1.48-0.1,2.23-0.14C398.63,33.09,398.57,32.54,398.48,31.73z M523.36,413.17
	c0.82-0.05,1.43-0.09,2.12-0.14c-0.12-0.73-0.23-1.38-0.35-2.14c-0.7,0.15-1.21,0.26-1.77,0.38
	C523.36,411.87,523.36,412.39,523.36,413.17z M279.89,80.32c-0.1-0.74-0.2-1.4-0.3-2.12c-0.73,0.07-1.33,0.13-2.01,0.2
	c0.04,0.77,0.07,1.39,0.1,2.08C278.42,80.43,279.11,80.38,279.89,80.32z M569.46,569.44c0.2,0.81,0.34,1.38,0.5,2.03
	c0.65-0.08,1.17-0.14,1.67-0.2c0-0.75,0-1.35,0-2.07C570.92,569.28,570.32,569.35,569.46,569.44z M593.34,448.41
	c-0.65,0.07-1.24,0.14-1.91,0.21c0.06,0.76,0.11,1.37,0.17,2.05c0.74-0.08,1.36-0.15,2.08-0.24
	C593.57,449.75,593.46,449.13,593.34,448.41z M651.82,618.73c-0.1-0.77-0.19-1.43-0.28-2.12c-0.78,0.06-1.39,0.11-2.15,0.17
	c0.08,0.72,0.15,1.33,0.21,1.95C650.33,618.73,651.02,618.73,651.82,618.73z M600.6,511.16c0.76-0.16,1.28-0.27,1.87-0.39
	c-0.05-0.68-0.1-1.28-0.15-1.95c-0.8,0.08-1.45,0.14-2.17,0.2C600.31,509.77,600.44,510.37,600.6,511.16z M287.15,79.67
	c-0.1-0.79-0.18-1.44-0.27-2.21c-0.81,0.23-1.46,0.41-2.17,0.61c0.13,0.67,0.24,1.18,0.35,1.73
	C285.81,79.75,286.43,79.71,287.15,79.67z M563.11,510.95c-0.12-0.77-0.23-1.44-0.36-2.24c-0.75,0.22-1.33,0.39-1.95,0.57
	c0.07,0.65,0.13,1.17,0.19,1.67C561.77,510.95,562.38,510.95,563.11,510.95z M554.52,291.15c-0.11-0.87-0.19-1.46-0.27-2.07
	c-0.76,0.04-1.38,0.07-2.09,0.11c0.14,0.76,0.26,1.42,0.4,2.19C553.22,291.31,553.74,291.25,554.52,291.15z M363.48,31
	c0.22,0.81,0.41,1.5,0.6,2.21c0.7-0.08,1.22-0.14,1.86-0.21c-0.1-0.79-0.18-1.44-0.25-2C364.89,31,364.27,31,363.48,31z
	 M540.52,319.28c0.05,0.71,0.1,1.37,0.15,2.07c0.82-0.09,1.48-0.16,2.21-0.24c-0.1-0.76-0.18-1.41-0.26-2.08
	C541.87,319.12,541.27,319.19,540.52,319.28z M543.66,246.69c-0.85,0.12-1.45,0.21-2.14,0.31c0.03,0.65,0.06,1.16,0.1,1.81
	c0.79-0.05,1.45-0.09,2.2-0.14C543.76,247.98,543.72,247.46,543.66,246.69z M571.61,236.92c-0.76,0.11-1.43,0.21-2.24,0.34
	c0.13,0.68,0.25,1.26,0.38,1.93c0.69-0.06,1.29-0.11,1.87-0.16C571.61,238.24,571.61,237.63,571.61,236.92z M602.09,572.55
	c0.76-0.16,1.4-0.29,2.13-0.45c-0.16-0.69-0.28-1.2-0.42-1.79c-0.61,0.05-1.14,0.09-1.71,0.14
	C602.09,571.19,602.09,571.78,602.09,572.55z M599.35,180.22c-0.89,0.16-1.58,0.28-2.37,0.42c0.11,0.73,0.21,1.4,0.32,2.1
	c0.78-0.12,1.45-0.22,2.05-0.31C599.35,181.65,599.35,181.02,599.35,180.22z M568.3,449c-0.15-0.7-0.28-1.3-0.44-2.02
	c-0.66,0.1-1.24,0.19-1.91,0.3c0.1,0.76,0.17,1.35,0.25,1.99C566.97,449.17,567.59,449.09,568.3,449z M573.68,369.14
	c0.78-0.09,1.42-0.16,2.13-0.24c-0.19-0.81-0.34-1.47-0.51-2.17c-0.67,0.11-1.18,0.2-1.72,0.29
	C573.62,367.76,573.65,368.37,573.68,369.14z M572.63,417.64c-0.74,0.17-1.23,0.28-1.77,0.4c0.06,0.66,0.11,1.19,0.16,1.71
	c0.75,0,1.35,0,2.06,0C572.92,419.06,572.8,418.46,572.63,417.64z M627.48,573.87c0.81-0.16,1.47-0.29,2.19-0.44
	c-0.08-0.59-0.15-1.12-0.21-1.63c-0.75,0-1.41,0-2.14,0C627.38,572.55,627.42,573.16,627.48,573.87z M566.22,243.2
	c0.89-0.14,1.49-0.24,2.19-0.35c-0.08-0.67-0.14-1.19-0.21-1.81c-0.82,0.06-1.48,0.11-2.19,0.16
	C566.08,241.92,566.14,242.47,566.22,243.2z M592.36,418.09c0.82-0.11,1.41-0.19,2.06-0.27c-0.1-0.81-0.18-1.48-0.26-2.16
	c-0.69,0.05-1.23,0.1-1.8,0.14C592.36,416.56,592.36,417.17,592.36,418.09z M458.12,116.88c0,0.84,0,1.49,0,2.23
	c0.77-0.07,1.38-0.13,2.03-0.18c-0.14-0.77-0.26-1.44-0.38-2.15C459.19,116.81,458.72,116.84,458.12,116.88z M488.6,156.01
	c-0.06-0.83-0.11-1.42-0.16-2.1c-0.79,0.12-1.45,0.22-2.16,0.33c0.07,0.85,0.13,1.51,0.18,2.21
	C487.26,156.28,487.87,156.16,488.6,156.01z M496.71,500.21c-0.81,0-1.47,0-2.22,0c0.13,0.8,0.23,1.44,0.34,2.18
	c0.78-0.19,1.36-0.33,2-0.49C496.79,501.26,496.75,500.74,496.71,500.21z M588.64,140.19c-0.09-0.83-0.15-1.46-0.23-2.2
	c-0.83,0.16-1.57,0.3-2.32,0.45c0.06,0.79,0.11,1.42,0.16,2.07C587.04,140.41,587.73,140.31,588.64,140.19z M549.67,548.06
	c0.77-0.08,1.29-0.14,1.93-0.2c-0.07-0.8-0.13-1.45-0.2-2.16c-0.79,0.11-1.39,0.2-2.1,0.3
	C549.42,546.68,549.53,547.28,549.67,548.06z M630.17,620.63c-0.15-0.78-0.27-1.37-0.4-2.05c-0.67,0.07-1.27,0.13-1.8,0.18
	c0,0.82,0,1.48,0,2.27C628.69,620.9,629.3,620.79,630.17,620.63z M593.5,303.92c0,0.74,0,1.27,0,1.84c0.76,0,1.42,0,2.24,0
	c-0.16-0.71-0.28-1.28-0.41-1.84C594.67,303.92,594.14,303.92,593.5,303.92z M579.77,148.09c0.72-0.09,1.37-0.18,2.05-0.26
	c-0.04-0.72-0.06-1.25-0.09-1.9c-0.78,0.05-1.45,0.08-2.17,0.13C579.63,146.76,579.69,147.36,579.77,148.09z M273.28,88.02
	c-0.08-0.75-0.15-1.29-0.22-1.95c-0.71,0.04-1.31,0.08-2.06,0.12c0.11,0.76,0.2,1.41,0.29,2.1C272,88.2,272.54,88.12,273.28,88.02z
	 M610.73,544.27c0,0.69,0,1.36,0,2.1c0.8-0.1,1.46-0.19,2.16-0.28c-0.06-0.77-0.12-1.37-0.17-2.03
	C612.03,544.14,611.4,544.2,610.73,544.27z M582.2,485.3c-0.69,0.13-1.27,0.23-1.91,0.35c0.09,0.7,0.15,1.24,0.23,1.9
	c0.59-0.04,1.12-0.08,1.67-0.12C582.2,486.66,582.2,486.04,582.2,485.3z M586.12,156.49c-0.68,0.04-1.28,0.08-1.86,0.12
	c0,0.74,0,1.35,0,2.05c0.82-0.05,1.55-0.1,2.38-0.15C586.45,157.76,586.29,157.16,586.12,156.49z M500.72,445.5
	c0.16,0.8,0.27,1.38,0.4,2.04c0.73-0.11,1.31-0.2,1.97-0.29c-0.06-0.78-0.12-1.43-0.17-2.08
	C502.15,445.28,501.53,445.38,500.72,445.5z M632.27,562.41c-0.04-0.68-0.07-1.29-0.11-2.04c-0.85,0.1-1.56,0.19-2.33,0.29
	c0.15,0.84,0.27,1.51,0.39,2.21C630.96,562.71,631.56,562.57,632.27,562.41z M415.25,41.05c0.05,0.87,0.09,1.48,0.14,2.23
	c0.68-0.1,1.25-0.19,1.88-0.29c-0.05-0.76-0.09-1.37-0.13-2.04C416.51,40.98,415.98,41.01,415.25,41.05z M619.15,618.06
	c-0.17-0.89-0.3-1.54-0.43-2.2c-0.72,0.11-1.25,0.19-1.77,0.27c0,0.66,0,1.27,0,1.93C617.67,618.06,618.29,618.06,619.15,618.06z
	 M569.73,341.91c-0.72,0.08-1.39,0.16-2.16,0.25c0.16,0.76,0.3,1.42,0.45,2.12c0.77-0.11,1.36-0.19,1.98-0.28
	C569.9,343.24,569.82,342.61,569.73,341.91z M583.47,207.33c-0.79,0.05-1.38,0.09-2.11,0.14c0.05,0.61,0.09,1.12,0.14,1.73
	c0.8-0.04,1.45-0.08,2.18-0.11C583.6,208.48,583.55,208.03,583.47,207.33z M593.48,487.89c-0.78,0.11-1.49,0.21-2.25,0.32
	c0.13,0.75,0.24,1.35,0.35,2c0.69-0.07,1.29-0.13,1.9-0.2C593.48,489.3,593.48,488.7,593.48,487.89z M534.42,581.86
	c0.69-0.07,1.38-0.15,2.09-0.22c-0.08-0.83-0.14-1.49-0.21-2.18c-0.82,0.18-1.49,0.32-2.14,0.46
	C534.24,580.58,534.31,581.12,534.42,581.86z M594.63,444.44c0.12,0.83,0.21,1.42,0.31,2.05c0.77-0.05,1.38-0.08,2.08-0.12
	c-0.08-0.69-0.15-1.29-0.22-1.92C596.1,444.44,595.49,444.44,594.63,444.44z M558.37,294.61c-0.05-0.85-0.09-1.46-0.13-2.21
	c-0.64,0.09-1.16,0.16-1.75,0.24c0,0.71,0,1.32,0,1.97C557.08,294.61,557.61,294.61,558.37,294.61z M549.08,311.39
	c-0.88,0.08-1.49,0.13-2.17,0.19c0.1,0.66,0.18,1.2,0.29,1.86c0.74-0.05,1.42-0.09,2.13-0.13
	C549.24,312.64,549.17,312.1,549.08,311.39z M621,579.58c0.08,0.71,0.15,1.3,0.22,1.98c0.65-0.05,1.18-0.09,1.75-0.13
	c0-0.74,0-1.34,0-2.06C622.26,579.45,621.67,579.51,621,579.58z M567.56,257.74c0.66-0.18,1.25-0.34,1.96-0.53
	c-0.09-0.67-0.16-1.27-0.25-1.91c-0.8,0.05-1.46,0.09-2.14,0.14C567.29,256.29,567.42,256.98,567.56,257.74z M461.79,35.57
	c0.61-0.06,1.14-0.12,1.76-0.19c-0.07-0.77-0.12-1.43-0.18-2.15c-0.72,0.1-1.24,0.18-1.79,0.26
	C461.65,34.24,461.72,34.85,461.79,35.57z M635.91,587.68c0.72-0.12,1.39-0.22,2.19-0.35c-0.1-0.65-0.18-1.23-0.28-1.92
	c-0.77,0.08-1.42,0.15-2.07,0.22C635.81,586.38,635.86,586.98,635.91,587.68z M490.24,157.62c0.13,0.72,0.22,1.24,0.32,1.8
	c0.86-0.06,1.54-0.11,2.24-0.17c-0.15-0.79-0.27-1.45-0.39-2.11C491.64,157.31,491.03,157.45,490.24,157.62z M586.15,448.87
	c-0.69,0.1-1.27,0.19-1.92,0.28c0.07,0.75,0.12,1.34,0.18,2.01c0.66-0.11,1.19-0.19,1.83-0.3
	C586.21,450.18,586.18,449.58,586.15,448.87z M403.11,44.24c-0.04-0.79-0.07-1.46-0.11-2.24c-0.77,0.18-1.43,0.34-2.14,0.51
	c0.09,0.72,0.16,1.31,0.23,1.91C401.79,44.36,402.33,44.31,403.11,44.24z M590.62,356.64c0.84-0.1,1.45-0.17,2.16-0.25
	c-0.1-0.64-0.19-1.17-0.28-1.76c-0.73,0.06-1.33,0.12-2.01,0.18C590.54,355.44,590.57,355.91,590.62,356.64z M623.04,561.21
	c0.05,0.83,0.09,1.49,0.13,2.26c0.7-0.08,1.22-0.15,1.85-0.22c-0.05-0.79-0.1-1.45-0.15-2.16
	C624.26,561.12,623.8,561.15,623.04,561.21z M571.86,489.87c-0.68,0.06-1.34,0.12-2.09,0.19c0.05,0.82,0.08,1.48,0.12,2.19
	c0.86-0.11,1.52-0.2,2.19-0.29C572,491.22,571.93,490.6,571.86,489.87z M584.57,136.88c-0.09-0.8-0.16-1.4-0.24-2.08
	c-0.72,0.07-1.32,0.13-2.06,0.2c0.05,0.71,0.09,1.32,0.13,2C583.12,136.97,583.73,136.93,584.57,136.88z M559.25,388.19
	c-0.06-0.79-0.1-1.39-0.15-2.02c-0.79,0.11-1.44,0.19-2.15,0.29c0.12,0.76,0.2,1.34,0.3,1.95
	C557.94,388.34,558.48,388.28,559.25,388.19z M578.17,563.71c-0.08-0.77-0.15-1.37-0.22-2.06c-0.65,0.05-1.19,0.08-1.78,0.12
	c0,0.73,0,1.33,0,2.05C576.81,563.79,577.34,563.76,578.17,563.71z M579.41,557.96c0.08,0.83,0.14,1.42,0.21,2.07
	c0.66-0.1,1.18-0.17,1.77-0.26c-0.07-0.72-0.13-1.31-0.2-2C580.57,557.84,580.12,557.89,579.41,557.96z M581.33,539.73
	c0.04,0.82,0.07,1.4,0.1,2.06c0.86-0.04,1.51-0.08,2.25-0.12c-0.19-0.78-0.33-1.37-0.49-2.05
	C582.62,539.66,582.1,539.69,581.33,539.73z M642.28,617.55c0.09,0.79,0.15,1.38,0.2,1.84c0.79,0,1.39,0,2.04,0
	c-0.11-0.75-0.19-1.37-0.29-2.07C643.62,617.4,643.08,617.46,642.28,617.55z M573.71,493.42c0,0.86,0,1.47,0,2.14
	c0.84-0.04,1.57-0.07,2.43-0.11c-0.23-0.79-0.42-1.44-0.59-2.02C574.87,493.42,574.33,493.42,573.71,493.42z M543.43,288.47
	c-0.03-0.67-0.06-1.27-0.1-1.96c-0.79,0.06-1.45,0.1-2.16,0.15c0.09,0.72,0.16,1.31,0.24,2
	C542.1,288.59,542.69,288.54,543.43,288.47z M581.78,296.2c0.8-0.18,1.36-0.32,1.97-0.46c-0.08-0.69-0.14-1.2-0.2-1.82
	c-0.72,0.09-1.3,0.16-1.9,0.23C581.68,294.85,581.72,295.38,581.78,296.2z M608.69,562.62c0.06,0.75,0.1,1.28,0.14,1.76
	c0.75,0,1.35,0,2.05,0c-0.05-0.75-0.09-1.4-0.13-2.12C610.05,562.38,609.46,562.49,608.69,562.62z M599.21,496.87
	c0.89-0.07,1.55-0.13,2.3-0.19c-0.17-0.75-0.29-1.27-0.43-1.87c-0.66,0.04-1.27,0.07-1.87,0.1
	C599.21,495.55,599.21,496.08,599.21,496.87z M577.81,601.51c-0.75,0.11-1.33,0.2-1.97,0.29c0.08,0.74,0.14,1.33,0.21,2
	c0.75-0.11,1.34-0.19,1.99-0.29C577.96,602.86,577.9,602.32,577.81,601.51z M534.49,393.97c0-0.75,0-1.42,0-2.14
	c-0.76,0.06-1.45,0.11-2.24,0.16c0.08,0.67,0.15,1.34,0.24,2.09C533.25,394.05,533.85,394.01,534.49,393.97z M563.3,539.53
	c0.8-0.05,1.4-0.09,2.08-0.14c-0.06-0.74-0.12-1.34-0.18-2.04c-0.78,0.12-1.43,0.22-2.13,0.33
	C563.15,538.33,563.21,538.79,563.3,539.53z M450.39,33.13c0.85-0.22,1.51-0.39,2.16-0.55c-0.06-0.74-0.12-1.34-0.17-2
	c-0.74,0.11-1.35,0.19-1.98,0.28C450.39,31.59,450.39,32.22,450.39,33.13z M572.33,478.98c0.08,0.83,0.14,1.44,0.21,2.16
	c0.74-0.11,1.4-0.22,2.06-0.32c-0.03-0.73-0.06-1.27-0.09-1.84C573.78,478.98,573.16,478.98,572.33,478.98z M595.49,578.1
	c0.12,0.87,0.2,1.44,0.28,2.08c0.73-0.11,1.25-0.18,1.85-0.27c-0.07-0.71-0.13-1.3-0.19-1.93
	C596.78,578.02,596.24,578.05,595.49,578.1z M567.09,250.35c0.68-0.12,1.32-0.24,1.94-0.36c-0.05-0.75-0.09-1.36-0.14-2.07
	c-0.8,0.14-1.52,0.26-2.24,0.38C566.81,249.05,566.94,249.65,567.09,250.35z M459.09,22.86c-0.73,0.13-1.4,0.25-2.18,0.38
	c0.08,0.64,0.15,1.22,0.23,1.84c0.86-0.05,1.52-0.09,2.24-0.13C459.27,24.14,459.19,23.54,459.09,22.86z M580.96,452.98
	c0.21,0.79,0.35,1.36,0.51,1.96c0.68-0.11,1.19-0.19,1.63-0.26c0-0.74,0-1.33,0-2.07C582.39,452.74,581.79,452.84,580.96,452.98z
	 M491.19,45.25c0.15,0.81,0.29,1.53,0.43,2.31c0.76-0.2,1.35-0.36,1.99-0.53c-0.05-0.71-0.09-1.32-0.14-1.99
	C492.67,45.11,491.98,45.17,491.19,45.25z M511.25,419.23c-0.73,0.07-1.32,0.12-1.97,0.18c0.1,0.86,0.19,1.59,0.28,2.33
	c0.76-0.17,1.36-0.3,2.02-0.45C511.47,420.65,511.38,420.05,511.25,419.23z M577.59,538.44c0.72-0.05,1.33-0.1,2.03-0.15
	c-0.06-0.77-0.12-1.42-0.18-2.19c-0.75,0.13-1.28,0.23-1.84,0.33C577.59,537.12,577.59,537.73,577.59,538.44z M581.72,478.16
	c-0.86,0.07-1.46,0.11-2.19,0.17c0.15,0.7,0.28,1.31,0.44,2.02c0.63-0.08,1.21-0.15,1.85-0.23
	C581.78,479.41,581.75,478.86,581.72,478.16z M435.9,89.84c-0.71,0.13-1.28,0.24-1.9,0.36c0.07,0.76,0.12,1.37,0.17,2.01
	c0.64-0.04,1.17-0.07,1.84-0.11C435.97,91.31,435.94,90.65,435.9,89.84z M373.16,14.56c0.85-0.09,1.45-0.15,2.08-0.21
	c-0.16-0.76-0.29-1.42-0.45-2.18c-0.61,0.09-1.12,0.16-1.63,0.23C373.16,13.14,373.16,13.74,373.16,14.56z M458.26,124.06
	c0.12,0.82,0.2,1.43,0.3,2.12c0.77-0.08,1.48-0.16,2.26-0.25c-0.17-0.74-0.31-1.32-0.43-1.87
	C459.64,124.06,459.02,124.06,458.26,124.06z M591.57,426.69c-0.68,0.06-1.29,0.11-1.96,0.16c0.07,0.75,0.12,1.43,0.2,2.24
	c0.68-0.06,1.29-0.11,1.96-0.17C591.69,428.18,591.64,427.5,591.57,426.69z M567.56,439.72c-0.66,0.14-1.3,0.28-1.99,0.43
	c0.05,0.68,0.09,1.2,0.13,1.7c0.75,0,1.34,0,2.01,0C567.66,441.13,567.62,440.52,567.56,439.72z M621.67,586.91
	c0.08,0.78,0.14,1.3,0.19,1.75c0.75,0,1.35,0,2.04,0c-0.1-0.78-0.19-1.44-0.29-2.18C622.93,586.63,622.4,586.75,621.67,586.91z
	 M614.91,612.7c-0.7,0-1.35,0-2.05,0c0.12,0.75,0.21,1.35,0.32,2.05c0.72-0.05,1.34-0.09,1.99-0.13
	C615.08,613.91,614.99,613.29,614.91,612.7z M573.3,532.87c0.23,0.91,0.39,1.56,0.58,2.3c0.64-0.12,1.15-0.22,1.71-0.33
	c-0.05-0.74-0.1-1.35-0.14-1.97C574.71,532.87,574.1,532.87,573.3,532.87z M592.77,589.16c0.09,0.68,0.16,1.28,0.26,2.01
	c0.68-0.06,1.27-0.11,1.94-0.16c-0.07-0.71-0.13-1.32-0.19-1.98C594.07,589.08,593.48,589.12,592.77,589.16z M572.84,441.25
	c0.81-0.1,1.4-0.18,1.99-0.26c-0.07-0.73-0.12-1.34-0.18-2.02c-0.74,0.11-1.39,0.2-2.08,0.31
	C572.67,439.97,572.74,440.5,572.84,441.25z M570.11,246.56c0.73-0.16,1.38-0.3,2.1-0.45c-0.1-0.69-0.17-1.22-0.25-1.8
	c-0.72,0.04-1.32,0.08-1.95,0.12C570.04,245.2,570.07,245.81,570.11,246.56z M537.51,243.61c0.06,0.79,0.12,1.45,0.18,2.22
	c0.73-0.1,1.25-0.18,1.91-0.27c-0.07-0.77-0.13-1.42-0.19-2.11C538.69,243.51,538.15,243.56,537.51,243.61z M500.34,456.18
	c-0.75,0.13-1.43,0.24-2.23,0.38c0.16,0.72,0.3,1.37,0.44,2.03c0.75-0.12,1.35-0.21,1.93-0.3
	C500.43,457.5,500.38,456.88,500.34,456.18z M577.99,417.18c0.06,0.75,0.11,1.34,0.16,1.97c0.72-0.05,1.33-0.09,1.97-0.13
	c0-0.76,0-1.42,0-2.17C579.43,416.95,578.84,417.05,577.99,417.18z M547.74,297.03c-0.72,0.1-1.31,0.19-1.91,0.27
	c0.03,0.71,0.06,1.26,0.08,1.91c0.8-0.04,1.54-0.08,2.35-0.12C548.08,298.38,547.95,297.85,547.74,297.03z M557.73,103.07
	c0.65-0.11,1.29-0.21,2.04-0.34c-0.06-0.71-0.11-1.31-0.16-1.95c-0.81,0.06-1.47,0.11-2.2,0.17
	C557.52,101.68,557.61,102.29,557.73,103.07z M592,244.42c0.69-0.03,1.33-0.06,2.03-0.09c-0.11-0.85-0.2-1.51-0.29-2.17
	c-0.82,0.16-1.48,0.29-2.15,0.42C591.74,243.27,591.86,243.8,592,244.42z M467.92,125.32c-0.05-0.72-0.09-1.38-0.14-2.13
	c-0.8,0.13-1.45,0.23-2.15,0.34c0.09,0.77,0.16,1.36,0.23,1.98C466.61,125.45,467.22,125.39,467.92,125.32z M537.44,578.15
	c0.73-0.15,1.39-0.28,2.15-0.43c-0.06-0.68-0.11-1.27-0.17-1.88c-0.74,0.03-1.34,0.06-1.98,0.09
	C537.44,576.73,537.44,577.4,537.44,578.15z M382.05,33.19c0,0.73,0,1.43,0,2.22c0.8-0.11,1.45-0.2,2.18-0.31
	c-0.12-0.76-0.22-1.34-0.31-1.91C383.28,33.19,382.73,33.19,382.05,33.19z M487.7,168.66c0.04,0.65,0.07,1.26,0.11,2.01
	c0.83-0.11,1.55-0.2,2.33-0.3c-0.14-0.83-0.26-1.49-0.38-2.18C489.02,168.37,488.42,168.5,487.7,168.66z M514.93,417.35
	c-0.11-0.74-0.19-1.26-0.28-1.81c-0.77,0-1.44,0-2.19,0c0.11,0.66,0.21,1.26,0.32,1.95C513.45,417.45,514.06,417.41,514.93,417.35z
	 M497.37,531.04c0.66-0.08,1.31-0.15,1.93-0.22c0-0.79,0-1.47,0-2.24c-0.78,0.17-1.43,0.31-2.05,0.44
	C497.29,529.74,497.32,530.29,497.37,531.04z M594.67,502.22c-0.83,0.18-1.49,0.32-2.16,0.47c0.07,0.69,0.13,1.23,0.19,1.9
	c0.67-0.07,1.29-0.13,1.96-0.19C594.67,503.71,594.67,503.09,594.67,502.22z M403.45,71.18c0,0.8,0,1.45,0,2.11
	c0.83-0.05,1.5-0.09,2.29-0.14c-0.23-0.77-0.42-1.41-0.62-2.09C404.46,71.11,403.93,71.15,403.45,71.18z M521.85,414.82
	c-0.64,0.05-1.24,0.1-1.9,0.16c0.04,0.73,0.07,1.33,0.11,2.03c0.72-0.05,1.32-0.1,1.98-0.15
	C521.97,416.15,521.92,415.53,521.85,414.82z M568.32,428.92c0.06,0.76,0.11,1.36,0.16,2.01c0.74-0.04,1.34-0.07,1.99-0.1
	c-0.12-0.78-0.21-1.45-0.32-2.2C569.5,428.73,569.06,428.8,568.32,428.92z M576.61,130.45c-0.09-0.79-0.15-1.39-0.23-2.13
	c-0.65,0.12-1.16,0.22-1.75,0.33c0.07,0.71,0.12,1.3,0.18,1.94C575.4,130.54,575.87,130.5,576.61,130.45z M591.1,461.58
	c-0.13-0.81-0.24-1.47-0.36-2.15c-0.72,0.08-1.26,0.13-1.92,0.21c0.05,0.67,0.1,1.28,0.16,1.94
	C589.65,461.58,590.26,461.58,591.1,461.58z M508.46,201.23c0.8,0,1.53,0,2.39,0c-0.24-0.79-0.42-1.43-0.62-2.1
	c-0.66,0.05-1.19,0.1-1.76,0.14C508.46,200,508.46,200.61,508.46,201.23z M572.83,526.01c0.08,0.64,0.16,1.24,0.24,1.89
	c0.73-0.03,1.35-0.06,2.06-0.09c-0.08-0.69-0.14-1.29-0.21-1.93C574.2,525.92,573.58,525.96,572.83,526.01z M583.64,460.06
	c-0.72,0-1.39,0-2.2,0c0.17,0.78,0.31,1.43,0.46,2.15c0.7-0.15,1.21-0.26,1.73-0.37C583.64,461.18,583.64,460.63,583.64,460.06z
	 M600.17,590.62c0.84,0,1.46,0,2.18,0c-0.17-0.74-0.33-1.39-0.49-2.09c-0.64,0.08-1.17,0.14-1.78,0.22
	C600.11,589.39,600.14,589.93,600.17,590.62z M591.45,508.22c-0.09-0.83-0.15-1.42-0.22-2.05c-0.79,0.07-1.46,0.14-2.25,0.21
	c0.19,0.79,0.34,1.44,0.49,2.08C590.19,508.38,590.73,508.31,591.45,508.22z M414.7,54.17c-0.06-0.82-0.11-1.43-0.16-2.18
	c-0.63,0.07-1.23,0.14-1.87,0.21c0.06,0.73,0.11,1.34,0.18,2.09C413.43,54.26,413.96,54.22,414.7,54.17z M591.91,329.36
	c0.07,0.9,0.12,1.51,0.17,2.16c0.77-0.15,1.43-0.28,2.22-0.44c-0.16-0.67-0.3-1.24-0.45-1.85
	C593.17,329.27,592.64,329.31,591.91,329.36z M570.28,224.69c0-0.87,0-1.49,0-2.23c-0.79,0.1-1.52,0.2-2.25,0.29
	c0.19,0.85,0.34,1.52,0.51,2.3C569.15,224.92,569.67,224.82,570.28,224.69z M582.24,567.1c-0.21-0.77-0.38-1.42-0.56-2.1
	c-0.67,0.1-1.19,0.18-1.79,0.27c0.08,0.75,0.15,1.33,0.21,1.83C580.9,567.1,581.51,567.1,582.24,567.1z M601.73,523.45
	c0.06,0.78,0.1,1.38,0.15,2.07c0.68-0.06,1.22-0.11,1.85-0.16c-0.07-0.75-0.13-1.41-0.2-2.15
	C602.92,523.29,602.46,523.35,601.73,523.45z M477.75,55.86c0.91-0.1,1.58-0.17,2.34-0.25c-0.17-0.73-0.3-1.3-0.44-1.95
	c-0.73,0.09-1.33,0.17-2.04,0.26C477.66,54.57,477.7,55.09,477.75,55.86z M541.52,306.7c-0.04-0.87-0.07-1.49-0.1-2.17
	c-0.75,0.14-1.42,0.27-2.16,0.41c0.06,0.66,0.12,1.27,0.18,1.98C540.14,306.84,540.75,306.78,541.52,306.7z M602.22,464.05
	c-0.14-0.74-0.26-1.34-0.39-2.03c-0.74,0.11-1.4,0.2-2.06,0.3c0.08,0.81,0.15,1.46,0.22,2.12
	C600.77,464.3,601.38,464.2,602.22,464.05z M577.31,576.14c0.13,0.86,0.22,1.44,0.31,2.03c0.7-0.06,1.23-0.1,1.85-0.15
	c-0.07-0.69-0.14-1.29-0.21-1.99C578.6,576.07,578.07,576.1,577.31,576.14z M544.52,300.86c-0.71,0.06-1.32,0.11-1.98,0.17
	c0,0.73,0,1.42,0,2.17c0.75-0.14,1.42-0.27,2.17-0.41C544.65,302.15,544.59,301.56,544.52,300.86z M617.38,578.19
	c0.63-0.04,1.17-0.08,1.76-0.12c0-0.76,0-1.37,0-2.13c-0.76,0.15-1.43,0.27-2.11,0.4C617.16,576.99,617.25,577.45,617.38,578.19z
	 M595.13,509.65c-0.79,0.05-1.4,0.08-2.1,0.12c0.07,0.8,0.13,1.46,0.19,2.16c0.78-0.19,1.37-0.33,2.01-0.49
	C595.19,510.87,595.17,510.4,595.13,509.65z M576.68,278.41c0.7-0.06,1.36-0.12,2.06-0.18c-0.13-0.78-0.23-1.39-0.32-1.9
	c-0.79,0-1.45,0-2.14,0C576.42,277.06,576.54,277.67,576.68,278.41z M612.57,565.87c0.05,0.68,0.09,1.32,0.14,2.01
	c0.78-0.05,1.4-0.1,2.06-0.14c-0.06-0.71-0.1-1.32-0.16-2.03C613.94,565.76,613.33,565.81,612.57,565.87z M586.74,227.06
	c0.8-0.09,1.33-0.16,1.94-0.23c-0.06-0.78-0.12-1.44-0.18-2.18c-0.74,0.11-1.25,0.19-1.76,0.27
	C586.74,225.65,586.74,226.25,586.74,227.06z M397.75,66.31c0-0.76,0-1.43,0-2.2c-0.83,0.18-1.55,0.33-2.27,0.48
	c0.13,0.75,0.23,1.35,0.34,1.95C396.57,66.45,397.18,66.38,397.75,66.31z M593.46,321.94c-0.67,0.09-1.27,0.18-2,0.28
	c0.08,0.66,0.16,1.23,0.24,1.86c0.77-0.05,1.37-0.08,2.07-0.13C593.66,323.22,593.57,322.62,593.46,321.94z M577.6,372.09
	c0.66-0.04,1.32-0.07,2.03-0.11c-0.04-0.79-0.08-1.46-0.12-2.18c-0.8,0.14-1.47,0.26-2.16,0.38
	C577.43,370.83,577.5,371.37,577.6,372.09z M492.47,180.98c0.72-0.09,1.25-0.15,1.85-0.22c-0.08-0.76-0.15-1.42-0.23-2.14
	c-0.71,0.14-1.22,0.24-1.75,0.34C492.39,179.66,492.42,180.2,492.47,180.98z M560.96,595.64c0,0.82,0,1.44,0,2.21
	c0.75-0.15,1.41-0.27,2.13-0.41c-0.09-0.69-0.15-1.23-0.22-1.8C562.22,595.64,561.67,595.64,560.96,595.64z M606.24,511.97
	c-0.7,0.25-1.48,0.29-1.72,0.67c-0.27,0.43-0.06,1.16-0.06,1.82c0.73-0.04,1.26-0.06,1.91-0.1
	C606.32,513.57,606.29,512.91,606.24,511.97z M555.02,114.34c0.76-0.16,1.44-0.3,2.22-0.47c-0.15-0.7-0.27-1.28-0.41-1.91
	c-0.8,0.04-1.49,0.07-2.2,0.11C554.77,112.88,554.88,113.56,555.02,114.34z M495.77,469.56c0.86-0.1,1.44-0.17,2.1-0.25
	c-0.1-0.74-0.18-1.26-0.26-1.82c-0.74,0.07-1.35,0.14-2.05,0.2C495.63,468.28,495.69,468.81,495.77,469.56z M404.14,56.61
	c-0.84,0.12-1.48,0.21-2.1,0.3c0.09,0.79,0.16,1.36,0.23,1.98c0.78-0.11,1.38-0.2,2.07-0.3C404.28,57.97,404.22,57.44,404.14,56.61z
	 M349.08,32.25c0.22,0.9,0.38,1.55,0.54,2.21c0.69-0.15,1.22-0.26,1.87-0.4c-0.05-0.64-0.09-1.24-0.13-1.81
	C350.6,32.25,349.99,32.25,349.08,32.25z M634.21,584.05c-0.12-0.85-0.2-1.43-0.28-2.04c-0.78,0.09-1.4,0.15-2.07,0.23
	c0.08,0.7,0.15,1.31,0.23,2.02C632.76,584.19,633.38,584.13,634.21,584.05z M532.22,576.42c-0.72,0.14-1.22,0.25-1.79,0.36
	c0.06,0.74,0.11,1.35,0.17,2.03c0.72-0.07,1.34-0.13,1.97-0.19C532.46,577.91,532.36,577.29,532.22,576.42z M598.52,173.42
	c-0.85,0.06-1.51,0.1-2.25,0.15c0.08,0.66,0.15,1.19,0.24,1.83c0.75-0.07,1.4-0.12,2.15-0.19
	C598.61,174.6,598.57,174.15,598.52,173.42z M583.35,189.21c0.11,0.68,0.2,1.27,0.31,1.93c0.73-0.06,1.32-0.1,1.97-0.16
	c-0.07-0.84-0.12-1.48-0.18-2.2C584.61,188.95,584.01,189.07,583.35,189.21z M518.75,566.91c0,0.83,0,1.42,0,2.12
	c0.75-0.12,1.42-0.22,2.2-0.34c-0.12-0.69-0.23-1.27-0.34-1.91C519.93,566.82,519.39,566.86,518.75,566.91z M587.7,154.59
	c0.64-0.08,1.15-0.13,1.69-0.2c0-0.74,0-1.33,0-2.06c-0.68,0.08-1.26,0.15-1.9,0.23C587.56,153.27,587.62,153.86,587.7,154.59z
	 M596.74,466.11c0.04,0.66,0.07,1.3,0.11,2c0.8-0.09,1.42-0.15,2.04-0.22c-0.04-0.73-0.07-1.36-0.11-2.06
	C598.14,465.92,597.52,466.01,596.74,466.11z M575.3,485.94c-0.73,0.12-1.39,0.23-2.17,0.36c0.04,0.7,0.07,1.31,0.11,2.03
	c0.77-0.09,1.43-0.18,2.06-0.25C575.3,487.33,575.3,486.7,575.3,485.94z M552.72,583.83c0.63,0,1.34,0,2.16,0
	c-0.21-0.76-0.38-1.41-0.53-1.99C552.84,581.77,552.28,582.35,552.72,583.83z M597.91,561.9c1.63-0.33,1.63-0.33,1.35-2.1
	C597.74,560,597.74,560,597.91,561.9z M600.34,569.03c-0.13-0.73-0.24-1.39-0.36-2.1c-0.67,0.08-1.2,0.15-1.69,0.2
	c0,0.71,0,1.31,0,2.02C599.02,569.1,599.64,569.07,600.34,569.03z M449.33,34.39c-0.82,0.17-1.48,0.3-2.15,0.44
	c0.08,0.72,0.15,1.31,0.22,1.99c0.71-0.09,1.3-0.17,1.93-0.25C449.33,35.87,449.33,35.26,449.33,34.39z M573.45,572.79
	c0.12,0.73,0.23,1.37,0.34,2.04c0.71-0.1,1.22-0.18,1.82-0.27c-0.09-0.76-0.16-1.34-0.24-1.94
	C574.67,572.69,574.13,572.73,573.45,572.79z M340.07,24.45c-0.1-0.83-0.18-1.5-0.26-2.21c-0.76,0.21-1.35,0.37-2.06,0.57
	c0.1,0.58,0.2,1.09,0.29,1.64C338.68,24.45,339.22,24.45,340.07,24.45z M547.77,589.54c-0.61,0.03-1.19,0.05-1.92,0.08
	c0.19,0.84,0.33,1.49,0.48,2.15c0.63-0.07,1.09-0.12,1.68-0.18C547.93,590.88,547.86,590.28,547.77,589.54z M547.18,289.84
	c-0.84,0.07-1.46,0.12-2.11,0.17c0.07,0.78,0.13,1.45,0.2,2.16c0.74-0.16,1.33-0.29,1.91-0.42
	C547.18,291.13,547.18,290.59,547.18,289.84z M580.69,368.49c0.72-0.17,1.37-0.33,2.08-0.5c-0.1-0.69-0.18-1.2-0.27-1.8
	c-0.72,0.06-1.31,0.11-1.91,0.16C580.63,367.14,580.65,367.75,580.69,368.49z M398.72,60.61c0.05,0.85,0.09,1.47,0.13,2.19
	c0.68-0.13,1.35-0.26,2.11-0.4c-0.05-0.72-0.1-1.33-0.15-1.98C400.08,60.49,399.46,60.55,398.72,60.61z M559.16,592.04
	c-0.84,0.15-1.5,0.27-2.22,0.39c0.18,0.72,0.33,1.29,0.48,1.88c0.71-0.07,1.23-0.12,1.73-0.17
	C559.16,593.41,559.16,592.87,559.16,592.04z M462.27,129.6c0.76-0.15,1.47-0.28,2.31-0.45c-0.12-0.72-0.22-1.3-0.33-1.96
	c-0.77,0.05-1.36,0.09-1.98,0.12C462.27,128.1,462.27,128.72,462.27,129.6z M503.42,434.31c0.11,0.83,0.19,1.44,0.28,2.15
	c0.7-0.1,1.3-0.18,2-0.28c-0.1-0.73-0.18-1.33-0.26-1.87C504.73,434.31,504.18,434.31,503.42,434.31z M560.27,107.99
	c-0.86,0.06-1.51,0.1-2.2,0.15c0.15,0.8,0.27,1.4,0.4,2.08c0.7-0.09,1.3-0.17,2.02-0.26C560.42,109.31,560.36,108.78,560.27,107.99z
	 M590.1,515.65c0.75-0.12,1.39-0.23,2.02-0.34c-0.07-0.78-0.12-1.38-0.18-2.03c-0.7,0.13-1.29,0.24-1.99,0.37
	C590,514.34,590.05,514.94,590.1,515.65z M591.08,276.91c0.8,0,1.41,0,2.05,0c0-0.73,0-1.35,0-2.1c-0.75,0.14-1.41,0.27-2.15,0.41
	C591.02,275.81,591.04,276.28,591.08,276.91z M572.23,228.33c0.87-0.14,1.49-0.23,2.1-0.33c0-0.75,0-1.43,0-2.21
	c-0.75,0.14-1.42,0.27-2.19,0.42C572.17,226.91,572.2,227.53,572.23,228.33z M571.28,464.7c0.03,0.67,0.06,1.34,0.1,2.07
	c0.77-0.11,1.38-0.2,2.05-0.3c-0.09-0.77-0.16-1.44-0.24-2.13C572.5,464.47,571.95,464.57,571.28,464.7z M638.02,608.94
	c0.7-0.1,1.21-0.18,1.8-0.26c-0.04-0.69-0.08-1.26-0.12-1.89c-0.78,0.08-1.37,0.15-2.03,0.22
	C637.79,607.65,637.88,608.17,638.02,608.94z M554.61,543.96c-0.06-0.7-0.12-1.31-0.18-1.97c-0.68,0.06-1.22,0.12-1.89,0.18
	c0.06,0.75,0.12,1.41,0.18,2.14C553.44,544.17,553.98,544.07,554.61,543.96z M299.74,62.07c-0.75,0.07-1.34,0.12-2.01,0.18
	c0.06,0.76,0.1,1.35,0.15,2.02c0.76-0.17,1.41-0.32,2.12-0.48C299.92,63.24,299.85,62.79,299.74,62.07z M506.78,448.73
	c-0.85,0.08-1.44,0.14-2.06,0.2c0.09,0.72,0.16,1.25,0.24,1.86c0.73-0.09,1.32-0.16,1.96-0.24
	C506.87,449.92,506.83,449.46,506.78,448.73z M584.13,301.12c-0.7,0.1-1.23,0.18-1.83,0.27c0.04,0.66,0.07,1.2,0.11,1.88
	c0.76-0.04,1.42-0.08,2.14-0.12C584.4,302.42,584.29,301.88,584.13,301.12z M551.13,293.08c-0.66,0.11-1.25,0.22-1.93,0.34
	c0.04,0.59,0.08,1.11,0.13,1.75c0.8-0.05,1.45-0.09,2.15-0.14C551.35,294.28,551.25,293.75,551.13,293.08z M598.36,587.22
	c-0.1-0.88-0.18-1.48-0.26-2.16c-0.67,0.14-1.2,0.25-1.83,0.38c0.03,0.65,0.06,1.24,0.09,1.9
	C597.08,587.3,597.62,587.27,598.36,587.22z M527.49,210.51c0.79-0.1,1.4-0.18,2.11-0.27c-0.05-0.79-0.09-1.48-0.14-2.23
	c-0.8,0.18-1.47,0.34-2.15,0.49C527.37,209.16,527.42,209.71,527.49,210.51z M417.07,64.83c0.73-0.17,1.39-0.32,2.15-0.5
	c-0.07-0.63-0.12-1.16-0.17-1.64c-0.82,0-1.48,0-2.19,0C416.93,63.44,417,64.06,417.07,64.83z M606.7,541.07
	c0.11,0.71,0.2,1.29,0.29,1.9c0.78-0.08,1.39-0.14,2.07-0.21c-0.11-0.67-0.21-1.2-0.31-1.83
	C608.05,540.98,607.43,541.02,606.7,541.07z M568.41,389.09c0.11,0.77,0.19,1.35,0.33,2.4c0.69-0.6,1.23-0.87,1.45-1.31
	c0.16-0.31-0.1-0.84-0.17-1.31C569.48,388.94,569.1,388.99,568.41,389.09z M611.79,558.7c0.08,0.82,0.14,1.49,0.21,2.22
	c0.74-0.17,1.34-0.31,2.01-0.47c-0.07-0.69-0.13-1.29-0.19-1.9C613.14,558.59,612.59,558.63,611.79,558.7z M592.22,600.19
	c-0.75,0-1.39,0-2.16,0c0.19,0.72,0.35,1.29,0.52,1.92c0.61-0.09,1.13-0.16,1.74-0.24C592.28,601.24,592.25,600.73,592.22,600.19z
	 M630.2,578.63c-0.89,0.1-1.56,0.18-2.3,0.26c0.18,0.74,0.33,1.33,0.49,2c0.71-0.07,1.29-0.13,1.91-0.2
	C630.27,579.95,630.24,579.41,630.2,578.63z M556.62,545.65c0.1,0.78,0.17,1.3,0.25,1.87c0.76-0.1,1.34-0.18,1.99-0.26
	c-0.11-0.74-0.2-1.34-0.3-2.01C557.87,545.39,557.34,545.5,556.62,545.65z M590.01,491.98c-0.81,0.06-1.41,0.1-2.05,0.14
	c0.07,0.73,0.13,1.34,0.2,2c0.75-0.09,1.42-0.17,2.15-0.26C590.22,493.22,590.13,492.69,590.01,491.98z M574.08,518.73
	c-0.68,0.06-1.27,0.12-1.92,0.18c0.06,0.82,0.11,1.49,0.16,2.24c0.79-0.17,1.46-0.32,2.26-0.5
	C574.41,519.97,574.26,519.4,574.08,518.73z M542.09,295.89c0.77-0.11,1.43-0.21,2.08-0.31c-0.07-0.76-0.12-1.37-0.18-2.03
	c-0.7,0.12-1.3,0.22-2.04,0.35C542,294.58,542.05,295.19,542.09,295.89z M498.9,465.81c0.63-0.11,1.26-0.23,2.03-0.36
	c-0.1-0.69-0.18-1.28-0.25-1.79c-0.77,0-1.39,0-2.05,0C498.72,464.35,498.8,464.97,498.9,465.81z M583.93,275.9
	c0,0.65,0,1.24,0,1.92c0.74-0.04,1.35-0.07,2.05-0.11c-0.14-0.7-0.26-1.3-0.4-1.97C584.98,275.79,584.45,275.85,583.93,275.9z
	 M595.3,469.8c-0.82,0.06-1.47,0.11-2.19,0.16c0.17,0.79,0.3,1.38,0.44,2.01c0.76-0.12,1.36-0.21,1.95-0.3
	C595.43,471.01,595.38,470.54,595.3,469.8z M615.6,601.68c0.12,0.64,0.23,1.24,0.36,1.92c0.59-0.05,1.13-0.09,1.76-0.15
	c-0.04-0.71-0.06-1.29-0.09-1.9C616.88,601.6,616.33,601.63,615.6,601.68z M496.09,493.03c-0.81,0-1.41,0-2.11,0
	c0.13,0.73,0.24,1.32,0.36,2c0.74-0.13,1.33-0.23,1.96-0.34C496.22,494.08,496.16,493.61,496.09,493.03z M592.4,462.68
	c0.19,0.88,0.34,1.54,0.49,2.22c0.69-0.16,1.23-0.28,1.88-0.42c-0.08-0.63-0.15-1.22-0.22-1.79
	C593.84,462.68,593.28,462.68,592.4,462.68z M428.07,65.35c0.12,0.79,0.21,1.38,0.31,2.04c0.78-0.08,1.39-0.15,2.05-0.22
	c-0.13-0.78-0.24-1.45-0.36-2.14C429.38,65.14,428.83,65.23,428.07,65.35z M355.58,37.53c-0.07-0.77-0.13-1.43-0.2-2.15
	c-0.76,0.17-1.35,0.3-1.96,0.43c0.03,0.66,0.06,1.2,0.09,1.83C354.26,37.6,354.87,37.57,355.58,37.53z M598.64,592.38
	c-0.69,0.08-1.26,0.14-1.93,0.22c0.04,0.73,0.08,1.33,0.12,2.06c0.73-0.16,1.31-0.28,1.95-0.41
	C598.73,593.58,598.69,593.05,598.64,592.38z M594.22,273.55c0.8-0.19,1.39-0.33,2.09-0.49c-0.04-0.67-0.08-1.28-0.13-1.99
	c-0.78,0.1-1.44,0.19-2.09,0.28C594.14,272.1,594.17,272.72,594.22,273.55z M424.42,79.83c-0.89,0.24-1.53,0.41-2.21,0.59
	c0.12,0.73,0.22,1.31,0.32,1.95c0.73-0.1,1.32-0.18,1.89-0.25C424.42,81.33,424.42,80.7,424.42,79.83z M333.27,32.19
	c-0.04-0.76-0.07-1.3-0.1-1.95c-0.8,0.09-1.44,0.16-2.09,0.24c0.06,0.77,0.11,1.38,0.17,2.06
	C331.92,32.42,332.51,32.32,333.27,32.19z M548.92,390.6c-0.75,0.09-1.43,0.18-2.19,0.27c0.09,0.76,0.15,1.35,0.23,2.02
	c0.69-0.09,1.23-0.16,1.82-0.24C548.82,391.96,548.87,391.34,548.92,390.6z M465.99,46.15c0.74-0.18,1.4-0.33,2.16-0.51
	c-0.07-0.62-0.12-1.15-0.17-1.61c-0.81,0-1.47,0-2.15,0C465.88,44.78,465.93,45.38,465.99,46.15z M595.84,540.22
	c0.83,0,1.44,0,2.17,0c-0.11-0.72-0.21-1.32-0.32-2c-0.73,0.15-1.32,0.27-1.98,0.4C595.76,539.2,595.8,539.66,595.84,540.22z
	 M647.49,613.4c-0.69,0.18-1.19,0.31-1.81,0.47c0.08,0.64,0.14,1.21,0.21,1.81c0.77-0.04,1.38-0.08,2.05-0.12
	C647.8,614.86,647.68,614.26,647.49,613.4z M654.23,607.61c0-0.74,0-1.43,0-2.17c-0.8,0.11-1.48,0.21-2.19,0.31
	c0.09,0.75,0.16,1.36,0.24,2.05C652.96,607.73,653.58,607.67,654.23,607.61z M578.33,131.7c0.14,0.75,0.24,1.33,0.35,1.97
	c0.76-0.09,1.36-0.16,1.95-0.24c-0.05-0.75-0.1-1.36-0.15-2.05C579.82,131.48,579.21,131.57,578.33,131.7z M563.81,103.92
	c-0.86,0.2-1.5,0.34-2.21,0.5c0.1,0.72,0.18,1.29,0.26,1.87c0.47-0.03,0.78-0.05,1.09-0.08c0.3-0.03,0.6-0.09,0.87-0.12
	C563.81,105.38,563.81,104.76,563.81,103.92z M337.64,40.86c-2.18,0.03-2.18,0.03-1.75,1.97c0.67-0.06,1.35-0.11,2.09-0.18
	C337.86,42.04,337.76,41.51,337.64,40.86z M629.73,555.46c0.7-0.09,1.35-0.17,2.07-0.26c-0.07-0.69-0.12-1.22-0.17-1.85
	c-0.77,0.07-1.43,0.13-2.19,0.2C629.55,554.28,629.63,554.8,629.73,555.46z M576.4,460.81c-0.74,0-1.35,0-2.04,0
	c0.04,0.75,0.07,1.42,0.11,2.15c0.74-0.15,1.34-0.27,2.02-0.41C576.46,461.95,576.43,461.42,576.4,460.81z M618.37,590.69
	c0.03,0.64,0.06,1.24,0.09,1.9c0.73-0.05,1.27-0.08,1.92-0.12c-0.05-0.7-0.09-1.3-0.15-1.99
	C619.56,590.56,619.02,590.62,618.37,590.69z M400.46,55.3c-0.07-0.82-0.12-1.47-0.17-2.16c-0.77,0.14-1.38,0.25-2.03,0.37
	c0.07,0.68,0.14,1.28,0.21,1.96C399.1,55.42,399.64,55.37,400.46,55.3z M583.16,413.17c-0.76,0.03-1.38,0.06-2.07,0.09
	c0.04,0.79,0.07,1.46,0.1,2.23c0.72-0.14,1.39-0.26,2.17-0.41C583.29,414.48,583.24,413.95,583.16,413.17z M593.81,266.09
	c0.8-0.09,1.37-0.15,1.95-0.22c0-0.84,0-1.52,0-2.28c-0.74,0.2-1.41,0.38-2.15,0.58C593.67,264.75,593.73,265.28,593.81,266.09z
	 M502.26,525.21c-0.64,0-1.23,0-1.86,0c0.04,0.72,0.07,1.31,0.11,2.02c0.77-0.17,1.41-0.32,2.1-0.47
	C502.46,526.13,502.36,525.68,502.26,525.21z M578.74,570.91c-0.08-0.73-0.16-1.39-0.24-2.14c-0.68,0.13-1.19,0.23-1.75,0.33
	c0.07,0.72,0.12,1.31,0.19,1.99C577.58,571.04,578.12,570.98,578.74,570.91z M588.43,557.07c-0.87,0.09-1.47,0.16-2.12,0.23
	c0.11,0.77,0.21,1.42,0.31,2.09c0.73-0.15,1.31-0.27,1.81-0.38C588.43,558.31,588.43,557.79,588.43,557.07z M596.8,570.71
	c-0.68,0.12-1.26,0.22-1.93,0.33c0.17,0.74,0.31,1.32,0.46,1.95c0.62-0.08,1.07-0.14,1.62-0.22
	C596.89,572.07,596.85,571.47,596.8,570.71z M577.76,330.5c0,0.75,0,1.36,0,2.08c0.7-0.06,1.3-0.11,2.04-0.17
	c-0.2-0.79-0.36-1.43-0.52-2.06C578.73,330.41,578.34,330.45,577.76,330.5z M574.44,394.28c-0.17-0.85-0.28-1.42-0.42-2.1
	c-0.67,0.18-1.17,0.32-1.75,0.47c0.06,0.62,0.11,1.14,0.16,1.72C573.1,394.35,573.63,394.32,574.44,394.28z M567.97,528.43
	c-0.04-0.76-0.07-1.37-0.1-2.12c-0.78,0.08-1.45,0.15-2.2,0.22c0.1,0.76,0.18,1.42,0.27,2.1
	C566.62,528.57,567.16,528.52,567.97,528.43z M428.74,50.96c-0.64,0.04-1.24,0.07-1.94,0.11c0.05,0.82,0.09,1.49,0.14,2.25
	c0.82-0.19,1.49-0.35,2.24-0.52C429.03,52.12,428.9,51.59,428.74,50.96z M503.36,201.63c-0.06-0.64-0.11-1.24-0.17-1.96
	c-0.79,0.04-1.44,0.08-2.17,0.12c0.16,0.78,0.28,1.38,0.41,1.99C502.11,201.73,502.66,201.69,503.36,201.63z M542.03,588.78
	c0.82-0.18,1.5-0.33,2.27-0.5c-0.17-0.68-0.31-1.2-0.47-1.83c-0.65,0.07-1.24,0.13-1.8,0.19
	C542.03,587.37,542.03,587.92,542.03,588.78z M358.76,33.62c-0.1-0.81-0.18-1.39-0.26-2.01c-0.77,0.1-1.38,0.18-2,0.25
	c0.07,0.74,0.12,1.36,0.19,2.07C357.33,33.84,357.94,33.75,358.76,33.62z M582.61,194.96c-0.18-0.79-0.34-1.49-0.5-2.18
	c-0.76,0.13-1.35,0.23-2.03,0.34c0.15,0.75,0.27,1.32,0.38,1.84C581.24,194.96,581.86,194.96,582.61,194.96z M557.9,475
	c0.67-0.06,1.2-0.11,1.73-0.16c0-0.71,0-1.31,0-2.09c-0.75,0.16-1.37,0.3-2.01,0.44C557.72,473.83,557.79,474.29,557.9,475z
	 M545.41,310.08c-0.09-0.81-0.15-1.4-0.22-2.01c-0.75,0.05-1.36,0.1-2.07,0.15c0.09,0.76,0.17,1.4,0.25,2.06
	C544.11,310.21,544.65,310.16,545.41,310.08z M572.74,399.74c0.08,0.69,0.15,1.31,0.22,2.01c0.76-0.17,1.27-0.29,1.88-0.42
	c-0.11-0.65-0.19-1.17-0.29-1.78C573.94,399.61,573.41,399.67,572.74,399.74z M553.92,534.81c-0.75,0.06-1.22,0.1-1.7,0.14
	c0,0.71,0,1.31,0,2.03c0.67-0.1,1.2-0.18,1.7-0.26C553.92,536.07,553.92,535.54,553.92,534.81z M516.57,418.8
	c0.06,0.82,0.11,1.41,0.17,2.13c0.77-0.21,1.34-0.38,1.93-0.54c-0.05-0.65-0.09-1.18-0.14-1.78
	C517.86,418.68,517.33,418.73,516.57,418.8z M619.65,585.19c-0.03-0.71-0.06-1.29-0.09-1.92c-0.79,0.08-1.38,0.13-2.06,0.2
	c0.14,0.71,0.25,1.31,0.39,2C618.44,585.39,618.89,585.31,619.65,585.19z M357.69,46.22c0.06,0.9,0.11,1.57,0.16,2.31
	c0.8-0.16,1.46-0.3,2.27-0.46c-0.18-0.71-0.33-1.28-0.47-1.85C359.02,46.22,358.49,46.22,357.69,46.22z M561.65,536.33
	c-0.21-0.76-0.39-1.42-0.58-2.11c-0.68,0.21-1.18,0.36-1.7,0.52c0.05,0.64,0.09,1.1,0.13,1.59
	C560.26,536.33,560.88,536.33,561.65,536.33z M643.18,602.87c-0.78,0.11-1.36,0.19-2.03,0.29c0.1,0.73,0.18,1.3,0.28,1.94
	c0.68-0.08,1.2-0.15,1.76-0.21C643.18,604.24,643.18,603.7,643.18,602.87z M599.48,457.13c0.76-0.08,1.37-0.15,2-0.22
	c0-0.78,0-1.47,0-2.25c-0.77,0.17-1.45,0.31-2.14,0.47C599.38,455.74,599.42,456.29,599.48,457.13z M593.62,596.45
	c0.08,0.75,0.14,1.28,0.21,1.88c0.62-0.04,1.16-0.08,1.76-0.12c0-0.75,0-1.37,0-2.08C594.97,596.23,594.43,596.32,593.62,596.45z
	 M586.08,282.87c-0.74,0.11-1.25,0.19-1.86,0.28c0.05,0.71,0.09,1.3,0.14,1.99c0.75-0.14,1.34-0.25,2.03-0.38
	C586.29,284.11,586.2,283.59,586.08,282.87z M582.47,281.53c-0.08-0.72-0.14-1.33-0.21-1.99c-0.67,0.06-1.22,0.11-1.79,0.16
	c0,0.68,0,1.28,0,2.04C581.19,281.66,581.79,281.6,582.47,281.53z M546.21,528.31c-0.78,0.08-1.43,0.14-2.11,0.21
	c0.07,0.79,0.13,1.44,0.19,2.14c0.72-0.15,1.31-0.27,2.03-0.42C546.28,529.6,546.25,529,546.21,528.31z M471.37,40.01
	c-0.81,0-1.48,0-2.28,0c0.17,0.74,0.31,1.33,0.48,2.03c0.69-0.08,1.27-0.14,1.9-0.21C471.43,41.1,471.4,40.56,471.37,40.01z
	 M378.02,47.99c-0.64,0.08-1.28,0.16-1.91,0.23c0.08,0.79,0.14,1.39,0.2,2.07c0.7-0.13,1.29-0.24,1.87-0.35
	C378.13,49.26,378.08,48.71,378.02,47.99z M342.49,35.1c0.7-0.07,1.15-0.11,1.76-0.17c-0.04-0.77-0.07-1.42-0.11-2.19
	c-0.7,0.17-1.2,0.29-1.65,0.39C342.49,33.81,342.49,34.33,342.49,35.1z M596.11,437.44c-0.83,0-1.44,0-2.2,0
	c0.11,0.7,0.2,1.3,0.3,1.99c0.73-0.14,1.33-0.25,2.02-0.38C596.19,438.5,596.15,438.04,596.11,437.44z M580.56,299.82
	c-0.08-0.85-0.13-1.46-0.19-2.16c-0.72,0.14-1.31,0.25-1.97,0.38c0.06,0.73,0.11,1.31,0.16,1.94
	C579.27,299.92,579.8,299.88,580.56,299.82z M591.55,284.37c0.86-0.13,1.47-0.22,2.13-0.32c-0.1-0.66-0.18-1.19-0.28-1.84
	c-0.7,0.07-1.29,0.12-1.86,0.17C591.55,283.09,591.55,283.64,591.55,284.37z M585.01,476.39c-0.07-0.83-0.12-1.43-0.17-2.14
	c-0.64,0.11-1.16,0.2-1.74,0.3c0.05,0.73,0.1,1.32,0.14,1.99C583.89,476.48,584.35,476.44,585.01,476.39z M570,550.03
	c-0.12-0.79-0.22-1.4-0.33-2.08c-0.72,0.08-1.31,0.15-2.04,0.23c0.13,0.73,0.23,1.31,0.33,1.86
	C568.66,550.03,569.2,550.03,570,550.03z M543.17,531.91c-0.88,0.24-1.52,0.41-2.17,0.58c0.07,0.72,0.13,1.26,0.18,1.83
	c0.73-0.06,1.35-0.11,1.99-0.16C543.17,533.43,543.17,532.81,543.17,531.91z M546.08,317.32c-0.15-0.77-0.27-1.38-0.41-2.11
	c-0.6,0.08-1.13,0.15-1.78,0.24c0.05,0.76,0.09,1.42,0.13,2.11C544.77,317.47,545.31,317.41,546.08,317.32z M587.1,458.15
	c-0.08-0.88-0.13-1.46-0.19-2.08c-0.74,0.08-1.27,0.14-1.96,0.21c0.08,0.69,0.15,1.29,0.23,1.95
	C585.8,458.21,586.33,458.18,587.1,458.15z M588.69,374.82c0.86-0.05,1.46-0.09,2.19-0.13c-0.13-0.66-0.24-1.18-0.36-1.78
	c-0.68,0.06-1.28,0.11-1.94,0.17C588.61,373.67,588.64,374.12,588.69,374.82z M540.58,297.58c-0.83,0-1.53,0-2.28,0
	c0.16,0.75,0.31,1.43,0.48,2.21c0.73-0.14,1.39-0.27,2.06-0.39C540.74,298.7,540.66,298.16,540.58,297.58z M570.33,557.1
	c-0.09-0.61-0.17-1.2-0.27-1.85c-0.66,0.04-1.2,0.07-1.83,0.11c0.08,0.76,0.15,1.42,0.21,2.1
	C569.15,557.33,569.69,557.23,570.33,557.1z M564.51,530.21c-0.81,0.07-1.43,0.12-2.21,0.18c0.16,0.77,0.3,1.42,0.44,2.12
	c0.69-0.12,1.21-0.21,1.77-0.3C564.51,531.54,564.51,531,564.51,530.21z M561.95,501.66c-0.71,0.17-1.21,0.29-1.78,0.43
	c0.09,0.66,0.16,1.17,0.22,1.66c0.75,0,1.35,0,2,0C562.25,503.08,562.12,502.49,561.95,501.66z M578.05,477.06
	c-0.11-0.78-0.2-1.38-0.29-2.04c-0.72,0.09-1.31,0.17-2.03,0.26c0.12,0.71,0.21,1.23,0.31,1.79
	C576.66,477.06,577.2,477.06,578.05,477.06z M600.06,503.85c0.7-0.05,1.35-0.09,2.08-0.15c-0.14-0.78-0.25-1.36-0.35-1.95
	c-0.74,0.17-1.34,0.31-1.95,0.46C599.92,502.81,599.98,503.27,600.06,503.85z M568.9,396.37c0.09,0.79,0.14,1.31,0.21,1.91
	c0.68-0.09,1.2-0.16,1.81-0.24c-0.05-0.67-0.1-1.21-0.15-1.87C570.14,396.23,569.61,396.29,568.9,396.37z M622.33,614.12
	c-0.09-0.88-0.16-1.48-0.28-2.55c-0.82,0.66-1.38,0.98-1.73,1.45c-0.17,0.23,0.03,0.74,0.06,1.1
	C621.04,614.12,621.58,614.12,622.33,614.12z M570.28,457.41c0.24,0.87,0.39,1.43,0.56,2.05c0.7-0.07,1.23-0.13,1.84-0.19
	c-0.06-0.7-0.11-1.3-0.15-1.85C571.78,457.41,571.16,457.41,570.28,457.41z M583.59,333.45c-0.86,0.11-1.45,0.19-2.18,0.28
	c0.17,0.69,0.3,1.2,0.46,1.82c0.63-0.06,1.21-0.11,1.72-0.16C583.59,334.7,583.59,334.16,583.59,333.45z M590.86,314.94
	c0.04,0.65,0.08,1.27,0.11,1.94c0.8-0.04,1.41-0.07,2.1-0.11c-0.16-0.75-0.28-1.34-0.4-1.93
	C592.02,314.88,591.55,314.91,590.86,314.94z M349.07,27.06c0.67-0.03,1.28-0.06,1.98-0.1c-0.04-0.74-0.06-1.35-0.1-2.03
	c-0.8,0.11-1.47,0.2-2.28,0.31C348.82,25.93,348.93,26.45,349.07,27.06z M599.65,435.34c-0.05-0.82-0.09-1.44-0.13-2.14
	c-0.79,0.08-1.46,0.15-2.34,0.24c0.19,0.71,0.34,1.29,0.5,1.9C598.3,435.34,598.84,435.34,599.65,435.34z M590,533.61
	c-0.07-0.71-0.12-1.24-0.19-1.86c-0.69,0.05-1.28,0.09-1.96,0.14c0.06,0.75,0.11,1.34,0.17,2.01
	C588.69,533.8,589.22,533.72,590,533.61z M531.01,397.77c-0.04-0.71-0.07-1.23-0.1-1.81c-0.75,0-1.42,0-2.16,0
	c0.18,0.74,0.33,1.38,0.5,2.05C529.85,397.93,530.31,397.87,531.01,397.77z M583.6,151.59c0.7-0.14,1.36-0.27,2.13-0.43
	c-0.12-0.68-0.22-1.25-0.34-1.89c-0.72,0.07-1.3,0.13-1.89,0.18C583.54,150.25,583.57,150.86,583.6,151.59z M589.91,473.86
	c0.05,0.76,0.1,1.37,0.15,2.14c0.8-0.22,1.44-0.39,2.08-0.56c-0.09-0.61-0.16-1.07-0.24-1.58
	C591.28,473.86,590.74,473.86,589.91,473.86z M582.56,427.83c0.09,0.59,0.17,1.1,0.26,1.69c0.74-0.06,1.33-0.12,1.98-0.17
	c-0.09-0.76-0.16-1.37-0.23-2.02C583.83,427.51,583.24,427.66,582.56,427.83z M610.05,555.13c-0.89,0.13-1.55,0.23-2.26,0.33
	c0.19,0.71,0.33,1.23,0.48,1.82c0.67-0.05,1.2-0.08,1.78-0.12C610.05,556.51,610.05,555.98,610.05,555.13z M359.64,40.9
	c-0.2-0.79-0.33-1.31-0.48-1.92c-0.63,0.03-1.22,0.06-1.91,0.09c0.07,0.72,0.12,1.31,0.18,1.94
	C358.16,40.98,358.77,40.95,359.64,40.9z M634.68,591.2c0-0.73,0-1.34,0-2.1c-0.71,0.14-1.31,0.25-1.98,0.38
	c0.03,0.62,0.07,1.16,0.1,1.72C633.49,591.2,634.09,591.2,634.68,591.2z M585.3,378.97c0.75-0.13,1.41-0.25,2.22-0.39
	c-0.18-0.67-0.33-1.23-0.49-1.84c-0.68,0.06-1.21,0.11-1.73,0.16C585.3,377.65,585.3,378.26,585.3,378.97z M586.62,264.95
	c0.08,0.69-0.59,1.55,0.57,1.77c0.42,0.08,0.89-0.07,1.42-0.13c-0.1-0.69-0.18-1.22-0.28-1.9
	C587.7,264.79,587.18,264.87,586.62,264.95z M477.27,46.71c0,0.72,0,1.4,0,2.15c0.7-0.1,1.29-0.19,2.03-0.31
	c-0.15-0.74-0.27-1.38-0.4-2.02C478.27,46.6,477.8,46.65,477.27,46.71z M498.17,205.79c0.77-0.19,1.34-0.33,1.91-0.48
	c-0.12-0.62-0.2-1.08-0.31-1.66c-0.62,0.09-1.19,0.17-1.83,0.26C498.01,204.54,498.07,205,498.17,205.79z M581.53,353.63
	c-0.1-0.78-0.16-1.28-0.23-1.86c-0.81,0.07-1.4,0.13-2.02,0.18c0.1,0.75,0.19,1.36,0.29,2.08
	C580.21,353.9,580.79,353.78,581.53,353.63z M592.86,338.25c0.72,0,1.4,0,2.15,0c-0.13-0.71-0.24-1.32-0.36-1.94
	c-0.76,0.1-1.35,0.18-1.99,0.27C592.75,337.26,592.8,337.73,592.86,338.25z M568.92,414.16c-0.79,0.2-1.31,0.33-1.9,0.48
	c0.07,0.64,0.13,1.17,0.19,1.78c0.65-0.08,1.17-0.15,1.71-0.21C568.92,415.51,568.92,414.98,568.92,414.16z M406.72,45.58
	c-0.78,0.11-1.37,0.19-1.97,0.27c0.04,0.7,0.07,1.25,0.1,1.9c0.8-0.04,1.47-0.08,2.3-0.13C407,46.9,406.89,46.38,406.72,45.58z
	 M511.93,426.78c-0.68,0-1.28,0-1.99,0c0.1,0.66,0.18,1.2,0.27,1.84c0.67-0.03,1.27-0.06,2.03-0.09
	C512.11,427.84,512.02,427.32,511.93,426.78z M544.96,537.74c0.88-0.12,1.54-0.21,2.26-0.31c-0.18-0.71-0.31-1.23-0.47-1.84
	c-0.65,0.05-1.25,0.09-1.79,0.14C544.96,536.39,544.96,536.93,544.96,537.74z M566.08,506.8c-0.07-0.77-0.14-1.42-0.21-2.14
	c-0.83,0.14-1.48,0.26-2.19,0.38c0.21,0.81,0.37,1.47,0.54,2.11C564.83,507.03,565.3,506.94,566.08,506.8z M580.28,585.26
	c-0.18-0.81-0.32-1.4-0.47-2.1c-0.66,0.21-1.16,0.37-1.68,0.53c0.07,0.62,0.12,1.07,0.17,1.57
	C578.96,585.26,579.51,585.26,580.28,585.26z M605.89,615.49c0.84-0.08,1.44-0.14,2.2-0.21c-0.13-0.7-0.25-1.29-0.38-1.97
	c-0.67,0.07-1.27,0.13-1.83,0.19C605.89,614.19,605.89,614.74,605.89,615.49z M612.12,538.95c-0.06-0.82-0.1-1.44-0.15-2.11
	c-0.71,0.09-1.32,0.17-2.05,0.27c0.16,0.79,0.29,1.44,0.42,2.1C610.97,539.11,611.44,539.05,612.12,538.95z M590.43,454.26
	c-0.06-0.77-0.12-1.41-0.18-2.09c-1.8,0.46-1.8,0.46-1.54,2.22C589.23,454.35,589.75,454.31,590.43,454.26z M595.54,285.69
	c0.08,0.77,0.14,1.31,0.21,1.96c0.66-0.04,1.24-0.08,1.94-0.13c-0.11-0.79-0.19-1.39-0.29-2.1
	C596.78,285.52,596.25,285.59,595.54,285.69z M635.3,578.4c0.09,0.64,0.19,1.27,0.29,1.96c0.72-0.04,1.25-0.08,1.83-0.11
	c0-0.82,0-1.5,0-2.25C636.73,578.13,636.11,578.24,635.3,578.4z M580.84,200.26c0.07,0.77,0.12,1.37,0.18,2
	c0.74-0.06,1.36-0.12,2.05-0.18c-0.17-0.75-0.3-1.34-0.45-1.99C582.05,200.15,581.59,200.19,580.84,200.26z M555.04,364.8
	c0.18,0.84,0.33,1.5,0.5,2.28c0.65-0.14,1.23-0.26,1.86-0.39c-0.05-0.77-0.09-1.38-0.13-2.04
	C556.55,364.7,555.93,364.74,555.04,364.8z M585.67,550.04c0.24,0.84,0.39,1.4,0.56,2c0.7-0.07,1.22-0.12,1.83-0.18
	c-0.06-0.71-0.11-1.3-0.16-1.94C587.17,549.97,586.55,550,585.67,550.04z M551.95,554.77c-0.06-0.6-0.12-1.18-0.18-1.83
	c-0.72,0.11-1.23,0.18-1.84,0.27c0.13,0.75,0.23,1.34,0.34,1.98C550.91,555.04,551.36,554.92,551.95,554.77z M490.94,38.34
	c0.05,0.57,0.1,1.09,0.16,1.73c0.68-0.05,1.28-0.09,1.99-0.14c-0.09-0.7-0.17-1.28-0.25-1.93C492.14,38.13,491.6,38.22,490.94,38.34
	z M567.34,479.55c-0.83,0.04-1.48,0.07-2.24,0.11c0.17,0.74,0.29,1.26,0.43,1.89c0.66-0.06,1.25-0.12,1.95-0.19
	C567.44,480.75,567.4,480.29,567.34,479.55z M624.97,602.96c-0.07-0.71-0.13-1.31-0.24-2.35c-0.75,0.55-1.32,0.84-1.7,1.29
	c-0.17,0.2,0.05,0.72,0.1,1.22C623.8,603.07,624.33,603.02,624.97,602.96z M575.97,437.5c0.68-0.13,1.29-0.24,1.96-0.36
	c-0.03-0.6-0.06-1.14-0.09-1.72c-0.72,0-1.33,0-2.01,0C575.88,436.13,575.92,436.75,575.97,437.5z M592.9,482.83
	c-0.13-0.83-0.22-1.4-0.29-1.85c-0.78,0-1.37,0-2.15,0c0.18,0.72,0.33,1.29,0.49,1.95C591.58,482.9,592.1,482.87,592.9,482.83z
	 M646.32,598.97c-0.66,0.1-1.24,0.2-1.85,0.29c0.07,0.74,0.12,1.33,0.18,2.05c0.65-0.05,1.17-0.09,1.78-0.14
	C646.4,600.45,646.36,599.78,646.32,598.97z M591.06,569.99c0.81-0.18,1.46-0.33,2.16-0.49c-0.16-0.65-0.29-1.16-0.41-1.64
	c-0.63,0-1.15,0-1.75,0C591.06,568.55,591.06,569.14,591.06,569.99z M429.69,18.05c-0.72,0.13-1.29,0.24-1.99,0.37
	c0.06,0.8,0.12,1.48,0.18,2.26c0.72-0.16,1.3-0.3,1.92-0.44C429.76,19.46,429.73,18.84,429.69,18.05z M585.75,465.59
	c0.78-0.13,1.29-0.21,1.69-0.28c0-0.75,0-1.35,0-2.09c-0.66,0.14-1.17,0.25-1.69,0.36C585.75,464.25,585.75,464.79,585.75,465.59z
	 M571.73,431.97c0.27,0.87,0.44,1.44,0.62,2.05c0.69-0.1,1.21-0.17,1.64-0.24c0-0.69,0-1.22,0-1.81
	C573.26,431.97,572.65,431.97,571.73,431.97z M569.87,450.36c0.2,0.77,0.34,1.29,0.49,1.87c0.68-0.06,1.21-0.11,1.84-0.16
	c-0.06-0.79-0.1-1.44-0.15-2.12C571.27,450.1,570.67,450.21,569.87,450.36z M640.36,613.86c-0.72,0.18-1.29,0.32-1.94,0.48
	c0.1,0.67,0.18,1.19,0.27,1.77c0.64-0.05,1.17-0.09,1.8-0.15C640.44,615.24,640.4,614.65,640.36,613.86z M584.71,165.8
	c0.87-0.14,1.53-0.25,2.3-0.38c-0.17-0.66-0.29-1.17-0.44-1.75c-0.68,0.04-1.28,0.08-1.86,0.12
	C584.71,164.45,584.71,164.98,584.71,165.8z M544.99,263.06c-0.11-0.84-0.19-1.43-0.27-2.09c-0.82,0.13-1.49,0.23-2.21,0.34
	c0.19,0.7,0.33,1.22,0.47,1.75C543.63,263.06,544.17,263.06,544.99,263.06z M535.8,574.5c-0.09-0.81-0.16-1.41-0.24-2.08
	c-0.8,0.1-1.45,0.18-2.18,0.28c0.18,0.71,0.31,1.23,0.45,1.8C534.43,574.5,534.96,574.5,535.8,574.5z M590.04,407.37
	c-0.09-0.86-0.16-1.5-0.24-2.24c-0.78,0.19-1.36,0.33-2.04,0.49c0.13,0.67,0.22,1.2,0.33,1.75
	C588.67,407.37,589.2,407.37,590.04,407.37z M352.56,28.46c0.16,0.76,0.28,1.34,0.42,1.98c0.7-0.05,1.23-0.08,1.9-0.13
	c-0.07-0.79-0.12-1.44-0.18-2.11C353.97,28.29,353.42,28.36,352.56,28.46z M395.21,59.21c0.66-0.05,1.25-0.09,1.93-0.14
	c-0.09-0.73-0.16-1.32-0.23-1.99c-0.78,0.11-1.44,0.2-2.16,0.3C394.92,58.07,395.05,58.59,395.21,59.21z M648.62,609.54
	c0.19,0.79,0.34,1.38,0.5,2.05c0.63-0.08,1.15-0.15,1.83-0.24c-0.04-0.69-0.08-1.29-0.12-1.97
	C650.11,609.44,649.5,609.48,648.62,609.54z M641.49,583.51c-0.09-0.75-0.16-1.36-0.25-2.08c-0.82,0.13-1.49,0.24-2.19,0.36
	c0.13,0.69,0.22,1.21,0.31,1.72C640.11,583.51,640.66,583.51,641.49,583.51z M596.01,292.89c0.1,0.7,0.17,1.21,0.25,1.74
	c0.73,0,1.34,0,2.08,0c-0.12-0.72-0.24-1.37-0.35-2.01C597.32,292.71,596.78,292.79,596.01,292.89z M576.36,484.58
	c0.75-0.15,1.4-0.27,2.17-0.42c-0.09-0.62-0.16-1.15-0.24-1.73c-0.68,0-1.29,0-1.93,0C576.36,483.14,576.36,483.81,576.36,484.58z
	 M542.32,274.06c-0.13-0.76-0.22-1.29-0.33-1.93c-0.75,0.06-1.41,0.11-2.15,0.16c0.17,0.77,0.3,1.36,0.43,1.95
	C540.98,274.18,541.54,274.13,542.32,274.06z M584.16,342.62c-0.07-0.62-0.13-1.2-0.2-1.84c-0.75,0.05-1.35,0.08-2.04,0.13
	c0.15,0.7,0.28,1.28,0.43,1.97C582.99,342.78,583.51,342.71,584.16,342.62z M582,274.35c-0.1-0.87-0.16-1.45-0.24-2.13
	c-0.73,0.19-1.23,0.33-1.81,0.48c0.04,0.63,0.08,1.15,0.11,1.76C580.71,274.42,581.24,274.39,582,274.35z M567.43,566.26
	c-0.73,0.07-1.25,0.13-1.86,0.19c0.07,0.69,0.13,1.27,0.19,1.9c0.73-0.06,1.33-0.12,2.11-0.18
	C567.71,567.49,567.59,566.98,567.43,566.26z M564.34,445.7c-0.13-0.72-0.25-1.36-0.38-2.06c-0.69,0.11-1.2,0.2-1.77,0.29
	c0.03,0.67,0.06,1.19,0.09,1.87C563.01,445.77,563.62,445.74,564.34,445.7z M583.16,561.16c0.15,0.77,0.25,1.29,0.36,1.86
	c0.67-0.04,1.21-0.07,1.75-0.1c0-0.7,0-1.31,0-2.08C584.57,560.95,583.97,561.04,583.16,561.16z M567.43,107.49
	c-0.75,0.1-1.33,0.18-1.93,0.26c0.04,0.69,0.07,1.22,0.1,1.86c0.8-0.04,1.45-0.07,2.28-0.11
	C567.72,108.8,567.61,108.28,567.43,107.49z M594.66,544.26c-0.15-0.68-0.28-1.31-0.43-1.99c-0.7,0.05-1.22,0.1-1.84,0.14
	c0.04,0.71,0.08,1.31,0.12,1.98C593.23,544.34,593.85,544.31,594.66,544.26z M596.16,310.7c-0.76,0.2-1.37,0.36-2.07,0.55
	c0.08,0.58,0.16,1.09,0.23,1.61c0.76,0,1.39,0,2.08,0C596.33,312.18,596.26,311.57,596.16,310.7z M541.49,579.35
	c0.09,0.76,0.17,1.34,0.25,1.97c0.77-0.08,1.38-0.15,2.05-0.22c-0.19-0.73-0.34-1.31-0.5-1.94
	C542.7,579.22,542.23,579.27,541.49,579.35z M596.55,484.33c-0.71,0-1.31,0-2.03,0c0.04,0.73,0.08,1.39,0.12,2.12
	c0.75-0.14,1.34-0.25,2.01-0.38C596.62,485.46,596.59,484.93,596.55,484.33z M604.91,561.25c0.69-0.13,1.26-0.25,1.88-0.37
	c-0.08-0.65-0.14-1.1-0.2-1.63c-0.73,0.05-1.33,0.09-2.05,0.14C604.67,560.06,604.77,560.59,604.91,561.25z M594.11,583.84
	c0-0.78,0-1.36,0-2.07c-0.66,0.12-1.17,0.22-1.79,0.33c0.12,0.71,0.22,1.28,0.33,1.89C593.19,583.93,593.57,583.89,594.11,583.84z
	 M481.41,156.48c-0.15-0.7-0.25-1.21-0.38-1.81c-0.67,0.1-1.24,0.19-1.87,0.29c0.09,0.73,0.15,1.3,0.23,1.92
	C480.1,156.74,480.63,156.63,481.41,156.48z M592.69,562.51c-0.14-0.75-0.27-1.42-0.41-2.1c-0.71,0.1-1.22,0.17-1.83,0.25
	c0.08,0.74,0.15,1.31,0.21,1.85C591.35,562.51,591.9,562.51,592.69,562.51z M572.77,544.01c-0.76,0.12-1.29,0.2-1.91,0.3
	c0.16,0.71,0.28,1.28,0.41,1.86c0.71-0.07,1.22-0.12,1.8-0.18C572.97,545.28,572.89,544.75,572.77,544.01z M630.76,587.87
	c-0.05-0.71-0.1-1.35-0.16-2.04c-0.7,0.09-1.23,0.15-1.91,0.24c0.15,0.74,0.27,1.36,0.4,2
	C629.72,587.99,630.18,587.93,630.76,587.87z M585.86,259.71c0.77-0.19,1.4-0.35,2.04-0.51c-0.17-0.67-0.28-1.12-0.42-1.69
	c-0.62,0.11-1.14,0.2-1.72,0.3C585.79,258.45,585.82,258.99,585.86,259.71z M590.55,580.62c-0.15-0.77-0.25-1.28-0.37-1.84
	c-0.66,0.04-1.2,0.06-1.84,0.1c0.04,0.69,0.08,1.3,0.13,2.06C589.16,580.83,589.76,580.74,590.55,580.62z M510.58,560.15
	c0.14,0.86,0.24,1.54,0.36,2.32c0.74-0.25,1.31-0.44,1.91-0.65c-0.12-0.67-0.21-1.19-0.29-1.67
	C511.9,560.15,511.35,560.15,510.58,560.15z M588.01,367.9c0.71-0.12,1.35-0.23,2.08-0.35c-0.11-0.68-0.2-1.21-0.31-1.82
	c-0.67,0.06-1.25,0.11-1.87,0.16C587.95,366.62,587.98,367.17,588.01,367.9z M495.43,487.55c-0.18-1.65-0.18-1.65-1.98-1.38
	c0.05,0.52,0.11,1.05,0.18,1.7C494.23,487.76,494.75,487.67,495.43,487.55z M565.92,546.64c0-0.81,0-1.43,0-2.19
	c-0.75,0.18-1.41,0.34-2.09,0.51c0.12,0.67,0.21,1.18,0.3,1.68C564.78,546.64,565.25,546.64,565.92,546.64z M606.39,575.45
	c0.63-0.05,1.15-0.1,1.76-0.15c-0.05-0.7-0.1-1.29-0.15-1.93c-0.73,0.09-1.33,0.17-2.01,0.26
	C606.14,574.34,606.25,574.85,606.39,575.45z M580.55,447.67c0.72-0.05,1.32-0.1,2.02-0.15c-0.14-0.64-0.26-1.14-0.38-1.7
	c-0.7,0.09-1.22,0.15-1.79,0.22C580.46,446.64,580.5,447.09,580.55,447.67z M588.42,596.87c-0.8,0.14-1.38,0.24-2.03,0.35
	c0.12,0.71,0.21,1.22,0.31,1.79c0.7-0.05,1.24-0.09,1.85-0.13C588.51,598.23,588.47,597.69,588.42,596.87z M573.15,446.42
	c0.06,0.85,0.1,1.45,0.15,2.15c0.73-0.16,1.33-0.28,2.01-0.43c-0.09-0.66-0.16-1.18-0.24-1.72
	C574.44,446.42,573.91,446.42,573.15,446.42z M635.03,596.54c-0.67,0.08-1.24,0.15-1.83,0.21c0.04,0.71,0.06,1.25,0.1,1.88
	c0.68-0.1,1.26-0.18,1.96-0.28C635.17,597.69,635.1,597.17,635.03,596.54z M469.68,128.72c0.75-0.12,1.4-0.22,2.05-0.32
	c-0.37-1.66-0.37-1.66-2.19-1.38C469.59,127.52,469.63,128.04,469.68,128.72z M631.32,616.86c0.76-0.08,1.35-0.15,2.06-0.23
	c-0.1-0.7-0.18-1.22-0.27-1.85c-0.67,0.07-1.25,0.14-1.79,0.2C631.32,615.62,631.32,616.09,631.32,616.86z M456.44,33.94
	c-0.73,0.15-1.39,0.28-2.09,0.43c0.12,0.68,0.21,1.2,0.32,1.82c0.7-0.05,1.3-0.1,1.96-0.15C456.56,35.3,456.51,34.68,456.44,33.94z
	 M556.79,444.47c-0.61,0.08-1.12,0.15-1.68,0.22c0.03,0.63,0.05,1.16,0.08,1.79c0.72-0.07,1.3-0.13,2.01-0.21
	C557.05,445.6,556.93,445.1,556.79,444.47z M560.8,487.16c-0.75,0.22-1.26,0.37-1.88,0.56c0.09,0.58,0.17,1.09,0.25,1.62
	c0.7-0.05,1.22-0.09,1.76-0.13C560.89,488.48,560.85,487.94,560.8,487.16z M587.39,544.63c-0.13-0.75-0.23-1.27-0.33-1.86
	c-0.71,0.06-1.31,0.11-1.95,0.16c0.07,0.74,0.13,1.36,0.2,2.06C585.98,544.88,586.58,544.78,587.39,544.63z M545.27,84.14
	c0.09,0.7,0.17,1.26,0.25,1.86c0.78-0.06,1.39-0.11,2.12-0.16c-0.13-0.63-0.23-1.15-0.34-1.7
	C546.65,84.14,546.05,84.14,545.27,84.14z M598.72,489.67c0.73-0.07,1.31-0.13,2.01-0.21c-0.17-0.75-0.3-1.31-0.43-1.88
	c-0.62,0.05-1.07,0.09-1.58,0.13C598.72,488.33,598.72,488.85,598.72,489.67z M540.58,545.17c-0.06-0.86-0.1-1.46-0.15-2.15
	c-0.77,0.17-1.43,0.32-2.1,0.48c0.14,0.65,0.25,1.16,0.37,1.67C539.34,545.17,539.86,545.17,540.58,545.17z M580.36,424.25
	c-0.69,0.06-1.27,0.11-1.9,0.16c0.04,0.74,0.08,1.36,0.12,2.05c0.74-0.15,1.39-0.28,2.1-0.42
	C580.56,425.38,580.47,424.87,580.36,424.25z M583.23,581.1c-0.08-0.65-0.15-1.23-0.23-1.85c-0.71,0.04-1.26,0.07-1.91,0.1
	c0.11,0.69,0.21,1.28,0.33,2.01C582.06,581.27,582.58,581.19,583.23,581.1z M585.17,144.06c-0.06-0.82-0.1-1.46-0.15-2.17
	c-0.77,0.16-1.36,0.28-2.06,0.42c0.13,0.68,0.23,1.24,0.35,1.85C583.96,144.12,584.42,144.1,585.17,144.06z M515.41,424.36
	c0-0.74,0-1.27,0-1.93c-0.75,0.13-1.41,0.24-2.16,0.37c0.2,0.68,0.35,1.19,0.52,1.77C514.34,424.5,514.8,424.44,515.41,424.36z
	 M591.03,607.62c0.07,0.58,0.14,1.15,0.23,1.81c0.66-0.11,1.16-0.19,1.73-0.28c-0.06-0.67-0.12-1.19-0.18-1.89
	C592.18,607.38,591.67,607.49,591.03,607.62z M560.74,447.41c-0.64,0.12-1.16,0.21-1.81,0.33c0.13,0.67,0.24,1.25,0.38,1.94
	c0.59-0.07,1.1-0.13,1.66-0.2C560.89,448.72,560.82,448.12,560.74,447.41z M582.83,573.95c-0.13-0.77-0.22-1.28-0.33-1.89
	c-0.72,0.17-1.29,0.3-1.96,0.46c0.14,0.58,0.25,1.01,0.35,1.43C581.51,573.95,582.03,573.95,582.83,573.95z M569.45,445.1
	c0.82-0.13,1.45-0.23,2.1-0.33c-0.12-0.66-0.21-1.11-0.31-1.65c-0.63,0.05-1.22,0.09-1.87,0.14
	C569.4,443.84,569.42,444.3,569.45,445.1z M353.96,44.91c0.76-0.07,1.38-0.13,2.17-0.21c-0.13-0.72-0.24-1.29-0.36-1.94
	c-0.69,0.05-1.23,0.09-1.81,0.14C353.96,43.55,353.96,44.09,353.96,44.91z M563.03,389.58c-0.71,0.11-1.17,0.19-1.73,0.28
	c0.06,0.59,0.1,1.12,0.16,1.75c0.69-0.04,1.27-0.07,1.9-0.11C563.24,390.81,563.15,390.28,563.03,389.58z M578.95,489.59
	c-1.9,0.07-1.9,0.07-1.65,1.82c0.65-0.05,1.3-0.1,2.02-0.16C579.18,490.62,579.08,490.17,578.95,489.59z M589.34,340.39
	c0.07,0.72,0.12,1.24,0.17,1.77c0.72,0,1.31,0,2.06,0c-0.14-0.7-0.25-1.28-0.37-1.86C590.58,340.33,590.11,340.36,589.34,340.39z
	 M576.5,442.57c0.09,0.77,0.17,1.35,0.24,1.99c0.7-0.06,1.24-0.11,1.94-0.18c-0.1-0.68-0.19-1.25-0.28-1.81
	C577.76,442.57,577.29,442.57,576.5,442.57z M348.58,43.42c-0.65,0.08-1.24,0.16-1.89,0.24c0.05,0.7,0.09,1.24,0.14,1.89
	c0.74-0.18,1.4-0.35,2.06-0.52C348.78,44.44,348.69,43.98,348.58,43.42z M490.4,177.26c-0.1-0.52-0.19-1.02-0.3-1.62
	c-0.71,0.09-1.29,0.16-1.94,0.23c0.09,0.68,0.16,1.2,0.23,1.77c0.48-0.05,0.87-0.07,1.26-0.14
	C489.87,177.48,490.08,177.37,490.4,177.26z M615.09,596.43c1.83-0.3,1.83-0.3,1.66-2.05c-0.58,0.04-1.16,0.09-1.85,0.14
	C614.97,595.21,615.02,595.73,615.09,596.43z M541.59,539.49c0,0.85,0,1.47,0,2.19c0.75-0.15,1.41-0.28,2.14-0.43
	c-0.12-0.69-0.21-1.21-0.31-1.77C542.8,539.49,542.27,539.49,541.59,539.49z M577.55,596.27c-0.13-0.79-0.22-1.3-0.38-2.21
	c-0.68,0.53-1.22,0.82-1.57,1.27c-0.14,0.18,0.1,0.68,0.17,1.04C576.38,596.34,576.83,596.31,577.55,596.27z M588.59,539.05
	c0.03,0.7,0.06,1.29,0.09,2.02c0.77-0.12,1.41-0.23,2.09-0.34c-0.13-0.7-0.22-1.21-0.33-1.8
	C589.76,538.98,589.23,539.01,588.59,539.05z M612.1,600.42c0.67-0.05,1.25-0.09,1.93-0.15c-0.13-0.71-0.23-1.28-0.34-1.9
	c-0.69,0.1-1.2,0.17-1.81,0.26C611.96,599.28,612.03,599.8,612.1,600.42z M578.03,126.36c0.63-0.03,1.21-0.06,1.91-0.09
	c-0.07-0.68-0.12-1.19-0.19-1.84c-0.75,0.11-1.39,0.21-2.11,0.32C577.79,125.38,577.9,125.83,578.03,126.36z M569.64,423.63
	c-0.16-0.72-0.3-1.35-0.45-1.99c-0.65,0.1-1.1,0.17-1.67,0.26c0.07,0.64,0.13,1.21,0.19,1.83
	C568.41,423.7,568.95,423.67,569.64,423.63z M584.51,261.11c-0.72,0.55-1.84-0.09-1.79,1.28c0.01,0.3,0.1,0.6,0.17,1.01
	c0.63-0.09,1.14-0.17,1.62-0.24C584.51,262.49,584.51,261.96,584.51,261.11z M597.2,491.43c-0.77,0.08-1.34,0.14-1.96,0.2
	c0.03,0.68,0.06,1.21,0.09,1.88c0.76-0.09,1.4-0.17,2.06-0.25C597.33,492.61,597.28,492.15,597.2,491.43z M588.22,286.2
	c0.16,0.72,0.28,1.23,0.41,1.84c0.61-0.04,1.14-0.07,1.77-0.1c-0.05-0.71-0.09-1.3-0.14-1.94
	C589.59,286.07,589.04,286.12,588.22,286.2z M595.77,453.67c0.61-0.06,1.13-0.12,1.74-0.18c-0.07-0.73-0.13-1.32-0.2-1.98
	c-0.75,0.09-1.34,0.16-1.99,0.24C595.5,452.51,595.62,453.04,595.77,453.67z M558.01,540.18c-0.1-0.74-0.17-1.25-0.26-1.89
	c-0.64,0.08-1.16,0.15-1.69,0.22c0,0.6,0,1.13,0,1.77C556.67,540.24,557.19,540.22,558.01,540.18z M548.46,286.15
	c0.1,0.67,0.19,1.26,0.29,1.96c0.71-0.07,1.3-0.13,1.99-0.19c-0.13-0.74-0.23-1.32-0.34-1.95
	C549.69,286.03,549.15,286.09,548.46,286.15z M599.93,480.37c-0.79,0.08-1.38,0.15-2,0.21c0.07,0.65,0.13,1.17,0.19,1.71
	c0.73,0,1.33,0,2.07,0C600.11,481.67,600.04,481.15,599.93,480.37z M419.1,44.49c0.09,0.75,0.15,1.28,0.22,1.91
	c0.75-0.13,1.4-0.24,2.08-0.36c-0.12-0.68-0.21-1.19-0.32-1.8C420.39,44.33,419.87,44.39,419.1,44.49z M589.87,573.46
	c0-0.72,0-1.32,0-2.06c-0.74,0.15-1.38,0.29-2.06,0.43c0.12,0.67,0.2,1.18,0.3,1.76C588.8,573.54,589.32,573.5,589.87,573.46z
	 M502.05,185.42c-0.76,0.08-1.4,0.14-2.14,0.22c0.17,0.66,0.3,1.15,0.44,1.69c0.68-0.04,1.19-0.06,1.84-0.1
	C502.14,186.57,502.1,186.06,502.05,185.42z M631.67,577.04c0.62-0.07,1.15-0.12,1.66-0.18c0-0.73,0-1.4,0-2.15
	c-0.74,0.13-1.33,0.23-1.97,0.35C631.49,575.81,631.57,576.34,631.67,577.04z M598.15,440.68c0.04,0.69,0.07,1.28,0.11,2.02
	c0.7-0.09,1.26-0.17,1.89-0.26c-0.07-0.76-0.12-1.35-0.18-2C599.31,440.53,598.8,440.6,598.15,440.68z M542.5,548.6
	c0.67-0.05,1.26-0.09,1.94-0.14c-0.09-0.72-0.16-1.3-0.24-1.93c-0.74,0.08-1.33,0.14-2.01,0.21
	C542.31,547.46,542.4,547.98,542.5,548.6z M495.38,507.33c0.04,0.79,0.07,1.41,0.1,2.15c0.73-0.12,1.37-0.23,2.06-0.35
	c-0.09-0.71-0.16-1.23-0.23-1.8C496.71,507.33,496.17,507.33,495.38,507.33z M639.26,599.71c-0.84,0.21-1.4,0.35-1.98,0.49
	c0.13,0.61,0.22,1.03,0.32,1.53c0.66-0.09,1.17-0.16,1.77-0.24C639.33,600.9,639.31,600.45,639.26,599.71z M650.48,604.23
	c-0.06-0.71-0.12-1.3-0.18-2c-0.76,0.14-1.41,0.27-2.15,0.41c0.17,0.65,0.3,1.14,0.45,1.7C649.25,604.3,649.78,604.27,650.48,604.23
	z M630.1,600.51c0.1,0.74,0.17,1.25,0.25,1.85c0.65-0.05,1.17-0.08,1.78-0.13c-0.04-0.71-0.07-1.31-0.1-1.96
	C631.4,600.34,630.86,600.41,630.1,600.51z M583.49,375.3c-0.12-0.78-0.2-1.3-0.29-1.91c-0.67,0.04-1.21,0.07-1.86,0.1
	c0.05,0.71,0.1,1.31,0.14,1.95C582.17,375.39,582.72,375.35,583.49,375.3z M531.12,564.17c-0.09-0.65-0.18-1.24-0.27-1.87
	c-0.66,0.07-1.12,0.13-1.62,0.18c0,0.67,0,1.26,0,1.94C529.91,564.34,530.45,564.26,531.12,564.17z M593.88,537.03
	c-0.11-0.8-0.18-1.39-0.27-2c-0.62,0.07-1.08,0.11-1.68,0.18c0.04,0.69,0.08,1.29,0.13,1.97
	C592.67,537.13,593.14,537.09,593.88,537.03z M350.7,46.73c0.07,0.84,0.13,1.45,0.19,2.17c0.67-0.12,1.26-0.23,1.89-0.35
	c-0.09-0.71-0.17-1.31-0.26-1.99C351.9,46.62,351.37,46.67,350.7,46.73z M652.84,612.92c0.08,0.81,0.14,1.46,0.21,2.16
	c0.7-0.14,1.22-0.24,1.79-0.36c-0.07-0.79-0.13-1.41-0.19-2.06C654.06,612.75,653.6,612.81,652.84,612.92z M397.45,46.21
	c0.11,0.81,0.19,1.35,0.27,1.96c0.68-0.04,1.28-0.08,2.06-0.13c-0.13-0.7-0.24-1.28-0.37-1.93
	C398.75,46.15,398.22,46.17,397.45,46.21z M561.48,496.37c-0.08-0.74-0.14-1.25-0.21-1.87c-0.7,0.14-1.23,0.24-1.82,0.36
	c0.03,0.61,0.06,1.14,0.09,1.79C560.18,496.56,560.71,496.48,561.48,496.37z M591.95,497.44c0.84-0.12,1.44-0.2,2.1-0.3
	c-0.11-0.74-0.19-1.27-0.28-1.9c-0.69,0.07-1.29,0.12-1.82,0.18C591.95,496.13,591.95,496.67,591.95,497.44z M586.88,145.55
	c0.09,0.61,0.17,1.18,0.26,1.83c0.72-0.08,1.3-0.15,1.97-0.22c-0.11-0.7-0.19-1.21-0.28-1.75
	C588.15,145.45,587.6,145.49,586.88,145.55z M351.95,39.44c-0.68,0.04-1.28,0.08-1.98,0.12c0.11,0.71,0.21,1.29,0.31,1.95
	c0.71-0.08,1.3-0.15,1.95-0.22C352.12,40.56,352.04,40.04,351.95,39.44z M581.07,346.53c-0.12-0.75-0.2-1.26-0.29-1.87
	c-0.68,0.12-1.2,0.22-1.84,0.34c0.04,0.59,0.08,1.11,0.12,1.68C579.74,346.62,580.28,346.58,581.07,346.53z M468.51,32.76
	c0.08,0.81,0.14,1.43,0.21,2.16c0.72-0.15,1.37-0.28,2.07-0.42c-0.12-0.67-0.22-1.19-0.32-1.74
	C469.83,32.76,469.3,32.76,468.51,32.76z M385.06,47.55c-0.64,0.04-1.23,0.08-1.92,0.13c0.06,0.7,0.11,1.23,0.17,1.91
	c0.75-0.14,1.39-0.26,2.11-0.39C385.28,48.56,385.18,48.1,385.06,47.55z M605.38,608.25c0.7-0.12,1.25-0.21,1.88-0.31
	c-0.1-0.71-0.17-1.22-0.24-1.78c-0.65,0.03-1.17,0.06-1.79,0.08C605.28,606.94,605.32,607.53,605.38,608.25z M422.92,47.65
	c0.09,0.87,0.16,1.46,0.22,2.12c0.74-0.1,1.34-0.17,2.1-0.27c-0.14-0.71-0.26-1.28-0.39-1.94C424.2,47.59,423.67,47.61,422.92,47.65
	z M546.72,282.44c-0.74,0.16-1.41,0.3-2.14,0.46c0.12,0.68,0.21,1.21,0.32,1.83c0.69-0.06,1.29-0.12,1.91-0.18
	C546.78,283.79,546.75,283.16,546.72,282.44z M627.38,589.94c-0.65,0.06-1.23,0.11-1.91,0.18c0.07,0.65,0.13,1.18,0.19,1.76
	c0.71-0.06,1.3-0.11,2.05-0.17C627.6,591.05,627.5,590.54,627.38,589.94z M571.14,564.24c-0.13-0.64-0.23-1.14-0.36-1.76
	c-0.63,0.04-1.21,0.07-1.9,0.11c0.08,0.66,0.14,1.17,0.21,1.74C569.8,564.3,570.4,564.27,571.14,564.24z M548.25,531.86
	c0.07,0.6,0.13,1.18,0.2,1.79c0.76-0.05,1.34-0.09,2.01-0.14c-0.14-0.74-0.24-1.27-0.37-1.91
	C549.46,531.69,548.93,531.76,548.25,531.86z M579.44,203.95c-0.8,0.09-1.38,0.16-2.04,0.23c0.09,0.73,0.16,1.31,0.23,1.94
	c0.73-0.08,1.33-0.14,2.06-0.22C579.6,205.24,579.53,204.72,579.44,203.95z M604.64,599.05c0.09,0.6,0.18,1.17,0.29,1.85
	c0.64-0.04,1.16-0.08,1.78-0.12c-0.05-0.71-0.09-1.31-0.13-1.96C605.91,598.9,605.37,598.96,604.64,599.05z M420.35,76.68
	c-0.75,0.13-1.39,0.24-2.09,0.36c0.17,0.71,0.3,1.23,0.45,1.86c0.6-0.05,1.12-0.1,1.63-0.15C420.35,78.04,420.35,77.44,420.35,76.68
	z M472.88,38.15c0.78-0.11,1.34-0.19,1.94-0.28c-0.05-0.76-0.09-1.36-0.13-2.07c-0.77,0.2-1.42,0.36-2.04,0.53
	C472.72,36.95,472.78,37.41,472.88,38.15z M576.98,364.82c1.89-0.1,1.89-0.1,1.63-1.84c-0.63,0.05-1.28,0.11-2,0.17
	C576.75,363.79,576.86,364.24,576.98,364.82z M563.44,498.02c0.08,0.63,0.15,1.15,0.24,1.78c0.69-0.08,1.27-0.15,1.93-0.23
	c-0.07-0.71-0.13-1.25-0.19-1.89C564.73,497.8,564.14,497.9,563.44,498.02z M588.09,385.89c-0.06-0.64-0.12-1.26-0.19-1.99
	c-0.75,0.1-1.34,0.17-1.95,0.25c0.07,0.66,0.12,1.18,0.18,1.74C586.82,385.89,587.35,385.89,588.09,385.89z M579.26,265.42
	c0.11,0.75,0.19,1.27,0.28,1.86c0.66-0.12,1.17-0.21,1.77-0.32c-0.09-0.68-0.17-1.21-0.25-1.78
	C580.47,265.26,580,265.33,579.26,265.42z M609.25,571.76c0.74-0.1,1.34-0.17,2.1-0.27c-0.12-0.68-0.22-1.23-0.33-1.85
	c-0.69,0.06-1.21,0.1-1.78,0.15C609.25,570.41,609.25,570.94,609.25,571.76z M596.11,476.84c-0.82,0.15-1.42,0.26-2.1,0.39
	c0.11,0.67,0.2,1.19,0.31,1.82c0.67-0.07,1.26-0.14,1.79-0.19C596.11,478.16,596.11,477.61,596.11,476.84z M590.85,332.95
	c-0.74,0.16-1.37,0.29-2,0.43c0.09,0.66,0.15,1.12,0.22,1.69c0.67-0.06,1.25-0.12,1.78-0.16
	C590.85,334.2,590.85,333.66,590.85,332.95z M597.64,498.66c-0.66,0.05-1.26,0.09-1.92,0.14c0.05,0.74,0.09,1.36,0.13,2.04
	c0.71-0.13,1.31-0.25,2.04-0.39C597.8,499.82,597.73,499.3,597.64,498.66z M401.41,49.57c0.05,0.83,0.09,1.48,0.13,2.21
	c0.76-0.17,1.35-0.3,2.08-0.46c-0.13-0.66-0.24-1.22-0.37-1.83C402.62,49.51,402.16,49.53,401.41,49.57z M643.35,565.22
	c-0.08-0.69-0.15-1.29-0.23-2.01c-0.69,0.12-1.22,0.2-1.86,0.31c0.08,0.66,0.15,1.24,0.23,1.86
	C642.2,565.33,642.74,565.28,643.35,565.22z M564.52,572.05c-0.07-0.8-0.12-1.34-0.17-1.94c-0.66,0.07-1.2,0.13-1.87,0.2
	c0.05,0.62,0.1,1.16,0.15,1.74C563.23,572.05,563.77,572.05,564.52,572.05z M603.37,478.37c-0.12-0.74-0.2-1.26-0.3-1.89
	c-0.61,0.07-1.12,0.13-1.79,0.21c0.11,0.65,0.2,1.21,0.29,1.79C602.21,478.44,602.66,478.41,603.37,478.37z M533.74,218.74
	c-0.77,0.12-1.28,0.19-1.89,0.28c0.05,0.63,0.09,1.16,0.14,1.79c0.68-0.05,1.27-0.1,1.91-0.15
	C533.84,220.02,533.8,219.49,533.74,218.74z M578.87,138.81c0.08,0.8,0.13,1.43,0.2,2.17c0.72-0.15,1.37-0.29,2.07-0.44
	c-0.11-0.67-0.2-1.19-0.28-1.73C580.2,138.81,579.66,138.81,578.87,138.81z M578.59,218.5c0.14,0.77,0.24,1.3,0.36,1.92
	c0.67-0.03,1.26-0.06,2-0.09c-0.13-0.74-0.23-1.31-0.32-1.82C579.93,218.5,579.39,218.5,578.59,218.5z M378.33,31.94
	c0.78-0.11,1.36-0.19,2-0.28c-0.07-0.76-0.13-1.36-0.19-2.02c-0.75,0.09-1.36,0.17-2.1,0.27C378.13,30.57,378.21,31.1,378.33,31.94z
	 M577.38,427.87c-0.76,0.18-1.42,0.34-2.11,0.5c0.13,0.67,0.23,1.18,0.35,1.76c0.71-0.07,1.24-0.12,1.76-0.17
	C577.38,429.22,577.38,428.6,577.38,427.87z M578.09,379.39c0.81-0.14,1.39-0.24,2.03-0.35c-0.11-0.71-0.19-1.23-0.28-1.83
	c-0.71,0.07-1.3,0.12-1.93,0.18C577.97,378.06,578.02,378.61,578.09,379.39z M595.91,458.92c0.13,0.71,0.24,1.28,0.36,1.93
	c0.69-0.05,1.22-0.08,1.85-0.13c-0.04-0.71-0.07-1.32-0.1-1.98C597.3,458.8,596.69,458.86,595.91,458.92z M629.41,595.19
	c0.7-0.06,1.29-0.11,2.01-0.17c-0.08-0.68-0.15-1.21-0.22-1.85c-0.66,0.05-1.25,0.1-1.89,0.15
	C629.35,594.01,629.38,594.55,629.41,595.19z M618.34,569.21c-1.95,0.07-1.95,0.07-1.65,1.79c0.61-0.05,1.22-0.1,1.91-0.15
	C618.51,570.27,618.44,569.81,618.34,569.21z M587.29,400.1c0.63-0.04,1.22-0.08,1.93-0.12c-0.11-0.73-0.19-1.31-0.28-1.92
	c-0.68,0.09-1.21,0.15-1.83,0.23C587.17,398.91,587.22,399.45,587.29,400.1z M558.82,529.12c0.65,0,1.25,0,1.99,0
	c-0.09-0.65-0.17-1.24-0.27-1.98c-0.67,0.14-1.24,0.26-1.83,0.38C558.75,528.14,558.78,528.6,558.82,529.12z M500.55,192.61
	c0.2,0.83,0.33,1.4,0.48,2.04c0.66-0.14,1.16-0.25,1.66-0.36c-0.04-0.69-0.07-1.22-0.11-1.86
	C501.89,192.49,501.37,192.54,500.55,192.61z M282.91,76.38c-0.04-0.71-0.07-1.31-0.11-2.05c-0.7,0.1-1.26,0.19-1.88,0.28
	c0.08,0.77,0.13,1.37,0.2,2.01C281.75,76.54,282.27,76.47,282.91,76.38z M514.89,563.54c0,0.82,0,1.42,0,2.16
	c0.68-0.17,1.23-0.31,1.81-0.46c-0.07-0.68-0.13-1.19-0.19-1.77C515.89,563.49,515.44,563.51,514.89,563.54z M609.65,595.06
	c-0.76,0.08-1.29,0.13-1.89,0.19c0.05,0.71,0.09,1.31,0.14,1.98c0.72-0.13,1.31-0.24,1.92-0.35
	C609.75,596.22,609.71,595.76,609.65,595.06z M538.21,585.3c0.84-0.14,1.44-0.24,2.09-0.35c-0.11-0.72-0.19-1.24-0.29-1.88
	c-0.69,0.07-1.28,0.13-1.81,0.19C538.21,583.97,538.21,584.52,538.21,585.3z M527.86,399.93c-0.58,0-0.98-0.02-1.36,0.01
	c-0.22,0.02-0.44,0.13-0.65,0.21c0.32,1.62,0.32,1.62,2.1,1.34C527.92,401.05,527.9,400.6,527.86,399.93z M540.62,527.05
	c0.66-0.07,1.24-0.14,1.9-0.22c-0.12-0.7-0.2-1.22-0.3-1.82c-0.74,0.07-1.35,0.14-2.03,0.2
	C540.35,525.87,540.47,526.4,540.62,527.05z M625.51,610.12c-0.11-0.72-0.19-1.24-0.29-1.85c-0.64,0.1-1.15,0.18-1.78,0.28
	c0.08,0.68,0.16,1.26,0.23,1.86C624.31,610.32,624.77,610.24,625.51,610.12z M586.57,329.75c-0.66,0.07-1.24,0.13-1.76,0.18
	c0,0.71,0,1.25,0,1.91c0.67-0.09,1.26-0.18,1.97-0.28C586.71,330.92,586.65,330.4,586.57,329.75z M589.5,526.46
	c-0.06-0.82-0.11-1.43-0.17-2.14c-0.78,0.18-1.43,0.34-2.11,0.5c0.17,0.68,0.3,1.19,0.43,1.73
	C588.32,526.52,588.78,526.5,589.5,526.46z M593.02,549.66c0.14,0.7,0.25,1.27,0.38,1.93c0.74-0.1,1.31-0.18,1.94-0.26
	c-0.09-0.75-0.17-1.35-0.25-2.02C594.36,549.43,593.77,549.53,593.02,549.66z M405.41,53.03c0.14,0.65,0.24,1.16,0.36,1.73
	c0.66-0.06,1.2-0.1,1.84-0.16c-0.05-0.7-0.1-1.29-0.15-1.95C406.73,52.79,406.13,52.9,405.41,53.03z M594.63,524.19
	c0.05,0.61,0.1,1.2,0.16,1.9c0.71-0.06,1.31-0.1,1.98-0.16c-0.09-0.73-0.16-1.33-0.25-2.01
	C595.86,524.02,595.31,524.1,594.63,524.19z M602.94,579.72c0.81-0.13,1.32-0.21,1.94-0.31c-0.16-0.7-0.28-1.21-0.42-1.79
	c-0.58,0.07-1.04,0.12-1.52,0.18C602.94,578.4,602.94,578.93,602.94,579.72z M545.32,268.18c-0.75,0.09-1.28,0.16-1.91,0.23
	c0.09,0.79,0.15,1.39,0.22,2.02c0.67-0.08,1.2-0.14,1.79-0.22C545.39,269.49,545.36,268.95,545.32,268.18z M397.21,41.02
	c0.65-0.06,1.17-0.11,1.81-0.17c-0.04-0.72-0.08-1.32-0.12-2.05c-0.75,0.13-1.33,0.24-1.91,0.34
	C397.07,39.83,397.14,40.37,397.21,41.02z M597.28,475.42c0.77-0.18,1.44-0.33,2.15-0.49c-0.15-0.66-0.26-1.18-0.39-1.76
	c-0.7,0.06-1.23,0.11-1.76,0.15C597.28,474.07,597.28,474.69,597.28,475.42z M594.91,612.83c0.82-0.05,1.41-0.09,2.09-0.14
	c-0.13-0.73-0.23-1.31-0.36-2.01c-0.67,0.1-1.19,0.18-1.73,0.26C594.91,611.54,594.91,612.07,594.91,612.83z M585.14,270.41
	c-0.11-0.74-0.19-1.3-0.27-1.91c-0.64,0.12-1.1,0.2-1.67,0.31c0.04,0.63,0.07,1.21,0.11,1.87
	C583.92,270.6,584.37,270.53,585.14,270.41z M585.57,402.22c-0.69,0.04-1.14,0.06-1.67,0.09c0,0.72,0,1.32,0,2
	c0.68-0.09,1.28-0.17,2.04-0.28C585.81,403.41,585.71,402.9,585.57,402.22z M478.02,34.08c-0.13-0.73-0.22-1.25-0.33-1.89
	c-0.68,0.06-1.28,0.1-1.83,0.15c0,0.77,0,1.4,0,2.11C476.54,34.34,477.15,34.23,478.02,34.08z M590.08,241.22
	c-0.07-0.68-0.14-1.33-0.2-1.97c-1.63-0.11-2.22,0.46-1.7,1.97C588.77,241.22,589.38,241.22,590.08,241.22z M564.34,113.27
	c-0.11-0.72-0.2-1.24-0.3-1.87c-0.68,0.06-1.28,0.12-1.83,0.17c0,0.76,0,1.39,0,2.1C562.89,113.54,563.49,113.43,564.34,113.27z
	 M628.03,598.85c-0.07-0.64-0.13-1.21-0.2-1.89c-0.71,0.07-1.29,0.13-1.93,0.2c0.11,0.72,0.19,1.3,0.29,1.95
	C626.85,599.01,627.38,598.94,628.03,598.85z M590.56,522.9c0.69-0.11,1.3-0.22,2.08-0.35c-0.12-0.68-0.21-1.24-0.32-1.88
	c-0.7,0.07-1.23,0.11-1.75,0.16C590.56,521.58,590.56,522.2,590.56,522.9z M569.97,405.43c1.82-0.2,1.82-0.2,1.32-2.1
	C569.92,403.31,569.41,403.92,569.97,405.43z M599.59,520.23c-0.72,0.07-1.3,0.13-1.95,0.2c0.09,0.66,0.16,1.17,0.25,1.79
	c0.7-0.15,1.27-0.26,1.9-0.4C599.73,521.31,599.68,520.93,599.59,520.23z M552.93,522.4c-0.09-0.69-0.17-1.27-0.25-1.92
	c-0.71,0.16-1.22,0.27-1.76,0.38c0.09,0.64,0.16,1.11,0.24,1.67C551.74,522.49,552.27,522.45,552.93,522.4z M583.87,570.36
	c0.66-0.1,1.26-0.19,2-0.31c-0.08-0.64-0.15-1.17-0.22-1.72c-0.67,0-1.27,0-1.96,0C583.75,569.05,583.81,569.67,583.87,570.36z
	 M573.66,471.71c-0.76,0.04-1.3,0.07-1.96,0.11c0.04,0.82,0.07,1.49,0.1,2.3c0.78-0.23,1.43-0.41,2.07-0.6
	C573.8,472.89,573.74,472.42,573.66,471.71z M579.47,225.71c0.19,1.72,0.19,1.72,2.06,1.41c-0.07-0.51-0.14-1.03-0.23-1.66
	C580.67,225.55,580.14,225.62,579.47,225.71z M584.78,371.62c0.78-0.07,1.38-0.13,2.09-0.2c-0.11-0.7-0.19-1.23-0.29-1.88
	c-0.67,0.08-1.25,0.15-1.81,0.22C584.78,370.39,584.78,370.86,584.78,371.62z M589.59,360.43c-0.11-0.76-0.19-1.35-0.28-2.01
	c-0.68,0.1-1.21,0.17-1.88,0.27c0.06,0.62,0.12,1.15,0.18,1.74C588.21,360.43,588.75,360.43,589.59,360.43z M401.72,69.65
	c-0.14-0.7-0.25-1.27-0.38-1.96c-0.7,0.08-1.28,0.15-1.91,0.23c0.07,0.75,0.13,1.35,0.2,2C400.34,69.83,400.94,69.75,401.72,69.65z
	 M584.73,555.75c-0.11-0.73-0.19-1.24-0.27-1.83c-0.73,0.09-1.34,0.16-2.03,0.24c0.16,0.64,0.29,1.14,0.44,1.69
	C583.49,555.82,583.96,555.79,584.73,555.75z M578.94,471.38c0.09,0.73,0.15,1.24,0.23,1.89c0.71-0.18,1.28-0.32,1.96-0.48
	c-0.15-0.59-0.25-1.02-0.37-1.5C580.17,471.32,579.7,471.34,578.94,471.38z M643.64,612.02c-0.07-0.65-0.12-1.24-0.19-1.91
	c-0.67,0.15-1.18,0.26-1.76,0.39c0.04,0.67,0.07,1.19,0.12,1.86C642.49,612.23,643,612.13,643.64,612.02z M580.76,465.99
	c-0.17-0.74-0.31-1.31-0.46-1.98c-0.66,0.08-1.19,0.15-1.81,0.22c0.06,0.66,0.11,1.25,0.17,1.88
	C579.39,466.08,579.99,466.04,580.76,465.99z M559.88,583.21c0.8-0.08,1.33-0.13,1.93-0.19c-0.07-0.7-0.13-1.29-0.2-1.98
	c-0.69,0.1-1.21,0.18-1.73,0.26C559.88,581.92,559.88,582.46,559.88,583.21z M378.81,37.12c0.04,0.73,0.07,1.44,0.11,2.22
	c0.75-0.15,1.27-0.26,1.89-0.38c-0.04-0.7-0.07-1.3-0.11-2.05C380.03,36.98,379.51,37.04,378.81,37.12z M582.78,407.98
	c-0.08-0.78-0.14-1.37-0.2-2.03c-0.7,0.1-1.23,0.17-1.91,0.26c0.09,0.67,0.17,1.25,0.25,1.88
	C581.57,408.05,582.04,408.02,582.78,407.98z M500.16,519.84c0.76-0.11,1.33-0.19,1.98-0.28c-0.16-0.64-0.28-1.09-0.39-1.54
	c-0.61,0-1.14,0-1.79,0C500.03,518.6,500.08,519.06,500.16,519.84z M608.53,580.74c-0.81,0.05-1.41,0.08-2.1,0.13
	c0.17,0.74,0.3,1.32,0.45,1.95c0.68-0.11,1.19-0.19,1.78-0.28C608.61,581.92,608.58,581.46,608.53,580.74z M588.84,519.23
	c-0.14-0.71-0.26-1.28-0.39-1.91c-0.68,0.04-1.22,0.07-1.87,0.11c0.07,0.69,0.12,1.29,0.19,1.96
	C587.47,519.33,588.07,519.29,588.84,519.23z M547.05,519.44c0.78-0.13,1.28-0.22,1.82-0.31c-0.11-0.65-0.19-1.1-0.28-1.67
	c-0.58,0.07-1.08,0.14-1.53,0.19C547.05,518.27,547.05,518.73,547.05,519.44z M616.53,610.81c0.96-0.06,1.78,0.08,1.67-1.03
	c-0.03-0.35-0.14-0.69-0.22-1.11c-0.58,0.14-1.02,0.24-1.44,0.34C616.53,609.63,616.53,610.14,616.53,610.81z M580.44,319.62
	c0.04,0.66,0.07,1.25,0.12,1.94c0.67-0.14,1.17-0.25,1.77-0.37c-0.11-0.67-0.2-1.2-0.29-1.8
	C581.48,319.47,581.02,319.54,580.44,319.62z M567.83,508.31c0.06,0.75,0.1,1.42,0.16,2.18c0.69-0.13,1.26-0.23,1.92-0.35
	c-0.08-0.84-0.14-1.5-0.2-2.19C569.03,508.09,568.51,508.19,567.83,508.31z M588,304.34c-0.67,0.05-1.26,0.1-1.93,0.15
	c0.11,0.72,0.2,1.29,0.3,1.92c0.7-0.11,1.22-0.19,1.86-0.28C588.15,305.47,588.08,304.95,588,304.34z M579.76,604.81
	c0.14,0.77,0.26,1.42,0.38,2.1c0.63-0.14,1.08-0.24,1.62-0.36c-0.06-0.63-0.12-1.17-0.17-1.74
	C580.96,604.81,580.43,604.81,579.76,604.81z M591.82,553.27c-0.78,0.06-1.39,0.11-2.19,0.17c0.14,0.72,0.26,1.3,0.38,1.95
	c0.68-0.05,1.21-0.09,1.8-0.14C591.82,554.61,591.82,554.07,591.82,553.27z M593.78,516.94c0.09,0.78,0.16,1.37,0.23,2.01
	c0.7-0.07,1.24-0.13,1.93-0.2c-0.1-0.68-0.19-1.26-0.27-1.81C595.02,516.94,594.56,516.94,593.78,516.94z M619.76,606.98
	c0.65-0.07,1.17-0.12,1.8-0.19c-0.05-0.67-0.1-1.25-0.15-1.92c-0.66,0.12-1.17,0.21-1.77,0.32
	C619.68,605.8,619.71,606.31,619.76,606.98z M613.53,553.34c-0.05-0.88-0.09-1.49-0.13-2.21c-0.78,0.2-1.44,0.36-2.13,0.54
	c0.12,0.66,0.22,1.18,0.31,1.67C612.26,553.34,612.8,553.34,613.53,553.34z M526.2,407.09c0.24,0.79,0.42,1.36,0.61,1.98
	c0.66-0.14,1.17-0.25,1.75-0.37c-0.11-0.63-0.2-1.14-0.28-1.61C527.6,407.09,527.06,407.09,526.2,407.09z M568.43,409.22
	c-0.13-0.84-0.22-1.42-0.33-2.07c-0.67,0.16-1.17,0.27-1.75,0.41c0.1,0.71,0.17,1.22,0.23,1.66
	C567.21,409.22,567.68,409.22,568.43,409.22z M574.63,233.21c-0.76,0.05-1.29,0.09-1.95,0.13c0.06,0.78,0.11,1.42,0.17,2.16
	c0.77-0.18,1.36-0.32,1.94-0.46C574.73,234.39,574.69,233.92,574.63,233.21z M582.1,359.01c-0.78,0.05-1.44,0.1-2.18,0.15
	c0.2,0.72,0.34,1.24,0.5,1.85c0.64-0.05,1.16-0.08,1.82-0.13C582.18,360.21,582.14,359.68,582.1,359.01z M609.27,611.57
	c0.78-0.05,1.39-0.09,2.2-0.14c-0.17-0.73-0.3-1.31-0.46-2.01c-0.64,0.07-1.17,0.13-1.74,0.2
	C609.27,610.24,609.27,610.77,609.27,611.57z M576.14,231.48c0.76-0.06,1.41-0.11,2.16-0.17c-0.16-0.72-0.27-1.23-0.41-1.86
	c-0.65,0.05-1.23,0.1-1.75,0.14C576.14,230.28,576.14,230.82,576.14,231.48z M597.28,515.06c0.79-0.04,1.4-0.07,2.2-0.11
	c-0.17-0.72-0.3-1.29-0.46-1.99c-0.68,0.11-1.24,0.21-1.83,0.3C597.22,513.88,597.24,514.35,597.28,515.06z M577.27,469.85
	c-0.14-0.78-0.23-1.33-0.34-1.95c-0.71,0.05-1.23,0.09-1.84,0.14c0.05,0.7,0.09,1.3,0.14,1.94
	C575.86,469.94,576.4,469.91,577.27,469.85z M582.31,382.62c0.77-0.07,1.3-0.13,1.89-0.18c-0.05-0.72-0.09-1.32-0.13-2
	c-0.72,0.13-1.31,0.24-1.93,0.35C582.2,381.45,582.25,381.92,582.31,382.62z M601.34,581.35c-0.72,0.13-1.27,0.23-1.9,0.35
	c0.04,0.66,0.06,1.17,0.1,1.87c0.71-0.13,1.27-0.24,1.89-0.36C601.4,582.56,601.38,582.05,601.34,581.35z M526.21,568
	c0.53,0,1.12,0,1.87,0c-0.07-0.75-0.12-1.4-0.18-2.02C526.4,565.96,525.73,566.47,526.21,568z M402.82,63.99
	c0.04,0.7,0.08,1.3,0.12,2.03c0.74-0.12,1.32-0.21,1.91-0.31c-0.08-0.69-0.14-1.22-0.21-1.88C404,63.89,403.48,63.93,402.82,63.99z
	 M577.48,411.95c0.78-0.11,1.36-0.2,1.98-0.29c-0.09-0.68-0.15-1.21-0.23-1.8c-0.69,0.05-1.29,0.09-2.02,0.14
	C577.3,410.64,577.37,411.16,577.48,411.95z M590.2,327.74c-0.09-0.66-0.16-1.18-0.25-1.83c-0.66,0.08-1.24,0.15-1.84,0.22
	c0.04,0.63,0.07,1.09,0.11,1.61C588.9,327.74,589.49,327.74,590.2,327.74z M526.75,513.91c-0.08-0.69-0.14-1.14-0.21-1.71
	c-0.57,0.05-1.08,0.1-1.66,0.15c0.07,0.61,0.12,1.06,0.17,1.56C525.59,513.91,526.04,513.91,526.75,513.91z M581.32,599.38
	c-0.08-0.75-0.14-1.27-0.21-1.86c-0.7,0.04-1.3,0.07-1.99,0.11c0.15,0.68,0.27,1.24,0.4,1.83
	C580.16,599.44,580.61,599.42,581.32,599.38z M501.25,512.44c-0.05-0.7-0.08-1.15-0.12-1.67c-0.65,0-1.18,0-1.75,0
	c0,0.66,0,1.25,0,1.98C500.03,512.64,500.55,512.55,501.25,512.44z M605.24,566.4c0.14,0.77,0.23,1.29,0.34,1.91
	c0.69-0.08,1.28-0.15,1.92-0.22c-0.11-0.66-0.19-1.18-0.28-1.69C606.56,566.4,606.03,566.4,605.24,566.4z M578.77,260.05
	c0.79-0.08,1.66,0.19,1.67-0.95c0-0.3-0.1-0.6-0.18-0.98c-0.63,0.07-1.14,0.12-1.78,0.19C578.59,258.98,578.67,259.5,578.77,260.05z
	 M594.2,289.4c-0.81,0.08-1.35,0.13-1.97,0.19c0.08,0.66,0.14,1.18,0.2,1.7c0.65,0,1.18,0,1.87,0
	C594.26,290.66,594.24,290.13,594.2,289.4z M586.64,411.13c-0.08-0.65-0.15-1.16-0.22-1.74c-0.71,0.04-1.31,0.08-2.04,0.13
	c0.11,0.62,0.2,1.14,0.31,1.74C585.35,411.21,585.94,411.18,586.64,411.13z M579.19,431.56c0.11,0.76,0.19,1.28,0.28,1.9
	c0.69-0.06,1.29-0.11,1.94-0.17c-0.11-0.67-0.19-1.2-0.27-1.73C580.52,431.56,579.99,431.56,579.19,431.56z M572.07,576.57
	c-0.81,0.07-1.4,0.13-2.1,0.19c0.17,0.67,0.29,1.18,0.45,1.8c0.61-0.09,1.12-0.17,1.65-0.25
	C572.07,577.72,572.07,577.25,572.07,576.57z M579.78,232.63c0.17,0.86,0.29,1.44,0.42,2.1c0.7-0.23,1.21-0.39,1.73-0.55
	c-0.05-0.59-0.09-1.05-0.14-1.55C581.13,232.63,580.59,232.63,579.78,232.63z M584.13,322.67c0.16,0.8,0.26,1.3,0.38,1.88
	c0.69-0.08,1.21-0.14,1.79-0.21c-0.07-0.67-0.13-1.19-0.2-1.83C585.44,322.57,584.91,322.61,584.13,322.67z M527.99,486.8
	c-0.55,0.03-1.06,0.06-1.66,0.09c0.05,0.61,0.1,1.07,0.14,1.53c0.61,0,1.12,0,1.72,0C528.11,487.82,528.06,487.36,527.99,486.8z
	 M566.93,559.05c-0.65,0.09-1.17,0.16-1.79,0.25c0.06,0.65,0.12,1.17,0.18,1.81c0.69-0.07,1.27-0.13,1.9-0.19
	C567.11,560.23,567.03,559.71,566.93,559.05z M553.65,408.53c-0.61,0.04-1.13,0.08-1.72,0.12c0.11,0.68,0.19,1.19,0.29,1.77
	c0.64-0.12,1.09-0.2,1.59-0.29C553.75,409.54,553.71,409.08,553.65,408.53z M588.11,512.09c-0.09-0.73-0.15-1.25-0.22-1.86
	c-0.66,0.09-1.18,0.15-1.78,0.23c0.06,0.65,0.12,1.18,0.18,1.78C586.89,512.19,587.35,512.15,588.11,512.09z M360.75,37.07
	c0.75-0.12,1.26-0.2,1.85-0.3c-0.11-0.68-0.19-1.19-0.28-1.74c-0.64,0.08-1.15,0.15-1.72,0.22
	C360.66,35.9,360.69,36.35,360.75,37.07z M565.07,434.53c0.6,0,1.18,0,1.74,0c0-0.66,0-1.2,0-1.9c-0.69,0.16-1.25,0.29-1.83,0.42
	C565.02,433.61,565.04,434,565.07,434.53z M555.42,469.87c-0.65,0.06-1.16,0.11-1.76,0.17c0.09,0.65,0.16,1.16,0.25,1.8
	c0.68-0.08,1.26-0.15,1.89-0.22C555.67,471.01,555.57,470.57,555.42,469.87z M613.31,593.12c-0.15-0.69-0.26-1.25-0.39-1.87
	c-0.71,0.07-1.21,0.12-1.8,0.18c0.07,0.68,0.12,1.2,0.18,1.79C612,593.19,612.6,593.15,613.31,593.12z M553.4,487.95
	c-0.72,0.32-1.16,0.51-1.61,0.71c0.2,0.44,0.3,0.98,0.62,1.29c0.18,0.17,0.71-0.01,1.19-0.05
	C553.54,489.3,553.5,488.84,553.4,487.95z M584.64,389.65c-0.04-0.65-0.06-1.17-0.1-1.81c-0.67,0.05-1.25,0.1-1.89,0.14
	c0.07,0.67,0.12,1.19,0.18,1.83C583.47,389.75,583.99,389.71,584.64,389.65z M534.21,558.6c-1.65,0.2-1.65,0.2-1.36,1.83
	c0.51-0.06,1.03-0.12,1.62-0.19C534.38,559.65,534.31,559.2,534.21,558.6z M568.15,595.15c0.14,0.69,0.24,1.2,0.35,1.77
	c0.62-0.1,1.13-0.19,1.71-0.29c-0.1-0.68-0.18-1.18-0.26-1.76C569.34,594.97,568.88,595.04,568.15,595.15z M535.61,408.3
	c-0.08-0.63-0.15-1.14-0.23-1.76c-0.66,0.1-1.17,0.17-1.74,0.26c0.11,0.67,0.2,1.18,0.29,1.75
	C534.58,408.45,535.04,408.38,535.61,408.3z M574.77,256.88c0.78-0.11,1.35-0.18,1.95-0.27c-0.11-0.64-0.19-1.09-0.27-1.55
	c-0.6,0-1.11,0-1.68,0C574.77,255.64,574.77,256.09,574.77,256.88z M585.18,602.7c-0.1-0.76-0.18-1.28-0.24-1.73
	c-0.66,0-1.19,0-1.86,0c0.07,0.64,0.13,1.16,0.2,1.73C583.86,602.7,584.38,602.7,585.18,602.7z M573.15,424.91
	c-0.7,0.07-1.28,0.13-1.91,0.19c0.06,0.77,0.11,1.31,0.16,1.94c0.77-0.19,1.42-0.36,2.08-0.53
	C573.36,425.89,573.26,425.43,573.15,424.91z M607.3,588.03c0.04,0.65,0.07,1.18,0.11,1.84c0.68-0.05,1.27-0.09,1.94-0.14
	c-0.08-0.67-0.15-1.2-0.21-1.7C608.49,588.03,607.96,588.03,607.3,588.03z M589.71,260.75c0.11,0.66,0.22,1.24,0.33,1.9
	c0.66-0.06,1.16-0.11,1.72-0.16c-0.08-0.73-0.14-1.26-0.21-1.9C590.92,260.65,590.38,260.69,589.71,260.75z M499.47,470.91
	c0.11,0.66,0.2,1.16,0.3,1.78c0.58-0.05,1.1-0.09,1.73-0.15c-0.07-0.65-0.13-1.15-0.19-1.71
	C500.65,470.86,500.13,470.88,499.47,470.91z M566.5,575.47c0.68-0.07,1.27-0.12,1.94-0.19c-0.14-0.67-0.25-1.18-0.37-1.75
	c-0.66,0.04-1.19,0.08-1.83,0.12C566.34,574.31,566.41,574.83,566.5,575.47z M587.59,320.7c0.64-0.07,1.23-0.13,1.88-0.21
	c-0.08-0.66-0.14-1.18-0.2-1.64c-0.66,0-1.18,0-1.85,0C587.49,319.5,587.53,320.03,587.59,320.7z M508.89,432.37
	c-0.13-0.8-0.22-1.37-0.32-2c-0.62,0.09-1.07,0.15-1.64,0.23c0.06,0.66,0.11,1.25,0.17,1.89
	C507.69,432.45,508.15,432.42,508.89,432.37z M542.86,573.95c-0.06-0.65-0.11-1.17-0.17-1.81c-0.69,0.04-1.28,0.07-1.94,0.1
	c0.1,0.67,0.18,1.19,0.28,1.82C541.65,574.02,542.18,573.99,542.86,573.95z M602.18,611.97c0.67-0.06,1.26-0.11,1.92-0.17
	c-0.08-0.66-0.15-1.18-0.23-1.82c-0.65,0.06-1.24,0.12-1.91,0.19C602.04,610.83,602.1,611.35,602.18,611.97z M502.78,508.6
	c0.7,0,1.23,0,1.89,0c-0.09-0.64-0.16-1.14-0.25-1.75c-0.67,0.09-1.18,0.17-1.64,0.23C502.78,507.63,502.78,508.02,502.78,508.6z
	 M584.66,348.01c-0.64,0.06-1.23,0.11-1.89,0.16c0.08,0.68,0.15,1.2,0.2,1.66c0.66,0,1.19,0,1.88,0
	C584.79,349.18,584.73,348.66,584.66,348.01z M576.84,236.74c0.09,0.73,0.16,1.24,0.24,1.85c0.65-0.04,1.17-0.08,1.82-0.12
	c-0.11-0.68-0.19-1.19-0.28-1.81C578.06,236.69,577.6,236.71,576.84,236.74z M588.54,351.32c-0.75,0.08-1.28,0.14-1.88,0.21
	c0.08,0.67,0.15,1.19,0.23,1.82c0.71-0.09,1.3-0.16,1.91-0.24C588.7,352.48,588.64,352.01,588.54,351.32z M598.44,505.83
	c-0.79,0.05-1.33,0.08-2.02,0.12c0.13,0.71,0.22,1.28,0.33,1.9c0.72-0.04,1.25-0.07,1.91-0.11
	C598.59,507.06,598.53,506.54,598.44,505.83z M562.24,590.2c-0.05-0.65-0.1-1.17-0.15-1.79c-0.69,0.04-1.22,0.08-1.83,0.12
	c0.06,0.72,0.11,1.32,0.16,1.98C561.07,590.39,561.59,590.3,562.24,590.2z M556.59,587c0.66-0.06,1.25-0.11,1.91-0.16
	c-0.09-0.66-0.15-1.18-0.23-1.8c-0.67,0.05-1.26,0.09-1.92,0.14C556.43,585.84,556.5,586.37,556.59,587z M506.15,465.11
	c0.79-0.09,1.3-0.15,1.88-0.21c-0.1-0.64-0.17-1.14-0.26-1.73c-0.67,0.08-1.18,0.14-1.71,0.2
	C506.09,463.98,506.11,464.43,506.15,465.11z M590.87,585.95c-0.71,0.07-1.23,0.12-1.83,0.19c0.07,0.68,0.12,1.21,0.18,1.81
	c0.7-0.03,1.31-0.06,2.06-0.1C591.14,587.21,591.03,586.69,590.87,585.95z M574.31,415.73c0.73-0.07,1.19-0.11,1.74-0.16
	c-0.05-0.69-0.09-1.28-0.14-1.94c-0.66,0.07-1.17,0.13-1.81,0.2C574.17,414.48,574.23,415,574.31,415.73z M606.04,593.63
	c-0.11-0.76-0.19-1.27-0.28-1.89c-0.69,0.06-1.27,0.12-1.92,0.18c0.11,0.66,0.19,1.18,0.28,1.71
	C604.73,593.63,605.26,593.63,606.04,593.63z M534.62,542.28c0.72-0.11,1.32-0.21,2.02-0.32c-0.17-0.67-0.3-1.2-0.46-1.83
	c-0.61,0.11-1.12,0.21-1.68,0.31C534.54,541.1,534.58,541.63,534.62,542.28z M556.01,392.03c-0.1-0.83-0.17-1.41-0.25-2.02
	c-0.68,0.11-1.21,0.2-1.86,0.3c0.12,0.68,0.21,1.19,0.3,1.72C554.79,392.03,555.24,392.03,556.01,392.03z M589.72,300.56
	c0,0.59,0,1.17,0,1.87c0.65-0.05,1.23-0.1,1.87-0.14c-0.08-0.66-0.15-1.18-0.22-1.72C590.79,300.56,590.33,300.56,589.72,300.56z
	 M522.74,423.84c-0.08-0.65-0.15-1.23-0.24-1.93c-0.66,0.17-1.16,0.29-1.76,0.44c0.13,0.67,0.23,1.16,0.33,1.68
	C521.68,423.95,522.15,423.9,522.74,423.84z M587.25,566.46c0.83-0.13,1.4-0.23,2-0.32c-0.1-0.67-0.18-1.18-0.27-1.77
	c-0.68,0.04-1.2,0.07-1.83,0.11C587.18,565.16,587.21,565.68,587.25,566.46z M588.95,293.35c0.04,0.66,0.08,1.27,0.13,2
	c0.73-0.13,1.24-0.21,1.82-0.31c-0.08-0.63-0.14-1.15-0.2-1.69C590.11,293.35,589.64,293.35,588.95,293.35z M536.34,415.54
	c-0.08-0.69-0.14-1.15-0.2-1.63c-0.57,0-1.09,0-1.74,0c0.07,0.63,0.13,1.13,0.19,1.63C535.19,415.54,535.64,415.54,536.34,415.54z
	 M529.81,403.31c0.1,0.76,0.17,1.27,0.25,1.87c0.7-0.1,1.21-0.17,1.8-0.25c-0.11-0.64-0.19-1.14-0.29-1.71
	C530.97,403.25,530.52,403.27,529.81,403.31z M626.59,604.32c0.17,0.75,0.28,1.27,0.42,1.88c0.67-0.07,1.19-0.12,1.8-0.18
	c-0.09-0.68-0.16-1.2-0.22-1.7C627.91,604.32,627.37,604.32,626.59,604.32z M586.92,311.59c0.09,0.74,0.15,1.25,0.23,1.9
	c0.66-0.08,1.24-0.15,1.88-0.22c-0.11-0.68-0.19-1.18-0.27-1.68C588.15,311.59,587.68,311.59,586.92,311.59z M614.65,589.2
	c0.75-0.17,1.76,0.47,1.8-0.9c0.01-0.28-0.13-0.57-0.22-0.95c-0.64,0.1-1.16,0.18-1.73,0.27
	C614.55,588.18,614.59,588.65,614.65,589.2z M548.21,551.65c-0.08-0.65-0.14-1.15-0.2-1.69c-0.68,0.03-1.21,0.06-1.9,0.1
	c0.11,0.65,0.19,1.15,0.29,1.73C547.02,551.73,547.55,551.69,548.21,551.65z M524.87,452.46c-0.05-1.62-0.05-1.62-1.67-1.49
	c0.06,0.51,0.12,1.01,0.19,1.6C523.98,452.52,524.43,452.49,524.87,452.46z M526.37,425.39c-0.67,0-1.19,0-1.81,0
	c0.1,0.63,0.18,1.14,0.27,1.76c0.63-0.1,1.12-0.17,1.63-0.25C526.43,426.37,526.41,425.99,526.37,425.39z M536.66,522.18
	c0.04,0.74,0.07,1.21,0.1,1.79c0.69-0.05,1.27-0.09,1.93-0.13c-0.12-0.67-0.2-1.18-0.3-1.76
	C537.76,522.11,537.31,522.14,536.66,522.18z M567.45,461.51c0.03,0.62,0.05,1.15,0.08,1.82c0.67-0.08,1.18-0.14,1.8-0.21
	c-0.04-0.61-0.07-1.13-0.11-1.79C568.56,461.39,568.05,461.44,567.45,461.51z M634.15,603.82c0,0.58,0,1.16,0,1.86
	c0.67-0.04,1.26-0.08,1.93-0.13c-0.11-0.66-0.2-1.18-0.29-1.73C635.21,603.82,634.75,603.82,634.15,603.82z M590.54,269.81
	c0.65-0.06,1.24-0.12,1.89-0.18c-0.1-0.7-0.17-1.23-0.26-1.82c-0.73,0.07-1.35,0.12-2.03,0.18
	C590.28,268.63,590.4,269.16,590.54,269.81z M570.72,251.64c0.11,0.78,0.18,1.3,0.25,1.87c0.66-0.04,1.19-0.07,1.84-0.11
	c-0.07-0.69-0.12-1.21-0.18-1.76C572.03,251.64,571.51,251.64,570.72,251.64z M562.75,577.43c0.2,0.79,0.33,1.36,0.49,1.98
	c0.67-0.14,1.18-0.24,1.75-0.35c-0.08-0.62-0.15-1.13-0.22-1.63C564.11,577.43,563.59,577.43,562.75,577.43z M557.66,451.64
	c-0.64,0.05-1.22,0.1-1.87,0.15c0.07,0.67,0.12,1.19,0.19,1.82c0.67-0.04,1.26-0.08,1.92-0.12
	C557.81,452.81,557.74,452.28,557.66,451.64z M587.1,582.54c-0.71,0.08-1.22,0.14-1.88,0.21c0.07,0.69,0.13,1.25,0.19,1.87
	c0.68-0.04,1.2-0.07,1.88-0.1C587.23,583.86,587.18,583.34,587.1,582.54z M579.6,393.65c0.8-0.1,1.31-0.16,1.87-0.23
	c-0.07-0.67-0.13-1.19-0.2-1.83c-0.69,0.12-1.21,0.2-1.76,0.29C579.54,392.47,579.56,392.93,579.6,393.65z M575.47,242.35
	c-0.06-0.65-0.1-1.16-0.16-1.8c-0.68,0.03-1.27,0.06-1.93,0.09c0.1,0.66,0.19,1.18,0.28,1.8
	C574.28,242.41,574.8,242.39,575.47,242.35z M516.89,525.39c-0.08-0.69-0.13-1.14-0.2-1.7c-0.61,0.06-1.12,0.11-1.67,0.17
	c0.07,0.61,0.12,1.07,0.19,1.65C515.73,525.47,516.18,525.44,516.89,525.39z M557.7,572.8c-0.49-0.76-0.75-1.32-1.16-1.7
	c-0.17-0.15-0.7,0.1-1.1,0.18c0.04,0.6,0.08,1.06,0.11,1.52C556.18,572.8,556.71,572.8,557.7,572.8z M591.66,575.04
	c0.11,0.64,0.2,1.15,0.3,1.74c0.64-0.06,1.17-0.11,1.83-0.17c-0.09-0.65-0.17-1.15-0.25-1.69
	C592.86,574.97,592.32,575,591.66,575.04z M636.5,592.81c0.13,0.78,0.22,1.29,0.32,1.88c0.72-0.06,1.32-0.11,1.97-0.17
	c-0.14-0.63-0.26-1.16-0.38-1.71C637.81,592.81,637.29,592.81,636.5,592.81z M539.1,570.61c-0.12-0.75-0.2-1.26-0.28-1.73
	c-0.68,0-1.22,0-1.9,0c0.12,0.7,0.21,1.26,0.32,1.87C537.9,570.7,538.36,570.67,539.1,570.61z M505.91,441.67c-0.7,0-1.21,0-1.82,0
	c0.04,0.71,0.07,1.31,0.11,2.01c0.64-0.12,1.15-0.21,1.71-0.31C505.91,442.82,505.91,442.35,505.91,441.67z M563.61,584.65
	c0.08,0.74,0.14,1.25,0.21,1.83c0.65-0.03,1.18-0.06,1.83-0.09c-0.1-0.67-0.18-1.18-0.26-1.73
	C564.81,584.65,564.35,584.65,563.61,584.65z M547.47,524.71c0.1,0.67,0.18,1.2,0.26,1.74c0.66,0,1.27,0,1.99,0
	c-0.09-0.67-0.16-1.19-0.25-1.83C548.79,524.65,548.18,524.68,547.47,524.71z M580.27,213.08c-0.19-0.8-0.31-1.32-0.44-1.89
	c-0.65,0.06-1.17,0.1-1.74,0.15c0.07,0.8,0.12,1.39,0.18,2.03C579.01,213.26,579.55,213.18,580.27,213.08z M565.63,400.32
	c0.08,0.65,0.15,1.21,0.22,1.73c1.65,0.09,1.94-0.61,1.4-1.98C566.76,400.15,566.26,400.23,565.63,400.32z M560.27,442.15
	c0.23-1.81-0.69-1.63-1.75-1.28C558.36,442.26,558.93,442.64,560.27,442.15z M637.28,619.92c-0.12-0.63-0.23-1.14-0.35-1.77
	c-0.64,0.06-1.23,0.12-1.84,0.18c0.05,0.62,0.09,1.09,0.13,1.59C635.93,619.92,636.53,619.92,637.28,619.92z M334.68,28.5
	c0.76-0.06,1.35-0.11,2.01-0.17c-0.09-0.67-0.15-1.2-0.24-1.88c-0.71,0.07-1.35,0.13-2.01,0.19
	C334.52,27.3,334.58,27.76,334.68,28.5z M502.27,461.85c0.81-0.1,1.32-0.17,1.93-0.25c-0.09-0.71-0.15-1.23-0.22-1.82
	c-0.69,0.05-1.22,0.09-1.71,0.12C502.27,460.54,502.27,461.08,502.27,461.85z M561.76,436.77c0.14,0.85-0.21,1.87,0.99,1.66
	c1.25-0.22,0.75-1.06,0.51-1.83C562.75,436.66,562.36,436.71,561.76,436.77z M542.73,281.27c-0.08-0.69-0.15-1.33-0.23-2
	c-0.73,0.07-1.26,0.12-1.95,0.18c0.18,0.74,0.32,1.32,0.47,1.95C541.62,281.36,542.09,281.32,542.73,281.27z M581.73,586.57
	c0.18,0.8,0.29,1.31,0.42,1.9c0.69-0.07,1.21-0.13,1.79-0.2c-0.09-0.67-0.16-1.19-0.23-1.7
	C583.06,586.57,582.53,586.57,581.73,586.57z M515.46,550.82c-0.09-0.69-0.15-1.15-0.23-1.71c-0.62,0.07-1.13,0.13-1.69,0.19
	c0.08,0.62,0.14,1.07,0.22,1.66C514.3,550.92,514.75,550.88,515.46,550.82z M537.66,536.14c0.11,0.74,0.19,1.27,0.29,1.91
	c0.66-0.04,1.26-0.08,1.94-0.12c-0.1-0.67-0.18-1.2-0.27-1.79C539.03,536.14,538.5,536.14,537.66,536.14z M587.35,338.85
	c0-0.59,0-1.17,0-1.87c-0.65,0.05-1.24,0.09-1.88,0.14c0.09,0.66,0.16,1.18,0.23,1.73C586.28,338.85,586.75,338.85,587.35,338.85z
	 M610.61,604.18c-0.1-0.77-0.17-1.28-0.25-1.85c-0.64,0.03-1.16,0.05-1.8,0.08c0.06,0.68,0.11,1.2,0.17,1.77
	C609.3,604.18,609.82,604.18,610.61,604.18z M583.83,608.17c0,0.79,0,1.39,0,2.15c0.68-0.14,1.25-0.25,1.94-0.39
	c-0.1-0.69-0.17-1.27-0.26-1.86C584.91,608.1,584.46,608.13,583.83,608.17z M589.69,280.75c-0.1-0.72-0.17-1.24-0.26-1.87
	c-0.69,0.09-1.27,0.16-1.88,0.24c0.09,0.63,0.15,1.1,0.22,1.64C588.37,280.75,588.89,280.75,589.69,280.75z M558.28,460.52
	c-0.04-0.66-0.08-1.12-0.12-1.71c-0.7,0.06-1.3,0.12-1.94,0.17c0.08,0.67,0.15,1.18,0.22,1.77
	C557.11,460.67,557.57,460.61,558.28,460.52z M612.55,607.43c1.83-0.19,1.83-0.19,1.44-1.75c-0.51,0.03-1.03,0.06-1.66,0.09
	C612.42,606.43,612.49,606.93,612.55,607.43z M579.9,398.97c0.13,0.77,0.22,1.28,0.32,1.87c0.69-0.1,1.2-0.17,1.76-0.25
	c-0.09-0.66-0.16-1.16-0.23-1.73C581.08,398.9,580.63,398.93,579.9,398.97z M583.34,315.44c0.23,0.77,0.39,1.32,0.57,1.94
	c0.66-0.11,1.17-0.19,1.74-0.29c-0.1-0.64-0.18-1.15-0.26-1.65C584.73,315.44,584.2,315.44,583.34,315.44z M586.92,537.49
	c-0.2-0.83-0.34-1.39-0.48-1.99c-0.69,0.12-1.21,0.21-1.78,0.3c0.1,0.64,0.18,1.16,0.26,1.69
	C585.54,537.49,586.07,537.49,586.92,537.49z M587.69,613.35c0.77-0.07,1.42-0.13,2.13-0.19c-0.16-0.66-0.27-1.16-0.4-1.71
	c-0.69,0.03-1.21,0.06-1.86,0.09C587.6,612.21,587.64,612.72,587.69,613.35z M574.84,422.9c0.68-0.04,1.29-0.08,2-0.13
	c-0.1-0.66-0.19-1.2-0.29-1.84c-0.66,0.04-1.26,0.07-1.96,0.11C574.68,421.73,574.75,422.27,574.84,422.9z M562.58,550.38
	c-0.04-0.73-0.07-1.2-0.1-1.73c-0.72,0-1.32,0-2.07,0c0.15,0.68,0.27,1.24,0.41,1.83C561.47,550.44,561.92,550.42,562.58,550.38z
	 M572.24,600.32c0.75-0.09,1.26-0.15,1.87-0.23c-0.08-0.65-0.14-1.16-0.22-1.76c-0.65,0.08-1.15,0.15-1.74,0.22
	C572.18,599.16,572.2,599.61,572.24,600.32z M589.64,595.15c0.79-0.15,1.31-0.26,1.92-0.38c-0.06-0.54-0.11-1-0.17-1.58
	c-0.66,0.06-1.25,0.11-1.86,0.16C589.57,593.97,589.6,594.44,589.64,595.15z M575.9,453.46c-0.87,0.12-1.45,0.21-2.07,0.29
	c0.12,0.68,0.22,1.19,0.32,1.8c0.68-0.04,1.2-0.08,1.86-0.12C575.97,454.76,575.94,454.23,575.9,453.46z M584.21,364.37
	c0.73-0.05,1.24-0.09,1.87-0.14c-0.06-0.68-0.11-1.2-0.16-1.84c-0.69,0.07-1.28,0.12-1.89,0.18
	C584.09,363.18,584.13,363.64,584.21,364.37z M615.95,582.11c-0.13-0.75-0.22-1.27-0.31-1.75c-0.65,0-1.17,0-1.83,0
	c0.07,0.65,0.13,1.17,0.19,1.75C614.61,582.11,615.14,582.11,615.95,582.11z M575.58,263.78c1.87-0.05,1.87-0.05,1.44-1.69
	c-0.53,0.07-1.07,0.14-1.67,0.21C575.44,262.85,575.51,263.32,575.58,263.78z M629.49,613.31c-0.08-0.76-0.14-1.29-0.21-1.94
	c-0.69,0.11-1.27,0.2-1.92,0.3c0.13,0.67,0.23,1.18,0.35,1.75C628.32,613.37,628.78,613.35,629.49,613.31z M584.48,575.48
	c0.09,0.74,0.16,1.26,0.24,1.9c0.63-0.04,1.2-0.08,1.86-0.12c-0.05-0.68-0.1-1.2-0.14-1.77
	C585.83,575.48,585.3,575.48,584.48,575.48z M502.14,501.64c0.74-0.12,1.26-0.2,1.81-0.28c-0.12-0.74,0.25-1.62-0.9-1.64
	c-0.29-0.01-0.58,0.08-0.91,0.12C502.14,500.41,502.14,500.86,502.14,501.64z M585.81,591.89c0.83-0.13,1.4-0.23,2-0.32
	c-0.1-0.67-0.18-1.18-0.27-1.76c-0.68,0.04-1.2,0.07-1.82,0.11C585.74,590.59,585.77,591.11,585.81,591.89z M521.93,478.24
	c0.73-0.32,1.18-0.51,1.62-0.7c-0.19-0.45-0.3-0.98-0.62-1.3c-0.17-0.17-0.71,0.02-1.18,0.05
	C521.81,476.89,521.85,477.34,521.93,478.24z M593.17,527.89c-0.68,0.06-1.29,0.11-1.97,0.17c0.09,0.65,0.15,1.18,0.24,1.83
	c0.65-0.05,1.23-0.1,1.88-0.15C593.27,529.06,593.23,528.52,593.17,527.89z M588.62,499.16c0.09,0.72,0.18,1.39,0.28,2.16
	c0.66-0.11,1.19-0.19,1.86-0.3c-0.15-0.72-0.28-1.36-0.4-1.99C589.69,499.08,589.22,499.11,588.62,499.16z M478.73,41.4
	c-0.1-0.76-0.17-1.29-0.26-1.98c-0.71,0.06-1.35,0.12-2.05,0.19c0.13,0.72,0.23,1.25,0.32,1.79C477.4,41.4,477.93,41.4,478.73,41.4z
	 M578.59,384.55c0.17,0.75,0.29,1.27,0.43,1.87c0.67-0.07,1.19-0.12,1.81-0.18c-0.09-0.68-0.16-1.2-0.23-1.69
	C579.92,384.55,579.38,384.55,578.59,384.55z M533.21,439.35c0.03,1.61,0.03,1.61,1.67,1.5c-0.07-0.51-0.13-1.01-0.21-1.6
	C534.1,439.28,533.65,439.31,533.21,439.35z M585.9,298.97c0.63,0,1.15,0,1.84,0c-0.08-0.69-0.15-1.26-0.23-1.91
	c-0.71,0.09-1.23,0.16-1.84,0.24C585.75,297.93,585.82,298.45,585.9,298.97z M561.24,575.88c-0.13-0.8-0.21-1.32-0.31-1.91
	c-0.73,0.07-1.35,0.12-2.06,0.18c0.22,0.7,0.39,1.27,0.57,1.85C560.06,575.96,560.51,575.93,561.24,575.88z M579.96,438.78
	c0.04,0.67,0.08,1.24,0.12,1.91c0.66-0.06,1.18-0.11,1.83-0.17c-0.12-0.69-0.22-1.25-0.33-1.85
	C580.98,438.71,580.52,438.74,579.96,438.78z M594.95,298.56c-0.17-0.79-0.3-1.35-0.43-1.95c-0.63,0.08-1.07,0.13-1.61,0.2
	c0.07,0.68,0.12,1.2,0.17,1.75C593.67,298.56,594.14,298.56,594.95,298.56z M585.49,396.85c-0.16-0.68-0.3-1.24-0.45-1.87
	c-0.69,0.12-1.19,0.21-1.71,0.3c0.05,0.6,0.09,1.06,0.13,1.57C584.17,396.85,584.76,396.85,585.49,396.85z M579.72,456.84
	c-0.81,0.12-1.38,0.2-1.99,0.28c0.12,0.68,0.21,1.19,0.31,1.77c0.7-0.03,1.22-0.06,1.89-0.09
	C579.85,458.15,579.8,457.63,579.72,456.84z M588.55,248.64c0.8-0.14,1.32-0.24,1.95-0.35c-0.07-0.65-0.13-1.17-0.2-1.84
	c-0.65,0.11-1.17,0.19-1.75,0.28C588.55,247.35,588.55,247.89,588.55,248.64z M630.7,607.64c0.04,0.66,0.08,1.27,0.13,2
	c0.73-0.13,1.24-0.21,1.82-0.31c-0.08-0.63-0.14-1.15-0.2-1.69C631.85,607.64,631.39,607.64,630.7,607.64z M557.69,577.96
	c-0.78,0.09-1.3,0.15-1.95,0.22c0.07,0.62,0.13,1.13,0.2,1.74c0.68-0.09,1.26-0.16,1.9-0.24
	C557.78,579.05,557.75,578.6,557.69,577.96z M593.8,558.49c1.86-0.15,1.86-0.15,1.62-1.81c-0.6,0.05-1.21,0.1-1.9,0.15
	C593.63,557.46,593.72,557.98,593.8,558.49z M594.13,455.61c-0.68,0.06-1.27,0.12-1.97,0.18c0.11,0.61,0.2,1.11,0.3,1.69
	c0.65-0.04,1.17-0.07,1.82-0.1C594.23,456.73,594.18,456.22,594.13,455.61z M507.35,456.06c-1.76,0.04-1.76,0.04-1.57,1.81
	c0.58-0.04,1.17-0.09,1.82-0.13C507.51,457.08,507.43,456.56,507.35,456.06z M595.25,533.36c0.8-0.1,1.39-0.18,2.02-0.26
	c-0.11-0.68-0.19-1.22-0.29-1.84c-0.66,0.04-1.27,0.08-2,0.12C595.07,532.04,595.15,532.57,595.25,533.36z M597.49,599.78
	c0.1,0.68,0.18,1.24,0.28,1.87c0.69-0.11,1.2-0.19,1.74-0.28c-0.03-0.71-0.05-1.22-0.08-1.81
	C598.68,599.64,598.15,599.7,597.49,599.78z M576.29,397.32c0.61,0,1.14,0,1.82,0c-0.07-0.64-0.12-1.17-0.18-1.7
	c-0.62,0-1.16,0-1.86,0C576.15,396.26,576.22,396.78,576.29,397.32z M498.11,496.41c0.08,0.7,0.14,1.2,0.21,1.8
	c0.62-0.04,1.13-0.07,1.79-0.12c-0.09-0.64-0.16-1.14-0.24-1.68C499.33,496.41,498.87,496.41,498.11,496.41z M574.16,247.85
	c0.11,0.72,0.18,1.23,0.26,1.8c0.59-0.06,1.05-0.1,1.5-0.14C575.76,247.92,575.76,247.92,574.16,247.85z M536.51,397.27
	c1.76-0.1,1.76-0.1,1.27-1.79c-0.44,0.04-0.88,0.07-1.45,0.12C536.4,396.25,536.45,396.76,536.51,397.27z M545.54,457.76
	c0.83,0.01,1.63,0.09,1.46-1.01c-0.04-0.25-0.49-0.6-0.76-0.61C545.11,456.11,545.28,456.85,545.54,457.76z M554.5,457.17
	c-0.11-0.61-0.2-1.12-0.3-1.71c-0.63,0.1-1.13,0.18-1.58,0.26C552.33,457.27,552.91,457.7,554.5,457.17z M515.76,530.99
	c-0.01,1.07,0.05,1.96,1.19,1.6c0.34-0.11,0.43-0.97,0.68-1.6C516.8,530.99,516.36,530.99,515.76,530.99z M549.06,479.3
	c-0.16-0.84,0.16-1.86-1.16-1.57c-1.09,0.24-0.61,1-0.39,1.69C548.01,479.39,548.46,479.35,549.06,479.3z M545.48,497.59
	c0.77-0.03,1.69,0.39,1.55-0.85c-0.04-0.32-0.49-0.84-0.77-0.85C545.14,495.85,545.3,496.65,545.48,497.59z M524.84,532.03
	c-0.35-0.64,0.29-1.61-0.93-1.56c-0.31,0.01-0.83,0.48-0.86,0.78C522.93,532.34,523.69,532.26,524.84,532.03z M523.95,499.6
	c0.56-0.09,1.05-0.17,1.52-0.25c0-0.88,0.1-1.73-1.04-1.52C523.25,498.05,523.83,498.83,523.95,499.6z M547.35,437.96
	c0.16,0.86-0.02,1.78,1.12,1.57c0.28-0.05,0.67-0.62,0.67-0.95C549.14,437.28,548.25,437.9,547.35,437.96z M507.55,437.91
	c-0.21,1.57,0.34,2.01,1.7,1.38C509.66,437.81,508.95,437.56,507.55,437.91z M543.62,394.76c0.22,1.76,0.22,1.76,1.77,1.42
	c-0.08-0.5-0.16-1.01-0.25-1.53C544.6,394.69,544.22,394.72,543.62,394.76z M549.3,419.67c0.09,0.9-0.1,1.88,1.15,1.63
	c1.13-0.22,0.56-1.02,0.37-1.71C550.32,419.62,549.87,419.64,549.3,419.67z M532.85,459c-0.3-0.65,0.09-1.65-1.09-1.46
	c-0.23,0.04-0.56,0.5-0.57,0.79C531.13,459.39,531.77,459.4,532.85,459z M559.06,427.88c-0.03-0.72,0.31-1.59-0.84-1.48
	c-0.29,0.03-0.78,0.59-0.76,0.87C557.54,428.45,558.36,427.91,559.06,427.88z M516.08,557.94c-0.04-0.52-0.08-1.02-0.13-1.63
	c-0.64,0.07-1.15,0.13-1.61,0.18C514,558.14,514.68,558.41,516.08,557.94z M523.8,418.3c0.06,0.63,0.11,1.19,0.17,1.81
	c0.7-0.1,1.22-0.18,1.8-0.27c-0.06-0.6-0.11-1.05-0.15-1.55C525.02,418.3,524.48,418.3,523.8,418.3z M522.04,564.76
	c0.88-0.06,1.87,0.24,1.75-0.98c-0.14-1.33-1.07-0.61-1.82-0.56C522,563.77,522.02,564.21,522.04,564.76z M550.86,394.42
	c-0.09,0.88-0.08,1.65,1.01,1.46c1.24-0.22,0.62-0.98,0.4-1.66C551.76,394.29,551.31,394.36,550.86,394.42z M538.97,528.9
	c-0.88,0.31-1.84,0.05-1.62,1.2c0.24,1.27,1.04,0.73,1.77,0.54C539.07,530.09,539.03,529.64,538.97,528.9z M586.15,530.3
	c-0.08-0.72-0.13-1.17-0.19-1.74c-0.71,0.08-1.3,0.14-1.98,0.21c0.11,0.62,0.19,1.07,0.27,1.53
	C584.87,530.3,585.41,530.3,586.15,530.3z M612.3,584.08c-0.63,0.09-1.13,0.16-1.74,0.24c0.29,0.71-0.28,1.66,0.92,1.69
	c0.29,0.01,0.59-0.1,0.98-0.17C612.41,585.23,612.36,584.72,612.3,584.08z M525.55,457.87c-0.67,0.1-1.12,0.17-1.59,0.24
	c-0.05,0.89-0.15,1.77,1.06,1.6C526.17,459.54,525.57,458.71,525.55,457.87z M558,393.73c0.07,0.92-0.17,1.83,1.01,1.68
	c1.2-0.16,0.65-0.97,0.59-1.68C559.09,393.73,558.65,393.73,558,393.73z M549.34,501.1c0.68-0.13,1.16-0.22,1.65-0.31
	c-0.09-0.83,0.02-1.66-1.07-1.53C548.79,499.4,549.26,500.18,549.34,501.1z M525.78,481.36c0.85-0.05,1.78,0.2,1.56-0.99
	c-0.06-0.31-0.57-0.77-0.87-0.77C525.27,479.63,525.74,480.5,525.78,481.36z M519.65,534.45c0.03,0.81-0.22,1.72,0.95,1.55
	c0.29-0.04,0.7-0.57,0.71-0.89C521.35,533.9,520.53,534.26,519.65,534.45z M520.19,496.22c0.48-0.07,0.97-0.15,1.49-0.23
	c-0.07-0.59-0.13-1.03-0.18-1.46C519.92,494.61,519.92,494.61,520.19,496.22z M531.11,417.7c-0.03,1.01-0.16,1.94,1.11,1.67
	c1.13-0.24,0.56-1.01,0.49-1.67C532.17,417.7,531.73,417.7,531.11,417.7z M579.32,245.43c-0.35-1.43-0.35-1.43-1.95-1.32
	c0.09,0.51,0.17,1.01,0.26,1.54C578.23,245.57,578.69,245.51,579.32,245.43z M528.91,495.67c-0.09-0.94,0.12-1.94-1.15-1.68
	c-1.1,0.23-0.57,1.01-0.5,1.68C527.8,495.67,528.24,495.67,528.91,495.67z M527.25,455.88c0.72-0.01,1.53,0.36,1.58-0.81
	c0.03-0.69-0.29-1.07-1.01-0.92C526.63,454.38,527.21,455.17,527.25,455.88z M587.08,249.98c-0.81,0.6-1.96,0.17-1.79,1.47
	c0.04,0.28,0.07,0.55,0.11,0.92c0.67-0.1,1.18-0.18,1.78-0.27C587.15,251.46,587.12,250.94,587.08,249.98z M526.03,439.82
	c0.03,1.65,0.03,1.65,1.56,1.37c-0.03-0.45-0.06-0.9-0.09-1.45C526.92,439.77,526.47,439.8,526.03,439.82z M543.75,501.37
	c-0.07-0.62-0.13-1.12-0.19-1.69c-0.62,0.04-1.07,0.07-1.65,0.1c0.08,0.63,0.14,1.14,0.21,1.71
	C542.73,501.45,543.18,501.42,543.75,501.37z M560.1,480.1c-0.68,0.12-1.2,0.21-1.83,0.32c0.06,0.62,0.11,1.13,0.17,1.74
	c0.68-0.07,1.19-0.12,1.78-0.18C560.17,481.28,560.14,480.76,560.1,480.1z M529.14,477.53c0.68-0.08,1.17-0.14,1.79-0.22
	c-0.28-0.71,0.05-1.64-1.09-1.58C528.69,475.8,529.08,476.6,529.14,477.53z M561.36,555.96c0.04,0.89-0.16,1.88,1.08,1.59
	c0.31-0.07,0.43-1,0.72-1.76C562.29,555.88,561.85,555.92,561.36,555.96z M523.12,557.41c-0.06-1.51-0.06-1.51-1.52-1.24
	C521.79,557.5,521.79,557.5,523.12,557.41z M585.02,308.27c-1.28,0.11-2.39,0.1-1.63,1.83c0.54-0.04,1.12-0.09,1.73-0.14
	C585.09,309.36,585.06,308.91,585.02,308.27z M586.56,244.97c-0.1-0.64-0.19-1.2-0.28-1.76c-0.88,0.1-1.77-0.15-1.62,1.05
	c0.04,0.28,0.11,0.56,0.17,0.89C585.48,245.08,585.94,245.03,586.56,244.97z M594.33,606.04c0.82-0.67,1.35-1,1.7-1.46
	c0.15-0.2-0.09-0.69-0.17-1.11c-0.57,0.08-1.02,0.15-1.53,0.22C594.33,604.29,594.33,604.81,594.33,606.04z M588.04,346.01
	c-0.1-0.75-0.16-1.27-0.24-1.83c-0.56,0.03-1,0.06-1.58,0.09c0.03,0.65,0.06,1.16,0.09,1.74
	C586.87,346.01,587.31,346.01,588.04,346.01z M552.81,415.86c-0.05,0.9-0.24,1.82,1.01,1.55c1.07-0.23,0.6-0.98,0.38-1.67
	C553.71,415.78,553.28,415.82,552.81,415.86z M501.85,494.36c0.65-0.11,1.14-0.2,1.65-0.29c-0.14-0.77,0.11-1.68-1.03-1.55
	C501.39,492.66,501.7,493.42,501.85,494.36z M520.5,503.49c0.87-0.13,1.81,0.11,1.56-1.07c-0.06-0.31-0.59-0.75-0.89-0.74
	C519.96,501.74,520.48,502.61,520.5,503.49z M497.96,474.74c-0.76,0-1.29,0-2.01,0c0.16,0.7,0.3,1.28,0.45,1.93
	c0.63-0.1,1.14-0.17,1.71-0.26C498.05,475.78,498.01,475.32,497.96,474.74z M605.33,584.54c-0.77,0.22-1.34,0.38-1.9,0.54
	c0.32,1.37,1.15,1.79,2.09,1.17C605.47,585.8,605.42,585.34,605.33,584.54z M541.34,557.76c-0.7,0.19-1.62-0.3-1.53,0.91
	c0.02,0.31,0.47,0.8,0.77,0.83C541.71,559.62,541.52,558.82,541.34,557.76z M530.87,435.9c-0.85,0.01-1.74-0.23-1.58,0.94
	c0.04,0.31,0.54,0.78,0.84,0.79C531.35,437.66,530.89,436.76,530.87,435.9z M550.6,453.88c-0.22-0.74,0.26-1.7-0.97-1.55
	c-0.32,0.04-0.81,0.51-0.83,0.81C548.71,454.34,549.6,453.94,550.6,453.88z M552.31,473.47c-0.72,0.44-1.25,0.65-1.59,1.03
	c-0.1,0.11,0.24,0.83,0.53,1.02c0.24,0.16,0.72-0.04,1.16-0.09C552.38,474.84,552.36,474.39,552.31,473.47z M556.86,525.65
	c-0.13-0.71-0.22-1.2-0.33-1.75c-0.69,0.1-1.21,0.17-1.81,0.25c0.12,0.68,0.21,1.18,0.31,1.7
	C555.66,525.78,556.13,525.73,556.86,525.65z M636.52,612.82c-0.16-0.91,0.26-1.91-1.07-1.84c-0.22,0.01-0.43,0.11-0.76,0.21
	c0.03,0.54,0.07,1.05,0.1,1.63C635.37,612.82,635.82,612.82,636.52,612.82z M563.95,523.17c-1.05,0.15-1.99,0-1.84,1.25
	c0.03,0.21,0.15,0.42,0.26,0.69c0.55-0.03,1.07-0.06,1.7-0.09C564.03,524.36,563.99,523.85,563.95,523.17z M553.37,402.99
	c-0.19-0.77,0.26-1.7-0.96-1.64c-0.7,0.04-0.94,0.4-0.84,1.07C551.76,403.58,552.52,403.07,553.37,402.99z M545.95,504.84
	c0.9,0,1.75,0.13,1.54-0.99c-0.06-0.3-0.58-0.73-0.88-0.72C545.45,503.19,545.91,504.03,545.95,504.84z M523.53,537.8
	c0.04,0.74-0.4,1.65,0.81,1.58c0.3-0.02,0.8-0.49,0.82-0.79C525.25,537.49,524.51,537.55,523.53,537.8z M586.89,392.97
	c0.86-0.12,1.76,0.08,1.54-1.07c-0.05-0.29-0.61-0.68-0.93-0.67C586.23,391.23,586.8,392.09,586.89,392.97z M527.37,461.44
	c0.52,0.79,0.76,1.29,1.14,1.63c0.12,0.1,0.85-0.17,0.92-0.39c0.12-0.39-0.04-0.88-0.09-1.36
	C528.82,461.35,528.43,461.37,527.37,461.44z M548.05,492.13c0.46,0.79,0.67,1.31,1.04,1.68c0.11,0.1,0.86-0.16,0.95-0.39
	c0.15-0.39-0.01-0.88-0.04-1.38C549.47,492.06,549.09,492.08,548.05,492.13z M515.94,538.14c0.43,0.81,0.68,1.28,0.93,1.75
	c0.37-0.2,0.87-0.31,1.07-0.62c0.16-0.24-0.03-0.73-0.06-1.13C517.36,538.14,516.97,538.14,515.94,538.14z M551.24,482.87
	c0.68-0.1,1.12-0.16,1.6-0.23c0.02-0.88,0.15-1.78-1.07-1.6C550.63,481.21,551.2,482.04,551.24,482.87z M500.77,505.34
	c-0.05-0.73-0.08-1.25-0.12-1.89c-0.65,0.05-1.17,0.1-1.79,0.15c0.04,0.65,0.07,1.17,0.11,1.74
	C499.56,505.34,500.01,505.34,500.77,505.34z M505.93,481.43c-1.61,0.34-1.61,0.34-1.18,1.85c0.43-0.04,0.86-0.09,1.36-0.14
	C506.04,482.52,506,482.07,505.93,481.43z M544,483.23c1.08,0.08,1.82,0.06,1.59-1.05c-0.24-1.22-1-0.6-1.7-0.49
	C543.93,482.22,543.96,482.68,544,483.23z M526.62,414.31c0.65,0.86,0.94,1.41,1.39,1.77c0.18,0.15,0.7-0.11,1.15-0.19
	c-0.12-0.63-0.21-1.13-0.3-1.58C528.25,414.31,527.8,414.31,526.62,414.31z M537.62,556.02c-0.14-1.62-0.14-1.62-1.67-1.33
	C535.81,555.85,536.13,556.5,537.62,556.02z M552.13,575.11c0.14,1.59,0.14,1.59,1.71,1.3C554.14,575.06,553.6,574.64,552.13,575.11
	z M545.79,523.12c-0.09-0.74-0.15-1.26-0.21-1.77c-1.75,0.15-1.75,0.15-1.42,1.86C544.6,523.18,545.06,523.16,545.79,523.12z
	 M523.17,492.32c1.06,0.19,1.82-0.01,1.49-1.16c-0.31-1.11-1.04-0.5-1.71-0.26C523.03,491.44,523.1,491.89,523.17,492.32z
	 M536.47,563.39c0.88,0.04,1.81,0.3,1.65-1.01c-0.17-1.36-1.08-0.65-1.88-0.71C536.34,562.35,536.41,562.86,536.47,563.39z
	 M506.6,551.83c0.86-0.02,1.8,0.25,1.62-1.08c-0.16-1.17-0.91-0.76-1.62-0.67C506.6,550.72,506.6,551.24,506.6,551.83z
	 M587.12,606.26c0.75-0.13,1.27-0.22,1.76-0.3c0.12-1.37-0.27-2.06-1.76-1.51C587.12,604.95,587.12,605.46,587.12,606.26z
	 M526.41,506.59c-0.56-0.7-0.91-1.14-1.27-1.58c-0.23,0.31-0.66,0.63-0.66,0.94C524.49,507.13,525.27,506.73,526.41,506.59z
	 M562.71,405.72c0.58,0,1.1,0,1.75,0c-0.05-0.68-0.1-1.26-0.15-1.92c-0.68,0.15-1.19,0.26-1.77,0.39
	C562.6,404.76,562.66,405.21,562.71,405.72z M526.92,474.05c-0.04-0.96,0.16-1.84-1.02-1.66c-1.2,0.19-0.6,0.99-0.58,1.66
	C525.86,474.05,526.3,474.05,526.92,474.05z M532.24,451.94c-0.02-0.88,0.12-1.81-1.11-1.61c-1.16,0.19-0.54,0.99-0.53,1.73
	C531.21,452.02,531.71,451.98,532.24,451.94z M540.75,550.47c-0.59,0.03-1.05,0.06-1.65,0.09c0.03,0.64,0.06,1.15,0.09,1.67
	C540.69,552.55,541.21,552.07,540.75,550.47z M583.02,328.31c-0.15-0.73-0.26-1.23-0.37-1.77c-0.66,0.09-1.18,0.16-1.8,0.24
	c0.06,0.58,0.1,1.03,0.15,1.53C581.65,328.31,582.18,328.31,583.02,328.31z M557.66,406.36c-0.6-0.81-0.86-1.3-1.26-1.61
	c-0.11-0.09-0.77,0.3-0.94,0.61c-0.15,0.25,0.05,0.7,0.1,1.13C556.11,406.46,556.55,406.43,557.66,406.36z M498.68,483.56
	c-0.06-1.67-0.06-1.67-1.75-1.31C496.79,483.46,497.2,484.06,498.68,483.56z M518.78,546.94c-0.09-0.87,0.2-1.9-1.13-1.59
	c-1.1,0.26-0.56,1.04-0.36,1.73C517.78,547.04,518.24,547,518.78,546.94z M518.92,507.06c-0.12-1.61-0.12-1.61-1.7-1.31
	C517.13,506.88,517.42,507.56,518.92,507.06z M583.82,255.96c-0.05-0.61-0.09-1.13-0.14-1.81c-0.64,0.11-1.15,0.19-1.75,0.3
	c0.08,0.63,0.14,1.14,0.21,1.73C582.77,256.1,583.23,256.04,583.82,255.96z M545.34,562.85c0.2-1.57-0.33-2-1.68-1.47
	c0.04,0.47,0.07,0.97,0.11,1.47C544.37,562.85,544.82,562.85,545.34,562.85z M542.33,507.09c0.43,0.69,0.65,1.19,1.02,1.53
	c0.12,0.11,0.85-0.14,0.93-0.35c0.14-0.38-0.01-0.87-0.04-1.4C543.66,506.94,543.21,506.99,542.33,507.09z M529.88,444.8
	c1.08,0.22,1.82,0.01,1.5-1.14c-0.31-1.11-1.04-0.5-1.71-0.26C529.75,443.93,529.82,444.38,529.88,444.8z M525.97,465.27
	c-1.55,0.09-1.55,0.09-1.21,1.64C526.25,467.14,526.37,466.41,525.97,465.27z M522.06,543.14c-0.04-0.77,0.43-1.7-0.76-1.67
	c-1.34,0.04-0.88,0.94-0.77,1.77C521.11,543.2,521.56,543.17,522.06,543.14z M561.66,541.52c-0.73,0.09-1.25,0.16-1.87,0.24
	c0.06,0.63,0.11,1.15,0.16,1.72c0.76-0.11,1.36-0.2,2.02-0.3C561.87,542.62,561.78,542.17,561.66,541.52z M556.88,414.12
	c0.37-0.72,0.73-1.1,0.68-1.4c-0.06-0.31-0.53-0.53-0.82-0.8c-0.21,0.27-0.6,0.54-0.59,0.8
	C556.17,413.07,556.49,413.41,556.88,414.12z M598.37,608.96c0.66-0.1,1.1-0.17,1.56-0.25c0.03-0.9,0.24-1.85-1.02-1.67
	C597.8,607.21,598.35,608.08,598.37,608.96z M515.06,543.55c-0.33-0.7,0.23-1.64-0.99-1.62c-0.66,0.01-0.98,0.29-0.9,1
	C513.32,544.07,514.06,543.68,515.06,543.55z M522.49,443.68c0.02,0.87-0.21,1.85,1.03,1.64c1.23-0.21,0.72-1.04,0.46-1.77
	C523.43,443.6,522.99,443.64,522.49,443.68z M553.87,448.53c-0.15-0.15-0.3-0.29-0.45-0.44c-0.43,0.37-0.91,0.71-1.27,1.15
	c-0.12,0.15,0.08,0.55,0.15,0.93c0.6-0.05,1.1-0.1,1.64-0.14C553.91,449.44,553.89,448.99,553.87,448.53z M521.13,509.03
	c-0.19,1.43,0.31,1.87,1.56,1.39C523.01,509.09,522.66,508.53,521.13,509.03z M625.93,615.45c-0.68,0.06-1.25,0.12-1.9,0.18
	c0.07,0.71,0.12,1.23,0.17,1.79c0.73-0.08,1.33-0.15,2.03-0.23C626.12,616.55,626.03,616.04,625.93,615.45z M575.45,406.71
	c-0.77,0.1-1.35,0.18-2.03,0.28c0.11,0.61,0.2,1.05,0.3,1.59c0.67-0.09,1.24-0.17,1.85-0.25
	C575.53,407.78,575.5,407.39,575.45,406.71z M524.12,483.35c-0.94,0.05-1.79-0.03-1.54,1.09c0.07,0.3,0.63,0.72,0.93,0.69
	C524.72,485.03,524.14,484.17,524.12,483.35z M602.78,597.62c-0.18-0.78-0.3-1.29-0.48-2.1c-0.58,0.38-1.15,0.56-1.35,0.93
	c-0.18,0.33,0.06,0.88,0.12,1.4C601.65,597.78,602.1,597.72,602.78,597.62z M610.02,577.16c0.1,0.61,0.19,1.12,0.29,1.71
	c0.64-0.1,1.15-0.18,1.61-0.25C612.14,577.14,611.68,576.55,610.02,577.16z M527.25,433.35c-0.54-0.37-0.89-0.78-1.17-0.74
	c-0.27,0.04-0.7,0.57-0.66,0.83c0.05,0.32,0.47,0.76,0.79,0.81C526.44,434.29,526.77,433.79,527.25,433.35z M519.34,552.2
	c-0.7,0.37-1.23,0.53-1.58,0.88c-0.14,0.14,0.03,0.93,0.21,1c0.42,0.16,0.94,0.05,1.48,0.05
	C519.42,553.47,519.39,553.03,519.34,552.2z M553.42,424.67c0.84,0.02,1.59,0.03,1.43-1.02c-0.04-0.24-0.47-0.6-0.73-0.61
	C553,423,553.19,423.75,553.42,424.67z M561.08,409.77c0.03-1.06,0.04-1.95-1.19-1.66c-1.12,0.27-0.57,1.02-0.43,1.66
	C560,409.77,560.46,409.77,561.08,409.77z M519.13,487.35c-0.01,0.89-0.14,1.84,1.05,1.63c1.24-0.22,0.67-1.04,0.45-1.75
	C520.07,487.27,519.63,487.3,519.13,487.35z M544.67,514.09c-0.64,0.19-1.51-0.32-1.52,0.91c0,0.72,0.37,1.01,1.04,0.87
	C545.38,515.63,544.77,514.87,544.67,514.09z M573.28,260.57c-0.09-0.64-0.17-1.22-0.25-1.85c-0.62,0.06-1.07,0.1-1.63,0.15
	c0.07,0.73,0.13,1.31,0.2,1.99C572.24,260.75,572.7,260.67,573.28,260.57z M523,469.02c-1.67,0.2-1.67,0.2-1.33,1.76
	c0.43-0.03,0.87-0.06,1.42-0.1C523.05,470.09,523.03,469.66,523,469.02z M528.47,448.6c-0.31-0.72,0.29-1.7-0.93-1.66
	c-1.34,0.04-0.88,0.95-0.77,1.78C527.35,448.67,527.8,448.64,528.47,448.6z M532.52,412.19c-0.12-0.79-0.19-1.31-0.28-1.9
	c-0.63,0.11-1.14,0.21-1.74,0.32c0.11,0.62,0.19,1.13,0.27,1.59C531.35,412.19,531.8,412.19,532.52,412.19z M549.58,484.79
	c-0.89,0.08-1.85-0.14-1.58,1.16c0.24,1.16,0.99,0.63,1.67,0.41C549.64,485.83,549.62,485.37,549.58,484.79z M550.35,578.82
	c-1.52,0.14-1.52,0.14-1.3,1.75C550.34,580.78,550.84,580.31,550.35,578.82z M502.47,485.39c-0.93-0.03-1.75-0.12-1.48,0.99
	c0.07,0.29,0.62,0.69,0.92,0.66C503.09,486.95,502.57,486.14,502.47,485.39z M519.84,559.67c-0.7,0.06-1.15,0.1-1.74,0.15
	c0.15,0.76-0.35,1.61,0.84,1.69c0.29,0.02,0.6-0.08,0.98-0.14C519.89,560.82,519.87,560.37,519.84,559.67z M557.66,514.77
	c0.71-0.1,1.21-0.17,1.82-0.26c-0.11-0.64-0.2-1.12-0.29-1.68c-0.68,0.14-1.17,0.23-1.69,0.34
	C557.55,513.72,557.59,514.1,557.66,514.77z M519.31,512.64c-0.65,0.09-1.14,0.15-1.67,0.22c0.13,0.76-0.17,1.66,0.98,1.59
	C519.74,514.38,519.43,513.6,519.31,512.64z M534.14,433.75c0.01-1.02,0.02-1.94-1.15-1.59c-0.35,0.11-0.46,0.97-0.79,1.73
	C533.08,433.83,533.52,433.8,534.14,433.75z M566.46,553.78c-0.09-0.75-0.15-1.27-0.23-1.87c-0.64,0.07-1.16,0.13-1.75,0.19
	c0.08,0.65,0.14,1.17,0.21,1.68C565.27,553.78,565.72,553.78,566.46,553.78z M539.1,449.62c-1.24-0.14-1.79,0.26-1.27,1.55
	C539.35,451.1,539.35,451.1,539.1,449.62z M547.25,542.67c-0.71,0.15-1.21,0.26-1.72,0.37c0.1,0.67,0.19,1.2,0.28,1.82
	c0.71-0.09,1.24-0.15,1.84-0.23C547.53,544,547.42,543.47,547.25,542.67z M508.95,454.34c0.78-0.66,1.62-1.07,1.6-1.42
	c-0.09-1.27-1.06-0.66-1.86-0.69C508.76,452.79,508.81,453.24,508.95,454.34z M552.97,441.12c-0.71,0.08-1.15,0.13-1.71,0.2
	c0.11,0.65,0.19,1.14,0.28,1.69c0.58-0.06,1.01-0.11,1.51-0.16C553.02,442.22,553,441.77,552.97,441.12z M503.86,540.92
	c-0.07-0.67-0.12-1.18-0.19-1.8c-0.74,0.16-1.31,0.29-1.97,0.44c0.24,0.67,0.42,1.16,0.59,1.65
	C502.82,541.11,503.21,541.04,503.86,540.92z M555.41,464.53c-0.25-0.78-0.43-1.35-0.62-1.95c-0.58,0.13-1.03,0.22-1.52,0.33
	c0.09,0.62,0.17,1.12,0.24,1.62C554.16,464.53,554.62,464.53,555.41,464.53z M445.08,31.37c-0.58,0.07-0.96,0.09-1.34,0.18
	c-0.12,0.03-0.21,0.2-0.31,0.31c0.31,1.41,0.91,1.78,1.89,1.14C445.26,32.54,445.19,32.08,445.08,31.37z M566.96,392.76
	c-0.77,0.16-1.32,0.27-2.36,0.49c0.71,0.69,1.09,1.15,1.56,1.48c0.15,0.11,0.54-0.11,0.88-0.2
	C567.02,393.98,567,393.53,566.96,392.76z M559.64,435.19c-0.05-1.01-0.07-1.82-1.13-1.55c-1.05,0.27-0.66,0.99-0.38,1.68
	C558.62,435.28,559.07,435.24,559.64,435.19z M534.23,534.83c0.75,0,1.27,0,1.92,0c-0.18-0.7-0.34-1.28-0.5-1.9
	c-0.59,0.11-1.05,0.2-1.61,0.31C534.11,533.79,534.16,534.24,534.23,534.83z M535.19,567.22c-0.07-0.62-0.13-1.13-0.2-1.73
	c-0.78,0.19-1.73-0.24-1.79,0.99c-0.01,0.27,0.15,0.56,0.25,0.92C534.05,567.34,534.56,567.28,535.19,567.22z M548.25,304.19
	c-0.72,0.13-1.22,0.22-1.8,0.33c0.17,0.69,0.3,1.26,0.46,1.89c0.67-0.1,1.19-0.18,1.76-0.27
	C548.53,305.5,548.42,304.98,548.25,304.19z M557.06,531.26c-1.63,0.08-1.63,0.08-1.23,1.72
	C557.11,533.02,557.6,532.5,557.06,531.26z M528.62,430.53c0.69-0.07,1.13-0.11,1.64-0.16c-0.13-0.72,0.41-1.6-0.79-1.66
	c-0.28-0.01-0.57,0.09-0.85,0.14C528.62,429.41,528.62,429.86,528.62,430.53z M396.11,50.06c-0.68,0.18-1.15,0.3-1.62,0.42
	c0.32,1.49,0.97,1.88,1.88,1.22C396.3,51.25,396.23,50.8,396.11,50.06z M554.79,399.23c1.73,0.28,1.73-0.54,1.41-1.71
	c-0.5,0.03-0.98,0.06-1.56,0.1C554.7,398.25,554.74,398.7,554.79,399.23z M609.18,547.98c-0.77,0.12-1.29,0.19-1.92,0.29
	c0.23,0.7,0.42,1.28,0.61,1.87c0.59-0.08,1.05-0.15,1.59-0.22C609.37,549.26,609.29,548.74,609.18,547.98z M501.72,478.23
	c-1.4-0.23-1.51,0.46-1.18,1.57C502.03,480.07,502.12,479.36,501.72,478.23z M519.2,527.18c-0.07,1.06-0.23,2.05,1.45,1.48
	c-0.05-0.5-0.09-1.01-0.15-1.63C520.01,527.09,519.64,527.13,519.2,527.18z M533.67,426.53c-0.09-0.63-0.16-1.14-0.23-1.63
	c-1.63-0.17-1.71,0.6-1.33,1.75C532.61,426.61,533.07,426.57,533.67,426.53z M535.13,401.12c-0.08-0.63-0.15-1.14-0.23-1.77
	c-0.52,0.04-0.96,0.08-1.55,0.13c0.05,0.63,0.1,1.18,0.15,1.8C534.12,401.22,534.56,401.18,535.13,401.12z M566.42,116.69
	c0.59-0.06,0.98-0.08,1.36-0.16c0.12-0.02,0.21-0.21,0.31-0.32c-0.3-1.39-0.95-1.78-1.92-1.14
	C566.24,115.52,566.31,115.99,566.42,116.69z M535.16,527.53c-0.12-1.68-0.12-1.68-1.8-1.33c0.05,0.51,0.1,1.04,0.16,1.62
	C534.1,527.72,534.57,527.63,535.16,527.53z M535.05,421.12c0.05,0.58,0.09,1.1,0.14,1.75c0.57-0.08,1.01-0.14,1.58-0.21
	c-0.1-0.63-0.18-1.12-0.27-1.66C535.95,421.04,535.56,421.08,535.05,421.12z M579.95,252.54c-0.07-0.65-0.12-1.22-0.19-1.87
	c-0.7,0.19-1.22,0.33-1.79,0.48c0.13,0.6,0.22,1.04,0.33,1.55C578.89,252.64,579.35,252.6,579.95,252.54z M538.99,404.52
	c-0.08-0.94,0.03-1.91-1.19-1.66c-1.12,0.23-0.55,1.01-0.47,1.66C537.86,404.52,538.31,404.52,538.99,404.52z M520.21,521.6
	c-0.16-0.79,0.38-1.69-0.82-1.73c-0.28-0.01-0.56,0.09-0.94,0.16c0.08,0.61,0.14,1.11,0.2,1.57
	C519.19,521.6,519.58,521.6,520.21,521.6z M582.9,247.07c-0.54,0.06-1,0.1-1.61,0.17c0.06,0.64,0.11,1.21,0.16,1.78
	C582.75,248.98,583.54,248.68,582.9,247.07z M479.82,35.81c0.09,0.57,0.18,1.09,0.28,1.73c0.68-0.15,1.17-0.26,1.61-0.35
	C481.83,35.72,481.3,35.26,479.82,35.81z M556.82,505.82c0.08,0.74,0.13,1.25,0.21,2c0.66-0.55,1.82,0.02,1.68-1.33
	c-0.02-0.22-0.11-0.43-0.18-0.68C558.03,505.82,557.58,505.82,556.82,505.82z M551.43,508.07c-0.07-0.86,0.25-1.97-1.45-1.49
	C549.82,507.63,549.76,508.54,551.43,508.07z M543.36,555.69c0.5-0.04,0.93-0.07,1.57-0.11c-0.3-0.79,0.26-1.87-1.18-1.69
	c-0.2,0.03-0.4,0.15-0.65,0.26C543.19,554.67,543.27,555.12,543.36,555.69z M564.73,411.07c-0.59,0.1-1.11,0.18-1.73,0.28
	c0.11,0.7,0.2,1.22,0.28,1.72C564.64,413.11,565.4,412.74,564.73,411.07z M559.47,554.22c-0.44-0.64-0.66-1.19-1.07-1.47
	c-0.18-0.12-0.93,0.21-1.07,0.5c-0.16,0.35,0.05,0.87,0.11,1.38C558.06,554.51,558.57,554.41,559.47,554.22z M548.16,510.24
	c-0.65,0.07-1.1,0.11-1.65,0.17c0.06,0.59,0.1,1.05,0.15,1.54c0.53,0,0.98,0,1.5,0C548.16,511.41,548.16,510.95,548.16,510.24z
	 M533.31,545.7c-0.08-0.63-0.14-1.14-0.2-1.63c-1.16,0.06-2.16,0.14-1.43,1.76C532.14,545.79,532.65,545.75,533.31,545.7z
	 M580.31,590.49c-0.6,0.11-1.11,0.21-1.61,0.31c0.21,1.47,0.21,1.47,1.87,1.2C580.5,591.55,580.42,591.11,580.31,590.49z
	 M510.61,515.26c1.46-0.12,2.06-0.52,1.32-1.8c-0.45,0.05-0.89,0.1-1.42,0.16C510.55,514.18,510.57,514.62,510.61,515.26z
	 M535.93,515.15c0.08,0.53,0.15,1.01,0.23,1.55c0.79-0.17,1.71,0.06,1.5-1.09C537.45,514.44,536.68,515,535.93,515.15z
	 M571.41,591.29c0.08,0.69,0.14,1.15,0.19,1.61c1.27,0.09,1.92-0.24,1.38-1.68C572.53,591.24,572.09,591.26,571.41,591.29z
	 M582.55,241.5c-0.11-1.57-0.11-1.57-1.81-1.3c0.11,0.5,0.21,0.99,0.33,1.53C581.66,241.64,582.11,241.57,582.55,241.5z
	 M507.64,518.94c1.56-0.23,1.56-0.23,1.14-1.69C507.65,517.16,507.15,517.57,507.64,518.94z M555.68,516.81
	c-0.5,0.08-1,0.17-1.54,0.26c0.08,0.59,0.14,1.05,0.2,1.53C555.62,518.59,556.3,518.26,555.68,516.81z M533.08,520.48
	c0.51-0.06,0.96-0.12,1.53-0.19c-0.05-0.57-0.09-1.08-0.13-1.57C533.18,518.57,532.55,518.91,533.08,520.48z M566.2,593.59
	c-0.36-0.72,0.3-1.73-1.03-1.78c-0.21-0.01-0.43,0.11-0.74,0.2c0.04,0.54,0.07,1.06,0.12,1.71
	C565.1,593.67,565.54,593.64,566.2,593.59z M558.26,500.15c-0.1-0.74-0.17-1.24-0.23-1.72c-1.24,0.09-2.13,0.3-1.39,1.8
	C557.07,500.22,557.53,500.19,558.26,500.15z M565.47,420.07c-0.04-1.58-0.03-1.64-0.58-1.7c-0.35-0.04-0.72,0.13-1.17,0.23
	c0.11,0.62,0.19,1.12,0.29,1.71C564.6,420.21,565.04,420.14,565.47,420.07z M576.6,402.81c0.55,0.73-0.17,1.97,1.24,1.83
	c0.22-0.02,0.43-0.12,0.74-0.21c-0.05-0.55-0.09-1.07-0.15-1.71C577.87,402.74,577.42,402.76,576.6,402.81z M542.3,422.12
	c1.01-0.06,1.82-0.08,1.57-1.13c-0.27-1.17-1.03-0.51-1.7-0.36C542.22,421.11,542.26,421.55,542.3,422.12z M505.74,522.6
	c0.23-1.61-0.59-1.62-1.79-1.27c0.1,0.5,0.19,0.99,0.3,1.52C504.86,522.76,505.3,522.68,505.74,522.6z M510.41,491.65
	c-0.68,0.33-1.61-0.1-1.54,1.12c0.04,0.6,0.28,0.8,0.88,0.76C510.92,493.44,510.63,492.71,510.41,491.65z M589.2,255.45
	c0.82-0.07,1.4-0.12,2.11-0.18c-0.16-0.61-0.27-1.05-0.42-1.62c-0.62,0.1-1.14,0.19-1.69,0.28
	C589.2,254.38,589.2,254.76,589.2,255.45z M530.37,491.59c0.81,0.04,1.68,0.2,1.49-0.95c-0.2-1.2-1.02-0.59-1.75-0.46
	C530.19,490.67,530.28,491.12,530.37,491.59z M544.57,488.81c0.01,0.91-0.06,1.91,1.18,1.58c1.05-0.28,0.53-1.06,0.28-1.74
	C545.51,488.71,545.05,488.76,544.57,488.81z M513.51,488.11c-1.56,0.12-1.56,0.12-1.23,1.64
	C513.29,489.78,513.97,489.53,513.51,488.11z M544.59,403.78c1.58-0.23,1.58-0.23,1.14-1.66
	C544.63,402.02,544.09,402.4,544.59,403.78z M525.34,559.18c0.1,0.89-0.07,1.78,1.06,1.63c1.21-0.16,0.64-0.95,0.59-1.63
	C526.45,559.18,526,559.18,525.34,559.18z M522.44,525.01c0.62-0.05,1.07-0.1,1.99-0.18c-0.58-0.7-0.92-1.49-1.32-1.52
	C521.96,523.22,522.36,524.13,522.44,525.01z M585.17,355.11c-0.73,0.13-1.18,0.21-1.64,0.29c0.07,0.64,0.12,1.16,0.19,1.78
	c0.62-0.06,1.07-0.1,1.61-0.15C585.27,356.37,585.23,355.85,585.17,355.11z M540.88,425.61c-0.07-0.54-0.13-0.98-0.2-1.54
	c-0.62,0.13-1.12,0.24-1.57,0.33C538.97,425.79,539.52,426.12,540.88,425.61z M523.09,550.32c-0.58-0.78-0.85-1.28-1.25-1.61
	c-0.12-0.1-0.81,0.18-0.88,0.4c-0.13,0.39,0.01,0.87,0.04,1.37C521.57,550.43,522.02,550.4,523.09,550.32z M517.6,426.15
	c0,0.56,0,1.07,0,1.57c1.41,0.19,2.07-0.13,1.39-1.65C518.55,426.1,518.1,426.12,517.6,426.15z M574.26,581.92
	c1.3,0,2.13-0.31,1.46-1.88c-0.5,0.06-1,0.13-1.63,0.2C574.15,580.89,574.2,581.41,574.26,581.92z M590.47,309.69
	c0.65-0.08,1.16-0.14,1.76-0.22c-0.15-0.68-0.27-1.24-0.4-1.84c-0.59,0.09-1.03,0.16-1.5,0.23
	C590.37,308.51,590.41,309.02,590.47,309.69z M562.08,564.9c0.69-0.04,1.14-0.06,1.74-0.09c-0.13-0.71-0.23-1.26-0.34-1.86
	c-0.62,0.1-1.07,0.18-1.56,0.26C561.97,563.77,562.02,564.23,562.08,564.9z M544.88,577.76c0.79-0.67,1.61-1.05,1.61-1.42
	c-0.03-1.3-1-0.75-1.83-0.77C544.71,576.15,544.76,576.6,544.88,577.76z M545.11,475.97c-0.09-0.83,0.23-1.79-1.05-1.6
	c-1.19,0.18-0.55,0.98-0.43,1.67C544.1,476.02,544.54,476,545.11,475.97z M503.84,476.05c0.55-0.07,1.04-0.13,1.65-0.21
	c-0.27-0.78-0.05-1.73-1.19-1.53C503.12,474.52,503.72,475.31,503.84,476.05z M584.24,593.63c-0.62,0.06-1.06,0.1-1.63,0.15
	c0.11,0.72,0.2,1.3,0.3,1.93c0.61-0.11,1.05-0.2,1.5-0.28C584.36,594.84,584.31,594.37,584.24,593.63z M501.18,532.33
	c0.06,0.64,0.11,1.1,0.17,1.69c0.79-0.19,1.82,0.24,1.74-1.03C503,531.85,502.06,532.41,501.18,532.33z M534.44,473.17
	c-0.42-0.67-0.61-1.2-0.99-1.5c-0.19-0.15-0.71,0.12-1.16,0.21c0.11,0.58,0.19,1.02,0.28,1.51
	C533.12,473.33,533.57,473.28,534.44,473.17z M513.9,430.09c0.19,0.72,0.32,1.22,0.46,1.74c0.74-0.27,1.73-0.07,1.47-1.22
	C515.59,429.5,514.81,430.01,513.9,430.09z M537.02,547.35c-0.83,0.13-1.93-0.28-1.65,1.05c0.07,0.33,1.02,0.47,1.81,0.8
	C537.1,548.33,537.06,547.88,537.02,547.35z M515.71,469.75c-0.75,0.1-1.68-0.23-1.51,0.95c0.04,0.28,0.53,0.7,0.83,0.71
	C516.19,471.45,515.92,470.66,515.71,469.75z M588.81,271.66c-0.68,0.38-1.21,0.58-1.59,0.94c-0.1,0.1,0.13,0.86,0.35,0.95
	c0.39,0.15,0.89,0,1.41-0.03C588.93,272.93,588.89,272.48,588.81,271.66z M535.56,469.58c1.6,0.06,1.94-0.5,1.37-1.72
	c-0.5,0.06-0.94,0.11-1.47,0.17C535.5,468.59,535.52,469.03,535.56,469.58z M509.96,466.67c0.08,0.8-0.1,1.71,1.06,1.38
	c0.31-0.09,0.75-0.66,0.69-0.91C511.42,465.95,510.66,466.49,509.96,466.67z M517.23,466.18c0.08,0.52,0.16,1.01,0.24,1.53
	c0.79-0.13,1.68,0.08,1.48-1.06C518.75,465.48,517.98,466.04,517.23,466.18z M603.19,602.68c-0.79,0.47-1.3,0.71-1.71,1.06
	c-0.09,0.08,0.1,0.77,0.24,0.8c0.5,0.1,1.04,0.04,1.62,0.04C603.31,604.08,603.28,603.69,603.19,602.68z M589.12,546.25
	c0.18,0.79,0.32,1.37,0.46,1.97c0.6-0.07,1.05-0.12,1.63-0.19c-0.09-0.69-0.17-1.26-0.26-1.92
	C590.33,546.15,589.88,546.19,589.12,546.25z M546.09,463.62c-0.01,0.81,0.06,1.53,1.1,1.24c0.23-0.06,0.52-0.6,0.47-0.86
	C547.42,462.82,546.72,463.41,546.09,463.62z M546.37,570.08c-0.5-0.8-0.04-2.03-1.4-1.71c-1.08,0.25-0.58,1.04-0.45,1.71
	C545.03,570.08,545.49,570.08,546.37,570.08z M556.42,439.04c-0.05-0.63-0.1-1.15-0.14-1.65c-1.41-0.12-1.92,0.36-1.35,1.73
	C555.38,439.09,555.83,439.06,556.42,439.04z M577.44,390.06c-0.08-0.55-0.15-1-0.21-1.45c-1.7,0.16-1.7,0.16-1.43,1.68
	C576.33,390.22,576.85,390.14,577.44,390.06z M577.27,449.82c0.09,0.73,0.15,1.26,0.23,1.91c0.69-0.08,1.21-0.14,1.83-0.22
	c-0.2-0.68-0.37-1.26-0.54-1.84C578.27,449.73,577.88,449.76,577.27,449.82z M514.01,449.72c-0.43-1.45-1.22-1.87-1.93-1.15
	c0.08,0.4,0.17,0.85,0.28,1.4C512.94,449.89,513.39,449.82,514.01,449.72z M540.9,447.48c0.81-0.01,1.7,0.13,1.51-0.99
	c-0.2-1.21-0.99-0.61-1.69-0.41C540.78,446.57,540.84,447.02,540.9,447.48z M510.34,446.72c-0.24-0.66-0.43-1.2-0.62-1.74
	c-1.23,0.49-1.23,0.49-1.13,1.86C509.11,446.81,509.64,446.77,510.34,446.72z M586.9,290.14c-0.82,0.21-1.79-0.29-1.78,0.9
	c0,0.28,0.34,0.73,0.59,0.79c0.4,0.09,0.87-0.08,1.35-0.14C587.01,291.16,586.97,290.77,586.9,290.14z M549.47,445
	c-0.53,0.07-1.03,0.13-1.56,0.2c0.19,0.74-0.12,1.71,1.05,1.51C550.04,446.52,549.67,445.77,549.47,445z M613.52,575.02
	c0.63-0.06,1.15-0.11,1.76-0.16c-0.16-0.69-0.3-1.26-0.45-1.88c-0.56,0.08-0.99,0.14-1.49,0.22
	C613.41,573.84,613.46,574.35,613.52,575.02z M560.16,567.04c-1.04-0.23-1.52,0.07-1.35,1.05c0.18,1,0.83,0.64,1.53,0.45
	C560.28,568.02,560.22,567.52,560.16,567.04z M548.68,556.77c-0.72,0.2-1.21,0.34-1.7,0.47c0.03,0.9,0.09,1.64,1.1,1.48
	C549.24,558.53,548.58,557.67,548.68,556.77z M555.82,556.17c-0.64,0.59-1.74,0.03-1.62,1.24c0.03,0.3,0.55,0.77,0.86,0.78
	C556.29,558.25,555.85,557.35,555.82,556.17z M512.28,554.55c-0.05-0.45-0.11-0.89-0.17-1.42c-0.61,0.1-1.12,0.18-1.62,0.27
	C510.6,554.83,510.6,554.83,512.28,554.55z M532.03,553.22c0.72-0.17,1.15-0.27,1.62-0.38c0.06-1.38-0.49-1.7-1.7-1.23
	C531.98,552.1,532,552.55,532.03,553.22z M511.54,547.63c-0.24-1.4-0.24-1.4-1.7-1.32c0.06,0.49,0.12,0.98,0.19,1.51
	C510.64,547.75,511.09,547.69,511.54,547.63z M506.06,544.44c0.92-0.12,1.93,0.13,1.58-1.06c-0.29-1-1.01-0.6-1.69-0.34
	C505.99,543.52,506.02,543.9,506.06,544.44z M620.6,597.74c-1.78-0.01-1.54,0.93-1.23,1.96
	C621.01,599.84,620.92,598.95,620.6,597.74z M505.29,537.43c0.72-0.47,1.9,0.08,1.63-1.28c-0.22-1.13-1.05-0.49-1.74-0.38
	C505.21,536.24,505.24,536.69,505.29,537.43z M506.48,529.77c-0.04-0.54-0.08-1-0.12-1.54c-0.64,0.1-1.15,0.18-1.64,0.25
	C504.5,529.94,505.14,530.15,506.48,529.77z M513.72,529.32c-0.08-0.54-0.14-1.04-0.28-2.05c-0.69,0.67-1.37,1.03-1.38,1.39
	C512.05,529.84,512.89,529.48,513.72,529.32z M507.99,526.29c0.85-0.2,1.79,0.03,1.6-1.09c-0.2-1.12-0.94-0.67-1.6-0.49
	C507.99,525.2,507.99,525.65,507.99,526.29z M513.02,522.18c0.05-1.09-0.21-1.72-1.15-1.47c-1.1,0.28-0.53,0.99-0.33,1.63
	C512.05,522.29,512.51,522.24,513.02,522.18z M558.45,521.96c1.64,0.12,1.66-0.68,1.23-1.78
	C558.19,519.92,558.09,520.7,558.45,521.96z M553.69,509.77c-0.02,0.95,0.1,1.58,0.99,1.45c0.28-0.04,0.66-0.37,0.74-0.64
	c0.1-0.31-0.08-0.7-0.15-1.12C554.68,509.58,554.23,509.67,553.69,509.77z M528.18,508.31c0.11,0.93,0.13,1.68,1.17,1.48
	c1.16-0.23,0.53-1.02,0.46-1.7C529.32,508.14,528.87,508.21,528.18,508.31z M511.67,506c-0.68,0.45-1.86-0.09-1.56,1.26
	c0.24,1.08,0.98,0.62,1.65,0.37C511.74,507.15,511.71,506.7,511.67,506z M552.97,502.58c0.05,0.93-0.01,1.75,1.12,1.43
	c0.26-0.07,0.62-0.51,0.6-0.76C554.63,502.1,553.83,502.44,552.97,502.58z M513.2,502.65c0.05,0.54,0.1,1.04,0.16,1.63
	c0.64-0.17,1.13-0.31,1.59-0.43C514.97,502.38,514.3,502.26,513.2,502.65z M528.53,500.8c-0.44,0.51-0.81,0.77-0.89,1.09
	c-0.05,0.19,0.41,0.72,0.59,0.7c0.37-0.04,0.72-0.35,1.08-0.55C529.1,501.71,528.89,501.38,528.53,500.8z M534.93,502.08
	c0.65-0.04,1.52,0.44,1.5-0.73c0-0.28-0.53-0.77-0.83-0.78C534.39,500.52,534.83,501.34,534.93,502.08z M509.34,499.31
	c0.06,0.48,0.11,0.94,0.16,1.45c0.57-0.08,1.02-0.14,1.5-0.2C511.2,499.06,510.59,498.81,509.34,499.31z M532.6,498.64
	c0.2-1.52-0.44-1.76-1.7-1.24c0.07,0.45,0.13,0.91,0.2,1.42C531.67,498.75,532.12,498.7,532.6,498.64z M538.15,496.95
	c0.05,0.44,0.1,0.89,0.17,1.42c0.63-0.1,1.13-0.19,1.59-0.27C539.92,496.56,539.25,496.44,538.15,496.95z M507.15,497.21
	c0.25-1.54-0.43-1.73-1.68-1.28c0.06,0.47,0.12,0.92,0.19,1.44C506.23,497.31,506.69,497.26,507.15,497.21z M512.59,496.58
	c0.24,0.18,0.47,0.35,0.71,0.53c0.38-0.34,0.98-0.63,1.08-1.04c0.17-0.71-0.49-1.04-1.04-0.76
	C512.96,495.5,512.83,496.14,512.59,496.58z M558.92,402.66c0.5-0.06,0.93-0.12,1.43-0.18c-0.11-0.62-0.2-1.1-0.28-1.58
	C558.45,400.86,558.75,401.74,558.92,402.66z M497.93,490.88c1.49,0.25,1.51-0.56,1.19-1.65
	C497.59,488.94,497.58,489.75,497.93,490.88z M548.27,407.18c0.71-0.08,1.21-0.13,1.73-0.18c-0.32-1.45-0.87-1.82-1.73-1.23
	C548.27,406.12,548.27,406.5,548.27,407.18z M542.68,407.48c0.22-1.54-0.51-1.66-1.71-1.3c0.07,0.5,0.13,0.96,0.21,1.47
	C541.74,407.59,542.19,407.53,542.68,407.48z M544.69,411.2c1.02-0.25,1.92,0.05,1.87-1.09c-0.04-0.76-0.68-1-1.11-0.68
	C545.06,409.71,544.99,410.43,544.69,411.2z M513.04,480.76c-0.63,0.15-1.53-0.27-1.53,0.88c0,0.72,0.44,0.95,1.07,0.8
	C513.72,482.17,513.1,481.41,513.04,480.76z M532.96,480.85c0.79-0.45,1.82-0.06,1.59-1.25c-0.21-1.11-0.98-0.63-1.7-0.49
	C532.87,479.58,532.9,480.02,532.96,480.85z M540.13,480.13c0.73-0.11,1.23-0.19,1.77-0.27c-0.55-1.4-1.03-1.68-1.77-1.16
	C540.13,479.07,540.13,479.46,540.13,480.13z M507.41,477.65c0.07,0.55,0.14,1.04,0.28,2.05c0.69-0.66,1.39-1.02,1.39-1.39
	C509.09,477.14,508.23,477.51,507.41,477.65z M556.34,478.52c-0.45-1.31-0.45-1.31-1.89-1.21c0.07,0.45,0.13,0.88,0.2,1.36
	C555.24,478.61,555.7,478.57,556.34,478.52z M514.71,478.84c0.88-0.24,1.84-0.04,1.6-1.17c-0.24-1.1-0.97-0.66-1.67-0.42
	C514.66,477.74,514.68,478.19,514.71,478.84z M550.42,412.63c-0.6,0.17-1.62-0.31-1.43,0.93c0.04,0.25,0.71,0.6,1.04,0.55
	C551.05,413.95,550.58,413.24,550.42,412.63z M537.87,476.58c0.21-1.48-0.37-1.78-1.65-1.25c0.05,0.47,0.09,0.92,0.14,1.44
	C536.94,476.69,537.39,476.63,537.87,476.58z M510.86,475.33c0.65-0.08,1.1-0.14,1.6-0.2c0.17-1.46-0.44-1.71-1.6-1.29
	C510.86,474.29,510.86,474.67,510.86,475.33z M517.98,473.28c0.06,0.59,0.1,1.03,0.15,1.56c0.62-0.07,1.11-0.13,1.62-0.2
	C519.41,473.35,519.41,473.35,517.98,473.28z M543.32,413.04c-0.82,0.18-1.77-0.07-1.54,1.06c0.06,0.28,0.62,0.68,0.93,0.66
	C543.9,414.69,543.33,413.84,543.32,413.04z M567.4,580.59c-0.2,0.22-0.4,0.45-0.59,0.67c0.36,0.35,0.72,0.69,1.08,1.04
	c0.26-0.29,0.52-0.58,0.77-0.87C568.24,581.14,567.82,580.86,567.4,580.59z M558.1,419.2c-0.8-0.1-1.59-0.17-1.34,0.98
	c0.05,0.25,0.53,0.61,0.79,0.59C558.83,420.66,558.23,419.84,558.1,419.2z M507.76,472.29c0.42-0.56,0.65-0.87,0.88-1.17
	c-0.32-0.24-0.61-0.59-0.96-0.66c-0.2-0.04-0.7,0.43-0.68,0.63C507.03,471.43,507.38,471.73,507.76,472.29z M566.2,427.22
	c-0.1-0.68-0.18-1.23-0.26-1.73c-1.68-0.02-1.56,0.85-1.19,1.95C565.19,427.37,565.63,427.31,566.2,427.22z M551.81,428.48
	c-0.11-0.72-0.18-1.21-0.26-1.71c-1.41,0.32-1.76,0.92-1.16,1.71C550.77,428.48,551.15,428.48,551.81,428.48z M504.43,466.99
	c-0.55,0.08-1.03,0.15-1.56,0.22c0.1,0.65,0.18,1.15,0.25,1.63C504.9,468.98,504.64,468.04,504.43,466.99z M543.04,427.73
	c0.01,0.66-0.55,1.56,0.74,1.46c0.31-0.03,0.84-0.51,0.83-0.77C544.58,427.26,543.79,427.57,543.04,427.73z M536.55,427.88
	c-0.37,0.55-0.7,0.85-0.74,1.18c-0.02,0.18,0.48,0.62,0.69,0.59c0.35-0.06,0.64-0.41,0.96-0.63
	C537.22,428.72,536.98,428.42,536.55,427.88z M523.05,430.99c0.17-1.33-0.33-1.73-1.68-1.31c0.04,0.55,0.08,1.06,0.13,1.64
	C522.07,431.2,522.53,431.1,523.05,430.99z M539.7,433.35c0.68-0.44,1.86,0,1.53-1.32c-0.26-1.03-1-0.61-1.66-0.33
	C539.61,432.21,539.65,432.66,539.7,433.35z M517.92,433.56c0.41,0.59,0.64,1.07,1.02,1.37c0.13,0.11,0.83-0.16,0.9-0.38
	c0.13-0.38-0.02-0.86-0.06-1.37C519.21,433.29,518.77,433.38,517.92,433.56z M510.96,434.05c-0.18,1,0.01,1.57,1.07,1.38
	c1-0.19,0.72-0.8,0.47-1.53C511.96,433.95,511.45,434,510.96,434.05z M550.73,435.79c0.71-0.11,1.6,0.23,1.47-0.93
	c-0.04-0.31-0.49-0.84-0.74-0.83C550.12,434.08,550.72,435,550.73,435.79z M536.4,435.41c-0.02,1.47,0.6,1.69,1.76,1.23
	c-0.03-0.52-0.07-0.97-0.1-1.53C537.44,435.22,536.94,435.32,536.4,435.41z M536.73,462.1c-0.04-0.55-0.07-1-0.11-1.56
	c-0.61,0.11-1.11,0.2-1.63,0.3C534.9,462.13,535.36,462.55,536.73,462.1z M509.46,459.28c0.36,1.57,0.88,1.9,1.81,1.28
	c-0.03-0.4-0.07-0.85-0.1-1.28C510.56,459.28,510.1,459.28,509.46,459.28z M518.38,458.63c-0.8,0.18-1.75-0.11-1.55,1.03
	c0.05,0.29,0.6,0.7,0.9,0.69C518.92,460.31,518.4,459.45,518.38,458.63z M572.61,583.71c-0.68,0.46-1.86-0.08-1.56,1.28
	c0.24,1.08,0.98,0.63,1.66,0.37C572.68,584.87,572.65,584.42,572.61,583.71z M514.8,456.33c-0.55-0.44-0.86-0.88-1.16-0.87
	c-0.26,0.01-0.63,0.5-0.7,0.83c-0.04,0.21,0.39,0.73,0.59,0.72C513.88,456.99,514.21,456.67,514.8,456.33z M519.74,456.3
	c0.13,0.14,0.26,0.28,0.39,0.42c0.51-0.11,1.01-0.23,1.58-0.35c-0.07-0.91-0.19-1.68-1.19-1.36
	C520.17,455.12,520,455.85,519.74,456.3z M519.28,448.97c0.15,0.15,0.29,0.29,0.44,0.44c0.49-0.07,0.99-0.14,1.5-0.22
	c-0.06-0.9-0.05-1.74-1.1-1.42C519.74,447.89,519.55,448.56,519.28,448.97z M534.33,453.63c-0.09,0.98-0.06,1.72,1.04,1.44
	c0.26-0.07,0.63-0.5,0.61-0.75C535.93,453.17,535.13,453.5,534.33,453.63z M516.2,453.19c0.66-0.08,1.56,0.41,1.53-0.79
	c-0.01-0.28-0.55-0.78-0.86-0.79C515.62,451.58,516.12,452.45,516.2,453.19z M541.79,565.06c-1.03-0.2-1.41,0.25-1.21,1.15
	c0.06,0.24,0.57,0.59,0.79,0.54C542.59,566.47,541.87,565.7,541.79,565.06z M547.38,564.12c0.09,1.31,0.21,2.01,1.11,1.74
	c0.29-0.08,0.43-0.65,0.64-0.99C548.65,564.66,548.17,564.46,547.38,564.12z M551.26,460.34c-0.39-0.42-0.61-0.67-0.84-0.92
	c-0.29,0.27-0.7,0.5-0.82,0.83c-0.07,0.19,0.39,0.81,0.51,0.79C550.47,460.97,550.78,460.66,551.26,460.34z M536.91,442.71
	c-0.07,0.86,0.11,1.52,1.09,1.3c0.26-0.06,0.63-0.71,0.55-0.98C538.24,441.98,537.54,442.5,536.91,442.71z M546.2,442.76
	c-0.58-0.36-0.9-0.69-1.25-0.72c-0.25-0.02-0.73,0.34-0.77,0.59c-0.12,0.69,0.32,1.05,1,0.9
	C545.46,443.46,545.69,443.14,546.2,442.76z M554.67,564.1c0.05,0.49,0.1,0.93,0.16,1.49c0.68-0.22,1.66,0.26,1.51-0.97
	C556.21,563.43,555.42,563.94,554.67,564.1z M519.69,440.2c-0.45,0.49-0.94,0.79-0.92,1.05c0.02,0.32,0.44,0.79,0.75,0.85
	c0.25,0.05,0.79-0.39,0.84-0.67C520.42,441.14,520.02,440.77,519.69,440.2z M552.94,561.33c-0.63-0.42-0.93-0.76-1.27-0.8
	c-0.25-0.02-0.79,0.46-0.76,0.56c0.14,0.37,0.4,0.84,0.72,0.94C551.88,562.11,552.28,561.7,552.94,561.33z M542.14,439.06
	c-0.2-0.21-0.4-0.43-0.6-0.64c-0.41,0.29-1.05,0.51-1.19,0.89c-0.2,0.59,0.28,1.11,0.92,0.86
	C541.65,440.03,541.86,439.45,542.14,439.06z M515.84,436.78c-0.46,0.57-0.94,0.92-0.9,1.18c0.06,0.32,0.51,0.66,0.87,0.79
	c0.15,0.05,0.69-0.41,0.69-0.64C516.5,437.76,516.19,437.41,515.84,436.78z M522.09,436.95c-0.15,1.06,0.36,1.37,1.24,1.13
	c0.19-0.05,0.43-0.51,0.39-0.72C523.45,436.14,522.71,436.77,522.09,436.95z M543.15,459.85c-0.44,0.54-0.92,0.88-0.87,1.13
	c0.06,0.31,0.51,0.64,0.86,0.75c0.16,0.05,0.69-0.4,0.68-0.6C543.8,460.78,543.49,460.45,543.15,459.85z M545.51,435.88
	c-0.48-0.5-0.73-0.88-1.07-1.04c-0.14-0.06-0.77,0.45-0.73,0.56c0.11,0.37,0.38,0.77,0.7,0.95
	C544.56,436.45,544.97,436.12,545.51,435.88z M576.44,587.38c-0.76,0.06-1.71-0.32-1.52,0.89c0.04,0.28,0.62,0.68,0.95,0.67
	C576.89,588.93,576.59,588.2,576.44,587.38z M567.55,587.94c-0.06,1.12,0.2,1.73,1.2,1.48c1.01-0.26,0.53-0.92,0.34-1.48
	C568.61,587.94,568.16,587.94,567.55,587.94z M520.65,462c-0.08,1.48,0.44,1.74,1.17,1.51c0.23-0.07,0.31-0.58,0.46-0.89
	C521.79,462.43,521.3,462.25,520.65,462z M514.47,464.62c0.31-0.67,0.6-1.03,0.58-1.37c-0.01-0.22-0.55-0.64-0.72-0.58
	c-0.34,0.11-0.81,0.5-0.81,0.77C513.51,463.73,513.98,464.04,514.47,464.62z M548.53,432.56c-0.03-1.46-0.5-1.79-1.25-1.54
	c-0.22,0.07-0.31,0.58-0.45,0.89C547.32,432.09,547.8,432.28,548.53,432.56z M554.95,429.86c-0.46,0.57-0.83,0.85-0.91,1.19
	c-0.05,0.22,0.36,0.53,0.57,0.8c0.31-0.26,0.74-0.47,0.89-0.8C555.59,430.85,555.25,430.46,554.95,429.86z M562.18,429.16
	c-0.44,0.49-0.92,0.79-0.9,1.06c0.02,0.32,0.45,0.8,0.75,0.84c0.27,0.04,0.81-0.39,0.87-0.68
	C562.95,430.09,562.54,429.72,562.18,429.16z M540.92,465c-0.58-0.46-0.86-0.84-1.2-0.9c-0.25-0.04-0.58,0.33-0.87,0.51
	c0.23,0.35,0.39,0.87,0.7,1C539.81,465.71,540.25,465.33,540.92,465z M532.53,464.4c-0.41,0.43-0.65,0.68-0.88,0.92
	c0.31,0.27,0.58,0.65,0.94,0.78c0.17,0.06,0.75-0.44,0.71-0.6C533.22,465.15,532.88,464.87,532.53,464.4z M552.06,466.68
	c-1.51,0-2.21,0.1-1.94,1.13c0.06,0.23,0.75,0.55,0.81,0.48C551.27,467.95,551.51,467.51,552.06,466.68z M544.69,467.9
	c-0.71-0.2-1.1-0.38-1.49-0.38c-0.17,0-0.47,0.38-0.48,0.6c-0.03,0.61,0.39,0.86,0.93,0.68C543.94,468.7,544.16,468.37,544.69,467.9
	z M529,470.37c0.23,0.13,0.46,0.26,0.69,0.4c0.11-0.36,0.31-0.71,0.32-1.07c0.02-0.51,0.11-1.22-0.74-1.12
	c-0.26,0.03-0.65,0.45-0.67,0.71C528.57,469.64,528.85,470.01,529,470.37z M530.37,555.21c-0.94,0.01-1.74-0.01-1.43,1.08
	c0.07,0.25,0.52,0.56,0.78,0.55C530.95,556.79,530.39,555.94,530.37,555.21z M528.24,423.21c1.06,0.22,1.41-0.27,1.19-1.16
	c-0.06-0.24-0.6-0.58-0.82-0.52C527.51,421.86,528.09,422.59,528.24,423.21z M548.51,471.36c-0.47-0.38-0.72-0.59-0.97-0.79
	c-0.23,0.33-0.53,0.64-0.67,1.01c-0.04,0.12,0.41,0.59,0.5,0.56C547.72,472,548.02,471.72,548.51,471.36z M546.45,416.2
	c-0.4,0.53-0.86,0.87-0.81,1.13c0.05,0.32,0.5,0.74,0.82,0.78c0.25,0.03,0.75-0.45,0.77-0.74
	C547.26,417.07,546.84,416.74,546.45,416.2z M538.34,409.18c-0.25,0.7-0.5,1.06-0.47,1.4c0.03,0.31,0.36,0.59,0.56,0.88
	c0.32-0.24,0.73-0.43,0.91-0.75C539.41,410.58,539,410.17,538.34,409.18z M520.56,481.08c-0.62-0.33-0.95-0.59-1.31-0.66
	c-0.14-0.03-0.56,0.47-0.53,0.52c0.21,0.32,0.46,0.69,0.79,0.82C519.67,481.83,520.03,481.44,520.56,481.08z M531.73,483.85
	c-0.44-0.46-0.7-0.93-0.89-0.9c-0.38,0.05-0.73,0.37-1.09,0.59c0.21,0.28,0.4,0.77,0.64,0.8
	C530.73,484.38,531.12,484.09,531.73,483.85z M517.45,485.15c-0.57-0.45-0.87-0.88-1.17-0.88c-0.31,0-0.62,0.42-0.93,0.66
	c0.27,0.3,0.49,0.76,0.82,0.84C516.44,485.84,516.83,485.47,517.45,485.15z M557.01,484.88c-0.53-0.3-0.86-0.61-1.2-0.63
	c-0.22-0.01-0.65,0.4-0.66,0.64c-0.04,0.61,0.3,1.08,0.99,0.87C556.42,485.68,556.6,485.31,557.01,484.88z M529,547.65
	c-0.43,0.55-0.8,0.83-0.85,1.16c-0.04,0.22,0.42,0.75,0.6,0.72c0.36-0.05,0.89-0.32,0.96-0.61
	C529.78,548.66,529.36,548.26,529,547.65z M535.55,487.25c-0.46-0.52-0.67-0.95-1-1.06c-0.26-0.09-0.65,0.22-0.98,0.36
	c0.18,0.37,0.28,0.91,0.57,1.07C534.4,487.75,534.88,487.45,535.55,487.25z M504.82,488.37c-0.03,1.24-0.13,1.99,0.87,1.78
	c0.31-0.07,0.53-0.57,0.79-0.87C506.02,489.03,505.57,488.79,504.82,488.37z M525.92,545.99c0.12-1.06-0.42-1.34-1.28-1.08
	c-0.18,0.05-0.41,0.53-0.36,0.73C524.6,546.8,525.31,546.19,525.92,545.99z M557.73,491.2c-1.16,0.25-1.66,0.36-2.13,0.46
	c-0.01,1.03,0.36,1.61,1.28,1.27C557.18,492.81,557.28,492.14,557.73,491.2z M518.22,500.33c0.09-1.42-0.23-1.9-1.07-1.68
	c-0.24,0.06-0.38,0.52-0.57,0.8C517.03,499.7,517.49,499.94,518.22,500.33z M547.54,398.46c0.02,0.5,0.04,1.01,0.07,1.65
	c0.71-0.21,1.62,0.2,1.58-0.97C549.15,397.97,548.36,398.31,547.54,398.46z M506.9,502.59c-0.37,0.51-0.77,0.85-0.71,1.02
	c0.14,0.36,0.52,0.62,0.8,0.92c0.24-0.24,0.69-0.51,0.67-0.72C507.61,503.45,507.26,503.14,506.9,502.59z M540.31,505.32
	c0.1-1.34-0.43-1.67-1.67-1.21c0.05,0.5,0.1,1,0.17,1.59C539.42,505.54,539.86,505.43,540.31,505.32z M561.83,396.73
	c0.08,1.3,0.15,1.98,1.07,1.75c0.29-0.07,0.47-0.6,0.7-0.92C563.13,397.34,562.65,397.11,561.83,396.73z M516.02,510.37
	c-0.65-0.36-1.01-0.72-1.31-0.67c-0.3,0.05-0.53,0.51-0.79,0.79c0.31,0.25,0.6,0.67,0.93,0.7
	C515.13,511.23,515.45,510.8,516.02,510.37z M506.68,510.3c-0.07,0.85,0,1.55,1.02,1.38c0.27-0.05,0.69-0.65,0.63-0.92
	C508.12,509.57,507.34,510.22,506.68,510.3z M534.25,512.25c-0.7-0.33-1.06-0.63-1.4-0.62c-0.24,0.01-0.71,0.56-0.66,0.65
	c0.18,0.34,0.5,0.77,0.83,0.83C533.28,513.15,533.63,512.71,534.25,512.25z M513.37,536.82c0.37-0.46,0.8-0.86,0.74-0.96
	c-0.2-0.32-0.54-0.65-0.89-0.74c-0.19-0.05-0.76,0.49-0.72,0.59C512.65,536.06,512.97,536.34,513.37,536.82z M530.62,515.78
	c-0.15-0.15-0.31-0.29-0.46-0.44c-0.48,0.08-0.97,0.17-1.47,0.25c0.12,0.84,0.05,1.74,1.12,1.37
	C530.17,516.84,530.35,516.19,530.62,515.78z M521.5,516.84c0.48,0.44,0.72,0.81,1.05,0.9c0.16,0.05,0.68-0.43,0.67-0.65
	c-0.02-0.35-0.34-0.69-0.54-1.04C522.36,516.26,522.04,516.48,521.5,516.84z M509.6,531.32c-0.46,0.57-0.94,0.92-0.89,1.17
	c0.06,0.32,0.51,0.66,0.86,0.78c0.15,0.05,0.69-0.41,0.69-0.64C510.27,532.29,509.95,531.95,509.6,531.32z M516.76,517.77
	c-0.59-0.48-0.9-0.92-1.2-0.91c-0.31,0-0.61,0.45-0.91,0.7c0.23,0.24,0.43,0.67,0.68,0.69
	C515.68,518.28,516.06,518.02,516.76,517.77z M541.84,519.02c-0.44-0.48-0.71-0.77-0.97-1.05c-0.27,0.28-0.76,0.55-0.77,0.83
	c-0.01,0.31,0.35,0.78,0.66,0.92C540.94,519.8,541.35,519.35,541.84,519.02z M527.69,520.25c-0.49-0.43-0.78-0.87-0.98-0.84
	c-0.36,0.07-0.68,0.42-1.01,0.65c0.23,0.24,0.44,0.67,0.68,0.69C526.72,520.78,527.09,520.5,527.69,520.25z M531.6,523.74
	c-0.48-0.53-0.72-1.04-1.03-1.08c-0.3-0.04-0.74,0.33-0.96,0.64c-0.08,0.1,0.29,0.76,0.52,0.8
	C530.49,524.16,530.91,523.92,531.6,523.74z M526.24,528.39c0.71-0.42,1.18-0.61,1.51-0.94c0.08-0.08-0.2-0.76-0.46-0.89
	c-0.28-0.15-0.73,0-1.16,0.02C526.16,527.08,526.18,527.46,526.24,528.39z M531.85,529.56c-0.63,0.43-1.67-0.13-1.52,1.09
	c0.13,1.15,0.91,0.69,1.62,0.52C531.92,530.69,531.89,530.25,531.85,529.56z M526.81,533.45c0,1.46,0.37,1.97,1.28,1.66
	c0.21-0.07,0.43-0.84,0.36-0.88C528.04,533.95,527.54,533.78,526.81,533.45z M550.46,513.36c-0.14,1.43,0.19,1.89,1.02,1.67
	c0.24-0.06,0.38-0.52,0.57-0.8C551.6,513.99,551.16,513.75,550.46,513.36z M585.51,235.95c-1.6,0.03-1.51,0.84-1.09,1.89
	C585.87,237.92,585.9,237.14,585.51,235.95z M537.35,508c-0.17-0.14-0.34-0.27-0.51-0.41c-0.47,0.3-1.55-0.15-1.23,1.08
	c0.07,0.28,0.64,0.42,0.98,0.63C536.84,508.86,537.09,508.43,537.35,508z M531.52,506.04c0.72-0.12,1.68,0.25,1.55-0.93
	c-0.13-1.17-0.91-0.75-1.64-0.52C531.46,505.08,531.49,505.53,531.52,506.04z M542.34,485.19c-0.66,0.54-1.37,0.85-1.39,1.19
	c-0.07,1.19,0.76,0.87,1.55,0.68C542.46,486.58,542.42,486.13,542.34,485.19z M510.19,484.51c-1.14,0.23-1.63,0.32-2.1,0.42
	c0.02,0.97,0.32,1.61,1.25,1.28C509.63,486.1,509.74,485.44,510.19,484.51z M548.21,571.69c-0.07,0.92-0.1,1.67,1,1.41
	c0.26-0.06,0.64-0.47,0.63-0.7C549.8,571.28,549.01,571.58,548.21,571.69z M560.82,414.93c-0.34,0.53-0.66,0.85-0.7,1.2
	c-0.02,0.15,0.61,0.59,0.81,0.52c0.32-0.12,0.61-0.52,0.72-0.86C561.68,415.64,561.23,415.34,560.82,414.93z M539.59,471.47
	c0.04,0.49,0.09,0.94,0.14,1.49c0.61-0.12,1.1-0.22,1.56-0.3C541.28,471.48,540.95,470.92,539.59,471.47z M540.42,416.85
	c-1.14,0.23-1.63,0.32-2.1,0.42c0.02,0.98,0.31,1.61,1.24,1.28C539.86,418.44,539.97,417.78,540.42,416.85z M560.52,422.42
	c0.08,0.72-0.3,1.65,0.92,1.51c1.2-0.14,0.74-0.91,0.53-1.64C561.48,422.34,561.02,422.38,560.52,422.42z M511.55,441.13
	c0.07,0.72-0.27,1.63,0.91,1.5c1.18-0.13,0.73-0.9,0.52-1.61C512.5,441.05,512.05,441.09,511.55,441.13z M538.23,458.64
	c0.84-0.18,1.79,0.02,1.53-1.14c-0.06-0.26-0.52-0.61-0.79-0.61C537.69,456.88,538.24,457.78,538.23,458.64z M541.38,454.33
	c0.16,0.11,0.33,0.23,0.49,0.34c0.43-0.07,0.85-0.14,1.3-0.22c0.02-0.89-0.08-1.64-1.07-1.36
	C541.77,453.19,541.61,453.9,541.38,454.33z M552.31,569.66c0.45-0.54,0.91-0.85,0.89-1.12c-0.03-0.3-0.46-0.67-0.79-0.79
	c-0.16-0.06-0.7,0.38-0.71,0.61C551.68,568.69,551.99,569.03,552.31,569.66z M558.22,559.97c-0.16,1.04,0.02,1.6,0.87,1.4
	c0.27-0.07,0.43-0.61,0.63-0.93C559.24,560.28,558.75,560.13,558.22,559.97z M526.68,552.67c-0.57-0.36-0.88-0.64-1.23-0.74
	c-0.13-0.04-0.57,0.44-0.54,0.5c0.19,0.35,0.43,0.71,0.76,0.93C525.76,553.43,526.16,553.03,526.68,552.67z M529.33,541.64
	c-0.47-0.37-0.8-0.8-0.95-0.74c-0.32,0.12-0.66,0.46-0.75,0.78c-0.05,0.18,0.42,0.74,0.58,0.71
	C528.55,542.34,528.83,542,529.33,541.64z M510.12,538.55c-0.37,0.55-0.73,0.86-0.68,1.08c0.08,0.34,0.45,0.6,0.69,0.9
	c0.23-0.23,0.66-0.47,0.65-0.7C510.77,539.48,510.45,539.15,510.12,538.55z M530.8,536.69c-0.05,1.32-0.03,2.02,0.91,1.84
	c0.28-0.06,0.48-0.58,0.71-0.89C531.99,537.39,531.56,537.14,530.8,536.69z M504.39,513.82c-0.38,0.43-0.76,0.67-0.78,0.92
	c-0.01,0.27,0.33,0.56,0.51,0.84c0.3-0.25,0.7-0.46,0.87-0.78C505.07,514.66,504.68,514.28,504.39,513.82z M539.4,510.94
	c-0.08,1.42,0.36,1.76,1.12,1.52c0.23-0.07,0.32-0.56,0.47-0.86C540.51,511.4,540.04,511.2,539.4,510.94z M553.35,495.11
	c-0.37,0.43-0.74,0.67-0.75,0.93c-0.01,0.26,0.32,0.55,0.51,0.82c0.29-0.25,0.68-0.45,0.83-0.77
	C554.01,495.94,553.64,495.57,553.35,495.11z M534.91,495.28c0.39-0.5,0.82-0.83,0.77-1.02c-0.1-0.34-0.49-0.6-0.75-0.89
	c-0.2,0.27-0.54,0.52-0.57,0.81C534.33,494.46,534.63,494.76,534.91,495.28z M543.27,493.72c-0.47-0.42-0.74-0.84-0.96-0.82
	c-0.36,0.04-0.68,0.37-1.01,0.58c0.21,0.28,0.4,0.77,0.63,0.78C542.29,494.29,542.66,493.99,543.27,493.72z M517.96,492.1
	c-0.67-0.33-1.02-0.63-1.36-0.62c-0.18,0-0.5,0.54-0.48,0.82c0.02,0.26,0.46,0.72,0.63,0.68
	C517.09,492.91,517.37,492.56,517.96,492.1z M539.49,490.36c-0.59-0.39-0.89-0.74-1.23-0.77c-0.23-0.02-0.5,0.39-0.76,0.61
	c0.28,0.29,0.52,0.68,0.86,0.81C538.55,491.08,538.91,490.71,539.49,490.36z M534.66,448.2c0.28-0.48,0.64-0.86,0.56-1.01
	c-0.16-0.3-0.56-0.48-0.85-0.71c-0.18,0.28-0.5,0.57-0.49,0.84C533.9,447.57,534.28,447.79,534.66,448.2z M517.32,445.32
	c-0.39-0.44-0.7-0.93-0.81-0.89c-0.34,0.12-0.72,0.41-0.85,0.73c-0.07,0.18,0.34,0.79,0.49,0.78
	C516.5,445.91,516.82,445.61,517.32,445.32z M548.09,424.7c-0.48-0.42-0.74-0.78-1.08-0.9c-0.15-0.05-0.69,0.47-0.66,0.64
	c0.08,0.34,0.38,0.72,0.7,0.88C547.2,425.4,547.59,425.02,548.09,424.7z M541.12,400.72c0.42-0.51,0.79-0.75,0.8-1.02
	c0.01-0.29-0.25-0.75-0.5-0.83c-0.22-0.07-0.8,0.28-0.81,0.46C540.59,399.7,540.86,400.07,541.12,400.72z M545.39,450.65
	c0.21,0.12,0.42,0.24,0.63,0.36c0.13-0.36,0.37-0.73,0.34-1.07c-0.03-0.26-0.39-0.68-0.62-0.69c-0.27-0.01-0.71,0.29-0.79,0.55
	C544.89,450.02,545.23,450.36,545.39,450.65z M537.6,484.19c0.39-0.52,0.75-0.79,0.74-1.04c-0.02-0.3-0.3-0.73-0.57-0.81
	c-0.2-0.06-0.77,0.33-0.77,0.51C537.01,483.21,537.3,483.57,537.6,484.19z"
              />
              <path
                d="M369.75,231.89c0.84-0.18,1.68-0.35,2.54-0.53c0.18-0.92,0.13-2.05,0.64-2.8c0.45-0.68,1.52-0.95,2.5-1.51
	c-0.03-0.4-0.08-1.01-0.14-1.74c-2.18-0.21-4.16-0.7-3.2-3.56c0.35-1.05,1.07-1.26,2.92-1.33c0.19-0.98,0.11-2.12,0.63-2.81
	c0.54-0.71,1.66-0.96,2.64-1.48c0.05-0.67,0.11-1.6,0.18-2.53c0.13-0.15,0.26-0.31,0.38-0.46c0.94-0.29,1.87-0.58,2.99-0.92
	c-0.01-0.08-0.1-0.44-0.1-0.8c-0.02-2.08,1.19-3.03,3.2-2.51c0.37,0.1,0.72,0.28,1.09,0.42c0.17,0.81,0.35,1.62,0.52,2.44
	c0.79-0.09,1.38-0.15,1.97-0.22c0.05-0.83,0.1-1.51,0.15-2.25c-0.91,0-1.76,0-2.62,0c-0.24-0.41-0.53-0.8-0.7-1.23
	c-0.52-1.33,0.14-2.69,1.43-3.03c1.25-0.33,2.34,0.33,2.7,1.69c0.12,0.44,0.1,0.92,0.15,1.4c0.93-0.12,1.76-0.23,2.58-0.34
	c0.06-2.53,0.69-3.57,2.17-3.7c1.41-0.12,2.23,0.8,2.68,3.01c0.06,0.04,0.12,0.13,0.18,0.12c0.62-0.08,1.24-0.17,1.85-0.26
	c0.24-2.28,0.94-3.26,2.34-3.32c1.31-0.06,2.08,0.82,2.56,2.99c0.82-0.11,1.65-0.22,2.47-0.33c0.1-2.37,0.73-3.27,2.24-3.28
	c1.47,0,2.11,0.85,2.39,3.01c1.1,0.12,2.15,0.23,3.2,0.34l-0.07-0.06c0.49,0.78,0.99,1.56,1.58,2.5c0.31,0,0.83,0,1.53,0
	c0.03-0.39,0-0.77,0.09-1.11c0.51-1.91,2.07-2.69,3.52-1.77c0.81,0.51,1.18,1.25,1.09,2.2c-0.06,0.62-0.19,1.23-0.29,1.85
	c-0.81,0.13-1.62,0.26-2.4,0.39c0.01,1.32,0.2,2.24,1.7,1.72c0.21-0.72,0.43-1.44,0.64-2.16c0.88-1.59,2.36-1.08,3.7-1.08
	c0.15,0.16,0.31,0.32,0.46,0.47c0.24,0.75,0.48,1.5,0.74,2.34c0.75,0.04,1.52,0.09,2.3,0.13c-0.01-2.25,0.66-3.34,2.06-3.38
	c1.05-0.04,2,0.79,2.14,1.86c0.19,1.48-0.66,2.21-3.04,2.56c0.12,0.68,0.25,1.35,0.38,2.07c0.62,0,1.17,0,1.67,0
	c0.45-0.96,0.79-1.99,1.38-2.85c0.24-0.34,1-0.45,1.53-0.45c1.4,0.01,1.99,0.81,2.38,3.21c1.27-0.36,2.53-0.3,3.31,0.96
	c0.46,0.76,0.82,1.61-0.15,2.32c-0.26,1.54-1.68,1.09-2.7,1.53c0.17,0.64,0.35,1.3,0.53,1.99c0.65-0.08,1.19-0.14,1.71-0.2
	c0.16-1.16,0.31-2.24,0.46-3.33c0.45,0,0.9,0.05,1.34,0.01c1.53-0.15,2.62,0.5,2.93,1.79c0.28,1.18-0.34,2.22-1.7,2.76
	c-0.36,0.14-0.76,0.18-1.17,0.27c0.1,0.67,0.2,1.29,0.3,1.97c0.66,0,1.28,0,1.99,0c-0.09-1.97,0.33-3.77,2.88-3.41
	c1.59,0.23,1.94,1.67,1.83,3.1c2.66-0.02,3.62,0.52,3.77,2.04c0.15,1.57-0.76,2.33-3.28,2.64c0.16,0.68,0.32,1.35,0.5,2.1
	c0.71,0,1.39,0,2.07,0c0.12-2.44,0.68-3.29,2.15-3.36c1.19-0.05,2.19,0.77,2.34,1.93c0.2,1.49-0.77,2.3-3.17,2.64
	c0.1,0.85,0.19,1.69,0.28,2.42c1.02,0.07,1.95,0.14,2.89,0.21c0.17,0.15,0.35,0.3,0.52,0.46c0.14,0.45,0.35,0.9,0.4,1.36
	c0.19,1.67-0.99,2.89-2.69,2.85c-0.61-0.02-1.21-0.01-1.81-0.02c-0.12-1.13-0.25-2.26-0.39-3.45c-0.72,0.15-1.38,0.29-2.16,0.45
	c0.06,0.79,0.12,1.52,0.14,1.84c1.01,0.48,1.72,0.82,2.43,1.15c1.14,0.6,0.95,1.57,0.55,2.44c-0.4,0.87-1.22,1.25-2.19,1.08
	c-1.43-0.25-1.79-1.39-1.97-2.63c-0.9,0-1.74,0-2.45,0c-0.33,1-0.52,1.98-0.99,2.81c-0.21,0.37-0.95,0.59-1.46,0.62
	c-1.27,0.08-1.95-0.84-2.36-2.98c-0.94-0.07-1.88-0.14-2.81-0.2c-0.17-0.2-0.35-0.4-0.52-0.59c-0.36-0.77-0.72-1.54-1.07-2.27
	c-0.62,0-1.24,0-1.7,0c-0.18,1.03-0.34,1.94-0.51,2.85c-0.11,0.15-0.21,0.29-0.32,0.44c-0.31,0.07-0.62,0.15-0.93,0.2
	c-1.93,0.32-2.89-0.34-3.17-2.21c-0.05-0.37-0.01-0.75-0.01-1.12c-2.57-0.05-3.51-0.55-3.73-1.91c-0.24-1.46,0.55-2.29,2.89-2.91
	c-0.04-0.59-0.09-1.21-0.15-1.99c-2.12,0.04-3.8-0.6-3.83-2.94c-1.06-0.36-2.2-0.46-2.9-1.07c-0.63-0.54-0.79-1.63-1.16-2.49
	c-0.73-0.07-1.59-0.16-2.46-0.24l0.08,0.07c-0.52-0.95-1.04-1.89-1.72-3.12c-0.33,0.03-1,0.09-1.64,0.15
	c-0.37,2.27-1.02,3.16-2.32,3.24c-1.25,0.08-1.98-0.72-2.64-2.94c-0.6,0.11-1.21,0.23-1.88,0.36c0,0.72,0,1.33,0,2.1
	c0.37,0.02,0.75,0.01,1.12,0.08c1.54,0.29,2.37,1.32,2.13,2.61c-0.27,1.41-1.56,2.09-3.15,1.62c-0.16-0.05-0.25-0.37-0.37-0.56
	c-0.45-0.72-0.89-1.44-1.4-2.27c-0.41,0.06-1.02,0.14-1.62,0.22c0,0.69,0,1.22,0,1.88c1.05,0.06,2.04,0.12,3.03,0.17
	c0,0.16-0.03,0.32,0,0.47c0.62,3.25,0.45,3.58-2.28,4.65c-0.37,1.26-0.69,2.37-1.02,3.49l0.07-0.08c-0.84,0.15-1.68,0.3-2.61,0.47
	c0,0.8,0,1.57,0,2.34c2.17-0.04,3.09,0.5,3.21,1.89c0.1,1.19-0.75,2.21-1.93,2.29c-1.36,0.1-2.1-0.87-2.27-3
	c-0.85,0.08-1.7,0.17-2.37,0.24c-0.33,1.06-0.53,2.09-1,2.98c-0.19,0.37-0.95,0.6-1.46,0.62c-1.46,0.08-2.14-1-2.17-3.31
	c-0.92,0.14-1.83,0.28-2.81,0.43c0,0.43,0.07,0.91-0.01,1.36c-0.22,1.24-1.21,2.05-2.31,1.98c-1.16-0.07-1.92-0.91-2.03-2.23
	c-0.04-0.54-0.07-1.08-0.1-1.62c1-0.26,1.99-0.52,2.9-0.76c-0.09-0.99-0.16-1.81-0.22-2.5c-1.06-0.13-2.02-0.25-2.99-0.36
	c-0.2-0.9-0.39-1.79-0.61-2.77c-0.8,0.16-1.52,0.31-2.18,0.45c0,0.79,0,1.42,0,2.04c0.98,0.08,1.91,0.15,2.83,0.23
	c0.59,1.2,1.46,2.37,0.3,3.72c-2.57,1.7-3.91,1.02-4.39-2.42c-0.65,0.06-1.32,0.13-1.98,0.2c-0.39,3.04-0.63,3.31-3.39,3.89
	c-0.05,1.56-0.13,3.23-2.22,3.52c-1.41,0.2-2.22-0.66-2.84-2.91c-0.92-0.08-1.86-0.16-2.79-0.24c-0.16-0.15-0.32-0.31-0.48-0.46
	c-0.97-2.86-0.61-3.63,2.32-4.7c0.03-0.53,0.06-1.14,0.09-1.88c-3.07-0.21-4.18-1.64-2.78-4.52L369.75,231.89z M391.84,234.35
	c0.09,0.82,0.18,1.56,0.27,2.37c0.75-0.14,1.35-0.25,1.98-0.36c0-0.73,0-1.33,0-2.01C393.3,234.35,392.62,234.35,391.84,234.35z
	 M393.49,227.26c-0.82,0-1.43,0-2.12,0c0.09,0.88,0.17,1.69,0.26,2.53c0.75-0.18,1.35-0.32,2.03-0.48
	C393.6,228.66,393.55,228.05,393.49,227.26z M388.47,231.48c0,0.79,0,1.41,0,2.07c0.89,0,1.72,0,2.59,0
	c-0.17-0.9-0.32-1.72-0.48-2.56C389.85,231.16,389.23,231.31,388.47,231.48z M431.9,234.84c0,0.85,0,1.59,0,2.38
	c0.86-0.17,1.6-0.32,2.37-0.48c-0.15-0.72-0.28-1.32-0.43-2C433.14,234.77,432.53,234.81,431.9,234.84z M448.09,225.88
	c-0.75,0.12-1.5,0.24-2.32,0.37c0.13,0.72,0.24,1.38,0.36,2.05c0.78,0,1.46,0,2.17,0C448.23,227.47,448.17,226.72,448.09,225.88z
	 M384.94,213.28c-0.8,0.04-1.45,0.06-2.15,0.09c0.2,0.82,0.37,1.48,0.52,2.1C385.06,215.69,385.29,214.81,384.94,213.28z
	 M410.02,209.51c0.11-0.84,0.2-1.51,0.3-2.28c-0.81,0.16-1.48,0.3-2.26,0.46c0.15,0.71,0.27,1.3,0.4,1.93
	C409.02,209.58,409.41,209.55,410.02,209.51z M436.35,216.13c-0.73,0.11-1.4,0.21-2.2,0.32c0.18,0.72,0.32,1.3,0.45,1.81
	c0.67,0,1.2,0,1.75,0C436.35,217.53,436.35,216.91,436.35,216.13z M396.12,240.49c0.93-0.17,1.6-0.3,2.39-0.44
	c-0.23-0.69-0.43-1.32-0.66-2c-0.57,0.05-1.02,0.09-1.6,0.13C396.21,238.93,396.17,239.61,396.12,240.49z M374,234.64
	c0.72-0.16,1.32-0.29,2-0.44c-0.06-0.61-0.12-1.13-0.18-1.71c-0.77,0-1.4,0-2.14,0C373.78,233.16,373.88,233.84,374,234.64z
	 M395.09,225.41c0.63-0.09,1.22-0.17,1.87-0.26c-0.04-0.64-0.07-1.17-0.1-1.66c-0.75,0-1.35,0-2.03,0
	C394.92,224.16,394.99,224.7,395.09,225.41z M444.83,237.37c-0.65,0.47-1.24,0.75-1.58,1.21c-0.21,0.29-0.04,0.85-0.04,1.35
	c0.82-0.1,1.49-0.17,2.2-0.26C445.23,238.95,445.08,238.35,444.83,237.37z M379.7,217.45c0.14,0.73,0.25,1.26,0.37,1.89
	c0.6-0.05,1.12-0.1,1.69-0.15c0-0.73,0-1.33,0-1.97C381.09,217.3,380.54,217.36,379.7,217.45z M393.43,208.89
	c0.22,0.73,0.39,1.31,0.57,1.9c0.62-0.04,1.06-0.07,1.58-0.11c0-0.66,0-1.19,0-1.79C394.91,208.89,394.3,208.89,393.43,208.89z
	 M378.42,221.42c-0.7,0-1.31,0-2.03,0c0.1,0.67,0.19,1.26,0.29,1.94c0.74-0.12,1.34-0.21,1.98-0.31
	C378.56,222.41,378.5,221.96,378.42,221.42z M381.58,233.75c0.69,0,1.29,0,1.97,0c-0.09-0.64-0.17-1.17-0.26-1.81
	c-0.65,0.11-1.17,0.2-1.79,0.31C381.53,232.81,381.56,233.27,381.58,233.75z M377.5,230.28c1.64,0.29,2.06-0.29,1.56-1.73
	c-0.58,0.04-1.16,0.09-1.78,0.14C377.36,229.28,377.42,229.74,377.5,230.28z M402.79,210.11c0-0.63,0-1.22,0-1.95
	c-0.69,0.13-1.24,0.24-1.75,0.34C400.96,210.01,401.42,210.64,402.79,210.11z M402.21,222.99c0.16,0.87-0.16,1.73,1.08,1.58
	c1.21-0.15,0.81-0.86,0.63-1.58C403.43,222.99,402.98,222.99,402.21,222.99z M393.09,219.94c-0.78,0.53-1.3,0.75-1.6,1.15
	c-0.1,0.13,0.27,0.79,0.56,1.02c0.23,0.17,0.69,0.03,1.16,0.03C393.18,221.49,393.16,221.05,393.09,219.94z M379.82,235.72
	c-0.75,0.14-1.25,0.24-1.72,0.33c-0.12,1.63,0.6,1.92,1.99,1.45C380.01,236.98,379.93,236.46,379.82,235.72z M446.93,235.37
	c1.49-0.19,1.49-0.19,1.09-1.73C446.79,233.52,446.47,234.07,446.93,235.37z M397.41,230.73c-0.78,0.13-1.34,0.22-1.93,0.32
	c0.09,0.62,0.15,1.07,0.22,1.52C397.27,232.27,397.27,232.27,397.41,230.73z M376.73,241.62c0.01-1.38-0.34-2.12-1.87-1.39
	c0.04,0.5,0.08,1.02,0.12,1.61C375.66,241.76,376.19,241.69,376.73,241.62z M414.63,220.22c-0.34-0.58-0.49-1.12-0.84-1.33
	c-0.21-0.13-0.9,0.17-1.08,0.46c-0.19,0.32-0.04,0.85-0.04,1.4C413.35,220.57,413.85,220.43,414.63,220.22z M390,226.18
	c-0.12-0.69-0.19-1.14-0.3-1.75c-0.72,0.23-1.76-0.2-1.61,1.1C388.23,226.67,389.11,226.09,390,226.18z M439.06,227.06
	c-0.23,0.89-0.06,1.51,0.93,1.37c0.98-0.14,0.6-0.82,0.4-1.5C439.94,226.97,439.5,227.02,439.06,227.06z M444.27,230.26
	c-0.79,0.07-1.59-0.08-1.35,0.94c0.06,0.25,0.55,0.58,0.82,0.57C444.8,231.71,444.44,231,444.27,230.26z M419.93,219.72
	c0.97,0.02,1.67-0.08,1.44-1.1c-0.2-0.89-0.82-0.66-1.44-0.41C419.93,218.67,419.93,219.13,419.93,219.72z M380.34,226.52
	c1.45,0,2.1-0.17,1.84-1.15c-0.06-0.23-0.76-0.53-0.8-0.48C381.07,225.26,380.83,225.71,380.34,226.52z M424.79,213.86
	c-0.93-0.11-1.61,0.07-1.32,1.04c0.1,0.34,0.79,0.5,1.56,0.96C424.91,214.82,424.85,214.33,424.79,213.86z M394.8,216.02
	c0,1.19,0,1.74,0,2.29c0.67-0.18,1.1-0.54,1.1-0.9C395.89,217.08,395.41,216.76,394.8,216.02z M385.89,221.65
	c-0.52-0.27-0.86-0.59-1.09-0.52c-0.26,0.08-0.59,0.51-0.58,0.77c0.01,0.22,0.49,0.59,0.73,0.57
	C385.22,222.45,385.45,222.05,385.89,221.65z M404.57,230.1c-0.96-0.05-1.52,0.24-1.28,1.19c0.24,0.95,0.88,0.56,1.52,0.31
	C404.73,231.09,404.65,230.58,404.57,230.1z M424.02,223.06c0.91,0.09,1.59,0.02,1.33-1.03c-0.06-0.26-0.55-0.64-0.77-0.6
	C423.42,221.64,423.99,222.41,424.02,223.06z M386.33,228.27c-0.94-0.02-1.54,0.27-1.31,1.21c0.23,0.98,0.9,0.51,1.54,0.3
	C386.48,229.27,386.41,228.76,386.33,228.27z M400.11,221.35c0.09-0.18,0.18-0.35,0.27-0.53c-0.36-0.29-0.69-0.75-1.1-0.84
	c-0.73-0.16-0.95,0.38-0.85,1.03c0.03,0.2,0.17,0.39,0.29,0.64C399.22,221.54,399.66,221.45,400.11,221.35z M399.82,234.45
	c-0.2,1.17,0.36,1.39,1.18,1.2c0.2-0.04,0.47-0.4,0.46-0.59C401.37,233.91,400.59,234.32,399.82,234.45z M390.54,213.09
	c-0.01,1.12,0.22,1.69,1.09,1.42c0.23-0.07,0.33-0.57,0.49-0.87C391.64,213.47,391.14,213.3,390.54,213.09z M412.06,211.49
	c0.4,0.55,0.7,0.96,1,1.38c0.25-0.3,0.73-0.61,0.72-0.91C413.75,210.89,413.02,211.26,412.06,211.49z M435.95,225.53
	c0.35-0.37,0.76-0.61,0.76-0.85c0.01-0.29-0.33-0.58-0.52-0.88c-0.33,0.25-0.66,0.5-0.99,0.76
	C435.42,224.83,435.62,225.1,435.95,225.53z M428.49,226.51c0.37-0.51,0.78-0.88,0.71-1.02c-0.17-0.34-0.52-0.64-0.87-0.79
	c-0.11-0.05-0.66,0.47-0.63,0.66C427.77,225.69,428.12,225.99,428.49,226.51z M400.62,228.83c0.02-1.45-0.2-2.09-1.19-1.78
	c-0.21,0.07-0.47,0.8-0.39,0.87C399.39,228.23,399.87,228.42,400.62,228.83z M427.15,217.25c0.17,0.58-0.09,1.39,0.93,1.22
	c0.23-0.04,0.59-0.55,0.55-0.79C428.5,216.68,427.84,217.08,427.15,217.25z M388.15,216.72c-0.31,0.62-0.56,0.91-0.57,1.21
	c-0.01,0.22,0.3,0.45,0.47,0.67c0.25-0.22,0.66-0.41,0.71-0.67C388.79,217.67,388.48,217.35,388.15,216.72z M405.38,211.67
	c-0.17,0.23-0.35,0.46-0.52,0.69c0.4,0.29,0.8,0.58,1.2,0.86c0.16-0.3,0.5-0.81,0.43-0.88
	C406.19,212.06,405.76,211.89,405.38,211.67z M398.51,212.33c-0.25,0.52-0.53,0.84-0.48,1.08c0.06,0.25,0.45,0.43,0.69,0.64
	c0.21-0.26,0.57-0.52,0.57-0.78C399.29,213.02,398.91,212.78,398.51,212.33z M417.88,215.24c-0.5-0.28-0.81-0.61-1.07-0.57
	c-0.23,0.04-0.57,0.51-0.53,0.74c0.04,0.25,0.45,0.61,0.7,0.62C417.22,216.03,417.46,215.63,417.88,215.24z M409.5,217.3
	c0.45-0.53,0.85-0.8,0.83-1.03c-0.03-0.29-0.41-0.53-0.64-0.8c-0.2,0.19-0.55,0.37-0.57,0.58
	C409.09,216.35,409.29,216.67,409.5,217.3z M439.53,236.23c1.48-0.41,1.31-1.04,0.97-1.7c-0.04-0.09-0.27-0.08-0.64-0.17
	C439.76,234.89,439.67,235.4,439.53,236.23z M406.35,221.11c0.4-0.47,0.77-0.72,0.76-0.96c0-0.27-0.34-0.54-0.54-0.81
	c-0.26,0.22-0.66,0.4-0.73,0.67C405.78,220.24,406.09,220.58,406.35,221.11z M432.11,229.76c0.46-0.38,0.96-0.66,0.92-0.79
	c-0.1-0.36-0.38-0.72-0.68-0.93c-0.1-0.07-0.72,0.32-0.74,0.53C431.58,228.9,431.87,229.25,432.11,229.76z M402.49,217.6
	c0.3-0.42,0.65-0.79,0.59-0.86c-0.19-0.26-0.52-0.42-0.8-0.62c-0.13,0.24-0.37,0.48-0.36,0.72
	C401.93,217.04,402.21,217.24,402.49,217.6z M435.71,232.88c0.47-0.43,0.81-0.74,1.15-1.05c-0.22-0.17-0.43-0.46-0.66-0.48
	C435.41,231.29,435.3,231.75,435.71,232.88z M432.74,221.04c-0.33-0.2-0.6-0.48-0.74-0.42c-0.23,0.09-0.39,0.38-0.57,0.59
	c0.25,0.21,0.49,0.41,0.74,0.62C432.33,221.61,432.48,221.4,432.74,221.04z"
              />
              <path
                d="M287.31,239.6c-0.41-0.98-0.82-1.96-1.26-3c-0.71-0.15-1.82-0.12-2.55-0.62c-0.68-0.47-0.95-1.52-1.46-2.42
	c-0.71-0.08-1.63-0.19-2.55-0.29c-0.14-0.14-0.29-0.28-0.43-0.41c-0.26-0.82-0.52-1.64-0.83-2.66c-0.49,0.08-1.07,0.18-1.55,0.25
	c-0.36,0.97-0.49,2-1.06,2.64c-0.51,0.57-1.49,0.73-2.43,1.13c0,0.6,0,1.44,0,2.37c0.47,0,1.03-0.07,1.57,0.01
	c1.11,0.16,1.63,0.94,1.72,1.98c0.09,1.03-0.77,1.97-1.87,2.1c-0.69,0.08-1.38,0.08-2.07,0.11c-0.13-1.04-0.26-2.07-0.4-3.18
	c-0.83,0.21-1.5,0.38-2.2,0.56c0.06,0.77,0.1,1.37,0.13,1.66c0.98,0.37,1.75,0.65,2.51,0.94c1.2,0.72,1.22,1.78,0.64,2.84
	c-0.54,0.99-1.47,1.36-2.61,1.11c-0.44-0.1-0.9-0.1-1.35-0.16c-0.15-0.87-0.31-1.73-0.45-2.53c-1.43,0.03-2.29,0.35-1.91,1.8
	c0.81,0.22,1.62,0.45,2.42,0.67c1.04,0.86,1.38,1.92,0.77,3.17c-0.51,1.04-1.27,1.35-3.06,1.38c-0.26,0.99-0.39,1.98-0.79,2.84
	c-0.18,0.39-0.89,0.66-1.4,0.75c-1.59,0.27-2.39-0.64-2.78-3.11c-0.43,0.04-0.87,0.08-1.32,0.1c-0.38,0.02-0.77,0-1.22,0
	c0.03,0.52,0.11,0.91,0.06,1.28c-0.19,1.45-1.03,2.22-2.33,2.18c-1.28-0.04-2.08-0.86-2.18-2.32c-0.03-0.39,0.07-0.79,0.11-1.18
	c0.11-0.13,0.22-0.25,0.33-0.38c0.93-0.3,1.86-0.59,2.81-0.9c-0.17-0.71-0.33-1.38-0.46-1.91c-1.06-0.23-1.96-0.43-2.87-0.63
	l0.09,0.08c-0.25-0.95-0.51-1.91-0.78-2.92c-0.76,0.05-1.52,0.09-2.1,0.13c-0.2,1.16-0.37,2.21-0.55,3.27c0,0,0.07-0.04,0.07-0.04
	c-0.99,0.25-1.97,0.5-2.96,0.74c0.03,3.14-0.57,3.71-3.79,3.61c-0.19-0.18-0.38-0.36-0.57-0.54c-0.33-0.77-0.66-1.53-0.96-2.24
	c-0.71,0-1.32,0-1.82,0c-0.2,1-0.38,1.89-0.55,2.79l0.07-0.09c-0.42,0.2-0.83,0.47-1.27,0.6c-1.4,0.41-2.66-0.22-3.05-1.47
	c-0.4-1.3,0.21-2.47,1.61-2.99c0.36-0.13,0.76-0.15,1.23-0.24c0-0.63,0-1.24,0-1.9c-1-0.25-1.97-0.49-2.94-0.73
	c0,0,0.08,0.08,0.08,0.08c-0.76-1.21-0.9-2.49-0.44-3.84l-0.08,0.08c0.85-0.41,1.7-0.82,2.7-1.3c0-0.51,0-1.19,0-1.87
	c-2.51-0.33-3.46-1.04-3.4-2.51c0.06-1.44,1-2.08,3.15-2.23c0.17-1.01,0.07-2.16,0.58-2.87c0.52-0.73,1.65-1.03,2.59-1.57
	c0.03-2.78,0.64-3.39,3.57-3.45c-0.36-3.14,0.1-3.75,2.98-4.04c0.33-1.04,0.44-2.21,1.04-3.02c0.43-0.58,1.51-0.68,2.27-0.99
	c0.28-0.89,0.48-1.94,0.94-2.85c0.19-0.37,0.95-0.59,1.47-0.62c1.41-0.09,2.17,1,2.35,3.2c1.08,0,2.16,0,3.24,0
	c0.18,0.17,0.36,0.33,0.54,0.5c0.29,0.79,0.57,1.57,0.84,2.29c0.69,0,1.23,0,1.79,0c0.1-0.98,0.19-1.9,0.29-2.82l-0.09,0.09
	c1.23-0.53,2.44-1.27,3.85-0.54c0.17,0.15,0.34,0.3,0.51,0.45c0.25,0.8,0.49,1.6,0.74,2.4c0.64-0.03,1.18-0.06,1.62-0.08
	c0.21-1,0.39-1.88,0.57-2.77l-0.07,0.06c2.75-1.86,4.25-1.05,4.53,2.53c0.82-0.1,1.64-0.2,2.37-0.29c0.31-0.98,0.49-1.98,0.95-2.83
	c0.2-0.36,0.96-0.55,1.48-0.59c1.14-0.07,2.14,0.99,2.14,2.12c0,1.31-0.93,2-3.06,2.25c0.12,0.78,0.24,1.56,0.35,2.25
	c1.16,0.3,2.29,0.59,3.42,0.88l-0.08-0.08c0.18,0.83,0.36,1.66,0.55,2.54c0.94,0.11,1.91,0.23,2.89,0.34
	c0.09,0.06,0.18,0.13,0.26,0.21c0.2,0.99,0.4,1.97,0.58,2.88c0.89,0.24,1.96,0.4,2.91,0.83c0.38,0.17,0.61,0.93,0.67,1.44
	c0.16,1.36-0.71,2.08-2.94,2.52c0,0.74,0,1.5,0,2.43c0.48,0,0.93-0.04,1.37,0.01c1.65,0.17,2.52,1.18,2.36,2.67
	c-0.15,1.37-1.15,2.13-2.71,2.04c-0.39-0.02-0.78-0.16-1.16-0.24L287.31,239.6z M258.29,241.99c0.39,0.82,0.79,1.64,1.22,2.56
	c0.49-0.08,1.08-0.18,1.79-0.29c-0.06-0.73-0.11-1.38-0.15-1.9c-1.1-0.11-2.03-0.2-2.95-0.3c-0.34-0.94-0.68-1.88-1.05-2.89
	c-0.54,0.1-1.07,0.19-1.64,0.3c0,0.83,0,1.53,0,2.07C256.49,241.7,257.39,241.85,258.29,241.99z M279.64,226.29
	c0.07,0.81,0.12,1.42,0.19,2.16c0.75-0.08,1.41-0.15,2.11-0.23c-0.09-0.78-0.16-1.38-0.24-2.08
	C281,226.19,280.46,226.23,279.64,226.29z M263.36,245.95c0,0.86,0,1.46,0,2.18c0.8-0.06,1.49-0.11,2.24-0.17
	c-0.14-0.74-0.25-1.36-0.37-2.01C264.62,245.95,264.08,245.95,263.36,245.95z M283.42,229.47c0.19,0.79,0.35,1.47,0.51,2.18
	c0.67-0.06,1.27-0.11,1.9-0.16c-0.06-0.89-0.12-1.64-0.18-2.46C284.92,229.18,284.3,229.3,283.42,229.47z M247.4,245.66
	c-0.15-0.72-0.27-1.31-0.41-1.97c-0.7,0.1-1.23,0.17-1.91,0.26c0.08,0.61,0.16,1.13,0.24,1.7
	C245.99,245.66,246.53,245.66,247.4,245.66z M245.56,229.22c-0.7,0.11-1.3,0.2-2,0.31c0.12,0.66,0.23,1.22,0.34,1.85
	c0.69-0.07,1.22-0.12,1.88-0.18C245.7,230.54,245.64,229.95,245.56,229.22z M239.96,233.02c0.13,0.84,0.25,1.57,0.37,2.34
	c0.7-0.1,1.3-0.18,2.01-0.27c-0.07-0.67-0.13-1.19-0.19-1.79C241.41,233.2,240.74,233.12,239.96,233.02z M250.67,241.9
	c-0.1-0.89-0.17-1.54-0.25-2.33c-0.74,0.24-1.3,0.42-1.89,0.61c0.06,0.65,0.1,1.17,0.15,1.72C249.36,241.9,249.9,241.9,250.67,241.9
	z M253.24,217.56c0.17,0.7,0.33,1.34,0.49,1.99c0.7-0.08,1.22-0.13,1.81-0.2c0.07-0.77,0.12-1.44,0.19-2.18
	C254.86,217.3,254.12,217.42,253.24,217.56z M274.16,219.04c-0.94,0.26-1.6,0.44-2.23,0.61c0.08,1.33,0.47,2.13,2.02,1.55
	C274.01,220.58,274.08,219.9,274.16,219.04z M247.08,227.35c1.55,0.03,2.01-0.64,1.52-2.06c-0.69,0-1.34,0-2.06,0
	C246.74,226.05,246.91,226.71,247.08,227.35z M249.95,221.37c0.18,0.81,0.31,1.41,0.46,2.08c0.64-0.13,1.15-0.23,1.72-0.35
	c-0.07-0.68-0.12-1.2-0.17-1.74C251.28,221.37,250.73,221.37,249.95,221.37z M277.75,224.62c0-0.55,0-1.14,0-1.84
	c-0.68,0.08-1.28,0.15-1.87,0.22C275.8,224.4,276.19,225.13,277.75,224.62z M260.56,235.25c-0.77,0.05-1.36,0.09-2.03,0.13
	c0.17,0.76,0.31,1.36,0.45,2.02c0.67-0.11,1.18-0.2,1.75-0.29C260.68,236.48,260.64,236.02,260.56,235.25z M262.08,231.52
	c0.1,0.74,0.17,1.24,0.25,1.83c0.71-0.1,1.22-0.17,1.76-0.25c-0.04-0.59-0.07-1.05-0.1-1.59
	C263.38,231.52,262.86,231.52,262.08,231.52z M271.39,232.49c-0.04-0.56-0.07-1.07-0.12-1.7c-0.71,0.1-1.3,0.18-2,0.28
	c0.11,0.62,0.19,1.11,0.28,1.63C270.21,232.62,270.74,232.56,271.39,232.49z M251.66,236.16c0.04,0.72,0.08,1.32,0.12,1.99
	c0.69-0.1,1.21-0.17,1.85-0.26c-0.11-0.69-0.21-1.27-0.31-1.88C252.77,236.06,252.38,236.1,251.66,236.16z M262.32,238.71
	c0.11,0.75,0.18,1.27,0.25,1.76c1.57,0.14,1.96-0.45,1.46-1.76C263.46,238.71,263,238.71,262.32,238.71z M241.25,242.49
	c0.78-0.14,1.29-0.23,2.18-0.4c-0.47-0.67-0.7-1.19-1.1-1.49c-0.21-0.16-0.71,0.07-1.07,0.13
	C241.25,241.3,241.25,241.75,241.25,242.49z M272.58,228.92c0.88,0.02,1.82,0.3,1.65-1.04c-0.17-1.35-1.02-0.77-1.84-0.72
	C272.46,227.85,272.52,228.38,272.58,228.92z M268.25,236.3c-0.47-0.68-0.69-1.2-1.08-1.49c-0.22-0.16-0.71,0.05-1.15,0.1
	c0.05,0.56,0.09,1,0.15,1.59C266.8,236.45,267.3,236.4,268.25,236.3z M260.02,228.21c-0.93-0.1-1.78-0.14-1.55,1.08
	c0.05,0.27,0.65,0.66,0.96,0.63C260.82,229.77,260.06,228.88,260.02,228.21z M255.06,234.14c0.72-0.05,1.23-0.09,2.23-0.17
	c-0.54-0.75-0.81-1.28-1.23-1.64c-0.17-0.14-0.68,0.12-1,0.2C255.06,233.05,255.06,233.44,255.06,234.14z M266.34,220.28
	c-0.55,0.07-1.04,0.13-1.56,0.2c0.12,0.79-0.07,1.72,1.04,1.53C266.9,221.84,266.5,221.05,266.34,220.28z M262.36,223.85
	c-0.39,0.68-0.75,1.03-0.72,1.34c0.03,0.32,0.44,0.61,0.69,0.91c0.26-0.3,0.68-0.57,0.73-0.9
	C263.1,224.9,262.74,224.54,262.36,223.85z M247.91,232.95c-0.19,1.56,0.47,1.76,1.62,1.29
	C249.82,232.89,249.24,232.56,247.91,232.95z M244.7,238.51c0.5-0.56,1.62,0.16,1.5-1.1c-0.02-0.22-0.52-0.53-0.82-0.55
	C244.49,236.78,244.33,237.32,244.7,238.51z M270.83,225.4c-0.51-0.66-0.83-1.08-1.16-1.51c-0.26,0.32-0.71,0.63-0.73,0.96
	C268.9,225.89,269.64,225.63,270.83,225.4z M252.06,228.56c-0.4,0.59-0.8,0.91-0.79,1.22c0.02,0.29,0.49,0.8,0.72,0.77
	c0.31-0.03,0.78-0.44,0.83-0.75C252.86,229.52,252.45,229.16,252.06,228.56z M266.96,229.43c0.13-0.15,0.26-0.29,0.39-0.44
	c-0.34-0.38-0.69-1.1-1.01-1.08c-1.14,0.06-0.79,0.86-0.59,1.52C266.19,229.43,266.58,229.43,266.96,229.43z M259.05,220.79
	c-0.5,0.45-1.07,0.72-1.2,1.13c-0.17,0.53,0.3,0.85,0.88,0.71C259.69,222.39,259.34,221.74,259.05,220.79z M254.53,227.1
	c0.67-0.49,1.38-0.75,1.41-1.07c0.1-1.12-0.7-0.91-1.53-0.71C254.44,225.76,254.47,226.19,254.53,227.1z"
              />
              <path
                d="M651.85,485.7c0.1,1.27,0.21,2.55,0.31,3.82c0,0-0.01,0-0.01,0c-1.39,0.59-2.78,0.74-4.16-0.01l0.09,0.07
	c-0.22-0.89-0.43-1.78-0.64-2.63c-0.92-0.09-1.82-0.19-2.72-0.28c-0.17-0.15-0.34-0.31-0.51-0.46c-0.49-1.12-0.48-2.24-0.02-3.37
	c0.93-0.34,1.86-0.67,2.84-1.02c-0.04-0.74-0.09-1.48-0.13-2.22c-0.89,0.05-1.65,0.09-2.33,0.13c-0.11,1.07-0.21,2.12-0.32,3.18
	c-0.94,0.29-1.89,0.58-2.94,0.9c0,0.73,0,1.58,0,2.43c-0.19,0.16-0.38,0.32-0.58,0.49c-1,0.96-2.16,0.58-3.29,0.38l0.1,0.08
	c-0.42-0.96-0.83-1.91-1.28-2.95c-0.7,0-1.47,0-2.23,0c-0.15-0.19-0.3-0.38-0.45-0.57c-0.66-0.76-0.99-1.58-0.6-2.6
	c0.53-1.39,1.81-1.55,3.04-1.69c0-1.05,0-1.91,0-2.77c0.18-0.18,0.35-0.36,0.53-0.54c2.99-0.79,3.69-0.36,4.45,2.81
	c0.75-0.03,1.51-0.07,2.28-0.11c-0.06-0.9-0.11-1.69-0.16-2.47c-2.95,0.18-3.43-0.19-4-3.09c-0.91,0-1.85,0-2.79,0
	c-0.16-0.31-0.32-0.61-0.49-0.92c-0.08-1.05-0.17-2.11-0.25-3.16c0.06-0.06,0.13-0.13,0.19-0.19c1.02-0.24,2.04-0.47,3.06-0.71
	c-0.19-0.8-0.35-1.46-0.54-2.22c-0.82,0-1.58,0-2.35,0c-0.15-0.19-0.3-0.38-0.45-0.58c-0.78-0.8-0.93-1.74-0.63-2.78
	c0.23-0.31,0.47-0.62,0.7-0.94c1.15-0.74,2.28-0.39,3.41,0.04l-0.09-0.08c0.28,0.94,0.56,1.88,0.84,2.8
	c0.81-0.04,1.56-0.08,2.14-0.11c0.14-1.1,0.27-2.08,0.4-3.06c0.13-0.17,0.26-0.34,0.39-0.51c1.07-0.06,2.19-0.56,3.14,0.37
	c0,0-0.08-0.08-0.08-0.08c1.18,0.75,0.86,1.93,0.84,3.05c2.03,0.52,1.59,2.57,2.65,3.88c-0.72,0.35-1.26,0.61-1.81,0.88
	c0,0.77,0,1.54,0,2.32c2.18,0.07,2.81,0.68,2.95,2.81c0.1,1.42-0.19,1.63-2.66,1.89c0.08,0.38,0.19,0.75,0.23,1.12
	c0.04,0.39,0.01,0.79,0.01,1.36c0.33,0,0.61-0.01,0.89,0c2.09,0.12,2.71,0.76,2.69,2.79c0,0.4-0.08,0.79-0.13,1.19l0.07-0.07
	c-0.92,0.37-1.85,0.74-2.91,1.16c0.04,0.52,0.08,1.12,0.11,1.59c1.2,0.25,2.24,0.47,3.28,0.69L651.85,485.7z M643.3,465.33
	c0.05,3.26-0.37,3.75-3.4,3.96c0.19,0.82,0.39,1.63,0.57,2.41c0.81-0.07,1.5-0.12,1.97-0.16c0.26-1.09,0.22-2.28,0.79-2.96
	c0.56-0.67,1.74-0.81,2.67-1.19c-0.07-0.67-0.15-1.43-0.23-2.18C644.85,465.26,644.07,465.29,643.3,465.33z M646.66,475.04
	c-0.1-1-0.19-1.82-0.27-2.6c-0.93,0.08-1.7,0.15-2.44,0.21c0.05,0.83,0.11,1.6,0.16,2.39C644.89,475.04,645.66,475.04,646.66,475.04
	z M639.73,480.38c-0.75,0.05-1.48,0.09-2.3,0.14c0.11,0.89,0.19,1.56,0.27,2.28c0.81-0.09,1.48-0.17,2.19-0.25
	C639.84,481.78,639.79,481.16,639.73,480.38z"
              />
              <path
                d="M379.12,390.13c-0.08-0.99-0.16-1.99-0.25-2.98c-2.32,0.21-3.44-0.4-3.59-1.96c-0.12-1.25,0.81-2.4,2.05-2.52
	c1.52-0.15,2.37,0.98,2.5,3.37c0.83-0.07,1.67-0.13,2.43-0.19c0.3-1.02,0.48-2.08,0.93-3c0.18-0.38,0.94-0.61,1.46-0.66
	c1.36-0.13,2.11,0.84,2.56,3.13c0.76-0.07,1.53-0.14,2.3-0.21c-0.15-2.35,0.36-3.34,1.82-3.55c1.29-0.19,2.4,0.66,2.54,1.94
	c0.17,1.51-0.65,2.23-3.01,2.63c0.04,0.76,0.07,1.54,0.12,2.41c0.91,0.09,1.83,0.17,2.75,0.26c0.17,0.15,0.33,0.31,0.5,0.46
	c0.23,0.9,0.47,1.8,0.71,2.72c2.43-0.1,3.42,0.36,3.6,1.88c0.07,0.59,0.02,1.46-0.35,1.77c-0.78,0.66-1.8,1.04-2.53,1.43
	c-0.19,0.98-0.37,1.88-0.54,2.79l0.07-0.05c-1.01,0.33-2.02,0.65-2.98,0.96c-0.17,0.78-0.23,1.8-0.63,2.66
	c-0.21,0.46-0.99,0.8-1.56,0.9c-1.62,0.28-2.45-0.78-2.71-3.14c-1.1-0.14-2.16-0.28-3.22-0.42c-0.15-0.06-0.29-0.13-0.43-0.21
	c-0.1-0.97-0.2-1.94-0.29-2.83c-1,0.05-1.85,0.1-2.7,0.14c-0.14-0.19-0.29-0.37-0.43-0.56c-0.81-0.92-1.11-1.93-0.41-3.04
	c0.75-1.18,1.87-1.32,3.25-0.97c-0.2-0.88-0.37-1.68-0.51-2.28c-1.27-0.35-2.4-0.66-3.53-0.96L379.12,390.13z M388.35,397.52
	c0.08,0.85,0.14,1.53,0.21,2.21c0.84-0.05,1.53-0.09,2.01-0.12c0.25-1.1,0.21-2.28,0.76-2.97c0.55-0.68,1.7-0.86,2.52-1.22
	c0-0.75,0-1.51,0-2.27c-0.93,0.08-1.75,0.16-2.58,0.23C391.54,396.34,391.4,396.56,388.35,397.52z M390.18,392.46
	c-0.09-0.87-0.17-1.7-0.26-2.59c-0.77,0.09-1.52,0.19-2.33,0.28c0.04,0.8,0.08,1.55,0.12,2.31
	C388.57,392.46,389.34,392.46,390.18,392.46z M384.27,396.48c0.99-0.23,1.71-0.4,2.44-0.58c-0.09-0.79-0.17-1.45-0.24-2.11
	c-0.88,0.12-1.62,0.23-2.4,0.34C384.14,394.93,384.2,395.61,384.27,396.48z M386.24,388.89c-0.09-0.84-0.17-1.53-0.25-2.26
	c-0.84,0.11-1.58,0.21-2.36,0.32c0.09,0.76,0.17,1.38,0.25,2.06C384.63,388.96,385.33,388.93,386.24,388.89z"
              />
              <path
                d="M394.24,389.28c-0.17-0.15-0.33-0.31-0.5-0.46c-0.1-0.77-0.4-1.57-0.27-2.29c0.27-1.46,1.39-1.87,3.63-1.55l-0.06-0.06
	c0.5,0.94,0.99,1.89,1.58,3c0.47-0.04,1.22-0.11,1.79-0.16c0.2-1.23,0.38-2.29,0.55-3.35l-0.08,0.07c0.89-0.23,1.78-0.45,2.72-0.69
	c0.09-0.88,0.19-1.8,0.28-2.72c0.13-0.14,0.26-0.27,0.39-0.41c0.94-0.27,1.89-0.55,2.73-0.79c0.11-0.94,0.21-1.79,0.31-2.65
	c0.11-0.11,0.21-0.24,0.31-0.36c0.98-0.15,1.95-0.29,2.93-0.44c0.18,0.13,0.35,0.27,0.53,0.4c0.36,0.85,0.73,1.7,1.1,2.59
	c0.59,0,1.28,0,1.78,0c0.39-1.15,0.54-2.36,1.17-3.21c0.75-1.01,2.03-0.49,3.11-0.36c0.13,0.09,0.25,0.19,0.37,0.3
	c0.44,1.18,0.65,2.36,0.06,3.55c-0.09,0.14-0.18,0.28-0.27,0.42c-0.89,0.14-1.78,0.28-2.62,0.41c0,0.91,0,1.82,0,2.73
	c-0.3,0.24-0.61,0.49-0.91,0.73c-0.81,0.2-1.62,0.4-2.23,0.55c-0.27,1.07-0.49,1.96-0.71,2.84l0.07-0.08
	c-0.91,0.42-1.81,0.84-2.88,1.33c0.05,0.58,0.11,1.33,0.16,1.92c1.02,0.16,1.86,0.29,2.71,0.42l0.15,0.1
	c0.23,0.41,0.57,0.79,0.68,1.22c0.32,1.31-0.38,2.45-1.58,2.74c-1.27,0.31-2.34-0.28-2.74-1.59c-0.13-0.44-0.11-0.93-0.17-1.43
	c-0.78,0.08-1.56,0.15-2.45,0.24c-0.1,1.04-0.19,2.1-0.29,3.16c0,0,0.08-0.08,0.08-0.08c-1.12,0.17-2.23,0.35-3.35,0.52
	c-0.19-0.14-0.37-0.27-0.56-0.41c-0.35-0.93-0.7-1.86-1.05-2.8c-2.76-0.07-3.13-0.38-3.7-3.12
	C396.07,389.45,395.15,389.36,394.24,389.28z M401.95,391.41c1-0.13,1.81-0.23,2.6-0.33c-0.14-0.82-0.25-1.5-0.38-2.29
	c-0.78,0.05-1.52,0.1-2.22,0.15C401.95,389.82,401.95,390.52,401.95,391.41z M405.31,385.06c0.05,0.89,0.08,1.58,0.12,2.38
	c0.78-0.09,1.49-0.17,2.26-0.26c-0.18-0.83-0.33-1.49-0.49-2.24C406.58,384.98,406.05,385.02,405.31,385.06z M410.76,381.06
	c-0.92,0.07-1.67,0.13-2.43,0.18c0.15,0.78,0.27,1.4,0.39,2.04c0.73-0.08,1.42-0.15,2.16-0.22
	C410.84,382.43,410.81,381.88,410.76,381.06z"
              />
              <path
                d="M671.53,581.9c-0.17-0.08-0.34-0.16-0.52-0.23c-0.65-0.86-1.25-1.89-0.49-2.81c0.68-0.82,1.77-1.31,2.85-2.07
	c-0.03-0.39-0.07-1.08-0.12-1.85c-0.99,0-1.92,0-2.85,0c-0.17-0.3-0.33-0.61-0.5-0.91c-0.1-0.96-0.2-1.92-0.3-2.88
	c0.18-0.18,0.36-0.36,0.53-0.54c0.9-0.2,1.79-0.41,2.68-0.61c0.07-0.94,0.14-1.87,0.21-2.8c0.08-0.11,0.18-0.21,0.28-0.3
	c3.14-1.05,4.13-0.4,4.29,2.78c0.56,0.34,1.29,0.55,1.61,1.03c0.54,0.82,1.25,1.72,0.69,2.85c-2.26,0.48-2.68,0.14-3.05-2.58
	c-0.83,0.09-1.66,0.18-2.62,0.28c0.06,0.7,0.12,1.38,0.18,2.01c1,0.3,2.13,0.36,2.85,0.94c0.64,0.52,0.83,1.61,1.25,2.52
	c2.21,0.32,2.82,0.76,2.97,2.22c0.16,1.52-0.31,2.01-2.64,2.74c0,0.7,0,1.41,0,2.27c0.55,0.04,1.08,0.04,1.6,0.13
	c1.36,0.23,2.16,1.22,2.04,2.47c-0.11,1.16-0.94,1.9-2.28,1.98c-0.47,0.03-0.95-0.08-1.43-0.13c-0.15-0.14-0.31-0.28-0.46-0.41
	c-0.16-0.91-0.31-1.82-0.45-2.63c-0.99-0.12-1.98-0.23-2.97-0.35l0.09,0.07c-0.42-0.91-0.84-1.83-1.3-2.83
	C673.06,582.15,672.29,582.02,671.53,581.9z M677.29,580.38c-0.06-0.75-0.1-1.35-0.16-2.1c-0.77,0.05-1.43,0.1-2.24,0.16
	c0.06,0.8,0.11,1.46,0.16,2.19C675.8,580.55,676.42,580.48,677.29,580.38z"
              />
              <path
                d="M670.13,570.6c-0.18,0.18-0.36,0.36-0.53,0.54c-1.02,0.15-2.05,0.29-3.07,0.44c-0.16-0.31-0.31-0.61-0.47-0.92
	c-0.09-0.96-0.19-1.93-0.28-2.89c0.16-0.13,0.31-0.26,0.47-0.39c0.89-0.27,1.78-0.54,2.66-0.81c-0.1-0.76-0.2-1.44-0.29-2.1
	c-2.92-0.13-3.29-0.44-3.86-3.18c-0.93-0.08-1.86-0.16-2.79-0.24c-0.16-0.14-0.32-0.29-0.48-0.43c-0.04-1.12-0.09-2.24-0.13-3.35
	c0.19-0.16,0.39-0.33,0.58-0.49c0.98,0.05,1.95,0.11,2.93,0.16c0.09,0.09,0.18,0.17,0.28,0.24c0.33,0.84,0.65,1.69,1,2.59
	c0.65-0.07,1.47-0.17,1.98-0.22c0.21-1.26,0.39-2.3,0.57-3.34l-0.06,0.07c0.97-0.23,1.94-0.47,2.81-0.67
	c0.22-1.13,0.42-2.19,0.62-3.25c0,0-0.08,0.05-0.08,0.05c0.55,0.03,1.09,0.06,1.62,0.09c1.61,3.22,1.58,3.46-0.57,4.31
	c0,0.74,0,1.52,0,2.16c1.02,0.37,2.02,0.57,2.81,1.08c0.43,0.28,0.68,1.08,0.73,1.67c0.13,1.38-0.61,1.93-2.97,2.38
	c-0.1,0.93-0.21,1.87-0.32,2.8c-0.1,0.09-0.19,0.19-0.28,0.3c-0.87,0.27-1.74,0.55-2.54,0.8
	C670.36,568.82,670.24,569.71,670.13,570.6z M669.73,563.14c0.9-0.11,1.7-0.21,2.53-0.32c-0.12-0.83-0.23-1.5-0.34-2.27
	c-0.78,0.04-1.47,0.07-2.19,0.11C669.73,561.54,669.73,562.3,669.73,563.14z"
              />
              <path
                d="M379.94,520.68c-1,0.02-2,0.04-3,0.06c-0.18-0.19-0.36-0.38-0.54-0.57c-0.13-0.94-0.25-1.88-0.38-2.82
	c0.15-0.19,0.31-0.38,0.46-0.58c0.94-0.25,1.89-0.51,2.62-0.7c0.24-1,0.21-2.17,0.77-2.85c0.55-0.66,1.71-0.81,2.31-1.06
	c0.28-1.09,0.53-2.04,0.77-2.98l0.01,0.01c2.81-1.15,3.79-0.57,4.19,2.35c1.17,0.21,2.28,0.41,3.38,0.61
	c0.1,0.04,0.19,0.09,0.29,0.14c-0.01,1.06-0.01,2.12-0.02,3.18l0.08-0.08c-1.06,0.45-2.11,0.91-3.22,1.38
	c0.25,2.1-0.39,3.27-1.98,3.44c-1.52,0.15-2.35-0.82-2.69-3.19c-0.84,0.04-1.67,0.09-2.3,0.12c-0.14,1.12-0.27,2.1-0.4,3.09
	C380.17,520.4,380.06,520.54,379.94,520.68z M383.8,513.34c0.15,0.91,0.26,1.6,0.38,2.32c0.74-0.09,1.42-0.18,2.18-0.28
	c-0.11-0.75-0.22-1.44-0.33-2.2C385.31,513.23,384.63,513.28,383.8,513.34z"
              />
              <path
                d="M278.49,391.62c0.41,0.99,0.81,1.98,1.21,2.95c0.79,0.2,1.77,0.33,2.63,0.73c0.39,0.19,0.66,0.89,0.75,1.41
	c0.24,1.44-0.64,2.22-2.71,2.67c-0.33,1.1-0.53,2.17-0.98,3.12c-0.18,0.38-0.92,0.61-1.43,0.68c-1.53,0.21-2.37-0.74-2.77-2.73
	c-1.2-0.5-2.37-0.73-3.19-1.4c-1.02-0.83-0.39-2.05-0.12-3.12l-0.07,0.09c0.94-0.32,1.89-0.64,2.79-0.95
	c0.1-0.8,0.21-1.64,0.32-2.48c0.1-0.15,0.19-0.29,0.29-0.44c1.02-0.96,2.21-0.65,3.39-0.45L278.49,391.62z M276.15,396.41
	c0.06,0.68,0.12,1.35,0.18,2.1c0.9-0.1,1.64-0.19,2.37-0.28c-0.11-0.83-0.19-1.5-0.29-2.2
	C277.62,396.17,276.94,396.28,276.15,396.41z"
              />
              <path
                d="M404.77,182.45c-0.82,0.24-1.64,0.48-2.68,0.78c0.29,1.01,0.29,2.15-0.72,3c-0.91,0.77-1.92,0.57-2.93,0.34
	c-0.11-0.1-0.22-0.18-0.34-0.26c-0.67-1.09-0.85-2.21-0.29-3.41c0.07-0.1,0.13-0.19,0.19-0.29c0.87-0.3,1.75-0.6,2.61-0.89
	c-0.06-0.78-0.11-1.6-0.17-2.33c-0.9,0-1.68,0-2.45,0c-0.3-0.23-0.59-0.46-0.89-0.69c-0.05-0.23-0.12-0.46-0.16-0.7
	c-0.28-1.77,0.5-3.02,1.97-3.15c1.35-0.12,2.3,0.85,2.48,2.57c0.02,0.16-0.03,0.32-0.01,0.48c0.02,0.15,0.11,0.28,0.1,0.28
	c1,0,1.93,0,2.86,0c0.13,0.15,0.26,0.3,0.39,0.45c0.16,1.11,0.32,2.21,0.48,3.32C405.09,182.11,404.93,182.28,404.77,182.45z"
              />
              <path
                d="M659.5,535.76c0.16-0.2,0.32-0.4,0.47-0.6c0.99,0.03,1.98,0.06,2.97,0.08c0.15,0.12,0.29,0.25,0.44,0.37
	c0.28,0.88,0.56,1.76,0.84,2.67c2.42,0.11,3.33,0.68,3.47,2.22c0.14,1.58-0.65,2.3-2.91,2.64c-0.13,0.98-0.25,1.97-0.38,2.95
	c-0.14,0.18-0.29,0.36-0.43,0.54c-0.98,0.01-1.97,0.02-2.95,0.03c-0.18-0.19-0.36-0.38-0.54-0.57c-0.6-0.85-0.86-1.93-0.22-2.64
	c0.7-0.77,1.83-1.15,2.87-1.75c-0.05-0.54-0.12-1.31-0.18-1.95c-1-0.09-1.92-0.18-2.84-0.26c-0.18-0.17-0.37-0.33-0.55-0.5
	C659.13,537.91,658.97,536.84,659.5,535.76z"
              />
              <path
                d="M398.01,182.61c-0.06,0.1-0.12,0.2-0.19,0.29c-1.01,0.26-2.02,0.51-2.82,0.72c-0.07,1.12-0.13,2.1-0.19,3.07
	c-0.18,0.13-0.36,0.27-0.54,0.4c-0.98,0.04-1.96,0.08-2.94,0.12c0,0,0.05,0.07,0.05,0.07c-0.94-0.78-1.33-1.78-0.98-2.96
	c0.47-1.59,1.94-1.54,3.22-1.7c-0.18-0.91-0.33-1.66-0.47-2.41c-1-0.07-1.94-0.13-2.87-0.19c-0.11-0.09-0.22-0.18-0.32-0.29
	c-0.31-1.19-0.44-2.38,0.02-3.56c0,0-0.08,0.08-0.08,0.08c0.08-0.01,0.16-0.01,0.24-0.04c2.78-1.11,3.71-0.6,4.24,2.48
	c0.89,0,1.82,0,2.75,0c0.3,0.23,0.59,0.46,0.89,0.69C398.01,180.47,398.01,181.54,398.01,182.61z"
              />
              <path
                d="M389.95,179.75c0.1,0.11,0.21,0.2,0.32,0.29c0.71,0.87,0.88,1.85,0.39,2.85c-0.59,1.21-1.79,1.3-2.75,1.31
	c-0.16,1.14-0.3,2.12-0.44,3.09c-0.1,0.13-0.2,0.25-0.3,0.38c-1.02,0.01-2.03,0.03-3.05,0.04c0,0,0.05,0.07,0.05,0.07
	c-0.5-0.93-1-1.87-1.57-2.93c-0.68,0-1.62,0-2.56,0c-0.08-0.21-0.15-0.41-0.23-0.62c-0.69-1.1-0.86-2.24,0.02-3.28
	c0.8-0.95,1.86-1.12,2.99-0.52c0,0,0.2,0.1,0.2,0.1c0.47,0.93,0.93,1.86,1.41,2.81c0.59-0.05,1.21-0.1,1.6-0.13
	c0.23-1.12,0.44-2.15,0.65-3.17c0.07-0.04,0.15-0.09,0.21-0.14C387.89,179.36,388.91,179.24,389.95,179.75z"
              />
              <path
                d="M376.4,520.18c0.18,0.19,0.36,0.38,0.54,0.57c0.4,1.16,0.35,2.32-0.06,3.47c-0.04,0.08-0.09,0.16-0.14,0.23
	c-0.92,0.14-1.84,0.29-2.83,0.44c0.05,0.45,0.16,0.91,0.14,1.37c-0.08,1.49-1.05,2.37-2.45,2.28c-1.37-0.09-2.2-1.11-2.1-2.62
	c0.03-0.47,0.16-0.93,0.24-1.4c0,0-0.05,0.09-0.05,0.09c1.02-0.28,2.03-0.56,3.05-0.83c-0.11-0.68-0.22-1.36-0.34-2.1
	c-0.97,0-1.89,0-2.82,0c-0.19-0.3-0.38-0.61-0.56-0.91c-0.09-0.96-0.19-1.92-0.28-2.89c0.18-0.18,0.35-0.36,0.53-0.55
	c0.99-0.04,2.22-0.47,2.9-0.03c0.84,0.54,1.21,1.8,1.86,2.88C374.68,520.18,375.54,520.18,376.4,520.18z"
              />
              <path
                d="M358.58,525.45c-0.2-0.89-0.41-1.79-0.62-2.7c-0.69,0.05-1.39,0.09-2.22,0.15c0.01,0.26,0.03,0.53,0.04,0.81
	c0.09,2.34-0.74,3.09-3.11,2.83c-0.31-0.03-0.62-0.07-0.93-0.11l-0.09-0.04c-0.47-1.24-0.52-2.5-0.11-3.76l-0.09,0.1
	c1-0.3,2-0.61,2.79-0.84c0.14-0.94,0.27-1.76,0.4-2.58c0.05-0.08,0.09-0.17,0.13-0.25c0.97-1.14,2.24-0.76,3.45-0.67
	c0.14,0.15,0.27,0.3,0.41,0.44c0.2,0.88,0.41,1.76,0.6,2.58c0.92-0.05,1.77-0.1,2.61-0.15c0.09,0.22,0.18,0.45,0.27,0.67
	c0.91,1.69,0.94,2.48,0.07,3.36c-1.18,1.19-2.43,0.71-3.67,0.11L358.58,525.45z"
              />
              <path
                d="M642.65,460.81c-0.13,0.17-0.26,0.34-0.39,0.51c-1.05,0.86-2.21,0.6-3.38,0.37l0.09,0.08c-0.75-1.26-1.41-2.53-0.26-3.91
	c0.06-0.06,0.13-0.12,0.19-0.18c0.76-0.2,1.51-0.41,2.38-0.64c-0.04-0.62-0.09-1.3-0.15-2.05c-1.03,0-1.97,0-2.91,0
	c-0.16-0.31-0.32-0.61-0.48-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.52-0.52c2.98-0.8,3.64-0.39,4.26,2.42
	c1.02,0.39,1.99,0.64,2.81,1.14c0.37,0.23,0.55,0.96,0.6,1.49c0.15,1.56-0.49,2.12-2.8,2.57
	C642.79,459.1,642.72,459.95,642.65,460.81z"
              />
              <path
                d="M226.76,212.15c0.13-0.13,0.26-0.26,0.4-0.39c0.52-0.18,1.03-0.42,1.56-0.52c0.45-0.09,0.93-0.02,1.27-0.02
	c0.19-0.97,0.24-1.91,0.59-2.71c0.16-0.37,0.9-0.62,1.41-0.69c1.19-0.16,2.2,0.76,2.24,1.92c0.06,1.34-0.77,1.96-3.01,2.25
	c0.81,3.05-0.44,4.17-2.92,4.23c-0.2,1.07-0.4,2.12-0.6,3.18c-0.08,0.03-0.17,0.05-0.25,0.06c-0.37,0.13-0.73,0.34-1.11,0.39
	c-1.67,0.22-2.73-0.5-2.95-1.95c-0.23-1.46,0.52-2.45,2.15-2.83c0.3-0.07,0.61-0.07,0.96-0.11
	C226.58,214,226.67,213.08,226.76,212.15z"
              />
              <path
                d="M404.77,182.45c0.16-0.17,0.31-0.33,0.47-0.5c0.89-0.26,1.78-0.52,2.69-0.79c-0.1-0.73-0.21-1.55-0.28-2.09
	c-1.09-0.17-1.99-0.3-2.9-0.44c-0.13-0.15-0.26-0.3-0.39-0.45c-0.04-0.16-0.08-0.31-0.11-0.47c-0.3-1.94,0.33-3.08,1.8-3.26
	c1.29-0.16,2.18,0.72,2.53,2.54c0.03,0.15,0.06,0.31,0.06,0.3c1.1,0.3,2.2,0.45,3.13,0.92c0.42,0.21,0.66,1.08,0.69,1.66
	c0.09,1.53-0.86,2.2-3.26,2.38c0.06,0.46,0.2,0.92,0.17,1.36c-0.08,1.43-0.92,2.29-2.2,2.37c-1.38,0.08-2.3-0.78-2.46-2.33
	C404.68,183.25,404.75,182.85,404.77,182.45z"
              />
              <path
                d="M652.14,489.53c0,0,0.01,0,0.01,0c0.5-0.13,1.27-0.52,1.46-0.34c0.6,0.59,1.13,1.35,1.44,2.14
	c0.48,1.26,0.05,1.67-1.95,2.17c0,0.84,0,1.68,0,2.52c2.18-0.07,2.93,0.31,3.29,1.6c0.52,1.88-0.63,3.16-2.55,2.82
	c-0.39-0.07-0.75-0.26-1.13-0.39c-0.12-0.04-0.23-0.09-0.34-0.15c0-0.9,0-1.81,0-2.69c-1.16,0-2.08,0-2.99,0
	c-0.12-0.14-0.25-0.28-0.37-0.43c-0.19-1.11-0.39-2.22-0.58-3.34c0.19-0.17,0.38-0.34,0.57-0.51c0.9-0.24,1.8-0.49,2.4-0.65
	C651.67,491.27,651.91,490.4,652.14,489.53z"
              />
              <path
                d="M334.31,328.98c0.52-0.19,1.02-0.45,1.55-0.56c1.46-0.31,2.77,0.54,3.03,1.92c0.24,1.25-0.56,2.41-1.96,2.78
	c-0.37,0.1-0.78,0.06-1.1,0.08c-0.26,1.15-0.52,2.26-0.77,3.36l0.08-0.08c-0.76,0.12-1.55,0.44-2.27,0.33
	c-1.44-0.21-1.89-1.37-1.58-3.58c0.16-0.19,0.32-0.37,0.49-0.56c0.74-0.19,1.48-0.39,2.26-0.59c0.1-1.03,0.21-2.09,0.32-3.14
	C334.36,328.93,334.31,328.98,334.31,328.98z"
              />
              <path
                d="M369.02,520.76c0.19,0.3,0.38,0.61,0.56,0.91c0.66,0.96,0.6,1.94,0.11,2.94c0,0,0.05-0.09,0.05-0.09
	c-0.92,0.46-1.84,0.93-2.73,1.38c-0.23,0.95-0.49,2.04-0.76,3.12l0.09-0.08c-0.38,0.1-0.75,0.23-1.13,0.29
	c-1.41,0.24-2.42-0.32-2.82-1.53c-0.4-1.21,0.13-2.31,1.43-2.87c0.43-0.18,0.92-0.21,1.48-0.33c0.06-0.97,0.13-1.98,0.19-2.98
	c0.09-0.1,0.18-0.19,0.28-0.27C366.85,521.09,367.94,520.92,369.02,520.76z"
              />
              <path
                d="M365.76,521.25c-0.1,0.08-0.19,0.18-0.28,0.27c-1.03,0.96-2.2,0.64-3.37,0.39c-0.09-0.22-0.18-0.45-0.27-0.67
	c-0.2-0.79-0.4-1.58-0.57-2.27c-0.97-0.06-1.81-0.11-2.64-0.16c-0.14-0.15-0.27-0.3-0.41-0.44c-0.56-1.13-0.94-2.37,0.2-3.25
	c0.59-0.46,1.61-0.62,2.39-0.52c1.04,0.13,1.35,0.94,1.53,2.87c0.98,0.04,1.97,0.09,2.95,0.13c0.05,0.1,0.09,0.2,0.13,0.3
	C366.3,518.93,366.59,520.03,365.76,521.25z"
              />
              <path
                d="M655.08,522.15c-0.19-0.3-0.37-0.61-0.56-0.91c-0.07-1.04-0.15-2.07-0.22-3.11c0.06-0.09,0.12-0.17,0.18-0.25
	c1.06-0.12,2.12-0.23,3.18-0.35c0.17,0.12,0.33,0.23,0.5,0.35c0.36,0.85,0.72,1.7,1.17,2.77c0.12,0.01,0.49,0.01,0.86,0.05
	c1.83,0.2,2.45,0.95,2.29,2.81c-0.05,0.55-0.17,1.09-0.26,1.63c0,0,0.01-0.1,0.01-0.1c-0.54,0.04-1.07,0.11-1.61,0.13
	c-1.46,0.05-2.12-0.45-2.5-1.9c-0.09-0.35-0.08-0.73-0.12-1.11C656.92,522.15,656,522.15,655.08,522.15z"
              />
              <path
                d="M376.49,516.79c-0.15,0.19-0.31,0.38-0.46,0.58c-3.22,0.61-4.1-0.16-3.87-3.37l-0.09,0.08c0.83-0.94,1.86-1.41,3.04-1.22
	c0.15-1.12,0.29-2.18,0.44-3.24l-0.09,0.09c1.13-0.15,2.26-0.29,3.38-0.44c0,0-0.06-0.08-0.06-0.08c1.94,2.74,1.26,4.24-1.94,4.6
	C376.72,514.82,376.6,515.8,376.49,516.79z"
              />
              <path
                d="M411.07,376.83c-0.18-0.13-0.35-0.27-0.53-0.4c-0.19-0.91-0.39-1.82-0.55-2.6c-0.84-0.07-1.61-0.12-2.38-0.18
	c-0.15-0.07-0.31-0.14-0.46-0.22c-0.16-0.27-0.36-0.53-0.46-0.83c-0.54-1.58-0.01-2.83,1.36-3.28c1.32-0.43,2.5,0.36,2.93,1.97
	c0.08,0.3,0.07,0.62,0.11,1.06c0.48-0.02,0.94-0.09,1.4-0.06c1.64,0.13,2.54,1.11,2.41,2.61c-0.12,1.37-1.06,2.13-2.65,2.11
	C411.85,377.01,411.46,376.89,411.07,376.83z"
              />
              <path
                d="M383.66,400.56c0.14,0.07,0.28,0.14,0.43,0.21c0.42,0.9,0.84,1.8,1.3,2.79c2.3,0.16,3.22,0.79,3.19,2.32
	c-0.02,1.11-0.78,1.98-1.86,2.13c-1.54,0.21-2.32-0.67-2.75-3.05c-0.93,0-1.86,0-2.79,0c-0.14-0.11-0.27-0.22-0.41-0.33
	c-0.76-1.13-0.59-2.37-0.41-3.61c0.04-0.07,0.08-0.14,0.12-0.21C381.55,400.72,382.61,400.64,383.66,400.56z"
              />
              <path
                d="M537.2,336.51c0.17,0.16,0.34,0.31,0.51,0.47c0.14,0.97,0.27,1.93,0.41,2.9l0.08-0.09c-1.08,1.24-2.46,1.29-3.93,0.98
	l0.09,0.08c-0.35-1.04-0.7-2.07-1.03-3.07c-0.74-0.06-1.63-0.14-2.52-0.22l-0.17-0.05c-0.29-0.55-0.72-1.06-0.83-1.64
	c-0.29-1.48,0.98-2.76,2.51-2.6c0.53,0.05,1.04,0.25,1.57,0.38c0,0-0.09-0.07-0.09-0.07c0.25,0.96,0.5,1.92,0.74,2.83
	C535.48,336.43,536.34,336.47,537.2,336.51z"
              />
              <path
                d="M397.09,504.43c0.22,0.98,0.43,1.97,0.64,2.94c0.86-0.05,1.72-0.11,2.58-0.16c0,0-0.06-0.05-0.06-0.05
	c0.19,0.26,0.36,0.52,0.56,0.76c0.67,0.82,0.81,1.72,0.31,2.66c-0.51,0.96-1.39,1.24-2.43,1.13c-0.39-0.04-0.78-0.1-1.18-0.14
	l0.08,0.09c-0.34-1.06-0.68-2.13-0.95-2.95c-1.02-0.17-1.99-0.32-2.96-0.48l0.07,0.07c-0.7-1.01-1.26-2.15-0.33-3.2
	c0.5-0.55,1.42-0.82,2.2-0.99c0.47-0.1,1.03,0.26,1.55,0.41C397.18,504.51,397.09,504.43,397.09,504.43z"
              />
              <path
                d="M543.85,368.61c0.18,0.26,0.37,0.51,0.53,0.78c0.54,0.93,0.53,1.95-0.25,2.61c-0.59,0.5-1.55,0.87-2.29,0.8
	c-1.1-0.1-1.76-0.97-1.81-2.16c-0.01-0.31-0.03-0.61-0.05-1.1c-1.26,0.11-2.58,0.44-3.4-0.9c-0.64-1.04-0.23-1.97,0.2-2.92
	c0,0-0.09,0.08-0.09,0.08c0.98-0.14,1.96-0.28,2.93-0.42c0.17,0.13,0.34,0.26,0.51,0.39c0.28,0.86,0.55,1.72,0.78,2.42
	c0.96,0.15,2,0.31,3.03,0.47L543.85,368.61z"
              />
              <path
                d="M637.03,443.78c0.06-0.09,0.12-0.18,0.18-0.27c1.06-0.12,2.12-0.23,3.19-0.35c0.16,0.11,0.31,0.22,0.47,0.33
	c0.24,0.41,0.54,0.8,0.7,1.24c0.16,0.44,0.17,0.93,0.25,1.4c2.28,0.6,2.33,0.68,2.51,2.72c0.07,0.83,0.16,1.61-0.89,1.91
	c-0.95,0.27-2-0.1-2.39-1.03c-0.24-0.56-0.28-1.22-0.4-1.83c-1.01-0.12-2.02-0.24-3.02-0.35c-0.11-0.23-0.23-0.46-0.34-0.69
	C637.19,445.84,637.11,444.81,637.03,443.78z"
              />
              <path
                d="M228.31,222.79c0.11,0.61,0.29,1.22,0.31,1.84c0.05,1.46-1.11,2.58-2.54,2.51c-1.35-0.07-2.26-1.13-2.2-2.58
	c0.06-1.44,1-2.36,2.47-2.26c0.69,0.05,1.36,0.35,2.03,0.54C228.38,222.84,228.31,222.79,228.31,222.79z"
              />
              <path
                d="M637.27,446.86c0.11,0.23,0.23,0.46,0.34,0.69c0.86,0.94,0.81,2,0.36,3.1c-0.17,0.17-0.34,0.35-0.52,0.52
	c-1.1,0.71-2.21,0.48-3.32,0.02c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.01-0.16-2.03-0.24-3.04c0.1-0.11,0.2-0.21,0.31-0.31
	C635.07,447.19,636.17,447.03,637.27,446.86z"
              />
              <path
                d="M345.7,331.72c0.15-0.06,0.31-0.1,0.43-0.18c1.12-0.73,2.3-0.87,3.28,0.09c1.06,1.03,1,2.26,0.14,3.45
	c0,0,0.04-0.05,0.04-0.05c-1.02,1.05-2.22,0.8-3.43,0.48c-0.13-0.11-0.27-0.22-0.4-0.33c-0.48-1.18-0.56-2.36,0.01-3.53
	L345.7,331.72z"
              />
              <path
                d="M379.81,184.21c0.08,0.21,0.15,0.41,0.23,0.62c0.73,0.89,0.62,1.85,0.27,2.84c-0.16,0.18-0.32,0.36-0.49,0.54
	c-1.19,0.75-2.35,0.41-3.51-0.11l0.08,0.09c-0.65-1.35-0.5-2.67,0.16-3.97c0,0-0.08,0.09-0.08,0.09
	C377.58,184.27,378.7,184.24,379.81,184.21z"
              />
              <path
                d="M489.23,485.17c-0.18,0.18-0.35,0.37-0.53,0.55c-1.18,0.72-2.32,0.35-3.45-0.14l0.08,0.08c-0.69-1.42-0.55-2.8,0.19-4.15
	c0,0-0.05,0.08-0.05,0.08c0.95-0.06,1.91-0.12,2.86-0.18c0.28,0.29,0.57,0.58,0.85,0.88C489.21,483.26,489.22,484.22,489.23,485.17z
	"
              />
              <path
                d="M365.42,517.89c-0.04-0.1-0.08-0.2-0.13-0.3c-0.83-3.02-0.06-4.01,3.05-3.93c0.23,0.27,0.46,0.55,0.69,0.82
	c0.75,0.89,0.64,1.86,0.24,2.85c-0.18,0.18-0.35,0.36-0.53,0.55C367.64,518.57,366.53,518.33,365.42,517.89z"
              />
              <path
                d="M271.87,395.93c-0.42,0.22-0.82,0.47-1.26,0.64c-1,0.41-2,0.19-2.54-0.66c-0.41-0.65-0.59-1.63-0.48-2.4
	c0.15-1.04,1.08-1.52,2.1-1.59c0.54-0.04,1.09,0.11,1.64,0.17c0.17,0.18,0.34,0.35,0.51,0.53c0.44,1.14,0.54,2.27-0.05,3.4
	C271.8,396.02,271.87,395.93,271.87,395.93z"
              />
              <path
                d="M342.38,336.01c-0.45,0.14-0.9,0.34-1.36,0.42c-1.05,0.18-1.88-0.21-2.45-1.11c-0.52-0.82-0.51-1.67,0.03-2.48
	c0.58-0.88,1.43-1.26,2.47-1.07c0.47,0.08,0.92,0.27,1.37,0.4c0,0-0.09-0.05-0.09-0.05c0.18,1.13,0.36,2.25,0.54,3.38
	C342.71,335.67,342.54,335.84,342.38,336.01z"
              />
              <path
                d="M491.13,510.35l-0.04,0.04c0.54,1.14,1.16,2.27,0.52,3.59c-0.14,0.16-0.28,0.32-0.42,0.49c-1.23,0.77-2.42,0.41-3.61-0.1
	l0.08,0.08c-0.08-0.3-0.16-0.59-0.23-0.9c-0.45-2.07,0.43-3.21,2.51-3.26C490.34,510.28,490.74,510.33,491.13,510.35z"
              />
              <path
                d="M271.85,392.63c-0.17-0.18-0.34-0.35-0.51-0.53c-0.16-0.44-0.42-0.88-0.46-1.33c-0.12-1.48,0.79-2.51,2.17-2.58
	c1.46-0.06,2.53,1.03,2.45,2.57c-0.02,0.47-0.2,0.93-0.3,1.39c-0.1,0.15-0.19,0.29-0.29,0.44
	C273.89,392.6,272.87,392.61,271.85,392.63z"
              />
              <path
                d="M229.17,231.78c0.06,1.26-0.86,2.33-2.08,2.44c-1.27,0.11-2.44-1.01-2.45-2.34c0-1.22,1.04-2.31,2.22-2.33
	C228.08,229.53,229.11,230.54,229.17,231.78z"
              />
              <path
                d="M532.87,322.58c-0.15,0.15-0.29,0.3-0.44,0.45c-0.3,0.1-0.59,0.21-0.89,0.3c-1.15,0.35-2.13-0.02-2.61-1.04
	c-0.29-0.62-0.31-1.58-0.04-2.21c0.43-1.03,1.41-1.44,2.56-1.15c0.31,0.08,0.61,0.17,0.91,0.26c0.16,0.12,0.32,0.25,0.48,0.37
	C532.85,320.57,532.86,321.57,532.87,322.58z"
              />
              <path
                d="M397.09,384.97c-0.69-1.35-0.64-2.67,0.13-3.98l-0.08,0.07c0.08,0.01,0.16,0.03,0.24,0.02c3.04-0.78,4.21,0.36,3.48,3.4
	c0,0,0.08-0.07,0.08-0.07c-1.17,1.19-2.51,1.14-3.92,0.5L397.09,384.97z"
              />
              <path
                d="M244.42,250.09c0.19,0.18,0.38,0.36,0.57,0.54c0.16,0.44,0.41,0.87,0.45,1.32c0.16,1.55-0.83,2.65-2.29,2.63
	c-1.44-0.01-2.43-1.14-2.28-2.67c0.05-0.46,0.19-0.91,0.29-1.37c0,0-0.07,0.09-0.07,0.09C242.2,250.45,243.31,250.27,244.42,250.09z
	"
              />
              <path
                d="M534.29,300.97c0.16,0.12,0.32,0.25,0.47,0.37c0.02,0.97,0.04,1.95,0.06,2.92c-0.16,0.18-0.31,0.36-0.47,0.54
	c-1.18,0.68-2.34,0.34-3.49-0.09l0.08,0.08c-0.12-0.37-0.27-0.73-0.35-1.11c-0.41-1.96,0.77-3.22,2.78-2.96
	C533.69,300.75,533.99,300.88,534.29,300.97z"
              />
              <path
                d="M354.77,519.03c-0.04,0.09-0.08,0.17-0.13,0.25c-1.07,0-2.15,0-3.22,0c-0.13-0.14-0.26-0.29-0.39-0.43
	c-0.13-0.99-0.25-1.97-0.38-2.96c0.12-0.14,0.24-0.28,0.36-0.41c1.34-0.93,2.66-0.61,3.96,0.1l-0.08-0.08
	C354.85,516.68,354.81,517.86,354.77,519.03z"
              />
              <path
                d="M390.83,512.32c-0.09-0.05-0.19-0.1-0.29-0.14c-1.11-0.9-1.05-2.08-0.78-3.31c0,0-0.06,0.07-0.06,0.07
	c1.19-1.24,2.57-1.26,4.05-0.64l-0.07-0.07c0.43,1.12,0.79,2.24,0.3,3.43c-0.09,0.11-0.17,0.23-0.24,0.36
	C392.77,512.11,391.8,512.21,390.83,512.32z"
              />
              <path
                d="M331.79,332.66c-0.16,0.19-0.32,0.37-0.49,0.56c-1.08,0.78-2.22,0.65-3.38,0.22c-0.07-0.09-0.15-0.18-0.24-0.26
	c-0.11-0.29-0.26-0.58-0.33-0.88c-0.4-1.68,0.37-2.94,1.88-3.13c1.46-0.18,2.48,0.7,2.63,2.32
	C331.9,331.87,331.82,332.27,331.79,332.66z"
              />
              <path
                d="M369.02,514.48c-0.23-0.27-0.46-0.55-0.69-0.82c-0.1-0.47-0.27-0.94-0.28-1.4c-0.01-1.43,1.09-2.52,2.38-2.45
	c1.3,0.08,2.3,1.29,2.12,2.68c-0.07,0.54-0.33,1.06-0.5,1.59c0,0,0.09-0.08,0.09-0.08C371.12,514.15,370.07,514.32,369.02,514.48z"
              />
              <path
                d="M342.44,332.18c-1.1-0.86-1.13-2.06-1.09-3.3l-0.07,0.08c0.3-0.25,0.59-0.52,0.91-0.75c0.93-0.69,1.95-0.73,2.86-0.1
	c0.91,0.63,1.28,1.57,0.89,2.7c-0.1,0.3-0.16,0.61-0.24,0.91l0.08-0.08c-1.08,0.61-2.19,1-3.43,0.49L342.44,332.18z"
              />
              <path
                d="M350.36,381.66c1.25-0.64,2.49-0.33,3.73,0.06l-0.08-0.08c0.37,1.31,0.48,2.61-0.23,3.86l0.09-0.08
	c-0.3,0.07-0.59,0.14-0.89,0.21c-2.08,0.49-3.27-0.54-3.08-2.69c0.03-0.31,0.11-0.61,0.17-0.91
	C350.16,381.9,350.25,381.77,350.36,381.66z"
              />
              <path
                d="M351.03,518.85c0.13,0.14,0.26,0.29,0.39,0.43c0.43,1.14,0.45,2.28,0.03,3.42l0.09-0.1c-0.35,0.16-0.7,0.37-1.07,0.48
	c-1.98,0.62-3.19-0.25-3.33-2.37c-0.02-0.31,0.02-0.63,0.03-0.95c0.17-0.18,0.34-0.37,0.51-0.55
	C348.8,519.1,349.91,518.98,351.03,518.85z"
              />
              <path
                d="M379.94,520.68c0.11-0.14,0.23-0.28,0.34-0.43c0.28-0.14,0.55-0.33,0.85-0.4c2.26-0.54,3.45,0.58,3.13,2.92
	c-0.04,0.31-0.11,0.62-0.16,0.93c-0.03,0.08-0.07,0.16-0.11,0.24c-0.23,0.03-0.47,0.06-0.7,0.09
	C380.4,524.47,379.65,523.72,379.94,520.68z"
              />
              <path
                d="M535.68,275.51c0.18,0.15,0.36,0.3,0.53,0.44c-0.01,1-0.01,1.99-0.02,2.99c0,0,0.07-0.11,0.07-0.11
	c-1.2,1.2-2.55,1.06-3.96,0.43l0.08,0.08c0-0.08,0.02-0.17-0.01-0.23c-0.46-1.04-0.8-2.08,0.06-3.09
	C533.37,274.95,534.5,275.06,535.68,275.51z"
              />
              <path
                d="M320.29,37.58c-0.13,0.14-0.26,0.28-0.39,0.42c-1.16,0.63-2.31,0.4-3.47,0c0,0,0.05,0.04,0.05,0.04
	c-0.14-0.44-0.34-0.88-0.42-1.33c-0.33-1.91,0.95-3.11,2.9-2.76c0.3,0.05,0.59,0.18,0.89,0.28c0,0-0.08-0.09-0.08-0.09
	C320.55,35.2,320.71,36.34,320.29,37.58z"
              />
              <path
                d="M393.74,512.01c0.07-0.12,0.15-0.24,0.24-0.36c1.2-0.54,2.41-0.4,3.61,0l-0.08-0.09c0,0.08-0.02,0.17,0,0.24
	c1.05,3.02,0.27,4.1-2.9,4c-0.16-0.12-0.32-0.24-0.49-0.37C393.35,514.37,393.35,513.21,393.74,512.01z"
              />
              <path
                d="M278.58,391.7c-0.68-1.36-0.62-2.69,0.12-4l-0.08,0.07c0.45-0.11,0.89-0.25,1.34-0.32c1.04-0.15,1.85,0.28,2.39,1.15
	c0.5,0.81,0.46,1.66-0.08,2.44c-0.59,0.84-1.42,1.22-2.45,0.99c-0.45-0.1-0.89-0.28-1.33-0.42
	C278.49,391.62,278.58,391.7,278.58,391.7z"
              />
              <path
                d="M412.12,391.08c0,0-0.15-0.1-0.15-0.1c0-1.23,0.01-2.45,0.01-3.68l-0.07,0.08c1.12-0.17,2.24-0.35,3.37-0.52
	c0.18,0.17,0.35,0.35,0.53,0.52c0.91,2.7,0.13,4.03-2.3,3.88C413.05,391.23,412.59,391.14,412.12,391.08z"
              />
              <path
                d="M415.81,387.38c-0.18-0.17-0.35-0.35-0.53-0.52c-0.14-0.96-0.28-1.92-0.43-2.88c0.3-0.24,0.61-0.49,0.91-0.73
	c0.98-0.03,1.96-0.06,2.94-0.09c0.1,0.11,0.2,0.21,0.31,0.31c0.12,0.75,0.43,1.53,0.32,2.25
	C419.13,387.14,418.13,387.55,415.81,387.38z"
              />
              <path
                d="M394.13,515.44c0.16,0.12,0.32,0.24,0.49,0.37c0.04,1,0.08,2,0.11,2.99l0.08-0.08c-1.21,1.19-2.58,1.12-4.03,0.54l0.1,0.08
	c-0.66-1.31-0.59-2.62,0-3.93l-0.08,0.08C391.91,515.27,393.01,514.78,394.13,515.44z"
              />
              <path
                d="M347.69,519.23c-0.17,0.18-0.34,0.37-0.51,0.55c-1.17,0.71-2.32,0.29-3.47-0.05c0,0,0.09,0.08,0.09,0.08
	c-0.59-1.33-0.52-2.65,0.1-3.95c0,0-0.09,0.09-0.09,0.09c0.08,0.01,0.16,0.04,0.23,0.02C347.1,515.17,348.15,516.1,347.69,519.23z"
              />
              <path
                d="M357.2,337.92c0.65,1.31,0.66,2.62-0.05,3.91c0,0,0.08-0.08,0.08-0.08c-0.38,0.11-0.75,0.25-1.13,0.31
	c-1.76,0.32-2.93-0.66-2.91-2.48c0-0.39,0.14-0.78,0.22-1.17c0.13-0.15,0.26-0.3,0.38-0.45c1.17-0.59,2.33-0.35,3.49,0.04
	L357.2,337.92z"
              />
              <path
                d="M340.41,519.81c1.13-0.24,2.26-0.68,3.39,0c0,0-0.09-0.08-0.09-0.08c0.25,0.48,0.6,0.94,0.73,1.45
	c0.4,1.6-0.82,2.98-2.47,2.83c-0.53-0.05-1.05-0.27-1.58-0.41l0.1,0.06c-0.64-1.31-0.57-2.62,0.01-3.93L340.41,519.81z"
              />
              <path
                d="M390.05,504.96c0.27,1.36,0.52,2.71-0.34,3.98c0,0,0.06-0.07,0.06-0.07c-0.38,0.08-0.75,0.17-1.12,0.22
	c-1.77,0.23-2.87-0.75-2.81-2.55c0.01-0.39,0.13-0.77,0.2-1.16c0,0-0.08,0.08-0.08,0.08c1.29-1.1,2.71-0.99,4.17-0.43L390.05,504.96
	z"
              />
              <path
                d="M335.57,383.64c0.4-0.25,0.77-0.57,1.2-0.75c1.76-0.71,3.43,0.57,3.17,2.46c-0.08,0.61-0.39,1.2-0.59,1.79
	c0-0.01,0.07-0.09,0.07-0.09c-0.07-0.03-0.15-0.09-0.22-0.08c-3.14,0.57-4.07-0.33-3.55-3.42
	C335.65,383.56,335.57,383.64,335.57,383.64z"
              />
              <path
                d="M383.02,180.51c0,0-0.2-0.1-0.2-0.1c-0.01-1.27-0.03-2.53-0.04-3.8l-0.09,0.09c0.22-0.05,0.45-0.08,0.66-0.16
	c2.52-0.88,3.68-0.03,3.6,2.63c-0.01,0.23-0.03,0.46-0.05,0.7c-0.07,0.05-0.14,0.1-0.22,0.14
	C385.54,180.8,384.27,180.62,383.02,180.51z"
              />
              <path
                d="M517.03,226.56c-0.65-1.36-0.48-2.69,0.17-4c0,0-0.05,0.07-0.05,0.06c0.29-0.02,0.59,0,0.87-0.05
	c2.06-0.36,3.12,0.55,3.03,2.62c-0.01,0.31-0.08,0.62-0.12,0.92l0.08-0.08c-1.24,1.15-2.61,1.16-4.05,0.45L517.03,226.56z"
              />
              <path
                d="M539.67,68.92c0,0-0.05,0.09-0.05,0.09c-0.99,1.15-2.28,0.75-3.5,0.66c-0.12-0.07-0.23-0.14-0.34-0.22
	c0-1.22,0.01-2.43,0.01-3.65c0,0-0.09,0.07-0.09,0.07c0.23-0.04,0.46-0.05,0.68-0.11C539.12,65,540.23,66.07,539.67,68.92z"
              />
              <path
                d="M397.51,9.38c-0.1,0.11-0.21,0.22-0.33,0.31c-1,0.11-2.01,0.23-3.01,0.34c-0.28-0.26-0.57-0.51-0.85-0.77
	c-0.09-0.31-0.23-0.61-0.26-0.92c-0.13-1.48,0.7-2.59,2.01-2.74c1.22-0.14,2.35,0.93,2.45,2.35C397.56,8.43,397.52,8.9,397.51,9.38z
	"
              />
              <path
                d="M663.37,535.61c-0.15-0.12-0.29-0.25-0.44-0.37c-0.34-1.17-0.35-2.34,0.01-3.51c0,0-0.09,0.08-0.09,0.08
	c1.14-0.14,2.28-0.28,3.42-0.42l-0.07-0.07c0.15,0.28,0.31,0.55,0.44,0.84c0.97,2.12,0.18,3.48-2.08,3.56
	C664.17,535.74,663.77,535.65,663.37,535.61z"
              />
              <path
                d="M339.53,54.23c0.07-0.12,0.14-0.23,0.22-0.34c1.04-0.18,2.09-0.67,3.13,0c0,0-0.08-0.04-0.08-0.04
	c1.14,1.52,1.28,2.35,0.52,3.31c-0.97,1.22-2.15,1.02-3.38,0.47l0.08,0.08C339.17,56.65,339.22,55.46,339.53,54.23z"
              />
              <path
                d="M487.27,463.72c-0.1,0.12-0.21,0.24-0.33,0.34c-0.38,0.12-0.75,0.26-1.13,0.36c-1.06,0.25-2-0.11-2.42-1.05
	c-0.28-0.64-0.3-1.57-0.06-2.23c0.37-0.97,1.3-1.35,2.37-1.15c0.39,0.07,0.77,0.21,1.15,0.32c0,0-0.08-0.08-0.08-0.08
	C487.54,461.31,487.61,462.49,487.27,463.72z"
              />
              <path
                d="M372.58,184.83c-0.08-0.29-0.15-0.59-0.23-0.88c-0.59-2.31,0.67-3.59,3.03-3.06c0.23,0.05,0.45,0.12,0.68,0.19l-0.08-0.07
	c0.64,1.03,0.87,2.12,0.49,3.3c0,0,0.08-0.09,0.08-0.09c-1.21,1.28-2.6,1.17-4.06,0.54C372.49,184.74,372.58,184.83,372.58,184.83z"
              />
              <path
                d="M401.75,395.46c0.19,0.14,0.37,0.27,0.56,0.41c0.09,0.46,0.25,0.92,0.26,1.39c0.03,1.75-1.21,2.71-2.96,2.34
	c-0.38-0.08-0.75-0.2-1.12-0.31l0.08,0.09c-0.14-0.44-0.34-0.87-0.42-1.32C397.72,395.9,399.09,394.92,401.75,395.46z"
              />
              <path
                d="M343.32,386.05c-1.06-1.08-0.58-2.35-0.44-3.58l-0.08,0.09c0.52-0.17,1.02-0.39,1.55-0.5c1.01-0.21,2.01-0.01,2.44,0.98
	c0.28,0.65,0.33,1.56,0.11,2.23c-0.33,1.01-1.29,1.35-2.33,1.19c-0.46-0.07-0.88-0.31-1.33-0.48
	C343.23,385.98,343.32,386.05,343.32,386.05z"
              />
              <path
                d="M623.36,621.52c0.07,0.14,0.13,0.28,0.18,0.43c0.28,0.38,0.65,0.72,0.81,1.15c0.39,0.98,0.2,1.99-0.69,2.53
	c-0.6,0.36-1.49,0.51-2.18,0.37c-1.04-0.21-1.49-1.11-1.47-2.16c0.01-0.47,0.15-0.93,0.23-1.4c0,0-0.08,0.08-0.08,0.08
	C621.23,622.19,622.29,621.85,623.36,621.52z"
              />
              <path
                d="M505.24,422.44c0.09,0.06,0.17,0.12,0.25,0.18c0.16,1.08,0.32,2.17,0.48,3.25c-0.28,0.26-0.56,0.52-0.84,0.78
	c-0.54,0.04-1.09,0.16-1.63,0.11c-1.22-0.11-2.03-1-2.06-2.15c-0.03-1.22,0.86-2.2,2.17-2.29
	C504.15,422.29,504.7,422.4,505.24,422.44z"
              />
              <path
                d="M387.18,187.66c0.1-0.13,0.2-0.25,0.3-0.38c1.3-0.89,2.6-0.63,3.9,0c0,0-0.05-0.07-0.05-0.07
	c-0.03,1.18-0.06,2.36-0.09,3.54l0.08-0.08c-0.15,0.02-0.31,0.01-0.46,0.06C387.9,191.74,386.81,190.83,387.18,187.66z"
              />
              <path
                d="M291.44,333.24c0.41-0.24,0.79-0.53,1.22-0.73c1.11-0.52,2.02-0.14,2.73,0.76c0.6,0.77,0.62,1.63,0.11,2.45
	c-0.55,0.89-1.38,1.35-2.43,1.08c-1.13-0.29-1.75-1.07-1.77-2.25c-0.01-0.47,0.14-0.94,0.22-1.41L291.44,333.24z"
              />
              <path
                d="M380.78,404.63c0.14,0.11,0.27,0.22,0.41,0.33c0.09,0.47,0.24,0.94,0.25,1.41c0.02,1.78-1.22,2.73-3,2.32
	c-0.38-0.09-0.75-0.22-1.13-0.33l0.08,0.09c-0.11-0.37-0.24-0.74-0.32-1.11c-0.42-1.99,0.74-3.21,2.79-2.93
	C380.17,404.44,380.47,404.55,380.78,404.63z"
              />
              <path
                d="M407.15,373.43c0.15,0.07,0.31,0.14,0.46,0.22c0,1.07,0,2.15,0,3.22c-0.09,0.13-0.2,0.25-0.31,0.36
	c-1.19,0.58-2.37,0.33-3.56,0l0.09,0.08c0-0.08,0.04-0.17,0.01-0.23c-0.49-1.09-0.86-2.18,0.09-3.22
	C404.88,372.83,406.01,373.07,407.15,373.43z"
              />
              <path
                d="M324.55,380.67c0.34-0.18,0.68-0.37,1.02-0.56c0.94-0.52,1.84-0.39,2.6,0.3c0.77,0.69,0.95,1.59,0.57,2.57
	c-0.14,0.36-0.26,0.72-0.39,1.09c0,0,0.09-0.09,0.09-0.09c-0.3,0.06-0.61,0.12-0.91,0.18c-2.08,0.46-3.17-0.49-3.04-2.64
	c0.02-0.32,0.09-0.63,0.14-0.94L324.55,380.67z"
              />
              <path
                d="M335.65,383.56c-0.45,0.1-0.89,0.23-1.34,0.29c-1.51,0.19-2.7-0.82-2.69-2.25c0.01-1.23,1.05-2.28,2.26-2.27
	c1.39,0.01,2.44,1.29,2.19,2.76c-0.09,0.53-0.32,1.04-0.49,1.56C335.57,383.64,335.65,383.56,335.65,383.56z"
              />
              <path
                d="M445.55,113.39c0.17,0.15,0.33,0.31,0.5,0.46c0.14,0.97,0.28,1.94,0.41,2.91c0,0,0.09-0.11,0.09-0.11
	c-1.18,1.08-2.46,1.57-3.97,0.65c0,0,0.09,0.08,0.09,0.08c-1.06-1.08-0.63-2.36-0.46-3.6c0,0-0.08,0.1-0.08,0.1
	C443.27,113.71,444.41,113.55,445.55,113.39z"
              />
              <path
                d="M291.52,333.16c-0.45,0.1-0.89,0.25-1.35,0.31c-1.5,0.2-2.71-0.81-2.72-2.24c0-1.23,1.04-2.29,2.25-2.3
	c1.39,0,2.46,1.29,2.22,2.75c-0.09,0.53-0.32,1.04-0.49,1.56C291.44,333.24,291.52,333.16,291.52,333.16z"
              />
              <path
                d="M390.78,519.26c0.21,0.43,0.5,0.83,0.61,1.28c0.49,1.92-0.85,3.25-2.84,2.84c-0.38-0.08-0.76-0.19-1.14-0.29
	c0,0,0.1,0.09,0.1,0.09c-0.14-0.44-0.32-0.87-0.41-1.31c-0.36-1.8,0.82-3.06,2.65-2.84c0.38,0.05,0.75,0.2,1.12,0.31L390.78,519.26z
	"
              />
              <path
                d="M379.82,188.21c0.16-0.18,0.32-0.36,0.49-0.54c1.31-0.74,2.59-0.53,3.87,0.1c0,0-0.05-0.07-0.05-0.07
	c-0.03,1.14-0.05,2.28-0.08,3.42c0,0,0.09-0.09,0.09-0.09c-0.28,0.12-0.56,0.25-0.85,0.35c-2.11,0.73-3.32-0.05-3.48-2.24
	C379.79,188.83,379.82,188.52,379.82,188.21z"
              />
              <path
                d="M336.66,520.3c-0.15-0.44-0.32-0.88-0.43-1.33c-0.22-0.91-0.04-1.76,0.72-2.34c0.73-0.56,1.54-0.77,2.43-0.3
	c0.96,0.5,1.35,1.28,1.26,2.33c-0.04,0.39-0.15,0.77-0.23,1.16c0,0,0.08-0.08,0.08-0.08c-1.19,1.13-2.54,0.97-3.93,0.5L336.66,520.3
	z"
              />
              <path
                d="M353.79,337.97c-0.13,0.15-0.26,0.3-0.38,0.45c-1.34,0.88-2.63,0.63-3.91-0.14l0.07,0.08c0.01-1.11,0.02-2.22,0.03-3.33
	c0,0-0.04,0.05-0.04,0.05c0.36-0.14,0.71-0.31,1.08-0.41c2.02-0.52,3.14,0.31,3.24,2.37C353.89,337.35,353.82,337.66,353.79,337.97z
	"
              />
              <path
                d="M404.2,503.78c0.11,0.3,0.22,0.6,0.34,0.89c0.4,0.99,0.26,1.89-0.52,2.63c-0.8,0.75-1.73,0.7-2.68,0.33
	c-0.37-0.14-0.72-0.31-1.09-0.47c0,0,0.06,0.05,0.06,0.05c0.02-0.22,0.09-0.44,0.07-0.66c-0.27-2.41,0.41-3.15,2.83-3.08
	c0.24,0.01,0.47,0.03,0.71,0.05C404.02,503.6,404.12,503.69,404.2,503.78z"
              />
              <path
                d="M536.21,358.35c0,0-0.17,0.07-0.17,0.07c-0.51,0.22-1,0.54-1.53,0.65c-1.46,0.3-2.63-0.51-2.82-1.86
	c-0.19-1.37,0.69-2.42,2.19-2.54c0.47-0.03,0.95,0.09,1.42,0.15c0.13,0.12,0.26,0.24,0.4,0.37
	C535.88,356.24,536.05,357.29,536.21,358.35z"
              />
              <path
                d="M376.63,65.37l0.02,0.02c1.22-0.36,2.44-1.06,3.65,0.01l-0.06-0.05c1.09,1,0.62,2.23,0.49,3.41c0,0,0.06-0.04,0.06-0.04
	c-0.15,0.03-0.31,0.05-0.45,0.1c-2.93,1-4.04,0.12-3.77-3C376.59,65.68,376.62,65.53,376.63,65.37z"
              />
              <path
                d="M394.26,187.1c0.18-0.13,0.36-0.27,0.54-0.4c0.99-1.08,2.16-0.62,3.3-0.39c0.12,0.08,0.23,0.16,0.34,0.26
	c0.08,0.54,0.22,1.08,0.22,1.62c0,1.25-0.79,2.14-1.92,2.29c-1.06,0.14-2.17-0.61-2.44-1.75
	C394.19,188.21,394.27,187.64,394.26,187.1z"
              />
              <path
                d="M376.74,524.45c0.06-0.07,0.1-0.15,0.14-0.23c0.43-0.2,0.84-0.5,1.29-0.58c1.48-0.28,2.6,0.49,2.79,1.85
	c0.19,1.36-0.69,2.41-2.18,2.53c-0.47,0.04-0.95-0.06-1.42-0.1c-0.14-0.14-0.28-0.27-0.42-0.41
	C376.45,526.52,376.26,525.51,376.74,524.45z"
              />
              <path
                d="M384.16,68.26c0.13,0.08,0.25,0.18,0.37,0.28c0.07,0.38,0.15,0.77,0.2,1.15c0.15,1.01-0.1,1.86-1.02,2.42
	c-0.83,0.5-1.64,0.46-2.42-0.09c-0.95-0.68-1.19-1.61-0.84-2.69c0.07-0.22,0.23-0.4,0.36-0.61c0,0-0.06,0.04-0.06,0.04
	C381.75,67.7,382.93,67.85,384.16,68.26z"
              />
              <path
                d="M382.97,509.21C382.97,509.21,382.96,509.2,382.97,509.21c-1.4,0.76-2.79,0.72-4.18-0.02c0,0,0.06,0.08,0.06,0.08
	c-0.06-0.46-0.16-0.92-0.18-1.38c-0.08-1.53,0.78-2.52,2.18-2.54c1.3-0.02,2.21,0.83,2.29,2.21
	C383.16,508.1,383.02,508.65,382.97,509.21z"
              />
              <path
                d="M526.99,387.71c-0.03-0.61-0.13-1.22-0.06-1.81c0.14-1.3,1.2-2.09,2.5-1.95c1.25,0.13,2.13,1.23,1.99,2.48
	c-0.14,1.25-1.19,2.13-2.5,1.94c-0.68-0.1-1.32-0.46-1.98-0.71C526.93,387.66,526.99,387.71,526.99,387.71z"
              />
              <path
                d="M348.07,527.14c-0.53,0.07-1.05,0.18-1.58,0.2c-1.4,0.04-2.44-1.01-2.39-2.36c0.05-1.24,1.13-2.25,2.34-2.18
	c1.31,0.07,2.31,1.28,2.1,2.63c-0.09,0.61-0.36,1.2-0.55,1.8C348.01,527.23,348.07,527.14,348.07,527.14z"
              />
              <path
                d="M535.78,361.99c0.18,0.15,0.36,0.29,0.55,0.44c0.93,1.04,0.4,2.23,0.35,3.38c0,0,0.09-0.08,0.09-0.08
	c-0.51,0.18-1.01,0.42-1.54,0.52c-1.45,0.29-2.61-0.49-2.81-1.83c-0.19-1.3,0.71-2.37,2.18-2.53
	C534.99,361.84,535.39,361.95,535.78,361.99z"
              />
              <path
                d="M489.17,532.83c1.2-0.87,2.4-1.77,3.88-0.52c0,0-0.04-0.07-0.04-0.07c1.09,1,0.61,2.23,0.5,3.41c0,0,0.05-0.06,0.05-0.06
	c-0.3,0.11-0.59,0.24-0.9,0.33c-2.25,0.66-3.32-0.12-3.41-2.46c-0.01-0.24,0-0.48,0-0.71C489.25,532.74,489.17,532.83,489.17,532.83
	z"
              />
              <path
                d="M290.44,242.36c-0.03-0.61-0.12-1.22-0.06-1.83c0.12-1.32,1.13-2.1,2.45-1.97c1.25,0.12,2.14,1.22,2.01,2.46
	c-0.13,1.24-1.2,2.13-2.49,1.94c-0.68-0.1-1.32-0.44-1.98-0.67L290.44,242.36z"
              />
              <path
                d="M419.02,383.46c-0.11-0.09-0.22-0.2-0.31-0.31c-0.11-1.01-0.21-2.03-0.32-3.04c0.09-0.14,0.18-0.28,0.27-0.42
	c0.52-0.18,1.02-0.46,1.55-0.52c1.34-0.14,2.35,0.7,2.47,1.96c0.13,1.28-0.68,2.29-2.02,2.42
	C420.13,383.61,419.57,383.5,419.02,383.46z"
              />
              <path
                d="M358.51,525.38c0.83,1.09,1.54,2.2,0.85,3.64c-0.06,0.08-0.11,0.16-0.15,0.25c-0.13,0.08-0.27,0.16-0.4,0.24
	c-1.05,0.64-2.2,0.76-2.98-0.21c-0.46-0.57-0.66-1.6-0.51-2.34c0.25-1.29,1.37-1.56,2.58-1.45c0.23,0.02,0.46-0.03,0.69-0.05
	C358.58,525.45,358.51,525.38,358.51,525.38z"
              />
              <path
                d="M353.96,56.41c0.1,0.11,0.21,0.2,0.33,0.29c0.13,0.28,0.29,0.56,0.37,0.85c0.54,2.17-0.87,3.56-2.98,2.94
	c-0.44-0.13-0.84-0.36-1.26-0.54c0,0,0.07,0.07,0.07,0.07c0.02-0.07,0.08-0.15,0.07-0.22C350.16,56.68,350.85,56,353.96,56.41z"
              />
              <path
                d="M523.97,222.67c0.09-0.1,0.18-0.21,0.25-0.33c0.93-0.54,1.88-0.87,2.92-0.33c0.14,0.09,0.29,0.18,0.43,0.27
	c1.12,1,0.56,2.24,0.47,3.41c0,0,0.08-0.08,0.08-0.08c-0.15,0.03-0.32,0.03-0.46,0.08C524.72,226.8,523.56,225.85,523.97,222.67z"
              />
              <path
                d="M544.86,379.68c-0.53,0.12-1.06,0.31-1.6,0.35c-1.39,0.09-2.47-0.99-2.41-2.35c0.05-1.25,1.16-2.26,2.37-2.17
	c1.32,0.1,2.29,1.33,2.06,2.7c-0.09,0.54-0.33,1.05-0.51,1.57L544.86,379.68z"
              />
              <path
                d="M330.02,521.3c-0.33-0.54-0.77-1.04-0.96-1.63c-0.4-1.22,0.4-2.48,1.63-2.76c1.28-0.29,2.45,0.45,2.71,1.71
	c0.26,1.29-0.47,2.41-1.79,2.63c-0.54,0.09-1.11-0.02-1.67-0.04L330.02,521.3z"
              />
              <path
                d="M255.51,249.2c-0.11,0.13-0.22,0.25-0.33,0.38c-0.15,0.03-0.33,0.02-0.46,0.08c-1.03,0.5-2,0.46-2.82-0.38
	c-0.9-0.93-0.85-1.97-0.17-3.01c0,0-0.07,0.04-0.07,0.04c1.02-1.05,2.21-0.87,3.43-0.48l-0.09-0.08
	C255.69,246.82,255.8,247.98,255.51,249.2z"
              />
              <path
                d="M467.82,153.43c0.01,1.29-0.98,2.25-2.29,2.23c-1.19-0.02-2.13-0.9-2.2-2.08c-0.08-1.27,0.89-2.32,2.17-2.36
	C466.78,151.18,467.81,152.16,467.82,153.43z"
              />
              <path
                d="M325.2,55.73c0.12-0.13,0.24-0.26,0.36-0.39c0.97-0.17,1.95-0.35,2.92-0.52c0.17,0.19,0.35,0.39,0.52,0.58
	c0.19,0.51,0.5,1.01,0.53,1.53c0.07,1.27-0.75,2.14-1.95,2.25c-1.2,0.11-2.17-0.58-2.4-1.8C325.09,56.85,325.2,56.28,325.2,55.73z"
              />
              <path
                d="M342.88,382.47c-0.59,0.21-1.17,0.56-1.77,0.61c-1.3,0.1-2.3-0.92-2.3-2.19c0-1.17,0.89-2.13,2.07-2.21
	c1.29-0.1,2.4,0.8,2.4,2.08c0,0.6-0.32,1.2-0.49,1.81C342.79,382.56,342.88,382.47,342.88,382.47z"
              />
              <path
                d="M528.19,329.92c1.22-0.01,2.28,1.07,2.25,2.3c-0.02,1.23-1.12,2.27-2.34,2.22c-1.15-0.05-2.07-1-2.11-2.18
	C525.95,330.99,526.94,329.93,528.19,329.92z"
              />
              <path
                d="M359.21,529.26c0.04-0.09,0.09-0.17,0.15-0.25c0.53-0.13,1.06-0.33,1.6-0.37c1.3-0.1,2.36,0.79,2.47,2.01
	c0.11,1.18-0.9,2.34-2.1,2.43c-1.22,0.08-2.23-0.84-2.3-2.17C359.02,530.36,359.15,529.81,359.21,529.26z"
              />
              <path
                d="M227.45,219.46c0.09-0.01,0.17-0.04,0.25-0.06c0.19-0.14,0.36-0.32,0.57-0.43c1.02-0.53,2.01-0.49,2.83,0.37
	c0.75,0.78,0.82,1.76,0.29,2.64c-0.58,0.98-1.5,1.38-2.65,0.98c-0.15-0.05-0.29-0.12-0.44-0.18c0,0,0.07,0.05,0.08,0.05
	C227.07,221.99,227.15,220.76,227.45,219.46z"
              />
              <path
                d="M396.17,500.73c0.51-0.19,1.01-0.44,1.55-0.57c1.45-0.34,2.62,0.39,2.85,1.73c0.24,1.37-0.59,2.42-2.07,2.58
	c-0.46,0.05-0.94-0.03-1.41-0.04c0,0,0.09,0.09,0.1,0.09c-1.02-1.12-1.15-2.46-0.92-3.88C396.26,500.64,396.17,500.73,396.17,500.73
	z"
              />
              <path
                d="M340.39,523.6c0.27,0.38,0.6,0.74,0.79,1.16c0.4,0.89,0.17,1.71-0.52,2.36c-0.67,0.64-1.47,0.85-2.38,0.45
	c-0.9-0.4-1.31-1.1-1.28-2.04c0.01-0.55,0.11-1.09,0.17-1.63c0.04-0.08,0.09-0.17,0.14-0.25c1.06,0.01,2.12,0.01,3.18,0.02
	C340.49,523.66,340.39,523.6,340.39,523.6z"
              />
              <path
                d="M255.1,123.41c0.28-0.3,0.57-0.61,0.85-0.91c0.54-0.09,1.09-0.29,1.62-0.24c1.13,0.1,1.85,0.94,1.9,2.04
	c0.05,1.12-0.6,1.99-1.67,2.25c-1.01,0.25-2.11-0.26-2.49-1.29C255.11,124.69,255.17,124.03,255.1,123.41z"
              />
              <path
                d="M365.41,59.14c0.13,0.06,0.25,0.13,0.36,0.21c0.08,0.63,0.23,1.25,0.21,1.88c-0.05,1.22-0.98,2.09-2.14,2.09
	c-1,0-1.99-0.84-2.19-1.85c-0.21-1.11,0.5-2.29,1.66-2.49C363.99,58.87,364.71,59.07,365.41,59.14z"
              />
              <path
                d="M388.17,71.69c0,0,0.12,0.04,0.12,0.04c0.08,0.63,0.24,1.25,0.24,1.88c-0.02,1.25-0.9,2.12-2.06,2.16
	c-1.2,0.04-2.3-1.05-2.28-2.25c0.02-1.12,0.95-2.11,2.12-2.13C386.93,71.37,387.55,71.58,388.17,71.69z"
              />
              <path
                d="M404.26,380.67c-0.13,0.14-0.26,0.27-0.39,0.41c-0.38,0.11-0.75,0.23-1.13,0.31c-1.05,0.22-1.98-0.11-2.37-1.06
	c-0.26-0.64-0.28-1.55-0.02-2.19c0.38-0.95,1.3-1.31,2.35-1.11c0.38,0.08,0.76,0.19,1.14,0.29c0,0-0.09-0.08-0.09-0.08
	C404.41,378.3,404.59,379.45,404.26,380.67z"
              />
              <path
                d="M476.49,176.12c-0.45,0.12-0.9,0.3-1.36,0.35c-1.4,0.18-2.57-0.74-2.65-2.03c-0.08-1.28,1-2.33,2.41-2.3
	c0.46,0.01,0.92,0.16,1.38,0.24c0,0-0.09-0.08-0.09-0.09c0.71,1.11,0.76,2.31,0.51,3.55C476.63,175.95,476.56,176.03,476.49,176.12z
	"
              />
              <path
                d="M374.49,407.03c0.01,1.22-1.08,2.3-2.29,2.28c-1.14-0.02-2.1-0.96-2.16-2.13c-0.07-1.27,0.89-2.35,2.14-2.39
	C373.4,404.74,374.48,405.79,374.49,407.03z"
              />
              <path
                d="M342.38,336.01c0.17-0.17,0.34-0.33,0.51-0.5c0.96-0.11,1.92-0.22,2.88-0.33c0.13,0.11,0.27,0.22,0.4,0.33
	c0.14,0.37,0.34,0.73,0.41,1.12c0.26,1.51-0.5,2.62-1.86,2.79c-1.4,0.17-2.38-0.68-2.5-2.22
	C342.18,336.8,342.32,336.4,342.38,336.01z"
              />
              <path
                d="M351.01,515.48c-0.12,0.14-0.24,0.28-0.36,0.41c-0.54,0.13-1.08,0.36-1.62,0.38c-1.29,0.03-2.31-1.03-2.29-2.27
	c0.02-1.13,1-2.14,2.13-2.2c1.16-0.06,2.13,0.76,2.26,2.01C351.19,514.36,351.05,514.93,351.01,515.48z"
              />
              <path
                d="M353.79,385.51c0.49-0.23,0.96-0.53,1.48-0.68c1.35-0.41,2.62,0.38,2.86,1.7c0.21,1.18-0.69,2.39-1.91,2.56
	c-1.24,0.17-2.29-0.68-2.41-2.02c-0.05-0.54,0.05-1.09,0.08-1.64C353.87,385.42,353.79,385.51,353.79,385.51z"
              />
              <path
                d="M376.31,188.09c0.25,0.58,0.6,1.14,0.71,1.74c0.23,1.29-0.65,2.4-1.9,2.56c-1.08,0.13-2.22-0.71-2.44-1.82
	c-0.24-1.2,0.59-2.44,1.86-2.59c0.6-0.07,1.24,0.13,1.85,0.2C376.4,188.18,376.31,188.09,376.31,188.09z"
              />
              <path
                d="M343.32,386.05c0,0-0.08-0.08-0.08-0.08c0.13,0.45,0.29,0.9,0.4,1.36c0.33,1.48-0.34,2.65-1.63,2.91
	c-1.44,0.29-2.47-0.58-2.64-2.26c-0.03-0.31,0.04-0.63,0.07-0.94c0,0.01-0.07,0.09-0.07,0.09
	C340.34,385.57,341.85,385.88,343.32,386.05z"
              />
              <path
                d="M468.07,162.37c0.49-0.25,0.96-0.58,1.48-0.73c1.23-0.36,2.46,0.37,2.77,1.58c0.33,1.26-0.62,2.62-1.94,2.78
	c-1.23,0.15-2.32-0.73-2.41-2.07c-0.04-0.54,0.12-1.1,0.19-1.65L468.07,162.37z"
              />
              <path
                d="M372.07,387.37c0.57-0.25,1.13-0.6,1.73-0.73c1.2-0.27,2.4,0.65,2.58,1.87c0.17,1.19-0.77,2.39-1.99,2.52
	c-1.14,0.13-2.2-0.66-2.34-1.86c-0.07-0.62,0.07-1.25,0.12-1.88L372.07,387.37z"
              />
              <path
                d="M411.6,504.97c-0.02,1.2-0.93,2.15-2.1,2.19c-1.21,0.04-2.32-1.06-2.29-2.27c0.02-1.23,1.14-2.25,2.38-2.17
	C410.77,502.8,411.62,503.76,411.6,504.97z"
              />
              <path
                d="M390.13,505.02c-0.3-0.35-0.71-0.66-0.9-1.07c-0.59-1.31,0.01-2.61,1.32-3.02c1.12-0.35,2.3,0.22,2.68,1.3
	c0.45,1.31-0.22,2.64-1.59,2.91c-0.5,0.1-1.07-0.12-1.6-0.19C390.05,504.96,390.13,505.02,390.13,505.02z"
              />
              <path
                d="M476.49,176.12c0.07-0.08,0.14-0.17,0.2-0.27c0.51-0.2,1.01-0.52,1.54-0.59c1.37-0.19,2.37,0.64,2.5,1.94
	c0.12,1.2-0.64,2.22-1.8,2.42c-1.29,0.21-2.41-0.54-2.6-1.85C476.25,177.23,476.43,176.67,476.49,176.12z"
              />
              <path
                d="M328.36,384.08c0.57-0.27,1.11-0.63,1.71-0.78c1.2-0.31,2.43,0.57,2.64,1.79c0.21,1.2-0.69,2.41-1.92,2.59
	c-1.14,0.17-2.24-0.6-2.41-1.79c-0.09-0.61,0.04-1.26,0.07-1.89C328.45,383.99,328.36,384.08,328.36,384.08z"
              />
              <path
                d="M404.2,503.78c-0.09-0.1-0.18-0.19-0.29-0.27c-0.19-0.52-0.49-1.02-0.54-1.55c-0.11-1.29,0.84-2.31,2.1-2.37
	c1.2-0.06,2.15,0.73,2.3,1.92c0.17,1.32-0.59,2.33-1.94,2.46C405.3,504.03,404.75,503.86,404.2,503.78z"
              />
              <path
                d="M369.29,228.53c-0.22-0.58-0.57-1.15-0.64-1.75c-0.15-1.29,0.83-2.34,2.08-2.39c1.11-0.05,2.16,0.85,2.31,1.97
	c0.15,1.2-0.76,2.41-2,2.48c-0.6,0.03-1.22-0.25-1.83-0.38C369.2,228.45,369.29,228.53,369.29,228.53z"
              />
              <path
                d="M366.25,529.02c0.49-0.26,0.96-0.58,1.48-0.75c1.32-0.43,2.65,0.38,2.89,1.7c0.23,1.21-0.65,2.41-1.89,2.62
	c-1.23,0.2-2.34-0.66-2.48-1.99c-0.06-0.54,0.06-1.1,0.09-1.65C366.33,528.94,366.25,529.02,366.25,529.02z"
              />
              <path
                d="M534.76,344.17c-0.13,0.12-0.27,0.24-0.4,0.35c-0.38,0.12-0.75,0.3-1.13,0.36c-1.44,0.19-2.61-0.69-2.7-2
	c-0.09-1.3,1-2.37,2.43-2.32c0.47,0.02,0.93,0.19,1.39,0.28l-0.09-0.08C534.85,341.84,535.09,342.96,534.76,344.17z"
              />
              <path
                d="M379.04,390.07c0.37,0.5,0.87,0.96,1.07,1.52c0.42,1.18-0.38,2.42-1.62,2.72c-1.01,0.25-2.14-0.33-2.51-1.3
	c-0.46-1.2,0.08-2.53,1.3-2.87c0.57-0.16,1.23-0.01,1.84-0.01C379.12,390.13,379.04,390.07,379.04,390.07z"
              />
              <path
                d="M376.95,527.51c0.14,0.14,0.28,0.27,0.42,0.41c0.16,0.53,0.42,1.05,0.46,1.59c0.09,1.22-0.76,2.17-1.93,2.31
	c-1.31,0.15-2.37-0.7-2.47-2c-0.1-1.21,0.63-2.17,1.86-2.35C375.82,527.39,376.39,527.49,376.95,527.51z"
              />
              <path
                d="M485.53,481.53c-0.61,0.45-1.15,1.09-1.83,1.29c-1.14,0.34-2.23-0.43-2.53-1.55c-0.33-1.18,0.43-2.44,1.63-2.73
	c1.16-0.28,2.26,0.28,2.6,1.45c0.15,0.51,0.06,1.09,0.09,1.62C485.48,481.6,485.53,481.53,485.53,481.53z"
              />
              <path
                d="M350.36,381.66c-0.11,0.11-0.2,0.23-0.29,0.36c-0.52,0.19-1.03,0.48-1.56,0.55c-1.29,0.15-2.22-0.59-2.42-1.78
	c-0.19-1.19,0.52-2.29,1.66-2.54c1.25-0.28,2.43,0.44,2.69,1.75C350.53,380.53,350.39,381.11,350.36,381.66z"
              />
              <path
                d="M376.06,181.07c-0.23-0.59-0.59-1.16-0.67-1.76c-0.17-1.22,0.66-2.22,1.83-2.39c1.19-0.17,2.42,0.8,2.53,2
	c0.1,1.11-0.75,2.25-1.91,2.36c-0.61,0.06-1.24-0.18-1.86-0.29C375.98,181,376.06,181.07,376.06,181.07z"
              />
              <path
                d="M430.03,9.78c-0.18,0.29-0.36,0.58-0.54,0.87c-0.92,0-1.83,0-2.75,0c-0.15-0.18-0.29-0.35-0.44-0.53
	c-0.17-0.43-0.47-0.86-0.49-1.3c-0.08-1.27,0.73-2.21,1.87-2.33c1.11-0.12,2.12,0.65,2.36,1.88C430.12,8.82,430.04,9.31,430.03,9.78
	z"
              />
              <path
                d="M377.53,403.3c-0.05,1.19-0.98,2.12-2.15,2.14c-1.22,0.02-2.29-1.09-2.24-2.32c0.05-1.22,1.21-2.23,2.43-2.12
	C376.75,401.11,377.58,402.08,377.53,403.3z"
              />
              <path
                d="M364.92,221.86c0.5-0.23,0.97-0.55,1.5-0.68c1.26-0.33,2.45,0.43,2.72,1.66c0.26,1.19-0.63,2.42-1.87,2.61
	c-1.25,0.19-2.33-0.67-2.43-2.02c-0.04-0.54,0.11-1.1,0.17-1.65L364.92,221.86z"
              />
              <path
                d="M530.64,337.5l0.17,0.05c0.08,0.63,0.24,1.27,0.23,1.9c-0.02,1.26-0.94,2.14-2.12,2.14c-1.12,0-2.16-1.01-2.21-2.12
	c-0.05-1.12,0.86-2.18,2.05-2.23C529.38,337.21,530.01,337.41,530.64,337.5z"
              />
              <path
                d="M361.52,383.08c-0.02,1.2-1.12,2.26-2.31,2.21c-1.23-0.05-2.17-1.11-2.1-2.38c0.07-1.17,1-2.08,2.15-2.09
	C360.47,380.8,361.54,381.87,361.52,383.08z"
              />
              <path
                d="M364.87,339.31c0.05,1.21-1.05,2.33-2.26,2.31c-1.17-0.02-2.09-0.96-2.13-2.15c-0.04-1.21,0.8-2.18,1.98-2.29
	C363.67,337.07,364.83,338.08,364.87,339.31z"
              />
              <path
                d="M321.71,382.75c0.04,1.25-0.96,2.26-2.23,2.27c-1.21,0.01-2.12-0.83-2.21-2.02c-0.1-1.29,0.81-2.32,2.08-2.39
	C320.62,380.54,321.67,381.5,321.71,382.75z"
              />
              <path
                d="M326.19,519.92c-0.06,1.18-1.01,2.12-2.17,2.12c-1.21,0-2.29-1.14-2.22-2.35c0.06-1.12,1.09-2.1,2.22-2.11
	C325.28,517.57,326.25,518.62,326.19,519.92z"
              />
              <path
                d="M568.38,608.55c0.13,0.15,0.26,0.29,0.38,0.44c0.06,0.38,0.17,0.77,0.18,1.15c0.04,1.54-0.79,2.54-2.09,2.56
	c-1.27,0.02-2.21-1-2.22-2.48c0-0.29,0.18-0.59,0.28-0.88l0.08-0.17C565.95,608.03,567.17,608.3,568.38,608.55z"
              />
              <path
                d="M372.49,184.74c0.24,0.58,0.59,1.14,0.7,1.74c0.22,1.29-0.67,2.4-1.91,2.54c-1.08,0.12-2.22-0.74-2.43-1.84
	c-0.23-1.2,0.62-2.43,1.88-2.58c0.6-0.07,1.24,0.14,1.85,0.22C372.58,184.83,372.49,184.74,372.49,184.74z"
              />
              <path
                d="M553.45,602.76c0.22,0.58,0.56,1.15,0.64,1.75c0.18,1.28-0.75,2.36-1.99,2.45c-1.08,0.09-2.18-0.8-2.36-1.9
	c-0.19-1.2,0.68-2.4,1.94-2.51c0.6-0.05,1.23,0.18,1.85,0.28C553.53,602.83,553.45,602.76,553.45,602.76z"
              />
              <path
                d="M320.27,518.68c-1.18,0.05-2.3-1-2.32-2.2c-0.02-1.21,1.04-2.28,2.25-2.27c1.15,0.01,2.09,0.92,2.16,2.08
	C322.44,517.56,321.51,518.63,320.27,518.68z"
              />
              <path
                d="M417.42,177.14c1.26,0.04,2.24,1.09,2.17,2.33c-0.07,1.26-1.12,2.16-2.41,2.07c-1.19-0.08-2.05-1-2.04-2.2
	C415.14,178.08,416.15,177.11,417.42,177.14z"
              />
              <path
                d="M502.64,560.09c0.13,0.15,0.25,0.29,0.38,0.44c0.07,0.55,0.23,1.1,0.2,1.64c-0.07,1.24-0.98,2.09-2.14,2.09
	c-1.09,0-2.14-0.97-2.22-2.07c-0.09-1.19,0.88-2.3,2.15-2.35C501.56,559.81,502.1,560,502.64,560.09z"
              />
              <path
                d="M327.68,333.18c0.09,0.08,0.17,0.17,0.24,0.26c0.16,0.53,0.4,1.05,0.46,1.59c0.15,1.31-0.76,2.36-2.02,2.45
	c-1.22,0.09-2.19-0.7-2.33-1.89c-0.16-1.3,0.65-2.33,2-2.46C326.56,333.07,327.12,333.16,327.68,333.18z"
              />
              <path
                d="M238.5,171.52c-0.31-0.35-0.74-0.64-0.92-1.05c-0.59-1.35,0-2.63,1.34-3.06c1.21-0.39,2.48,0.32,2.77,1.55
	c0.32,1.35-0.47,2.57-1.9,2.77c-0.44,0.06-0.92-0.17-1.37-0.27C238.42,171.47,238.5,171.52,238.5,171.52z"
              />
              <path
                d="M404.84,370.4c-1.25-0.02-2.21-1.1-2.12-2.38c0.08-1.19,1.04-2.08,2.21-2.05c1.12,0.03,2.13,1.02,2.17,2.14
	C407.14,369.32,406.05,370.42,404.84,370.4z"
              />
              <path
                d="M441.74,110.1c0.13,0.12,0.27,0.25,0.4,0.37c0.6,1.14,0.63,2.27-0.01,3.41c0,0,0.08-0.1,0.08-0.1
	c-0.43,0.19-0.84,0.46-1.29,0.54c-1.48,0.27-2.56-0.5-2.74-1.85c-0.18-1.39,0.65-2.37,2.15-2.49
	C440.8,109.94,441.28,110.05,441.74,110.1z"
              />
              <path
                d="M235.37,182.46c-0.1-0.15-0.21-0.3-0.31-0.46c-0.04-0.99-0.09-1.97-0.13-2.96l-0.09,0.1c0.42-0.21,0.82-0.45,1.25-0.64
	c1.01-0.44,1.97-0.2,2.51,0.68c0.36,0.59,0.53,1.52,0.35,2.18c-0.25,0.99-1.15,1.48-2.21,1.43
	C236.29,182.77,235.83,182.58,235.37,182.46z"
              />
              <path
                d="M383.99,523.94c0.04-0.08,0.08-0.16,0.11-0.24c0.99-1.14,2.2-0.88,3.42-0.53c0,0-0.1-0.09-0.1-0.09
	c0.19,0.42,0.45,0.83,0.56,1.27c0.38,1.52-0.42,2.79-1.83,2.95c-1.25,0.15-2.36-0.84-2.45-2.26
	C383.68,524.69,383.89,524.31,383.99,523.94z"
              />
              <path
                d="M293.25,245.5c1.24-0.04,2.24,0.96,2.24,2.24c0,1.18-0.86,2.13-2.01,2.23c-1.17,0.1-2.33-0.92-2.4-2.12
	C291.01,246.65,292.04,245.54,293.25,245.5z"
              />
              <path
                d="M368.27,218.02c0.5-0.23,0.98-0.55,1.5-0.69c1.27-0.34,2.47,0.42,2.73,1.66c0.26,1.2-0.62,2.43-1.86,2.63
	c-1.25,0.2-2.34-0.67-2.45-2.02c-0.04-0.55,0.1-1.11,0.16-1.66L368.27,218.02z"
              />
              <path
                d="M343.31,512.61c0.66,1.04,0.75,2.16,0.5,3.34c0,0,0.09-0.09,0.09-0.09c-0.41,0.23-0.82,0.48-1.24,0.68
	c-1.01,0.48-1.99,0.23-2.53-0.63c-0.37-0.59-0.52-1.52-0.35-2.2c0.26-1.01,1.18-1.47,2.24-1.39c0.47,0.04,0.92,0.24,1.38,0.37
	L343.31,512.61z"
              />
              <path
                d="M544.77,379.76c0.57-0.27,1.11-0.64,1.71-0.8c1.19-0.31,2.43,0.59,2.64,1.81c0.2,1.14-0.53,2.23-1.69,2.53
	c-1.23,0.32-2.46-0.44-2.65-1.74c-0.09-0.61,0.04-1.26,0.08-1.89C544.86,379.68,544.77,379.76,544.77,379.76z"
              />
              <path
                d="M498.23,217.4c0.71,1.08,0.82,2.25,0.56,3.48l0.09-0.09c-0.41,0.22-0.82,0.45-1.24,0.65c-1,0.47-1.96,0.22-2.51-0.63
	c-0.37-0.58-0.53-1.49-0.37-2.16c0.23-1.01,1.14-1.47,2.19-1.42c0.46,0.02,0.92,0.17,1.38,0.26
	C498.32,217.48,498.23,217.4,498.23,217.4z"
              />
              <path
                d="M436.53,111.3c-1.24-0.01-2.2-1.05-2.16-2.33c0.04-1.18,0.93-2.1,2.09-2.14c1.21-0.05,2.29,0.99,2.31,2.2
	C438.8,110.24,437.74,111.32,436.53,111.3z"
              />
              <path
                d="M533.59,250.14c-0.06,0.08-0.12,0.17-0.17,0.26c-0.08,0.03-0.15,0.06-0.23,0.08c-1.71,0.53-2.66,0.45-3.15-0.58
	c-0.3-0.63-0.36-1.55-0.13-2.21c0.39-1.1,1.25-1.27,3.07-0.95c0.24,0.04,0.47,0.09,0.71,0.14c0,0,0.12,0.07,0.12,0.07
	C533.74,248.02,533.67,249.08,533.59,250.14z"
              />
              <path
                d="M282.53,221.93c-0.29-0.47-0.65-0.91-0.86-1.41c-0.49-1.19,0.09-2.52,1.24-2.95c1.28-0.48,2.67,0.28,2.95,1.61
	c0.27,1.29-0.44,2.4-1.76,2.63c-0.54,0.09-1.1,0.02-1.65,0.03C282.45,221.85,282.53,221.93,282.53,221.93z"
              />
              <path
                d="M361.07,511.2c0.57-0.26,1.11-0.63,1.71-0.76c1.19-0.27,2.4,0.66,2.57,1.87c0.17,1.19-0.77,2.38-1.98,2.52
	c-1.14,0.13-2.21-0.65-2.35-1.84c-0.07-0.61,0.09-1.25,0.14-1.87L361.07,511.2z"
              />
              <path
                d="M533.82,253.93c0.14,0.16,0.27,0.32,0.41,0.48c0.65,0.97,0.66,1.94,0.04,2.92c0,0,0.04-0.08,0.04-0.08
	c-0.4,0.25-0.77,0.61-1.21,0.73c-1.29,0.36-2.46-0.37-2.71-1.62c-0.26-1.3,0.46-2.44,1.78-2.63
	C532.71,253.66,533.28,253.86,533.82,253.93z"
              />
              <path
                d="M579.83,615.09l0.04-0.08c0.89-0.68,1.84-1,2.93-0.47c0.12,0.08,0.24,0.15,0.37,0.22c0.22,0.41,0.56,0.79,0.64,1.23
	c0.28,1.48-0.52,2.57-1.89,2.69c-1.37,0.13-2.42-0.79-2.43-2.24C579.48,615.99,579.71,615.54,579.83,615.09z"
              />
              <path
                d="M487.89,453.44c-1.24,0-2.23-1.05-2.19-2.33c0.03-1.18,0.93-2.12,2.09-2.18c1.21-0.06,2.31,0.97,2.34,2.2
	C490.16,452.35,489.09,453.45,487.89,453.44z"
              />
              <path
                d="M405.2,512.82c0.02,1.24-1,2.23-2.27,2.22c-1.3-0.01-2.22-0.98-2.17-2.29c0.04-1.19,0.93-2.07,2.12-2.11
	C404.14,510.6,405.19,511.58,405.2,512.82z"
              />
              <path
                d="M484.45,185.92c0.13,0.12,0.26,0.24,0.39,0.36c0.17,1.05,0.34,2.11,0.51,3.16c0,0,0.04-0.02,0.04-0.02
	c-0.58,0.23-1.15,0.56-1.76,0.69c-1.37,0.29-2.46-0.43-2.69-1.69c-0.25-1.38,0.6-2.47,2.08-2.6
	C483.5,185.77,483.98,185.88,484.45,185.92z"
              />
              <path
                d="M534.07,297.17c-0.06,0.08-0.12,0.17-0.16,0.25c-0.07,0.03-0.15,0.05-0.22,0.09c-1.08,0.55-2.13,0.64-3.02-0.32
	c-0.64-0.69-0.83-1.52-0.37-2.4c0.67-1.27,1.76-1.43,3.03-1.11c0.15,0.04,0.31,0,0.47,0c0.14,0.18,0.29,0.37,0.43,0.55
	C534.17,295.21,534.12,296.19,534.07,297.17z"
              />
              <path
                d="M337.31,523.64c-0.05,0.08-0.1,0.16-0.14,0.25c-0.49,0.24-0.95,0.61-1.47,0.7c-1.33,0.25-2.39-0.54-2.58-1.8
	c-0.19-1.27,0.58-2.35,1.89-2.53c0.54-0.07,1.1,0.02,1.65,0.04c0,0-0.09-0.07-0.09-0.07C337.2,521.28,337.97,522.3,337.31,523.64z"
              />
              <path
                d="M485.26,485.59c0.23,0.59,0.57,1.16,0.67,1.77c0.21,1.31-0.71,2.41-1.97,2.51c-1.1,0.08-2.21-0.85-2.35-1.96
	c-0.14-1.13,0.68-2.24,1.86-2.38c0.61-0.07,1.25,0.1,1.87,0.15C485.34,485.67,485.26,485.59,485.26,485.59z"
              />
              <path
                d="M434.05,103.81c0.09,0.12,0.18,0.24,0.28,0.35c0.19,0.43,0.51,0.85,0.56,1.3c0.13,1.29-0.84,2.39-2.07,2.46
	c-1.15,0.07-2.16-0.83-2.26-2.01c-0.11-1.31,0.72-2.3,2.1-2.38C433.11,103.5,433.58,103.71,434.05,103.81z"
              />
              <path
                d="M534.79,264.46c-0.95,1.02-2.04,1.21-3.17,0.38c-0.86-0.64-1.01-1.58-0.67-2.57c0.42-1.24,1.41-1.55,3.37-1.09
	c0,0-0.05-0.07-0.05-0.07c1.02,1.01,1.33,2.13,0.5,3.41L534.79,264.46z"
              />
              <path
                d="M520.93,226.11c0.52-0.16,1.03-0.42,1.57-0.46c1.33-0.11,2.3,0.78,2.37,2.06c0.06,1.29-0.86,2.28-2.14,2.31
	c-1.36,0.03-2.39-1.03-2.25-2.43c0.05-0.53,0.34-1.04,0.53-1.55C521.01,226.03,520.93,226.11,520.93,226.11z"
              />
              <path
                d="M354.96,515.58c-0.28-0.47-0.62-0.91-0.83-1.41c-0.48-1.18,0.1-2.5,1.25-2.93c1.18-0.44,2.49,0.19,2.86,1.39
	c0.41,1.34-0.32,2.6-1.72,2.84c-0.54,0.09-1.1,0.02-1.65,0.03C354.88,515.5,354.96,515.58,354.96,515.58z"
              />
              <path
                d="M378.86,213.14c-0.13,0.15-0.26,0.31-0.38,0.46c-0.54,0.11-1.08,0.31-1.62,0.32c-1.17,0.02-2.17-0.93-2.24-2.04
	c-0.08-1.11,0.85-2.17,1.99-2.27c1.2-0.11,2.14,0.62,2.31,1.87C378.98,212.02,378.88,212.59,378.86,213.14z"
              />
              <path
                d="M347.08,57.1c0.22,0.59,0.55,1.16,0.65,1.77c0.21,1.31-0.71,2.4-1.97,2.49c-1.1,0.08-2.2-0.85-2.34-1.97
	c-0.13-1.12,0.7-2.23,1.88-2.36c0.61-0.07,1.25,0.1,1.87,0.15C347.16,57.18,347.08,57.1,347.08,57.1z"
              />
              <path
                d="M430.54,100.52c0,0-0.05-0.06-0.05-0.06c0.08,0.71,0.26,1.42,0.23,2.12c-0.04,1.11-0.96,1.92-2.03,1.95
	c-1.09,0.03-2.18-0.96-2.25-2.05c-0.07-1.03,0.7-2.11,1.76-2.2C428.96,100.21,429.75,100.43,430.54,100.52z"
              />
              <path
                d="M348.66,385.41c1.26-0.04,2.33,0.98,2.31,2.22c-0.01,1.16-0.95,2.11-2.13,2.16c-1.3,0.05-2.3-0.92-2.28-2.22
	C346.56,386.37,347.46,385.45,348.66,385.41z"
              />
              <path
                d="M408.55,509.1c-0.05,1.24-1.1,2.16-2.38,2.09c-1.27-0.07-2.15-1.11-2.03-2.4c0.11-1.19,1.03-2.01,2.22-1.99
	C407.61,506.83,408.6,507.87,408.55,509.1z"
              />
              <path
                d="M334.48,517.43c-1.18-0.05-2.12-1-2.13-2.16c-0.01-1.24,1.06-2.27,2.32-2.22c1.29,0.05,2.18,1.09,2.08,2.42
	C336.65,516.68,335.73,517.48,334.48,517.43z"
              />
              <path
                d="M498.79,220.87c0.52-0.17,1.02-0.44,1.55-0.5c1.34-0.15,2.33,0.7,2.43,1.98c0.11,1.38-0.92,2.44-2.3,2.37
	c-1.26-0.07-2.19-1.07-2.07-2.37c0.05-0.53,0.31-1.05,0.47-1.57C498.88,220.79,498.79,220.87,498.79,220.87z"
              />
              <path
                d="M325.63,514c0.61-0.16,1.22-0.44,1.83-0.45c1.21-0.03,2.09,0.94,2.11,2.14c0.02,1.3-0.99,2.27-2.29,2.21
	c-1.18-0.05-2.16-0.99-2.1-2.17c0.03-0.61,0.35-1.21,0.54-1.82L325.63,514z"
              />
              <path
                d="M370.2,242.91c0.16,0.15,0.32,0.31,0.48,0.46c0.13,0.45,0.37,0.9,0.38,1.36c0.03,1.3-0.98,2.31-2.2,2.29
	c-1.07-0.02-1.95-0.82-2.07-1.9c-0.15-1.31,0.63-2.27,2.02-2.39C369.26,242.7,369.73,242.85,370.2,242.91z"
              />
              <path
                d="M236.02,149.89c0.1,0.06,0.19,0.12,0.28,0.19c0,1.05-0.01,2.09-0.01,3.14c0,0,0.07-0.11,0.07-0.11
	c-0.19,0.14-0.38,0.28-0.58,0.41c-0.86,0.57-1.74,0.77-2.59-0.01c-0.84-0.77-0.73-1.73-0.35-2.62c0.45-1.05,1.27-1.58,2.48-1.16
	C235.55,149.81,235.79,149.84,236.02,149.89z"
              />
              <path
                d="M399.77,518.88c-1.26-0.04-2.23-1.08-2.16-2.31c0.07-1.23,1.14-2.16,2.4-2.08c1.17,0.08,2.02,1,2.03,2.19
	C402.04,517.95,401.04,518.91,399.77,518.88z"
              />
              <path
                d="M351.65,526.37c0,0,0.09,0.04,0.09,0.04c0.18,0.35,0.43,0.69,0.53,1.06c0.37,1.42-0.36,2.64-1.66,2.88
	c-1.36,0.25-2.41-0.55-2.61-2.05c-0.05-0.38,0.05-0.78,0.08-1.17c0,0.01-0.07,0.09-0.07,0.09
	C348.98,525.94,350.2,525.67,351.65,526.37z"
              />
              <path
                d="M380.5,400.81c-0.04,0.07-0.09,0.14-0.12,0.21c-1.11,0.61-2.23,0.89-3.24-0.17c-0.65-0.68-0.85-1.51-0.39-2.39
	c0.66-1.29,1.77-1.42,3.03-1.12c0.15,0.03,0.31,0,0.47-0.01c0.14,0.19,0.29,0.37,0.43,0.56
	C380.61,398.85,380.55,399.83,380.5,400.81z"
              />
              <path
                d="M531.48,347.99c0.53-0.16,1.05-0.42,1.59-0.46c1.35-0.1,2.33,0.83,2.35,2.14c0.02,1.3-0.98,2.27-2.29,2.22
	c-1.28-0.05-2.24-1.1-2.1-2.4c0.06-0.54,0.35-1.05,0.53-1.58L531.48,347.99z"
              />
              <path
                d="M426.27,237.62c1.2,0.02,2.1,0.91,2.14,2.13c0.04,1.3-0.95,2.29-2.24,2.26c-1.18-0.03-2.13-0.96-2.17-2.12
	C423.96,238.64,425,237.6,426.27,237.62z"
              />
              <path
                d="M404.54,8.72c0,0,0.07-0.09,0.07-0.09c-0.67,0.23-1.32,0.58-2,0.66c-1.23,0.14-2.16-0.7-2.27-1.88
	c-0.1-1.08,0.59-2.05,1.63-2.29c1.13-0.26,2.36,0.38,2.6,1.49C404.71,7.28,404.56,8.02,404.54,8.72z"
              />
              <path
                d="M484.8,474.72c-0.13,0.15-0.26,0.3-0.39,0.45c-0.52,0.17-1.03,0.43-1.57,0.48c-1.27,0.13-2.19-0.61-2.38-1.78
	c-0.19-1.17,0.52-2.26,1.64-2.52c1.22-0.28,2.39,0.43,2.67,1.72C484.89,473.6,484.8,474.17,484.8,474.72z"
              />
              <path
                d="M318.62,59.64c0,0,0.07,0.03,0.07,0.03c0.09,0.55,0.23,1.1,0.25,1.66c0.05,1.43-0.8,2.4-2.06,2.42
	c-1.32,0.03-2.33-1.11-2.2-2.55c0.03-0.38,0.26-0.75,0.4-1.12c0,0-0.07,0.06-0.07,0.06C316.08,59.07,317.37,59.51,318.62,59.64z"
              />
              <path
                d="M489.68,203.56c0.15,0.14,0.3,0.28,0.45,0.41c0.14,0.45,0.37,0.9,0.39,1.35c0.07,1.29-0.93,2.33-2.15,2.34
	c-1.07,0.01-1.97-0.79-2.1-1.86c-0.16-1.3,0.6-2.26,2-2.39C488.73,203.37,489.21,203.51,489.68,203.56z"
              />
              <path
                d="M375.55,509.62c-0.52,0.2-1.02,0.5-1.55,0.57c-1.36,0.18-2.37-0.67-2.47-1.96c-0.1-1.3,0.83-2.34,2.12-2.37
	c1.27-0.02,2.3,0.96,2.25,2.26c-0.02,0.54-0.28,1.06-0.43,1.6C375.46,509.71,375.55,509.62,375.55,509.62z"
              />
              <path
                d="M538.21,596.68c0.12,0.11,0.24,0.22,0.37,0.33c0.16,0.53,0.41,1.04,0.45,1.58c0.09,1.19-0.75,2.16-1.91,2.3
	c-1.2,0.15-2.21-0.59-2.41-1.76c-0.22-1.28,0.54-2.37,1.85-2.54C537.1,596.52,537.66,596.65,538.21,596.68z"
              />
              <path
                d="M532.16,239.41c0.32,0.45,0.74,0.86,0.94,1.36c0.48,1.21-0.19,2.43-1.43,2.81c-1.13,0.34-2.31-0.29-2.63-1.41
	c-0.37-1.28,0.26-2.46,1.57-2.73c0.52-0.11,1.09,0.03,1.64,0.05C532.23,239.47,532.16,239.41,532.16,239.41z"
              />
              <path
                d="M363.75,229.52c-1.17-0.05-2.11-1-2.13-2.15c-0.01-1.23,1.05-2.26,2.31-2.22c1.28,0.04,2.19,1.09,2.08,2.4
	C365.9,228.76,364.97,229.57,363.75,229.52z"
              />
              <path
                d="M532.31,314.05c-0.06,1.12-1.11,2.1-2.23,2.09c-1.26-0.02-2.21-1.11-2.1-2.4c0.11-1.19,1.09-2.02,2.3-1.94
	C531.43,311.88,532.37,312.93,532.31,314.05z"
              />
              <path
                d="M334.31,328.98c0,0,0.05-0.05,0.05-0.05c-0.5,0.21-0.99,0.54-1.52,0.6c-1.25,0.14-2.35-0.88-2.38-2.08
	c-0.03-1.11,0.92-2.13,2.05-2.19c1.29-0.08,2.27,0.81,2.24,2.16C334.74,327.94,334.47,328.45,334.31,328.98z"
              />
              <path
                d="M516.95,226.47c0.21,0.59,0.52,1.16,0.62,1.76c0.21,1.31-0.69,2.39-1.95,2.47c-1.1,0.08-2.19-0.84-2.32-1.94
	c-0.14-1.19,0.83-2.3,2.11-2.36c0.54-0.02,1.08,0.1,1.62,0.16C517.03,226.56,516.95,226.47,516.95,226.47z"
              />
              <path
                d="M322.78,26.34c-0.39,0.26-0.74,0.62-1.17,0.76c-1.28,0.41-2.47-0.24-2.77-1.43c-0.32-1.27,0.4-2.43,1.65-2.67
	c1.34-0.25,2.51,0.65,2.58,2.06c0.02,0.44-0.23,0.9-0.36,1.34C322.72,26.41,322.78,26.34,322.78,26.34z"
              />
              <path
                d="M372.16,387.29c-0.52,0.19-1.02,0.47-1.55,0.54c-1.35,0.16-2.36-0.7-2.43-1.99c-0.08-1.3,0.87-2.33,2.14-2.33
	c1.26-0.01,2.27,0.99,2.21,2.28c-0.03,0.54-0.29,1.06-0.45,1.59C372.07,387.37,372.16,387.29,372.16,387.29z"
              />
              <path
                d="M413.98,181.01c1.25-0.01,2.28,1.05,2.23,2.27c-0.05,1.14-1.01,2.06-2.18,2.08c-1.29,0.02-2.24-0.96-2.19-2.27
	C411.88,181.9,412.78,181.02,413.98,181.01z"
              />
              <path
                d="M486.79,500.06c0.13,0.17,0.25,0.33,0.38,0.5c0.03,0.98,0.06,1.97,0.09,2.95c0,0,0.09-0.09,0.09-0.09
	c-0.49,0.23-0.96,0.52-1.48,0.66c-1.34,0.38-2.61-0.45-2.79-1.78c-0.16-1.18,0.78-2.3,2.07-2.38
	C485.69,499.88,486.24,500.01,486.79,500.06z"
              />
              <path
                d="M334.41,58.7c-1.21,0.04-2.13-0.79-2.23-2c-0.1-1.32,0.8-2.33,2.1-2.36c1.26-0.03,2.31,1,2.28,2.24
	C336.53,57.71,335.58,58.66,334.41,58.7z"
              />
              <path
                d="M357.14,341.83c0.5-0.22,0.98-0.5,1.5-0.65c1.29-0.37,2.48,0.31,2.79,1.54c0.3,1.17-0.56,2.44-1.79,2.66
	c-1.25,0.22-2.33-0.62-2.47-1.97c-0.05-0.54,0.03-1.1,0.05-1.65C357.23,341.75,357.14,341.83,357.14,341.83z"
              />
              <path
                d="M414.93,500.99c0.05,1.28-0.92,2.32-2.16,2.3c-1.09-0.01-2.12-1-2.18-2.09c-0.07-1.2,0.99-2.29,2.21-2.28
	C413.97,498.92,414.88,499.8,414.93,500.99z"
              />
              <path
                d="M450.22,123.78c0.23,0.59,0.56,1.16,0.66,1.76c0.22,1.31-0.68,2.4-1.94,2.5c-1.1,0.09-2.2-0.83-2.35-1.94
	c-0.15-1.2,0.79-2.34,2.08-2.42c0.54-0.03,1.09,0.11,1.63,0.18C450.3,123.87,450.22,123.78,450.22,123.78z"
              />
              <path
                d="M486.73,494.86c-0.01,1.12-1.01,2.15-2.13,2.18c-1.26,0.04-2.26-1.01-2.19-2.31c0.06-1.2,1.01-2.08,2.2-2.05
	C485.75,492.72,486.73,493.73,486.73,494.86z"
              />
              <path
                d="M369.72,65.79c-0.42,0.22-0.82,0.46-1.25,0.65c-0.92,0.4-1.73,0.15-2.37-0.54c-0.64-0.69-0.83-1.51-0.36-2.41
	c0.46-0.87,1.19-1.24,2.15-1.19c0.47,0.03,0.93,0.13,1.39,0.2c0,0-0.09-0.09-0.09-0.09c0.57,1.1,0.8,2.24,0.45,3.46L369.72,65.79z"
              />
              <path
                d="M394.73,518.8c0.53-0.15,1.05-0.4,1.59-0.44c1.33-0.09,2.31,0.85,2.33,2.14c0.01,1.29-0.99,2.26-2.28,2.2
	c-1.27-0.06-2.21-1.09-2.07-2.4c0.05-0.54,0.33-1.05,0.52-1.58C394.81,518.72,394.73,518.8,394.73,518.8z"
              />
              <path
                d="M667.74,545.77c-0.16-0.61-0.4-1.21-0.46-1.83c-0.11-1.28,0.77-2.24,1.95-2.25c1.04-0.01,2.07,1.06,2.12,2.21
	c0.05,1.18-0.88,2.16-2.08,2.11c-0.54-0.02-1.08-0.21-1.62-0.33C667.65,545.68,667.74,545.77,667.74,545.77z"
              />
              <path
                d="M288.54,335.01c0.03,1.11-0.94,2.15-2.06,2.21c-1.17,0.07-2.15-0.8-2.24-1.98c-0.1-1.3,0.84-2.32,2.13-2.32
	C287.52,332.93,288.51,333.88,288.54,335.01z"
              />
              <path
                d="M405.65,395.34c0,0-0.08,0.08-0.08,0.08c0.51-0.2,1.01-0.51,1.53-0.56c1.3-0.12,2.35,0.86,2.36,2.08
	c0.01,1.1-0.96,2.12-2.08,2.18c-1.25,0.06-2.25-0.89-2.19-2.22C405.21,396.38,405.49,395.86,405.65,395.34z"
              />
              <path
                d="M329.72,376.07c1.17-0.03,2.11,0.84,2.19,2.02c0.08,1.28-0.86,2.33-2.1,2.36c-1.19,0.02-2.28-1.07-2.24-2.26
	C327.61,377.07,328.6,376.1,329.72,376.07z"
              />
              <path
                d="M318.47,512.98c0.05,1.21-0.78,2.18-1.96,2.27c-1.21,0.1-2.35-0.96-2.35-2.17c0-1.13,0.99-2.12,2.13-2.14
	C317.48,510.91,318.41,511.78,318.47,512.98z"
              />
              <path
                d="M369.84,231.81c-0.5,0.23-0.98,0.49-1.49,0.68c-0.94,0.34-1.83,0.09-2.31-0.72c-0.33-0.55-0.47-1.36-0.35-1.99
	c0.18-0.96,0.99-1.42,1.96-1.44c0.55-0.01,1.1,0.12,1.64,0.19c0,0-0.09-0.08-0.09-0.08c0.18,1.15,0.37,2.3,0.55,3.45
	C369.75,231.89,369.84,231.81,369.84,231.81z"
              />
              <path
                d="M349.5,338.28c0.29,0.54,0.64,1.06,0.84,1.64c0.42,1.18-0.32,2.47-1.52,2.78c-1.15,0.3-2.3-0.33-2.63-1.43
	c-0.37-1.22,0.29-2.5,1.52-2.78c0.6-0.14,1.24-0.09,1.86-0.12C349.57,338.35,349.5,338.28,349.5,338.28z"
              />
              <path
                d="M335.07,336.55c0.5-0.22,0.98-0.49,1.5-0.64c1.31-0.37,2.47,0.29,2.79,1.52c0.3,1.17-0.56,2.43-1.79,2.65
	c-1.25,0.22-2.32-0.62-2.45-1.97c-0.05-0.54,0.03-1.1,0.04-1.65C335.15,336.47,335.07,336.55,335.07,336.55z"
              />
              <path
                d="M324.63,380.59c-0.52,0.18-1.03,0.46-1.56,0.52c-1.36,0.15-2.34-0.73-2.4-2.03c-0.06-1.3,0.89-2.31,2.17-2.3
	c1.26,0.01,2.26,1.01,2.18,2.31c-0.03,0.54-0.3,1.06-0.47,1.58C324.55,380.67,324.63,380.59,324.63,380.59z"
              />
              <path
                d="M457.25,141.97c-1.15,0.04-2.12-0.84-2.21-2.01c-0.1-1.29,0.81-2.32,2.08-2.36c1.11-0.03,2.16,0.89,2.26,1.99
	C459.49,140.76,458.45,141.93,457.25,141.97z"
              />
              <path
                d="M411.1,497.88c0.05,1.21-0.77,2.15-1.95,2.24c-1.25,0.1-2.35-0.84-2.4-2.07c-0.05-1.21,0.99-2.27,2.23-2.26
	C410.15,495.8,411.06,496.7,411.1,497.88z"
              />
              <path
                d="M403.89,498.47c-0.01,1.21-0.91,2.11-2.1,2.13c-1.16,0.01-2.16-0.92-2.22-2.06c-0.06-1.22,1.03-2.3,2.26-2.26
	C403,496.33,403.9,497.28,403.89,498.47z"
              />
              <path
                d="M290.37,242.29c0.37,0.5,0.85,0.96,1.09,1.52c0.5,1.19-0.15,2.52-1.31,2.88c-1.09,0.35-2.28-0.23-2.72-1.31
	c-0.46-1.14,0.14-2.43,1.39-2.84c0.51-0.16,1.09-0.13,1.63-0.19C290.44,242.36,290.37,242.29,290.37,242.29z"
              />
              <path
                d="M463.87,150.08c0.01,1.2-0.89,2.13-2.07,2.16c-1.16,0.03-2.17-0.88-2.24-2.03c-0.08-1.21,1-2.31,2.23-2.29
	C462.96,147.95,463.86,148.88,463.87,150.08z"
              />
              <path
                d="M417.71,495.35c0.18,0.52,0.44,1.02,0.51,1.55c0.2,1.35-0.65,2.39-1.91,2.49c-1.12,0.08-2.19-0.82-2.31-1.94
	c-0.13-1.21,0.83-2.27,2.16-2.29c0.54-0.01,1.09,0.17,1.63,0.27L417.71,495.35z"
              />
              <path
                d="M331.8,340.37c0.66-0.23,1.31-0.59,2-0.67c1.15-0.14,2.18,0.86,2.24,1.99c0.05,1.01-0.78,2.02-1.81,2.2
	c-1.18,0.21-2.27-0.51-2.42-1.72c-0.08-0.62,0.05-1.26,0.09-1.89L331.8,340.37z"
              />
              <path
                d="M325.72,513.93c-0.5,0.23-0.97,0.52-1.49,0.68c-1.28,0.4-2.48-0.28-2.8-1.52c-0.28-1.1,0.47-2.28,1.62-2.54
	c1.3-0.29,2.4,0.45,2.59,1.79c0.08,0.54,0,1.11,0,1.66C325.63,514,325.72,513.93,325.72,513.93z"
              />
              <path
                d="M317.81,379.54c-0.05,1.14-1.05,2.07-2.21,2.06c-1.3-0.01-2.21-1.04-2.09-2.36c0.11-1.18,1.1-2.04,2.27-1.95
	C316.92,377.38,317.87,378.43,317.81,379.54z"
              />
              <path
                d="M339.08,377.86c-0.04,1.2-1.01,2.1-2.19,2.06c-1.13-0.04-2.12-1.1-2.08-2.21c0.04-1.14,1.06-2.07,2.24-2.03
	C338.27,375.71,339.13,376.63,339.08,377.86z"
              />
              <path
                d="M442.68,117.38c0,0-0.09-0.08-0.09-0.08c0.19,0.6,0.48,1.18,0.57,1.79c0.18,1.21-0.62,2.23-1.75,2.39
	c-1.1,0.16-2.23-0.68-2.41-1.8c-0.17-1.06,0.65-2.22,1.81-2.39C441.41,117.21,442.05,117.34,442.68,117.38z"
              />
              <path
                d="M384.05,191.12c0.52-0.16,1.03-0.39,1.56-0.45c1.31-0.17,2.35,0.7,2.43,1.98c0.08,1.29-0.86,2.31-2.14,2.3
	c-1.24-0.02-2.23-1.05-2.14-2.32c0.04-0.53,0.25-1.06,0.38-1.58C384.14,191.04,384.05,191.12,384.05,191.12z"
              />
              <path
                d="M364.44,232.38c1.17,0.06,2.04,1.02,2.01,2.22c-0.03,1.2-0.94,2.1-2.13,2.09c-1.24-0.01-2.28-1.12-2.17-2.33
	C362.26,233.23,363.31,232.32,364.44,232.38z"
              />
              <path
                d="M286.14,224.95c-0.08-0.08-0.16-0.15-0.26-0.21c-0.18-0.52-0.47-1.03-0.51-1.56c-0.11-1.27,0.91-2.31,2.15-2.33
	c1.18-0.02,2.11,0.86,2.17,2.05c0.07,1.21-0.68,2.16-1.92,2.27C287.24,225.2,286.69,225.02,286.14,224.95z"
              />
              <path
                d="M326.88,344.67c-1.25-0.01-2.27-1.07-2.19-2.29c0.07-1.13,1.06-2.05,2.2-2.05c1.27,0,2.21,1.01,2.14,2.31
	C328.98,343.84,328.09,344.68,326.88,344.67z"
              />
              <path
                d="M481.04,182.89c0.13,0.53,0.34,1.05,0.38,1.58c0.08,1.29-0.9,2.3-2.15,2.31c-1.29,0.01-2.22-1-2.13-2.3
	c0.08-1.28,1.11-2.15,2.43-1.98c0.53,0.07,1.04,0.31,1.56,0.47C481.13,182.98,481.04,182.89,481.04,182.89z"
              />
              <path
                d="M660.53,626.31c0.12,0.45,0.31,0.9,0.36,1.36c0.14,1.43-0.85,2.52-2.21,2.5c-1.29-0.02-2.2-1.05-2.1-2.36
	c0.1-1.29,1.13-2.13,2.48-1.95c0.53,0.07,1.04,0.35,1.56,0.53C660.62,626.4,660.53,626.31,660.53,626.31z"
              />
              <path
                d="M426.91,99.25c-0.01,1.2-0.91,2.11-2.1,2.12c-1.15,0.01-2.15-0.93-2.21-2.06c-0.06-1.2,1.04-2.29,2.26-2.25
	C426.03,97.11,426.92,98.06,426.91,99.25z"
              />
              <path
                d="M457.04,235.67c-0.17-0.15-0.35-0.3-0.52-0.46c-0.17-0.52-0.46-1.03-0.49-1.56c-0.07-1.25,0.78-2.16,1.96-2.24
	c1.05-0.07,2.03,0.66,2.29,1.7c0.27,1.09-0.41,2.26-1.59,2.54C458.17,235.78,457.59,235.67,457.04,235.67z"
              />
              <path
                d="M413.85,491.98c0.19,0.59,0.49,1.17,0.55,1.78c0.12,1.22-0.74,2.17-1.92,2.26c-1.12,0.09-2.19-0.8-2.32-1.92
	c-0.13-1.12,0.72-2.19,1.91-2.27c0.61-0.04,1.24,0.15,1.86,0.23L413.85,491.98z"
              />
              <path
                d="M513.14,223.14c0.2,0.59,0.5,1.17,0.59,1.79c0.18,1.23-0.64,2.24-1.81,2.39c-1.12,0.15-2.24-0.71-2.42-1.84
	c-0.18-1.13,0.64-2.23,1.84-2.36c0.62-0.07,1.26,0.07,1.89,0.11C513.22,223.23,513.14,223.14,513.14,223.14z"
              />
              <path
                d="M352.1,346.1c-1.19,0.01-2.09-0.85-2.15-2.04c-0.06-1.28,0.89-2.3,2.14-2.29c1.13,0.01,2.12,0.93,2.19,2.05
	C354.37,345.03,353.35,346.1,352.1,346.1z"
              />
              <path
                d="M365.01,532.09c1.18-0.02,2.19,0.92,2.21,2.06c0.02,1.11-0.99,2.16-2.11,2.19c-1.18,0.03-2.13-0.89-2.16-2.09
	C362.91,533.03,363.78,532.12,365.01,532.09z"
              />
              <path
                d="M531.57,347.91c-0.5,0.23-0.97,0.51-1.49,0.67c-1.29,0.39-2.48-0.27-2.79-1.51c-0.28-1.1,0.48-2.28,1.62-2.53
	c1.3-0.28,2.39,0.45,2.58,1.8c0.07,0.54,0.01,1.1,0,1.65C531.48,347.99,531.57,347.91,531.57,347.91z"
              />
              <path
                d="M373.39,217.75c-1.11-0.03-2.11-1.04-2.12-2.14c-0.01-1.21,1.09-2.21,2.33-2.12c1.19,0.08,2.02,1.01,1.96,2.22
	C375.5,216.88,374.55,217.78,373.39,217.75z"
              />
              <path
                d="M386.03,505.37c-0.52,0.17-1.03,0.44-1.56,0.49c-1.35,0.13-2.32-0.74-2.37-2.04c-0.05-1.29,0.92-2.3,2.18-2.28
	c1.23,0.02,2.23,1.05,2.15,2.33c-0.04,0.53-0.31,1.05-0.47,1.58C385.95,505.45,386.03,505.37,386.03,505.37z"
              />
              <path
                d="M260.32,216.52c-0.18-0.17-0.36-0.33-0.54-0.5c-0.17-0.44-0.44-0.86-0.49-1.31c-0.15-1.47,0.78-2.52,2.13-2.5
	c1.33,0.02,2.31,1.15,2.14,2.54c-0.06,0.46-0.28,0.89-0.43,1.34c0,0,0.09-0.09,0.09-0.09C262.26,216.17,261.29,216.34,260.32,216.52
	z"
              />
              <path
                d="M395.23,524.5c0.05,1.2-0.77,2.16-1.95,2.25c-1.2,0.09-2.34-0.95-2.34-2.14c0-1.11,0.98-2.09,2.11-2.12
	C394.25,522.45,395.17,523.31,395.23,524.5z"
              />
              <path
                d="M323.37,388.32c-1.19,0.04-2.12-0.84-2.18-2.04c-0.06-1.2,0.78-2.17,1.95-2.27c1.21-0.1,2.36,0.95,2.36,2.16
	C325.49,387.29,324.51,388.28,323.37,388.32z"
              />
              <path
                d="M435.16,241.4c0.11-0.15,0.21-0.29,0.32-0.44c1.09-0.19,2.18-0.39,3.27-0.58c0.17,0.2,0.35,0.4,0.52,0.59
	c0.03,0.39,0.09,0.78,0.09,1.17c-0.02,1.5-0.76,2.39-2,2.44c-1.22,0.05-2.06-0.77-2.25-2.25
	C435.06,242.04,435.14,241.72,435.16,241.4z"
              />
              <path
                d="M535.23,387.71c-0.15-0.12-0.29-0.25-0.44-0.37c-0.18-0.51-0.49-1.02-0.53-1.55c-0.09-1.24,0.76-2.17,1.93-2.27
	c1.04-0.08,2.04,0.66,2.29,1.69c0.26,1.09-0.43,2.26-1.6,2.53C536.36,387.87,535.78,387.74,535.23,387.71z"
              />
              <path
                d="M356.22,531.48c0.05,1.13-0.85,2.13-2,2.24c-1.29,0.12-2.31-0.79-2.35-2.08c-0.04-1.19,0.79-2.15,1.96-2.25
	C355.06,529.28,356.17,530.25,356.22,531.48z"
              />
              <path
                d="M362.63,231.21c-0.01,1.21-0.92,2.12-2.11,2.13c-1.17,0.01-2.16-0.92-2.22-2.07c-0.06-1.22,1.03-2.3,2.27-2.26
	C361.75,229.05,362.64,230.01,362.63,231.21z"
              />
              <path
                d="M532.29,353.69c0.05,1.1-0.89,2.14-2.02,2.23c-1.17,0.09-2.15-0.74-2.26-1.92c-0.12-1.3,0.79-2.32,2.08-2.34
	C531.24,351.65,532.24,352.58,532.29,353.69z"
              />
              <path
                d="M459.57,18.45c-0.21-0.5-0.52-0.99-0.62-1.52c-0.26-1.39,0.52-2.38,1.84-2.49c1.2-0.1,2.23,0.69,2.38,1.82
	c0.15,1.16-0.83,2.35-2.08,2.39c-0.53,0.02-1.07-0.19-1.61-0.3C459.48,18.36,459.57,18.45,459.57,18.45z"
              />
              <path
                d="M483.9,467.96c-0.52,0.17-1.03,0.43-1.56,0.48c-1.35,0.12-2.31-0.77-2.35-2.06c-0.04-1.29,0.94-2.29,2.2-2.26
	c1.23,0.03,2.22,1.07,2.13,2.34c-0.04,0.53-0.31,1.05-0.48,1.57C483.83,468.03,483.9,467.96,483.9,467.96z"
              />
              <path
                d="M366.57,506.66c1.17,0.04,2.14,1.06,2.09,2.19c-0.05,1.12-1.11,2.09-2.24,2.06c-1.19-0.04-2.07-0.99-2.04-2.21
	C364.42,507.49,365.36,506.61,366.57,506.66z"
              />
              <path
                d="M385.01,529.23c-0.04,1.22-1.14,2.18-2.38,2.08c-1.18-0.09-2-1.03-1.96-2.23c0.04-1.18,0.94-2.08,2.11-2.1
	C383.99,526.97,385.05,528.02,385.01,529.23z"
              />
              <path
                d="M539,372.49c1.17,0,2.09,0.93,2.11,2.12c0.02,1.21-0.84,2.14-2.03,2.19c-1.24,0.05-2.33-1.01-2.28-2.22
	C536.85,373.46,537.87,372.49,539,372.49z"
              />
              <path
                d="M390.47,201.19c1.13-0.01,2.15,0.96,2.2,2.08c0.05,1.21-1.03,2.27-2.28,2.22c-1.18-0.05-2.05-0.98-2.03-2.18
	C388.38,202.12,389.3,201.19,390.47,201.19z"
              />
              <path
                d="M358.78,61.87c-0.04,1.22-0.95,2.1-2.15,2.09c-1.16-0.01-2.15-0.97-2.18-2.11c-0.03-1.22,1.08-2.28,2.32-2.21
	C357.95,59.7,358.82,60.66,358.78,61.87z"
              />
              <path
                d="M486.99,456.14c-0.42,0.37-0.78,0.84-1.26,1.07c-1.2,0.58-2.49,0.04-2.99-1.15c-0.48-1.14,0.17-2.5,1.38-2.88
	c1.21-0.38,2.41,0.3,2.74,1.61c0.11,0.45,0.05,0.95,0.08,1.42C486.94,456.21,486.99,456.14,486.99,456.14z"
              />
              <path
                d="M481.61,477.28c0.03,1.21-0.82,2.14-2.01,2.2c-1.24,0.06-2.34-0.99-2.3-2.2c0.04-1.11,1.05-2.09,2.18-2.1
	C480.65,475.17,481.58,476.08,481.61,477.28z"
              />
              <path
                d="M336.51,388.89c-0.05,1.12-1.12,2.1-2.24,2.06c-1.19-0.04-2.07-1-2.04-2.21c0.04-1.21,0.97-2.08,2.19-2.04
	C335.59,386.74,336.56,387.76,336.51,388.89z"
              />
              <path
                d="M532.77,360.94c0.02,1.12-0.99,2.16-2.12,2.18c-1.18,0.03-2.13-0.9-2.15-2.1c-0.02-1.23,0.85-2.13,2.08-2.15
	C531.74,358.85,532.75,359.8,532.77,360.94z"
              />
              <path
                d="M662.23,525.04c0,0-0.01,0.1-0.01,0.1c1.78-1.3,2.94-1.17,3.55,0.4c0.64,1.64-0.09,2.77-1.87,2.88
	c-0.39,0.02-0.78-0.04-1.18-0.06c-0.09-0.08-0.17-0.16-0.24-0.25C662.03,527.11,661.76,526.1,662.23,525.04z"
              />
              <path
                d="M368.66,382.32c0.01,1.11-1.02,2.13-2.16,2.14c-1.17,0.01-2.11-0.94-2.11-2.13c0-1.19,0.94-2.15,2.1-2.15
	C367.61,380.19,368.65,381.22,368.66,382.32z"
              />
              <path
                d="M365.01,221.77c-0.52,0.16-1.03,0.38-1.56,0.45c-1.31,0.18-2.36-0.72-2.41-1.99c-0.05-1.17,0.84-2.16,1.98-2.22
	c1.2-0.06,2.27,0.99,2.22,2.25c-0.02,0.53-0.22,1.06-0.33,1.59C364.92,221.86,365.01,221.77,365.01,221.77z"
              />
              <path
                d="M287.22,239.52c0.1,0.53,0.27,1.05,0.29,1.58c0.06,1.28-0.98,2.32-2.21,2.26c-1.17-0.05-2.05-1.03-2-2.21
	c0.06-1.27,1.12-2.18,2.42-2c0.53,0.07,1.05,0.29,1.57,0.44C287.31,239.6,287.22,239.52,287.22,239.52z"
              />
              <path
                d="M415.69,175.94c0.03,1.11-0.92,2.13-2.06,2.2c-1.19,0.07-2.14-0.77-2.23-1.97c-0.09-1.28,0.86-2.34,2.12-2.33
	C414.65,173.85,415.66,174.83,415.69,175.94z"
              />
              <path
                d="M292.2,235.71c-1.17,0.05-2.14-0.86-2.18-2.05c-0.04-1.19,0.86-2.18,2.02-2.22c1.11-0.04,2.18,0.95,2.23,2.06
	C294.32,234.61,293.33,235.66,292.2,235.71z"
              />
              <path
                d="M361.15,511.12c-0.52,0.16-1.03,0.4-1.56,0.47c-1.32,0.17-2.35-0.74-2.39-2.02c-0.04-1.18,0.85-2.16,2-2.22
	c1.21-0.05,2.29,0.99,2.23,2.26c-0.02,0.54-0.24,1.07-0.36,1.6C361.07,511.2,361.15,511.12,361.15,511.12z"
              />
              <path
                d="M408.95,516.3c-0.06,1.12-1.11,2.08-2.24,2.03c-1.17-0.05-2.05-1.03-2-2.22c0.05-1.18,1.04-2.1,2.19-2.04
	C408,514.12,409,515.21,408.95,516.3z"
              />
              <path
                d="M481.38,461.24c-1.15-0.02-2.16-1.04-2.14-2.16c0.02-1.11,1.07-2.13,2.19-2.12c1.17,0.01,2.09,0.97,2.08,2.17
	C483.5,460.33,482.56,461.26,481.38,461.24z"
              />
              <path
                d="M468.15,162.28c-0.52,0.16-1.03,0.4-1.57,0.46c-1.32,0.16-2.35-0.74-2.39-2.03c-0.03-1.18,0.86-2.16,2.01-2.21
	c1.21-0.05,2.28,1,2.22,2.26c-0.03,0.54-0.24,1.07-0.37,1.6C468.07,162.37,468.15,162.28,468.15,162.28z"
              />
              <path
                d="M339.94,57.63c0.19,0.6,0.48,1.18,0.56,1.79c0.16,1.22-0.68,2.21-1.85,2.34c-1.13,0.13-2.22-0.73-2.38-1.86
	c-0.16-1.13,0.66-2.21,1.87-2.32c0.62-0.06,1.26,0.08,1.89,0.13C340.02,57.71,339.94,57.63,339.94,57.63z"
              />
              <path
                d="M405.19,492.48c1.15,0.04,2.05,1.04,2.01,2.21c-0.04,1.17-1.01,2.09-2.17,2.04c-1.12-0.05-2.11-1.1-2.06-2.2
	C403.02,493.43,404.1,492.44,405.19,492.48z"
              />
              <path
                d="M535.26,369.15c1.15,0.06,2.03,1.02,2,2.2c-0.02,1.18-0.94,2.09-2.11,2.09c-1.23,0-2.27-1.11-2.16-2.31
	C533.09,370.01,534.14,369.09,535.26,369.15z"
              />
              <path
                d="M379.23,504.18c0.02,1.12-0.94,2.12-2.08,2.17c-1.18,0.05-2.13-0.8-2.21-1.99c-0.08-1.28,0.89-2.33,2.14-2.31
	C378.2,502.07,379.21,503.07,379.23,504.18z"
              />
              <path
                d="M324.52,332c-0.03,1.12-1.02,2.08-2.16,2.09c-1.27,0.01-2.22-1.02-2.12-2.31c0.09-1.17,1.07-2.05,2.23-1.98
	C323.58,329.86,324.55,330.89,324.52,332z"
              />
              <path
                d="M505.92,220.38c0.52-0.15,1.04-0.36,1.57-0.43c1.31-0.17,2.36,0.76,2.4,2.04c0.04,1.2-0.86,2.16-2.03,2.2
	c-1.24,0.04-2.28-1.01-2.2-2.29c0.03-0.54,0.22-1.07,0.34-1.6C506,220.3,505.92,220.38,505.92,220.38z"
              />
              <path
                d="M533.42,367.98c-0.02,1.18-0.93,2.09-2.1,2.1c-1.13,0-2.14-0.95-2.18-2.06c-0.05-1.19,1.06-2.28,2.27-2.22
	C532.56,365.84,533.44,366.79,533.42,367.98z"
              />
              <path
                d="M318.27,386.74c-0.05,1.14-1.1,2.08-2.25,2.04c-1.18-0.05-2.06-1.02-2.01-2.23c0.05-1.19,1.04-2.11,2.2-2.05
	C317.33,384.56,318.32,385.64,318.27,386.74z"
              />
              <path
                d="M379.37,530.86c1.1-0.04,2.18,0.96,2.22,2.06c0.05,1.1-0.95,2.16-2.07,2.2c-1.16,0.05-2.13-0.87-2.17-2.05
	C377.32,531.89,378.22,530.9,379.37,530.86z"
              />
              <path
                d="M479.94,482.39c1.14-0.02,2.11,0.94,2.13,2.12c0.01,1.19-0.9,2.14-2.07,2.15c-1.13,0.01-2.16-0.99-2.18-2.1
	C477.8,483.47,478.84,482.41,479.94,482.39z"
              />
              <path
                d="M511.9,410.68c-0.45,0.32-0.87,0.72-1.37,0.93c-1.2,0.51-2.45-0.11-2.84-1.35c-0.34-1.06,0.34-2.28,1.45-2.63
	c1.09-0.34,2.27,0.3,2.58,1.48c0.14,0.52,0.07,1.1,0.1,1.65C511.83,410.76,511.9,410.68,511.9,410.68z"
              />
              <path
                d="M374.43,533.47c0.01,1.12-0.97,2.11-2.11,2.15c-1.18,0.04-2.12-0.83-2.18-2.02c-0.07-1.28,0.91-2.32,2.17-2.28
	C373.42,531.35,374.43,532.37,374.43,533.47z"
              />
              <path
                d="M423.05,95.78c0.04,1.21-0.84,2.17-2.02,2.22c-1.15,0.04-2.19-0.91-2.24-2.05c-0.05-1.1,0.95-2.18,2.08-2.23
	C422.03,93.67,423.01,94.59,423.05,95.78z"
              />
              <path
                d="M382.27,207.73c-0.03,1.18-0.95,2.08-2.12,2.07c-1.14-0.01-2.13-0.97-2.16-2.09c-0.03-1.19,1.09-2.27,2.29-2.2
	C381.43,205.59,382.3,206.55,382.27,207.73z"
              />
              <path
                d="M415.84,368.68c1.1-0.04,2.17,0.95,2.22,2.05c0.05,1.1-0.94,2.15-2.06,2.2c-1.16,0.05-2.13-0.87-2.17-2.04
	C413.79,369.72,414.69,368.72,415.84,368.68z"
              />
              <path
                d="M445.01,120.58c1.16,0.04,2.06,0.98,2.05,2.17c0,1.19-0.9,2.11-2.08,2.13c-1.24,0.02-2.3-1.06-2.21-2.27
	C442.85,121.49,443.88,120.55,445.01,120.58z"
              />
              <path
                d="M309.2,511.66c1.17,0.03,2.08,1,2.05,2.2c-0.03,1.19-0.98,2.12-2.16,2.08c-1.14-0.03-2.14-1.07-2.1-2.19
	C307.02,512.63,308.07,511.63,309.2,511.66z"
              />
              <path
                d="M331.89,340.28c-0.52,0.17-1.04,0.42-1.57,0.49c-1.34,0.17-2.36-0.72-2.41-2.03c-0.04-1.2,0.84-2.17,2.01-2.23
	c1.23-0.06,2.31,0.97,2.26,2.25c-0.02,0.54-0.25,1.07-0.38,1.61C331.8,340.37,331.89,340.28,331.89,340.28z"
              />
              <path
                d="M347.23,344.43c0,1.18-0.95,2.12-2.11,2.11c-1.12-0.01-2.15-1.03-2.14-2.13c0.01-1.09,1.06-2.12,2.16-2.12
	C346.29,342.29,347.23,343.26,347.23,344.43z"
              />
              <path
                d="M329.93,521.21c0.11,0.53,0.3,1.06,0.31,1.6c0.05,1.29-1.01,2.3-2.26,2.24c-1.18-0.06-2.04-1.03-1.99-2.24
	c0.06-1.29,1.11-2.18,2.43-2c0.54,0.07,1.05,0.31,1.58,0.48C330.02,521.3,329.93,521.21,329.93,521.21z"
              />
              <path
                d="M484.96,193.18c0.13,0.1,0.25,0.2,0.38,0.3c0.19,0.51,0.48,1.01,0.53,1.54c0.12,1.23-0.7,2.18-1.86,2.29
	c-1.03,0.1-2.02-0.62-2.28-1.66c-0.24-0.96,0.35-2.15,1.37-2.44C483.68,193.04,484.34,193.18,484.96,193.18z"
              />
              <path
                d="M418.24,90.43c0.15,0.13,0.3,0.25,0.45,0.38c0.17,0.52,0.46,1.02,0.5,1.55c0.1,1.24-0.73,2.17-1.89,2.27
	c-1.04,0.09-2.03-0.63-2.28-1.66c-0.27-1.07,0.41-2.23,1.58-2.53C417.11,90.32,417.69,90.43,418.24,90.43z"
              />
              <path
                d="M321.81,343c-0.01,1.18-0.91,2.1-2.08,2.12c-1.13,0.02-2.14-0.92-2.2-2.04c-0.06-1.19,1.04-2.29,2.24-2.25
	C320.93,340.87,321.82,341.82,321.81,343z"
              />
              <path
                d="M332.85,511.86c0.02,1.19-0.86,2.12-2.03,2.16c-1.14,0.04-2.16-0.87-2.25-1.99c-0.09-1.19,0.98-2.31,2.19-2.3
	C331.92,509.75,332.83,510.68,332.85,511.86z"
              />
              <path
                d="M369.64,65.87c0.52-0.14,1.03-0.34,1.56-0.41c1.3-0.17,2.37,0.75,2.41,2.02c0.04,1.18-0.86,2.16-2.02,2.19
	c-1.23,0.04-2.26-1.01-2.18-2.29c0.03-0.53,0.21-1.06,0.32-1.59C369.72,65.79,369.64,65.87,369.64,65.87z"
              />
              <path
                d="M368.35,217.93c-0.52,0.16-1.03,0.4-1.57,0.47c-1.33,0.17-2.36-0.73-2.4-2.02c-0.04-1.19,0.85-2.17,2.01-2.22
	c1.22-0.06,2.29,0.98,2.24,2.26c-0.02,0.54-0.24,1.07-0.37,1.6C368.27,218.02,368.35,217.93,368.35,217.93z"
              />
              <path
                d="M418.6,376.14c-0.11-0.11-0.24-0.21-0.37-0.3c-0.18-0.52-0.48-1.02-0.53-1.55c-0.1-1.12,0.67-2.04,1.71-2.22
	c1.07-0.19,2.24,0.68,2.42,1.8c0.16,1-0.52,2.08-1.6,2.32C419.73,376.31,419.15,376.17,418.6,376.14z"
              />
              <path
                d="M252.21,109.08c-1.21,0.04-2.15-0.85-2.16-2.04c-0.01-1.17,0.9-2.17,2.03-2.22c1.16-0.06,2.11,0.86,2.14,2.06
	C254.26,108.08,253.39,109.04,252.21,109.08z"
              />
              <path
                d="M425.47,108.52c-1.16-0.03-2.08-1.03-2.02-2.21c0.05-1.08,0.94-1.97,1.99-2.01c1.1-0.04,2.19,1,2.2,2.1
	C427.64,107.52,426.59,108.55,425.47,108.52z"
              />
              <path
                d="M416.61,396.28c-0.02,1.1-1.05,2.1-2.17,2.08c-1.16-0.02-2.07-0.97-2.05-2.15c0.02-1.16,0.99-2.12,2.12-2.1
	C415.59,394.14,416.63,395.2,416.61,396.28z"
              />
              <path
                d="M480.9,470.07c0.04,1.08-0.95,2.14-2.05,2.18c-1.15,0.04-2.11-0.87-2.15-2.04c-0.04-1.16,0.87-2.15,2-2.19
	C479.79,467.98,480.85,468.97,480.9,470.07z"
              />
              <path
                d="M528.59,231.07c0.06,1.17-0.82,2.15-1.97,2.21c-1.11,0.06-2.16-0.89-2.22-2c-0.06-1.08,0.92-2.16,2.01-2.22
	C527.53,228.99,528.53,229.92,528.59,231.07z"
              />
              <path
                d="M350.42,59.96c0.3,0.44,0.66,0.86,0.89,1.34c0.57,1.2,0.07,2.47-1.08,2.95c-1.06,0.43-2.24-0.08-2.71-1.19
	c-0.48-1.13,0.1-2.38,1.38-2.8c0.51-0.16,1.07-0.16,1.6-0.23C350.48,60.02,350.42,59.96,350.42,59.96z"
              />
              <path
                d="M386.75,197.88c1.11,0.07,2.09,1.19,1.99,2.28c-0.1,1.14-1.21,2.05-2.33,1.92c-1.07-0.12-1.87-1.05-1.84-2.14
	C384.6,198.77,385.62,197.81,386.75,197.88z"
              />
              <path
                d="M326.29,22.66c0,0,0.07-0.1,0.07-0.1c-0.57,0.26-1.12,0.63-1.72,0.76c-1.21,0.26-2.25-0.53-2.43-1.69
	c-0.17-1.13,0.45-2.09,1.53-2.35c1.19-0.29,2.31,0.3,2.58,1.49C326.44,21.37,326.3,22.02,326.29,22.66z"
              />
              <path
                d="M363.04,238.34c0.02,1.18-0.94,2.16-2.1,2.14c-1.12-0.02-2.14-1.08-2.09-2.19c0.05-1.01,1.01-1.98,2.02-2.03
	C362.01,236.2,363.02,237.18,363.04,238.34z"
              />
              <path
                d="M555.42,374.91c-0.08,0.12-0.16,0.23-0.25,0.34c-0.54,0.11-1.07,0.28-1.62,0.31c-1.31,0.05-2.34-0.92-2.33-2.13
	c0.01-1.12,0.91-2.03,2.15-2.06c0.54-0.02,1.09,0.12,1.63,0.18c0,0-0.09-0.07-0.09-0.07C555.08,372.62,555.25,373.76,555.42,374.91z
	"
              />
              <path
                d="M383.17,205.93c-1.15-0.06-2.02-1.05-1.96-2.22c0.06-1.15,1.06-2.08,2.18-2.01c1.1,0.07,2.07,1.15,2.01,2.23
	C385.34,205.05,384.28,205.99,383.17,205.93z"
              />
              <path
                d="M275.54,184.63c0,1.1-1.02,2.11-2.13,2.12c-1.15,0.01-2.09-0.94-2.09-2.11c0-1.16,0.95-2.13,2.08-2.13
	C274.48,182.51,275.54,183.55,275.54,184.63z"
              />
              <path
                d="M267.5,215.99c-0.17-0.15-0.34-0.3-0.51-0.45c-0.15-0.44-0.4-0.87-0.44-1.32c-0.13-1.35,0.72-2.37,1.94-2.44
	c1.28-0.07,2.31,1.05,2.2,2.45c-0.04,0.46-0.22,0.91-0.33,1.37c0,0,0.07-0.06,0.07-0.06C269.46,215.69,268.48,215.84,267.5,215.99z"
              />
              <path
                d="M291.35,224.26c1.14-0.03,2.12,0.97,2.12,2.14c-0.01,1.08-0.84,1.99-1.91,2.08c-1.12,0.09-2.21-0.86-2.27-1.99
	C289.22,225.39,290.24,224.29,291.35,224.26z"
              />
              <path
                d="M350.81,537.51c-1.1-0.04-2.09-1.1-2.04-2.19c0.05-1.09,1.11-2.08,2.2-2.04c1.14,0.04,2.04,1.03,2,2.2
	C352.93,536.64,351.96,537.55,350.81,537.51z"
              />
              <path
                d="M542.8,602.18c-0.05,1.11-1.1,2.07-2.21,2.02c-1.15-0.05-2.04-1.03-1.99-2.19c0.05-1.15,1.05-2.09,2.16-2.03
	C541.84,600.02,542.84,601.11,542.8,602.18z"
              />
              <path
                d="M359.85,226.09c-1.15,0.04-2.14-0.92-2.15-2.09c0-1.08,0.83-2.01,1.88-2.12c1.1-0.11,2.22,0.85,2.3,1.98
	C361.96,224.96,360.97,226.05,359.85,226.09z"
              />
              <path
                d="M359.99,534.89c0.02,1.1-1,2.12-2.11,2.14c-1.15,0.02-2.1-0.92-2.11-2.09c-0.01-1.17,0.92-2.14,2.06-2.15
	C358.92,532.78,359.98,533.81,359.99,534.89z"
              />
              <path
                d="M296.78,57.7c-0.15,0.14-0.3,0.28-0.44,0.42c-0.61,0.12-1.23,0.36-1.83,0.33c-0.98-0.06-1.77-1.03-1.75-1.93
	c0.02-1.07,1.05-2.09,2.15-2.13c1.06-0.04,1.76,0.52,1.93,1.66C296.91,56.59,296.8,57.15,296.78,57.7z"
              />
              <path
                d="M389.97,176.18c-0.52,0.15-1.02,0.38-1.55,0.45c-1.3,0.18-2.36-0.71-2.41-1.97c-0.05-1.17,0.83-2.17,1.97-2.23
	c1.19-0.06,2.27,0.99,2.22,2.25c-0.02,0.53-0.2,1.06-0.31,1.58C389.89,176.27,389.97,176.18,389.97,176.18z"
              />
              <path
                d="M325.62,346.27c0.04,1.18-0.85,2.15-2.01,2.18c-1.12,0.04-2.16-0.93-2.2-2.04c-0.04-1.07,0.97-2.16,2.06-2.2
	C324.59,344.17,325.58,345.11,325.62,346.27z"
              />
              <path
                d="M312.5,507.84c1.13,0,2.08,0.97,2.08,2.12c0,1.17-0.93,2.11-2.08,2.11c-1.11,0-2.13-1.02-2.13-2.11
	C310.37,508.89,311.43,507.84,312.5,507.84z"
              />
              <path
                d="M352.03,512.07c-1.16-0.03-2.07-1.04-2.01-2.22c0.06-1.08,0.95-1.96,2.01-1.99c1.1-0.03,2.18,1.02,2.18,2.12
	C354.22,511.08,353.16,512.1,352.03,512.07z"
              />
              <path
                d="M403.67,517.92c1.13,0,2.08,0.97,2.08,2.12c0,1.17-0.93,2.11-2.08,2.11c-1.11,0-2.13-1.02-2.13-2.11
	C401.54,518.97,402.6,517.92,403.67,517.92z"
              />
              <path
                d="M412.03,365.34c1.1-0.03,2.18,1.03,2.17,2.13c-0.01,1.12-1.07,2.13-2.19,2.09c-1.07-0.04-1.94-0.91-2-1.99
	C409.95,366.38,410.88,365.37,412.03,365.34z"
              />
              <path
                d="M459.98,146.77c-0.02,1.17-0.98,2.1-2.13,2.07c-1.11-0.03-2.12-1.07-2.09-2.16c0.03-1.09,1.09-2.1,2.18-2.08
	C459.08,144.63,460,145.61,459.98,146.77z"
              />
              <path
                d="M250.37,380.12c-1.15,0-2.08-0.94-2.08-2.11c0-1.16,0.95-2.13,2.08-2.12c1.08,0,2.13,1.05,2.13,2.12
	C252.5,379.1,251.48,380.12,250.37,380.12z"
              />
              <path
                d="M262.8,191.05c-1.16-0.03-2.08-1.03-2.02-2.2c0.05-1.07,0.94-1.96,1.99-2c1.09-0.04,2.18,0.99,2.19,2.09
	C264.97,190.05,263.92,191.08,262.8,191.05z"
              />
              <path
                d="M365.24,386.18c-0.01,1.11-1.06,2.12-2.18,2.08c-1.16-0.04-2.06-1.05-1.99-2.23c0.06-1.07,0.96-1.95,2.01-1.98
	C364.18,384.03,365.25,385.08,365.24,386.18z"
              />
              <path
                d="M354.1,381.72c-0.27-0.39-0.62-0.74-0.8-1.17c-0.52-1.23,0.05-2.48,1.25-2.84c1.25-0.38,2.49,0.32,2.73,1.55
	c0.25,1.27-0.51,2.34-1.86,2.52c-0.45,0.06-0.93-0.09-1.4-0.14C354.01,381.64,354.1,381.72,354.1,381.72z"
              />
              <path
                d="M555.78,609.95c-1.12-0.01-2.13-1.06-2.1-2.17c0.04-1.11,1.13-2.11,2.23-2.04c1.05,0.07,1.91,0.98,1.94,2.05
	C557.88,608.98,556.94,609.96,555.78,609.95z"
              />
              <path
                d="M379,195.65c-1.19,0.01-2.12-0.91-2.13-2.11c-0.01-1.22,0.86-2.11,2.09-2.12c1.18-0.01,2.17,0.94,2.17,2.08
	C381.14,194.62,380.13,195.63,379,195.65z"
              />
              <path
                d="M523.82,388.48c0,0,0.08-0.08,0.08-0.08c-1.08,0.68-2.2,0.97-3.22-0.04c-0.66-0.65-0.87-1.47-0.4-2.37
	c0.76-1.46,2-1.25,3.27-0.96c0.07,0.02,0.16,0,0.23,0.01l-0.08-0.08C524.19,386.11,524.64,387.27,523.82,388.48z"
              />
              <path
                d="M380.23,502.46c-1.12,0.04-2.18-0.97-2.18-2.08c-0.01-1.1,1.07-2.15,2.16-2.12c1.05,0.03,1.95,0.91,2.01,1.98
	C382.3,501.41,381.39,502.42,380.23,502.46z"
              />
              <path
                d="M322.91,337.03c1.1,0.05,2.1,1.17,2.02,2.25c-0.08,1.13-1.18,2.06-2.3,1.95c-1.06-0.11-1.88-1.03-1.87-2.11
	C320.77,337.97,321.78,336.98,322.91,337.03z"
              />
              <path
                d="M322.52,436.93c0.06,1.1-0.94,2.18-2.05,2.21c-1.06,0.03-1.99-0.81-2.1-1.88c-0.12-1.16,0.74-2.23,1.89-2.33
	C321.36,434.83,322.46,435.8,322.52,436.93z"
              />
              <path
                d="M398.48,399.29c0.16,0.43,0.36,0.85,0.48,1.3c0.25,0.9,0.12,1.73-0.65,2.34c-0.75,0.6-1.72,0.76-2.39,0.16
	c-0.56-0.51-0.81-1.4-1.05-2.17c-0.09-0.3,0.21-0.73,0.34-1.11c0,0-0.07,0.05-0.07,0.05c1.04-0.86,2.24-0.65,3.43-0.48
	L398.48,399.29z"
              />
              <path
                d="M391.23,190.76c0.49-0.24,0.96-0.6,1.47-0.71c1.26-0.25,2.26,0.45,2.48,1.61c0.22,1.19-0.48,2.24-1.65,2.47
	c-1.22,0.24-2.43-0.59-2.54-1.86c-0.04-0.52,0.21-1.07,0.33-1.6C391.32,190.67,391.23,190.76,391.23,190.76z"
              />
              <path
                d="M340.04,511.1c0.04,1.21-0.84,2.16-2.03,2.19c-1.13,0.04-2.18-0.93-2.23-2.05c-0.05-1.13,0.92-2.14,2.08-2.18
	C339.07,509.03,340,509.89,340.04,511.1z"
              />
              <path
                d="M519.65,229.8c1.16,0.04,2.13,1.06,2.08,2.18c-0.05,1.12-1.11,2.08-2.24,2.04c-1.19-0.04-2.07-0.99-2.03-2.2
	C517.5,230.62,518.44,229.76,519.65,229.8z"
              />
              <path
                d="M478.13,460.83c1.15,0.05,2.05,1.07,1.98,2.24c-0.07,1.17-1.1,2.07-2.26,1.95c-1.03-0.11-1.93-1.09-1.91-2.11
	C475.95,461.82,477.04,460.79,478.13,460.83z"
              />
              <path
                d="M426.54,494.79c-1.15,0.04-2.14-0.92-2.14-2.09c0-1.07,0.82-2.01,1.88-2.11c1.1-0.11,2.21,0.84,2.29,1.97
	C428.65,493.67,427.67,494.75,426.54,494.79z"
              />
              <path
                d="M345.14,528.85c0.04,1.11-0.96,2.16-2.08,2.21c-1.18,0.04-2.14-0.86-2.19-2.05c-0.04-1.23,0.81-2.13,2.04-2.17
	C344.09,526.79,345.1,527.7,345.14,528.85z"
              />
              <path
                d="M492.76,550.45c-1.14,0.04-2.13-0.93-2.14-2.09c0-1.07,0.83-2.01,1.88-2.11c1.1-0.11,2.21,0.85,2.28,1.97
	C494.86,549.34,493.88,550.41,492.76,550.45z"
              />
              <path
                d="M494.85,216.12c-0.04,1.12-1.07,2.08-2.21,2.06c-1.19-0.03-2.08-0.96-2.05-2.17c0.03-1.2,0.95-2.07,2.16-2.04
	C493.92,214,494.89,215,494.85,216.12z"
              />
              <path
                d="M367.58,192.96c-1.13-0.06-2.07-1.14-1.98-2.25c0.1-1.11,1.23-2.06,2.31-1.93c1.05,0.13,1.86,1.07,1.83,2.14
	C369.72,192.09,368.72,193.03,367.58,192.96z"
              />
              <path
                d="M497.24,561c-1.11-0.01-2.13-1.06-2.09-2.16c0.04-1.1,1.14-2.1,2.22-2.03c1.05,0.07,1.91,0.98,1.94,2.04
	C499.34,560.02,498.39,561.01,497.24,561z"
              />
              <path
                d="M402.38,523.87c0.01,1.16-0.97,2.14-2.11,2.11c-1.11-0.03-2.11-1.1-2.04-2.2c0.06-1,1.03-1.96,2.02-2
	C401.37,521.73,402.37,522.72,402.38,523.87z"
              />
              <path
                d="M313.54,435.41c1.14,0.05,2.05,1.07,1.98,2.23c-0.07,1.07-0.95,1.93-2.01,1.96c-1.11,0.02-2.16-1-2.15-2.11
	C311.37,436.4,312.46,435.37,313.54,435.41z"
              />
              <path
                d="M432.12,243.29c-0.06,1.17-1.08,2.07-2.23,1.97c-1.02-0.09-1.93-1.07-1.93-2.08c0-1.09,1.07-2.13,2.16-2.11
	C431.27,241.11,432.18,242.12,432.12,243.29z"
              />
              <path
                d="M428.09,485.46c0.02,1.1-1.01,2.14-2.12,2.13c-1.06-0.01-1.95-0.87-2.03-1.94c-0.08-1.16,0.81-2.2,1.95-2.26
	C426.98,483.34,428.07,484.36,428.09,485.46z"
              />
              <path
                d="M528.57,62.98c-0.15-0.13-0.3-0.26-0.44-0.39c-0.12-0.38-0.3-0.75-0.34-1.13c-0.11-1.3,0.64-2.2,1.83-2.27
	c1.08-0.06,2.05,0.69,2.21,1.72c0.18,1.16-0.58,2.14-1.86,2.27C529.51,63.22,529.03,63.05,528.57,62.98z"
              />
              <path
                d="M417.88,390.3c1.09,0.03,2.1,1.13,2.04,2.21c-0.06,1.12-1.15,2.07-2.26,1.98c-1.06-0.09-1.89-1.01-1.89-2.07
	C415.77,391.26,416.76,390.27,417.88,390.3z"
              />
              <path
                d="M423.31,494.42c1.13-0.03,2.11,0.97,2.1,2.12c-0.01,1.07-0.84,1.98-1.9,2.07c-1.11,0.09-2.19-0.86-2.25-1.98
	C421.2,495.55,422.22,494.45,423.31,494.42z"
              />
              <path
                d="M431.91,488.76c0.05,1.11-0.93,2.16-2.04,2.18c-1.04,0.02-1.97-0.82-2.08-1.88c-0.11-1.15,0.75-2.2,1.89-2.3
	C430.76,486.67,431.85,487.65,431.91,488.76z"
              />
              <path
                d="M464.52,157.18c0.06,1.11-0.92,2.16-2.02,2.18c-1.03,0.02-1.97-0.82-2.08-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C463.38,155.11,464.46,156.07,464.52,157.18z"
              />
              <path
                d="M272.11,188.38c0.06,1.11-0.91,2.16-2.03,2.18c-1.03,0.02-1.97-0.82-2.07-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C270.96,186.3,272.05,187.27,272.11,188.38z"
              />
              <path
                d="M403.67,371.94c0.04,1.11-0.94,2.14-2.07,2.18c-1.18,0.04-2.13-0.85-2.17-2.04c-0.04-1.21,0.81-2.12,2.03-2.15
	C402.63,369.9,403.63,370.81,403.67,371.94z"
              />
              <path
                d="M346.23,377.05c0,1.14-0.98,2.12-2.11,2.08c-1.02-0.03-1.95-0.94-2.01-1.96c-0.07-1.08,0.92-2.18,2-2.21
	C345.23,374.92,346.23,375.91,346.23,377.05z"
              />
              <path
                d="M406.18,490.76c0.01-1.14,1.01-2.13,2.12-2.08c1.08,0.04,2.07,1.14,2,2.22c-0.07,1.02-1,1.93-2.02,1.96
	C407.16,492.89,406.18,491.9,406.18,490.76z"
              />
              <path
                d="M338.06,450.08c-0.05,1.15-1.07,2.07-2.2,1.97c-1.02-0.09-1.91-1.04-1.92-2.05c-0.01-1.09,1.03-2.13,2.12-2.11
	C337.19,447.92,338.12,448.94,338.06,450.08z"
              />
              <path
                d="M480.64,489.65c1.09-0.03,2.14,1,2.13,2.09c0,1.1-1.04,2.11-2.14,2.07c-1.05-0.04-1.92-0.91-1.98-1.96
	C478.59,490.7,479.51,489.68,480.64,489.65z"
              />
              <path
                d="M475.46,476.06c-1.12,0.03-2.11-0.95-2.1-2.09c0-1.14,1.01-2.13,2.12-2.09c0.98,0.04,1.94,0.99,2,1.98
	C477.54,474.98,476.58,476.03,475.46,476.06z"
              />
              <path
                d="M352.94,329.57c-0.01,1.15-1,2.1-2.13,2.06c-1.04-0.04-1.93-0.93-1.98-1.98c-0.05-1.09,0.95-2.17,2.03-2.19
	C351.97,327.43,352.95,328.43,352.94,329.57z"
              />
              <path
                d="M417.78,495.43c-0.19-0.51-0.44-1-0.54-1.53c-0.26-1.35,0.51-2.41,1.78-2.55c1.16-0.13,2.23,0.68,2.39,1.8
	c0.16,1.17-0.82,2.31-2.1,2.36c-0.53,0.02-1.07-0.11-1.61-0.16C417.71,495.35,417.78,495.43,417.78,495.43z"
              />
              <path
                d="M279.39,187.83c-0.05,1.12-1.08,2.07-2.21,2.03c-1.19-0.04-2.06-0.97-2.03-2.17c0.03-1.19,0.96-2.06,2.17-2.02
	C278.47,185.71,279.44,186.71,279.39,187.83z"
              />
              <path
                d="M412.31,512.22c-0.06,1.12-1.1,2.05-2.24,2.01c-1.19-0.05-2.05-0.99-2-2.19c0.05-1.2,0.97-2.04,2.19-1.99
	C411.41,510.09,412.37,511.11,412.31,512.22z"
              />
              <path
                d="M334.88,437.67c-1.12,0.03-2.11-0.94-2.11-2.08c0-1.14,1-2.13,2.11-2.09c0.98,0.03,1.94,0.98,2,1.98
	C336.95,436.59,335.99,437.64,334.88,437.67z"
              />
              <path
                d="M486.97,525.75c-1.18,0.02-2.11-0.89-2.13-2.07c-0.02-1.21,0.85-2.1,2.07-2.12c1.17-0.02,2.16,0.91,2.17,2.05
	C489.09,524.72,488.1,525.73,486.97,525.75z"
              />
              <path
                d="M435.25,484.92c0.06,1.11-0.91,2.16-2.03,2.18c-1.03,0.02-1.97-0.82-2.07-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C434.11,482.84,435.2,483.81,435.25,484.92z"
              />
              <path
                d="M541.79,381.88c-0.02,1.11-1.06,2.09-2.17,2.04c-1.05-0.05-1.91-0.93-1.95-1.99c-0.05-1.15,0.89-2.16,2.02-2.17
	C540.77,379.75,541.81,380.79,541.79,381.88z"
              />
              <path
                d="M439.32,116.12c-0.05,1.16-1.07,2.07-2.21,1.97c-1.02-0.09-1.92-1.05-1.93-2.06c-0.01-1.09,1.05-2.13,2.14-2.11
	C438.45,113.95,439.37,114.96,439.32,116.12z"
              />
              <path
                d="M290.15,336.35c1.17-0.03,2.17,0.88,2.2,2.02c0.03,1.1-0.97,2.14-2.08,2.17c-1.18,0.03-2.12-0.87-2.16-2.05
	C288.07,337.27,288.93,336.38,290.15,336.35z"
              />
              <path
                d="M460.91,240.75c0.01,1.14-0.96,2.13-2.09,2.11c-1.1-0.02-2.08-1.07-2.03-2.18c0.05-1,0.99-1.95,1.98-2
	C459.88,238.63,460.9,239.62,460.91,240.75z"
              />
              <path
                d="M396.17,500.73c0,0,0.09-0.1,0.09-0.1c-0.5,0.21-0.99,0.54-1.51,0.6c-1.29,0.14-2.35-0.87-2.34-2.09
	c0.01-1.13,0.99-2.06,2.15-2.06c1.2,0,2.09,0.85,2.06,2.08C396.6,499.7,396.33,500.21,396.17,500.73z"
              />
              <path
                d="M341.15,339.22c1.15,0.04,2.12,1.05,2.07,2.16c-0.05,1.12-1.09,2.06-2.22,2.02c-1.19-0.04-2.06-0.97-2.02-2.18
	C339.01,340.04,339.94,339.18,341.15,339.22z"
              />
              <path
                d="M389.94,198.24c-1.14-0.03-2.06-1.04-1.99-2.2c0.06-1.06,0.95-1.94,1.99-1.98c1.09-0.04,2.15,0.99,2.15,2.09
	C392.09,197.26,391.05,198.27,389.94,198.24z"
              />
              <path
                d="M326.74,506.43c1.09,0.05,2.06,1.15,1.98,2.24c-0.08,1.13-1.16,2.04-2.27,1.93c-1.04-0.11-1.86-1.04-1.85-2.1
	C324.62,507.36,325.64,506.38,326.74,506.43z"
              />
              <path
                d="M384.88,196.53c0.06,1.11-0.92,2.17-2.03,2.19c-1.04,0.03-1.98-0.81-2.09-1.87c-0.12-1.15,0.74-2.21,1.88-2.31
	C383.72,194.45,384.82,195.41,384.88,196.53z"
              />
              <path
                d="M476.06,479.08c1.11-0.04,2.12,0.95,2.13,2.09c0.01,1.06-0.82,1.98-1.87,2.09c-1.11,0.11-2.19-0.82-2.26-1.95
	C473.99,480.22,474.98,479.12,476.06,479.08z"
              />
              <path
                d="M250.98,387.29c-1.12-0.06-2.06-1.14-1.96-2.25c0.09-1.02,1.06-1.93,2.06-1.93c1.13-0.01,2.09,0.99,2.06,2.14
	C253.12,386.41,252.11,387.35,250.98,387.29z"
              />
              <path
                d="M346.77,530.21c1.17-0.02,2.16,0.89,2.18,2.02c0.03,1.1-0.96,2.12-2.08,2.15c-1.18,0.03-2.12-0.86-2.14-2.04
	C344.7,531.13,345.56,530.24,346.77,530.21z"
              />
              <path
                d="M332.82,345.7c-0.04,1.18-0.99,2.06-2.17,2.02c-1.13-0.04-2.1-1.07-2.06-2.17c0.04-1.14,1.03-2.03,2.21-1.99
	C332.02,343.59,332.87,344.49,332.82,345.7z"
              />
              <path
                d="M312.21,381.46c1.17-0.03,2.16,0.87,2.2,2c0.04,1.1-0.94,2.13-2.07,2.16c-1.18,0.04-2.12-0.85-2.16-2.03
	C310.14,382.39,310.99,381.49,312.21,381.46z"
              />
              <path
                d="M392.15,528.53c-0.06,1.14-1.07,2.04-2.22,2c-1.19-0.05-2.05-0.98-2-2.17c0.04-1.19,0.98-2.03,2.18-1.99
	C391.25,526.41,392.2,527.41,392.15,528.53z"
              />
              <path
                d="M431.15,481.59c0.04,1.16-0.95,2.18-2.09,2.14c-1.02-0.03-1.98-0.98-2.01-2.01c-0.04-1.12,1.01-2.18,2.12-2.14
	C430.21,479.61,431.12,480.52,431.15,481.59z"
              />
              <path
                d="M396.91,529.8c-1.12-0.03-2.09-1.13-1.99-2.25c0.09-1.04,1.08-1.94,2.1-1.91c1.15,0.04,2.07,1.09,1.98,2.25
	C398.9,528.96,397.95,529.83,396.91,529.8z"
              />
              <path
                d="M652.37,626.58c0,0,0.04,0.04,0.03,0.03c0.36,0.48,0.89,0.92,1.06,1.46c0.34,1.13-0.5,2.27-1.66,2.45
	c-1.03,0.17-2.13-0.56-2.26-1.64c-0.08-0.69,0.08-1.42,0.13-2.13c0,0,0.01,0.05,0.01,0.05
	C650.58,626.73,651.48,626.65,652.37,626.58z"
              />
              <path
                d="M534.57,382.26c0.04,1.12-0.99,2.17-2.11,2.15c-1.06-0.03-1.96-0.92-1.99-2c-0.04-1.16,0.95-2.19,2.08-2.16
	C533.56,380.27,534.54,381.25,534.57,382.26z"
              />
              <path
                d="M333.76,510.12c-1.16-0.06-2.06-1.12-1.94-2.28c0.11-1.06,1.09-1.92,2.12-1.87c1.11,0.06,2.07,1.18,1.95,2.29
	C335.78,509.31,334.79,510.17,333.76,510.12z"
              />
              <path
                d="M378.15,536.76c0.04,1.12-0.99,2.17-2.11,2.14c-1.06-0.03-1.96-0.92-1.99-2c-0.04-1.16,0.95-2.19,2.09-2.16
	C377.13,534.78,378.11,535.75,378.15,536.76z"
              />
              <path
                d="M383.21,245.73c-1.16-0.05-2.07-1.1-1.96-2.27c0.1-1.06,1.07-1.93,2.11-1.89c1.13,0.04,2.08,1.14,1.97,2.27
	C385.22,244.89,384.24,245.77,383.21,245.73z"
              />
              <path
                d="M373.57,400.02c0.04,1.16-0.95,2.18-2.09,2.14c-1.02-0.03-1.98-0.98-2.01-2.01c-0.04-1.12,1.01-2.18,2.12-2.14
	C372.63,398.04,373.54,398.95,373.57,400.02z"
              />
              <path
                d="M415.66,508.38c-0.06,1.13-1.08,2.04-2.23,1.99c-1.19-0.05-2.05-0.98-2-2.18c0.05-1.19,0.98-2.03,2.19-1.98
	C414.77,506.26,415.72,507.27,415.66,508.38z"
              />
              <path
                d="M401.13,362.74c1.15,0.04,2.11,1.04,2.05,2.16c-0.05,1.12-1.08,2.05-2.22,2.01c-1.19-0.04-2.05-0.97-2.01-2.17
	C399,363.55,399.93,362.7,401.13,362.74z"
              />
              <path
                d="M487.81,509.22c0.01,1.2-0.87,2.1-2.06,2.12c-1.14,0.01-2.14-0.95-2.16-2.06c-0.02-1.13,0.94-2.09,2.11-2.1
	C486.9,507.16,487.8,508.03,487.81,509.22z"
              />
              <path
                d="M279.06,232.85c0.14,0.14,0.29,0.28,0.43,0.41c0.11,0.61,0.35,1.24,0.29,1.84c-0.1,1.08-1.09,1.8-2.17,1.75
	c-1.11-0.06-1.92-0.87-1.97-1.97c-0.05-1.13,0.6-1.96,1.77-2.12C277.95,232.69,278.51,232.81,279.06,232.85z"
              />
              <path
                d="M343.39,512.69c-0.18-0.51-0.43-1.01-0.52-1.53c-0.26-1.35,0.51-2.4,1.79-2.54c1.16-0.12,2.23,0.69,2.37,1.81
	c0.15,1.18-0.82,2.29-2.11,2.33c-0.53,0.02-1.07-0.1-1.61-0.16C343.31,512.61,343.39,512.69,343.39,512.69z"
              />
              <path
                d="M332.12,444.54c1.03-0.04,2,0.85,2.09,1.91c0.1,1.17-0.81,2.21-1.97,2.25c-1.03,0.03-2.01-0.84-2.11-1.89
	C330.03,445.7,331.02,444.58,332.12,444.54z"
              />
              <path
                d="M249.82,372.88c-1.16-0.04-2.07-1.09-1.97-2.25c0.09-1.06,1.06-1.94,2.09-1.91c1.1,0.04,2.09,1.16,1.98,2.27
	C251.83,372.04,250.85,372.92,249.82,372.88z"
              />
              <path
                d="M413.93,492.06c-0.18-0.51-0.42-1.01-0.52-1.53c-0.26-1.35,0.51-2.4,1.79-2.54c1.16-0.12,2.23,0.69,2.37,1.81
	c0.15,1.18-0.82,2.29-2.11,2.33c-0.53,0.02-1.07-0.1-1.61-0.16C413.85,491.98,413.93,492.06,413.93,492.06z"
              />
              <path
                d="M532.07,377.21c-1.05,0.02-1.99-0.85-2.07-1.92c-0.09-1.16,0.85-2.21,1.99-2.24c1.1-0.02,2.14,1.04,2.1,2.16
	C534.05,376.24,533.1,377.19,532.07,377.21z"
              />
              <path
                d="M520.18,589.69c-0.03,1.03-0.99,1.98-2.01,2.01c-1.14,0.03-2.13-0.98-2.09-2.14c0.03-1.07,0.94-1.98,1.99-2.01
	C519.17,587.51,520.22,588.57,520.18,589.69z"
              />
              <path
                d="M487.59,514.36c0.29,0.47,0.66,0.91,0.84,1.42c0.41,1.13-0.19,2.28-1.3,2.64c-1.24,0.4-2.48-0.29-2.73-1.53
	c-0.24-1.18,0.42-2.23,1.63-2.45c0.53-0.1,1.09,0,1.64,0.01C487.67,514.44,487.59,514.36,487.59,514.36z"
              />
              <path
                d="M315.07,516.92c0,1.19-0.9,2.1-2.09,2.1c-1.13,0-2.13-0.97-2.14-2.09c-0.01-1.14,0.96-2.08,2.13-2.08
	C314.18,514.85,315.07,515.72,315.07,516.92z"
              />
              <path
                d="M324.14,62.91c-1.12,0.07-2.19-0.94-2.19-2.07c0-1.02,0.92-2.01,1.95-2.07c1.03-0.07,2.02,0.78,2.15,1.84
	C326.19,61.76,325.3,62.83,324.14,62.91z"
              />
              <path
                d="M282.73,183.92c0,1.14-1.02,2.1-2.18,2.07c-1.21-0.03-2.09-0.98-2.02-2.19c0.06-1.12,0.89-1.91,2.03-1.94
	C281.74,181.83,282.73,182.76,282.73,183.92z"
              />
              <path
                d="M354.68,334.96c-1.02-0.03-1.96-1.01-1.96-2.05c0-1.04,0.94-2.02,1.96-2.05c1.14-0.03,2.14,1.02,2.07,2.18
	C356.68,334.09,355.72,334.99,354.68,334.96z"
              />
              <path
                d="M370.2,403.87c0.03,1.15-0.97,2.16-2.09,2.12c-1-0.03-1.95-1-1.98-2.01c-0.03-1.12,1-2.16,2.1-2.12
	C369.28,401.9,370.17,402.82,370.2,403.87z"
              />
              <path
                d="M371.68,212.18c0.02,1.21-0.89,2.13-2.1,2.12c-1.16-0.01-2.15-1.02-2.1-2.15c0.04-1.06,0.93-1.94,2.02-1.99
	C370.72,210.11,371.66,210.98,371.68,212.18z"
              />
              <path
                d="M489.25,532.74c-0.62,0.04-1.26,0.17-1.87,0.11c-1.21-0.11-2.01-1.2-1.82-2.33c0.18-1.08,1.1-1.8,2.22-1.73
	c1.22,0.07,2.06,0.99,1.92,2.24c-0.07,0.61-0.35,1.2-0.53,1.79C489.17,532.83,489.25,532.74,489.25,532.74z"
              />
              <path
                d="M357.07,237.08c-1.11-0.04-2.06-1.12-1.96-2.24c0.1-1.04,1.07-1.92,2.09-1.88c1.13,0.04,2.06,1.1,1.96,2.24
	C359.06,236.25,358.1,237.12,357.07,237.08z"
              />
              <path
                d="M525.41,235.04c-0.01,1.04-0.96,2.01-1.98,2.02c-1.14,0.02-2.13-1.04-2.04-2.19c0.08-1.06,1.05-1.95,2.09-1.91
	C524.51,233.01,525.42,234,525.41,235.04z"
              />
              <path
                d="M365.82,211.15c-1.05,0.03-2-0.86-2.06-1.93c-0.06-1.07,0.78-2.05,1.84-2.15c1.13-0.1,2.19,0.89,2.19,2.05
	C367.79,210.18,366.88,211.12,365.82,211.15z"
              />
              <path
                d="M506.3,411.45c1.02-0.03,1.99,0.85,2.08,1.89c0.1,1.15-0.81,2.2-1.96,2.24c-1.02,0.04-1.99-0.83-2.09-1.88
	C504.24,412.58,505.2,411.48,506.3,411.45z"
              />
              <path
                d="M481.09,496.89c1.04-0.05,2.01,0.84,2.08,1.91c0.07,1.07-0.75,2.05-1.81,2.17c-1.13,0.12-2.2-0.86-2.21-2.01
	C479.14,497.89,480.03,496.94,481.09,496.89z"
              />
              <path
                d="M506.47,225.93c-0.01,1.06-0.93,2-1.98,2.02c-1.15,0.02-2.12-1.02-2.04-2.19c0.07-1.07,1.05-1.95,2.09-1.9
	C505.59,223.91,506.48,224.87,506.47,225.93z"
              />
              <path
                d="M434.49,477.75c0.04,1.14-0.96,2.15-2.08,2.12c-1-0.03-1.94-0.98-1.97-1.99c-0.03-1.13,0.97-2.15,2.08-2.12
	C433.56,475.8,434.46,476.71,434.49,477.75z"
              />
              <path
                d="M413.39,400.06c0.02,1.15-1,2.15-2.13,2.1c-1.01-0.05-1.95-1.02-1.97-2.04c-0.02-1.11,1.05-2.15,2.15-2.1
	C412.49,398.07,413.38,399,413.39,400.06z"
              />
              <path
                d="M387.35,501.7c-1.17,0-2.14-0.97-2.1-2.11c0.04-1.16,1.03-2.04,2.22-1.98c1.12,0.06,1.92,0.87,1.95,1.98
	C389.46,500.79,388.56,501.7,387.35,501.7z"
              />
              <path
                d="M414.29,206.39c-0.15-0.6-0.4-1.2-0.43-1.8c-0.06-1.21,0.85-2.08,2.05-2.08c1.08,0,1.99,0.79,2.1,1.84
	c0.12,1.12-0.73,2.16-1.93,2.22c-0.61,0.03-1.24-0.15-1.86-0.23C414.22,206.33,414.29,206.39,414.29,206.39z"
              />
              <path
                d="M378.39,204.45c0.05,1.2-0.82,2.13-2.04,2.15c-1.17,0.02-2.15-0.93-2.14-2.08c0.01-1.06,0.86-1.94,1.95-2.02
	C377.38,202.43,378.34,203.26,378.39,204.45z"
              />
              <path
                d="M319.17,21.67c0.06,1.04-0.78,1.99-1.85,2.11c-1.21,0.13-2.22-0.7-2.28-1.88c-0.06-1.1,0.71-1.99,1.83-2.1
	C318.07,19.67,319.1,20.5,319.17,21.67z"
              />
              <path
                d="M367.13,181.62c1.02-0.04,1.99,0.82,2.09,1.86c0.12,1.14-0.79,2.19-1.93,2.25c-1.02,0.05-1.99-0.8-2.09-1.85
	C365.09,182.75,366.01,181.67,367.13,181.62z"
              />
              <path
                d="M392.61,76.91c0,1.13-1.01,2.1-2.18,2.08c-1.21-0.03-2.09-0.98-2.03-2.19c0.06-1.12,0.88-1.91,2.02-1.94
	C391.62,74.82,392.61,75.74,392.61,76.91z"
              />
              <path
                d="M233.43,200.68c-0.14-0.11-0.28-0.22-0.43-0.33c0-1.02,0-2.05,0.01-3.07c0,0-0.08,0.1-0.08,0.1
	c0.43-0.19,0.85-0.46,1.31-0.55c1.39-0.27,2.47,0.5,2.59,1.76c0.11,1.18-0.67,2.09-1.97,2.21
	C234.39,200.85,233.91,200.73,233.43,200.68z"
              />
              <path
                d="M236.74,243.81c0.17,0.44,0.42,0.87,0.5,1.33c0.21,1.34-0.66,2.45-1.91,2.5c-1.03,0.04-2.01-0.84-2.1-1.89
	c-0.11-1.24,0.91-2.29,2.23-2.22c0.46,0.02,0.92,0.24,1.37,0.37C236.82,243.89,236.74,243.81,236.74,243.81z"
              />
              <path
                d="M264.17,185.07c0.03-1.14,1.08-2.1,2.19-2c1.02,0.09,1.89,1.06,1.86,2.09c-0.02,1.01-0.96,1.97-1.96,2.01
	C265.15,187.21,264.15,186.21,264.17,185.07z"
              />
              <path
                d="M372.81,392.79c0.05,1.06-0.81,2.05-1.85,2.13c-1.13,0.1-2.19-0.91-2.18-2.06c0.01-1.06,0.94-2,1.98-2.02
	C371.8,390.82,372.76,391.73,372.81,392.79z"
              />
              <path
                d="M451.98,472.43c0.05,1.07-0.79,2.05-1.84,2.14c-1.13,0.1-2.19-0.9-2.19-2.05c0.01-1.05,0.93-2,1.97-2.03
	C450.97,470.46,451.93,471.37,451.98,472.43z"
              />
              <path
                d="M255.59,358c-1.15-0.03-2.07-1.08-1.98-2.23c0.09-1.05,1.05-1.94,2.08-1.91c1.1,0.03,2.08,1.14,1.99,2.24
	C257.59,357.15,256.62,358.03,255.59,358z"
              />
              <path
                d="M340.45,391.9c0.02,1.14-1,2.14-2.12,2.09c-1.01-0.05-1.94-1.02-1.95-2.03c-0.02-1.12,1.03-2.14,2.13-2.09
	C339.55,389.92,340.44,390.85,340.45,391.9z"
              />
              <path
                d="M329.74,436.19c0.07,1.13-0.89,2.15-2.05,2.19c-1.1,0.03-2.01-0.75-2.13-1.83c-0.13-1.2,0.69-2.19,1.89-2.28
	C328.65,434.17,329.67,435.03,329.74,436.19z"
              />
              <path
                d="M253.83,372.1c1.11,0.07,2.05,1.19,1.92,2.3c-0.12,1.05-1.12,1.9-2.14,1.83c-1.15-0.07-2.04-1.14-1.91-2.29
	C251.82,372.89,252.81,372.04,253.83,372.1z"
              />
              <path
                d="M265.9,393.04c-1.11-0.04-2.08-1.13-1.97-2.25c0.1-1.04,1.08-1.93,2.1-1.89c1.14,0.04,2.06,1.1,1.96,2.25
	C267.9,392.21,266.94,393.07,265.9,393.04z"
              />
              <path
                d="M683.01,595.13c0.3-0.04,0.64-0.01,0.9-0.13c0.87-0.4,1.57-0.17,2.07,0.58c0.49,0.76,0.75,1.61,0.22,2.46
	c-0.55,0.88-1.36,0.98-2.29,0.64c-0.29-0.11-0.6-0.17-0.9-0.26c0,0,0.1,0.08,0.1,0.08c0-1.15,0-2.3,0-3.44
	C683.1,595.07,683.01,595.13,683.01,595.13z"
              />
              <path
                d="M373.88,193.99c0,1.15-0.99,2.08-2.16,2.05c-1.21-0.03-2.08-0.97-2.01-2.17c0.06-1.11,0.89-1.89,2.02-1.92
	C372.91,191.92,373.88,192.83,373.88,193.99z"
              />
              <path
                d="M317.42,442.93c-1.02,0.06-2.02-0.79-2.14-1.83c-0.13-1.11,0.81-2.23,1.92-2.3c1.03-0.06,2.01,0.79,2.13,1.84
	C319.46,441.79,318.57,442.86,317.42,442.93z"
              />
              <path
                d="M423.24,388.52c0.03,1.12-1.02,2.16-2.13,2.11c-1.05-0.04-1.94-0.95-1.97-2.01c-0.03-1.15,0.98-2.17,2.1-2.13
	C422.25,386.53,423.22,387.51,423.24,388.52z"
              />
              <path
                d="M337.56,442.89c-0.05,1.06-1.01,1.97-2.05,1.95c-1.15-0.02-2.08-1.09-1.96-2.26c0.11-1.07,1.11-1.92,2.15-1.82
	C336.76,440.85,337.61,441.82,337.56,442.89z"
              />
              <path
                d="M357.28,338c-0.18-0.59-0.44-1.18-0.52-1.79c-0.14-1.23,0.71-2.17,1.92-2.24c1.11-0.06,2.04,0.66,2.21,1.73
	c0.19,1.14-0.61,2.22-1.83,2.33c-0.62,0.06-1.25-0.07-1.87-0.12C357.2,337.92,357.28,338,357.28,338z"
              />
              <path
                d="M368.12,335.61c-0.04,1.15-1.05,2.04-2.23,1.98c-1.2-0.07-2.05-1.04-1.94-2.23c0.1-1.08,0.97-1.87,2.08-1.86
	C367.21,333.5,368.16,334.46,368.12,335.61z"
              />
              <path
                d="M341.4,446.08c0.04,1.14-0.95,2.15-2.07,2.12c-1-0.03-1.94-0.98-1.97-1.99c-0.04-1.13,0.97-2.15,2.08-2.12
	C340.47,444.13,341.37,445.04,341.4,446.08z"
              />
              <path
                d="M433.63,494.26c-1.05-0.02-1.97-0.95-1.99-2.01c-0.02-1.16,1.03-2.16,2.17-2.07c1.05,0.08,1.91,1.07,1.86,2.13
	C435.62,493.36,434.67,494.28,433.63,494.26z"
              />
              <path
                d="M278.7,387.7c-0.47,0.28-0.91,0.62-1.41,0.82c-1.14,0.47-2.26-0.06-2.67-1.19c-0.4-1.1,0.18-2.27,1.31-2.64
	c1.08-0.36,2.23,0.27,2.55,1.46c0.14,0.52,0.1,1.08,0.14,1.63C278.62,387.77,278.7,387.7,278.7,387.7z"
              />
              <path
                d="M317.87,450.13c-1.03,0.05-2.01-0.82-2.12-1.86c-0.12-1.11,0.84-2.23,1.95-2.28c1.03-0.05,2,0.82,2.11,1.87
	C319.93,449.02,319.02,450.08,317.87,450.13z"
              />
              <path
                d="M486.1,447.97c0.05,1.12-0.96,2.18-2.07,2.16c-1.05-0.01-1.96-0.91-2.01-1.97c-0.05-1.15,0.92-2.19,2.05-2.17
	C485.07,446,486.05,446.96,486.1,447.97z"
              />
              <path
                d="M472.92,171.07c-0.06,1.2-1.03,2.04-2.25,1.94c-1.07-0.08-1.94-0.99-1.94-2.04c-0.01-1.15,0.97-2.09,2.16-2.06
	C472.12,168.93,472.98,169.86,472.92,171.07z"
              />
              <path
                d="M397.13,359.63c1.01-0.04,2,0.85,2.1,1.89c0.1,1.12-0.86,2.21-1.97,2.25c-1.04,0.04-1.99-0.83-2.09-1.89
	C395.07,360.73,395.99,359.67,397.13,359.63z"
              />
              <path
                d="M394.27,82.1c-1.15-0.03-2.07-1.08-1.98-2.23c0.09-1.05,1.05-1.94,2.08-1.91c1.1,0.03,2.08,1.14,1.99,2.24
	C396.26,81.25,395.3,82.12,394.27,82.1z"
              />
              <path
                d="M416.57,502.39c1.21-0.03,2.13,0.87,2.13,2.07c0,1.11-0.8,1.96-1.91,2.04c-1.18,0.09-2.21-0.79-2.28-1.94
	C414.45,503.41,415.39,502.42,416.57,502.39z"
              />
              <path
                d="M345.25,449.44c0.04,1.15-0.96,2.17-2.09,2.14c-1.01-0.03-1.96-0.99-2-2.01c-0.04-1.12,1-2.17,2.1-2.13
	C344.32,447.47,345.22,448.38,345.25,449.44z"
              />
              <path
                d="M371.96,505.04c-0.07,1.15-1.09,2.02-2.27,1.93c-1.19-0.09-2.02-1.09-1.89-2.28c0.12-1.08,1.02-1.84,2.12-1.82
	C371.08,502.9,372.02,503.9,371.96,505.04z"
              />
              <path
                d="M370.23,181.88c-1.06-0.06-1.94-1.02-1.92-2.07c0.02-1.15,1.11-2.12,2.23-1.99c1.06,0.12,1.87,1.11,1.79,2.18
	C372.24,181.06,371.27,181.94,370.23,181.88z"
              />
              <path
                d="M323.97,451.23c-0.03,1.15-1.03,2.06-2.21,2c-1.21-0.06-2.06-1.02-1.97-2.21c0.08-1.09,0.94-1.87,2.06-1.88
	C323.05,449.13,324,450.08,323.97,451.23z"
              />
              <path
                d="M371.16,331.54c-0.04,1.05-0.94,1.96-1.98,1.99c-1.1,0.03-2.12-1.01-2.09-2.13c0.04-1.01,1-1.97,1.99-2
	C370.21,329.37,371.2,330.4,371.16,331.54z"
              />
              <path
                d="M422.68,487.51c1.18,0.02,2.14,1.03,2.08,2.17c-0.06,1.15-1.11,2.04-2.28,1.96c-1.11-0.08-1.91-0.93-1.92-2.05
	C420.55,488.38,421.46,487.48,422.68,487.51z"
              />
              <path
                d="M316.71,451.97c-0.03,1.02-0.98,1.98-2,2.01c-1.13,0.03-2.12-0.98-2.09-2.14c0.03-1.06,0.94-1.97,1.98-2.01
	C315.71,449.8,316.75,450.85,316.71,451.97z"
              />
              <path
                d="M482.71,451.97c-0.03,1.02-0.97,1.96-1.97,1.99c-1.12,0.03-2.11-0.99-2.07-2.12c0.03-1.05,0.94-1.95,1.97-1.99
	C481.75,449.82,482.75,450.84,482.71,451.97z"
              />
              <path
                d="M340.16,451.29c1.02,0.04,1.95,1.02,1.95,2.06c0,1.04-0.95,2.01-1.97,2.04c-1.14,0.03-2.14-1.02-2.06-2.18
	C338.15,452.16,339.13,451.26,340.16,451.29z"
              />
              <path
                d="M334.95,453.86c-0.02,1.02-0.95,1.99-1.97,2.04c-1.13,0.05-2.14-0.95-2.12-2.1c0.02-1.06,0.9-1.99,1.95-2.04
	C333.91,451.7,334.97,452.74,334.95,453.86z"
              />
              <path
                d="M474.98,468.85c-1.04,0.03-1.99-0.84-2.07-1.9c-0.09-1.15,0.84-2.21,1.98-2.24c1.1-0.03,2.14,1.03,2.1,2.14
	C476.94,467.87,475.99,468.83,474.98,468.85z"
              />
              <path
                d="M523.78,385.03c-0.18-0.59-0.44-1.18-0.51-1.79c-0.14-1.23,0.71-2.17,1.92-2.24c1.11-0.06,2.04,0.66,2.21,1.74
	c0.19,1.14-0.61,2.22-1.83,2.33c-0.62,0.06-1.25-0.07-1.88-0.12C523.7,384.95,523.78,385.03,523.78,385.03z"
              />
              <path
                d="M320.53,455.22c0.03,1.12-0.99,2.14-2.1,2.1c-1.04-0.04-1.93-0.96-1.95-2c-0.03-1.14,0.98-2.15,2.09-2.11
	C319.56,453.24,320.51,454.21,320.53,455.22z"
              />
              <path
                d="M477.52,457.79c-1.02-0.03-1.96-1.02-1.96-2.05c0-1.04,0.94-2.02,1.96-2.05c1.14-0.03,2.14,1.02,2.07,2.18
	C479.52,456.93,478.56,457.83,477.52,457.79z"
              />
              <path
                d="M356.75,225.78c1.06,0.06,1.94,1.01,1.93,2.07c-0.02,1.16-1.1,2.13-2.23,2c-1.06-0.12-1.87-1.1-1.79-2.18
	C354.73,226.61,355.71,225.72,356.75,225.78z"
              />
              <path
                d="M455.46,136.41c0,1.14-1,2.09-2.17,2.06c-1.22-0.03-2.09-0.97-2.02-2.18c0.06-1.12,0.88-1.91,2.02-1.94
	C454.48,134.33,455.46,135.25,455.46,136.41z"
              />
              <path
                d="M373.45,383.9c-1.02,0-1.96-0.9-2.02-1.93c-0.07-1.12,0.91-2.18,2.01-2.18c1.03,0,1.96,0.89,2.02,1.94
	C375.54,382.87,374.58,383.9,373.45,383.9z"
              />
              <path
                d="M227.65,241.12c-1.15,0.02-2.14-0.97-2.1-2.11c0.04-1.06,0.92-1.9,2.03-1.92c1.14-0.02,1.98,0.73,2.06,1.85
	C229.72,240.14,228.85,241.1,227.65,241.12z"
              />
              <path
                d="M305.31,508.39c1.04,0,1.99,0.92,2.03,1.98c0.04,1.16-0.98,2.18-2.11,2.12c-1.03-0.06-1.93-1.05-1.91-2.09
	C303.33,509.35,304.28,508.39,305.31,508.39z"
              />
              <path
                d="M254.27,383.43c-1.02,0.04-1.98-0.83-2.08-1.87c-0.11-1.13,0.81-2.19,1.94-2.24c1.02-0.04,1.98,0.82,2.08,1.87
	C256.32,382.31,255.39,383.39,254.27,383.43z"
              />
              <path
                d="M421.61,105.11c-1.05-0.04-1.96-0.98-1.96-2.04c0-1.16,1.06-2.15,2.19-2.04c1.06,0.1,1.89,1.07,1.83,2.15
	C423.61,104.25,422.66,105.14,421.61,105.11z"
              />
              <path
                d="M332,528.35c-1.04,0.04-1.99-0.83-2.09-1.89c-0.1-1.15,0.82-2.21,1.96-2.25c1.01-0.04,2,0.85,2.1,1.89
	C334.08,527.22,333.11,528.31,332,528.35z"
              />
              <path
                d="M405.75,187.8c-0.02,1.2-0.98,2.09-2.18,2.03c-1.07-0.05-1.98-0.94-2.02-2c-0.05-1.15,0.91-2.13,2.1-2.14
	C404.87,185.68,405.77,186.58,405.75,187.8z"
              />
              <path
                d="M341.35,328.88c-0.62,0.02-1.26,0.12-1.87,0.04c-1.08-0.14-1.85-1.17-1.75-2.19c0.11-1.15,1.16-1.99,2.35-1.87
	c1.11,0.11,1.94,0.96,1.83,2.08c-0.07,0.69-0.41,1.35-0.62,2.02C341.28,328.96,341.35,328.88,341.35,328.88z"
              />
              <path
                d="M476.23,459.81c-0.12,1.04-1.1,1.89-2.11,1.83c-1.12-0.06-2.02-1.15-1.9-2.27c0.12-1.04,1.1-1.89,2.11-1.83
	C475.46,457.6,476.35,458.68,476.23,459.81z"
              />
              <path
                d="M616.34,622.4c0.16,0.18,0.33,0.36,0.49,0.53c0.31,1.08,0.7,2.28-0.47,3.01c-0.58,0.36-1.54,0.48-2.19,0.27
	c-1.31-0.43-1.27-1.65-1.14-2.8c0,0-0.07,0.07-0.07,0.07C613.83,622.29,615.06,622.27,616.34,622.4z"
              />
              <path
                d="M397.22,381c-0.48,0.27-0.93,0.58-1.43,0.79c-1.05,0.42-2.14-0.04-2.58-1.03c-0.48-1.1,0.04-2.31,1.18-2.72
	c0.99-0.36,2.17,0.14,2.52,1.18c0.19,0.58,0.16,1.24,0.23,1.86C397.15,381.07,397.22,381,397.22,381z"
              />
              <path
                d="M633.28,624.5c1.01,0,1.97,0.92,2.03,1.95c0.06,1.12-0.93,2.18-2.04,2.18c-1.04,0-1.96-0.89-2.02-1.95
	C631.19,625.53,632.15,624.5,633.28,624.5z"
              />
              <path
                d="M497.32,228.42c-1.05,0.04-2-0.85-2.07-1.92c-0.07-1.08,0.76-2.05,1.82-2.16c1.13-0.11,2.2,0.88,2.2,2.03
	C499.27,227.44,498.37,228.38,497.32,228.42z"
              />
              <path
                d="M362.51,64.99c-0.04,1.09-0.91,1.91-2.03,1.91c-1.21,0-2.1-0.97-1.99-2.17c0.1-1.1,1-1.86,2.13-1.79
	C361.72,63,362.55,63.9,362.51,64.99z"
              />
              <path
                d="M447.28,485.62c-1.02-0.04-1.94-1.02-1.94-2.05c0.01-1.04,0.94-2.01,1.96-2.03c1.13-0.02,2.13,1.03,2.05,2.18
	C449.28,484.76,448.31,485.66,447.28,485.62z"
              />
              <path
                d="M530.87,304.7c0.24,0.49,0.55,0.97,0.7,1.49c0.34,1.22-0.33,2.31-1.5,2.53c-1.11,0.21-2.14-0.42-2.41-1.46
	c-0.29-1.14,0.41-2.26,1.64-2.47c0.54-0.09,1.11-0.01,1.66-0.01C530.95,304.78,530.87,304.7,530.87,304.7z"
              />
              <path
                d="M337.78,529.38c0.01,1.12-0.8,1.97-1.92,2c-1.11,0.04-2.02-0.77-2.09-1.85c-0.08-1.16,0.87-2.15,2.05-2.13
	C336.93,527.43,337.77,528.27,337.78,529.38z"
              />
              <path
                d="M388.73,532.16c0.07,1.14-0.86,2.13-2.03,2.15c-1.09,0.03-1.99-0.74-2.11-1.81c-0.13-1.18,0.7-2.17,1.88-2.25
	C387.66,530.16,388.66,531.01,388.73,532.16z"
              />
              <path
                d="M448.14,469.53c-0.06,1.11-0.92,1.89-2.06,1.86c-1.12-0.03-1.96-0.87-1.96-1.97c0-1.19,0.98-2.08,2.19-1.98
	C447.42,467.53,448.2,468.43,448.14,469.53z"
              />
              <path
                d="M528.06,350.41c0.01,1.18-0.93,2.06-2.14,2.01c-1.1-0.05-1.91-0.89-1.92-1.98c-0.01-1.09,0.8-1.94,1.9-2
	C527.11,348.36,528.06,349.23,528.06,350.41z"
              />
              <path
                d="M281.54,406.2c-1.2-0.01-2.08-0.93-2.03-2.12c0.04-1.1,0.85-1.9,1.97-1.94c1.19-0.05,2.16,0.84,2.18,1.99
	C283.66,405.28,282.71,406.21,281.54,406.2z"
              />
              <path
                d="M264.36,347.92c0.06,1.12-0.72,2-1.84,2.08c-1.22,0.08-2.19-0.8-2.18-1.99c0.01-1.1,0.86-1.94,1.97-1.97
	C263.44,346.01,264.31,346.81,264.36,347.92z"
              />
              <path
                d="M423.92,501.7c1.05,0.06,1.94,1.01,1.92,2.06c-0.02,1.14-1.1,2.12-2.22,1.99c-1.06-0.12-1.86-1.09-1.78-2.17
	C421.92,502.51,422.88,501.64,423.92,501.7z"
              />
              <path
                d="M527.86,370.01c1.2-0.05,2.14,0.85,2.13,2.03c-0.01,1.1-0.82,1.93-1.94,1.97c-1.21,0.05-2.15-0.84-2.13-2.02
	C525.93,370.9,526.77,370.05,527.86,370.01z"
              />
              <path
                d="M442.18,484.33c-0.02,1.19-0.97,2.04-2.19,1.96c-1.1-0.07-1.91-0.94-1.89-2.03c0.02-1.18,0.99-2.04,2.19-1.96
	C441.4,482.37,442.2,483.24,442.18,484.33z"
              />
              <path
                d="M448.89,476.53c0.05,1.08-0.74,1.97-1.83,2.07c-1.21,0.11-2.18-0.72-2.23-1.9c-0.04-1.09,0.74-1.97,1.83-2.07
	C447.86,474.52,448.84,475.36,448.89,476.53z"
              />
              <path
                d="M530.7,379.17c0.02,1.1-0.77,1.95-1.87,2.02c-1.21,0.08-2.16-0.77-2.18-1.95c-0.02-1.09,0.78-1.95,1.87-2.03
	C529.73,377.13,530.68,377.99,530.7,379.17z"
              />
              <path
                d="M318.38,346.94c0.06,1.09-0.71,1.99-1.8,2.1c-1.21,0.13-2.21-0.7-2.27-1.87c-0.06-1.08,0.73-1.99,1.81-2.1
	C317.31,344.95,318.31,345.77,318.38,346.94z"
              />
              <path
                d="M472.88,463.54c0.06,1.08-0.71,1.98-1.8,2.1c-1.2,0.13-2.19-0.69-2.26-1.87c-0.06-1.08,0.71-1.99,1.8-2.1
	C471.81,461.55,472.81,462.38,472.88,463.54z"
              />
              <path
                d="M415.33,89.34c0.02,1.19-0.88,2.09-2.08,2.08c-1.17-0.01-2.11-0.97-2.07-2.11c0.04-1.06,0.9-1.9,1.98-1.96
	C414.37,87.3,415.3,88.15,415.33,89.34z"
              />
              <path
                d="M527.55,327c-1.21,0.04-2.16-0.88-2.14-2.06c0.02-1.08,0.88-1.94,1.98-1.98c1.21-0.04,2.15,0.87,2.13,2.06
	C529.5,326.14,528.68,326.96,527.55,327z"
              />
              <path
                d="M377.3,408.35c0.25,0.49,0.57,0.95,0.73,1.46c0.36,1.17-0.29,2.28-1.43,2.55c-1.18,0.28-2.29-0.4-2.53-1.53
	c-0.24-1.15,0.46-2.22,1.67-2.41c0.53-0.09,1.1,0.01,1.64,0.02C377.39,408.43,377.3,408.35,377.3,408.35z"
              />
              <path
                d="M383.42,494.5c1.03-0.06,2.02,0.81,2.11,1.87c0.1,1.14-0.87,2.21-2.01,2.21c-1.03,0-1.97-0.94-1.99-1.99
	C381.5,495.52,382.38,494.55,383.42,494.5z"
              />
              <path
                d="M527.85,596c0.05,1.09-0.74,1.99-1.84,2.09c-1.22,0.11-2.2-0.72-2.26-1.9c-0.05-1.09,0.74-1.99,1.83-2.1
	C526.79,593.98,527.79,594.82,527.85,596z"
              />
              <path
                d="M506.87,570.54c0.24,0.49,0.56,0.95,0.71,1.46c0.35,1.18-0.3,2.28-1.43,2.54c-1.18,0.27-2.28-0.4-2.52-1.54
	c-0.24-1.15,0.47-2.21,1.68-2.4c0.53-0.08,1.09,0.01,1.64,0.02C506.96,570.62,506.87,570.54,506.87,570.54z"
              />
              <path
                d="M258.24,119.82c0.5-0.38,0.94-0.87,1.5-1.11c1.03-0.44,2.13,0.08,2.55,1.06c0.42,0.99-0.01,2.18-0.93,2.61
	c-1.05,0.49-2.3,0.06-2.76-1.03c-0.2-0.49-0.2-1.07-0.29-1.6C258.3,119.76,258.24,119.82,258.24,119.82z"
              />
              <path
                d="M373.05,206.35c1.2,0.05,2.06,1,1.98,2.19c-0.08,1.09-0.93,1.88-2.04,1.89c-1.18,0.01-2.14-0.93-2.12-2.08
	C370.89,207.2,371.86,206.31,373.05,206.35z"
              />
              <path
                d="M529.5,364.76c0.03,1.11-0.78,1.98-1.91,2.04c-1.14,0.06-2.03-0.7-2.13-1.81c-0.1-1.2,0.81-2.17,2.02-2.17
	C528.6,362.83,529.47,363.66,529.5,364.76z"
              />
              <path
                d="M475.3,183.55c-1.2-0.06-2.05-1.03-1.95-2.22c0.1-1.18,1.09-1.97,2.3-1.84c1.08,0.11,1.89,1.02,1.85,2.08
	C477.47,182.73,476.48,183.62,475.3,183.55z"
              />
              <path
                d="M397.36,173.87c-0.02,1.18-1.01,2.07-2.22,1.99c-1.12-0.07-1.92-0.93-1.91-2.05c0.02-1.21,0.97-2.07,2.2-1.99
	C396.54,171.89,397.37,172.78,397.36,173.87z"
              />
              <path
                d="M261.07,351.89c-0.02,1.18-1,2.06-2.21,1.98c-1.12-0.07-1.92-0.93-1.9-2.04c0.02-1.2,0.97-2.06,2.2-1.98
	C260.26,349.92,261.09,350.8,261.07,351.89z"
              />
              <path
                d="M429.34,107.52c1.2-0.01,2.14,0.95,2.08,2.12c-0.06,1.08-0.94,1.91-2.05,1.92c-1.21,0.01-2.12-0.93-2.06-2.12
	C427.36,108.34,428.21,107.53,429.34,107.52z"
              />
              <path
                d="M338.01,347.17c-1.18,0.04-2.15-0.86-2.16-2.02c-0.01-1.15,0.94-2.07,2.13-2.05c1.11,0.02,1.95,0.8,2.02,1.9
	C340.07,346.19,339.21,347.13,338.01,347.17z"
              />
              <path
                d="M406.18,361.07c-0.05,1.09-0.94,1.91-2.06,1.89c-1.21-0.02-2.09-1-1.97-2.2c0.11-1.1,1.03-1.85,2.16-1.77
	C405.42,359.07,406.23,359.98,406.18,361.07z"
              />
              <path
                d="M425.85,377.23c0.03,1.1-0.78,1.97-1.9,2.03c-1.15,0.06-2.02-0.68-2.11-1.8c-0.1-1.2,0.8-2.16,2-2.16
	C424.94,375.31,425.81,376.14,425.85,377.23z"
              />
              <path
                d="M327.03,447.37c-0.01,1.2-0.98,2.07-2.2,2c-1.1-0.07-1.94-0.96-1.93-2.05c0.01-1.18,1.01-2.07,2.22-1.99
	C326.24,445.4,327.04,446.26,327.03,447.37z"
              />
              <path
                d="M342.19,60.99c1.19-0.04,2.13,0.88,2.1,2.05c-0.03,1.09-0.85,1.91-1.96,1.94c-1.21,0.04-2.12-0.85-2.09-2.04
	C340.26,61.86,341.1,61.03,342.19,60.99z"
              />
              <path
                d="M443.61,478.45c1.11,0.05,1.92,0.89,1.93,1.99c0.01,1.19-0.93,2.07-2.14,2.01c-1.1-0.05-1.93-0.9-1.93-1.99
	C441.45,479.29,442.41,478.4,443.61,478.45z"
              />
              <path
                d="M418.91,484.18c1.17,0.06,2.07,1.07,1.97,2.2c-0.1,1.05-1,1.86-2.08,1.86c-1.2,0-2.1-0.91-2.06-2.09
	C416.77,484.97,417.71,484.12,418.91,484.18z"
              />
              <path
                d="M392.75,495.89c-0.05,1.17-1.02,2.01-2.23,1.9c-1.09-0.09-1.87-0.97-1.83-2.06c0.05-1.17,1.03-2.02,2.23-1.91
	C392,493.92,392.79,494.81,392.75,495.89z"
              />
              <path
                d="M411.53,488.68c-1.11-0.04-1.93-0.85-1.96-1.94c-0.03-1.17,0.91-2.09,2.1-2.05c1.09,0.03,1.93,0.87,1.96,1.95
	C413.65,487.83,412.74,488.72,411.53,488.68z"
              />
              <path
                d="M487.48,441.99c1.09,0.04,1.93,0.87,1.95,1.96c0.03,1.19-0.89,2.08-2.1,2.03c-1.11-0.04-1.93-0.86-1.96-1.95
	C485.36,442.86,486.29,441.95,487.48,441.99z"
              />
              <path
                d="M321.18,441.98c1.11,0.02,1.95,0.83,1.99,1.92c0.05,1.18-0.87,2.1-2.08,2.08c-1.1-0.02-1.96-0.84-2-1.93
	C319.06,442.89,319.98,441.96,321.18,441.98z"
              />
              <path
                d="M408.14,366.36c-1.13-0.05-1.95-0.88-1.96-2c-0.01-1.2,0.93-2.1,2.15-2.05c1.1,0.05,1.96,0.92,1.98,2
	C410.33,365.49,409.35,366.41,408.14,366.36z"
              />
              <path
                d="M429.2,500.01c-0.06,1.05-1.03,1.95-2.06,1.92c-1.15-0.03-2.05-1.1-1.92-2.27c0.12-1.06,1.12-1.9,2.15-1.79
	C428.42,497.97,429.26,498.96,429.2,500.01z"
              />
              <path
                d="M284.5,189.31c-1.21,0.03-2.15-0.9-2.11-2.09c0.03-1.12,0.87-1.93,2-1.96c1.21-0.03,2.17,0.91,2.13,2.09
	C286.48,188.42,285.6,189.28,284.5,189.31z"
              />
              <path
                d="M449.62,135.04c-1.12,0.06-2.04-0.72-2.14-1.81c-0.1-1.17,0.83-2.17,2.02-2.17c1.12,0,1.98,0.83,2.01,1.93
	C451.55,134.12,450.75,134.98,449.62,135.04z"
              />
              <path
                d="M422.78,175.25c0.01,1.09-0.82,1.97-1.92,2.04c-1.22,0.07-2.18-0.8-2.19-1.99c-0.01-1.11,0.8-1.96,1.91-2.03
	C421.78,173.19,422.76,174.08,422.78,175.25z"
              />
              <path
                d="M330.37,443.42c0.05,1.08-0.74,1.97-1.83,2.07c-1.21,0.11-2.18-0.72-2.23-1.9c-0.04-1.09,0.74-1.97,1.83-2.07
	C329.34,441.41,330.32,442.25,330.37,443.42z"
              />
              <path
                d="M397.79,200.63c1.1,0.05,1.91,0.9,1.92,1.99c0,1.19-0.92,2.05-2.14,1.99c-1.11-0.05-1.91-0.88-1.92-1.98
	C395.65,201.46,396.59,200.57,397.79,200.63z"
              />
              <path
                d="M525.48,341.2c1.21-0.04,2.15,0.88,2.12,2.07c-0.03,1.11-0.85,1.93-1.98,1.97c-1.21,0.04-2.16-0.88-2.13-2.06
	C523.52,342.09,524.38,341.23,525.48,341.2z"
              />
              <path
                d="M277.8,213.12c0.04,1.13-0.74,1.98-1.87,2.05c-1.22,0.07-2.17-0.84-2.14-2.03c0.03-1.09,0.9-1.93,2-1.94
	C276.9,211.19,277.76,212.01,277.8,213.12z"
              />
              <path
                d="M424.64,180.62c-1.12,0.04-2.03-0.75-2.11-1.84c-0.09-1.17,0.85-2.16,2.05-2.14c1.12,0.01,1.97,0.85,1.99,1.96
	C426.58,179.73,425.77,180.58,424.64,180.62z"
              />
              <path
                d="M319.62,511.28c-1.16-0.04-2.08-1.05-2-2.18c0.08-1.15,1.09-1.98,2.28-1.88c1.09,0.1,1.87,0.95,1.86,2.05
	C321.75,510.44,320.81,511.33,319.62,511.28z"
              />
              <path
                d="M385.3,536.16c-0.03,1.11-0.87,1.94-2,1.94c-1.12,0-1.99-0.82-2.03-1.92c-0.04-1.19,0.92-2.12,2.13-2.06
	C384.51,534.18,385.32,535.05,385.3,536.16z"
              />
              <path
                d="M375,394.02c1.1,0.05,1.93,0.94,1.9,2.03c-0.03,1.19-1.02,2.04-2.24,1.92c-1.1-0.11-1.86-1.02-1.77-2.13
	C372.98,394.73,373.87,393.97,375,394.02z"
              />
              <path
                d="M369.11,539.57c-1.1-0.01-1.98-0.83-2.03-1.91c-0.06-1.17,0.87-2.12,2.07-2.11c1.11,0.01,1.97,0.82,2.02,1.91
	C371.23,538.63,370.31,539.57,369.11,539.57z"
              />
              <path
                d="M261,185.82c0.01,1.12-0.8,1.97-1.92,2c-1.11,0.03-2.02-0.77-2.09-1.85c-0.08-1.16,0.87-2.15,2.05-2.13
	C260.16,183.87,260.99,184.71,261,185.82z"
              />
              <path
                d="M526.86,335.93c0.05,1.09-0.72,1.98-1.82,2.07c-1.21,0.11-2.19-0.72-2.24-1.89c-0.05-1.08,0.74-1.98,1.82-2.08
	C525.82,333.92,526.81,334.76,526.86,335.93z"
              />
              <path
                d="M379.45,202.75c-1.2,0-2.1-0.91-2.06-2.09c0.03-1.1,0.83-1.91,1.94-1.97c1.19-0.06,2.17,0.81,2.2,1.96
	C381.57,201.79,380.63,202.74,379.45,202.75z"
              />
              <path
                d="M544.76,603.21c1.1,0,1.96,0.81,2.02,1.89c0.06,1.17-0.84,2.11-2.04,2.11c-1.11,0-1.96-0.8-2.02-1.89
	C542.66,604.14,543.56,603.21,544.76,603.21z"
              />
              <path
                d="M343.07,507.41c-0.04,1.11-0.9,1.92-2.03,1.91c-1.13-0.01-1.98-0.84-2-1.95c-0.02-1.19,0.95-2.1,2.16-2.03
	C342.31,505.41,343.11,506.3,343.07,507.41z"
              />
              <path
                d="M397.84,493.31c1.21-0.04,2.15,0.88,2.12,2.07c-0.03,1.11-0.85,1.93-1.98,1.97c-1.21,0.04-2.16-0.88-2.13-2.06
	C395.88,494.2,396.74,493.34,397.84,493.31z"
              />
              <path
                d="M436.96,486.14c1.2-0.03,2.12,0.87,2.09,2.05c-0.03,1.09-0.86,1.91-1.96,1.94c-1.2,0.03-2.12-0.88-2.09-2.05
	C435.03,486.99,435.86,486.17,436.96,486.14z"
              />
              <path
                d="M464.01,236.68c0.01,1.1-0.79,1.95-1.89,2.01c-1.21,0.07-2.16-0.79-2.17-1.97c-0.01-1.18,0.93-2.07,2.13-2.01
	C463.17,234.75,463.99,235.59,464.01,236.68z"
              />
              <path
                d="M393.79,201.27c-1.12-0.03-1.95-0.84-1.98-1.93c-0.04-1.18,0.89-2.1,2.09-2.07c1.1,0.03,1.95,0.86,1.99,1.94
	C395.92,200.38,395,201.3,393.79,201.27z"
              />
              <path
                d="M436.37,478.93c1.12,0.03,1.95,0.85,1.98,1.95c0.03,1.18-0.9,2.1-2.11,2.07c-1.1-0.03-1.96-0.88-1.99-1.96
	C434.22,479.81,435.16,478.89,436.37,478.93z"
              />
              <path
                d="M419.93,498.57c1.19-0.03,2.12,0.86,2.11,2.04c0,1.1-0.79,1.94-1.89,2.02c-1.18,0.09-2.19-0.76-2.25-1.91
	C417.84,499.58,418.76,498.6,419.93,498.57z"
              />
              <path
                d="M325.76,456.57c-1.21,0.03-2.14-0.89-2.11-2.09c0.03-1.11,0.86-1.93,1.99-1.95c1.21-0.03,2.16,0.9,2.12,2.08
	C327.73,455.69,326.87,456.54,325.76,456.57z"
              />
              <path
                d="M443,475.25c-1.11-0.01-1.96-0.81-2.01-1.9c-0.06-1.18,0.85-2.11,2.05-2.1c1.1,0.01,1.95,0.82,2.01,1.9
	C445.11,474.33,444.2,475.26,443,475.25z"
              />
              <path
                d="M423.93,482.46c-0.05,1.1-0.93,1.91-2.05,1.9c-1.22-0.01-2.1-0.99-1.98-2.19c0.11-1.1,1.03-1.86,2.15-1.78
	C423.16,480.46,423.98,481.36,423.93,482.46z"
              />
              <path
                d="M276.54,182.53c-1.13,0.01-1.98-0.79-2.02-1.9c-0.04-1.12,0.75-1.99,1.87-2.06c1.2-0.07,2.17,0.84,2.15,2.02
	C278.51,181.69,277.65,182.52,276.54,182.53z"
              />
              <path
                d="M531.5,290.56c-1.21-0.03-2.07-0.97-2-2.17c0.07-1.1,0.9-1.89,2.02-1.91c1.19-0.03,2.15,0.89,2.14,2.05
	C533.65,289.68,532.67,290.6,531.5,290.56z"
              />
              <path
                d="M331.07,430.48c1.12,0.04,1.94,0.91,1.93,2.02c-0.01,1.11-0.86,1.95-1.98,1.96c-1.2,0.01-2.14-0.96-2.05-2.14
	C329.04,431.24,329.96,430.44,331.07,430.48z"
              />
              <path
                d="M400.41,375.99c0.05,1.05-0.79,2.03-1.85,2.13c-1.03,0.1-2.03-0.75-2.14-1.81c-0.13-1.17,0.79-2.24,1.94-2.26
	C399.39,374.03,400.36,374.94,400.41,375.99z"
              />
              <path
                d="M368.05,235.63c1.21-0.06,2.14,0.79,2.17,1.98c0.03,1.19-0.86,2.09-2.07,2.09c-1.08,0-1.98-0.81-2.08-1.87
	C365.98,236.69,366.88,235.68,368.05,235.63z"
              />
              <path
                d="M377.67,197.31c0.02,1.09-0.81,1.97-1.91,2.05c-1.22,0.08-2.18-0.78-2.2-1.98c-0.02-1.11,0.78-1.97,1.89-2.05
	C376.66,195.26,377.65,196.13,377.67,197.31z"
              />
              <path
                d="M374.98,501.13c-0.02,1.1-0.88,1.93-1.99,1.94c-1.22,0.02-2.11-0.93-2.02-2.14c0.08-1.1,0.97-1.87,2.1-1.82
	C374.17,499.16,375,500.03,374.98,501.13z"
              />
              <path
                d="M363.96,538.06c-0.01,1.18-0.96,2.04-2.17,1.97c-1.1-0.07-1.9-0.91-1.89-2.01c0.01-1.18,0.96-2.05,2.17-1.97
	C363.16,536.11,363.97,536.97,363.96,538.06z"
              />
              <path
                d="M452.72,479.72c0.01,1.05-0.9,2-1.94,2.05c-1.13,0.05-2.13-0.98-2.07-2.14c0.05-1.06,1-1.96,2.04-1.93
	C451.79,477.73,452.71,478.68,452.72,479.72z"
              />
              <path
                d="M406.54,80.6c0.26,0.48,0.6,0.94,0.76,1.46c0.38,1.17-0.27,2.29-1.4,2.57c-1.17,0.3-2.3-0.37-2.56-1.5
	c-0.26-1.16,0.42-2.23,1.64-2.45c0.53-0.09,1.1,0,1.65,0C406.63,80.68,406.54,80.6,406.54,80.6z"
              />
              <path
                d="M523.17,590.69c0.25,0.49,0.59,0.95,0.75,1.46c0.36,1.17-0.29,2.29-1.43,2.56c-1.18,0.28-2.3-0.39-2.54-1.53
	c-0.25-1.16,0.45-2.23,1.67-2.43c0.53-0.09,1.1,0.01,1.65,0.02C523.25,590.77,523.17,590.69,523.17,590.69z"
              />
              <path
                d="M297.9,386.77c-1.03,0.02-1.99-0.89-2.05-1.94c-0.06-1.14,0.96-2.19,2.09-2.14c1.03,0.04,1.94,1.01,1.92,2.06
	C299.86,385.79,298.93,386.75,297.9,386.77z"
              />
              <path
                d="M439.63,475.09c1.19-0.02,2.12,0.92,2.07,2.09c-0.05,1.09-0.89,1.9-2,1.91c-1.2,0.02-2.12-0.91-2.07-2.09
	C437.67,475.93,438.53,475.11,439.63,475.09z"
              />
              <path
                d="M488.33,536.02c1.21-0.02,2.15,0.92,2.1,2.1c-0.04,1.08-0.92,1.92-2.02,1.94c-1.21,0.02-2.13-0.91-2.09-2.1
	C486.37,536.85,487.21,536.04,488.33,536.02z"
              />
              <path
                d="M532.31,279.27c0.23,0.49,0.54,0.97,0.68,1.49c0.34,1.23-0.33,2.31-1.49,2.53c-1.1,0.21-2.13-0.42-2.4-1.46
	c-0.29-1.14,0.42-2.25,1.64-2.46c0.54-0.09,1.1-0.01,1.65-0.01C532.39,279.35,532.31,279.27,532.31,279.27z"
              />
              <path
                d="M570.77,611.84c1.12,0.05,1.93,0.88,1.94,2c0.01,1.19-0.94,2.09-2.15,2.03c-1.1-0.05-1.95-0.92-1.96-2.01
	C568.59,612.68,569.55,611.78,570.77,611.84z"
              />
              <path
                d="M354.77,540.79c-1.04,0.02-1.98-0.87-2.03-1.94c-0.05-1.07,0.79-2.04,1.84-2.12c1.13-0.09,2.18,0.9,2.17,2.05
	C356.74,539.83,355.82,540.77,354.77,540.79z"
              />
              <path
                d="M493.8,438.36c-1.16-0.04-2.09-1.06-2.01-2.19c0.08-1.15,1.1-1.99,2.29-1.89c1.1,0.1,1.87,0.95,1.87,2.05
	C495.94,437.53,495.01,438.4,493.8,438.36z"
              />
              <path
                d="M520.89,390.78c-0.03,1.21-0.99,2.06-2.23,1.96c-1.1-0.08-1.93-0.99-1.9-2.07c0.03-1.18,1.04-2.05,2.25-1.96
	C520.12,388.79,520.92,389.67,520.89,390.78z"
              />
              <path
                d="M456.08,143.68c-0.04,1.09-0.92,1.91-2.03,1.91c-1.21-0.01-2.1-0.97-1.98-2.18c0.1-1.1,1.01-1.86,2.14-1.79
	C455.29,141.7,456.12,142.6,456.08,143.68z"
              />
              <path
                d="M354.67,390.64c0.05,1.14-0.9,2.12-2.07,2.13c-1.1,0.01-1.99-0.77-2.09-1.85c-0.11-1.19,0.72-2.16,1.92-2.23
	C353.63,388.62,354.62,389.48,354.67,390.64z"
              />
              <path
                d="M548.23,373.82c0.06,1.1-0.71,2-1.82,2.12c-1.21,0.13-2.22-0.71-2.29-1.89c-0.06-1.08,0.74-2.01,1.83-2.12
	C547.18,371.8,548.17,372.62,548.23,373.82z"
              />
              <path
                d="M435.21,112.82c0.04,1.09-0.73,1.97-1.83,2.06c-1.21,0.1-2.18-0.73-2.23-1.9c-0.04-1.08,0.75-1.97,1.83-2.07
	C434.18,110.8,435.17,111.64,435.21,112.82z"
              />
              <path
                d="M283.56,237.86c0.02,1.05-0.88,2.03-1.9,2.08c-1.13,0.06-2.16-0.97-2.11-2.11c0.04-1.05,0.99-1.97,2.03-1.96
	C282.6,235.86,283.54,236.81,283.56,237.86z"
              />
              <path
                d="M331.59,441.67c-1.1-0.05-1.93-0.9-1.94-1.99c-0.01-1.18,0.94-2.07,2.14-2.01c1.11,0.05,1.92,0.89,1.93,1.98
	C333.74,440.83,332.8,441.72,331.59,441.67z"
              />
              <path
                d="M490.55,442.16c-1.11,0.02-2-0.78-2.08-1.87c-0.08-1.17,0.83-2.14,2.03-2.16c1.2-0.01,2.12,0.92,2.07,2.11
	C492.52,441.34,491.67,442.14,490.55,442.16z"
              />
              <path
                d="M410.43,83.98c0.23,0.49,0.53,0.97,0.68,1.49c0.34,1.23-0.33,2.3-1.5,2.52c-1.1,0.21-2.13-0.42-2.4-1.46
	c-0.29-1.14,0.42-2.25,1.64-2.45c0.54-0.09,1.1-0.01,1.65-0.01C410.52,84.06,410.43,83.98,410.43,83.98z"
              />
              <path
                d="M331.15,450.62c0.05,1.14-0.89,2.11-2.06,2.12c-1.1,0.01-1.98-0.76-2.08-1.84c-0.11-1.18,0.72-2.15,1.91-2.23
	C330.12,448.61,331.1,449.46,331.15,450.62z"
              />
              <path
                d="M364.68,505.43c-0.01,1.09-0.84,1.93-1.93,1.97c-1.2,0.05-2.14-0.85-2.12-2.02c0.02-1.18,0.97-2.04,2.17-1.97
	C363.89,503.48,364.69,504.34,364.68,505.43z"
              />
              <path
                d="M526.7,355.63c1.12-0.03,2,0.77,2.06,1.88c0.06,1.11-0.73,2.01-1.84,2.09c-1.23,0.09-2.2-0.79-2.19-1.99
	C524.74,356.5,525.58,355.65,526.7,355.63z"
              />
              <path
                d="M326.54,440.24c-0.06,1.18-1.04,2.01-2.26,1.9c-1.09-0.1-1.89-1.01-1.83-2.09c0.06-1.17,1.07-2.02,2.27-1.9
	C325.81,438.25,326.59,439.15,326.54,440.24z"
              />
              <path
                d="M495.42,555.59c-0.02,1.04-0.94,1.97-1.98,1.99c-1.13,0.02-2.08-1.02-2-2.17c0.08-1.07,1.03-1.93,2.07-1.87
	C494.56,553.59,495.44,554.54,495.42,555.59z"
              />
              <path
                d="M403.01,491.14c0.06,1.06-0.77,2.04-1.8,2.14c-1.1,0.1-2.18-0.9-2.18-2.02c0-1.04,0.91-1.98,1.95-2.02
	C402,489.19,402.95,490.08,403.01,491.14z"
              />
              <path
                d="M478.63,488.39c-0.01,1.04-0.93,1.97-1.96,2.01c-1.13,0.03-2.09-1-2.02-2.16c0.07-1.06,1.02-1.94,2.05-1.89
	C477.74,486.41,478.63,487.35,478.63,488.39z"
              />
              <path
                d="M243.38,153.42c0.38-0.28,0.72-0.64,1.15-0.81c1.13-0.47,2.31,0.06,2.7,1.13c0.39,1.07-0.26,2.28-1.39,2.61
	c-1.1,0.32-2.18-0.36-2.43-1.61c-0.09-0.45,0.03-0.94,0.05-1.4C243.45,153.34,243.38,153.42,243.38,153.42z"
              />
              <path
                d="M406.04,236.93c0.38-0.28,0.71-0.65,1.13-0.82c1.14-0.47,2.31,0.03,2.71,1.1c0.4,1.06-0.23,2.28-1.37,2.62
	c-1.09,0.33-2.19-0.34-2.45-1.58c-0.09-0.45,0.02-0.94,0.04-1.4C406.11,236.85,406.04,236.93,406.04,236.93z"
              />
              <path
                d="M425.56,230.59c1.03,0.02,1.93,0.96,1.93,2.02c0,1.05-0.9,2-1.93,2.03c-1.13,0.03-2.13-1-2.06-2.14
	C423.57,231.46,424.53,230.57,425.56,230.59z"
              />
              <path
                d="M375.33,68.89c1.03-0.02,1.98,0.89,2.04,1.93c0.06,1.14-0.95,2.17-2.08,2.13c-1.02-0.04-1.92-1-1.92-2.05
	C373.39,69.85,374.31,68.9,375.33,68.89z"
              />
              <path
                d="M410.91,185.01c1.03,0.02,1.96,0.95,1.98,1.99c0.02,1.13-1.04,2.15-2.15,2.06c-1.02-0.08-1.88-1.06-1.84-2.11
	C408.94,185.89,409.87,184.99,410.91,185.01z"
              />
              <path
                d="M437.84,474.01c-0.02,1.04-0.94,1.97-1.98,1.99c-1.13,0.02-2.08-1.02-2-2.17c0.08-1.07,1.03-1.93,2.07-1.87
	C436.97,472.02,437.85,472.97,437.84,474.01z"
              />
              <path
                d="M327.46,328.34c-0.13,1.03-1.15,1.88-2.16,1.79c-1.14-0.11-1.97-1.22-1.77-2.37c0.18-1.06,1.22-1.82,2.24-1.64
	C326.82,326.3,327.59,327.31,327.46,328.34z"
              />
              <path
                d="M427.28,478.27c0.02,1.04-0.87,1.99-1.91,2.05c-1.03,0.06-1.99-0.81-2.06-1.87c-0.08-1.16,0.87-2.2,2-2.17
	C426.34,476.3,427.27,477.23,427.28,478.27z"
              />
              <path
                d="M430.45,498.08c-1.03-0.05-1.91-1.02-1.89-2.07c0.02-1.06,0.94-1.98,1.97-1.98c1.14,0,2.1,1.05,2.01,2.19
	C432.46,497.26,431.48,498.13,430.45,498.08z"
              />
              <path
                d="M425.85,209.32c-0.15-0.16-0.31-0.32-0.46-0.47c-0.15-0.45-0.41-0.89-0.42-1.35c-0.02-1.17,0.78-1.98,1.88-2.03
	c1.03-0.05,1.85,0.61,2.02,1.63c0.18,1.11-0.43,2.01-1.59,2.24C426.82,209.43,426.33,209.33,425.85,209.32z"
              />
              <path
                d="M397.71,370.92c-1.03,0.01-1.98-0.89-2.04-1.93c-0.06-1.13,0.95-2.17,2.08-2.13c1.03,0.04,1.92,1,1.91,2.05
	C399.65,369.97,398.74,370.91,397.71,370.92z"
              />
              <path
                d="M692.35,621.73c-0.05,1.14-0.91,1.88-2.05,1.79c-1.06-0.09-1.88-1.06-1.8-2.11c0.08-1.09,1-1.88,2.09-1.8
	C691.67,619.7,692.4,620.58,692.35,621.73z"
              />
              <path
                d="M254.53,359.67c0.06,1.04-0.78,2.02-1.83,2.12c-1.03,0.1-2.01-0.73-2.13-1.8c-0.13-1.17,0.78-2.23,1.92-2.25
	C253.52,357.73,254.47,358.63,254.53,359.67z"
              />
              <path
                d="M456.07,475.98c-0.04,1.04-0.99,1.95-2.02,1.95c-1.13-0.01-2.06-1.06-1.95-2.22c0.1-1.07,1.07-1.91,2.11-1.82
	C455.25,473.97,456.11,474.93,456.07,475.98z"
              />
              <path
                d="M447.64,129.85c0.04,1.05-0.81,2.04-1.84,2.12c-1.11,0.09-2.17-0.93-2.15-2.06c0.02-1.04,0.94-1.97,1.98-1.99
	C446.66,127.89,447.59,128.79,447.64,129.85z"
              />
              <path
                d="M255.02,367.04c-0.03,1.04-0.97,1.96-2,1.96c-1.13,0-2.07-1.05-1.97-2.2c0.09-1.07,1.06-1.92,2.09-1.84
	C254.18,365.04,255.05,365.99,255.02,367.04z"
              />
              <path
                d="M269.84,81.9c0,0-0.09,0.13-0.09,0.13c-0.63,0.1-1.26,0.3-1.88,0.27c-0.98-0.04-1.67-0.54-1.7-1.62
	c-0.03-1,0.9-2.09,1.88-2.15c1.03-0.07,1.69,0.45,1.83,1.47C269.96,80.62,269.86,81.27,269.84,81.9z"
              />
              <path
                d="M412.88,519.33c-0.01,1.07-0.86,1.92-1.95,1.95c-1.13,0.03-1.97-0.74-2.02-1.86c-0.06-1.2,0.85-2.12,2.05-2.07
	C412.04,517.39,412.89,518.27,412.88,519.33z"
              />
              <path
                d="M393.94,533.77c-1.09,0.05-2.02-0.75-2.11-1.81c-0.1-1.15,0.84-2.14,2.02-2.14c1.11,0,1.95,0.82,1.97,1.93
	C395.85,532.86,395.05,533.72,393.94,533.77z"
              />
              <path
                d="M533.87,378.46c0.02-1.03,0.94-1.96,1.97-1.98c1.13-0.02,2.07,1,1.99,2.16c-0.08,1.07-1.02,1.92-2.05,1.86
	C534.74,380.44,533.85,379.49,533.87,378.46z"
              />
              <path
                d="M336.57,349.09c-0.07,1.07-1,1.89-2.1,1.84c-1.12-0.06-1.9-0.93-1.84-2.07c0.06-1.12,0.95-1.91,2.07-1.84
	C335.79,347.09,336.64,348.02,336.57,349.09z"
              />
              <path
                d="M513.97,588.28c-1.02-0.04-1.91-0.99-1.9-2.04c0.01-1.05,0.92-1.98,1.95-1.99c1.13-0.02,2.11,1.03,2.02,2.16
	C515.96,587.44,514.99,588.32,513.97,588.28z"
              />
              <path
                d="M453.26,466.69c1.03-0.05,1.97,0.82,2.04,1.88c0.06,1.06-0.75,2.04-1.78,2.14c-1.1,0.11-2.18-0.88-2.19-2
	C451.33,467.68,452.23,466.74,453.26,466.69z"
              />
              <path
                d="M341.58,532.87c-0.06,1.11-0.9,1.88-2.03,1.85c-1.09-0.03-1.94-0.88-1.94-1.95c0-1.15,1-2.07,2.17-1.98
	C340.86,530.87,341.64,531.77,341.58,532.87z"
              />
              <path
                d="M315.95,444.81c-0.06,1.03-1.01,1.93-2.04,1.91c-1.13-0.02-2.03-1.08-1.91-2.24c0.11-1.07,1.08-1.88,2.12-1.78
	C315.16,442.81,316,443.78,315.95,444.81z"
              />
              <path
                d="M319.03,334.06c1.12,0.02,1.94,0.89,1.91,2.02c-0.03,1.14-0.87,1.93-2.02,1.9c-1.11-0.03-1.97-0.93-1.93-2.01
	C317.02,334.89,317.93,334.03,319.03,334.06z"
              />
              <path
                d="M280.14,407.97c0.02,1.14-0.79,1.97-1.92,1.98c-1.1,0.01-2-0.84-2.01-1.92c-0.02-1.07,0.86-1.97,1.95-1.99
	C279.28,406.02,280.13,406.84,280.14,407.97z"
              />
              <path
                d="M529.74,597.48c1.1,0.05,1.9,0.92,1.88,2.03c-0.02,1.12-0.85,1.92-1.98,1.92c-1.19,0-2.11-0.99-2.01-2.14
	C527.72,598.22,528.65,597.42,529.74,597.48z"
              />
              <path
                d="M385.24,410.02c-0.05,1.14-0.92,1.91-2.07,1.85c-1.1-0.06-1.95-0.97-1.89-2.05c0.06-1.07,1-1.91,2.09-1.86
	C384.49,408,385.29,408.89,385.24,410.02z"
              />
              <path
                d="M317.14,522.25c-1.1,0.04-2.01-0.76-2.09-1.83c-0.09-1.15,0.86-2.13,2.04-2.12c1.11,0.01,1.94,0.83,1.95,1.95
	C319.06,521.37,318.26,522.21,317.14,522.25z"
              />
              <path
                d="M256.92,388.32c0,1.08-0.88,1.95-1.98,1.95c-1.14,0.01-1.96-0.81-1.96-1.95c0-1.13,0.83-1.96,1.95-1.96
	C256.03,386.37,256.92,387.25,256.92,388.32z"
              />
              <path
                d="M371.79,7.62c-0.57,0.25-1.12,0.62-1.71,0.72c-1.15,0.19-2.07-0.55-2.19-1.62c-0.11-0.96,0.55-1.87,1.52-2.08
	c1.08-0.23,2.09,0.35,2.32,1.45c0.11,0.51-0.01,1.07-0.02,1.61C371.72,7.7,371.79,7.62,371.79,7.62z"
              />
              <path
                d="M483.73,525.51c1.09-0.04,2.03,0.8,2.08,1.88c0.05,1.08-0.8,1.99-1.9,2.04c-1.15,0.05-2.01-0.73-2.05-1.86
	C481.81,526.44,482.62,525.56,483.73,525.51z"
              />
              <path
                d="M469.01,460.34c-0.02,1.09-0.92,1.93-2.03,1.92c-1.14-0.02-1.95-0.86-1.92-2c0.02-1.13,0.88-1.95,2-1.92
	C468.17,458.36,469.04,459.26,469.01,460.34z"
              />
              <path
                d="M483.14,518.32c1.12,0.02,1.93,0.88,1.9,2.01c-0.03,1.13-0.87,1.93-2.01,1.89c-1.1-0.03-1.96-0.93-1.93-2
	C481.13,519.15,482.04,518.3,483.14,518.32z"
              />
              <path
                d="M465.84,464.17c-0.02,1.13-0.85,1.94-1.99,1.92c-1.1-0.02-1.97-0.91-1.95-1.98c0.02-1.07,0.93-1.94,2.02-1.93
	C465.04,462.19,465.86,463.04,465.84,464.17z"
              />
              <path
                d="M419.33,511.57c0.04,1.11-0.75,1.98-1.85,2.05c-1.2,0.07-2.17-0.85-2.14-2.02c0.03-1.08,0.89-1.92,1.99-1.93
	C418.45,509.66,419.29,510.46,419.33,511.57z"
              />
              <path
                d="M406.16,400.8c0,1.09-0.88,1.95-1.99,1.96c-1.14,0-1.97-0.82-1.97-1.96c0-1.13,0.84-1.97,1.96-1.96
	C405.26,398.84,406.15,399.72,406.16,400.8z"
              />
              <path
                d="M532.36,274.1c0.01,1.07-0.82,1.94-1.92,1.98c-1.13,0.04-1.98-0.71-2.05-1.82c-0.08-1.2,0.82-2.13,2.02-2.1
	C531.49,272.18,532.35,273.03,532.36,274.1z"
              />
              <path
                d="M415.96,515.47c0.01,1.11-0.79,1.94-1.91,1.97c-1.09,0.03-1.99-0.77-2.07-1.84c-0.08-1.15,0.87-2.12,2.04-2.1
	C415.14,513.53,415.95,514.36,415.96,515.47z"
              />
              <path
                d="M518.14,235.69c0.03,1.14-0.78,1.98-1.91,2c-1.1,0.02-2-0.83-2.03-1.91c-0.03-1.08,0.84-1.97,1.94-2
	C517.27,233.75,518.12,234.56,518.14,235.69z"
              />
              <path
                d="M312.11,441.32c0.02,1.03-0.87,1.98-1.91,2.04c-1.11,0.06-2.11-0.95-2.06-2.1c0.04-1.06,0.96-1.95,2-1.93
	C311.17,439.36,312.1,440.29,312.11,441.32z"
              />
              <path
                d="M533.75,600.56c1.03,0.02,1.95,0.95,1.97,1.98c0.02,1.12-1.04,2.14-2.14,2.05c-1.02-0.08-1.87-1.06-1.83-2.11
	C531.79,601.43,532.71,600.54,533.75,600.56z"
              />
              <path
                d="M296.32,245.85c-1.11-0.06-1.94-0.97-1.88-2.06c0.06-1.08,1-1.91,2.09-1.86c1.11,0.05,1.91,0.94,1.86,2.07
	C298.33,245.13,297.47,245.91,296.32,245.85z"
              />
              <path
                d="M467.78,469.46c-1.12,0.02-2.01-0.81-2.05-1.9c-0.03-1.08,0.83-1.98,1.93-2.02c1.13-0.03,1.99,0.78,2.02,1.91
	C469.72,468.58,468.91,469.43,467.78,469.46z"
              />
              <path
                d="M472.27,475.8c1.02-0.01,1.98,0.89,2.03,1.92c0.06,1.13-0.95,2.16-2.07,2.11c-1.03-0.04-1.91-0.99-1.9-2.04
	C470.33,476.74,471.23,475.81,472.27,475.8z"
              />
              <path
                d="M416.91,482.86c0,1.14-0.83,1.96-1.97,1.95c-1.11-0.01-1.99-0.88-1.98-1.96c0-1.08,0.9-1.96,1.99-1.96
	C416.07,480.89,416.91,481.73,416.91,482.86z"
              />
              <path
                d="M371.13,204.97c0.03,1.07-0.79,1.96-1.88,2.02c-1.13,0.06-2-0.68-2.09-1.78c-0.1-1.2,0.78-2.15,1.98-2.14
	C370.22,203.06,371.1,203.9,371.13,204.97z"
              />
              <path
                d="M312.39,341.73c1.08-0.06,2.02,0.74,2.12,1.79c0.11,1.14-0.82,2.14-1.99,2.15c-1.12,0.01-1.95-0.78-1.98-1.9
	C310.51,342.66,311.3,341.79,312.39,341.73z"
              />
              <path
                d="M315.59,507.84c-1.11-0.02-1.98-0.9-1.96-1.98c0.02-1.08,0.92-1.95,2.01-1.94c1.12,0.01,1.95,0.86,1.94,1.99
	C317.57,507.05,316.73,507.86,315.59,507.84z"
              />
              <path
                d="M382.77,176.61c-0.43,0.19-0.85,0.46-1.3,0.55c-1.3,0.29-2.32-0.34-2.56-1.49c-0.24-1.15,0.59-2.25,1.77-2.37
	c1.22-0.12,2.09,0.65,2.17,1.98c0.03,0.47-0.11,0.94-0.17,1.41C382.68,176.7,382.77,176.61,382.77,176.61z"
              />
              <path
                d="M585.85,621.87c-1.02,0.04-1.99-0.84-2.07-1.88c-0.08-1.13,0.9-2.17,2.03-2.16c1.03,0.01,1.93,0.94,1.94,2
	C587.76,620.88,586.87,621.83,585.85,621.87z"
              />
              <path
                d="M297.9,236.65c0.03,1.14-0.78,1.98-1.91,2c-1.1,0.02-2-0.83-2.03-1.91c-0.03-1.08,0.84-1.97,1.94-2
	C297.02,234.71,297.87,235.52,297.9,236.65z"
              />
              <path
                d="M420.69,509.77c-1.1-0.01-1.96-0.84-1.99-1.91c-0.03-1.16,0.95-2.09,2.12-2.02c1.09,0.06,1.88,0.93,1.85,2.04
	C422.64,508.99,421.82,509.78,420.69,509.77z"
              />
              <path
                d="M471.61,468.88c1.11,0.02,1.93,0.84,1.95,1.96c0.01,1.12-0.79,1.96-1.9,1.99c-1.19,0.04-2.14-0.91-2.08-2.07
	C469.62,469.68,470.51,468.87,471.61,468.88z"
              />
              <path
                d="M444.32,485.69c1.11,0.05,1.9,0.94,1.85,2.07c-0.06,1.13-0.92,1.9-2.06,1.84c-1.1-0.06-1.94-0.98-1.87-2.06
	C442.29,486.47,443.23,485.64,444.32,485.69z"
              />
              <path
                d="M372.35,199.22c1.09,0.05,1.89,0.91,1.87,2.02c-0.02,1.12-0.84,1.91-1.97,1.91c-1.18,0-2.11-0.99-2-2.13
	C370.34,199.96,371.27,199.16,372.35,199.22z"
              />
              <path
                d="M526.79,375.95c-0.05,1.14-0.91,1.91-2.06,1.85c-1.1-0.05-1.95-0.97-1.89-2.05c0.06-1.08,0.99-1.92,2.09-1.87
	C526.04,373.93,526.84,374.82,526.79,375.95z"
              />
              <path
                d="M495.12,429c0.06,1.14-0.72,2.02-1.85,2.07c-1.1,0.06-2.04-0.77-2.1-1.85c-0.06-1.08,0.78-2.01,1.87-2.07
	C494.17,427.08,495.06,427.87,495.12,429z"
              />
              <path
                d="M350.21,506.8c0.02,1.14-0.79,1.98-1.92,2c-1.11,0.02-2.01-0.83-2.03-1.92c-0.02-1.08,0.85-1.98,1.95-2
	C349.33,504.86,350.19,505.67,350.21,506.8z"
              />
              <path
                d="M336.17,431.72c0.02-1.03,0.94-1.96,1.97-1.98c1.13-0.02,2.07,1,1.99,2.16c-0.08,1.07-1.02,1.92-2.05,1.86
	C337.04,433.71,336.15,432.75,336.17,431.72z"
              />
              <path
                d="M551.55,370.18c-0.05,1.06-0.94,1.88-2.03,1.87c-1.2-0.01-2.06-0.98-1.94-2.17c0.11-1.08,1.02-1.84,2.13-1.76
	C550.78,368.21,551.6,369.12,551.55,370.18z"
              />
              <path
                d="M467.07,169.86c-1.03,0.03-1.96-0.85-2.01-1.91c-0.05-1.07,0.76-2.03,1.81-2.11c1.11-0.09,2.17,0.9,2.16,2.03
	C469.02,168.89,468.1,169.83,467.07,169.86z"
              />
              <path
                d="M370.37,197.99c0.04,1.05-0.8,2.03-1.83,2.11c-1.1,0.08-2.16-0.93-2.14-2.05c0.02-1.03,0.94-1.96,1.97-1.98
	C369.41,196.04,370.33,196.92,370.37,197.99z"
              />
              <path
                d="M325.75,432.88c0.04,1.11-0.74,1.98-1.84,2.04c-1.18,0.07-2.16-0.86-2.13-2.01c0.03-1.07,0.9-1.91,1.98-1.92
	C324.87,430.97,325.71,431.77,325.75,432.88z"
              />
              <path
                d="M424.38,386.44c-1.15,0.03-1.99-0.76-2.02-1.89c-0.03-1.13,0.78-2,1.9-2.02c1.1-0.03,2.01,0.82,2.05,1.9
	C426.34,385.51,425.48,386.41,424.38,386.44z"
              />
              <path
                d="M318.55,433.61c0.03,1.05-0.83,2.02-1.85,2.09c-1.11,0.07-2.15-0.95-2.12-2.07c0.03-1.03,0.96-1.95,1.99-1.96
	C317.61,431.65,318.52,432.54,318.55,433.61z"
              />
              <path
                d="M483.88,505.79c0.06,1.08-0.79,1.99-1.89,2.04c-1.14,0.06-2-0.72-2.05-1.85c-0.05-1.13,0.75-2.01,1.86-2.06
	C482.89,503.88,483.83,504.72,483.88,505.79z"
              />
              <path
                d="M279.08,191.02c0.05-1.08,0.98-1.92,2.08-1.88c1.11,0.04,1.92,0.93,1.88,2.05c-0.05,1.13-0.91,1.92-2.05,1.86
	C279.87,193.01,279.03,192.1,279.08,191.02z"
              />
              <path
                d="M349.25,452.59c0.02,1.14-0.79,1.98-1.93,1.99c-1.11,0.01-2.01-0.83-2.03-1.92c-0.02-1.08,0.85-1.98,1.95-2
	C348.38,450.64,349.23,451.46,349.25,452.59z"
              />
              <path
                d="M399.14,195.53c-0.05,1.13-0.91,1.9-2.06,1.85c-1.1-0.06-1.94-0.97-1.88-2.05c0.06-1.07,1-1.91,2.08-1.86
	C398.4,193.52,399.2,194.41,399.14,195.53z"
              />
              <path
                d="M357.42,506.06c0.03,1.05-0.82,2.02-1.85,2.09c-1.11,0.07-2.15-0.95-2.12-2.07c0.03-1.03,0.96-1.95,1.99-1.96
	C356.48,504.1,357.39,505,357.42,506.06z"
              />
              <path
                d="M517.48,394.81c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C516.53,392.82,517.48,393.77,517.48,394.81z"
              />
              <path
                d="M471.1,21.38c-0.29-0.46-0.63-0.89-0.84-1.37c-0.47-1.08-0.04-2.15,0.97-2.53c0.98-0.38,2.16,0.13,2.54,1.1
	c0.41,1.03-0.04,2.08-1.14,2.47c-0.5,0.18-1.06,0.18-1.59,0.27C471.03,21.31,471.1,21.38,471.1,21.38z"
              />
              <path
                d="M459.13,472.11c-0.03,1.08-0.97,1.99-2.01,1.96c-1.05-0.04-1.97-1.01-1.94-2.05c0.03-1.05,1-1.98,2.04-1.96
	C458.28,470.08,459.16,471.02,459.13,472.11z"
              />
              <path
                d="M348.55,445.47c-0.03,1.06-0.93,1.91-2.01,1.89c-1.12-0.02-1.91-0.85-1.88-1.98c0.02-1.12,0.86-1.93,1.97-1.9
	C347.7,443.51,348.58,444.42,348.55,445.47z"
              />
              <path
                d="M548.66,606.63c1.06,0.03,1.93,0.95,1.9,1.99c-0.03,1.05-0.94,1.9-2.01,1.88c-1.11-0.02-1.9-0.86-1.87-1.98
	C546.71,607.41,547.56,606.6,548.66,606.63z"
              />
              <path
                d="M527.23,236.41c1.07-0.01,1.94,0.88,1.94,1.97c0,1.08-0.89,1.99-1.94,1.98c-1.02-0.01-1.98-0.96-1.98-1.98
	C525.25,237.36,526.2,236.41,527.23,236.41z"
              />
              <path
                d="M364.48,196.71c-1.05-0.05-1.95-1.03-1.9-2.07c0.04-1.04,1.03-1.96,2.06-1.92c1.07,0.04,1.91,0.98,1.87,2.08
	C366.47,195.88,365.53,196.76,364.48,196.71z"
              />
              <path
                d="M404.04,529.24c-1.05-0.06-1.88-1.03-1.82-2.11c0.06-1.09,0.99-1.93,2.06-1.85c1.05,0.08,1.92,1.06,1.85,2.09
	C406.07,528.4,405.06,529.3,404.04,529.24z"
              />
              <path
                d="M466.29,471.38c-0.02,1.12-0.85,1.92-1.97,1.89c-1.08-0.03-1.95-0.92-1.92-1.97c0.02-1.06,0.93-1.92,2-1.9
	C465.52,469.41,466.31,470.25,466.29,471.38z"
              />
              <path
                d="M366.05,499.63c1.1,0.02,1.89,0.87,1.87,1.99c-0.03,1.12-0.86,1.91-1.97,1.87c-1.07-0.03-1.94-0.93-1.9-1.98
	C364.07,500.47,364.99,499.6,366.05,499.63z"
              />
              <path
                d="M317.53,339.97c-0.06,1.11-0.92,1.88-2.03,1.81c-1.08-0.06-1.91-0.98-1.85-2.04c0.06-1.04,1.01-1.88,2.07-1.83
	C316.81,337.98,317.59,338.86,317.53,339.97z"
              />
              <path
                d="M492.02,432.99c-0.03,1.05-0.93,1.9-2,1.88c-1.11-0.02-1.9-0.85-1.88-1.97c0.02-1.11,0.86-1.92,1.97-1.89
	C491.17,431.04,492.04,431.95,492.02,432.99z"
              />
              <path
                d="M327.48,347.73c1.05,0.06,1.94,1.03,1.89,2.06c-0.05,1.03-1.04,1.95-2.06,1.9c-1.05-0.04-1.9-1-1.85-2.08
	C325.49,348.52,326.41,347.67,327.48,347.73z"
              />
              <path
                d="M305.16,382.05c1.1,0.05,1.89,0.94,1.83,2.06c-0.06,1.13-0.91,1.89-2.04,1.83c-1.09-0.06-1.92-0.98-1.86-2.04
	C303.15,382.84,304.09,382,305.16,382.05z"
              />
              <path
                d="M310.38,506.52c0.04,1.09-0.82,2.05-1.88,2.1c-1.04,0.04-2.02-0.88-2.07-1.93c-0.05-1.05,0.84-2.03,1.9-2.08
	C309.39,504.55,310.34,505.43,310.38,506.52z"
              />
              <path
                d="M488.96,546.99c-1.02,0.06-2.03-0.84-2.1-1.87c-0.07-1.04,0.79-2.02,1.85-2.1c1.07-0.08,2,0.76,2.06,1.85
	C490.84,545.95,490.01,546.93,488.96,546.99z"
              />
              <path
                d="M459.89,479.24c0.01,1.02-0.93,1.99-1.96,2.01c-1.04,0.02-1.95-0.89-1.97-1.97c-0.01-1.09,0.86-2,1.92-2
	C458.92,477.27,459.88,478.21,459.89,479.24z"
              />
              <path
                d="M423.11,182.38c0.05,1.13-0.72,1.99-1.84,2.04c-1.08,0.05-2.01-0.78-2.07-1.85c-0.05-1.06,0.8-1.99,1.87-2.04
	C422.19,180.47,423.06,181.26,423.11,182.38z"
              />
              <path
                d="M564.4,99.87c-0.11-0.14-0.23-0.29-0.34-0.43c-0.15-0.28-0.37-0.54-0.42-0.84c-0.26-1.42,0.26-2.47,1.27-2.69
	c0.76-0.16,1.82,0.76,2.14,1.87c0.33,1.14-0.06,1.85-1.23,2.08C565.37,99.95,564.88,99.87,564.4,99.87z"
              />
              <path
                d="M288.77,215.84c-0.01,1.08-0.91,1.99-1.95,1.98c-1.03-0.01-1.98-0.98-1.98-2c0-1.02,0.96-1.97,1.99-1.97
	C287.9,213.84,288.78,214.74,288.77,215.84z"
              />
              <path
                d="M443.57,126.66c-0.05,1.03-1.04,1.95-2.06,1.91c-1.05-0.04-1.9-0.99-1.86-2.08c0.04-1.09,0.96-1.95,2.03-1.89
	C442.73,124.66,443.62,125.63,443.57,126.66z"
              />
              <path
                d="M458.7,155.94c-1.04,0.04-2.03-0.87-2.08-1.92c-0.05-1.05,0.83-2.03,1.9-2.09c1.06-0.06,2.01,0.82,2.05,1.91
	C460.62,154.92,459.75,155.89,458.7,155.94z"
              />
              <path
                d="M290.23,190.34c0.02,1.09-0.86,2.04-1.91,2.06c-1.04,0.02-2.01-0.91-2.04-1.96c-0.03-1.05,0.88-2.02,1.94-2.05
	C289.28,188.36,290.2,189.25,290.23,190.34z"
              />
              <path
                d="M442.34,464.11c1.11,0.02,1.91,0.87,1.88,2c-0.03,1.13-0.86,1.92-1.99,1.88c-1.09-0.03-1.95-0.93-1.92-1.99
	C440.35,464.94,441.27,464.09,442.34,464.11z"
              />
              <path
                d="M318.76,377.6c-1.05,0.01-1.95-0.9-1.96-1.98c-0.01-1.09,0.87-1.99,1.94-1.99c1.03,0,1.99,0.95,1.99,1.97
	C320.74,376.63,319.79,377.59,318.76,377.6z"
              />
              <path
                d="M327.94,375.14c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C327,373.15,327.94,374.1,327.94,375.14z"
              />
              <path
                d="M539.54,605.93c0,1.01-0.95,1.98-1.97,1.99c-1.04,0.01-1.94-0.9-1.95-1.97c0-1.09,0.86-1.98,1.93-1.98
	C538.59,603.97,539.54,604.91,539.54,605.93z"
              />
              <path
                d="M389.34,539.42c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C388.4,537.5,389.31,538.36,389.34,539.42z"
              />
              <path
                d="M393.73,493.91c-1.09-0.06-1.93-0.97-1.87-2.04c0.06-1.06,1-1.9,2.07-1.85c1.1,0.05,1.89,0.93,1.84,2.05
	C395.71,493.2,394.86,493.97,393.73,493.91z"
              />
              <path
                d="M249.18,365.62c-1.05,0.01-1.97-0.92-1.97-2c0-1.09,0.9-2.01,1.96-2.01c1.05,0,2,0.96,2,2
	C251.17,364.65,250.22,365.61,249.18,365.62z"
              />
              <path
                d="M308.24,438.11c0.07,1.05-0.77,1.98-1.84,2.04c-1.12,0.07-1.98-0.69-2.04-1.81c-0.06-1.1,0.72-2,1.8-2.06
	C307.22,436.23,308.17,437.07,308.24,438.11z"
              />
              <path
                d="M400.21,359.85c-1.05-0.06-1.88-1.03-1.83-2.11c0.06-1.09,0.99-1.93,2.05-1.86c1.05,0.07,1.92,1.05,1.86,2.09
	C402.23,359.01,401.23,359.91,400.21,359.85z"
              />
              <path
                d="M512.25,230.44c1.11-0.06,1.97,0.73,2.02,1.85c0.05,1.13-0.72,1.98-1.83,2.03c-1.08,0.05-2-0.79-2.06-1.84
	C510.33,231.42,511.19,230.49,512.25,230.44z"
              />
              <path
                d="M380.06,538.2c1.03-0.06,2.03,0.84,2.09,1.89c0.06,1.04-0.81,2.03-1.87,2.11c-1.06,0.08-2-0.79-2.06-1.88
	C378.17,539.21,379,538.26,380.06,538.2z"
              />
              <path
                d="M273.93,193.82c-1.02,0.02-2-0.91-2.03-1.93c-0.03-1.02,0.88-1.99,1.92-2.02c1.07-0.04,1.97,0.82,1.99,1.91
	C275.83,192.86,274.97,193.79,273.93,193.82z"
              />
              <path
                d="M340.89,439.04c-0.05,1.04-1.04,1.96-2.07,1.92c-1.05-0.04-1.91-1-1.87-2.09c0.04-1.09,0.97-1.96,2.04-1.9
	C340.05,437.02,340.94,437.99,340.89,439.04z"
              />
              <path
                d="M406.34,487.33c0.04,1.09-0.82,2.04-1.87,2.09c-1.03,0.04-2.02-0.88-2.07-1.92c-0.05-1.05,0.84-2.02,1.9-2.07
	C405.37,485.37,406.3,486.24,406.34,487.33z"
              />
              <path
                d="M343.98,458.39c-1.07,0.02-1.98-0.83-2.01-1.88c-0.03-1.04,0.85-1.96,1.9-1.99c1.1-0.03,1.95,0.77,1.97,1.88
	C345.87,457.53,345.09,458.37,343.98,458.39z"
              />
              <path
                d="M345.68,536.07c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C344.74,534.14,345.65,535,345.68,536.07z"
              />
              <path
                d="M472.36,456.41c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C471.41,454.49,472.33,455.35,472.36,456.41z"
              />
              <path
                d="M498.44,425.22c0.03,1.13-0.75,1.97-1.87,1.99c-1.08,0.02-1.99-0.82-2.02-1.88c-0.03-1.05,0.84-1.96,1.91-1.99
	C497.57,423.3,498.41,424.1,498.44,425.22z"
              />
              <path
                d="M484.3,204.39c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99c1.04,0.01,1.99,0.96,1.99,2
	C486.29,203.43,485.33,204.38,484.3,204.39z"
              />
              <path
                d="M451.44,465.23c0.06,1.09-0.78,2.06-1.84,2.12c-1.03,0.06-2.03-0.85-2.09-1.89c-0.07-1.05,0.81-2.03,1.87-2.1
	C450.44,463.28,451.38,464.14,451.44,465.23z"
              />
              <path
                d="M365.83,187.44c0.04,1.02-0.86,2-1.89,2.06c-1.05,0.06-1.97-0.81-2.01-1.89c-0.04-1.1,0.78-2.02,1.85-2.06
	C364.8,185.5,365.78,186.41,365.83,187.44z"
              />
              <path
                d="M462.5,468.22c0,1.08-0.92,2.01-1.97,2.01c-1.04-0.01-1.99-0.97-1.99-2.01c0-1.04,0.95-2,2-2
	C461.59,466.21,462.5,467.13,462.5,468.22z"
              />
              <path
                d="M374.96,540.68c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C374.02,538.69,374.97,539.64,374.96,540.68z"
              />
              <path
                d="M331.11,62.37c-1.05-0.06-1.9-1.05-1.84-2.13c0.06-1.09,1.02-1.95,2.08-1.88c1.07,0.08,1.93,1.06,1.86,2.12
	C333.15,61.54,332.14,62.44,331.11,62.37z"
              />
              <path
                d="M365.68,539.44c1.07-0.05,2.01,0.79,2.07,1.85c0.06,1.07-0.78,1.98-1.87,2.04c-1.13,0.06-1.98-0.71-2.04-1.84
	C363.79,540.38,364.58,539.49,365.68,539.44z"
              />
              <path
                d="M390.37,537.58c-1.09-0.06-1.93-0.97-1.87-2.04c0.06-1.06,1-1.9,2.08-1.85c1.11,0.05,1.9,0.94,1.84,2.06
	C392.36,536.87,391.51,537.64,390.37,537.58z"
              />
              <path
                d="M501.82,421.34c0.05,1.13-0.73,2-1.85,2.04c-1.09,0.05-2.02-0.78-2.07-1.85c-0.05-1.07,0.79-1.99,1.88-2.05
	C500.89,419.43,501.77,420.22,501.82,421.34z"
              />
              <path
                d="M490.78,212.66c0.02,1.13-0.78,1.97-1.9,1.99c-1.1,0.02-1.99-0.83-2.02-1.9c-0.02-1.07,0.85-1.97,1.93-1.99
	C489.91,210.72,490.76,211.54,490.78,212.66z"
              />
              <path
                d="M428.69,472.48c1.04,0.04,1.95,1,1.92,2.03c-0.03,1.03-1.01,1.96-2.03,1.94c-1.05-0.02-1.92-0.97-1.89-2.05
	C426.72,473.31,427.62,472.44,428.69,472.48z"
              />
              <path
                d="M329.58,459.84c-1.08,0.02-1.99-0.82-2.02-1.88c-0.03-1.05,0.84-1.96,1.91-2c1.11-0.03,1.95,0.77,1.98,1.89
	C331.49,458.98,330.7,459.81,329.58,459.84z"
              />
              <path
                d="M402.29,191.54c0.03,1.05-0.84,1.95-1.91,1.98c-1.12,0.03-1.94-0.76-1.97-1.88c-0.02-1.12,0.77-1.97,1.87-1.99
	C401.34,189.63,402.26,190.5,402.29,191.54z"
              />
              <path
                d="M362.29,214.96c-1.05-0.07-1.89-1.05-1.83-2.14c0.06-1.09,1.03-1.95,2.08-1.87c1.07,0.08,1.93,1.07,1.86,2.12
	C364.33,214.13,363.33,215.03,362.29,214.96z"
              />
              <path
                d="M418.96,238.59c1.06-0.04,1.99,0.81,2.04,1.85c0.05,1.05-0.8,1.96-1.87,2.01c-1.12,0.05-1.96-0.72-2.01-1.84
	C417.08,239.51,417.87,238.64,418.96,238.59z"
              />
              <path
                d="M479.88,189.82c1.04-0.05,2.03,0.86,2.09,1.91c0.05,1.05-0.83,2.03-1.89,2.1c-1.05,0.07-2-0.81-2.06-1.9
	C477.97,190.84,478.82,189.88,479.88,189.82z"
              />
              <path
                d="M503,419.36c-1.05,0-1.95-0.94-1.94-2.02c0.01-1.09,0.91-1.99,1.98-1.98c1.04,0.01,1.98,0.97,1.97,2.01
	C504.99,418.41,504.03,419.36,503,419.36z"
              />
              <path
                d="M324.76,505.36c0.02,1.13-0.78,1.97-1.9,1.99c-1.1,0.02-1.99-0.83-2.02-1.9c-0.02-1.07,0.85-1.97,1.93-1.99
	C323.89,503.43,324.73,504.24,324.76,505.36z"
              />
              <path
                d="M484.37,532.74c1.06,0.04,1.93,0.96,1.89,2c-0.04,1.05-0.95,1.9-2.02,1.87c-1.11-0.03-1.89-0.87-1.86-1.99
	C482.42,533.5,483.26,532.7,484.37,532.74z"
              />
              <path
                d="M454.5,481.11c1.03-0.02,2,0.91,2.03,1.94c0.03,1.02-0.9,2-1.93,2.03c-1.04,0.04-1.96-0.86-1.99-1.94
	C452.58,482.05,453.43,481.13,454.5,481.11z"
              />
              <path
                d="M283.09,210.76c1.09,0.05,1.87,0.93,1.82,2.05c-0.06,1.12-0.91,1.88-2.03,1.82c-1.08-0.06-1.91-0.98-1.85-2.03
	C281.09,211.55,282.03,210.71,283.09,210.76z"
              />
              <path
                d="M458.64,464.78c0.04,1.09-0.82,2.04-1.87,2.09c-1.03,0.04-2.02-0.88-2.07-1.92c-0.05-1.05,0.84-2.02,1.9-2.07
	C457.67,462.82,458.6,463.69,458.64,464.78z"
              />
              <path
                d="M329.9,502.7c1.06-0.07,2,0.79,2.06,1.88c0.05,1.09-0.79,2.05-1.84,2.11c-1.03,0.06-2.03-0.85-2.09-1.9
	C327.97,503.75,328.85,502.77,329.9,502.7z"
              />
              <path
                d="M470.14,474.74c-0.02,1.13-0.86,1.92-1.99,1.89c-1.09-0.03-1.95-0.92-1.92-1.99c0.03-1.06,0.94-1.92,2.02-1.9
	C469.36,472.77,470.16,473.62,470.14,474.74z"
              />
              <path
                d="M374.48,327.74c-0.06,1.04-1.06,1.95-2.09,1.9c-1.05-0.06-1.9-1.02-1.84-2.11c0.05-1.09,0.99-1.95,2.06-1.88
	C373.66,325.71,374.54,326.69,374.48,327.74z"
              />
              <path
                d="M484.55,513.08c0,1.14-0.81,1.95-1.94,1.95c-1.1,0-1.98-0.87-1.98-1.94c0-1.07,0.89-1.95,1.97-1.96
	C483.71,511.12,484.54,511.95,484.55,513.08z"
              />
              <path
                d="M295.14,227.55c1.07-0.05,2.01,0.79,2.07,1.85c0.06,1.07-0.78,1.98-1.87,2.04c-1.13,0.06-1.98-0.71-2.04-1.84
	C293.25,228.48,294.04,227.6,295.14,227.55z"
              />
              <path
                d="M463.23,475.23c-0.03,1.06-0.93,1.91-2.02,1.88c-1.12-0.02-1.9-0.86-1.88-1.99c0.03-1.12,0.87-1.92,1.98-1.89
	C462.39,473.27,463.26,474.18,463.23,475.23z"
              />
              <path
                d="M409.51,523.46c-0.03,1.05-1,1.98-2.04,1.96c-1.05-0.02-1.94-0.98-1.91-2.06c0.03-1.09,0.95-1.99,2.02-1.95
	C408.64,521.44,409.54,522.4,409.51,523.46z"
              />
              <path
                d="M230.18,229.99c-1.07-0.02-1.94-0.91-1.93-1.96c0.02-1.05,0.92-1.92,1.98-1.91c1.11,0.01,1.9,0.84,1.89,1.97
	C232.12,229.21,231.3,230.01,230.18,229.99z"
              />
              <path
                d="M598.66,621.03c0.01,0.63,0.1,1.27,0,1.89c-0.17,1.04-1.08,1.62-2.11,1.49c-0.98-0.13-1.74-1.02-1.7-1.99
	c0.04-0.98,0.86-1.88,1.85-1.83c0.69,0.03,1.36,0.34,2.05,0.53C598.74,621.11,598.66,621.03,598.66,621.03z"
              />
              <path
                d="M417.18,403.42c0.01,1.01-0.93,1.98-1.96,2c-1.04,0.02-1.95-0.88-1.96-1.96c-0.01-1.09,0.85-1.99,1.92-1.99
	C416.21,401.47,417.17,402.4,417.18,403.42z"
              />
              <path
                d="M419.71,224.73c-0.01,0.63,0.07,1.27-0.04,1.88c-0.19,1.02-1.12,1.59-2.15,1.44c-0.98-0.15-1.72-1.05-1.66-2.02
	c0.06-0.97,0.9-1.86,1.88-1.79c0.69,0.05,1.36,0.37,2.04,0.57C419.79,224.8,419.71,224.73,419.71,224.73z"
              />
              <path
                d="M474.76,484.9c0.06,1.04-0.81,2.03-1.86,2.11c-1.06,0.08-2-0.78-2.06-1.88c-0.06-1.1,0.77-2.05,1.83-2.12
	C473.7,482.96,474.7,483.85,474.76,484.9z"
              />
              <path
                d="M409.68,483.66c-0.04,1.09-0.96,1.95-2.01,1.89c-1.03-0.06-1.93-1.04-1.89-2.06c0.04-1.03,1.03-1.94,2.05-1.9
	C408.9,481.64,409.72,482.56,409.68,483.66z"
              />
              <path
                d="M402.97,531.08c0,1.08-0.89,1.99-1.94,1.98c-1.02-0.01-1.98-0.97-1.98-1.98c0-1.02,0.94-1.97,1.98-1.97
	C402.1,529.11,402.97,529.99,402.97,531.08z"
              />
              <path
                d="M440.91,493.42c-1.07,0.02-1.98-0.83-2.01-1.88c-0.03-1.04,0.85-1.96,1.9-1.99c1.1-0.03,1.95,0.77,1.97,1.88
	C442.8,492.56,442.02,493.4,440.91,493.42z"
              />
              <path
                d="M440.88,470.01c-0.06,1.13-0.92,1.89-2.06,1.83c-1.09-0.06-1.93-0.98-1.86-2.05c0.06-1.07,1.01-1.9,2.09-1.84
	C440.15,468,440.94,468.89,440.88,470.01z"
              />
              <path
                d="M308.47,382.1c-1.07,0.02-1.98-0.82-2.01-1.87c-0.03-1.04,0.84-1.96,1.9-1.99c1.1-0.04,1.95,0.77,1.98,1.88
	C310.37,381.24,309.58,382.07,308.47,382.1z"
              />
              <path
                d="M523.41,379.78c-0.05,1.13-0.9,1.9-2.04,1.85c-1.09-0.05-1.94-0.96-1.88-2.04c0.05-1.07,0.99-1.91,2.07-1.86
	C522.67,377.78,523.46,378.66,523.41,379.78z"
              />
              <path
                d="M404.41,173.3c0.03,1.14-0.76,1.98-1.89,2c-1.1,0.02-2-0.82-2.03-1.89c-0.03-1.07,0.84-1.97,1.92-2
	C403.53,171.37,404.38,172.18,404.41,173.3z"
              />
              <path
                d="M252.3,251.57c0,1.14-0.81,1.95-1.94,1.95c-1.1,0-1.98-0.87-1.98-1.94c0-1.07,0.89-1.95,1.97-1.96
	C251.47,249.61,252.3,250.44,252.3,251.57z"
              />
              <path
                d="M386.89,494.67c-1.05,0.01-1.94-0.91-1.94-1.98c0-1.09,0.87-1.97,1.94-1.97c1.03,0.01,1.98,0.95,1.98,1.97
	C388.87,493.71,387.91,494.67,386.89,494.67z"
              />
              <path
                d="M312.75,448.66c-0.06,1.09-0.99,1.94-2.04,1.86c-1.04-0.08-1.92-1.06-1.85-2.09c0.06-1.03,1.06-1.92,2.08-1.86
	C311.99,446.63,312.81,447.57,312.75,448.66z"
              />
              <path
                d="M356.09,345.12c1.05-0.05,2,0.8,2.06,1.84c0.06,1.05-0.78,1.97-1.86,2.03c-1.12,0.06-1.97-0.71-2.02-1.83
	C354.21,346.05,355,345.17,356.09,345.12z"
              />
              <path
                d="M339.16,504.21c0,1.08-0.91,1.99-1.95,1.98c-1.02-0.01-1.98-0.97-1.98-1.99c0-1.02,0.96-1.98,1.99-1.98
	C338.28,502.23,339.16,503.12,339.16,504.21z"
              />
              <path
                d="M245.72,369.43c-1.04-0.05-1.87-1-1.82-2.08c0.05-1.09,0.95-1.92,2.02-1.85c1.04,0.06,1.92,1.03,1.86,2.05
	C247.72,368.58,246.72,369.48,245.72,369.43z"
              />
              <path
                d="M454.59,461.42c0.05,1.01-0.85,1.99-1.88,2.05c-1.05,0.06-1.96-0.79-2.01-1.88c-0.05-1.1,0.76-2.01,1.83-2.05
	C453.56,459.5,454.55,460.4,454.59,461.42z"
              />
              <path
                d="M459.72,459.07c1.04-0.05,2.04,0.88,2.07,1.91c0.02,1.03-0.94,2.01-1.98,2c-0.99,0-1.81-0.79-1.89-1.8
	C457.83,460.06,458.63,459.13,459.72,459.07z"
              />
              <path
                d="M376.41,495.06c1.04,0.05,1.93,1,1.89,2.03c-0.04,1.01-1.03,1.94-2.04,1.9c-1.05-0.04-1.89-0.97-1.85-2.05
	C374.44,495.85,375.33,495.01,376.41,495.06z"
              />
              <path
                d="M399.59,534.81c0.06,1.08-0.76,2.03-1.81,2.09c-1,0.06-2.01-0.85-2.08-1.86c-0.07-1.02,0.8-1.99,1.85-2.07
	C398.63,532.9,399.53,533.71,399.59,534.81z"
              />
              <path
                d="M313.7,502.72c0.03,1.11-0.85,2.02-1.92,2.01c-1.04-0.01-1.99-0.99-1.96-2.02c0.03-0.95,0.88-1.81,1.85-1.87
	C312.77,500.76,313.67,501.6,313.7,502.72z"
              />
              <path
                d="M453.33,486.94c0,1.1-0.92,2-1.98,1.95c-1.05-0.04-1.96-1.04-1.89-2.08c0.06-0.96,0.93-1.78,1.9-1.82
	C452.46,484.96,453.34,485.82,453.33,486.94z"
              />
              <path
                d="M353.24,63.68c1.02-0.03,2,0.89,2.03,1.9c0.03,1.01-0.89,1.99-1.91,2.03c-1.05,0.04-1.95-0.83-1.99-1.91
	C351.34,64.6,352.17,63.71,353.24,63.68z"
              />
              <path
                d="M437.84,125.17c-1.07,0.06-1.99-0.83-2-1.93c-0.01-1.03,0.71-1.86,1.72-1.96c1.05-0.1,2.07,0.74,2.16,1.79
	C439.79,124.11,438.89,125.12,437.84,125.17z"
              />
              <path
                d="M248.44,374.55c0.05,1.08-0.77,2.03-1.81,2.09c-1,0.06-2.01-0.85-2.07-1.87c-0.06-1.02,0.81-1.99,1.85-2.06
	C247.49,372.63,248.39,373.45,248.44,374.55z"
              />
              <path
                d="M334.77,457.25c-0.04-1.05,0.89-2,1.96-2c1.01-0.01,1.82,0.75,1.9,1.77c0.09,1.11-0.72,2.08-1.79,2.14
	C335.83,459.21,334.8,458.28,334.77,457.25z"
              />
              <path
                d="M359.29,214.83c1.07,0,1.93,0.87,1.93,1.96c0,1.08-0.89,1.98-1.93,1.97c-1.01-0.01-1.97-0.97-1.97-1.97
	C357.31,215.76,358.25,214.83,359.29,214.83z"
              />
              <path
                d="M313.22,455.75c0,1.1-0.92,2-1.98,1.95c-1.05-0.04-1.96-1.05-1.89-2.08c0.06-0.96,0.93-1.78,1.9-1.82
	C312.35,453.77,313.22,454.63,313.22,455.75z"
              />
              <path
                d="M559.63,613.17c-1.08,0-1.94-0.91-1.91-2.02c0.03-1.02,0.8-1.83,1.8-1.89c1.04-0.06,2.05,0.87,2.08,1.9
	C561.63,612.19,560.67,613.17,559.63,613.17z"
              />
              <path
                d="M253.24,139.52c0,1.1-0.9,2-1.97,1.97c-1.05-0.03-1.97-1.03-1.91-2.06c0.05-0.95,0.92-1.79,1.89-1.83
	C252.35,137.55,253.24,138.4,253.24,139.52z"
              />
              <path
                d="M394.25,540.73c-1.02-0.04-1.94-1.02-1.91-2.03c0.03-1.01,1.01-1.93,2.03-1.9c1.07,0.03,1.9,0.93,1.86,2.02
	C396.2,539.9,395.3,540.77,394.25,540.73z"
              />
              <path
                d="M448.05,11.96c-0.57,0.25-1.13,0.62-1.73,0.72c-1.04,0.18-1.93-0.5-2.14-1.45c-0.23-1.05,0.45-2.12,1.49-2.34
	c0.99-0.21,1.97,0.27,2.24,1.28c0.16,0.58,0.04,1.24,0.05,1.87C447.98,12.04,448.05,11.96,448.05,11.96z"
              />
              <path
                d="M344.72,442.31c0,1-0.96,1.96-1.97,1.97c-1.05,0.01-1.93-0.9-1.93-1.97c0-1.09,0.86-1.96,1.93-1.96
	C343.78,440.36,344.72,441.29,344.72,442.31z"
              />
              <path
                d="M413.31,526.57c-0.03,1.06-0.98,1.93-2.05,1.87c-1.1-0.06-1.89-0.97-1.79-2.09c0.09-1.03,0.88-1.76,1.89-1.76
	C412.42,524.59,413.34,525.52,413.31,526.57z"
              />
              <path
                d="M371.56,544.8c-0.03,1.06-0.98,1.92-2.04,1.87c-1.1-0.06-1.88-0.97-1.79-2.08c0.08-1.02,0.88-1.76,1.88-1.76
	C370.67,542.83,371.59,543.76,371.56,544.8z"
              />
              <path
                d="M370.77,248.29c-0.04-1.05,0.86-1.99,1.93-2c1.01-0.01,1.82,0.72,1.92,1.73c0.11,1.11-0.68,2.05-1.77,2.12
	C371.77,250.2,370.81,249.35,370.77,248.29z"
              />
              <path
                d="M286.12,250.41c-1.08,0-1.94-0.9-1.91-2.01c0.03-1.02,0.79-1.82,1.79-1.88c1.04-0.06,2.04,0.86,2.08,1.88
	C288.11,249.42,287.15,250.4,286.12,250.41z"
              />
              <path
                d="M467.05,478.42c0.07,1.05-0.81,2-1.89,2.03c-1.03,0.03-1.84-0.67-1.95-1.68c-0.12-1.11,0.64-2.07,1.72-2.16
	C465.99,476.52,466.99,477.37,467.05,478.42z"
              />
              <path
                d="M418.09,480.94c-1.14,0.01-1.95-0.83-1.91-1.98c0.03-1.03,0.78-1.82,1.79-1.88c1.07-0.06,2.04,0.81,2.07,1.87
	C420.07,480.02,419.18,480.93,418.09,480.94z"
              />
              <path
                d="M424.83,243.94c-0.03,1.07-0.97,1.93-2.06,1.88c-1.11-0.05-1.9-0.96-1.81-2.09c0.08-1.03,0.87-1.76,1.9-1.77
	C423.95,241.96,424.86,242.88,424.83,243.94z"
              />
              <path
                d="M257.62,209.34c1.08,0.03,1.89,0.94,1.83,2.05c-0.06,1.02-0.82,1.76-1.84,1.79c-1.06,0.02-1.99-0.87-1.99-1.91
	C255.62,210.22,256.57,209.31,257.62,209.34z"
              />
              <path
                d="M414.81,524.59c-1.11,0.05-1.96-0.76-1.97-1.88c-0.01-1.02,0.7-1.84,1.69-1.94c1.03-0.1,2.05,0.74,2.13,1.78
	C416.74,523.57,415.87,524.54,414.81,524.59z"
              />
              <path
                d="M433.61,9.79c-0.14-0.45-0.36-0.89-0.4-1.34c-0.09-1.19,0.62-1.95,1.78-1.98c1.17-0.04,1.95,0.71,1.92,1.86
	c-0.02,1.07-0.87,1.82-2.05,1.74c-0.45-0.03-0.9-0.24-1.34-0.37C433.53,9.7,433.61,9.79,433.61,9.79z"
              />
              <path
                d="M350.94,347.8c-0.01,1.01-1.01,1.98-2.03,1.94c-1.07-0.04-1.89-0.98-1.83-2.08c0.06-1.02,0.84-1.78,1.85-1.8
	C349.98,345.83,350.95,346.77,350.94,347.8z"
              />
              <path
                d="M468.93,483.81c-1.12-0.01-1.92-0.86-1.88-1.99c0.04-1.01,0.81-1.8,1.8-1.85c1.05-0.05,2.01,0.84,2.04,1.89
	C470.91,482.91,470,483.82,468.93,483.81z"
              />
              <path
                d="M335.07,65.39c-1.13,0.01-1.94-0.84-1.91-1.97c0.03-1.02,0.78-1.82,1.78-1.88c1.06-0.06,2.03,0.82,2.07,1.87
	C337.05,64.47,336.16,65.39,335.07,65.39z"
              />
              <path
                d="M526.07,368.64c0.01,1.05-0.91,1.95-1.98,1.93c-1.03-0.02-1.79-0.75-1.85-1.78c-0.07-1.1,0.74-2.02,1.82-2.06
	C525.11,366.69,526.06,367.59,526.07,368.64z"
              />
              <path
                d="M495.37,223.32c-0.03,1.06-0.98,1.92-2.06,1.87c-1.1-0.06-1.89-0.98-1.79-2.1c0.09-1.02,0.88-1.76,1.9-1.75
	C494.48,221.34,495.4,222.27,495.37,223.32z"
              />
              <path
                d="M530.87,299.61c-0.03,1.06-0.99,1.92-2.06,1.87c-1.1-0.06-1.89-0.98-1.79-2.1c0.09-1.02,0.88-1.76,1.9-1.75
	C529.99,297.63,530.91,298.56,530.87,299.61z"
              />
              <path
                d="M449.93,138.33c1.09-0.03,1.94,0.82,1.93,1.94c-0.01,1.02-0.73,1.81-1.73,1.89c-1.06,0.08-2.03-0.77-2.09-1.81
	C447.98,139.32,448.88,138.36,449.93,138.33z"
              />
              <path
                d="M435.86,120c0.06,1.05-0.83,1.99-1.9,2.01c-1.02,0.03-1.83-0.68-1.93-1.69c-0.12-1.1,0.66-2.06,1.73-2.14
	C434.81,118.09,435.8,118.95,435.86,120z"
              />
              <path
                d="M447.55,462.2c0.01,1.14-0.82,1.96-1.94,1.94c-1.08-0.03-1.96-0.96-1.9-2.02c0.05-0.96,0.89-1.78,1.87-1.83
	C446.68,460.24,447.54,461.07,447.55,462.2z"
              />
              <path
                d="M313.49,63.46c1.07,0.01,1.98,0.95,1.94,2.01c-0.04,1.07-1,1.92-2.09,1.85c-1.02-0.06-1.75-0.84-1.78-1.89
	C311.54,64.28,312.36,63.45,313.49,63.46z"
              />
              <path
                d="M362.43,381.13c-1.14,0.01-1.95-0.83-1.92-1.98c0.03-1.03,0.78-1.82,1.79-1.88c1.07-0.06,2.04,0.81,2.08,1.87
	C364.41,380.21,363.52,381.12,362.43,381.13z"
              />
              <path
                d="M503.69,569.3c-0.04,1.12-0.91,1.92-2.03,1.84c-0.98-0.07-1.78-0.9-1.81-1.86c-0.03-1.04,0.9-1.97,1.95-1.97
	C502.9,567.3,503.73,568.17,503.69,569.3z"
              />
              <path
                d="M426.44,510.82c0.06,1.11-0.78,2.04-1.85,2.06c-1.02,0.02-2.01-0.95-2.01-1.96c0-0.94,0.83-1.82,1.79-1.91
	C425.48,508.91,426.39,509.7,426.44,510.82z"
              />
              <path
                d="M324.23,458.27c0.06,1.14-0.73,2.01-1.85,2.03c-1.08,0.02-2-0.87-2-1.93c0-0.96,0.8-1.82,1.77-1.91
	C323.25,456.36,324.17,457.16,324.23,458.27z"
              />
              <path
                d="M434.41,497.25c1.05,0.02,1.96,0.97,1.92,1.99c-0.04,1.05-1.01,1.9-2.07,1.84c-1-0.06-1.73-0.85-1.75-1.87
	C432.49,498.1,433.32,497.23,434.41,497.25z"
              />
              <path
                d="M382.6,381.2c-0.04,1.06-1,1.91-2.08,1.85c-1.09-0.07-1.88-1-1.77-2.12c0.1-1.02,0.91-1.75,1.92-1.73
	C381.74,379.21,382.64,380.15,382.6,381.2z"
              />
              <path
                d="M247.03,383.8c-1.02,0.01-2.01-0.97-1.99-1.98c0.01-1.02,1.01-1.95,2.05-1.91c1.01,0.04,1.77,0.81,1.82,1.84
	C248.95,382.87,248.11,383.79,247.03,383.8z"
              />
              <path
                d="M465.12,456.86c0.05,1.04-0.84,1.97-1.91,1.99c-1.01,0.01-1.81-0.7-1.91-1.71c-0.1-1.09,0.69-2.05,1.75-2.12
	C464.09,454.95,465.07,455.83,465.12,456.86z"
              />
              <path
                d="M477.77,188.72c0,1.12-0.85,1.94-1.96,1.9c-0.97-0.04-1.81-0.84-1.86-1.8c-0.06-1.03,0.83-1.99,1.88-2.03
	C476.92,186.76,477.77,187.6,477.77,188.72z"
              />
              <path
                d="M574.47,615.28c1.07-0.02,2,0.89,1.99,1.95c-0.01,1.06-0.94,1.94-2.02,1.9c-1.03-0.04-1.78-0.78-1.83-1.82
	C572.55,616.19,573.37,615.3,574.47,615.28z"
              />
              <path
                d="M397.23,490.04c-1.07,0.02-1.98-0.88-1.97-1.93c0.01-1.04,0.97-1.94,2.01-1.9c0.99,0.04,1.76,0.82,1.81,1.82
	C399.13,489.16,398.33,490.02,397.23,490.04z"
              />
              <path
                d="M265.56,342.26c1.06,0.04,1.95,1.02,1.87,2.05c-0.08,1.05-1.06,1.88-2.13,1.78c-1.01-0.09-1.72-0.88-1.71-1.92
	C263.6,343.05,264.46,342.21,265.56,342.26z"
              />
              <path
                d="M427.88,176.71c-1.07-0.01-1.97-0.93-1.93-1.98c0.04-1.04,1.03-1.92,2.07-1.86c0.99,0.06,1.74,0.86,1.77,1.88
	C429.82,175.88,429,176.72,427.88,176.71z"
              />
              <path
                d="M437.5,497.24c-1.07,0-1.98-0.91-1.95-1.97c0.03-1.05,1-1.93,2.05-1.88c0.99,0.05,1.75,0.85,1.79,1.86
	C439.43,496.4,438.62,497.24,437.5,497.24z"
              />
              <path
                d="M521.94,238.95c-0.05,1.1-0.98,1.95-2.05,1.85c-0.95-0.08-1.79-0.98-1.81-1.91c-0.02-1.03,0.93-1.97,1.99-1.96
	C521.16,236.94,521.98,237.83,521.94,238.95z"
              />
              <path
                d="M296.67,75.94c1.06,0,1.98,0.92,1.96,1.97c-0.02,1.05-0.97,1.93-2.04,1.87c-1.01-0.05-1.76-0.81-1.8-1.84
	C294.75,76.82,295.57,75.95,296.67,75.94z"
              />
              <path
                d="M422.29,234.77c1.04-0.05,2.01,0.84,2.04,1.87c0.03,1.05-0.87,1.96-1.94,1.96c-1.02,0-1.8-0.72-1.88-1.74
	C420.43,235.77,421.23,234.83,422.29,234.77z"
              />
              <path
                d="M365.16,199.98c1.09,0.06,1.87,0.99,1.77,2.12c-0.09,1-0.91,1.77-1.9,1.77c-1.03-0.01-1.99-0.99-1.96-2.01
	C363.12,200.83,364.13,199.92,365.16,199.98z"
              />
              <path
                d="M389.79,546.56c0.07,1.04-0.81,1.98-1.88,2.02c-1.01,0.03-1.82-0.67-1.94-1.68c-0.12-1.1,0.64-2.06,1.71-2.15
	C388.72,544.66,389.72,545.52,389.79,546.56z"
              />
              <path
                d="M475.69,452.72c-0.05,1.05-1.03,1.9-2.09,1.82c-1.09-0.08-1.86-1.02-1.74-2.13c0.1-1.01,0.92-1.74,1.93-1.71
	C474.84,450.72,475.74,451.68,475.69,452.72z"
              />
              <path
                d="M432.01,116.83c-0.05,1.05-1.03,1.89-2.08,1.82c-1.08-0.08-1.85-1.02-1.74-2.12c0.1-1,0.93-1.73,1.92-1.71
	C431.16,114.84,432.07,115.8,432.01,116.83z"
              />
              <path
                d="M283.67,387.85c-1.13-0.03-1.92-0.9-1.86-2.04c0.06-1.03,0.83-1.79,1.85-1.82c1.08-0.03,2.01,0.86,2.02,1.93
	C285.68,386.99,284.77,387.88,283.67,387.85z"
              />
              <path
                d="M350.35,446.85c1.06-0.04,2.01,0.86,2.02,1.91c0.01,1.06-0.91,1.96-1.99,1.94c-1.04-0.02-1.8-0.74-1.86-1.78
	C348.45,447.81,349.26,446.89,350.35,446.85z"
              />
              <path
                d="M378.76,543.92c0.06,1.02-0.86,2.02-1.9,2.06c-0.98,0.04-1.84-0.72-1.95-1.71c-0.13-1.12,0.63-2.07,1.72-2.16
	C377.68,542.02,378.7,542.89,378.76,543.92z"
              />
              <path
                d="M385.97,543.36c-0.03,1.07-0.98,1.93-2.06,1.88c-1.11-0.06-1.89-0.97-1.8-2.1c0.09-1.04,0.87-1.76,1.9-1.76
	C385.08,541.38,386,542.3,385.97,543.36z"
              />
              <path
                d="M335.28,500.87c-0.01,1.1-0.92,1.98-1.99,1.93c-1.05-0.05-1.95-1.05-1.87-2.08c0.07-0.94,0.95-1.76,1.91-1.79
	C334.43,498.89,335.29,499.75,335.28,500.87z"
              />
              <path
                d="M391.16,544.75c-1.12-0.03-1.91-0.9-1.85-2.03c0.06-1.02,0.84-1.79,1.84-1.82c1.06-0.03,2.01,0.87,2.01,1.93
	C393.16,543.89,392.23,544.78,391.16,544.75z"
              />
              <path
                d="M344.36,501.55c1.12-0.03,1.96,0.81,1.95,1.95c-0.01,1.04-0.73,1.83-1.75,1.9c-1.09,0.08-2.05-0.75-2.11-1.83
	C342.39,502.53,343.29,501.58,344.36,501.55z"
              />
              <path
                d="M484.99,539.95c1.09-0.01,1.92,0.86,1.89,1.97c-0.03,1.02-0.77,1.8-1.77,1.86c-1.06,0.06-2.02-0.8-2.06-1.85
	C483.03,540.9,483.94,539.96,484.99,539.95z"
              />
              <path
                d="M400.36,542.15c-0.02,1.02-1.03,1.97-2.05,1.92c-1.07-0.05-1.88-1-1.81-2.1c0.07-1.02,0.86-1.77,1.87-1.78
	C399.43,540.18,400.38,541.12,400.36,542.15z"
              />
              <path
                d="M360.53,541.97c-0.05,1.06-1.02,1.9-2.1,1.83c-1.09-0.08-1.86-1.02-1.75-2.13c0.11-1.01,0.92-1.74,1.93-1.72
	C359.68,539.97,360.58,540.92,360.53,541.97z"
              />
              <path
                d="M454.55,152.71c-1.11,0.01-1.92-0.84-1.89-1.96c0.03-1.01,0.79-1.81,1.77-1.87c1.04-0.06,2.02,0.83,2.05,1.86
	C456.52,151.79,455.62,152.71,454.55,152.71z"
              />
              <path
                d="M419.73,229.57c-0.04-1.03,0.87-1.98,1.92-1.99c1-0.02,1.81,0.71,1.9,1.72c0.1,1.1-0.68,2.04-1.75,2.11
	C420.74,231.48,419.77,230.62,419.73,229.57z"
              />
              <path
                d="M473.3,178.18c-0.01,1.07-0.95,1.94-2.03,1.9c-1.12-0.04-1.91-0.93-1.83-2.07c0.07-1.03,0.85-1.78,1.87-1.79
	C472.39,176.21,473.31,177.12,473.3,178.18z"
              />
              <path
                d="M319.04,500.12c1.08,0.03,1.9,0.94,1.83,2.05c-0.06,1.03-0.82,1.77-1.84,1.79c-1.07,0.03-2-0.87-1.99-1.92
	C317.04,501,317.98,500.09,319.04,500.12z"
              />
              <path
                d="M326.19,499.65c1.09,0.01,1.91,0.89,1.87,2c-0.04,1.02-0.78,1.78-1.8,1.83c-1.06,0.05-2.01-0.83-2.03-1.88
	C324.22,500.57,325.15,499.64,326.19,499.65z"
              />
              <path
                d="M258.89,393.59c-1.12,0.07-1.98-0.73-2.01-1.86c-0.02-1.03,0.67-1.85,1.67-1.97c1.04-0.12,2.06,0.7,2.17,1.75
	C260.82,392.55,259.96,393.53,258.89,393.59z"
              />
              <path
                d="M503.22,229.82c-0.04,1.11-0.97,1.95-2.04,1.86c-0.94-0.08-1.8-0.97-1.82-1.9c-0.02-1.03,0.92-1.97,1.98-1.97
	C502.44,227.82,503.27,228.7,503.22,229.82z"
              />
              <path
                d="M401.41,540.21c-1.04-0.06-1.93-1.07-1.84-2.09c0.09-1.04,1.1-1.86,2.15-1.75c1,0.1,1.7,0.91,1.68,1.94
	C403.39,539.42,402.49,540.27,401.41,540.21z"
              />
              <path
                d="M322.12,350.4c-0.13,1.03-1.18,1.84-2.2,1.69c-1.08-0.16-1.78-1.13-1.6-2.24c0.16-0.99,1.02-1.66,2.03-1.57
	C321.41,348.37,322.25,349.37,322.12,350.4z"
              />
              <path
                d="M343.9,435.21c-0.05,1.1-0.98,1.93-2.05,1.83c-0.94-0.09-1.78-0.99-1.79-1.91c-0.01-1.02,0.95-1.95,1.99-1.94
	C343.16,433.22,343.96,434.09,343.9,435.21z"
              />
              <path
                d="M398.14,81.48c1.11-0.01,1.93,0.84,1.91,1.97c-0.02,1-0.8,1.83-1.77,1.89c-1.01,0.07-2.03-0.86-2.07-1.87
	C396.16,82.44,397.09,81.49,398.14,81.48z"
              />
              <path
                d="M378.82,5.85c-0.01,0.98-0.83,1.79-1.8,1.77c-0.93-0.02-1.83-0.91-1.83-1.81c0-0.92,0.87-1.71,1.87-1.71
	C378.14,4.1,378.83,4.78,378.82,5.85z"
              />
              <path
                d="M349.49,539.13c0.06,1.01-0.86,2.01-1.9,2.04c-0.98,0.03-1.83-0.71-1.93-1.71c-0.12-1.12,0.63-2.05,1.72-2.14
	C348.42,537.24,349.43,538.1,349.49,539.13z"
              />
              <path
                d="M290.06,253.75c-1.07,0.06-1.98-0.79-2-1.89c-0.02-1.03,0.68-1.84,1.68-1.94c1.05-0.11,2.06,0.71,2.16,1.75
	C291.99,252.68,291.1,253.68,290.06,253.75z"
              />
              <path
                d="M423.37,475.22c-0.03,1.04-0.98,1.91-2.03,1.86c-1.08-0.05-1.87-0.98-1.78-2.07c0.08-0.99,0.89-1.75,1.87-1.75
	C422.47,473.26,423.4,474.2,423.37,475.22z"
              />
              <path
                d="M251.57,390.5c1.05,0.01,1.99,0.95,1.95,1.97c-0.04,1.01-1.06,1.95-2.07,1.88c-0.97-0.06-1.75-0.88-1.78-1.88
	C249.64,391.35,250.47,390.49,251.57,390.5z"
              />
              <path
                d="M257.06,348.71c0.02,1.1-0.85,1.99-1.93,1.97c-1.03-0.02-1.97-1.01-1.92-2.02c0.04-0.94,0.89-1.77,1.86-1.82
	C256.18,346.78,257.04,347.59,257.06,348.71z"
              />
              <path
                d="M527.87,57.97c-0.04,0.93-0.96,1.71-1.95,1.67c-1.04-0.05-1.77-0.79-1.77-1.83c-0.01-1.01,0.79-1.85,1.76-1.85
	C526.89,55.97,527.91,57.01,527.87,57.97z"
              />
              <path
                d="M358.81,504.23c-1.03,0-1.99-0.98-1.95-1.99c0.03-1.02,1.03-1.92,2.07-1.87c1,0.05,1.75,0.83,1.78,1.86
	C360.73,503.33,359.88,504.23,358.81,504.23z"
              />
              <path
                d="M343.72,348.16c0.06,1.01-0.87,1.99-1.9,2.02c-0.99,0.03-1.81-0.71-1.92-1.71c-0.12-1.12,0.62-2.04,1.72-2.12
	C342.67,346.28,343.66,347.14,343.72,348.16z"
              />
              <path
                d="M512.15,582.79c0.07,1.02-0.85,2.01-1.89,2.05c-0.98,0.04-1.83-0.71-1.94-1.7c-0.13-1.12,0.62-2.06,1.71-2.15
	C511.08,580.9,512.09,581.76,512.15,582.79z"
              />
              <path
                d="M322.14,390.03c-0.01,1-1.02,1.95-2.03,1.91c-1.07-0.04-1.87-0.96-1.81-2.07c0.06-1.02,0.83-1.75,1.84-1.77
	C321.19,388.09,322.15,389.02,322.14,390.03z"
              />
              <path
                d="M431.77,472.55c-1.02,0.01-2-0.95-1.98-1.95c0.02-1.01,1-1.92,2.04-1.88c1.01,0.04,1.76,0.79,1.8,1.81
	C433.67,471.64,432.85,472.54,431.77,472.55z"
              />
              <path
                d="M335.08,374.47c-0.03,1.04-0.99,1.91-2.04,1.85c-1.07-0.06-1.86-0.99-1.77-2.08c0.09-1,0.9-1.74,1.88-1.74
	C334.19,372.5,335.11,373.44,335.08,374.47z"
              />
              <path
                d="M531.51,266.92c0,1.11-0.84,1.94-1.94,1.91c-1.06-0.03-1.94-0.98-1.87-2.01c0.06-0.94,0.91-1.76,1.86-1.8
	C530.64,264.97,531.5,265.81,531.51,266.92z"
              />
              <path
                d="M461.77,480.46c1.04,0.02,1.95,0.97,1.91,1.99c-0.04,1.04-1.01,1.9-2.06,1.83c-0.99-0.06-1.73-0.85-1.75-1.86
	C459.84,481.31,460.69,480.44,461.77,480.46z"
              />
              <path
                d="M353.5,502.74c0.02,1.1-0.84,1.98-1.92,1.96c-1.03-0.02-1.97-1-1.92-2.01c0.04-0.94,0.89-1.76,1.85-1.81
	C352.63,500.82,353.48,501.62,353.5,502.74z"
              />
              <path
                d="M338.15,410.13c0.02,1.1-0.85,1.99-1.92,1.97c-1.03-0.02-1.97-1-1.92-2.02c0.04-0.94,0.89-1.77,1.85-1.83
	C337.27,408.2,338.13,409.01,338.15,410.13z"
              />
              <path
                d="M423.09,514.76c0,1.1-0.88,1.98-1.95,1.95c-1.04-0.03-1.96-1.02-1.9-2.04c0.06-0.94,0.91-1.76,1.88-1.8
	C422.22,512.81,423.08,513.64,423.09,514.76z"
              />
              <path
                d="M389.79,380.53c-0.07,1.01-1.1,1.89-2.11,1.8c-1.07-0.1-1.82-1.05-1.7-2.15c0.11-1.02,0.9-1.7,1.93-1.67
	C388.97,378.55,389.86,379.5,389.79,380.53z"
              />
              <path
                d="M431.03,501.35c1.07,0.01,1.98,0.93,1.95,1.96c-0.03,1-1.06,1.93-2.06,1.87c-0.97-0.06-1.74-0.87-1.77-1.87
	C429.11,502.19,429.93,501.35,431.03,501.35z"
              />
              <path
                d="M448.78,456.47c1.04,0,1.97,0.94,1.94,1.96c-0.03,1.04-0.98,1.91-2.03,1.86c-0.99-0.05-1.74-0.82-1.78-1.83
	C446.87,457.35,447.7,456.47,448.78,456.47z"
              />
              <path
                d="M420.51,399.64c-0.02,1.01-1.04,1.96-2.04,1.91c-1.07-0.05-1.87-0.99-1.8-2.1c0.07-1.02,0.85-1.76,1.86-1.76
	C419.59,397.68,420.53,398.62,420.51,399.64z"
              />
              <path
                d="M251,110.74c0.03,0.99-0.93,1.99-1.93,2.02c-0.94,0.03-1.8-0.84-1.8-1.81c0-1.01,0.97-2.04,1.91-2.03
	C250.11,108.92,250.97,109.79,251,110.74z"
              />
              <path
                d="M455.65,15.06c-0.13-0.45-0.34-0.89-0.38-1.35c-0.11-1.19,0.62-2.01,1.75-2.05c1.15-0.04,1.99,0.76,1.96,1.88
	c-0.02,1.07-0.9,1.86-2.06,1.77c-0.46-0.03-0.91-0.22-1.36-0.34C455.57,14.97,455.65,15.06,455.65,15.06z"
              />
              <path
                d="M410.11,530.25c0.06,1.11-0.74,1.99-1.83,2.02c-1.06,0.02-1.98-0.87-1.98-1.91c0-0.93,0.81-1.81,1.75-1.9
	C409.11,528.34,410.04,529.16,410.11,530.25z"
              />
              <path
                d="M355.91,218.71c1.11-0.02,1.94,0.83,1.92,1.95c-0.02,1-0.79,1.83-1.77,1.9c-1.01,0.07-2.04-0.84-2.08-1.86
	C353.93,219.68,354.85,218.73,355.91,218.71z"
              />
              <path
                d="M271.27,181.2c0.04,1.01-0.9,1.99-1.92,2c-0.98,0.01-1.8-0.73-1.9-1.73c-0.11-1.12,0.65-2.04,1.74-2.1
	C270.23,179.31,271.22,180.18,271.27,181.2z"
              />
              <path
                d="M526.48,605.2c-1.09-0.02-1.89-0.91-1.83-2.02c0.06-1,0.84-1.78,1.82-1.8c1.04-0.03,1.99,0.88,1.99,1.91
	C528.46,604.33,527.53,605.22,526.48,605.2z"
              />
              <path
                d="M256.25,365.08c-1.07-0.06-1.86-1.02-1.77-2.12c0.08-1.02,0.87-1.74,1.89-1.74c1.05,0.01,1.99,0.95,1.95,1.97
	C258.29,364.21,257.26,365.14,256.25,365.08z"
              />
              <path
                d="M429.62,507c0.05,1.01-0.89,2-1.92,2.02c-0.99,0.02-1.82-0.73-1.92-1.73c-0.11-1.12,0.65-2.05,1.74-2.12
	C428.57,505.1,429.57,505.98,429.62,507z"
              />
              <path
                d="M342.45,500.19c-0.03,1.11-0.91,1.91-2,1.84c-0.96-0.06-1.78-0.89-1.81-1.84c-0.03-1.02,0.89-1.97,1.93-1.97
	C341.65,498.21,342.48,499.08,342.45,500.19z"
              />
              <path
                d="M380.65,549.33c-1.08,0-1.93-0.89-1.89-2c0.03-1.02,0.78-1.8,1.78-1.86c1.04-0.05,2.04,0.85,2.07,1.87
	C382.63,548.36,381.68,549.33,380.65,549.33z"
              />
              <path
                d="M379.44,76.21c-1.03,0-1.99-0.98-1.95-1.99c0.03-1.02,1.03-1.92,2.07-1.87c1,0.05,1.75,0.83,1.78,1.86
	C381.37,75.32,380.52,76.22,379.44,76.21z"
              />
              <path
                d="M395.56,405.36c0,1-0.99,1.96-2,1.94c-1.08-0.03-1.89-0.93-1.84-2.04c0.05-1.02,0.8-1.77,1.82-1.79
	C394.58,403.43,395.55,404.35,395.56,405.36z"
              />
              <path
                d="M236.38,240.05c-0.53,0.09-1.07,0.26-1.6,0.25c-1.14-0.01-1.93-0.88-1.9-1.98c0.03-1.12,0.91-1.92,2.02-1.85
	c1.08,0.07,1.9,0.97,1.8,2.08c-0.05,0.53-0.27,1.05-0.41,1.58C236.29,240.13,236.38,240.05,236.38,240.05z"
              />
              <path
                d="M396.97,546.17c-0.13,1.02-1.18,1.83-2.2,1.68c-1.08-0.16-1.77-1.12-1.6-2.23c0.16-0.99,1-1.65,2.02-1.56
	C396.26,544.15,397.1,545.16,396.97,546.17z"
              />
              <path
                d="M524.79,354.29c-0.02,1.11-0.88,1.92-1.98,1.86c-0.96-0.05-1.79-0.87-1.83-1.82c-0.05-1.02,0.86-1.98,1.9-2
	C523.96,352.32,524.81,353.19,524.79,354.29z"
              />
              <path
                d="M292.56,385.2c0,1.1-0.87,1.97-1.94,1.94c-1.03-0.03-1.95-1.02-1.89-2.03c0.06-0.94,0.91-1.75,1.87-1.79
	C291.71,383.27,292.56,384.08,292.56,385.2z"
              />
              <path
                d="M547.74,79.3c-0.15-0.69-0.41-1.37-0.42-2.06c-0.01-0.95,0.82-1.66,1.69-1.64c0.79,0.02,1.89,1.19,1.95,2.12
	c0.06,0.98-0.53,1.49-1.41,1.59c-0.62,0.07-1.26-0.06-1.89-0.1C547.66,79.23,547.74,79.3,547.74,79.3z"
              />
              <path
                d="M349.63,499.65c0,1.02-0.76,1.83-1.76,1.89c-1.06,0.06-2.03-0.85-2.03-1.9c0-1.04,1-1.97,2.04-1.9
	C348.86,497.8,349.64,498.65,349.63,499.65z"
              />
              <path
                d="M476.92,450.47c-1.03,0.04-2.03-0.94-2.01-1.96c0.03-0.95,0.88-1.79,1.87-1.83c1.05-0.04,1.85,0.66,1.94,1.69
	C478.82,449.49,478.01,450.43,476.92,450.47z"
              />
              <path
                d="M417.97,520.74c-1.09-0.06-1.88-1.02-1.75-2.14c0.12-1,0.99-1.74,1.98-1.67c0.96,0.07,1.8,0.95,1.8,1.9
	C420.01,519.9,419.03,520.8,417.97,520.74z"
              />
              <path
                d="M309.35,432.29c1.05-0.02,1.83,0.68,1.9,1.72c0.08,1.12-0.74,2.03-1.84,2.05c-0.96,0.01-1.86-0.8-1.95-1.75
	C307.37,433.27,308.26,432.31,309.35,432.29z"
              />
              <path
                d="M381.62,493.16c0.01,1.01-1.01,1.97-2.03,1.92c-1-0.05-1.76-0.87-1.76-1.89c-0.01-1.05,0.71-1.83,1.74-1.89
	C380.63,491.24,381.61,492.12,381.62,493.16z"
              />
              <path
                d="M525.26,361.49c-0.02,1.02-0.79,1.82-1.79,1.86c-1.07,0.04-2.02-0.88-1.99-1.93c0.03-1.04,1.03-1.95,2.07-1.86
	C524.53,359.63,525.28,360.48,525.26,361.49z"
              />
              <path
                d="M486.63,435.15c1.08-0.03,2.03,0.89,2,1.94c-0.03,0.94-0.91,1.81-1.86,1.85c-1.1,0.05-1.97-0.83-1.95-1.96
	C484.84,435.94,485.59,435.18,486.63,435.15z"
              />
              <path
                d="M385.15,77.29c0.05,1.04-0.64,1.88-1.65,1.99c-1.06,0.12-2.08-0.73-2.14-1.78c-0.06-1.03,0.88-2.02,1.93-2.02
	C384.28,75.48,385.09,76.27,385.15,77.29z"
              />
              <path
                d="M362.6,191.36c0,1.03-0.76,1.85-1.76,1.91c-1.03,0.06-2.05-0.89-2.05-1.91c0-1.04,0.98-1.94,2.05-1.89
	C361.87,189.53,362.6,190.32,362.6,191.36z"
              />
              <path
                d="M362.32,543.33c1.03-0.05,2.02,0.89,2.01,1.93c-0.01,0.96-0.84,1.81-1.81,1.87c-1.01,0.06-1.85-0.66-1.96-1.67
	C360.44,544.36,361.25,543.38,362.32,543.33z"
              />
              <path
                d="M428.81,373.57c-0.06,1.03-0.85,1.78-1.87,1.78c-1.07,0-2-0.97-1.92-2.01c0.07-1.05,1.12-1.91,2.15-1.78
	C428.16,371.68,428.87,372.55,428.81,373.57z"
              />
              <path
                d="M426.1,111.47c1.07,0.07,1.87,1.06,1.73,2.15c-0.12,1.01-0.98,1.72-1.98,1.65c-0.97-0.07-1.79-0.93-1.79-1.89
	C424.07,112.33,425.06,111.41,426.1,111.47z"
              />
              <path
                d="M408.1,405.83c-1.01,0.06-1.86-0.67-1.96-1.69c-0.12-1.15,0.65-2.06,1.77-2.1c0.98-0.04,1.9,0.72,2.01,1.67
	C410.05,404.74,409.16,405.77,408.1,405.83z"
              />
              <path
                d="M412.8,479.83c-0.05,1.04-1.08,1.91-2.12,1.8c-1-0.1-1.71-0.94-1.67-1.97c0.04-1.13,0.94-1.9,2.08-1.78
	C412.06,477.99,412.85,478.88,412.8,479.83z"
              />
              <path
                d="M423.08,371.98c-1.06-0.01-1.98-0.99-1.9-2.03c0.08-0.95,0.98-1.77,1.93-1.77c1.07,0,1.93,0.94,1.85,2.04
	C424.9,371.25,424.1,372,423.08,371.98z"
              />
              <path
                d="M479.34,515.3c1.03,0.04,1.77,0.8,1.78,1.85c0.01,1.12-0.86,1.98-1.96,1.92c-0.95-0.05-1.81-0.92-1.83-1.86
	C477.3,516.16,478.26,515.25,479.34,515.3z"
              />
              <path
                d="M396.49,352.62c1.09,0.01,2,0.96,1.92,2c-0.07,0.95-0.97,1.78-1.93,1.79c-1.09,0-1.94-0.91-1.88-2.03
	C394.66,353.34,395.44,352.62,396.49,352.62z"
              />
              <path
                d="M518.04,381.62c1-0.04,1.85,0.71,1.94,1.72c0.1,1.11-0.72,2.05-1.81,2.08c-0.98,0.03-1.88-0.75-1.98-1.72
	C516.08,382.69,516.99,381.66,518.04,381.62z"
              />
              <path
                d="M466.54,455c-1.02,0-1.81-0.75-1.87-1.79c-0.06-1.11,0.8-2.03,1.89-2.02c0.96,0.01,1.85,0.85,1.91,1.8
	C468.54,454.04,467.61,455,466.54,455z"
              />
              <path
                d="M532.28,606.49c-0.04,1.03-1.07,1.92-2.1,1.82c-1-0.09-1.72-0.93-1.69-1.95c0.03-1.13,0.92-1.91,2.06-1.8
	C531.53,604.65,532.32,605.54,532.28,606.49z"
              />
              <path
                d="M292.55,219.07c0.06,1.12-0.79,2.03-1.88,2.03c-0.96,0-1.86-0.84-1.92-1.79c-0.08-1.05,0.84-1.99,1.93-2
	C291.71,217.3,292.49,218.03,292.55,219.07z"
              />
              <path
                d="M361.3,196.67c1.03,0.04,1.77,0.81,1.79,1.86c0.01,1.12-0.88,1.99-1.97,1.93c-0.96-0.05-1.82-0.94-1.84-1.88
	C359.25,197.54,360.21,196.63,361.3,196.67z"
              />
              <path
                d="M375.37,548.03c-0.09,1.05-1.13,1.87-2.18,1.72c-1.01-0.14-1.68-0.98-1.61-2.01c0.08-1.12,1-1.87,2.13-1.71
	C374.69,546.16,375.45,547.08,375.37,548.03z"
              />
              <path
                d="M347.24,431.29c-0.01,1.02-0.77,1.84-1.76,1.89c-1.02,0.05-2.04-0.91-2.03-1.92c0.01-1.04,0.98-1.93,2.06-1.86
	C346.53,429.47,347.25,430.25,347.24,431.29z"
              />
              <path
                d="M357.77,244.13c-1.06-0.05-1.96-1.07-1.85-2.09c0.1-0.96,1.01-1.73,1.99-1.7c1.13,0.03,1.9,0.93,1.8,2.08
	C359.62,243.43,358.78,244.17,357.77,244.13z"
              />
              <path
                d="M247.72,390.96c-1.01,0-1.81-0.78-1.86-1.8c-0.06-1.15,0.75-2,1.89-1.99c0.98,0.01,1.86,0.83,1.92,1.78
	C249.73,389.97,248.77,390.97,247.72,390.96z"
              />
              <path
                d="M308.68,445.49c-0.04,1.06-1.04,1.93-2.1,1.83c-1.01-0.09-1.73-0.92-1.69-1.96c0.03-1.01,0.83-1.82,1.82-1.85
	C307.75,443.49,308.72,444.45,308.68,445.49z"
              />
              <path
                d="M320.88,521.74c0.98-0.01,1.81,0.77,1.88,1.78c0.07,1.12-0.76,2.02-1.86,2.02c-0.97,0-1.85-0.8-1.92-1.76
	C318.91,522.74,319.84,521.75,320.88,521.74z"
              />
              <path
                d="M353.3,376.35c-0.01,1.05-0.99,1.95-2.05,1.88c-1-0.07-1.74-0.89-1.73-1.91c0.01-1,0.79-1.84,1.77-1.89
	C352.32,374.37,353.31,375.31,353.3,376.35z"
              />
              <path
                d="M255.67,191.57c-1.07,0.01-2-0.93-1.95-1.98c0.05-0.95,0.92-1.8,1.88-1.82c1.08-0.03,1.95,0.88,1.9,1.99
	C257.46,190.78,256.68,191.55,255.67,191.57z"
              />
              <path
                d="M362.03,207.65c-1.01,0.06-1.85-0.66-1.95-1.67c-0.12-1.14,0.64-2.04,1.76-2.09c0.98-0.04,1.89,0.71,2.01,1.66
	C363.97,206.56,363.09,207.59,362.03,207.65z"
              />
              <path
                d="M417.3,363.64c-0.01,1.02-0.77,1.84-1.76,1.89c-1.02,0.05-2.04-0.91-2.03-1.92c0.01-1.04,0.98-1.93,2.06-1.86
	C416.58,361.81,417.3,362.59,417.3,363.64z"
              />
              <path
                d="M355.22,231.96c-0.03,1.05-1.03,1.93-2.09,1.83c-1-0.09-1.72-0.92-1.69-1.95c0.03-1,0.83-1.82,1.81-1.85
	C354.28,229.96,355.25,230.92,355.22,231.96z"
              />
              <path
                d="M479.76,526.01c-1.07,0.01-2-0.93-1.95-1.98c0.05-0.95,0.92-1.8,1.88-1.82c1.08-0.03,1.95,0.88,1.9,1.99
	C481.55,525.23,480.76,526,479.76,526.01z"
              />
              <path
                d="M281.02,209.33c0.01,1.03-0.74,1.85-1.74,1.92c-1.06,0.07-2.04-0.83-2.05-1.89c-0.01-1.04,0.98-1.98,2.03-1.93
	C280.23,207.49,281.01,208.32,281.02,209.33z"
              />
              <path
                d="M393.13,356.46c1.09,0.01,2,0.96,1.92,2c-0.07,0.95-0.97,1.78-1.93,1.79c-1.09,0-1.94-0.91-1.88-2.03
	C391.31,357.18,392.08,356.46,393.13,356.46z"
              />
              <path
                d="M421.13,367.13c-0.07,1.02-0.89,1.78-1.9,1.76c-1.05-0.02-1.99-1.03-1.9-2.05c0.09-1.06,1.11-1.86,2.19-1.72
	C420.56,365.25,421.21,366.06,421.13,367.13z"
              />
              <path
                d="M478.13,500.91c0.99,0.04,1.83,0.86,1.86,1.82c0.03,1.01-0.97,1.99-2,1.95c-1.09-0.04-1.89-0.97-1.79-2.09
	C476.29,501.56,477.08,500.87,478.13,500.91z"
              />
              <path
                d="M456.02,455.79c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C454.18,456.48,454.97,455.77,456.02,455.79z"
              />
              <path
                d="M477.31,493.7c1.05-0.01,1.83,0.71,1.89,1.75c0.07,1.12-0.77,2.04-1.86,2.04c-0.96,0-1.86-0.82-1.94-1.78
	C475.32,494.67,476.22,493.71,477.31,493.7z"
              />
              <path
                d="M512.45,396.77c1.04,0.04,1.77,0.81,1.79,1.86c0.01,1.12-0.88,1.99-1.97,1.93c-0.96-0.05-1.82-0.94-1.84-1.88
	C510.41,397.63,511.37,396.72,512.45,396.77z"
              />
              <path
                d="M301.12,382.8c-1.06-0.07-1.94-1.1-1.81-2.12c0.12-0.96,1.04-1.71,2.02-1.67c1.12,0.05,1.89,0.97,1.76,2.11
	C302.98,382.13,302.12,382.86,301.12,382.8z"
              />
              <path
                d="M478.57,508.09c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C476.74,508.79,477.52,508.07,478.57,508.09z"
              />
              <path
                d="M482.08,444.83c-0.06,1.02-0.86,1.8-1.87,1.79c-1.05,0-2-1-1.94-2.02c0.07-1.04,1.1-1.88,2.17-1.76
	C481.47,442.96,482.14,443.77,482.08,444.83z"
              />
              <path
                d="M417.53,97.83c1.08-0.03,2.03,0.89,2,1.94c-0.03,0.94-0.91,1.81-1.86,1.85c-1.1,0.05-1.97-0.83-1.95-1.96
	C415.74,98.61,416.49,97.85,417.53,97.83z"
              />
              <path
                d="M489.61,550.32c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C487.77,551.01,488.56,550.3,489.61,550.32z"
              />
              <path
                d="M442.4,131.89c1.03,0.04,1.77,0.82,1.78,1.87c0.01,1.12-0.88,1.99-1.98,1.92c-0.95-0.06-1.82-0.94-1.83-1.88
	C440.35,132.75,441.32,131.85,442.4,131.89z"
              />
              <path
                d="M259.52,357.14c0.99,0.02,1.79,0.83,1.82,1.84c0.04,1.13-0.82,2-1.93,1.96c-0.98-0.03-1.83-0.86-1.87-1.83
	C257.51,358.08,258.47,357.12,259.52,357.14z"
              />
              <path
                d="M386.41,550.62c-0.06,1.06-1.08,1.9-2.14,1.78c-1.01-0.12-1.71-0.95-1.65-1.99c0.05-1.01,0.87-1.8,1.86-1.8
	C385.53,548.6,386.48,549.58,386.41,550.62z"
              />
              <path
                d="M260.21,344.97c-0.04,1.02-1.07,1.92-2.1,1.83c-1-0.09-1.73-0.92-1.7-1.95c0.03-1.13,0.91-1.91,2.05-1.81
	C259.45,343.14,260.24,344.01,260.21,344.97z"
              />
              <path
                d="M391.63,548.13c1.04-0.03,2.01,0.94,1.97,1.97c-0.03,0.96-0.88,1.79-1.85,1.83c-1.11,0.04-1.96-0.83-1.93-1.95
	C389.85,548.97,390.66,548.15,391.63,548.13z"
              />
              <path
                d="M371.88,322.35c-1.03,0.06-2.05-0.89-2.05-1.91c0-0.96,0.82-1.81,1.81-1.88c1.04-0.07,1.87,0.61,1.99,1.64
	C373.75,321.31,372.96,322.29,371.88,322.35z"
              />
              <path
                d="M607.84,623.26c1.05-0.01,1.81,0.71,1.87,1.75c0.06,1.12-0.77,2.02-1.87,2.02c-0.95,0-1.85-0.83-1.92-1.78
	C605.85,624.21,606.75,623.27,607.84,623.26z"
              />
              <path
                d="M406.74,534.54c-0.05,1.02-0.83,1.8-1.84,1.81c-1.03,0.01-2.01-0.98-1.96-1.99c0.05-1.03,1.07-1.88,2.14-1.77
	C406.11,532.68,406.78,533.48,406.74,534.54z"
              />
              <path
                d="M393.8,363.66c1.05-0.02,1.83,0.69,1.91,1.73c0.08,1.12-0.75,2.05-1.84,2.06c-0.95,0.01-1.87-0.81-1.95-1.76
	C391.82,364.65,392.71,363.69,393.8,363.66z"
              />
              <path
                d="M299.33,234.75c-1.06,0.01-1.99-0.94-1.94-1.97c0.05-0.94,0.93-1.79,1.87-1.81c1.07-0.03,1.94,0.89,1.9,1.98
	C301.12,233.96,300.34,234.73,299.33,234.75z"
              />
              <path
                d="M406.12,546.7c1.02,0.02,1.96,1.05,1.86,2.05c-0.09,0.94-0.98,1.75-1.93,1.73c-1.07-0.01-1.91-0.95-1.82-2.04
	C404.31,547.46,405.16,546.67,406.12,546.7z"
              />
              <path
                d="M262.43,183.88c-1.04,0.04-2-0.9-1.97-1.93c0.02-0.93,0.89-1.8,1.82-1.85c1.06-0.05,1.95,0.85,1.93,1.93
	C264.19,183.04,263.41,183.84,262.43,183.88z"
              />
              <path
                d="M368.16,548.7c-0.06,1.04-1.09,1.89-2.12,1.77c-0.98-0.12-1.69-0.97-1.63-1.98c0.05-0.98,0.88-1.79,1.84-1.79
	C367.26,546.69,368.22,547.69,368.16,548.7z"
              />
              <path
                d="M349.61,459.77c0.03,1-0.71,1.84-1.68,1.92c-1.03,0.09-2.02-0.79-2.05-1.83c-0.03-1,0.96-1.98,1.96-1.94
	C348.78,457.97,349.59,458.8,349.61,459.77z"
              />
              <path
                d="M467.47,485.79c-0.03,1.03-1.03,1.91-2.06,1.82c-0.97-0.09-1.71-0.93-1.68-1.93c0.03-0.97,0.84-1.81,1.78-1.84
	C466.53,483.81,467.51,484.78,467.47,485.79z"
              />
              <path
                d="M261.55,338.92c1.02-0.06,2.02,0.89,2.01,1.92c-0.01,0.95-0.83,1.81-1.8,1.87c-0.99,0.06-1.85-0.66-1.96-1.66
	C259.68,339.97,260.49,338.98,261.55,338.92z"
              />
              <path
                d="M345.59,496.34c-0.03,1.03-1.03,1.92-2.06,1.82c-0.97-0.09-1.71-0.93-1.68-1.92c0.03-0.97,0.83-1.81,1.78-1.84
	C344.64,494.36,345.62,495.34,345.59,496.34z"
              />
              <path
                d="M438.52,460.81c1.04,0.03,1.88,1.01,1.79,2.07c-0.09,0.99-0.93,1.73-1.91,1.7c-0.95-0.04-1.8-0.88-1.83-1.82
	C436.53,461.75,437.51,460.78,438.52,460.81z"
              />
              <path
                d="M380.59,585.09c1.01-0.03,1.98,0.95,1.95,1.95c-0.03,0.94-0.88,1.78-1.83,1.82c-0.98,0.04-1.82-0.71-1.91-1.7
	C378.71,586.1,379.55,585.12,380.59,585.09z"
              />
              <path
                d="M427.75,378.76c1.02,0.05,1.93,1.09,1.81,2.09c-0.11,0.95-1.02,1.73-1.97,1.69c-1.06-0.04-1.89-1-1.78-2.08
	C425.92,379.48,426.79,378.71,427.75,378.76z"
              />
              <path
                d="M284.1,391.22c1.02-0.06,2.02,0.89,2.01,1.92c-0.01,0.95-0.83,1.81-1.8,1.87c-0.99,0.06-1.85-0.66-1.96-1.66
	C282.23,392.27,283.04,391.28,284.1,391.22z"
              />
              <path
                d="M411.99,531.82c0.97-0.05,1.84,0.71,1.94,1.7c0.11,1.09-0.71,2.05-1.79,2.09c-0.96,0.04-1.87-0.74-1.98-1.7
	C410.05,532.91,410.95,531.87,411.99,531.82z"
              />
              <path
                d="M365.09,239.6c0.95,0.02,1.77,0.85,1.8,1.83c0.04,1.1-0.82,1.99-1.91,1.95c-0.96-0.03-1.81-0.87-1.85-1.81
	C363.1,240.55,364.07,239.57,365.09,239.6z"
              />
              <path
                d="M264.04,387.77c0.05,1.04-0.89,1.99-1.95,1.97c-1-0.02-1.78-0.8-1.82-1.81c-0.04-1,0.69-1.87,1.67-1.97
	C262.96,385.84,263.99,386.73,264.04,387.77z"
              />
              <path
                d="M420.45,526.05c-0.01,1.03-0.99,1.94-2.02,1.87c-0.97-0.07-1.73-0.9-1.72-1.89c0.01-0.97,0.8-1.82,1.74-1.88
	C419.45,524.09,420.46,525.05,420.45,526.05z"
              />
              <path
                d="M333.14,352.78c0.03,1.04-0.93,1.97-1.99,1.93c-0.99-0.04-1.76-0.84-1.78-1.85c-0.02-0.99,0.74-1.86,1.7-1.94
	C332.09,350.83,333.11,351.75,333.14,352.78z"
              />
              <path
                d="M523.92,54.89c-0.05,0.93-1,1.75-1.93,1.66c-0.96-0.09-1.71-0.97-1.66-1.95c0.06-1.07,0.82-1.72,1.89-1.61
	C523.22,53.09,523.97,53.93,523.92,54.89z"
              />
              <path
                d="M520.53,599.95c-0.01-1.01,1-1.97,2-1.91c0.95,0.06,1.74,0.91,1.75,1.89c0.01,1-0.75,1.83-1.73,1.9
	C521.52,601.89,520.54,600.98,520.53,599.95z"
              />
              <path
                d="M411.31,362.39c-0.99-0.02-1.78-0.81-1.81-1.82c-0.04-1.1,0.84-2,1.91-1.97c0.95,0.03,1.82,0.89,1.86,1.83
	C413.31,361.46,412.37,362.41,411.31,362.39z"
              />
              <path
                d="M388.44,555.75c-0.97-0.06-1.79-0.93-1.8-1.88c-0.01-1.02,1-1.97,2.02-1.91c1.06,0.06,1.87,1.05,1.75,2.13
	C390.29,555.1,389.44,555.82,388.44,555.75z"
              />
              <path
                d="M269.97,333.24c-0.05,1-0.85,1.78-1.84,1.78c-1.04,0.01-1.98-0.96-1.91-1.99c0.06-0.93,0.94-1.78,1.88-1.79
	C269.15,331.23,270.02,332.16,269.97,333.24z"
              />
              <path
                d="M480.51,533.2c-1,0.03-1.83-0.71-1.91-1.72c-0.09-1.07,0.76-2.05,1.81-2.07c0.94-0.02,1.86,0.8,1.96,1.73
	C482.47,532.17,481.57,533.17,480.51,533.2z"
              />
              <path
                d="M351.2,457.87c-1.05,0.04-2.01-0.89-1.98-1.93c0.02-0.94,0.89-1.81,1.83-1.86c1.07-0.05,1.96,0.84,1.94,1.94
	C352.97,457.04,352.2,457.84,351.2,457.87z"
              />
              <path
                d="M526.2,328.73c0.05,1.09-0.8,1.99-1.87,1.97c-0.95-0.02-1.81-0.85-1.86-1.78c-0.05-1,0.9-1.99,1.91-1.98
	C525.32,326.94,526.15,327.77,526.2,328.73z"
              />
              <path
                d="M303.39,507.41c-0.05,1.05-1.06,1.9-2.11,1.78c-1-0.11-1.7-0.94-1.65-1.96c0.04-1,0.85-1.79,1.83-1.8
	C302.5,505.42,303.44,506.39,303.39,507.41z"
              />
              <path
                d="M381.24,556.23c-0.97-0.06-1.79-0.93-1.8-1.88c-0.01-1.02,1-1.97,2.02-1.91c1.06,0.06,1.87,1.05,1.75,2.13
	C383.09,555.58,382.24,556.3,381.24,556.23z"
              />
              <path
                d="M317,498.66c0.01,1.01-0.73,1.84-1.72,1.92c-1.05,0.08-2.04-0.82-2.05-1.86c-0.02-1.02,0.97-1.98,2-1.93
	C316.19,496.83,316.98,497.67,317,498.66z"
              />
              <path
                d="M251.73,188.19c-1.05-0.05-1.94-1.06-1.83-2.07c0.1-0.92,1.05-1.74,1.96-1.71c1.05,0.04,1.89,1.02,1.79,2.08
	C253.56,187.49,252.71,188.24,251.73,188.19z"
              />
              <path
                d="M350.63,410.84c-1.05-0.02-1.96-1-1.88-2.02c0.08-0.93,0.99-1.76,1.92-1.76c1.05,0.01,1.91,0.96,1.84,2.03
	C352.43,410.1,351.62,410.86,350.63,410.84z"
              />
              <path
                d="M309.87,336.51c-0.01-1.02,0.99-1.97,2.01-1.92c0.97,0.06,1.75,0.9,1.76,1.89c0.01,1.02-0.74,1.84-1.73,1.9
	C310.86,338.47,309.88,337.56,309.87,336.51z"
              />
              <path
                d="M473.05,443.53c0.95,0.02,1.77,0.85,1.8,1.83c0.04,1.1-0.82,1.99-1.91,1.95c-0.96-0.03-1.81-0.87-1.85-1.81
	C471.07,444.48,472.04,443.5,473.05,443.53z"
              />
              <path
                d="M387.04,557.72c0,0.99-0.77,1.82-1.74,1.87c-1.03,0.06-2-0.86-2-1.89c0.01-1,1.02-1.95,2.02-1.88
	C386.26,555.88,387.05,556.75,387.04,557.72z"
              />
              <path
                d="M408.64,535.66c0.98-0.04,1.83,0.73,1.92,1.72c0.1,1.09-0.73,2.04-1.8,2.07c-0.96,0.03-1.86-0.76-1.97-1.71
	C406.69,536.73,407.6,535.7,408.64,535.66z"
              />
              <path
                d="M328.99,389.28c0,1-0.75,1.83-1.73,1.89c-1.04,0.07-2.02-0.85-2.02-1.88c0-1.01,1-1.97,2.01-1.91
	C328.2,387.45,328.98,388.3,328.99,389.28z"
              />
              <path
                d="M312.92,352.78c-0.97-0.04-1.75-0.85-1.77-1.84c-0.02-1.07,0.88-1.98,1.92-1.93c0.93,0.05,1.79,0.92,1.81,1.84
	C314.91,351.88,313.95,352.82,312.92,352.78z"
              />
              <path
                d="M426.4,471.42c-0.04,1-0.84,1.79-1.82,1.8c-1.04,0.01-1.98-0.94-1.93-1.97c0.05-0.93,0.93-1.79,1.86-1.81
	C425.56,469.42,426.44,470.34,426.4,471.42z"
              />
              <path
                d="M391.85,488.5c0.07,1.11-0.77,2.01-1.87,2c-0.96-0.01-1.84-0.82-1.9-1.77c-0.07-1.01,0.88-2.01,1.91-2.02
	C390.96,486.71,391.8,487.51,391.85,488.5z"
              />
              <path
                d="M516.58,52.93c-0.1-0.3-0.19-0.6-0.29-0.9c-0.33-0.98-0.05-1.87,0.89-2.15c0.55-0.17,1.34,0.17,1.91,0.48
	c0.72,0.39,0.91,1.15,0.51,1.86c-0.46,0.84-1.19,1.27-2.2,0.9c-0.3-0.11-0.6-0.19-0.91-0.28C516.5,52.85,516.58,52.93,516.58,52.93z
	"
              />
              <path
                d="M265.04,335.09c1.02,0.02,1.96,1.04,1.87,2.04c-0.08,0.94-0.98,1.75-1.93,1.74c-1.07-0.01-1.91-0.94-1.82-2.03
	C263.23,335.86,264.09,335.07,265.04,335.09z"
              />
              <path
                d="M260.18,384.5c-0.01,1.03-0.99,1.94-2.02,1.87c-0.97-0.07-1.73-0.9-1.72-1.89c0.01-0.97,0.8-1.82,1.74-1.88
	C259.18,382.53,260.19,383.49,260.18,384.5z"
              />
              <path
                d="M286.75,194.51c-0.02,0.99-0.8,1.8-1.77,1.84c-1.03,0.04-1.99-0.9-1.97-1.92c0.03-1.01,1.05-1.94,2.05-1.85
	C286.01,192.67,286.78,193.54,286.75,194.51z"
              />
              <path
                d="M307.52,450.73c1.04,0.03,1.88,1.01,1.79,2.07c-0.09,0.99-0.93,1.73-1.91,1.7c-0.95-0.04-1.8-0.88-1.83-1.82
	C305.54,451.68,306.52,450.7,307.52,450.73z"
              />
              <path
                d="M343.95,438.24c-0.01-1.01,1-1.97,2-1.91c0.95,0.06,1.74,0.91,1.75,1.89c0.01,1-0.76,1.83-1.73,1.9
	C344.94,440.19,343.96,439.27,343.95,438.24z"
              />
              <path
                d="M471.51,489.18c-0.04,1.01-0.84,1.79-1.83,1.81c-1.06,0.01-1.99-0.94-1.94-1.97c0.05-0.94,0.93-1.79,1.87-1.81
	C470.68,487.17,471.55,488.08,471.51,489.18z"
              />
              <path
                d="M552.47,610.04c1.02,0.02,1.95,1.04,1.86,2.03c-0.08,0.94-0.97,1.74-1.92,1.73c-1.06-0.01-1.9-0.95-1.82-2.02
	C550.66,610.82,551.52,610.02,552.47,610.04z"
              />
              <path
                d="M411.33,544.67c0.05,1.02-0.89,1.98-1.92,1.96c-0.97-0.01-1.77-0.81-1.81-1.8c-0.04-0.98,0.69-1.85,1.65-1.96
	C410.24,542.76,411.28,543.65,411.33,544.67z"
              />
              <path
                d="M265.64,116.68c0.03,1-0.7,1.84-1.68,1.93c-1.03,0.09-2.03-0.79-2.06-1.82c-0.03-1,0.95-1.98,1.95-1.95
	C264.81,114.88,265.61,115.71,265.64,116.68z"
              />
              <path
                d="M417.1,529.92c-0.02,1.04-1.02,1.93-2.05,1.84c-0.98-0.08-1.72-0.92-1.7-1.92c0.02-0.98,0.82-1.82,1.78-1.86
	C416.14,527.94,417.12,528.9,417.1,529.92z"
              />
              <path
                d="M471.5,449.3c-0.02,1-0.8,1.81-1.78,1.85c-1.04,0.04-2-0.9-1.98-1.93c0.02-1.02,1.04-1.94,2.05-1.85
	C470.76,447.45,471.52,448.32,471.5,449.3z"
              />
              <path
                d="M322.39,496.32c1.04,0.03,1.89,1,1.8,2.06c-0.08,0.99-0.92,1.74-1.9,1.71c-0.95-0.03-1.8-0.87-1.84-1.81
	C320.41,497.27,321.38,496.29,322.39,496.32z"
              />
              <path
                d="M315.09,330.78c0.94-0.03,1.81,0.76,1.89,1.72c0.09,1.07-0.74,2.02-1.8,2.04c-0.94,0.02-1.84-0.78-1.94-1.71
	C313.15,331.84,314.07,330.81,315.09,330.78z"
              />
              <path
                d="M371.03,497.69c0.03,1.03-0.93,1.97-1.97,1.93c-0.98-0.04-1.76-0.85-1.78-1.85c-0.02-0.98,0.74-1.85,1.7-1.94
	C369.99,495.75,371.01,496.66,371.03,497.69z"
              />
              <path
                d="M242.73,373.38c-0.95,0.02-1.85-0.85-1.88-1.81c-0.02-0.94,0.85-1.87,1.8-1.89c0.96-0.03,1.83,0.82,1.85,1.81
	C244.52,372.48,243.7,373.35,242.73,373.38z"
              />
              <path
                d="M522.62,372.53c-0.03,0.95-0.9,1.8-1.84,1.81c-1.05,0.01-1.93-0.96-1.84-2.02c0.08-0.97,0.99-1.76,1.93-1.7
	C521.79,370.69,522.65,371.62,522.62,372.53z"
              />
              <path
                d="M298.65,223.8c0.92,0.04,1.79,0.94,1.79,1.86c0,1.04-1.01,1.95-2.03,1.84c-0.97-0.1-1.7-0.98-1.64-1.97
	C296.84,224.58,297.71,223.77,298.65,223.8z"
              />
              <path
                d="M354.4,224.72c-0.01,0.99-0.82,1.83-1.79,1.85c-0.95,0.02-1.85-0.82-1.91-1.77c-0.06-1.01,0.94-2.02,1.95-1.98
	C353.6,222.86,354.41,223.74,354.4,224.72z"
              />
              <path
                d="M426.87,518.23c-0.05,1.03-0.85,1.76-1.88,1.73c-0.98-0.03-1.84-0.87-1.85-1.83c-0.01-1.05,0.97-1.93,2.06-1.85
	C426.22,516.38,426.92,517.19,426.87,518.23z"
              />
              <path
                d="M312.99,36.85c0,0.94-0.84,1.8-1.8,1.83c-1.09,0.04-1.94-0.83-1.88-1.94c0.06-1.03,0.84-1.73,1.87-1.7
	C312.16,35.08,312.99,35.9,312.99,36.85z"
              />
              <path
                d="M684.04,627.35c0.94-0.06,1.84,0.73,1.92,1.69c0.08,0.96-0.65,1.88-1.6,2c-1.02,0.13-2.04-0.75-2.07-1.79
	C682.27,628.33,683.12,627.41,684.04,627.35z"
              />
              <path
                d="M506.26,581.64c-0.95-0.06-1.74-0.94-1.71-1.91c0.03-0.97,0.87-1.81,1.81-1.81c1,0,1.95,1.04,1.85,2.03
	C508.12,580.89,507.17,581.7,506.26,581.64z"
              />
              <path
                d="M416.1,475.73c-0.04,0.95-0.92,1.79-1.87,1.79c-1.05,0-1.92-0.99-1.81-2.05c0.1-0.97,1.01-1.75,1.95-1.67
	C415.29,473.87,416.14,474.82,416.1,475.73z"
              />
              <path
                d="M258.7,370.15c-0.03,0.94-0.91,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.82-2.02c0.09-0.96,0.99-1.75,1.93-1.68
	C257.88,368.3,258.73,369.23,258.7,370.15z"
              />
              <path
                d="M396.15,558.53c1.05-0.05,1.85,0.65,1.93,1.68c0.07,1.03-0.63,1.88-1.64,1.98c-1.07,0.11-2.08-0.76-2.1-1.81
	C394.32,559.42,395.15,558.57,396.15,558.53z"
              />
              <path
                d="M448,137.02c0.06,1.11-0.77,2-1.86,2c-0.96,0-1.84-0.81-1.91-1.76c-0.08-1.04,0.82-1.97,1.91-1.98
	C447.18,135.27,447.94,135.99,448,137.02z"
              />
              <path
                d="M390.87,561.03c0.02,0.99-0.77,1.85-1.74,1.9c-1.04,0.05-2.02-0.92-1.96-1.95c0.05-0.93,0.97-1.8,1.9-1.79
	C390.02,559.2,390.85,560.05,390.87,561.03z"
              />
              <path
                d="M432.12,369.63c-0.01,0.99-0.86,1.84-1.83,1.83c-0.95-0.01-1.83-0.91-1.82-1.86c0.01-0.94,0.92-1.84,1.86-1.83
	C431.29,367.77,432.13,368.64,432.12,369.63z"
              />
              <path
                d="M385.59,583.02c0.06,1.02-0.67,1.89-1.66,1.98c-1.05,0.1-2.06-0.81-2.06-1.87c0-0.93,0.88-1.84,1.82-1.88
	C384.66,581.22,385.53,582.04,385.59,583.02z"
              />
              <path
                d="M381.85,559.68c0.95,0,1.79,0.85,1.82,1.82c0.03,0.98-0.76,1.85-1.72,1.91c-1.02,0.06-2.01-0.9-1.97-1.93
	C380.02,560.55,380.93,559.68,381.85,559.68z"
              />
              <path
                d="M529.88,245.49c0.05,1-0.91,1.98-1.95,1.97c-1.01-0.01-1.78-0.77-1.83-1.79c-0.05-1.06,0.62-1.84,1.65-1.95
	C528.82,243.62,529.83,244.45,529.88,245.49z"
              />
              <path
                d="M409.09,563.06c-0.04,0.98-0.87,1.79-1.83,1.78c-0.94-0.01-1.81-0.87-1.83-1.81c-0.03-1.01,0.99-1.99,1.98-1.91
	C408.35,561.2,409.13,562.1,409.09,563.06z"
              />
              <path
                d="M455.49,222.92c0.06,0.93-0.78,1.87-1.74,1.96c-1.03,0.09-1.89-0.6-2.01-1.62c-0.13-1.15,0.65-2.05,1.79-2.07
	C454.53,221.17,455.43,221.97,455.49,222.92z"
              />
              <path
                d="M552.09,377.2c0.04,0.98-0.72,1.86-1.68,1.94c-1.03,0.08-2.02-0.85-2-1.89c0.02-0.92,0.91-1.82,1.83-1.83
	C551.19,375.41,552.04,376.23,552.09,377.2z"
              />
              <path
                d="M239.76,254.28c1.05-0.03,1.82,0.66,1.9,1.7c0.09,1.12-0.72,2.02-1.81,2.04c-1.03,0.02-2.01-0.96-1.97-1.96
	C237.92,255.11,238.77,254.31,239.76,254.28z"
              />
              <path
                d="M417.5,469.96c0.92-0.05,1.88,0.83,1.93,1.77c0.05,0.94-0.79,1.88-1.75,1.93c-0.97,0.05-1.85-0.76-1.9-1.75
	C415.73,470.93,416.54,470.01,417.5,469.96z"
              />
              <path
                d="M296.65,222.58c-0.02,0.96-0.88,1.79-1.87,1.8c-1.12,0.02-1.95-0.87-1.86-2c0.08-1.03,0.89-1.72,1.94-1.67
	C295.85,220.77,296.67,221.62,296.65,222.58z"
              />
              <path
                d="M403.7,478.39c1,0.01,1.86,0.83,1.88,1.79c0.02,1.02-0.99,1.96-2.04,1.88c-1.01-0.07-1.75-0.9-1.71-1.93
	C401.88,479.1,402.65,478.38,403.7,478.39z"
              />
              <path
                d="M401.91,563.82c-0.05,1.03-0.85,1.76-1.88,1.73c-0.98-0.03-1.84-0.87-1.85-1.83c-0.01-1.05,0.97-1.93,2.06-1.85
	C401.27,561.96,401.97,562.77,401.91,563.82z"
              />
              <path
                d="M385.93,563.05c0.91,0,1.82,0.88,1.85,1.81c0.03,1.02-0.96,1.98-1.97,1.91c-0.95-0.07-1.73-0.95-1.7-1.92
	C384.14,563.88,384.98,563.04,385.93,563.05z"
              />
              <path
                d="M428.28,366.21c0.02,0.97-0.76,1.84-1.72,1.9c-1.01,0.06-2-0.91-1.95-1.93c0.04-0.92,0.95-1.79,1.86-1.79
	C427.43,364.4,428.26,365.24,428.28,366.21z"
              />
              <path
                d="M381.75,579.86c-0.04,1-0.87,1.8-1.85,1.79c-0.96-0.01-1.83-0.87-1.85-1.82c-0.03-1.01,1.01-2,2.01-1.92
	C381.01,577.98,381.79,578.88,381.75,579.86z"
              />
              <path
                d="M530.99,260.12c-0.07,1.03-0.88,1.74-1.92,1.7c-0.98-0.04-1.83-0.91-1.82-1.86c0.01-1.05,1.01-1.91,2.1-1.8
	C530.38,258.25,531.06,259.06,530.99,260.12z"
              />
              <path
                d="M387.16,581.17c-0.96,0.02-1.87-0.81-1.93-1.76c-0.07-1.01,0.93-2.03,1.95-1.99c0.97,0.04,1.77,0.9,1.77,1.9
	C388.95,580.31,388.14,581.15,387.16,581.17z"
              />
              <path
                d="M387.71,588.36c-0.93,0.06-1.87-0.75-1.97-1.69c-0.1-1,0.84-2.04,1.86-2.04c0.95,0,1.79,0.83,1.82,1.81
	C389.45,587.42,388.67,588.3,387.71,588.36z"
              />
              <path
                d="M415.62,96.51c0.04,0.93-0.78,1.85-1.73,1.93c-0.96,0.08-1.86-0.69-1.95-1.66c-0.11-1.06,0.76-2.05,1.81-2.06
	C414.67,94.71,415.57,95.58,415.62,96.51z"
              />
              <path
                d="M366.19,68.32c-0.03,0.95-0.9,1.8-1.85,1.81c-1.05,0.01-1.93-0.96-1.83-2.03c0.09-0.97,0.99-1.76,1.93-1.7
	C365.37,66.47,366.22,67.41,366.19,68.32z"
              />
              <path
                d="M392.46,555.15c1.05,0.03,1.79,0.79,1.78,1.85c0,1.04-0.77,1.83-1.79,1.85c-1.05,0.03-2.03-0.96-1.96-1.98
	C390.56,555.92,391.47,555.13,392.46,555.15z"
              />
              <path
                d="M399.5,554.68c1.06-0.05,1.86,0.65,1.94,1.69c0.08,1.03-0.63,1.89-1.64,2c-1.07,0.11-2.09-0.77-2.11-1.81
	C397.67,555.58,398.5,554.73,399.5,554.68z"
              />
              <path
                d="M491.44,219.84c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.68c-0.1-1.07,0.77-2.06,1.82-2.06
	C490.5,218.03,491.41,218.9,491.44,219.84z"
              />
              <path
                d="M406.73,557.66c-0.97-0.03-1.82-0.91-1.82-1.86c0-1.03,1.04-1.98,2.05-1.88c0.97,0.1,1.72,1.01,1.66,1.99
	C408.57,556.91,407.73,557.69,406.73,557.66z"
              />
              <path
                d="M384.39,592.21c-0.97-0.05-1.76-0.92-1.73-1.91c0.02-0.98,0.86-1.83,1.81-1.84c1.02-0.01,1.98,1.03,1.89,2.04
	C386.27,591.44,385.33,592.26,384.39,592.21z"
              />
              <path
                d="M436.97,466.73c0.03,1.02-0.69,1.85-1.69,1.94c-1.03,0.09-2.05-0.8-2.09-1.82c-0.04-1.04,0.9-1.95,1.97-1.92
	C436.2,464.95,436.94,465.69,436.97,466.73z"
              />
              <path
                d="M530.32,292.29c0.04,0.95-0.78,1.86-1.75,1.93c-0.98,0.08-1.87-0.68-1.96-1.68c-0.1-1.08,0.77-2.07,1.83-2.07
	C529.37,290.48,530.28,291.35,530.32,292.29z"
              />
              <path
                d="M243.82,221.53c-1.03-0.04-1.78-0.84-1.77-1.88c0.01-1.06,0.75-1.81,1.81-1.83c1.1-0.02,2.02,0.93,1.94,1.98
	C245.71,220.76,244.79,221.57,243.82,221.53z"
              />
              <path
                d="M376.01,555.12c-0.02,1.03-0.8,1.79-1.82,1.8c-0.99,0.01-1.86-0.8-1.91-1.77c-0.05-1.05,0.9-1.96,1.99-1.91
	C375.31,553.29,376.03,554.07,376.01,555.12z"
              />
              <path
                d="M293.48,186.46c0.05,1.03-0.68,1.86-1.69,1.94c-1.06,0.08-2.07-0.83-2.06-1.86c0.01-0.96,0.86-1.79,1.86-1.81
	C292.64,184.71,293.43,185.43,293.48,186.46z"
              />
              <path
                d="M436.72,506.29c0.04,0.92-0.79,1.85-1.72,1.92c-0.95,0.07-1.85-0.69-1.95-1.66c-0.11-1.05,0.76-2.04,1.8-2.05
	C435.77,504.5,436.68,505.37,436.72,506.29z"
              />
              <path
                d="M377.22,588.93c0.93-0.04,1.88,0.82,1.95,1.75c0.08,1.03-0.87,2.03-1.93,2c-0.98-0.02-1.78-0.86-1.79-1.86
	C375.45,589.84,376.26,588.97,377.22,588.93z"
              />
              <path
                d="M397.61,553.25c-0.05,1.02-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C396.99,551.42,397.65,552.2,397.61,553.25z"
              />
              <path
                d="M402.99,550.84c1.06,0.02,1.8,0.77,1.81,1.83c0.01,1.04-0.75,1.84-1.77,1.88c-1.05,0.04-2.05-0.95-1.99-1.96
	C401.1,551.62,402,550.82,402.99,550.84z"
              />
              <path
                d="M438.55,128.58c1.04,0.04,1.77,0.82,1.76,1.88c-0.01,1.03-0.79,1.81-1.82,1.82c-1.06,0.01-2.02-0.99-1.93-2.01
	C436.65,129.32,437.57,128.54,438.55,128.58z"
              />
              <path
                d="M383,594.14c0.02,0.95-0.82,1.84-1.78,1.89c-0.98,0.06-1.84-0.71-1.92-1.71c-0.09-1.08,0.8-2.05,1.86-2.03
	C382.09,592.32,382.98,593.21,383,594.14z"
              />
              <path
                d="M409.93,550.07c0.94,0.01,1.84,0.89,1.87,1.83c0.03,1.04-0.96,2-2,1.93c-0.98-0.07-1.75-0.94-1.71-1.94
	C408.12,550.9,408.96,550.06,409.93,550.07z"
              />
              <path
                d="M404.16,568.68c-0.96,0-1.79-0.82-1.82-1.79c-0.03-0.96,0.76-1.86,1.7-1.93c0.99-0.07,2,0.92,1.97,1.92
	C405.97,567.83,405.1,568.68,404.16,568.68z"
              />
              <path
                d="M306.43,503.15c0.05,1.03-0.67,1.86-1.68,1.95c-1.06,0.08-2.07-0.83-2.06-1.86c0.01-0.96,0.85-1.79,1.85-1.81
	C305.59,501.4,306.38,502.12,306.43,503.15z"
              />
              <path
                d="M370.37,549.88c1.06,0.02,1.8,0.77,1.81,1.83c0.01,1.04-0.75,1.84-1.77,1.88c-1.05,0.04-2.05-0.95-1.99-1.96
	C368.47,550.66,369.37,549.86,370.37,549.88z"
              />
              <path
                d="M391.64,568.29c-0.01,0.95-0.88,1.82-1.84,1.84c-1.07,0.02-1.95-0.93-1.87-2.01c0.08-0.98,0.96-1.78,1.92-1.73
	C390.78,566.44,391.65,567.37,391.64,568.29z"
              />
              <path
                d="M379.38,551.17c0.03,1.02-0.68,1.85-1.68,1.95c-1.03,0.1-2.06-0.79-2.1-1.81c-0.05-1.04,0.88-1.95,1.96-1.93
	C378.61,549.4,379.35,550.13,379.38,551.17z"
              />
              <path
                d="M382.58,570.6c-0.96,0.01-1.8-0.81-1.84-1.79c-0.04-0.97,0.75-1.87,1.69-1.94c0.99-0.08,2.02,0.91,1.99,1.92
	C384.4,569.74,383.53,570.59,382.58,570.6z"
              />
              <path
                d="M239.23,187.74c0.01,0.98-0.79,1.83-1.75,1.87c-1.03,0.04-1.99-0.94-1.93-1.97c0.06-0.92,0.99-1.79,1.91-1.76
	C238.41,185.91,239.22,186.76,239.23,187.74z"
              />
              <path
                d="M369.76,317.33c-0.01,1-0.82,1.83-1.8,1.85c-0.96,0.02-1.86-0.82-1.92-1.77c-0.06-1.02,0.94-2.03,1.96-1.99
	C368.96,315.47,369.77,316.34,369.76,317.33z"
              />
              <path
                d="M527.02,319.66c-0.99,0.01-1.82-0.78-1.86-1.79c-0.04-0.99,0.74-1.89,1.7-1.96c1.01-0.08,2.04,0.91,2.02,1.93
	C528.86,318.79,527.99,319.65,527.02,319.66z"
              />
              <path
                d="M440.08,502.58c-0.03,0.94-0.91,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.82-2.02c0.09-0.96,0.99-1.75,1.93-1.68
	C439.26,500.74,440.11,501.66,440.08,502.58z"
              />
              <path
                d="M400.79,549.38c-0.04,1.02-1.05,1.9-2.09,1.81c-0.99-0.09-1.71-0.91-1.68-1.92c0.03-1.13,0.9-1.89,2.04-1.78
	C400.03,547.57,400.83,548.44,400.79,549.38z"
              />
              <path
                d="M366.88,391.4c-1.06-0.05-1.94-1.05-1.82-2.06c0.11-0.94,1.01-1.69,1.98-1.65c1.12,0.04,1.88,0.92,1.76,2.05
	C368.71,390.74,367.9,391.45,366.88,391.4z"
              />
              <path
                d="M487.6,549c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.67c-0.1-1.07,0.77-2.06,1.82-2.06
	C486.66,547.2,487.57,548.07,487.6,549z"
              />
              <path
                d="M409.4,357.24c-0.06,0.96-0.96,1.79-1.92,1.77c-1.07-0.02-1.91-1.01-1.79-2.09c0.11-0.98,1.04-1.75,1.99-1.66
	C408.61,355.35,409.46,356.33,409.4,357.24z"
              />
              <path
                d="M402.41,484.04c0.01,1.02-0.72,1.83-1.71,1.9c-1.03,0.07-2.04-0.83-2.05-1.85c-0.02-1.03,0.94-1.92,2.01-1.88
	C401.67,482.25,402.4,483,402.41,484.04z"
              />
              <path
                d="M328.27,461.79c-0.03,0.94-0.9,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.83-2.02c0.09-0.96,0.99-1.75,1.93-1.69
	C327.46,459.95,328.3,460.88,328.27,461.79z"
              />
              <path
                d="M395.18,484.85c-0.04,0.98-0.88,1.79-1.84,1.79c-0.95-0.01-1.82-0.86-1.84-1.81c-0.03-1.01,1-2,1.99-1.92
	C394.43,482.98,395.22,483.88,395.18,484.85z"
              />
              <path
                d="M368.6,322.41c1.08-0.02,2.01,0.89,1.97,1.93c-0.04,0.94-0.91,1.78-1.86,1.81c-1.1,0.04-1.95-0.83-1.91-1.94
	C366.82,323.17,367.56,322.43,368.6,322.41z"
              />
              <path
                d="M347.07,324.36c1,0.01,1.86,0.82,1.89,1.78c0.03,1.02-0.98,1.96-2.03,1.9c-1.02-0.06-1.76-0.88-1.72-1.91
	C345.25,325.09,346.02,324.36,347.07,324.36z"
              />
              <path
                d="M508.26,231.11c-1.03-0.01-1.8-0.78-1.82-1.81c-0.02-1.05,0.71-1.82,1.75-1.87c1.09-0.05,2.04,0.87,1.98,1.92
	C510.12,230.31,509.24,231.12,508.26,231.11z"
              />
              <path
                d="M385.12,575.89c0.03,1-0.75,1.87-1.73,1.92c-1.04,0.06-2.03-0.9-1.99-1.94c0.04-0.94,0.95-1.81,1.89-1.81
	C384.25,574.06,385.09,574.9,385.12,575.89z"
              />
              <path
                d="M317,459.08c0.01,1.02-0.72,1.84-1.72,1.91c-1.03,0.07-2.04-0.84-2.06-1.86c-0.02-1.04,0.93-1.93,2.01-1.89
	C316.26,457.29,316.99,458.04,317,459.08z"
              />
              <path
                d="M392.31,575.41c0.03,1-0.75,1.87-1.73,1.92c-1.04,0.06-2.03-0.9-1.99-1.94c0.04-0.94,0.95-1.81,1.89-1.81
	C391.45,573.58,392.29,574.42,392.31,575.41z"
              />
              <path
                d="M443.46,458.78c0.05,0.94-0.77,1.86-1.73,1.94c-0.97,0.08-1.87-0.67-1.97-1.66c-0.11-1.07,0.76-2.07,1.8-2.08
	C442.5,456.98,443.41,457.85,443.46,458.78z"
              />
              <path
                d="M448.7,144.05c0.02,0.95-0.85,1.86-1.8,1.88c-0.97,0.02-1.83-0.82-1.85-1.81c-0.02-0.99,0.81-1.87,1.77-1.89
	C447.76,142.21,448.68,143.11,448.7,144.05z"
              />
              <path
                d="M460.3,486.43c0.01,1-0.98,1.95-2.01,1.9c-1-0.04-1.75-0.82-1.76-1.84c-0.01-1.04,0.69-1.82,1.71-1.88
	C459.3,484.53,460.28,485.4,460.3,486.43z"
              />
              <path
                d="M413.31,546.24c0.93,0.02,1.82,0.92,1.84,1.84c0.02,1.04-0.98,1.98-2.02,1.89c-0.97-0.08-1.73-0.96-1.68-1.95
	C411.49,547.04,412.35,546.22,413.31,546.24z"
              />
              <path
                d="M318.26,330.67c-0.98,0-1.81-0.86-1.81-1.85c0-0.99,0.85-1.85,1.82-1.85c0.94,0,1.84,0.92,1.83,1.86
	C320.1,329.79,319.22,330.67,318.26,330.67z"
              />
              <path
                d="M446.78,455.09c-0.04,0.95-0.97,1.82-1.91,1.78c-0.97-0.04-1.78-0.93-1.74-1.92c0.04-0.98,0.92-1.82,1.88-1.78
	C445.96,453.21,446.82,454.15,446.78,455.09z"
              />
              <path
                d="M452.07,456.17c-1.02-0.04-1.78-0.84-1.77-1.88c0.01-1.06,0.75-1.81,1.81-1.83c1.1-0.02,2.03,0.93,1.94,1.98
	C453.96,455.4,453.03,456.21,452.07,456.17z"
              />
              <path
                d="M443.94,213.25c-0.04,0.95-0.93,1.8-1.88,1.8c-1.06,0-1.93-0.98-1.82-2.05c0.1-0.97,1.01-1.76,1.96-1.68
	C443.12,211.39,443.98,212.34,443.94,213.25z"
              />
              <path
                d="M273.31,329.29c0.01,1-0.79,1.85-1.77,1.88c-1.05,0.04-2.02-0.94-1.95-1.98c0.06-0.94,1-1.8,1.93-1.77
	C272.49,327.45,273.31,328.3,273.31,329.29z"
              />
              <path
                d="M262.7,396.89c-0.97,0.06-1.86-0.75-1.91-1.74c-0.05-0.98,0.75-1.9,1.71-1.96c0.91-0.06,1.88,0.82,1.94,1.76
	C264.5,395.89,263.66,396.83,262.7,396.89z"
              />
              <path
                d="M295.49,191.79c-1.03,0.01-2-0.96-1.94-1.96c0.05-0.94,0.91-1.74,1.89-1.76c1.13-0.02,1.93,0.8,1.88,1.94
	C297.27,191.03,296.5,191.78,295.49,191.79z"
              />
              <path
                d="M313.12,329.33c-0.02,0.99-0.87,1.83-1.84,1.82c-0.95-0.01-1.82-0.91-1.81-1.87c0.01-0.94,0.93-1.84,1.86-1.83
	C312.3,327.47,313.13,328.34,313.12,329.33z"
              />
              <path
                d="M423.49,395.57c-0.03,0.98-0.85,1.81-1.81,1.81c-0.95,0-1.83-0.84-1.87-1.79c-0.04-1,0.97-2.01,1.97-1.94
	C422.72,393.71,423.52,394.6,423.49,395.57z"
              />
              <path
                d="M306.04,499.37c0.02-0.96,0.89-1.77,1.88-1.78c1.14-0.01,1.95,0.85,1.85,1.99c-0.08,1.03-0.9,1.73-1.94,1.67
	C306.87,501.2,306.02,500.31,306.04,499.37z"
              />
              <path
                d="M461.23,453.91c-0.05,0.96-0.92,1.77-1.91,1.77c-1.12-0.01-1.93-0.92-1.82-2.04c0.1-1.03,0.92-1.7,1.97-1.63
	C460.47,452.08,461.28,452.95,461.23,453.91z"
              />
              <path
                d="M251.87,350.73c1.03,0.03,1.76,0.77,1.79,1.81c0.03,1.11-0.83,1.96-1.93,1.91c-0.96-0.04-1.81-0.88-1.84-1.82
	C249.86,351.6,250.8,350.7,251.87,350.73z"
              />
              <path
                d="M356.14,452.19c-0.02,0.96-0.9,1.82-1.86,1.83c-1.08,0.01-1.95-0.94-1.86-2.03c0.08-0.98,0.98-1.78,1.94-1.72
	C355.3,450.33,356.16,451.26,356.14,452.19z"
              />
              <path
                d="M356.63,498.95c-0.01,0.96-0.87,1.8-1.85,1.83c-1.12,0.03-1.96-0.85-1.89-1.97c0.07-1.03,0.87-1.74,1.92-1.69
	C355.81,497.15,356.64,497.99,356.63,498.95z"
              />
              <path
                d="M377.9,596.14c0.95-0.06,1.85,0.74,1.93,1.71c0.08,0.98-0.65,1.89-1.62,2.01c-1.04,0.13-2.06-0.76-2.09-1.81
	C376.11,597.14,376.98,596.2,377.9,596.14z"
              />
              <path
                d="M392.53,349.31c0.99-0.05,1.9,0.7,2,1.66c0.11,1.04-0.8,2.01-1.89,2.02c-1.03,0-1.81-0.76-1.84-1.78
	C390.77,350.16,391.49,349.37,392.53,349.31z"
              />
              <path
                d="M481.73,543.86c0.96,0.05,1.77,0.96,1.73,1.94c-0.05,0.99-0.93,1.81-1.9,1.76c-0.95-0.05-1.8-0.98-1.75-1.93
	C479.86,544.69,480.82,543.81,481.73,543.86z"
              />
              <path
                d="M443.44,498.58c0.06,0.91-0.76,1.86-1.68,1.95c-0.95,0.09-1.86-0.66-1.97-1.62c-0.13-1.05,0.72-2.06,1.76-2.08
	C442.45,496.8,443.38,497.66,443.44,498.58z"
              />
              <path
                d="M404.17,545.4c0.05,1-0.93,1.98-1.96,1.97c-1.01-0.01-1.78-0.78-1.82-1.8c-0.04-1.05,0.63-1.84,1.66-1.94
	C403.11,543.53,404.12,544.36,404.17,545.4z"
              />
              <path
                d="M407.41,189.04c0.98-0.05,1.9,0.71,2,1.66c0.11,1.04-0.81,2.01-1.89,2.01c-1.03,0-1.81-0.76-1.83-1.79
	C405.65,189.88,406.37,189.1,407.41,189.04z"
              />
              <path
                d="M416.63,542.41c0.91,0,1.82,0.88,1.85,1.8c0.04,1.02-0.95,1.99-1.97,1.92c-0.95-0.07-1.73-0.94-1.7-1.91
	C414.85,543.25,415.69,542.41,416.63,542.41z"
              />
              <path
                d="M449.92,490.79c-0.01,1.02-0.75,1.82-1.75,1.87c-1.03,0.05-2.03-0.88-2.03-1.9c0.01-1.04,0.98-1.91,2.05-1.85
	C449.21,488.97,449.92,489.75,449.92,490.79z"
              />
              <path
                d="M400.86,402.77c0.92,0.04,1.8,0.95,1.8,1.87c0,1.04-1.01,1.96-2.04,1.85c-0.98-0.1-1.71-0.98-1.65-1.97
	C399.04,403.55,399.92,402.74,400.86,402.77z"
              />
              <path
                d="M274.43,403.04c1.03,0.04,1.76,0.78,1.78,1.81c0.02,1.12-0.83,1.95-1.94,1.9c-0.96-0.04-1.81-0.89-1.83-1.83
	C272.42,403.9,273.36,403,274.43,403.04z"
              />
              <path
                d="M547.08,612.46c-0.03,0.94-0.9,1.8-1.84,1.8c-1.05,0.01-1.92-0.96-1.83-2.02c0.09-0.96,0.99-1.75,1.92-1.69
	C546.26,610.62,547.11,611.54,547.08,612.46z"
              />
              <path
                d="M331.39,497.59c-0.05,1.02-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C330.77,495.75,331.44,496.54,331.39,497.59z"
              />
              <path
                d="M336.47,498.9c-1.01-0.01-1.78-0.78-1.82-1.8c-0.05-1.14,0.76-1.97,1.89-1.94c0.99,0.02,1.84,0.82,1.89,1.77
	C338.47,497.94,337.5,498.92,336.47,498.9z"
              />
              <path
                d="M309.78,333.21c-0.04,1.01-0.86,1.81-1.85,1.8c-0.96-0.01-1.84-0.86-1.87-1.82c-0.03-1.02,1-2.01,2.01-1.94
	C309.03,331.33,309.82,332.23,309.78,333.21z"
              />
              <path
                d="M467.63,445.86c0.02,0.97-0.76,1.84-1.72,1.9c-1.01,0.06-2-0.91-1.95-1.93c0.04-0.92,0.95-1.79,1.86-1.79
	C466.78,444.05,467.61,444.89,467.63,445.86z"
              />
              <path
                d="M411.86,405.43c1.09,0.02,1.97,0.96,1.88,2c-0.08,0.94-0.98,1.75-1.93,1.74c-1.1-0.01-1.91-0.91-1.84-2.02
	C410.05,406.11,410.82,405.41,411.86,405.43z"
              />
              <path
                d="M351.3,544.45c-1.03-0.04-1.78-0.83-1.77-1.86c0.01-1.05,0.75-1.8,1.8-1.82c1.09-0.02,2.01,0.92,1.93,1.97
	C353.18,543.68,352.27,544.48,351.3,544.45z"
              />
              <path
                d="M381.07,572.51c0.04,0.93-0.78,1.85-1.72,1.93c-0.96,0.08-1.86-0.69-1.96-1.66c-0.11-1.07,0.76-2.05,1.81-2.06
	C380.12,570.71,381.03,571.58,381.07,572.51z"
              />
              <path
                d="M409.63,170.8c1.04-0.02,1.81,0.67,1.89,1.7c0.08,1.11-0.72,2.01-1.82,2.02c-1.03,0.02-2-0.96-1.96-1.95
	C407.8,171.63,408.66,170.82,409.63,170.8z"
              />
              <path
                d="M374.87,334.82c0.01,0.95-0.85,1.81-1.83,1.85c-1.12,0.05-1.98-0.82-1.92-1.94c0.06-1.03,0.84-1.75,1.89-1.72
	C374.01,333.03,374.86,333.86,374.87,334.82z"
              />
              <path
                d="M299.22,194.87c-0.95-0.05-1.8-0.99-1.75-1.93c0.05-0.94,1.01-1.82,1.93-1.77c0.96,0.05,1.77,0.97,1.72,1.95
	C301.07,194.11,300.18,194.93,299.22,194.87z"
              />
              <path
                d="M309.08,345.66c0.93-0.04,1.88,0.82,1.95,1.75c0.08,1.03-0.87,2.03-1.93,2c-0.98-0.02-1.78-0.86-1.79-1.86
	C307.31,346.56,308.12,345.69,309.08,345.66z"
              />
              <path
                d="M524.08,346.99c0.04,0.95-0.79,1.86-1.75,1.93c-0.98,0.07-1.87-0.68-1.96-1.68c-0.1-1.08,0.77-2.07,1.83-2.07
	C523.14,345.18,524.04,346.05,524.08,346.99z"
              />
              <path
                d="M387.1,82.61c-0.96-0.01-1.83-0.87-1.85-1.82c-0.02-1.01,1.01-2,2.01-1.92c0.95,0.08,1.73,0.98,1.69,1.96
	C388.91,81.82,388.07,82.63,387.1,82.61z"
              />
              <path
                d="M405.68,539.79c0.99,0.04,1.82,0.87,1.84,1.82c0.02,1.01-0.97,1.96-2,1.92c-1.09-0.04-1.88-0.97-1.77-2.08
	C403.84,540.42,404.64,539.74,405.68,539.79z"
              />
              <path
                d="M353.68,446.81c-0.96-0.05-1.75-0.92-1.73-1.9c0.02-0.97,0.86-1.82,1.81-1.83c1.01-0.01,1.97,1.03,1.88,2.03
	C355.55,446.04,354.61,446.86,353.68,446.81z"
              />
              <path
                d="M423.99,110.35c-0.04,1.03-0.83,1.77-1.86,1.76c-0.99-0.02-1.85-0.85-1.87-1.81c-0.02-1.05,0.95-1.94,2.04-1.87
	C423.32,108.5,424.03,109.31,423.99,110.35z"
              />
              <path
                d="M475.34,492.31c-0.05,1.01-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C474.72,490.48,475.39,491.26,475.34,492.31z"
              />
              <path
                d="M302.94,440.2c0.92,0.02,1.81,0.92,1.83,1.84c0.02,1.04-0.98,1.97-2.01,1.89c-0.96-0.08-1.72-0.97-1.68-1.95
	C301.13,441.01,301.99,440.18,302.94,440.2z"
              />
              <path
                d="M478.22,441.44c-0.04,1.02-0.81,1.77-1.82,1.78c-1.03,0.01-2-0.96-1.95-1.96c0.05-1.03,1.05-1.86,2.12-1.76
	C477.59,439.61,478.26,440.39,478.22,441.44z"
              />
              <path
                d="M351.81,441.63c-0.02,0.95-0.9,1.81-1.85,1.82c-1.06,0.01-1.94-0.95-1.85-2.03c0.08-0.97,0.98-1.77,1.93-1.71
	C350.98,439.78,351.83,440.7,351.81,441.63z"
              />
              <path
                d="M485.26,440.82c0.03,1-0.95,1.97-1.98,1.94c-1-0.03-1.77-0.8-1.8-1.82c-0.03-1.05,0.66-1.84,1.68-1.92
	C484.22,438.93,485.22,439.79,485.26,440.82z"
              />
              <path
                d="M452.59,147.37c0.04,0.95-0.78,1.86-1.74,1.94c-0.98,0.08-1.87-0.67-1.97-1.67c-0.11-1.08,0.76-2.07,1.82-2.08
	C451.63,145.56,452.54,146.43,452.59,147.37z"
              />
              <path
                d="M341.71,493c-0.04,0.94-0.92,1.79-1.86,1.78c-1.04-0.01-1.91-0.99-1.81-2.04c0.1-0.96,1.01-1.74,1.95-1.67
	C340.91,491.15,341.75,492.09,341.71,493z"
              />
              <path
                d="M392.79,84.02c0.04,1.01-0.71,1.88-1.69,1.96c-1.05,0.08-2.05-0.86-2.02-1.9c0.02-0.94,0.91-1.83,1.85-1.85
	C391.89,82.21,392.75,83.04,392.79,84.02z"
              />
              <path
                d="M543.31,609.18c0.06,0.91-0.74,1.86-1.68,1.98c-1.1,0.14-2.03-0.63-2.09-1.74c-0.06-1.06,0.59-1.85,1.62-1.97
	C542.23,607.33,543.24,608.14,543.31,609.18z"
              />
              <path
                d="M412.6,542.79c-0.96,0.02-1.87-0.81-1.93-1.76c-0.07-1.01,0.93-2.03,1.95-1.99c0.97,0.04,1.77,0.9,1.77,1.9
	C414.38,541.93,413.57,542.76,412.6,542.79z"
              />
              <path
                d="M388.28,572.04c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.67c-0.1-1.07,0.77-2.06,1.82-2.06
	C387.33,570.23,388.24,571.1,388.28,572.04z"
              />
              <path
                d="M469.36,174.73c0.04,0.94-0.78,1.85-1.73,1.92c-0.96,0.07-1.85-0.69-1.95-1.67c-0.1-1.07,0.77-2.05,1.82-2.05
	C468.42,172.93,469.32,173.8,469.36,174.73z"
              />
              <path
                d="M293.97,193.75c0,1.02-0.74,1.83-1.74,1.89c-1.03,0.06-2.04-0.86-2.04-1.88c-0.01-1.04,0.95-1.92,2.03-1.87
	C293.25,191.95,293.97,192.71,293.97,193.75z"
              />
              <path
                d="M431.82,179.8c-1.02,0.04-2.02-0.91-2-1.91c0.02-0.94,0.86-1.76,1.84-1.81c1.13-0.05,1.95,0.75,1.93,1.88
	C433.58,178.98,432.83,179.76,431.82,179.8z"
              />
              <path
                d="M401.53,559.83c0.02-0.96,0.89-1.77,1.88-1.78c1.14-0.01,1.95,0.85,1.85,1.99c-0.08,1.03-0.9,1.73-1.94,1.67
	C402.36,561.66,401.51,560.77,401.53,559.83z"
              />
              <path
                d="M483,538.63c-0.06,1-0.91,1.78-1.89,1.74c-0.96-0.04-1.81-0.91-1.81-1.86c0-1.03,1.04-1.98,2.05-1.87
	C482.3,536.74,483.06,537.65,483,538.63z"
              />
              <path
                d="M372.37,492.03c0.91-0.07,1.89,0.81,1.96,1.74c0.07,0.95-0.76,1.89-1.72,1.96c-0.97,0.07-1.87-0.73-1.93-1.72
	C370.61,493.03,371.41,492.1,372.37,492.03z"
              />
              <path
                d="M404.16,193.09c0.92,0,1.82,0.88,1.85,1.81c0.03,1.02-0.96,1.98-1.97,1.91c-0.95-0.07-1.73-0.95-1.7-1.92
	C402.37,193.92,403.22,193.09,404.16,193.09z"
              />
              <path
                d="M270.69,340.31c0.02,0.93-0.82,1.83-1.76,1.88c-0.95,0.05-1.83-0.73-1.91-1.7c-0.08-1.06,0.81-2.03,1.85-2.01
	C269.79,338.5,270.68,339.39,270.69,340.31z"
              />
              <path
                d="M348.4,429.06c-0.97-0.05-1.76-0.92-1.73-1.91c0.02-0.98,0.86-1.83,1.81-1.84c1.02-0.01,1.98,1.03,1.89,2.04
	C350.28,428.3,349.34,429.11,348.4,429.06z"
              />
              <path
                d="M417.74,537.09c-0.01,1-0.82,1.84-1.8,1.85c-0.96,0.02-1.86-0.82-1.92-1.77c-0.06-1.02,0.94-2.03,1.96-1.99
	C416.95,535.23,417.75,536.1,417.74,537.09z"
              />
              <path
                d="M343.4,427.86c0.03,1.02-0.69,1.85-1.69,1.94c-1.03,0.09-2.05-0.8-2.09-1.82c-0.04-1.04,0.9-1.95,1.97-1.92
	C342.63,426.08,343.37,426.82,343.4,427.86z"
              />
              <path
                d="M310.25,340.29c0.03,0.99-0.76,1.86-1.72,1.92c-1.03,0.06-2.02-0.9-1.98-1.94c0.04-0.93,0.95-1.81,1.88-1.81
	C309.39,338.47,310.23,339.31,310.25,340.29z"
              />
              <path
                d="M402.86,198.86c-0.04,0.99-0.87,1.8-1.84,1.8c-0.96-0.01-1.83-0.86-1.86-1.81c-0.03-1.01,1-2,2-1.93
	C402.11,196.98,402.9,197.88,402.86,198.86z"
              />
              <path
                d="M446.53,494.93c-0.04,1-0.87,1.8-1.85,1.79c-0.96-0.01-1.83-0.87-1.85-1.82c-0.03-1.01,1.01-2,2.01-1.92
	C445.79,493.05,446.57,493.95,446.53,494.93z"
              />
              <path
                d="M484.71,433.66c0,0.97-0.85,1.8-1.86,1.8c-1.04,0-1.83-0.77-1.83-1.79c0-1.02,0.78-1.79,1.82-1.8
	C483.85,431.88,484.71,432.7,484.71,433.66z"
              />
              <path
                d="M357.41,193.4c1.04,0.02,1.8,0.81,1.78,1.84c-0.02,1.02-0.82,1.77-1.86,1.75c-1.01-0.02-1.84-0.86-1.81-1.83
	C355.54,194.17,356.41,193.38,357.41,193.4z"
              />
              <path
                d="M480.51,197.24c1.01,0.03,1.83,0.86,1.8,1.84c-0.03,0.96-0.91,1.77-1.91,1.75c-1.03-0.03-1.8-0.82-1.77-1.85
	C478.66,197.96,479.46,197.22,480.51,197.24z"
              />
              <path
                d="M413.89,201.53c-0.06,1.03-0.87,1.74-1.92,1.68c-1.01-0.05-1.8-0.92-1.74-1.89c0.06-0.97,0.95-1.74,1.95-1.69
	C413.21,199.69,413.94,200.5,413.89,201.53z"
              />
              <path
                d="M338.31,536.71c-0.06,0.94-1.01,1.79-1.92,1.72c-0.96-0.07-1.74-0.97-1.68-1.94c0.06-0.97,0.96-1.78,1.9-1.72
	C337.54,534.83,338.37,535.77,338.31,536.71z"
              />
              <path
                d="M523.54,339.83c0.02,0.95-0.84,1.85-1.79,1.87c-0.96,0.02-1.82-0.82-1.84-1.8c-0.02-0.98,0.81-1.86,1.77-1.88
	C522.61,338,523.53,338.89,523.54,339.83z"
              />
              <path
                d="M278.05,196.78c-0.96,0.05-1.84-0.76-1.89-1.74c-0.05-0.97,0.75-1.88,1.7-1.93c0.91-0.05,1.85,0.81,1.9,1.75
	C279.82,195.82,279,196.73,278.05,196.78z"
              />
              <path
                d="M406.68,202.14c-0.12,1.02-1,1.69-2.04,1.56c-0.99-0.12-1.74-1.06-1.61-2.02c0.14-0.99,1.05-1.68,2.07-1.56
	C406.13,200.24,406.81,201.11,406.68,202.14z"
              />
              <path
                d="M369.82,336.87c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C367.97,337.71,368.81,336.88,369.82,336.87z"
              />
              <path
                d="M491.11,425.65c0.06,0.97-0.76,1.88-1.72,1.92c-0.85,0.03-1.69-0.71-1.8-1.58c-0.12-0.98,0.62-1.93,1.59-2.03
	C490.11,423.86,491.05,424.67,491.11,425.65z"
              />
              <path
                d="M368.31,342.43c0.04,1.03-0.72,1.84-1.76,1.87c-0.99,0.03-1.89-0.78-1.93-1.74c-0.04-0.97,0.78-1.83,1.79-1.86
	C367.47,340.67,368.27,341.4,368.31,342.43z"
              />
              <path
                d="M373.72,177.6c-0.95,0.02-1.84-0.85-1.86-1.81c-0.02-0.94,0.86-1.86,1.8-1.87c0.95-0.02,1.82,0.83,1.83,1.81
	C375.51,176.72,374.69,177.58,373.72,177.6z"
              />
              <path
                d="M479.52,435.71c0.99,0.01,1.83,0.82,1.86,1.77c0.02,1.02-0.96,1.93-2.02,1.85c-1-0.07-1.73-0.89-1.69-1.9
	C477.71,436.41,478.48,435.69,479.52,435.71z"
              />
              <path
                d="M340.45,538.15c0.97,0.07,1.74,1.01,1.65,1.98c-0.09,0.87-0.91,1.63-1.77,1.63c-0.97,0-1.8-0.88-1.77-1.87
	C338.59,538.92,339.5,538.08,340.45,538.15z"
              />
              <path
                d="M474.07,438.29c-0.03,0.97-0.97,1.83-1.91,1.76c-0.96-0.08-1.76-1.04-1.66-2c0.09-0.87,0.92-1.64,1.78-1.64
	C473.24,436.41,474.11,437.33,474.07,438.29z"
              />
              <path
                d="M244.5,165.37c0.04,1.02-0.72,1.83-1.75,1.86c-0.99,0.03-1.88-0.77-1.92-1.73c-0.04-0.98,0.76-1.82,1.78-1.85
	C243.67,163.62,244.47,164.35,244.5,165.37z"
              />
              <path
                d="M399.75,475.26c0.91-0.05,1.85,0.83,1.89,1.76c0.05,0.96-0.78,1.86-1.73,1.91c-0.96,0.05-1.83-0.77-1.88-1.74
	C398,476.21,398.8,475.31,399.75,475.26z"
              />
              <path
                d="M462.85,166.29c-0.99-0.05-1.81-0.89-1.79-1.84c0.02-1.04,1.01-1.88,2.08-1.77c1.02,0.1,1.7,0.92,1.62,1.95
	C464.68,165.64,463.88,166.34,462.85,166.29z"
              />
              <path
                d="M410,95.05c-0.96,0.05-1.84-0.76-1.89-1.74c-0.05-0.97,0.75-1.88,1.7-1.93c0.91-0.05,1.85,0.81,1.9,1.75
	C411.78,94.09,410.96,95,410,95.05z"
              />
              <path
                d="M293.39,83.52c-0.97,0.04-1.85-0.8-1.86-1.79c-0.01-0.99,0.85-1.86,1.82-1.84c0.85,0.02,1.68,0.82,1.74,1.68
	C295.17,82.55,294.36,83.48,293.39,83.52z"
              />
              <path
                d="M266.49,84.08c0.05,1.03-0.66,1.85-1.66,1.94c-1.01,0.09-1.86-0.54-1.87-1.37c-0.01-0.84,1.08-2.12,1.87-2.2
	C265.66,82.37,266.45,83.14,266.49,84.08z"
              />
              <path
                d="M466.24,494.76c-0.95-0.03-1.78-0.93-1.75-1.89c0.03-0.93,0.96-1.81,1.87-1.78c0.95,0.04,1.77,0.93,1.73,1.9
	C468.06,493.97,467.19,494.79,466.24,494.76z"
              />
              <path
                d="M412.54,416.29c-0.98-0.01-1.84-0.82-1.87-1.76c-0.03-1.04,0.93-1.93,2-1.86c1.02,0.06,1.74,0.85,1.7,1.88
	C414.35,415.56,413.57,416.3,412.54,416.29z"
              />
              <path
                d="M370.96,414.3c-0.04-0.97,0.81-1.87,1.77-1.87c0.86,0,1.68,0.76,1.77,1.63c0.1,0.95-0.7,1.92-1.65,1.99
	C371.93,416.12,371,415.26,370.96,414.3z"
              />
              <path
                d="M273.52,249.62c0.02,0.95-0.83,1.84-1.77,1.85c-0.95,0.02-1.8-0.82-1.82-1.79c-0.02-0.97,0.81-1.84,1.76-1.86
	C272.6,247.81,273.5,248.69,273.52,249.62z"
              />
              <path
                d="M421.54,413.86c-0.06,0.98-0.98,1.8-1.94,1.71c-0.96-0.09-1.73-1.07-1.61-2.03c0.11-0.87,0.97-1.63,1.82-1.6
	C420.77,411.97,421.59,412.88,421.54,413.86z"
              />
              <path
                d="M340.18,411.72c1.04,0.04,1.78,0.84,1.73,1.88c-0.04,1.03-0.85,1.75-1.9,1.71c-1.01-0.04-1.82-0.9-1.77-1.87
	C338.3,412.46,339.18,411.68,340.18,411.72z"
              />
              <path
                d="M383.62,601.31c0.04,0.98-0.79,1.87-1.76,1.88c-0.85,0.01-1.68-0.75-1.77-1.62c-0.1-0.98,0.67-1.91,1.63-1.99
	C382.67,599.5,383.58,600.33,383.62,601.31z"
              />
              <path
                d="M469.11,443.9c-0.95-0.04-1.8-0.94-1.76-1.9c0.03-0.93,0.97-1.82,1.89-1.79c0.96,0.04,1.77,0.93,1.74,1.91
	C470.95,443.11,470.08,443.94,469.11,443.9z"
              />
              <path
                d="M370.71,411.35c-0.12,1.01-0.96,1.68-1.99,1.59c-0.98-0.09-1.77-0.98-1.71-1.92c0.07-1.06,1.07-1.84,2.16-1.69
	C370.18,409.47,370.83,410.31,370.71,411.35z"
              />
              <path
                d="M536.05,609.72c0.03,0.97-0.8,1.81-1.81,1.84c-1.04,0.03-1.84-0.72-1.87-1.74c-0.02-1.03,0.73-1.81,1.77-1.84
	C535.16,607.95,536.02,608.75,536.05,609.72z"
              />
              <path
                d="M417.56,410.54c0.06,0.94-0.74,1.83-1.72,1.92c-1.03,0.09-1.87-0.59-1.98-1.6c-0.13-1.12,0.65-2.02,1.78-2.04
	C416.63,408.81,417.5,409.58,417.56,410.54z"
              />
              <path
                d="M358.62,211.38c-1.04,0.02-1.84-0.73-1.86-1.75c-0.02-1.03,0.74-1.81,1.78-1.84c1.01-0.02,1.87,0.77,1.9,1.75
	C360.46,210.51,359.62,211.35,358.62,211.38z"
              />
              <path
                d="M421.09,533.05c-0.02,1.03-0.82,1.78-1.87,1.75c-1.01-0.03-1.85-0.88-1.82-1.85c0.03-0.97,0.9-1.78,1.91-1.75
	C420.37,531.22,421.12,532,421.09,533.05z"
              />
              <path
                d="M437.89,211.88c-0.98-0.05-1.81-0.89-1.79-1.84c0.02-1.04,1.01-1.88,2.08-1.77c1.02,0.1,1.7,0.92,1.62,1.95
	C439.73,211.23,438.93,211.92,437.89,211.88z"
              />
              <path
                d="M357.84,406.67c0.96-0.01,1.81,0.87,1.79,1.85c-0.03,0.98-0.92,1.82-1.87,1.76c-0.88-0.05-1.63-0.83-1.67-1.72
	C356.04,407.59,356.89,406.68,357.84,406.67z"
              />
              <path
                d="M479.59,542.41c-0.03,0.98-0.94,1.81-1.89,1.74c-0.96-0.07-1.75-1.01-1.66-1.98c0.08-0.86,0.92-1.63,1.77-1.63
	C478.78,540.55,479.62,541.43,479.59,542.41z"
              />
              <path
                d="M390.35,347.95c0.04,0.99-0.79,1.88-1.77,1.88c-0.95,0.01-1.82-0.9-1.79-1.87c0.02-0.87,0.8-1.69,1.66-1.76
	C389.39,346.13,390.31,346.97,390.35,347.95z"
              />
              <path
                d="M408.9,476.42c-0.03,0.96-0.92,1.78-1.91,1.76c-1.04-0.02-1.8-0.82-1.78-1.85c0.03-1.03,0.82-1.77,1.88-1.75
	C408.1,474.6,408.93,475.45,408.9,476.42z"
              />
              <path
                d="M395.46,571.61c-0.13,0.96-1.05,1.68-2.03,1.6c-1.1-0.09-1.84-1.08-1.64-2.17c0.18-0.98,1.07-1.6,2.1-1.45
	C394.87,569.73,395.6,570.67,395.46,571.61z"
              />
              <path
                d="M297.06,252.91c-0.97-0.06-1.77-0.99-1.7-1.96c0.08-0.97,1.05-1.78,1.98-1.66c0.85,0.11,1.59,0.97,1.58,1.84
	C298.92,252.09,298,252.96,297.06,252.91z"
              />
              <path
                d="M472.79,194.37c-0.97,0.01-1.82-0.87-1.8-1.85c0.02-0.98,0.92-1.83,1.88-1.77c0.86,0.05,1.65,0.86,1.69,1.73
	C474.6,193.45,473.75,194.36,472.79,194.37z"
              />
              <path
                d="M266.62,190.52c1.05-0.03,1.85,0.71,1.88,1.74c0.03,1.03-0.73,1.83-1.77,1.86c-1,0.03-1.89-0.79-1.92-1.75
	C264.78,191.39,265.61,190.55,266.62,190.52z"
              />
              <path
                d="M252.15,211.69c0.03,0.99-0.81,1.87-1.78,1.87c-0.95,0-1.82-0.91-1.78-1.88c0.03-0.87,0.81-1.69,1.67-1.75
	C251.21,209.87,252.12,210.71,252.15,211.69z"
              />
              <path
                d="M355.8,405.17c-0.03,0.98-0.93,1.82-1.89,1.76c-0.96-0.06-1.77-1.02-1.68-1.98c0.08-0.87,0.92-1.64,1.78-1.65
	C354.98,403.3,355.83,404.19,355.8,405.17z"
              />
              <path
                d="M392.79,582.43c0,1.03-0.8,1.8-1.84,1.79c-1-0.01-1.87-0.85-1.86-1.81c0.01-0.97,0.88-1.79,1.88-1.79
	C392.02,580.63,392.79,581.4,392.79,582.43z"
              />
              <path
                d="M510.82,402.55c-0.04,1.03-0.84,1.77-1.89,1.73c-1.01-0.04-1.84-0.9-1.8-1.87c0.04-0.97,0.92-1.76,1.93-1.73
	C510.12,400.72,510.86,401.51,510.82,402.55z"
              />
              <path
                d="M305.43,449.28c-0.01,0.99-0.86,1.83-1.82,1.82c-0.95-0.01-1.82-0.9-1.81-1.85c0.01-0.94,0.91-1.84,1.84-1.83
	C304.61,447.43,305.44,448.3,305.43,449.28z"
              />
              <path
                d="M400.92,569.12c1,0.06,1.79,0.93,1.73,1.9c-0.07,0.97-0.96,1.74-1.96,1.68c-1.03-0.06-1.76-0.89-1.7-1.91
	C399.05,569.76,399.86,569.06,400.92,569.12z"
              />
              <path
                d="M361.87,330.35c0.91-0.03,1.84,0.85,1.87,1.78c0.03,0.96-0.8,1.85-1.75,1.89c-0.96,0.03-1.82-0.79-1.85-1.77
	C360.1,331.27,360.91,330.38,361.87,330.35z"
              />
              <path
                d="M457.08,490.26c0.02,1.02-0.75,1.81-1.79,1.83c-1,0.02-1.87-0.8-1.89-1.76c-0.02-0.97,0.81-1.81,1.82-1.82
	C456.27,488.49,457.06,489.24,457.08,490.26z"
              />
              <path
                d="M462.54,448.18c1.03,0.05,1.76,0.82,1.74,1.84c-0.02,1.01-0.78,1.77-1.81,1.78c-1.08,0.01-1.98-0.92-1.89-1.96
	C460.68,448.88,461.56,448.13,462.54,448.18z"
              />
              <path
                d="M376.44,329.2c1,0.06,1.79,0.93,1.73,1.9c-0.07,0.97-0.96,1.74-1.96,1.68c-1.03-0.06-1.76-0.89-1.7-1.91
	C374.58,329.84,375.39,329.14,376.44,329.2z"
              />
              <path
                d="M464.26,489.63c-0.03,0.98-0.9,1.81-1.86,1.78c-0.95-0.03-1.8-0.94-1.77-1.89c0.03-0.93,0.97-1.82,1.88-1.79
	C463.47,487.76,464.29,488.65,464.26,489.63z"
              />
              <path
                d="M499.78,233.51c0.04,1.02-0.72,1.83-1.75,1.86c-0.99,0.03-1.88-0.77-1.92-1.73c-0.04-0.98,0.77-1.82,1.78-1.85
	C498.95,231.76,499.74,232.49,499.78,233.51z"
              />
              <path
                d="M450.1,451.14c0.03,0.95-0.81,1.84-1.75,1.87c-0.95,0.03-1.81-0.81-1.84-1.77c-0.03-0.96,0.79-1.85,1.74-1.88
	C449.17,449.33,450.08,450.2,450.1,451.14z"
              />
              <path
                d="M382.85,487.52c1.04,0.02,1.79,0.78,1.79,1.81c0,1.02-0.76,1.8-1.78,1.84c-1.07,0.03-2.02-0.91-1.95-1.94
	C380.99,488.26,381.86,487.49,382.85,487.52z"
              />
              <path
                d="M363.79,498.37c0.05,0.97-0.77,1.84-1.78,1.88c-1.05,0.05-1.86-0.69-1.9-1.72c-0.04-1.04,0.69-1.84,1.74-1.88
	C362.86,496.61,363.74,497.4,363.79,498.37z"
              />
              <path
                d="M430.43,122.11c0.92-0.07,1.87,0.82,1.9,1.77c0.03,0.87-0.7,1.73-1.55,1.85c-0.94,0.13-1.92-0.66-2.01-1.63
	C428.67,123.14,429.47,122.18,430.43,122.11z"
              />
              <path
                d="M338.36,354.16c-0.97-0.04-1.79-0.95-1.73-1.93c0.06-0.98,1-1.8,1.95-1.7c0.86,0.09,1.61,0.93,1.61,1.81
	C340.2,353.3,339.3,354.19,338.36,354.16z"
              />
              <path
                d="M403.81,86.86c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.81-0.94-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.69
	C403.15,85,403.87,85.81,403.81,86.86z"
              />
              <path
                d="M396.92,87.31c-0.05,0.95-0.93,1.75-1.9,1.74c-1.12-0.01-1.92-0.91-1.8-2.03c0.11-1.03,0.92-1.69,1.97-1.61
	C396.17,85.49,396.97,86.36,396.92,87.31z"
              />
              <path
                d="M418.49,172.21c-0.03,0.96-0.91,1.77-1.9,1.75c-1.03-0.02-1.8-0.82-1.77-1.84c0.03-1.02,0.82-1.76,1.87-1.74
	C417.69,170.39,418.52,171.23,418.49,172.21z"
              />
              <path
                d="M257.45,395.45c0.04,0.98-0.77,1.87-1.73,1.91c-0.93,0.04-1.85-0.82-1.9-1.77c-0.04-0.94,0.81-1.87,1.75-1.91
	C256.53,393.64,257.41,394.47,257.45,395.45z"
              />
              <path
                d="M403.58,351.94c0.91-0.06,1.86,0.81,1.91,1.74c0.05,0.95-0.76,1.87-1.71,1.92c-0.95,0.06-1.84-0.75-1.89-1.73
	C401.83,352.91,402.62,352,403.58,351.94z"
              />
              <path
                d="M316.97,352.44c0.96-0.06,1.86,0.8,1.88,1.78c0.02,0.99-0.83,1.86-1.8,1.85c-0.87-0.01-1.68-0.78-1.76-1.66
	C315.2,353.47,316.03,352.5,316.97,352.44z"
              />
              <path
                d="M404.73,575.83c-0.97,0.06-1.86-0.78-1.88-1.77c-0.02-0.98,0.83-1.87,1.8-1.86c0.86,0.01,1.69,0.79,1.77,1.66
	C406.5,574.83,405.69,575.78,404.73,575.83z"
              />
              <path
                d="M384.86,595.7c0.9-0.06,1.85,0.81,1.9,1.73c0.06,0.96-0.74,1.86-1.7,1.92c-0.95,0.06-1.83-0.75-1.89-1.72
	C383.12,596.68,383.92,595.76,384.86,595.7z"
              />
              <path
                d="M319.34,28.84c0.05,0.97-0.75,1.87-1.71,1.92c-0.92,0.05-1.85-0.8-1.9-1.74c-0.05-0.94,0.78-1.87,1.72-1.92
	C318.4,27.04,319.29,27.87,319.34,28.84z"
              />
              <path
                d="M421.94,520.15c0.99,0.02,1.84,0.83,1.86,1.78c0.02,1.02-0.99,1.94-2.04,1.86c-1.01-0.07-1.73-0.9-1.69-1.92
	C420.13,520.84,420.9,520.13,421.94,520.15z"
              />
              <path
                d="M580.4,620.26c0.04,1.03-0.72,1.84-1.76,1.87c-0.99,0.03-1.89-0.78-1.93-1.74c-0.04-0.97,0.78-1.83,1.79-1.86
	C579.57,618.5,580.36,619.23,580.4,620.26z"
              />
              <path
                d="M424.94,389.87c0.96-0.06,1.86,0.8,1.88,1.78c0.02,0.99-0.83,1.86-1.8,1.85c-0.87-0.01-1.68-0.78-1.76-1.66
	C423.17,390.9,424,389.93,424.94,389.87z"
              />
              <path
                d="M262.83,353.37c0.96-0.01,1.81,0.85,1.82,1.83c0.01,0.99-0.82,1.84-1.79,1.85c-0.95,0.01-1.83-0.87-1.84-1.83
	C261.01,354.29,261.9,353.38,262.83,353.37z"
              />
              <path
                d="M399.3,574.81c-0.04,0.96-0.94,1.77-1.93,1.74c-1.04-0.03-1.79-0.84-1.76-1.87c0.04-1.04,0.84-1.76,1.89-1.73
	C398.52,572.98,399.34,573.84,399.3,574.81z"
              />
              <path
                d="M284.61,252.36c-0.04,0.96-0.93,1.76-1.92,1.73c-1.03-0.04-1.79-0.84-1.75-1.86c0.04-1.03,0.84-1.75,1.89-1.72
	C283.84,250.54,284.65,251.38,284.61,252.36z"
              />
              <path
                d="M347.21,391.34c0.06,1.02-0.65,1.85-1.66,1.94c-1.05,0.09-2.07-0.81-2.06-1.83c0-0.95,0.85-1.78,1.84-1.81
	C346.37,389.6,347.15,390.31,347.21,391.34z"
              />
              <path
                d="M340.48,462.36c-0.98-0.05-1.82-0.91-1.8-1.86c0.02-1.04,1.02-1.89,2.1-1.77c1.02,0.11,1.7,0.93,1.62,1.96
	C342.32,461.71,341.51,462.42,340.48,462.36z"
              />
              <path
                d="M250.26,356.52c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.82-0.93-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.7
	C249.59,354.67,250.32,355.48,250.26,356.52z"
              />
              <path
                d="M301.59,240c0.03,0.98-0.78,1.86-1.75,1.89c-0.94,0.03-1.85-0.83-1.88-1.79c-0.03-0.94,0.83-1.86,1.77-1.9
	C300.69,238.18,301.56,239.02,301.59,240z"
              />
              <path
                d="M335.22,461.01c0.01,1.02-0.75,1.81-1.76,1.85c-0.98,0.03-1.87-0.74-1.95-1.69c-0.09-1.04,0.83-1.97,1.91-1.95
	C334.45,459.22,335.21,459.98,335.22,461.01z"
              />
              <path
                d="M434.72,125.44c0.96,0.05,1.76,0.97,1.71,1.94c-0.05,0.99-0.93,1.8-1.9,1.74c-0.96-0.05-1.78-0.97-1.73-1.93
	C432.85,126.27,433.8,125.39,434.72,125.44z"
              />
              <path
                d="M466.16,241.88c-0.95,0.06-1.84-0.74-1.9-1.71c-0.06-0.96,0.74-1.88,1.68-1.94c0.9-0.06,1.85,0.8,1.91,1.72
	C467.91,240.9,467.12,241.81,466.16,241.88z"
              />
              <path
                d="M375.83,325.59c-1.04-0.01-1.82-0.79-1.81-1.82c0.01-1.03,0.8-1.79,1.85-1.78c1.01,0.01,1.86,0.84,1.85,1.82
	C377.71,324.77,376.83,325.6,375.83,325.59z"
              />
              <path
                d="M337.78,323.62c-0.04,0.97-0.97,1.82-1.9,1.74c-0.96-0.08-1.74-1.04-1.64-2c0.09-0.87,0.93-1.62,1.78-1.62
	C336.98,321.75,337.82,322.65,337.78,323.62z"
              />
              <path
                d="M461.44,227.69c0.96-0.04,1.84,0.82,1.85,1.81c0,0.99-0.86,1.85-1.83,1.82c-0.88-0.03-1.67-0.8-1.73-1.68
	C459.65,228.65,460.46,227.72,461.44,227.69z"
              />
              <path
                d="M432.24,519.22c-0.95,0-1.82-0.9-1.79-1.87c0.03-0.87,0.81-1.69,1.66-1.76c0.95-0.07,1.87,0.77,1.9,1.75
	C434.05,518.33,433.21,519.21,432.24,519.22z"
              />
              <path
                d="M361.08,549.09c-0.01,0.97-0.86,1.81-1.81,1.81c-0.93-0.01-1.79-0.89-1.78-1.84c0.01-0.93,0.9-1.82,1.82-1.81
	C360.26,547.25,361.09,548.12,361.08,549.09z"
              />
              <path
                d="M403.53,99.08c0.96,0.06,1.77,1,1.69,1.98c-0.08,0.98-1.04,1.78-1.99,1.65c-0.85-0.11-1.59-0.97-1.57-1.84
	C401.67,99.92,402.61,99.02,403.53,99.08z"
              />
              <path
                d="M518.5,595.03c1.06-0.05,1.86,0.66,1.92,1.69c0.06,1.03-0.68,1.84-1.71,1.9c-1,0.05-1.89-0.73-1.95-1.7
	C516.69,595.94,517.49,595.08,518.5,595.03z"
              />
              <path
                d="M361.57,390.26c-0.01,0.98-0.84,1.82-1.81,1.82c-0.94,0-1.81-0.89-1.8-1.84c0-0.94,0.9-1.83,1.82-1.83
	C360.74,388.42,361.57,389.28,361.57,390.26z"
              />
              <path
                d="M289.33,389.35c-0.04,0.97-0.97,1.83-1.91,1.75c-0.96-0.08-1.76-1.04-1.65-2c0.09-0.88,0.92-1.63,1.78-1.63
	C288.51,387.47,289.37,388.39,289.33,389.35z"
              />
              <path
                d="M296.62,388.46c0.06,0.94-0.72,1.83-1.7,1.91c-1.03,0.09-1.87-0.57-1.99-1.58c-0.13-1.12,0.64-2.01,1.77-2.04
	C295.7,386.73,296.56,387.5,296.62,388.46z"
              />
              <path
                d="M377.9,576.57c0.05,1.03-0.69,1.84-1.72,1.89c-1,0.05-1.89-0.74-1.94-1.7c-0.05-0.97,0.75-1.83,1.75-1.88
	C377.04,574.83,377.85,575.54,377.9,576.57z"
              />
              <path
                d="M321,462.29c-0.04,0.97-0.97,1.83-1.92,1.74c-0.96-0.08-1.75-1.04-1.65-2.01c0.09-0.87,0.93-1.63,1.79-1.63
	C320.18,460.41,321.04,461.33,321,462.29z"
              />
              <path
                d="M301.98,386.3c1.01,0.01,1.84,0.85,1.82,1.82c-0.02,0.96-0.89,1.78-1.89,1.76c-1.04-0.01-1.81-0.8-1.79-1.82
	C300.15,387.04,300.94,386.28,301.98,386.3z"
              />
              <path
                d="M407.9,89.84c0.01,0.96-0.85,1.85-1.8,1.86c-0.97,0.01-1.81-0.83-1.83-1.81c-0.01-0.98,0.82-1.86,1.78-1.87
	C406.98,88.01,407.89,88.9,407.9,89.84z"
              />
              <path
                d="M427.55,359.04c0.01,0.94-0.86,1.83-1.79,1.84c-0.95,0.01-1.79-0.84-1.8-1.81c-0.01-0.97,0.83-1.83,1.78-1.84
	C426.66,357.22,427.54,358.1,427.55,359.04z"
              />
              <path
                d="M514.98,389.41c-1.04-0.01-1.82-0.79-1.81-1.82c0.01-1.03,0.8-1.79,1.85-1.78c1.01,0.01,1.86,0.84,1.85,1.82
	C516.86,388.59,515.98,389.42,514.98,389.41z"
              />
              <path
                d="M418.52,357.98c1.01-0.02,1.88,0.79,1.91,1.76c0.02,0.97-0.83,1.82-1.83,1.84c-1.05,0.02-1.84-0.73-1.86-1.76
	C416.71,358.77,417.47,358,418.52,357.98z"
              />
              <path
                d="M396.99,565.73c0.99,0.02,1.84,0.83,1.86,1.78c0.02,1.02-0.99,1.94-2.04,1.86c-1.01-0.07-1.73-0.9-1.69-1.92
	C395.17,566.43,395.95,565.71,396.99,565.73z"
              />
              <path
                d="M247.19,360.32c-0.04,0.96-0.94,1.77-1.93,1.74c-1.04-0.03-1.79-0.84-1.75-1.87c0.04-1.04,0.84-1.76,1.89-1.73
	C246.41,358.5,247.23,359.35,247.19,360.32z"
              />
              <path
                d="M308.92,389.2c-0.95,0.01-1.83-0.87-1.84-1.82c-0.01-0.94,0.88-1.85,1.81-1.86c0.96-0.01,1.81,0.85,1.82,1.83
	C310.72,388.34,309.89,389.2,308.92,389.2z"
              />
              <path
                d="M375.81,594.87c0.02,0.94-0.81,1.79-1.79,1.84c-1.03,0.05-1.83-0.65-1.91-1.66c-0.09-1.12,0.72-1.97,1.85-1.95
	C374.95,593.11,375.79,593.92,375.81,594.87z"
              />
              <path
                d="M363.07,554.25c-0.95-0.03-1.77-0.92-1.74-1.88c0.03-0.94,0.95-1.81,1.86-1.77c0.95,0.04,1.76,0.93,1.73,1.89
	C364.89,553.46,364.02,554.29,363.07,554.25z"
              />
              <path
                d="M351.53,465c-0.95-0.05-1.76-0.94-1.72-1.9c0.04-0.93,0.98-1.8,1.88-1.75c0.95,0.05,1.75,0.95,1.71,1.91
	C353.36,464.23,352.48,465.05,351.53,465z"
              />
              <path
                d="M346.47,463.74c-0.05,0.94-0.97,1.8-1.89,1.75c-0.96-0.05-1.76-0.94-1.71-1.91c0.04-0.97,0.93-1.8,1.87-1.75
	C345.67,461.88,346.51,462.81,346.47,463.74z"
              />
              <path
                d="M447.68,216.61c-0.03,0.97-0.97,1.83-1.91,1.76c-0.96-0.08-1.76-1.04-1.66-2c0.09-0.87,0.92-1.64,1.78-1.64
	C446.85,214.73,447.72,215.64,447.68,216.61z"
              />
              <path
                d="M422.3,464.21c-0.04-0.97,0.81-1.87,1.77-1.87c0.85,0,1.68,0.76,1.77,1.63c0.1,0.95-0.7,1.92-1.65,1.99
	C423.27,466.03,422.34,465.17,422.3,464.21z"
              />
              <path
                d="M388.28,595.53c-0.96-0.02-1.78-0.89-1.76-1.87c0.02-0.97,0.88-1.82,1.83-1.8c0.95,0.02,1.8,0.92,1.78,1.87
	C390.11,594.69,389.22,595.55,388.28,595.53z"
              />
              <path
                d="M528.54,250.98c1.01,0.05,1.82,0.92,1.76,1.89c-0.06,0.97-0.96,1.76-1.96,1.71c-1.03-0.05-1.78-0.87-1.72-1.9
	C526.67,251.64,527.48,250.92,528.54,250.98z"
              />
              <path
                d="M430.17,181.93c0.04,0.98-0.79,1.87-1.76,1.88c-0.85,0.01-1.68-0.75-1.78-1.62c-0.1-0.97,0.67-1.91,1.63-1.99
	C429.21,180.12,430.13,180.95,430.17,181.93z"
              />
              <path
                d="M431.37,362.33c0.05,0.97-0.8,1.89-1.76,1.9c-0.97,0.01-1.83-0.86-1.81-1.85c0.02-0.88,0.77-1.7,1.64-1.78
	C430.36,360.51,431.32,361.37,431.37,362.33z"
              />
              <path
                d="M476.54,506.7c-0.06,0.97-0.95,1.75-1.95,1.7c-1.03-0.05-1.77-0.87-1.71-1.9c0.06-1.03,0.86-1.74,1.92-1.69
	C475.8,504.87,476.6,505.73,476.54,506.7z"
              />
              <path
                d="M424.24,363.36c-0.13,0.96-1.04,1.67-2.02,1.59c-1.09-0.09-1.84-1.07-1.64-2.16c0.18-0.98,1.07-1.59,2.09-1.45
	C423.65,361.49,424.38,362.42,424.24,363.36z"
              />
              <path
                d="M415.78,555.23c0.04,0.96-0.76,1.86-1.71,1.91c-0.92,0.05-1.84-0.81-1.88-1.75c-0.04-0.94,0.79-1.86,1.72-1.9
	C414.85,553.44,415.74,554.27,415.78,555.23z"
              />
              <path
                d="M418.15,188.13c-0.97,0.04-1.85-0.8-1.86-1.79c-0.01-0.99,0.85-1.86,1.82-1.84c0.85,0.02,1.68,0.82,1.74,1.68
	C419.93,187.15,419.12,188.08,418.15,188.13z"
              />
              <path
                d="M529.5,285.21c-0.03,0.98-0.93,1.81-1.88,1.75c-0.96-0.06-1.75-1-1.66-1.97c0.08-0.86,0.91-1.64,1.76-1.64
	C528.67,283.35,529.52,284.23,529.5,285.21z"
              />
              <path
                d="M376.54,582.06c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C374.68,582.91,375.53,582.07,376.54,582.06z"
              />
              <path
                d="M429.73,467.37c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.82-0.93-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.7
	C429.06,465.51,429.78,466.32,429.73,467.37z"
              />
              <path
                d="M368.77,555.68c0.06,0.98-0.73,1.89-1.69,1.95c-0.93,0.06-1.87-0.79-1.93-1.73c-0.06-0.94,0.77-1.88,1.72-1.95
	C367.81,553.88,368.71,554.7,368.77,555.68z"
              />
              <path
                d="M396.82,479.09c0.96,0.06,1.76,0.98,1.7,1.95c-0.06,0.99-0.94,1.79-1.91,1.73c-0.95-0.06-1.78-0.99-1.72-1.94
	C394.95,479.9,395.91,479.03,396.82,479.09z"
              />
              <path
                d="M422.51,467.87c0.06,0.97-0.73,1.88-1.69,1.94c-0.92,0.06-1.86-0.78-1.92-1.72c-0.06-0.94,0.77-1.88,1.7-1.94
	C421.54,466.08,422.45,466.9,422.51,467.87z"
              />
              <path
                d="M449.64,218.05c0.91-0.06,1.87,0.82,1.92,1.75c0.05,0.95-0.77,1.88-1.73,1.93c-0.96,0.06-1.85-0.75-1.9-1.74
	C447.88,219.03,448.69,218.11,449.64,218.05z"
              />
              <path
                d="M415.31,468.39c0.04,0.96-0.77,1.85-1.72,1.9c-0.92,0.04-1.83-0.81-1.87-1.75c-0.04-0.93,0.8-1.86,1.73-1.9
	C414.38,466.59,415.27,467.43,415.31,468.39z"
              />
              <path
                d="M487.97,556.34c-0.04,0.97-0.97,1.82-1.9,1.74c-0.95-0.08-1.74-1.04-1.64-2c0.09-0.87,0.93-1.62,1.78-1.62
	C487.17,554.47,488.01,555.37,487.97,556.34z"
              />
              <path
                d="M499.8,566.1c0,1.02-0.76,1.8-1.78,1.83c-0.98,0.03-1.87-0.75-1.94-1.7c-0.08-1.04,0.84-1.97,1.93-1.94
	C499.05,564.31,499.8,565.07,499.8,566.1z"
              />
              <path
                d="M472.07,187.18c-0.94,0.01-1.82-0.86-1.82-1.82c-0.01-0.94,0.87-1.84,1.8-1.85c0.95-0.01,1.8,0.85,1.81,1.82
	C473.86,186.32,473.03,187.18,472.07,187.18z"
              />
              <path
                d="M396.15,578.66c-0.04,1.02-0.81,1.75-1.84,1.74c-0.98-0.01-1.84-0.82-1.86-1.77c-0.03-1.04,0.93-1.92,2.01-1.86
	C395.47,576.84,396.18,577.63,396.15,578.66z"
              />
              <path
                d="M457.64,224.58c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C455.78,225.43,456.63,224.59,457.64,224.58z"
              />
              <path
                d="M431.79,508.65c1.05,0.02,1.8,0.8,1.78,1.84c-0.02,1.03-0.82,1.78-1.87,1.76c-1.01-0.02-1.85-0.87-1.82-1.84
	C429.91,509.43,430.78,508.62,431.79,508.65z"
              />
              <path
                d="M379.83,558.35c0.05,1.01-0.68,1.83-1.69,1.9c-1.06,0.07-2.05-0.83-2.02-1.85c0.02-0.95,0.87-1.76,1.85-1.77
	C379.01,556.61,379.79,557.33,379.83,558.35z"
              />
              <path
                d="M430.94,374.97c0.91-0.05,1.85,0.82,1.89,1.76c0.05,0.96-0.78,1.86-1.73,1.91c-0.96,0.05-1.83-0.77-1.88-1.74
	C429.19,375.92,429.99,375.02,430.94,374.97z"
              />
              <path
                d="M339,68.65c-0.95,0.06-1.84-0.75-1.89-1.72c-0.06-0.96,0.74-1.87,1.69-1.94c0.9-0.06,1.85,0.8,1.9,1.73
	C340.75,67.69,339.95,68.59,339,68.65z"
              />
              <path
                d="M401.41,243.79c1.05-0.05,1.86,0.66,1.92,1.69c0.06,1.03-0.68,1.84-1.71,1.9c-1,0.05-1.89-0.73-1.95-1.7
	C399.61,244.7,400.41,243.84,401.41,243.79z"
              />
              <path
                d="M402.76,261.76c-1.04,0.02-1.84-0.73-1.86-1.75c-0.02-1.03,0.74-1.81,1.78-1.84c1.01-0.02,1.87,0.77,1.9,1.75
	C404.6,260.89,403.77,261.74,402.76,261.76z"
              />
              <path
                d="M287.58,185.02c-1.03,0.02-1.82-0.72-1.86-1.74c-0.04-1.03,0.67-1.84,1.69-1.91c1.08-0.07,2.05,0.82,2.03,1.85
	C289.43,184.17,288.56,185,287.58,185.02z"
              />
              <path
                d="M393.05,566c-1.02-0.03-1.77-0.82-1.77-1.83c0.01-1.02,0.76-1.77,1.79-1.8c1.08-0.02,1.99,0.89,1.91,1.93
	C394.92,565.26,394.03,566.04,393.05,566z"
              />
              <path
                d="M415.88,242.53c0.95-0.02,1.82,0.83,1.84,1.81c0.02,0.99-0.8,1.86-1.77,1.88c-0.94,0.02-1.84-0.85-1.86-1.8
	C414.07,243.47,414.93,242.55,415.88,242.53z"
              />
              <path
                d="M347.92,66.34c0.01,0.96-0.86,1.84-1.81,1.86c-0.97,0.01-1.81-0.83-1.82-1.82c-0.01-0.99,0.82-1.85,1.78-1.86
	C347,64.5,347.91,65.4,347.92,66.34z"
              />
              <path
                d="M412.44,559.07c0.04,0.98-0.76,1.87-1.72,1.92c-0.93,0.04-1.86-0.81-1.9-1.76c-0.04-0.94,0.8-1.87,1.75-1.92
	C411.51,557.26,412.4,558.09,412.44,559.07z"
              />
              <path
                d="M244.97,378.65c0.04,0.98-0.76,1.87-1.72,1.92c-0.93,0.04-1.86-0.81-1.9-1.76c-0.04-0.94,0.8-1.87,1.75-1.92
	C244.04,376.84,244.93,377.67,244.97,378.65z"
              />
              <path
                d="M475.53,511.99c1.03,0.06,1.74,0.84,1.71,1.87c-0.03,1.02-0.8,1.76-1.83,1.75c-1.08,0-1.97-0.95-1.86-1.99
	C473.66,512.66,474.54,511.94,475.53,511.99z"
              />
              <path
                d="M494.1,564.57c-0.98-0.01-1.84-0.84-1.87-1.79c-0.03-1.04,0.95-1.93,2.02-1.86c1.02,0.07,1.74,0.87,1.7,1.9
	C495.92,563.85,495.13,564.59,494.1,564.57z"
              />
              <path
                d="M329.73,64.23c-0.02,0.96-0.9,1.79-1.9,1.77c-1.04-0.02-1.81-0.81-1.79-1.84c0.02-1.03,0.81-1.78,1.86-1.76
	C328.91,62.42,329.75,63.25,329.73,64.23z"
              />
              <path
                d="M430.22,514.32c-0.01,1.02-0.78,1.78-1.81,1.79c-0.98,0.01-1.86-0.79-1.91-1.74c-0.06-1.04,0.89-1.95,1.97-1.91
	C429.51,512.51,430.24,513.28,430.22,514.32z"
              />
              <path
                d="M283.44,261.27c-1.01-0.02-1.83-0.84-1.81-1.82c0.02-0.98,0.87-1.77,1.88-1.75c1.03,0.02,1.79,0.8,1.78,1.82
	C285.27,260.55,284.48,261.29,283.44,261.27z"
              />
              <path
                d="M238.99,140.71c0.03,0.97-0.75,1.85-1.72,1.89c-0.79,0.04-1.43-0.22-1.51-1.12c-0.09-1.04,0.92-2.58,1.64-2.55
	C238.17,138.95,238.97,139.84,238.99,140.71z"
              />
              <path
                d="M378.71,567.41c-1.03-0.01-1.81-0.78-1.81-1.79c0.01-1.01,0.8-1.77,1.83-1.77c1,0,1.84,0.82,1.84,1.79
	C380.57,566.6,379.72,567.41,378.71,567.41z"
              />
              <path
                d="M523.99,244c-1,0.06-1.88-0.7-1.95-1.67c-0.07-0.98,0.71-1.84,1.71-1.89c1.05-0.06,1.86,0.64,1.92,1.66
	C525.75,243.12,525.02,243.94,523.99,244z"
              />
              <path
                d="M326.55,526.65c0.06,1.02-0.66,1.84-1.69,1.9c-1,0.06-1.88-0.7-1.94-1.68c-0.06-0.98,0.72-1.83,1.72-1.89
	C325.68,524.92,326.48,525.62,326.55,526.65z"
              />
              <path
                d="M337.58,462.6c1,0.06,1.78,0.92,1.71,1.89c-0.07,0.97-0.95,1.73-1.95,1.67c-1.03-0.06-1.75-0.88-1.69-1.9
	C335.72,463.24,336.53,462.54,337.58,462.6z"
              />
              <path
                d="M424.23,529.11c0.03,0.97-0.78,1.8-1.79,1.83c-1.05,0.03-1.84-0.71-1.86-1.73c-0.03-1.02,0.73-1.81,1.76-1.84
	C423.34,527.35,424.2,528.13,424.23,529.11z"
              />
              <path
                d="M354.04,240.64c-1,0.02-1.86-0.79-1.88-1.75c-0.02-0.97,0.81-1.8,1.81-1.81c1.03-0.01,1.83,0.74,1.85,1.75
	C355.85,239.85,355.08,240.62,354.04,240.64z"
              />
              <path
                d="M357.04,213.54c-0.06,0.97-0.95,1.73-1.94,1.68c-1.03-0.06-1.76-0.87-1.69-1.9c0.06-1.02,0.87-1.72,1.92-1.67
	C356.33,211.7,357.11,212.56,357.04,213.54z"
              />
              <path
                d="M490.11,557.61c1,0.03,1.81,0.86,1.78,1.84c-0.03,0.96-0.9,1.76-1.9,1.73c-1.03-0.03-1.79-0.82-1.75-1.84
	C488.27,558.32,489.07,557.58,490.11,557.61z"
              />
              <path
                d="M565.3,614.64c-0.04,0.96-0.92,1.75-1.91,1.71c-1.03-0.04-1.78-0.83-1.74-1.86c0.04-1.02,0.84-1.74,1.88-1.71
	C564.54,612.82,565.34,613.66,565.3,614.64z"
              />
              <path
                d="M372.62,598.68c0.03,1.02-0.73,1.81-1.76,1.84c-1,0.03-1.87-0.77-1.89-1.73c-0.03-0.98,0.78-1.81,1.79-1.83
	C371.79,596.93,372.59,597.67,372.62,598.68z"
              />
              <path
                d="M320.21,19.78c-0.98-0.01-1.68-0.64-1.72-1.57c-0.03-0.91,1.1-1.95,2.06-1.82c0.9,0.12,1.39,0.72,1.45,1.61
	C322.08,18.97,321.22,19.79,320.21,19.78z"
              />
              <path
                d="M419.02,408.58c-1.03-0.01-1.81-0.78-1.81-1.79c0.01-1.01,0.8-1.77,1.83-1.77c1,0,1.84,0.82,1.84,1.79
	C420.88,407.78,420.03,408.59,419.02,408.58z"
              />
              <path
                d="M440.76,137.6c0.01,1.01-0.77,1.78-1.81,1.79c-1,0-1.85-0.81-1.86-1.78c0-0.97,0.84-1.79,1.84-1.79
	C439.97,135.83,440.76,136.59,440.76,137.6z"
              />
              <path
                d="M374.58,600.31c1.04-0.03,1.84,0.69,1.88,1.71c0.04,1.02-0.71,1.82-1.74,1.85c-1,0.03-1.87-0.75-1.91-1.72
	C372.77,601.19,373.57,600.35,374.58,600.31z"
              />
              <path
                d="M407.36,418.23c0.05-0.98,0.92-1.75,1.92-1.7c1.04,0.05,1.77,0.84,1.72,1.87c-0.05,1.02-0.85,1.74-1.89,1.69
	C408.1,420.05,407.31,419.21,407.36,418.23z"
              />
              <path
                d="M334.5,493.73c-0.05,0.97-0.92,1.74-1.92,1.7c-1.03-0.04-1.77-0.84-1.72-1.87c0.05-1.02,0.85-1.74,1.9-1.69
	C333.75,491.91,334.54,492.76,334.5,493.73z"
              />
              <path
                d="M414.83,421.69c-0.01,1-0.79,1.76-1.81,1.75c-1,0-1.82-0.81-1.81-1.78c0.01-0.97,0.84-1.76,1.84-1.75
	C414.07,419.92,414.84,420.68,414.83,421.69z"
              />
              <path
                d="M311.6,374.54c0.96,0.03,1.78,0.94,1.68,1.91c-0.1,1-0.7,1.58-1.69,1.66c-0.95,0.08-1.82-0.81-1.82-1.78
	C309.77,375.36,310.64,374.51,311.6,374.54z"
              />
              <path
                d="M435.92,372.99c-0.01,0.97-0.89,1.81-1.84,1.77c-0.95-0.04-1.75-0.95-1.67-1.93c0.06-0.87,0.86-1.64,1.71-1.66
	C435.07,371.16,435.93,372.02,435.92,372.99z"
              />
              <path
                d="M412.22,472.47c0.04,0.98-0.76,1.81-1.76,1.84c-1.04,0.03-1.84-0.69-1.87-1.71c-0.03-1.01,0.71-1.81,1.74-1.84
	C411.33,470.72,412.18,471.49,412.22,472.47z"
              />
              <path
                d="M399.96,581.94c0,1-0.78,1.76-1.8,1.76c-1,0-1.82-0.8-1.82-1.77c0-0.97,0.82-1.77,1.82-1.76
	C399.18,580.18,399.96,580.95,399.96,581.94z"
              />
              <path
                d="M433.85,513.55c0.01-0.98,0.89-1.88,1.82-1.77c0.95,0.12,1.6,0.66,1.68,1.68c0.08,0.96-0.76,1.87-1.71,1.9
	C434.7,515.4,433.84,514.52,433.85,513.55z"
              />
              <path
                d="M396.12,372.78c-0.02,1.01-0.81,1.74-1.84,1.72c-1-0.02-1.8-0.83-1.77-1.81c0.03-0.98,0.86-1.75,1.86-1.72
	C395.38,370.99,396.14,371.77,396.12,372.78z"
              />
              <path
                d="M391.29,481.46c-0.03,0.97-0.92,1.8-1.87,1.74c-0.96-0.06-1.73-0.98-1.64-1.95c0.08-0.85,0.91-1.63,1.74-1.63
	C390.48,479.62,391.32,480.49,391.29,481.46z"
              />
              <path
                d="M459.14,159.35c1.02,0.03,1.78,0.81,1.76,1.82c-0.02,1.01-0.81,1.75-1.85,1.73c-1.01-0.02-1.81-0.84-1.78-1.82
	C457.3,160.1,458.14,159.32,459.14,159.35z"
              />
              <path
                d="M626.17,625.27c1.02,0.05,1.76,0.85,1.72,1.86c-0.05,1.02-0.84,1.73-1.89,1.69c-1.01-0.04-1.79-0.87-1.74-1.85
	C624.31,625.98,625.17,625.22,626.17,625.27z"
              />
              <path
                d="M398.78,92.36c-1.02-0.05-1.76-0.86-1.7-1.87c0.06-1.02,0.86-1.72,1.9-1.67c1.01,0.05,1.78,0.89,1.72,1.87
	C400.64,91.66,399.78,92.41,398.78,92.36z"
              />
              <path
                d="M433.06,463.65c-0.12,0.99-0.99,1.66-2.02,1.54c-0.99-0.12-1.7-1.02-1.57-1.99c0.13-1,1.01-1.66,2.03-1.54
	C432.5,461.78,433.18,462.64,433.06,463.65z"
              />
              <path
                d="M293.67,253.14c0.95-0.07,1.85,0.76,1.85,1.74c0,1.02-0.56,1.63-1.52,1.82c-0.92,0.18-1.89-0.64-1.99-1.6
	C291.93,254.15,292.71,253.21,293.67,253.14z"
              />
              <path
                d="M387.96,485.57c-0.04,1.02-0.84,1.73-1.88,1.69c-1.01-0.04-1.79-0.87-1.74-1.85c0.05-0.98,0.91-1.74,1.9-1.7
	C387.27,483.76,388.01,484.56,387.96,485.57z"
              />
              <path
                d="M374.86,564.03c-1.02-0.01-1.79-0.79-1.78-1.79c0.01-0.99,0.81-1.75,1.82-1.74c1,0.01,1.81,0.81,1.79,1.79
	C376.69,563.26,375.87,564.05,374.86,564.03z"
              />
              <path
                d="M410.52,245.06c0.02,1.01-0.74,1.79-1.77,1.81c-1,0.02-1.85-0.77-1.86-1.74c-0.02-0.98,0.79-1.79,1.79-1.81
	C409.71,243.31,410.5,244.06,410.52,245.06z"
              />
              <path
                d="M284.08,245.18c-0.05,0.98-0.9,1.73-1.9,1.68c-1.02-0.05-1.75-0.85-1.7-1.86c0.05-1.01,0.86-1.72,1.89-1.67
	C283.37,243.38,284.13,244.2,284.08,245.18z"
              />
              <path
                d="M477.69,521.1c-0.06,1.02-0.86,1.72-1.9,1.67c-1.01-0.05-1.78-0.89-1.72-1.87c0.06-0.98,0.92-1.73,1.92-1.68
	C477.02,519.28,477.75,520.09,477.69,521.1z"
              />
              <path
                d="M427.57,525.33c0,0.97-0.83,1.76-1.83,1.76c-1.02-0.01-1.8-0.78-1.79-1.77c0.01-0.99,0.8-1.76,1.81-1.76
	C426.76,523.55,427.57,524.35,427.57,525.33z"
              />
              <path
                d="M455.3,448.69c1.02,0.03,1.78,0.81,1.76,1.82c-0.02,1.01-0.81,1.75-1.85,1.73c-1.01-0.02-1.81-0.84-1.78-1.82
	C453.46,449.44,454.3,448.66,455.3,448.69z"
              />
              <path
                d="M433.56,363.99c0.95-0.07,1.85,0.76,1.88,1.73c0.03,0.97-0.8,1.85-1.76,1.86c-0.83,0.01-1.67-0.77-1.75-1.62
	C431.83,364.99,432.61,364.05,433.56,363.99z"
              />
              <path
                d="M288.63,255.73c-0.04,1.01-0.83,1.72-1.87,1.68c-1.01-0.04-1.78-0.86-1.73-1.84c0.05-0.98,0.89-1.73,1.89-1.69
	C287.93,253.93,288.67,254.73,288.63,255.73z"
              />
              <path
                d="M243.71,364.42c-0.05,0.98-0.95,1.79-1.89,1.7c-0.96-0.09-1.75-1.04-1.61-1.98c0.15-1,0.81-1.53,1.78-1.58
	C242.93,362.5,243.76,363.45,243.71,364.42z"
              />
              <path
                d="M444.85,141.04c-0.04,0.97-0.9,1.75-1.89,1.71c-1.02-0.04-1.77-0.83-1.74-1.85c0.04-1.01,0.84-1.74,1.87-1.7
	C444.1,139.23,444.89,140.05,444.85,141.04z"
              />
              <path
                d="M375.27,587.63c0.04,0.98-0.76,1.81-1.76,1.84c-1.04,0.03-1.84-0.69-1.87-1.71c-0.03-1.01,0.71-1.81,1.74-1.84
	C374.38,585.89,375.24,586.65,375.27,587.63z"
              />
              <path
                d="M327.2,494.28c0.05,0.98-0.77,1.87-1.72,1.88c-0.96,0.01-1.83-0.85-1.79-1.82c0.04-1.02,0.66-1.59,1.61-1.74
	C326.25,492.45,327.15,493.3,327.2,494.28z"
              />
              <path
                d="M297.56,608.74c0.05,0.97-0.77,1.82-1.77,1.85c-0.96,0.03-1.7-0.6-1.79-1.52c-0.1-1.01,0.61-1.88,1.62-1.98
	C296.61,607,297.51,607.76,297.56,608.74z"
              />
              <path
                d="M367.03,494.37c0,0.97-0.87,1.84-1.8,1.8c-0.96-0.04-1.79-0.95-1.7-1.91c0.09-1.02,0.74-1.56,1.69-1.67
	C366.15,492.48,367.03,493.39,367.03,494.37z"
              />
              <path
                d="M278.05,260.29c-0.05,0.97-0.96,1.78-1.9,1.7c-0.95-0.08-1.72-1.03-1.61-1.98c0.09-0.84,0.96-1.62,1.77-1.6
	C277.27,258.43,278.1,259.33,278.05,260.29z"
              />
              <path
                d="M370.92,560.68c-1.01,0.06-1.85-0.68-1.92-1.66c-0.06-0.98,0.69-1.82,1.69-1.87c1.03-0.06,1.84,0.64,1.9,1.65
	C372.66,559.8,371.94,560.62,370.92,560.68z"
              />
              <path
                d="M403.32,578.04c0.04,1.01-0.71,1.81-1.74,1.84c-1,0.03-1.85-0.74-1.89-1.71c-0.04-0.98,0.75-1.81,1.76-1.84
	C402.49,576.3,403.28,577.02,403.32,578.04z"
              />
              <path
                d="M349.3,436.19c-0.94,0.05-1.83-0.81-1.82-1.78c0.01-1.02,0.6-1.61,1.56-1.78c0.92-0.17,1.88,0.68,1.95,1.64
	C351.06,435.22,350.26,436.14,349.3,436.19z"
              />
              <path
                d="M391.58,591.38c-1.02-0.05-1.75-0.86-1.7-1.86c0.06-1.01,0.86-1.72,1.89-1.67c1,0.05,1.76,0.87,1.71,1.86
	C393.43,590.69,392.58,591.44,391.58,591.38z"
              />
              <path
                d="M477.39,434.3c0.05,0.98-0.77,1.87-1.72,1.88c-0.96,0.01-1.83-0.85-1.79-1.82c0.04-1.02,0.66-1.59,1.61-1.74
	C476.44,432.47,477.35,433.32,477.39,434.3z"
              />
              <path
                d="M476.64,526.43c1,0.01,1.8,0.81,1.79,1.79c-0.01,0.97-0.84,1.76-1.85,1.74c-1.02-0.02-1.79-0.79-1.77-1.79
	C474.83,527.18,475.62,526.42,476.64,526.43z"
              />
              <path
                d="M276.22,73.98c-0.23,0.05-0.47,0.05-0.68,0.15c-0.85,0.38-1.79,0.6-2.32-0.32c-0.6-1.01,0.17-1.81,0.9-2.41
	c0.36-0.3,1.04-0.56,1.41-0.41c0.41,0.17,0.83,0.78,0.9,1.25c0.09,0.58-0.18,1.22-0.29,1.83C276.14,74.07,276.22,73.98,276.22,73.98
	z"
              />
              <path
                d="M476.61,197.44c-1.03,0-1.81-0.77-1.81-1.77c0-1,0.79-1.77,1.81-1.78c1,0,1.83,0.8,1.83,1.77
	C478.45,196.64,477.62,197.45,476.61,197.44z"
              />
              <path
                d="M431.15,129.35c0.95-0.07,1.85,0.75,1.88,1.72c0.04,0.97-0.8,1.85-1.76,1.86c-0.83,0.01-1.67-0.77-1.76-1.61
	C429.43,130.36,430.21,129.42,431.15,129.35z"
              />
              <path
                d="M315.51,25.8c0.01,1.02-0.79,1.79-1.83,1.77c-0.95-0.02-1.67-0.69-1.74-1.62c-0.07-1.02,0.68-1.85,1.71-1.89
	C314.69,24.02,315.5,24.78,315.51,25.8z"
              />
              <path
                d="M271.11,387.35c-0.13,0.99-1.01,1.65-2.01,1.53c-1.01-0.12-1.68-0.98-1.55-1.98c0.13-0.99,0.99-1.65,2.01-1.53
	C270.53,385.48,271.23,386.38,271.11,387.35z"
              />
              <path
                d="M320.01,494.87c-0.01,0.97-0.89,1.83-1.83,1.78c-0.96-0.05-1.78-0.97-1.68-1.93c0.11-1.02,0.77-1.55,1.72-1.65
	C319.15,492.96,320.02,493.9,320.01,494.87z"
              />
              <path
                d="M416.52,356.31c0.06,0.98-0.7,1.81-1.71,1.86c-1.04,0.05-1.84-0.65-1.89-1.66c-0.06-1.01,0.67-1.81,1.69-1.87
	C415.61,354.59,416.46,355.33,416.52,356.31z"
              />
              <path
                d="M508.22,41.63c-0.03,1.31-0.74,1.64-1.58,1.75c-1.02,0.13-2-0.61-1.98-1.62c0.01-0.56,0.31-1.26,0.73-1.61
	c0.25-0.21,1.04-0.02,1.45,0.23C507.42,40.72,507.86,41.28,508.22,41.63z"
              />
              <path
                d="M445.34,500.25c0.94-0.05,1.84,0.8,1.82,1.78c-0.02,1.01-0.59,1.62-1.55,1.79c-0.93,0.16-1.88-0.67-1.95-1.64
	C443.57,501.22,444.38,500.3,445.34,500.25z"
              />
              <path
                d="M314.37,323.67c0.94-0.03,1.82,0.84,1.79,1.82c-0.03,1.01-0.61,1.61-1.58,1.76c-0.93,0.14-1.86-0.7-1.92-1.67
	C312.59,324.61,313.42,323.7,314.37,323.67z"
              />
              <path
                d="M375.27,567.69c1-0.05,1.85,0.69,1.9,1.67c0.06,0.98-0.71,1.81-1.71,1.86c-1.04,0.05-1.84-0.65-1.89-1.67
	C373.52,568.55,374.25,567.75,375.27,567.69z"
              />
              <path
                d="M389.33,356.74c-1.03,0.02-1.82-0.71-1.84-1.72c-0.02-1,0.73-1.79,1.75-1.81c1-0.03,1.83,0.74,1.86,1.72
	C391.12,355.91,390.33,356.72,389.33,356.74z"
              />
              <path
                d="M408.03,571.98c-0.96,0.02-1.81-0.84-1.8-1.81c0.01-0.97,0.88-1.81,1.84-1.77c0.86,0.04,1.63,0.81,1.67,1.69
	C409.79,571.07,408.98,571.96,408.03,571.98z"
              />
              <path
                d="M238.12,175.35c0.5-0.2,0.99-0.48,1.51-0.59c1.16-0.24,2.15,0.44,2.22,1.52c0.07,0.97-0.37,1.68-1.37,1.91
	c-1.11,0.26-1.98-0.33-2.21-1.54c-0.09-0.45-0.05-0.93-0.07-1.39C238.2,175.27,238.12,175.35,238.12,175.35z"
              />
              <path
                d="M486.05,428.05c1.02,0.05,1.76,0.85,1.72,1.86c-0.05,1.02-0.84,1.73-1.89,1.69c-1.01-0.04-1.79-0.87-1.74-1.85
	C484.2,428.77,485.05,428.01,486.05,428.05z"
              />
              <path
                d="M378.43,607.01c-0.96-0.03-1.75-0.93-1.69-1.91c0.06-0.88,0.83-1.65,1.69-1.68c0.95-0.03,1.82,0.82,1.82,1.8
	C380.25,606.19,379.39,607.04,378.43,607.01z"
              />
              <path
                d="M357.02,546.05c-0.05,0.98-0.9,1.73-1.9,1.68c-1.02-0.05-1.75-0.86-1.7-1.86c0.05-1.01,0.86-1.72,1.89-1.67
	C356.31,544.24,357.07,545.06,357.02,546.05z"
              />
              <path
                d="M359.64,202.49c-0.06,1.01-0.85,1.71-1.89,1.66c-1.01-0.05-1.76-0.88-1.71-1.86c0.06-0.98,0.91-1.72,1.91-1.67
	C358.97,200.67,359.7,201.48,359.64,202.49z"
              />
              <path
                d="M474.08,497.64c1.02,0.06,1.74,0.87,1.68,1.88c-0.06,1.01-0.86,1.71-1.9,1.65c-1-0.06-1.76-0.89-1.69-1.87
	C472.23,498.31,473.08,497.58,474.08,497.64z"
              />
              <path
                d="M505.64,404.55c1.02,0,1.8,0.77,1.8,1.77c0,1-0.78,1.76-1.8,1.76c-1,0-1.82-0.79-1.82-1.77
	C503.82,405.34,504.64,404.55,505.64,404.55z"
              />
              <path
                d="M273.77,210.08c-0.01,1.01-0.79,1.77-1.82,1.76c-1-0.01-1.83-0.82-1.82-1.79c0.01-0.97,0.85-1.77,1.85-1.76
	C273,208.3,273.77,209.07,273.77,210.08z"
              />
              <path
                d="M426.79,122.37c-0.95,0.05-1.84-0.78-1.86-1.75c-0.02-0.97,0.83-1.84,1.78-1.83c0.85,0.01,1.66,0.78,1.73,1.64
	C428.53,121.4,427.75,122.32,426.79,122.37z"
              />
              <path
                d="M410.51,411.2c-0.04,1.01-0.83,1.72-1.87,1.68c-1.01-0.04-1.78-0.86-1.73-1.84c0.05-0.98,0.89-1.73,1.89-1.69
	C409.81,409.39,410.55,410.19,410.51,411.2z"
              />
              <path
                d="M381.7,413.68c0.04,0.97-0.79,1.86-1.74,1.87c-0.94,0.02-1.81-0.87-1.76-1.84c0.05-1.02,0.65-1.59,1.61-1.73
	C380.74,411.85,381.65,412.71,381.7,413.68z"
              />
              <path
                d="M419.7,538.42c1.03-0.03,1.83,0.71,1.86,1.72c0.03,1.01-0.73,1.8-1.75,1.83c-1,0.03-1.85-0.76-1.88-1.72
	C417.89,539.27,418.69,538.45,419.7,538.42z"
              />
              <path
                d="M334.67,414.27c-0.02,0.97-0.9,1.8-1.86,1.75c-0.96-0.05-1.74-0.97-1.66-1.94c0.07-0.86,0.88-1.63,1.73-1.64
	C333.84,412.43,334.69,413.31,334.67,414.27z"
              />
              <path
                d="M367.41,604.34c-1-0.02-1.8-0.83-1.78-1.81c0.02-0.98,0.86-1.75,1.86-1.72c1.02,0.03,1.78,0.81,1.76,1.81
	C369.23,603.62,368.44,604.36,367.41,604.34z"
              />
              <path
                d="M352.73,495.56c0,0.97-0.83,1.78-1.83,1.78c-1.03,0-1.81-0.76-1.81-1.77c0-1,0.78-1.77,1.81-1.78
	C351.89,493.79,352.73,494.59,352.73,495.56z"
              />
              <path
                d="M416.49,419.61c-1,0.05-1.86-0.71-1.92-1.68c-0.05-0.98,0.72-1.82,1.72-1.87c1.04-0.05,1.84,0.66,1.9,1.68
	C418.25,418.76,417.51,419.56,416.49,419.61z"
              />
              <path
                d="M424.9,536.4c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.74-1.82,1.77-1.82
	C424.13,534.6,424.92,535.39,424.9,536.4z"
              />
              <path
                d="M478.87,535.28c0.06,1.01-0.7,1.83-1.72,1.86c-1.04,0.03-1.88-0.75-1.83-1.75c0.05-1,0.59-1.62,1.61-1.74
	C477.94,533.53,478.8,534.28,478.87,535.28z"
              />
              <path
                d="M287.06,344.05c-0.95-0.01-1.78-0.89-1.75-1.85c0.04-0.96,0.94-1.82,1.88-1.7c0.96,0.13,1.56,0.7,1.61,1.71
	C288.85,343.18,288,344.06,287.06,344.05z"
              />
              <path
                d="M320.54,415.35c0.05,1.01-0.75,1.85-1.78,1.84c-1.02-0.01-1.65-0.54-1.79-1.55c-0.15-1,0.61-1.89,1.63-1.97
	C319.62,413.59,320.5,414.35,320.54,415.35z"
              />
              <path
                d="M405,473.04c-0.01,0.99-0.88,1.79-1.89,1.74c-0.95-0.04-1.66-0.73-1.71-1.66c-0.05-1.02,0.72-1.83,1.75-1.86
	C404.18,471.23,405.01,472.04,405,473.04z"
              />
              <path
                d="M299.19,337.73c-0.01,1.02-0.83,1.77-1.88,1.73c-0.94-0.04-1.66-0.74-1.71-1.66c-0.05-1.01,0.74-1.84,1.76-1.85
	C298.4,335.93,299.2,336.72,299.19,337.73z"
              />
              <path
                d="M494.45,421.81c0.06,0.96-0.75,1.86-1.7,1.9c-0.83,0.03-1.67-0.71-1.78-1.56c-0.12-0.97,0.61-1.9,1.56-2
	C493.48,420.05,494.39,420.85,494.45,421.81z"
              />
              <path
                d="M273.75,336.21c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.66-1.78-1.58c-0.1-1.01,0.64-1.87,1.66-1.93
	C272.89,334.45,273.72,335.18,273.75,336.21z"
              />
              <path
                d="M470.05,494.3c1.03-0.04,1.86,0.71,1.88,1.72c0.02,1.01-0.78,1.79-1.81,1.78c-0.95-0.01-1.67-0.67-1.74-1.6
	C468.28,495.18,469.02,494.35,470.05,494.3z"
              />
              <path
                d="M505.2,234.85c-1.03,0.02-1.84-0.77-1.83-1.77c0.01-0.91,0.72-1.65,1.64-1.72c1.04-0.08,1.88,0.63,1.93,1.65
	C507,234.01,506.23,234.82,505.2,234.85z"
              />
              <path
                d="M508.94,46.76c-0.17-0.61-0.46-1.21-0.45-1.82c0.01-0.49,0.27-1.17,0.64-1.4c0.37-0.22,1.05-0.06,1.54,0.09
	c0.35,0.11,0.64,0.47,0.89,0.77c0.84,0.99,0.48,2.04-0.81,2.25c-0.61,0.1-1.26,0.02-1.89,0.02
	C508.86,46.68,508.94,46.76,508.94,46.76z"
              />
              <path
                d="M404.55,406.22c0.95-0.05,1.82,0.78,1.81,1.77c-0.02,1.01-0.59,1.61-1.55,1.77c-0.92,0.16-1.87-0.67-1.94-1.63
	C402.79,407.18,403.59,406.27,404.55,406.22z"
              />
              <path
                d="M365.37,599.48c-0.01,0.96-0.88,1.8-1.83,1.76c-0.95-0.04-1.75-0.94-1.65-1.92c0.1-1,0.73-1.55,1.7-1.63
	C364.53,597.61,365.39,598.51,365.37,599.48z"
              />
              <path
                d="M366.84,407.83c-0.04,1.03-0.86,1.75-1.92,1.68c-0.92-0.06-1.65-0.8-1.67-1.7c-0.03-1,0.79-1.82,1.81-1.81
	C366.1,406.01,366.88,406.81,366.84,407.83z"
              />
              <path
                d="M410.82,351.51c0.95-0.03,1.81,0.82,1.78,1.8c-0.03,1.01-0.61,1.6-1.58,1.74c-0.93,0.14-1.85-0.7-1.91-1.66
	C409.05,352.45,409.87,351.55,410.82,351.51z"
              />
              <path
                d="M323.95,355.3c-1.04-0.04-1.79-0.86-1.72-1.88c0.08-1.02,0.93-1.73,1.97-1.63c0.91,0.09,1.63,0.86,1.62,1.76
	C325.83,354.53,324.97,355.33,323.95,355.3z"
              />
              <path
                d="M407.87,422.13c0.01,1-0.81,1.81-1.83,1.77c-1.02-0.04-1.62-0.6-1.73-1.6c-0.11-1.01,0.66-1.84,1.69-1.89
	C407.01,420.35,407.85,421.13,407.87,422.13z"
              />
              <path
                d="M310.98,497.33c-1.05-0.05-1.79-0.87-1.7-1.9c0.08-1.02,0.94-1.72,1.98-1.61c0.91,0.09,1.62,0.87,1.61,1.77
	C312.86,496.57,312,497.37,310.98,497.33z"
              />
              <path
                d="M328.57,531.87c-1.03-0.01-1.83-0.83-1.79-1.83c0.04-0.91,0.77-1.63,1.7-1.68c1.04-0.05,1.87,0.7,1.9,1.71
	C330.4,531.09,329.61,531.88,328.57,531.87z"
              />
              <path
                d="M514.61,239.55c0.01,0.96-0.84,1.82-1.79,1.81c-0.95-0.01-1.78-0.9-1.7-1.87c0.08-1.01,0.68-1.57,1.65-1.68
	C513.71,237.71,514.59,238.59,514.61,239.55z"
              />
              <path
                d="M427.41,125.99c0.95-0.01,1.8,0.85,1.78,1.81c-0.02,0.96-0.9,1.84-1.84,1.74c-0.97-0.1-1.57-0.68-1.65-1.68
	C425.63,126.89,426.46,126,427.41,125.99z"
              />
              <path
                d="M337.06,356.22c-0.02,0.96-0.9,1.79-1.85,1.74c-0.96-0.05-1.72-0.96-1.64-1.93c0.07-0.86,0.88-1.63,1.72-1.63
	C336.24,354.39,337.09,355.25,337.06,356.22z"
              />
              <path
                d="M495.89,230.46c0,0.97-0.86,1.81-1.81,1.78c-0.95-0.03-1.76-0.92-1.67-1.9c0.09-1.01,0.71-1.56,1.68-1.65
	C495.03,228.6,495.89,229.5,495.89,230.46z"
              />
              <path
                d="M504.25,576.47c-0.03,0.98-0.89,1.8-1.84,1.73c-0.95-0.07-1.76-1-1.65-1.94c0.13-1,0.78-1.53,1.74-1.61
	C503.43,574.57,504.28,575.5,504.25,576.47z"
              />
              <path
                d="M359.87,495.2c-0.06,1.01-0.92,1.73-1.95,1.63c-0.92-0.09-1.61-0.84-1.61-1.74c0-1,0.82-1.78,1.85-1.75
	C359.17,493.37,359.93,494.19,359.87,495.2z"
              />
              <path
                d="M410.02,198.17c-0.06,1.02-0.89,1.72-1.94,1.64c-0.92-0.07-1.62-0.81-1.63-1.72c-0.01-1,0.81-1.8,1.83-1.77
	C409.3,196.34,410.07,197.16,410.02,198.17z"
              />
              <path
                d="M371.2,604.18c1.04-0.05,1.87,0.71,1.89,1.73c0.02,1.02-0.78,1.8-1.82,1.79c-0.95-0.01-1.68-0.68-1.76-1.61
	C369.42,605.07,370.17,604.22,371.2,604.18z"
              />
              <path
                d="M514.64,591.7c1.04-0.06,1.88,0.68,1.91,1.7c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.65-1.78-1.58
	C512.88,592.62,513.62,591.76,514.64,591.7z"
              />
              <path
                d="M418.89,551.33c-0.06,1.01-0.91,1.74-1.94,1.65c-0.92-0.08-1.62-0.82-1.63-1.73c-0.01-1,0.81-1.78,1.83-1.77
	C418.18,549.5,418.95,550.32,418.89,551.33z"
              />
              <path
                d="M524.88,607.02c0.06,0.98-0.73,1.87-1.68,1.9c-0.95,0.03-1.83-0.82-1.81-1.78c0.02-1.02,0.62-1.59,1.58-1.75
	C523.91,605.22,524.82,606.04,524.88,607.02z"
              />
              <path
                d="M453.69,494.18c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.75-1.82,1.77-1.82
	C452.92,492.38,453.71,493.17,453.69,494.18z"
              />
              <path
                d="M371.88,591.56c-0.01,1-0.86,1.79-1.87,1.73c-1.02-0.07-1.6-0.64-1.69-1.65c-0.09-1,0.71-1.82,1.74-1.85
	C371.07,589.76,371.89,590.55,371.88,591.56z"
              />
              <path
                d="M411.83,243.01c-1.04,0-1.83-0.8-1.79-1.82c0.04-1.02,0.87-1.76,1.91-1.7c0.92,0.05,1.65,0.79,1.68,1.7
	C413.67,242.2,412.86,243.02,411.83,243.01z"
              />
              <path
                d="M391.79,362.22c-0.02,1.02-0.85,1.76-1.9,1.71c-0.93-0.05-1.66-0.77-1.69-1.68c-0.04-1.01,0.76-1.83,1.79-1.83
	C391.03,360.41,391.82,361.2,391.79,362.22z"
              />
              <path
                d="M457.02,157.99c0.01,0.96-0.84,1.82-1.8,1.8c-0.95-0.02-1.77-0.91-1.69-1.88c0.08-1.01,0.69-1.57,1.66-1.67
	C456.14,156.14,457.02,157.02,457.02,157.99z"
              />
              <path
                d="M395.49,258.9c0.94-0.02,1.8,0.86,1.75,1.83c-0.05,1.01-0.63,1.59-1.6,1.72c-0.94,0.13-1.84-0.72-1.89-1.69
	C393.71,259.81,394.54,258.92,395.49,258.9z"
              />
              <path
                d="M293.93,383.12c-1.03-0.06-1.77-0.91-1.68-1.92c0.09-0.92,0.83-1.59,1.77-1.59c1.04,0,1.83,0.79,1.8,1.81
	C295.8,382.44,294.98,383.18,293.93,383.12z"
              />
              <path
                d="M362.7,610.57c-0.04,0.98-0.93,1.82-1.87,1.68c-0.95-0.15-1.56-0.71-1.61-1.72c-0.05-0.96,0.82-1.83,1.77-1.82
	C361.93,608.71,362.74,609.6,362.7,610.57z"
              />
              <path
                d="M346.94,490.68c0.96-0.07,1.83,0.74,1.83,1.74c0,1.01-0.56,1.62-1.52,1.8c-0.92,0.18-1.88-0.65-1.97-1.6
	C345.2,491.66,345.98,490.74,346.94,490.68z"
              />
              <path
                d="M460.41,446.67c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.75-1.82,1.77-1.82
	C459.64,444.87,460.43,445.67,460.41,446.67z"
              />
              <path
                d="M520.17,363.52c0.96-0.07,1.83,0.75,1.83,1.74c0,1.01-0.56,1.62-1.52,1.8c-0.91,0.18-1.88-0.65-1.97-1.6
	C518.42,364.5,519.21,363.59,520.17,363.52z"
              />
              <path
                d="M359.41,447.99c0.04,1-0.76,1.84-1.79,1.83c-1.02-0.02-1.64-0.55-1.78-1.56c-0.14-1.01,0.62-1.88,1.64-1.95
	C358.49,446.23,359.37,447,359.41,447.99z"
              />
              <path
                d="M409.04,104.32c-0.03,0.96-0.92,1.78-1.87,1.72c-0.95-0.06-1.72-0.98-1.63-1.95c0.08-0.85,0.9-1.62,1.73-1.62
	C408.24,102.48,409.07,103.36,409.04,104.32z"
              />
              <path
                d="M471.35,508.69c1.02,0,1.81,0.81,1.77,1.82c-0.04,0.92-0.74,1.62-1.68,1.67c-1.05,0.05-1.87-0.68-1.89-1.7
	C469.52,509.48,470.32,508.69,471.35,508.69z"
              />
              <path
                d="M429.02,519.72c1.02-0.05,1.88,0.74,1.89,1.74c0.01,0.9-0.7,1.68-1.61,1.77c-1.05,0.11-1.91-0.59-1.99-1.61
	C427.24,520.6,427.98,519.77,429.02,519.72z"
              />
              <path
                d="M374.51,580.46c0.02,1.01-0.77,1.8-1.81,1.78c-1.04-0.02-1.61-0.61-1.74-1.59c-0.13-1.01,0.64-1.85,1.66-1.9
	C373.66,578.71,374.49,579.46,374.51,580.46z"
              />
              <path
                d="M569.31,617.76c-0.04,0.96-0.95,1.77-1.89,1.7c-0.95-0.07-1.71-1-1.61-1.96c0.09-0.84,0.93-1.61,1.75-1.6
	C568.52,615.92,569.35,616.8,569.31,617.76z"
              />
              <path
                d="M237.76,252.82c-0.02,1.02-0.85,1.76-1.9,1.71c-0.93-0.05-1.66-0.77-1.69-1.68c-0.04-1.01,0.76-1.83,1.79-1.83
	C237,251.01,237.79,251.8,237.76,252.82z"
              />
              <path
                d="M436.9,134.19c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.66-1.78-1.58c-0.1-1.01,0.64-1.87,1.66-1.93
	C436.03,132.44,436.86,133.17,436.9,134.19z"
              />
              <path
                d="M406.35,470.93c-1.02,0.02-1.84-0.77-1.83-1.77c0.01-0.91,0.72-1.65,1.64-1.72c1.04-0.08,1.88,0.63,1.93,1.65
	C408.15,470.09,407.38,470.9,406.35,470.93z"
              />
              <path
                d="M381.71,247.52c-0.01,1.01-0.83,1.76-1.87,1.72c-0.94-0.04-1.65-0.73-1.69-1.65c-0.05-1.01,0.72-1.83,1.75-1.84
	C380.92,245.73,381.73,246.52,381.71,247.52z"
              />
              <path
                d="M360.11,249.32c0.06,1.01-0.71,1.83-1.75,1.84c-1.03,0.01-1.63-0.55-1.79-1.54c-0.16-1.01,0.58-1.88,1.6-1.95
	C359.22,247.59,360.06,248.3,360.11,249.32z"
              />
              <path
                d="M394.68,584.02c1.05-0.06,1.88,0.68,1.91,1.7c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.65-1.78-1.58
	C392.91,584.95,393.66,584.08,394.68,584.02z"
              />
              <path
                d="M322.39,64.88c0.02,0.97-0.82,1.84-1.76,1.83c-0.95-0.02-1.8-0.91-1.73-1.87c0.07-1.01,0.7-1.56,1.65-1.69
	C321.51,63.03,322.37,63.89,322.39,64.88z"
              />
              <path
                d="M282.27,203.72c0.94,0,1.79,0.88,1.72,1.86c-0.06,1.01-0.66,1.58-1.63,1.7c-0.94,0.11-1.83-0.75-1.86-1.72
	C280.48,204.6,281.32,203.72,282.27,203.72z"
              />
              <path
                d="M411.24,568.12c-0.97-0.07-1.76-1.03-1.64-1.99c0.11-0.87,0.97-1.6,1.84-1.55c0.98,0.05,1.74,0.99,1.62,1.99
	C412.96,567.46,412.11,568.18,411.24,568.12z"
              />
              <path
                d="M392.96,376.67c-0.06,1.01-0.91,1.75-1.93,1.63c-1.01-0.11-1.56-0.72-1.61-1.72c-0.05-0.99,0.81-1.78,1.82-1.76
	C392.26,374.85,393.02,375.66,392.96,376.67z"
              />
              <path
                d="M240.58,225.24c-1.03,0.02-1.84-0.75-1.83-1.75c0.01-0.99,0.84-1.77,1.85-1.73c1.04,0.04,1.58,0.65,1.68,1.63
	C242.39,224.41,241.6,225.22,240.58,225.24z"
              />
              <path
                d="M394.36,477.69c-0.05,0.9-0.85,1.67-1.72,1.67c-0.97,0-1.82-0.91-1.76-1.88c0.06-0.88,0.86-1.66,1.74-1.67
	C393.58,475.79,394.42,476.69,394.36,477.69z"
              />
              <path
                d="M402.65,95.69c-1.03-0.04-1.78-0.86-1.7-1.87c0.08-1,0.93-1.71,1.96-1.61c0.91,0.09,1.6,0.84,1.59,1.75
	C404.49,94.95,403.66,95.73,402.65,95.69z"
              />
              <path
                d="M403.75,585.1c-0.02,0.97-0.94,1.81-1.89,1.72c-0.89-0.08-1.58-0.87-1.57-1.79c0.02-0.92,0.74-1.68,1.63-1.72
	C402.9,583.26,403.77,584.12,403.75,585.1z"
              />
              <path
                d="M396.06,475.5c-0.97,0.04-1.84-0.83-1.82-1.8c0.02-0.88,0.78-1.67,1.66-1.72c0.87-0.05,1.7,0.68,1.79,1.56
	C397.81,474.54,397.04,475.46,396.06,475.5z"
              />
              <path
                d="M365.62,246.93c0.98,0.06,1.78,1,1.66,1.97c-0.11,0.88-0.95,1.61-1.82,1.57c-0.97-0.04-1.75-0.97-1.65-1.97
	C363.91,247.62,364.76,246.88,365.62,246.93z"
              />
              <path
                d="M419.82,249.49c-0.85,0.04-1.68-0.71-1.77-1.58c-0.1-0.99,0.68-1.9,1.65-1.92c0.86-0.02,1.7,0.71,1.79,1.57
	C421.6,248.5,420.77,249.45,419.82,249.49z"
              />
              <path
                d="M407.11,581.23c0,0.98-0.9,1.83-1.87,1.77c-0.9-0.06-1.61-0.84-1.61-1.77c0-0.92,0.72-1.71,1.61-1.76
	C406.23,579.4,407.12,580.24,407.11,581.23z"
              />
              <path
                d="M401.06,469.98c-0.04,0.9-0.8,1.67-1.68,1.68c-0.96,0.02-1.82-0.87-1.78-1.84c0.04-0.97,0.98-1.78,1.94-1.66
	C400.44,468.26,401.1,469.06,401.06,469.98z"
              />
              <path
                d="M505.37,586.91c0.04-0.9,0.8-1.68,1.68-1.7c0.97-0.02,1.84,0.87,1.79,1.85c-0.04,0.88-0.83,1.68-1.7,1.71
	C506.19,588.8,505.33,587.9,505.37,586.91z"
              />
              <path
                d="M408.26,97.09c-0.01,0.92-0.73,1.69-1.62,1.73c-0.96,0.05-1.85-0.81-1.83-1.78c0.01-0.98,0.91-1.81,1.88-1.73
	C407.58,95.39,408.27,96.17,408.26,97.09z"
              />
              <path
                d="M360.56,628.64c0.05,0.96-0.8,1.86-1.76,1.85c-0.87-0.01-1.63-0.77-1.68-1.66c-0.05-0.92,0.6-1.71,1.5-1.82
	C359.56,626.88,360.51,627.68,360.56,628.64z"
              />
              <path
                d="M396.07,246.22c0.04,0.97-0.81,1.86-1.78,1.84c-0.87-0.02-1.64-0.78-1.68-1.68c-0.04-0.92,0.62-1.72,1.52-1.83
	C395.09,244.44,396.03,245.24,396.07,246.22z"
              />
              <path
                d="M440.7,509.65c0.04,0.98-0.82,1.87-1.79,1.85c-0.88-0.02-1.65-0.79-1.69-1.69c-0.04-0.92,0.64-1.73,1.53-1.84
	C439.72,507.86,440.66,508.67,440.7,509.65z"
              />
              <path
                d="M350.21,466.99c0.02,0.92-0.69,1.72-1.58,1.8c-0.96,0.09-1.88-0.75-1.9-1.73c-0.02-0.98,0.86-1.85,1.83-1.8
	C349.46,465.3,350.19,466.07,350.21,466.99z"
              />
              <path
                d="M286.72,380.34c0.97-0.07,1.87,0.76,1.87,1.74c0,0.88-0.73,1.69-1.61,1.77c-0.97,0.09-1.84-0.73-1.85-1.74
	C285.12,381.18,285.82,380.4,286.72,380.34z"
              />
              <path
                d="M467.56,509.08c-0.96,0.04-1.83-0.82-1.81-1.79c0.02-0.87,0.78-1.66,1.66-1.7c0.88-0.05,1.69,0.66,1.78,1.55
	C469.29,508.13,468.53,509.04,467.56,509.08z"
              />
              <path
                d="M411.45,465.29c0.03,1-0.76,1.8-1.79,1.79c-1.04-0.01-1.61-0.58-1.74-1.57c-0.13-1.01,0.62-1.85,1.64-1.91
	C410.59,463.54,411.42,464.29,411.45,465.29z"
              />
              <path
                d="M510.94,588.35c1.02,0.01,1.8,0.82,1.76,1.82c-0.04,1.01-0.86,1.73-1.91,1.66c-1.05-0.07-1.55-0.72-1.63-1.69
	C509.08,589.14,509.92,588.34,510.94,588.35z"
              />
              <path
                d="M442.13,507.66c-0.97,0.04-1.85-0.83-1.82-1.81c0.02-0.89,0.79-1.68,1.67-1.73c0.86-0.05,1.71,0.7,1.8,1.57
	C443.88,506.69,443.1,507.62,442.13,507.66z"
              />
              <path
                d="M418.63,464.67c-0.03,0.98-0.95,1.81-1.91,1.72c-0.89-0.09-1.59-0.89-1.57-1.81c0.02-0.92,0.76-1.69,1.65-1.73
	C417.78,462.81,418.66,463.68,418.63,464.67z"
              />
              <path
                d="M263.18,254.31c-0.05,1.01-0.88,1.72-1.93,1.64c-0.92-0.07-1.61-0.8-1.62-1.71c-0.01-1.01,0.79-1.79,1.81-1.77
	C262.46,252.49,263.23,253.31,263.18,254.31z"
              />
              <path
                d="M304.15,229.05c-0.01,0.92-0.74,1.7-1.63,1.74c-0.97,0.05-1.85-0.81-1.84-1.79c0.01-0.98,0.92-1.82,1.89-1.74
	C303.46,227.33,304.16,228.13,304.15,229.05z"
              />
              <path
                d="M521.31,358.14c-0.03,0.96-0.95,1.79-1.89,1.7c-0.89-0.09-1.57-0.86-1.55-1.78c0.02-0.92,0.74-1.67,1.63-1.7
	C520.46,356.32,521.33,357.17,521.31,358.14z"
              />
              <path
                d="M412.1,100.41c0.01,0.92-0.68,1.7-1.58,1.78c-0.96,0.08-1.87-0.76-1.88-1.74c-0.01-0.98,0.87-1.83,1.84-1.78
	C411.37,98.72,412.09,99.48,412.1,100.41z"
              />
              <path
                d="M315.45,358.14c-0.03,0.96-0.94,1.79-1.89,1.7c-0.89-0.08-1.57-0.86-1.55-1.78c0.02-0.92,0.74-1.67,1.63-1.7
	C314.61,356.32,315.48,357.17,315.45,358.14z"
              />
              <path
                d="M352.17,219.28c-0.86,0.04-1.69-0.71-1.78-1.59c-0.1-0.99,0.69-1.91,1.66-1.94c0.86-0.03,1.71,0.72,1.8,1.58
	C353.95,218.28,353.12,219.23,352.17,219.28z"
              />
              <path
                d="M420.2,462.55c-0.88-0.02-1.64-0.78-1.68-1.68c-0.04-1,0.79-1.88,1.76-1.85c0.87,0.03,1.66,0.81,1.7,1.69
	C422.03,461.68,421.17,462.57,420.2,462.55z"
              />
              <path
                d="M436.15,459.7c-0.06,1.01-0.92,1.74-1.94,1.62c-1.01-0.12-1.56-0.74-1.6-1.73c-0.04-0.99,0.82-1.77,1.83-1.74
	C435.46,457.87,436.21,458.68,436.15,459.7z"
              />
              <path
                d="M355.01,460.84c-1.02,0.02-1.87-0.76-1.8-1.76c0.07-0.97,0.56-1.63,1.61-1.72c1.03-0.09,1.87,0.64,1.93,1.64
	C356.81,460,356.03,460.82,355.01,460.84z"
              />
              <path
                d="M363.47,245.48c0.06,1-0.72,1.82-1.74,1.83c-1.03,0.01-1.63-0.54-1.77-1.53c-0.15-1.01,0.58-1.87,1.59-1.94
	C362.57,243.76,363.41,244.47,363.47,245.48z"
              />
              <path
                d="M358.29,623.28c-0.97-0.03-1.75-0.93-1.66-1.92c0.08-0.88,0.89-1.62,1.76-1.58c0.87,0.03,1.65,0.81,1.68,1.68
	C360.11,622.43,359.25,623.31,358.29,623.28z"
              />
              <path
                d="M573.13,620.94c0.06,0.96-0.78,1.86-1.74,1.86c-0.86,0-1.64-0.76-1.7-1.65c-0.06-0.92,0.59-1.72,1.48-1.84
	C572.11,619.2,573.07,619.99,573.13,620.94z"
              />
              <path
                d="M429.86,387.93c-0.04,0.9-0.81,1.68-1.69,1.69c-0.97,0.02-1.83-0.88-1.78-1.85c0.05-0.98,0.98-1.79,1.95-1.68
	C429.23,386.2,429.91,387.01,429.86,387.93z"
              />
              <path
                d="M417.87,457.32c0.06,0.99-0.78,1.9-1.74,1.88c-0.86-0.01-1.68-0.8-1.73-1.67c-0.06-0.98,0.79-1.88,1.76-1.88
	C417.02,455.65,417.82,456.43,417.87,457.32z"
              />
              <path
                d="M414.49,560.76c0.96,0.01,1.8,0.92,1.73,1.87c-0.07,0.86-0.89,1.63-1.74,1.63c-0.96,0-1.77-0.89-1.7-1.88
	C412.84,561.5,413.62,560.75,414.49,560.76z"
              />
              <path
                d="M425.53,171.44c0,0.92-0.71,1.7-1.6,1.76c-0.96,0.07-1.86-0.78-1.86-1.76c0-0.98,0.89-1.82,1.86-1.76
	C424.83,169.75,425.53,170.52,425.53,171.44z"
              />
              <path
                d="M314.69,390.69c0.02,0.92-0.67,1.71-1.56,1.79c-0.95,0.09-1.87-0.74-1.89-1.72c-0.02-0.98,0.85-1.84,1.82-1.79
	C313.95,389.01,314.67,389.77,314.69,390.69z"
              />
              <path
                d="M361.44,619.46c-0.97-0.01-1.81-0.92-1.74-1.89c0.06-0.87,0.88-1.64,1.74-1.65c0.96-0.01,1.78,0.89,1.72,1.89
	C363.1,618.7,362.3,619.46,361.44,619.46z"
              />
              <path
                d="M414.79,255.52c-0.06,0.96-1.03,1.77-1.96,1.64c-0.89-0.12-1.54-0.94-1.49-1.85c0.05-0.89,0.84-1.66,1.7-1.66
	C414,253.65,414.85,254.55,414.79,255.52z"
              />
              <path
                d="M549.29,614c0.97-0.01,1.83,0.89,1.77,1.87c-0.05,0.88-0.85,1.67-1.72,1.69c-0.96,0.02-1.8-0.88-1.76-1.87
	C547.64,614.79,548.42,614.01,549.29,614z"
              />
              <path
                d="M468.71,500.22c-0.04,0.9-0.8,1.66-1.67,1.67c-0.96,0.02-1.81-0.87-1.77-1.84c0.05-0.96,0.98-1.76,1.93-1.65
	C468.1,498.51,468.75,499.29,468.71,500.22z"
              />
              <path
                d="M558.04,615.03c0.01,1-0.8,1.8-1.82,1.76c-1.01-0.05-1.61-0.6-1.72-1.6c-0.11-1,0.67-1.84,1.69-1.88
	C557.2,613.26,558.03,614.03,558.04,615.03z"
              />
              <path
                d="M690.93,614.79c0.03,0.85-0.34,1.34-1.23,1.33c-0.96-0.02-1.77-0.9-1.71-1.86c0.05-0.84,0.91-1.7,1.67-1.67
	C690.43,612.62,690.95,613.53,690.93,614.79z"
              />
              <path
                d="M357.55,612.58c0.86-0.03,1.68,0.7,1.76,1.58c0.09,0.99-0.69,1.89-1.66,1.92c-0.85,0.02-1.69-0.73-1.78-1.58
	C355.77,613.55,356.58,612.62,357.55,612.58z"
              />
              <path
                d="M442.8,451.78c-0.05,0.89-0.84,1.65-1.7,1.65c-0.96,0-1.8-0.9-1.74-1.86c0.06-0.96,1.01-1.75,1.96-1.62
	C442.21,450.06,442.85,450.85,442.8,451.78z"
              />
              <path
                d="M415.62,448.48c0.86-0.03,1.67,0.71,1.75,1.59c0.09,0.99-0.7,1.89-1.66,1.91c-0.84,0.02-1.69-0.74-1.77-1.59
	C413.83,449.44,414.66,448.51,415.62,448.48z"
              />
              <path
                d="M368.18,608.03c1.02,0,1.83,0.81,1.75,1.81c-0.09,0.98-0.61,1.61-1.65,1.66c-1.04,0.06-1.86-0.68-1.88-1.69
	C366.36,608.82,367.16,608.03,368.18,608.03z"
              />
              <path
                d="M422.31,400.98c0.87-0.04,1.68,0.68,1.77,1.57c0.1,0.99-0.68,1.9-1.64,1.93c-0.85,0.03-1.69-0.71-1.79-1.57
	C420.54,401.95,421.35,401.02,422.31,400.98z"
              />
              <path
                d="M290.09,213.72c-1.03-0.05-1.77-0.88-1.64-1.9c0.13-0.99,0.7-1.57,1.74-1.58c1.02-0.01,1.81,0.8,1.79,1.79
	C291.95,213.04,291.13,213.78,290.09,213.72z"
              />
              <path
                d="M448.81,499.71c-1.03,0.01-1.83-0.77-1.81-1.77c0.02-0.99,0.85-1.76,1.87-1.71c1.04,0.05,1.57,0.67,1.67,1.65
	C450.63,498.89,449.83,499.7,448.81,499.71z"
              />
              <path
                d="M465.03,436.95c0.87-0.03,1.69,0.7,1.77,1.59c0.09,0.99-0.7,1.91-1.67,1.93c-0.86,0.02-1.7-0.73-1.79-1.59
	C463.25,437.92,464.06,436.99,465.03,436.95z"
              />
              <path
                d="M299.13,436.94c0.9,0.03,1.63,0.8,1.66,1.72c0.03,1-0.84,1.87-1.8,1.82c-0.87-0.04-1.65-0.85-1.68-1.73
	C297.28,437.78,298.15,436.91,299.13,436.94z"
              />
              <path
                d="M467.09,232.75c0.06,0.97-0.79,1.88-1.75,1.87c-0.87,0-1.65-0.76-1.71-1.66c-0.06-0.92,0.6-1.73,1.49-1.85
	C466.07,230.99,467.03,231.79,467.09,232.75z"
              />
              <path
                d="M460.86,493.34c0.04,0.98-0.82,1.87-1.79,1.85c-0.88-0.02-1.65-0.79-1.68-1.7c-0.04-0.92,0.64-1.73,1.54-1.83
	C459.88,491.55,460.81,492.36,460.86,493.34z"
              />
              <path
                d="M464.68,496.7c0.04,0.96-0.82,1.85-1.78,1.83c-0.88-0.02-1.62-0.77-1.66-1.68c-0.04-0.92,0.62-1.71,1.51-1.81
	C463.71,494.93,464.64,495.73,464.68,496.7z"
              />
              <path
                d="M375.1,610.83c-0.88-0.02-1.65-0.79-1.69-1.69c-0.04-1,0.81-1.89,1.77-1.86c0.88,0.03,1.67,0.81,1.71,1.7
	C376.93,609.95,376.07,610.85,375.1,610.83z"
              />
              <path
                d="M518.64,242.9c0.02,0.91-0.68,1.72-1.57,1.8c-0.96,0.09-1.88-0.75-1.9-1.73c-0.02-0.98,0.85-1.85,1.83-1.8
	C517.88,241.21,518.62,241.98,518.64,242.9z"
              />
              <path
                d="M486.78,209.48c0.07,1-0.69,1.83-1.72,1.85c-1.02,0.01-1.63-0.52-1.79-1.52c-0.16-1.01,0.56-1.88,1.57-1.96
	C485.87,207.76,486.72,208.47,486.78,209.48z"
              />
              <path
                d="M278.35,263.4c-0.04-1.01,0.74-1.82,1.76-1.82c1.02,0,1.85,0.8,1.78,1.79c-0.06,1-0.63,1.59-1.64,1.69
	C279.23,265.17,278.39,264.4,278.35,263.4z"
              />
              <path
                d="M484.09,552.89c0.01,0.92-0.7,1.71-1.59,1.79c-0.96,0.08-1.87-0.76-1.89-1.74c-0.01-0.98,0.87-1.84,1.85-1.79
	C483.35,551.19,484.08,551.97,484.09,552.89z"
              />
              <path
                d="M358.64,607.05c0.05,0.96-0.8,1.86-1.76,1.85c-0.87-0.01-1.63-0.76-1.68-1.66c-0.05-0.92,0.6-1.71,1.5-1.82
	C357.65,605.29,358.59,606.08,358.64,607.05z"
              />
              <path
                d="M314.49,30.99c0.89,0.03,1.64,0.81,1.66,1.72c0.03,0.99-0.84,1.88-1.79,1.83c-0.86-0.04-1.65-0.85-1.68-1.73
	C312.65,31.84,313.52,30.96,314.49,30.99z"
              />
              <path
                d="M364.02,608.15c-1.03-0.03-1.8-0.85-1.68-1.87c0.11-0.99,0.66-1.58,1.7-1.61c1.01-0.03,1.83,0.76,1.83,1.75
	C365.87,607.42,365.05,608.18,364.02,608.15z"
              />
              <path
                d="M417.62,433.75c-0.96,0.02-1.81-0.87-1.77-1.83c0.04-0.87,0.83-1.65,1.7-1.67c0.97-0.03,1.79,0.84,1.74,1.84
	C419.24,432.98,418.49,433.73,417.62,433.75z"
              />
              <path
                d="M480.24,549.48c0.04,1-0.8,1.88-1.76,1.85c-0.86-0.03-1.66-0.83-1.7-1.7c-0.05-0.97,0.81-1.86,1.78-1.84
	C479.43,547.81,480.2,548.58,480.24,549.48z"
              />
              <path
                d="M328.73,322.52c1.01-0.05,1.85,0.72,1.87,1.72c0.02,0.91-0.66,1.67-1.57,1.76c-1.03,0.11-1.89-0.59-1.98-1.58
	C326.97,323.41,327.71,322.57,328.73,322.52z"
              />
              <path
                d="M334.2,533.43c0.03,1-0.76,1.79-1.79,1.78c-1.04-0.01-1.61-0.59-1.74-1.58c-0.13-1.01,0.62-1.85,1.64-1.9
	C333.34,531.68,334.17,532.43,334.2,533.43z"
              />
              <path
                d="M379.72,325.15c0.88,0.02,1.64,0.79,1.67,1.69c0.04,1-0.8,1.88-1.76,1.84c-0.86-0.03-1.65-0.82-1.69-1.7
	C377.89,326.01,378.75,325.13,379.72,325.15z"
              />
              <path
                d="M365.38,330.12c-0.97,0.06-1.86-0.8-1.85-1.78c0.01-0.89,0.75-1.69,1.64-1.75c0.88-0.06,1.72,0.65,1.82,1.54
	C367.12,329.13,366.37,330.06,365.38,330.12z"
              />
              <path
                d="M407.11,415.09c0.06,0.98-0.79,1.89-1.76,1.88c-0.87,0-1.66-0.77-1.72-1.67c-0.05-0.91,0.61-1.74,1.5-1.86
	C406.09,413.32,407.05,414.12,407.11,415.09z"
              />
              <path
                d="M230.67,237.03c-0.96-0.02-1.76-0.94-1.68-1.93c0.07-0.88,0.9-1.64,1.76-1.61c0.87,0.03,1.66,0.81,1.7,1.69
	C232.5,236.16,231.64,237.05,230.67,237.03z"
              />
              <path
                d="M420.91,429.92c-0.96-0.02-1.8-0.96-1.71-1.91c0.07-0.86,0.91-1.63,1.77-1.62c0.96,0.01,1.77,0.92,1.69,1.91
	C422.58,429.18,421.76,429.94,420.91,429.92z"
              />
              <path
                d="M404.42,426.2c0.02,0.92-0.67,1.71-1.57,1.79c-0.96,0.09-1.87-0.75-1.89-1.72c-0.02-0.98,0.85-1.83,1.83-1.79
	C403.68,424.52,404.4,425.28,404.42,426.2z"
              />
              <path
                d="M303.03,234.47c0.97-0.07,1.87,0.76,1.87,1.74c0,0.88-0.73,1.69-1.61,1.77c-0.97,0.09-1.84-0.73-1.85-1.74
	C301.44,235.31,302.14,234.53,303.03,234.47z"
              />
              <path
                d="M360.3,601.52c0.97,0.03,1.8,0.97,1.71,1.93c-0.08,0.87-0.92,1.63-1.78,1.62c-0.97-0.02-1.78-0.94-1.69-1.93
	C358.61,602.25,359.43,601.49,360.3,601.52z"
              />
              <path
                d="M276.79,205.92c0.04,0.99-0.81,1.89-1.77,1.86c-0.87-0.03-1.66-0.83-1.7-1.7c-0.05-0.98,0.82-1.87,1.79-1.85
	C275.98,204.24,276.76,205.02,276.79,205.92z"
              />
              <path
                d="M409.93,423.77c1.02,0,1.83,0.81,1.75,1.81c-0.09,0.98-0.61,1.61-1.65,1.66c-1.04,0.06-1.86-0.68-1.88-1.69
	C408.11,424.56,408.91,423.77,409.93,423.77z"
              />
              <path
                d="M519.24,602c0.85-0.04,1.7,0.72,1.79,1.59c0.1,0.99-0.69,1.92-1.67,1.95c-0.87,0.03-1.71-0.71-1.81-1.59
	C517.46,602.99,518.27,602.05,519.24,602z"
              />
              <path
                d="M497.5,418.04c0.02,0.92-0.65,1.7-1.55,1.78c-0.95,0.09-1.87-0.74-1.89-1.7c-0.03-0.97,0.84-1.82,1.81-1.79
	C496.76,416.37,497.48,417.12,497.5,418.04z"
              />
              <path
                d="M353.55,423.36c0,0.92-0.7,1.69-1.59,1.75c-0.95,0.07-1.85-0.78-1.85-1.74c0-0.97,0.88-1.8,1.85-1.74
	C352.85,421.67,353.55,422.43,353.55,423.36z"
              />
              <path
                d="M420.2,422.73c-0.88-0.02-1.65-0.79-1.69-1.7c-0.04-1,0.81-1.89,1.77-1.85c0.88,0.03,1.66,0.82,1.7,1.7
	C422.03,421.86,421.17,422.75,420.2,422.73z"
              />
              <path
                d="M423.12,202.29c0.97,0.01,1.82,0.93,1.74,1.9c-0.06,0.87-0.89,1.65-1.75,1.65c-0.96,0-1.79-0.91-1.73-1.9
	C421.45,203.06,422.26,202.29,423.12,202.29z"
              />
              <path
                d="M345.87,543.24c0.04,1-0.79,1.87-1.75,1.83c-0.85-0.03-1.65-0.83-1.69-1.68c-0.05-0.96,0.81-1.84,1.78-1.82
	C345.08,541.59,345.83,542.34,345.87,543.24z"
              />
              <path
                d="M302.84,337.3c-0.04-1.01,0.73-1.81,1.74-1.81c1.01,0,1.84,0.8,1.78,1.78c-0.06,1-0.63,1.59-1.63,1.69
	C303.71,339.06,302.88,338.3,302.84,337.3z"
              />
              <path
                d="M519.41,376.6c-0.02,0.99-0.84,1.75-1.86,1.7c-1.04-0.05-1.57-0.66-1.66-1.64c-0.09-1.01,0.7-1.81,1.72-1.82
	C518.62,374.82,519.43,375.62,519.41,376.6z"
              />
              <path
                d="M692.71,628.68c0.05,0.94-0.62,1.72-1.52,1.79c-0.96,0.07-1.85-0.78-1.83-1.76c0.02-0.88,0.79-1.67,1.67-1.68
	C691.9,627.01,692.66,627.76,692.71,628.68z"
              />
              <path
                d="M416.26,4.92c1.78-0.03,2.34,0.38,2.32,1.31c-0.02,0.92-0.69,1.55-1.7,1.56c-1.01,0.02-2.07-0.86-1.79-1.7
	C415.29,5.52,416.04,5.13,416.26,4.92z"
              />
              <path
                d="M408.7,575.65c0.96-0.01,1.81,0.88,1.75,1.83c-0.05,0.85-0.86,1.63-1.7,1.65c-0.96,0.02-1.77-0.85-1.72-1.84
	C407.08,576.4,407.83,575.66,408.7,575.65z"
              />
              <path
                d="M259.24,333.56c0.03-0.91,0.77-1.65,1.65-1.66c0.96-0.02,1.82,0.85,1.77,1.81c-0.04,0.85-0.84,1.65-1.68,1.68
	C260.03,335.41,259.2,334.55,259.24,333.56z"
              />
              <path
                d="M275.31,330.69c1.02,0.01,1.83,0.85,1.77,1.84c-0.06,0.91-0.81,1.6-1.75,1.61c-1.04,0.01-1.81-0.79-1.74-1.84
	C273.64,331.37,274.37,330.69,275.31,330.69z"
              />
              <path
                d="M417.13,423.3c0.94,0,1.67,0.7,1.72,1.63c0.05,1.05-0.71,1.84-1.76,1.82c-0.94-0.02-1.69-0.71-1.74-1.63
	C415.29,424.13,416.11,423.3,417.13,423.3z"
              />
              <path
                d="M354.55,247.78c-0.96,0.03-1.7-0.61-1.78-1.55c-0.1-1.04,0.64-1.88,1.67-1.9c1.02-0.02,1.85,0.8,1.82,1.79
	C356.23,247.04,355.5,247.75,354.55,247.78z"
              />
              <path
                d="M422.21,547.28c0.06,0.95-0.58,1.72-1.5,1.83c-1.03,0.12-1.93-0.6-1.99-1.6c-0.07-1,0.74-1.84,1.76-1.85
	C421.42,545.65,422.15,546.34,422.21,547.28z"
              />
              <path
                d="M423.58,415.38c0.96-0.01,1.81,0.88,1.75,1.83c-0.05,0.85-0.86,1.63-1.7,1.65c-0.96,0.02-1.77-0.85-1.72-1.84
	C421.95,416.13,422.7,415.39,423.58,415.38z"
              />
              <path
                d="M256.9,182.46c0.01,0.96-0.87,1.81-1.82,1.76c-0.89-0.05-1.59-0.8-1.6-1.72c-0.01-0.92,0.67-1.68,1.56-1.75
	C256,180.68,256.9,181.5,256.9,182.46z"
              />
              <path
                d="M332.01,464.94c-0.04,1.01-0.88,1.75-1.9,1.65c-1-0.1-1.56-0.7-1.62-1.7c-0.06-0.98,0.78-1.78,1.79-1.77
	C331.3,463.13,332.06,463.93,332.01,464.94z"
              />
              <path
                d="M433.01,356.85c0.89,0.03,1.6,0.77,1.62,1.69c0.02,1-0.8,1.84-1.77,1.79c-0.86-0.05-1.63-0.84-1.65-1.7
	C431.18,357.66,432.04,356.81,433.01,356.85z"
              />
              <path
                d="M349.52,230.28c-0.95,0.04-1.82-0.82-1.8-1.78c0.02-0.87,0.77-1.64,1.65-1.69c0.87-0.05,1.68,0.65,1.77,1.54
	C351.24,229.34,350.48,230.24,349.52,230.28z"
              />
              <path
                d="M405.74,463.71c-1.02-0.05-1.76-0.87-1.64-1.89c0.12-0.99,0.69-1.56,1.72-1.58c1.01-0.02,1.81,0.79,1.79,1.78
	C407.59,463.02,406.77,463.76,405.74,463.71z"
              />
              <path
                d="M406.55,427.62c0.94-0.01,1.68,0.67,1.74,1.61c0.07,1.05-0.69,1.85-1.73,1.85c-0.95-0.01-1.7-0.69-1.76-1.6
	C404.72,428.47,405.52,427.63,406.55,427.62z"
              />
              <path
                d="M499.09,412.51c0.87-0.04,1.67,0.67,1.76,1.56c0.09,0.99-0.67,1.89-1.63,1.92c-0.85,0.03-1.69-0.71-1.78-1.56
	C497.32,413.48,498.13,412.55,499.09,412.51z"
              />
              <path
                d="M413.03,463.22c-0.96,0-1.68-0.67-1.73-1.61c-0.06-1.04,0.71-1.86,1.74-1.84c0.93,0.01,1.69,0.71,1.75,1.62
	C414.86,462.38,414.06,463.22,413.03,463.22z"
              />
              <path
                d="M243.99,383.95c1.01,0.05,1.75,0.89,1.63,1.9c-0.12,0.99-0.7,1.56-1.73,1.57c-1.02,0.01-1.81-0.79-1.78-1.78
	C242.14,384.65,242.98,383.9,243.99,383.95z"
              />
              <path
                d="M308.24,461.31c-1.02,0.03-1.84-0.75-1.83-1.73c0-0.98,0.83-1.77,1.84-1.73c1.03,0.04,1.58,0.63,1.68,1.62
	C310.03,460.49,309.27,461.29,308.24,461.31z"
              />
              <path
                d="M409.2,456.41c1.02,0.01,1.82,0.86,1.75,1.86c-0.07,0.91-0.82,1.59-1.76,1.6c-1.05,0.01-1.8-0.8-1.73-1.85
	C407.53,457.07,408.26,456.4,409.2,456.41z"
              />
              <path
                d="M368.49,595.36c0.01,1-0.79,1.79-1.81,1.75c-1.01-0.05-1.6-0.61-1.71-1.6c-0.11-0.99,0.67-1.83,1.68-1.87
	C367.66,593.59,368.48,594.36,368.49,595.36z"
              />
              <path
                d="M423.67,355.87c0.04,1.02-0.73,1.8-1.75,1.8c-1.02,0-1.84-0.8-1.77-1.78c0.07-0.99,0.64-1.59,1.64-1.69
	C422.79,354.1,423.63,354.87,423.67,355.87z"
              />
              <path
                d="M286.24,206.89c1.01-0.06,1.85,0.68,1.89,1.67c0.04,0.98-0.75,1.79-1.77,1.79c-1.03,0-1.61-0.57-1.74-1.55
	C284.49,207.79,285.22,206.95,286.24,206.89z"
              />
              <path
                d="M416.25,103.54c0,1-0.85,1.78-1.89,1.72c-0.95-0.05-1.63-0.78-1.63-1.72c0-0.94,0.69-1.67,1.63-1.73
	C415.38,101.75,416.24,102.54,416.25,103.54z"
              />
              <path
                d="M437.75,457.47c-1.03,0-1.84-0.83-1.77-1.83c0.06-0.91,0.81-1.61,1.75-1.62c1.03-0.01,1.81,0.8,1.75,1.84
	C439.43,456.79,438.71,457.47,437.75,457.47z"
              />
              <path
                d="M297.96,197.1c0.01,1-0.8,1.79-1.82,1.74c-1.01-0.05-1.6-0.61-1.71-1.6c-0.11-0.99,0.68-1.82,1.69-1.87
	C297.13,195.33,297.95,196.1,297.96,197.1z"
              />
              <path
                d="M360.1,455.2c0.04,0.98-0.76,1.79-1.77,1.79c-1.03-0.01-1.61-0.57-1.74-1.56c-0.13-1.01,0.6-1.85,1.62-1.91
	C359.23,453.47,360.07,454.21,360.1,455.2z"
              />
              <path
                d="M263.31,89.32c-0.37,0.12-0.73,0.25-1.11,0.35c-0.93,0.24-1.77,0.16-2.15-0.88c-0.27-0.75,0.7-2.28,1.54-2.4
	c0.83-0.11,1.4,0.34,1.56,1.13c0.12,0.61,0.06,1.25,0.08,1.88C263.23,89.4,263.31,89.32,263.31,89.32z"
              />
              <path
                d="M421.19,453.55c0.02,0.92-0.66,1.69-1.54,1.77c-0.94,0.08-1.85-0.74-1.88-1.7c-0.02-0.96,0.84-1.81,1.81-1.77
	C420.47,451.9,421.18,452.64,421.19,453.55z"
              />
              <path
                d="M403.12,435.18c-0.94-0.04-1.77-0.99-1.66-1.93c0.09-0.84,0.94-1.58,1.79-1.55c0.97,0.03,1.73,0.92,1.63,1.91
	C404.79,434.48,403.97,435.22,403.12,435.18z"
              />
              <path
                d="M369.42,396.61c0.06,0.95-0.58,1.72-1.5,1.83c-1.03,0.12-1.93-0.6-1.99-1.6c-0.06-1,0.74-1.84,1.76-1.84
	C368.63,394.99,369.37,395.67,369.42,396.61z"
              />
              <path
                d="M343.11,490.84c-0.95-0.06-1.75-1.02-1.63-1.95c0.11-0.84,0.96-1.56,1.81-1.52c0.98,0.05,1.71,0.94,1.6,1.94
	C344.79,490.19,343.97,490.89,343.11,490.84z"
              />
              <path
                d="M502.44,408.42c0.95-0.05,1.7,0.59,1.8,1.53c0.11,1.05-0.6,1.88-1.64,1.92c-0.95,0.04-1.72-0.61-1.83-1.52
	C500.65,409.36,501.42,408.47,502.44,408.42z"
              />
              <path
                d="M424.55,409.92c0,0.92-0.69,1.68-1.57,1.74c-0.95,0.07-1.84-0.77-1.85-1.73c-0.01-0.96,0.87-1.79,1.84-1.73
	C423.86,408.25,424.55,409,424.55,409.92z"
              />
              <path
                d="M279.94,241.88c0.06,0.95-0.79,1.85-1.74,1.85c-0.86,0-1.63-0.75-1.68-1.64c-0.05-0.92,0.58-1.71,1.48-1.82
	C278.93,240.14,279.88,240.93,279.94,241.88z"
              />
              <path
                d="M268.45,398.25c-0.05,1-0.87,1.72-1.91,1.64c-1.02-0.07-1.73-0.91-1.59-1.93c0.14-0.99,0.73-1.54,1.76-1.54
	C267.71,396.43,268.5,397.26,268.45,398.25z"
              />
              <path
                d="M420.09,106.85c0.03,0.98-0.76,1.79-1.78,1.78c-1.03-0.01-1.6-0.57-1.73-1.56c-0.13-1.01,0.61-1.85,1.63-1.9
	C419.22,105.11,420.06,105.86,420.09,106.85z"
              />
              <path
                d="M411.67,259.52c-0.02,1.01-0.84,1.77-1.86,1.7c-1.01-0.08-1.58-0.65-1.67-1.65c-0.08-0.98,0.73-1.8,1.74-1.82
	C410.91,257.73,411.69,258.51,411.67,259.52z"
              />
              <path
                d="M468.55,436.61c-0.95,0.06-1.84-0.78-1.83-1.75c0-0.87,0.74-1.65,1.62-1.72c0.89-0.07,1.69,0.6,1.8,1.5
	C470.25,435.65,469.52,436.55,468.55,436.61z"
              />
              <path
                d="M290.57,257.28c1.02-0.06,1.88,0.74,1.88,1.74c0,0.9-0.73,1.66-1.66,1.72c-1.04,0.07-1.87-0.71-1.86-1.74
	C288.93,258.06,289.62,257.33,290.57,257.28z"
              />
              <path
                d="M413.09,194.34c-0.05,0.95-0.77,1.62-1.73,1.62c-1.03,0-1.84-0.84-1.77-1.83c0.07-1,0.97-1.73,1.99-1.62
	C412.5,192.61,413.14,193.39,413.09,194.34z"
              />
              <path
                d="M423.41,435.39c-0.02,0.96-0.94,1.78-1.88,1.7c-0.89-0.08-1.56-0.85-1.55-1.77c0.02-0.92,0.72-1.66,1.61-1.7
	C422.57,433.58,423.43,434.43,423.41,435.39z"
              />
              <path
                d="M267.94,351.42c-0.03,0.96-0.94,1.78-1.88,1.69c-0.89-0.08-1.56-0.85-1.54-1.77c0.02-0.92,0.73-1.66,1.62-1.69
	C267.11,349.61,267.97,350.46,267.94,351.42z"
              />
              <path
                d="M377.37,489.93c-0.07,1-0.97,1.72-2,1.59c-0.92-0.11-1.56-0.89-1.49-1.84c0.06-0.94,0.79-1.62,1.74-1.61
	C376.64,488.09,377.44,488.93,377.37,489.93z"
              />
              <path
                d="M411.2,109.37c-0.84,0.04-1.67-0.69-1.77-1.56c-0.1-1,0.65-1.89,1.62-1.92c0.85-0.03,1.7,0.7,1.8,1.54
	C412.96,108.37,412.14,109.32,411.2,109.37z"
              />
              <path
                d="M473.81,517.87c-0.13,0.99-1.02,1.64-2.04,1.49c-0.99-0.14-1.64-1.05-1.43-2.06c0.21-0.99,0.87-1.46,1.88-1.39
	C473.22,515.98,473.94,516.89,473.81,517.87z"
              />
              <path
                d="M398.87,258.34c-1.01-0.01-1.8-0.81-1.77-1.79c0.04-0.99,0.88-1.74,1.89-1.67c1.04,0.07,1.55,0.7,1.63,1.68
	C400.7,257.56,399.9,258.35,398.87,258.34z"
              />
              <path
                d="M374.01,573.19c0.07,0.99-0.7,1.82-1.71,1.84c-1.02,0.02-1.63-0.51-1.78-1.51c-0.16-1.01,0.55-1.87,1.56-1.96
	C373.1,571.48,373.95,572.2,374.01,573.19z"
              />
              <path
                d="M418.33,437.45c0.96,0.01,1.8,0.92,1.72,1.86c-0.07,0.85-0.89,1.62-1.73,1.62c-0.95,0-1.75-0.88-1.69-1.87
	C416.69,438.18,417.46,437.44,418.33,437.45z"
              />
              <path
                d="M465.53,191.3c0.86-0.03,1.66,0.7,1.73,1.59c0.08,0.99-0.7,1.88-1.66,1.9c-0.84,0.01-1.68-0.74-1.76-1.59
	C463.74,192.25,464.57,191.32,465.53,191.3z"
              />
              <path
                d="M527.09,612.23c-0.95,0.06-1.84-0.78-1.83-1.75c0-0.87,0.74-1.65,1.62-1.72c0.89-0.07,1.69,0.6,1.79,1.5
	C528.79,611.27,528.06,612.17,527.09,612.23z"
              />
              <path
                d="M463.75,442.69c0.06,0.98-0.72,1.81-1.73,1.82c-1.02,0.01-1.62-0.53-1.76-1.52c-0.15-1.01,0.57-1.86,1.58-1.94
	C462.85,440.98,463.69,441.7,463.75,442.69z"
              />
              <path
                d="M393.36,257.3c0.02,0.92-0.66,1.69-1.55,1.77c-0.94,0.08-1.85-0.74-1.88-1.7c-0.02-0.96,0.84-1.81,1.81-1.76
	C392.64,255.64,393.35,256.38,393.36,257.3z"
              />
              <path
                d="M268.8,401.44c0.04-0.94,0.75-1.63,1.68-1.65c1-0.01,1.84,0.82,1.79,1.8c-0.04,1-0.91,1.73-1.94,1.63
	C269.4,403.14,268.76,402.4,268.8,401.44z"
              />
              <path
                d="M422.07,444.26c-0.9-0.04-1.6-0.82-1.58-1.75c0.02-0.93,0.74-1.67,1.65-1.67c0.97-0.01,1.83,0.91,1.74,1.86
	C423.79,443.56,422.92,444.31,422.07,444.26z"
              />
              <path
                d="M425.42,440.44c-0.91-0.05-1.61-0.83-1.58-1.77c0.02-0.93,0.77-1.68,1.67-1.68c0.98,0,1.83,0.93,1.73,1.88
	C427.15,439.74,426.29,440.49,425.42,440.44z"
              />
              <path
                d="M299.57,444.21c0.91,0.01,1.63,0.75,1.65,1.68c0.01,0.93-0.69,1.7-1.59,1.74c-0.96,0.05-1.85-0.84-1.8-1.8
	C297.87,444.96,298.69,444.2,299.57,444.21z"
              />
              <path
                d="M418.78,444.69c0.87-0.01,1.69,0.76,1.74,1.62c0.05,0.96-0.84,1.85-1.8,1.8c-0.9-0.04-1.6-0.82-1.59-1.74
	C417.14,445.43,417.87,444.69,418.78,444.69z"
              />
              <path
                d="M279.69,254.4c1.01,0.03,1.79,0.9,1.7,1.89c-0.08,0.89-0.86,1.57-1.78,1.55c-1.05-0.01-1.77-0.82-1.67-1.87
	C278.01,255.03,278.74,254.38,279.69,254.4z"
              />
              <path
                d="M445.96,447.8c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.64
	C445.15,446.13,445.94,446.92,445.96,447.8z"
              />
              <path
                d="M367.65,564.73c-0.87-0.02-1.67-0.83-1.7-1.7c-0.02-0.98,0.88-1.82,1.86-1.74c0.92,0.07,1.59,0.85,1.54,1.79
	C369.32,563.98,368.52,564.75,367.65,564.73z"
              />
              <path
                d="M354.2,490.01c0.95-0.03,1.67,0.62,1.76,1.56c0.1,1.05-0.62,1.86-1.67,1.88c-0.93,0.01-1.7-0.66-1.79-1.55
	C352.41,490.91,353.19,490.04,354.2,490.01z"
              />
              <path
                d="M385.54,353.57c-0.85,0.05-1.73-0.69-1.82-1.54c-0.11-0.94,0.74-1.88,1.71-1.88c0.91,0,1.65,0.73,1.67,1.65
	C387.13,352.74,386.45,353.52,385.54,353.57z"
              />
              <path
                d="M368.67,488.83c0.91,0.01,1.63,0.75,1.64,1.68c0.01,0.93-0.69,1.7-1.59,1.74c-0.96,0.04-1.85-0.85-1.8-1.81
	C366.97,489.58,367.79,488.82,368.67,488.83z"
              />
              <path
                d="M365.34,559.72c-0.05,0.86-0.88,1.64-1.74,1.64c-0.97-0.01-1.76-0.92-1.65-1.92c0.1-0.92,0.89-1.57,1.81-1.49
	C364.65,558.02,365.38,558.85,365.34,559.72z"
              />
              <path
                d="M366.53,613.67c-0.03,0.95-0.71,1.63-1.67,1.66c-1.02,0.02-1.85-0.79-1.81-1.77c0.04-0.99,0.92-1.75,1.93-1.66
	C365.91,611.98,366.56,612.72,366.53,613.67z"
              />
              <path
                d="M354.79,437.7c0.02,0.88-0.72,1.7-1.61,1.77c-0.98,0.08-1.83-0.77-1.8-1.79c0.03-0.92,0.79-1.67,1.7-1.65
	C353.96,436.04,354.76,436.83,354.79,437.7z"
              />
              <path
                d="M500.17,573.01c0.03,0.87-0.71,1.69-1.6,1.77c-0.99,0.08-1.83-0.75-1.79-1.77c0.03-0.93,0.77-1.65,1.68-1.65
	C499.34,571.37,500.14,572.14,500.17,573.01z"
              />
              <path
                d="M303.92,434.85c0.01,0.87-0.75,1.69-1.63,1.74c-0.98,0.06-1.81-0.79-1.76-1.81c0.04-0.92,0.81-1.64,1.71-1.62
	C303.12,433.19,303.9,433.98,303.92,434.85z"
              />
              <path
                d="M390.51,410.92c-0.96,0.02-1.67-0.63-1.74-1.58c-0.08-1.04,0.67-1.86,1.7-1.86c0.92,0,1.68,0.69,1.76,1.59
	C392.32,410.05,391.53,410.9,390.51,410.92z"
              />
              <path
                d="M368.88,310.03c0.04,0.93-0.64,1.73-1.54,1.81c-0.97,0.08-1.88-0.78-1.87-1.75c0.01-0.88,0.81-1.67,1.69-1.7
	C368.06,308.37,368.84,309.11,368.88,310.03z"
              />
              <path
                d="M401.56,350.72c-0.03,0.95-0.71,1.63-1.67,1.66c-1.02,0.02-1.85-0.79-1.81-1.77c0.04-0.99,0.92-1.75,1.93-1.66
	C400.93,349.03,401.58,349.77,401.56,350.72z"
              />
              <path
                d="M428.23,532.45c0.04,0.98-0.78,1.8-1.8,1.78c-0.96-0.02-1.64-0.69-1.68-1.64c-0.03-0.95,0.61-1.69,1.54-1.78
	C427.3,530.7,428.18,531.45,428.23,532.45z"
              />
              <path
                d="M414.04,454.27c0.02,0.95-0.64,1.67-1.58,1.74c-1.03,0.08-1.89-0.68-1.9-1.68c-0.02-0.98,0.84-1.79,1.84-1.75
	C413.32,452.62,414.02,453.34,414.04,454.27z"
              />
              <path
                d="M469.41,198.13c-0.86,0-1.69-0.8-1.72-1.67c-0.04-0.98,0.84-1.84,1.83-1.78c0.92,0.06,1.6,0.83,1.57,1.77
	C471.06,197.36,470.29,198.13,469.41,198.13z"
              />
              <path
                d="M311.6,354.72c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.63
	C310.8,353.04,311.59,353.84,311.6,354.72z"
              />
              <path
                d="M526.32,312.32c-0.87,0.02-1.66-0.73-1.71-1.63c-0.05-0.94,0.6-1.71,1.53-1.79c0.98-0.08,1.88,0.75,1.86,1.72
	C527.99,311.48,527.18,312.3,526.32,312.32z"
              />
              <path
                d="M302.48,500.1c0.02,0.87-0.74,1.69-1.62,1.75c-0.98,0.07-1.81-0.78-1.77-1.8c0.04-0.92,0.8-1.65,1.7-1.63
	C301.67,498.44,302.46,499.23,302.48,500.1z"
              />
              <path
                d="M373.04,313.31c-0.05,1-0.92,1.73-1.95,1.62c-0.94-0.09-1.57-0.83-1.52-1.79c0.04-0.94,0.75-1.63,1.69-1.64
	C372.26,311.5,373.09,312.33,373.04,313.31z"
              />
              <path
                d="M356.61,395.83c-0.87-0.02-1.67-0.83-1.7-1.7c-0.02-0.98,0.88-1.82,1.86-1.74c0.92,0.07,1.59,0.85,1.55,1.79
	C358.28,395.07,357.48,395.85,356.61,395.83z"
              />
              <path
                d="M468.69,187.48c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C467.04,188.23,467.79,187.48,468.69,187.48z"
              />
              <path
                d="M461.48,500.53c0.04,0.93-0.63,1.72-1.54,1.79c-0.96,0.08-1.87-0.78-1.85-1.74c0.02-0.87,0.81-1.66,1.69-1.67
	C460.69,498.88,461.44,499.61,461.48,500.53z"
              />
              <path
                d="M304.11,454.75c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C302.46,455.5,303.2,454.75,304.11,454.75z"
              />
              <path
                d="M289.01,195.87c0.94,0,1.65,0.68,1.7,1.63c0.06,1.05-0.7,1.84-1.74,1.82c-0.93-0.02-1.68-0.72-1.73-1.62
	C287.18,196.71,288,195.86,289.01,195.87z"
              />
              <path
                d="M429.12,353.5c0.91,0.01,1.65,0.76,1.66,1.69c0.02,0.94-0.69,1.71-1.59,1.76c-0.97,0.05-1.86-0.84-1.81-1.81
	C427.42,354.26,428.24,353.49,429.12,353.5z"
              />
              <path
                d="M295.79,215.28c0.04,0.99-0.79,1.8-1.81,1.77c-0.95-0.02-1.64-0.7-1.67-1.65c-0.03-0.94,0.62-1.69,1.55-1.77
	C294.87,213.54,295.76,214.29,295.79,215.28z"
              />
              <path
                d="M425.01,456.99c-0.03,0.91-0.78,1.67-1.66,1.68c-0.95,0-1.82-0.95-1.73-1.88c0.09-0.86,0.94-1.58,1.82-1.53
	C424.37,455.3,425.04,456.05,425.01,456.99z"
              />
              <path
                d="M356.23,412.37c-0.03,0.87-0.84,1.67-1.71,1.68c-0.97,0.01-1.79-0.91-1.69-1.91c0.08-0.92,0.89-1.61,1.8-1.54
	C355.52,410.68,356.26,411.49,356.23,412.37z"
              />
              <path
                d="M424.88,433.24c-0.86,0.05-1.73-0.7-1.82-1.56c-0.1-0.95,0.76-1.89,1.74-1.88c0.91,0,1.65,0.75,1.67,1.68
	C426.48,432.41,425.78,433.19,424.88,433.24z"
              />
              <path
                d="M347.34,414.53c-0.87,0.02-1.66-0.73-1.71-1.63c-0.05-0.94,0.6-1.71,1.53-1.79c0.98-0.08,1.88,0.75,1.86,1.72
	C349,413.69,348.2,414.5,347.34,414.53z"
              />
              <path
                d="M299.78,53.78c-0.67,0.18-1.34,0.5-2.02,0.5c-1.2,0.01-1.83-1.28-1.03-2.17c0.48-0.52,1.31-1.04,1.97-1.01
	c0.92,0.04,1.1,0.97,1.03,1.82c-0.03,0.31-0.03,0.62-0.04,0.93C299.69,53.86,299.78,53.78,299.78,53.78z"
              />
              <path
                d="M402.03,460.6c-0.87,0.02-1.67-0.75-1.71-1.65c-0.04-0.94,0.63-1.72,1.55-1.79c0.99-0.08,1.88,0.77,1.86,1.75
	C403.71,459.78,402.9,460.58,402.03,460.6z"
              />
              <path
                d="M470.92,501.53c0.93,0.03,1.63,0.74,1.65,1.68c0.03,1.04-0.76,1.81-1.81,1.76c-0.92-0.04-1.66-0.78-1.68-1.67
	C469.07,502.31,469.92,501.49,470.92,501.53z"
              />
              <path
                d="M297.3,505.67c-0.9-0.05-1.59-0.82-1.57-1.75c0.02-0.92,0.76-1.65,1.66-1.65c0.96,0,1.82,0.93,1.72,1.87
	C299.03,504.98,298.16,505.72,297.3,505.67z"
              />
              <path
                d="M437.34,181.19c0.05,0.98-0.76,1.81-1.78,1.8c-0.96-0.01-1.65-0.67-1.69-1.63c-0.04-0.95,0.59-1.7,1.52-1.8
	C436.4,179.45,437.29,180.19,437.34,181.19z"
              />
              <path
                d="M365.04,346.53c0.03,0.93-0.67,1.72-1.57,1.78c-0.97,0.06-1.87-0.81-1.84-1.78c0.03-0.88,0.84-1.66,1.72-1.66
	C364.26,344.86,365.01,345.6,365.04,346.53z"
              />
              <path
                d="M473.48,430.98c0.03,0.93-0.66,1.71-1.56,1.77c-0.96,0.06-1.86-0.81-1.83-1.77c0.03-0.87,0.83-1.64,1.71-1.65
	C472.71,429.32,473.45,430.05,473.48,430.98z"
              />
              <path
                d="M463.74,505.7c-0.86,0.05-1.73-0.7-1.82-1.56c-0.1-0.95,0.76-1.89,1.74-1.88c0.91,0,1.65,0.75,1.67,1.68
	C465.35,504.87,464.65,505.65,463.74,505.7z"
              />
              <path
                d="M354.3,430.62c-0.04,0.86-0.86,1.65-1.72,1.66c-0.96,0.01-1.77-0.91-1.67-1.9c0.09-0.92,0.88-1.58,1.8-1.51
	C353.6,428.93,354.34,429.75,354.3,430.62z"
              />
              <path
                d="M413.85,427.15c0.93,0.05,1.62,0.77,1.63,1.71c0.01,1.04-0.8,1.79-1.84,1.72c-0.92-0.06-1.65-0.8-1.65-1.7
	C411.98,427.91,412.85,427.1,413.85,427.15z"
              />
              <path
                d="M297.98,216.75c0.9,0.06,1.65,0.87,1.62,1.75c-0.02,0.97-0.98,1.8-1.93,1.68c-0.89-0.12-1.55-0.95-1.47-1.87
	C296.27,217.4,297.08,216.7,297.98,216.75z"
              />
              <path
                d="M328.87,429.06c0.03,0.88-0.71,1.71-1.6,1.78c-0.99,0.08-1.84-0.75-1.8-1.78c0.03-0.93,0.79-1.67,1.69-1.66
	C328.04,427.4,328.85,428.18,328.87,429.06z"
              />
              <path
                d="M336.1,428.31c0.05,0.98-0.77,1.81-1.79,1.79c-0.96-0.01-1.65-0.68-1.68-1.64c-0.04-0.95,0.6-1.7,1.53-1.79
	C335.16,426.57,336.05,427.32,336.1,428.31z"
              />
              <path
                d="M384.72,342.94c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C383.07,343.7,383.81,342.95,384.72,342.94z"
              />
              <path
                d="M324.73,465.62c-0.02,0.92-0.77,1.68-1.65,1.69c-0.96,0.01-1.83-0.93-1.74-1.87c0.08-0.86,0.93-1.59,1.8-1.55
	C324.07,463.93,324.75,464.67,324.73,465.62z"
              />
              <path
                d="M368.18,69.92c0.91,0.01,1.64,0.76,1.66,1.69c0.01,0.94-0.69,1.71-1.6,1.75c-0.97,0.05-1.86-0.84-1.81-1.81
	C366.48,70.67,367.3,69.91,368.18,69.92z"
              />
              <path
                d="M355.59,425.02c0.91-0.05,1.69,0.64,1.76,1.56c0.07,0.92-0.58,1.74-1.47,1.85c-0.94,0.12-1.9-0.72-1.92-1.67
	C353.95,425.87,354.71,425.07,355.59,425.02z"
              />
              <path
                d="M357.16,70.71c-1.02,0.01-1.84-0.81-1.79-1.79c0.04-0.9,0.79-1.62,1.71-1.65c1.03-0.03,1.81,0.76,1.76,1.8
	C358.81,70.02,358.12,70.69,357.16,70.71z"
              />
              <path
                d="M401.07,97.9c-0.06,0.95-0.76,1.61-1.71,1.6c-1.02-0.01-1.82-0.85-1.75-1.83c0.07-0.99,0.97-1.72,1.98-1.6
	C400.5,96.18,401.13,96.95,401.07,97.9z"
              />
              <path
                d="M469.22,237.96c-0.87,0.02-1.67-0.75-1.72-1.64c-0.05-0.94,0.62-1.72,1.54-1.8c0.98-0.08,1.89,0.76,1.87,1.73
	C470.89,237.12,470.09,237.93,469.22,237.96z"
              />
              <path
                d="M425.98,424.36c-0.03,0.92-0.79,1.69-1.67,1.69c-0.96,0-1.83-0.95-1.73-1.9c0.09-0.87,0.95-1.6,1.82-1.55
	C425.32,422.66,426.01,423.42,425.98,424.36z"
              />
              <path
                d="M268.64,255.22c-0.87,0.02-1.66-0.74-1.7-1.64c-0.05-0.94,0.61-1.7,1.54-1.78c0.98-0.08,1.88,0.76,1.85,1.73
	C270.3,254.39,269.49,255.2,268.64,255.22z"
              />
              <path
                d="M343.01,467.82c-0.04,0.95-0.73,1.62-1.69,1.63c-1.02,0.01-1.83-0.82-1.78-1.8c0.05-1,0.94-1.73,1.95-1.63
	C342.42,466.12,343.05,466.87,343.01,467.82z"
              />
              <path
                d="M412.45,246.52c0.87-0.05,1.73,0.67,1.82,1.52c0.1,0.94-0.77,1.89-1.72,1.89c-0.88,0-1.64-0.76-1.67-1.67
	C410.86,247.33,411.53,246.58,412.45,246.52z"
              />
              <path
                d="M295.94,341.7c0.04,0.93-0.64,1.73-1.54,1.81c-0.97,0.08-1.88-0.78-1.87-1.75c0.01-0.88,0.81-1.67,1.69-1.7
	C295.13,340.04,295.9,340.78,295.94,341.7z"
              />
              <path
                d="M491.72,227.13c-0.02,0.92-0.77,1.69-1.65,1.7c-0.96,0.01-1.84-0.92-1.75-1.88c0.08-0.87,0.93-1.61,1.81-1.57
	C491.04,225.43,491.74,226.19,491.72,227.13z"
              />
              <path
                d="M369.63,250.34c0.91,0.01,1.64,0.76,1.65,1.69c0.01,0.94-0.7,1.71-1.6,1.75c-0.97,0.04-1.86-0.85-1.8-1.82
	C367.92,251.09,368.75,250.33,369.63,250.34z"
              />
              <path
                d="M432.96,522.88c0.91,0.01,1.65,0.76,1.66,1.69c0.02,0.94-0.69,1.71-1.59,1.76c-0.97,0.05-1.86-0.84-1.81-1.81
	C431.26,523.64,432.08,522.88,432.96,522.88z"
              />
              <path
                d="M433.47,138.31c0.01,0.87-0.75,1.69-1.63,1.74c-0.98,0.06-1.81-0.79-1.76-1.81c0.04-0.92,0.81-1.64,1.71-1.62
	C432.67,136.65,433.46,137.44,433.47,138.31z"
              />
              <path
                d="M401.9,417.34c0.87-0.05,1.73,0.67,1.82,1.52c0.1,0.94-0.77,1.89-1.72,1.89c-0.88,0-1.64-0.76-1.67-1.67
	C400.3,418.15,400.97,417.4,401.9,417.34z"
              />
              <path
                d="M631.39,375.67c0,0-0.07-0.15-0.07-0.15c-0.97-2.21-0.93-2.42,0.76-3.91c1.19,1.03,1.36,2.38,1.36,4.07
	C632.71,375.67,632.05,375.67,631.39,375.67z"
              />
              <path
                d="M646.05,629.18c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.63
	C645.25,627.51,646.04,628.3,646.05,629.18z"
              />
              <path
                d="M340.32,371.96c0.94-0.01,1.66,0.65,1.73,1.6c0.08,1.05-0.66,1.85-1.71,1.85c-0.93,0-1.69-0.69-1.76-1.59
	C338.51,372.83,339.3,371.98,340.32,371.96z"
              />
              <path
                d="M259.29,377.26c0.02,0.87-0.74,1.69-1.62,1.75c-0.98,0.07-1.81-0.78-1.77-1.8c0.04-0.92,0.8-1.65,1.7-1.63
	C258.48,375.6,259.27,376.39,259.29,377.26z"
              />
              <path
                d="M360.78,422.63c0,0.94-0.67,1.66-1.61,1.71c-1.02,0.06-1.88-0.72-1.88-1.71c0-0.98,0.87-1.78,1.87-1.72
	C360.09,420.97,360.77,421.7,360.78,422.63z"
              />
              <path
                d="M423.45,122.93c0.93,0.05,1.62,0.77,1.63,1.71c0.01,1.04-0.8,1.79-1.84,1.72c-0.92-0.06-1.65-0.8-1.65-1.7
	C421.58,123.69,422.45,122.88,423.45,122.93z"
              />
              <path
                d="M352.06,488.62c-0.01,0.91-0.73,1.67-1.61,1.69c-0.94,0.02-1.82-0.89-1.75-1.82c0.07-0.84,0.9-1.57,1.76-1.55
	C351.39,486.98,352.07,487.69,352.06,488.62z"
              />
              <path
                d="M470.02,537.53c-0.93-0.06-1.57-0.82-1.51-1.77c0.06-0.93,0.84-1.61,1.75-1.54c0.87,0.07,1.62,0.92,1.55,1.78
	C471.75,536.86,470.9,537.58,470.02,537.53z"
              />
              <path
                d="M432.22,456.18c0.02,0.95-0.64,1.68-1.57,1.72c-0.91,0.05-1.72-0.63-1.81-1.52c-0.1-0.96,0.74-1.85,1.72-1.86
	C431.48,454.53,432.19,455.23,432.22,456.18z"
              />
              <path
                d="M487.18,422.73c-0.06,0.87-0.89,1.61-1.78,1.56c-0.94-0.05-1.59-0.79-1.54-1.75c0.05-0.94,0.82-1.63,1.73-1.57
	C486.48,421.02,487.24,421.87,487.18,422.73z"
              />
              <path
                d="M428.69,247.07c-0.04,0.99-0.9,1.73-1.92,1.63c-0.92-0.08-1.56-0.81-1.53-1.76c0.03-0.93,0.72-1.63,1.65-1.65
	C427.88,245.28,428.73,246.11,428.69,247.07z"
              />
              <path
                d="M365.57,622.5c-0.95,0.02-1.67-0.65-1.71-1.6c-0.05-0.97,0.59-1.71,1.53-1.78c0.99-0.08,1.89,0.75,1.88,1.72
	C367.25,621.71,366.47,622.48,365.57,622.5z"
              />
              <path
                d="M414.97,112.45c-0.95,0.01-1.65-0.63-1.72-1.58c-0.07-1.04,0.67-1.85,1.69-1.85c0.9,0,1.68,0.71,1.75,1.59
	C416.78,111.58,415.98,112.43,414.97,112.45z"
              />
              <path
                d="M384.04,608.57c0,0.85-0.78,1.67-1.64,1.72c-0.97,0.05-1.79-0.81-1.74-1.82c0.05-0.92,0.81-1.62,1.72-1.59
	C383.26,606.91,384.04,607.71,384.04,608.57z"
              />
              <path
                d="M360.06,185.81c-0.88,0.02-1.71-0.74-1.74-1.61c-0.03-0.86,0.76-1.69,1.63-1.73c0.91-0.03,1.67,0.68,1.7,1.62
	C361.67,185.06,360.99,185.79,360.06,185.81z"
              />
              <path
                d="M393.86,596.89c-0.04,0.95-0.72,1.62-1.66,1.63c-1,0.02-1.83-0.81-1.78-1.77c0.05-0.97,0.94-1.73,1.93-1.63
	C393.26,595.2,393.89,595.94,393.86,596.89z"
              />
              <path
                d="M426.99,538.02c0.88,0.02,1.67,0.81,1.69,1.67c0.02,0.96-0.89,1.78-1.88,1.7c-0.94-0.08-1.57-0.81-1.52-1.77
	C425.34,538.68,426.07,537.99,426.99,538.02z"
              />
              <path
                d="M475.7,539.33c-0.05,0.88-0.86,1.6-1.76,1.59c-1.04-0.02-1.75-0.85-1.62-1.91c0.12-0.96,0.87-1.56,1.83-1.47
	C475.04,537.64,475.76,538.46,475.7,539.33z"
              />
              <path
                d="M376.18,79.79c-0.95,0.05-1.67-0.59-1.74-1.54c-0.07-0.94,0.55-1.72,1.46-1.82c0.96-0.11,1.9,0.7,1.92,1.66
	C377.82,78.94,377.06,79.74,376.18,79.79z"
              />
              <path
                d="M415.72,116.23c0.93,0.02,1.64,0.75,1.65,1.7c0.01,0.95-0.69,1.68-1.64,1.7c-1.01,0.03-1.87-0.83-1.79-1.81
	C414,116.94,414.82,116.21,415.72,116.23z"
              />
              <path
                d="M435.85,246.42c0.07,0.85-0.65,1.68-1.53,1.77c-0.94,0.1-1.69-0.49-1.81-1.43c-0.13-1.03,0.58-1.88,1.59-1.91
	C434.96,244.83,435.79,245.57,435.85,246.42z"
              />
              <path
                d="M346.32,424.03c0.02,0.95-0.64,1.68-1.57,1.72c-0.91,0.05-1.72-0.63-1.81-1.52c-0.1-0.96,0.74-1.85,1.72-1.86
	C345.59,422.38,346.3,423.08,346.32,424.03z"
              />
              <path
                d="M463.76,173.36c-0.91,0.05-1.73-0.63-1.82-1.52c-0.1-0.97,0.72-1.87,1.72-1.87c0.94,0,1.65,0.7,1.68,1.65
	C465.36,172.58,464.7,173.31,463.76,173.36z"
              />
              <path
                d="M363.84,458.85c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C363.29,457.1,363.98,458,363.84,458.85z"
              />
              <path
                d="M279.02,250.88c-0.84,0.06-1.72-0.68-1.82-1.52c-0.11-0.93,0.74-1.87,1.7-1.87c0.91-0.01,1.64,0.71,1.67,1.63
	C280.6,250.04,279.92,250.82,279.02,250.88z"
              />
              <path
                d="M397.15,94.52c-0.05,0.96-0.79,1.63-1.74,1.57c-0.9-0.05-1.66-0.89-1.6-1.76c0.06-0.87,0.9-1.63,1.78-1.58
	C396.53,92.8,397.2,93.55,397.15,94.52z"
              />
              <path
                d="M252.73,345.43c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.72c0.03-0.86,0.87-1.65,1.75-1.63
	C252.06,343.72,252.75,344.46,252.73,345.43z"
              />
              <path
                d="M405.4,247.24c0.86,0.02,1.67,0.82,1.68,1.67c0.02,0.95-0.89,1.78-1.87,1.7c-0.93-0.08-1.56-0.81-1.51-1.77
	C403.75,247.91,404.49,247.21,405.4,247.24z"
              />
              <path
                d="M431.02,442.06c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C430.47,440.31,431.15,441.21,431.02,442.06z"
              />
              <path
                d="M424.57,117.45c0.01,0.93-0.66,1.65-1.59,1.71c-1,0.06-1.87-0.72-1.87-1.69c0-0.96,0.87-1.77,1.85-1.71
	C423.89,115.8,424.57,116.52,424.57,117.45z"
              />
              <path
                d="M452.63,503.02c-0.92,0-1.71-0.73-1.75-1.62c-0.05-0.98,0.83-1.83,1.83-1.78c0.93,0.05,1.61,0.79,1.6,1.74
	C454.29,502.33,453.58,503.02,452.63,503.02z"
              />
              <path
                d="M441.21,247.51c-0.84,0.06-1.72-0.68-1.82-1.51c-0.11-0.92,0.73-1.85,1.69-1.86c0.91-0.01,1.63,0.69,1.66,1.62
	C442.78,246.67,442.1,247.45,441.21,247.51z"
              />
              <path
                d="M429.14,420.47c0.01,0.85-0.77,1.66-1.63,1.71c-0.97,0.05-1.79-0.79-1.73-1.8c0.05-0.92,0.79-1.61,1.7-1.58
	C428.36,418.82,429.14,419.61,429.14,420.47z"
              />
              <path
                d="M416.69,443.22c0.03,0.88-0.72,1.67-1.62,1.73c-0.97,0.06-1.69-0.56-1.78-1.53c-0.09-1.05,0.67-1.88,1.69-1.86
	C415.87,441.58,416.66,442.35,416.69,443.22z"
              />
              <path
                d="M306.98,344.52c-0.05,0.95-0.78,1.62-1.73,1.59c-0.9-0.03-1.68-0.81-1.68-1.68c0-0.97,0.91-1.79,1.89-1.7
	C306.4,342.81,307.03,343.57,306.98,344.52z"
              />
              <path
                d="M456.52,443.36c-0.05,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.64-1.91c0.11-0.96,0.87-1.57,1.83-1.48
	C455.85,441.66,456.57,442.48,456.52,443.36z"
              />
              <path
                d="M437.55,367.18c0.9,0.03,1.69,0.8,1.7,1.68c0.02,0.98-0.88,1.79-1.89,1.71c-0.95-0.08-1.59-0.82-1.54-1.79
	C435.87,367.84,436.61,367.16,437.55,367.18z"
              />
              <path
                d="M264.74,208.85c0.89-0.01,1.7,0.73,1.75,1.61c0.06,0.97-0.8,1.82-1.81,1.78c-0.94-0.04-1.62-0.77-1.6-1.72
	C263.1,209.57,263.81,208.85,264.74,208.85z"
              />
              <path
                d="M411.8,575.26c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C413.45,574.52,412.66,575.28,411.8,575.26z"
              />
              <path
                d="M397.93,600.08c0.06,0.86-0.7,1.7-1.59,1.75c-0.94,0.06-1.68-0.62-1.73-1.57c-0.05-0.95,0.62-1.71,1.55-1.76
	C397.03,598.46,397.87,599.22,397.93,600.08z"
              />
              <path
                d="M406.81,454.7c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C406.07,453.08,406.77,453.76,406.81,454.7z"
              />
              <path
                d="M357.23,442.54c-0.89,0.05-1.71-0.63-1.81-1.51c-0.1-0.94,0.74-1.86,1.71-1.86c0.92,0,1.63,0.7,1.66,1.64
	C358.82,441.76,358.16,442.49,357.23,442.54z"
              />
              <path
                d="M368.95,575.44c0.89,0.03,1.67,0.82,1.68,1.69c0.01,0.97-0.91,1.78-1.9,1.69c-0.95-0.09-1.57-0.83-1.51-1.8
	C367.28,576.08,368.01,575.41,368.95,575.44z"
              />
              <path
                d="M391.9,342.24c0.89-0.01,1.7,0.73,1.75,1.61c0.06,0.97-0.8,1.82-1.81,1.78c-0.94-0.04-1.62-0.77-1.6-1.72
	C390.25,342.96,390.96,342.25,391.9,342.24z"
              />
              <path
                d="M391.2,607.75c0.06,0.86-0.69,1.69-1.57,1.75c-0.92,0.06-1.68-0.61-1.73-1.55c-0.05-0.94,0.61-1.71,1.53-1.76
	C390.3,606.14,391.14,606.89,391.2,607.75z"
              />
              <path
                d="M390.37,371.03c-0.95-0.06-1.59-0.81-1.52-1.78c0.07-0.95,0.84-1.63,1.76-1.55c0.89,0.07,1.63,0.92,1.57,1.79
	C392.11,370.37,391.27,371.09,390.37,371.03z"
              />
              <path
                d="M415.57,578.31c-0.88-0.05-1.64-0.88-1.59-1.74c0.05-0.87,0.89-1.62,1.76-1.57c0.91,0.05,1.59,0.81,1.54,1.75
	C417.25,577.69,416.5,578.37,415.57,578.31z"
              />
              <path
                d="M455.74,495.78c0.91,0,1.7,0.75,1.74,1.64c0.04,0.98-0.83,1.81-1.84,1.76c-0.95-0.05-1.61-0.78-1.58-1.74
	C454.08,496.48,454.8,495.78,455.74,495.78z"
              />
              <path
                d="M565.87,621.72c0.03,0.84-0.77,1.67-1.63,1.69c-0.91,0.03-1.65-0.69-1.67-1.61c-0.02-0.93,0.68-1.68,1.58-1.7
	C565,620.09,565.84,620.88,565.87,621.72z"
              />
              <path
                d="M405.64,440.74c-0.06,0.84-0.92,1.62-1.76,1.6c-0.97-0.02-1.73-0.94-1.61-1.93c0.11-0.91,0.91-1.54,1.83-1.45
	C404.98,439.05,405.7,439.89,405.64,440.74z"
              />
              <path
                d="M517.03,367.71c0.91,0.06,1.57,0.84,1.51,1.78c-0.06,0.94-0.82,1.6-1.75,1.53c-0.88-0.07-1.62-0.91-1.55-1.77
	C515.31,368.38,516.16,367.65,517.03,367.71z"
              />
              <path
                d="M469.94,181.99c0.01,0.86-0.77,1.67-1.63,1.72c-0.97,0.05-1.8-0.8-1.74-1.81c0.05-0.92,0.81-1.62,1.71-1.59
	C469.14,180.33,469.93,181.12,469.94,181.99z"
              />
              <path
                d="M442.25,365.05c0,0.93-0.72,1.66-1.63,1.65c-0.86-0.01-1.68-0.82-1.68-1.65c0-0.84,0.83-1.65,1.68-1.66
	C441.51,363.39,442.24,364.13,442.25,365.05z"
              />
              <path
                d="M483.85,426.57c-0.06,0.87-0.86,1.6-1.75,1.58c-1.03-0.02-1.74-0.85-1.61-1.9c0.11-0.95,0.87-1.56,1.82-1.46
	C483.19,424.88,483.91,425.71,483.85,426.57z"
              />
              <path
                d="M372.29,76.44c-0.95,0.02-1.67-0.65-1.71-1.6c-0.05-0.97,0.59-1.71,1.53-1.78c0.99-0.08,1.89,0.75,1.88,1.72
	C373.97,75.66,373.19,76.42,372.29,76.44z"
              />
              <path
                d="M423.93,545.24c-0.93,0.05-1.74-0.62-1.83-1.52c-0.1-0.98,0.72-1.87,1.73-1.88c0.94,0,1.66,0.7,1.69,1.65
	C425.55,544.46,424.88,545.19,423.93,545.24z"
              />
              <path
                d="M376.86,316.69c-0.06,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.63-1.92c0.12-0.96,0.87-1.57,1.84-1.47
	C376.2,314.99,376.92,315.81,376.86,316.69z"
              />
              <path
                d="M385.5,476.63c0.93,0.03,1.61,0.78,1.57,1.73c-0.03,0.94-0.77,1.64-1.7,1.6c-0.89-0.04-1.66-0.86-1.62-1.73
	C383.79,477.37,384.63,476.6,385.5,476.63z"
              />
              <path
                d="M364.54,592.27c-0.01,0.91-0.73,1.66-1.61,1.68c-0.94,0.02-1.82-0.89-1.75-1.82c0.07-0.84,0.9-1.57,1.77-1.54
	C363.87,590.62,364.55,591.34,364.54,592.27z"
              />
              <path
                d="M488.96,417.14c0.86,0.06,1.62,0.91,1.56,1.75c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.58-0.81-1.52-1.75
	C487.29,417.78,488.07,417.08,488.96,417.14z"
              />
              <path
                d="M485.72,218.43c-0.86-0.01-1.68-0.81-1.68-1.65c0-0.84,0.83-1.66,1.67-1.66c0.9-0.01,1.63,0.74,1.63,1.66
	C487.35,217.71,486.64,218.44,485.72,218.43z"
              />
              <path
                d="M426.82,451.19c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C425.09,451.86,425.94,451.13,426.82,451.19z"
              />
              <path
                d="M556.96,620.59c0.85-0.01,1.69,0.78,1.71,1.62c0.02,0.84-0.78,1.66-1.64,1.69c-0.91,0.02-1.65-0.69-1.66-1.62
	C555.35,621.35,556.05,620.6,556.96,620.59z"
              />
              <path
                d="M412.86,439.88c0.02,0.88-0.74,1.67-1.65,1.72c-0.97,0.05-1.7-0.58-1.77-1.55c-0.08-1.05,0.69-1.88,1.72-1.85
	C412.05,438.22,412.84,439,412.86,439.88z"
              />
              <path
                d="M402.68,593.98c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C404.33,593.24,403.55,593.99,402.68,593.98z"
              />
              <path
                d="M425.72,459.98c0.04-0.94,0.75-1.62,1.68-1.63c0.99-0.01,1.82,0.84,1.78,1.8c-0.05,0.99-0.93,1.72-1.95,1.61
	C426.3,461.67,425.68,460.93,425.72,459.98z"
              />
              <path
                d="M422.88,348.79c-0.03,0.86-0.86,1.63-1.73,1.6c-0.94-0.02-1.61-0.75-1.58-1.7c0.03-0.93,0.78-1.65,1.68-1.62
	C422.11,347.1,422.91,347.94,422.88,348.79z"
              />
              <path
                d="M407.75,445.44c-0.95,0-1.65-0.69-1.67-1.64c-0.02-0.95,0.65-1.69,1.58-1.74c0.99-0.05,1.87,0.8,1.83,1.77
	C409.45,444.69,408.65,445.44,407.75,445.44z"
              />
              <path
                d="M364.08,418.88c-0.04,0.95-0.76,1.63-1.71,1.61c-0.9-0.02-1.69-0.78-1.7-1.66c-0.02-0.97,0.88-1.8,1.87-1.73
	C363.49,417.17,364.13,417.91,364.08,418.88z"
              />
              <path
                d="M459.88,439.46c-0.02,0.89-0.8,1.64-1.71,1.66c-0.96,0.02-1.67-0.66-1.72-1.62c-0.05-1.06,0.75-1.85,1.79-1.78
	C459.15,437.78,459.9,438.58,459.88,439.46z"
              />
              <path
                d="M431.47,385.73c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C433.12,384.99,432.34,385.74,431.47,385.73z"
              />
              <path
                d="M399.37,598.01c-0.88,0-1.68-0.74-1.73-1.6c-0.05-0.95,0.84-1.81,1.81-1.76c0.92,0.05,1.59,0.79,1.57,1.73
	C401,597.32,400.3,598.01,399.37,598.01z"
              />
              <path
                d="M449.29,443.97c0.01,0.85-0.75,1.66-1.61,1.72c-0.97,0.06-1.79-0.78-1.74-1.78c0.04-0.92,0.78-1.61,1.69-1.59
	C448.51,442.33,449.28,443.1,449.29,443.97z"
              />
              <path
                d="M433.98,454.05c-0.89,0.04-1.7-0.65-1.78-1.52c-0.09-0.94,0.76-1.84,1.72-1.83c0.91,0.01,1.61,0.72,1.63,1.66
	C435.58,453.29,434.91,454.01,433.98,454.05z"
              />
              <path
                d="M416.41,249.9c0.87,0.01,1.67,0.77,1.7,1.62c0.03,0.94-0.87,1.81-1.82,1.74c-0.89-0.06-1.57-0.83-1.54-1.75
	C414.78,250.59,415.5,249.89,416.41,249.9z"
              />
              <path
                d="M428.05,426c0.91-0.05,1.68,0.62,1.75,1.53c0.07,0.91-0.57,1.72-1.45,1.82c-0.93,0.11-1.88-0.71-1.9-1.65
	C426.42,426.85,427.17,426.05,428.05,426z"
              />
              <path
                d="M443.55,146.48c0.92,0.02,1.61,0.75,1.62,1.68c0.01,0.94-0.68,1.66-1.61,1.68c-0.98,0.03-1.84-0.84-1.77-1.79
	C441.86,147.19,442.68,146.46,443.55,146.48z"
              />
              <path
                d="M494.71,571.61c-0.95,0.06-1.69-0.61-1.74-1.57c-0.05-0.96,0.62-1.72,1.55-1.77c0.88-0.04,1.73,0.71,1.78,1.58
	C496.36,570.72,495.61,571.55,494.71,571.61z"
              />
              <path
                d="M304.01,494.37c0.87-0.05,1.7,0.64,1.8,1.51c0.11,0.94-0.72,1.84-1.7,1.84c-0.93,0-1.63-0.69-1.65-1.62
	C302.44,495.16,303.09,494.43,304.01,494.37z"
              />
              <path
                d="M410.89,252.25c0.03,0.85-0.77,1.67-1.64,1.7c-0.92,0.03-1.66-0.68-1.68-1.61c-0.02-0.95,0.67-1.69,1.59-1.71
	C410.02,250.61,410.86,251.4,410.89,252.25z"
              />
              <path
                d="M381.63,81.47c0,0.86-0.82,1.66-1.7,1.67c-0.94,0.01-1.66-0.72-1.65-1.67c0-0.95,0.73-1.69,1.66-1.68
	C380.81,79.8,381.63,80.61,381.63,81.47z"
              />
              <path
                d="M368.27,571.62c-0.89,0.05-1.71-0.63-1.81-1.51c-0.1-0.94,0.74-1.86,1.71-1.86c0.92,0,1.63,0.7,1.66,1.64
	C369.86,570.84,369.2,571.57,368.27,571.62z"
              />
              <path
                d="M390.78,600.69c-0.02,0.88-0.8,1.64-1.7,1.66c-0.95,0.02-1.67-0.66-1.71-1.61c-0.05-1.05,0.75-1.84,1.78-1.78
	C390.04,599.01,390.79,599.81,390.78,600.69z"
              />
              <path
                d="M364.08,253c-0.12,0.96-0.87,1.56-1.85,1.45c-0.91-0.1-1.62-0.91-1.55-1.8c0.07-0.97,1.05-1.74,2.02-1.58
	C363.6,251.22,364.19,252.05,364.08,253z"
              />
              <path
                d="M415.71,349.25c-0.02,0.84-0.82,1.62-1.68,1.64c-0.92,0.02-1.64-0.66-1.68-1.59c-0.04-1.03,0.75-1.82,1.75-1.76
	C414.96,347.59,415.73,348.41,415.71,349.25z"
              />
              <path
                d="M554.86,618.94c-0.03,0.85-0.83,1.61-1.7,1.62c-1.02,0.01-1.75-0.81-1.65-1.85c0.09-0.93,0.85-1.57,1.76-1.51
	C554.14,617.28,554.89,618.09,554.86,618.94z"
              />
              <path
                d="M458.12,504.77c-0.04,0.94-0.76,1.62-1.69,1.6c-0.88-0.02-1.67-0.79-1.69-1.65c-0.02-0.95,0.89-1.79,1.85-1.72
	C457.51,503.07,458.15,503.82,458.12,504.77z"
              />
              <path
                d="M318.28,429.92c-0.05-0.95,0.59-1.7,1.52-1.78c0.98-0.08,1.89,0.75,1.87,1.71c-0.01,0.86-0.8,1.64-1.69,1.66
	C319.04,431.54,318.33,430.87,318.28,429.92z"
              />
              <path
                d="M419.64,581.67c-0.92-0.06-1.57-0.82-1.5-1.76c0.06-0.92,0.84-1.61,1.74-1.53c0.87,0.07,1.61,0.92,1.54,1.77
	C421.35,581,420.51,581.72,419.64,581.67z"
              />
              <path
                d="M356.89,253.3c-0.02,0.96-0.73,1.65-1.68,1.64c-0.9,0-1.7-0.75-1.73-1.62c-0.04-0.97,0.84-1.82,1.83-1.76
	C356.24,251.61,356.91,252.35,356.89,253.3z"
              />
              <path
                d="M401,430.13c-0.03,0.9-0.79,1.65-1.66,1.64c-0.94-0.01-1.8-0.96-1.7-1.87c0.09-0.84,0.94-1.55,1.81-1.49
	C400.38,428.47,401.04,429.2,401,430.13z"
              />
              <path
                d="M416.83,569.36c0.06,0.97-0.59,1.71-1.54,1.77c-0.89,0.05-1.73-0.68-1.79-1.56c-0.06-0.87,0.7-1.71,1.59-1.78
	C416,567.73,416.77,568.42,416.83,569.36z"
              />
              <path
                d="M349.67,546.49c-0.03,0.94-0.74,1.63-1.67,1.62c-0.88-0.01-1.68-0.77-1.71-1.63c-0.03-0.96,0.86-1.8,1.83-1.74
	C349.05,544.81,349.7,545.54,349.67,546.49z"
              />
              <path
                d="M450.94,505.13c0.02,0.96-0.65,1.68-1.59,1.72c-0.92,0.04-1.72-0.64-1.81-1.53c-0.09-0.97,0.74-1.86,1.74-1.86
	C450.21,503.47,450.92,504.18,450.94,505.13z"
              />
              <path
                d="M322.08,370.09c0.92-0.01,1.66,0.73,1.67,1.67c0.01,0.95-0.7,1.68-1.64,1.68c-0.89,0-1.71-0.79-1.71-1.66
	C320.39,370.93,321.21,370.1,322.08,370.09z"
              />
              <path
                d="M360.5,462.56c-0.06,0.87-0.9,1.6-1.78,1.55c-0.94-0.05-1.59-0.8-1.53-1.76c0.06-0.93,0.83-1.63,1.74-1.56
	C359.81,460.86,360.56,461.71,360.5,462.56z"
              />
              <path
                d="M432.47,416.32c0.05,0.86-0.72,1.68-1.6,1.73c-0.92,0.05-1.67-0.64-1.7-1.58c-0.04-0.95,0.64-1.7,1.56-1.74
	C431.58,414.7,432.43,415.47,432.47,416.32z"
              />
              <path
                d="M461.65,191.59c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C463.32,191,462.59,191.64,461.65,191.59z"
              />
              <path
                d="M532.76,613.51c0.06,0.86-0.69,1.69-1.57,1.75c-0.92,0.06-1.68-0.61-1.73-1.55c-0.05-0.94,0.61-1.71,1.53-1.76
	C531.85,611.9,532.7,612.64,532.76,613.51z"
              />
              <path
                d="M437.51,141.53c-0.04,0.96-0.76,1.64-1.72,1.62c-0.92-0.02-1.69-0.78-1.71-1.66c-0.02-0.98,0.88-1.81,1.88-1.74
	C436.9,139.82,437.55,140.57,437.51,141.53z"
              />
              <path
                d="M248.37,188.72c0.88-0.04,1.73,0.71,1.78,1.58c0.05,0.87-0.71,1.7-1.6,1.75c-0.95,0.05-1.69-0.62-1.73-1.57
	C246.77,189.52,247.43,188.76,248.37,188.72z"
              />
              <path
                d="M311.94,464.62c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C313.61,464.03,312.88,464.67,311.94,464.62z"
              />
              <path
                d="M416.37,190.62c-0.12,0.96-0.87,1.55-1.84,1.45c-0.9-0.09-1.61-0.91-1.55-1.79c0.07-0.97,1.05-1.73,2.01-1.57
	C415.9,188.85,416.49,189.67,416.37,190.62z"
              />
              <path
                d="M517.16,600.58c0.04,0.96-0.6,1.69-1.55,1.76c-1,0.07-1.89-0.76-1.86-1.73c0.03-0.87,0.82-1.64,1.71-1.66
	C516.39,598.94,517.12,599.63,517.16,600.58z"
              />
              <path
                d="M476.17,546.53c-0.06,0.86-0.86,1.59-1.75,1.58c-1.03-0.02-1.73-0.85-1.61-1.9c0.12-0.94,0.87-1.56,1.81-1.46
	C475.5,544.84,476.22,545.68,476.17,546.53z"
              />
              <path
                d="M427.62,545.25c0.9-0.03,1.64,0.7,1.66,1.62c0.02,0.94-0.67,1.67-1.59,1.69c-0.86,0.01-1.69-0.77-1.71-1.61
	C425.97,546.11,426.77,545.28,427.62,545.25z"
              />
              <path
                d="M391.55,169.05c0.89,0.06,1.64,0.91,1.59,1.78c-0.06,0.88-0.9,1.61-1.79,1.56c-0.95-0.05-1.6-0.8-1.54-1.77
	C389.86,169.67,390.63,168.98,391.55,169.05z"
              />
              <path
                d="M361.41,453.13c-1-0.02-1.79-0.89-1.7-1.85c0.08-0.87,0.87-1.57,1.76-1.56c1.02,0.01,1.74,0.82,1.66,1.86
	C363.06,452.52,362.36,453.15,361.41,453.13z"
              />
              <path
                d="M295.22,208.25c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.57-0.81-1.52-1.75c0.06-0.92,0.83-1.62,1.72-1.55
	C294.53,206.56,295.28,207.4,295.22,208.25z"
              />
              <path
                d="M511.62,389.73c0.93-0.01,1.64,0.65,1.71,1.58c0.08,1.04-0.65,1.84-1.68,1.84c-0.9,0-1.68-0.7-1.75-1.57
	C509.83,390.61,510.63,389.75,511.62,389.73z"
              />
              <path
                d="M406.72,351.65c-0.89-0.04-1.59-0.81-1.58-1.74c0.01-0.93,0.73-1.65,1.65-1.66c0.96-0.01,1.82,0.91,1.73,1.84
	C408.43,350.93,407.56,351.69,406.72,351.65z"
              />
              <path
                d="M480.72,430.25c0.03,0.95-0.61,1.68-1.54,1.76c-1.02,0.09-1.89-0.65-1.92-1.64c-0.03-0.97,0.81-1.8,1.8-1.78
	C479.99,428.62,480.69,429.31,480.72,430.25z"
              />
              <path
                d="M268.87,113c-0.05,0.84-0.89,1.61-1.73,1.6c-0.96-0.01-1.73-0.92-1.62-1.91c0.1-0.9,0.89-1.54,1.81-1.45
	C268.2,111.32,268.93,112.14,268.87,113z"
              />
              <path
                d="M367.92,588.23c-0.02,0.95-0.72,1.64-1.67,1.64c-0.89,0-1.69-0.75-1.73-1.62c-0.04-0.96,0.84-1.81,1.82-1.76
	C367.27,586.54,367.94,587.28,367.92,588.23z"
              />
              <path
                d="M254.52,343.21c-0.89,0.04-1.7-0.65-1.78-1.52c-0.09-0.94,0.76-1.84,1.72-1.83c0.91,0.01,1.61,0.72,1.63,1.66
	C256.12,342.45,255.45,343.17,254.52,343.21z"
              />
              <path
                d="M460.29,506.12c0.91,0,1.64,0.72,1.66,1.64c0.02,0.92-0.66,1.7-1.56,1.75c-0.95,0.06-1.85-0.82-1.82-1.77
	C458.61,506.89,459.42,506.12,460.29,506.12z"
              />
              <path
                d="M367.22,415.01c-0.03,0.86-0.86,1.63-1.73,1.6c-0.94-0.02-1.61-0.75-1.58-1.7c0.03-0.93,0.78-1.65,1.68-1.62
	C366.45,413.32,367.24,414.16,367.22,415.01z"
              />
              <path
                d="M362.62,444.19c0.02,0.94-0.64,1.66-1.57,1.71c-0.89,0.04-1.7-0.64-1.79-1.52c-0.09-0.94,0.75-1.84,1.71-1.84
	C361.89,442.55,362.6,443.26,362.62,444.19z"
              />
              <path
                d="M473.33,533.73c-0.93,0.04-1.73-0.65-1.81-1.55c-0.09-0.98,0.75-1.86,1.75-1.85c0.94,0.01,1.65,0.73,1.67,1.68
	C474.96,532.96,474.28,533.69,473.33,533.73z"
              />
              <path
                d="M511.4,598.96c-0.94,0-1.65-0.73-1.64-1.68c0.01-0.94,0.75-1.68,1.67-1.67c0.87,0.01,1.69,0.84,1.69,1.69
	C513.11,598.17,512.28,598.96,511.4,598.96z"
              />
              <path
                d="M436.69,360.01c0.91-0.05,1.66,0.61,1.74,1.54c0.08,0.94-0.53,1.7-1.44,1.81c-0.96,0.11-1.9-0.69-1.91-1.64
	C435.06,360.88,435.83,360.06,436.69,360.01z"
              />
              <path
                d="M392.98,605.66c-0.92,0.06-1.67-0.62-1.72-1.56c-0.05-0.94,0.62-1.71,1.54-1.75c0.86-0.05,1.71,0.7,1.77,1.57
	C394.62,604.78,393.87,605.61,392.98,605.66z"
              />
              <path
                d="M490.77,564.89c0.87,0,1.68,0.77,1.72,1.62c0.04,0.95-0.84,1.8-1.82,1.74c-0.93-0.05-1.59-0.78-1.56-1.72
	C489.14,565.59,489.85,564.89,490.77,564.89z"
              />
              <path
                d="M539.73,613.24c-0.06,0.95-0.76,1.6-1.71,1.6c-1-0.01-1.81-0.85-1.74-1.83c0.07-0.98,0.98-1.71,1.97-1.59
	C539.17,611.54,539.79,612.3,539.73,613.24z"
              />
              <path
                d="M389.8,340.89c-0.04,0.85-0.86,1.64-1.71,1.65c-0.96,0.01-1.76-0.9-1.66-1.89c0.09-0.91,0.88-1.57,1.79-1.5
	C389.1,339.21,389.84,340.03,389.8,340.89z"
              />
              <path
                d="M408.44,452.65c-1-0.03-1.79-0.89-1.7-1.85c0.08-0.87,0.87-1.57,1.76-1.56c1.02,0.01,1.74,0.82,1.66,1.86
	C410.09,452.04,409.38,452.67,408.44,452.65z"
              />
              <path
                d="M453.05,510.19c-0.89-0.02-1.68-0.85-1.66-1.71c0.02-0.86,0.86-1.65,1.74-1.64c0.94,0.02,1.63,0.75,1.61,1.72
	C454.72,509.51,453.99,510.22,453.05,510.19z"
              />
              <path
                d="M409.23,582.9c0.87,0.01,1.66,0.79,1.68,1.64c0.02,0.94-0.89,1.8-1.84,1.72c-0.89-0.07-1.56-0.85-1.52-1.76
	C407.59,583.59,408.32,582.89,409.23,582.9z"
              />
              <path
                d="M244.32,394.54c-0.86-0.02-1.66-0.85-1.64-1.69c0.02-0.84,0.86-1.64,1.71-1.62c0.91,0.02,1.61,0.76,1.59,1.7
	C245.97,393.85,245.23,394.56,244.32,394.54z"
              />
              <path
                d="M318.45,393.8c0.03,0.95-0.65,1.68-1.59,1.7c-0.87,0.02-1.7-0.75-1.73-1.61c-0.03-0.85,0.77-1.68,1.63-1.71
	C317.67,392.15,318.42,392.87,318.45,393.8z"
              />
              <path
                d="M562.06,618.3c0.07,0.86-0.65,1.68-1.53,1.78c-0.95,0.1-1.7-0.49-1.82-1.44c-0.13-1.04,0.59-1.89,1.6-1.92
	C561.17,616.7,561.99,617.44,562.06,618.3z"
              />
              <path
                d="M260.92,208.84c-0.92,0-1.63-0.73-1.63-1.67c0.01-0.92,0.75-1.67,1.65-1.66c0.86,0.01,1.68,0.84,1.67,1.68
	C262.61,208.05,261.79,208.84,260.92,208.84z"
              />
              <path
                d="M528.68,278.3c-0.05,0.96-0.77,1.63-1.73,1.6c-0.91-0.03-1.69-0.8-1.69-1.68c-0.01-0.98,0.91-1.8,1.9-1.71
	C528.09,276.58,528.73,277.34,528.68,278.3z"
              />
              <path
                d="M434.12,208.37c-0.94-0.01-1.64-0.74-1.61-1.7c0.02-0.95,0.77-1.67,1.69-1.64c0.87,0.03,1.68,0.86,1.66,1.72
	C435.83,207.61,435,208.39,434.12,208.37z"
              />
              <path
                d="M445.81,510.68c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C447.48,510.09,446.76,510.73,445.81,510.68z"
              />
              <path
                d="M317.61,359.52c0.88,0.03,1.67,0.83,1.67,1.69c0.01,0.96-0.91,1.77-1.9,1.68c-0.94-0.09-1.56-0.82-1.5-1.79
	C315.94,360.16,316.68,359.48,317.61,359.52z"
              />
              <path
                d="M426.85,414.97c-0.89-0.05-1.57-0.82-1.55-1.74c0.02-0.93,0.74-1.63,1.65-1.63c0.96,0,1.81,0.92,1.7,1.85
	C428.57,414.28,427.69,415.02,426.85,414.97z"
              />
              <path
                d="M371.59,564.39c0.92,0.03,1.62,0.74,1.65,1.67c0.03,1.04-0.76,1.8-1.79,1.75c-0.92-0.04-1.65-0.77-1.67-1.66
	C369.75,565.18,370.6,564.36,371.59,564.39z"
              />
              <path
                d="M269.84,206.72c-0.01,0.85-0.8,1.64-1.67,1.67c-0.93,0.03-1.66-0.64-1.7-1.58c-0.05-1.03,0.73-1.84,1.74-1.79
	C269.07,205.06,269.85,205.87,269.84,206.72z"
              />
              <path
                d="M418.38,563.96c0.86,0.03,1.65,0.87,1.62,1.72c-0.03,0.86-0.86,1.62-1.73,1.6c-0.93-0.03-1.61-0.76-1.58-1.71
	C416.72,564.64,417.47,563.92,418.38,563.96z"
              />
              <path
                d="M371.1,584.57c-0.14,0.96-1.09,1.64-2.07,1.46c-0.89-0.16-1.46-0.97-1.34-1.91c0.13-1.02,1.02-1.66,2.03-1.45
	C370.59,582.84,371.22,583.7,371.1,584.57z"
              />
              <path
                d="M471.05,528.69c0,0.94-0.73,1.67-1.65,1.65c-0.87-0.01-1.69-0.82-1.69-1.67c0.01-0.85,0.84-1.66,1.7-1.66
	C470.33,527,471.05,527.75,471.05,528.69z"
              />
              <path
                d="M339.55,471.65c-0.04,0.92-0.79,1.62-1.7,1.57c-0.87-0.04-1.64-0.88-1.59-1.72c0.05-0.85,0.89-1.62,1.74-1.58
	C338.91,469.96,339.59,470.72,339.55,471.65z"
              />
              <path
                d="M503.54,237.03c-0.05,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.64-1.91c0.11-0.96,0.87-1.57,1.83-1.48
	C502.87,235.33,503.59,236.15,503.54,237.03z"
              />
              <path
                d="M418.06,584.09c-0.14,0.87-1.01,1.53-1.89,1.42c-0.91-0.12-1.51-0.93-1.39-1.87c0.13-0.95,0.92-1.56,1.84-1.42
	C417.51,582.35,418.19,583.24,418.06,584.09z"
              />
              <path
                d="M481.26,207.91c-0.96,0.05-1.68-0.58-1.75-1.54c-0.07-0.95,0.55-1.72,1.46-1.83c0.97-0.11,1.91,0.7,1.92,1.66
	C482.92,207.07,482.15,207.86,481.26,207.91z"
              />
              <path
                d="M431.28,528.59c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C430.54,526.97,431.24,527.65,431.28,528.59z"
              />
              <path
                d="M356.38,206.51c-0.02,0.91-0.76,1.66-1.63,1.66c-0.94,0.01-1.81-0.93-1.72-1.85c0.08-0.84,0.92-1.56,1.79-1.52
	C355.73,204.85,356.4,205.57,356.38,206.51z"
              />
              <path
                d="M359.8,597.8c-0.87,0.01-1.63-0.73-1.67-1.63c-0.04-0.94,0.61-1.67,1.53-1.74c0.97-0.07,1.86,0.76,1.83,1.71
	C361.46,596.99,360.65,597.79,359.8,597.8z"
              />
              <path
                d="M366.24,254.22c0.92,0,1.63,0.7,1.65,1.63c0.02,0.92-0.66,1.69-1.55,1.74c-0.95,0.06-1.85-0.81-1.81-1.75
	C364.57,254.98,365.37,254.22,366.24,254.22z"
              />
              <path
                d="M354.45,351.18c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.71c0.03-0.86,0.87-1.65,1.74-1.63
	C353.79,349.48,354.47,350.22,354.45,351.18z"
              />
              <path
                d="M508.34,393.62c0.91,0.03,1.6,0.79,1.57,1.73c-0.03,0.94-0.77,1.63-1.7,1.59c-0.88-0.04-1.65-0.87-1.61-1.72
	C506.64,394.36,507.48,393.59,508.34,393.62z"
              />
              <path
                d="M333.4,67.58c-0.01,0.94-0.69,1.64-1.62,1.68c-1,0.04-1.85-0.76-1.84-1.72c0.02-0.96,0.91-1.75,1.89-1.68
	C332.75,65.92,333.41,66.65,333.4,67.58z"
              />
              <path
                d="M353.76,550.02c-0.14,0.87-1.01,1.53-1.89,1.42c-0.91-0.12-1.51-0.93-1.39-1.87c0.13-0.95,0.92-1.56,1.84-1.42
	C353.21,548.28,353.89,549.18,353.76,550.02z"
              />
              <path
                d="M354.26,616.5c0.92,0.01,1.62,0.71,1.63,1.64c0.01,0.92-0.68,1.68-1.57,1.72c-0.95,0.05-1.84-0.84-1.79-1.77
	C352.58,617.24,353.4,616.49,354.26,616.5z"
              />
              <path
                d="M410.42,431c0.93,0.01,1.63,0.69,1.67,1.63c0.05,1.04-0.71,1.82-1.74,1.79c-0.9-0.02-1.66-0.74-1.7-1.62
	C408.6,431.84,409.44,430.99,410.42,431z"
              />
              <path
                d="M509.03,237.92c-0.87,0.01-1.63-0.73-1.67-1.63c-0.04-0.94,0.61-1.67,1.53-1.74c0.97-0.07,1.86,0.76,1.83,1.71
	C510.69,237.11,509.88,237.91,509.03,237.92z"
              />
              <path
                d="M466.1,511.16c0,0.85-0.78,1.67-1.64,1.71c-0.97,0.05-1.79-0.82-1.73-1.82c0.05-0.91,0.82-1.62,1.72-1.59
	C465.31,509.5,466.1,510.3,466.1,511.16z"
              />
              <path
                d="M331.87,361.72c-0.89-0.04-1.59-0.81-1.58-1.73c0.01-0.93,0.72-1.65,1.64-1.66c0.96-0.01,1.83,0.9,1.74,1.84
	C333.58,361,332.71,361.76,331.87,361.72z"
              />
              <path
                d="M373.69,613.23c-0.05,0.96-0.77,1.63-1.73,1.6c-0.91-0.03-1.69-0.8-1.69-1.68c-0.01-0.98,0.9-1.8,1.9-1.71
	C373.1,611.51,373.74,612.27,373.69,613.23z"
              />
              <path
                d="M314.54,490.06c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C312.8,490.73,313.66,490,314.54,490.06z"
              />
              <path
                d="M422.95,451.45c-0.84,0.04-1.71-0.72-1.8-1.55c-0.09-0.93,0.77-1.85,1.73-1.84c0.92,0.01,1.63,0.73,1.64,1.66
	C424.54,450.64,423.84,451.41,422.95,451.45z"
              />
              <path
                d="M520.81,351.09c0.03,0.88-0.71,1.67-1.62,1.74c-0.97,0.07-1.7-0.55-1.78-1.52c-0.1-1.05,0.66-1.88,1.68-1.87
	C519.99,349.45,520.78,350.21,520.81,351.09z"
              />
              <path
                d="M406.1,257.84c-1.01,0.01-1.83-0.82-1.78-1.79c0.05-0.89,0.8-1.61,1.71-1.64c1.02-0.03,1.79,0.77,1.75,1.8
	C407.74,257.16,407.05,257.83,406.1,257.84z"
              />
              <path
                d="M385.59,603.05c0.86-0.05,1.72,0.67,1.81,1.52c0.09,0.92-0.78,1.87-1.72,1.87c-0.87,0-1.63-0.76-1.66-1.67
	C384,603.84,384.67,603.11,385.59,603.05z"
              />
              <path
                d="M677.02,631.58c-0.93,0.04-1.67-0.65-1.7-1.6c-0.03-0.96,0.65-1.7,1.57-1.73c0.87-0.03,1.71,0.74,1.75,1.6
	C678.68,630.72,677.91,631.55,677.02,631.58z"
              />
              <path
                d="M241.05,375.35c0.01,0.86-0.76,1.68-1.62,1.73c-0.98,0.06-1.8-0.78-1.75-1.79c0.04-0.92,0.8-1.63,1.7-1.61
	C240.25,373.7,241.04,374.49,241.05,375.35z"
              />
              <path
                d="M436.48,173.98c0.06,0.95-0.6,1.7-1.53,1.75c-0.88,0.05-1.71-0.68-1.77-1.55c-0.06-0.85,0.7-1.7,1.57-1.76
	C435.66,172.35,436.43,173.05,436.48,173.98z"
              />
              <path
                d="M385.81,360.76c-0.87-0.06-1.63-0.88-1.61-1.74c0.02-0.96,0.95-1.75,1.92-1.63c0.91,0.11,1.54,0.9,1.46,1.83
	C387.5,360.12,386.69,360.82,385.81,360.76z"
              />
              <path
                d="M351.58,235.64c0.05,0.92-0.61,1.7-1.5,1.79c-0.94,0.09-1.87-0.75-1.86-1.69c0-0.85,0.78-1.64,1.65-1.67
	C350.78,234.03,351.53,234.72,351.58,235.64z"
              />
              <path
                d="M360.73,256.57c0.02,0.96-0.64,1.68-1.58,1.73c-0.92,0.05-1.73-0.63-1.82-1.52c-0.1-0.97,0.73-1.86,1.73-1.86
	C359.99,254.91,360.71,255.62,360.73,256.57z"
              />
              <path
                d="M259.48,337.93c-0.12,0.94-0.84,1.54-1.8,1.47c-1.01-0.07-1.75-0.96-1.61-1.93c0.14-0.96,1.11-1.65,2.07-1.47
	C259.02,336.17,259.59,337,259.48,337.93z"
              />
              <path
                d="M363.14,491.27c-0.01,0.96-0.71,1.66-1.66,1.67c-0.92,0.01-1.71-0.71-1.76-1.6c-0.06-0.98,0.81-1.84,1.81-1.8
	C362.46,489.58,363.15,490.32,363.14,491.27z"
              />
              <path
                d="M438.92,175.75c0.86-0.01,1.68,0.74,1.73,1.6c0.06,0.94-0.81,1.8-1.79,1.76c-0.92-0.04-1.59-0.76-1.58-1.7
	C437.31,176.48,438.01,175.76,438.92,175.75z"
              />
              <path
                d="M323.3,324.85c-0.05,0.95-0.78,1.62-1.73,1.59c-0.9-0.03-1.68-0.81-1.68-1.68c0-0.97,0.91-1.79,1.89-1.7
	C322.71,323.14,323.35,323.89,323.3,324.85z"
              />
              <path
                d="M384.04,482.36c-0.13,0.89-0.96,1.54-1.88,1.45c-1.03-0.09-1.68-0.99-1.48-2.03c0.18-0.92,1.01-1.49,1.94-1.32
	C383.51,480.62,384.16,481.49,384.04,482.36z"
              />
              <path
                d="M431.69,448.99c0.02,0.95-0.68,1.68-1.6,1.69c-0.87,0.01-1.7-0.77-1.71-1.63c-0.02-0.84,0.79-1.68,1.65-1.7
	C430.93,447.33,431.68,448.06,431.69,448.99z"
              />
              <path
                d="M400.59,589.2c-0.03,0.95-0.71,1.63-1.66,1.65c-1.01,0.02-1.84-0.79-1.8-1.77c0.04-0.98,0.92-1.74,1.92-1.65
	C399.97,587.51,400.61,588.26,400.59,589.2z"
              />
              <path
                d="M425.95,550.76c0,0.95-0.72,1.67-1.66,1.66c-0.88-0.01-1.7-0.82-1.69-1.68c0.01-0.85,0.84-1.67,1.71-1.67
	C425.23,549.07,425.95,549.81,425.95,550.76z"
              />
              <path
                d="M397.22,592.89c0.04,0.95-0.58,1.69-1.51,1.78c-1.01,0.1-1.89-0.63-1.94-1.62c-0.05-0.97,0.79-1.81,1.78-1.8
	C396.48,591.27,397.18,591.95,397.22,592.89z"
              />
              <path
                d="M401.01,263.74c0.04,0.95-0.6,1.67-1.53,1.73c-0.97,0.06-1.86-0.77-1.83-1.72c0.03-0.84,0.84-1.62,1.7-1.63
	C400.25,262.11,400.97,262.81,401.01,263.74z"
              />
              <path
                d="M274.75,323.52c0.91-0.04,1.73,0.65,1.82,1.54c0.1,0.98-0.72,1.86-1.74,1.86c-0.95,0-1.66-0.7-1.68-1.66
	C273.13,324.31,273.81,323.57,274.75,323.52z"
              />
              <path
                d="M451.44,152.96c0.86,0.01,1.68,0.79,1.7,1.64c0.03,0.95-0.88,1.82-1.83,1.76c-0.89-0.06-1.58-0.84-1.55-1.76
	C449.8,153.66,450.53,152.95,451.44,152.96z"
              />
              <path
                d="M343.41,411.38c-0.94-0.02-1.62-0.69-1.65-1.64c-0.03-1.03,0.74-1.81,1.76-1.78c0.89,0.03,1.65,0.77,1.69,1.65
	C345.24,410.58,344.41,411.4,343.41,411.38z"
              />
              <path
                d="M347.29,351.7c-0.04,0.95-0.76,1.63-1.71,1.61c-0.9-0.02-1.69-0.78-1.7-1.66c-0.02-0.97,0.88-1.8,1.87-1.73
	C346.69,350,347.33,350.73,347.29,351.7z"
              />
              <path
                d="M413.03,233.96c0.05,0.97-0.58,1.7-1.53,1.78c-1,0.08-1.9-0.74-1.88-1.71c0.02-0.87,0.81-1.65,1.7-1.67
	C412.25,232.33,412.98,233.02,413.03,233.96z"
              />
              <path
                d="M404.23,465.83c0,0.98-0.87,1.76-1.88,1.7c-0.93-0.06-1.6-0.77-1.6-1.71c0.01-0.93,0.69-1.65,1.61-1.7
	C403.37,464.05,404.24,464.86,404.23,465.83z"
              />
              <path
                d="M438.7,448.62c-0.05,0.86-0.87,1.61-1.75,1.57c-0.93-0.04-1.59-0.78-1.54-1.73c0.04-0.92,0.81-1.63,1.7-1.58
	C437.98,446.94,438.75,447.78,438.7,448.62z"
              />
              <path
                d="M439.68,376.06c0.02,0.84-0.79,1.67-1.66,1.69c-0.92,0.02-1.65-0.7-1.66-1.63c-0.01-0.94,0.69-1.68,1.61-1.69
	C438.83,374.42,439.66,375.22,439.68,376.06z"
              />
              <path
                d="M272.85,265.76c-0.89-0.04-1.59-0.81-1.58-1.73c0.01-0.93,0.72-1.65,1.64-1.66c0.96-0.01,1.83,0.9,1.74,1.84
	C274.56,265.04,273.69,265.79,272.85,265.76z"
              />
              <path
                d="M320.95,355.69c0.85,0.02,1.66,0.82,1.67,1.66c0.02,0.94-0.89,1.77-1.86,1.69c-0.93-0.08-1.55-0.81-1.5-1.76
	C319.31,356.35,320.04,355.66,320.95,355.69z"
              />
              <path
                d="M291.09,204.81c-0.01,0.93-0.74,1.65-1.65,1.63c-0.86-0.02-1.67-0.84-1.65-1.68c0.02-0.84,0.85-1.64,1.7-1.63
	C290.4,203.14,291.1,203.88,291.09,204.81z"
              />
              <path
                d="M449.9,510.71c0.85-0.04,1.7,0.71,1.75,1.57c0.05,0.85-0.7,1.68-1.58,1.73c-0.91,0.05-1.66-0.63-1.71-1.56
	C448.32,511.53,449,510.75,449.9,510.71z"
              />
              <path
                d="M250.21,396.24c0,0.97-0.86,1.76-1.87,1.7c-0.93-0.05-1.61-0.77-1.6-1.71c0-0.93,0.69-1.66,1.61-1.71
	C249.33,394.47,250.2,395.28,250.21,396.24z"
              />
              <path
                d="M419.44,122.99c-0.91-0.04-1.68-0.82-1.67-1.7c0.01-0.97,0.93-1.79,1.92-1.69c0.93,0.09,1.56,0.86,1.5,1.82
	C421.13,122.39,420.4,123.03,419.44,122.99z"
              />
              <path
                d="M330.45,490.21c0.05,0.96-0.61,1.7-1.56,1.74c-0.9,0.04-1.72-0.7-1.76-1.58c-0.05-0.86,0.72-1.7,1.6-1.75
	C329.65,488.57,330.4,489.27,330.45,490.21z"
              />
              <path
                d="M308.92,326.14c0.02,0.96-0.65,1.69-1.59,1.74c-0.93,0.05-1.73-0.63-1.82-1.53c-0.1-0.98,0.73-1.87,1.74-1.87
	C308.18,324.48,308.89,325.19,308.92,326.14z"
              />
              <path
                d="M442.83,514.79c-0.86,0.02-1.64-0.72-1.69-1.61c-0.05-0.94,0.59-1.68,1.51-1.75c0.97-0.08,1.86,0.74,1.84,1.69
	C444.48,513.96,443.67,514.77,442.83,514.79z"
              />
              <path
                d="M335.96,488.1c0.93-0.03,1.66,0.61,1.74,1.54c0.1,1.04-0.61,1.85-1.64,1.87c-0.91,0.02-1.69-0.66-1.78-1.53
	C334.18,489.01,334.97,488.13,335.96,488.1z"
              />
              <path
                d="M397.55,410.18c-0.84,0.06-1.72-0.68-1.82-1.52c-0.11-0.93,0.74-1.87,1.7-1.87c0.91-0.01,1.64,0.71,1.67,1.63
	C399.12,409.34,398.44,410.12,397.55,410.18z"
              />
              <path
                d="M408.22,263.35c-0.01,0.94-0.7,1.64-1.64,1.65c-0.88,0.01-1.69-0.72-1.74-1.59c-0.06-0.95,0.82-1.82,1.79-1.78
	C407.55,261.68,408.23,262.41,408.22,263.35z"
              />
              <path
                d="M329.84,356.97c-0.04,0.89-0.82,1.63-1.74,1.63c-1.05,0-1.78-0.82-1.67-1.89c0.1-0.97,0.85-1.59,1.81-1.51
	C329.14,355.27,329.88,356.08,329.84,356.97z"
              />
              <path
                d="M367.71,628.01c0,0.85-0.82,1.65-1.7,1.66c-0.93,0.01-1.65-0.72-1.64-1.67c0-0.94,0.74-1.68,1.65-1.67
	C366.89,626.34,367.71,627.16,367.71,628.01z"
              />
              <path
                d="M356.68,625.45c-0.05,0.84-0.87,1.62-1.72,1.62c-0.96,0-1.74-0.91-1.64-1.89c0.09-0.9,0.88-1.55,1.8-1.47
	C356,623.78,356.73,624.59,356.68,625.45z"
              />
              <path
                d="M292.33,611.01c0.93-0.05,1.68,0.66,1.7,1.61c0.02,0.96-0.65,1.68-1.6,1.71c-0.92,0.03-1.72-0.7-1.74-1.59
	C290.67,611.86,291.43,611.06,292.33,611.01z"
              />
              <path
                d="M386.11,258.14c-0.05,0.95-0.79,1.62-1.73,1.57c-0.89-0.05-1.65-0.88-1.59-1.75c0.06-0.87,0.9-1.62,1.77-1.58
	C385.49,256.43,386.16,257.19,386.11,258.14z"
              />
              <path
                d="M417.11,197.54c0.03,0.84-0.76,1.67-1.63,1.7c-0.91,0.03-1.65-0.68-1.67-1.6c-0.02-0.93,0.67-1.68,1.58-1.71
	C416.25,195.91,417.09,196.7,417.11,197.54z"
              />
              <path
                d="M413.49,114.88c-0.03,0.91-0.79,1.66-1.67,1.66c-0.94-0.01-1.81-0.96-1.71-1.89c0.09-0.85,0.96-1.56,1.82-1.51
	C412.86,113.2,413.52,113.94,413.49,114.88z"
              />
              <path
                d="M355.78,464.62c0.93,0.05,1.6,0.81,1.55,1.77c-0.05,0.96-0.79,1.63-1.74,1.57c-0.9-0.05-1.65-0.89-1.6-1.76
	C354.05,465.33,354.9,464.58,355.78,464.62z"
              />
              <path
                d="M474.49,524.81c0.02,0.94-0.64,1.67-1.57,1.73c-1.02,0.08-1.88-0.69-1.9-1.67c-0.02-0.97,0.84-1.79,1.83-1.75
	C473.78,523.16,474.47,523.88,474.49,524.81z"
              />
              <path
                d="M283.44,198.36c-0.02,0.9-0.77,1.65-1.64,1.66c-0.94,0-1.81-0.94-1.72-1.85c0.08-0.84,0.93-1.56,1.8-1.51
	C282.81,196.7,283.47,197.42,283.44,198.36z"
              />
              <path
                d="M469.95,514.34c-0.03,0.89-0.81,1.63-1.73,1.64c-1.05,0.01-1.79-0.81-1.68-1.88c0.09-0.96,0.84-1.59,1.8-1.52
	C469.25,512.65,469.98,513.45,469.95,514.34z"
              />
              <path
                d="M419.77,352.49c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C419.02,350.87,419.72,351.55,419.77,352.49z"
              />
              <path
                d="M439.57,143.12c0.94-0.05,1.67,0.6,1.75,1.54c0.08,0.96-0.53,1.72-1.46,1.82c-1,0.11-1.91-0.67-1.93-1.65
	C437.92,143.97,438.68,143.17,439.57,143.12z"
              />
              <path
                d="M430.27,434.68c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.72c0.03-0.86,0.87-1.65,1.75-1.63
	C429.61,432.97,430.29,433.71,430.27,434.68z"
              />
              <path
                d="M420.74,114.07c0.02,0.94-0.64,1.67-1.57,1.73c-1.02,0.08-1.88-0.69-1.9-1.67c-0.02-0.97,0.84-1.79,1.83-1.75
	C420.03,112.42,420.73,113.14,420.74,114.07z"
              />
              <path
                d="M235.76,231.41c0,0.85-0.78,1.66-1.63,1.7c-0.98,0.05-1.78-0.79-1.72-1.8c0.05-0.92,0.8-1.6,1.71-1.57
	C234.99,229.77,235.76,230.56,235.76,231.41z"
              />
              <path
                d="M289.04,262.94c-0.05,0.87-0.85,1.6-1.75,1.59c-1.03-0.01-1.74-0.85-1.62-1.9c0.11-0.95,0.87-1.56,1.81-1.47
	C288.37,261.25,289.09,262.08,289.04,262.94z"
              />
              <path
                d="M259.39,400.57c-0.89-0.05-1.57-0.82-1.55-1.74c0.02-0.93,0.74-1.63,1.65-1.63c0.96,0,1.81,0.92,1.7,1.85
	C261.1,399.89,260.23,400.62,259.39,400.57z"
              />
              <path
                d="M359.08,487.96c-0.04,0.85-0.84,1.6-1.71,1.61c-1.02,0-1.74-0.82-1.63-1.86c0.1-0.93,0.86-1.57,1.77-1.49
	C358.38,486.29,359.12,487.11,359.08,487.96z"
              />
              <path
                d="M419.55,558.52c-0.05,0.87-0.85,1.6-1.75,1.59c-1.03-0.01-1.74-0.85-1.62-1.9c0.11-0.95,0.87-1.56,1.81-1.47
	C418.88,556.83,419.61,557.66,419.55,558.52z"
              />
              <path
                d="M266,329.76c0.01,0.86-0.75,1.68-1.62,1.74c-0.98,0.06-1.81-0.78-1.76-1.79c0.04-0.92,0.79-1.63,1.7-1.61
	C265.2,328.11,265.99,328.9,266,329.76z"
              />
              <path
                d="M543.82,616.35c-0.06,0.84-0.9,1.61-1.74,1.6c-0.96-0.02-1.73-0.92-1.62-1.91c0.1-0.91,0.89-1.53,1.81-1.45
	C543.16,614.68,543.88,615.5,543.82,616.35z"
              />
              <path
                d="M406.29,447.81c-0.12,0.96-0.87,1.55-1.84,1.45c-0.9-0.09-1.61-0.91-1.55-1.79c0.07-0.97,1.05-1.73,2.01-1.57
	C405.82,446.05,406.41,446.87,406.29,447.81z"
              />
              <path
                d="M364.92,445.91c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C363.19,446.59,364.04,445.85,364.92,445.91z"
              />
              <path
                d="M353.93,200.96c-0.89-0.04-1.58-0.81-1.57-1.72c0.01-0.93,0.71-1.63,1.64-1.64c0.96-0.01,1.82,0.9,1.72,1.83
	C355.64,200.26,354.77,201,353.93,200.96z"
              />
              <path
                d="M357.63,553.12c0.02,0.85-0.79,1.67-1.66,1.69c-0.92,0.02-1.66-0.7-1.67-1.64c-0.01-0.95,0.7-1.69,1.62-1.7
	C356.78,551.47,357.62,552.27,357.63,553.12z"
              />
              <path
                d="M576.94,624.5c-0.05,0.84-0.89,1.63-1.73,1.62c-0.96-0.01-1.75-0.93-1.64-1.91c0.1-0.91,0.89-1.56,1.81-1.48
	C576.26,622.81,576.99,623.64,576.94,624.5z"
              />
              <path
                d="M305.86,330.11c-0.05,0.98-0.94,1.72-1.95,1.61c-0.93-0.1-1.55-0.84-1.51-1.79c0.05-0.94,0.75-1.62,1.68-1.63
	C305.08,328.3,305.91,329.15,305.86,330.11z"
              />
              <path
                d="M307.42,517.59c0.03,0.96-0.63,1.67-1.56,1.69c-0.86,0.02-1.69-0.75-1.72-1.6c-0.03-0.84,0.76-1.67,1.61-1.7
	C306.67,515.95,307.39,516.65,307.42,517.59z"
              />
              <path
                d="M424.34,446c-0.05-0.94,0.59-1.68,1.52-1.75c0.97-0.08,1.86,0.74,1.84,1.7c-0.02,0.84-0.83,1.65-1.67,1.67
	C425.17,447.64,424.39,446.89,424.34,446z"
              />
              <path
                d="M479.02,202.82c0.03,0.85-0.76,1.68-1.63,1.71c-0.92,0.03-1.66-0.68-1.68-1.61c-0.02-0.95,0.67-1.69,1.59-1.71
	C478.15,201.18,478.99,201.97,479.02,202.82z"
              />
              <path
                d="M462.92,435.55c0.03,0.94-0.64,1.67-1.57,1.72c-0.9,0.05-1.71-0.64-1.8-1.52c-0.1-0.94,0.74-1.85,1.71-1.85
	C462.18,433.9,462.89,434.61,462.92,435.55z"
              />
              <path
                d="M453.17,447.19c-0.05,0.98-0.94,1.72-1.95,1.61c-0.93-0.1-1.55-0.84-1.51-1.79c0.05-0.94,0.75-1.62,1.68-1.63
	C452.39,445.38,453.22,446.23,453.17,447.19z"
              />
              <path
                d="M302.47,333.98c-0.06,0.87-0.87,1.59-1.76,1.57c-1.03-0.02-1.74-0.86-1.6-1.91c0.12-0.95,0.88-1.55,1.83-1.45
	C301.82,332.28,302.53,333.11,302.47,333.98z"
              />
              <path
                d="M411.93,445.4c0.93,0.05,1.61,0.79,1.59,1.75c-0.02,0.96-0.73,1.65-1.68,1.65c-1.02,0-1.84-0.88-1.73-1.86
	C410.2,446.04,411.02,445.36,411.93,445.4z"
              />
              <path
                d="M298.6,330.61c-0.06,0.87-0.9,1.6-1.78,1.55c-0.94-0.05-1.59-0.8-1.53-1.76c0.06-0.93,0.83-1.63,1.74-1.56
	C297.91,328.9,298.66,329.75,298.6,330.61z"
              />
              <path
                d="M369.57,380.2c-1.01-0.02-1.8-0.88-1.7-1.86c0.08-0.88,0.87-1.58,1.77-1.57c1.02,0.01,1.76,0.83,1.67,1.87
	C371.23,379.58,370.52,380.23,369.57,380.2z"
              />
              <path
                d="M343.88,355.46c0,0.94-0.71,1.66-1.64,1.66c-0.86-0.01-1.69-0.82-1.68-1.66c0-0.84,0.83-1.66,1.68-1.67
	C343.15,353.78,343.88,354.53,343.88,355.46z"
              />
              <path
                d="M300.73,605.02c-0.02,0.95-0.75,1.65-1.69,1.63c-0.89-0.02-1.68-0.84-1.64-1.71c0.03-0.86,0.87-1.65,1.74-1.64
	C300.06,603.32,300.75,604.06,300.73,605.02z"
              />
              <path
                d="M414.42,437.76c-0.95-0.02-1.64-0.74-1.64-1.7c0-0.95,0.71-1.68,1.64-1.7c1-0.02,1.85,0.85,1.78,1.83
	C416.14,437.08,415.33,437.79,414.42,437.76z"
              />
              <path
                d="M415.37,594.85c0.03,0.94-0.65,1.67-1.58,1.7c-0.86,0.02-1.69-0.75-1.72-1.6c-0.03-0.84,0.77-1.68,1.62-1.71
	C414.59,593.2,415.34,593.93,415.37,594.85z"
              />
              <path
                d="M284.19,332.05c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.57-0.81-1.52-1.75c0.06-0.92,0.83-1.61,1.72-1.55
	C283.49,330.35,284.25,331.2,284.19,332.05z"
              />
              <path
                d="M380.7,486.08c-0.06,0.89-0.86,1.6-1.78,1.58c-1.05-0.02-1.76-0.87-1.62-1.93c0.12-0.96,0.88-1.56,1.85-1.46
	C380.05,484.38,380.76,485.2,380.7,486.08z"
              />
              <path
                d="M475.15,551.97c0.89-0.01,1.63,0.73,1.64,1.65c0.01,0.93-0.7,1.66-1.62,1.66c-0.86,0-1.68-0.8-1.69-1.64
	C473.48,552.8,474.3,551.98,475.15,551.97z"
              />
              <path
                d="M364.35,631.95c-0.06,0.86-0.87,1.58-1.75,1.56c-1.02-0.02-1.72-0.86-1.59-1.9c0.12-0.94,0.89-1.55,1.81-1.44
	C363.7,630.27,364.42,631.11,364.35,631.95z"
              />
              <path
                d="M252.26,397.88c0.99-0.01,1.82,0.84,1.77,1.8c-0.05,0.89-0.8,1.6-1.71,1.63c-1.04,0.03-1.8-0.76-1.75-1.8
	C250.62,398.58,251.33,397.89,252.26,397.88z"
              />
              <path
                d="M470.4,521.59c-0.06,0.86-0.87,1.58-1.75,1.56c-1.02-0.02-1.72-0.86-1.59-1.9c0.12-0.94,0.88-1.55,1.81-1.44
	C469.74,519.9,470.46,520.74,470.4,521.59z"
              />
              <path
                d="M294.45,201c-0.02,0.92-0.76,1.63-1.67,1.6c-0.86-0.03-1.65-0.86-1.63-1.7c0.03-0.85,0.87-1.63,1.72-1.61
	C293.78,199.31,294.47,200.07,294.45,201z"
              />
              <path
                d="M423.51,581.72c0.85-0.04,1.7,0.71,1.75,1.57c0.05,0.85-0.7,1.68-1.58,1.73c-0.91,0.05-1.66-0.63-1.71-1.56
	C421.93,582.54,422.61,581.77,423.51,581.72z"
              />
              <path
                d="M334.18,469.91c-0.93,0.04-1.73-0.65-1.81-1.55c-0.09-0.98,0.75-1.86,1.75-1.85c0.94,0.01,1.65,0.73,1.67,1.68
	C335.81,469.14,335.13,469.87,334.18,469.91z"
              />
              <path
                d="M422.91,554.55c0.03,0.85-0.73,1.65-1.61,1.71c-0.94,0.06-1.67-0.57-1.75-1.51c-0.09-1.03,0.66-1.86,1.66-1.85
	C422.07,552.92,422.88,553.7,422.91,554.55z"
              />
              <path
                d="M437.95,520.66c0.05,0.92-0.61,1.7-1.5,1.79c-0.94,0.09-1.87-0.75-1.86-1.69c0-0.85,0.78-1.64,1.65-1.67
	C437.15,519.05,437.9,519.74,437.95,520.66z"
              />
              <path
                d="M361.48,556.38c-0.06,0.84-0.91,1.6-1.75,1.58c-0.96-0.02-1.72-0.93-1.6-1.92c0.11-0.91,0.9-1.53,1.82-1.43
	C360.83,554.71,361.55,555.53,361.48,556.38z"
              />
              <path
                d="M362.1,626.35c-0.94-0.03-1.61-0.72-1.63-1.66c-0.02-1.03,0.76-1.8,1.78-1.75c0.89,0.04,1.64,0.79,1.67,1.67
	C363.95,625.57,363.11,626.38,362.1,626.35z"
              />
              <path
                d="M502.9,581.93c0.91-0.05,1.69,0.63,1.76,1.54c0.07,0.91-0.57,1.73-1.46,1.84c-0.94,0.12-1.89-0.71-1.92-1.65
	C501.26,582.8,502.02,581.99,502.9,581.93z"
              />
              <path
                d="M414.72,587.79c-0.06,0.87-0.89,1.6-1.77,1.55c-0.93-0.05-1.59-0.8-1.53-1.75c0.05-0.93,0.82-1.63,1.73-1.56
	C414.01,586.1,414.77,586.94,414.72,587.79z"
              />
              <path
                d="M287.25,201.33c0.06,0.95-0.6,1.7-1.53,1.75c-0.88,0.05-1.71-0.68-1.77-1.55c-0.06-0.85,0.7-1.7,1.57-1.76
	C286.43,199.7,287.2,200.4,287.25,201.33z"
              />
              <path
                d="M515.95,380.64c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C515.4,378.89,516.09,379.79,515.95,380.64z"
              />
              <path
                d="M237.41,228.99c-0.91,0-1.63-0.73-1.62-1.66c0.01-0.92,0.75-1.66,1.64-1.65c0.85,0.01,1.67,0.84,1.66,1.67
	C239.09,228.19,238.27,228.99,237.41,228.99z"
              />
              <path
                d="M370.32,616.99c-0.01,0.95-0.7,1.65-1.64,1.66c-0.9,0.01-1.7-0.72-1.75-1.59c-0.06-0.96,0.81-1.82,1.8-1.78
	C369.65,615.32,370.33,616.04,370.32,616.99z"
              />
              <path
                d="M407.31,438.25c-0.96,0.02-1.68-0.65-1.72-1.61c-0.05-0.96,0.6-1.71,1.54-1.79c1-0.08,1.9,0.75,1.89,1.73
	C409,437.47,408.23,438.23,407.31,438.25z"
              />
              <path
                d="M395.87,349c-0.96,0.06-1.68-0.54-1.79-1.48c-0.12-1.03,0.57-1.87,1.59-1.92c0.98-0.05,1.85,0.77,1.84,1.73
	C397.5,348.19,396.77,348.94,395.87,349z"
              />
              <path
                d="M434.89,378.26c0.93,0.01,1.62,0.75,1.61,1.7c-0.01,0.94-0.75,1.66-1.67,1.63c-0.87-0.02-1.68-0.85-1.66-1.7
	C433.19,379.04,434.02,378.25,434.89,378.26z"
              />
              <path
                d="M311.53,520.92c0.05,0.86-0.76,1.74-1.63,1.72c-0.92-0.02-1.48-0.52-1.61-1.44c-0.13-0.93,0.55-1.76,1.45-1.84
	C310.61,519.28,311.49,520.05,311.53,520.92z"
              />
              <path
                d="M416.15,602.07c0.01,0.86-0.83,1.71-1.7,1.66c-0.92-0.05-1.47-0.56-1.57-1.49c-0.1-0.93,0.61-1.75,1.52-1.79
	C415.27,600.4,416.14,601.21,416.15,602.07z"
              />
              <path
                d="M419.2,598.19c0.04,0.95-0.67,1.72-1.61,1.72c-0.87,0-1.75-0.85-1.66-1.69c0.1-0.88,0.6-1.48,1.52-1.59
	C418.35,596.5,419.16,597.26,419.2,598.19z"
              />
              <path
                d="M522.64,333.14c-0.15,0.88-1.08,1.55-1.93,1.39c-0.89-0.16-1.51-1.08-1.28-1.98c0.23-0.89,0.83-1.36,1.75-1.28
	C522.07,331.35,522.78,332.29,522.64,333.14z"
              />
              <path
                d="M326.98,470.61c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.73,1.56
	C328.57,469.8,327.89,470.57,326.98,470.61z"
              />
              <path
                d="M379.28,614.09c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C380.88,613.27,380.19,614.05,379.28,614.09z"
              />
              <path
                d="M428,133.33c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C426.32,133.95,427.06,133.28,428,133.33z"
              />
              <path
                d="M454.18,437.69c-0.87,0.07-1.73-0.7-1.76-1.56c-0.04-0.84,0.79-1.74,1.63-1.7c0.9,0.04,1.47,0.54,1.59,1.45
	C455.76,436.81,455.08,437.62,454.18,437.69z"
              />
              <path
                d="M340.82,485.95c0.01,0.86-0.83,1.71-1.7,1.66c-0.92-0.05-1.47-0.56-1.57-1.49c-0.1-0.93,0.61-1.75,1.52-1.79
	C339.93,484.28,340.8,485.09,340.82,485.95z"
              />
              <path
                d="M389.95,261.21c-0.02,0.92-0.78,1.66-1.68,1.64c-0.86-0.03-1.67-0.86-1.65-1.71c0.02-0.88,0.81-1.62,1.72-1.61
	C389.29,259.54,389.97,260.26,389.95,261.21z"
              />
              <path
                d="M506.82,399.2c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C506.06,397.45,506.87,398.36,506.82,399.2z"
              />
              <path
                d="M250.6,403.44c0,0.85-0.85,1.69-1.72,1.63c-0.92-0.06-1.46-0.59-1.54-1.51c-0.09-0.91,0.65-1.73,1.54-1.77
	C249.74,401.76,250.59,402.58,250.6,403.44z"
              />
              <path
                d="M474.89,199.83c-0.04,0.92-0.82,1.66-1.72,1.61c-0.87-0.05-1.66-0.89-1.62-1.75c0.04-0.88,0.86-1.62,1.76-1.58
	C474.26,198.13,474.93,198.87,474.89,199.83z"
              />
              <path
                d="M381.8,334.15c-0.02,0.92-0.8,1.68-1.69,1.65c-0.87-0.03-1.67-0.86-1.66-1.72c0.02-0.88,0.82-1.63,1.73-1.62
	C381.13,332.46,381.83,333.19,381.8,334.15z"
              />
              <path
                d="M355.2,484.45c0.05,0.86-0.76,1.74-1.64,1.72c-0.92-0.02-1.49-0.52-1.62-1.44c-0.13-0.94,0.54-1.77,1.46-1.84
	C354.27,482.81,355.15,483.58,355.2,484.45z"
              />
              <path
                d="M422.79,574.53c0.91-0.05,1.71,0.71,1.69,1.64c-0.02,0.92-0.51,1.5-1.42,1.64c-0.88,0.13-1.76-0.61-1.84-1.5
	C421.13,575.46,421.91,574.57,422.79,574.53z"
              />
              <path
                d="M362.38,484.16c-0.07,0.88-0.94,1.61-1.81,1.52c-0.91-0.09-1.57-0.93-1.42-1.86c0.15-0.91,0.73-1.4,1.64-1.41
	C361.65,482.4,362.45,483.31,362.38,484.16z"
              />
              <path
                d="M408.19,595.72c-0.06,0.92-0.84,1.62-1.74,1.56c-0.87-0.06-1.64-0.91-1.58-1.76c0.06-0.88,0.88-1.59,1.78-1.54
	C407.59,594.03,408.24,594.77,408.19,595.72z"
              />
              <path
                d="M422.55,594.38c0.02,0.93-0.72,1.7-1.64,1.68c-0.87-0.02-1.72-0.87-1.61-1.72c0.11-0.88,0.63-1.46,1.54-1.56
	C421.73,592.68,422.53,593.46,422.55,594.38z"
              />
              <path
                d="M387.19,248.66c-0.92,0.04-1.7-0.71-1.67-1.65c0.03-0.93,0.54-1.49,1.44-1.63c0.86-0.14,1.76,0.64,1.83,1.52
	C388.86,247.76,388.08,248.62,387.19,248.66z"
              />
              <path
                d="M425.49,397.24c0.92-0.01,1.7,0.73,1.72,1.61c0.02,0.85-0.79,1.68-1.65,1.7c-0.89,0.02-1.65-0.72-1.67-1.64
	C423.86,397.97,424.55,397.25,425.49,397.24z"
              />
              <path
                d="M447.41,152.97c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C449.02,152.15,448.33,152.93,447.41,152.97z"
              />
              <path
                d="M507.66,595.84c-0.9,0.05-1.68-0.66-1.73-1.58c-0.05-0.95,0.61-1.68,1.56-1.73c0.9-0.04,1.72,0.68,1.77,1.55
	C509.3,594.94,508.53,595.79,507.66,595.84z"
              />
              <path
                d="M441.13,517.01c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C440.41,515.31,441.18,516.14,441.13,517.01z"
              />
              <path
                d="M448.26,516.32c-0.07,0.88-0.94,1.6-1.81,1.5c-0.9-0.1-1.56-0.95-1.4-1.85c0.16-0.91,0.74-1.38,1.64-1.4
	C447.54,514.56,448.33,515.48,448.26,516.32z"
              />
              <path
                d="M334.75,540.69c-0.01,0.94-0.78,1.69-1.7,1.62c-0.93-0.08-1.44-0.66-1.57-1.54c-0.12-0.85,0.73-1.73,1.6-1.76
	C333.99,538.99,334.76,539.76,334.75,540.69z"
              />
              <path
                d="M365.78,314.04c-0.05,0.85-0.9,1.62-1.76,1.58c-0.91-0.04-1.6-0.8-1.56-1.74c0.04-0.94,0.78-1.61,1.72-1.57
	C365.07,312.35,365.83,313.18,365.78,314.04z"
              />
              <path
                d="M297.46,379.17c-0.91,0.04-1.7-0.72-1.67-1.64c0.03-0.92,0.54-1.48,1.44-1.63c0.85-0.14,1.75,0.64,1.82,1.51
	C299.11,378.27,298.34,379.13,297.46,379.17z"
              />
              <path
                d="M339.63,404.68c0.85,0.01,1.67,0.87,1.63,1.71c-0.04,0.86-0.9,1.65-1.76,1.55c-0.9-0.11-1.42-0.65-1.47-1.56
	C337.98,405.47,338.76,404.67,339.63,404.68z"
              />
              <path
                d="M429.65,423.56c0.02-0.93,0.81-1.71,1.71-1.62c0.92,0.09,1.44,0.68,1.56,1.56c0.11,0.85-0.73,1.71-1.61,1.74
	C430.38,425.27,429.64,424.51,429.65,423.56z"
              />
              <path
                d="M515.89,606.2c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C514.24,607.12,515.02,606.24,515.89,606.2z"
              />
              <path
                d="M345.02,469.2c0.95-0.06,1.7,0.61,1.75,1.55c0.05,0.94-0.64,1.73-1.55,1.78c-0.86,0.05-1.73-0.72-1.79-1.57
	C343.37,470.1,344.13,469.26,345.02,469.2z"
              />
              <path
                d="M369.32,625.81c-0.93-0.03-1.64-0.81-1.55-1.76c0.09-0.93,0.63-1.45,1.55-1.53c0.86-0.07,1.73,0.78,1.73,1.64
	C371.05,625.02,370.19,625.84,369.32,625.81z"
              />
              <path
                d="M520.77,247.69c-0.87,0.01-1.7-0.82-1.68-1.67c0.02-0.86,0.89-1.69,1.75-1.6c0.91,0.1,1.44,0.63,1.51,1.55
	C522.43,246.91,521.7,247.68,520.77,247.69z"
              />
              <path
                d="M357.32,632.56c0,0.94-0.71,1.66-1.63,1.66c-0.89,0-1.69-0.77-1.69-1.64c0-0.89,0.76-1.64,1.67-1.65
	C356.61,630.91,357.32,631.61,357.32,632.56z"
              />
              <path
                d="M385.63,377.23c0.03,0.94-0.66,1.7-1.57,1.73c-0.86,0.03-1.71-0.74-1.75-1.6c-0.04-0.86,0.73-1.68,1.62-1.72
	C384.86,375.61,385.6,376.28,385.63,377.23z"
              />
              <path
                d="M433.24,446.82c-0.9,0.03-1.69-0.73-1.65-1.64c0.04-0.91,0.55-1.47,1.44-1.61c0.84-0.14,1.73,0.65,1.8,1.52
	C434.89,445.94,434.11,446.79,433.24,446.82z"
              />
              <path
                d="M513.72,604.5c0,0.91-0.77,1.69-1.67,1.62c-0.92-0.07-1.43-0.64-1.56-1.51c-0.12-0.83,0.71-1.72,1.57-1.75
	C512.93,602.81,513.72,603.6,513.72,604.5z"
              />
              <path
                d="M421.73,563.43c-0.92,0.03-1.7-0.72-1.66-1.66c0.03-0.92,0.55-1.49,1.45-1.62c0.86-0.13,1.75,0.65,1.82,1.53
	C423.4,562.54,422.61,563.4,421.73,563.43z"
              />
              <path
                d="M426.73,351.76c0.05,0.86-0.72,1.71-1.59,1.77c-0.9,0.06-1.69-0.65-1.75-1.58c-0.06-0.95,0.61-1.7,1.55-1.75
	C425.84,350.16,426.68,350.89,426.73,351.76z"
              />
              <path
                d="M395.2,611.33c-0.07,0.94-0.87,1.63-1.78,1.5c-0.92-0.13-1.4-0.75-1.47-1.62c-0.07-0.85,0.85-1.67,1.71-1.64
	C394.54,609.6,395.26,610.42,395.2,611.33z"
              />
              <path
                d="M317.47,466.26c0.02,0.94-0.72,1.7-1.64,1.69c-0.88-0.02-1.73-0.87-1.62-1.73c0.11-0.88,0.63-1.47,1.55-1.56
	C316.66,464.56,317.45,465.33,317.47,466.26z"
              />
              <path
                d="M421.91,587.24c-0.01,0.86-0.88,1.66-1.75,1.62c-0.94-0.05-1.62-0.83-1.52-1.78c0.1-0.93,0.66-1.44,1.57-1.5
	C421.07,585.52,421.92,586.39,421.91,587.24z"
              />
              <path
                d="M416.31,123.5c0.86-0.05,1.72,0.74,1.74,1.6c0.02,0.85-0.81,1.7-1.67,1.66c-0.91-0.05-1.46-0.55-1.57-1.47
	C414.71,124.39,415.42,123.55,416.31,123.5z"
              />
              <path
                d="M370.56,78.58c0.01,0.85-0.84,1.7-1.71,1.64c-0.92-0.06-1.46-0.57-1.55-1.5c-0.09-0.91,0.63-1.74,1.53-1.78
	C369.68,76.91,370.55,77.72,370.56,78.58z"
              />
              <path
                d="M372.46,621.97c-0.87-0.01-1.68-0.86-1.64-1.71c0.04-0.87,0.93-1.67,1.78-1.56c0.9,0.12,1.43,0.67,1.48,1.59
	C374.14,621.22,373.38,621.98,372.46,621.97z"
              />
              <path
                d="M358.64,377.53c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C360.25,376.71,359.55,377.49,358.64,377.53z"
              />
              <path
                d="M410.92,580.77c-0.02-0.95,0.69-1.68,1.63-1.68c0.91,0,1.7,0.76,1.71,1.64c0.01,0.86-0.8,1.69-1.67,1.71
	C411.7,582.45,410.93,581.69,410.92,580.77z"
              />
              <path
                d="M488.63,563.53c-0.04,0.86-0.88,1.64-1.75,1.62c-0.9-0.03-1.62-0.81-1.59-1.74c0.03-0.94,0.78-1.64,1.71-1.61
	C487.9,561.84,488.67,562.66,488.63,563.53z"
              />
              <path
                d="M471.26,548.62c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C469.61,549.54,470.4,548.66,471.26,548.62z"
              />
              <path
                d="M291.58,85.52c0.02,0.93-0.7,1.69-1.61,1.71c-0.87,0.01-1.7-0.78-1.72-1.63c-0.02-0.88,0.75-1.66,1.64-1.69
	C290.85,83.88,291.57,84.57,291.58,85.52z"
              />
              <path
                d="M582.34,625.32c-0.92-0.04-1.62-0.83-1.52-1.77c0.1-0.92,0.66-1.43,1.56-1.5c0.86-0.06,1.71,0.81,1.69,1.66
	C584.07,624.57,583.21,625.36,582.34,625.32z"
              />
              <path
                d="M407.54,588.5c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C406.82,586.81,407.59,587.64,407.54,588.5z"
              />
              <path
                d="M355.41,611.08c-0.06,0.92-0.85,1.64-1.75,1.57c-0.88-0.06-1.65-0.92-1.59-1.78c0.06-0.87,0.89-1.6,1.79-1.55
	C354.81,609.38,355.47,610.12,355.41,611.08z"
              />
              <path
                d="M363.35,411.58c0.01,0.85-0.82,1.7-1.68,1.64c-0.91-0.06-1.46-0.56-1.55-1.48c-0.1-0.9,0.63-1.73,1.51-1.78
	C362.47,409.92,363.33,410.72,363.35,411.58z"
              />
              <path
                d="M403.21,411.91c-0.04,0.85-0.89,1.62-1.75,1.6c-0.91-0.03-1.61-0.8-1.57-1.73c0.04-0.94,0.77-1.62,1.71-1.58
	C402.49,410.23,403.25,411.05,403.21,411.91z"
              />
              <path
                d="M241.69,382.67c-0.06,0.92-0.85,1.64-1.75,1.57c-0.88-0.06-1.65-0.92-1.59-1.78c0.06-0.87,0.89-1.6,1.79-1.55
	C241.08,380.97,241.75,381.72,241.69,382.67z"
              />
              <path
                d="M316.33,412.03c0.03,0.85-0.8,1.72-1.67,1.68c-0.92-0.04-1.47-0.55-1.58-1.46c-0.11-0.91,0.59-1.75,1.49-1.81
	C315.43,410.38,316.3,411.17,316.33,412.03z"
              />
              <path
                d="M553.7,627.72c-0.91,0.04-1.7-0.71-1.67-1.63c0.03-0.92,0.53-1.48,1.43-1.63c0.85-0.14,1.75,0.63,1.82,1.51
	C555.35,626.82,554.58,627.68,553.7,627.72z"
              />
              <path
                d="M404.83,599.41c0.03,0.93-0.68,1.7-1.59,1.72c-0.86,0.02-1.7-0.76-1.74-1.61c-0.03-0.87,0.74-1.67,1.63-1.7
	C404.07,597.78,404.8,598.47,404.83,599.41z"
              />
              <path
                d="M326.39,360.97c-0.15,0.88-1.08,1.55-1.93,1.39c-0.89-0.16-1.51-1.08-1.28-1.98c0.23-0.89,0.83-1.36,1.75-1.28
	C325.82,359.18,326.53,360.12,326.39,360.97z"
              />
              <path
                d="M426.2,344.85c0.03,0.85-0.79,1.71-1.66,1.67c-0.91-0.04-1.46-0.54-1.58-1.46c-0.11-0.9,0.6-1.74,1.48-1.8
	C425.31,343.21,426.17,343.99,426.2,344.85z"
              />
              <path
                d="M385.46,84.7c-0.06,0.85-0.93,1.6-1.79,1.55c-0.91-0.05-1.59-0.83-1.53-1.77c0.06-0.94,0.8-1.6,1.75-1.53
	C384.77,83.01,385.52,83.84,385.46,84.7z"
              />
              <path
                d="M324.42,66.38c0.91-0.05,1.71,0.71,1.69,1.64c-0.02,0.92-0.51,1.5-1.42,1.64c-0.88,0.13-1.76-0.61-1.84-1.5
	C322.76,67.31,323.55,66.43,324.42,66.38z"
              />
              <path
                d="M399.85,249.63c0.01,0.86-0.79,1.68-1.66,1.7c-0.89,0.02-1.65-0.73-1.67-1.65c-0.02-0.95,0.68-1.66,1.62-1.67
	C399.06,248.01,399.84,248.74,399.85,249.63z"
              />
              <path
                d="M388.8,413.02c0.01,0.86-0.84,1.71-1.72,1.65c-0.92-0.06-1.47-0.57-1.56-1.5c-0.09-0.93,0.62-1.74,1.53-1.78
	C387.92,411.35,388.79,412.17,388.8,413.02z"
              />
              <path
                d="M275.12,244.16c0.95,0.03,1.63,0.76,1.59,1.71c-0.03,0.92-0.81,1.66-1.71,1.62c-0.88-0.04-1.66-0.88-1.63-1.74
	C273.41,244.88,274.22,244.14,275.12,244.16z"
              />
              <path
                d="M317.74,323.3c-0.87-0.03-1.67-0.86-1.65-1.71c0.02-0.88,0.81-1.62,1.72-1.61c0.94,0.01,1.63,0.73,1.61,1.68
	C319.39,322.59,318.63,323.33,317.74,323.3z"
              />
              <path
                d="M434.38,437.99c0,0.85-0.85,1.69-1.72,1.63c-0.92-0.06-1.46-0.59-1.54-1.51c-0.09-0.91,0.65-1.73,1.54-1.77
	C433.52,436.31,434.37,437.13,434.38,437.99z"
              />
              <path
                d="M350.43,616.68c-0.87,0.01-1.69-0.82-1.67-1.67c0.02-0.86,0.89-1.68,1.74-1.59c0.89,0.1,1.43,0.63,1.5,1.55
	C352.07,615.9,351.34,616.67,350.43,616.68z"
              />
              <path
                d="M464.69,433.18c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.72,1.56
	C466.29,432.37,465.6,433.14,464.69,433.18z"
              />
              <path
                d="M365.48,453.15c0.85-0.03,1.71,0.79,1.71,1.64c0,0.85-0.85,1.69-1.71,1.62c-0.91-0.07-1.45-0.58-1.54-1.5
	C363.86,454,364.6,453.18,365.48,453.15z"
              />
              <path
                d="M350.02,71.11c-0.87,0.05-1.73-0.74-1.75-1.6c-0.02-0.85,0.82-1.73,1.68-1.68c0.91,0.06,1.47,0.56,1.58,1.48
	C351.63,70.25,350.94,71.05,350.02,71.11z"
              />
              <path
                d="M310.44,360.29c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C308.76,360.91,309.5,360.24,310.44,360.29z"
              />
              <path
                d="M330.9,537.44c-0.07,0.94-0.87,1.63-1.78,1.5c-0.92-0.13-1.4-0.75-1.47-1.62c-0.07-0.85,0.85-1.67,1.71-1.64
	C330.24,535.7,330.96,536.52,330.9,537.44z"
              />
              <path
                d="M255.91,255.01c-0.04,0.86-0.89,1.64-1.76,1.61c-0.91-0.03-1.62-0.81-1.58-1.75c0.03-0.94,0.78-1.63,1.72-1.59
	C255.18,253.31,255.95,254.14,255.91,255.01z"
              />
              <path
                d="M301.39,339.41c0.89-0.05,1.7,0.72,1.68,1.64c-0.02,0.91-0.51,1.49-1.42,1.63c-0.87,0.14-1.75-0.61-1.83-1.49
	C299.74,340.34,300.52,339.46,301.39,339.41z"
              />
              <path
                d="M433.59,430.69c0.06,0.95-0.64,1.72-1.56,1.74c-0.87,0.02-1.77-0.8-1.69-1.65c0.08-0.87,0.57-1.48,1.49-1.61
	C432.7,429.03,433.53,429.77,433.59,430.69z"
              />
              <path
                d="M464.91,184.42c0.87,0.03,1.67,0.91,1.61,1.76c-0.06,0.88-0.94,1.64-1.82,1.53c-0.92-0.12-1.42-0.69-1.45-1.61
	C463.22,185.16,464.01,184.38,464.91,184.42z"
              />
              <path
                d="M332.23,322.06c-0.87,0.03-1.72-0.78-1.72-1.64c0-0.86,0.86-1.71,1.72-1.63c0.91,0.08,1.45,0.6,1.54,1.52
	C333.86,321.25,333.15,322.03,332.23,322.06z"
              />
              <path
                d="M441.29,373.88c-0.91-0.03-1.62-0.82-1.53-1.75c0.09-0.92,0.65-1.43,1.54-1.51c0.85-0.07,1.7,0.79,1.7,1.63
	C443,373.11,442.15,373.91,441.29,373.88z"
              />
              <path
                d="M283.79,181.74c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.72,1.56
	C285.39,180.94,284.7,181.71,283.79,181.74z"
              />
              <path
                d="M396.79,609.01c-0.91,0.04-1.7-0.71-1.67-1.63c0.03-0.92,0.53-1.48,1.43-1.63c0.85-0.14,1.75,0.63,1.82,1.51
	C398.44,608.11,397.67,608.97,396.79,609.01z"
              />
              <path
                d="M420.22,126.84c0.87-0.01,1.71,0.83,1.69,1.69c-0.02,0.86-0.89,1.68-1.76,1.6c-0.92-0.08-1.45-0.62-1.51-1.55
	C418.57,127.64,419.31,126.85,420.22,126.84z"
              />
              <path
                d="M312.8,428.65c0.87,0.03,1.67,0.91,1.61,1.76c-0.06,0.88-0.94,1.64-1.82,1.53c-0.92-0.12-1.42-0.69-1.45-1.61
	C311.11,429.4,311.9,428.62,312.8,428.65z"
              />
              <path
                d="M311.47,414.26c0.9-0.04,1.7,0.72,1.68,1.65c-0.02,0.92-0.52,1.5-1.44,1.63c-0.88,0.13-1.76-0.62-1.83-1.51
	C309.81,415.18,310.6,414.3,311.47,414.26z"
              />
              <path
                d="M500.28,240.71c0.04,0.93-0.68,1.7-1.59,1.7c-0.86,0-1.73-0.83-1.64-1.68c0.09-0.87,0.6-1.46,1.5-1.58
	C499.42,239.04,500.24,239.8,500.28,240.71z"
              />
              <path
                d="M425.9,590.8c-0.13,0.93-0.98,1.58-1.88,1.38c-0.89-0.2-1.36-0.81-1.35-1.71c0.01-0.87,0.94-1.62,1.8-1.53
	C425.37,589.03,426.02,589.89,425.9,590.8z"
              />
              <path
                d="M591.31,622.93c0.02,0.85-0.78,1.68-1.65,1.71c-0.89,0.03-1.65-0.71-1.68-1.64c-0.02-0.95,0.66-1.67,1.61-1.68
	C590.51,621.31,591.29,622.05,591.31,622.93z"
              />
              <path
                d="M400.34,462.79c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C399.62,461.09,400.39,461.92,400.34,462.79z"
              />
              <path
                d="M423.99,569.05c-0.03,0.92-0.82,1.65-1.72,1.55c-0.92-0.1-1.41-0.69-1.51-1.56c-0.09-0.83,0.78-1.69,1.63-1.7
	C423.26,567.34,424.02,568.15,423.99,569.05z"
              />
              <path
                d="M401.23,453.34c-0.87-0.02-1.67-0.85-1.65-1.71c0.02-0.89,0.8-1.62,1.72-1.61c0.94,0.01,1.63,0.73,1.61,1.68
	C402.88,452.62,402.13,453.37,401.23,453.34z"
              />
              <path
                d="M402.01,251.12c0.86,0.01,1.68,0.87,1.64,1.72c-0.04,0.87-0.91,1.66-1.78,1.56c-0.92-0.1-1.43-0.65-1.48-1.57
	C400.35,251.9,401.12,251.11,402.01,251.12z"
              />
              <path
                d="M420.63,572.95c-0.07,0.95-0.87,1.64-1.79,1.5c-0.93-0.13-1.4-0.76-1.47-1.63c-0.07-0.85,0.85-1.68,1.72-1.65
	C419.99,571.21,420.7,572.02,420.63,572.95z"
              />
              <path
                d="M417.01,589.41c0.9-0.06,1.71,0.69,1.7,1.62c-0.01,0.92-0.49,1.5-1.41,1.65c-0.88,0.14-1.77-0.59-1.86-1.48
	C415.37,590.35,416.14,589.46,417.01,589.41z"
              />
              <path
                d="M249.62,349.3c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C248.86,347.55,249.67,348.45,249.62,349.3z"
              />
              <path
                d="M300.37,451.46c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C298.68,452.08,299.42,451.41,300.37,451.46z"
              />
              <path
                d="M369.54,416.67c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C367.89,417.58,368.67,416.71,369.54,416.67z"
              />
              <path
                d="M474.91,425.55c0.92-0.01,1.7,0.73,1.72,1.61c0.02,0.85-0.79,1.68-1.65,1.7c-0.89,0.02-1.65-0.72-1.67-1.64
	C473.28,426.28,473.97,425.56,474.91,425.55z"
              />
              <path
                d="M325.36,532.3c0.9-0.06,1.71,0.69,1.7,1.62c-0.01,0.92-0.49,1.5-1.41,1.65c-0.88,0.14-1.77-0.59-1.86-1.48
	C323.72,533.25,324.49,532.36,325.36,532.3z"
              />
              <path
                d="M432.01,535.6c0.02,0.86-0.78,1.7-1.65,1.73c-0.89,0.03-1.66-0.72-1.69-1.64c-0.03-0.95,0.67-1.68,1.61-1.7
	C431.19,533.97,431.99,534.72,432.01,535.6z"
              />
              <path
                d="M562.47,625.72c-0.07,0.88-0.94,1.6-1.81,1.5c-0.9-0.1-1.56-0.95-1.4-1.86c0.16-0.91,0.74-1.38,1.64-1.4
	C561.74,623.96,562.53,624.88,562.47,625.72z"
              />
              <path
                d="M528.13,271.02c-0.01,0.94-0.78,1.69-1.7,1.62c-0.93-0.08-1.45-0.66-1.57-1.54c-0.12-0.85,0.73-1.73,1.6-1.76
	C527.36,269.32,528.13,270.1,528.13,271.02z"
              />
              <path
                d="M323.04,364.66c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C322.28,362.9,323.09,363.81,323.04,364.66z"
              />
              <path
                d="M600.71,623.95c0.86,0.03,1.66,0.91,1.6,1.76c-0.06,0.88-0.93,1.64-1.81,1.52c-0.91-0.13-1.41-0.69-1.45-1.61
	C599.02,624.71,599.83,623.92,600.71,623.95z"
              />
              <path
                d="M358.12,599.9c0.02,0.86-0.78,1.7-1.65,1.73c-0.89,0.03-1.66-0.72-1.69-1.64c-0.03-0.95,0.67-1.68,1.61-1.7
	C357.29,598.27,358.1,599.02,358.12,599.9z"
              />
              <path
                d="M459.3,186.66c-0.07,0.88-0.93,1.6-1.8,1.51c-0.91-0.1-1.56-0.93-1.41-1.85c0.15-0.91,0.74-1.39,1.63-1.41
	C458.57,184.9,459.36,185.82,459.3,186.66z"
              />
              <path
                d="M423.52,252.49c-0.92-0.04-1.62-0.84-1.52-1.77c0.1-0.92,0.66-1.43,1.56-1.5c0.86-0.06,1.71,0.81,1.69,1.66
	C425.24,251.73,424.39,252.53,423.52,252.49z"
              />
              <path
                d="M426.21,573.98c-0.86-0.01-1.67-0.86-1.63-1.7c0.04-0.86,0.92-1.67,1.77-1.55c0.89,0.12,1.42,0.66,1.47,1.58
	C427.87,573.22,427.11,573.99,426.21,573.98z"
              />
              <path
                d="M472.29,543.03c0.03,0.85-0.8,1.72-1.68,1.69c-0.92-0.04-1.48-0.54-1.59-1.47c-0.11-0.93,0.58-1.76,1.49-1.82
	C471.38,541.37,472.26,542.16,472.29,543.03z"
              />
              <path
                d="M351.53,607.86c0,0.91-0.78,1.68-1.66,1.61c-0.91-0.07-1.42-0.64-1.55-1.51c-0.11-0.83,0.72-1.71,1.56-1.74
	C350.73,606.18,351.53,606.98,351.53,607.86z"
              />
              <path
                d="M442.22,570.92c-0.01,0.9-0.79,1.67-1.68,1.6c-0.91-0.08-1.42-0.65-1.54-1.52c-0.11-0.83,0.73-1.71,1.57-1.73
	C441.43,569.24,442.22,570.04,442.22,570.92z"
              />
              <path
                d="M447.77,568.79c0.86-0.03,1.67,0.77,1.64,1.65c-0.03,0.91-0.54,1.46-1.43,1.59c-0.86,0.13-1.72-0.63-1.79-1.5
	C446.13,569.7,446.93,568.82,447.77,568.79z"
              />
              <path
                d="M380.6,320.05c-0.06,0.87-0.92,1.6-1.78,1.52c-0.9-0.09-1.56-0.92-1.42-1.83c0.14-0.9,0.73-1.38,1.61-1.41
	C379.85,318.3,380.65,319.21,380.6,320.05z"
              />
              <path
                d="M445.39,567.09c-0.01,0.85-0.86,1.64-1.72,1.6c-0.9-0.04-1.6-0.85-1.5-1.76c0.1-0.91,0.66-1.41,1.55-1.48
	C444.55,565.38,445.4,566.25,445.39,567.09z"
              />
              <path
                d="M436.27,419.73c0.01,0.85-0.82,1.69-1.67,1.64c-0.9-0.06-1.45-0.56-1.55-1.47c-0.1-0.89,0.62-1.72,1.5-1.77
	C435.4,418.08,436.26,418.88,436.27,419.73z"
              />
              <path
                d="M330.72,473.68c-0.86-0.01-1.66-0.86-1.61-1.7c0.05-0.86,0.92-1.66,1.76-1.54c0.88,0.13,1.41,0.66,1.46,1.57
	C332.37,472.93,331.61,473.7,330.72,473.68z"
              />
              <path
                d="M380.75,256.31c-0.86,0.06-1.71-0.7-1.75-1.56c-0.03-0.83,0.8-1.73,1.63-1.68c0.89,0.05,1.46,0.54,1.58,1.45
	C382.33,255.43,381.65,256.24,380.75,256.31z"
              />
              <path
                d="M391.62,89.43c0.86-0.03,1.67,0.76,1.65,1.64c-0.03,0.91-0.54,1.46-1.43,1.6c-0.86,0.13-1.73-0.62-1.79-1.5
	C389.99,90.34,390.78,89.46,391.62,89.43z"
              />
              <path
                d="M400.03,106.6c-0.9-0.03-1.62-0.82-1.53-1.73c0.09-0.91,0.64-1.42,1.52-1.51c0.84-0.08,1.7,0.78,1.7,1.62
	C401.73,105.83,400.89,106.63,400.03,106.6z"
              />
              <path
                d="M435.5,412.57c0,0.91-0.78,1.68-1.67,1.61c-0.91-0.08-1.42-0.64-1.54-1.51c-0.11-0.83,0.72-1.71,1.56-1.74
	C434.71,410.89,435.5,411.68,435.5,412.57z"
              />
              <path
                d="M307.27,490.58c0.89,0.03,1.62,0.83,1.54,1.74c-0.08,0.91-0.62,1.43-1.52,1.5c-0.86,0.07-1.7-0.75-1.71-1.6
	C305.58,491.38,306.43,490.55,307.27,490.58z"
              />
              <path
                d="M427.15,565.17c-0.01,0.85-0.86,1.64-1.72,1.6c-0.9-0.04-1.6-0.85-1.5-1.76c0.1-0.91,0.66-1.41,1.55-1.48
	C426.32,563.46,427.16,564.33,427.15,565.17z"
              />
              <path
                d="M630.52,368.54c-0.07-0.05-0.14-0.11-0.21-0.17c0-1.29-0.01-2.57-0.01-3.86c0,0-0.01-0.01-0.01-0.01
	c0.73,0,1.57-0.02,1.72,0.89c0.15,0.92,0.15,1.89,0.01,2.8C631.98,368.38,631.04,368.42,630.52,368.54z"
              />
              <path
                d="M309.46,40.31c-0.03,1.38-0.81,2.06-1.76,1.98c-0.89-0.08-1.24-0.62-1.25-1.45c-0.01-1.07,0.67-1.93,1.54-1.67
	C308.63,39.36,309.11,40.02,309.46,40.31z"
              />
              <path
                d="M438.18,441.43c-0.05,0.87-0.9,1.61-1.77,1.53c-0.9-0.08-1.57-0.91-1.43-1.81c0.13-0.91,0.71-1.39,1.6-1.43
	C437.41,439.69,438.22,440.6,438.18,441.43z"
              />
              <path
                d="M423.02,601.62c0,0.91-0.78,1.68-1.66,1.61c-0.91-0.07-1.42-0.64-1.55-1.51c-0.12-0.83,0.72-1.71,1.56-1.74
	C422.23,599.94,423.02,600.74,423.02,601.62z"
              />
              <path
                d="M274.27,177.37c0.04,0.92-0.68,1.69-1.58,1.69c-0.86,0-1.72-0.83-1.63-1.67c0.09-0.87,0.6-1.45,1.5-1.57
	C273.41,175.71,274.23,176.47,274.27,177.37z"
              />
              <path
                d="M297.3,442.75c0.02,0.91-0.73,1.68-1.62,1.66c-0.86-0.02-1.69-0.87-1.59-1.71c0.11-0.87,0.63-1.44,1.52-1.54
	C296.47,441.07,297.28,441.86,297.3,442.75z"
              />
              <path
                d="M435.87,435.77c-0.86,0.03-1.7-0.78-1.69-1.63c0-0.84,0.87-1.69,1.71-1.61c0.89,0.09,1.44,0.6,1.52,1.51
	C437.49,434.95,436.77,435.74,435.87,435.77z"
              />
              <path
                d="M296.94,598.27c-0.03-1.27,0.71-2.14,1.56-2.08c0.9,0.06,1.46,0.59,1.55,1.51c0.09,0.9-0.68,1.73-1.59,1.7
	C297.53,599.37,296.97,598.91,296.94,598.27z"
              />
              <path
                d="M303.53,514.75c-0.34,0.26-0.85,0.87-1.48,1.05c-0.8,0.22-1.66-0.74-1.59-1.62c0.07-0.89,0.55-1.47,1.49-1.51
	C302.88,512.61,303.58,513.36,303.53,514.75z"
              />
              <path
                d="M479.92,423.22c-0.07,0.87-0.93,1.6-1.79,1.5c-0.9-0.1-1.55-0.94-1.4-1.84c0.15-0.9,0.74-1.38,1.63-1.4
	C479.19,421.47,479.98,422.39,479.92,423.22z"
              />
              <path
                d="M453.86,514.08c0.84,0.02,1.64,0.9,1.59,1.73c-0.06,0.87-0.92,1.63-1.78,1.51c-0.89-0.13-1.4-0.67-1.44-1.58
	C452.2,514.86,453.01,514.06,453.86,514.08z"
              />
              <path
                d="M431.94,203.46c-0.07,0.87-0.93,1.6-1.79,1.5c-0.9-0.1-1.55-0.94-1.4-1.84c0.15-0.9,0.74-1.38,1.63-1.39
	C431.21,201.71,432,202.63,431.94,203.46z"
              />
              <path
                d="M391.83,615.07c-0.01,0.9-0.79,1.67-1.68,1.6c-0.91-0.08-1.42-0.65-1.54-1.52c-0.11-0.83,0.73-1.71,1.57-1.73
	C391.05,613.38,391.84,614.18,391.83,615.07z"
              />
              <path
                d="M425,556.32c0.84-0.02,1.68,0.82,1.67,1.66c-0.01,0.85-0.86,1.67-1.72,1.58c-0.9-0.09-1.43-0.61-1.5-1.52
	C423.38,557.15,424.13,556.33,425,556.32z"
              />
              <path
                d="M387.42,371.58c0.87,0.03,1.61,0.86,1.52,1.75c-0.09,0.91-0.63,1.42-1.53,1.49c-0.86,0.07-1.7-0.77-1.69-1.62
	C385.72,372.37,386.58,371.55,387.42,371.58z"
              />
              <path
                d="M400.5,422.84c0.02,0.92-0.69,1.67-1.59,1.69c-0.85,0.01-1.69-0.78-1.71-1.61c-0.03-0.85,0.75-1.64,1.64-1.67
	C399.76,421.22,400.48,421.91,400.5,422.84z"
              />
              <path
                d="M367.8,422.69c-0.36,0.26-0.9,0.87-1.55,1.03c-0.83,0.21-1.72-0.8-1.61-1.66c0.11-0.9,0.67-1.45,1.58-1.52
	C367.09,420.47,367.88,421.36,367.8,422.69z"
              />
              <path
                d="M441.33,586.89c-0.26-0.37-0.87-0.91-1.04-1.56c-0.22-0.85,0.75-1.74,1.62-1.65c0.91,0.1,1.45,0.68,1.53,1.57
	C443.52,586.13,442.64,586.95,441.33,586.89z"
              />
              <path
                d="M384.17,4.02c0.92-0.03,1.63,0.31,1.66,1.29c0.03,0.9-0.51,1.49-1.47,1.55c-0.94,0.05-1.53-0.38-1.64-1.34
	C382.62,4.63,383.2,4.05,384.17,4.02z"
              />
              <path
                d="M300.52,185.91c-0.05,0.83-0.9,1.6-1.75,1.57c-0.91-0.03-1.59-0.78-1.55-1.72c0.04-0.94,0.76-1.6,1.7-1.55
	C299.79,184.26,300.56,185.08,300.52,185.91z"
              />
              <path
                d="M383.35,249.23c1.28,0,2.14,0.89,2.02,1.74c-0.13,0.89-0.66,1.45-1.59,1.5c-0.91,0.04-1.8-0.84-1.54-1.71
	C382.43,250.11,383.06,249.6,383.35,249.23z"
              />
              <path
                d="M409.88,593.44c-0.85-0.02-1.66-0.84-1.65-1.68c0.01-0.86,0.79-1.61,1.69-1.6c0.94,0.01,1.63,0.71,1.61,1.65
	C411.52,592.72,410.77,593.46,409.88,593.44z"
              />
              <path
                d="M436.84,427.63c-0.38,0.22-0.97,0.78-1.63,0.88c-0.86,0.13-1.64-0.94-1.45-1.79c0.2-0.88,0.76-1.41,1.7-1.37
	C436.36,425.38,437.04,426.29,436.84,427.63z"
              />
              <path
                d="M468.4,539.64c0.05,0.87-0.78,1.75-1.66,1.68c-0.92-0.07-1.48-0.59-1.54-1.53c-0.05-0.94,0.49-1.5,1.38-1.68
	C467.43,537.95,468.35,538.76,468.4,539.64z"
              />
              <path
                d="M376.07,252.92c-0.26-0.37-0.87-0.91-1.04-1.56c-0.22-0.85,0.75-1.74,1.62-1.65c0.91,0.1,1.45,0.68,1.53,1.57
	C378.27,252.16,377.38,252.99,376.07,252.92z"
              />
              <path
                d="M330.92,371.01c0.02,0.93-0.68,1.68-1.58,1.7c-0.85,0.02-1.69-0.76-1.73-1.6c-0.03-0.85,0.73-1.65,1.62-1.68
	C330.18,369.4,330.9,370.07,330.92,371.01z"
              />
              <path
                d="M328.27,69.74c1.29,0.1,2.08,1.03,1.89,1.87c-0.19,0.87-0.74,1.41-1.69,1.38c-0.91-0.03-1.74-0.96-1.42-1.81
	C327.3,70.56,327.97,70.09,328.27,69.74z"
              />
              <path
                d="M417.4,347.54c-0.71-0.88-1.36-1.37-1.57-2.01c-0.28-0.84,0.6-1.75,1.49-1.77c0.86-0.02,1.86,0.89,1.61,1.68
	C418.72,346.09,418.1,346.62,417.4,347.54z"
              />
              <path
                d="M396.48,253.51c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C395.71,251.88,396.5,252.66,396.48,253.51z"
              />
              <path
                d="M518.56,362.14c-0.87,0.73-1.35,1.38-1.99,1.6c-0.81,0.28-1.74-0.65-1.76-1.53c-0.02-0.86,0.9-1.85,1.69-1.6
	C517.15,360.81,517.66,361.44,518.56,362.14z"
              />
              <path
                d="M327.1,320.89c-0.88,0.72-1.37,1.36-2.01,1.58c-0.81,0.27-1.74-0.67-1.74-1.54c-0.01-0.87,0.92-1.84,1.71-1.59
	C325.71,319.55,326.21,320.18,327.1,320.89z"
              />
              <path
                d="M459.54,432.19c-0.87,0.73-1.35,1.38-1.99,1.6c-0.81,0.28-1.74-0.65-1.76-1.53c-0.02-0.86,0.9-1.85,1.69-1.6
	C458.13,430.86,458.64,431.49,459.54,432.19z"
              />
              <path
                d="M238.17,193.09c0.89,0.02,1.64,0.88,1.53,1.79c-0.12,0.93-0.7,1.46-1.62,1.44c-0.93-0.02-1.45-0.64-1.56-1.53
	C236.42,193.93,237.31,193.07,238.17,193.09z"
              />
              <path
                d="M323.24,491.04c-0.02,0.91-0.77,1.65-1.66,1.63c-0.85-0.02-1.66-0.84-1.65-1.68c0.01-0.87,0.8-1.61,1.7-1.6
	C322.58,489.4,323.26,490.1,323.24,491.04z"
              />
              <path
                d="M390.42,474.18c0.02,0.92-0.69,1.67-1.59,1.69c-0.85,0.01-1.69-0.78-1.71-1.61c-0.03-0.85,0.75-1.64,1.64-1.67
	C389.69,472.57,390.4,473.25,390.42,474.18z"
              />
              <path
                d="M271.17,197.29c-1.31-0.01-2.14-0.88-2.02-1.74c0.13-0.89,0.69-1.44,1.6-1.49c0.89-0.05,1.79,0.87,1.52,1.73
	C272.09,196.42,271.45,196.93,271.17,197.29z"
              />
              <path
                d="M458.86,511.66c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C458.09,510.03,458.87,510.81,458.86,511.66z"
              />
              <path
                d="M442.26,357.87c-0.89,0.7-1.39,1.34-2.03,1.55c-0.81,0.26-1.73-0.7-1.72-1.57c0.01-0.87,0.95-1.83,1.74-1.56
	C440.89,356.51,441.38,357.15,442.26,357.87z"
              />
              <path
                d="M403.84,109.75c-0.91-0.04-1.58-0.8-1.54-1.74c0.05-0.93,0.78-1.59,1.72-1.53c0.89,0.05,1.64,0.86,1.58,1.72
	C405.55,109.04,404.69,109.79,403.84,109.75z"
              />
              <path
                d="M306.28,376.89c-0.05,0.83-0.9,1.6-1.75,1.57c-0.91-0.03-1.59-0.78-1.55-1.72c0.04-0.94,0.76-1.6,1.7-1.55
	C305.55,375.23,306.32,376.05,306.28,376.89z"
              />
              <path
                d="M254.74,137.85c-0.71-0.88-1.36-1.37-1.57-2.01c-0.28-0.84,0.6-1.75,1.49-1.77c0.86-0.02,1.86,0.89,1.61,1.68
	C256.06,136.41,255.43,136.93,254.74,137.85z"
              />
              <path
                d="M482.75,558.49c0.94-0.05,1.67,0.6,1.72,1.53c0.05,0.94-0.62,1.69-1.54,1.74c-0.86,0.04-1.71-0.71-1.77-1.55
	C481.11,559.35,481.86,558.54,482.75,558.49z"
              />
              <path
                d="M254.63,406.58c-0.01,0.91-0.76,1.66-1.65,1.64c-0.85-0.01-1.66-0.83-1.66-1.67c0-0.86,0.79-1.61,1.69-1.61
	C253.95,404.94,254.64,405.64,254.63,406.58z"
              />
              <path
                d="M409.44,111.47c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C408.66,109.85,409.45,110.62,409.44,111.47z"
              />
              <path
                d="M366.25,487.13c0.02,0.85-0.76,1.67-1.62,1.7c-0.89,0.03-1.65-0.7-1.68-1.61c-0.03-0.94,0.64-1.65,1.59-1.67
	C365.44,485.54,366.23,486.26,366.25,487.13z"
              />
              <path
                d="M389.71,467.29c-0.02,0.88-0.91,1.69-1.77,1.56c-0.91-0.14-1.44-0.7-1.42-1.63c0.02-0.93,0.59-1.47,1.49-1.57
	C388.86,465.55,389.73,466.43,389.71,467.29z"
              />
              <path
                d="M256.07,401.11c0.91-0.03,1.7,0.69,1.74,1.55c0.03,0.85-0.74,1.68-1.61,1.72c-0.89,0.04-1.66-0.67-1.7-1.58
	C254.46,401.85,255.12,401.14,256.07,401.11z"
              />
              <path
                d="M332.69,488.04c-1.31,0.07-2.21-0.75-2.12-1.62c0.08-0.89,0.61-1.48,1.53-1.58c0.87-0.1,1.84,0.78,1.62,1.64
	C333.55,487.13,332.94,487.67,332.69,488.04z"
              />
              <path
                d="M478.91,555.13c0.94-0.05,1.67,0.6,1.72,1.53c0.05,0.94-0.62,1.69-1.54,1.74c-0.85,0.04-1.71-0.71-1.76-1.55
	C477.27,555.99,478.02,555.18,478.91,555.13z"
              />
              <path
                d="M419.93,605.37c0.05,0.87-0.77,1.75-1.65,1.69c-0.92-0.06-1.49-0.59-1.54-1.52c-0.05-0.94,0.48-1.51,1.37-1.68
	C418.96,603.68,419.88,604.49,419.93,605.37z"
              />
              <path
                d="M664.23,631.02c0.06,0.85-0.69,1.69-1.56,1.75c-0.9,0.07-1.68-0.62-1.74-1.53c-0.06-0.96,0.57-1.67,1.52-1.73
	C663.36,629.46,664.18,630.15,664.23,631.02z"
              />
              <path
                d="M429.7,554.63c-0.36,0.26-0.89,0.87-1.54,1.05c-0.83,0.22-1.73-0.78-1.62-1.65c0.11-0.9,0.66-1.46,1.57-1.53
	C428.97,552.42,429.77,553.3,429.7,554.63z"
              />
              <path
                d="M461.38,168.84c-0.36,0.26-0.89,0.88-1.54,1.05c-0.83,0.22-1.73-0.78-1.63-1.65c0.11-0.9,0.66-1.46,1.57-1.54
	C460.64,166.63,461.44,167.51,461.38,168.84z"
              />
              <path
                d="M399.98,435.66c0.91-0.05,1.72,0.64,1.78,1.51c0.05,0.85-0.7,1.69-1.56,1.75c-0.9,0.06-1.68-0.62-1.73-1.54
	C398.4,436.42,399.03,435.71,399.98,435.66z"
              />
              <path
                d="M384.28,339.13c-0.89,0.02-1.64-0.72-1.66-1.63c-0.02-0.94,0.66-1.64,1.61-1.65c0.9-0.01,1.69,0.73,1.7,1.6
	C385.94,338.29,385.14,339.11,384.28,339.13z"
              />
              <path
                d="M342.29,393.66c0.89,0.02,1.64,0.88,1.53,1.79c-0.12,0.93-0.7,1.46-1.62,1.44c-0.93-0.02-1.45-0.64-1.56-1.53
	C340.55,394.5,341.44,393.64,342.29,393.66z"
              />
              <path
                d="M351.08,394.62c-0.03,0.91-0.79,1.64-1.68,1.6c-0.86-0.03-1.64-0.86-1.62-1.71c0.02-0.87,0.82-1.59,1.72-1.57
	C350.44,392.97,351.11,393.68,351.08,394.62z"
              />
              <path
                d="M457.45,220.77c-1.31,0.07-2.21-0.75-2.12-1.62c0.08-0.89,0.61-1.48,1.53-1.58c0.87-0.1,1.84,0.78,1.62,1.64
	C458.31,219.86,457.7,220.4,457.45,220.77z"
              />
              <path
                d="M433.71,533.46c-0.89,0.02-1.64-0.72-1.66-1.63c-0.02-0.94,0.66-1.64,1.6-1.65c0.9-0.01,1.69,0.73,1.7,1.6
	C435.36,532.62,434.56,533.44,433.71,533.46z"
              />
              <path
                d="M380.12,478.77c0,0.87-0.87,1.71-1.74,1.6c-0.91-0.11-1.46-0.67-1.46-1.6c0-0.93,0.56-1.48,1.46-1.61
	C379.23,477.05,380.12,477.91,380.12,478.77z"
              />
              <path
                d="M232.87,215.4c0.95,0.01,1.62,0.71,1.6,1.66c-0.02,0.91-0.78,1.65-1.66,1.63c-0.85-0.02-1.65-0.85-1.64-1.69
	C231.18,216.13,231.97,215.39,232.87,215.4z"
              />
              <path
                d="M437.68,580.29c1.29,0.1,2.08,1.03,1.89,1.87c-0.19,0.87-0.74,1.41-1.69,1.38c-0.91-0.03-1.74-0.96-1.42-1.81
	C436.71,581.1,437.37,580.64,437.68,580.29z"
              />
              <path
                d="M363.71,442.02c-0.29-0.36-0.93-0.85-1.14-1.49c-0.28-0.86,0.6-1.78,1.5-1.75c0.92,0.03,1.48,0.58,1.63,1.46
	C365.83,441.1,365.01,441.98,363.71,442.02z"
              />
              <path
                d="M248.79,216.37c-0.37,0.25-0.91,0.86-1.56,1.02c-0.83,0.21-1.71-0.81-1.59-1.68c0.12-0.9,0.68-1.45,1.59-1.51
	C248.11,214.15,248.88,215.04,248.79,216.37z"
              />
              <path
                d="M282.16,409.77c0.96-0.06,1.71,0.6,1.72,1.55c0.01,0.94-0.49,1.49-1.39,1.64c-0.9,0.15-1.76-0.54-1.87-1.43
	C280.52,410.65,281.24,409.83,282.16,409.77z"
              />
              <path
                d="M381.62,346.91c0.96,0.01,1.65,0.72,1.58,1.67c-0.06,0.93-0.61,1.43-1.51,1.51c-0.89,0.08-1.72-0.69-1.75-1.55
	C379.92,347.67,380.7,346.91,381.62,346.91z"
              />
              <path
                d="M238.6,366.59c0.95,0,1.66,0.71,1.6,1.65c-0.05,0.93-0.59,1.44-1.49,1.53c-0.89,0.09-1.73-0.67-1.77-1.53
	C236.91,367.38,237.7,366.59,238.6,366.59z"
              />
              <path
                d="M452.62,440.25c-0.06,0.87-0.94,1.61-1.82,1.53c-0.93-0.08-1.59-0.9-1.46-1.84c0.13-0.95,0.75-1.38,1.65-1.4
	C451.89,438.53,452.68,439.38,452.62,440.25z"
              />
              <path
                d="M430.81,544.46c-0.93,0.01-1.66-0.73-1.62-1.67c0.04-0.94,0.56-1.45,1.48-1.54c0.94-0.08,1.75,0.62,1.78,1.52
	C432.49,543.64,431.7,544.45,430.81,544.46z"
              />
              <path
                d="M446.57,574.21c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C445.16,572.9,445.67,573.53,446.57,574.21z"
              />
              <path
                d="M669.71,629.04c0.92-0.02,1.73,0.74,1.71,1.62c-0.01,0.86-0.87,1.69-1.74,1.61c-0.9-0.08-1.48-0.57-1.55-1.52
	C668.07,629.79,668.75,629.06,669.71,629.04z"
              />
              <path
                d="M399.53,455.53c-0.02,0.94-0.77,1.65-1.7,1.6c-0.89-0.05-1.69-0.91-1.56-1.76c0.13-0.89,0.69-1.41,1.61-1.46
	C398.82,453.86,399.54,454.59,399.53,455.53z"
              />
              <path
                d="M426.97,580.91c-0.93,0.01-1.65-0.72-1.61-1.66c0.04-0.93,0.56-1.45,1.47-1.53c0.94-0.08,1.74,0.61,1.77,1.51
	C428.64,580.1,427.86,580.9,426.97,580.91z"
              />
              <path
                d="M526.09,250.08c-0.38,0.22-0.96,0.78-1.62,0.88c-0.86,0.13-1.62-0.92-1.44-1.78c0.19-0.88,0.76-1.4,1.68-1.37
	C525.6,247.84,526.29,248.75,526.09,250.08z"
              />
              <path
                d="M503.77,592.45c-0.93,0.02-1.67-0.71-1.65-1.65c0.03-0.94,0.54-1.46,1.46-1.56c0.93-0.1,1.75,0.6,1.81,1.49
	C505.44,591.6,504.66,592.43,503.77,592.45z"
              />
              <path
                d="M493.8,30.49c1.16,0.1,2.66,1.46,2.36,1.96c-0.28,0.47-0.89,0.93-1.41,1.01c-0.95,0.14-1.86-0.92-1.66-1.88
	C493.17,31.14,493.6,30.77,493.8,30.49z"
              />
              <path
                d="M545.91,618c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C544.27,618.76,544.94,618.03,545.91,618z"
              />
              <path
                d="M336.96,542.21c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.54c-0.89,0.09-1.73-0.66-1.77-1.53
	C335.28,543.01,336.07,542.21,336.96,542.21z"
              />
              <path
                d="M356.64,186.65c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.53c-0.89,0.09-1.73-0.66-1.77-1.53
	C354.95,187.45,355.74,186.65,356.64,186.65z"
              />
              <path
                d="M469.4,427.62c0.03,0.87-0.76,1.68-1.65,1.68c-0.93,0.01-1.66-0.74-1.61-1.68c0.04-0.94,0.57-1.45,1.49-1.53
	C468.56,426.01,469.37,426.72,469.4,427.62z"
              />
              <path
                d="M302.6,190.83c-0.93-0.04-1.63-0.83-1.54-1.77c0.09-0.95,0.66-1.42,1.58-1.47c0.92-0.05,1.72,0.74,1.71,1.62
	C304.33,190.08,303.48,190.87,302.6,190.83z"
              />
              <path
                d="M275.61,254.63c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C277.24,253.84,276.54,254.61,275.61,254.63z"
              />
              <path
                d="M429.08,586.4c0.05,0.86-0.72,1.73-1.6,1.7c-0.88-0.03-1.5-0.44-1.63-1.38c-0.13-0.96,0.48-1.71,1.43-1.79
	C428.21,584.85,429.03,585.51,429.08,586.4z"
              />
              <path
                d="M668.54,538.67c-0.56-0.43-1.37-0.76-1.61-1.33c-0.22-0.53-0.01-1.4,0.3-1.96c0.43-0.75,1.4-0.63,1.65,0.19
	c0.26,0.86,0.27,1.8,0.39,2.7C669.03,538.41,668.78,538.54,668.54,538.67z"
              />
              <path
                d="M286.3,266.64c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C284.9,265.33,285.4,265.95,286.3,266.64z"
              />
              <path
                d="M362.88,72.16c-0.03,0.86-0.88,1.62-1.76,1.57c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.44
	C362.12,70.49,362.91,71.28,362.88,72.16z"
              />
              <path
                d="M435.81,539.06c-0.04,0.86-0.88,1.6-1.77,1.54c-0.93-0.06-1.59-0.84-1.48-1.77c0.11-0.93,0.69-1.38,1.6-1.41
	C435.05,537.39,435.85,538.2,435.81,539.06z"
              />
              <path
                d="M505.64,242.44c-0.69-0.89-1.32-1.4-1.52-2.04c-0.26-0.83,0.65-1.72,1.52-1.72c0.85,0,1.81,0.93,1.55,1.72
	C506.98,241.04,506.35,241.54,505.64,242.44z"
              />
              <path
                d="M325.99,416.34c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C327.62,415.55,326.92,416.31,325.99,416.34z"
              />
              <path
                d="M442.08,543.86c0.89,0.01,1.65,0.85,1.55,1.75c-0.11,0.92-0.68,1.46-1.58,1.45c-0.92,0-1.45-0.6-1.57-1.49
	C440.36,544.73,441.23,543.85,442.08,543.86z"
              />
              <path
                d="M551.45,622.9c0.04,0.87-0.75,1.68-1.64,1.69c-0.93,0.01-1.66-0.73-1.62-1.67c0.04-0.94,0.56-1.45,1.48-1.54
	C550.61,621.3,551.42,622.01,551.45,622.9z"
              />
              <path
                d="M365.95,566.89c-0.04,0.93-0.82,1.64-1.74,1.57c-0.89-0.07-1.67-0.95-1.53-1.81c0.15-0.89,0.72-1.41,1.65-1.43
	C365.28,565.19,365.98,565.94,365.95,566.89z"
              />
              <path
                d="M312.01,69.33c-0.06,0.87-0.92,1.59-1.81,1.51c-0.93-0.08-1.58-0.88-1.45-1.82c0.13-0.94,0.74-1.37,1.64-1.38
	C311.29,67.63,312.07,68.47,312.01,69.33z"
              />
              <path
                d="M468.84,546.42c0.07,1.28-0.74,2.15-1.61,2.09c-0.91-0.07-1.46-0.59-1.53-1.51c-0.07-0.88,0.79-1.88,1.59-1.65
	C467.94,545.53,468.46,546.15,468.84,546.42z"
              />
              <path
                d="M347.51,375.35c-0.7-0.91-1.32-1.42-1.53-2.06c-0.25-0.79,0.73-1.71,1.58-1.69c0.88,0.01,1.77,0.91,1.5,1.74
	C348.84,373.98,348.21,374.47,347.51,375.35z"
              />
              <path
                d="M407.17,604.45c-0.93-0.06-1.61-0.86-1.5-1.81c0.11-0.95,0.71-1.4,1.62-1.43c0.9-0.03,1.71,0.8,1.67,1.67
	C408.92,603.74,408.04,604.5,407.17,604.45z"
              />
              <path
                d="M496.26,237.66c0,0.86-0.82,1.63-1.71,1.6c-0.93-0.03-1.62-0.78-1.54-1.72c0.08-0.93,0.63-1.42,1.54-1.47
	C495.47,236.03,496.27,236.78,496.26,237.66z"
              />
              <path
                d="M420.22,253.32c0.92,0,1.72,0.78,1.69,1.65c-0.03,0.86-0.9,1.67-1.77,1.58c-0.9-0.09-1.46-0.6-1.52-1.54
	C418.56,254.06,419.26,253.33,420.22,253.32z"
              />
              <path
                d="M416.81,237.22c-0.03,0.94-0.8,1.65-1.72,1.59c-0.88-0.06-1.68-0.94-1.55-1.79c0.14-0.9,0.71-1.41,1.63-1.45
	C416.12,235.53,416.83,236.27,416.81,237.22z"
              />
              <path
                d="M395.9,412.07c0.07,1.28-0.74,2.15-1.61,2.09c-0.91-0.07-1.46-0.59-1.53-1.51c-0.07-0.88,0.79-1.88,1.59-1.65
	C395,411.18,395.53,411.79,395.9,412.07z"
              />
              <path
                d="M410.33,600.62c-0.88-0.03-1.67-0.9-1.61-1.76c0.06-0.89,0.91-1.59,1.84-1.48c0.92,0.11,1.43,0.65,1.45,1.59
	C412.02,599.91,411.24,600.66,410.33,600.62z"
              />
              <path
                d="M354.72,603.87c-0.06,0.87-0.92,1.59-1.81,1.51c-0.93-0.08-1.58-0.88-1.45-1.82c0.13-0.94,0.74-1.37,1.64-1.38
	C354,602.17,354.78,603.01,354.72,603.87z"
              />
              <path
                d="M247.08,253.8c0.91,0.04,1.67,0.87,1.59,1.74c-0.08,0.88-0.96,1.62-1.84,1.49c-0.9-0.14-1.43-0.67-1.44-1.62
	C245.39,254.46,246.13,253.76,247.08,253.8z"
              />
              <path
                d="M340.82,359.62c-0.05,0.86-0.93,1.62-1.81,1.54c-0.93-0.08-1.59-0.88-1.48-1.82c0.12-0.95,0.73-1.39,1.64-1.41
	C340.06,357.91,340.87,358.77,340.82,359.62z"
              />
              <path
                d="M508.24,603.28c-0.69-0.91-1.31-1.43-1.52-2.07c-0.25-0.79,0.74-1.7,1.59-1.68c0.88,0.02,1.76,0.92,1.49,1.75
	C509.59,601.91,508.95,602.4,508.24,603.28z"
              />
              <path
                d="M440.04,565.84c-0.75-0.84-1.41-1.3-1.65-1.92c-0.32-0.82,0.52-1.75,1.41-1.82c0.85-0.06,1.88,0.8,1.67,1.6
	C441.3,564.36,440.7,564.9,440.04,565.84z"
              />
              <path
                d="M403.88,605.07c0.92-0.02,1.71,0.73,1.7,1.6c-0.01,0.86-0.83,1.65-1.72,1.58c-0.9-0.07-1.46-0.55-1.53-1.49
	C402.25,605.81,402.92,605.09,403.88,605.07z"
              />
              <path
                d="M448.71,563.28c-0.03,0.88-0.91,1.68-1.77,1.54c-0.89-0.15-1.43-0.71-1.4-1.64c0.03-0.93,0.6-1.46,1.5-1.55
	C447.89,561.54,448.74,562.42,448.71,563.28z"
              />
              <path
                d="M399.54,614.54c-0.87,0.72-1.35,1.36-1.98,1.58c-0.8,0.28-1.72-0.65-1.73-1.53c-0.01-0.86,0.9-1.84,1.69-1.58
	C398.15,613.22,398.66,613.85,399.54,614.54z"
              />
              <path
                d="M400.52,442.86c0.92-0.02,1.73,0.74,1.71,1.62c-0.01,0.86-0.87,1.69-1.74,1.61c-0.9-0.08-1.48-0.57-1.55-1.52
	C398.88,443.61,399.56,442.88,400.52,442.86z"
              />
              <path
                d="M440.29,446.08c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.43c0.91-0.03,1.71,0.76,1.68,1.63
	C442.03,445.38,441.18,446.13,440.29,446.08z"
              />
              <path
                d="M274.09,256.79c0.03,0.86-0.76,1.66-1.64,1.67c-0.93,0-1.65-0.73-1.6-1.67c0.04-0.93,0.56-1.44,1.48-1.52
	C273.26,255.2,274.06,255.9,274.09,256.79z"
              />
              <path
                d="M431.67,409.38c0.03,0.94-0.68,1.69-1.61,1.69c-0.89,0-1.73-0.81-1.65-1.67c0.08-0.9,0.6-1.45,1.52-1.54
	C430.9,407.77,431.64,408.42,431.67,409.38z"
              />
              <path
                d="M499.21,581.87c-0.89,0.02-1.7-0.74-1.71-1.6c0-0.88,0.79-1.63,1.71-1.58c0.91,0.05,1.46,0.53,1.54,1.46
	C500.83,581.09,500.15,581.84,499.21,581.87z"
              />
              <path
                d="M431.67,575.45c0.01,0.93-0.72,1.65-1.65,1.63c-0.89-0.03-1.7-0.85-1.59-1.71c0.11-0.88,0.64-1.42,1.56-1.48
	C430.94,573.82,431.66,574.51,431.67,575.45z"
              />
              <path
                d="M425.75,131.61c0,0.86-0.84,1.66-1.72,1.64c-0.93-0.02-1.64-0.8-1.56-1.75c0.07-0.95,0.64-1.43,1.56-1.49
	C424.94,129.96,425.75,130.73,425.75,131.61z"
              />
              <path
                d="M267.59,324.37c0.92-0.03,1.71,0.71,1.71,1.58c0,0.86-0.82,1.66-1.71,1.6c-0.9-0.06-1.47-0.54-1.54-1.48
	C265.97,325.11,266.63,324.39,267.59,324.37z"
              />
              <path
                d="M396.15,435.53c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.43c0.91-0.03,1.71,0.76,1.68,1.63
	C397.88,434.82,397.03,435.58,396.15,435.53z"
              />
              <path
                d="M414.42,121.73c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C413.01,120.41,413.52,121.04,414.42,121.73z"
              />
              <path
                d="M285.83,326.28c0.92-0.02,1.71,0.72,1.7,1.59c-0.01,0.86-0.83,1.65-1.71,1.59c-0.9-0.07-1.46-0.54-1.54-1.48
	C284.21,327.03,284.87,326.31,285.83,326.28z"
              />
              <path
                d="M472.03,233.72c-0.26-0.37-0.86-0.91-1.02-1.56c-0.21-0.84,0.76-1.73,1.62-1.63c0.9,0.11,1.43,0.68,1.51,1.57
	C474.21,232.98,473.33,233.79,472.03,233.72z"
              />
              <path
                d="M279.64,384.21c-0.92,0-1.66-0.76-1.62-1.7c0.05-0.95,0.58-1.46,1.51-1.54c0.93-0.08,1.75,0.65,1.78,1.54
	C281.34,383.38,280.52,384.21,279.64,384.21z"
              />
              <path
                d="M379.39,258.49c-0.86,0.72-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.64-1.75-1.51c-0.02-0.86,0.89-1.85,1.67-1.6
	C377.99,257.17,378.5,257.8,379.39,258.49z"
              />
              <path
                d="M427.84,406.07c0.01,0.93-0.75,1.69-1.67,1.66c-0.88-0.03-1.71-0.89-1.61-1.74c0.11-0.9,0.65-1.44,1.57-1.51
	C427.1,404.41,427.84,405.11,427.84,406.07z"
              />
              <path
                d="M462.7,515.05c-0.03,0.86-0.89,1.64-1.77,1.59c-0.93-0.05-1.62-0.84-1.52-1.79c0.1-0.95,0.68-1.41,1.6-1.45
	C461.93,513.36,462.72,514.17,462.7,515.05z"
              />
              <path
                d="M270.24,110.68c-0.88-0.02-1.66-0.85-1.61-1.7c0.05-0.89,0.86-1.58,1.8-1.48c0.92,0.09,1.42,0.62,1.45,1.55
	C271.9,109.97,271.16,110.7,270.24,110.68z"
              />
              <path
                d="M527.45,303.4c0,0.86-0.82,1.63-1.71,1.6c-0.93-0.03-1.62-0.78-1.54-1.72c0.08-0.93,0.63-1.42,1.54-1.47
	C526.66,301.77,527.46,302.52,527.45,303.4z"
              />
              <path
                d="M395.24,464.93c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C393.59,465.69,394.27,464.96,395.24,464.93z"
              />
              <path
                d="M416.13,229.93c0.02,0.87-0.76,1.66-1.66,1.66c-0.93,0-1.64-0.74-1.59-1.67c0.05-0.93,0.58-1.44,1.49-1.51
	C415.31,228.33,416.1,229.04,416.13,229.93z"
              />
              <path
                d="M296.78,436.11c-0.36,0.26-0.89,0.87-1.53,1.04c-0.82,0.22-1.7-0.78-1.6-1.64c0.1-0.9,0.65-1.45,1.56-1.52
	C296.05,433.91,296.85,434.8,296.78,436.11z"
              />
              <path
                d="M568.09,623.3c0.91,0.04,1.65,0.84,1.58,1.71c-0.08,0.87-0.93,1.59-1.82,1.46c-0.9-0.13-1.41-0.65-1.42-1.59
	C566.41,623.95,567.14,623.26,568.09,623.3z"
              />
              <path
                d="M473.37,557.97c-0.36,0.26-0.88,0.88-1.52,1.06c-0.82,0.23-1.71-0.76-1.63-1.62c0.09-0.9,0.64-1.45,1.54-1.54
	C472.61,555.78,473.42,556.66,473.37,557.97z"
              />
              <path
                d="M373.45,486.74c-0.03,0.86-0.89,1.64-1.77,1.59c-0.93-0.05-1.62-0.84-1.52-1.79c0.1-0.95,0.68-1.41,1.6-1.45
	C372.67,485.05,373.47,485.86,373.45,486.74z"
              />
              <path
                d="M362.98,261.33c-0.89,0.05-1.72-0.69-1.75-1.56c-0.03-0.87,0.76-1.65,1.67-1.62c0.91,0.03,1.48,0.49,1.58,1.42
	C364.58,260.51,363.91,261.28,362.98,261.33z"
              />
              <path
                d="M509.37,383.74c0.36-0.26,0.88-0.87,1.53-1.06c0.79-0.22,1.71,0.8,1.61,1.65c-0.11,0.89-0.63,1.46-1.55,1.52
	C510.07,385.91,509.3,385.08,509.37,383.74z"
              />
              <path
                d="M443.21,578.08c-0.87,0.71-1.35,1.36-1.99,1.57c-0.8,0.28-1.72-0.66-1.73-1.53c-0.01-0.86,0.91-1.84,1.69-1.58
	C441.82,576.75,442.32,577.38,443.21,578.08z"
              />
              <path
                d="M389.44,87.89c0.03,0.94-0.67,1.67-1.6,1.67c-0.89,0-1.72-0.79-1.64-1.66c0.08-0.89,0.59-1.44,1.51-1.53
	C388.67,86.29,389.4,86.94,389.44,87.89z"
              />
              <path
                d="M383.63,328.67c0.96,0.06,1.61,0.81,1.5,1.78c-0.11,0.95-0.72,1.4-1.61,1.45c-0.87,0.05-1.69-0.82-1.67-1.68
	C381.88,329.32,382.69,328.61,383.63,328.67z"
              />
              <path
                d="M464.74,519.99c-0.93-0.07-1.6-0.87-1.49-1.81c0.11-0.95,0.71-1.4,1.62-1.42c0.9-0.03,1.71,0.82,1.66,1.68
	C466.49,519.3,465.62,520.06,464.74,519.99z"
              />
              <path
                d="M371.43,630.88c0.06,1.28-0.75,2.15-1.62,2.08c-0.91-0.07-1.45-0.6-1.53-1.52c-0.07-0.88,0.8-1.88,1.6-1.65
	C370.53,629.98,371.06,630.61,371.43,630.88z"
              />
              <path
                d="M299.65,345.44c-0.36,0.25-0.9,0.86-1.55,1.02c-0.83,0.21-1.69-0.8-1.58-1.66c0.11-0.9,0.67-1.44,1.58-1.5
	C298.96,343.24,299.74,344.12,299.65,345.44z"
              />
              <path
                d="M436.36,356.36c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C437.98,355.57,437.29,356.33,436.36,356.36z"
              />
              <path
                d="M352.97,210.24c0.04,0.94-0.66,1.68-1.59,1.68c-0.89,0-1.72-0.79-1.65-1.65c0.08-0.89,0.58-1.45,1.5-1.53
	C352.2,208.64,352.93,209.29,352.97,210.24z"
              />
              <path
                d="M350.41,221.28c0.04,0.86-0.77,1.7-1.65,1.71c-0.92,0.01-1.67-0.75-1.63-1.69c0.04-0.95,0.57-1.46,1.49-1.55
	C349.55,219.66,350.37,220.38,350.41,221.28z"
              />
              <path
                d="M438.39,527.89c0.04,0.94-0.67,1.69-1.6,1.69c-0.89,0-1.73-0.8-1.66-1.67c0.08-0.9,0.59-1.45,1.51-1.54
	C437.61,526.28,438.35,526.94,438.39,527.89z"
              />
              <path
                d="M300.09,201.85c-0.88,0.03-1.72-0.74-1.73-1.6c-0.01-0.88,0.79-1.65,1.71-1.6c0.91,0.04,1.48,0.52,1.56,1.46
	C301.71,201.04,301.03,201.81,300.09,201.85z"
              />
              <path
                d="M347.99,485.19c0.04,0.87-0.74,1.67-1.64,1.68c-0.93,0.01-1.65-0.72-1.61-1.66c0.04-0.93,0.56-1.45,1.48-1.53
	C347.15,483.6,347.95,484.3,347.99,485.19z"
              />
              <path
                d="M420.93,200.75c-0.03,0.87-0.86,1.61-1.75,1.56c-0.93-0.05-1.6-0.82-1.5-1.76c0.1-0.93,0.67-1.4,1.58-1.43
	C420.17,199.1,420.96,199.88,420.93,200.75z"
              />
              <path
                d="M400.04,601.69c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C398.39,602.45,399.07,601.71,400.04,601.69z"
              />
              <path
                d="M301.86,220.24c0.96-0.05,1.69,0.62,1.69,1.56c0,0.93-0.51,1.47-1.4,1.61c-0.89,0.14-1.75-0.56-1.84-1.44
	C300.22,221.1,300.95,220.29,301.86,220.24z"
              />
              <path
                d="M386.44,610.34c0.96,0.02,1.64,0.74,1.57,1.7c-0.07,0.94-0.64,1.43-1.54,1.5c-0.88,0.07-1.72-0.73-1.73-1.59
	C384.72,611.07,385.51,610.32,386.44,610.34z"
              />
              <path
                d="M387.07,471.08c-0.87,0.71-1.35,1.36-1.99,1.57c-0.8,0.28-1.72-0.66-1.73-1.53c-0.01-0.86,0.91-1.84,1.69-1.58
	C385.68,469.75,386.18,470.38,387.07,471.08z"
              />
              <path
                d="M278.37,203.78c-0.88-0.03-1.66-0.88-1.6-1.74c0.06-0.89,0.9-1.57,1.83-1.47c0.92,0.11,1.42,0.64,1.44,1.58
	C280.06,203.08,279.3,203.81,278.37,203.78z"
              />
              <path
                d="M445.54,527.74c-0.36,0.26-0.88,0.88-1.53,1.05c-0.82,0.23-1.71-0.76-1.62-1.63c0.09-0.9,0.64-1.45,1.55-1.54
	C444.79,525.55,445.59,526.43,445.54,527.74z"
              />
              <path
                d="M360.29,439.17c-0.71-0.88-1.34-1.37-1.55-2.01c-0.27-0.83,0.61-1.73,1.49-1.75c0.85-0.02,1.83,0.9,1.58,1.69
	C361.6,437.75,360.98,438.26,360.29,439.17z"
              />
              <path
                d="M375.73,614.65c0.97-0.06,1.72,0.61,1.73,1.56c0.01,0.95-0.5,1.49-1.4,1.65c-0.89,0.16-1.77-0.55-1.87-1.44
	C374.08,615.54,374.81,614.71,375.73,614.65z"
              />
              <path
                d="M392.06,468.77c0.96-0.05,1.71,0.63,1.71,1.59c0,0.95-0.52,1.49-1.42,1.63c-0.88,0.15-1.77-0.58-1.86-1.47
	C390.41,469.65,391.15,468.82,392.06,468.77z"
              />
              <path
                d="M352.24,468.77c0.96-0.05,1.71,0.63,1.71,1.59c0,0.95-0.52,1.49-1.42,1.63c-0.88,0.15-1.77-0.58-1.86-1.47
	C350.58,469.65,351.32,468.82,352.24,468.77z"
              />
              <path
                d="M426.78,348.01c0-0.95,0.73-1.65,1.69-1.58c0.92,0.06,1.46,0.6,1.57,1.49c0.11,0.85-0.72,1.7-1.6,1.73
	C427.52,349.68,426.78,348.95,426.78,348.01z"
              />
              <path
                d="M461.09,220.9c0.3,0.35,0.96,0.82,1.19,1.45c0.31,0.83-0.53,1.77-1.42,1.79c-0.94,0.02-1.48-0.51-1.66-1.39
	C459.03,221.91,459.82,220.99,461.09,220.9z"
              />
              <path
                d="M440.08,589.19c-0.03,0.88-0.91,1.68-1.77,1.54c-0.89-0.15-1.43-0.71-1.4-1.63c0.03-0.93,0.6-1.46,1.49-1.55
	C439.25,587.45,440.1,588.33,440.08,589.19z"
              />
              <path
                d="M365.55,582.87c-0.92,0.01-1.67-0.75-1.63-1.69c0.04-0.95,0.57-1.46,1.49-1.55c0.93-0.09,1.75,0.63,1.79,1.53
	C367.24,582.02,366.43,582.86,365.55,582.87z"
              />
              <path
                d="M465.56,523.97c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.54c-0.89,0.09-1.73-0.66-1.77-1.53
	C463.87,524.77,464.67,523.98,465.56,523.97z"
              />
              <path
                d="M433.9,351.32c0.03,0.86-0.79,1.7-1.66,1.7c-0.92,0.01-1.66-0.76-1.62-1.7c0.04-0.95,0.58-1.46,1.5-1.54
	C433.05,349.7,433.87,350.43,433.9,351.32z"
              />
              <path
                d="M450.14,557.76c1.26,0.08,2.04,0.99,1.88,1.83c-0.17,0.87-0.71,1.4-1.64,1.39c-0.89-0.01-1.73-0.95-1.43-1.77
	C449.19,558.58,449.84,558.11,450.14,557.76z"
              />
              <path
                d="M360.38,565.05c-0.86-0.06-1.62-0.92-1.53-1.74c0.1-0.87,0.94-1.52,1.85-1.39c0.91,0.13,1.37,0.69,1.37,1.6
	C362.07,564.45,361.32,565.11,360.38,565.05z"
              />
              <path
                d="M436.92,422.62c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C437.64,424.78,436.86,423.94,436.92,422.62z"
              />
              <path
                d="M335.51,397.56c-1.36,0.01-2.17-0.78-2.06-1.65c0.12-0.88,0.65-1.43,1.58-1.45c0.95-0.03,1.79,0.75,1.54,1.64
	C336.41,396.72,335.78,397.22,335.51,397.56z"
              />
              <path
                d="M366.69,574.05c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C365.94,572.42,366.71,573.18,366.69,574.05z"
              />
              <path
                d="M448.35,576.02c0.87,0.03,1.63,0.89,1.51,1.77c-0.12,0.91-0.7,1.44-1.6,1.42c-0.91-0.02-1.44-0.63-1.53-1.51
	C446.63,576.85,447.51,575.99,448.35,576.02z"
              />
              <path
                d="M249,148.94c0.3,0.35,0.95,0.82,1.19,1.44c0.31,0.82-0.53,1.76-1.41,1.78c-0.93,0.02-1.48-0.51-1.65-1.38
	C246.96,149.93,247.73,149.02,249,148.94z"
              />
              <path
                d="M375.13,484.44c-0.93,0-1.64-0.72-1.58-1.65c0.05-0.92,0.56-1.43,1.48-1.5c0.93-0.07,1.71,0.62,1.74,1.51
	C376.79,483.66,376.02,484.44,375.13,484.44z"
              />
              <path
                d="M439.64,542.06c-0.1,1.31-0.98,2-1.89,1.85c-0.92-0.15-1.39-0.73-1.34-1.67c0.04-0.95,0.9-1.7,1.8-1.38
	C438.83,541.08,439.3,541.75,439.64,542.06z"
              />
              <path
                d="M439.83,439.11c-0.88-0.04-1.61-0.9-1.48-1.78c0.14-0.91,0.74-1.43,1.62-1.39c0.9,0.04,1.43,0.65,1.51,1.54
	C441.57,438.33,440.7,439.15,439.83,439.11z"
              />
              <path
                d="M277.38,265.83c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C275.24,266.74,276.04,265.88,277.38,265.83z"
              />
              <path
                d="M386.22,364.69c1.35,0.02,2.16,0.84,2.03,1.69c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C385.31,365.51,385.95,365.03,386.22,364.69z"
              />
              <path
                d="M434.67,588.14c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.54-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C436.01,586.73,435.37,587.23,434.67,588.14z"
              />
              <path
                d="M354.88,398.01c-0.05,0.93-0.8,1.58-1.74,1.48c-0.91-0.1-1.4-0.66-1.47-1.54c-0.07-0.87,0.74-1.61,1.65-1.6
	C354.26,396.36,354.93,397.07,354.88,398.01z"
              />
              <path
                d="M452.74,526.31c0.02,1.32-0.78,2.13-1.66,2.03c-0.91-0.1-1.42-0.68-1.49-1.56c-0.07-0.85,0.88-1.83,1.66-1.58
	C451.89,525.41,452.39,526.04,452.74,526.31z"
              />
              <path
                d="M432.43,582.75c-0.05,0.86-0.88,1.56-1.78,1.49c-0.92-0.07-1.58-0.86-1.44-1.78c0.14-0.91,0.71-1.36,1.62-1.37
	C431.72,581.08,432.48,581.89,432.43,582.75z"
              />
              <path
                d="M442.95,554.27c-1.28-0.01-2.1-0.86-1.98-1.72c0.12-0.89,0.67-1.43,1.58-1.48c0.87-0.05,1.77,0.88,1.51,1.71
	C443.86,553.42,443.23,553.92,442.95,554.27z"
              />
              <path
                d="M362.94,583.52c0.24,0.35,0.85,0.88,1,1.52c0.21,0.9-0.67,1.66-1.63,1.58c-0.93-0.07-1.45-0.63-1.52-1.54
	C360.72,584.2,361.56,583.46,362.94,583.52z"
              />
              <path
                d="M442.31,524.11c-0.93,0.71-1.44,1.34-2.07,1.52c-0.87,0.25-1.72-0.62-1.73-1.51c0-0.91,0.83-1.76,1.72-1.51
	C440.87,522.78,441.38,523.41,442.31,524.11z"
              />
              <path
                d="M410.26,348.21c-0.75-0.85-1.42-1.31-1.64-1.92c-0.31-0.88,0.49-1.71,1.44-1.75c0.94-0.04,1.88,0.74,1.66,1.58
	C411.56,346.77,410.94,347.29,410.26,348.21z"
              />
              <path
                d="M307.96,351.32c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C306.49,350.03,307.01,350.64,307.96,351.32z"
              />
              <path
                d="M313.28,522.56c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C312.37,523.38,313.01,522.89,313.28,522.56z"
              />
              <path
                d="M425.8,187.36c-1.38,0.07-2.21-0.66-2.13-1.55c0.07-0.89,0.57-1.46,1.51-1.52c0.96-0.07,1.82,0.65,1.62,1.56
	C426.65,186.48,426.05,187.01,425.8,187.36z"
              />
              <path
                d="M340.44,399.64c-0.36,0.26-0.89,0.86-1.53,1.03c-0.81,0.22-1.69-0.78-1.59-1.64c0.1-0.89,0.65-1.44,1.55-1.51
	C339.71,397.45,340.51,398.34,340.44,399.64z"
              />
              <path
                d="M369.58,483.68c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C368.67,481.66,369.5,482.35,369.58,483.68z"
              />
              <path
                d="M366.69,75.86c-0.36,0.28-0.86,0.93-1.49,1.12c-0.91,0.28-1.72-0.52-1.72-1.48c0-0.94,0.48-1.49,1.42-1.59
	C365.84,73.82,366.65,74.53,366.69,75.86z"
              />
              <path
                d="M316.59,365.24c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C315.12,363.94,315.64,364.55,316.59,365.24z"
              />
              <path
                d="M353.53,249.97c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C352,248.76,352.55,249.34,353.53,249.97z"
              />
              <path
                d="M449.43,523.4c-0.87,0.7-1.36,1.34-1.99,1.55c-0.8,0.27-1.7-0.66-1.71-1.54c0-0.86,0.92-1.82,1.69-1.56
	C448.07,522.06,448.56,522.7,449.43,523.4z"
              />
              <path
                d="M355.19,421.1c-0.89-0.01-1.66-0.81-1.62-1.66c0.04-0.89,0.84-1.57,1.76-1.49c0.92,0.08,1.41,0.6,1.46,1.52
	C356.84,420.4,356.12,421.12,355.19,421.1z"
              />
              <path
                d="M244.65,355.4c-0.72-0.86-1.37-1.34-1.57-1.96c-0.28-0.89,0.53-1.68,1.49-1.69c0.92-0.02,1.85,0.8,1.6,1.62
	C245.98,354,245.35,354.5,244.65,355.4z"
              />
              <path
                d="M449.02,436.65c-0.88,0.77-1.34,1.42-1.95,1.64c-0.85,0.3-1.75-0.5-1.81-1.39c-0.06-0.88,0.74-1.81,1.62-1.61
	C447.5,435.42,448.05,436.01,449.02,436.65z"
              />
              <path
                d="M391.05,248.56c0.89,0.05,1.63,0.87,1.55,1.72c-0.08,0.86-0.94,1.55-1.83,1.42c-0.9-0.13-1.39-0.67-1.39-1.59
	C389.37,249.17,390.1,248.51,391.05,248.56z"
              />
              <path
                d="M352.77,621.9c0.06,0.85-0.71,1.7-1.58,1.69c-0.89-0.01-1.49-0.44-1.63-1.36c-0.14-0.93,0.49-1.71,1.41-1.79
	C351.9,620.36,352.71,621.02,352.77,621.9z"
              />
              <path
                d="M396,425.17c0.26,0.34,0.87,0.85,1.04,1.48c0.24,0.89-0.62,1.67-1.57,1.63c-0.92-0.05-1.46-0.58-1.56-1.48
	C393.82,425.92,394.63,425.14,396,425.17z"
              />
              <path
                d="M320.09,467.85c0.28,0.33,0.94,0.8,1.14,1.42c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.01-1.49-0.51-1.65-1.38
	C317.95,468.75,318.75,467.89,320.09,467.85z"
              />
              <path
                d="M293.08,345.75c-0.97,0.65-1.51,1.24-2.16,1.39c-0.87,0.2-1.69-0.74-1.63-1.62c0.06-0.9,0.95-1.71,1.82-1.41
	C291.73,344.33,292.19,344.99,293.08,345.75z"
              />
              <path
                d="M424.71,599.65c-0.69-0.91-1.32-1.43-1.5-2.07c-0.24-0.83,0.71-1.63,1.64-1.6c0.96,0.03,1.77,0.84,1.47,1.73
	C426.11,598.33,425.44,598.8,424.71,599.65z"
              />
              <path
                d="M380.12,618.05c0.27,0.34,0.9,0.83,1.08,1.45c0.26,0.88-0.58,1.68-1.53,1.66c-0.93-0.02-1.47-0.55-1.6-1.44
	C377.96,618.86,378.77,618.06,380.12,618.05z"
              />
              <path
                d="M433.31,573.21c-0.86-0.06-1.62-0.92-1.53-1.74c0.1-0.87,0.94-1.52,1.85-1.39c0.91,0.13,1.37,0.69,1.37,1.6
	C435,572.6,434.25,573.27,433.31,573.21z"
              />
              <path
                d="M394.84,461.46c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.53-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C396.18,460.05,395.55,460.56,394.84,461.46z"
              />
              <path
                d="M527.4,263.81c0,0.88-0.86,1.7-1.72,1.58c-0.89-0.12-1.44-0.67-1.44-1.59c0-0.92,0.57-1.46,1.45-1.58
	C526.53,262.1,527.4,262.96,527.4,263.81z"
              />
              <path
                d="M463.12,521.82c-0.04,1.31-0.86,2.03-1.78,1.92c-0.93-0.11-1.41-0.65-1.41-1.59c0-0.96,0.81-1.73,1.72-1.45
	C462.28,520.9,462.77,521.54,463.12,521.82z"
              />
              <path
                d="M319.2,527.17c-0.09,1.31-0.96,2-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C318.38,526.2,318.85,526.87,319.2,527.17z"
              />
              <path
                d="M469.51,554.63c-0.36,0.26-0.89,0.86-1.53,1.03c-0.81,0.22-1.69-0.78-1.59-1.64c0.1-0.89,0.65-1.44,1.55-1.51
	C468.79,552.44,469.59,553.33,469.51,554.63z"
              />
              <path
                d="M414.88,610.89c-0.88-0.05-1.61-0.9-1.47-1.79c0.14-0.91,0.74-1.43,1.62-1.39c0.9,0.04,1.43,0.66,1.51,1.54
	C416.62,610.12,415.75,610.93,414.88,610.89z"
              />
              <path
                d="M384.63,615.85c-0.05,0.93-0.8,1.59-1.73,1.49c-0.91-0.1-1.41-0.66-1.48-1.53c-0.07-0.87,0.73-1.62,1.64-1.61
	C384,614.2,384.68,614.91,384.63,615.85z"
              />
              <path
                d="M444.44,180.26c-0.1,1.31-0.98,2-1.89,1.85c-0.92-0.15-1.39-0.73-1.34-1.67c0.04-0.95,0.9-1.7,1.8-1.38
	C443.63,179.29,444.1,179.95,444.44,180.26z"
              />
              <path
                d="M350.6,241.36c0.92-0.02,1.69,0.7,1.69,1.57c0,0.86-0.8,1.63-1.69,1.57c-0.9-0.06-1.45-0.54-1.53-1.46
	C348.99,242.11,349.66,241.39,350.6,241.36z"
              />
              <path
                d="M330.22,364.24c-0.36,0.28-0.85,0.93-1.49,1.12c-0.91,0.28-1.72-0.52-1.72-1.47c0-0.93,0.48-1.49,1.42-1.59
	C329.37,362.2,330.18,362.91,330.22,364.24z"
              />
              <path
                d="M432.04,548.5c0.24,0.35,0.84,0.88,0.99,1.51c0.21,0.9-0.67,1.64-1.62,1.57c-0.93-0.07-1.44-0.63-1.51-1.52
	C429.84,549.17,430.66,548.44,432.04,548.5z"
              />
              <path
                d="M383.17,475.37c-0.35,0.28-0.85,0.91-1.48,1.11c-0.8,0.26-1.75-0.69-1.68-1.56c0.07-0.88,0.58-1.47,1.49-1.58
	C382.35,473.24,383.18,474.07,383.17,475.37z"
              />
              <path
                d="M462.26,429.05c-0.38,0.22-0.96,0.78-1.61,0.88c-0.84,0.13-1.6-0.92-1.42-1.77c0.18-0.88,0.75-1.39,1.67-1.36
	C461.78,426.83,462.46,427.74,462.26,429.05z"
              />
              <path
                d="M442.76,521.82c-0.25-0.35-0.87-0.86-1.02-1.5c-0.23-0.9,0.63-1.65,1.59-1.6c0.93,0.05,1.45,0.61,1.54,1.5
	C444.96,521.11,444.14,521.87,442.76,521.82z"
              />
              <path
                d="M337.42,363.47c-0.06,0.86-0.89,1.55-1.78,1.48c-0.92-0.08-1.57-0.87-1.43-1.78c0.14-0.91,0.72-1.36,1.62-1.36
	C336.72,361.8,337.47,362.61,337.42,363.47z"
              />
              <path
                d="M439.43,575.12c-0.97,0.65-1.51,1.24-2.16,1.39c-0.87,0.2-1.69-0.74-1.63-1.62c0.06-0.9,0.95-1.71,1.82-1.41
	C438.08,573.69,438.54,574.35,439.43,575.12z"
              />
              <path
                d="M368.61,441.95c0.27,0.34,0.9,0.83,1.08,1.45c0.26,0.88-0.58,1.68-1.53,1.66c-0.93-0.02-1.47-0.55-1.6-1.44
	C366.44,442.76,367.25,441.96,368.61,441.95z"
              />
              <path
                d="M374.28,82.48c-0.37,0.26-0.91,0.86-1.56,1.02c-0.83,0.21-1.64-0.77-1.55-1.67c0.09-0.92,0.67-1.42,1.56-1.48
	C373.57,80.29,374.38,81.22,374.28,82.48z"
              />
              <path
                d="M497.48,410.72c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C495.96,409.51,496.5,410.08,497.48,410.72z"
              />
              <path
                d="M428.12,200.57c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C427.21,198.56,428.04,199.25,428.12,200.57z"
              />
              <path
                d="M354.17,557.01c-0.01,0.88-0.87,1.69-1.73,1.56c-0.89-0.13-1.43-0.68-1.43-1.6c0.01-0.92,0.58-1.45,1.46-1.57
	C353.31,555.29,354.18,556.15,354.17,557.01z"
              />
              <path
                d="M399.8,415.71c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C399.04,414.08,399.82,414.84,399.8,415.71z"
              />
              <path
                d="M431.75,568.1c-0.88,0.78-1.33,1.44-1.95,1.67c-0.85,0.31-1.77-0.5-1.84-1.39c-0.07-0.88,0.74-1.82,1.61-1.64
	C430.22,566.89,430.77,567.47,431.75,568.1z"
              />
              <path
                d="M270.74,260.38c-0.09,1.31-0.96,2-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C269.92,259.41,270.39,260.08,270.74,260.38z"
              />
              <path
                d="M434.35,577.25c0.27,0.34,0.91,0.83,1.09,1.46c0.26,0.89-0.59,1.7-1.54,1.68c-0.93-0.02-1.48-0.56-1.61-1.45
	C432.17,578.07,432.98,577.26,434.35,577.25z"
              />
              <path
                d="M404.59,346.92c-0.35,0.29-0.83,0.94-1.45,1.15c-0.9,0.3-1.73-0.47-1.75-1.41c-0.02-0.92,0.44-1.5,1.37-1.62
	C403.69,344.92,404.52,345.6,404.59,346.92z"
              />
              <path
                d="M386.92,617.28c0.87,0.03,1.63,0.89,1.51,1.77c-0.12,0.91-0.7,1.44-1.6,1.42c-0.91-0.02-1.44-0.62-1.53-1.51
	C385.21,618.12,386.08,617.26,386.92,617.28z"
              />
              <path
                d="M337.43,70.36c-0.09,1.31-0.96,2.01-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C336.62,69.4,337.09,70.06,337.43,70.36z"
              />
              <path
                d="M441.28,430.31c-0.87,0.7-1.36,1.34-1.99,1.55c-0.8,0.27-1.7-0.66-1.71-1.54c0-0.86,0.92-1.82,1.69-1.56
	C439.91,428.98,440.4,429.61,441.28,430.31z"
              />
              <path
                d="M430.51,395.07c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C429.75,393.45,430.53,394.2,430.51,395.07z"
              />
              <path
                d="M345.75,618.4c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C346.47,620.55,345.69,619.71,345.75,618.4z"
              />
              <path
                d="M431.75,402.18c-0.91,0.73-1.4,1.36-2.02,1.56c-0.87,0.27-1.73-0.56-1.75-1.46c-0.03-0.89,0.8-1.77,1.68-1.55
	C430.28,400.9,430.8,401.5,431.75,402.18z"
              />
              <path
                d="M344.61,70.23c-0.02,0.86-0.83,1.59-1.73,1.54c-0.93-0.05-1.6-0.81-1.49-1.73c0.11-0.91,0.66-1.39,1.57-1.41
	C343.87,68.6,344.64,69.36,344.61,70.23z"
              />
              <path
                d="M459.3,519.17c-0.35,0.29-0.82,0.95-1.44,1.16c-0.9,0.31-1.74-0.46-1.77-1.4c-0.03-0.92,0.42-1.5,1.36-1.63
	C458.38,517.18,459.21,517.85,459.3,519.17z"
              />
              <path
                d="M494.13,414.55c-0.88,0.78-1.33,1.44-1.95,1.67c-0.85,0.31-1.77-0.5-1.84-1.39c-0.07-0.88,0.74-1.82,1.61-1.64
	C492.6,413.34,493.15,413.92,494.13,414.55z"
              />
              <path d="M663.11,517.33c0.38,1.22,0.7,2.26,1.02,3.29c-1.62,0.52-2.18,0.35-2.57-0.67C661.15,518.84,661.51,518.14,663.11,517.33z" />
              <path
                d="M534.5,615.36c1.25,0,2.1,0.87,1.99,1.72c-0.12,0.89-0.65,1.44-1.57,1.48c-0.88,0.05-1.78-0.85-1.52-1.69
	C533.6,616.23,534.22,615.73,534.5,615.36z"
              />
              <path
                d="M438.19,567.42c-0.05,1.31-0.9,2.03-1.81,1.92c-0.93-0.12-1.42-0.67-1.4-1.61c0.01-0.96,0.82-1.73,1.74-1.44
	C437.34,566.49,437.83,567.14,438.19,567.42z"
              />
              <path
                d="M347.54,481.3c0.96-0.65,1.49-1.25,2.13-1.4c0.88-0.21,1.69,0.7,1.64,1.59c-0.05,0.89-0.93,1.71-1.79,1.42
	C348.9,482.7,348.43,482.06,347.54,481.3z"
              />
              <path
                d="M407.26,611.57c-0.25-0.35-0.87-0.87-1.03-1.51c-0.23-0.91,0.64-1.67,1.6-1.61c0.94,0.06,1.46,0.61,1.55,1.51
	C409.46,610.84,408.63,611.61,407.26,611.57z"
              />
              <path
                d="M392.17,429.19c0.88,0.01,1.64,0.85,1.54,1.74c-0.11,0.92-0.68,1.45-1.57,1.45c-0.91,0-1.45-0.59-1.56-1.48
	C390.48,430.05,391.33,429.18,392.17,429.19z"
              />
              <path
                d="M346.49,8.88c0.65-0.46,1.16-1.03,1.78-1.21c0.49-0.14,1.29,0.01,1.65,0.35c0.53,0.49,0.11,1.64-0.66,1.99
	C348.21,10.48,347.26,10.43,346.49,8.88z"
              />
              <path
                d="M387.95,252.4c0.94,0.06,1.59,0.81,1.47,1.74c-0.12,0.92-0.7,1.38-1.59,1.41c-0.89,0.03-1.67-0.78-1.63-1.64
	C386.24,253.03,387.02,252.35,387.95,252.4z"
              />
              <path
                d="M503.44,403.15c0.03,0.86-0.75,1.66-1.64,1.63c-0.89-0.03-1.47-0.49-1.59-1.41c-0.12-0.93,0.54-1.68,1.48-1.74
	C502.62,401.58,503.41,402.26,503.44,403.15z"
              />
              <path
                d="M437.03,546.05c-0.88,0.77-1.35,1.43-1.96,1.65c-0.85,0.31-1.76-0.52-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C435.51,544.82,436.05,545.4,437.03,546.05z"
              />
              <path
                d="M450.45,567.9c-0.26-0.35-0.88-0.87-1.04-1.51c-0.23-0.91,0.63-1.67,1.59-1.62c0.94,0.05,1.46,0.61,1.56,1.5
	C452.65,567.17,451.82,567.94,450.45,567.9z"
              />
              <path
                d="M363.48,305.19c0.94,0.06,1.59,0.81,1.47,1.74c-0.12,0.92-0.7,1.38-1.59,1.41c-0.89,0.03-1.67-0.78-1.63-1.64
	C361.77,305.81,362.55,305.13,363.48,305.19z"
              />
              <path
                d="M392.45,436.18c1.36,0.02,2.17,0.85,2.04,1.71c-0.14,0.89-0.69,1.42-1.62,1.44c-0.95,0.01-1.79-0.8-1.52-1.69
	C391.54,437,392.18,436.51,392.45,436.18z"
              />
              <path
                d="M255.65,413.61c-0.88,0.77-1.34,1.43-1.96,1.66c-0.85,0.31-1.77-0.51-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C254.13,412.38,254.67,412.97,255.65,413.61z"
              />
              <path
                d="M366.18,321.16c-0.01,0.9-0.82,1.62-1.74,1.53c-0.92-0.09-1.45-0.63-1.43-1.56c0.01-0.93,0.53-1.46,1.46-1.53
	C365.41,319.54,366.19,320.27,366.18,321.16z"
              />
              <path
                d="M465.02,559.55c-1.28-0.01-2.1-0.86-1.98-1.72c0.12-0.89,0.67-1.43,1.58-1.48c0.87-0.05,1.77,0.88,1.51,1.71
	C465.93,558.69,465.3,559.19,465.02,559.55z"
              />
              <path
                d="M360.47,351.99c-1.36,0.01-2.18-0.79-2.07-1.67c0.12-0.89,0.65-1.43,1.59-1.47c0.96-0.03,1.8,0.75,1.55,1.65
	C361.37,351.14,360.73,351.64,360.47,351.99z"
              />
              <path
                d="M370.82,450.65c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C369.34,449.36,369.86,449.96,370.82,450.65z"
              />
              <path
                d="M437.01,536.71c-0.25-0.35-0.87-0.87-1.03-1.51c-0.23-0.91,0.64-1.67,1.6-1.61c0.94,0.06,1.46,0.61,1.55,1.51
	C439.21,535.99,438.38,536.76,437.01,536.71z"
              />
              <path
                d="M468.21,532.67c-0.9,0.74-1.38,1.38-2,1.58c-0.86,0.28-1.74-0.55-1.77-1.44c-0.04-0.88,0.78-1.78,1.66-1.57
	C466.73,531.41,467.26,532.01,468.21,532.67z"
              />
              <path
                d="M380.46,339.73c1.36,0.02,2.17,0.85,2.04,1.71c-0.14,0.89-0.69,1.42-1.62,1.44c-0.95,0.01-1.79-0.8-1.52-1.69
	C379.54,340.56,380.19,340.07,380.46,339.73z"
              />
              <path
                d="M445.38,440.73c-0.06,0.86-0.89,1.55-1.78,1.48c-0.92-0.08-1.57-0.87-1.43-1.78c0.14-0.91,0.72-1.36,1.62-1.36
	C444.68,439.05,445.44,439.87,445.38,440.73z"
              />
              <path
                d="M620.54,628.09c-0.35,0.3-0.82,0.96-1.45,1.18c-0.9,0.31-1.75-0.46-1.78-1.41c-0.03-0.93,0.43-1.51,1.36-1.64
	C619.61,626.09,620.45,626.77,620.54,628.09z"
              />
              <path
                d="M312.76,322.1c-0.93,0.71-1.44,1.34-2.07,1.52c-0.87,0.25-1.72-0.62-1.73-1.51c0-0.91,0.83-1.76,1.72-1.51
	C311.32,320.77,311.82,321.4,312.76,322.1z"
              />
              <path
                d="M261.88,193.01c-0.92,0.71-1.42,1.34-2.04,1.52c-0.87,0.26-1.72-0.59-1.73-1.49c-0.01-0.9,0.81-1.76,1.7-1.52
	C260.44,191.7,260.95,192.32,261.88,193.01z"
              />
              <path
                d="M384.72,109.96c-0.91,0.73-1.4,1.36-2.02,1.56c-0.87,0.27-1.73-0.56-1.75-1.46c-0.03-0.89,0.8-1.77,1.68-1.54
	C383.26,108.68,383.78,109.28,384.72,109.96z"
              />
              <path
                d="M593.71,151.02l-0.11-0.21c0-0.98,0-1.95,0.01-2.93c0,0-0.03,0.08-0.03,0.08c0.67-0.35,1.26-0.25,1.52,0.49
	c0.3,0.86,0.48,1.77,0.83,3.14C594.93,151.33,594.32,151.17,593.71,151.02z"
              />
              <path
                d="M370.01,261.35c-0.68-0.91-1.31-1.42-1.49-2.06c-0.23-0.83,0.7-1.62,1.63-1.59c0.95,0.03,1.76,0.84,1.46,1.72
	C371.4,260.03,370.74,260.5,370.01,261.35z"
              />
              <path
                d="M376.74,375.66c0.73,0.86,1.39,1.33,1.6,1.95c0.29,0.88-0.52,1.7-1.48,1.73c-0.93,0.03-1.87-0.78-1.63-1.62
	C375.41,377.08,376.04,376.57,376.74,375.66z"
              />
              <path
                d="M430.31,593.74c-0.95,0.68-1.46,1.29-2.09,1.46c-0.89,0.23-1.7-0.64-1.68-1.54c0.02-0.91,0.86-1.73,1.75-1.46
	C428.91,592.39,429.4,593.02,430.31,593.74z"
              />
              <path
                d="M394.31,264.37c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C392.79,263.16,393.34,263.73,394.31,264.37z"
              />
              <path
                d="M298.07,449.73c0.01,0.86-0.78,1.62-1.67,1.6c-0.93-0.02-1.63-0.75-1.55-1.68c0.07-0.92,0.6-1.42,1.51-1.47
	C297.28,448.13,298.06,448.85,298.07,449.73z"
              />
              <path
                d="M617.6,257.33C617.6,257.33,617.6,257.33,617.6,257.33c-0.95-1.09-0.61-2.26-0.24-3.43c0,0-0.08,0.07-0.08,0.07
	c0.52-0.03,1.04-0.06,1.77-0.11c0.09,0.93,0.25,1.75,0.24,2.57C619.28,257.66,618.61,257.88,617.6,257.33z"
              />
              <path
                d="M416.98,256.66c0.76,0.84,1.43,1.29,1.66,1.91c0.32,0.87-0.48,1.72-1.43,1.77c-0.93,0.05-1.89-0.73-1.67-1.57
	C415.7,258.12,416.32,257.59,416.98,256.66z"
              />
              <path
                d="M444.77,579.85c1.25,0,2.1,0.87,1.99,1.72c-0.12,0.89-0.65,1.44-1.57,1.48c-0.88,0.05-1.78-0.85-1.52-1.69
	C443.86,580.73,444.49,580.22,444.77,579.85z"
              />
              <path
                d="M466.94,565.16c-0.01,0.88-0.87,1.69-1.73,1.56c-0.89-0.13-1.43-0.68-1.43-1.6c0.01-0.92,0.58-1.45,1.46-1.57
	C466.08,563.45,466.95,564.31,466.94,565.16z"
              />
              <path
                d="M434.96,405.9c-0.36,0.25-0.9,0.85-1.55,1.01c-0.81,0.2-1.67-0.81-1.56-1.67c0.12-0.89,0.67-1.43,1.57-1.48
	C434.28,403.71,435.05,404.6,434.96,405.9z"
              />
              <path
                d="M303.84,593.99c-0.89,0.67-1.42,1.36-2.06,1.48c-0.87,0.17-1.46-0.49-1.54-1.42c-0.07-0.9,0.8-1.89,1.59-1.64
	C302.48,592.63,302.97,593.28,303.84,593.99z"
              />
              <path
                d="M411.06,604.6c0.92-0.02,1.69,0.7,1.69,1.57c0,0.86-0.8,1.63-1.69,1.57c-0.9-0.06-1.45-0.54-1.53-1.46
	C409.45,605.35,410.12,604.63,411.06,604.6z"
              />
              <path
                d="M399.6,448.36c-0.95,0.68-1.46,1.29-2.1,1.45c-0.89,0.23-1.7-0.65-1.68-1.55c0.03-0.9,0.87-1.73,1.75-1.46
	C398.2,447,398.69,447.63,399.6,448.36z"
              />
              <path
                d="M390.64,434.64c0,0.88-0.86,1.7-1.72,1.58c-0.89-0.12-1.44-0.67-1.44-1.59c0-0.92,0.57-1.46,1.45-1.58
	C389.77,432.93,390.65,433.78,390.64,434.64z"
              />
              <path
                d="M375.61,255.27c-0.88,0.77-1.35,1.43-1.96,1.65c-0.85,0.31-1.76-0.52-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C374.09,254.04,374.63,254.62,375.61,255.27z"
              />
              <path
                d="M418.93,191.94c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C416.79,192.84,417.59,191.99,418.93,191.94z"
              />
              <path
                d="M443.59,561.49c-1.3-0.08-2.02-0.95-1.87-1.83c0.15-0.91,0.75-1.38,1.64-1.38c0.86,0,1.74,1.01,1.43,1.78
	C444.54,560.68,443.89,561.15,443.59,561.49z"
              />
              <path
                d="M357.03,558.53c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C354.89,559.44,355.69,558.59,357.03,558.53z"
              />
              <path
                d="M449.39,530.16c0.01,1.32-0.79,2.12-1.67,2.02c-0.91-0.1-1.41-0.69-1.48-1.57c-0.07-0.85,0.89-1.83,1.67-1.57
	C448.54,529.26,449.04,529.88,449.39,530.16z"
              />
              <path
                d="M396.76,443.23c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.54-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C398.1,441.82,397.46,442.32,396.76,443.23z"
              />
              <path
                d="M434.36,564.77c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C433.44,562.76,434.28,563.45,434.36,564.77z"
              />
              <path
                d="M443.64,541.14c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C444.36,543.3,443.58,542.46,443.64,541.14z"
              />
              <path
                d="M430.84,588.3c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C429.93,589.12,430.57,588.63,430.84,588.3z"
              />
              <path
                d="M251.19,411.14c-0.36,0.26-0.88,0.87-1.52,1.05c-0.81,0.22-1.7-0.77-1.6-1.63c0.1-0.89,0.65-1.44,1.54-1.52
	C250.45,408.96,251.25,409.85,251.19,411.14z"
              />
              <path
                d="M454.41,560.41c0.67,0.92,1.28,1.44,1.45,2.08c0.22,0.85-0.72,1.61-1.65,1.57c-0.95-0.05-1.74-0.88-1.43-1.75
	C453,561.7,453.67,561.25,454.41,560.41z"
              />
              <path
                d="M321.41,532.43c-0.93,0.02-1.65-0.7-1.61-1.63c0.04-0.92,0.53-1.44,1.45-1.53c0.92-0.09,1.72,0.59,1.77,1.48
	C323.07,531.61,322.3,532.41,321.41,532.43z"
              />
              <path
                d="M400.57,608.92c0.9,0.01,1.66,0.78,1.62,1.65c-0.04,0.86-0.87,1.59-1.76,1.5c-0.9-0.09-1.42-0.6-1.46-1.52
	C398.93,609.6,399.63,608.9,400.57,608.92z"
              />
              <path
                d="M304.79,196.41c-0.01,0.86-0.81,1.6-1.7,1.57c-0.93-0.04-1.61-0.78-1.52-1.71c0.09-0.92,0.63-1.4,1.54-1.44
	C304.02,194.79,304.8,195.53,304.79,196.41z"
              />
              <path
                d="M497.94,405.49c1.38-0.04,2.21,0.72,2.12,1.6c-0.09,0.9-0.62,1.45-1.55,1.51c-0.95,0.06-1.82-0.71-1.6-1.61
	C497.07,406.36,497.69,405.84,497.94,405.49z"
              />
              <path
                d="M418.47,235.44c-0.68-0.91-1.31-1.42-1.49-2.06c-0.23-0.83,0.7-1.62,1.63-1.59c0.95,0.03,1.76,0.84,1.46,1.72
	C419.87,234.12,419.2,234.59,418.47,235.44z"
              />
              <path
                d="M521.97,610.89c-0.91,0.75-1.39,1.39-2.01,1.6c-0.86,0.28-1.75-0.56-1.78-1.45c-0.04-0.89,0.79-1.79,1.67-1.57
	C520.48,609.61,521.01,610.22,521.97,610.89z"
              />
              <path
                d="M358.03,433.93c-0.01,0.9-0.82,1.62-1.74,1.53c-0.92-0.09-1.45-0.63-1.43-1.56c0.01-0.93,0.53-1.46,1.46-1.53
	C357.25,432.3,358.03,433.03,358.03,433.93z"
              />
              <path
                d="M428.44,559.51c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C427.53,560.33,428.17,559.84,428.44,559.51z"
              />
              <path
                d="M422.39,198.97c-0.71-0.93-1.38-1.44-1.5-2.06c-0.17-0.85,0.43-1.47,1.38-1.55c0.96-0.08,1.84,0.67,1.61,1.56
	C423.72,197.54,423.09,198.05,422.39,198.97z"
              />
              <path
                d="M270.88,347.71c-0.1,0.85-0.63,1.36-1.57,1.36c-0.95,0-1.48-0.57-1.56-1.48c-0.08-0.92,0.75-1.65,1.71-1.57
	C270.33,346.08,270.92,346.74,270.88,347.71z"
              />
              <path
                d="M356.59,479.07c1.26,0.09,2.01,1,1.84,1.84c-0.17,0.87-0.73,1.38-1.65,1.36c-0.88-0.03-1.71-0.98-1.39-1.78
	C355.64,479.87,356.29,479.42,356.59,479.07z"
              />
              <path
                d="M439.37,408.71c-0.91,0.7-1.41,1.34-2.06,1.54c-0.81,0.25-1.71-0.76-1.6-1.64c0.11-0.91,0.71-1.59,1.58-1.42
	C437.94,407.32,438.46,408.01,439.37,408.71z"
              />
              <path
                d="M469.02,562.92c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C469.96,562.12,469.32,562.58,469.02,562.92z"
              />
              <path
                d="M477.51,560.96c-0.35,0.28-0.83,0.92-1.46,1.11c-0.9,0.28-1.71-0.5-1.71-1.44c0-0.92,0.46-1.48,1.39-1.58
	C476.65,558.95,477.46,559.64,477.51,560.96z"
              />
              <path
                d="M435.37,591.1c0.7,0.93,1.35,1.45,1.49,2.08c0.19,0.9-0.44,1.48-1.39,1.57c-0.96,0.08-1.89-0.71-1.65-1.57
	C433.99,592.53,434.64,592.03,435.37,591.1z"
              />
              <path
                d="M454.69,547.64c-0.07,1.33-0.51,1.91-1.46,1.98c-0.95,0.07-1.51-0.45-1.68-1.34c-0.16-0.87,0.68-1.85,1.55-1.66
	C453.73,546.77,454.27,547.37,454.69,547.64z"
              />
              <path
                d="M422.81,135.54c-0.85,0.77-1.31,1.43-1.94,1.67c-0.8,0.31-1.75-0.62-1.71-1.52c0.03-0.94,0.63-1.62,1.48-1.52
	C421.28,134.24,421.85,134.9,422.81,135.54z"
              />
              <path
                d="M436.48,559.05c0.28,0.34,0.93,0.84,1.11,1.47c0.26,0.86-0.67,1.67-1.63,1.59c-0.95-0.07-1.45-0.62-1.46-1.56
	C434.5,559.69,435.17,559.06,436.48,559.05z"
              />
              <path
                d="M465.1,536.66c-0.98,0.67-1.52,1.27-2.17,1.41c-0.9,0.2-1.68-0.72-1.54-1.65c0.14-0.92,0.75-1.53,1.64-1.3
	C463.66,535.29,464.16,535.94,465.1,536.66z"
              />
              <path
                d="M243.41,357.1c-0.95,0.71-1.46,1.35-2.09,1.5c-0.88,0.21-1.5-0.39-1.62-1.33c-0.12-0.94,0.66-1.84,1.56-1.63
	C241.91,355.81,242.44,356.42,243.41,357.1z"
              />
              <path
                d="M442.76,531.16c-0.87,0.76-1.32,1.42-1.93,1.64c-0.85,0.31-1.74-0.48-1.81-1.37c-0.06-0.88,0.72-1.8,1.59-1.61
	C441.26,529.96,441.8,530.53,442.76,531.16z"
              />
              <path
                d="M391.1,442.26c-0.36,0.28-0.86,0.91-1.5,1.11c-0.8,0.25-1.68-0.69-1.63-1.57c0.04-0.92,0.6-1.44,1.47-1.55
	C390.26,440.14,391.12,441,391.1,442.26z"
              />
              <path
                d="M461.07,560.47c1.34,0.01,2.14,0.82,2.01,1.68c-0.13,0.88-0.66,1.41-1.59,1.42c-0.94,0.01-1.77-0.79-1.51-1.66
	C460.17,561.29,460.8,560.81,461.07,560.47z"
              />
              <path
                d="M387.25,438.92c-0.36,0.28-0.87,0.9-1.51,1.09c-0.8,0.24-1.67-0.72-1.61-1.59c0.06-0.92,0.62-1.43,1.49-1.53
	C386.45,436.79,387.29,437.67,387.25,438.92z"
              />
              <path
                d="M223.15,197.65c-0.09-0.61-0.2-0.99-0.2-1.38c0-0.74,0.08-1.61,1.03-1.56c0.49,0.02,1.13,0.53,1.39,1
	c0.45,0.83,0.03,1.57-0.81,1.92C224.22,197.77,223.78,197.65,223.15,197.65z"
              />
              <path
                d="M351.12,474.16c-0.9,0.72-1.39,1.35-2,1.54c-0.87,0.27-1.71-0.55-1.74-1.45c-0.02-0.89,0.79-1.76,1.66-1.54
	C349.67,472.88,350.18,473.49,351.12,474.16z"
              />
              <path
                d="M257.37,411.44c-1.38,0.07-2.19-0.65-2.11-1.55c0.07-0.88,0.56-1.45,1.5-1.51c0.96-0.06,1.81,0.65,1.61,1.56
	C258.22,410.57,257.62,411.09,257.37,411.44z"
              />
              <path
                d="M443.17,435.52c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C444.42,434.01,443.8,434.56,443.17,435.52z"
              />
              <path
                d="M392.34,449.15c-0.96,0.65-1.51,1.31-2.15,1.4c-0.89,0.12-1.44-0.6-1.51-1.5c-0.07-0.87,0.91-1.85,1.69-1.55
	C391.01,447.74,391.47,448.39,392.34,449.15z"
              />
              <path
                d="M398.54,172.09c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C399.79,170.58,399.18,171.13,398.54,172.09z"
              />
              <path
                d="M450.09,537.3c0.08,1.27-0.72,2.14-1.57,2.08c-0.89-0.07-1.45-0.57-1.53-1.48c-0.08-0.86,0.77-1.86,1.56-1.65
	C449.2,536.42,449.72,537.03,450.09,537.3z"
              />
              <path
                d="M450.36,435.04c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C451.62,433.53,451,434.08,450.36,435.04z"
              />
              <path
                d="M347.69,231.95c-0.1,1.34-0.57,1.89-1.51,1.94c-0.95,0.05-1.5-0.49-1.64-1.38c-0.14-0.88,0.72-1.83,1.59-1.61
	C346.76,231.06,347.29,231.66,347.69,231.95z"
              />
              <path
                d="M415.13,128.98c-0.9,0.7-1.42,1.39-2.06,1.52c-0.86,0.17-1.48-0.47-1.59-1.41c-0.1-0.88,0.79-1.9,1.59-1.66
	C413.73,127.63,414.24,128.28,415.13,128.98z"
              />
              <path
                d="M386.33,171.51c-1.01,0.56-1.61,1.13-2.26,1.21c-0.86,0.11-1.58-1.02-1.33-1.86c0.26-0.86,0.92-1.47,1.78-1.15
	C385.12,169.94,385.53,170.68,386.33,171.51z"
              />
              <path
                d="M435.98,345.96c0.3,0.38,0.95,0.87,1.14,1.51c0.26,0.86-0.67,1.67-1.63,1.59c-0.95-0.07-1.47-0.62-1.45-1.56
	C434.06,346.56,434.62,346.07,435.98,345.96z"
              />
              <path
                d="M407.75,169.57c-0.98,0.67-1.51,1.28-2.14,1.41c-0.9,0.18-1.46-0.48-1.56-1.39c-0.1-0.91,0.75-1.79,1.63-1.54
	C406.31,168.23,406.8,168.85,407.75,169.57z"
              />
              <path
                d="M460.3,549.73c-0.71-0.93-1.38-1.44-1.5-2.06c-0.17-0.85,0.43-1.47,1.38-1.55c0.96-0.08,1.84,0.67,1.6,1.56
	C461.63,548.31,461,548.81,460.3,549.73z"
              />
              <path
                d="M306.48,356.47c0.72,0.92,1.36,1.42,1.55,2.05c0.25,0.86-0.68,1.65-1.63,1.57c-0.94-0.08-1.57-0.66-1.39-1.55
	C305.14,357.91,305.79,357.4,306.48,356.47z"
              />
              <path
                d="M346.34,360.96c-0.7-0.93-1.32-1.45-1.51-2.09c-0.24-0.84,0.75-1.74,1.62-1.62c0.91,0.13,1.56,0.75,1.39,1.62
	C347.71,359.5,347.04,360.03,346.34,360.96z"
              />
              <path
                d="M458.28,523.94c0.75,0.89,1.4,1.36,1.6,1.98c0.28,0.86-0.59,1.68-1.53,1.64c-0.94-0.04-1.59-0.62-1.46-1.49
	C456.98,525.44,457.62,524.9,458.28,523.94z"
              />
              <path
                d="M548.24,626.82c-0.09,0.96-0.61,1.5-1.55,1.51c-0.95,0.02-1.49-0.54-1.59-1.45c-0.1-0.91,0.73-1.68,1.67-1.61
	C547.73,625.35,548.18,625.92,548.24,626.82z"
              />
              <path
                d="M364.43,481.82c-1.29-0.07-2.02-0.92-1.88-1.8c0.14-0.91,0.74-1.38,1.62-1.4c0.85-0.02,1.73,0.98,1.44,1.76
	C365.36,481.01,364.73,481.48,364.43,481.82z"
              />
              <path
                d="M481.91,564.05c-1,0.65-1.55,1.25-2.2,1.39c-0.92,0.19-1.68-0.75-1.53-1.68c0.15-0.92,0.78-1.53,1.67-1.28
	C480.48,562.64,480.98,563.3,481.91,564.05z"
              />
              <path
                d="M455.91,167.09c-0.66-0.95-1.32-1.49-1.43-2.13c-0.14-0.87,0.53-1.46,1.44-1.56c0.85-0.09,1.82,0.86,1.55,1.67
	C457.26,165.7,456.63,166.19,455.91,167.09z"
              />
              <path
                d="M383.02,447.37c0.7,0.92,1.33,1.43,1.52,2.08c0.25,0.84-0.73,1.74-1.6,1.63c-0.92-0.11-1.57-0.74-1.41-1.6
	C381.66,448.84,382.33,448.3,383.02,447.37z"
              />
              <path
                d="M395.28,342.23c-0.69-0.92-1.32-1.44-1.5-2.08c-0.24-0.83,0.75-1.73,1.61-1.61c0.9,0.13,1.55,0.74,1.38,1.6
	C396.64,340.78,395.97,341.3,395.28,342.23z"
              />
              <path
                d="M384.42,442.15c-0.85,0.77-1.31,1.43-1.94,1.67c-0.8,0.31-1.74-0.62-1.71-1.52c0.03-0.94,0.63-1.62,1.48-1.52
	C382.89,440.86,383.46,441.52,384.42,442.15z"
              />
              <path
                d="M430.05,340.69c-0.91,0.75-1.38,1.41-2,1.59c-0.87,0.25-1.51-0.32-1.67-1.24c-0.16-0.91,0.57-1.86,1.48-1.69
	C428.49,339.47,429.05,340.05,430.05,340.69z"
              />
              <path
                d="M348.68,611.59c-0.87,0.74-1.35,1.4-1.98,1.62c-0.8,0.29-1.76-0.68-1.68-1.56c0.08-0.91,0.65-1.62,1.53-1.49
	C347.18,610.26,347.73,610.93,348.68,611.59z"
              />
              <path
                d="M474.62,564.58c-0.87,0.73-1.35,1.39-1.97,1.61c-0.8,0.28-1.71-0.67-1.65-1.56c0.06-0.93,0.68-1.59,1.52-1.47
	C473.14,563.24,473.69,563.92,474.62,564.58z"
              />
              <path
                d="M446.07,587.06c0.29,0.36,0.91,0.86,1.12,1.5c0.26,0.8-0.67,1.73-1.52,1.68c-0.89-0.06-1.45-0.55-1.55-1.46
	C444.02,587.91,444.82,587.06,446.07,587.06z"
              />
              <path
                d="M430.77,600.76c-0.87,0.76-1.33,1.41-1.94,1.62c-0.86,0.3-1.74-0.5-1.79-1.39c-0.06-0.88,0.73-1.79,1.61-1.6
	C429.27,599.54,429.81,600.12,430.77,600.76z"
              />
              <path
                d="M457.17,529.75c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C455.62,528.53,456.17,529.11,457.17,529.75z"
              />
              <path
                d="M464.1,553.1c-0.69-0.94-1.32-1.46-1.48-2.1c-0.22-0.86,0.74-1.63,1.68-1.52c0.94,0.11,1.55,0.72,1.34,1.6
	C465.49,551.69,464.82,552.19,464.1,553.1z"
              />
              <path
                d="M542.74,621.36c0.63,0.97,1.22,1.52,1.35,2.16c0.17,0.88-0.8,1.58-1.74,1.43c-0.94-0.15-1.49-0.81-1.25-1.65
	C541.27,622.68,541.97,622.22,542.74,621.36z"
              />
              <path
                d="M413.94,613.12c-0.91,0.7-1.41,1.34-2.06,1.54c-0.81,0.25-1.71-0.76-1.6-1.64c0.11-0.91,0.71-1.59,1.58-1.42
	C412.51,611.73,413.03,612.42,413.94,613.12z"
              />
              <path
                d="M342.85,324.65c-0.31-0.37-0.97-0.85-1.17-1.48c-0.27-0.84,0.67-1.68,1.6-1.63c0.95,0.06,1.48,0.59,1.48,1.54
	C344.77,324.03,344.2,324.52,342.85,324.65z"
              />
              <path
                d="M512.71,613.82c-0.64-0.98-1.23-1.53-1.37-2.18c-0.18-0.84,0.87-1.67,1.71-1.49c0.89,0.19,1.51,0.82,1.26,1.69
	C514.14,612.46,513.46,612.94,512.71,613.82z"
              />
              <path
                d="M459.26,558.68c-0.01,0.9-0.79,1.61-1.72,1.52c-0.92-0.09-1.44-0.62-1.43-1.54c0.01-0.92,0.52-1.46,1.45-1.53
	C458.49,557.06,459.26,557.78,459.26,558.68z"
              />
              <path
                d="M384.23,355.64c-0.9,0.72-1.39,1.35-2,1.54c-0.87,0.27-1.71-0.55-1.74-1.45c-0.02-0.89,0.79-1.76,1.66-1.54
	C382.78,354.36,383.29,354.97,384.23,355.64z"
              />
              <path
                d="M317.79,489.76c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C319.15,488.32,318.49,488.83,317.79,489.76z"
              />
              <path
                d="M396.18,618.28c-0.86,0.76-1.33,1.42-1.95,1.66c-0.8,0.3-1.77-0.65-1.71-1.54c0.07-0.91,0.63-1.63,1.51-1.52
	C394.66,616.96,395.22,617.62,396.18,618.28z"
              />
              <path
                d="M457.88,537.11c-0.97,0.68-1.5,1.31-2.13,1.43c-0.88,0.17-1.48-0.44-1.56-1.37c-0.08-0.94,0.7-1.8,1.61-1.56
	C456.44,535.77,456.94,536.4,457.88,537.11z"
              />
              <path
                d="M341.16,545.04c0.67,0.97,1.28,1.51,1.43,2.15c0.19,0.87-0.79,1.61-1.73,1.48c-0.95-0.13-1.54-0.77-1.3-1.65
	C339.72,546.4,340.41,545.93,341.16,545.04z"
              />
              <path
                d="M515.89,373.12c-0.87,0.73-1.35,1.39-1.97,1.61c-0.8,0.28-1.71-0.67-1.65-1.56c0.06-0.93,0.67-1.59,1.52-1.47
	C514.41,371.79,514.96,372.46,515.89,373.12z"
              />
              <path
                d="M376.69,625.55c-0.76-0.88-1.43-1.34-1.63-1.96c-0.29-0.87,0.58-1.7,1.52-1.67c0.95,0.03,1.6,0.61,1.49,1.48
	C377.99,624.03,377.34,624.58,376.69,625.55z"
              />
              <path
                d="M459.75,566.24c-0.35,0.28-0.83,0.92-1.46,1.11c-0.9,0.28-1.71-0.5-1.71-1.44c0-0.92,0.46-1.48,1.39-1.58
	C458.9,564.23,459.7,564.92,459.75,566.24z"
              />
              <path
                d="M504.18,595.91c0.71,0.92,1.35,1.42,1.52,2.05c0.24,0.87-0.66,1.64-1.61,1.56c-0.94-0.08-1.56-0.66-1.38-1.54
	C502.84,597.36,503.49,596.84,504.18,595.91z"
              />
              <path
                d="M253.21,206.38c1.36-0.05,2.18,0.69,2.1,1.57c-0.08,0.89-0.59,1.44-1.51,1.5c-0.94,0.06-1.81-0.69-1.59-1.58
	C252.36,207.25,252.96,206.73,253.21,206.38z"
              />
              <path
                d="M272.91,321.98c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C271.36,320.76,271.91,321.34,272.91,321.98z"
              />
              <path
                d="M463.84,529.1c-0.19,1.36-0.75,1.85-1.68,1.83c-0.95-0.03-1.46-0.61-1.52-1.53c-0.05-0.91,0.85-1.77,1.73-1.48
	C463,528.13,463.47,528.79,463.84,529.1z"
              />
              <path
                d="M292.34,378.22c-0.94,0.7-1.45,1.35-2.08,1.49c-0.87,0.21-1.49-0.39-1.6-1.32c-0.11-0.92,0.66-1.83,1.56-1.62
	C290.85,376.93,291.37,377.54,292.34,378.22z"
              />
              <path
                d="M523.65,319.53c0.69,0.93,1.32,1.43,1.48,2.06c0.23,0.89-0.65,1.63-1.61,1.55c-0.95-0.08-1.55-0.7-1.37-1.56
	C522.27,320.96,522.94,320.45,523.65,319.53z"
              />
              <path
                d="M324.96,485.07c0.71,0.91,1.34,1.41,1.52,2.03c0.25,0.89-0.62,1.65-1.59,1.57c-0.95-0.07-1.56-0.68-1.4-1.54
	C323.61,486.52,324.27,486.01,324.96,485.07z"
              />
              <path
                d="M373.45,479.56c-0.98,0.67-1.52,1.27-2.17,1.41c-0.9,0.2-1.68-0.72-1.54-1.65c0.14-0.92,0.75-1.53,1.64-1.3
	C372.01,478.19,372.51,478.84,373.45,479.56z"
              />
              <path
                d="M441.51,350.67c-0.9,0.69-1.41,1.32-2.05,1.51c-0.8,0.24-1.67-0.78-1.57-1.65c0.11-0.93,0.75-1.55,1.59-1.39
	C440.1,349.27,440.62,349.97,441.51,350.67z"
              />
              <path
                d="M465.91,179.4c-0.37,0.26-0.9,0.86-1.55,1.02c-0.83,0.2-1.63-0.77-1.54-1.66c0.09-0.92,0.68-1.4,1.55-1.47
	C465.21,177.23,466.01,178.15,465.91,179.4z"
              />
              <path
                d="M446.07,361.08c-0.86,0.75-1.32,1.41-1.94,1.65c-0.79,0.3-1.75-0.64-1.69-1.53c0.06-0.91,0.63-1.62,1.49-1.51
	C444.56,359.77,445.12,360.44,446.07,361.08z"
              />
              <path
                d="M420.71,343.69c-0.7-0.93-1.32-1.45-1.51-2.09c-0.24-0.84,0.75-1.74,1.62-1.62c0.91,0.13,1.56,0.75,1.39,1.62
	C422.09,342.22,421.41,342.75,420.71,343.69z"
              />
              <path
                d="M355.71,192c-0.94,0.73-1.44,1.39-2.07,1.55c-0.88,0.23-1.52-0.36-1.66-1.3c-0.14-0.94,0.62-1.87,1.54-1.67
	C354.17,190.72,354.72,191.33,355.71,192z"
              />
              <path
                d="M432.6,389.65c0.28,0.34,0.95,0.81,1.16,1.44c0.28,0.84-0.65,1.69-1.59,1.64c-0.94-0.05-1.47-0.57-1.5-1.52
	C430.65,390.34,431.28,389.7,432.6,389.65z"
              />
              <path
                d="M432.43,598.6c-1.36,0-2.15-0.78-2.03-1.65c0.12-0.88,0.64-1.42,1.58-1.44c0.94-0.02,1.78,0.76,1.53,1.64
	C433.33,597.77,432.7,598.27,432.43,598.6z"
              />
              <path
                d="M557.64,631.57c-0.67-0.94-1.32-1.49-1.43-2.13c-0.15-0.87,0.53-1.47,1.43-1.57c0.85-0.1,1.82,0.85,1.56,1.66
	C558.99,630.18,558.36,630.67,557.64,631.57z"
              />
              <path
                d="M393.5,442.84c0.75,0.89,1.43,1.36,1.6,1.98c0.23,0.87-0.35,1.5-1.3,1.63c-0.94,0.13-1.91-0.6-1.72-1.48
	C392.22,444.34,392.84,443.8,393.5,442.84z"
              />
              <path
                d="M442.45,590.36c0.73,0.89,1.38,1.38,1.59,2.02c0.27,0.82-0.68,1.77-1.54,1.69c-0.92-0.08-1.59-0.69-1.46-1.56
	C441.13,591.88,441.79,591.32,442.45,590.36z"
              />
              <path
                d="M470.87,229.13c-1,0.65-1.56,1.25-2.21,1.38c-0.92,0.19-1.68-0.75-1.52-1.69c0.15-0.92,0.78-1.53,1.67-1.28
	C469.45,227.72,469.94,228.38,470.87,229.13z"
              />
              <path
                d="M434.39,557.34c-1,0.65-1.55,1.24-2.19,1.37c-0.91,0.18-1.67-0.75-1.51-1.67c0.16-0.92,0.78-1.52,1.66-1.27
	C432.97,555.94,433.46,556.6,434.39,557.34z"
              />
              <path
                d="M355.62,262.59c-0.67-0.95-1.34-1.5-1.44-2.14c-0.14-0.87,0.54-1.48,1.45-1.57c0.86-0.09,1.83,0.86,1.56,1.68
	C356.98,261.19,356.34,261.69,355.62,262.59z"
              />
              <path
                d="M325.7,472.64c-0.92,0.76-1.4,1.41-2.02,1.6c-0.89,0.28-1.77-0.57-1.7-1.49c0.07-0.91,0.6-1.6,1.51-1.46
	C324.14,471.4,324.69,472,325.7,472.64z"
              />
              <path
                d="M441.86,540.04c-1.38,0.07-2.12-0.62-2.08-1.56c0.05-0.92,0.55-1.45,1.48-1.51c0.92-0.06,1.85,0.72,1.63,1.56
	C442.73,539.17,442.11,539.69,441.86,540.04z"
              />
              <path
                d="M443.5,206.1c-1,0.65-1.55,1.24-2.19,1.37c-0.91,0.18-1.67-0.75-1.51-1.67c0.16-0.92,0.78-1.52,1.66-1.27
	C442.09,204.7,442.58,205.35,443.5,206.1z"
              />
              <path
                d="M374.15,446.79c-0.9,0.73-1.37,1.37-1.99,1.56c-0.87,0.28-1.72-0.54-1.75-1.43c-0.03-0.88,0.78-1.77,1.65-1.55
	C372.69,445.53,373.21,446.12,374.15,446.79z"
              />
              <path
                d="M376.98,336.04c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C375.7,337.54,376.31,337,376.98,336.04z"
              />
              <path
                d="M461.01,533.22c-0.95,0.7-1.46,1.33-2.08,1.48c-0.9,0.22-1.48-0.43-1.61-1.33c-0.13-0.89,0.7-1.82,1.57-1.6
	C459.52,531.92,460.04,532.53,461.01,533.22z"
              />
              <path
                d="M390.94,464.71c-0.25-0.34-0.86-0.86-1.02-1.49c-0.22-0.9,0.63-1.64,1.58-1.59c0.93,0.05,1.43,0.61,1.52,1.49
	C393.12,464.01,392.31,464.76,390.94,464.71z"
              />
              <path
                d="M380.63,106.67c-0.96,0.69-1.48,1.33-2.1,1.46c-0.87,0.19-1.48-0.42-1.58-1.35c-0.09-0.93,0.68-1.81,1.59-1.58
	C379.16,105.35,379.67,105.97,380.63,106.67z"
              />
              <path
                d="M485.73,567.27c-0.95,0.71-1.46,1.35-2.09,1.5c-0.88,0.21-1.5-0.39-1.62-1.33c-0.12-0.94,0.66-1.84,1.56-1.63
	C484.23,565.98,484.76,566.59,485.73,567.27z"
              />
              <path
                d="M358.75,590.49c-0.32-0.36-1-0.82-1.23-1.44c-0.3-0.81,0.63-1.71,1.55-1.69c0.94,0.03,1.5,0.54,1.54,1.48
	C360.63,589.8,360.09,590.31,358.75,590.49z"
              />
              <path
                d="M447.44,549.34c-0.36,0.26-0.88,0.86-1.52,1.04c-0.8,0.22-1.68-0.76-1.59-1.62c0.1-0.89,0.64-1.43,1.53-1.51
	C446.7,547.17,447.51,548.05,447.44,549.34z"
              />
              <path
                d="M385.91,623.07c-0.99,0.65-1.54,1.25-2.17,1.36c-0.89,0.16-1.46-0.48-1.51-1.42c-0.05-0.94,0.75-1.77,1.65-1.51
	C384.5,621.69,384.98,622.33,385.91,623.07z"
              />
              <path
                d="M433.93,344.25c-0.99,0.67-1.53,1.28-2.18,1.42c-0.91,0.2-1.69-0.73-1.55-1.66c0.13-0.93,0.75-1.54,1.65-1.31
	C432.48,342.87,432.98,343.52,433.93,344.25z"
              />
              <path
                d="M454.3,580.9c-1.01,0.63-1.58,1.22-2.22,1.31c-0.91,0.14-1.44-0.56-1.5-1.47c-0.05-0.91,0.85-1.76,1.72-1.47
	C452.92,579.48,453.39,580.13,454.3,580.9z"
              />
              <path
                d="M237.65,205.91c-0.93,0.73-1.43,1.38-2.06,1.54c-0.88,0.23-1.51-0.36-1.65-1.29c-0.14-0.93,0.63-1.85,1.53-1.66
	C236.12,204.65,236.66,205.25,237.65,205.91z"
              />
              <path
                d="M450.8,521.17c-1.29-0.07-2.02-0.92-1.88-1.8c0.14-0.9,0.74-1.38,1.62-1.39c0.85-0.02,1.73,0.98,1.43,1.76
	C451.73,520.36,451.1,520.83,450.8,521.17z"
              />
              <path
                d="M352.98,475.21c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C351.71,476.7,352.32,476.16,352.98,475.21z"
              />
              <path
                d="M486.87,415.57c-0.96,0.65-1.51,1.31-2.15,1.4c-0.89,0.13-1.44-0.6-1.51-1.5c-0.07-0.87,0.91-1.85,1.69-1.55
	C485.53,414.15,486,414.81,486.87,415.57z"
              />
              <path
                d="M450.86,212.28c-0.1,1.34-0.58,1.89-1.52,1.93c-0.95,0.04-1.49-0.5-1.63-1.39c-0.13-0.88,0.72-1.82,1.6-1.6
	C449.94,211.39,450.45,212,450.86,212.28z"
              />
              <path
                d="M495.21,575.01c0.74,0.88,1.39,1.37,1.61,2c0.28,0.82-0.65,1.77-1.52,1.71c-0.93-0.07-1.6-0.67-1.48-1.54
	C493.91,576.54,494.56,575.97,495.21,575.01z"
              />
              <path
                d="M349.48,352.84c0.68,0.94,1.31,1.46,1.48,2.11c0.23,0.85-0.77,1.72-1.64,1.59c-0.92-0.13-1.55-0.77-1.37-1.63
	C348.09,354.28,348.77,353.76,349.48,352.84z"
              />
              <path
                d="M441.59,556.78c-0.97,0.68-1.5,1.29-2.14,1.45c-0.9,0.21-1.69-0.7-1.56-1.62c0.12-0.93,0.73-1.54,1.61-1.33
	C440.13,555.43,440.64,556.07,441.59,556.78z"
              />
              <path
                d="M449.35,546.61c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C450.29,545.8,449.64,546.27,449.35,546.61z"
              />
              <path
                d="M579.12,629.64c-0.71-0.86-1.34-1.34-1.56-1.97c-0.28-0.81,0.6-1.71,1.46-1.74c0.83-0.03,1.81,0.87,1.57,1.65
	C580.4,628.22,579.79,628.74,579.12,629.64z"
              />
              <path
                d="M440.85,549.4c-0.87,0.76-1.32,1.42-1.93,1.64c-0.85,0.31-1.74-0.48-1.81-1.37c-0.06-0.88,0.72-1.8,1.59-1.61
	C439.34,548.19,439.88,548.77,440.85,549.4z"
              />
              <path
                d="M436.45,415.43c0.36-0.26,0.87-0.87,1.51-1.06c0.77-0.23,1.68,0.78,1.6,1.62c-0.09,0.89-0.62,1.44-1.52,1.52
	C437.18,417.58,436.39,416.72,436.45,415.43z"
              />
              <path
                d="M391.04,414.28c0.66,0.95,1.31,1.5,1.42,2.14c0.14,0.86-0.53,1.47-1.44,1.55c-0.86,0.08-1.81-0.86-1.55-1.67
	C389.68,415.66,390.32,415.17,391.04,414.28z"
              />
              <path
                d="M469.11,566.38c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C467.83,567.87,468.44,567.33,469.11,566.38z"
              />
              <path
                d="M421.79,191.79c-0.66-0.97-1.27-1.52-1.41-2.16c-0.18-0.87,0.81-1.61,1.75-1.47c0.95,0.14,1.52,0.79,1.29,1.66
	C423.24,190.43,422.55,190.9,421.79,191.79z"
              />
              <path
                d="M344.64,481.82c-0.93,0.72-1.43,1.37-2.05,1.53c-0.87,0.23-1.5-0.37-1.64-1.28c-0.14-0.92,0.62-1.84,1.52-1.65
	C343.12,480.56,343.66,481.16,344.64,481.82z"
              />
              <path
                d="M365.45,180.63c-1,0.56-1.6,1.11-2.25,1.19c-0.87,0.11-1.55-0.97-1.32-1.84c0.24-0.89,0.92-1.46,1.75-1.15
	C364.24,179.04,364.65,179.8,365.45,180.63z"
              />
              <path
                d="M343.34,474.77c-0.05,0.82-0.51,1.39-1.47,1.45c-0.96,0.06-1.51-0.48-1.67-1.37c-0.15-0.87,0.67-1.7,1.6-1.69
	C342.67,473.17,343.31,473.8,343.34,474.77z"
              />
              <path
                d="M456.17,546.37c-0.68-0.94-1.3-1.46-1.44-2.08c-0.21-0.9,0.68-1.62,1.64-1.52c0.95,0.1,1.53,0.74,1.35,1.58
	C457.58,544.96,456.89,545.46,456.17,546.37z"
              />
              <path
                d="M453.46,533.49c0.06,1.27-0.75,2.13-1.6,2.05c-0.89-0.08-1.44-0.59-1.51-1.51c-0.07-0.86,0.8-1.86,1.58-1.63
	C452.58,532.59,453.09,533.21,453.46,533.49z"
              />
              <path
                d="M332.73,475.56c0.86-0.76,1.32-1.47,1.94-1.64c0.86-0.24,1.49,0.38,1.66,1.28c0.16,0.87-0.63,1.93-1.45,1.75
	C334.23,476.8,333.68,476.2,332.73,475.56z"
              />
              <path
                d="M462.27,570.81c-1.34-0.18-1.87-0.7-1.83-1.64c0.04-0.93,0.6-1.44,1.54-1.47c0.91-0.02,1.83,0.88,1.52,1.68
	C463.27,570,462.59,570.45,462.27,570.81z"
              />
              <path
                d="M446.79,557.62c-1.27,0-2.08-0.85-1.97-1.7c0.11-0.89,0.67-1.42,1.56-1.47c0.85-0.05,1.77,0.88,1.51,1.69
	C447.69,556.76,447.07,557.26,446.79,557.62z"
              />
              <path
                d="M450.58,584.34c0.07,1.27-0.73,2.13-1.59,2.06c-0.89-0.07-1.44-0.58-1.52-1.5c-0.07-0.86,0.79-1.86,1.57-1.64
	C449.69,583.45,450.21,584.06,450.58,584.34z"
              />
              <path
                d="M381.35,446.26c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C379.8,445.04,380.35,445.62,381.35,446.26z"
              />
              <path
                d="M351.99,627.65c0.28,0.34,0.95,0.81,1.16,1.44c0.28,0.84-0.65,1.69-1.59,1.64c-0.94-0.05-1.47-0.57-1.5-1.52
	C350.03,628.34,350.67,627.7,351.99,627.65z"
              />
              <path
                d="M338.65,370.64c-0.98,0.67-1.51,1.27-2.14,1.4c-0.91,0.18-1.46-0.49-1.55-1.39c-0.09-0.91,0.76-1.79,1.64-1.54
	C337.22,369.28,337.71,369.91,338.65,370.64z"
              />
              <path
                d="M393.96,454.26c-0.66-0.97-1.27-1.52-1.41-2.16c-0.18-0.87,0.81-1.61,1.75-1.47c0.95,0.14,1.52,0.79,1.29,1.66
	C395.41,452.9,394.72,453.37,393.96,454.26z"
              />
              <path
                d="M457.17,569.69c-0.92,0.7-1.41,1.33-2.03,1.51c-0.88,0.25-1.7-0.58-1.71-1.48c-0.01-0.9,0.81-1.75,1.69-1.5
	C455.74,568.38,456.24,569,457.17,569.69z"
              />
              <path
                d="M453.59,557.42c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C454.94,555.98,454.29,556.49,453.59,557.42z"
              />
              <path
                d="M463.39,541.68c0.72,0.92,1.36,1.42,1.55,2.05c0.25,0.86-0.68,1.65-1.63,1.57c-0.94-0.08-1.57-0.66-1.39-1.55
	C462.05,543.13,462.7,542.62,463.39,541.68z"
              />
              <path
                d="M455.67,574.81c0.62,0.97,1.21,1.52,1.33,2.15c0.17,0.9-0.76,1.57-1.72,1.43c-0.96-0.14-1.49-0.82-1.27-1.65
	C454.17,576.14,454.89,575.68,455.67,574.81z"
              />
              <path
                d="M487.34,568.3c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C486.07,569.79,486.68,569.25,487.34,568.3z"
              />
              <path
                d="M382.65,261.36c0.1,1.31-0.65,2.16-1.53,2.11c-0.91-0.05-1.44-0.59-1.56-1.47c-0.11-0.84,0.76-1.87,1.55-1.66
	C381.75,260.51,382.28,261.1,382.65,261.36z"
              />
              <path
                d="M462.94,554.95c-1,0.65-1.56,1.25-2.19,1.36c-0.91,0.15-1.46-0.53-1.53-1.44c-0.07-0.91,0.81-1.78,1.69-1.5
	C461.53,553.55,462.01,554.19,462.94,554.95z"
              />
              <path
                d="M386.81,454.99c-0.66-0.95-1.32-1.49-1.43-2.13c-0.14-0.87,0.53-1.46,1.44-1.56c0.85-0.09,1.82,0.86,1.55,1.67
	C388.17,453.6,387.53,454.09,386.81,454.99z"
              />
              <path
                d="M363.13,570.77c-1,0.65-1.55,1.26-2.18,1.37c-0.91,0.16-1.46-0.52-1.54-1.43c-0.08-0.91,0.79-1.78,1.68-1.52
	C361.71,569.38,362.2,570.02,363.13,570.77z"
              />
              <path
                d="M452.31,539.01c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C451.03,540.51,451.64,539.97,452.31,539.01z"
              />
              <path
                d="M524.03,612.24c0.63,0.97,1.22,1.52,1.35,2.16c0.17,0.88-0.8,1.58-1.74,1.43c-0.94-0.15-1.49-0.81-1.25-1.65
	C522.56,613.56,523.26,613.11,524.03,612.24z"
              />
              <path
                d="M425.67,606.82c-0.77-0.87-1.44-1.32-1.65-1.93c-0.3-0.85,0.55-1.7,1.49-1.68c0.95,0.02,1.6,0.58,1.5,1.45
	C426.93,605.29,426.3,605.85,425.67,606.82z"
              />
              <path
                d="M387.5,462.19c-0.65-0.97-1.25-1.52-1.4-2.16c-0.19-0.84,0.85-1.68,1.7-1.51c0.89,0.18,1.52,0.8,1.28,1.67
	C388.91,460.81,388.23,461.3,387.5,462.19z"
              />
              <path
                d="M396.56,103.23c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C397.5,102.43,396.86,102.89,396.56,103.23z"
              />
              <path
                d="M303.73,241.31c0.66,0.96,1.27,1.5,1.41,2.14c0.19,0.87-0.78,1.6-1.72,1.47c-0.94-0.13-1.53-0.77-1.29-1.63
	C302.3,242.67,302.98,242.2,303.73,241.31z"
              />
              <path
                d="M467.01,225.51c-0.91,0.76-1.39,1.42-2.01,1.61c-0.87,0.26-1.52-0.31-1.69-1.23c-0.17-0.92,0.56-1.87,1.47-1.7
	C465.44,224.3,466,224.88,467.01,225.51z"
              />
              <path
                d="M406.2,613.85c-0.02,0.84-0.46,1.42-1.41,1.52c-0.96,0.1-1.8-0.63-1.72-1.55c0.07-0.91,0.59-1.47,1.54-1.51
	C405.47,612.27,406.15,612.91,406.2,613.85z"
              />
              <path
                d="M441.96,421.4c-0.75-0.88-1.39-1.35-1.62-1.98c-0.29-0.8,0.64-1.78,1.5-1.71c0.91,0.07,1.6,0.65,1.48,1.52
	C443.23,419.87,442.59,420.43,441.96,421.4z"
              />
              <path
                d="M435.35,555.5c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C436.71,554.06,436.05,554.57,435.35,555.5z"
              />
              <path
                d="M348.66,551.49c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C347.39,552.99,348,552.45,348.66,551.49z"
              />
              <path
                d="M519.92,343.5c0.02,1.3-0.79,2.11-1.65,2.02c-0.9-0.1-1.41-0.68-1.48-1.55c-0.07-0.85,0.87-1.82,1.64-1.57
	C519.06,342.6,519.56,343.22,519.92,343.5z"
              />
              <path
                d="M270.89,204.48c-0.29-0.34-0.93-0.81-1.16-1.44c-0.29-0.77,0.6-1.78,1.44-1.75c0.88,0.02,1.48,0.49,1.61,1.4
	C272.91,203.56,272.18,204.41,270.89,204.48z"
              />
              <path
                d="M439.12,598.48c-0.67-0.95-1.34-1.5-1.44-2.14c-0.14-0.87,0.54-1.48,1.45-1.57c0.86-0.09,1.83,0.86,1.56,1.68
	C440.48,597.08,439.84,597.57,439.12,598.48z"
              />
              <path
                d="M447.36,209.45c-1,0.65-1.55,1.25-2.2,1.39c-0.92,0.19-1.68-0.75-1.53-1.68c0.15-0.92,0.78-1.53,1.67-1.28
	C445.93,208.04,446.43,208.71,447.36,209.45z"
              />
              <path
                d="M464.26,243.8c-0.02,0.93-0.46,1.51-1.41,1.62c-0.96,0.11-1.8-0.62-1.73-1.54c0.07-0.91,0.58-1.49,1.53-1.51
	C463.6,242.35,464.13,242.86,464.26,243.8z"
              />
              <path
                d="M432.87,250.23c-0.85,0.76-1.31,1.42-1.94,1.65c-0.8,0.3-1.73-0.63-1.69-1.52c0.04-0.93,0.64-1.6,1.49-1.5
	C431.36,248.94,431.92,249.6,432.87,250.23z"
              />
              <path
                d="M481.67,421.39c-0.69-0.87-1.32-1.37-1.53-2c-0.27-0.81,0.63-1.71,1.49-1.72c0.83-0.01,1.79,0.9,1.54,1.68
	C482.97,420,482.36,420.5,481.67,421.39z"
              />
              <path
                d="M416.95,130.2c0.66,0.95,1.33,1.51,1.43,2.15c0.13,0.86-0.53,1.48-1.46,1.56c-0.87,0.08-1.82-0.86-1.55-1.68
	C415.58,131.59,416.23,131.09,416.95,130.2z"
              />
              <path
                d="M456.09,522.47c0.03,1.3-0.77,2.12-1.63,2.03c-0.9-0.09-1.41-0.66-1.49-1.55c-0.07-0.85,0.85-1.83,1.63-1.58
	C455.24,521.58,455.74,522.2,456.09,522.47z"
              />
              <path
                d="M373.13,625.38c0.73,0.91,1.42,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.32,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C371.86,626.89,372.47,626.34,373.13,625.38z"
              />
              <path
                d="M437.93,600.25c-0.94,0.67-1.48,1.34-2.11,1.45c-0.89,0.15-1.46-0.55-1.54-1.46c-0.08-0.88,0.86-1.87,1.65-1.59
	C436.56,598.87,437.04,599.51,437.93,600.25z"
              />
              <path
                d="M396.98,419.55c-0.98,0.69-1.51,1.3-2.15,1.46c-0.91,0.22-1.69-0.7-1.57-1.64c0.12-0.94,0.73-1.55,1.62-1.34
	C395.51,418.19,396.02,418.84,396.98,419.55z"
              />
              <path
                d="M348.02,477.98c-0.93,0.73-1.43,1.38-2.06,1.54c-0.88,0.23-1.51-0.36-1.65-1.29c-0.14-0.93,0.63-1.85,1.53-1.66
	C346.49,476.72,347.03,477.32,348.02,477.98z"
              />
              <path
                d="M437.46,205c-0.68-0.94-1.29-1.47-1.46-2.12c-0.22-0.84,0.79-1.71,1.65-1.57c0.9,0.15,1.54,0.77,1.34,1.63
	C438.84,203.58,438.17,204.09,437.46,205z"
              />
              <path
                d="M450.4,245.25c-0.96,0.64-1.53,1.29-2.16,1.38c-0.89,0.11-1.44-0.62-1.5-1.52c-0.06-0.87,0.94-1.84,1.71-1.53
	C449.08,243.82,449.54,244.48,450.4,245.25z"
              />
              <path
                d="M364.98,425.94c-0.88,0.72-1.37,1.36-2,1.57c-0.8,0.27-1.71-0.7-1.63-1.59c0.09-0.91,0.67-1.59,1.54-1.45
	C363.52,424.59,364.06,425.26,364.98,425.94z"
              />
              <path
                d="M446.25,533.94c0.08,1.27-0.72,2.14-1.57,2.08c-0.89-0.07-1.45-0.57-1.53-1.48c-0.08-0.86,0.77-1.86,1.56-1.65
	C445.36,533.06,445.88,533.67,446.25,533.94z"
              />
              <path
                d="M297.91,212.79c-1.38,0.07-2.12-0.62-2.08-1.56c0.05-0.92,0.55-1.45,1.48-1.51c0.92-0.06,1.85,0.72,1.63,1.56
	C298.77,211.92,298.16,212.44,297.91,212.79z"
              />
              <path
                d="M386.73,444.05c0.3,0.35,0.94,0.82,1.17,1.45c0.3,0.81-0.55,1.74-1.43,1.75c-0.92,0.01-1.46-0.52-1.62-1.39
	C384.7,445.01,385.48,444.11,386.73,444.05z"
              />
              <path
                d="M499.37,585.93c1.36-0.05,2.18,0.69,2.1,1.57c-0.08,0.89-0.59,1.44-1.51,1.5c-0.94,0.06-1.81-0.69-1.59-1.58
	C498.52,586.8,499.12,586.28,499.37,585.93z"
              />
              <path
                d="M459.6,538.33c0.69,0.93,1.35,1.45,1.47,2.07c0.16,0.86-0.45,1.47-1.4,1.54c-0.96,0.07-1.83-0.69-1.59-1.58
	C458.26,539.74,458.89,539.25,459.6,538.33z"
              />
              <path
                d="M294.38,181.11c1.27,0.03,2.03,0.89,1.91,1.76c-0.12,0.91-0.69,1.4-1.58,1.43c-0.86,0.03-1.76-0.92-1.48-1.72
	C293.46,181.95,294.09,181.47,294.38,181.11z"
              />
              <path
                d="M458.94,575.16c-0.74-0.9-1.44-1.39-1.57-2c-0.19-0.84,0.37-1.49,1.33-1.6c0.96-0.11,1.86,0.6,1.66,1.5
	C460.22,573.69,459.61,574.21,458.94,575.16z"
              />
              <path
                d="M464.28,422.95c0.29,0.36,0.92,0.85,1.14,1.48c0.27,0.79-0.64,1.74-1.5,1.7c-0.89-0.04-1.46-0.53-1.57-1.44
	C462.25,423.82,463.02,422.97,464.28,422.95z"
              />
              <path
                d="M453.28,573.24c-0.19,1.36-0.75,1.85-1.68,1.83c-0.95-0.02-1.46-0.61-1.52-1.53c-0.05-0.91,0.85-1.77,1.73-1.48
	C452.44,572.27,452.92,572.93,453.28,573.24z"
              />
              <path
                d="M260.16,368.36c-0.69-0.94-1.32-1.46-1.48-2.1c-0.22-0.86,0.74-1.63,1.68-1.52c0.94,0.11,1.55,0.72,1.34,1.6
	C261.55,366.96,260.88,367.45,260.16,368.36z"
              />
              <path
                d="M361.87,263.67c-0.86,0.76-1.32,1.43-1.95,1.66c-0.8,0.3-1.74-0.63-1.7-1.53c0.04-0.94,0.64-1.61,1.5-1.51
	C360.35,262.36,360.92,263.03,361.87,263.67z"
              />
              <path
                d="M486.65,225.29c-1.36,0-2.15-0.78-2.03-1.65c0.12-0.88,0.64-1.42,1.58-1.44c0.94-0.02,1.78,0.76,1.53,1.64
	C487.55,224.46,486.92,224.95,486.65,225.29z"
              />
              <path
                d="M538.62,622.46c-0.64-0.98-1.23-1.53-1.37-2.18c-0.18-0.84,0.87-1.67,1.71-1.49c0.89,0.19,1.51,0.82,1.26,1.69
	C540.05,621.1,539.37,621.57,538.62,622.46z"
              />
              <path
                d="M275.74,105.06c-0.94,0.67-1.48,1.34-2.11,1.45c-0.89,0.15-1.46-0.55-1.54-1.46c-0.08-0.88,0.86-1.87,1.65-1.59
	C274.37,103.67,274.86,104.32,275.74,105.06z"
              />
              <path
                d="M470.67,422.28c1.34,0.02,2.14,0.83,2.01,1.69c-0.14,0.88-0.67,1.4-1.6,1.41c-0.94,0.01-1.77-0.8-1.5-1.67
	C469.77,423.09,470.41,422.61,470.67,422.28z"
              />
              <path
                d="M444.63,366.29c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.4,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C443.36,367.78,443.97,367.24,444.63,366.29z"
              />
              <path
                d="M242.1,389.79c-0.1,0.96-0.61,1.49-1.56,1.5c-0.95,0.01-1.49-0.54-1.59-1.46c-0.1-0.9,0.74-1.67,1.68-1.6
	C241.59,388.31,242.04,388.89,242.1,389.79z"
              />
              <path
                d="M298.24,204.08c-0.09,0.96-0.61,1.5-1.55,1.51c-0.95,0.02-1.49-0.54-1.59-1.45c-0.1-0.91,0.73-1.68,1.67-1.61
	C297.73,202.61,298.18,203.18,298.24,204.08z"
              />
              <path
                d="M449.39,553.8c-0.29-0.34-0.93-0.81-1.16-1.44c-0.29-0.77,0.6-1.78,1.44-1.75c0.88,0.02,1.48,0.49,1.61,1.4
	C451.41,552.88,450.69,553.73,449.39,553.8z"
              />
              <path
                d="M333.21,482.76c0.86-0.76,1.32-1.47,1.94-1.64c0.86-0.24,1.49,0.38,1.66,1.28c0.16,0.87-0.63,1.93-1.45,1.75
	C334.71,484,334.16,483.4,333.21,482.76z"
              />
              <path
                d="M393.77,423.64c-0.92,0.68-1.44,1.35-2.07,1.47c-0.88,0.16-1.46-0.52-1.55-1.42c-0.09-0.87,0.82-1.87,1.61-1.61
	C392.39,422.28,392.88,422.92,393.77,423.64z"
              />
              <path
                d="M400.68,342.98c0.07,1.27-0.74,2.13-1.59,2.06c-0.89-0.07-1.44-0.59-1.52-1.5c-0.07-0.86,0.79-1.86,1.58-1.63
	C399.79,342.09,400.31,342.71,400.68,342.98z"
              />
              <path
                d="M446.64,181.81c0.7,0.94,1.33,1.45,1.5,2.09c0.23,0.87-0.71,1.63-1.66,1.53c-0.95-0.1-1.56-0.7-1.35-1.58
	C445.27,183.22,445.93,182.72,446.64,181.81z"
              />
              <path
                d="M393.12,455.88c-0.92,0.74-1.41,1.4-2.04,1.57c-0.88,0.24-1.51-0.34-1.66-1.27c-0.15-0.93,0.6-1.86,1.51-1.68
	C391.57,454.64,392.12,455.23,393.12,455.88z"
              />
              <path
                d="M454.23,181.6c0.29,0.36,0.91,0.86,1.12,1.5c0.26,0.8-0.67,1.73-1.52,1.68c-0.89-0.06-1.45-0.55-1.55-1.46
	C452.18,182.45,452.97,181.6,454.23,181.6z"
              />
              <path
                d="M453.08,213.7c0.7,0.91,1.33,1.42,1.52,2.06c0.24,0.83-0.73,1.72-1.6,1.62c-0.91-0.11-1.56-0.73-1.39-1.59
	C451.73,215.16,452.4,214.63,453.08,213.7z"
              />
              <path
                d="M456.82,553.57c-0.65-0.97-1.3-1.52-1.39-2.15c-0.11-0.87,0.54-1.45,1.49-1.48c0.95-0.03,1.81,0.8,1.52,1.67
	C458.24,552.23,457.57,552.69,456.82,553.57z"
              />
              <path
                d="M514.1,248.29c-1.38,0.08-2.19-0.63-2.13-1.53c0.06-0.88,0.55-1.45,1.48-1.53c0.96-0.07,1.82,0.64,1.62,1.54
	C514.94,247.41,514.34,247.94,514.1,248.29z"
              />
              <path
                d="M300.99,245.72c0.25,0.34,0.87,0.85,1.04,1.47c0.24,0.88-0.62,1.66-1.56,1.62c-0.92-0.04-1.45-0.58-1.55-1.47
	C298.83,246.46,299.63,245.69,300.99,245.72z"
              />
              <path
                d="M325.77,366.31c0.3,0.35,0.95,0.81,1.19,1.43c0.31,0.81-0.53,1.75-1.41,1.77c-0.92,0.02-1.47-0.5-1.63-1.37
	C323.76,367.3,324.52,366.39,325.77,366.31z"
              />
              <path
                d="M437.44,387.07c-0.88,0.72-1.37,1.36-2,1.57c-0.8,0.27-1.71-0.7-1.63-1.59c0.09-0.91,0.67-1.59,1.54-1.45
	C435.98,385.72,436.51,386.39,437.44,387.07z"
              />
              <path
                d="M402.89,112.14c-0.93,0.71-1.44,1.32-2.08,1.53c-0.71,0.23-1.51-0.66-1.52-1.46c-0.01-0.8,0.75-1.73,1.46-1.53
	C401.41,110.87,401.94,111.46,402.89,112.14z"
              />
              <path
                d="M374.79,105.64c-0.75-0.87-1.39-1.33-1.62-1.95c-0.29-0.78,0.63-1.75,1.48-1.69c0.89,0.06,1.58,0.61,1.45,1.49
	C376.01,104.12,375.4,104.68,374.79,105.64z"
              />
              <path
                d="M508.62,606.22c0.75,0.87,1.4,1.34,1.63,1.96c0.29,0.8-0.62,1.76-1.49,1.7c-0.91-0.06-1.59-0.62-1.47-1.5
	C507.37,607.76,508,607.19,508.62,606.22z"
              />
              <path
                d="M320.33,368.29c-0.85,0.74-1.31,1.44-1.92,1.6c-0.84,0.23-1.47-0.37-1.62-1.27c-0.15-0.89,0.61-1.91,1.44-1.72
	C318.86,367.05,319.39,367.65,320.33,368.29z"
              />
              <path
                d="M344.83,608.36c-0.95,0.68-1.48,1.28-2.13,1.47c-0.71,0.21-1.49-0.73-1.48-1.52c0.01-0.79,0.82-1.7,1.52-1.47
	C343.39,607.05,343.9,607.66,344.83,608.36z"
              />
              <path
                d="M293.16,502.11c-0.27-0.34-0.95-0.85-1.08-1.46c-0.18-0.89,0.45-1.49,1.4-1.53c0.96-0.04,1.48,0.51,1.57,1.43
	C295.14,501.4,294.47,502.08,293.16,502.11z"
              />
              <path
                d="M338.73,476.68c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.54c-0.84,0.09-1.8-0.85-1.53-1.64
	C337.39,478.05,338.02,477.57,338.73,476.68z"
              />
              <path
                d="M224.1,214.61c-0.87,0.57-1.41,0.92-1.95,1.28c-0.35-0.65-0.87-1.27-1-1.96c-0.13-0.7,0.48-1.34,1.18-1.04
	C222.94,213.14,223.35,213.85,224.1,214.61z"
              />
              <path
                d="M509.87,388c-0.86,0.72-1.34,1.41-1.95,1.57c-0.85,0.21-1.47-0.39-1.6-1.3c-0.13-0.89,0.65-1.89,1.47-1.69
	C508.42,386.74,508.95,387.35,509.87,388z"
              />
              <path
                d="M491.56,575.89c-0.7-0.89-1.36-1.38-1.52-2c-0.22-0.87,0.4-1.47,1.28-1.63c0.85-0.15,1.86,0.69,1.65,1.51
	C492.82,574.41,492.22,574.94,491.56,575.89z"
              />
              <path
                d="M432.37,187.23c-0.75-0.87-1.39-1.34-1.62-1.96c-0.29-0.79,0.64-1.77,1.49-1.71c0.9,0.07,1.59,0.63,1.47,1.5
	C433.62,185.7,432.99,186.26,432.37,187.23z"
              />
              <path
                d="M474.35,422.08c-0.65-0.95-1.26-1.48-1.39-2.1c-0.19-0.89,0.71-1.59,1.66-1.48c0.95,0.11,1.5,0.77,1.3,1.6
	C475.78,420.71,475.1,421.19,474.35,422.08z"
              />
              <path
                d="M259.74,91.74c-0.05,1.36-0.8,2.06-1.74,1.83c-0.41-0.1-1.06-0.86-1-0.96c0.41-0.69,0.93-1.34,1.55-1.83
	C258.67,90.7,259.46,91.51,259.74,91.74z"
              />
              <path
                d="M309.36,365.64c-0.9,0.75-1.37,1.4-1.98,1.58c-0.87,0.26-1.49-0.34-1.66-1.23c-0.17-0.9,0.58-1.85,1.47-1.68
	C307.82,364.43,308.37,365.01,309.36,365.64z"
              />
              <path
                d="M468.14,572.37c-0.9,0.68-1.41,1.31-2.05,1.49c-0.81,0.23-1.66-0.76-1.54-1.64c0.12-0.91,0.72-1.55,1.58-1.37
	C466.74,570.98,467.25,571.67,468.14,572.37z"
              />
              <path
                d="M389.94,420.38c-1,0.63-1.57,1.28-2.2,1.34c-0.88,0.08-1.41-0.64-1.38-1.58c0.02-0.81,0.86-1.65,1.58-1.38
	C388.56,418.99,389.04,419.62,389.94,420.38z"
              />
              <path
                d="M425.01,140.31c-1.11-0.01-1.68-0.51-1.72-1.44c-0.03-0.92,0.48-1.52,1.45-1.55c0.94-0.03,1.53,0.49,1.57,1.43
	C426.35,139.7,425.79,140.2,425.01,140.31z"
              />
              <path
                d="M392.79,622.1c-0.84,0.75-1.3,1.45-1.91,1.63c-0.84,0.24-1.48-0.35-1.64-1.25c-0.16-0.89,0.59-1.91,1.42-1.73
	C391.3,620.88,391.84,621.47,392.79,622.1z"
              />
              <path
                d="M424.22,608.83c-0.9,0.69-1.41,1.37-2.04,1.49c-0.84,0.15-1.46-0.48-1.56-1.4c-0.09-0.86,0.79-1.87,1.58-1.62
	C422.84,607.49,423.33,608.13,424.22,608.83z"
              />
              <path
                d="M571.89,626.73c1.05,0.13,1.54,0.72,1.47,1.63c-0.07,0.93-0.67,1.44-1.62,1.36c-0.96-0.07-1.46-0.67-1.39-1.6
	C570.42,627.18,571.05,626.75,571.89,626.73z"
              />
              <path
                d="M360.3,474.73c0.66,0.98,1.24,1.52,1.4,2.17c0.19,0.74-0.72,1.56-1.49,1.48c-0.9-0.1-1.61-0.65-1.43-1.55
	C358.91,476.21,359.58,475.68,360.3,474.73z"
              />
              <path
                d="M497.39,244.49c-0.85,0.74-1.31,1.44-1.92,1.6c-0.84,0.23-1.47-0.37-1.62-1.27c-0.15-0.89,0.61-1.91,1.44-1.72
	C495.92,243.25,496.46,243.85,497.39,244.49z"
              />
              <path
                d="M354.64,596.79c-0.88,0.7-1.37,1.34-2,1.54c-0.79,0.25-1.68-0.74-1.6-1.61c0.09-0.93,0.73-1.55,1.55-1.42
	C353.22,595.41,353.74,596.11,354.64,596.79z"
              />
              <path
                d="M481.58,211.31c0.71,0.89,1.39,1.39,1.54,2.01c0.21,0.86-0.4,1.48-1.3,1.64c-0.85,0.15-1.87-0.7-1.67-1.52
	C480.31,212.8,480.92,212.26,481.58,211.31z"
              />
              <path
                d="M293.94,439.39c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C292.51,438.05,293.03,438.72,293.94,439.39z"
              />
              <path
                d="M368.74,476.19c-0.06,0.83-0.52,1.4-1.48,1.43c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C368.03,474.61,368.71,475.24,368.74,476.19z"
              />
              <path
                d="M327.61,477.64c-0.87-0.03-1.47-0.49-1.51-1.44c-0.03-0.93,0.49-1.51,1.45-1.55c0.96-0.05,1.49,0.5,1.58,1.43
	C329.21,476.92,328.54,477.61,327.61,477.64z"
              />
              <path
                d="M379.61,345.29c-0.92,0.74-1.42,1.46-2.04,1.57c-0.85,0.16-1.51-0.48-1.56-1.42c-0.04-0.95,0.58-1.62,1.43-1.53
	C378.07,343.97,378.63,344.64,379.61,345.29z"
              />
              <path
                d="M375.94,475.71c-0.06,0.83-0.52,1.4-1.48,1.44c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C375.22,474.13,375.91,474.76,375.94,475.71z"
              />
              <path
                d="M361.77,310.5c-0.05,0.89-0.44,1.52-1.42,1.58c-0.95,0.05-1.55-0.43-1.62-1.37c-0.06-0.93,0.44-1.53,1.39-1.6
	C361.09,309.03,361.6,309.57,361.77,310.5z"
              />
              <path
                d="M370.4,343.77c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.53c-0.84,0.09-1.8-0.85-1.53-1.64
	C369.06,345.14,369.69,344.65,370.4,343.77z"
              />
              <path
                d="M509.89,244.91c-1.03,0.02-1.62-0.48-1.67-1.41c-0.05-0.92,0.45-1.53,1.41-1.58c0.95-0.04,1.54,0.46,1.6,1.4
	C511.29,244.26,510.76,244.8,509.89,244.91z"
              />
              <path
                d="M331.3,390.56c0.66,0.95,1.27,1.47,1.41,2.09c0.2,0.89-0.69,1.59-1.65,1.49c-0.95-0.11-1.5-0.75-1.31-1.59
	C329.89,391.94,330.57,391.45,331.3,390.56z"
              />
              <path
                d="M348.9,73.47c-1,0.63-1.58,1.27-2.2,1.31c-0.85,0.06-1.46-0.62-1.39-1.57c0.07-0.93,0.74-1.55,1.59-1.36
	C347.52,71.98,348,72.7,348.9,73.47z"
              />
              <path
                d="M351.27,600.66c-0.88,0.69-1.38,1.32-2.01,1.51c-0.79,0.24-1.66-0.77-1.56-1.62c0.1-0.92,0.74-1.53,1.56-1.38
	C349.87,599.28,350.38,599.97,351.27,600.66z"
              />
              <path
                d="M390.42,427.4c-0.93,0.71-1.44,1.32-2.08,1.53c-0.71,0.23-1.51-0.66-1.52-1.46c-0.02-0.8,0.75-1.73,1.46-1.53
	C388.93,426.12,389.46,426.72,390.42,427.4z"
              />
              <path
                d="M378.08,417.69c-0.12,0.88-0.61,1.43-1.58,1.39c-0.95-0.04-1.49-0.6-1.45-1.54c0.03-0.94,0.62-1.44,1.56-1.45
	C377.47,416.09,378.11,416.8,378.08,417.69z"
              />
              <path
                d="M411.32,125.96c-0.98,0.64-1.53,1.24-2.16,1.35c-0.89,0.15-1.44-0.49-1.5-1.41c-0.05-0.93,0.76-1.76,1.64-1.5
	C409.92,124.58,410.4,125.22,411.32,125.96z"
              />
              <path
                d="M284.78,338.93c-0.12,0.96-0.64,1.47-1.58,1.47c-0.87,0-1.54-0.69-1.47-1.54c0.08-0.93,0.6-1.48,1.56-1.44
	C284.25,337.44,284.7,338.03,284.78,338.93z"
              />
              <path
                d="M265.8,262.68c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.53c-0.84,0.09-1.8-0.85-1.53-1.64
	C264.45,264.04,265.08,263.56,265.8,262.68z"
              />
              <path
                d="M233.55,242.37c-0.92,0.72-1.4,1.36-2.01,1.53c-0.88,0.24-1.48-0.37-1.62-1.27c-0.14-0.9,0.62-1.83,1.5-1.64
	C232.04,241.13,232.57,241.72,233.55,242.37z"
              />
              <path
                d="M347.92,604.49c-0.89,0.69-1.39,1.33-2.02,1.52c-0.79,0.24-1.67-0.77-1.57-1.63c0.1-0.92,0.74-1.54,1.57-1.39
	C346.52,603.1,347.03,603.8,347.92,604.49z"
              />
              <path
                d="M382.61,361.38c1.12,0.05,1.68,0.57,1.69,1.5c0.01,0.93-0.53,1.5-1.49,1.51c-0.95,0-1.51-0.53-1.52-1.48
	C381.27,361.96,381.87,361.49,382.61,361.38z"
              />
              <path
                d="M432.69,605.75c-0.88-0.03-1.47-0.5-1.5-1.45c-0.03-0.94,0.51-1.5,1.46-1.54c0.96-0.04,1.49,0.51,1.57,1.44
	C434.29,605.05,433.61,605.73,432.69,605.75z"
              />
              <path
                d="M345.04,362.5c-0.84,0.76-1.29,1.42-1.91,1.66c-0.78,0.3-1.73-0.62-1.7-1.5c0.04-0.93,0.62-1.59,1.47-1.5
	C343.53,361.22,344.09,361.87,345.04,362.5z"
              />
              <path
                d="M274.04,320.13c-0.64-0.94-1.27-1.48-1.38-2.11c-0.15-0.87,0.53-1.44,1.41-1.53c0.85-0.09,1.79,0.85,1.53,1.65
	C275.39,318.76,274.76,319.24,274.04,320.13z"
              />
              <path
                d="M455.65,428.83c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C454.22,427.49,454.74,428.16,455.65,428.83z"
              />
              <path
                d="M328.02,541.11c-0.88,0.71-1.37,1.35-2,1.55c-0.8,0.26-1.69-0.71-1.6-1.59c0.09-0.91,0.68-1.58,1.54-1.43
	C326.58,539.76,327.11,540.44,328.02,541.11z"
              />
              <path
                d="M393.3,336.82c-0.97,0.66-1.53,1.33-2.16,1.41c-0.87,0.1-1.43-0.6-1.43-1.54c-0.01-0.79,0.84-1.67,1.54-1.43
	C391.88,335.48,392.38,336.09,393.3,336.82z"
              />
              <path
                d="M527.15,296.29c-0.85,0.75-1.31,1.46-1.92,1.64c-0.85,0.25-1.48-0.36-1.65-1.26c-0.17-0.88,0.61-1.93,1.43-1.75
	C525.66,295.06,526.2,295.66,527.15,296.29z"
              />
              <path
                d="M408.49,116.57c0.69,0.92,1.31,1.42,1.48,2.05c0.23,0.88-0.65,1.62-1.6,1.54c-0.94-0.08-1.54-0.7-1.36-1.55
	C407.12,117.99,407.79,117.49,408.49,116.57z"
              />
              <path
                d="M361.84,429.83c-0.89,0.69-1.39,1.33-2.02,1.52c-0.79,0.24-1.67-0.77-1.57-1.63c0.1-0.92,0.74-1.54,1.57-1.39
	C360.43,428.44,360.95,429.14,361.84,429.83z"
              />
              <path
                d="M385.83,417.03c-0.95,0.63-1.51,1.27-2.13,1.35c-0.86,0.1-1.43-0.57-1.47-1.49c-0.04-0.88,0.9-1.8,1.69-1.51
	C384.53,415.61,384.98,416.27,385.83,417.03z"
              />
              <path
                d="M477.89,567.71c0.04,0.86-0.59,1.56-1.45,1.56c-0.94,0.01-1.54-0.48-1.58-1.42c-0.04-0.93,0.49-1.49,1.44-1.56
	C477.15,566.23,477.85,566.87,477.89,567.71z"
              />
              <path
                d="M347.92,628.18c-0.75-0.87-1.39-1.33-1.62-1.95c-0.29-0.78,0.63-1.75,1.48-1.69c0.89,0.06,1.58,0.61,1.45,1.49
	C349.14,626.67,348.53,627.22,347.92,628.18z"
              />
              <path
                d="M366.95,265.18c-0.69-0.93-1.31-1.43-1.47-2.05c-0.22-0.88,0.66-1.62,1.61-1.54c0.94,0.08,1.53,0.71,1.36,1.55
	C368.32,263.75,367.65,264.26,366.95,265.18z"
              />
              <path
                d="M358.67,567.36c-0.06,0.83-0.52,1.4-1.48,1.43c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C357.95,565.78,358.63,566.41,358.67,567.36z"
              />
              <path
                d="M420.84,612.59c-0.87,0.71-1.35,1.36-1.97,1.56c-0.79,0.26-1.68-0.7-1.61-1.57c0.07-0.92,0.7-1.55,1.52-1.42
	C419.41,611.25,419.93,611.93,420.84,612.59z"
              />
              <path
                d="M386.33,464.06c-1,0.63-1.58,1.27-2.2,1.31c-0.85,0.06-1.46-0.62-1.39-1.57c0.07-0.93,0.74-1.55,1.59-1.36
	C384.94,462.57,385.42,463.29,386.33,464.06z"
              />
              <path
                d="M434.76,398.01c-0.84,0.76-1.29,1.41-1.9,1.65c-0.78,0.3-1.72-0.62-1.68-1.48c0.03-0.92,0.62-1.58,1.46-1.49
	C433.25,396.74,433.81,397.38,434.76,398.01z"
              />
              <path
                d="M475.35,239.37c-0.95,0.68-1.48,1.28-2.13,1.47c-0.71,0.21-1.49-0.73-1.48-1.52c0.01-0.79,0.82-1.7,1.52-1.47
	C473.91,238.05,474.42,238.66,475.35,239.37z"
              />
              <path
                d="M655.43,629.74c0.65,0.94,1.3,1.48,1.4,2.12c0.14,0.86-0.52,1.46-1.43,1.55c-0.85,0.08-1.81-0.86-1.54-1.66
	C654.08,631.11,654.71,630.62,655.43,629.74z"
              />
              <path
                d="M427.51,252.09c0.64,0.99,1.22,1.54,1.37,2.19c0.18,0.76-0.73,1.55-1.51,1.46c-0.89-0.11-1.6-0.67-1.41-1.56
	C426.1,253.54,426.78,253.03,427.51,252.09z"
              />
              <path
                d="M288.35,89.48c-0.12,0.88-0.61,1.43-1.57,1.4c-0.95-0.04-1.49-0.6-1.46-1.54c0.03-0.94,0.61-1.45,1.56-1.45
	C287.73,87.88,288.38,88.59,288.35,89.48z"
              />
              <path
                d="M403.41,617.85c-1,0.62-1.56,1.2-2.19,1.29c-0.91,0.14-1.42-0.55-1.47-1.45c-0.04-0.92,0.82-1.74,1.69-1.45
	C402.05,616.44,402.51,617.09,403.41,617.85z"
              />
              <path
                d="M347.16,225.2c-0.12,0.96-0.64,1.47-1.58,1.47c-0.87,0-1.54-0.69-1.47-1.54c0.08-0.93,0.6-1.48,1.56-1.44
	C346.63,223.72,347.08,224.31,347.16,225.2z"
              />
              <path
                d="M375.51,441.52c0.87,0.11,1.41,0.63,1.37,1.57c-0.04,0.95-0.63,1.43-1.58,1.43c-0.87-0.01-1.53-0.71-1.45-1.56
	C373.95,442.03,374.48,441.53,375.51,441.52z"
              />
              <path
                d="M387.34,331.54c0.71,0.91,1.34,1.4,1.51,2.02c0.25,0.88-0.62,1.63-1.58,1.57c-0.94-0.07-1.55-0.67-1.39-1.53
	C386,332.98,386.65,332.46,387.34,331.54z"
              />
              <path
                d="M428.64,143.67c-0.98-0.06-1.53-0.59-1.51-1.52c0.01-0.93,0.56-1.49,1.52-1.48c0.95,0.02,1.5,0.57,1.49,1.51
	C430.14,143.13,429.56,143.62,428.64,143.67z"
              />
              <path
                d="M247.16,400.07c-0.88,0.7-1.38,1.33-2.02,1.53c-0.79,0.24-1.67-0.76-1.58-1.62c0.1-0.92,0.74-1.55,1.56-1.4
	C245.75,398.69,246.26,399.38,247.16,400.07z"
              />
              <path
                d="M304.02,348.26c-0.89,0.69-1.39,1.31-2.02,1.5c-0.81,0.24-1.64-0.72-1.55-1.61c0.1-0.91,0.7-1.54,1.55-1.38
	C302.62,346.9,303.13,347.57,304.02,348.26z"
              />
              <path
                d="M305.47,322.83c-0.9,0.69-1.4,1.32-2.04,1.51c-0.8,0.24-1.66-0.75-1.56-1.63c0.11-0.91,0.71-1.56,1.56-1.39
	C304.06,321.45,304.57,322.14,305.47,322.83z"
              />
              <path
                d="M479.86,210.07c-0.89,0.69-1.38,1.32-2.01,1.51c-0.79,0.24-1.66-0.77-1.56-1.62c0.1-0.92,0.74-1.53,1.56-1.38
	C478.47,208.69,478.98,209.38,479.86,210.07z"
              />
              <path
                d="M329.63,542.42c0.75,0.87,1.39,1.33,1.62,1.95c0.29,0.79-0.62,1.74-1.48,1.69c-0.9-0.06-1.58-0.61-1.45-1.48
	C328.41,543.94,329.02,543.38,329.63,542.42z"
              />
              <path
                d="M384.33,322.93c-0.06,0.94-0.45,1.57-1.42,1.62c-0.95,0.05-1.55-0.43-1.62-1.37c-0.06-0.93,0.44-1.52,1.39-1.6
	C383.65,321.5,384.14,322.06,384.33,322.93z"
              />
              <path
                d="M490.2,411.56c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C488.77,410.22,489.29,410.89,490.2,411.56z"
              />
              <path
                d="M609.63,207.96c0.29-0.11,0.59-0.22,1.09-0.4c0.35,0.73,0.79,1.44,1.03,2.22c0.11,0.36-0.07,1.12-0.29,1.19
	c-0.44,0.15-1.13,0.13-1.47-0.14c-1.07-0.83-0.82-1.91-0.28-2.96C609.71,207.88,609.63,207.96,609.63,207.96z"
              />
              <path
                d="M358.25,473.5c-0.9,0.69-1.4,1.32-2.04,1.51c-0.8,0.24-1.66-0.75-1.56-1.63c0.11-0.91,0.71-1.56,1.56-1.39
	C356.84,472.12,357.36,472.81,358.25,473.5z"
              />
              <path
                d="M386.8,111.86c0.27,0.35,0.95,0.85,1.08,1.47c0.19,0.89-0.46,1.49-1.41,1.53c-0.96,0.04-1.49-0.5-1.57-1.43
	C384.82,112.59,385.5,111.9,386.8,111.86z"
              />
              <path
                d="M299.48,614.28c-0.65-0.94-1.3-1.48-1.41-2.12c-0.14-0.86,0.53-1.45,1.42-1.55c0.85-0.09,1.81,0.86,1.54,1.66
	C300.83,612.9,300.19,613.39,299.48,614.28z"
              />
              <path
                d="M392.01,96.17c0.75,0.87,1.39,1.33,1.62,1.95c0.29,0.79-0.62,1.74-1.48,1.69c-0.9-0.06-1.58-0.61-1.45-1.48
	C390.79,97.69,391.4,97.13,392.01,96.17z"
              />
              <path
                d="M396.64,106.72c0.71,0.89,1.37,1.38,1.53,2c0.22,0.86-0.39,1.47-1.28,1.63c-0.84,0.15-1.86-0.69-1.66-1.51
	C395.39,108.2,395.99,107.67,396.64,106.72z"
              />
              <path
                d="M367.1,347.59c0.73,0.88,1.38,1.36,1.6,1.98c0.28,0.8-0.66,1.75-1.51,1.68c-0.91-0.07-1.58-0.65-1.45-1.52
	C365.83,349.1,366.46,348.55,367.1,347.59z"
              />
              <path
                d="M438.88,401.61c-0.98,0.66-1.54,1.32-2.17,1.39c-0.87,0.1-1.42-0.61-1.42-1.55c0-0.79,0.85-1.67,1.55-1.42
	C437.47,400.26,437.97,400.88,438.88,401.61z"
              />
              <path
                d="M345.28,615.42c-0.85,0.73-1.32,1.43-1.93,1.6c-0.84,0.23-1.47-0.37-1.62-1.28c-0.15-0.89,0.62-1.91,1.44-1.71
	C343.82,614.17,344.35,614.77,345.28,615.42z"
              />
              <path
                d="M361.68,579.36c-0.87-0.03-1.47-0.49-1.51-1.44c-0.03-0.93,0.49-1.51,1.45-1.55c0.96-0.05,1.49,0.5,1.58,1.43
	C363.28,578.65,362.61,579.33,361.68,579.36z"
              />
              <path
                d="M525.22,258.71c-0.65-0.95-1.24-1.49-1.39-2.13c-0.19-0.83,0.83-1.65,1.67-1.49c0.88,0.17,1.5,0.78,1.26,1.64
	C526.6,257.35,525.94,257.83,525.22,258.71z"
              />
              <path
                d="M440.8,383.17c-0.91,0.75-1.39,1.38-2.02,1.61c-0.73,0.26-1.54-0.56-1.57-1.39c-0.02-0.95,0.52-1.65,1.39-1.56
	C439.24,381.88,439.81,382.53,440.8,383.17z"
              />
              <path
                d="M355.57,594.82c-0.64-0.96-1.27-1.5-1.35-2.12c-0.12-0.87,0.52-1.44,1.45-1.47c0.93-0.03,1.79,0.8,1.51,1.64
	C356.98,593.48,356.33,593.94,355.57,594.82z"
              />
              <path
                d="M492.89,234.36c-0.97,0.66-1.5,1.27-2.13,1.39c-0.88,0.17-1.46-0.46-1.53-1.39c-0.07-0.93,0.72-1.78,1.62-1.53
	C491.48,233.01,491.97,233.64,492.89,234.36z"
              />
              <path
                d="M682.61,591.36c-0.17-1.16-1.05-2.46,0.77-3.26c1.07,0.52,1.64,1.45,1.35,2.62c-0.32,1.3-1.47,0.32-2.21,0.55
	C682.53,591.28,682.61,591.36,682.61,591.36z"
              />
              <path
                d="M353.92,256.98c-0.85,0.73-1.32,1.43-1.93,1.6c-0.84,0.23-1.47-0.37-1.62-1.28c-0.15-0.89,0.62-1.91,1.44-1.71
	C352.45,255.73,352.99,256.33,353.92,256.98z"
              />
              <path
                d="M263.16,257.71c0.85-0.75,1.31-1.46,1.93-1.63c0.85-0.24,1.48,0.37,1.65,1.27c0.16,0.87-0.62,1.92-1.44,1.74
	C264.66,258.95,264.11,258.35,263.16,257.71z"
              />
              <path
                d="M517.79,355.13c-0.95,0.63-1.51,1.27-2.13,1.35c-0.86,0.1-1.43-0.57-1.47-1.49c-0.04-0.88,0.9-1.8,1.69-1.51
	C516.49,353.71,516.94,354.37,517.79,355.13z"
              />
              <path
                d="M302.11,326.8c-0.94,0.64-1.49,1.24-2.13,1.38c-0.82,0.19-1.6-0.86-1.44-1.74c0.16-0.9,0.8-1.51,1.65-1.28
	C300.79,325.35,301.26,326.05,302.11,326.8z"
              />
              <path
                d="M462.76,578.29c-0.7-0.89-1.36-1.38-1.52-2c-0.22-0.87,0.4-1.47,1.29-1.62c0.85-0.15,1.86,0.69,1.65,1.51
	C464.03,576.81,463.43,577.34,462.76,578.29z"
              />
              <path
                d="M347.02,550.36c-0.9,0.75-1.37,1.41-1.98,1.59c-0.86,0.26-1.5-0.31-1.66-1.22c-0.17-0.9,0.56-1.85,1.46-1.68
	C345.47,549.17,346.02,549.74,347.02,550.36z"
              />
              <path
                d="M332.67,365.12c0.69,0.92,1.31,1.42,1.48,2.05c0.23,0.88-0.65,1.62-1.6,1.54c-0.94-0.08-1.54-0.7-1.36-1.55
	C331.31,366.55,331.97,366.04,332.67,365.12z"
              />
              <path
                d="M312.06,22.54c-0.85,0.65-1.35,1.26-1.97,1.44c-0.79,0.23-1.32-0.28-1.31-1.18c0.01-0.94,0.82-1.99,1.48-1.74
	C310.84,21.27,311.26,21.85,312.06,22.54z"
              />
              <path
                d="M359.6,467.53c0.67,0.99,1.31,1.55,1.4,2.19c0.13,0.9-0.62,1.42-1.51,1.47c-0.76,0.05-1.63-0.84-1.41-1.57
	C358.28,468.99,358.88,468.48,359.6,467.53z"
              />
              <path
                d="M266.14,203.4c-0.96,0.67-1.49,1.26-2.14,1.45c-0.71,0.2-1.47-0.74-1.46-1.53c0.02-0.79,0.83-1.69,1.53-1.45
	C264.71,202.07,265.21,202.68,266.14,203.4z"
              />
              <path
                d="M308.34,232.02c-0.85,0.74-1.32,1.45-1.94,1.62c-0.86,0.24-1.48-0.38-1.64-1.28c-0.16-0.87,0.64-1.92,1.45-1.73
	C306.87,230.77,307.4,231.37,308.34,232.02z"
              />
              <path
                d="M350.77,205.19c-0.64-0.96-1.27-1.5-1.35-2.12c-0.12-0.87,0.52-1.44,1.45-1.47c0.93-0.03,1.79,0.8,1.51,1.64
	C352.18,203.85,351.53,204.31,350.77,205.19z"
              />
              <path
                d="M486.8,232.44c-0.88-0.09-1.43-0.61-1.38-1.56c0.04-0.94,0.62-1.46,1.57-1.43c0.96,0.03,1.48,0.63,1.44,1.55
	C488.39,231.93,487.82,232.43,486.8,232.44z"
              />
              <path
                d="M442.71,405.02c-0.99,0.63-1.56,1.27-2.19,1.32c-0.87,0.07-1.39-0.63-1.36-1.56c0.03-0.8,0.86-1.63,1.56-1.36
	C441.34,403.65,441.82,404.27,442.71,405.02z"
              />
              <path
                d="M312.67,488.22c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C311.09,486.96,311.68,487.6,312.67,488.22z"
              />
              <path
                d="M442.04,381.55c-0.66-0.96-1.3-1.5-1.4-2.13c-0.14-0.88,0.54-1.49,1.45-1.47c0.89,0.02,1.6,0.61,1.44,1.5
	C443.41,380.08,442.74,380.61,442.04,381.55z"
              />
              <path
                d="M379.85,471.73c-0.99,0.62-1.57,1.26-2.2,1.31c-0.87,0.07-1.39-0.64-1.35-1.57c0.03-0.81,0.86-1.62,1.57-1.36
	C378.49,470.35,378.96,470.98,379.85,471.73z"
              />
              <path
                d="M383.21,467.83c-0.97,0.65-1.53,1.3-2.16,1.37c-0.88,0.1-1.4-0.6-1.4-1.53c0-0.81,0.82-1.65,1.53-1.4
	C381.81,466.49,382.3,467.11,383.21,467.83z"
              />
              <path
                d="M364.98,465.82c-0.94,0.69-1.47,1.36-2.09,1.46c-0.88,0.15-1.46-0.54-1.46-1.45c-0.01-0.92,0.59-1.6,1.46-1.46
	C363.51,464.46,364.04,465.13,364.98,465.82z"
              />
              <path
                d="M415.57,136.05c-0.9,0.75-1.38,1.46-1.99,1.59c-0.84,0.17-1.5-0.45-1.57-1.37c-0.07-0.94,0.55-1.61,1.39-1.54
	C414.02,134.78,414.59,135.41,415.57,136.05z"
              />
              <path
                d="M353.18,562.08c0.74,0.93,1.42,1.43,1.57,2.05c0.2,0.89-0.5,1.49-1.38,1.55c-0.91,0.06-1.61-0.51-1.49-1.41
	C351.96,563.64,352.56,563.08,353.18,562.08z"
              />
              <path
                d="M443.19,411.93c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C441.61,410.66,442.19,411.31,443.19,411.93z"
              />
              <path
                d="M278.95,67.29c0.76,1.96,0.41,3.04-0.9,3.12c-0.45,0.03-1.06-0.3-1.35-0.67c-0.16-0.21,0.07-0.95,0.34-1.22
	C277.54,68.06,278.19,67.77,278.95,67.29z"
              />
              <path
                d="M387.05,431.2c-0.91,0.72-1.42,1.41-2.03,1.54c-0.87,0.18-1.47-0.48-1.51-1.4c-0.04-0.93,0.53-1.62,1.4-1.52
	C385.53,429.89,386.08,430.54,387.05,431.2z"
              />
              <path
                d="M518.72,615.36c-1.04,0.55-1.66,1.15-2.27,1.14c-0.84-0.01-1.41-0.71-1.26-1.66c0.15-0.91,0.84-1.48,1.67-1.23
	C517.46,613.79,517.88,614.53,518.72,615.36z"
              />
              <path
                d="M356.07,72.84c-0.94,0.69-1.47,1.36-2.08,1.45c-0.85,0.13-1.48-0.54-1.48-1.46c0-0.93,0.64-1.58,1.49-1.45
	C354.61,71.47,355.13,72.15,356.07,72.84z"
              />
              <path
                d="M497.89,584.49c-0.99,0.62-1.57,1.26-2.2,1.31c-0.87,0.07-1.39-0.64-1.35-1.57c0.03-0.81,0.86-1.62,1.57-1.36
	C496.53,583.11,497,583.74,497.89,584.49z"
              />
              <path
                d="M425.92,337.59c-0.9,0.74-1.39,1.44-2,1.57c-0.86,0.19-1.48-0.43-1.54-1.37c-0.06-0.94,0.51-1.63,1.37-1.55
	C424.36,336.3,424.93,336.95,425.92,337.59z"
              />
              <path
                d="M345.99,622.6c-0.9,0.75-1.38,1.46-1.99,1.59c-0.84,0.17-1.5-0.45-1.57-1.37c-0.07-0.94,0.55-1.61,1.39-1.54
	C344.45,621.33,345.01,621.97,345.99,622.6z"
              />
              <path
                d="M448.47,595.81c-0.92,0.71-1.44,1.39-2.06,1.51c-0.88,0.17-1.47-0.5-1.49-1.42c-0.03-0.93,0.55-1.61,1.42-1.49
	C446.97,594.49,447.52,595.15,448.47,595.81z"
              />
              <path
                d="M365.95,464.09c-0.67-0.98-1.31-1.53-1.4-2.16c-0.13-0.89,0.61-1.46,1.5-1.44c0.9,0.02,1.57,0.64,1.38,1.53
	C367.3,462.64,366.65,463.15,365.95,464.09z"
              />
              <path
                d="M389.66,626.17c-0.97,0.65-1.52,1.31-2.13,1.37c-0.85,0.09-1.46-0.58-1.42-1.51c0.04-0.93,0.7-1.55,1.54-1.4
	C388.25,624.74,388.75,625.44,389.66,626.17z"
              />
              <path
                d="M379.81,455.45c-0.73-0.92-1.38-1.41-1.54-2.03c-0.22-0.89,0.44-1.53,1.33-1.58c0.87-0.05,1.64,0.49,1.54,1.39
	C381.07,453.88,380.44,454.45,379.81,455.45z"
              />
              <path
                d="M443,597.59c0.7,0.96,1.37,1.48,1.48,2.11c0.16,0.88-0.55,1.47-1.44,1.49c-0.92,0.02-1.58-0.6-1.44-1.48
	C441.7,599.09,442.34,598.56,443,597.59z"
              />
              <path
                d="M504.61,243.99c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C503.03,242.72,503.61,243.36,504.61,243.99z"
              />
              <path
                d="M455.43,242.51c0.72,0.94,1.4,1.45,1.53,2.07c0.18,0.88-0.52,1.48-1.41,1.53c-0.92,0.05-1.6-0.55-1.47-1.44
	C454.17,244.04,454.79,243.49,455.43,242.51z"
              />
              <path
                d="M475.34,571.92c-0.96,0.66-1.51,1.32-2.13,1.41c-0.88,0.12-1.41-0.58-1.43-1.5c-0.02-0.8,0.79-1.66,1.5-1.43
	C473.92,570.61,474.42,571.21,475.34,571.92z"
              />
              <path
                d="M466.11,15.95c0.84-0.51,1.28-0.96,1.76-1.03c0.96-0.12,1.83,0.68,1.79,1.44c-0.04,0.74-0.98,1.57-1.65,1.27
	C467.4,17.36,466.96,16.73,466.11,15.95z"
              />
              <path
                d="M439.37,186.74c-0.67-0.98-1.31-1.53-1.4-2.16c-0.13-0.89,0.61-1.46,1.5-1.44c0.9,0.02,1.57,0.64,1.38,1.53
	C440.71,185.29,440.07,185.81,439.37,186.74z"
              />
              <path
                d="M383.94,458.81c-0.77-0.9-1.46-1.38-1.63-2c-0.24-0.89,0.46-1.45,1.33-1.6c0.77-0.14,1.69,0.6,1.55,1.37
	C385.06,457.23,384.52,457.8,383.94,458.81z"
              />
              <path
                d="M406.3,344.2c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C407.81,343.56,407.17,344.21,406.3,344.2z"
              />
              <path
                d="M365.06,266.96c-0.02,0.88-0.68,1.49-1.56,1.42c-0.84-0.07-1.3-0.56-1.38-1.37c-0.08-0.82,0.61-1.52,1.46-1.55
	C364.44,265.44,365.08,266.09,365.06,266.96z"
              />
              <path
                d="M403.61,265.08c0.58,1.01,1.16,1.58,1.22,2.21c0.09,0.9-0.63,1.44-1.52,1.35c-0.86-0.09-1.56-0.7-1.32-1.58
	C402.15,266.45,402.85,265.98,403.61,265.08z"
              />
              <path
                d="M593.51,627.78c-0.86,0.03-1.55-0.61-1.56-1.45c0-0.82,0.7-1.51,1.54-1.48c0.85,0.03,1.31,0.52,1.4,1.33
	C595,627.07,594.37,627.75,593.51,627.78z"
              />
              <path
                d="M318.37,536.13c-0.88,0.06-1.56-0.54-1.55-1.42c0.01-0.82,0.41-1.36,1.25-1.49c0.83-0.12,1.61,0.49,1.7,1.3
	C319.85,535.34,319.22,536.07,318.37,536.13z"
              />
              <path
                d="M441.78,603.54c-1,0.69-1.54,1.28-2.18,1.44c-0.75,0.19-1.44-0.73-1.37-1.58c0.07-0.85,0.65-1.44,1.39-1.31
	C440.25,602.22,440.79,602.86,441.78,603.54z"
              />
              <path
                d="M440.39,343.55c-0.05,0.83-0.88,1.65-1.58,1.35c-0.56-0.24-1.13-0.85-1.32-1.42c-0.24-0.72,0.66-1.51,1.47-1.5
	C439.78,341.98,440.44,342.71,440.39,343.55z"
              />
              <path
                d="M302.68,214.56c0.04,0.87-0.59,1.53-1.45,1.53c-0.85,0-1.55-0.69-1.48-1.51c0.07-0.81,0.52-1.31,1.35-1.41
	C301.95,213.07,302.64,213.69,302.68,214.56z"
              />
              <path
                d="M271.3,267.89c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C270.6,266.39,271.3,267.06,271.3,267.89z"
              />
              <path
                d="M433.13,170.61c-0.95,0.74-1.46,1.4-2.09,1.55c-0.74,0.17-1.33-0.39-1.45-1.23c-0.12-0.85,0.51-1.8,1.28-1.65
	C431.54,169.4,432.1,169.97,433.13,170.61z"
              />
              <path
                d="M397.66,267.93c-0.02,0.87-0.68,1.47-1.54,1.39c-0.82-0.08-1.28-0.57-1.36-1.37c-0.08-0.79,0.63-1.51,1.45-1.52
	C397.06,266.42,397.69,267.06,397.66,267.93z"
              />
              <path
                d="M379.87,625.32c1.38-0.03,1.95,0.46,2,1.38c0.06,0.91-0.42,1.53-1.38,1.57c-0.93,0.04-1.65-0.46-1.55-1.37
	C379.01,626.29,379.62,625.72,379.87,625.32z"
              />
              <path
                d="M347.73,212.66c1.38-0.02,1.94,0.48,1.99,1.4c0.05,0.91-0.43,1.52-1.39,1.56c-0.93,0.04-1.64-0.47-1.54-1.38
	C346.86,213.62,347.48,213.05,347.73,212.66z"
              />
              <path
                d="M372.76,439.68c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C372.26,438.21,372.68,438.8,372.76,439.68z"
              />
              <path
                d="M415.07,341.97c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C414.21,340.98,414.69,341.66,415.07,341.97z"
              />
              <path
                d="M358.33,580.23c0.86,0,1.51,0.67,1.44,1.54c-0.06,0.82-0.49,1.33-1.35,1.39c-0.86,0.06-1.57-0.57-1.59-1.41
	C356.81,580.93,357.49,580.23,358.33,580.23z"
              />
              <path
                d="M284.9,93.7c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C284.45,92.07,285.03,92.85,284.9,93.7z"
              />
              <path
                d="M331.62,477.17c0.59,1,1.17,1.57,1.24,2.2c0.1,0.91-0.62,1.44-1.51,1.36c-0.87-0.08-1.56-0.69-1.33-1.57
	C330.17,478.55,330.86,478.07,331.62,477.17z"
              />
              <path
                d="M373.62,344.11c-0.66-0.98-1.3-1.53-1.38-2.15c-0.12-0.89,0.62-1.44,1.5-1.42c0.89,0.02,1.56,0.64,1.36,1.53
	C374.96,342.68,374.32,343.19,373.62,344.11z"
              />
              <path
                d="M376.98,439.29c-0.06-0.82,0.6-1.54,1.44-1.57c0.85-0.02,1.34,0.43,1.49,1.24c0.15,0.84-0.45,1.61-1.28,1.68
	C377.8,440.73,377.04,440.11,376.98,439.29z"
              />
              <path
                d="M447.13,422.4c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C446.64,420.94,447.06,421.53,447.13,422.4z"
              />
              <path
                d="M323.67,537.62c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C322.72,536.72,323.25,537.34,323.67,537.62z"
              />
              <path
                d="M438.41,148.67c-1,0.67-1.54,1.26-2.18,1.41c-0.76,0.18-1.42-0.73-1.34-1.58c0.08-0.85,0.65-1.42,1.39-1.28
	C436.9,147.35,437.43,147.98,438.41,148.67z"
              />
              <path
                d="M298.41,389.84c0.77,0.9,1.46,1.37,1.62,1.98c0.24,0.88-0.46,1.49-1.32,1.58c-0.9,0.09-1.62-0.45-1.53-1.35
	C297.26,391.43,297.83,390.85,298.41,389.84z"
              />
              <path
                d="M504.82,390.42c0.28,0.37,0.89,0.89,1.08,1.52c0.21,0.72-0.73,1.55-1.51,1.47c-0.79-0.08-1.32-0.52-1.39-1.37
	C502.93,391.19,503.58,390.46,504.82,390.42z"
              />
              <path
                d="M387.25,265.29c-0.95,0.67-1.48,1.33-2.09,1.41c-0.84,0.11-1.47-0.55-1.45-1.47c0.02-0.92,0.66-1.56,1.5-1.42
	C385.82,263.91,386.32,264.58,387.25,265.29z"
              />
              <path
                d="M434.83,338.87c0.84-0.02,1.54,0.66,1.54,1.48c0,0.83-0.7,1.49-1.56,1.45c-0.86-0.04-1.3-0.53-1.38-1.35
	C433.34,339.58,433.97,338.89,434.83,338.87z"
              />
              <path
                d="M376.52,170.45c1.35-0.02,1.91,0.46,1.97,1.37c0.05,0.9-0.41,1.51-1.36,1.56c-0.92,0.04-1.62-0.47-1.53-1.36
	C375.66,171.4,376.27,170.84,376.52,170.45z"
              />
              <path
                d="M310.99,616.26c-1.29-0.03-1.91-0.7-1.82-1.56c0.08-0.81,0.53-1.29,1.36-1.34c0.81-0.05,1.69,0.69,1.49,1.43
	C311.85,615.41,311.25,615.91,310.99,616.26z"
              />
              <path
                d="M501.29,394.54c0.86-0.02,1.52,0.62,1.48,1.5c-0.04,0.82-0.47,1.34-1.31,1.43c-0.84,0.09-1.58-0.56-1.63-1.37
	C499.78,395.26,500.44,394.56,501.29,394.54z"
              />
              <path
                d="M452.85,589.43c-0.92-0.05-1.49-0.54-1.5-1.47c-0.01-0.93,0.53-1.47,1.48-1.5c0.96-0.02,1.49,0.54,1.5,1.46
	C454.34,588.84,453.83,589.38,452.85,589.43z"
              />
              <path
                d="M238.81,386.41c-1.02,0.67-1.55,1.26-2.18,1.39c-0.8,0.16-1.28-0.47-1.36-1.28c-0.09-0.85,0.57-1.7,1.36-1.52
	C237.27,385.15,237.8,385.73,238.81,386.41z"
              />
              <path
                d="M327.77,481.83c1.27,0.08,1.9,0.83,1.77,1.67c-0.12,0.81-0.59,1.28-1.45,1.28c-0.85,0-1.68-0.74-1.44-1.5
	C326.85,482.67,327.49,482.18,327.77,481.83z"
              />
              <path
                d="M307.48,624.41c1.29-0.04,1.93,0.6,1.89,1.47c-0.04,0.81-0.46,1.32-1.29,1.41c-0.82,0.09-1.73-0.6-1.56-1.35
	C306.65,625.31,307.23,624.77,307.48,624.41z"
              />
              <path
                d="M413.91,264.09c-1.05-0.01-1.6-0.51-1.63-1.44c-0.03-0.91,0.47-1.51,1.43-1.53c0.94-0.02,1.51,0.5,1.55,1.43
	C415.3,263.49,414.75,264,413.91,264.09z"
              />
              <path
                d="M376.07,262.58c-0.98,0.7-1.5,1.32-2.11,1.45c-0.92,0.19-1.47-0.48-1.48-1.4c-0.01-0.91,0.52-1.61,1.44-1.45
	C374.53,261.29,375.07,261.9,376.07,262.58z"
              />
              <path
                d="M255.15,334.58c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C254.7,332.95,255.28,333.73,255.15,334.58z"
              />
              <path
                d="M320.38,482.39c1.28-0.08,1.96,0.54,1.94,1.4c-0.01,0.81-0.4,1.37-1.24,1.45c-0.93,0.09-1.66-0.41-1.6-1.29
	C319.52,483.34,320.15,482.77,320.38,482.39z"
              />
              <path
                d="M305.8,226.63c-0.86,0.01-1.5-0.64-1.44-1.51c0.05-0.82,0.49-1.32,1.33-1.4c0.82-0.07,1.56,0.59,1.59,1.4
	C307.31,225.93,306.64,226.62,305.8,226.63z"
              />
              <path
                d="M427.42,611.97c-0.02,0.87-0.69,1.48-1.55,1.4c-0.83-0.08-1.29-0.57-1.37-1.37c-0.08-0.8,0.63-1.51,1.46-1.53
	C426.82,610.45,427.45,611.1,427.42,611.97z"
              />
              <path
                d="M497.87,398.38c0.87-0.06,1.55,0.56,1.54,1.44c-0.01,0.82-0.41,1.36-1.26,1.47c-0.84,0.11-1.61-0.5-1.68-1.31
	C496.39,399.17,497.04,398.43,497.87,398.38z"
              />
              <path
                d="M367.38,438.64c-0.66-0.98-1.3-1.53-1.38-2.15c-0.12-0.89,0.62-1.44,1.5-1.42c0.89,0.02,1.56,0.64,1.36,1.53
	C368.72,437.21,368.08,437.72,367.38,438.64z"
              />
              <path
                d="M501.86,594.66c-0.11,0.93-0.57,1.5-1.53,1.47c-0.94-0.03-1.49-0.57-1.47-1.5c0.02-0.93,0.58-1.46,1.52-1.46
	C501.35,593.18,501.77,593.78,501.86,594.66z"
              />
              <path
                d="M341.47,612.06c-0.95,0.73-1.45,1.39-2.08,1.54c-0.73,0.17-1.32-0.38-1.44-1.22c-0.12-0.85,0.5-1.78,1.27-1.64
	C339.88,610.86,340.45,611.43,341.47,612.06z"
              />
              <path
                d="M267.07,383.66c-0.18,1.32-0.69,1.84-1.63,1.82c-0.96-0.03-1.46-0.62-1.42-1.53c0.04-0.92,0.61-1.59,1.53-1.37
	C266.16,382.72,266.66,383.36,267.07,383.66z"
              />
              <path
                d="M305.12,615.17c-0.07,0.96-0.48,1.56-1.44,1.59c-0.93,0.02-1.51-0.49-1.54-1.41c-0.03-0.92,0.49-1.47,1.43-1.53
	C304.53,613.76,304.96,614.35,305.12,615.17z"
              />
              <path
                d="M541.55,627.51c-0.93,0.69-1.45,1.36-2.07,1.46c-0.87,0.15-1.45-0.52-1.46-1.44c-0.01-0.92,0.58-1.59,1.44-1.45
	C540.08,626.18,540.61,626.84,541.55,627.51z"
              />
              <path
                d="M434.04,145.12c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C433.08,144.21,433.62,144.83,434.04,145.12z"
              />
              <path
                d="M365.45,473.03c-0.94,0.68-1.47,1.34-2.09,1.44c-0.87,0.14-1.45-0.54-1.44-1.46c0-0.92,0.6-1.58,1.46-1.44
	C363.99,471.68,364.52,472.35,365.45,473.03z"
              />
              <path
                d="M388.28,96.6c-0.86-0.02-1.5-0.7-1.4-1.57c0.09-0.81,0.53-1.31,1.39-1.35c0.84-0.04,1.55,0.63,1.56,1.46
	C389.83,95.97,389.14,96.62,388.28,96.6z"
              />
              <path
                d="M371.07,473.77c-1.37,0-1.91-0.49-1.94-1.41c-0.03-0.91,0.45-1.5,1.39-1.53c0.93-0.03,1.6,0.52,1.5,1.4
	C371.94,472.84,371.32,473.38,371.07,473.77z"
              />
              <path
                d="M396.23,625.72c-0.06,0.82-0.89,1.62-1.59,1.31c-0.55-0.25-1.11-0.86-1.29-1.43c-0.23-0.71,0.7-1.49,1.49-1.47
	C395.66,624.15,396.29,624.87,396.23,625.72z"
              />
              <path
                d="M335.42,547.33c-0.22,1.32-0.72,1.84-1.67,1.77c-0.95-0.07-1.45-0.65-1.39-1.57c0.06-0.93,0.7-1.55,1.58-1.33
	C334.55,546.35,335.04,547.02,335.42,547.33z"
              />
              <path
                d="M506.42,605.21c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C505.72,603.72,506.42,604.39,506.42,605.21z"
              />
              <path
                d="M302.63,207.48c-1.03,0.65-1.58,1.21-2.22,1.34c-0.79,0.16-1.44-0.71-1.32-1.56c0.11-0.8,0.61-1.43,1.4-1.24
	C301.12,206.17,301.63,206.78,302.63,207.48z"
              />
              <path
                d="M475.43,226.84c1.24-0.04,1.92,0.66,1.9,1.5c-0.03,0.85-0.54,1.31-1.31,1.44c-0.75,0.13-1.74-0.67-1.57-1.38
	C474.6,227.76,475.18,227.22,475.43,226.84z"
              />
              <path
                d="M274.49,383.38c-0.97,0.65-1.52,1.29-2.12,1.35c-0.84,0.08-1.45-0.58-1.41-1.51c0.04-0.92,0.7-1.54,1.54-1.38
	C273.08,381.96,273.58,382.65,274.49,383.38z"
              />
              <path
                d="M475.44,206.89c-0.05,0.88-0.45,1.49-1.41,1.52c-0.93,0.03-1.51-0.48-1.55-1.39c-0.04-0.91,0.48-1.47,1.41-1.53
	C474.84,205.43,475.31,205.97,475.44,206.89z"
              />
              <path
                d="M257.09,328.42c0.67,0.95,1.3,1.48,1.41,2.09c0.15,0.87-0.51,1.49-1.42,1.48c-0.88-0.01-1.59-0.6-1.45-1.47
	C255.73,329.9,256.39,329.38,257.09,328.42z"
              />
              <path
                d="M405.41,120.94c0.31,0.36,1.01,0.84,1.17,1.44c0.22,0.87-0.38,1.5-1.33,1.58c-0.95,0.08-1.53-0.43-1.6-1.35
	C403.58,121.7,404.06,121.13,405.41,120.94z"
              />
              <path
                d="M381.82,437.2c-0.68-0.96-1.34-1.5-1.43-2.12c-0.14-0.88,0.59-1.45,1.47-1.45c0.9,0,1.57,0.61,1.4,1.49
	C383.13,435.74,382.5,436.26,381.82,437.2z"
              />
              <path
                d="M423.41,617.18c-1.3,0.09-1.96-0.51-1.96-1.38c0-0.81,0.4-1.34,1.23-1.47c0.8-0.13,1.76,0.53,1.63,1.28
	C424.19,616.25,423.64,616.8,423.41,617.18z"
              />
              <path
                d="M494.58,405.13c-0.84-0.01-1.51-0.71-1.47-1.52c0.04-0.8,0.79-1.47,1.61-1.39c0.83,0.09,1.27,0.59,1.31,1.41
	C496.09,404.51,495.45,405.15,494.58,405.13z"
              />
              <path
                d="M337.68,552.44c-0.84-0.02-1.51-0.71-1.47-1.52c0.04-0.8,0.79-1.47,1.61-1.39c0.83,0.09,1.27,0.59,1.31,1.41
	C339.18,551.82,338.54,552.46,337.68,552.44z"
              />
              <path
                d="M360.23,328.93c-1.02,0.64-1.58,1.23-2.2,1.32c-0.93,0.13-1.43-0.58-1.39-1.49c0.04-0.91,0.62-1.57,1.53-1.35
	C358.78,327.55,359.27,328.19,360.23,328.93z"
              />
              <path
                d="M543.76,632.08c-1.29,0.01-1.93-0.63-1.87-1.5c0.05-0.81,0.49-1.31,1.32-1.39c0.81-0.08,1.71,0.63,1.54,1.38
	C544.6,631.2,544.01,631.72,543.76,632.08z"
              />
              <path
                d="M379.74,352.41c-0.04,0.82-0.78,1.45-1.62,1.39c-0.83-0.06-1.46-0.81-1.32-1.65c0.13-0.8,0.61-1.28,1.47-1.27
	C379.11,350.88,379.78,351.59,379.74,352.41z"
              />
              <path
                d="M313,468.69c0.24,0.38,0.81,0.93,0.94,1.58c0.15,0.73-0.85,1.49-1.6,1.34c-0.77-0.15-1.26-0.63-1.28-1.47
	C311.05,469.32,311.77,468.63,313,468.69z"
              />
              <path
                d="M280.12,328.48c0.06,0.83-0.6,1.59-1.4,1.61c-0.79,0.02-1.72-0.78-1.5-1.49c0.18-0.57,0.75-1.2,1.3-1.44
	C279.24,326.85,280.06,327.65,280.12,328.48z"
              />
              <path
                d="M377.03,632.1c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C378.54,631.47,377.9,632.11,377.03,632.1z"
              />
              <path
                d="M299.4,257.99c-0.2,1.31-0.7,1.83-1.64,1.76c-0.93-0.07-1.44-0.65-1.37-1.56c0.06-0.9,0.69-1.53,1.57-1.32
	C298.55,257.02,299.03,257.68,299.4,257.99z"
              />
              <path
                d="M409.65,134.51c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C411.17,133.87,410.52,134.52,409.65,134.51z"
              />
              <path
                d="M308.22,610.92c0.09,1.26-0.56,1.97-1.41,1.99c-0.85,0.02-1.35-0.42-1.49-1.24c-0.15-0.84,0.48-1.77,1.27-1.64
	C307.24,610.14,307.82,610.68,308.22,610.92z"
              />
              <path
                d="M262.96,380.36c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C262.1,379.37,262.58,380.04,262.96,380.36z"
              />
              <path
                d="M415.64,614.4c0.71,0.94,1.38,1.46,1.49,2.08c0.16,0.87-0.55,1.47-1.43,1.49c-0.91,0.03-1.58-0.57-1.44-1.45
	C414.36,615.9,414.99,615.36,415.64,614.4z"
              />
              <path
                d="M247.91,407.16c-0.95,0.74-1.45,1.41-2.08,1.56c-0.74,0.18-1.33-0.39-1.45-1.23c-0.12-0.85,0.5-1.8,1.28-1.65
	C246.31,405.96,246.88,406.53,247.91,407.16z"
              />
              <path
                d="M294.04,446.73c-0.05,0.83-0.89,1.65-1.59,1.34c-0.55-0.24-1.12-0.86-1.31-1.43c-0.23-0.72,0.68-1.51,1.48-1.5
	C293.44,445.15,294.09,445.88,294.04,446.73z"
              />
              <path
                d="M368.85,468.81c-0.97,0.73-1.46,1.35-2.08,1.52c-0.79,0.22-1.31-0.38-1.44-1.18c-0.14-0.83,0.49-1.75,1.26-1.62
	C367.22,467.64,367.79,468.19,368.85,468.81z"
              />
              <path
                d="M375.4,467.98c0.05,1.24-0.63,1.93-1.47,1.93c-0.85,0-1.32-0.48-1.43-1.28c-0.12-0.84,0.53-1.73,1.32-1.58
	C374.46,467.17,375.01,467.74,375.4,467.98z"
              />
              <path
                d="M342.96,554.28c0.04,0.87-0.58,1.52-1.43,1.51c-0.83-0.01-1.67-0.75-1.43-1.48c0.19-0.55,0.77-1.16,1.31-1.36
	C342.19,552.67,342.93,553.43,342.96,554.28z"
              />
              <path
                d="M444.7,354.13c-0.06,0.83-0.9,1.64-1.6,1.33c-0.55-0.25-1.12-0.87-1.3-1.45c-0.23-0.72,0.7-1.51,1.5-1.48
	C444.13,352.55,444.77,353.28,444.7,354.13z"
              />
              <path
                d="M491.5,408.97c-0.86,0.01-1.5-0.64-1.44-1.51c0.05-0.82,0.49-1.32,1.33-1.4c0.82-0.07,1.56,0.59,1.59,1.4
	C493.01,408.27,492.34,408.96,491.5,408.97z"
              />
              <path
                d="M237.47,379.2c-0.1,0.92-0.55,1.5-1.52,1.48c-0.94-0.02-1.49-0.55-1.48-1.48c0.01-0.93,0.56-1.46,1.5-1.47
	C236.94,377.72,237.37,378.31,237.47,379.2z"
              />
              <path
                d="M357.07,355.75c-1.37-0.06-1.87-0.59-1.86-1.51c0.01-0.91,0.52-1.47,1.47-1.45c0.93,0.02,1.57,0.6,1.42,1.47
	C358,354.87,357.34,355.38,357.07,355.75z"
              />
              <path
                d="M259.49,258.49c-0.37,0.25-0.89,0.85-1.52,0.99c-0.75,0.17-1.63-0.83-1.36-1.53c0.22-0.55,0.82-1.13,1.38-1.32
	C258.73,256.38,259.49,257.23,259.49,258.49z"
              />
              <path
                d="M345.36,555.39c0.68,0.94,1.32,1.46,1.43,2.08c0.16,0.87-0.5,1.49-1.4,1.49c-0.88,0-1.6-0.58-1.46-1.46
	C344.02,556.88,344.68,556.34,345.36,555.39z"
              />
              <path
                d="M552.05,629.66c-0.05,1.32-0.47,1.91-1.43,1.98c-0.96,0.07-1.53-0.45-1.59-1.36c-0.06-0.91,0.43-1.65,1.36-1.54
	C551.04,628.8,551.61,629.39,552.05,629.66z"
              />
              <path
                d="M435.1,199.62c-0.06,0.82-0.89,1.62-1.59,1.31c-0.55-0.25-1.11-0.86-1.29-1.43c-0.23-0.71,0.7-1.49,1.49-1.47
	C434.53,198.06,435.16,198.77,435.1,199.62z"
              />
              <path
                d="M447.59,157.04c1.29-0.04,1.93,0.6,1.89,1.47c-0.04,0.81-0.46,1.32-1.29,1.41c-0.82,0.09-1.73-0.6-1.56-1.35
	C446.77,157.95,447.35,157.41,447.59,157.04z"
              />
              <path
                d="M460.88,579.75c-0.05,1.32-0.47,1.91-1.43,1.98c-0.96,0.07-1.53-0.44-1.59-1.36c-0.06-0.91,0.43-1.65,1.36-1.54
	C459.87,578.9,460.44,579.49,460.88,579.75z"
              />
              <path
                d="M385.67,630.21c-0.02,0.87-0.68,1.47-1.54,1.39c-0.82-0.08-1.28-0.57-1.36-1.37c-0.08-0.79,0.63-1.51,1.45-1.52
	C385.07,628.69,385.69,629.34,385.67,630.21z"
              />
              <path
                d="M534.88,625.63c-0.24-0.39-0.81-0.96-0.93-1.61c-0.14-0.75,0.85-1.47,1.62-1.32c0.79,0.15,1.26,0.65,1.26,1.49
	C536.85,625.01,536.09,625.72,534.88,625.63z"
              />
              <path
                d="M280.3,95.22c0.58,1.01,1.15,1.59,1.21,2.22c0.09,0.9-0.64,1.44-1.53,1.34c-0.86-0.09-1.55-0.71-1.31-1.59
	C278.84,96.58,279.53,96.11,280.3,95.22z"
              />
              <path
                d="M274.9,197.24c0.31,0.36,1.01,0.84,1.17,1.44c0.22,0.87-0.38,1.5-1.33,1.58c-0.95,0.08-1.53-0.43-1.6-1.35
	C273.06,198,273.54,197.42,274.9,197.24z"
              />
              <path
                d="M470.25,574.19c0.29,0.37,0.91,0.88,1.1,1.51c0.22,0.72-0.71,1.55-1.49,1.48c-0.8-0.07-1.32-0.51-1.4-1.35
	C468.38,574.99,469.01,574.25,470.25,574.19z"
              />
              <path
                d="M363.59,431.11c0.68,0.94,1.32,1.46,1.43,2.08c0.16,0.87-0.5,1.49-1.4,1.49c-0.88,0-1.6-0.58-1.46-1.46
	C362.25,432.6,362.91,432.07,363.59,431.11z"
              />
              <path
                d="M308.61,468.89c-0.64-1.02-1.21-1.58-1.32-2.21c-0.15-0.81,0.53-1.28,1.31-1.37c0.78-0.09,1.73,0.71,1.52,1.43
	C309.94,467.38,309.33,467.9,308.61,468.89z"
              />
              <path
                d="M306.92,589.98c-0.94,0.74-1.43,1.39-2.05,1.54c-0.73,0.18-1.31-0.38-1.43-1.21c-0.12-0.84,0.5-1.77,1.25-1.63
	C305.33,588.81,305.9,589.36,306.92,589.98z"
              />
              <path
                d="M408.9,615.68c0.29,0.41,0.93,0.94,1.03,1.55c0.14,0.88-0.52,1.44-1.46,1.44c-0.96,0-1.46-0.57-1.45-1.48
	C407.02,616.27,407.57,615.77,408.9,615.68z"
              />
              <path
                d="M478.42,235.51c-0.94,0.68-1.47,1.35-2.07,1.44c-0.85,0.12-1.47-0.54-1.47-1.46c0-0.92,0.64-1.56,1.49-1.43
	C476.97,234.15,477.49,234.82,478.42,235.51z"
              />
              <path
                d="M351.53,560.97c-0.99,0.63-1.56,1.27-2.18,1.32c-0.85,0.07-1.41-0.62-1.34-1.56c0.07-0.93,0.7-1.53,1.55-1.33
	C350.16,559.54,350.64,560.23,351.53,560.97z"
              />
              <path
                d="M299.63,510.94c0.04,0.98-0.57,1.59-1.46,1.55c-0.97-0.04-1.42-0.62-1.43-1.51c0-0.83,0.52-1.29,1.34-1.32
	C299.02,509.63,299.54,510.13,299.63,510.94z"
              />
              <path
                d="M431.68,196.47c-1.1,0.53-1.72,1.04-2.37,1.1c-0.79,0.06-1.33-0.93-1.13-1.75c0.2-0.82,0.84-1.32,1.57-1.08
	C430.36,194.94,430.8,195.64,431.68,196.47z"
              />
              <path
                d="M351.61,195.99c-0.04,0.87-0.72,1.47-1.59,1.37c-0.83-0.1-1.27-0.6-1.33-1.41c-0.06-0.8,0.67-1.5,1.49-1.5
	C351.04,194.45,351.65,195.12,351.61,195.99z"
              />
              <path
                d="M444.8,377.12c-0.28-0.38-0.89-0.9-1.07-1.54c-0.21-0.72,0.74-1.54,1.52-1.45c0.79,0.09,1.32,0.53,1.38,1.38
	C446.68,376.34,446.02,377.1,444.8,377.12z"
              />
              <path
                d="M367.87,375.45c-1.1,0.53-1.72,1.04-2.37,1.1c-0.79,0.06-1.33-0.93-1.13-1.75c0.2-0.82,0.84-1.32,1.57-1.08
	C366.54,373.92,366.98,374.62,367.87,375.45z"
              />
              <path
                d="M638.57,629.73c-0.02,0.87-0.7,1.48-1.57,1.41c-0.84-0.07-1.3-0.57-1.37-1.38c-0.08-0.82,0.62-1.52,1.47-1.54
	C637.96,628.2,638.59,628.85,638.57,629.73z"
              />
              <path
                d="M319.03,68.75c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C318.52,67.28,318.94,67.88,319.03,68.75z"
              />
              <path
                d="M380.65,372.49c0.24,0.37,0.82,0.91,0.96,1.55c0.16,0.76-0.76,1.44-1.58,1.35c-0.84-0.09-1.26-0.61-1.29-1.43
	C378.7,373.08,379.35,372.44,380.65,372.49z"
              />
              <path
                d="M293.8,412.55c0.24,0.38,0.81,0.93,0.94,1.58c0.15,0.73-0.85,1.49-1.6,1.34c-0.77-0.15-1.26-0.63-1.28-1.47
	C291.86,413.17,292.57,412.49,293.8,412.55z"
              />
              <path
                d="M401.1,117.06c0.78,0.9,1.47,1.37,1.62,1.97c0.21,0.85-0.38,1.48-1.32,1.56c-0.94,0.08-1.62-0.44-1.54-1.33
	C399.93,118.64,400.51,118.07,401.1,117.06z"
              />
              <path
                d="M529.18,617.68c-0.09,0.91-0.54,1.48-1.49,1.46c-0.93-0.02-1.47-0.56-1.47-1.47c0.01-0.91,0.56-1.45,1.49-1.45
	C528.67,616.22,529.1,616.8,529.18,617.68z"
              />
              <path
                d="M427.24,192.59c0.01,1.24-0.68,1.92-1.52,1.89c-0.85-0.03-1.3-0.51-1.4-1.32c-0.1-0.86,0.56-1.72,1.36-1.54
	C426.31,191.76,426.85,192.34,427.24,192.59z"
              />
              <path
                d="M406.78,115.36c-1.01,0.66-1.55,1.26-2.18,1.36c-0.92,0.15-1.44-0.55-1.42-1.46c0.02-0.91,0.59-1.58,1.5-1.38
	C405.3,114.01,405.81,114.64,406.78,115.36z"
              />
              <path
                d="M307,431c0.02,0.88-0.61,1.52-1.49,1.49c-0.85-0.03-1.33-0.51-1.45-1.3c-0.13-0.8,0.55-1.55,1.38-1.62
	C306.31,429.5,306.98,430.12,307,431z"
              />
              <path
                d="M238.5,359.46c0.29,0.38,0.96,0.87,1.08,1.48c0.17,0.87-0.45,1.46-1.39,1.49c-0.95,0.04-1.48-0.49-1.51-1.41
	C236.66,360.11,237.15,359.58,238.5,359.46z"
              />
              <path
                d="M376.18,451.76c-1.37-0.15-1.86-0.72-1.8-1.64c0.06-0.92,0.62-1.45,1.58-1.37c0.94,0.07,1.56,0.69,1.35,1.56
	C377.16,450.92,376.48,451.4,376.18,451.76z"
              />
              <path
                d="M295.75,508.2c-0.31,0.22-0.78,0.75-1.34,0.88c-0.85,0.2-1.5-0.51-1.47-1.39c0.03-0.83,0.47-1.31,1.31-1.34
	C295.11,506.31,295.77,506.94,295.75,508.2z"
              />
              <path
                d="M348.28,239.53c-0.06,0.88-0.46,1.48-1.42,1.5c-0.93,0.02-1.5-0.49-1.53-1.4c-0.03-0.91,0.49-1.47,1.42-1.52
	C347.69,238.06,348.16,238.61,348.28,239.53z"
              />
              <path
                d="M307.93,192.63c-0.04,0.86-0.78,1.6-1.56,1.31c-0.54-0.2-1.12-0.81-1.3-1.37c-0.24-0.72,0.63-1.48,1.44-1.47
	C307.36,191.1,307.98,191.76,307.93,192.63z"
              />
              <path
                d="M521.94,618.15c0.01,0.88-0.61,1.49-1.48,1.46c-0.83-0.03-1.65-0.8-1.38-1.52c0.21-0.55,0.81-1.13,1.36-1.32
	C521.23,616.51,521.93,617.29,521.94,618.15z"
              />
              <path
                d="M303.62,601.37c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C303.17,599.74,303.74,600.52,303.62,601.37z"
              />
              <path
                d="M305.16,465.19c-1.36-0.15-1.84-0.72-1.78-1.63c0.06-0.91,0.62-1.44,1.57-1.37c0.93,0.07,1.55,0.69,1.34,1.55
	C306.14,464.35,305.46,464.83,305.16,465.19z"
              />
              <path
                d="M456.21,585.59c-0.92-0.05-1.49-0.54-1.5-1.47c-0.01-0.93,0.53-1.47,1.48-1.5c0.96-0.02,1.49,0.54,1.5,1.46
	C457.7,585,457.18,585.54,456.21,585.59z"
              />
              <path
                d="M316.45,371.99c-0.06,1.31-0.48,1.91-1.43,1.97c-0.95,0.07-1.53-0.45-1.58-1.37c-0.06-0.91,0.43-1.65,1.37-1.54
	C315.44,371.13,316.01,371.72,316.45,371.99z"
              />
              <path
                d="M359.81,415.56c-0.07,0.96-0.47,1.55-1.43,1.57c-0.93,0.02-1.5-0.49-1.53-1.4c-0.03-0.91,0.49-1.47,1.42-1.52
	C359.23,414.16,359.66,414.74,359.81,415.56z"
              />
              <path
                d="M308.92,618.73c-0.04,0.87-0.73,1.47-1.6,1.37c-0.84-0.09-1.28-0.6-1.34-1.41c-0.06-0.82,0.66-1.51,1.5-1.5
	C308.33,617.18,308.96,617.86,308.92,618.73z"
              />
              <path
                d="M447.95,429.18c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C447,428.27,447.53,428.89,447.95,429.18z"
              />
              <path
                d="M478,417.65c-1.38-0.06-1.89-0.59-1.88-1.51c0.01-0.91,0.53-1.48,1.48-1.46c0.93,0.02,1.58,0.59,1.43,1.47
	C478.92,416.77,478.27,417.28,478,417.65z"
              />
              <path
                d="M306.49,416.72c-1.01,0.65-1.56,1.24-2.19,1.34c-0.92,0.14-1.44-0.57-1.4-1.48c0.03-0.91,0.61-1.58,1.51-1.37
	C305.02,415.35,305.53,415.99,306.49,416.72z"
              />
              <path
                d="M316.06,318.4c-1.05,0.62-1.62,1.16-2.26,1.27c-0.78,0.13-1.41-0.77-1.27-1.61c0.14-0.8,0.66-1.4,1.44-1.19
	C314.6,317.04,315.09,317.66,316.06,318.4z"
              />
              <path
                d="M237.05,371.71c-0.22,1.32-0.72,1.84-1.67,1.77c-0.95-0.07-1.45-0.65-1.39-1.57c0.06-0.93,0.7-1.55,1.58-1.33
	C236.18,370.73,236.67,371.4,237.05,371.71z"
              />
              <path
                d="M399.52,116.03c-0.94,0.68-1.47,1.34-2.09,1.44c-0.87,0.14-1.45-0.54-1.44-1.46c0-0.92,0.6-1.58,1.46-1.44
	C398.06,114.68,398.59,115.35,399.52,116.03z"
              />
              <path
                d="M472.04,416.61c0.01,0.88-0.61,1.5-1.48,1.46c-0.83-0.03-1.65-0.8-1.38-1.52c0.2-0.55,0.8-1.14,1.36-1.32
	C471.32,414.98,472.03,415.75,472.04,416.61z"
              />
              <path
                d="M479.21,574.87c-0.97,0.73-1.47,1.34-2.09,1.51c-0.79,0.21-1.31-0.39-1.44-1.19c-0.14-0.83,0.5-1.75,1.27-1.61
	C477.59,573.69,478.16,574.24,479.21,574.87z"
              />
              <path
                d="M258.91,416.76c-0.17,1.32-0.67,1.85-1.61,1.83c-0.96-0.02-1.47-0.6-1.44-1.52c0.03-0.92,0.59-1.59,1.51-1.39
	C257.99,415.82,258.5,416.46,258.91,416.76z"
              />
              <path
                d="M390.64,118c-1.22-0.01-1.9-0.76-1.84-1.59c0.05-0.85,0.58-1.3,1.36-1.4c0.79-0.1,1.74,0.71,1.53,1.44
	C391.52,117.1,390.91,117.62,390.64,118z"
              />
              <path
                d="M372.85,100.03c-0.89,0.76-1.36,1.48-1.95,1.61c-0.83,0.18-1.5-0.43-1.57-1.35c-0.08-0.94,0.53-1.6,1.37-1.54
	C371.3,98.79,371.86,99.42,372.85,100.03z"
              />
              <path d="M554.63,81.59c-1,1.22-1.84,1.13-2.51,0.39c-0.75-0.82-0.72-1.72,0.56-2.63C553.3,80.08,553.93,80.8,554.63,81.59z" />
              <path
                d="M450.13,181.57c-1.05-0.04-1.63-0.51-1.66-1.45c-0.02-0.93,0.51-1.48,1.45-1.52c0.96-0.04,1.49,0.51,1.53,1.43
	C451.49,180.96,450.95,181.48,450.13,181.57z"
              />
              <path
                d="M450.98,591.78c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C450.48,590.3,450.9,590.9,450.98,591.78z"
              />
              <path
                d="M445.67,155.77c-0.36,0.26-0.87,0.87-1.5,1.03c-0.74,0.19-1.65-0.8-1.39-1.5c0.21-0.56,0.8-1.15,1.36-1.35
	C444.88,153.68,445.65,154.51,445.67,155.77z"
              />
              <path
                d="M304.07,621.02c0.86-0.02,1.52,0.62,1.48,1.5c-0.04,0.82-0.47,1.34-1.31,1.43c-0.84,0.09-1.58-0.56-1.63-1.37
	C302.56,621.75,303.23,621.04,304.07,621.02z"
              />
              <path
                d="M296.39,495.28c1.38-0.02,1.94,0.48,1.99,1.4c0.05,0.91-0.43,1.52-1.39,1.56c-0.93,0.04-1.64-0.47-1.54-1.38
	C295.52,496.24,296.13,495.68,296.39,495.28z"
              />
              <path
                d="M279.11,101.01c-0.95,0.75-1.45,1.42-2.07,1.58c-0.74,0.18-1.33-0.38-1.46-1.21c-0.13-0.85,0.49-1.8,1.26-1.66
	C277.49,99.82,278.06,100.38,279.11,101.01z"
              />
              <path
                d="M249.96,419.93c-0.59-1-1.17-1.58-1.24-2.21c-0.1-0.91,0.63-1.43,1.52-1.35c0.87,0.08,1.56,0.68,1.33,1.57
	C251.41,418.55,250.72,419.03,249.96,419.93z"
              />
              <path
                d="M322.35,317.66c-0.06,0.83-0.9,1.64-1.59,1.33c-0.55-0.25-1.12-0.87-1.3-1.44c-0.23-0.72,0.69-1.51,1.49-1.49
	C321.76,316.08,322.4,316.82,322.35,317.66z"
              />
              <path
                d="M453.38,161.74c-0.11,1.01-0.55,1.58-1.51,1.56c-0.93-0.02-1.47-0.56-1.46-1.47c0.01-0.91,0.56-1.44,1.49-1.45
	C452.87,160.36,453.25,160.99,453.38,161.74z"
              />
              <path
                d="M341.09,74.04c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C340.59,72.57,341.01,73.17,341.09,74.04z"
              />
              <path
                d="M300.27,617.28c0.67,0.95,1.3,1.48,1.41,2.09c0.15,0.87-0.51,1.49-1.42,1.48c-0.88-0.01-1.59-0.6-1.45-1.47
	C298.92,618.76,299.58,618.24,300.27,617.28z"
              />
              <path
                d="M383.35,371.54c-0.85,0.03-1.55-0.61-1.56-1.44c-0.01-0.81,0.7-1.51,1.52-1.48c0.84,0.03,1.3,0.51,1.4,1.32
	C384.81,370.82,384.2,371.51,383.35,371.54z"
              />
              <path
                d="M303.13,460.27c-0.98,0.7-1.5,1.32-2.11,1.45c-0.92,0.19-1.47-0.48-1.48-1.4c-0.01-0.91,0.52-1.61,1.44-1.45
	C301.59,458.98,302.13,459.6,303.13,460.27z"
              />
              <path
                d="M332.61,72.67c0.58,1.01,1.15,1.59,1.21,2.22c0.09,0.9-0.64,1.44-1.53,1.34c-0.86-0.09-1.55-0.71-1.31-1.59
	C331.14,74.03,331.84,73.56,332.61,72.67z"
              />
              <path
                d="M449.94,364.46c-1.05,0.62-1.62,1.16-2.26,1.27c-0.78,0.13-1.41-0.77-1.27-1.61c0.14-0.8,0.66-1.4,1.44-1.19
	C448.47,363.1,448.97,363.72,449.94,364.46z"
              />
              <path
                d="M451.89,187.35c-0.04,0.86-0.78,1.6-1.56,1.31c-0.54-0.2-1.12-0.81-1.3-1.37c-0.24-0.72,0.63-1.48,1.44-1.47
	C451.32,185.82,451.93,186.49,451.89,187.35z"
              />
              <path
                d="M518.9,250.13c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C518.2,248.64,518.9,249.3,518.9,250.13z"
              />
              <path
                d="M481.69,571.15c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C481.19,569.67,481.61,570.27,481.69,571.15z"
              />
              <path
                d="M442.56,427.72c-0.97-0.04-1.49-0.58-1.48-1.5c0.01-0.92,0.53-1.48,1.49-1.47c0.94,0.02,1.48,0.56,1.48,1.49
	C444.05,427.18,443.48,427.67,442.56,427.72z"
              />
              <path
                d="M392.98,107.57c-0.74-0.98-1.36-1.48-1.55-2.12c-0.22-0.71,0.71-1.53,1.5-1.46c0.79,0.07,1.47,0.53,1.33,1.34
	C394.15,105.98,393.6,106.54,392.98,107.57z"
              />
              <path
                d="M450.95,425.69c0.04,0.87-0.59,1.53-1.45,1.53c-0.85,0-1.55-0.69-1.48-1.51c0.07-0.81,0.52-1.31,1.35-1.41
	C450.22,424.2,450.91,424.82,450.95,425.69z"
              />
              <path
                d="M292.57,455.28c-0.24-0.39-0.8-0.95-0.93-1.6c-0.14-0.75,0.84-1.46,1.62-1.31c0.79,0.15,1.25,0.64,1.26,1.48
	C294.51,454.67,293.78,455.36,292.57,455.28z"
              />
              <path
                d="M373.96,453.54c0.09,1.26-0.57,1.96-1.42,1.98c-0.85,0.02-1.34-0.43-1.48-1.24c-0.14-0.84,0.49-1.77,1.28-1.63
	C372.99,452.75,373.56,453.3,373.96,453.54z"
              />
              <path
                d="M458.75,425.05c-0.94,0.68-1.47,1.35-2.08,1.43c-0.85,0.12-1.47-0.54-1.47-1.46c0.01-0.92,0.65-1.56,1.49-1.43
	C457.3,423.69,457.82,424.36,458.75,425.05z"
              />
              <path
                d="M566.47,628.29c-0.12,1.32-0.57,1.88-1.52,1.91c-0.96,0.03-1.5-0.52-1.51-1.44c-0.02-0.92,0.51-1.62,1.44-1.47
	C565.5,627.4,566.05,628.01,566.47,628.29z"
              />
              <path
                d="M339.53,424.67c-0.98,0.64-1.54,1.28-2.16,1.34c-0.86,0.08-1.41-0.61-1.36-1.54c0.05-0.93,0.68-1.54,1.53-1.35
	C338.15,423.25,338.64,423.94,339.53,424.67z"
              />
              <path
                d="M515.19,366.03c-1,0.68-1.54,1.28-2.19,1.44c-0.76,0.18-1.43-0.73-1.36-1.58c0.07-0.85,0.65-1.44,1.39-1.3
	C513.67,364.7,514.2,365.34,515.19,366.03z"
              />
              <path
                d="M398.24,623.05c-1.35-0.15-1.83-0.72-1.77-1.62c0.06-0.91,0.61-1.43,1.56-1.36c0.92,0.07,1.54,0.69,1.34,1.55
	C399.23,622.22,398.54,622.69,398.24,623.05z"
              />
              <path
                d="M357.83,77.41c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C359.35,76.77,358.7,77.42,357.83,77.41z"
              />
              <path
                d="M468.82,420.18c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C467.95,419.19,468.44,419.87,468.82,420.18z"
              />
              <path
                d="M255.65,421c-0.02,0.87-0.7,1.48-1.57,1.41c-0.84-0.07-1.3-0.57-1.37-1.38c-0.08-0.82,0.62-1.52,1.47-1.54
	C255.04,419.47,255.68,420.12,255.65,421z"
              />
              <path
                d="M228.14,247.74c-0.88,0.02-1.51-0.6-1.47-1.48c0.03-0.82,0.46-1.33,1.3-1.43c0.83-0.09,1.56,0.54,1.62,1.37
	C229.63,247.05,229.01,247.72,228.14,247.74z"
              />
              <path
                d="M460.68,181.2c1.24,0.07,1.86,0.82,1.77,1.67c-0.09,0.85-0.62,1.28-1.42,1.34c-0.79,0.06-1.7-0.79-1.47-1.5
	C459.77,182.07,460.39,181.57,460.68,181.2z"
              />
              <path
                d="M384.95,422.62c0.25,0.38,0.83,0.92,0.98,1.56c0.17,0.72-0.83,1.51-1.58,1.38c-0.77-0.14-1.28-0.6-1.3-1.45
	C383.02,423.28,383.71,422.58,384.95,422.62z"
              />
              <path
                d="M370.82,457.87c0.02,0.88-0.61,1.52-1.49,1.49c-0.85-0.03-1.33-0.51-1.45-1.3c-0.13-0.8,0.55-1.55,1.38-1.62
	C370.12,456.38,370.8,456.99,370.82,457.87z"
              />
              <path
                d="M304.98,608.25c-0.91,0.72-1.4,1.4-2.02,1.53c-0.86,0.18-1.46-0.46-1.51-1.38c-0.04-0.93,0.53-1.61,1.39-1.51
	C303.47,606.96,304.02,607.61,304.98,608.25z"
              />
              <path
                d="M496.5,592.75c-0.85-0.01-1.52-0.7-1.48-1.53c0.04-0.82,0.79-1.48,1.62-1.4c0.84,0.08,1.28,0.59,1.33,1.42
	C498.02,592.12,497.37,592.77,496.5,592.75z"
              />
              <path
                d="M468.63,579.42c-0.95,0.74-1.45,1.4-2.07,1.55c-0.73,0.17-1.32-0.38-1.44-1.22c-0.12-0.85,0.5-1.78,1.27-1.64
	C467.04,578.23,467.6,578.8,468.63,579.42z"
              />
              <path
                d="M341.07,366.85c-0.06,0.87-0.77,1.45-1.64,1.33c-0.83-0.12-1.27-0.63-1.3-1.45c-0.03-0.82,0.7-1.49,1.54-1.47
	C340.52,365.29,341.13,365.98,341.07,366.85z"
              />
              <path
                d="M405.6,619.6c0.24,0.37,0.82,0.91,0.96,1.55c0.16,0.76-0.76,1.44-1.58,1.35c-0.84-0.09-1.26-0.61-1.29-1.43
	C403.65,620.19,404.3,619.56,405.6,619.6z"
              />
              <path
                d="M486.41,574.61c-1.05,0.63-1.61,1.18-2.25,1.29c-0.78,0.14-1.42-0.75-1.29-1.59c0.13-0.8,0.64-1.41,1.43-1.21
	C484.92,573.26,485.43,573.88,486.41,574.61z"
              />
              <path
                d="M531.04,622.27c-0.24-0.39-0.81-0.96-0.93-1.61c-0.14-0.75,0.85-1.47,1.62-1.32c0.79,0.15,1.26,0.65,1.26,1.49
	C533.01,621.65,532.25,622.36,531.04,622.27z"
              />
              <path
                d="M430.82,608.09c-0.09,0.91-0.54,1.48-1.49,1.46c-0.93-0.02-1.47-0.56-1.47-1.47c0.01-0.91,0.56-1.45,1.49-1.45
	C430.3,606.62,430.73,607.2,430.82,608.09z"
              />
              <path
                d="M298.41,456.84c-0.11,1.02-0.56,1.59-1.52,1.57c-0.94-0.02-1.48-0.56-1.47-1.48c0.01-0.92,0.56-1.44,1.5-1.46
	C297.9,455.45,298.27,456.09,298.41,456.84z"
              />
              <path
                d="M510.06,624.72c-0.7-0.88-1.37-1.39-1.51-2.01c-0.17-0.75,0.47-1.3,1.29-1.39c0.85-0.1,1.67,0.58,1.49,1.4
	C511.2,623.31,510.66,623.81,510.06,624.72z"
              />
              <path
                d="M443.95,409.41c-0.21-0.32-0.73-0.82-0.85-1.4c-0.18-0.84,0.68-1.68,1.45-1.4c0.55,0.2,1.14,0.82,1.33,1.38
	C446.12,408.73,445.21,409.47,443.95,409.41z"
              />
              <path
                d="M355.76,574.66c0.95-0.75,1.43-1.38,2.05-1.55c0.77-0.21,1.31,0.37,1.45,1.17c0.15,0.83-0.46,1.74-1.24,1.61
	C357.37,575.79,356.8,575.26,355.76,574.66z"
              />
              <path
                d="M357.31,585.65c-1.03,0.62-1.59,1.17-2.23,1.28c-0.78,0.14-1.41-0.75-1.28-1.58c0.13-0.79,0.64-1.4,1.42-1.2
	C355.85,584.3,356.34,584.92,357.31,585.65z"
              />
              <path
                d="M417.56,141.55c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.82,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.6,1.34,1.36
	C418.89,140.1,418.23,140.63,417.56,141.55z"
              />
              <path
                d="M366.65,427.36c0.7,0.91,1.42,1.45,1.45,2.03c0.03,0.44-0.73,1.15-1.29,1.36c-0.74,0.28-1.63-0.59-1.45-1.39
	C365.49,428.77,366.03,428.29,366.65,427.36z"
              />
              <path
                d="M376.53,309.26c-1.01,0.75-1.53,1.4-2.15,1.52c-0.74,0.14-1.32-0.43-1.37-1.29c-0.05-0.84,0.4-1.51,1.19-1.45
	C374.84,308.09,375.43,308.66,376.53,309.26z"
              />
              <path
                d="M383.77,102.73c-0.8,0.69-1.22,1.28-1.78,1.48c-0.77,0.26-1.58-0.51-1.62-1.33c-0.04-0.79,0.74-1.66,1.5-1.46
	C382.45,101.57,382.92,102.12,383.77,102.73z"
              />
              <path
                d="M355.8,571.73c-1.07,0.48-1.71,0.99-2.34,0.99c-0.79,0-1.17-0.71-1.07-1.55c0.11-0.86,0.92-1.47,1.71-1.12
	C354.64,570.31,355.01,570.93,355.8,571.73z"
              />
              <path
                d="M361.16,80.72c-0.23-0.37-0.86-0.94-0.89-1.55c-0.04-0.86,0.67-1.39,1.6-1.29c0.82,0.09,1.22,0.64,1.23,1.45
	C363.11,80.2,362.45,80.8,361.16,80.72z"
              />
              <path
                d="M456.57,190.55c-1.01,0.58-1.6,1.16-2.22,1.2c-0.75,0.06-1.24-0.59-1.2-1.43c0.04-0.84,0.8-1.55,1.59-1.27
	C455.29,189.25,455.72,189.83,456.57,190.55z"
              />
              <path
                d="M439.4,249.54c0.06,0.87-0.53,1.53-1.38,1.55c-0.82,0.02-1.68-0.71-1.46-1.43c0.17-0.56,0.73-1.18,1.27-1.4
	C438.57,247.96,439.34,248.7,439.4,249.54z"
              />
              <path
                d="M334.78,77.49c0.39-0.27,0.93-0.89,1.55-1.01c0.8-0.15,1.3,0.51,1.32,1.34c0.03,0.83-0.4,1.36-1.24,1.47
	C335.6,79.41,334.82,78.72,334.78,77.49z"
              />
              <path
                d="M347.75,245.34c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.5c-0.84-0.05-1.31-0.54-1.41-1.35
	C345.75,246.06,346.51,245.32,347.75,245.34z"
              />
              <path
                d="M523.33,316.65c-0.72-0.83-1.32-1.24-1.51-1.79c-0.28-0.79,0.42-1.57,1.26-1.59c0.81-0.01,1.51,0.42,1.45,1.2
	C524.49,315.09,523.9,315.67,523.33,316.65z"
              />
              <path
                d="M461.48,421.3c-0.05,0.82-0.87,1.61-1.57,1.31c-0.55-0.24-1.11-0.85-1.29-1.41c-0.23-0.71,0.67-1.47,1.47-1.46
	C460.92,419.76,461.53,420.46,461.48,421.3z"
              />
              <path
                d="M412.8,618.9c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C410.87,619.5,411.56,618.84,412.8,618.9z"
              />
              <path
                d="M382.12,420.54c-0.01,1.23-0.77,1.93-1.59,1.84c-0.85-0.09-1.28-0.61-1.28-1.44c0-0.84,0.5-1.5,1.29-1.37
	C381.17,419.67,381.72,420.28,382.12,420.54z"
              />
              <path
                d="M303.09,254.57c-1.04,0.62-1.6,1.16-2.24,1.27c-0.78,0.14-1.41-0.76-1.27-1.59c0.13-0.79,0.65-1.4,1.43-1.2
	C301.63,253.22,302.13,253.84,303.09,254.57z"
              />
              <path
                d="M348.07,362.19c1.03-0.67,1.58-1.26,2.2-1.36c0.77-0.12,1.28,0.49,1.3,1.33c0.02,0.85-0.53,1.47-1.26,1.39
	C349.68,363.48,349.12,362.86,348.07,362.19z"
              />
              <path
                d="M350.12,420.08c-1.03,0.63-1.58,1.19-2.21,1.31c-0.78,0.15-1.42-0.72-1.3-1.56c0.12-0.8,0.63-1.41,1.4-1.22
	C348.63,418.75,349.14,419.36,350.12,420.08z"
              />
              <path
                d="M525.77,621.49c0.02,0.87-0.59,1.5-1.45,1.47c-0.82-0.02-1.65-0.79-1.39-1.5c0.2-0.55,0.79-1.14,1.34-1.33
	C525.03,619.87,525.75,620.63,525.77,621.49z"
              />
              <path
                d="M312.72,621.9c0.06,0.87-0.53,1.53-1.38,1.55c-0.82,0.02-1.68-0.71-1.46-1.43c0.17-0.56,0.73-1.18,1.27-1.4
	C311.89,620.31,312.66,621.06,312.72,621.9z"
              />
              <path
                d="M330.02,317.06c-0.81,0.66-1.25,1.23-1.82,1.43c-0.71,0.24-1.57-0.63-1.58-1.4c-0.01-0.78,0.8-1.62,1.56-1.39
	C328.75,315.86,329.2,316.43,330.02,317.06z"
              />
              <path
                d="M343.95,601.04c-0.79,0.7-1.21,1.29-1.78,1.51c-0.71,0.27-1.59-0.56-1.63-1.34c-0.04-0.8,0.72-1.66,1.5-1.46
	C342.62,599.89,343.1,600.44,343.95,601.04z"
              />
              <path
                d="M524.3,280.65c0.23,0.31,0.77,0.77,0.92,1.33c0.21,0.81-0.62,1.68-1.38,1.44c-0.55-0.18-1.16-0.77-1.37-1.31
	C522.21,281.39,523.08,280.63,524.3,280.65z"
              />
              <path
                d="M380.9,359.7c-0.87,0.57-1.36,1.1-1.94,1.22c-0.79,0.17-1.53-0.7-1.46-1.49c0.06-0.8,0.93-1.57,1.66-1.28
	C379.71,358.37,380.11,358.97,380.9,359.7z"
              />
              <path
                d="M419.13,618.43c1.24,0.02,2.08,0.81,1.79,1.52c-0.22,0.55-0.85,1.13-1.42,1.29c-0.78,0.22-1.58-0.67-1.35-1.49
	C418.32,619.18,418.89,618.74,419.13,618.43z"
              />
              <path
                d="M302.13,492.81c-0.96,0.72-1.45,1.34-2.07,1.5c-0.78,0.21-1.3-0.38-1.43-1.18c-0.13-0.83,0.49-1.74,1.26-1.6
	C300.53,491.64,301.09,492.19,302.13,492.81z"
              />
              <path
                d="M372.58,373.25c1.23,0.01,2.07,0.8,1.78,1.51c-0.22,0.54-0.85,1.11-1.4,1.27c-0.78,0.22-1.57-0.67-1.34-1.48
	C371.78,373.99,372.35,373.55,372.58,373.25z"
              />
              <path
                d="M265.11,176.48c1.24,0.03,2.09,0.85,1.79,1.56c-0.23,0.55-0.87,1.13-1.44,1.29c-0.77,0.22-1.58-0.71-1.34-1.52
	C264.29,177.23,264.86,176.79,265.11,176.48z"
              />
              <path
                d="M513.72,358.3c0.08,1.28-0.59,2.12-1.37,1.89c-0.56-0.16-1.18-0.74-1.39-1.29c-0.28-0.72,0.58-1.61,1.39-1.44
	C512.92,357.58,513.41,358.1,513.72,358.3z"
              />
              <path
                d="M340.02,621.46c-0.65-1.03-1.22-1.58-1.35-2.22c-0.15-0.73,0.82-1.45,1.59-1.32c0.79,0.13,1.4,0.66,1.21,1.44
	C341.33,619.98,340.72,620.49,340.02,621.46z"
              />
              <path
                d="M517.65,623.42c-1.26,0.04-2.16-0.73-1.91-1.45c0.19-0.56,0.8-1.16,1.35-1.35c0.78-0.26,1.61,0.59,1.42,1.43
	C518.39,622.62,517.86,623.11,517.65,623.42z"
              />
              <path
                d="M463.36,419.37c-0.63-0.95-1.26-1.5-1.35-2.13c-0.11-0.78,0.57-1.25,1.39-1.29c0.86-0.04,1.6,0.69,1.37,1.52
	C464.61,418.03,464.04,418.49,463.36,419.37z"
              />
              <path
                d="M381.85,460.88c-0.34,0.27-0.81,0.89-1.42,1.09c-0.71,0.23-1.68-0.72-1.46-1.42c0.18-0.56,0.74-1.18,1.28-1.4
	C380.98,458.85,381.77,459.6,381.85,460.88z"
              />
              <path
                d="M353.01,356.68c1.26-0.09,1.94,0.53,1.94,1.38c-0.01,0.8-0.39,1.36-1.22,1.45c-0.93,0.1-1.65-0.4-1.6-1.27
	C352.15,357.64,352.78,357.06,353.01,356.68z"
              />
              <path
                d="M387.61,106.3c-0.86,0.59-1.34,1.13-1.91,1.27c-0.78,0.19-1.54-0.67-1.49-1.46c0.05-0.81,0.89-1.59,1.63-1.32
	C386.41,104.99,386.82,105.59,387.61,106.3z"
              />
              <path
                d="M532.24,630.03c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.83,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.59,1.34,1.36
	C533.58,628.58,532.92,629.11,532.24,630.03z"
              />
              <path
                d="M513.03,620.39c-0.3-0.37-0.98-0.87-1.13-1.5c-0.18-0.77,0.46-1.34,1.28-1.41c0.81-0.07,1.38,0.31,1.53,1.16
	C514.85,619.49,514.26,620.29,513.03,620.39z"
              />
              <path
                d="M299.18,417.46c-0.98,0.6-1.55,1.21-2.17,1.27c-0.73,0.07-1.26-0.55-1.24-1.4c0.01-0.84,0.75-1.57,1.55-1.32
	C297.87,416.19,298.31,416.76,299.18,417.46z"
              />
              <path
                d="M372.6,306.22c-1.03,0.56-1.62,1.13-2.25,1.17c-0.76,0.05-1.22-0.61-1.17-1.45c0.05-0.85,0.81-1.53,1.61-1.24
	C371.34,304.9,371.76,305.49,372.6,306.22z"
              />
              <path
                d="M512.37,377.04c-0.81,0.66-1.25,1.24-1.83,1.44c-0.71,0.24-1.58-0.64-1.59-1.41c-0.01-0.79,0.81-1.64,1.57-1.41
	C511.1,375.83,511.55,376.4,512.37,377.04z"
              />
              <path
                d="M376.27,86.81c-0.3-0.38-0.98-0.88-1.12-1.5c-0.18-0.77,0.47-1.34,1.29-1.41c0.81-0.07,1.38,0.31,1.53,1.17
	C378.1,85.92,377.5,86.71,376.27,86.81z"
              />
              <path
                d="M393.37,629.39c-0.8,0.68-1.23,1.27-1.8,1.48c-0.71,0.26-1.58-0.6-1.61-1.38c-0.03-0.8,0.77-1.65,1.54-1.44
	C392.07,628.21,392.54,628.77,393.37,629.39z"
              />
              <path
                d="M366.03,175.04c1.25-0.03,1.91,0.64,1.88,1.48c-0.02,0.84-0.54,1.29-1.3,1.43c-0.75,0.13-1.73-0.65-1.56-1.37
	C365.21,175.94,365.78,175.41,366.03,175.04z"
              />
              <path
                d="M525.78,289.69c-0.37,0.25-0.89,0.83-1.52,0.97c-0.75,0.16-1.61-0.83-1.33-1.52c0.22-0.54,0.83-1.11,1.39-1.29
	C525.07,287.6,525.8,288.42,525.78,289.69z"
              />
              <path
                d="M282.65,377.26c1.24,0.07,1.86,0.78,1.75,1.62c-0.1,0.8-0.55,1.27-1.39,1.29c-0.82,0.01-1.67-0.73-1.44-1.45
	C281.76,378.09,282.38,377.61,282.65,377.26z"
              />
              <path
                d="M436.63,609.47c-0.73-0.97-1.34-1.48-1.53-2.11c-0.21-0.71,0.71-1.5,1.5-1.43c0.79,0.07,1.45,0.52,1.31,1.33
	C437.81,607.89,437.25,608.45,436.63,609.47z"
              />
              <path
                d="M446.6,415.26c-0.03,0.86-0.75,1.6-1.53,1.32c-0.54-0.2-1.12-0.8-1.31-1.35c-0.24-0.71,0.62-1.48,1.42-1.48
	C446.01,413.74,446.63,414.4,446.6,415.26z"
              />
              <path
                d="M424.28,260.07c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.82,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.6,1.34,1.36
	C425.61,258.62,424.95,259.15,424.28,260.07z"
              />
              <path
                d="M460.21,173.53c0.7,0.9,1.43,1.43,1.47,2.01c0.03,0.44-0.73,1.15-1.28,1.37c-0.73,0.28-1.64-0.58-1.47-1.38
	C459.06,174.95,459.59,174.46,460.21,173.53z"
              />
              <path
                d="M306.28,629.22c0.04,1.23-0.69,1.95-1.52,1.89c-0.86-0.05-1.3-0.56-1.33-1.39c-0.03-0.84,0.45-1.52,1.24-1.42
	C305.31,628.37,305.88,628.97,306.28,629.22z"
              />
              <path
                d="M394.08,121.86c-0.65-0.89-1.21-1.36-1.36-1.94c-0.21-0.82,0.56-1.53,1.41-1.47c0.82,0.06,1.48,0.56,1.35,1.33
	C395.38,120.4,394.74,120.94,394.08,121.86z"
              />
              <path
                d="M344.08,628.45c1.24,0.07,1.83,0.79,1.74,1.64c-0.09,0.84-0.63,1.25-1.4,1.31c-0.78,0.06-1.68-0.78-1.45-1.48
	C343.18,629.29,343.8,628.8,344.08,628.45z"
              />
              <path
                d="M500.54,603.25c-0.2-0.33-0.71-0.84-0.82-1.42c-0.16-0.85,0.72-1.66,1.48-1.36c0.55,0.21,1.13,0.85,1.29,1.41
	C502.72,602.63,501.79,603.35,500.54,603.25z"
              />
              <path
                d="M374.95,464.78c0.95-0.75,1.43-1.38,2.05-1.55c0.77-0.21,1.31,0.37,1.45,1.17c0.15,0.83-0.46,1.74-1.24,1.61
	C376.57,465.91,376,465.37,374.95,464.78z"
              />
              <path
                d="M309.16,412.34c0.04,1.23-0.7,1.94-1.53,1.89c-0.85-0.06-1.3-0.57-1.32-1.4c-0.02-0.84,0.46-1.51,1.25-1.42
	C308.19,411.49,308.76,412.09,309.16,412.34z"
              />
              <path
                d="M280.54,617.85c0.42-0.45,0.68-0.92,1.06-1.08c0.52-0.22,1.17-0.34,1.69-0.21c0.19,0.05,0.41,1.14,0.19,1.4
	c-0.39,0.47-1.08,0.85-1.69,0.91C281.44,618.9,281.02,618.26,280.54,617.85z"
              />
              <path
                d="M370.3,463.66c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.49c-0.84-0.05-1.31-0.54-1.41-1.35
	C368.3,464.39,369.06,463.64,370.3,463.66z"
              />
              <path
                d="M481.42,410.65c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C479.49,411.25,480.18,410.59,481.42,410.65z"
              />
              <path
                d="M359.41,301.35c0.73,0.94,1.4,1.44,1.53,2.06c0.16,0.75-0.44,1.31-1.28,1.41c-0.86,0.1-1.66-0.57-1.49-1.42
	C358.29,302.82,358.8,302.31,359.41,301.35z"
              />
              <path
                d="M331.86,551.21c0.07,1.27-0.61,2.11-1.38,1.88c-0.56-0.17-1.17-0.75-1.38-1.29c-0.28-0.72,0.6-1.61,1.4-1.43
	C331.07,550.48,331.55,551,331.86,551.21z"
              />
              <path
                d="M322.31,410.18c0.23,0.33,0.78,0.8,0.92,1.38c0.2,0.83-0.61,1.58-1.44,1.49c-0.8-0.09-1.32-0.56-1.4-1.39
	C320.32,410.87,321.09,410.14,322.31,410.18z"
              />
              <path
                d="M328.58,548.26c-0.79,0.69-1.21,1.27-1.76,1.48c-0.7,0.26-1.57-0.55-1.61-1.31c-0.04-0.79,0.71-1.62,1.48-1.43
	C327.26,547.14,327.73,547.67,328.58,548.26z"
              />
              <path
                d="M351.87,591.63c-0.66-0.89-1.22-1.35-1.38-1.93c-0.22-0.83,0.54-1.54,1.4-1.49c0.82,0.05,1.49,0.55,1.36,1.31
	C353.16,590.15,352.52,590.69,351.87,591.63z"
              />
              <path
                d="M374.55,351.22c-0.72-0.85-1.31-1.27-1.49-1.83c-0.27-0.81,0.44-1.58,1.3-1.58c0.82,0,1.52,0.45,1.44,1.23
	C375.74,349.67,375.14,350.24,374.55,351.22z"
              />
              <path
                d="M310.45,27.64c0.63,0.91,1.17,1.4,1.31,1.98c0.19,0.76-0.75,1.71-1.43,1.44c-0.56-0.22-1.32-0.91-1.3-1.36
	C309.07,29.11,309.78,28.56,310.45,27.64z"
              />
              <path
                d="M374.3,434.36c1.24,0.07,1.86,0.78,1.75,1.62c-0.1,0.8-0.55,1.27-1.39,1.29c-0.82,0.01-1.67-0.73-1.44-1.45
	C373.41,435.19,374.03,434.71,374.3,434.36z"
              />
              <path
                d="M493.47,581.29c-0.37,0.29-0.88,0.95-1.5,1.08c-0.74,0.16-1.33-0.4-1.39-1.26c-0.06-0.84,0.35-1.36,1.17-1.52
	C492.56,579.44,493.36,580.07,493.47,581.29z"
              />
              <path
                d="M294.75,350.73c-0.65-0.89-1.2-1.35-1.35-1.92c-0.21-0.82,0.55-1.51,1.4-1.46c0.82,0.05,1.47,0.56,1.34,1.31
	C296.05,349.28,295.41,349.81,294.75,350.73z"
              />
              <path
                d="M345.84,595.88c0.23,0.33,0.77,0.82,0.91,1.4c0.19,0.82-0.7,1.7-1.45,1.42c-0.56-0.21-1.16-0.82-1.35-1.38
	C343.71,596.6,344.63,595.82,345.84,595.88z"
              />
              <path
                d="M348.38,591.49c0.71,0.89,1.43,1.41,1.48,1.98c0.03,0.43-0.71,1.15-1.26,1.36c-0.73,0.29-1.64-0.55-1.47-1.35
	C347.26,592.9,347.78,592.41,348.38,591.49z"
              />
              <path
                d="M515.49,346.36c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C513.56,346.95,514.24,346.29,515.49,346.36z"
              />
              <path
                d="M263.31,261.85c-0.37,0.25-0.89,0.84-1.51,0.98c-0.75,0.17-1.62-0.82-1.35-1.51c0.22-0.55,0.82-1.12,1.38-1.3
	C262.58,259.76,263.32,260.58,263.31,261.85z"
              />
              <path
                d="M520.19,323.72c0.7,0.91,1.43,1.44,1.46,2.02c0.03,0.44-0.73,1.15-1.28,1.36c-0.73,0.28-1.64-0.58-1.46-1.39
	C519.04,325.13,519.57,324.64,520.19,323.72z"
              />
              <path
                d="M464.84,583.83c-0.37,0.25-0.89,0.83-1.52,0.97c-0.75,0.16-1.61-0.83-1.33-1.52c0.22-0.54,0.83-1.11,1.39-1.29
	C464.13,581.74,464.86,582.56,464.84,583.83z"
              />
              <path
                d="M294.15,263.58c-0.27-0.34-0.88-0.83-1.06-1.45c-0.22-0.72,0.65-1.48,1.45-1.45c0.82,0.03,1.29,0.5,1.38,1.3
	C296.02,262.85,295.42,263.53,294.15,263.58z"
              />
              <path
                d="M442.73,348.83c-0.63-0.95-1.26-1.5-1.35-2.12c-0.11-0.77,0.57-1.25,1.38-1.29c0.86-0.04,1.6,0.68,1.37,1.51
	C443.98,347.49,443.41,347.95,442.73,348.83z"
              />
              <path
                d="M481.16,232.1c-0.37,0.25-0.89,0.84-1.51,0.98c-0.75,0.17-1.62-0.82-1.35-1.51c0.22-0.55,0.82-1.12,1.38-1.3
	C480.43,230.01,481.17,230.83,481.16,232.1z"
              />
              <path
                d="M317.06,625.16c-0.82,0.63-1.27,1.19-1.84,1.37c-0.71,0.22-1.55-0.66-1.54-1.41c0.01-0.77,0.84-1.58,1.58-1.34
	C315.82,623.95,316.25,624.51,317.06,625.16z"
              />
              <path
                d="M461.99,587.46c-0.82,0.64-1.27,1.21-1.84,1.38c-0.78,0.23-1.56-0.58-1.56-1.39c0-0.8,0.81-1.63,1.57-1.4
	C460.72,586.24,461.17,586.81,461.99,587.46z"
              />
              <path
                d="M405.31,131.1c-0.23-0.37-0.86-0.94-0.89-1.55c-0.04-0.86,0.67-1.39,1.6-1.29c0.82,0.09,1.22,0.64,1.23,1.45
	C407.25,130.58,406.6,131.18,405.31,131.1z"
              />
              <path
                d="M261.38,406.19c-0.06,0.85-0.81,1.58-1.57,1.27c-0.54-0.21-1.1-0.84-1.26-1.39c-0.22-0.72,0.66-1.46,1.46-1.44
	C260.83,404.65,261.44,405.34,261.38,406.19z"
              />
              <path
                d="M506.06,610.97c0.23,0.31,0.79,0.77,0.95,1.34c0.23,0.84-0.51,1.55-1.38,1.53c-0.85-0.02-1.32-0.52-1.44-1.31
	C504.07,611.74,504.82,610.97,506.06,610.97z"
              />
              <path
                d="M585.25,630.86c-1.04,0.69-1.58,1.32-2.21,1.41c-0.73,0.1-1.29-0.5-1.29-1.35c0-0.84,0.49-1.47,1.26-1.37
	C583.64,629.62,584.2,630.21,585.25,630.86z"
              />
              <path
                d="M462.33,215.52c-1.02,0.57-1.62,1.14-2.24,1.18c-0.76,0.05-1.23-0.6-1.18-1.45c0.05-0.85,0.81-1.54,1.6-1.25
	C461.07,214.21,461.48,214.79,462.33,215.52z"
              />
              <path
                d="M587.62,626.52c0.09,1.28-0.6,2.14-1.37,1.92c-0.56-0.17-1.19-0.75-1.41-1.29c-0.29-0.71,0.59-1.63,1.39-1.47
	C586.82,625.79,587.31,626.32,587.62,626.52z"
              />
              <path
                d="M402.45,623.45c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.49c-0.84-0.05-1.31-0.54-1.41-1.35
	C400.45,624.18,401.21,623.43,402.45,623.45z"
              />
              <path
                d="M393.71,114.75c-0.72-0.96-1.33-1.46-1.49-2.07c-0.2-0.77,0.4-1.31,1.19-1.46c0.78-0.14,1.75,0.55,1.62,1.29
	C394.9,113.15,394.35,113.71,393.71,114.75z"
              />
              <path
                d="M530.53,624.77c-0.96,0.72-1.45,1.34-2.07,1.5c-0.78,0.21-1.3-0.38-1.43-1.18c-0.13-0.83,0.49-1.74,1.26-1.6
	C528.93,623.6,529.49,624.15,530.53,624.77z"
              />
              <path
                d="M297.85,616.12c-0.06,0.81-0.88,1.61-1.58,1.3c-0.54-0.24-1.1-0.85-1.28-1.42c-0.22-0.71,0.68-1.47,1.48-1.45
	C297.3,614.58,297.91,615.28,297.85,616.12z"
              />
              <path
                d="M439.36,388.59c0.65,0.78,1.22,1.21,1.4,1.76c0.25,0.8-0.48,1.55-1.31,1.58c-0.78,0.02-1.67-0.78-1.45-1.49
	C438.18,389.86,438.74,389.41,439.36,388.59z"
              />
              <path
                d="M323.9,481.43c-0.27-0.34-0.88-0.83-1.06-1.45c-0.22-0.72,0.65-1.48,1.45-1.45c0.82,0.03,1.29,0.5,1.38,1.3
	C325.77,480.69,325.17,481.38,323.9,481.43z"
              />
              <path
                d="M403.93,126.45c-0.86,0.59-1.34,1.13-1.91,1.27c-0.78,0.19-1.54-0.67-1.49-1.46c0.05-0.81,0.89-1.59,1.63-1.32
	C402.72,125.15,403.13,125.75,403.93,126.45z"
              />
              <path
                d="M485,227.89c-0.83,0.62-1.29,1.16-1.86,1.33c-0.72,0.21-1.54-0.68-1.51-1.43c0.02-0.77,0.86-1.57,1.6-1.32
	C483.78,226.65,484.21,227.22,485,227.89z"
              />
              <path
                d="M465.6,218.52c-0.04,0.81-0.81,1.48-1.64,1.36c-0.84-0.12-1.26-0.66-1.23-1.49c0.03-0.83,0.5-1.32,1.35-1.37
	C464.9,216.98,465.64,217.73,465.6,218.52z"
              />
              <path
                d="M509.4,617.62c-0.65-1.03-1.22-1.58-1.35-2.22c-0.15-0.73,0.82-1.45,1.6-1.32c0.79,0.13,1.4,0.66,1.21,1.44
	C510.71,616.15,510.1,616.65,509.4,617.62z"
              />
              <path
                d="M316.12,531.22c-0.8,0.69-1.22,1.29-1.78,1.48c-0.76,0.27-1.59-0.51-1.62-1.32c-0.04-0.79,0.74-1.66,1.5-1.46
	C314.79,530.07,315.27,530.61,316.12,531.22z"
              />
              <path
                d="M379.37,265.2c0.09,1.28-0.59,2.14-1.37,1.91c-0.56-0.17-1.19-0.75-1.41-1.29c-0.29-0.71,0.59-1.63,1.39-1.47
	C378.56,264.48,379.05,265,379.37,265.2z"
              />
              <path
                d="M293.48,392.42c-0.99,0.68-1.53,1.26-2.17,1.41c-0.76,0.18-1.41-0.71-1.34-1.56c0.07-0.85,0.63-1.41,1.37-1.27
	C291.97,391.13,292.5,391.74,293.48,392.42z"
              />
              <path
                d="M442.31,152.03c-0.82,0.65-1.28,1.21-1.85,1.4c-0.72,0.23-1.57-0.67-1.56-1.44c0.01-0.78,0.85-1.62,1.6-1.38
	C441.07,150.8,441.51,151.38,442.31,152.03z"
              />
              <path
                d="M340.59,605.28c-0.84,0.62-1.31,1.17-1.88,1.32c-0.78,0.21-1.55-0.63-1.52-1.43c0.03-0.8,0.86-1.62,1.6-1.36
	C339.35,604.01,339.78,604.6,340.59,605.28z"
              />
              <path
                d="M521.38,623.33c0.6,1.01,1.21,1.6,1.26,2.22c0.05,0.75-0.61,1.25-1.46,1.24c-0.85-0.02-1.58-0.79-1.3-1.6
	C520.07,624.63,520.66,624.2,521.38,623.33z"
              />
              <path
                d="M318.51,473.38c-0.79,0.7-1.2,1.28-1.75,1.49c-0.7,0.27-1.57-0.53-1.62-1.29c-0.05-0.8,0.67-1.62,1.47-1.44
	C317.17,472.27,317.64,472.8,318.51,473.38z"
              />
              <path
                d="M538.13,631.71c-1.07,0.48-1.7,0.98-2.33,0.98c-0.79,0-1.16-0.71-1.06-1.53c0.11-0.85,0.91-1.46,1.69-1.1
	C536.97,630.29,537.34,630.91,538.13,631.71z"
              />
              <path
                d="M364.97,392.85c0.09,1.27-0.59,2.12-1.36,1.9c-0.56-0.16-1.18-0.74-1.4-1.28c-0.28-0.71,0.58-1.62,1.38-1.45
	C364.16,392.13,364.65,392.64,364.97,392.85z"
              />
              <path
                d="M383.99,93.2c-0.21-0.31-0.74-0.8-0.87-1.38c-0.19-0.84,0.64-1.69,1.42-1.43c0.56,0.19,1.16,0.8,1.35,1.35
	C386.15,92.48,385.26,93.24,383.99,93.2z"
              />
              <path
                d="M362.17,271.09c-0.85,0.61-1.31,1.16-1.89,1.32c-0.74,0.2-1.54-0.69-1.5-1.46c0.03-0.78,0.88-1.58,1.62-1.32
	C360.96,269.83,361.38,270.42,362.17,271.09z"
              />
              <path
                d="M285.41,401.74c-1.26,0.04-2.16-0.73-1.91-1.45c0.19-0.56,0.8-1.16,1.35-1.35c0.78-0.26,1.61,0.59,1.42,1.43
	C286.14,400.94,285.62,401.42,285.41,401.74z"
              />
              <path
                d="M356.84,266.22c0.23,0.32,0.79,0.79,0.94,1.36c0.21,0.82-0.64,1.72-1.4,1.47c-0.56-0.19-1.18-0.78-1.39-1.34
	C354.72,267,355.62,266.2,356.84,266.22z"
              />
              <path
                d="M437.51,394.65c-0.33,0.28-0.8,0.9-1.41,1.11c-0.7,0.24-1.69-0.71-1.47-1.41c0.17-0.56,0.73-1.19,1.27-1.41
	C436.63,392.63,437.42,393.38,437.51,394.65z"
              />
              <path
                d="M482.33,222.72c-0.64-1.01-1.21-1.55-1.33-2.18c-0.15-0.8,0.5-1.27,1.29-1.36c0.78-0.09,1.72,0.68,1.53,1.4
	C483.64,221.21,483.04,221.73,482.33,222.72z"
              />
              <path
                d="M418.72,338.04c-0.94,0.75-1.42,1.41-2.04,1.56c-0.72,0.18-1.32-0.35-1.45-1.18c-0.13-0.84,0.47-1.76,1.24-1.64
	C417.11,336.89,417.68,337.43,418.72,338.04z"
              />
              <path
                d="M250.53,336.17c0.7,0.92,1.43,1.46,1.46,2.04c0.03,0.45-0.74,1.16-1.3,1.37c-0.74,0.28-1.65-0.6-1.46-1.41
	C249.36,337.59,249.9,337.1,250.53,336.17z"
              />
              <path
                d="M303.08,626.58c-0.99,0.68-1.53,1.27-2.16,1.42c-0.76,0.18-1.41-0.71-1.34-1.55c0.07-0.85,0.62-1.41,1.37-1.27
	C301.57,625.29,302.1,625.9,303.08,626.58z"
              />
              <path
                d="M472.13,223.7c0.28,0.35,0.91,0.82,1.12,1.44c0.24,0.72-0.6,1.48-1.41,1.48c-0.84,0-1.3-0.46-1.42-1.26
	C470.28,224.53,470.9,223.79,472.13,223.7z"
              />
              <path
                d="M493.11,586.79c0.23,0.31,0.77,0.77,0.92,1.33c0.21,0.81-0.62,1.68-1.38,1.44c-0.55-0.18-1.16-0.77-1.37-1.31
	C491.02,587.53,491.89,586.77,493.11,586.79z"
              />
              <path
                d="M469.48,221.5c-0.09,1.23-0.89,1.87-1.71,1.73c-0.85-0.14-1.23-0.7-1.18-1.52c0.05-0.83,0.59-1.46,1.38-1.28
	C468.59,220.58,469.1,221.22,469.48,221.5z"
              />
              <path
                d="M431.42,335.53c0.26,0.36,0.93,0.88,1.02,1.48c0.13,0.87-0.54,1.42-1.48,1.41c-0.84-0.02-1.27-0.52-1.35-1.33
	C429.54,336.23,430.17,335.55,431.42,335.53z"
              />
              <path
                d="M519.09,337.09c-0.34,0.27-0.82,0.89-1.43,1.08c-0.71,0.22-1.68-0.73-1.45-1.43c0.18-0.56,0.75-1.17,1.3-1.39
	C518.24,335.06,519.01,335.82,519.09,337.09z"
              />
              <path
                d="M490.18,577.78c-1.02,0.58-1.61,1.17-2.24,1.22c-0.75,0.06-1.24-0.6-1.21-1.45c0.04-0.85,0.81-1.57,1.6-1.28
	C488.89,576.47,489.32,577.05,490.18,577.78z"
              />
              <path
                d="M513.95,624.08c0.71,0.91,1.44,1.45,1.48,2.03c0.03,0.45-0.73,1.16-1.29,1.38c-0.72,0.29-1.65-0.6-1.48-1.4
	C512.79,625.51,513.33,625.01,513.95,624.08z"
              />
              <path
                d="M279.11,337.79c-0.63-0.95-1.26-1.5-1.35-2.12c-0.11-0.77,0.57-1.25,1.38-1.29c0.86-0.04,1.6,0.68,1.37,1.51
	C280.35,336.46,279.78,336.91,279.11,337.79z"
              />
              <path
                d="M412.79,170.83c-0.66-0.9-1.22-1.38-1.37-1.96c-0.22-0.82,0.57-1.56,1.42-1.49c0.82,0.06,1.49,0.57,1.36,1.34
	C414.1,169.35,413.45,169.89,412.79,170.83z"
              />
              <path
                d="M439.45,396.25c1.25-0.03,1.91,0.64,1.89,1.48c-0.02,0.84-0.54,1.29-1.3,1.43c-0.75,0.13-1.73-0.65-1.56-1.37
	C438.62,397.15,439.2,396.62,439.45,396.25z"
              />
              <path
                d="M530.91,631.95c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C529.62,630.68,530.06,631.27,530.91,631.95z"
              />
              <path
                d="M577.94,631.36c-0.9,0.65-1.37,1.21-1.95,1.37c-0.78,0.22-1.52-0.64-1.43-1.48c0.09-0.85,0.65-1.38,1.44-1.2
	C576.58,630.17,577.06,630.73,577.94,631.36z"
              />
              <path
                d="M342.54,626.51c-0.88,0.69-1.33,1.26-1.89,1.43c-0.75,0.22-1.59-0.67-1.5-1.45c0.1-0.82,0.64-1.39,1.44-1.24
	C341.15,625.35,341.63,625.9,342.54,626.51z"
              />
              <path
                d="M280.71,268.62c0.72,0.81,1.32,1.23,1.52,1.79c0.26,0.71-0.62,1.59-1.42,1.52c-0.81-0.07-1.45-0.52-1.3-1.36
	C279.61,269.99,280.14,269.5,280.71,268.62z"
              />
              <path
                d="M561.19,631.4c1.24,0.04,1.92,0.77,1.79,1.56c-0.13,0.8-0.65,1.23-1.46,1.24c-0.86,0.01-1.57-0.72-1.32-1.53
	C560.38,632.11,560.95,631.69,561.19,631.4z"
              />
              <path
                d="M306.38,582.89c-0.84,0.67-1.29,1.25-1.87,1.44c-0.72,0.24-1.49-0.65-1.43-1.49c0.06-0.87,0.64-1.4,1.4-1.27
	C305.04,581.67,305.52,582.27,306.38,582.89z"
              />
              <path
                d="M480.53,217.27c-0.9,0.65-1.38,1.2-1.96,1.36c-0.78,0.21-1.51-0.64-1.42-1.49c0.09-0.85,0.66-1.38,1.45-1.2
	C479.18,216.07,479.65,216.64,480.53,217.27z"
              />
              <path
                d="M398.02,125.14c-0.66-0.8-1.26-1.24-1.43-1.79c-0.24-0.81,0.32-1.36,1.14-1.49c0.82-0.14,1.75,0.59,1.57,1.34
	C399.17,123.78,398.63,124.27,398.02,125.14z"
              />
              <path
                d="M242.04,216.41c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.12
	C240.75,215.14,241.2,215.73,242.04,216.41z"
              />
              <path
                d="M446.72,601.15c0.72,0.81,1.32,1.22,1.53,1.78c0.27,0.72-0.61,1.59-1.41,1.53c-0.81-0.06-1.46-0.51-1.31-1.35
	C445.64,602.53,446.16,602.03,446.72,601.15z"
              />
              <path
                d="M297.49,628.5c0.72,0.81,1.32,1.22,1.53,1.78c0.27,0.71-0.61,1.59-1.41,1.53c-0.81-0.06-1.46-0.51-1.31-1.35
	C296.4,629.88,296.93,629.38,297.49,628.5z"
              />
              <path
                d="M315.94,484.67c-0.93,0.59-1.44,1.11-2.03,1.23c-0.8,0.16-1.46-0.73-1.32-1.58c0.14-0.86,0.76-1.32,1.52-1.1
	C314.67,483.38,315.1,483.98,315.94,484.67z"
              />
              <path
                d="M262.4,326.68c-0.9,0.65-1.36,1.21-1.93,1.36c-0.75,0.2-1.58-0.72-1.46-1.5c0.13-0.82,0.69-1.37,1.48-1.2
	C261.05,325.46,261.52,326.04,262.4,326.68z"
              />
              <path
                d="M472.37,582.82c-0.87,0.69-1.31,1.26-1.89,1.45c-0.78,0.25-1.53-0.56-1.48-1.42c0.05-0.86,0.58-1.41,1.38-1.26
	C470.96,581.68,471.46,582.22,472.37,582.82z"
              />
              <path
                d="M484.22,410.16c-0.59-0.85-1.14-1.34-1.26-1.91c-0.17-0.83,0.44-1.31,1.27-1.38c0.85-0.07,1.7,0.71,1.45,1.46
	C485.49,408.91,484.9,409.35,484.22,410.16z"
              />
              <path
                d="M324.77,545.1c-0.92,0.61-1.41,1.14-1.98,1.26c-0.78,0.16-1.54-0.76-1.39-1.55c0.16-0.82,0.75-1.34,1.52-1.15
	C323.48,543.81,323.92,544.41,324.77,545.1z"
              />
              <path
                d="M512.68,630.03c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C511.39,628.76,511.83,629.36,512.68,630.03z"
              />
              <path
                d="M469.21,586.75c-0.9,0.65-1.36,1.21-1.93,1.36c-0.75,0.2-1.58-0.72-1.46-1.5c0.13-0.82,0.69-1.37,1.48-1.2
	C467.87,585.54,468.33,586.11,469.21,586.75z"
              />
              <path d="M223.21,237.07c0-0.98,0-1.8,0-2.67c1.23-0.27,1.89,0.14,1.95,1.22C225.21,236.68,224.65,237.24,223.21,237.07z" />
              <path
                d="M485.53,234.96c-0.88,0.69-1.33,1.26-1.9,1.43c-0.75,0.22-1.59-0.68-1.49-1.46c0.1-0.82,0.64-1.39,1.44-1.24
	C484.15,233.8,484.63,234.35,485.53,234.96z"
              />
              <path
                d="M277.07,412.22c-0.93,0.59-1.44,1.11-2.03,1.23c-0.8,0.16-1.46-0.73-1.32-1.58c0.14-0.86,0.76-1.32,1.52-1.1
	C275.8,410.92,276.23,411.52,277.07,412.22z"
              />
              <path
                d="M507.17,382.81c-0.68-0.81-1.27-1.24-1.47-1.81c-0.26-0.75,0.58-1.54,1.43-1.48c0.83,0.06,1.45,0.53,1.28,1.37
	C508.3,381.46,507.75,381.95,507.17,382.81z"
              />
              <path
                d="M308.94,239.51c-0.93,0.57-1.44,1.09-2.02,1.19c-0.79,0.14-1.51-0.8-1.34-1.58c0.18-0.83,0.8-1.31,1.56-1.11
	C307.68,238.16,308.1,238.79,308.94,239.51z"
              />
              <path
                d="M278.69,623.05c-0.66-0.87-1.24-1.33-1.39-1.89c-0.2-0.75,0.72-1.52,1.52-1.44c0.87,0.08,1.37,0.67,1.2,1.44
	C279.91,621.72,279.33,622.18,278.69,623.05z"
              />
              <path
                d="M447.21,355.47c0.72,0.81,1.32,1.23,1.52,1.79c0.26,0.71-0.62,1.59-1.42,1.52c-0.81-0.07-1.45-0.52-1.3-1.36
	C446.12,356.84,446.65,356.35,447.21,355.47z"
              />
              <path
                d="M351.93,568.13c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C350.64,566.86,351.08,567.46,351.93,568.13z"
              />
              <path
                d="M473.05,590.21c-0.92,0.6-1.41,1.13-1.99,1.25c-0.78,0.16-1.54-0.77-1.39-1.55c0.16-0.82,0.76-1.34,1.53-1.14
	C471.76,588.92,472.19,589.52,473.05,590.21z"
              />
              <path
                d="M342.02,81.06c-0.81,0.72-1.22,1.32-1.77,1.53c-0.73,0.27-1.75-0.63-1.52-1.35c0.18-0.56,0.81-1.25,1.33-1.33
	C340.52,79.83,341.11,80.49,342.02,81.06z"
              />
              <path
                d="M337.43,592.79c-0.62-0.89-1.17-1.37-1.29-1.94c-0.17-0.81,0.4-1.34,1.22-1.44c0.78-0.1,1.71,0.74,1.49,1.46
	C338.67,591.45,338.1,591.91,337.43,592.79z"
              />
              <path
                d="M378.48,457.5c-0.84,0.61-1.3,1.15-1.87,1.3c-0.77,0.2-1.54-0.64-1.51-1.43c0.03-0.79,0.87-1.6,1.6-1.34
	C377.27,456.23,377.69,456.81,378.48,457.5z"
              />
              <path
                d="M380.99,430.17c-0.59-0.96-1.1-1.46-1.21-2.04c-0.16-0.84,0.7-1.65,1.46-1.35c0.54,0.21,1.27,0.93,1.24,1.37
	C382.43,428.72,381.72,429.25,380.99,430.17z"
              />
              <path
                d="M313.18,369.3c-1.11,0.59-1.71,1.14-2.35,1.18c-0.82,0.06-1.22-0.66-1.15-1.47c0.06-0.82,0.6-1.45,1.38-1.26
	C311.68,367.9,312.19,368.54,313.18,369.3z"
              />
              <path
                d="M256.61,428.19c-0.87,0.63-1.35,1.2-1.91,1.32c-0.81,0.16-1.31-0.48-1.44-1.26c-0.12-0.76,0.75-1.68,1.48-1.46
	C255.32,426.95,255.76,427.52,256.61,428.19z"
              />
              <path
                d="M236.72,364.89c-0.87,0.6-1.36,1.14-1.95,1.28c-0.8,0.19-1.5-0.68-1.37-1.53c0.13-0.82,0.68-1.39,1.47-1.19
	C235.45,363.61,235.9,364.22,236.72,364.89z"
              />
              <path
                d="M501.7,607.08c0.6,0.93,1.12,1.43,1.25,2.02c0.16,0.75-0.81,1.49-1.59,1.34c-0.83-0.16-1.34-0.75-1.11-1.53
	C500.4,608.35,501,607.92,501.7,607.08z"
              />
              <path
                d="M389.03,100.3c0.67,0.92,1.22,1.38,1.37,1.95c0.22,0.84-0.52,1.54-1.38,1.51c-0.84-0.03-1.47-0.56-1.37-1.31
	C387.73,101.83,388.36,101.29,389.03,100.3z"
              />
              <path
                d="M510.19,252.58c-0.63-0.88-1.19-1.35-1.31-1.92c-0.18-0.81,0.39-1.34,1.21-1.46c0.78-0.11,1.71,0.73,1.5,1.45
	C511.41,251.24,510.86,251.7,510.19,252.58z"
              />
              <path
                d="M431.23,189.26c-1.08,0.64-1.65,1.22-2.28,1.3c-0.8,0.09-1.25-0.59-1.22-1.41c0.03-0.82,0.52-1.48,1.31-1.33
	C429.66,187.93,430.19,188.55,431.23,189.26z"
              />
              <path
                d="M337.62,609.03c-1.02,0.67-1.56,1.27-2.18,1.37c-0.78,0.12-1.26-0.52-1.27-1.34c-0.01-0.82,0.47-1.48,1.24-1.38
	C336.03,607.77,336.58,608.37,337.62,609.03z"
              />
              <path
                d="M450.46,371.51c-0.81,0.66-1.24,1.23-1.81,1.41c-0.75,0.24-1.56-0.57-1.57-1.37c-0.01-0.79,0.79-1.63,1.54-1.41
	C449.19,370.31,449.64,370.87,450.46,371.51z"
              />
              <path
                d="M436.56,253.37c-0.83,0.71-1.25,1.3-1.81,1.49c-0.73,0.25-1.62-0.62-1.53-1.41c0.09-0.8,0.58-1.44,1.38-1.32
	C435.16,252.22,435.66,252.78,436.56,253.37z"
              />
              <path
                d="M476.38,585.89c-0.83,0.71-1.25,1.3-1.81,1.49c-0.73,0.25-1.62-0.62-1.53-1.41c0.09-0.8,0.58-1.44,1.38-1.32
	C474.99,584.75,475.49,585.3,476.38,585.89z"
              />
              <path
                d="M356.68,372.52c-0.86,0.62-1.33,1.18-1.91,1.33c-0.8,0.21-1.51-0.64-1.4-1.49c0.11-0.83,0.63-1.41,1.43-1.22
	C355.38,371.27,355.84,371.87,356.68,372.52z"
              />
              <path
                d="M609.61,628.3c1.01-0.69,1.54-1.31,2.16-1.42c0.77-0.13,1.26,0.51,1.29,1.32c0.02,0.82-0.44,1.48-1.22,1.39
	C611.22,629.53,610.66,628.94,609.61,628.3z"
              />
              <path
                d="M372.18,425.21c-1.02,0.7-1.54,1.31-2.17,1.43c-0.79,0.15-1.28-0.49-1.31-1.31c-0.03-0.83,0.44-1.5,1.21-1.42
	C370.55,423.97,371.12,424.56,372.18,425.21z"
              />
              <path
                d="M452.32,598.85c-1,0.73-1.51,1.35-2.13,1.49c-0.78,0.17-1.3-0.45-1.35-1.27c-0.05-0.82,0.39-1.51,1.17-1.45
	C450.65,597.66,451.23,598.24,452.32,598.85z"
              />
              <path
                d="M379.23,424.87c-0.87,0.6-1.36,1.14-1.95,1.28c-0.8,0.19-1.5-0.68-1.37-1.53c0.13-0.82,0.68-1.39,1.47-1.19
	C377.96,423.59,378.41,424.2,379.23,424.87z"
              />
              <path
                d="M499.34,598.57c-1.07,0.63-1.64,1.22-2.26,1.29c-0.79,0.09-1.24-0.59-1.21-1.41c0.03-0.82,0.52-1.47,1.31-1.32
	C497.8,597.24,498.33,597.86,499.34,598.57z"
              />
              <path
                d="M428.79,619.13c-1.03,0.66-1.58,1.26-2.2,1.35c-0.78,0.11-1.25-0.53-1.25-1.36c0-0.82,0.49-1.47,1.25-1.36
	C427.21,617.85,427.76,618.46,428.79,619.13z"
              />
              <path
                d="M457.18,592.71c-1.25,0.03-2.14-0.74-1.89-1.45c0.2-0.55,0.8-1.15,1.35-1.34c0.77-0.26,1.6,0.59,1.4,1.43
	C457.92,591.92,457.39,592.4,457.18,592.71z"
              />
              <path
                d="M332.9,598.32c-0.81,0.72-1.22,1.32-1.77,1.53c-0.73,0.27-1.75-0.63-1.52-1.35c0.18-0.56,0.81-1.25,1.33-1.33
	C331.41,597.1,332,597.76,332.9,598.32z"
              />
              <path
                d="M373.2,463.29c-0.66-0.98-1.34-1.55-1.35-2.12c0-0.44,0.78-1.1,1.34-1.27c0.76-0.23,1.57,0.65,1.36,1.46
	C374.4,461.91,373.86,462.37,373.2,463.29z"
              />
              <path
                d="M446.13,174.74c0.64,0.88,1.2,1.34,1.34,1.91c0.19,0.8-0.37,1.35-1.2,1.47c-0.79,0.11-1.72-0.69-1.51-1.43
	C444.92,176.1,445.48,175.63,446.13,174.74z"
              />
              <path
                d="M342.23,563.52c-0.62-0.89-1.17-1.37-1.29-1.94c-0.17-0.81,0.4-1.34,1.22-1.44c0.78-0.1,1.71,0.74,1.49,1.46
	C343.46,562.18,342.9,562.64,342.23,563.52z"
              />
              <path
                d="M345.39,77.48c-0.89,0.6-1.37,1.16-1.94,1.26c-0.82,0.14-1.31-0.5-1.41-1.29c-0.1-0.77,0.79-1.67,1.51-1.43
	C344.11,76.21,344.55,76.79,345.39,77.48z"
              />
              <path
                d="M400.53,628.9c-0.86,0.55-1.34,1.06-1.91,1.19c-0.75,0.17-1.49-0.72-1.42-1.48c0.07-0.77,0.96-1.54,1.64-1.25
	C399.39,627.59,399.77,628.18,400.53,628.9z"
              />
              <path
                d="M350.11,254.04c-1.07,0.63-1.64,1.22-2.26,1.29c-0.79,0.09-1.24-0.59-1.21-1.41c0.03-0.82,0.52-1.47,1.31-1.32
	C348.57,252.72,349.1,253.34,350.11,254.04z"
              />
              <path
                d="M340.37,558.23c-0.86,0.61-1.34,1.16-1.92,1.31c-0.8,0.2-1.51-0.66-1.39-1.51c0.12-0.82,0.65-1.4,1.45-1.21
	C339.07,556.97,339.53,557.58,340.37,558.23z"
              />
              <path
                d="M454.78,421.99c-0.84,0.61-1.3,1.15-1.87,1.3c-0.77,0.2-1.54-0.64-1.51-1.43c0.03-0.79,0.87-1.6,1.6-1.34
	C453.56,420.72,453.98,421.31,454.78,421.99z"
              />
              <path
                d="M425.3,622.96c-0.84,0.63-1.31,1.23-1.89,1.36c-0.8,0.19-1.33-0.39-1.43-1.23c-0.1-0.85,0.63-1.71,1.41-1.49
	C423.98,621.76,424.44,622.33,425.3,622.96z"
              />
              <path
                d="M334.99,599.89c0.55,0.92,1.07,1.44,1.15,2.02c0.11,0.81-0.48,1.31-1.32,1.34c-0.8,0.03-1.67-0.86-1.39-1.55
	C333.66,601.13,334.26,600.71,334.99,599.89z"
              />
              <path
                d="M516.16,254.04c-1.08,0.64-1.65,1.22-2.28,1.29c-0.8,0.09-1.25-0.59-1.22-1.42c0.03-0.82,0.52-1.48,1.32-1.33
	C514.6,252.71,515.13,253.33,516.16,254.04z"
              />
              <path
                d="M373.43,419.39c0.68,0.98,1.32,1.52,1.41,2.14c0.11,0.75-0.51,1.28-1.35,1.32c-0.86,0.04-1.61-0.65-1.4-1.49
	C372.24,420.79,372.78,420.32,373.43,419.39z"
              />
              <path
                d="M524.6,630.14c-0.21-0.31-0.74-0.79-0.88-1.36c-0.2-0.83,0.63-1.68,1.4-1.43c0.55,0.18,1.16,0.78,1.36,1.33
	C526.74,629.39,525.85,630.17,524.6,630.14z"
              />
              <path
                d="M338.17,596.04c0.64,0.88,1.2,1.34,1.34,1.91c0.19,0.8-0.37,1.35-1.2,1.47c-0.79,0.11-1.72-0.69-1.51-1.43
	C336.96,597.4,337.51,596.93,338.17,596.04z"
              />
              <path
                d="M363.45,355.21c-0.23-0.3-0.79-0.75-0.96-1.31c-0.24-0.81,0.51-1.57,1.35-1.55c0.84,0.02,1.31,0.52,1.45,1.3
	C365.43,354.42,364.69,355.18,363.45,355.21z"
              />
              <path
                d="M316.57,618.35c-0.86,0.56-1.35,1.07-1.92,1.19c-0.76,0.16-1.5-0.72-1.43-1.49c0.07-0.77,0.96-1.55,1.66-1.25
	C315.42,617.03,315.81,617.62,316.57,618.35z"
              />
              <path
                d="M336.23,554.85c-0.81,0.63-1.25,1.18-1.81,1.35c-0.73,0.22-1.53-0.61-1.52-1.39c0.01-0.77,0.83-1.61,1.55-1.37
	C335,553.64,335.43,554.2,336.23,554.85z"
              />
              <path
                d="M368.14,275.5c0.67,0.88,1.24,1.34,1.41,1.92c0.22,0.73-0.69,1.55-1.49,1.45c-0.83-0.1-1.39-0.64-1.22-1.44
	C366.97,276.87,367.52,276.39,368.14,275.5z"
              />
              <path
                d="M305.99,203.66c-1.08,0.64-1.65,1.22-2.28,1.3c-0.8,0.09-1.25-0.59-1.22-1.41c0.03-0.82,0.52-1.48,1.31-1.33
	C304.42,202.33,304.96,202.94,305.99,203.66z"
              />
              <path
                d="M470.47,205.03c-1.23-0.08-2.06-0.95-1.74-1.61c0.26-0.53,0.91-1.07,1.48-1.21c0.77-0.19,1.55,0.77,1.28,1.56
	C471.3,204.32,470.71,204.74,470.47,205.03z"
              />
              <path
                d="M258.79,204.13c-0.87,0.6-1.35,1.15-1.94,1.3c-0.8,0.2-1.5-0.67-1.38-1.52c0.12-0.82,0.66-1.4,1.46-1.2
	C257.51,202.85,257.96,203.46,258.79,204.13z"
              />
              <path
                d="M472.01,243.55c-1.1,0.61-1.69,1.17-2.33,1.22c-0.81,0.07-1.23-0.63-1.18-1.45c0.05-0.83,0.57-1.46,1.36-1.28
	C470.49,242.17,471,242.81,472.01,243.55z"
              />
              <path
                d="M349.63,206.97c-1.05,0.66-1.6,1.25-2.23,1.34c-0.79,0.11-1.26-0.55-1.24-1.38c0.01-0.82,0.5-1.48,1.28-1.35
	C348.06,205.67,348.6,206.29,349.63,206.97z"
              />
              <path
                d="M310.18,605.6c0.63,0.98,1.29,1.57,1.28,2.14c-0.01,0.43-0.81,1.07-1.37,1.22c-0.77,0.21-1.55-0.69-1.31-1.48
	C308.94,606.93,309.49,606.49,310.18,605.6z"
              />
              <path
                d="M450.93,418.7c-0.85,0.57-1.33,1.1-1.9,1.23c-0.76,0.18-1.51-0.69-1.46-1.47c0.06-0.78,0.92-1.57,1.63-1.29
	C449.75,417.39,450.15,417.99,450.93,418.7z"
              />
              <path
                d="M378.06,434.01c-0.69-0.96-1.38-1.51-1.39-2.08c-0.01-0.44,0.75-1.12,1.3-1.3c0.75-0.25,1.58,0.6,1.38,1.41
	C379.21,432.61,378.68,433.08,378.06,434.01z"
              />
              <path
                d="M368.86,96.99c-0.78,0.67-1.2,1.25-1.75,1.44c-0.72,0.26-1.55-0.55-1.58-1.32c-0.03-0.77,0.75-1.63,1.49-1.43
	C367.58,95.84,368.04,96.38,368.86,96.99z"
              />
              <path
                d="M335.57,587.55c-0.88,0.59-1.37,1.14-1.96,1.27c-0.8,0.18-1.49-0.69-1.36-1.54c0.13-0.82,0.69-1.39,1.48-1.17
	C334.3,586.26,334.75,586.87,335.57,587.55z"
              />
              <path
                d="M311.52,630.61c-0.21-0.32-0.73-0.81-0.85-1.38c-0.18-0.84,0.66-1.67,1.43-1.4c0.55,0.19,1.14,0.81,1.33,1.36
	C313.67,629.91,312.77,630.67,311.52,630.61z"
              />
              <path
                d="M370.86,434.5c-0.69-0.97-1.39-1.52-1.4-2.1c-0.01-0.44,0.75-1.12,1.3-1.31c0.76-0.26,1.59,0.59,1.39,1.42
	C372.02,433.09,371.49,433.56,370.86,434.5z"
              />
              <path
                d="M307.44,597.2c-0.85,0.57-1.32,1.09-1.89,1.23c-0.75,0.18-1.5-0.69-1.45-1.46c0.06-0.77,0.93-1.56,1.62-1.28
	C306.28,595.9,306.67,596.49,307.44,597.2z"
              />
              <path
                d="M409.03,622.39c0.63,0.99,1.25,1.55,1.31,2.17c0.07,0.73-0.56,1.26-1.4,1.27c-0.84,0.01-1.57-0.73-1.33-1.54
	C407.78,623.72,408.34,623.27,409.03,622.39z"
              />
              <path
                d="M246,113.6c1.34,0.92,1.56,1.41,0.91,2.16c-0.66,0.77-1.4,1.04-2.19-0.12c0.18-0.36,0.35-0.78,0.59-1.16
	C245.51,114.16,245.78,113.88,246,113.6z"
              />
              <path
                d="M291.17,616.68c-0.85,0.67-1.29,1.27-1.85,1.41c-0.81,0.2-1.34-0.42-1.5-1.2c-0.15-0.74,0.69-1.71,1.43-1.52
	C289.82,615.51,290.29,616.05,291.17,616.68z"
              />
              <path
                d="M493.59,241.56c-1.06,0.64-1.63,1.22-2.26,1.29c-0.79,0.09-1.24-0.58-1.22-1.41c0.03-0.82,0.52-1.47,1.3-1.32
	C492.04,240.24,492.57,240.86,493.59,241.56z"
              />
              <path
                d="M335.97,618.28c-0.64-0.94-1.16-1.41-1.3-1.98c-0.2-0.82,0.63-1.68,1.39-1.42c0.55,0.18,1.32,0.87,1.31,1.31
	C337.36,616.76,336.66,617.31,335.97,618.28z"
              />
              <path
                d="M474.04,577.36c0.29,0.36,1,0.88,1.05,1.46c0.04,0.43-0.7,1.17-1.24,1.35c-0.76,0.25-1.33-0.3-1.48-1.12
	C472.21,578.22,472.8,577.46,474.04,577.36z"
              />
              <path
                d="M314.01,612.99c-0.69-0.89-1.24-1.34-1.41-1.9c-0.24-0.8,0.56-1.71,1.32-1.49c0.56,0.16,1.36,0.8,1.37,1.24
	C315.31,611.41,314.65,612,314.01,612.99z"
              />
              <path
                d="M339.28,317.74c0.56,0.92,1.09,1.43,1.18,2.01c0.12,0.81-0.45,1.32-1.3,1.36c-0.8,0.04-1.68-0.84-1.41-1.53
	C337.97,319,338.56,318.58,339.28,317.74z"
              />
              <path
                d="M347.31,634.13c-0.43-1.11-0.28-1.89,0.63-2.14c0.49-0.14,1.2,0.09,1.65,0.39c0.27,0.19,0.37,0.85,0.3,1.26
	c-0.05,0.25-0.56,0.53-0.9,0.58C348.47,634.28,347.93,634.17,347.31,634.13z"
              />
              <path
                d="M344.82,215.95c0.69,0.89,1.26,1.34,1.43,1.9c0.25,0.82-0.49,1.56-1.34,1.55c-0.85-0.01-1.48-0.53-1.41-1.27
	C343.57,217.51,344.19,216.95,344.82,215.95z"
              />
              <path
                d="M432.14,615.17c-0.99,0.72-1.49,1.35-2.11,1.48c-0.78,0.17-1.29-0.45-1.34-1.27c-0.05-0.82,0.39-1.5,1.17-1.44
	C430.49,613.98,431.07,614.56,432.14,615.17z"
              />
              <path
                d="M402.62,337.42c0.56,0.92,1.09,1.43,1.18,2.01c0.12,0.81-0.45,1.32-1.3,1.36c-0.8,0.04-1.68-0.84-1.41-1.53
	C401.31,338.68,401.9,338.25,402.62,337.42z"
              />
              <path
                d="M517.4,630.62c-0.21-0.31-0.74-0.79-0.88-1.36c-0.2-0.83,0.63-1.68,1.4-1.43c0.55,0.18,1.16,0.78,1.36,1.33
	C519.54,629.87,518.65,630.65,517.4,630.62z"
              />
              <path
                d="M409.8,340.94c-0.73-0.94-1.45-1.47-1.49-2.05c-0.03-0.44,0.71-1.16,1.26-1.36c0.77-0.29,1.62,0.53,1.46,1.37
	C410.91,339.48,410.4,339.97,409.8,340.94z"
              />
              <path
                d="M489.34,238.25c-0.89,0.59-1.38,1.15-1.95,1.24c-0.81,0.13-1.3-0.51-1.39-1.31c-0.09-0.77,0.8-1.67,1.52-1.42
	C488.08,236.96,488.5,237.54,489.34,238.25z"
              />
              <path
                d="M369.34,270.26c-0.8,0.65-1.22,1.21-1.78,1.4c-0.73,0.24-1.54-0.58-1.55-1.36c-0.01-0.77,0.79-1.62,1.52-1.39
	C368.09,269.08,368.53,269.63,369.34,270.26z"
              />
              <path
                d="M479.52,582.23c-0.86,0.61-1.34,1.16-1.92,1.31c-0.8,0.2-1.51-0.66-1.39-1.51c0.12-0.82,0.65-1.4,1.45-1.21
	C478.23,580.96,478.69,581.57,479.52,582.23z"
              />
              <path
                d="M301.95,412.81c0.05,1.21-0.69,1.94-1.5,1.89c-0.85-0.05-1.3-0.55-1.33-1.37c-0.03-0.83,0.44-1.51,1.22-1.42
	C300.98,411.97,301.55,412.56,301.95,412.81z"
              />
              <path
                d="M473.65,410.76c0.7,0.96,1.35,1.47,1.47,2.08c0.14,0.74-0.47,1.29-1.3,1.36c-0.85,0.07-1.62-0.6-1.43-1.44
	C472.52,412.19,473.04,411.71,473.65,410.76z"
              />
              <path
                d="M435.11,611.63c-0.87,0.56-1.36,1.08-1.94,1.2c-0.78,0.16-1.51-0.72-1.44-1.5c0.07-0.78,0.96-1.55,1.67-1.26
	C433.94,610.3,434.34,610.9,435.11,611.63z"
              />
              <path
                d="M438.37,338.47c-0.7-0.84-1.3-1.27-1.46-1.83c-0.22-0.78,0.3-1.37,1.11-1.54c0.78-0.16,1.75,0.59,1.59,1.35
	C439.49,337.04,438.96,337.54,438.37,338.47z"
              />
              <path
                d="M570.82,632.2c-1.07,0.6-1.65,1.17-2.27,1.22c-0.79,0.07-1.22-0.62-1.17-1.43c0.05-0.81,0.56-1.44,1.33-1.28
	C569.32,630.85,569.83,631.48,570.82,632.2z"
              />
              <path
                d="M241.09,394.97c0.72,0.84,1.3,1.26,1.49,1.81c0.28,0.78-0.48,1.72-1.24,1.55c-0.57-0.13-1.4-0.73-1.43-1.16
	C239.87,396.59,240.51,395.97,241.09,394.97z"
              />
              <path
                d="M389.02,270.34c-0.65-0.9-1.2-1.37-1.36-1.95c-0.2-0.73,0.75-1.55,1.52-1.43c0.82,0.13,1.38,0.67,1.19,1.48
	C390.23,269,389.66,269.47,389.02,270.34z"
              />
              <path
                d="M455.68,595.14c-1.04,0.67-1.59,1.27-2.22,1.36c-0.79,0.12-1.26-0.54-1.26-1.36c0-0.83,0.48-1.48,1.26-1.37
	C454.09,593.86,454.64,594.46,455.68,595.14z"
              />
              <path
                d="M429.29,333.5c-1,0.73-1.51,1.35-2.13,1.49c-0.78,0.17-1.3-0.45-1.35-1.27c-0.05-0.82,0.39-1.51,1.17-1.45
	C427.61,332.31,428.2,332.89,429.29,333.5z"
              />
              <path
                d="M422.09,168.06c-1.03,0.68-1.58,1.28-2.2,1.38c-0.79,0.13-1.27-0.53-1.27-1.35c-0.01-0.83,0.47-1.49,1.25-1.38
	C420.49,166.79,421.04,167.4,422.09,168.06z"
              />
              <path
                d="M410.31,264.43c0.7,0.95,1.39,1.49,1.42,2.06c0.02,0.43-0.73,1.13-1.28,1.31c-0.77,0.26-1.59-0.55-1.4-1.38
	C409.18,265.85,409.7,265.36,410.31,264.43z"
              />
              <path
                d="M372.73,266.31c-0.78,0.71-1.19,1.3-1.74,1.51c-0.74,0.28-1.59-0.49-1.64-1.28c-0.05-0.78,0.7-1.66,1.47-1.48
	C371.39,265.19,371.87,265.72,372.73,266.31z"
              />
              <path
                d="M290.84,264.51c1.22,0.09,1.82,0.85,1.67,1.68c-0.15,0.81-0.71,1.37-1.47,1.12c-0.53-0.18-1.27-0.91-1.24-1.34
	C289.86,265.4,290.56,264.88,290.84,264.51z"
              />
              <path
                d="M480.8,576.31c0.73,0.93,1.4,1.43,1.53,2.04c0.16,0.75-0.44,1.31-1.27,1.41c-0.85,0.1-1.65-0.56-1.49-1.4
	C479.69,577.77,480.2,577.27,480.8,576.31z"
              />
              <path
                d="M445.67,400.66c-0.78,0.71-1.19,1.31-1.74,1.51c-0.74,0.28-1.59-0.49-1.64-1.28c-0.05-0.78,0.7-1.66,1.47-1.48
	C444.32,399.55,444.81,400.08,445.67,400.66z"
              />
              <path
                d="M265.25,402.21c-0.85,0.66-1.31,1.26-1.87,1.39c-0.81,0.19-1.33-0.44-1.48-1.22c-0.15-0.75,0.7-1.7,1.44-1.5
	C263.91,401.02,264.38,401.58,265.25,402.21z"
              />
              <path
                d="M334.51,596.61c-0.73-0.82-1.33-1.24-1.56-1.8c-0.28-0.69,0.6-1.59,1.39-1.56c0.84,0.03,1.44,0.53,1.32,1.34
	C335.58,595.18,335.06,595.69,334.51,596.61z"
              />
              <path
                d="M330.98,410.75c-0.79,0.7-1.19,1.3-1.75,1.5c-0.75,0.28-1.59-0.49-1.64-1.29c-0.05-0.78,0.72-1.66,1.47-1.47
	C329.65,409.63,330.13,410.16,330.98,410.75z"
              />
              <path
                d="M337.7,403.2c-0.82,0.64-1.26,1.21-1.82,1.38c-0.76,0.23-1.56-0.58-1.56-1.38c0-0.79,0.81-1.62,1.55-1.39
	C336.44,401.99,336.88,402.55,337.7,403.2z"
              />
              <path
                d="M344.2,229.04c-0.86,0.62-1.33,1.18-1.91,1.33c-0.8,0.21-1.51-0.64-1.4-1.49c0.11-0.83,0.63-1.41,1.43-1.22
	C342.9,227.8,343.36,228.4,344.2,229.04z"
              />
              <path
                d="M361.26,326.94c-0.54-0.92-1.06-1.44-1.14-2.02c-0.11-0.81,0.49-1.31,1.33-1.33c0.79-0.03,1.66,0.88,1.38,1.57
	C362.6,325.71,362,326.13,361.26,326.94z"
              />
              <path
                d="M388.83,326.37c-0.83,0.65-1.28,1.26-1.85,1.4c-0.8,0.21-1.34-0.37-1.46-1.19c-0.12-0.84,0.6-1.72,1.38-1.52
	C387.48,325.21,387.96,325.76,388.83,326.37z"
              />
              <path
                d="M366.54,304.87c-0.54-0.92-1.06-1.44-1.14-2.02c-0.11-0.81,0.49-1.31,1.33-1.33c0.79-0.03,1.66,0.88,1.38,1.57
	C367.88,303.64,367.28,304.05,366.54,304.87z"
              />
              <path
                d="M382.59,88.32c-0.81,0.68-1.25,1.29-1.82,1.45c-0.79,0.22-1.35-0.33-1.49-1.16c-0.14-0.84,0.56-1.74,1.34-1.56
	C381.21,87.19,381.71,87.73,382.59,88.32z"
              />
              <path
                d="M356.42,578.28c-0.85,0.67-1.29,1.28-1.85,1.41c-0.81,0.2-1.33-0.43-1.49-1.21c-0.16-0.75,0.68-1.71,1.42-1.52
	C355.07,577.12,355.54,577.66,356.42,578.28z"
              />
              <path
                d="M502.2,618.23c-0.61-0.96-1.2-1.48-1.25-2.05c-0.07-0.77,0.54-1.35,1.39-1.3c0.83,0.04,1.42,0.61,1.24,1.42
	C503.45,616.87,502.87,617.33,502.2,618.23z"
              />
              <path
                d="M340.04,577.8c0.66,0.9,1.23,1.37,1.35,1.94c0.17,0.81-0.41,1.37-1.25,1.4c-0.86,0.04-1.45-0.55-1.37-1.31
	C338.83,579.26,339.42,578.75,340.04,577.8z"
              />
              <path
                d="M453.8,367.77c-0.87,0.6-1.34,1.16-1.9,1.26c-0.81,0.15-1.28-0.5-1.39-1.27c-0.1-0.75,0.77-1.66,1.48-1.42
	C452.55,366.52,452.97,367.09,453.8,367.77z"
              />
              <path
                d="M350.38,261.25c-0.93,0.58-1.45,1.17-2.01,1.2c-0.79,0.04-1.37-0.66-1.13-1.45c0.15-0.48,0.85-1.14,1.25-1.1
	C349.03,259.96,349.51,260.6,350.38,261.25z"
              />
              <path
                d="M481.65,583.59c0.62,0.86,1.17,1.33,1.3,1.89c0.18,0.79-0.38,1.32-1.19,1.44c-0.78,0.11-1.69-0.7-1.48-1.42
	C480.46,584.93,481.01,584.46,481.65,583.59z"
              />
              <path
                d="M501.52,627.36c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C500.25,626.09,500.7,626.71,501.52,627.36z"
              />
              <path
                d="M379.36,368.72c-0.61-0.96-1.2-1.48-1.25-2.05c-0.07-0.77,0.54-1.35,1.39-1.3c0.83,0.04,1.42,0.61,1.24,1.42
	C380.61,367.35,380.03,367.81,379.36,368.72z"
              />
              <path
                d="M456.71,609.63c-0.97,0.59-1.49,1.15-2.05,1.19c-0.81,0.05-1.36-0.68-1.15-1.44c0.13-0.47,0.85-1.13,1.26-1.1
	C455.3,608.3,455.79,608.94,456.71,609.63z"
              />
              <path
                d="M375.85,428.63c-0.83,0.63-1.3,1.22-1.87,1.35c-0.8,0.18-1.32-0.4-1.41-1.22c-0.1-0.84,0.64-1.7,1.4-1.48
	C374.55,427.44,375.01,428,375.85,428.63z"
              />
              <path
                d="M479.01,409.06c-0.91,0.58-1.42,1.16-2,1.22c-0.81,0.09-1.3-0.55-1.25-1.4c0.05-0.85,0.61-1.43,1.4-1.25
	C477.73,407.76,478.18,408.39,479.01,409.06z"
              />
              <path
                d="M309.02,591.24c0.57,0.95,1.11,1.47,1.18,2.05c0.1,0.82-0.52,1.33-1.37,1.28c-0.84-0.05-1.41-0.65-1.26-1.42
	C307.69,592.59,308.32,592.13,309.02,591.24z"
              />
              <path
                d="M516.65,261.24c-0.9,0.59-1.39,1.15-1.94,1.22c-0.8,0.1-1.3-0.55-1.27-1.34c0.03-0.79,0.57-1.41,1.35-1.26
	C515.34,259.97,515.79,260.57,516.65,261.24z"
              />
              <path
                d="M453.52,407.44c-0.82,0.65-1.26,1.26-1.81,1.37c-0.77,0.16-1.31-0.42-1.35-1.24c-0.04-0.84,0.48-1.44,1.24-1.36
	C452.15,406.28,452.65,406.86,453.52,407.44z"
              />
              <path
                d="M330.24,609.66c-0.87,0.56-1.36,1.09-1.94,1.21c-0.78,0.16-1.45-0.73-1.29-1.56c0.15-0.81,0.72-1.35,1.49-1.13
	C329.04,608.35,329.46,608.98,330.24,609.66z"
              />
              <path
                d="M475.11,591.27c0.54,0.91,1.06,1.43,1.14,2.01c0.11,0.81-0.48,1.3-1.32,1.33c-0.79,0.03-1.65-0.87-1.37-1.55
	C473.79,592.49,474.38,592.08,475.11,591.27z"
              />
              <path
                d="M452.86,606.27c-0.92,0.59-1.43,1.15-1.99,1.22c-0.81,0.1-1.32-0.57-1.28-1.37c0.04-0.79,0.6-1.43,1.39-1.27
	C451.54,604.96,451.99,605.58,452.86,606.27z"
              />
              <path
                d="M287.3,407.4c-0.85,0.69-1.29,1.31-1.83,1.44c-0.8,0.18-1.34-0.46-1.4-1.24c-0.06-0.8,0.46-1.46,1.25-1.39
	C285.87,406.26,286.38,406.82,287.3,407.4z"
              />
              <path
                d="M294.99,327.26c-0.82,0.7-1.24,1.29-1.79,1.49c-0.73,0.25-1.73-0.67-1.48-1.38c0.19-0.55,0.83-1.22,1.35-1.3
	C293.52,326,294.1,326.67,294.99,327.26z"
              />
              <path
                d="M352.88,182.93c0.6,0.93,1.18,1.44,1.24,2c0.08,0.75-0.51,1.33-1.35,1.29c-0.82-0.04-1.4-0.59-1.23-1.39
	C351.65,184.28,352.23,183.81,352.88,182.93z"
              />
              <path
                d="M441.85,254.98c-0.65-0.97-1.23-1.47-1.29-2.03c-0.09-0.79,0.58-1.41,1.37-1.23c0.47,0.11,1.13,0.8,1.13,1.23
	C443.05,253.48,442.46,254.01,441.85,254.98z"
              />
              <path
                d="M314.61,576.42c0.64,0.87,1.21,1.33,1.32,1.89c0.17,0.79-0.41,1.33-1.22,1.37c-0.83,0.04-1.41-0.53-1.33-1.28
	C313.44,577.83,314.01,577.33,314.61,576.42z"
              />
              <path
                d="M332.28,575.02c-0.56-1.01-1.12-1.56-1.13-2.12c-0.01-0.77,0.67-1.38,1.47-1.11c0.47,0.16,1.06,0.89,1.03,1.31
	C333.61,573.63,332.97,574.11,332.28,575.02z"
              />
              <path
                d="M368.53,282.73c0.69,0.87,1.32,1.33,1.43,1.89c0.16,0.77-0.41,1.36-1.24,1.41c-0.84,0.05-1.46-0.46-1.36-1.27
	C367.43,284.19,367.97,283.67,368.53,282.73z"
              />
              <path
                d="M469.91,407.48c0.62,0.97,1.2,1.48,1.25,2.04c0.06,0.78-0.59,1.41-1.39,1.21c-0.47-0.12-1.11-0.82-1.1-1.25
	C468.68,408.95,469.28,408.43,469.91,407.48z"
              />
              <path
                d="M592.03,630.06c-0.88,0.7-1.33,1.31-1.88,1.44c-0.82,0.18-1.37-0.47-1.42-1.26c-0.05-0.81,0.49-1.47,1.29-1.39
	C590.59,628.89,591.1,629.46,592.03,630.06z"
              />
              <path
                d="M420.86,260.19c0.67,0.87,1.29,1.33,1.39,1.89c0.15,0.76-0.43,1.35-1.25,1.39c-0.82,0.04-1.44-0.47-1.34-1.28
	C419.75,261.62,420.29,261.12,420.86,260.19z"
              />
              <path
                d="M503.58,632.61c-0.67-0.89-1.25-1.35-1.38-1.91c-0.19-0.81,0.41-1.37,1.22-1.42c0.84-0.05,1.45,0.52,1.38,1.29
	C504.75,631.14,504.18,631.65,503.58,632.61z"
              />
              <path
                d="M335.27,627.34c-0.89,0.6-1.38,1.17-1.93,1.24c-0.8,0.11-1.3-0.54-1.28-1.33c0.02-0.79,0.56-1.42,1.34-1.27
	C333.95,626.08,334.4,626.68,335.27,627.34z"
              />
              <path
                d="M354.05,367.27c-0.65-0.96-1.24-1.46-1.31-2.02c-0.09-0.79,0.57-1.42,1.36-1.24c0.47,0.1,1.14,0.79,1.14,1.21
	C355.25,365.75,354.66,366.29,354.05,367.27z"
              />
              <path
                d="M339.51,574.55c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.41,0.62,1.22,1.42
	C340.77,573.21,340.19,573.66,339.51,574.55z"
              />
              <path
                d="M489.34,404.24c-0.83,0.73-1.24,1.32-1.79,1.52c-0.73,0.26-1.6-0.57-1.56-1.36c0.04-0.83,0.56-1.41,1.35-1.3
	C487.91,403.17,488.42,403.69,489.34,404.24z"
              />
              <path
                d="M244.71,188.92c-0.68-0.8-1.27-1.22-1.46-1.77c-0.26-0.73,0.57-1.51,1.4-1.46c0.81,0.05,1.43,0.5,1.27,1.33
	C245.81,187.59,245.28,188.07,244.71,188.92z"
              />
              <path
                d="M358.07,180.98c-0.84,0.65-1.31,1.26-1.87,1.37c-0.79,0.16-1.33-0.43-1.36-1.27c-0.03-0.85,0.49-1.47,1.28-1.37
	C356.69,179.78,357.19,180.38,358.07,180.98z"
              />
              <path
                d="M334.1,612.79c-0.8,0.67-1.24,1.28-1.8,1.44c-0.78,0.22-1.34-0.33-1.48-1.15c-0.14-0.83,0.56-1.73,1.33-1.55
	C332.75,611.66,333.24,612.21,334.1,612.79z"
              />
              <path
                d="M499.65,605.62c-0.85,0.66-1.31,1.27-1.88,1.4c-0.8,0.17-1.34-0.42-1.38-1.27c-0.04-0.85,0.48-1.48,1.27-1.39
	C498.24,604.43,498.75,605.02,499.65,605.62z"
              />
              <path
                d="M459.78,605.77c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C458.5,604.49,458.95,605.12,459.78,605.77z"
              />
              <path
                d="M447.96,406.12c-0.69-0.83-1.29-1.26-1.45-1.82c-0.22-0.78,0.3-1.36,1.11-1.53c0.76-0.16,1.73,0.59,1.58,1.34
	C449.07,404.7,448.55,405.2,447.96,406.12z"
              />
              <path
                d="M407.73,628.17c-0.83,0.67-1.33,1.4-1.79,1.37c-0.52-0.03-1.2-0.64-1.46-1.16c-0.31-0.64,0.63-1.67,1.35-1.49
	C406.4,627.03,406.87,627.57,407.73,628.17z"
              />
              <path
                d="M308.07,584.08c0.69,0.86,1.32,1.32,1.44,1.87c0.16,0.76-0.4,1.37-1.23,1.42c-0.84,0.05-1.46-0.45-1.37-1.26
	C306.97,585.53,307.51,585.02,308.07,584.08z"
              />
              <path
                d="M345.66,369.69c-0.8,0.7-1.22,1.33-1.78,1.49c-0.75,0.22-1.37-0.23-1.53-1.08c-0.16-0.86,0.46-1.71,1.29-1.57
	C344.22,368.64,344.74,369.14,345.66,369.69z"
              />
              <path
                d="M445.38,187.83c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C444.11,186.56,444.56,187.18,445.38,187.83z"
              />
              <path
                d="M309.28,602.35c-0.58-0.94-1.13-1.45-1.18-2.01c-0.07-0.76,0.54-1.32,1.36-1.26c0.79,0.06,1.38,0.61,1.2,1.4
	C310.53,601.03,309.95,601.47,309.28,602.35z"
              />
              <path
                d="M482.03,594.7c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.41,0.62,1.22,1.42
	C483.29,593.36,482.7,593.82,482.03,594.7z"
              />
              <path
                d="M331.68,603.74c0.66,0.87,1.21,1.33,1.38,1.9c0.22,0.72-0.69,1.53-1.47,1.43c-0.81-0.11-1.37-0.63-1.2-1.43
	C330.52,605.08,331.07,604.61,331.68,603.74z"
              />
              <path
                d="M312.74,575.11c-0.96,0.57-1.49,1.13-2.04,1.16c-0.81,0.04-1.33-0.7-1.11-1.45c0.14-0.47,0.85-1.11,1.26-1.08
	C311.37,573.77,311.85,574.41,312.74,575.11z"
              />
              <path
                d="M361.54,287.12c-0.67-0.89-1.25-1.35-1.38-1.91c-0.19-0.81,0.41-1.37,1.22-1.42c0.84-0.05,1.45,0.52,1.38,1.29
	C362.72,285.65,362.14,286.17,361.54,287.12z"
              />
              <path
                d="M341.18,592.05c0.71,0.81,1.3,1.22,1.51,1.78c0.26,0.71-0.61,1.55-1.42,1.49c-0.81-0.06-1.44-0.52-1.29-1.35
	C340.08,593.4,340.61,592.91,341.18,592.05z"
              />
              <path
                d="M430.43,327.83c0.69,0.87,1.32,1.33,1.43,1.89c0.16,0.77-0.41,1.36-1.24,1.41c-0.84,0.05-1.46-0.46-1.36-1.27
	C429.33,329.29,429.87,328.77,430.43,327.83z"
              />
              <path
                d="M511.7,369.98c-0.92,0.58-1.43,1.11-2.01,1.22c-0.8,0.16-1.45-0.71-1.32-1.55c0.14-0.86,0.74-1.31,1.51-1.1
	C510.43,368.71,510.87,369.3,511.7,369.98z"
              />
              <path
                d="M378,370.73c-0.98,0.55-1.52,1.11-2.07,1.12c-0.8,0.02-1.34-0.71-1.09-1.48c0.15-0.47,0.88-1.1,1.29-1.06
	C376.65,369.36,377.12,370.02,378,370.73z"
              />
              <path
                d="M330.32,629.2c0.6,0.92,1.17,1.42,1.22,1.98c0.08,0.75-0.5,1.32-1.34,1.28c-0.81-0.04-1.39-0.58-1.22-1.37
	C329.11,630.53,329.68,630.07,330.32,629.2z"
              />
              <path
                d="M469.67,593.81c-0.85,0.7-1.27,1.28-1.82,1.46c-0.74,0.24-1.58-0.6-1.52-1.37c0.07-0.81,0.57-1.39,1.37-1.26
	C468.26,592.73,468.76,593.25,469.67,593.81z"
              />
              <path
                d="M475.58,602.35c-0.59-0.92-1.12-1.42-1.2-1.98c-0.12-0.8,0.5-1.31,1.3-1.29c0.81,0.02,1.39,0.6,1.25,1.36
	C476.84,600.99,476.25,601.46,475.58,602.35z"
              />
              <path
                d="M500.34,612.98c-0.9,0.59-1.4,1.14-1.95,1.21c-0.8,0.1-1.29-0.56-1.26-1.35c0.03-0.79,0.58-1.41,1.36-1.25
	C499.03,611.69,499.48,612.3,500.34,612.98z"
              />
              <path
                d="M335.63,571.16c-0.66-0.87-1.23-1.32-1.36-1.87c-0.18-0.8,0.42-1.34,1.2-1.39c0.82-0.05,1.42,0.51,1.35,1.27
	C336.77,569.71,336.22,570.22,335.63,571.16z"
              />
              <path
                d="M316.98,482.4c-0.6-0.94-1.17-1.46-1.22-2.02c-0.07-0.76,0.53-1.33,1.36-1.28c0.8,0.05,1.4,0.6,1.22,1.4
	C318.22,481.06,317.65,481.52,316.98,482.4z"
              />
              <path
                d="M463.99,588.69c0.58,0.86,1.12,1.34,1.23,1.9c0.16,0.83-0.47,1.29-1.27,1.37c-0.8,0.07-1.69-0.77-1.44-1.48
	C462.7,589.92,463.29,589.5,463.99,588.69z"
              />
              <path
                d="M257.26,435.44c-0.87,0.57-1.37,1.15-1.93,1.21c-0.77,0.08-1.28-0.53-1.23-1.36c0.04-0.82,0.6-1.4,1.36-1.24
	C256.01,434.16,256.45,434.79,257.26,435.44z"
              />
              <path
                d="M316.22,590.82c0.56,0.92,1.09,1.43,1.15,2c0.09,0.79-0.53,1.3-1.34,1.25c-0.82-0.05-1.37-0.65-1.21-1.4
	C314.93,592.12,315.55,591.67,316.22,590.82z"
              />
              <path
                d="M305.1,362.58c-0.88,0.69-1.32,1.26-1.88,1.42c-0.75,0.22-1.59-0.65-1.5-1.43c0.1-0.81,0.63-1.39,1.42-1.24
	C303.71,361.45,304.2,361.98,305.1,362.58z"
              />
              <path
                d="M351.79,401.86c-0.88,0.57-1.38,1.14-1.94,1.2c-0.77,0.08-1.27-0.54-1.22-1.37c0.05-0.83,0.62-1.38,1.37-1.22
	C350.54,400.58,350.98,401.2,351.79,401.86z"
              />
              <path
                d="M498.17,631.25c-0.94,0.56-1.48,1.14-2.04,1.16c-0.78,0.02-1.36-0.69-1.09-1.48c0.16-0.47,0.88-1.12,1.27-1.07
	C496.84,629.92,497.31,630.57,498.17,631.25z"
              />
              <path
                d="M480.19,589.49c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C478.95,588.21,479.37,588.79,480.19,589.49z"
              />
              <path
                d="M363,301.54c-0.68-0.9-1.27-1.35-1.4-1.91c-0.19-0.81,0.4-1.38,1.22-1.44c0.85-0.06,1.46,0.52,1.4,1.29
	C364.18,300.04,363.6,300.57,363,301.54z"
              />
              <path
                d="M359.03,401.24c-0.85,0.64-1.32,1.25-1.88,1.36c-0.8,0.15-1.33-0.44-1.35-1.28c-0.02-0.85,0.5-1.46,1.29-1.36
	C357.66,400.04,358.16,400.63,359.03,401.24z"
              />
              <path
                d="M492.71,400.65c-0.91,0.61-1.4,1.14-1.96,1.26c-0.78,0.17-1.54-0.74-1.39-1.51c0.15-0.81,0.74-1.34,1.5-1.15
	C491.41,399.38,491.85,399.97,492.71,400.65z"
              />
              <path
                d="M344.63,568.85c-0.9,0.57-1.42,1.15-1.98,1.21c-0.8,0.08-1.29-0.56-1.24-1.4c0.05-0.84,0.61-1.42,1.4-1.24
	C343.37,567.55,343.81,568.18,344.63,568.85z"
              />
              <path
                d="M265.46,362.36c-0.84,0.65-1.31,1.26-1.87,1.37c-0.79,0.16-1.33-0.43-1.36-1.27c-0.03-0.85,0.49-1.47,1.28-1.37
	C264.08,361.16,264.58,361.76,265.46,362.36z"
              />
              <path
                d="M500.85,620.16c-0.92,0.61-1.41,1.17-1.97,1.25c-0.81,0.11-1.33-0.56-1.3-1.36c0.03-0.8,0.58-1.44,1.38-1.29
	C499.51,618.87,499.97,619.48,500.85,620.16z"
              />
              <path
                d="M460.66,592.54c0.7,0.8,1.29,1.21,1.49,1.76c0.25,0.71-0.61,1.53-1.41,1.48c-0.81-0.06-1.43-0.51-1.28-1.33
	C459.57,593.89,460.1,593.4,460.66,592.54z"
              />
              <path
                d="M291,181.25c-0.65-0.8-1.24-1.23-1.39-1.78c-0.22-0.8,0.33-1.33,1.14-1.45c0.81-0.12,1.72,0.59,1.54,1.33
	C292.14,179.92,291.6,180.39,291,181.25z"
              />
              <path
                d="M320.45,621.45c-0.93,0.57-1.43,1.08-2,1.18c-0.79,0.14-1.51-0.77-1.34-1.55c0.17-0.82,0.79-1.31,1.54-1.11
	C319.19,620.12,319.61,620.73,320.45,621.45z"
              />
              <path
                d="M490.57,584.94c-0.92,0.58-1.41,1.09-1.99,1.21c-0.81,0.16-1.45-0.69-1.31-1.53c0.14-0.85,0.74-1.3,1.5-1.09
	C489.31,583.69,489.74,584.27,490.57,584.94z"
              />
              <path
                d="M506.94,628.47c-0.66-0.8-1.25-1.23-1.41-1.78c-0.23-0.8,0.32-1.34,1.14-1.47c0.82-0.13,1.74,0.59,1.56,1.33
	C508.09,627.13,507.54,627.61,506.94,628.47z"
              />
              <path
                d="M362.04,373.94c-0.67-0.87-1.25-1.31-1.38-1.86c-0.19-0.79,0.41-1.34,1.19-1.4c0.82-0.06,1.43,0.5,1.36,1.26
	C363.17,372.49,362.61,373,362.04,373.94z"
              />
              <path
                d="M334.81,619.92c-0.8,0.72-1.2,1.32-1.76,1.53c-0.72,0.27-1.74-0.62-1.51-1.34c0.18-0.55,0.8-1.24,1.32-1.33
	C333.32,618.69,333.91,619.35,334.81,619.92z"
              />
              <path
                d="M359.27,361.33c-0.85,0.69-1.29,1.31-1.83,1.44c-0.8,0.18-1.34-0.46-1.4-1.23c-0.06-0.8,0.46-1.46,1.25-1.39
	C357.84,360.19,358.35,360.75,359.27,361.33z"
              />
              <path
                d="M597.39,631.63c-0.69-0.85-1.26-1.29-1.46-1.86c-0.24-0.69,0.66-1.56,1.42-1.48c0.81,0.08,1.39,0.59,1.24,1.39
	C598.5,630.25,597.97,630.74,597.39,631.63z"
              />
              <path
                d="M307.82,374.92c-0.68-0.88-1.26-1.32-1.4-1.88c-0.19-0.8,0.4-1.36,1.2-1.42c0.83-0.07,1.44,0.5,1.38,1.26
	C308.97,373.44,308.41,373.97,307.82,374.92z"
              />
              <path
                d="M486.7,581.75c-0.85,0.61-1.33,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.4,1.44-1.2
	C485.42,580.49,485.87,581.1,486.7,581.75z"
              />
              <path
                d="M434.28,331.2c0.7,0.84,1.27,1.27,1.47,1.84c0.25,0.7-0.63,1.55-1.41,1.49c-0.83-0.06-1.4-0.57-1.26-1.37
	C433.18,332.59,433.71,332.09,434.28,331.2z"
              />
              <path
                d="M427.12,628.28c-0.59-0.93-1.13-1.43-1.21-2c-0.12-0.8,0.5-1.32,1.32-1.3c0.82,0.02,1.4,0.61,1.27,1.37
	C428.39,626.91,427.79,627.38,427.12,628.28z"
              />
              <path
                d="M521.3,251.38c0.62,0.85,1.17,1.31,1.31,1.87c0.19,0.74-0.73,1.49-1.52,1.37c-0.8-0.13-1.38-0.65-1.16-1.45
	C520.07,252.62,520.65,252.19,521.3,251.38z"
              />
              <path
                d="M325.19,425.82c-0.9,0.6-1.38,1.16-1.93,1.23c-0.8,0.1-1.3-0.55-1.27-1.34c0.02-0.79,0.56-1.42,1.35-1.27
	C323.88,424.55,324.33,425.15,325.19,425.82z"
              />
              <path
                d="M448.28,409.39c0.66,0.88,1.22,1.34,1.39,1.92c0.22,0.73-0.7,1.54-1.48,1.44c-0.82-0.11-1.38-0.64-1.2-1.44
	C447.11,410.74,447.67,410.27,448.28,409.39z"
              />
              <path
                d="M342.67,234.25c0.68,0.87,1.25,1.31,1.44,1.89c0.24,0.72-0.66,1.55-1.45,1.47c-0.83-0.08-1.39-0.61-1.24-1.41
	C341.53,235.63,342.08,235.14,342.67,234.25z"
              />
              <path
                d="M508.06,619.24c-0.85,0.71-1.27,1.29-1.83,1.48c-0.74,0.25-1.59-0.6-1.53-1.39c0.07-0.82,0.58-1.4,1.38-1.27
	C506.65,618.15,507.15,618.67,508.06,619.24z"
              />
              <path
                d="M480.99,224.54c-0.91,0.61-1.4,1.14-1.98,1.27c-0.8,0.18-1.47-0.67-1.36-1.52c0.12-0.86,0.71-1.33,1.48-1.13
	C479.69,223.3,480.14,223.88,480.99,224.54z"
              />
              <path
                d="M338.99,567.33c-0.67-0.88-1.24-1.34-1.37-1.89c-0.18-0.8,0.41-1.35,1.21-1.41c0.83-0.05,1.44,0.52,1.37,1.28
	C340.15,565.87,339.58,566.39,338.99,567.33z"
              />
              <path
                d="M356.27,309.38c-0.66-0.8-1.25-1.23-1.41-1.78c-0.23-0.8,0.32-1.34,1.14-1.47c0.82-0.13,1.74,0.59,1.56,1.33
	C357.42,308.03,356.87,308.51,356.27,309.38z"
              />
              <path
                d="M451.27,362.47c-0.68-0.9-1.26-1.36-1.39-1.92c-0.19-0.81,0.4-1.38,1.23-1.43c0.85-0.05,1.46,0.53,1.4,1.3
	C452.46,360.99,451.88,361.51,451.27,362.47z"
              />
              <path
                d="M260.21,178.73c-0.87,0.58-1.36,1.12-1.94,1.26c-0.79,0.18-1.48-0.7-1.34-1.54c0.14-0.81,0.69-1.38,1.47-1.16
	C258.97,177.44,259.41,178.06,260.21,178.73z"
              />
              <path
                d="M420.07,336.25c-0.65-0.81-1.24-1.24-1.4-1.79c-0.23-0.8,0.33-1.34,1.15-1.46c0.82-0.13,1.73,0.6,1.55,1.34
	C421.23,334.91,420.68,335.39,420.07,336.25z"
              />
              <path
                d="M332.11,425.38c-0.88,0.57-1.38,1.14-1.94,1.2c-0.77,0.08-1.27-0.54-1.22-1.37c0.05-0.83,0.62-1.38,1.37-1.22
	C330.87,424.09,331.31,424.72,332.11,425.38z"
              />
              <path
                d="M254.38,193.6c-0.87,0.57-1.38,1.15-1.93,1.21c-0.77,0.08-1.27-0.53-1.23-1.37c0.04-0.83,0.61-1.39,1.36-1.23
	C253.13,192.33,253.57,192.95,254.38,193.6z"
              />
              <path
                d="M233.22,222.31c0.67,0.85,1.28,1.3,1.4,1.85c0.16,0.75-0.39,1.35-1.22,1.39c-0.82,0.04-1.43-0.46-1.34-1.25
	C232.13,223.74,232.66,223.23,233.22,222.31z"
              />
              <path
                d="M260.88,371.51c0.64,0.89,1.21,1.36,1.32,1.92c0.16,0.8-0.42,1.34-1.24,1.37c-0.84,0.03-1.42-0.55-1.34-1.3
	C259.69,372.93,260.27,372.43,260.88,371.51z"
              />
              <path
                d="M491.26,591.89c-0.85,0.7-1.27,1.28-1.82,1.46c-0.74,0.24-1.58-0.6-1.52-1.37c0.07-0.81,0.57-1.39,1.37-1.26
	C489.85,590.81,490.35,591.33,491.26,591.89z"
              />
              <path
                d="M412.7,333.09c0.69,0.89,1.32,1.36,1.43,1.92c0.15,0.77-0.43,1.37-1.27,1.41c-0.85,0.04-1.46-0.47-1.36-1.29
	C411.58,334.55,412.11,334.04,412.7,333.09z"
              />
              <path
                d="M319.75,614.1c-0.89,0.63-1.36,1.17-1.93,1.32c-0.8,0.2-1.49-0.61-1.4-1.45c0.1-0.84,0.65-1.35,1.43-1.17
	C318.42,612.93,318.89,613.48,319.75,614.1z"
              />
              <path
                d="M447.09,398.91c-0.64-0.88-1.19-1.35-1.35-1.92c-0.2-0.72,0.73-1.55,1.49-1.42c0.79,0.13,1.37,0.66,1.18,1.45
	C448.28,397.58,447.72,398.04,447.09,398.91z"
              />
              <path
                d="M407.47,335.47c-0.82,0.65-1.28,1.27-1.83,1.38c-0.78,0.17-1.32-0.42-1.36-1.25c-0.04-0.84,0.48-1.46,1.25-1.37
	C406.09,334.29,406.6,334.88,407.47,335.47z"
              />
              <path
                d="M348.08,564.61c-0.87,0.66-1.32,1.23-1.89,1.4c-0.79,0.23-1.52-0.56-1.45-1.42c0.06-0.86,0.6-1.39,1.4-1.23
	C346.7,563.47,347.19,564.02,348.08,564.61z"
              />
              <path
                d="M245.54,259.15c-0.85,0.68-1.29,1.29-1.83,1.41c-0.79,0.17-1.33-0.46-1.38-1.24c-0.05-0.8,0.47-1.45,1.25-1.37
	C244.13,258,244.63,258.57,245.54,259.15z"
              />
              <path
                d="M494.26,394.53c0.56,0.91,1.09,1.41,1.18,1.99c0.13,0.8-0.45,1.31-1.29,1.36c-0.79,0.04-1.66-0.82-1.4-1.52
	C492.97,395.79,493.55,395.36,494.26,394.53z"
              />
              <path
                d="M265.42,322.68c-0.87,0.57-1.38,1.15-1.93,1.21c-0.77,0.08-1.27-0.53-1.23-1.37c0.04-0.83,0.61-1.39,1.36-1.23
	C264.17,321.4,264.61,322.03,265.42,322.68z"
              />
              <path
                d="M327.06,593.5c0.66,0.8,1.25,1.23,1.4,1.78c0.21,0.79-0.35,1.33-1.15,1.46c-0.79,0.13-1.71-0.61-1.53-1.35
	C325.91,594.82,326.45,594.36,327.06,593.5z"
              />
              <path
                d="M330.24,589.33c0.66,0.88,1.22,1.34,1.39,1.92c0.22,0.73-0.7,1.54-1.48,1.44c-0.82-0.11-1.38-0.64-1.2-1.44
	C329.07,590.68,329.62,590.21,330.24,589.33z"
              />
              <path
                d="M404.39,632.24c-0.98,0.55-1.52,1.11-2.07,1.12c-0.8,0.02-1.34-0.71-1.09-1.48c0.15-0.47,0.89-1.1,1.29-1.06
	C403.05,630.88,403.51,631.53,404.39,632.24z"
              />
              <path
                d="M322.32,476.55c-0.83,0.67-1.33,1.4-1.79,1.37c-0.52-0.03-1.2-0.64-1.46-1.16c-0.31-0.64,0.63-1.67,1.35-1.49
	C320.99,475.4,321.46,475.94,322.32,476.55z"
              />
              <path
                d="M447.12,249.06c-0.88,0.7-1.32,1.31-1.88,1.44c-0.82,0.18-1.37-0.47-1.42-1.26c-0.05-0.81,0.49-1.47,1.29-1.4
	C445.67,247.9,446.18,248.47,447.12,249.06z"
              />
              <path
                d="M252.74,424.74c-0.83,0.66-1.34,1.38-1.8,1.36c-0.52-0.03-1.2-0.64-1.45-1.17c-0.3-0.64,0.64-1.67,1.36-1.48
	C251.42,423.58,251.88,424.12,252.74,424.74z"
              />
              <path
                d="M460.35,193.64c-0.83,0.68-1.27,1.26-1.84,1.45c-0.79,0.26-1.53-0.51-1.5-1.37c0.03-0.87,0.56-1.41,1.35-1.28
	C458.94,192.53,459.44,193.07,460.35,193.64z"
              />
              <path
                d="M309.63,578.88c-0.92,0.6-1.44,1.19-2,1.24c-0.81,0.07-1.38-0.63-1.15-1.43c0.14-0.48,0.83-1.16,1.22-1.13
	C308.24,577.6,308.74,578.23,309.63,578.88z"
              />
              <path
                d="M364.74,279.37c0.68,0.86,1.24,1.3,1.43,1.87c0.23,0.71-0.66,1.54-1.44,1.46c-0.82-0.08-1.39-0.6-1.23-1.4
	C363.62,280.74,364.15,280.26,364.74,279.37z"
              />
              <path
                d="M313.42,582.28c-0.88,0.57-1.39,1.15-1.96,1.21c-0.78,0.08-1.28-0.55-1.23-1.38c0.05-0.83,0.61-1.41,1.38-1.24
	C312.17,580.99,312.61,581.62,313.42,582.28z"
              />
              <path
                d="M259.95,424.14c-0.8,0.72-1.2,1.32-1.76,1.53c-0.72,0.27-1.74-0.62-1.51-1.34c0.18-0.55,0.8-1.24,1.32-1.33
	C258.46,422.92,259.05,423.58,259.95,424.14z"
              />
              <path
                d="M262.58,413.41c-0.87,0.56-1.36,1.09-1.94,1.21c-0.78,0.16-1.45-0.73-1.29-1.56c0.15-0.81,0.72-1.35,1.49-1.13
	C261.39,412.1,261.81,412.73,262.58,413.41z"
              />
              <path
                d="M477.14,221.12c-0.89,0.63-1.36,1.17-1.93,1.31c-0.81,0.2-1.49-0.61-1.39-1.46c0.1-0.84,0.66-1.35,1.44-1.16
	C475.82,219.94,476.28,220.49,477.14,221.12z"
              />
              <path
                d="M459.32,598.54c-0.84,0.6-1.31,1.15-1.88,1.3c-0.78,0.2-1.49-0.66-1.37-1.49c0.12-0.81,0.65-1.39,1.43-1.19
	C458.06,597.29,458.51,597.89,459.32,598.54z"
              />
              <path
                d="M277.88,319.51c0.69,0.81,1.27,1.23,1.46,1.79c0.24,0.71-0.64,1.53-1.44,1.45c-0.8-0.07-1.42-0.54-1.25-1.36
	C276.76,320.84,277.3,320.36,277.88,319.51z"
              />
              <path
                d="M364.76,93.81c-0.89,0.57-1.4,1.15-1.96,1.2c-0.78,0.08-1.28-0.55-1.23-1.39c0.05-0.84,0.62-1.4,1.38-1.23
	C363.51,92.52,363.95,93.15,364.76,93.81z"
              />
              <path
                d="M308.19,604.34c-0.9,0.59-1.42,1.17-1.99,1.23c-0.81,0.1-1.3-0.54-1.26-1.39c0.04-0.85,0.6-1.44,1.39-1.26
	C306.89,603.04,307.35,603.67,308.19,604.34z"
              />
              <path
                d="M326.27,629.71c-0.68-0.89-1.28-1.35-1.39-1.91c-0.15-0.77,0.45-1.35,1.26-1.38c0.81-0.03,1.44,0.48,1.33,1.29
	C327.38,628.27,326.85,628.78,326.27,629.71z"
              />
              <path
                d="M378.58,314.66c-0.69-0.8-1.28-1.23-1.48-1.79c-0.26-0.74,0.57-1.53,1.41-1.47c0.82,0.05,1.44,0.51,1.28,1.34
	C379.7,313.31,379.16,313.8,378.58,314.66z"
              />
              <path
                d="M429.12,151.34c-0.63-0.96-1.23-1.49-1.24-2.02c-0.01-0.43,0.64-1.13,1.11-1.25c0.79-0.19,1.46,0.43,1.39,1.22
	C430.32,149.85,429.74,150.36,429.12,151.34z"
              />
              <path
                d="M466.52,415.22c-0.62-0.87-1.17-1.34-1.29-1.9c-0.18-0.8,0.4-1.32,1.2-1.44c0.76-0.11,1.69,0.73,1.47,1.44
	C467.73,413.89,467.18,414.35,466.52,415.22z"
              />
              <path
                d="M443.33,395.75c-0.67-0.82-1.26-1.25-1.44-1.82c-0.25-0.75,0.59-1.52,1.44-1.45c0.82,0.07,1.43,0.54,1.26,1.37
	C444.47,394.42,443.92,394.9,443.33,395.75z"
              />
              <path
                d="M493.1,593.49c0.65,0.82,1.24,1.25,1.39,1.8c0.21,0.8-0.38,1.33-1.17,1.47c-0.79,0.13-1.72-0.64-1.53-1.38
	C491.93,594.81,492.48,594.35,493.1,593.49z"
              />
              <path
                d="M521.68,634.53c-0.61-0.89-1.16-1.36-1.28-1.93c-0.17-0.8,0.41-1.33,1.22-1.43c0.77-0.1,1.69,0.74,1.47,1.46
	C522.91,633.2,522.35,633.65,521.68,634.53z"
              />
              <path
                d="M373.89,280.78c-0.83,0.64-1.29,1.25-1.85,1.36c-0.78,0.16-1.32-0.43-1.35-1.27c-0.03-0.84,0.49-1.45,1.27-1.35
	C372.52,279.6,373.01,280.19,373.89,280.78z"
              />
              <path
                d="M326.01,393.38c-0.9,0.63-1.37,1.18-1.95,1.33c-0.8,0.2-1.5-0.63-1.4-1.48c0.1-0.85,0.66-1.36,1.45-1.18
	C324.67,392.19,325.14,392.75,326.01,393.38z"
              />
              <path d="M294.5,603.29c0.56-1.21,0.97-2.1,1.38-2.99c1.19,0.22,1.47,0.91,1.25,1.76C296.86,603.11,296.12,603.56,294.5,603.29z" />
              <path
                d="M367.46,82.54c-0.87,0.71-1.31,1.33-1.86,1.46c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.27-1.41
	C366,81.39,366.52,81.95,367.46,82.54z"
              />
              <path
                d="M237.54,391.65c0.56,0.94,1.1,1.46,1.16,2.04c0.09,0.81-0.53,1.31-1.37,1.26c-0.84-0.05-1.4-0.66-1.24-1.42
	C236.21,392.97,236.84,392.52,237.54,391.65z"
              />
              <path
                d="M303.53,491.05c-0.69-0.83-1.29-1.26-1.45-1.82c-0.22-0.78,0.3-1.36,1.11-1.53c0.76-0.16,1.73,0.59,1.58,1.34
	C304.64,489.63,304.12,490.13,303.53,491.05z"
              />
              <path
                d="M362.42,357.69c-0.86,0.59-1.34,1.14-1.92,1.28c-0.79,0.19-1.49-0.68-1.35-1.52c0.13-0.82,0.67-1.38,1.46-1.18
	C361.16,356.41,361.6,357.03,362.42,357.69z"
              />
              <path
                d="M365.93,297.65c-0.59-0.94-1.15-1.44-1.21-2c-0.08-0.76,0.53-1.32,1.35-1.27c0.79,0.05,1.39,0.59,1.21,1.39
	C367.16,296.32,366.59,296.77,365.93,297.65z"
              />
              <path
                d="M234.03,375.73c-0.85,0.69-1.29,1.31-1.83,1.43c-0.8,0.18-1.34-0.46-1.4-1.24c-0.06-0.8,0.46-1.46,1.25-1.39
	C232.61,374.59,233.12,375.15,234.03,375.73z"
              />
              <path
                d="M497.06,394.58c-0.54-0.92-1.05-1.44-1.12-2.01c-0.1-0.81,0.5-1.29,1.33-1.32c0.78-0.03,1.64,0.88,1.36,1.56
	C498.39,393.37,497.79,393.78,497.06,394.58z"
              />
              <path
                d="M506.45,245.6c0.61,0.86,1.16,1.34,1.3,1.91c0.18,0.74-0.76,1.51-1.55,1.37c-0.8-0.14-1.38-0.68-1.16-1.48
	C505.19,246.84,505.79,246.41,506.45,245.6z"
              />
              <path
                d="M275.56,271.35c-0.91,0.58-1.43,1.16-2,1.22c-0.81,0.09-1.3-0.56-1.25-1.41c0.05-0.85,0.61-1.43,1.4-1.25
	C274.27,270.04,274.73,270.68,275.56,271.35z"
              />
              <path
                d="M336.94,561.96c-0.83,0.65-1.28,1.26-1.84,1.38c-0.78,0.16-1.32-0.42-1.36-1.25c-0.04-0.84,0.48-1.45,1.26-1.37
	C335.56,560.78,336.06,561.37,336.94,561.96z"
              />
              <path
                d="M444.04,339.78c-0.93,0.57-1.44,1.09-2.03,1.19c-0.8,0.15-1.44-0.73-1.29-1.57c0.15-0.86,0.77-1.3,1.52-1.08
	C442.79,338.49,443.22,339.09,444.04,339.78z"
              />
              <path
                d="M430.46,624.47c-0.59-0.95-1.13-1.46-1.21-2.04c-0.11-0.82,0.51-1.35,1.34-1.31c0.83,0.03,1.43,0.63,1.28,1.4
	C431.78,623.09,431.16,623.56,430.46,624.47z"
              />
              <path
                d="M367.45,288.61c-0.92,0.6-1.42,1.16-1.98,1.23c-0.8,0.09-1.32-0.57-1.29-1.37c0.03-0.8,0.59-1.43,1.39-1.27
	C366.12,287.3,366.58,287.92,367.45,288.61z"
              />
              <path
                d="M449.49,610.11c-0.91,0.59-1.41,1.15-1.97,1.21c-0.8,0.09-1.31-0.57-1.27-1.36c0.04-0.79,0.59-1.42,1.38-1.26
	C448.18,608.81,448.62,609.42,449.49,610.11z"
              />
              <path
                d="M440.69,196.83c0.61,0.94,1.2,1.45,1.25,2.02c0.08,0.76-0.51,1.34-1.36,1.3c-0.83-0.04-1.41-0.59-1.24-1.4
	C439.46,198.19,440.03,197.72,440.69,196.83z"
              />
              <path
                d="M307.27,197.77c0.63,0.95,1.22,1.46,1.29,2.03c0.09,0.77-0.51,1.35-1.37,1.32c-0.85-0.03-1.43-0.58-1.26-1.39
	C306.05,199.15,306.63,198.67,307.27,197.77z"
              />
              <path
                d="M471.27,216.02c0.68,0.87,1.25,1.31,1.44,1.89c0.24,0.72-0.66,1.55-1.45,1.47c-0.83-0.08-1.39-0.61-1.24-1.41
	C470.13,217.4,470.67,216.91,471.27,216.02z"
              />
              <path
                d="M310.8,633.04c-1.04,0.59-1.55,1.05-2.12,1.17c-0.78,0.16-1.4-0.22-1.4-1.15c-0.01-0.83,0.47-1.49,1.25-1.39
	C309.16,631.75,309.72,632.36,310.8,633.04z"
              />
              <path
                d="M303.14,217.96c0.84-0.62,1.31-1.18,1.89-1.34c0.77-0.21,1.5,0.66,1.39,1.49c-0.1,0.82-0.63,1.4-1.43,1.21
	C304.43,219.19,303.97,218.6,303.14,217.96z"
              />
              <path
                d="M344.9,199.64c0.91-0.58,1.43-1.17,2-1.23c0.79-0.08,1.3,0.56,1.25,1.42c-0.05,0.85-0.6,1.43-1.41,1.24
	C346.18,200.94,345.73,200.31,344.9,199.64z"
              />
              <path
                d="M248.26,414.6c-0.92,0.59-1.43,1.11-2.01,1.22c-0.8,0.16-1.45-0.71-1.32-1.55c0.14-0.86,0.74-1.31,1.51-1.1
	C247,413.33,247.43,413.92,248.26,414.6z"
              />
              <path
                d="M467.95,200.33c-0.9,0.58-1.41,1.15-1.98,1.21c-0.8,0.08-1.29-0.55-1.24-1.4c0.05-0.85,0.62-1.41,1.39-1.24
	C466.69,199.03,467.14,199.67,467.95,200.33z"
              />
              <path
                d="M315.36,603.86c-0.89,0.58-1.4,1.16-1.97,1.23c-0.8,0.09-1.3-0.54-1.25-1.38c0.04-0.84,0.6-1.42,1.38-1.25
	C314.08,602.57,314.54,603.2,315.36,603.86z"
              />
              <path
                d="M288.02,414.64c-0.84,0.66-1.29,1.27-1.85,1.39c-0.79,0.17-1.34-0.42-1.37-1.26c-0.04-0.85,0.48-1.47,1.26-1.38
	C286.62,413.46,287.13,414.05,288.02,414.64z"
              />
              <path
                d="M307.64,391.29c-0.87,0.57-1.37,1.15-1.93,1.21c-0.77,0.08-1.28-0.53-1.23-1.36c0.04-0.82,0.6-1.4,1.36-1.24
	C306.39,390.02,306.83,390.64,307.64,391.29z"
              />
              <path
                d="M298.86,623.29c-0.91,0.61-1.4,1.14-1.96,1.26c-0.78,0.17-1.54-0.74-1.39-1.51c0.15-0.81,0.74-1.34,1.5-1.15
	C297.56,622.03,298,622.62,298.86,623.29z"
              />
              <path
                d="M249.21,342.09c-0.9,0.6-1.39,1.13-1.96,1.26c-0.81,0.18-1.47-0.65-1.35-1.5c0.12-0.85,0.7-1.32,1.47-1.12
	C247.92,340.88,248.37,341.45,249.21,342.09z"
              />
              <path
                d="M451.15,378.79c-0.92,0.6-1.44,1.19-1.99,1.23c-0.79,0.05-1.38-0.64-1.15-1.43c0.14-0.48,0.83-1.15,1.23-1.12
	C449.77,377.51,450.27,378.14,451.15,378.79z"
              />
              <path
                d="M442.32,610.88c-0.93,0.57-1.42,1.07-1.99,1.17c-0.8,0.14-1.5-0.75-1.34-1.53c0.17-0.81,0.77-1.3,1.52-1.1
	C441.06,609.58,441.48,610.18,442.32,610.88z"
              />
              <path
                d="M336.72,621.5c0.63,0.86,1.19,1.32,1.33,1.88c0.19,0.79-0.37,1.33-1.17,1.45c-0.77,0.12-1.7-0.68-1.5-1.41
	C335.54,622.85,336.08,622.38,336.72,621.5z"
              />
              <path
                d="M452.28,416.68c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.4,0.62,1.22,1.42
	C453.54,415.34,452.95,415.79,452.28,416.68z"
              />
              <path
                d="M448.31,389.69c-0.85,0.66-1.31,1.27-1.88,1.4c-0.8,0.17-1.34-0.42-1.38-1.27c-0.03-0.85,0.48-1.48,1.27-1.39
	C446.9,388.5,447.41,389.09,448.31,389.69z"
              />
              <path
                d="M278.85,417.14c-0.59-0.89-1.13-1.37-1.24-1.93c-0.16-0.8,0.42-1.31,1.23-1.41c0.76-0.09,1.67,0.77,1.44,1.47
	C280.09,415.84,279.53,416.28,278.85,417.14z"
              />
              <path
                d="M479.91,629.31c-0.88,0.56-1.39,1.14-1.94,1.19c-0.77,0.07-1.27-0.55-1.22-1.38c0.05-0.83,0.62-1.38,1.38-1.22
	C478.67,628.03,479.1,628.65,479.91,629.31z"
              />
              <path
                d="M360.85,276.03c0.69,0.86,1.31,1.3,1.43,1.86c0.16,0.76-0.39,1.36-1.22,1.41c-0.83,0.05-1.45-0.45-1.36-1.25
	C359.76,277.48,360.29,276.96,360.85,276.03z"
              />
              <path
                d="M346.62,590.17c-0.9,0.6-1.39,1.13-1.96,1.26c-0.8,0.18-1.47-0.65-1.35-1.5c0.12-0.85,0.7-1.32,1.47-1.12
	C345.33,588.95,345.77,589.52,346.62,590.17z"
              />
              <path
                d="M500.53,390.79c-0.6-0.95-1.14-1.46-1.22-2.03c-0.12-0.82,0.5-1.35,1.34-1.32c0.84,0.03,1.43,0.62,1.29,1.4
	C501.84,389.4,501.22,389.87,500.53,390.79z"
              />
              <path
                d="M334.83,580.14c-0.87,0.7-1.32,1.32-1.87,1.45c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.28-1.41
	C333.38,578.98,333.89,579.55,334.83,580.14z"
              />
              <path
                d="M391.46,109.33c-0.9,0.63-1.36,1.16-1.92,1.3c-0.76,0.19-1.55-0.7-1.42-1.46c0.13-0.8,0.69-1.35,1.47-1.17
	C390.13,108.13,390.58,108.69,391.46,109.33z"
              />
              <path
                d="M371.42,275.58c-0.71-0.83-1.29-1.25-1.5-1.81c-0.26-0.68,0.63-1.57,1.39-1.52c0.82,0.06,1.4,0.56,1.28,1.36
	C372.5,274.18,371.98,274.68,371.42,275.58z"
              />
              <path
                d="M552.75,633.99c0.35-0.78,0.5-1.44,0.9-1.87c0.22-0.24,0.96-0.26,1.28-0.06c0.4,0.25,0.82,0.82,0.81,1.24
	c-0.01,0.33-0.63,0.79-1.06,0.9C554.21,634.32,553.65,634.11,552.75,633.99z"
              />
              <path
                d="M383.3,98.96c0.83-0.62,1.3-1.17,1.87-1.33c0.75-0.21,1.49,0.66,1.38,1.49c-0.11,0.82-0.63,1.39-1.42,1.2
	C384.57,100.18,384.11,99.59,383.3,98.96z"
              />
              <path
                d="M274.64,343.41c-0.86,0.65-1.31,1.21-1.88,1.38c-0.8,0.23-1.51-0.55-1.44-1.4c0.06-0.85,0.6-1.37,1.39-1.22
	C273.28,342.28,273.76,342.82,274.64,343.41z"
              />
              <path
                d="M469.4,214.52c-0.78,0.66-1.21,1.28-1.77,1.44c-0.77,0.22-1.33-0.32-1.47-1.14c-0.14-0.83,0.54-1.72,1.31-1.55
	C468.05,213.4,468.54,213.94,469.4,214.52z"
              />
              <path
                d="M452.39,353.39c-0.97,0.59-1.49,1.15-2.05,1.19c-0.81,0.05-1.36-0.68-1.15-1.44c0.13-0.48,0.85-1.13,1.26-1.1
	C450.98,352.07,451.47,352.71,452.39,353.39z"
              />
              <path
                d="M446.61,202.22c-0.91,0.6-1.4,1.16-1.96,1.23c-0.8,0.1-1.31-0.56-1.28-1.35c0.03-0.79,0.58-1.43,1.37-1.27
	C445.29,200.94,445.74,201.55,446.61,202.22z"
              />
              <path
                d="M370.94,351.17c0.71,0.81,1.3,1.23,1.5,1.78c0.25,0.71-0.62,1.55-1.43,1.49c-0.81-0.06-1.44-0.52-1.28-1.36
	C369.83,352.52,370.37,352.03,370.94,351.17z"
              />
              <path
                d="M474.52,215.81c-0.68-0.82-1.26-1.25-1.45-1.81c-0.25-0.75,0.59-1.52,1.44-1.45c0.82,0.06,1.43,0.54,1.26,1.37
	C475.66,214.48,475.11,214.96,474.52,215.81z"
              />
              <path
                d="M503.14,625.39c-0.69-0.83-1.28-1.25-1.44-1.8c-0.22-0.77,0.31-1.34,1.1-1.52c0.75-0.17,1.72,0.59,1.57,1.33
	C504.24,623.98,503.73,624.48,503.14,625.39z"
              />
              <path
                d="M353.07,416.03c-0.87,0.71-1.31,1.33-1.87,1.45c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.28-1.41
	C351.61,414.88,352.13,415.44,353.07,416.03z"
              />
              <path
                d="M342.74,587.02c-0.85,0.61-1.32,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.39,1.44-1.2
	C341.47,585.77,341.92,586.37,342.74,587.02z"
              />
              <path
                d="M312.86,594.62c0.57,0.94,1.1,1.46,1.17,2.03c0.1,0.81-0.52,1.32-1.36,1.27c-0.83-0.05-1.4-0.65-1.25-1.41
	C311.54,595.95,312.17,595.49,312.86,594.62z"
              />
              <path
                d="M321.52,70.17c0.56,0.9,1.08,1.4,1.17,1.97c0.13,0.8-0.45,1.3-1.28,1.35c-0.78,0.05-1.65-0.82-1.39-1.51
	C320.23,71.42,320.81,71,321.52,70.17z"
              />
              <path
                d="M316.82,538.62c-0.91,0.58-1.43,1.16-2,1.22c-0.81,0.09-1.3-0.56-1.25-1.41c0.05-0.85,0.61-1.43,1.4-1.25
	C315.54,537.32,316,537.95,316.82,538.62z"
              />
              <path
                d="M656.68,503.5c0.02-1.08-1.03-2.27,0.33-3.21c1.15,1.24,1.15,1.24,1.4,3.06c-0.61,0.03-1.2,0.06-1.79,0.08
	C656.61,503.44,656.68,503.5,656.68,503.5z"
              />
              <path
                d="M480.89,596.65c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C479.61,595.38,480.07,596,480.89,596.65z"
              />
              <path
                d="M442.45,388.55c-0.57-0.86-1.12-1.34-1.23-1.91c-0.16-0.83,0.46-1.29,1.28-1.35c0.84-0.06,1.68,0.72,1.42,1.46
	C443.73,387.32,443.14,387.75,442.45,388.55z"
              />
              <path
                d="M260.66,431.59c-0.89,0.59-1.4,1.17-1.96,1.24c-0.8,0.1-1.3-0.53-1.26-1.38c0.04-0.84,0.59-1.43,1.37-1.26
	C259.38,430.31,259.83,430.93,260.66,431.59z"
              />
              <path
                d="M329.5,621.96c0.65,0.9,1.22,1.37,1.33,1.93c0.17,0.81-0.42,1.35-1.25,1.38c-0.85,0.03-1.44-0.55-1.35-1.31
	C328.29,623.41,328.87,622.9,329.5,621.96z"
              />
              <path
                d="M336.96,585.58c-0.62-0.88-1.17-1.35-1.3-1.92c-0.18-0.8,0.4-1.33,1.21-1.44c0.77-0.11,1.7,0.73,1.48,1.45
	C338.18,584.24,337.63,584.7,336.96,585.58z"
              />
              <path
                d="M244.2,348.09c-0.69-0.92-1.31-1.4-1.4-1.96c-0.13-0.78,0.47-1.36,1.29-1.39c0.84-0.03,1.45,0.5,1.33,1.32
	C245.34,346.63,244.8,347.13,244.2,348.09z"
              />
              <path
                d="M448.61,207.48c-0.68-0.91-1.29-1.38-1.39-1.94c-0.13-0.77,0.45-1.36,1.28-1.38c0.83-0.02,1.44,0.5,1.32,1.31
	C449.75,206.03,449.21,206.53,448.61,207.48z"
              />
              <path
                d="M450.08,348.03c-0.67-0.87-1.28-1.33-1.39-1.88c-0.15-0.76,0.42-1.35,1.23-1.38c0.81-0.04,1.43,0.47,1.33,1.27
	C451.18,346.6,450.65,347.1,450.08,348.03z"
              />
              <path
                d="M321.39,549.18c-0.97,0.58-1.5,1.14-2.05,1.17c-0.81,0.04-1.35-0.69-1.14-1.45c0.13-0.47,0.85-1.12,1.27-1.09
	C320,547.85,320.48,548.49,321.39,549.18z"
              />
              <path
                d="M443.68,605.02c0.65,0.81,1.24,1.24,1.38,1.79c0.21,0.8-0.37,1.32-1.17,1.45c-0.79,0.13-1.71-0.63-1.52-1.37
	C442.51,606.33,443.06,605.87,443.68,605.02z"
              />
              <path
                d="M253.45,432.07c-0.93,0.58-1.45,1.17-2.01,1.2c-0.79,0.04-1.37-0.66-1.13-1.45c0.15-0.48,0.85-1.14,1.25-1.1
	C252.1,430.78,252.58,431.42,253.45,432.07z"
              />
              <path
                d="M494.33,627.7c-0.83,0.64-1.29,1.26-1.84,1.37c-0.78,0.16-1.32-0.43-1.35-1.26c-0.03-0.84,0.49-1.45,1.26-1.36
	C492.96,626.52,493.45,627.11,494.33,627.7z"
              />
              <path
                d="M365.99,274.23c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C364.75,272.96,365.17,273.54,365.99,274.23z"
              />
              <path
                d="M343.56,245.34c-0.67-0.87-1.28-1.32-1.39-1.88c-0.15-0.76,0.42-1.35,1.23-1.38c0.81-0.04,1.43,0.47,1.33,1.27
	C344.65,243.91,344.13,244.41,343.56,245.34z"
              />
              <path
                d="M444.64,350.14c0.89-0.6,1.37-1.15,1.94-1.27c0.79-0.16,1.33,0.37,1.41,1.22c0.07,0.85-0.65,1.66-1.44,1.43
	C445.97,351.36,445.51,350.8,444.64,350.14z"
              />
              <path
                d="M329.06,614.78c0.62,0.86,1.17,1.33,1.3,1.89c0.18,0.79-0.38,1.32-1.19,1.44c-0.78,0.11-1.69-0.7-1.48-1.42
	C327.87,616.11,328.42,615.65,329.06,614.78z"
              />
              <path
                d="M453.87,604.26c-0.53-0.91-1.04-1.43-1.12-2c-0.1-0.8,0.5-1.28,1.32-1.32c0.77-0.03,1.63,0.88,1.35,1.55
	C455.19,603.05,454.6,603.46,453.87,604.26z"
              />
              <path
                d="M472.51,210.9c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C471.28,209.62,471.7,210.2,472.51,210.9z"
              />
              <path
                d="M505.89,385.01c-0.85,0.61-1.33,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.39,1.44-1.2
	C504.61,383.75,505.07,384.36,505.89,385.01z"
              />
              <path
                d="M344.27,212.72c-0.66-0.87-1.23-1.32-1.36-1.87c-0.18-0.8,0.42-1.34,1.2-1.39c0.82-0.05,1.42,0.51,1.35,1.27
	C345.41,211.27,344.85,211.78,344.27,212.72z"
              />
              <path
                d="M495.12,618.7c-0.65-0.97-1.24-1.46-1.3-2.02c-0.09-0.79,0.57-1.42,1.36-1.24c0.47,0.11,1.13,0.79,1.14,1.22
	C496.32,617.19,495.73,617.72,495.12,618.7z"
              />
              <path
                d="M306.53,210.88c-0.93,0.6-1.44,1.17-2,1.23c-0.81,0.1-1.33-0.57-1.3-1.38c0.04-0.8,0.6-1.45,1.4-1.28
	C305.19,209.56,305.64,210.18,306.53,210.88z"
              />
              <path
                d="M364.08,292.42c-0.9,0.61-1.39,1.17-1.95,1.25c-0.8,0.11-1.31-0.55-1.29-1.34c0.02-0.79,0.56-1.43,1.36-1.28
	C362.75,291.14,363.2,291.75,364.08,292.42z"
              />
              <path
                d="M425.2,147.97c-0.59-0.99-1.14-1.51-1.18-2.07c-0.07-0.81,0.62-1.39,1.4-1.18c0.47,0.13,1.12,0.84,1.1,1.26
	C426.48,146.52,425.86,147.03,425.2,147.97z"
              />
              <path
                d="M305,73.27c0.89-0.6,1.37-1.15,1.94-1.27c0.79-0.16,1.33,0.37,1.41,1.22c0.07,0.85-0.65,1.66-1.44,1.43
	C306.34,74.49,305.88,73.93,305,73.27z"
              />
              <path
                d="M484.38,243.92c-0.67-0.88-1.24-1.34-1.37-1.89c-0.18-0.8,0.41-1.35,1.21-1.41c0.83-0.05,1.44,0.52,1.37,1.28
	C485.54,242.46,484.98,242.98,484.38,243.92z"
              />
              <path
                d="M447.8,382.66c-0.89,0.58-1.4,1.16-1.97,1.22c-0.8,0.09-1.3-0.54-1.25-1.39c0.04-0.84,0.6-1.42,1.38-1.25
	C446.52,381.37,446.97,381.99,447.8,382.66z"
              />
              <path
                d="M240.86,347.99c0.58,0.97,1.13,1.5,1.17,2.07c0.06,0.81-0.63,1.38-1.41,1.16c-0.47-0.14-1.11-0.85-1.09-1.27
	C239.56,349.41,240.19,348.91,240.86,347.99z"
              />
              <path
                d="M318.68,543.84c-0.6-0.89-1.14-1.38-1.25-1.95c-0.16-0.81,0.42-1.32,1.24-1.41c0.77-0.09,1.69,0.77,1.45,1.48
	C319.93,542.52,319.36,542.97,318.68,543.84z"
              />
              <path
                d="M464.9,207.55c0.85-0.68,1.32-1.32,1.86-1.38c0.4-0.04,1.11,0.6,1.27,1.07c0.26,0.79-0.32,1.5-1.1,1.47
	C466.37,208.7,465.83,208.11,464.9,207.55z"
              />
              <path d="M224.22,244.36c-0.07-0.94-0.15-1.9-0.23-2.97c1.37-0.04,1.98,0.55,2,1.52C226.01,243.78,225.52,244.42,224.22,244.36z" />
              <path
                d="M418.12,623.57c-0.83,0.68-1.27,1.26-1.84,1.45c-0.79,0.26-1.53-0.51-1.5-1.37c0.03-0.87,0.56-1.41,1.35-1.28
	C416.71,622.47,417.22,623,418.12,623.57z"
              />
              <path
                d="M473.74,596.98c-0.85,0.66-1.31,1.28-1.87,1.4c-0.8,0.17-1.35-0.42-1.38-1.27c-0.04-0.85,0.47-1.48,1.27-1.39
	C472.32,595.79,472.84,596.38,473.74,596.98z"
              />
              <path
                d="M329.71,420.01c-0.59-0.92-1.12-1.42-1.2-1.98c-0.12-0.8,0.5-1.31,1.3-1.29c0.81,0.02,1.39,0.6,1.25,1.36
	C330.97,418.66,330.37,419.12,329.71,420.01z"
              />
              <path
                d="M338.18,576.48c-0.91,0.61-1.41,1.18-1.97,1.26c-0.81,0.11-1.33-0.55-1.3-1.35c0.02-0.8,0.57-1.44,1.37-1.29
	C336.84,575.2,337.3,575.81,338.18,576.48z"
              />
              <path
                d="M495.91,625.89c-0.68-0.89-1.26-1.34-1.4-1.9c-0.19-0.81,0.4-1.37,1.21-1.43c0.84-0.06,1.45,0.51,1.39,1.28
	C497.08,624.41,496.51,624.93,495.91,625.89z"
              />
              <path
                d="M259.67,131.71c-0.93,0.57-1.45,1.15-2,1.18c-0.77,0.03-1.36-0.66-1.11-1.45c0.15-0.47,0.85-1.12,1.25-1.08
	C258.34,130.41,258.81,131.05,259.67,131.71z"
              />
              <path
                d="M456.1,415.93c0.69,0.86,1.29,1.3,1.42,1.85c0.2,0.82-0.4,1.36-1.23,1.41c-0.83,0.05-1.49-0.45-1.41-1.26
	C454.93,417.36,455.5,416.85,456.1,415.93z"
              />
              <path
                d="M327.65,604.5c-0.67-0.88-1.28-1.35-1.38-1.91c-0.14-0.8,0.47-1.33,1.32-1.33c0.84-0.01,1.48,0.45,1.33,1.3
	C328.82,603.12,328.26,603.6,327.65,604.5z"
              />
              <path
                d="M234.7,383.09c-0.9,0.6-1.4,1.18-1.94,1.22c-0.76,0.05-1.36-0.61-1.14-1.39c0.13-0.47,0.8-1.13,1.2-1.11
	C233.34,381.84,233.82,382.45,234.7,383.09z"
              />
              <path
                d="M309.55,206.84c-0.85,0.66-1.3,1.28-1.84,1.37c-0.77,0.13-1.4-0.5-1.24-1.29c0.1-0.49,0.71-1.21,1.1-1.22
	C308.09,205.69,308.63,206.27,309.55,206.84z"
              />
              <path
                d="M290.34,506.5c-0.7-0.89-1.34-1.35-1.41-1.89c-0.1-0.76,0.53-1.4,1.35-1.21c0.48,0.11,1.15,0.72,1.17,1.13
	C291.46,505.04,290.89,505.57,290.34,506.5z"
              />
              <path
                d="M449.76,383.99c0.61,0.95,1.2,1.48,1.2,2.01c0,0.41-0.67,1.08-1.14,1.19c-0.78,0.18-1.44-0.42-1.33-1.22
	C448.57,385.42,449.14,384.93,449.76,383.99z"
              />
              <path
                d="M463.35,209.34c0.65,0.92,1.23,1.39,1.32,1.95c0.13,0.8-0.51,1.41-1.3,1.26c-0.48-0.09-1.17-0.75-1.17-1.16
	C462.19,210.84,462.77,210.3,463.35,209.34z"
              />
              <path
                d="M268.79,318.84c-0.92,0.58-1.45,1.16-1.99,1.19c-0.77,0.04-1.36-0.66-1.12-1.44c0.15-0.47,0.84-1.12,1.24-1.09
	C267.44,317.54,267.92,318.18,268.79,318.84z"
              />
              <path
                d="M300.26,431.62c-0.96,0.57-1.48,1.13-2.03,1.16c-0.8,0.04-1.34-0.68-1.12-1.44c0.14-0.47,0.85-1.12,1.25-1.08
	C298.88,430.3,299.36,430.94,300.26,431.62z"
              />
              <path
                d="M380.48,315.99c0.84-0.67,1.3-1.31,1.82-1.36c0.39-0.04,1.1,0.59,1.25,1.05c0.26,0.78-0.33,1.46-1.08,1.45
	C381.94,317.12,381.41,316.55,380.48,315.99z"
              />
              <path
                d="M389.42,632.72c0.08,1.25-0.41,1.52-1.15,1.52c-0.72,0-1.54,0.07-1.6-0.94c-0.05-0.83,0.5-1.43,1.3-1.35
	C388.54,632.02,389.07,632.53,389.42,632.72z"
              />
              <path
                d="M263.31,420.56c-0.96,0.59-1.47,1.15-2.02,1.19c-0.8,0.05-1.35-0.67-1.14-1.42c0.13-0.47,0.83-1.13,1.24-1.1
	C261.92,419.26,262.41,419.89,263.31,420.56z"
              />
              <path
                d="M381.18,97.55c-0.64-0.93-1.21-1.41-1.3-1.96c-0.12-0.8,0.55-1.38,1.3-1.23c0.46,0.09,1.14,0.75,1.14,1.16
	C382.34,96.05,381.76,96.59,381.18,97.55z"
              />
              <path
                d="M353.09,582.23c-0.88,0.73-1.31,1.36-1.86,1.49c-0.78,0.19-1.4-0.35-1.47-1.19c-0.07-0.83,0.41-1.48,1.24-1.4
	C351.57,581.19,352.11,581.7,353.09,582.23z"
              />
              <path
                d="M316.92,312.51c0.68,0.88,1.31,1.35,1.36,1.88c0.04,0.41-0.55,1.14-1.01,1.3c-0.79,0.28-1.46-0.33-1.46-1.09
	C315.82,314.04,316.37,313.49,316.92,312.51z"
              />
              <path
                d="M267.25,201.42c-0.55-0.92-1.09-1.44-1.15-2.01c-0.09-0.85,0.58-1.27,1.42-1.21c0.85,0.06,1.42,0.62,1.23,1.41
	C268.61,200.16,267.96,200.59,267.25,201.42z"
              />
              <path
                d="M505.16,377.68c-0.91,0.68-1.39,1.31-1.91,1.34c-0.41,0.03-1.11-0.62-1.25-1.09c-0.22-0.77,0.34-1.48,1.13-1.43
	C503.68,376.53,504.2,377.09,505.16,377.68z"
              />
              <path
                d="M292.93,495.36c-0.67-0.93-1.25-1.4-1.34-1.95c-0.14-0.81,0.51-1.41,1.29-1.27c0.48,0.08,1.17,0.74,1.19,1.15
	C294.07,493.82,293.5,494.37,292.93,495.36z"
              />
              <path
                d="M352.64,76.79c-0.99,0.59-1.51,1.12-2.07,1.18c-0.83,0.1-1.34-0.55-1.3-1.37c0.04-0.82,0.63-1.4,1.44-1.22
	C351.26,75.51,351.72,76.09,352.64,76.79z"
              />
              <path
                d="M281.74,176.79c-0.92,0.59-1.43,1.18-1.98,1.21c-0.78,0.05-1.37-0.64-1.13-1.42c0.14-0.48,0.83-1.13,1.23-1.1
	C280.39,175.51,280.87,176.13,281.74,176.79z"
              />
              <path
                d="M329.71,586.26c-0.59-0.91-1.14-1.4-1.23-1.97c-0.13-0.85,0.52-1.29,1.36-1.27c0.85,0.02,1.45,0.56,1.29,1.36
	C331.02,584.94,330.39,585.4,329.71,586.26z"
              />
              <path
                d="M321.2,628.33c-0.85,0.7-1.3,1.31-1.86,1.46c-0.78,0.2-1.38-0.3-1.45-1.18c-0.07-0.87,0.41-1.48,1.23-1.41
	C319.69,627.25,320.23,627.78,321.2,628.33z"
              />
              <path
                d="M450.5,391.2c0.59,0.95,1.15,1.46,1.21,2.02c0.08,0.8-0.59,1.38-1.37,1.18c-0.47-0.12-1.12-0.82-1.1-1.23
	C449.26,392.63,449.87,392.12,450.5,391.2z"
              />
              <path
                d="M501.15,247.91c-0.9,0.66-1.37,1.25-1.94,1.37c-0.8,0.16-1.35-0.38-1.38-1.25c-0.03-0.88,0.49-1.45,1.3-1.33
	C499.7,246.77,500.21,247.32,501.15,247.91z"
              />
              <path
                d="M509.06,257.93c0.85-0.67,1.31-1.32,1.84-1.37c0.39-0.04,1.1,0.59,1.26,1.06c0.26,0.78-0.32,1.48-1.09,1.46
	C510.53,259.07,509.99,258.49,509.06,257.93z"
              />
              <path
                d="M360.7,296.17c-0.91,0.64-1.39,1.22-1.94,1.29c-0.8,0.1-1.36-0.58-1.19-1.35c0.11-0.47,0.76-1.15,1.16-1.15
	C359.26,294.97,359.78,295.56,360.7,296.17z"
              />
              <path
                d="M436.89,616.49c-0.59-0.93-1.17-1.45-1.22-2.01c-0.06-0.79,0.6-1.29,1.44-1.23c0.84,0.06,1.43,0.59,1.21,1.41
	C438.18,615.21,437.58,615.65,436.89,616.49z"
              />
              <path
                d="M223.08,219.98c0.28,0.42,0.92,0.95,0.9,1.46c-0.02,0.54-0.55,1.37-1.03,1.53c-0.77,0.25-0.99-0.54-1-1.2
	C221.94,221.1,221.75,220.31,223.08,219.98z"
              />
              <path
                d="M310.37,526.29c0.62,0.89,1.18,1.37,1.28,1.93c0.15,0.84-0.48,1.32-1.31,1.31c-0.84,0-1.46-0.54-1.33-1.33
	C309.1,527.65,309.71,527.18,310.37,526.29z"
              />
              <path
                d="M315.91,630.41c0.63,0.91,1.23,1.4,1.3,1.95c0.11,0.8-0.53,1.32-1.37,1.3c-0.84-0.02-1.45-0.53-1.28-1.36
	C314.68,631.75,315.26,631.29,315.91,630.41z"
              />
              <path
                d="M252.76,258.86c-0.96,0.58-1.47,1.14-2.02,1.18c-0.8,0.05-1.34-0.67-1.13-1.43c0.13-0.47,0.84-1.12,1.24-1.09
	C251.37,257.55,251.85,258.19,252.76,258.86z"
              />
              <path
                d="M476.06,625.74c-0.85,0.66-1.3,1.28-1.84,1.37c-0.77,0.13-1.4-0.5-1.24-1.29c0.1-0.49,0.71-1.21,1.1-1.21
	C474.6,624.59,475.14,625.17,476.06,625.74z"
              />
              <path
                d="M472.35,606.2c-0.65-0.94-1.22-1.42-1.3-1.98c-0.13-0.81,0.55-1.39,1.31-1.24c0.47,0.09,1.16,0.76,1.16,1.18
	C473.52,604.69,472.94,605.23,472.35,606.2z"
              />
              <path
                d="M406.46,138.81c-0.57-0.97-1.11-1.49-1.15-2.04c-0.05-0.79,0.64-1.35,1.4-1.13c0.46,0.13,1.07,0.84,1.05,1.26
	C407.73,137.42,407.11,137.9,406.46,138.81z"
              />
              <path
                d="M472.7,629.73c-0.91,0.6-1.41,1.18-1.95,1.22c-0.77,0.05-1.36-0.61-1.14-1.39c0.13-0.47,0.81-1.14,1.2-1.11
	C471.34,628.48,471.83,629.09,472.7,629.73z"
              />
              <path
                d="M451.05,398.41c0.56,0.96,1.11,1.49,1.14,2.05c0.05,0.8-0.65,1.36-1.42,1.13c-0.46-0.14-1.09-0.86-1.05-1.28
	C449.76,399.78,450.39,399.29,451.05,398.41z"
              />
              <path
                d="M451.53,611.48c0.56,0.95,1.1,1.48,1.13,2.03c0.05,0.79-0.65,1.35-1.41,1.12c-0.46-0.14-1.07-0.86-1.03-1.27
	C450.26,612.83,450.88,612.35,451.53,611.48z"
              />
              <path
                d="M261.09,438.8c-0.92,0.57-1.44,1.15-1.98,1.17c-0.76,0.03-1.35-0.65-1.11-1.43c0.14-0.47,0.84-1.11,1.23-1.08
	C259.76,437.52,260.23,438.15,261.09,438.8z"
              />
              <path
                d="M323.65,601.13c-0.59-0.93-1.17-1.45-1.22-2.01c-0.06-0.79,0.6-1.29,1.44-1.23c0.84,0.06,1.43,0.59,1.21,1.41
	C324.94,599.86,324.33,600.29,323.65,601.13z"
              />
              <path
                d="M425.98,630.25c-0.95,0.59-1.47,1.15-2.02,1.19c-0.8,0.05-1.35-0.67-1.14-1.43c0.13-0.47,0.83-1.12,1.24-1.1
	C424.58,628.94,425.07,629.58,425.98,630.25z"
              />
              <path
                d="M306.33,250.27c-0.88,0.68-1.34,1.27-1.91,1.4c-0.79,0.18-1.36-0.35-1.41-1.22c-0.05-0.88,0.46-1.46,1.27-1.37
	C304.86,249.16,305.38,249.7,306.33,250.27z"
              />
              <path
                d="M346.04,250.71c-0.93,0.57-1.45,1.15-2,1.18c-0.77,0.03-1.36-0.66-1.11-1.45c0.15-0.47,0.85-1.12,1.25-1.08
	C344.71,249.41,345.18,250.05,346.04,250.71z"
              />
              <path
                d="M351.32,188.8c-0.92,0.58-1.45,1.16-1.99,1.19c-0.77,0.04-1.36-0.66-1.12-1.44c0.15-0.47,0.85-1.12,1.24-1.09
	C349.98,187.51,350.46,188.15,351.32,188.8z"
              />
              <path
                d="M455.06,361.95c0.59,0.94,1.17,1.47,1.17,2c0,0.41-0.67,1.07-1.14,1.17c-0.77,0.16-1.43-0.43-1.31-1.22
	C453.86,363.34,454.43,362.86,455.06,361.95z"
              />
              <path
                d="M439.4,329.22c-0.9,0.67-1.36,1.27-1.9,1.35c-0.79,0.12-1.38-0.54-1.22-1.32c0.1-0.48,0.72-1.18,1.12-1.18
	C437.92,328.06,438.45,328.64,439.4,329.22z"
              />
              <path
                d="M360.89,90.44c-0.92,0.57-1.44,1.15-1.98,1.17c-0.76,0.03-1.35-0.65-1.11-1.43c0.15-0.47,0.84-1.11,1.24-1.07
	C359.57,89.15,360.04,89.78,360.89,90.44z"
              />
              <path
                d="M315.42,583.61c0.6,0.96,1.19,1.49,1.19,2.02c0,0.41-0.68,1.08-1.15,1.18c-0.78,0.16-1.44-0.43-1.32-1.23
	C314.22,585.02,314.79,584.53,315.42,583.61z"
              />
              <path
                d="M484.72,599.89c-0.88,0.64-1.36,1.25-1.91,1.32c-0.79,0.1-1.4-0.55-1.21-1.35c0.11-0.49,0.76-1.19,1.15-1.19
	C483.29,598.69,483.81,599.29,484.72,599.89z"
              />
              <path
                d="M307.63,185.42c-0.91,0.58-1.42,1.17-1.96,1.2c-0.76,0.04-1.35-0.63-1.12-1.41c0.14-0.47,0.82-1.12,1.22-1.09
	C306.29,184.15,306.77,184.78,307.63,185.42z"
              />
              <path
                d="M346.85,367.94c-0.67-0.88-1.28-1.35-1.38-1.91c-0.14-0.8,0.47-1.33,1.32-1.33c0.84-0.01,1.48,0.45,1.33,1.3
	C348.02,366.56,347.46,367.04,346.85,367.94z"
              />
              <path
                d="M311.9,587.47c0.67,0.88,1.3,1.35,1.35,1.89c0.04,0.41-0.57,1.14-1.04,1.29c-0.77,0.25-1.47-0.32-1.44-1.11
	C310.8,588.97,311.35,588.43,311.9,587.47z"
              />
              <path
                d="M317.89,576.42c-0.54-0.99-1.1-1.54-1.07-2.07c0.02-0.41,0.73-1.04,1.2-1.11c0.76-0.11,1.41,0.49,1.25,1.29
	C319.16,575.08,318.57,575.53,317.89,576.42z"
              />
              <path
                d="M494.46,607.61c0.68,0.88,1.31,1.35,1.36,1.88c0.04,0.41-0.55,1.14-1.01,1.3c-0.79,0.28-1.46-0.33-1.46-1.09
	C493.37,609.14,493.92,608.6,494.46,607.61z"
              />
              <path
                d="M288.12,437.99c-0.66-0.86-1.25-1.31-1.38-1.87c-0.19-0.83,0.41-1.34,1.25-1.38c0.85-0.04,1.48,0.47,1.4,1.26
	C289.33,436.57,288.73,437.08,288.12,437.99z"
              />
              <path
                d="M354.18,327.63c-0.59-0.91-1.14-1.4-1.23-1.97c-0.13-0.85,0.52-1.29,1.36-1.27c0.85,0.02,1.45,0.56,1.29,1.36
	C355.49,326.31,354.86,326.76,354.18,327.63z"
              />
              <path
                d="M466.58,597.86c-0.94,0.58-1.45,1.12-2.02,1.19c-0.8,0.1-1.31-0.5-1.27-1.35c0.04-0.85,0.6-1.39,1.4-1.2
	C465.24,596.64,465.7,597.21,466.58,597.86z"
              />
              <path
                d="M338.94,417.46c-0.95,0.57-1.47,1.11-2.04,1.18c-0.8,0.09-1.3-0.51-1.25-1.36c0.05-0.85,0.62-1.38,1.41-1.19
	C337.62,416.22,338.07,416.8,338.94,417.46z"
              />
              <path
                d="M306.88,317.1c0.58,0.92,1.15,1.42,1.2,1.97c0.07,0.79-0.58,1.28-1.41,1.23c-0.83-0.05-1.41-0.58-1.22-1.39
	C305.59,318.37,306.19,317.94,306.88,317.1z"
              />
              <path
                d="M319.26,606.75c-0.86,0.68-1.3,1.27-1.85,1.41c-0.78,0.2-1.37-0.31-1.44-1.16c-0.07-0.84,0.4-1.46,1.22-1.38
	C317.77,605.68,318.3,606.2,319.26,606.75z"
              />
              <path
                d="M604.62,630.85c-0.7-0.84-1.34-1.27-1.46-1.82c-0.16-0.76,0.39-1.33,1.24-1.38c0.84-0.04,1.48,0.4,1.38,1.22
	C605.72,629.44,605.18,629.94,604.62,630.85z"
              />
              <path
                d="M291.67,497.56c-0.96,0.59-1.46,1.12-2.03,1.19c-0.81,0.11-1.33-0.52-1.29-1.32c0.04-0.8,0.59-1.4,1.4-1.22
	C290.31,496.34,290.76,496.9,291.67,497.56z"
              />
              <path
                d="M324.38,604.52c0.7,0.85,1.34,1.28,1.45,1.83c0.16,0.77-0.41,1.33-1.25,1.37c-0.83,0.04-1.47-0.4-1.38-1.23
	C323.27,605.94,323.82,605.44,324.38,604.52z"
              />
              <path
                d="M322,619.33c-0.7-0.83-1.35-1.27-1.46-1.81c-0.17-0.76,0.39-1.33,1.24-1.38c0.83-0.04,1.48,0.39,1.39,1.22
	C323.09,617.92,322.55,618.43,322,619.33z"
              />
              <path d="M223.05,230.15c0-1.15,0-2.14,0-3.26c1.19,0.21,1.77,0.69,1.73,1.74C224.74,229.57,224.15,229.94,223.05,230.15z" />
              <path
                d="M413.16,629.41c-0.7-0.84-1.34-1.27-1.46-1.82c-0.16-0.76,0.39-1.33,1.24-1.38c0.84-0.04,1.48,0.4,1.38,1.22
	C414.26,628,413.72,628.5,413.16,629.41z"
              />
              <path
                d="M291.19,450.35c-0.9,0.69-1.34,1.27-1.9,1.39c-0.79,0.18-1.38-0.38-1.43-1.2c-0.05-0.81,0.44-1.45,1.27-1.35
	C289.7,449.27,290.21,449.78,291.19,450.35z"
              />
              <path
                d="M371.57,85.69c-0.87,0.66-1.33,1.24-1.89,1.37c-0.79,0.18-1.36-0.35-1.41-1.2c-0.05-0.84,0.44-1.45,1.26-1.35
	C370.11,84.59,370.62,85.12,371.57,85.69z"
              />
              <path
                d="M435.87,618.34c-0.88,0.65-1.34,1.22-1.91,1.34c-0.79,0.17-1.35-0.37-1.39-1.22c-0.03-0.84,0.47-1.44,1.28-1.33
	C434.42,617.22,434.93,617.76,435.87,618.34z"
              />
              <path
                d="M422.19,626.93c-0.9,0.69-1.34,1.27-1.89,1.39c-0.79,0.18-1.38-0.38-1.43-1.2c-0.05-0.81,0.44-1.45,1.27-1.35
	C420.7,625.85,421.21,626.36,422.19,626.93z"
              />
              <path
                d="M347.98,588.14c-0.61-0.87-1.18-1.35-1.26-1.9c-0.12-0.8,0.48-1.29,1.33-1.29c0.86,0,1.43,0.57,1.31,1.32
	C349.27,586.82,348.64,587.28,347.98,588.14z"
              />
              <path
                d="M373.57,87.26c0.59,0.91,1.16,1.42,1.21,1.97c0.07,0.79-0.58,1.28-1.4,1.23c-0.83-0.05-1.41-0.58-1.22-1.38
	C372.29,88.53,372.89,88.1,373.57,87.26z"
              />
              <path d="M492.34,29.58c-0.74,0.19-1.39,0.58-1.89,0.44c-0.91-0.26-1.36-1-0.87-2.11C490.84,27.56,491.54,28.27,492.34,29.58z" />
              <path
                d="M364.29,402.44c-0.61-0.87-1.18-1.35-1.26-1.9c-0.12-0.8,0.48-1.29,1.33-1.29c0.86,0,1.43,0.57,1.31,1.32
	C365.59,401.12,364.96,401.58,364.29,402.44z"
              />
              <path
                d="M348.69,575.14c0.94-0.58,1.45-1.11,2.03-1.19c0.81-0.11,1.31,0.5,1.27,1.34c-0.04,0.85-0.61,1.39-1.4,1.21
	C350.03,576.37,349.57,575.79,348.69,575.14z"
              />
              <path
                d="M354.53,264.3c-0.94,0.63-1.42,1.17-1.98,1.27c-0.82,0.14-1.36-0.49-1.35-1.28c0.01-0.78,0.53-1.43,1.36-1.27
	C353.12,263.13,353.6,263.67,354.53,264.3z"
              />
              <path
                d="M395.25,630.92c0.64,0.86,1.23,1.32,1.33,1.87c0.15,0.8-0.45,1.31-1.29,1.32c-0.85,0.01-1.45-0.52-1.35-1.29
	C394,632.28,394.62,631.8,395.25,630.92z"
              />
              <path
                d="M522.6,269.53c-0.58-0.89-1.14-1.38-1.22-1.93c-0.11-0.81,0.51-1.28,1.35-1.25c0.86,0.02,1.42,0.59,1.28,1.35
	C523.91,268.23,523.28,268.68,522.6,269.53z"
              />
              <path
                d="M522.57,309.36c-0.7-0.83-1.35-1.27-1.46-1.81c-0.17-0.76,0.39-1.33,1.24-1.38c0.83-0.04,1.48,0.39,1.39,1.22
	C523.67,307.94,523.13,308.45,522.57,309.36z"
              />
              <path d="M300.18,49.56c0.64-0.8,1.18-1.48,1.7-2.13c1.17,0.41,1.22,1.1,0.84,1.8C302.2,50.21,301.38,50.16,300.18,49.56z" />
              <path
                d="M476.87,633.01c-0.9,0.6-1.33,1.09-1.83,1.18c-0.82,0.16-1.51-0.33-1.35-1.19c0.09-0.48,0.75-1.21,1.15-1.21
	C475.36,631.8,475.9,632.4,476.87,633.01z"
              />
              <path
                d="M323.07,610.56c-1,0.47-1.57,0.95-2.15,0.95c-0.81,0-1.23-0.66-1.09-1.49c0.14-0.86,0.79-1.28,1.54-1.03
	C321.9,609.18,322.29,609.81,323.07,610.56z"
              />
              <path
                d="M458.14,212.33c-1.02,0.46-1.59,0.92-2.16,0.92c-0.8,0-1.25-0.66-1.1-1.48c0.15-0.82,0.79-1.3,1.55-1.04
	C456.95,210.91,457.33,211.54,458.14,212.33z"
              />
              <path
                d="M513.54,634.28c-0.6-1.17-0.44-1.94,0.46-2.21c0.47-0.14,1.19,0.15,1.63,0.47c0.23,0.17,0.23,1.17,0.12,1.21
	C515.02,634.02,514.23,634.13,513.54,634.28z"
              />
              <path
                d="M516.48,340.56c-0.89,0.63-1.37,1.19-1.94,1.3c-0.8,0.16-1.35-0.41-1.35-1.25c-0.01-0.84,0.5-1.43,1.32-1.29
	C515.07,339.41,515.57,339.96,516.48,340.56z"
              />
              <path
                d="M321.89,596.02c-0.99,0.55-1.51,1.06-2.08,1.11c-0.81,0.07-1.31-0.56-1.23-1.38c0.07-0.81,0.66-1.37,1.45-1.16
	C320.58,594.73,321.02,595.31,321.89,596.02z"
              />
              <path
                d="M322.23,626.53c-0.59-0.9-1.17-1.41-1.22-1.96c-0.07-0.78,0.55-1.28,1.4-1.23c0.84,0.05,1.41,0.58,1.23,1.38
	C323.51,625.26,322.91,625.69,322.23,626.53z"
              />
              <path
                d="M346.14,583.01c-0.96,0.57-1.48,1.11-2.05,1.17c-0.8,0.09-1.3-0.52-1.24-1.37c0.06-0.85,0.63-1.37,1.42-1.18
	C344.82,581.77,345.27,582.35,346.14,583.01z"
              />
              <path
                d="M464.18,197.19c-0.94,0.58-1.45,1.12-2.03,1.19c-0.8,0.1-1.31-0.5-1.27-1.35c0.05-0.85,0.6-1.39,1.4-1.2
	C462.84,195.97,463.3,196.54,464.18,197.19z"
              />
              <path
                d="M448.1,343.01c-0.95,0.62-1.43,1.16-2,1.25c-0.82,0.13-1.35-0.5-1.34-1.3c0.02-0.79,0.55-1.42,1.37-1.25
	C446.7,341.83,447.18,342.37,448.1,343.01z"
              />
              <path
                d="M525.12,274.75c-0.87,0.66-1.33,1.24-1.89,1.37c-0.79,0.18-1.36-0.35-1.4-1.2c-0.04-0.84,0.44-1.45,1.26-1.35
	C523.66,273.65,524.17,274.18,525.12,274.75z"
              />
              <path
                d="M304.63,355.56c-0.98,0.58-1.49,1.1-2.05,1.17c-0.81,0.1-1.33-0.54-1.27-1.35c0.05-0.81,0.62-1.39,1.42-1.2
	C303.28,354.31,303.73,354.88,304.63,355.56z"
              />
              <path
                d="M327.66,620.81c-0.94,0.63-1.42,1.17-1.98,1.27c-0.82,0.14-1.36-0.49-1.35-1.28c0.01-0.78,0.53-1.43,1.36-1.27
	C326.25,619.65,326.73,620.19,327.66,620.81z"
              />
              <path
                d="M324.86,611.72c0.7,0.85,1.34,1.28,1.45,1.83c0.16,0.77-0.41,1.33-1.25,1.37c-0.83,0.04-1.47-0.4-1.38-1.23
	C323.75,613.13,324.29,612.63,324.86,611.72z"
              />
              <path
                d="M326.55,586.29c0.59,0.91,1.16,1.42,1.21,1.97c0.07,0.79-0.58,1.28-1.4,1.23c-0.83-0.05-1.41-0.58-1.22-1.38
	C325.26,587.57,325.86,587.13,326.55,586.29z"
              />
              <path
                d="M485.65,586.77c0.55,0.92,1.08,1.43,1.13,1.99c0.08,0.83-0.57,1.26-1.4,1.2c-0.83-0.06-1.4-0.62-1.22-1.39
	C484.29,588.03,484.93,587.61,485.65,586.77z"
              />
              <path
                d="M271.66,620.41c0.54,0.72,1.07,1.11,1.14,1.57c0.13,0.83-0.47,1.31-1.29,1.32c-0.81,0.01-1.48-0.48-1.35-1.27
	C270.25,621.53,270.95,621.14,271.66,620.41z"
              />
              <path
                d="M329.06,555.89c-1.02,0.46-1.59,0.92-2.16,0.92c-0.8,0-1.25-0.66-1.1-1.48c0.15-0.82,0.79-1.3,1.55-1.04
	C327.88,554.48,328.25,555.11,329.06,555.89z"
              />
              <path
                d="M371.3,291.96c-1.01,0.58-1.56,1.15-2.08,1.13c-0.39-0.02-1.03-0.82-1.02-1.25c0.02-0.43,0.72-1.19,1.1-1.17
	C369.83,290.69,370.33,291.29,371.3,291.96z"
              />
              <path
                d="M349.24,579.05c-0.92,0.63-1.39,1.17-1.95,1.28c-0.81,0.16-1.35-0.47-1.35-1.25c0-0.78,0.5-1.43,1.33-1.27
	C347.83,577.9,348.31,578.43,349.24,579.05z"
              />
              <path
                d="M479.29,622.09c-0.75,0.55-1.18,1.11-1.68,1.17c-0.8,0.1-1.35-0.46-1.31-1.31c0.04-0.84,0.63-1.36,1.42-1.18
	C478.22,620.88,478.59,621.47,479.29,622.09z"
              />
              <path
                d="M512.02,349.74c0.56,0.91,1.09,1.41,1.15,1.97c0.09,0.82-0.55,1.25-1.37,1.21c-0.83-0.05-1.4-0.6-1.22-1.37
	C510.69,351.01,511.31,350.58,512.02,349.74z"
              />
              <path
                d="M461.15,599.74c0.69,0.85,1.31,1.28,1.43,1.82c0.16,0.77-0.41,1.32-1.24,1.35c-0.83,0.04-1.46-0.41-1.36-1.22
	C460.04,601.14,460.58,600.64,461.15,599.74z"
              />
              <path
                d="M489.85,245.37c-0.74,0.57-1.16,1.13-1.65,1.21c-0.8,0.13-1.36-0.43-1.34-1.28c0.02-0.85,0.61-1.37,1.39-1.21
	C488.74,244.19,489.14,244.77,489.85,245.37z"
              />
              <path
                d="M314.06,70.74c0.65,0.84,1.28,1.32,1.33,1.84c0.03,0.4-0.61,1.12-1.08,1.24c-0.81,0.22-1.4-0.41-1.33-1.21
	C313.03,72.1,313.52,71.64,314.06,70.74z"
              />
              <path
                d="M318.53,599.79c-0.97,0.58-1.47,1.1-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C317.19,598.56,317.63,599.12,318.53,599.79z"
              />
              <path
                d="M345.4,575.76c-0.95,0.6-1.45,1.12-2.01,1.2c-0.81,0.12-1.32-0.51-1.29-1.31c0.03-0.79,0.57-1.4,1.38-1.22
	C344.04,574.56,344.49,575.11,345.4,575.76z"
              />
              <path
                d="M337.21,316.51c-1.02,0.54-1.59,1.08-2.12,1.04c-0.38-0.03-1-0.86-0.96-1.3c0.03-0.43,0.78-1.16,1.15-1.13
	C335.81,315.18,336.29,315.81,337.21,316.51z"
              />
              <path
                d="M487.19,628.6c-0.94,0.56-1.46,1.09-2.02,1.15c-0.8,0.09-1.29-0.51-1.23-1.35c0.06-0.84,0.62-1.37,1.41-1.17
	C485.89,627.36,486.33,627.94,487.19,628.6z"
              />
              <path d="M568.61,99.68c0.53,0.92,0.97,1.7,1.46,2.55c-0.83,0.71-1.51,0.55-2.06-0.15C567.38,101.3,567.29,100.52,568.61,99.68z" />
              <path
                d="M482.39,238.96c-0.94,0.57-1.45,1.1-2.01,1.16c-0.8,0.1-1.3-0.5-1.25-1.34c0.05-0.84,0.61-1.37,1.4-1.18
	C481.08,237.73,481.53,238.3,482.39,238.96z"
              />
              <path
                d="M450.02,617.11c-0.7,0.63-1.07,1.22-1.55,1.34c-0.8,0.2-1.38-0.33-1.43-1.17c-0.06-0.86,0.52-1.4,1.3-1.32
	C448.83,616.01,449.26,616.57,450.02,617.11z"
              />
              <path
                d="M379.99,99.76c-1.01,0.6-1.56,1.18-2.09,1.16c-0.43-0.01-1.1-0.68-1.19-1.15c-0.15-0.78,0.41-1.46,1.26-1.32
	C378.53,98.55,379.01,99.1,379.99,99.76z"
              />
              <path
                d="M323.2,554.06c-0.59-0.9-1.16-1.39-1.22-1.94c-0.07-0.77,0.53-1.28,1.38-1.23c0.83,0.04,1.4,0.57,1.22,1.36
	C324.46,552.79,323.86,553.23,323.2,554.06z"
              />
              <path
                d="M325,592.01c-0.89,0.61-1.37,1.16-1.93,1.27c-0.8,0.15-1.33-0.42-1.33-1.25c0-0.83,0.51-1.42,1.31-1.27
	C323.62,590.86,324.1,591.41,325,592.01z"
              />
              <path
                d="M289.28,349.57c-0.76,0.54-1.2,1.09-1.69,1.15c-0.79,0.09-1.35-0.47-1.29-1.33c0.05-0.84,0.66-1.35,1.44-1.16
	C288.21,348.34,288.58,348.94,289.28,349.57z"
              />
              <path
                d="M359.78,368.54c-0.95,0.69-1.44,1.3-1.97,1.34c-0.38,0.02-1.1-0.72-1.13-1.15c-0.02-0.43,0.61-1.24,0.99-1.27
	C358.2,367.42,358.77,367.98,359.78,368.54z"
              />
              <path
                d="M402.57,131.69c0.54,0.91,1.12,1.46,1.1,1.99c-0.01,0.4-0.76,1.04-1.23,1.09c-0.81,0.09-1.35-0.59-1.17-1.39
	C401.38,132.89,401.94,132.49,402.57,131.69z"
              />
              <path
                d="M492.04,250.56c-0.63-0.87-1.25-1.36-1.28-1.88c-0.02-0.4,0.64-1.11,1.11-1.21c0.81-0.19,1.4,0.43,1.3,1.25
	C493.11,249.22,492.6,249.68,492.04,250.56z"
              />
              <path
                d="M321.87,556.13c-0.94,0.7-1.43,1.32-1.95,1.36c-0.38,0.03-1.11-0.71-1.14-1.14c-0.03-0.43,0.59-1.25,0.98-1.28
	C320.28,555.03,320.85,555.58,321.87,556.13z"
              />
              <path
                d="M348.76,571.96c-0.97,0.58-1.47,1.09-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C347.42,570.73,347.87,571.29,348.76,571.96z"
              />
              <path
                d="M495.85,602.63c-0.98,0.59-1.52,1.16-2.06,1.16c-0.41,0-1.07-0.68-1.17-1.16c-0.18-0.81,0.42-1.45,1.25-1.31
	C494.43,601.42,494.92,601.99,495.85,602.63z"
              />
              <path
                d="M337.35,628.53c0.69,0.85,1.32,1.28,1.43,1.82c0.16,0.77-0.41,1.32-1.24,1.35c-0.83,0.04-1.46-0.41-1.36-1.22
	C336.24,629.93,336.78,629.43,337.35,628.53z"
              />
              <path
                d="M279.42,314.14c-0.88,0.63-1.34,1.19-1.9,1.31c-0.79,0.17-1.34-0.38-1.36-1.22c-0.02-0.83,0.47-1.43,1.28-1.31
	C278,313.02,278.5,313.55,279.42,314.14z"
              />
              <path
                d="M490.04,601.12c-0.63-0.93-1.21-1.42-1.27-1.98c-0.1-0.83,0.58-1.39,1.39-1.18c0.49,0.13,1.17,0.78,1.16,1.18
	C491.3,599.68,490.69,600.19,490.04,601.12z"
              />
              <path
                d="M493.67,620.9c-0.97,0.58-1.47,1.1-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C492.33,619.67,492.78,620.23,493.67,620.9z"
              />
              <path d="M239,131.37c0.37-1.14,0.64-1.98,0.91-2.78c1.17-0.16,1.46,0.54,1.4,1.25C241.23,130.77,240.61,131.35,239,131.37z" />
              <path
                d="M295.73,627.11c-0.92,0.58-1.42,1.12-1.98,1.2c-0.8,0.12-1.31-0.48-1.28-1.31c0.03-0.83,0.57-1.39,1.37-1.21
	C294.4,625.91,294.86,626.47,295.73,627.11z"
              />
              <path
                d="M518.11,255.22c0.63,0.86,1.21,1.32,1.3,1.87c0.14,0.79-0.45,1.29-1.29,1.3c-0.85,0.01-1.43-0.53-1.33-1.29
	C516.86,256.56,517.47,256.09,518.11,255.22z"
              />
              <path
                d="M436.18,186.56c0.71,0.9,1.35,1.37,1.41,1.91c0.04,0.39-0.62,1.1-1.1,1.24c-0.77,0.24-1.51-0.24-1.45-1.1
	C435.08,188.05,435.61,187.53,436.18,186.56z"
              />
              <path
                d="M509.38,360.78c0.57,0.91,1.14,1.42,1.18,1.97c0.06,0.78-0.57,1.26-1.4,1.21c-0.82-0.05-1.4-0.58-1.2-1.38
	C508.09,362.04,508.7,361.61,509.38,360.78z"
              />
              <path
                d="M333.16,558.83c-0.87,0.65-1.32,1.22-1.87,1.34c-0.78,0.18-1.35-0.35-1.39-1.19c-0.04-0.83,0.45-1.44,1.25-1.33
	C331.72,557.72,332.22,558.25,333.16,558.83z"
              />
              <path
                d="M320.57,604.92c-0.7-0.83-1.34-1.26-1.46-1.79c-0.16-0.75,0.37-1.33,1.22-1.37c0.83-0.04,1.46,0.39,1.38,1.2
	C321.65,603.51,321.12,604.02,320.57,604.92z"
              />
              <path
                d="M238.44,260.07c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C237.01,258.83,237.48,259.39,238.44,260.07z"
              />
              <path
                d="M452.55,206.75c0.54,0.92,1.06,1.43,1.11,1.99c0.07,0.83-0.58,1.24-1.4,1.18c-0.83-0.06-1.38-0.62-1.19-1.39
	C451.21,207.99,451.84,207.58,452.55,206.75z"
              />
              <path
                d="M241.86,405.83c-0.71-0.9-1.36-1.36-1.44-1.9c-0.11-0.77,0.5-1.44,1.34-1.25c0.49,0.11,1.19,0.71,1.21,1.12
	C242.98,404.32,242.42,404.87,241.86,405.83z"
              />
              <path
                d="M521.77,258.53c0.73,0.89,1.35,1.32,1.47,1.87c0.17,0.81-0.42,1.32-1.28,1.34c-0.75,0.02-1.34-0.42-1.28-1.11
	C520.72,260.06,521.22,259.53,521.77,258.53z"
              />
              <path
                d="M390.57,327.95c0.66,0.78,1.23,1.16,1.35,1.66c0.2,0.79-0.31,1.49-1.13,1.42c-0.47-0.04-1.24-0.65-1.27-1.05
	C389.5,329.45,390.05,328.88,390.57,327.95z"
              />
              <path
                d="M519.15,329.65c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C517.72,328.41,518.19,328.97,519.15,329.65z"
              />
              <path
                d="M241.58,188.96c0.6,0.96,1.17,1.48,1.21,2.03c0.06,0.83-0.67,1.36-1.45,1.13c-0.47-0.14-1.12-0.83-1.1-1.24
	C240.27,190.36,240.9,189.87,241.58,188.96z"
              />
              <path
                d="M425.29,330.63c-0.95,0.56-1.47,1.08-2.03,1.14c-0.8,0.08-1.28-0.52-1.22-1.36c0.06-0.84,0.63-1.36,1.41-1.16
	C424,329.39,424.43,329.96,425.29,330.63z"
              />
              <path
                d="M487.95,595.76c-0.96,0.68-1.43,1.21-1.99,1.35c-0.7,0.17-1.23-0.35-1.31-1.07c-0.09-0.83,0.33-1.5,1.18-1.43
	C486.4,594.66,486.92,595.18,487.95,595.76z"
              />
              <path
                d="M651.36,482.38c0.75-0.16,1.49-0.19,1.48,0.85c-0.01,0.92,0.51,2.12-0.99,2.47c0,0,0.07,0.05,0.07,0.05
	c-0.86-1.05-0.99-2.2-0.49-3.44L651.36,482.38z"
              />
              <path
                d="M290.48,443.32c-0.97,0.59-1.51,1.17-2.05,1.17c-0.41,0-1.07-0.68-1.18-1.15c-0.19-0.81,0.42-1.45,1.24-1.32
	C289.05,442.11,289.54,442.68,290.48,443.32z"
              />
              <path
                d="M408.8,329.86c0.66,0.78,1.23,1.16,1.35,1.66c0.2,0.79-0.31,1.49-1.13,1.42c-0.47-0.04-1.24-0.65-1.27-1.05
	C407.73,331.37,408.29,330.8,408.8,329.86z"
              />
              <path
                d="M376.82,356.48c-0.92,0.58-1.42,1.12-1.99,1.2c-0.8,0.12-1.31-0.48-1.28-1.31c0.03-0.83,0.57-1.39,1.37-1.21
	C375.49,355.28,375.95,355.84,376.82,356.48z"
              />
              <path
                d="M327.91,580.92c-0.93,0.65-1.44,1.25-1.97,1.28c-0.41,0.02-1.11-0.6-1.25-1.06c-0.24-0.8,0.32-1.48,1.15-1.4
	C326.4,579.78,326.92,580.33,327.91,580.92z"
              />
              <path
                d="M421.6,140.97c0.56,0.99,1.1,1.52,1.12,2.08c0.03,0.83-0.71,1.33-1.48,1.08c-0.47-0.15-1.09-0.87-1.05-1.27
	C420.24,142.33,420.88,141.87,421.6,140.97z"
              />
              <path
                d="M302.47,373.84c-1.01,0.55-1.56,1.08-2.13,1.1c-0.82,0.03-1.37-0.66-1.08-1.46c0.16-0.46,0.89-1.05,1.3-1.01
	C301.09,372.53,301.57,373.15,302.47,373.84z"
              />
              <path
                d="M346.45,194.4c-0.65-0.79-1.21-1.19-1.32-1.69c-0.19-0.8,0.35-1.48,1.16-1.4c0.47,0.05,1.23,0.68,1.24,1.08
	C347.55,192.92,346.98,193.48,346.45,194.4z"
              />
              <path
                d="M263.81,427.74c-1,0.59-1.55,1.16-2.07,1.14c-0.38-0.01-1.04-0.81-1.03-1.24c0.01-0.43,0.71-1.19,1.09-1.18
	C262.33,426.48,262.84,427.08,263.81,427.74z"
              />
              <path
                d="M433.1,194.23c-0.71-0.9-1.33-1.34-1.43-1.89c-0.16-0.81,0.44-1.31,1.3-1.32c0.75-0.01,1.32,0.45,1.26,1.13
	C434.18,192.72,433.67,193.24,433.1,194.23z"
              />
              <path
                d="M508.55,373.75c-0.95,0.6-1.45,1.12-2.01,1.2c-0.81,0.12-1.32-0.51-1.29-1.31c0.03-0.79,0.57-1.4,1.38-1.22
	C507.18,372.55,507.64,373.1,508.55,373.75z"
              />
              <path
                d="M484.12,632.43c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C482.69,631.18,483.16,631.75,484.12,632.43z"
              />
              <path
                d="M458.4,172.17c-0.9,0.6-1.38,1.15-1.95,1.25c-0.8,0.14-1.32-0.44-1.32-1.27c0.01-0.83,0.53-1.41,1.33-1.25
	C457.03,171.01,457.51,171.56,458.4,172.17z"
              />
              <path
                d="M488.52,622.77c0.68,0.85,1.31,1.29,1.41,1.83c0.15,0.77-0.41,1.31-1.25,1.34c-0.83,0.03-1.45-0.42-1.35-1.24
	C487.39,624.16,487.94,623.67,488.52,622.77z"
              />
              <path
                d="M293.09,432.09c-0.88,0.69-1.32,1.27-1.86,1.4c-0.78,0.19-1.37-0.36-1.43-1.17c-0.05-0.8,0.41-1.45,1.23-1.35
	C291.6,431.04,292.12,431.54,293.09,432.09z"
              />
              <path
                d="M479.35,605.62c-0.52-0.93-1.08-1.49-1.05-2.02c0.02-0.4,0.79-1.02,1.26-1.06c0.81-0.07,1.33,0.63,1.14,1.42
	C480.57,604.45,480.01,604.83,479.35,605.62z"
              />
              <path
                d="M436.6,197.54c-0.53-0.92-1.05-1.44-1.09-1.99c-0.07-0.82,0.59-1.23,1.41-1.17c0.85,0.06,1.37,0.65,1.18,1.4
	C437.96,196.32,437.32,196.73,436.6,197.54z"
              />
              <path
                d="M452.25,373.04c0.65,0.84,1.28,1.32,1.33,1.84c0.03,0.4-0.61,1.12-1.08,1.24c-0.81,0.22-1.4-0.41-1.33-1.21
	C451.22,374.4,451.72,373.93,452.25,373.04z"
              />
              <path
                d="M446.21,614.12c-1.01,0.61-1.54,1.19-2.07,1.18c-0.43-0.01-1.11-0.66-1.21-1.13c-0.16-0.78,0.4-1.47,1.24-1.33
	C444.74,612.93,445.23,613.48,446.21,614.12z"
              />
              <path
                d="M398.32,334.35c0.69,0.92,1.32,1.39,1.39,1.94c0.1,0.78-0.55,1.42-1.37,1.23c-0.48-0.11-1.18-0.74-1.18-1.15
	C397.15,335.84,397.73,335.31,398.32,334.35z"
              />
              <path
                d="M376.59,269.46c-0.89,0.68-1.34,1.25-1.89,1.37c-0.79,0.18-1.37-0.38-1.41-1.19c-0.04-0.79,0.43-1.44,1.26-1.33
	C375.12,268.38,375.62,268.89,376.59,269.46z"
              />
              <path
                d="M470.39,601.08c-0.92,0.63-1.43,1.23-1.96,1.26c-0.41,0.02-1.1-0.61-1.23-1.07c-0.23-0.79,0.33-1.47,1.15-1.38
	C468.92,599.96,469.43,600.5,470.39,601.08z"
              />
              <path
                d="M327.97,564.59c-0.66-0.86-1.3-1.34-1.34-1.86c-0.03-0.39,0.65-1.07,1.13-1.2c0.84-0.22,1.43,0.42,1.37,1.22
	C329.09,563.25,328.55,563.71,327.97,564.59z"
              />
              <path
                d="M312.86,535.4c-0.77,0.57-1.18,1.09-1.68,1.2c-0.83,0.18-1.38-0.37-1.38-1.21c0-0.83,0.55-1.39,1.38-1.2
	C311.68,534.3,312.09,534.82,312.86,535.4z"
              />
              <path
                d="M642.77,633c-0.8,0.57-1.21,1.05-1.71,1.18c-0.7,0.18-1.24-0.24-1.31-1c-0.08-0.85,0.42-1.44,1.23-1.36
	C641.48,631.87,641.93,632.41,642.77,633z"
              />
              <path
                d="M311.34,473.92c-0.95,0.65-1.42,1.16-1.98,1.29c-0.7,0.16-1.2-0.36-1.26-1.08c-0.07-0.8,0.33-1.48,1.18-1.38
	C309.84,472.81,310.34,473.33,311.34,473.92z"
              />
              <path
                d="M377.16,110.65c-0.75,0.62-1.13,1.16-1.63,1.29c-0.81,0.21-1.41-0.28-1.44-1.14c-0.04-0.85,0.48-1.42,1.32-1.27
	C375.92,109.61,376.35,110.11,377.16,110.65z"
              />
              <path
                d="M400.05,272.84c-0.6-0.86-1.13-1.29-1.19-1.77c-0.11-0.8,0.43-1.44,1.27-1.26c0.47,0.11,1.16,0.75,1.15,1.13
	C401.27,271.45,400.67,271.94,400.05,272.84z"
              />
              <path
                d="M289.91,269.86c-0.8,0.69-1.17,1.26-1.67,1.4c-0.8,0.22-1.52-0.25-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C288.35,268.82,288.93,269.36,289.91,269.86z"
              />
              <path
                d="M445.49,337.67c-0.66-0.9-1.26-1.37-1.33-1.9c-0.11-0.79,0.52-1.4,1.32-1.2c0.48,0.11,1.17,0.73,1.17,1.12
	C446.66,336.2,446.09,336.72,445.49,337.67z"
              />
              <path
                d="M481.43,627.01c-0.63-0.92-1.21-1.39-1.28-1.94c-0.1-0.81,0.55-1.38,1.35-1.17c0.48,0.12,1.15,0.76,1.15,1.15
	C482.64,625.57,482.05,626.08,481.43,627.01z"
              />
              <path
                d="M322.42,563.09c-0.72,0.63-1.08,1.19-1.56,1.32c-0.78,0.22-1.39-0.25-1.45-1.09c-0.06-0.83,0.43-1.41,1.26-1.29
	C321.17,562.1,321.61,562.58,322.42,563.09z"
              />
              <path
                d="M381.66,270.99c-0.54-0.99-1.04-1.51-1.09-2.07c-0.06-0.68,0.51-1.13,1.26-1.11c0.86,0.02,1.44,0.52,1.27,1.33
	C383,269.67,382.38,270.1,381.66,270.99z"
              />
              <path d="M300.2,634.03c-0.22-1.12,0.16-1.84,1.2-1.89c1.02-0.05,1.47,0.65,1.48,1.89C301.96,634.03,301.11,634.03,300.2,634.03z" />
              <path
                d="M490.29,392c0.28,0.32,0.85,0.7,0.97,1.18c0.2,0.79-0.26,1.49-1.13,1.38c-0.49-0.06-1.16-0.57-1.31-1.02
	C488.56,392.74,489.15,392.25,490.29,392z"
              />
              <path
                d="M434.46,623.76c0.59,0.87,1.11,1.32,1.17,1.81c0.09,0.81-0.49,1.44-1.32,1.23c-0.47-0.12-1.16-0.78-1.14-1.17
	C433.2,625.13,433.82,624.64,434.46,623.76z"
              />
              <path
                d="M268.15,271.79c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.1-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C267.07,270.61,267.45,271.19,268.15,271.79z"
              />
              <path
                d="M294.97,619.66c-0.82,0.7-1.2,1.26-1.69,1.4c-0.81,0.23-1.51-0.27-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C293.39,618.62,293.97,619.15,294.97,619.66z"
              />
              <path
                d="M264.54,268.73c-0.99,0.55-1.55,1.11-2.09,1.09c-0.41-0.02-1.04-0.72-1.12-1.2c-0.14-0.8,0.48-1.42,1.3-1.25
	C263.18,267.49,263.64,268.06,264.54,268.73z"
              />
              <path
                d="M245.34,192.4c0.6,0.86,1.12,1.28,1.19,1.76c0.12,0.8-0.42,1.43-1.25,1.25c-0.47-0.1-1.16-0.73-1.15-1.11
	C244.15,193.79,244.74,193.3,245.34,192.4z"
              />
              <path
                d="M286.35,373.11c0.59,0.8,1.08,1.22,1.23,1.74c0.19,0.69-0.67,1.52-1.32,1.26c-0.47-0.19-1.02-0.82-1.05-1.29
	C285.19,374.38,285.79,373.9,286.35,373.11z"
              />
              <path
                d="M257.62,442.44c-0.75,0.64-1.13,1.16-1.63,1.33c-0.67,0.23-1.55-0.59-1.33-1.26c0.17-0.49,0.74-1.11,1.21-1.17
	C256.29,441.29,256.81,441.9,257.62,442.44z"
              />
              <path
                d="M379.1,92.56c-0.8,0.55-1.23,1.06-1.74,1.16c-0.84,0.16-1.38-0.4-1.36-1.26c0.02-0.86,0.61-1.38,1.43-1.18
	C377.94,91.41,378.34,91.95,379.1,92.56z"
              />
              <path
                d="M315.13,477.48c-0.98,0.52-1.55,1.07-2.08,1.03c-0.41-0.03-1.03-0.77-1.07-1.24c-0.07-0.82,0.59-1.37,1.42-1.15
	C313.9,476.24,314.29,476.79,315.13,477.48z"
              />
              <path
                d="M488.91,397.29c-1.07,0.57-1.63,1.12-2.15,1.08c-0.4-0.03-1.05-0.81-1.03-1.22c0.02-0.42,0.74-1.12,1.15-1.12
	C487.4,396.04,487.91,396.63,488.91,397.29z"
              />
              <path
                d="M443.34,625.27c-0.81,0.5-1.25,0.98-1.75,1.04c-0.82,0.11-1.32-0.46-1.26-1.29c0.07-0.83,0.66-1.31,1.45-1.09
	C442.26,624.07,442.62,624.63,443.34,625.27z"
              />
              <path
                d="M451.04,339.29c-0.82,0.51-1.27,0.99-1.77,1.05c-0.83,0.11-1.34-0.45-1.27-1.3c0.07-0.85,0.67-1.32,1.46-1.1
	C449.95,338.08,450.31,338.64,451.04,339.29z"
              />
              <path
                d="M310.47,197.45c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C311.54,196.2,311.02,196.64,310.47,197.45z"
              />
              <path
                d="M650.13,632.26c-0.96,0.65-1.44,1.17-2,1.3c-0.7,0.16-1.21-0.37-1.27-1.09c-0.07-0.8,0.34-1.49,1.19-1.39
	C648.61,631.15,649.12,631.67,650.13,632.26z"
              />
              <path
                d="M483.92,612.28c0.66,0.84,1.29,1.3,1.34,1.81c0.04,0.38-0.62,1.06-1.09,1.18c-0.8,0.22-1.4-0.36-1.33-1.17
	C482.88,613.61,483.37,613.16,483.92,612.28z"
              />
              <path
                d="M287.18,427.06c0.66,0.84,1.29,1.3,1.34,1.81c0.04,0.38-0.62,1.06-1.09,1.18c-0.8,0.22-1.4-0.36-1.33-1.17
	C286.14,428.39,286.64,427.94,287.18,427.06z"
              />
              <path
                d="M352.74,272.79c-0.23-0.28-0.79-0.67-0.9-1.15c-0.18-0.8,0.31-1.48,1.17-1.36c0.48,0.07,1.14,0.6,1.27,1.05
	C354.52,272.14,353.95,272.64,352.74,272.79z"
              />
              <path
                d="M369.5,356.86c-0.77,0.59-1.17,1.13-1.68,1.25c-0.82,0.19-1.41-0.32-1.42-1.19c-0.01-0.85,0.53-1.42,1.37-1.25
	C368.28,355.78,368.71,356.3,369.5,356.86z"
              />
              <path
                d="M513.86,331.55c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C512.7,332.79,513.22,332.39,513.86,331.55z"
              />
              <path
                d="M265.5,196.32c-1.01,0.53-1.58,1.08-2.11,1.04c-0.41-0.03-1.03-0.74-1.1-1.22c-0.12-0.8,0.51-1.41,1.32-1.22
	C264.17,195.04,264.61,195.63,265.5,196.32z"
              />
              <path
                d="M468.79,626.57c-0.77,0.57-1.18,1.08-1.68,1.19c-0.82,0.18-1.38-0.37-1.37-1.2c0-0.83,0.55-1.38,1.38-1.2
	C467.62,625.47,468.03,625.99,468.79,626.57z"
              />
              <path
                d="M318.81,579.57c0.6,0.95,1.17,1.47,1.17,1.99c0,0.4-0.69,1.01-1.17,1.12c-0.8,0.19-1.44-0.4-1.33-1.2
	C317.56,580.94,318.15,580.48,318.81,579.57z"
              />
              <path
                d="M277.98,339.66c-0.96,0.59-1.49,1.16-2.03,1.17c-0.41,0-1.06-0.67-1.17-1.14c-0.19-0.8,0.4-1.44,1.23-1.31
	C276.57,338.48,277.05,339.04,277.98,339.66z"
              />
              <path
                d="M258.84,263.4c0.6,0.88,1.14,1.32,1.19,1.82c0.09,0.81-0.5,1.45-1.34,1.24c-0.48-0.12-1.17-0.79-1.15-1.17
	C257.57,264.76,258.2,264.27,258.84,263.4z"
              />
              <path
                d="M394.79,331.55c0.28,0.32,0.84,0.69,0.96,1.17c0.21,0.79-0.26,1.48-1.12,1.37c-0.48-0.06-1.15-0.56-1.3-1.01
	C393.08,332.29,393.67,331.8,394.79,331.55z"
              />
              <path
                d="M456.28,356.72c-0.8,0.57-1.23,1.05-1.74,1.18c-0.66,0.16-1.5-0.79-1.21-1.38c0.23-0.47,0.85-1.02,1.32-1.04
	C455.06,355.45,455.52,356.11,456.28,356.72z"
              />
              <path
                d="M520.54,264.16c-0.96,0.67-1.42,1.19-1.97,1.32c-0.71,0.17-1.28-0.4-1.17-1.09c0.08-0.48,0.61-1.22,0.99-1.26
	C518.92,263.09,519.48,263.61,520.54,264.16z"
              />
              <path
                d="M443.4,332.48c-0.8,0.55-1.23,1.06-1.74,1.16c-0.84,0.16-1.38-0.4-1.36-1.26c0.02-0.86,0.61-1.38,1.43-1.18
	C442.24,331.33,442.64,331.87,443.4,332.48z"
              />
              <path
                d="M293.38,376.07c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C294.57,374.59,293.98,375.12,293.38,376.07z"
              />
              <path
                d="M308.11,481.23c0.75-0.6,1.13-1.13,1.62-1.25c0.81-0.2,1.37,0.33,1.39,1.16c0.02,0.82-0.5,1.38-1.32,1.23
	C309.31,482.27,308.89,481.77,308.11,481.23z"
              />
              <path
                d="M282.22,433.06c0.75-0.63,1.19-1.29,1.61-1.28c0.47,0.01,1.1,0.54,1.34,1.01c0.3,0.59-0.52,1.56-1.18,1.41
	C283.47,434.08,283.03,433.6,282.22,433.06z"
              />
              <path
                d="M493.13,613.41c-0.81,0.7-1.18,1.26-1.67,1.4c-0.8,0.23-1.5-0.25-1.44-1.09c0.04-0.47,0.61-1.23,1.01-1.27
	C491.56,612.39,492.14,612.92,493.13,613.41z"
              />
              <path
                d="M328.88,579.04c-0.54-0.99-1.04-1.51-1.09-2.07c-0.06-0.68,0.51-1.13,1.26-1.11c0.86,0.02,1.44,0.52,1.27,1.33
	C330.22,577.72,329.6,578.16,328.88,579.04z"
              />
              <path
                d="M441.94,191.8c-0.76,0.59-1.16,1.12-1.66,1.24c-0.82,0.19-1.4-0.33-1.41-1.18c-0.01-0.84,0.52-1.41,1.36-1.24
	C440.73,190.72,441.15,191.24,441.94,191.8z"
              />
              <path
                d="M462.1,610.59c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C463.17,609.34,462.65,609.79,462.1,610.59z"
              />
              <path
                d="M489.24,610.64c-1.02,0.38-1.64,0.83-2.15,0.73c-0.38-0.08-0.86-0.88-0.86-1.35c0.01-0.86,0.76-1.24,1.52-0.94
	C488.21,609.27,488.52,609.86,489.24,610.64z"
              />
              <path
                d="M454.33,168.97c-0.7,0.61-1.08,1.19-1.56,1.3c-0.79,0.18-1.36-0.35-1.4-1.18c-0.05-0.85,0.53-1.38,1.31-1.29
	C453.16,167.87,453.58,168.42,454.33,168.97z"
              />
              <path
                d="M485.53,401.05c-1.03,0.6-1.56,1.16-2.08,1.14c-0.4-0.01-1.08-0.76-1.08-1.17c0.01-0.42,0.69-1.15,1.09-1.16
	C483.99,399.87,484.52,400.44,485.53,401.05z"
              />
              <path
                d="M343.49,221.96c-0.94,0.67-1.42,1.27-1.94,1.3c-0.42,0.02-1.12-0.57-1.26-1.02c-0.23-0.75,0.26-1.47,1.1-1.4
	C341.95,220.88,342.47,221.39,343.49,221.96z"
              />
              <path
                d="M332.06,567.41c-1.23-0.12-1.82-0.6-1.6-1.42c0.12-0.46,0.77-1.01,1.26-1.1c0.86-0.15,1.38,0.53,1.23,1.33
	C332.85,566.72,332.29,567.12,332.06,567.41z"
              />
              <path
                d="M465.05,606.87c-0.6-0.94-1.16-1.44-1.22-1.98c-0.08-0.83,0.61-1.37,1.39-1.14c0.48,0.14,1.15,0.8,1.13,1.19
	C466.33,605.46,465.71,605.95,465.05,606.87z"
              />
              <path
                d="M489.11,633.74c-0.64-0.92-1.22-1.4-1.28-1.95c-0.1-0.82,0.56-1.39,1.36-1.18c0.48,0.12,1.17,0.76,1.16,1.16
	C490.34,632.29,489.73,632.8,489.11,633.74z"
              />
              <path
                d="M416.43,329.66c0.24,0.28,0.81,0.65,0.93,1.14c0.2,0.79-0.26,1.49-1.13,1.38c-0.49-0.06-1.16-0.57-1.3-1.02
	C414.68,330.37,415.22,329.84,416.43,329.66z"
              />
              <path
                d="M486.99,601.72c0.27,0.36,0.89,0.85,0.92,1.38c0.02,0.38-0.72,1.1-1.15,1.13c-0.43,0.03-1.16-0.54-1.3-1
	C485.2,602.43,485.75,601.9,486.99,601.72z"
              />
              <path
                d="M453.1,344.06c-0.58-0.81-1.08-1.23-1.22-1.75c-0.19-0.68,0.62-1.4,1.34-1.29c0.73,0.11,1.23,0.6,1.06,1.31
	C454.16,342.84,453.66,343.26,453.1,344.06z"
              />
              <path
                d="M326.28,566.71c-0.81,0.52-1.25,1-1.75,1.07c-0.83,0.12-1.34-0.44-1.28-1.29c0.06-0.85,0.66-1.33,1.45-1.11
	C325.18,565.52,325.55,566.08,326.28,566.71z"
              />
              <path
                d="M319.04,567.38c-0.81,0.56-1.24,1.04-1.75,1.17c-0.66,0.16-1.5-0.8-1.21-1.39c0.23-0.47,0.86-1.02,1.33-1.03
	C317.84,566.11,318.28,566.76,319.04,567.38z"
              />
              <path
                d="M364.77,358.9c0.5,0.96,1.03,1.53,0.98,2.04c-0.04,0.38-0.8,0.93-1.28,0.98c-0.87,0.1-1.31-0.61-1.1-1.39
	C363.51,360.05,364.07,359.69,364.77,358.9z"
              />
              <path
                d="M344.65,402.6c-0.98,0.55-1.53,1.11-2.06,1.08c-0.41-0.02-1.03-0.71-1.12-1.18c-0.14-0.79,0.46-1.42,1.28-1.25
	C343.3,401.37,343.76,401.94,344.65,402.6z"
              />
              <path
                d="M316.08,428.29c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C317.29,426.91,316.69,427.41,316.08,428.29z"
              />
              <path
                d="M324.98,632.18c-1.06,0.44-1.64,0.89-2.2,0.87c-0.85-0.03-1.27-0.8-0.94-1.54c0.2-0.44,0.98-0.95,1.39-0.88
	C323.74,630.74,324.15,631.41,324.98,632.18z"
              />
              <path
                d="M256.59,262c-0.93,0.69-1.42,1.3-1.94,1.34c-0.37,0.03-1.09-0.7-1.12-1.13c-0.03-0.42,0.59-1.23,0.97-1.26
	C255.02,260.91,255.58,261.45,256.59,262z"
              />
              <path
                d="M447.35,192.91c-0.59-0.8-1.09-1.22-1.24-1.74c-0.2-0.67,0.61-1.41,1.33-1.31c0.73,0.1,1.24,0.58,1.07,1.3
	C448.41,191.68,447.91,192.1,447.35,192.91z"
              />
              <path
                d="M469.3,610.1c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C470.36,608.86,469.84,609.3,469.3,610.1z"
              />
              <path
                d="M307.06,483.66c0.27,0.34,0.81,0.74,0.91,1.23c0.16,0.81-0.36,1.47-1.21,1.32c-0.48-0.08-1.12-0.63-1.25-1.09
	C305.3,484.31,305.92,483.86,307.06,483.66z"
              />
              <path
                d="M371.15,172.17c-0.04,1.21-0.46,1.7-1.31,1.71c-0.75,0.01-1.3-0.45-1.19-1.13c0.07-0.49,0.62-1.23,1-1.26
	C370.16,171.43,370.73,171.96,371.15,172.17z"
              />
              <path
                d="M456.73,403.67c-0.72,0.58-1.12,1.15-1.61,1.24c-0.79,0.14-1.35-0.4-1.36-1.23c-0.01-0.84,0.57-1.37,1.35-1.24
	C455.6,402.53,456,403.1,456.73,403.67z"
              />
              <path
                d="M433.37,154.31c-0.7-0.8-1.26-1.16-1.38-1.64c-0.2-0.78,0.25-1.48,1.11-1.38c0.48,0.06,1.23,0.62,1.27,1
	C434.41,152.8,433.88,153.37,433.37,154.31z"
              />
              <path
                d="M493.38,387.46c0.6,0.82,1.12,1.24,1.21,1.74c0.15,0.8-0.42,1.44-1.21,1.33c-0.46-0.07-1.18-0.74-1.17-1.14
	C492.22,388.86,492.81,388.34,493.38,387.46z"
              />
              <path
                d="M285.39,425.8c-0.99,0.6-1.52,1.16-2.04,1.15c-0.38-0.01-1.03-0.79-1.02-1.22c0.01-0.43,0.7-1.19,1.07-1.18
	C283.92,424.57,284.43,425.16,285.39,425.8z"
              />
              <path
                d="M475.27,618.59c-0.77,0.61-1.16,1.11-1.68,1.29c-0.66,0.23-1.5-0.6-1.27-1.29c0.17-0.49,0.76-1.1,1.2-1.14
	C473.97,617.41,474.46,618.03,475.27,618.59z"
              />
              <path
                d="M317.02,558.34c0.47,0.86,1.02,1.4,0.95,1.83c-0.08,0.46-0.7,1.02-1.19,1.17c-0.64,0.19-1.45-0.79-1.18-1.42
	C315.81,559.43,316.35,559.07,317.02,558.34z"
              />
              <path
                d="M352.24,302.22c0.68,0.9,1.31,1.38,1.35,1.9c0.03,0.39-0.63,1.06-1.1,1.21c-0.77,0.23-1.47-0.27-1.41-1.1
	C351.12,303.68,351.66,303.17,352.24,302.22z"
              />
              <path
                d="M362.91,404.35c-0.92,0.63-1.43,1.23-1.96,1.26c-0.41,0.02-1.1-0.61-1.23-1.07c-0.23-0.79,0.33-1.47,1.15-1.38
	C361.44,403.22,361.95,403.76,362.91,404.35z"
              />
              <path
                d="M411.63,631.28c-0.95,0.66-1.41,1.18-1.97,1.31c-0.69,0.17-1.21-0.35-1.27-1.06c-0.08-0.8,0.31-1.48,1.16-1.4
	C410.11,630.19,410.62,630.7,411.63,631.28z"
              />
              <path
                d="M277.65,276.38c-0.66-0.74-1.19-1.11-1.4-1.61c-0.26-0.63,0.54-1.59,1.2-1.4c0.49,0.15,1.1,0.72,1.17,1.18
	C278.69,274.99,278.14,275.53,277.65,276.38z"
              />
              <path
                d="M508.39,353.63c0.67,0.85,1.3,1.31,1.35,1.82c0.04,0.38-0.63,1.06-1.09,1.19c-0.81,0.22-1.42-0.37-1.35-1.18
	C507.33,354.97,507.83,354.52,508.39,353.63z"
              />
              <path
                d="M459.15,179.48c-1.03,0.56-1.6,1.11-2.12,1.08c-0.42-0.03-1.07-0.72-1.14-1.18c-0.11-0.76,0.46-1.44,1.3-1.26
	C457.75,178.23,458.2,178.79,459.15,179.48z"
              />
              <path
                d="M357.13,272.94c0.64,0.94,1.24,1.44,1.26,1.97c0.01,0.4-0.67,1.04-1.16,1.17c-0.79,0.21-1.47-0.34-1.37-1.17
	C355.93,274.36,356.5,273.87,357.13,272.94z"
              />
              <path
                d="M482.17,404.82c-0.95,0.65-1.42,1.16-1.98,1.29c-0.7,0.16-1.2-0.36-1.26-1.08c-0.07-0.8,0.33-1.48,1.18-1.38
	C480.66,403.72,481.17,404.23,482.17,404.82z"
              />
              <path
                d="M454.81,176.3c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.11-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C453.73,175.12,454.1,175.7,454.81,176.3z"
              />
              <path
                d="M325.96,559.43c-0.96,0.59-1.49,1.17-2.03,1.17c-0.41,0-1.06-0.67-1.17-1.14c-0.19-0.8,0.4-1.44,1.23-1.31
	C324.55,558.24,325.03,558.8,325.96,559.43z"
              />
              <path
                d="M358.45,291.01c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C359.52,289.76,359,290.21,358.45,291.01z"
              />
              <path
                d="M313.86,545.61c0.83-0.56,1.28-1.1,1.76-1.14c0.77-0.06,1.42,0.52,1.22,1.33c-0.12,0.47-0.81,1.16-1.19,1.12
	C315.14,546.89,314.67,546.25,313.86,545.61z"
              />
              <path
                d="M474.99,405.51c-0.75,0.63-1.14,1.16-1.64,1.32c-0.68,0.22-1.55-0.6-1.32-1.26c0.17-0.49,0.75-1.1,1.21-1.16
	C473.67,404.35,474.18,404.96,474.99,405.51z"
              />
              <path
                d="M472.99,613.29c-0.58-0.85-1.09-1.28-1.17-1.79c-0.14-0.82,0.46-1.43,1.24-1.29c0.46,0.08,1.15,0.77,1.14,1.17
	C474.19,611.91,473.59,612.42,472.99,613.29z"
              />
              <path
                d="M262.54,436.46c-0.57-0.92-1.14-1.44-1.13-1.94c0.01-0.38,0.72-0.99,1.19-1.07c0.84-0.15,1.34,0.51,1.19,1.3
	C263.71,435.22,263.18,435.62,262.54,436.46z"
              />
              <path
                d="M283.08,633.43c-0.51-0.94-1.06-1.5-1.02-2.03c0.03-0.39,0.79-0.99,1.27-1.03c0.8-0.06,1.31,0.63,1.1,1.42
	C284.29,632.29,283.74,632.66,283.08,633.43z"
              />
              <path
                d="M286.79,626c0.47,0.85,1.02,1.4,0.95,1.83c-0.08,0.46-0.7,1.02-1.19,1.17c-0.64,0.19-1.45-0.79-1.18-1.41
	C285.58,627.09,286.12,626.73,286.79,626z"
              />
              <path
                d="M371.05,371.33c-0.95,0.58-1.48,1.16-2.01,1.16c-0.4,0-1.06-0.66-1.16-1.13c-0.18-0.79,0.4-1.43,1.22-1.3
	C369.65,370.15,370.13,370.71,371.05,371.33z"
              />
              <path
                d="M380.91,113.96c-0.76,0.54-1.19,1.09-1.68,1.14c-0.78,0.09-1.34-0.46-1.29-1.31c0.05-0.83,0.65-1.33,1.42-1.15
	C379.85,112.75,380.22,113.34,380.91,113.96z"
              />
              <path
                d="M512.97,344.54c-0.82,0.53-1.27,1.02-1.79,1.1c-0.84,0.12-1.37-0.44-1.31-1.31c0.06-0.87,0.67-1.35,1.48-1.13
	C511.85,343.34,512.23,343.9,512.97,344.54z"
              />
              <path
                d="M321.99,579.4c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C323.06,578.15,322.53,578.6,321.99,579.4z"
              />
              <path
                d="M433.22,257.69c-1.02,0.56-1.58,1.11-2.1,1.07c-0.42-0.03-1.06-0.71-1.13-1.17c-0.11-0.76,0.45-1.43,1.29-1.25
	C431.83,256.45,432.29,257.01,433.22,257.69z"
              />
              <path
                d="M344.75,259.65c-0.63-0.85-1.25-1.33-1.29-1.85c-0.03-0.4,0.63-1.1,1.09-1.21c0.8-0.19,1.39,0.41,1.29,1.22
	C345.79,258.32,345.3,258.77,344.75,259.65z"
              />
              <path
                d="M480.8,616.35c0.53,0.91,1.1,1.45,1.08,1.98c-0.01,0.39-0.76,1.02-1.23,1.07c-0.8,0.08-1.33-0.6-1.15-1.39
	C479.62,617.52,480.16,617.13,480.8,616.35z"
              />
              <path
                d="M309.72,190.5c-0.5-0.85-1.06-1.38-1-1.82c0.07-0.46,0.67-1.04,1.16-1.19c0.65-0.2,1.47,0.74,1.22,1.38
	C310.9,189.37,310.38,189.75,309.72,190.5z"
              />
              <path
                d="M426.44,328.49c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C427.65,327.11,427.05,327.6,426.44,328.49z"
              />
              <path
                d="M330.54,569.96c-1.03,0.6-1.56,1.16-2.08,1.14c-0.4-0.01-1.08-0.76-1.08-1.17c0.01-0.42,0.69-1.15,1.09-1.16
	C329,568.77,329.53,569.34,330.54,569.96z"
              />
              <path
                d="M440.95,619.82c-0.55-0.97-1.07-1.49-1.1-2.04c-0.05-0.85,0.69-1.32,1.45-1.08c0.47,0.15,1.11,0.86,1.07,1.25
	C442.31,618.48,441.67,618.94,440.95,619.82z"
              />
              <path
                d="M321.59,568.59c0.5,0.96,1.03,1.53,0.98,2.04c-0.04,0.38-0.8,0.93-1.28,0.98c-0.87,0.1-1.31-0.61-1.1-1.39
	C320.32,569.74,320.88,569.38,321.59,568.59z"
              />
              <path
                d="M416.69,632.77c-0.54-0.98-1.02-1.5-1.07-2.06c-0.06-0.68,0.5-1.12,1.25-1.1c0.85,0.02,1.43,0.52,1.26,1.32
	C418.02,631.46,417.41,631.89,416.69,632.77z"
              />
              <path
                d="M344.37,82.82c0.48,0.85,1.03,1.4,0.95,1.83c-0.08,0.46-0.69,1.02-1.18,1.17c-0.64,0.19-1.45-0.79-1.18-1.41
	C343.16,83.91,343.7,83.55,344.37,82.82z"
              />
              <path
                d="M292.35,630.83c-0.91,0.63-1.41,1.22-1.94,1.25c-0.4,0.02-1.09-0.6-1.22-1.06c-0.23-0.78,0.32-1.45,1.14-1.37
	C290.89,629.71,291.4,630.25,292.35,630.83z"
              />
              <path
                d="M377.61,363.67c-1.07,0.59-1.63,1.15-2.15,1.12c-0.41-0.03-1.09-0.79-1.07-1.21c0.01-0.42,0.73-1.14,1.15-1.15
	C376.05,362.43,376.58,363.02,377.61,363.67z"
              />
              <path
                d="M433.66,324.17c0.59,0.8,1.09,1.22,1.24,1.73c0.19,0.68-0.67,1.53-1.31,1.27c-0.47-0.19-1.03-0.82-1.06-1.28
	C432.5,325.44,433.1,324.96,433.66,324.17z"
              />
              <path
                d="M285.42,346.06c-0.71,0.6-1.1,1.17-1.58,1.27c-0.79,0.16-1.36-0.37-1.38-1.2c-0.02-0.85,0.55-1.37,1.33-1.26
	C284.28,344.94,284.69,345.5,285.42,346.06z"
              />
              <path
                d="M316.09,571.27c-1.01,0.55-1.56,1.1-2.09,1.06c-0.38-0.03-1-0.84-0.97-1.26c0.03-0.43,0.75-1.16,1.12-1.13
	C314.68,569.98,315.16,570.59,316.09,571.27z"
              />
              <path
                d="M291.68,457.61c-1,0.64-1.52,1.21-2.04,1.21c-0.4,0-1.1-0.71-1.11-1.12c-0.01-0.42,0.65-1.18,1.04-1.2
	C290.09,456.47,290.63,457.02,291.68,457.61z"
              />
              <path
                d="M487.86,619.29c-0.61-0.86-1.15-1.29-1.21-1.78c-0.11-0.8,0.44-1.45,1.28-1.27c0.48,0.11,1.17,0.75,1.16,1.14
	C489.09,617.89,488.48,618.4,487.86,619.29z"
              />
              <path
                d="M291.61,624.1c-1.01,0.53-1.58,1.06-2.1,1.02c-0.38-0.03-0.98-0.86-0.94-1.29c0.04-0.43,0.78-1.15,1.14-1.11
	C290.23,622.77,290.7,623.39,291.61,624.1z"
              />
              <path
                d="M375.22,278.64c-0.54-0.96-1.07-1.48-1.1-2.03c-0.05-0.84,0.68-1.31,1.44-1.07c0.47,0.15,1.1,0.85,1.06,1.24
	C376.56,277.3,375.92,277.76,375.22,278.64z"
              />
              <path
                d="M629.66,629.06c1.23,0.22,1.77,0.76,1.49,1.55c-0.16,0.45-0.9,1.01-1.33,0.97c-0.44-0.04-1.17-0.78-1.13-1.16
	C628.74,629.89,629.38,629.42,629.66,629.06z"
              />
              <path
                d="M352.69,369.35c-0.76,0.58-1.17,1.11-1.67,1.23c-0.82,0.19-1.39-0.34-1.4-1.19c-0.01-0.84,0.53-1.4,1.37-1.23
	C351.49,368.26,351.91,368.78,352.69,369.35z"
              />
              <path
                d="M324.39,315.48c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C325.45,314.24,324.93,314.68,324.39,315.48z"
              />
              <path
                d="M363.12,364.74c-0.95,0.66-1.45,1.26-1.97,1.28c-0.37,0.02-1.07-0.73-1.09-1.16c-0.02-0.43,0.62-1.22,1-1.24
	C361.58,363.6,362.12,364.16,363.12,364.74z"
              />
              <path
                d="M464.47,410.27c-0.8,0.53-1.24,1.01-1.74,1.09c-0.83,0.13-1.35-0.43-1.3-1.27c0.05-0.84,0.64-1.34,1.44-1.13
	C463.36,409.09,463.73,409.64,464.47,410.27z"
              />
              <path
                d="M446.72,621.25c-0.76,0.59-1.15,1.11-1.65,1.23c-0.81,0.19-1.39-0.33-1.4-1.17c-0.01-0.83,0.52-1.39,1.35-1.23
	C445.52,620.18,445.94,620.69,446.72,621.25z"
              />
              <path
                d="M261.91,200.33c-0.76,0.54-1.19,1.09-1.68,1.14c-0.78,0.09-1.34-0.46-1.29-1.31c0.05-0.83,0.65-1.33,1.42-1.15
	C260.85,199.12,261.22,199.71,261.91,200.33z"
              />
              <path
                d="M455.52,408.8c0.61,0.86,1.13,1.29,1.2,1.78c0.12,0.81-0.43,1.44-1.26,1.25c-0.47-0.11-1.17-0.74-1.16-1.12
	C454.31,410.2,454.91,409.7,455.52,408.8z"
              />
              <path
                d="M281.55,422.29c-0.95,0.68-1.44,1.28-1.96,1.32c-0.37,0.02-1.08-0.72-1.11-1.14c-0.02-0.42,0.61-1.23,0.99-1.25
	C279.99,421.18,280.54,421.73,281.55,422.29z"
              />
              <path
                d="M347.31,78.66c0.6,0.91,1.19,1.42,1.19,1.94c0,0.39-0.71,1.01-1.19,1.11c-0.85,0.18-1.39-0.47-1.25-1.28
	C346.14,79.93,346.68,79.51,347.31,78.66z"
              />
              <path
                d="M463.94,628.57c0.58,0.91,1.15,1.43,1.14,1.94c0,0.38-0.71,0.98-1.18,1.08c-0.84,0.16-1.35-0.48-1.2-1.28
	C462.79,629.82,463.31,629.41,463.94,628.57z"
              />
              <path
                d="M244.24,411.07c-0.76,0.6-1.15,1.14-1.65,1.26c-0.81,0.2-1.4-0.31-1.42-1.17c-0.02-0.84,0.5-1.41,1.34-1.25
	C243.02,410,243.45,410.51,244.24,411.07z"
              />
              <path
                d="M297.47,465.97c-0.51-0.94-1.06-1.5-1.02-2.03c0.03-0.39,0.79-0.99,1.27-1.03c0.8-0.06,1.31,0.63,1.1,1.42
	C298.68,464.82,298.13,465.2,297.47,465.97z"
              />
              <path
                d="M442.14,175.49c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C443.32,174.02,442.74,174.54,442.14,175.49z"
              />
              <path
                d="M490.81,605.04c0.28,0.33,0.84,0.72,0.96,1.21c0.19,0.81-0.3,1.37-1.19,1.41c-0.74,0.03-1.24-0.34-1.33-1.09
	C489.15,605.71,489.65,605.27,490.81,605.04z"
              />
              <path
                d="M245.09,421.81c0.89-0.67,1.34-1.25,1.88-1.37c0.81-0.18,1.42,0.44,1.29,1.23c-0.08,0.47-0.7,1.17-1.1,1.2
	C246.64,422.9,246.08,422.36,245.09,421.81z"
              />
              <path
                d="M486.64,621.1c-0.8,0.69-1.17,1.26-1.67,1.4c-0.8,0.22-1.52-0.25-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C485.09,620.06,485.67,620.6,486.64,621.1z"
              />
              <path
                d="M247.79,185.08c-0.54-0.98-1.02-1.5-1.07-2.06c-0.06-0.68,0.5-1.12,1.25-1.1c0.85,0.02,1.43,0.52,1.26,1.32
	C249.12,183.77,248.5,184.2,247.79,185.08z"
              />
              <path
                d="M357.69,280.2c0.59,0.87,1.12,1.32,1.17,1.81c0.09,0.81-0.49,1.44-1.32,1.23c-0.47-0.12-1.16-0.78-1.14-1.16
	C356.43,281.56,357.04,281.08,357.69,280.2z"
              />
              <path
                d="M271.99,314.94c-0.74,0.57-1.14,1.13-1.63,1.21c-0.79,0.13-1.35-0.42-1.33-1.26c0.01-0.84,0.6-1.36,1.38-1.21
	C270.88,313.78,271.27,314.35,271.99,314.94z"
              />
              <path
                d="M239.32,353.85c-0.99,0.58-1.54,1.15-2.06,1.13c-0.38-0.01-1.02-0.8-1.01-1.23c0.01-0.43,0.71-1.18,1.08-1.16
	C237.86,352.6,238.37,353.19,239.32,353.85z"
              />
              <path
                d="M398.67,132.24c-0.62-0.84-1.15-1.25-1.23-1.73c-0.14-0.79,0.38-1.44,1.21-1.28c0.47,0.09,1.17,0.7,1.18,1.09
	C399.83,130.82,399.25,131.33,398.67,132.24z"
              />
              <path
                d="M371.76,299c-0.95,0.66-1.45,1.26-1.97,1.28c-0.37,0.02-1.07-0.73-1.09-1.16c-0.02-0.43,0.62-1.22,1-1.24
	C370.22,297.87,370.76,298.42,371.76,299z"
              />
              <path
                d="M437.81,619.88c0.6,0.95,1.17,1.47,1.17,1.99c0,0.4-0.69,1.01-1.17,1.12c-0.8,0.19-1.44-0.4-1.33-1.2
	C436.56,621.25,437.15,620.79,437.81,619.88z"
              />
              <path
                d="M412.19,140.16c-0.83,0.51-1.28,1-1.78,1.06c-0.84,0.11-1.35-0.46-1.28-1.31c0.07-0.86,0.68-1.33,1.48-1.1
	C411.09,138.94,411.45,139.51,412.19,140.16z"
              />
              <path
                d="M233.55,369.09c-1,0.5-1.59,1.03-2.11,0.98c-0.4-0.04-1-0.8-1.03-1.27c-0.05-0.83,0.63-1.35,1.45-1.11
	C232.36,367.82,232.73,368.38,233.55,369.09z"
              />
              <path
                d="M478.27,607.46c-0.92,0.7-1.39,1.32-1.92,1.37c-0.37,0.03-1.1-0.69-1.14-1.12c-0.03-0.42,0.58-1.24,0.95-1.28
	C476.69,606.39,477.26,606.92,478.27,607.46z"
              />
              <path
                d="M465.86,610.33c0.58,0.92,1.16,1.44,1.15,1.95c0,0.38-0.71,0.99-1.19,1.08c-0.85,0.17-1.37-0.48-1.22-1.29
	C464.69,611.58,465.22,611.17,465.86,610.33z"
              />
              <path
                d="M343.93,202.47c0.28,0.32,0.84,0.69,0.96,1.17c0.21,0.79-0.26,1.48-1.12,1.37c-0.48-0.06-1.15-0.56-1.3-1.01
	C342.22,203.21,342.8,202.72,343.93,202.47z"
              />
              <path
                d="M472.11,622.58c-0.81,0.55-1.24,1.04-1.76,1.15c-0.66,0.15-1.49-0.81-1.2-1.4c0.23-0.47,0.86-1.01,1.33-1.02
	C470.91,621.3,471.36,621.95,472.11,622.58z"
              />
              <path
                d="M521.57,302.15c-0.54-0.96-1.07-1.48-1.1-2.03c-0.05-0.84,0.68-1.31,1.44-1.07c0.47,0.15,1.1,0.85,1.06,1.24
	C522.91,300.82,522.27,301.27,521.57,302.15z"
              />
              <path
                d="M301.95,183.79c-0.58-0.81-1.08-1.23-1.22-1.75c-0.19-0.68,0.62-1.4,1.34-1.29c0.73,0.11,1.23,0.6,1.06,1.31
	C303.01,182.57,302.51,182.99,301.95,183.79z"
              />
              <path
                d="M431.26,627.9c0.59,0.8,1.09,1.22,1.24,1.73c0.19,0.68-0.67,1.53-1.31,1.27c-0.47-0.19-1.03-0.82-1.06-1.28
	C430.1,629.18,430.7,628.7,431.26,627.9z"
              />
              <path
                d="M453.67,351.24c-0.5-0.85-1.06-1.38-1-1.82c0.07-0.46,0.67-1.04,1.16-1.19c0.65-0.2,1.47,0.74,1.22,1.38
	C454.85,350.12,454.33,350.49,453.67,351.24z"
              />
              <path
                d="M357.63,300.44c-1.01,0.53-1.58,1.08-2.11,1.04c-0.41-0.03-1.03-0.74-1.1-1.22c-0.12-0.8,0.51-1.41,1.32-1.22
	C356.3,299.17,356.74,299.75,357.63,300.44z"
              />
              <path
                d="M459.41,415.89c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C460.59,414.41,460.01,414.94,459.41,415.89z"
              />
              <path
                d="M457.04,616.82c-0.82,0.55-1.26,1.03-1.78,1.16c-0.66,0.17-1.45-0.76-1.17-1.41c0.21-0.46,0.85-1.01,1.3-1.01
	C455.83,615.56,456.28,616.2,457.04,616.82z"
              />
              <path
                d="M293.81,459.17c0.61,0.86,1.15,1.29,1.22,1.79c0.12,0.81-0.44,1.45-1.28,1.26c-0.48-0.11-1.18-0.75-1.17-1.13
	C292.6,460.57,293.2,460.07,293.81,459.17z"
              />
              <path
                d="M458.67,610.8c0.58,0.92,1.15,1.45,1.14,1.96c-0.01,0.38-0.72,0.99-1.2,1.08c-0.85,0.16-1.37-0.49-1.21-1.29
	C457.5,612.06,458.03,611.65,458.67,610.8z"
              />
              <path
                d="M303.66,78.99c-0.63-0.85-1.16-1.26-1.25-1.74c-0.14-0.8,0.38-1.46,1.22-1.29c0.48,0.09,1.19,0.71,1.19,1.09
	C304.83,77.56,304.25,78.08,303.66,78.99z"
              />
              <path
                d="M327.2,573.84c-1.05,0.59-1.61,1.14-2.13,1.11c-0.4-0.02-1.07-0.79-1.06-1.2c0.01-0.42,0.72-1.14,1.13-1.14
	C325.67,572.61,326.19,573.19,327.2,573.84z"
              />
              <path d="M609.92,200.86c0.31,0.89,0.62,1.78,0.97,2.77C608.72,203.49,608.47,202.95,609.92,200.86z" />
              <path
                d="M315.56,421.16c-0.71-0.88-1.33-1.32-1.43-1.85c-0.16-0.79,0.42-1.3,1.27-1.31c0.74-0.01,1.3,0.43,1.24,1.11
	C316.59,419.66,316.1,420.18,315.56,421.16z"
              />
              <path
                d="M309.46,246.5c-0.75,0.58-1.14,1.11-1.63,1.22c-0.81,0.19-1.37-0.33-1.38-1.17c-0.01-0.82,0.51-1.38,1.34-1.22
	C308.28,245.44,308.69,245.94,309.46,246.5z"
              />
              <path
                d="M519.87,316.66c0.6,0.88,1.14,1.32,1.19,1.82c0.09,0.81-0.5,1.45-1.34,1.24c-0.48-0.12-1.17-0.79-1.15-1.18
	C518.61,318.02,519.23,317.54,519.87,316.66z"
              />
              <path
                d="M499.88,379.83c0.59,0.8,1.08,1.22,1.23,1.74c0.19,0.69-0.67,1.52-1.32,1.26c-0.47-0.19-1.02-0.82-1.05-1.29
	C498.72,381.1,499.32,380.62,499.88,379.83z"
              />
              <path
                d="M319.5,587.29c0.28,0.33,0.84,0.72,0.96,1.21c0.19,0.81-0.3,1.37-1.19,1.41c-0.74,0.03-1.24-0.34-1.33-1.09
	C317.85,587.95,318.34,587.51,319.5,587.29z"
              />
              <path
                d="M324.5,584.82c-0.93,0.6-1.44,1.18-1.98,1.2c-0.4,0.01-1.07-0.63-1.19-1.09c-0.2-0.79,0.36-1.45,1.18-1.33
	C323.07,583.67,323.57,584.21,324.5,584.82z"
              />
              <path
                d="M322.6,416.97c0.6,0.86,1.12,1.28,1.19,1.76c0.12,0.8-0.42,1.43-1.25,1.25c-0.47-0.1-1.16-0.73-1.15-1.11
	C321.4,418.36,321.99,417.86,322.6,416.97z"
              />
              <path
                d="M477.41,241.38c0.23,0.28,0.79,0.66,0.91,1.15c0.19,0.8-0.31,1.48-1.16,1.36c-0.48-0.07-1.15-0.59-1.28-1.05
	C475.63,242.05,476.18,241.53,477.41,241.38z"
              />
              <path
                d="M362.64,317.88c-1,0.57-1.55,1.13-2.07,1.11c-0.38-0.02-1.02-0.81-1-1.24c0.02-0.43,0.72-1.17,1.09-1.15
	C361.2,316.62,361.69,317.22,362.64,317.88z"
              />
              <path
                d="M286.27,503.04c-0.53-0.84-1.02-1.29-1.08-1.79c-0.08-0.69,0.41-1.17,1.17-1.15c0.86,0.02,1.4,0.51,1.22,1.33
	C287.47,501.91,286.91,502.28,286.27,503.04z"
              />
              <path
                d="M485.09,606.9c-0.76,0.56-1.17,1.08-1.66,1.18c-0.82,0.18-1.36-0.37-1.36-1.19c0.01-0.81,0.54-1.37,1.37-1.19
	C483.93,605.8,484.33,606.32,485.09,606.9z"
              />
              <path
                d="M246.1,159.77c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C244.95,161.01,245.47,160.61,246.1,159.77z"
              />
              <path
                d="M372.3,365.62c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C371.15,366.86,371.67,366.46,372.3,365.62z"
              />
              <path
                d="M298.8,353.92c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C300.01,352.54,299.42,353.03,298.8,353.92z"
              />
              <path
                d="M507.59,366.8c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.1-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C506.51,365.62,506.89,366.2,507.59,366.8z"
              />
              <path
                d="M327.17,75.07c-0.92,0.7-1.38,1.31-1.9,1.36c-0.41,0.04-1.13-0.54-1.28-0.99c-0.25-0.75,0.22-1.48,1.06-1.43
	C325.61,74.04,326.14,74.53,327.17,75.07z"
              />
              <path
                d="M392.9,273.52c-0.57-0.79-1.07-1.21-1.2-1.72c-0.17-0.71,0.33-1.2,1.05-1.32c0.72-0.11,1.54,0.6,1.35,1.28
	C393.96,272.28,393.48,272.71,392.9,273.52z"
              />
              <path
                d="M284.47,418.61c-0.8,0.56-1.24,1.11-1.71,1.14c-0.78,0.06-1.41-0.54-1.19-1.34c0.12-0.45,0.82-0.99,1.28-1.01
	C283.26,417.39,283.71,418.01,284.47,418.61z"
              />
              <path
                d="M365.08,366.15c0.58,0.82,1.13,1.26,1.19,1.75c0.1,0.83-0.53,1.41-1.33,1.19c-0.45-0.12-1.01-0.79-1.03-1.24
	C363.89,367.42,364.49,366.95,365.08,366.15z"
              />
              <path
                d="M344.05,418.65c-0.54-0.86-1.06-1.32-1.09-1.81c-0.05-0.82,0.61-1.38,1.4-1.12c0.44,0.15,0.95,0.85,0.95,1.31
	C345.31,417.45,344.68,417.88,344.05,418.65z"
              />
              <path d="M390.24,4.3c0.89-0.31,1.81-0.72,2.3,0.49c0.08,0.21-0.51,0.96-0.87,1.03C390.66,5.99,390.02,5.56,390.24,4.3z" />
              <path
                d="M438.4,627.2c0.62,0.78,1.17,1.17,1.27,1.66c0.16,0.82-0.43,1.42-1.24,1.26c-0.45-0.09-1.05-0.72-1.1-1.17
	C437.28,628.53,437.86,628.03,438.4,627.2z"
              />
              <path
                d="M446.47,162.41c-0.82,0.55-1.25,1.01-1.77,1.14c-0.66,0.16-1.43-0.74-1.14-1.39c0.2-0.46,0.85-0.99,1.29-0.99
	C445.28,161.17,445.71,161.8,446.47,162.41z"
              />
              <path
                d="M386.15,317.47c0.59,0.79,1.14,1.21,1.22,1.71c0.13,0.83-0.52,1.41-1.3,1.25c-0.44-0.09-1.01-0.79-1.04-1.25
	C385,318.74,385.6,318.26,386.15,317.47z"
              />
              <path
                d="M259.96,444.15c0.58,0.79,1.13,1.22,1.2,1.72c0.12,0.83-0.53,1.41-1.31,1.23c-0.44-0.1-1-0.8-1.03-1.26
	C258.81,445.4,259.41,444.93,259.96,444.15z"
              />
              <path
                d="M459.36,246c0.54,0.78,1.12,1.26,1.09,1.69c-0.03,0.45-0.6,1.14-1.03,1.23c-0.77,0.16-1.41-0.42-1.28-1.24
	C458.22,247.19,458.77,246.77,459.36,246z"
              />
              <path
                d="M464.26,616.12c-0.86,0.53-1.31,0.97-1.83,1.07c-0.75,0.14-1.32-0.61-1.17-1.39c0.14-0.73,0.63-1.19,1.34-0.98
	C463.1,614.98,463.48,615.49,464.26,616.12z"
              />
              <path
                d="M373.09,107.52c-0.87,0.51-1.32,0.96-1.84,1.05c-0.74,0.13-1.3-0.63-1.15-1.4c0.15-0.73,0.64-1.19,1.35-0.96
	C371.94,106.36,372.32,106.88,373.09,107.52z"
              />
              <path
                d="M454.17,248.57c-0.85,0.52-1.31,1.01-1.81,1.06c-0.86,0.09-1.26-0.54-1.15-1.37c0.1-0.72,0.61-1.19,1.31-0.97
	C453.02,247.44,453.4,247.94,454.17,248.57z"
              />
              <path
                d="M368.34,362.33c0.62,0.78,1.17,1.17,1.27,1.66c0.16,0.82-0.43,1.42-1.24,1.26c-0.45-0.09-1.05-0.72-1.1-1.17
	C367.22,363.66,367.8,363.16,368.34,362.33z"
              />
              <path
                d="M471.45,615.69c-0.88,0.5-1.34,0.93-1.85,1.02c-0.74,0.12-1.29-0.65-1.13-1.42c0.16-0.73,0.66-1.17,1.36-0.94
	C470.32,614.52,470.69,615.04,471.45,615.69z"
              />
              <path
                d="M342.56,420.65c-0.78,0.6-1.23,1.21-1.65,1.2c-0.46-0.02-1.15-0.54-1.28-0.98c-0.23-0.79,0.35-1.41,1.17-1.33
	C341.29,419.59,341.73,420.1,342.56,420.65z"
              />
              <path
                d="M455.56,369.31c0.66,0.73,1.19,1.09,1.39,1.58c0.25,0.62-0.55,1.58-1.19,1.38c-0.48-0.15-1.08-0.71-1.16-1.17
	C454.54,370.68,455.09,370.15,455.56,369.31z"
              />
              <path
                d="M350.4,324.12c-0.59-0.82-1.14-1.25-1.2-1.74c-0.1-0.82,0.51-1.42,1.32-1.2c0.45,0.12,1.01,0.79,1.04,1.24
	C351.58,322.85,350.98,323.32,350.4,324.12z"
              />
              <path
                d="M447.42,628.19c-0.76,0.6-1.16,1.1-1.67,1.27c-0.66,0.22-1.48-0.59-1.25-1.28c0.16-0.48,0.75-1.09,1.19-1.12
	C446.14,627.03,446.63,627.64,447.42,628.19z"
              />
              <path
                d="M518.17,322.7c-0.91,0.4-1.48,0.89-1.9,0.78c-0.44-0.12-1.01-0.79-1.03-1.25c-0.04-0.83,0.68-1.31,1.46-1.04
	C517.17,321.35,517.48,321.97,518.17,322.7z"
              />
              <path
                d="M356.3,80.14c-0.86,0.53-1.31,0.97-1.83,1.07c-0.74,0.14-1.31-0.62-1.17-1.39c0.14-0.73,0.63-1.19,1.34-0.98
	C355.13,79.01,355.52,79.52,356.3,80.14z"
              />
              <path
                d="M450.51,165.74c-0.8,0.56-1.22,1.04-1.73,1.17c-0.66,0.16-1.49-0.77-1.21-1.37c0.22-0.47,0.84-1.01,1.31-1.03
	C449.3,164.5,449.75,165.14,450.51,165.74z"
              />
              <path
                d="M442.65,159.23c-0.82,0.56-1.29,1.14-1.73,1.11c-0.44-0.03-1.1-0.61-1.2-1.05c-0.17-0.78,0.37-1.42,1.22-1.26
	C441.44,158.12,441.86,158.64,442.65,159.23z"
              />
              <path
                d="M495.89,253.58c-0.63-0.78-1.2-1.18-1.29-1.66c-0.16-0.82,0.43-1.44,1.24-1.28c0.46,0.09,1.06,0.73,1.12,1.18
	C497.01,252.24,496.43,252.74,495.89,253.58z"
              />
              <path
                d="M370.98,287.9c0.77-0.62,1.16-1.17,1.64-1.27c0.82-0.17,1.44,0.41,1.3,1.21c-0.08,0.45-0.73,1.04-1.18,1.11
	C372.32,289,371.82,288.43,370.98,287.9z"
              />
              <path
                d="M450.81,252.24c-0.78,0.59-1.18,1.08-1.68,1.22c-0.71,0.2-1.21-0.27-1.28-1c-0.08-0.83,0.34-1.45,1.19-1.34
	C449.54,251.17,449.98,251.68,450.81,252.24z"
              />
              <path
                d="M310.35,566.05c0.58,0.78,1.13,1.19,1.2,1.69c0.13,0.82-0.51,1.4-1.28,1.23c-0.43-0.09-1-0.79-1.03-1.24
	C309.22,567.3,309.8,566.82,310.35,566.05z"
              />
              <path
                d="M371.88,362.03c-0.62-0.78-1.25-1.21-1.24-1.63c0.01-0.46,0.52-1.16,0.96-1.3c0.8-0.26,1.44,0.31,1.39,1.13
	C372.95,360.72,372.43,361.17,371.88,362.03z"
              />
              <path
                d="M287.68,494.24c-0.86,0.52-1.32,1.01-1.82,1.06c-0.86,0.09-1.27-0.54-1.16-1.38c0.1-0.73,0.62-1.19,1.32-0.97
	C286.52,493.11,286.9,493.62,287.68,494.24z"
              />
              <path
                d="M468.28,619.3c-0.78,0.6-1.23,1.21-1.65,1.2c-0.46-0.02-1.15-0.54-1.28-0.98c-0.23-0.79,0.35-1.41,1.17-1.33
	C467.01,618.24,467.45,618.75,468.28,619.3z"
              />
              <path
                d="M351.42,82.09c0.56,0.83,1.15,1.31,1.11,1.74c-0.04,0.45-0.64,1.09-1.09,1.19c-0.81,0.18-1.42-0.41-1.27-1.25
	C350.25,83.29,350.8,82.89,351.42,82.09z"
              />
              <path
                d="M272.99,408.77c-0.82,0.55-1.31,1.13-1.73,1.09c-0.46-0.05-1.12-0.62-1.21-1.07c-0.17-0.8,0.44-1.39,1.26-1.24
	C271.79,407.63,272.2,408.17,272.99,408.77z"
              />
              <path
                d="M478.62,614.91c-0.82,0.54-1.26,1-1.78,1.13c-0.66,0.16-1.42-0.76-1.13-1.4c0.2-0.46,0.85-0.99,1.3-0.98
	C477.44,613.66,477.87,614.3,478.62,614.91z"
              />
              <path
                d="M505.16,357.78c0.58,0.79,1.13,1.22,1.2,1.72c0.12,0.83-0.53,1.41-1.31,1.23c-0.44-0.1-1-0.8-1.03-1.26
	C504.01,359.03,504.61,358.56,505.16,357.78z"
              />
              <path
                d="M313.66,562.23c0.46,0.83,1,1.36,0.93,1.79c-0.08,0.45-0.71,1.09-1.15,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C312.4,563.3,312.99,562.94,313.66,562.23z"
              />
              <path
                d="M309.58,226.52c0.6,0.79,1.21,1.25,1.19,1.68c-0.02,0.45-0.57,1.13-1.01,1.25c-0.8,0.23-1.44-0.35-1.35-1.17
	C308.46,227.79,309,227.35,309.58,226.52z"
              />
              <path
                d="M440.23,256.94c-0.82,0.54-1.25,1.01-1.77,1.14c-0.65,0.16-1.43-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.99
	C439.04,255.7,439.48,256.33,440.23,256.94z"
              />
              <path
                d="M454.35,627.66c-0.75,0.63-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.42-1.31-1.23c0.09-0.47,0.72-1.09,1.18-1.15
	C453.02,626.52,453.53,627.12,454.35,627.66z"
              />
              <path
                d="M456.69,632.66c-0.57-0.81-1.17-1.29-1.14-1.71c0.04-0.45,0.61-1.12,1.06-1.23c0.81-0.2,1.42,0.4,1.3,1.22
	C457.85,631.43,457.29,631.85,456.69,632.66z"
              />
              <path
                d="M236.02,357.7c-0.81,0.56-1.23,1.03-1.74,1.15c-0.66,0.15-1.48-0.79-1.19-1.38c0.23-0.47,0.85-1,1.32-1.01
	C234.82,356.44,235.26,357.08,236.02,357.7z"
              />
              <path
                d="M445.71,195.02c-0.81,0.56-1.23,1.04-1.74,1.16c-0.66,0.16-1.48-0.78-1.2-1.37c0.23-0.47,0.84-1,1.31-1.02
	C444.5,193.77,444.95,194.41,445.71,195.02z"
              />
              <path
                d="M296.36,491.33c-0.66-0.73-1.18-1.1-1.39-1.59c-0.26-0.62,0.54-1.57,1.19-1.37c0.48,0.14,1.09,0.71,1.16,1.16
	C297.39,489.96,296.84,490.49,296.36,491.33z"
              />
              <path
                d="M624.49,631.21c-0.81,0.55-1.25,1.01-1.77,1.15c-0.65,0.17-1.43-0.75-1.15-1.39c0.2-0.46,0.84-1,1.29-1
	C623.29,629.97,623.73,630.6,624.49,631.21z"
              />
              <path
                d="M361.07,177.09c-0.86,0.51-1.32,0.99-1.82,1.04c-0.86,0.08-1.25-0.55-1.13-1.38c0.11-0.73,0.62-1.18,1.32-0.95
	C359.94,175.95,360.32,176.46,361.07,177.09z"
              />
              <path
                d="M519.19,313.09c-0.62-0.78-1.25-1.21-1.24-1.63c0.01-0.46,0.52-1.16,0.96-1.3c0.8-0.26,1.44,0.31,1.39,1.13
	C520.27,311.77,519.74,312.23,519.19,313.09z"
              />
              <path
                d="M427.21,165.87c0.54,0.78,1.12,1.26,1.09,1.69c-0.03,0.45-0.6,1.14-1.03,1.23c-0.77,0.16-1.41-0.42-1.28-1.24
	C426.07,167.06,426.62,166.64,427.21,165.87z"
              />
              <path
                d="M447.78,631.7c0.75-0.62,1.19-1.27,1.61-1.25c0.47,0.01,1.09,0.54,1.32,1c0.29,0.59-0.52,1.54-1.18,1.39
	C449.02,632.72,448.59,632.25,447.78,631.7z"
              />
              <path
                d="M285.88,618.83c0.58,0.78,1.13,1.21,1.2,1.7c0.12,0.82-0.53,1.39-1.3,1.22c-0.43-0.1-0.99-0.79-1.01-1.24
	C284.73,620.07,285.33,619.61,285.88,618.83z"
              />
              <path
                d="M452.14,622.34c-0.58-0.79-1.13-1.22-1.21-1.72c-0.13-0.84,0.5-1.31,1.32-1.27c0.72,0.04,1.22,0.56,1.06,1.27
	C453.19,621.14,452.7,621.56,452.14,622.34z"
              />
              <path
                d="M415.98,143.4c-0.81,0.56-1.29,1.15-1.71,1.11c-0.45-0.04-1.12-0.61-1.22-1.06c-0.18-0.8,0.42-1.4,1.24-1.26
	C414.78,142.28,415.19,142.81,415.98,143.4z"
              />
              <path
                d="M303.47,261.75c-0.82,0.54-1.26,1-1.78,1.13c-0.66,0.16-1.42-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.98
	C302.29,260.5,302.72,261.14,303.47,261.75z"
              />
              <path
                d="M461.59,627.05c-0.81,0.57-1.29,1.17-1.72,1.14c-0.46-0.03-1.14-0.6-1.25-1.04c-0.19-0.81,0.4-1.41,1.24-1.28
	C460.35,625.94,460.78,626.47,461.59,627.05z"
              />
              <path
                d="M304.79,524.93c0.78-0.62,1.17-1.16,1.65-1.26c0.83-0.18,1.43,0.41,1.29,1.21c-0.08,0.45-0.72,1.05-1.17,1.11
	C306.13,526.03,305.63,525.46,304.79,524.93z"
              />
              <path
                d="M275.79,381.22c-0.57-0.81-1.17-1.29-1.14-1.71c0.04-0.45,0.61-1.12,1.06-1.23c0.81-0.2,1.42,0.4,1.3,1.22
	C276.94,379.99,276.39,380.41,275.79,381.22z"
              />
              <path
                d="M308.73,425.47c0.66,0.72,1.26,1.08,1.38,1.56c0.2,0.82-0.42,1.43-1.18,1.36c-0.44-0.04-1.06-0.69-1.14-1.14
	C307.72,426.83,308.26,426.3,308.73,425.47z"
              />
              <path
                d="M287.2,447.15c-0.84,0.56-1.33,1.14-1.77,1.1c-0.45-0.04-1.11-0.63-1.2-1.08c-0.16-0.79,0.39-1.43,1.26-1.25
	C285.99,446.03,286.4,446.55,287.2,447.15z"
              />
              <path
                d="M239.64,400.89c-0.81,0.54-1.26,1.08-1.75,1.12c-0.81,0.07-1.37-0.59-1.13-1.37c0.13-0.44,0.82-0.98,1.26-0.98
	C238.46,399.66,238.89,400.29,239.64,400.89z"
              />
              <path
                d="M253.76,439.08c-0.74,0.63-1.13,1.21-1.6,1.3c-0.78,0.15-1.44-0.41-1.3-1.22c0.08-0.46,0.72-1.07,1.17-1.14
	C252.44,437.96,252.95,438.55,253.76,439.08z"
              />
              <path
                d="M403.52,332.47c-0.83,0.55-1.32,1.14-1.75,1.1c-0.46-0.05-1.13-0.63-1.22-1.08c-0.17-0.81,0.44-1.4,1.27-1.25
	C402.32,331.33,402.73,331.87,403.52,332.47z"
              />
              <path
                d="M454.83,381.99c-0.75,0.63-1.14,1.22-1.62,1.3c-0.79,0.14-1.46-0.43-1.3-1.24c0.09-0.46,0.73-1.08,1.19-1.14
	C453.51,380.85,454.02,381.45,454.83,381.99z"
              />
              <path
                d="M511.4,266.76c-0.55-0.77-1.03-1.19-1.15-1.69c-0.17-0.7,0.32-1.22,1.04-1.26c0.81-0.04,1.44,0.42,1.31,1.25
	C512.52,265.55,511.98,265.98,511.4,266.76z"
              />
              <path
                d="M317.87,553c-0.82,0.54-1.25,1.01-1.77,1.14c-0.65,0.16-1.43-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.99
	C316.68,551.76,317.12,552.39,317.87,553z"
              />
              <path
                d="M419.31,266.03c-0.8,0.56-1.24,1.11-1.71,1.14c-0.78,0.05-1.41-0.54-1.19-1.34c0.12-0.45,0.82-0.98,1.28-1.01
	C418.1,264.8,418.55,265.43,419.31,266.03z"
              />
              <path
                d="M347.86,398.69c-0.83,0.55-1.32,1.14-1.75,1.1c-0.46-0.05-1.13-0.63-1.22-1.08c-0.17-0.81,0.44-1.4,1.27-1.25
	C346.66,397.55,347.06,398.09,347.86,398.69z"
              />
              <path
                d="M315.2,398.04c-0.91,0.4-1.48,0.89-1.9,0.77c-0.44-0.12-1.01-0.79-1.03-1.25c-0.04-0.83,0.68-1.31,1.46-1.04
	C314.2,396.68,314.51,397.3,315.2,398.04z"
              />
              <path
                d="M507.99,346.78c0.46,0.83,1,1.36,0.93,1.78c-0.08,0.45-0.71,1.09-1.14,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C506.73,347.85,507.33,347.49,507.99,346.78z"
              />
              <path
                d="M362.21,397.33c-0.75,0.64-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.42-1.31-1.23c0.08-0.47,0.72-1.09,1.18-1.15
	C360.89,396.2,361.4,396.8,362.21,397.33z"
              />
              <path
                d="M350.96,268.46c-0.81,0.54-1.26,1.08-1.75,1.11c-0.81,0.06-1.36-0.59-1.13-1.37c0.13-0.44,0.83-0.98,1.27-0.98
	C349.78,267.23,350.22,267.85,350.96,268.46z"
              />
              <path
                d="M515.67,266.62c0.47,0.84,1.02,1.38,0.94,1.81c-0.08,0.46-0.68,1.02-1.17,1.16c-0.64,0.19-1.44-0.78-1.18-1.39
	C514.47,267.71,515,267.35,515.67,266.62z"
              />
              <path
                d="M438.21,172.01c-0.59-0.82-1.14-1.25-1.2-1.74c-0.1-0.82,0.51-1.42,1.32-1.2c0.45,0.12,1.01,0.79,1.04,1.24
	C439.39,170.74,438.79,171.21,438.21,172.01z"
              />
              <path
                d="M464.92,623.11c-0.77,0.62-1.21,1.24-1.63,1.23c-0.46-0.01-1.16-0.52-1.3-0.96c-0.24-0.79,0.33-1.42,1.15-1.35
	C463.63,622.07,464.08,622.57,464.92,623.11z"
              />
              <path
                d="M498.49,385.51c-0.8,0.56-1.22,1.04-1.73,1.17c-0.66,0.16-1.49-0.77-1.2-1.37c0.22-0.47,0.84-1.01,1.31-1.03
	C497.28,384.26,497.73,384.9,498.49,385.51z"
              />
              <path
                d="M342.81,215.19c-0.9,0.41-1.41,0.84-1.91,0.83c-0.88-0.01-1.26-0.75-0.94-1.48c0.19-0.41,0.95-0.85,1.39-0.79
	C341.78,213.81,342.13,214.48,342.81,215.19z"
              />
              <path
                d="M457.7,624c-0.8,0.55-1.25,1.1-1.72,1.13c-0.77,0.05-1.41-0.55-1.18-1.35c0.13-0.44,0.83-0.98,1.29-1
	C456.5,622.76,456.94,623.39,457.7,624z"
              />
              <path
                d="M301.66,465.78c0.46,0.83,1,1.36,0.93,1.78c-0.08,0.45-0.71,1.09-1.14,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C300.4,466.85,300.99,466.49,301.66,465.78z"
              />
              <path
                d="M459.46,209.91c-0.52-0.85-1.09-1.36-1.03-1.78c0.07-0.45,0.68-1.08,1.14-1.16c0.82-0.14,1.39,0.49,1.21,1.31
	C460.68,208.76,460.11,209.14,459.46,209.91z"
              />
              <path
                d="M519.2,273.25c-0.54-0.86-1.06-1.31-1.09-1.8c-0.04-0.81,0.6-1.38,1.39-1.11c0.43,0.14,0.94,0.85,0.94,1.3
	C520.44,272.05,519.82,272.48,519.2,273.25z"
              />
              <path
                d="M450.51,624.29c-0.75,0.64-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.41-1.31-1.23c0.08-0.47,0.72-1.09,1.18-1.15
	C449.18,623.16,449.69,623.76,450.51,624.29z"
              />
              <path
                d="M453.6,391.06c-0.59-0.79-1.08-1.2-1.23-1.72c-0.2-0.67,0.59-1.38,1.32-1.29c0.72,0.09,1.23,0.57,1.06,1.28
	C454.63,389.84,454.14,390.27,453.6,391.06z"
              />
              <path
                d="M520.39,304.06c-0.78,0.59-1.18,1.08-1.68,1.22c-0.71,0.2-1.21-0.27-1.28-1c-0.08-0.83,0.34-1.45,1.19-1.34
	C519.12,302.99,519.56,303.51,520.39,304.06z"
              />
              <path
                d="M481.97,610.91c-0.76,0.61-1.16,1.11-1.66,1.28c-0.66,0.22-1.48-0.58-1.25-1.27c0.16-0.48,0.75-1.09,1.19-1.13
	C480.68,609.75,481.17,610.36,481.97,610.91z"
              />
              <path
                d="M311.74,540.62c0.47,0.84,1.01,1.37,0.94,1.8c-0.08,0.45-0.68,1.01-1.16,1.16c-0.63,0.19-1.43-0.77-1.18-1.39
	C310.55,541.7,311.07,541.34,311.74,540.62z"
              />
              <path
                d="M305.48,472.62c-0.66-0.73-1.18-1.1-1.39-1.59c-0.26-0.62,0.54-1.57,1.19-1.37c0.48,0.14,1.09,0.71,1.16,1.16
	C306.51,471.25,305.96,471.78,305.48,472.62z"
              />
              <path
                d="M376.72,273.03c0.78-0.63,1.17-1.18,1.66-1.28c0.83-0.18,1.45,0.41,1.31,1.21c-0.08,0.45-0.73,1.05-1.19,1.12
	C378.07,274.13,377.57,273.56,376.72,273.03z"
              />
              <path
                d="M394.82,125.3c0.58,0.81,1.12,1.24,1.17,1.72c0.1,0.81-0.51,1.39-1.31,1.17c-0.44-0.12-0.99-0.78-1.01-1.22
	C393.66,126.55,394.25,126.09,394.82,125.3z"
              />
              <path
                d="M286.41,273.89c-0.79,0.57-1.26,1.16-1.68,1.13c-0.45-0.03-1.12-0.59-1.23-1.03c-0.19-0.78,0.39-1.39,1.21-1.27
	C285.19,272.79,285.61,273.31,286.41,273.89z"
              />
              <path
                d="M375.65,96.25c-0.76,0.61-1.19,1.23-1.61,1.22c-0.45-0.01-1.15-0.52-1.29-0.95c-0.24-0.78,0.32-1.41,1.13-1.34
	C374.38,95.21,374.82,95.72,375.65,96.25z"
              />
              <path
                d="M419.78,147.09c-0.95,0.38-1.54,0.86-1.93,0.72c-0.43-0.15-0.66-0.86-0.97-1.32c0.47-0.32,0.96-0.9,1.41-0.87
	C418.7,145.66,419.06,146.35,419.78,147.09z"
              />
              <path
                d="M438.79,155.8c-0.77,0.58-1.17,1.07-1.66,1.21c-0.7,0.19-1.2-0.28-1.27-1c-0.08-0.82,0.34-1.44,1.19-1.33
	C437.54,154.73,437.98,155.25,438.79,155.8z"
              />
              <path
                d="M342.08,207.59c-0.82,0.61-1.29,1.22-1.7,1.18c-0.45-0.04-0.85-0.68-1.27-1.06c0.39-0.42,0.72-1.09,1.17-1.17
	C340.68,206.47,341.21,207.05,342.08,207.59z"
              />
              <path
                d="M310.19,214.03c-0.84,0.51-1.29,0.99-1.79,1.04c-0.85,0.08-1.25-0.54-1.13-1.36c0.1-0.72,0.61-1.17,1.3-0.96
	C309.07,212.9,309.44,213.41,310.19,214.03z"
              />
              <path
                d="M407.21,268.74c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C406.2,270.08,406.67,269.62,407.21,268.74z"
              />
              <path
                d="M512.41,337.13c-0.76,0.61-1.19,1.23-1.61,1.22c-0.45-0.01-1.15-0.52-1.29-0.95c-0.24-0.78,0.32-1.41,1.13-1.34
	C511.13,336.09,511.57,336.59,512.41,337.13z"
              />
              <path
                d="M601.35,634.55c-0.58-0.81-1.12-1.23-1.18-1.71c-0.09-0.8,0.5-1.4,1.3-1.18c0.44,0.12,0.98,0.78,1.01,1.23
	C602.51,633.3,601.92,633.76,601.35,634.55z"
              />
              <path
                d="M257.15,269.14c-0.86,0.55-1.38,1.13-1.78,1.06c-0.45-0.07-0.8-0.74-1.19-1.15c0.42-0.39,0.8-1.04,1.26-1.09
	C255.84,267.92,256.32,268.54,257.15,269.14z"
              />
              <path
                d="M457.52,204.94c-0.9,0.49-1.37,0.94-1.87,0.99c-0.75,0.07-1.11-0.53-1.05-1.25c0.06-0.73,0.53-1.24,1.25-1.04
	C456.34,203.78,456.73,204.3,457.52,204.94z"
              />
              <path
                d="M462.87,202.52c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C461.86,203.86,462.33,203.4,462.87,202.52z"
              />
              <path
                d="M353.96,280.45c-0.68-0.8-1.33-1.22-1.32-1.64c0.01-0.44,0.56-1.1,1.01-1.26c0.69-0.25,1.27,0.29,1.23,1.01
	C354.85,279.06,354.43,279.54,353.96,280.45z"
              />
              <path
                d="M518.3,278.7c0.84-0.59,1.33-1.19,1.74-1.14c0.45,0.06,0.83,0.72,1.24,1.11c-0.4,0.4-0.77,1.06-1.22,1.13
	C519.65,279.86,519.15,279.27,518.3,278.7z"
              />
              <path
                d="M435.04,631.02c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C434.03,632.35,434.5,631.9,435.04,631.02z"
              />
              <path
                d="M348.57,302.5c-0.62-0.77-1.17-1.16-1.26-1.64c-0.15-0.8,0.42-1.42,1.22-1.26c0.45,0.09,1.04,0.72,1.09,1.17
	C349.67,301.18,349.1,301.67,348.57,302.5z"
              />
              <path
                d="M249.29,429.02c-0.91,0.5-1.38,0.95-1.89,1c-0.76,0.08-1.12-0.52-1.06-1.25c0.06-0.73,0.53-1.25,1.26-1.05
	C248.09,427.85,248.48,428.37,249.29,429.02z"
              />
              <path
                d="M305.9,479.6c-0.51-0.91-0.96-1.37-1.01-1.87c-0.06-0.71,0.51-1.29,1.21-1.06c0.47,0.15,1.04,0.79,1.07,1.24
	C307.2,478.32,306.56,478.77,305.9,479.6z"
              />
              <path
                d="M311.01,201.08c0.59,0.84,1.1,1.26,1.19,1.76c0.13,0.7-0.37,1.33-1.1,1.17c-0.48-0.1-1.13-0.68-1.19-1.12
	C309.84,202.48,310.44,201.96,311.01,201.08z"
              />
              <path
                d="M392.7,124.03c-0.94,0.4-1.45,0.8-1.96,0.79c-0.74-0.01-1.04-0.64-0.91-1.36c0.14-0.73,0.67-1.16,1.36-0.9
	C391.67,122.75,391.99,123.31,392.7,124.03z"
              />
              <path
                d="M444.07,632.37c-0.81,0.54-1.29,1.12-1.73,1.08c-0.44-0.04-1.08-0.63-1.17-1.07c-0.16-0.78,0.38-1.41,1.23-1.23
	C442.89,631.26,443.29,631.79,444.07,632.37z"
              />
              <path
                d="M308.81,222.84c-0.52-0.83-1.09-1.33-1.03-1.75c0.06-0.44,0.66-1.07,1.11-1.15c0.8-0.15,1.37,0.48,1.21,1.27
	C310,221.7,309.44,222.08,308.81,222.84z"
              />
              <path
                d="M460.88,201.09c-0.89,0.5-1.36,0.95-1.87,1c-0.75,0.07-1.11-0.52-1.05-1.24c0.06-0.73,0.53-1.24,1.25-1.05
	C459.7,199.93,460.08,200.46,460.88,201.09z"
              />
              <path
                d="M448.84,330.19c0.54,0.82,1.11,1.31,1.07,1.73c-0.05,0.44-0.63,1.07-1.08,1.17c-0.8,0.17-1.38-0.43-1.23-1.24
	C447.68,331.37,448.23,330.97,448.84,330.19z"
              />
              <path
                d="M277.8,632.25c-0.83,0.61-1.31,1.23-1.72,1.19c-0.46-0.04-0.86-0.68-1.28-1.06c0.39-0.42,0.73-1.1,1.18-1.18
	C276.39,631.12,276.91,631.7,277.8,632.25z"
              />
              <path
                d="M251.67,330.88c-0.8,0.61-1.2,1.12-1.69,1.25c-0.72,0.18-1.31-0.35-1.2-1.05c0.08-0.47,0.63-1.14,1.05-1.21
	C250.26,329.8,250.79,330.36,251.67,330.88z"
              />
              <path
                d="M428.29,263.18c-0.66-0.81-1.3-1.26-1.28-1.67c0.02-0.45,0.59-1.08,1.05-1.24c0.7-0.23,1.26,0.33,1.21,1.04
	C429.22,261.81,428.78,262.28,428.29,263.18z"
              />
              <path
                d="M375.8,282.21c0.58,0.78,1.18,1.23,1.16,1.65c-0.02,0.44-0.55,1.11-0.99,1.24c-0.79,0.23-1.41-0.35-1.32-1.15
	C374.69,283.46,375.23,283.03,375.8,282.21z"
              />
              <path
                d="M446.5,328.54c-0.83,0.59-1.25,1.09-1.76,1.2c-0.73,0.16-1.3-0.42-1.17-1.11c0.09-0.47,0.68-1.11,1.12-1.17
	C445.12,327.4,445.63,327.99,446.5,328.54z"
              />
              <path
                d="M344.74,196.77c-0.84,0.5-1.3,0.98-1.8,1.02c-0.87,0.08-1.23-0.56-1.12-1.38c0.1-0.72,0.62-1.17,1.31-0.94
	C343.63,195.63,344,196.15,344.74,196.77z"
              />
              <path
                d="M338.44,224.13c0.55,0.85,1.13,1.35,1.08,1.77c-0.06,0.44-0.74,1.12-1.12,1.11c-0.42-0.02-1.08-0.69-1.13-1.12
	C337.22,225.46,337.84,224.98,338.44,224.13z"
              />
              <path
                d="M267.04,407.13c-0.5-0.9-1.05-1.44-0.96-1.84c0.09-0.44,0.77-0.75,1.19-1.12c0.37,0.43,1.02,0.84,1.04,1.28
	C268.33,405.86,267.69,406.31,267.04,407.13z"
              />
              <path
                d="M355.16,85.44c0.59,0.84,1.1,1.26,1.19,1.76c0.13,0.7-0.37,1.33-1.1,1.17c-0.48-0.1-1.13-0.68-1.19-1.12
	C353.99,86.84,354.59,86.32,355.16,85.44z"
              />
              <path
                d="M346.69,407.58c-0.51-0.84-1.06-1.35-1-1.76c0.07-0.44,0.67-1.06,1.12-1.14c0.8-0.14,1.36,0.49,1.19,1.29
	C347.9,406.45,347.33,406.82,346.69,407.58z"
              />
              <path
                d="M466.19,408.1c-0.66-0.82-1.31-1.27-1.29-1.69c0.02-0.45,0.59-1.09,1.06-1.24c0.71-0.23,1.28,0.33,1.22,1.05
	C467.15,406.72,466.7,407.19,466.19,408.1z"
              />
              <path
                d="M460.59,406.8c-0.79,0.57-1.26,1.16-1.68,1.13c-0.45-0.03-1.12-0.59-1.23-1.03c-0.19-0.78,0.39-1.39,1.21-1.27
	C459.38,405.71,459.79,406.23,460.59,406.8z"
              />
              <path
                d="M421.04,327.18c-0.81,0.55-1.28,1.13-1.71,1.1c-0.44-0.03-1.09-0.61-1.18-1.05c-0.17-0.77,0.37-1.41,1.22-1.24
	C419.85,326.08,420.26,326.6,421.04,327.18z"
              />
              <path
                d="M461.06,619.98c-0.84,0.51-1.35,1.06-1.76,1c-0.45-0.07-1.09-0.68-1.15-1.12c-0.11-0.78,0.5-1.37,1.31-1.17
	C459.94,618.8,460.31,619.35,461.06,619.98z"
              />
              <path d="M689.77,608.73c-2.37-0.66-2.51-0.99-1.21-2.97C688.99,606.81,689.33,607.64,689.77,608.73z" />
              <path
                d="M264.82,441.81c-0.84,0.58-1.33,1.18-1.73,1.13c-0.45-0.06-0.83-0.71-1.24-1.1c0.4-0.41,0.76-1.07,1.21-1.14
	C263.47,440.64,263.97,441.24,264.82,441.81z"
              />
              <path
                d="M515.12,326.19c-0.85,0.57-1.28,1.06-1.79,1.15c-0.74,0.14-1.29-0.47-1.14-1.15c0.1-0.47,0.72-1.1,1.15-1.14
	C513.77,325.01,514.27,325.61,515.12,326.19z"
              />
              <path
                d="M234.4,256.63c-0.85,0.56-1.29,1.04-1.79,1.13c-0.74,0.13-1.27-0.48-1.12-1.15c0.11-0.47,0.72-1.09,1.16-1.13
	C233.08,255.44,233.57,256.05,234.4,256.63z"
              />
              <path
                d="M356.27,285.89c-0.83,0.56-1.32,1.15-1.75,1.11c-0.43-0.04-1.12-0.72-1.11-1.11c0-0.4,0.68-1.08,1.11-1.12
	C354.95,284.72,355.44,285.32,356.27,285.89z"
              />
              <path
                d="M351.54,295.13c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C350.54,296.47,351.01,296.01,351.54,295.13z"
              />
              <path
                d="M287.64,454.26c-0.77,0.58-1.17,1.07-1.67,1.2c-0.7,0.19-1.2-0.28-1.27-1.01c-0.07-0.82,0.35-1.44,1.19-1.33
	C286.4,453.2,286.83,453.71,287.64,454.26z"
              />
              <path
                d="M440.91,323.45c0.57,0.87,1.16,1.38,1.1,1.8c-0.06,0.44-0.75,1.07-1.17,1.08c-0.38,0-1.03-0.71-1.07-1.15
	C439.73,324.76,440.33,324.29,440.91,323.45z"
              />
              <path
                d="M315.51,191.71c-0.81,0.63-1.21,1.14-1.71,1.27c-0.73,0.19-1.21-0.32-1.26-1.06c-0.04-0.72,0.32-1.33,1.08-1.25
	C314.14,190.73,314.6,191.19,315.51,191.71z"
              />
              <path
                d="M520.57,284.09c0.57,0.88,1.17,1.4,1.11,1.81c-0.07,0.44-0.71,1.01-1.19,1.12c-0.74,0.16-1.23-0.48-1.1-1.17
	C519.47,285.35,519.98,284.93,520.57,284.09z"
              />
              <path
                d="M260.57,451.09c0.53,0.91,1.09,1.45,1.01,1.86c-0.09,0.43-0.75,0.97-1.23,1.05c-0.73,0.12-1.19-0.53-1.03-1.21
	C259.44,452.29,259.95,451.9,260.57,451.09z"
              />
              <path
                d="M298.23,410.12c-0.83,0.59-1.25,1.09-1.76,1.2c-0.73,0.16-1.3-0.42-1.17-1.11c0.09-0.47,0.68-1.12,1.12-1.17
	C296.85,408.97,297.36,409.56,298.23,410.12z"
              />
              <path
                d="M289.66,412.4c-0.68-0.8-1.33-1.22-1.32-1.64c0.01-0.44,0.56-1.1,1.01-1.26c0.69-0.25,1.27,0.29,1.23,1.01
	C290.55,411.01,290.13,411.49,289.66,412.4z"
              />
              <path
                d="M503.19,253.11c-0.69-0.8-1.34-1.23-1.33-1.65c0.01-0.45,0.56-1.1,1.02-1.27c0.7-0.25,1.29,0.29,1.25,1.01
	C504.1,251.7,503.67,252.19,503.19,253.11z"
              />
              <path
                d="M340.62,233.47c-0.96,0.39-1.56,0.86-1.95,0.72c-0.43-0.15-0.66-0.86-0.98-1.33c0.48-0.32,0.97-0.9,1.42-0.87
	C339.52,232.02,339.89,232.71,340.62,233.47z"
              />
              <path
                d="M344.28,189.33c-0.8,0.61-1.2,1.12-1.69,1.25c-0.72,0.18-1.31-0.35-1.2-1.05c0.08-0.47,0.63-1.13,1.05-1.21
	C342.86,188.24,343.39,188.8,344.28,189.33z"
              />
              <path
                d="M321.79,77.3c0.62,0.83,1.25,1.3,1.22,1.72c-0.03,0.44-0.63,1.05-1.1,1.19c-0.7,0.2-1.26-0.37-1.17-1.08
	C320.8,78.62,321.27,78.17,321.79,77.3z"
              />
              <path
                d="M310.4,233.72c0.57,0.87,1.16,1.38,1.1,1.8c-0.06,0.44-0.75,1.07-1.17,1.08c-0.38,0-1.03-0.71-1.07-1.15
	C309.22,235.03,309.81,234.56,310.4,233.72z"
              />
              <path
                d="M517.72,315.2c-0.87,0.55-1.39,1.13-1.8,1.07c-0.45-0.07-0.81-0.74-1.2-1.16c0.42-0.39,0.81-1.04,1.27-1.09
	C516.4,313.98,516.88,314.6,517.72,315.2z"
              />
              <path
                d="M522.97,293.12c-0.81,0.55-1.29,1.13-1.71,1.09c-0.45-0.04-1.11-0.62-1.2-1.06c-0.17-0.79,0.43-1.38,1.24-1.24
	C521.78,292,522.19,292.53,522.97,293.12z"
              />
              <path
                d="M339.75,238.52c0.53,0.91,1.09,1.45,1.01,1.86c-0.09,0.43-0.75,0.97-1.23,1.05c-0.73,0.12-1.19-0.53-1.03-1.21
	C338.61,239.72,339.12,239.33,339.75,238.52z"
              />
              <path
                d="M354.9,291.29c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C353.9,292.63,354.37,292.18,354.9,291.29z"
              />
              <path
                d="M347.36,185.58c-0.84,0.58-1.33,1.18-1.73,1.13c-0.45-0.06-0.83-0.71-1.24-1.1c0.4-0.41,0.76-1.07,1.21-1.14
	C346,184.41,346.5,185.01,347.36,185.58z"
              />
              <path d="M332.36,13.5c-1.01,2.2-1.59,2.27-3.13,0.3C330.37,13.69,331.29,13.6,332.36,13.5z" />
              <path
                d="M291.72,417.89c-0.87,0.55-1.39,1.13-1.8,1.07c-0.45-0.07-0.81-0.74-1.2-1.16c0.42-0.39,0.81-1.04,1.27-1.09
	C290.39,416.66,290.87,417.28,291.72,417.89z"
              />
              <path
                d="M241.41,418.33c0.85-0.59,1.34-1.2,1.75-1.15c0.46,0.06,0.83,0.72,1.25,1.12c-0.4,0.4-0.77,1.07-1.23,1.13
	C242.77,419.5,242.27,418.9,241.41,418.33z"
              />
              <path
                d="M458.83,368.97c-0.57-0.84-1.17-1.35-1.12-1.77c0.05-0.43,0.7-1.02,1.18-1.12c0.68-0.13,1.25,0.42,1.09,1.16
	C459.88,367.74,459.39,368.16,458.83,368.97z"
              />
              <path
                d="M285.08,485.28c0.57,0.74,1.05,1.11,1.14,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-0.99
	C284.07,486.56,284.59,486.08,285.08,485.28z"
              />
              <path
                d="M240.58,185.42c-0.46-0.91-0.88-1.39-0.91-1.88c-0.04-0.71,0.54-1.24,1.23-0.99c0.45,0.16,0.99,0.82,1,1.26
	C241.9,184.22,241.25,184.64,240.58,185.42z"
              />
              <path
                d="M362.62,84.76c0.46,0.95,0.91,1.47,0.86,1.93c-0.04,0.37-0.83,1.02-1.06,0.93c-0.48-0.18-1.1-0.74-1.13-1.18
	C361.25,86.04,361.92,85.59,362.62,84.76z"
              />
              <path
                d="M463.43,356.3c-0.98,0.37-1.53,0.78-1.98,0.68c-0.36-0.07-0.92-0.91-0.82-1.13c0.22-0.45,0.82-0.99,1.26-0.99
	C462.28,354.87,462.67,355.55,463.43,356.3z"
              />
              <path
                d="M349.51,87.7c-0.61,0.5-1.07,1.14-1.36,1.07c-0.49-0.12-0.88-0.68-1.31-1.06c0.43-0.38,0.81-0.96,1.3-1.08
	C348.43,86.56,348.9,87.2,349.51,87.7z"
              />
              <path
                d="M252.71,444.65c0.61,0.81,1.24,1.29,1.2,1.7c-0.05,0.45-0.71,0.83-1.11,1.25c-0.4-0.39-1.06-0.75-1.12-1.19
	C251.62,446,252.19,445.49,252.71,444.65z"
              />
              <path
                d="M460.95,390.32c-0.67-0.84-1.31-1.3-1.28-1.7c0.04-0.44,0.65-0.97,1.12-1.16c0.21-0.08,0.98,0.57,1.02,0.94
	C461.86,388.87,461.41,389.37,460.95,390.32z"
              />
              <path
                d="M449.41,174.6c-0.64-0.77-1.29-1.2-1.28-1.62c0.01-0.43,0.59-1.07,1.05-1.21c0.64-0.2,1.29,0.26,1.2,1.02
	C450.32,173.29,449.88,173.75,449.41,174.6z"
              />
              <path
                d="M384.79,117.29c-0.88,0.55-1.4,1.14-1.79,1.07c-0.45-0.09-0.79-0.76-1.18-1.18c0.42-0.38,0.82-1.02,1.27-1.07
	C383.5,116.07,383.97,116.68,384.79,117.29z"
              />
              <path
                d="M307,546.3c0.64-0.44,1.16-1.04,1.42-0.94c0.47,0.17,1.06,0.74,1.1,1.19c0.07,0.68-0.56,1.19-1.28,0.96
	C307.84,547.38,307.55,546.85,307,546.3z"
              />
              <path
                d="M245.41,425.47c-0.85,0.58-1.29,1.1-1.77,1.13c-0.34,0.03-0.93-0.55-1.05-0.95c-0.2-0.68,0.25-1.32,0.98-1.25
	C244.07,424.44,244.53,424.93,245.41,425.47z"
              />
              <path
                d="M299.54,483.99c0.56,0.82,1.15,1.31,1.1,1.72c-0.05,0.45-0.7,0.83-1.09,1.24c-0.41-0.4-1.09-0.77-1.15-1.21
	C298.35,485.33,298.96,484.83,299.54,483.99z"
              />
              <path
                d="M319.77,178.6c-0.57-0.75-1.13-1.18-1.17-1.65c-0.03-0.35,0.57-0.97,1-1.08c0.74-0.19,1.25,0.38,1.18,1.1
	C320.74,177.4,320.28,177.81,319.77,178.6z"
              />
              <path
                d="M265.54,448.98c-0.84,0.57-1.28,1.09-1.76,1.13c-0.34,0.03-0.93-0.54-1.04-0.95c-0.2-0.68,0.25-1.31,0.98-1.25
	C264.21,447.96,264.67,448.45,265.54,448.98z"
              />
              <path
                d="M306.83,257.65c-0.85,0.52-1.37,1.08-1.79,1.02c-0.42-0.06-1.06-0.76-1.04-1.15c0.02-0.4,0.72-1.04,1.14-1.06
	C305.57,256.44,306.03,257.05,306.83,257.65z"
              />
              <path
                d="M347.88,295.21c-0.59-0.74-1.07-1.1-1.16-1.54c-0.16-0.73,0.33-1.32,1.06-1.23c0.42,0.05,1.08,0.63,1.09,0.98
	C348.87,293.91,348.36,294.4,347.88,295.21z"
              />
              <path
                d="M311.43,560.66c-0.95,0.55-1.5,1.13-1.88,1.03c-0.45-0.12-0.92-0.77-1.04-1.28c-0.05-0.23,0.71-0.93,1.1-0.92
	C310.08,559.5,310.53,560.04,311.43,560.66z"
              />
              <path
                d="M258.39,449.6c-0.79,0.65-1.23,1.29-1.67,1.29c-0.43,0-1.08-0.59-1.24-1.05c-0.23-0.69,0.3-1.3,1.03-1.25
	C257.02,448.63,257.5,449.1,258.39,449.6z"
              />
              <path
                d="M516.94,287.93c0.7,0.89,1.26,1.3,1.32,1.78c0.04,0.34-0.58,1-0.97,1.06c-0.35,0.06-1.1-0.47-1.14-0.81
	C516.08,289.49,516.51,288.95,516.94,287.93z"
              />
              <path
                d="M290.68,490.65c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.97-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C289.61,489.22,289.97,489.91,290.68,490.65z"
              />
              <path
                d="M283.51,490.89c-0.88,0.51-1.41,1.06-1.8,0.97c-0.44-0.09-0.76-0.76-1.13-1.18c0.43-0.37,0.83-0.99,1.28-1.02
	C282.27,489.64,282.72,490.26,283.51,490.89z"
              />
              <path d="M342.68,634.09c-0.97,0-1.79,0-2.58,0c-0.29-1.1,0.22-1.57,1.02-1.66C342.02,632.33,342.63,632.75,342.68,634.09z" />
              <path
                d="M447.47,256.2c-0.85,0.66-1.26,1.21-1.75,1.3c-0.69,0.13-1.29-0.37-1.15-1.12c0.08-0.42,0.59-1.05,0.94-1.07
	C445.99,255.28,446.51,255.73,447.47,256.2z"
              />
              <path
                d="M233.31,392c-0.57-0.84-1.16-1.34-1.11-1.77c0.05-0.43,0.7-1.02,1.17-1.11c0.68-0.13,1.25,0.42,1.09,1.15
	C234.35,390.77,233.87,391.19,233.31,392z"
              />
              <path
                d="M278.87,310.29c0.9-0.53,1.38-1.04,1.83-1.02c0.37,0.01,1.06,0.75,1,0.99c-0.13,0.48-0.65,1.1-1.09,1.18
	C280.22,311.5,279.73,310.89,278.87,310.29z"
              />
              <path
                d="M487.95,390.2c-0.96,0.47-1.47,0.93-1.93,0.88c-0.38-0.04-1.03-0.83-0.95-1.05c0.19-0.48,0.74-1.07,1.19-1.12
	C486.65,388.87,487.11,389.52,487.95,390.2z"
              />
              <path
                d="M452.81,257.29c-0.52-0.79-0.97-1.19-1.02-1.63c-0.08-0.72,0.44-1.29,1.18-1.11c0.42,0.11,1.03,0.73,1,1.08
	C453.93,256.11,453.37,256.54,452.81,257.29z"
              />
              <path
                d="M243.46,174.28c-0.56-0.75-1.12-1.19-1.15-1.66c-0.02-0.35,0.58-0.97,1.01-1.08c0.74-0.18,1.25,0.39,1.17,1.11
	C244.44,173.09,243.98,173.49,243.46,174.28z"
              />
              <path
                d="M360.9,173.05c0.91-0.54,1.46-1.11,1.84-1.02c0.43,0.1,0.91,0.77,1,1.26c0.05,0.25-0.7,0.94-1.07,0.92
	C362.22,174.2,361.77,173.66,360.9,173.05z"
              />
              <path
                d="M267.02,423.61c-0.93,0.55-1.41,1.05-1.9,1.05c-0.33,0-0.93-0.69-0.93-1.07c0.01-0.38,0.61-1.08,0.94-1.07
	C265.63,422.53,266.1,423.04,267.02,423.61z"
              />
              <path
                d="M461.94,377.4c0.64-0.45,1.17-1.05,1.44-0.96c0.48,0.17,1.08,0.74,1.13,1.2c0.07,0.68-0.57,1.21-1.29,0.98
	C462.79,378.49,462.5,377.95,461.94,377.4z"
              />
              <path
                d="M314.68,184.99c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.97-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C313.6,183.56,313.96,184.25,314.68,184.99z"
              />
              <path
                d="M507.15,256.21c-0.58-0.8-1.08-1.21-1.19-1.7c-0.17-0.73,0.37-1.31,1.07-1.18c0.48,0.08,1.14,0.65,1.21,1.09
	C508.29,254.83,507.7,255.35,507.15,256.21z"
              />
              <path
                d="M358.44,84.88c-0.6-0.78-1.1-1.16-1.22-1.65c-0.18-0.71,0.33-1.31,1.03-1.19c0.48,0.08,1.15,0.61,1.22,1.04
	C359.54,83.49,358.97,84.02,358.44,84.88z"
              />
              <path
                d="M343.47,182.59c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.96-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C342.39,181.17,342.75,181.85,343.47,182.59z"
              />
              <path
                d="M509.06,341.19c-0.82,0.64-1.28,1.27-1.68,1.23c-0.45-0.04-0.86-0.67-1.28-1.04c0.38-0.42,0.71-1.08,1.15-1.17
	C507.65,340.12,508.18,340.67,509.06,341.19z"
              />
              <path
                d="M311.86,211.83c-0.59-0.87-1.11-1.31-1.14-1.77c-0.02-0.37,0.65-1.12,0.88-1.07c0.5,0.11,1.17,0.56,1.27,0.98
	C312.97,210.37,312.4,210.91,311.86,211.83z"
              />
              <path
                d="M331.34,314.99c-0.5-0.89-1.04-1.42-0.96-1.82c0.09-0.43,0.76-0.75,1.17-1.12c0.37,0.42,1.01,0.83,1.04,1.27
	C332.62,313.73,331.99,314.17,331.34,314.99z"
              />
              <path d="M305.5,43.61c1.04,1.58,0.83,2.02-1.09,2.34C304.33,44.66,304.33,44.66,305.5,43.61z" />
              <path
                d="M325.82,84.2c-0.52-0.95-1.07-1.51-0.97-1.89c0.11-0.43,0.8-0.85,1.29-0.95c0.23-0.04,0.88,0.73,0.85,1.1
	C326.97,82.91,326.44,83.34,325.82,84.2z"
              />
              <path
                d="M281.14,628.4c-0.9,0.5-1.44,1.05-1.83,0.96c-0.45-0.11-0.76-0.78-1.13-1.21c0.44-0.35,0.86-0.96,1.31-0.99
	C279.89,627.14,280.33,627.76,281.14,628.4z"
              />
              <path
                d="M373.09,296.8c-0.5-0.95-0.98-1.46-0.95-1.93c0.02-0.37,0.78-1.06,1.01-0.98c0.49,0.17,1.13,0.69,1.19,1.13
	C374.39,295.42,373.75,295.91,373.09,296.8z"
              />
              <path
                d="M490.66,252.65c-0.95,0.51-1.43,0.98-1.94,1c-0.74,0.04-1.19-0.6-0.96-1.29c0.13-0.41,0.76-0.94,1.12-0.91
	C489.35,251.49,489.79,252.03,490.66,252.65z"
              />
              <path
                d="M456.74,197.6c-0.84,0.56-1.33,1.16-1.73,1.1c-0.45-0.06-0.82-0.71-1.22-1.11c0.4-0.4,0.77-1.05,1.23-1.11
	C455.42,196.42,455.91,197.02,456.74,197.6z"
              />
              <path
                d="M503.95,343.38c0.57,0.88,1.08,1.34,1.09,1.81c0.01,0.37-0.7,1.1-0.93,1.04c-0.5-0.12-1.18-0.59-1.27-1.03
	C502.76,344.81,503.37,344.28,503.95,343.38z"
              />
              <path
                d="M456.21,250.01c0.6,0.91,1.2,1.43,1.14,1.84c-0.07,0.43-0.75,0.92-1.24,1.05c-0.24,0.06-0.96-0.66-0.95-1.03
	C455.15,251.4,455.65,250.92,456.21,250.01z"
              />
              <path
                d="M452.94,194.36c-0.82,0.65-1.28,1.3-1.69,1.26c-0.46-0.04-0.87-0.69-1.3-1.07c0.39-0.43,0.72-1.12,1.17-1.21
	C451.52,193.25,452.05,193.83,452.94,194.36z"
              />
              <path
                d="M459.45,392.58c-0.95,0.48-1.45,0.93-1.96,0.94c-0.76,0.01-1.15-0.66-0.9-1.34c0.15-0.41,0.79-0.94,1.14-0.89
	C458.21,391.36,458.62,391.92,459.45,392.58z"
              />
              <path
                d="M293.87,422.95c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C294.86,421.56,294.38,422.04,293.87,422.95z"
              />
              <path
                d="M270.26,482.66c-0.56-0.84-1.15-1.34-1.1-1.76c0.05-0.43,0.7-1.01,1.16-1.1c0.67-0.13,1.23,0.43,1.08,1.15
	C271.3,481.45,270.82,481.86,270.26,482.66z"
              />
              <path
                d="M309.2,539.24c-0.61,0.49-1.07,1.12-1.36,1.04c-0.49-0.12-0.87-0.68-1.29-1.06c0.43-0.38,0.81-0.94,1.3-1.06
	C308.14,538.1,308.6,538.74,309.2,539.24z"
              />
              <path
                d="M311.63,314.95c-0.85,0.55-1.29,1.05-1.79,1.11c-0.68,0.08-1.13-0.42-1.13-1.18c0.01-0.77,0.52-1.22,1.18-1.11
	C310.39,313.87,310.82,314.38,311.63,314.95z"
              />
              <path
                d="M268.45,358.5c-0.81,0.61-1.28,1.24-1.71,1.21c-0.43-0.02-1.04-0.63-1.18-1.1c-0.21-0.71,0.36-1.27,1.07-1.18
	C267.14,357.49,267.59,357.97,268.45,358.5z"
              />
              <path
                d="M602.87,176.26c1.26,0.31,2.18,1.59,1.71,2.51c-0.12,0.23-0.78,0.19-1.19,0.28c0,0,0.06,0.07,0.06,0.07
	c-0.16-0.98-0.32-1.96-0.48-2.94L602.87,176.26z"
              />
              <path
                d="M457.98,378.12c-0.8,0.63-1.24,1.25-1.67,1.24c-0.42-0.01-1.05-0.58-1.2-1.04c-0.22-0.68,0.31-1.27,1.02-1.19
	C456.64,377.18,457.11,377.63,457.98,378.12z"
              />
              <path
                d="M250.67,443.25c-0.84,0.56-1.29,1.07-1.77,1.1c-0.33,0.02-0.91-0.55-1.02-0.96c-0.19-0.68,0.27-1.3,0.99-1.22
	C249.36,442.21,249.81,442.7,250.67,443.25z"
              />
              <path
                d="M457.04,347.38c-0.49-0.92-0.96-1.42-0.94-1.89c0.02-0.35,0.78-1.02,1.03-0.94c0.48,0.15,1.13,0.69,1.17,1.11
	C458.34,346.07,457.69,346.54,457.04,347.38z"
              />
              <path
                d="M345.48,263.25c0.54,0.93,1.12,1.49,1.02,1.88c-0.12,0.43-0.82,0.87-1.32,0.96c-0.24,0.04-0.9-0.73-0.88-1.09
	C344.33,264.53,344.87,264.1,345.48,263.25z"
              />
              <path
                d="M232.2,251.41c-0.58-0.85-1.1-1.29-1.14-1.76c-0.02-0.35,0.65-1.1,0.89-1.05c0.5,0.09,1.21,0.54,1.3,0.96
	C233.34,249.96,232.75,250.51,232.2,251.41z"
              />
              <path
                d="M338.31,184.29c0.61,0.86,1.14,1.3,1.18,1.77c0.03,0.36-0.5,0.98-0.9,1.12c-0.7,0.24-1.31-0.26-1.3-0.96
	C337.3,185.72,337.78,185.24,338.31,184.29z"
              />
              <path
                d="M484.83,247.42c0.53,0.91,1.02,1.39,1.01,1.85c-0.01,0.37-0.74,1.07-0.98,1c-0.49-0.14-1.15-0.64-1.22-1.08
	C483.58,248.8,484.21,248.29,484.83,247.42z"
              />
              <path
                d="M282.53,496.18c0.59,0.9,1.17,1.4,1.1,1.8c-0.07,0.42-0.72,0.9-1.2,1.01c-0.24,0.06-0.93-0.64-0.93-1
	C281.5,497.54,281.99,497.08,282.53,496.18z"
              />
              <path
                d="M300.16,265.57c-0.94,0.53-1.42,1-1.93,1.03c-0.74,0.05-1.2-0.59-0.98-1.28c0.13-0.41,0.75-0.95,1.1-0.92
	C298.84,264.44,299.28,264.97,300.16,265.57z"
              />
              <path
                d="M484.85,394.02c-0.93,0.47-1.43,0.92-1.93,0.93c-0.75,0.01-1.14-0.65-0.89-1.33c0.15-0.4,0.78-0.93,1.12-0.88
	C483.63,392.81,484.04,393.37,484.85,394.02z"
              />
              <path
                d="M519.59,297.29c-1,0.37-1.55,0.78-2,0.69c-0.36-0.08-0.93-0.91-0.83-1.14c0.22-0.46,0.83-1,1.27-1
	C518.44,295.84,518.83,296.53,519.59,297.29z"
              />
              <path
                d="M369.24,104.1c-0.91,0.53-1.4,1.02-1.89,1.02c-0.35,0-0.91-0.62-1-1.04c-0.15-0.71,0.35-1.32,1.09-1.2
	C367.95,102.96,368.38,103.49,369.24,104.1z"
              />
              <path
                d="M330.82,78.67c-0.85,0.52-1.37,1.08-1.79,1.02c-0.42-0.06-1.06-0.76-1.04-1.15c0.02-0.4,0.72-1.04,1.14-1.06
	C329.56,77.46,330.02,78.07,330.82,78.67z"
              />
              <path
                d="M489.62,384.41c0.52,0.9,1,1.37,1,1.84c0,0.36-0.72,1.06-0.95,1c-0.49-0.14-1.14-0.63-1.21-1.06
	C488.4,385.78,489.01,385.28,489.62,384.41z"
              />
              <path
                d="M251.67,267.73c-0.64-0.85-1.27-1.33-1.23-1.74c0.05-0.42,0.7-0.95,1.19-1.09c0.25-0.07,1,0.6,1.01,0.96
	C252.65,266.33,252.17,266.82,251.67,267.73z"
              />
              <path
                d="M314.12,177.51c-0.79,0.53-1.31,1.11-1.59,1.01c-0.47-0.17-0.96-0.73-1.1-1.22c-0.06-0.22,0.71-0.97,1.06-0.95
	C312.95,176.38,313.38,176.95,314.12,177.51z"
              />
              <path
                d="M302.73,420.63c-0.9,0.5-1.44,1.05-1.83,0.96c-0.45-0.11-0.76-0.78-1.13-1.21c0.44-0.35,0.86-0.96,1.31-0.99
	C301.49,419.37,301.93,419.99,302.73,420.63z"
              />
              <path
                d="M496,376.49c0.61,0.88,1.24,1.4,1.17,1.79c-0.08,0.44-0.74,0.93-1.23,1.07c-0.24,0.06-0.96-0.65-0.97-1.01
	C494.96,377.87,495.46,377.39,496,376.49z"
              />
              <path
                d="M449.57,198.3c-0.85,0.59-1.34,1.21-1.74,1.15c-0.46-0.07-0.82-0.73-1.23-1.13c0.4-0.4,0.77-1.06,1.22-1.13
	C448.22,197.13,448.72,197.73,449.57,198.3z"
              />
              <path
                d="M353.86,177.63c-0.82,0.6-1.29,1.2-1.72,1.17c-0.42-0.03-1.02-0.64-1.15-1.11c-0.2-0.71,0.39-1.24,1.08-1.14
	C352.57,176.62,353.01,177.1,353.86,177.63z"
              />
              <path
                d="M422.1,148.13c0.61,0.81,1.23,1.28,1.19,1.68c-0.05,0.44-0.71,0.83-1.1,1.23c-0.4-0.39-1.05-0.74-1.12-1.18
	C421.01,149.47,421.57,148.96,422.1,148.13z"
              />
              <path
                d="M468.28,247.41c-0.9,0.51-1.45,1.06-1.84,0.97c-0.45-0.11-0.76-0.79-1.13-1.23c0.44-0.36,0.86-0.98,1.32-1
	C467.04,246.13,467.48,246.76,468.28,247.41z"
              />
              <path
                d="M349.12,179.8c0.48,0.86,1.02,1.39,0.94,1.79c-0.09,0.43-0.81,1.08-1.16,1.03c-0.43-0.06-1.03-0.75-1.06-1.19
	C347.81,181.03,348.46,180.58,349.12,179.8z"
              />
              <path
                d="M463.27,395.61c-0.87,0.64-1.31,1.18-1.79,1.23c-0.33,0.03-0.99-0.6-1.02-0.98c-0.03-0.39,0.5-1.14,0.82-1.16
	C461.77,394.67,462.3,395.13,463.27,395.61z"
              />
              <path
                d="M473.91,248.33c-0.69-0.86-1.34-1.33-1.3-1.73c0.04-0.45,0.66-0.99,1.14-1.18c0.22-0.08,1.01,0.58,1.04,0.95
	C474.83,246.84,474.38,247.36,473.91,248.33z"
              />
              <path
                d="M270.15,356.15c-0.59-0.74-1.07-1.09-1.17-1.53c-0.17-0.73,0.32-1.32,1.05-1.23c0.42,0.05,1.08,0.62,1.09,0.98
	C271.14,354.84,270.63,355.33,270.15,356.15z"
              />
              <path
                d="M264.6,454.47c0.5,0.91,0.98,1.39,0.96,1.86c-0.01,0.36-0.74,1.05-0.97,0.98c-0.49-0.15-1.13-0.65-1.19-1.08
	C263.36,455.82,263.98,455.33,264.6,454.47z"
              />
              <path
                d="M266.32,476.14c0.49,0.82,1,1.3,0.99,1.78c0,0.35-0.65,0.92-1.08,0.98c-0.75,0.11-1.23-0.48-1.07-1.21
	C265.26,477.25,265.74,476.89,266.32,476.14z"
              />
              <path
                d="M397.05,134.23c-0.86,0.57-1.32,1.08-1.8,1.11c-0.34,0.02-0.93-0.56-1.04-0.97c-0.19-0.69,0.27-1.32,1.01-1.24
	C395.71,133.18,396.17,133.68,397.05,134.23z"
              />
              <path
                d="M482.66,245.92c-0.8,0.64-1.24,1.26-1.64,1.23c-0.45-0.03-0.86-0.64-1.29-1c0.37-0.43,0.68-1.1,1.12-1.2
	C481.23,244.86,481.77,245.41,482.66,245.92z"
              />
              <path
                d="M257.48,458.68c-0.65-0.78-1.29-1.21-1.28-1.63c0.01-0.43,0.59-1.08,1.05-1.22c0.65-0.2,1.29,0.28,1.2,1.03
	C258.39,457.35,257.95,457.81,257.48,458.68z"
              />
              <path
                d="M456.09,396.44c-0.83,0.61-1.31,1.23-1.74,1.2c-0.43-0.03-1.04-0.66-1.17-1.14c-0.2-0.72,0.4-1.27,1.1-1.18
	C454.78,395.38,455.23,395.89,456.09,396.44z"
              />
              <path
                d="M517.03,308.12c-0.95,0.48-1.44,0.94-1.95,0.95c-0.76,0.02-1.16-0.65-0.91-1.33c0.15-0.41,0.78-0.94,1.13-0.9
	C515.78,306.91,516.19,307.46,517.03,308.12z"
              />
              <path
                d="M317.87,74.2c0.67,0.85,1.32,1.32,1.27,1.71c-0.05,0.45-0.68,0.98-1.17,1.15c-0.24,0.08-1-0.59-1.03-0.96
	C316.91,75.64,317.38,75.14,317.87,74.2z"
              />
              <path
                d="M275.55,420.79c-0.57-0.84-1.18-1.34-1.13-1.76c0.05-0.43,0.69-1.03,1.17-1.13c0.67-0.14,1.26,0.41,1.11,1.15
	C276.58,419.55,276.1,419.98,275.55,420.79z"
              />
              <path
                d="M284.8,441.9c-0.64-0.79-1.29-1.23-1.27-1.66c0.02-0.44,0.61-1.09,1.07-1.22c0.65-0.19,1.29,0.29,1.2,1.05
	C285.74,440.58,285.29,441.04,284.8,441.9z"
              />
              <path
                d="M452.64,333.3c0.55,0.84,1.14,1.34,1.08,1.76c-0.06,0.45-0.72,0.83-1.12,1.24c-0.41-0.41-1.09-0.8-1.14-1.25
	C451.42,334.63,452.04,334.14,452.64,333.3z"
              />
              <path
                d="M281.88,429.67c-0.86,0.53-1.32,1.01-1.82,1.07c-0.69,0.07-1.12-0.45-1.1-1.2c0.03-0.77,0.54-1.21,1.21-1.07
	C280.67,428.57,281.08,429.08,281.88,429.67z"
              />
              <path
                d="M308.82,572.21c-1.01,0.39-1.63,0.86-2.01,0.71c-0.4-0.16-0.74-0.91-0.76-1.41c-0.01-0.25,0.83-0.81,1.17-0.73
	C307.69,570.89,308.04,571.48,308.82,572.21z"
              />
              <path
                d="M340.88,193.21c-0.83,0.59-1.26,1.11-1.74,1.15c-0.34,0.03-0.93-0.53-1.06-0.94c-0.21-0.68,0.23-1.31,0.96-1.26
	C339.53,192.2,340,192.68,340.88,193.21z"
              />
              <path
                d="M246.72,333.35c0.57,0.8,1.06,1.2,1.16,1.69c0.15,0.72-0.41,1.29-1.07,1.17c-0.47-0.08-1.11-0.67-1.18-1.11
	C245.57,334.69,246.16,334.18,246.72,333.35z"
              />
              <path
                d="M377.54,118.3c-0.98,0.37-1.53,0.78-1.98,0.68c-0.36-0.07-0.92-0.91-0.82-1.13c0.22-0.45,0.82-0.99,1.26-0.99
	C376.39,116.87,376.78,117.55,377.54,118.3z"
              />
              <path
                d="M305.61,369.69c-0.88,0.55-1.39,1.13-1.79,1.05c-0.45-0.09-0.79-0.75-1.17-1.17c0.42-0.37,0.81-1,1.26-1.05
	C304.31,368.48,304.78,369.08,305.61,369.69z"
              />
              <path
                d="M308.98,531.93c-0.85,0.62-1.28,1.15-1.73,1.18c-0.36,0.02-1.1-0.63-1.06-0.88c0.08-0.48,0.53-1.15,0.95-1.27
	C307.51,530.86,308.06,531.42,308.98,531.93z"
              />
              <path
                d="M309.29,183.24c-0.64-0.77-1.29-1.2-1.28-1.62c0.01-0.43,0.59-1.07,1.05-1.21c0.64-0.2,1.29,0.26,1.2,1.02
	C310.2,181.93,309.77,182.39,309.29,183.24z"
              />
              <path
                d="M316.17,198.98c-0.88,0.6-1.32,1.13-1.79,1.15c-0.37,0.01-1.11-0.68-1.06-0.93c0.1-0.49,0.58-1.15,1.01-1.26
	C314.72,197.85,315.26,198.43,316.17,198.98z"
              />
              <path
                d="M501.18,374.29c-0.8,0.63-1.24,1.26-1.68,1.25c-0.43-0.01-1.06-0.59-1.21-1.05c-0.22-0.69,0.31-1.28,1.03-1.21
	C499.83,373.32,500.3,373.78,501.18,374.29z"
              />
              <path
                d="M461.34,374.28c-0.91,0.5-1.4,0.98-1.88,0.97c-0.35-0.01-1.01-0.77-0.94-1.02c0.13-0.48,0.68-1.13,1.1-1.16
	C460.03,373.04,460.51,373.66,461.34,374.28z"
              />
              <path
                d="M268.77,631.16c0.57,0.74,1.05,1.11,1.14,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-0.99
	C267.76,632.43,268.27,631.95,268.77,631.16z"
              />
              <path
                d="M337.68,216.95c0.59,0.88,1.18,1.4,1.12,1.8c-0.07,0.42-0.73,0.91-1.22,1.04c-0.23,0.06-0.94-0.66-0.93-1.01
	C336.65,218.3,337.14,217.83,337.68,216.95z"
              />
              <path
                d="M370.17,91.23c0.5,0.91,0.98,1.39,0.96,1.86c-0.01,0.36-0.74,1.05-0.97,0.98c-0.49-0.15-1.13-0.65-1.19-1.08
	C368.93,92.58,369.55,92.09,370.17,91.23z"
              />
              <path
                d="M479.51,396.22c0.69,0.75,1.36,1.16,1.35,1.57c0,0.44-0.59,1.15-1.01,1.23c-0.36,0.06-1.1-0.54-1.21-0.97
	C478.55,397.65,479.07,397.1,479.51,396.22z"
              />
              <path
                d="M608.41,634.08c-0.6-0.82-1.2-1.28-1.16-1.69c0.04-0.44,0.66-0.84,1.03-1.25c0.42,0.38,1.1,0.7,1.18,1.14
	C609.54,632.68,608.96,633.2,608.41,634.08z"
              />
              <path
                d="M299.36,361.08c-0.59-0.89-1.11-1.34-1.12-1.81c-0.01-0.37,0.68-1.12,0.91-1.06c0.5,0.11,1.17,0.58,1.26,1.01
	C300.5,359.61,299.92,360.15,299.36,361.08z"
              />
              <path
                d="M345.22,180.15c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C346.2,178.76,345.72,179.24,345.22,180.15z"
              />
              <path
                d="M312.24,551.31c-0.53-0.83-1-1.26-1.09-1.77c-0.14-0.75,0.47-1.27,1.13-1.12c0.47,0.11,1.09,0.72,1.13,1.16
	C313.45,550,312.83,550.49,312.24,551.31z"
              />
              <path
                d="M312.61,242.72c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.08c0.01-0.38,0.62-1.07,0.95-1.06
	C311.22,241.63,311.69,242.15,312.61,242.72z"
              />
              <path
                d="M316.43,182.55c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C317.41,181.16,316.93,181.64,316.43,182.55z"
              />
              <path
                d="M314.74,556.82c-0.87,0.56-1.4,1.16-1.8,1.08c-0.46-0.09-0.8-0.77-1.19-1.2c0.42-0.39,0.82-1.04,1.28-1.09
	C313.44,555.58,313.92,556.21,314.74,556.82z"
              />
              <path
                d="M339.51,198.69c0.64,0.87,1.28,1.35,1.23,1.76c-0.05,0.43-0.69,0.95-1.18,1.11c-0.23,0.07-0.98-0.61-0.99-0.97
	C338.55,200.12,339.02,199.62,339.51,198.69z"
              />
              <path
                d="M266.25,416.25c-0.84,0.64-1.31,1.28-1.69,1.23c-0.45-0.06-0.99-0.64-1.16-1.12c-0.08-0.23,0.59-1,0.96-1.03
	C264.82,415.28,265.32,415.76,266.25,416.25z"
              />
              <path
                d="M458.68,385.49c-0.86,0.57-1.37,1.16-1.76,1.09c-0.45-0.08-0.8-0.74-1.19-1.15c0.41-0.38,0.79-1.02,1.24-1.07
	C457.36,384.3,457.84,384.9,458.68,385.49z"
              />
              <path
                d="M236.62,221.9c-0.55-0.88-1.14-1.39-1.07-1.79c0.08-0.44,0.76-0.77,1.18-1.15c0.37,0.41,1,0.8,1.04,1.25
	C237.81,220.61,237.21,221.08,236.62,221.9z"
              />
              <path
                d="M355.24,175.59c-0.45-0.94-0.91-1.46-0.86-1.93c0.03-0.36,0.82-0.99,1.07-0.9c0.48,0.17,1.1,0.74,1.12,1.17
	C356.6,174.33,355.93,174.78,355.24,175.59z"
              />
              <path
                d="M262.9,460.11c-0.86,0.55-1.37,1.13-1.78,1.06c-0.45-0.07-0.8-0.74-1.19-1.15c0.42-0.39,0.8-1.04,1.25-1.09
	C261.59,458.89,262.07,459.51,262.9,460.11z"
              />
              <path
                d="M465.25,397.49c0.57,0.74,1.13,1.16,1.19,1.64c0.04,0.34-0.56,0.96-0.99,1.08c-0.75,0.21-1.26-0.35-1.2-1.08
	C464.3,398.69,464.76,398.28,465.25,397.49z"
              />
              <path
                d="M456.37,399.54c0.93-0.55,1.48-1.12,1.87-1.03c0.44,0.11,0.92,0.78,1.01,1.27c0.05,0.25-0.71,0.95-1.08,0.93
	C457.7,400.69,457.26,400.15,456.37,399.54z"
              />
              <path d="M616.71,239.75c0.41,1.14,0.72,2,1.09,3.01C615.76,242.45,615.62,242.06,616.71,239.75z" />
              <path
                d="M268.49,378.18c0.64,0.85,1.27,1.33,1.22,1.74c-0.05,0.43-0.69,0.94-1.17,1.1c-0.22,0.07-0.97-0.6-0.98-0.96
	C267.54,379.58,268,379.09,268.49,378.18z"
              />
              <path
                d="M346.17,317.24c0.66,0.84,1.3,1.3,1.27,1.7c-0.04,0.43-0.65,0.96-1.13,1.14c-0.22,0.08-0.99-0.58-1.01-0.93
	C345.26,318.67,345.71,318.17,346.17,317.24z"
              />
              <path
                d="M514.37,319.18c-0.97,0.48-1.49,0.94-1.95,0.89c-0.38-0.04-1.04-0.84-0.96-1.06c0.19-0.48,0.74-1.08,1.2-1.13
	C513.05,317.85,513.52,318.5,514.37,319.18z"
              />
              <path
                d="M265.64,429.16c0.57,0.74,1.13,1.16,1.19,1.64c0.04,0.34-0.56,0.96-0.99,1.08c-0.75,0.21-1.26-0.35-1.2-1.08
	C264.69,430.36,265.14,429.95,265.64,429.16z"
              />
              <path
                d="M269.83,625.75c-0.64,0.44-1.16,1.03-1.44,0.93c-0.47-0.16-0.8-0.76-1.19-1.17c0.46-0.34,0.88-0.87,1.38-0.96
	C268.87,624.51,269.28,625.19,269.83,625.75z"
              />
              <path
                d="M266.52,439.75c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C267.5,438.35,267.03,438.83,266.52,439.75z"
              />
              <path
                d="M467.07,359.28c-0.6,0.51-1.05,1.15-1.35,1.09c-0.49-0.11-0.89-0.67-1.32-1.05c0.42-0.39,0.8-0.97,1.29-1.1
	C465.98,358.14,466.45,358.78,467.07,359.28z"
              />
              <path
                d="M502.25,361.62c0.46,0.95,0.91,1.47,0.86,1.93c-0.04,0.37-0.83,1.02-1.06,0.93c-0.48-0.18-1.09-0.74-1.14-1.19
	C500.88,362.91,501.56,362.46,502.25,361.62z"
              />
              <path
                d="M301.3,319.54c-0.92,0.53-1.4,1.03-1.86,1.01c-0.37-0.01-1.07-0.76-1-0.99c0.14-0.49,0.66-1.12,1.1-1.2
	C299.92,318.3,300.42,318.93,301.3,319.54z"
              />
              <path
                d="M286.64,463.14c-0.56-0.75-1.12-1.19-1.15-1.66c-0.02-0.35,0.58-0.97,1.01-1.08c0.74-0.18,1.25,0.39,1.17,1.11
	C287.62,461.96,287.16,462.36,286.64,463.14z"
              />
              <path
                d="M380.97,280.17c-0.92,0.55-1.4,1.06-1.87,1.05c-0.37-0.01-1.09-0.74-1.03-0.98c0.14-0.49,0.65-1.14,1.09-1.22
	C379.56,278.95,380.08,279.56,380.97,280.17z"
              />
              <path
                d="M463.99,363.15c-0.91,0.57-1.38,1.08-1.85,1.08c-0.37,0-1.1-0.73-1.04-0.97c0.13-0.5,0.63-1.15,1.07-1.23
	C462.56,361.96,463.09,362.56,463.99,363.15z"
              />
              <path
                d="M265.84,409.53c-0.97,0.54-1.52,1.1-1.9,1c-0.45-0.12-0.9-0.77-1.03-1.28c-0.05-0.22,0.73-0.91,1.11-0.9
	C264.49,408.37,264.93,408.9,265.84,409.53z"
              />
              <path
                d="M460.13,359.9c-0.82,0.65-1.28,1.3-1.69,1.26c-0.46-0.04-0.87-0.69-1.3-1.07c0.39-0.43,0.72-1.12,1.17-1.21
	C458.71,358.8,459.25,359.37,460.13,359.9z"
              />
              <path
                d="M476.44,403.54c-0.59-0.81-1.09-1.22-1.21-1.72c-0.17-0.73,0.38-1.32,1.08-1.21c0.48,0.08,1.15,0.67,1.22,1.1
	C477.59,402.14,477,402.67,476.44,403.54z"
              />
              <path
                d="M299.71,179.13c-0.84,0.43-1.42,0.95-1.69,0.82c-0.45-0.21-0.87-0.81-0.96-1.31c-0.04-0.23,0.8-0.9,1.15-0.83
	C298.67,177.89,299.03,178.49,299.71,179.13z"
              />
              <path
                d="M457.44,351.12c0.69,0.75,1.36,1.16,1.35,1.57c0,0.44-0.59,1.15-1.01,1.23c-0.36,0.06-1.1-0.54-1.21-0.97
	C456.48,352.55,456.99,352,457.44,351.12z"
              />
              <path
                d="M511.72,330.19c-0.81,0.61-1.27,1.22-1.7,1.2c-0.42-0.02-1.03-0.62-1.17-1.08c-0.21-0.7,0.36-1.25,1.06-1.16
	C510.42,329.2,510.87,329.67,511.72,330.19z"
              />
              <path
                d="M504.64,350.62c0.6,0.81,1.22,1.29,1.18,1.69c-0.05,0.45-0.71,0.82-1.11,1.23c-0.39-0.39-1.04-0.75-1.11-1.19
	C503.54,351.95,504.11,351.45,504.64,350.62z"
              />
              <path
                d="M318.83,188.13c-0.92,0.49-1.4,0.95-1.9,0.97c-0.75,0.03-1.16-0.63-0.92-1.3c0.14-0.41,0.76-0.96,1.1-0.91
	C317.58,186.95,318,187.5,318.83,188.13z"
              />
              <path
                d="M453.66,201.32c-0.79,0.61-1.18,1.12-1.66,1.25c-0.71,0.18-1.3-0.34-1.19-1.04c0.07-0.47,0.62-1.13,1.03-1.2
	C452.26,200.25,452.79,200.8,453.66,201.32z"
              />
              <path
                d="M461.87,404.74c-0.5-0.94-0.97-1.44-0.94-1.9c0.02-0.37,0.78-1.05,0.99-0.98c0.49,0.17,1.12,0.68,1.18,1.12
	C463.15,403.38,462.51,403.87,461.87,404.74z"
              />
              <path
                d="M339.43,178.86c-0.6,0.51-1.05,1.15-1.35,1.09c-0.49-0.11-0.89-0.67-1.32-1.05c0.42-0.39,0.8-0.97,1.29-1.1
	C338.34,177.72,338.81,178.36,339.43,178.86z"
              />
              <path
                d="M371.92,116.23c-0.49-0.81-1-1.3-0.99-1.77c0.01-0.36,0.66-0.94,1.08-0.99c0.73-0.1,1.22,0.48,1.07,1.22
	C372.98,115.13,372.5,115.48,371.92,116.23z"
              />
              <path
                d="M275.45,626.78c-0.48-0.82-0.99-1.31-0.97-1.78c0.01-0.36,0.67-0.93,1.09-0.98c0.73-0.09,1.22,0.5,1.05,1.23
	C276.52,625.69,276.04,626.04,275.45,626.78z"
              />
              <path
                d="M385.75,273.96c-0.58-0.8-1.08-1.2-1.19-1.7c-0.17-0.73,0.37-1.31,1.07-1.18c0.48,0.08,1.14,0.65,1.21,1.09
	C386.89,272.58,386.3,273.1,385.75,273.96z"
              />
              <path
                d="M617.31,631.67c-0.86,0.55-1.32,1.06-1.82,1.12c-0.7,0.08-1.15-0.47-1.13-1.22c0.02-0.78,0.53-1.25,1.22-1.13
	C616.07,630.53,616.49,631.06,617.31,631.67z"
              />
              <path
                d="M463.03,368.68c0.48,0.8,0.99,1.29,0.99,1.77c0,0.35-0.66,0.92-1.08,0.98c-0.74,0.11-1.22-0.48-1.06-1.21
	C461.97,369.78,462.45,369.42,463.03,368.68z"
              />
              <path
                d="M446,321.41c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.07c0.01-0.38,0.61-1.07,0.94-1.06
	C444.61,320.33,445.09,320.84,446,321.41z"
              />
              <path
                d="M265.03,465.2c-0.6-0.9-1.21-1.42-1.14-1.81c0.08-0.44,0.73-0.92,1.23-1.06c0.23-0.06,0.95,0.66,0.95,1.02
	C266.07,463.82,265.57,464.29,265.03,465.2z"
              />
              <path
                d="M259.29,270.67c0.53,0.91,1.02,1.39,1.01,1.86c0,0.36-0.73,1.07-0.96,1c-0.49-0.14-1.15-0.64-1.21-1.07
	C258.07,272.06,258.68,271.55,259.29,270.67z"
              />
              <path
                d="M397.96,327.27c0.54,0.92,1.03,1.41,1.02,1.88c0,0.36-0.73,1.08-0.97,1.01c-0.5-0.14-1.16-0.64-1.23-1.07
	C396.73,328.68,397.35,328.17,397.96,327.27z"
              />
              <path
                d="M393.22,130.91c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.07c0.01-0.38,0.61-1.07,0.94-1.06
	C391.83,129.83,392.3,130.34,393.22,130.91z"
              />
              <path
                d="M504.28,370.65c-0.88,0.55-1.4,1.14-1.79,1.07c-0.45-0.09-0.79-0.76-1.18-1.18c0.42-0.38,0.82-1.02,1.27-1.07
	C502.97,369.42,503.45,370.04,504.28,370.65z"
              />
              <path
                d="M281.4,279.5c-0.63-0.88-1.26-1.38-1.2-1.78c0.06-0.44,0.71-0.94,1.2-1.1c0.22-0.07,0.96,0.63,0.98,0.99
	C282.39,278.09,281.91,278.58,281.4,279.5z"
              />
              <path
                d="M405.27,330.1c-0.66-0.83-1.31-1.3-1.27-1.71c0.04-0.43,0.68-0.97,1.16-1.12c0.25-0.08,1.02,0.58,1.04,0.93
	C406.22,328.68,405.75,329.18,405.27,330.1z"
              />
              <path
                d="M249.93,436.12c-0.85,0.54-1.35,1.12-1.75,1.06c-0.45-0.07-0.79-0.73-1.18-1.14c0.41-0.39,0.79-1.03,1.24-1.08
	C248.63,434.91,249.11,435.52,249.93,436.12z"
              />
              <path
                d="M462.79,348.58c-0.87,0.64-1.3,1.19-1.78,1.23c-0.33,0.03-0.99-0.59-1.03-0.97c-0.03-0.39,0.49-1.14,0.81-1.17
	C461.28,347.64,461.81,348.1,462.79,348.58z"
              />
              <path
                d="M374.01,300.44c0.51,0.87,1.06,1.39,0.99,1.8c-0.07,0.43-0.77,1.08-1.15,1.05c-0.41-0.04-1.04-0.72-1.07-1.16
	C372.76,301.71,373.39,301.26,374.01,300.44z"
              />
              <path
                d="M379.36,305.8c-0.84,0.43-1.42,0.95-1.69,0.82c-0.45-0.21-0.87-0.81-0.96-1.31c-0.04-0.23,0.8-0.89,1.15-0.83
	C378.32,304.56,378.68,305.16,379.36,305.8z"
              />
              <path
                d="M416.08,163.31c0.57,0.74,1.04,1.11,1.13,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-1
	C415.07,164.58,415.59,164.11,416.08,163.31z"
              />
              <path d="M508.67,634.04c-0.91,0-1.74,0-2.6,0c-0.13-1.06,0.32-1.52,1.12-1.59C508.12,632.38,508.62,632.87,508.67,634.04z" />
              <path
                d="M460.19,379.59c0.61,0.86,1.14,1.3,1.18,1.77c0.03,0.36-0.5,0.98-0.9,1.12c-0.7,0.24-1.31-0.26-1.3-0.96
	C459.18,381.01,459.66,380.53,460.19,379.59z"
              />
              <path
                d="M265.67,472.38c-0.54-0.87-1.1-1.38-1.04-1.79c0.07-0.44,0.73-0.78,1.14-1.17c0.39,0.41,1.05,0.79,1.1,1.24
	C266.91,471.07,266.29,471.54,265.67,472.38z"
              />
              <path
                d="M308.77,551.88c0.65,0.84,1.28,1.3,1.24,1.7c-0.04,0.43-0.65,0.96-1.12,1.13c-0.22,0.08-0.98-0.57-1-0.93
	C307.86,553.32,308.31,552.82,308.77,551.88z"
              />
              <path
                d="M412.46,329.61c-0.65-0.78-1.3-1.22-1.29-1.64c0.01-0.44,0.6-1.09,1.06-1.23c0.65-0.2,1.3,0.28,1.21,1.03
	C413.38,328.28,412.94,328.75,412.46,329.61z"
              />
              <path
                d="M256.43,324.95c-0.57-0.75-1.13-1.18-1.17-1.65c-0.03-0.35,0.57-0.97,1-1.08c0.74-0.19,1.25,0.38,1.18,1.1
	C257.4,323.75,256.94,324.16,256.43,324.95z"
              />
              <path
                d="M226.58,372.66c0.9-0.53,1.44-1.1,1.82-1.01c0.43,0.1,0.9,0.76,0.99,1.25c0.05,0.25-0.7,0.93-1.06,0.92
	C227.87,373.8,227.44,373.26,226.58,372.66z"
              />
              <path
                d="M382.43,277.94c-0.59-0.74-1.07-1.09-1.17-1.53c-0.17-0.73,0.32-1.32,1.05-1.23c0.42,0.05,1.08,0.62,1.09,0.98
	C383.42,276.63,382.91,277.12,382.43,277.94z"
              />
              <path
                d="M409,163.69c0.52,0.9,1,1.37,1,1.84c0,0.36-0.72,1.06-0.95,1c-0.49-0.14-1.14-0.63-1.21-1.06
	C407.79,165.06,408.4,164.56,409,163.69z"
              />
              <path
                d="M334.18,407.14c-0.87,0.52-1.33,1-1.83,1.05c-0.7,0.07-1.12-0.47-1.08-1.21c0.03-0.77,0.56-1.2,1.22-1.06
	C332.98,406.02,333.39,406.54,334.18,407.14z"
              />
              <path
                d="M297.95,323.68c-0.89,0.53-1.44,1.1-1.84,1.02c-0.45-0.1-0.78-0.79-1.16-1.23c0.44-0.37,0.86-1.01,1.32-1.04
	C296.68,322.39,297.14,323.04,297.95,323.68z"
              />
              <path
                d="M424.4,323.34c-0.88,0.54-1.35,1.04-1.83,1.06c-0.34,0.01-0.91-0.59-1.01-1c-0.17-0.7,0.32-1.31,1.04-1.21
	C423.09,322.25,423.53,322.76,424.4,323.34z"
              />
              <path
                d="M386.8,118.82c0.6,0.86,1.13,1.3,1.16,1.76c0.02,0.36-0.65,1.12-0.89,1.08c-0.51-0.1-1.2-0.55-1.31-0.98
	C385.66,120.3,386.25,119.74,386.8,118.82z"
              />
              <path
                d="M449.8,324.73c-0.85,0.56-1.29,1.07-1.78,1.1c-0.34,0.02-0.92-0.56-1.03-0.97c-0.19-0.69,0.27-1.31,1-1.23
	C448.49,323.69,448.94,324.18,449.8,324.73z"
              />
              <path
                d="M394.61,165.1c0.53,0.91,1.02,1.39,1.01,1.86c0,0.36-0.73,1.07-0.96,1c-0.49-0.14-1.15-0.64-1.21-1.07
	C393.38,166.5,394,165.99,394.61,165.1z"
              />
              <path
                d="M231.11,363.44c-0.67-0.79-1.32-1.21-1.31-1.63c0.01-0.44,0.56-1.09,1.01-1.25c0.69-0.24,1.26,0.29,1.22,1
	C232,362.06,231.58,362.54,231.11,363.44z"
              />
              <path
                d="M270.2,273.33c0.6,0.86,1.13,1.3,1.16,1.76c0.02,0.36-0.65,1.12-0.89,1.08c-0.51-0.1-1.2-0.55-1.31-0.98
	C269.06,274.81,269.65,274.25,270.2,273.33z"
              />
              <path
                d="M282.71,626.17c-0.56-0.9-1.16-1.44-1.08-1.85c0.08-0.42,0.79-0.88,1.29-0.98c0.25-0.05,0.93,0.69,0.91,1.04
	C283.8,624.87,283.29,625.31,282.71,626.17z"
              />
              <path
                d="M253.09,325.39c0.66,0.79,1.3,1.23,1.29,1.64c-0.01,0.43-0.57,1.07-1.02,1.23c-0.69,0.24-1.26-0.3-1.21-1.01
	C252.18,326.76,252.61,326.29,253.09,325.39z"
              />
              <path
                d="M492.54,384.09c-0.47-0.94-0.93-1.44-0.89-1.9c0.03-0.36,0.79-1.03,1-0.95c0.48,0.18,1.1,0.7,1.14,1.14
	C493.84,382.77,493.2,383.24,492.54,384.09z"
              />
              <path
                d="M517.68,275.39c-0.9,0.53-1.38,1.03-1.83,1.01c-0.37-0.01-1.06-0.75-1-0.98c0.14-0.48,0.65-1.11,1.09-1.19
	C516.33,274.17,516.83,274.79,517.68,275.39z"
              />
              <path
                d="M350.01,273.55c0.51,0.92,0.99,1.41,0.98,1.88c-0.01,0.36-0.75,1.05-0.98,0.99c-0.49-0.15-1.14-0.66-1.2-1.09
	C348.76,274.92,349.39,274.42,350.01,273.55z"
              />
              <path
                d="M238.72,409.75c-0.63-0.85-1.27-1.33-1.23-1.74c0.04-0.42,0.7-0.95,1.19-1.09c0.25-0.07,0.99,0.6,1,0.96
	C239.7,408.36,239.22,408.85,238.72,409.75z"
              />
              <path
                d="M263.64,467.21c-0.8,0.58-1.27,1.18-1.69,1.15c-0.43-0.03-1.11-0.67-1.12-1.07c-0.01-0.4,0.63-1.09,1.05-1.14
	C262.3,466.09,262.8,466.67,263.64,467.21z"
              />
              <path
                d="M467.53,366.47c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.39,0.79-0.96,1.28-1.09
	C466.45,365.35,466.93,365.98,467.53,366.47z"
              />
              <path
                d="M378.32,291.03c-0.87,0.62-1.3,1.15-1.77,1.19c-0.33,0.03-0.97-0.61-1-0.98c-0.03-0.38,0.5-1.12,0.83-1.14
	C376.84,290.08,377.35,290.54,378.32,291.03z"
              />
              <path
                d="M336.17,182.75c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.96-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C335,181.66,335.44,182.21,336.17,182.75z"
              />
              <path
                d="M268.91,445.22c-0.92,0.53-1.4,1.02-1.88,1.02c-0.33,0-0.92-0.69-0.91-1.07c0.01-0.38,0.61-1.06,0.94-1.05
	C267.55,444.14,268.01,444.65,268.91,445.22z"
              />
              <path
                d="M301.01,588.09c-0.1-0.11-0.26-0.21-0.28-0.33c-0.18-0.8-0.19-1.62,0.73-1.94c0.22-0.08,0.83,0.36,0.95,0.68
	C302.67,587.24,302.39,587.51,301.01,588.09z"
              />
              <path
                d="M321.77,184.19c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.97-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C320.61,183.1,321.04,183.65,321.77,184.19z"
              />
              <path
                d="M317.63,193.57c0.48,0.79,0.99,1.26,0.98,1.74c0,0.35-0.65,0.92-1.06,0.98c-0.73,0.11-1.22-0.47-1.06-1.19
	C316.58,194.65,317.06,194.29,317.63,193.57z"
              />
              <path
                d="M352.88,289.59c-0.87,0.62-1.3,1.16-1.77,1.2c-0.33,0.03-0.98-0.6-1-0.98c-0.03-0.38,0.5-1.12,0.82-1.14
	C351.41,288.64,351.92,289.1,352.88,289.59z"
              />
              <path
                d="M292.02,464.9c-0.76,0.53-1.27,1.12-1.55,1.03c-0.47-0.16-0.96-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.04-0.96
	C290.86,463.8,291.29,464.36,292.02,464.9z"
              />
              <path
                d="M499.97,253.55c0.48,0.79,0.99,1.26,0.98,1.74c0,0.35-0.65,0.92-1.06,0.98c-0.73,0.11-1.21-0.47-1.06-1.19
	C498.92,254.63,499.4,254.27,499.97,253.55z"
              />
              <path d="M451.8,9.69c1.17,0.15,1.88,0.23,2.7,0.34C453.41,11.96,453.22,11.96,451.8,9.69z" />
              <path
                d="M470.45,355.49c-0.92,0.53-1.4,1.02-1.88,1.02c-0.33,0-0.92-0.7-0.91-1.07c0.01-0.38,0.62-1.06,0.94-1.05
	C469.08,354.41,469.55,354.92,470.45,355.49z"
              />
              <path
                d="M299.95,364.87c0.57,0.73,1.05,1.08,1.14,1.52c0.15,0.72-0.33,1.3-1.06,1.19c-0.42-0.06-1.06-0.63-1.06-0.98
	C298.96,366.13,299.47,365.65,299.95,364.87z"
              />
              <path
                d="M387.56,165.59c0.44,1,0.87,1.53,0.81,2c-0.04,0.34-0.77,0.87-1.12,0.82c-0.39-0.06-1.01-0.71-0.97-1.04
	C386.33,166.89,386.88,166.47,387.56,165.59z"
              />
              <path d="M592.87,634.07c0.15-1.41,0.66-2.04,1.73-1.65c0.36,0.13,0.82,0.99,0.69,1.25C594.75,634.73,593.86,634.15,592.87,634.07z" />
              <path
                d="M514.43,302.39c-0.48-0.8-0.98-1.28-0.97-1.75c0.01-0.36,0.65-0.93,1.07-0.98c0.71-0.09,1.21,0.48,1.05,1.21
	C515.48,301.3,515,301.66,514.43,302.39z"
              />
              <path d="M429.07,634.13c-0.8,0-1.46,0-2.1,0c-0.49-1.07-0.1-1.71,0.84-1.79C428.7,632.26,429.23,632.81,429.07,634.13z" />
              <path
                d="M695.11,630.37c0.51,0.63,1.03,0.99,1.11,1.42c0.13,0.72-0.38,1.28-1.12,1.12c-0.39-0.09-0.91-0.68-0.94-1.07
	C694.14,631.45,694.66,631.02,695.11,630.37z"
              />
              <path
                d="M261.2,319.45c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.38,0.79-0.96,1.28-1.08
	C260.12,318.33,260.59,318.96,261.2,319.45z"
              />
              <path
                d="M271.31,474c-0.91,0.54-1.39,1.03-1.87,1.03c-0.33,0-0.92-0.69-0.92-1.06c0-0.38,0.61-1.06,0.93-1.05
	C269.94,472.93,270.41,473.44,271.31,474z"
              />
              <path
                d="M298.15,370.32c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.38,0.79-0.96,1.28-1.08
	C297.07,369.19,297.54,369.82,298.15,370.32z"
              />
              <path
                d="M273.31,415.95c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.97-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C272.14,414.86,272.58,415.41,273.31,415.95z"
              />
              <path
                d="M229.16,365.59c-0.76,0.52-1.28,1.11-1.55,1.01c-0.47-0.16-0.95-0.71-1.09-1.19c-0.06-0.23,0.69-0.97,1.04-0.95
	C228.01,364.49,228.44,365.04,229.16,365.59z"
              />
              <path
                d="M322.24,397.44c-0.82,0.43-1.39,0.95-1.66,0.82c-0.45-0.21-0.87-0.8-0.95-1.29c-0.04-0.23,0.79-0.89,1.13-0.83
	C321.21,396.22,321.57,396.81,322.24,397.44z"
              />
              <path
                d="M350.56,308.19c-0.82,0.43-1.39,0.95-1.66,0.82c-0.45-0.21-0.87-0.8-0.95-1.29c-0.04-0.23,0.79-0.89,1.13-0.83
	C349.52,306.97,349.88,307.56,350.56,308.19z"
              />
              <path
                d="M518.43,282.57c-0.92,0.53-1.41,1.01-1.89,1.01c-0.33,0-0.91-0.7-0.9-1.08c0.01-0.38,0.62-1.06,0.95-1.04
	C517.07,281.48,517.54,281.99,518.43,282.57z"
              />
              <path d="M469.07,634.11c-0.87,0.06-1.59,0.1-2.34,0.15c-0.36-1.01-0.08-1.56,0.77-1.71C468.49,632.38,468.88,632.96,469.07,634.11z" />
              <path
                d="M506.63,332.39c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C505.55,333.69,506.06,333.25,506.63,332.39z"
              />
              <path
                d="M548.35,634.09c-0.81,0-1.63,0-2.54,0c0.04-1.03,0.52-1.57,1.29-1.47c0.56,0.07,1.05,0.6,1.58,0.93
	C548.57,633.73,548.46,633.91,548.35,634.09z"
              />
              <path
                d="M508.81,317.48c-0.55-0.75-1.09-1.17-1.13-1.64c-0.03-0.32,0.59-0.9,1.01-1c0.71-0.17,1.27,0.31,1.16,1.07
	C509.77,316.34,509.33,316.72,508.81,317.48z"
              />
              <path
                d="M645.7,461.1c0,0,0.08,0.08,0.08,0.08c-0.11-0.54-0.33-1.08-0.31-1.62c0.02-0.52,0.27-1.04,0.42-1.56
	c0.18,0.03,0.37,0.05,0.55,0.08c0.08,0.76,0.26,1.52,0.2,2.27C646.62,460.62,646.03,460.85,645.7,461.1z"
              />
              <path
                d="M468.5,373.7c-0.89,0.54-1.36,1.03-1.84,1.04c-0.32,0-0.91-0.68-0.91-1.05c0-0.38,0.6-1.06,0.92-1.05
	C467.14,372.65,467.61,373.15,468.5,373.7z"
              />
              <path
                d="M317.34,173.62c-0.9,0.53-1.37,1.02-1.84,1.02c-0.32,0-0.91-0.68-0.9-1.05c0.01-0.38,0.6-1.05,0.92-1.04
	C316,172.56,316.46,173.06,317.34,173.62z"
              />
              <path
                d="M344.77,296.05c0.49,0.78,0.91,1.18,0.96,1.61c0.08,0.77-0.51,1.21-1.2,1.03c-0.41-0.11-1-0.73-0.97-1.05
	C343.62,297.17,344.18,296.77,344.77,296.05z"
              />
              <path
                d="M310.7,78.15c-0.48-0.9-0.94-1.38-0.92-1.84c0.02-0.35,0.76-1,1-0.93c0.48,0.14,1.12,0.67,1.17,1.09
	C311.98,76.86,311.34,77.33,310.7,78.15z"
              />
              <path
                d="M296.76,269.31c-0.64,0.51-0.98,0.98-1.41,1.08c-0.72,0.17-1.25-0.31-1.27-1.04c-0.02-0.74,0.51-1.21,1.23-1.07
	C295.74,268.36,296.1,268.82,296.76,269.31z"
              />
              <path
                d="M341.76,247.5c-0.87,0.4-1.4,0.83-1.88,0.79c-0.33-0.03-0.82-0.72-0.85-1.14c-0.06-0.79,0.58-1.15,1.29-0.93
	C340.75,246.35,341.06,246.85,341.76,247.5z"
              />
              <path
                d="M230.24,380.04c-0.65,0.43-1.05,0.91-1.49,0.94c-0.74,0.05-1.21-0.54-0.97-1.25c0.13-0.39,0.76-0.86,1.14-0.84
	C229.31,378.93,229.67,379.51,230.24,380.04z"
              />
              <path
                d="M496.82,347.46c-0.54-0.73-1.09-1.16-1.12-1.62c-0.02-0.34,0.62-0.73,0.97-1.1c0.4,0.38,0.99,0.7,1.13,1.16
	C497.88,346.2,497.32,346.7,496.82,347.46z"
              />
              <path
                d="M688.08,631.11c0.47,0.63,1.08,1.14,0.99,1.4c-0.17,0.46-0.72,1.03-1.17,1.1c-0.72,0.11-1.18-0.51-0.97-1.23
	C687.05,631.97,687.56,631.67,688.08,631.11z"
              />
              <path
                d="M308.19,421.73c-0.57-0.87-1.17-1.38-1.1-1.78c0.07-0.41,0.75-0.89,1.23-0.99c0.25-0.05,0.94,0.65,0.92,1
	C309.23,420.42,308.73,420.87,308.19,421.73z"
              />
              <path
                d="M503.41,336.33c0.58,0.74,1.05,1.09,1.15,1.52c0.17,0.76-0.38,1.28-1.08,1.18c-0.42-0.06-1.08-0.63-1.08-0.97
	C502.39,337.59,502.91,337.12,503.41,336.33z"
              />
              <path
                d="M424.71,263.64c0.56,0.72,1.16,1.17,1.09,1.48c-0.1,0.47-0.66,0.84-1.02,1.25c-0.37-0.34-1.04-0.68-1.04-1.02
	C423.74,264.89,424.24,264.42,424.71,263.64z"
              />
              <path
                d="M465.7,384.79c-0.76,0.51-1.27,1.09-1.54,0.99c-0.46-0.17-0.94-0.72-1.07-1.2c-0.06-0.22,0.69-0.96,1.04-0.93
	C464.58,383.69,464.99,384.25,465.7,384.79z"
              />
              <path
                d="M455.77,329.63c0.52,0.78,1.04,1.23,1.05,1.69c0,0.34-0.64,0.91-1.05,0.98c-0.7,0.12-1.26-0.38-1.1-1.14
	C454.76,330.72,455.22,330.37,455.77,329.63z"
              />
              <path
                d="M458.24,338.41c-0.92,0.59-1.44,1.17-1.82,1.09c-0.44-0.09-0.91-0.72-1.06-1.21c-0.06-0.21,0.66-0.91,1.04-0.92
	C456.84,337.37,457.31,337.86,458.24,338.41z"
              />
              <path
                d="M291.53,354.42c-0.49-0.78-0.92-1.17-0.97-1.6c-0.09-0.76,0.48-1.23,1.19-1.03c0.41,0.12,1.02,0.72,0.98,1.03
	C292.67,353.29,292.11,353.69,291.53,354.42z"
              />
              <path
                d="M356.99,316.28c-0.57-0.72-1.17-1.16-1.11-1.47c0.1-0.47,0.65-0.85,1.01-1.27c0.37,0.34,1.04,0.67,1.05,1.02
	C357.95,315.01,357.45,315.49,356.99,316.28z"
              />
              <path
                d="M253.48,452.01c0.42,0.82,0.89,1.32,0.84,1.77c-0.04,0.34-0.71,0.82-1.14,0.86c-0.74,0.06-1.18-0.53-0.96-1.24
	C252.34,452.98,252.84,452.68,253.48,452.01z"
              />
              <path
                d="M243.46,340.53c-0.56-0.76-1.1-1.18-1.15-1.66c-0.03-0.32,0.6-0.92,1.02-1.02c0.72-0.17,1.28,0.32,1.17,1.08
	C244.43,339.38,243.98,339.76,243.46,340.53z"
              />
              <path
                d="M470.97,402.37c-0.96,0.5-1.44,0.94-1.94,0.96c-0.73,0.03-1.16-0.6-0.92-1.28c0.14-0.39,0.75-0.9,1.11-0.87
	C469.68,401.24,470.11,401.76,470.97,402.37z"
              />
              <path
                d="M466.57,351.95c-0.84,0.62-1.27,1.15-1.73,1.2c-0.32,0.03-0.97-0.59-1-0.96c-0.03-0.38,0.49-1.11,0.8-1.13
	C465.12,351.03,465.63,351.48,466.57,351.95z"
              />
              <path
                d="M340.86,173.3c0.67,0.86,1.22,1.28,1.27,1.74c0.04,0.33-0.57,0.98-0.96,1.04c-0.34,0.05-1.07-0.47-1.11-0.81
	C340,174.8,340.43,174.28,340.86,173.3z"
              />
              <path
                d="M333.5,419.71c0.62,0.84,1.15,1.25,1.18,1.71c0.03,0.36-0.61,1.11-0.85,1.07c-0.49-0.08-1.18-0.5-1.3-0.92
	C332.42,421.2,332.98,420.64,333.5,419.71z"
              />
              <path
                d="M302.08,81.04c-0.75,0.52-1.27,1.1-1.54,1.01c-0.46-0.16-0.94-0.71-1.08-1.19c-0.06-0.22,0.68-0.96,1.03-0.94
	C300.95,79.95,301.36,80.5,302.08,81.04z"
              />
              <path
                d="M381.24,287.56c-0.81,0.42-1.38,0.93-1.64,0.81c-0.44-0.21-0.85-0.8-0.93-1.28c-0.04-0.23,0.78-0.88,1.11-0.82
	C380.23,286.35,380.58,286.94,381.24,287.56z"
              />
              <path
                d="M338.43,211.5c-0.58,0.49-1.03,1.12-1.32,1.05c-0.48-0.11-0.86-0.67-1.28-1.04c0.42-0.38,0.79-0.94,1.27-1.06
	C337.38,210.38,337.84,211.01,338.43,211.5z"
              />
              <path
                d="M367.77,89.71c-0.59,0.57-0.88,1.09-1.3,1.22c-0.7,0.22-1.38-0.22-1.32-0.93c0.04-0.44,0.64-1.02,1.11-1.17
	C366.56,88.72,367.08,89.28,367.77,89.71z"
              />
              <path
                d="M274.43,280.05c-0.57-0.72-1.03-1.07-1.13-1.5c-0.17-0.75,0.35-1.27,1.07-1.15c0.42,0.07,1.07,0.62,1.07,0.94
	C275.43,278.81,274.92,279.27,274.43,280.05z"
              />
              <path
                d="M359.14,321.61c-0.64,0.51-0.98,0.98-1.41,1.08c-0.72,0.17-1.25-0.31-1.27-1.04c-0.02-0.74,0.51-1.21,1.23-1.07
	C358.12,320.66,358.48,321.12,359.14,321.61z"
              />
              <path d="M263.36,622.36c0.81-0.1,1.45-0.18,2.06-0.26c0.42,0.94,0.2,1.49-0.51,1.71C264.07,624.08,263.55,623.68,263.36,622.36z" />
              <path
                d="M245.84,432.83c-0.67,0.44-1.13,0.98-1.49,0.93c-0.41-0.06-0.74-0.66-1.11-1.02c0.43-0.38,0.8-0.94,1.3-1.07
	C244.8,431.6,245.26,432.28,245.84,432.83z"
              />
              <path
                d="M512.17,273.83c-0.56-0.76-1.1-1.18-1.15-1.66c-0.03-0.32,0.6-0.92,1.02-1.02c0.72-0.17,1.28,0.32,1.17,1.08
	C513.14,272.68,512.69,273.06,512.17,273.83z"
              />
              <path
                d="M304.51,481.75c-0.94,0.65-1.38,1.17-1.86,1.21c-0.34,0.02-0.98-0.59-1.02-0.96c-0.04-0.36,0.5-1.08,0.84-1.11
	C302.95,480.84,303.47,481.27,304.51,481.75z"
              />
              <path
                d="M382.46,634.05c-0.86,0-1.72,0-2.68,0c0.04-1,0.53-1.54,1.31-1.44c0.56,0.08,1.06,0.61,1.58,0.94
	C382.6,633.72,382.53,633.89,382.46,634.05z"
              />
              <path
                d="M308.1,255.34c-0.47-0.78-0.97-1.26-0.96-1.72c0.01-0.35,0.67-0.69,1.04-1.03c0.36,0.42,0.92,0.8,1.02,1.27
	C309.27,254.17,308.67,254.62,308.1,255.34z"
              />
              <path
                d="M493.92,374.97c-0.84,0.63-1.26,1.17-1.72,1.22c-0.32,0.03-0.97-0.58-1-0.95c-0.03-0.38,0.48-1.12,0.79-1.14
	C492.46,374.06,492.97,374.51,493.92,374.97z"
              />
              <path
                d="M283.82,451.08c-0.86,0.62-1.28,1.14-1.77,1.21c-0.68,0.1-1.26-0.4-1.1-1.14c0.09-0.41,0.62-1,0.96-1.01
	C282.39,450.13,282.88,450.59,283.82,451.08z"
              />
              <path
                d="M249.27,262.64c-0.91,0.54-1.37,1.02-1.87,1.06c-0.71,0.07-1.21-0.51-1-1.22c0.12-0.4,0.7-0.95,1.05-0.93
	C247.94,261.58,248.39,262.08,249.27,262.64z"
              />
              <path
                d="M282.85,443.88c-0.61,0.55-0.92,1.06-1.34,1.18c-0.72,0.21-1.37-0.27-1.29-0.97c0.05-0.44,0.68-1,1.15-1.14
	C281.66,442.86,282.17,443.43,282.85,443.88z"
              />
              <path
                d="M340.7,252.73c0.61,0.84,1.14,1.26,1.18,1.71c0.03,0.36-0.62,1.11-0.86,1.07c-0.49-0.08-1.17-0.51-1.3-0.93
	C339.62,254.21,340.18,253.65,340.7,252.73z"
              />
              <path
                d="M436.9,323.83c-0.48-0.9-0.94-1.38-0.92-1.84c0.02-0.35,0.76-1,1-0.93c0.48,0.14,1.12,0.67,1.17,1.09
	C438.18,322.54,437.54,323.01,436.9,323.83z"
              />
              <path
                d="M390.03,320.97c0.52,0.79,1.06,1.24,1.06,1.71c0,0.35-0.64,0.93-1.07,1c-0.7,0.11-1.27-0.38-1.11-1.16
	C389.02,322.08,389.48,321.72,390.03,320.97z"
              />
              <path
                d="M429.85,320.87c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C428.78,322.17,429.29,321.73,429.85,320.87z"
              />
              <path
                d="M507.63,263.28c-0.57-0.73-1.04-1.08-1.14-1.52c-0.17-0.76,0.36-1.28,1.08-1.17c0.43,0.07,1.09,0.63,1.08,0.96
	C508.65,262.02,508.13,262.49,507.63,263.28z"
              />
              <path
                d="M425.92,154.81c-0.51-0.77-0.95-1.15-1.01-1.59c-0.1-0.76,0.46-1.23,1.17-1.05c0.42,0.11,1.03,0.7,0.99,1.02
	C427.03,153.66,426.49,154.07,425.92,154.81z"
              />
              <path
                d="M501.64,341.92c-0.69,0.44-1.19,1-1.48,0.9c-0.47-0.15-1.07-0.71-1.12-1.15c-0.08-0.72,0.6-1.13,1.29-0.93
	C500.74,340.87,501.05,341.37,501.64,341.92z"
              />
              <path
                d="M492.75,257.5c-0.57-0.72-1.03-1.07-1.13-1.5c-0.17-0.75,0.35-1.27,1.07-1.15c0.42,0.07,1.07,0.62,1.07,0.94
	C493.76,256.26,493.25,256.72,492.75,257.5z"
              />
              <path
                d="M401.13,137.19c-0.87,0.67-1.33,1.3-1.72,1.25c-0.45-0.05-0.97-0.63-1.18-1.1c-0.08-0.18,0.58-0.96,0.95-1
	C399.64,136.29,400.15,136.73,401.13,137.19z"
              />
              <path
                d="M500.94,347.52c0.47,0.64,1.08,1.15,0.99,1.41c-0.17,0.47-0.72,1.03-1.18,1.1c-0.73,0.11-1.19-0.51-0.97-1.23
	C499.91,348.38,500.42,348.08,500.94,347.52z"
              />
              <path
                d="M275.67,484.29c-0.66,0.51-1.01,0.98-1.44,1.07c-0.72,0.15-1.25-0.33-1.25-1.07c-0.01-0.76,0.53-1.21,1.25-1.06
	C274.66,483.32,275.01,483.79,275.67,484.29z"
              />
              <path
                d="M509.11,324.7c-0.47-0.81-0.97-1.3-0.95-1.77c0.01-0.33,0.69-0.86,1.12-0.91c0.74-0.09,1.24,0.46,1.03,1.21
	C510.19,323.65,509.71,323.99,509.11,324.7z"
              />
              <path
                d="M266.59,376.82c-0.99,0.55-1.47,1.02-1.95,1.01c-0.34-0.01-0.92-0.67-0.92-1.03c0-0.36,0.6-1.01,0.94-1.01
	C265.14,375.79,265.62,376.27,266.59,376.82z"
              />
              <path
                d="M510.1,311.97c0.95-0.53,1.42-1,1.88-0.98c0.38,0.02,1.08,0.75,1.01,0.95c-0.17,0.49-0.65,1.1-1.1,1.18
	C511.51,313.19,511.01,312.59,510.1,311.97z"
              />
              <path
                d="M320.46,312.32c-0.56-0.84-1.07-1.28-1.1-1.75c-0.02-0.34,0.66-1.06,0.91-1.01c0.49,0.1,1.18,0.56,1.25,0.97
	C321.6,310.92,321.01,311.45,320.46,312.32z"
              />
              <path
                d="M417.26,324.04c-0.99,0.55-1.47,1.02-1.95,1.01c-0.34-0.01-0.92-0.67-0.92-1.03c0-0.36,0.6-1.01,0.94-1.01
	C415.81,323.01,416.29,323.49,417.26,324.04z"
              />
              <path
                d="M273.43,309.36c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C272.35,310.66,272.86,310.21,273.43,309.36z"
              />
              <path
                d="M435.46,259.08c0.49,0.79,0.92,1.19,0.97,1.63c0.08,0.77-0.51,1.23-1.21,1.04c-0.42-0.11-1.02-0.74-0.98-1.07
	C434.3,260.22,434.87,259.82,435.46,259.08z"
              />
              <path
                d="M506.16,325.29c0.52,0.79,1.06,1.25,1.06,1.71c0,0.35-0.64,0.92-1.07,0.99c-0.7,0.11-1.27-0.39-1.11-1.16
	C505.14,326.4,505.6,326.04,506.16,325.29z"
              />
              <path
                d="M434.29,168.49c-0.46-0.79-0.96-1.27-0.94-1.73c0.01-0.35,0.68-0.68,1.05-1.02c0.36,0.42,0.91,0.8,1,1.28
	C435.46,167.33,434.86,167.77,434.29,168.49z"
              />
              <path
                d="M263,277.41c-0.47-0.78-0.97-1.26-0.96-1.72c0.01-0.35,0.67-0.69,1.04-1.03c0.36,0.42,0.92,0.8,1.02,1.27
	C264.16,276.24,263.56,276.69,263,277.41z"
              />
              <path
                d="M470.98,362.72c-0.76,0.51-1.27,1.1-1.54,1c-0.46-0.17-0.94-0.71-1.07-1.19c-0.06-0.23,0.69-0.96,1.03-0.94
	C469.86,361.62,470.27,362.17,470.98,362.72z"
              />
              <path
                d="M403.28,166.3c-0.69,0.45-1.2,1.02-1.5,0.92c-0.47-0.16-1.08-0.72-1.13-1.17c-0.08-0.72,0.61-1.15,1.31-0.94
	C402.38,165.24,402.69,165.75,403.28,166.3z"
              />
              <path
                d="M379.8,119.45c0.49,0.79,0.92,1.19,0.97,1.63c0.08,0.77-0.51,1.23-1.21,1.04c-0.42-0.11-1.02-0.74-0.98-1.07
	C378.64,120.59,379.21,120.18,379.8,119.45z"
              />
              <path
                d="M403.28,139.14c0.5,0.77,0.93,1.16,0.99,1.6c0.1,0.77-0.49,1.22-1.18,1.05c-0.42-0.1-1.02-0.71-0.99-1.04
	C402.15,140.29,402.7,139.87,403.28,139.14z"
              />
              <path
                d="M396.81,276.46c-0.57-0.72-1.17-1.16-1.11-1.47c0.1-0.47,0.65-0.85,1.01-1.27c0.37,0.34,1.04,0.67,1.05,1.02
	C397.78,275.19,397.28,275.67,396.81,276.46z"
              />
              <path
                d="M322.93,174.73c-0.57-0.72-1.18-1.16-1.12-1.47c0.09-0.47,0.64-0.86,1-1.28c0.38,0.34,1.04,0.66,1.05,1.01
	C323.88,173.46,323.39,173.94,322.93,174.73z"
              />
              <path
                d="M505.44,258.55c-0.89,0.53-1.37,1.02-1.84,1.02c-0.32,0-0.91-0.68-0.9-1.05c0.01-0.38,0.6-1.05,0.92-1.04
	C504.1,257.49,504.56,257.99,505.44,258.55z"
              />
              <path
                d="M279.5,487.66c-0.65,0.5-1.11,1.1-1.41,1.03c-0.49-0.11-1.15-0.61-1.23-1.04c-0.14-0.7,0.5-1.19,1.21-1.04
	C278.49,486.69,278.85,487.16,279.5,487.66z"
              />
              <path
                d="M501.42,354.7c0.48,0.64,1.1,1.16,1,1.42c-0.17,0.47-0.73,1.05-1.19,1.12c-0.73,0.11-1.2-0.52-0.98-1.25
	C500.39,355.57,500.9,355.26,501.42,354.7z"
              />
              <path
                d="M498.73,368.82c-0.55-0.75-1.09-1.18-1.13-1.65c-0.03-0.32,0.59-0.9,1.01-1c0.71-0.17,1.26,0.32,1.15,1.07
	C499.69,367.68,499.25,368.06,498.73,368.82z"
              />
              <path
                d="M394.13,327.31c-0.54-0.73-1.09-1.16-1.12-1.62c-0.02-0.34,0.62-0.73,0.97-1.1c0.4,0.38,0.99,0.7,1.13,1.16
	C395.2,326.04,394.64,326.54,394.13,327.31z"
              />
              <path
                d="M270.41,466.83c-0.75,0.52-1.27,1.1-1.54,1.01c-0.46-0.16-0.94-0.71-1.08-1.19c-0.06-0.22,0.68-0.96,1.03-0.94
	C269.28,465.74,269.69,466.29,270.41,466.83z"
              />
              <path
                d="M360.64,170c-0.98,0.56-1.46,1.04-1.94,1.03c-0.34,0-0.93-0.66-0.93-1.02c0-0.36,0.59-1.01,0.93-1.02
	C359.18,168.98,359.66,169.45,360.64,170z"
              />
              <path
                d="M292.1,425c-0.59,0.49-0.97,1.04-1.35,1.05c-0.37,0-0.75-0.56-1.13-0.87c0.36-0.41,0.65-1.01,1.1-1.17
	C290.98,423.92,291.49,424.54,292.1,425z"
              />
              <path
                d="M496.38,260.47c-0.41-0.68-0.96-1.22-0.85-1.48c0.18-0.44,0.79-0.7,1.21-1.03c0.28,0.41,0.78,0.83,0.76,1.22
	C497.48,259.55,496.91,259.89,496.38,260.47z"
              />
              <path
                d="M287.28,177.69c-0.6-0.57-1.18-0.86-1.29-1.27c-0.18-0.72,0.32-1.3,1.06-1.21c0.41,0.05,0.98,0.58,1.05,0.96
	C288.16,176.54,287.67,177.01,287.28,177.69z"
              />
              <path
                d="M328.9,183.28c-0.63,0.56-0.95,1.06-1.37,1.16c-0.71,0.18-1.31-0.27-1.21-1.01c0.05-0.39,0.62-0.93,1.02-0.99
	C327.71,182.38,328.17,182.86,328.9,183.28z"
              />
              <path
                d="M292.35,484.74c0.49,0.63,1.03,1.03,1.02,1.4c-0.02,0.39-0.51,0.97-0.9,1.08c-0.71,0.18-1.27-0.31-1.18-1.05
	C291.34,485.75,291.84,485.39,292.35,484.74z"
              />
              <path
                d="M451.16,259.38c-0.56,0.52-1,1.18-1.25,1.11c-0.47-0.13-0.84-0.67-1.25-1.03c0.35-0.36,0.67-0.94,1.07-1
	C450.09,258.41,450.52,258.95,451.16,259.38z"
              />
              <path
                d="M279.7,447.88c-0.68,0.45-1.06,0.91-1.49,0.96c-0.73,0.08-1.24-0.45-1.03-1.16c0.11-0.37,0.73-0.83,1.13-0.83
	C278.68,446.85,279.06,447.38,279.7,447.88z"
              />
              <path
                d="M327.08,174.79c0.4,0.68,0.9,1.14,0.83,1.5c-0.07,0.39-0.63,0.92-1.03,0.97c-0.71,0.09-1.24-0.47-1.05-1.19
	C325.94,175.66,326.5,175.36,327.08,174.79z"
              />
              <path
                d="M389.12,127.83c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C388.1,126.79,388.48,127.33,389.12,127.83z"
              />
              <path
                d="M489.4,261.2c-0.53-0.67-1.08-1.06-1.06-1.42c0.03-0.38,0.58-0.99,0.93-1c0.35-0.02,0.96,0.53,1.03,0.91
	C490.37,260.04,489.86,260.49,489.4,261.2z"
              />
              <path
                d="M279.99,344.48c-0.54-0.61-1.2-1.06-1.13-1.34c0.11-0.48,0.68-0.86,1.05-1.28c0.35,0.37,0.94,0.7,0.98,1.1
	C280.94,343.35,280.43,343.8,279.99,344.48z"
              />
              <path
                d="M247.98,208.57c-0.59,0.5-0.96,1.07-1.35,1.08c-0.38,0.01-0.99-0.48-1.11-0.87c-0.22-0.71,0.31-1.27,1.01-1.22
	C246.95,207.59,247.35,208.11,247.98,208.57z"
              />
              <path
                d="M472.77,344.55c-0.65,0.43-1.1,0.96-1.46,0.91c-0.4-0.06-0.72-0.65-1.08-1.02c0.42-0.37,0.79-0.92,1.28-1.04
	C471.76,343.34,472.21,344.01,472.77,344.55z"
              />
              <path
                d="M354.47,311.62c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C353.69,310.39,353.96,311.01,354.47,311.62z"
              />
              <path
                d="M329.89,84.31c0.43,0.67,0.94,1.11,0.9,1.49c-0.04,0.39-0.62,0.73-0.97,1.09c-0.37-0.41-0.94-0.78-1.04-1.25
	C328.72,85.35,329.36,84.91,329.89,84.31z"
              />
              <path
                d="M442.63,258.72c0.45,0.72,0.97,1.17,0.91,1.53c-0.07,0.38-0.69,0.94-1.04,0.91c-0.36-0.02-0.93-0.64-0.95-1.02
	C441.52,259.79,442.09,259.39,442.63,258.72z"
              />
              <path
                d="M314.74,311.11c-0.73,0.47-1.17,0.97-1.53,0.92c-0.38-0.05-0.69-0.63-1.03-0.98c0.36-0.33,0.7-0.9,1.08-0.93
	C313.62,310.08,314.03,310.6,314.74,311.11z"
              />
              <path
                d="M380.5,126.79c0.46,0.67,0.94,1.06,0.97,1.48c0.05,0.74-0.55,1.2-1.24,0.97c-0.38-0.13-0.84-0.74-0.84-1.13
	C379.4,127.75,379.97,127.39,380.5,126.79z"
              />
              <path
                d="M270.95,447.05c0.51,0.62,1.16,1.1,1.09,1.39c-0.13,0.47-0.72,0.82-1.12,1.22c-0.33-0.38-0.91-0.74-0.93-1.14
	C269.96,448.13,270.49,447.7,270.95,447.05z"
              />
              <path
                d="M337.2,197.01c-0.57,0.51-0.92,1.08-1.3,1.1c-0.38,0.02-0.99-0.45-1.12-0.83c-0.24-0.7,0.28-1.26,0.97-1.22
	C336.17,196.08,336.57,196.58,337.2,197.01z"
              />
              <path
                d="M240.1,341.27c0.46,0.65,0.99,1.07,0.96,1.43c-0.03,0.39-0.55,0.96-0.94,1.04c-0.71,0.15-1.27-0.36-1.14-1.09
	C239.05,342.24,239.57,341.9,240.1,341.27z"
              />
              <path
                d="M295.55,354.47c0.45,0.66,0.95,1.07,0.97,1.49c0.02,0.68-0.5,1.26-1.23,1.01c-0.38-0.13-0.82-0.77-0.81-1.17
	C294.49,355.42,295.05,355.06,295.55,354.47z"
              />
              <path
                d="M410.99,145.71c0.43,0.67,0.95,1.12,0.91,1.51c-0.04,0.4-0.63,0.74-0.98,1.1c-0.38-0.42-0.95-0.79-1.06-1.27
	C409.79,146.77,410.45,146.32,410.99,145.71z"
              />
              <path
                d="M345.44,290.42c-0.71,0.49-1.13,1.01-1.49,0.98c-0.38-0.04-0.71-0.61-1.07-0.95c0.34-0.35,0.66-0.92,1.04-0.97
	C344.29,289.43,344.72,289.94,345.44,290.42z"
              />
              <path
                d="M331.55,172.52c-0.76,0.43-1.23,0.91-1.6,0.84c-0.38-0.07-0.66-0.68-0.99-1.05c0.38-0.31,0.76-0.87,1.15-0.87
	C330.48,171.43,330.87,171.98,331.55,172.52z"
              />
              <path
                d="M487,256.22c-0.67,0.55-1.05,1.1-1.41,1.09c-0.39-0.01-0.76-0.54-1.15-0.85c0.32-0.37,0.59-0.97,0.96-1.05
	C485.76,255.34,486.24,255.81,487,256.22z"
              />
              <path
                d="M492.82,341.05c0.51,0.57,1.01,0.89,1.12,1.31c0.19,0.72-0.33,1.27-1.01,1.18c-0.39-0.05-0.95-0.62-1-1.01
	C491.87,342.16,492.39,341.71,492.82,341.05z"
              />
              <path
                d="M514.11,295.26c-0.54-0.62-1.07-0.95-1.14-1.37c-0.13-0.73,0.41-1.26,1.13-1.1c0.4,0.08,0.92,0.64,0.95,1.03
	C515.09,294.19,514.57,294.61,514.11,295.26z"
              />
              <path
                d="M275.89,444.54c-0.74,0.45-1.2,0.95-1.57,0.89c-0.38-0.06-0.68-0.66-1.02-1.02c0.37-0.33,0.73-0.89,1.12-0.91
	C274.79,443.48,275.2,444.02,275.89,444.54z"
              />
              <path
                d="M461.82,341.75c-0.67,0.51-1.03,0.98-1.45,1.05c-0.72,0.12-1.28-0.38-1.13-1.11c0.08-0.39,0.69-0.88,1.1-0.91
	C460.71,340.76,461.13,341.27,461.82,341.75z"
              />
              <path
                d="M277.8,426.22c-0.66,0.49-1.03,0.96-1.46,1.03c-0.73,0.12-1.28-0.38-1.09-1.12c0.1-0.39,0.7-0.88,1.09-0.89
	C276.73,425.23,277.14,425.75,277.8,426.22z"
              />
              <path
                d="M343.06,261.7c-0.68,0.46-1.07,0.92-1.5,0.97c-0.74,0.09-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.84
	C342.03,260.66,342.41,261.2,343.06,261.7z"
              />
              <path
                d="M280.58,375.55c-0.58,0.5-0.95,1.06-1.33,1.07c-0.37,0.01-0.98-0.47-1.1-0.86c-0.22-0.71,0.31-1.25,1-1.2
	C279.57,374.59,279.96,375.1,280.58,375.55z"
              />
              <path
                d="M282.77,483.63c-0.63,0.47-1.06,1.01-1.42,0.98c-0.4-0.04-0.74-0.62-1.11-0.96c0.4-0.39,0.74-0.96,1.21-1.1
	C281.69,482.47,282.17,483.12,282.77,483.63z"
              />
              <path
                d="M479.02,389.23c0.49,0.7,1.02,1.13,0.98,1.49c-0.05,0.38-0.65,0.98-1,0.97c-0.36-0.01-0.95-0.6-1-0.98
	C477.97,390.35,478.52,389.93,479.02,389.23z"
              />
              <path
                d="M276.34,451.76c-0.69,0.44-1.08,0.88-1.51,0.92c-0.74,0.07-1.22-0.49-1-1.19c0.12-0.37,0.75-0.8,1.15-0.8
	C275.35,450.7,275.71,451.24,276.34,451.76z"
              />
              <path
                d="M470.47,378.27c-0.42-0.73-0.91-1.2-0.84-1.54c0.08-0.37,0.72-0.89,1.07-0.86c0.35,0.03,0.88,0.66,0.89,1.04
	C471.6,377.26,471.03,377.63,470.47,378.27z"
              />
              <path
                d="M461.59,254.91c-0.71,0.5-1.13,1.03-1.51,0.99c-0.39-0.03-0.72-0.62-1.08-0.96c0.35-0.35,0.67-0.94,1.05-0.99
	C460.43,253.92,460.87,254.43,461.59,254.91z"
              />
              <path
                d="M289.94,483.18c-0.63,0.45-1.06,0.98-1.42,0.94c-0.39-0.04-0.72-0.63-1.08-0.97c0.4-0.38,0.75-0.93,1.22-1.06
	C288.91,482.01,289.37,482.66,289.94,483.18z"
              />
              <path
                d="M476.23,340.22c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C474.99,339.31,475.46,339.79,476.23,340.22z"
              />
              <path
                d="M474.76,365.73c-0.7,0.5-1.11,1.03-1.48,0.99c-0.38-0.03-0.72-0.6-1.08-0.93c0.34-0.35,0.65-0.93,1.03-0.98
	C473.6,364.76,474.04,365.27,474.76,365.73z"
              />
              <path
                d="M464.32,346.38c-0.46-0.65-0.98-1.07-0.96-1.45c0.03-0.4,0.6-0.75,0.93-1.13c0.39,0.4,0.97,0.75,1.09,1.21
	C465.46,345.29,464.83,345.76,464.32,346.38z"
              />
              <path
                d="M320.25,83.17c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.9,1.07-0.94
	C319.13,82.15,319.55,82.68,320.25,83.17z"
              />
              <path
                d="M269.12,452.35c-0.6,0.5-0.99,1.06-1.39,1.07c-0.38,0.01-1-0.5-1.11-0.9c-0.21-0.72,0.32-1.29,1.04-1.21
	C268.1,451.35,268.48,451.87,269.12,452.35z"
              />
              <path
                d="M464.58,393.42c-0.48-0.66-1.11-1.19-1.01-1.45c0.17-0.47,0.78-0.77,1.2-1.15c0.31,0.4,0.85,0.8,0.85,1.2
	C465.63,392.41,465.08,392.8,464.58,393.42z"
              />
              <path
                d="M483.8,386.64c-0.6,0.48-0.99,1.03-1.36,1.02c-0.39-0.02-0.75-0.58-1.13-0.9c0.37-0.4,0.68-0.98,1.14-1.14
	C482.7,385.54,483.2,386.16,483.8,386.64z"
              />
              <path
                d="M297.21,482.43c-0.69,0.46-1.11,0.97-1.48,0.93c-0.4-0.04-0.99-0.54-1.06-0.93c-0.14-0.71,0.42-1.21,1.14-1.06
	C296.23,481.46,296.57,481.93,297.21,482.43z"
              />
              <path
                d="M337.13,170.22c0.51,0.59,1.14,1.04,1.08,1.32c-0.11,0.46-0.67,0.82-1.04,1.22c-0.34-0.36-0.91-0.7-0.95-1.09
	C336.18,171.3,336.69,170.87,337.13,170.22z"
              />
              <path
                d="M243.02,213.93c-0.41-0.68-0.96-1.22-0.85-1.48c0.18-0.44,0.79-0.7,1.21-1.03c0.28,0.41,0.78,0.83,0.76,1.22
	C244.12,213,243.55,213.35,243.02,213.93z"
              />
              <path
                d="M241.98,263.28c-0.59,0.5-1.07,1.15-1.32,1.07c-0.47-0.14-0.82-0.71-1.21-1.1c0.37-0.34,0.72-0.93,1.12-0.96
	C240.93,262.25,241.35,262.81,241.98,263.28z"
              />
              <path
                d="M511.81,286.61c0.71-0.49,1.13-1.01,1.49-0.97c0.38,0.04,0.71,0.61,1.06,0.95c-0.35,0.34-0.67,0.92-1.05,0.97
	C512.95,287.6,512.53,287.09,511.81,286.61z"
              />
              <path
                d="M327.73,311.36c-0.52-0.59-1.1-0.95-1.11-1.33c-0.01-0.41,0.42-1.06,0.81-1.2c0.74-0.26,1.27,0.33,1.26,1.01
	C328.68,310.26,328.18,310.68,327.73,311.36z"
              />
              <path
                d="M496.4,337.19c0.43,0.67,0.94,1.11,0.9,1.49c-0.04,0.39-0.63,0.73-0.97,1.09c-0.37-0.41-0.94-0.78-1.04-1.25
	C495.22,338.23,495.87,337.78,496.4,337.19z"
              />
              <path
                d="M345.89,171.27c-0.64,0.46-1.07,1.01-1.46,0.98c-0.38-0.03-0.96-0.59-1.04-0.99c-0.14-0.71,0.38-1.28,1.13-1.12
	C344.95,170.22,345.29,170.75,345.89,171.27z"
              />
              <path
                d="M334.67,81.62c-0.65,0.54-1,1.03-1.42,1.12c-0.72,0.14-1.31-0.34-1.19-1.07c0.07-0.39,0.67-0.92,1.07-0.97
	C333.51,80.65,333.96,81.16,334.67,81.62z"
              />
              <path
                d="M252.07,145.27c0.46,0.63,1.06,1.11,0.97,1.39c-0.13,0.45-0.72,0.77-1.11,1.14c-0.32-0.38-0.88-0.75-0.88-1.14
	C251.04,146.28,251.58,145.89,252.07,145.27z"
              />
              <path
                d="M278.82,456.46c-0.55-0.57-1.12-0.91-1.14-1.28c-0.02-0.38,0.43-1,0.8-1.14c0.68-0.24,1.28,0.2,1.25,0.93
	C279.71,455.39,279.25,455.79,278.82,456.46z"
              />
              <path
                d="M376.51,126.36c-0.46-0.65-0.98-1.06-0.95-1.43c0.03-0.38,0.55-0.95,0.95-1.04c0.72-0.16,1.26,0.37,1.14,1.09
	C377.56,125.4,377.04,125.74,376.51,126.36z"
              />
              <path
                d="M271.73,441.21c-0.57,0.55-0.88,1.08-1.3,1.19c-0.76,0.2-1.28-0.4-1.22-1.06c0.04-0.4,0.62-0.98,1.03-1.04
	C270.61,440.24,271.07,440.77,271.73,441.21z"
              />
              <path
                d="M285.32,422c0.64-0.52,0.98-1.01,1.4-1.1c0.72-0.16,1.3,0.33,1.17,1.06c-0.07,0.39-0.66,0.91-1.06,0.96
	C286.46,422.96,286.03,422.45,285.32,422z"
              />
              <path
                d="M311.13,394.42c-0.62,0.55-0.94,1.03-1.36,1.14c-0.7,0.17-1.29-0.27-1.19-1.01c0.06-0.39,0.61-0.92,1.01-0.97
	C309.96,393.52,310.41,394,311.13,394.42z"
              />
              <path
                d="M280.24,494.94c-0.75,0.41-1.21,0.88-1.58,0.81c-0.37-0.07-0.65-0.66-0.97-1.02c0.37-0.31,0.75-0.86,1.11-0.85
	C279.19,493.88,279.57,494.41,280.24,494.94z"
              />
              <path
                d="M465.11,337.74c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C464.02,336.59,464.5,337.23,465.11,337.74z"
              />
              <path
                d="M475.79,356.2c-0.44-0.72-0.94-1.18-0.87-1.53c0.07-0.37,0.7-0.91,1.05-0.88c0.35,0.02,0.89,0.64,0.91,1.02
	C476.89,355.17,476.33,355.55,475.79,356.2z"
              />
              <path
                d="M309.15,80.59c-0.64,0.45-1.07,1-1.44,0.95c-0.4-0.05-0.73-0.63-1.1-0.99c0.41-0.38,0.76-0.94,1.24-1.08
	C308.11,79.41,308.57,80.07,309.15,80.59z"
              />
              <path
                d="M463.36,252.56c-0.46-0.65-0.98-1.06-0.95-1.43c0.03-0.38,0.55-0.95,0.95-1.04c0.72-0.16,1.26,0.37,1.14,1.09
	C464.42,251.6,463.89,251.94,463.36,252.56z"
              />
              <path
                d="M477.41,394.62c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.09-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.84
	C476.39,393.58,476.77,394.12,477.41,394.62z"
              />
              <path
                d="M272.99,455.63c-0.76,0.42-1.22,0.89-1.58,0.82c-0.38-0.07-0.65-0.67-0.97-1.04c0.38-0.31,0.75-0.86,1.13-0.86
	C271.94,454.54,272.32,455.09,272.99,455.63z"
              />
              <path
                d="M351.59,169.01c0.52,0.64,1.03,0.99,1.09,1.41c0.11,0.74-0.46,1.23-1.16,1.07c-0.39-0.09-0.9-0.67-0.92-1.06
	C350.58,170.06,351.11,169.65,351.59,169.01z"
              />
              <path
                d="M316.42,79.93c-0.76,0.41-1.24,0.88-1.6,0.8c-0.38-0.08-0.64-0.68-0.96-1.05c0.38-0.31,0.76-0.84,1.15-0.85
	C315.38,78.84,315.75,79.39,316.42,79.93z"
              />
              <path
                d="M369.59,111.3c-0.63,0.45-1.06,0.98-1.42,0.94c-0.39-0.04-0.72-0.63-1.08-0.97c0.4-0.38,0.75-0.93,1.22-1.06
	C368.57,110.14,369.02,110.79,369.59,111.3z"
              />
              <path
                d="M470.23,394.93c-0.68,0.54-1.08,1.09-1.45,1.08c-0.39-0.01-0.76-0.57-1.14-0.88c0.33-0.37,0.61-0.98,1-1.04
	C469,394.01,469.47,394.5,470.23,394.93z"
              />
              <path
                d="M383.86,122.94c0.47,0.68,0.96,1.07,0.98,1.5c0.05,0.75-0.56,1.21-1.26,0.99c-0.39-0.13-0.85-0.75-0.85-1.15
	C382.75,123.9,383.33,123.54,383.86,122.94z"
              />
              <path
                d="M470.69,249.13c0.46,0.72,0.99,1.17,0.93,1.53c-0.07,0.38-0.69,0.93-1.05,0.92c-0.35-0.01-0.91-0.64-0.94-1.02
	C469.6,250.19,470.16,249.79,470.69,249.13z"
              />
              <path
                d="M316.34,206.12c-0.57,0.53-1.02,1.19-1.27,1.13c-0.48-0.13-0.85-0.68-1.27-1.05c0.36-0.36,0.69-0.96,1.09-1.01
	C315.26,205.14,315.7,205.68,316.34,206.12z"
              />
              <path
                d="M277.56,439.14c0.45,0.72,0.97,1.17,0.91,1.53c-0.07,0.38-0.69,0.94-1.04,0.91c-0.36-0.02-0.93-0.64-0.95-1.02
	C276.45,440.2,277.02,439.81,277.56,439.14z"
              />
              <path
                d="M301.78,429.16c-0.53-0.67-1.09-1.07-1.06-1.43c0.03-0.38,0.59-1.01,0.94-1.02c0.36-0.01,0.99,0.54,1.05,0.92
	C302.77,427.99,302.25,428.44,301.78,429.16z"
              />
              <path
                d="M510.54,299.07c-0.43-0.67-0.89-1.07-0.91-1.48c-0.03-0.74,0.57-1.18,1.25-0.94c0.38,0.13,0.82,0.76,0.8,1.14
	C511.67,298.16,511.09,298.5,510.54,299.07z"
              />
              <path
                d="M477.67,251.37c-0.52-0.61-1.16-1.06-1.1-1.34c0.11-0.47,0.68-0.84,1.05-1.25c0.34,0.37,0.92,0.71,0.96,1.1
	C478.62,250.28,478.1,250.71,477.67,251.37z"
              />
              <path
                d="M289.54,396.46c-0.66,0.49-1.02,0.96-1.44,1.03c-0.72,0.12-1.26-0.39-1.1-1.1c0.09-0.38,0.69-0.86,1.09-0.89
	C288.45,395.47,288.86,395.98,289.54,396.46z"
              />
              <path
                d="M329.36,396.33c-0.62,0.55-0.94,1.04-1.35,1.15c-0.7,0.18-1.29-0.27-1.2-1c0.05-0.39,0.61-0.92,1-0.98
	C328.19,395.44,328.64,395.91,329.36,396.33z"
              />
              <path
                d="M500.91,334.45c-0.62,0.53-0.97,1.08-1.35,1.1c-0.39,0.02-1.04-0.4-1.16-0.77c-0.23-0.68,0.22-1.27,0.97-1.2
	C499.8,333.61,500.21,334.03,500.91,334.45z"
              />
              <path
                d="M312.17,215.79c0.52,0.6,1.17,1.05,1.1,1.34c-0.11,0.47-0.68,0.83-1.06,1.24c-0.34-0.36-0.92-0.71-0.96-1.1
	C311.22,216.88,311.73,216.45,312.17,215.79z"
              />
              <path
                d="M432.14,263.31c0.41,0.68,0.85,1.09,0.87,1.51c0.03,0.7-0.52,1.21-1.22,0.95c-0.37-0.14-0.8-0.78-0.78-1.17
	C431.03,264.23,431.61,263.89,432.14,263.31z"
              />
              <path
                d="M459.81,333.12c0.49,0.63,1.03,1.03,1.02,1.4c-0.02,0.39-0.51,0.97-0.9,1.08c-0.71,0.18-1.27-0.31-1.18-1.05
	C458.8,334.12,459.31,333.76,459.81,333.12z"
              />
              <path
                d="M332.72,186.76c-0.66,0.5-1.06,1.03-1.42,1.01c-0.4-0.02-1.03-0.48-1.12-0.86c-0.17-0.7,0.34-1.23,1.07-1.13
	C331.67,185.84,332.04,186.29,332.72,186.76z"
              />
              <path
                d="M298.86,477.04c0.49,0.71,1.02,1.15,0.97,1.5c-0.05,0.38-0.66,0.95-1.02,0.95c-0.35,0-0.93-0.61-0.97-0.99
	C297.81,478.15,298.35,477.73,298.86,477.04z"
              />
              <path
                d="M437.81,314.93c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.36-0.34,0.69-0.9,1.07-0.94
	C436.7,313.91,437.11,314.44,437.81,314.93z"
              />
              <path
                d="M235.58,397.5c-0.68,0.46-1.07,0.93-1.5,0.98c-0.73,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C234.56,396.46,234.94,397,235.58,397.5z"
              />
              <path
                d="M509.57,308.52c-0.67,0.55-1.05,1.11-1.43,1.11c-0.39,0-0.77-0.55-1.16-0.86c0.32-0.38,0.59-0.99,0.97-1.06
	C508.33,307.63,508.81,308.11,509.57,308.52z"
              />
              <path
                d="M335.38,175.81c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C334.35,174.77,334.74,175.31,335.38,175.81z"
              />
              <path
                d="M264.6,315.56c-0.77,0.4-1.25,0.85-1.61,0.77c-0.38-0.08-0.63-0.69-0.94-1.06c0.39-0.3,0.77-0.83,1.16-0.83
	C263.57,314.44,263.93,315,264.6,315.56z"
              />
              <path
                d="M295.93,428.25c-0.55,0.53-0.89,1.11-1.26,1.14c-0.38,0.03-1.01-0.41-1.15-0.79c-0.26-0.69,0.23-1.27,0.93-1.25
	C294.87,427.36,295.28,427.83,295.93,428.25z"
              />
              <path
                d="M350.53,281.03c0.43,0.67,0.95,1.12,0.91,1.51c-0.04,0.4-0.63,0.74-0.98,1.1c-0.38-0.42-0.95-0.79-1.06-1.27
	C349.33,282.08,349.99,281.63,350.53,281.03z"
              />
              <path
                d="M445.37,170.99c-0.48-0.66-0.97-1.03-1.01-1.46c-0.08-0.75,0.51-1.21,1.21-1.01c0.39,0.11,0.87,0.72,0.87,1.1
	C446.44,170,445.88,170.37,445.37,170.99z"
              />
              <path
                d="M320.4,170.07c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C319.63,168.84,319.9,169.47,320.4,170.07z"
              />
              <path
                d="M381.77,310.12c-0.57-0.62-1.15-0.98-1.15-1.33c0-0.38,0.52-1.02,0.87-1.05c0.35-0.04,1.01,0.47,1.09,0.83
	C382.66,308.92,382.18,309.4,381.77,310.12z"
              />
              <path
                d="M505.88,265.6c-0.56,0.54-1,1.21-1.26,1.14c-0.49-0.12-0.86-0.66-1.29-1.03c0.36-0.36,0.68-0.96,1.08-1.02
	C504.77,264.63,505.23,265.17,505.88,265.6z"
              />
              <path
                d="M284.27,458.29c-0.65,0.53-0.98,1.01-1.41,1.1c-0.72,0.15-1.3-0.33-1.17-1.06c0.07-0.39,0.66-0.91,1.06-0.95
	C283.13,457.34,283.57,457.84,284.27,458.29z"
              />
              <path
                d="M414.88,149.11c0.5,0.6,1.13,1.06,1.06,1.33c-0.12,0.46-0.68,0.81-1.06,1.21c-0.33-0.36-0.9-0.71-0.93-1.1
	C413.92,150.18,414.44,149.75,414.88,149.11z"
              />
              <path
                d="M365.85,170.78c-0.42-0.7-0.92-1.16-0.86-1.54c0.06-0.4,0.65-0.72,1.01-1.08c0.36,0.43,0.92,0.83,1.01,1.31
	C367.08,169.75,366.41,170.18,365.85,170.78z"
              />
              <path
                d="M279.65,281.63c-0.63,0.45-1.06,1-1.44,0.97c-0.38-0.03-0.72-0.62-1.08-0.97c0.39-0.39,0.74-0.97,1.2-1.09
	C278.6,280.47,279.06,281.12,279.65,281.63z"
              />
              <path
                d="M261.26,624.88c0.45,0.7,0.96,1.16,0.9,1.51c-0.07,0.38-0.7,0.93-1.05,0.91c-0.37-0.03-0.94-0.64-0.96-1.01
	C260.13,625.92,260.71,625.53,261.26,624.88z"
              />
              <path
                d="M327.69,169.21c-0.77,0.4-1.25,0.86-1.61,0.78c-0.38-0.08-0.63-0.69-0.94-1.07c0.39-0.3,0.78-0.83,1.16-0.83
	C326.66,168.09,327.03,168.65,327.69,169.21z"
              />
              <path
                d="M496.3,364.13c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C495.21,362.98,495.69,363.62,496.3,364.13z"
              />
              <path
                d="M472.63,396.93c0.47,0.68,0.96,1.07,0.98,1.5c0.05,0.75-0.56,1.21-1.26,0.99c-0.39-0.13-0.85-0.75-0.85-1.15
	C471.52,397.89,472.1,397.53,472.63,396.93z"
              />
              <path
                d="M269.81,459.58c-0.61,0.49-1.01,1.05-1.38,1.03c-0.4-0.02-0.77-0.58-1.15-0.91c0.38-0.41,0.69-0.99,1.16-1.16
	C268.69,458.46,269.2,459.09,269.81,459.58z"
              />
              <path
                d="M342.53,314.39c0.48,0.7,1,1.14,0.95,1.49c-0.05,0.38-0.65,0.95-1,0.94c-0.35-0.01-0.92-0.6-0.96-0.98
	C341.48,315.49,342.02,315.07,342.53,314.39z"
              />
              <path
                d="M363.49,99.43c0.56,0.61,1.11,0.93,1.2,1.35c0.16,0.74-0.41,1.27-1.12,1.15c-0.4-0.07-0.94-0.63-0.99-1.02
	C362.54,100.54,363.06,100.1,363.49,99.43z"
              />
              <path
                d="M273.25,478.56c-0.42-0.73-0.92-1.2-0.84-1.55c0.08-0.38,0.73-0.9,1.08-0.87c0.36,0.04,0.9,0.67,0.91,1.05
	C274.41,477.56,273.82,477.93,273.25,478.56z"
              />
              <path
                d="M264.09,368.12c0.47,0.71,1,1.15,0.95,1.51c-0.06,0.38-0.67,0.96-1.02,0.94c-0.36-0.01-0.94-0.61-0.98-1
	C263.01,369.22,263.56,368.81,264.09,368.12z"
              />
              <path
                d="M470.24,371.32c-0.52-0.58-1.08-0.94-1.1-1.32c-0.02-0.4,0.42-1.05,0.8-1.18c0.73-0.26,1.26,0.33,1.25,1
	C471.18,370.24,470.68,370.65,470.24,371.32z"
              />
              <path
                d="M488.3,330.49c0.5,0.6,1.13,1.06,1.06,1.33c-0.12,0.46-0.68,0.81-1.06,1.21c-0.33-0.36-0.9-0.71-0.93-1.1
	C487.34,331.55,487.85,331.13,488.3,330.49z"
              />
              <path
                d="M238.46,346.61c-0.73,0.47-1.17,0.98-1.55,0.94c-0.38-0.05-0.7-0.64-1.04-1c0.36-0.34,0.71-0.91,1.09-0.94
	C237.34,345.58,237.76,346.11,238.46,346.61z"
              />
              <path
                d="M249.86,203.06c0.52,0.64,1.05,1,1.11,1.42c0.12,0.75-0.47,1.24-1.18,1.08c-0.39-0.09-0.91-0.68-0.93-1.07
	C248.84,204.12,249.38,203.71,249.86,203.06z"
              />
              <path
                d="M325.78,187.2c-0.64,0.51-1.02,1.05-1.4,1.06c-0.4,0-1.03-0.45-1.14-0.83c-0.21-0.71,0.28-1.27,1.03-1.18
	C324.7,186.3,325.1,186.75,325.78,187.2z"
              />
              <path
                d="M371.71,168.64c0.64-0.52,0.97-1,1.39-1.09c0.71-0.15,1.28,0.32,1.15,1.04c-0.07,0.39-0.65,0.9-1.04,0.95
	C372.84,169.59,372.4,169.09,371.71,168.64z"
              />
              <path
                d="M273.63,628.83c-0.56,0.54-1,1.21-1.25,1.15c-0.49-0.12-0.87-0.66-1.29-1.03c0.36-0.37,0.67-0.97,1.08-1.03
	C272.53,627.87,272.98,628.4,273.63,628.83z"
              />
              <path
                d="M275.15,437.29c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.36-0.34,0.69-0.9,1.07-0.94
	C274.03,436.27,274.45,436.8,275.15,437.29z"
              />
              <path
                d="M514.34,279.46c-0.68,0.45-1.06,0.91-1.49,0.96c-0.73,0.08-1.24-0.45-1.03-1.16c0.11-0.37,0.73-0.83,1.13-0.83
	C513.33,278.43,513.71,278.96,514.34,279.46z"
              />
              <path
                d="M474.29,358.64c-0.7,0.47-1.13,0.98-1.5,0.94c-0.4-0.04-1-0.55-1.08-0.94c-0.14-0.72,0.43-1.22,1.15-1.08
	C473.29,357.65,473.63,358.12,474.29,358.64z"
              />
              <path
                d="M504.3,330.84c-0.7,0.45-1.1,0.91-1.55,0.95c-0.75,0.07-1.25-0.51-1.03-1.22c0.12-0.38,0.77-0.83,1.17-0.81
	C503.29,329.77,503.67,330.32,504.3,330.84z"
              />
              <path
                d="M306.84,178c-0.64,0.57-0.96,1.07-1.38,1.18c-0.72,0.18-1.33-0.28-1.23-1.02c0.05-0.39,0.63-0.94,1.03-1
	C305.64,177.09,306.1,177.58,306.84,178z"
              />
              <path
                d="M370.09,118.54c-0.65,0.43-1.1,0.96-1.46,0.91c-0.4-0.06-0.72-0.65-1.08-1.02c0.42-0.37,0.79-0.92,1.28-1.04
	C369.08,117.33,369.52,118.01,370.09,118.54z"
              />
              <path
                d="M448.78,317.47c-0.58,0.52-1.04,1.17-1.29,1.1c-0.48-0.14-0.84-0.69-1.24-1.07c0.37-0.35,0.7-0.95,1.1-0.99
	C447.71,316.46,448.14,317.01,448.78,317.47z"
              />
              <path
                d="M462.88,331.97c-0.45-0.64-0.98-1.06-0.95-1.43c0.03-0.39,0.59-0.74,0.92-1.11c0.38,0.39,0.95,0.74,1.07,1.2
	C464,330.9,463.38,331.36,462.88,331.97z"
              />
              <path
                d="M348.09,279.33c-0.69,0.51-1.09,1.05-1.46,1.02c-0.39-0.03-0.74-0.58-1.1-0.91c0.33-0.36,0.63-0.94,1.01-1
	C346.9,278.39,347.35,278.88,348.09,279.33z"
              />
              <path
                d="M508.36,270.33c-0.55-0.62-1.09-0.96-1.16-1.38c-0.13-0.74,0.42-1.27,1.14-1.12c0.4,0.08,0.93,0.65,0.96,1.04
	C509.34,269.24,508.81,269.67,508.36,270.33z"
              />
              <path
                d="M336.6,205.81c-0.53-0.62-1.19-1.07-1.12-1.36c0.12-0.48,0.69-0.85,1.07-1.26c0.35,0.37,0.93,0.71,0.97,1.12
	C337.56,204.69,337.04,205.13,336.6,205.81z"
              />
              <path
                d="M440.47,316.54c0.46,0.67,0.97,1.08,0.98,1.51c0.02,0.69-0.51,1.27-1.25,1.02c-0.39-0.13-0.83-0.78-0.82-1.18
	C439.4,317.5,439.96,317.13,440.47,316.54z"
              />
              <path
                d="M380.03,169.53c-0.44-0.67-0.91-1.07-0.93-1.5c-0.03-0.74,0.58-1.19,1.27-0.95c0.38,0.14,0.83,0.76,0.81,1.15
	C381.16,168.61,380.57,168.95,380.03,169.53z"
              />
              <path
                d="M280.9,462.21c-0.71,0.49-1.13,1.01-1.49,0.97c-0.38-0.04-0.71-0.61-1.06-0.95c0.35-0.34,0.67-0.92,1.05-0.97
	C279.76,461.21,280.19,461.73,280.9,462.21z"
              />
              <path
                d="M302.13,476.18c-0.46-0.65-0.99-1.07-0.96-1.45c0.03-0.39,0.56-0.96,0.96-1.05c0.72-0.16,1.28,0.37,1.15,1.11
	C303.2,475.21,302.67,475.55,302.13,476.18z"
              />
              <path
                d="M280.83,435.33c0.49,0.63,1.02,1.02,1.01,1.38c-0.02,0.38-0.51,0.96-0.89,1.06c-0.7,0.19-1.26-0.31-1.16-1.04
	C279.84,436.33,280.34,435.97,280.83,435.33z"
              />
              <path
                d="M268.67,278.93c-0.71,0.48-1.14,0.99-1.51,0.95c-0.38-0.05-0.7-0.62-1.05-0.97c0.35-0.34,0.68-0.91,1.06-0.96
	C267.54,277.92,267.96,278.44,268.67,278.93z"
              />
              <path
                d="M475.49,349.02c-0.53-0.67-1.09-1.07-1.06-1.43c0.03-0.38,0.59-1.01,0.94-1.02c0.36-0.01,0.99,0.54,1.05,0.92
	C476.48,347.85,475.95,348.31,475.49,349.02z"
              />
              <path
                d="M248.29,268.33c0.45,0.72,0.96,1.16,0.9,1.52c-0.06,0.37-0.68,0.92-1.03,0.9c-0.35-0.02-0.9-0.63-0.93-1.01
	C247.21,269.39,247.76,268.99,248.29,268.33z"
              />
              <path
                d="M263.83,474.28c-0.64,0.56-1.03,1.16-1.37,1.14c-0.38-0.03-0.87-0.59-1.01-1.01c-0.07-0.22,0.49-0.93,0.78-0.93
	C262.65,473.46,263.09,473.87,263.83,474.28z"
              />
              <path
                d="M414.54,271.27c-0.52-0.61-1.08-0.98-1.08-1.35c0-0.39,0.47-0.99,0.86-1.11c0.7-0.21,1.29,0.25,1.22,1
	C415.5,270.23,415.01,270.61,414.54,271.27z"
              />
              <path
                d="M325.07,180.17c-0.72,0.46-1.16,0.97-1.54,0.92c-0.41-0.05-1-0.59-1.07-0.98c-0.12-0.73,0.46-1.22,1.2-1.06
	C324.09,179.15,324.42,179.64,325.07,180.17z"
              />
              <path
                d="M341.47,302.94c-0.58-0.62-1.18-0.98-1.18-1.34c0-0.38,0.53-1.03,0.89-1.07c0.35-0.04,1.01,0.47,1.1,0.84
	C342.37,301.72,341.88,302.2,341.47,302.94z"
              />
              <path
                d="M433.2,317.28c0.5,0.65,1,1.01,1.05,1.43c0.09,0.74-0.48,1.23-1.18,1.04c-0.39-0.1-0.88-0.69-0.9-1.08
	C432.16,318.3,432.71,317.91,433.2,317.28z"
              />
              <path
                d="M374.21,121.76c-0.61,0.49-1.01,1.05-1.41,1.06c-0.38,0-0.99-0.52-1.1-0.92c-0.2-0.72,0.33-1.29,1.06-1.19
	C373.2,120.76,373.58,121.28,374.21,121.76z"
              />
              <path
                d="M497.53,354.31c-0.51-0.68-1.06-1.09-1.03-1.46c0.03-0.38,0.62-0.99,0.96-1c0.36,0,0.97,0.56,1.03,0.94
	C498.54,353.15,498.01,353.6,497.53,354.31z"
              />
              <path
                d="M240.61,415.22c-0.72,0.47-1.15,0.98-1.52,0.94c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.91,1.07-0.94
	C239.49,414.2,239.91,414.72,240.61,415.22z"
              />
              <path
                d="M468.06,349.74c-0.41-0.69-0.91-1.15-0.85-1.52c0.06-0.4,0.64-0.72,1-1.07c0.36,0.43,0.9,0.82,1,1.29
	C469.26,348.73,468.61,349.15,468.06,349.74z"
              />
              <path
                d="M359.63,162.68c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C358.39,161.77,358.86,162.25,359.63,162.68z"
              />
              <path
                d="M453.44,327.79c-0.64,0.52-1.02,1.07-1.42,1.07c-0.4,0.01-1.04-0.46-1.16-0.84c-0.21-0.72,0.29-1.29,1.04-1.2
	C452.35,326.88,452.75,327.34,453.44,327.79z"
              />
              <path
                d="M259.33,464.16c-0.68,0.48-1.19,1.06-1.45,0.96c-0.47-0.17-0.79-0.74-1.17-1.15c0.4-0.3,0.79-0.82,1.2-0.84
	C258.27,463.13,258.67,463.66,259.33,464.16z"
              />
              <path
                d="M347.86,165.91c0.44,0.65,0.97,1.09,0.93,1.46c-0.04,0.39-0.63,0.72-0.98,1.08c-0.36-0.41-0.91-0.77-1.01-1.24
	C346.73,166.93,347.36,166.49,347.86,165.91z"
              />
              <path
                d="M485.68,384.26c-0.46-0.64-0.99-1.07-0.96-1.44c0.03-0.39,0.61-0.74,0.96-1.1c0.37,0.4,0.93,0.76,1.04,1.22
	C486.78,383.22,486.17,383.67,485.68,384.26z"
              />
              <path
                d="M406.9,145.54c-0.44-0.67-0.91-1.07-0.93-1.5c-0.03-0.74,0.58-1.19,1.27-0.95c0.38,0.14,0.83,0.76,0.81,1.15
	C408.03,144.62,407.45,144.96,406.9,145.54z"
              />
              <path
                d="M439.18,163.04c-0.6,0.48-0.99,1.03-1.36,1.02c-0.39-0.02-0.75-0.58-1.13-0.9c0.37-0.4,0.68-0.98,1.14-1.14
	C438.08,161.94,438.57,162.56,439.18,163.04z"
              />
              <path
                d="M427.46,319.41c-0.59,0.51-0.97,1.08-1.36,1.09c-0.38,0.01-1-0.48-1.13-0.88c-0.22-0.72,0.3-1.29,1.02-1.24
	C426.43,318.43,426.82,318.94,427.46,319.41z"
              />
              <path
                d="M326.43,420.43c0.49,0.7,1.02,1.12,0.97,1.48c-0.05,0.38-0.64,0.96-0.99,0.95s-0.93-0.59-0.97-0.97
	C325.4,421.54,325.94,421.12,326.43,420.43z"
              />
              <path
                d="M510.29,276.07c-0.73,0.48-1.17,1-1.54,0.95c-0.39-0.05-0.71-0.64-1.06-0.99c0.36-0.34,0.7-0.92,1.1-0.95
	C509.16,275.04,509.58,275.57,510.29,276.07z"
              />
              <path
                d="M361.3,97.43c-0.68,0.54-1.08,1.09-1.45,1.08c-0.39-0.01-0.76-0.57-1.14-0.89c0.33-0.37,0.61-0.98,1-1.04
	C360.08,96.51,360.55,97,361.3,97.43z"
              />
              <path
                d="M420.2,320.26c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C419.43,319.03,419.7,319.65,420.2,320.26z"
              />
              <path
                d="M506.91,319.68c-0.66,0.5-1.06,1.03-1.42,1.01c-0.4-0.02-1.03-0.48-1.12-0.86c-0.17-0.7,0.34-1.23,1.07-1.13
	C505.86,318.76,506.23,319.21,506.91,319.68z"
              />
              <path
                d="M332.27,179.63c-0.7,0.49-1.08,0.96-1.51,1c-0.72,0.08-1.29-0.45-1.09-1.18c0.1-0.39,0.75-0.85,1.16-0.87
	C331.2,178.58,331.6,179.12,332.27,179.63z"
              />
              <path
                d="M389.35,274.11c0.56,0.62,1.14,0.98,1.14,1.34c0,0.38-0.52,1.02-0.87,1.05c-0.35,0.04-1.01-0.47-1.09-0.83
	C388.46,275.31,388.95,274.83,389.35,274.11z"
              />
              <path
                d="M289.47,369.6c0.49,0.63,1.02,1.02,1.01,1.38c-0.02,0.38-0.51,0.96-0.89,1.06c-0.7,0.19-1.26-0.31-1.16-1.04
	C288.48,370.59,288.97,370.23,289.47,369.6z"
              />
              <path
                d="M498.12,358.55c0.53,0.61,1.09,0.97,1.1,1.34c0,0.39-0.47,1-0.85,1.12c-0.69,0.22-1.29-0.24-1.23-0.99
	C497.17,359.6,497.65,359.22,498.12,358.55z"
              />
              <path
                d="M322.37,191.25c-0.58,0.52-0.94,1.11-1.34,1.13c-0.38,0.02-1.02-0.46-1.15-0.85c-0.24-0.71,0.26-1.3,0.99-1.26
	C321.31,190.3,321.72,190.81,322.37,191.25z"
              />
              <path
                d="M354.84,168.09c-0.42-0.66-0.93-1.11-0.88-1.47c0.05-0.39,0.6-0.93,0.99-0.99c0.72-0.12,1.24,0.43,1.09,1.15
	C355.95,167.19,355.4,167.5,354.84,168.09z"
              />
              <path
                d="M495.27,372.52c-0.48-0.66-0.97-1.03-1.01-1.46c-0.08-0.75,0.51-1.21,1.21-1.01c0.39,0.11,0.87,0.72,0.87,1.1
	C496.34,371.53,495.78,371.9,495.27,372.52z"
              />
              <path
                d="M336.73,190.23c-0.71,0.35-1.17,0.77-1.6,0.74c-0.75-0.05-1.14-0.72-0.82-1.38c0.18-0.37,0.88-0.75,1.26-0.68
	C335.95,188.98,336.22,189.61,336.73,190.23z"
              />
              <path
                d="M349.77,174.37c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C348.75,173.34,349.13,173.87,349.77,174.37z"
              />
              <path
                d="M495.07,349.77c-0.62,0.45-1.04,0.99-1.42,0.96c-0.38-0.03-0.71-0.62-1.07-0.96c0.39-0.38,0.73-0.95,1.18-1.07
	C494.03,348.62,494.49,349.26,495.07,349.77z"
              />
              <path
                d="M452.88,320.89c-0.6,0.49-0.99,1.04-1.39,1.05c-0.38,0-0.98-0.51-1.08-0.91c-0.19-0.71,0.34-1.26,1.05-1.18
	C451.88,319.9,452.26,320.42,452.88,320.89z"
              />
              <path
                d="M468.93,380.76c-0.56,0.54-1,1.21-1.25,1.15c-0.49-0.12-0.87-0.66-1.29-1.03c0.36-0.37,0.67-0.97,1.08-1.03
	C467.83,379.79,468.28,380.33,468.93,380.76z"
              />
              <path
                d="M443.04,166.43c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C441.95,165.29,442.43,165.93,443.04,166.43z"
              />
              <path
                d="M296.85,471.42c0.64-0.52,0.97-1,1.39-1.09c0.71-0.15,1.28,0.32,1.15,1.04c-0.07,0.39-0.65,0.9-1.04,0.95
	C297.98,472.37,297.55,471.87,296.85,471.42z"
              />
              <path
                d="M429.77,158.01c-0.46-0.64-0.98-1.05-0.95-1.42c0.03-0.38,0.54-0.94,0.94-1.03c0.71-0.16,1.24,0.36,1.12,1.08
	C430.81,157.06,430.29,157.4,429.77,158.01z"
              />
              <path
                d="M399.58,325.33c0.59-0.54,1.05-1.21,1.31-1.14c0.49,0.13,0.85,0.69,1.27,1.08c-0.37,0.36-0.7,0.96-1.11,1.01
	C400.68,326.33,400.23,325.78,399.58,325.33z"
              />
              <path
                d="M271.22,434.08c-0.57,0.51-1.02,1.15-1.27,1.08c-0.47-0.13-0.82-0.68-1.22-1.06c0.36-0.35,0.69-0.93,1.08-0.98
	C270.17,433.08,270.59,433.62,271.22,434.08z"
              />
              <path
                d="M238.5,200.46c0.51,0.57,1.01,0.89,1.12,1.31c0.19,0.72-0.33,1.27-1.01,1.18c-0.39-0.05-0.95-0.62-1-1.01
	C237.56,201.57,238.07,201.12,238.5,200.46z"
              />
              <path
                d="M269.34,412.43c-0.57,0.54-0.88,1.08-1.31,1.19c-0.76,0.2-1.28-0.4-1.22-1.07c0.04-0.4,0.63-0.98,1.04-1.04
	C268.22,411.45,268.68,411.99,269.34,412.43z"
              />
              <path
                d="M294.43,469.41c-0.57-0.57-1.12-0.87-1.21-1.28c-0.16-0.7,0.33-1.27,1.05-1.16c0.4,0.06,0.94,0.58,1,0.97
	C295.33,468.3,294.84,468.75,294.43,469.41z"
              />
              <path
                d="M266.51,629.71c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.91,1.07-0.94
	C265.4,628.69,265.81,629.21,266.51,629.71z"
              />
              <path
                d="M345.74,270.74c0.56,0.6,1.09,0.92,1.18,1.34c0.15,0.73-0.39,1.26-1.1,1.13c-0.4-0.07-0.93-0.62-0.98-1.01
	C344.8,271.84,345.31,271.41,345.74,270.74z"
              />
              <path
                d="M471.98,350.12c0.52,0.62,1.17,1.09,1.1,1.37c-0.12,0.47-0.71,0.83-1.1,1.24c-0.34-0.37-0.92-0.73-0.95-1.13
	C471,351.22,471.53,350.78,471.98,350.12z"
              />
              <path
                d="M405.35,274.68c-0.71,0.35-1.17,0.77-1.6,0.74c-0.75-0.05-1.14-0.72-0.82-1.38c0.18-0.37,0.88-0.75,1.26-0.68
	C404.57,273.43,404.84,274.06,405.35,274.68z"
              />
              <path
                d="M408.21,323.02c0.52,0.64,1.05,1,1.11,1.42c0.12,0.75-0.47,1.24-1.18,1.08c-0.39-0.09-0.91-0.68-0.93-1.07
	C407.19,324.08,407.73,323.67,408.21,323.02z"
              />
              <path
                d="M281.89,326.23c-0.52-0.61-1.16-1.06-1.1-1.34c0.11-0.47,0.68-0.84,1.05-1.25c0.34,0.37,0.92,0.71,0.96,1.1
	C282.85,325.13,282.33,325.56,281.89,326.23z"
              />
              <path
                d="M252.54,179.25c-0.58,0.54-0.9,1.06-1.32,1.17c-0.76,0.19-1.28-0.41-1.2-1.08c0.05-0.4,0.64-0.97,1.05-1.03
	C251.44,178.25,251.89,178.8,252.54,179.25z"
              />
              <path
                d="M512.92,304.7c-0.62,0.54-0.98,1.09-1.37,1.11c-0.39,0.02-1.05-0.41-1.17-0.78c-0.23-0.69,0.22-1.28,0.98-1.22
	C511.81,303.85,512.21,304.28,512.92,304.7z"
              />
              <path
                d="M363.4,165.92c-0.62,0.49-1.03,1.06-1.4,1.04c-0.4-0.02-0.78-0.59-1.16-0.92c0.38-0.41,0.7-1,1.17-1.17
	C362.26,164.78,362.77,165.42,363.4,165.92z"
              />
              <path
                d="M290.52,324.06c-0.7,0.49-1.08,0.96-1.51,1c-0.72,0.08-1.29-0.45-1.09-1.18c0.1-0.39,0.75-0.85,1.16-0.87
	C289.46,323.01,289.86,323.55,290.52,324.06z"
              />
              <path
                d="M503.58,323.43c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C502.34,322.51,502.81,322.99,503.58,323.43z"
              />
              <path
                d="M273.35,349.16c0.51,0.62,1.16,1.1,1.09,1.39c-0.13,0.47-0.72,0.82-1.12,1.22c-0.33-0.38-0.91-0.74-0.93-1.14
	C272.36,350.24,272.89,349.81,273.35,349.16z"
              />
              <path
                d="M346.67,304.81c-0.68,0.51-1.04,0.99-1.47,1.06c-0.72,0.11-1.3-0.4-1.14-1.13c0.09-0.39,0.71-0.88,1.12-0.91
	C345.56,303.8,345.98,304.33,346.67,304.81z"
              />
              <path
                d="M327.99,189.16c0.54,0.65,1.1,1.03,1.09,1.38c-0.01,0.38-0.56,1-0.9,1.02c-0.35,0.03-0.98-0.5-1.05-0.87
	C327.05,190.35,327.55,189.89,327.99,189.16z"
              />
              <path
                d="M293.41,273.31c-0.78,0.42-1.26,0.89-1.62,0.81c-0.39-0.08-0.66-0.69-0.98-1.06c0.39-0.31,0.78-0.85,1.17-0.86
	C292.34,272.19,292.73,272.75,293.41,273.31z"
              />
              <path
                d="M384.13,130.15c0.56,0.6,1.09,0.92,1.18,1.34c0.15,0.73-0.39,1.26-1.1,1.13c-0.4-0.07-0.93-0.62-0.98-1.01
	C383.18,131.25,383.69,130.82,384.13,130.15z"
              />
              <path
                d="M253.57,273.19c-0.73,0.48-1.17,1-1.54,0.95c-0.39-0.05-0.71-0.64-1.06-0.99c0.36-0.34,0.7-0.92,1.1-0.95
	C252.44,272.16,252.86,272.69,253.57,273.19z"
              />
              <path
                d="M273,468.43c0.44,0.71,0.94,1.15,0.88,1.5c-0.06,0.37-0.67,0.92-1.01,0.9c-0.35-0.02-0.9-0.62-0.92-0.99
	C271.93,469.49,272.47,469.1,273,468.43z"
              />
              <path
                d="M469.57,321.34c0.51,0.71,0.99,1.09,1.03,1.51c0.03,0.3-0.57,0.98-0.79,0.93c-0.43-0.09-1.04-0.49-1.12-0.86
	C468.63,322.57,469.13,322.09,469.57,321.34z"
              />
              <path
                d="M259.3,630.27c-0.74,0.42-1.19,0.9-1.55,0.83c-0.37-0.07-0.65-0.66-0.96-1.02c0.37-0.31,0.73-0.85,1.1-0.87
	C258.25,629.21,258.63,629.75,259.3,630.27z"
              />
              <path d="M282.69,64.33c-0.14,1.41-0.54,2.04-1.79,1.76C281.16,65.06,281.16,65.06,282.69,64.33z" />
              <path
                d="M369.19,166.91c-0.5-0.65-1.05-1.05-1.02-1.42c0.03-0.39,0.6-0.74,0.93-1.11c0.36,0.34,0.96,0.65,1.01,1.03
	C370.15,165.78,369.64,166.23,369.19,166.91z"
              />
              <path
                d="M506.42,272.83c-0.75,0.41-1.21,0.87-1.57,0.8c-0.37-0.08-0.63-0.67-0.94-1.04c0.37-0.3,0.75-0.84,1.12-0.84
	C505.39,271.74,505.76,272.29,506.42,272.83z"
              />
              <path
                d="M334.61,208.24c-0.73,0.44-1.21,0.95-1.56,0.87c-0.36-0.08-0.76-0.71-0.82-1.14c-0.03-0.24,0.64-0.84,0.92-0.8
	C333.57,207.23,333.94,207.71,334.61,208.24z"
              />
              <path
                d="M456.83,260.5c-0.55-0.68-1.13-1.08-1.1-1.44c0.03-0.39,0.57-0.86,0.99-1.03c0.17-0.07,0.88,0.52,0.89,0.84
	C457.64,259.29,457.23,259.73,456.83,260.5z"
              />
              <path
                d="M467.94,386.6c0.5,0.66,1.05,1.07,1.02,1.44c-0.02,0.39-0.6,0.74-0.94,1.11c-0.37-0.35-0.98-0.66-1.02-1.05
	C466.95,387.74,467.48,387.29,467.94,386.6z"
              />
              <path
                d="M478.6,345.16c-0.51-0.66-1.05-1.06-1.02-1.41c0.03-0.38,0.58-0.98,0.91-0.99c0.35-0.01,0.95,0.53,1.01,0.9
	C479.56,344.01,479.06,344.45,478.6,345.16z"
              />
              <path
                d="M277.04,431.96c0.47,0.7,0.98,1.14,0.93,1.49c-0.06,0.37-0.65,0.92-1,0.92c-0.34-0.01-0.9-0.6-0.93-0.98
	C276.01,433.04,276.54,432.64,277.04,431.96z"
              />
              <path
                d="M286.93,467.21c0.56,0.68,1.13,1.08,1.11,1.44c-0.03,0.39-0.57,0.86-0.99,1.04c-0.17,0.07-0.88-0.52-0.89-0.83
	C286.13,468.43,286.54,467.99,286.93,467.21z"
              />
              <path
                d="M485.61,344.44c-0.41-0.71-0.9-1.17-0.83-1.53c0.07-0.38,0.68-0.65,1.05-0.97c0.31,0.38,0.86,0.76,0.87,1.14
	C486.7,343.44,486.14,343.82,485.61,344.44z"
              />
              <path
                d="M235.02,350.61c-0.64,0.51-1.04,1.08-1.41,1.06c-0.38-0.02-1-0.61-1.01-0.97c-0.01-0.36,0.56-0.99,0.94-1.04
	C233.9,349.61,234.35,350.15,235.02,350.61z"
              />
              <path
                d="M319.7,202.4c-0.64,0.47-1.07,1.02-1.43,0.98c-0.38-0.04-0.97-0.65-0.97-1c0-0.36,0.6-0.95,0.99-0.99
	C318.65,201.36,319.07,201.92,319.7,202.4z"
              />
              <path d="M255.47,95.21c0.74,1.03,0.53,1.73-0.74,2.11C253.93,96.25,255.04,95.84,255.47,95.21z" />
              <path
                d="M322.21,164.44c0.54,0.64,1.11,1.02,1.11,1.39c-0.01,0.39-0.57,0.77-0.89,1.16c-0.38-0.33-0.99-0.61-1.06-0.99
	C321.3,165.63,321.79,165.16,322.21,164.44z"
              />
              <path
                d="M486.92,371.79c0.64-0.49,1.07-1.06,1.42-1.02c0.39,0.04,1,0.64,1.01,1c0,0.36-0.6,0.97-0.99,1.02
	C488,372.83,487.57,372.27,486.92,371.79z"
              />
              <path
                d="M456.74,324.2c-0.76,0.39-1.24,0.85-1.59,0.77c-0.37-0.09-0.63-0.68-0.93-1.05c0.38-0.3,0.77-0.82,1.15-0.82
	C455.73,323.09,456.09,323.64,456.74,324.2z"
              />
              <path
                d="M495.15,325.73c-0.51-0.64-1.06-1.04-1.04-1.4c0.02-0.38,0.58-0.74,0.91-1.11c0.36,0.33,0.96,0.63,1.02,1.01
	C496.1,324.59,495.59,325.04,495.15,325.73z"
              />
              <path
                d="M299.32,424.66c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C298.28,423.63,298.64,424.12,299.32,424.66z"
              />
              <path
                d="M491.16,362.23c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C492.05,360.96,491.57,361.45,491.16,362.23z"
              />
              <path
                d="M256.43,198.33c-0.51-0.66-1.05-1.06-1.02-1.41c0.03-0.38,0.58-0.98,0.91-0.99c0.35-0.01,0.95,0.53,1.01,0.9
	C257.39,197.18,256.89,197.62,256.43,198.33z"
              />
              <path
                d="M480.74,351.02c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.84,1.12-0.86
	C479.69,349.94,480.07,350.48,480.74,351.02z"
              />
              <path
                d="M486.35,351.67c-0.44-0.76-0.93-1.24-0.86-1.59c0.08-0.37,0.69-0.77,1.12-0.85c0.22-0.04,0.82,0.64,0.78,0.94
	C487.35,350.6,486.87,350.97,486.35,351.67z"
              />
              <path
                d="M334.99,231.2c-0.44-0.69-0.95-1.14-0.89-1.5c0.06-0.38,0.66-0.68,1.02-1.01c0.33,0.37,0.89,0.73,0.91,1.11
	C336.05,230.17,335.5,230.57,334.99,231.2z"
              />
              <path
                d="M250.64,629.43c0.46,0.67,0.99,1.1,0.95,1.47c-0.04,0.38-0.63,0.71-0.98,1.06c-0.34-0.36-0.93-0.7-0.96-1.08
	C249.61,630.5,250.16,630.08,250.64,629.43z"
              />
              <path
                d="M398.65,321.98c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C397.61,320.95,397.97,321.44,398.65,321.98z"
              />
              <path d="M229.37,165.85c1.48,0.88,1.07,1.69,0,2.58C228.5,167.52,229.2,166.68,229.37,165.85z" />
              <path
                d="M280.42,348.69c0.56,0.68,1.05,1.03,1.12,1.45c0.05,0.3-0.51,1.01-0.72,0.98c-0.44-0.06-1.07-0.43-1.17-0.79
	C279.55,349.98,280.03,349.48,280.42,348.69z"
              />
              <path
                d="M486.42,324.68c0.72-0.44,1.2-0.96,1.54-0.89c0.36,0.08,0.77,0.7,0.84,1.13c0.04,0.24-0.63,0.85-0.91,0.81
	C487.47,325.68,487.1,325.2,486.42,324.68z"
              />
              <path
                d="M424.84,164.32c-0.72,0.44-1.17,0.92-1.53,0.87c-0.37-0.06-0.66-0.64-0.98-1c0.36-0.32,0.71-0.87,1.08-0.89
	C423.77,163.28,424.17,163.8,424.84,164.32z"
              />
              <path
                d="M418.66,152.46c0.53,0.63,1.09,1.01,1.08,1.37c-0.01,0.39-0.56,0.76-0.87,1.14c-0.37-0.32-0.98-0.6-1.05-0.97
	C417.76,153.64,418.25,153.17,418.66,152.46z"
              />
              <path
                d="M397.47,141.3c-0.65,0.56-1.05,1.15-1.4,1.12c-0.38-0.03-0.87-0.57-1.02-1c-0.07-0.21,0.5-0.91,0.81-0.92
	C396.27,140.48,396.71,140.9,397.47,141.3z"
              />
              <path
                d="M476.48,360.48c0.55,0.63,1.11,1,1.11,1.35c-0.01,0.37-0.53,0.99-0.88,1.03c-0.34,0.03-0.97-0.48-1.05-0.85
	C475.58,361.67,476.06,361.21,476.48,360.48z"
              />
              <path
                d="M510.22,288.97c0.48,0.7,1,1.12,0.96,1.47c-0.05,0.37-0.63,0.94-0.98,0.94c-0.34,0-0.91-0.59-0.95-0.96
	C509.21,290.07,509.73,289.65,510.22,288.97z"
              />
              <path
                d="M336.85,86.52c-0.42-0.72-0.9-1.18-0.83-1.53c0.08-0.37,0.7-0.89,1.04-0.86c0.35,0.03,0.87,0.65,0.89,1.02
	C337.96,85.51,337.4,85.88,336.85,86.52z"
              />
              <path
                d="M319.47,408.12c-0.68,0.55-1.09,1.13-1.43,1.1c-0.38-0.04-0.85-0.6-0.99-1.03c-0.07-0.21,0.52-0.9,0.82-0.9
	C318.29,407.27,318.72,407.7,319.47,408.12z"
              />
              <path
                d="M495.57,356.9c-0.65,0.48-1.08,1.03-1.45,1c-0.38-0.04-0.98-0.65-0.98-1.01c0-0.36,0.6-0.98,0.98-1.01
	C494.5,355.86,494.92,356.42,495.57,356.9z"
              />
              <path
                d="M486.46,375.49c-0.68,0.55-1.09,1.13-1.43,1.1c-0.38-0.04-0.85-0.6-0.99-1.03c-0.07-0.21,0.52-0.9,0.82-0.9
	C485.28,374.64,485.71,375.07,486.46,375.49z"
              />
              <path
                d="M291.25,470.6c0.42,0.7,0.91,1.16,0.85,1.53c-0.07,0.38-0.67,0.66-1.04,0.98c-0.32-0.38-0.87-0.75-0.88-1.13
	C290.18,471.61,290.74,471.23,291.25,470.6z"
              />
              <path
                d="M472.06,326.2c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.59,0.96-0.97,1
	C473.13,327.22,472.7,326.67,472.06,326.2z"
              />
              <path
                d="M335.85,238.4c-0.51-0.64-1.06-1.04-1.04-1.4c0.02-0.38,0.58-0.74,0.91-1.11c0.36,0.33,0.96,0.63,1.02,1.01
	C336.79,237.26,336.28,237.71,335.85,238.4z"
              />
              <path
                d="M339.09,294.29c0.69-0.49,1.11-1,1.47-0.97c0.38,0.04,0.7,0.6,1.05,0.94c-0.34,0.34-0.65,0.92-1.02,0.96
	C340.22,295.27,339.79,294.76,339.09,294.29z"
              />
              <path
                d="M467.74,326.53c-0.61,0.54-0.98,1.13-1.35,1.13c-0.38,0-1.03-0.55-1.06-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C466.56,325.6,467.04,326.11,467.74,326.53z"
              />
              <path
                d="M263.15,633.25c-0.63,0.49-0.96,0.95-1.26,0.94c-0.46-0.02-0.91-0.38-1.36-0.6c0.33-0.37,0.61-0.96,1-1.05
	C261.9,632.45,262.39,632.89,263.15,633.25z"
              />
              <path
                d="M498.35,321.92c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C499.24,320.65,498.76,321.15,498.35,321.92z"
              />
              <path
                d="M680.89,631.82c0.43,0.67,0.95,1.12,0.9,1.5c-0.05,0.35-0.71,0.85-1.09,0.83c-0.33-0.01-0.91-0.65-0.9-0.99
	C679.82,632.77,680.37,632.41,680.89,631.82z"
              />
              <path
                d="M350.91,161.81c0.48,0.7,1,1.12,0.96,1.47c-0.05,0.37-0.63,0.94-0.98,0.94c-0.34,0-0.91-0.59-0.95-0.96
	C349.9,162.91,350.42,162.5,350.91,161.81z"
              />
              <path
                d="M257.57,276.42c-0.7,0.42-1.17,0.93-1.53,0.86c-0.37-0.07-0.91-0.73-0.88-1.09c0.03-0.35,0.68-0.91,1.06-0.9
	C256.58,275.29,256.95,275.88,257.57,276.42z"
              />
              <path
                d="M284.68,465.2c-0.6,0.54-0.97,1.14-1.33,1.13c-0.39,0-1.04-0.54-1.07-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C283.52,464.27,283.99,464.79,284.68,465.2z"
              />
              <path
                d="M475.46,373.05c-0.74,0.44-1.18,0.92-1.54,0.86c-0.38-0.07-0.66-0.64-0.99-1c0.36-0.32,0.71-0.87,1.09-0.89
	C474.39,372,474.78,372.54,475.46,373.05z"
              />
              <path
                d="M297.18,83.35c0.47,0.68,1,1.11,0.96,1.49c-0.04,0.39-0.64,0.72-0.99,1.08c-0.35-0.36-0.95-0.71-0.98-1.1
	C296.13,84.44,296.69,84.01,297.18,83.35z"
              />
              <path
                d="M459.17,325.92c0.44,0.71,0.94,1.15,0.88,1.5c-0.06,0.37-0.67,0.92-1.01,0.9c-0.35-0.02-0.9-0.62-0.92-0.99
	C458.11,326.97,458.65,326.59,459.17,325.92z"
              />
              <path
                d="M296.28,361.65c0.44,0.7,0.95,1.16,0.9,1.53c-0.06,0.38-0.66,0.69-1.03,1.03c-0.33-0.38-0.91-0.74-0.93-1.13
	C295.2,362.7,295.76,362.3,296.28,361.65z"
              />
              <path
                d="M506.67,295.72c-0.4-0.73-0.87-1.2-0.79-1.54c0.09-0.37,0.73-0.87,1.07-0.83c0.35,0.04,0.86,0.68,0.86,1.05
	C507.81,294.75,507.24,295.1,506.67,295.72z"
              />
              <path
                d="M467.58,339.58c0.44,0.7,0.95,1.16,0.9,1.53c-0.06,0.38-0.66,0.69-1.03,1.03c-0.33-0.38-0.91-0.74-0.93-1.13
	C466.5,340.63,467.07,340.23,467.58,339.58z"
              />
              <path
                d="M499.09,325.94c0.4,0.73,0.88,1.2,0.8,1.54c-0.09,0.37-0.72,0.87-1.07,0.83c-0.34-0.04-0.86-0.67-0.86-1.04
	C497.96,326.92,498.52,326.56,499.09,325.94z"
              />
              <path
                d="M288.66,278.74c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C289.55,277.46,289.07,277.96,288.66,278.74z"
              />
              <path
                d="M244.84,631.47c-0.68,0.43-1.14,0.94-1.49,0.88c-0.37-0.07-0.91-0.7-0.88-1.05c0.03-0.35,0.66-0.89,1.04-0.9
	C243.86,630.38,244.24,630.95,244.84,631.47z"
              />
              <path d="M231.42,157.1c0.18-1.11,0.28-1.77,0.4-2.51C233.33,155.55,233.29,155.96,231.42,157.1z" />
              <path
                d="M392.05,139.57c-0.42-0.72-0.92-1.19-0.86-1.56c0.07-0.38,0.69-0.66,1.07-0.99c0.32,0.39,0.87,0.77,0.88,1.16
	C393.15,138.56,392.58,138.94,392.05,139.57z"
              />
              <path
                d="M506.17,312.48c-0.7,0.49-1.11,1.01-1.46,0.98c-0.38-0.04-0.71-0.6-1.06-0.93c0.34-0.34,0.65-0.91,1.03-0.97
	C505.02,311.51,505.45,312.01,506.17,312.48z"
              />
              <path
                d="M491.83,326.63c0.43,0.71,0.93,1.18,0.87,1.55c-0.07,0.38-0.69,0.67-1.06,1c-0.32-0.38-0.88-0.76-0.89-1.15
	C490.74,327.65,491.31,327.26,491.83,326.63z"
              />
              <path
                d="M308.75,172.86c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C307.69,173.91,308.23,173.52,308.75,172.86z"
              />
              <path
                d="M507.46,302.93c-0.44-0.72-0.93-1.17-0.87-1.52c0.07-0.37,0.69-0.9,1.04-0.88c0.34,0.02,0.87,0.64,0.89,1.01
	C508.54,301.9,507.99,302.28,507.46,302.93z"
              />
              <path
                d="M278.18,306.86c-0.61,0.54-0.97,1.12-1.33,1.11c-0.38,0-1.02-0.54-1.05-0.89c-0.03-0.35,0.5-1,0.87-1.07
	C277.02,305.95,277.48,306.45,278.18,306.86z"
              />
              <path
                d="M342,167.85c-0.65,0.48-1.07,1.02-1.43,0.98c-0.38-0.04-0.97-0.64-0.97-0.99c0-0.36,0.6-0.96,0.97-0.99
	C340.94,166.81,341.36,167.37,342,167.85z"
              />
              <path
                d="M311.91,424.57c-0.41-0.71-0.9-1.17-0.83-1.53c0.07-0.38,0.68-0.65,1.05-0.97c0.31,0.38,0.86,0.76,0.87,1.14
	C313,423.58,312.44,423.95,311.91,424.57z"
              />
              <path
                d="M492.92,367.86c-0.62,0.51-1.01,1.07-1.37,1.05c-0.38-0.02-1-0.58-1.02-0.93c-0.01-0.36,0.54-0.98,0.92-1.04
	C491.81,366.89,492.26,367.42,492.92,367.86z"
              />
              <path
                d="M273.89,423.14c-0.66,0.47-1.09,1.02-1.46,0.98c-0.38-0.04-0.98-0.66-0.97-1.01c0.01-0.36,0.61-0.97,0.99-1
	C272.82,422.07,273.24,422.64,273.89,423.14z"
              />
              <path
                d="M500.41,260.91c0.44,0.68,0.96,1.13,0.91,1.5c-0.05,0.38-0.65,0.69-1.01,1.02c-0.33-0.37-0.9-0.72-0.92-1.1
	C499.37,261.96,499.92,261.55,500.41,260.91z"
              />
              <path
                d="M389.78,134.74c-0.65,0.48-1.09,1.04-1.44,1c-0.39-0.05-1-0.66-0.99-1.02c0-0.36,0.62-0.96,1.01-1
	C388.71,133.68,389.13,134.25,389.78,134.74z"
              />
              <path
                d="M385.04,137.35c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C383.98,138.4,384.52,138.01,385.04,137.35z"
              />
              <path
                d="M274.34,430.26c-0.63,0.51-1.03,1.07-1.39,1.05c-0.38-0.02-0.99-0.6-1-0.95c-0.01-0.35,0.55-0.97,0.93-1.03
	C273.24,429.28,273.68,429.8,274.34,430.26z"
              />
              <path
                d="M388.7,143.42c-0.41-0.76-0.83-1.2-0.82-1.62c0.01-0.31,0.7-0.92,0.88-0.85c0.43,0.16,0.98,0.63,1.01,1.01
	C389.8,142.32,389.23,142.73,388.7,143.42z"
              />
              <path
                d="M229.62,385.62c0.43,0.77,0.92,1.25,0.84,1.61c-0.08,0.37-0.69,0.77-1.12,0.86c-0.2,0.04-0.8-0.65-0.77-0.96
	C228.62,386.7,229.1,386.32,229.62,385.62z"
              />
              <path
                d="M493.07,264.34c-0.44-0.75-0.93-1.24-0.86-1.59c0.08-0.37,0.69-0.77,1.12-0.85c0.22-0.04,0.82,0.64,0.78,0.94
	C494.07,263.27,493.59,263.64,493.07,264.34z"
              />
              <path
                d="M294.3,366.93c-0.69,0.51-1.12,1.07-1.47,1.02c-0.37-0.05-0.82-0.64-0.93-1.08c-0.06-0.22,0.56-0.88,0.86-0.87
	C293.18,366.03,293.59,366.47,294.3,366.93z"
              />
              <path
                d="M313.1,225.93c-0.52-0.64-1.07-1.02-1.06-1.39c0.01-0.39,0.57-0.76,0.88-1.13c0.37,0.33,0.97,0.61,1.04,0.99
	C314.02,224.76,313.52,225.22,313.1,225.93z"
              />
              <path
                d="M270.52,427.09c-0.7,0.42-1.17,0.93-1.53,0.86c-0.37-0.07-0.91-0.73-0.88-1.09c0.03-0.35,0.68-0.91,1.06-0.9
	C269.54,425.96,269.91,426.55,270.52,427.09z"
              />
              <path
                d="M471.56,330.05c-0.65,0.47-1.08,1.01-1.44,0.97c-0.38-0.04-0.97-0.65-0.96-1c0.01-0.36,0.6-0.95,0.98-0.99
	C470.5,329,470.92,329.56,471.56,330.05z"
              />
              <path
                d="M483.1,253.32c-0.68,0.45-1.13,0.98-1.49,0.93c-0.38-0.05-0.95-0.69-0.93-1.04c0.02-0.36,0.64-0.94,1.02-0.96
	C482.06,252.23,482.46,252.8,483.1,253.32z"
              />
              <path
                d="M490.54,339c-0.6,0.54-0.97,1.14-1.33,1.13c-0.39,0-1.04-0.54-1.07-0.9c-0.03-0.36,0.5-1.01,0.88-1.09
	C489.36,338.07,489.84,338.58,490.54,339z"
              />
              <path
                d="M365.21,163.57c-0.43-0.75-0.86-1.18-0.85-1.6c0-0.31,0.68-0.93,0.86-0.86c0.43,0.15,1,0.6,1.04,0.98
	C366.3,162.45,365.74,162.88,365.21,163.57z"
              />
              <path
                d="M348.78,286.57c-0.7,0.49-1.11,1.01-1.47,0.98c-0.38-0.04-0.71-0.6-1.06-0.93c0.34-0.34,0.65-0.91,1.03-0.97
	C347.63,285.6,348.06,286.1,348.78,286.57z"
              />
              <path
                d="M423.1,269.04c-0.63,0.5-1.03,1.07-1.39,1.04c-0.38-0.02-0.99-0.6-1-0.96c-0.01-0.35,0.56-0.97,0.93-1.02
	C421.99,268.05,422.43,268.58,423.1,269.04z"
              />
              <path
                d="M296.48,475.35c-0.76,0.39-1.24,0.85-1.59,0.77c-0.37-0.09-0.63-0.68-0.93-1.05c0.38-0.3,0.77-0.82,1.15-0.82
	C295.47,474.24,295.83,474.79,296.48,475.35z"
              />
              <path
                d="M343.5,268.77c-0.68,0.52-1.08,1.05-1.43,1.02c-0.38-0.03-0.73-0.57-1.09-0.89c0.33-0.35,0.62-0.93,0.99-1
	C342.32,267.84,342.77,268.32,343.5,268.77z"
              />
              <path
                d="M289.25,476.01c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C288.21,474.98,288.57,475.46,289.25,476.01z"
              />
              <path
                d="M303.9,268.45c-0.65,0.56-1.05,1.15-1.4,1.12c-0.38-0.03-0.87-0.57-1.02-1c-0.07-0.21,0.5-0.91,0.81-0.92
	C302.7,267.64,303.14,268.05,303.9,268.45z"
              />
              <path
                d="M485.76,368.36c-0.68,0.51-1.12,1.07-1.47,1.03c-0.37-0.05-0.82-0.64-0.93-1.07c-0.06-0.22,0.56-0.88,0.85-0.87
	C484.64,367.47,485.05,367.91,485.76,368.36z"
              />
              <path
                d="M335.75,222.87c-0.75,0.33-1.29,0.77-1.63,0.65c-0.37-0.13-0.8-0.83-0.73-1.2c0.07-0.34,0.79-0.8,1.16-0.75
	C334.92,221.62,335.2,222.25,335.75,222.87z"
              />
              <path
                d="M490.3,378.98c-0.65,0.48-1.08,1.03-1.45,1c-0.38-0.04-0.98-0.65-0.98-1.01c0-0.36,0.6-0.98,0.98-1.01
	C489.22,377.93,489.64,378.49,490.3,378.98z"
              />
              <path
                d="M334.87,168.68c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.85,1.12-0.86
	C333.82,167.61,334.2,168.15,334.87,168.68z"
              />
              <path
                d="M282.75,317.6c-0.66,0.46-1.1,0.99-1.45,0.94c-0.38-0.05-0.95-0.67-0.93-1.03c0.01-0.35,0.63-0.93,1.01-0.95
	C281.73,316.54,282.13,317.1,282.75,317.6z"
              />
              <path
                d="M491.75,353.53c-0.72,0.49-1.17,1.03-1.52,0.98c-0.37-0.06-0.81-0.66-0.91-1.09c-0.05-0.22,0.58-0.87,0.88-0.85
	C490.63,352.6,491.03,353.05,491.75,353.53z"
              />
              <path
                d="M497.01,331.49c-0.66,0.47-1.09,1.02-1.46,0.98c-0.38-0.04-0.98-0.66-0.97-1.01c0.01-0.36,0.61-0.97,0.99-1
	C495.95,330.43,496.36,330.99,497.01,331.49z"
              />
              <path
                d="M317.37,399.55c0.56,0.68,1.05,1.03,1.12,1.45c0.05,0.3-0.51,1.01-0.72,0.98c-0.44-0.06-1.07-0.43-1.17-0.79
	C316.5,400.85,316.97,400.34,317.37,399.55z"
              />
              <path
                d="M277.97,346.87c-0.67,0.46-1.12,1.01-1.47,0.95c-0.39-0.06-0.97-0.68-0.95-1.04c0.01-0.36,0.64-0.94,1.03-0.97
	C276.93,345.79,277.34,346.36,277.97,346.87z"
              />
              <path
                d="M320.66,422.63c-0.63,0.48-1.05,1.03-1.41,1c-0.38-0.04-0.99-0.63-0.98-0.98c0-0.36,0.59-0.96,0.97-1.01
	C319.59,421.6,320.02,422.15,320.66,422.63z"
              />
              <path
                d="M228.56,358.19c-0.66,0.55-1,1.05-1.42,1.13c-0.28,0.05-0.98-0.51-0.95-0.75c0.05-0.44,0.41-1.07,0.77-1.17
	C227.3,357.3,227.81,357.78,228.56,358.19z"
              />
              <path
                d="M239.89,423.6c-0.5-0.64-1.04-1.03-1.02-1.4c0.02-0.39,0.58-0.74,0.9-1.11c0.36,0.34,0.96,0.64,1.02,1.02
	C240.84,422.47,240.33,422.92,239.89,423.6z"
              />
              <path
                d="M452.13,313.58c-0.6,0.54-0.96,1.12-1.32,1.11c-0.38,0-1.02-0.54-1.05-0.88c-0.03-0.35,0.49-1,0.86-1.08
	C450.97,312.66,451.44,313.16,452.13,313.58z"
              />
              <path
                d="M423.38,316.08c-0.69,0.5-1.13,1.06-1.48,1.01c-0.37-0.05-0.82-0.65-0.92-1.08c-0.05-0.23,0.57-0.88,0.86-0.86
	C422.27,315.17,422.67,315.62,423.38,316.08z"
              />
              <path
                d="M472.3,337.1c-0.61,0.54-0.98,1.13-1.34,1.12c-0.39-0.01-1.04-0.55-1.07-0.91c-0.03-0.36,0.51-1.01,0.89-1.09
	C471.14,336.15,471.61,336.67,472.3,337.1z"
              />
              <path
                d="M286.85,280.96c-0.67,0.55-1.08,1.14-1.43,1.1c-0.38-0.04-0.86-0.6-1-1.03c-0.07-0.21,0.52-0.9,0.82-0.91
	C285.66,280.12,286.1,280.54,286.85,280.96z"
              />
              <path
                d="M472.54,333.4c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.58,0.96-0.97,1
	C473.61,334.42,473.18,333.87,472.54,333.4z"
              />
              <path
                d="M253.86,628.57c-0.41-0.77-0.83-1.21-0.82-1.63c0.01-0.32,0.7-0.93,0.88-0.86c0.43,0.17,0.97,0.63,1.01,1.02
	C254.96,627.46,254.4,627.87,253.86,628.57z"
              />
              <path
                d="M307.77,564.84c-0.75,0.41-1.19,0.83-1.61,0.81c-0.29-0.01-0.82-0.71-0.75-0.97c0.12-0.42,0.62-0.98,0.99-1.01
	C306.75,563.65,307.13,564.26,307.77,564.84z"
              />
              <path d="M599.8,161.29c-2.02-0.35-1.58-1.42-1.06-2.54C599.05,159.5,599.36,160.23,599.8,161.29z" />
              <path
                d="M332.39,215.28c0.72-0.44,1.2-0.96,1.54-0.89c0.36,0.08,0.77,0.7,0.84,1.13c0.04,0.24-0.63,0.85-0.91,0.81
	C333.44,216.27,333.07,215.8,332.39,215.28z"
              />
              <path
                d="M231.65,354.37c-0.61,0.54-0.98,1.13-1.35,1.13c-0.38,0-1.03-0.55-1.06-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C230.48,353.43,230.95,353.94,231.65,354.37z"
              />
              <path
                d="M468.47,333.98c-0.69,0.43-1.16,0.96-1.51,0.89c-0.38-0.07-0.93-0.72-0.91-1.07c0.03-0.36,0.67-0.91,1.06-0.92
	C467.47,332.86,467.85,333.44,468.47,333.98z"
              />
              <path
                d="M485.33,334.56c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C484.27,335.61,484.81,335.22,485.33,334.56z"
              />
              <path
                d="M429,313.93c0.54,0.65,1.09,1.03,1.08,1.38c-0.02,0.37-0.55,0.98-0.9,1.01c-0.34,0.03-0.96-0.5-1.03-0.87
	C428.08,315.1,428.57,314.65,429,313.93z"
              />
              <path
                d="M444.95,314.3c-0.69,0.43-1.16,0.96-1.51,0.89c-0.38-0.07-0.93-0.72-0.9-1.07c0.03-0.36,0.67-0.91,1.06-0.92
	C443.96,313.18,444.34,313.77,444.95,314.3z"
              />
              <path
                d="M275.64,457.4c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C274.58,458.45,275.12,458.06,275.64,457.4z"
              />
              <path
                d="M277.26,478.98c0.47,0.7,0.98,1.12,0.93,1.47c-0.05,0.37-0.63,0.94-0.97,0.94c-0.35-0.01-0.92-0.59-0.95-0.96
	C276.23,480.08,276.76,479.67,277.26,478.98z"
              />
              <path d="M632.72,634.24c-0.17-1.2,0.21-1.63,0.94-1.68c0.84-0.06,1.27,0.4,1.3,1.52C634.23,634.14,633.52,634.19,632.72,634.24z" />
              <path
                d="M491.02,346.35c-0.65,0.48-1.09,1.04-1.44,1c-0.39-0.05-1-0.66-0.99-1.02c0-0.36,0.62-0.96,1.01-1
	C489.95,345.29,490.38,345.86,491.02,346.35z"
              />
              <path
                d="M269.82,419.81c-0.67,0.46-1.12,1.01-1.47,0.96c-0.39-0.06-0.97-0.68-0.95-1.04c0.01-0.36,0.64-0.94,1.03-0.97
	C268.78,418.72,269.18,419.3,269.82,419.81z"
              />
              <path
                d="M302.59,314.17c0.51,0.65,1.06,1.04,1.04,1.41c-0.02,0.39-0.59,0.74-0.91,1.11c-0.36-0.33-0.96-0.63-1.02-1.01
	C301.64,315.31,302.15,314.86,302.59,314.17z"
              />
              <path
                d="M229.35,631.33c0.43,0.71,0.93,1.18,0.86,1.54c-0.07,0.38-0.68,0.67-1.06,0.99c-0.32-0.38-0.88-0.76-0.88-1.15
	C228.27,632.35,228.83,631.97,229.35,631.33z"
              />
              <path
                d="M493.9,335.23c-0.63,0.52-1.02,1.09-1.38,1.07c-0.39-0.02-1.02-0.59-1.04-0.95c-0.01-0.36,0.55-1,0.94-1.06
	C492.77,334.24,493.23,334.78,493.9,335.23z"
              />
              <path
                d="M290.2,479.27c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.58,0.96-0.97,1
	C291.27,480.29,290.84,479.74,290.2,479.27z"
              />
              <path
                d="M378.49,298.43c-0.68,0.45-1.13,0.98-1.49,0.93c-0.38-0.05-0.95-0.69-0.93-1.04c0.02-0.36,0.64-0.94,1.02-0.96
	C377.46,297.34,377.86,297.91,378.49,298.43z"
              />
              <path
                d="M244.97,438.42c0.43,0.71,0.92,1.16,0.86,1.53c-0.06,0.38-0.64,0.68-0.99,1.02c-0.32-0.37-0.86-0.74-0.88-1.13
	C243.94,439.47,244.47,439.07,244.97,438.42z"
              />
              <path
                d="M331.96,192.52c0.47,0.7,0.98,1.12,0.93,1.47c-0.05,0.37-0.63,0.94-0.97,0.94c-0.35-0.01-0.92-0.59-0.95-0.96
	C330.93,193.62,331.46,193.21,331.96,192.52z"
              />
              <path
                d="M285.89,479.61c-0.67,0.55-1.08,1.14-1.43,1.1c-0.38-0.04-0.86-0.6-1-1.03c-0.07-0.21,0.52-0.9,0.82-0.91
	C284.7,478.77,285.14,479.19,285.89,479.61z"
              />
              <path
                d="M383.07,282c0.47,0.68,1,1.11,0.96,1.49c-0.04,0.39-0.64,0.72-0.99,1.08c-0.35-0.36-0.95-0.71-0.98-1.1
	C382.03,283.09,382.58,282.67,383.07,282z"
              />
              <path
                d="M510.97,283.36c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.84,1.12-0.86
	C509.92,282.28,510.3,282.83,510.97,283.36z"
              />
              <path
                d="M433.73,161.17c-0.51-0.65-1.07-1.05-1.05-1.42c0.02-0.39,0.59-0.76,0.92-1.13c0.37,0.34,0.98,0.64,1.04,1.03
	C434.7,160.01,434.18,160.47,433.73,161.17z"
              />
              <path
                d="M353.32,91.15c-0.78,0.43-1.21,0.85-1.64,0.85c-0.26,0-0.74-0.65-0.73-0.99c0.01-0.32,0.57-0.9,0.84-0.87
	C352.23,90.19,352.61,90.64,353.32,91.15z"
              />
              <path
                d="M487.58,366.02c-0.41-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.9,0.83-0.84c0.42,0.15,0.98,0.58,1.03,0.95
	C488.64,364.93,488.09,365.34,487.58,366.02z"
              />
              <path
                d="M477.6,315.19c-0.76,0.46-1.18,0.89-1.61,0.9c-0.26,0.01-0.76-0.63-0.76-0.97c0.01-0.33,0.55-0.92,0.82-0.9
	C476.48,314.26,476.88,314.71,477.6,315.19z"
              />
              <path
                d="M347.21,161.52c-0.51-0.56-1.07-0.92-1.03-1.18c0.07-0.42,0.53-0.78,0.83-1.17c0.34,0.27,0.95,0.54,0.96,0.82
	C347.99,160.43,347.57,160.86,347.21,161.52z"
              />
              <path
                d="M497.37,267.56c-0.5-0.57-1.05-0.94-1-1.2c0.08-0.42,0.56-0.76,0.87-1.14c0.33,0.28,0.94,0.57,0.94,0.85
	C498.17,266.5,497.74,266.92,497.37,267.56z"
              />
              <path
                d="M305.64,266.21c-0.52-0.67-1.08-1.08-1.04-1.43c0.04-0.37,0.6-0.81,1.02-0.96c0.17-0.06,0.83,0.53,0.84,0.83
	C306.45,265.07,306.04,265.49,305.64,266.21z"
              />
              <path
                d="M243.41,198.08c-0.69,0.46-1.13,0.98-1.49,0.93c-0.35-0.05-0.77-0.66-0.85-1.08c-0.04-0.22,0.58-0.84,0.85-0.81
	C242.35,197.17,242.73,197.61,243.41,198.08z"
              />
              <path
                d="M503.47,283.85c-0.42,0.46-0.77,1.08-0.99,1.03c-0.44-0.08-0.8-0.54-1.2-0.84c0.34-0.36,0.63-0.86,1.04-1.01
	C502.55,282.96,502.99,283.49,503.47,283.85z"
              />
              <path
                d="M296.68,395.77c-0.69,0.47-1.13,0.99-1.48,0.94c-0.35-0.05-0.77-0.65-0.85-1.07c-0.05-0.22,0.58-0.84,0.85-0.81
	C295.61,394.86,295.99,395.31,296.68,395.77z"
              />
              <path
                d="M307.1,557.68c-0.64,0.36-1.01,0.56-1.38,0.77c-0.21-0.44-0.52-0.87-0.59-1.33c-0.03-0.16,0.65-0.65,0.79-0.57
	C306.32,556.76,306.61,557.18,307.1,557.68z"
              />
              <path d="M422.22,634.1c-1.12,0-1.84,0-2.62,0C420.36,632.27,420.63,632.25,422.22,634.1z" />
              <path
                d="M336.91,252.78c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.84c0.42,0.15,0.99,0.58,1.03,0.95
	C337.97,251.68,337.42,252.1,336.91,252.78z"
              />
              <path
                d="M490.4,315.2c-0.38-0.81-0.77-1.26-0.74-1.68c0.02-0.28,0.65-0.76,0.95-0.72c0.34,0.04,0.89,0.6,0.86,0.87
	C491.42,314.1,490.95,314.47,490.4,315.2z"
              />
              <path
                d="M419.19,162.76c-0.47-0.97-0.87-1.42-0.83-1.84c0.02-0.29,0.64-0.53,0.99-0.79c0.28,0.4,0.74,0.78,0.79,1.21
	C420.17,161.67,419.69,162.05,419.19,162.76z"
              />
              <path d="M424.66,5.71c-0.89,1.08-0.89,1.08-1.87-0.12C423.75,5.23,423.75,5.23,424.66,5.71z" />
              <path
                d="M240.67,428.01c0.37,0.64,0.78,1.05,0.78,1.46c0,0.28-0.57,0.56-0.89,0.84c-0.3-0.37-0.76-0.7-0.84-1.11
	C239.67,428.95,240.2,428.58,240.67,428.01z"
              />
              <path
                d="M447.85,263.48c-0.8,0.4-1.25,0.79-1.67,0.77c-0.26-0.01-0.71-0.67-0.68-1.01c0.03-0.32,0.61-0.86,0.87-0.82
	C446.8,262.47,447.15,262.94,447.85,263.48z"
              />
              <path d="M284.96,615.16c0.67-1.2,0.67-1.2,2-1.61C286.91,614.7,286.22,615,284.96,615.16z" />
              <path
                d="M280.57,284.97c0.72-0.49,1.12-0.94,1.54-0.97c0.27-0.02,0.82,0.56,0.82,0.89c0.01,0.34-0.49,0.98-0.75,0.98
	C281.75,285.85,281.33,285.42,280.57,284.97z"
              />
              <path
                d="M337.68,244.13c-0.7,0.48-1.14,1.01-1.48,0.95c-0.37-0.06-0.79-0.64-0.89-1.06c-0.05-0.21,0.57-0.83,0.87-0.82
	C336.59,243.22,336.98,243.66,337.68,244.13z"
              />
              <path
                d="M497.65,314.68c-0.41-0.72-0.82-1.14-0.82-1.55c0-0.3,0.66-0.9,0.83-0.84c0.42,0.15,0.98,0.59,1.02,0.95
	C498.72,313.59,498.16,314,497.65,314.68z"
              />
              <path
                d="M494.29,315.63c0.52,0.68,1.07,1.09,1.03,1.44c-0.04,0.37-0.6,0.8-1.02,0.96c-0.17,0.06-0.83-0.54-0.83-0.84
	C493.47,316.77,493.88,316.35,494.29,315.63z"
              />
              <path
                d="M374.39,128.87c-0.42,0.46-0.77,1.08-0.99,1.03c-0.44-0.08-0.8-0.54-1.2-0.84c0.34-0.36,0.63-0.86,1.04-1.01
	C373.47,127.97,373.92,128.51,374.39,128.87z"
              />
              <path
                d="M236.43,633.61c-0.49-0.77-1.02-1.24-0.95-1.58c0.08-0.37,0.67-0.78,1.1-0.87c0.22-0.05,0.83,0.58,0.81,0.88
	C237.38,632.45,236.94,632.85,236.43,633.61z"
              />
              <path
                d="M295.74,175.38c-0.7,0.54-1.06,1.01-1.48,1.07c-0.26,0.04-0.84-0.51-0.87-0.84c-0.03-0.33,0.42-0.99,0.69-1
	C294.5,174.59,294.94,174.99,295.74,175.38z"
              />
              <path d="M677.55,566.37c-1.18-0.69-1.35-1.43-0.65-2.58C677.88,564.53,677.92,565.31,677.55,566.37z" />
              <path
                d="M412.25,160.27c0.46,0.57,0.94,0.92,1.01,1.35c0.04,0.27-0.63,0.96-0.8,0.91c-0.43-0.14-0.91-0.54-1.05-0.95
	C411.32,161.34,411.84,160.89,412.25,160.27z"
              />
              <path
                d="M261.15,279.78c-0.49,0.36-0.95,0.89-1.16,0.8c-0.41-0.16-0.69-0.67-1.02-1.03c0.4-0.29,0.77-0.74,1.21-0.81
	C260.4,278.7,260.74,279.32,261.15,279.78z"
              />
              <path
                d="M486.85,355.94c0.49,0.68,0.96,1.05,1,1.46c0.03,0.31-0.57,0.98-0.76,0.94c-0.43-0.11-1.02-0.49-1.1-0.87
	C485.91,357.13,486.42,356.66,486.85,355.94z"
              />
              <path
                d="M483.05,379.29c-0.64,0.55-1.02,1.14-1.35,1.11c-0.38-0.03-0.86-0.55-1.01-0.97c-0.07-0.2,0.48-0.89,0.79-0.91
	C481.87,378.49,482.31,378.9,483.05,379.29z"
              />
              <path
                d="M502.51,269.44c-0.63,0.52-0.96,1-1.37,1.08c-0.27,0.05-0.95-0.5-0.92-0.72c0.05-0.43,0.4-1.08,0.74-1.17
	C501.29,268.54,501.78,269.03,502.51,269.44z"
              />
              <path
                d="M474.52,252.4c0.41,0.61,0.92,1.07,0.84,1.31c-0.14,0.41-0.63,0.81-1.06,0.94c-0.17,0.05-0.84-0.64-0.8-0.91
	C473.57,253.32,474.06,252.97,474.52,252.4z"
              />
              <path
                d="M361.56,160.17c-0.51-0.67-0.98-1.03-1.03-1.44c-0.03-0.3,0.55-0.99,0.74-0.95c0.43,0.1,1.03,0.47,1.12,0.84
	C362.47,158.96,361.98,159.44,361.56,160.17z"
              />
              <path
                d="M354.93,318.35c-0.64,0.55-1.02,1.14-1.35,1.11c-0.38-0.03-0.86-0.55-1.01-0.97c-0.07-0.2,0.48-0.89,0.79-0.91
	C353.76,317.56,354.19,317.96,354.93,318.35z"
              />
              <path
                d="M400.46,279.65c-0.42-0.73-0.92-1.2-0.84-1.54c0.09-0.37,0.68-0.75,1.11-0.85c0.19-0.04,0.78,0.64,0.75,0.94
	C401.43,278.61,400.96,278.97,400.46,279.65z"
              />
              <path
                d="M365.45,123.9c-0.47-0.97-0.87-1.42-0.83-1.84c0.02-0.29,0.64-0.53,0.99-0.79c0.28,0.4,0.74,0.78,0.79,1.21
	C366.43,122.8,365.95,123.19,365.45,123.9z"
              />
              <path
                d="M472.71,320.43c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.84c0.42,0.15,0.99,0.58,1.03,0.95
	C473.77,319.34,473.22,319.76,472.71,320.43z"
              />
              <path
                d="M451.29,306.46c-0.58,0.45-0.93,0.94-1.34,0.98c-0.28,0.03-0.96-0.64-0.9-0.8c0.15-0.43,0.54-0.89,0.96-1.05
	C450.22,305.51,450.68,306.03,451.29,306.46z"
              />
              <path
                d="M274.78,492.64c-0.43-0.47-0.91-0.76-0.93-1.07c-0.02-0.33,0.41-0.69,0.64-1.03c0.36,0.3,0.86,0.54,1.04,0.92
	C275.62,491.65,275.13,492.11,274.78,492.64z"
              />
              <path
                d="M304.49,83c0.37,0.66,0.79,1.08,0.78,1.5c-0.01,0.29-0.61,0.56-0.95,0.84c-0.3-0.38-0.77-0.73-0.85-1.16
	C303.42,83.94,303.98,83.57,304.49,83z"
              />
              <path
                d="M323.74,307.86c-0.41-0.61-0.86-1-0.89-1.43c-0.02-0.28,0.57-0.6,0.89-0.9c0.33,0.35,0.83,0.67,0.94,1.08
	C324.73,306.87,324.2,307.28,323.74,307.86z"
              />
              <path
                d="M503.2,276.92c-0.74,0.39-1.17,0.8-1.58,0.78c-0.3-0.02-0.86-0.71-0.78-0.91c0.15-0.41,0.63-0.93,1.01-0.97
	C502.18,275.8,502.56,276.38,503.2,276.92z"
              />
              <path
                d="M380.5,293.08c0.4,0.73,0.81,1.15,0.8,1.57c-0.01,0.3-0.67,0.89-0.84,0.83c-0.42-0.16-0.97-0.6-1.01-0.97
	C379.42,294.15,379.98,293.75,380.5,293.08z"
              />
              <path
                d="M465.51,320.91c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.85c0.42,0.15,0.99,0.58,1.03,0.95
	C466.57,319.82,466.02,320.24,465.51,320.91z"
              />
              <path
                d="M448.31,310.36c-0.69,0.46-1.13,0.99-1.49,0.93c-0.35-0.05-0.77-0.65-0.86-1.07c-0.05-0.23,0.58-0.84,0.85-0.82
	C447.24,309.45,447.62,309.89,448.31,310.36z"
              />
              <path
                d="M284.06,471.1c0.4,0.73,0.81,1.15,0.8,1.57c-0.01,0.3-0.67,0.89-0.84,0.83c-0.42-0.16-0.97-0.6-1.01-0.97
	C282.97,472.17,283.53,471.77,284.06,471.1z"
              />
              <path
                d="M345.12,163.86c-0.62,0.53-0.95,1.01-1.37,1.09c-0.27,0.05-0.95-0.49-0.93-0.72c0.05-0.43,0.39-1.08,0.74-1.17
	C343.9,162.97,344.39,163.46,345.12,163.86z"
              />
              <path
                d="M250.94,450.13c-0.72,0.51-1.14,1.04-1.49,1c-0.37-0.05-0.79-0.59-0.95-1.01c-0.06-0.16,0.54-0.82,0.84-0.82
	C249.75,449.3,250.17,449.7,250.94,450.13z"
              />
              <path
                d="M336.42,163.04c0.52,0.68,1.07,1.09,1.03,1.44c-0.04,0.37-0.6,0.8-1.02,0.96c-0.17,0.06-0.83-0.54-0.83-0.84
	C335.6,164.18,336.01,163.76,336.42,163.04z"
              />
              <path
                d="M491.09,319.47c0.43,0.73,0.94,1.2,0.86,1.53c-0.09,0.37-0.67,0.76-1.11,0.86c-0.18,0.04-0.78-0.63-0.75-0.94
	C490.13,320.52,490.59,320.15,491.09,319.47z"
              />
              <path
                d="M315.07,164.96c0.49,0.68,0.96,1.05,1,1.46c0.03,0.31-0.57,0.98-0.76,0.94c-0.43-0.11-1.02-0.49-1.1-0.87
	C314.13,166.16,314.63,165.69,315.07,164.96z"
              />
              <path
                d="M272.03,374.76c0.41,0.61,0.92,1.07,0.84,1.31c-0.14,0.41-0.63,0.81-1.06,0.94c-0.17,0.05-0.84-0.64-0.8-0.91
	C271.08,375.68,271.57,375.32,272.03,374.76z"
              />
              <path
                d="M337.28,260.01c-0.35-0.82-0.72-1.28-0.68-1.7c0.03-0.28,0.67-0.74,0.98-0.69c0.34,0.05,0.88,0.63,0.83,0.9
	C338.34,258.95,337.85,259.3,337.28,260.01z"
              />
              <path
                d="M322.5,87.71c-0.41-0.72-0.82-1.14-0.82-1.55c0-0.3,0.66-0.9,0.83-0.84c0.42,0.15,0.99,0.59,1.02,0.95
	C323.57,86.63,323.01,87.04,322.5,87.71z"
              />
              <path
                d="M485.28,361.26c-0.76,0.46-1.18,0.89-1.61,0.9c-0.26,0.01-0.76-0.63-0.76-0.97c0.01-0.33,0.55-0.92,0.82-0.9
	C484.16,360.32,484.55,360.77,485.28,361.26z"
              />
              <path
                d="M313.56,230.22c0.43,0.73,0.93,1.2,0.85,1.54c-0.09,0.37-0.68,0.75-1.11,0.85c-0.19,0.04-0.78-0.64-0.75-0.94
	C312.59,231.26,313.06,230.9,313.56,230.22z"
              />
              <path
                d="M475.42,309.17c-0.42-0.49-1-0.93-0.92-1.12c0.16-0.41,0.66-0.69,1.02-1.03c0.27,0.33,0.75,0.66,0.76,0.99
	C476.28,308.35,475.81,308.68,475.42,309.17z"
              />
              <path
                d="M279.86,288.62c-0.45,0.48-0.84,1.1-1.02,1.05c-0.44-0.14-0.78-0.59-1.16-0.91c0.31-0.31,0.59-0.8,0.94-0.86
	C278.94,287.84,279.33,288.29,279.86,288.62z"
              />
              <path
                d="M499.81,296.33c-0.51-0.54-1.06-0.88-1.02-1.14c0.06-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C500.57,295.25,500.16,295.67,499.81,296.33z"
              />
              <path
                d="M506.2,288.55c-0.53-0.72-1.08-1.14-1.03-1.47c0.05-0.37,0.61-0.8,1.03-0.93c0.2-0.06,0.84,0.52,0.85,0.82
	C507.05,287.38,506.64,287.79,506.2,288.55z"
              />
              <path
                d="M456.05,316.99c-0.8,0.4-1.3,0.86-1.65,0.76c-0.35-0.1-0.7-0.72-0.76-1.15c-0.03-0.19,0.66-0.73,0.96-0.69
	C455,315.97,455.35,316.44,456.05,316.99z"
              />
              <path
                d="M265,282.98c-0.48,0.42-0.92,1-1.11,0.93c-0.42-0.16-0.71-0.65-1.06-1c0.33-0.28,0.66-0.76,1.01-0.78
	C264.16,282.11,264.5,282.59,265,282.98z"
              />
              <path
                d="M478.6,369.29c-0.8,0.4-1.3,0.86-1.65,0.76c-0.35-0.1-0.7-0.72-0.76-1.15c-0.03-0.19,0.66-0.73,0.96-0.69
	C477.56,368.27,477.9,368.74,478.6,369.29z"
              />
              <path
                d="M496.32,297.64c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.07,1.02c-0.26-0.35-0.71-0.7-0.72-1.05
	C495.43,298.44,495.91,298.11,496.32,297.64z"
              />
              <path
                d="M483.4,339.93c-0.78,0.44-1.19,0.85-1.61,0.86c-0.29,0-0.89-0.64-0.83-0.83c0.13-0.41,0.56-0.97,0.92-1.01
	C482.25,338.91,482.67,339.42,483.4,339.93z"
              />
              <path
                d="M449.44,297.92c0.5,0.72,0.96,1.09,0.93,1.41c-0.03,0.34-0.53,0.64-0.83,0.96c-0.34-0.36-0.85-0.68-0.95-1.1
	C448.53,298.9,449.05,298.48,449.44,297.92z"
              />
              <path
                d="M343.17,155.51c0.45,0.57,0.92,0.92,0.98,1.33c0.04,0.27-0.62,0.93-0.8,0.88c-0.41-0.13-0.88-0.53-1.02-0.93
	C342.25,156.55,342.75,156.11,343.17,155.51z"
              />
              <path
                d="M507.15,279.77c-0.74,0.54-1.15,1.07-1.49,1.02c-0.38-0.05-0.82-0.57-0.97-0.98c-0.07-0.18,0.52-0.84,0.82-0.85
	C505.91,278.94,506.34,279.34,507.15,279.77z"
              />
              <path
                d="M273.71,462.71c-0.76,0.47-1.16,0.89-1.58,0.91c-0.3,0.01-0.92-0.61-0.86-0.8c0.12-0.42,0.53-0.98,0.89-1.04
	C272.52,461.73,272.96,462.23,273.71,462.71z"
              />
              <path
                d="M370.54,125.66c-0.46,0.39-0.87,0.95-1.09,0.88c-0.42-0.13-0.73-0.62-1.09-0.95c0.38-0.31,0.72-0.76,1.15-0.87
	C369.74,124.67,370.11,125.25,370.54,125.66z"
              />
              <path
                d="M333.87,303.25c-0.23-0.36-0.66-0.75-0.63-1.09c0.03-0.32,0.55-0.6,0.86-0.89c0.32,0.37,0.83,0.7,0.9,1.11
	c0.07,0.38-0.32,0.85-0.5,1.28C334.29,303.52,334.08,303.39,333.87,303.25z"
              />
              <path d="M375.06,634.11c-0.79,0-1.54,0-2.41,0C373.9,632.64,374.19,632.65,375.06,634.11z" />
              <path
                d="M315.57,214.74c-0.4-0.6-0.84-0.99-0.87-1.41c-0.02-0.26,0.69-0.87,0.86-0.8c0.4,0.16,0.83,0.6,0.93,1
	C316.55,213.79,316.03,214.18,315.57,214.74z"
              />
              <path
                d="M500.42,303.42c-0.43-0.47-1.02-0.88-0.96-1.09c0.13-0.41,0.62-0.72,0.96-1.06c0.29,0.32,0.79,0.62,0.81,0.95
	C501.25,302.55,500.79,302.91,500.42,303.42z"
              />
              <path
                d="M275.87,467.52c-0.39-0.79-0.78-1.22-0.76-1.63c0.02-0.29,0.69-0.83,0.9-0.76c0.41,0.14,0.94,0.62,0.97,0.98
	C277.01,466.46,276.44,466.85,275.87,467.52z"
              />
              <path
                d="M454.53,302.63c-0.48,0.43-0.91,1.02-1.09,0.95c-0.42-0.16-0.73-0.64-1.08-0.99c0.33-0.28,0.65-0.76,1-0.79
	C453.68,301.78,454.03,302.26,454.53,302.63z"
              />
              <path
                d="M390.71,149.02c-0.46,0.47-0.87,1.09-1.04,1.03c-0.44-0.15-0.76-0.61-1.14-0.95c0.32-0.3,0.61-0.79,0.96-0.84
	C389.81,148.22,390.19,148.67,390.71,149.02z"
              />
              <path
                d="M493.3,301.48c0.33,0.53,0.77,0.92,0.73,1.24c-0.05,0.34-0.56,0.61-0.86,0.91c-0.32-0.37-0.78-0.71-0.9-1.13
	C492.22,302.3,492.84,301.92,493.3,301.48z"
              />
              <path
                d="M477.99,337.97c-0.45-0.76-0.86-1.16-0.87-1.57c0-0.3,0.63-0.89,0.83-0.83c0.42,0.12,0.98,0.54,1.04,0.91
	C479.06,336.82,478.51,337.25,477.99,337.97z"
              />
              <path
                d="M444.69,303.19c0.72-0.47,1.12-0.9,1.53-0.92c0.27-0.02,0.8,0.56,0.8,0.87c0,0.34-0.49,0.96-0.74,0.95
	C445.85,304.06,445.44,303.64,444.69,303.19z"
              />
              <path
                d="M497.77,274.62c-0.4-0.47-0.89-0.79-0.9-1.11c-0.01-0.34,0.45-0.69,0.71-1.04c0.38,0.32,0.88,0.58,1.07,0.99
	C498.74,273.64,498.17,274.12,497.77,274.62z"
              />
              <path
                d="M498.2,306.01c-0.48,0.42-0.92,1-1.11,0.93c-0.42-0.16-0.71-0.65-1.06-1c0.33-0.28,0.66-0.76,1.01-0.78
	C497.36,305.15,497.71,305.63,498.2,306.01z"
              />
              <path
                d="M283.87,305.31c0.42,0.46,0.92,0.76,0.93,1.07c0.01,0.35-0.43,0.72-0.67,1.08c-0.39-0.31-0.89-0.56-1.11-0.96
	C282.94,306.34,283.48,305.84,283.87,305.31z"
              />
              <path
                d="M275.25,473.12c0.8-0.41,1.3-0.88,1.64-0.78c0.36,0.1,0.7,0.71,0.78,1.15c0.03,0.18-0.65,0.73-0.96,0.69
	C276.31,474.12,275.95,473.66,275.25,473.12z"
              />
              <path
                d="M489.76,307.73c-0.4-0.51-0.97-0.99-0.88-1.17c0.19-0.41,0.7-0.67,1.08-0.99c0.25,0.35,0.71,0.7,0.71,1.04
	C490.66,306.95,490.17,307.27,489.76,307.73z"
              />
              <path
                d="M444.32,307.36c-0.71,0.24-1.19,0.56-1.59,0.48c-0.26-0.05-0.67-0.88-0.56-1.02c0.25-0.34,0.78-0.65,1.19-0.64
	C443.62,306.18,443.87,306.78,444.32,307.36z"
              />
              <path
                d="M495.14,270.06c-0.51,0.4-0.95,0.95-1.17,0.87c-0.4-0.14-0.66-0.66-0.99-1.02c0.33-0.27,0.67-0.75,0.99-0.74
	C494.31,269.19,494.64,269.67,495.14,270.06z"
              />
              <path
                d="M428.75,267.3c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C427.72,268.22,428.26,267.85,428.75,267.3z"
              />
              <path d="M666.28,531.4c0.29-0.88-0.9-2.02,0.64-2.8c0.27,1.11,0.83,2.14-0.71,2.73C666.21,531.32,666.28,531.4,666.28,531.4z" />
              <path
                d="M469.41,308.52c-0.52,0.37-0.99,0.9-1.18,0.82c-0.4-0.19-0.65-0.7-0.97-1.07c0.36-0.25,0.72-0.71,1.07-0.7
	C468.64,307.57,468.94,308.08,469.41,308.52z"
              />
              <path
                d="M245.88,266.48c-0.77,0.46-1.17,0.88-1.59,0.89c-0.3,0.01-0.9-0.62-0.85-0.81c0.12-0.42,0.54-0.98,0.91-1.03
	C244.7,265.48,245.14,265.99,245.88,266.48z"
              />
              <path
                d="M233.36,262.61c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.06,1.02c-0.26-0.35-0.72-0.7-0.72-1.05
	C232.48,263.41,232.97,263.08,233.36,262.61z"
              />
              <path
                d="M454.8,263.11c-0.59,0.3-1.17,0.77-1.33,0.65c-0.36-0.26-0.52-0.81-0.75-1.24c0.38-0.18,0.81-0.56,1.14-0.49
	C454.18,262.1,454.4,262.63,454.8,263.11z"
              />
              <path
                d="M494.82,309.85c-0.44,0.42-0.82,1-1.04,0.95c-0.43-0.11-0.76-0.58-1.13-0.9c0.36-0.33,0.68-0.8,1.1-0.92
	C493.98,308.92,494.37,309.47,494.82,309.85z"
              />
              <path
                d="M324.76,193c0.47,0.74,0.89,1.14,0.91,1.54c0.01,0.29-0.6,0.9-0.81,0.85c-0.42-0.11-1-0.52-1.06-0.88
	C323.74,194.17,324.26,193.73,324.76,193z"
              />
              <path
                d="M326.28,400.78c-0.76,0.35-1.22,0.75-1.6,0.68c-0.41-0.08-0.74-0.58-1.11-0.9c0.3-0.31,0.58-0.83,0.9-0.86
	C324.82,399.66,325.21,400.1,326.28,400.78z"
              />
              <path
                d="M308.22,86.51c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.07,1.02c-0.26-0.35-0.72-0.7-0.72-1.05
	C307.33,87.3,307.82,86.98,308.22,86.51z"
              />
              <path
                d="M266.32,310.08c0.42,0.77,0.82,1.19,0.81,1.61c-0.01,0.29-0.66,0.85-0.88,0.78c-0.41-0.13-0.96-0.58-1-0.95
	C265.22,311.18,265.77,310.78,266.32,310.08z"
              />
              <path
                d="M484.11,347.2c-0.81,0.41-1.24,0.8-1.65,0.77c-0.31-0.02-0.87-0.69-0.8-0.87c0.16-0.41,0.62-0.92,1-0.96
	C482.99,346.11,483.39,346.65,484.11,347.2z"
              />
              <path
                d="M471.47,382.53c0.43,0.79,0.92,1.29,0.82,1.61c-0.11,0.37-0.73,0.71-1.17,0.78c-0.2,0.03-0.75-0.64-0.71-0.94
	C470.46,383.57,470.93,383.23,471.47,382.53z"
              />
              <path
                d="M248.12,627.5c-0.58,0.47-0.92,0.94-1.34,1.01c-0.26,0.05-0.93-0.6-0.87-0.79c0.12-0.42,0.51-0.9,0.91-1.04
	C247.05,626.6,247.5,627.09,248.12,627.5z"
              />
              <path
                d="M307.21,311.68c-0.46,0.47-0.87,1.09-1.04,1.03c-0.44-0.15-0.76-0.61-1.14-0.95c0.32-0.3,0.61-0.79,0.96-0.84
	C306.32,310.88,306.7,311.34,307.21,311.68z"
              />
              <path
                d="M472.46,313.12c-0.51-0.54-1.06-0.88-1.02-1.14c0.06-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C473.22,312.04,472.81,312.47,472.46,313.12z"
              />
              <path
                d="M465.02,313.73c-0.4-0.78-0.79-1.21-0.77-1.62c0.01-0.27,0.61-0.76,0.91-0.73c0.33,0.03,0.9,0.56,0.87,0.82
	C465.98,312.62,465.53,313,465.02,313.73z"
              />
              <path
                d="M478.2,255.89c0.42,0.46,0.92,0.76,0.93,1.07c0.01,0.35-0.43,0.72-0.67,1.08c-0.39-0.31-0.89-0.56-1.11-0.96
	C477.27,256.92,477.82,256.42,478.2,255.89z"
              />
              <path
                d="M369.9,158.26c-0.51,0.4-0.95,0.95-1.17,0.87c-0.4-0.14-0.66-0.66-0.99-1.02c0.33-0.27,0.67-0.75,0.99-0.74
	C369.07,157.39,369.4,157.86,369.9,158.26z"
              />
              <path
                d="M311.49,85.1c-0.45-0.76-0.86-1.16-0.87-1.57c0-0.3,0.63-0.89,0.83-0.83c0.42,0.12,0.99,0.55,1.04,0.91
	C312.55,83.96,312.01,84.38,311.49,85.1z"
              />
              <path
                d="M321.71,157.52c0.39,0.53,0.93,1.03,0.84,1.19c-0.22,0.4-0.72,0.65-1.11,0.96c-0.24-0.36-0.68-0.73-0.67-1.08
	C320.79,158.28,321.29,157.98,321.71,157.52z"
              />
              <path
                d="M354.36,157.97c0.44,0.78,0.94,1.27,0.84,1.59c-0.11,0.37-0.71,0.72-1.15,0.8c-0.2,0.04-0.76-0.63-0.73-0.93
	C353.37,159.03,353.83,158.67,354.36,157.97z"
              />
              <path
                d="M236.04,404.77c-0.8,0.41-1.29,0.86-1.62,0.77c-0.37-0.11-0.73-0.69-0.81-1.11c-0.04-0.19,0.65-0.75,0.95-0.72
	C234.97,403.76,235.33,404.22,236.04,404.77z"
              />
              <path
                d="M311.71,251.22c-0.51-0.54-1.06-0.88-1.02-1.14c0.07-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C312.47,250.14,312.06,250.57,311.71,251.22z"
              />
              <path d="M397.22,4.24c0.85-0.08,1.42-0.13,2.09-0.19c-0.05,0.4,0,0.7-0.12,0.82C398.55,5.47,397.95,5.48,397.22,4.24z" />
              <path
                d="M393.94,145.66c-0.71,0.24-1.19,0.56-1.59,0.48c-0.26-0.05-0.67-0.88-0.56-1.02c0.25-0.34,0.78-0.66,1.19-0.64
	C393.24,144.48,393.49,145.08,393.94,145.66z"
              />
              <path
                d="M469.06,314.32c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C468.02,315.24,468.57,314.88,469.06,314.32z"
              />
              <path
                d="M341.31,160.66c-0.75,0.46-1.16,0.89-1.58,0.91c-0.26,0.01-0.75-0.6-0.75-0.94c0-0.31,0.52-0.9,0.79-0.88
	C340.19,159.77,340.58,160.2,341.31,160.66z"
              />
              <path
                d="M501.57,314.87c0.49,0.74,1.01,1.19,0.95,1.52c-0.07,0.37-0.65,0.77-1.08,0.87c-0.2,0.05-0.81-0.57-0.8-0.86
	C500.66,316,501.09,315.61,501.57,314.87z"
              />
              <path
                d="M364.71,153.69c0.33,0.53,0.77,0.92,0.73,1.24c-0.05,0.34-0.56,0.61-0.86,0.91c-0.32-0.37-0.78-0.71-0.9-1.13
	C363.62,154.51,364.24,154.13,364.71,153.69z"
              />
              <path
                d="M278.42,353.85c-0.44,0.48-0.81,1.11-1,1.06c-0.44-0.12-0.79-0.57-1.17-0.88c0.3-0.31,0.57-0.81,0.92-0.88
	C277.48,353.1,277.88,353.53,278.42,353.85z"
              />
              <path
                d="M285.94,353.31c-0.77,0.48-1.17,0.9-1.58,0.91c-0.31,0.01-0.94-0.62-0.89-0.79c0.13-0.42,0.53-0.97,0.91-1.05
	C284.72,352.31,285.17,352.82,285.94,353.31z"
              />
              <path
                d="M400.35,316.42c0.65,0.93,1.11,1.3,1.08,1.62c-0.03,0.34-0.53,0.64-0.83,0.96c-0.34-0.36-0.86-0.68-0.95-1.09
	C399.57,317.56,400,317.1,400.35,316.42z"
              />
              <path
                d="M395.06,318.17c-0.99,0.8-1.42,1.34-1.63,1.27c-0.42-0.14-0.71-0.63-1.06-0.98c0.39-0.3,0.74-0.77,1.17-0.85
	C393.91,317.53,394.38,317.9,395.06,318.17z"
              />
              <path
                d="M318.18,164.03c-0.4-0.78-0.79-1.21-0.77-1.62c0.01-0.27,0.61-0.76,0.91-0.73c0.33,0.03,0.9,0.56,0.87,0.82
	C319.15,162.91,318.7,163.29,318.18,164.03z"
              />
              <path
                d="M253.14,199.23c0.43,0.79,0.92,1.29,0.82,1.61c-0.11,0.37-0.73,0.71-1.17,0.78c-0.2,0.03-0.75-0.64-0.71-0.94
	C252.13,200.28,252.6,199.93,253.14,199.23z"
              />
              <path
                d="M250.6,323.81c-0.5,0.4-0.95,0.96-1.14,0.88c-0.41-0.17-0.69-0.67-1.02-1.03c0.34-0.27,0.68-0.74,1.03-0.75
	C249.79,322.91,250.12,323.4,250.6,323.81z"
              />
              <path
                d="M315.36,238.88c-0.45,0.4-0.85,0.97-1.07,0.91c-0.42-0.12-0.75-0.6-1.11-0.93c0.37-0.32,0.71-0.78,1.14-0.89
	C314.54,237.91,314.93,238.47,315.36,238.88z"
              />
              <path
                d="M390.85,162.21c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C389.81,163.14,390.35,162.77,390.85,162.21z"
              />
              <path
                d="M413.09,320.79c-0.8,0.42-1.23,0.82-1.64,0.8c-0.31-0.01-0.89-0.68-0.82-0.85c0.15-0.41,0.6-0.94,0.98-0.98
	C411.95,319.71,412.36,320.25,413.09,320.79z"
              />
              <path
                d="M410.34,154.36c0.51-0.37,0.87-0.83,1.21-0.82c0.32,0.01,0.64,0.51,0.95,0.79c-0.34,0.34-0.63,0.85-1.04,0.96
	C411.24,155.36,410.83,154.78,410.34,154.36z"
              />
              <path
                d="M326.96,201.73c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.55-0.88,0.81-0.85
	C325.82,200.81,326.21,201.24,326.96,201.73z"
              />
              <path
                d="M377.82,164.65c-0.72,0.5-1.09,0.94-1.5,0.98c-0.29,0.03-0.94-0.55-0.9-0.73c0.1-0.42,0.47-1.01,0.82-1.09
	C376.58,163.73,377.05,164.21,377.82,164.65z"
              />
              <path
                d="M235.21,413.04c-0.35-0.57-0.77-0.95-0.72-1.26c0.05-0.32,0.56-0.57,0.87-0.84c0.28,0.32,0.77,0.63,0.77,0.96
	C236.13,412.2,235.64,412.52,235.21,413.04z"
              />
              <path
                d="M399.91,146.02c-0.5-0.71-0.95-1.07-0.98-1.47c-0.02-0.3,0.55-0.95,0.74-0.91c0.43,0.09,1.02,0.46,1.11,0.82
	C400.86,144.79,400.36,145.26,399.91,146.02z"
              />
              <path
                d="M478.07,322.05c-0.45,0.43-0.82,1.01-1.05,0.96c-0.43-0.09-0.78-0.55-1.17-0.86c0.35-0.34,0.66-0.82,1.08-0.95
	C477.17,321.13,477.58,321.66,478.07,322.05z"
              />
              <path
                d="M483.93,322.96c-0.51-0.57-1.06-0.95-1.01-1.19c0.1-0.42,0.51-0.88,0.91-1.05c0.18-0.08,0.92,0.49,0.91,0.77
	C484.74,321.91,484.31,322.32,483.93,322.96z"
              />
              <path
                d="M405.87,321.32c-0.81,0.39-1.24,0.76-1.64,0.73c-0.3-0.03-0.85-0.7-0.78-0.88c0.17-0.4,0.63-0.9,1-0.93
	C404.78,320.21,405.17,320.76,405.87,321.32z"
              />
              <path
                d="M383.55,165.58c-0.47-0.6-0.99-1.02-0.92-1.25c0.12-0.41,0.56-0.84,0.97-0.99c0.18-0.07,0.88,0.55,0.86,0.82
	C384.43,164.57,383.98,164.96,383.55,165.58z"
              />
              <path
                d="M376.85,133.41c-0.35-0.66-0.76-1.1-0.74-1.51c0.02-0.28,0.78-0.81,0.97-0.72c0.39,0.19,0.78,0.68,0.85,1.11
	C377.98,132.53,377.39,132.88,376.85,133.41z"
              />
              <path
                d="M332.26,152.96c0.38,0.53,0.9,1,0.81,1.18c-0.19,0.39-0.69,0.64-1.06,0.94c-0.24-0.35-0.68-0.7-0.66-1.04
	C331.36,153.72,331.84,153.42,332.26,152.96z"
              />
              <path
                d="M285,321.84c-0.31-0.53-0.71-0.91-0.66-1.23c0.05-0.32,0.54-0.58,0.84-0.86c0.31,0.36,0.75,0.69,0.86,1.11
	C286.08,321.05,285.46,321.41,285,321.84z"
              />
              <path
                d="M362.67,119.13c-0.49,0.39-0.82,0.86-1.14,0.86c-0.32,0-0.64-0.47-0.97-0.74c0.32-0.35,0.58-0.85,0.98-0.98
	C361.75,118.2,362.18,118.74,362.67,119.13z"
              />
              <path
                d="M430.62,165.22c-0.5-0.71-0.95-1.07-0.98-1.47c-0.02-0.3,0.55-0.95,0.74-0.91c0.43,0.09,1.02,0.46,1.11,0.82
	C431.57,163.98,431.07,164.46,430.62,165.22z"
              />
              <path
                d="M330.75,165.07c-0.43,0.46-0.76,1.06-1,1.02c-0.44-0.07-0.81-0.51-1.22-0.79c0.34-0.35,0.62-0.86,1.03-1
	C329.8,164.21,330.24,164.72,330.75,165.07z"
              />
              <path
                d="M460.1,320.1c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.99c-0.06-0.18,0.52-0.82,0.83-0.83
	C458.9,319.26,459.31,319.67,460.1,320.1z"
              />
              <path
                d="M474.57,378.72c0.41,0.76,0.8,1.18,0.79,1.59c-0.01,0.28-0.66,0.84-0.86,0.77c-0.41-0.13-0.95-0.58-0.99-0.94
	C473.48,379.8,474.03,379.4,474.57,378.72z"
              />
              <path
                d="M301.31,176.24c-0.39-0.78-0.77-1.2-0.75-1.61c0.02-0.29,0.68-0.82,0.88-0.75c0.41,0.14,0.93,0.6,0.96,0.97
	C302.43,175.19,301.87,175.57,301.31,176.24z"
              />
              <path
                d="M292.47,321.51c-0.5-0.56-1.05-0.94-0.99-1.17c0.1-0.41,0.5-0.87,0.9-1.04c0.18-0.08,0.9,0.49,0.9,0.76
	C293.27,320.46,292.85,320.87,292.47,321.51z"
              />
              <path
                d="M312.45,163.41c-0.51,0.33-0.88,0.77-1.19,0.72c-0.33-0.05-0.6-0.53-0.89-0.83c0.36-0.31,0.68-0.76,1.09-0.88
	C311.65,162.37,312.02,162.96,312.45,163.41z"
              />
              <path
                d="M261.57,176c-0.35-0.56-0.78-0.95-0.73-1.26c0.05-0.32,0.57-0.57,0.88-0.85c0.28,0.32,0.78,0.64,0.79,0.96
	C262.51,175.17,262.01,175.49,261.57,176z"
              />
              <path
                d="M462.54,325c-0.53-0.71-1.07-1.12-1.02-1.45c0.05-0.36,0.6-0.79,1.01-0.92c0.2-0.06,0.83,0.51,0.83,0.8
	C463.37,323.84,462.97,324.24,462.54,325z"
              />
              <path
                d="M252.38,321.13c-0.32-0.52-0.74-0.9-0.7-1.23c0.04-0.32,0.55-0.57,0.86-0.86c0.31,0.36,0.77,0.69,0.87,1.1
	C253.45,320.35,252.84,320.71,252.38,321.13z"
              />
              <path
                d="M397.83,164.12c-0.4-0.63-0.86-1.08-0.78-1.31c0.15-0.39,0.65-0.64,1-0.95c0.28,0.32,0.81,0.68,0.77,0.94
	C398.77,163.2,398.3,163.54,397.83,164.12z"
              />
              <path d="M580.45,117.39c0.49,0.88,0.89,1.58,1.37,2.43C580.13,119.09,580.13,119.09,580.45,117.39z" />
              <path
                d="M406.62,162.17c-0.74,0.54-1.1,0.99-1.51,1.04c-0.27,0.03-0.84-0.48-0.86-0.79c-0.03-0.33,0.4-0.96,0.67-0.98
	C405.33,161.4,405.78,161.78,406.62,162.17z"
              />
              <path
                d="M481.47,318.17c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C480.22,317.36,480.65,317.75,481.47,318.17z"
              />
              <path
                d="M312.31,408.87c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.98c-0.06-0.18,0.53-0.82,0.83-0.83
	C311.11,408.03,311.52,408.44,312.31,408.87z"
              />
              <path
                d="M487.2,316.47c0.42,0.61,0.85,0.98,0.88,1.39c0.02,0.26-0.66,0.87-0.83,0.8c-0.39-0.14-0.83-0.57-0.94-0.96
	C486.24,317.46,486.75,317.06,487.2,316.47z"
              />
              <path
                d="M326.96,161.95c-0.82,0.41-1.25,0.8-1.67,0.78c-0.26-0.01-0.72-0.63-0.7-0.96c0.02-0.31,0.57-0.85,0.83-0.83
	C325.84,160.99,326.21,161.43,326.96,161.95z"
              />
              <path
                d="M481.91,325.49c-0.48,0.4-0.88,0.94-1.11,0.88c-0.42-0.11-0.75-0.59-1.11-0.92c0.37-0.32,0.71-0.79,1.14-0.89
	C481.06,324.5,481.44,325.07,481.91,325.49z"
              />
              <path
                d="M326.21,154.79c-0.47,0.44-0.84,1.01-1.07,0.95c-0.4-0.09-0.72-0.57-1.07-0.89c0.3-0.29,0.59-0.79,0.91-0.81
	C325.31,154.01,325.68,154.44,326.21,154.79z"
              />
              <path
                d="M408.92,317.44c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C408.1,316.38,408.48,316.98,408.92,317.44z"
              />
              <path
                d="M362.68,131.85c0.68,1.03,1.18,1.51,1.08,1.7c-0.19,0.38-0.7,0.6-1.08,0.89c-0.23-0.35-0.62-0.7-0.63-1.06
	C362.04,132.98,362.37,132.57,362.68,131.85z"
              />
              <path
                d="M372.6,162.66c-0.49-0.56-1.02-0.93-0.97-1.16c0.09-0.41,0.49-0.86,0.88-1.03c0.17-0.08,0.89,0.48,0.89,0.75
	C373.39,161.63,372.98,162.03,372.6,162.66z"
              />
              <path
                d="M332.74,160.14c0.38,0.53,0.91,1.01,0.82,1.2c-0.19,0.4-0.7,0.65-1.08,0.96c-0.24-0.35-0.69-0.71-0.67-1.05
	C331.83,160.92,332.32,160.61,332.74,160.14z"
              />
              <path
                d="M232.44,627.58c0.38,0.55,0.83,0.93,0.79,1.23c-0.05,0.31-0.58,0.76-0.9,0.76c-0.27,0-0.75-0.55-0.75-0.87
	C231.57,628.39,232.04,628.07,232.44,627.58z"
              />
              <path
                d="M380.14,153.76c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C379.32,152.83,379.64,153.33,380.14,153.76z"
              />
              <path
                d="M416.55,316.56c-0.89,0.7-1.29,1.22-1.51,1.15c-0.41-0.13-0.71-0.6-1.05-0.93c0.37-0.3,0.71-0.77,1.13-0.85
	C415.46,315.87,415.9,316.26,416.55,316.56z"
              />
              <path
                d="M326.41,407.65c-0.53,0.34-0.91,0.78-1.23,0.74c-0.34-0.05-0.62-0.55-0.92-0.85c0.37-0.32,0.7-0.77,1.12-0.9
	C325.57,406.58,325.96,407.19,326.41,407.65z"
              />
              <path
                d="M461.77,317.67c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C462.63,316.68,462.19,317.06,461.77,317.67z"
              />
              <path
                d="M381.18,160.73c-0.74,0.54-1.1,0.99-1.51,1.04c-0.27,0.03-0.84-0.48-0.86-0.79c-0.03-0.33,0.4-0.96,0.67-0.98
	C379.9,159.96,380.35,160.34,381.18,160.73z"
              />
              <path
                d="M427.69,160.38c-0.47,0.42-0.85,0.98-1.08,0.92c-0.43-0.1-0.76-0.57-1.14-0.88c0.36-0.33,0.68-0.81,1.11-0.92
	C426.81,159.43,427.21,159.98,427.69,160.38z"
              />
              <path
                d="M237.06,419.1c-0.47,0.33-0.85,0.75-1.01,0.68c-0.34-0.15-0.65-0.52-0.79-0.87c-0.05-0.12,0.51-0.68,0.71-0.64
	C236.32,418.33,236.6,418.72,237.06,419.1z"
              />
              <path
                d="M388.11,160.05c-0.46,0.44-0.83,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C387.2,159.29,387.58,159.71,388.11,160.05z"
              />
              <path
                d="M374.38,144.31c-0.37-0.5-0.81-0.83-0.79-1.15c0.02-0.33,0.47-0.64,0.74-0.96c0.34,0.33,0.81,0.62,0.96,1.02
	C375.35,143.42,374.79,143.84,374.38,144.31z"
              />
              <path
                d="M321.36,199.76c-0.48-0.74-0.99-1.2-0.91-1.52c0.08-0.37,0.66-0.75,1.08-0.85c0.19-0.05,0.78,0.58,0.76,0.87
	C322.27,198.67,321.84,199.04,321.36,199.76z"
              />
              <path
                d="M307.99,168.44c-0.45-0.59-0.9-0.94-0.95-1.35c-0.03-0.27,0.62-0.91,0.78-0.85c0.4,0.14,0.85,0.53,0.99,0.92
	C308.89,167.39,308.41,167.82,307.99,168.44z"
              />
              <path
                d="M240.8,628.36c-0.58,0.35-0.93,0.7-1.3,0.73c-0.24,0.02-0.77-0.54-0.72-0.68c0.13-0.38,0.48-0.89,0.79-0.93
	C239.88,627.45,240.25,627.94,240.8,628.36z"
              />
              <path
                d="M389.33,316.69c-0.35-0.65-0.75-1.08-0.73-1.49c0.01-0.27,0.78-0.8,0.95-0.71c0.39,0.19,0.77,0.68,0.84,1.1
	C390.44,315.82,389.86,316.17,389.33,316.69z"
              />
              <path
                d="M353.13,143.84c0.35,0.56,0.77,0.95,0.73,1.26c-0.05,0.32-0.57,0.56-0.88,0.84c-0.28-0.32-0.78-0.64-0.78-0.96
	C352.2,144.66,352.69,144.34,353.13,143.84z"
              />
              <path
                d="M247.25,327.92c-0.48,0.38-0.89,0.9-1.12,0.83c-0.41-0.13-0.72-0.6-1.07-0.94c0.38-0.3,0.72-0.76,1.15-0.85
	C246.44,326.92,246.8,327.49,247.25,327.92z"
              />
              <path
                d="M349.64,313.98c0.4,0.45,0.89,0.75,0.9,1.06c0,0.34-0.43,0.69-0.68,1.04c-0.38-0.31-0.86-0.57-1.08-0.97
	C348.69,314.98,349.24,314.49,349.64,313.98z"
              />
              <path
                d="M484.8,314.53c-0.8,0.41-1.23,0.79-1.64,0.77c-0.26-0.01-0.7-0.63-0.68-0.95c0.02-0.31,0.56-0.84,0.82-0.82
	C483.7,313.59,484.07,314.02,484.8,314.53z"
              />
              <path
                d="M312.95,404.85c0.51-0.37,0.85-0.82,1.19-0.82c0.32,0,0.63,0.48,0.95,0.75c-0.33,0.34-0.61,0.84-1.01,0.95
	C313.85,405.81,313.44,405.26,312.95,404.85z"
              />
              <path
                d="M328.7,196.38c0.41,0.76,0.8,1.18,0.79,1.59c-0.01,0.28-0.66,0.84-0.86,0.77c-0.41-0.13-0.95-0.58-0.99-0.94
	C327.61,197.46,328.16,197.06,328.7,196.38z"
              />
              <path
                d="M225.3,249.02c0.32,0.54,0.66,0.89,0.67,1.25c0.01,0.2-0.5,0.44-0.77,0.66c-0.25-0.32-0.62-0.61-0.71-0.96
	C224.45,249.77,224.91,249.46,225.3,249.02z"
              />
              <path
                d="M481.6,354.12c0.79-0.41,1.27-0.87,1.62-0.78c0.35,0.09,0.69,0.7,0.77,1.13c0.03,0.18-0.64,0.73-0.94,0.69
	C482.65,355.11,482.3,354.65,481.6,354.12z"
              />
              <path
                d="M402.2,159.09c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C401.38,158.04,401.76,158.64,402.2,159.09z"
              />
              <path
                d="M484.44,327.37c0.51,0.7,0.96,1.06,1,1.46c0.03,0.3-0.53,0.95-0.72,0.91c-0.43-0.09-1.02-0.44-1.13-0.81
	C483.5,328.61,484,328.13,484.44,327.37z"
              />
              <path
                d="M457.89,314.32c-0.44-0.61-0.95-1.03-0.87-1.26c0.13-0.4,0.57-0.81,0.98-0.96c0.17-0.06,0.85,0.57,0.82,0.84
	C458.78,313.34,458.33,313.71,457.89,314.32z"
              />
              <path
                d="M228.79,252.29c0.38,0.49,0.84,0.82,0.83,1.13c-0.01,0.33-0.46,0.65-0.72,0.97c-0.35-0.32-0.83-0.59-0.99-0.99
	C227.83,253.23,228.38,252.79,228.79,252.29z"
              />
              <path
                d="M409.42,158.57c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.8,1.1-0.93
	C408.56,157.54,408.96,158.13,409.42,158.57z"
              />
              <path
                d="M268.89,172.91c0.4,0.5,0.95,0.98,0.87,1.13c-0.21,0.4-0.7,0.66-1.07,0.98c-0.25-0.35-0.69-0.69-0.69-1.04
	C268,173.67,268.48,173.37,268.89,172.91z"
              />
              <path
                d="M468.73,254.57c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C467.61,253.5,468.01,254.03,468.73,254.57z"
              />
              <path
                d="M480.03,383.43c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C478.78,382.62,479.21,383.01,480.03,383.43z"
              />
              <path
                d="M338.59,313.85c-0.41-0.64-0.85-1.03-0.86-1.45c-0.01-0.27,0.7-0.87,0.88-0.8c0.4,0.16,0.83,0.61,0.94,1.02
	C339.61,312.86,339.07,313.26,338.59,313.85z"
              />
              <path
                d="M354.71,152.24c-0.49,0.4-0.81,0.88-1.12,0.87c-0.34-0.01-0.67-0.47-1-0.74c0.33-0.35,0.61-0.83,1.01-0.99
	C353.78,151.3,354.22,151.84,354.71,152.24z"
              />
              <path
                d="M478.81,329.43c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.97
	C477.69,328.36,478.09,328.89,478.81,329.43z"
              />
              <path
                d="M305.48,422.39c0.17,0.42,0.54,0.87,0.47,1.25c-0.07,0.42-0.47,0.9-0.85,1.08c-0.19,0.09-0.94-0.44-0.95-0.71
	c-0.02-0.41,0.39-0.84,0.62-1.26C305,422.63,305.24,422.51,305.48,422.39z"
              />
              <path
                d="M425.48,310.71c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C424.38,311.96,424.84,311.6,425.48,310.71z"
              />
              <path
                d="M674.54,634.08c-0.64,0-1.27,0-2.39,0c0.58-0.68,0.88-1.33,1.19-1.33c0.49-0.01,0.98,0.42,1.47,0.66
	C674.72,633.64,674.63,633.86,674.54,634.08z"
              />
              <path
                d="M386.93,312.17c-0.78,0.4-1.27,0.86-1.61,0.77c-0.35-0.09-0.7-0.7-0.77-1.13c-0.03-0.19,0.64-0.74,0.93-0.69
	C385.89,311.18,386.24,311.64,386.93,312.17z"
              />
              <path
                d="M400.52,150.57c0.4,0.47,0.86,0.78,0.86,1.1c0,0.33-0.44,0.67-0.68,1c-0.36-0.31-0.85-0.57-1.01-0.96
	C399.6,151.53,400.13,151.08,400.52,150.57z"
              />
              <path
                d="M433.97,311.53c-0.78,0.48-1.18,0.89-1.6,0.91c-0.27,0.01-0.78-0.56-0.78-0.88c0-0.32,0.49-0.91,0.75-0.91
	C432.76,310.66,433.17,311.07,433.97,311.53z"
              />
              <path
                d="M328.79,159.22c-0.38-0.51-0.84-0.86-0.82-1.17c0.02-0.34,0.49-0.65,0.76-0.98c0.35,0.34,0.83,0.63,0.99,1.04
	C329.79,158.29,329.22,158.72,328.79,159.22z"
              />
              <path
                d="M465.07,258.23c-0.44,0.42-0.79,0.98-1.02,0.93c-0.42-0.09-0.76-0.54-1.13-0.84c0.34-0.33,0.64-0.81,1.05-0.93
	C464.2,257.33,464.6,257.86,465.07,258.23z"
              />
              <path
                d="M332.57,199.73c0.53,0.7,1.07,1.1,1.03,1.44c-0.04,0.36-0.59,0.8-1,0.92c-0.2,0.06-0.84-0.5-0.85-0.79
	C331.75,200.9,332.15,200.49,332.57,199.73z"
              />
              <path
                d="M479.03,376.23c-0.44,0.45-0.79,1.04-1.02,0.99c-0.43-0.08-0.8-0.53-1.19-0.83c0.34-0.35,0.64-0.84,1.05-0.98
	C478.1,375.35,478.53,375.86,479.03,376.23z"
              />
              <path
                d="M347.04,311.76c-0.48,0.42-0.9,0.98-1.09,0.91c-0.41-0.15-0.71-0.62-1.06-0.96c0.33-0.27,0.64-0.74,0.99-0.76
	C346.2,310.92,346.54,311.38,347.04,311.76z"
              />
              <path
                d="M316.85,86.89c-0.73,0.51-1.1,0.94-1.51,0.98c-0.26,0.03-0.8-0.51-0.82-0.81c-0.02-0.33,0.43-0.95,0.69-0.95
	C315.63,86.09,316.05,86.47,316.85,86.89z"
              />
              <path
                d="M358.96,131.35c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C359.68,130.44,359.27,130.82,358.96,131.35z"
              />
              <path
                d="M360.87,152.94c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C361.6,152.04,361.19,152.41,360.87,152.94z"
              />
              <path
                d="M289.44,356.51c-0.43,0.46-0.8,1.06-0.99,1.01c-0.42-0.12-0.75-0.56-1.12-0.87c0.29-0.31,0.56-0.81,0.89-0.86
	C288.52,355.74,288.91,356.17,289.44,356.51z"
              />
              <path
                d="M479.41,312.39c-0.4-0.63-0.86-1.08-0.78-1.31c0.15-0.39,0.65-0.64,1-0.95c0.28,0.32,0.81,0.67,0.77,0.94
	C480.34,311.47,479.87,311.81,479.41,312.39z"
              />
              <path d="M443.15,8.02c-0.97,0.81-1.57,0.75-2.17-0.11C441.67,7.19,442.27,7.3,443.15,8.02z" />
              <path
                d="M439.69,309.72c0.43,0.62,0.88,1.01,0.91,1.42c0.02,0.27-0.67,0.88-0.86,0.82c-0.41-0.14-0.85-0.58-0.97-0.98
	C438.71,310.74,439.23,310.33,439.69,309.72z"
              />
              <path
                d="M244.1,331.58c-0.7,0.55-1.11,1.07-1.33,1c-0.41-0.13-0.71-0.6-1.05-0.93c0.37-0.3,0.72-0.77,1.13-0.85
	C243.15,330.76,243.55,331.22,244.1,331.58z"
              />
              <path
                d="M383.13,142.63c-0.8,0.48-1.21,0.9-1.63,0.92c-0.27,0.01-0.79-0.57-0.79-0.88c0-0.33,0.49-0.92,0.76-0.92
	C381.9,141.75,382.32,142.16,383.13,142.63z"
              />
              <path
                d="M415.33,156.6c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C414.43,157.62,414.96,157.11,415.33,156.6z"
              />
              <path
                d="M377.06,157.43c-0.45,0.43-0.74,0.92-1.07,0.95c-0.32,0.03-0.69-0.42-1.03-0.66c0.29-0.37,0.52-0.89,0.9-1.07
	C376.06,156.56,376.53,157.07,377.06,157.43z"
              />
              <path
                d="M481.9,261.94c-0.35-0.76-0.75-1.23-0.67-1.58c0.09-0.41,0.61-0.73,0.94-1.08c0.29,0.31,0.78,0.59,0.82,0.92
	C483.01,260.52,482.58,260.89,481.9,261.94z"
              />
              <path
                d="M487.62,310.73c-0.49,0.34-0.93,0.83-1.15,0.74c-0.4-0.15-0.67-0.64-1-0.99c0.39-0.28,0.76-0.71,1.19-0.77
	C486.89,309.68,487.21,310.27,487.62,310.73z"
              />
              <path
                d="M340.92,89.62c-0.43-0.45-0.92-0.74-0.94-1.06c-0.02-0.34,0.41-0.71,0.65-1.06c0.38,0.3,0.88,0.53,1.08,0.93
	C341.8,88.61,341.29,89.09,340.92,89.62z"
              />
              <path
                d="M407.61,149.98c0.5,0.54,1.05,0.89,1,1.13c-0.08,0.41-0.47,0.87-0.85,1.05c-0.18,0.08-0.91-0.46-0.92-0.72
	C406.84,151.03,407.25,150.62,407.61,149.98z"
              />
              <path
                d="M382.61,135.56c-0.78,0.41-1.2,0.81-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C381.51,134.52,381.91,135.04,382.61,135.56z"
              />
              <path
                d="M337.26,157.48c-0.49,0.41-0.8,0.9-1.13,0.91c-0.33,0.01-0.67-0.45-1.01-0.71c0.31-0.36,0.57-0.88,0.96-1.03
	C336.29,156.57,336.74,157.1,337.26,157.48z"
              />
              <path
                d="M293.82,90.23c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C294.69,89.23,294.24,89.61,293.82,90.23z"
              />
              <path
                d="M335.12,89.05c-0.84,0.39-1.28,0.76-1.7,0.73c-0.26-0.02-0.7-0.65-0.67-0.98c0.03-0.31,0.59-0.83,0.86-0.8
	C334.02,88.07,334.38,88.52,335.12,89.05z"
              />
              <path
                d="M469.18,261.58c-0.49,0.42-0.82,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C468.25,260.76,468.63,261.21,469.18,261.58z"
              />
              <path
                d="M405.55,155.08c-0.46,0.41-0.77,0.9-1.07,0.9c-0.34,0-0.68-0.45-1.02-0.7c0.32-0.36,0.58-0.83,0.97-1.01
	C404.61,154.18,405.06,154.7,405.55,155.08z"
              />
              <path
                d="M460.71,263.31c-0.35-0.51-0.78-0.86-0.76-1.18c0.03-0.33,0.49-0.62,0.77-0.93c0.33,0.34,0.79,0.64,0.93,1.05
	C461.71,262.45,461.14,262.85,460.71,263.31z"
              />
              <path
                d="M331.73,398.49c0.43,0.62,0.88,1.01,0.91,1.42c0.02,0.27-0.67,0.88-0.86,0.82c-0.41-0.14-0.85-0.58-0.97-0.98
	C330.74,399.51,331.26,399.1,331.73,398.49z"
              />
              <path
                d="M424.1,157.19c-0.8,0.4-1.23,0.78-1.63,0.76c-0.3-0.02-0.86-0.69-0.79-0.86c0.16-0.4,0.61-0.92,0.99-0.95
	C423.01,156.11,423.4,156.64,424.1,157.19z"
              />
              <path
                d="M455.58,309.53c-0.77,0.52-1.15,0.96-1.56,1c-0.28,0.02-0.83-0.52-0.85-0.83c-0.02-0.33,0.44-0.96,0.71-0.97
	C454.31,308.71,454.75,309.1,455.58,309.53z"
              />
              <path
                d="M383.97,157.13c-0.51,0.36-0.88,0.81-1.18,0.77c-0.34-0.04-0.63-0.52-0.94-0.81c0.35-0.33,0.66-0.78,1.08-0.92
	C383.12,156.11,383.52,156.69,383.97,157.13z"
              />
              <path
                d="M330.8,205.09c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.54-0.87,0.81-0.85
	C329.65,204.16,330.04,204.59,330.8,205.09z"
              />
              <path
                d="M502.04,309.02c-0.42,0.45-0.75,1.05-0.98,1.01c-0.43-0.07-0.8-0.5-1.2-0.78c0.33-0.35,0.61-0.85,1.01-0.99
	C501.1,308.17,501.54,308.67,502.04,309.02z"
              />
              <path
                d="M487.71,263.63c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.54-0.87,0.81-0.85
	C486.56,262.7,486.95,263.13,487.71,263.63z"
              />
              <path
                d="M320.43,90.28c-0.49,0.42-0.82,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C319.5,89.46,319.88,89.91,320.43,90.28z"
              />
              <path
                d="M439.3,262.71c0.39,0.65,0.81,1.06,0.81,1.48c0,0.27-0.73,0.84-0.92,0.76c-0.4-0.17-0.81-0.63-0.9-1.05
	C438.25,263.65,438.8,263.27,439.3,262.71z"
              />
              <path
                d="M461.29,310.46c-0.45-0.59-0.96-0.99-0.89-1.22c0.12-0.4,0.54-0.82,0.94-0.97c0.17-0.07,0.85,0.54,0.83,0.81
	C462.14,309.48,461.7,309.85,461.29,310.46z"
              />
              <path
                d="M313.44,90.9c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.86-0.89c0.32-0.28,0.63-0.77,0.96-0.79
	C312.62,89.97,312.94,90.47,313.44,90.9z"
              />
              <path
                d="M334.75,310.46c-0.4-0.62-0.83-1.01-0.84-1.41c-0.01-0.27,0.69-0.85,0.86-0.78c0.39,0.16,0.81,0.6,0.91,1
	C335.74,309.5,335.22,309.88,334.75,310.46z"
              />
              <path
                d="M481.1,331.32c0.48,0.59,1.02,0.99,0.96,1.23c-0.11,0.41-0.54,0.86-0.94,1.01c-0.19,0.07-0.9-0.52-0.89-0.79
	C480.26,332.35,480.69,331.95,481.1,331.32z"
              />
              <path
                d="M339.35,265.39c-0.42,0.44-0.77,1.01-0.99,0.97c-0.42-0.08-0.78-0.52-1.16-0.81c0.34-0.34,0.62-0.82,1.03-0.96
	C338.45,264.52,338.87,265.03,339.35,265.39z"
              />
              <path
                d="M342.14,307.46c0.33,0.54,0.76,0.92,0.72,1.24c-0.04,0.33-0.54,0.6-0.84,0.9c-0.32-0.36-0.79-0.68-0.91-1.1
	C341.06,308.3,341.67,307.91,342.14,307.46z"
              />
              <path
                d="M386.9,145.95c-0.45,0.43-0.82,1.01-1.05,0.96c-0.43-0.09-0.78-0.55-1.17-0.86c0.35-0.34,0.66-0.82,1.08-0.95
	C385.99,145.03,386.41,145.56,386.9,145.95z"
              />
              <path
                d="M481.49,358.2c-0.84,0.42-1.28,0.81-1.7,0.79c-0.27-0.01-0.73-0.64-0.71-0.97c0.02-0.31,0.57-0.86,0.85-0.83
	C480.35,357.23,480.73,357.67,481.49,358.2z"
              />
              <path
                d="M491.29,267c-0.53,0.37-1,0.88-1.21,0.79c-0.39-0.17-0.63-0.68-0.93-1.05c0.35-0.25,0.71-0.71,1.04-0.69
	C490.52,266.08,490.82,266.58,491.29,267z"
              />
              <path
                d="M443.1,266.22c0.37,0.52,0.89,0.98,0.8,1.17c-0.18,0.39-0.68,0.63-1.04,0.93c-0.24-0.34-0.67-0.7-0.65-1.03
	C442.21,266.97,442.69,266.67,443.1,266.22z"
              />
              <path
                d="M281.82,476.59c-0.47,0.42-0.85,0.98-1.08,0.92c-0.43-0.1-0.76-0.57-1.14-0.88c0.36-0.33,0.68-0.81,1.11-0.92
	C280.94,475.65,281.34,476.19,281.82,476.59z"
              />
              <path d="M293.17,634.22c0.69-1.62,1.45-1.44,2.2-0.81c0.01,0.22,0.01,0.45,0.02,0.67C294.66,634.13,293.91,634.18,293.17,634.22z" />
              <path
                d="M482.69,268.58c-0.31-0.53-0.71-0.91-0.66-1.23c0.05-0.32,0.54-0.58,0.84-0.86c0.31,0.36,0.75,0.69,0.86,1.11
	C483.77,267.79,483.16,268.15,482.69,268.58z"
              />
              <path
                d="M435.76,308.91c-0.36-0.52-0.79-0.87-0.77-1.19c0.03-0.33,0.5-0.63,0.78-0.94c0.33,0.35,0.8,0.65,0.94,1.06
	C436.78,308.03,436.2,308.44,435.76,308.91z"
              />
              <path
                d="M270.78,307.81c-0.5,0.36-0.93,0.88-1.16,0.8c-0.41-0.14-0.71-0.63-1.05-0.97c0.39-0.3,0.76-0.75,1.19-0.83
	C269.98,306.76,270.34,307.36,270.78,307.81z"
              />
              <path
                d="M435.91,266.67c0.38,0.53,0.91,1.01,0.82,1.2c-0.19,0.4-0.7,0.65-1.08,0.96c-0.24-0.35-0.69-0.71-0.67-1.05
	C434.99,267.44,435.48,267.14,435.91,266.67z"
              />
              <path
                d="M364.8,116.64c-0.45-0.62-0.96-1.05-0.89-1.28c0.13-0.41,0.58-0.82,0.99-0.97c0.18-0.06,0.86,0.57,0.83,0.85
	C365.71,115.65,365.25,116.03,364.8,116.64z"
              />
              <path
                d="M290.82,93.92c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C291.54,93.02,291.13,93.4,290.82,93.92z"
              />
              <path
                d="M483.79,307.25c-0.46,0.39-0.85,0.92-1.07,0.86c-0.41-0.12-0.72-0.58-1.08-0.9c0.36-0.31,0.69-0.77,1.1-0.87
	C482.97,306.29,483.34,306.84,483.79,307.25z"
              />
              <path
                d="M331.69,219.38c-0.48,0.42-0.8,0.92-1.11,0.92c-0.35,0-0.7-0.46-1.05-0.72c0.32-0.36,0.59-0.85,1-1.03
	C330.72,218.46,331.18,218.99,331.69,219.38z"
              />
              <path
                d="M317.77,307.32c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.79,1.1-0.93
	C316.91,306.29,317.31,306.88,317.77,307.32z"
              />
              <path
                d="M359.11,155.5c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C357.86,154.69,358.29,155.08,359.11,155.5z"
              />
              <path
                d="M376.3,150.41c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C375.48,149.47,375.8,149.97,376.3,150.41z"
              />
              <path
                d="M336.47,150.41c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C335.65,149.47,335.97,149.97,336.47,150.41z"
              />
              <path
                d="M326.74,214.85c0.35,0.56,0.78,0.95,0.73,1.26c-0.05,0.32-0.57,0.57-0.88,0.84c-0.28-0.32-0.78-0.64-0.78-0.96
	C325.8,215.68,326.3,215.36,326.74,214.85z"
              />
              <path
                d="M357.22,94.21c-0.82,0.46-1.24,0.86-1.66,0.87c-0.27,0-0.76-0.6-0.76-0.92c0.01-0.32,0.52-0.9,0.8-0.88
	C356.02,93.3,356.42,93.72,357.22,94.21z"
              />
              <path
                d="M365.91,128.29c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C365,129.31,365.53,128.8,365.91,128.29z"
              />
              <path
                d="M396.99,157.26c-0.34-0.73-0.74-1.17-0.66-1.52c0.09-0.4,0.59-0.71,0.91-1.06c0.28,0.3,0.76,0.58,0.79,0.9
	C398.05,155.91,397.63,156.27,396.99,157.26z"
              />
              <path
                d="M486.76,269.55c0.38,0.57,0.83,0.95,0.79,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.63-0.76-0.96
	C485.87,270.39,486.35,270.06,486.76,269.55z"
              />
              <path
                d="M293.31,359.97c-0.48,0.42-0.8,0.92-1.11,0.92c-0.35,0-0.7-0.46-1.05-0.72c0.32-0.36,0.59-0.85,1-1.03
	C292.34,359.05,292.79,359.58,293.31,359.97z"
              />
              <path
                d="M458.37,305.79c-0.5,0.41-0.83,0.89-1.14,0.88c-0.35-0.02-0.68-0.48-1.02-0.75c0.33-0.35,0.62-0.84,1.03-1
	C457.43,304.84,457.87,305.38,458.37,305.79z"
              />
              <path
                d="M381.1,149.85c0.49-0.4,0.91-0.94,1.12-0.87c0.39,0.14,0.67,0.63,0.99,0.97c-0.32,0.27-0.64,0.74-0.96,0.75
	C381.93,150.7,381.6,150.24,381.1,149.85z"
              />
              <path
                d="M323.32,205.57c-0.52,0.37-0.97,0.88-1.18,0.8c-0.39-0.16-0.63-0.67-0.93-1.03c0.34-0.25,0.69-0.71,1.01-0.69
	C322.56,204.66,322.86,205.15,323.32,205.57z"
              />
              <path
                d="M505.42,305.37c-0.49,0.38-0.91,0.91-1.14,0.84c-0.42-0.13-0.73-0.61-1.08-0.95c0.38-0.31,0.73-0.77,1.16-0.86
	C504.6,304.36,504.97,304.94,505.42,305.37z"
              />
              <path
                d="M464.5,304.11c0.33,0.53,0.75,0.9,0.71,1.23c-0.04,0.32-0.53,0.59-0.83,0.89c-0.32-0.36-0.78-0.68-0.89-1.09
	C463.44,304.94,464.04,304.56,464.5,304.11z"
              />
              <path
                d="M481.19,371.03c0.46,0.73,0.88,1.12,0.89,1.52c0.01,0.29-0.6,0.89-0.8,0.84c-0.42-0.11-0.99-0.51-1.05-0.87
	C480.17,372.19,480.69,371.76,481.19,371.03z"
              />
              <path
                d="M300.6,272.66c-0.8,0.48-1.21,0.9-1.63,0.92c-0.27,0.01-0.79-0.57-0.79-0.88c0-0.33,0.49-0.92,0.76-0.92
	C299.37,271.78,299.78,272.2,300.6,272.66z"
              />
              <path
                d="M337.93,306.64c-0.44-0.61-0.95-1.03-0.87-1.26c0.13-0.4,0.57-0.81,0.98-0.96c0.17-0.06,0.85,0.57,0.82,0.84
	C338.82,305.67,338.37,306.03,337.93,306.64z"
              />
              <path
                d="M340.14,272.68c-0.5,0.4-0.83,0.87-1.16,0.87c-0.33,0-0.65-0.48-0.98-0.74c0.32-0.35,0.59-0.85,0.99-1
	C339.2,271.73,339.63,272.28,340.14,272.68z"
              />
              <path
                d="M280.26,467.97c0.4,0.77,0.78,1.19,0.77,1.6c-0.01,0.28-0.67,0.82-0.88,0.75c-0.41-0.13-0.94-0.6-0.97-0.96
	C279.15,469.03,279.71,468.64,280.26,467.97z"
              />
              <path
                d="M418.25,274.47c-0.4-0.62-0.83-1.01-0.84-1.41c-0.01-0.27,0.69-0.85,0.86-0.78c0.39,0.16,0.81,0.6,0.91,1
	C419.23,273.51,418.71,273.9,418.25,274.47z"
              />
              <path
                d="M269.31,372.8c-0.51,0.37-0.97,0.89-1.16,0.81c-0.4-0.17-0.65-0.67-0.97-1.03c0.35-0.25,0.7-0.7,1.04-0.69
	C268.53,371.88,268.84,372.38,269.31,372.8z"
              />
              <path
                d="M239.54,336.74c-0.37-0.5-0.82-0.85-0.81-1.16c0.02-0.34,0.48-0.65,0.75-0.97c0.34,0.34,0.82,0.62,0.97,1.03
	C240.53,335.83,239.97,336.26,239.54,336.74z"
              />
              <path
                d="M246.09,273.71c-0.53,0.37-0.99,0.89-1.2,0.81c-0.39-0.16-0.64-0.67-0.94-1.04c0.34-0.25,0.7-0.72,1.03-0.7
	C245.3,272.79,245.61,273.28,246.09,273.71z"
              />
              <path
                d="M412.59,273.7c-0.78,0.44-1.18,0.85-1.58,0.85c-0.31-0.01-0.91-0.63-0.85-0.81c0.14-0.41,0.56-0.94,0.93-1
	C411.43,272.68,411.85,273.2,412.59,273.7z"
              />
              <path
                d="M479.97,303.94c-0.52,0.37-0.88,0.83-1.19,0.8c-0.34-0.03-0.64-0.52-0.96-0.8c0.35-0.33,0.66-0.8,1.08-0.94
	C479.08,302.93,479.49,303.51,479.97,303.94z"
              />
              <path
                d="M440.1,303.83c-0.46,0.41-0.77,0.9-1.07,0.9c-0.34,0-0.68-0.45-1.02-0.7c0.32-0.36,0.58-0.83,0.97-1.01
	C439.16,302.93,439.6,303.45,440.1,303.83z"
              />
              <path
                d="M491.74,274.2c-0.55,0.36-0.93,0.81-1.25,0.77c-0.31-0.04-0.78-0.6-0.76-0.9c0.02-0.3,0.57-0.79,0.87-0.78
	C490.93,273.29,491.25,273.78,491.74,274.2z"
              />
              <path
                d="M282.27,370.31c0.42,0.5,0.98,0.94,0.91,1.13c-0.16,0.41-0.65,0.69-1,1.02c-0.27-0.33-0.73-0.65-0.75-0.98
	C281.42,371.15,281.88,370.81,282.27,370.31z"
              />
              <path
                d="M274.11,303.87c-0.5,0.41-0.83,0.89-1.14,0.88c-0.35-0.02-0.68-0.48-1.02-0.75c0.33-0.35,0.62-0.84,1.03-1
	C273.17,302.92,273.61,303.46,274.11,303.87z"
              />
              <path
                d="M224.99,362.08c-0.51,0.44-0.83,0.93-1.16,0.94c-0.32,0.01-0.87-0.47-0.9-0.77c-0.03-0.3,0.45-0.87,0.76-0.91
	C224.02,361.3,224.42,361.73,224.99,362.08z"
              />
              <path
                d="M485.97,302.17c0.39,0.5,0.86,0.83,0.85,1.16c-0.01,0.34-0.47,0.66-0.74,1c-0.36-0.33-0.85-0.61-1.01-1.01
	C484.99,303.12,485.55,302.67,485.97,302.17z"
              />
              <path
                d="M280.09,304.34c-0.4-0.49-0.97-0.96-0.89-1.12c0.19-0.4,0.68-0.66,1.05-0.98c0.26,0.33,0.71,0.67,0.71,1
	C280.96,303.56,280.48,303.88,280.09,304.34z"
              />
              <path
                d="M313.26,170.6c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C312.14,169.53,312.54,170.06,313.26,170.6z"
              />
              <path
                d="M302.27,396.49c-0.39-0.78-0.77-1.2-0.75-1.61c0.02-0.29,0.68-0.82,0.88-0.75c0.41,0.14,0.93,0.6,0.96,0.97
	C303.39,395.43,302.83,395.82,302.27,396.49z"
              />
              <path
                d="M231.4,394.16c-0.55,0.36-0.94,0.8-1.22,0.74c-0.33-0.07-0.58-0.57-0.86-0.89c0.32-0.28,0.64-0.76,0.97-0.77
	C230.59,393.23,230.91,393.73,231.4,394.16z"
              />
              <path d="M672.07,546.35c0.29,0.89,0.49,1.52,0.86,2.63C671.42,547.67,671.42,547.67,672.07,546.35z" />
              <path
                d="M342.77,277.38c-0.47-0.6-0.99-1.02-0.92-1.25c0.12-0.41,0.56-0.84,0.97-0.99c0.18-0.07,0.88,0.55,0.86,0.82
	C343.64,276.37,343.19,276.76,342.77,277.38z"
              />
              <path
                d="M295.74,277.85c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C296.61,276.85,296.16,277.23,295.74,277.85z"
              />
              <path
                d="M460.68,303.14c-0.47-0.44-1.09-0.81-1.03-1c0.11-0.42,0.57-0.75,0.88-1.11c0.3,0.29,0.79,0.55,0.84,0.88
	C461.42,302.23,461,302.61,460.68,303.14z"
              />
              <path
                d="M250.12,277.09c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.79,1.1-0.93
	C249.25,276.06,249.65,276.65,250.12,277.09z"
              />
              <path
                d="M352.41,136.64c0.38,0.57,0.83,0.95,0.78,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.64-0.76-0.96
	C351.52,137.47,352,137.14,352.41,136.64z"
              />
              <path
                d="M223.25,633.16c-0.46,0.45-0.75,0.95-1.07,0.96c-0.35,0.02-0.72-0.41-1.08-0.64c0.3-0.37,0.54-0.88,0.93-1.05
	C222.25,632.34,222.71,632.82,223.25,633.16z"
              />
              <path
                d="M237.45,213.31c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C236.33,212.24,236.73,212.77,237.45,213.31z"
              />
              <path
                d="M304.64,169.64c0.41,0.62,0.89,1.05,0.81,1.29c-0.13,0.39-0.59,0.78-0.99,0.91c-0.17,0.05-0.82-0.59-0.78-0.85
	C303.73,170.57,304.19,170.22,304.64,169.64z"
              />
              <path d="M221.65,208.06c-0.7-0.46-0.73-1-0.2-1.56c0.09-0.1,0.55-0.05,0.63,0.07C222.49,207.18,222.51,207.74,221.65,208.06z" />
              <path
                d="M407.76,276.56c0.38,0.48,0.86,0.81,0.84,1.12c-0.01,0.34-0.48,0.66-0.74,0.99c-0.36-0.33-0.83-0.61-1.02-1.02
	C406.77,277.49,407.35,277.03,407.76,276.56z"
              />
              <path
                d="M381.23,300.55c0.37,0.52,0.9,1.03,0.81,1.17c-0.23,0.39-0.72,0.62-1.11,0.92c-0.23-0.35-0.66-0.72-0.64-1.06
	C380.31,301.26,380.81,300.98,381.23,300.55z"
              />
              <path
                d="M331.2,212.07c-0.44,0.47-0.81,1.08-1,1.02c-0.43-0.12-0.76-0.57-1.14-0.88c0.3-0.31,0.57-0.82,0.9-0.87
	C330.28,211.3,330.67,211.74,331.2,212.07z"
              />
              <path
                d="M393.63,277.61c0.42,0.61,0.85,0.98,0.88,1.39c0.02,0.26-0.66,0.87-0.83,0.8c-0.39-0.14-0.83-0.57-0.94-0.96
	C392.67,278.6,393.18,278.2,393.63,277.61z"
              />
              <path
                d="M386.34,280.86c-0.44-0.75-0.85-1.15-0.85-1.55c0-0.29,0.62-0.88,0.82-0.82c0.42,0.12,0.97,0.54,1.03,0.9
	C387.39,279.72,386.86,280.14,386.34,280.86z"
              />
              <path
                d="M482.09,298.84c0.4,0.47,0.86,0.78,0.86,1.1c0,0.33-0.44,0.67-0.68,1c-0.36-0.31-0.85-0.57-1.01-0.96
	C481.18,299.79,481.71,299.35,482.09,298.84z"
              />
              <path
                d="M340.62,279.91c-0.51,0.39-0.95,0.92-1.17,0.84c-0.4-0.15-0.66-0.65-0.97-1.01c0.33-0.26,0.67-0.74,1-0.73
	C339.8,279.01,340.13,279.49,340.62,279.91z"
              />
              <path
                d="M397.9,148.61c-0.48,0.42-0.9,0.98-1.09,0.91c-0.41-0.15-0.71-0.62-1.06-0.96c0.33-0.27,0.64-0.74,0.99-0.76
	C397.06,147.78,397.41,148.24,397.9,148.61z"
              />
              <path
                d="M252.82,460.75c0.5-0.39,0.83-0.85,1.15-0.84c0.34,0.01,0.66,0.47,0.98,0.73c-0.32,0.34-0.6,0.82-0.99,0.96
	C253.75,461.68,253.33,461.15,252.82,460.75z"
              />
              <path
                d="M489.41,298.37c0.34,0.52,0.77,0.87,0.74,1.19c-0.03,0.32-0.5,0.61-0.78,0.91c-0.32-0.34-0.79-0.65-0.91-1.05
	C488.4,299.22,488.97,298.83,489.41,298.37z"
              />
              <path
                d="M253.94,280.39c-0.5,0.39-0.84,0.85-1.14,0.83c-0.34-0.02-0.65-0.49-0.98-0.77c0.34-0.34,0.63-0.81,1.04-0.96
	C253.04,279.42,253.46,279.98,253.94,280.39z"
              />
              <path
                d="M362.73,111.71c-1.08,0.69-1.56,1.18-1.76,1.09c-0.38-0.18-0.59-0.7-0.87-1.08c0.35-0.23,0.69-0.64,1.05-0.66
	C361.55,111.05,361.98,111.38,362.73,111.71z"
              />
              <path
                d="M292.39,278.71c0.64,0.96,1.07,1.32,1.04,1.65c-0.03,0.33-0.52,0.62-0.81,0.93c-0.33-0.35-0.86-0.66-0.94-1.07
	C291.61,279.88,292.04,279.42,292.39,278.71z"
              />
              <path
                d="M277.27,299.69c-0.46,0.44-0.84,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C276.36,298.92,276.73,299.35,277.27,299.69z"
              />
              <path
                d="M290.57,364.02c-0.65,0.33-1.04,0.69-1.43,0.68c-0.35-0.01-0.68-0.43-1.02-0.66c0.29-0.37,0.51-0.88,0.89-1.05
	C289.2,362.9,289.67,363.41,290.57,364.02z"
              />
              <path
                d="M499.63,280.61c-0.49,0.4-0.92,0.94-1.12,0.87c-0.41-0.15-0.69-0.64-1.02-0.99c0.34-0.26,0.67-0.72,1.01-0.74
	C498.82,279.74,499.15,280.22,499.63,280.61z"
              />
              <path
                d="M236.28,338.17c0.38,0.57,0.83,0.95,0.79,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.63-0.76-0.96
	C235.4,339.01,235.87,338.68,236.28,338.17z"
              />
              <path
                d="M358.06,148.49c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C357.24,147.55,357.57,148.05,358.06,148.49z"
              />
              <path
                d="M473.31,391.13c-0.72,0.5-1.09,0.94-1.5,0.98c-0.29,0.03-0.94-0.55-0.9-0.73c0.1-0.42,0.47-1.01,0.82-1.09
	C472.07,390.22,472.54,390.7,473.31,391.13z"
              />
              <path
                d="M457.87,298.49c-0.45,0.44-0.84,1.02-1.03,0.96c-0.42-0.13-0.73-0.58-1.09-0.9c0.31-0.29,0.59-0.78,0.92-0.82
	C456.98,297.69,457.35,298.14,457.87,298.49z"
              />
              <path
                d="M270.97,283.74c-0.48-0.74-0.99-1.2-0.91-1.52c0.08-0.37,0.66-0.75,1.08-0.85c0.19-0.05,0.78,0.58,0.76,0.87
	C271.89,282.64,271.45,283.01,270.97,283.74z"
              />
              <path
                d="M403.76,146.59c0.52,0.56,1.09,0.92,1.04,1.16c-0.08,0.42-0.49,0.9-0.88,1.07c-0.19,0.08-0.94-0.46-0.94-0.74
	C402.97,147.67,403.39,147.25,403.76,146.59z"
              />
              <path
                d="M363.81,126.57c-0.71,0.32-1.16,0.7-1.51,0.62c-0.4-0.09-0.71-0.58-1.06-0.9c0.3-0.29,0.58-0.77,0.91-0.82
	C362.45,125.44,362.81,125.88,363.81,126.57z"
              />
              <path
                d="M505.01,298.15c-0.82,0.46-1.24,0.86-1.66,0.87c-0.27,0-0.76-0.6-0.76-0.92c0.01-0.32,0.52-0.9,0.8-0.88
	C503.81,297.23,504.21,297.65,505.01,298.15z"
              />
              <path
                d="M390.21,283.96c-0.37-0.5-0.82-0.85-0.81-1.16c0.02-0.34,0.48-0.65,0.75-0.97c0.34,0.34,0.82,0.62,0.97,1.03
	C391.2,283.05,390.64,283.47,390.21,283.96z"
              />
              <path
                d="M462.7,298.12c0.48-0.39,0.9-0.93,1.1-0.86c0.39,0.14,0.66,0.62,0.97,0.96c-0.31,0.27-0.63,0.74-0.94,0.74
	C463.5,298.96,463.18,298.5,462.7,298.12z"
              />
              <path
                d="M224.5,368.18c0.37,0.52,0.84,0.89,0.8,1.2c-0.04,0.33-0.54,0.61-0.85,0.91c-0.31-0.31-0.83-0.6-0.86-0.94
	C223.57,369.05,224.08,368.7,224.5,368.18z"
              />
              <path
                d="M344.7,283.35c-0.78,0.41-1.2,0.8-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C343.6,282.31,344,282.83,344.7,283.35z"
              />
              <path
                d="M337.37,299.45c-0.4-0.64-0.88-1.09-0.79-1.32c0.15-0.39,0.62-0.78,1.04-0.9c0.17-0.05,0.81,0.62,0.77,0.89
	C338.32,298.52,337.85,298.86,337.37,299.45z"
              />
              <path
                d="M337.43,283.84c-0.45,0.46-0.84,1.07-1.02,1.01c-0.43-0.14-0.76-0.58-1.13-0.9c0.31-0.29,0.6-0.77,0.94-0.82
	C336.53,283.07,336.91,283.5,337.43,283.84z"
              />
              <path
                d="M290.42,284.33c-0.53,0.34-0.91,0.78-1.23,0.74c-0.34-0.05-0.62-0.55-0.92-0.85c0.37-0.32,0.7-0.77,1.12-0.9
	C289.58,283.27,289.97,283.87,290.42,284.33z"
              />
              <path
                d="M478.13,297.88c-0.42-0.51-0.89-0.84-0.88-1.15c0.01-0.33,0.49-0.65,0.76-0.97c0.32,0.28,0.85,0.53,0.9,0.86
	C478.96,296.93,478.5,297.32,478.13,297.88z"
              />
              <path
                d="M275.23,284.23c0.18,0.41,0.54,0.85,0.48,1.22c-0.07,0.41-0.46,0.89-0.84,1.07c-0.18,0.09-0.93-0.44-0.94-0.7
	c-0.01-0.41,0.39-0.83,0.61-1.25C274.77,284.46,275,284.34,275.23,284.23z"
              />
              <path
                d="M318.95,208.03c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C317.86,209.27,318.31,208.92,318.95,208.03z"
              />
              <path
                d="M268.81,286.25c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C267.98,285.19,268.36,285.79,268.81,286.25z"
              />
              <path
                d="M280.63,295.85c-0.46,0.44-0.84,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C279.72,295.08,280.09,295.51,280.63,295.85z"
              />
              <path
                d="M481.97,365.36c-0.83,0.44-1.26,0.84-1.68,0.83c-0.27,0-0.75-0.62-0.73-0.94c0.01-0.32,0.55-0.88,0.82-0.86
	C480.8,364.42,481.2,364.85,481.97,365.36z"
              />
              <path
                d="M360.18,103.53c0.41,0.51,0.89,0.84,0.88,1.15c-0.01,0.33-0.49,0.64-0.76,0.96c-0.32-0.28-0.86-0.53-0.9-0.85
	C359.35,104.48,359.8,104.09,360.18,103.53z"
              />
              <path
                d="M452.83,293.92c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C451.73,295.16,452.19,294.81,452.83,293.92z"
              />
              <path
                d="M386.99,287.79c-0.43-0.45-0.92-0.74-0.94-1.06c-0.02-0.34,0.41-0.71,0.65-1.06c0.38,0.3,0.88,0.54,1.08,0.93
	C387.86,286.78,387.35,287.26,386.99,287.79z"
              />
              <path
                d="M341.58,287c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.99c-0.06-0.18,0.52-0.82,0.83-0.83
	C340.38,286.15,340.79,286.56,341.58,287z"
              />
              <path
                d="M267.24,366.97c-0.32-0.54-0.73-0.93-0.68-1.26c0.05-0.33,0.56-0.59,0.87-0.88c0.31,0.37,0.77,0.71,0.88,1.13
	C268.35,366.16,267.72,366.53,267.24,366.97z"
              />
              <path
                d="M327.16,208.93c-0.52,0.37-0.97,0.88-1.18,0.8c-0.39-0.16-0.63-0.67-0.93-1.03c0.34-0.25,0.69-0.71,1.01-0.69
	C326.4,208.02,326.7,208.51,327.16,208.93z"
              />
              <path
                d="M476.7,387.47c-0.84,0.42-1.28,0.81-1.7,0.79c-0.27-0.01-0.73-0.64-0.71-0.97c0.02-0.31,0.57-0.86,0.85-0.83
	C475.55,386.5,475.93,386.94,476.7,387.47z"
              />
              <path
                d="M461.03,294.7c-0.5,0.41-0.82,0.89-1.14,0.89c-0.31,0-0.85-0.49-0.87-0.79c-0.01-0.31,0.46-0.86,0.77-0.89
	C460.1,293.87,460.48,294.33,461.03,294.7z"
              />
              <path
                d="M294.24,287.64c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C293.42,286.7,293.75,287.2,294.24,287.64z"
              />
              <path
                d="M498.83,286.63c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C497.92,287.66,498.45,287.15,498.83,286.63z"
              />
              <path
                d="M285.69,289.83c-0.34-0.64-0.78-1.08-0.71-1.42c0.09-0.4,0.61-0.7,0.94-1.04c0.29,0.3,0.79,0.59,0.81,0.91
	C286.76,288.61,286.3,288.97,285.69,289.83z"
              />
              <path
                d="M262.65,127.88c-0.78,0.41-1.2,0.81-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C261.55,126.84,261.95,127.36,262.65,127.88z"
              />
              <path
                d="M378.63,147.69c-0.48-0.45-1.1-0.82-1.05-1.02c0.12-0.42,0.57-0.75,0.89-1.12c0.3,0.29,0.81,0.56,0.85,0.89
	C379.38,146.76,378.96,147.15,378.63,147.69z"
              />
              <path
                d="M271.82,290.66c-0.42-0.45-0.91-0.74-0.92-1.05c-0.02-0.34,0.41-0.7,0.64-1.05c0.37,0.3,0.87,0.53,1.06,0.92
	C272.69,289.66,272.18,290.14,271.82,290.66z"
              />
              <path
                d="M285.59,366.5c0.42,0.48,0.98,0.88,0.92,1.08c-0.13,0.4-0.61,0.69-0.94,1.03c-0.27-0.31-0.74-0.61-0.76-0.93
	C284.79,367.35,285.23,367.01,285.59,366.5z"
              />
              <path
                d="M378.98,139.37c-0.52,0.36-0.98,0.87-1.19,0.78c-0.38-0.17-0.62-0.68-0.92-1.04c0.34-0.25,0.7-0.7,1.02-0.68
	C378.22,138.45,378.52,138.94,378.98,139.37z"
              />
              <path
                d="M383.53,292.17c-0.35-0.76-0.75-1.23-0.67-1.58c0.09-0.41,0.61-0.73,0.94-1.08c0.29,0.31,0.78,0.59,0.82,0.92
	C384.64,290.75,384.21,291.12,383.53,292.17z"
              />
              <path
                d="M275.53,294.02c-0.37-0.5-0.81-0.83-0.79-1.15c0.02-0.33,0.47-0.64,0.74-0.96c0.34,0.33,0.81,0.62,0.96,1.02
	C276.5,293.12,275.95,293.54,275.53,294.02z"
              />
              <path
                d="M337.93,290.95c-0.51,0.39-0.85,0.86-1.16,0.84c-0.35-0.02-0.66-0.5-0.99-0.77c0.34-0.34,0.64-0.82,1.05-0.97
	C337.02,289.97,337.44,290.53,337.93,290.95z"
              />
              <path
                d="M351.65,156.08c-0.5,0.4-0.83,0.87-1.16,0.87c-0.33,0-0.65-0.48-0.98-0.74c0.32-0.35,0.59-0.86,0.99-1
	C350.72,155.13,351.15,155.68,351.65,156.08z"
              />
              <path
                d="M364.44,109.4c-0.49-0.56-1.02-0.93-0.97-1.16c0.09-0.41,0.49-0.86,0.88-1.03c0.17-0.08,0.89,0.48,0.89,0.75
	C365.24,108.37,364.82,108.77,364.44,109.4z"
              />
              <path
                d="M503.12,289.58c0.39,0.65,0.81,1.06,0.81,1.48c0,0.27-0.73,0.84-0.92,0.76c-0.4-0.17-0.81-0.63-0.9-1.05
	C502.06,290.52,502.62,290.15,503.12,289.58z"
              />
              <path
                d="M291.16,291.33c-0.49,0.42-0.81,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C290.23,290.51,290.61,290.96,291.16,291.33z"
              />
              <path
                d="M443.5,299.69c-0.51,0.45-0.83,0.95-1.13,0.93c-0.34-0.01-0.66-0.48-0.99-0.76c0.28-0.31,0.53-0.82,0.85-0.88
	C442.54,298.94,442.93,299.36,443.5,299.69z"
              />
              <path
                d="M284.98,361.69c-0.43-0.51-0.91-0.83-0.9-1.14c0.01-0.32,0.5-0.63,0.78-0.94c0.3,0.28,0.81,0.53,0.85,0.85
	C285.75,360.76,285.33,361.14,284.98,361.69z"
              />
              <path
                d="M356.24,140.01c0.37,0.56,0.82,0.93,0.77,1.24c-0.04,0.32-0.57,0.56-0.88,0.84c-0.27-0.31-0.74-0.62-0.75-0.94
	C355.37,140.84,355.84,140.51,356.24,140.01z"
              />
              <path
                d="M281,356.21c0.42,0.49,0.9,0.81,0.89,1.11c-0.01,0.33-0.48,0.89-0.76,0.89c-0.33,0-0.86-0.45-0.92-0.77
	C280.16,357.15,280.62,356.76,281,356.21z"
              />
              <path
                d="M301.9,167.67c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.31,0.56-0.78,0.88-0.79
	C301.1,166.75,301.42,167.25,301.9,167.67z"
              />
              <path
                d="M245.6,279.63c0.37,0.59,0.73,0.93,0.76,1.3c0.02,0.25-0.54,0.79-0.68,0.74c-0.37-0.13-0.88-0.48-0.92-0.8
	C244.71,280.57,245.19,280.19,245.6,279.63z"
              />
              <path
                d="M334.75,144.32c-0.37-0.56-0.81-0.94-0.77-1.24c0.05-0.32,0.57-0.57,0.89-0.85c0.27,0.32,0.74,0.63,0.75,0.95
	C335.62,143.5,335.15,143.82,334.75,144.32z"
              />
              <path
                d="M282.98,364.33c-0.55,0.39-0.87,0.77-1.24,0.82c-0.22,0.03-0.77-0.51-0.73-0.69c0.09-0.38,0.44-0.91,0.74-0.95
	C282.05,363.47,282.43,363.94,282.98,364.33z"
              />
              <path
                d="M338.01,140.48c-0.41-0.5-0.87-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.31,0.28,0.83,0.53,0.88,0.85
	C338.83,139.55,338.38,139.92,338.01,140.48z"
              />
              <path
                d="M360.32,143.35c0.35,0.6,0.7,0.96,0.72,1.33c0.01,0.24-0.57,0.76-0.71,0.71c-0.37-0.14-0.86-0.51-0.89-0.82
	C359.41,144.26,359.89,143.91,360.32,143.35z"
              />
              <path
                d="M229.83,348.48c-0.45-0.49-0.95-0.8-0.95-1.12c0-0.31,0.48-0.83,0.8-0.87c0.28-0.03,0.83,0.44,0.88,0.75
	C230.59,347.56,230.17,347.94,229.83,348.48z"
              />
              <path
                d="M331.3,225.21c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C330.4,226.02,330.88,225.7,331.3,225.21z"
              />
              <path
                d="M492.41,281.41c-0.59,0.35-0.94,0.71-1.3,0.73c-0.24,0.01-0.75-0.58-0.69-0.75c0.13-0.37,0.51-0.85,0.83-0.89
	C491.54,280.47,491.89,280.98,492.41,281.41z"
              />
              <path
                d="M466.96,279.96c-0.54,0.36-0.91,0.8-1.2,0.75c-0.32-0.06-0.8-0.59-0.78-0.87c0.03-0.31,0.55-0.77,0.88-0.79
	C466.16,279.03,466.48,279.53,466.96,279.96z"
              />
              <path
                d="M398.67,282.12c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.74,0.94-0.74
	C397.83,281.24,398.16,281.7,398.67,282.12z"
              />
              <path
                d="M332.61,147.04c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.3,0.56-0.77,0.88-0.78
	C331.8,146.12,332.12,146.62,332.61,147.04z"
              />
              <path
                d="M375.16,136.12c-0.53,0.42-0.86,0.88-1.19,0.88c-0.31,0-0.62-0.5-0.92-0.78c0.28-0.31,0.53-0.83,0.84-0.86
	C374.22,135.33,374.6,135.76,375.16,136.12z"
              />
              <path
                d="M350.89,149.19c-0.57,0.37-0.95,0.82-1.24,0.77c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.28,0.63-0.74,0.97-0.77
	C350.03,148.29,350.37,148.77,350.89,149.19z"
              />
              <path
                d="M327.18,224.47c-0.42-0.56-0.9-0.94-0.85-1.23c0.05-0.32,0.54-0.67,0.92-0.81c0.13-0.05,0.7,0.49,0.68,0.74
	C327.91,223.54,327.54,223.88,327.18,224.47z"
              />
              <path
                d="M328.17,149.61c0.34,0.55,0.77,0.93,0.72,1.24c-0.05,0.31-0.56,0.56-0.86,0.84c-0.28-0.31-0.77-0.62-0.77-0.94
	C327.26,150.43,327.74,150.11,328.17,149.61z"
              />
              <path
                d="M355.76,132.81c0.37,0.56,0.82,0.93,0.77,1.24c-0.04,0.32-0.57,0.56-0.88,0.84c-0.27-0.31-0.74-0.62-0.75-0.94
	C354.89,133.64,355.36,133.31,355.76,132.81z"
              />
              <path d="M628.1,330.17c0.05,0.55,0.1,1.11,0.19,2.07C627.33,331.43,626.9,330.93,628.1,330.17z" />
              <path
                d="M302.77,520.22c0.38,0.57,0.76,0.91,0.81,1.3c0.03,0.25-0.53,0.84-0.65,0.8c-0.38-0.14-0.91-0.48-0.95-0.79
	C301.94,521.19,302.4,520.79,302.77,520.22z"
              />
              <path
                d="M351.93,131.84c-0.41-0.5-0.88-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.32,0.28,0.83,0.53,0.89,0.85
	C352.74,130.91,352.29,131.29,351.93,131.84z"
              />
              <path
                d="M346.41,151.8c0.35,0.54,0.79,0.91,0.74,1.21c-0.05,0.32-0.59,0.81-0.86,0.78c-0.32-0.04-0.81-0.55-0.82-0.87
	C345.46,152.61,345.97,152.29,346.41,151.8z"
              />
              <path
                d="M387.36,152.87c-0.52,0.44-0.84,0.94-1.14,0.92c-0.34-0.02-0.66-0.49-0.98-0.76c0.28-0.31,0.53-0.81,0.86-0.87
	C386.4,152.1,386.79,152.53,387.36,152.87z"
              />
              <path
                d="M339.45,152.28c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C338.55,153.08,339.04,152.76,339.45,152.28z"
              />
              <path
                d="M356.13,126.66c-0.5,0.46-0.81,0.98-1.11,0.96c-0.32-0.02-0.74-0.48-0.87-0.84c-0.07-0.18,0.41-0.79,0.65-0.8
	C355.16,125.99,355.53,126.34,356.13,126.66z"
              />
              <path
                d="M305.27,163.89c-0.59,0.33-0.96,0.69-1.32,0.69c-0.24,0-0.73-0.6-0.67-0.77c0.13-0.36,0.53-0.84,0.86-0.86
	C304.43,162.92,304.76,163.44,305.27,163.89z"
              />
              <path
                d="M297.58,283.65c-0.48,0.42-0.8,0.92-1.1,0.9c-0.33-0.01-0.87-0.48-0.89-0.77c-0.02-0.3,0.45-0.83,0.78-0.89
	C296.67,282.84,297.05,283.29,297.58,283.65z"
              />
              <path
                d="M295.65,315.16c0.43,0.5,0.91,0.83,0.9,1.14c0,0.32-0.5,0.62-0.77,0.94c-0.31-0.28-0.81-0.53-0.86-0.84
	C294.87,316.08,295.3,315.71,295.65,315.16z"
              />
              <path
                d="M257.12,316.19c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.75,0.94-0.74
	C256.29,315.31,256.61,315.78,257.12,316.19z"
              />
              <path
                d="M265.7,124.01c-0.54,0.35-0.91,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C264.91,123.11,265.23,123.59,265.7,124.01z"
              />
              <path
                d="M410.91,312.26c0.38,0.59,0.84,0.99,0.78,1.29c-0.06,0.32-0.59,0.64-0.97,0.75c-0.14,0.04-0.66-0.54-0.64-0.79
	C410.13,313.15,410.51,312.82,410.91,312.26z"
              />
              <path
                d="M419.31,312.84c-0.58,0.36-0.97,0.79-1.3,0.74c-0.31-0.04-0.55-0.58-0.81-0.9c0.32-0.26,0.64-0.73,0.95-0.73
	C418.48,311.95,418.79,312.42,419.31,312.84z"
              />
              <path
                d="M462.62,282.52c0.3,0.62,0.62,1,0.62,1.38c0,0.23-0.63,0.71-0.78,0.64c-0.36-0.16-0.82-0.58-0.82-0.89
	C461.64,283.34,462.16,283.03,462.62,282.52z"
              />
              <path
                d="M359.48,123.05c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.3,0.56-0.77,0.88-0.78
	C358.68,122.13,358.99,122.62,359.48,123.05z"
              />
              <path
                d="M393.82,311.68c-0.59,0.33-1.02,0.77-1.32,0.69c-0.3-0.08-0.59-0.65-0.63-1.04c-0.02-0.18,0.59-0.66,0.8-0.61
	C393.05,310.83,393.33,311.24,393.82,311.68z"
              />
              <path
                d="M395.32,159.64c-0.53,0.41-0.87,0.88-1.2,0.87c-0.31-0.01-0.61-0.5-0.92-0.79c0.28-0.3,0.54-0.82,0.85-0.85
	C394.38,158.84,394.75,159.27,395.32,159.64z"
              />
              <path
                d="M489.06,285.25c-0.59,0.35-0.94,0.71-1.3,0.73c-0.24,0.01-0.75-0.58-0.69-0.75c0.13-0.37,0.51-0.85,0.83-0.89
	C488.18,284.31,488.53,284.81,489.06,285.25z"
              />
              <path
                d="M481.38,278.51c-0.58,0.36-0.93,0.73-1.29,0.75c-0.24,0.01-0.76-0.57-0.71-0.73c0.12-0.37,0.49-0.85,0.82-0.9
	C480.48,277.58,480.85,278.08,481.38,278.51z"
              />
              <path
                d="M263.52,308.04c-0.5,0.46-0.82,0.98-1.11,0.96c-0.32-0.02-0.74-0.48-0.87-0.84c-0.07-0.18,0.41-0.79,0.65-0.8
	C262.55,307.37,262.92,307.72,263.52,308.04z"
              />
              <path
                d="M270.8,486.96c0.38,0.5,0.76,0.8,0.82,1.16c0.04,0.2-0.44,0.49-0.69,0.75c-0.3-0.29-0.7-0.54-0.84-0.89
	C270.03,487.81,270.45,487.44,270.8,486.96z"
              />
              <path
                d="M331.98,306.11c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.74,0.94-0.74
	C331.14,305.23,331.46,305.7,331.98,306.11z"
              />
              <path
                d="M472.29,257.47c-0.51,0.45-0.83,0.95-1.13,0.94c-0.34-0.01-0.66-0.48-0.99-0.75c0.28-0.31,0.52-0.82,0.85-0.88
	C471.32,256.71,471.72,257.13,472.29,257.47z"
              />
              <path
                d="M472.77,304.5c-0.52,0.44-0.84,0.94-1.14,0.92c-0.34-0.02-0.66-0.49-0.98-0.76c0.28-0.31,0.53-0.81,0.86-0.87
	C471.81,303.73,472.2,304.16,472.77,304.5z"
              />
              <path
                d="M384.9,303.64c0.43,0.5,0.91,0.83,0.9,1.14c0,0.32-0.5,0.62-0.77,0.94c-0.31-0.28-0.81-0.53-0.86-0.84
	C384.12,304.57,384.55,304.19,384.9,303.64z"
              />
              <path
                d="M300.91,87.02c0.44,0.49,0.95,0.82,0.94,1.12c-0.01,0.32-0.49,0.83-0.82,0.87c-0.27,0.03-0.81-0.46-0.86-0.78
	C300.13,87.93,300.56,87.56,300.91,87.02z"
              />
              <path
                d="M265.75,303.14c0.37,0.59,0.73,0.93,0.76,1.3c0.02,0.25-0.54,0.79-0.68,0.74c-0.37-0.13-0.88-0.48-0.92-0.8
	C264.86,304.08,265.34,303.71,265.75,303.14z"
              />
              <path
                d="M303.56,408.24c0.41,0.5,0.87,0.83,0.86,1.14c-0.01,0.32-0.48,0.63-0.75,0.95c-0.32-0.28-0.85-0.52-0.89-0.84
	C302.75,409.17,303.19,408.8,303.56,408.24z"
              />
              <path
                d="M326.5,88.46c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C325.6,89.26,326.08,88.95,326.5,88.46z"
              />
              <path
                d="M481.85,285.53c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C480.82,284.74,481.26,285.18,481.85,285.53z"
              />
              <path
                d="M475.03,262.11c-0.45-0.49-0.95-0.8-0.95-1.12c0-0.31,0.48-0.83,0.8-0.87c0.28-0.03,0.83,0.44,0.88,0.75
	C475.79,261.18,475.37,261.57,475.03,262.11z"
              />
              <path
                d="M337.37,93.47c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.86,0.53,0.88,0.85
	C338.3,92.59,337.8,92.94,337.37,93.47z"
              />
              <path
                d="M487.29,279.17c-0.34-0.62-0.77-1.05-0.69-1.34c0.09-0.32,0.64-0.59,1.03-0.68c0.14-0.03,0.62,0.59,0.57,0.84
	C488.13,278.35,487.72,278.64,487.29,279.17z"
              />
              <path
                d="M288.26,302.44c-0.55,0.39-0.87,0.77-1.24,0.82c-0.22,0.03-0.77-0.51-0.73-0.69c0.09-0.38,0.44-0.91,0.74-0.95
	C287.33,301.57,287.71,302.04,288.26,302.44z"
              />
              <path
                d="M478.91,265.47c-0.34-0.55-0.76-0.94-0.71-1.25c0.05-0.31,0.61-0.77,0.89-0.74c0.32,0.04,0.79,0.57,0.79,0.88
	C479.86,264.69,479.36,264.99,478.91,265.47z"
              />
              <path
                d="M476.13,300.83c-0.57,0.37-0.95,0.81-1.25,0.76c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.27,0.64-0.74,0.98-0.76
	C475.28,299.93,475.62,300.41,476.13,300.83z"
              />
              <path
                d="M466.58,288.29c-0.4-0.56-0.79-0.88-0.83-1.24c-0.03-0.26,0.5-0.85,0.62-0.81c0.39,0.12,0.91,0.43,0.98,0.75
	C467.42,287.29,466.96,287.7,466.58,288.29z"
              />
              <path
                d="M460.53,287.53c-0.53,0.4-0.85,0.8-1.22,0.86c-0.23,0.04-0.8-0.48-0.76-0.66c0.08-0.38,0.41-0.93,0.71-0.98
	C459.56,286.68,459.96,287.15,460.53,287.53z"
              />
              <path
                d="M465.8,265.56c-0.54,0.35-0.91,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C465.01,264.66,465.32,265.14,465.8,265.56z"
              />
              <path
                d="M322.72,211.5c0.42,0.53,0.82,0.83,0.89,1.19c0.04,0.25-0.44,0.86-0.59,0.83c-0.39-0.09-0.94-0.37-1.02-0.69
	C321.93,212.54,322.37,212.11,322.72,211.5z"
              />
              <path
                d="M270.03,300.46c-0.48,0.41-0.8,0.88-1.12,0.88c-0.31,0-0.84-0.48-0.85-0.77c-0.01-0.31,0.45-0.85,0.75-0.89
	C269.12,299.64,269.49,300.09,270.03,300.46z"
              />
              <path
                d="M358.8,115.81c-0.56,0.37-0.96,0.84-1.25,0.78c-0.31-0.06-0.64-0.59-0.72-0.97c-0.04-0.18,0.53-0.71,0.75-0.67
	C357.95,115.02,358.27,115.41,358.8,115.81z"
              />
              <path
                d="M458.6,266.09c-0.59,0.33-1.02,0.77-1.32,0.69c-0.3-0.08-0.59-0.65-0.63-1.04c-0.02-0.18,0.59-0.66,0.8-0.61
	C457.83,265.24,458.11,265.66,458.6,266.09z"
              />
              <path
                d="M241.66,278.67c-0.37-0.56-0.81-0.94-0.77-1.24c0.05-0.32,0.57-0.57,0.89-0.85c0.27,0.32,0.73,0.63,0.75,0.95
	C242.53,277.85,242.06,278.17,241.66,278.67z"
              />
              <path
                d="M237.07,268.35c-0.34-0.55-0.76-0.94-0.71-1.25c0.05-0.31,0.61-0.77,0.89-0.74c0.32,0.04,0.79,0.57,0.79,0.88
	C238.02,267.56,237.52,267.87,237.07,268.35z"
              />
              <path
                d="M416.11,276.89c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C415.09,276.1,415.52,276.55,416.11,276.89z"
              />
              <path
                d="M484.8,287.79c0.38,0.59,0.84,0.99,0.78,1.29c-0.06,0.32-0.59,0.64-0.97,0.75c-0.14,0.04-0.66-0.54-0.64-0.79
	C484.02,288.67,484.41,288.35,484.8,287.79z"
              />
              <path
                d="M468.33,270.05c-0.32-0.61-0.66-0.98-0.66-1.36c0-0.24,0.61-0.74,0.75-0.67c0.36,0.16,0.84,0.55,0.85,0.87
	C469.29,269.2,468.78,269.53,468.33,270.05z"
              />
              <path
                d="M463.14,276.41c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C462.11,275.62,462.55,276.07,463.14,276.41z"
              />
              <path
                d="M385.39,297.71c-0.54,0.36-0.91,0.8-1.2,0.75c-0.32-0.06-0.8-0.59-0.78-0.87c0.03-0.31,0.55-0.77,0.88-0.79
	C384.58,296.79,384.91,297.28,385.39,297.71z"
              />
              <path
                d="M272.27,297.87c-0.41-0.5-0.87-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.31,0.28,0.84,0.53,0.88,0.85
	C273.08,296.94,272.64,297.32,272.27,297.87z"
              />
              <path
                d="M486.43,296.27c-0.54,0.35-0.92,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C485.65,295.38,485.96,295.86,486.43,296.27z"
              />
              <path
                d="M446.86,296.03c-0.57,0.37-0.95,0.81-1.25,0.76c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.27,0.64-0.74,0.98-0.76
	C446.01,295.13,446.34,295.61,446.86,296.03z"
              />
              <path d="M255.83,634.03c-0.83,0-1.47,0-2.29,0C254.68,632.73,254.68,632.73,255.83,634.03z" />
              <path
                d="M358.32,108.55c-0.53,0.4-0.85,0.8-1.22,0.86c-0.23,0.04-0.8-0.48-0.76-0.66c0.08-0.38,0.41-0.93,0.71-0.98
	C357.36,107.71,357.76,108.17,358.32,108.55z"
              />
              <path d="M588.39,634.13c-1.11,0-1.77,0-2.5,0C586.76,632.66,586.77,632.66,588.39,634.13z" />
              <path
                d="M492.37,294.31c0.44,0.49,0.95,0.81,0.95,1.12c-0.01,0.32-0.49,0.83-0.81,0.87c-0.27,0.03-0.82-0.46-0.87-0.77
	C491.59,295.22,492.02,294.85,492.37,294.31z"
              />
              <path
                d="M356.31,100.17c0.42,0.53,0.82,0.83,0.89,1.19c0.04,0.25-0.44,0.86-0.59,0.83c-0.39-0.09-0.94-0.37-1.02-0.69
	C355.52,101.22,355.96,100.79,356.31,100.17z"
              />
              <path
                d="M464.37,290.98c-0.58,0.36-0.99,0.82-1.28,0.75c-0.31-0.07-0.63-0.61-0.7-1c-0.03-0.18,0.55-0.69,0.78-0.65
	C463.54,290.16,463.84,290.57,464.37,290.98z"
              />
              <path
                d="M465.01,273.89c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.85,0.52,0.88,0.85
	C465.94,273,465.45,273.35,465.01,273.89z"
              />
              <path
                d="M455.91,292.59c-0.34-0.55-0.77-0.93-0.72-1.24c0.05-0.32,0.56-0.56,0.86-0.84c0.28,0.32,0.76,0.63,0.77,0.95
	C456.83,291.77,456.34,292.09,455.91,292.59z"
              />
              <path
                d="M495.85,292.83c-0.4-0.5-0.87-0.83-0.86-1.14c0.01-0.32,0.51-0.86,0.78-0.85c0.33,0.01,0.86,0.46,0.9,0.78
	C496.71,291.91,496.24,292.29,495.85,292.83z"
              />
              <path
                d="M283.99,292.21c-0.58,0.36-0.97,0.79-1.3,0.74c-0.31-0.04-0.55-0.58-0.81-0.9c0.32-0.26,0.64-0.73,0.95-0.73
	C283.16,291.32,283.48,291.79,283.99,292.21z"
              />
              <path d="M538.92,634.23c0.64-1.39,1.37-1.45,2.43-0.15C540.32,634.15,539.62,634.19,538.92,634.23z" />
              <path
                d="M448.7,290.95c0.44,0.49,0.95,0.81,0.95,1.12c-0.01,0.32-0.49,0.83-0.81,0.87c-0.27,0.03-0.82-0.46-0.87-0.77
	C447.92,291.87,448.35,291.49,448.7,290.95z"
              />
              <path
                d="M481.33,294.05c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.85,0.52,0.88,0.85
	C482.26,293.15,481.76,293.5,481.33,294.05z"
              />
              <path
                d="M489.8,292.42c-0.58,0.36-0.99,0.82-1.28,0.75c-0.31-0.07-0.63-0.61-0.7-1c-0.03-0.18,0.55-0.69,0.78-0.65
	C488.97,291.6,489.27,292.01,489.8,292.42z"
              />
              <path
                d="M484.52,274.55c-0.48,0.41-0.8,0.88-1.12,0.88c-0.31,0-0.84-0.48-0.85-0.77c-0.01-0.31,0.45-0.85,0.75-0.89
	C483.61,273.73,483.98,274.18,484.52,274.55z"
              />
              <path
                d="M441.63,294.05c-0.4-0.56-0.79-0.88-0.83-1.24c-0.03-0.26,0.5-0.85,0.62-0.81c0.39,0.12,0.91,0.43,0.98,0.75
	C442.46,293.05,442.01,293.46,441.63,294.05z"
              />
              <path d="M461.81,634.04c-0.89,0-1.57,0-2.37,0C460.49,632.77,460.49,632.77,461.81,634.04z" />
              <path
                d="M439.6,296.57c-0.5,0.43-0.78,0.84-1.13,0.92c-0.23,0.05-0.82-0.43-0.8-0.6c0.06-0.38,0.35-0.91,0.66-1.01
	C438.6,295.79,439.03,296.23,439.6,296.57z"
              />
              <path d="M499.42,634.21c0.64-1.33,1.33-1.33,2.24-0.12C500.76,634.14,500.09,634.18,499.42,634.21z" />
              <path
                d="M275.06,357.75c-0.5,0.44-0.82,0.92-1.11,0.91c-0.33-0.01-0.65-0.47-0.97-0.73c0.28-0.31,0.51-0.8,0.84-0.86
	C274.11,357.01,274.49,357.42,275.06,357.75z"
              />
              <path
                d="M268.41,292.13c0.36,0.59,0.8,0.99,0.74,1.29c-0.07,0.31-0.59,0.61-0.97,0.72c-0.13,0.04-0.63-0.54-0.6-0.8
	C267.63,292.98,268.02,292.67,268.41,292.13z"
              />
              <path
                d="M387.51,292.61c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C386.73,293.63,387.16,293.21,387.51,292.61z"
              />
              <path
                d="M475.44,293.47c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C474.41,292.72,474.82,293.13,475.44,293.47z"
              />
              <path
                d="M458.03,272.02c0.42,0.44,0.9,0.74,0.87,0.95c-0.06,0.37-0.44,0.7-0.69,1.04c-0.29-0.22-0.83-0.44-0.83-0.66
	C457.38,272.98,457.72,272.61,458.03,272.02z"
              />
              <path d="M332.78,634.06c0.98-1.16,1.64-1.12,2.72,0C334.42,634.06,333.75,634.06,332.78,634.06z" />
              <path
                d="M458.53,279.18c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C457.75,280.19,458.18,279.78,458.53,279.18z"
              />
              <path
                d="M294.85,294.81c-0.46,0.35-0.83,0.8-1,0.73c-0.34-0.14-0.68-0.51-0.81-0.87c-0.05-0.14,0.51-0.73,0.69-0.69
	C294.1,294.07,294.39,294.44,294.85,294.81z"
              />
              <path
                d="M251.06,457.33c-0.34,0.32-0.63,0.71-0.72,0.67c-0.3-0.14-0.58-0.41-0.74-0.7c-0.05-0.09,0.37-0.6,0.51-0.58
	C250.41,456.78,250.67,457.06,251.06,457.33z"
              />
              <path
                d="M426.72,272.56c-0.63,0.34-1,0.68-1.36,0.67c-0.25,0-0.76-0.6-0.7-0.72c0.17-0.35,0.54-0.8,0.87-0.83
	C425.83,271.64,426.17,272.12,426.72,272.56z"
              />
              <path
                d="M333.54,293.85c0.31,0.59,0.64,0.96,0.63,1.32c0,0.23-0.59,0.69-0.77,0.62c-0.36-0.13-0.85-0.54-0.85-0.84
	C332.55,294.65,333.07,294.34,333.54,293.85z"
              />
              <path
                d="M348.9,140.79c0.43,0.49,0.93,0.8,0.92,1.1c-0.01,0.31-0.49,0.81-0.81,0.84c-0.27,0.03-0.79-0.45-0.84-0.76
	C348.13,141.68,348.56,141.31,348.9,140.79z"
              />
              <path
                d="M480.72,271.4c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C479.69,270.64,480.1,271.06,480.72,271.4z"
              />
              <path
                d="M285.25,100.41c-0.43,0.38-0.78,0.85-0.95,0.79c-0.35-0.11-0.72-0.46-0.86-0.81c-0.06-0.15,0.45-0.77,0.64-0.74
	C284.44,99.7,284.76,100.06,285.25,100.41z"
              />
              <path
                d="M389.93,155.18c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C389.1,156.1,389.56,155.72,389.93,155.18z"
              />
              <path
                d="M250.4,197.57c-0.59,0.39-0.96,0.84-1.27,0.8c-0.3-0.04-0.65-0.54-0.77-0.9c-0.05-0.15,0.49-0.69,0.72-0.67
	C249.46,196.84,249.8,197.2,250.4,197.57z"
              />
              <path
                d="M441.03,271.1c-0.48,0.45-0.79,0.96-1.08,0.95c-0.31-0.01-0.73-0.47-0.85-0.82c-0.06-0.18,0.4-0.78,0.62-0.78
	C440.09,270.44,440.46,270.78,441.03,271.1z"
              />
              <path
                d="M271.99,361.81c-0.63,0.34-1.06,0.75-1.37,0.68c-0.3-0.07-0.57-0.62-0.65-1c-0.03-0.14,0.57-0.61,0.81-0.56
	C271.15,360.99,271.45,361.38,271.99,361.81z"
              />
              <path
                d="M264.77,298.09c-0.19-0.37-0.52-0.75-0.52-1.12c0-0.22,0.53-0.44,0.82-0.65c0.25,0.34,0.64,0.66,0.71,1.04
	c0.06,0.34-0.24,0.74-0.38,1.12C265.19,298.34,264.98,298.22,264.77,298.09z"
              />
              <path
                d="M240.89,271.68c-0.33-0.54-0.74-0.92-0.69-1.22c0.05-0.3,0.6-0.75,0.88-0.71c0.31,0.04,0.78,0.56,0.77,0.86
	C241.84,270.92,241.34,271.21,240.89,271.68z"
              />
              <path
                d="M469.87,297.24c0.63-0.31,1.07-0.69,1.39-0.61c0.29,0.07,0.56,0.64,0.61,1.02c0.02,0.15-0.59,0.6-0.83,0.54
	C470.68,298.1,470.39,297.69,469.87,297.24z"
              />
              <path
                d="M275.9,172.18c0.44,0.5,0.93,0.82,0.93,1.13c0,0.3-0.46,0.71-0.81,0.86c-0.15,0.06-0.75-0.41-0.75-0.64
	C275.26,173.15,275.59,172.78,275.9,172.18z"
              />
              <path
                d="M353.93,111.3c0.27,0.59,0.58,0.97,0.56,1.33c-0.01,0.22-0.53,0.41-0.82,0.62c-0.22-0.35-0.58-0.68-0.62-1.05
	C353.02,111.99,353.5,111.72,353.93,111.3z"
              />
              <path
                d="M461.28,268.14c0.38,0.58,0.82,0.97,0.77,1.26c-0.06,0.31-0.57,0.63-0.94,0.74c-0.13,0.04-0.66-0.53-0.63-0.77
	C460.52,269.01,460.9,268.68,461.28,268.14z"
              />
              <path
                d="M390.88,288.84c0.4,0.44,0.87,0.75,0.82,0.95c-0.07,0.36-0.45,0.66-0.7,0.99c-0.28-0.22-0.79-0.45-0.79-0.67
	C390.23,289.76,390.57,289.4,390.88,288.84z"
              />
              <path
                d="M283.42,174.53c-0.59-1.08-0.93-1.45-0.94-1.82c0-0.22,0.52-0.45,0.8-0.68c0.26,0.34,0.67,0.65,0.74,1.02
	C284.08,173.38,283.75,173.77,283.42,174.53z"
              />
              <path
                d="M264.53,291.15c-0.38-0.57-0.75-0.89-0.78-1.25c-0.02-0.25,0.51-0.81,0.64-0.76c0.37,0.13,0.86,0.45,0.93,0.77
	C265.37,290.19,264.91,290.58,264.53,291.15z"
              />
              <path
                d="M437.79,288.37c0.33,0.54,0.67,0.88,0.7,1.25c0.01,0.21-0.49,0.46-0.75,0.69c-0.26-0.32-0.65-0.6-0.74-0.96
	C436.95,289.14,437.4,288.82,437.79,288.37z"
              />
              <path
                d="M290.62,300.25c-0.59-1.08-0.93-1.45-0.94-1.82c0-0.22,0.52-0.45,0.8-0.68c0.26,0.34,0.67,0.65,0.74,1.02
	C291.28,299.09,290.95,299.48,290.62,300.25z"
              />
              <path
                d="M336.76,276.64c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C335.71,275.92,336.14,276.32,336.76,276.64z"
              />
              <path
                d="M331.47,298.78c-0.51,0.41-0.84,0.86-1.16,0.86c-0.31,0-0.61-0.48-0.91-0.75c0.27-0.3,0.52-0.81,0.82-0.84
	C330.54,298.01,330.91,298.43,331.47,298.78z"
              />
              <path
                d="M475.54,269.3c-0.33-0.59-0.68-0.94-0.69-1.3c-0.01-0.22,0.56-0.7,0.74-0.65c0.36,0.12,0.87,0.51,0.88,0.8
	C476.49,268.46,475.99,268.78,475.54,269.3z"
              />
              <path
                d="M283.12,298.16c0.45,0.52,0.97,0.85,0.94,1.14c-0.03,0.31-0.52,0.69-0.89,0.81c-0.17,0.06-0.75-0.43-0.74-0.66
	C282.45,299.09,282.79,298.74,283.12,298.16z"
              />
              <path
                d="M450.05,267.85c-0.3-0.6-0.63-0.97-0.63-1.34c0-0.22,0.6-0.67,0.78-0.61c0.36,0.13,0.84,0.55,0.84,0.84
	C451.04,267.05,450.53,267.36,450.05,267.85z"
              />
              <path
                d="M287.22,95.4c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C286.45,96.41,286.88,96,287.22,95.4z"
              />
              <path
                d="M342.04,141.25c0.38,0.56,0.75,0.88,0.78,1.23c0.02,0.24-0.51,0.77-0.67,0.73c-0.38-0.1-0.89-0.43-0.95-0.75
	C341.13,142.19,341.62,141.8,342.04,141.25z"
              />
              <path
                d="M294.38,94.93c0.43,0.5,0.83,0.79,0.9,1.14c0.05,0.24-0.41,0.85-0.55,0.83c-0.38-0.07-0.94-0.34-1.02-0.64
	C293.64,95.96,294.06,95.53,294.38,94.93z"
              />
              <path
                d="M298.58,171.73c-0.56,0.37-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.61-0.73,0.95-0.75
	C297.73,170.87,298.06,171.33,298.58,171.73z"
              />
              <path
                d="M493.19,288.16c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C492.14,287.43,492.57,287.84,493.19,288.16z"
              />
              <path
                d="M371.8,140.09c-0.62,0.35-1.03,0.77-1.34,0.71c-0.3-0.06-0.6-0.6-0.69-0.97c-0.03-0.14,0.55-0.63,0.79-0.59
	C370.93,139.29,371.24,139.67,371.8,140.09z"
              />
              <path
                d="M342.61,95.69c-0.51,0.32-0.84,0.67-1.2,0.71c-0.2,0.02-0.68-0.62-0.62-0.75c0.16-0.34,0.53-0.68,0.89-0.8
	C341.85,94.79,342.18,95.28,342.61,95.69z"
              />
              <path
                d="M435.02,301.64c-0.31-0.56-0.64-0.92-0.65-1.28c0-0.22,0.51-0.45,0.79-0.67c0.25,0.33,0.63,0.64,0.7,1
	C435.9,300.89,435.43,301.2,435.02,301.64z"
              />
              <path
                d="M452.03,289.45c-0.33-0.59-0.68-0.94-0.69-1.3c-0.01-0.22,0.57-0.7,0.74-0.65c0.36,0.12,0.87,0.51,0.88,0.8
	C452.98,288.61,452.48,288.94,452.03,289.45z"
              />
              <path
                d="M331.9,139.74c-0.52,0.39-0.82,0.78-1.18,0.84c-0.22,0.04-0.78-0.47-0.74-0.65c0.08-0.37,0.4-0.91,0.7-0.96
	C330.95,138.91,331.34,139.37,331.9,139.74z"
              />
              <path
                d="M328.71,143.52c-0.47,0.4-0.75,0.8-1.11,0.88c-0.21,0.05-0.8-0.53-0.76-0.66c0.14-0.37,0.45-0.77,0.81-0.93
	C327.82,142.73,328.22,143.17,328.71,143.52z"
              />
              <path
                d="M307.81,94.67c0.5-0.36,0.81-0.74,1.18-0.8c0.19-0.03,0.73,0.59,0.67,0.74c-0.14,0.36-0.5,0.73-0.86,0.85
	C308.63,95.52,308.27,95.05,307.81,94.67z"
              />
              <path
                d="M473.03,264.86c-0.58,0.35-0.96,0.76-1.28,0.72c-0.3-0.04-0.53-0.57-0.8-0.88c0.31-0.26,0.63-0.72,0.94-0.71
	C472.21,263.99,472.52,264.44,473.03,264.86z"
              />
              <path
                d="M368.43,143.73c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C367.38,143,367.81,143.4,368.43,143.73z"
              />
              <path
                d="M467.81,300.09c0.35,0.52,0.78,0.89,0.73,1.18c-0.05,0.31-0.57,0.79-0.84,0.76c-0.32-0.04-0.8-0.53-0.81-0.84
	C466.88,300.89,467.38,300.58,467.81,300.09z"
              />
              <path
                d="M345.17,139.73c-0.39-0.49-0.85-0.82-0.83-1.12c0.02-0.31,0.5-0.83,0.77-0.83c0.32,0.01,0.84,0.46,0.88,0.76
	C346.02,138.84,345.55,139.2,345.17,139.73z"
              />
              <path
                d="M317.64,93.82c-0.9,0.75-1.26,1.22-1.44,1.16c-0.36-0.13-0.62-0.54-0.92-0.83c0.33-0.27,0.62-0.69,0.99-0.77
	C316.61,93.3,317.02,93.6,317.64,93.82z"
              />
              <path
                d="M494.7,278.4c-0.44-0.48-0.94-0.78-0.94-1.09c0-0.3,0.47-0.81,0.78-0.85c0.27-0.03,0.81,0.43,0.86,0.73
	C495.46,277.49,495.04,277.86,494.7,278.4z"
              />
              <path
                d="M328.13,302.58c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C327.1,301.83,327.51,302.24,328.13,302.58z"
              />
              <path
                d="M297.94,90.88c0.35,0.52,0.78,0.89,0.74,1.18c-0.05,0.31-0.57,0.79-0.84,0.77c-0.32-0.03-0.79-0.53-0.81-0.84
	C297.01,91.69,297.5,91.37,297.94,90.88z"
              />
              <path
                d="M320.08,302.09c0.14,0.37,0.44,0.77,0.39,1.11c-0.06,0.37-0.44,0.69-0.69,1.04c-0.29-0.21-0.82-0.43-0.83-0.65
	c-0.01-0.37,0.32-0.75,0.5-1.13C319.67,302.34,319.87,302.21,320.08,302.09z"
              />
              <path
                d="M395.19,286c-0.41,0.43-0.71,0.93-0.91,0.9c-0.37-0.07-0.79-0.4-0.96-0.73c-0.08-0.15,0.38-0.84,0.57-0.83
	C394.26,285.35,394.63,285.69,395.19,286z"
              />
              <path
                d="M355.54,119.7c-0.43,0.33-0.81,0.78-0.98,0.72c-0.35-0.14-0.6-0.56-0.89-0.86c0.34-0.26,0.65-0.64,1.02-0.72
	C354.88,118.79,355.18,119.31,355.54,119.7z"
              />
              <path
                d="M268.78,120.08c-0.54,0.39-0.85,0.77-1.2,0.82c-0.23,0.03-0.78-0.5-0.74-0.66c0.1-0.37,0.42-0.87,0.74-0.94
	C267.85,119.23,268.24,119.7,268.78,120.08z"
              />
              <path
                d="M227.58,351.29c-0.64,0.32-1.02,0.65-1.37,0.63c-0.25-0.01-0.74-0.63-0.67-0.75c0.18-0.35,0.56-0.78,0.89-0.81
	C226.72,350.35,227.04,350.83,227.58,351.29z"
              />
              <path d="M627.48,634.09c-0.61,0-1.23,0-1.95,0C626.42,633.01,626.42,633.01,627.48,634.09z" />
              <path
                d="M305.16,549.19c0.36,0.46,0.79,0.79,0.73,0.98c-0.11,0.35-0.49,0.61-0.76,0.91c-0.26-0.24-0.76-0.51-0.73-0.71
	C304.43,550.02,304.8,549.7,305.16,549.19z"
              />
              <path
                d="M310.09,260.99c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C309.24,260.11,309.57,260.58,310.09,260.99z"
              />
              <path
                d="M441.15,284.53c0.33,0.54,0.67,0.88,0.7,1.25c0.01,0.21-0.49,0.46-0.75,0.69c-0.26-0.32-0.65-0.6-0.74-0.96
	C440.31,285.3,440.76,284.98,441.15,284.53z"
              />
              <path
                d="M432.81,304.44c-0.47,0.34-0.86,0.77-1.02,0.69c-0.34-0.15-0.66-0.53-0.78-0.89c-0.05-0.14,0.52-0.71,0.72-0.66
	C432.08,303.66,432.36,304.05,432.81,304.44z"
              />
              <path
                d="M230.31,260.6c-0.44,0.38-0.71,0.78-1.06,0.86c-0.19,0.05-0.76-0.51-0.72-0.64c0.12-0.36,0.43-0.74,0.77-0.91
	C229.45,259.84,229.85,260.27,230.31,260.6z"
              />
              <path
                d="M319.34,215.37c0.43,0.5,0.83,0.79,0.9,1.14c0.05,0.24-0.41,0.85-0.55,0.83c-0.38-0.07-0.94-0.34-1.02-0.64
	C318.59,216.4,319.01,215.97,319.34,215.37z"
              />
              <path
                d="M389.96,308.28c-0.57,0.35-0.98,0.79-1.26,0.72c-0.3-0.08-0.6-0.62-0.67-0.99c-0.03-0.17,0.55-0.67,0.77-0.62
	C389.16,307.46,389.45,307.86,389.96,308.28z"
              />
              <path
                d="M328.59,404.87c-0.39-0.56-0.85-0.94-0.8-1.23c0.06-0.32,0.55-0.65,0.91-0.78c0.13-0.05,0.67,0.5,0.65,0.75
	C329.32,403.97,328.96,404.31,328.59,404.87z"
              />
              <path
                d="M415.33,309.68c-0.47,0.36-0.85,0.81-1.03,0.74c-0.35-0.14-0.69-0.52-0.82-0.88c-0.05-0.15,0.51-0.74,0.7-0.7
	C414.55,308.92,414.85,309.3,415.33,309.68z"
              />
              <path
                d="M345.87,144.6c0.38,0.56,0.75,0.88,0.78,1.23c0.02,0.24-0.51,0.77-0.67,0.73c-0.38-0.1-0.89-0.43-0.95-0.75
	C344.97,145.54,345.46,145.16,345.87,144.6z"
              />
              <path
                d="M493.87,284.38c0.56-0.35,0.94-0.76,1.26-0.73c0.3,0.03,0.55,0.55,0.82,0.85c-0.31,0.27-0.61,0.75-0.92,0.75
	C494.71,285.25,494.39,284.79,493.87,284.38z"
              />
              <path
                d="M455.42,285.39c-0.34-0.59-0.68-0.95-0.7-1.31c-0.01-0.24,0.57-0.75,0.7-0.7c0.36,0.15,0.83,0.51,0.86,0.83
	C456.32,284.51,455.84,284.86,455.42,285.39z"
              />
              <path
                d="M250.49,284.29c-0.49,0.34-0.88,0.78-1.05,0.71c-0.35-0.15-0.68-0.54-0.81-0.91c-0.05-0.14,0.54-0.72,0.74-0.67
	C249.74,283.49,250.03,283.89,250.49,284.29z"
              />
              <path
                d="M309.2,401.9c0.48-0.44,0.75-0.85,1.11-0.95c0.21-0.06,0.81,0.41,0.79,0.59c-0.05,0.38-0.32,0.94-0.62,1.03
	C310.21,402.65,309.78,402.22,309.2,401.9z"
              />
              <path
                d="M259.21,313.44c-0.37-0.56-0.73-0.89-0.76-1.25c-0.02-0.23,0.52-0.75,0.69-0.71c0.38,0.1,0.9,0.45,0.94,0.75
	C260.12,312.53,259.62,312.9,259.21,313.44z"
              />
              <path
                d="M290.58,313c0.48-0.35,0.79-0.72,1.15-0.78c0.19-0.03,0.71,0.57,0.65,0.72c-0.14,0.35-0.49,0.71-0.84,0.83
	C291.37,313.83,291.02,313.37,290.58,313z"
              />
              <path
                d="M395.5,314.52c0.63-0.31,1.07-0.69,1.39-0.61c0.29,0.07,0.56,0.64,0.61,1.02c0.02,0.15-0.59,0.6-0.83,0.54
	C396.3,315.37,396.02,314.97,395.5,314.52z"
              />
              <path
                d="M307.32,398.39c-0.43,0.38-0.78,0.85-0.95,0.79c-0.35-0.11-0.72-0.46-0.86-0.81c-0.06-0.15,0.45-0.77,0.64-0.74
	C306.52,397.68,306.84,398.04,307.32,398.39z"
              />
              <path
                d="M485.19,281.79c-0.54,0.39-0.85,0.77-1.2,0.82c-0.23,0.03-0.78-0.5-0.74-0.66c0.1-0.37,0.42-0.87,0.74-0.94
	C484.26,280.94,484.65,281.41,485.19,281.79z"
              />
              <path
                d="M339.84,146.14c-0.5,0.44-0.82,0.92-1.11,0.91c-0.33-0.01-0.65-0.47-0.97-0.73c0.28-0.31,0.51-0.8,0.84-0.86
	C338.88,145.4,339.27,145.81,339.84,146.14z"
              />
              <path
                d="M360.68,137.25c-0.48,0.43-0.75,0.84-1.11,0.93c-0.22,0.06-0.81-0.41-0.79-0.58c0.05-0.38,0.32-0.95,0.61-1.03
	C359.67,136.48,360.1,136.92,360.68,137.25z"
              />
              <path
                d="M289.67,316.9c-0.56,0.35-0.97,0.8-1.25,0.73c-0.31-0.07-0.61-0.61-0.68-0.98c-0.03-0.18,0.55-0.68,0.76-0.63
	C288.87,316.1,289.17,316.49,289.67,316.9z"
              />
              <path
                d="M371.86,155.56c-0.44-0.48-0.94-0.78-0.94-1.09c0-0.3,0.47-0.81,0.78-0.85c0.27-0.03,0.81,0.43,0.86,0.73
	C372.62,154.65,372.2,155.03,371.86,155.56z"
              />
              <path
                d="M232.77,342.32c0.44,0.48,0.93,0.8,0.93,1.1c-0.01,0.31-0.49,0.81-0.8,0.84c-0.27,0.03-0.8-0.45-0.85-0.76
	C232,343.21,232.43,342.84,232.77,342.32z"
              />
              <path
                d="M322.16,220.04c0.48-0.44,0.75-0.85,1.11-0.95c0.21-0.06,0.81,0.41,0.79,0.59c-0.05,0.38-0.32,0.94-0.62,1.03
	C323.16,220.79,322.73,220.36,322.16,220.04z"
              />
              <path
                d="M371.36,146.06c0.35,0.52,0.78,0.89,0.73,1.18c-0.05,0.31-0.57,0.79-0.84,0.76c-0.32-0.04-0.8-0.53-0.81-0.84
	C370.43,146.86,370.93,146.55,371.36,146.06z"
              />
              <path
                d="M365.3,147.54c-0.54,0.46-0.87,0.95-1.16,0.93c-0.33-0.03-0.71-0.47-0.89-0.82c-0.06-0.12,0.43-0.72,0.68-0.73
	C364.29,146.9,364.66,147.23,365.3,147.54z"
              />
              <path
                d="M226.39,629.58c-0.62,0.33-1.03,0.74-1.34,0.67c-0.29-0.06-0.56-0.61-0.64-0.98c-0.03-0.14,0.56-0.6,0.79-0.56
	C225.56,628.79,225.86,629.17,226.39,629.58z"
              />
              <path
                d="M317.73,233.65c0.26,0.59,0.57,0.98,0.55,1.35c-0.01,0.22-0.55,0.41-0.85,0.62c-0.23-0.35-0.59-0.69-0.63-1.06
	C316.78,234.33,317.28,234.06,317.73,233.65z"
              />
              <path
                d="M317.29,220.68c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C316.44,219.81,316.77,220.27,317.29,220.68z"
              />
              <path
                d="M305.01,535.72c-0.39,0.37-0.61,0.73-0.78,0.71c-0.31-0.05-0.58-0.32-0.87-0.49c0.17-0.22,0.33-0.6,0.51-0.61
	C304.17,535.3,304.48,535.51,305.01,535.72z"
              />
              <path
                d="M335.26,136c-0.56,0.35-0.97,0.8-1.25,0.73c-0.31-0.07-0.61-0.61-0.68-0.98c-0.03-0.18,0.54-0.68,0.76-0.63
	C334.45,135.19,334.74,135.59,335.26,136z"
              />
              <path
                d="M331.59,232.42c0.45,0.52,0.97,0.85,0.94,1.14c-0.03,0.31-0.52,0.69-0.89,0.81c-0.17,0.06-0.75-0.43-0.74-0.66
	C330.91,233.35,331.25,233,331.59,232.42z"
              />
              <path
                d="M257.79,283.54c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C256.94,282.67,257.27,283.13,257.79,283.54z"
              />
              <path
                d="M319.95,224.65c-0.19-0.36-0.51-0.73-0.51-1.09c0-0.21,0.52-0.43,0.8-0.64c0.24,0.34,0.62,0.65,0.68,1.02
	c0.06,0.32-0.24,0.71-0.38,1.07C320.35,224.89,320.15,224.77,319.95,224.65z"
              />
              <path d="M628.14,338.8c0-0.7,0-1.41,0-2.59c0.96,1.09,0.79,1.84,0.56,2.59C628.51,338.8,628.33,338.8,628.14,338.8z" />
              <path
                d="M349.71,134.83c-0.58,0.34-0.97,0.76-1.28,0.71c-0.3-0.05-0.53-0.57-0.79-0.89c0.32-0.25,0.63-0.71,0.95-0.71
	C348.9,133.95,349.21,134.41,349.71,134.83z"
              />
              <path
                d="M343.68,149.49c-0.5,0.44-0.81,0.93-1.1,0.92c-0.33-0.01-0.65-0.47-0.98-0.73c0.27-0.31,0.51-0.8,0.83-0.87
	C342.72,148.76,343.11,149.17,343.68,149.49z"
              />
              <path
                d="M393.28,151.34c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C392.46,152.26,392.92,151.88,393.28,151.34z"
              />
              <path
                d="M338.55,132.02c-0.46,0.38-0.76,0.78-1.12,0.86c-0.2,0.04-0.78-0.53-0.73-0.67c0.13-0.37,0.45-0.77,0.8-0.92
	C337.67,131.22,338.06,131.67,338.55,132.02z"
              />
              <path
                d="M299.41,278.71c0.44,0.5,0.93,0.82,0.93,1.13c0,0.3-0.46,0.71-0.81,0.86c-0.15,0.06-0.75-0.41-0.75-0.64
	C298.78,279.68,299.1,279.31,299.41,278.71z"
              />
              <path
                d="M371.32,132.72c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C370.28,131.97,370.7,132.38,371.32,132.72z"
              />
              <path
                d="M367.85,149.9c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C367.03,150.82,367.48,150.44,367.85,149.9z"
              />
              <path
                d="M331.26,132.61c-0.42,0.33-0.83,0.79-0.95,0.72c-0.34-0.18-0.57-0.58-0.84-0.9c0.29-0.22,0.57-0.61,0.86-0.61
	C330.6,131.83,330.86,132.25,331.26,132.61z"
              />
              <path
                d="M328.86,230.61c-0.46,0.28-0.79,0.65-1.06,0.61c-0.28-0.05-0.49-0.49-0.73-0.76c0.32-0.26,0.6-0.63,0.97-0.74
	C328.19,229.67,328.51,230.21,328.86,230.61z"
              />
              <path
                d="M240.4,328.07c-0.96,0.68-1.39,1.12-1.52,1.04c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C239.35,327.55,239.73,327.81,240.4,328.07z"
              />
              <path
                d="M218.16,629.12c0.36,0.43,0.76,0.69,0.77,0.96c0.01,0.3-0.34,0.61-0.53,0.92c-0.31-0.26-0.74-0.48-0.89-0.81
	C217.44,630,217.85,629.6,218.16,629.12z"
              />
              <path
                d="M333.65,127.67c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C332.82,128.45,333.29,128.1,333.65,127.67z"
              />
              <path d="M615.67,233.27c0.4,0.72,0.67,1.22,1.02,1.85C615.49,234.87,615.49,234.87,615.67,233.27z" />
              <path
                d="M242.11,325.59c-0.3-0.5-0.65-0.82-0.62-1.1c0.04-0.29,0.45-0.53,0.69-0.79c0.27,0.32,0.66,0.6,0.76,0.96
	C243,324.85,242.51,325.17,242.11,325.59z"
              />
              <path
                d="M246.33,320.74c-0.44,0.32-0.86,0.76-0.98,0.69c-0.34-0.19-0.55-0.6-0.81-0.93c0.3-0.21,0.6-0.59,0.88-0.58
	C245.69,319.94,245.94,320.36,246.33,320.74z"
              />
              <path
                d="M247.65,316.79c0.43-0.33,0.85-0.79,0.97-0.72c0.34,0.19,0.56,0.6,0.83,0.92c-0.29,0.22-0.58,0.61-0.86,0.6
	C248.32,317.58,248.06,317.16,247.65,316.79z"
              />
              <path
                d="M307.26,158.86c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C306.43,159.64,306.9,159.29,307.26,158.86z"
              />
              <path
                d="M407.07,309.05c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C406.24,309.83,406.7,309.48,407.07,309.05z"
              />
              <path
                d="M412.83,280.71c-0.36,0.39-0.68,0.92-0.82,0.88c-0.37-0.12-0.65-0.49-0.97-0.75c0.24-0.27,0.46-0.71,0.73-0.75
	C412.05,280.05,412.37,280.42,412.83,280.71z"
              />
              <path
                d="M389.33,301.03c-0.42,0.33-0.83,0.79-0.95,0.72c-0.34-0.18-0.57-0.58-0.84-0.9c0.29-0.22,0.57-0.61,0.86-0.61
	C388.66,300.25,388.92,300.67,389.33,301.03z"
              />
              <path
                d="M304.93,575c-0.07,0.59-0.14,1.17-0.21,1.76c-0.22-0.02-0.43-0.04-0.65-0.05c0.03-0.59,0.06-1.17,0.08-1.76
	C304.41,574.96,304.67,574.98,304.93,575z"
              />
              <path d="M487.51,25.77c-1.14,0.7-1.57,0.34-1.52-1.01C486.62,25.17,487.07,25.47,487.51,25.77z" />
              <path
                d="M334.38,95.04c0.33,0.46,0.77,0.85,0.7,1.02c-0.15,0.35-0.57,0.58-0.88,0.85c-0.21-0.3-0.58-0.6-0.57-0.89
	C333.63,95.74,334.03,95.47,334.38,95.04z"
              />
              <path
                d="M443.78,273.54c0.33,0.46,0.77,0.85,0.7,1.02c-0.15,0.35-0.57,0.58-0.88,0.85c-0.21-0.3-0.58-0.6-0.57-0.89
	C443.03,274.24,443.43,273.97,443.78,273.54z"
              />
              <path
                d="M291.17,98.88c0.34,0.45,0.78,0.83,0.72,1c-0.14,0.35-0.56,0.58-0.86,0.87c-0.21-0.29-0.59-0.58-0.59-0.87
	C290.44,99.6,290.83,99.32,291.17,98.88z"
              />
              <path
                d="M256.69,142.38c-0.95,0.67-1.37,1.1-1.5,1.03c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C255.66,141.85,256.04,142.12,256.69,142.38z"
              />
              <path
                d="M297.95,290.95c-0.44,0.34-0.84,0.8-0.97,0.73c-0.34-0.18-0.56-0.58-0.83-0.9c0.29-0.22,0.58-0.62,0.86-0.62
	C297.28,290.16,297.54,290.59,297.95,290.95z"
              />
              <path d="M226.42,179.19c0.15-0.83,0.25-1.4,0.38-2.13c0.32,0.27,0.6,0.39,0.62,0.53C227.46,178.16,227.43,178.17,226.42,179.19z" />
              <path
                d="M308.3,33.08c-0.21,0.39-0.42,0.79-0.65,1.16c-0.01,0.02-0.39-0.11-0.39-0.15c0.05-0.45,0.15-0.89,0.23-1.33
	C307.76,32.87,308.03,32.97,308.3,33.08z"
              />
              <path
                d="M438.09,274.8c-0.96,0.68-1.39,1.12-1.52,1.04c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C437.05,274.28,437.43,274.55,438.09,274.8z"
              />
              <path
                d="M275.09,373.34c-0.38-0.45-0.85-0.8-0.79-0.97c0.12-0.35,0.47-0.74,0.82-0.86c0.17-0.06,0.8,0.46,0.77,0.64
	C275.83,372.51,275.46,372.83,275.09,373.34z"
              />
              <path
                d="M278.8,361.16c-0.4,0.37-0.71,0.85-0.92,0.81c-0.38-0.08-0.7-0.46-1.05-0.72c0.32-0.28,0.61-0.68,0.98-0.78
	C278.03,360.41,278.37,360.84,278.8,361.16z"
              />
              <path
                d="M364.28,140.75c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C364.54,140.33,364.41,140.54,364.28,140.75z"
              />
              <path
                d="M323.7,139.47c0.39,0.43,0.8,0.69,0.8,0.95c0,0.26-0.39,0.72-0.64,0.74c-0.26,0.02-0.71-0.35-0.77-0.61
	C323.04,140.3,323.4,139.97,323.7,139.47z"
              />
              <path
                d="M332.22,279.56c0.36,0.46,0.81,0.84,0.75,0.98c-0.16,0.34-0.53,0.66-0.89,0.78c-0.15,0.05-0.71-0.49-0.67-0.67
	C331.49,280.3,331.86,280.02,332.22,279.56z"
              />
              <path
                d="M223.36,353.52c0.21,0.57,0.49,0.96,0.43,1.28c-0.07,0.35-0.45,0.64-0.7,0.95c-0.3-0.28-0.7-0.52-0.85-0.86
	C222.16,354.75,222.63,354.38,223.36,353.52z"
              />
              <path
                d="M452.49,281.43c-0.62,0.16-1.04,0.38-1.38,0.31c-0.21-0.05-0.31-0.56-0.45-0.87c0.37-0.16,0.74-0.45,1.11-0.44
	C451.96,280.43,452.14,280.93,452.49,281.43z"
              />
              <path
                d="M274.57,366.09c-0.36-0.45-0.8-0.8-0.73-0.96c0.13-0.34,0.49-0.69,0.83-0.8c0.16-0.05,0.74,0.47,0.71,0.65
	C275.3,365.33,274.93,365.62,274.57,366.09z"
              />
              <path
                d="M305.7,543.17c-0.55,0.29-0.84,0.54-1.14,0.55c-0.15,0.01-0.44-0.44-0.43-0.67c0.02-0.23,0.38-0.63,0.52-0.6
	C304.94,542.52,305.19,542.81,305.7,543.17z"
              />
              <path
                d="M239.43,281.73c-0.53,0.26-0.88,0.57-1.15,0.51c-0.26-0.06-0.44-0.5-0.65-0.78c0.28-0.21,0.57-0.59,0.85-0.58
	C238.75,280.89,238.99,281.31,239.43,281.73z"
              />
              <path
                d="M405.55,281.82c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C405.8,281.4,405.68,281.61,405.55,281.82z"
              />
              <path
                d="M444.3,280.55c0.33,0.44,0.71,0.74,0.69,1c-0.02,0.27-0.45,0.51-0.69,0.76c-0.28-0.29-0.66-0.54-0.8-0.88
	C443.44,281.29,443.93,280.94,444.3,280.55z"
              />
              <path
                d="M279.35,368.67c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C279.6,368.25,279.48,368.46,279.35,368.67z"
              />
              <path
                d="M222.54,347c0.33,0.42,0.78,0.78,0.71,0.95c-0.12,0.34-0.53,0.57-0.82,0.84c-0.21-0.28-0.58-0.55-0.58-0.83
	C221.86,347.69,222.23,347.41,222.54,347z"
              />
              <path
                d="M272.18,369.15c-0.38,0.18-0.77,0.49-1.15,0.48c-0.21,0-0.41-0.54-0.62-0.83c0.35-0.23,0.68-0.59,1.05-0.65
	c0.34-0.05,0.73,0.24,1.1,0.38C272.43,368.74,272.3,368.95,272.18,369.15z"
              />
              <path
                d="M437.15,280.74c0.52,1.03,0.85,1.38,0.79,1.65c-0.06,0.27-0.51,0.44-0.79,0.66c-0.22-0.29-0.59-0.56-0.61-0.86
	C436.52,281.85,436.81,281.49,437.15,280.74z"
              />
              <path
                d="M303.37,529.65c-0.28-0.47-0.53-0.71-0.51-0.92c0.02-0.23,0.3-0.43,0.47-0.65c0.18,0.2,0.46,0.39,0.49,0.62
	C303.86,528.91,303.62,529.16,303.37,529.65z"
              />
              <path
                d="M118.08,631.67c-0.42,0.33-0.66,0.63-0.95,0.68c-0.17,0.03-0.61-0.39-0.58-0.54c0.06-0.31,0.35-0.58,0.55-0.87
	C117.38,631.15,117.64,631.35,118.08,631.67z"
              />
              <path
                d="M477.92,282.58c-0.42,0.31-0.76,0.74-0.97,0.68c-0.36-0.1-0.64-0.5-0.96-0.77c0.34-0.25,0.65-0.62,1.03-0.7
	C477.22,281.75,477.52,282.21,477.92,282.58z"
              />
              <path
                d="M325.52,227.28c-0.71,0.29-1.08,0.57-1.41,0.54c-0.3-0.03-0.77-0.37-0.8-0.63c-0.03-0.25,0.34-0.73,0.61-0.8
	C324.17,226.34,324.52,226.7,325.52,227.28z"
              />
              <path
                d="M470.74,283.01c-0.41,0.34-0.75,0.8-0.96,0.74c-0.38-0.09-0.68-0.49-1.02-0.76c0.34-0.26,0.65-0.66,1.03-0.74
	C470,282.2,470.33,282.66,470.74,283.01z"
              />
              <path
                d="M225.7,344.94c-0.36-0.39-0.77-0.63-0.79-0.89c-0.01-0.28,0.36-0.58,0.56-0.87c0.33,0.25,0.66,0.51,0.99,0.76
	C226.25,344.22,226.04,344.5,225.7,344.94z"
              />
              <path
                d="M366.77,135.58c0.38,0.47,0.86,0.88,0.79,1.01c-0.18,0.35-0.56,0.69-0.93,0.81c-0.15,0.05-0.73-0.51-0.69-0.7
	C366.01,136.34,366.4,136.04,366.77,135.58z"
              />
              <path
                d="M473.35,277.67c0.21,0.59,0.5,0.99,0.44,1.31c-0.07,0.36-0.46,0.65-0.72,0.98c-0.31-0.29-0.72-0.53-0.88-0.88
	C472.13,278.94,472.61,278.55,473.35,277.67z"
              />
              <path
                d="M326.95,137.64c-0.36-0.38-0.83-0.72-0.78-0.86c0.13-0.34,0.5-0.59,0.78-0.87c0.24,0.25,0.66,0.48,0.69,0.75
	C327.66,136.91,327.27,137.2,326.95,137.64z"
              />
              <path
                d="M324.26,146.64c0.35,0.47,0.71,0.77,0.77,1.11c0.03,0.19-0.54,0.7-0.69,0.65c-0.35-0.12-0.71-0.46-0.85-0.8
	C323.43,147.46,323.88,147.09,324.26,146.64z"
              />
              <path
                d="M230.26,340.49c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C229.98,340.06,230.12,340.27,230.26,340.49z"
              />
              <path
                d="M242.36,283.66c0.28,0.51,0.62,0.87,0.56,1.13c-0.06,0.27-0.52,0.45-0.81,0.66c-0.21-0.29-0.58-0.58-0.58-0.87
	C241.55,284.32,241.97,284.07,242.36,283.66z"
              />
              <path
                d="M449.89,284.81c-1.02,0.52-1.39,0.85-1.78,0.86c-0.21,0.01-0.43-0.52-0.65-0.81c0.34-0.24,0.66-0.63,1.03-0.69
	C448.8,284.13,449.18,284.46,449.89,284.81z"
              />
              <path
                d="M316.79,226.74c0.39,0.49,0.77,0.79,0.85,1.16c0.04,0.18-0.57,0.72-0.75,0.67c-0.35-0.11-0.72-0.48-0.86-0.83
	C315.97,227.57,316.43,227.2,316.79,226.74z"
              />
              <path
                d="M556.93,85.4c-0.28-0.36-0.55-0.71-0.83-1.07c0.13-0.1,0.27-0.2,0.4-0.31c0.27,0.36,0.54,0.72,0.81,1.08
	C557.18,85.21,557.06,85.3,556.93,85.4z"
              />
              <path
                d="M342.84,135.11c-0.99,0.5-1.35,0.82-1.73,0.83c-0.21,0-0.43-0.51-0.64-0.8c0.33-0.24,0.64-0.62,1-0.68
	C341.78,134.43,342.14,134.77,342.84,135.11z"
              />
              <path
                d="M441.43,278.37c-0.43,0.38-0.7,0.79-0.97,0.8c-0.26,0-0.74-0.4-0.75-0.64c-0.02-0.27,0.35-0.74,0.62-0.79
	C440.59,277.68,440.93,278.05,441.43,278.37z"
              />
              <path
                d="M402.3,285.3c-0.44,0.38-0.7,0.78-0.96,0.78c-0.28,0-0.55-0.39-0.83-0.6c0.23-0.27,0.43-0.7,0.7-0.75
	C401.46,284.67,401.8,285.01,402.3,285.3z"
              />
              <path
                d="M303.46,91.44c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C304.87,92.29,304.5,91.97,303.46,91.44z"
              />
              <path
                d="M344.62,90.5c0.37,0.47,0.73,0.76,0.8,1.11c0.04,0.17-0.54,0.7-0.71,0.64c-0.34-0.11-0.7-0.46-0.83-0.8
	C343.83,91.3,344.27,90.95,344.62,90.5z"
              />
              <path
                d="M225.06,377.56c-0.37-0.39-0.85-0.73-0.79-0.87c0.13-0.34,0.5-0.59,0.78-0.87c0.24,0.25,0.65,0.48,0.67,0.75
	C225.75,376.82,225.37,377.12,225.06,377.56z"
              />
              <path
                d="M293.63,167.31c0.33,0.44,0.71,0.74,0.69,1c-0.02,0.27-0.45,0.51-0.69,0.76c-0.28-0.29-0.66-0.54-0.8-0.88
	C292.77,168.05,293.26,167.7,293.63,167.31z"
              />
              <path
                d="M330.57,94.06c-0.4-0.47-0.79-0.76-0.9-1.12c-0.05-0.16,0.55-0.74,0.73-0.69c0.36,0.09,0.74,0.46,0.89,0.8
	C331.37,93.21,330.92,93.59,330.57,94.06z"
              />
              <path
                d="M447.65,278.73c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C448.37,277.99,447.98,278.29,447.65,278.73z"
              />
              <path
                d="M474.65,286.62c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C474.9,286.2,474.77,286.41,474.65,286.62z"
              />
              <path
                d="M235.56,333.41c-0.36-0.38-0.83-0.73-0.78-0.86c0.13-0.34,0.51-0.58,0.78-0.86c0.24,0.25,0.65,0.48,0.68,0.75
	C236.27,332.69,235.88,332.98,235.56,333.41z"
              />
              <path
                d="M261.53,286.79c-0.4,0.37-0.71,0.85-0.92,0.81c-0.38-0.08-0.7-0.46-1.05-0.72c0.32-0.28,0.61-0.68,0.98-0.78
	C260.75,286.03,261.1,286.47,261.53,286.79z"
              />
              <path
                d="M301.33,286.8c-0.47,0.35-0.75,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C300.53,286.12,300.84,286.48,301.33,286.8z"
              />
              <path
                d="M322.13,93.55c0.57-0.4,0.88-0.76,1.23-0.81c0.22-0.03,0.68,0.41,0.7,0.66c0.02,0.26-0.37,0.78-0.58,0.78
	C323.12,94.18,322.76,93.87,322.13,93.55z"
              />
              <path
                d="M321.16,150.46c0.35,0.5,0.72,0.81,0.77,1.17c0.03,0.18-0.58,0.69-0.75,0.62c-0.34-0.12-0.68-0.5-0.8-0.85
	C320.32,151.24,320.78,150.9,321.16,150.46z"
              />
              <path
                d="M259.59,471.03c-0.42,0.34-0.69,0.69-0.85,0.64c-0.31-0.09-0.55-0.4-0.83-0.61c0.19-0.21,0.4-0.6,0.58-0.6
	C258.79,470.47,259.08,470.75,259.59,471.03z"
              />
              <path
                d="M253.5,288.36c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.76,0.44,0.73,0.62
	C254.19,287.57,253.84,287.88,253.5,288.36z"
              />
              <path
                d="M455.68,277.48c-0.36,0.18-0.72,0.49-1.07,0.48c-0.23-0.01-0.43-0.5-0.65-0.78c0.34-0.24,0.65-0.6,1.02-0.67
	c0.32-0.06,0.7,0.23,1.05,0.37C455.91,277.08,455.79,277.28,455.68,277.48z"
              />
              <path
                d="M333.23,286.34c0.21,0.58,0.49,0.97,0.43,1.29c-0.07,0.35-0.45,0.64-0.7,0.96c-0.3-0.28-0.7-0.52-0.85-0.86
	C332.04,287.57,332.51,287.2,333.23,286.34z"
              />
              <path
                d="M302.76,95.34c-0.38,0.35-0.69,0.81-0.88,0.77c-0.36-0.08-0.67-0.44-1-0.69c0.31-0.27,0.58-0.66,0.93-0.75
	C302.01,94.61,302.34,95.03,302.76,95.34z"
              />
              <path
                d="M327.21,95.83c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C326.39,96.55,326.86,96.21,327.21,95.83z"
              />
              <path
                d="M344.67,132.42c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.75,0.43,0.73,0.62
	C345.37,131.62,345.01,131.93,344.67,132.42z"
              />
              <path
                d="M319.99,96.29c0.33,0.47,0.72,0.78,0.68,1.03c-0.04,0.26-0.48,0.65-0.75,0.65c-0.25-0.01-0.66-0.44-0.68-0.7
	C319.23,97.01,319.63,96.73,319.99,96.29z"
              />
              <path
                d="M225.77,384.63c-0.32-0.36-0.64-0.56-0.65-0.78c-0.01-0.23,0.27-0.49,0.42-0.73c0.27,0.21,0.61,0.38,0.78,0.65
	C226.37,383.88,226.03,384.23,225.77,384.63z"
              />
              <path
                d="M444.96,289.85c-0.38-0.45-0.85-0.8-0.79-0.97c0.12-0.35,0.47-0.74,0.82-0.86c0.17-0.06,0.8,0.46,0.77,0.64
	C445.68,289.02,445.32,289.34,444.96,289.85z"
              />
              <path
                d="M312.7,99.08c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C313.47,98.33,313.07,98.62,312.7,99.08z"
              />
              <path
                d="M352.62,99.26c-0.36-0.44-0.76-0.72-0.74-0.97c0.02-0.27,0.44-0.7,0.69-0.7c0.26,0,0.7,0.39,0.74,0.66
	C353.34,98.49,352.95,98.8,352.62,99.26z"
              />
              <path
                d="M305.49,97.75c0.36,0.38,0.83,0.72,0.78,0.86c-0.12,0.34-0.5,0.59-0.77,0.87c-0.25-0.24-0.66-0.47-0.69-0.74
	C304.79,98.49,305.17,98.19,305.49,97.75z"
              />
              <path
                d="M299.41,99.05c-0.4,0.42-0.7,0.92-0.87,0.87c-0.37-0.1-0.68-0.46-1.01-0.71c0.26-0.25,0.48-0.65,0.78-0.71
	C298.58,98.46,298.92,98.79,299.41,99.05z"
              />
              <path
                d="M422.48,274.82c0.21,0.58,0.49,0.97,0.43,1.29c-0.07,0.35-0.45,0.64-0.7,0.96c-0.3-0.28-0.7-0.52-0.85-0.86
	C421.29,276.06,421.76,275.68,422.48,274.82z"
              />
              <path
                d="M340.65,129.02c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C341.36,128.28,340.97,128.58,340.65,129.02z"
              />
              <path
                d="M479.17,289.61c-1.02,0.52-1.39,0.85-1.78,0.86c-0.21,0.01-0.43-0.52-0.65-0.81c0.34-0.24,0.66-0.63,1.03-0.69
	C478.07,288.92,478.45,289.26,479.17,289.61z"
              />
              <path
                d="M296.96,163.47c0.33,0.47,0.72,0.78,0.68,1.03c-0.04,0.26-0.48,0.65-0.75,0.65c-0.25-0.01-0.66-0.44-0.68-0.7
	C296.2,164.19,296.6,163.91,296.96,163.47z"
              />
              <path
                d="M347.89,128.6c-0.42-0.45-0.82-0.72-0.96-1.08c-0.06-0.15,0.52-0.76,0.71-0.72c0.36,0.08,0.77,0.42,0.94,0.76
	C348.66,127.71,348.22,128.13,347.89,128.6z"
              />
              <path
                d="M471.29,290.46c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C471.54,290.04,471.42,290.25,471.29,290.46z"
              />
              <path
                d="M258.35,290.72c-0.48,0.31-0.79,0.67-1.06,0.63c-0.27-0.04-0.5-0.46-0.74-0.71c0.27-0.23,0.52-0.63,0.8-0.65
	C257.61,289.98,257.89,290.37,258.35,290.72z"
              />
              <path
                d="M469.24,276.88c-0.4-0.47-0.79-0.76-0.9-1.12c-0.05-0.16,0.55-0.74,0.73-0.69c0.36,0.09,0.74,0.46,0.89,0.8
	C470.04,276.03,469.59,276.41,469.24,276.88z"
              />
              <path
                d="M429.29,274.79c0.36,0.39,0.78,0.63,0.79,0.89c0.02,0.28-0.35,0.58-0.55,0.87c-0.33-0.25-0.73-0.45-0.96-0.78
	C428.52,275.68,428.96,275.24,429.29,274.79z"
              />
              <path
                d="M330.6,291.59c-0.47,0.35-0.75,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C329.8,290.92,330.11,291.28,330.6,291.59z"
              />
              <path
                d="M287.4,169.12c-0.5,0.32-0.83,0.69-1.09,0.64c-0.26-0.05-0.64-0.52-0.62-0.78c0.02-0.25,0.48-0.65,0.75-0.65
	C286.7,168.33,286.97,168.75,287.4,169.12z"
              />
              <path
                d="M302.92,274.76c0.34,0.49,0.7,0.8,0.75,1.15c0.03,0.18-0.57,0.67-0.73,0.61c-0.33-0.13-0.67-0.49-0.78-0.83
	C302.1,275.52,302.55,275.19,302.92,274.76z"
              />
              <path
                d="M302.46,101.57c0.33,0.47,0.71,0.78,0.67,1.03c-0.04,0.27-0.49,0.66-0.74,0.65c-0.25-0.01-0.67-0.44-0.69-0.7
	C301.69,102.29,302.09,102.01,302.46,101.57z"
              />
              <path
                d="M336.69,125.95c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C337.47,125.2,337.06,125.49,336.69,125.95z"
              />
              <path
                d="M329.62,237.78c-0.51,0.29-0.85,0.65-1.11,0.59c-0.26-0.06-0.62-0.54-0.59-0.8c0.03-0.25,0.51-0.62,0.77-0.61
	C328.96,236.97,329.21,237.39,329.62,237.78z"
              />
              <path
                d="M333.48,240.79c-0.41,0.33-0.73,0.78-0.94,0.73c-0.37-0.09-0.67-0.48-1-0.74c0.33-0.26,0.63-0.64,1.01-0.73
	C332.76,239.99,333.08,240.44,333.48,240.79z"
              />
              <path
                d="M312.44,19.21c0.4-0.19,0.8-0.38,1.2-0.57c0.08,0.2,0.25,0.57,0.23,0.58c-0.38,0.21-0.79,0.38-1.2,0.56
	C312.59,19.6,312.52,19.4,312.44,19.21z"
              />
              <path
                d="M477.5,275.14c-0.43,0.46-0.71,0.96-0.91,0.93c-0.39-0.06-0.72-0.42-1.08-0.65c0.22-0.29,0.41-0.8,0.65-0.82
	C476.52,274.56,476.91,274.88,477.5,275.14z"
              />
              <path
                d="M345.16,124.53c-0.35,0.14-0.72,0.43-1.03,0.38c-0.37-0.07-0.68-0.44-1.01-0.67c0.22-0.27,0.43-0.77,0.66-0.77
	c0.35-0.01,0.71,0.31,1.07,0.49C344.94,124.14,345.05,124.33,345.16,124.53z"
              />
              <path
                d="M111.04,632.39c-0.48,0.3-0.79,0.64-1.06,0.61c-0.26-0.03-0.48-0.44-0.72-0.68c0.26-0.22,0.51-0.6,0.78-0.61
	C110.3,631.7,110.58,632.06,111.04,632.39z"
              />
              <path
                d="M352.24,123.89c-0.58,0.25-0.96,0.55-1.31,0.52c-0.23-0.02-0.42-0.53-0.62-0.81c0.35-0.22,0.69-0.57,1.06-0.62
	C351.56,122.95,351.82,123.44,352.24,123.89z"
              />
              <path
                d="M319.07,242.01c-0.48,0.34-0.79,0.73-1.06,0.69c-0.26-0.03-0.67-0.49-0.66-0.75c0.01-0.26,0.45-0.68,0.71-0.69
	C318.34,241.25,318.62,241.65,319.07,242.01z"
              />
              <path
                d="M296.48,103.45c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C296.2,103.02,296.34,103.23,296.48,103.45z"
              />
              <path
                d="M281.88,104.66c-0.49,0.26-0.83,0.59-1.1,0.55c-0.28-0.05-0.5-0.46-0.75-0.71c0.33-0.25,0.63-0.62,0.99-0.7
	C281.19,103.76,281.49,104.25,281.88,104.66z"
              />
              <path
                d="M403.56,313.13c0.42,0.45,0.83,0.72,0.96,1.08c0.06,0.15-0.52,0.76-0.71,0.72c-0.36-0.08-0.76-0.42-0.94-0.76
	C402.79,314.01,403.23,313.6,403.56,313.13z"
              />
              <path
                d="M353.12,106.49c-0.38-0.45-0.76-0.73-0.85-1.08c-0.04-0.17,0.53-0.72,0.7-0.67c0.34,0.1,0.71,0.44,0.86,0.77
	C353.89,105.67,353.46,106.04,353.12,106.49z"
              />
              <path
                d="M282.83,313.61c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C284.24,314.46,283.86,314.13,282.83,313.61z"
              />
              <path
                d="M238.97,274.56c-0.56,0.24-0.94,0.54-1.29,0.51c-0.22-0.02-0.41-0.52-0.61-0.8c0.34-0.22,0.67-0.56,1.04-0.61
	C238.31,273.63,238.57,274.11,238.97,274.56z"
              />
              <path
                d="M300.11,399.12c-0.42,0.32-0.71,0.7-0.99,0.7c-0.28,0-0.56-0.38-0.84-0.59c0.28-0.29,0.52-0.71,0.85-0.82
	C299.33,398.35,299.68,398.8,300.11,399.12z"
              />
              <path
                d="M292.18,398.61c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C291.36,399.33,291.83,398.99,292.18,398.61z"
              />
              <path
                d="M452.01,273.89c-0.44,0.33-0.73,0.7-1,0.7c-0.29-0.01-0.56-0.39-0.85-0.6c0.28-0.29,0.53-0.7,0.87-0.81
	C451.21,273.11,451.57,273.55,452.01,273.89z"
              />
              <path
                d="M253.07,312.79c-0.48,0.31-0.79,0.67-1.06,0.63c-0.27-0.04-0.5-0.46-0.74-0.71c0.27-0.23,0.52-0.63,0.8-0.65
	C252.33,312.05,252.61,312.44,253.07,312.79z"
              />
              <path
                d="M299.92,312.09c-0.41,0.44-0.68,0.91-0.87,0.89c-0.37-0.05-0.69-0.4-1.03-0.62c0.2-0.28,0.4-0.78,0.62-0.79
	C298.98,311.54,299.36,311.84,299.92,312.09z"
              />
              <path
                d="M231.71,401.4c-0.47,0.29-0.82,0.66-1.05,0.6c-0.26-0.07-0.58-0.55-0.56-0.83c0.02-0.25,0.5-0.66,0.73-0.63
	C231.1,400.58,231.33,401,231.71,401.4z"
              />
              <path
                d="M314.92,244.97c0.36,0.51,0.73,0.83,0.79,1.19c0.03,0.19-0.6,0.7-0.77,0.64c-0.34-0.12-0.7-0.51-0.81-0.86
	C314.07,245.76,314.54,245.42,314.92,244.97z"
              />
              <path
                d="M401.08,311.22c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C400.81,310.79,400.94,311,401.08,311.22z"
              />
              <path
                d="M434.34,292.29c0.34,0.45,0.73,0.74,0.71,1.01c-0.01,0.28-0.42,0.54-0.66,0.81c-0.26-0.25-0.7-0.48-0.73-0.75
	C433.64,293.08,434.02,292.76,434.34,292.29z"
              />
              <path
                d="M332.71,273.4c-0.41,0.32-0.69,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.58c0.27-0.28,0.51-0.69,0.84-0.8
	C331.94,272.65,332.29,273.08,332.71,273.4z"
              />
              <path
                d="M334.38,247.92c-0.61,0.4-0.95,0.76-1.32,0.8c-0.22,0.03-0.69-0.45-0.7-0.72c-0.01-0.27,0.41-0.79,0.63-0.78
	C333.36,247.24,333.73,247.58,334.38,247.92z"
              />
              <path
                d="M262.17,294.19c-0.48,0.26-0.8,0.58-1.07,0.54c-0.28-0.04-0.5-0.45-0.74-0.69c0.32-0.25,0.61-0.61,0.97-0.7
	C261.5,293.3,261.79,293.79,262.17,294.19z"
              />
              <path
                d="M316.01,159.31c-0.73,0.34-1.09,0.67-1.4,0.62c-0.36-0.05-0.68-0.43-1.01-0.67c0.21-0.28,0.43-0.8,0.64-0.8
	C314.61,158.47,314.98,158.79,316.01,159.31z"
              />
              <path
                d="M291.83,106.11c0.34,0.45,0.73,0.74,0.71,1.01c-0.01,0.28-0.42,0.54-0.66,0.81c-0.26-0.25-0.7-0.48-0.73-0.75
	C291.13,106.9,291.51,106.58,291.83,106.11z"
              />
              <path
                d="M287.75,310.69c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C288.52,309.94,288.11,310.23,287.75,310.69z"
              />
              <path
                d="M422.51,308.74c-0.4,0.42-0.67,0.89-0.87,0.87c-0.36-0.04-0.68-0.39-1.02-0.61c0.2-0.29,0.38-0.8,0.59-0.81
	C421.58,308.19,421.95,308.49,422.51,308.74z"
              />
              <path
                d="M317.8,154.3c0.35,0.5,0.72,0.81,0.77,1.17c0.03,0.18-0.58,0.69-0.75,0.62c-0.34-0.12-0.68-0.5-0.8-0.85
	C316.96,155.08,317.42,154.74,317.8,154.3z"
              />
              <path
                d="M468.25,293.99c-0.61,0.4-0.95,0.76-1.32,0.8c-0.22,0.03-0.69-0.45-0.7-0.72c-0.01-0.27,0.41-0.79,0.63-0.78
	C467.24,293.3,467.61,293.64,468.25,293.99z"
              />
              <path
                d="M256.23,308.87c-0.47,0.35-0.76,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C255.42,308.2,255.74,308.56,256.23,308.87z"
              />
              <path
                d="M428.67,309.5c-0.37-0.47-0.76-0.76-0.84-1.12c-0.04-0.19,0.54-0.75,0.69-0.7c0.37,0.12,0.75,0.46,0.93,0.81
	C429.53,308.62,429.05,309.03,428.67,309.5z"
              />
              <path
                d="M301.95,309.23c-0.16-0.3-0.51-0.65-0.44-0.89c0.07-0.27,0.54-0.62,0.79-0.59c0.25,0.03,0.59,0.49,0.62,0.78
	c0.03,0.34-0.23,0.71-0.37,1.07C302.35,309.48,302.15,309.36,301.95,309.23z"
              />
              <path
                d="M315.25,252.24c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C314.61,253.09,314.96,252.74,315.25,252.24z"
              />
              <path
                d="M307.78,307.85c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C309.19,308.7,308.81,308.37,307.78,307.85z"
              />
              <path
                d="M321.45,403.33c0.35,0.47,0.8,0.85,0.73,1.01c-0.15,0.35-0.54,0.71-0.89,0.8c-0.19,0.05-0.78-0.53-0.73-0.69
	C320.66,404.09,321.05,403.8,321.45,403.33z"
              />
              <path
                d="M395.86,306.47c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C395.22,307.31,395.58,306.96,395.86,306.47z"
              />
              <path
                d="M335.17,255.05c-1.01,0.52-1.37,0.83-1.74,0.84c-0.21,0-0.42-0.5-0.63-0.78c0.33-0.24,0.63-0.61,0.99-0.66
	C334.1,254.4,334.46,254.72,335.17,255.05z"
              />
              <path
                d="M286.56,296.56c-0.37-0.46-0.82-0.83-0.76-1c0.14-0.35,0.51-0.72,0.86-0.83c0.18-0.05,0.78,0.49,0.75,0.67
	C287.33,295.77,286.95,296.07,286.56,296.56z"
              />
              <path
                d="M326.47,296.48c-0.36-0.39-0.77-0.63-0.79-0.89c-0.01-0.28,0.36-0.58,0.56-0.87c0.33,0.25,0.66,0.51,0.99,0.76
	C327.02,295.76,326.8,296.04,326.47,296.48z"
              />
              <path
                d="M312.07,258.14c-0.37-0.46-0.75-0.75-0.82-1.1c-0.04-0.19,0.53-0.74,0.68-0.69c0.36,0.12,0.74,0.45,0.91,0.79
	C312.9,257.28,312.44,257.68,312.07,258.14z"
              />
              <path
                d="M472.26,273.05c-0.37-0.46-0.82-0.83-0.76-1c0.14-0.35,0.51-0.72,0.86-0.83c0.18-0.05,0.78,0.49,0.75,0.67
	C473.03,272.26,472.65,272.56,472.26,273.05z"
              />
              <path
                d="M308.03,405.57c-0.44,0.36-0.73,0.77-1,0.76c-0.26-0.01-0.71-0.44-0.71-0.69c-0.01-0.26,0.39-0.72,0.66-0.76
	C307.24,404.85,307.55,405.24,308.03,405.57z"
              />
              <path
                d="M278.66,108.34c-0.6,0.36-0.94,0.69-1.31,0.72c-0.22,0.01-0.65-0.46-0.65-0.72c0-0.26,0.43-0.74,0.65-0.72
	C277.72,107.64,278.06,107.98,278.66,108.34z"
              />
              <path
                d="M431.1,271.86c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C432.51,272.71,432.13,272.38,431.1,271.86z"
              />
              <path
                d="M307.28,271.96c-0.41,0.32-0.68,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.57c0.27-0.29,0.5-0.69,0.83-0.81
	C306.51,271.21,306.86,271.64,307.28,271.96z"
              />
              <path
                d="M424.77,306.07c-0.35-0.45-0.75-0.75-0.72-1c0.03-0.27,0.47-0.68,0.73-0.68c0.25,0,0.68,0.42,0.71,0.69
	C425.52,305.33,425.12,305.62,424.77,306.07z"
              />
              <path
                d="M250.45,157.76c-0.43,0.32-0.7,0.69-0.97,0.69c-0.28,0-0.55-0.37-0.82-0.58c0.27-0.28,0.5-0.69,0.84-0.81
	C249.67,157,250.02,157.44,250.45,157.76z"
              />
              <path
                d="M258.87,303.9c0.14,0.36,0.4,0.72,0.38,1.06c-0.03,0.29-0.37,0.75-0.61,0.78c-0.25,0.03-0.72-0.32-0.79-0.58
	c-0.06-0.24,0.28-0.59,0.44-0.9C258.48,304.14,258.68,304.02,258.87,303.9z"
              />
              <path
                d="M349.79,108.29c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C349.16,109.14,349.51,108.79,349.79,108.29z"
              />
              <path
                d="M255.44,175.21c-0.42,0.37-0.68,0.79-0.94,0.79c-0.28-0.01-0.74-0.4-0.76-0.65c-0.02-0.26,0.37-0.72,0.64-0.78
	C254.62,174.52,254.95,174.9,255.44,175.21z"
              />
              <path
                d="M233.72,269.99c0.37,0.42,0.78,0.69,0.78,0.94c0,0.26-0.39,0.72-0.64,0.74c-0.26,0.02-0.72-0.35-0.77-0.61
	C233.04,270.81,233.41,270.48,233.72,269.99z"
              />
              <path
                d="M246.85,201.43c-0.42,0.32-0.71,0.7-0.99,0.7c-0.28,0-0.56-0.38-0.84-0.59c0.28-0.29,0.52-0.71,0.85-0.82
	C246.06,200.66,246.42,201.1,246.85,201.43z"
              />
              <path
                d="M393.15,304.29c-0.42,0.37-0.68,0.79-0.94,0.79c-0.28-0.01-0.74-0.4-0.76-0.66c-0.02-0.26,0.37-0.72,0.64-0.78
	C392.34,303.59,392.67,303.98,393.15,304.29z"
              />
              <path
                d="M448.07,270.47c-0.6,0.39-0.93,0.75-1.29,0.79c-0.22,0.02-0.68-0.44-0.69-0.7c-0.01-0.26,0.4-0.78,0.62-0.77
	C447.08,269.81,447.44,270.14,448.07,270.47z"
              />
              <path
                d="M454.07,271.09c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.75,0.43,0.73,0.62
	C454.77,270.29,454.42,270.6,454.07,271.09z"
              />
              <path
                d="M242.64,204.27c0.36,0.38,0.83,0.72,0.78,0.86c-0.12,0.34-0.5,0.59-0.77,0.87c-0.25-0.24-0.66-0.47-0.69-0.74
	C241.93,205.01,242.31,204.71,242.64,204.27z"
              />
              <path
                d="M348.36,120.51c-0.5,0.25-0.83,0.58-1.1,0.53c-0.29-0.06-0.52-0.46-0.77-0.71c0.33-0.25,0.64-0.61,1.01-0.71
	C347.66,119.59,347.96,120.09,348.36,120.51z"
              />
              <path
                d="M274.43,111.18c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C273.6,111.91,274.07,111.57,274.43,111.18z"
              />
              <path
                d="M334.89,268.49c0.35,0.47,0.8,0.85,0.73,1.01c-0.15,0.35-0.54,0.71-0.89,0.8c-0.19,0.05-0.78-0.53-0.73-0.69
	C334.1,269.25,334.49,268.96,334.89,268.49z"
              />
              <path
                d="M335.4,262.43c-0.42,0.31-0.76,0.74-0.97,0.68c-0.36-0.1-0.64-0.5-0.96-0.77c0.34-0.25,0.65-0.62,1.03-0.7
	C334.7,261.59,335.01,262.06,335.4,262.43z"
              />
              <path
                d="M242,436.51c-0.38,0.3-0.65,0.65-0.8,0.61c-0.29-0.09-0.55-0.37-0.71-0.65c-0.04-0.08,0.36-0.58,0.51-0.56
	C241.3,435.96,241.57,436.23,242,436.51z"
              />
              <path
                d="M291.64,172.11c-0.6,0.39-0.93,0.75-1.29,0.79c-0.22,0.02-0.68-0.44-0.69-0.7c-0.01-0.26,0.4-0.78,0.62-0.77
	C290.65,171.44,291.01,171.77,291.64,172.11z"
              />
              <path d="M136.38,633.77c-0.67,0.05-1.13,0.08-1.61,0.11c0-0.31-0.06-0.52,0.01-0.61C135.45,632.46,135.87,632.78,136.38,633.77z" />
              <path
                d="M324.07,299.47c-0.48,0.26-0.8,0.58-1.07,0.53c-0.28-0.05-0.49-0.45-0.74-0.69c0.32-0.25,0.61-0.6,0.97-0.69
	C323.4,298.58,323.69,299.07,324.07,299.47z"
              />
              <path
                d="M240.23,209.34c-0.36,0.18-0.72,0.49-1.07,0.48c-0.23-0.01-0.43-0.5-0.65-0.78c0.34-0.24,0.65-0.6,1.02-0.67
	c0.33-0.06,0.72,0.21,1.08,0.33C240.48,208.92,240.35,209.13,240.23,209.34z"
              />
              <path
                d="M271.07,117.02c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C271.79,116.28,271.4,116.58,271.07,117.02z"
              />
              <path
                d="M351.42,116.49c-0.41,0.32-0.69,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.57c0.27-0.29,0.5-0.69,0.83-0.81
	C350.65,115.74,351.01,116.17,351.42,116.49z"
              />
              <path
                d="M262.94,301.4c-0.53,0.26-0.88,0.57-1.15,0.51c-0.26-0.06-0.44-0.5-0.65-0.78c0.28-0.21,0.57-0.59,0.85-0.58
	C262.26,300.56,262.5,300.98,262.94,301.4z"
              />
              <path
                d="M297.46,117.62c-0.55,0.24-0.92,0.54-1.16,0.46c-0.23-0.07-0.43-0.53-0.46-0.84c-0.01-0.14,0.47-0.49,0.63-0.44
	C296.77,116.89,297,117.21,297.46,117.62z"
              />
              <path
                d="M344.19,116.92c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C343.41,116.35,343.69,116.63,344.19,116.92z"
              />
              <path
                d="M428.94,301.55c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C428.36,300.72,428.54,301.08,428.94,301.55z"
              />
              <path
                d="M143.33,633.28c-0.49,0.24-0.77,0.5-1,0.46c-0.18-0.03-0.36-0.42-0.39-0.67c-0.02-0.11,0.36-0.4,0.5-0.37
	C142.69,632.74,142.9,632.98,143.33,633.28z"
              />
              <path
                d="M254.41,302.47c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.04,0.32-0.25,0.68-0.39,1.02C254.79,302.68,254.6,302.58,254.41,302.47z"
              />
              <path
                d="M396.3,300.22c-0.48,0.35-0.73,0.65-1.04,0.71c-0.17,0.04-0.62-0.36-0.6-0.5c0.06-0.31,0.3-0.76,0.53-0.8
	C395.46,299.59,395.79,299.93,396.3,300.22z"
              />
              <path
                d="M295.26,301.91c-0.48,0.36-0.79,0.77-1.02,0.72c-0.27-0.05-0.55-0.46-0.66-0.78c-0.04-0.11,0.39-0.57,0.59-0.56
	C294.46,301.32,294.75,301.61,295.26,301.91z"
              />
              <path
                d="M279.25,115.42c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C278.34,114.82,278.7,115.15,279.25,115.42z"
              />
              <path
                d="M330.01,266.11c0.51-0.27,0.8-0.54,1.1-0.55c0.2,0,0.61,0.47,0.56,0.57c-0.13,0.3-0.42,0.68-0.69,0.72
	C330.75,266.89,330.47,266.48,330.01,266.11z"
              />
              <path
                d="M269.58,140.44c0.33,0.5,0.71,0.84,0.66,1.08c-0.05,0.25-0.5,0.51-0.82,0.58c-0.13,0.03-0.56-0.42-0.53-0.6
	C268.95,141.18,269.25,140.92,269.58,140.44z"
              />
              <path
                d="M286.44,114.83c-0.5,0.33-0.83,0.72-1.08,0.67c-0.26-0.05-0.52-0.49-0.61-0.8c-0.04-0.13,0.41-0.57,0.6-0.54
	C285.66,114.2,285.94,114.49,286.44,114.83z"
              />
              <path
                d="M229.85,266.41c0.51,0.98,0.83,1.32,0.77,1.57c-0.06,0.26-0.49,0.43-0.76,0.64c-0.21-0.27-0.58-0.54-0.6-0.83
	C229.24,267.47,229.53,267.13,229.85,266.41z"
              />
              <path
                d="M290.26,118.06c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C289.41,117.44,289.76,117.79,290.26,118.06z"
              />
              <path
                d="M283.05,118.68c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C282.33,117.93,282.61,118.33,283.05,118.68z"
              />
              <path
                d="M310.44,268.22c-0.54,0.28-0.85,0.54-1.16,0.55c-0.19,0.01-0.6-0.46-0.55-0.58c0.12-0.3,0.42-0.72,0.67-0.74
	C309.66,267.44,309.95,267.84,310.44,268.22z"
              />
              <path
                d="M292.57,113.34c0.35,0.44,0.74,0.72,0.73,0.98c-0.02,0.27-0.43,0.52-0.67,0.78c-0.24-0.24-0.66-0.47-0.69-0.74
	C291.91,114.1,292.27,113.79,292.57,113.34z"
              />
              <path
                d="M226.18,263.61c0.26,0.45,0.55,0.74,0.57,1.05c0.01,0.18-0.4,0.39-0.63,0.59c-0.23-0.28-0.55-0.53-0.65-0.84
	C225.43,264.27,225.83,263.99,226.18,263.61z"
              />
              <path
                d="M300.79,113.71c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C300.05,112.96,300.33,113.34,300.79,113.71z"
              />
              <path
                d="M347.58,113.08c-0.48,0.36-0.78,0.75-1.02,0.72c-0.27-0.04-0.55-0.44-0.67-0.75c-0.04-0.1,0.39-0.57,0.59-0.56
	C346.77,112.5,347.06,112.78,347.58,113.08z"
              />
              <path
                d="M102.94,633.76c-0.38-0.43-0.7-0.65-0.78-0.94c-0.04-0.15,0.42-0.63,0.51-0.6c0.3,0.11,0.61,0.35,0.74,0.63
	C103.48,632.99,103.18,633.31,102.94,633.76z"
              />
              <path
                d="M423.94,296.89c0.4,0.46,0.82,0.73,0.8,0.96c-0.02,0.25-0.42,0.54-0.72,0.66c-0.11,0.04-0.55-0.33-0.56-0.52
	C423.46,297.71,423.7,297.41,423.94,296.89z"
              />
              <path
                d="M258.8,297.98c-0.52,0.27-0.83,0.54-1.13,0.54c-0.2,0-0.62-0.48-0.58-0.57c0.14-0.31,0.43-0.7,0.7-0.74
	C258.04,297.18,258.33,297.59,258.8,297.98z"
              />
              <path
                d="M274.56,120.13c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.05,0.32-0.23,0.69-0.37,1.04C274.96,120.36,274.76,120.24,274.56,120.13z"
              />
              <path
                d="M312.62,304.9c-0.39-0.43-0.81-0.68-0.81-0.94c0-0.27,0.39-0.54,0.61-0.82c0.26,0.22,0.69,0.41,0.74,0.68
	C313.21,304.08,312.88,304.41,312.62,304.9z"
              />
              <path
                d="M400.31,303.8c-0.52,0.23-0.83,0.47-1.11,0.45c-0.2-0.02-0.57-0.5-0.53-0.58c0.16-0.28,0.45-0.64,0.73-0.67
	C399.61,302.97,399.87,303.39,400.31,303.8z"
              />
              <path
                d="M282.61,111.58c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C281.7,110.99,282.06,111.31,282.61,111.58z"
              />
              <path
                d="M431.4,298.17c-0.38-0.41-0.79-0.66-0.79-0.91c0-0.28,0.37-0.55,0.58-0.83c0.28,0.21,0.72,0.39,0.78,0.65
	C432.03,297.33,431.68,297.67,431.4,298.17z"
              />
              <path
                d="M391.38,298.18c-0.28-0.46-0.63-0.78-0.59-1.04c0.04-0.27,0.45-0.48,0.71-0.72c0.23,0.26,0.64,0.52,0.65,0.79
	C392.15,297.46,391.75,297.74,391.38,298.18z"
              />
              <path
                d="M231.4,409.2c-0.18-0.38-0.37-0.62-0.39-0.88c-0.01-0.12,0.32-0.26,0.5-0.39c0.16,0.22,0.37,0.43,0.44,0.67
	C231.97,408.71,231.68,408.9,231.4,409.2z"
              />
              <path
                d="M301.28,120.99c-0.55,0.23-0.91,0.52-1.15,0.44c-0.22-0.08-0.41-0.53-0.43-0.83c-0.01-0.13,0.47-0.47,0.63-0.42
	C300.61,120.26,300.83,120.58,301.28,120.99z"
              />
              <path
                d="M289.51,111.08c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C288.8,110.37,289.04,110.7,289.51,111.08z"
              />
              <path
                d="M341.14,121.11c-0.53,0.31-0.88,0.67-1.11,0.61c-0.26-0.07-0.5-0.51-0.57-0.83c-0.03-0.13,0.44-0.55,0.63-0.51
	C340.4,120.44,340.66,120.76,341.14,121.11z"
              />
              <path
                d="M305.6,305.56c-0.33-0.46-0.63-0.71-0.68-1c-0.03-0.18,0.37-0.63,0.48-0.6c0.31,0.08,0.73,0.3,0.8,0.55
	C306.26,304.74,305.9,305.07,305.6,305.56z"
              />
              <path
                d="M296.72,110.43c-0.55,0.24-0.87,0.49-1.17,0.46c-0.21-0.02-0.6-0.52-0.55-0.6c0.17-0.3,0.47-0.67,0.76-0.7
	C295.98,109.57,296.25,110.01,296.72,110.43z"
              />
              <path
                d="M303.62,110.06c-0.3,0.12-0.61,0.35-0.89,0.32c-0.2-0.02-0.57-0.51-0.52-0.59c0.17-0.28,0.46-0.62,0.75-0.66
	c0.3-0.04,0.64,0.24,0.97,0.38C303.83,109.7,303.73,109.88,303.62,110.06z"
              />
              <path
                d="M300.81,406.21c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C299.93,405.62,300.28,405.94,300.81,406.21z"
              />
              <path
                d="M294.13,121.18c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C293.21,120.58,293.57,120.91,294.13,121.18z"
              />
              <path
                d="M291.29,306.81c-0.38-0.41-0.79-0.66-0.79-0.91c0-0.28,0.37-0.55,0.58-0.83c0.28,0.21,0.72,0.39,0.78,0.65
	C291.91,305.97,291.57,306.31,291.29,306.81z"
              />
              <path
                d="M417.43,306.56c-0.26-0.55-0.6-0.93-0.51-1.14c0.11-0.26,0.55-0.45,0.88-0.5c0.13-0.02,0.5,0.48,0.45,0.67
	C418.18,305.87,417.84,306.1,417.43,306.56z"
              />
              <path
                d="M411.39,306.38c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C410.68,305.67,410.92,306,411.39,306.38z"
              />
              <path
                d="M226.32,257.39c-0.33,0.29-0.55,0.48-0.77,0.67c-0.19-0.29-0.46-0.56-0.53-0.88c-0.03-0.12,0.42-0.51,0.61-0.48
	C225.85,256.73,226.03,257.08,226.32,257.39z"
              />
              <path
                d="M404.17,306.93c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C403.39,306.36,403.67,306.64,404.17,306.93z"
              />
              <path
                d="M245.59,445.68c0.48,0.93,0.73,1.19,0.69,1.41c-0.03,0.23-0.34,0.41-0.52,0.62c-0.2-0.26-0.51-0.5-0.58-0.8
	C245.13,446.67,245.35,446.36,245.59,445.68z"
              />
              <path
                d="M324.4,106.88c0.36,0.45,0.67,0.69,0.74,0.97c0.04,0.18-0.33,0.64-0.45,0.62c-0.31-0.06-0.74-0.26-0.82-0.51
	C323.79,107.75,324.12,107.4,324.4,106.88z"
              />
              <path
                d="M284.92,122.01c0.44-0.33,0.73-0.73,0.98-0.7c0.27,0.03,0.68,0.46,0.68,0.72c-0.01,0.26-0.42,0.67-0.7,0.7
	C285.64,122.76,285.35,122.36,284.92,122.01z"
              />
              <path
                d="M285.67,107.72c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C284.96,107.01,285.2,107.34,285.67,107.72z"
              />
              <path
                d="M254.94,294.46c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C254.16,293.89,254.44,294.17,254.94,294.46z"
              />
              <path
                d="M310.55,157.1c-0.32-0.46-0.7-0.77-0.67-1.03c0.03-0.27,0.46-0.49,0.71-0.73c0.23,0.26,0.63,0.51,0.65,0.78
	C311.26,156.38,310.88,156.66,310.55,157.1z"
              />
              <path
                d="M296.01,309.17c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C295.28,308.43,295.57,308.83,296.01,309.17z"
              />
              <path
                d="M428.44,294.34c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C427.88,293.54,428.05,293.89,428.44,294.34z"
              />
              <path
                d="M289.78,403.76c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C289.2,402.93,289.38,403.28,289.78,403.76z"
              />
              <path
                d="M295.82,401.98c0.4,0.46,0.82,0.73,0.8,0.96c-0.02,0.25-0.42,0.54-0.72,0.66c-0.11,0.04-0.55-0.33-0.56-0.52
	C295.34,402.79,295.58,402.49,295.82,401.98z"
              />
              <path
                d="M243.86,180.58c-0.12-0.3-0.35-0.61-0.33-0.9c0.02-0.19,0.48-0.55,0.58-0.5c0.29,0.15,0.66,0.43,0.69,0.71
	c0.04,0.32-0.23,0.68-0.36,1.02C244.24,180.8,244.05,180.69,243.86,180.58z"
              />
              <path
                d="M299.15,107.42c-0.39-0.48-0.81-0.78-0.78-1.01c0.04-0.26,0.46-0.55,0.78-0.66c0.12-0.04,0.58,0.37,0.57,0.56
	C299.7,106.62,299.43,106.92,299.15,107.42z"
              />
              <path
                d="M278.55,121.73c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C278.05,122.56,278.3,122.26,278.55,121.73z"
              />
              <path
                d="M395.82,293.2c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C394.98,292.58,395.32,292.93,395.82,293.2z"
              />
              <path
                d="M299.92,159.65c0.37,0.46,0.69,0.69,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C299.3,160.53,299.64,160.17,299.92,159.65z"
              />
              <path
                d="M346.86,106.25c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C346.3,105.44,346.47,105.79,346.86,106.25z"
              />
              <path
                d="M307.22,105.98c-0.47,0.29-0.75,0.57-1.04,0.59c-0.19,0.01-0.61-0.42-0.57-0.53c0.11-0.3,0.37-0.69,0.63-0.74
	C306.46,105.26,306.76,105.64,307.22,105.98z"
              />
              <path
                d="M330.06,244.77c-0.53,0.24-0.89,0.53-1.13,0.46c-0.22-0.07-0.42-0.52-0.45-0.82c-0.01-0.14,0.46-0.49,0.62-0.44
	C329.39,244.06,329.61,244.37,330.06,244.77z"
              />
              <path
                d="M294.07,161.21c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C293.34,160.46,293.61,160.85,294.07,161.21z"
              />
              <path
                d="M328.63,104.33c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C328.06,103.52,328.24,103.87,328.63,104.33z"
              />
              <path
                d="M288.12,102.79c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C287.33,103.47,287.74,103.21,288.12,102.79z"
              />
              <path
                d="M272.72,123.1c-0.47,0.39-0.76,0.8-1,0.77c-0.28-0.04-0.57-0.42-0.72-0.72c-0.04-0.09,0.37-0.58,0.57-0.58
	C271.88,122.56,272.19,122.83,272.72,123.1z"
              />
              <path
                d="M297.94,124.54c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C297.05,123.96,297.4,124.28,297.94,124.54z"
              />
              <path
                d="M343.06,102.93c-0.55,0.28-0.91,0.62-1.15,0.55c-0.25-0.07-0.47-0.54-0.51-0.86c-0.02-0.14,0.47-0.52,0.65-0.48
	C342.36,102.22,342.6,102.55,343.06,102.93z"
              />
              <path
                d="M291.1,125.72c-0.35,0.13-0.72,0.39-1.05,0.36c-0.3-0.03-0.56-0.38-0.84-0.59c0.21-0.28,0.38-0.72,0.64-0.79
	c0.24-0.06,0.58,0.28,0.88,0.44C290.85,125.34,290.97,125.53,291.1,125.72z"
              />
              <path
                d="M283.72,125.82c-0.42,0.35-0.7,0.76-0.94,0.74c-0.27-0.02-0.71-0.42-0.72-0.67c-0.01-0.25,0.38-0.69,0.65-0.73
	C282.95,125.12,283.26,125.5,283.72,125.82z"
              />
              <path
                d="M275.45,125.58c0.37,0.46,0.69,0.69,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C274.83,126.46,275.17,126.1,275.45,125.58z"
              />
              <path
                d="M349.3,101.09c0.3,0.52,0.66,0.88,0.59,1.11c-0.08,0.26-0.53,0.48-0.85,0.54c-0.14,0.02-0.54-0.45-0.5-0.63
	C348.61,101.81,348.93,101.56,349.3,101.09z"
              />
              <path
                d="M251.27,291.74c-0.35,0.13-0.72,0.39-1.05,0.36c-0.3-0.03-0.56-0.38-0.84-0.59c0.21-0.28,0.38-0.72,0.64-0.79
	c0.24-0.06,0.58,0.28,0.88,0.44C251.02,291.36,251.15,291.55,251.27,291.74z"
              />
              <path
                d="M310.41,101.85c-0.44,0.35-0.72,0.74-0.98,0.73c-0.26-0.01-0.68-0.43-0.69-0.68c0-0.26,0.39-0.69,0.65-0.73
	C309.64,101.14,309.95,101.52,310.41,101.85z"
              />
              <path
                d="M268.51,126.3c0.24,0.54,0.49,0.86,0.48,1.17c-0.01,0.2-0.52,0.58-0.63,0.53c-0.3-0.16-0.67-0.48-0.69-0.76
	C267.66,127.01,268.1,126.74,268.51,126.3z"
              />
              <path
                d="M322.81,100.57c0.51-0.27,0.8-0.54,1.1-0.55c0.2,0,0.61,0.47,0.56,0.57c-0.13,0.3-0.42,0.68-0.69,0.72
	C323.56,101.34,323.27,100.94,322.81,100.57z"
              />
              <path
                d="M253.5,153.96c-0.49,0.3-0.77,0.58-1.06,0.6c-0.19,0.01-0.63-0.43-0.59-0.53c0.11-0.3,0.38-0.71,0.64-0.75
	C252.72,153.24,253.03,153.62,253.5,153.96z"
              />
              <path
                d="M431.52,290.36c-0.54,0.23-0.85,0.48-1.14,0.45c-0.21-0.02-0.59-0.51-0.54-0.59c0.17-0.29,0.46-0.65,0.74-0.68
	C430.81,289.52,431.08,289.95,431.52,290.36z"
              />
              <path
                d="M330.92,100.96c-0.28-0.46-0.63-0.78-0.59-1.04c0.04-0.27,0.45-0.48,0.71-0.72c0.23,0.26,0.64,0.52,0.65,0.79
	C331.69,100.25,331.29,100.52,330.92,100.96z"
              />
              <path
                d="M301.95,128.34c-0.6,0.14-0.95,0.32-1.24,0.26c-0.19-0.04-0.46-0.59-0.39-0.67c0.2-0.26,0.56-0.56,0.83-0.54
	C301.37,127.41,301.56,127.86,301.95,128.34z"
              />
              <path
                d="M339.2,99.35c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C338.36,98.73,338.7,99.07,339.2,99.35z"
              />
              <path
                d="M293.87,129.5c-0.39-0.48-0.81-0.78-0.78-1.01c0.04-0.26,0.46-0.55,0.78-0.66c0.12-0.04,0.58,0.37,0.57,0.56
	C294.43,128.7,294.15,128.99,293.87,129.5z"
              />
              <path
                d="M287.56,129.14c-0.41,0.37-0.67,0.78-0.91,0.78c-0.27-0.01-0.73-0.38-0.76-0.63c-0.02-0.25,0.35-0.7,0.62-0.76
	C286.75,128.48,287.08,128.84,287.56,129.14z"
              />
              <path
                d="M345.1,99.5c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.04,0.32-0.25,0.68-0.39,1.02C345.48,99.71,345.29,99.6,345.1,99.5z"
              />
              <path
                d="M398.89,289.27c-0.49,0.3-0.77,0.58-1.06,0.6c-0.19,0.01-0.63-0.43-0.59-0.53c0.11-0.3,0.38-0.71,0.64-0.75
	C398.11,288.55,398.42,288.93,398.89,289.27z"
              />
              <path
                d="M326.46,128.47c0.28,0.48,0.55,0.76,0.58,1.05c0.02,0.18-0.41,0.59-0.52,0.56c-0.3-0.1-0.73-0.36-0.76-0.6
	C325.72,129.25,326.11,128.95,326.46,128.47z"
              />
              <path
                d="M280.4,129.87c-0.48,0.36-0.78,0.75-1.02,0.72c-0.27-0.04-0.55-0.44-0.67-0.75c-0.04-0.1,0.39-0.57,0.59-0.56
	C279.59,129.29,279.89,129.58,280.4,129.87z"
              />
              <path
                d="M273.17,130.35c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C272.39,129.78,272.67,130.06,273.17,130.35z"
              />
              <path
                d="M290.72,165.27c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C290.15,164.46,290.33,164.81,290.72,165.27z"
              />
              <path
                d="M265.09,130.14c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C264.3,130.82,264.71,130.56,265.09,130.14z"
              />
              <path
                d="M267.87,152.65c-0.52,0.23-0.83,0.47-1.11,0.45c-0.2-0.02-0.57-0.5-0.53-0.58c0.16-0.28,0.45-0.64,0.73-0.67
	C267.17,151.82,267.43,152.24,267.87,152.65z"
              />
              <path
                d="M682.45,581.89c0,0.49,0,0.98,0,1.47c-0.12,0.02-0.24,0.03-0.37,0.05c-0.08-0.5-0.16-0.99-0.23-1.49
	C682.05,581.91,682.25,581.9,682.45,581.89z"
              />
              <path
                d="M297.48,132.84c-0.26-0.54-0.58-0.91-0.5-1.12c0.1-0.25,0.54-0.44,0.86-0.5c0.12-0.02,0.48,0.47,0.43,0.65
	C298.2,132.17,297.87,132.39,297.48,132.84z"
              />
              <path
                d="M329.32,277.43c-0.55,0.22-0.87,0.46-1.16,0.43c-0.21-0.02-0.58-0.52-0.53-0.6c0.18-0.29,0.48-0.64,0.76-0.67
	C328.61,276.58,328.87,277.01,329.32,277.43z"
              />
              <path
                d="M326.71,288.45c-0.55,0.23-0.91,0.52-1.15,0.44c-0.22-0.08-0.41-0.53-0.43-0.83c-0.01-0.13,0.47-0.47,0.63-0.42
	C326.04,287.73,326.26,288.04,326.71,288.45z"
              />
              <path
                d="M322.13,230.78c-0.99,0.56-1.33,0.91-1.57,0.85c-0.27-0.07-0.44-0.51-0.65-0.78c0.28-0.21,0.54-0.57,0.83-0.6
	C321.08,230.22,321.43,230.49,322.13,230.78z"
              />
              <path
                d="M246.37,287.04c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C245.58,287.73,245.99,287.47,246.37,287.04z"
              />
              <path
                d="M349.76,95.22c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C349.18,94.39,349.36,94.75,349.76,95.22z"
              />
              <path
                d="M314.92,152.19c-0.55,0.22-0.87,0.46-1.16,0.43c-0.21-0.02-0.58-0.52-0.53-0.6c0.18-0.29,0.48-0.64,0.76-0.67
	C314.22,151.34,314.48,151.77,314.92,152.19z"
              />
              <path
                d="M275.06,152.1c-0.49,0.26-0.78,0.52-1.07,0.52c-0.2,0-0.59-0.46-0.55-0.55c0.13-0.3,0.41-0.67,0.67-0.71
	C274.33,151.33,274.62,151.73,275.06,152.1z"
              />
              <path
                d="M291.4,132.65c-0.47,0.3-0.8,0.67-1.04,0.62c-0.27-0.06-0.65-0.5-0.63-0.76c0.01-0.24,0.47-0.62,0.74-0.63
	C290.73,131.88,290.99,132.29,291.4,132.65z"
              />
              <path
                d="M284.21,132.98c-0.41,0.37-0.67,0.78-0.91,0.78c-0.27-0.01-0.73-0.38-0.76-0.63c-0.02-0.25,0.35-0.7,0.62-0.76
	C283.39,132.31,283.73,132.68,284.21,132.98z"
              />
              <path
                d="M322.96,132.31c0.36,0.45,0.67,0.69,0.74,0.97c0.04,0.18-0.33,0.64-0.45,0.62c-0.31-0.06-0.74-0.26-0.82-0.51
	C322.35,133.18,322.68,132.83,322.96,132.31z"
              />
              <path
                d="M277.04,133.94c-0.55,0.24-0.87,0.49-1.17,0.46c-0.21-0.02-0.6-0.52-0.55-0.6c0.17-0.3,0.47-0.67,0.76-0.7
	C276.31,133.09,276.58,133.52,277.04,133.94z"
              />
              <path
                d="M270.1,134.14c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C269.22,133.55,269.57,133.87,270.1,134.14z"
              />
              <path
                d="M211.98,630.7c-0.45,0.29-0.72,0.58-1.02,0.62c-0.16,0.02-0.58-0.47-0.54-0.56c0.15-0.28,0.41-0.58,0.7-0.68
	C211.28,630.03,211.57,630.38,211.98,630.7z"
              />
              <path
                d="M433.99,285.12c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C433.2,285.81,433.61,285.55,433.99,285.12z"
              />
              <path
                d="M262.93,135.08c-0.55,0.28-0.91,0.62-1.15,0.55c-0.25-0.07-0.47-0.54-0.51-0.86c-0.02-0.14,0.47-0.52,0.66-0.48
	C262.23,134.37,262.47,134.7,262.93,135.08z"
              />
              <path
                d="M234.4,277.2c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C233.91,278.03,234.15,277.73,234.4,277.2z"
              />
              <path
                d="M256.87,149.93c-0.52,0.29-0.88,0.65-1.1,0.58c-0.27-0.09-0.5-0.52-0.57-0.84c-0.03-0.13,0.46-0.54,0.66-0.5
	C256.16,149.23,256.4,149.56,256.87,149.93z"
              />
              <path d="M667.49,634.07c-0.85,0-1.39,0-2.08,0C666.35,633.44,666.35,633.44,667.49,634.07z" />
              <path
                d="M233.38,336.15c-0.47,0.39-0.76,0.8-1,0.77c-0.28-0.04-0.57-0.42-0.72-0.72c-0.04-0.09,0.37-0.58,0.57-0.58
	C232.54,335.61,232.84,335.88,233.38,336.15z"
              />
              <path
                d="M226.1,337.07c-0.59,0.14-0.92,0.32-1.21,0.25c-0.18-0.04-0.45-0.57-0.38-0.66c0.19-0.25,0.55-0.55,0.82-0.53
	C225.55,336.15,225.73,336.6,226.1,337.07z"
              />
              <path
                d="M295.25,135.86c-0.54,0.23-0.85,0.48-1.14,0.45c-0.21-0.02-0.59-0.51-0.54-0.59c0.17-0.29,0.46-0.65,0.74-0.68
	C294.53,135.02,294.8,135.44,295.25,135.86z"
              />
              <path
                d="M263.15,148.37c0.28,0.51,0.56,0.8,0.58,1.11c0.01,0.19-0.46,0.61-0.57,0.57c-0.31-0.12-0.72-0.41-0.75-0.67
	C262.37,149.14,262.77,148.84,263.15,148.37z"
              />
              <path
                d="M287.42,135.42c0.29,0.48,0.64,0.81,0.6,1.06c-0.05,0.26-0.49,0.46-0.76,0.68c-0.21-0.27-0.59-0.54-0.59-0.82
	C286.66,136.09,287.06,135.83,287.42,135.42z"
              />
              <path
                d="M270.57,147.88c0.12,0.31,0.35,0.63,0.32,0.93c-0.02,0.2-0.5,0.55-0.6,0.5c-0.29-0.15-0.66-0.45-0.69-0.72
	c-0.04-0.32,0.24-0.68,0.39-1.03C270.18,147.67,270.37,147.78,270.57,147.88z"
              />
              <path
                d="M409.46,284.73c-0.51,0.31-0.86,0.68-1.08,0.61c-0.27-0.08-0.52-0.5-0.6-0.82c-0.03-0.12,0.45-0.55,0.65-0.52
	C408.73,284.05,408.98,284.38,409.46,284.73z"
              />
              <path
                d="M328.06,284.35c0.52-0.28,0.82-0.55,1.13-0.56c0.2,0,0.62,0.48,0.57,0.58c-0.14,0.3-0.43,0.7-0.7,0.73
	C328.83,285.13,328.53,284.72,328.06,284.35z"
              />
              <path
                d="M318.12,148.33c-0.57,0.24-0.94,0.55-1.19,0.47c-0.24-0.07-0.44-0.54-0.47-0.86c-0.01-0.14,0.48-0.5,0.65-0.45
	C317.41,147.58,317.64,147.91,318.12,148.33z"
              />
              <path
                d="M434.2,278.94c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C433.48,278.2,433.76,278.6,434.2,278.94z"
              />
              <path
                d="M278.45,148.27c-0.51,0.26-0.8,0.53-1.1,0.53c-0.2,0-0.61-0.47-0.56-0.56c0.14-0.3,0.42-0.69,0.69-0.73
	C277.7,147.48,277.99,147.89,278.45,148.27z"
              />
              <path
                d="M307.7,279.05c-0.45,0.37-0.73,0.78-0.94,0.74c-0.27-0.04-0.55-0.41-0.69-0.7c-0.04-0.09,0.35-0.56,0.55-0.56
	C306.9,278.53,307.19,278.79,307.7,279.05z"
              />
              <path
                d="M222.79,340.9c-0.51,0.29-0.87,0.64-1.08,0.57c-0.27-0.09-0.5-0.51-0.57-0.82c-0.03-0.12,0.46-0.53,0.65-0.49
	C222.09,340.21,222.33,340.54,222.79,340.9z"
              />
              <path
                d="M281.14,136.88c-0.44,0.35-0.72,0.74-0.98,0.73c-0.26-0.01-0.68-0.43-0.69-0.68c0-0.26,0.39-0.69,0.65-0.73
	C280.37,136.16,280.68,136.55,281.14,136.88z"
              />
              <path
                d="M284.55,148.46c-0.37-0.46-0.69-0.7-0.76-1c-0.04-0.19,0.34-0.67,0.46-0.65c0.32,0.06,0.79,0.27,0.87,0.51
	C285.19,147.57,284.84,147.93,284.55,148.46z"
              />
              <path
                d="M292.63,146.82c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C291.9,146.07,292.17,146.45,292.63,146.82z"
              />
              <path
                d="M319.78,138.1c-0.37-0.46-0.78-0.75-0.75-0.98c0.04-0.25,0.45-0.52,0.75-0.63c0.11-0.04,0.54,0.36,0.53,0.55
	C320.3,137.32,320.04,137.61,319.78,138.1z"
              />
              <path
                d="M263.42,142.07c-0.47,0.39-0.71,0.71-1.02,0.79c-0.18,0.05-0.67-0.34-0.65-0.48c0.04-0.31,0.27-0.77,0.51-0.85
	C262.5,141.46,262.86,141.79,263.42,142.07z"
              />
              <path
                d="M429.96,282.01c0.37,0.46,0.69,0.7,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C429.34,282.89,429.68,282.53,429.96,282.01z"
              />
              <path
                d="M303.5,282c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C303,282.83,303.25,282.53,303.5,282z"
              />
              <path
                d="M273.01,136.6c0.3,0.52,0.66,0.88,0.59,1.11c-0.08,0.26-0.53,0.48-0.85,0.54c-0.14,0.02-0.54-0.45-0.5-0.63
	C272.31,137.31,272.64,137.07,273.01,136.6z"
              />
              <path
                d="M265.62,139.35c-0.26-0.53-0.53-0.83-0.53-1.15c0-0.19,0.48-0.59,0.6-0.54c0.3,0.12,0.71,0.43,0.73,0.69
	C266.42,138.61,266.01,138.89,265.62,139.35z"
              />
              <path
                d="M260.23,146.11c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C259.53,145.4,259.77,145.73,260.23,146.11z"
              />
              <path
                d="M267.25,145.58c-0.53,0.31-0.88,0.67-1.11,0.61c-0.26-0.07-0.5-0.51-0.57-0.83c-0.03-0.13,0.44-0.55,0.63-0.51
	C266.51,144.91,266.77,145.23,267.25,145.58z"
              />
              <path
                d="M425.71,280.41c-0.13-0.31-0.37-0.63-0.35-0.93c0.01-0.2,0.5-0.57,0.61-0.52c0.3,0.14,0.69,0.45,0.72,0.73
	c0.04,0.32-0.25,0.69-0.4,1.04C426.1,280.63,425.9,280.52,425.71,280.41z"
              />
              <path
                d="M273.27,143.81c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C272.77,144.63,273.02,144.33,273.27,143.81z"
              />
              <path
                d="M259.55,138.85c-0.47,0.32-0.78,0.7-1.04,0.67c-0.26-0.03-0.65-0.48-0.63-0.73c0.01-0.25,0.44-0.66,0.7-0.67
	C258.84,138.11,259.11,138.5,259.55,138.85z"
              />
              <path
                d="M319.42,144.23c0.52-0.28,0.82-0.55,1.13-0.56c0.2,0,0.62,0.48,0.57,0.58c-0.14,0.3-0.43,0.7-0.7,0.73
	C320.19,145.02,319.89,144.61,319.42,144.23z"
              />
              <path
                d="M299.35,138.94c-0.45,0.37-0.68,0.67-0.98,0.75c-0.16,0.04-0.62-0.32-0.6-0.46c0.04-0.3,0.25-0.75,0.48-0.81
	C298.49,138.36,298.83,138.68,299.35,138.94z"
              />
              <path
                d="M292.17,139.9c-0.5,0.28-0.84,0.62-1.09,0.56c-0.25-0.06-0.6-0.53-0.57-0.79c0.03-0.24,0.51-0.6,0.76-0.58
	C291.53,139.11,291.77,139.52,292.17,139.9z"
              />
              <path
                d="M280.49,145.11c-0.26-0.53-0.53-0.83-0.53-1.15c0-0.19,0.48-0.59,0.6-0.54c0.3,0.12,0.71,0.43,0.73,0.69
	C281.3,144.36,280.88,144.64,280.49,145.11z"
              />
              <path
                d="M288.83,143.54c-0.43,0.37-0.69,0.76-0.95,0.77c-0.26,0-0.71-0.39-0.73-0.63c-0.01-0.26,0.35-0.71,0.61-0.76
	C288.01,142.88,288.33,143.24,288.83,143.54z"
              />
              <path
                d="M419.99,280.37c-0.43,0.34-0.72,0.74-0.97,0.71c-0.27-0.03-0.69-0.45-0.69-0.71c0-0.25,0.41-0.68,0.68-0.71
	C419.26,279.63,419.56,280.03,419.99,280.37z"
              />
              <path
                d="M284.05,139.26c0.29,0.51,0.65,0.87,0.59,1.11c-0.07,0.25-0.53,0.47-0.85,0.54c-0.12,0.03-0.51-0.46-0.47-0.65
	C283.38,139.96,283.7,139.71,284.05,139.26z"
              />
              <path
                d="M296,143.19c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C295.43,142.38,295.61,142.73,296,143.19z"
              />
              <path
                d="M276.62,139.95c0.42,0.47,0.85,0.75,0.83,1c-0.02,0.26-0.44,0.56-0.75,0.69c-0.12,0.05-0.59-0.34-0.59-0.54
	C276.11,140.79,276.37,140.48,276.62,139.95z"
              />
              <path
                d="M421.74,301.6c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C420.85,301.02,421.2,301.34,421.74,301.6z"
              />
              <path
                d="M325.83,281.09c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.61c0.27-0.22,0.52-0.53,0.83-0.62
	C325.24,280.42,325.51,280.8,325.83,281.09z"
              />
              <path d="M579.07,633.55c0.49,0,0.98,0,1.67,0c-0.55,1.03-1.11,0.68-1.65,0.5C579.08,633.88,579.08,633.71,579.07,633.55z" />
              <path
                d="M412.79,633.61c0.48,0,0.95,0,1.43,0c0.02,0.12,0.04,0.25,0.05,0.37c-0.48,0.07-0.96,0.15-1.44,0.22
	C412.81,634,412.8,633.81,412.79,633.61z"
              />
              <path
                d="M267.58,485.18c-0.28,0.03-0.52,0.13-0.66,0.05c-0.16-0.09-0.23-0.33-0.34-0.5c0.25-0.08,0.5-0.2,0.75-0.21
	C267.4,484.52,267.47,484.88,267.58,485.18z"
              />
              <path
                d="M423.01,284.41c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C423.56,283.65,423.25,283.97,423.01,284.41z"
              />
              <path
                d="M416.6,284.28c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C415.95,283.57,416.21,283.94,416.6,284.28z"
              />
              <path
                d="M303.25,155.62c0.37,0.42,0.69,0.65,0.76,0.93c0.04,0.18-0.31,0.66-0.43,0.64c-0.32-0.05-0.77-0.24-0.86-0.49
	C302.63,156.5,302.98,156.13,303.25,155.62z"
              />
              <path
                d="M317.61,101.43c-0.52,0.3-0.85,0.65-1.09,0.6c-0.25-0.06-0.48-0.49-0.56-0.8c-0.03-0.12,0.43-0.52,0.61-0.49
	C316.88,100.79,317.13,101.09,317.61,101.43z"
              />
              <path
                d="M235.04,286.34c-0.31-0.4-0.66-0.66-0.62-0.82c0.08-0.3,0.39-0.54,0.6-0.8c0.22,0.19,0.63,0.4,0.62,0.57
	C235.62,285.59,235.33,285.87,235.04,286.34z"
              />
              <path
                d="M335.85,103.17c-0.45,0.39-0.67,0.71-0.97,0.8c-0.17,0.05-0.66-0.3-0.65-0.44c0.03-0.3,0.23-0.76,0.46-0.83
	C334.93,102.62,335.29,102.92,335.85,103.17z"
              />
              <path
                d="M321.51,104.51c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.55-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C320.92,103.84,321.19,104.22,321.51,104.51z"
              />
              <path
                d="M314.43,105.22c-0.49,0.33-0.81,0.71-1.02,0.66c-0.27-0.06-0.52-0.46-0.63-0.77c-0.04-0.1,0.4-0.53,0.6-0.51
	C313.67,104.62,313.94,104.9,314.43,105.22z"
              />
              <path
                d="M339.82,106.5c-0.36,0.39-0.58,0.78-0.74,0.76c-0.31-0.05-0.6-0.32-0.89-0.5c0.16-0.23,0.31-0.63,0.5-0.65
	C338.98,106.07,339.3,106.3,339.82,106.5z"
              />
              <path
                d="M331.61,108.04c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C332.24,107.41,331.93,107.64,331.61,108.04z"
              />
              <path
                d="M427.61,286.97c-0.43,0.26-0.76,0.58-0.89,0.51c-0.28-0.15-0.53-0.46-0.64-0.77c-0.04-0.09,0.45-0.56,0.61-0.51
	C426.98,286.27,427.2,286.6,427.61,286.97z"
              />
              <path
                d="M318.18,108.53c-0.33,0.31-0.57,0.69-0.72,0.66c-0.31-0.06-0.57-0.35-0.85-0.55c0.25-0.23,0.47-0.55,0.77-0.66
	C317.52,107.92,317.81,108.26,318.18,108.53z"
              />
              <path
                d="M310.98,109.04c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C310.35,108.42,310.62,108.76,310.98,109.04z"
              />
              <path
                d="M239.98,289.04c-0.5,0.18-0.87,0.42-0.99,0.33c-0.25-0.18-0.36-0.55-0.54-0.84c0.25-0.14,0.57-0.46,0.73-0.4
	C239.46,288.26,239.63,288.62,239.98,289.04z"
              />
              <path d="M215.77,634.04c-0.64,0.03-1.09,0.05-1.7,0.08C214.83,633.18,214.83,633.18,215.77,634.04z" />
              <path
                d="M343.58,109.78c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.62c0.27-0.22,0.52-0.53,0.83-0.62
	C343,109.12,343.27,109.5,343.58,109.78z"
              />
              <path
                d="M336.45,110.3c-0.34,0.4-0.55,0.81-0.7,0.79c-0.32-0.04-0.61-0.3-0.92-0.47c0.16-0.24,0.29-0.65,0.47-0.67
	C335.6,109.9,335.93,110.12,336.45,110.3z"
              />
              <path
                d="M328.3,110.27c0.32,0.39,0.67,0.65,0.63,0.82c-0.07,0.3-0.38,0.54-0.59,0.8c-0.22-0.19-0.64-0.39-0.63-0.55
	C327.74,111.03,328.01,110.75,328.3,110.27z"
              />
              <path
                d="M313.86,113.32c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C314.48,112.69,314.18,112.92,313.86,113.32z"
              />
              <path
                d="M307.66,112.7c-0.34,0.4-0.55,0.81-0.7,0.79c-0.32-0.04-0.61-0.3-0.92-0.47c0.16-0.24,0.29-0.65,0.47-0.67
	C306.81,112.3,307.14,112.52,307.66,112.7z"
              />
              <path
                d="M243.85,292.05c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C243.27,291.28,243.49,291.67,243.85,292.05z"
              />
              <path
                d="M340.3,114.03c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C339.72,113.26,339.94,113.65,340.3,114.03z"
              />
              <path
                d="M322.3,291.4c0.35,0.46,0.75,0.75,0.71,0.98c-0.04,0.25-0.45,0.53-0.75,0.63c-0.12,0.04-0.56-0.37-0.54-0.56
	C321.74,292.16,322.02,291.88,322.3,291.4z"
              />
              <path
                d="M310.95,275.33c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C310.37,274.66,310.63,275.04,310.95,275.33z"
              />
              <path
                d="M333.11,114.45c-0.5,0.24-0.81,0.49-1.1,0.48c-0.19-0.01-0.36-0.41-0.53-0.63c0.28-0.19,0.54-0.48,0.85-0.55
	C332.48,113.72,332.72,114.09,333.11,114.45z"
              />
              <path
                d="M336.17,116.8c0.35,0.41,0.67,0.64,0.75,0.93c0.04,0.14-0.43,0.61-0.54,0.57c-0.29-0.1-0.6-0.36-0.73-0.64
	C335.59,117.53,335.91,117.22,336.17,116.8z"
              />
              <path
                d="M231.71,275.03c-0.6,0.22-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C230.99,274.29,231.22,274.61,231.71,275.03z"
              />
              <path
                d="M333.93,121.58c-0.52,0.3-0.85,0.65-1.09,0.6c-0.25-0.06-0.48-0.49-0.56-0.8c-0.03-0.12,0.43-0.52,0.61-0.49
	C333.19,120.94,333.45,121.24,333.93,121.58z"
              />
              <path
                d="M303.98,123.24c0.38,0.42,0.7,0.65,0.8,0.95c0.04,0.13-0.41,0.62-0.54,0.59c-0.3-0.09-0.62-0.34-0.75-0.62
	C303.42,124.01,303.73,123.68,303.98,123.24z"
              />
              <path
                d="M280.14,169.62c-0.51,0.25-0.82,0.5-1.13,0.5c-0.16,0-0.34-0.41-0.5-0.64c0.27-0.19,0.53-0.48,0.83-0.53
	C279.51,168.92,279.75,169.28,280.14,169.62z"
              />
              <path
                d="M330.57,125.24c-0.45,0.39-0.67,0.71-0.97,0.8c-0.17,0.05-0.66-0.3-0.65-0.44c0.03-0.3,0.23-0.76,0.46-0.83
	C329.66,124.7,330.01,125,330.57,125.24z"
              />
              <path
                d="M308.38,125.94c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C307.55,127.14,307.83,126.86,308.38,125.94z"
              />
              <path
                d="M197.48,631.89c-0.38,0.26-0.62,0.56-0.85,0.55c-0.2-0.01-0.55-0.37-0.54-0.56c0.01-0.22,0.34-0.57,0.54-0.58
	C196.87,631.29,197.1,631.6,197.48,631.89z"
              />
              <path
                d="M312.96,130.73c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C312.3,130.03,312.56,130.39,312.96,130.73z"
              />
              <path
                d="M246.61,294.3c0.38,0.42,0.71,0.63,0.78,0.91c0.05,0.18-0.3,0.67-0.42,0.65c-0.32-0.04-0.76-0.22-0.87-0.47
	C246.01,295.19,246.35,294.81,246.61,294.3z"
              />
              <path
                d="M305.8,131.13c-0.49,0.33-0.81,0.71-1.02,0.66c-0.27-0.06-0.52-0.46-0.63-0.77c-0.04-0.1,0.4-0.53,0.6-0.51
	C305.03,130.53,305.3,130.82,305.8,131.13z"
              />
              <path
                d="M308.86,133.13c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C308.03,134.34,308.31,134.06,308.86,133.13z"
              />
              <path
                d="M319.41,294.84c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C318.59,296.05,318.87,295.76,319.41,294.84z"
              />
              <path
                d="M302.31,135.22c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.55-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C301.73,134.55,302,134.93,302.31,135.22z"
              />
              <path
                d="M203.93,630.52c0.25,0.41,0.56,0.77,0.5,0.86c-0.18,0.26-0.52,0.4-0.79,0.59c-0.12-0.24-0.36-0.5-0.34-0.72
	C203.32,631.03,203.63,630.85,203.93,630.52z"
              />
              <path
                d="M312.69,136.49c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C311.87,137.7,312.15,137.41,312.69,136.49z"
              />
              <path
                d="M305.61,137.44c0.3,0.4,0.67,0.71,0.61,0.83c-0.14,0.29-0.44,0.55-0.74,0.69c-0.08,0.04-0.57-0.41-0.54-0.57
	C305,138.1,305.3,137.85,305.61,137.44z"
              />
              <path
                d="M317.61,140.97c-0.53,0.31-0.82,0.57-1.13,0.61c-0.16,0.02-0.5-0.39-0.5-0.61c0-0.22,0.35-0.64,0.5-0.62
	C316.79,140.39,317.07,140.66,317.61,140.97z"
              />
              <path
                d="M399.66,296.44c-0.53,0.31-0.82,0.57-1.13,0.61c-0.16,0.02-0.5-0.39-0.5-0.61c0-0.22,0.35-0.64,0.5-0.62
	C398.84,295.86,399.13,296.13,399.66,296.44z"
              />
              <path
                d="M310.51,142.03c-0.3,0.09-0.62,0.3-0.9,0.25c-0.31-0.05-0.58-0.32-0.86-0.5c0.15-0.24,0.3-0.66,0.47-0.67
	c0.31-0.01,0.63,0.21,0.95,0.34C310.27,141.65,310.39,141.84,310.51,142.03z"
              />
              <path
                d="M219.95,359.93c-0.3-0.46-0.6-0.72-0.56-0.92c0.03-0.2,0.39-0.4,0.64-0.48c0.09-0.03,0.42,0.3,0.41,0.44
	C220.4,359.23,220.2,359.46,219.95,359.93z"
              />
              <path
                d="M301.29,142.53c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C302.04,143.11,301.72,142.79,301.29,142.53z"
              />
              <path
                d="M314.21,145.12c-0.5,0.26-0.79,0.52-1.1,0.54c-0.16,0.01-0.35-0.4-0.53-0.62c0.26-0.2,0.51-0.51,0.8-0.57
	C313.56,144.43,313.81,144.78,314.21,145.12z"
              />
              <path
                d="M297.55,299.02c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C298.17,298.38,297.86,298.62,297.55,299.02z"
              />
              <path
                d="M251.57,298.65c-0.51,0.3-0.85,0.65-1.07,0.58c-0.26-0.08-0.49-0.49-0.58-0.8c-0.03-0.1,0.44-0.51,0.64-0.48
	C250.85,297.99,251.1,298.31,251.57,298.65z"
              />
              <path
                d="M270.89,154.91c0.25,0.44,0.56,0.77,0.49,0.92c-0.13,0.28-0.45,0.53-0.75,0.62c-0.12,0.04-0.58-0.45-0.54-0.59
	C270.19,155.56,270.51,155.33,270.89,154.91z"
              />
              <path
                d="M298.88,145.3c0.28,0.48,0.56,0.76,0.58,1.07c0.01,0.17-0.4,0.37-0.63,0.55c-0.21-0.26-0.51-0.51-0.59-0.8
	C298.21,145.95,298.56,145.69,298.88,145.3z"
              />
              <path
                d="M309.92,149.79c-0.3-0.41-0.6-0.66-0.66-0.95c-0.03-0.16,0.44-0.6,0.54-0.56c0.3,0.13,0.6,0.39,0.74,0.67
	C310.59,149.08,310.22,149.39,309.92,149.79z"
              />
              <path
                d="M295.85,151.01c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C296.4,150.25,296.09,150.57,295.85,151.01z"
              />
              <path
                d="M288.42,151.78c-0.29-0.5-0.64-0.84-0.58-1.07c0.06-0.24,0.52-0.45,0.83-0.51c0.12-0.02,0.5,0.44,0.46,0.61
	C289.07,151.1,288.77,151.34,288.42,151.78z"
              />
              <path
                d="M228.67,333.83c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C229.22,333.07,228.92,333.39,228.67,333.83z"
              />
              <path
                d="M693.85,626.17c-0.26-0.38-0.52-0.76-0.78-1.14c0.14-0.09,0.28-0.19,0.41-0.28c0.25,0.39,0.5,0.77,0.76,1.16
	C694.1,625.99,693.97,626.08,693.85,626.17z"
              />
              <path
                d="M282.29,151.39c-0.53,0.27-0.88,0.6-1.1,0.52c-0.25-0.09-0.47-0.51-0.54-0.82c-0.02-0.11,0.46-0.49,0.66-0.45
	C281.6,150.7,281.83,151.02,282.29,151.39z"
              />
              <path
                d="M315.73,299.21c0.33,0.27,0.74,0.48,0.71,0.62c-0.05,0.31-0.32,0.58-0.5,0.87c-0.26-0.22-0.59-0.41-0.74-0.69
	C315.14,299.89,315.48,299.56,315.73,299.21z"
              />
              <path
                d="M256.66,157.95c-0.3-0.41-0.6-0.66-0.66-0.95c-0.03-0.16,0.44-0.6,0.54-0.56c0.3,0.13,0.6,0.39,0.74,0.67
	C257.33,157.23,256.96,157.55,256.66,157.95z"
              />
              <path
                d="M283.5,165.75c-0.5,0.26-0.79,0.52-1.1,0.54c-0.16,0.01-0.35-0.4-0.53-0.62c0.26-0.2,0.51-0.5,0.8-0.57
	C282.85,165.07,283.1,165.42,283.5,165.75z"
              />
              <path
                d="M232.67,329.25c-0.6,0.22-0.93,0.43-1.24,0.41c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.54
	C231.95,328.52,232.18,328.83,232.67,329.25z"
              />
              <path
                d="M307.62,152.7c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C306.99,152.08,307.26,152.43,307.62,152.7z"
              />
              <path
                d="M259.85,154.18c-0.39-0.44-0.81-0.7-0.79-0.94c0.01-0.25,0.4-0.56,0.69-0.68c0.12-0.05,0.6,0.32,0.6,0.51
	C260.34,153.37,260.09,153.68,259.85,154.18z"
              />
              <path
                d="M250.91,165.15c-0.6,0.21-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C250.18,164.41,250.41,164.72,250.91,165.15z"
              />
              <path
                d="M247.4,303.35c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C248.06,302.71,247.75,302.94,247.4,303.35z"
              />
              <path
                d="M311.85,264.41c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C312.6,264.99,312.27,264.67,311.85,264.41z"
              />
              <path
                d="M413.43,303.35c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C414.08,302.71,413.78,302.94,413.43,303.35z"
              />
              <path
                d="M300.45,153.33c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.43-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C299.69,152.73,300.02,153.06,300.45,153.33z"
              />
              <path
                d="M324.34,233.36c0.38,0.42,0.71,0.63,0.78,0.91c0.05,0.18-0.3,0.67-0.42,0.65c-0.32-0.04-0.76-0.22-0.87-0.47
	C323.75,234.25,324.09,233.87,324.34,233.36z"
              />
              <path
                d="M293.25,153.81c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.43-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C292.49,153.21,292.82,153.54,293.25,153.81z"
              />
              <path
                d="M296.15,156.34c0.32,0.39,0.67,0.65,0.63,0.82c-0.07,0.3-0.38,0.54-0.59,0.8c-0.22-0.19-0.64-0.39-0.63-0.55
	C295.59,157.1,295.86,156.81,296.15,156.34z"
              />
              <path
                d="M285.34,153.29c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C284.52,154.49,284.8,154.21,285.34,153.29z"
              />
              <path
                d="M321.25,237.19c0.4,0.43,0.82,0.69,0.8,0.92c-0.02,0.27-0.4,0.58-0.69,0.71c-0.12,0.05-0.6-0.32-0.6-0.51
	C320.75,238,321.01,237.7,321.25,237.19z"
              />
              <path
                d="M242.36,317.29c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C241.73,316.67,242,317.01,242.36,317.29z"
              />
              <path
                d="M325.28,240.61c0.3,0.4,0.67,0.71,0.61,0.83c-0.14,0.29-0.44,0.55-0.74,0.69c-0.08,0.04-0.57-0.4-0.54-0.57
	C324.67,241.26,324.97,241.02,325.28,240.61z"
              />
              <path
                d="M285.79,162.75c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C286.45,162.12,286.14,162.35,285.79,162.75z"
              />
              <path
                d="M330.23,258.06c0.31,0.4,0.66,0.66,0.62,0.83c-0.08,0.3-0.38,0.53-0.6,0.8c-0.22-0.19-0.64-0.4-0.62-0.56
	C329.66,258.82,329.94,258.53,330.23,258.06z"
              />
              <path
                d="M298.38,306.29c-0.31-0.45-0.6-0.71-0.65-1.01c-0.03-0.17,0.38-0.61,0.5-0.58c0.31,0.08,0.75,0.32,0.79,0.56
	C299.06,305.5,298.69,305.82,298.38,306.29z"
              />
              <path
                d="M289.92,158.08c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C289.27,157.38,289.53,157.74,289.92,158.08z"
              />
              <path
                d="M250.43,305.68c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C251.18,306.26,250.85,305.94,250.43,305.68z"
              />
              <path
                d="M254.27,161.31c-0.6,0.22-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C253.54,160.57,253.77,160.88,254.27,161.31z"
              />
              <path d="M358.39,4.18c0.35,0,0.69,0,1.04,0c0,0.1,0,0.2,0,0.31c-0.35,0-0.69,0-1.04,0C358.39,4.38,358.39,4.28,358.39,4.18z" />
              <path
                d="M278.83,155.07c-0.33,0.31-0.57,0.69-0.72,0.66c-0.31-0.06-0.57-0.35-0.85-0.55c0.25-0.23,0.47-0.55,0.77-0.66
	C278.17,154.47,278.46,154.81,278.83,155.07z"
              />
              <path
                d="M319.67,249.34c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C319.09,248.58,319.31,248.96,319.67,249.34z"
              />
              <path
                d="M330.68,251.69c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.42-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C329.92,251.1,330.25,251.42,330.68,251.69z"
              />
              <path
                d="M282.72,158.65c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C282.14,157.89,282.36,158.27,282.72,158.65z"
              />
              <path
                d="M231.03,282.95c-0.24-0.43-0.53-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C231.73,282.32,231.41,282.54,231.03,282.95z"
              />
              <path
                d="M279.54,162.6c-0.47,0.15-0.88,0.38-0.98,0.28c-0.22-0.21-0.29-0.59-0.43-0.9c0.28-0.07,0.59-0.24,0.83-0.17
	C279.16,161.88,279.28,162.23,279.54,162.6z"
              />
              <path
                d="M230.54,307.37c-0.33,0.31-0.52,0.64-0.73,0.65c-0.2,0.01-0.58-0.28-0.6-0.47c-0.02-0.21,0.25-0.58,0.46-0.63
	C229.87,306.87,230.14,307.15,230.54,307.37z"
              />
              <path
                d="M274.63,158.21c0.45,0.5,0.76,0.72,0.86,1.01c0.04,0.13-0.32,0.53-0.54,0.58c-0.19,0.04-0.63-0.21-0.65-0.38
	C274.25,159.13,274.45,158.8,274.63,158.21z"
              />
              <path
                d="M249.02,309.93c-0.29,0.1-0.61,0.31-0.87,0.25c-0.29-0.06-0.53-0.37-0.79-0.57c0.2-0.2,0.37-0.52,0.6-0.56
	c0.22-0.04,0.49,0.21,0.74,0.33C248.8,309.57,248.91,309.75,249.02,309.93z"
              />
              <path
                d="M366.12,633.67c0.4,0,0.8,0,1.19,0c0.01,0.11,0.03,0.22,0.04,0.33c-0.39,0.06-0.79,0.12-1.18,0.18
	C366.16,634.01,366.14,633.84,366.12,633.67z"
              />
              <path
                d="M311.64,282.56c-0.34,0.25-0.67,0.6-0.77,0.55c-0.26-0.15-0.41-0.48-0.61-0.73c0.22-0.15,0.45-0.45,0.65-0.43
	C311.14,281.97,311.33,282.29,311.64,282.56z"
              />
              <path
                d="M319.37,257.16c-0.35-0.29-0.75-0.55-0.72-0.63c0.11-0.28,0.38-0.5,0.58-0.75c0.2,0.18,0.52,0.34,0.56,0.55
	C319.83,256.54,319.57,256.79,319.37,257.16z"
              />
              <path
                d="M267.74,158.82c0.26,0.4,0.59,0.78,0.53,0.86c-0.2,0.26-0.54,0.41-0.82,0.61c-0.13-0.25-0.38-0.51-0.36-0.74
	C267.11,159.33,267.43,159.15,267.74,158.82z"
              />
              <path
                d="M228.22,278.89c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C227.61,278.37,227.84,278.64,228.22,278.89z"
              />
              <path
                d="M234.57,311.15c-0.42,0.12-0.73,0.31-0.92,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C234.18,310.29,234.35,310.74,234.57,311.15z"
              />
              <path
                d="M227.45,311.22c-0.41,0.33-0.65,0.67-0.83,0.64c-0.22-0.04-0.44-0.36-0.53-0.61c-0.04-0.09,0.27-0.46,0.42-0.45
	C226.75,310.8,227,311,227.45,311.22z"
              />
              <path
                d="M322.91,245.63c-0.29,0.1-0.61,0.31-0.87,0.25c-0.29-0.06-0.53-0.37-0.79-0.57c0.2-0.2,0.37-0.52,0.6-0.56
	c0.22-0.04,0.49,0.21,0.74,0.33C322.7,245.28,322.81,245.46,322.91,245.63z"
              />
              <path
                d="M260.4,161.23c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C261.11,160.6,260.71,160.91,260.4,161.23z"
              />
              <path
                d="M245.71,313.31c-0.34,0.36-0.58,0.75-0.71,0.72c-0.31-0.08-0.56-0.36-0.83-0.56c0.21-0.2,0.39-0.51,0.63-0.56
	C245,312.86,245.28,313.1,245.71,313.31z"
              />
              <path
                d="M238.84,314.38c-0.53,0.21-0.81,0.42-1.09,0.41c-0.25-0.02-0.48-0.28-0.72-0.43c0.2-0.27,0.37-0.6,0.64-0.78
	C237.76,313.52,238.11,313.86,238.84,314.38z"
              />
              <path
                d="M237.74,306.87c-0.29,0.31-0.45,0.63-0.67,0.67c-0.21,0.04-0.46-0.22-0.7-0.35c0.17-0.28,0.3-0.61,0.54-0.8
	C236.99,306.34,237.37,306.65,237.74,306.87z"
              />
              <path
                d="M245.02,306.24c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C244.4,305.72,244.63,305.99,245.02,306.24z"
              />
              <path
                d="M230.35,313.82c0.32,0.37,0.63,0.57,0.64,0.78c0,0.22-0.29,0.44-0.45,0.66c-0.22-0.17-0.57-0.31-0.63-0.52
	C229.85,314.53,230.12,314.26,230.35,313.82z"
              />
              <path
                d="M322.02,286.24c-0.45-0.62-0.74-0.84-0.73-1.05c0.01-0.21,0.32-0.4,0.5-0.6c0.23,0.22,0.58,0.4,0.66,0.67
	C322.52,285.46,322.25,285.77,322.02,286.24z"
              />
              <path
                d="M306.24,286.17c0.55-0.21,0.87-0.47,1.11-0.4c0.29,0.08,0.51,0.42,0.76,0.65c-0.22,0.2-0.42,0.52-0.65,0.55
	C307.26,286.99,307.01,286.69,306.24,286.17z"
              />
              <path
                d="M419.48,288.18c-0.29-0.38-0.65-0.74-0.6-0.82c0.17-0.27,0.5-0.45,0.78-0.66c0.15,0.24,0.42,0.48,0.42,0.71
	C420.07,287.63,419.76,287.84,419.48,288.18z"
              />
              <path
                d="M316.25,261.03c-0.33-0.3-0.66-0.46-0.7-0.67c-0.04-0.22,0.22-0.48,0.35-0.72c0.29,0.18,0.62,0.31,0.84,0.55
	C316.8,260.25,316.49,260.64,316.25,261.03z"
              />
              <path
                d="M413.1,288.34c-0.45,0.12-0.75,0.3-0.94,0.22c-0.21-0.08-0.32-0.41-0.47-0.63c0.3-0.14,0.59-0.36,0.89-0.39
	C412.7,287.53,412.86,287.95,413.1,288.34z"
              />
              <path
                d="M233.9,303.61c-0.31,0.27-0.51,0.57-0.74,0.59c-0.2,0.02-0.43-0.28-0.65-0.44c0.2-0.25,0.37-0.58,0.63-0.72
	C233.25,302.99,233.58,303.35,233.9,303.61z"
              />
              <path
                d="M272.33,163.19c-0.4,0.22-0.79,0.52-0.88,0.45c-0.24-0.19-0.37-0.54-0.53-0.83c0.24-0.13,0.52-0.38,0.73-0.34
	C271.86,162.52,272.03,162.85,272.33,163.19z"
              />
              <path
                d="M233.95,319.06c-0.08-0.3-0.28-0.62-0.22-0.89c0.07-0.29,0.38-0.52,0.58-0.77c0.19,0.2,0.5,0.38,0.54,0.6
	c0.04,0.21-0.21,0.47-0.33,0.71C234.33,318.83,234.14,318.95,233.95,319.06z"
              />
              <path
                d="M228.43,318.38c-0.94,0.48-1.22,0.74-1.4,0.68c-0.22-0.06-0.51-0.43-0.48-0.62c0.03-0.2,0.39-0.47,0.63-0.49
	C227.44,317.92,227.74,318.12,228.43,318.38z"
              />
              <path
                d="M405.94,288.77c-0.31,0.27-0.57,0.64-0.71,0.59c-0.3-0.09-0.54-0.39-0.81-0.6c0.27-0.21,0.51-0.51,0.81-0.6
	C405.37,288.11,405.63,288.49,405.94,288.77z"
              />
              <path
                d="M407.42,302.75c-0.34,0.36-0.58,0.75-0.71,0.72c-0.31-0.08-0.56-0.36-0.83-0.57c0.21-0.2,0.39-0.51,0.63-0.56
	C406.71,302.3,406.98,302.55,407.42,302.75z"
              />
              <path
                d="M247.41,175.15c0.2,0.42,0.44,0.68,0.4,0.89c-0.04,0.22-0.36,0.39-0.56,0.58c-0.19-0.26-0.47-0.5-0.55-0.8
	C246.67,175.71,247.06,175.47,247.41,175.15z"
              />
              <path
                d="M238.25,320.34c0.22,0.37,0.5,0.63,0.46,0.83c-0.04,0.23-0.37,0.41-0.57,0.61c-0.22-0.25-0.5-0.48-0.62-0.78
	C237.49,320.92,237.9,320.64,238.25,320.34z"
              />
              <path
                d="M231.98,321.75c-0.29,0.31-0.45,0.63-0.67,0.67c-0.21,0.04-0.46-0.22-0.7-0.35c0.17-0.28,0.3-0.61,0.54-0.8
	C231.24,321.21,231.61,321.53,231.98,321.75z"
              />
              <path
                d="M258.13,164.55c-0.29,0.08-0.6,0.27-0.87,0.22c-0.29-0.06-0.52-0.34-0.78-0.53c0.19-0.19,0.36-0.5,0.58-0.54
	c0.22-0.04,0.48,0.19,0.72,0.31C257.89,164.18,258.01,164.37,258.13,164.55z"
              />
              <path
                d="M232.65,289.55c-0.42,0.12-0.73,0.31-0.91,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C232.26,288.7,232.43,289.15,232.65,289.55z"
              />
              <path
                d="M240.43,303.7c-0.32-0.29-0.64-0.44-0.68-0.64c-0.04-0.21,0.21-0.46,0.34-0.69c0.28,0.17,0.56,0.34,0.84,0.5
	C240.8,303.1,240.66,303.33,240.43,303.7z"
              />
              <path
                d="M327.25,261.99c0.23,0.44,0.49,0.72,0.44,0.92c-0.05,0.21-0.41,0.35-0.63,0.53c-0.16-0.22-0.45-0.44-0.45-0.66
	C326.61,262.57,326.93,262.36,327.25,261.99z"
              />
              <path
                d="M292.92,606.9c0.2-0.31,0.4-0.62,0.6-0.92c0.08,0.05,0.16,0.1,0.25,0.15c-0.19,0.31-0.38,0.62-0.58,0.94
	C293.1,607.01,293.01,606.96,292.92,606.9z"
              />
              <path
                d="M235.99,325.23c-0.51,0.29-0.79,0.56-1.09,0.59c-0.15,0.02-0.49-0.39-0.49-0.6c0-0.22,0.34-0.62,0.49-0.6
	C235.21,324.66,235.49,324.93,235.99,325.23z"
              />
              <path
                d="M228.66,325.75c-0.36,0.26-0.7,0.62-0.8,0.56c-0.27-0.15-0.43-0.49-0.63-0.75c0.23-0.16,0.47-0.46,0.67-0.44
	C228.14,325.14,228.34,325.46,228.66,325.75z"
              />
              <path
                d="M319.39,288.97c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C318.78,288.45,319,288.71,319.39,288.97z"
              />
              <path
                d="M312.12,289.82c-0.4,0.22-0.67,0.49-0.88,0.45c-0.2-0.04-0.48-0.42-0.46-0.62c0.02-0.2,0.39-0.5,0.6-0.48
	C311.59,289.18,311.78,289.51,312.12,289.82z"
              />
              <path
                d="M302.3,301.1c-0.31,0.34-0.57,0.74-0.67,0.71c-0.31-0.11-0.55-0.39-0.82-0.6c0.21-0.2,0.4-0.53,0.64-0.58
	C301.64,300.59,301.91,300.87,302.3,301.1z"
              />
              <path
                d="M309.43,301.07c-0.42,0.12-0.73,0.31-0.91,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C309.04,300.21,309.21,300.67,309.43,301.07z"
              />
              <path
                d="M229.3,301.11c-0.28-0.33-0.58-0.52-0.6-0.73c-0.01-0.23,0.24-0.47,0.38-0.7c0.27,0.2,0.59,0.36,0.77,0.62
	C229.92,300.38,229.57,300.73,229.3,301.11z"
              />
              <path
                d="M263.68,155.75c0.22,0.37,0.5,0.63,0.46,0.83c-0.04,0.23-0.37,0.41-0.57,0.61c-0.22-0.25-0.5-0.48-0.62-0.78
	C262.92,156.33,263.34,156.06,263.68,155.75z"
              />
              <path
                d="M225.3,329.47c-0.32,0.3-0.5,0.62-0.73,0.65c-0.21,0.03-0.46-0.26-0.69-0.4c0.19-0.28,0.34-0.61,0.6-0.79
	C224.57,328.87,224.93,329.22,225.3,329.47z"
              />
              <path
                d="M321.1,110.77c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C320.55,111.51,320.83,111.24,321.1,110.77z"
              />
              <path
                d="M403.46,299.8c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C402.67,299.23,402.95,299.5,403.46,299.8z"
              />
              <path
                d="M237.3,299.67c-0.31,0.32-0.48,0.65-0.7,0.69c-0.21,0.04-0.48-0.22-0.72-0.35c0.18-0.29,0.31-0.63,0.56-0.82
	C236.52,299.12,236.91,299.44,237.3,299.67z"
              />
              <path
                d="M305.09,290.37c-0.58,0.21-0.89,0.42-1.2,0.4c-0.15-0.01-0.4-0.47-0.36-0.68c0.04-0.22,0.43-0.57,0.56-0.53
	C304.39,289.66,304.62,289.96,305.09,290.37z"
              />
              <path
                d="M423.31,289.75c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C422.76,290.49,423.04,290.22,423.31,289.75z"
              />
              <path
                d="M222.22,333.63c-0.44,0.28-0.69,0.58-0.9,0.55c-0.23-0.03-0.42-0.37-0.63-0.57c0.22-0.19,0.42-0.5,0.66-0.53
	C221.55,333.05,221.8,333.35,222.22,333.63z"
              />
              <path
                d="M251.42,172.05c-0.35,0.33-0.56,0.66-0.75,0.66c-0.22-0.01-0.44-0.31-0.66-0.49c0.17-0.22,0.32-0.56,0.53-0.62
	C250.74,171.56,251.01,171.83,251.42,172.05z"
              />
              <path
                d="M303.05,150.2c-0.35-0.31-0.77-0.57-0.74-0.66c0.11-0.29,0.39-0.52,0.6-0.78c0.21,0.19,0.54,0.36,0.58,0.58
	C303.53,149.54,303.26,149.81,303.05,150.2z"
              />
              <path
                d="M182.57,632.17c0.09,0.29,0.28,0.59,0.25,0.86c-0.03,0.23-0.3,0.56-0.5,0.59c-0.18,0.03-0.54-0.25-0.6-0.45
	c-0.05-0.18,0.2-0.45,0.32-0.68C182.22,632.39,182.4,632.28,182.57,632.17z"
              />
              <path
                d="M276.17,166.28c-0.31,0.32-0.48,0.65-0.7,0.69c-0.21,0.04-0.48-0.22-0.72-0.35c0.18-0.29,0.31-0.63,0.56-0.82
	C275.39,165.72,275.77,166.04,276.17,166.28z"
              />
              <path
                d="M243.61,299.91c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C244.31,299.27,243.92,299.58,243.61,299.91z"
              />
              <path
                d="M219.62,344.54c-0.4,0.34-0.6,0.65-0.82,0.66c-0.21,0.01-0.43-0.29-0.65-0.46c0.17-0.21,0.31-0.55,0.51-0.59
	C218.88,344.09,219.15,344.33,219.62,344.54z"
              />
              <path
                d="M268.93,166.92c-0.34,0.25-0.67,0.6-0.77,0.55c-0.26-0.15-0.41-0.48-0.61-0.73c0.22-0.15,0.45-0.45,0.65-0.43
	C268.43,166.33,268.62,166.64,268.93,166.92z"
              />
              <path
                d="M417.76,298.46c-0.35,0.33-0.58,0.69-0.72,0.66c-0.28-0.07-0.52-0.35-0.77-0.54c0.19-0.19,0.36-0.5,0.58-0.53
	C417.07,298.01,417.33,298.25,417.76,298.46z"
              />
              <path
                d="M306.89,145.81c-0.47,0.15-0.88,0.38-0.98,0.28c-0.22-0.21-0.29-0.59-0.43-0.9c0.28-0.07,0.59-0.24,0.83-0.17
	C306.51,145.09,306.63,145.43,306.89,145.81z"
              />
              <path
                d="M327.28,271.1c-0.08-0.3-0.24-0.61-0.22-0.9c0.02-0.26,0.23-0.51,0.36-0.76c0.29,0.19,0.63,0.34,0.83,0.59
	c0.06,0.08-0.26,0.46-0.4,0.7C327.66,270.86,327.47,270.98,327.28,271.1z"
              />
              <path
                d="M219.4,353.16c-0.49-0.92-0.75-1.18-0.7-1.37c0.05-0.19,0.41-0.45,0.61-0.42c0.19,0.02,0.47,0.35,0.49,0.56
	C219.83,352.2,219.63,352.49,219.4,353.16z"
              />
              <path
                d="M306.16,297.26c-0.94,0.48-1.22,0.74-1.4,0.68c-0.22-0.06-0.51-0.43-0.48-0.62c0.03-0.2,0.39-0.47,0.63-0.49
	C305.18,296.8,305.47,297.01,306.16,297.26z"
              />
              <path
                d="M324.95,115.98c-0.28-0.46-0.56-0.73-0.58-1.03c-0.01-0.17,0.39-0.37,0.6-0.56c0.21,0.26,0.51,0.49,0.58,0.79
	C325.6,115.33,325.26,115.59,324.95,115.98z"
              />
              <path
                d="M317.89,116.44c-0.24-0.43-0.54-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C318.58,115.82,318.26,116.03,317.89,116.44z"
              />
              <path
                d="M310.78,117.09c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C311.49,116.45,311.09,116.76,310.78,117.09z"
              />
              <path
                d="M233.18,296.42c-0.32,0.28-0.53,0.59-0.74,0.59c-0.22,0-0.45-0.29-0.67-0.46c0.21-0.25,0.39-0.56,0.66-0.71
	C232.52,295.79,232.84,296.15,233.18,296.42z"
              />
              <path
                d="M303.5,116.07c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C302.96,116.82,303.26,116.5,303.5,116.07z"
              />
              <path
                d="M313.43,272.35c-0.29-0.38-0.65-0.74-0.6-0.82c0.17-0.27,0.5-0.45,0.78-0.66c0.15,0.24,0.42,0.48,0.42,0.71
	C314.02,271.8,313.72,272,313.43,272.35z"
              />
              <path
                d="M404.96,296.08c0.4-0.35,0.61-0.67,0.9-0.75c0.15-0.04,0.63,0.42,0.59,0.51c-0.11,0.3-0.36,0.6-0.63,0.75
	C405.7,296.65,405.38,296.33,404.96,296.08z"
              />
              <path
                d="M482.5,21.94c0.15,0.23,0.31,0.46,0.46,0.68c-0.07,0.07-0.15,0.14-0.22,0.21c-0.21-0.2-0.42-0.4-0.63-0.6
	C482.24,22.13,482.37,22.04,482.5,21.94z"
              />
              <path
                d="M227.51,271.86c-0.49,0.21-0.78,0.46-0.98,0.4c-0.22-0.06-0.36-0.4-0.54-0.62c0.24-0.16,0.47-0.44,0.71-0.44
	C226.9,271.2,227.1,271.51,227.51,271.86z"
              />
              <path
                d="M253.7,167.29c0.29,0.32,0.48,0.52,0.66,0.72c-0.24,0.22-0.48,0.43-0.72,0.65c-0.17-0.21-0.47-0.41-0.48-0.62
	C253.16,167.83,253.45,167.62,253.7,167.29z"
              />
              <path
                d="M240.35,296.29c-0.44,0.12-0.75,0.32-0.94,0.24c-0.21-0.09-0.32-0.44-0.47-0.68c0.3-0.16,0.6-0.38,0.92-0.44
	C239.95,295.39,240.12,295.87,240.35,296.29z"
              />
              <path
                d="M316.69,133.78c-0.31,0.27-0.57,0.63-0.71,0.59c-0.3-0.09-0.54-0.39-0.81-0.6c0.27-0.21,0.51-0.51,0.81-0.6
	C316.12,133.12,316.38,133.5,316.69,133.78z"
              />
              <path
                d="M265.76,171.03c-0.42,0.12-0.73,0.31-0.92,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C265.37,170.18,265.54,170.63,265.76,171.03z"
              />
              <path
                d="M421.08,294.66c-0.4,0.22-0.79,0.52-0.88,0.45c-0.24-0.19-0.37-0.54-0.53-0.83c0.24-0.13,0.52-0.38,0.73-0.34
	C420.62,293.99,420.78,294.33,421.08,294.66z"
              />
              <path
                d="M300.95,293.11c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C300.4,293.85,300.68,293.57,300.95,293.11z"
              />
              <path
                d="M320.09,129.82c-0.39,0.35-0.61,0.67-0.89,0.73c-0.16,0.04-0.63-0.41-0.6-0.5c0.12-0.3,0.36-0.6,0.63-0.75
	C319.36,129.24,319.68,129.56,320.09,129.82z"
              />
              <path
                d="M316.06,126.78c-0.38,0.3-0.67,0.66-0.81,0.61c-0.28-0.1-0.48-0.43-0.71-0.66c0.22-0.17,0.43-0.47,0.66-0.48
	C315.43,126.24,315.67,126.52,316.06,126.78z"
              />
              <path
                d="M247.64,169.07c-0.49,0.18-0.85,0.41-0.96,0.32c-0.24-0.18-0.42-0.52-0.48-0.82c-0.02-0.09,0.52-0.45,0.66-0.38
	C247.12,168.32,247.29,168.66,247.64,169.07z"
              />
              <path
                d="M308.96,293.89c-0.48,0.1-0.81,0.28-0.98,0.18c-0.19-0.11-0.36-0.53-0.28-0.71c0.08-0.19,0.5-0.38,0.7-0.32
	C308.6,293.1,308.7,293.47,308.96,293.89z"
              />
              <path
                d="M323.32,126.09c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C322.54,125.53,322.82,125.79,323.32,126.09z"
              />
              <path
                d="M188.61,632.56c0.4-0.25,0.64-0.52,0.84-0.49c0.22,0.03,0.4,0.34,0.6,0.52c-0.2,0.2-0.39,0.53-0.62,0.56
	C189.25,633.17,189.01,632.86,188.61,632.56z"
              />
              <path
                d="M328.92,119.32c-0.24-0.43-0.53-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C329.62,118.7,329.3,118.91,328.92,119.32z"
              />
              <path
                d="M228.54,292.24c0.26,0.38,0.56,0.62,0.54,0.83c-0.02,0.22-0.35,0.41-0.55,0.61c-0.21-0.2-0.56-0.39-0.58-0.61
	C227.94,292.86,228.26,292.62,228.54,292.24z"
              />
              <path
                d="M321.73,117.99c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C321.19,118.74,321.49,118.42,321.73,117.99z"
              />
              <path
                d="M311.15,122.65c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C310.69,123.3,310.95,123.03,311.15,122.65z"
              />
              <path
                d="M318.55,121.81c0.27,0.46,0.55,0.74,0.57,1.04c0.01,0.17-0.39,0.36-0.61,0.55c-0.2-0.26-0.5-0.49-0.57-0.79
	C317.89,122.45,318.24,122.19,318.55,121.81z"
              />
              <path
                d="M302.99,401.43c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C302.53,402.09,302.79,401.82,302.99,401.43z"
              />
              <path
                d="M401.86,291.69c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C401.32,292.44,401.62,292.12,401.86,291.69z"
              />
              <path
                d="M326.68,122.26c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C325.9,121.69,326.18,121.96,326.68,122.26z"
              />
              <path
                d="M236.56,292.58c-0.34,0.29-0.55,0.61-0.76,0.61c-0.23,0-0.46-0.3-0.69-0.47c0.22-0.26,0.41-0.58,0.68-0.73
	C235.88,291.94,236.21,292.3,236.56,292.58z"
              />
              <path
                d="M293.58,406.97c-0.58,0.21-0.89,0.42-1.2,0.4c-0.15-0.01-0.4-0.47-0.36-0.68c0.04-0.22,0.43-0.57,0.56-0.53
	C292.88,406.26,293.1,406.56,293.58,406.97z"
              />
              <path
                d="M273.07,170.51c-0.49,0.18-0.85,0.41-0.96,0.32c-0.24-0.18-0.42-0.52-0.48-0.82c-0.02-0.09,0.52-0.45,0.66-0.38
	C272.55,169.75,272.72,170.09,273.07,170.51z"
              />
              <path
                d="M307.58,119.29c0.25,0.37,0.41,0.6,0.56,0.83c-0.28,0.19-0.57,0.38-0.85,0.56c-0.14-0.24-0.41-0.49-0.38-0.72
	C306.93,119.76,307.27,119.59,307.58,119.29z"
              />
              <path
                d="M314.51,118.81c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C314.05,119.46,314.31,119.19,314.51,118.81z"
              />
              <path
                d="M324.51,273.3c0.27,0.42,0.48,0.64,0.53,0.89c0.02,0.13-0.3,0.46-0.4,0.43c-0.26-0.06-0.48-0.27-0.71-0.42
	C324.07,273.96,324.22,273.73,324.51,273.3z"
              />
              <path
                d="M315.12,293.66c-0.25-0.43-0.46-0.65-0.5-0.9c-0.02-0.13,0.31-0.45,0.41-0.42c0.26,0.07,0.47,0.29,0.7,0.44
	C315.57,293.02,315.41,293.24,315.12,293.66z"
              />
              <path
                d="M318.19,274.7c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.41-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C317.51,274.08,317.74,274.38,318.19,274.7z"
              />
              <path
                d="M226.51,391.04c-0.12,0.07-0.24,0.14-0.36,0.2c-0.05-0.13-0.17-0.28-0.14-0.39c0.02-0.09,0.22-0.14,0.34-0.21
	C226.4,390.77,226.45,390.91,226.51,391.04z"
              />
              <path
                d="M413.76,295.18c-0.34,0.27-0.54,0.54-0.65,0.51c-0.23-0.08-0.41-0.31-0.61-0.48c0.14-0.15,0.29-0.44,0.42-0.43
	C413.16,294.8,413.39,294.99,413.76,295.18z"
              />
              <path
                d="M322.59,251.7c0.27,0.42,0.48,0.64,0.53,0.89c0.02,0.13-0.3,0.46-0.4,0.43c-0.26-0.06-0.48-0.27-0.71-0.42
	C322.15,252.37,322.3,252.14,322.59,251.7z"
              />
              <path
                d="M319.99,270.54c0.4-0.1,0.67-0.23,0.91-0.2c0.12,0.02,0.2,0.35,0.3,0.53c-0.25,0.11-0.49,0.28-0.74,0.3
	C320.35,271.18,320.22,270.87,319.99,270.54z"
              />
              <path
                d="M237.22,426.1c-0.22,0.23-0.36,0.38-0.5,0.53c-0.13-0.15-0.3-0.28-0.37-0.46c-0.03-0.09,0.15-0.37,0.19-0.36
	C236.74,425.85,236.93,425.96,237.22,426.1z"
              />
              <path
                d="M257.82,172.26c-0.29-0.42-0.44-0.65-0.6-0.88c0.23-0.16,0.45-0.37,0.71-0.44c0.1-0.03,0.43,0.3,0.41,0.43
	C258.29,171.62,258.08,171.84,257.82,172.26z"
              />
              <path
                d="M312.91,296.77c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.42-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C312.23,296.16,312.46,296.45,312.91,296.77z"
              />
              <path
                d="M226.08,297.38c-0.39,0.09-0.66,0.23-0.91,0.19c-0.12-0.02-0.2-0.34-0.29-0.53c0.24-0.11,0.48-0.29,0.73-0.3
	C225.73,296.73,225.86,297.05,226.08,297.38z"
              />
              <path
                d="M409.17,292.7c-0.25-0.43-0.46-0.65-0.5-0.9c-0.02-0.13,0.31-0.45,0.41-0.42c0.26,0.07,0.47,0.29,0.7,0.44
	C409.62,292.06,409.46,292.28,409.17,292.7z"
              />
              <path
                d="M218.71,337.6c-0.38,0.14-0.62,0.29-0.84,0.28c-0.14-0.01-0.25-0.29-0.37-0.45c0.22-0.14,0.43-0.34,0.67-0.39
	C218.26,337.01,218.43,337.3,218.71,337.6z"
              />
              <path
                d="M317.52,267.34c-0.48,0.32-0.69,0.55-0.94,0.59c-0.16,0.03-0.55-0.27-0.53-0.33c0.07-0.26,0.23-0.61,0.43-0.67
	C316.68,266.86,316.97,267.1,317.52,267.34z"
              />
              <path
                d="M417.05,291.33c-0.47,0.31-0.67,0.53-0.9,0.57c-0.16,0.02-0.36-0.22-0.54-0.34c0.15-0.22,0.27-0.55,0.47-0.62
	C416.25,290.87,416.53,291.1,417.05,291.33z"
              />
              <path
                d="M410.6,299.16c-0.53,0.23-0.83,0.47-1.03,0.4c-0.2-0.07-0.35-0.42-0.43-0.67c-0.02-0.06,0.37-0.36,0.52-0.33
	C409.92,298.6,410.13,298.84,410.6,299.16z"
              />
              <path
                d="M324.51,266.98c-0.37,0.19-0.6,0.38-0.83,0.4c-0.13,0.01-0.28-0.27-0.43-0.42c0.2-0.17,0.38-0.4,0.61-0.48
	C323.96,266.43,324.17,266.7,324.51,266.98z"
              />
              <path
                d="M217.88,330.27c-0.24,0.19-0.48,0.47-0.54,0.43c-0.22-0.13-0.37-0.37-0.54-0.56c0.18-0.11,0.37-0.31,0.54-0.3
	C217.5,329.84,217.65,330.07,217.88,330.27z"
              />
              <path
                d="M221.53,326.36c-0.47,0.31-0.68,0.54-0.92,0.58c-0.16,0.03-0.54-0.27-0.52-0.33c0.07-0.25,0.22-0.59,0.42-0.66
	C220.71,325.89,221,326.13,221.53,326.36z"
              />
              <path
                d="M320.81,263.65c-0.51,0.22-0.79,0.45-0.98,0.38c-0.2-0.07-0.34-0.4-0.41-0.64c-0.02-0.06,0.35-0.35,0.5-0.32
	C320.16,263.11,320.37,263.34,320.81,263.65z"
              />
              <path
                d="M224.91,322.52c-0.48,0.32-0.69,0.55-0.94,0.59c-0.16,0.03-0.55-0.27-0.53-0.34c0.07-0.26,0.23-0.61,0.43-0.67
	C224.07,322.04,224.36,322.28,224.91,322.52z"
              />
              <path
                d="M265.21,163.84c-0.53,0.23-0.83,0.47-1.02,0.41c-0.2-0.06-0.36-0.41-0.43-0.67c-0.02-0.06,0.36-0.37,0.52-0.34
	C264.52,163.29,264.74,163.52,265.21,163.84z"
              />
              <path
                d="M96.35,633.33c-0.29,0.32-0.45,0.61-0.57,0.59c-0.26-0.04-0.49-0.23-0.74-0.35c0.13-0.17,0.24-0.45,0.38-0.47
	C95.67,633.07,95.93,633.21,96.35,633.33z"
              />
              <path
                d="M220.55,319.27c-0.23,0.18-0.45,0.46-0.55,0.42c-0.24-0.09-0.42-0.33-0.62-0.5c0.23-0.15,0.44-0.36,0.69-0.41
	C220.17,318.75,220.35,319.06,220.55,319.27z"
              />
              <path
                d="M223.5,308.38c-0.39,0.11-0.65,0.26-0.89,0.23c-0.13-0.02-0.23-0.32-0.34-0.5c0.24-0.13,0.46-0.32,0.71-0.36
	C223.09,307.74,223.24,308.05,223.5,308.38z"
              />
              <path
                d="M225.8,304.92c-0.16-0.19-0.44-0.43-0.41-0.5c0.12-0.22,0.35-0.39,0.54-0.57c0.16,0.22,0.31,0.45,0.47,0.67
	C226.22,304.65,226.03,304.77,225.8,304.92z"
              />
              <path
                d="M324.03,259.67c-0.34,0.27-0.54,0.54-0.65,0.51c-0.23-0.08-0.41-0.31-0.61-0.48c0.14-0.15,0.29-0.44,0.42-0.43
	C323.43,259.29,323.66,259.48,324.03,259.67z"
              />
              <path
                d="M228.95,286.03c-0.46,0.32-0.66,0.55-0.89,0.58c-0.16,0.03-0.37-0.21-0.55-0.33c0.15-0.22,0.26-0.55,0.46-0.63
	C228.14,285.59,228.42,285.82,228.95,286.03z"
              />
              <path
                d="M314.66,286.32c-0.23-0.31-0.44-0.5-0.5-0.72c-0.03-0.11,0.33-0.44,0.39-0.42c0.23,0.11,0.45,0.3,0.59,0.51
	C315.18,285.75,314.91,286,314.66,286.32z"
              />
              <path
                d="M321.43,277.4c0.09,0.24,0.25,0.5,0.23,0.73c-0.01,0.13-0.39,0.37-0.5,0.33c-0.22-0.1-0.49-0.34-0.53-0.56
	c-0.05-0.28,0.11-0.59,0.18-0.89C321.02,277.14,321.23,277.27,321.43,277.4z"
              />
              <path
                d="M224.15,315.3c-0.46,0.32-0.66,0.55-0.89,0.58c-0.16,0.03-0.37-0.21-0.55-0.33c0.15-0.22,0.26-0.55,0.46-0.63
	C223.34,314.86,223.63,315.09,224.15,315.3z"
              />
              <path
                d="M314.83,278.54c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.42-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C314.15,277.92,314.38,278.21,314.83,278.54z"
              />
              <path
                d="M241.93,310.21c-0.55,0.22-0.85,0.45-1.03,0.38c-0.21-0.08-0.32-0.43-0.47-0.66c0.19-0.12,0.41-0.37,0.58-0.34
	C241.25,309.63,241.45,309.87,241.93,310.21z"
              />
              <path
                d="M327.31,255.99c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.41-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C326.63,255.37,326.86,255.66,327.31,255.99z"
              />
              <path
                d="M326.75,633.51c0.25,0.16,0.41,0.26,0.57,0.36c-0.15,0.12-0.29,0.28-0.46,0.35c-0.09,0.03-0.25-0.1-0.37-0.16
	C326.56,633.89,326.64,633.73,326.75,633.51z"
              />
              <path
                d="M326.15,247.88c0.17,0.48,0.34,0.75,0.32,0.99c-0.01,0.14-0.41,0.39-0.52,0.34c-0.24-0.11-0.4-0.38-0.59-0.58
	C325.56,248.45,325.76,248.26,326.15,247.88z"
              />
              <path
                d="M318.49,281.56c-0.23,0.34-0.36,0.67-0.46,0.66c-0.26-0.02-0.5-0.19-0.75-0.31c0.1-0.19,0.18-0.5,0.31-0.53
	C317.83,281.34,318.1,281.47,318.49,281.56z"
              />
              <path
                d="M261.27,166.62c0.08,0.31,0.25,0.64,0.2,0.92c-0.04,0.23-0.33,0.48-0.57,0.58c-0.11,0.05-0.51-0.21-0.52-0.35
	c-0.02-0.24,0.15-0.51,0.25-0.76C260.85,166.88,261.06,166.75,261.27,166.62z"
              />
              <path
                d="M478.63,70.73c-0.17,0.14-0.33,0.28-0.5,0.43c-0.86,0.31-1.72,0.63-2.62,0.96c0.08,0.59,0.18,1.28,0.27,1.92
	c0.93,0,1.8,0,2.66,0c0.16,0.31,0.32,0.61,0.48,0.92c0.25,0.85,0.49,1.7,0.75,2.59c0.77-0.04,1.69-0.1,2.61-0.15
	c0.08,0.16,0.15,0.33,0.23,0.49c0.35,0.98,0.71,1.97,1.08,3.02c2.68-0.34,3.36,0.22,3.63,3.23c0.01,0.08,0.04,0.15,0.06,0.23
	c-0.07,0.06-0.13,0.12-0.19,0.18c-1.03,0.45-2.05,0.9-3.15,1.38c0,0.79,0,1.64,0,2.49c-0.24,0.31-0.48,0.62-0.72,0.94
	c-0.84,0-1.68,0-2.54,0c0,0.97,0,1.74,0,2.33c1.13,0.19,2.16,0.37,3.2,0.55c0.17,0.08,0.35,0.16,0.52,0.24
	c0.02,1.04,0.04,2.08,0.05,3.12c-0.17,0.15-0.33,0.3-0.5,0.46c-0.89,0.25-1.78,0.51-2.66,0.76c0.06,0.76,0.12,1.52,0.17,2.07
	c1.02,0.23,2.18,0.19,2.86,0.73c0.69,0.55,0.92,1.68,1.39,2.64c0.78,0,1.64,0,2.51,0c0.18,0.19,0.36,0.37,0.54,0.56
	c0.35,1.11,0.48,2.22-0.01,3.32c-0.12,0.16-0.23,0.32-0.35,0.48c-1.02,0-2.03,0-3.05,0l0.07,0.06c-0.49-0.93-0.98-1.85-1.54-2.93
	c-0.67,0-1.59,0-2.52,0c-0.17-0.3-0.34-0.61-0.51-0.91c-0.18-0.83-0.36-1.66-0.53-2.45c-0.95,0-1.88,0-2.81,0
	c-0.17-0.3-0.33-0.61-0.5-0.91c-0.16-0.9-0.33-1.81-0.48-2.63c-0.89,0.06-1.81,0.13-2.72,0.19c-0.16-0.19-0.32-0.38-0.49-0.57
	c-0.37-0.85-0.73-1.69-1.14-2.64c-0.55,0.05-1.22,0.11-1.78,0.17c-0.13,1.06-0.25,2.05-0.37,3.05c-0.11,0.15-0.22,0.31-0.33,0.46
	c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.61-0.93-0.89-2.05-0.11-2.85c0.68-0.7,1.78-0.99,2.68-1.46
	c0.1-0.82,0.21-1.81,0.33-2.8c0.11-0.16,0.23-0.32,0.34-0.48c0.92-0.08,1.84-0.16,2.8-0.24c-0.09-0.87-0.17-1.7-0.25-2.42
	c-1.01-0.09-1.93-0.16-2.86-0.24c-0.17-0.15-0.33-0.3-0.5-0.45c-0.49-1.12-0.46-2.25-0.04-3.39c0.94-0.31,1.88-0.62,2.83-0.93
	c0-0.7,0-1.47,0-2.3c-0.9,0.04-1.71,0.08-2.44,0.12c-0.11,1.07-0.22,2.12-0.33,3.17c-1.11,0.44-2.2,0.99-3.42,0.38
	c0,0,0.06,0.05,0.06,0.05c-0.49-0.94-0.99-1.88-1.57-2.99c-0.42,0.05-1.09,0.12-1.83,0.21c0,1.02,0,1.89,0,2.76
	c-0.23,0.31-0.47,0.62-0.7,0.94c-0.92,0-1.84,0-2.75-0.01c-0.15-0.19-0.3-0.39-0.45-0.58c-0.69-0.75-0.99-1.58-0.6-2.6
	c0.53-1.4,1.81-1.55,3.05-1.69c0-1.05,0-1.91,0-2.77c0.17-0.18,0.35-0.35,0.52-0.53c2.25-0.83,3.74-0.7,4.43,2.8
	c0.75-0.04,1.51-0.07,2.2-0.1c0.06-0.97,0.11-1.83,0.16-2.69c0.14-0.18,0.28-0.36,0.42-0.54c0.95-0.27,1.9-0.54,2.86-0.81
	c-0.17-2.93,0.16-3.33,2.96-3.7c0.13-1.04,0.25-2.03,0.37-3.03c0.12-0.17,0.25-0.34,0.37-0.51
	C478.14,66.92,478.78,67.48,478.63,70.73z M473.01,85.22c1.09,0.17,2.18,0.03,2.7,0.5c0.73,0.66,1.05,1.78,1.59,2.78
	c0.65-0.07,1.4-0.16,2.14-0.24c-0.14-0.86-0.25-1.54-0.35-2.19c-2.98-0.1-3.29-0.37-3.96-3.39c-0.74,0.13-1.48,0.27-2.21,0.4
	C472.95,83.92,472.98,84.62,473.01,85.22z M472.14,75.66c0.07,0.93,0.13,1.75,0.19,2.56c0.91,0,1.81,0,2.73,0
	c-0.16-0.92-0.3-1.75-0.44-2.56C473.78,75.66,472.95,75.66,472.14,75.66z M476.24,81.51c0.81-0.16,1.61-0.31,2.43-0.47
	c-0.06-0.91-0.12-1.67-0.17-2.38c-0.96,0.09-1.8,0.17-2.61,0.24C476.02,79.83,476.13,80.66,476.24,81.51z M477.34,93.3
	c0,0.97,0,1.8,0,2.66c0.93-0.2,1.75-0.38,2.56-0.56c-0.14-0.81-0.25-1.48-0.38-2.23C478.7,93.22,478.01,93.26,477.34,93.3z
	 M482.37,84.5c0-0.81,0-1.64,0-2.55c-0.81,0.15-1.54,0.28-2.28,0.42c0.06,0.92,0.12,1.68,0.17,2.42
	C481,84.68,481.63,84.6,482.37,84.5z M473.32,90.07c0.07,0.82,0.14,1.59,0.21,2.35c0.89-0.08,1.71-0.16,2.54-0.24
	c-0.13-0.77-0.25-1.45-0.36-2.11C474.89,90.07,474.14,90.07,473.32,90.07z"
              />
              <path
                d="M618.26,347.74c-0.06,0.08-0.12,0.16-0.18,0.23c-1.05,0.17-2.11,0.33-3.16,0.5c-0.19-0.21-0.38-0.42-0.56-0.63
	c-0.36-0.83-0.73-1.66-1.12-2.55c-0.55,0.08-1.22,0.17-1.7,0.24c-0.15,1.04-0.27,1.94-0.4,2.84c-0.14,0.19-0.29,0.38-0.43,0.57
	c-0.98,0.01-1.96,0.02-2.95,0.03c-0.18-0.19-0.36-0.38-0.54-0.57c-0.09-0.9-0.58-2.07-0.19-2.62c0.58-0.81,1.79-1.18,2.65-1.69
	c0.13-0.85,0.27-1.76,0.41-2.67c0.15-0.2,0.31-0.4,0.46-0.59c0.9-0.1,1.81-0.21,2.71-0.32c-0.08-0.84-0.16-1.66-0.23-2.31
	c-1.02-0.09-1.93-0.17-2.84-0.26c-0.18-0.16-0.36-0.32-0.53-0.48c-0.52-1.14-0.48-2.28,0.03-3.42l-0.08,0.09
	c0.93-0.33,1.87-0.65,2.84-0.99c0.05-0.74,0.1-1.59,0.15-2.43c0.15-0.19,0.3-0.38,0.45-0.56c1.07-0.83,2.26-0.49,3.43-0.35
	c0.12,0.06,0.24,0.12,0.35,0.19c0.19,0.91,0.38,1.83,0.55,2.68c0.9,0,1.76,0,2.61,0c0.18,0.3,0.37,0.61,0.55,0.91
	c0.54,0.87,0.87,1.92,0.12,2.64c-0.79,0.75-1.92,1.12-2.94,1.69c0.09,0.57,0.2,1.35,0.29,1.96c1.07,0.11,2.07,0.21,3.07,0.31
	c0.16,0.2,0.32,0.4,0.48,0.61c0.03,0.97,0.06,1.94,0.09,2.9c-0.24,0.31-0.47,0.63-0.71,0.94c-0.84,0-1.69,0-2.52,0
	C618.35,345.86,618.3,346.8,618.26,347.74z M613.98,334.46c0.06,0.91,0.1,1.68,0.15,2.44c0.88-0.07,1.64-0.12,2.43-0.18
	c-0.14-0.89-0.27-1.71-0.4-2.54C615.42,334.28,614.79,334.36,613.98,334.46z M614.65,343.81c1-0.09,1.76-0.15,2.56-0.22
	c-0.17-0.83-0.31-1.5-0.43-2.09c-0.84,0-1.54,0-2.33,0C614.51,342.24,614.57,342.94,614.65,343.81z"
              />
              <path
                d="M517.97,147.42c-0.18-0.2-0.36-0.4-0.53-0.61c-0.37-0.83-0.73-1.65-1.1-2.49c-0.81-0.08-1.72-0.16-2.63-0.25
	c-0.17-0.15-0.33-0.3-0.5-0.45c-0.16-0.91-0.31-1.82-0.47-2.71c-0.89-0.06-1.89-0.13-2.89-0.2c-0.16-0.14-0.32-0.29-0.48-0.43
	c-0.05-1.11-0.09-2.23-0.14-3.34c0.2-0.17,0.39-0.34,0.59-0.51c0.76-0.11,1.53-0.22,2.27-0.32c0.08-0.93,0.15-1.77,0.23-2.62
	c0.15-0.19,0.29-0.38,0.44-0.56c0.94-0.26,1.88-0.53,2.78-0.78c0.04-0.78,0.09-1.63,0.13-2.49c0.15-0.19,0.3-0.37,0.45-0.56
	c1.08-0.84,2.28-0.48,3.45-0.35c0.11,0.06,0.22,0.12,0.33,0.18c0.19,0.91,0.38,1.82,0.56,2.69c0.91,0,1.76,0,2.61,0
	c0.17,0.3,0.33,0.61,0.5,0.91c0.56,0.76,0.94,1.71,0.28,2.42c-0.74,0.8-1.8,1.31-2.91,2.07c0.02,0.43,0.06,1.19,0.09,1.71
	c1.15,0.19,2.15,0.36,3.15,0.52c0.13,0.06,0.26,0.12,0.38,0.19c0.05,1.06,0.11,2.13,0.16,3.19c-0.24,0.31-0.47,0.62-0.71,0.94
	c-0.83,0-1.66,0-2.39,0c-0.21,1.15-0.37,2.04-0.53,2.92c-0.06,0.09-0.12,0.18-0.18,0.27C519.93,146.98,518.95,147.2,517.97,147.42z
	 M516.27,140c-0.06-1.02-0.12-2.04-0.18-3.1c-0.95,0.16-1.64,0.28-2.32,0.39c0,0.81,0,1.58,0,2.2c0.92,0.18,1.73,0.34,2.53,0.49
	c1.62,0.28,0.97,1.92,1.6,2.77c0.82-0.09,1.57-0.18,2.32-0.27c-0.14-0.85-0.26-1.52-0.37-2.19C518.59,140.2,517.42,140.1,516.27,140
	z M519.25,135.35c0-0.72,0-1.45,0-2.17c-0.95,0.05-1.72,0.09-2.5,0.13c0.11,0.83,0.22,1.59,0.32,2.35
	C517.84,135.55,518.53,135.45,519.25,135.35z"
              />
              <path
                d="M540.34,199.7c-0.16-0.31-0.32-0.61-0.48-0.92c-0.08-1.05-0.17-2.1-0.25-3.15c0.06-0.07,0.13-0.14,0.19-0.21
	c0.97-0.21,1.95-0.42,2.77-0.6c0.13-0.95,0.24-1.85,0.36-2.75c0.16-0.17,0.31-0.33,0.47-0.5c1.15-0.73,2.29-0.39,3.43,0.03
	l-0.1-0.08c0.28,0.94,0.56,1.87,0.82,2.73c0.92,0.09,1.76,0.17,2.6,0.25c0.15,0.14,0.31,0.28,0.46,0.41
	c0.57,0.94,0.79,1.91,0.22,2.93c-0.69,1.22-1.93,1.13-3.14,1.12c0.24,0.95,0.44,1.76,0.65,2.59c0.97,0,1.84,0,2.71,0
	c0.18,0.19,0.36,0.37,0.54,0.56c0,1.1,0,2.19,0.01,3.29c-0.13,0.17-0.26,0.35-0.39,0.52c-2.5,0.55-3.7,0.42-4.22-2.88
	c-0.92,0-1.86,0-2.8,0c-0.17-0.3-0.33-0.61-0.5-0.91c-0.18-0.87-0.37-1.73-0.52-2.44C542.16,199.7,541.25,199.7,540.34,199.7z
	 M546.75,198.21c-0.19-0.9-0.35-1.7-0.51-2.49c-0.83,0.11-1.51,0.21-2.14,0.3c0.09,0.92,0.16,1.72,0.23,2.47
	C545.12,198.41,545.81,198.32,546.75,198.21z"
              />
              <path
                d="M556.34,179.49c0.18,0.19,0.36,0.38,0.55,0.57c0.29,1.08,0.56,2.16,0.01,3.25c-0.15,0.19-0.29,0.37-0.44,0.56
	c-0.9,0.11-1.79,0.22-2.53,0.32c-0.16,1.05-0.31,2.08-0.46,3.11c0,0,0.08-0.09,0.08-0.09c-0.93,0.34-1.87,0.68-2.85,1.05
	c-0.06,0.69-0.14,1.54-0.21,2.39c-0.11,0.16-0.23,0.32-0.34,0.48c-1.04,0.92-2.22,0.64-3.39,0.41c0,0,0.1,0.08,0.1,0.08
	c-0.39-0.93-0.79-1.87-1.2-2.85c-0.68-0.11-1.45-0.23-2.22-0.35c-0.16-0.07-0.31-0.14-0.47-0.21c-0.67-0.82-1.09-1.68-0.63-2.79
	c0.56-1.35,1.83-1.48,3.01-1.65c0-1.04,0-1.89,0-2.74c0.18-0.18,0.35-0.36,0.53-0.54c2.99-0.79,3.7-0.35,4.4,2.72
	c0.76,0,1.52,0,2.22,0c0.05-0.98,0.1-1.84,0.14-2.69c0.15-0.19,0.3-0.37,0.45-0.56C554.16,179.8,555.25,179.65,556.34,179.49z
	 M546.74,184.96c0.08,0.81,0.16,1.5,0.23,2.24c0.78-0.07,1.48-0.12,2.24-0.19c-0.06-0.79-0.12-1.47-0.18-2.28
	C548.23,184.81,547.56,184.88,546.74,184.96z"
              />
              <path
                d="M551.8,125.85c-0.06,0.07-0.13,0.14-0.19,0.21c-0.87,0.34-1.74,0.68-2.65,1.03c0.05,0.69,0.12,1.51,0.17,2.26
	c0.99-0.05,1.86-0.09,2.73-0.14c0.16,0.3,0.32,0.61,0.49,0.91c0.3,0.77,0.61,1.54,0.93,2.36c0.88,0,1.74,0,2.61,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.36,1.11,0.47,2.23,0,3.34c-0.11,0.15-0.22,0.31-0.33,0.46c-1.02,0-2.04,0-3.06,0
	c0,0,0.07,0.06,0.07,0.06c-0.49-0.93-0.97-1.87-1.53-2.93c-0.69,0-1.62,0-2.55,0c-0.16-0.3-0.33-0.61-0.49-0.91
	c-0.18-0.83-0.35-1.67-0.52-2.44c-0.96,0-1.9,0-2.83,0c-0.16-0.31-0.32-0.61-0.49-0.92c-0.09-0.96-0.19-1.93-0.28-2.89
	c0.16-0.13,0.31-0.26,0.47-0.4c0.87-0.26,1.75-0.51,2.67-0.78c0-0.71,0-1.46,0-2.21c0.24-0.31,0.48-0.63,0.71-0.94
	c1.11-0.67,2.21-0.5,3.32-0.01c0.16,0.07,0.33,0.15,0.49,0.22C551.97,123.74,551.88,124.8,551.8,125.85z"
              />
              <path
                d="M529.79,84.11c0.16,0.31,0.32,0.61,0.48,0.92c0.58,0.75,0.95,1.7,0.29,2.41c-0.73,0.8-1.79,1.3-2.97,2.12
	c0,0.48,0,1.35,0,2.22c-0.23,0.31-0.47,0.62-0.7,0.94c-0.31-0.01-0.62-0.03-0.93-0.03c-2.39,0.02-3.09-0.78-2.89-3.33
	c0.08-0.17,0.16-0.35,0.23-0.52c0.89-0.4,1.79-0.8,2.76-1.23c0-0.61,0-1.36,0-2.04c-1.01-0.1-1.9-0.2-2.8-0.29
	c-0.11-0.06-0.22-0.12-0.33-0.18c-0.33-0.92-0.65-1.84-0.95-2.67c-0.85-0.08-1.7-0.16-2.54-0.24c-0.17-0.18-0.35-0.37-0.52-0.55
	c-0.1-0.95-0.2-1.89-0.3-2.84c0.11-0.16,0.23-0.31,0.34-0.47c1.1-0.18,2.21-0.36,3.31-0.54c0.15,0.18,0.31,0.36,0.46,0.55
	c0.2,0.83,0.41,1.66,0.6,2.44c0.89,0,1.75,0,2.61,0c0.16,0.31,0.32,0.61,0.48,0.92c0.25,0.84,0.5,1.68,0.77,2.57
	C527.96,84.2,528.87,84.15,529.79,84.11z"
              />
              <path
                d="M500.8,79.79c0.13,0.07,0.25,0.15,0.37,0.24c0.19,0.89,0.38,1.79,0.56,2.65c0.9,0,1.73,0,2.57,0c0.2,0.3,0.4,0.6,0.6,0.91
	c0.49,0.78,0.89,1.73,0.23,2.44c-0.74,0.79-1.8,1.29-2.97,2.09c0,0.49,0,1.35,0,2.22c-0.23,0.31-0.47,0.62-0.7,0.94
	c-3.22,0.01-3.56-0.25-4.18-3.1c-0.93-0.08-1.86-0.16-2.78-0.23c-0.16-0.14-0.32-0.29-0.48-0.43c-0.47-1.16-0.44-2.31,0.02-3.46
	l-0.08,0.09c0.94-0.31,1.88-0.62,2.82-0.93c0.07-0.81,0.15-1.66,0.22-2.5c0.12-0.17,0.25-0.34,0.38-0.5
	C498.44,79.36,499.63,79.64,500.8,79.79z M500.58,84.17c-0.85,0.11-1.56,0.21-2.32,0.31c0.1,0.82,0.18,1.49,0.26,2.2
	c0.79-0.11,1.46-0.2,2.21-0.3C500.68,85.61,500.63,84.94,500.58,84.17z"
              />
              <path
                d="M553.07,179.95c-0.15,0.19-0.3,0.37-0.45,0.56c-2.38,0.64-3.71,0.39-4.2-2.89c-0.91,0-1.85,0-2.78,0
	c-0.17-0.3-0.33-0.61-0.5-0.91c-0.08-1.04-0.16-2.08-0.24-3.12c0.06-0.08,0.12-0.16,0.18-0.24c1.06-0.13,2.12-0.25,3.18-0.38
	c0.17,0.13,0.35,0.25,0.52,0.38c0.38,0.91,0.75,1.82,1.12,2.7c0.71,0,1.41,0,2.11,0c-0.05-3.25,0.24-3.59,3.05-3.97
	c0.12-1.04,0.23-2.03,0.34-3.03c0.12-0.17,0.24-0.33,0.36-0.5c1.01,0.02,2.03,0.04,3.04,0.06c0.15,0.13,0.29,0.26,0.44,0.39
	c0.16,0.98,0.32,1.96,0.48,2.94c-0.19,0.16-0.37,0.32-0.56,0.48c-0.84,0.29-1.68,0.58-2.42,0.83c-0.11,0.95-0.23,1.93-0.35,2.9
	c-0.13,0.17-0.25,0.33-0.38,0.5c-0.92,0.11-1.83,0.21-2.62,0.3C553.29,177.99,553.18,178.97,553.07,179.95z"
              />
              <path
                d="M633.91,450.7c0.08,0.16,0.15,0.33,0.23,0.49c0.87,0.99,1.07,2.09,0.48,3.29c-0.17,0.17-0.34,0.35-0.51,0.52
	c-0.78,0.24-1.57,0.49-2.39,0.74c0,0.91,0,1.76,0,2.61c-0.23,0.31-0.46,0.62-0.69,0.94c-0.31-0.01-0.62-0.04-0.93-0.04
	c-2.39,0.02-3.07-0.76-2.91-3.32c0.07-0.15,0.14-0.31,0.21-0.46c0.95-0.43,1.91-0.87,2.9-1.32c-0.06-0.63-0.12-1.29-0.17-1.81
	c-1.05-0.09-1.89-0.16-2.74-0.24c-0.17-0.18-0.33-0.35-0.5-0.53c-0.62-0.9-0.64-1.86-0.28-2.86c0.1-0.15,0.2-0.3,0.31-0.44
	c1.08-0.93,2.34-0.49,3.54-0.45c0.1,0.06,0.19,0.12,0.28,0.18c0.18,0.95,0.36,1.9,0.54,2.8
	C632.24,450.78,633.07,450.74,633.91,450.7z"
              />
              <path
                d="M482.51,77.88c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.02-0.16-2.03-0.24-3.05c0.09-0.11,0.19-0.21,0.3-0.3
	c0.91-0.22,1.83-0.43,2.82-0.67c0-0.74,0-1.5,0-2.26c0.23-0.31,0.45-0.62,0.68-0.93c1.15-0.73,2.29-0.4,3.43,0.03
	c0,0-0.09-0.08-0.09-0.08c0.16,1.14,0.32,2.28,0.48,3.41c-0.14,0.13-0.28,0.25-0.42,0.38c-0.88,0.27-1.75,0.54-2.7,0.82
	c0.09,0.65,0.19,1.31,0.3,2.08c0.97,0,1.9,0,2.83,0c0.17,0.18,0.34,0.36,0.5,0.54c0.36,1.12,0.46,2.24,0,3.36
	c-0.1,0.15-0.21,0.3-0.31,0.45c-2.75,0.52-3.87,0.27-4.19-2.77C484.57,78.22,483.54,78.05,482.51,77.88z"
              />
              <path
                d="M605.23,320.62c-0.15-0.19-0.3-0.38-0.45-0.58c-0.96-0.96-0.92-2.05-0.42-3.21c0.16-0.17,0.32-0.34,0.48-0.5
	c1.15-0.74,2.29-0.4,3.42,0.04c0,0-0.09-0.08-0.09-0.08c0.29,0.93,0.57,1.86,0.84,2.72c0.91,0.09,1.75,0.17,2.58,0.25
	c0.16,0.15,0.33,0.3,0.49,0.44c1.06,2.82,0.44,3.81-2.52,4.14c-0.12,1.01-0.24,1.99-0.36,2.98c-0.13,0.18-0.26,0.35-0.4,0.53
	c-0.45,0.08-0.91,0.21-1.36,0.23c-1.55,0.09-2.49-0.72-2.63-2.26c-0.04-0.46,0.04-0.93,0.07-1.39c0.14-0.13,0.28-0.26,0.42-0.38
	c0.87-0.3,1.73-0.61,2.71-0.95c-0.1-0.58-0.22-1.24-0.35-1.97C606.78,320.62,606.01,320.62,605.23,320.62z"
              />
              <path
                d="M513.21,143.63c0.17,0.15,0.33,0.3,0.5,0.45c1.03,2.83,0.43,3.77-2.54,4.16c-0.12,1.03-0.24,2.02-0.36,3
	c-0.11,0.16-0.23,0.31-0.34,0.47c-1.01,0.01-2.02,0.02-3.02,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.25-0.79-0.51-1.59-0.76-2.36
	c-0.94,0-1.87,0-2.8,0c-0.16-0.31-0.31-0.61-0.47-0.92c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19
	c1.06-0.1,2.12-0.19,3.18-0.29c0.15,0.09,0.31,0.18,0.46,0.28c0.39,0.9,0.78,1.81,1.21,2.79c0.59-0.04,1.26-0.09,1.82-0.13
	c0.08-1.1,0.15-2.09,0.22-3.09c0.19-0.16,0.37-0.32,0.56-0.48C511.27,143.64,512.24,143.63,513.21,143.63z"
              />
              <path
                d="M568.32,149.72c0.14,0.19,0.29,0.37,0.43,0.56c-0.05,0.98-0.11,1.95-0.16,2.93c-0.06,0.06-0.13,0.12-0.19,0.18
	c-0.98,0.25-1.97,0.49-2.95,0.74c-0.16-0.18-0.31-0.37-0.47-0.56c-0.36-0.85-0.72-1.7-1.13-2.67c-0.54,0.05-1.23,0.11-1.8,0.16
	c-0.13,1.05-0.25,2.05-0.37,3.04c-0.12,0.16-0.24,0.33-0.37,0.49c-1,0.01-2,0.02-3,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-1.03-2.74-0.42-3.65,2.59-4.06c0.07-1.03,0.15-2.02,0.22-3.02c0.19-0.16,0.37-0.32,0.56-0.48c0.99,0.07,2.21-0.2,2.89,0.29
	c0.73,0.53,0.95,1.77,1.32,2.54C566.35,149.48,567.34,149.6,568.32,149.72z"
              />
              <path
                d="M556.06,136.84c0.11-0.15,0.22-0.31,0.33-0.46c1.1-0.18,2.21-0.37,3.31-0.55c0.18,0.19,0.36,0.38,0.54,0.57
	c0.38,1.14,0.43,2.27-0.06,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.9,0.66-2.93,1.01c0,0.71,0,1.58,0,2.44
	c-0.19,0.16-0.38,0.32-0.58,0.49c-0.97,0.98-2.15,0.53-3.27,0.45c-0.37-0.94-0.75-1.88-1.16-2.94c-0.7,0-1.49,0-2.28,0
	c-0.15-0.2-0.29-0.4-0.44-0.6c-0.96-1.01-1.09-2.12-0.23-3.22c0.78-0.99,1.84-1.17,3.01-0.67c0.22,0.09,0.45,0.15,0.68,0.22
	l-0.07-0.06c0.28,0.94,0.56,1.89,0.84,2.86c0.7-0.07,1.38-0.14,1.97-0.2C555.9,138.51,555.98,137.68,556.06,136.84z"
              />
              <path
                d="M449.5,73.98c0.13-0.17,0.25-0.34,0.38-0.5c1.05-0.8,2.23-0.52,3.39-0.39c0,0-0.09-0.08-0.09-0.08c0.39,1,0.79,2,1.15,2.94
	c0.8,0,1.66,0,2.52,0c0.15,0.31,0.31,0.61,0.46,0.92c0.57,0.73,0.76,1.49,0.38,2.39c-0.56,1.35-1.85,1.44-3.06,1.65
	c0,1.01,0,1.86,0,2.71c-0.15,0.16-0.3,0.32-0.46,0.49c-1.14,0.41-2.29,0.84-3.35-0.13c-1.03-0.95-0.99-2.09-0.45-3.27
	c0,0-0.05,0.07-0.05,0.07c0.95-0.44,1.91-0.89,2.92-1.36c-0.05-0.6-0.11-1.26-0.15-1.8c-1.05-0.09-1.89-0.16-2.73-0.24
	c-0.17-0.18-0.34-0.36-0.52-0.55C449.72,75.88,449.61,74.93,449.5,73.98z"
              />
              <path
                d="M546.28,100.98c-0.15,0.13-0.3,0.26-0.46,0.39c-0.91,0.33-1.82,0.65-2.73,0.98c0.12,0.68,0.24,1.35,0.35,1.92
	c0.94,0,1.8,0,2.65,0c0.16,0.31,0.32,0.61,0.48,0.92c0.77,0.9,0.67,1.86,0.23,2.86c-0.18,0.18-0.35,0.36-0.53,0.54
	c-0.92,0.58-1.88,0.58-2.87,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.34-0.92-0.68-1.84-0.99-2.69c-0.84-0.07-1.69-0.14-2.53-0.22
	c-0.17-0.18-0.34-0.36-0.51-0.54c-1.02-2.74-0.42-3.64,2.6-4.06c0.07-1.03,0.15-2.03,0.22-3.03c0.18-0.15,0.37-0.31,0.55-0.46
	c0.98-0.01,1.95-0.02,2.93-0.03l-0.09-0.08C546.33,98.45,546.51,99.65,546.28,100.98z"
              />
              <path
                d="M518.97,78.31c-0.11,0.16-0.23,0.31-0.34,0.47c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.53
	c-1.03-2.74-0.42-3.65,2.6-4.07c0.07-1.03,0.15-2.03,0.22-3.03c0.18-0.15,0.37-0.31,0.55-0.46c0.97,0.01,1.95,0.02,2.92,0.02
	c0.14,0.13,0.29,0.26,0.43,0.38c0.28,0.88,0.55,1.76,0.76,2.42c1.04,0.11,2.04,0.22,3.04,0.33c0.15,0.19,0.3,0.39,0.45,0.58
	c-0.06,0.98-0.11,1.95-0.17,2.93c-0.06,0.06-0.13,0.12-0.19,0.18c-0.84,0.82-1.9,0.77-2.95,0.74c-0.15-0.18-0.31-0.36-0.46-0.55
	c-0.38-0.89-0.76-1.78-1.16-2.7c-0.62,0.06-1.29,0.13-1.78,0.18C519.2,76.36,519.08,77.34,518.97,78.31z"
              />
              <path
                d="M501.19,159.63c-0.16-0.07-0.33-0.15-0.49-0.22c-0.89-1.08-1.19-2.24-0.22-3.4c0.88-1.06,2.02-1.05,3.21-0.55
	c0.14,0.06,0.3,0.1,0.44,0.15c0,0-0.07-0.06-0.07-0.06c0.28,0.95,0.56,1.89,0.84,2.87c0.71-0.07,1.38-0.14,1.97-0.2
	c0.09-0.99,0.17-1.83,0.25-2.67c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.21-0.35,3.32-0.53c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.28-0.05,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.91,0.65-2.94,1.01c0,0.71,0,1.58,0,2.46
	c-0.19,0.16-0.38,0.32-0.57,0.48c-1,0.95-2.16,0.57-3.3,0.39c0,0,0.1,0.08,0.1,0.08c-0.39-0.93-0.79-1.87-1.2-2.85
	C502.73,159.87,501.96,159.75,501.19,159.63z"
              />
              <path
                d="M488.39,106.61c0.12-0.16,0.23-0.32,0.35-0.48c1.11-0.17,2.21-0.34,3.32-0.52c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.28-0.05,3.4l0.08-0.09c-0.97,0.34-1.94,0.68-2.94,1.03c0,0.76,0,1.6,0,2.44c-0.18,0.15-0.37,0.31-0.55,0.46
	c-1.01,0.94-2.17,0.57-3.31,0.4c0,0,0.1,0.08,0.1,0.08c-0.39-0.94-0.78-1.87-1.19-2.85c-0.69-0.1-1.46-0.22-2.24-0.33
	c-0.18-0.08-0.35-0.16-0.53-0.25c-0.86-1.09-1.14-2.25-0.18-3.4c0.88-1.05,2.02-1.04,3.2-0.54c0.14,0.06,0.29,0.1,0.44,0.16
	c0,0-0.07-0.06-0.07-0.06c0.28,0.95,0.56,1.89,0.84,2.87c0.71-0.07,1.39-0.13,1.98-0.19C488.24,108.3,488.32,107.45,488.39,106.61z"
              />
              <path
                d="M487.76,135.43c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.43-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09
	c0.96-0.3,1.92-0.6,2.84-0.89c0.08-0.84,0.17-1.7,0.25-2.55c0.1-0.15,0.2-0.3,0.31-0.44c1.02-0.89,2.23-0.51,3.38-0.51
	c0.36,1.01,0.72,2.02,1.08,3.05c0.8-0.04,1.66-0.08,2.52-0.12c0.15,0.31,0.31,0.61,0.46,0.92c0.81,1,0.79,2.05-0.01,3
	c-0.8,0.95-1.85,1.09-2.99,0.6c-0.22-0.09-0.44-0.17-0.66-0.25l0.08,0.06c-0.27-0.94-0.55-1.88-0.82-2.83
	c-0.74,0.07-1.42,0.13-2.02,0.19c-0.08,0.93-0.16,1.78-0.23,2.64c-0.11,0.15-0.21,0.31-0.32,0.46
	C490.11,135.86,488.93,135.64,487.76,135.43z"
              />
              <path
                d="M573.65,163.53c-0.07,0.06-0.13,0.12-0.2,0.18c-1.03,0.45-2.06,0.9-3.15,1.37c0,0.8,0,1.65,0,2.49
	c-0.24,0.31-0.48,0.63-0.72,0.94c-0.89-0.08-1.77-0.17-2.66-0.25c-0.16-0.07-0.31-0.14-0.47-0.21c-0.67-0.83-1.09-1.68-0.63-2.79
	c0.57-1.35,1.83-1.48,3.02-1.65c0-1.04,0-1.89,0-2.74c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43
	c0.16,0.31,0.32,0.61,0.49,0.92C573.2,161.73,573.42,162.63,573.65,163.53z"
              />
              <path
                d="M564.57,269.29c-0.16,0.15-0.33,0.3-0.49,0.44c-0.88,0.25-1.76,0.51-2.54,0.73c-0.11,0.87-0.22,1.76-0.34,2.64
	c-0.17,0.17-0.34,0.34-0.51,0.52c-1.09,0.71-2.2,0.48-3.31,0.07c-0.08-0.18-0.16-0.36-0.25-0.53c-0.09-1.06-0.17-2.12-0.26-3.17
	c0.07-0.06,0.13-0.12,0.19-0.18c0.96-0.21,1.93-0.43,2.9-0.65c0-0.77,0-1.53,0-2.28c0.25-0.31,0.5-0.63,0.74-0.94
	c1.14-0.74,2.27-0.41,3.39,0.05l-0.09-0.08C564.2,267.04,564.39,268.17,564.57,269.29z"
              />
              <path
                d="M491.12,134.96c0.11-0.15,0.21-0.31,0.32-0.46c1.03,0,2.05,0,3.08,0l-0.08-0.06c0.48,1.01,0.97,2.01,1.4,2.92
	c0.92,0.15,1.96,0.31,2.99,0.48c0.09,0.03,0.18,0.07,0.27,0.11c0.05,1.08,0.11,2.16,0.16,3.25c-0.23,0.31-0.45,0.62-0.68,0.93
	c-0.89-0.08-1.79-0.17-2.68-0.25c-0.16-0.07-0.31-0.14-0.47-0.21c-0.4-0.9-0.79-1.79-1.18-2.66c-0.83-0.07-1.75-0.14-2.66-0.22
	c-0.15-0.13-0.3-0.27-0.45-0.4C490.74,137.25,490.67,136.11,491.12,134.96z"
              />
              <path
                d="M548.23,122.47c-0.24,0.31-0.48,0.63-0.71,0.94c-3.22,0.01-3.55-0.24-4.18-3.1c-0.95-0.08-1.88-0.16-2.8-0.24
	c-0.15-0.13-0.3-0.26-0.44-0.4c-0.4-1.14-0.48-2.28-0.03-3.43c0.11-0.15,0.21-0.31,0.32-0.46c1.02,0.07,2.05,0.15,3.07,0.22
	l0.1,0.09c0.42,0.86,0.83,1.73,1.22,2.53c0.89,0.15,1.93,0.32,2.98,0.49c0.15,0.07,0.31,0.14,0.46,0.21
	C548.22,120.37,548.23,121.42,548.23,122.47z"
              />
              <path
                d="M455.12,90.81c-0.23,0.31-0.45,0.62-0.68,0.93c-0.91,0-1.82,0-2.73,0c-0.15-0.18-0.31-0.36-0.46-0.54
	c-0.36-0.85-0.73-1.71-1.1-2.59c-0.84-0.07-1.77-0.14-2.71-0.22c-0.15-0.13-0.29-0.26-0.44-0.39c-0.04-1.13-0.08-2.26-0.12-3.39
	c0.19-0.16,0.37-0.31,0.56-0.47c3.12-0.4,3.63-0.03,4.02,2.79c1.12,0.18,2.17,0.35,3.22,0.52c0.09,0.03,0.19,0.07,0.28,0.11
	C455.02,88.65,455.07,89.73,455.12,90.81z"
              />
              <path
                d="M507.45,155.09c-0.11,0.15-0.22,0.31-0.33,0.46c-1.02,0-2.04,0-3.06,0l0.07,0.06c-0.49-0.94-0.97-1.88-1.52-2.93
	c-0.7,0-1.64,0-2.57,0c-0.16-0.31-0.32-0.61-0.47-0.92c-0.1-0.96-0.2-1.92-0.3-2.88c0.18-0.18,0.35-0.36,0.53-0.54
	c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.31,0.31,0.61,0.47,0.92c0.31,0.77,0.62,1.54,0.95,2.36c0.87,0,1.74,0,2.6,0
	c0.17,0.18,0.34,0.36,0.51,0.54C507.81,152.86,507.92,153.97,507.45,155.09z"
              />
              <path
                d="M466.15,96.98c0.17,0.18,0.34,0.36,0.51,0.54c0.36,1.11,0.47,2.23,0,3.34c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.99-0.03-2.2,0.28-2.91-0.18c-0.77-0.5-1.06-1.71-1.61-2.69c-0.73,0-1.65,0-2.58,0c-0.16-0.31-0.31-0.61-0.47-0.92
	c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19c1.06-0.1,2.12-0.19,3.18-0.29c0.15,0.09,0.31,0.18,0.46,0.28
	c0.39,0.89,0.77,1.78,1.22,2.82C464.31,96.98,465.23,96.98,466.15,96.98z"
              />
              <path
                d="M566.46,168.06c0.16,0.07,0.31,0.14,0.47,0.21c0.01,1.05,0.02,2.1,0.03,3.15c-0.24,0.31-0.49,0.63-0.73,0.94
	c-3.21,0.02-3.54-0.24-4.16-3.11c-0.95-0.08-1.88-0.15-2.81-0.23c-0.15-0.13-0.29-0.26-0.44-0.39c-0.04-1.13-0.07-2.26-0.11-3.4
	c0.18-0.15,0.36-0.31,0.54-0.46c0.98,0.06,1.95,0.13,2.93,0.19c0,0,0.1,0.08,0.1,0.08c0.42,0.86,0.83,1.73,1.21,2.53
	C564.38,167.71,565.42,167.89,566.46,168.06z"
              />
              <path
                d="M539.8,195.43c-0.06,0.07-0.13,0.14-0.19,0.21c-0.98,0.24-1.97,0.48-2.95,0.72c-0.16-0.19-0.33-0.38-0.49-0.57
	c-0.36-0.84-0.73-1.69-1.1-2.56c-0.82-0.07-1.75-0.14-2.68-0.22c-0.15-0.14-0.31-0.27-0.46-0.41c-0.04-1.12-0.08-2.25-0.13-3.37
	c0.19-0.16,0.38-0.32,0.57-0.49c0.99,0.07,2.21-0.2,2.89,0.29c0.73,0.52,0.95,1.76,1.29,2.48c1.03,0.18,2.01,0.35,2.99,0.52
	c0.17,0.08,0.33,0.15,0.5,0.23C539.97,193.32,539.89,194.38,539.8,195.43z"
              />
              <path
                d="M569.36,160.35c-0.17,0.17-0.34,0.35-0.51,0.52c-1.06,0.72-2.17,0.43-3.28,0.19c-0.09-0.22-0.18-0.44-0.27-0.66
	c-0.18-0.75-0.35-1.5-0.51-2.18c-0.91-0.08-1.77-0.16-2.63-0.23c-0.18-0.19-0.35-0.38-0.53-0.57c-0.11-0.94-0.21-1.88-0.32-2.83
	c0.12-0.16,0.24-0.33,0.37-0.49c1.1-0.18,2.2-0.35,3.3-0.53c0.16,0.18,0.31,0.37,0.47,0.56c0.2,0.83,0.41,1.67,0.6,2.43
	c0.89,0,1.75,0,2.61,0c0.08,0.16,0.15,0.33,0.23,0.49C569.75,158.05,569.94,159.14,569.36,160.35z"
              />
              <path
                d="M506.49,144.55c-0.15-0.09-0.31-0.18-0.46-0.28c-0.2-0.87-0.41-1.74-0.61-2.63c-0.88,0-1.64,0-2.4,0
	c-0.15-0.19-0.3-0.38-0.45-0.57c-0.82-0.82-0.87-1.81-0.66-2.86c0.23-0.28,0.47-0.57,0.7-0.85c1.15-0.72,2.29-0.45,3.43,0.03
	l-0.09-0.08c0.28,0.93,0.57,1.87,0.83,2.74c0.91,0.08,1.75,0.16,2.59,0.23c0.16,0.14,0.32,0.29,0.48,0.43
	c0.15,0.98,0.3,1.96,0.45,2.93c-0.19,0.16-0.37,0.32-0.56,0.48C508.76,145.04,507.62,144.73,506.49,144.55z"
              />
              <path
                d="M511.29,155.11c-0.17-0.18-0.35-0.37-0.52-0.55c-0.1-0.95-0.2-1.9-0.3-2.84c0.11-0.16,0.23-0.31,0.34-0.47
	c1.1-0.18,2.21-0.36,3.31-0.54c0.18,0.19,0.36,0.38,0.54,0.57c0.17,0.83,0.34,1.66,0.5,2.42c0.91,0,1.76,0,2.61,0
	c0.19,0.3,0.37,0.61,0.56,0.91c0.96,2.51,0.14,3.81-2.43,3.84c-0.31,0-0.63,0-0.94,0c-0.1-0.07-0.19-0.15-0.28-0.23
	c-0.28-1-0.55-1.99-0.79-2.85C512.94,155.27,512.11,155.19,511.29,155.11z"
              />
              <path
                d="M619.27,485.24c-0.16-0.15-0.32-0.29-0.48-0.44c-0.42-1.12-0.5-2.24-0.03-3.36c0.12-0.17,0.24-0.33,0.36-0.5
	c0.83-0.11,1.66-0.22,2.43-0.31c0.08-0.95,0.16-1.79,0.23-2.62c0.12-0.16,0.24-0.33,0.37-0.49c1.08-0.87,2.3-0.52,3.49-0.41
	c0.11,0.06,0.21,0.12,0.31,0.18c0.16,1.19,0.68,2.38,0.04,3.57c-0.09,0.14-0.17,0.28-0.26,0.42c-0.84,0.16-1.69,0.32-2.65,0.5
	c0,0.81,0,1.67,0,2.53c-0.2,0.17-0.4,0.35-0.6,0.52C621.51,485.77,620.38,485.43,619.27,485.24z"
              />
              <path
                d="M462.35,94.17c-0.15-0.09-0.31-0.18-0.46-0.28c-0.2-0.87-0.41-1.74-0.61-2.63c-0.88,0-1.64,0-2.4,0
	c-0.15-0.19-0.3-0.38-0.45-0.57c-0.81-0.81-0.88-1.8-0.65-2.85c0.23-0.29,0.46-0.58,0.69-0.86c1.08-0.67,2.19-0.43,3.31-0.14
	c0.15,0.19,0.31,0.39,0.46,0.58c0.1,0.76,0.19,1.52,0.28,2.22c1.02,0.1,1.87,0.19,2.72,0.28c0.15,0.13,0.3,0.27,0.45,0.4
	c1.15,2.95,0.19,4.25-2.87,3.9C462.66,94.21,462.51,94.19,462.35,94.17z"
              />
              <path
                d="M568.36,146c-0.22,0.31-0.45,0.62-0.67,0.93c-3.43,0.07-3.57-0.42-4.19-3.11c-0.94-0.08-1.87-0.15-2.8-0.23
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.42-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09c1.11-0.5,2.21-1.08,3.43-0.3
	c0.09,0.08,0.18,0.16,0.28,0.23c0.34,0.88,0.67,1.76,0.93,2.43c1.05,0.12,2.02,0.22,3,0.33c0.16,0.2,0.32,0.41,0.48,0.61
	C568.32,144.05,568.34,145.03,568.36,146z"
              />
              <path
                d="M613.95,460.5c-0.17-0.08-0.33-0.15-0.5-0.23c-0.74-0.84-0.98-1.8-0.68-2.89c0.24-0.31,0.49-0.62,0.73-0.94
	c1.14-0.68,2.27-0.49,3.39,0.03l-0.07-0.06c0.28,0.93,0.56,1.86,0.81,2.7c0.93,0.1,1.76,0.18,2.59,0.27
	c0.16,0.14,0.32,0.29,0.48,0.43c0.15,0.97,0.3,1.94,0.45,2.91c-0.2,0.17-0.4,0.34-0.6,0.51c-0.99,1-2.15,0.55-3.27,0.37l0.09,0.08
	c-0.4-0.93-0.8-1.86-1.23-2.84C615.49,460.74,614.72,460.62,613.95,460.5z"
              />
              <path
                d="M477,99.46c0.17,0.3,0.33,0.61,0.5,0.91c0.73,0.91,0.64,1.88,0.21,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	c-0.92,0.57-1.89,0.58-2.88,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.34-0.92-0.67-1.85-0.98-2.69c-0.85-0.07-1.69-0.14-2.54-0.22
	c-0.17-0.18-0.34-0.36-0.51-0.54c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c1.1-0.19,2.2-0.38,3.3-0.57
	c0.16,0.19,0.32,0.38,0.49,0.57c0.21,0.87,0.42,1.73,0.62,2.55C475.33,99.54,476.17,99.5,477,99.46z"
              />
              <path
                d="M525.24,193.5c-0.16-0.11-0.33-0.22-0.49-0.33c-0.19-0.83-0.39-1.65-0.57-2.45c-0.84-0.13-1.6-0.25-2.36-0.37
	c-0.18-0.09-0.35-0.17-0.53-0.26c-0.73-0.84-0.94-1.81-0.67-2.88c0.25-0.31,0.5-0.63,0.75-0.94c1.14-0.74,2.27-0.39,3.39,0.05
	l-0.09-0.08c0.29,0.93,0.57,1.86,0.84,2.73c0.91,0.09,1.74,0.17,2.58,0.25c0.15,0.14,0.31,0.28,0.46,0.41
	c0.11,0.97,0.22,1.94,0.33,2.91c-0.11,0.16-0.23,0.32-0.34,0.48C527.54,193.91,526.39,193.73,525.24,193.5z"
              />
              <path
                d="M609.65,337.46c0.18,0.16,0.36,0.32,0.53,0.48c0.12,0.96,0.24,1.92,0.35,2.88c-0.15,0.2-0.31,0.4-0.46,0.59
	c-1.02,0.77-2.15,0.61-3.28,0.36c-0.16-0.15-0.33-0.3-0.49-0.44c-0.2-0.8-0.4-1.6-0.59-2.36c-0.84-0.12-1.58-0.22-2.33-0.33
	c-0.19-0.09-0.37-0.18-0.56-0.27c-0.85-1.1-1.11-2.27-0.14-3.4c0.88-1.04,2.02-1.03,3.2-0.54c0.14,0.06,0.29,0.11,0.44,0.16
	c0,0-0.07-0.05-0.07-0.05c0.28,0.93,0.56,1.85,0.82,2.72C607.99,337.33,608.82,337.39,609.65,337.46z"
              />
              <path
                d="M528.56,189.65c-0.15-0.14-0.31-0.28-0.46-0.41c-0.39-1.11-0.52-2.23-0.04-3.35c0.13-0.18,0.26-0.35,0.39-0.53
	c0.83-0.1,1.67-0.2,2.43-0.3c0.08-0.95,0.14-1.8,0.21-2.64c0.12-0.16,0.24-0.33,0.37-0.49c1.1-0.16,2.21-0.33,3.31-0.49
	c0.17,0.18,0.34,0.36,0.51,0.54c0.35,1.11,0.49,2.21,0.01,3.32c-0.07,0.14-0.14,0.27-0.22,0.39c-0.88,0.16-1.77,0.32-2.68,0.49
	c0,0.89,0,1.73,0,2.57c-0.19,0.16-0.38,0.32-0.57,0.49C530.83,190.16,529.68,189.85,528.56,189.65z"
              />
              <path
                d="M532.88,153.19c-0.17-0.18-0.35-0.37-0.52-0.55c-1.02-2.73-0.42-3.64,2.55-4.06c0.12-1.03,0.23-2.02,0.35-3.01
	c0.12-0.17,0.25-0.34,0.37-0.51c1.01,0.01,2.02,0.03,3.03,0.04c0.15,0.14,0.31,0.27,0.46,0.41c0.15,0.99,0.31,1.97,0.46,2.96
	c-0.16,0.14-0.33,0.28-0.49,0.42c-0.85,0.31-1.71,0.62-2.49,0.91c-0.11,0.9-0.23,1.89-0.34,2.89c-0.12,0.15-0.24,0.3-0.36,0.45
	C534.88,153.15,533.88,153.17,532.88,153.19z"
              />
              <path
                d="M551.61,202.13c-0.18-0.19-0.36-0.37-0.54-0.56c-1.01-2.72-0.42-3.62,2.53-4.05c0.13-1.04,0.24-2.02,0.36-3
	c0.13-0.17,0.26-0.35,0.38-0.52c1,0,2,0,3.01,0c0.17,0.16,0.35,0.31,0.52,0.47c0.14,0.97,0.28,1.93,0.42,2.9
	c-0.18,0.16-0.37,0.33-0.55,0.49c-0.83,0.31-1.67,0.62-2.44,0.9c-0.11,0.9-0.22,1.87-0.34,2.84c-0.13,0.17-0.25,0.34-0.38,0.5
	C553.59,202.11,552.6,202.12,551.61,202.13z"
              />
              <path
                d="M611.56,395.05c-0.18,0.16-0.37,0.33-0.55,0.49c-0.84,0.3-1.68,0.6-2.46,0.88c-0.1,0.91-0.22,1.89-0.33,2.87
	c-0.12,0.16-0.24,0.33-0.36,0.49c-1,0.01-2.01,0.02-3.01,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.14-0.97-0.29-1.93-0.43-2.9
	c0.18-0.15,0.36-0.29,0.54-0.44c0.86-0.28,1.72-0.56,2.42-0.79c0.12-0.99,0.23-1.97,0.35-2.95c0.12-0.17,0.25-0.34,0.37-0.51
	C611.05,391.29,611.68,391.83,611.56,395.05z"
              />
              <path
                d="M518.46,70.72c-0.18,0.15-0.37,0.31-0.55,0.46c-1,0.91-2.16,0.6-3.29,0.43c-0.14-0.13-0.29-0.26-0.43-0.38
	c-0.19-0.89-0.38-1.77-0.54-2.52c-0.92,0-1.69,0-2.47,0c-0.14-0.19-0.28-0.37-0.43-0.56c-0.94-0.97-0.92-2.07-0.43-3.23
	c0.15-0.16,0.3-0.32,0.46-0.49c1.15-0.64,2.29-0.51,3.43,0.02c0,0-0.07-0.06-0.07-0.06c0.27,0.94,0.55,1.88,0.8,2.72
	c0.94,0.1,1.78,0.19,2.61,0.28c0.14,0.13,0.29,0.26,0.43,0.38C518.14,68.75,518.3,69.73,518.46,70.72z"
              />
              <path
                d="M620.7,459.81c-0.16-0.14-0.32-0.29-0.48-0.43c-0.47-1.16-0.44-2.31,0.02-3.46l-0.08,0.09c0.95-0.31,1.9-0.63,2.84-0.94
	c0.08-0.8,0.17-1.65,0.25-2.5c0.1-0.15,0.2-0.3,0.31-0.44c1-0.85,2.19-0.56,3.34-0.53c0.17,0.18,0.33,0.35,0.5,0.53
	c0.37,1.12,0.46,2.24,0.01,3.36c-0.07,0.15-0.14,0.31-0.21,0.46c-0.89,0.11-1.78,0.21-2.69,0.32c0,0.94,0,1.78,0,2.62
	c-0.2,0.16-0.39,0.32-0.59,0.49C622.95,460.33,621.82,459.98,620.7,459.81z"
              />
              <path
                d="M514.18,71.22c0.14,0.13,0.29,0.26,0.43,0.38c1.17,2.93,0.22,4.24-2.86,3.9c-0.16-0.02-0.31-0.04-0.47-0.06
	c-0.15-0.09-0.3-0.18-0.46-0.27c-0.18-0.91-0.36-1.82-0.51-2.57c-0.89-0.11-1.65-0.2-2.42-0.3c-0.31-0.21-0.61-0.43-0.92-0.64
	c-0.44-0.97-0.48-1.93-0.03-2.91c0.16-0.17,0.31-0.33,0.47-0.5c1.09-0.67,2.2-0.41,3.32-0.12c0.14,0.19,0.28,0.37,0.43,0.56
	c0.1,0.76,0.2,1.53,0.3,2.23C512.48,71.04,513.33,71.13,514.18,71.22z"
              />
              <path
                d="M620.55,463.23c0.2-0.17,0.4-0.34,0.6-0.51c0.97,0.01,1.94,0.02,2.92,0.02c0.15,0.14,0.31,0.28,0.46,0.42
	c1.09,2.8,0.47,3.8-2.49,4.15c-0.13,1.02-0.25,2-0.38,2.99c-0.13,0.16-0.26,0.33-0.39,0.49c-0.99,0.02-1.98,0.03-2.97,0.05
	c-0.18-0.19-0.35-0.37-0.53-0.56c-0.14-0.96-0.28-1.92-0.42-2.87c0.15-0.14,0.3-0.27,0.46-0.41c0.87-0.3,1.74-0.59,2.56-0.87
	C620.42,465.23,620.49,464.23,620.55,463.23z"
              />
              <path
                d="M512.59,93.28c0.2-0.17,0.4-0.34,0.6-0.51c0.97,0.01,1.94,0.01,2.91,0.02c0.16,0.14,0.32,0.29,0.47,0.43
	c0.15,0.97,0.3,1.95,0.45,2.92c-0.2,0.17-0.39,0.34-0.59,0.5c-0.83,0.29-1.66,0.59-2.39,0.84c-0.11,0.94-0.23,1.91-0.35,2.88
	c-0.13,0.17-0.25,0.33-0.38,0.5c-0.45,0.08-0.9,0.22-1.36,0.24c-1.99,0.11-2.72-0.92-2.58-3.63c0.16-0.14,0.32-0.29,0.48-0.43
	c0.87-0.29,1.74-0.59,2.54-0.86C512.46,95.26,512.52,94.27,512.59,93.28z"
              />
              <path
                d="M447.44,84.15c-0.19,0.16-0.37,0.31-0.56,0.47c-0.98,0.9-2.12,0.62-3.25,0.43c-0.16-0.14-0.32-0.28-0.48-0.42
	c-0.45-1.16-0.43-2.31,0.02-3.47c0,0-0.08,0.09-0.08,0.09c0.95-0.31,1.91-0.61,2.94-0.94c0-0.82,0-1.68,0-2.55
	c0.18-0.15,0.36-0.3,0.55-0.44c1.08-0.17,2.16-0.33,3.24-0.5c0.17,0.18,0.34,0.36,0.52,0.55c0.38,1.13,0.48,2.27-0.03,3.39
	c0,0,0.05-0.07,0.05-0.07c-0.94,0.33-1.89,0.65-2.93,1.01C447.44,82.42,447.44,83.29,447.44,84.15z"
              />
              <path
                d="M626.92,448.29c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.04,0.02-3.06,0.03c-0.17-0.18-0.33-0.35-0.5-0.53
	c-0.59-0.94-0.89-2.06-0.11-2.85c0.69-0.7,1.79-0.99,2.68-1.45c0.1-0.83,0.21-1.82,0.33-2.81c0.11-0.16,0.23-0.32,0.34-0.48
	c3.5-0.38,4.1,0.14,3.96,3.4c-0.16,0.14-0.33,0.28-0.49,0.42c-0.86,0.31-1.72,0.62-2.51,0.9
	C627.15,446.29,627.04,447.29,626.92,448.29z"
              />
              <path
                d="M648.99,492.93c-0.19,0.17-0.38,0.34-0.57,0.51c-0.85,0.29-1.7,0.59-2.44,0.84c-0.11,0.95-0.23,1.94-0.34,2.93
	c-0.11,0.15-0.22,0.31-0.33,0.46c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.35-0.36-0.52-0.54c-0.61-0.93-0.88-2.05-0.11-2.85
	c0.68-0.7,1.78-0.99,2.68-1.45c0.1-0.82,0.21-1.81,0.33-2.8c0.11-0.16,0.23-0.32,0.34-0.48c1.02,0,2.04,0,3.06,0
	c0,0-0.09-0.07-0.09-0.07C649.01,490.45,649.21,491.63,648.99,492.93z"
              />
              <path
                d="M529.98,167.63c0.12-0.17,0.24-0.33,0.36-0.5c1.02,0.02,2.03,0.04,3.05,0.05c0.14,0.13,0.28,0.26,0.43,0.39
	c0.16,0.98,0.32,1.96,0.48,2.95c-0.19,0.16-0.37,0.31-0.56,0.47c-0.84,0.29-1.68,0.58-2.4,0.84c-0.12,0.95-0.24,1.92-0.36,2.9
	c-0.13,0.17-0.25,0.34-0.38,0.5c-0.47,0.09-0.93,0.24-1.4,0.26c-1.95,0.09-2.66-0.93-2.55-3.62c0.17-0.16,0.35-0.32,0.52-0.48
	c0.86-0.29,1.72-0.59,2.48-0.84C529.75,169.6,529.87,168.62,529.98,167.63z"
              />
              <path
                d="M564.54,312.97c-0.15-0.13-0.3-0.27-0.45-0.4c-0.04-1.13-0.08-2.25-0.12-3.38c0.19-0.16,0.38-0.32,0.57-0.48
	c0.81-0.12,1.63-0.24,2.31-0.34c0.1-0.97,0.18-1.8,0.26-2.63c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.21-0.36,3.32-0.54
	c0.18,0.19,0.35,0.37,0.53,0.56c0,1.1,0,2.2,0.01,3.3c-0.08,0.17-0.15,0.34-0.23,0.52c-0.89,0.11-1.78,0.22-2.68,0.34
	c0,0.94,0,1.78,0,2.62c-0.19,0.16-0.37,0.32-0.56,0.48C566.81,313.46,565.67,313.15,564.54,312.97z"
              />
              <path
                d="M619.11,480.94c-0.12,0.17-0.24,0.33-0.36,0.5c-1.04,0.9-2.22,0.63-3.39,0.4l0.09,0.08c-0.75-1.26-1.39-2.53-0.28-3.93
	c0.07-0.06,0.15-0.11,0.22-0.17c0.8-0.22,1.6-0.44,2.32-0.64c0.08-0.87,0.16-1.71,0.24-2.54c0.11-0.16,0.23-0.31,0.34-0.47
	c1.1-0.18,2.21-0.35,3.31-0.53c0.18,0.19,0.36,0.37,0.54,0.56c0,1.1,0,2.21,0,3.31c-0.12,0.16-0.24,0.33-0.37,0.49
	c-0.83,0.12-1.66,0.25-2.46,0.37C619.25,479.26,619.18,480.1,619.11,480.94z"
              />
              <path
                d="M518.93,81.62c0.17,0.18,0.35,0.37,0.52,0.55c0,1.11,0,2.22,0,3.32c-0.12,0.16-0.23,0.32-0.35,0.48
	c-0.84,0.12-1.68,0.24-2.47,0.35c-0.07,0.89-0.15,1.74-0.22,2.6c-0.12,0.16-0.23,0.32-0.35,0.48c-1.01,0.88-2.17,0.68-3.34,0.45
	c-0.16-0.14-0.31-0.28-0.47-0.42c-0.41-1.13-0.47-2.27-0.04-3.41c0.16-0.07,0.32-0.15,0.49-0.22c0.8-0.22,1.61-0.44,2.32-0.63
	c0.09-0.88,0.18-1.71,0.26-2.55c0.1-0.15,0.21-0.3,0.31-0.45C516.71,81.98,517.82,81.8,518.93,81.62z"
              />
              <path
                d="M530.59,175.23c0.13-0.17,0.25-0.34,0.38-0.5c0.88-0.27,1.77-0.54,2.56-0.78c0.06-0.96,0.13-1.96,0.2-2.96
	c0.19-0.16,0.37-0.31,0.56-0.47c0.46-0.07,0.93-0.19,1.39-0.19c1.96,0,2.56,0.9,2.44,3.59c-0.15,0.12-0.29,0.24-0.44,0.36
	c-0.9,0.32-1.79,0.64-2.54,0.91c-0.12,0.99-0.24,1.96-0.35,2.92c-0.11,0.14-0.23,0.29-0.34,0.43c-1,0.02-2.01,0.04-3.01,0.07
	c-0.18-0.18-0.35-0.37-0.53-0.55C530.81,177.12,530.7,176.18,530.59,175.23z"
              />
              <path
                d="M548.61,216.58c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02c0.15,0.13,0.3,0.27,0.45,0.4
	c0.16,0.98,0.31,1.96,0.47,2.94c-0.19,0.16-0.37,0.31-0.56,0.47c-0.85,0.29-1.7,0.58-2.44,0.83c-0.11,0.98-0.23,1.97-0.34,2.96
	c-0.1,0.15-0.2,0.3-0.31,0.44c-0.38,0.05-0.76,0.12-1.15,0.16c-1.89,0.18-2.8-0.57-2.91-2.4c-0.02-0.39,0.04-0.78,0.07-1.16
	c0.14-0.13,0.28-0.26,0.42-0.38c0.91-0.3,1.81-0.6,2.59-0.86C548.46,218.52,548.53,217.55,548.61,216.58z"
              />
              <path
                d="M565.47,326.83c0.17,0.18,0.34,0.36,0.51,0.54c0.4,1.14,0.45,2.27-0.04,3.4c0,0,0.08-0.09,0.08-0.09
	c-0.95,0.33-1.91,0.65-2.94,1c0,0.72,0,1.59,0,2.46c-0.19,0.16-0.38,0.32-0.57,0.48c-0.99,0.92-2.13,0.6-3.26,0.42
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.28-1.18-0.73-2.37,0.25-3.46c0.06-0.06,0.13-0.12,0.2-0.18c0.8-0.22,1.61-0.44,2.32-0.64
	c0.09-0.88,0.18-1.71,0.26-2.55c0.1-0.15,0.21-0.3,0.31-0.45C563.15,326.52,564.33,326.79,565.47,326.83z"
              />
              <path
                d="M530.01,164.22c-0.16-0.14-0.32-0.28-0.48-0.42c-0.45-1.16-0.43-2.31,0.02-3.47c0,0-0.08,0.09-0.08,0.09
	c0.95-0.31,1.91-0.62,2.85-0.92c0.08-0.81,0.15-1.66,0.23-2.51c0.11-0.15,0.22-0.31,0.32-0.46c1.11-0.17,2.22-0.35,3.33-0.52
	c0.17,0.18,0.34,0.35,0.51,0.53c0.39,1.14,0.43,2.28-0.04,3.41c0,0,0.08-0.09,0.08-0.09c-0.97,0.33-1.95,0.66-2.94,1
	c0,0.79,0,1.63,0,2.47c-0.19,0.15-0.38,0.31-0.57,0.46C532.27,164.73,531.13,164.4,530.01,164.22z"
              />
              <path
                d="M549.36,224.17c0.1-0.15,0.2-0.3,0.31-0.44c0.89-0.29,1.78-0.57,2.59-0.83c0.06-0.93,0.12-1.94,0.19-2.96
	c0.19-0.16,0.37-0.31,0.56-0.47c0.46-0.07,0.93-0.18,1.39-0.19c1.95,0,2.56,0.89,2.45,3.57c-0.16,0.13-0.31,0.26-0.47,0.4
	c-0.89,0.32-1.79,0.64-2.52,0.9c-0.12,1-0.24,1.97-0.35,2.93c-0.11,0.15-0.21,0.3-0.32,0.46c-1.01,0.01-2.03,0.02-3.04,0.03
	c-0.17-0.18-0.34-0.35-0.5-0.53C549.03,226.13,549.01,225.16,549.36,224.17z"
              />
              <path
                d="M489.85,81.18c0.1-0.15,0.21-0.3,0.31-0.45c1.11-0.18,2.22-0.36,3.33-0.55c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.27-0.05,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.9,0.66-2.86,0.99c-0.06,0.73-0.12,1.59-0.18,2.44
	c-0.12,0.17-0.25,0.34-0.37,0.51c-1.04,0.9-2.21,0.64-3.39,0.39l0.09,0.08c-0.75-1.26-1.43-2.52-0.26-3.91
	c0.06-0.06,0.13-0.12,0.19-0.18c0.8-0.22,1.6-0.45,2.31-0.65C489.68,82.84,489.77,82.01,489.85,81.18z"
              />
              <path
                d="M562.14,324.01c-0.17-0.18-0.34-0.36-0.5-0.53c-0.1-0.95-0.2-1.9-0.3-2.86c0.11-0.15,0.22-0.31,0.33-0.46
	c0.88-0.28,1.77-0.57,2.57-0.82c0.06-0.94,0.13-1.94,0.2-2.95c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02
	c0.15,0.13,0.3,0.27,0.45,0.4c1.09,2.8,0.48,3.78-2.51,4.17c-0.12,1.03-0.24,2.02-0.36,3.01c-0.11,0.15-0.22,0.31-0.33,0.46
	C564.17,323.99,563.15,324,562.14,324.01z"
              />
              <path
                d="M526.89,92.7c0.23-0.31,0.47-0.62,0.7-0.94c1.07-0.67,2.18-0.4,3.29-0.14c0.15,0.2,0.3,0.39,0.46,0.59
	c0.03,0.97,0.05,1.94,0.08,2.91c-0.23,0.31-0.45,0.62-0.68,0.93c-0.92,0-1.84-0.01-2.76-0.01c-0.15-0.2-0.29-0.4-0.44-0.6
	C526.73,94.68,526.62,93.74,526.89,92.7z"
              />
              <path
                d="M457.31,76.87c-0.15-0.31-0.31-0.61-0.46-0.92c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52
	c1.04-0.14,2.08-0.29,3.12-0.43c0.19,0.3,0.38,0.61,0.56,0.91c0.64,0.92,0.61,1.88,0.16,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	C459.37,76.57,458.34,76.72,457.31,76.87z"
              />
              <path
                d="M501.46,91.26c0.23-0.31,0.47-0.62,0.7-0.94c1.07-0.67,2.17-0.42,3.28-0.15c0.16,0.2,0.32,0.4,0.47,0.6
	c0.03,0.97,0.06,1.93,0.09,2.9c-0.24,0.31-0.48,0.63-0.71,0.94c-0.89-0.07-1.78-0.14-2.68-0.21c-0.31-0.23-0.61-0.46-0.92-0.69
	C501.62,92.9,501.54,92.08,501.46,91.26z"
              />
              <path
                d="M546.28,108.57c0.18-0.18,0.35-0.36,0.53-0.54c1.03-0.14,2.06-0.27,3.09-0.41c0.2,0.3,0.4,0.61,0.6,0.91
	c0.62,0.92,0.61,1.88,0.14,2.86c-0.18,0.18-0.35,0.36-0.53,0.54c-1.05,0.68-2.15,0.47-3.26,0.19c-0.09-0.22-0.19-0.45-0.28-0.67
	C546.48,110.49,546.38,109.53,546.28,108.57z"
              />
              <path
                d="M560.72,265.94c-0.25,0.31-0.5,0.63-0.74,0.94c-0.91,0-1.82,0-2.72,0c-0.16-0.2-0.31-0.39-0.47-0.59
	c-0.79-0.79-0.93-1.73-0.62-2.76c0.23-0.31,0.47-0.63,0.7-0.94c1.06-0.67,2.16-0.43,3.27-0.16c0.16,0.21,0.33,0.41,0.49,0.62
	C560.66,264.01,560.69,264.98,560.72,265.94z"
              />
              <path
                d="M633.57,483.68c0.15,0.19,0.3,0.38,0.45,0.57c0.02,0.97,0.04,1.94,0.07,2.92c-0.15,0.16-0.3,0.32-0.45,0.48
	c-0.96,0.06-1.93,0.13-2.89,0.19c-0.16-0.07-0.31-0.14-0.47-0.21c-0.81-0.85-0.95-1.86-0.74-2.96c0.25-0.29,0.49-0.58,0.74-0.87
	C631.35,483.13,632.46,483.38,633.57,483.68z"
              />
              <path
                d="M626.93,411.85c0.17,0.15,0.33,0.3,0.5,0.45c0.15,0.97,0.29,1.94,0.44,2.9c-0.2,0.17-0.4,0.35-0.6,0.52
	c-0.97,0.94-2.1,0.6-3.21,0.41c-0.16-0.15-0.32-0.29-0.48-0.44c-0.42-1.11-0.5-2.23-0.03-3.35c0.12-0.17,0.25-0.34,0.37-0.51
	C624.92,411.85,625.93,411.85,626.93,411.85z"
              />
              <path
                d="M542.45,97.59c-0.18,0.15-0.37,0.31-0.55,0.46c-1,0.92-2.15,0.59-3.29,0.43c-0.14-0.13-0.29-0.26-0.43-0.39
	c-0.41-1.14-0.48-2.28-0.04-3.43c0.11-0.16,0.22-0.31,0.32-0.47c1.02,0,2.04,0.01,3.05,0.01c0.16,0.15,0.33,0.3,0.49,0.44
	C542.16,95.63,542.31,96.61,542.45,97.59z"
              />
              <path
                d="M604.83,316.34c-0.16,0.17-0.32,0.34-0.48,0.5c-0.96,0.06-1.92,0.12-2.89,0.19c-0.17-0.08-0.34-0.16-0.51-0.23
	c-0.84-1-0.99-2.09-0.45-3.28c0.17-0.18,0.34-0.37,0.52-0.55c1.07-0.67,2.18-0.38,3.28-0.11c0.15,0.19,0.29,0.38,0.44,0.57
	C604.77,314.39,604.8,315.36,604.83,316.34z"
              />
              <path
                d="M554.35,194c-0.13,0.17-0.26,0.35-0.38,0.52c-1.02,0.84-2.17,0.67-3.33,0.41c-0.15-0.14-0.31-0.28-0.46-0.41
	c-0.41-1.13-0.49-2.26-0.03-3.39c0.11-0.16,0.23-0.32,0.34-0.48c1.01,0,2.02,0,3.04,0c0.17,0.15,0.34,0.31,0.51,0.46
	C554.13,192.07,554.24,193.03,554.35,194z"
              />
              <path
                d="M490.65,127.76c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.03,0.02-3.05,0.03c-0.17-0.18-0.34-0.36-0.52-0.54
	c-0.14-0.98-0.28-1.96-0.42-2.94c0.17-0.13,0.33-0.25,0.5-0.38c1.09-0.17,2.19-0.33,3.28-0.5c0.17,0.17,0.33,0.35,0.5,0.52
	C491.01,125.51,491.1,126.63,490.65,127.76z"
              />
              <path
                d="M626.3,440.64c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.18,0.68-3.35,0.45c-0.15-0.14-0.31-0.27-0.46-0.41
	c-0.39-1.13-0.49-2.25-0.03-3.39c0.12-0.16,0.23-0.33,0.35-0.49c1.01,0,2.02,0,3.03,0c0.17,0.15,0.34,0.31,0.51,0.46
	C626.1,438.71,626.2,439.67,626.3,440.64z"
              />
              <path
                d="M489.12,113.4c0.18-0.15,0.37-0.31,0.55-0.46c0.97,0,1.95,0,2.92,0c0.15,0.14,0.3,0.27,0.46,0.41
	c0.15,0.98,0.31,1.96,0.46,2.95c-0.19,0.15-0.38,0.3-0.56,0.46c-0.99,0.94-2.15,0.58-3.28,0.43c-0.14-0.13-0.29-0.25-0.43-0.38
	C489.19,115.67,489.15,114.54,489.12,113.4z"
              />
              <path
                d="M535.62,145.06c-0.12,0.17-0.25,0.34-0.37,0.51c-1.01,0.86-2.15,0.71-3.31,0.43c-0.16-0.15-0.33-0.3-0.49-0.45
	c-0.05-1.1-0.09-2.21-0.14-3.31c0.2-0.18,0.4-0.36,0.6-0.53c0.97,0.01,1.94,0.01,2.9,0.02c0.16,0.15,0.32,0.29,0.49,0.44
	C535.4,143.12,535.51,144.09,535.62,145.06z"
              />
              <path
                d="M540.13,365.77c-0.17-0.13-0.34-0.26-0.51-0.39c-0.01-1.16-0.02-2.32-0.02-3.47c0.1-0.15,0.2-0.29,0.29-0.44
	c3.54-0.31,4.11,0.17,3.99,3.36l0.08-0.09C542.91,365.93,541.6,366.15,540.13,365.77z"
              />
              <path
                d="M645.01,489.58c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44c-0.15-0.13-0.3-0.27-0.45-0.4
	c-0.04-1.13-0.09-2.25-0.13-3.38c0.19-0.16,0.38-0.32,0.58-0.49c0.97-0.01,1.94-0.02,2.9-0.02c0.17,0.15,0.34,0.31,0.51,0.46
	C644.81,487.65,644.91,488.62,645.01,489.58z"
              />
              <path
                d="M507.82,162.35c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02c0.15,0.13,0.3,0.27,0.45,0.4
	c0.61,0.91,0.69,1.89,0.34,2.91c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44c-0.15-0.13-0.3-0.27-0.45-0.4
	C507.9,164.61,507.86,163.48,507.82,162.35z"
              />
              <path
                d="M562.52,334.62c0.19-0.16,0.38-0.32,0.57-0.48c0.97-0.01,1.94-0.01,2.91-0.02c0.17,0.15,0.33,0.3,0.5,0.45
	c0.12,0.97,0.23,1.93,0.35,2.9c-0.13,0.18-0.27,0.36-0.4,0.53c-1.03,0.82-2.18,0.63-3.34,0.4c-0.15-0.13-0.3-0.27-0.45-0.4
	C562.6,336.87,562.56,335.74,562.52,334.62z"
              />
              <path
                d="M635.77,468.94c-0.06,0.07-0.13,0.13-0.19,0.19c-0.86,0.33-1.72,0.67-2.55,0.99c-0.1,0.8-0.22,1.7-0.34,2.6
	c-0.17,0.17-0.34,0.35-0.51,0.52c-1.03,0.15-2.05,0.3-3.08,0.45c-0.16-0.31-0.31-0.61-0.47-0.92c-0.16-0.74-0.32-1.48-0.48-2.19
	c-0.86-0.07-1.76-0.14-2.66-0.22c-0.18-0.18-0.35-0.37-0.53-0.55c-1.02-2.78-0.25-3.91,2.63-3.85c0.24,0,0.48-0.01,0.71-0.01
	c0.16,0.19,0.32,0.37,0.48,0.56c0.21,0.88,0.42,1.75,0.63,2.62c0.74-0.11,1.42-0.21,1.93-0.28c0.13-1,0.24-1.88,0.36-2.77
	c0.16-0.17,0.31-0.33,0.47-0.5c1.08-0.66,2.19-0.42,3.31-0.13c0.15,0.19,0.3,0.38,0.45,0.58
	C635.88,466.99,635.83,467.96,635.77,468.94z"
              />
              <path
                d="M634.1,455.01c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43c0.16,0.31,0.32,0.61,0.48,0.92
	c0.22,0.9,0.45,1.8,0.67,2.7c-0.07,0.06-0.13,0.12-0.19,0.18c-1.03,0.45-2.06,0.9-3.16,1.38c0,0.8,0,1.64,0,2.49
	c-0.23,0.31-0.47,0.62-0.7,0.94c-0.91,0-1.82-0.01-2.73-0.01c-0.15-0.2-0.31-0.4-0.46-0.6c-0.82-0.77-0.93-1.71-0.63-2.74
	c0.23-0.31,0.46-0.62,0.69-0.94c0.79-0.24,1.59-0.47,2.39-0.71C634.1,456.72,634.1,455.87,634.1,455.01z"
              />
              <path
                d="M632.18,473.24c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43c0.16,0.3,0.32,0.61,0.49,0.92
	c0.75,0.9,0.65,1.87,0.22,2.86c-0.18,0.18-0.35,0.36-0.53,0.54c-1.05,0.71-2.17,0.43-3.28,0.19c-0.09-0.22-0.18-0.44-0.27-0.66
	C632.38,475.17,632.28,474.21,632.18,473.24z"
              />
              <path
                d="M493.04,113.36c-0.15-0.14-0.3-0.27-0.46-0.41c-0.46-1.16-0.45-2.32,0.02-3.47l-0.08,0.09c1.05-0.31,2.1-0.61,3.14-0.92
	c-0.13-0.67-0.3-1.51-0.45-2.26c-0.92-0.08-1.78-0.15-2.64-0.23c-0.17-0.18-0.35-0.37-0.52-0.55c-0.24-0.87-0.47-1.75-0.7-2.6
	c-0.81-0.06-1.71-0.14-2.61-0.21c-0.18-0.19-0.36-0.38-0.54-0.56c-0.11-0.94-0.22-1.89-0.33-2.83c0.13-0.17,0.27-0.35,0.4-0.52
	c0.87-0.29,1.74-0.57,2.39-0.79c0.15-1.03,0.29-1.95,0.43-2.87c0.15-0.19,0.3-0.39,0.45-0.58c0.91-0.1,1.81-0.2,2.56-0.28
	c0.12-1.05,0.24-2.03,0.36-3c0.14-0.19,0.28-0.37,0.42-0.56c3.1-0.43,3.62-0.08,4.25,2.89c0.85,0,1.71,0,2.58,0
	c0.31,0.23,0.61,0.46,0.92,0.69c0,1.1,0,2.19-0.01,3.29c-0.06,0.09-0.12,0.18-0.18,0.27c-1.01,0.25-2.02,0.51-3.02,0.76
	c0.16,0.76,0.3,1.43,0.45,2.17c0.86,0,1.7,0,2.54,0c0.18,0.3,0.37,0.6,0.55,0.91c0.26,0.85,0.51,1.71,0.73,2.45
	c0.86,0,1.72,0,2.58,0c0.17,0.3,0.34,0.61,0.51,0.91c0.3,0.77,0.6,1.53,0.93,2.36c0.87,0,1.73,0,2.59,0
	c0.18,0.19,0.35,0.37,0.53,0.56c0.41,1.14,0.47,2.27-0.06,3.4l0.08-0.09c-0.99,0.35-1.89,1.4-3.05,0.37
	c-1.64-0.25-1.18-1.77-1.57-2.81c-0.75,0.07-1.42,0.13-2.12,0.2c0.05,0.96,0.08,1.72,0.11,2.29c1.27,0.12,2.42,0.23,3.58,0.33
	c0.1,0.96,0.21,1.92,0.3,2.8c1.03,0.11,1.87,0.19,2.72,0.28c0.16,0.14,0.32,0.29,0.49,0.43c0.12,0.96,0.25,1.93,0.37,2.89
	c-0.14,0.19-0.29,0.38-0.43,0.57c-1.05,0.86-2.2,0.61-3.36,0.33c0,0,0.09,0.08,0.09,0.08c-0.43-0.97-0.87-1.95-1.33-2.98
	c-0.69,0.07-1.37,0.14-2.08,0.21c0,1,0,1.86,0,2.73c-0.23,0.31-0.47,0.62-0.7,0.94c-0.78,0-1.57,0-2.25,0
	c-0.14,1.05-0.27,1.96-0.39,2.87c-0.17,0.17-0.34,0.34-0.51,0.52c-1.12,0.71-2.23,0.5-3.35,0c-0.07-0.15-0.14-0.31-0.21-0.46
	c-0.08-0.97-0.17-1.94-0.25-2.9c0.15-0.12,0.29-0.24,0.44-0.36c0.88-0.27,1.77-0.54,2.68-0.82c-0.08-0.7-0.16-1.38-0.24-2.05
	c-3.04-0.24-3.34-0.49-3.94-3.24C494.89,113.52,493.97,113.44,493.04,113.36z M492.35,99.4c0.05,0.93,0.1,1.68,0.14,2.42
	c0.87-0.07,1.64-0.13,2.4-0.19c-0.07-3.17,0.32-3.64,3.32-4c-0.14-0.77-0.29-1.53-0.43-2.27c-0.8,0-1.55,0-2.31,0
	C495.63,98.52,495.33,98.9,492.35,99.4z M499.31,111.92c0.16-1.01,0.02-2.07,0.49-2.61c0.65-0.76,1.75-1.13,2.81-1.75
	c-0.03-0.51-0.09-1.26-0.14-2c-0.92,0.08-1.7,0.15-2.48,0.23c0.28,2.9-0.17,3.43-3.29,3.95c0.12,0.77,0.24,1.55,0.36,2.33
	C497.84,112.01,498.61,111.96,499.31,111.92z M500.71,112.93c0.06,0.98,0.1,1.75,0.14,2.55c0.93-0.11,1.76-0.2,2.58-0.29
	c-0.14-0.83-0.25-1.52-0.38-2.26C502.33,112.93,501.63,112.93,500.71,112.93z M496.05,102.57c0.15,0.85,0.28,1.59,0.41,2.31
	c0.83-0.08,1.52-0.15,2.35-0.23c-0.05-0.72-0.09-1.41-0.14-2.08C497.77,102.57,496.93,102.57,496.05,102.57z"
              />
              <path
                d="M491.53,94.66c-0.15,0.19-0.3,0.39-0.45,0.58c-0.85,0.27-1.7,0.54-2.46,0.78c-0.11,0.93-0.23,1.9-0.35,2.87
	c-0.13,0.17-0.27,0.35-0.4,0.52c-0.45,0.09-0.9,0.23-1.36,0.25c-1.96,0.11-2.71-0.96-2.58-3.63c0.17-0.15,0.33-0.3,0.5-0.46
	c0.87-0.32,1.74-0.63,2.64-0.96c-0.11-0.58-0.22-1.2-0.33-1.8c-0.83-0.13-1.6-0.24-2.37-0.36c-0.17-0.08-0.35-0.16-0.52-0.24
	c-0.72-0.85-0.96-1.8-0.66-2.88c0.24-0.31,0.48-0.62,0.72-0.94c1.15-0.74,2.28-0.39,3.41,0.04l-0.09-0.08
	c0.29,0.93,0.57,1.86,0.84,2.72c0.91,0.09,1.75,0.17,2.58,0.25c0.16,0.15,0.33,0.3,0.49,0.44C491.28,92.74,491.41,93.7,491.53,94.66
	z"
              />
              <path
                d="M462.24,87.42c-0.15-0.19-0.31-0.39-0.46-0.58c-0.78-0.79-0.93-1.73-0.63-2.77c0.23-0.31,0.47-0.62,0.7-0.94
	c1.14-0.67,2.27-0.52,3.4,0.02c0,0-0.06-0.05-0.06-0.05c0.28,0.93,0.56,1.86,0.83,2.73c0.9,0.07,1.73,0.14,2.57,0.21
	c0.17,0.15,0.33,0.3,0.5,0.45c0.1,0.97,0.2,1.93,0.3,2.9c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.2-0.81-0.4-1.63-0.61-2.5C463.78,87.42,463.01,87.42,462.24,87.42z"
              />
              <path
                d="M457.78,87.84c-0.94-0.09-1.88-0.18-2.81-0.27c-0.09-0.04-0.18-0.08-0.28-0.11c-0.98-0.99-1.04-2.13-0.52-3.35
	c0.15-0.16,0.3-0.32,0.46-0.49c1.08-0.66,2.2-0.42,3.32-0.14c0.15,0.19,0.3,0.39,0.45,0.58c0.03,0.97,0.05,1.94,0.08,2.91
	C458.24,87.26,458.01,87.55,457.78,87.84z"
              />
              <path
                d="M528.06,142.66c-0.37-0.92-0.74-1.84-1.13-2.8c-0.72-0.13-1.54-0.28-2.37-0.42c-0.12-0.07-0.25-0.14-0.38-0.19
	c-1.42-2.66-0.85-3.76,2.43-4.52c-0.03-0.74-0.07-1.5-0.1-2.2c-1.08,0-2.01,0-2.94,0c-0.17-0.3-0.33-0.61-0.5-0.91
	c-0.1-0.96-0.2-1.92-0.29-2.89c0.18-0.18,0.35-0.36,0.53-0.54c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.3,0.32,0.61,0.49,0.91
	c0.26,0.86,0.51,1.71,0.7,2.34c0.95,0.19,2.08,0.1,2.66,0.62c0.73,0.65,1.01,1.81,1.5,2.79c0.6-0.08,1.29-0.18,1.96-0.27
	c0.08-0.92,0.16-1.77,0.24-2.62c0.11-0.16,0.23-0.31,0.34-0.47c1.11-0.17,2.22-0.34,3.33-0.51c0.17,0.18,0.33,0.35,0.5,0.53
	c0.37,1.12,0.46,2.24,0.01,3.36c-0.1,0.15-0.2,0.3-0.31,0.44c-0.84,0.12-1.69,0.23-2.49,0.34c-0.07,0.89-0.14,1.75-0.21,2.6
	c-0.12,0.17-0.25,0.34-0.37,0.51c-0.96,0.29-1.92,0.58-2.87,0.87c0,0.78,0,1.62,0,2.46c-0.2,0.18-0.4,0.36-0.6,0.53
	C530.33,143.23,529.17,142.74,528.06,142.66z M530.49,135.65c-0.9,0.17-1.63,0.31-2.45,0.46c0.1,0.77,0.19,1.44,0.27,2.03
	c0.9,0,1.66,0,2.46,0C530.68,137.33,530.59,136.57,530.49,135.65z"
              />
              <path
                d="M523.3,128.19c-0.18,0.18-0.35,0.36-0.53,0.54c-0.92,0.57-1.89,0.58-2.87,0.2c-0.11-0.06-0.22-0.13-0.33-0.18
	c-0.32-0.93-0.64-1.85-0.97-2.78c-0.76,0.04-1.52,0.08-2.14,0.11c-0.12,1.06-0.23,2.04-0.35,3.02c-0.15,0.19-0.3,0.37-0.45,0.56
	c-0.9,0.11-1.81,0.23-2.59,0.32c-0.11,1-0.22,1.97-0.32,2.95c-0.15,0.19-0.29,0.38-0.44,0.56c-0.81,0.14-1.62,0.27-2.5,0.42
	c0,0.86,0,1.69,0,2.52c-0.2,0.17-0.39,0.34-0.59,0.51c-1,0.99-2.15,0.55-3.28,0.37c0,0,0.09,0.08,0.09,0.08
	c-0.79-1.25-1.37-2.52-0.35-3.94c0.1-0.05,0.19-0.1,0.28-0.16c0.75-0.21,1.51-0.43,2.28-0.64c0.08-0.82,0.16-1.68,0.24-2.53
	c0.13-0.17,0.26-0.35,0.39-0.52c0.94-0.28,1.88-0.55,2.78-0.82c0.05-0.8,0.1-1.65,0.16-2.5c0.15-0.19,0.29-0.37,0.44-0.56
	c0.94-0.26,1.89-0.52,2.9-0.8c-0.03-0.19-0.1-0.49-0.1-0.8c-0.02-1.99,0.68-2.72,2.64-2.77c0.39-0.01,0.78,0.03,1.18,0.04
	c0.17,0.19,0.33,0.38,0.5,0.58c0.19,0.83,0.39,1.66,0.61,2.6c0.71-0.11,1.44-0.23,2.07-0.33c0-0.88,0-1.58,0-2.28
	c0.24-0.31,0.49-0.63,0.73-0.94c1.1-0.67,2.2-0.5,3.31-0.01c0.17,0.08,0.33,0.15,0.5,0.23c-0.08,1.05-0.17,2.11-0.25,3.16
	c-0.06,0.07-0.13,0.14-0.19,0.21c-0.9,0.35-1.8,0.7-2.48,0.96C523.55,126.54,523.42,127.37,523.3,128.19z"
              />
              <path
                d="M540.05,192.27c-0.17-0.08-0.33-0.15-0.5-0.23c-0.78-0.85-0.93-1.85-0.71-2.94c0.25-0.3,0.5-0.59,0.76-0.89
	c1.12-0.66,2.23-0.49,3.35,0c0.16,0.07,0.31,0.14,0.47,0.21c0,1.05,0,2.11,0,3.16c-0.16,0.17-0.31,0.33-0.47,0.5
	C541.98,192.14,541.02,192.2,540.05,192.27z"
              />
              <path
                d="M545.14,176.71c0.17,0.3,0.33,0.61,0.5,0.91c0.73,0.91,0.65,1.87,0.21,2.86c-0.18,0.18-0.35,0.36-0.53,0.54
	c-1.05,0.67-2.16,0.46-3.27,0.18c-0.09-0.22-0.18-0.44-0.27-0.66c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52
	C543.05,177,544.09,176.86,545.14,176.71z"
              />
              <path
                d="M538.15,131.11c-0.17-0.18-0.33-0.35-0.5-0.53c-0.62-0.9-0.64-1.86-0.28-2.86c0.1-0.15,0.2-0.3,0.31-0.44
	c0.89-0.29,1.78-0.57,2.59-0.84c0.06-0.93,0.13-1.95,0.2-2.96c0.18-0.16,0.36-0.31,0.55-0.47c0.46-0.06,0.93-0.18,1.39-0.18
	c1.96-0.01,2.56,0.89,2.45,3.57c-0.16,0.13-0.31,0.26-0.47,0.4c-0.86,0.3-1.72,0.61-2.7,0.95c0.09,0.59,0.2,1.33,0.31,2.07
	c0.94-0.04,1.8-0.09,2.66-0.13c0.16,0.31,0.32,0.61,0.49,0.92c0.75,0.9,0.65,1.87,0.22,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	c-0.92,0.57-1.89,0.58-2.87,0.2c-0.09-0.06-0.18-0.12-0.27-0.18c-0.35-0.92-0.69-1.84-1.02-2.7
	C539.85,131.25,539,131.18,538.15,131.11z"
              />
              <path
                d="M544.85,134c0.17-0.18,0.35-0.35,0.52-0.53c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.3,0.33,0.61,0.49,0.91
	c0.56,0.76,0.94,1.71,0.28,2.42c-0.74,0.79-1.8,1.3-2.97,2.1c0,0.49,0,1.36,0,2.23c-0.23,0.31-0.45,0.62-0.68,0.93
	c-0.31-0.01-0.62-0.04-0.93-0.04c-2.39,0.03-3.08-0.75-2.91-3.32c0.07-0.15,0.14-0.31,0.21-0.46c0.94-0.43,1.89-0.87,2.86-1.31
	C544.85,135.7,544.85,134.85,544.85,134z"
              />
              <path
                d="M516.1,92.78c-0.43-1.12-0.48-2.25-0.04-3.38c0.12-0.16,0.23-0.32,0.35-0.48c0.83-0.1,1.67-0.21,2.43-0.3
	c0.09-0.96,0.17-1.8,0.25-2.64c0.12-0.16,0.23-0.32,0.35-0.48c1.07-0.88,2.29-0.54,3.48-0.42c0.11,0.06,0.22,0.12,0.33,0.18
	c0.16,1.19,0.68,2.37,0.04,3.57c-0.08,0.17-0.16,0.35-0.23,0.52c-0.86,0.12-1.72,0.24-2.69,0.37c0,0.85,0,1.71,0,2.58
	c-0.2,0.16-0.4,0.33-0.59,0.49c-0.96,0.96-2.1,0.6-3.21,0.43C516.42,93.07,516.26,92.92,516.1,92.78z"
              />
              <path
                d="M491.15,91.77c-0.16-0.15-0.33-0.3-0.49-0.44c-0.42-1.11-0.5-2.23-0.03-3.35c0.12-0.17,0.25-0.34,0.37-0.51
	c1.01,0.01,2.02,0.02,3.03,0.03c0.16,0.14,0.32,0.29,0.48,0.43c0.12,0.97,0.25,1.93,0.37,2.9c-0.14,0.19-0.28,0.37-0.42,0.56
	C493.43,92.19,492.3,92.04,491.15,91.77z"
              />
              <path
                d="M542,177.15c-0.17,0.17-0.34,0.34-0.51,0.52c-1.14,0.77-2.27,0.42-3.39-0.05l0.09,0.08c-0.17-1.13-0.34-2.27-0.51-3.4
	c0.15-0.12,0.29-0.24,0.44-0.36c0.88-0.26,1.75-0.52,2.58-0.77c0.03-0.74,0.07-1.49,0.1-2.24c0.23-0.31,0.47-0.63,0.7-0.94
	c0.88-0.21,1.76-0.43,2.67-0.65c-0.11-0.72-0.22-1.45-0.34-2.25c-0.87,0-1.64,0-2.41,0c-0.16-0.2-0.32-0.41-0.48-0.61
	c-0.36-0.84-0.72-1.67-1.08-2.51c-0.84-0.07-1.76-0.15-2.68-0.22c-0.15-0.13-0.3-0.26-0.44-0.4c-0.42-1.16-0.42-2.32,0.02-3.48
	l-0.08,0.09c1.02-0.3,2.04-0.6,3.12-0.91c-0.12-0.69-0.28-1.54-0.41-2.25c-0.99-0.09-1.83-0.16-2.67-0.24
	c-0.17-0.18-0.34-0.35-0.51-0.53c-0.11-0.96-0.21-1.92-0.32-2.88c0.12-0.15,0.24-0.3,0.36-0.45c1.08-0.88,2.32-0.48,3.52-0.42
	c0.1,0.06,0.2,0.12,0.29,0.18c0.18,0.92,0.35,1.83,0.52,2.69c0.93,0,1.79,0,2.65,0c0.16,0.3,0.32,0.61,0.49,0.91
	c0.25,0.84,0.49,1.68,0.72,2.45c0.81,0,1.72,0,2.63,0c0.16,0.3,0.33,0.61,0.49,0.91c1.08,2.53,0.54,3.39-2.55,4.13
	c0,0.78,0,1.56,0,2.33c0.88-0.1,1.66-0.19,2.44-0.28c-0.18-2.95,0.45-3.64,3.42-3.74c0.08,0,0.16-0.03,0.24-0.04
	c0.18,0.18,0.36,0.35,0.54,0.53c0.26,0.89,0.51,1.78,0.76,2.64c0.87,0.1,1.72,0.19,2.57,0.29c0.15,0.14,0.31,0.27,0.46,0.41
	c0.63,0.91,0.7,1.88,0.34,2.91c-0.12,0.17-0.24,0.33-0.36,0.5c-0.94,0.29-1.88,0.59-2.87,0.89c0.22,3.05-0.55,3.84-3.52,3.45
	c-0.08-0.01-0.16-0.03-0.24-0.05c-0.17-0.13-0.35-0.25-0.52-0.38c-0.21-0.82-0.41-1.63-0.62-2.43c-0.76,0-1.43,0-2.08,0
	c-0.17,1.01-0.32,1.91-0.48,2.81c-0.06,0.08-0.12,0.16-0.18,0.24c-0.86,0.32-1.73,0.64-2.56,0.95
	C542.23,175.35,542.12,176.25,542,177.15z M543.44,162.23c-0.07-1.01-0.12-1.75-0.18-2.49c-0.91,0.11-1.67,0.2-2.44,0.28
	c0.15,0.83,0.28,1.58,0.42,2.41C541.95,162.37,542.62,162.31,543.44,162.23z M551.47,168.84c-0.16-0.78-0.3-1.45-0.46-2.24
	c-0.79,0.05-1.54,0.1-2.32,0.15c0.08,0.85,0.14,1.51,0.19,2.09C549.87,168.84,550.63,168.84,551.47,168.84z"
              />
              <path
                d="M557.8,154.08c0.17,0.18,0.34,0.36,0.51,0.54c0,1.12,0,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45
	c-1.01,0.01-2.03,0.02-3.04,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.1-0.95-0.19-1.9-0.29-2.85c0.11-0.15,0.22-0.31,0.32-0.46
	C555.58,154.44,556.69,154.26,557.8,154.08z"
              />
              <path
                d="M560.7,143.59c0.16,0.98,0.31,1.96,0.47,2.93c-0.19,0.16-0.37,0.32-0.56,0.48c-0.99,0.91-2.13,0.61-3.26,0.42
	c-0.15-0.14-0.3-0.27-0.45-0.41c-0.05-1.12-0.09-2.25-0.14-3.37c0.19-0.16,0.38-0.32,0.58-0.49c0.97,0.01,1.94,0.01,2.92,0.02
	C560.4,143.32,560.55,143.45,560.7,143.59z"
              />
              <path
                d="M546.29,140.72c1.08-0.69,2.2-0.39,3.32-0.15c0.15,0.2,0.29,0.4,0.44,0.6c0.03,0.97,0.06,1.94,0.09,2.91
	c-0.23,0.31-0.46,0.62-0.69,0.94c-0.92,0-1.85-0.01-2.77-0.01c-0.15-0.19-0.29-0.38-0.44-0.57c-0.76-0.8-0.94-1.74-0.63-2.78
	C545.84,141.34,546.07,141.03,546.29,140.72z"
              />
              <path
                d="M546.86,112.13c0.89,0.92,1.03,1.94,0.35,3.01c-0.63,1-1.6,1.21-2.7,1.05c-0.31-0.05-0.63-0.06-0.95-0.09
	c0,0-0.1-0.09-0.1-0.09c-0.27-1.02-0.53-2.03-0.8-3.04c-0.77,0.08-1.44,0.15-2.03,0.21c-0.08,0.93-0.16,1.77-0.24,2.62
	c-0.11,0.15-0.21,0.31-0.32,0.46c-1.02,0.9-2.2,0.67-3.38,0.46c-0.14-0.13-0.29-0.25-0.43-0.38c-0.02-1.16-0.03-2.32-0.05-3.48
	c0.96-0.27,1.91-0.54,2.82-0.8c0.08-0.86,0.16-1.71,0.23-2.56c0.11-0.15,0.22-0.31,0.33-0.46c1.08-0.9,2.32-0.5,3.52-0.43
	c0.1,0.06,0.2,0.12,0.29,0.18c0.19,0.95,0.37,1.9,0.55,2.8c0.95-0.04,1.79-0.08,2.62-0.12C546.67,111.68,546.77,111.9,546.86,112.13
	z"
              />
              <path
                d="M539.6,109.02c-0.11,0.15-0.22,0.31-0.33,0.46c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.22-0.35,3.32-0.53c0.17,0.18,0.34,0.36,0.51,0.54
	C539.96,106.79,540.07,107.91,539.6,109.02z"
              />
              <path
                d="M498.09,131.09c-0.15-0.31-0.31-0.61-0.46-0.92c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.5-0.52
	c1.05-0.14,2.09-0.29,3.14-0.43c0.15,0.31,0.31,0.61,0.46,0.92c0.8,0.89,0.67,1.87,0.24,2.86c-0.17,0.18-0.35,0.35-0.52,0.53
	C500.15,130.79,499.12,130.94,498.09,131.09z"
              />
              <path
                d="M498.84,137.84c-1.56-2.59-0.95-3.82,2.34-4.46c0-0.89,0-1.81,0-2.74c0.17-0.18,0.35-0.35,0.52-0.53
	c0.3-0.08,0.6-0.18,0.9-0.24c1.94-0.4,2.99,0.33,3.29,2.27c0.06,0.38,0.06,0.77,0.09,1.16c-0.09,0.05-0.19,0.11-0.29,0.16
	c-1.01,0.47-2.01,0.94-3.08,1.45c0,0.77,0,1.61,0,2.45c-0.23,0.28-0.47,0.57-0.7,0.85c-0.94-0.09-1.87-0.18-2.81-0.26
	C499.02,137.91,498.93,137.87,498.84,137.84z"
              />
              <path
                d="M540.54,120.07c0.16,0.98,0.31,1.96,0.47,2.95c-0.18,0.16-0.36,0.31-0.55,0.47c-0.86,0.29-1.71,0.57-2.45,0.82
	c-0.11,0.98-0.22,1.98-0.34,2.97c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.03,0.02-3.05,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-1.01-2.74-0.42-3.63,2.6-4.07c0.07-1.03,0.14-2.03,0.22-3.03c0.18-0.16,0.36-0.31,0.55-0.47c0.97,0.01,1.95,0.01,2.92,0.02
	C540.24,119.81,540.39,119.94,540.54,120.07z"
              />
              <path
                d="M565.56,161.06c0.9,0.91,1.04,1.93,0.37,3.02c-0.63,1-1.6,1.21-2.71,1.05c-0.31-0.05-0.63-0.06-0.95-0.09
	c0,0-0.1-0.09-0.1-0.09c-0.27-1.02-0.53-2.03-0.8-3.05c-0.77,0.08-1.44,0.15-2.13,0.22c0,0.93,0,1.78,0,2.64
	c-0.18,0.15-0.36,0.31-0.54,0.46c-0.99,0.9-2.14,0.6-3.27,0.43c-0.15-0.14-0.31-0.27-0.46-0.41c-0.43-1.16-0.42-2.32,0.02-3.48
	l-0.08,0.09c0.96-0.3,1.91-0.6,2.84-0.89c0.08-0.84,0.17-1.7,0.25-2.55c0.1-0.15,0.21-0.3,0.31-0.45c1.11-0.19,2.21-0.37,3.32-0.56
	c0.18,0.19,0.35,0.38,0.53,0.57c0.17,0.84,0.34,1.68,0.48,2.41c0.93,0,1.79,0,2.65,0C565.38,160.62,565.47,160.84,565.56,161.06z"
              />
              <path
                d="M632.74,476.8c1.42,2.53,0.8,3.68-2.47,4.27c0,0.88,0,1.81,0,2.73c-0.25,0.29-0.49,0.58-0.74,0.87
	c-0.16-0.02-0.31-0.05-0.47-0.04c-2.79,0.17-3.5-0.54-3.34-3.34c0.09-0.14,0.17-0.28,0.26-0.42c0.9-0.41,1.8-0.81,2.89-1.3
	c0-0.73,0-1.67,0-2.61c0.1-0.12,0.21-0.22,0.33-0.32c1.09-0.16,2.18-0.33,3.27-0.49C632.56,476.35,632.65,476.57,632.74,476.8z"
              />
              <path
                d="M629.2,476.62c-0.12,0.1-0.23,0.21-0.33,0.32c-0.9,0.79-1.89,0.71-2.93,0.34c-0.1-0.06-0.21-0.12-0.31-0.18
	c-0.33-0.92-0.66-1.84-0.95-2.67c-0.85-0.08-1.69-0.16-2.53-0.24c-0.18-0.19-0.36-0.37-0.54-0.56c-0.11-0.95-0.22-1.9-0.33-2.85
	c0.13-0.16,0.26-0.33,0.39-0.49c1.1-0.16,2.2-0.33,3.31-0.49c0.18,0.18,0.35,0.37,0.53,0.55c0.17,0.83,0.35,1.66,0.51,2.41
	c0.92,0,1.77,0,2.63,0c0.16,0.31,0.31,0.61,0.47,0.92C629.89,474.64,629.85,475.62,629.2,476.62z"
              />
              <path
                d="M538.84,189.1c-0.23,0-0.47-0.02-0.7-0.01c-2.56,0.11-3.3-0.71-3.07-3.4c0.08-0.13,0.16-0.26,0.22-0.39
	c0.91-0.42,1.81-0.83,2.86-1.31c-0.04-0.52-0.09-1.19-0.14-1.78c-1.01-0.08-1.87-0.15-2.73-0.23c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.96-0.2-1.92-0.3-2.89c0.11-0.14,0.23-0.29,0.34-0.43c1.04-0.85,2.23-0.56,3.4-0.43c0,0-0.09-0.08-0.09-0.08
	c0.39,0.99,0.79,1.99,1.17,2.94c0.8,0,1.65,0,2.51,0c0.09,0.22,0.18,0.44,0.27,0.66c1.44,2.52,0.81,3.7-2.46,4.27
	c0,0.88,0,1.81,0,2.73C539.34,188.51,539.09,188.8,538.84,189.1z"
              />
              <path
                d="M546.24,144.43c0.15,0.19,0.29,0.38,0.44,0.57c0.03,0.97,0.05,1.94,0.08,2.92c-0.22,0.31-0.45,0.62-0.67,0.93
	c-0.78,0-1.56,0-2.24,0c-0.15,1.04-0.28,1.94-0.41,2.85c-0.18,0.18-0.35,0.36-0.53,0.54c-0.92,0.58-1.88,0.58-2.87,0.2
	c-0.1-0.06-0.19-0.12-0.29-0.18c-0.84-1-0.73-2.19-0.68-3.37c0.16-0.14,0.33-0.28,0.49-0.42c0.9-0.25,1.79-0.51,2.72-0.77
	c-0.11-0.66-0.21-1.34-0.31-1.96c-1.01-0.08-1.94-0.15-2.87-0.23c-0.15-0.14-0.31-0.27-0.46-0.41c-0.04-1.12-0.08-2.25-0.12-3.37
	c0.19-0.16,0.38-0.33,0.57-0.49c0.98,0.07,2.21-0.2,2.89,0.29c0.73,0.53,0.95,1.77,1.41,2.74c0.24-0.04,0.63-0.18,1.01-0.15
	C545.02,144.17,545.63,144.32,546.24,144.43z"
              />
              <path
                d="M624.07,462.74c-0.05-1.12-0.1-2.25-0.15-3.37c0.2-0.16,0.39-0.32,0.59-0.49c0.08,0,0.16,0,0.23-0.01
	c2.89-0.36,3.43,0.05,3.78,2.86c1.06,0.1,2.1,0.2,3.14,0.3c0.15,0.2,0.31,0.4,0.46,0.6c0.02,0.97,0.04,1.95,0.06,2.92
	c-0.16,0.17-0.31,0.33-0.47,0.5c-0.97,0.15-1.95,0.29-2.92,0.44c-0.16-0.19-0.32-0.37-0.48-0.56c-0.36-0.85-0.73-1.69-1.11-2.57
	c-0.82-0.06-1.75-0.14-2.67-0.21C624.38,463.02,624.22,462.88,624.07,462.74z"
              />
              <path
                d="M510.82,108.09c-0.18-0.19-0.35-0.37-0.53-0.56c-0.25-0.79-0.5-1.58-0.75-2.36c-0.92,0-1.84,0-2.77,0
	c-0.17-0.3-0.34-0.61-0.51-0.91c-0.17-0.83-0.34-1.66-0.5-2.45c-0.95,0-1.88,0-2.81,0c-0.18-0.3-0.37-0.6-0.55-0.91
	c0.01-0.98,0.02-1.95,0.03-2.93c0.06-0.09,0.12-0.18,0.18-0.27c0.87-0.3,1.73-0.59,2.68-0.91c0-0.67,0-1.42,0-2.17
	c0.24-0.31,0.48-0.63,0.71-0.94c1.12-0.76,2.25-0.35,3.38-0.02c0.16,1.13,0.31,2.25,0.47,3.38c-0.16,0.14-0.32,0.29-0.48,0.43
	c-0.89,0.26-1.78,0.52-2.65,0.77c0.05,0.77,0.1,1.54,0.13,2.1c1.02,0.22,2.17,0.17,2.86,0.72c0.7,0.55,0.93,1.68,1.41,2.65
	c0.78,0,1.65,0,2.52,0c0.17,0.18,0.35,0.37,0.52,0.55c0,1.1,0.01,2.2,0.01,3.3c-0.12,0.17-0.25,0.34-0.37,0.5
	C512.81,108.06,511.81,108.07,510.82,108.09z"
              />
              <path
                d="M514.17,104.24c-0.17-0.18-0.35-0.37-0.52-0.55c-0.11-0.95-0.22-1.89-0.33-2.84c0.13-0.17,0.25-0.33,0.38-0.5
	c0.88-0.27,1.75-0.55,2.55-0.79c0.06-0.94,0.13-1.93,0.19-2.93c0.2-0.17,0.39-0.34,0.59-0.5c0.97,0,1.95,0.01,2.92,0.01
	c0.15,0.14,0.31,0.28,0.46,0.42c0.15,0.99,0.3,1.97,0.45,2.96c-0.16,0.14-0.32,0.27-0.48,0.41c-0.85,0.31-1.71,0.62-2.5,0.91
	c-0.11,0.89-0.22,1.89-0.34,2.88c-0.12,0.16-0.24,0.32-0.36,0.49C516.17,104.22,515.17,104.23,514.17,104.24z"
              />
              <path
                d="M541.51,169.98c-0.23,0.31-0.47,0.63-0.7,0.94c-3.22,0-3.55-0.25-4.18-3.12c-0.95-0.08-1.88-0.15-2.81-0.23
	c-0.14-0.13-0.28-0.26-0.43-0.39c-0.05-1.13-0.1-2.26-0.15-3.39c0.19-0.15,0.38-0.31,0.57-0.46c0.97,0.01,1.95,0.01,2.92,0.02
	c0.15,0.13,0.3,0.26,0.44,0.4c0.27,0.88,0.54,1.76,0.75,2.42c1.03,0.11,2.03,0.21,3.02,0.31c0.16,0.2,0.32,0.41,0.48,0.61
	C541.45,168.04,541.48,169.01,541.51,169.98z"
              />
              <path
                d="M538.61,98.48c1.11,2.79,0.5,3.78-2.5,4.17c-0.12,1.03-0.24,2.02-0.36,3.01c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.46,0.08-0.92,0.2-1.38,0.22c-1.75,0.1-2.37-0.56-2.92-3.03c-1.02-0.11-2.01-0.21-3-0.32c-0.09-0.22-0.18-0.44-0.27-0.66
	c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19c0.97-0.22,1.93-0.43,2.93-0.65c0-0.76,0-1.51,0-2.26
	c0.23-0.31,0.45-0.62,0.68-0.93c1.13-0.76,2.26-0.34,3.4-0.03c0.23,0.88,0.47,1.76,0.72,2.69c0.84,0.09,1.75,0.19,2.65,0.29
	C538.33,98.22,538.47,98.35,538.61,98.48z M534.7,101.58c-0.21-0.9-0.37-1.56-0.52-2.22c-0.77,0.06-1.39,0.11-2.12,0.17
	c0.15,0.82,0.28,1.53,0.41,2.25C533.27,101.71,533.89,101.66,534.7,101.58z"
              />
              <path
                d="M527.81,98.98c-0.06,0.06-0.13,0.12-0.19,0.19c-0.85,0.34-1.71,0.68-2.54,1.01c-0.1,0.79-0.22,1.69-0.34,2.59
	c-0.17,0.17-0.34,0.35-0.51,0.52c-0.92,0.57-1.89,0.58-2.88,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.82-1.01-0.73-2.19-0.67-3.38
	c0.16-0.14,0.32-0.27,0.48-0.41c0.89-0.25,1.79-0.5,2.71-0.76c-0.11-0.67-0.21-1.35-0.31-1.96c-1.01-0.08-1.93-0.16-2.85-0.24
	c-0.15-0.14-0.31-0.28-0.46-0.42c-0.05-1.12-0.1-2.24-0.16-3.36c0.2-0.16,0.4-0.33,0.59-0.49c0.08,0,0.16,0,0.23-0.01
	c2.88-0.37,3.44,0.06,3.78,2.87c1.06,0.11,2.1,0.21,3.15,0.32c0.15,0.2,0.29,0.4,0.44,0.6C527.92,97.03,527.86,98.01,527.81,98.98z"
              />
              <path
                d="M497.35,127.28c-1.12,0.78-2.24,0.38-3.37,0.01c-0.23-0.89-0.47-1.78-0.71-2.7c-0.83-0.06-1.73-0.13-2.64-0.2
	c-0.17-0.17-0.33-0.35-0.5-0.52c-1.02-2.75-0.42-3.65,2.59-4.08c0.07-1.03,0.14-2.03,0.21-3.03c0.19-0.15,0.37-0.3,0.56-0.46
	c0.46-0.07,0.93-0.19,1.39-0.19c1.95,0,2.56,0.9,2.44,3.59c-0.15,0.12-0.29,0.24-0.44,0.36c-0.86,0.31-1.73,0.62-2.71,0.97
	c0.08,0.57,0.19,1.32,0.3,2.07c0.94-0.05,1.81-0.09,2.67-0.14c0.07,0.15,0.14,0.31,0.21,0.46c0.86,1.01,1.07,2.11,0.49,3.33
	C497.69,126.93,497.52,127.11,497.35,127.28z"
              />
              <path
                d="M538.53,141.73c-0.98,0.92-2.11,0.62-3.23,0.43c-0.16-0.15-0.32-0.29-0.49-0.44c-0.41-1.11-0.5-2.23-0.04-3.35
	c0.12-0.17,0.25-0.34,0.37-0.51c0.84-0.1,1.68-0.2,2.43-0.29c0.1-0.96,0.19-1.8,0.27-2.65c0.1-0.15,0.2-0.3,0.31-0.44
	c1.08-0.93,2.34-0.49,3.55-0.45c0.09,0.06,0.18,0.12,0.27,0.18c0.15,1.21,0.61,2.42,0.02,3.63c-0.07,0.15-0.14,0.31-0.21,0.46
	c-0.89,0.11-1.78,0.21-2.68,0.32c0,0.94,0,1.79,0,2.63C538.91,141.4,538.72,141.57,538.53,141.73z"
              />
              <path
                d="M521.04,103.31c0.1,0.06,0.2,0.12,0.29,0.18c0.18,0.92,0.36,1.84,0.52,2.68c0.93,0,1.79,0,2.65,0
	c0.16,0.31,0.31,0.61,0.47,0.92c0.24,0.85,0.49,1.71,0.74,2.6c0.77-0.05,1.7-0.1,2.63-0.16c0.15,0.31,0.31,0.61,0.46,0.92
	c1.07,2.47,0.5,3.39-2.55,4.11c0,0.77,0,1.54,0,2.1c1.16,0.19,2.22,0.36,3.27,0.53c0.15,0.07,0.3,0.13,0.44,0.2
	c0.05,0.85,0.1,1.7,0.15,2.47c1.06,0.11,1.92,0.2,2.77,0.3c0.14,0.13,0.29,0.25,0.43,0.38c1.17,2.93,0.22,4.24-2.86,3.9
	c-0.16-0.02-0.31-0.04-0.47-0.06c-0.15-0.09-0.3-0.18-0.46-0.27c-0.18-0.84-0.37-1.68-0.54-2.49c-0.85-0.14-1.62-0.26-2.38-0.39
	c-0.17-0.08-0.33-0.15-0.5-0.23c-0.38-0.91-0.77-1.82-1.21-2.88c-0.58,0.04-1.32,0.1-2.1,0.16c0,1.02,0,1.87,0,2.73
	c-0.24,0.31-0.49,0.63-0.73,0.94c-0.89,0-1.79,0.01-2.68,0.01c-0.17-0.19-0.33-0.38-0.5-0.58c-0.36-0.84-0.73-1.68-1.11-2.58
	c-0.64,0-1.4,0-1.96,0c-0.21,1.06-0.14,2.24-0.68,2.95c-0.53,0.69-1.68,0.91-2.48,1.29c-0.12,0.85-0.26,1.76-0.39,2.67
	c-0.15,0.19-0.29,0.37-0.44,0.56c-0.91,0.11-1.83,0.22-2.6,0.32c-0.11,1.03-0.22,2.01-0.33,3c-0.13,0.17-0.26,0.35-0.39,0.52
	c-0.98-0.03-2.19,0.28-2.89-0.18c-0.76-0.5-1.05-1.71-1.59-2.69c-0.73,0-1.65,0-2.58,0c-0.15-0.31-0.31-0.61-0.46-0.92
	c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52c0.88-0.21,1.75-0.41,2.64-0.62c0-0.77,0-1.51,0-2.25
	c0.23-0.31,0.47-0.62,0.7-0.94c1.15-0.74,2.28-0.4,3.41,0.04c0,0-0.09-0.08-0.09-0.08c0.27,0.97,0.54,1.94,0.83,2.97
	c0.73-0.12,1.55-0.26,2.1-0.36c0.16-1.11,0.3-2.03,0.43-2.95c0.14-0.19,0.29-0.38,0.43-0.57c0.92-0.09,1.85-0.18,2.61-0.25
	c0.12-1.08,0.23-2.08,0.34-3.07c0.12-0.17,0.25-0.34,0.37-0.51c0.91-0.08,1.83-0.17,2.52-0.23c0.18-1.11,0.35-2.15,0.52-3.2
	l-0.07,0.08c0.99-0.34,1.97-0.68,3.04-1.05c-0.09-0.63-0.21-1.44-0.3-2.11c-0.99-0.08-1.83-0.16-2.67-0.23
	c-0.18-0.19-0.36-0.38-0.55-0.57c-0.1-0.94-0.2-1.88-0.31-2.83c0.12-0.16,0.24-0.32,0.36-0.49
	C518.61,102.84,519.85,103.23,521.04,103.31z M524.73,113.28c-0.06-0.92-0.11-1.71-0.16-2.52c-0.9,0.14-1.64,0.25-2.37,0.37
	c0.14,0.82,0.26,1.5,0.4,2.32C523.29,113.39,523.95,113.34,524.73,113.28z M505.18,123.61c0.15,0.78,0.28,1.45,0.42,2.13
	c0.87-0.06,1.62-0.1,2.37-0.15c-0.08-0.91-0.15-1.69-0.22-2.47C506.92,123.28,506.16,123.43,505.18,123.61z M518.92,115.13
	c0.07,0.77,0.14,1.54,0.2,2.25c0.88-0.08,1.66-0.15,2.53-0.23c-0.14-0.77-0.28-1.49-0.4-2.14
	C520.4,115.06,519.71,115.09,518.92,115.13z"
              />
              <path
                d="M514.99,114.35c-0.12,0.17-0.25,0.34-0.37,0.51c-1.02,0.85-2.16,0.68-3.32,0.43c-0.16-0.14-0.32-0.29-0.49-0.43
	c-0.47-1.16-0.44-2.31,0.02-3.46c0,0-0.08,0.09-0.08,0.09c0.94-0.31,1.88-0.62,2.81-0.94c0.08-0.81,0.16-1.65,0.24-2.5
	c0.12-0.17,0.25-0.34,0.37-0.5c1-0.85,2.17-0.55,3.3-0.51c0.18,0.19,0.36,0.38,0.55,0.57c0.4,1.14,0.48,2.27-0.06,3.39
	c0,0,0.07-0.08,0.07-0.08c-0.93,0.35-1.85,0.69-2.84,1.06C515.14,112.66,515.06,113.5,514.99,114.35z"
              />
              <path
                d="M529.55,124.13c0.15,0.09,0.3,0.18,0.46,0.27c0.39,0.89,0.78,1.78,1.24,2.82c0.73,0,1.64,0,2.56,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.38,1.11,0.49,2.22,0,3.34c-0.11,0.16-0.23,0.31-0.34,0.47c-0.46,0.08-0.91,0.21-1.38,0.24
	c-1.75,0.1-2.38-0.57-2.93-3.11c-0.92,0-1.84,0-2.77,0c-0.16-0.3-0.32-0.61-0.49-0.91c-0.08-1.05-0.17-2.1-0.25-3.15
	c0.06-0.07,0.13-0.14,0.19-0.21C527.43,124.32,528.49,124.22,529.55,124.13z"
              />
              <path
                d="M542.92,152.24c0.18-0.18,0.35-0.36,0.53-0.54c0.91-0.21,1.81-0.41,2.65-0.6c0-0.83,0-1.54,0-2.25
	c0.22-0.31,0.45-0.62,0.67-0.93c1.08-0.67,2.19-0.43,3.31-0.14c0.15,0.19,0.31,0.39,0.46,0.58c0.02,0.97,0.04,1.95,0.06,2.92
	c-0.14,0.13-0.28,0.25-0.42,0.38c-0.88,0.26-1.75,0.53-2.7,0.81c0.05,0.36,0.18,0.81,0.15,1.25c-0.03,0.45-0.22,0.89-0.34,1.34
	c-0.18,0.18-0.35,0.36-0.53,0.54c-1.02,0.15-2.03,0.29-3.05,0.44c-0.16-0.3-0.32-0.61-0.49-0.91
	C543.12,154.16,543.02,153.2,542.92,152.24z"
              />
              <path
                d="M546.76,155.6c0.18-0.18,0.35-0.36,0.53-0.54c3.01-0.78,3.72-0.34,4.38,2.87c0.9,0,1.83,0,2.77,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.41,1.14,0.46,2.28-0.04,3.4c0,0,0.08-0.09,0.08-0.09c-1.04,0.63-2.12,1.04-3.35,0.54
	c-0.18-0.18-0.36-0.35-0.54-0.53c-0.26-0.79-0.51-1.59-0.77-2.39c-0.92,0-1.84,0-2.77,0c-0.16-0.3-0.33-0.61-0.49-0.91
	C546.96,157.52,546.86,156.56,546.76,155.6z"
              />
              <path
                d="M550.53,148.36c-0.15-0.19-0.31-0.39-0.46-0.58c-0.78-0.8-0.93-1.73-0.62-2.77c0.23-0.31,0.46-0.62,0.69-0.94
	c1.12-0.77,2.26-0.34,3.39-0.03c0.24,0.88,0.47,1.75,0.72,2.69c0.83,0.09,1.74,0.19,2.64,0.28c0.15,0.14,0.3,0.27,0.45,0.41
	c1.09,2.81,0.47,3.8-2.51,4.17c-0.12,1.03-0.24,2.02-0.36,3.02c-0.11,0.15-0.22,0.31-0.32,0.46c-0.38,0.06-0.76,0.13-1.15,0.17
	c-1.87,0.18-2.8-0.59-2.9-2.42c-0.02-0.39,0.05-0.78,0.07-1.16c0.14-0.13,0.28-0.25,0.42-0.38c0.87-0.3,1.74-0.6,2.73-0.94
	c-0.11-0.58-0.23-1.24-0.36-1.98C552.09,148.36,551.31,148.36,550.53,148.36z"
              />
              <path
                d="M529.98,117.4c-0.15-0.07-0.3-0.13-0.44-0.2c-0.93-1.07-1.23-2.22-0.27-3.4c0.87-1.07,2.01-1.07,3.21-0.56
	c0.14,0.06,0.3,0.09,0.45,0.14c0,0-0.05-0.06-0.05-0.06c0.26,0.94,0.53,1.88,0.77,2.72c0.95,0.1,1.79,0.19,2.63,0.28
	c0.14,0.13,0.29,0.25,0.43,0.38c0.16,0.98,0.32,1.97,0.48,2.95c-0.18,0.16-0.36,0.31-0.55,0.47c-1,0.91-2.16,0.6-3.29,0.43
	c-0.14-0.13-0.29-0.25-0.43-0.38c-0.19-0.88-0.38-1.77-0.53-2.47C531.48,117.59,530.73,117.5,529.98,117.4z"
              />
              <path
                d="M536.26,112.9c-1.08,0.51-2.17,0.99-3.39,0.43c0,0,0.05,0.06,0.05,0.06c-0.49-0.94-0.98-1.89-1.52-2.93
	c-0.71,0-1.65,0-2.59,0c-0.15-0.31-0.31-0.61-0.46-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.51-0.52
	c3-0.8,3.71-0.35,4.38,2.85c0.9,0,1.84,0,2.77,0c0.17,0.18,0.34,0.36,0.51,0.54C536.24,110.65,536.25,111.77,536.26,112.9z"
              />
              <path
                d="M528.13,103c0.97,0.93,0.91,2,0.44,3.13c-0.17,0.17-0.34,0.35-0.51,0.52c-1.03,0.15-2.05,0.3-3.08,0.45
	c-0.16-0.31-0.31-0.61-0.47-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.51-0.52c1.04-0.14,2.09-0.29,3.13-0.43
	C527.95,102.56,528.04,102.78,528.13,103z"
              />
            </svg>
          </div>
        </div>

        <div
          className="homeWrapper cloneWrapper"
          style={{
            clipPath: `polygon(0 0, calc(${state.x} * 1% + ( ${state.y} -50) * 1.2%) 0, calc(${state.x} * 1% + (${state.y} - 50) * -0.1%) 100%, 0 100%`,
          }}
        >
          <div className={home.yannieHome}>
            <div className={home.name}>
              <p className={home.title}>software engineer</p>
              <h1 className={home.yannie}>YANNIE</h1>
              <h1 className={home.yeung}>yeung</h1>
            </div>
            <svg
              className="cloneSvg"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 800 640"
              style={{ enableBackground: "new 0 0 800 640;" }}
              xmlSpace="preserve"
            >
              <path
                d="M498.99,437.93c-0.12-1.08-0.24-2.17-0.34-3.03c-1.07-0.33-2.1-0.52-3-0.98c-0.36-0.19-0.58-0.95-0.61-1.46
	c-0.07-1.5,1.09-2.21,3.12-2.09c0.12-1.04,0.09-2.12,0.43-3.07c0.16-0.45,0.97-0.83,1.54-0.94c1.37-0.27,2.24,0.79,2.78,3.2
	c0.67-0.17,1.34-0.34,2.08-0.53c0.05-0.81,0.09-1.59,0.14-2.37c0.28-0.26,0.56-0.52,0.84-0.78c0.8-0.26,1.6-0.52,2.33-0.76
	c0-0.74,0-1.35,0-1.92c-1-0.2-1.91-0.39-2.82-0.57c-0.08-0.07-0.16-0.13-0.25-0.18c-0.97-2.8-0.43-3.69,2.4-4.1
	c0.27-1.05,0.29-2.25,0.87-3.03c0.46-0.62,1.6-0.74,2.46-1.08c0.28-1.06,0.61-2.3,0.93-3.54c0,0-0.07,0.08-0.07,0.08
	c0.84-0.09,1.68-0.18,2.58-0.28c-0.11-0.84-0.21-1.61-0.31-2.36c-2.49-0.19-3.32-0.72-3.4-2.14c-0.07-1.22,0.76-2.19,1.94-2.3
	c1.42-0.13,2.18,0.75,2.6,3.04c0.38-0.06,0.75-0.14,1.14-0.18c0.39-0.04,0.78-0.05,1.26-0.08c-0.04-0.46-0.08-0.83-0.09-1.2
	c-0.01-0.39,0-0.79,0-1.3c-0.5,0.04-1.03,0.16-1.53,0.1c-1.33-0.17-2.19-1.24-2.09-2.47c0.1-1.22,1.15-2.13,2.46-2.12
	c0.62,0.01,1.24,0.07,1.86,0.11c0.09,1.07,0.18,2.14,0.28,3.24c0.84-0.08,1.61-0.16,2.44-0.24c-0.04-0.82-0.08-1.58-0.12-2.31
	c-0.96-0.16-2.04,0.54-2.6-0.68c-1.04-0.37-1.2-1.22-1-2.1c0.36-1.57,1.64-1.99,3.04-2.01c0.1-3.1,0.37-3.41,3.31-3.97
	c0.1-1.04,0.21-2.06,0.31-3.09c0,0-0.08,0.08-0.08,0.08c1.05-0.26,2.11-0.51,3.16-0.77c0,0-0.06-0.05-0.06-0.05
	c0.68,1.02,1.35,2.03,2.16,3.24c0.28-0.01,0.99-0.05,1.47-0.07c0.37-1.08,0.51-2.23,1.1-3.05c0.76-1.05,2.03-0.58,3.13-0.44
	c0.15,0.12,0.29,0.25,0.44,0.37c0.22,0.91,0.43,1.82,0.65,2.72c0.75-0.07,1.45-0.13,2.06-0.19c0.25-1,0.24-2.15,0.79-2.87
	c0.49-0.64,1.6-0.82,2.42-1.2c0.27-0.88,0.46-1.94,0.93-2.86c0.19-0.37,0.96-0.59,1.48-0.62c1.45-0.09,2.18,1.01,2.29,3.34
	c0.83-0.07,1.66-0.15,2.36-0.21c0.24-1,0.32-2.02,0.75-2.86c0.23-0.45,1.03-0.76,1.61-0.85c1.41-0.22,2.24,0.72,2.77,3.08
	c0.66-0.09,1.33-0.19,2.06-0.29c0-0.75,0-1.45,0-2c-1.03-0.35-2.03-0.55-2.87-1.02c-0.37-0.21-0.56-0.96-0.6-1.48
	c-0.08-1.35,0.75-1.99,2.86-2.38c0.13-0.94,0.26-1.86,0.39-2.78c0.09-0.11,0.17-0.22,0.25-0.34c0.9-0.35,1.8-0.71,2.77-1.09
	c-0.06-0.6-0.13-1.34-0.18-1.88c-1.14-0.17-2.12-0.32-3.1-0.46c0,0,0.09,0.07,0.09,0.07c-0.31-0.96-0.62-1.92-0.94-2.89
	c-2.97-0.09-3.37-0.49-3.58-3.65c-0.84,0.14-1.68,0.28-2.58,0.43c0.05,0.46,0.17,0.92,0.13,1.37c-0.15,1.54-1.28,2.42-2.8,2.21
	c-0.47-0.07-0.91-0.26-1.37-0.39c0,0,0.09,0.05,0.09,0.05c-0.66-1.31-0.64-2.62,0.01-3.93l-0.08,0.09c0.9-0.22,1.8-0.44,2.7-0.66
	c-0.11-0.8-0.21-1.48-0.29-2.04c-1-0.26-2.11-0.25-2.73-0.79c-0.69-0.59-0.94-1.69-1.37-2.55c-0.68,0.03-1.38,0.07-2.03,0.1
	c-0.09,0.87-0.18,1.72-0.27,2.58c-0.1,0.15-0.2,0.29-0.29,0.44c-0.97,0.92-2.11,0.76-3.26,0.52c-0.18-0.15-0.36-0.29-0.55-0.44
	c-0.39-1.23-0.65-2.44,0.26-3.57c0,0,0.17-0.07,0.17-0.07c0.88-0.19,1.76-0.39,2.65-0.58c-0.09-0.82-0.18-1.63-0.25-2.28
	c-1.08-0.11-1.99-0.21-2.9-0.3c-0.13-0.12-0.26-0.24-0.4-0.37c-0.8-2.94-0.36-3.67,2.68-4.31c0-0.75,0-1.52,0-2.3
	c-3.37-0.05-3.8-0.49-3.63-3.68c0.13-0.12,0.27-0.24,0.4-0.35c0.88-0.28,1.77-0.56,2.57-0.81c0.28-1.18,0.57-2.38,0.86-3.58
	l-0.08,0.09c0.81-0.17,1.63-0.35,2.53-0.54c-0.04-0.8-0.08-1.47-0.11-2.01c-1.01-0.13-1.92-0.24-2.82-0.35
	c-0.17-0.16-0.34-0.31-0.51-0.47c-0.18-0.9-0.37-1.8-0.55-2.68c-0.89-0.08-1.88-0.18-2.86-0.27l0.09,0.07
	c-0.39-0.92-0.78-1.85-1.16-2.73c-0.88-0.27-1.93-0.48-2.85-0.94c-0.39-0.2-0.62-0.93-0.69-1.45c-0.19-1.38,0.82-2.23,3.04-2.68
	c0.07-0.93,0.14-1.87,0.22-2.8c0.15-0.15,0.29-0.3,0.44-0.45c0.94-0.25,1.89-0.51,2.88-0.77c-0.17-0.71-0.32-1.37-0.47-1.99
	c-0.89-0.09-1.67-0.17-2.44-0.25c-0.16-0.12-0.32-0.25-0.48-0.37c-1.15-2.9-0.61-3.86,2.61-4.57c-0.11-0.67-0.22-1.35-0.32-2
	c-2.57-0.25-3.66-1.06-3.6-2.61c0.07-1.54,1.22-2.29,3.48-2.26c-0.06-0.99-0.12-1.98-0.18-2.97c0.16-0.18,0.31-0.36,0.47-0.54
	c0.86-0.37,1.71-0.73,2.68-1.15c-0.1-0.5-0.2-1.03-0.29-1.54c-0.87-0.08-1.66-0.16-2.45-0.24c-0.16-0.12-0.32-0.25-0.47-0.37
	c-0.73-1.12-0.57-2.33-0.39-3.54c0.05-0.09,0.1-0.17,0.16-0.25c1.03-0.25,2.06-0.49,3.07-0.73c-0.1-0.86-0.18-1.62-0.26-2.33
	c-0.94,0.05-1.79,0.09-2.65,0.13c-0.14-0.18-0.29-0.37-0.43-0.55c-0.79-0.93-1.12-1.94-0.4-3.04c0.75-1.13,1.83-1.4,3.19-0.92
	c-0.18-0.95-0.34-1.77-0.46-2.4c-1.03-0.36-2.03-0.54-2.84-1.05c-0.43-0.27-0.68-1.08-0.71-1.66c-0.06-1.4,1.01-2.14,3.06-2.36
	c0.21-1.17,0.42-2.3,0.62-3.42c0,0-0.07,0.11-0.07,0.11c0.94-0.28,1.88-0.56,2.85-0.85c-0.16-0.7-0.3-1.3-0.44-1.89
	c-0.84-0.09-1.62-0.17-2.39-0.25c-0.18-0.15-0.36-0.3-0.53-0.44c-0.25-0.97-0.5-1.95-0.74-2.87c-0.81-0.18-1.79-0.28-2.65-0.66
	c-0.4-0.18-0.69-0.88-0.79-1.39c-0.28-1.43,0.68-2.29,2.77-2.67c0.18-1.18,0.35-2.32,0.53-3.46l-0.03,0.07
	c0.97-0.26,1.94-0.52,2.93-0.78c-0.03-0.71-0.06-1.32-0.09-1.82c-1.23-0.3-2.29-0.55-3.34-0.81c0,0,0.05,0.07,0.05,0.07
	c-0.5-1.31-0.46-2.62,0-3.93c0,0-0.04,0.08-0.04,0.08c0.9-0.24,1.81-0.47,2.76-0.72c-0.07-0.67-0.14-1.36-0.22-2.2
	c-0.87,0-1.73,0-2.59,0c-0.14-0.16-0.27-0.32-0.41-0.48c-0.78-1.1-0.57-2.31-0.4-3.52c0.05-0.09,0.11-0.18,0.17-0.26
	c0.97-0.23,1.94-0.47,2.98-0.71c-0.05-0.7-0.1-1.45-0.14-2.1c-0.94-0.13-1.78-0.25-2.61-0.37l-0.12-0.07
	c-1.63-2.57-1.13-3.79,2.09-4.71c-0.04-0.64-0.07-1.31-0.1-1.83c-1.31-0.35-2.42-0.65-3.53-0.94l0.08,0.07
	c-0.13-0.9-0.25-1.81-0.37-2.68c-2.48-0.12-3.37-0.69-3.45-2.13c-0.08-1.5,0.79-2.18,3.18-2.43c-0.13-0.8-0.26-1.61-0.42-2.59
	c-1.1-0.17-2.51,0.24-3.27-1.12c-0.59-1.04-0.27-1.98,0.23-2.92c0,0-0.08,0.08-0.08,0.08c1.3-1.08,2.71-0.33,4.08-0.24
	c0.05,0.87,0.1,1.74,0.16,2.66c0.96-0.07,1.64-0.12,2.37-0.18c-0.09-0.79-0.17-1.47-0.24-2.13c-0.83-0.11-1.59-0.2-2.36-0.3
	c-0.58-0.88-1.17-1.76-1.88-2.84c-0.6-0.09-1.6-0.24-2.61-0.4c-0.14-0.09-0.29-0.18-0.43-0.27c-0.08-0.98-0.17-1.95-0.26-3.01
	c-0.87,0.18-1.61,0.33-2.16,0.44c-0.19,1.08-0.35,1.99-0.51,2.9c-0.08,0.12-0.16,0.23-0.25,0.33c-0.08,0.01-0.16,0.01-0.24,0.03
	c-2.93,0.57-3.65,0.04-4.01-3.03c-0.83,0.12-1.66,0.23-2.56,0.36c0,0.86,0,1.72,0,2.59c0,0.01,0.05-0.07,0.05-0.07
	c-1.19,1.3-2.58,1.3-4.06,0.59l0.08,0.09c-0.81-1.31-0.25-2.72-0.25-4.07c0.97-0.08,1.93-0.16,2.88-0.25
	c-0.13-0.95-0.22-1.63-0.33-2.42c-0.78,0.05-1.46,0.09-1.98,0.12c-0.19,0.96-0.36,1.78-0.52,2.6c-0.72,1.12-1.71,1.4-2.87,0.8
	c-1.16-0.59-1.45-1.6-1.15-2.84c0.11-0.45,0.11-0.92,0.17-1.38c0.96-0.06,1.92-0.12,2.9-0.18c-0.14-0.93-0.24-1.68-0.33-2.24
	c-1.05-0.28-2.12-0.29-2.76-0.81c-0.65-0.53-0.89-1.57-1.34-2.44c-0.61,0.09-1.28,0.18-1.95,0.28c0.05,0.82,0.09,1.5,0.13,2.18
	c3.15-0.04,3.73,0.54,3.4,3.28c-0.57,1.34-1.72,1.42-2.88,1.35c-0.14,1.08-0.27,2.13-0.41,3.19l0.08-0.08
	c-0.43,0.19-0.85,0.45-1.3,0.56c-1.5,0.37-2.67-0.27-2.98-1.6c-0.32-1.34,0.4-2.46,1.87-2.81c0.38-0.09,0.79-0.06,1.27-0.08
	c-0.08-0.78-0.15-1.54-0.24-2.44c-0.72,0.06-1.48,0.12-2.23,0.18c-0.34,3.41-1.05,3.97-4.15,3.28l0.08,0.08
	c-0.37-0.93-0.75-1.87-1.2-3.01c-1.07,0.28-2.34,0.25-3.09-1.06c-0.45-0.78-0.74-1.64,0.23-2.32c0.48-1.66,1.89-1.14,3.05-1.15
	c-0.22-0.88-0.41-1.62-0.61-2.39c-0.77,0-1.53,0-2.21,0c-0.08,1.25-0.16,2.4-0.23,3.55c-0.45-0.01-0.9-0.08-1.35-0.03
	c-1.68,0.19-2.82-0.54-2.98-1.96c-0.14-1.31,0.71-2.29,2.26-2.56c0.3-0.05,0.62,0.02,1.08,0.05c-0.07-0.48-0.17-0.92-0.2-1.36
	c-0.03-0.45-0.01-0.91-0.01-1.26c-1.06,0-2,0-2.93,0c-0.15-0.14-0.3-0.28-0.45-0.41c-0.25-0.9-0.51-1.79-0.72-2.53
	c-1.02-0.34-2.08-0.56-2.99-1.04c-0.36-0.19-0.54-0.96-0.57-1.48c-0.07-1.17,0.88-2.11,2.06-2.2c1.56-0.12,2.28,0.75,2.46,3.1
	c0.77-0.08,1.53-0.16,2.46-0.25c-0.07-0.74-0.14-1.46-0.21-2.18c-3.06-0.17-3.41-0.48-3.67-3.29c-1.05-0.08-2.11-0.15-3.17-0.23
	c-0.13-0.1-0.25-0.2-0.38-0.3c-0.42-1.32-0.82-2.64,0.44-3.76c0,0-0.04,0.02-0.04,0.02c0.88-0.2,1.76-0.39,2.65-0.59
	c-0.13-0.72-0.26-1.49-0.38-2.17c-0.96-0.14-1.87-0.27-2.78-0.4c-0.13-0.12-0.26-0.24-0.39-0.36c-0.2-0.91-0.4-1.83-0.62-2.86
	c-0.79-0.05-1.79-0.11-2.79-0.17l0.09,0.09c-0.01-0.08-0.01-0.17-0.04-0.23c-1.41-2.73-0.92-3.75,2.2-4.42c0-0.75,0-1.52,0-2.25
	c-0.97-0.21-2.15-0.14-2.82-0.68c-0.68-0.56-0.87-1.72-1.21-2.48c-0.99-0.2-2.04-0.41-3.08-0.62c0,0,0.09,0.08,0.09,0.08
	c-0.23-0.98-0.46-1.97-0.67-2.86c-0.94-0.23-1.98-0.35-2.9-0.76c-0.38-0.17-0.65-0.9-0.73-1.41c-0.23-1.49,0.76-2.32,3.09-2.74
	c-0.04-0.78-0.09-1.56-0.15-2.53c-1.23,0.27-2.62,0.46-3.42-0.96c-0.44-0.78-0.74-1.63,0.21-2.32c0.41-1.62,1.86-1.08,2.96-1.31
	c-0.8-2.68,0.51-3.78,2.92-4.01c-0.1-0.78-0.19-1.45-0.27-2.09c-0.84,0-1.55,0-2.26,0c0.36,2.38,0.02,3.43-3.09,3.91
	c-0.09,1.17-0.17,2.34-0.25,3.51c-0.45-0.01-0.9-0.07-1.34-0.02c-1.58,0.18-2.62-0.4-2.94-1.7c-0.33-1.29,0.35-2.34,1.88-2.83
	c0.36-0.11,0.74-0.13,1.13-0.2c0-0.86,0-1.64,0-2.42c-2.99,0.21-3.5-0.19-3.98-3.16c-1.27,0.04-2.77,0.32-3.42-1.24
	c-0.37-0.88-0.58-1.85,0.58-2.43c0.38-1.45,1.84-0.43,2.4-0.93c0.12-1.05-0.07-2.13,0.38-2.7c0.64-0.78,1.76-1.17,2.77-1.8
	c-0.1-0.48-0.22-1.07-0.34-1.68c-0.75,0.04-1.37,0.08-1.83,0.1c-0.28,1.04-0.27,2.18-0.83,2.77c-0.61,0.64-1.75,0.77-2.79,1.18
	c0.08,0.91,0.18,1.98,0.27,3.05c-0.54,0.07-1.07,0.11-1.61,0.2c-1.59,0.26-2.69-0.34-2.98-1.64c-0.31-1.38,0.54-2.43,2.23-2.73
	c0.3-0.05,0.6-0.06,0.86-0.08c-0.07-0.93-0.13-1.78-0.2-2.63c-3.05,0.16-3.6-0.29-3.71-2.93c-1.06-0.26-2.13-0.36-3.05-0.81
	c-0.42-0.21-0.7-1.07-0.73-1.65c-0.09-1.46,1-2.19,3.27-2.3c-0.13-0.76-0.26-1.53-0.4-2.4c-0.72,0-1.49,0-2.05,0
	c-0.27,1.04-0.4,2.02-0.82,2.87c-0.19,0.39-0.91,0.64-1.43,0.72c-1.12,0.17-2.18-0.77-2.31-1.93c-0.19-1.57,0.67-2.31,3.05-2.56
	c-0.05-0.45-0.11-0.91-0.14-1.37c-0.02-0.39,0-0.79,0-1.05c-1.17-0.26-2.27-0.5-3.37-0.74l0.08,0.08c-0.22-0.98-0.44-1.96-0.61-2.72
	c-1.02-0.27-2.1-0.36-2.94-0.86c-0.46-0.27-0.67-1.21-0.71-1.86c-0.03-0.58,0.33-1.19,0.51-1.79c0,0-0.09,0.11-0.09,0.11
	c0.87-0.23,1.75-0.47,2.62-0.7c-0.11-0.7-0.2-1.31-0.28-1.83c-0.97-0.13-1.86-0.26-2.75-0.38c-0.17-0.15-0.33-0.31-0.5-0.46
	c-0.21-0.9-0.43-1.81-0.61-2.56c-0.97-0.12-1.88-0.24-2.8-0.36c-0.13-0.12-0.27-0.25-0.4-0.37c-0.2-0.9-0.4-1.8-0.56-2.56
	c-0.95-0.23-2.1-0.2-2.76-0.76c-0.68-0.56-0.88-1.69-1.31-2.62c-0.8,0-1.79,0-2.78,0c-0.1-0.11-0.2-0.22-0.28-0.35
	c-0.27-0.94-0.54-1.88-0.8-2.8c-0.86-0.17-1.81-0.37-2.76-0.56c0,0,0.05,0.06,0.05,0.06c-0.41-0.98-0.83-1.96-1.24-2.95
	c-0.72-0.16-1.86-0.11-2.56-0.64c-0.65-0.49-0.83-1.59-1.21-2.42c-0.87-0.24-1.93-0.42-2.87-0.86c-0.37-0.18-0.59-0.93-0.65-1.45
	c-0.17-1.58,0.71-2.28,3.18-2.64c-0.15-0.69-0.29-1.38-0.45-2.13c-0.66,0-1.28,0-2.07,0c0.13,2.84-1.02,4.25-4,3.39
	c-0.15-0.13-0.3-0.25-0.45-0.38c-0.2-0.91-0.4-1.83-0.6-2.75c-2.7,0.18-3.24-0.23-3.82-2.86c-1.14-0.29-2.27-0.57-3.39-0.85
	c0,0,0.08,0.08,0.08,0.08c-0.2-0.9-0.39-1.8-0.56-2.54c-1.19-0.32-2.3-0.62-3.41-0.92l0.08,0.08c-0.18-0.84-0.35-1.69-0.55-2.62
	c-0.81,0.07-1.62,0.15-2.44,0.22c0.14,2.3-0.47,3.33-1.98,3.42c-1.21,0.07-2.17-0.75-2.27-1.96c-0.13-1.46,0.62-2.12,2.92-2.51
	c-0.06-0.75-0.12-1.52-0.19-2.42c-0.69,0.07-1.37,0.13-2.08,0.2c-0.04,0.64,0.01,1.21-0.12,1.74c-0.29,1.2-1.31,1.85-2.51,1.72
	c-1.16-0.13-2.05-1.07-2.06-2.26c-0.01-0.61,0.11-1.23,0.17-1.84c0.96-0.21,1.91-0.42,2.93-0.64c-0.1-0.79-0.2-1.52-0.29-2.26
	c-0.83,0.06-1.52,0.11-1.97,0.14c-0.23,1.05-0.42,1.94-0.61,2.82c-2.72,1.47-3.88,0.89-4.51-2.19c-0.94-0.17-1.85-0.34-2.76-0.51
	c0,0-0.12-0.04-0.12-0.04c-0.35-0.92-0.7-1.85-1.1-2.87c-0.71-0.07-1.63-0.17-2.55-0.27c-0.12-0.1-0.24-0.2-0.37-0.28
	c-0.29-0.86-0.57-1.73-0.86-2.59c-0.96-0.1-2.01-0.21-3.05-0.32l0.06,0.05c-1.15-2.65-0.83-4.53,2.6-4.72
	c-0.15-0.77-0.28-1.44-0.42-2.15c-0.76,0.04-1.39,0.08-1.89,0.11c-0.26,0.99-0.25,2.12-0.8,2.72c-0.6,0.66-1.73,0.83-2.52,1.16
	c-0.2,0.93-0.41,1.9-0.62,2.87l-0.02-0.02c-0.28,0.15-0.54,0.37-0.84,0.44c-1.69,0.39-2.89-0.25-3.16-1.65
	c-0.28-1.41,0.56-2.42,2.26-2.72c0.16-0.03,0.32,0.01,0.48-0.01c0.14-0.02,0.27-0.1,0.4-0.16c-0.09-0.78-0.17-1.56-0.26-2.38
	c-0.82,0-1.65,0-2.56,0c0.05,0.38,0.13,0.69,0.14,1c0.03,2.1-0.81,2.86-2.95,2.68c-0.32-0.03-0.63-0.1-0.94-0.15
	c0,0,0.09,0.09,0.09,0.09c-0.36-0.99-0.72-1.98-1.05-2.89c-0.79-0.08-1.62-0.17-2.46-0.26c-0.12-0.08-0.24-0.15-0.36-0.21
	c-0.36-0.97-0.72-1.94-1.13-3.05c-0.64-0.1-1.77,0.8-2.44-0.51c-1.44-0.27-0.88-1.67-1.39-2.46c-0.76,0.07-1.51,0.14-2.27,0.21
	c0.06,0.8,0.11,1.49,0.16,2.25c1.2,0,2.35,0,3.5,0c0.03,0.46,0.03,0.92,0.09,1.37c0.23,1.66-0.46,2.82-1.84,3.05
	c-1.33,0.22-2.36-0.71-2.63-2.39c-0.04-0.23,0.03-0.47,0.04-0.68c-1.09-0.08-2.16-0.15-3.23-0.23c-0.12-0.09-0.23-0.18-0.33-0.29
	c-0.19-0.91-0.38-1.82-0.58-2.79c-0.68,0.04-1.43,0.09-2.06,0.12c-0.36,1.04-0.51,2.24-1.14,3.07c-0.76,1.01-2.03,0.46-3.1,0.28
	l0.08,0.09c-0.35-0.87-0.7-1.74-1.07-2.66c-1.02-0.21-2.15-0.43-3.29-0.66c0,0,0.08,0.04,0.08,0.04c-0.22-0.95-0.44-1.9-0.67-2.89
	c-0.82,0.08-1.49,0.14-2.06,0.19c-0.15,1.01-0.27,1.85-0.4,2.69c-0.08,0.11-0.15,0.22-0.22,0.34c-2.79,1.08-3.53,0.69-4.57-2.46
	c-0.61,0.05-1.23,0.1-2.02,0.16c0.2,2.81-1,4.16-3.93,3.48c-0.17-0.19-0.35-0.39-0.52-0.58c-0.22-0.89-0.44-1.78-0.65-2.66
	c-0.78,0.08-1.53,0.16-2.12,0.22c-0.06,1.08-0.11,2.02-0.15,2.96c-0.12,0.13-0.24,0.26-0.36,0.39c-0.89,0.28-1.78,0.55-2.7,0.83
	c0.21,3.06-0.89,3.99-3.82,3.11c0,0-0.07-0.03-0.07-0.03c-0.36-1-0.72-2-1.11-3.07c-0.51,0.09-1.26,0.22-1.93,0.33
	c-0.2,1.13-0.39,2.18-0.58,3.24l0.07-0.06c-1.07,0.25-2.14,0.5-3.21,0.75c0.47,2.97-0.3,3.89-3.08,3.8
	c-0.27,1.06-0.29,2.27-0.88,3.03c-0.47,0.61-1.64,0.69-2.46,0.99c-0.19,0.81-0.31,1.87-0.72,2.81c-0.16,0.37-0.92,0.62-1.43,0.67
	c-1.53,0.17-2.22-0.76-2.41-3.19c-0.82,0.11-1.64,0.22-2.48,0.33c0,0.82,0,1.6,0,2.18c1.01,0.29,2,0.45,2.85,0.89
	c0.38,0.2,0.62,0.93,0.68,1.45c0.11,1.01-0.84,2-1.89,2.13c-1.35,0.17-2.11-0.67-2.53-2.96c-0.36,0.06-0.72,0.13-1.09,0.17
	c-0.4,0.05-0.79,0.07-1.13,0.09c-0.27,0.99-0.3,2.12-0.87,2.85c-0.5,0.65-1.59,0.86-2.48,1.29c-0.04,2.74-0.47,3.21-3.41,3.57
	c0.11,2.94-0.47,3.57-3.35,3.74c-0.18,1.05-0.1,2.23-0.62,3.04c-0.42,0.66-1.56,0.86-2.42,1.29c-0.24,0.8-0.31,2.01-0.94,2.76
	c-0.49,0.59-1.66,0.62-2.49,0.89c-0.2,0.85-0.18,2.1-0.78,2.83c-0.53,0.65-1.76,0.72-2.76,1.07c0.21,2.94-0.3,3.54-3.09,3.54
	c-0.12,1.06,0.09,2.33-0.43,3.07c-0.53,0.76-1.79,1-2.85,1.53c0.26,2.81-0.76,3.35-3.37,3.53c0.17,2.74-0.25,3.3-3.05,3.98
	c-0.08,2.48-0.68,3.45-2.21,3.58c-1.56,0.14-2.32-0.71-2.84-3.17c-0.69,0.06-1.38,0.11-2.11,0.17c0,0.78,0,1.54,0,2.4
	c0.42-0.05,0.72-0.12,1.02-0.12c2.2,0.04,3.2,1.39,2.55,3.46c-0.14,0.45-0.37,0.87-0.56,1.3l0.06-0.06
	c-0.75,0.09-1.5,0.18-2.34,0.28c0,0.91,0,1.69,0,2.46c-0.28,0.3-0.57,0.61-0.85,0.91c-0.84,0.17-1.68,0.35-2.59,0.54
	c0.04,0.66,0.08,1.36,0.12,2.05c0.97,0.25,2.01,0.31,2.83,0.78c0.99,0.56,1.23,2.13,0.43,2.94c-0.66,0.67-1.67,0.98-2.64,1.52
	c0.04,3.23-1.02,3.54-3.4,3.56c0.07,3.12-0.3,3.58-3.33,4c0,0.77,0,1.54,0,2.18c1.03,0.25,2.03,0.37,2.9,0.77
	c0.39,0.18,0.67,0.89,0.75,1.4c0.24,1.49-0.71,2.24-3.22,2.63c0.43,1.2,0.54,2.4-0.59,3.26c-0.77,0.58-1.66,1.02-2.54,0.11
	c-1.58-0.19-1.1-1.69-1.56-2.54c-0.74,0-1.42,0-2.22,0c0.07,0.8,0.12,1.48,0.18,2.17c1.28,0.13,2.44,0.25,3.61,0.38
	c-0.15,1.43-0.31,2.86-0.46,4.29l0.07-0.08c-0.84,0.17-1.68,0.34-2.65,0.53c0.04,0.67,0.09,1.35,0.13,2.01
	c2.61,0.18,3.56,0.83,3.57,2.35c0.01,1.56-1.02,2.25-3.37,2.28c-0.06,1.04,0.21,2.28-0.28,3.05c-0.49,0.78-1.73,1.09-2.83,1.72
	c-0.12,0.54-0.08,1.61-0.58,2.28c-0.49,0.67-1.52,0.93-2.59,1.53c0.02,0.37,0.06,1.04,0.09,1.58c1.27,0.3,2.41,0.58,3.55,0.85
	l-0.07-0.05c0.21,1.32,0.41,2.64-0.31,3.88c0,0,0.08-0.08,0.08-0.08c-0.95,0.25-1.9,0.5-2.67,0.7c-0.24,1.12-0.46,2.15-0.68,3.18
	c0,0,0.09-0.1,0.09-0.1c-0.9,0.28-1.8,0.55-2.79,0.85c0.06,0.62,0.12,1.31,0.19,2.1c0.98,0,1.85,0,2.72,0
	c0.1,0.15,0.21,0.3,0.31,0.46c1.31,2.94,0.59,4.15-2.52,4.46c-0.22,0.83-0.42,1.61-0.63,2.39c-0.2,1.71-1.87,1.08-2.77,1.68
	c0.06,0.66,0.12,1.28,0.18,1.91c0.86,0,1.63,0,2.37,0c0.08-1.27,0.15-2.43,0.22-3.6c0.36,0.06,0.74,0.22,1.09,0.18
	c1.91-0.26,2.99,0.29,3.24,1.73c0.27,1.52-0.69,2.46-2.74,2.7c-0.15,0.02-0.29,0.06-0.26,0.06c-0.22,1.19-0.42,2.31-0.62,3.42
	c0,0,0.08-0.1,0.08-0.1c-0.97,0.27-1.95,0.55-2.98,0.83c0.03,0.7,0.07,1.47,0.1,2.23c1.02,0,1.95,0,2.88,0
	c0.14,0.11,0.28,0.22,0.43,0.33c1.24,2.95,0.59,4.09-2.44,4.44c-0.28,1.04-0.31,2.23-0.89,3c-0.48,0.63-1.62,0.75-2.71,1.2
	c-0.06,0.59-0.15,1.51-0.24,2.44c-0.13,0.13-0.26,0.26-0.4,0.39c-0.29,0.13-0.57,0.3-0.87,0.37c-1.65,0.37-2.81-0.27-3.13-1.69
	c-0.31-1.43,0.43-2.47,2.08-2.89c0.15-0.04,0.32,0,0.47-0.03c0.15-0.03,0.28-0.1,0.42-0.16c0.04-0.15,0.12-0.3,0.11-0.44
	c-0.03-0.55-0.08-1.1-0.14-1.78c-0.47-0.03-0.93,0-1.38-0.09c-1.28-0.27-1.91-1.16-1.75-2.38c0.17-1.22,1.04-1.9,2.36-1.78
	c0.47,0.04,0.92,0.2,1.38,0.3c0.16,0.83,0.33,1.66,0.51,2.55c0.91-0.1,1.67-0.19,2.45-0.27c-0.09-0.81-0.16-1.48-0.23-2.21
	c-1.05,0-1.91,0-2.78,0c-0.31-0.46-0.72-0.88-0.91-1.39c-0.57-1.54,0.14-2.91,1.71-3.36c0.44-0.13,0.92-0.12,1.4-0.18
	c0-0.67,0.01-1.21-0.01-1.76c0-0.14-0.09-0.29-0.14-0.43c-2.47-0.16-3.24-0.64-3.39-2.13c-0.14-1.5,0.57-2.25,2.87-2.96
	c-0.04-0.67-0.08-1.36-0.12-2.04c-2.33-0.37-2.83-0.73-2.74-1.94c0.11-1.41,0.71-2.29,1.62-2.35c1.11-0.06,1.73,0.74,2.09,2.81
	c0.75-0.07,1.51-0.14,2.37-0.23c0-0.7,0-1.39,0-2.07c-2.77-0.3-3.51-0.77-3.63-2.31c-0.12-1.49,0.7-2.3,2.96-2.89
	c0-0.75,0-1.52,0-2.28c-0.75-0.15-1.6-0.22-2.33-0.53c-0.3-0.13-0.5-0.79-0.54-1.23c-0.15-1.85,0.5-2.51,2.62-2.79
	c-0.17-3.17,0.11-3.57,2.91-4.21c0-0.75,0-1.52,0-2.28c-2.35-0.05-2.89-0.37-3-1.8c-0.14-1.81,0.49-2.47,2.47-2.8
	c0.22-1.04,0.17-2.2,0.7-2.91c0.54-0.71,1.67-0.97,2.63-1.48c0.15-0.86,0.34-1.93,0.53-3c0,0-0.07,0.11-0.07,0.11
	c0.95-0.28,1.9-0.56,2.84-0.84c-0.05-0.72-0.09-1.33-0.13-1.89c-1.02-0.15-1.86-0.28-2.7-0.41c-0.09-0.07-0.18-0.13-0.28-0.19
	c-1.25-2.86-0.65-3.94,2.5-4.58c0.23-3.03,0.44-3.28,3.38-3.9c-0.13-0.68-0.26-1.36-0.39-2.02c-2.49-0.27-3.28-0.82-3.35-2.29
	c-0.08-1.58,0.68-2.26,2.9-2.66c0.25-0.99,0.25-2.11,0.79-2.84c0.49-0.66,1.56-0.9,2.53-1.4c0-0.55,0-1.32,0-1.98
	c-0.95-0.26-1.85-0.41-2.65-0.78c-0.32-0.15-0.51-0.79-0.56-1.23c-0.14-1.26,0.69-2.24,1.86-2.32c1.29-0.09,1.83,0.56,2.28,2.78
	c0.78-0.08,1.56-0.16,2.4-0.24c0-0.78,0-1.56,0-2.34c-2.47,0.1-3.38-0.37-3.6-1.84c-0.23-1.52,0.54-2.36,2.55-2.91
	c0.38-1.08,0.53-2.23,1.14-3.04c0.44-0.59,1.49-0.72,2.32-1.08c0.25-0.81,0.33-2,0.94-2.78c0.49-0.63,1.62-0.76,2.41-1.08
	c0.3-0.86,0.44-2.02,1.05-2.8c0.44-0.57,1.51-0.64,2.48-1c-0.06-0.55-0.15-1.38-0.23-2.21c-2.45,0.08-3.37-0.41-3.57-1.87
	c-0.22-1.55,0.73-2.42,2.95-2.79c0.31-1.07,0.4-2.23,0.97-3.07c0.42-0.61,1.47-0.79,2.2-1.14c0.19-0.87,0.15-2.04,0.69-2.74
	c0.55-0.71,1.69-0.95,2.62-1.43c0.2-0.87,0.44-1.93,0.68-2.99c0,0-0.08,0.08-0.08,0.08c0.89-0.25,1.79-0.5,2.67-0.74
	c0.25-0.87,0.29-1.98,0.85-2.71c0.5-0.65,1.55-0.89,2.35-1.3c0.21-0.82,0.43-1.72,0.66-2.62c0,0,0.09-0.13,0.09-0.13
	c0.92-0.35,1.85-0.7,2.86-1.08c-0.07-0.63-0.15-1.4-0.23-2.17c-2.1-0.07-2.84-0.46-2.96-1.57c-0.11-1,1.07-2.33,2.16-2.45
	c0.97-0.1,1.41,0.47,1.93,2.53c0.16,0.01,0.32,0.05,0.46,0.02c0.61-0.12,1.23-0.25,1.77-0.37c0.14-0.99,0.26-1.92,0.39-2.84
	l-0.09,0.09c0.93-0.4,1.86-0.8,2.8-1.21c0.33-2.75,0.58-3.04,3.53-3.77c-0.35-2.35,0.91-3.49,3.15-3.69
	c0.31-3.19,0.57-3.49,3.22-3.91c0.41-1.05,0.69-2.1,1.22-3c0.22-0.38,0.95-0.59,1.46-0.63c1.3-0.1,1.99,0.82,2.42,3.06
	c0.67,0,1.37,0,2.04,0c0.12-0.96,0.24-1.88,0.35-2.8c0.15-0.14,0.3-0.28,0.44-0.42c0.9-0.26,1.8-0.51,2.65-0.75
	c0.11-1.02,0.23-2.1,0.35-3.17l-0.09,0.08c0.94-0.38,1.88-0.76,2.81-1.14c0.28-2.72,0.56-3.03,3.38-3.76
	c0.34-2.91,0.72-3.31,3.29-3.5c0.25-1,0.23-2.16,0.8-2.86c0.56-0.69,1.69-0.92,2.64-1.38c-0.23-3.29,0.89-4.27,3.87-3.17
	c0,0-0.05-0.04-0.05-0.04c0.44,0.96,0.88,1.91,1.36,2.96c0.62-0.07,1.29-0.15,1.88-0.22c0.09-1.03,0.16-1.89,0.23-2.74
	c0.13-0.14,0.26-0.28,0.39-0.42c0.88-0.24,1.77-0.48,2.73-0.74c0-0.76,0-1.59,0-2.2c-1.16-0.18-2.2-0.34-3.25-0.49l0.08,0.09
	c-0.97-3-0.6-3.69,2.26-4.46c0.23-1.19,0.45-2.32,0.67-3.44l-0.06,0.07c1.06-0.19,2.12-0.38,2.8-0.5c0.31-1.22,0.57-2.29,0.84-3.35
	c0,0-0.07,0.1-0.07,0.1c0.8-0.31,1.6-0.62,2.51-0.98c0-0.62,0-1.4,0-2.19c-2.58,0-3.55-0.56-3.62-2.06
	c-0.06-1.24,0.94-2.28,2.23-2.32c1.48-0.05,2.18,0.85,2.43,3.17c0.54-0.05,1.08-0.12,1.63-0.16c0.23-0.02,0.47,0.03,0.63,0.04
	c0.25-1,0.22-2.19,0.8-2.84c0.59-0.65,1.78-0.77,2.81-1.16c0.18-0.61,0.34-1.5,0.73-2.29c0.16-0.33,0.78-0.52,1.23-0.61
	c1.5-0.31,1.95,0.06,2.83,2.23c0.67,0,1.37,0,2.24,0c0.04-1.67,0.14-3.35,2.25-3.62c1.53-0.2,2.3,0.65,2.9,3.2
	c0.67-0.03,1.36-0.07,2.06-0.1c-0.18-2.9,0.1-3.78,3.42-4.19c0.15-2.13,0.8-3.13,2.09-3.2c1.3-0.07,2.08,1,2.21,3.03
	c0.92-0.06,1.83-0.11,2.66-0.17c0.26-0.9,0.45-1.88,0.85-2.76c0.15-0.33,0.79-0.53,1.23-0.6c1.35-0.22,2.06,0.52,2.71,2.66
	c3.2-0.02,3.74,0.43,3.97,3.47c0.61-0.06,1.23-0.13,1.84-0.19c0.3-1.16,0.6-2.28,0.89-3.4l-0.08,0.08c0.08,0.01,0.16,0.04,0.24,0.02
	c2.98-0.77,3.6-0.42,4.46,2.62c0.58-0.03,1.18-0.06,1.9-0.1c0.02-2.16,0.56-3.96,3.2-3.5c1.66,0.3,1.89,1.72,1.83,3.21
	c0.86,0,1.71,0,2.4,0c0.16-0.98,0.18-2.06,0.55-2.99c0.18-0.44,0.99-0.8,1.57-0.89c1.49-0.25,2.33,0.8,2.68,3.2
	c0.91,0,1.84,0,2.77,0c0.28,0.26,0.57,0.51,0.85,0.77c0.09,0.85,0.18,1.69,0.27,2.58c0.87-0.04,1.64-0.08,2.16-0.11
	c0.22-1.05,0.4-1.93,0.58-2.82c0.12-0.1,0.23-0.2,0.33-0.31c2.6-1.09,3.55-0.52,4.16,2.59c0.76,0,1.53,0,2.03,0
	c0.32-1.19,0.61-2.26,0.91-3.33c0,0-0.07,0.09-0.07,0.09c0.95-0.27,1.91-0.53,2.91-0.81c0.08-2.2,0.5-2.97,1.81-3.19
	c1.33-0.23,2.14,0.47,2.83,2.39c1.04,0.24,2.21,0.22,2.93,0.77c0.71,0.54,0.95,1.68,1.45,2.66c0.42,0,1.03,0,1.64,0
	c0.23-2.52,0.86-3.47,2.33-3.62c1.47-0.14,2.27,0.65,2.88,2.84c0.99,0.12,1.99,0.24,2.98,0.35c0.15,0.18,0.29,0.35,0.44,0.53
	c0.16,0.9,0.32,1.81,0.49,2.74c0.72-0.11,1.34-0.21,1.92-0.3c0.12-0.87,0.23-1.65,0.34-2.44c0.18-0.29,0.36-0.58,0.54-0.87
	c1.2,0,2.39,0,3.59,0c0,0-0.09-0.09-0.09-0.09c0.3,0.89,0.6,1.78,0.94,2.78c0.63,0,1.31,0,1.99,0c0.06-2.45,0.67-3.45,2.16-3.63
	c1.53-0.19,2.4,0.73,2.86,3.03c0.96,0.24,2.09,0.25,2.83,0.8c0.66,0.49,0.88,1.57,1.36,2.52c0.49,0,1.19,0,1.73,0
	c0.23-1.12,0.44-2.17,0.65-3.23c0,0-0.08,0.08-0.08,0.08c0.08-0.01,0.16-0.02,0.24-0.04c2.96-0.95,3.84-0.39,4.23,2.63
	c1.09,0.15,2.15,0.29,3.22,0.43c0,0-0.09-0.09-0.09-0.09c0.31,1.01,0.63,2.02,0.93,2.98c0.97,0.16,2.02,0.33,3.07,0.5l-0.09-0.09
	c0.28,0.89,0.57,1.78,0.91,2.86c0.61-0.03,1.35-0.07,2.1-0.12c-0.06-2.39,0.64-3.52,2.18-3.65c1.57-0.12,2.43,1,2.61,3.22
	c1.33,0.24,2.58,0.48,3.83,0.71l-0.06-0.07c0.18,0.84,0.35,1.68,0.53,2.56c0.71,0,1.4,0,2.21,0c-0.06-2.05,0.05-4.14,2.98-3.19
	c0.59,0.19,1.12,1.34,1.2,2.1c0.11,1.12-0.84,1.66-2.9,1.97c0.1,0.81,0.21,1.63,0.31,2.44c0.82,0,1.59,0,2.35,0
	c-0.04-2.43,0.46-3.34,1.92-3.5c1.55-0.17,2.29,0.65,2.6,2.84c0.98,0.19,2.13,0.1,2.83,0.62c0.72,0.54,1.02,1.66,1.56,2.65
	c2.76,0.22,3.05,0.46,3.75,3.3c2.92,0.25,3.24,0.52,3.8,3.28c0.82-0.04,1.66-0.08,2.49-0.12c0.01-2.06,0.24-2.52,1.27-2.57
	c1.05-0.05,2.28,0.8,2.3,1.71c0.04,1.59-1.3,1.54-2.4,1.76c0.1,0.83,0.2,1.61,0.31,2.49c0.72,0,1.48,0,2.34,0
	c0.08-0.93-0.6-2.35,0.93-2.73c1.41-0.35,2.08,0.86,2.8,1.74c-0.1,1.72-1.38,1.76-2.64,1.83c0.17,0.82,0.31,1.51,0.45,2.18
	c3.1,0.32,3.25,0.45,3.88,3.26c1.06,0.29,2.04,0.56,3.02,0.84c0,0-0.07-0.08-0.07-0.08c0.28,0.96,0.57,1.92,0.89,2.99
	c0.66-0.04,1.55-0.09,2.41-0.15c0.2-0.83,0.37-1.71,0.66-2.54c0.07-0.2,0.52-0.36,0.81-0.39c0.95-0.12,2.24,1.07,2.27,2.03
	c0.02,0.92-0.55,1.29-2.61,1.61c0.1,0.43,0.21,0.85,0.29,1.28c0.07,0.38,0.12,0.77,0.18,1.14c0.98,0.09,1.9,0.18,2.83,0.27
	l-0.08-0.08c0.4,1,0.8,2,1.24,3.09c0.71,0.17,1.87,0.13,2.55,0.68c0.69,0.57,0.9,1.71,1.33,2.63c0.77,0.21,1.89,0.25,2.6,0.8
	c0.64,0.51,0.83,1.59,1.28,2.54c0.76,0,1.7,0,2.63,0c0.15,0.13,0.3,0.26,0.44,0.39c0.26,0.89,0.53,1.78,0.83,2.81
	c0.53,0,1.22,0,2.08,0c0-0.38-0.03-0.75,0-1.11c0.15-1.63,1-2.45,2.38-2.28c0.95,0.12,1.67,0.6,1.84,1.58c0.1,0.61,0,1.26,0,1.89
	c0,0,0.09-0.07,0.09-0.07c-0.9,0.42-1.81,0.84-2.84,1.32c0.06,0.55,0.14,1.23,0.21,1.89c0.95,0.16,1.78,0.3,2.62,0.45
	c0.11,0.08,0.23,0.15,0.34,0.22c0.37,0.84,0.74,1.67,1.14,2.57c0.6,0,1.29,0,1.92,0c0.15-1.11,0.29-2.17,0.44-3.23
	c0,0,0.05-0.09,0.05-0.09c0.55-0.05,1.11-0.16,1.66-0.15c1.44,0.02,2.36,0.79,2.5,2.03c0.14,1.25-0.57,2.12-2.04,2.47
	c-0.3,0.07-0.63,0.01-1.03,0.01c0,0.79,0,1.61,0,2.51c0.9-0.14,1.66-0.26,2.41-0.38c0.15-2.28,0.71-3.16,2.03-3.22
	c1.03-0.04,2.2,0.95,2.34,2c0.16,1.23-0.64,1.96-2.77,2.45c0.06,0.72,0.12,1.48,0.18,2.18c0.98,0.17,1.89,0.32,2.79,0.48l-0.08-0.07
	c0.46,0.89,0.92,1.79,1.42,2.77c2.78,0.35,3.09,0.63,3.53,3.27c0.95,0.31,2.12,0.38,2.78,0.99c0.66,0.61,0.81,1.76,1.22,2.75
	c2.04-0.05,3.2,0.66,3.35,2.18c0.14,1.41-0.58,2.1-2.95,2.69c0.11,0.56,0.22,1.13,0.35,1.77c0.79,0,1.48,0,2.18,0
	c-0.02-2.37,0.55-3.3,2.04-3.32c1.13-0.01,2.25,1.11,2.32,2.3c0.06,1.19-0.75,1.79-3.07,2.16c0.08,0.66,0.17,1.33,0.26,2.1
	c1,0.18,2,0.36,2.99,0.54c0.11,0.14,0.23,0.29,0.34,0.43c0.15,0.86,0.31,1.71,0.46,2.55c0.89,0.16,2.04,0.07,2.77,0.58
	c0.75,0.52,1.09,1.63,1.67,2.59c2.63,0.35,2.95,0.65,3.41,3.26c0.92,0.38,1.95,0.54,2.54,1.13c0.61,0.61,0.79,1.65,1.22,2.65
	c0.55,0.27,1.43,0.51,2.03,1.05c0.53,0.47,1.12,1.33,1.03,1.91c-0.09,0.57-0.99,1.03-1.56,1.5c-0.16,0.13-0.45,0.11-0.82,0.19
	c0.06,0.69,0.13,1.36,0.19,2.03c0.92,0.26,1.98,0.29,2.63,0.83c0.67,0.54,0.93,1.58,1.42,2.5c2.73,0.47,2.91,0.66,3.39,3.85
	c1.27,0.09,2.23,0.56,2.3,2.1c0.05,1.15,0.04,2.14-1.56,2.17c0.06,0.9,0.12,1.67,0.17,2.42c2.81,0.19,3.24,0.57,3.81,3.45
	c0.55,0,1.14,0,1.69,0c1.01,3.6,0.94,3.89-1.27,4.71c0.13,0.67,0.26,1.36,0.39,2.03c3.22,0.65,3.47,1.01,3.27,4.57
	c0,0,0.03-0.08,0.03-0.08c-0.97,0.26-1.94,0.52-3.01,0.8c0.1,0.66,0.2,1.33,0.31,2.13c0.97,0,1.83,0,2.69,0l0.11,0.21
	c0.31,1.05,0.62,2.1,0.93,3.17c0.7,0.18,1.66,0.25,2.4,0.69c0.42,0.25,0.6,1.08,0.7,1.68c0.28,1.69-0.06,2-2.58,2.65
	c0.04,0.61,0.09,1.23,0.14,1.9c0.22,0.07,0.42,0.16,0.64,0.21c0.61,0.14,1.24,0.21,1.83,0.4c0.27,0.09,0.67,0.42,0.66,0.64
	c-0.07,2.13,2.44,2.49,2.86,4.28c0.38,1.59,0.15,2-1.79,2.77c0.11,0.66,0.23,1.35,0.36,2.12c3.3,0.12,3.72,2.07,3,4.72
	c0,0,0.09-0.08,0.09-0.08c-0.91,0.19-1.83,0.38-2.76,0.57c0.04,0.78,0.08,1.47,0.11,2.03c1.13,0.12,2.13,0.23,3.13,0.34
	c0,0-0.06-0.07-0.06-0.07c0.33,1.04,0.67,2.07,1.1,3.43c1.33,0.08,1.7,1.28,1.8,2.75c0.09,1.28-0.72,1.63-1.73,1.63
	c0.1,1.03,0.18,1.81,0.28,2.72c0.72,0.08,1.48,0.17,2.17,0.25c1.89,3.59,1.8,3.83-1.71,4.47c0.07,0.61,0.15,1.22,0.24,1.97
	c2.21,0.13,4.07,0.75,3.27,3.55c-0.41,1.43-1.75,1.4-3.1,1.41c0.09,0.81,0.17,1.56,0.28,2.51c0.36-0.05,0.71-0.13,1.06-0.13
	c2.11-0.02,2.99,1.01,2.73,3.16c-0.05,0.39-0.1,0.78-0.15,1.18l0.08-0.08c-1.06,0.23-2.11,0.46-3.31,0.72
	c0.14,0.68,0.29,1.39,0.44,2.08c3.09,0.24,3.74,0.92,3.45,3.48c-0.04,0.38,0.04,0.76,0.06,1.14c-1.06,0.13-2.11,0.27-3.23,0.42
	c0.15,0.83,0.28,1.57,0.42,2.33c0.91-0.07,1.67-0.13,2.08-0.16c0.26-1.04,0.47-1.84,0.67-2.64c0.53-0.64,1.18-1.27,2.01-0.7
	c1.03,0.7,1.11,1.91,1.03,3.03c-0.09,1.27-1.24,1.23-2.26,1.35c0.05,0.9,0.09,1.67,0.13,2.44c2.33,0.11,3.15,0.7,3.27,2.31
	c0.12,1.61-0.43,2.13-2.83,2.59c0,0.42,0,0.87,0,1.32c0,0.39,0,0.78,0,1.19c2.55-0.27,3.46,0.21,3.69,1.9
	c0.23,1.71-0.48,2.4-3.01,2.87c0.06,0.67,0.13,1.35,0.18,1.9c1.02,0.37,2.01,0.6,2.86,1.09c0.37,0.22,0.55,0.95,0.6,1.47
	c0.16,1.57-0.48,2.17-2.91,2.84c0.04,0.6,0.08,1.23,0.13,1.91c2.08-0.06,4.07,0.08,3.57,2.97c-0.24,1.39-1.2,1.84-3.42,1.78
	c0.11,0.84,0.23,1.68,0.35,2.58c0.83-0.08,1.66-0.17,2.62-0.26c0.16-1.13-0.48-2.77,1.64-3.17c0.25,1.47,1.03,2.69-0.29,4.17
	c-0.58,0.65-0.27,2.09-0.36,3.16c0,0,0.08-0.07,0.08-0.07c-1.12,0.35-2.23,0.7-3.36,1.06c0.17,0.64,0.37,1.38,0.54,2.03
	c1.07,0.12,2.06,0.23,3.05,0.34l0.01,0c0.23,1.07,0.46,2.14,0.72,3.34c0.41,0.16,1.18,0.26,1.6,0.7c0.39,0.41,0.43,1.17,0.57,1.79
	c0.32,1.41-0.7,1.74-1.8,1.96c0.06,0.91,0.1,1.69,0.16,2.67c1.7-0.03,2.14,1.12,2.22,2.61c0.07,1.26-0.4,2.02-1.73,1.84
	c0,1.1,0,1.96,0,2.82c0.69,0.08,1.29,0.16,2.04,0.25c0.23,1.19,0.45,2.34,0.71,3.66c-0.65,0.26-1.28,0.51-2.02,0.81
	c0.03,0.64,0.07,1.34,0.12,2.25c1.49-0.03,2.5,0.46,2.5,2.26c0,1.46-0.24,2.52-1.94,2.78c0.08,0.66,0.14,1.19,0.2,1.69
	c2.54,0.63,3.04,1.15,2.98,3.01c-0.05,1.55-0.27,1.73-2.76,2.19c0,0.22-0.03,0.45,0.01,0.66c0.07,0.47,0.18,0.93,0.26,1.36
	c2.41,0.55,2.65,0.77,2.72,2.6c0.07,1.76-0.09,1.94-2.34,2.65c0.13,0.68,0.26,1.37,0.38,2.02c2.52,0.41,2.95,0.82,2.93,2.65
	c-0.02,1.66-0.36,1.98-2.61,2.38c0,0.83,0,1.66,0,2.5c2.42-0.31,3.27,0.2,3.53,2.03c0.21,1.5-0.51,2.2-2.89,2.75
	c0,0.69,0,1.39,0,1.95c1.03,0.36,2.02,0.57,2.84,1.06c0.35,0.21,0.45,0.99,0.49,1.53c0.13,1.7-0.33,2.11-2.84,2.72
	c0,0.78,0,1.56,0,2.35c2.54-0.26,3.49,0.21,3.73,1.8c0.26,1.78-0.45,2.48-3.14,3.02c0.1,0.68,0.19,1.36,0.29,2.03
	c2.46,0.3,3.18,0.81,3.35,2.33c0.18,1.56-0.48,2.24-2.8,2.88c0,0.69,0,1.39,0,1.99c0.96,0.23,1.91,0.31,2.7,0.69
	c1,0.49,0.88,1.57,1,2.53c0.06,0.5,0.63,0.92,0.74,1.43c0.17,0.81,0.16,1.66,0.23,2.58c-1.81-0.22-1.29-1.6-1.72-2.5
	c-0.83,0.11-1.65,0.21-2.49,0.32c0,0.9,0,1.68,0,2.46c3.09-0.34,3.35-0.15,4.02,2.84c0.09,0.38,0.35,0.72,0.43,1.1
	c0.26,1.2,0.78,2.27,0.03,3.65c-0.4,0.74-0.75,2.29,0.77,3.24c0.7,0.43,0.83,1.78,1.27,2.86c-1.89,0.79-0.9,2.8-1.79,4.07
	c0,0,0.01,0.01,0.01,0.01c-0.87,1.18-1.93,1.34-3.18,0.62c-0.52-0.97-1.02-1.95-1.53-2.94c-0.58,0.11-1.25,0.24-1.94,0.37
	c0,0.83,0,1.6,0,2.26c1.25,0.09,2.39,0.17,3.54,0.26c0.15,0.97,0.29,1.94,0.41,2.75c1.04,0.21,1.88,0.37,2.71,0.54
	c0.07,0.06,0.14,0.12,0.21,0.17c1.39,2.69,0.83,3.72-2.4,4.45c0.04,0.69,0.09,1.39,0.13,2.09c0.98,0.14,2.02,0.29,3.07,0.44
	c0,0,0.07,0.15,0.07,0.15c0.26,0.97,0.53,1.95,0.83,3.07c1.12-0.02,2.31,0.03,2.47,1.7c0.16,1.67-0.09,2.13-2.04,2.97
	c0,0.35-0.01,0.74,0,1.12c0.01,0.39,0.03,0.78,0.05,1.24c0.67,0.11,1.27,0.21,1.93,0.32c0.06,0.65,0.04,1.2,0.17,1.73
	c0.49,2.05,0.51,2.05-1.66,2.65c0.04,0.73,0.08,1.5,0.12,2.41c1.1,0.34,2.8-0.07,2.76,2.03c-0.03,1.85-0.08,2.09-2.17,2.91
	c0.07,0.66,0.14,1.34,0.21,2.02c2.18,0.28,3.15,0.97,3.17,2.23c0.02,1.47-0.81,2.21-3.05,2.68c0.16,0.84,0.31,1.69,0.42,2.27
	c0.87,0.24,2.02,0.25,2.2,0.67c0.42,0.96,0.46,2.12,0.42,3.2c-0.01,0.3-0.78,0.75-1.24,0.83c-0.68,0.1-1.41-0.07-2.11-0.14
	c-0.08-1.03-0.18-2.06-0.27-3.14c-0.96,0.23-1.63,0.39-2.22,0.54c0,0.83,0,1.51,0,2.02c0.93,0.2,1.73,0.37,2.54,0.54
	c0.53,0.76,1.07,1.53,1.85,2.64c0.39,0.15,1.72,0.25,2.11,0.91c0.53,0.9,0.36,2.22,0.49,3.37c-0.88,0.26-1.76,0.51-2.78,0.81
	c0.04,0.77,0.09,1.58,0.13,2.4c0.96-0.14,1.78-0.26,2.58-0.38c0-1.06,0-1.97,0-2.89c0.34,0.37,0.9,0.71,0.98,1.13
	c0.17,0.84,0.05,1.73,0.09,2.6c0.07,1.31-0.03,2.59,0.84,3.82c0.7,0.98,0.81,2.17,0.06,3.51c-0.53,0.95-0.17,2.39-0.22,3.61
	c-0.98,0.18-1.95,0.36-2.9,0.54c0,0.8,0,1.48,0,2.15c0.85,0,1.61,0,2.21,0c0.23-1.01,0.43-1.88,0.63-2.74
	c0.41-0.07,0.97-0.35,1.22-0.18c0.7,0.48,0.97,3.27,0.25,3.67c-1.21,0.67-0.36,1.52-0.56,2.23c1.88,1.08,2.51,2.14,2.21,3.69
	c-0.27,1.36-1.32,1.09-2.25,1.06c-0.16-0.11-0.31-0.22-0.47-0.33c-0.21-0.81-0.42-1.62-0.64-2.44c-0.73,0-1.4,0-2.05,0
	c-0.18,1-0.34,1.89-0.5,2.78c-0.06,0.09-0.12,0.18-0.18,0.27c-0.88,0.32-1.76,0.63-2.46,0.89c-0.2,0.91-0.4,1.8-0.59,2.69
	c-0.11,0.1-0.21,0.2-0.31,0.31c-0.89,0.81-1.89,0.72-2.92,0.36c-0.09-0.06-0.18-0.13-0.28-0.18c-0.86-1-0.74-2.19-0.69-3.37
	c0.16-0.14,0.33-0.28,0.49-0.42c0.89-0.26,1.78-0.53,2.67-0.79c-0.11-0.75-0.21-1.43-0.28-1.93c-1.06-0.28-2.17-0.27-2.79-0.81
	c-0.68-0.6-0.9-1.71-1.27-2.48c-0.89-0.1-1.74-0.2-2.6-0.29c-0.17-0.15-0.34-0.31-0.51-0.46c-0.17-0.95-0.35-1.91-0.52-2.85
	c-0.83,0.08-1.64,0.15-2.3,0.21c-0.08,0.93-0.15,1.78-0.22,2.63c-0.12,0.16-0.23,0.33-0.35,0.49c-1.01,0.89-2.15,0.68-3.31,0.43
	c-0.4-0.91-0.8-1.82-1.26-2.88c-0.62,0.04-1.36,0.09-2.12,0.14c0.05,0.94,0.1,1.7,0.13,2.25c1.19,0.16,2.25,0.3,3.31,0.44
	c0.23,0.94,0.45,1.88,0.66,2.73c0.96,0.1,1.8,0.19,2.63,0.28c0.15,0.14,0.31,0.27,0.46,0.41c1.13,2.88,0.35,4.01-2.89,4.07
	c0.19,0.83,0.37,1.66,0.58,2.6c0.91,0,1.84,0,2.77,0c0.17,0.18,0.33,0.35,0.5,0.53c0.37,1.12,0.46,2.24,0.01,3.36
	c-0.1,0.15-0.2,0.3-0.31,0.44c-0.99,0.1-1.98,0.2-2.68,0.28c-0.16,1.18-0.29,2.17-0.42,3.16c0,0,0.08-0.09,0.08-0.09
	c-1.08,0.61-2.18,1.03-3.43,0.5l0.07,0.06c-0.49-0.95-0.98-1.89-1.55-3c-0.43,0.05-1.1,0.13-1.85,0.22c0,1.01,0,1.88,0,2.75
	c-0.24,0.31-0.49,0.62-0.73,0.94c-0.85,0-1.69,0-2.54,0c0,0.99,0,1.76,0,2.35c1.14,0.19,2.18,0.37,3.22,0.54
	c0.17,0.08,0.33,0.15,0.5,0.23c0,0.94,0,1.89,0,2.6c1.29,0.22,2.36,0.4,3.43,0.58l-0.09-0.08c0.17,1.13,0.35,2.27,0.52,3.4
	c-0.15,0.14-0.3,0.27-0.46,0.41c-0.88,0.26-1.75,0.52-2.69,0.79c0.1,0.65,0.2,1.32,0.31,2.08c0.95,0,1.88,0,2.8,0
	c0.18,0.19,0.35,0.37,0.53,0.56c0,1.11,0,2.22-0.01,3.33c-0.11,0.16-0.23,0.31-0.34,0.47c-1-0.04-2.23,0.27-2.93-0.2
	c-0.77-0.52-1.05-1.76-1.58-2.75c-0.58,0.05-1.35,0.12-2.13,0.19c0.09,0.89,0.18,1.71,0.26,2.52c2.75-0.29,3.48,0.26,3.72,2.72
	c0.02,0.23,0.06,0.47,0.09,0.7c-0.07,0.06-0.15,0.11-0.22,0.17c-0.97,0.46-1.93,0.91-2.99,1.41c0,0.6,0,1.37,0,1.89
	c1.18,0.22,2.23,0.42,3.27,0.62l-0.09-0.08c0.3,0.92,0.59,1.85,0.87,2.72c0.88,0.08,1.71,0.16,2.55,0.24
	c0.16,0.15,0.32,0.29,0.48,0.44c0.24,0.89,0.49,1.77,0.75,2.71c0.75,0,1.57,0,2.26,0c0.07-1.12,0.14-2.12,0.21-3.12
	c0.2-0.17,0.4-0.35,0.6-0.52c0.99,0.07,2.22-0.2,2.9,0.3c0.73,0.53,0.95,1.77,1.3,2.49c1.04,0.19,2.02,0.36,3.01,0.54
	c0.16,0.07,0.31,0.14,0.47,0.21c0,0.99,0,1.98,0,3.03c1.02-0.18,1.85-0.33,2.53-0.45c0.13-0.97,0.24-1.87,0.36-2.77
	c0.15-0.16,0.3-0.32,0.45-0.48c1.15-0.71,2.3-0.42,3.44,0.03c0,0-0.1-0.08-0.1-0.08c0.28,0.94,0.56,1.88,0.81,2.73
	c0.93,0.09,1.77,0.18,2.61,0.27c0.15,0.13,0.3,0.27,0.45,0.4c0.58,0.94,0.8,1.91,0.23,2.93c-0.68,1.22-1.92,1.13-3.13,1.13
	c0.23,0.95,0.43,1.76,0.64,2.6c0.97,0,1.85,0,2.72,0c0.17,0.18,0.35,0.36,0.52,0.54c0.16,0.95,0.31,1.91,0.47,2.86
	c0.84-0.07,1.67-0.14,2.32-0.19c0.09-1.01,0.16-1.86,0.23-2.7c0.11-0.15,0.22-0.31,0.33-0.46c1.03-0.83,2.21-0.58,3.36-0.43
	c0.12,0.14,0.25,0.28,0.37,0.43c0.23,0.8,0.46,1.59,0.68,2.34c0.82,0.13,1.57,0.24,2.31,0.35c0.11,0.06,0.22,0.1,0.34,0.14
	c0.46,0.96,0.91,1.92,1.37,2.87c0.8,0.18,1.7,0.39,2.6,0.59l-0.07-0.07c1.45,2.77,0.95,3.85-2.24,4.52c0,0.34,0,0.71,0,1.08
	c0,0.39,0,0.78,0,1.23c0.92-0.05,1.69-0.09,2.24-0.12c0.33-1.05,0.53-2.01,0.95-2.87c0.16-0.33,0.79-0.53,1.24-0.6
	c0.71-0.11,1.54,1.09,1.83,2.5c0.22,1.09-0.34,1.56-2.47,2.03c0.03,0.67,0.06,1.36,0.1,2.09c0.29,0.09,0.5,0.16,0.72,0.21
	c0.62,0.12,1.35,0.06,1.82,0.38c0.83,0.57,1.22,1.49,0.93,2.53c-0.26,0.93-0.92,1.51-1.89,1.59c-0.55,0.05-1.11-0.09-1.66-0.14
	c-0.17-0.12-0.33-0.23-0.5-0.35c-0.2-0.82-0.4-1.63-0.6-2.45c-0.76,0-1.43,0-2.09,0c-0.17,1-0.33,1.9-0.49,2.8
	c-0.06,0.08-0.12,0.17-0.18,0.25c-0.86,0.32-1.73,0.63-2.63,0.96c0.05,0.69,0.11,1.5,0.16,2.15c0.99,0,1.84,0,2.69,0
	c0.19,0.3,0.37,0.61,0.56,0.91c0.23,0.87,0.45,1.74,0.68,2.63c2.7-0.34,3.21,0.04,3.89,2.94c0.95,0.13,1.89,0.26,2.82,0.39
	c0.07,0.09,0.16,0.17,0.24,0.25c0.49,1.13,0.67,2.28,0.14,3.46c0,0,0.09-0.08,0.09-0.08c-0.94,0.33-1.88,0.66-2.86,1.01
	c-0.03,0.71-0.07,1.56-0.11,2.42c-0.16,0.2-0.31,0.4-0.47,0.6c-1.02,0.8-2.14,0.58-3.26,0.33c-0.41-0.91-0.81-1.83-1.28-2.89
	c-0.61,0.04-1.35,0.09-2.11,0.14c0.05,0.95,0.09,1.71,0.12,2.27c1.2,0.15,2.26,0.29,3.32,0.42c0.22,0.94,0.44,1.88,0.65,2.77
	c0.94,0.07,1.78,0.12,2.62,0.18c0.18,0.17,0.37,0.33,0.55,0.5c0.49,0.95,0.89,2.1,0.1,2.88c-0.74,0.73-1.89,1.03-2.96,1.57
	c0.13,0.54,0.32,1.35,0.52,2.16c0.98,0,1.84,0,2.71,0c0.18,0.19,0.36,0.38,0.54,0.57c0.15,0.91,0.29,1.83,0.45,2.82
	c0.72-0.06,1.55-0.12,2.31-0.18c0.07-0.96,0.13-1.81,0.19-2.67c0.14-0.18,0.29-0.36,0.43-0.54c1.04-0.85,2.19-0.56,3.34-0.32
	l-0.09-0.09c0.39,0.92,0.78,1.83,1.16,2.74c0.85,0.32,2.11,0.44,2.69,1.13c0.54,0.63,0.35,1.88,0.48,2.86c0,0,0.08-0.05,0.08-0.05
	c-1.04,0.46-2.07,0.92-3.01,1.33c-0.13,0.8-0.28,1.69-0.42,2.59l0.06-0.07c-0.94,1.21-2.27,0.98-3.55,0.98
	c-0.1-0.08-0.19-0.16-0.28-0.24c-0.26-0.97-0.53-1.93-0.81-2.97c-0.65,0.07-1.34,0.14-2.12,0.22c0,0.87,0,1.73,0,2.6
	c-0.19,0.16-0.39,0.33-0.58,0.49c-0.91,0.29-1.82,0.59-2.79,0.9c0.07,0.67,0.16,1.49,0.23,2.19c1,0.1,1.85,0.18,2.69,0.26
	c0.16,0.14,0.32,0.29,0.48,0.43c0.25,0.88,0.49,1.77,0.75,2.68c3.24,0.1,3.67,0.53,3.53,3.65c-0.16,0.13-0.31,0.26-0.47,0.39
	c-0.86,0.31-1.72,0.63-2.63,0.96c0.08,0.6,0.17,1.29,0.26,1.94c0.92,0,1.79,0,2.65,0c0.16,0.31,0.31,0.61,0.47,0.92
	c0.24,0.85,0.49,1.71,0.74,2.6c0.77-0.05,1.7-0.1,2.63-0.16c0.17,0.3,0.33,0.61,0.5,0.91c0.54,0.77,0.93,1.72,0.26,2.43
	c-0.75,0.8-1.81,1.3-2.9,2.04c0.02,0.46,0.07,1.22,0.09,1.73c1.16,0.19,2.16,0.36,3.16,0.53c0.17,0.08,0.34,0.16,0.52,0.23
	c0.05,0.93,0.09,1.87,0.13,2.57c1.18,0.22,2.24,0.42,3.3,0.62l-0.09-0.07c0.28,0.93,0.57,1.87,0.81,2.66
	c0.93,0.11,1.77,0.22,2.61,0.32c0.15,0.14,0.31,0.28,0.46,0.41c1.14,2.89,0.38,4.02-2.83,4.16c0.15,0.76,0.29,1.52,0.46,2.38
	c0.73,0,1.49,0,2.38,0c-0.03-0.38-0.08-0.75-0.08-1.12c0.03-2,0.81-2.74,2.76-2.63c0.39,0.02,0.78,0.12,1.17,0.18l-0.08-0.08
	c0.7,1.22,1.07,2.47,0.48,3.85l0.09-0.06c-1.06,0.35-2.12,0.7-3.19,1.05c0.07,0.63,0.16,1.39,0.25,2.14
	c0.98,0.08,1.96,0.17,2.94,0.25l-0.1-0.08c0.36,1.08,0.71,2.16,1.11,3.37c0.61,0.14,1.61,0.24,2.48,0.63
	c0.38,0.17,0.63,0.9,0.7,1.41c0.21,1.5-0.47,2.09-2.82,2.54c0.06,0.75,0.11,1.52,0.16,2.11c1.02,0.3,2,0.45,2.84,0.88
	c0.39,0.2,0.65,0.91,0.74,1.42c0.21,1.19-0.85,2.4-2.05,2.49c-1.41,0.1-2.19-0.82-2.59-3.11c-0.76,0.04-1.53,0.08-2.39,0.12
	c0,0.73,0,1.5,0,2.21c1.09,0.25,2.33,0.22,3.06,0.81c0.67,0.55,0.79,1.77,1.17,2.74c2.16-0.4,3.51,0.27,3.66,1.95
	c0.13,1.52-0.97,2.38-3.43,2.6c0.13,0.73,0.25,1.46,0.37,2.17c2.64,0.29,3.67,1.03,3.67,2.6c0,1.23-0.98,2.3-2.21,2.41
	c-1.57,0.14-2.32-0.67-2.86-3.1c-0.77,0-1.54,0-2.31,0c0.07,2.48-0.49,3.5-2.02,3.64c-1.62,0.15-2.39-0.8-2.57-3.12
	c-1.05-0.24-2.3-0.19-3.03-0.79c-0.67-0.54-0.77-1.78-1.14-2.76c-2.15,0.19-3.09,0.34-3.77-3.08c-0.71,0.03-1.42,0.07-2.28,0.11
	c0.04,0.8,0.07,1.62,0.11,2.44c2.92-0.19,3.46,0.24,3.89,2.93c1.07,0.38,2.13,0.64,3.03,1.15c0.34,0.19,0.47,0.99,0.47,1.52
	c0.01,1.12-0.84,1.94-1.96,2.03c-1.5,0.12-2.2-0.7-2.6-3.09c-0.75,0.08-1.51,0.16-2.4,0.26c0.03,0.51,0.14,1.03,0.08,1.54
	c-0.14,1.22-1.1,2.1-2.22,2.12c-1.23,0.02-2.17-0.94-2.24-2.32c-0.02-0.47,0.1-0.95,0.16-1.42c0.93-0.37,1.85-0.73,2.9-1.15
	c-0.06-0.73-0.12-1.54-0.18-2.36c-0.92,0.1-1.69,0.19-2.25,0.25c-0.16,1.19-0.29,2.25-0.43,3.3c-1.13,0.15-2.25,0.3-3.38,0.45
	l0.09,0.09c-0.53-1.02-1.05-2.04-1.66-3.23c-0.43,0.06-1.1,0.15-1.86,0.25c0,0.58,0.07,1.06-0.01,1.51
	c-0.29,1.54-1.35,2.32-2.91,2.13c-0.6-0.07-1.18-0.3-1.77-0.46c0.01,0-0.03-0.03-0.03-0.03c-0.07-0.94-0.15-1.88-0.23-2.9
	c-0.73,0.1-1.41,0.18-2.01,0.26c-0.16,1.03-0.3,1.95-0.45,2.87l0-0.05c-1.82,1.25-2.99,1.29-3.88,0.02
	c-0.56-0.8-0.97-1.69,0.05-2.51c0.31-1.52,1.7-1.07,2.7-1.48c-0.12-0.68-0.24-1.36-0.38-2.15c-0.75,0.07-1.41,0.13-1.99,0.18
	c-0.12,1.28-0.23,2.37-0.33,3.46c-1.07,0.08-2.14,0.17-3.24,0.25c0,0.61,0.1,1.22-0.02,1.79c-0.23,1.08-1.23,1.77-2.26,1.69
	c-1.08-0.08-1.84-0.84-1.95-2.01c-0.05-0.54,0.02-1.09,0.03-1.64c0.94-0.28,1.87-0.56,2.91-0.87c-0.12-0.7-0.26-1.5-0.41-2.36
	c-0.71,0.09-1.31,0.17-1.82,0.24c-0.22,1.08-0.43,2.06-0.63,3.04c-0.44,0.16-0.88,0.37-1.34,0.46c-1.53,0.31-2.44-0.15-3.07-1.56
	c-0.19-0.42-0.28-0.87-0.45-1.38c-0.58,0.04-1.05,0.06-1.64,0.1c-0.19,2.05-0.47,4.22-3.4,3.37c-1.59-0.46-1.65-1.91-1.54-3.03
	c-1.22-0.15-2.35-0.29-3.48-0.42c-0.05-0.15-0.11-0.29-0.18-0.43c-0.24-0.74-0.48-1.47-0.75-2.33c-0.64,0.06-1.3,0.12-1.75,0.15
	c-0.26,1.21-0.48,2.19-0.69,3.17l0.08-0.08c-1.14,0.17-2.28,0.34-3.41,0.5c-0.16-0.18-0.33-0.36-0.49-0.53
	c-0.31-0.88-0.61-1.76-0.92-2.65c-0.55,0-1.17,0-1.62,0c-0.29,1.33-0.56,2.53-0.82,3.73l0.07-0.07c-0.08-0.01-0.16-0.05-0.23-0.03
	c-3.05,0.67-3.97-0.05-4.11-3.22c-0.76,0.14-1.52,0.29-2.03,0.38c-0.48,1.09-0.78,2.14-1.38,2.96c-0.27,0.37-1.16,0.45-1.74,0.38
	c-1.42-0.18-1.84-1.21-1.35-3.01c0.21-1.59,1.63-1.14,2.64-1.53c-0.13-0.75-0.26-1.49-0.39-2.27c-0.81,0.06-1.49,0.11-2.06,0.16
	c-0.07,1.32-0.13,2.48-0.19,3.64c-1.18,0.05-2.36,0.09-3.54,0.14l0.08,0.08c-0.54-1-1.07-2.01-1.74-3.26c-0.39,0-1.16,0-1.83,0
	c-0.3,0.97-0.5,2.01-0.96,2.93c-0.19,0.37-0.95,0.57-1.47,0.61c-1.32,0.09-2.06-0.9-2.48-3.14c-3.06-0.04-3.3-0.22-4.04-2.99
	c-1.04-0.17-2.04-0.34-3.04-0.51c-0.13-0.07-0.25-0.14-0.37-0.22c-0.09-0.91-0.18-1.82-0.28-2.75c-0.82,0-1.57,0-2.28,0
	c-0.13,1.13-0.25,2.17-0.37,3.22c0,0-0.04,0.08-0.04,0.08c-0.08,0.01-0.16,0.03-0.24,0.04c-3.11,0.57-3.67,0.2-4.25-2.76
	c-3.32,0.22-3.71-0.97-3.77-3.38c-0.92,0-1.86,0-2.8,0c-0.13-0.15-0.26-0.29-0.38-0.44c-0.28-0.89-0.56-1.78-0.87-2.74
	c-0.67,0.1-1.44,0.22-2.18,0.34c-0.11,1.02-0.23,2.03-0.34,3.03c0,0-0.08,0.17-0.08,0.17c-0.39,0.06-0.78,0.14-1.17,0.18
	c-2.13,0.24-2.82-0.33-3.06-2.52c-0.02-0.23-0.05-0.46-0.08-0.69c-3.59,0.26-3.76-0.8-3.92-3.22c-1.11-0.12-2.18-0.23-3.24-0.34
	c0,0,0.08,0.07,0.08,0.07c-0.42-0.92-0.83-1.84-1.28-2.82c-0.61,0-1.3,0-1.77,0c-0.39,1.13-0.62,2.17-1.12,3.08
	c-0.2,0.37-0.96,0.58-1.47,0.61c-1.59,0.09-2.36-1.11-2.29-3.23c-1-0.17-2.17-0.06-2.89-0.57c-0.73-0.52-1.03-1.66-1.51-2.51
	c-0.79-0.11-1.71-0.24-2.63-0.37c-0.12-0.11-0.24-0.22-0.37-0.33c-0.21-0.91-0.42-1.82-0.64-2.75c-0.7,0-1.47,0-2.14,0
	c-0.25,1-0.39,2.05-0.8,2.98c-0.16,0.37-0.91,0.62-1.42,0.69c-1.45,0.2-2.1-0.52-2.78-2.87c-3-0.21-3.34-0.5-3.83-3.22
	c-1.17-0.28-2.29-0.56-3.42-0.83l0.08,0.08c-0.11-0.43-0.34-0.87-0.29-1.28c0.07-0.6,0.34-1.18,0.53-1.77
	c0.22-1.57,1.79-0.94,2.61-1.53c-0.05-0.74-0.09-1.44-0.13-2.18c-0.86,0.06-1.63,0.11-2.48,0.17c0,1.24,0,2.4,0,3.56
	c-0.23-0.05-0.47-0.19-0.68-0.15c-3.12,0.57-3.64,0.21-4.07-2.84c-2.78,0.15-3.47-0.11-3.88-3.36c-2.52-0.03-3.47-0.58-3.61-2.1
	c-0.14-1.57,0.75-2.32,3.15-2.66c-0.08-0.78-0.16-1.56-0.25-2.42c-0.75,0.05-1.51,0.09-2.27,0.14c0.05,2.55-0.49,3.49-2.06,3.55
	c-1.2,0.05-2.2-0.77-2.33-1.93c-0.17-1.44,0.76-2.25,3.1-2.62c-0.1-0.66-0.21-1.33-0.31-1.97c-1.15-0.28-2.29-0.56-3.42-0.83
	c0,0,0.08,0.08,0.08,0.08c-0.16-0.98-0.32-1.96-0.49-2.95c-2.25,0.15-3.36-0.42-3.64-1.91c-0.27-1.46,0.67-2.4,3.02-2.95
	c0-0.67,0-1.35,0-2.16c-0.97-0.05-1.9-0.09-2.82-0.13c-0.13-0.15-0.25-0.29-0.38-0.44c-0.27-0.89-0.55-1.78-0.85-2.76
	c-0.74-0.17-1.91-0.12-2.59-0.67c-0.69-0.55-0.91-1.7-1.38-2.67c-2.3-0.07-3.28-0.68-3.33-2.19c-0.05-1.42,0.8-2.1,3.13-2.51
	c-0.07-0.38-0.17-0.76-0.2-1.14c-0.04-0.39-0.01-0.78-0.01-1.36c-0.45,0.03-0.81,0.09-1.16,0.07c-1.99-0.14-2.75-1.15-2.41-3.17
	c0.05-0.3,0.06-0.61,0.09-0.92c1.03-0.26,2.06-0.52,3.07-0.78c-0.07-0.85-0.13-1.63-0.2-2.41c-0.9,0.07-1.73,0.13-2.32,0.17
	c-0.18,1.12-0.34,2.09-0.5,3.07c-0.44,0.16-0.87,0.41-1.32,0.47c-1.31,0.19-2.25-0.42-2.54-1.56c-0.31-1.26,0.29-2.3,1.64-2.71
	c0.37-0.11,0.78-0.07,1.05-0.1c0.26-1.14,0.51-2.23,0.76-3.33l-0.05,0.06c0.91-0.34,1.83-0.68,2.77-1.03
	c-0.05-0.65-0.11-1.4-0.16-1.98c-1.12-0.14-2.11-0.27-3.1-0.4l0.04,0.07c-0.27-1.06-0.55-2.11-0.83-3.2
	c-2.44-0.13-3.36-0.68-3.5-2.13c-0.14-1.51,0.74-2.25,3.16-2.67c-0.12-0.7-0.23-1.4-0.37-2.23c-0.58,0-1.22,0.11-1.79-0.02
	c-1.17-0.28-1.89-1.41-1.76-2.51c0.15-1.21,1.25-2.12,2.52-2.07c0.61,0.02,1.22,0.1,1.83,0.16c0.13,1.02,0.26,2.03,0.39,3.04
	c0.89-0.11,1.57-0.2,2.34-0.29c-0.08-0.82-0.15-1.58-0.18-1.97c-1.02-0.29-1.81-0.51-2.59-0.73c-1.34-0.74-1.05-2.02-1.07-3.2
	c0.14-0.16,0.28-0.32,0.42-0.49c0.87-0.33,1.74-0.65,2.67-1c0-0.63,0-1.32,0-1.77c-1.14-0.29-2.16-0.56-3.19-0.82l0.04-0.04
	c-0.29-0.81-0.58-1.62-0.94-2.62c-0.07,0-0.36,0.01-0.65,0c-2.31-0.1-3.16-1.25-2.52-3.42c0.09-0.3,0.21-0.59,0.32-0.89
	c0,0-0.09,0.09-0.09,0.09c0.87-0.23,1.75-0.47,2.59-0.7c-0.08-0.87-0.16-1.63-0.22-2.25c-0.91,0-1.69,0-2.47,0
	c-0.13-0.17-0.25-0.33-0.38-0.5c-1.47-2.73-0.69-4.04,2.61-4.26c-0.06-0.82-0.12-1.65-0.19-2.47c-2.38,0.1-3.49-0.51-3.69-2.04
	c-0.19-1.43,0.71-2.28,2.99-2.78c0.06-0.91,0.12-1.84,0.18-2.78c0.18-0.18,0.35-0.37,0.53-0.55c0.88-0.23,1.77-0.46,2.67-0.69
	c-0.1-0.69-0.2-1.36-0.3-2.05c-0.86-0.05-1.64-0.09-2.41-0.14c-0.28-0.29-0.57-0.58-0.85-0.88c-0.1-0.92-0.2-1.84-0.3-2.76
	c-3.18,0.66-4.31-0.42-3.64-3.5c0.13-0.15,0.26-0.3,0.39-0.45c0.91-0.21,1.82-0.41,2.72-0.61c-0.07-0.82-0.15-1.67-0.23-2.51
	c-3.04,0.33-3.89-0.59-3.38-3.64l-0.07,0.08c0.94-0.44,1.89-0.88,2.88-1.34c0.07-0.81,0.15-1.72,0.23-2.63
	c0.12-0.11,0.23-0.22,0.33-0.34c0.94-0.27,1.88-0.55,2.74-0.8c0-0.83,0-1.59,0-2.11c-1.16-0.2-2.2-0.39-3.25-0.57l0.08,0.08
	c-0.78-1.42-0.63-2.82,0.15-4.19l-0.05,0.07c0.85-0.08,1.69-0.16,2.47-0.23c0.23-1.01,0.21-2.15,0.74-2.89
	c0.47-0.66,1.56-0.87,2.45-1.32c0-0.59,0-1.36,0-2.14c-0.67,0-1.3,0.1-1.87-0.02c-1.14-0.25-1.91-1.37-1.8-2.47
	c0.11-1.11,1.06-2.05,2.22-2.11c0.7-0.03,1.4,0.07,2.1,0.11c0.13,1.07,0.26,2.13,0.39,3.25c0.85-0.1,1.59-0.2,2.38-0.29
	c-0.06-0.81-0.11-1.48-0.14-1.82c-1.06-0.45-1.85-0.79-2.64-1.12c-1.13-0.44-1.19-1.35-0.93-2.32c0.29-1.07,0.99-1.48,3.12-1.97
	c-0.21-1.34-0.24-2.76,1.31-3.39C497.52,437.15,498.44,436.88,498.99,437.93c1.47,0.22,0.89,1.68,1.49,2.44
	c0.62-0.11,1.24-0.21,1.91-0.33c-0.07-0.74-0.14-1.42-0.21-2.11C501.03,437.93,500.01,437.93,498.99,437.93z M452.47,23.4
	c0.23-1.56,1.89-1.12,2.72-1.88c-0.05-0.65-0.09-1.25-0.15-1.94c-0.83,0.09-1.49,0.17-2.1,0.24c-0.16,1.3-0.3,2.47-0.45,3.62
	c-0.93,0.03-1.86,0.05-2.83,0.08c0.12,1.01,0.2,1.68,0.28,2.34c0.82-0.07,1.51-0.14,2.13-0.19
	C452.21,24.89,452.34,24.14,452.47,23.4z M545.13,335.79c-1.41-0.28-0.88-1.64-1.36-2.45c-0.74,0.07-1.48,0.15-2.22,0.22
	c0.04,0.81,0.08,1.5,0.12,2.23C542.86,335.79,544,335.79,545.13,335.79c0.16,1.12,0.31,2.24,0.47,3.37
	c0.86-0.17,1.61-0.32,2.35-0.46c-0.09-0.8-0.17-1.47-0.21-1.87C546.73,336.42,545.93,336.11,545.13,335.79z M572.35,286.79
	c1.58,0.17,1.16,1.65,1.67,2.65c0.67-0.06,1.34-0.13,1.92-0.18c0-0.78,0-1.4,0-2.03C574.67,287.08,573.51,286.94,572.35,286.79
	c0-0.98,0-1.96,0-3.07c-1.03,0.16-1.78,0.28-2.55,0.4c0.07,0.88,0.12,1.64,0.16,2.14C570.89,286.47,571.62,286.63,572.35,286.79z
	 M602.23,288.25c0.31-1.4,1.62-0.96,2.52-1.36c0-0.77,0-1.55,0-2.39c-0.92,0.12-1.74,0.23-2.41,0.32c0,0.72,0.01,1.27,0,1.82
	c-0.02,0.54-0.06,1.09-0.1,1.63c-1.03,0.12-2.06,0.25-3.18,0.38c0.18,0.86,0.32,1.52,0.45,2.18c0.8,0,1.48,0,1.9,0
	C601.72,289.87,601.97,289.06,602.23,288.25z M572.23,192.94c0.25,0.9,0.51,1.8,0.78,2.79c0.58-0.08,1.27-0.17,2.09-0.27
	c-0.03-0.74-0.06-1.43-0.09-2c-1.03-0.16-1.94-0.3-2.85-0.44c-0.4-0.99-0.8-1.97-1.23-3.02c-0.58,0.08-1.25,0.18-1.96,0.28
	c0,0.83,0,1.6,0,2.18C570.12,192.63,571.18,192.79,572.23,192.94z M581.61,176.93c-0.29,1.6-1.79,1.24-2.81,1.73
	c0.06,0.7,0.11,1.39,0.18,2.19c0.81-0.1,1.54-0.2,2.11-0.27c0.18-1.36,0.33-2.53,0.5-3.69c0.95,0,1.89,0,2.88,0
	c-0.17-0.98-0.28-1.67-0.39-2.34c-0.75,0.05-1.45,0.1-2.11,0.15C581.85,175.44,581.73,176.19,581.61,176.93z M618.56,318.94
	c-0.11-0.97-0.23-1.95-0.34-2.9c-0.91,0.05-1.65,0.09-2.38,0.13c0.07,0.91,0.13,1.69,0.18,2.3c0.93,0.15,1.75,0.29,2.58,0.43
	c0.56,0.86,1.13,1.72,1.81,2.75c0.37-0.02,1.06-0.06,1.91-0.12c-0.1-0.83-0.2-1.56-0.28-2.17
	C620.73,319.21,619.64,319.08,618.56,318.94z M608.95,240.75c0.25-1.44,1.63-0.94,2.55-1.46c-0.06-0.71-0.12-1.45-0.19-2.24
	c-0.92,0.1-1.68,0.19-2.36,0.26c0,1.27,0,2.36,0,3.46c-1.04,0.12-2.08,0.25-3.11,0.37c0.06,0.95,0.1,1.65,0.14,2.37
	c0.85-0.07,1.62-0.13,2.12-0.17C608.42,242.36,608.68,241.56,608.95,240.75z M328.07,44.38c-1.01,0.27-2.02,0.53-3.06,0.81
	c0.13,0.75,0.26,1.49,0.39,2.22c0.81-0.05,1.5-0.09,2.1-0.13c0.17-1,0.33-1.98,0.5-2.96c0.93-0.36,1.85-0.72,2.74-1.06
	c0-0.73,0-1.44,0-2.16c-0.9,0.07-1.73,0.14-2.36,0.19C328.26,42.4,328.16,43.39,328.07,44.38z M447.23,45.59
	c-1.02-0.03-2.04-0.07-3.09-0.1c0.2,0.97,0.35,1.73,0.49,2.42c0.82,0,1.57,0,2.17,0C446.97,47.05,447.1,46.32,447.23,45.59
	c0.18-1.68,1.66-1.33,2.75-1.74c-0.05-0.66-0.11-1.35-0.18-2.15c-0.75,0.06-1.44,0.11-2.13,0.17
	C447.52,43.14,447.38,44.36,447.23,45.59z M429.99,60.78c-0.12-0.9-0.24-1.81-0.37-2.8c-0.82,0.08-1.48,0.14-2.15,0.2
	c0,0.83,0,1.52,0,2.09c0.92,0.17,1.74,0.32,2.56,0.47c0.56,0.84,1.13,1.69,1.84,2.75c0.3-0.02,0.99-0.06,1.81-0.11
	c-0.08-0.82-0.15-1.54-0.21-2.15C432.16,61.07,431.07,60.93,429.99,60.78z M616.15,406.3c0.29-1.41,1.61-0.98,2.51-1.37
	c0-0.78,0-1.55,0-2.34c-0.87,0.08-1.64,0.14-2.49,0.22c0,1.21,0,2.36,0,3.51c-1.04,0.12-2.09,0.24-3.13,0.36
	c0.05,0.92,0.1,1.61,0.14,2.34c0.87-0.06,1.64-0.11,2.17-0.15C615.63,407.92,615.89,407.11,616.15,406.3z M488.75,62.89
	c0.26,0.95,0.51,1.9,0.77,2.86c0.79-0.05,1.53-0.11,2.3-0.16c-0.11-0.87-0.21-1.63-0.28-2.17c-1.06-0.17-1.96-0.32-2.86-0.46
	c-0.4-0.92-0.79-1.84-1.27-2.94c-0.55,0.09-1.28,0.2-1.93,0.3c0,0.89,0,1.59,0,2.14C486.63,62.61,487.69,62.75,488.75,62.89z
	 M653.52,591.03c0.25-1.59,1.86-1.15,2.77-1.81c-0.03-0.69-0.06-1.38-0.1-2.08c-0.83,0.06-1.58,0.12-2.19,0.16
	c-0.16,1.32-0.3,2.55-0.46,3.76c-0.96,0-1.92,0-2.94,0c0.17,0.99,0.3,1.73,0.43,2.47c0.79-0.08,1.47-0.15,2.06-0.21
	C653.25,592.52,653.38,591.77,653.52,591.03z M652.14,529.63c0.26-1.52,1.84-0.85,2.61-1.5c-0.06-0.8-0.11-1.54-0.16-2.28
	c-0.91,0.1-1.68,0.19-2.44,0.27c0,1.24,0,2.39,0,3.53c-1.04,0.11-2.08,0.22-3.1,0.33c0.05,0.96,0.09,1.66,0.13,2.38
	c0.85-0.06,1.62-0.11,2.16-0.14C651.64,531.27,651.89,530.45,652.14,529.63z M569.48,337.66c1.68,0.19,1.08,1.82,1.67,2.72
	c0.72-0.06,1.39-0.12,2.13-0.18c-0.07-0.84-0.13-1.51-0.19-2.15C571.79,337.91,570.64,337.79,569.48,337.66c0-0.96,0-1.92,0-2.79
	c-1.17-0.12-2.08-0.21-2.99-0.3c-0.17-0.15-0.33-0.3-0.5-0.45c-0.5-1.15-0.47-2.3,0.03-3.44c0,0-0.08,0.09-0.08,0.09
	c1-0.32,2-0.63,3.03-0.96c-0.1-0.7-0.23-1.51-0.33-2.21c-0.97-0.08-1.81-0.16-2.66-0.23c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c0.95-0.28,1.9-0.57,2.68-0.8c0.22-0.96,0.18-2.14,0.74-2.81
	c0.55-0.66,1.72-0.81,2.66-1.2c-0.12-0.63-0.26-1.39-0.4-2.13c-0.96-0.08-1.89-0.16-2.82-0.25c-0.15-0.13-0.3-0.27-0.45-0.4
	c-0.04-1.13-0.08-2.25-0.12-3.38c0.19-0.16,0.37-0.32,0.56-0.48c1,0.08,2.2-0.16,2.93,0.32c0.71,0.46,0.94,1.67,1.41,2.61
	c0.55-0.07,1.24-0.15,1.99-0.24c-0.05-0.79-0.1-1.47-0.15-2.14c-3.14-0.23-3.73-0.83-3.51-3.5c0.08-0.17,0.15-0.34,0.23-0.52
	c0.91-0.42,1.81-0.84,2.76-1.28c-0.04-0.55-0.09-1.17-0.14-1.76c-0.91-0.09-1.77-0.17-2.63-0.25c-0.18-0.19-0.35-0.37-0.53-0.56
	c-0.25-0.89-0.49-1.79-0.74-2.7c-0.76,0.04-1.6,0.09-2.24,0.12c-0.12,1.13-0.23,2.13-0.33,3.12c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.84,0.12-1.69,0.24-2.57,0.36c0,0.88,0,1.75,0,2.61c-0.19,0.16-0.38,0.32-0.57,0.48c-0.97,0.95-2.14,0.54-3.26,0.45
	c-0.37-1.01-0.74-2.01-1.13-3.08c-0.64,0.12-1.38,0.26-2.07,0.38c0,0.85,0,1.55,0,2.32c0.59-0.03,1.13-0.13,1.65-0.08
	c0.54,0.06,1.07,0.26,1.6,0.4c0.25,0.93,0.5,1.87,0.73,2.71c0.94,0.1,1.77,0.18,2.6,0.27c0.15,0.13,0.3,0.27,0.45,0.4
	c0.16,0.98,0.31,1.96,0.47,2.94c-0.19,0.16-0.38,0.32-0.57,0.48c-0.85,0.29-1.69,0.58-2.43,0.83c-0.11,0.97-0.23,1.95-0.34,2.94
	c-0.11,0.15-0.22,0.31-0.33,0.46c-0.47,0.08-0.93,0.21-1.4,0.23c-1.83,0.09-2.47-0.69-2.78-3.32c-0.81,0.14-1.62,0.28-2.4,0.42
	c0.07,0.89,0.14,1.66,0.18,2.14c0.99,0.2,2.05,0.1,2.58,0.58c0.74,0.67,1.08,1.77,1.65,2.8c0.74,0,1.61,0,2.48,0
	c0.17,0.18,0.34,0.36,0.5,0.53c0.36,1.12,0.46,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45c-0.31,0.04-0.63,0.13-0.94,0.1
	c-0.76-0.08-1.77,0.03-2.2-0.41c-0.65-0.67-0.9-1.73-1.33-2.66c-0.62,0.06-1.4,0.14-2.2,0.23c0.07,0.44,0.15,0.82,0.18,1.2
	c0.03,0.44,0.01,0.89,0.01,1.35c2.77-0.35,3.52,0.22,3.78,2.68c0.02,0.23,0.08,0.46,0.12,0.7c-0.07,0.06-0.13,0.12-0.2,0.18
	c-0.98,0.44-1.96,0.88-3.13,1.4c0.05,0.48,0.11,1.14,0.18,1.77c1.01,0.11,1.86,0.2,2.71,0.29c0.15,0.13,0.3,0.27,0.45,0.4
	c0.27,0.93,0.55,1.86,0.79,2.7c0.91,0.09,1.75,0.17,2.59,0.26c0.15,0.13,0.3,0.27,0.45,0.4c0.57,0.93,0.95,2.03,0.2,2.87
	c-0.66,0.73-1.73,1.09-2.75,1.69c0.05,0.51,0.13,1.27,0.2,2.03c0.86-0.04,1.62-0.08,2.37-0.12c-0.16-3.07,0.17-3.48,2.94-3.83
	c0.13-1.04,0.25-2.04,0.38-3.03c0.13-0.18,0.27-0.36,0.4-0.53C567.71,337.59,568.67,336.73,569.48,337.66z M660.72,590.56
	c0.35-1.71,1.88-1.23,3.01-1.6c-0.14-0.74-0.27-1.5-0.41-2.26c-0.78,0.05-1.52,0.1-2.11,0.13c-0.16,1.34-0.3,2.56-0.46,3.77
	c-0.94,0.03-1.88,0.06-2.81,0.1c0.09,0.99,0.15,1.67,0.22,2.36c0.85-0.06,1.59-0.11,2.15-0.15
	C660.45,592.03,660.59,591.29,660.72,590.56z M512.94,57.49c-0.16-1.04-0.32-2.07-0.47-3.08c-0.89,0-1.59,0-2.29,0
	c0,0.87,0,1.69,0,2.6c1.04-0.12,2.1-0.73,2.78,0.47c0.52,0.78,1.04,1.57,1.61,2.44c0.47,0,1.16,0,1.98,0
	c-0.07-0.92-0.13-1.75-0.19-2.44C515.11,57.5,514.02,57.5,512.94,57.49z M481.29,60.4c-1.08,0.09-2.15,0.17-3.26,0.26
	c0.12,0.92,0.23,1.74,0.33,2.5c0.89-0.07,1.68-0.14,2.47-0.2C480.92,62.08,480.13,61.05,481.29,60.4c0.22-1.52,1.74-0.96,2.6-1.52
	c-0.1-0.74-0.19-1.41-0.27-2.02c-0.85,0-1.54,0-2.32,0C481.29,58.09,481.29,59.24,481.29,60.4z M628.33,382.94
	c0.95-0.3,1.9-0.6,2.82-0.88c0-0.79,0-1.49,0-2.26c-0.89,0.06-1.72,0.11-2.43,0.16c-0.12,1.07-0.22,2.06-0.33,3.05
	c-1.06,0.53-2.11,1.15-3.33,0.39c-0.51-0.91-1.01-1.83-1.58-2.86c-0.52,0.04-1.29,0.1-2.06,0.15c0.23,2.81-0.18,3.35-3.01,4.03
	c0,0.77,0,1.53,0,2.3c0.93-0.05,1.78-0.09,2.63-0.13c-0.52-3.07,0.53-4.09,3.61-3.58c0.16,0.03,0.32,0.01,0.47,0.03
	c0.2,0.97,0.4,1.94,0.6,2.95c0.79-0.04,1.56-0.08,2.21-0.11C628.07,385.06,628.2,384,628.33,382.94z M524.48,67.04
	c-0.1-1.02-0.2-2.03-0.3-3.09c-0.95,0.22-1.6,0.38-2.21,0.53c0,0.81,0,1.49,0,2.12c0.92,0.15,1.74,0.28,2.56,0.4
	c1.38,0.52,1.09,1.88,1.31,2.92c0.91-0.12,1.66-0.22,2.42-0.33c-0.1-0.8-0.19-1.49-0.28-2.17
	C526.78,67.29,525.63,67.17,524.48,67.04z M545.38,97.56c-0.21-0.9-0.41-1.79-0.61-2.64c-0.94-0.09-1.85-0.18-2.76-0.27
	c-0.16-0.15-0.33-0.3-0.49-0.44c-0.46-1.12-0.51-2.25-0.01-3.37c0.94-0.31,1.87-0.62,2.9-0.96c-0.14-0.66-0.29-1.4-0.43-2.07
	c-0.84,0-1.57,0-2.11,0c-0.11,1.12-0.2,2.1-0.3,3.09c-0.97,0.27-1.93,0.54-2.88,0.8c-0.07,0.78-0.15,1.64-0.23,2.5
	c-0.11,0.16-0.22,0.31-0.32,0.47c-1.01,0.97-2.21,0.62-3.37,0.49c-0.37-0.94-0.75-1.88-1.16-2.93c-0.7,0-1.49,0-2.27,0
	c-0.15-0.2-0.3-0.39-0.46-0.59c-0.87-0.92-1.08-1.96-0.37-3.03c0.77-1.15,1.87-1.37,3.15-0.9c0.22,0.08,0.47,0.07,0.7,0.11
	c0.24,0.97,0.48,1.94,0.72,2.94c0.73-0.07,1.42-0.14,2.12-0.21c-0.05-0.9-0.09-1.68-0.12-2.38c-0.98-0.1-1.89-0.19-2.8-0.28
	c-0.47-0.93-0.93-1.85-1.44-2.87c-0.74,0-1.66,0-2.58,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.18-0.87-0.36-1.75-0.51-2.44
	c-1.02,0-1.94,0-2.85,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.08-1.06-0.17-2.12-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.18
	c0.99-0.23,1.98-0.45,2.96-0.68c-0.12-0.79-0.23-1.47-0.35-2.25c-0.86,0-1.65,0-2.44,0c-0.15-0.19-0.3-0.39-0.45-0.58
	c-0.36-0.84-0.72-1.69-1.09-2.55c-0.84-0.06-1.77-0.14-2.7-0.21c-0.14-0.13-0.29-0.26-0.43-0.38c-0.14-0.98-0.28-1.95-0.4-2.82
	c-0.99-0.05-2-0.1-3-0.15c-0.14-0.13-0.29-0.26-0.43-0.38c-0.03-1.16-0.05-2.32-0.08-3.48c0.96-0.29,1.91-0.59,2.82-0.87
	c0-0.8,0-1.51,0-2.26c-0.88,0.04-1.72,0.08-2.39,0.11c-0.14,1.09-0.26,2.08-0.38,3.07c-1.08,0.56-2.17,0.99-3.39,0.43
	c0,0,0.07,0.06,0.07,0.06c-0.5-1-1-1.99-1.52-3.02c-0.55,0.07-1.21,0.16-1.91,0.26c0,1.04,0,1.9,0,2.75
	c-0.15,0.16-0.3,0.32-0.46,0.49c-0.95,0.23-1.91,0.45-2.89,0.69c0,0.95,0,1.8,0,2.66c-0.16,0.17-0.31,0.33-0.47,0.5
	c-0.9,0.22-1.81,0.44-2.79,0.68c0.04,0.75,0.08,1.52,0.12,2.23c0.97,0,1.84,0,2.71,0c0.31,0.21,0.61,0.43,0.92,0.64
	c0.04,0.99,0.09,1.98,0.13,2.87c0.99,0,1.89,0,2.8,0c0.15,0.09,0.3,0.18,0.46,0.27c0.39,0.89,0.78,1.78,1.24,2.82
	c0.73,0,1.65,0,2.56,0c0.17,0.18,0.34,0.36,0.51,0.53c0.36,1.12,0.46,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45
	c-2.61,0.37-3.46,0.09-3.97-1.41c-0.17-0.5-0.17-1.06-0.25-1.57c-0.88,0.08-1.66,0.15-2.46,0.23c0.07,0.44,0.16,0.82,0.19,1.2
	c0.03,0.43,0.01,0.86,0.01,1.31c2.85-0.29,3.57,0.26,3.82,2.71c0.02,0.24,0.06,0.47,0.09,0.7c-0.16,0.07-0.32,0.15-0.49,0.22
	c-0.88,0.44-1.76,0.88-2.85,1.42c0.04,0.43,0.11,1.09,0.17,1.71c1.02,0.11,1.87,0.2,2.72,0.29c0.16,0.14,0.31,0.28,0.47,0.42
	c0.15,0.97,0.31,1.94,0.46,2.92c-0.2,0.17-0.4,0.34-0.6,0.51c-0.97,1-2.14,0.5-3.25,0.44c-0.37-0.94-0.75-1.87-1.17-2.94
	c-0.69,0-1.47,0-2.26,0c-0.16-0.2-0.32-0.4-0.47-0.6c-0.72-0.75-0.97-1.58-0.59-2.6c0.53-1.43,1.86-1.5,3.07-1.72
	c-0.05-0.85-0.09-1.54-0.14-2.28c-1.01,0-1.94,0-2.87,0c-0.2-0.3-0.4-0.6-0.6-0.91c-0.17-0.83-0.33-1.66-0.47-2.33
	c-0.97-0.12-1.82-0.22-2.67-0.32c-0.12-0.09-0.24-0.16-0.37-0.24c-0.32-0.9-0.65-1.81-0.96-2.7c-0.76,0.04-1.51,0.08-2.1,0.11
	c-0.12,1.05-0.24,2.02-0.35,3c-0.13,0.17-0.25,0.34-0.38,0.5c-1,0.01-2,0.02-2.99,0.03c-0.17-0.18-0.35-0.37-0.52-0.55
	c-0.24-0.88-0.47-1.75-0.7-2.62c-0.81-0.06-1.72-0.13-2.62-0.19c-0.17-0.18-0.34-0.36-0.5-0.54c-0.14-0.97-0.29-1.94-0.43-2.91
	c0.14-0.13,0.28-0.25,0.42-0.38c0.88-0.29,1.77-0.59,2.68-0.89c-0.07-0.71-0.16-1.55-0.22-2.18c-1.09-0.13-2.02-0.23-2.94-0.34
	c0,0,0.09,0.08,0.09,0.08c-0.44-0.97-0.88-1.94-1.35-2.97c-0.68,0.07-1.35,0.14-2.08,0.21c0,1,0,1.87,0,2.73
	c-0.23,0.31-0.45,0.62-0.68,0.93c-0.81,0-1.62,0-2.13,0c-0.28,1.21-0.49,2.07-0.69,2.93c-0.1,0.09-0.2,0.19-0.3,0.3
	c-1.04,0.2-2.08,0.4-3.12,0.6c-0.16-0.31-0.32-0.61-0.48-0.92c-0.1-0.96-0.21-1.92-0.31-2.88c0.17-0.14,0.33-0.28,0.5-0.43
	c0.89-0.26,1.79-0.52,2.7-0.79c-0.1-0.73-0.2-1.41-0.28-1.98c-1-0.25-2.1-0.23-2.73-0.76c-0.69-0.6-0.95-1.7-1.43-2.64
	c-0.61,0.03-1.37,0.07-2.03,0.1c-0.07,0.94-0.13,1.81-0.19,2.67c-0.12,0.17-0.25,0.34-0.37,0.51c-0.94,0.28-1.88,0.57-2.69,0.81
	c-0.17,0.88-0.09,2.06-0.63,2.77c-0.53,0.69-1.69,0.91-2.46,1.28c-0.13,0.87-0.27,1.77-0.41,2.68c-0.14,0.18-0.28,0.36-0.42,0.54
	c-0.99-0.03-2.2,0.28-2.9-0.19c-0.76-0.51-1.05-1.74-1.56-2.7c-0.77,0-1.63,0-2.49,0c-0.19-0.3-0.38-0.61-0.56-0.91
	c-0.17-0.9-0.33-1.8-0.5-2.7c-0.83,0.16-1.65,0.31-2.22,0.42c-0.16,1.03-0.28,1.87-0.41,2.7c-0.17,0.17-0.34,0.34-0.51,0.52
	c-1.14,0.77-2.27,0.42-3.39-0.05l0.09,0.08c-0.26-0.97-0.52-1.94-0.79-2.95c-0.7,0.11-1.53,0.23-2.23,0.34
	c-0.13,1.04-0.25,2.02-0.37,3.01c-0.13,0.17-0.25,0.34-0.38,0.5c-0.92,0.11-1.84,0.23-2.64,0.32c-0.09,1.03-0.18,2.02-0.27,3.01
	c-0.18,0.15-0.36,0.3-0.55,0.44c-0.88,0.14-1.77,0.28-2.53,0.41c-0.14,1.02-0.28,2.05-0.41,3.08c0,0,0.08-0.09,0.08-0.09
	c-1,0.34-2,0.67-3.04,1.02c0.11,0.66,0.24,1.43,0.37,2.16c0.93,0.1,1.79,0.19,2.65,0.29c0.16,0.14,0.32,0.28,0.48,0.42
	c0.27,0.93,0.55,1.86,0.79,2.69c0.9,0.1,1.74,0.19,2.58,0.27c0.15,0.13,0.29,0.26,0.44,0.39c0.59,0.93,0.96,2.05,0.2,2.88
	c-0.67,0.73-1.76,1.07-2.79,1.66c0.04,0.52,0.11,1.33,0.18,2.19c0.41-0.09,0.72-0.19,1.03-0.21c0.47-0.03,0.94-0.01,1.48-0.01
	c-0.04-0.32-0.1-0.62-0.11-0.93c-0.04-2.03,0.66-2.78,2.62-2.83c0.4-0.01,0.79,0.03,1.19,0.05c0.15,0.18,0.31,0.36,0.46,0.54
	c0.19,0.83,0.39,1.67,0.6,2.59c0.73-0.11,1.47-0.22,2.13-0.31c0-0.88,0-1.58,0-2.28c0.23-0.31,0.45-0.62,0.68-0.93
	c1.08-0.67,2.19-0.42,3.31-0.12c0.15,0.19,0.3,0.38,0.45,0.57c-0.06,0.97-0.11,1.95-0.17,2.92c-0.06,0.06-0.13,0.12-0.19,0.19
	c-0.9,0.36-1.79,0.71-2.65,1.05c0.04,0.77,0.08,1.52,0.12,2.27c0.93-0.05,1.85-0.1,2.78-0.15c0.16,0.31,0.31,0.61,0.47,0.92
	c0.25,0.86,0.5,1.72,0.7,2.4c0.9,0.16,2.04,0.04,2.63,0.56c0.74,0.65,1.03,1.81,1.55,2.81c0.59-0.08,1.29-0.17,1.96-0.26
	c0.09-0.93,0.17-1.78,0.25-2.64c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.17,2.22-0.35,3.32-0.52c0.17,0.18,0.34,0.36,0.51,0.54
	c0.16,0.95,0.32,1.9,0.45,2.75c1.06,0.05,2.06,0.11,3.07,0.16c0.1,0.06,0.2,0.12,0.29,0.18c0.15,0.99,0.31,1.97,0.47,3.02
	c0.76-0.17,1.57-0.35,2.41-0.54c0-0.95,0-1.82,0-2.68c0.17-0.18,0.35-0.35,0.52-0.53c1.04-0.14,2.09-0.28,3.13-0.42
	c0.17,0.3,0.34,0.61,0.5,0.91c0.53,0.75,0.93,1.69,0.28,2.38c-0.78,0.83-1.86,1.38-2.89,2.11c0,0.46,0,1.16,0,1.69
	c1.13,0.19,2.17,0.37,3.2,0.54c0.18,0.08,0.35,0.16,0.53,0.25c0.05,0.94,0.1,1.87,0.13,2.6c1.17,0.21,2.23,0.39,3.3,0.58
	c0,0-0.1-0.08-0.1-0.08c0.29,0.93,0.57,1.87,0.84,2.73c0.92,0.1,1.76,0.19,2.59,0.28c0.14,0.13,0.29,0.26,0.43,0.38
	c1.11,2.78,0.5,3.77-2.49,4.17c-0.11,1.03-0.21,2.02-0.32,3.01c-0.17,0.13-0.33,0.25-0.5,0.38c-2.87,0.69-3.55,0.24-4.17-2.83
	c-0.74,0.06-1.49,0.12-2.28,0.18c0.11,0.86,0.19,1.54,0.25,2.07c1.03,0.28,2.13,0.27,2.74,0.81c0.68,0.6,0.91,1.69,1.41,2.7
	c0.74,0,1.61,0,2.47,0c0.17,0.18,0.34,0.36,0.52,0.54c0.42,1.14,0.46,2.27-0.05,3.4c0,0,0.08-0.09,0.08-0.09
	c-0.99,0.34-1.97,0.69-2.99,1.04c0.11,0.67,0.24,1.42,0.36,2.14c0.93,0.11,1.77,0.21,2.62,0.3c0.15,0.13,0.3,0.27,0.45,0.4
	c0.28,0.93,0.56,1.86,0.81,2.7c0.89,0.09,1.74,0.17,2.58,0.26c0.15,0.13,0.3,0.27,0.45,0.4c0.27,0.88,0.55,1.75,0.73,2.33
	c1.13,0.2,2.12,0.37,3.11,0.54c0.16,0.07,0.31,0.14,0.47,0.21c0,0.99,0,1.98,0,3.04c1-0.19,1.82-0.34,2.68-0.5c0-0.78,0-1.53,0-2.28
	c0.23-0.31,0.45-0.62,0.68-0.93c1.08-0.67,2.19-0.42,3.31-0.12c0.15,0.19,0.3,0.38,0.45,0.57c-0.06,0.97-0.11,1.95-0.17,2.92
	c-0.06,0.06-0.13,0.12-0.19,0.19c-0.85,0.34-1.7,0.68-2.53,1.01c-0.1,0.79-0.22,1.68-0.34,2.58c-0.18,0.18-0.35,0.36-0.53,0.54
	c-0.88,0.24-1.76,0.47-2.59,0.69c0.08,0.81,0.16,1.57,0.23,2.32c0.94-0.05,1.8-0.09,2.66-0.13c0.16,0.31,0.32,0.61,0.47,0.92
	c0.57,0.76,0.95,1.7,0.29,2.41c-0.74,0.8-1.8,1.31-2.88,2.05c0.03,0.47,0.07,1.22,0.1,1.73c1.16,0.2,2.16,0.37,3.15,0.54
	c0.16,0.07,0.33,0.15,0.49,0.22c0,0.94,0,1.88,0,2.59c1.29,0.22,2.36,0.41,3.43,0.6c0,0-0.1-0.08-0.09-0.08
	c0.29,0.93,0.57,1.87,0.84,2.73c0.92,0.09,1.75,0.18,2.59,0.27c0.15,0.13,0.3,0.27,0.45,0.4c0.58,0.94,0.96,2.06,0.18,2.89
	c-0.68,0.73-1.77,1.08-2.77,1.64c0.04,0.53,0.11,1.3,0.18,2.12c0.83-0.06,1.66-0.11,2.49-0.17c-0.26-3.05,0.1-3.49,2.92-3.87
	c0.12-1.05,0.24-2.06,0.36-3.07c0.11-0.16,0.23-0.32,0.34-0.48c1.01,0.06,2.24-0.22,2.96,0.26c0.75,0.51,1.01,1.76,1.5,2.73
	c0.65-0.06,1.42-0.13,2.22-0.2c-0.12-0.83-0.23-1.58-0.34-2.31c-2.91-0.09-3.29-0.41-3.87-3.16c-0.94-0.08-1.87-0.16-2.81-0.24
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.42-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09c1.11-0.5,2.21-1.08,3.43-0.3
	c0.09,0.08,0.18,0.16,0.28,0.23c0.33,0.86,0.66,1.71,1.01,2.61c0.68-0.07,1.52-0.16,2.08-0.21c0.16-0.99,0.03-2.04,0.49-2.58
	c0.65-0.75,1.74-1.12,2.77-1.72c-0.03-0.48-0.07-1.17-0.11-1.93c-1,0-1.93,0-2.86,0c-0.19-0.3-0.37-0.61-0.56-0.91
	c-0.17-0.75-0.35-1.5-0.51-2.18c-0.89-0.08-1.75-0.16-2.6-0.24c-0.18-0.19-0.36-0.38-0.54-0.57c-1.03-2.75-0.27-3.88,2.6-3.87
	c0.24,0,0.48-0.02,0.71-0.03c0.18,0.2,0.36,0.4,0.53,0.61c0.19,0.82,0.39,1.65,0.6,2.53c0.78-0.08,1.52-0.16,2.34-0.25
	c0-1.06,0-2,0-2.94c0.06-0.09,0.12-0.18,0.18-0.27c0.96-0.22,1.93-0.45,2.92-0.68c0-0.75,0-1.5,0-2.25
	c0.24-0.31,0.47-0.62,0.71-0.94c1.13-0.76,2.26-0.32,3.4-0.02c0.25,0.93,0.5,1.86,0.73,2.71c0.94,0.08,1.77,0.16,2.6,0.23
	c0.16,0.15,0.33,0.3,0.49,0.45c0.55,0.95,0.77,1.92,0.2,2.94c-0.69,1.22-1.92,1.12-3.12,1.13c0.23,0.95,0.42,1.76,0.62,2.58
	c0.98,0,1.85,0,2.72,0c0.17,0.18,0.35,0.37,0.52,0.55c0.36,1.12,0.46,2.23,0,3.35c-0.11,0.15-0.22,0.31-0.32,0.46
	c-0.92,0.1-1.84,0.2-2.61,0.28c-0.16,1.06-0.31,2.11-0.47,3.16c0,0,0.08-0.09,0.08-0.09c-0.99,0.33-1.97,0.67-3.05,1.03
	c0.11,0.62,0.25,1.43,0.37,2.15c0.98,0.1,1.82,0.19,2.66,0.28c0.16,0.14,0.32,0.28,0.48,0.42c0.61,0.91,0.69,1.88,0.33,2.9
	c-0.12,0.17-0.24,0.33-0.36,0.5c-0.99,0.29-1.98,0.57-2.96,0.86c0.04,0.79,0.09,1.85,0.14,2.91c-0.17,0.16-0.35,0.32-0.52,0.48
	c-0.89,0.27-1.78,0.53-2.66,0.8c0.11,0.72,0.21,1.4,0.28,1.9c1.06,0.28,2.15,0.27,2.77,0.81c0.68,0.59,0.92,1.69,1.41,2.68
	c0.75,0,1.62,0,2.48,0c0.18,0.18,0.35,0.37,0.53,0.55c0.36,1.1,0.5,2.21,0,3.31c-0.12,0.16-0.24,0.33-0.37,0.49
	c-0.82,0.13-1.64,0.27-2.42,0.4c-0.08,0.88-0.15,1.71-0.22,2.54c-0.13,0.18-0.26,0.35-0.39,0.53c-1.04,0.91-2.2,0.67-3.38,0.37
	c0,0,0.09,0.08,0.09,0.08c-0.41-1.04-0.83-2.08-1.24-3.13c-0.66,0.13-1.39,0.28-2.15,0.43c0,0.96,0,1.81,0,2.66
	c-0.25,0.31-0.5,0.63-0.75,0.94c-0.84,0-1.69,0-2.54,0c0,0.98,0,1.75,0,2.35c1.13,0.19,2.17,0.36,3.21,0.54
	c0.18,0.09,0.35,0.17,0.53,0.26c0.04,0.98,0.09,1.97,0.12,2.82c1,0,1.9,0,2.81,0c0.16,0.11,0.33,0.22,0.49,0.33
	c0.39,0.91,0.79,1.81,1.2,2.74c0.58-0.06,1.19-0.13,1.72-0.18c0.14-1.04,0.27-2.03,0.39-3.02c0.11-0.16,0.23-0.32,0.34-0.48
	c1.02,0.01,2.03,0.03,3.05,0.04c0.15,0.14,0.31,0.27,0.46,0.41c0.58,0.93,0.95,2.05,0.19,2.88c-0.66,0.73-1.75,1.08-2.77,1.67
	c0.04,0.49,0.11,1.26,0.17,1.98c0.85,0,1.62,0,2.47,0c-0.04-0.36-0.09-0.67-0.09-0.98c-0.02-2.13,0.66-2.79,2.86-2.8
	c0.32,0,0.63,0.01,0.95,0.01c0.16,0.19,0.33,0.38,0.49,0.57c0.2,0.83,0.4,1.67,0.58,2.43c0.9,0,1.76,0,2.62,0
	c0.16,0.31,0.32,0.61,0.48,0.92c0.24,0.85,0.48,1.7,0.74,2.58c0.78-0.04,1.7-0.08,2.62-0.13c0.17,0.3,0.33,0.61,0.5,0.91
	c0.24,0.86,0.48,1.73,0.66,2.36c0.99,0.23,2.16,0.2,2.83,0.75c0.68,0.57,0.89,1.72,1.32,2.67c0.61-0.08,1.3-0.18,1.98-0.27
	c0.08-0.92,0.16-1.78,0.23-2.63c0.13-0.17,0.26-0.35,0.39-0.52c0.94-0.28,1.89-0.55,2.78-0.81c0.07-0.81,0.13-1.66,0.2-2.51
	c0.13-0.17,0.25-0.34,0.38-0.5c1.04-0.26,2.08-0.53,2.98-0.76c-0.06-0.95-0.13-1.97-0.2-2.98c0.18-0.16,0.37-0.33,0.55-0.49
	c0.89-0.25,1.79-0.5,2.68-0.76c-0.13-0.7-0.26-1.37-0.35-1.85c-1.04-0.11-1.89-0.2-2.75-0.29c-0.17-0.16-0.35-0.31-0.52-0.47
	c-0.16-0.9-0.32-1.8-0.48-2.68c-0.87-0.07-1.86-0.14-2.85-0.21c-0.17-0.15-0.34-0.31-0.51-0.46c-0.5-1.15-0.47-2.29,0.03-3.44
	l-0.08,0.09c0.94-0.32,1.88-0.64,2.83-0.96c0.05-0.76,0.11-1.61,0.16-2.46c0.15-0.19,0.29-0.37,0.44-0.56
	c1.02-0.24,2.03-0.48,3.05-0.72c-0.15-0.76-0.31-1.58-0.45-2.31c-0.92-0.08-1.77-0.15-2.62-0.22c-0.18-0.19-0.36-0.38-0.55-0.57
	c-0.11-0.94-0.21-1.88-0.32-2.83c0.13-0.17,0.25-0.33,0.38-0.5c0.88-0.27,1.76-0.55,2.55-0.79c0.07-0.95,0.14-1.94,0.21-2.94
	c0.19-0.16,0.37-0.32,0.56-0.48c0.99,0.07,2.24-0.2,2.91,0.31c0.73,0.55,0.93,1.82,1.39,2.81c0.64-0.14,1.45-0.32,2.19-0.48
	c0-0.84,0-1.54,0-2.25c0.24-0.31,0.49-0.63,0.73-0.94c0.89-0.22,1.77-0.43,2.63-0.64c0-0.73,0-1.49,0-2.26
	c0.24-0.31,0.48-0.63,0.72-0.94c1.11-0.71,2.21-0.47,3.32,0.02c0.27,0.88,0.55,1.76,0.82,2.64c0.8,0,1.6,0,2.37,0
	c-0.07-0.95-0.14-1.77-0.2-2.57c-1.05,0-2.05,0-3.05,0c-0.47-1.3-1.38-2.6-0.11-3.95c0.06-0.06,0.13-0.12,0.2-0.18
	c0.78-0.25,1.56-0.49,2.38-0.75c-0.06-0.69-0.11-1.28-0.17-1.94c-1.05,0-1.97,0-2.88,0c-0.16-0.31-0.32-0.61-0.49-0.92
	c-0.15-0.84-0.31-1.69-0.43-2.35c-1.08-0.18-2.13-0.35-3.18-0.52c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.06-0.17-2.11-0.25-3.17
	c0.06-0.06,0.13-0.12,0.19-0.18c0.97-0.22,1.95-0.44,2.9-0.66c-0.05-0.83-0.1-1.59-0.14-2.27c-0.88,0-1.74,0-2.59,0
	c-0.14-0.19-0.29-0.37-0.43-0.56c-0.75-0.81-0.94-1.74-0.64-2.79c0.22-0.31,0.45-0.62,0.67-0.93c0.9-0.22,1.8-0.45,2.66-0.66
	c-0.11-0.74-0.22-1.44-0.34-2.25c-0.8,0-1.59,0-2.38,0c-0.16-0.2-0.32-0.41-0.48-0.61c-1.41-2.53-0.85-3.61,2.41-4.38
	c-0.07-0.71-0.15-1.44-0.22-2.11c-0.87,0-1.57,0-2.28,0c0.19,3.05-0.53,3.77-3.59,3.71c-0.08,0-0.16,0.02-0.24,0.03
	c-0.1-0.07-0.19-0.15-0.28-0.23c-0.28-0.99-0.55-1.99-0.79-2.82c-0.94-0.1-1.77-0.19-2.59-0.27c-0.18-0.19-0.36-0.38-0.54-0.57
	c-0.24-0.87-0.47-1.74-0.71-2.6c-0.8-0.06-1.71-0.12-2.61-0.19c-0.17-0.18-0.34-0.36-0.51-0.54c-0.25-0.79-0.51-1.58-0.76-2.36
	c-0.93,0-1.85,0-2.78,0c-0.16-0.3-0.32-0.61-0.49-0.91c-0.08-1.05-0.17-2.1-0.25-3.15c0.06-0.07,0.13-0.14,0.19-0.21
	c1.02-0.23,2.05-0.46,3.07-0.69c-0.19-0.8-0.34-1.46-0.5-2.11c-0.82-0.13-1.58-0.25-2.33-0.37c-0.16-0.07-0.33-0.15-0.49-0.22
	c-0.38-0.92-0.77-1.84-1.18-2.82c-0.63-0.09-1.4-0.21-2.17-0.32c-0.15-0.07-0.31-0.14-0.46-0.21c-0.67-0.82-1.09-1.68-0.63-2.79
	c0.56-1.35,1.83-1.49,3-1.65c0-1.04,0-1.89,0-2.74c0.18-0.18,0.35-0.36,0.53-0.54c0.89-0.2,1.79-0.4,2.77-0.61
	c-0.07-0.75-0.13-1.49-0.2-2.25c-1,0-1.86,0-2.72,0c-0.2-0.3-0.4-0.61-0.6-0.91c-0.17-0.83-0.35-1.66-0.51-2.45
	c-0.95,0-1.88,0-2.81,0c-0.16-0.31-0.32-0.61-0.48-0.92c-0.09-0.97-0.18-1.93-0.27-2.9c0.15-0.13,0.3-0.26,0.46-0.39
	c0.8-0.25,1.6-0.5,2.54-0.8c-0.06-0.68-0.13-1.4-0.18-1.93c-1.28-0.29-2.32-0.53-3.36-0.76L545.38,97.56z M669.41,603.98
	c0.33-1.63,1.95-0.84,2.79-1.38c-0.14-0.79-0.25-1.48-0.39-2.25c-0.79,0.05-1.55,0.09-2.39,0.14c0,1.22,0,2.37-0.01,3.51
	c-1.04,0.13-2.07,0.27-3.11,0.4c0.06,0.93,0.11,1.63,0.15,2.34c0.85-0.07,1.63-0.12,2.11-0.16
	C668.89,605.6,669.15,604.79,669.41,603.98z M542.26,354.46c-0.11-0.99-0.22-1.97-0.34-3c-0.86,0.18-1.53,0.32-2.19,0.45
	c0,0.79,0,1.48,0,2c0.93,0.19,1.74,0.36,2.56,0.52c1.53,0.36,1.02,1.87,1.45,2.82c0.87-0.07,1.62-0.14,2.36-0.2
	c-0.13-0.82-0.24-1.51-0.34-2.18C544.56,354.73,543.41,354.6,542.26,354.46z M431.4,35.37c-0.1-1.02-0.21-2.03-0.32-3.1
	c-0.96,0.23-1.61,0.39-2.19,0.53c0,0.84,0,1.52,0,2.09c0.92,0.16,1.73,0.31,2.55,0.45c1.5,0.44,1.01,1.9,1.41,2.87
	c0.83-0.1,1.6-0.18,2.36-0.27c-0.11-0.8-0.21-1.49-0.3-2.18C433.71,35.63,432.55,35.5,431.4,35.37z M625.67,358.33
	c1.53,0.26,0.78,1.84,1.46,2.62c0.72-0.12,1.4-0.24,2.08-0.36c0-0.83,0-1.59,0-2.26C627.96,358.33,626.81,358.33,625.67,358.33
	c-0.15-1.06-0.3-2.11-0.45-3.2c-0.94,0.06-1.7,0.12-2.41,0.16c0.06,0.91,0.11,1.67,0.16,2.45
	C623.87,357.95,625.03,356.96,625.67,358.33z M601.21,244.59c-0.06-0.98-0.12-1.96-0.17-2.9c-0.9,0-1.67,0-2.45,0
	c0.06,0.87,0.11,1.66,0.16,2.39c0.85,0.17,1.68,0.33,2.5,0.49c1.52,0.4,1.05,1.86,1.45,2.82c0.83-0.08,1.6-0.15,2.4-0.23
	c-0.12-0.81-0.22-1.54-0.32-2.25C603.5,244.8,602.35,244.7,601.21,244.59z M620.55,502.8c1.03-0.21,2.07-0.43,3.14-0.65
	c-0.12-0.78-0.24-1.59-0.35-2.39c-0.89,0.05-1.65,0.09-2.19,0.12c-0.19,1.07-0.36,2.03-0.52,3c-0.89,0.42-1.78,0.83-2.69,1.26
	c0,0.62,0,1.31,0,2.08c0.88-0.05,1.7-0.09,2.38-0.13C620.39,504.92,620.47,503.86,620.55,502.8z M555,118.55
	c0.24,0.96,0.48,1.93,0.72,2.88c0.84-0.05,1.59-0.1,2.33-0.14c-0.12-0.87-0.22-1.65-0.3-2.29c-0.98-0.13-1.89-0.26-2.8-0.38
	c-0.41-1.04-0.83-2.08-1.24-3.11c-0.7,0.16-1.42,0.33-2.14,0.49c0.05,0.89,0.1,1.65,0.13,2.19
	C552.89,118.31,553.95,118.43,555,118.55z M608.26,359.59c-0.95,0.31-1.9,0.62-2.89,0.95c0.04,0.72,0.09,1.42,0.13,2.14
	c0.89,0,1.71,0,2.31,0c0.14-1.15,0.26-2.15,0.38-3.15c0.94-0.33,1.87-0.66,2.86-1.01c-0.05-0.74-0.1-1.49-0.14-2.24
	c-0.94,0.06-1.76,0.12-2.31,0.16C608.47,357.61,608.36,358.6,608.26,359.59z M597.49,237.45c0.96-0.21,1.93-0.43,2.91-0.65
	c0-0.82,0-1.58,0-2.3c-0.9,0-1.73,0-2.31,0c-0.2,1.11-0.37,2.06-0.53,3.03c-0.88,0.41-1.77,0.83-2.65,1.24
	c0.04,0.68,0.09,1.36,0.13,1.99c0.85,0,1.65,0,2.29,0C597.39,239.56,597.44,238.5,597.49,237.45z M618,514.71
	c0.42-1.64,2-1.15,3.06-1.57c-0.18-0.82-0.34-1.57-0.5-2.3c-0.79,0.06-1.48,0.12-2.1,0.16c-0.14,1.27-0.28,2.52-0.43,3.75
	c-0.88,0.04-1.76,0.09-2.72,0.13c0.05,0.95,0.08,1.62,0.12,2.33c0.88-0.04,1.62-0.08,2.2-0.11
	C617.77,516.22,617.88,515.47,618,514.71z M490.89,466.81c-0.36,1.53-1.8,0.91-2.71,1.31c0.04,0.8,0.08,1.57,0.12,2.41
	c0.86-0.12,1.66-0.23,2.36-0.32c0.07-1.26,0.14-2.34,0.21-3.42c0.99-0.14,1.98-0.28,3.02-0.43c-0.08-0.79-0.14-1.47-0.22-2.24
	c-0.86,0.06-1.61,0.1-1.91,0.12C491.41,465.3,491.15,466.05,490.89,466.81z M277.34,88.11c1.05-0.15,2.09-0.3,3.16-0.46
	c-0.06-0.9-0.12-1.66-0.17-2.43c-0.85,0.11-1.61,0.2-2.1,0.26c-0.32,0.99-0.58,1.83-0.83,2.68c-0.83,0.5-1.66,1.01-2.61,1.58
	c0.04,0.54,0.08,1.23,0.13,1.9c0.93,0,1.72,0,2.43,0C277.34,90.43,277.34,89.27,277.34,88.11z M402.39,27.04
	c-1.09,0.44-2.15,1.07-3.35,0.33c-0.5-0.93-1-1.86-1.53-2.86c-0.6,0.05-1.38,0.11-2.23,0.17c0.84,2.73-0.73,3.66-2.96,3.96
	c0.09,0.8,0.15,1.42,0.22,2.08c0.83,0,1.6,0,2.37,0c-0.22-3.11,0.73-3.97,3.71-3.46c0.16,0.03,0.32,0.03,0.47,0.04
	c0.21,0.97,0.42,1.93,0.63,2.93c0.85-0.05,1.66-0.09,2.5-0.14c-0.1-0.52-0.26-0.9-0.23-1.27c0.05-0.61,0.21-1.22,0.33-1.83
	c0.94-0.32,1.88-0.64,2.8-0.94c0-0.77,0-1.47,0-2.21c-0.88,0.05-1.71,0.1-2.37,0.14C402.62,25.08,402.51,26.06,402.39,27.04z
	 M602.81,215.4c1.01-0.24,2.02-0.49,3.05-0.74c-0.12-0.78-0.24-1.53-0.34-2.22c-0.84,0-1.59,0-2.15,0c-0.18,1.1-0.34,2.06-0.49,3.03
	c-0.91,0.39-1.83,0.78-2.82,1.2c0.04,0.65,0.09,1.41,0.14,2.16c0.89-0.07,1.66-0.13,2.36-0.18
	C602.65,217.54,602.73,216.47,602.81,215.4z M564.11,265.99c-0.44-0.97-0.89-1.95-1.34-2.94c-0.72,0-1.44,0-2.15,0
	c-0.16-0.21-0.33-0.41-0.49-0.62c-1.4-2.54-0.82-3.63,2.42-4.36c-0.03-0.74-0.07-1.51-0.11-2.28c-0.89,0.06-1.68,0.12-2.27,0.16
	c-0.2,1.02-0.11,2.11-0.61,2.73c-0.57,0.7-1.67,0.98-2.7,1.52c0,0.66,0,1.52,0,2.39c-0.23,0.31-0.47,0.63-0.7,0.94
	c-3.21,0-3.54-0.25-4.21-3.21c-0.73,0.07-1.46,0.13-2.25,0.21c0.08,0.86,0.15,1.62,0.21,2.36c3.08,0.05,3.37,0.31,3.82,3.07
	c1.06,0.12,2.06,0.23,3.06,0.34c0.16,0.2,0.31,0.39,0.47,0.59c-0.06,0.97-0.12,1.95-0.18,2.92c-0.06,0.06-0.13,0.12-0.19,0.18
	c-0.91,0.36-1.81,0.71-2.76,1.09c0.12,0.65,0.27,1.4,0.4,2.09c0.93,0,1.77,0,2.62,0c0.08,0.18,0.16,0.36,0.25,0.53
	c0.39,0.9,0.78,1.8,1.23,2.84c0.59-0.05,1.33-0.11,2.07-0.17c0-1.03,0-1.89,0-2.74c0.17-0.17,0.34-0.34,0.51-0.52
	c2.98-0.79,3.68-0.36,4.45,2.82c0.75-0.04,1.52-0.08,2.29-0.12c-0.06-0.9-0.12-1.69-0.18-2.48c-3.25,0.11-3.82-0.46-3.69-3.6
	c0.16-0.15,0.33-0.3,0.49-0.44c0.85-0.31,1.7-0.62,2.55-0.94c-0.07-0.76-0.14-1.52-0.19-2.09c-1.07-0.13-1.99-0.24-2.91-0.35
	C564.02,265.91,564.11,265.99,564.11,265.99z M555.92,338.89c-0.39-0.93-0.78-1.87-1.21-2.92c-0.64,0.03-1.33,0.07-2.04,0.11
	c0,0.92,0,1.7,0,2.31c1.17,0.16,2.24,0.31,3.31,0.45c0.22,0.97,0.44,1.94,0.67,2.95c0.82-0.16,1.63-0.32,2.34-0.46
	c0-0.88,0-1.63,0-2.44C557.93,338.89,556.92,338.89,555.92,338.89z M638.74,504.7c1.03-0.21,2.05-0.41,3.07-0.62
	c-0.05-0.88-0.09-1.65-0.13-2.43c-0.91,0.05-1.74,0.1-2.31,0.13c-0.21,1.09-0.4,2.04-0.57,3c-0.9,0.38-1.81,0.77-2.83,1.2
	c0.07,0.51,0.17,1.28,0.28,2.07c0.84,0,1.66,0,2.48,0C638.74,506.85,638.74,505.77,638.74,504.7z M608.26,316.37
	c-0.42-0.96-0.83-1.92-1.28-2.95c-0.71,0-1.47,0-2.23,0c-0.15-0.19-0.29-0.38-0.44-0.57c-0.84-0.93-1.09-1.96-0.38-3.04
	c0.77-1.16,1.88-1.36,3.15-0.91c0.22,0.08,0.47,0.07,0.71,0.1c0.23,0.97,0.47,1.94,0.71,2.96c0.72-0.07,1.4-0.14,2.11-0.21
	c-0.05-0.9-0.09-1.67-0.12-2.35c-0.97-0.11-1.87-0.22-2.78-0.32c-0.48-0.93-0.96-1.87-1.48-2.89c-0.61,0.05-1.38,0.1-1.89,0.14
	c-0.21,1.04-0.14,2.13-0.64,2.74c-0.57,0.69-1.65,0.96-2.68,1.51c0,0.66,0,1.52,0,2.38c-0.17,0.18-0.34,0.37-0.52,0.55
	c-0.9,0.21-1.8,0.43-2.75,0.65c0.05,0.71,0.1,1.46,0.14,2.05c1.1,0.21,2.08,0.39,3.06,0.57c0.17,0.08,0.34,0.16,0.51,0.23
	c0.04,0.93,0.08,1.85,0.12,2.66c1.09,0.12,2.14,0.24,3.19,0.36c0.15,0.19,0.3,0.38,0.45,0.58c0.02,0.97,0.05,1.95,0.07,2.92
	c-0.14,0.13-0.28,0.26-0.42,0.38c-0.9,0.26-1.79,0.53-2.68,0.79c0.06,0.79,0.13,1.62,0.19,2.44c2.76-0.42,3.31-0.01,4.04,3
	c0.75-0.04,1.51-0.08,2.17-0.11c0.08-0.99,0.14-1.84,0.21-2.7c0.13-0.18,0.26-0.35,0.4-0.53c0.94-0.27,1.89-0.55,2.86-0.83
	c-0.19-2.86,0.15-3.29,3.06-3.78c-0.04-0.83-0.09-1.65-0.12-2.29c-1.05-0.08-1.99-0.15-2.92-0.21c-0.16-0.15-0.33-0.3-0.49-0.44
	c-0.15-0.96-0.3-1.92-0.43-2.73c-1.02-0.08-2.01-0.15-2.99-0.23C608.16,316.29,608.26,316.37,608.26,316.37z M609.63,290.9
	c0.27,0.9,0.54,1.79,0.82,2.71c0.76,0,1.52,0,2.31,0c-0.09-0.92-0.18-1.75-0.25-2.47c-1.06-0.06-2-0.12-2.93-0.18
	c-0.34-1.07-0.67-2.14-1.02-3.24c-0.62,0.13-1.38,0.29-2.17,0.46c0,0.79,0,1.57,0,2.15C607.53,290.54,608.58,290.72,609.63,290.9z
	 M609.61,334.13l0.08-0.09c-1.08,0.63-2.18,1.04-3.43,0.5l0.07,0.05c-0.48-1.01-0.97-2.01-1.45-3.02c-0.71,0.04-1.53,0.09-2.36,0.14
	c0.55,2.7,0.09,3.35-2.79,4.07c0,0.77,0,1.53,0,2.07c1.12,0.19,2.11,0.35,3.1,0.52c0.19,0.09,0.37,0.18,0.56,0.27
	c0.06,0.83,0.13,1.67,0.18,2.42c1.05,0.1,1.89,0.19,2.73,0.27c0.16,0.15,0.33,0.3,0.49,0.44c0.56,0.94,0.78,1.92,0.22,2.92
	c-0.68,1.21-1.9,1.16-3.12,1.14c0.22,0.93,0.42,1.74,0.61,2.57c0.97,0,1.83,0,2.7,0c0.18,0.19,0.36,0.38,0.54,0.57
	c0.15,0.95,0.31,1.89,0.46,2.84c0.81-0.08,1.62-0.15,2.29-0.22c0.07-0.97,0.13-1.81,0.2-2.66c0.14-0.19,0.29-0.38,0.43-0.57
	c1.07-0.17,2.15-0.35,3.22-0.52c0.19,0.21,0.38,0.42,0.56,0.63c0.21,0.82,0.42,1.63,0.65,2.49c0.75-0.05,1.44-0.09,2.06-0.13
	c0.16-1.01,0.31-1.93,0.45-2.85c0.06-0.08,0.12-0.16,0.18-0.23c0.86-0.31,1.73-0.62,2.67-0.95c0-0.66,0-1.41,0-2.17
	c0.24-0.31,0.48-0.63,0.71-0.94c0.89-0.23,1.78-0.46,2.64-0.68c-0.12-0.74-0.22-1.43-0.35-2.22c-0.81,0-1.6,0-2.39,0
	c-0.16-0.2-0.32-0.4-0.48-0.61c-1.41-2.54-0.84-3.62,2.43-4.39c-0.06-0.73-0.13-1.48-0.2-2.21c-1.03,0-1.9,0-2.77,0
	c-0.18-0.3-0.37-0.61-0.55-0.91c-0.17-0.81-0.33-1.62-0.49-2.37c-0.9-0.1-1.78-0.21-2.67-0.31c-0.11-0.07-0.23-0.13-0.35-0.19
	c-0.32-0.92-0.63-1.84-0.95-2.76c-0.76,0.04-1.51,0.08-2.12,0.11c-0.12,1.05-0.24,2.02-0.35,2.99c-0.15,0.19-0.3,0.38-0.45,0.56
	c-0.9,0.11-1.79,0.23-2.54,0.33C609.91,332.06,609.76,333.1,609.61,334.13z M676.42,602.89c-1.02,0.26-2.05,0.53-3.12,0.8
	c0.14,0.74,0.3,1.56,0.44,2.25c0.93,0,1.68,0,2.21,0c0.15-1.14,0.28-2.13,0.41-3.12c0.93-0.34,1.86-0.68,2.84-1.03
	c-0.05-0.75-0.1-1.5-0.15-2.23c-0.9,0.05-1.66,0.09-2.38,0.13C676.58,600.77,676.5,601.83,676.42,602.89z M615.02,312.14
	c-0.97,0.26-1.93,0.53-2.97,0.81c0.04,0.7,0.08,1.48,0.12,2.25c0.9,0,1.73,0,2.33,0c0.17-1.14,0.32-2.13,0.46-3.13
	c0.93-0.34,1.87-0.68,2.88-1.05c-0.11-0.65-0.25-1.4-0.37-2.14c-0.87,0.04-1.62,0.08-2.16,0.11
	C615.19,310.14,615.11,311.14,615.02,312.14z M626.95,408.52c-0.93,0.3-1.87,0.6-2.83,0.91c-0.06,0.73-0.13,1.57-0.21,2.41
	c-0.12,0.17-0.25,0.34-0.37,0.51c-1.01,0.28-2.01,0.56-3.05,0.84c0.13,0.64,0.29,1.46,0.45,2.26c0.92,0.09,1.78,0.17,2.63,0.25
	c0.16,0.15,0.32,0.29,0.48,0.44c1.08,2.87,0.37,3.91-2.87,4.17c0.2,0.82,0.39,1.63,0.59,2.46c0.83-0.04,1.59-0.08,2.36-0.12
	c-0.28-3.09,0.09-3.54,2.92-3.87c0.08-1.03,0.15-2.04,0.22-3.04c0.2-0.17,0.4-0.35,0.6-0.52c0.89-0.1,1.78-0.2,2.66-0.3
	c-0.11-0.86-0.21-1.63-0.3-2.36c-0.96-0.09-1.88-0.17-2.79-0.25c-0.17-0.15-0.33-0.3-0.5-0.45c-0.48-1.12-0.44-2.25-0.04-3.39
	c0.95-0.33,1.9-0.65,2.78-0.96c0-0.81,0-1.51,0-2.26c-0.91,0.06-1.74,0.11-2.35,0.15C627.19,406.53,627.07,407.53,626.95,408.52z
	 M673.48,610.5c-0.39-0.93-0.78-1.86-1.18-2.82c-0.65,0-1.34,0-2.07,0c0,0.88,0,1.65,0,2.25c1.17,0.18,2.24,0.34,3.31,0.51
	c0.26,0.95,0.51,1.89,0.74,2.72c0.88,0,1.62,0,2.33,0c-0.05-0.92-0.1-1.75-0.15-2.52C675.46,610.59,674.47,610.54,673.48,610.5z
	 M499.03,61.72c1.2-0.04,2.39-0.08,3.59-0.12c0.48,0.94,0.95,1.89,1.48,2.93c0.53,0,1.36,0,2.2,0c-0.37-2.85,0.06-3.46,3.05-4.21
	c-0.04-0.76-0.08-1.53-0.12-2.3c-0.94,0.11-1.77,0.2-2.61,0.29c0.39,3.06-0.66,4.01-3.6,3.4c-0.15-0.03-0.31-0.03-0.47-0.05
	c-0.22-0.97-0.43-1.93-0.65-2.89c-0.82,0.04-1.58,0.08-2.06,0.1c-0.28,1.04-0.53,1.95-0.76,2.89c-0.82,0.5-1.64,1-2.49,1.51
	c0,0.57,0,1.24,0,1.9c0.86,0,1.63,0,2.43,0C499.03,63.99,499.03,62.85,499.03,61.72z M473.86,60.61c-0.94,0.31-1.88,0.62-2.89,0.95
	c0.03,0.67,0.07,1.44,0.11,2.26c0.89,0,1.73,0,2.43,0c0.1-1.14,0.2-2.2,0.3-3.26c1.04-0.28,2.07-0.56,3.09-0.83
	c-0.14-0.7-0.29-1.51-0.45-2.3c-0.86,0.06-1.61,0.11-2.18,0.15C474.12,58.66,473.99,59.64,473.86,60.61z M657.38,553.56
	c1.05-0.16,2.1-0.33,3.15-0.49c-0.06-0.94-0.12-1.7-0.17-2.47c-0.88,0.06-1.65,0.11-2.17,0.15c-0.28,1.01-0.53,1.93-0.76,2.87
	c-0.82,0.49-1.65,0.98-2.61,1.56c0.05,0.46,0.13,1.13,0.21,1.84c0.82,0,1.58,0,2.35,0C657.38,555.82,657.38,554.69,657.38,553.56z
	 M621.44,430.29c1.03-0.19,2.06-0.37,3.08-0.56c-0.04-0.91-0.08-1.68-0.12-2.46c-0.89,0.05-1.72,0.09-2.26,0.12
	c-0.24,1.09-0.44,2.02-0.63,2.97c-0.93,0.42-1.85,0.84-2.84,1.29c0.1,0.59,0.22,1.34,0.35,2.1c0.88,0,1.65,0,2.42,0
	C621.44,432.56,621.44,431.43,621.44,430.29z M592.36,216.57c-0.34-1.07-0.69-2.14-1.04-3.23c-0.61,0.14-1.36,0.31-2.19,0.49
	c0.05,0.78,0.09,1.54,0.13,2.1c1.16,0.21,2.16,0.4,3.15,0.58c0.24,0.89,0.48,1.78,0.73,2.69c0.8,0,1.61,0,2.38,0
	c-0.06-0.94-0.11-1.77-0.17-2.64C594.36,216.57,593.36,216.57,592.36,216.57z M545.35,217.03c-0.41-0.98-0.81-1.95-1.24-2.97
	c-0.72,0.07-1.4,0.14-2.09,0.21c0.04,0.89,0.07,1.66,0.1,2.23c1.17,0.17,2.22,0.33,3.27,0.48c0.13,1.13,0.26,2.27,0.39,3.4
	c-0.14,0.13-0.28,0.26-0.42,0.38c-0.87,0.28-1.75,0.56-2.61,0.83c0.07,0.78,0.14,1.54,0.19,2.03c0.99,0.2,2.05,0.11,2.58,0.6
	c0.73,0.67,1.07,1.77,1.64,2.8c0.74,0,1.61,0,2.47,0c0.17,0.18,0.34,0.35,0.5,0.53c0.16,0.95,0.32,1.9,0.47,2.85
	c0.83-0.07,1.66-0.14,2.31-0.2c0.1-1,0.18-1.84,0.26-2.69c0.11-0.15,0.21-0.3,0.32-0.46c1.01-0.28,2.02-0.56,2.87-0.8
	c0-0.94,0-1.98,0-3.03c0.16-0.13,0.31-0.26,0.47-0.4c0.89-0.28,1.77-0.55,2.64-0.82c-0.1-0.76-0.2-1.45-0.29-2.11
	c-2.9-0.1-3.26-0.42-3.85-3.16c-0.94-0.08-1.87-0.16-2.81-0.23c-0.15-0.13-0.3-0.27-0.45-0.4c-0.14-0.96-0.28-1.93-0.43-2.89
	c-0.8,0.05-1.64,0.11-2.49,0.17c0,0.88,0,1.79,0,2.71c-0.19,0.16-0.38,0.32-0.57,0.48C547.63,217.53,546.47,217.12,545.35,217.03z
	 M466.72,57.57c0.23,0.97,0.47,1.95,0.71,2.96c0.71-0.07,1.4-0.13,2.11-0.2c-0.04-0.91-0.08-1.68-0.12-2.36
	c-0.97-0.11-1.87-0.22-2.78-0.32c-0.48-0.94-0.96-1.89-1.48-2.9c-0.61,0.05-1.39,0.12-1.91,0.16c-0.17,0.99-0.04,2.04-0.51,2.57
	c-0.66,0.75-1.76,1.11-2.76,1.68c0.02,0.54,0.05,1.25,0.09,2.03c0.88-0.07,1.71-0.14,2.54-0.21c-0.33-3.07,0.68-4.01,3.62-3.47
	C466.4,57.53,466.56,57.54,466.72,57.57z M604.34,399.28c0.17,0.18,0.34,0.36,0.51,0.54c0.15,0.95,0.31,1.9,0.46,2.86
	c0.83-0.07,1.65-0.14,2.31-0.2c0.09-1,0.16-1.85,0.24-2.69c0.12-0.16,0.24-0.33,0.36-0.49c1.03-0.27,2.07-0.54,2.94-0.77
	c-0.05-0.96-0.1-1.97-0.16-2.98c0.18-0.16,0.37-0.33,0.55-0.49c0.89-0.26,1.77-0.51,2.66-0.77c-0.11-0.73-0.21-1.41-0.28-1.91
	c-1.05-0.27-2.15-0.25-2.77-0.79c-0.68-0.6-0.92-1.7-1.38-2.63c-0.62,0.03-1.36,0.07-2,0.1c-0.07,0.94-0.13,1.79-0.19,2.65
	c-0.12,0.17-0.25,0.34-0.37,0.51c-0.85,0.3-1.7,0.59-2.48,0.86c-0.09,0.91-0.2,1.89-0.3,2.87c-0.18,0.15-0.36,0.29-0.54,0.44
	c-0.87,0.17-1.74,0.34-2.62,0.51c0.08,0.76,0.16,1.52,0.25,2.39C602.49,399.28,603.42,399.28,604.34,399.28z M674.62,617.92
	c0.09,1,0.16,1.81,0.23,2.61c0.86-0.07,1.62-0.13,2.19-0.18c0.24-1.07,0.19-2.24,0.74-2.93c0.54-0.68,1.69-0.89,2.59-1.31
	c-0.07-0.69-0.14-1.44-0.21-2.17c-0.89,0.05-1.66,0.1-2.42,0.14C677.98,617.18,677.6,617.67,674.62,617.92z M570.51,291.12
	c-0.21,1.02-0.15,2.21-0.7,2.91c-0.53,0.68-1.7,0.86-2.64,1.28c0.06,0.68,0.13,1.49,0.2,2.3c0.86-0.07,1.63-0.14,2.2-0.19
	c0.25-1.09,0.2-2.27,0.76-2.96c0.54-0.67,1.7-0.85,2.62-1.26c-0.12-0.66-0.26-1.42-0.39-2.09
	C571.73,291.12,571.02,291.12,570.51,291.12z M602.69,371.53c0.13,0.76,0.25,1.51,0.38,2.23c0.82,0,1.53,0,2.07,0
	c0.22-1.04,0.15-2.23,0.7-2.93c0.53-0.68,1.69-0.85,2.62-1.27c-0.06-0.69-0.13-1.51-0.19-2.28c-0.86,0.06-1.64,0.11-2.42,0.16
	C606.01,370.57,605.72,370.94,602.69,371.53z M493.75,73.83c0.06,0.97,0.12,1.78,0.17,2.58c0.86-0.08,1.63-0.15,2.39-0.22
	c-0.06-3.18,0.28-3.61,3.28-4.02c-0.14-0.76-0.27-1.52-0.41-2.27c-0.8,0.06-1.54,0.12-2.27,0.18
	C497.02,73.12,496.69,73.52,493.75,73.83z M597.41,227.43c0.05,0.82,0.09,1.64,0.14,2.49c0.83-0.07,1.6-0.13,2.38-0.19
	c0.01-3.2,0.37-3.64,3.34-3.95c-0.14-0.77-0.27-1.53-0.4-2.27c-0.8,0-1.55,0-2.3,0C600.67,226.64,600.33,227.06,597.41,227.43z
	 M548.72,248.5c0.93,0,1.63,0,2.33,0c-0.03-3.27,0.34-3.7,3.39-3.87c-0.17-0.83-0.34-1.66-0.5-2.48c-0.81,0-1.58,0-2.2,0
	c-0.15,1.01,0,2.13-0.49,2.8c-0.53,0.74-1.63,1.08-2.52,1.61C548.72,247.11,548.72,247.73,548.72,248.5z M629.25,400.55
	c-0.09-3.13,0.09-3.38,3.09-4.04c-0.05-0.75-0.1-1.52-0.16-2.29c-0.88,0.08-1.63,0.14-2.15,0.19c-0.23,1.07-0.17,2.2-0.7,2.82
	c-0.59,0.69-1.72,0.91-2.62,1.34c0.03,0.65,0.07,1.41,0.11,2.17C627.69,400.67,628.47,400.61,629.25,400.55z M643.34,507.46
	c1.14,0.25,2.26,0.19,2.87,0.71c0.67,0.57,0.88,1.69,1.32,2.63c0.62-0.06,1.39-0.13,2.2-0.2c-0.12-0.84-0.22-1.59-0.29-2.11
	c-1.09-0.31-2.19-0.31-2.79-0.87c-0.66-0.62-0.86-1.73-1.29-2.69c-0.68,0.11-1.42,0.23-2.13,0.34
	C643.27,506.14,643.31,506.85,643.34,507.46z M598.08,205.59c-3.02,0.48-3.83-0.35-4.01-3.23c-0.82,0.15-1.64,0.29-2.42,0.43
	c0.05,0.86,0.1,1.62,0.14,2.16c1.03,0.22,2.19,0.15,2.88,0.69c0.7,0.54,0.93,1.68,1.37,2.56c0.62,0,1.32,0,2.05,0
	C598.08,207.35,598.08,206.59,598.08,205.59z M627.33,376.72c-1.19-0.31-2.38-0.29-3.03-0.87c-0.65-0.58-0.77-1.77-1.13-2.71
	c-0.67,0.14-1.49,0.3-2.25,0.46c0.04,0.88,0.08,1.64,0.11,2.19c1.01,0.21,2.17,0.14,2.86,0.67c0.7,0.54,0.95,1.67,1.39,2.55
	c0.61,0,1.3,0,2.04,0C627.33,378.16,627.33,377.39,627.33,376.72z M616.54,365.44c0.95-0.06,1.79-0.1,2.62-0.15
	c-0.16-0.84-0.3-1.6-0.45-2.35c-2.96-0.13-3.25-0.38-3.95-3.37c-0.74,0.14-1.49,0.29-2.15,0.42c0,0.83,0,1.54,0,2.24
	C615.77,362.15,616.2,362.5,616.54,365.44z M405.26,12.89c0.08,3.04-0.14,3.33-3.05,3.99c0.07,0.76,0.15,1.52,0.22,2.26
	c0.83-0.05,1.53-0.08,2.03-0.11c0.26-1.03,0.24-2.21,0.82-2.9c0.56-0.67,1.72-0.83,2.61-1.21c-0.07-0.71-0.14-1.47-0.21-2.17
	C406.83,12.8,406.04,12.84,405.26,12.89z M449.48,65.56c0.99,0.22,2.21,0.14,2.87,0.71c0.7,0.61,0.87,1.83,1.27,2.8
	c0.7-0.16,1.46-0.34,2.23-0.52c-0.09-0.75-0.18-1.44-0.25-2.01c-0.99-0.25-2.09-0.24-2.71-0.77c-0.69-0.6-0.96-1.7-1.44-2.64
	c-0.59,0.03-1.35,0.06-2.11,0.09C449.39,64.07,449.44,64.86,449.48,65.56z M551.09,235.08c0.05,0.88,0.1,1.65,0.14,2.41
	c3.18-0.03,3.5,0.23,3.92,3.19c0.84-0.09,1.68-0.17,2.49-0.26c-0.13-0.82-0.23-1.52-0.34-2.2c-3.02-0.21-3.34-0.49-3.89-3.4
	C552.72,234.9,552.02,234.98,551.09,235.08z M625.84,401.83c-2.94,0.3-3.43-0.08-4.02-3.05c-0.78,0.05-1.55,0.1-2.33,0.15
	c0.08,0.91,0.15,1.74,0.2,2.26c1.01,0.19,2.07,0.08,2.61,0.55c0.74,0.66,1.09,1.77,1.63,2.73c0.58,0,1.29,0,2.05,0
	C625.93,403.63,625.89,402.84,625.84,401.83z M518.46,580.03c-0.14-3.12,0.18-3.52,3.13-4.04c-0.13-0.68-0.25-1.36-0.4-2.16
	c-0.74,0.07-1.41,0.13-1.89,0.18c-0.29,1.08-0.28,2.21-0.84,2.81c-0.61,0.65-1.75,0.82-2.59,1.17c0,0.73,0,1.49,0,2.24
	C516.73,580.16,517.52,580.1,518.46,580.03z M441.5,67.6c-0.86,0.13-1.63,0.25-2.39,0.37c0.07,0.83,0.14,1.59,0.19,2.1
	c0.99,0.19,2.05,0.09,2.59,0.57c0.74,0.66,1.09,1.77,1.66,2.77c0.57-0.03,1.31-0.07,2.05-0.12c-0.07-0.94-0.14-1.72-0.2-2.49
	C442.45,71.1,441.85,70.63,441.5,67.6z M607.27,355.54c-0.05-0.97-0.09-1.75-0.13-2.38c-1.05-0.22-2.22-0.16-2.9-0.7
	c-0.68-0.55-0.87-1.71-1.29-2.64c-0.73,0.09-1.47,0.19-2.23,0.28c0.15,0.85,0.27,1.53,0.41,2.31c2.71-0.58,3.65,0.97,3.86,3.14
	C605.81,355.54,606.44,355.54,607.27,355.54z M517.53,180.11c-0.24,1.1-0.16,2.25-0.7,2.8c-0.68,0.7-1.86,0.92-2.81,1.34
	c0.11,0.68,0.23,1.45,0.35,2.23c0.85-0.09,1.68-0.18,2.2-0.24c0.19-1.03,0.08-2.09,0.56-2.61c0.68-0.74,1.78-1.08,2.75-1.61
	c-0.03-0.61-0.07-1.32-0.11-2.07C518.95,180.01,518.18,180.06,517.53,180.11z M612.01,410.52c-3.05-0.25-3.38-0.53-3.91-3.47
	c-0.75,0.11-1.5,0.22-2.3,0.34c0.06,0.85,0.11,1.61,0.16,2.21c1.05,0.21,2.17,0.13,2.8,0.65c0.69,0.57,0.92,1.68,1.37,2.61
	c0.62-0.06,1.32-0.13,2.07-0.2C612.13,411.88,612.07,411.19,612.01,410.52z M643.87,514.82c3.09-0.13,3.55,0.26,3.92,3.33
	c0.82-0.16,1.63-0.32,2.44-0.48c-0.12-0.8-0.22-1.48-0.29-2c-1.04-0.26-2.15-0.24-2.76-0.78c-0.68-0.6-0.92-1.7-1.38-2.63
	c-0.61,0.04-1.36,0.08-2.1,0.12C643.77,513.27,643.82,514.05,643.87,514.82z M603.39,420.76c0.94,0,1.64,0,2.34,0
	c-0.01-3.27,0.31-3.66,3.31-3.95c-0.15-0.83-0.31-1.65-0.46-2.46c-0.83,0.05-1.57,0.09-2.1,0.12c-0.18,1.05-0.07,2.16-0.57,2.81
	c-0.55,0.72-1.66,1.02-2.52,1.49C603.39,419.41,603.39,420.03,603.39,420.76z M608.7,456.45c0.19-1.07,0.07-2.12,0.55-2.65
	c0.67-0.74,1.77-1.09,2.79-1.67c-0.04-0.58-0.09-1.33-0.15-2.06c-0.91,0.09-1.69,0.17-2.53,0.25c0.75,2.8-0.72,3.8-3.16,3.9
	c0.15,0.85,0.27,1.54,0.39,2.24C607.37,456.45,608.07,456.45,608.7,456.45z M556.94,301.96c2.72-0.51,3.81,0.94,3.91,3.36
	c0.87-0.12,1.56-0.22,2.29-0.32c-0.05-0.85-0.1-1.63-0.15-2.41c-2.94,0.22-3.29-0.04-4.11-3.01c-0.66,0.06-1.34,0.12-2.04,0.19
	C556.87,300.55,556.9,301.17,556.94,301.96z M557.93,187.67c0,0.79,0,1.56,0,2.33c3.08-0.12,3.48,0.19,4.03,3.1
	c0.75-0.05,1.5-0.1,2.37-0.16c-0.11-0.84-0.22-1.58-0.28-2.08c-1.12-0.3-2.23-0.3-2.83-0.85c-0.66-0.61-0.86-1.73-1.29-2.69
	C559.31,187.42,558.63,187.54,557.93,187.67z M603.56,259.3c0.37,2.85-0.12,3.45-3.12,3.99c0.13,0.75,0.26,1.5,0.39,2.28
	c0.87-0.07,1.64-0.13,2.09-0.16c0.2-1.03,0.11-2.09,0.59-2.61c0.67-0.73,1.78-1.07,2.74-1.6c-0.03-0.63-0.07-1.34-0.11-2.06
	C605.31,259.18,604.54,259.23,603.56,259.3z M565.93,214.54c0.21-1.07,0.11-2.15,0.6-2.67c0.68-0.73,1.81-1.04,2.84-1.58
	c-0.07-0.46-0.16-1.15-0.26-1.85c-0.83,0-1.6,0-2.37,0c0.25,2.86-0.11,3.32-3.12,4.06c0.06,0.73,0.11,1.48,0.17,2.21
	C564.64,214.65,565.35,214.59,565.93,214.54z M614.96,428.21c-0.21,1.09-0.11,2.16-0.59,2.68c-0.68,0.73-1.79,1.05-2.76,1.56
	c0.03,0.64,0.07,1.35,0.1,2.1c0.89-0.1,1.71-0.2,2.53-0.29c-0.31-2.84,0.16-3.4,3.2-3.92c-0.13-0.75-0.26-1.49-0.39-2.24
	C616.22,428.14,615.51,428.18,614.96,428.21z M625.2,505.3c0.78,0,1.59,0,2.17,0c0.18-1,0.07-2.06,0.55-2.59
	c0.67-0.74,1.78-1.08,2.73-1.62c0-0.55,0-1.25,0-2.04c-0.91,0.07-1.74,0.14-2.59,0.21c0.77,2.89-0.78,3.81-3.16,3.88
	C625,503.93,625.1,504.56,625.2,505.3z M447.41,52.49c-2.43,0.43-3.53,0.28-4.11-3.04c-0.76,0.07-1.52,0.13-2.32,0.2
	c0.11,0.82,0.2,1.51,0.29,2.18c3.17,0.28,3.29,0.46,3.92,3.44c0.75-0.1,1.5-0.2,2.21-0.29C447.41,54.14,447.41,53.43,447.41,52.49z
	 M613.65,495.62c0.75-0.04,1.49-0.07,2.25-0.11c-0.06-0.89-0.11-1.67-0.16-2.45c-3.04,0.26-3.55-0.17-3.91-3.25
	c-0.76,0.13-1.51,0.27-2.34,0.41c0.06,0.73,0.12,1.41,0.18,2.07C612.76,492.54,613.01,492.75,613.65,495.62z M610.87,438.15
	c-0.12-0.91-0.22-1.59-0.33-2.43c-2.65,0.58-3.73-0.79-3.87-3.12c-0.84,0.08-1.52,0.15-2.25,0.22c0.05,0.86,0.1,1.63,0.14,2.21
	c1.03,0.2,2.16,0.11,2.78,0.63c0.69,0.57,0.92,1.7,1.37,2.64C609.29,438.26,610,438.21,610.87,438.15z M470.4,112.14
	c-0.77,0-1.52,0-2.34,0c0.09,0.92,0.16,1.74,0.22,2.3c1.09,0.22,2.25,0.11,2.82,0.64c0.69,0.65,0.88,1.83,1.3,2.8
	c0.68-0.14,1.43-0.29,2.15-0.44c-0.11-0.83-0.2-1.5-0.28-2.14C471.3,115.24,470.97,114.96,470.4,112.14z M410.74,32.88
	c0.96-0.1,1.71-0.18,2.47-0.26c-0.31-2.73,0.1-3.26,3.02-3.97c-0.06-0.67-0.13-1.35-0.19-2.02c-0.87,0-1.63,0-2.13,0
	c-0.24,1.05-0.17,2.21-0.71,2.77c-0.67,0.7-1.86,0.9-2.81,1.31C410.49,31.34,410.6,32.03,410.74,32.88z M541.46,207.01
	c-0.19,1-0.09,2.09-0.59,2.72c-0.57,0.71-1.66,0.99-2.67,1.53c0.04,0.55,0.1,1.3,0.15,2.04c0.92-0.09,1.7-0.16,2.22-0.21
	c0.18-0.98,0.07-2.04,0.55-2.57c0.67-0.74,1.78-1.09,2.78-1.65c-0.04-0.61-0.1-1.37-0.15-2.1
	C542.84,206.87,542.06,206.95,541.46,207.01z M560.67,236.59c0.2-1.02,0.12-2.13,0.63-2.74c0.58-0.7,1.68-0.98,2.66-1.49
	c-0.03-0.53-0.07-1.22-0.12-1.97c-0.86,0.05-1.63,0.09-2.41,0.13c0.34,2.36,0.25,3.31-3.09,4.04c0.03,0.76,0.07,1.53,0.1,2.3
	C559.31,236.76,560.09,236.66,560.67,236.59z M572.54,204.21c-3.05,0.23-3.55-0.2-3.91-3.33c-0.82,0.18-1.63,0.36-2.42,0.54
	c0.13,0.84,0.23,1.51,0.33,2.16c2.93,0.06,3.22,0.31,3.91,3.19c0.69-0.05,1.38-0.11,2.08-0.16
	C572.54,205.78,572.54,205,572.54,204.21z M565.61,127.62c-0.07-0.57-0.15-1.17-0.24-1.91c-0.78,0.05-1.45,0.09-2.1,0.14
	c-0.15,3.05-0.38,3.31-3.23,3.81c0,0.77,0,1.54,0,2.39c0.86-0.09,1.68-0.17,2.49-0.26C562.49,128.71,562.56,128.6,565.61,127.62z
	 M667.03,584.98c-0.13-0.92-0.22-1.6-0.34-2.43c-2.69,0.58-3.78-0.79-3.84-3.26c-0.85,0.19-1.52,0.34-2.19,0.49c0,0.8,0,1.49,0,2.06
	c1.01,0.17,2.15,0.05,2.79,0.56c0.71,0.56,0.96,1.7,1.47,2.68C665.44,585.06,666.15,585.03,667.03,584.98z M603.41,270.32
	c-3.03-0.1-3.33-0.35-3.95-3.21c-0.68,0.07-1.37,0.14-2.13,0.21c0.06,0.8,0.11,1.54,0.16,2.28c2.96-0.12,3.42,0.3,3.77,3.36
	c0.76-0.15,1.51-0.3,2.27-0.45c0.06-0.01,0.11-0.11,0.14-0.14C603.58,271.71,603.51,271.08,603.41,270.32z M354.23,20.87
	c-3.08,0.32-3.37-0.3-4.04-2.86c-0.69,0-1.37,0-2.19,0c0.06,0.78,0.11,1.46,0.16,2.12c3.06,0.28,3.33,0.5,4.01,3.39
	c0.67-0.12,1.34-0.25,2.07-0.38C354.23,22.4,354.23,21.7,354.23,20.87z M429.42,39.67c-0.17,1.09-0.04,2.21-0.53,2.86
	c-0.54,0.73-1.65,1.03-2.59,1.57c0.08,0.54,0.17,1.14,0.26,1.75c0.75-0.06,1.38-0.11,1.76-0.14c0.37-1.09,0.45-2.26,1.08-2.9
	c0.6-0.61,1.76-0.66,2.68-0.96c-0.17-0.73-0.35-1.48-0.51-2.19C430.8,39.67,430.1,39.67,429.42,39.67z M595.83,223.8
	c-0.91,0.04-1.81,0.08-2.72,0.12c0.17,0.95,0.33,1.85,0.48,2.74c0.85-0.12,1.63-0.23,2.38-0.34
	C595.93,225.55,595.88,224.77,595.83,223.8z M620.45,492.78c0.06,0.82,0.11,1.64,0.16,2.37c0.98,0,1.88,0,2.72,0
	c-0.17-1-0.33-1.89-0.48-2.75C621.99,492.54,621.23,492.66,620.45,492.78z M606.72,377.24c0.84-0.07,1.66-0.14,2.48-0.21
	c-0.07-0.93-0.12-1.72-0.18-2.53c-0.87,0.04-1.71,0.08-2.54,0.12C606.56,375.52,606.64,376.35,606.72,377.24z M509.57,173.51
	c0.06,0.92,0.12,1.77,0.18,2.61c0.9,0,1.83,0,2.79,0c-0.19-0.96-0.36-1.83-0.51-2.61C511.12,173.51,510.35,173.51,509.57,173.51z
	 M448.22,62.35c-0.06-0.92-0.11-1.76-0.16-2.63c-0.84,0.06-1.69,0.12-2.56,0.18c0.09,0.86,0.17,1.69,0.25,2.45
	C446.68,62.35,447.44,62.35,448.22,62.35z M558.09,205.69c-0.9,0-1.68,0-2.46,0c0.08,0.93,0.14,1.76,0.21,2.52c0.95,0,1.87,0,2.72,0
	C558.39,207.32,558.24,206.54,558.09,205.69z M470.14,64.98c-0.74,0.04-1.63,0.09-2.56,0.14c0.07,0.87,0.15,1.71,0.22,2.56
	c0.9-0.06,1.73-0.11,2.49-0.15C470.24,66.66,470.19,65.9,470.14,64.98z M611.48,308.13c0.88-0.06,1.63-0.12,2.46-0.18
	c-0.05-0.88-0.1-1.7-0.15-2.53c-0.91,0.07-1.74,0.12-2.49,0.18C611.36,306.46,611.42,307.23,611.48,308.13z M564.53,348.4
	c0.94-0.04,1.82-0.08,2.64-0.12c-0.18-0.95-0.34-1.76-0.48-2.48c-0.88,0-1.64,0-2.4,0C564.37,346.73,564.45,347.56,564.53,348.4z
	 M443.35,88.98c-0.99,0.05-1.85,0.09-2.74,0.14c0.13,0.85,0.28,1.75,0.42,2.66c0.83-0.11,1.6-0.21,2.32-0.3
	C443.35,90.64,443.35,89.86,443.35,88.98z M612.76,373.31c-0.18-1.04-0.32-1.91-0.47-2.77c-0.87,0.15-1.63,0.28-2.34,0.4
	c0,0.83,0,1.64,0,2.37C610.92,373.31,611.77,373.31,612.76,373.31z M606.57,388.17c-0.07-0.99-0.13-1.83-0.19-2.67
	c-0.85,0.07-1.68,0.14-2.5,0.21c0.07,0.85,0.12,1.63,0.19,2.46C604.89,388.17,605.67,388.17,606.57,388.17z M568.94,287.63
	c-0.83,0.06-1.66,0.12-2.51,0.19c0.1,0.88,0.18,1.71,0.26,2.44c0.93,0,1.68,0,2.42,0C569.06,289.33,569,288.49,568.94,287.63z
	 M599.34,222.63c-0.08-0.99-0.14-1.83-0.21-2.66c-0.88,0.07-1.71,0.14-2.54,0.2c0.1,0.88,0.19,1.71,0.28,2.46
	C597.76,222.63,598.47,222.63,599.34,222.63z M566.35,298.66c-0.95,0.08-1.78,0.15-2.57,0.21c0.11,0.93,0.2,1.75,0.3,2.56
	c0.88-0.07,1.65-0.13,2.45-0.19C566.46,300.41,566.41,299.64,566.35,298.66z M448.12,48.85c0.11,0.97,0.2,1.78,0.29,2.57
	c0.85-0.08,1.61-0.14,2.42-0.22c-0.07-0.88-0.13-1.7-0.18-2.5C449.77,48.76,449.01,48.81,448.12,48.85z M480.16,168.88
	c0.12,0.74,0.25,1.59,0.4,2.52c0.79-0.08,1.53-0.15,2.33-0.23c-0.05-0.91-0.09-1.72-0.13-2.53
	C481.85,168.72,481.09,168.79,480.16,168.88z M573.86,300.88c-0.21-0.98-0.38-1.78-0.54-2.55c-0.82,0-1.57,0-2.33,0
	c0.05,0.9,0.09,1.73,0.14,2.55C571.95,300.88,572.79,300.88,573.86,300.88z M408.77,11.45c0.89,0,1.7,0,2.53,0
	c-0.19-0.95-0.36-1.77-0.51-2.54c-0.8,0-1.48,0-2.25,0C408.62,9.81,408.7,10.63,408.77,11.45z M499.16,54.2c0.96,0,1.87,0,2.78,0
	c-0.2-0.97-0.38-1.84-0.56-2.73c-0.86,0.19-1.58,0.35-2.22,0.49C499.16,52.81,499.16,53.51,499.16,54.2z M452.16,54.68
	c0.89,0,1.79,0,2.71,0c-0.16-0.93-0.31-1.75-0.45-2.59c-0.86,0.05-1.67,0.11-2.43,0.16C452.05,53.15,452.11,53.91,452.16,54.68z
	 M599.28,209.11c0.07,0.94,0.12,1.71,0.18,2.53c0.82-0.05,1.66-0.09,2.54-0.14c-0.07-0.87-0.13-1.71-0.2-2.52
	C600.92,209.03,600.14,209.07,599.28,209.11z M619.2,520.66c0.96-0.04,1.8-0.08,2.65-0.12c-0.15-0.85-0.3-1.68-0.44-2.51
	c-0.81,0.05-1.58,0.09-2.35,0.13C619.1,518.96,619.14,519.73,619.2,520.66z M466.77,108.6c-0.89,0.1-1.71,0.19-2.53,0.28
	c0.07,0.85,0.13,1.64,0.19,2.46c0.87-0.05,1.7-0.11,2.48-0.15C466.87,110.32,466.82,109.55,466.77,108.6z M442.07,63.71
	c0.13,0.82,0.26,1.71,0.4,2.62c0.82-0.12,1.58-0.23,2.29-0.34c0-0.85,0-1.66,0-2.42C443.8,63.63,442.96,63.67,442.07,63.71z
	 M563.06,291.67c0.1,0.79,0.22,1.63,0.34,2.57c0.78-0.07,1.55-0.15,2.37-0.23c-0.08-0.84-0.15-1.67-0.22-2.46
	C564.71,291.6,563.93,291.63,563.06,291.67z M611.96,355.06c0.97,0,1.82,0,2.72,0c-0.14-0.84-0.28-1.68-0.43-2.56
	c-0.82,0.04-1.65,0.07-2.45,0.11C611.85,353.45,611.9,354.21,611.96,355.06z M639.83,509c0.09,0.91,0.17,1.73,0.24,2.52
	c0.88-0.05,1.65-0.09,2.46-0.13c-0.05-0.87-0.1-1.74-0.15-2.57C641.54,508.88,640.78,508.93,639.83,509z M602.42,411.15
	c0.08,0.87,0.16,1.7,0.24,2.51c0.91-0.05,1.68-0.1,2.45-0.15c-0.05-0.88-0.1-1.71-0.15-2.56
	C604.13,411.02,603.29,411.08,602.42,411.15z M547.92,241.37c0.87-0.04,1.7-0.08,2.49-0.11c-0.05-0.9-0.09-1.72-0.14-2.58
	c-0.79,0.05-1.62,0.1-2.5,0.15C547.83,239.67,547.87,240.51,547.92,241.37z M680.98,621.19c-0.96,0.04-1.72,0.08-2.44,0.11
	c0,0.92,0,1.8,0,2.71c0.9-0.1,1.72-0.19,2.56-0.28C681.05,622.84,681.02,622.07,680.98,621.19z M629.26,429.43c0.85,0,1.7,0,2.6,0
	c-0.14-0.88-0.28-1.72-0.41-2.52c-0.82,0-1.59,0-2.4,0C629.13,427.81,629.19,428.63,629.26,429.43z M611.68,363.43
	c-0.95,0.09-1.76,0.16-2.58,0.24c0.09,0.87,0.17,1.69,0.25,2.49c0.88-0.05,1.65-0.1,2.45-0.15
	C611.76,365.2,611.72,364.38,611.68,363.43z M625.17,423.49c0.08,1.01,0.15,1.84,0.21,2.68c0.84-0.06,1.68-0.12,2.53-0.17
	c-0.06-0.89-0.12-1.73-0.18-2.51C626.85,423.49,626.07,423.49,625.17,423.49z M565.74,280.5c0.11,0.99,0.2,1.81,0.3,2.75
	c0.82-0.12,1.56-0.22,2.33-0.33c-0.04-0.89-0.08-1.66-0.11-2.42C567.44,280.5,566.67,280.5,565.74,280.5z M617.1,496.56
	c0.07,0.82,0.14,1.64,0.22,2.51c0.82-0.06,1.63-0.11,2.47-0.17c-0.08-0.9-0.16-1.72-0.23-2.52
	C618.67,496.44,617.91,496.5,617.1,496.56z M677.23,606.9c0.09,0.86,0.17,1.69,0.25,2.46c0.92,0,1.69,0,2.47,0
	c-0.1-0.93-0.19-1.77-0.28-2.59C678.81,606.81,678.03,606.85,677.23,606.9z M357.78,19.57c-0.07-0.84-0.14-1.67-0.21-2.48
	c-0.87,0.1-1.63,0.19-2.39,0.28c0,0.76,0,1.52,0,2.33C356.05,19.65,356.88,19.61,357.78,19.57z M512.48,180.61
	c-0.73,0.08-1.42,0.15-2.19,0.23c0.07,0.86,0.14,1.68,0.21,2.5c0.94-0.05,1.71-0.08,2.47-0.12
	C512.8,182.33,512.65,181.5,512.48,180.61z M403.27,31.08c0.08,0.92,0.15,1.7,0.21,2.5c0.87-0.06,1.71-0.12,2.5-0.17
	c-0.07-0.91-0.14-1.73-0.2-2.51C404.95,30.96,404.17,31.01,403.27,31.08z M487.48,141.8c0-0.94,0-1.69,0-2.49
	c-0.87,0.09-1.69,0.18-2.53,0.27c0.05,0.89,0.09,1.72,0.13,2.57C485.92,142.03,486.68,141.92,487.48,141.8z M453.04,119.5
	c0-0.83,0-1.6,0-2.42c-0.91,0.11-1.73,0.21-2.46,0.31c0,0.88,0,1.65,0,2.48C451.4,119.75,452.22,119.63,453.04,119.5z
	 M469.97,104.74c-0.91,0.13-1.64,0.23-2.38,0.34c0.05,0.87,0.1,1.63,0.15,2.52c0.83-0.12,1.55-0.23,2.23-0.33
	C469.97,106.4,469.97,105.71,469.97,104.74z M615.89,358.4c0.81-0.16,1.61-0.33,2.42-0.49c-0.07-0.91-0.13-1.67-0.18-2.39
	c-0.95,0.09-1.79,0.18-2.59,0.25C615.67,356.72,615.78,357.55,615.89,358.4z M594.59,198.54c0.14,0.94,0.27,1.78,0.41,2.67
	c0.78-0.14,1.54-0.27,2.35-0.41c-0.06-0.81-0.12-1.58-0.17-2.26C596.3,198.54,595.51,198.54,594.59,198.54z M571.56,276.65
	c-0.95,0.05-1.71,0.08-2.51,0.12c0.07,0.88,0.14,1.74,0.22,2.6c0.86-0.09,1.62-0.18,2.41-0.26
	C571.64,278.31,571.6,277.53,571.56,276.65z M540.39,86.77c0-0.79,0-1.62,0-2.48c-0.89,0.05-1.71,0.09-2.52,0.14
	c0.08,0.9,0.16,1.71,0.23,2.52C538.87,86.89,539.57,86.83,540.39,86.77z M605.03,205.05c-0.85,0.17-1.59,0.31-2.28,0.45
	c0.04,0.85,0.08,1.6,0.12,2.34c0.9-0.07,1.72-0.14,2.54-0.21C605.28,206.81,605.17,206.04,605.03,205.05z M559.36,208.83
	c0.14,1.05,0.25,1.89,0.35,2.72c0.77-0.15,1.53-0.3,2.4-0.47c-0.04-0.77-0.07-1.54-0.11-2.26
	C561.09,208.83,560.3,208.83,559.36,208.83z M549.62,231.42c-0.9,0.12-1.59,0.22-2.33,0.32c0.04,0.8,0.08,1.58,0.12,2.54
	c0.81-0.12,1.53-0.22,2.21-0.32C549.62,233.09,549.62,232.37,549.62,231.42z M604.26,403.73c-0.9,0.07-1.72,0.14-2.54,0.2
	c0.11,0.93,0.2,1.76,0.3,2.63c0.87-0.13,1.61-0.24,2.36-0.35C604.34,405.31,604.3,404.53,604.26,403.73z M358.98,20.54
	c0,0.9,0,1.8,0,2.69c0.98-0.19,1.86-0.36,2.72-0.53c-0.12-0.82-0.24-1.57-0.35-2.31C360.5,20.45,359.74,20.49,358.98,20.54z
	 M600.46,389.48c0.12,0.9,0.24,1.78,0.36,2.66c0.86-0.11,1.61-0.21,2.35-0.3c-0.06-0.83-0.11-1.58-0.17-2.35
	C602.18,389.48,601.36,389.48,600.46,389.48z M339.19,15.15c-0.92,0-1.68,0-2.47,0c0.14,0.92,0.27,1.75,0.4,2.57
	c0.82-0.06,1.58-0.11,2.4-0.17C339.42,16.79,339.31,16.03,339.19,15.15z M274.11,95.48c-0.12-1.01-0.22-1.83-0.31-2.54
	c-0.92,0-1.75,0-2.65,0c0.16,0.86,0.31,1.68,0.47,2.54C272.44,95.48,273.22,95.48,274.11,95.48z M550.25,270.3
	c1.03-0.11,1.87-0.19,2.77-0.29c-0.18-0.83-0.35-1.65-0.54-2.51c-0.8,0.08-1.53,0.15-2.23,0.21
	C550.25,268.61,550.25,269.4,550.25,270.3z M472.84,56.51c-0.05-0.91-0.1-1.66-0.14-2.41c-0.85,0.06-1.61,0.11-2.42,0.16
	c0.06,0.82,0.11,1.63,0.16,2.42C471.23,56.62,471.93,56.57,472.84,56.51z M505.5,57.18c-0.05-0.83-0.1-1.65-0.14-2.47
	c-1.01,0.08-1.82,0.15-2.65,0.22c0.19,0.94,0.33,1.69,0.48,2.44C503.96,57.31,504.66,57.25,505.5,57.18z M455.7,55.35
	c0.18,0.95,0.33,1.78,0.49,2.61c0.82-0.17,1.55-0.33,2.3-0.48c-0.05-0.82-0.1-1.52-0.15-2.25C457.49,55.27,456.72,55.31,455.7,55.35
	z M448.89,56.17c0.07,0.83,0.14,1.64,0.21,2.5c0.84-0.14,1.51-0.24,2.18-0.35c-0.05-0.9-0.09-1.66-0.13-2.49
	C450.36,55.95,449.68,56.05,448.89,56.17z M625.89,390.96c0.09,0.92,0.17,1.74,0.25,2.56c0.92-0.07,1.68-0.13,2.45-0.19
	c-0.1-0.89-0.19-1.71-0.28-2.48C627.48,390.89,626.78,390.92,625.89,390.96z M570.18,330.83c0.11,0.76,0.23,1.55,0.35,2.37
	c0.83-0.07,1.52-0.13,2.25-0.19c-0.05-0.9-0.09-1.71-0.13-2.44C571.79,330.66,571.09,330.73,570.18,330.83z M560.21,229.4
	c-0.06-0.86-0.11-1.67-0.17-2.47c-1,0.1-1.82,0.18-2.66,0.26c0.2,0.92,0.37,1.67,0.53,2.42C558.7,229.53,559.39,229.47,560.21,229.4
	z M632.89,401.44c-0.85,0.06-1.65,0.11-2.41,0.17c0.11,0.93,0.2,1.68,0.28,2.37c0.9,0,1.73,0,2.59,0
	C633.2,403.08,633.05,402.26,632.89,401.44z M596.19,213.02c0.06,0.84,0.11,1.66,0.17,2.54c0.85-0.12,1.53-0.22,2.23-0.33
	c-0.04-0.89-0.08-1.66-0.12-2.49C597.68,212.84,596.98,212.93,596.19,213.02z M666.17,577.75c0-0.94,0-1.71,0-2.48
	c-0.95,0.09-1.79,0.18-2.66,0.26c0.15,0.87,0.29,1.71,0.43,2.54C664.69,577.96,665.37,577.86,666.17,577.75z M451.65,47.47
	c0.83,0,1.72,0,2.66,0c-0.16-0.92-0.31-1.75-0.48-2.65c-0.79,0.18-1.52,0.35-2.19,0.5C451.65,46.1,451.65,46.73,451.65,47.47z
	 M370,23.22c0.07,0.85,0.14,1.61,0.21,2.46c0.85-0.12,1.67-0.23,2.48-0.35c-0.12-0.81-0.21-1.5-0.32-2.24
	C371.53,23.14,370.77,23.18,370,23.22z M552.57,212.21c0.91-0.1,1.6-0.18,2.38-0.27c-0.06-0.88-0.11-1.7-0.16-2.55
	c-0.85,0.12-1.53,0.22-2.22,0.32C552.57,210.5,552.57,211.22,552.57,212.21z M545.31,210.45c0.1,0.77,0.18,1.45,0.26,2.09
	c0.91,0,1.68,0,2.45,0c-0.05-0.89-0.1-1.72-0.15-2.6C547.12,210.09,546.36,210.24,545.31,210.45z M639.07,515.05
	c-0.05-0.83-0.09-1.59-0.13-2.32c-0.93,0.06-1.74,0.12-2.55,0.17c0.16,0.9,0.3,1.71,0.44,2.54
	C637.67,515.29,638.36,515.17,639.07,515.05z M601.23,230.74c0.06,0.82,0.12,1.65,0.18,2.42c0.89,0,1.75,0,2.66,0
	c-0.14-0.85-0.29-1.7-0.43-2.53C602.85,230.67,602.07,230.71,601.23,230.74z M483.1,131.44c-0.12-0.8-0.24-1.55-0.35-2.3
	c-0.82,0.06-1.52,0.11-2.29,0.16c0.06,0.89,0.12,1.71,0.18,2.49C481.52,131.66,482.28,131.55,483.1,131.44z M550.46,325.52
	c-0.93,0-1.76,0-2.61,0c0.17,0.94,0.31,1.77,0.46,2.6c0.79-0.15,1.53-0.3,2.28-0.44C550.54,326.86,550.5,326.18,550.46,325.52z
	 M401.21,20.55c-0.91,0-1.75,0-2.66,0c0.18,0.83,0.36,1.65,0.56,2.52c0.75-0.06,1.45-0.12,2.22-0.19
	C401.28,22.05,401.25,21.28,401.21,20.55z M321.22,44.52c0.96-0.17,1.78-0.32,2.59-0.46c-0.14-0.81-0.26-1.5-0.39-2.24
	c-0.83,0.05-1.6,0.09-2.35,0.14C321.12,42.82,321.16,43.59,321.22,44.52z M383.18,21c-0.06-0.84-0.11-1.62-0.17-2.44
	c-0.85,0.1-1.66,0.19-2.38,0.28c0,0.84,0,1.54,0,2.3C381.51,21.09,382.33,21.04,383.18,21z M616.88,383.37
	c-0.09-0.9-0.16-1.63-0.23-2.34c-0.97,0.06-1.75,0.12-2.55,0.17c0.16,0.9,0.3,1.73,0.44,2.56
	C615.29,383.63,615.98,383.52,616.88,383.37z M448.69,69.45c-0.05-0.96-0.09-1.79-0.13-2.63c-0.8,0.12-1.55,0.23-2.28,0.34
	c0.06,0.86,0.11,1.63,0.17,2.47C447.15,69.58,447.85,69.52,448.69,69.45z M459.1,133.48c0.93-0.05,1.71-0.09,2.5-0.13
	c-0.1-0.88-0.2-1.73-0.29-2.57c-0.89,0.06-1.72,0.12-2.59,0.19C458.83,131.74,458.95,132.51,459.1,133.48z M558.84,201.7
	c0.18,0.92,0.33,1.67,0.49,2.46c0.8-0.07,1.53-0.14,2.32-0.21c-0.07-0.91-0.12-1.66-0.18-2.39
	C560.58,201.61,559.8,201.65,558.84,201.7z M603.47,381.1c0.8-0.1,1.55-0.2,2.26-0.29c0-0.87,0-1.65,0-2.49
	c-0.89,0.09-1.69,0.18-2.49,0.26C603.32,379.46,603.39,380.23,603.47,381.1z M610.28,377.97c0.11,0.93,0.21,1.7,0.29,2.43
	c0.89,0,1.76,0,2.6,0c-0.15-0.91-0.27-1.69-0.39-2.43C612,377.97,611.23,377.97,610.28,377.97z M572.92,153.78
	c0.05,0.94,0.08,1.71,0.12,2.5c0.84-0.1,1.67-0.2,2.53-0.31c-0.08-0.92-0.15-1.7-0.22-2.5
	C574.57,153.57,573.78,153.67,572.92,153.78z M565.04,353.16c0.05,0.84,0.09,1.6,0.14,2.45c0.85-0.07,1.59-0.14,2.34-0.2
	c-0.12-0.88-0.23-1.65-0.35-2.5C566.45,352.99,565.78,353.07,565.04,353.16z M427.85,36.24c-0.88,0.05-1.67,0.09-2.5,0.13
	c0.06,0.83,0.13,1.67,0.2,2.51c0.88-0.06,1.66-0.12,2.49-0.17C427.98,37.95,427.92,37.18,427.85,36.24z M569.06,358.59
	c0.82-0.05,1.51-0.09,2.19-0.13c0-0.87,0-1.63,0-2.44c-0.92,0.11-1.74,0.21-2.52,0.31C568.84,357.1,568.93,357.72,569.06,358.59z
	 M420.49,32.22c-0.2-0.97-0.37-1.75-0.53-2.52c-0.82,0.14-1.49,0.25-2.24,0.37c0.05,0.85,0.1,1.6,0.15,2.33
	C418.76,32.34,419.53,32.28,420.49,32.22z M657.86,564.05c-0.05-0.8-0.1-1.56-0.15-2.31c-0.86,0-1.7,0-2.6,0
	c0.13,0.9,0.26,1.78,0.39,2.68C656.34,564.29,657.1,564.17,657.86,564.05z M424.24,35.47c-0.1-0.91-0.19-1.74-0.27-2.5
	c-0.9,0-1.73,0-2.6,0c0.15,0.88,0.29,1.71,0.42,2.5C422.62,35.47,423.4,35.47,424.24,35.47z M502.01,430.65
	c-0.95,0.16-1.84,0.3-2.72,0.45c0.08,0.83,0.14,1.52,0.21,2.26c0.85-0.1,1.67-0.2,2.51-0.29
	C502.01,432.26,502.01,431.49,502.01,430.65z M513.22,176.74c0.18,0.93,0.33,1.68,0.5,2.5c0.78-0.07,1.53-0.13,2.37-0.21
	c-0.07-0.92-0.13-1.73-0.18-2.48C514.97,176.62,514.19,176.67,513.22,176.74z M506.51,180.1c0.92-0.16,1.66-0.28,2.4-0.4
	c-0.06-0.9-0.12-1.65-0.17-2.42c-0.86,0.08-1.62,0.15-2.4,0.23C506.4,178.32,506.45,179.09,506.51,180.1z M549.21,255.77
	c0.85,0,1.71,0,2.61,0c-0.15-0.92-0.29-1.76-0.43-2.65c-0.78,0.06-1.55,0.12-2.35,0.18C549.1,254.11,549.15,254.9,549.21,255.77z
	 M621.67,311.85c-0.94,0.14-1.71,0.26-2.51,0.38c0.11,0.77,0.22,1.46,0.33,2.23c0.85-0.06,1.6-0.12,2.32-0.18
	C621.76,313.43,621.72,312.72,621.67,311.85z M390.17,17.94c-0.95,0-1.72,0-2.59,0c0.17,0.89,0.32,1.69,0.47,2.48
	c0.79-0.08,1.47-0.15,2.23-0.23C390.25,19.46,390.21,18.77,390.17,17.94z M493.29,457.03c-0.89,0-1.69,0-2.5,0
	c0.09,0.88,0.16,1.62,0.24,2.39c0.82-0.09,1.57-0.16,2.38-0.25C493.36,458.39,493.32,457.71,493.29,457.03z M605.74,251.73
	c-1.03,0.18-1.86,0.33-2.73,0.48c0.15,0.8,0.3,1.55,0.45,2.36c0.81-0.08,1.55-0.16,2.28-0.23
	C605.74,253.42,605.74,252.64,605.74,251.73z M601.85,364.05c0.15,0.9,0.27,1.68,0.4,2.5c0.77,0,1.55,0,2.4,0
	c-0.08-0.88-0.16-1.72-0.23-2.5C603.59,364.05,602.82,364.05,601.85,364.05z M560.8,183.47c0.15,0.9,0.28,1.73,0.42,2.58
	c0.79-0.13,1.52-0.25,2.23-0.37c0-0.84,0-1.59,0-2.36C562.5,183.38,561.66,183.43,560.8,183.47z M397.26,17.33
	c-0.76,0.1-1.5,0.19-2.26,0.29c0.08,0.88,0.15,1.62,0.22,2.36c0.91-0.12,1.65-0.22,2.38-0.31C397.48,18.8,397.38,18.11,397.26,17.33
	z M568.1,182.95c0.1,0.93,0.17,1.7,0.26,2.55c0.83-0.09,1.66-0.18,2.5-0.27c-0.14-0.86-0.26-1.56-0.37-2.27
	C569.72,182.95,569.01,182.95,568.1,182.95z M662.68,574.79c-0.18-0.93-0.33-1.74-0.49-2.55c-0.79,0.05-1.55,0.11-2.39,0.16
	c0.1,0.83,0.2,1.64,0.29,2.39C660.99,574.79,661.77,574.79,662.68,574.79z M509.61,187.06c-0.04-0.9-0.08-1.73-0.12-2.61
	c-0.82,0.08-1.63,0.15-2.45,0.23c0.06,0.87,0.11,1.63,0.17,2.38C508.06,187.06,508.82,187.06,509.61,187.06z M663.21,610.57
	c0.89-0.1,1.63-0.18,2.36-0.26c-0.14-0.89-0.24-1.57-0.33-2.16c-0.89,0-1.66,0-2.44,0C662.92,608.94,663.04,609.64,663.21,610.57z
	 M458.88,102.13c-0.98,0.12-1.74,0.22-2.49,0.31c0.09,0.9,0.18,1.72,0.26,2.5c0.82-0.14,1.5-0.26,2.23-0.38
	C458.88,103.77,458.88,103.01,458.88,102.13z M634.02,497.66c0-0.95,0-1.72,0-2.52c-0.93,0.1-1.75,0.19-2.56,0.28
	c0.1,0.88,0.18,1.64,0.26,2.4C632.49,497.77,633.19,497.72,634.02,497.66z M564.29,179.67c0.08,0.87,0.16,1.69,0.23,2.5
	c0.91-0.13,1.66-0.23,2.43-0.34c-0.07-0.92-0.12-1.67-0.17-2.41C565.87,179.52,565.08,179.6,564.29,179.67z M606.41,427.86
	c-0.11-0.86-0.21-1.64-0.32-2.46c-0.78,0.07-1.54,0.13-2.37,0.2c0.08,0.85,0.16,1.67,0.24,2.49
	C604.81,428.01,605.52,427.95,606.41,427.86z M599.36,248.99c0.07,0.95,0.12,1.71,0.18,2.53c0.84-0.06,1.64-0.13,2.46-0.19
	c-0.23-0.95-0.43-1.75-0.63-2.55C600.67,248.85,600.06,248.92,599.36,248.99z M659.34,565.22c0.06,0.87,0.11,1.63,0.17,2.47
	c0.74-0.07,1.48-0.14,2.17-0.2c0-0.92,0-1.74,0-2.58C660.88,565.01,660.18,565.11,659.34,565.22z M562.04,248.38
	c-0.97,0.17-1.74,0.31-2.53,0.44c0.06,0.81,0.12,1.62,0.18,2.44c0.86-0.12,1.61-0.21,2.34-0.31
	C562.04,250.05,562.04,249.27,562.04,248.38z M267.57,103.23c-0.09-0.96-0.16-1.7-0.23-2.45c-0.93,0.09-1.69,0.17-2.44,0.24
	c0.09,0.87,0.17,1.64,0.25,2.4C265.96,103.35,266.66,103.3,267.57,103.23z M605.55,280.88c0.08,0.95,0.14,1.71,0.21,2.51
	c0.81-0.08,1.57-0.16,2.33-0.24c0-0.85,0-1.62,0-2.42C607.22,280.79,606.44,280.83,605.55,280.88z M530.28,80.49
	c0.93-0.12,1.7-0.22,2.51-0.33c0-0.88,0-1.74,0-2.61c-0.9,0.16-1.74,0.3-2.51,0.44C530.28,78.81,530.28,79.59,530.28,80.49z
	 M672.11,592.13c1.01-0.2,1.83-0.36,2.71-0.53c-0.2-0.81-0.38-1.53-0.56-2.3c-0.81,0.07-1.49,0.13-2.15,0.19
	C672.11,590.41,672.11,591.18,672.11,592.13z M589.6,194.39c0-0.88,0-1.69,0-2.55c-0.85,0.14-1.6,0.26-2.36,0.39
	c0,0.83,0,1.59,0,2.37C588.09,194.52,588.85,194.45,589.6,194.39z M490.78,499.26c0.95-0.12,1.7-0.21,2.47-0.3
	c-0.16-0.83-0.3-1.5-0.42-2.1c-0.86,0-1.56,0-2.27,0C490.63,497.65,490.69,498.36,490.78,499.26z M261.5,104.81
	c0.1,0.9,0.2,1.71,0.29,2.5c0.88-0.12,1.62-0.23,2.4-0.33c-0.08-0.88-0.15-1.61-0.22-2.34C263.12,104.71,262.42,104.75,261.5,104.81
	z M563.04,176.14c-1.07,0.18-1.88,0.31-2.68,0.45c0.15,0.79,0.27,1.46,0.4,2.17c0.83-0.09,1.57-0.17,2.28-0.25
	C563.04,177.68,563.04,176.98,563.04,176.14z M659.52,583.29c-0.79,0.07-1.54,0.13-2.34,0.2c0.04,0.82,0.08,1.59,0.13,2.4
	c0.87-0.08,1.69-0.15,2.47-0.22C659.69,584.81,659.61,584.05,659.52,583.29z M556.13,252.6c0.05,0.86,0.09,1.69,0.14,2.54
	c0.88-0.13,1.69-0.25,2.53-0.37c-0.07-0.91-0.13-1.67-0.18-2.4C557.7,252.46,556.91,252.53,556.13,252.6z M564.11,356.73
	c-0.89,0.12-1.63,0.23-2.37,0.33c0.07,0.87,0.14,1.62,0.2,2.36c0.9-0.05,1.66-0.1,2.41-0.15
	C564.27,358.43,564.2,357.66,564.11,356.73z M567.08,376.9c0.79-0.11,1.41-0.19,2.11-0.29c-0.03-0.76-0.06-1.45-0.1-2.24
	c-0.9,0.04-1.71,0.08-2.54,0.12C566.74,375.37,566.89,376.05,567.08,376.9z M567.72,178.3c0.85-0.09,1.67-0.17,2.43-0.25
	c0-0.96,0-1.79,0-2.61c-0.9,0.16-1.67,0.29-2.43,0.43C567.72,176.65,567.72,177.43,567.72,178.3z M554.72,278.01
	c0.1,0.96,0.18,1.71,0.27,2.5c0.83-0.15,1.56-0.29,2.21-0.41c0-0.87,0-1.56,0-2.27C556.35,277.89,555.59,277.95,554.72,278.01z
	 M434.91,75.32c-0.93,0.08-1.7,0.15-2.53,0.23c0.05,0.9,0.1,1.76,0.14,2.6c0.88-0.18,1.62-0.34,2.39-0.5
	C434.91,76.91,434.91,76.23,434.91,75.32z M597.68,356.34c0.78-0.12,1.51-0.23,2.34-0.36c-0.06-0.83-0.12-1.56-0.18-2.32
	c-0.9,0.09-1.64,0.16-2.35,0.23C597.55,354.78,597.61,355.54,597.68,356.34z M620.14,377.11c-0.92,0.09-1.76,0.17-2.57,0.25
	c0.08,0.86,0.16,1.64,0.24,2.46c0.8-0.07,1.56-0.14,2.34-0.21C620.14,378.74,620.14,377.96,620.14,377.11z M604.37,279.77
	c-0.06-0.84-0.11-1.65-0.17-2.45c-0.93,0.09-1.71,0.16-2.49,0.23c0.1,0.87,0.19,1.69,0.28,2.47
	C602.84,279.94,603.54,279.86,604.37,279.77z M394.05,21.22c-0.89,0.04-1.71,0.08-2.51,0.12c0.06,0.9,0.12,1.72,0.17,2.54
	c0.88-0.13,1.69-0.25,2.5-0.37C394.15,22.68,394.1,21.99,394.05,21.22z M627.64,492.09c0.07,0.84,0.14,1.6,0.21,2.39
	c0.82-0.07,1.58-0.14,2.35-0.21c0-0.94,0-1.79,0-2.64C629.35,491.79,628.58,491.92,627.64,492.09z M653.4,553.72
	c-0.12-0.82-0.22-1.49-0.32-2.17c-0.84,0-1.54,0-2.27,0c0.05,0.86,0.1,1.64,0.14,2.42C651.72,553.89,652.42,553.82,653.4,553.72z
	 M621.56,351.68c-0.92,0.09-1.76,0.17-2.58,0.24c0.08,0.89,0.15,1.71,0.22,2.54c0.83-0.11,1.59-0.21,2.37-0.31
	C621.56,353.3,621.56,352.52,621.56,351.68z M379.66,24.55c0-0.82,0-1.5,0-2.23c-0.91,0.08-1.73,0.15-2.51,0.22
	c0.08,0.93,0.16,1.74,0.23,2.56C378.16,24.91,378.85,24.75,379.66,24.55z M390.72,27.34c0-0.79,0-1.61,0-2.45
	c-0.89,0.11-1.72,0.2-2.51,0.3c0.08,0.93,0.15,1.75,0.21,2.52C389.23,27.58,389.92,27.47,390.72,27.34z M537.6,206.14
	c0.83-0.09,1.59-0.16,2.47-0.26c-0.05-0.87-0.1-1.68-0.15-2.48c-0.91,0.09-1.69,0.16-2.5,0.24
	C537.49,204.5,537.54,205.31,537.6,206.14z M516.84,175.47c0.94-0.11,1.7-0.21,2.51-0.3c0-0.89,0-1.74,0-2.61
	c-0.9,0.16-1.74,0.3-2.51,0.44C516.84,173.79,516.84,174.57,516.84,175.47z M646.47,503.93c0.95-0.26,1.74-0.47,2.52-0.68
	c-0.06-0.75-0.1-1.37-0.15-2.01c-0.86,0-1.69,0-2.53,0C646.36,502.14,646.41,502.96,646.47,503.93z M617.51,417.18
	c0,0.99,0,1.81,0,2.65c0.95-0.18,1.78-0.34,2.65-0.51c-0.19-0.78-0.36-1.45-0.56-2.25C618.87,417.1,618.18,417.14,617.51,417.18z
	 M248.56,133.75c-0.07-0.99-0.12-1.8-0.18-2.64c-0.82,0.19-1.56,0.37-2.42,0.57c0.09,0.7,0.18,1.38,0.27,2.07
	C247.03,133.75,247.72,133.75,248.56,133.75z M231.14,172.44c0.09,0.98,0.16,1.72,0.23,2.48c0.92-0.13,1.67-0.23,2.51-0.34
	c-0.11-0.8-0.2-1.47-0.3-2.14C232.71,172.44,231.99,172.44,231.14,172.44z M615.42,448.7c-0.05-0.86-0.1-1.68-0.15-2.48
	c-0.9,0.08-1.67,0.16-2.5,0.24c0.06,0.84,0.12,1.65,0.18,2.48C613.77,448.85,614.53,448.78,615.42,448.7z M588.33,206.52
	c0.11,0.95,0.19,1.71,0.28,2.47c0.83-0.08,1.52-0.15,2.24-0.23c0-0.84,0-1.62,0-2.47C590.01,206.38,589.24,206.44,588.33,206.52z
	 M622.35,348.13c0.05,0.87,0.1,1.64,0.15,2.45c0.77-0.08,1.55-0.16,2.38-0.25c0-0.88,0-1.74,0-2.61
	C624.05,347.85,623.27,347.98,622.35,348.13z M607.25,381.92c0.05,0.94,0.08,1.65,0.12,2.48c0.73-0.09,1.41-0.17,2.14-0.25
	c0-0.86,0-1.62,0-2.47C608.74,381.77,608.04,381.84,607.25,381.92z M580.54,173.57c-0.07-0.83-0.13-1.6-0.2-2.43
	c-0.81,0-1.58,0-2.4,0c0.06,0.88,0.12,1.74,0.18,2.61C578.97,173.69,579.74,173.63,580.54,173.57z M435.71,71.69
	c0.09,0.96,0.16,1.71,0.23,2.48c0.81-0.08,1.56-0.15,2.39-0.24c-0.04-0.85-0.07-1.61-0.11-2.39
	C437.34,71.6,436.57,71.64,435.71,71.69z M534.11,593.07c-0.13-0.97-0.24-1.79-0.36-2.69c-0.84,0.17-1.58,0.32-2.37,0.49
	c0.1,0.86,0.19,1.6,0.27,2.33C532.5,593.15,533.21,593.11,534.11,593.07z M666.18,617.62c0-0.87,0-1.72,0-2.6
	c-0.87,0.15-1.69,0.3-2.5,0.44c0.07,0.83,0.14,1.59,0.21,2.34C664.72,617.73,665.42,617.68,666.18,617.62z M601.61,414.83
	c-1,0.08-1.8,0.14-2.62,0.21c0.16,0.88,0.3,1.68,0.45,2.48c0.83-0.15,1.5-0.27,2.17-0.39C601.61,416.37,601.61,415.68,601.61,414.83
	z M320.47,48.03c-0.05-0.95-0.1-1.72-0.15-2.53c-0.81,0.14-1.55,0.26-2.33,0.4c0.05,0.81,0.1,1.51,0.15,2.24
	C318.93,48.1,319.64,48.07,320.47,48.03z M602.41,342.83c-0.75,0-1.49,0-2.22,0c0,0.96,0,1.79,0,2.62c0.92-0.18,1.75-0.34,2.64-0.51
	C602.68,344.23,602.55,343.56,602.41,342.83z M542.5,387.28c0.13,0.91,0.25,1.67,0.37,2.53c0.68-0.08,1.36-0.17,2.09-0.26
	c0-0.91,0-1.68,0-2.46C544.15,387.16,543.38,387.21,542.5,387.28z M618.89,489.15c-0.68,0.07-1.48,0.15-2.29,0.23
	c0.09,0.92,0.16,1.73,0.24,2.54c0.8-0.09,1.49-0.18,2.17-0.26C618.96,490.85,618.93,490.08,618.89,489.15z M629.45,389.65
	c0.93-0.08,1.73-0.14,2.51-0.2c-0.09-0.91-0.16-1.68-0.23-2.47c-0.85,0.09-1.64,0.18-2.42,0.26
	C629.36,388.06,629.4,388.76,629.45,389.65z M465.29,49.99c-0.15-0.91-0.28-1.64-0.41-2.43c-0.82,0.09-1.51,0.17-2.21,0.25
	c0.05,0.83,0.1,1.58,0.15,2.33C463.63,50.09,464.32,50.05,465.29,49.99z M617.73,390.79c-0.2-0.99-0.34-1.73-0.5-2.55
	c-0.82,0.08-1.55,0.16-2.21,0.22c0,0.9,0,1.6,0,2.33C615.9,390.79,616.73,390.79,617.73,390.79z M555.91,126.09
	c0.18,0.82,0.35,1.64,0.55,2.55c0.7-0.05,1.38-0.1,2.05-0.15c-0.06-0.93-0.12-1.76-0.17-2.56
	C557.49,125.99,556.72,126.04,555.91,126.09z M572.28,146.58c0,0.96,0,1.67,0,2.44c0.94-0.06,1.83-0.12,2.67-0.18
	c-0.18-0.83-0.34-1.53-0.5-2.26C573.76,146.58,573.07,146.58,572.28,146.58z M611.49,445.36c0-0.96,0-1.8,0-2.65
	c-0.9,0.17-1.72,0.32-2.53,0.47c0.07,0.81,0.14,1.56,0.21,2.34C609.92,445.47,610.6,445.42,611.49,445.36z M626.03,528.19
	c-0.94,0.16-1.69,0.28-2.54,0.42c0.13,0.74,0.24,1.41,0.36,2.13c0.78,0,1.46,0,2.18,0C626.03,529.89,626.03,529.13,626.03,528.19z
	 M618.9,391.7c0,1.05,0,1.88,0,2.72c0.94-0.18,1.77-0.34,2.68-0.52c-0.2-0.8-0.38-1.52-0.55-2.2
	C620.27,391.7,619.66,391.7,618.9,391.7z M466.18,50.79c0.22,0.92,0.41,1.72,0.6,2.5c0.79-0.06,1.47-0.11,2.2-0.16
	c-0.04-0.83-0.08-1.6-0.11-2.35C468.01,50.79,467.17,50.79,466.18,50.79z M508.71,53.32c0-0.84,0-1.67,0-2.51
	c-0.88,0.14-1.65,0.26-2.42,0.38c0.06,0.8,0.13,1.56,0.19,2.33C507.27,53.45,507.97,53.39,508.71,53.32z M602.79,302.82
	c-0.86,0.07-1.63,0.13-2.44,0.19c0.1,0.88,0.18,1.68,0.27,2.45c0.86-0.09,1.54-0.16,2.28-0.24
	C602.86,304.37,602.83,303.6,602.79,302.82z M459.42,54.04c0.93-0.11,1.67-0.2,2.46-0.3c-0.07-0.87-0.13-1.62-0.19-2.36
	c-0.9,0.07-1.67,0.13-2.44,0.18C459.3,52.38,459.35,53.14,459.42,54.04z M606.89,295.27c0,0.98,0,1.81,0,2.7
	c0.95-0.19,1.82-0.37,2.7-0.55c-0.2-0.81-0.37-1.48-0.56-2.25C608.3,295.21,607.62,295.24,606.89,295.27z M491.23,477.34
	c-0.1-0.84-0.19-1.51-0.26-2.1c-0.86,0-1.55,0-2.32,0c0.07,0.94,0.13,1.8,0.2,2.66C489.68,477.69,490.36,477.54,491.23,477.34z
	 M617.12,510c-0.13-0.99-0.23-1.78-0.33-2.52c-0.86,0.07-1.55,0.12-2.24,0.17c0,0.78,0,1.55,0,2.34
	C615.39,510,616.15,510,617.12,510z M493.17,69.15c0.94-0.06,1.71-0.11,2.51-0.17c-0.1-0.86-0.19-1.67-0.28-2.46
	c-0.88,0.1-1.62,0.18-2.34,0.25C493.1,67.6,493.13,68.3,493.17,69.15z M626.91,527.16c0.79-0.13,1.53-0.25,2.33-0.38
	c-0.05-0.82-0.09-1.51-0.13-2.24c-0.89,0.06-1.67,0.11-2.41,0.15C626.78,525.57,626.84,526.34,626.91,527.16z M615.66,395.66
	c0.09,0.87,0.16,1.61,0.24,2.39c0.84-0.09,1.58-0.17,2.29-0.25c0-0.94,0-1.76,0-2.59C617.32,395.36,616.56,395.5,615.66,395.66z
	 M546.52,237.89c0-0.85,0-1.68,0-2.53c-0.95,0.05-1.84,0.1-2.73,0.14c0.16,0.81,0.31,1.57,0.47,2.38
	C545,237.89,545.76,237.89,546.52,237.89z M440.25,55.64c-0.07-0.89-0.13-1.62-0.19-2.34c-0.85,0.07-1.55,0.13-2.31,0.2
	c0.05,0.86,0.09,1.67,0.14,2.49C438.68,55.87,439.36,55.77,440.25,55.64z M608.06,475.75c0.07,0.92,0.13,1.75,0.2,2.58
	c0.88-0.2,1.62-0.36,2.35-0.53c-0.05-0.83-0.09-1.54-0.13-2.27C609.62,475.61,608.84,475.68,608.06,475.75z M594.35,233.7
	c0.89-0.05,1.63-0.09,2.39-0.13c-0.08-0.94-0.14-1.75-0.21-2.56c-0.88,0.07-1.63,0.13-2.41,0.19
	C594.19,232.01,594.26,232.77,594.35,233.7z M606.22,298.77c-0.93,0.18-1.75,0.33-2.54,0.48c0.1,0.88,0.18,1.63,0.26,2.36
	c0.85-0.09,1.54-0.17,2.28-0.25C606.22,300.54,606.22,299.75,606.22,298.77z M598.9,328.41c0.09,0.96,0.16,1.71,0.24,2.5
	c0.84-0.1,1.59-0.19,2.37-0.28c-0.04-0.89-0.07-1.67-0.11-2.44C600.55,328.26,599.78,328.33,598.9,328.41z M535.26,232.77
	c-1.05,0.07-1.9,0.13-2.76,0.19c0.19,0.86,0.35,1.61,0.53,2.45c0.8-0.12,1.54-0.23,2.23-0.33
	C535.26,234.29,535.26,233.65,535.26,232.77z M601.21,407.39c-0.93,0.25-1.73,0.46-2.57,0.68c0.08,0.76,0.15,1.43,0.22,2.1
	c0.86,0,1.61,0,2.35,0C601.21,409.26,601.21,408.43,601.21,407.39z M484.34,63.86c-0.88,0.08-1.61,0.15-2.34,0.22
	c0.05,0.95,0.1,1.7,0.14,2.45c0.93-0.09,1.76-0.18,2.59-0.26C484.6,65.48,484.49,64.8,484.34,63.86z M622.93,368.6
	c-0.16-0.9-0.29-1.63-0.43-2.43c-0.81,0.04-1.5,0.07-2.18,0.11c0,0.93,0,1.74,0,2.55C621.2,368.75,621.97,368.68,622.93,368.6z
	 M556.72,231.11c-0.86,0-1.7,0-2.56,0c0.09,0.9,0.17,1.72,0.25,2.55c0.82-0.17,1.63-0.35,2.44-0.52
	C556.8,232.39,556.76,231.77,556.72,231.11z M566.57,369.65c0.79-0.06,1.46-0.12,2.07-0.16c0-0.96,0-1.76,0-2.56
	c-0.93,0.18-1.75,0.34-2.55,0.49C566.25,368.15,566.38,368.76,566.57,369.65z M650.99,511.72c0,0.87,0,1.64,0,2.47
	c0.89-0.08,1.71-0.15,2.59-0.23c-0.13-0.84-0.25-1.57-0.37-2.37C652.39,511.64,651.71,511.68,650.99,511.72z M655.81,582.43
	c0-0.88,0-1.76,0-2.69c-0.89,0.21-1.64,0.38-2.47,0.58c0.07,0.73,0.13,1.41,0.19,2.11C654.33,582.43,655.03,582.43,655.81,582.43z
	 M564.94,366.39c-0.18-0.82-0.36-1.63-0.53-2.42c-0.82,0.1-1.49,0.17-2.11,0.25c0,0.88,0,1.65,0,2.49
	C563.2,366.6,564.03,366.5,564.94,366.39z M426.37,61.7c-0.82,0.11-1.57,0.21-2.23,0.29c0,0.88,0,1.65,0,2.46
	c0.88-0.19,1.63-0.35,2.37-0.51C426.46,63.16,426.42,62.46,426.37,61.7z M429.59,81.58c0.77,0,1.47,0,2.09,0c0-0.91,0-1.73,0-2.56
	c-0.85,0.16-1.6,0.31-2.51,0.48C429.32,80.23,429.45,80.89,429.59,81.58z M608.9,403.38c0.06,0.81,0.13,1.66,0.2,2.54
	c0.83-0.18,1.63-0.35,2.44-0.52c-0.05-0.75-0.09-1.38-0.13-2.02C610.61,403.38,609.84,403.38,608.9,403.38z M534.34,83.42
	c0.83,0,1.59,0,2.41,0c-0.1-0.81-0.19-1.49-0.29-2.25c-0.86,0.04-1.62,0.08-2.46,0.12C534.13,82.09,534.23,82.75,534.34,83.42z
	 M441.17,62.93c-0.22-0.92-0.38-1.59-0.57-2.37c-0.73,0.05-1.42,0.1-2.16,0.16c0.06,0.79,0.11,1.47,0.16,2.22
	C439.42,62.93,440.18,62.93,441.17,62.93z M436.62,81.34c0.84-0.09,1.61-0.17,2.38-0.25c-0.16-0.89-0.29-1.64-0.43-2.43
	c-0.79,0.13-1.45,0.24-2.11,0.35C436.52,79.89,436.57,80.59,436.62,81.34z M626.9,498.1c-0.05-0.88-0.09-1.64-0.13-2.42
	c-0.95,0.07-1.78,0.14-2.7,0.21c0.22,0.85,0.4,1.58,0.6,2.37C625.47,498.2,626.15,498.15,626.9,498.1z M660.2,619.11
	c0.16,0.85,0.3,1.62,0.45,2.44c0.86-0.06,1.54-0.11,2.29-0.17c-0.09-0.86-0.16-1.6-0.23-2.27
	C661.83,619.11,661.13,619.11,660.2,619.11z M526.14,167.52c-0.24-0.9-0.43-1.62-0.64-2.39c-0.81,0.07-1.5,0.12-2.21,0.18
	c0.05,0.89,0.1,1.67,0.14,2.41C524.3,167.66,525.08,167.6,526.14,167.52z M493.24,153.3c0.17,0.87,0.33,1.67,0.48,2.45
	c0.82-0.12,1.49-0.22,2.22-0.33c-0.07-0.86-0.13-1.6-0.19-2.33C494.84,153.17,494.07,153.23,493.24,153.3z M597.31,309.26
	c0.84-0.09,1.52-0.16,2.26-0.24c-0.05-0.84-0.09-1.53-0.13-2.31c-0.87,0.05-1.64,0.1-2.44,0.15
	C597.1,307.65,597.19,308.34,597.31,309.26z M624.17,372.13c0.98-0.19,1.74-0.34,2.52-0.49c-0.12-0.72-0.22-1.34-0.35-2.06
	c-0.81,0.05-1.56,0.09-2.33,0.13C624.08,370.59,624.12,371.3,624.17,372.13z M551.23,361.54c0,0.99,0,1.81,0,2.66
	c0.91-0.19,1.73-0.36,2.55-0.53c-0.16-0.77-0.3-1.43-0.45-2.13C552.64,361.54,552.03,361.54,551.23,361.54z M634.94,509.68
	c-0.74,0.05-1.43,0.09-2.23,0.14c0.08,0.85,0.16,1.59,0.23,2.35c0.94-0.07,1.71-0.12,2.52-0.18
	C635.28,511.16,635.12,510.48,634.94,509.68z M605.46,236.29c0.89-0.1,1.57-0.17,2.26-0.24c-0.06-0.84-0.1-1.54-0.15-2.31
	c-0.92,0.06-1.74,0.11-2.61,0.17C605.13,234.73,605.27,235.41,605.46,236.29z M429.12,92.67c-0.08-0.88-0.14-1.57-0.22-2.36
	c-0.82,0.07-1.64,0.15-2.47,0.22c0.15,0.87,0.28,1.62,0.41,2.38C427.61,92.83,428.29,92.75,429.12,92.67z M610.04,428.48
	c-0.92,0.1-1.68,0.19-2.47,0.28c0.17,0.87,0.31,1.62,0.46,2.37c0.74-0.08,1.35-0.15,2.01-0.22
	C610.04,430.13,610.04,429.44,610.04,428.48z M409.66,23.24c0.18,0.83,0.34,1.57,0.51,2.4c0.76-0.06,1.43-0.11,2.21-0.17
	c-0.08-0.81-0.15-1.5-0.22-2.23C411.29,23.24,410.51,23.24,409.66,23.24z M598.28,402.97c0.84,0,1.53,0,2.29,0
	c-0.06-0.86-0.11-1.7-0.17-2.54c-0.91,0.11-1.73,0.2-2.53,0.3C598.01,401.49,598.14,402.18,598.28,402.97z M590.41,190.86
	c0.8-0.14,1.53-0.28,2.38-0.43c-0.07-0.74-0.14-1.43-0.22-2.2c-0.83,0.05-1.56,0.09-2.27,0.14
	C590.34,189.3,590.38,190.06,590.41,190.86z M527.62,587.55c0.06,0.99,0.11,1.73,0.16,2.48c0.82-0.1,1.51-0.18,2.3-0.28
	c-0.12-0.79-0.23-1.53-0.35-2.34C529.01,587.45,528.4,587.49,527.62,587.55z M669.09,611.41c-0.82,0.06-1.55,0.11-2.23,0.16
	c0,0.98,0,1.76,0,2.59c0.88-0.18,1.69-0.34,2.53-0.51C669.29,612.88,669.2,612.21,669.09,611.41z M598.38,363.38
	c0.91-0.04,1.67-0.07,2.54-0.11c-0.2-0.86-0.37-1.57-0.56-2.34c-0.8,0.08-1.47,0.15-2.14,0.22
	C598.28,361.92,598.32,362.54,598.38,363.38z M614.42,520.89c-0.05-0.8-0.1-1.57-0.15-2.35c-0.88,0.07-1.7,0.13-2.53,0.19
	c0.17,0.83,0.32,1.57,0.46,2.31C612.98,520.99,613.67,520.94,614.42,520.89z M661.05,600.05c-0.1-0.79-0.19-1.56-0.28-2.35
	c-0.81,0.06-1.57,0.12-2.34,0.18c0.07,0.86,0.14,1.63,0.2,2.46C659.46,600.25,660.22,600.15,661.05,600.05z M612.07,399.43
	c0.22,0.87,0.38,1.54,0.57,2.33c0.77-0.04,1.51-0.08,2.3-0.12c-0.09-0.9-0.16-1.65-0.23-2.38
	C613.81,399.32,613.04,399.37,612.07,399.43z M559.67,599.28c-0.8,0.07-1.47,0.12-2.19,0.18c0,0.87,0,1.64,0,2.47
	c0.82-0.16,1.57-0.3,2.39-0.46C559.81,600.75,559.75,600.07,559.67,599.28z M444.4,58.97c-0.11-0.9-0.2-1.65-0.3-2.44
	c-0.8,0.13-1.54,0.26-2.35,0.4c0.07,0.76,0.12,1.42,0.18,2.05C442.81,58.97,443.51,58.97,444.4,58.97z M622.62,521.22
	c0.26,0.98,0.45,1.7,0.66,2.47c0.8-0.08,1.48-0.15,2.17-0.22c-0.05-0.83-0.1-1.53-0.15-2.25
	C624.45,521.22,623.68,521.22,622.62,521.22z M645.59,576.15c0.91-0.17,1.59-0.29,2.38-0.44c-0.08-0.74-0.16-1.42-0.25-2.17
	c-0.8,0.04-1.48,0.08-2.13,0.12C645.59,574.52,645.59,575.23,645.59,576.15z M426.72,21.89c-1,0.08-1.76,0.14-2.56,0.2
	c0.14,0.79,0.27,1.55,0.39,2.26c0.76,0,1.45,0,2.17,0C426.72,23.53,426.72,22.76,426.72,21.89z M619.21,410.01
	c-0.75,0-1.51,0-2.34,0c0.08,0.9,0.15,1.65,0.22,2.4c0.93-0.06,1.7-0.11,2.57-0.17C619.5,411.45,619.36,410.76,619.21,410.01z
	 M614.67,441.45c-0.09-0.73-0.19-1.5-0.3-2.35c-0.89,0.08-1.57,0.15-2.3,0.21c0.04,0.95,0.08,1.77,0.12,2.6
	C613.05,441.75,613.75,441.62,614.67,441.45z M683.14,605.4c-0.04-0.83-0.08-1.67-0.12-2.59c-0.85,0.17-1.59,0.31-2.36,0.46
	c0.06,0.84,0.11,1.58,0.17,2.32C681.68,605.52,682.38,605.46,683.14,605.4z M510.15,566.24c-0.17-0.85-0.32-1.6-0.48-2.38
	c-0.79,0.08-1.46,0.15-2.12,0.22c0,0.89,0,1.65,0,2.42C508.4,566.41,509.17,566.34,510.15,566.24z M604.45,269.02
	c0.98-0.1,1.74-0.18,2.54-0.27c-0.1-0.81-0.2-1.55-0.31-2.38c-0.84,0.07-1.57,0.13-2.23,0.19
	C604.45,267.47,604.45,268.17,604.45,269.02z M460.51,68.58c0.94-0.19,1.81-0.37,2.69-0.55c-0.21-0.82-0.37-1.49-0.56-2.21
	c-0.8,0.07-1.49,0.13-2.13,0.18C460.51,66.93,460.51,67.71,460.51,68.58z M641.35,525.79c0.85,0,1.66,0,2.5,0
	c-0.13-0.85-0.25-1.62-0.37-2.46c-0.8,0.07-1.6,0.13-2.38,0.2C641.19,524.36,641.27,525.05,641.35,525.79z M434.13,23.93
	c-0.14-0.9-0.27-1.73-0.41-2.64c-0.78,0.18-1.51,0.35-2.23,0.51c0.06,0.83,0.12,1.51,0.16,2.13
	C432.54,23.93,433.3,23.93,434.13,23.93z M318.56,53.04c0,0.93,0,1.74,0,2.59c0.83-0.18,1.57-0.35,2.35-0.52
	c-0.07-0.77-0.13-1.44-0.19-2.07C319.92,53.04,319.25,53.04,318.56,53.04z M437.01,19.61c-0.05-0.72-0.1-1.39-0.15-2.08
	c-0.86,0-1.61,0-2.42,0c0.12,0.86,0.22,1.62,0.33,2.42C435.52,19.84,436.2,19.74,437.01,19.61z M365.5,55.03
	c0.84-0.08,1.66-0.17,2.53-0.26c-0.11-0.8-0.21-1.53-0.32-2.34c-0.8,0.04-1.48,0.07-2.21,0.11C365.5,53.43,365.5,54.2,365.5,55.03z
	 M591.78,220.79c-0.74,0.05-1.42,0.09-2.21,0.14c0.07,0.87,0.12,1.62,0.17,2.28c0.9,0,1.67,0,2.59,0
	C592.14,222.37,591.98,221.64,591.78,220.79z M623.16,397.39c0.79-0.12,1.46-0.22,2.06-0.3c-0.05-0.91-0.1-1.68-0.15-2.45
	c-0.83,0.06-1.65,0.12-2.47,0.18C622.78,395.66,622.95,396.41,623.16,397.39z M644.81,526.59c0.18,0.88,0.34,1.68,0.5,2.48
	c0.79-0.12,1.46-0.22,2.22-0.34c-0.07-0.84-0.12-1.57-0.18-2.3C646.43,526.49,645.66,526.54,644.81,526.59z M406.69,27.46
	c0.04,0.89,0.08,1.57,0.11,2.26c0.91,0,1.68,0,2.49,0c-0.17-0.89-0.32-1.72-0.49-2.61C408.12,27.23,407.51,27.33,406.69,27.46z
	 M522.8,158.08c0.07,0.99,0.12,1.73,0.17,2.51c0.9-0.14,1.65-0.25,2.39-0.36c-0.16-0.84-0.28-1.53-0.42-2.3
	C524.24,157.98,523.62,158.03,522.8,158.08z M614.04,376.23c0.73,0,1.48,0,2.33,0c-0.04-0.87-0.07-1.63-0.11-2.42
	c-0.94,0.06-1.77,0.12-2.68,0.18C613.74,374.79,613.88,375.47,614.04,376.23z M612.67,499.58c-0.16-0.83-0.3-1.56-0.46-2.38
	c-0.85,0.08-1.59,0.14-2.33,0.21c0.13,0.86,0.23,1.54,0.32,2.18C611.08,499.58,611.84,499.58,612.67,499.58z M471.28,147.99
	c0,0.88,0,1.7,0,2.54c0.86-0.11,1.62-0.2,2.49-0.31c-0.1-0.78-0.19-1.51-0.27-2.23C472.63,147.99,471.93,147.99,471.28,147.99z
	 M684.71,619.84c-0.14-0.91-0.27-1.74-0.4-2.63c-0.8,0.15-1.56,0.3-2.38,0.45c0.06,0.77,0.12,1.52,0.17,2.18
	C683.05,619.84,683.88,619.84,684.71,619.84z M595.3,411.92c0.09,0.91,0.16,1.59,0.23,2.19c0.87,0,1.63,0,2.5,0
	c-0.2-0.84-0.36-1.55-0.51-2.19C596.74,411.92,596.12,411.92,595.3,411.92z M460.63,107.85c0.92,0,1.69,0,2.46,0
	c-0.06-0.9-0.11-1.72-0.17-2.54c-0.88,0.22-1.68,0.42-2.46,0.61C460.52,106.62,460.57,107.18,460.63,107.85z M603.1,429.04
	c-0.89,0.24-1.68,0.45-2.45,0.65c0.05,0.81,0.1,1.47,0.14,2.13c0.88-0.06,1.63-0.12,2.3-0.17
	C603.1,430.76,603.1,429.94,603.1,429.04z M406.24,22.54c0.92-0.05,1.67-0.08,2.46-0.12c-0.15-0.88-0.29-1.68-0.43-2.46
	c-0.83,0.06-1.51,0.1-2.27,0.15C406.08,20.87,406.15,21.63,406.24,22.54z M462.91,136.84c0.88-0.09,1.63-0.18,2.42-0.26
	c-0.08-0.85-0.15-1.61-0.22-2.35c-0.86,0.06-1.6,0.12-2.33,0.17C462.82,135.21,462.86,135.91,462.91,136.84z M548.97,598.96
	c-0.15-0.89-0.28-1.62-0.42-2.4c-0.82,0.07-1.5,0.13-2.14,0.19c0,0.89,0,1.66,0,2.44C547.23,599.12,548,599.05,548.97,598.96z
	 M651.62,561.1c0.92-0.05,1.68-0.09,2.56-0.13c-0.2-0.85-0.37-1.56-0.55-2.33c-0.8,0.09-1.47,0.16-2.14,0.23
	C651.54,559.64,651.57,560.26,651.62,561.1z M619.39,452.01c-0.12-0.88-0.23-1.64-0.34-2.41c-0.86,0.07-1.62,0.13-2.49,0.21
	c0.12,0.79,0.22,1.5,0.33,2.2C617.76,452.01,618.46,452.01,619.39,452.01z M555.38,256.13c-0.95,0.15-1.76,0.27-2.54,0.39
	c0.17,0.92,0.3,1.68,0.44,2.45c0.77-0.13,1.44-0.24,2.1-0.35C555.38,257.79,555.38,257.09,555.38,256.13z M595.48,248.17
	c0.86-0.11,1.62-0.2,2.41-0.3c-0.04-0.82-0.08-1.64-0.12-2.49c-0.76,0.09-1.52,0.19-2.29,0.28
	C595.48,246.49,595.48,247.31,595.48,248.17z M504.51,47.74c-0.93,0-1.63,0-2.4,0c0.09,0.91,0.17,1.75,0.25,2.6
	c0.8-0.18,1.56-0.35,2.31-0.52C504.61,49.08,504.57,48.46,504.51,47.74z M522.14,164.18c-0.13-0.86-0.24-1.56-0.37-2.37
	c-0.78,0.04-1.54,0.08-2.34,0.12c0.05,0.9,0.09,1.66,0.12,2.44C520.42,164.3,521.19,164.25,522.14,164.18z M442,74.88
	c-0.96,0.07-1.72,0.13-2.52,0.19c0.13,0.79,0.24,1.46,0.35,2.14c0.83,0,1.59,0,2.4,0C442.15,76.44,442.08,75.75,442,74.88z
	 M590.23,201.5c-0.13-0.85-0.23-1.52-0.35-2.3c-0.77,0.04-1.45,0.08-2.17,0.12c0,0.98,0,1.8,0,2.66
	C588.54,201.83,589.3,201.68,590.23,201.5z M553.43,303.54c0.06,0.91,0.11,1.67,0.16,2.43c0.82-0.15,1.49-0.27,2.29-0.41
	c-0.08-0.73-0.16-1.41-0.23-2.13C554.91,303.47,554.28,303.5,553.43,303.54z M648.04,533.83c-0.77,0-1.6,0-2.53,0
	c0.18,0.78,0.35,1.51,0.54,2.29c0.83-0.06,1.52-0.1,2.22-0.15C648.19,535.22,648.12,534.6,648.04,533.83z M522.59,171.26
	c-0.14-0.85-0.26-1.53-0.39-2.29c-0.79,0.04-1.47,0.08-2.17,0.12c0,0.97,0,1.8,0,2.65C520.88,171.58,521.64,171.44,522.59,171.26z
	 M675.2,596.42c-0.94,0.1-1.7,0.17-2.49,0.26c0.13,0.78,0.24,1.45,0.35,2.11c0.85,0,1.61,0,2.39,0
	C675.37,598,675.29,597.3,675.2,596.42z M260.82,110.89c-0.08-0.98-0.13-1.71-0.19-2.45c-0.85,0.09-1.54,0.16-2.31,0.25
	c0.12,0.82,0.23,1.56,0.35,2.36C259.39,111,260.01,110.95,260.82,110.89z M553.27,343.39c0.12,0.76,0.23,1.47,0.33,2.09
	c0.87,0,1.56,0,2.32,0c-0.08-0.92-0.15-1.75-0.23-2.59C554.87,343.07,554.12,343.22,553.27,343.39z M369.58,16.07
	c0.05,0.94,0.09,1.63,0.13,2.41c0.8-0.07,1.53-0.14,2.38-0.22c-0.09-0.79-0.16-1.48-0.24-2.2
	C371.08,16.07,370.38,16.07,369.58,16.07z M635.46,432.32c-0.06-0.83-0.11-1.52-0.17-2.28c-0.87,0.05-1.69,0.09-2.52,0.13
	c0.17,0.91,0.31,1.67,0.46,2.45C633.93,432.53,634.6,432.43,635.46,432.32z M236.31,161.45c-0.93,0-1.63,0-2.45,0
	c0.06,0.83,0.12,1.58,0.18,2.4c0.91-0.11,1.66-0.19,2.43-0.28C236.41,162.81,236.36,162.18,236.31,161.45z M533.45,76.74
	c0.9-0.18,1.73-0.34,2.57-0.51c-0.17-0.76-0.32-1.42-0.48-2.17c-0.77,0.06-1.45,0.11-2.09,0.15
	C533.45,75.16,533.45,75.94,533.45,76.74z M548.68,345.98c-0.06-0.87-0.11-1.62-0.16-2.39c-0.95,0.09-1.77,0.17-2.61,0.25
	c0.22,0.85,0.39,1.52,0.58,2.28C547.23,346.07,547.91,346.02,548.68,345.98z M557.86,314.01c0.04,0.86,0.08,1.61,0.12,2.41
	c0.92-0.15,1.74-0.28,2.6-0.41c-0.17-0.76-0.31-1.42-0.47-2.14C559.3,313.92,558.62,313.96,557.86,314.01z M596.09,379.3
	c0.08,0.94,0.15,1.69,0.22,2.43c0.83-0.13,1.5-0.24,2.32-0.37c-0.13-0.73-0.25-1.39-0.37-2.06
	C597.53,379.3,596.91,379.3,596.09,379.3z M501.27,173.25c-0.11-0.94-0.2-1.72-0.29-2.54c-0.8,0.17-1.54,0.33-2.28,0.48
	c0.07,0.76,0.13,1.38,0.19,2.05C499.66,173.25,500.36,173.25,501.27,173.25z M662.38,600.96c0.11,0.8,0.22,1.58,0.33,2.38
	c0.77-0.06,1.38-0.1,2.1-0.15c-0.07-0.88-0.13-1.62-0.19-2.36C663.83,600.88,663.21,600.91,662.38,600.96z M438.76,39.02
	c-0.76,0.04-1.51,0.07-2.21,0.11c0,0.96,0,1.78,0,2.65c0.91-0.23,1.71-0.43,2.46-0.62C438.92,40.39,438.85,39.77,438.76,39.02z
	 M592.07,209.86c0.15,0.9,0.27,1.63,0.39,2.37c0.83-0.1,1.52-0.19,2.33-0.29c-0.07-0.77-0.13-1.5-0.2-2.23
	C593.72,209.76,593.02,209.8,592.07,209.86z M608.24,449.21c-0.1-0.96-0.17-1.71-0.24-2.45c-0.93,0.07-1.7,0.13-2.57,0.2
	c0.21,0.85,0.39,1.57,0.55,2.25C606.73,449.21,607.35,449.21,608.24,449.21z M649.66,595.26c-0.75,0-1.43,0-2.18,0
	c0.06,0.81,0.11,1.49,0.15,2.13c0.91,0,1.66,0,2.45,0C649.92,596.6,649.79,595.92,649.66,595.26z M570.7,351.24
	c-0.08-0.84-0.14-1.57-0.2-2.21c-0.91,0-1.67,0-2.55,0c0.19,0.83,0.36,1.55,0.55,2.37C569.26,351.35,569.93,351.3,570.7,351.24z
	 M428.9,74.54c0.74-0.04,1.42-0.07,2.23-0.11c-0.05-0.89-0.1-1.65-0.14-2.45c-0.96,0.09-1.79,0.17-2.64,0.25
	C428.54,73.07,428.71,73.75,428.9,74.54z M657.78,601.32c-0.85,0.2-1.59,0.38-2.34,0.56c0,0.74,0,1.42,0,2.18
	c0.84-0.05,1.59-0.1,2.34-0.15C657.78,603.06,657.78,602.23,657.78,601.32z M570.88,174.63c0.95-0.19,1.83-0.37,2.72-0.55
	c-0.2-0.81-0.37-1.48-0.56-2.23c-0.76,0.03-1.43,0.06-2.16,0.1C570.88,172.93,570.88,173.76,570.88,174.63z M628.29,430.58
	c-0.91,0.15-1.63,0.26-2.36,0.38c0.07,0.91,0.14,1.65,0.2,2.46c0.81-0.14,1.48-0.26,2.16-0.38
	C628.29,432.24,628.29,431.54,628.29,430.58z M433.68,45.24c0.77-0.04,1.51-0.09,2.26-0.13c-0.05-1-0.09-1.8-0.12-2.62
	c-1,0.2-1.82,0.36-2.65,0.53C433.35,43.78,433.5,44.45,433.68,45.24z M536.62,209.74c0-0.91,0-1.67,0-2.48
	c-0.84,0.1-1.59,0.18-2.4,0.28c0.16,0.85,0.3,1.59,0.44,2.34C535.4,209.82,536,209.78,536.62,209.74z M634.74,504.82
	c-0.14-0.87-0.25-1.56-0.38-2.39c-0.7,0.06-1.36,0.11-2.11,0.17c0.04,0.89,0.08,1.64,0.11,2.41
	C633.17,504.95,633.86,504.89,634.74,504.82z M652.82,546.55c-0.05-0.86-0.09-1.61-0.14-2.41c-0.86,0.1-1.61,0.19-2.4,0.29
	c0.1,0.82,0.19,1.48,0.29,2.25C651.36,546.63,652.05,546.6,652.82,546.55z M634.79,491.55c0.09,0.93,0.16,1.74,0.24,2.53
	c0.83-0.12,1.59-0.22,2.4-0.33c-0.17-0.85-0.33-1.65-0.48-2.43C636.21,491.4,635.59,491.47,634.79,491.55z M313.95,42.46
	c0.19,0.93,0.33,1.6,0.49,2.33c0.75-0.09,1.35-0.17,1.97-0.24c-0.04-0.82-0.08-1.51-0.12-2.26
	C315.52,42.34,314.84,42.39,313.95,42.46z M602.66,382.01c-0.95,0.24-1.7,0.43-2.54,0.64c0.09,0.69,0.19,1.37,0.28,2.09
	c0.8,0,1.54,0,2.25,0C602.66,383.83,602.66,383,602.66,382.01z M648.8,543.18c0-0.89,0-1.67,0-2.47c-0.8,0.15-1.48,0.28-2.29,0.43
	c0.06,0.71,0.11,1.37,0.16,2.04C647.45,543.18,648.13,543.18,648.8,543.18z M585.06,416.4c0.09,0.77,0.16,1.42,0.23,2.01
	c0.83,0,1.43,0,2.17,0c-0.05-0.82-0.09-1.55-0.14-2.35C586.51,416.18,585.84,416.28,585.06,416.4z M604.7,492.81
	c0-0.93,0-1.68,0-2.49c-0.84,0.17-1.59,0.32-2.35,0.48c0.09,0.73,0.17,1.34,0.25,2.01C603.31,492.81,603.92,492.81,604.7,492.81z
	 M561.43,243.8c-0.15-0.9-0.27-1.63-0.4-2.4c-0.87,0.09-1.55,0.16-2.24,0.23c0.05,0.9,0.1,1.66,0.15,2.42
	C559.76,243.97,560.46,243.9,561.43,243.8z M599.48,348.9c0-1.04,0-1.82,0-2.64c-0.82,0.16-1.58,0.31-2.44,0.48
	c0.06,0.74,0.12,1.42,0.18,2.16C597.98,348.9,598.67,348.9,599.48,348.9z M453.87,113.53c0,0.95,0,1.66,0,2.41
	c0.75-0.13,1.44-0.25,2.28-0.39c-0.05-0.71-0.1-1.38-0.15-2.13C455.24,113.46,454.62,113.49,453.87,113.53z M492.38,489.55
	c-0.79,0.07-1.47,0.14-2.13,0.2c0,0.82,0,1.52,0,2.32c0.83-0.08,1.57-0.15,2.43-0.24C492.58,491.04,492.49,490.37,492.38,489.55z
	 M520.02,209.07c0.1,0.88,0.17,1.52,0.25,2.21c0.85-0.06,1.52-0.11,2.25-0.16c-0.14-0.81-0.25-1.47-0.38-2.26
	C521.45,208.93,520.85,208.99,520.02,209.07z M607.01,255.46c0.1,0.79,0.21,1.57,0.32,2.42c0.87-0.08,1.56-0.14,2.3-0.2
	c-0.06-0.86-0.11-1.62-0.16-2.37C608.63,255.36,607.92,255.4,607.01,255.46z M606.17,464.84c-0.97,0.19-1.73,0.34-2.49,0.5
	c0.14,0.77,0.27,1.44,0.38,2.03c0.82,0,1.43,0,2.11,0C606.17,466.49,606.17,465.72,606.17,464.84z M621.32,546.73
	c0.17,0.91,0.3,1.6,0.45,2.43c0.71-0.09,1.39-0.17,2.2-0.28c-0.09-0.79-0.17-1.48-0.24-2.16
	C622.9,546.73,622.19,546.73,621.32,546.73z M623.73,491.3c0.9-0.17,1.72-0.32,2.56-0.47c-0.14-0.75-0.27-1.43-0.39-2.1
	c-0.86,0.05-1.61,0.09-2.39,0.13C623.58,489.7,623.65,490.47,623.73,491.3z M563.18,207.48c0.81-0.07,1.55-0.12,2.3-0.19
	c-0.05-0.88-0.09-1.58-0.14-2.38c-0.88,0.08-1.69,0.16-2.51,0.24C562.96,205.99,563.06,206.67,563.18,207.48z M515.24,211.65
	c-0.07-0.82-0.14-1.5-0.2-2.19c-0.8,0-1.49,0-2.24,0c0,0.86,0,1.62,0,2.43C513.58,211.8,514.28,211.74,515.24,211.65z M661.37,604.9
	c-0.77,0.08-1.5,0.15-2.22,0.23c0.04,0.87,0.07,1.58,0.1,2.22c0.89,0,1.73,0,2.6,0C661.67,606.48,661.53,605.72,661.37,604.9z
	 M620.85,539.66c0.19,0.83,0.34,1.48,0.51,2.22c0.78-0.07,1.45-0.14,2.03-0.19c0-0.92,0-1.67,0-2.43
	C622.54,539.39,621.78,539.51,620.85,539.66z M563.58,351.97c-0.05-0.81-0.09-1.57-0.14-2.43c-0.8,0.13-1.48,0.24-2.14,0.35
	c0,0.83,0,1.58,0,2.4C562.13,352.17,562.82,352.08,563.58,351.97z M607.01,503.51c0.74-0.04,1.39-0.08,2.09-0.13
	c-0.04-0.89-0.08-1.57-0.12-2.37c-0.75,0.06-1.41,0.1-2.13,0.16C606.92,502.04,606.96,502.72,607.01,503.51z M479.27,30.53
	c0.84-0.07,1.52-0.13,2.3-0.2c-0.09-0.86-0.17-1.6-0.25-2.37c-0.92,0.07-1.67,0.12-2.43,0.17
	C479.02,28.93,479.13,29.63,479.27,30.53z M616.34,502.59c-0.06-0.88-0.1-1.62-0.15-2.34c-0.97,0.08-1.79,0.14-2.6,0.2
	c0.19,0.89,0.36,1.69,0.53,2.49C614.87,502.82,615.49,502.72,616.34,502.59z M438.69,20.97c0.08,0.81,0.14,1.42,0.21,2.14
	c0.77-0.04,1.45-0.07,2.14-0.11c0-0.9,0-1.66,0-2.43C440.27,20.7,439.58,20.82,438.69,20.97z M584.5,203.3
	c0.18,0.86,0.33,1.52,0.44,2.06c0.82,0,1.44,0,2.09,0c0-0.89,0-1.67,0-2.52C586.22,202.99,585.46,203.12,584.5,203.3z
	 M587.59,212.54c-0.08-0.92-0.15-1.61-0.21-2.34c-0.84,0.12-1.51,0.23-2.24,0.33c0.08,0.84,0.15,1.53,0.22,2.22
	C586.14,212.68,586.76,212.62,587.59,212.54z M541.39,346.67c0-0.88,0-1.58,0-2.29c-0.9,0.05-1.72,0.1-2.57,0.15
	c0.07,0.79,0.13,1.54,0.2,2.35C539.78,346.81,540.54,346.74,541.39,346.67z M593.63,350.45c0.15,0.92,0.25,1.58,0.37,2.33
	c0.81-0.04,1.48-0.08,2.18-0.11c-0.05-0.82-0.1-1.52-0.14-2.22C595.26,350.45,594.56,350.45,593.63,350.45z M587.52,172.75
	c0-0.71,0-1.43,0-2.26c-0.9,0.06-1.63,0.11-2.42,0.16c0.15,0.8,0.27,1.41,0.4,2.1C586.17,172.75,586.79,172.75,587.52,172.75z
	 M667.54,550.17c-0.93,0.06-1.75,0.1-2.6,0.16c0.17,0.83,0.33,1.56,0.47,2.26c0.84-0.1,1.52-0.17,2.3-0.26
	C667.65,551.57,667.6,550.89,667.54,550.17z M261.41,99.84c0.85,0,1.47,0,2.14,0c0-0.86,0-1.62,0-2.46
	c-0.9,0.15-1.71,0.29-2.52,0.43C261.18,98.55,261.29,99.17,261.41,99.84z M548.94,353.2c0-0.76,0-1.51,0-2.33
	c-0.75,0.05-1.36,0.08-2.11,0.13c0.08,0.88,0.15,1.62,0.22,2.38C547.77,353.31,548.33,353.25,548.94,353.2z M550.78,317.09
	c0.94-0.18,1.7-0.33,2.48-0.48c-0.12-0.78-0.22-1.46-0.33-2.2c-0.83,0.1-1.51,0.18-2.15,0.25
	C550.78,315.53,550.78,316.24,550.78,317.09z M603.08,291.94c0,0.87,0,1.64,0,2.46c0.92-0.1,1.75-0.2,2.56-0.29
	c-0.14-0.85-0.26-1.53-0.39-2.31C604.46,291.85,603.77,291.9,603.08,291.94z M651.85,537c-0.85,0.08-1.57,0.14-2.21,0.2
	c0,0.99,0,1.74,0,2.53c0.89-0.17,1.71-0.33,2.51-0.48C652.05,538.53,651.97,537.91,651.85,537z M531.85,70.67
	c-0.87,0.05-1.56,0.09-2.23,0.13c0,0.9,0,1.68,0,2.48c0.84-0.13,1.6-0.24,2.38-0.36C531.95,72.18,531.91,71.56,531.85,70.67z
	 M629.07,353.59c-0.13-0.87-0.24-1.6-0.35-2.36c-0.94,0.08-1.71,0.15-2.61,0.23c0.15,0.77,0.3,1.48,0.43,2.13
	C627.4,353.59,628.09,353.59,629.07,353.59z M593.44,614.56c-0.61,0-1.28,0-2,0c0,0.87,0,1.68,0,2.48c0.86-0.08,1.61-0.15,2.36-0.23
	C593.68,616.06,593.57,615.37,593.44,614.56z M601.02,201.73c-0.82,0.16-1.49,0.29-2.06,0.41c0,0.85,0,1.55,0,2.38
	c0.81-0.11,1.55-0.22,2.26-0.32C601.15,203.36,601.1,202.66,601.02,201.73z M626.38,535.63c-0.81,0.06-1.54,0.12-2.27,0.18
	c0.05,0.96,0.09,1.69,0.13,2.44c0.81-0.15,1.49-0.27,2.32-0.42C626.51,537.11,626.45,536.44,626.38,535.63z M439.79,48.53
	c-0.06-0.97-0.1-1.74-0.15-2.52c-0.88,0.1-1.65,0.2-2.5,0.3c0.14,0.84,0.26,1.57,0.38,2.32C438.31,48.59,438.94,48.56,439.79,48.53z
	 M251.59,127.4c-0.84,0.14-1.57,0.26-2.29,0.38c0,0.85,0,1.53,0,2.33c0.84-0.1,1.59-0.18,2.41-0.27
	C251.67,129.04,251.63,128.28,251.59,127.4z M413.48,39.72c-0.06-0.73-0.11-1.41-0.17-2.19c-0.8,0.05-1.49,0.09-2.16,0.12
	c0,0.91,0,1.67,0,2.47C411.94,39.98,412.64,39.86,413.48,39.72z M650.44,504.52c0.05,0.94,0.08,1.7,0.12,2.49
	c0.77-0.11,1.44-0.21,2.23-0.32c-0.05-0.82-0.09-1.56-0.14-2.33C651.9,504.41,651.28,504.45,650.44,504.52z M495.37,145.65
	c-0.93,0.19-1.8,0.37-2.67,0.54c0.19,0.8,0.35,1.46,0.51,2.1c0.76,0,1.43,0,2.16,0C495.37,147.36,495.37,146.53,495.37,145.65z
	 M519.1,214.99c-0.09-0.86-0.16-1.52-0.24-2.28c-0.82,0.05-1.5,0.09-2.26,0.14c0.04,0.87,0.07,1.62,0.11,2.38
	C517.51,215.14,518.2,215.08,519.1,214.99z M559.1,215.04c-0.16-0.87-0.29-1.6-0.43-2.35c-0.88,0.15-1.61,0.27-2.34,0.4
	c0.08,0.83,0.15,1.52,0.21,2.19C557.38,215.2,558.14,215.13,559.1,215.04z M551.96,386.96c-0.75,0-1.44,0-2.25,0
	c0.07,0.85,0.13,1.59,0.2,2.4c0.84-0.14,1.53-0.25,2.29-0.37C552.11,388.24,552.04,387.62,551.96,386.96z M418.23,37.15
	c0.13,0.9,0.23,1.57,0.33,2.31c0.89-0.05,1.64-0.1,2.44-0.15c-0.21-0.84-0.37-1.52-0.55-2.27C419.75,37.08,419.13,37.1,418.23,37.15
	z M616.16,416.06c-0.16-0.9-0.29-1.62-0.43-2.37c-0.86,0.09-1.53,0.17-2.22,0.24c0.06,0.84,0.12,1.54,0.17,2.26
	C614.47,416.15,615.18,416.12,616.16,416.06z M607.82,470.87c0.81-0.04,1.56-0.08,2.32-0.12c-0.06-0.92-0.11-1.67-0.16-2.45
	c-0.87,0.11-1.61,0.2-2.4,0.3C607.67,469.41,607.74,470.08,607.82,470.87z M609.78,246.91c0.93-0.09,1.76-0.17,2.61-0.26
	c-0.18-0.82-0.33-1.49-0.48-2.19c-0.75,0-1.43,0-2.14,0C609.78,245.33,609.78,246.1,609.78,246.91z M502.37,174.15
	c0.14,0.79,0.28,1.55,0.41,2.27c0.75,0,1.45,0,2.17,0c0-0.83,0-1.6,0-2.44C504.06,174.04,503.24,174.09,502.37,174.15z
	 M550.11,320.58c-0.05-0.98-0.08-1.74-0.12-2.5c-0.85,0.13-1.61,0.26-2.49,0.4c0.15,0.75,0.29,1.46,0.42,2.11
	C548.69,320.58,549.3,320.58,550.11,320.58z M622.27,390.14c0.98-0.05,1.75-0.09,2.61-0.13c-0.18-0.79-0.34-1.51-0.52-2.26
	c-0.85,0.08-1.52,0.14-2.24,0.21C622.17,388.7,622.21,389.31,622.27,390.14z M336.42,50.12c0.81-0.05,1.5-0.09,2.26-0.14
	c-0.1-0.85-0.18-1.6-0.27-2.4c-0.84,0.09-1.52,0.16-2.22,0.23C336.27,48.62,336.33,49.24,336.42,50.12z M437.98,13.68
	c0.05,0.78,0.09,1.46,0.14,2.19c0.74,0,1.42,0,2.24,0c-0.05-0.79-0.1-1.47-0.15-2.19C439.45,13.68,438.76,13.68,437.98,13.68z
	 M628.65,422.3c0.78-0.06,1.59-0.12,2.49-0.19c-0.18-0.86-0.34-1.68-0.51-2.49c-0.74,0.07-1.36,0.13-1.98,0.19
	C628.65,420.61,628.65,421.38,628.65,422.3z M627.5,531.91c0.04,0.79,0.08,1.47,0.12,2.28c0.75-0.04,1.44-0.07,2.27-0.11
	c-0.05-0.76-0.1-1.45-0.15-2.17C628.98,531.91,628.29,531.91,627.5,531.91z M564.49,237.62c-0.86,0.04-1.54,0.08-2.32,0.12
	c0.04,0.88,0.08,1.64,0.12,2.43c0.84-0.1,1.59-0.19,2.36-0.28C564.59,239.12,564.55,238.5,564.49,237.62z M432.71,93.82
	c-0.86,0-1.56,0-2.23,0c0,0.86,0,1.66,0,2.51c0.91-0.14,1.7-0.26,2.49-0.39C432.87,95.16,432.8,94.55,432.71,93.82z M649.96,555.23
	c-0.73,0.07-1.47,0.14-2.29,0.22c0.07,0.78,0.13,1.51,0.2,2.28c0.83-0.06,1.51-0.11,2.19-0.16
	C650.03,556.76,650,556.07,649.96,555.23z M478.96,125.77c-0.91,0.09-1.58,0.16-2.33,0.24c0.07,0.8,0.13,1.46,0.2,2.24
	c0.83-0.05,1.51-0.1,2.25-0.14C479.04,127.31,479.01,126.63,478.96,125.77z M657,596.69c0-0.83,0-1.6,0-2.37
	c-0.87,0.07-1.63,0.14-2.43,0.21c0.09,0.84,0.17,1.6,0.25,2.36C655.64,596.82,656.33,596.75,657,596.69z M562.71,247.51
	c0.89-0.16,1.71-0.31,2.55-0.47c-0.15-0.76-0.27-1.43-0.42-2.2c-0.77,0.04-1.45,0.08-2.13,0.12
	C562.71,245.89,562.71,246.67,562.71,247.51z M520.55,218.54c0.87-0.16,1.57-0.28,2.39-0.43c-0.09-0.71-0.17-1.36-0.26-2.08
	c-0.89,0.1-1.56,0.17-2.23,0.24C520.48,217.07,520.51,217.7,520.55,218.54z M560.21,216.1c0.06,0.96,0.11,1.72,0.16,2.5
	c0.87-0.1,1.64-0.18,2.45-0.28c-0.08-0.8-0.15-1.48-0.23-2.22C561.83,216.1,561.14,216.1,560.21,216.1z M455.69,50.51
	c0.72-0.03,1.45-0.07,2.3-0.1c-0.08-0.85-0.15-1.53-0.22-2.2c-0.88,0-1.66,0-2.52,0C455.4,48.98,455.53,49.65,455.69,50.51z
	 M525.96,154.11c0.07,0.79,0.14,1.52,0.21,2.28c0.83,0,1.6,0,2.47,0c-0.1-0.81-0.19-1.56-0.27-2.28
	C527.56,154.11,526.86,154.11,525.96,154.11z M368.96,51.04c0.93-0.07,1.69-0.12,2.47-0.18c-0.17-0.86-0.31-1.54-0.46-2.29
	c-0.73,0.08-1.34,0.15-2.01,0.22C368.96,49.52,368.96,50.14,368.96,51.04z M602.01,198.22c0.06,0.88,0.1,1.62,0.15,2.35
	c0.94-0.05,1.71-0.09,2.53-0.14c-0.17-0.83-0.32-1.59-0.47-2.34C603.53,198.14,602.9,198.17,602.01,198.22z M668.21,586.07
	c0.06,0.96,0.11,1.73,0.16,2.5c0.88-0.1,1.65-0.18,2.5-0.28c-0.14-0.84-0.26-1.58-0.38-2.33
	C669.69,585.99,669.07,586.02,668.21,586.07z M577.16,174.86c-0.89,0.07-1.66,0.12-2.49,0.19c0.17,0.79,0.34,1.62,0.53,2.5
	c0.71-0.12,1.38-0.23,1.96-0.32C577.16,176.39,577.16,175.69,577.16,174.86z M616.7,423.28c0-0.95,0-1.76,0-2.59
	c-0.87,0.17-1.64,0.32-2.44,0.48c0.09,0.76,0.17,1.44,0.26,2.22C615.32,423.35,616.01,423.32,616.7,423.28z M648.45,580.75
	c-0.8,0.05-1.53,0.1-2.28,0.15c0.05,0.89,0.1,1.58,0.14,2.33c0.81-0.07,1.56-0.14,2.37-0.22
	C648.6,582.22,648.53,581.55,648.45,580.75z M245.8,142.23c-0.87,0.15-1.71,0.3-2.55,0.45c0.16,0.86,0.29,1.54,0.42,2.23
	c0.74-0.04,1.43-0.07,2.14-0.11C245.8,143.97,245.8,143.2,245.8,142.23z M442,35.26c-0.81,0.04-1.42,0.07-2,0.1
	c0,0.82,0,1.51,0,2.25c0.87-0.06,1.62-0.11,2.4-0.16C442.26,36.66,442.14,36.05,442,35.26z M552.97,354.24
	c-0.77,0.07-1.5,0.14-2.16,0.19c0,0.85,0,1.53,0,2.26c0.79-0.06,1.47-0.11,2.28-0.17C553.05,355.78,553.01,355.09,552.97,354.24z
	 M321.77,49.16c0.09,0.92,0.15,1.6,0.22,2.32c0.84-0.04,1.6-0.08,2.45-0.12c-0.18-0.85-0.34-1.56-0.47-2.2
	C323.18,49.16,322.57,49.16,321.77,49.16z M606.9,463.94c0.87-0.16,1.7-0.32,2.54-0.47c-0.11-0.78-0.21-1.45-0.32-2.25
	c-0.8,0.06-1.53,0.11-2.22,0.16C606.9,462.35,606.9,463.13,606.9,463.94z M638.08,500.91c-0.13-0.9-0.24-1.64-0.35-2.37
	c-0.94,0.1-1.7,0.17-2.48,0.25c0.14,0.78,0.27,1.46,0.39,2.12C636.41,500.91,637.12,500.91,638.08,500.91z M364.13,51.41
	c-0.1-0.87-0.18-1.6-0.28-2.4c-0.86,0.11-1.53,0.2-2.27,0.3c0.1,0.8,0.18,1.48,0.27,2.23C362.64,51.49,363.32,51.45,364.13,51.41z
	 M602.3,255.99c-0.89,0.04-1.66,0.08-2.53,0.13c0.14,0.77,0.28,1.5,0.42,2.27c0.82-0.06,1.5-0.12,2.28-0.18
	C602.41,257.39,602.35,256.71,602.3,255.99z M591.03,198c1.01-0.12,1.84-0.23,2.69-0.33c-0.21-0.83-0.38-1.51-0.57-2.27
	c-0.77,0.05-1.46,0.1-2.12,0.15C591.03,196.38,591.03,197.09,591.03,198z M432.15,70.68c0.75-0.03,1.49-0.07,2.2-0.1
	c0-0.95,0-1.76,0-2.6c-0.91,0.2-1.7,0.38-2.47,0.55C431.98,69.29,432.05,69.91,432.15,70.68z M316.9,49.53
	c-0.84,0.06-1.51,0.11-2.24,0.17c0.05,0.78,0.09,1.46,0.13,2.11c0.81,0,1.54,0,2.29,0C317.03,51.04,316.98,50.42,316.9,49.53z
	 M605.15,497.72c-0.9,0.04-1.6,0.07-2.37,0.11c0.13,0.82,0.24,1.56,0.36,2.35c0.8-0.07,1.45-0.13,2.16-0.19
	C605.24,499.15,605.2,498.54,605.15,497.72z M656.33,531.72c0.86-0.09,1.55-0.15,2.3-0.23c-0.07-0.79-0.12-1.47-0.19-2.24
	c-0.82,0.08-1.49,0.15-2.25,0.23C656.24,530.23,656.28,530.85,656.33,531.72z M608.32,272.33c0.92-0.09,1.74-0.17,2.59-0.25
	c-0.17-0.8-0.31-1.47-0.46-2.16c-0.74,0-1.42,0-2.13,0C608.32,270.76,608.32,271.52,608.32,272.33z M356.67,49.68
	c-0.8,0.18-1.41,0.32-2.05,0.46c0,0.71,0,1.4,0,2.11c0.86,0,1.63,0,2.41,0C356.91,51.4,356.8,50.63,356.67,49.68z M665.63,597.13
	c0.07,0.74,0.13,1.48,0.21,2.34c0.85-0.06,1.58-0.12,2.34-0.17c-0.07-0.9-0.12-1.6-0.17-2.31
	C667.22,597.03,666.51,597.08,665.63,597.13z M511.52,195.15c0.14,0.71,0.26,1.36,0.41,2.14c0.75-0.06,1.42-0.12,2.02-0.17
	c0-0.91,0-1.65,0-2.4C513.11,194.87,512.35,195,511.52,195.15z M504.85,76.61c0.09,0.77,0.18,1.5,0.28,2.28
	c0.87-0.06,1.56-0.11,2.2-0.15c0-0.97,0-1.78,0-2.62C506.47,76.29,505.71,76.44,504.85,76.61z M434.12,52.31c0.76,0,1.44,0,2.27,0
	c-0.05-0.84-0.09-1.59-0.13-2.36c-0.8,0.07-1.49,0.14-2.32,0.21C434,50.91,434.06,51.59,434.12,52.31z M604.56,474.7
	c0.84-0.07,1.51-0.13,2.21-0.2c-0.08-0.85-0.14-1.54-0.21-2.27c-0.89,0.08-1.65,0.15-2.41,0.23
	C604.29,473.23,604.4,473.85,604.56,474.7z M349.84,52.79c-0.15-0.9-0.26-1.59-0.4-2.43c-0.78,0.07-1.5,0.14-2.3,0.22
	c0.12,0.89,0.22,1.56,0.3,2.21C348.28,52.79,348.99,52.79,349.84,52.79z M565.5,194.03c0.07,0.98,0.13,1.74,0.18,2.5
	c0.9-0.1,1.67-0.19,2.47-0.28c-0.11-0.81-0.2-1.49-0.3-2.22C567.1,194.03,566.41,194.03,565.5,194.03z M628.87,506.43
	c0.06,0.93,0.11,1.63,0.17,2.4c0.83-0.06,1.58-0.12,2.4-0.18c-0.09-0.82-0.16-1.5-0.23-2.22
	C630.44,506.43,629.75,506.43,628.87,506.43z M518.83,194.65c0.07,0.73,0.13,1.41,0.2,2.08c0.78,0,1.46,0,2.22,0
	c0-0.92,0-1.74,0-2.59C520.42,194.32,519.67,194.47,518.83,194.65z M455.04,101.26c0-0.88,0-1.65,0-2.48
	c-0.91,0.12-1.74,0.23-2.56,0.34c0.14,0.83,0.26,1.5,0.39,2.26C453.66,101.34,454.35,101.3,455.04,101.26z M613.96,294.6
	c0.05,0.82,0.1,1.63,0.16,2.47c0.9-0.11,1.63-0.21,2.39-0.3c-0.1-0.86-0.18-1.6-0.26-2.29C615.41,294.53,614.73,294.56,613.96,294.6
	z M630.87,528.09c0.07,0.75,0.15,1.47,0.21,2.15c0.91,0,1.61,0,2.35,0c-0.15-0.83-0.28-1.58-0.42-2.38
	C632.28,527.94,631.66,528.01,630.87,528.09z M642.15,590.6c-0.05-0.89-0.09-1.57-0.13-2.31c-0.83,0.15-1.51,0.27-2.23,0.39
	c0.1,0.77,0.18,1.37,0.25,1.92C640.82,590.6,641.37,590.6,642.15,590.6z M564.17,222.12c1.03-0.21,1.84-0.37,2.65-0.54
	c-0.19-0.79-0.34-1.46-0.52-2.2c-0.78,0.06-1.46,0.11-2.13,0.16C564.17,220.45,564.17,221.23,564.17,222.12z M478.58,118.36
	c-0.87,0.17-1.63,0.32-2.48,0.49c0.07,0.73,0.14,1.4,0.21,2.21c0.82-0.06,1.56-0.11,2.26-0.15
	C478.58,119.98,478.58,119.28,478.58,118.36z M416.71,33.69c-0.83,0.05-1.57,0.09-2.35,0.13c0.07,0.86,0.12,1.56,0.18,2.32
	c0.85-0.05,1.6-0.1,2.42-0.15C416.87,35.16,416.79,34.49,416.71,33.69z M523.13,176.1c-0.89,0.09-1.71,0.17-2.55,0.25
	c0.13,0.84,0.24,1.52,0.37,2.34c0.82-0.07,1.55-0.13,2.3-0.2C523.21,177.59,523.17,176.89,523.13,176.1z M478.02,158.46
	c-0.87,0.05-1.54,0.09-2.26,0.13c0.04,0.88,0.07,1.65,0.11,2.44c0.89-0.09,1.71-0.17,2.57-0.26
	C478.3,160,478.18,159.33,478.02,158.46z M648.18,565.13c0.93-0.19,1.67-0.34,2.41-0.48c-0.08-0.75-0.14-1.37-0.22-2.14
	c-0.77,0.06-1.5,0.11-2.2,0.16C648.18,563.58,648.18,564.28,648.18,565.13z M414.9,14.42c-0.1-0.86-0.19-1.52-0.26-2.11
	c-0.89,0-1.63,0-2.39,0c0.16,0.86,0.29,1.6,0.43,2.35C413.41,14.58,414.03,14.51,414.9,14.42z M438.41,102.82
	c0.79-0.15,1.48-0.29,2.2-0.43c-0.08-0.77-0.14-1.45-0.21-2.14c-0.86,0-1.63,0-2.44,0C438.09,101.02,438.23,101.79,438.41,102.82z
	 M607.68,486.63c-0.83,0.07-1.5,0.13-2.12,0.18c0,0.94,0,1.73,0,2.53c0.87-0.17,1.61-0.32,2.34-0.47
	C607.84,488.14,607.77,487.52,607.68,486.63z M622.15,527.55c-0.08-0.78-0.15-1.54-0.23-2.39c-0.72,0.12-1.38,0.22-2.12,0.34
	c0.04,0.81,0.07,1.54,0.11,2.31C620.73,527.72,621.41,527.64,622.15,527.55z M612.57,486.26c0.04,0.84,0.08,1.57,0.13,2.35
	c0.93-0.13,1.7-0.23,2.54-0.35c-0.13-0.68-0.25-1.36-0.37-2.01C614.07,486.26,613.37,486.26,612.57,486.26z M535.82,224.22
	c0.93-0.11,1.59-0.19,2.27-0.27c-0.04-0.87-0.07-1.56-0.11-2.29c-0.78,0.14-1.47,0.27-2.27,0.41
	C535.74,222.77,535.77,223.39,535.82,224.22z M343.29,20.6c-0.11-0.87-0.2-1.52-0.28-2.14c-0.89,0-1.6,0-2.39,0
	c0.15,0.81,0.3,1.56,0.45,2.36C341.8,20.75,342.42,20.68,343.29,20.6z M547.33,360.67c0.88-0.11,1.64-0.2,2.51-0.3
	c-0.1-0.82-0.19-1.54-0.29-2.32c-0.87,0.06-1.56,0.11-2.22,0.16C547.33,359.02,547.33,359.72,547.33,360.67z M466.52,21.9
	c-0.8,0.17-1.54,0.32-2.36,0.49c0.07,0.75,0.13,1.41,0.2,2.19c0.82-0.08,1.49-0.14,2.16-0.2C466.52,23.47,466.52,22.7,466.52,21.9z
	 M609.97,302.33c-0.96,0.06-1.72,0.11-2.52,0.16c0.11,0.78,0.21,1.45,0.31,2.17c0.8,0,1.55,0,2.38,0
	C610.09,303.9,610.04,303.2,609.97,302.33z M609.5,508.25c-0.72,0.05-1.4,0.09-2.17,0.14c0.09,0.83,0.17,1.56,0.25,2.32
	c0.91-0.07,1.66-0.12,2.43-0.18C609.83,509.72,609.68,509.05,609.5,508.25z M554,323.96c-0.05-0.94-0.09-1.64-0.14-2.44
	c-0.88,0.09-1.69,0.18-2.51,0.26c0.13,0.84,0.23,1.51,0.33,2.18C552.47,323.96,553.15,323.96,554,323.96z M521.79,396.58
	c0.07,0.82,0.14,1.57,0.21,2.35c0.74-0.08,1.36-0.14,2.11-0.21c-0.05-0.84-0.1-1.58-0.15-2.36
	C523.18,396.43,522.57,396.5,521.79,396.58z M585.43,423.45c0.15,0.85,0.27,1.54,0.42,2.34c0.73-0.07,1.39-0.14,2.11-0.22
	c-0.06-0.82-0.11-1.44-0.17-2.12C587.01,423.45,586.32,423.45,585.43,423.45z M619.81,359.18c0,0.86,0,1.64,0,2.51
	c0.82-0.15,1.55-0.27,2.3-0.41c-0.05-0.91-0.09-1.68-0.14-2.46C621.19,358.95,620.5,359.07,619.81,359.18z M386.21,14.6
	c-0.77,0.19-1.43,0.35-2,0.49c0,0.82,0,1.48,0,2.13c0.87,0,1.62,0,2.44,0C386.51,16.37,386.38,15.62,386.21,14.6z M563.47,225.59
	c0-1.03,0-1.81,0-2.61c-0.89,0.16-1.72,0.32-2.57,0.47c0.12,0.78,0.22,1.46,0.32,2.14C562,225.59,562.68,225.59,563.47,225.59z
	 M610.29,225.07c-0.07-0.8-0.13-1.48-0.2-2.29c-0.81,0.04-1.55,0.09-2.32,0.13c0.05,0.86,0.09,1.56,0.13,2.34
	C608.71,225.19,609.45,225.14,610.29,225.07z M551.05,332.63c-0.82,0.05-1.57,0.09-2.35,0.13c0.07,0.92,0.13,1.67,0.19,2.43
	c0.91-0.11,1.67-0.19,2.47-0.29C551.25,334.11,551.16,333.43,551.05,332.63z M650.93,525.02c-0.06-0.96-0.12-1.72-0.17-2.49
	c-0.89,0.09-1.65,0.18-2.46,0.26c0.1,0.8,0.18,1.48,0.27,2.23C649.31,525.02,650,525.02,650.93,525.02z M365.25,25.89
	c0-0.98,0-1.68,0-2.43c-0.82,0.12-1.57,0.23-2.36,0.34c0.19,0.84,0.33,1.51,0.49,2.2C364.06,25.96,364.6,25.93,365.25,25.89z
	 M517.01,191.03c-0.84,0.06-1.53,0.1-2.3,0.15c0.04,0.93,0.08,1.72,0.12,2.53c0.91-0.17,1.67-0.3,2.43-0.44
	C517.18,192.54,517.12,191.92,517.01,191.03z M444.78,24.08c-0.83,0.08-1.51,0.15-2.2,0.22c0.06,0.76,0.12,1.38,0.17,2.03
	c0.82,0,1.57,0,2.37,0C445.01,25.6,444.91,24.98,444.78,24.08z M546.01,93.23c0.72-0.04,1.45-0.08,2.28-0.13
	c-0.08-0.88-0.15-1.57-0.22-2.31c-0.84,0.08-1.58,0.15-2.33,0.22C545.84,91.81,545.92,92.44,546.01,93.23z M618.34,426.48
	c0.88,0,1.62,0,2.46,0c-0.13-0.85-0.25-1.58-0.36-2.29c-0.92,0.09-1.68,0.16-2.43,0.23C618.13,425.15,618.23,425.76,618.34,426.48z
	 M452.34,112.3c-0.07-0.87-0.11-1.48-0.16-2.08c-0.84,0-1.58,0-2.4,0c0.16,0.8,0.29,1.47,0.41,2.08
	C450.94,112.3,451.54,112.3,452.34,112.3z M608.96,250.43c-0.14-0.83-0.25-1.5-0.38-2.26c-0.78,0.04-1.46,0.07-2.17,0.11
	c0,0.96,0,1.78,0,2.62C607.25,250.74,608.01,250.6,608.96,250.43z M287.16,66.48c0.16,0.76,0.3,1.44,0.47,2.22
	c0.72-0.06,1.37-0.11,2.04-0.17c-0.07-0.91-0.13-1.64-0.18-2.34C288.69,66.29,288.07,66.37,287.16,66.48z M613.31,424.7
	c-0.87,0.08-1.67,0.15-2.48,0.22c0.12,0.84,0.22,1.51,0.33,2.31c0.81-0.06,1.55-0.12,2.3-0.18
	C613.41,426.2,613.36,425.51,613.31,424.7z M588.4,177.5c-0.9,0.14-1.67,0.25-2.52,0.38c0.12,0.7,0.24,1.37,0.35,2.03
	c0.76,0,1.44,0,2.17,0C588.4,179.11,588.4,178.41,588.4,177.5z M539.68,227.58c0.92-0.06,1.61-0.11,2.31-0.16
	c-0.04-0.83-0.08-1.53-0.12-2.33c-0.82,0.07-1.56,0.14-2.34,0.22C539.58,226.11,539.62,226.74,539.68,227.58z M604.05,193.18
	c0-0.88,0-1.71,0-2.6c-0.86,0.23-1.6,0.43-2.34,0.63c0,0.72,0,1.4,0,2.08C602.56,193.25,603.32,193.21,604.05,193.18z M416.36,18.14
	c0.87-0.18,1.56-0.32,2.38-0.48c-0.06-0.7-0.12-1.37-0.18-2.13c-0.83,0.08-1.52,0.14-2.2,0.21
	C416.36,16.58,416.36,17.29,416.36,18.14z M489.41,192.28c0.83-0.18,1.5-0.32,2.21-0.48c-0.06-0.76-0.11-1.37-0.16-1.94
	c-0.89,0-1.63,0-2.37,0C489.2,190.68,489.29,191.38,489.41,192.28z M647.39,566.15c-0.8,0.15-1.55,0.29-2.33,0.44
	c0.05,0.75,0.1,1.37,0.14,2.02c0.75,0,1.44,0,2.18,0C647.39,567.74,647.39,566.97,647.39,566.15z M614.87,304.17
	c0.73-0.04,1.52-0.09,2.33-0.14c-0.06-0.93-0.11-1.73-0.16-2.57c-0.92,0.2-1.64,0.36-2.3,0.51
	C614.79,302.74,614.82,303.37,614.87,304.17z M561.6,122.24c-0.84,0.07-1.54,0.12-2.25,0.18c0,0.77,0,1.55,0,2.38
	c0.85-0.08,1.6-0.16,2.41-0.24C561.71,123.8,561.66,123.11,561.6,122.24z M598.32,281.4c0.2,0.88,0.37,1.59,0.55,2.36
	c0.81-0.08,1.47-0.15,2.16-0.22c-0.08-0.89-0.15-1.62-0.21-2.33C599.96,281.27,599.26,281.33,598.32,281.4z M284.04,83.95
	c-0.18-0.78-0.35-1.53-0.55-2.38c-0.71,0.07-1.37,0.13-2.12,0.2c0.06,0.83,0.11,1.5,0.16,2.18
	C282.45,83.95,283.22,83.95,284.04,83.95z M547.01,324.78c-0.2-0.89-0.36-1.64-0.55-2.46c-0.74,0.11-1.41,0.2-2.04,0.29
	c0,0.83,0,1.47,0,2.16C545.24,324.78,546.02,324.78,547.01,324.78z M478.55,165.62c-0.82,0.05-1.49,0.09-2.04,0.12
	c0,1.03,0,1.85,0,2.67c0.89-0.18,1.65-0.32,2.49-0.49C478.85,167.21,478.72,166.53,478.55,165.62z M635.99,437.23
	c-0.86,0.05-1.55,0.1-2.22,0.14c0,0.9,0,1.67,0,2.47c0.86-0.12,1.61-0.23,2.39-0.34C636.11,438.76,636.06,438.13,635.99,437.23z
	 M565.23,286.84c0-0.97,0-1.8,0-2.73c-0.84,0.21-1.66,0.4-2.53,0.62c0.11,0.73,0.22,1.41,0.33,2.11
	C563.79,286.84,564.49,286.84,565.23,286.84z M553.92,284.07c0-0.95,0-1.71,0-2.47c-0.84,0.14-1.57,0.26-2.38,0.4
	c0.18,0.78,0.34,1.44,0.48,2.07C552.72,284.07,553.26,284.07,553.92,284.07z M475.2,124.73c-0.04-0.78-0.08-1.46-0.12-2.15
	c-0.83,0-1.59,0-2.47,0c0.19,0.84,0.34,1.55,0.48,2.15C473.89,124.73,474.51,124.73,475.2,124.73z M524.63,150.64
	c-0.95,0.1-1.72,0.18-2.59,0.27c0.13,0.83,0.25,1.56,0.37,2.35c0.86-0.07,1.55-0.13,2.21-0.18
	C524.63,152.26,524.63,151.55,524.63,150.64z M434.68,59.63c0.87-0.04,1.55-0.07,2.38-0.11c-0.07-0.83-0.13-1.56-0.2-2.41
	c-0.85,0.13-1.52,0.23-2.18,0.32C434.68,58.14,434.68,58.76,434.68,59.63z M613.19,300.67c-0.07-0.85-0.13-1.67-0.19-2.47
	c-0.88,0.07-1.63,0.13-2.41,0.19c0.22,0.89,0.42,1.69,0.62,2.52C611.9,300.82,612.51,300.75,613.19,300.67z M669.19,595.54
	c0.85,0,1.53,0,2.27,0c0-0.94,0-1.78,0-2.65c-0.88,0.19-1.7,0.36-2.5,0.53C669.04,594.16,669.11,594.78,669.19,595.54z
	 M626.3,517.54c0.05,0.68,0.1,1.41,0.16,2.28c0.83-0.06,1.57-0.11,2.21-0.16c0-0.97,0-1.73,0-2.49
	C627.89,517.28,627.19,517.4,626.3,517.54z M368.8,22.15c-0.09-0.93-0.16-1.67-0.24-2.43c-0.91,0.08-1.67,0.15-2.47,0.22
	c0.11,0.8,0.2,1.48,0.3,2.21C367.14,22.15,367.83,22.15,368.8,22.15z M577.09,159.31c0.81-0.07,1.5-0.13,2.24-0.19
	c-0.07-0.83-0.13-1.57-0.19-2.29c-0.92,0-1.74,0-2.59,0C576.72,157.63,576.89,158.39,577.09,159.31z M552.09,328.92
	c0.04,0.93,0.08,1.69,0.12,2.49c0.79-0.12,1.47-0.22,2.26-0.34c-0.05-0.81-0.09-1.57-0.14-2.35
	C553.59,328.78,552.97,328.84,552.09,328.92z M554.8,249.28c-0.94,0.04-1.77,0.08-2.61,0.12c0.15,0.82,0.29,1.56,0.43,2.33
	c0.81-0.09,1.55-0.18,2.34-0.27C554.9,250.67,554.85,250,554.8,249.28z M452.73,59.52c0.06,0.8,0.11,1.49,0.16,2.2
	c0.92-0.1,1.73-0.18,2.53-0.26c-0.12-0.81-0.23-1.5-0.34-2.24C454.34,59.31,453.66,59.4,452.73,59.52z M607.43,481.81
	c-0.06-0.9-0.11-1.71-0.17-2.52c-0.8,0.14-1.49,0.26-2.27,0.39c0.06,0.74,0.12,1.47,0.18,2.23
	C606,481.88,606.69,481.85,607.43,481.81z M435.18,64.51c0,1.01,0,1.8,0,2.61c0.87-0.19,1.61-0.35,2.36-0.52
	c-0.09-0.78-0.17-1.44-0.24-2.1C436.53,64.51,435.91,64.51,435.18,64.51z M567.43,189.05c-0.07-0.92-0.13-1.67-0.19-2.43
	c-0.81,0.11-1.49,0.2-2.2,0.3c0.07,0.93,0.13,1.68,0.18,2.42C565.96,189.25,566.58,189.17,567.43,189.05z M545.68,228.36
	c-0.96,0.11-1.65,0.19-2.44,0.29c0.11,0.8,0.21,1.52,0.32,2.25c0.86-0.1,1.55-0.19,2.31-0.28
	C545.81,229.87,545.75,229.18,545.68,228.36z M607.88,265.09c0.79-0.07,1.48-0.14,2.27-0.21c-0.11-0.81-0.2-1.49-0.31-2.29
	c-0.75,0.04-1.41,0.08-2.12,0.12C607.79,263.6,607.83,264.3,607.88,265.09z M610.62,517.76c-0.1-0.84-0.18-1.59-0.27-2.42
	c-0.75,0.1-1.43,0.2-2.21,0.31c0.07,0.79,0.12,1.46,0.18,2.11C609.15,517.76,609.84,517.76,610.62,517.76z M596.7,299.74
	c0.06,0.69,0.12,1.39,0.19,2.21c0.85-0.07,1.5-0.13,2.17-0.19c-0.05-0.9-0.09-1.63-0.13-2.36
	C598.14,299.52,597.52,299.62,596.7,299.74z M594.02,370.96c0-0.78,0-1.55,0-2.43c-0.84,0.16-1.52,0.3-2.21,0.43
	c0.08,0.77,0.15,1.37,0.22,2C592.78,370.96,593.33,370.96,594.02,370.96z M526.98,179.5c-0.94,0.07-1.71,0.12-2.55,0.18
	c0.14,0.84,0.27,1.57,0.39,2.29c0.87-0.11,1.56-0.2,2.3-0.29C527.08,180.99,527.04,180.36,526.98,179.5z M415.62,19.01
	c-0.9,0.2-1.65,0.37-2.38,0.54c0.1,0.8,0.18,1.48,0.26,2.18c0.83-0.04,1.44-0.07,2.12-0.11C415.62,20.71,415.62,19.93,415.62,19.01z
	 M485.16,31.23c-0.88,0.07-1.68,0.13-2.51,0.19c0.22,0.85,0.39,1.51,0.54,2.08c0.73,0,1.33,0,1.96,0
	C485.16,32.71,485.16,32.03,485.16,31.23z M307.4,63.79c-0.11-0.88-0.2-1.68-0.3-2.49c-0.8,0.13-1.46,0.24-2.18,0.35
	c0.06,0.85,0.1,1.52,0.15,2.14C305.89,63.79,306.58,63.79,307.4,63.79z M629.43,516.19c0.81-0.15,1.57-0.3,2.42-0.46
	c-0.07-0.75-0.13-1.43-0.21-2.22c-0.83,0.06-1.52,0.11-2.22,0.16C629.43,514.59,629.43,515.37,629.43,516.19z M586.71,187.25
	c0.7-0.04,1.44-0.07,2.27-0.12c-0.13-0.86-0.23-1.53-0.34-2.25c-0.88,0.06-1.62,0.11-2.38,0.16
	C586.42,185.82,586.55,186.44,586.71,187.25z M636.53,428.53c0.88,0,1.57,0,2.39,0c-0.04-0.86-0.08-1.61-0.12-2.42
	c-0.91,0.09-1.68,0.17-2.48,0.25C636.4,427.16,636.46,427.78,636.53,428.53z M569.45,366.01c0.8-0.16,1.55-0.31,2.35-0.47
	c-0.05-0.77-0.1-1.43-0.15-2.17c-0.87,0.07-1.54,0.12-2.19,0.17C569.45,364.44,569.45,365.2,569.45,366.01z M608.48,230.16
	c0.11,0.82,0.2,1.49,0.3,2.28c0.79-0.06,1.46-0.11,2.09-0.15c0-0.95,0-1.75,0-2.55C610.07,229.88,609.38,230,608.48,230.16z
	 M497.9,163.8c0.07,0.87,0.13,1.58,0.18,2.27c0.82,0,1.58,0,2.45,0c-0.13-0.87-0.24-1.61-0.35-2.37
	C499.39,163.73,498.77,163.76,497.9,163.8z M475.29,111.51c0.06,0.93,0.11,1.7,0.17,2.48c0.88-0.09,1.66-0.17,2.51-0.26
	c-0.14-0.85-0.26-1.58-0.38-2.34C476.79,111.43,476.16,111.47,475.29,111.51z M459.27,64.8c-0.16-0.9-0.28-1.58-0.42-2.33
	c-0.8,0.05-1.49,0.09-2.18,0.13c0,0.89,0,1.67,0,2.46C457.54,64.97,458.31,64.89,459.27,64.8z M444.41,19.12
	c-0.2-0.84-0.35-1.5-0.51-2.16c-0.79,0.05-1.4,0.08-2.02,0.12c0,0.93,0,1.7,0,2.48C442.71,19.41,443.49,19.28,444.41,19.12z
	 M622.93,326.17c-0.84,0.15-1.61,0.29-2.5,0.45c0.12,0.78,0.22,1.5,0.34,2.3c0.87-0.1,1.55-0.18,2.16-0.24
	C622.93,327.73,622.93,326.96,622.93,326.17z M479.16,114.87c0.05,1,0.09,1.82,0.13,2.64c0.92-0.18,1.68-0.33,2.52-0.49
	c-0.13-0.79-0.25-1.51-0.38-2.26C480.65,114.8,480.03,114.83,479.16,114.87z M628.04,512.48c-0.07-0.78-0.13-1.46-0.2-2.28
	c-0.81,0.04-1.56,0.08-2.33,0.12c0.05,0.87,0.09,1.57,0.14,2.36C626.46,512.61,627.21,512.55,628.04,512.48z M615.36,366.85
	c-0.92,0.07-1.65,0.13-2.41,0.19c0.09,0.86,0.17,1.62,0.26,2.39c0.91-0.18,1.71-0.34,2.51-0.5
	C615.6,368.2,615.49,367.59,615.36,366.85z M592.26,183.3c-0.04-0.79-0.07-1.47-0.11-2.21c-0.76,0-1.44,0-2.28,0
	c0.06,0.78,0.11,1.47,0.17,2.21C590.79,183.3,591.48,183.3,592.26,183.3z M622.45,405.93c-0.85,0.09-1.51,0.16-2.27,0.25
	c0.1,0.85,0.18,1.51,0.27,2.23c0.79-0.08,1.46-0.16,2.17-0.23C622.55,407.42,622.51,406.79,622.45,405.93z M611.39,485.17
	c-0.16-0.92-0.29-1.63-0.42-2.38c-0.86,0.08-1.53,0.14-2.25,0.21c0.07,0.85,0.14,1.59,0.2,2.32
	C609.76,485.27,610.45,485.22,611.39,485.17z M608.65,496.23c-0.06-0.91-0.11-1.61-0.16-2.4c-0.81,0.05-1.56,0.1-2.37,0.16
	c0.12,0.9,0.2,1.58,0.29,2.25C607.17,496.23,607.79,496.23,608.65,496.23z M612.79,251.35c-0.79,0.12-1.56,0.24-2.4,0.37
	c0.09,0.76,0.17,1.45,0.25,2.14c0.84,0,1.6,0,2.39,0C612.94,252.95,612.87,252.17,612.79,251.35z M420.63,19.01c0,0.7,0,1.39,0,2.19
	c0.84-0.04,1.59-0.07,2.4-0.11c-0.12-0.85-0.23-1.54-0.34-2.31C421.98,18.87,421.36,18.93,420.63,19.01z M251.06,120.1
	c-0.85,0.18-1.61,0.35-2.39,0.52c0.03,0.75,0.07,1.44,0.1,2.21c0.82-0.08,1.57-0.16,2.29-0.23
	C251.06,121.74,251.06,120.96,251.06,120.1z M652.58,572.9c0.12,0.72,0.25,1.53,0.38,2.34c0.75-0.06,1.43-0.11,2.14-0.16
	c0-0.79,0-1.55,0-2.3C654.26,572.82,653.49,572.86,652.58,572.9z M613.53,511.39c-0.98,0-1.74,0-2.58,0
	c0.17,0.89,0.32,1.72,0.49,2.62c0.8-0.2,1.47-0.37,2.09-0.52C613.53,512.72,613.53,512.09,613.53,511.39z M542.43,232.21
	c-0.82,0.14-1.51,0.26-2.23,0.38c0.11,0.75,0.2,1.43,0.31,2.16c0.85-0.04,1.58-0.07,2.33-0.11
	C542.69,233.77,542.57,233.09,542.43,232.21z M653.58,600.38c0-0.73,0-1.46,0-2.18c-0.87,0.05-1.62,0.09-2.47,0.14
	c0.16,0.82,0.32,1.62,0.47,2.43C652.32,600.62,652.93,600.5,653.58,600.38z M576.39,500.21c-0.82,0.18-1.43,0.31-2.07,0.46
	c0,0.73,0,1.41,0,2.12c0.83-0.04,1.59-0.08,2.44-0.13C576.65,501.89,576.53,501.14,576.39,500.21z M580.83,162.67
	c0.87-0.12,1.6-0.22,2.33-0.32c-0.08-0.82-0.15-1.49-0.22-2.16c-0.79,0.06-1.47,0.11-2.22,0.16
	C580.75,161.09,580.79,161.77,580.83,162.67z M544.36,242.8c0.16,0.83,0.3,1.59,0.45,2.42c0.73-0.13,1.41-0.25,2.13-0.37
	c-0.05-0.79-0.1-1.53-0.14-2.24C545.97,242.67,545.27,242.73,544.36,242.8z M578.29,318.02c0-0.73,0-1.32,0-2.08
	c-0.78,0.04-1.45,0.07-2.09,0.1c0,0.82,0,1.52,0,2.35C576.96,318.26,577.61,318.14,578.29,318.02z M580.32,503.51
	c-0.86,0.04-1.55,0.08-2.36,0.12c0.15,0.8,0.27,1.46,0.38,2.09c0.74,0,1.35,0,2.08,0C580.39,505,580.36,504.39,580.32,503.51z
	 M491.02,182.57c-0.92,0.04-1.61,0.07-2.41,0.11c0.19,0.81,0.34,1.47,0.51,2.2c0.76-0.12,1.36-0.22,1.9-0.3
	C491.02,183.88,491.02,183.33,491.02,182.57z M610.81,506.67c0.87-0.05,1.66-0.1,2.46-0.15c-0.13-0.84-0.23-1.52-0.35-2.26
	c-0.79,0.13-1.51,0.25-2.27,0.38C610.71,505.38,610.76,505.99,610.81,506.67z M464.34,69.07c0.17,0.82,0.34,1.63,0.51,2.48
	c0.72-0.08,1.39-0.16,2.05-0.24c-0.08-0.88-0.15-1.64-0.22-2.42C465.89,68.95,465.15,69.01,464.34,69.07z M353.66,13.84
	c-0.92,0.1-1.66,0.18-2.4,0.26c0.15,0.84,0.27,1.52,0.39,2.21c0.74-0.04,1.43-0.07,2.17-0.11
	C353.76,15.42,353.72,14.73,353.66,13.84z M427.03,68.85c-0.87,0.19-1.53,0.33-2.19,0.47c0.04,0.8,0.07,1.43,0.1,2.09
	c0.88,0,1.71,0,2.57,0C427.35,70.57,427.21,69.81,427.03,68.85z M385.06,29.1c0.18,0.79,0.32,1.4,0.49,2.12
	c0.72-0.04,1.38-0.09,1.93-0.12c0-0.94,0-1.7,0-2.43C386.67,28.82,385.97,28.94,385.06,29.1z M621.85,509.34
	c0.97-0.1,1.71-0.18,2.46-0.25c-0.15-0.8-0.27-1.46-0.38-2.03c-0.83,0-1.51,0-2.29,0C621.7,507.79,621.77,508.47,621.85,509.34z
	 M536.73,236.39c0.09,0.9,0.16,1.51,0.23,2.19c0.79-0.06,1.48-0.11,2.21-0.16c-0.05-0.77-0.09-1.39-0.13-2.03
	C538.31,236.39,537.63,236.39,536.73,236.39z M361.02,15.8c-0.08-1-0.13-1.75-0.2-2.59c-0.77,0.18-1.44,0.33-2.19,0.51
	c0.06,0.77,0.12,1.45,0.17,2.08C359.59,15.8,360.23,15.8,361.02,15.8z M575.35,319.5c-0.98,0.15-1.79,0.28-2.62,0.41
	c0.22,0.83,0.4,1.49,0.58,2.2c0.75-0.04,1.42-0.08,2.04-0.11C575.35,321.1,575.35,320.4,575.35,319.5z M651.57,576.88
	c-0.79,0.1-1.38,0.17-2,0.25c0.05,0.8,0.09,1.48,0.14,2.22c0.81-0.07,1.47-0.12,2.22-0.18C651.8,578.35,651.71,577.75,651.57,576.88
	z M502.99,68.23c-0.16-0.89-0.29-1.57-0.41-2.24c-0.83,0.05-1.58,0.09-2.39,0.14c0.12,0.78,0.22,1.46,0.31,2.1
	C501.35,68.23,502.05,68.23,502.99,68.23z M413.26,77.4c-0.78,0.12-1.43,0.21-2.17,0.32c0.07,0.79,0.13,1.45,0.19,2.13
	c0.78-0.07,1.39-0.12,2.13-0.18C413.36,78.89,413.32,78.21,413.26,77.4z M301.8,67.87c0.88-0.09,1.63-0.17,2.34-0.25
	c-0.11-0.92-0.21-1.69-0.32-2.52c-0.7,0.13-1.37,0.26-2.02,0.39C301.8,66.26,301.8,66.96,301.8,67.87z M434.22,28.72
	c-0.86,0-1.6,0-2.37,0c0.11,0.79,0.21,1.47,0.32,2.3c0.78-0.14,1.51-0.27,2.25-0.4C434.35,29.87,434.28,29.27,434.22,28.72z
	 M600.82,323.45c-0.07-0.91-0.12-1.67-0.18-2.51c-0.74,0.12-1.47,0.24-2.2,0.36c0.1,0.88,0.18,1.63,0.26,2.38
	C599.44,323.6,600.06,323.54,600.82,323.45z M584.77,495.9c0,0.77,0,1.51,0,2.39c0.82-0.1,1.55-0.2,2.35-0.3
	c-0.16-0.81-0.29-1.47-0.41-2.09C585.98,495.9,585.43,495.9,584.77,495.9z M577.59,166.56c0.75-0.08,1.44-0.16,2.22-0.24
	c-0.04-0.81-0.08-1.57-0.13-2.45c-0.77,0.13-1.42,0.24-2.09,0.36C577.59,165.06,577.59,165.75,577.59,166.56z M490.95,166.66
	c0.68-0.06,1.37-0.12,2.18-0.19c-0.05-0.75-0.09-1.43-0.14-2.13c-0.81,0-1.5,0-2.31,0C490.77,165.12,490.85,165.81,490.95,166.66z
	 M644.18,572.45c-0.15-0.92-0.25-1.57-0.35-2.17c-0.86,0-1.56,0-2.35,0c0.17,0.8,0.33,1.52,0.47,2.17
	C642.71,572.45,643.31,572.45,644.18,572.45z M536.28,231.49c0.81-0.14,1.48-0.25,2.21-0.37c-0.04-0.71-0.07-1.32-0.11-1.94
	c-0.8,0-1.48,0-2.28,0C536.16,229.97,536.21,230.64,536.28,231.49z M596.18,252.98c0.04,0.84,0.07,1.51,0.1,2.16
	c0.87,0,1.6,0,2.41,0c-0.1-0.92-0.2-1.73-0.29-2.54C597.62,252.73,597.01,252.83,596.18,252.98z M656.71,618.37
	c0.87,0,1.68,0,2.54,0c-0.18-0.91-0.34-1.71-0.51-2.57c-0.81,0.23-1.46,0.42-2.02,0.58C656.71,617.15,656.71,617.77,656.71,618.37z
	 M648.86,569.8c0.08,0.81,0.14,1.49,0.21,2.28c0.84-0.05,1.58-0.09,2.41-0.14c-0.18-0.87-0.31-1.54-0.45-2.24
	C650.23,569.73,649.61,569.76,648.86,569.8z M276.6,84.29c-0.1-0.85-0.18-1.52-0.26-2.24c-0.79,0.05-1.48,0.09-2.28,0.14
	c0.12,0.8,0.24,1.53,0.34,2.22C275.16,84.37,275.78,84.34,276.6,84.29z M521.26,407.52c-0.9,0.08-1.56,0.14-2.24,0.2
	c0.07,0.86,0.12,1.55,0.19,2.33c0.77-0.17,1.43-0.32,2.05-0.45C521.26,408.89,521.26,408.35,521.26,407.52z M523.01,577.05
	c0.1,0.72,0.2,1.44,0.32,2.3c0.79-0.05,1.47-0.09,2.22-0.13c-0.05-0.85-0.1-1.59-0.15-2.38
	C524.61,576.91,523.9,576.97,523.01,577.05z M602.02,446.16c0.98,0,1.74,0,2.53,0c-0.15-0.92-0.3-1.75-0.45-2.64
	c-0.77,0.19-1.44,0.35-2.08,0.51C602.02,444.81,602.02,445.44,602.02,446.16z M484.94,179.63c0,0.78,0,1.47,0,2.23
	c0.87-0.07,1.69-0.13,2.54-0.2c-0.14-0.85-0.26-1.59-0.39-2.4C486.31,179.4,485.64,179.51,484.94,179.63z M602.47,424.53
	c-0.09-0.95-0.17-1.7-0.25-2.51c-0.83,0.17-1.5,0.31-2.14,0.45c0,0.76,0,1.39,0,2.07C600.84,424.53,601.54,424.53,602.47,424.53z
	 M453.61,108.43c0.83,0,1.45,0,2.06,0c0-0.83,0-1.59,0-2.44c-0.83,0.14-1.57,0.27-2.3,0.39
	C453.46,107.12,453.53,107.74,453.61,108.43z M237.42,157.63c0.07,0.83,0.13,1.56,0.18,2.23c0.92,0,1.69,0,2.63,0
	c-0.2-0.83-0.37-1.55-0.56-2.33C238.88,157.56,238.2,157.59,237.42,157.63z M421.18,86.19c-0.07-0.71-0.15-1.4-0.23-2.21
	c-0.77,0.06-1.44,0.11-2.09,0.15c0,0.84,0,1.52,0,2.26C419.66,86.33,420.36,86.27,421.18,86.19z M658.63,571.39
	c-0.09-1-0.16-1.75-0.24-2.6c-0.85,0.18-1.57,0.34-2.3,0.49c0.05,0.82,0.1,1.49,0.14,2.11C657.1,571.39,657.79,571.39,658.63,571.39
	z M632.3,423.02c0.17,0.79,0.31,1.46,0.49,2.27c0.72-0.04,1.41-0.08,2.14-0.12c0-0.85,0-1.63,0-2.49
	C634.04,422.8,633.2,422.9,632.3,423.02z M595.12,325.09c0.11,0.81,0.21,1.48,0.32,2.26c0.8-0.04,1.47-0.07,2.23-0.11
	c-0.08-0.86-0.14-1.54-0.21-2.26C596.63,325.01,595.93,325.05,595.12,325.09z M567.48,228.93c-0.14-0.87-0.25-1.54-0.38-2.32
	c-0.82,0.04-1.57,0.07-2.35,0.1c0.12,0.81,0.23,1.48,0.34,2.22C565.82,228.93,566.5,228.93,567.48,228.93z M513.17,187.88
	c-0.86,0.04-1.61,0.08-2.42,0.13c0.11,0.77,0.2,1.45,0.3,2.18c0.89-0.05,1.66-0.09,2.44-0.14
	C513.38,189.3,513.29,188.69,513.17,187.88z M474.16,110.43c-0.14-0.88-0.24-1.57-0.36-2.33c-0.79,0.11-1.53,0.22-2.38,0.34
	c0.06,0.71,0.12,1.39,0.19,2.13C472.47,110.53,473.23,110.48,474.16,110.43z M647,519.25c-0.98,0.22-1.72,0.38-2.53,0.56
	c0.11,0.68,0.21,1.28,0.3,1.84c0.79,0,1.48,0,2.23,0C647,520.86,647,520.17,647,519.25z M548.43,256.75
	c-0.94,0.28-1.68,0.49-2.41,0.71c0.07,0.74,0.14,1.41,0.2,2c0.84,0,1.53,0,2.21,0C548.43,258.59,548.43,257.76,548.43,256.75z
	 M637.46,522.51c0.96-0.06,1.65-0.1,2.4-0.14c-0.06-0.79-0.11-1.56-0.18-2.48c-0.83,0.17-1.56,0.31-2.22,0.44
	C637.46,521.14,637.46,521.77,637.46,522.51z M538.74,258.03c0.07,0.79,0.13,1.44,0.19,2.15c0.86-0.07,1.55-0.12,2.29-0.17
	c-0.14-0.79-0.27-1.45-0.4-2.18C540.07,257.89,539.47,257.95,538.74,258.03z M615.83,287.14c-0.9,0.19-1.56,0.33-2.21,0.47
	c0,0.79,0,1.46,0,2.24c0.82-0.11,1.56-0.2,2.32-0.3C615.9,288.74,615.87,288.04,615.83,287.14z M371.54,55.78
	c-0.84,0.04-1.52,0.07-2.32,0.1c0.07,0.84,0.13,1.51,0.2,2.26c0.91-0.05,1.72-0.1,2.54-0.15C371.82,57.23,371.7,56.61,371.54,55.78z
	 M501.68,180.33c-0.14-0.84-0.24-1.44-0.36-2.14c-0.73,0.06-1.39,0.12-2.1,0.18c0.07,0.82,0.13,1.48,0.19,2.19
	C500.23,180.48,500.83,180.42,501.68,180.33z M498.4,57.98c-0.1-0.95-0.19-1.77-0.28-2.63c-0.84,0.17-1.52,0.31-2.27,0.47
	c0.04,0.78,0.07,1.47,0.11,2.16C496.75,57.98,497.45,57.98,498.4,57.98z M522.6,406.01c0.83-0.06,1.43-0.1,2.15-0.16
	c-0.08-0.78-0.15-1.43-0.22-2c-0.81,0-1.41,0-2.11,0C522.48,404.59,522.53,405.19,522.6,406.01z M439.21,85.98
	c-0.75,0.04-1.47,0.08-2.2,0.13c0.07,0.9,0.12,1.58,0.18,2.34c0.88-0.05,1.69-0.09,2.53-0.14
	C439.53,87.45,439.39,86.79,439.21,85.98z M376.56,57.64c0.92-0.09,1.66-0.16,2.43-0.24c-0.11-0.83-0.2-1.51-0.3-2.26
	c-0.8,0.08-1.46,0.14-2.13,0.2C376.56,56.15,376.56,56.78,376.56,57.64z M565.73,362.51c0.84-0.07,1.58-0.14,2.36-0.21
	c-0.12-0.83-0.21-1.49-0.3-2.15c-0.84,0.05-1.57,0.09-2.33,0.13C565.55,361.07,565.63,361.75,565.73,362.51z M436.14,34.24
	c0.82-0.09,1.52-0.17,2.29-0.26c-0.11-0.88-0.2-1.54-0.29-2.23c-0.77,0.09-1.38,0.16-2,0.23C436.14,32.74,436.14,33.41,436.14,34.24
	z M632.79,523.06c-0.19-0.91-0.32-1.58-0.47-2.31c-0.83,0.05-1.52,0.1-2.25,0.14c0.11,0.8,0.21,1.48,0.3,2.17
	C631.14,523.06,631.83,523.06,632.79,523.06z M575.88,329.07c-0.1-0.76-0.18-1.43-0.26-2.07c-0.81,0-1.51,0-2.21,0
	c0.09,0.89,0.18,1.69,0.26,2.48C574.43,329.34,575.05,329.22,575.88,329.07z M572.97,361.91c0.85-0.05,1.46-0.08,2.17-0.12
	c-0.05-0.8-0.09-1.46-0.14-2.24c-0.81,0.07-1.48,0.13-2.2,0.2C572.86,360.5,572.91,361.11,572.97,361.91z M566.61,261.47
	c-0.1-0.87-0.17-1.55-0.27-2.37c-0.82,0.13-1.55,0.24-2.28,0.35c0.06,0.78,0.1,1.39,0.15,2.01
	C564.99,261.47,565.68,261.47,566.61,261.47z M565.71,373.71c-0.2-0.94-0.37-1.74-0.55-2.56c-0.76,0.14-1.37,0.25-2.02,0.37
	c0,0.75,0,1.45,0,2.19C563.97,373.71,564.74,373.71,565.71,373.71z M551.36,86.99c-0.86,0.08-1.53,0.15-2.25,0.22
	c0.06,0.8,0.11,1.47,0.17,2.25c0.87-0.17,1.52-0.29,2.18-0.41C551.43,88.34,551.4,87.8,551.36,86.99z M593.64,155.49
	c-0.77,0.14-1.43,0.25-2.1,0.37c0,0.73,0,1.32,0,2.06c0.79-0.05,1.46-0.09,2.1-0.13C593.64,156.99,593.64,156.31,593.64,155.49z
	 M604.6,450.82c-0.79,0.16-1.49,0.3-2.19,0.44c0.04,0.87,0.07,1.54,0.1,2.24c0.95-0.14,1.69-0.25,2.44-0.36
	C604.83,452.34,604.73,451.65,604.6,450.82z M599.24,262.26c-0.13-0.81-0.24-1.56-0.37-2.4c-0.76,0.12-1.43,0.22-2.06,0.31
	c0,0.84,0,1.57,0,2.3C597.7,262.4,598.44,262.34,599.24,262.26z M501.71,167.13c0.06,0.9,0.11,1.59,0.17,2.38
	c0.87-0.14,1.67-0.28,2.51-0.41c-0.12-0.71-0.23-1.32-0.34-1.97C503.31,167.13,502.63,167.13,501.71,167.13z M549.83,593.06
	c0.04,0.79,0.06,1.39,0.1,2.09c0.79-0.05,1.45-0.1,2.21-0.15c-0.12-0.89-0.2-1.54-0.3-2.25
	C551.13,592.85,550.59,592.94,549.83,593.06z M652.41,568.07c0.92-0.06,1.6-0.1,2.37-0.15c-0.1-0.82-0.18-1.56-0.27-2.29
	c-0.85,0.16-1.54,0.3-2.29,0.44C652.28,566.73,652.34,567.35,652.41,568.07z M633.75,523.88c0.21,0.98,0.36,1.73,0.53,2.54
	c0.76-0.17,1.42-0.32,2.05-0.46c0-0.79,0-1.47,0-2.09C635.46,523.88,634.69,523.88,633.75,523.88z M547.22,329.29
	c-0.85,0.18-1.53,0.32-2.29,0.48c0.05,0.69,0.1,1.3,0.15,1.9c0.88,0,1.65,0,2.46,0C547.43,330.89,547.34,330.2,547.22,329.29z
	 M581.45,169.8c0.86-0.05,1.69-0.1,2.54-0.14c-0.14-0.84-0.27-1.59-0.41-2.42c-0.75,0.13-1.42,0.25-2.13,0.37
	C581.45,168.38,581.45,169.08,581.45,169.8z M430.85,16.75c0.97-0.05,1.72-0.09,2.54-0.14c-0.19-0.81-0.35-1.54-0.52-2.27
	c-0.74,0.06-1.35,0.12-2.02,0.17C430.85,15.27,430.85,15.96,430.85,16.75z M540.06,329.9c-0.74,0.11-1.48,0.23-2.2,0.34
	c0,0.82,0,1.55,0,2.29c0.89-0.09,1.64-0.17,2.37-0.24C540.18,331.48,540.13,330.77,540.06,329.9z M492.26,54.6
	c0.73-0.06,1.41-0.13,2.19-0.19c-0.06-0.82-0.11-1.55-0.16-2.27c-0.94,0.11-1.7,0.2-2.46,0.29C492,53.22,492.13,53.91,492.26,54.6z
	 M344.21,14.59c0.06,0.9,0.11,1.57,0.16,2.36c0.86-0.09,1.53-0.15,2.27-0.23c-0.12-0.83-0.22-1.49-0.31-2.13
	C345.59,14.59,345.03,14.59,344.21,14.59z M595.81,374.46c0.91-0.13,1.57-0.22,2.26-0.32c-0.1-0.81-0.18-1.43-0.27-2.12
	c-0.79,0.06-1.48,0.12-2.18,0.17C595.67,372.96,595.73,373.59,595.81,374.46z M577.86,310.94c0-0.85,0-1.53,0-2.34
	c-0.78,0.07-1.44,0.14-2.23,0.21c0.04,0.76,0.07,1.44,0.11,2.23C576.49,311,577.09,310.97,577.86,310.94z M629.27,566.71
	c-0.08-0.76-0.16-1.5-0.26-2.35c-0.77,0.1-1.43,0.18-2.15,0.27c0.05,0.79,0.08,1.4,0.12,2.08
	C627.75,566.71,628.44,566.71,629.27,566.71z M606.51,221.74c-0.14-0.88-0.25-1.54-0.34-2.14c-0.87,0.04-1.55,0.07-2.31,0.11
	c0.09,0.73,0.17,1.41,0.26,2.15C604.92,221.82,605.61,221.78,606.51,221.74z M376,21.53c-0.08-0.75-0.16-1.43-0.24-2.12
	c-0.89,0-1.66,0-2.48,0c0.11,0.76,0.21,1.44,0.31,2.12C374.4,21.53,375.16,21.53,376,21.53z M562.69,297.91
	c-0.09-0.81-0.17-1.57-0.25-2.39c-0.84,0.17-1.52,0.31-2.24,0.46c0.04,0.71,0.08,1.32,0.11,1.93
	C561.17,297.91,561.88,297.91,562.69,297.91z M267.77,92.23c0.89,0,1.65,0,2.54,0c-0.2-0.85-0.36-1.58-0.54-2.33
	c-0.8,0.04-1.46,0.08-2.1,0.11C267.71,90.85,267.73,91.48,267.77,92.23z M594.3,397.61c0.08,0.84,0.14,1.49,0.21,2.18
	c0.86-0.04,1.53-0.07,2.27-0.11c-0.14-0.82-0.26-1.49-0.39-2.2C595.69,397.52,595.15,397.56,594.3,397.61z M571.6,529.6
	c-0.82,0.12-1.48,0.22-2.07,0.3c0,0.84,0,1.52,0,2.35c0.89-0.18,1.68-0.33,2.46-0.49C571.84,530.99,571.74,530.38,571.6,529.6z
	 M596.72,357.49c-0.72,0.09-1.47,0.17-2.27,0.27c0.09,0.76,0.16,1.44,0.25,2.23c0.76-0.04,1.46-0.07,2.13-0.1
	C596.8,359.07,596.76,358.36,596.72,357.49z M641.97,533.02c0.97-0.05,1.78-0.09,2.6-0.13c-0.19-0.9-0.35-1.65-0.53-2.47
	c-0.74,0.17-1.39,0.32-2.07,0.48C641.97,531.62,641.97,532.24,641.97,533.02z M329.05,48.44c0.04,0.8,0.08,1.56,0.12,2.4
	c0.78-0.05,1.52-0.11,2.29-0.16c-0.07-0.87-0.13-1.65-0.19-2.42C330.49,48.33,329.8,48.38,329.05,48.44z M451.42,18.56
	c-0.07-0.88-0.13-1.64-0.2-2.45c-0.81,0.1-1.53,0.2-2.23,0.28c0.05,0.85,0.08,1.54,0.12,2.27C449.81,18.64,450.5,18.6,451.42,18.56z
	 M538.12,268.91c-0.77,0-1.46,0-2.21,0c0,0.84,0,1.61,0,2.46c0.91-0.11,1.74-0.2,2.68-0.31
	C538.42,270.27,538.27,269.6,538.12,268.91z M618.12,271.43c-0.07-0.86-0.14-1.65-0.21-2.47c-0.85,0.15-1.59,0.27-2.38,0.41
	c0.04,0.76,0.08,1.42,0.11,2.06C616.51,271.43,617.27,271.43,618.12,271.43z M575.45,116.04c0-0.84,0-1.58,0-2.36
	c-0.81,0.1-1.49,0.19-2.3,0.29c0.04,0.78,0.08,1.45,0.12,2.27C574.08,116.16,574.75,116.1,575.45,116.04z M609.32,284.22
	c0.16,0.91,0.29,1.63,0.42,2.39c0.84-0.15,1.51-0.27,2.25-0.4c-0.07-0.72-0.13-1.33-0.19-1.99
	C611,284.22,610.29,284.22,609.32,284.22z M600.07,395.75c-0.07-0.8-0.14-1.55-0.21-2.33c-0.79,0.13-1.46,0.25-2.26,0.38
	c0.07,0.7,0.13,1.36,0.18,1.95C598.61,395.75,599.29,395.75,600.07,395.75z M494.33,471.38c-0.8,0.05-1.47,0.1-2.18,0.15
	c0.06,0.79,0.11,1.41,0.16,2.17c0.83-0.06,1.56-0.12,2.31-0.18C494.52,472.78,494.44,472.22,494.33,471.38z M367.03,45.24
	c-0.84,0.07-1.51,0.12-2.21,0.18c0.08,0.9,0.14,1.63,0.2,2.39c0.83-0.15,1.51-0.27,2.17-0.39C367.15,46.7,367.1,46.07,367.03,45.24z
	 M607.54,441.9c-0.14-0.76-0.26-1.44-0.38-2.1c-0.83,0.07-1.56,0.13-2.33,0.19c0.11,0.87,0.2,1.6,0.29,2.31
	C605.94,442.16,606.63,442.05,607.54,441.9z M498.55,44.72c0,0.79,0,1.53,0,2.22c0.89,0,1.65,0,2.51,0
	c-0.19-0.85-0.35-1.59-0.52-2.35C499.8,44.64,499.18,44.68,498.55,44.72z M594.82,172.27c-0.08-0.81-0.14-1.47-0.22-2.24
	c-0.8,0.08-1.45,0.14-2.17,0.21c0.1,0.82,0.18,1.48,0.26,2.17C593.47,172.37,594.07,172.33,594.82,172.27z M598.25,197.22
	c0.93-0.08,1.63-0.13,2.42-0.2c-0.07-0.78-0.15-1.55-0.22-2.33c-0.78,0.08-1.47,0.14-2.2,0.21
	C598.25,195.68,598.25,196.39,598.25,197.22z M287.69,73.69c0.14,0.86,0.25,1.61,0.37,2.38c0.75-0.11,1.37-0.19,2.11-0.3
	c-0.06-0.74-0.11-1.42-0.17-2.08C289.18,73.69,288.49,73.69,287.69,73.69z M500.59,210.59c-0.82,0.08-1.56,0.16-2.33,0.23
	c0.09,0.86,0.16,1.53,0.24,2.27c0.86-0.07,1.61-0.14,2.38-0.2C500.77,212.07,500.68,211.39,500.59,210.59z M341.74,45.97
	c-0.13-0.83-0.24-1.49-0.34-2.15c-0.76,0.05-1.36,0.08-2.01,0.12c0.07,0.86,0.12,1.61,0.18,2.37
	C340.33,46.2,340.94,46.1,341.74,45.97z M596.47,341.89c0.94-0.08,1.69-0.14,2.46-0.21c-0.16-0.86-0.3-1.59-0.44-2.35
	c-0.81,0.05-1.47,0.09-2.14,0.13C596.4,340.34,596.43,341.03,596.47,341.89z M549.61,271.18c-0.91,0.24-1.57,0.42-2.36,0.63
	c0.04,0.72,0.08,1.38,0.11,2.05c0.83,0,1.53,0,2.25,0C549.61,273.03,549.61,272.25,549.61,271.18z M549.79,96.6
	c0.86-0.06,1.53-0.1,2.32-0.15c-0.1-0.85-0.18-1.51-0.26-2.18c-0.79,0.04-1.4,0.07-2.06,0.1C549.79,95.09,549.79,95.71,549.79,96.6z
	 M542.39,340.86c0.05,0.81,0.08,1.48,0.12,2.09c0.94,0,1.75,0,2.56,0c-0.18-0.93-0.34-1.74-0.51-2.58
	C543.84,340.53,543.24,340.67,542.39,340.86z M407.39,36.65c0.94-0.07,1.69-0.13,2.45-0.19c-0.14-0.81-0.26-1.47-0.38-2.13
	c-0.84,0.06-1.57,0.11-2.33,0.16C407.22,35.21,407.29,35.83,407.39,36.65z M427.14,29.03c-0.79,0.15-1.44,0.27-2.1,0.39
	c0,0.81,0,1.5,0,2.29c0.75-0.08,1.43-0.16,2.1-0.23C427.14,30.66,427.14,29.91,427.14,29.03z M574.82,341.46c0,0.88,0,1.63,0,2.42
	c0.77-0.16,1.44-0.3,2.08-0.43c0-0.73,0-1.33,0-2.1C576.12,341.39,575.46,341.42,574.82,341.46z M368.19,41.48
	c0.07,0.89,0.12,1.56,0.17,2.31c0.91-0.07,1.6-0.13,2.38-0.19c-0.17-0.81-0.31-1.47-0.45-2.11
	C369.56,41.48,369.01,41.48,368.19,41.48z M624.84,542.95c0.12,0.84,0.22,1.55,0.32,2.28c0.8-0.04,1.41-0.07,2.17-0.11
	c-0.08-0.83-0.14-1.5-0.2-2.16C626.35,542.95,625.67,542.95,624.84,542.95z M419.56,24.99c-0.1-0.89-0.18-1.56-0.27-2.31
	c-0.81,0.06-1.49,0.11-2.21,0.16c0.08,0.81,0.15,1.49,0.22,2.26C418.07,25.07,418.68,25.04,419.56,24.99z M460.75,112.63
	c0.25,0.95,0.45,1.68,0.64,2.38c0.8-0.07,1.47-0.12,2.1-0.18c-0.07-0.86-0.12-1.55-0.17-2.21
	C462.5,112.63,461.74,112.63,460.75,112.63z M493.7,171.47c-0.84,0.05-1.57,0.1-2.33,0.14c0.16,0.77,0.28,1.37,0.44,2.12
	c0.7-0.05,1.37-0.1,2.08-0.15C493.82,172.8,493.77,172.18,493.7,171.47z M619.14,560.18c0.8-0.11,1.41-0.19,2.1-0.28
	c-0.05-0.78-0.1-1.45-0.14-2.08c-0.86,0-1.54,0-2.32,0C618.91,558.62,619.01,559.3,619.14,560.18z M451.33,97.95
	c-0.07-0.89-0.13-1.56-0.18-2.19c-0.96,0-1.73,0-2.61,0c0.18,0.82,0.35,1.54,0.49,2.19C449.85,97.95,450.47,97.95,451.33,97.95z
	 M628.19,557.29c-0.8,0.04-1.4,0.07-2.13,0.1c0.12,0.85,0.21,1.5,0.31,2.21c0.86-0.06,1.55-0.11,2.25-0.16
	C628.47,558.72,628.35,558.1,628.19,557.29z M643.37,547.13c0.82-0.08,1.49-0.14,2.18-0.21c-0.08-0.8-0.14-1.42-0.2-2.04
	c-0.87,0-1.64,0-2.42,0C643.08,545.62,643.2,546.24,643.37,547.13z M444,96.19c-0.81,0.09-1.57,0.17-2.44,0.26
	c0.1,0.79,0.19,1.53,0.29,2.28c0.81-0.1,1.48-0.18,2.16-0.26C444,97.66,444,96.96,444,96.19z M430.51,25.16
	c-0.89,0.16-1.56,0.28-2.26,0.4c0,0.78,0,1.47,0,2.18c0.83,0,1.59,0,2.4,0C430.61,26.91,430.57,26.14,430.51,25.16z M369.15,29.3
	c0-0.82,0-1.62,0-2.48c-0.76,0.13-1.48,0.26-2.23,0.39c0.12,0.8,0.22,1.46,0.31,2.1C367.92,29.3,368.47,29.3,369.15,29.3z
	 M569.42,199.84c0.99-0.22,1.72-0.38,2.49-0.56c-0.1-0.72-0.19-1.32-0.29-1.98c-0.81,0.04-1.5,0.08-2.2,0.11
	C569.42,198.21,569.42,198.9,569.42,199.84z M424.49,40.14c-0.88,0-1.7,0-2.58,0c0.18,0.88,0.34,1.68,0.5,2.52
	c0.74-0.14,1.41-0.26,2.08-0.39C424.49,41.51,424.49,40.83,424.49,40.14z M629.96,436.41c0.92-0.09,1.67-0.16,2.45-0.23
	c-0.11-0.82-0.2-1.5-0.3-2.25c-0.84,0.16-1.58,0.3-2.31,0.44C629.86,435.06,629.9,435.61,629.96,436.41z M631.28,548.45
	c-0.16-0.88-0.27-1.49-0.38-2.11c-0.73,0-1.41,0-2.18,0c0.07,0.79,0.13,1.45,0.19,2.11C629.72,548.45,630.4,548.45,631.28,548.45z
	 M574.28,352.35c-0.78,0.05-1.51,0.1-2.22,0.14c0.07,0.88,0.12,1.58,0.18,2.37c0.79-0.14,1.52-0.26,2.26-0.39
	C574.41,353.7,574.35,353.08,574.28,352.35z M489.23,145.35c0.86-0.07,1.6-0.13,2.34-0.19c-0.18-0.86-0.33-1.62-0.5-2.44
	c-0.7,0.08-1.36,0.17-2.04,0.25C489.09,143.76,489.15,144.45,489.23,145.35z M654.03,547.7c0.15,0.84,0.27,1.51,0.38,2.12
	c0.83,0,1.52,0,2.34,0c-0.09-0.8-0.17-1.54-0.25-2.29C655.63,547.59,654.93,547.63,654.03,547.7z M515.53,198.42
	c0.06,0.78,0.11,1.44,0.16,2.21c0.77-0.05,1.44-0.09,2.2-0.14c-0.09-0.81-0.16-1.47-0.23-2.18
	C516.91,198.34,516.3,198.38,515.53,198.42z M569.65,131.04c-0.07-0.82-0.14-1.51-0.21-2.34c-0.77,0.09-1.52,0.17-2.33,0.26
	c0.19,0.85,0.33,1.52,0.48,2.18C568.36,131.1,568.98,131.07,569.65,131.04z M530.48,206.84c0.98-0.13,1.73-0.22,2.54-0.32
	c-0.16-0.85-0.29-1.6-0.44-2.38c-0.83,0.18-1.49,0.32-2.1,0.45C530.48,205.41,530.48,206.04,530.48,206.84z M647.13,550.51
	c0.86-0.06,1.52-0.12,2.29-0.17c-0.08-0.82-0.15-1.49-0.22-2.22c-0.79,0.04-1.47,0.08-2.2,0.12
	C647.04,549.01,647.07,549.62,647.13,550.51z M611.29,276.85c-0.79,0.1-1.54,0.19-2.38,0.3c0.14,0.84,0.25,1.56,0.37,2.3
	c0.85-0.05,1.53-0.09,2.28-0.14C611.46,278.44,611.38,277.68,611.29,276.85z M564.08,135.1c0.86-0.08,1.55-0.15,2.36-0.23
	c-0.07-0.73-0.13-1.4-0.19-2.05c-0.87,0-1.63,0-2.48,0C563.87,133.59,563.97,134.27,564.08,135.1z M467.55,147.15
	c0.81-0.09,1.56-0.18,2.45-0.29c-0.09-0.73-0.17-1.39-0.25-2.03c-0.91,0-1.66,0-2.49,0C467.35,145.64,467.45,146.38,467.55,147.15z
	 M556.15,99.82c-0.21-0.86-0.36-1.52-0.53-2.23c-0.77,0.11-1.37,0.19-2.02,0.28c0.09,0.75,0.17,1.36,0.24,1.95
	C554.62,99.82,555.25,99.82,556.15,99.82z M386.96,10.7c0.15,0.98,0.26,1.73,0.37,2.51c0.78-0.11,1.46-0.2,2.21-0.31
	c-0.06-0.78-0.11-1.47-0.17-2.21C388.58,10.7,387.88,10.7,386.96,10.7z M559.62,346.24c-0.9,0.08-1.72,0.15-2.55,0.22
	c0.16,0.79,0.31,1.48,0.46,2.21c0.73-0.06,1.41-0.11,2.1-0.16C559.62,347.69,559.62,346.99,559.62,346.24z M561.85,277.54
	c0.16,0.82,0.28,1.49,0.41,2.15c0.83-0.05,1.57-0.09,2.38-0.14c-0.12-0.82-0.22-1.55-0.32-2.23
	C563.45,277.4,562.75,277.46,561.85,277.54z M379.18,15.11c-0.95,0.19-1.7,0.33-2.47,0.48c0.13,0.79,0.24,1.46,0.36,2.16
	c0.79-0.07,1.48-0.13,2.12-0.19C379.18,16.76,379.18,16.05,379.18,15.11z M569.2,110.63c0.11,0.78,0.2,1.44,0.28,2.06
	c0.8,0,1.4,0,2.18,0c-0.06-0.77-0.1-1.44-0.15-2.06C570.7,110.63,570,110.63,569.2,110.63z M499.06,552.65
	c0.83-0.12,1.66-0.24,2.55-0.36c-0.1-0.73-0.18-1.41-0.28-2.12c-0.84,0.11-1.6,0.21-2.38,0.31
	C498.99,551.21,499.02,551.91,499.06,552.65z M327.03,40.18c-0.14-0.84-0.24-1.44-0.34-2.04c-0.8,0-1.49,0-2.28,0
	c0.09,0.75,0.16,1.41,0.25,2.16C325.41,40.26,326.1,40.23,327.03,40.18z M447.72,99.6c-0.79,0.09-1.53,0.17-2.28,0.25
	c0.08,0.85,0.15,1.54,0.22,2.34c0.83-0.18,1.55-0.33,2.27-0.48C447.86,100.94,447.79,100.32,447.72,99.6z M364.52,40.44
	c0.9-0.09,1.56-0.16,2.24-0.23c-0.07-0.85-0.13-1.53-0.2-2.32c-0.78,0.18-1.44,0.33-2.05,0.47
	C364.52,39.07,364.52,39.61,364.52,40.44z M536.25,202.55c-0.05-0.77-0.1-1.46-0.15-2.22c-0.91,0.06-1.74,0.11-2.59,0.16
	c0.17,0.8,0.31,1.48,0.46,2.2C534.74,202.64,535.43,202.6,536.25,202.55z M624.6,556.14c-0.06-0.89-0.11-1.51-0.15-2.11
	c-0.76,0-1.44,0-2.24,0c0.09,0.81,0.16,1.47,0.24,2.11C623.19,556.14,623.8,556.14,624.6,556.14z M496.04,200.36
	c-0.82,0-1.53,0-2.23,0c0.06,0.88,0.11,1.69,0.16,2.48c0.84-0.16,1.53-0.29,2.27-0.43C496.18,201.77,496.12,201.16,496.04,200.36z
	 M596.84,193.64c-0.07-0.7-0.15-1.37-0.23-2.15c-0.77,0.08-1.45,0.16-2.26,0.24c0.04,0.75,0.08,1.41,0.12,2.08
	C595.35,193.75,596.03,193.7,596.84,193.64z M567.11,503.28c0.82-0.07,1.61-0.13,2.4-0.19c-0.09-0.86-0.16-1.55-0.23-2.27
	c-0.74,0.07-1.43,0.13-2.17,0.2C567.11,501.74,567.11,502.43,567.11,503.28z M484.29,143.09c-0.96,0.26-1.69,0.45-2.42,0.65
	c0.12,0.75,0.21,1.37,0.31,2c0.76-0.07,1.44-0.14,2.11-0.2C484.29,144.72,484.29,144.02,484.29,143.09z M577.63,290.65
	c0.06,0.74,0.11,1.34,0.17,2.06c0.73-0.06,1.33-0.12,2.03-0.18c-0.04-0.73-0.08-1.33-0.11-1.88
	C578.95,290.65,578.34,290.65,577.63,290.65z M634.05,415.93c-0.88,0-1.57,0-2.37,0c0.07,0.74,0.14,1.42,0.21,2.21
	c0.84-0.06,1.58-0.11,2.38-0.16C634.19,417.22,634.12,416.61,634.05,415.93z M407.29,76.56c0.79-0.13,1.45-0.24,2.13-0.35
	c-0.13-0.78-0.23-1.44-0.33-2.01c-0.84,0-1.53,0-2.22,0C407.02,74.97,407.13,75.65,407.29,76.56z M554.02,352.79
	c0.76-0.05,1.49-0.09,2.35-0.15c-0.15-0.85-0.28-1.5-0.4-2.18c-0.79,0.05-1.39,0.08-2.06,0.13
	C553.95,351.38,553.98,352,554.02,352.79z M494.29,162.81c0.81-0.04,1.56-0.08,2.4-0.12c-0.14-0.88-0.26-1.61-0.38-2.34
	c-0.84,0.05-1.51,0.09-2.2,0.14C494.18,161.35,494.23,162.03,494.29,162.81z M617.94,300.45c0.99-0.19,1.75-0.34,2.52-0.49
	c-0.13-0.71-0.24-1.33-0.37-2.05c-0.73,0.06-1.42,0.12-2.15,0.18C617.94,298.87,617.94,299.58,617.94,300.45z M644.83,579.71
	c-0.1-0.94-0.17-1.62-0.25-2.4c-0.8,0.08-1.49,0.15-2.31,0.23c0.13,0.79,0.24,1.47,0.36,2.18
	C643.38,579.71,644.01,579.71,644.83,579.71z M617.51,290.87c0,0.86,0,1.54,0,2.22c0.88-0.05,1.64-0.09,2.49-0.13
	c-0.14-0.73-0.26-1.41-0.41-2.18C618.91,290.81,618.28,290.84,617.51,290.87z M474.83,146.38c0.73,0,1.42,0,2.21,0
	c-0.07-0.8-0.13-1.54-0.2-2.34c-0.75,0.12-1.36,0.22-2.1,0.33C474.77,145.06,474.8,145.68,474.83,146.38z M555.93,247.81
	c0.66-0.06,1.37-0.13,2.18-0.2c-0.14-0.89-0.25-1.61-0.37-2.36c-0.84,0.05-1.5,0.09-2.23,0.14
	C555.65,246.25,555.77,246.93,555.93,247.81z M479.56,161.83c0.09,0.82,0.17,1.54,0.24,2.26c0.87-0.07,1.55-0.13,2.35-0.19
	c-0.09-0.76-0.17-1.49-0.25-2.26C481.05,161.71,480.38,161.76,479.56,161.83z M594.99,367.33c0.81-0.11,1.54-0.21,2.32-0.32
	c-0.07-0.83-0.13-1.5-0.19-2.21c-0.86,0.05-1.52,0.08-2.13,0.12C594.99,365.81,594.99,366.5,594.99,367.33z M603.62,438.93
	c-0.06-0.8-0.12-1.62-0.18-2.5c-0.81,0.12-1.55,0.23-2.28,0.34c0.07,0.81,0.12,1.49,0.17,2.15
	C602.12,438.93,602.82,438.93,603.62,438.93z M600.95,183.94c0.04,0.77,0.07,1.38,0.1,2.05c0.81,0,1.56,0,2.44,0
	c-0.05-0.79-0.1-1.54-0.15-2.38C602.54,183.72,601.87,183.81,600.95,183.94z M505.63,183.55c0-0.83,0-1.68,0-2.57
	c-0.9,0.25-1.65,0.45-2.45,0.67c0.15,0.67,0.29,1.28,0.43,1.9C504.33,183.55,504.96,183.55,505.63,183.55z M511.99,202.3
	c0.15,0.84,0.28,1.58,0.42,2.37c0.77-0.12,1.43-0.22,2.12-0.33c-0.12-0.79-0.22-1.45-0.31-2.04
	C513.38,202.3,512.69,202.3,511.99,202.3z M590.42,412.34c-0.89,0.06-1.55,0.1-2.19,0.15c0.05,0.91,0.08,1.59,0.12,2.34
	c0.77-0.1,1.45-0.2,2.2-0.3C590.51,413.79,590.47,413.16,590.42,412.34z M408.81,66.75c-0.77,0.14-1.49,0.27-2.25,0.4
	c0.11,0.77,0.19,1.36,0.27,1.89c0.83,0,1.43,0,2.1,0C408.88,68.26,408.85,67.57,408.81,66.75z M596.27,186.71
	c-0.18-0.95-0.31-1.61-0.43-2.19c-0.8,0-1.41,0-2.15,0c0.06,0.78,0.12,1.46,0.18,2.19C594.67,186.71,595.37,186.71,596.27,186.71z
	 M413.97,68.7c0.85-0.06,1.57-0.1,2.35-0.15c-0.17-0.9-0.3-1.57-0.44-2.27c-0.75,0.1-1.35,0.19-1.91,0.27
	C413.97,67.33,413.97,67.95,413.97,68.7z M572.75,605.52c0,0.84,0,1.51,0,2.2c0.8-0.08,1.49-0.14,2.27-0.22
	c-0.12-0.82-0.23-1.54-0.35-2.31C573.98,605.31,573.45,605.4,572.75,605.52z M446.18,107.1c0,0.87,0,1.55,0,2.28
	c0.85-0.07,1.61-0.13,2.44-0.2c-0.13-0.7-0.25-1.37-0.37-2.08C447.56,107.1,446.94,107.1,446.18,107.1z M577.65,498.78
	c0.79-0.12,1.45-0.23,2.12-0.33c-0.08-0.79-0.15-1.41-0.21-2.02c-0.82,0-1.59,0-2.37,0C577.34,497.19,577.47,497.88,577.65,498.78z
	 M516.2,413.67c0.75-0.04,1.37-0.07,2-0.1c0-0.79,0-1.54,0-2.34c-0.85,0.1-1.61,0.18-2.41,0.27
	C515.92,412.22,516.03,412.83,516.2,413.67z M323.8,30.93c0.07,0.98,0.12,1.72,0.18,2.47c0.79-0.13,1.46-0.24,2.23-0.37
	c-0.11-0.75-0.2-1.41-0.29-2.1C325.13,30.93,324.52,30.93,323.8,30.93z M386.88,21.85c-0.94,0.07-1.74,0.13-2.61,0.19
	c0.18,0.77,0.33,1.42,0.49,2.12c0.8-0.05,1.49-0.09,2.25-0.14C386.96,23.25,386.92,22.58,386.88,21.85z M443.83,40.81
	c0.94-0.13,1.62-0.21,2.31-0.31c-0.08-0.75-0.15-1.37-0.22-2c-0.75,0-1.44,0-2.28,0C443.7,39.28,443.76,39.96,443.83,40.81z
	 M549.94,347.12c0.12,0.85,0.22,1.58,0.33,2.32c0.79-0.04,1.41-0.07,2.11-0.1c-0.05-0.8-0.1-1.54-0.15-2.33
	C551.43,347.05,550.74,347.08,549.94,347.12z M578.88,510.87c0.04,0.82,0.08,1.43,0.11,2.16c0.81-0.05,1.48-0.09,2.16-0.13
	c-0.04-0.86-0.08-1.55-0.12-2.29C580.32,510.7,579.69,510.77,578.88,510.87z M589.19,176.49c0.94-0.19,1.61-0.33,2.35-0.48
	c-0.06-0.75-0.12-1.42-0.18-2.18c-0.82,0.04-1.49,0.08-2.17,0.11C589.19,174.83,589.19,175.59,589.19,176.49z M390.65,64.81
	c-0.86,0.11-1.54,0.2-2.36,0.3c0.06,0.75,0.11,1.44,0.17,2.27c0.79-0.05,1.48-0.09,2.19-0.14
	C390.65,66.47,390.65,65.77,390.65,64.81z M589.45,233.99c-0.11-0.71-0.2-1.31-0.31-2c-0.69,0.07-1.28,0.13-1.99,0.2
	c0.08,0.71,0.15,1.29,0.21,1.8C588.13,233.99,588.73,233.99,589.45,233.99z M545.32,250.15c0.04,0.78,0.07,1.4,0.1,2.02
	c0.8,0,1.54,0,2.36,0c-0.08-0.87-0.15-1.62-0.22-2.37C546.78,249.92,546.16,250.02,545.32,250.15z M632.39,549.47
	c0.2,0.92,0.35,1.65,0.5,2.38c0.75-0.17,1.35-0.3,1.98-0.43c0-0.72,0-1.38,0-2.11C634.04,549.36,633.29,549.41,632.39,549.47z
	 M388,38.33c-0.07-0.85-0.12-1.45-0.17-1.99c-0.84,0-1.5,0-2.25,0c0.12,0.8,0.23,1.48,0.35,2.27
	C386.61,38.52,387.22,38.43,388,38.33z M412.01,65.19c-0.04-0.74-0.08-1.4-0.12-2.16c-0.82,0.05-1.48,0.08-2.14,0.12
	c0.04,0.84,0.07,1.52,0.11,2.32C410.55,65.38,411.22,65.3,412.01,65.19z M463.77,62.09c0.07,0.84,0.12,1.51,0.17,2.14
	c0.96,0,1.72,0,2.61,0c-0.18-0.82-0.33-1.54-0.49-2.27C465.26,62,464.64,62.04,463.77,62.09z M292.71,62.39
	c-0.98,0.11-1.7,0.19-2.43,0.27c0.16,0.93,0.27,1.61,0.4,2.34c0.77-0.12,1.43-0.22,2.03-0.31
	C292.71,63.84,292.71,63.22,292.71,62.39z M536.93,322.95c0.11,0.77,0.22,1.51,0.33,2.32c0.75-0.07,1.43-0.13,2.19-0.2
	c-0.06-0.73-0.11-1.41-0.16-2.12C538.51,322.95,537.8,322.95,536.93,322.95z M568.69,493.68c-0.8,0.06-1.56,0.11-2.41,0.17
	c0.12,0.81,0.23,1.54,0.36,2.36c0.77-0.16,1.43-0.3,2.05-0.43C568.69,494.98,568.69,494.37,568.69,493.68z M393.87,63.24
	c-0.11-0.73-0.2-1.38-0.29-2c-0.8,0-1.41,0-1.99,0c0,0.8,0,1.54,0,2.33C392.39,63.45,393.06,63.35,393.87,63.24z M540.95,589.96
	c-0.91,0.22-1.57,0.38-2.16,0.53c0,0.79,0,1.4,0,2.12c0.8-0.09,1.54-0.17,2.31-0.25C541.05,591.54,541.01,590.85,540.95,589.96z
	 M251.79,116.75c0.04,0.79,0.09,1.54,0.13,2.37c0.82-0.19,1.5-0.34,2.24-0.51c-0.04-0.76-0.07-1.45-0.1-2.15
	C253.25,116.57,252.56,116.65,251.79,116.75z M436.96,99.44c-0.17-0.9-0.3-1.57-0.43-2.22c-0.8,0-1.48,0-2.29,0
	c0.13,0.84,0.25,1.56,0.35,2.22C435.4,99.44,436.03,99.44,436.96,99.44z M268.43,99.67c0.91-0.13,1.65-0.24,2.45-0.36
	c-0.24-0.88-0.43-1.61-0.63-2.33c-0.75,0.07-1.35,0.13-1.94,0.19C268.36,98.04,268.39,98.75,268.43,99.67z M574,347.47
	c-0.05-0.97-0.09-1.71-0.13-2.51c-0.87,0.15-1.61,0.27-2.35,0.4c0.11,0.82,0.19,1.48,0.28,2.11
	C572.6,347.47,573.22,347.47,574,347.47z M515.23,572.94c0.91-0.07,1.6-0.13,2.45-0.19c-0.09-0.76-0.18-1.5-0.27-2.29
	c-0.85,0.1-1.51,0.18-2.17,0.26C515.23,571.52,515.23,572.15,515.23,572.94z M471.55,121.68c-0.17-1.01-0.3-1.75-0.44-2.58
	c-0.82,0.24-1.48,0.43-2.13,0.63c0.06,0.75,0.11,1.35,0.16,1.95C469.98,121.68,470.68,121.68,471.55,121.68z M606.94,421.64
	c0.14,0.77,0.27,1.44,0.41,2.22c0.78-0.05,1.52-0.1,2.29-0.15c-0.07-0.83-0.12-1.49-0.17-2.07
	C608.51,421.64,607.77,421.64,606.94,421.64z M492.72,61.91c0.84-0.13,1.46-0.23,2.09-0.32c0-0.75,0-1.44,0-2.29
	c-0.78,0.14-1.43,0.26-2.09,0.38C492.72,60.47,492.72,61.11,492.72,61.91z M572.16,325.84c-0.06-0.77-0.12-1.44-0.17-2.05
	c-0.93,0-1.72,0-2.58,0c0.19,0.81,0.34,1.46,0.52,2.21C570.71,325.95,571.39,325.9,572.16,325.84z M640.68,516.37
	c0.07,0.82,0.14,1.58,0.21,2.39c0.77-0.15,1.43-0.28,2.05-0.4c-0.05-0.83-0.1-1.52-0.15-2.31
	C642.09,516.17,641.42,516.26,640.68,516.37z M635.83,516.74c-0.92,0.15-1.61,0.26-2.34,0.37c0.08,0.71,0.15,1.33,0.22,2.02
	c0.82-0.08,1.55-0.15,2.29-0.22C635.94,518.12,635.89,517.5,635.83,516.74z M464.85,116.13c0.05,0.81,0.09,1.57,0.14,2.47
	c0.78-0.19,1.51-0.37,2.25-0.55c-0.04-0.81-0.08-1.48-0.11-2.15C466.27,115.98,465.59,116.05,464.85,116.13z M382.6,40.53
	c0.06,0.82,0.1,1.42,0.14,2.03c0.86-0.04,1.55-0.08,2.36-0.12c-0.13-0.79-0.26-1.54-0.39-2.34C384,40.25,383.39,40.37,382.6,40.53z
	 M560.4,355.82c-0.04-0.87-0.08-1.68-0.12-2.5c-0.85,0.21-1.6,0.4-2.4,0.6c0.12,0.65,0.23,1.26,0.35,1.91
	C558.96,355.82,559.65,355.82,560.4,355.82z M355.88,24.57c0.1,0.78,0.19,1.49,0.28,2.23c0.84-0.15,1.44-0.26,2.02-0.36
	c0-0.81,0-1.5,0-2.29C357.39,24.29,356.7,24.42,355.88,24.57z M308.34,59.91c0.92,0,1.67,0,2.52,0c-0.16-0.81-0.31-1.55-0.48-2.38
	c-0.71,0.07-1.37,0.13-2.04,0.19C308.34,58.52,308.34,59.13,308.34,59.91z M573.95,181.18c0-0.65,0-1.32,0-2.02
	c-0.89,0-1.65,0-2.5,0c0.12,0.77,0.23,1.43,0.35,2.14C572.54,181.26,573.23,181.22,573.95,181.18z M595.29,425.16
	c-0.18-0.92-0.3-1.58-0.44-2.26c-0.78,0.04-1.38,0.06-2.12,0.1c0.06,0.78,0.12,1.45,0.17,2.16
	C593.71,425.16,594.4,425.16,595.29,425.16z M554.24,313.05c0.84-0.05,1.6-0.1,2.41-0.15c-0.1-0.81-0.18-1.49-0.26-2.14
	c-0.81,0-1.54,0-2.29,0C554.16,311.6,554.19,312.29,554.24,313.05z M574.64,301.54c0.22,0.88,0.38,1.56,0.57,2.34
	c0.73-0.05,1.39-0.1,2.12-0.16c-0.1-0.81-0.19-1.48-0.28-2.18C576.26,301.54,575.57,301.54,574.64,301.54z M595.01,407.06
	c0.85-0.05,1.51-0.09,2.27-0.14c-0.14-0.84-0.25-1.51-0.38-2.25c-0.71,0.05-1.29,0.1-1.89,0.14
	C595.01,405.57,595.01,406.18,595.01,407.06z M393.08,38.07c0.81-0.1,1.48-0.18,2.21-0.27c-0.09-0.79-0.17-1.39-0.24-1.98
	c-0.78,0-1.44,0-2.11,0C392.99,36.57,393.03,37.19,393.08,38.07z M604.98,521.69c0.88-0.07,1.48-0.11,2.18-0.16
	c-0.1-0.81-0.18-1.47-0.26-2.19c-0.73,0.07-1.33,0.14-1.92,0.2C604.98,520.27,604.98,520.87,604.98,521.69z M597.01,388.7
	c0.79,0,1.55,0,2.44,0c-0.18-0.82-0.34-1.53-0.48-2.19c-0.78,0-1.41,0-2.12,0C596.9,387.26,596.95,387.95,597.01,388.7z
	 M645.91,591.77c-0.82,0.06-1.43,0.1-2.18,0.16c0.08,0.82,0.14,1.48,0.19,2.04c0.79,0,1.39,0,2.1,0
	C645.99,593.24,645.95,592.64,645.91,591.77z M658.98,576.09c-0.82,0.12-1.59,0.24-2.39,0.35c0.04,0.87,0.07,1.54,0.1,2.35
	c0.85-0.15,1.58-0.28,2.29-0.41C658.98,577.59,658.98,576.97,658.98,576.09z M410.33,41.24c-0.83,0.11-1.59,0.22-2.41,0.33
	c0.13,0.78,0.25,1.44,0.36,2.12c0.77,0,1.39,0,2.05,0C410.33,42.87,410.33,42.1,410.33,41.24z M630.92,569.94
	c0.78-0.09,1.4-0.15,2.08-0.23c0-0.72,0-1.42,0-2.19c-0.87,0.1-1.67,0.18-2.48,0.27C630.66,568.56,630.78,569.18,630.92,569.94z
	 M637.96,569.47c0.96-0.04,1.7-0.08,2.48-0.11c-0.16-0.8-0.3-1.48-0.46-2.26c-0.72,0.07-1.37,0.13-2.03,0.19
	C637.96,568.1,637.96,568.72,637.96,569.47z M326.85,26.73c0.19,0.77,0.38,1.51,0.58,2.3c0.72-0.06,1.32-0.11,1.93-0.16
	c0-0.82,0-1.45,0-2.14C328.55,26.73,327.78,26.73,326.85,26.73z M558.56,360.97c0,0.74,0,1.41,0,2.14c0.87-0.07,1.62-0.12,2.37-0.18
	c-0.09-0.79-0.17-1.48-0.25-2.2C559.94,360.81,559.25,360.89,558.56,360.97z M333.53,19.13c0.1,0.84,0.19,1.59,0.28,2.44
	c0.77-0.17,1.44-0.31,2.12-0.45c0-0.76,0-1.43,0-2.13C335.08,19.03,334.33,19.08,333.53,19.13z M511.22,567.28
	c0.11,0.84,0.21,1.57,0.31,2.31c0.86-0.11,1.54-0.19,2.26-0.28c-0.08-0.79-0.15-1.47-0.23-2.19
	C512.75,567.17,512.07,567.22,511.22,567.28z M620.27,545.92c-0.14-0.8-0.29-1.59-0.44-2.43c-0.79,0.13-1.39,0.23-2.1,0.35
	c0.05,0.76,0.09,1.43,0.13,2.08C618.72,545.92,619.42,545.92,620.27,545.92z M536.22,329c-0.14-0.85-0.24-1.44-0.33-1.97
	c-0.72,0-1.32,0-2.06,0c0.07,0.8,0.13,1.46,0.2,2.19C534.76,329.15,535.36,329.09,536.22,329z M589.67,486.98
	c-0.12-0.77-0.22-1.43-0.32-2.07c-0.78,0-1.39,0-2.07,0c0.05,0.81,0.09,1.55,0.14,2.35C588.2,487.16,588.87,487.07,589.67,486.98z
	 M498.68,189.29c-0.78,0.04-1.39,0.07-2.05,0.1c0.07,0.82,0.13,1.49,0.19,2.22c0.76-0.11,1.37-0.19,2.06-0.29
	C498.81,190.64,498.76,190.09,498.68,189.29z M456.74,120.72c-0.79,0-1.55,0-2.37,0c0.11,0.8,0.2,1.48,0.31,2.31
	c0.75-0.05,1.45-0.09,2.22-0.14C456.85,122.15,456.8,121.45,456.74,120.72z M616.19,445.18c0.95-0.14,1.7-0.25,2.54-0.37
	c-0.21-0.87-0.39-1.61-0.57-2.35c-0.75,0.05-1.38,0.1-1.97,0.14C616.19,443.46,616.19,444.24,616.19,445.18z M611.94,273
	c0.13,0.87,0.24,1.62,0.37,2.52c0.79-0.21,1.49-0.39,2.21-0.58c-0.07-0.76-0.12-1.36-0.18-1.94C613.51,273,612.81,273,611.94,273z
	 M423.68,54.92c0,0.73,0,1.4,0,2.16c0.82-0.1,1.56-0.19,2.3-0.27c-0.07-0.83-0.12-1.52-0.19-2.31
	C425.04,54.65,424.38,54.78,423.68,54.92z M615.88,522.16c0,0.87,0,1.56,0,2.37c0.83-0.14,1.56-0.26,2.39-0.4
	c-0.08-0.85-0.14-1.58-0.2-2.32C617.25,521.94,616.63,522.04,615.88,522.16z M402.47,37.05c-0.05-0.88-0.09-1.55-0.13-2.23
	c-0.81,0.08-1.48,0.15-2.28,0.23c0.09,0.74,0.18,1.46,0.28,2.22C401.08,37.19,401.68,37.13,402.47,37.05z M639.34,541.96
	c0.04,0.71,0.09,1.37,0.13,2.06c0.87-0.05,1.6-0.09,2.4-0.13c-0.17-0.87-0.32-1.6-0.47-2.38
	C640.68,541.67,640.08,541.8,639.34,541.96z M368.02,12.55c-0.8,0.1-1.49,0.19-2.32,0.3c0.1,0.74,0.2,1.41,0.28,1.99
	c0.84,0,1.5,0,2.16,0C368.1,14.06,368.06,13.44,368.02,12.55z M478.07,142.67c0.76-0.08,1.5-0.16,2.3-0.25
	c-0.18-0.81-0.32-1.46-0.47-2.12c-0.74,0.04-1.34,0.07-2.01,0.11C477.95,141.18,478,141.86,478.07,142.67z M645.36,586.77
	c-0.12-0.89-0.21-1.55-0.3-2.23c-0.84,0.05-1.5,0.09-2.17,0.12c0.06,0.93,0.11,1.67,0.16,2.43
	C643.85,586.98,644.53,586.89,645.36,586.77z M646.57,559.27c-0.89,0-1.57,0-2.4,0c0.13,0.79,0.25,1.46,0.36,2.1
	c0.78,0,1.45,0,2.16,0C646.65,560.62,646.61,560,646.57,559.27z M585.9,441.51c-0.78,0.11-1.45,0.21-2.25,0.32
	c0.13,0.77,0.24,1.41,0.32,1.93c0.79,0,1.38,0,1.92,0C585.9,442.97,585.9,442.29,585.9,441.51z M372.62,51.94
	c0.07,0.79,0.13,1.47,0.2,2.24c0.79-0.06,1.48-0.12,2.29-0.18c-0.07-0.73-0.13-1.42-0.21-2.18
	C374.11,51.86,373.43,51.9,372.62,51.94z M656.1,542.77c-0.16-0.82-0.32-1.62-0.49-2.43c-0.74,0.06-1.36,0.11-1.99,0.17
	c0.06,0.86,0.11,1.62,0.16,2.43C654.55,542.87,655.24,542.83,656.1,542.77z M568.03,264.91c0.83-0.13,1.57-0.24,2.32-0.36
	c-0.07-0.77-0.12-1.39-0.19-2.12c-0.77,0.06-1.45,0.11-2.13,0.16C568.03,263.41,568.03,264.1,568.03,264.91z M598.94,425.97
	c-0.98,0.11-1.72,0.2-2.47,0.28c0.14,0.81,0.26,1.49,0.4,2.27c0.75-0.16,1.4-0.3,2.07-0.44
	C598.94,427.34,598.94,426.72,598.94,425.97z M491.82,149.96c-0.88,0.18-1.53,0.31-2.19,0.44c0.05,0.77,0.09,1.38,0.13,2.01
	c0.83,0,1.58,0,2.44,0C492.08,151.64,491.96,150.9,491.82,149.96z M369.66,34.27c-0.79,0.04-1.45,0.08-2.13,0.11
	c0,0.83,0,1.52,0,2.38c0.79-0.13,1.52-0.25,2.27-0.38C369.75,35.59,369.71,34.98,369.66,34.27z M435.58,27
	c0.73-0.05,1.41-0.09,2.16-0.14c-0.08-0.79-0.15-1.48-0.22-2.14c-0.85,0-1.62,0-2.43,0C435.26,25.52,435.41,26.2,435.58,27z
	 M595.69,390.26c-0.77,0.05-1.44,0.09-2.13,0.14c0.06,0.83,0.11,1.51,0.17,2.29c0.76-0.09,1.41-0.17,2.1-0.25
	C595.78,391.61,595.74,391,595.69,390.26z M525.46,147.05c0.06,0.89,0.12,1.71,0.18,2.53c0.89-0.14,1.63-0.26,2.41-0.38
	c-0.23-0.88-0.41-1.61-0.6-2.34C526.71,146.92,526.09,146.98,525.46,147.05z M449.84,105.19c0.69-0.12,1.26-0.21,1.84-0.31
	c-0.05-0.79-0.08-1.4-0.12-2.11c-0.77,0.06-1.44,0.12-2.2,0.18C449.52,103.74,449.67,104.41,449.84,105.19z M562,375.12
	c-0.91,0.1-1.61,0.17-2.39,0.25c0.05,0.8,0.1,1.54,0.16,2.36c0.85-0.2,1.52-0.36,2.23-0.52C562,376.51,562,375.89,562,375.12z
	 M546.47,264.51c0.08,0.78,0.15,1.5,0.22,2.15c0.85,0,1.54,0,2.31,0c-0.1-0.87-0.19-1.68-0.29-2.49
	C547.92,264.29,547.23,264.4,546.47,264.51z M309.98,52.36c-0.16-0.83-0.29-1.48-0.42-2.13c-0.79,0.1-1.45,0.19-2.13,0.28
	c0.07,0.86,0.13,1.59,0.19,2.33C308.47,52.67,309.14,52.53,309.98,52.36z M538.81,220.56c0.82-0.12,1.63-0.24,2.47-0.36
	c-0.1-0.79-0.19-1.47-0.29-2.26c-0.76,0.04-1.45,0.07-2.18,0.11C538.81,218.86,538.81,219.64,538.81,220.56z M578.94,531.11
	c-0.08-0.84-0.14-1.44-0.19-1.98c-0.75,0-1.35,0-2.09,0c0.09,0.78,0.17,1.42,0.25,2.11C577.6,531.19,578.14,531.16,578.94,531.11z
	 M364.71,16.8c-0.81,0-1.58,0-2.47,0c0.14,0.81,0.26,1.52,0.37,2.19c0.87-0.1,1.54-0.17,2.31-0.26
	C364.85,18.02,364.78,17.41,364.71,16.8z M371.91,44.67c0.13,0.82,0.25,1.57,0.37,2.37c0.76-0.19,1.41-0.34,2.06-0.5
	c-0.04-0.71-0.08-1.32-0.12-1.99C373.44,44.59,372.7,44.63,371.91,44.67z M480.71,147.64c-0.69,0-1.36,0-2.13,0
	c0.05,0.79,0.09,1.45,0.13,2.16c0.89-0.04,1.57-0.07,2.32-0.11C480.91,148.92,480.81,148.3,480.71,147.64z M363.9,9.39
	c-0.87,0-1.54,0-2.27,0c0.09,0.93,0.18,1.75,0.27,2.67c0.77-0.19,1.43-0.35,2.13-0.52C363.99,10.79,363.95,10.17,363.9,9.39z
	 M483.31,51.84c-0.05-0.82-0.1-1.55-0.15-2.37c-0.85,0.12-1.58,0.23-2.34,0.35c0.17,0.82,0.31,1.47,0.43,2.03
	C482.02,51.84,482.63,51.84,483.31,51.84z M507.56,191.95c0.05,0.86,0.09,1.61,0.13,2.42c0.84-0.15,1.58-0.28,2.39-0.43
	c-0.13-0.77-0.24-1.49-0.36-2.19C508.98,191.82,508.37,191.88,507.56,191.95z M448.68,29.42c0-0.87,0-1.54,0-2.23
	c-0.86,0.06-1.62,0.11-2.45,0.17c0.18,0.77,0.34,1.43,0.49,2.06C447.41,29.42,447.96,29.42,448.68,29.42z M531.25,213.81
	c0.88,0,1.63,0,2.47,0c-0.17-0.84-0.32-1.59-0.47-2.36c-0.75,0.09-1.44,0.17-2.14,0.26C531.16,212.4,531.2,213.03,531.25,213.81z
	 M534.62,586.94c0.08,0.91,0.14,1.64,0.21,2.44c0.83-0.2,1.5-0.36,2.21-0.53c-0.08-0.73-0.16-1.4-0.23-2.06
	C536.08,586.85,535.46,586.89,534.62,586.94z M444.49,103.41c-0.92,0.22-1.59,0.38-2.17,0.52c0,0.81,0,1.43,0,2.15
	c0.81-0.09,1.56-0.17,2.33-0.26C444.6,105,444.55,104.3,444.49,103.41z M574.37,336.53c0.91-0.12,1.52-0.2,2.04-0.27
	c0-0.75,0-1.36,0-2.11c-0.8,0.05-1.47,0.1-2.18,0.15C574.27,335.07,574.31,335.69,574.37,336.53z M537.16,214.55
	c-0.9,0.08-1.62,0.14-2.41,0.2c0.18,0.88,0.32,1.53,0.47,2.24c0.83-0.07,1.49-0.12,2.16-0.17
	C537.3,216.04,537.24,215.42,537.16,214.55z M617.02,538.96c0.9-0.17,1.71-0.32,2.51-0.48c-0.17-0.83-0.3-1.5-0.44-2.17
	c-0.8,0.11-1.47,0.2-2.08,0.28C617.02,537.44,617.02,538.14,617.02,538.96z M563.5,602.52c-0.71,0.11-1.36,0.22-2,0.32
	c0,0.88,0,1.63,0,2.45c0.79-0.11,1.55-0.21,2.3-0.31C563.7,604.15,563.61,603.39,563.5,602.52z M343.64,47.41c0,0.85,0,1.5,0,2.24
	c0.87-0.1,1.61-0.18,2.36-0.26c-0.2-0.84-0.35-1.51-0.52-2.21C344.84,47.26,344.3,47.33,343.64,47.41z M603.74,356.98
	c-0.9,0.15-1.55,0.26-2.2,0.37c0.08,0.77,0.14,1.37,0.21,2.05c0.78-0.09,1.44-0.16,2.16-0.25
	C603.85,358.44,603.8,357.83,603.74,356.98z M266.53,93.79c-0.77,0-1.45,0-2.12,0c0,0.89,0,1.7,0,2.54
	c0.86-0.15,1.59-0.28,2.37-0.42C266.69,95.16,266.61,94.49,266.53,93.79z M525.26,395.03c0.79-0.04,1.52-0.08,2.36-0.12
	c-0.15-0.85-0.26-1.52-0.4-2.3c-0.83,0.05-1.57,0.09-2.34,0.13C525.01,393.57,525.12,394.24,525.26,395.03z M437.96,95.36
	c0.88-0.07,1.55-0.12,2.29-0.18c-0.05-0.76-0.1-1.45-0.15-2.19c-0.83,0.17-1.56,0.32-2.3,0.47
	C437.85,94.12,437.9,94.67,437.96,95.36z M606.01,536.22c0.93-0.13,1.68-0.23,2.47-0.34c-0.17-0.79-0.32-1.45-0.45-2.04
	c-0.77,0-1.38,0-2.02,0C606.01,534.6,606.01,535.29,606.01,536.22z M568.69,272.15c0.68-0.09,1.4-0.19,2.16-0.3
	c-0.05-0.84-0.09-1.54-0.13-2.34c-0.73,0.09-1.41,0.17-2.03,0.25C568.69,270.54,568.69,271.24,568.69,272.15z M576.25,608.71
	c0.17,0.92,0.29,1.57,0.41,2.21c0.87-0.07,1.54-0.12,2.28-0.17c-0.14-0.79-0.25-1.4-0.36-2.04
	C577.82,608.71,577.13,608.71,576.25,608.71z M522.16,580.89c-0.93,0.06-1.73,0.11-2.59,0.17c0.18,0.77,0.33,1.42,0.5,2.14
	c0.79-0.05,1.46-0.1,2.23-0.15C522.24,582.26,522.2,581.6,522.16,580.89z M599.69,295.74c0.05,1,0.09,1.82,0.12,2.65
	c0.9-0.2,1.64-0.36,2.39-0.52c-0.11-0.78-0.21-1.45-0.31-2.14C601.16,295.74,600.54,295.74,599.69,295.74z M582.69,181.95
	c0.05,0.88,0.09,1.56,0.13,2.22c0.88,0,1.64,0,2.46,0c-0.17-0.84-0.32-1.59-0.49-2.4C584.16,181.82,583.54,181.88,582.69,181.95z
	 M347.49,29.09c-0.83,0-1.43,0-2.12,0c0.11,0.79,0.19,1.45,0.29,2.18c0.81-0.06,1.49-0.1,2.19-0.15
	C347.72,30.36,347.61,29.75,347.49,29.09z M486.21,45.81c-0.79,0.03-1.45,0.06-2.11,0.09c0.04,0.88,0.07,1.55,0.11,2.32
	c0.76-0.12,1.42-0.22,2.13-0.33C486.29,47.09,486.25,46.49,486.21,45.81z M459.82,111.79c-0.2-0.84-0.38-1.56-0.55-2.26
	c-0.76,0.04-1.38,0.07-2.08,0.1c0.07,0.75,0.13,1.44,0.2,2.16C458.2,111.79,458.96,111.79,459.82,111.79z M578.62,357.95
	c-0.17-0.92-0.29-1.58-0.41-2.26c-0.74,0.07-1.34,0.12-2.02,0.19c0.09,0.8,0.16,1.46,0.23,2.18
	C577.17,358.01,577.78,357.99,578.62,357.95z M423.21,26.06c-0.89,0.06-1.61,0.1-2.36,0.15c0.13,0.83,0.23,1.49,0.34,2.21
	c0.79-0.16,1.46-0.29,2.24-0.44C423.36,27.39,423.3,26.87,423.21,26.06z M596.05,432.36c-0.22-0.91-0.39-1.62-0.56-2.34
	c-0.77,0.09-1.36,0.16-2.03,0.24c0.09,0.83,0.17,1.48,0.23,2.1C594.52,432.36,595.14,432.36,596.05,432.36z M568.64,533.3
	c-0.76,0.18-1.42,0.34-2.19,0.52c0.05,0.72,0.09,1.38,0.14,2.08c0.84-0.09,1.51-0.16,2.2-0.24
	C568.73,534.8,568.69,534.11,568.64,533.3z M346.97,23.99c0-0.74,0-1.48,0-2.32c-0.73,0.07-1.4,0.14-2.12,0.21
	c0.09,0.78,0.18,1.46,0.27,2.23C345.75,24.07,346.29,24.04,346.97,23.99z M664.18,593.88c-0.94,0.07-1.68,0.13-2.47,0.19
	c0.12,0.73,0.23,1.34,0.33,1.93c0.77,0,1.46,0,2.25,0C664.25,595.27,664.22,594.65,664.18,593.88z M414.25,47.01
	c-0.14-0.91-0.25-1.56-0.35-2.23c-0.74,0.04-1.34,0.08-2.02,0.12c0.05,0.83,0.09,1.49,0.14,2.21
	C412.84,47.08,413.46,47.05,414.25,47.01z M573.22,213.66c-0.04-0.84-0.08-1.58-0.12-2.36c-0.87,0.18-1.54,0.32-2.36,0.49
	c0.1,0.74,0.19,1.41,0.29,2.17C571.78,213.86,572.45,213.77,573.22,213.66z M580.22,337.63c-0.8,0-1.42,0-2.11,0
	c0.07,0.74,0.13,1.41,0.2,2.17c0.78-0.07,1.45-0.14,2.14-0.2C580.37,338.88,580.3,338.34,580.22,337.63z M569.73,215.67
	c-0.76,0-1.45,0-2.27,0c0.05,0.76,0.09,1.43,0.14,2.17c0.88-0.08,1.62-0.14,2.4-0.21C569.9,216.89,569.81,216.28,569.73,215.67z
	 M569.04,456.3c-0.12-0.77-0.23-1.42-0.34-2.09c-0.75,0-1.36,0-2.05,0c0.07,0.73,0.13,1.41,0.2,2.09
	C567.59,456.3,568.26,456.3,569.04,456.3z M605.15,537.43c-0.85,0.14-1.52,0.24-2.24,0.36c0.06,0.81,0.11,1.42,0.14,1.93
	c0.81,0,1.47,0,2.09,0C605.15,538.94,605.15,538.31,605.15,537.43z M529.2,580.27c-0.82,0.06-1.57,0.12-2.32,0.18
	c0.12,0.84,0.22,1.51,0.33,2.25c0.8-0.05,1.47-0.1,2.22-0.14C529.35,581.73,529.28,581.06,529.2,580.27z M408.37,48.73
	c0.18,0.84,0.33,1.54,0.49,2.28c0.78-0.11,1.35-0.19,1.96-0.27c-0.07-0.83-0.13-1.43-0.19-2.11
	C409.86,48.66,409.18,48.69,408.37,48.73z M602.15,530.48c0.07,0.75,0.13,1.43,0.2,2.22c0.7-0.03,1.31-0.06,2.09-0.1
	c-0.05-0.76-0.1-1.44-0.15-2.13C603.51,530.48,602.88,530.48,602.15,530.48z M586.05,219.77c0.76,0,1.45,0,2.24,0
	c-0.09-0.78-0.16-1.45-0.25-2.27c-0.74,0.04-1.43,0.08-2.2,0.12C585.91,218.38,585.98,219.06,586.05,219.77z M588.47,430.56
	c-0.78,0.05-1.44,0.09-2.18,0.13c0.1,0.79,0.19,1.46,0.28,2.12c0.69,0,1.29,0,2.05,0C588.56,432.04,588.52,431.37,588.47,430.56z
	 M490.29,48.99c-0.84,0.18-1.43,0.3-2.06,0.43c0.04,0.73,0.08,1.32,0.11,1.91c0.84,0,1.51,0,2.31,0
	C490.54,50.55,490.43,49.88,490.29,48.99z M611.52,265.99c0.16,0.85,0.28,1.5,0.41,2.19c0.77-0.13,1.37-0.23,1.97-0.33
	c0-0.78,0-1.47,0-2.24C613.13,265.73,612.46,265.84,611.52,265.99z M550.61,309.63c0.84-0.08,1.44-0.15,2.06-0.21
	c-0.08-0.83-0.14-1.5-0.21-2.27c-0.85,0.13-1.52,0.23-2.27,0.34C550.33,308.21,550.45,308.82,550.61,309.63z M359.74,27.91
	c0.12,0.81,0.21,1.47,0.3,2.13c0.82-0.07,1.5-0.13,2.19-0.2c-0.13-0.82-0.23-1.49-0.34-2.16C361.18,27.76,360.57,27.83,359.74,27.91
	z M573.69,220.82c-0.05-0.75-0.1-1.41-0.15-2.12c-0.81,0.07-1.49,0.13-2.24,0.2c0.06,0.79,0.11,1.47,0.16,2.26
	C572.23,221.04,572.9,220.94,573.69,220.82z M553.44,92.69c0.72-0.06,1.26-0.1,1.85-0.15c0-0.79,0-1.47,0-2.28
	c-0.75,0.09-1.42,0.18-2.12,0.26C553.26,91.25,553.34,91.87,553.44,92.69z M636.36,563.47c-0.87,0.22-1.53,0.39-2.29,0.58
	c0.05,0.72,0.09,1.37,0.13,2.07c0.81-0.08,1.47-0.15,2.16-0.22C636.36,565.14,636.36,564.45,636.36,563.47z M589.2,479.85
	c-0.16-0.92-0.28-1.59-0.41-2.32c-0.73,0.1-1.32,0.18-1.95,0.26c0.08,0.8,0.14,1.41,0.2,2.06
	C587.73,479.85,588.35,479.85,589.2,479.85z M626.63,577.73c-0.13-0.85-0.23-1.51-0.35-2.29c-0.73,0.08-1.37,0.15-2.04,0.22
	c0.09,0.82,0.15,1.42,0.22,2.06C625.14,577.73,625.76,577.73,626.63,577.73z M505.13,553.54c-0.76,0-1.45,0-2.23,0
	c0.09,0.78,0.17,1.46,0.26,2.29c0.76-0.05,1.44-0.1,2.2-0.15C505.28,554.92,505.2,554.23,505.13,553.54z M649.99,584.19
	c0.05,0.76,0.09,1.42,0.13,2.13c0.89-0.05,1.64-0.09,2.48-0.13c-0.13-0.73-0.24-1.38-0.37-2.12
	C651.45,584.12,650.76,584.15,649.99,584.19z M245.2,137.49c-0.15-0.8-0.25-1.38-0.37-2.04c-0.78,0.04-1.47,0.08-2.23,0.13
	c0.05,0.77,0.08,1.42,0.12,2.08C243.63,137.59,244.32,137.55,245.2,137.49z M386.63,45.49c0.68,0,1.39,0,2.18,0
	c-0.11-0.81-0.21-1.47-0.3-2.16c-0.84,0.04-1.53,0.07-2.24,0.11C386.39,44.16,386.5,44.77,386.63,45.49z M576.15,539.96
	c-0.88,0.06-1.6,0.12-2.35,0.17c0.23,0.87,0.39,1.53,0.56,2.18c0.74-0.04,1.34-0.08,1.98-0.11
	C576.28,541.43,576.23,540.81,576.15,539.96z M614,542.6c0.88-0.09,1.55-0.16,2.18-0.22c0-0.83,0-1.52,0-2.38
	c-0.84,0.21-1.55,0.4-2.18,0.56C614,541.28,614,541.83,614,542.6z M570.62,127.34c0.74-0.13,1.38-0.24,2.1-0.36
	c-0.09-0.78-0.16-1.38-0.23-1.99c-0.73,0.04-1.33,0.07-2,0.11C570.54,125.87,570.58,126.54,570.62,127.34z M605.68,528.76
	c0.89,0,1.57,0,2.34,0c-0.18-0.84-0.33-1.55-0.48-2.29c-0.73,0.08-1.33,0.15-1.99,0.23C605.59,527.45,605.63,528.06,605.68,528.76z
	 M567.02,543.21c0.87-0.21,1.53-0.37,2.2-0.53c-0.05-0.77-0.09-1.38-0.13-2.05c-0.78,0.12-1.44,0.22-2.06,0.31
	C567.02,541.74,567.02,542.35,567.02,543.21z M570.68,602.04c-0.82,0.05-1.49,0.1-2.26,0.14c0.08,0.77,0.16,1.45,0.24,2.22
	c0.75-0.12,1.41-0.23,2.12-0.35C570.74,603.38,570.72,602.84,570.68,602.04z M421.87,53.35c-0.06-0.67-0.11-1.28-0.17-2
	c-0.79,0.04-1.53,0.08-2.28,0.13c0.13,0.84,0.23,1.52,0.35,2.3C420.5,53.63,421.16,53.49,421.87,53.35z M485.88,149.09
	c0.86-0.07,1.54-0.13,2.29-0.2c-0.05-0.79-0.09-1.48-0.14-2.28c-0.76,0.11-1.43,0.21-2.15,0.31
	C485.88,147.63,485.88,148.25,485.88,149.09z M614.93,264.44c0.88-0.16,1.56-0.29,2.27-0.42c0-0.79,0-1.53,0-2.34
	c-0.87,0.19-1.6,0.35-2.27,0.5C614.93,262.97,614.93,263.58,614.93,264.44z M493.31,528c0.76-0.21,1.47-0.4,2.21-0.61
	c-0.06-0.82-0.11-1.48-0.15-2.15c-0.86,0.1-1.52,0.18-2.17,0.26C493.23,526.37,493.27,527.12,493.31,528z M599.44,375.45
	c0.09,0.83,0.16,1.44,0.23,2.07c0.81-0.05,1.54-0.09,2.33-0.13c-0.07-0.7-0.14-1.3-0.2-1.94
	C601.01,375.45,600.33,375.45,599.44,375.45z M447.95,19.96c-0.86,0.14-1.54,0.25-2.21,0.36c0.15,0.8,0.28,1.49,0.41,2.21
	c0.65-0.1,1.25-0.19,1.92-0.28C448.04,21.5,448,20.81,447.95,19.96z M567.73,468.45c0.13,0.78,0.25,1.49,0.37,2.17
	c0.79-0.09,1.4-0.15,2.08-0.22c-0.07-0.72-0.14-1.39-0.22-2.1C569.19,468.35,568.51,468.4,567.73,468.45z M619.89,567.24
	c0.84-0.04,1.44-0.06,2.13-0.1c-0.08-0.81-0.15-1.48-0.21-2.13c-0.82,0-1.51,0-2.31,0C619.63,565.77,619.74,566.42,619.89,567.24z
	 M442.54,42.32c-0.81,0.05-1.42,0.1-2.02,0.14c0.04,0.91,0.08,1.68,0.12,2.48c0.79-0.1,1.55-0.2,2.36-0.31
	C442.84,43.85,442.71,43.17,442.54,42.32z M568.73,121.63c-0.83,0.06-1.53,0.1-2.28,0.15c0.17,0.83,0.28,1.42,0.42,2.11
	c0.71-0.05,1.3-0.09,1.86-0.12C568.73,122.97,568.73,122.37,568.73,121.63z M489.56,41.97c-0.74,0.04-1.35,0.07-2.05,0.11
	c0.06,0.71,0.13,1.38,0.18,2.03c0.8,0,1.48,0,2.22,0C489.8,43.42,489.7,42.82,489.56,41.97z M486.98,52.93
	c-0.85,0.07-1.51,0.12-2.22,0.18c0.09,0.85,0.16,1.51,0.24,2.21c0.84-0.05,1.5-0.09,2.17-0.13
	C487.1,54.37,487.05,53.75,486.98,52.93z M389.31,34.61c0.76-0.03,1.36-0.06,2.08-0.09c-0.04-0.8-0.08-1.47-0.12-2.26
	c-0.81,0.05-1.48,0.09-2.17,0.13C389.17,33.15,389.23,33.77,389.31,34.61z M634.43,544.33c0-0.8,0-1.55,0-2.42
	c-0.89,0.2-1.68,0.38-2.45,0.56c0.13,0.77,0.23,1.38,0.34,2.04C633.03,544.45,633.71,544.39,634.43,544.33z M361.26,44.24
	c0.85-0.04,1.45-0.07,2.14-0.1c-0.09-0.82-0.16-1.49-0.24-2.23c-0.81,0.06-1.49,0.1-2.22,0.15
	C361.05,42.83,361.14,43.45,361.26,44.24z M391.06,54.09c0.08,0.73,0.15,1.4,0.22,2.11c0.77-0.04,1.39-0.08,2.12-0.11
	c-0.16-0.81-0.29-1.46-0.43-2.12C392.26,54.01,391.71,54.05,391.06,54.09z M432.81,53.92c-0.78,0.04-1.39,0.08-2.14,0.12
	c0.14,0.82,0.26,1.49,0.39,2.23c0.69-0.06,1.23-0.1,1.89-0.15C432.9,55.42,432.86,54.81,432.81,53.92z M463.2,28.34
	c-0.13-0.92-0.22-1.58-0.32-2.27c-0.75,0.04-1.36,0.08-2.08,0.12c0.05,0.81,0.1,1.48,0.14,2.14
	C461.76,28.34,462.39,28.34,463.2,28.34z M582.22,467.25c0.09,0.78,0.16,1.38,0.22,1.99c0.8,0,1.41,0,2.11,0
	c-0.06-0.73-0.11-1.4-0.17-2.13C583.68,467.17,583.07,467.2,582.22,467.25z M590.93,166.66c-0.79,0.06-1.51,0.12-2.25,0.18
	c0.05,0.83,0.1,1.45,0.14,2.18c0.74-0.03,1.42-0.07,2.11-0.1C590.93,168.11,590.93,167.48,590.93,166.66z M470.03,133.72
	c0.15,0.76,0.28,1.41,0.43,2.15c0.7-0.05,1.29-0.09,1.83-0.13c0-0.79,0-1.38,0-2.03C471.51,133.72,470.85,133.72,470.03,133.72z
	 M384.07,56.72c0.76-0.05,1.29-0.09,1.96-0.13c-0.05-0.76-0.1-1.43-0.14-2.01c-0.82,0-1.48,0-2.22,0
	C383.8,55.33,383.92,55.94,384.07,56.72z M432,88.7c0-0.7,0-1.38,0-2.02c-0.79,0-1.46,0-2.14,0c0.05,0.83,0.08,1.51,0.13,2.3
	C430.7,88.88,431.32,88.79,432,88.7z M594.68,378.15c-0.08-0.86-0.14-1.53-0.2-2.31c-0.74,0.04-1.41,0.07-2.16,0.1
	c0.05,0.84,0.1,1.51,0.14,2.2C593.23,378.15,593.84,378.15,594.68,378.15z M570.09,370.62c0.1,0.88,0.18,1.55,0.27,2.34
	c0.77-0.04,1.46-0.08,2.23-0.12c-0.07-0.79-0.13-1.52-0.18-2.22C571.57,370.62,570.95,370.62,570.09,370.62z M598.25,168.44
	c-0.13-0.86-0.22-1.53-0.33-2.29c-0.75,0.1-1.35,0.17-2,0.25c0.04,0.74,0.07,1.36,0.11,2.04
	C596.71,168.44,597.33,168.44,598.25,168.44z M574.44,560.41c-0.09-0.77-0.17-1.45-0.26-2.22c-0.7,0.1-1.31,0.19-1.99,0.29
	c0.06,0.74,0.12,1.33,0.17,1.93C573.14,560.41,573.75,560.41,574.44,560.41z M494.68,43.65c0.89-0.1,1.57-0.18,2.33-0.26
	c-0.07-0.76-0.14-1.44-0.21-2.19c-0.82,0.1-1.48,0.18-2.12,0.26C494.68,42.21,494.68,42.83,494.68,43.65z M416.62,57.57
	c0.89,0,1.58,0,2.35,0c-0.18-0.84-0.33-1.57-0.49-2.31c-0.74,0.08-1.34,0.15-2.01,0.23C416.52,56.24,416.57,56.86,416.62,57.57z
	 M619.22,369.97c-0.74,0.07-1.41,0.14-2.08,0.2c0.04,0.9,0.08,1.67,0.12,2.51c0.8-0.12,1.54-0.23,2.28-0.35
	C619.42,371.48,619.33,370.79,619.22,369.97z M662.2,614.16c-0.13-0.73-0.25-1.4-0.36-2.06c-0.77,0.08-1.45,0.16-2.19,0.24
	c0.06,0.75,0.11,1.44,0.16,2.15C660.64,614.38,661.4,614.27,662.2,614.16z M566.46,467.33c-0.15-0.77-0.29-1.43-0.44-2.18
	c-0.69,0.06-1.28,0.11-1.82,0.15c0,0.79,0,1.39,0,2.03C564.98,467.33,565.64,467.33,566.46,467.33z M420.01,13.88
	c0.84,0,1.51,0,2.24,0c-0.14-0.77-0.25-1.43-0.36-2.07c-0.75,0-1.4,0-2.08,0C419.88,12.55,419.94,13.16,420.01,13.88z M598.42,547.4
	c-0.09-0.84-0.15-1.43-0.22-2.12c-0.78,0.06-1.45,0.12-2.19,0.18c0.18,0.83,0.32,1.49,0.46,2.17
	C597.17,547.55,597.72,547.48,598.42,547.4z M597.4,187.59c0.13,0.85,0.26,1.66,0.39,2.52c0.8-0.22,1.45-0.4,2.09-0.57
	c-0.05-0.75-0.09-1.36-0.13-1.95C598.95,187.59,598.25,187.59,597.4,187.59z M543.31,326.14c-0.9,0.14-1.57,0.24-2.28,0.35
	c0,0.78,0,1.46,0,2.26c0.82-0.14,1.55-0.27,2.28-0.4C543.31,327.61,543.31,327,543.31,326.14z M611.02,467.2
	c0.8-0.11,1.48-0.2,2.26-0.3c-0.1-0.8-0.19-1.55-0.29-2.39c-0.79,0.08-1.45,0.14-2.09,0.2C610.94,465.59,610.97,466.29,611.02,467.2
	z M636.05,547.89c0.88-0.1,1.55-0.17,2.3-0.25c-0.07-0.76-0.13-1.44-0.19-2.2c-0.75,0.1-1.42,0.18-2.11,0.27
	C636.05,546.46,636.05,547.07,636.05,547.89z M581.41,517.94c-0.71,0.06-1.36,0.12-2.04,0.18c0.04,0.78,0.07,1.37,0.11,2.08
	c0.8-0.05,1.45-0.09,2.13-0.14C581.54,519.27,581.48,518.67,581.41,517.94z M484.35,174.68c0.82-0.14,1.57-0.26,2.32-0.38
	c-0.1-0.82-0.18-1.49-0.25-2.04c-0.87,0-1.56,0-2.27,0C484.22,173.12,484.29,173.88,484.35,174.68z M477.57,135.39
	c0.83-0.04,1.44-0.07,2.21-0.1c-0.11-0.86-0.19-1.6-0.29-2.34c-0.78,0.11-1.4,0.19-2.07,0.29
	C477.47,133.96,477.52,134.58,477.57,135.39z M551.21,277.03c0.85-0.05,1.54-0.09,2.3-0.14c-0.05-0.79-0.09-1.46-0.13-2.18
	c-0.9,0.06-1.66,0.11-2.48,0.17C550.99,275.56,551.08,276.17,551.21,277.03z M625.69,568.4c-0.68,0-1.36,0-2.17,0
	c0.04,0.78,0.08,1.45,0.12,2.22c0.84-0.04,1.51-0.07,2.19-0.11C625.78,569.7,625.74,569.08,625.69,568.4z M586.44,488.55
	c-0.75,0.11-1.43,0.21-2.11,0.31c0,0.74,0,1.34,0,1.99c0.76,0,1.42,0,2.11,0C586.44,490.05,586.44,489.35,586.44,488.55z
	 M522.61,197.91c0.12,0.75,0.22,1.41,0.32,2.06c0.76-0.08,1.44-0.14,2.23-0.22c-0.08-0.71-0.15-1.36-0.23-2.02
	C524.09,197.79,523.41,197.85,522.61,197.91z M574.19,186.11c-0.87,0-1.66,0-2.47,0c0.23,0.93,0.43,1.74,0.64,2.6
	c0.73-0.21,1.37-0.4,1.99-0.58C574.3,187.37,574.25,186.76,574.19,186.11z M571.91,139.39c0,0.76,0,1.52,0,2.4
	c0.7-0.09,1.38-0.17,2.1-0.26c-0.1-0.76-0.18-1.44-0.28-2.23C573.12,139.33,572.57,139.36,571.91,139.39z M642.98,558.3
	c-0.12-0.68-0.24-1.41-0.38-2.22c-0.77,0.08-1.44,0.15-2.13,0.23c0.06,0.81,0.11,1.48,0.16,2.12
	C641.46,558.38,642.15,558.34,642.98,558.3z M529.7,569.36c0.1,0.86,0.16,1.47,0.25,2.2c0.74-0.03,1.42-0.07,2.18-0.1
	c-0.18-0.84-0.32-1.5-0.45-2.1C530.96,569.36,530.4,569.36,529.7,569.36z M611.22,387.48c0.93-0.08,1.7-0.15,2.52-0.22
	c-0.11-0.78-0.21-1.45-0.33-2.25c-0.81,0.16-1.54,0.3-2.3,0.45C611.15,386.15,611.18,386.69,611.22,387.48z M415.27,59.38
	c-0.74,0-1.46,0-2.2,0c0.08,0.77,0.14,1.38,0.2,2c0.7,0,1.37,0,2.12,0C415.35,60.63,415.31,60.02,415.27,59.38z M603.09,516.02
	c-0.8,0.1-1.46,0.19-2.17,0.28c0.1,0.72,0.17,1.26,0.25,1.79c0.75,0,1.41,0,2.08,0C603.2,517.39,603.15,516.84,603.09,516.02z
	 M563.22,515.98c-0.77,0.16-1.34,0.28-1.94,0.4c0.05,0.69,0.09,1.22,0.13,1.8c0.79,0,1.4,0,2.13,0
	C563.45,517.51,563.36,516.89,563.22,515.98z M433.19,82.74c0.06,0.78,0.11,1.51,0.16,2.17c0.87,0,1.55,0,2.3,0
	c-0.08-0.79-0.15-1.47-0.23-2.28C434.67,82.66,433.98,82.7,433.19,82.74z M542.15,256.2c0.81-0.18,1.41-0.31,2.11-0.47
	c-0.04-0.65-0.07-1.24-0.12-1.94c-0.84,0.08-1.57,0.15-2.31,0.22C541.95,254.8,542.04,255.41,542.15,256.2z M592.75,227.78
	c-0.8,0.17-1.56,0.33-2.36,0.49c0.08,0.73,0.14,1.31,0.19,1.86c0.88,0,1.55,0,2.27,0C592.82,229.37,592.79,228.68,592.75,227.78z
	 M561.54,288.55c-0.81,0.06-1.42,0.1-2.1,0.15c0.09,0.78,0.18,1.45,0.25,2.1c0.83,0,1.52,0,2.31,0
	C561.85,290.05,561.71,289.39,561.54,288.55z M405.71,59.76c0.18,0.9,0.32,1.55,0.46,2.22c0.74-0.05,1.27-0.08,1.95-0.13
	c-0.05-0.76-0.1-1.43-0.15-2.1C407.19,59.76,406.57,59.76,405.71,59.76z M564.63,490.57c-0.69,0.09-1.28,0.16-1.95,0.25
	c0.09,0.79,0.15,1.38,0.22,1.96c0.77,0,1.38,0,2.12,0C564.88,492.03,564.77,491.35,564.63,490.57z M342.92,42.3
	c0.78-0.06,1.44-0.1,2.15-0.15c-0.1-0.81-0.18-1.42-0.26-2.11c-0.7,0.05-1.3,0.08-1.99,0.13C342.85,40.93,342.88,41.54,342.92,42.3z
	 M584.06,546.65c-0.77,0.13-1.36,0.23-2.02,0.34c0.06,0.73,0.1,1.33,0.15,2.02c0.78-0.04,1.44-0.07,2.16-0.11
	C584.26,548.12,584.18,547.52,584.06,546.65z M400.72,15.47c-0.11-0.67-0.21-1.27-0.31-1.84c-0.77,0-1.46,0-2.32,0
	c0.11,0.84,0.2,1.55,0.3,2.27C399.26,15.73,399.94,15.61,400.72,15.47z M577.6,514.56c-0.81,0.04-1.56,0.07-2.34,0.11
	c0.2,0.81,0.36,1.48,0.51,2.12c0.72,0,1.33,0,2.02,0C577.73,516.05,577.67,515.36,577.6,514.56z M653.37,622.29
	c0.81-0.08,1.54-0.15,2.31-0.22c-0.07-0.83-0.12-1.52-0.19-2.26c-0.76,0.11-1.43,0.2-2.24,0.32
	C653.29,620.88,653.33,621.55,653.37,622.29z M415.03,80.92c0,0.79,0,1.39,0,2.07c0.78-0.08,1.46-0.15,2.27-0.23
	c-0.08-0.65-0.15-1.25-0.22-1.83C416.37,80.92,415.76,80.92,415.03,80.92z M582.91,492.5c-0.74,0.05-1.4,0.1-2.1,0.15
	c0.04,0.8,0.07,1.41,0.11,2.16c0.78-0.04,1.46-0.07,2.2-0.11C583.05,493.94,582.99,493.27,582.91,492.5z M392.1,41.76
	c-0.09-0.91-0.16-1.58-0.24-2.32c-0.71,0.11-1.32,0.2-1.9,0.29c0,0.76,0,1.37,0,2.03C390.68,41.76,391.3,41.76,392.1,41.76z
	 M593.79,408.67c-0.73,0-1.45,0-2.24,0c0.09,0.81,0.16,1.47,0.25,2.2c0.8-0.04,1.46-0.07,2.16-0.11
	C593.89,409.95,593.84,409.34,593.79,408.67z M579.58,312.23c0.05,0.68,0.1,1.35,0.16,2.1c0.76-0.05,1.37-0.1,2.1-0.15
	c-0.07-0.7-0.13-1.3-0.19-1.95C580.91,312.23,580.29,312.23,579.58,312.23z M586.93,440.2c0.68-0.09,1.35-0.17,2.14-0.27
	c-0.08-0.81-0.15-1.47-0.22-2.15c-0.79,0.04-1.39,0.06-2.05,0.09C586.85,438.69,586.88,439.39,586.93,440.2z M616.3,276.62
	c0.04,0.69,0.08,1.3,0.12,1.98c0.82-0.06,1.55-0.1,2.31-0.16c-0.04-0.87-0.07-1.56-0.11-2.36
	C617.8,276.27,617.08,276.44,616.3,276.62z M571.05,229.77c-0.99,0-1.74,0-2.55,0c0.2,0.84,0.37,1.57,0.55,2.33
	c0.75-0.11,1.41-0.21,2.01-0.3C571.05,231.04,571.05,230.42,571.05,229.77z M561.65,370.13c-0.08-0.93-0.14-1.64-0.21-2.42
	c-0.86,0.23-1.52,0.4-2.23,0.59c0.03,0.68,0.07,1.29,0.1,1.96C560.07,370.22,560.75,370.18,561.65,370.13z M639.93,551.36
	c0.84-0.18,1.55-0.34,2.33-0.5c-0.15-0.74-0.27-1.33-0.41-1.98c-0.7,0.07-1.31,0.13-1.92,0.19
	C639.93,549.86,639.93,550.54,639.93,551.36z M341.78,27.91c0.77-0.03,1.37-0.06,2.1-0.09c-0.05-0.8-0.09-1.46-0.13-2.23
	c-0.81,0.04-1.47,0.08-2.16,0.12C341.66,26.46,341.71,27.08,341.78,27.91z M593.19,385.41c0.77-0.06,1.44-0.11,2.16-0.16
	c-0.08-0.87-0.14-1.55-0.2-2.27c-0.8,0.09-1.42,0.16-2.12,0.23C593.08,383.94,593.13,384.62,593.19,385.41z M572.1,410.57
	c-0.67,0.03-1.32,0.06-2.08,0.1c0.1,0.8,0.19,1.44,0.27,2.13c0.78-0.12,1.37-0.22,2.01-0.32
	C572.23,411.8,572.17,411.26,572.1,410.57z M591.29,421.8c-0.06-0.79-0.12-1.52-0.19-2.29c-0.82,0.04-1.43,0.07-2.22,0.11
	c0.1,0.82,0.18,1.54,0.27,2.27C589.96,421.86,590.58,421.84,591.29,421.8z M571.28,134.5c0.9-0.05,1.56-0.09,2.24-0.13
	c-0.11-0.85-0.19-1.46-0.28-2.19c-0.7,0.04-1.36,0.07-2.08,0.11C571.2,133.07,571.23,133.68,571.28,134.5z M562.4,463.98
	c-0.05-0.76-0.09-1.49-0.14-2.3c-0.73,0.12-1.32,0.22-1.89,0.31c0,0.74,0,1.33,0,1.98C561.07,463.98,561.68,463.98,562.4,463.98z
	 M579.5,188.13c0.84-0.18,1.49-0.33,2.26-0.49c-0.06-0.75-0.11-1.43-0.16-2.14c-0.77,0.14-1.43,0.27-2.1,0.39
	C579.5,186.63,579.5,187.24,579.5,188.13z M403.93,38.46c0.06,0.87,0.11,1.56,0.17,2.32c0.82-0.16,1.57-0.31,2.35-0.47
	c-0.15-0.78-0.28-1.44-0.4-2.1C405.3,38.3,404.68,38.38,403.93,38.46z M636.95,573.09c-0.09-0.79-0.17-1.51-0.26-2.27
	c-0.83,0.16-1.49,0.29-2.16,0.42c0.05,0.77,0.1,1.38,0.14,2.03C635.45,573.21,636.13,573.16,636.95,573.09z M549.4,300.15
	c0.19,0.86,0.33,1.52,0.47,2.16c0.74,0,1.36,0,2.05,0c-0.07-0.74-0.13-1.43-0.19-2.16C551,300.15,550.31,300.15,549.4,300.15z
	 M421.03,65.95c0.03,0.76,0.06,1.37,0.09,2.08c0.8-0.08,1.52-0.15,2.31-0.22c-0.07-0.88-0.14-1.61-0.2-2.37
	C422.45,65.62,421.85,65.76,421.03,65.95z M562.83,384.46c-0.15-0.78-0.28-1.47-0.4-2.14c-0.8,0.08-1.42,0.14-2.11,0.21
	c0.08,0.71,0.15,1.38,0.22,2.07C561.32,384.56,562,384.51,562.83,384.46z M296.62,65.87c-0.73,0.1-1.38,0.18-2.06,0.27
	c0.08,0.8,0.14,1.4,0.2,1.96c0.8,0,1.48,0,2.23,0C296.86,67.32,296.74,66.66,296.62,65.87z M570.47,499.54
	c0.8-0.15,1.4-0.26,2.01-0.37c0-0.75,0-1.43,0-2.22c-0.75,0.12-1.39,0.22-2.01,0.31C570.47,498.06,570.47,498.67,570.47,499.54z
	 M596.9,278.17c-0.73,0.1-1.39,0.2-2.11,0.3c0.05,0.77,0.09,1.37,0.13,1.98c0.86,0,1.61,0,2.39,0
	C597.17,279.66,597.05,278.97,596.9,278.17z M593.95,162.85c-0.78,0.05-1.38,0.1-1.96,0.14c0,0.8,0,1.47,0,2.27
	c0.76-0.09,1.37-0.17,2.05-0.25C594.02,164.35,593.99,163.72,593.95,162.85z M591.11,237.45c0.84-0.14,1.45-0.24,2.16-0.36
	c-0.04-0.76-0.07-1.44-0.11-2.19c-0.79,0.14-1.47,0.26-2.25,0.41C590.98,236.02,591.04,236.63,591.11,237.45z M574.66,507.77
	c0.09,0.79,0.17,1.39,0.24,2.06c0.81-0.12,1.49-0.22,2.23-0.33c-0.11-0.76-0.2-1.36-0.28-1.94
	C576.1,507.64,575.48,507.7,574.66,507.77z M598.58,420.95c-0.1-0.81-0.17-1.41-0.25-2.07c-0.83,0.14-1.51,0.26-2.25,0.38
	c0.12,0.75,0.22,1.34,0.31,1.91C597.16,421.1,597.78,421.04,598.58,420.95z M547.8,281.2c0.86-0.17,1.53-0.3,2.36-0.47
	c-0.1-0.72-0.2-1.39-0.3-2.11c-0.76,0.11-1.42,0.21-2.06,0.3C547.8,279.72,547.8,280.34,547.8,281.2z M519.39,201.79
	c0.09,0.86,0.16,1.52,0.21,2.06c0.83,0,1.48,0,2.24,0c-0.16-0.8-0.29-1.46-0.44-2.18C520.73,201.71,520.19,201.74,519.39,201.79z
	 M439.21,28.06c0.06,0.88,0.1,1.56,0.15,2.35c0.8-0.11,1.41-0.19,2.13-0.29c-0.05-0.73-0.1-1.4-0.14-2.05
	C440.6,28.06,440.04,28.06,439.21,28.06z M338.76,36.75c0.18,0.87,0.32,1.54,0.47,2.22c0.76-0.04,1.36-0.07,2.05-0.11
	c-0.11-0.8-0.2-1.47-0.29-2.11C340.22,36.75,339.6,36.75,338.76,36.75z M584.15,506.88c-0.87,0.05-1.48,0.09-2.13,0.13
	c0,0.79,0,1.48,0,2.3c0.75-0.1,1.43-0.18,2.13-0.27C584.15,508.29,584.15,507.68,584.15,506.88z M427.6,76.05
	c-0.82,0.13-1.49,0.23-2.28,0.35c0.07,0.86,0.12,1.58,0.18,2.35c0.88-0.19,1.54-0.33,2.28-0.49
	C427.73,77.56,427.68,76.94,427.6,76.05z M381.51,28.23c0.83-0.16,1.49-0.29,2.14-0.42c-0.08-0.79-0.13-1.4-0.2-2.14
	c-0.72,0.1-1.39,0.2-2.1,0.31C381.39,26.72,381.44,27.34,381.51,28.23z M392.34,70.57c0.83-0.07,1.51-0.13,2.29-0.19
	c-0.14-0.75-0.26-1.4-0.39-2.08c-0.86,0.05-1.54,0.09-2.21,0.13C392.13,69.16,392.22,69.78,392.34,70.57z M372.96,30.53
	c-0.66-0.03-1.34-0.06-2.1-0.1c0.08,0.79,0.15,1.48,0.21,2.18c0.75,0,1.44,0,2.19,0C373.16,31.9,373.07,31.29,372.96,30.53z
	 M417.44,69.63c0.18,0.81,0.33,1.47,0.49,2.16c0.73-0.06,1.34-0.12,1.96-0.17c0-0.75,0-1.43,0-2.11
	C419.1,69.54,418.4,69.58,417.44,69.63z M603.54,505.04c0.14,0.83,0.25,1.54,0.37,2.26c0.79-0.04,1.39-0.07,2.07-0.1
	c-0.08-0.83-0.14-1.5-0.22-2.29C604.99,504.96,604.32,505,603.54,505.04z M553.68,598.35c0.9-0.11,1.57-0.2,2.33-0.29
	c-0.09-0.69-0.18-1.27-0.26-1.89c-0.82,0.07-1.44,0.12-2.07,0.17C553.68,597,553.68,597.55,553.68,598.35z M293.35,69.75
	c-0.78,0.04-1.45,0.07-2.2,0.11c0.09,0.8,0.17,1.47,0.26,2.24c0.77-0.08,1.43-0.15,2.1-0.21C293.45,71.1,293.4,70.49,293.35,69.75z
	 M528.85,200.79c-0.8,0.13-1.47,0.24-2.28,0.38c0.06,0.75,0.12,1.43,0.18,2.15c0.82-0.08,1.5-0.14,2.23-0.21
	C528.94,202.29,528.9,201.6,528.85,200.79z M558.71,381.12c0-0.74,0-1.49,0-2.34c-0.85,0.16-1.59,0.31-2.41,0.46
	c0.05,0.79,0.09,1.48,0.13,2.23C557.24,381.35,557.92,381.24,558.71,381.12z M606.45,554.19c-0.13-0.84-0.22-1.45-0.32-2.09
	c-0.73,0-1.35,0-2.05,0c0.07,0.73,0.14,1.4,0.21,2.09C605,554.19,605.62,554.19,606.45,554.19z M573.2,506.39
	c-0.03-0.73-0.07-1.46-0.1-2.22c-0.87,0.09-1.55,0.16-2.27,0.23c0.08,0.84,0.14,1.53,0.21,2.27
	C571.78,506.58,572.4,506.5,573.2,506.39z M645.87,554.19c0-0.72,0-1.41,0-2.22c-0.78,0.06-1.43,0.11-2.21,0.17
	c0.23,0.89,0.41,1.55,0.57,2.19C644.88,554.27,645.36,554.23,645.87,554.19z M489.46,163.38c-0.15-0.78-0.27-1.45-0.42-2.22
	c-0.74,0.07-1.4,0.14-2.1,0.21c0.06,0.79,0.1,1.46,0.15,2.12C487.88,163.45,488.57,163.42,489.46,163.38z M543.32,241.84
	c-0.21-0.86-0.37-1.53-0.53-2.18c-0.74,0-1.37,0-2.08,0c0.07,0.75,0.14,1.44,0.21,2.18C541.68,241.84,542.38,241.84,543.32,241.84z
	 M457.29,30c0.18,0.87,0.32,1.55,0.47,2.27c0.72-0.08,1.33-0.15,2.03-0.22c-0.08-0.8-0.14-1.47-0.2-2.15
	C458.75,29.94,458.13,29.97,457.29,30z M590.06,159.5c-0.82,0.04-1.51,0.08-2.25,0.12c0.16,0.78,0.3,1.45,0.43,2.07
	c0.74,0,1.36,0,2.04,0C590.2,160.99,590.15,160.37,590.06,159.5z M585.56,505.38c0.81-0.06,1.55-0.11,2.31-0.16
	c-0.2-0.84-0.35-1.51-0.48-2.08c-0.77,0-1.38,0-2.07,0C585.39,503.91,585.47,504.59,585.56,505.38z M565.43,457.95
	c-0.84,0.05-1.45,0.09-2.12,0.14c0.06,0.79,0.11,1.46,0.17,2.25c0.69-0.1,1.3-0.19,1.96-0.29
	C565.43,459.38,565.43,458.77,565.43,457.95z M583.81,501.93c-0.05-0.91-0.09-1.58-0.13-2.34c-0.85,0.1-1.51,0.17-2.19,0.25
	c0.07,0.83,0.11,1.43,0.17,2.09C582.34,501.93,582.96,501.93,583.81,501.93z M423.93,75.03c-0.08-0.81-0.15-1.54-0.22-2.3
	c-0.84,0.16-1.5,0.29-2.19,0.42c0.05,0.77,0.09,1.38,0.13,2.03C422.42,75.13,423.1,75.09,423.93,75.03z M613.15,282.58
	c0.71-0.09,1.39-0.18,2.09-0.27c-0.07-0.75-0.13-1.43-0.2-2.11c-0.88,0-1.68,0-2.49,0C612.77,281.07,612.96,281.82,613.15,282.58z
	 M640.52,574.27c-0.75,0.08-1.36,0.14-2.11,0.22c0.05,0.81,0.09,1.53,0.14,2.34c0.88-0.21,1.54-0.38,2.26-0.55
	C640.71,575.64,640.63,575.1,640.52,574.27z M467.46,36.55c-0.79,0.16-1.38,0.28-2.01,0.41c0.08,0.77,0.15,1.37,0.22,1.99
	c0.78-0.04,1.39-0.07,2.07-0.11C467.64,38.06,467.57,37.45,467.46,36.55z M605.1,273.7c0.05,0.87,0.1,1.7,0.16,2.55
	c0.77-0.13,1.45-0.24,2.2-0.37c-0.1-0.82-0.19-1.56-0.29-2.32C606.39,273.61,605.76,273.65,605.1,273.7z M374.01,39.55
	c-0.1-0.75-0.18-1.4-0.27-2.11c-0.83,0.14-1.49,0.24-2.21,0.36c0.09,0.76,0.17,1.36,0.24,1.94
	C372.5,39.68,373.12,39.63,374.01,39.55z M572.88,280.19c0.14,0.86,0.27,1.59,0.39,2.3c0.84-0.17,1.51-0.3,2.25-0.44
	c-0.13-0.71-0.24-1.31-0.34-1.86C574.39,280.19,573.75,280.19,572.88,280.19z M526.39,573.22c0,0.89,0,1.58,0,2.37
	c0.77-0.12,1.44-0.23,2.18-0.35c-0.03-0.76-0.06-1.36-0.09-2.02C527.72,573.22,527.1,573.22,526.39,573.22z M416.71,75.74
	c-0.17-0.87-0.3-1.51-0.43-2.17c-0.73,0.05-1.26,0.09-1.92,0.13c0.05,0.78,0.09,1.46,0.13,2.2
	C415.23,75.85,415.85,75.81,416.71,75.74z M604.99,229.13c0.8-0.13,1.39-0.23,1.97-0.33c-0.1-0.81-0.18-1.48-0.27-2.24
	c-0.83,0.07-1.51,0.13-2.2,0.19C604.65,227.55,604.8,228.24,604.99,229.13z M453.57,26.94c0.11,0.87,0.2,1.53,0.29,2.19
	c0.85-0.11,1.51-0.19,2.22-0.29c-0.12-0.81-0.21-1.43-0.32-2.13C455.07,26.78,454.45,26.84,453.57,26.94z M501.33,75.54
	c0.8-0.1,1.42-0.17,2.05-0.24c-0.11-0.78-0.21-1.47-0.33-2.28c-0.77,0.06-1.46,0.12-2.19,0.17
	C501.02,73.97,501.15,74.65,501.33,75.54z M496.22,182.22c0.04,0.68,0.08,1.34,0.13,2.11c0.73-0.06,1.31-0.11,2.01-0.16
	c-0.08-0.86-0.14-1.51-0.21-2.23C497.43,182.05,496.89,182.13,496.22,182.22z M577.74,182.14c-0.82,0.14-1.54,0.26-2.31,0.4
	c0.11,0.76,0.2,1.36,0.29,1.99c0.78-0.08,1.44-0.16,2.12-0.23C577.8,183.48,577.78,182.87,577.74,182.14z M622.3,572.13
	c-0.73,0.09-1.39,0.18-2.1,0.27c0.08,0.8,0.14,1.41,0.21,2.08c0.73-0.07,1.34-0.14,2.02-0.21
	C622.38,573.52,622.34,572.9,622.3,572.13z M589.78,447.13c-0.08-0.84-0.14-1.44-0.22-2.15c-0.67,0.04-1.27,0.07-2.01,0.11
	c0.09,0.82,0.15,1.47,0.22,2.14C588.52,447.19,589.07,447.16,589.78,447.13z M537.79,292.81c0.86-0.23,1.52-0.4,2.27-0.6
	c-0.04-0.65-0.07-1.24-0.1-1.91c-0.84,0.04-1.51,0.07-2.32,0.11C537.69,291.19,537.73,291.87,537.79,292.81z M551.38,587.72
	c-0.11-0.85-0.19-1.45-0.28-2.15c-0.7,0.1-1.28,0.18-1.95,0.27c0.1,0.77,0.18,1.36,0.26,1.98
	C550.1,587.79,550.66,587.76,551.38,587.72z M673.32,616.91c-0.14-0.76-0.27-1.44-0.41-2.19c-0.8,0.1-1.46,0.18-2.12,0.26
	c0.12,0.77,0.22,1.39,0.34,2.15C671.87,617.05,672.56,616.98,673.32,616.91z M610.14,417.65c0.26,0.96,0.46,1.69,0.65,2.39
	c0.7-0.06,1.24-0.1,1.92-0.16c-0.1-0.85-0.19-1.57-0.27-2.24C611.63,417.65,611.01,417.65,610.14,417.65z M412.74,72.44
	c-0.05-0.74-0.09-1.43-0.14-2.24c-0.74,0.08-1.4,0.15-2.12,0.23c0.07,0.78,0.12,1.38,0.18,2.01
	C411.41,72.44,412.03,72.44,412.74,72.44z M493.86,505.95c-0.13-0.66-0.25-1.31-0.39-2.01c-0.84,0.06-1.51,0.11-2.2,0.16
	c0.12,0.82,0.21,1.43,0.32,2.12C492.33,506.13,493.02,506.04,493.86,505.95z M286.1,70.52c-0.8,0.06-1.42,0.1-2.11,0.15
	c0.07,0.8,0.14,1.47,0.2,2.13c0.81-0.06,1.5-0.1,2.23-0.16C286.32,71.95,286.23,71.33,286.1,70.52z M600.92,449.87
	c-0.05-0.79-0.09-1.52-0.14-2.29c-0.84,0.14-1.5,0.25-2.2,0.37c0.07,0.75,0.12,1.34,0.17,1.92
	C599.51,449.87,600.13,449.87,600.92,449.87z M596.96,320.06c-0.12-0.83-0.22-1.51-0.33-2.28c-0.8,0.06-1.5,0.12-2.26,0.18
	c0.16,0.76,0.3,1.44,0.47,2.24C595.51,320.15,596.12,320.11,596.96,320.06z M604.38,239.92c-0.09-0.76-0.18-1.43-0.27-2.17
	c-0.78,0.14-1.45,0.26-2.18,0.39c0.12,0.8,0.2,1.4,0.29,2.01C602.98,240.07,603.59,240,604.38,239.92z M524.74,571.96
	c-0.05-0.85-0.09-1.45-0.13-2.12c-0.84,0.05-1.52,0.09-2.31,0.13c0.16,0.77,0.3,1.42,0.45,2.11
	C523.45,572.03,523.99,572,524.74,571.96z M572.22,119.88c-0.04-0.86-0.08-1.47-0.12-2.22c-0.76,0.16-1.4,0.29-2.07,0.43
	c0.09,0.74,0.16,1.32,0.23,1.95C570.96,119.98,571.49,119.94,572.22,119.88z M539.77,77.07c-0.86,0.14-1.62,0.27-2.4,0.4
	c0.14,0.79,0.26,1.47,0.38,2.16c0.75-0.07,1.43-0.13,2.14-0.19C539.85,78.65,539.81,77.95,539.77,77.07z M483.53,120.49
	c0.74-0.07,1.42-0.13,2.17-0.2c-0.13-0.75-0.25-1.41-0.4-2.22c-0.74,0.08-1.41,0.15-2.12,0.22
	C483.3,119.04,483.41,119.7,483.53,120.49z M492.74,186.03c0.09,0.75,0.16,1.41,0.25,2.15c0.74-0.08,1.32-0.14,1.99-0.22
	c-0.07-0.78-0.12-1.38-0.18-2.03C494.06,185.97,493.45,185.99,492.74,186.03z M601.41,370.4c-0.21-0.82-0.39-1.54-0.57-2.26
	c-0.67,0.05-1.2,0.09-1.83,0.14c0.05,0.82,0.09,1.48,0.14,2.29C599.97,370.51,600.66,370.46,601.41,370.4z M667.2,589.77
	c-0.9,0.1-1.56,0.17-2.26,0.25c0.2,0.82,0.36,1.5,0.54,2.25c0.68-0.04,1.29-0.08,1.98-0.12C667.37,591.35,667.3,590.67,667.2,589.77
	z M565.55,233.92c0.06,0.9,0.11,1.58,0.17,2.37c0.81-0.13,1.46-0.24,2.17-0.35c-0.17-0.77-0.3-1.38-0.43-2.02
	C566.86,233.92,566.33,233.92,565.55,233.92z M476.13,139.1c0-0.68,0-1.35,0-1.97c-0.76,0-1.42,0-2.12,0
	c0.1,0.78,0.18,1.38,0.28,2.09C474.92,139.18,475.46,139.15,476.13,139.1z M593.27,361.48c-0.8,0.08-1.47,0.14-2.21,0.22
	c0.17,0.76,0.31,1.42,0.44,1.98c0.72,0,1.25,0,1.94,0C593.38,362.93,593.33,362.27,593.27,361.48z M564.88,118.57
	c-0.79,0.04-1.44,0.08-2.15,0.12c0.05,0.83,0.1,1.44,0.14,2.14c0.77-0.1,1.45-0.19,2.25-0.3
	C565.04,119.84,564.96,119.25,564.88,118.57z M594.61,249.24c-0.82,0.22-1.53,0.41-2.29,0.61c0.12,0.68,0.22,1.21,0.32,1.78
	c0.74-0.03,1.35-0.06,1.96-0.09C594.61,250.74,594.61,250.04,594.61,249.24z M503.81,188.76c0.08,0.76,0.14,1.37,0.2,1.96
	c0.75,0,1.42,0,2.18,0c-0.09-0.84-0.17-1.57-0.25-2.3C505.19,188.54,504.58,188.64,503.81,188.76z M554.35,104.94
	c0.06,0.8,0.1,1.46,0.16,2.22c0.89-0.07,1.58-0.13,2.34-0.19c-0.15-0.78-0.29-1.46-0.44-2.24
	C555.66,104.81,555.06,104.87,554.35,104.94z M571.66,511.7c0.05,0.73,0.11,1.4,0.15,2.04c0.86,0,1.55,0,2.32,0
	c-0.1-0.85-0.2-1.61-0.3-2.49C573.14,511.39,572.47,511.53,571.66,511.7z M581.24,288.93c0.76-0.08,1.29-0.14,1.94-0.21
	c-0.05-0.75-0.09-1.4-0.13-2.14c-0.77,0.15-1.36,0.27-1.96,0.39C581.14,287.67,581.18,288.2,581.24,288.93z M564.67,514.23
	c0.83-0.08,1.49-0.14,2.18-0.2c-0.07-0.84-0.13-1.51-0.19-2.21c-0.8,0.14-1.47,0.26-2.2,0.39
	C564.54,512.86,564.59,513.41,564.67,514.23z M386.44,63.87c0-0.73,0-1.45,0-2.3c-0.82,0.09-1.48,0.16-2.18,0.23
	c0.19,0.84,0.35,1.52,0.5,2.19C385.36,63.95,385.84,63.92,386.44,63.87z M464.79,31.75c0.88-0.07,1.56-0.12,2.34-0.18
	c-0.19-0.79-0.35-1.45-0.49-2.01c-0.73,0-1.26,0-1.96,0C464.72,30.27,464.75,30.89,464.79,31.75z M538.19,252.99
	c0.91-0.07,1.58-0.13,2.36-0.19c-0.2-0.8-0.36-1.46-0.53-2.14c-0.8,0.05-1.41,0.09-2.04,0.13
	C538.06,251.54,538.12,252.17,538.19,252.99z M496.99,444.55c0.89-0.13,1.55-0.23,2.28-0.35c-0.18-0.8-0.32-1.46-0.47-2.15
	c-0.7,0.03-1.23,0.06-1.81,0.09C496.99,442.93,496.99,443.6,496.99,444.55z M565.02,452.93c-0.05-0.84-0.09-1.5-0.14-2.25
	c-0.74,0.13-1.33,0.23-2.04,0.35c0.06,0.72,0.12,1.31,0.18,2.01C563.66,453,564.2,452.97,565.02,452.93z M557.87,287.35
	c-0.07-0.87-0.12-1.48-0.16-1.99c-0.78,0-1.43,0-2.15,0c0.14,0.81,0.24,1.46,0.35,2.13C556.61,287.45,557.13,287.41,557.87,287.35z
	 M541.47,82.99c0.89-0.1,1.57-0.17,2.33-0.25c-0.1-0.72-0.19-1.38-0.29-2.12c-0.74,0.06-1.4,0.12-2.03,0.17
	C541.47,81.56,541.47,82.17,541.47,82.99z M624.71,516.32c-0.08-0.82-0.15-1.5-0.24-2.33c-0.76,0.04-1.45,0.09-2.23,0.13
	c0.18,0.84,0.33,1.58,0.49,2.37C623.44,516.43,624.04,516.38,624.71,516.32z M563.64,254.46c0.78-0.05,1.52-0.09,2.34-0.14
	c-0.17-0.83-0.31-1.5-0.46-2.26c-0.81,0.04-1.5,0.08-2.2,0.11C563.42,252.96,563.52,253.64,563.64,254.46z M619,325.33
	c-0.13-0.86-0.23-1.48-0.34-2.15c-0.73,0.1-1.4,0.2-2.1,0.3c0.13,0.77,0.24,1.45,0.35,2.12C617.65,325.5,618.26,325.43,619,325.33z
	 M585.43,230.62c-0.06-0.7-0.12-1.3-0.18-2.02c-0.69,0.1-1.28,0.19-1.97,0.29c0.1,0.72,0.19,1.3,0.27,1.91
	C584.24,230.73,584.77,230.68,585.43,230.62z M483.59,167.62c0.87-0.22,1.54-0.39,2.3-0.59c-0.03-0.7-0.06-1.31-0.09-2.03
	c-0.81,0.07-1.48,0.13-2.2,0.19C483.59,165.96,483.59,166.66,483.59,167.62z M568.32,515.53c0.05,0.71,0.1,1.38,0.15,2.09
	c0.81-0.08,1.48-0.15,2.18-0.22c-0.04-0.78-0.07-1.47-0.11-2.21C569.82,515.3,569.14,515.4,568.32,515.53z M577.5,137.71
	c-0.22-0.85-0.39-1.51-0.56-2.16c-0.69,0.03-1.22,0.06-1.89,0.09c0.04,0.77,0.08,1.42,0.12,2.08
	C575.96,137.71,576.58,137.71,577.5,137.71z M464.02,119.7c-0.84,0.19-1.58,0.35-2.33,0.52c0.13,0.73,0.24,1.33,0.35,1.94
	c0.79-0.07,1.41-0.12,1.98-0.17C464.02,121.17,464.02,120.49,464.02,119.7z M422.61,60.59c-0.05-0.86-0.08-1.48-0.12-2.08
	c-0.83,0-1.59,0-2.44,0c0.16,0.82,0.31,1.56,0.46,2.33C421.22,60.75,421.84,60.68,422.61,60.59z M387.84,60.08
	c0.71-0.06,1.36-0.12,2.07-0.19c-0.06-0.78-0.11-1.38-0.15-1.92c-0.79,0-1.43,0-2.11,0C387.72,58.73,387.77,59.34,387.84,60.08z
	 M300.92,58.2c0.15,0.77,0.28,1.43,0.41,2.08c0.77-0.08,1.38-0.14,2.02-0.2c-0.07-0.74-0.13-1.35-0.19-2.01
	C302.39,58.11,301.71,58.15,300.92,58.2z M493.63,193.33c0,0.75,0,1.35,0,2.04c0.75-0.04,1.37-0.08,2.07-0.12
	c-0.06-0.73-0.12-1.42-0.18-2.25C494.8,193.13,494.21,193.23,493.63,193.33z M618.31,564.08c-0.16-0.94-0.27-1.63-0.39-2.39
	c-0.7,0.16-1.36,0.31-2.06,0.47c0.05,0.72,0.08,1.32,0.12,1.92C616.79,564.08,617.47,564.08,618.31,564.08z M574.01,374.05
	c0.09,0.87,0.16,1.53,0.24,2.24c0.87-0.08,1.53-0.14,2.26-0.21c-0.19-0.81-0.34-1.46-0.47-2.04
	C575.32,374.05,574.78,374.05,574.01,374.05z M488.76,58.54c0.74-0.12,1.45-0.24,2.28-0.39c-0.09-0.64-0.16-1.16-0.24-1.74
	c-0.76,0-1.43,0-2.19,0C488.67,57.12,488.71,57.73,488.76,58.54z M327.8,34.31c0.11,0.77,0.21,1.44,0.31,2.14
	c0.78-0.11,1.45-0.2,2.13-0.3c-0.08-0.81-0.15-1.48-0.22-2.2C329.23,34.08,328.55,34.19,327.8,34.31z M579.14,285.42
	c-0.04-0.72-0.08-1.39-0.12-2.17c-0.69,0.06-1.21,0.11-1.87,0.17c0.06,0.75,0.1,1.42,0.16,2.18
	C577.97,285.54,578.5,285.48,579.14,285.42z M409.29,56c0.07,0.72,0.13,1.39,0.2,2.16c0.74-0.05,1.33-0.1,2-0.15
	c-0.05-0.81-0.09-1.42-0.13-2.12C410.62,55.93,410.01,55.96,409.29,56z M592.83,256.9c0.08,0.83,0.13,1.42,0.19,2.03
	c0.81-0.05,1.43-0.09,2.18-0.14c-0.06-0.75-0.11-1.43-0.16-2.15C594.28,256.73,593.66,256.8,592.83,256.9z M565.88,486.79
	c0.1,0.77,0.17,1.36,0.26,2.03c0.82-0.09,1.47-0.16,2.04-0.23c0-0.86,0-1.54,0-2.3C567.42,486.46,566.75,486.6,565.88,486.79z
	 M539.43,283.35c-0.8,0-1.48,0-2.29,0c0.07,0.73,0.13,1.38,0.2,2.12c0.86-0.16,1.52-0.28,2.27-0.43
	C539.54,284.43,539.49,283.9,539.43,283.35z M637.1,560.1c0.07,0.86,0.12,1.58,0.18,2.41c0.85-0.24,1.5-0.43,2.18-0.62
	c-0.05-0.74-0.1-1.35-0.15-2C638.59,559.96,637.98,560.02,637.1,560.1z M553.12,298.42c0.87,0,1.47,0,2.16,0
	c-0.09-0.77-0.16-1.44-0.24-2.19c-0.72,0.06-1.31,0.11-1.92,0.15C553.12,297.16,553.12,297.76,553.12,298.42z M496.49,521.69
	c0.06,0.79,0.11,1.51,0.16,2.28c0.78-0.16,1.38-0.28,2.1-0.42c-0.04-0.64-0.08-1.23-0.12-1.86
	C497.85,521.69,497.24,521.69,496.49,521.69z M610.81,258.84c0.13,0.77,0.25,1.45,0.37,2.13c0.76-0.07,1.44-0.13,2.26-0.21
	c-0.12-0.7-0.23-1.35-0.33-1.92C612.24,258.84,611.55,258.84,610.81,258.84z M497.69,177.07c-0.05-0.75-0.1-1.45-0.16-2.27
	c-0.74,0.08-1.4,0.15-2.13,0.23c0.07,0.79,0.13,1.41,0.18,2.04C496.34,177.07,496.97,177.07,497.69,177.07z M573.87,121.25
	c0.08,0.78,0.14,1.39,0.21,2.1c0.64-0.03,1.23-0.06,1.97-0.09c-0.06-0.8-0.11-1.45-0.16-2.12
	C575.17,121.18,574.62,121.21,573.87,121.25z M586.08,195.84c-0.91,0.08-1.58,0.14-2.33,0.21c0.18,0.79,0.33,1.45,0.51,2.24
	c0.74-0.04,1.41-0.07,2.12-0.1C586.28,197.39,586.2,196.71,586.08,195.84z M486.84,34.81c0.03,0.75,0.07,1.48,0.11,2.29
	c0.81-0.12,1.4-0.2,2.05-0.29c-0.07-0.82-0.13-1.42-0.19-2.12C488.13,34.73,487.58,34.76,486.84,34.81z M604.1,485.6
	c-0.12-0.89-0.21-1.56-0.31-2.28c-0.85,0.1-1.51,0.17-2.22,0.25c0.18,0.78,0.31,1.38,0.46,2.03
	C602.69,485.6,603.3,485.6,604.1,485.6z M506.77,556.93c0.14,0.91,0.25,1.57,0.35,2.24c0.81-0.06,1.44-0.1,2.15-0.15
	c-0.17-0.77-0.33-1.44-0.47-2.09C508.11,556.93,507.57,556.93,506.77,556.93z M600.7,555.96c0.11,0.78,0.19,1.44,0.29,2.18
	c0.74-0.1,1.32-0.19,1.97-0.28c-0.08-0.77-0.15-1.37-0.22-2C602.02,555.9,601.42,555.93,600.7,555.96z M608.78,524.96
	c0.81-0.15,1.46-0.28,2.23-0.42c-0.1-0.66-0.19-1.23-0.28-1.88c-0.8,0.08-1.45,0.15-2.12,0.22
	C608.67,523.64,608.72,524.24,608.78,524.96z M533.19,585.72c-0.05-0.62-0.11-1.29-0.17-1.98c-0.8,0-1.49,0-2.28,0
	c0.1,0.77,0.19,1.44,0.29,2.11C531.74,585.8,532.37,585.76,533.19,585.72z M260.13,101.64c-0.98,0.06-1.73,0.11-2.54,0.16
	c0.15,0.75,0.27,1.35,0.4,2.03c0.77-0.04,1.47-0.07,2.3-0.1C260.24,102.99,260.19,102.38,260.13,101.64z M561.1,114.97
	c-0.81,0.18-1.52,0.33-2.13,0.46c0,0.79,0,1.4,0,2.12c0.76-0.12,1.42-0.22,2.13-0.33C561.1,116.47,561.1,115.79,561.1,114.97z
	 M561.2,283.55c-0.13-0.86-0.23-1.52-0.34-2.26c-0.72,0.05-1.31,0.1-1.98,0.15c0.1,0.82,0.18,1.49,0.28,2.24
	C559.82,283.64,560.36,283.6,561.2,283.55z M570.88,377.94c0.09,0.78,0.17,1.45,0.25,2.22c0.68-0.07,1.22-0.13,1.85-0.2
	c-0.07-0.74-0.14-1.42-0.22-2.18C572.1,377.83,571.57,377.87,570.88,377.94z M585.56,481.5c-0.76,0.06-1.4,0.11-2.1,0.16
	c0.11,0.83,0.18,1.42,0.27,2.11c0.69-0.05,1.28-0.09,1.94-0.14C585.64,482.82,585.6,482.21,585.56,481.5z M580.08,525.41
	c0.09,0.79,0.16,1.33,0.23,1.97c0.64-0.05,1.24-0.09,1.85-0.13c0-0.79,0-1.4,0-2.13C581.46,525.21,580.87,525.29,580.08,525.41z
	 M612.71,526.06c0,0.73,0,1.42,0,2.2c0.8-0.12,1.53-0.24,2.31-0.36c-0.06-0.87-0.1-1.54-0.15-2.29
	C614.09,525.78,613.41,525.92,612.71,526.06z M565.93,378.36c-0.74,0.12-1.44,0.24-2.29,0.38c0.14,0.69,0.25,1.25,0.37,1.86
	c0.76-0.03,1.36-0.06,2.06-0.09C566.03,379.78,565.99,379.17,565.93,378.36z M482.3,150.74c0.14,0.72,0.27,1.43,0.41,2.14
	c0.78-0.08,1.39-0.14,2.07-0.21c-0.08-0.78-0.16-1.44-0.22-2.06C483.76,150.66,483.13,150.69,482.3,150.74z M333.89,37.45
	c-0.71,0.05-1.38,0.11-2.16,0.17c0.05,0.8,0.08,1.47,0.12,2.19c0.79-0.06,1.48-0.11,2.27-0.17
	C334.04,38.91,333.97,38.24,333.89,37.45z M642.61,597.71c-0.09-0.83-0.15-1.41-0.22-2.02c-0.78,0.08-1.39,0.14-2.1,0.21
	c0.12,0.73,0.23,1.39,0.34,2.08C641.34,597.88,641.88,597.81,642.61,597.71z M535.97,310.83c0.75-0.1,1.42-0.2,2.19-0.3
	c-0.04-0.71-0.07-1.31-0.1-1.9c-0.83,0-1.51,0-2.37,0C535.79,309.41,535.87,310.08,535.97,310.83z M575.89,202.68
	c-0.06-0.88-0.11-1.61-0.17-2.38c-0.79,0.16-1.46,0.29-2.22,0.44c0.07,0.79,0.13,1.51,0.19,2.23
	C574.48,202.86,575.09,202.78,575.89,202.68z M542.58,593.62c0.05,0.73,0.09,1.39,0.14,2.1c0.86-0.08,1.55-0.14,2.33-0.21
	c-0.11-0.7-0.21-1.29-0.3-1.88C543.99,593.62,543.36,593.62,542.58,593.62z M638.87,554.81c0-0.76,0-1.45,0-2.28
	c-0.81,0.17-1.52,0.32-2.22,0.47c0.04,0.79,0.06,1.4,0.1,2.09C637.47,555,638.15,554.91,638.87,554.81z M640.2,529.37
	c0-0.76,0-1.47,0-2.27c-0.75,0.14-1.43,0.27-2.12,0.4c0.05,0.79,0.09,1.47,0.13,2.23C638.95,529.59,639.57,529.48,640.2,529.37z
	 M389.39,52.7c-0.05-0.87-0.08-1.48-0.12-2.23c-0.77,0.14-1.42,0.26-2.11,0.39c0.11,0.76,0.19,1.35,0.29,2
	C388.12,52.8,388.66,52.76,389.39,52.7z M473.77,50.57c0.05,0.77,0.09,1.44,0.13,2.15c0.82-0.06,1.51-0.12,2.26-0.17
	c-0.06-0.79-0.12-1.49-0.18-2.26C475.27,50.38,474.58,50.47,473.77,50.57z M598.06,332.05c-0.87,0.12-1.55,0.21-2.31,0.32
	c0.18,0.82,0.32,1.49,0.48,2.21c0.7-0.03,1.24-0.05,1.83-0.08C598.06,333.7,598.06,333.01,598.06,332.05z M466.4,132.85
	c0.87-0.15,1.54-0.26,2.26-0.38c-0.1-0.78-0.18-1.39-0.27-2.08c-0.75,0.14-1.42,0.27-2.09,0.4
	C466.33,131.51,466.36,132.06,466.4,132.85z M618.78,529.11c-0.81,0.08-1.55,0.16-2.3,0.23c0.05,0.83,0.08,1.53,0.13,2.31
	c0.76-0.14,1.5-0.27,2.18-0.39C618.78,530.45,618.78,529.83,618.78,529.11z M578.4,149.86c-0.75,0-1.47,0-2.34,0
	c0.07,0.79,0.13,1.53,0.2,2.36c0.79-0.18,1.46-0.33,2.14-0.48C578.4,151.07,578.4,150.53,578.4,149.86z M575.62,350.88
	c0.88-0.06,1.56-0.11,2.3-0.17c-0.16-0.76-0.31-1.42-0.46-2.15c-0.72,0.03-1.31,0.06-1.97,0.09
	C575.54,349.42,575.57,350.03,575.62,350.88z M574.99,383.47c0.82-0.13,1.41-0.22,2.08-0.32c-0.12-0.75-0.22-1.34-0.32-2.01
	c-0.73,0.1-1.32,0.17-1.92,0.25C574.88,382.15,574.93,382.7,574.99,383.47z M580.77,550.58c-0.83,0.04-1.44,0.08-2.16,0.12
	c0.11,0.8,0.21,1.46,0.31,2.21c0.76-0.12,1.35-0.21,1.85-0.29C580.77,551.89,580.77,551.34,580.77,550.58z M494.76,460.43
	c0.15,0.92,0.26,1.58,0.38,2.28c0.85-0.09,1.51-0.16,2.24-0.24c-0.18-0.8-0.32-1.4-0.47-2.04
	C496.23,460.43,495.61,460.43,494.76,460.43z M609.59,532.06c0.79-0.13,1.38-0.23,2.06-0.34c-0.07-0.76-0.13-1.41-0.18-2.04
	c-0.88,0.04-1.57,0.07-2.33,0.1C609.28,530.52,609.42,531.2,609.59,532.06z M569.86,383.83c-0.07-0.9-0.12-1.51-0.16-2.04
	c-0.8,0-1.47,0-2.16,0c0.08,0.77,0.14,1.39,0.21,2.04C568.4,383.83,569.01,383.83,569.86,383.83z M545.25,349.8
	c-0.06-0.69-0.12-1.39-0.19-2.18c-0.89,0.18-1.54,0.32-2.23,0.46c0.09,0.76,0.16,1.36,0.24,1.97
	C543.79,349.97,544.41,349.9,545.25,349.8z M634.52,531.18c0.19,0.83,0.35,1.56,0.52,2.31c0.77-0.12,1.36-0.22,2.03-0.33
	c-0.04-0.72-0.07-1.34-0.1-1.98C636.14,531.18,635.39,531.18,634.52,531.18z M473.77,131.98c0.6-0.04,1.18-0.08,1.88-0.12
	c-0.09-0.82-0.17-1.47-0.25-2.18c-0.7,0.09-1.22,0.15-1.8,0.22C473.66,130.62,473.71,131.24,473.77,131.98z M417.73,48.17
	c-0.77,0.08-1.36,0.14-1.98,0.21c0.04,0.77,0.07,1.38,0.11,2.12c0.85-0.05,1.54-0.09,2.31-0.14
	C418.03,49.65,417.91,49.03,417.73,48.17z M625.56,550.17c0.09,0.8,0.16,1.47,0.25,2.22c0.71-0.06,1.3-0.11,1.94-0.16
	c-0.06-0.81-0.1-1.44-0.16-2.16C626.95,550.1,626.34,550.13,625.56,550.17z M530.06,217.44c-0.08-0.77-0.14-1.38-0.21-2.02
	c-0.75,0.06-1.38,0.11-2.01,0.16c0,0.8,0,1.54,0,2.36C528.6,217.78,529.21,217.64,530.06,217.44z M330.41,23.19
	c0.06,0.84,0.1,1.45,0.15,2.12c0.76-0.12,1.43-0.22,2.11-0.33c0-0.76,0-1.44,0-2.2C331.91,22.92,331.24,23.04,330.41,23.19z
	 M620.5,534.72c0.94-0.09,1.59-0.15,2.28-0.22c-0.11-0.77-0.19-1.38-0.28-2c-0.75,0.06-1.42,0.12-2.23,0.19
	C620.34,533.31,620.41,533.91,620.5,534.72z M574.71,215.05c0.05,0.74,0.1,1.41,0.16,2.2c0.75-0.1,1.4-0.19,2.15-0.28
	c-0.03-0.73-0.06-1.34-0.09-2.02C576.18,214.97,575.5,215.01,574.71,215.05z M485.72,38.44c-0.77,0.19-1.41,0.34-2.04,0.49
	c0.07,0.72,0.12,1.26,0.18,1.89c0.63-0.03,1.23-0.06,1.96-0.09C485.79,39.93,485.76,39.26,485.72,38.44z M602.37,548.56
	c-0.77,0.11-1.38,0.2-2.1,0.31c0.06,0.72,0.11,1.32,0.16,2.03c0.67-0.03,1.27-0.06,1.93-0.08
	C602.37,550.07,602.37,549.45,602.37,548.56z M592.52,394.02c-0.74,0.19-1.37,0.34-2.04,0.51c0.07,0.7,0.12,1.22,0.17,1.74
	c0.75,0,1.35,0,2.02,0C592.63,395.53,592.58,394.86,592.52,394.02z M472.3,49.16c-0.05-0.9-0.1-1.63-0.15-2.41
	c-0.82,0.2-1.49,0.37-2.2,0.54c0.04,0.76,0.07,1.41,0.1,2.07C470.9,49.28,471.51,49.23,472.3,49.16z M614.52,549.94
	c0.88-0.21,1.54-0.37,2.22-0.54c-0.05-0.76-0.08-1.38-0.13-2.05c-0.78,0.12-1.45,0.23-2.09,0.33
	C614.52,548.45,614.52,549.07,614.52,549.94z M392.48,48.81c-0.06-0.73-0.11-1.39-0.16-2.12c-0.78,0.09-1.36,0.17-1.98,0.24
	c0.04,0.8,0.07,1.42,0.11,2.13C391.16,48.97,391.76,48.9,392.48,48.81z M583.3,436.59c0.65,0,1.23,0,1.93,0
	c-0.04-0.76-0.08-1.41-0.12-2.16c-0.86,0.09-1.51,0.15-2.23,0.22C583.04,435.39,583.17,435.98,583.3,436.59z M600.96,534.25
	c-0.9,0.07-1.57,0.13-2.35,0.19c0.2,0.82,0.36,1.47,0.54,2.18c0.74-0.13,1.26-0.23,1.81-0.33
	C600.96,535.63,600.96,535.08,600.96,534.25z M574.79,547.34c0.07,0.81,0.12,1.42,0.18,2.16c0.72-0.08,1.37-0.16,2.06-0.24
	c-0.04-0.79-0.06-1.4-0.09-2.13C576.2,547.2,575.59,547.26,574.79,547.34z M523.12,204.98c0.1,0.8,0.19,1.48,0.29,2.24
	c0.73-0.13,1.39-0.25,2.12-0.38c-0.06-0.71-0.12-1.37-0.18-2.01C524.59,204.89,523.97,204.93,523.12,204.98z M640.92,536.57
	c-0.05-0.81-0.08-1.42-0.12-2.11c-0.76,0.06-1.45,0.11-2.22,0.16c0.05,0.79,0.09,1.51,0.13,2.26
	C639.48,536.77,640.1,536.68,640.92,536.57z M518.37,207.75c-0.13-0.88-0.23-1.53-0.33-2.21c-0.69,0.07-1.21,0.13-1.85,0.2
	c0.07,0.8,0.13,1.45,0.2,2.17C517.09,207.85,517.63,207.81,518.37,207.75z M573.61,387.07c-0.06-0.79-0.11-1.39-0.15-1.91
	c-0.75,0-1.35,0-2.06,0c0.13,0.78,0.24,1.44,0.35,2.11C572.41,387.2,572.88,387.15,573.61,387.07z M311,48.61
	c0.9-0.08,1.57-0.14,2.28-0.2c-0.12-0.79-0.22-1.4-0.32-2.02c-0.78,0.09-1.46,0.17-2.23,0.26C310.83,47.29,310.9,47.83,311,48.61z
	 M512.86,169.78c0.13,0.85,0.24,1.54,0.36,2.31c0.77-0.11,1.5-0.22,2.24-0.33c-0.17-0.82-0.3-1.49-0.43-2.14
	C514.28,169.68,513.66,169.72,512.86,169.78z M618.84,307.21c0.78-0.05,1.51-0.09,2.34-0.14c-0.14-0.76-0.24-1.35-0.35-1.97
	c-0.87,0.09-1.54,0.15-2.25,0.23C618.67,306.01,618.74,306.55,618.84,307.21z M511.17,206.08c-0.79,0.19-1.45,0.35-2.16,0.52
	c0.11,0.7,0.2,1.29,0.29,1.89c0.86-0.07,1.55-0.13,2.21-0.18C511.4,207.56,511.3,206.93,511.17,206.08z M592.23,436.17
	c0-0.73,0-1.46,0-2.04c-0.81,0-1.47,0-2.17,0c0.12,0.81,0.21,1.46,0.31,2.16C591.06,436.24,591.6,436.2,592.23,436.17z
	 M377.68,40.57c-0.89,0.15-1.56,0.27-2.28,0.4c0.05,0.77,0.09,1.39,0.14,2.09c0.79-0.09,1.46-0.17,2.14-0.25
	C377.68,42.05,377.68,41.43,377.68,40.57z M462.02,40.65c0.08,0.76,0.15,1.42,0.22,2.17c0.72-0.04,1.32-0.08,2.05-0.12
	c-0.08-0.83-0.15-1.49-0.21-2.18C463.32,40.57,462.72,40.61,462.02,40.65z M523.74,212.21c0.14,0.81,0.26,1.47,0.39,2.21
	c0.72-0.09,1.33-0.17,2.07-0.27c-0.04-0.7-0.08-1.31-0.12-2.05C525.28,212.14,524.6,212.17,523.74,212.21z M570.3,539.15
	c0.94,0,1.68,0,2.5,0c-0.2-0.87-0.36-1.6-0.53-2.36c-0.73,0.11-1.33,0.2-1.97,0.29C570.3,537.79,570.3,538.41,570.3,539.15z
	 M592.91,343.36c0.23,0.92,0.39,1.58,0.56,2.27c0.73-0.05,1.35-0.1,2.02-0.15c-0.1-0.78-0.18-1.44-0.28-2.24
	C594.48,343.28,593.8,343.32,592.91,343.36z M658.09,608.77c-0.78,0.07-1.46,0.14-2.22,0.21c0.04,0.77,0.08,1.5,0.12,2.3
	c0.76-0.12,1.43-0.23,2.22-0.35C658.17,610.17,658.13,609.5,658.09,608.77z M642.54,540.06c0.86-0.12,1.54-0.21,2.26-0.3
	c-0.07-0.74-0.13-1.34-0.19-1.96c-0.78,0.07-1.46,0.12-2.21,0.19C642.44,538.64,642.49,539.24,642.54,540.06z M558.86,465.78
	c-2.03,0.05-2.03,0.05-1.63,1.97c0.6,0,1.2,0,1.84,0C558.99,467.01,558.93,466.39,558.86,465.78z M539.86,393.41
	c0.71-0.14,1.29-0.26,1.87-0.38c0-0.76,0-1.37,0-1.96c-0.91,0-1.73,0-2.58,0C539.39,391.84,539.59,392.51,539.86,393.41z
	 M588.22,470.39c-0.73,0.08-1.39,0.15-2.11,0.23c0.07,0.79,0.12,1.4,0.18,2.03c0.75,0,1.38,0,2.09,0
	C588.33,471.91,588.28,471.21,588.22,470.39z M580.2,545.47c-0.12-0.87-0.2-1.46-0.3-2.12c-0.73,0.1-1.32,0.18-1.95,0.27
	c0.05,0.79,0.09,1.41,0.13,2.1C578.8,545.63,579.41,545.56,580.2,545.47z M574.16,210.31c0.87-0.26,1.52-0.45,2.15-0.64
	c-0.07-0.77-0.12-1.38-0.18-2.02c-0.78,0.07-1.46,0.14-2.14,0.2C574.04,208.66,574.09,209.36,574.16,210.31z M599.57,541.58
	c0.05,0.82,0.09,1.43,0.12,2.05c0.76,0,1.38,0,2.11,0c-0.08-0.75-0.15-1.44-0.23-2.2C600.91,541.48,600.36,541.52,599.57,541.58z
	 M482.54,44.59c-0.09-0.85-0.15-1.46-0.22-2.16c-0.8,0.07-1.45,0.13-2.21,0.21c0.19,0.77,0.33,1.37,0.47,1.95
	C481.2,44.59,481.73,44.59,482.54,44.59z M562.95,471.1c-0.1-0.85-0.18-1.45-0.26-2.12c-0.72,0.08-1.31,0.14-1.87,0.2
	c0,0.81,0,1.42,0,2.14C561.54,471.24,562.15,471.18,562.95,471.1z M600.64,471.5c0.91-0.09,1.58-0.16,2.35-0.24
	c-0.19-0.77-0.34-1.36-0.49-2c-0.73,0.08-1.33,0.14-1.97,0.2C600.57,470.19,600.6,470.73,600.64,471.5z M551.59,339.79
	c-0.81,0.06-1.43,0.1-2.15,0.16c0.11,0.85,0.21,1.59,0.31,2.37c0.77-0.05,1.39-0.09,2.09-0.14
	C551.76,341.38,551.69,340.7,551.59,339.79z M393.78,45.16c0.89-0.06,1.55-0.11,2.31-0.16c-0.18-0.75-0.32-1.35-0.48-2.04
	c-0.65,0.07-1.24,0.13-1.83,0.18C393.78,43.81,393.78,44.35,393.78,45.16z M473.12,43.32c0,0.85,0,1.52,0,2.32
	c0.75-0.16,1.41-0.31,2.19-0.48c-0.04-0.65-0.07-1.25-0.1-1.94C474.48,43.25,473.88,43.28,473.12,43.32z M575.03,275.17
	c-0.19-0.86-0.35-1.54-0.51-2.25c-0.7,0.11-1.31,0.2-1.89,0.29c0,0.77,0,1.46,0,2.19C573.43,275.32,574.13,275.25,575.03,275.17z
	 M381.53,43.94c-0.91,0.17-1.56,0.3-2.26,0.43c0.07,0.76,0.12,1.37,0.18,2.02c0.77-0.1,1.44-0.18,2.08-0.26
	C381.53,45.37,381.53,44.75,381.53,43.94z M630.59,541.28c-0.19-0.88-0.34-1.57-0.5-2.28c-0.63,0.04-1.17,0.07-1.84,0.11
	c0.05,0.81,0.09,1.49,0.14,2.28C629.15,541.35,629.77,541.32,630.59,541.28z M498.41,545.64c0.84-0.16,1.51-0.3,2.32-0.46
	c-0.15-0.69-0.27-1.27-0.38-1.79c-0.74,0-1.33,0-1.94,0C498.41,544.13,498.41,544.75,498.41,545.64z M536.47,315.98
	c0.13,0.71,0.26,1.37,0.38,2.03c0.73-0.1,1.34-0.18,2.05-0.27c-0.05-0.77-0.09-1.48-0.14-2.21
	C537.92,315.69,537.24,315.83,536.47,315.98z M356.72,9.69c-0.77,0.05-1.39,0.09-2.09,0.13c0.13,0.89,0.25,1.71,0.39,2.61
	c0.63-0.13,1.14-0.24,1.7-0.36C356.72,11.32,356.72,10.63,356.72,9.69z M649.4,590.26c-0.23-0.92-0.41-1.65-0.6-2.38
	c-0.69,0.06-1.22,0.1-1.83,0.16c0.06,0.83,0.11,1.51,0.17,2.22C647.89,590.26,648.5,590.26,649.4,590.26z M591.89,468.81
	c-0.19-0.88-0.33-1.54-0.5-2.31c-0.74,0.17-1.31,0.31-1.95,0.46c0.09,0.73,0.16,1.32,0.22,1.85
	C590.43,468.81,591.04,468.81,591.89,468.81z M550.66,538.72c-0.7,0-1.38,0-2.15,0c0.17,0.78,0.32,1.44,0.47,2.11
	c0.7-0.04,1.24-0.07,1.89-0.11C550.79,540,550.73,539.39,550.66,538.72z M454.74,41.4c0.09,0.75,0.16,1.39,0.24,2.06
	c0.78-0.12,1.39-0.22,2.11-0.33c-0.08-0.65-0.16-1.23-0.23-1.82C456.12,41.33,455.51,41.36,454.74,41.4z M591.81,386.89
	c-0.88,0.08-1.54,0.14-2.27,0.21c0.2,0.79,0.35,1.38,0.53,2.06c0.6-0.03,1.13-0.06,1.74-0.09
	C591.81,388.34,591.81,387.75,591.81,386.89z M334.64,46.62c-0.09-0.73-0.17-1.41-0.27-2.2c-0.73,0.12-1.33,0.22-1.94,0.32
	c0,0.78,0,1.48,0,2.27C333.2,46.87,333.88,46.75,334.64,46.62z M637.45,538.28c-0.86,0.1-1.54,0.18-2.19,0.25c0,0.8,0,1.49,0,2.34
	c0.81-0.2,1.53-0.37,2.19-0.53C637.45,539.62,637.45,539.08,637.45,538.28z M600.33,274.12c-0.77,0.18-1.45,0.34-2.24,0.53
	c0.08,0.67,0.15,1.25,0.22,1.92c0.82-0.07,1.5-0.12,2.24-0.18C600.47,275.56,600.41,274.88,600.33,274.12z M501.85,207.03
	c0.06,0.7,0.11,1.36,0.17,2.05c0.73-0.09,1.34-0.16,2-0.24c0-0.73,0-1.41,0-2.17C503.26,206.8,502.59,206.91,501.85,207.03z
	 M338.1,29.56c0.18,0.87,0.31,1.52,0.46,2.25c0.74-0.13,1.26-0.22,1.76-0.3c0-0.71,0-1.31,0-1.94
	C339.59,29.56,338.98,29.56,338.1,29.56z M603.51,547.2c0.85-0.13,1.5-0.24,2.25-0.36c-0.18-0.77-0.32-1.36-0.47-2.02
	c-0.65,0.04-1.18,0.08-1.78,0.11C603.51,545.68,603.51,546.28,603.51,547.2z M461.15,46.55c-0.26-0.85-0.35-1.6-0.73-2.18
	c-0.12-0.18-0.97,0.12-1.4,0.19c0,0.78,0,1.39,0,2.17C459.72,46.67,460.31,46.62,461.15,46.55z M499.68,536.03
	c-0.72,0.03-1.37,0.06-1.95,0.09c0,0.88,0,1.57,0,2.37c0.81-0.22,1.53-0.42,2.26-0.63C499.88,537.19,499.79,536.66,499.68,536.03z
	 M583.91,129.66c0-0.84,0-1.52,0-2.29c-0.84,0.16-1.59,0.31-2.37,0.46c0.04,0.74,0.08,1.41,0.12,2.17
	C582.44,129.88,583.13,129.78,583.91,129.66z M564.57,387.81c0.7-0.17,1.26-0.31,1.76-0.44c0-0.67,0-1.19,0-1.88
	c-0.77,0.04-1.44,0.07-2.21,0.11C564.27,386.34,564.41,386.99,564.57,387.81z M633.69,537.04c-0.09-0.67-0.16-1.24-0.25-1.9
	c-0.8,0.09-1.45,0.17-2.19,0.25c0.15,0.75,0.26,1.32,0.38,1.94C632.37,537.23,632.97,537.14,633.69,537.04z M578.96,307.29
	c0.8-0.13,1.35-0.22,2-0.33c-0.03-0.7-0.06-1.3-0.09-2.02c-0.72,0.04-1.33,0.07-1.91,0.1C578.96,305.82,578.96,306.44,578.96,307.29
	z M502.42,548.66c0.74-0.11,1.33-0.2,2.03-0.31c-0.1-0.66-0.17-1.17-0.25-1.72c-0.71,0-1.3,0-1.96,0
	C502.3,547.35,502.35,547.95,502.42,548.66z M546.1,275.34c-0.86,0.14-1.53,0.25-2.2,0.36c0.04,0.71,0.07,1.26,0.1,1.92
	c0.8-0.05,1.47-0.1,2.2-0.15C546.17,276.74,546.15,276.19,546.1,275.34z M409.3,16.26c0.11,0.69,0.22,1.36,0.34,2.14
	c0.76-0.08,1.42-0.15,2.12-0.23c-0.15-0.81-0.27-1.47-0.39-2.13C410.61,16.13,410,16.19,409.3,16.26z M564.94,474.65
	c0.69-0.06,1.33-0.12,2.05-0.18c-0.18-0.84-0.31-1.49-0.46-2.18c-0.71,0.11-1.22,0.2-1.77,0.28
	C564.83,473.31,564.88,473.91,564.94,474.65z M577.83,269.1c-0.76,0-1.43,0-2.22,0c0.14,0.76,0.27,1.41,0.41,2.17
	c0.73-0.19,1.3-0.33,1.91-0.48C577.89,270.13,577.86,269.6,577.83,269.1z M545.49,584.3c0.86,0,1.45,0,2.1,0
	c-0.13-0.76-0.23-1.36-0.34-2.01c-0.71,0.1-1.31,0.18-1.97,0.26C545.37,583.22,545.43,583.77,545.49,584.3z M432.53,48.91
	c-0.08-0.75-0.15-1.41-0.22-2.1c-0.77,0.12-1.38,0.22-2.12,0.33c0.07,0.64,0.14,1.22,0.2,1.77
	C431.16,48.91,431.78,48.91,432.53,48.91z M581.9,214.48c0.17,0.81,0.29,1.33,0.4,1.88c0.64,0,1.23,0,1.87,0c0-0.76,0-1.43,0-2.22
	C583.4,214.26,582.74,214.36,581.9,214.48z M553.23,549.23c0.09,0.72,0.17,1.39,0.26,2.12c0.69-0.1,1.23-0.18,1.86-0.27
	c-0.05-0.69-0.09-1.28-0.14-1.85C554.47,549.23,553.92,549.23,553.23,549.23z M615.37,535.17c0-0.71,0-1.39,0-2.16
	c-0.78,0.08-1.45,0.15-2.17,0.22c0,0.74,0,1.43,0,2.2C613.93,535.34,614.62,535.26,615.37,535.17z M507.31,204.94
	c-0.08-0.72-0.14-1.31-0.21-1.99c-0.74,0.08-1.34,0.14-1.95,0.2c0,0.77,0,1.46,0,2.2C505.85,205.22,506.46,205.1,507.31,204.94z
	 M582.62,532.42c-0.73,0.07-1.38,0.13-2.06,0.19c0.1,0.78,0.17,1.38,0.26,2.09c0.72-0.15,1.31-0.27,1.97-0.4
	C582.74,533.63,582.69,533.09,582.62,532.42z M568.76,477.76c0.69-0.09,1.34-0.17,2.06-0.26c-0.06-0.83-0.11-1.51-0.16-2.22
	c-0.8,0.16-1.48,0.3-2.24,0.45C568.54,476.4,568.64,477.02,568.76,477.76z M495.47,48.64c0,0.71,0,1.41,0,2.22
	c0.73-0.08,1.41-0.15,2.13-0.23c-0.11-0.9-0.21-1.65-0.3-2.41C496.61,48.38,496.07,48.5,495.47,48.64z M590,347.65
	c0.07,0.75,0.11,1.28,0.17,1.91c0.84-0.07,1.5-0.13,2.25-0.19c-0.17-0.78-0.29-1.37-0.43-2.01
	C591.29,347.46,590.75,347.54,590,347.65z M561.65,478.47c0.79-0.07,1.37-0.13,1.98-0.19c-0.04-0.76-0.07-1.37-0.11-2.12
	c-0.76,0.11-1.34,0.19-1.99,0.28C561.58,477.11,561.61,477.66,561.65,478.47z M553.16,591.32c0.86-0.14,1.44-0.24,2.14-0.35
	c-0.16-0.71-0.29-1.29-0.44-1.96c-0.63,0.06-1.14,0.11-1.7,0.16C553.16,589.9,553.16,590.49,553.16,591.32z M627.12,584.82
	c-0.15-0.77-0.29-1.43-0.42-2.1c-0.69,0.05-1.23,0.09-1.87,0.14c0.05,0.8,0.09,1.46,0.14,2.21
	C625.8,584.97,626.41,584.9,627.12,584.82z M570.64,435.89c-0.76,0.06-1.29,0.11-1.94,0.16c0.08,0.81,0.14,1.47,0.22,2.22
	c0.77-0.15,1.35-0.26,1.97-0.37C570.8,437.2,570.73,436.65,570.64,435.89z M624.57,595.88c-0.27-0.86-0.47-1.48-0.69-2.15
	c-0.68,0.15-1.17,0.27-1.7,0.39c0.03,0.66,0.06,1.19,0.09,1.77C623.03,595.88,623.63,595.88,624.57,595.88z M618.42,551.06
	c0.1,0.64,0.18,1.22,0.25,1.71c0.83,0,1.43,0,2.15,0c-0.07-0.78-0.14-1.45-0.21-2.2C619.81,550.75,619.14,550.9,618.42,551.06z
	 M574.23,267.79c-0.16-0.76-0.27-1.28-0.39-1.87c-0.7,0.04-1.31,0.07-2.02,0.11c0.07,0.8,0.13,1.45,0.2,2.14
	C572.79,268.04,573.4,267.93,574.23,267.79z M451.06,40.27c0.75-0.11,1.42-0.21,2.19-0.32c-0.04-0.7-0.07-1.3-0.11-2.03
	c-0.78,0.06-1.45,0.1-2.08,0.15C451.06,38.85,451.06,39.52,451.06,40.27z M573.58,551.21c-0.72,0-1.44,0-2.25,0
	c0.16,0.7,0.31,1.35,0.47,2.07c0.7-0.07,1.21-0.12,1.78-0.17C573.58,552.44,573.58,551.91,573.58,551.21z M541.54,266.82
	c-0.1-0.67-0.17-1.18-0.23-1.63c-0.77,0-1.37,0-2.1,0c0.06,0.7,0.11,1.3,0.16,2C540.07,267.07,540.67,266.97,541.54,266.82z
	 M551.61,529.87c0.65-0.13,1.17-0.24,1.81-0.37c-0.08-0.68-0.15-1.27-0.22-1.91c-0.75,0.07-1.35,0.13-2.05,0.19
	C551.32,528.55,551.45,529.14,551.61,529.87z M493.03,480.8c0.73-0.04,1.41-0.07,2.17-0.11c-0.14-0.78-0.25-1.45-0.37-2.13
	c-0.68,0.06-1.21,0.11-1.8,0.16C493.03,479.44,493.03,480.05,493.03,480.8z M458.5,39.44c0.76-0.07,1.28-0.12,1.78-0.17
	c0-0.74,0-1.34,0-2.09c-0.79,0.05-1.46,0.09-2.22,0.13C458.21,38.04,458.34,38.65,458.5,39.44z M598.99,552.51
	c-0.87,0.04-1.49,0.07-2.24,0.11c0.16,0.75,0.3,1.4,0.46,2.13c0.65-0.04,1.17-0.07,1.78-0.11
	C598.99,553.9,598.99,553.31,598.99,552.51z M598.63,529.34c0.75-0.07,1.28-0.12,1.78-0.17c0-0.74,0-1.34,0-2.1
	c-0.79,0.06-1.46,0.1-2.22,0.16C598.35,527.95,598.47,528.56,598.63,529.34z M603.34,460.29c0.8-0.05,1.53-0.09,2.28-0.13
	c-0.14-0.79-0.25-1.41-0.36-2.05c-0.73,0.07-1.41,0.14-2.22,0.21C603.13,458.99,603.23,459.58,603.34,460.29z M591.14,379.65
	c-0.85,0.16-1.41,0.26-2.04,0.38c0.17,0.76,0.31,1.34,0.46,2.01c0.62-0.07,1.13-0.13,1.72-0.19
	C591.23,381.1,591.19,380.5,591.14,379.65z M379.95,51.55c0.08,0.82,0.15,1.42,0.22,2.1c0.8-0.14,1.46-0.25,2.14-0.37
	c-0.09-0.8-0.16-1.41-0.23-2.05C381.33,51.33,380.71,51.43,379.95,51.55z M575.55,556.78c0.77-0.18,1.34-0.3,1.99-0.45
	c-0.1-0.73-0.18-1.31-0.27-1.98c-0.74,0.13-1.32,0.24-1.9,0.34C575.43,555.39,575.47,555.93,575.55,556.78z M617.16,554.63
	c-0.72,0.1-1.38,0.19-2.05,0.28c0.09,0.75,0.16,1.36,0.23,1.93c0.78,0,1.46,0,2.24,0C617.44,556.09,617.32,555.42,617.16,554.63z
	 M598.84,574.29c0.1,0.81,0.17,1.34,0.25,1.99c0.64-0.09,1.22-0.17,1.88-0.27c-0.07-0.74-0.12-1.33-0.18-1.99
	C600.12,574.12,599.59,574.19,598.84,574.29z M497.06,169.85c-0.11-0.91-0.19-1.59-0.27-2.28c-0.7,0.06-1.24,0.1-1.73,0.15
	c0,0.83,0,1.51,0,2.27C495.75,169.94,496.29,169.9,497.06,169.85z M582.81,223.75c0.79-0.16,1.32-0.27,1.93-0.4
	c-0.06-0.69-0.11-1.29-0.17-1.96c-0.82,0.07-1.48,0.13-2.24,0.19C582.5,222.35,582.63,222.95,582.81,223.75z M575.64,142.95
	c0.11,0.66,0.2,1.23,0.3,1.86c0.75-0.11,1.34-0.2,2.06-0.31c-0.1-0.68-0.19-1.24-0.28-1.86
	C576.96,142.75,576.36,142.85,575.64,142.95z M382.15,13.53c0-0.66,0-1.34,0-2.1c-0.78,0.08-1.45,0.15-2.15,0.22
	c0.04,0.82,0.07,1.54,0.1,2.32C380.86,13.81,381.46,13.68,382.15,13.53z M575.3,222.3c0,0.77,0,1.45,0,2.24
	c0.74-0.15,1.42-0.28,2.21-0.44c-0.05-0.68-0.1-1.29-0.15-1.93C576.62,222.21,575.99,222.25,575.3,222.3z M564.82,200.14
	c-0.34-2.45-0.34-2.45-2.46-2.09c0.12,0.74,0.24,1.48,0.36,2.24C563.47,200.24,564.08,200.19,564.82,200.14z M591.14,401.58
	c0,0.79,0,1.39,0,2.08c0.73-0.06,1.33-0.12,2.05-0.18c-0.07-0.75-0.13-1.42-0.2-2.14C592.3,401.43,591.76,401.5,591.14,401.58z
	 M346.33,38.44c0.86-0.04,1.48-0.08,2.19-0.11c-0.14-0.76-0.25-1.42-0.39-2.22c-0.7,0.14-1.27,0.26-1.79,0.37
	C346.33,37.16,346.33,37.69,346.33,38.44z M571.18,482.63c-0.85,0.1-1.47,0.17-2.16,0.25c0.05,0.8,0.1,1.53,0.15,2.34
	c0.83-0.24,1.48-0.43,2.02-0.58C571.18,483.9,571.18,483.35,571.18,482.63z M311.39,53.55c0.18,0.82,0.34,1.54,0.51,2.28
	c0.71-0.08,1.25-0.14,1.82-0.2c-0.08-0.81-0.14-1.49-0.21-2.23C312.72,53.45,312.1,53.5,311.39,53.55z M635.53,556.49
	c-0.84,0.11-1.52,0.2-2.23,0.3c0.07,0.79,0.12,1.46,0.18,2.16c0.75-0.1,1.37-0.19,2.04-0.28C635.53,558,635.53,557.38,635.53,556.49
	z M552.62,103.68c-0.05-0.86-0.1-1.52-0.15-2.3c-0.82,0.08-1.46,0.15-2.12,0.22c0.12,0.84,0.22,1.51,0.32,2.24
	C551.31,103.79,551.84,103.74,552.62,103.68z M578.98,198.64c-0.12-0.75-0.21-1.27-0.3-1.85c-0.73,0.06-1.34,0.11-2.03,0.17
	c0.05,0.81,0.1,1.47,0.14,2.18C577.56,198.97,578.17,198.83,578.98,198.64z M562.17,483.54c0,0.82,0,1.42,0,2.17
	c0.73-0.08,1.34-0.14,2.06-0.22c-0.14-0.77-0.26-1.43-0.38-2.11C563.23,483.44,562.76,483.49,562.17,483.54z M499.3,196.46
	c-0.74,0.12-1.32,0.21-1.98,0.31c0.11,0.75,0.19,1.34,0.26,1.86c0.78,0,1.4,0,2.13,0C499.58,197.94,499.47,197.34,499.3,196.46z
	 M304.43,54.42c0.06,0.77,0.11,1.39,0.16,2.06c0.77-0.11,1.45-0.2,2.13-0.29c-0.04-0.82-0.07-1.49-0.1-2.23
	C305.88,54.12,305.27,54.25,304.43,54.42z M571.3,524.59c-0.04-0.76-0.08-1.49-0.12-2.3c-0.83,0.19-1.49,0.35-2.16,0.5
	c0.06,0.77,0.11,1.42,0.16,2.1C569.95,524.78,570.56,524.69,571.3,524.59z M425.83,14.81c-0.9,0.09-1.58,0.16-2.33,0.24
	c0.13,0.83,0.25,1.54,0.37,2.31c0.76-0.18,1.35-0.32,2.05-0.49C425.9,16.22,425.87,15.62,425.83,14.81z M576.39,524.09
	c0.73-0.09,1.26-0.16,1.83-0.24c-0.06-0.73-0.11-1.35-0.18-2.11c-0.66,0.05-1.28,0.1-1.95,0.15
	C576.18,522.61,576.27,523.24,576.39,524.09z M504.54,195.99c0.04,0.78,0.07,1.32,0.1,1.98c0.78-0.07,1.45-0.12,2.11-0.18
	c0-0.8,0-1.48,0-2.25C506.01,195.69,505.35,195.82,504.54,195.99z M559.24,454.71c0.27,0.9,0.46,1.53,0.66,2.2
	c0.69-0.11,1.21-0.2,1.79-0.29c-0.07-0.73-0.13-1.33-0.19-1.91C560.75,454.71,560.14,454.71,559.24,454.71z M583.25,521.46
	c0.07,0.84,0.12,1.45,0.18,2.13c0.73-0.09,1.34-0.17,2.09-0.27c-0.15-0.71-0.29-1.35-0.43-2
	C584.42,521.38,583.97,521.41,583.25,521.46z M565.14,521.61c0.85-0.2,1.5-0.36,2.15-0.51c-0.06-0.75-0.11-1.35-0.16-2.02
	c-0.77,0.11-1.43,0.2-2.16,0.3C565.02,520.14,565.07,520.75,565.14,521.61z M616.9,286c0.78-0.16,1.47-0.3,2.2-0.46
	c-0.04-0.67-0.08-1.28-0.13-2.01c-0.74,0.08-1.42,0.15-2.22,0.24C616.81,284.57,616.85,285.25,616.9,286z M374.57,33.72
	c0.11,0.87,0.19,1.55,0.29,2.36c0.74-0.17,1.4-0.32,2.11-0.48c-0.07-0.76-0.13-1.36-0.19-1.99
	C376.03,33.64,375.41,33.68,374.57,33.72z M494.28,453.22c0.11,0.89,0.2,1.57,0.29,2.29c0.77-0.07,1.39-0.12,2.05-0.18
	c-0.14-0.76-0.26-1.43-0.38-2.11C495.6,453.22,495.07,453.22,494.28,453.22z M337.1,33.51c-0.65,0.12-1.29,0.24-1.96,0.36
	c0.07,0.78,0.12,1.36,0.18,1.98c0.83-0.09,1.5-0.16,2.21-0.23C337.37,34.87,337.25,34.27,337.1,33.51z M426.29,85.48
	c0.71-0.04,1.38-0.07,2.1-0.11c-0.09-0.76-0.17-1.37-0.24-2.01c-0.79,0.1-1.46,0.19-2.2,0.28
	C426.08,84.31,426.17,84.84,426.29,85.48z M471.08,143.15c0.71-0.09,1.22-0.15,1.84-0.23c-0.08-0.79-0.14-1.44-0.21-2.15
	c-0.76,0.08-1.36,0.15-1.98,0.21C470.84,141.7,470.94,142.31,471.08,143.15z M601.64,565.25c0.76-0.04,1.41-0.08,2.15-0.13
	c-0.17-0.79-0.31-1.43-0.47-2.16c-0.71,0.18-1.21,0.31-1.67,0.43C601.64,564.04,601.64,564.57,601.64,565.25z M594.44,565.59
	c0.65,0,1.29,0,2,0c-0.08-0.76-0.15-1.34-0.22-2.02c-0.76,0.07-1.34,0.13-1.99,0.19C594.3,564.43,594.36,564.97,594.44,565.59z
	 M554.55,357.77c0.09,0.69,0.16,1.29,0.25,1.98c0.77-0.11,1.42-0.2,2.16-0.31c-0.12-0.72-0.21-1.23-0.28-1.68
	C555.91,357.77,555.29,357.77,554.55,357.77z M472,31.02c0.83-0.1,1.5-0.18,2.19-0.26c-0.05-0.79-0.09-1.44-0.14-2.17
	c-0.8,0.15-1.46,0.28-2.15,0.41C471.94,29.67,471.96,30.2,472,31.02z M593.29,443.33c-0.2-0.88-0.34-1.49-0.5-2.22
	c-0.65,0.11-1.23,0.21-1.9,0.32c0.08,0.75,0.14,1.35,0.2,1.9C591.86,443.33,592.48,443.33,593.29,443.33z M539.86,312.06
	c0,0.79,0,1.47,0,2.25c0.77-0.18,1.44-0.33,2.17-0.5c-0.08-0.71-0.15-1.3-0.22-1.91C541.05,311.96,540.43,312.01,539.86,312.06z
	 M579.17,325.24c-0.1-0.87-0.17-1.46-0.25-2.11c-0.78,0.07-1.38,0.12-2,0.17c0.05,0.75,0.08,1.36,0.13,2.11
	C577.78,325.35,578.38,325.31,579.17,325.24z M576.55,296.51c-0.07-0.86-0.12-1.47-0.18-2.21c-0.78,0.07-1.43,0.12-2.14,0.19
	c0.22,0.84,0.39,1.5,0.57,2.19C575.39,296.63,575.84,296.58,576.55,296.51z M483.17,160.2c0.86-0.12,1.49-0.21,2.15-0.31
	c0-0.75,0-1.45,0-2.21c-0.78,0.15-1.46,0.28-2.15,0.42C483.17,158.79,483.17,159.43,483.17,160.2z M503.48,452.62
	c-0.77,0-1.39,0-2.08,0c0.04,0.77,0.07,1.45,0.1,2.24c0.76-0.18,1.42-0.33,2.08-0.49C503.54,453.66,503.51,453.12,503.48,452.62z
	 M595.47,179.43c-0.17-0.82-0.32-1.53-0.47-2.26c-0.73,0.08-1.25,0.14-1.81,0.21c0.07,0.81,0.13,1.48,0.2,2.23
	C594.16,179.54,594.77,179.49,595.47,179.43z M582.9,516.35c0.79-0.04,1.47-0.07,2.26-0.11c-0.17-0.77-0.32-1.43-0.48-2.14
	c-0.71,0.06-1.24,0.11-1.77,0.16C582.9,514.99,582.9,515.59,582.9,516.35z M574.84,567.55c-0.09-0.87-0.15-1.45-0.21-2.12
	c-0.78,0.08-1.36,0.15-2.02,0.22c0.1,0.82,0.18,1.48,0.27,2.17C573.58,567.72,574.11,567.65,574.84,567.55z M498.16,514.19
	c-0.84,0.19-1.49,0.34-2.16,0.49c0.06,0.77,0.11,1.38,0.16,2.02c0.73-0.13,1.34-0.23,2-0.35
	C498.16,515.68,498.16,515.07,498.16,514.19z M376.42,26.09c-0.82,0.12-1.55,0.23-2.41,0.36c0.19,0.74,0.36,1.39,0.54,2.09
	c0.69-0.06,1.28-0.12,1.87-0.17C376.42,27.55,376.42,26.87,376.42,26.09z M582.63,155.13c-0.08-0.84-0.14-1.51-0.2-2.24
	c-0.78,0.18-1.44,0.33-2.12,0.49c0.05,0.74,0.09,1.34,0.13,1.98C581.21,155.28,581.83,155.21,582.63,155.13z M391.34,16.49
	c0.79-0.06,1.32-0.1,2.01-0.16c-0.09-0.85-0.16-1.58-0.24-2.31c-0.72,0.05-1.25,0.08-1.9,0.13
	C391.25,14.94,391.29,15.62,391.34,16.49z M584.13,422.46c-0.09-0.72-0.17-1.37-0.26-2.1c-0.75,0.04-1.35,0.08-2.07,0.12
	c0.05,0.76,0.09,1.36,0.14,1.98C582.69,422.46,583.31,422.46,584.13,422.46z M578.38,189.42c-0.78,0.13-1.44,0.25-2.21,0.38
	c0.06,0.66,0.12,1.25,0.18,1.91c0.79-0.09,1.45-0.16,2.15-0.24C578.46,190.69,578.42,190.09,578.38,189.42z M481.11,136.49
	c0.15,0.77,0.27,1.42,0.39,2.02c0.83,0,1.53,0,2.28,0c-0.13-0.68-0.25-1.35-0.4-2.13C482.65,136.42,481.97,136.45,481.11,136.49z
	 M647.17,608.34c-0.08-0.78-0.14-1.38-0.21-2.11c-0.73,0.06-1.32,0.12-1.99,0.17c0.08,0.71,0.15,1.3,0.22,1.94
	C645.84,608.34,646.37,608.34,647.17,608.34z M567.02,600.85c-0.17-0.78-0.28-1.29-0.41-1.89c-0.68,0.05-1.27,0.09-1.95,0.14
	c0.07,0.79,0.13,1.43,0.19,2.12C565.61,601.09,566.21,600.99,567.02,600.85z M600.39,613.78c-0.68,0.34-1.38,0.48-1.7,0.92
	c-0.23,0.31,0.06,1,0.12,1.58c0.8-0.08,1.45-0.15,2.2-0.23C600.8,615.26,600.63,614.67,600.39,613.78z M470.39,27.46
	c-0.08-0.66-0.16-1.24-0.24-1.9c-0.79,0.06-1.44,0.1-2.18,0.15c0.11,0.72,0.21,1.3,0.3,1.91C469.06,27.57,469.67,27.52,470.39,27.46
	z M499.67,449.4c-0.88,0-1.49,0-2.22,0c0.11,0.68,0.21,1.26,0.31,1.91c0.69-0.06,1.3-0.11,1.99-0.17
	C499.72,450.54,499.7,450.03,499.67,449.4z M609.72,616.71c0,0.9,0,1.58,0,2.36c0.84-0.14,1.57-0.27,2.36-0.4
	c-0.19-0.75-0.34-1.35-0.49-1.96C610.95,616.71,610.41,616.71,609.72,616.71z M398.48,31.73c-0.83,0.04-1.52,0.08-2.34,0.12
	c0.12,0.75,0.22,1.35,0.33,2.02c0.8-0.05,1.48-0.1,2.23-0.14C398.63,33.09,398.57,32.54,398.48,31.73z M523.36,413.17
	c0.82-0.05,1.43-0.09,2.12-0.14c-0.12-0.73-0.23-1.38-0.35-2.14c-0.7,0.15-1.21,0.26-1.77,0.38
	C523.36,411.87,523.36,412.39,523.36,413.17z M279.89,80.32c-0.1-0.74-0.2-1.4-0.3-2.12c-0.73,0.07-1.33,0.13-2.01,0.2
	c0.04,0.77,0.07,1.39,0.1,2.08C278.42,80.43,279.11,80.38,279.89,80.32z M569.46,569.44c0.2,0.81,0.34,1.38,0.5,2.03
	c0.65-0.08,1.17-0.14,1.67-0.2c0-0.75,0-1.35,0-2.07C570.92,569.28,570.32,569.35,569.46,569.44z M593.34,448.41
	c-0.65,0.07-1.24,0.14-1.91,0.21c0.06,0.76,0.11,1.37,0.17,2.05c0.74-0.08,1.36-0.15,2.08-0.24
	C593.57,449.75,593.46,449.13,593.34,448.41z M651.82,618.73c-0.1-0.77-0.19-1.43-0.28-2.12c-0.78,0.06-1.39,0.11-2.15,0.17
	c0.08,0.72,0.15,1.33,0.21,1.95C650.33,618.73,651.02,618.73,651.82,618.73z M600.6,511.16c0.76-0.16,1.28-0.27,1.87-0.39
	c-0.05-0.68-0.1-1.28-0.15-1.95c-0.8,0.08-1.45,0.14-2.17,0.2C600.31,509.77,600.44,510.37,600.6,511.16z M287.15,79.67
	c-0.1-0.79-0.18-1.44-0.27-2.21c-0.81,0.23-1.46,0.41-2.17,0.61c0.13,0.67,0.24,1.18,0.35,1.73
	C285.81,79.75,286.43,79.71,287.15,79.67z M563.11,510.95c-0.12-0.77-0.23-1.44-0.36-2.24c-0.75,0.22-1.33,0.39-1.95,0.57
	c0.07,0.65,0.13,1.17,0.19,1.67C561.77,510.95,562.38,510.95,563.11,510.95z M554.52,291.15c-0.11-0.87-0.19-1.46-0.27-2.07
	c-0.76,0.04-1.38,0.07-2.09,0.11c0.14,0.76,0.26,1.42,0.4,2.19C553.22,291.31,553.74,291.25,554.52,291.15z M363.48,31
	c0.22,0.81,0.41,1.5,0.6,2.21c0.7-0.08,1.22-0.14,1.86-0.21c-0.1-0.79-0.18-1.44-0.25-2C364.89,31,364.27,31,363.48,31z
	 M540.52,319.28c0.05,0.71,0.1,1.37,0.15,2.07c0.82-0.09,1.48-0.16,2.21-0.24c-0.1-0.76-0.18-1.41-0.26-2.08
	C541.87,319.12,541.27,319.19,540.52,319.28z M543.66,246.69c-0.85,0.12-1.45,0.21-2.14,0.31c0.03,0.65,0.06,1.16,0.1,1.81
	c0.79-0.05,1.45-0.09,2.2-0.14C543.76,247.98,543.72,247.46,543.66,246.69z M571.61,236.92c-0.76,0.11-1.43,0.21-2.24,0.34
	c0.13,0.68,0.25,1.26,0.38,1.93c0.69-0.06,1.29-0.11,1.87-0.16C571.61,238.24,571.61,237.63,571.61,236.92z M602.09,572.55
	c0.76-0.16,1.4-0.29,2.13-0.45c-0.16-0.69-0.28-1.2-0.42-1.79c-0.61,0.05-1.14,0.09-1.71,0.14
	C602.09,571.19,602.09,571.78,602.09,572.55z M599.35,180.22c-0.89,0.16-1.58,0.28-2.37,0.42c0.11,0.73,0.21,1.4,0.32,2.1
	c0.78-0.12,1.45-0.22,2.05-0.31C599.35,181.65,599.35,181.02,599.35,180.22z M568.3,449c-0.15-0.7-0.28-1.3-0.44-2.02
	c-0.66,0.1-1.24,0.19-1.91,0.3c0.1,0.76,0.17,1.35,0.25,1.99C566.97,449.17,567.59,449.09,568.3,449z M573.68,369.14
	c0.78-0.09,1.42-0.16,2.13-0.24c-0.19-0.81-0.34-1.47-0.51-2.17c-0.67,0.11-1.18,0.2-1.72,0.29
	C573.62,367.76,573.65,368.37,573.68,369.14z M572.63,417.64c-0.74,0.17-1.23,0.28-1.77,0.4c0.06,0.66,0.11,1.19,0.16,1.71
	c0.75,0,1.35,0,2.06,0C572.92,419.06,572.8,418.46,572.63,417.64z M627.48,573.87c0.81-0.16,1.47-0.29,2.19-0.44
	c-0.08-0.59-0.15-1.12-0.21-1.63c-0.75,0-1.41,0-2.14,0C627.38,572.55,627.42,573.16,627.48,573.87z M566.22,243.2
	c0.89-0.14,1.49-0.24,2.19-0.35c-0.08-0.67-0.14-1.19-0.21-1.81c-0.82,0.06-1.48,0.11-2.19,0.16
	C566.08,241.92,566.14,242.47,566.22,243.2z M592.36,418.09c0.82-0.11,1.41-0.19,2.06-0.27c-0.1-0.81-0.18-1.48-0.26-2.16
	c-0.69,0.05-1.23,0.1-1.8,0.14C592.36,416.56,592.36,417.17,592.36,418.09z M458.12,116.88c0,0.84,0,1.49,0,2.23
	c0.77-0.07,1.38-0.13,2.03-0.18c-0.14-0.77-0.26-1.44-0.38-2.15C459.19,116.81,458.72,116.84,458.12,116.88z M488.6,156.01
	c-0.06-0.83-0.11-1.42-0.16-2.1c-0.79,0.12-1.45,0.22-2.16,0.33c0.07,0.85,0.13,1.51,0.18,2.21
	C487.26,156.28,487.87,156.16,488.6,156.01z M496.71,500.21c-0.81,0-1.47,0-2.22,0c0.13,0.8,0.23,1.44,0.34,2.18
	c0.78-0.19,1.36-0.33,2-0.49C496.79,501.26,496.75,500.74,496.71,500.21z M588.64,140.19c-0.09-0.83-0.15-1.46-0.23-2.2
	c-0.83,0.16-1.57,0.3-2.32,0.45c0.06,0.79,0.11,1.42,0.16,2.07C587.04,140.41,587.73,140.31,588.64,140.19z M549.67,548.06
	c0.77-0.08,1.29-0.14,1.93-0.2c-0.07-0.8-0.13-1.45-0.2-2.16c-0.79,0.11-1.39,0.2-2.1,0.3
	C549.42,546.68,549.53,547.28,549.67,548.06z M630.17,620.63c-0.15-0.78-0.27-1.37-0.4-2.05c-0.67,0.07-1.27,0.13-1.8,0.18
	c0,0.82,0,1.48,0,2.27C628.69,620.9,629.3,620.79,630.17,620.63z M593.5,303.92c0,0.74,0,1.27,0,1.84c0.76,0,1.42,0,2.24,0
	c-0.16-0.71-0.28-1.28-0.41-1.84C594.67,303.92,594.14,303.92,593.5,303.92z M579.77,148.09c0.72-0.09,1.37-0.18,2.05-0.26
	c-0.04-0.72-0.06-1.25-0.09-1.9c-0.78,0.05-1.45,0.08-2.17,0.13C579.63,146.76,579.69,147.36,579.77,148.09z M273.28,88.02
	c-0.08-0.75-0.15-1.29-0.22-1.95c-0.71,0.04-1.31,0.08-2.06,0.12c0.11,0.76,0.2,1.41,0.29,2.1C272,88.2,272.54,88.12,273.28,88.02z
	 M610.73,544.27c0,0.69,0,1.36,0,2.1c0.8-0.1,1.46-0.19,2.16-0.28c-0.06-0.77-0.12-1.37-0.17-2.03
	C612.03,544.14,611.4,544.2,610.73,544.27z M582.2,485.3c-0.69,0.13-1.27,0.23-1.91,0.35c0.09,0.7,0.15,1.24,0.23,1.9
	c0.59-0.04,1.12-0.08,1.67-0.12C582.2,486.66,582.2,486.04,582.2,485.3z M586.12,156.49c-0.68,0.04-1.28,0.08-1.86,0.12
	c0,0.74,0,1.35,0,2.05c0.82-0.05,1.55-0.1,2.38-0.15C586.45,157.76,586.29,157.16,586.12,156.49z M500.72,445.5
	c0.16,0.8,0.27,1.38,0.4,2.04c0.73-0.11,1.31-0.2,1.97-0.29c-0.06-0.78-0.12-1.43-0.17-2.08
	C502.15,445.28,501.53,445.38,500.72,445.5z M632.27,562.41c-0.04-0.68-0.07-1.29-0.11-2.04c-0.85,0.1-1.56,0.19-2.33,0.29
	c0.15,0.84,0.27,1.51,0.39,2.21C630.96,562.71,631.56,562.57,632.27,562.41z M415.25,41.05c0.05,0.87,0.09,1.48,0.14,2.23
	c0.68-0.1,1.25-0.19,1.88-0.29c-0.05-0.76-0.09-1.37-0.13-2.04C416.51,40.98,415.98,41.01,415.25,41.05z M619.15,618.06
	c-0.17-0.89-0.3-1.54-0.43-2.2c-0.72,0.11-1.25,0.19-1.77,0.27c0,0.66,0,1.27,0,1.93C617.67,618.06,618.29,618.06,619.15,618.06z
	 M569.73,341.91c-0.72,0.08-1.39,0.16-2.16,0.25c0.16,0.76,0.3,1.42,0.45,2.12c0.77-0.11,1.36-0.19,1.98-0.28
	C569.9,343.24,569.82,342.61,569.73,341.91z M583.47,207.33c-0.79,0.05-1.38,0.09-2.11,0.14c0.05,0.61,0.09,1.12,0.14,1.73
	c0.8-0.04,1.45-0.08,2.18-0.11C583.6,208.48,583.55,208.03,583.47,207.33z M593.48,487.89c-0.78,0.11-1.49,0.21-2.25,0.32
	c0.13,0.75,0.24,1.35,0.35,2c0.69-0.07,1.29-0.13,1.9-0.2C593.48,489.3,593.48,488.7,593.48,487.89z M534.42,581.86
	c0.69-0.07,1.38-0.15,2.09-0.22c-0.08-0.83-0.14-1.49-0.21-2.18c-0.82,0.18-1.49,0.32-2.14,0.46
	C534.24,580.58,534.31,581.12,534.42,581.86z M594.63,444.44c0.12,0.83,0.21,1.42,0.31,2.05c0.77-0.05,1.38-0.08,2.08-0.12
	c-0.08-0.69-0.15-1.29-0.22-1.92C596.1,444.44,595.49,444.44,594.63,444.44z M558.37,294.61c-0.05-0.85-0.09-1.46-0.13-2.21
	c-0.64,0.09-1.16,0.16-1.75,0.24c0,0.71,0,1.32,0,1.97C557.08,294.61,557.61,294.61,558.37,294.61z M549.08,311.39
	c-0.88,0.08-1.49,0.13-2.17,0.19c0.1,0.66,0.18,1.2,0.29,1.86c0.74-0.05,1.42-0.09,2.13-0.13
	C549.24,312.64,549.17,312.1,549.08,311.39z M621,579.58c0.08,0.71,0.15,1.3,0.22,1.98c0.65-0.05,1.18-0.09,1.75-0.13
	c0-0.74,0-1.34,0-2.06C622.26,579.45,621.67,579.51,621,579.58z M567.56,257.74c0.66-0.18,1.25-0.34,1.96-0.53
	c-0.09-0.67-0.16-1.27-0.25-1.91c-0.8,0.05-1.46,0.09-2.14,0.14C567.29,256.29,567.42,256.98,567.56,257.74z M461.79,35.57
	c0.61-0.06,1.14-0.12,1.76-0.19c-0.07-0.77-0.12-1.43-0.18-2.15c-0.72,0.1-1.24,0.18-1.79,0.26
	C461.65,34.24,461.72,34.85,461.79,35.57z M635.91,587.68c0.72-0.12,1.39-0.22,2.19-0.35c-0.1-0.65-0.18-1.23-0.28-1.92
	c-0.77,0.08-1.42,0.15-2.07,0.22C635.81,586.38,635.86,586.98,635.91,587.68z M490.24,157.62c0.13,0.72,0.22,1.24,0.32,1.8
	c0.86-0.06,1.54-0.11,2.24-0.17c-0.15-0.79-0.27-1.45-0.39-2.11C491.64,157.31,491.03,157.45,490.24,157.62z M586.15,448.87
	c-0.69,0.1-1.27,0.19-1.92,0.28c0.07,0.75,0.12,1.34,0.18,2.01c0.66-0.11,1.19-0.19,1.83-0.3
	C586.21,450.18,586.18,449.58,586.15,448.87z M403.11,44.24c-0.04-0.79-0.07-1.46-0.11-2.24c-0.77,0.18-1.43,0.34-2.14,0.51
	c0.09,0.72,0.16,1.31,0.23,1.91C401.79,44.36,402.33,44.31,403.11,44.24z M590.62,356.64c0.84-0.1,1.45-0.17,2.16-0.25
	c-0.1-0.64-0.19-1.17-0.28-1.76c-0.73,0.06-1.33,0.12-2.01,0.18C590.54,355.44,590.57,355.91,590.62,356.64z M623.04,561.21
	c0.05,0.83,0.09,1.49,0.13,2.26c0.7-0.08,1.22-0.15,1.85-0.22c-0.05-0.79-0.1-1.45-0.15-2.16
	C624.26,561.12,623.8,561.15,623.04,561.21z M571.86,489.87c-0.68,0.06-1.34,0.12-2.09,0.19c0.05,0.82,0.08,1.48,0.12,2.19
	c0.86-0.11,1.52-0.2,2.19-0.29C572,491.22,571.93,490.6,571.86,489.87z M584.57,136.88c-0.09-0.8-0.16-1.4-0.24-2.08
	c-0.72,0.07-1.32,0.13-2.06,0.2c0.05,0.71,0.09,1.32,0.13,2C583.12,136.97,583.73,136.93,584.57,136.88z M559.25,388.19
	c-0.06-0.79-0.1-1.39-0.15-2.02c-0.79,0.11-1.44,0.19-2.15,0.29c0.12,0.76,0.2,1.34,0.3,1.95
	C557.94,388.34,558.48,388.28,559.25,388.19z M578.17,563.71c-0.08-0.77-0.15-1.37-0.22-2.06c-0.65,0.05-1.19,0.08-1.78,0.12
	c0,0.73,0,1.33,0,2.05C576.81,563.79,577.34,563.76,578.17,563.71z M579.41,557.96c0.08,0.83,0.14,1.42,0.21,2.07
	c0.66-0.1,1.18-0.17,1.77-0.26c-0.07-0.72-0.13-1.31-0.2-2C580.57,557.84,580.12,557.89,579.41,557.96z M581.33,539.73
	c0.04,0.82,0.07,1.4,0.1,2.06c0.86-0.04,1.51-0.08,2.25-0.12c-0.19-0.78-0.33-1.37-0.49-2.05
	C582.62,539.66,582.1,539.69,581.33,539.73z M642.28,617.55c0.09,0.79,0.15,1.38,0.2,1.84c0.79,0,1.39,0,2.04,0
	c-0.11-0.75-0.19-1.37-0.29-2.07C643.62,617.4,643.08,617.46,642.28,617.55z M573.71,493.42c0,0.86,0,1.47,0,2.14
	c0.84-0.04,1.57-0.07,2.43-0.11c-0.23-0.79-0.42-1.44-0.59-2.02C574.87,493.42,574.33,493.42,573.71,493.42z M543.43,288.47
	c-0.03-0.67-0.06-1.27-0.1-1.96c-0.79,0.06-1.45,0.1-2.16,0.15c0.09,0.72,0.16,1.31,0.24,2
	C542.1,288.59,542.69,288.54,543.43,288.47z M581.78,296.2c0.8-0.18,1.36-0.32,1.97-0.46c-0.08-0.69-0.14-1.2-0.2-1.82
	c-0.72,0.09-1.3,0.16-1.9,0.23C581.68,294.85,581.72,295.38,581.78,296.2z M608.69,562.62c0.06,0.75,0.1,1.28,0.14,1.76
	c0.75,0,1.35,0,2.05,0c-0.05-0.75-0.09-1.4-0.13-2.12C610.05,562.38,609.46,562.49,608.69,562.62z M599.21,496.87
	c0.89-0.07,1.55-0.13,2.3-0.19c-0.17-0.75-0.29-1.27-0.43-1.87c-0.66,0.04-1.27,0.07-1.87,0.1
	C599.21,495.55,599.21,496.08,599.21,496.87z M577.81,601.51c-0.75,0.11-1.33,0.2-1.97,0.29c0.08,0.74,0.14,1.33,0.21,2
	c0.75-0.11,1.34-0.19,1.99-0.29C577.96,602.86,577.9,602.32,577.81,601.51z M534.49,393.97c0-0.75,0-1.42,0-2.14
	c-0.76,0.06-1.45,0.11-2.24,0.16c0.08,0.67,0.15,1.34,0.24,2.09C533.25,394.05,533.85,394.01,534.49,393.97z M563.3,539.53
	c0.8-0.05,1.4-0.09,2.08-0.14c-0.06-0.74-0.12-1.34-0.18-2.04c-0.78,0.12-1.43,0.22-2.13,0.33
	C563.15,538.33,563.21,538.79,563.3,539.53z M450.39,33.13c0.85-0.22,1.51-0.39,2.16-0.55c-0.06-0.74-0.12-1.34-0.17-2
	c-0.74,0.11-1.35,0.19-1.98,0.28C450.39,31.59,450.39,32.22,450.39,33.13z M572.33,478.98c0.08,0.83,0.14,1.44,0.21,2.16
	c0.74-0.11,1.4-0.22,2.06-0.32c-0.03-0.73-0.06-1.27-0.09-1.84C573.78,478.98,573.16,478.98,572.33,478.98z M595.49,578.1
	c0.12,0.87,0.2,1.44,0.28,2.08c0.73-0.11,1.25-0.18,1.85-0.27c-0.07-0.71-0.13-1.3-0.19-1.93
	C596.78,578.02,596.24,578.05,595.49,578.1z M567.09,250.35c0.68-0.12,1.32-0.24,1.94-0.36c-0.05-0.75-0.09-1.36-0.14-2.07
	c-0.8,0.14-1.52,0.26-2.24,0.38C566.81,249.05,566.94,249.65,567.09,250.35z M459.09,22.86c-0.73,0.13-1.4,0.25-2.18,0.38
	c0.08,0.64,0.15,1.22,0.23,1.84c0.86-0.05,1.52-0.09,2.24-0.13C459.27,24.14,459.19,23.54,459.09,22.86z M580.96,452.98
	c0.21,0.79,0.35,1.36,0.51,1.96c0.68-0.11,1.19-0.19,1.63-0.26c0-0.74,0-1.33,0-2.07C582.39,452.74,581.79,452.84,580.96,452.98z
	 M491.19,45.25c0.15,0.81,0.29,1.53,0.43,2.31c0.76-0.2,1.35-0.36,1.99-0.53c-0.05-0.71-0.09-1.32-0.14-1.99
	C492.67,45.11,491.98,45.17,491.19,45.25z M511.25,419.23c-0.73,0.07-1.32,0.12-1.97,0.18c0.1,0.86,0.19,1.59,0.28,2.33
	c0.76-0.17,1.36-0.3,2.02-0.45C511.47,420.65,511.38,420.05,511.25,419.23z M577.59,538.44c0.72-0.05,1.33-0.1,2.03-0.15
	c-0.06-0.77-0.12-1.42-0.18-2.19c-0.75,0.13-1.28,0.23-1.84,0.33C577.59,537.12,577.59,537.73,577.59,538.44z M581.72,478.16
	c-0.86,0.07-1.46,0.11-2.19,0.17c0.15,0.7,0.28,1.31,0.44,2.02c0.63-0.08,1.21-0.15,1.85-0.23
	C581.78,479.41,581.75,478.86,581.72,478.16z M435.9,89.84c-0.71,0.13-1.28,0.24-1.9,0.36c0.07,0.76,0.12,1.37,0.17,2.01
	c0.64-0.04,1.17-0.07,1.84-0.11C435.97,91.31,435.94,90.65,435.9,89.84z M373.16,14.56c0.85-0.09,1.45-0.15,2.08-0.21
	c-0.16-0.76-0.29-1.42-0.45-2.18c-0.61,0.09-1.12,0.16-1.63,0.23C373.16,13.14,373.16,13.74,373.16,14.56z M458.26,124.06
	c0.12,0.82,0.2,1.43,0.3,2.12c0.77-0.08,1.48-0.16,2.26-0.25c-0.17-0.74-0.31-1.32-0.43-1.87
	C459.64,124.06,459.02,124.06,458.26,124.06z M591.57,426.69c-0.68,0.06-1.29,0.11-1.96,0.16c0.07,0.75,0.12,1.43,0.2,2.24
	c0.68-0.06,1.29-0.11,1.96-0.17C591.69,428.18,591.64,427.5,591.57,426.69z M567.56,439.72c-0.66,0.14-1.3,0.28-1.99,0.43
	c0.05,0.68,0.09,1.2,0.13,1.7c0.75,0,1.34,0,2.01,0C567.66,441.13,567.62,440.52,567.56,439.72z M621.67,586.91
	c0.08,0.78,0.14,1.3,0.19,1.75c0.75,0,1.35,0,2.04,0c-0.1-0.78-0.19-1.44-0.29-2.18C622.93,586.63,622.4,586.75,621.67,586.91z
	 M614.91,612.7c-0.7,0-1.35,0-2.05,0c0.12,0.75,0.21,1.35,0.32,2.05c0.72-0.05,1.34-0.09,1.99-0.13
	C615.08,613.91,614.99,613.29,614.91,612.7z M573.3,532.87c0.23,0.91,0.39,1.56,0.58,2.3c0.64-0.12,1.15-0.22,1.71-0.33
	c-0.05-0.74-0.1-1.35-0.14-1.97C574.71,532.87,574.1,532.87,573.3,532.87z M592.77,589.16c0.09,0.68,0.16,1.28,0.26,2.01
	c0.68-0.06,1.27-0.11,1.94-0.16c-0.07-0.71-0.13-1.32-0.19-1.98C594.07,589.08,593.48,589.12,592.77,589.16z M572.84,441.25
	c0.81-0.1,1.4-0.18,1.99-0.26c-0.07-0.73-0.12-1.34-0.18-2.02c-0.74,0.11-1.39,0.2-2.08,0.31
	C572.67,439.97,572.74,440.5,572.84,441.25z M570.11,246.56c0.73-0.16,1.38-0.3,2.1-0.45c-0.1-0.69-0.17-1.22-0.25-1.8
	c-0.72,0.04-1.32,0.08-1.95,0.12C570.04,245.2,570.07,245.81,570.11,246.56z M537.51,243.61c0.06,0.79,0.12,1.45,0.18,2.22
	c0.73-0.1,1.25-0.18,1.91-0.27c-0.07-0.77-0.13-1.42-0.19-2.11C538.69,243.51,538.15,243.56,537.51,243.61z M500.34,456.18
	c-0.75,0.13-1.43,0.24-2.23,0.38c0.16,0.72,0.3,1.37,0.44,2.03c0.75-0.12,1.35-0.21,1.93-0.3
	C500.43,457.5,500.38,456.88,500.34,456.18z M577.99,417.18c0.06,0.75,0.11,1.34,0.16,1.97c0.72-0.05,1.33-0.09,1.97-0.13
	c0-0.76,0-1.42,0-2.17C579.43,416.95,578.84,417.05,577.99,417.18z M547.74,297.03c-0.72,0.1-1.31,0.19-1.91,0.27
	c0.03,0.71,0.06,1.26,0.08,1.91c0.8-0.04,1.54-0.08,2.35-0.12C548.08,298.38,547.95,297.85,547.74,297.03z M557.73,103.07
	c0.65-0.11,1.29-0.21,2.04-0.34c-0.06-0.71-0.11-1.31-0.16-1.95c-0.81,0.06-1.47,0.11-2.2,0.17
	C557.52,101.68,557.61,102.29,557.73,103.07z M592,244.42c0.69-0.03,1.33-0.06,2.03-0.09c-0.11-0.85-0.2-1.51-0.29-2.17
	c-0.82,0.16-1.48,0.29-2.15,0.42C591.74,243.27,591.86,243.8,592,244.42z M467.92,125.32c-0.05-0.72-0.09-1.38-0.14-2.13
	c-0.8,0.13-1.45,0.23-2.15,0.34c0.09,0.77,0.16,1.36,0.23,1.98C466.61,125.45,467.22,125.39,467.92,125.32z M537.44,578.15
	c0.73-0.15,1.39-0.28,2.15-0.43c-0.06-0.68-0.11-1.27-0.17-1.88c-0.74,0.03-1.34,0.06-1.98,0.09
	C537.44,576.73,537.44,577.4,537.44,578.15z M382.05,33.19c0,0.73,0,1.43,0,2.22c0.8-0.11,1.45-0.2,2.18-0.31
	c-0.12-0.76-0.22-1.34-0.31-1.91C383.28,33.19,382.73,33.19,382.05,33.19z M487.7,168.66c0.04,0.65,0.07,1.26,0.11,2.01
	c0.83-0.11,1.55-0.2,2.33-0.3c-0.14-0.83-0.26-1.49-0.38-2.18C489.02,168.37,488.42,168.5,487.7,168.66z M514.93,417.35
	c-0.11-0.74-0.19-1.26-0.28-1.81c-0.77,0-1.44,0-2.19,0c0.11,0.66,0.21,1.26,0.32,1.95C513.45,417.45,514.06,417.41,514.93,417.35z
	 M497.37,531.04c0.66-0.08,1.31-0.15,1.93-0.22c0-0.79,0-1.47,0-2.24c-0.78,0.17-1.43,0.31-2.05,0.44
	C497.29,529.74,497.32,530.29,497.37,531.04z M594.67,502.22c-0.83,0.18-1.49,0.32-2.16,0.47c0.07,0.69,0.13,1.23,0.19,1.9
	c0.67-0.07,1.29-0.13,1.96-0.19C594.67,503.71,594.67,503.09,594.67,502.22z M403.45,71.18c0,0.8,0,1.45,0,2.11
	c0.83-0.05,1.5-0.09,2.29-0.14c-0.23-0.77-0.42-1.41-0.62-2.09C404.46,71.11,403.93,71.15,403.45,71.18z M521.85,414.82
	c-0.64,0.05-1.24,0.1-1.9,0.16c0.04,0.73,0.07,1.33,0.11,2.03c0.72-0.05,1.32-0.1,1.98-0.15
	C521.97,416.15,521.92,415.53,521.85,414.82z M568.32,428.92c0.06,0.76,0.11,1.36,0.16,2.01c0.74-0.04,1.34-0.07,1.99-0.1
	c-0.12-0.78-0.21-1.45-0.32-2.2C569.5,428.73,569.06,428.8,568.32,428.92z M576.61,130.45c-0.09-0.79-0.15-1.39-0.23-2.13
	c-0.65,0.12-1.16,0.22-1.75,0.33c0.07,0.71,0.12,1.3,0.18,1.94C575.4,130.54,575.87,130.5,576.61,130.45z M591.1,461.58
	c-0.13-0.81-0.24-1.47-0.36-2.15c-0.72,0.08-1.26,0.13-1.92,0.21c0.05,0.67,0.1,1.28,0.16,1.94
	C589.65,461.58,590.26,461.58,591.1,461.58z M508.46,201.23c0.8,0,1.53,0,2.39,0c-0.24-0.79-0.42-1.43-0.62-2.1
	c-0.66,0.05-1.19,0.1-1.76,0.14C508.46,200,508.46,200.61,508.46,201.23z M572.83,526.01c0.08,0.64,0.16,1.24,0.24,1.89
	c0.73-0.03,1.35-0.06,2.06-0.09c-0.08-0.69-0.14-1.29-0.21-1.93C574.2,525.92,573.58,525.96,572.83,526.01z M583.64,460.06
	c-0.72,0-1.39,0-2.2,0c0.17,0.78,0.31,1.43,0.46,2.15c0.7-0.15,1.21-0.26,1.73-0.37C583.64,461.18,583.64,460.63,583.64,460.06z
	 M600.17,590.62c0.84,0,1.46,0,2.18,0c-0.17-0.74-0.33-1.39-0.49-2.09c-0.64,0.08-1.17,0.14-1.78,0.22
	C600.11,589.39,600.14,589.93,600.17,590.62z M591.45,508.22c-0.09-0.83-0.15-1.42-0.22-2.05c-0.79,0.07-1.46,0.14-2.25,0.21
	c0.19,0.79,0.34,1.44,0.49,2.08C590.19,508.38,590.73,508.31,591.45,508.22z M414.7,54.17c-0.06-0.82-0.11-1.43-0.16-2.18
	c-0.63,0.07-1.23,0.14-1.87,0.21c0.06,0.73,0.11,1.34,0.18,2.09C413.43,54.26,413.96,54.22,414.7,54.17z M591.91,329.36
	c0.07,0.9,0.12,1.51,0.17,2.16c0.77-0.15,1.43-0.28,2.22-0.44c-0.16-0.67-0.3-1.24-0.45-1.85
	C593.17,329.27,592.64,329.31,591.91,329.36z M570.28,224.69c0-0.87,0-1.49,0-2.23c-0.79,0.1-1.52,0.2-2.25,0.29
	c0.19,0.85,0.34,1.52,0.51,2.3C569.15,224.92,569.67,224.82,570.28,224.69z M582.24,567.1c-0.21-0.77-0.38-1.42-0.56-2.1
	c-0.67,0.1-1.19,0.18-1.79,0.27c0.08,0.75,0.15,1.33,0.21,1.83C580.9,567.1,581.51,567.1,582.24,567.1z M601.73,523.45
	c0.06,0.78,0.1,1.38,0.15,2.07c0.68-0.06,1.22-0.11,1.85-0.16c-0.07-0.75-0.13-1.41-0.2-2.15
	C602.92,523.29,602.46,523.35,601.73,523.45z M477.75,55.86c0.91-0.1,1.58-0.17,2.34-0.25c-0.17-0.73-0.3-1.3-0.44-1.95
	c-0.73,0.09-1.33,0.17-2.04,0.26C477.66,54.57,477.7,55.09,477.75,55.86z M541.52,306.7c-0.04-0.87-0.07-1.49-0.1-2.17
	c-0.75,0.14-1.42,0.27-2.16,0.41c0.06,0.66,0.12,1.27,0.18,1.98C540.14,306.84,540.75,306.78,541.52,306.7z M602.22,464.05
	c-0.14-0.74-0.26-1.34-0.39-2.03c-0.74,0.11-1.4,0.2-2.06,0.3c0.08,0.81,0.15,1.46,0.22,2.12
	C600.77,464.3,601.38,464.2,602.22,464.05z M577.31,576.14c0.13,0.86,0.22,1.44,0.31,2.03c0.7-0.06,1.23-0.1,1.85-0.15
	c-0.07-0.69-0.14-1.29-0.21-1.99C578.6,576.07,578.07,576.1,577.31,576.14z M544.52,300.86c-0.71,0.06-1.32,0.11-1.98,0.17
	c0,0.73,0,1.42,0,2.17c0.75-0.14,1.42-0.27,2.17-0.41C544.65,302.15,544.59,301.56,544.52,300.86z M617.38,578.19
	c0.63-0.04,1.17-0.08,1.76-0.12c0-0.76,0-1.37,0-2.13c-0.76,0.15-1.43,0.27-2.11,0.4C617.16,576.99,617.25,577.45,617.38,578.19z
	 M595.13,509.65c-0.79,0.05-1.4,0.08-2.1,0.12c0.07,0.8,0.13,1.46,0.19,2.16c0.78-0.19,1.37-0.33,2.01-0.49
	C595.19,510.87,595.17,510.4,595.13,509.65z M576.68,278.41c0.7-0.06,1.36-0.12,2.06-0.18c-0.13-0.78-0.23-1.39-0.32-1.9
	c-0.79,0-1.45,0-2.14,0C576.42,277.06,576.54,277.67,576.68,278.41z M612.57,565.87c0.05,0.68,0.09,1.32,0.14,2.01
	c0.78-0.05,1.4-0.1,2.06-0.14c-0.06-0.71-0.1-1.32-0.16-2.03C613.94,565.76,613.33,565.81,612.57,565.87z M586.74,227.06
	c0.8-0.09,1.33-0.16,1.94-0.23c-0.06-0.78-0.12-1.44-0.18-2.18c-0.74,0.11-1.25,0.19-1.76,0.27
	C586.74,225.65,586.74,226.25,586.74,227.06z M397.75,66.31c0-0.76,0-1.43,0-2.2c-0.83,0.18-1.55,0.33-2.27,0.48
	c0.13,0.75,0.23,1.35,0.34,1.95C396.57,66.45,397.18,66.38,397.75,66.31z M593.46,321.94c-0.67,0.09-1.27,0.18-2,0.28
	c0.08,0.66,0.16,1.23,0.24,1.86c0.77-0.05,1.37-0.08,2.07-0.13C593.66,323.22,593.57,322.62,593.46,321.94z M577.6,372.09
	c0.66-0.04,1.32-0.07,2.03-0.11c-0.04-0.79-0.08-1.46-0.12-2.18c-0.8,0.14-1.47,0.26-2.16,0.38
	C577.43,370.83,577.5,371.37,577.6,372.09z M492.47,180.98c0.72-0.09,1.25-0.15,1.85-0.22c-0.08-0.76-0.15-1.42-0.23-2.14
	c-0.71,0.14-1.22,0.24-1.75,0.34C492.39,179.66,492.42,180.2,492.47,180.98z M560.96,595.64c0,0.82,0,1.44,0,2.21
	c0.75-0.15,1.41-0.27,2.13-0.41c-0.09-0.69-0.15-1.23-0.22-1.8C562.22,595.64,561.67,595.64,560.96,595.64z M606.24,511.97
	c-0.7,0.25-1.48,0.29-1.72,0.67c-0.27,0.43-0.06,1.16-0.06,1.82c0.73-0.04,1.26-0.06,1.91-0.1
	C606.32,513.57,606.29,512.91,606.24,511.97z M555.02,114.34c0.76-0.16,1.44-0.3,2.22-0.47c-0.15-0.7-0.27-1.28-0.41-1.91
	c-0.8,0.04-1.49,0.07-2.2,0.11C554.77,112.88,554.88,113.56,555.02,114.34z M495.77,469.56c0.86-0.1,1.44-0.17,2.1-0.25
	c-0.1-0.74-0.18-1.26-0.26-1.82c-0.74,0.07-1.35,0.14-2.05,0.2C495.63,468.28,495.69,468.81,495.77,469.56z M404.14,56.61
	c-0.84,0.12-1.48,0.21-2.1,0.3c0.09,0.79,0.16,1.36,0.23,1.98c0.78-0.11,1.38-0.2,2.07-0.3C404.28,57.97,404.22,57.44,404.14,56.61z
	 M349.08,32.25c0.22,0.9,0.38,1.55,0.54,2.21c0.69-0.15,1.22-0.26,1.87-0.4c-0.05-0.64-0.09-1.24-0.13-1.81
	C350.6,32.25,349.99,32.25,349.08,32.25z M634.21,584.05c-0.12-0.85-0.2-1.43-0.28-2.04c-0.78,0.09-1.4,0.15-2.07,0.23
	c0.08,0.7,0.15,1.31,0.23,2.02C632.76,584.19,633.38,584.13,634.21,584.05z M532.22,576.42c-0.72,0.14-1.22,0.25-1.79,0.36
	c0.06,0.74,0.11,1.35,0.17,2.03c0.72-0.07,1.34-0.13,1.97-0.19C532.46,577.91,532.36,577.29,532.22,576.42z M598.52,173.42
	c-0.85,0.06-1.51,0.1-2.25,0.15c0.08,0.66,0.15,1.19,0.24,1.83c0.75-0.07,1.4-0.12,2.15-0.19
	C598.61,174.6,598.57,174.15,598.52,173.42z M583.35,189.21c0.11,0.68,0.2,1.27,0.31,1.93c0.73-0.06,1.32-0.1,1.97-0.16
	c-0.07-0.84-0.12-1.48-0.18-2.2C584.61,188.95,584.01,189.07,583.35,189.21z M518.75,566.91c0,0.83,0,1.42,0,2.12
	c0.75-0.12,1.42-0.22,2.2-0.34c-0.12-0.69-0.23-1.27-0.34-1.91C519.93,566.82,519.39,566.86,518.75,566.91z M587.7,154.59
	c0.64-0.08,1.15-0.13,1.69-0.2c0-0.74,0-1.33,0-2.06c-0.68,0.08-1.26,0.15-1.9,0.23C587.56,153.27,587.62,153.86,587.7,154.59z
	 M596.74,466.11c0.04,0.66,0.07,1.3,0.11,2c0.8-0.09,1.42-0.15,2.04-0.22c-0.04-0.73-0.07-1.36-0.11-2.06
	C598.14,465.92,597.52,466.01,596.74,466.11z M575.3,485.94c-0.73,0.12-1.39,0.23-2.17,0.36c0.04,0.7,0.07,1.31,0.11,2.03
	c0.77-0.09,1.43-0.18,2.06-0.25C575.3,487.33,575.3,486.7,575.3,485.94z M552.72,583.83c0.63,0,1.34,0,2.16,0
	c-0.21-0.76-0.38-1.41-0.53-1.99C552.84,581.77,552.28,582.35,552.72,583.83z M597.91,561.9c1.63-0.33,1.63-0.33,1.35-2.1
	C597.74,560,597.74,560,597.91,561.9z M600.34,569.03c-0.13-0.73-0.24-1.39-0.36-2.1c-0.67,0.08-1.2,0.15-1.69,0.2
	c0,0.71,0,1.31,0,2.02C599.02,569.1,599.64,569.07,600.34,569.03z M449.33,34.39c-0.82,0.17-1.48,0.3-2.15,0.44
	c0.08,0.72,0.15,1.31,0.22,1.99c0.71-0.09,1.3-0.17,1.93-0.25C449.33,35.87,449.33,35.26,449.33,34.39z M573.45,572.79
	c0.12,0.73,0.23,1.37,0.34,2.04c0.71-0.1,1.22-0.18,1.82-0.27c-0.09-0.76-0.16-1.34-0.24-1.94
	C574.67,572.69,574.13,572.73,573.45,572.79z M340.07,24.45c-0.1-0.83-0.18-1.5-0.26-2.21c-0.76,0.21-1.35,0.37-2.06,0.57
	c0.1,0.58,0.2,1.09,0.29,1.64C338.68,24.45,339.22,24.45,340.07,24.45z M547.77,589.54c-0.61,0.03-1.19,0.05-1.92,0.08
	c0.19,0.84,0.33,1.49,0.48,2.15c0.63-0.07,1.09-0.12,1.68-0.18C547.93,590.88,547.86,590.28,547.77,589.54z M547.18,289.84
	c-0.84,0.07-1.46,0.12-2.11,0.17c0.07,0.78,0.13,1.45,0.2,2.16c0.74-0.16,1.33-0.29,1.91-0.42
	C547.18,291.13,547.18,290.59,547.18,289.84z M580.69,368.49c0.72-0.17,1.37-0.33,2.08-0.5c-0.1-0.69-0.18-1.2-0.27-1.8
	c-0.72,0.06-1.31,0.11-1.91,0.16C580.63,367.14,580.65,367.75,580.69,368.49z M398.72,60.61c0.05,0.85,0.09,1.47,0.13,2.19
	c0.68-0.13,1.35-0.26,2.11-0.4c-0.05-0.72-0.1-1.33-0.15-1.98C400.08,60.49,399.46,60.55,398.72,60.61z M559.16,592.04
	c-0.84,0.15-1.5,0.27-2.22,0.39c0.18,0.72,0.33,1.29,0.48,1.88c0.71-0.07,1.23-0.12,1.73-0.17
	C559.16,593.41,559.16,592.87,559.16,592.04z M462.27,129.6c0.76-0.15,1.47-0.28,2.31-0.45c-0.12-0.72-0.22-1.3-0.33-1.96
	c-0.77,0.05-1.36,0.09-1.98,0.12C462.27,128.1,462.27,128.72,462.27,129.6z M503.42,434.31c0.11,0.83,0.19,1.44,0.28,2.15
	c0.7-0.1,1.3-0.18,2-0.28c-0.1-0.73-0.18-1.33-0.26-1.87C504.73,434.31,504.18,434.31,503.42,434.31z M560.27,107.99
	c-0.86,0.06-1.51,0.1-2.2,0.15c0.15,0.8,0.27,1.4,0.4,2.08c0.7-0.09,1.3-0.17,2.02-0.26C560.42,109.31,560.36,108.78,560.27,107.99z
	 M590.1,515.65c0.75-0.12,1.39-0.23,2.02-0.34c-0.07-0.78-0.12-1.38-0.18-2.03c-0.7,0.13-1.29,0.24-1.99,0.37
	C590,514.34,590.05,514.94,590.1,515.65z M591.08,276.91c0.8,0,1.41,0,2.05,0c0-0.73,0-1.35,0-2.1c-0.75,0.14-1.41,0.27-2.15,0.41
	C591.02,275.81,591.04,276.28,591.08,276.91z M572.23,228.33c0.87-0.14,1.49-0.23,2.1-0.33c0-0.75,0-1.43,0-2.21
	c-0.75,0.14-1.42,0.27-2.19,0.42C572.17,226.91,572.2,227.53,572.23,228.33z M571.28,464.7c0.03,0.67,0.06,1.34,0.1,2.07
	c0.77-0.11,1.38-0.2,2.05-0.3c-0.09-0.77-0.16-1.44-0.24-2.13C572.5,464.47,571.95,464.57,571.28,464.7z M638.02,608.94
	c0.7-0.1,1.21-0.18,1.8-0.26c-0.04-0.69-0.08-1.26-0.12-1.89c-0.78,0.08-1.37,0.15-2.03,0.22
	C637.79,607.65,637.88,608.17,638.02,608.94z M554.61,543.96c-0.06-0.7-0.12-1.31-0.18-1.97c-0.68,0.06-1.22,0.12-1.89,0.18
	c0.06,0.75,0.12,1.41,0.18,2.14C553.44,544.17,553.98,544.07,554.61,543.96z M299.74,62.07c-0.75,0.07-1.34,0.12-2.01,0.18
	c0.06,0.76,0.1,1.35,0.15,2.02c0.76-0.17,1.41-0.32,2.12-0.48C299.92,63.24,299.85,62.79,299.74,62.07z M506.78,448.73
	c-0.85,0.08-1.44,0.14-2.06,0.2c0.09,0.72,0.16,1.25,0.24,1.86c0.73-0.09,1.32-0.16,1.96-0.24
	C506.87,449.92,506.83,449.46,506.78,448.73z M584.13,301.12c-0.7,0.1-1.23,0.18-1.83,0.27c0.04,0.66,0.07,1.2,0.11,1.88
	c0.76-0.04,1.42-0.08,2.14-0.12C584.4,302.42,584.29,301.88,584.13,301.12z M551.13,293.08c-0.66,0.11-1.25,0.22-1.93,0.34
	c0.04,0.59,0.08,1.11,0.13,1.75c0.8-0.05,1.45-0.09,2.15-0.14C551.35,294.28,551.25,293.75,551.13,293.08z M598.36,587.22
	c-0.1-0.88-0.18-1.48-0.26-2.16c-0.67,0.14-1.2,0.25-1.83,0.38c0.03,0.65,0.06,1.24,0.09,1.9
	C597.08,587.3,597.62,587.27,598.36,587.22z M527.49,210.51c0.79-0.1,1.4-0.18,2.11-0.27c-0.05-0.79-0.09-1.48-0.14-2.23
	c-0.8,0.18-1.47,0.34-2.15,0.49C527.37,209.16,527.42,209.71,527.49,210.51z M417.07,64.83c0.73-0.17,1.39-0.32,2.15-0.5
	c-0.07-0.63-0.12-1.16-0.17-1.64c-0.82,0-1.48,0-2.19,0C416.93,63.44,417,64.06,417.07,64.83z M606.7,541.07
	c0.11,0.71,0.2,1.29,0.29,1.9c0.78-0.08,1.39-0.14,2.07-0.21c-0.11-0.67-0.21-1.2-0.31-1.83
	C608.05,540.98,607.43,541.02,606.7,541.07z M568.41,389.09c0.11,0.77,0.19,1.35,0.33,2.4c0.69-0.6,1.23-0.87,1.45-1.31
	c0.16-0.31-0.1-0.84-0.17-1.31C569.48,388.94,569.1,388.99,568.41,389.09z M611.79,558.7c0.08,0.82,0.14,1.49,0.21,2.22
	c0.74-0.17,1.34-0.31,2.01-0.47c-0.07-0.69-0.13-1.29-0.19-1.9C613.14,558.59,612.59,558.63,611.79,558.7z M592.22,600.19
	c-0.75,0-1.39,0-2.16,0c0.19,0.72,0.35,1.29,0.52,1.92c0.61-0.09,1.13-0.16,1.74-0.24C592.28,601.24,592.25,600.73,592.22,600.19z
	 M630.2,578.63c-0.89,0.1-1.56,0.18-2.3,0.26c0.18,0.74,0.33,1.33,0.49,2c0.71-0.07,1.29-0.13,1.91-0.2
	C630.27,579.95,630.24,579.41,630.2,578.63z M556.62,545.65c0.1,0.78,0.17,1.3,0.25,1.87c0.76-0.1,1.34-0.18,1.99-0.26
	c-0.11-0.74-0.2-1.34-0.3-2.01C557.87,545.39,557.34,545.5,556.62,545.65z M590.01,491.98c-0.81,0.06-1.41,0.1-2.05,0.14
	c0.07,0.73,0.13,1.34,0.2,2c0.75-0.09,1.42-0.17,2.15-0.26C590.22,493.22,590.13,492.69,590.01,491.98z M574.08,518.73
	c-0.68,0.06-1.27,0.12-1.92,0.18c0.06,0.82,0.11,1.49,0.16,2.24c0.79-0.17,1.46-0.32,2.26-0.5
	C574.41,519.97,574.26,519.4,574.08,518.73z M542.09,295.89c0.77-0.11,1.43-0.21,2.08-0.31c-0.07-0.76-0.12-1.37-0.18-2.03
	c-0.7,0.12-1.3,0.22-2.04,0.35C542,294.58,542.05,295.19,542.09,295.89z M498.9,465.81c0.63-0.11,1.26-0.23,2.03-0.36
	c-0.1-0.69-0.18-1.28-0.25-1.79c-0.77,0-1.39,0-2.05,0C498.72,464.35,498.8,464.97,498.9,465.81z M583.93,275.9
	c0,0.65,0,1.24,0,1.92c0.74-0.04,1.35-0.07,2.05-0.11c-0.14-0.7-0.26-1.3-0.4-1.97C584.98,275.79,584.45,275.85,583.93,275.9z
	 M595.3,469.8c-0.82,0.06-1.47,0.11-2.19,0.16c0.17,0.79,0.3,1.38,0.44,2.01c0.76-0.12,1.36-0.21,1.95-0.3
	C595.43,471.01,595.38,470.54,595.3,469.8z M615.6,601.68c0.12,0.64,0.23,1.24,0.36,1.92c0.59-0.05,1.13-0.09,1.76-0.15
	c-0.04-0.71-0.06-1.29-0.09-1.9C616.88,601.6,616.33,601.63,615.6,601.68z M496.09,493.03c-0.81,0-1.41,0-2.11,0
	c0.13,0.73,0.24,1.32,0.36,2c0.74-0.13,1.33-0.23,1.96-0.34C496.22,494.08,496.16,493.61,496.09,493.03z M592.4,462.68
	c0.19,0.88,0.34,1.54,0.49,2.22c0.69-0.16,1.23-0.28,1.88-0.42c-0.08-0.63-0.15-1.22-0.22-1.79
	C593.84,462.68,593.28,462.68,592.4,462.68z M428.07,65.35c0.12,0.79,0.21,1.38,0.31,2.04c0.78-0.08,1.39-0.15,2.05-0.22
	c-0.13-0.78-0.24-1.45-0.36-2.14C429.38,65.14,428.83,65.23,428.07,65.35z M355.58,37.53c-0.07-0.77-0.13-1.43-0.2-2.15
	c-0.76,0.17-1.35,0.3-1.96,0.43c0.03,0.66,0.06,1.2,0.09,1.83C354.26,37.6,354.87,37.57,355.58,37.53z M598.64,592.38
	c-0.69,0.08-1.26,0.14-1.93,0.22c0.04,0.73,0.08,1.33,0.12,2.06c0.73-0.16,1.31-0.28,1.95-0.41
	C598.73,593.58,598.69,593.05,598.64,592.38z M594.22,273.55c0.8-0.19,1.39-0.33,2.09-0.49c-0.04-0.67-0.08-1.28-0.13-1.99
	c-0.78,0.1-1.44,0.19-2.09,0.28C594.14,272.1,594.17,272.72,594.22,273.55z M424.42,79.83c-0.89,0.24-1.53,0.41-2.21,0.59
	c0.12,0.73,0.22,1.31,0.32,1.95c0.73-0.1,1.32-0.18,1.89-0.25C424.42,81.33,424.42,80.7,424.42,79.83z M333.27,32.19
	c-0.04-0.76-0.07-1.3-0.1-1.95c-0.8,0.09-1.44,0.16-2.09,0.24c0.06,0.77,0.11,1.38,0.17,2.06
	C331.92,32.42,332.51,32.32,333.27,32.19z M548.92,390.6c-0.75,0.09-1.43,0.18-2.19,0.27c0.09,0.76,0.15,1.35,0.23,2.02
	c0.69-0.09,1.23-0.16,1.82-0.24C548.82,391.96,548.87,391.34,548.92,390.6z M465.99,46.15c0.74-0.18,1.4-0.33,2.16-0.51
	c-0.07-0.62-0.12-1.15-0.17-1.61c-0.81,0-1.47,0-2.15,0C465.88,44.78,465.93,45.38,465.99,46.15z M595.84,540.22
	c0.83,0,1.44,0,2.17,0c-0.11-0.72-0.21-1.32-0.32-2c-0.73,0.15-1.32,0.27-1.98,0.4C595.76,539.2,595.8,539.66,595.84,540.22z
	 M647.49,613.4c-0.69,0.18-1.19,0.31-1.81,0.47c0.08,0.64,0.14,1.21,0.21,1.81c0.77-0.04,1.38-0.08,2.05-0.12
	C647.8,614.86,647.68,614.26,647.49,613.4z M654.23,607.61c0-0.74,0-1.43,0-2.17c-0.8,0.11-1.48,0.21-2.19,0.31
	c0.09,0.75,0.16,1.36,0.24,2.05C652.96,607.73,653.58,607.67,654.23,607.61z M578.33,131.7c0.14,0.75,0.24,1.33,0.35,1.97
	c0.76-0.09,1.36-0.16,1.95-0.24c-0.05-0.75-0.1-1.36-0.15-2.05C579.82,131.48,579.21,131.57,578.33,131.7z M563.81,103.92
	c-0.86,0.2-1.5,0.34-2.21,0.5c0.1,0.72,0.18,1.29,0.26,1.87c0.47-0.03,0.78-0.05,1.09-0.08c0.3-0.03,0.6-0.09,0.87-0.12
	C563.81,105.38,563.81,104.76,563.81,103.92z M337.64,40.86c-2.18,0.03-2.18,0.03-1.75,1.97c0.67-0.06,1.35-0.11,2.09-0.18
	C337.86,42.04,337.76,41.51,337.64,40.86z M629.73,555.46c0.7-0.09,1.35-0.17,2.07-0.26c-0.07-0.69-0.12-1.22-0.17-1.85
	c-0.77,0.07-1.43,0.13-2.19,0.2C629.55,554.28,629.63,554.8,629.73,555.46z M576.4,460.81c-0.74,0-1.35,0-2.04,0
	c0.04,0.75,0.07,1.42,0.11,2.15c0.74-0.15,1.34-0.27,2.02-0.41C576.46,461.95,576.43,461.42,576.4,460.81z M618.37,590.69
	c0.03,0.64,0.06,1.24,0.09,1.9c0.73-0.05,1.27-0.08,1.92-0.12c-0.05-0.7-0.09-1.3-0.15-1.99
	C619.56,590.56,619.02,590.62,618.37,590.69z M400.46,55.3c-0.07-0.82-0.12-1.47-0.17-2.16c-0.77,0.14-1.38,0.25-2.03,0.37
	c0.07,0.68,0.14,1.28,0.21,1.96C399.1,55.42,399.64,55.37,400.46,55.3z M583.16,413.17c-0.76,0.03-1.38,0.06-2.07,0.09
	c0.04,0.79,0.07,1.46,0.1,2.23c0.72-0.14,1.39-0.26,2.17-0.41C583.29,414.48,583.24,413.95,583.16,413.17z M593.81,266.09
	c0.8-0.09,1.37-0.15,1.95-0.22c0-0.84,0-1.52,0-2.28c-0.74,0.2-1.41,0.38-2.15,0.58C593.67,264.75,593.73,265.28,593.81,266.09z
	 M502.26,525.21c-0.64,0-1.23,0-1.86,0c0.04,0.72,0.07,1.31,0.11,2.02c0.77-0.17,1.41-0.32,2.1-0.47
	C502.46,526.13,502.36,525.68,502.26,525.21z M578.74,570.91c-0.08-0.73-0.16-1.39-0.24-2.14c-0.68,0.13-1.19,0.23-1.75,0.33
	c0.07,0.72,0.12,1.31,0.19,1.99C577.58,571.04,578.12,570.98,578.74,570.91z M588.43,557.07c-0.87,0.09-1.47,0.16-2.12,0.23
	c0.11,0.77,0.21,1.42,0.31,2.09c0.73-0.15,1.31-0.27,1.81-0.38C588.43,558.31,588.43,557.79,588.43,557.07z M596.8,570.71
	c-0.68,0.12-1.26,0.22-1.93,0.33c0.17,0.74,0.31,1.32,0.46,1.95c0.62-0.08,1.07-0.14,1.62-0.22
	C596.89,572.07,596.85,571.47,596.8,570.71z M577.76,330.5c0,0.75,0,1.36,0,2.08c0.7-0.06,1.3-0.11,2.04-0.17
	c-0.2-0.79-0.36-1.43-0.52-2.06C578.73,330.41,578.34,330.45,577.76,330.5z M574.44,394.28c-0.17-0.85-0.28-1.42-0.42-2.1
	c-0.67,0.18-1.17,0.32-1.75,0.47c0.06,0.62,0.11,1.14,0.16,1.72C573.1,394.35,573.63,394.32,574.44,394.28z M567.97,528.43
	c-0.04-0.76-0.07-1.37-0.1-2.12c-0.78,0.08-1.45,0.15-2.2,0.22c0.1,0.76,0.18,1.42,0.27,2.1
	C566.62,528.57,567.16,528.52,567.97,528.43z M428.74,50.96c-0.64,0.04-1.24,0.07-1.94,0.11c0.05,0.82,0.09,1.49,0.14,2.25
	c0.82-0.19,1.49-0.35,2.24-0.52C429.03,52.12,428.9,51.59,428.74,50.96z M503.36,201.63c-0.06-0.64-0.11-1.24-0.17-1.96
	c-0.79,0.04-1.44,0.08-2.17,0.12c0.16,0.78,0.28,1.38,0.41,1.99C502.11,201.73,502.66,201.69,503.36,201.63z M542.03,588.78
	c0.82-0.18,1.5-0.33,2.27-0.5c-0.17-0.68-0.31-1.2-0.47-1.83c-0.65,0.07-1.24,0.13-1.8,0.19
	C542.03,587.37,542.03,587.92,542.03,588.78z M358.76,33.62c-0.1-0.81-0.18-1.39-0.26-2.01c-0.77,0.1-1.38,0.18-2,0.25
	c0.07,0.74,0.12,1.36,0.19,2.07C357.33,33.84,357.94,33.75,358.76,33.62z M582.61,194.96c-0.18-0.79-0.34-1.49-0.5-2.18
	c-0.76,0.13-1.35,0.23-2.03,0.34c0.15,0.75,0.27,1.32,0.38,1.84C581.24,194.96,581.86,194.96,582.61,194.96z M557.9,475
	c0.67-0.06,1.2-0.11,1.73-0.16c0-0.71,0-1.31,0-2.09c-0.75,0.16-1.37,0.3-2.01,0.44C557.72,473.83,557.79,474.29,557.9,475z
	 M545.41,310.08c-0.09-0.81-0.15-1.4-0.22-2.01c-0.75,0.05-1.36,0.1-2.07,0.15c0.09,0.76,0.17,1.4,0.25,2.06
	C544.11,310.21,544.65,310.16,545.41,310.08z M572.74,399.74c0.08,0.69,0.15,1.31,0.22,2.01c0.76-0.17,1.27-0.29,1.88-0.42
	c-0.11-0.65-0.19-1.17-0.29-1.78C573.94,399.61,573.41,399.67,572.74,399.74z M553.92,534.81c-0.75,0.06-1.22,0.1-1.7,0.14
	c0,0.71,0,1.31,0,2.03c0.67-0.1,1.2-0.18,1.7-0.26C553.92,536.07,553.92,535.54,553.92,534.81z M516.57,418.8
	c0.06,0.82,0.11,1.41,0.17,2.13c0.77-0.21,1.34-0.38,1.93-0.54c-0.05-0.65-0.09-1.18-0.14-1.78
	C517.86,418.68,517.33,418.73,516.57,418.8z M619.65,585.19c-0.03-0.71-0.06-1.29-0.09-1.92c-0.79,0.08-1.38,0.13-2.06,0.2
	c0.14,0.71,0.25,1.31,0.39,2C618.44,585.39,618.89,585.31,619.65,585.19z M357.69,46.22c0.06,0.9,0.11,1.57,0.16,2.31
	c0.8-0.16,1.46-0.3,2.27-0.46c-0.18-0.71-0.33-1.28-0.47-1.85C359.02,46.22,358.49,46.22,357.69,46.22z M561.65,536.33
	c-0.21-0.76-0.39-1.42-0.58-2.11c-0.68,0.21-1.18,0.36-1.7,0.52c0.05,0.64,0.09,1.1,0.13,1.59
	C560.26,536.33,560.88,536.33,561.65,536.33z M643.18,602.87c-0.78,0.11-1.36,0.19-2.03,0.29c0.1,0.73,0.18,1.3,0.28,1.94
	c0.68-0.08,1.2-0.15,1.76-0.21C643.18,604.24,643.18,603.7,643.18,602.87z M599.48,457.13c0.76-0.08,1.37-0.15,2-0.22
	c0-0.78,0-1.47,0-2.25c-0.77,0.17-1.45,0.31-2.14,0.47C599.38,455.74,599.42,456.29,599.48,457.13z M593.62,596.45
	c0.08,0.75,0.14,1.28,0.21,1.88c0.62-0.04,1.16-0.08,1.76-0.12c0-0.75,0-1.37,0-2.08C594.97,596.23,594.43,596.32,593.62,596.45z
	 M586.08,282.87c-0.74,0.11-1.25,0.19-1.86,0.28c0.05,0.71,0.09,1.3,0.14,1.99c0.75-0.14,1.34-0.25,2.03-0.38
	C586.29,284.11,586.2,283.59,586.08,282.87z M582.47,281.53c-0.08-0.72-0.14-1.33-0.21-1.99c-0.67,0.06-1.22,0.11-1.79,0.16
	c0,0.68,0,1.28,0,2.04C581.19,281.66,581.79,281.6,582.47,281.53z M546.21,528.31c-0.78,0.08-1.43,0.14-2.11,0.21
	c0.07,0.79,0.13,1.44,0.19,2.14c0.72-0.15,1.31-0.27,2.03-0.42C546.28,529.6,546.25,529,546.21,528.31z M471.37,40.01
	c-0.81,0-1.48,0-2.28,0c0.17,0.74,0.31,1.33,0.48,2.03c0.69-0.08,1.27-0.14,1.9-0.21C471.43,41.1,471.4,40.56,471.37,40.01z
	 M378.02,47.99c-0.64,0.08-1.28,0.16-1.91,0.23c0.08,0.79,0.14,1.39,0.2,2.07c0.7-0.13,1.29-0.24,1.87-0.35
	C378.13,49.26,378.08,48.71,378.02,47.99z M342.49,35.1c0.7-0.07,1.15-0.11,1.76-0.17c-0.04-0.77-0.07-1.42-0.11-2.19
	c-0.7,0.17-1.2,0.29-1.65,0.39C342.49,33.81,342.49,34.33,342.49,35.1z M596.11,437.44c-0.83,0-1.44,0-2.2,0
	c0.11,0.7,0.2,1.3,0.3,1.99c0.73-0.14,1.33-0.25,2.02-0.38C596.19,438.5,596.15,438.04,596.11,437.44z M580.56,299.82
	c-0.08-0.85-0.13-1.46-0.19-2.16c-0.72,0.14-1.31,0.25-1.97,0.38c0.06,0.73,0.11,1.31,0.16,1.94
	C579.27,299.92,579.8,299.88,580.56,299.82z M591.55,284.37c0.86-0.13,1.47-0.22,2.13-0.32c-0.1-0.66-0.18-1.19-0.28-1.84
	c-0.7,0.07-1.29,0.12-1.86,0.17C591.55,283.09,591.55,283.64,591.55,284.37z M585.01,476.39c-0.07-0.83-0.12-1.43-0.17-2.14
	c-0.64,0.11-1.16,0.2-1.74,0.3c0.05,0.73,0.1,1.32,0.14,1.99C583.89,476.48,584.35,476.44,585.01,476.39z M570,550.03
	c-0.12-0.79-0.22-1.4-0.33-2.08c-0.72,0.08-1.31,0.15-2.04,0.23c0.13,0.73,0.23,1.31,0.33,1.86
	C568.66,550.03,569.2,550.03,570,550.03z M543.17,531.91c-0.88,0.24-1.52,0.41-2.17,0.58c0.07,0.72,0.13,1.26,0.18,1.83
	c0.73-0.06,1.35-0.11,1.99-0.16C543.17,533.43,543.17,532.81,543.17,531.91z M546.08,317.32c-0.15-0.77-0.27-1.38-0.41-2.11
	c-0.6,0.08-1.13,0.15-1.78,0.24c0.05,0.76,0.09,1.42,0.13,2.11C544.77,317.47,545.31,317.41,546.08,317.32z M587.1,458.15
	c-0.08-0.88-0.13-1.46-0.19-2.08c-0.74,0.08-1.27,0.14-1.96,0.21c0.08,0.69,0.15,1.29,0.23,1.95
	C585.8,458.21,586.33,458.18,587.1,458.15z M588.69,374.82c0.86-0.05,1.46-0.09,2.19-0.13c-0.13-0.66-0.24-1.18-0.36-1.78
	c-0.68,0.06-1.28,0.11-1.94,0.17C588.61,373.67,588.64,374.12,588.69,374.82z M540.58,297.58c-0.83,0-1.53,0-2.28,0
	c0.16,0.75,0.31,1.43,0.48,2.21c0.73-0.14,1.39-0.27,2.06-0.39C540.74,298.7,540.66,298.16,540.58,297.58z M570.33,557.1
	c-0.09-0.61-0.17-1.2-0.27-1.85c-0.66,0.04-1.2,0.07-1.83,0.11c0.08,0.76,0.15,1.42,0.21,2.1
	C569.15,557.33,569.69,557.23,570.33,557.1z M564.51,530.21c-0.81,0.07-1.43,0.12-2.21,0.18c0.16,0.77,0.3,1.42,0.44,2.12
	c0.69-0.12,1.21-0.21,1.77-0.3C564.51,531.54,564.51,531,564.51,530.21z M561.95,501.66c-0.71,0.17-1.21,0.29-1.78,0.43
	c0.09,0.66,0.16,1.17,0.22,1.66c0.75,0,1.35,0,2,0C562.25,503.08,562.12,502.49,561.95,501.66z M578.05,477.06
	c-0.11-0.78-0.2-1.38-0.29-2.04c-0.72,0.09-1.31,0.17-2.03,0.26c0.12,0.71,0.21,1.23,0.31,1.79
	C576.66,477.06,577.2,477.06,578.05,477.06z M600.06,503.85c0.7-0.05,1.35-0.09,2.08-0.15c-0.14-0.78-0.25-1.36-0.35-1.95
	c-0.74,0.17-1.34,0.31-1.95,0.46C599.92,502.81,599.98,503.27,600.06,503.85z M568.9,396.37c0.09,0.79,0.14,1.31,0.21,1.91
	c0.68-0.09,1.2-0.16,1.81-0.24c-0.05-0.67-0.1-1.21-0.15-1.87C570.14,396.23,569.61,396.29,568.9,396.37z M622.33,614.12
	c-0.09-0.88-0.16-1.48-0.28-2.55c-0.82,0.66-1.38,0.98-1.73,1.45c-0.17,0.23,0.03,0.74,0.06,1.1
	C621.04,614.12,621.58,614.12,622.33,614.12z M570.28,457.41c0.24,0.87,0.39,1.43,0.56,2.05c0.7-0.07,1.23-0.13,1.84-0.19
	c-0.06-0.7-0.11-1.3-0.15-1.85C571.78,457.41,571.16,457.41,570.28,457.41z M583.59,333.45c-0.86,0.11-1.45,0.19-2.18,0.28
	c0.17,0.69,0.3,1.2,0.46,1.82c0.63-0.06,1.21-0.11,1.72-0.16C583.59,334.7,583.59,334.16,583.59,333.45z M590.86,314.94
	c0.04,0.65,0.08,1.27,0.11,1.94c0.8-0.04,1.41-0.07,2.1-0.11c-0.16-0.75-0.28-1.34-0.4-1.93
	C592.02,314.88,591.55,314.91,590.86,314.94z M349.07,27.06c0.67-0.03,1.28-0.06,1.98-0.1c-0.04-0.74-0.06-1.35-0.1-2.03
	c-0.8,0.11-1.47,0.2-2.28,0.31C348.82,25.93,348.93,26.45,349.07,27.06z M599.65,435.34c-0.05-0.82-0.09-1.44-0.13-2.14
	c-0.79,0.08-1.46,0.15-2.34,0.24c0.19,0.71,0.34,1.29,0.5,1.9C598.3,435.34,598.84,435.34,599.65,435.34z M590,533.61
	c-0.07-0.71-0.12-1.24-0.19-1.86c-0.69,0.05-1.28,0.09-1.96,0.14c0.06,0.75,0.11,1.34,0.17,2.01
	C588.69,533.8,589.22,533.72,590,533.61z M531.01,397.77c-0.04-0.71-0.07-1.23-0.1-1.81c-0.75,0-1.42,0-2.16,0
	c0.18,0.74,0.33,1.38,0.5,2.05C529.85,397.93,530.31,397.87,531.01,397.77z M583.6,151.59c0.7-0.14,1.36-0.27,2.13-0.43
	c-0.12-0.68-0.22-1.25-0.34-1.89c-0.72,0.07-1.3,0.13-1.89,0.18C583.54,150.25,583.57,150.86,583.6,151.59z M589.91,473.86
	c0.05,0.76,0.1,1.37,0.15,2.14c0.8-0.22,1.44-0.39,2.08-0.56c-0.09-0.61-0.16-1.07-0.24-1.58
	C591.28,473.86,590.74,473.86,589.91,473.86z M582.56,427.83c0.09,0.59,0.17,1.1,0.26,1.69c0.74-0.06,1.33-0.12,1.98-0.17
	c-0.09-0.76-0.16-1.37-0.23-2.02C583.83,427.51,583.24,427.66,582.56,427.83z M610.05,555.13c-0.89,0.13-1.55,0.23-2.26,0.33
	c0.19,0.71,0.33,1.23,0.48,1.82c0.67-0.05,1.2-0.08,1.78-0.12C610.05,556.51,610.05,555.98,610.05,555.13z M359.64,40.9
	c-0.2-0.79-0.33-1.31-0.48-1.92c-0.63,0.03-1.22,0.06-1.91,0.09c0.07,0.72,0.12,1.31,0.18,1.94
	C358.16,40.98,358.77,40.95,359.64,40.9z M634.68,591.2c0-0.73,0-1.34,0-2.1c-0.71,0.14-1.31,0.25-1.98,0.38
	c0.03,0.62,0.07,1.16,0.1,1.72C633.49,591.2,634.09,591.2,634.68,591.2z M585.3,378.97c0.75-0.13,1.41-0.25,2.22-0.39
	c-0.18-0.67-0.33-1.23-0.49-1.84c-0.68,0.06-1.21,0.11-1.73,0.16C585.3,377.65,585.3,378.26,585.3,378.97z M586.62,264.95
	c0.08,0.69-0.59,1.55,0.57,1.77c0.42,0.08,0.89-0.07,1.42-0.13c-0.1-0.69-0.18-1.22-0.28-1.9
	C587.7,264.79,587.18,264.87,586.62,264.95z M477.27,46.71c0,0.72,0,1.4,0,2.15c0.7-0.1,1.29-0.19,2.03-0.31
	c-0.15-0.74-0.27-1.38-0.4-2.02C478.27,46.6,477.8,46.65,477.27,46.71z M498.17,205.79c0.77-0.19,1.34-0.33,1.91-0.48
	c-0.12-0.62-0.2-1.08-0.31-1.66c-0.62,0.09-1.19,0.17-1.83,0.26C498.01,204.54,498.07,205,498.17,205.79z M581.53,353.63
	c-0.1-0.78-0.16-1.28-0.23-1.86c-0.81,0.07-1.4,0.13-2.02,0.18c0.1,0.75,0.19,1.36,0.29,2.08
	C580.21,353.9,580.79,353.78,581.53,353.63z M592.86,338.25c0.72,0,1.4,0,2.15,0c-0.13-0.71-0.24-1.32-0.36-1.94
	c-0.76,0.1-1.35,0.18-1.99,0.27C592.75,337.26,592.8,337.73,592.86,338.25z M568.92,414.16c-0.79,0.2-1.31,0.33-1.9,0.48
	c0.07,0.64,0.13,1.17,0.19,1.78c0.65-0.08,1.17-0.15,1.71-0.21C568.92,415.51,568.92,414.98,568.92,414.16z M406.72,45.58
	c-0.78,0.11-1.37,0.19-1.97,0.27c0.04,0.7,0.07,1.25,0.1,1.9c0.8-0.04,1.47-0.08,2.3-0.13C407,46.9,406.89,46.38,406.72,45.58z
	 M511.93,426.78c-0.68,0-1.28,0-1.99,0c0.1,0.66,0.18,1.2,0.27,1.84c0.67-0.03,1.27-0.06,2.03-0.09
	C512.11,427.84,512.02,427.32,511.93,426.78z M544.96,537.74c0.88-0.12,1.54-0.21,2.26-0.31c-0.18-0.71-0.31-1.23-0.47-1.84
	c-0.65,0.05-1.25,0.09-1.79,0.14C544.96,536.39,544.96,536.93,544.96,537.74z M566.08,506.8c-0.07-0.77-0.14-1.42-0.21-2.14
	c-0.83,0.14-1.48,0.26-2.19,0.38c0.21,0.81,0.37,1.47,0.54,2.11C564.83,507.03,565.3,506.94,566.08,506.8z M580.28,585.26
	c-0.18-0.81-0.32-1.4-0.47-2.1c-0.66,0.21-1.16,0.37-1.68,0.53c0.07,0.62,0.12,1.07,0.17,1.57
	C578.96,585.26,579.51,585.26,580.28,585.26z M605.89,615.49c0.84-0.08,1.44-0.14,2.2-0.21c-0.13-0.7-0.25-1.29-0.38-1.97
	c-0.67,0.07-1.27,0.13-1.83,0.19C605.89,614.19,605.89,614.74,605.89,615.49z M612.12,538.95c-0.06-0.82-0.1-1.44-0.15-2.11
	c-0.71,0.09-1.32,0.17-2.05,0.27c0.16,0.79,0.29,1.44,0.42,2.1C610.97,539.11,611.44,539.05,612.12,538.95z M590.43,454.26
	c-0.06-0.77-0.12-1.41-0.18-2.09c-1.8,0.46-1.8,0.46-1.54,2.22C589.23,454.35,589.75,454.31,590.43,454.26z M595.54,285.69
	c0.08,0.77,0.14,1.31,0.21,1.96c0.66-0.04,1.24-0.08,1.94-0.13c-0.11-0.79-0.19-1.39-0.29-2.1
	C596.78,285.52,596.25,285.59,595.54,285.69z M635.3,578.4c0.09,0.64,0.19,1.27,0.29,1.96c0.72-0.04,1.25-0.08,1.83-0.11
	c0-0.82,0-1.5,0-2.25C636.73,578.13,636.11,578.24,635.3,578.4z M580.84,200.26c0.07,0.77,0.12,1.37,0.18,2
	c0.74-0.06,1.36-0.12,2.05-0.18c-0.17-0.75-0.3-1.34-0.45-1.99C582.05,200.15,581.59,200.19,580.84,200.26z M555.04,364.8
	c0.18,0.84,0.33,1.5,0.5,2.28c0.65-0.14,1.23-0.26,1.86-0.39c-0.05-0.77-0.09-1.38-0.13-2.04
	C556.55,364.7,555.93,364.74,555.04,364.8z M585.67,550.04c0.24,0.84,0.39,1.4,0.56,2c0.7-0.07,1.22-0.12,1.83-0.18
	c-0.06-0.71-0.11-1.3-0.16-1.94C587.17,549.97,586.55,550,585.67,550.04z M551.95,554.77c-0.06-0.6-0.12-1.18-0.18-1.83
	c-0.72,0.11-1.23,0.18-1.84,0.27c0.13,0.75,0.23,1.34,0.34,1.98C550.91,555.04,551.36,554.92,551.95,554.77z M490.94,38.34
	c0.05,0.57,0.1,1.09,0.16,1.73c0.68-0.05,1.28-0.09,1.99-0.14c-0.09-0.7-0.17-1.28-0.25-1.93C492.14,38.13,491.6,38.22,490.94,38.34
	z M567.34,479.55c-0.83,0.04-1.48,0.07-2.24,0.11c0.17,0.74,0.29,1.26,0.43,1.89c0.66-0.06,1.25-0.12,1.95-0.19
	C567.44,480.75,567.4,480.29,567.34,479.55z M624.97,602.96c-0.07-0.71-0.13-1.31-0.24-2.35c-0.75,0.55-1.32,0.84-1.7,1.29
	c-0.17,0.2,0.05,0.72,0.1,1.22C623.8,603.07,624.33,603.02,624.97,602.96z M575.97,437.5c0.68-0.13,1.29-0.24,1.96-0.36
	c-0.03-0.6-0.06-1.14-0.09-1.72c-0.72,0-1.33,0-2.01,0C575.88,436.13,575.92,436.75,575.97,437.5z M592.9,482.83
	c-0.13-0.83-0.22-1.4-0.29-1.85c-0.78,0-1.37,0-2.15,0c0.18,0.72,0.33,1.29,0.49,1.95C591.58,482.9,592.1,482.87,592.9,482.83z
	 M646.32,598.97c-0.66,0.1-1.24,0.2-1.85,0.29c0.07,0.74,0.12,1.33,0.18,2.05c0.65-0.05,1.17-0.09,1.78-0.14
	C646.4,600.45,646.36,599.78,646.32,598.97z M591.06,569.99c0.81-0.18,1.46-0.33,2.16-0.49c-0.16-0.65-0.29-1.16-0.41-1.64
	c-0.63,0-1.15,0-1.75,0C591.06,568.55,591.06,569.14,591.06,569.99z M429.69,18.05c-0.72,0.13-1.29,0.24-1.99,0.37
	c0.06,0.8,0.12,1.48,0.18,2.26c0.72-0.16,1.3-0.3,1.92-0.44C429.76,19.46,429.73,18.84,429.69,18.05z M585.75,465.59
	c0.78-0.13,1.29-0.21,1.69-0.28c0-0.75,0-1.35,0-2.09c-0.66,0.14-1.17,0.25-1.69,0.36C585.75,464.25,585.75,464.79,585.75,465.59z
	 M571.73,431.97c0.27,0.87,0.44,1.44,0.62,2.05c0.69-0.1,1.21-0.17,1.64-0.24c0-0.69,0-1.22,0-1.81
	C573.26,431.97,572.65,431.97,571.73,431.97z M569.87,450.36c0.2,0.77,0.34,1.29,0.49,1.87c0.68-0.06,1.21-0.11,1.84-0.16
	c-0.06-0.79-0.1-1.44-0.15-2.12C571.27,450.1,570.67,450.21,569.87,450.36z M640.36,613.86c-0.72,0.18-1.29,0.32-1.94,0.48
	c0.1,0.67,0.18,1.19,0.27,1.77c0.64-0.05,1.17-0.09,1.8-0.15C640.44,615.24,640.4,614.65,640.36,613.86z M584.71,165.8
	c0.87-0.14,1.53-0.25,2.3-0.38c-0.17-0.66-0.29-1.17-0.44-1.75c-0.68,0.04-1.28,0.08-1.86,0.12
	C584.71,164.45,584.71,164.98,584.71,165.8z M544.99,263.06c-0.11-0.84-0.19-1.43-0.27-2.09c-0.82,0.13-1.49,0.23-2.21,0.34
	c0.19,0.7,0.33,1.22,0.47,1.75C543.63,263.06,544.17,263.06,544.99,263.06z M535.8,574.5c-0.09-0.81-0.16-1.41-0.24-2.08
	c-0.8,0.1-1.45,0.18-2.18,0.28c0.18,0.71,0.31,1.23,0.45,1.8C534.43,574.5,534.96,574.5,535.8,574.5z M590.04,407.37
	c-0.09-0.86-0.16-1.5-0.24-2.24c-0.78,0.19-1.36,0.33-2.04,0.49c0.13,0.67,0.22,1.2,0.33,1.75
	C588.67,407.37,589.2,407.37,590.04,407.37z M352.56,28.46c0.16,0.76,0.28,1.34,0.42,1.98c0.7-0.05,1.23-0.08,1.9-0.13
	c-0.07-0.79-0.12-1.44-0.18-2.11C353.97,28.29,353.42,28.36,352.56,28.46z M395.21,59.21c0.66-0.05,1.25-0.09,1.93-0.14
	c-0.09-0.73-0.16-1.32-0.23-1.99c-0.78,0.11-1.44,0.2-2.16,0.3C394.92,58.07,395.05,58.59,395.21,59.21z M648.62,609.54
	c0.19,0.79,0.34,1.38,0.5,2.05c0.63-0.08,1.15-0.15,1.83-0.24c-0.04-0.69-0.08-1.29-0.12-1.97
	C650.11,609.44,649.5,609.48,648.62,609.54z M641.49,583.51c-0.09-0.75-0.16-1.36-0.25-2.08c-0.82,0.13-1.49,0.24-2.19,0.36
	c0.13,0.69,0.22,1.21,0.31,1.72C640.11,583.51,640.66,583.51,641.49,583.51z M596.01,292.89c0.1,0.7,0.17,1.21,0.25,1.74
	c0.73,0,1.34,0,2.08,0c-0.12-0.72-0.24-1.37-0.35-2.01C597.32,292.71,596.78,292.79,596.01,292.89z M576.36,484.58
	c0.75-0.15,1.4-0.27,2.17-0.42c-0.09-0.62-0.16-1.15-0.24-1.73c-0.68,0-1.29,0-1.93,0C576.36,483.14,576.36,483.81,576.36,484.58z
	 M542.32,274.06c-0.13-0.76-0.22-1.29-0.33-1.93c-0.75,0.06-1.41,0.11-2.15,0.16c0.17,0.77,0.3,1.36,0.43,1.95
	C540.98,274.18,541.54,274.13,542.32,274.06z M584.16,342.62c-0.07-0.62-0.13-1.2-0.2-1.84c-0.75,0.05-1.35,0.08-2.04,0.13
	c0.15,0.7,0.28,1.28,0.43,1.97C582.99,342.78,583.51,342.71,584.16,342.62z M582,274.35c-0.1-0.87-0.16-1.45-0.24-2.13
	c-0.73,0.19-1.23,0.33-1.81,0.48c0.04,0.63,0.08,1.15,0.11,1.76C580.71,274.42,581.24,274.39,582,274.35z M567.43,566.26
	c-0.73,0.07-1.25,0.13-1.86,0.19c0.07,0.69,0.13,1.27,0.19,1.9c0.73-0.06,1.33-0.12,2.11-0.18
	C567.71,567.49,567.59,566.98,567.43,566.26z M564.34,445.7c-0.13-0.72-0.25-1.36-0.38-2.06c-0.69,0.11-1.2,0.2-1.77,0.29
	c0.03,0.67,0.06,1.19,0.09,1.87C563.01,445.77,563.62,445.74,564.34,445.7z M583.16,561.16c0.15,0.77,0.25,1.29,0.36,1.86
	c0.67-0.04,1.21-0.07,1.75-0.1c0-0.7,0-1.31,0-2.08C584.57,560.95,583.97,561.04,583.16,561.16z M567.43,107.49
	c-0.75,0.1-1.33,0.18-1.93,0.26c0.04,0.69,0.07,1.22,0.1,1.86c0.8-0.04,1.45-0.07,2.28-0.11
	C567.72,108.8,567.61,108.28,567.43,107.49z M594.66,544.26c-0.15-0.68-0.28-1.31-0.43-1.99c-0.7,0.05-1.22,0.1-1.84,0.14
	c0.04,0.71,0.08,1.31,0.12,1.98C593.23,544.34,593.85,544.31,594.66,544.26z M596.16,310.7c-0.76,0.2-1.37,0.36-2.07,0.55
	c0.08,0.58,0.16,1.09,0.23,1.61c0.76,0,1.39,0,2.08,0C596.33,312.18,596.26,311.57,596.16,310.7z M541.49,579.35
	c0.09,0.76,0.17,1.34,0.25,1.97c0.77-0.08,1.38-0.15,2.05-0.22c-0.19-0.73-0.34-1.31-0.5-1.94
	C542.7,579.22,542.23,579.27,541.49,579.35z M596.55,484.33c-0.71,0-1.31,0-2.03,0c0.04,0.73,0.08,1.39,0.12,2.12
	c0.75-0.14,1.34-0.25,2.01-0.38C596.62,485.46,596.59,484.93,596.55,484.33z M604.91,561.25c0.69-0.13,1.26-0.25,1.88-0.37
	c-0.08-0.65-0.14-1.1-0.2-1.63c-0.73,0.05-1.33,0.09-2.05,0.14C604.67,560.06,604.77,560.59,604.91,561.25z M594.11,583.84
	c0-0.78,0-1.36,0-2.07c-0.66,0.12-1.17,0.22-1.79,0.33c0.12,0.71,0.22,1.28,0.33,1.89C593.19,583.93,593.57,583.89,594.11,583.84z
	 M481.41,156.48c-0.15-0.7-0.25-1.21-0.38-1.81c-0.67,0.1-1.24,0.19-1.87,0.29c0.09,0.73,0.15,1.3,0.23,1.92
	C480.1,156.74,480.63,156.63,481.41,156.48z M592.69,562.51c-0.14-0.75-0.27-1.42-0.41-2.1c-0.71,0.1-1.22,0.17-1.83,0.25
	c0.08,0.74,0.15,1.31,0.21,1.85C591.35,562.51,591.9,562.51,592.69,562.51z M572.77,544.01c-0.76,0.12-1.29,0.2-1.91,0.3
	c0.16,0.71,0.28,1.28,0.41,1.86c0.71-0.07,1.22-0.12,1.8-0.18C572.97,545.28,572.89,544.75,572.77,544.01z M630.76,587.87
	c-0.05-0.71-0.1-1.35-0.16-2.04c-0.7,0.09-1.23,0.15-1.91,0.24c0.15,0.74,0.27,1.36,0.4,2
	C629.72,587.99,630.18,587.93,630.76,587.87z M585.86,259.71c0.77-0.19,1.4-0.35,2.04-0.51c-0.17-0.67-0.28-1.12-0.42-1.69
	c-0.62,0.11-1.14,0.2-1.72,0.3C585.79,258.45,585.82,258.99,585.86,259.71z M590.55,580.62c-0.15-0.77-0.25-1.28-0.37-1.84
	c-0.66,0.04-1.2,0.06-1.84,0.1c0.04,0.69,0.08,1.3,0.13,2.06C589.16,580.83,589.76,580.74,590.55,580.62z M510.58,560.15
	c0.14,0.86,0.24,1.54,0.36,2.32c0.74-0.25,1.31-0.44,1.91-0.65c-0.12-0.67-0.21-1.19-0.29-1.67
	C511.9,560.15,511.35,560.15,510.58,560.15z M588.01,367.9c0.71-0.12,1.35-0.23,2.08-0.35c-0.11-0.68-0.2-1.21-0.31-1.82
	c-0.67,0.06-1.25,0.11-1.87,0.16C587.95,366.62,587.98,367.17,588.01,367.9z M495.43,487.55c-0.18-1.65-0.18-1.65-1.98-1.38
	c0.05,0.52,0.11,1.05,0.18,1.7C494.23,487.76,494.75,487.67,495.43,487.55z M565.92,546.64c0-0.81,0-1.43,0-2.19
	c-0.75,0.18-1.41,0.34-2.09,0.51c0.12,0.67,0.21,1.18,0.3,1.68C564.78,546.64,565.25,546.64,565.92,546.64z M606.39,575.45
	c0.63-0.05,1.15-0.1,1.76-0.15c-0.05-0.7-0.1-1.29-0.15-1.93c-0.73,0.09-1.33,0.17-2.01,0.26
	C606.14,574.34,606.25,574.85,606.39,575.45z M580.55,447.67c0.72-0.05,1.32-0.1,2.02-0.15c-0.14-0.64-0.26-1.14-0.38-1.7
	c-0.7,0.09-1.22,0.15-1.79,0.22C580.46,446.64,580.5,447.09,580.55,447.67z M588.42,596.87c-0.8,0.14-1.38,0.24-2.03,0.35
	c0.12,0.71,0.21,1.22,0.31,1.79c0.7-0.05,1.24-0.09,1.85-0.13C588.51,598.23,588.47,597.69,588.42,596.87z M573.15,446.42
	c0.06,0.85,0.1,1.45,0.15,2.15c0.73-0.16,1.33-0.28,2.01-0.43c-0.09-0.66-0.16-1.18-0.24-1.72
	C574.44,446.42,573.91,446.42,573.15,446.42z M635.03,596.54c-0.67,0.08-1.24,0.15-1.83,0.21c0.04,0.71,0.06,1.25,0.1,1.88
	c0.68-0.1,1.26-0.18,1.96-0.28C635.17,597.69,635.1,597.17,635.03,596.54z M469.68,128.72c0.75-0.12,1.4-0.22,2.05-0.32
	c-0.37-1.66-0.37-1.66-2.19-1.38C469.59,127.52,469.63,128.04,469.68,128.72z M631.32,616.86c0.76-0.08,1.35-0.15,2.06-0.23
	c-0.1-0.7-0.18-1.22-0.27-1.85c-0.67,0.07-1.25,0.14-1.79,0.2C631.32,615.62,631.32,616.09,631.32,616.86z M456.44,33.94
	c-0.73,0.15-1.39,0.28-2.09,0.43c0.12,0.68,0.21,1.2,0.32,1.82c0.7-0.05,1.3-0.1,1.96-0.15C456.56,35.3,456.51,34.68,456.44,33.94z
	 M556.79,444.47c-0.61,0.08-1.12,0.15-1.68,0.22c0.03,0.63,0.05,1.16,0.08,1.79c0.72-0.07,1.3-0.13,2.01-0.21
	C557.05,445.6,556.93,445.1,556.79,444.47z M560.8,487.16c-0.75,0.22-1.26,0.37-1.88,0.56c0.09,0.58,0.17,1.09,0.25,1.62
	c0.7-0.05,1.22-0.09,1.76-0.13C560.89,488.48,560.85,487.94,560.8,487.16z M587.39,544.63c-0.13-0.75-0.23-1.27-0.33-1.86
	c-0.71,0.06-1.31,0.11-1.95,0.16c0.07,0.74,0.13,1.36,0.2,2.06C585.98,544.88,586.58,544.78,587.39,544.63z M545.27,84.14
	c0.09,0.7,0.17,1.26,0.25,1.86c0.78-0.06,1.39-0.11,2.12-0.16c-0.13-0.63-0.23-1.15-0.34-1.7
	C546.65,84.14,546.05,84.14,545.27,84.14z M598.72,489.67c0.73-0.07,1.31-0.13,2.01-0.21c-0.17-0.75-0.3-1.31-0.43-1.88
	c-0.62,0.05-1.07,0.09-1.58,0.13C598.72,488.33,598.72,488.85,598.72,489.67z M540.58,545.17c-0.06-0.86-0.1-1.46-0.15-2.15
	c-0.77,0.17-1.43,0.32-2.1,0.48c0.14,0.65,0.25,1.16,0.37,1.67C539.34,545.17,539.86,545.17,540.58,545.17z M580.36,424.25
	c-0.69,0.06-1.27,0.11-1.9,0.16c0.04,0.74,0.08,1.36,0.12,2.05c0.74-0.15,1.39-0.28,2.1-0.42
	C580.56,425.38,580.47,424.87,580.36,424.25z M583.23,581.1c-0.08-0.65-0.15-1.23-0.23-1.85c-0.71,0.04-1.26,0.07-1.91,0.1
	c0.11,0.69,0.21,1.28,0.33,2.01C582.06,581.27,582.58,581.19,583.23,581.1z M585.17,144.06c-0.06-0.82-0.1-1.46-0.15-2.17
	c-0.77,0.16-1.36,0.28-2.06,0.42c0.13,0.68,0.23,1.24,0.35,1.85C583.96,144.12,584.42,144.1,585.17,144.06z M515.41,424.36
	c0-0.74,0-1.27,0-1.93c-0.75,0.13-1.41,0.24-2.16,0.37c0.2,0.68,0.35,1.19,0.52,1.77C514.34,424.5,514.8,424.44,515.41,424.36z
	 M591.03,607.62c0.07,0.58,0.14,1.15,0.23,1.81c0.66-0.11,1.16-0.19,1.73-0.28c-0.06-0.67-0.12-1.19-0.18-1.89
	C592.18,607.38,591.67,607.49,591.03,607.62z M560.74,447.41c-0.64,0.12-1.16,0.21-1.81,0.33c0.13,0.67,0.24,1.25,0.38,1.94
	c0.59-0.07,1.1-0.13,1.66-0.2C560.89,448.72,560.82,448.12,560.74,447.41z M582.83,573.95c-0.13-0.77-0.22-1.28-0.33-1.89
	c-0.72,0.17-1.29,0.3-1.96,0.46c0.14,0.58,0.25,1.01,0.35,1.43C581.51,573.95,582.03,573.95,582.83,573.95z M569.45,445.1
	c0.82-0.13,1.45-0.23,2.1-0.33c-0.12-0.66-0.21-1.11-0.31-1.65c-0.63,0.05-1.22,0.09-1.87,0.14
	C569.4,443.84,569.42,444.3,569.45,445.1z M353.96,44.91c0.76-0.07,1.38-0.13,2.17-0.21c-0.13-0.72-0.24-1.29-0.36-1.94
	c-0.69,0.05-1.23,0.09-1.81,0.14C353.96,43.55,353.96,44.09,353.96,44.91z M563.03,389.58c-0.71,0.11-1.17,0.19-1.73,0.28
	c0.06,0.59,0.1,1.12,0.16,1.75c0.69-0.04,1.27-0.07,1.9-0.11C563.24,390.81,563.15,390.28,563.03,389.58z M578.95,489.59
	c-1.9,0.07-1.9,0.07-1.65,1.82c0.65-0.05,1.3-0.1,2.02-0.16C579.18,490.62,579.08,490.17,578.95,489.59z M589.34,340.39
	c0.07,0.72,0.12,1.24,0.17,1.77c0.72,0,1.31,0,2.06,0c-0.14-0.7-0.25-1.28-0.37-1.86C590.58,340.33,590.11,340.36,589.34,340.39z
	 M576.5,442.57c0.09,0.77,0.17,1.35,0.24,1.99c0.7-0.06,1.24-0.11,1.94-0.18c-0.1-0.68-0.19-1.25-0.28-1.81
	C577.76,442.57,577.29,442.57,576.5,442.57z M348.58,43.42c-0.65,0.08-1.24,0.16-1.89,0.24c0.05,0.7,0.09,1.24,0.14,1.89
	c0.74-0.18,1.4-0.35,2.06-0.52C348.78,44.44,348.69,43.98,348.58,43.42z M490.4,177.26c-0.1-0.52-0.19-1.02-0.3-1.62
	c-0.71,0.09-1.29,0.16-1.94,0.23c0.09,0.68,0.16,1.2,0.23,1.77c0.48-0.05,0.87-0.07,1.26-0.14
	C489.87,177.48,490.08,177.37,490.4,177.26z M615.09,596.43c1.83-0.3,1.83-0.3,1.66-2.05c-0.58,0.04-1.16,0.09-1.85,0.14
	C614.97,595.21,615.02,595.73,615.09,596.43z M541.59,539.49c0,0.85,0,1.47,0,2.19c0.75-0.15,1.41-0.28,2.14-0.43
	c-0.12-0.69-0.21-1.21-0.31-1.77C542.8,539.49,542.27,539.49,541.59,539.49z M577.55,596.27c-0.13-0.79-0.22-1.3-0.38-2.21
	c-0.68,0.53-1.22,0.82-1.57,1.27c-0.14,0.18,0.1,0.68,0.17,1.04C576.38,596.34,576.83,596.31,577.55,596.27z M588.59,539.05
	c0.03,0.7,0.06,1.29,0.09,2.02c0.77-0.12,1.41-0.23,2.09-0.34c-0.13-0.7-0.22-1.21-0.33-1.8
	C589.76,538.98,589.23,539.01,588.59,539.05z M612.1,600.42c0.67-0.05,1.25-0.09,1.93-0.15c-0.13-0.71-0.23-1.28-0.34-1.9
	c-0.69,0.1-1.2,0.17-1.81,0.26C611.96,599.28,612.03,599.8,612.1,600.42z M578.03,126.36c0.63-0.03,1.21-0.06,1.91-0.09
	c-0.07-0.68-0.12-1.19-0.19-1.84c-0.75,0.11-1.39,0.21-2.11,0.32C577.79,125.38,577.9,125.83,578.03,126.36z M569.64,423.63
	c-0.16-0.72-0.3-1.35-0.45-1.99c-0.65,0.1-1.1,0.17-1.67,0.26c0.07,0.64,0.13,1.21,0.19,1.83
	C568.41,423.7,568.95,423.67,569.64,423.63z M584.51,261.11c-0.72,0.55-1.84-0.09-1.79,1.28c0.01,0.3,0.1,0.6,0.17,1.01
	c0.63-0.09,1.14-0.17,1.62-0.24C584.51,262.49,584.51,261.96,584.51,261.11z M597.2,491.43c-0.77,0.08-1.34,0.14-1.96,0.2
	c0.03,0.68,0.06,1.21,0.09,1.88c0.76-0.09,1.4-0.17,2.06-0.25C597.33,492.61,597.28,492.15,597.2,491.43z M588.22,286.2
	c0.16,0.72,0.28,1.23,0.41,1.84c0.61-0.04,1.14-0.07,1.77-0.1c-0.05-0.71-0.09-1.3-0.14-1.94
	C589.59,286.07,589.04,286.12,588.22,286.2z M595.77,453.67c0.61-0.06,1.13-0.12,1.74-0.18c-0.07-0.73-0.13-1.32-0.2-1.98
	c-0.75,0.09-1.34,0.16-1.99,0.24C595.5,452.51,595.62,453.04,595.77,453.67z M558.01,540.18c-0.1-0.74-0.17-1.25-0.26-1.89
	c-0.64,0.08-1.16,0.15-1.69,0.22c0,0.6,0,1.13,0,1.77C556.67,540.24,557.19,540.22,558.01,540.18z M548.46,286.15
	c0.1,0.67,0.19,1.26,0.29,1.96c0.71-0.07,1.3-0.13,1.99-0.19c-0.13-0.74-0.23-1.32-0.34-1.95
	C549.69,286.03,549.15,286.09,548.46,286.15z M599.93,480.37c-0.79,0.08-1.38,0.15-2,0.21c0.07,0.65,0.13,1.17,0.19,1.71
	c0.73,0,1.33,0,2.07,0C600.11,481.67,600.04,481.15,599.93,480.37z M419.1,44.49c0.09,0.75,0.15,1.28,0.22,1.91
	c0.75-0.13,1.4-0.24,2.08-0.36c-0.12-0.68-0.21-1.19-0.32-1.8C420.39,44.33,419.87,44.39,419.1,44.49z M589.87,573.46
	c0-0.72,0-1.32,0-2.06c-0.74,0.15-1.38,0.29-2.06,0.43c0.12,0.67,0.2,1.18,0.3,1.76C588.8,573.54,589.32,573.5,589.87,573.46z
	 M502.05,185.42c-0.76,0.08-1.4,0.14-2.14,0.22c0.17,0.66,0.3,1.15,0.44,1.69c0.68-0.04,1.19-0.06,1.84-0.1
	C502.14,186.57,502.1,186.06,502.05,185.42z M631.67,577.04c0.62-0.07,1.15-0.12,1.66-0.18c0-0.73,0-1.4,0-2.15
	c-0.74,0.13-1.33,0.23-1.97,0.35C631.49,575.81,631.57,576.34,631.67,577.04z M598.15,440.68c0.04,0.69,0.07,1.28,0.11,2.02
	c0.7-0.09,1.26-0.17,1.89-0.26c-0.07-0.76-0.12-1.35-0.18-2C599.31,440.53,598.8,440.6,598.15,440.68z M542.5,548.6
	c0.67-0.05,1.26-0.09,1.94-0.14c-0.09-0.72-0.16-1.3-0.24-1.93c-0.74,0.08-1.33,0.14-2.01,0.21
	C542.31,547.46,542.4,547.98,542.5,548.6z M495.38,507.33c0.04,0.79,0.07,1.41,0.1,2.15c0.73-0.12,1.37-0.23,2.06-0.35
	c-0.09-0.71-0.16-1.23-0.23-1.8C496.71,507.33,496.17,507.33,495.38,507.33z M639.26,599.71c-0.84,0.21-1.4,0.35-1.98,0.49
	c0.13,0.61,0.22,1.03,0.32,1.53c0.66-0.09,1.17-0.16,1.77-0.24C639.33,600.9,639.31,600.45,639.26,599.71z M650.48,604.23
	c-0.06-0.71-0.12-1.3-0.18-2c-0.76,0.14-1.41,0.27-2.15,0.41c0.17,0.65,0.3,1.14,0.45,1.7C649.25,604.3,649.78,604.27,650.48,604.23
	z M630.1,600.51c0.1,0.74,0.17,1.25,0.25,1.85c0.65-0.05,1.17-0.08,1.78-0.13c-0.04-0.71-0.07-1.31-0.1-1.96
	C631.4,600.34,630.86,600.41,630.1,600.51z M583.49,375.3c-0.12-0.78-0.2-1.3-0.29-1.91c-0.67,0.04-1.21,0.07-1.86,0.1
	c0.05,0.71,0.1,1.31,0.14,1.95C582.17,375.39,582.72,375.35,583.49,375.3z M531.12,564.17c-0.09-0.65-0.18-1.24-0.27-1.87
	c-0.66,0.07-1.12,0.13-1.62,0.18c0,0.67,0,1.26,0,1.94C529.91,564.34,530.45,564.26,531.12,564.17z M593.88,537.03
	c-0.11-0.8-0.18-1.39-0.27-2c-0.62,0.07-1.08,0.11-1.68,0.18c0.04,0.69,0.08,1.29,0.13,1.97
	C592.67,537.13,593.14,537.09,593.88,537.03z M350.7,46.73c0.07,0.84,0.13,1.45,0.19,2.17c0.67-0.12,1.26-0.23,1.89-0.35
	c-0.09-0.71-0.17-1.31-0.26-1.99C351.9,46.62,351.37,46.67,350.7,46.73z M652.84,612.92c0.08,0.81,0.14,1.46,0.21,2.16
	c0.7-0.14,1.22-0.24,1.79-0.36c-0.07-0.79-0.13-1.41-0.19-2.06C654.06,612.75,653.6,612.81,652.84,612.92z M397.45,46.21
	c0.11,0.81,0.19,1.35,0.27,1.96c0.68-0.04,1.28-0.08,2.06-0.13c-0.13-0.7-0.24-1.28-0.37-1.93
	C398.75,46.15,398.22,46.17,397.45,46.21z M561.48,496.37c-0.08-0.74-0.14-1.25-0.21-1.87c-0.7,0.14-1.23,0.24-1.82,0.36
	c0.03,0.61,0.06,1.14,0.09,1.79C560.18,496.56,560.71,496.48,561.48,496.37z M591.95,497.44c0.84-0.12,1.44-0.2,2.1-0.3
	c-0.11-0.74-0.19-1.27-0.28-1.9c-0.69,0.07-1.29,0.12-1.82,0.18C591.95,496.13,591.95,496.67,591.95,497.44z M586.88,145.55
	c0.09,0.61,0.17,1.18,0.26,1.83c0.72-0.08,1.3-0.15,1.97-0.22c-0.11-0.7-0.19-1.21-0.28-1.75
	C588.15,145.45,587.6,145.49,586.88,145.55z M351.95,39.44c-0.68,0.04-1.28,0.08-1.98,0.12c0.11,0.71,0.21,1.29,0.31,1.95
	c0.71-0.08,1.3-0.15,1.95-0.22C352.12,40.56,352.04,40.04,351.95,39.44z M581.07,346.53c-0.12-0.75-0.2-1.26-0.29-1.87
	c-0.68,0.12-1.2,0.22-1.84,0.34c0.04,0.59,0.08,1.11,0.12,1.68C579.74,346.62,580.28,346.58,581.07,346.53z M468.51,32.76
	c0.08,0.81,0.14,1.43,0.21,2.16c0.72-0.15,1.37-0.28,2.07-0.42c-0.12-0.67-0.22-1.19-0.32-1.74
	C469.83,32.76,469.3,32.76,468.51,32.76z M385.06,47.55c-0.64,0.04-1.23,0.08-1.92,0.13c0.06,0.7,0.11,1.23,0.17,1.91
	c0.75-0.14,1.39-0.26,2.11-0.39C385.28,48.56,385.18,48.1,385.06,47.55z M605.38,608.25c0.7-0.12,1.25-0.21,1.88-0.31
	c-0.1-0.71-0.17-1.22-0.24-1.78c-0.65,0.03-1.17,0.06-1.79,0.08C605.28,606.94,605.32,607.53,605.38,608.25z M422.92,47.65
	c0.09,0.87,0.16,1.46,0.22,2.12c0.74-0.1,1.34-0.17,2.1-0.27c-0.14-0.71-0.26-1.28-0.39-1.94C424.2,47.59,423.67,47.61,422.92,47.65
	z M546.72,282.44c-0.74,0.16-1.41,0.3-2.14,0.46c0.12,0.68,0.21,1.21,0.32,1.83c0.69-0.06,1.29-0.12,1.91-0.18
	C546.78,283.79,546.75,283.16,546.72,282.44z M627.38,589.94c-0.65,0.06-1.23,0.11-1.91,0.18c0.07,0.65,0.13,1.18,0.19,1.76
	c0.71-0.06,1.3-0.11,2.05-0.17C627.6,591.05,627.5,590.54,627.38,589.94z M571.14,564.24c-0.13-0.64-0.23-1.14-0.36-1.76
	c-0.63,0.04-1.21,0.07-1.9,0.11c0.08,0.66,0.14,1.17,0.21,1.74C569.8,564.3,570.4,564.27,571.14,564.24z M548.25,531.86
	c0.07,0.6,0.13,1.18,0.2,1.79c0.76-0.05,1.34-0.09,2.01-0.14c-0.14-0.74-0.24-1.27-0.37-1.91
	C549.46,531.69,548.93,531.76,548.25,531.86z M579.44,203.95c-0.8,0.09-1.38,0.16-2.04,0.23c0.09,0.73,0.16,1.31,0.23,1.94
	c0.73-0.08,1.33-0.14,2.06-0.22C579.6,205.24,579.53,204.72,579.44,203.95z M604.64,599.05c0.09,0.6,0.18,1.17,0.29,1.85
	c0.64-0.04,1.16-0.08,1.78-0.12c-0.05-0.71-0.09-1.31-0.13-1.96C605.91,598.9,605.37,598.96,604.64,599.05z M420.35,76.68
	c-0.75,0.13-1.39,0.24-2.09,0.36c0.17,0.71,0.3,1.23,0.45,1.86c0.6-0.05,1.12-0.1,1.63-0.15C420.35,78.04,420.35,77.44,420.35,76.68
	z M472.88,38.15c0.78-0.11,1.34-0.19,1.94-0.28c-0.05-0.76-0.09-1.36-0.13-2.07c-0.77,0.2-1.42,0.36-2.04,0.53
	C472.72,36.95,472.78,37.41,472.88,38.15z M576.98,364.82c1.89-0.1,1.89-0.1,1.63-1.84c-0.63,0.05-1.28,0.11-2,0.17
	C576.75,363.79,576.86,364.24,576.98,364.82z M563.44,498.02c0.08,0.63,0.15,1.15,0.24,1.78c0.69-0.08,1.27-0.15,1.93-0.23
	c-0.07-0.71-0.13-1.25-0.19-1.89C564.73,497.8,564.14,497.9,563.44,498.02z M588.09,385.89c-0.06-0.64-0.12-1.26-0.19-1.99
	c-0.75,0.1-1.34,0.17-1.95,0.25c0.07,0.66,0.12,1.18,0.18,1.74C586.82,385.89,587.35,385.89,588.09,385.89z M579.26,265.42
	c0.11,0.75,0.19,1.27,0.28,1.86c0.66-0.12,1.17-0.21,1.77-0.32c-0.09-0.68-0.17-1.21-0.25-1.78
	C580.47,265.26,580,265.33,579.26,265.42z M609.25,571.76c0.74-0.1,1.34-0.17,2.1-0.27c-0.12-0.68-0.22-1.23-0.33-1.85
	c-0.69,0.06-1.21,0.1-1.78,0.15C609.25,570.41,609.25,570.94,609.25,571.76z M596.11,476.84c-0.82,0.15-1.42,0.26-2.1,0.39
	c0.11,0.67,0.2,1.19,0.31,1.82c0.67-0.07,1.26-0.14,1.79-0.19C596.11,478.16,596.11,477.61,596.11,476.84z M590.85,332.95
	c-0.74,0.16-1.37,0.29-2,0.43c0.09,0.66,0.15,1.12,0.22,1.69c0.67-0.06,1.25-0.12,1.78-0.16
	C590.85,334.2,590.85,333.66,590.85,332.95z M597.64,498.66c-0.66,0.05-1.26,0.09-1.92,0.14c0.05,0.74,0.09,1.36,0.13,2.04
	c0.71-0.13,1.31-0.25,2.04-0.39C597.8,499.82,597.73,499.3,597.64,498.66z M401.41,49.57c0.05,0.83,0.09,1.48,0.13,2.21
	c0.76-0.17,1.35-0.3,2.08-0.46c-0.13-0.66-0.24-1.22-0.37-1.83C402.62,49.51,402.16,49.53,401.41,49.57z M643.35,565.22
	c-0.08-0.69-0.15-1.29-0.23-2.01c-0.69,0.12-1.22,0.2-1.86,0.31c0.08,0.66,0.15,1.24,0.23,1.86
	C642.2,565.33,642.74,565.28,643.35,565.22z M564.52,572.05c-0.07-0.8-0.12-1.34-0.17-1.94c-0.66,0.07-1.2,0.13-1.87,0.2
	c0.05,0.62,0.1,1.16,0.15,1.74C563.23,572.05,563.77,572.05,564.52,572.05z M603.37,478.37c-0.12-0.74-0.2-1.26-0.3-1.89
	c-0.61,0.07-1.12,0.13-1.79,0.21c0.11,0.65,0.2,1.21,0.29,1.79C602.21,478.44,602.66,478.41,603.37,478.37z M533.74,218.74
	c-0.77,0.12-1.28,0.19-1.89,0.28c0.05,0.63,0.09,1.16,0.14,1.79c0.68-0.05,1.27-0.1,1.91-0.15
	C533.84,220.02,533.8,219.49,533.74,218.74z M578.87,138.81c0.08,0.8,0.13,1.43,0.2,2.17c0.72-0.15,1.37-0.29,2.07-0.44
	c-0.11-0.67-0.2-1.19-0.28-1.73C580.2,138.81,579.66,138.81,578.87,138.81z M578.59,218.5c0.14,0.77,0.24,1.3,0.36,1.92
	c0.67-0.03,1.26-0.06,2-0.09c-0.13-0.74-0.23-1.31-0.32-1.82C579.93,218.5,579.39,218.5,578.59,218.5z M378.33,31.94
	c0.78-0.11,1.36-0.19,2-0.28c-0.07-0.76-0.13-1.36-0.19-2.02c-0.75,0.09-1.36,0.17-2.1,0.27C378.13,30.57,378.21,31.1,378.33,31.94z
	 M577.38,427.87c-0.76,0.18-1.42,0.34-2.11,0.5c0.13,0.67,0.23,1.18,0.35,1.76c0.71-0.07,1.24-0.12,1.76-0.17
	C577.38,429.22,577.38,428.6,577.38,427.87z M578.09,379.39c0.81-0.14,1.39-0.24,2.03-0.35c-0.11-0.71-0.19-1.23-0.28-1.83
	c-0.71,0.07-1.3,0.12-1.93,0.18C577.97,378.06,578.02,378.61,578.09,379.39z M595.91,458.92c0.13,0.71,0.24,1.28,0.36,1.93
	c0.69-0.05,1.22-0.08,1.85-0.13c-0.04-0.71-0.07-1.32-0.1-1.98C597.3,458.8,596.69,458.86,595.91,458.92z M629.41,595.19
	c0.7-0.06,1.29-0.11,2.01-0.17c-0.08-0.68-0.15-1.21-0.22-1.85c-0.66,0.05-1.25,0.1-1.89,0.15
	C629.35,594.01,629.38,594.55,629.41,595.19z M618.34,569.21c-1.95,0.07-1.95,0.07-1.65,1.79c0.61-0.05,1.22-0.1,1.91-0.15
	C618.51,570.27,618.44,569.81,618.34,569.21z M587.29,400.1c0.63-0.04,1.22-0.08,1.93-0.12c-0.11-0.73-0.19-1.31-0.28-1.92
	c-0.68,0.09-1.21,0.15-1.83,0.23C587.17,398.91,587.22,399.45,587.29,400.1z M558.82,529.12c0.65,0,1.25,0,1.99,0
	c-0.09-0.65-0.17-1.24-0.27-1.98c-0.67,0.14-1.24,0.26-1.83,0.38C558.75,528.14,558.78,528.6,558.82,529.12z M500.55,192.61
	c0.2,0.83,0.33,1.4,0.48,2.04c0.66-0.14,1.16-0.25,1.66-0.36c-0.04-0.69-0.07-1.22-0.11-1.86
	C501.89,192.49,501.37,192.54,500.55,192.61z M282.91,76.38c-0.04-0.71-0.07-1.31-0.11-2.05c-0.7,0.1-1.26,0.19-1.88,0.28
	c0.08,0.77,0.13,1.37,0.2,2.01C281.75,76.54,282.27,76.47,282.91,76.38z M514.89,563.54c0,0.82,0,1.42,0,2.16
	c0.68-0.17,1.23-0.31,1.81-0.46c-0.07-0.68-0.13-1.19-0.19-1.77C515.89,563.49,515.44,563.51,514.89,563.54z M609.65,595.06
	c-0.76,0.08-1.29,0.13-1.89,0.19c0.05,0.71,0.09,1.31,0.14,1.98c0.72-0.13,1.31-0.24,1.92-0.35
	C609.75,596.22,609.71,595.76,609.65,595.06z M538.21,585.3c0.84-0.14,1.44-0.24,2.09-0.35c-0.11-0.72-0.19-1.24-0.29-1.88
	c-0.69,0.07-1.28,0.13-1.81,0.19C538.21,583.97,538.21,584.52,538.21,585.3z M527.86,399.93c-0.58,0-0.98-0.02-1.36,0.01
	c-0.22,0.02-0.44,0.13-0.65,0.21c0.32,1.62,0.32,1.62,2.1,1.34C527.92,401.05,527.9,400.6,527.86,399.93z M540.62,527.05
	c0.66-0.07,1.24-0.14,1.9-0.22c-0.12-0.7-0.2-1.22-0.3-1.82c-0.74,0.07-1.35,0.14-2.03,0.2
	C540.35,525.87,540.47,526.4,540.62,527.05z M625.51,610.12c-0.11-0.72-0.19-1.24-0.29-1.85c-0.64,0.1-1.15,0.18-1.78,0.28
	c0.08,0.68,0.16,1.26,0.23,1.86C624.31,610.32,624.77,610.24,625.51,610.12z M586.57,329.75c-0.66,0.07-1.24,0.13-1.76,0.18
	c0,0.71,0,1.25,0,1.91c0.67-0.09,1.26-0.18,1.97-0.28C586.71,330.92,586.65,330.4,586.57,329.75z M589.5,526.46
	c-0.06-0.82-0.11-1.43-0.17-2.14c-0.78,0.18-1.43,0.34-2.11,0.5c0.17,0.68,0.3,1.19,0.43,1.73
	C588.32,526.52,588.78,526.5,589.5,526.46z M593.02,549.66c0.14,0.7,0.25,1.27,0.38,1.93c0.74-0.1,1.31-0.18,1.94-0.26
	c-0.09-0.75-0.17-1.35-0.25-2.02C594.36,549.43,593.77,549.53,593.02,549.66z M405.41,53.03c0.14,0.65,0.24,1.16,0.36,1.73
	c0.66-0.06,1.2-0.1,1.84-0.16c-0.05-0.7-0.1-1.29-0.15-1.95C406.73,52.79,406.13,52.9,405.41,53.03z M594.63,524.19
	c0.05,0.61,0.1,1.2,0.16,1.9c0.71-0.06,1.31-0.1,1.98-0.16c-0.09-0.73-0.16-1.33-0.25-2.01
	C595.86,524.02,595.31,524.1,594.63,524.19z M602.94,579.72c0.81-0.13,1.32-0.21,1.94-0.31c-0.16-0.7-0.28-1.21-0.42-1.79
	c-0.58,0.07-1.04,0.12-1.52,0.18C602.94,578.4,602.94,578.93,602.94,579.72z M545.32,268.18c-0.75,0.09-1.28,0.16-1.91,0.23
	c0.09,0.79,0.15,1.39,0.22,2.02c0.67-0.08,1.2-0.14,1.79-0.22C545.39,269.49,545.36,268.95,545.32,268.18z M397.21,41.02
	c0.65-0.06,1.17-0.11,1.81-0.17c-0.04-0.72-0.08-1.32-0.12-2.05c-0.75,0.13-1.33,0.24-1.91,0.34
	C397.07,39.83,397.14,40.37,397.21,41.02z M597.28,475.42c0.77-0.18,1.44-0.33,2.15-0.49c-0.15-0.66-0.26-1.18-0.39-1.76
	c-0.7,0.06-1.23,0.11-1.76,0.15C597.28,474.07,597.28,474.69,597.28,475.42z M594.91,612.83c0.82-0.05,1.41-0.09,2.09-0.14
	c-0.13-0.73-0.23-1.31-0.36-2.01c-0.67,0.1-1.19,0.18-1.73,0.26C594.91,611.54,594.91,612.07,594.91,612.83z M585.14,270.41
	c-0.11-0.74-0.19-1.3-0.27-1.91c-0.64,0.12-1.1,0.2-1.67,0.31c0.04,0.63,0.07,1.21,0.11,1.87
	C583.92,270.6,584.37,270.53,585.14,270.41z M585.57,402.22c-0.69,0.04-1.14,0.06-1.67,0.09c0,0.72,0,1.32,0,2
	c0.68-0.09,1.28-0.17,2.04-0.28C585.81,403.41,585.71,402.9,585.57,402.22z M478.02,34.08c-0.13-0.73-0.22-1.25-0.33-1.89
	c-0.68,0.06-1.28,0.1-1.83,0.15c0,0.77,0,1.4,0,2.11C476.54,34.34,477.15,34.23,478.02,34.08z M590.08,241.22
	c-0.07-0.68-0.14-1.33-0.2-1.97c-1.63-0.11-2.22,0.46-1.7,1.97C588.77,241.22,589.38,241.22,590.08,241.22z M564.34,113.27
	c-0.11-0.72-0.2-1.24-0.3-1.87c-0.68,0.06-1.28,0.12-1.83,0.17c0,0.76,0,1.39,0,2.1C562.89,113.54,563.49,113.43,564.34,113.27z
	 M628.03,598.85c-0.07-0.64-0.13-1.21-0.2-1.89c-0.71,0.07-1.29,0.13-1.93,0.2c0.11,0.72,0.19,1.3,0.29,1.95
	C626.85,599.01,627.38,598.94,628.03,598.85z M590.56,522.9c0.69-0.11,1.3-0.22,2.08-0.35c-0.12-0.68-0.21-1.24-0.32-1.88
	c-0.7,0.07-1.23,0.11-1.75,0.16C590.56,521.58,590.56,522.2,590.56,522.9z M569.97,405.43c1.82-0.2,1.82-0.2,1.32-2.1
	C569.92,403.31,569.41,403.92,569.97,405.43z M599.59,520.23c-0.72,0.07-1.3,0.13-1.95,0.2c0.09,0.66,0.16,1.17,0.25,1.79
	c0.7-0.15,1.27-0.26,1.9-0.4C599.73,521.31,599.68,520.93,599.59,520.23z M552.93,522.4c-0.09-0.69-0.17-1.27-0.25-1.92
	c-0.71,0.16-1.22,0.27-1.76,0.38c0.09,0.64,0.16,1.11,0.24,1.67C551.74,522.49,552.27,522.45,552.93,522.4z M583.87,570.36
	c0.66-0.1,1.26-0.19,2-0.31c-0.08-0.64-0.15-1.17-0.22-1.72c-0.67,0-1.27,0-1.96,0C583.75,569.05,583.81,569.67,583.87,570.36z
	 M573.66,471.71c-0.76,0.04-1.3,0.07-1.96,0.11c0.04,0.82,0.07,1.49,0.1,2.3c0.78-0.23,1.43-0.41,2.07-0.6
	C573.8,472.89,573.74,472.42,573.66,471.71z M579.47,225.71c0.19,1.72,0.19,1.72,2.06,1.41c-0.07-0.51-0.14-1.03-0.23-1.66
	C580.67,225.55,580.14,225.62,579.47,225.71z M584.78,371.62c0.78-0.07,1.38-0.13,2.09-0.2c-0.11-0.7-0.19-1.23-0.29-1.88
	c-0.67,0.08-1.25,0.15-1.81,0.22C584.78,370.39,584.78,370.86,584.78,371.62z M589.59,360.43c-0.11-0.76-0.19-1.35-0.28-2.01
	c-0.68,0.1-1.21,0.17-1.88,0.27c0.06,0.62,0.12,1.15,0.18,1.74C588.21,360.43,588.75,360.43,589.59,360.43z M401.72,69.65
	c-0.14-0.7-0.25-1.27-0.38-1.96c-0.7,0.08-1.28,0.15-1.91,0.23c0.07,0.75,0.13,1.35,0.2,2C400.34,69.83,400.94,69.75,401.72,69.65z
	 M584.73,555.75c-0.11-0.73-0.19-1.24-0.27-1.83c-0.73,0.09-1.34,0.16-2.03,0.24c0.16,0.64,0.29,1.14,0.44,1.69
	C583.49,555.82,583.96,555.79,584.73,555.75z M578.94,471.38c0.09,0.73,0.15,1.24,0.23,1.89c0.71-0.18,1.28-0.32,1.96-0.48
	c-0.15-0.59-0.25-1.02-0.37-1.5C580.17,471.32,579.7,471.34,578.94,471.38z M643.64,612.02c-0.07-0.65-0.12-1.24-0.19-1.91
	c-0.67,0.15-1.18,0.26-1.76,0.39c0.04,0.67,0.07,1.19,0.12,1.86C642.49,612.23,643,612.13,643.64,612.02z M580.76,465.99
	c-0.17-0.74-0.31-1.31-0.46-1.98c-0.66,0.08-1.19,0.15-1.81,0.22c0.06,0.66,0.11,1.25,0.17,1.88
	C579.39,466.08,579.99,466.04,580.76,465.99z M559.88,583.21c0.8-0.08,1.33-0.13,1.93-0.19c-0.07-0.7-0.13-1.29-0.2-1.98
	c-0.69,0.1-1.21,0.18-1.73,0.26C559.88,581.92,559.88,582.46,559.88,583.21z M378.81,37.12c0.04,0.73,0.07,1.44,0.11,2.22
	c0.75-0.15,1.27-0.26,1.89-0.38c-0.04-0.7-0.07-1.3-0.11-2.05C380.03,36.98,379.51,37.04,378.81,37.12z M582.78,407.98
	c-0.08-0.78-0.14-1.37-0.2-2.03c-0.7,0.1-1.23,0.17-1.91,0.26c0.09,0.67,0.17,1.25,0.25,1.88
	C581.57,408.05,582.04,408.02,582.78,407.98z M500.16,519.84c0.76-0.11,1.33-0.19,1.98-0.28c-0.16-0.64-0.28-1.09-0.39-1.54
	c-0.61,0-1.14,0-1.79,0C500.03,518.6,500.08,519.06,500.16,519.84z M608.53,580.74c-0.81,0.05-1.41,0.08-2.1,0.13
	c0.17,0.74,0.3,1.32,0.45,1.95c0.68-0.11,1.19-0.19,1.78-0.28C608.61,581.92,608.58,581.46,608.53,580.74z M588.84,519.23
	c-0.14-0.71-0.26-1.28-0.39-1.91c-0.68,0.04-1.22,0.07-1.87,0.11c0.07,0.69,0.12,1.29,0.19,1.96
	C587.47,519.33,588.07,519.29,588.84,519.23z M547.05,519.44c0.78-0.13,1.28-0.22,1.82-0.31c-0.11-0.65-0.19-1.1-0.28-1.67
	c-0.58,0.07-1.08,0.14-1.53,0.19C547.05,518.27,547.05,518.73,547.05,519.44z M616.53,610.81c0.96-0.06,1.78,0.08,1.67-1.03
	c-0.03-0.35-0.14-0.69-0.22-1.11c-0.58,0.14-1.02,0.24-1.44,0.34C616.53,609.63,616.53,610.14,616.53,610.81z M580.44,319.62
	c0.04,0.66,0.07,1.25,0.12,1.94c0.67-0.14,1.17-0.25,1.77-0.37c-0.11-0.67-0.2-1.2-0.29-1.8
	C581.48,319.47,581.02,319.54,580.44,319.62z M567.83,508.31c0.06,0.75,0.1,1.42,0.16,2.18c0.69-0.13,1.26-0.23,1.92-0.35
	c-0.08-0.84-0.14-1.5-0.2-2.19C569.03,508.09,568.51,508.19,567.83,508.31z M588,304.34c-0.67,0.05-1.26,0.1-1.93,0.15
	c0.11,0.72,0.2,1.29,0.3,1.92c0.7-0.11,1.22-0.19,1.86-0.28C588.15,305.47,588.08,304.95,588,304.34z M579.76,604.81
	c0.14,0.77,0.26,1.42,0.38,2.1c0.63-0.14,1.08-0.24,1.62-0.36c-0.06-0.63-0.12-1.17-0.17-1.74
	C580.96,604.81,580.43,604.81,579.76,604.81z M591.82,553.27c-0.78,0.06-1.39,0.11-2.19,0.17c0.14,0.72,0.26,1.3,0.38,1.95
	c0.68-0.05,1.21-0.09,1.8-0.14C591.82,554.61,591.82,554.07,591.82,553.27z M593.78,516.94c0.09,0.78,0.16,1.37,0.23,2.01
	c0.7-0.07,1.24-0.13,1.93-0.2c-0.1-0.68-0.19-1.26-0.27-1.81C595.02,516.94,594.56,516.94,593.78,516.94z M619.76,606.98
	c0.65-0.07,1.17-0.12,1.8-0.19c-0.05-0.67-0.1-1.25-0.15-1.92c-0.66,0.12-1.17,0.21-1.77,0.32
	C619.68,605.8,619.71,606.31,619.76,606.98z M613.53,553.34c-0.05-0.88-0.09-1.49-0.13-2.21c-0.78,0.2-1.44,0.36-2.13,0.54
	c0.12,0.66,0.22,1.18,0.31,1.67C612.26,553.34,612.8,553.34,613.53,553.34z M526.2,407.09c0.24,0.79,0.42,1.36,0.61,1.98
	c0.66-0.14,1.17-0.25,1.75-0.37c-0.11-0.63-0.2-1.14-0.28-1.61C527.6,407.09,527.06,407.09,526.2,407.09z M568.43,409.22
	c-0.13-0.84-0.22-1.42-0.33-2.07c-0.67,0.16-1.17,0.27-1.75,0.41c0.1,0.71,0.17,1.22,0.23,1.66
	C567.21,409.22,567.68,409.22,568.43,409.22z M574.63,233.21c-0.76,0.05-1.29,0.09-1.95,0.13c0.06,0.78,0.11,1.42,0.17,2.16
	c0.77-0.18,1.36-0.32,1.94-0.46C574.73,234.39,574.69,233.92,574.63,233.21z M582.1,359.01c-0.78,0.05-1.44,0.1-2.18,0.15
	c0.2,0.72,0.34,1.24,0.5,1.85c0.64-0.05,1.16-0.08,1.82-0.13C582.18,360.21,582.14,359.68,582.1,359.01z M609.27,611.57
	c0.78-0.05,1.39-0.09,2.2-0.14c-0.17-0.73-0.3-1.31-0.46-2.01c-0.64,0.07-1.17,0.13-1.74,0.2
	C609.27,610.24,609.27,610.77,609.27,611.57z M576.14,231.48c0.76-0.06,1.41-0.11,2.16-0.17c-0.16-0.72-0.27-1.23-0.41-1.86
	c-0.65,0.05-1.23,0.1-1.75,0.14C576.14,230.28,576.14,230.82,576.14,231.48z M597.28,515.06c0.79-0.04,1.4-0.07,2.2-0.11
	c-0.17-0.72-0.3-1.29-0.46-1.99c-0.68,0.11-1.24,0.21-1.83,0.3C597.22,513.88,597.24,514.35,597.28,515.06z M577.27,469.85
	c-0.14-0.78-0.23-1.33-0.34-1.95c-0.71,0.05-1.23,0.09-1.84,0.14c0.05,0.7,0.09,1.3,0.14,1.94
	C575.86,469.94,576.4,469.91,577.27,469.85z M582.31,382.62c0.77-0.07,1.3-0.13,1.89-0.18c-0.05-0.72-0.09-1.32-0.13-2
	c-0.72,0.13-1.31,0.24-1.93,0.35C582.2,381.45,582.25,381.92,582.31,382.62z M601.34,581.35c-0.72,0.13-1.27,0.23-1.9,0.35
	c0.04,0.66,0.06,1.17,0.1,1.87c0.71-0.13,1.27-0.24,1.89-0.36C601.4,582.56,601.38,582.05,601.34,581.35z M526.21,568
	c0.53,0,1.12,0,1.87,0c-0.07-0.75-0.12-1.4-0.18-2.02C526.4,565.96,525.73,566.47,526.21,568z M402.82,63.99
	c0.04,0.7,0.08,1.3,0.12,2.03c0.74-0.12,1.32-0.21,1.91-0.31c-0.08-0.69-0.14-1.22-0.21-1.88C404,63.89,403.48,63.93,402.82,63.99z
	 M577.48,411.95c0.78-0.11,1.36-0.2,1.98-0.29c-0.09-0.68-0.15-1.21-0.23-1.8c-0.69,0.05-1.29,0.09-2.02,0.14
	C577.3,410.64,577.37,411.16,577.48,411.95z M590.2,327.74c-0.09-0.66-0.16-1.18-0.25-1.83c-0.66,0.08-1.24,0.15-1.84,0.22
	c0.04,0.63,0.07,1.09,0.11,1.61C588.9,327.74,589.49,327.74,590.2,327.74z M526.75,513.91c-0.08-0.69-0.14-1.14-0.21-1.71
	c-0.57,0.05-1.08,0.1-1.66,0.15c0.07,0.61,0.12,1.06,0.17,1.56C525.59,513.91,526.04,513.91,526.75,513.91z M581.32,599.38
	c-0.08-0.75-0.14-1.27-0.21-1.86c-0.7,0.04-1.3,0.07-1.99,0.11c0.15,0.68,0.27,1.24,0.4,1.83
	C580.16,599.44,580.61,599.42,581.32,599.38z M501.25,512.44c-0.05-0.7-0.08-1.15-0.12-1.67c-0.65,0-1.18,0-1.75,0
	c0,0.66,0,1.25,0,1.98C500.03,512.64,500.55,512.55,501.25,512.44z M605.24,566.4c0.14,0.77,0.23,1.29,0.34,1.91
	c0.69-0.08,1.28-0.15,1.92-0.22c-0.11-0.66-0.19-1.18-0.28-1.69C606.56,566.4,606.03,566.4,605.24,566.4z M578.77,260.05
	c0.79-0.08,1.66,0.19,1.67-0.95c0-0.3-0.1-0.6-0.18-0.98c-0.63,0.07-1.14,0.12-1.78,0.19C578.59,258.98,578.67,259.5,578.77,260.05z
	 M594.2,289.4c-0.81,0.08-1.35,0.13-1.97,0.19c0.08,0.66,0.14,1.18,0.2,1.7c0.65,0,1.18,0,1.87,0
	C594.26,290.66,594.24,290.13,594.2,289.4z M586.64,411.13c-0.08-0.65-0.15-1.16-0.22-1.74c-0.71,0.04-1.31,0.08-2.04,0.13
	c0.11,0.62,0.2,1.14,0.31,1.74C585.35,411.21,585.94,411.18,586.64,411.13z M579.19,431.56c0.11,0.76,0.19,1.28,0.28,1.9
	c0.69-0.06,1.29-0.11,1.94-0.17c-0.11-0.67-0.19-1.2-0.27-1.73C580.52,431.56,579.99,431.56,579.19,431.56z M572.07,576.57
	c-0.81,0.07-1.4,0.13-2.1,0.19c0.17,0.67,0.29,1.18,0.45,1.8c0.61-0.09,1.12-0.17,1.65-0.25
	C572.07,577.72,572.07,577.25,572.07,576.57z M579.78,232.63c0.17,0.86,0.29,1.44,0.42,2.1c0.7-0.23,1.21-0.39,1.73-0.55
	c-0.05-0.59-0.09-1.05-0.14-1.55C581.13,232.63,580.59,232.63,579.78,232.63z M584.13,322.67c0.16,0.8,0.26,1.3,0.38,1.88
	c0.69-0.08,1.21-0.14,1.79-0.21c-0.07-0.67-0.13-1.19-0.2-1.83C585.44,322.57,584.91,322.61,584.13,322.67z M527.99,486.8
	c-0.55,0.03-1.06,0.06-1.66,0.09c0.05,0.61,0.1,1.07,0.14,1.53c0.61,0,1.12,0,1.72,0C528.11,487.82,528.06,487.36,527.99,486.8z
	 M566.93,559.05c-0.65,0.09-1.17,0.16-1.79,0.25c0.06,0.65,0.12,1.17,0.18,1.81c0.69-0.07,1.27-0.13,1.9-0.19
	C567.11,560.23,567.03,559.71,566.93,559.05z M553.65,408.53c-0.61,0.04-1.13,0.08-1.72,0.12c0.11,0.68,0.19,1.19,0.29,1.77
	c0.64-0.12,1.09-0.2,1.59-0.29C553.75,409.54,553.71,409.08,553.65,408.53z M588.11,512.09c-0.09-0.73-0.15-1.25-0.22-1.86
	c-0.66,0.09-1.18,0.15-1.78,0.23c0.06,0.65,0.12,1.18,0.18,1.78C586.89,512.19,587.35,512.15,588.11,512.09z M360.75,37.07
	c0.75-0.12,1.26-0.2,1.85-0.3c-0.11-0.68-0.19-1.19-0.28-1.74c-0.64,0.08-1.15,0.15-1.72,0.22
	C360.66,35.9,360.69,36.35,360.75,37.07z M565.07,434.53c0.6,0,1.18,0,1.74,0c0-0.66,0-1.2,0-1.9c-0.69,0.16-1.25,0.29-1.83,0.42
	C565.02,433.61,565.04,434,565.07,434.53z M555.42,469.87c-0.65,0.06-1.16,0.11-1.76,0.17c0.09,0.65,0.16,1.16,0.25,1.8
	c0.68-0.08,1.26-0.15,1.89-0.22C555.67,471.01,555.57,470.57,555.42,469.87z M613.31,593.12c-0.15-0.69-0.26-1.25-0.39-1.87
	c-0.71,0.07-1.21,0.12-1.8,0.18c0.07,0.68,0.12,1.2,0.18,1.79C612,593.19,612.6,593.15,613.31,593.12z M553.4,487.95
	c-0.72,0.32-1.16,0.51-1.61,0.71c0.2,0.44,0.3,0.98,0.62,1.29c0.18,0.17,0.71-0.01,1.19-0.05
	C553.54,489.3,553.5,488.84,553.4,487.95z M584.64,389.65c-0.04-0.65-0.06-1.17-0.1-1.81c-0.67,0.05-1.25,0.1-1.89,0.14
	c0.07,0.67,0.12,1.19,0.18,1.83C583.47,389.75,583.99,389.71,584.64,389.65z M534.21,558.6c-1.65,0.2-1.65,0.2-1.36,1.83
	c0.51-0.06,1.03-0.12,1.62-0.19C534.38,559.65,534.31,559.2,534.21,558.6z M568.15,595.15c0.14,0.69,0.24,1.2,0.35,1.77
	c0.62-0.1,1.13-0.19,1.71-0.29c-0.1-0.68-0.18-1.18-0.26-1.76C569.34,594.97,568.88,595.04,568.15,595.15z M535.61,408.3
	c-0.08-0.63-0.15-1.14-0.23-1.76c-0.66,0.1-1.17,0.17-1.74,0.26c0.11,0.67,0.2,1.18,0.29,1.75
	C534.58,408.45,535.04,408.38,535.61,408.3z M574.77,256.88c0.78-0.11,1.35-0.18,1.95-0.27c-0.11-0.64-0.19-1.09-0.27-1.55
	c-0.6,0-1.11,0-1.68,0C574.77,255.64,574.77,256.09,574.77,256.88z M585.18,602.7c-0.1-0.76-0.18-1.28-0.24-1.73
	c-0.66,0-1.19,0-1.86,0c0.07,0.64,0.13,1.16,0.2,1.73C583.86,602.7,584.38,602.7,585.18,602.7z M573.15,424.91
	c-0.7,0.07-1.28,0.13-1.91,0.19c0.06,0.77,0.11,1.31,0.16,1.94c0.77-0.19,1.42-0.36,2.08-0.53
	C573.36,425.89,573.26,425.43,573.15,424.91z M607.3,588.03c0.04,0.65,0.07,1.18,0.11,1.84c0.68-0.05,1.27-0.09,1.94-0.14
	c-0.08-0.67-0.15-1.2-0.21-1.7C608.49,588.03,607.96,588.03,607.3,588.03z M589.71,260.75c0.11,0.66,0.22,1.24,0.33,1.9
	c0.66-0.06,1.16-0.11,1.72-0.16c-0.08-0.73-0.14-1.26-0.21-1.9C590.92,260.65,590.38,260.69,589.71,260.75z M499.47,470.91
	c0.11,0.66,0.2,1.16,0.3,1.78c0.58-0.05,1.1-0.09,1.73-0.15c-0.07-0.65-0.13-1.15-0.19-1.71
	C500.65,470.86,500.13,470.88,499.47,470.91z M566.5,575.47c0.68-0.07,1.27-0.12,1.94-0.19c-0.14-0.67-0.25-1.18-0.37-1.75
	c-0.66,0.04-1.19,0.08-1.83,0.12C566.34,574.31,566.41,574.83,566.5,575.47z M587.59,320.7c0.64-0.07,1.23-0.13,1.88-0.21
	c-0.08-0.66-0.14-1.18-0.2-1.64c-0.66,0-1.18,0-1.85,0C587.49,319.5,587.53,320.03,587.59,320.7z M508.89,432.37
	c-0.13-0.8-0.22-1.37-0.32-2c-0.62,0.09-1.07,0.15-1.64,0.23c0.06,0.66,0.11,1.25,0.17,1.89
	C507.69,432.45,508.15,432.42,508.89,432.37z M542.86,573.95c-0.06-0.65-0.11-1.17-0.17-1.81c-0.69,0.04-1.28,0.07-1.94,0.1
	c0.1,0.67,0.18,1.19,0.28,1.82C541.65,574.02,542.18,573.99,542.86,573.95z M602.18,611.97c0.67-0.06,1.26-0.11,1.92-0.17
	c-0.08-0.66-0.15-1.18-0.23-1.82c-0.65,0.06-1.24,0.12-1.91,0.19C602.04,610.83,602.1,611.35,602.18,611.97z M502.78,508.6
	c0.7,0,1.23,0,1.89,0c-0.09-0.64-0.16-1.14-0.25-1.75c-0.67,0.09-1.18,0.17-1.64,0.23C502.78,507.63,502.78,508.02,502.78,508.6z
	 M584.66,348.01c-0.64,0.06-1.23,0.11-1.89,0.16c0.08,0.68,0.15,1.2,0.2,1.66c0.66,0,1.19,0,1.88,0
	C584.79,349.18,584.73,348.66,584.66,348.01z M576.84,236.74c0.09,0.73,0.16,1.24,0.24,1.85c0.65-0.04,1.17-0.08,1.82-0.12
	c-0.11-0.68-0.19-1.19-0.28-1.81C578.06,236.69,577.6,236.71,576.84,236.74z M588.54,351.32c-0.75,0.08-1.28,0.14-1.88,0.21
	c0.08,0.67,0.15,1.19,0.23,1.82c0.71-0.09,1.3-0.16,1.91-0.24C588.7,352.48,588.64,352.01,588.54,351.32z M598.44,505.83
	c-0.79,0.05-1.33,0.08-2.02,0.12c0.13,0.71,0.22,1.28,0.33,1.9c0.72-0.04,1.25-0.07,1.91-0.11
	C598.59,507.06,598.53,506.54,598.44,505.83z M562.24,590.2c-0.05-0.65-0.1-1.17-0.15-1.79c-0.69,0.04-1.22,0.08-1.83,0.12
	c0.06,0.72,0.11,1.32,0.16,1.98C561.07,590.39,561.59,590.3,562.24,590.2z M556.59,587c0.66-0.06,1.25-0.11,1.91-0.16
	c-0.09-0.66-0.15-1.18-0.23-1.8c-0.67,0.05-1.26,0.09-1.92,0.14C556.43,585.84,556.5,586.37,556.59,587z M506.15,465.11
	c0.79-0.09,1.3-0.15,1.88-0.21c-0.1-0.64-0.17-1.14-0.26-1.73c-0.67,0.08-1.18,0.14-1.71,0.2
	C506.09,463.98,506.11,464.43,506.15,465.11z M590.87,585.95c-0.71,0.07-1.23,0.12-1.83,0.19c0.07,0.68,0.12,1.21,0.18,1.81
	c0.7-0.03,1.31-0.06,2.06-0.1C591.14,587.21,591.03,586.69,590.87,585.95z M574.31,415.73c0.73-0.07,1.19-0.11,1.74-0.16
	c-0.05-0.69-0.09-1.28-0.14-1.94c-0.66,0.07-1.17,0.13-1.81,0.2C574.17,414.48,574.23,415,574.31,415.73z M606.04,593.63
	c-0.11-0.76-0.19-1.27-0.28-1.89c-0.69,0.06-1.27,0.12-1.92,0.18c0.11,0.66,0.19,1.18,0.28,1.71
	C604.73,593.63,605.26,593.63,606.04,593.63z M534.62,542.28c0.72-0.11,1.32-0.21,2.02-0.32c-0.17-0.67-0.3-1.2-0.46-1.83
	c-0.61,0.11-1.12,0.21-1.68,0.31C534.54,541.1,534.58,541.63,534.62,542.28z M556.01,392.03c-0.1-0.83-0.17-1.41-0.25-2.02
	c-0.68,0.11-1.21,0.2-1.86,0.3c0.12,0.68,0.21,1.19,0.3,1.72C554.79,392.03,555.24,392.03,556.01,392.03z M589.72,300.56
	c0,0.59,0,1.17,0,1.87c0.65-0.05,1.23-0.1,1.87-0.14c-0.08-0.66-0.15-1.18-0.22-1.72C590.79,300.56,590.33,300.56,589.72,300.56z
	 M522.74,423.84c-0.08-0.65-0.15-1.23-0.24-1.93c-0.66,0.17-1.16,0.29-1.76,0.44c0.13,0.67,0.23,1.16,0.33,1.68
	C521.68,423.95,522.15,423.9,522.74,423.84z M587.25,566.46c0.83-0.13,1.4-0.23,2-0.32c-0.1-0.67-0.18-1.18-0.27-1.77
	c-0.68,0.04-1.2,0.07-1.83,0.11C587.18,565.16,587.21,565.68,587.25,566.46z M588.95,293.35c0.04,0.66,0.08,1.27,0.13,2
	c0.73-0.13,1.24-0.21,1.82-0.31c-0.08-0.63-0.14-1.15-0.2-1.69C590.11,293.35,589.64,293.35,588.95,293.35z M536.34,415.54
	c-0.08-0.69-0.14-1.15-0.2-1.63c-0.57,0-1.09,0-1.74,0c0.07,0.63,0.13,1.13,0.19,1.63C535.19,415.54,535.64,415.54,536.34,415.54z
	 M529.81,403.31c0.1,0.76,0.17,1.27,0.25,1.87c0.7-0.1,1.21-0.17,1.8-0.25c-0.11-0.64-0.19-1.14-0.29-1.71
	C530.97,403.25,530.52,403.27,529.81,403.31z M626.59,604.32c0.17,0.75,0.28,1.27,0.42,1.88c0.67-0.07,1.19-0.12,1.8-0.18
	c-0.09-0.68-0.16-1.2-0.22-1.7C627.91,604.32,627.37,604.32,626.59,604.32z M586.92,311.59c0.09,0.74,0.15,1.25,0.23,1.9
	c0.66-0.08,1.24-0.15,1.88-0.22c-0.11-0.68-0.19-1.18-0.27-1.68C588.15,311.59,587.68,311.59,586.92,311.59z M614.65,589.2
	c0.75-0.17,1.76,0.47,1.8-0.9c0.01-0.28-0.13-0.57-0.22-0.95c-0.64,0.1-1.16,0.18-1.73,0.27
	C614.55,588.18,614.59,588.65,614.65,589.2z M548.21,551.65c-0.08-0.65-0.14-1.15-0.2-1.69c-0.68,0.03-1.21,0.06-1.9,0.1
	c0.11,0.65,0.19,1.15,0.29,1.73C547.02,551.73,547.55,551.69,548.21,551.65z M524.87,452.46c-0.05-1.62-0.05-1.62-1.67-1.49
	c0.06,0.51,0.12,1.01,0.19,1.6C523.98,452.52,524.43,452.49,524.87,452.46z M526.37,425.39c-0.67,0-1.19,0-1.81,0
	c0.1,0.63,0.18,1.14,0.27,1.76c0.63-0.1,1.12-0.17,1.63-0.25C526.43,426.37,526.41,425.99,526.37,425.39z M536.66,522.18
	c0.04,0.74,0.07,1.21,0.1,1.79c0.69-0.05,1.27-0.09,1.93-0.13c-0.12-0.67-0.2-1.18-0.3-1.76
	C537.76,522.11,537.31,522.14,536.66,522.18z M567.45,461.51c0.03,0.62,0.05,1.15,0.08,1.82c0.67-0.08,1.18-0.14,1.8-0.21
	c-0.04-0.61-0.07-1.13-0.11-1.79C568.56,461.39,568.05,461.44,567.45,461.51z M634.15,603.82c0,0.58,0,1.16,0,1.86
	c0.67-0.04,1.26-0.08,1.93-0.13c-0.11-0.66-0.2-1.18-0.29-1.73C635.21,603.82,634.75,603.82,634.15,603.82z M590.54,269.81
	c0.65-0.06,1.24-0.12,1.89-0.18c-0.1-0.7-0.17-1.23-0.26-1.82c-0.73,0.07-1.35,0.12-2.03,0.18
	C590.28,268.63,590.4,269.16,590.54,269.81z M570.72,251.64c0.11,0.78,0.18,1.3,0.25,1.87c0.66-0.04,1.19-0.07,1.84-0.11
	c-0.07-0.69-0.12-1.21-0.18-1.76C572.03,251.64,571.51,251.64,570.72,251.64z M562.75,577.43c0.2,0.79,0.33,1.36,0.49,1.98
	c0.67-0.14,1.18-0.24,1.75-0.35c-0.08-0.62-0.15-1.13-0.22-1.63C564.11,577.43,563.59,577.43,562.75,577.43z M557.66,451.64
	c-0.64,0.05-1.22,0.1-1.87,0.15c0.07,0.67,0.12,1.19,0.19,1.82c0.67-0.04,1.26-0.08,1.92-0.12
	C557.81,452.81,557.74,452.28,557.66,451.64z M587.1,582.54c-0.71,0.08-1.22,0.14-1.88,0.21c0.07,0.69,0.13,1.25,0.19,1.87
	c0.68-0.04,1.2-0.07,1.88-0.1C587.23,583.86,587.18,583.34,587.1,582.54z M579.6,393.65c0.8-0.1,1.31-0.16,1.87-0.23
	c-0.07-0.67-0.13-1.19-0.2-1.83c-0.69,0.12-1.21,0.2-1.76,0.29C579.54,392.47,579.56,392.93,579.6,393.65z M575.47,242.35
	c-0.06-0.65-0.1-1.16-0.16-1.8c-0.68,0.03-1.27,0.06-1.93,0.09c0.1,0.66,0.19,1.18,0.28,1.8
	C574.28,242.41,574.8,242.39,575.47,242.35z M516.89,525.39c-0.08-0.69-0.13-1.14-0.2-1.7c-0.61,0.06-1.12,0.11-1.67,0.17
	c0.07,0.61,0.12,1.07,0.19,1.65C515.73,525.47,516.18,525.44,516.89,525.39z M557.7,572.8c-0.49-0.76-0.75-1.32-1.16-1.7
	c-0.17-0.15-0.7,0.1-1.1,0.18c0.04,0.6,0.08,1.06,0.11,1.52C556.18,572.8,556.71,572.8,557.7,572.8z M591.66,575.04
	c0.11,0.64,0.2,1.15,0.3,1.74c0.64-0.06,1.17-0.11,1.83-0.17c-0.09-0.65-0.17-1.15-0.25-1.69
	C592.86,574.97,592.32,575,591.66,575.04z M636.5,592.81c0.13,0.78,0.22,1.29,0.32,1.88c0.72-0.06,1.32-0.11,1.97-0.17
	c-0.14-0.63-0.26-1.16-0.38-1.71C637.81,592.81,637.29,592.81,636.5,592.81z M539.1,570.61c-0.12-0.75-0.2-1.26-0.28-1.73
	c-0.68,0-1.22,0-1.9,0c0.12,0.7,0.21,1.26,0.32,1.87C537.9,570.7,538.36,570.67,539.1,570.61z M505.91,441.67c-0.7,0-1.21,0-1.82,0
	c0.04,0.71,0.07,1.31,0.11,2.01c0.64-0.12,1.15-0.21,1.71-0.31C505.91,442.82,505.91,442.35,505.91,441.67z M563.61,584.65
	c0.08,0.74,0.14,1.25,0.21,1.83c0.65-0.03,1.18-0.06,1.83-0.09c-0.1-0.67-0.18-1.18-0.26-1.73
	C564.81,584.65,564.35,584.65,563.61,584.65z M547.47,524.71c0.1,0.67,0.18,1.2,0.26,1.74c0.66,0,1.27,0,1.99,0
	c-0.09-0.67-0.16-1.19-0.25-1.83C548.79,524.65,548.18,524.68,547.47,524.71z M580.27,213.08c-0.19-0.8-0.31-1.32-0.44-1.89
	c-0.65,0.06-1.17,0.1-1.74,0.15c0.07,0.8,0.12,1.39,0.18,2.03C579.01,213.26,579.55,213.18,580.27,213.08z M565.63,400.32
	c0.08,0.65,0.15,1.21,0.22,1.73c1.65,0.09,1.94-0.61,1.4-1.98C566.76,400.15,566.26,400.23,565.63,400.32z M560.27,442.15
	c0.23-1.81-0.69-1.63-1.75-1.28C558.36,442.26,558.93,442.64,560.27,442.15z M637.28,619.92c-0.12-0.63-0.23-1.14-0.35-1.77
	c-0.64,0.06-1.23,0.12-1.84,0.18c0.05,0.62,0.09,1.09,0.13,1.59C635.93,619.92,636.53,619.92,637.28,619.92z M334.68,28.5
	c0.76-0.06,1.35-0.11,2.01-0.17c-0.09-0.67-0.15-1.2-0.24-1.88c-0.71,0.07-1.35,0.13-2.01,0.19
	C334.52,27.3,334.58,27.76,334.68,28.5z M502.27,461.85c0.81-0.1,1.32-0.17,1.93-0.25c-0.09-0.71-0.15-1.23-0.22-1.82
	c-0.69,0.05-1.22,0.09-1.71,0.12C502.27,460.54,502.27,461.08,502.27,461.85z M561.76,436.77c0.14,0.85-0.21,1.87,0.99,1.66
	c1.25-0.22,0.75-1.06,0.51-1.83C562.75,436.66,562.36,436.71,561.76,436.77z M542.73,281.27c-0.08-0.69-0.15-1.33-0.23-2
	c-0.73,0.07-1.26,0.12-1.95,0.18c0.18,0.74,0.32,1.32,0.47,1.95C541.62,281.36,542.09,281.32,542.73,281.27z M581.73,586.57
	c0.18,0.8,0.29,1.31,0.42,1.9c0.69-0.07,1.21-0.13,1.79-0.2c-0.09-0.67-0.16-1.19-0.23-1.7
	C583.06,586.57,582.53,586.57,581.73,586.57z M515.46,550.82c-0.09-0.69-0.15-1.15-0.23-1.71c-0.62,0.07-1.13,0.13-1.69,0.19
	c0.08,0.62,0.14,1.07,0.22,1.66C514.3,550.92,514.75,550.88,515.46,550.82z M537.66,536.14c0.11,0.74,0.19,1.27,0.29,1.91
	c0.66-0.04,1.26-0.08,1.94-0.12c-0.1-0.67-0.18-1.2-0.27-1.79C539.03,536.14,538.5,536.14,537.66,536.14z M587.35,338.85
	c0-0.59,0-1.17,0-1.87c-0.65,0.05-1.24,0.09-1.88,0.14c0.09,0.66,0.16,1.18,0.23,1.73C586.28,338.85,586.75,338.85,587.35,338.85z
	 M610.61,604.18c-0.1-0.77-0.17-1.28-0.25-1.85c-0.64,0.03-1.16,0.05-1.8,0.08c0.06,0.68,0.11,1.2,0.17,1.77
	C609.3,604.18,609.82,604.18,610.61,604.18z M583.83,608.17c0,0.79,0,1.39,0,2.15c0.68-0.14,1.25-0.25,1.94-0.39
	c-0.1-0.69-0.17-1.27-0.26-1.86C584.91,608.1,584.46,608.13,583.83,608.17z M589.69,280.75c-0.1-0.72-0.17-1.24-0.26-1.87
	c-0.69,0.09-1.27,0.16-1.88,0.24c0.09,0.63,0.15,1.1,0.22,1.64C588.37,280.75,588.89,280.75,589.69,280.75z M558.28,460.52
	c-0.04-0.66-0.08-1.12-0.12-1.71c-0.7,0.06-1.3,0.12-1.94,0.17c0.08,0.67,0.15,1.18,0.22,1.77
	C557.11,460.67,557.57,460.61,558.28,460.52z M612.55,607.43c1.83-0.19,1.83-0.19,1.44-1.75c-0.51,0.03-1.03,0.06-1.66,0.09
	C612.42,606.43,612.49,606.93,612.55,607.43z M579.9,398.97c0.13,0.77,0.22,1.28,0.32,1.87c0.69-0.1,1.2-0.17,1.76-0.25
	c-0.09-0.66-0.16-1.16-0.23-1.73C581.08,398.9,580.63,398.93,579.9,398.97z M583.34,315.44c0.23,0.77,0.39,1.32,0.57,1.94
	c0.66-0.11,1.17-0.19,1.74-0.29c-0.1-0.64-0.18-1.15-0.26-1.65C584.73,315.44,584.2,315.44,583.34,315.44z M586.92,537.49
	c-0.2-0.83-0.34-1.39-0.48-1.99c-0.69,0.12-1.21,0.21-1.78,0.3c0.1,0.64,0.18,1.16,0.26,1.69
	C585.54,537.49,586.07,537.49,586.92,537.49z M587.69,613.35c0.77-0.07,1.42-0.13,2.13-0.19c-0.16-0.66-0.27-1.16-0.4-1.71
	c-0.69,0.03-1.21,0.06-1.86,0.09C587.6,612.21,587.64,612.72,587.69,613.35z M574.84,422.9c0.68-0.04,1.29-0.08,2-0.13
	c-0.1-0.66-0.19-1.2-0.29-1.84c-0.66,0.04-1.26,0.07-1.96,0.11C574.68,421.73,574.75,422.27,574.84,422.9z M562.58,550.38
	c-0.04-0.73-0.07-1.2-0.1-1.73c-0.72,0-1.32,0-2.07,0c0.15,0.68,0.27,1.24,0.41,1.83C561.47,550.44,561.92,550.42,562.58,550.38z
	 M572.24,600.32c0.75-0.09,1.26-0.15,1.87-0.23c-0.08-0.65-0.14-1.16-0.22-1.76c-0.65,0.08-1.15,0.15-1.74,0.22
	C572.18,599.16,572.2,599.61,572.24,600.32z M589.64,595.15c0.79-0.15,1.31-0.26,1.92-0.38c-0.06-0.54-0.11-1-0.17-1.58
	c-0.66,0.06-1.25,0.11-1.86,0.16C589.57,593.97,589.6,594.44,589.64,595.15z M575.9,453.46c-0.87,0.12-1.45,0.21-2.07,0.29
	c0.12,0.68,0.22,1.19,0.32,1.8c0.68-0.04,1.2-0.08,1.86-0.12C575.97,454.76,575.94,454.23,575.9,453.46z M584.21,364.37
	c0.73-0.05,1.24-0.09,1.87-0.14c-0.06-0.68-0.11-1.2-0.16-1.84c-0.69,0.07-1.28,0.12-1.89,0.18
	C584.09,363.18,584.13,363.64,584.21,364.37z M615.95,582.11c-0.13-0.75-0.22-1.27-0.31-1.75c-0.65,0-1.17,0-1.83,0
	c0.07,0.65,0.13,1.17,0.19,1.75C614.61,582.11,615.14,582.11,615.95,582.11z M575.58,263.78c1.87-0.05,1.87-0.05,1.44-1.69
	c-0.53,0.07-1.07,0.14-1.67,0.21C575.44,262.85,575.51,263.32,575.58,263.78z M629.49,613.31c-0.08-0.76-0.14-1.29-0.21-1.94
	c-0.69,0.11-1.27,0.2-1.92,0.3c0.13,0.67,0.23,1.18,0.35,1.75C628.32,613.37,628.78,613.35,629.49,613.31z M584.48,575.48
	c0.09,0.74,0.16,1.26,0.24,1.9c0.63-0.04,1.2-0.08,1.86-0.12c-0.05-0.68-0.1-1.2-0.14-1.77
	C585.83,575.48,585.3,575.48,584.48,575.48z M502.14,501.64c0.74-0.12,1.26-0.2,1.81-0.28c-0.12-0.74,0.25-1.62-0.9-1.64
	c-0.29-0.01-0.58,0.08-0.91,0.12C502.14,500.41,502.14,500.86,502.14,501.64z M585.81,591.89c0.83-0.13,1.4-0.23,2-0.32
	c-0.1-0.67-0.18-1.18-0.27-1.76c-0.68,0.04-1.2,0.07-1.82,0.11C585.74,590.59,585.77,591.11,585.81,591.89z M521.93,478.24
	c0.73-0.32,1.18-0.51,1.62-0.7c-0.19-0.45-0.3-0.98-0.62-1.3c-0.17-0.17-0.71,0.02-1.18,0.05
	C521.81,476.89,521.85,477.34,521.93,478.24z M593.17,527.89c-0.68,0.06-1.29,0.11-1.97,0.17c0.09,0.65,0.15,1.18,0.24,1.83
	c0.65-0.05,1.23-0.1,1.88-0.15C593.27,529.06,593.23,528.52,593.17,527.89z M588.62,499.16c0.09,0.72,0.18,1.39,0.28,2.16
	c0.66-0.11,1.19-0.19,1.86-0.3c-0.15-0.72-0.28-1.36-0.4-1.99C589.69,499.08,589.22,499.11,588.62,499.16z M478.73,41.4
	c-0.1-0.76-0.17-1.29-0.26-1.98c-0.71,0.06-1.35,0.12-2.05,0.19c0.13,0.72,0.23,1.25,0.32,1.79C477.4,41.4,477.93,41.4,478.73,41.4z
	 M578.59,384.55c0.17,0.75,0.29,1.27,0.43,1.87c0.67-0.07,1.19-0.12,1.81-0.18c-0.09-0.68-0.16-1.2-0.23-1.69
	C579.92,384.55,579.38,384.55,578.59,384.55z M533.21,439.35c0.03,1.61,0.03,1.61,1.67,1.5c-0.07-0.51-0.13-1.01-0.21-1.6
	C534.1,439.28,533.65,439.31,533.21,439.35z M585.9,298.97c0.63,0,1.15,0,1.84,0c-0.08-0.69-0.15-1.26-0.23-1.91
	c-0.71,0.09-1.23,0.16-1.84,0.24C585.75,297.93,585.82,298.45,585.9,298.97z M561.24,575.88c-0.13-0.8-0.21-1.32-0.31-1.91
	c-0.73,0.07-1.35,0.12-2.06,0.18c0.22,0.7,0.39,1.27,0.57,1.85C560.06,575.96,560.51,575.93,561.24,575.88z M579.96,438.78
	c0.04,0.67,0.08,1.24,0.12,1.91c0.66-0.06,1.18-0.11,1.83-0.17c-0.12-0.69-0.22-1.25-0.33-1.85
	C580.98,438.71,580.52,438.74,579.96,438.78z M594.95,298.56c-0.17-0.79-0.3-1.35-0.43-1.95c-0.63,0.08-1.07,0.13-1.61,0.2
	c0.07,0.68,0.12,1.2,0.17,1.75C593.67,298.56,594.14,298.56,594.95,298.56z M585.49,396.85c-0.16-0.68-0.3-1.24-0.45-1.87
	c-0.69,0.12-1.19,0.21-1.71,0.3c0.05,0.6,0.09,1.06,0.13,1.57C584.17,396.85,584.76,396.85,585.49,396.85z M579.72,456.84
	c-0.81,0.12-1.38,0.2-1.99,0.28c0.12,0.68,0.21,1.19,0.31,1.77c0.7-0.03,1.22-0.06,1.89-0.09
	C579.85,458.15,579.8,457.63,579.72,456.84z M588.55,248.64c0.8-0.14,1.32-0.24,1.95-0.35c-0.07-0.65-0.13-1.17-0.2-1.84
	c-0.65,0.11-1.17,0.19-1.75,0.28C588.55,247.35,588.55,247.89,588.55,248.64z M630.7,607.64c0.04,0.66,0.08,1.27,0.13,2
	c0.73-0.13,1.24-0.21,1.82-0.31c-0.08-0.63-0.14-1.15-0.2-1.69C631.85,607.64,631.39,607.64,630.7,607.64z M557.69,577.96
	c-0.78,0.09-1.3,0.15-1.95,0.22c0.07,0.62,0.13,1.13,0.2,1.74c0.68-0.09,1.26-0.16,1.9-0.24
	C557.78,579.05,557.75,578.6,557.69,577.96z M593.8,558.49c1.86-0.15,1.86-0.15,1.62-1.81c-0.6,0.05-1.21,0.1-1.9,0.15
	C593.63,557.46,593.72,557.98,593.8,558.49z M594.13,455.61c-0.68,0.06-1.27,0.12-1.97,0.18c0.11,0.61,0.2,1.11,0.3,1.69
	c0.65-0.04,1.17-0.07,1.82-0.1C594.23,456.73,594.18,456.22,594.13,455.61z M507.35,456.06c-1.76,0.04-1.76,0.04-1.57,1.81
	c0.58-0.04,1.17-0.09,1.82-0.13C507.51,457.08,507.43,456.56,507.35,456.06z M595.25,533.36c0.8-0.1,1.39-0.18,2.02-0.26
	c-0.11-0.68-0.19-1.22-0.29-1.84c-0.66,0.04-1.27,0.08-2,0.12C595.07,532.04,595.15,532.57,595.25,533.36z M597.49,599.78
	c0.1,0.68,0.18,1.24,0.28,1.87c0.69-0.11,1.2-0.19,1.74-0.28c-0.03-0.71-0.05-1.22-0.08-1.81
	C598.68,599.64,598.15,599.7,597.49,599.78z M576.29,397.32c0.61,0,1.14,0,1.82,0c-0.07-0.64-0.12-1.17-0.18-1.7
	c-0.62,0-1.16,0-1.86,0C576.15,396.26,576.22,396.78,576.29,397.32z M498.11,496.41c0.08,0.7,0.14,1.2,0.21,1.8
	c0.62-0.04,1.13-0.07,1.79-0.12c-0.09-0.64-0.16-1.14-0.24-1.68C499.33,496.41,498.87,496.41,498.11,496.41z M574.16,247.85
	c0.11,0.72,0.18,1.23,0.26,1.8c0.59-0.06,1.05-0.1,1.5-0.14C575.76,247.92,575.76,247.92,574.16,247.85z M536.51,397.27
	c1.76-0.1,1.76-0.1,1.27-1.79c-0.44,0.04-0.88,0.07-1.45,0.12C536.4,396.25,536.45,396.76,536.51,397.27z M545.54,457.76
	c0.83,0.01,1.63,0.09,1.46-1.01c-0.04-0.25-0.49-0.6-0.76-0.61C545.11,456.11,545.28,456.85,545.54,457.76z M554.5,457.17
	c-0.11-0.61-0.2-1.12-0.3-1.71c-0.63,0.1-1.13,0.18-1.58,0.26C552.33,457.27,552.91,457.7,554.5,457.17z M515.76,530.99
	c-0.01,1.07,0.05,1.96,1.19,1.6c0.34-0.11,0.43-0.97,0.68-1.6C516.8,530.99,516.36,530.99,515.76,530.99z M549.06,479.3
	c-0.16-0.84,0.16-1.86-1.16-1.57c-1.09,0.24-0.61,1-0.39,1.69C548.01,479.39,548.46,479.35,549.06,479.3z M545.48,497.59
	c0.77-0.03,1.69,0.39,1.55-0.85c-0.04-0.32-0.49-0.84-0.77-0.85C545.14,495.85,545.3,496.65,545.48,497.59z M524.84,532.03
	c-0.35-0.64,0.29-1.61-0.93-1.56c-0.31,0.01-0.83,0.48-0.86,0.78C522.93,532.34,523.69,532.26,524.84,532.03z M523.95,499.6
	c0.56-0.09,1.05-0.17,1.52-0.25c0-0.88,0.1-1.73-1.04-1.52C523.25,498.05,523.83,498.83,523.95,499.6z M547.35,437.96
	c0.16,0.86-0.02,1.78,1.12,1.57c0.28-0.05,0.67-0.62,0.67-0.95C549.14,437.28,548.25,437.9,547.35,437.96z M507.55,437.91
	c-0.21,1.57,0.34,2.01,1.7,1.38C509.66,437.81,508.95,437.56,507.55,437.91z M543.62,394.76c0.22,1.76,0.22,1.76,1.77,1.42
	c-0.08-0.5-0.16-1.01-0.25-1.53C544.6,394.69,544.22,394.72,543.62,394.76z M549.3,419.67c0.09,0.9-0.1,1.88,1.15,1.63
	c1.13-0.22,0.56-1.02,0.37-1.71C550.32,419.62,549.87,419.64,549.3,419.67z M532.85,459c-0.3-0.65,0.09-1.65-1.09-1.46
	c-0.23,0.04-0.56,0.5-0.57,0.79C531.13,459.39,531.77,459.4,532.85,459z M559.06,427.88c-0.03-0.72,0.31-1.59-0.84-1.48
	c-0.29,0.03-0.78,0.59-0.76,0.87C557.54,428.45,558.36,427.91,559.06,427.88z M516.08,557.94c-0.04-0.52-0.08-1.02-0.13-1.63
	c-0.64,0.07-1.15,0.13-1.61,0.18C514,558.14,514.68,558.41,516.08,557.94z M523.8,418.3c0.06,0.63,0.11,1.19,0.17,1.81
	c0.7-0.1,1.22-0.18,1.8-0.27c-0.06-0.6-0.11-1.05-0.15-1.55C525.02,418.3,524.48,418.3,523.8,418.3z M522.04,564.76
	c0.88-0.06,1.87,0.24,1.75-0.98c-0.14-1.33-1.07-0.61-1.82-0.56C522,563.77,522.02,564.21,522.04,564.76z M550.86,394.42
	c-0.09,0.88-0.08,1.65,1.01,1.46c1.24-0.22,0.62-0.98,0.4-1.66C551.76,394.29,551.31,394.36,550.86,394.42z M538.97,528.9
	c-0.88,0.31-1.84,0.05-1.62,1.2c0.24,1.27,1.04,0.73,1.77,0.54C539.07,530.09,539.03,529.64,538.97,528.9z M586.15,530.3
	c-0.08-0.72-0.13-1.17-0.19-1.74c-0.71,0.08-1.3,0.14-1.98,0.21c0.11,0.62,0.19,1.07,0.27,1.53
	C584.87,530.3,585.41,530.3,586.15,530.3z M612.3,584.08c-0.63,0.09-1.13,0.16-1.74,0.24c0.29,0.71-0.28,1.66,0.92,1.69
	c0.29,0.01,0.59-0.1,0.98-0.17C612.41,585.23,612.36,584.72,612.3,584.08z M525.55,457.87c-0.67,0.1-1.12,0.17-1.59,0.24
	c-0.05,0.89-0.15,1.77,1.06,1.6C526.17,459.54,525.57,458.71,525.55,457.87z M558,393.73c0.07,0.92-0.17,1.83,1.01,1.68
	c1.2-0.16,0.65-0.97,0.59-1.68C559.09,393.73,558.65,393.73,558,393.73z M549.34,501.1c0.68-0.13,1.16-0.22,1.65-0.31
	c-0.09-0.83,0.02-1.66-1.07-1.53C548.79,499.4,549.26,500.18,549.34,501.1z M525.78,481.36c0.85-0.05,1.78,0.2,1.56-0.99
	c-0.06-0.31-0.57-0.77-0.87-0.77C525.27,479.63,525.74,480.5,525.78,481.36z M519.65,534.45c0.03,0.81-0.22,1.72,0.95,1.55
	c0.29-0.04,0.7-0.57,0.71-0.89C521.35,533.9,520.53,534.26,519.65,534.45z M520.19,496.22c0.48-0.07,0.97-0.15,1.49-0.23
	c-0.07-0.59-0.13-1.03-0.18-1.46C519.92,494.61,519.92,494.61,520.19,496.22z M531.11,417.7c-0.03,1.01-0.16,1.94,1.11,1.67
	c1.13-0.24,0.56-1.01,0.49-1.67C532.17,417.7,531.73,417.7,531.11,417.7z M579.32,245.43c-0.35-1.43-0.35-1.43-1.95-1.32
	c0.09,0.51,0.17,1.01,0.26,1.54C578.23,245.57,578.69,245.51,579.32,245.43z M528.91,495.67c-0.09-0.94,0.12-1.94-1.15-1.68
	c-1.1,0.23-0.57,1.01-0.5,1.68C527.8,495.67,528.24,495.67,528.91,495.67z M527.25,455.88c0.72-0.01,1.53,0.36,1.58-0.81
	c0.03-0.69-0.29-1.07-1.01-0.92C526.63,454.38,527.21,455.17,527.25,455.88z M587.08,249.98c-0.81,0.6-1.96,0.17-1.79,1.47
	c0.04,0.28,0.07,0.55,0.11,0.92c0.67-0.1,1.18-0.18,1.78-0.27C587.15,251.46,587.12,250.94,587.08,249.98z M526.03,439.82
	c0.03,1.65,0.03,1.65,1.56,1.37c-0.03-0.45-0.06-0.9-0.09-1.45C526.92,439.77,526.47,439.8,526.03,439.82z M543.75,501.37
	c-0.07-0.62-0.13-1.12-0.19-1.69c-0.62,0.04-1.07,0.07-1.65,0.1c0.08,0.63,0.14,1.14,0.21,1.71
	C542.73,501.45,543.18,501.42,543.75,501.37z M560.1,480.1c-0.68,0.12-1.2,0.21-1.83,0.32c0.06,0.62,0.11,1.13,0.17,1.74
	c0.68-0.07,1.19-0.12,1.78-0.18C560.17,481.28,560.14,480.76,560.1,480.1z M529.14,477.53c0.68-0.08,1.17-0.14,1.79-0.22
	c-0.28-0.71,0.05-1.64-1.09-1.58C528.69,475.8,529.08,476.6,529.14,477.53z M561.36,555.96c0.04,0.89-0.16,1.88,1.08,1.59
	c0.31-0.07,0.43-1,0.72-1.76C562.29,555.88,561.85,555.92,561.36,555.96z M523.12,557.41c-0.06-1.51-0.06-1.51-1.52-1.24
	C521.79,557.5,521.79,557.5,523.12,557.41z M585.02,308.27c-1.28,0.11-2.39,0.1-1.63,1.83c0.54-0.04,1.12-0.09,1.73-0.14
	C585.09,309.36,585.06,308.91,585.02,308.27z M586.56,244.97c-0.1-0.64-0.19-1.2-0.28-1.76c-0.88,0.1-1.77-0.15-1.62,1.05
	c0.04,0.28,0.11,0.56,0.17,0.89C585.48,245.08,585.94,245.03,586.56,244.97z M594.33,606.04c0.82-0.67,1.35-1,1.7-1.46
	c0.15-0.2-0.09-0.69-0.17-1.11c-0.57,0.08-1.02,0.15-1.53,0.22C594.33,604.29,594.33,604.81,594.33,606.04z M588.04,346.01
	c-0.1-0.75-0.16-1.27-0.24-1.83c-0.56,0.03-1,0.06-1.58,0.09c0.03,0.65,0.06,1.16,0.09,1.74
	C586.87,346.01,587.31,346.01,588.04,346.01z M552.81,415.86c-0.05,0.9-0.24,1.82,1.01,1.55c1.07-0.23,0.6-0.98,0.38-1.67
	C553.71,415.78,553.28,415.82,552.81,415.86z M501.85,494.36c0.65-0.11,1.14-0.2,1.65-0.29c-0.14-0.77,0.11-1.68-1.03-1.55
	C501.39,492.66,501.7,493.42,501.85,494.36z M520.5,503.49c0.87-0.13,1.81,0.11,1.56-1.07c-0.06-0.31-0.59-0.75-0.89-0.74
	C519.96,501.74,520.48,502.61,520.5,503.49z M497.96,474.74c-0.76,0-1.29,0-2.01,0c0.16,0.7,0.3,1.28,0.45,1.93
	c0.63-0.1,1.14-0.17,1.71-0.26C498.05,475.78,498.01,475.32,497.96,474.74z M605.33,584.54c-0.77,0.22-1.34,0.38-1.9,0.54
	c0.32,1.37,1.15,1.79,2.09,1.17C605.47,585.8,605.42,585.34,605.33,584.54z M541.34,557.76c-0.7,0.19-1.62-0.3-1.53,0.91
	c0.02,0.31,0.47,0.8,0.77,0.83C541.71,559.62,541.52,558.82,541.34,557.76z M530.87,435.9c-0.85,0.01-1.74-0.23-1.58,0.94
	c0.04,0.31,0.54,0.78,0.84,0.79C531.35,437.66,530.89,436.76,530.87,435.9z M550.6,453.88c-0.22-0.74,0.26-1.7-0.97-1.55
	c-0.32,0.04-0.81,0.51-0.83,0.81C548.71,454.34,549.6,453.94,550.6,453.88z M552.31,473.47c-0.72,0.44-1.25,0.65-1.59,1.03
	c-0.1,0.11,0.24,0.83,0.53,1.02c0.24,0.16,0.72-0.04,1.16-0.09C552.38,474.84,552.36,474.39,552.31,473.47z M556.86,525.65
	c-0.13-0.71-0.22-1.2-0.33-1.75c-0.69,0.1-1.21,0.17-1.81,0.25c0.12,0.68,0.21,1.18,0.31,1.7
	C555.66,525.78,556.13,525.73,556.86,525.65z M636.52,612.82c-0.16-0.91,0.26-1.91-1.07-1.84c-0.22,0.01-0.43,0.11-0.76,0.21
	c0.03,0.54,0.07,1.05,0.1,1.63C635.37,612.82,635.82,612.82,636.52,612.82z M563.95,523.17c-1.05,0.15-1.99,0-1.84,1.25
	c0.03,0.21,0.15,0.42,0.26,0.69c0.55-0.03,1.07-0.06,1.7-0.09C564.03,524.36,563.99,523.85,563.95,523.17z M553.37,402.99
	c-0.19-0.77,0.26-1.7-0.96-1.64c-0.7,0.04-0.94,0.4-0.84,1.07C551.76,403.58,552.52,403.07,553.37,402.99z M545.95,504.84
	c0.9,0,1.75,0.13,1.54-0.99c-0.06-0.3-0.58-0.73-0.88-0.72C545.45,503.19,545.91,504.03,545.95,504.84z M523.53,537.8
	c0.04,0.74-0.4,1.65,0.81,1.58c0.3-0.02,0.8-0.49,0.82-0.79C525.25,537.49,524.51,537.55,523.53,537.8z M586.89,392.97
	c0.86-0.12,1.76,0.08,1.54-1.07c-0.05-0.29-0.61-0.68-0.93-0.67C586.23,391.23,586.8,392.09,586.89,392.97z M527.37,461.44
	c0.52,0.79,0.76,1.29,1.14,1.63c0.12,0.1,0.85-0.17,0.92-0.39c0.12-0.39-0.04-0.88-0.09-1.36
	C528.82,461.35,528.43,461.37,527.37,461.44z M548.05,492.13c0.46,0.79,0.67,1.31,1.04,1.68c0.11,0.1,0.86-0.16,0.95-0.39
	c0.15-0.39-0.01-0.88-0.04-1.38C549.47,492.06,549.09,492.08,548.05,492.13z M515.94,538.14c0.43,0.81,0.68,1.28,0.93,1.75
	c0.37-0.2,0.87-0.31,1.07-0.62c0.16-0.24-0.03-0.73-0.06-1.13C517.36,538.14,516.97,538.14,515.94,538.14z M551.24,482.87
	c0.68-0.1,1.12-0.16,1.6-0.23c0.02-0.88,0.15-1.78-1.07-1.6C550.63,481.21,551.2,482.04,551.24,482.87z M500.77,505.34
	c-0.05-0.73-0.08-1.25-0.12-1.89c-0.65,0.05-1.17,0.1-1.79,0.15c0.04,0.65,0.07,1.17,0.11,1.74
	C499.56,505.34,500.01,505.34,500.77,505.34z M505.93,481.43c-1.61,0.34-1.61,0.34-1.18,1.85c0.43-0.04,0.86-0.09,1.36-0.14
	C506.04,482.52,506,482.07,505.93,481.43z M544,483.23c1.08,0.08,1.82,0.06,1.59-1.05c-0.24-1.22-1-0.6-1.7-0.49
	C543.93,482.22,543.96,482.68,544,483.23z M526.62,414.31c0.65,0.86,0.94,1.41,1.39,1.77c0.18,0.15,0.7-0.11,1.15-0.19
	c-0.12-0.63-0.21-1.13-0.3-1.58C528.25,414.31,527.8,414.31,526.62,414.31z M537.62,556.02c-0.14-1.62-0.14-1.62-1.67-1.33
	C535.81,555.85,536.13,556.5,537.62,556.02z M552.13,575.11c0.14,1.59,0.14,1.59,1.71,1.3C554.14,575.06,553.6,574.64,552.13,575.11
	z M545.79,523.12c-0.09-0.74-0.15-1.26-0.21-1.77c-1.75,0.15-1.75,0.15-1.42,1.86C544.6,523.18,545.06,523.16,545.79,523.12z
	 M523.17,492.32c1.06,0.19,1.82-0.01,1.49-1.16c-0.31-1.11-1.04-0.5-1.71-0.26C523.03,491.44,523.1,491.89,523.17,492.32z
	 M536.47,563.39c0.88,0.04,1.81,0.3,1.65-1.01c-0.17-1.36-1.08-0.65-1.88-0.71C536.34,562.35,536.41,562.86,536.47,563.39z
	 M506.6,551.83c0.86-0.02,1.8,0.25,1.62-1.08c-0.16-1.17-0.91-0.76-1.62-0.67C506.6,550.72,506.6,551.24,506.6,551.83z
	 M587.12,606.26c0.75-0.13,1.27-0.22,1.76-0.3c0.12-1.37-0.27-2.06-1.76-1.51C587.12,604.95,587.12,605.46,587.12,606.26z
	 M526.41,506.59c-0.56-0.7-0.91-1.14-1.27-1.58c-0.23,0.31-0.66,0.63-0.66,0.94C524.49,507.13,525.27,506.73,526.41,506.59z
	 M562.71,405.72c0.58,0,1.1,0,1.75,0c-0.05-0.68-0.1-1.26-0.15-1.92c-0.68,0.15-1.19,0.26-1.77,0.39
	C562.6,404.76,562.66,405.21,562.71,405.72z M526.92,474.05c-0.04-0.96,0.16-1.84-1.02-1.66c-1.2,0.19-0.6,0.99-0.58,1.66
	C525.86,474.05,526.3,474.05,526.92,474.05z M532.24,451.94c-0.02-0.88,0.12-1.81-1.11-1.61c-1.16,0.19-0.54,0.99-0.53,1.73
	C531.21,452.02,531.71,451.98,532.24,451.94z M540.75,550.47c-0.59,0.03-1.05,0.06-1.65,0.09c0.03,0.64,0.06,1.15,0.09,1.67
	C540.69,552.55,541.21,552.07,540.75,550.47z M583.02,328.31c-0.15-0.73-0.26-1.23-0.37-1.77c-0.66,0.09-1.18,0.16-1.8,0.24
	c0.06,0.58,0.1,1.03,0.15,1.53C581.65,328.31,582.18,328.31,583.02,328.31z M557.66,406.36c-0.6-0.81-0.86-1.3-1.26-1.61
	c-0.11-0.09-0.77,0.3-0.94,0.61c-0.15,0.25,0.05,0.7,0.1,1.13C556.11,406.46,556.55,406.43,557.66,406.36z M498.68,483.56
	c-0.06-1.67-0.06-1.67-1.75-1.31C496.79,483.46,497.2,484.06,498.68,483.56z M518.78,546.94c-0.09-0.87,0.2-1.9-1.13-1.59
	c-1.1,0.26-0.56,1.04-0.36,1.73C517.78,547.04,518.24,547,518.78,546.94z M518.92,507.06c-0.12-1.61-0.12-1.61-1.7-1.31
	C517.13,506.88,517.42,507.56,518.92,507.06z M583.82,255.96c-0.05-0.61-0.09-1.13-0.14-1.81c-0.64,0.11-1.15,0.19-1.75,0.3
	c0.08,0.63,0.14,1.14,0.21,1.73C582.77,256.1,583.23,256.04,583.82,255.96z M545.34,562.85c0.2-1.57-0.33-2-1.68-1.47
	c0.04,0.47,0.07,0.97,0.11,1.47C544.37,562.85,544.82,562.85,545.34,562.85z M542.33,507.09c0.43,0.69,0.65,1.19,1.02,1.53
	c0.12,0.11,0.85-0.14,0.93-0.35c0.14-0.38-0.01-0.87-0.04-1.4C543.66,506.94,543.21,506.99,542.33,507.09z M529.88,444.8
	c1.08,0.22,1.82,0.01,1.5-1.14c-0.31-1.11-1.04-0.5-1.71-0.26C529.75,443.93,529.82,444.38,529.88,444.8z M525.97,465.27
	c-1.55,0.09-1.55,0.09-1.21,1.64C526.25,467.14,526.37,466.41,525.97,465.27z M522.06,543.14c-0.04-0.77,0.43-1.7-0.76-1.67
	c-1.34,0.04-0.88,0.94-0.77,1.77C521.11,543.2,521.56,543.17,522.06,543.14z M561.66,541.52c-0.73,0.09-1.25,0.16-1.87,0.24
	c0.06,0.63,0.11,1.15,0.16,1.72c0.76-0.11,1.36-0.2,2.02-0.3C561.87,542.62,561.78,542.17,561.66,541.52z M556.88,414.12
	c0.37-0.72,0.73-1.1,0.68-1.4c-0.06-0.31-0.53-0.53-0.82-0.8c-0.21,0.27-0.6,0.54-0.59,0.8
	C556.17,413.07,556.49,413.41,556.88,414.12z M598.37,608.96c0.66-0.1,1.1-0.17,1.56-0.25c0.03-0.9,0.24-1.85-1.02-1.67
	C597.8,607.21,598.35,608.08,598.37,608.96z M515.06,543.55c-0.33-0.7,0.23-1.64-0.99-1.62c-0.66,0.01-0.98,0.29-0.9,1
	C513.32,544.07,514.06,543.68,515.06,543.55z M522.49,443.68c0.02,0.87-0.21,1.85,1.03,1.64c1.23-0.21,0.72-1.04,0.46-1.77
	C523.43,443.6,522.99,443.64,522.49,443.68z M553.87,448.53c-0.15-0.15-0.3-0.29-0.45-0.44c-0.43,0.37-0.91,0.71-1.27,1.15
	c-0.12,0.15,0.08,0.55,0.15,0.93c0.6-0.05,1.1-0.1,1.64-0.14C553.91,449.44,553.89,448.99,553.87,448.53z M521.13,509.03
	c-0.19,1.43,0.31,1.87,1.56,1.39C523.01,509.09,522.66,508.53,521.13,509.03z M625.93,615.45c-0.68,0.06-1.25,0.12-1.9,0.18
	c0.07,0.71,0.12,1.23,0.17,1.79c0.73-0.08,1.33-0.15,2.03-0.23C626.12,616.55,626.03,616.04,625.93,615.45z M575.45,406.71
	c-0.77,0.1-1.35,0.18-2.03,0.28c0.11,0.61,0.2,1.05,0.3,1.59c0.67-0.09,1.24-0.17,1.85-0.25
	C575.53,407.78,575.5,407.39,575.45,406.71z M524.12,483.35c-0.94,0.05-1.79-0.03-1.54,1.09c0.07,0.3,0.63,0.72,0.93,0.69
	C524.72,485.03,524.14,484.17,524.12,483.35z M602.78,597.62c-0.18-0.78-0.3-1.29-0.48-2.1c-0.58,0.38-1.15,0.56-1.35,0.93
	c-0.18,0.33,0.06,0.88,0.12,1.4C601.65,597.78,602.1,597.72,602.78,597.62z M610.02,577.16c0.1,0.61,0.19,1.12,0.29,1.71
	c0.64-0.1,1.15-0.18,1.61-0.25C612.14,577.14,611.68,576.55,610.02,577.16z M527.25,433.35c-0.54-0.37-0.89-0.78-1.17-0.74
	c-0.27,0.04-0.7,0.57-0.66,0.83c0.05,0.32,0.47,0.76,0.79,0.81C526.44,434.29,526.77,433.79,527.25,433.35z M519.34,552.2
	c-0.7,0.37-1.23,0.53-1.58,0.88c-0.14,0.14,0.03,0.93,0.21,1c0.42,0.16,0.94,0.05,1.48,0.05
	C519.42,553.47,519.39,553.03,519.34,552.2z M553.42,424.67c0.84,0.02,1.59,0.03,1.43-1.02c-0.04-0.24-0.47-0.6-0.73-0.61
	C553,423,553.19,423.75,553.42,424.67z M561.08,409.77c0.03-1.06,0.04-1.95-1.19-1.66c-1.12,0.27-0.57,1.02-0.43,1.66
	C560,409.77,560.46,409.77,561.08,409.77z M519.13,487.35c-0.01,0.89-0.14,1.84,1.05,1.63c1.24-0.22,0.67-1.04,0.45-1.75
	C520.07,487.27,519.63,487.3,519.13,487.35z M544.67,514.09c-0.64,0.19-1.51-0.32-1.52,0.91c0,0.72,0.37,1.01,1.04,0.87
	C545.38,515.63,544.77,514.87,544.67,514.09z M573.28,260.57c-0.09-0.64-0.17-1.22-0.25-1.85c-0.62,0.06-1.07,0.1-1.63,0.15
	c0.07,0.73,0.13,1.31,0.2,1.99C572.24,260.75,572.7,260.67,573.28,260.57z M523,469.02c-1.67,0.2-1.67,0.2-1.33,1.76
	c0.43-0.03,0.87-0.06,1.42-0.1C523.05,470.09,523.03,469.66,523,469.02z M528.47,448.6c-0.31-0.72,0.29-1.7-0.93-1.66
	c-1.34,0.04-0.88,0.95-0.77,1.78C527.35,448.67,527.8,448.64,528.47,448.6z M532.52,412.19c-0.12-0.79-0.19-1.31-0.28-1.9
	c-0.63,0.11-1.14,0.21-1.74,0.32c0.11,0.62,0.19,1.13,0.27,1.59C531.35,412.19,531.8,412.19,532.52,412.19z M549.58,484.79
	c-0.89,0.08-1.85-0.14-1.58,1.16c0.24,1.16,0.99,0.63,1.67,0.41C549.64,485.83,549.62,485.37,549.58,484.79z M550.35,578.82
	c-1.52,0.14-1.52,0.14-1.3,1.75C550.34,580.78,550.84,580.31,550.35,578.82z M502.47,485.39c-0.93-0.03-1.75-0.12-1.48,0.99
	c0.07,0.29,0.62,0.69,0.92,0.66C503.09,486.95,502.57,486.14,502.47,485.39z M519.84,559.67c-0.7,0.06-1.15,0.1-1.74,0.15
	c0.15,0.76-0.35,1.61,0.84,1.69c0.29,0.02,0.6-0.08,0.98-0.14C519.89,560.82,519.87,560.37,519.84,559.67z M557.66,514.77
	c0.71-0.1,1.21-0.17,1.82-0.26c-0.11-0.64-0.2-1.12-0.29-1.68c-0.68,0.14-1.17,0.23-1.69,0.34
	C557.55,513.72,557.59,514.1,557.66,514.77z M519.31,512.64c-0.65,0.09-1.14,0.15-1.67,0.22c0.13,0.76-0.17,1.66,0.98,1.59
	C519.74,514.38,519.43,513.6,519.31,512.64z M534.14,433.75c0.01-1.02,0.02-1.94-1.15-1.59c-0.35,0.11-0.46,0.97-0.79,1.73
	C533.08,433.83,533.52,433.8,534.14,433.75z M566.46,553.78c-0.09-0.75-0.15-1.27-0.23-1.87c-0.64,0.07-1.16,0.13-1.75,0.19
	c0.08,0.65,0.14,1.17,0.21,1.68C565.27,553.78,565.72,553.78,566.46,553.78z M539.1,449.62c-1.24-0.14-1.79,0.26-1.27,1.55
	C539.35,451.1,539.35,451.1,539.1,449.62z M547.25,542.67c-0.71,0.15-1.21,0.26-1.72,0.37c0.1,0.67,0.19,1.2,0.28,1.82
	c0.71-0.09,1.24-0.15,1.84-0.23C547.53,544,547.42,543.47,547.25,542.67z M508.95,454.34c0.78-0.66,1.62-1.07,1.6-1.42
	c-0.09-1.27-1.06-0.66-1.86-0.69C508.76,452.79,508.81,453.24,508.95,454.34z M552.97,441.12c-0.71,0.08-1.15,0.13-1.71,0.2
	c0.11,0.65,0.19,1.14,0.28,1.69c0.58-0.06,1.01-0.11,1.51-0.16C553.02,442.22,553,441.77,552.97,441.12z M503.86,540.92
	c-0.07-0.67-0.12-1.18-0.19-1.8c-0.74,0.16-1.31,0.29-1.97,0.44c0.24,0.67,0.42,1.16,0.59,1.65
	C502.82,541.11,503.21,541.04,503.86,540.92z M555.41,464.53c-0.25-0.78-0.43-1.35-0.62-1.95c-0.58,0.13-1.03,0.22-1.52,0.33
	c0.09,0.62,0.17,1.12,0.24,1.62C554.16,464.53,554.62,464.53,555.41,464.53z M445.08,31.37c-0.58,0.07-0.96,0.09-1.34,0.18
	c-0.12,0.03-0.21,0.2-0.31,0.31c0.31,1.41,0.91,1.78,1.89,1.14C445.26,32.54,445.19,32.08,445.08,31.37z M566.96,392.76
	c-0.77,0.16-1.32,0.27-2.36,0.49c0.71,0.69,1.09,1.15,1.56,1.48c0.15,0.11,0.54-0.11,0.88-0.2
	C567.02,393.98,567,393.53,566.96,392.76z M559.64,435.19c-0.05-1.01-0.07-1.82-1.13-1.55c-1.05,0.27-0.66,0.99-0.38,1.68
	C558.62,435.28,559.07,435.24,559.64,435.19z M534.23,534.83c0.75,0,1.27,0,1.92,0c-0.18-0.7-0.34-1.28-0.5-1.9
	c-0.59,0.11-1.05,0.2-1.61,0.31C534.11,533.79,534.16,534.24,534.23,534.83z M535.19,567.22c-0.07-0.62-0.13-1.13-0.2-1.73
	c-0.78,0.19-1.73-0.24-1.79,0.99c-0.01,0.27,0.15,0.56,0.25,0.92C534.05,567.34,534.56,567.28,535.19,567.22z M548.25,304.19
	c-0.72,0.13-1.22,0.22-1.8,0.33c0.17,0.69,0.3,1.26,0.46,1.89c0.67-0.1,1.19-0.18,1.76-0.27
	C548.53,305.5,548.42,304.98,548.25,304.19z M557.06,531.26c-1.63,0.08-1.63,0.08-1.23,1.72
	C557.11,533.02,557.6,532.5,557.06,531.26z M528.62,430.53c0.69-0.07,1.13-0.11,1.64-0.16c-0.13-0.72,0.41-1.6-0.79-1.66
	c-0.28-0.01-0.57,0.09-0.85,0.14C528.62,429.41,528.62,429.86,528.62,430.53z M396.11,50.06c-0.68,0.18-1.15,0.3-1.62,0.42
	c0.32,1.49,0.97,1.88,1.88,1.22C396.3,51.25,396.23,50.8,396.11,50.06z M554.79,399.23c1.73,0.28,1.73-0.54,1.41-1.71
	c-0.5,0.03-0.98,0.06-1.56,0.1C554.7,398.25,554.74,398.7,554.79,399.23z M609.18,547.98c-0.77,0.12-1.29,0.19-1.92,0.29
	c0.23,0.7,0.42,1.28,0.61,1.87c0.59-0.08,1.05-0.15,1.59-0.22C609.37,549.26,609.29,548.74,609.18,547.98z M501.72,478.23
	c-1.4-0.23-1.51,0.46-1.18,1.57C502.03,480.07,502.12,479.36,501.72,478.23z M519.2,527.18c-0.07,1.06-0.23,2.05,1.45,1.48
	c-0.05-0.5-0.09-1.01-0.15-1.63C520.01,527.09,519.64,527.13,519.2,527.18z M533.67,426.53c-0.09-0.63-0.16-1.14-0.23-1.63
	c-1.63-0.17-1.71,0.6-1.33,1.75C532.61,426.61,533.07,426.57,533.67,426.53z M535.13,401.12c-0.08-0.63-0.15-1.14-0.23-1.77
	c-0.52,0.04-0.96,0.08-1.55,0.13c0.05,0.63,0.1,1.18,0.15,1.8C534.12,401.22,534.56,401.18,535.13,401.12z M566.42,116.69
	c0.59-0.06,0.98-0.08,1.36-0.16c0.12-0.02,0.21-0.21,0.31-0.32c-0.3-1.39-0.95-1.78-1.92-1.14
	C566.24,115.52,566.31,115.99,566.42,116.69z M535.16,527.53c-0.12-1.68-0.12-1.68-1.8-1.33c0.05,0.51,0.1,1.04,0.16,1.62
	C534.1,527.72,534.57,527.63,535.16,527.53z M535.05,421.12c0.05,0.58,0.09,1.1,0.14,1.75c0.57-0.08,1.01-0.14,1.58-0.21
	c-0.1-0.63-0.18-1.12-0.27-1.66C535.95,421.04,535.56,421.08,535.05,421.12z M579.95,252.54c-0.07-0.65-0.12-1.22-0.19-1.87
	c-0.7,0.19-1.22,0.33-1.79,0.48c0.13,0.6,0.22,1.04,0.33,1.55C578.89,252.64,579.35,252.6,579.95,252.54z M538.99,404.52
	c-0.08-0.94,0.03-1.91-1.19-1.66c-1.12,0.23-0.55,1.01-0.47,1.66C537.86,404.52,538.31,404.52,538.99,404.52z M520.21,521.6
	c-0.16-0.79,0.38-1.69-0.82-1.73c-0.28-0.01-0.56,0.09-0.94,0.16c0.08,0.61,0.14,1.11,0.2,1.57
	C519.19,521.6,519.58,521.6,520.21,521.6z M582.9,247.07c-0.54,0.06-1,0.1-1.61,0.17c0.06,0.64,0.11,1.21,0.16,1.78
	C582.75,248.98,583.54,248.68,582.9,247.07z M479.82,35.81c0.09,0.57,0.18,1.09,0.28,1.73c0.68-0.15,1.17-0.26,1.61-0.35
	C481.83,35.72,481.3,35.26,479.82,35.81z M556.82,505.82c0.08,0.74,0.13,1.25,0.21,2c0.66-0.55,1.82,0.02,1.68-1.33
	c-0.02-0.22-0.11-0.43-0.18-0.68C558.03,505.82,557.58,505.82,556.82,505.82z M551.43,508.07c-0.07-0.86,0.25-1.97-1.45-1.49
	C549.82,507.63,549.76,508.54,551.43,508.07z M543.36,555.69c0.5-0.04,0.93-0.07,1.57-0.11c-0.3-0.79,0.26-1.87-1.18-1.69
	c-0.2,0.03-0.4,0.15-0.65,0.26C543.19,554.67,543.27,555.12,543.36,555.69z M564.73,411.07c-0.59,0.1-1.11,0.18-1.73,0.28
	c0.11,0.7,0.2,1.22,0.28,1.72C564.64,413.11,565.4,412.74,564.73,411.07z M559.47,554.22c-0.44-0.64-0.66-1.19-1.07-1.47
	c-0.18-0.12-0.93,0.21-1.07,0.5c-0.16,0.35,0.05,0.87,0.11,1.38C558.06,554.51,558.57,554.41,559.47,554.22z M548.16,510.24
	c-0.65,0.07-1.1,0.11-1.65,0.17c0.06,0.59,0.1,1.05,0.15,1.54c0.53,0,0.98,0,1.5,0C548.16,511.41,548.16,510.95,548.16,510.24z
	 M533.31,545.7c-0.08-0.63-0.14-1.14-0.2-1.63c-1.16,0.06-2.16,0.14-1.43,1.76C532.14,545.79,532.65,545.75,533.31,545.7z
	 M580.31,590.49c-0.6,0.11-1.11,0.21-1.61,0.31c0.21,1.47,0.21,1.47,1.87,1.2C580.5,591.55,580.42,591.11,580.31,590.49z
	 M510.61,515.26c1.46-0.12,2.06-0.52,1.32-1.8c-0.45,0.05-0.89,0.1-1.42,0.16C510.55,514.18,510.57,514.62,510.61,515.26z
	 M535.93,515.15c0.08,0.53,0.15,1.01,0.23,1.55c0.79-0.17,1.71,0.06,1.5-1.09C537.45,514.44,536.68,515,535.93,515.15z
	 M571.41,591.29c0.08,0.69,0.14,1.15,0.19,1.61c1.27,0.09,1.92-0.24,1.38-1.68C572.53,591.24,572.09,591.26,571.41,591.29z
	 M582.55,241.5c-0.11-1.57-0.11-1.57-1.81-1.3c0.11,0.5,0.21,0.99,0.33,1.53C581.66,241.64,582.11,241.57,582.55,241.5z
	 M507.64,518.94c1.56-0.23,1.56-0.23,1.14-1.69C507.65,517.16,507.15,517.57,507.64,518.94z M555.68,516.81
	c-0.5,0.08-1,0.17-1.54,0.26c0.08,0.59,0.14,1.05,0.2,1.53C555.62,518.59,556.3,518.26,555.68,516.81z M533.08,520.48
	c0.51-0.06,0.96-0.12,1.53-0.19c-0.05-0.57-0.09-1.08-0.13-1.57C533.18,518.57,532.55,518.91,533.08,520.48z M566.2,593.59
	c-0.36-0.72,0.3-1.73-1.03-1.78c-0.21-0.01-0.43,0.11-0.74,0.2c0.04,0.54,0.07,1.06,0.12,1.71
	C565.1,593.67,565.54,593.64,566.2,593.59z M558.26,500.15c-0.1-0.74-0.17-1.24-0.23-1.72c-1.24,0.09-2.13,0.3-1.39,1.8
	C557.07,500.22,557.53,500.19,558.26,500.15z M565.47,420.07c-0.04-1.58-0.03-1.64-0.58-1.7c-0.35-0.04-0.72,0.13-1.17,0.23
	c0.11,0.62,0.19,1.12,0.29,1.71C564.6,420.21,565.04,420.14,565.47,420.07z M576.6,402.81c0.55,0.73-0.17,1.97,1.24,1.83
	c0.22-0.02,0.43-0.12,0.74-0.21c-0.05-0.55-0.09-1.07-0.15-1.71C577.87,402.74,577.42,402.76,576.6,402.81z M542.3,422.12
	c1.01-0.06,1.82-0.08,1.57-1.13c-0.27-1.17-1.03-0.51-1.7-0.36C542.22,421.11,542.26,421.55,542.3,422.12z M505.74,522.6
	c0.23-1.61-0.59-1.62-1.79-1.27c0.1,0.5,0.19,0.99,0.3,1.52C504.86,522.76,505.3,522.68,505.74,522.6z M510.41,491.65
	c-0.68,0.33-1.61-0.1-1.54,1.12c0.04,0.6,0.28,0.8,0.88,0.76C510.92,493.44,510.63,492.71,510.41,491.65z M589.2,255.45
	c0.82-0.07,1.4-0.12,2.11-0.18c-0.16-0.61-0.27-1.05-0.42-1.62c-0.62,0.1-1.14,0.19-1.69,0.28
	C589.2,254.38,589.2,254.76,589.2,255.45z M530.37,491.59c0.81,0.04,1.68,0.2,1.49-0.95c-0.2-1.2-1.02-0.59-1.75-0.46
	C530.19,490.67,530.28,491.12,530.37,491.59z M544.57,488.81c0.01,0.91-0.06,1.91,1.18,1.58c1.05-0.28,0.53-1.06,0.28-1.74
	C545.51,488.71,545.05,488.76,544.57,488.81z M513.51,488.11c-1.56,0.12-1.56,0.12-1.23,1.64
	C513.29,489.78,513.97,489.53,513.51,488.11z M544.59,403.78c1.58-0.23,1.58-0.23,1.14-1.66
	C544.63,402.02,544.09,402.4,544.59,403.78z M525.34,559.18c0.1,0.89-0.07,1.78,1.06,1.63c1.21-0.16,0.64-0.95,0.59-1.63
	C526.45,559.18,526,559.18,525.34,559.18z M522.44,525.01c0.62-0.05,1.07-0.1,1.99-0.18c-0.58-0.7-0.92-1.49-1.32-1.52
	C521.96,523.22,522.36,524.13,522.44,525.01z M585.17,355.11c-0.73,0.13-1.18,0.21-1.64,0.29c0.07,0.64,0.12,1.16,0.19,1.78
	c0.62-0.06,1.07-0.1,1.61-0.15C585.27,356.37,585.23,355.85,585.17,355.11z M540.88,425.61c-0.07-0.54-0.13-0.98-0.2-1.54
	c-0.62,0.13-1.12,0.24-1.57,0.33C538.97,425.79,539.52,426.12,540.88,425.61z M523.09,550.32c-0.58-0.78-0.85-1.28-1.25-1.61
	c-0.12-0.1-0.81,0.18-0.88,0.4c-0.13,0.39,0.01,0.87,0.04,1.37C521.57,550.43,522.02,550.4,523.09,550.32z M517.6,426.15
	c0,0.56,0,1.07,0,1.57c1.41,0.19,2.07-0.13,1.39-1.65C518.55,426.1,518.1,426.12,517.6,426.15z M574.26,581.92
	c1.3,0,2.13-0.31,1.46-1.88c-0.5,0.06-1,0.13-1.63,0.2C574.15,580.89,574.2,581.41,574.26,581.92z M590.47,309.69
	c0.65-0.08,1.16-0.14,1.76-0.22c-0.15-0.68-0.27-1.24-0.4-1.84c-0.59,0.09-1.03,0.16-1.5,0.23
	C590.37,308.51,590.41,309.02,590.47,309.69z M562.08,564.9c0.69-0.04,1.14-0.06,1.74-0.09c-0.13-0.71-0.23-1.26-0.34-1.86
	c-0.62,0.1-1.07,0.18-1.56,0.26C561.97,563.77,562.02,564.23,562.08,564.9z M544.88,577.76c0.79-0.67,1.61-1.05,1.61-1.42
	c-0.03-1.3-1-0.75-1.83-0.77C544.71,576.15,544.76,576.6,544.88,577.76z M545.11,475.97c-0.09-0.83,0.23-1.79-1.05-1.6
	c-1.19,0.18-0.55,0.98-0.43,1.67C544.1,476.02,544.54,476,545.11,475.97z M503.84,476.05c0.55-0.07,1.04-0.13,1.65-0.21
	c-0.27-0.78-0.05-1.73-1.19-1.53C503.12,474.52,503.72,475.31,503.84,476.05z M584.24,593.63c-0.62,0.06-1.06,0.1-1.63,0.15
	c0.11,0.72,0.2,1.3,0.3,1.93c0.61-0.11,1.05-0.2,1.5-0.28C584.36,594.84,584.31,594.37,584.24,593.63z M501.18,532.33
	c0.06,0.64,0.11,1.1,0.17,1.69c0.79-0.19,1.82,0.24,1.74-1.03C503,531.85,502.06,532.41,501.18,532.33z M534.44,473.17
	c-0.42-0.67-0.61-1.2-0.99-1.5c-0.19-0.15-0.71,0.12-1.16,0.21c0.11,0.58,0.19,1.02,0.28,1.51
	C533.12,473.33,533.57,473.28,534.44,473.17z M513.9,430.09c0.19,0.72,0.32,1.22,0.46,1.74c0.74-0.27,1.73-0.07,1.47-1.22
	C515.59,429.5,514.81,430.01,513.9,430.09z M537.02,547.35c-0.83,0.13-1.93-0.28-1.65,1.05c0.07,0.33,1.02,0.47,1.81,0.8
	C537.1,548.33,537.06,547.88,537.02,547.35z M515.71,469.75c-0.75,0.1-1.68-0.23-1.51,0.95c0.04,0.28,0.53,0.7,0.83,0.71
	C516.19,471.45,515.92,470.66,515.71,469.75z M588.81,271.66c-0.68,0.38-1.21,0.58-1.59,0.94c-0.1,0.1,0.13,0.86,0.35,0.95
	c0.39,0.15,0.89,0,1.41-0.03C588.93,272.93,588.89,272.48,588.81,271.66z M535.56,469.58c1.6,0.06,1.94-0.5,1.37-1.72
	c-0.5,0.06-0.94,0.11-1.47,0.17C535.5,468.59,535.52,469.03,535.56,469.58z M509.96,466.67c0.08,0.8-0.1,1.71,1.06,1.38
	c0.31-0.09,0.75-0.66,0.69-0.91C511.42,465.95,510.66,466.49,509.96,466.67z M517.23,466.18c0.08,0.52,0.16,1.01,0.24,1.53
	c0.79-0.13,1.68,0.08,1.48-1.06C518.75,465.48,517.98,466.04,517.23,466.18z M603.19,602.68c-0.79,0.47-1.3,0.71-1.71,1.06
	c-0.09,0.08,0.1,0.77,0.24,0.8c0.5,0.1,1.04,0.04,1.62,0.04C603.31,604.08,603.28,603.69,603.19,602.68z M589.12,546.25
	c0.18,0.79,0.32,1.37,0.46,1.97c0.6-0.07,1.05-0.12,1.63-0.19c-0.09-0.69-0.17-1.26-0.26-1.92
	C590.33,546.15,589.88,546.19,589.12,546.25z M546.09,463.62c-0.01,0.81,0.06,1.53,1.1,1.24c0.23-0.06,0.52-0.6,0.47-0.86
	C547.42,462.82,546.72,463.41,546.09,463.62z M546.37,570.08c-0.5-0.8-0.04-2.03-1.4-1.71c-1.08,0.25-0.58,1.04-0.45,1.71
	C545.03,570.08,545.49,570.08,546.37,570.08z M556.42,439.04c-0.05-0.63-0.1-1.15-0.14-1.65c-1.41-0.12-1.92,0.36-1.35,1.73
	C555.38,439.09,555.83,439.06,556.42,439.04z M577.44,390.06c-0.08-0.55-0.15-1-0.21-1.45c-1.7,0.16-1.7,0.16-1.43,1.68
	C576.33,390.22,576.85,390.14,577.44,390.06z M577.27,449.82c0.09,0.73,0.15,1.26,0.23,1.91c0.69-0.08,1.21-0.14,1.83-0.22
	c-0.2-0.68-0.37-1.26-0.54-1.84C578.27,449.73,577.88,449.76,577.27,449.82z M514.01,449.72c-0.43-1.45-1.22-1.87-1.93-1.15
	c0.08,0.4,0.17,0.85,0.28,1.4C512.94,449.89,513.39,449.82,514.01,449.72z M540.9,447.48c0.81-0.01,1.7,0.13,1.51-0.99
	c-0.2-1.21-0.99-0.61-1.69-0.41C540.78,446.57,540.84,447.02,540.9,447.48z M510.34,446.72c-0.24-0.66-0.43-1.2-0.62-1.74
	c-1.23,0.49-1.23,0.49-1.13,1.86C509.11,446.81,509.64,446.77,510.34,446.72z M586.9,290.14c-0.82,0.21-1.79-0.29-1.78,0.9
	c0,0.28,0.34,0.73,0.59,0.79c0.4,0.09,0.87-0.08,1.35-0.14C587.01,291.16,586.97,290.77,586.9,290.14z M549.47,445
	c-0.53,0.07-1.03,0.13-1.56,0.2c0.19,0.74-0.12,1.71,1.05,1.51C550.04,446.52,549.67,445.77,549.47,445z M613.52,575.02
	c0.63-0.06,1.15-0.11,1.76-0.16c-0.16-0.69-0.3-1.26-0.45-1.88c-0.56,0.08-0.99,0.14-1.49,0.22
	C613.41,573.84,613.46,574.35,613.52,575.02z M560.16,567.04c-1.04-0.23-1.52,0.07-1.35,1.05c0.18,1,0.83,0.64,1.53,0.45
	C560.28,568.02,560.22,567.52,560.16,567.04z M548.68,556.77c-0.72,0.2-1.21,0.34-1.7,0.47c0.03,0.9,0.09,1.64,1.1,1.48
	C549.24,558.53,548.58,557.67,548.68,556.77z M555.82,556.17c-0.64,0.59-1.74,0.03-1.62,1.24c0.03,0.3,0.55,0.77,0.86,0.78
	C556.29,558.25,555.85,557.35,555.82,556.17z M512.28,554.55c-0.05-0.45-0.11-0.89-0.17-1.42c-0.61,0.1-1.12,0.18-1.62,0.27
	C510.6,554.83,510.6,554.83,512.28,554.55z M532.03,553.22c0.72-0.17,1.15-0.27,1.62-0.38c0.06-1.38-0.49-1.7-1.7-1.23
	C531.98,552.1,532,552.55,532.03,553.22z M511.54,547.63c-0.24-1.4-0.24-1.4-1.7-1.32c0.06,0.49,0.12,0.98,0.19,1.51
	C510.64,547.75,511.09,547.69,511.54,547.63z M506.06,544.44c0.92-0.12,1.93,0.13,1.58-1.06c-0.29-1-1.01-0.6-1.69-0.34
	C505.99,543.52,506.02,543.9,506.06,544.44z M620.6,597.74c-1.78-0.01-1.54,0.93-1.23,1.96
	C621.01,599.84,620.92,598.95,620.6,597.74z M505.29,537.43c0.72-0.47,1.9,0.08,1.63-1.28c-0.22-1.13-1.05-0.49-1.74-0.38
	C505.21,536.24,505.24,536.69,505.29,537.43z M506.48,529.77c-0.04-0.54-0.08-1-0.12-1.54c-0.64,0.1-1.15,0.18-1.64,0.25
	C504.5,529.94,505.14,530.15,506.48,529.77z M513.72,529.32c-0.08-0.54-0.14-1.04-0.28-2.05c-0.69,0.67-1.37,1.03-1.38,1.39
	C512.05,529.84,512.89,529.48,513.72,529.32z M507.99,526.29c0.85-0.2,1.79,0.03,1.6-1.09c-0.2-1.12-0.94-0.67-1.6-0.49
	C507.99,525.2,507.99,525.65,507.99,526.29z M513.02,522.18c0.05-1.09-0.21-1.72-1.15-1.47c-1.1,0.28-0.53,0.99-0.33,1.63
	C512.05,522.29,512.51,522.24,513.02,522.18z M558.45,521.96c1.64,0.12,1.66-0.68,1.23-1.78
	C558.19,519.92,558.09,520.7,558.45,521.96z M553.69,509.77c-0.02,0.95,0.1,1.58,0.99,1.45c0.28-0.04,0.66-0.37,0.74-0.64
	c0.1-0.31-0.08-0.7-0.15-1.12C554.68,509.58,554.23,509.67,553.69,509.77z M528.18,508.31c0.11,0.93,0.13,1.68,1.17,1.48
	c1.16-0.23,0.53-1.02,0.46-1.7C529.32,508.14,528.87,508.21,528.18,508.31z M511.67,506c-0.68,0.45-1.86-0.09-1.56,1.26
	c0.24,1.08,0.98,0.62,1.65,0.37C511.74,507.15,511.71,506.7,511.67,506z M552.97,502.58c0.05,0.93-0.01,1.75,1.12,1.43
	c0.26-0.07,0.62-0.51,0.6-0.76C554.63,502.1,553.83,502.44,552.97,502.58z M513.2,502.65c0.05,0.54,0.1,1.04,0.16,1.63
	c0.64-0.17,1.13-0.31,1.59-0.43C514.97,502.38,514.3,502.26,513.2,502.65z M528.53,500.8c-0.44,0.51-0.81,0.77-0.89,1.09
	c-0.05,0.19,0.41,0.72,0.59,0.7c0.37-0.04,0.72-0.35,1.08-0.55C529.1,501.71,528.89,501.38,528.53,500.8z M534.93,502.08
	c0.65-0.04,1.52,0.44,1.5-0.73c0-0.28-0.53-0.77-0.83-0.78C534.39,500.52,534.83,501.34,534.93,502.08z M509.34,499.31
	c0.06,0.48,0.11,0.94,0.16,1.45c0.57-0.08,1.02-0.14,1.5-0.2C511.2,499.06,510.59,498.81,509.34,499.31z M532.6,498.64
	c0.2-1.52-0.44-1.76-1.7-1.24c0.07,0.45,0.13,0.91,0.2,1.42C531.67,498.75,532.12,498.7,532.6,498.64z M538.15,496.95
	c0.05,0.44,0.1,0.89,0.17,1.42c0.63-0.1,1.13-0.19,1.59-0.27C539.92,496.56,539.25,496.44,538.15,496.95z M507.15,497.21
	c0.25-1.54-0.43-1.73-1.68-1.28c0.06,0.47,0.12,0.92,0.19,1.44C506.23,497.31,506.69,497.26,507.15,497.21z M512.59,496.58
	c0.24,0.18,0.47,0.35,0.71,0.53c0.38-0.34,0.98-0.63,1.08-1.04c0.17-0.71-0.49-1.04-1.04-0.76
	C512.96,495.5,512.83,496.14,512.59,496.58z M558.92,402.66c0.5-0.06,0.93-0.12,1.43-0.18c-0.11-0.62-0.2-1.1-0.28-1.58
	C558.45,400.86,558.75,401.74,558.92,402.66z M497.93,490.88c1.49,0.25,1.51-0.56,1.19-1.65
	C497.59,488.94,497.58,489.75,497.93,490.88z M548.27,407.18c0.71-0.08,1.21-0.13,1.73-0.18c-0.32-1.45-0.87-1.82-1.73-1.23
	C548.27,406.12,548.27,406.5,548.27,407.18z M542.68,407.48c0.22-1.54-0.51-1.66-1.71-1.3c0.07,0.5,0.13,0.96,0.21,1.47
	C541.74,407.59,542.19,407.53,542.68,407.48z M544.69,411.2c1.02-0.25,1.92,0.05,1.87-1.09c-0.04-0.76-0.68-1-1.11-0.68
	C545.06,409.71,544.99,410.43,544.69,411.2z M513.04,480.76c-0.63,0.15-1.53-0.27-1.53,0.88c0,0.72,0.44,0.95,1.07,0.8
	C513.72,482.17,513.1,481.41,513.04,480.76z M532.96,480.85c0.79-0.45,1.82-0.06,1.59-1.25c-0.21-1.11-0.98-0.63-1.7-0.49
	C532.87,479.58,532.9,480.02,532.96,480.85z M540.13,480.13c0.73-0.11,1.23-0.19,1.77-0.27c-0.55-1.4-1.03-1.68-1.77-1.16
	C540.13,479.07,540.13,479.46,540.13,480.13z M507.41,477.65c0.07,0.55,0.14,1.04,0.28,2.05c0.69-0.66,1.39-1.02,1.39-1.39
	C509.09,477.14,508.23,477.51,507.41,477.65z M556.34,478.52c-0.45-1.31-0.45-1.31-1.89-1.21c0.07,0.45,0.13,0.88,0.2,1.36
	C555.24,478.61,555.7,478.57,556.34,478.52z M514.71,478.84c0.88-0.24,1.84-0.04,1.6-1.17c-0.24-1.1-0.97-0.66-1.67-0.42
	C514.66,477.74,514.68,478.19,514.71,478.84z M550.42,412.63c-0.6,0.17-1.62-0.31-1.43,0.93c0.04,0.25,0.71,0.6,1.04,0.55
	C551.05,413.95,550.58,413.24,550.42,412.63z M537.87,476.58c0.21-1.48-0.37-1.78-1.65-1.25c0.05,0.47,0.09,0.92,0.14,1.44
	C536.94,476.69,537.39,476.63,537.87,476.58z M510.86,475.33c0.65-0.08,1.1-0.14,1.6-0.2c0.17-1.46-0.44-1.71-1.6-1.29
	C510.86,474.29,510.86,474.67,510.86,475.33z M517.98,473.28c0.06,0.59,0.1,1.03,0.15,1.56c0.62-0.07,1.11-0.13,1.62-0.2
	C519.41,473.35,519.41,473.35,517.98,473.28z M543.32,413.04c-0.82,0.18-1.77-0.07-1.54,1.06c0.06,0.28,0.62,0.68,0.93,0.66
	C543.9,414.69,543.33,413.84,543.32,413.04z M567.4,580.59c-0.2,0.22-0.4,0.45-0.59,0.67c0.36,0.35,0.72,0.69,1.08,1.04
	c0.26-0.29,0.52-0.58,0.77-0.87C568.24,581.14,567.82,580.86,567.4,580.59z M558.1,419.2c-0.8-0.1-1.59-0.17-1.34,0.98
	c0.05,0.25,0.53,0.61,0.79,0.59C558.83,420.66,558.23,419.84,558.1,419.2z M507.76,472.29c0.42-0.56,0.65-0.87,0.88-1.17
	c-0.32-0.24-0.61-0.59-0.96-0.66c-0.2-0.04-0.7,0.43-0.68,0.63C507.03,471.43,507.38,471.73,507.76,472.29z M566.2,427.22
	c-0.1-0.68-0.18-1.23-0.26-1.73c-1.68-0.02-1.56,0.85-1.19,1.95C565.19,427.37,565.63,427.31,566.2,427.22z M551.81,428.48
	c-0.11-0.72-0.18-1.21-0.26-1.71c-1.41,0.32-1.76,0.92-1.16,1.71C550.77,428.48,551.15,428.48,551.81,428.48z M504.43,466.99
	c-0.55,0.08-1.03,0.15-1.56,0.22c0.1,0.65,0.18,1.15,0.25,1.63C504.9,468.98,504.64,468.04,504.43,466.99z M543.04,427.73
	c0.01,0.66-0.55,1.56,0.74,1.46c0.31-0.03,0.84-0.51,0.83-0.77C544.58,427.26,543.79,427.57,543.04,427.73z M536.55,427.88
	c-0.37,0.55-0.7,0.85-0.74,1.18c-0.02,0.18,0.48,0.62,0.69,0.59c0.35-0.06,0.64-0.41,0.96-0.63
	C537.22,428.72,536.98,428.42,536.55,427.88z M523.05,430.99c0.17-1.33-0.33-1.73-1.68-1.31c0.04,0.55,0.08,1.06,0.13,1.64
	C522.07,431.2,522.53,431.1,523.05,430.99z M539.7,433.35c0.68-0.44,1.86,0,1.53-1.32c-0.26-1.03-1-0.61-1.66-0.33
	C539.61,432.21,539.65,432.66,539.7,433.35z M517.92,433.56c0.41,0.59,0.64,1.07,1.02,1.37c0.13,0.11,0.83-0.16,0.9-0.38
	c0.13-0.38-0.02-0.86-0.06-1.37C519.21,433.29,518.77,433.38,517.92,433.56z M510.96,434.05c-0.18,1,0.01,1.57,1.07,1.38
	c1-0.19,0.72-0.8,0.47-1.53C511.96,433.95,511.45,434,510.96,434.05z M550.73,435.79c0.71-0.11,1.6,0.23,1.47-0.93
	c-0.04-0.31-0.49-0.84-0.74-0.83C550.12,434.08,550.72,435,550.73,435.79z M536.4,435.41c-0.02,1.47,0.6,1.69,1.76,1.23
	c-0.03-0.52-0.07-0.97-0.1-1.53C537.44,435.22,536.94,435.32,536.4,435.41z M536.73,462.1c-0.04-0.55-0.07-1-0.11-1.56
	c-0.61,0.11-1.11,0.2-1.63,0.3C534.9,462.13,535.36,462.55,536.73,462.1z M509.46,459.28c0.36,1.57,0.88,1.9,1.81,1.28
	c-0.03-0.4-0.07-0.85-0.1-1.28C510.56,459.28,510.1,459.28,509.46,459.28z M518.38,458.63c-0.8,0.18-1.75-0.11-1.55,1.03
	c0.05,0.29,0.6,0.7,0.9,0.69C518.92,460.31,518.4,459.45,518.38,458.63z M572.61,583.71c-0.68,0.46-1.86-0.08-1.56,1.28
	c0.24,1.08,0.98,0.63,1.66,0.37C572.68,584.87,572.65,584.42,572.61,583.71z M514.8,456.33c-0.55-0.44-0.86-0.88-1.16-0.87
	c-0.26,0.01-0.63,0.5-0.7,0.83c-0.04,0.21,0.39,0.73,0.59,0.72C513.88,456.99,514.21,456.67,514.8,456.33z M519.74,456.3
	c0.13,0.14,0.26,0.28,0.39,0.42c0.51-0.11,1.01-0.23,1.58-0.35c-0.07-0.91-0.19-1.68-1.19-1.36
	C520.17,455.12,520,455.85,519.74,456.3z M519.28,448.97c0.15,0.15,0.29,0.29,0.44,0.44c0.49-0.07,0.99-0.14,1.5-0.22
	c-0.06-0.9-0.05-1.74-1.1-1.42C519.74,447.89,519.55,448.56,519.28,448.97z M534.33,453.63c-0.09,0.98-0.06,1.72,1.04,1.44
	c0.26-0.07,0.63-0.5,0.61-0.75C535.93,453.17,535.13,453.5,534.33,453.63z M516.2,453.19c0.66-0.08,1.56,0.41,1.53-0.79
	c-0.01-0.28-0.55-0.78-0.86-0.79C515.62,451.58,516.12,452.45,516.2,453.19z M541.79,565.06c-1.03-0.2-1.41,0.25-1.21,1.15
	c0.06,0.24,0.57,0.59,0.79,0.54C542.59,566.47,541.87,565.7,541.79,565.06z M547.38,564.12c0.09,1.31,0.21,2.01,1.11,1.74
	c0.29-0.08,0.43-0.65,0.64-0.99C548.65,564.66,548.17,564.46,547.38,564.12z M551.26,460.34c-0.39-0.42-0.61-0.67-0.84-0.92
	c-0.29,0.27-0.7,0.5-0.82,0.83c-0.07,0.19,0.39,0.81,0.51,0.79C550.47,460.97,550.78,460.66,551.26,460.34z M536.91,442.71
	c-0.07,0.86,0.11,1.52,1.09,1.3c0.26-0.06,0.63-0.71,0.55-0.98C538.24,441.98,537.54,442.5,536.91,442.71z M546.2,442.76
	c-0.58-0.36-0.9-0.69-1.25-0.72c-0.25-0.02-0.73,0.34-0.77,0.59c-0.12,0.69,0.32,1.05,1,0.9
	C545.46,443.46,545.69,443.14,546.2,442.76z M554.67,564.1c0.05,0.49,0.1,0.93,0.16,1.49c0.68-0.22,1.66,0.26,1.51-0.97
	C556.21,563.43,555.42,563.94,554.67,564.1z M519.69,440.2c-0.45,0.49-0.94,0.79-0.92,1.05c0.02,0.32,0.44,0.79,0.75,0.85
	c0.25,0.05,0.79-0.39,0.84-0.67C520.42,441.14,520.02,440.77,519.69,440.2z M552.94,561.33c-0.63-0.42-0.93-0.76-1.27-0.8
	c-0.25-0.02-0.79,0.46-0.76,0.56c0.14,0.37,0.4,0.84,0.72,0.94C551.88,562.11,552.28,561.7,552.94,561.33z M542.14,439.06
	c-0.2-0.21-0.4-0.43-0.6-0.64c-0.41,0.29-1.05,0.51-1.19,0.89c-0.2,0.59,0.28,1.11,0.92,0.86
	C541.65,440.03,541.86,439.45,542.14,439.06z M515.84,436.78c-0.46,0.57-0.94,0.92-0.9,1.18c0.06,0.32,0.51,0.66,0.87,0.79
	c0.15,0.05,0.69-0.41,0.69-0.64C516.5,437.76,516.19,437.41,515.84,436.78z M522.09,436.95c-0.15,1.06,0.36,1.37,1.24,1.13
	c0.19-0.05,0.43-0.51,0.39-0.72C523.45,436.14,522.71,436.77,522.09,436.95z M543.15,459.85c-0.44,0.54-0.92,0.88-0.87,1.13
	c0.06,0.31,0.51,0.64,0.86,0.75c0.16,0.05,0.69-0.4,0.68-0.6C543.8,460.78,543.49,460.45,543.15,459.85z M545.51,435.88
	c-0.48-0.5-0.73-0.88-1.07-1.04c-0.14-0.06-0.77,0.45-0.73,0.56c0.11,0.37,0.38,0.77,0.7,0.95
	C544.56,436.45,544.97,436.12,545.51,435.88z M576.44,587.38c-0.76,0.06-1.71-0.32-1.52,0.89c0.04,0.28,0.62,0.68,0.95,0.67
	C576.89,588.93,576.59,588.2,576.44,587.38z M567.55,587.94c-0.06,1.12,0.2,1.73,1.2,1.48c1.01-0.26,0.53-0.92,0.34-1.48
	C568.61,587.94,568.16,587.94,567.55,587.94z M520.65,462c-0.08,1.48,0.44,1.74,1.17,1.51c0.23-0.07,0.31-0.58,0.46-0.89
	C521.79,462.43,521.3,462.25,520.65,462z M514.47,464.62c0.31-0.67,0.6-1.03,0.58-1.37c-0.01-0.22-0.55-0.64-0.72-0.58
	c-0.34,0.11-0.81,0.5-0.81,0.77C513.51,463.73,513.98,464.04,514.47,464.62z M548.53,432.56c-0.03-1.46-0.5-1.79-1.25-1.54
	c-0.22,0.07-0.31,0.58-0.45,0.89C547.32,432.09,547.8,432.28,548.53,432.56z M554.95,429.86c-0.46,0.57-0.83,0.85-0.91,1.19
	c-0.05,0.22,0.36,0.53,0.57,0.8c0.31-0.26,0.74-0.47,0.89-0.8C555.59,430.85,555.25,430.46,554.95,429.86z M562.18,429.16
	c-0.44,0.49-0.92,0.79-0.9,1.06c0.02,0.32,0.45,0.8,0.75,0.84c0.27,0.04,0.81-0.39,0.87-0.68
	C562.95,430.09,562.54,429.72,562.18,429.16z M540.92,465c-0.58-0.46-0.86-0.84-1.2-0.9c-0.25-0.04-0.58,0.33-0.87,0.51
	c0.23,0.35,0.39,0.87,0.7,1C539.81,465.71,540.25,465.33,540.92,465z M532.53,464.4c-0.41,0.43-0.65,0.68-0.88,0.92
	c0.31,0.27,0.58,0.65,0.94,0.78c0.17,0.06,0.75-0.44,0.71-0.6C533.22,465.15,532.88,464.87,532.53,464.4z M552.06,466.68
	c-1.51,0-2.21,0.1-1.94,1.13c0.06,0.23,0.75,0.55,0.81,0.48C551.27,467.95,551.51,467.51,552.06,466.68z M544.69,467.9
	c-0.71-0.2-1.1-0.38-1.49-0.38c-0.17,0-0.47,0.38-0.48,0.6c-0.03,0.61,0.39,0.86,0.93,0.68C543.94,468.7,544.16,468.37,544.69,467.9
	z M529,470.37c0.23,0.13,0.46,0.26,0.69,0.4c0.11-0.36,0.31-0.71,0.32-1.07c0.02-0.51,0.11-1.22-0.74-1.12
	c-0.26,0.03-0.65,0.45-0.67,0.71C528.57,469.64,528.85,470.01,529,470.37z M530.37,555.21c-0.94,0.01-1.74-0.01-1.43,1.08
	c0.07,0.25,0.52,0.56,0.78,0.55C530.95,556.79,530.39,555.94,530.37,555.21z M528.24,423.21c1.06,0.22,1.41-0.27,1.19-1.16
	c-0.06-0.24-0.6-0.58-0.82-0.52C527.51,421.86,528.09,422.59,528.24,423.21z M548.51,471.36c-0.47-0.38-0.72-0.59-0.97-0.79
	c-0.23,0.33-0.53,0.64-0.67,1.01c-0.04,0.12,0.41,0.59,0.5,0.56C547.72,472,548.02,471.72,548.51,471.36z M546.45,416.2
	c-0.4,0.53-0.86,0.87-0.81,1.13c0.05,0.32,0.5,0.74,0.82,0.78c0.25,0.03,0.75-0.45,0.77-0.74
	C547.26,417.07,546.84,416.74,546.45,416.2z M538.34,409.18c-0.25,0.7-0.5,1.06-0.47,1.4c0.03,0.31,0.36,0.59,0.56,0.88
	c0.32-0.24,0.73-0.43,0.91-0.75C539.41,410.58,539,410.17,538.34,409.18z M520.56,481.08c-0.62-0.33-0.95-0.59-1.31-0.66
	c-0.14-0.03-0.56,0.47-0.53,0.52c0.21,0.32,0.46,0.69,0.79,0.82C519.67,481.83,520.03,481.44,520.56,481.08z M531.73,483.85
	c-0.44-0.46-0.7-0.93-0.89-0.9c-0.38,0.05-0.73,0.37-1.09,0.59c0.21,0.28,0.4,0.77,0.64,0.8
	C530.73,484.38,531.12,484.09,531.73,483.85z M517.45,485.15c-0.57-0.45-0.87-0.88-1.17-0.88c-0.31,0-0.62,0.42-0.93,0.66
	c0.27,0.3,0.49,0.76,0.82,0.84C516.44,485.84,516.83,485.47,517.45,485.15z M557.01,484.88c-0.53-0.3-0.86-0.61-1.2-0.63
	c-0.22-0.01-0.65,0.4-0.66,0.64c-0.04,0.61,0.3,1.08,0.99,0.87C556.42,485.68,556.6,485.31,557.01,484.88z M529,547.65
	c-0.43,0.55-0.8,0.83-0.85,1.16c-0.04,0.22,0.42,0.75,0.6,0.72c0.36-0.05,0.89-0.32,0.96-0.61
	C529.78,548.66,529.36,548.26,529,547.65z M535.55,487.25c-0.46-0.52-0.67-0.95-1-1.06c-0.26-0.09-0.65,0.22-0.98,0.36
	c0.18,0.37,0.28,0.91,0.57,1.07C534.4,487.75,534.88,487.45,535.55,487.25z M504.82,488.37c-0.03,1.24-0.13,1.99,0.87,1.78
	c0.31-0.07,0.53-0.57,0.79-0.87C506.02,489.03,505.57,488.79,504.82,488.37z M525.92,545.99c0.12-1.06-0.42-1.34-1.28-1.08
	c-0.18,0.05-0.41,0.53-0.36,0.73C524.6,546.8,525.31,546.19,525.92,545.99z M557.73,491.2c-1.16,0.25-1.66,0.36-2.13,0.46
	c-0.01,1.03,0.36,1.61,1.28,1.27C557.18,492.81,557.28,492.14,557.73,491.2z M518.22,500.33c0.09-1.42-0.23-1.9-1.07-1.68
	c-0.24,0.06-0.38,0.52-0.57,0.8C517.03,499.7,517.49,499.94,518.22,500.33z M547.54,398.46c0.02,0.5,0.04,1.01,0.07,1.65
	c0.71-0.21,1.62,0.2,1.58-0.97C549.15,397.97,548.36,398.31,547.54,398.46z M506.9,502.59c-0.37,0.51-0.77,0.85-0.71,1.02
	c0.14,0.36,0.52,0.62,0.8,0.92c0.24-0.24,0.69-0.51,0.67-0.72C507.61,503.45,507.26,503.14,506.9,502.59z M540.31,505.32
	c0.1-1.34-0.43-1.67-1.67-1.21c0.05,0.5,0.1,1,0.17,1.59C539.42,505.54,539.86,505.43,540.31,505.32z M561.83,396.73
	c0.08,1.3,0.15,1.98,1.07,1.75c0.29-0.07,0.47-0.6,0.7-0.92C563.13,397.34,562.65,397.11,561.83,396.73z M516.02,510.37
	c-0.65-0.36-1.01-0.72-1.31-0.67c-0.3,0.05-0.53,0.51-0.79,0.79c0.31,0.25,0.6,0.67,0.93,0.7
	C515.13,511.23,515.45,510.8,516.02,510.37z M506.68,510.3c-0.07,0.85,0,1.55,1.02,1.38c0.27-0.05,0.69-0.65,0.63-0.92
	C508.12,509.57,507.34,510.22,506.68,510.3z M534.25,512.25c-0.7-0.33-1.06-0.63-1.4-0.62c-0.24,0.01-0.71,0.56-0.66,0.65
	c0.18,0.34,0.5,0.77,0.83,0.83C533.28,513.15,533.63,512.71,534.25,512.25z M513.37,536.82c0.37-0.46,0.8-0.86,0.74-0.96
	c-0.2-0.32-0.54-0.65-0.89-0.74c-0.19-0.05-0.76,0.49-0.72,0.59C512.65,536.06,512.97,536.34,513.37,536.82z M530.62,515.78
	c-0.15-0.15-0.31-0.29-0.46-0.44c-0.48,0.08-0.97,0.17-1.47,0.25c0.12,0.84,0.05,1.74,1.12,1.37
	C530.17,516.84,530.35,516.19,530.62,515.78z M521.5,516.84c0.48,0.44,0.72,0.81,1.05,0.9c0.16,0.05,0.68-0.43,0.67-0.65
	c-0.02-0.35-0.34-0.69-0.54-1.04C522.36,516.26,522.04,516.48,521.5,516.84z M509.6,531.32c-0.46,0.57-0.94,0.92-0.89,1.17
	c0.06,0.32,0.51,0.66,0.86,0.78c0.15,0.05,0.69-0.41,0.69-0.64C510.27,532.29,509.95,531.95,509.6,531.32z M516.76,517.77
	c-0.59-0.48-0.9-0.92-1.2-0.91c-0.31,0-0.61,0.45-0.91,0.7c0.23,0.24,0.43,0.67,0.68,0.69
	C515.68,518.28,516.06,518.02,516.76,517.77z M541.84,519.02c-0.44-0.48-0.71-0.77-0.97-1.05c-0.27,0.28-0.76,0.55-0.77,0.83
	c-0.01,0.31,0.35,0.78,0.66,0.92C540.94,519.8,541.35,519.35,541.84,519.02z M527.69,520.25c-0.49-0.43-0.78-0.87-0.98-0.84
	c-0.36,0.07-0.68,0.42-1.01,0.65c0.23,0.24,0.44,0.67,0.68,0.69C526.72,520.78,527.09,520.5,527.69,520.25z M531.6,523.74
	c-0.48-0.53-0.72-1.04-1.03-1.08c-0.3-0.04-0.74,0.33-0.96,0.64c-0.08,0.1,0.29,0.76,0.52,0.8
	C530.49,524.16,530.91,523.92,531.6,523.74z M526.24,528.39c0.71-0.42,1.18-0.61,1.51-0.94c0.08-0.08-0.2-0.76-0.46-0.89
	c-0.28-0.15-0.73,0-1.16,0.02C526.16,527.08,526.18,527.46,526.24,528.39z M531.85,529.56c-0.63,0.43-1.67-0.13-1.52,1.09
	c0.13,1.15,0.91,0.69,1.62,0.52C531.92,530.69,531.89,530.25,531.85,529.56z M526.81,533.45c0,1.46,0.37,1.97,1.28,1.66
	c0.21-0.07,0.43-0.84,0.36-0.88C528.04,533.95,527.54,533.78,526.81,533.45z M550.46,513.36c-0.14,1.43,0.19,1.89,1.02,1.67
	c0.24-0.06,0.38-0.52,0.57-0.8C551.6,513.99,551.16,513.75,550.46,513.36z M585.51,235.95c-1.6,0.03-1.51,0.84-1.09,1.89
	C585.87,237.92,585.9,237.14,585.51,235.95z M537.35,508c-0.17-0.14-0.34-0.27-0.51-0.41c-0.47,0.3-1.55-0.15-1.23,1.08
	c0.07,0.28,0.64,0.42,0.98,0.63C536.84,508.86,537.09,508.43,537.35,508z M531.52,506.04c0.72-0.12,1.68,0.25,1.55-0.93
	c-0.13-1.17-0.91-0.75-1.64-0.52C531.46,505.08,531.49,505.53,531.52,506.04z M542.34,485.19c-0.66,0.54-1.37,0.85-1.39,1.19
	c-0.07,1.19,0.76,0.87,1.55,0.68C542.46,486.58,542.42,486.13,542.34,485.19z M510.19,484.51c-1.14,0.23-1.63,0.32-2.1,0.42
	c0.02,0.97,0.32,1.61,1.25,1.28C509.63,486.1,509.74,485.44,510.19,484.51z M548.21,571.69c-0.07,0.92-0.1,1.67,1,1.41
	c0.26-0.06,0.64-0.47,0.63-0.7C549.8,571.28,549.01,571.58,548.21,571.69z M560.82,414.93c-0.34,0.53-0.66,0.85-0.7,1.2
	c-0.02,0.15,0.61,0.59,0.81,0.52c0.32-0.12,0.61-0.52,0.72-0.86C561.68,415.64,561.23,415.34,560.82,414.93z M539.59,471.47
	c0.04,0.49,0.09,0.94,0.14,1.49c0.61-0.12,1.1-0.22,1.56-0.3C541.28,471.48,540.95,470.92,539.59,471.47z M540.42,416.85
	c-1.14,0.23-1.63,0.32-2.1,0.42c0.02,0.98,0.31,1.61,1.24,1.28C539.86,418.44,539.97,417.78,540.42,416.85z M560.52,422.42
	c0.08,0.72-0.3,1.65,0.92,1.51c1.2-0.14,0.74-0.91,0.53-1.64C561.48,422.34,561.02,422.38,560.52,422.42z M511.55,441.13
	c0.07,0.72-0.27,1.63,0.91,1.5c1.18-0.13,0.73-0.9,0.52-1.61C512.5,441.05,512.05,441.09,511.55,441.13z M538.23,458.64
	c0.84-0.18,1.79,0.02,1.53-1.14c-0.06-0.26-0.52-0.61-0.79-0.61C537.69,456.88,538.24,457.78,538.23,458.64z M541.38,454.33
	c0.16,0.11,0.33,0.23,0.49,0.34c0.43-0.07,0.85-0.14,1.3-0.22c0.02-0.89-0.08-1.64-1.07-1.36
	C541.77,453.19,541.61,453.9,541.38,454.33z M552.31,569.66c0.45-0.54,0.91-0.85,0.89-1.12c-0.03-0.3-0.46-0.67-0.79-0.79
	c-0.16-0.06-0.7,0.38-0.71,0.61C551.68,568.69,551.99,569.03,552.31,569.66z M558.22,559.97c-0.16,1.04,0.02,1.6,0.87,1.4
	c0.27-0.07,0.43-0.61,0.63-0.93C559.24,560.28,558.75,560.13,558.22,559.97z M526.68,552.67c-0.57-0.36-0.88-0.64-1.23-0.74
	c-0.13-0.04-0.57,0.44-0.54,0.5c0.19,0.35,0.43,0.71,0.76,0.93C525.76,553.43,526.16,553.03,526.68,552.67z M529.33,541.64
	c-0.47-0.37-0.8-0.8-0.95-0.74c-0.32,0.12-0.66,0.46-0.75,0.78c-0.05,0.18,0.42,0.74,0.58,0.71
	C528.55,542.34,528.83,542,529.33,541.64z M510.12,538.55c-0.37,0.55-0.73,0.86-0.68,1.08c0.08,0.34,0.45,0.6,0.69,0.9
	c0.23-0.23,0.66-0.47,0.65-0.7C510.77,539.48,510.45,539.15,510.12,538.55z M530.8,536.69c-0.05,1.32-0.03,2.02,0.91,1.84
	c0.28-0.06,0.48-0.58,0.71-0.89C531.99,537.39,531.56,537.14,530.8,536.69z M504.39,513.82c-0.38,0.43-0.76,0.67-0.78,0.92
	c-0.01,0.27,0.33,0.56,0.51,0.84c0.3-0.25,0.7-0.46,0.87-0.78C505.07,514.66,504.68,514.28,504.39,513.82z M539.4,510.94
	c-0.08,1.42,0.36,1.76,1.12,1.52c0.23-0.07,0.32-0.56,0.47-0.86C540.51,511.4,540.04,511.2,539.4,510.94z M553.35,495.11
	c-0.37,0.43-0.74,0.67-0.75,0.93c-0.01,0.26,0.32,0.55,0.51,0.82c0.29-0.25,0.68-0.45,0.83-0.77
	C554.01,495.94,553.64,495.57,553.35,495.11z M534.91,495.28c0.39-0.5,0.82-0.83,0.77-1.02c-0.1-0.34-0.49-0.6-0.75-0.89
	c-0.2,0.27-0.54,0.52-0.57,0.81C534.33,494.46,534.63,494.76,534.91,495.28z M543.27,493.72c-0.47-0.42-0.74-0.84-0.96-0.82
	c-0.36,0.04-0.68,0.37-1.01,0.58c0.21,0.28,0.4,0.77,0.63,0.78C542.29,494.29,542.66,493.99,543.27,493.72z M517.96,492.1
	c-0.67-0.33-1.02-0.63-1.36-0.62c-0.18,0-0.5,0.54-0.48,0.82c0.02,0.26,0.46,0.72,0.63,0.68
	C517.09,492.91,517.37,492.56,517.96,492.1z M539.49,490.36c-0.59-0.39-0.89-0.74-1.23-0.77c-0.23-0.02-0.5,0.39-0.76,0.61
	c0.28,0.29,0.52,0.68,0.86,0.81C538.55,491.08,538.91,490.71,539.49,490.36z M534.66,448.2c0.28-0.48,0.64-0.86,0.56-1.01
	c-0.16-0.3-0.56-0.48-0.85-0.71c-0.18,0.28-0.5,0.57-0.49,0.84C533.9,447.57,534.28,447.79,534.66,448.2z M517.32,445.32
	c-0.39-0.44-0.7-0.93-0.81-0.89c-0.34,0.12-0.72,0.41-0.85,0.73c-0.07,0.18,0.34,0.79,0.49,0.78
	C516.5,445.91,516.82,445.61,517.32,445.32z M548.09,424.7c-0.48-0.42-0.74-0.78-1.08-0.9c-0.15-0.05-0.69,0.47-0.66,0.64
	c0.08,0.34,0.38,0.72,0.7,0.88C547.2,425.4,547.59,425.02,548.09,424.7z M541.12,400.72c0.42-0.51,0.79-0.75,0.8-1.02
	c0.01-0.29-0.25-0.75-0.5-0.83c-0.22-0.07-0.8,0.28-0.81,0.46C540.59,399.7,540.86,400.07,541.12,400.72z M545.39,450.65
	c0.21,0.12,0.42,0.24,0.63,0.36c0.13-0.36,0.37-0.73,0.34-1.07c-0.03-0.26-0.39-0.68-0.62-0.69c-0.27-0.01-0.71,0.29-0.79,0.55
	C544.89,450.02,545.23,450.36,545.39,450.65z M537.6,484.19c0.39-0.52,0.75-0.79,0.74-1.04c-0.02-0.3-0.3-0.73-0.57-0.81
	c-0.2-0.06-0.77,0.33-0.77,0.51C537.01,483.21,537.3,483.57,537.6,484.19z"
              />
              <path
                d="M369.75,231.89c0.84-0.18,1.68-0.35,2.54-0.53c0.18-0.92,0.13-2.05,0.64-2.8c0.45-0.68,1.52-0.95,2.5-1.51
	c-0.03-0.4-0.08-1.01-0.14-1.74c-2.18-0.21-4.16-0.7-3.2-3.56c0.35-1.05,1.07-1.26,2.92-1.33c0.19-0.98,0.11-2.12,0.63-2.81
	c0.54-0.71,1.66-0.96,2.64-1.48c0.05-0.67,0.11-1.6,0.18-2.53c0.13-0.15,0.26-0.31,0.38-0.46c0.94-0.29,1.87-0.58,2.99-0.92
	c-0.01-0.08-0.1-0.44-0.1-0.8c-0.02-2.08,1.19-3.03,3.2-2.51c0.37,0.1,0.72,0.28,1.09,0.42c0.17,0.81,0.35,1.62,0.52,2.44
	c0.79-0.09,1.38-0.15,1.97-0.22c0.05-0.83,0.1-1.51,0.15-2.25c-0.91,0-1.76,0-2.62,0c-0.24-0.41-0.53-0.8-0.7-1.23
	c-0.52-1.33,0.14-2.69,1.43-3.03c1.25-0.33,2.34,0.33,2.7,1.69c0.12,0.44,0.1,0.92,0.15,1.4c0.93-0.12,1.76-0.23,2.58-0.34
	c0.06-2.53,0.69-3.57,2.17-3.7c1.41-0.12,2.23,0.8,2.68,3.01c0.06,0.04,0.12,0.13,0.18,0.12c0.62-0.08,1.24-0.17,1.85-0.26
	c0.24-2.28,0.94-3.26,2.34-3.32c1.31-0.06,2.08,0.82,2.56,2.99c0.82-0.11,1.65-0.22,2.47-0.33c0.1-2.37,0.73-3.27,2.24-3.28
	c1.47,0,2.11,0.85,2.39,3.01c1.1,0.12,2.15,0.23,3.2,0.34l-0.07-0.06c0.49,0.78,0.99,1.56,1.58,2.5c0.31,0,0.83,0,1.53,0
	c0.03-0.39,0-0.77,0.09-1.11c0.51-1.91,2.07-2.69,3.52-1.77c0.81,0.51,1.18,1.25,1.09,2.2c-0.06,0.62-0.19,1.23-0.29,1.85
	c-0.81,0.13-1.62,0.26-2.4,0.39c0.01,1.32,0.2,2.24,1.7,1.72c0.21-0.72,0.43-1.44,0.64-2.16c0.88-1.59,2.36-1.08,3.7-1.08
	c0.15,0.16,0.31,0.32,0.46,0.47c0.24,0.75,0.48,1.5,0.74,2.34c0.75,0.04,1.52,0.09,2.3,0.13c-0.01-2.25,0.66-3.34,2.06-3.38
	c1.05-0.04,2,0.79,2.14,1.86c0.19,1.48-0.66,2.21-3.04,2.56c0.12,0.68,0.25,1.35,0.38,2.07c0.62,0,1.17,0,1.67,0
	c0.45-0.96,0.79-1.99,1.38-2.85c0.24-0.34,1-0.45,1.53-0.45c1.4,0.01,1.99,0.81,2.38,3.21c1.27-0.36,2.53-0.3,3.31,0.96
	c0.46,0.76,0.82,1.61-0.15,2.32c-0.26,1.54-1.68,1.09-2.7,1.53c0.17,0.64,0.35,1.3,0.53,1.99c0.65-0.08,1.19-0.14,1.71-0.2
	c0.16-1.16,0.31-2.24,0.46-3.33c0.45,0,0.9,0.05,1.34,0.01c1.53-0.15,2.62,0.5,2.93,1.79c0.28,1.18-0.34,2.22-1.7,2.76
	c-0.36,0.14-0.76,0.18-1.17,0.27c0.1,0.67,0.2,1.29,0.3,1.97c0.66,0,1.28,0,1.99,0c-0.09-1.97,0.33-3.77,2.88-3.41
	c1.59,0.23,1.94,1.67,1.83,3.1c2.66-0.02,3.62,0.52,3.77,2.04c0.15,1.57-0.76,2.33-3.28,2.64c0.16,0.68,0.32,1.35,0.5,2.1
	c0.71,0,1.39,0,2.07,0c0.12-2.44,0.68-3.29,2.15-3.36c1.19-0.05,2.19,0.77,2.34,1.93c0.2,1.49-0.77,2.3-3.17,2.64
	c0.1,0.85,0.19,1.69,0.28,2.42c1.02,0.07,1.95,0.14,2.89,0.21c0.17,0.15,0.35,0.3,0.52,0.46c0.14,0.45,0.35,0.9,0.4,1.36
	c0.19,1.67-0.99,2.89-2.69,2.85c-0.61-0.02-1.21-0.01-1.81-0.02c-0.12-1.13-0.25-2.26-0.39-3.45c-0.72,0.15-1.38,0.29-2.16,0.45
	c0.06,0.79,0.12,1.52,0.14,1.84c1.01,0.48,1.72,0.82,2.43,1.15c1.14,0.6,0.95,1.57,0.55,2.44c-0.4,0.87-1.22,1.25-2.19,1.08
	c-1.43-0.25-1.79-1.39-1.97-2.63c-0.9,0-1.74,0-2.45,0c-0.33,1-0.52,1.98-0.99,2.81c-0.21,0.37-0.95,0.59-1.46,0.62
	c-1.27,0.08-1.95-0.84-2.36-2.98c-0.94-0.07-1.88-0.14-2.81-0.2c-0.17-0.2-0.35-0.4-0.52-0.59c-0.36-0.77-0.72-1.54-1.07-2.27
	c-0.62,0-1.24,0-1.7,0c-0.18,1.03-0.34,1.94-0.51,2.85c-0.11,0.15-0.21,0.29-0.32,0.44c-0.31,0.07-0.62,0.15-0.93,0.2
	c-1.93,0.32-2.89-0.34-3.17-2.21c-0.05-0.37-0.01-0.75-0.01-1.12c-2.57-0.05-3.51-0.55-3.73-1.91c-0.24-1.46,0.55-2.29,2.89-2.91
	c-0.04-0.59-0.09-1.21-0.15-1.99c-2.12,0.04-3.8-0.6-3.83-2.94c-1.06-0.36-2.2-0.46-2.9-1.07c-0.63-0.54-0.79-1.63-1.16-2.49
	c-0.73-0.07-1.59-0.16-2.46-0.24l0.08,0.07c-0.52-0.95-1.04-1.89-1.72-3.12c-0.33,0.03-1,0.09-1.64,0.15
	c-0.37,2.27-1.02,3.16-2.32,3.24c-1.25,0.08-1.98-0.72-2.64-2.94c-0.6,0.11-1.21,0.23-1.88,0.36c0,0.72,0,1.33,0,2.1
	c0.37,0.02,0.75,0.01,1.12,0.08c1.54,0.29,2.37,1.32,2.13,2.61c-0.27,1.41-1.56,2.09-3.15,1.62c-0.16-0.05-0.25-0.37-0.37-0.56
	c-0.45-0.72-0.89-1.44-1.4-2.27c-0.41,0.06-1.02,0.14-1.62,0.22c0,0.69,0,1.22,0,1.88c1.05,0.06,2.04,0.12,3.03,0.17
	c0,0.16-0.03,0.32,0,0.47c0.62,3.25,0.45,3.58-2.28,4.65c-0.37,1.26-0.69,2.37-1.02,3.49l0.07-0.08c-0.84,0.15-1.68,0.3-2.61,0.47
	c0,0.8,0,1.57,0,2.34c2.17-0.04,3.09,0.5,3.21,1.89c0.1,1.19-0.75,2.21-1.93,2.29c-1.36,0.1-2.1-0.87-2.27-3
	c-0.85,0.08-1.7,0.17-2.37,0.24c-0.33,1.06-0.53,2.09-1,2.98c-0.19,0.37-0.95,0.6-1.46,0.62c-1.46,0.08-2.14-1-2.17-3.31
	c-0.92,0.14-1.83,0.28-2.81,0.43c0,0.43,0.07,0.91-0.01,1.36c-0.22,1.24-1.21,2.05-2.31,1.98c-1.16-0.07-1.92-0.91-2.03-2.23
	c-0.04-0.54-0.07-1.08-0.1-1.62c1-0.26,1.99-0.52,2.9-0.76c-0.09-0.99-0.16-1.81-0.22-2.5c-1.06-0.13-2.02-0.25-2.99-0.36
	c-0.2-0.9-0.39-1.79-0.61-2.77c-0.8,0.16-1.52,0.31-2.18,0.45c0,0.79,0,1.42,0,2.04c0.98,0.08,1.91,0.15,2.83,0.23
	c0.59,1.2,1.46,2.37,0.3,3.72c-2.57,1.7-3.91,1.02-4.39-2.42c-0.65,0.06-1.32,0.13-1.98,0.2c-0.39,3.04-0.63,3.31-3.39,3.89
	c-0.05,1.56-0.13,3.23-2.22,3.52c-1.41,0.2-2.22-0.66-2.84-2.91c-0.92-0.08-1.86-0.16-2.79-0.24c-0.16-0.15-0.32-0.31-0.48-0.46
	c-0.97-2.86-0.61-3.63,2.32-4.7c0.03-0.53,0.06-1.14,0.09-1.88c-3.07-0.21-4.18-1.64-2.78-4.52L369.75,231.89z M391.84,234.35
	c0.09,0.82,0.18,1.56,0.27,2.37c0.75-0.14,1.35-0.25,1.98-0.36c0-0.73,0-1.33,0-2.01C393.3,234.35,392.62,234.35,391.84,234.35z
	 M393.49,227.26c-0.82,0-1.43,0-2.12,0c0.09,0.88,0.17,1.69,0.26,2.53c0.75-0.18,1.35-0.32,2.03-0.48
	C393.6,228.66,393.55,228.05,393.49,227.26z M388.47,231.48c0,0.79,0,1.41,0,2.07c0.89,0,1.72,0,2.59,0
	c-0.17-0.9-0.32-1.72-0.48-2.56C389.85,231.16,389.23,231.31,388.47,231.48z M431.9,234.84c0,0.85,0,1.59,0,2.38
	c0.86-0.17,1.6-0.32,2.37-0.48c-0.15-0.72-0.28-1.32-0.43-2C433.14,234.77,432.53,234.81,431.9,234.84z M448.09,225.88
	c-0.75,0.12-1.5,0.24-2.32,0.37c0.13,0.72,0.24,1.38,0.36,2.05c0.78,0,1.46,0,2.17,0C448.23,227.47,448.17,226.72,448.09,225.88z
	 M384.94,213.28c-0.8,0.04-1.45,0.06-2.15,0.09c0.2,0.82,0.37,1.48,0.52,2.1C385.06,215.69,385.29,214.81,384.94,213.28z
	 M410.02,209.51c0.11-0.84,0.2-1.51,0.3-2.28c-0.81,0.16-1.48,0.3-2.26,0.46c0.15,0.71,0.27,1.3,0.4,1.93
	C409.02,209.58,409.41,209.55,410.02,209.51z M436.35,216.13c-0.73,0.11-1.4,0.21-2.2,0.32c0.18,0.72,0.32,1.3,0.45,1.81
	c0.67,0,1.2,0,1.75,0C436.35,217.53,436.35,216.91,436.35,216.13z M396.12,240.49c0.93-0.17,1.6-0.3,2.39-0.44
	c-0.23-0.69-0.43-1.32-0.66-2c-0.57,0.05-1.02,0.09-1.6,0.13C396.21,238.93,396.17,239.61,396.12,240.49z M374,234.64
	c0.72-0.16,1.32-0.29,2-0.44c-0.06-0.61-0.12-1.13-0.18-1.71c-0.77,0-1.4,0-2.14,0C373.78,233.16,373.88,233.84,374,234.64z
	 M395.09,225.41c0.63-0.09,1.22-0.17,1.87-0.26c-0.04-0.64-0.07-1.17-0.1-1.66c-0.75,0-1.35,0-2.03,0
	C394.92,224.16,394.99,224.7,395.09,225.41z M444.83,237.37c-0.65,0.47-1.24,0.75-1.58,1.21c-0.21,0.29-0.04,0.85-0.04,1.35
	c0.82-0.1,1.49-0.17,2.2-0.26C445.23,238.95,445.08,238.35,444.83,237.37z M379.7,217.45c0.14,0.73,0.25,1.26,0.37,1.89
	c0.6-0.05,1.12-0.1,1.69-0.15c0-0.73,0-1.33,0-1.97C381.09,217.3,380.54,217.36,379.7,217.45z M393.43,208.89
	c0.22,0.73,0.39,1.31,0.57,1.9c0.62-0.04,1.06-0.07,1.58-0.11c0-0.66,0-1.19,0-1.79C394.91,208.89,394.3,208.89,393.43,208.89z
	 M378.42,221.42c-0.7,0-1.31,0-2.03,0c0.1,0.67,0.19,1.26,0.29,1.94c0.74-0.12,1.34-0.21,1.98-0.31
	C378.56,222.41,378.5,221.96,378.42,221.42z M381.58,233.75c0.69,0,1.29,0,1.97,0c-0.09-0.64-0.17-1.17-0.26-1.81
	c-0.65,0.11-1.17,0.2-1.79,0.31C381.53,232.81,381.56,233.27,381.58,233.75z M377.5,230.28c1.64,0.29,2.06-0.29,1.56-1.73
	c-0.58,0.04-1.16,0.09-1.78,0.14C377.36,229.28,377.42,229.74,377.5,230.28z M402.79,210.11c0-0.63,0-1.22,0-1.95
	c-0.69,0.13-1.24,0.24-1.75,0.34C400.96,210.01,401.42,210.64,402.79,210.11z M402.21,222.99c0.16,0.87-0.16,1.73,1.08,1.58
	c1.21-0.15,0.81-0.86,0.63-1.58C403.43,222.99,402.98,222.99,402.21,222.99z M393.09,219.94c-0.78,0.53-1.3,0.75-1.6,1.15
	c-0.1,0.13,0.27,0.79,0.56,1.02c0.23,0.17,0.69,0.03,1.16,0.03C393.18,221.49,393.16,221.05,393.09,219.94z M379.82,235.72
	c-0.75,0.14-1.25,0.24-1.72,0.33c-0.12,1.63,0.6,1.92,1.99,1.45C380.01,236.98,379.93,236.46,379.82,235.72z M446.93,235.37
	c1.49-0.19,1.49-0.19,1.09-1.73C446.79,233.52,446.47,234.07,446.93,235.37z M397.41,230.73c-0.78,0.13-1.34,0.22-1.93,0.32
	c0.09,0.62,0.15,1.07,0.22,1.52C397.27,232.27,397.27,232.27,397.41,230.73z M376.73,241.62c0.01-1.38-0.34-2.12-1.87-1.39
	c0.04,0.5,0.08,1.02,0.12,1.61C375.66,241.76,376.19,241.69,376.73,241.62z M414.63,220.22c-0.34-0.58-0.49-1.12-0.84-1.33
	c-0.21-0.13-0.9,0.17-1.08,0.46c-0.19,0.32-0.04,0.85-0.04,1.4C413.35,220.57,413.85,220.43,414.63,220.22z M390,226.18
	c-0.12-0.69-0.19-1.14-0.3-1.75c-0.72,0.23-1.76-0.2-1.61,1.1C388.23,226.67,389.11,226.09,390,226.18z M439.06,227.06
	c-0.23,0.89-0.06,1.51,0.93,1.37c0.98-0.14,0.6-0.82,0.4-1.5C439.94,226.97,439.5,227.02,439.06,227.06z M444.27,230.26
	c-0.79,0.07-1.59-0.08-1.35,0.94c0.06,0.25,0.55,0.58,0.82,0.57C444.8,231.71,444.44,231,444.27,230.26z M419.93,219.72
	c0.97,0.02,1.67-0.08,1.44-1.1c-0.2-0.89-0.82-0.66-1.44-0.41C419.93,218.67,419.93,219.13,419.93,219.72z M380.34,226.52
	c1.45,0,2.1-0.17,1.84-1.15c-0.06-0.23-0.76-0.53-0.8-0.48C381.07,225.26,380.83,225.71,380.34,226.52z M424.79,213.86
	c-0.93-0.11-1.61,0.07-1.32,1.04c0.1,0.34,0.79,0.5,1.56,0.96C424.91,214.82,424.85,214.33,424.79,213.86z M394.8,216.02
	c0,1.19,0,1.74,0,2.29c0.67-0.18,1.1-0.54,1.1-0.9C395.89,217.08,395.41,216.76,394.8,216.02z M385.89,221.65
	c-0.52-0.27-0.86-0.59-1.09-0.52c-0.26,0.08-0.59,0.51-0.58,0.77c0.01,0.22,0.49,0.59,0.73,0.57
	C385.22,222.45,385.45,222.05,385.89,221.65z M404.57,230.1c-0.96-0.05-1.52,0.24-1.28,1.19c0.24,0.95,0.88,0.56,1.52,0.31
	C404.73,231.09,404.65,230.58,404.57,230.1z M424.02,223.06c0.91,0.09,1.59,0.02,1.33-1.03c-0.06-0.26-0.55-0.64-0.77-0.6
	C423.42,221.64,423.99,222.41,424.02,223.06z M386.33,228.27c-0.94-0.02-1.54,0.27-1.31,1.21c0.23,0.98,0.9,0.51,1.54,0.3
	C386.48,229.27,386.41,228.76,386.33,228.27z M400.11,221.35c0.09-0.18,0.18-0.35,0.27-0.53c-0.36-0.29-0.69-0.75-1.1-0.84
	c-0.73-0.16-0.95,0.38-0.85,1.03c0.03,0.2,0.17,0.39,0.29,0.64C399.22,221.54,399.66,221.45,400.11,221.35z M399.82,234.45
	c-0.2,1.17,0.36,1.39,1.18,1.2c0.2-0.04,0.47-0.4,0.46-0.59C401.37,233.91,400.59,234.32,399.82,234.45z M390.54,213.09
	c-0.01,1.12,0.22,1.69,1.09,1.42c0.23-0.07,0.33-0.57,0.49-0.87C391.64,213.47,391.14,213.3,390.54,213.09z M412.06,211.49
	c0.4,0.55,0.7,0.96,1,1.38c0.25-0.3,0.73-0.61,0.72-0.91C413.75,210.89,413.02,211.26,412.06,211.49z M435.95,225.53
	c0.35-0.37,0.76-0.61,0.76-0.85c0.01-0.29-0.33-0.58-0.52-0.88c-0.33,0.25-0.66,0.5-0.99,0.76
	C435.42,224.83,435.62,225.1,435.95,225.53z M428.49,226.51c0.37-0.51,0.78-0.88,0.71-1.02c-0.17-0.34-0.52-0.64-0.87-0.79
	c-0.11-0.05-0.66,0.47-0.63,0.66C427.77,225.69,428.12,225.99,428.49,226.51z M400.62,228.83c0.02-1.45-0.2-2.09-1.19-1.78
	c-0.21,0.07-0.47,0.8-0.39,0.87C399.39,228.23,399.87,228.42,400.62,228.83z M427.15,217.25c0.17,0.58-0.09,1.39,0.93,1.22
	c0.23-0.04,0.59-0.55,0.55-0.79C428.5,216.68,427.84,217.08,427.15,217.25z M388.15,216.72c-0.31,0.62-0.56,0.91-0.57,1.21
	c-0.01,0.22,0.3,0.45,0.47,0.67c0.25-0.22,0.66-0.41,0.71-0.67C388.79,217.67,388.48,217.35,388.15,216.72z M405.38,211.67
	c-0.17,0.23-0.35,0.46-0.52,0.69c0.4,0.29,0.8,0.58,1.2,0.86c0.16-0.3,0.5-0.81,0.43-0.88
	C406.19,212.06,405.76,211.89,405.38,211.67z M398.51,212.33c-0.25,0.52-0.53,0.84-0.48,1.08c0.06,0.25,0.45,0.43,0.69,0.64
	c0.21-0.26,0.57-0.52,0.57-0.78C399.29,213.02,398.91,212.78,398.51,212.33z M417.88,215.24c-0.5-0.28-0.81-0.61-1.07-0.57
	c-0.23,0.04-0.57,0.51-0.53,0.74c0.04,0.25,0.45,0.61,0.7,0.62C417.22,216.03,417.46,215.63,417.88,215.24z M409.5,217.3
	c0.45-0.53,0.85-0.8,0.83-1.03c-0.03-0.29-0.41-0.53-0.64-0.8c-0.2,0.19-0.55,0.37-0.57,0.58
	C409.09,216.35,409.29,216.67,409.5,217.3z M439.53,236.23c1.48-0.41,1.31-1.04,0.97-1.7c-0.04-0.09-0.27-0.08-0.64-0.17
	C439.76,234.89,439.67,235.4,439.53,236.23z M406.35,221.11c0.4-0.47,0.77-0.72,0.76-0.96c0-0.27-0.34-0.54-0.54-0.81
	c-0.26,0.22-0.66,0.4-0.73,0.67C405.78,220.24,406.09,220.58,406.35,221.11z M432.11,229.76c0.46-0.38,0.96-0.66,0.92-0.79
	c-0.1-0.36-0.38-0.72-0.68-0.93c-0.1-0.07-0.72,0.32-0.74,0.53C431.58,228.9,431.87,229.25,432.11,229.76z M402.49,217.6
	c0.3-0.42,0.65-0.79,0.59-0.86c-0.19-0.26-0.52-0.42-0.8-0.62c-0.13,0.24-0.37,0.48-0.36,0.72
	C401.93,217.04,402.21,217.24,402.49,217.6z M435.71,232.88c0.47-0.43,0.81-0.74,1.15-1.05c-0.22-0.17-0.43-0.46-0.66-0.48
	C435.41,231.29,435.3,231.75,435.71,232.88z M432.74,221.04c-0.33-0.2-0.6-0.48-0.74-0.42c-0.23,0.09-0.39,0.38-0.57,0.59
	c0.25,0.21,0.49,0.41,0.74,0.62C432.33,221.61,432.48,221.4,432.74,221.04z"
              />
              <path
                d="M287.31,239.6c-0.41-0.98-0.82-1.96-1.26-3c-0.71-0.15-1.82-0.12-2.55-0.62c-0.68-0.47-0.95-1.52-1.46-2.42
	c-0.71-0.08-1.63-0.19-2.55-0.29c-0.14-0.14-0.29-0.28-0.43-0.41c-0.26-0.82-0.52-1.64-0.83-2.66c-0.49,0.08-1.07,0.18-1.55,0.25
	c-0.36,0.97-0.49,2-1.06,2.64c-0.51,0.57-1.49,0.73-2.43,1.13c0,0.6,0,1.44,0,2.37c0.47,0,1.03-0.07,1.57,0.01
	c1.11,0.16,1.63,0.94,1.72,1.98c0.09,1.03-0.77,1.97-1.87,2.1c-0.69,0.08-1.38,0.08-2.07,0.11c-0.13-1.04-0.26-2.07-0.4-3.18
	c-0.83,0.21-1.5,0.38-2.2,0.56c0.06,0.77,0.1,1.37,0.13,1.66c0.98,0.37,1.75,0.65,2.51,0.94c1.2,0.72,1.22,1.78,0.64,2.84
	c-0.54,0.99-1.47,1.36-2.61,1.11c-0.44-0.1-0.9-0.1-1.35-0.16c-0.15-0.87-0.31-1.73-0.45-2.53c-1.43,0.03-2.29,0.35-1.91,1.8
	c0.81,0.22,1.62,0.45,2.42,0.67c1.04,0.86,1.38,1.92,0.77,3.17c-0.51,1.04-1.27,1.35-3.06,1.38c-0.26,0.99-0.39,1.98-0.79,2.84
	c-0.18,0.39-0.89,0.66-1.4,0.75c-1.59,0.27-2.39-0.64-2.78-3.11c-0.43,0.04-0.87,0.08-1.32,0.1c-0.38,0.02-0.77,0-1.22,0
	c0.03,0.52,0.11,0.91,0.06,1.28c-0.19,1.45-1.03,2.22-2.33,2.18c-1.28-0.04-2.08-0.86-2.18-2.32c-0.03-0.39,0.07-0.79,0.11-1.18
	c0.11-0.13,0.22-0.25,0.33-0.38c0.93-0.3,1.86-0.59,2.81-0.9c-0.17-0.71-0.33-1.38-0.46-1.91c-1.06-0.23-1.96-0.43-2.87-0.63
	l0.09,0.08c-0.25-0.95-0.51-1.91-0.78-2.92c-0.76,0.05-1.52,0.09-2.1,0.13c-0.2,1.16-0.37,2.21-0.55,3.27c0,0,0.07-0.04,0.07-0.04
	c-0.99,0.25-1.97,0.5-2.96,0.74c0.03,3.14-0.57,3.71-3.79,3.61c-0.19-0.18-0.38-0.36-0.57-0.54c-0.33-0.77-0.66-1.53-0.96-2.24
	c-0.71,0-1.32,0-1.82,0c-0.2,1-0.38,1.89-0.55,2.79l0.07-0.09c-0.42,0.2-0.83,0.47-1.27,0.6c-1.4,0.41-2.66-0.22-3.05-1.47
	c-0.4-1.3,0.21-2.47,1.61-2.99c0.36-0.13,0.76-0.15,1.23-0.24c0-0.63,0-1.24,0-1.9c-1-0.25-1.97-0.49-2.94-0.73
	c0,0,0.08,0.08,0.08,0.08c-0.76-1.21-0.9-2.49-0.44-3.84l-0.08,0.08c0.85-0.41,1.7-0.82,2.7-1.3c0-0.51,0-1.19,0-1.87
	c-2.51-0.33-3.46-1.04-3.4-2.51c0.06-1.44,1-2.08,3.15-2.23c0.17-1.01,0.07-2.16,0.58-2.87c0.52-0.73,1.65-1.03,2.59-1.57
	c0.03-2.78,0.64-3.39,3.57-3.45c-0.36-3.14,0.1-3.75,2.98-4.04c0.33-1.04,0.44-2.21,1.04-3.02c0.43-0.58,1.51-0.68,2.27-0.99
	c0.28-0.89,0.48-1.94,0.94-2.85c0.19-0.37,0.95-0.59,1.47-0.62c1.41-0.09,2.17,1,2.35,3.2c1.08,0,2.16,0,3.24,0
	c0.18,0.17,0.36,0.33,0.54,0.5c0.29,0.79,0.57,1.57,0.84,2.29c0.69,0,1.23,0,1.79,0c0.1-0.98,0.19-1.9,0.29-2.82l-0.09,0.09
	c1.23-0.53,2.44-1.27,3.85-0.54c0.17,0.15,0.34,0.3,0.51,0.45c0.25,0.8,0.49,1.6,0.74,2.4c0.64-0.03,1.18-0.06,1.62-0.08
	c0.21-1,0.39-1.88,0.57-2.77l-0.07,0.06c2.75-1.86,4.25-1.05,4.53,2.53c0.82-0.1,1.64-0.2,2.37-0.29c0.31-0.98,0.49-1.98,0.95-2.83
	c0.2-0.36,0.96-0.55,1.48-0.59c1.14-0.07,2.14,0.99,2.14,2.12c0,1.31-0.93,2-3.06,2.25c0.12,0.78,0.24,1.56,0.35,2.25
	c1.16,0.3,2.29,0.59,3.42,0.88l-0.08-0.08c0.18,0.83,0.36,1.66,0.55,2.54c0.94,0.11,1.91,0.23,2.89,0.34
	c0.09,0.06,0.18,0.13,0.26,0.21c0.2,0.99,0.4,1.97,0.58,2.88c0.89,0.24,1.96,0.4,2.91,0.83c0.38,0.17,0.61,0.93,0.67,1.44
	c0.16,1.36-0.71,2.08-2.94,2.52c0,0.74,0,1.5,0,2.43c0.48,0,0.93-0.04,1.37,0.01c1.65,0.17,2.52,1.18,2.36,2.67
	c-0.15,1.37-1.15,2.13-2.71,2.04c-0.39-0.02-0.78-0.16-1.16-0.24L287.31,239.6z M258.29,241.99c0.39,0.82,0.79,1.64,1.22,2.56
	c0.49-0.08,1.08-0.18,1.79-0.29c-0.06-0.73-0.11-1.38-0.15-1.9c-1.1-0.11-2.03-0.2-2.95-0.3c-0.34-0.94-0.68-1.88-1.05-2.89
	c-0.54,0.1-1.07,0.19-1.64,0.3c0,0.83,0,1.53,0,2.07C256.49,241.7,257.39,241.85,258.29,241.99z M279.64,226.29
	c0.07,0.81,0.12,1.42,0.19,2.16c0.75-0.08,1.41-0.15,2.11-0.23c-0.09-0.78-0.16-1.38-0.24-2.08
	C281,226.19,280.46,226.23,279.64,226.29z M263.36,245.95c0,0.86,0,1.46,0,2.18c0.8-0.06,1.49-0.11,2.24-0.17
	c-0.14-0.74-0.25-1.36-0.37-2.01C264.62,245.95,264.08,245.95,263.36,245.95z M283.42,229.47c0.19,0.79,0.35,1.47,0.51,2.18
	c0.67-0.06,1.27-0.11,1.9-0.16c-0.06-0.89-0.12-1.64-0.18-2.46C284.92,229.18,284.3,229.3,283.42,229.47z M247.4,245.66
	c-0.15-0.72-0.27-1.31-0.41-1.97c-0.7,0.1-1.23,0.17-1.91,0.26c0.08,0.61,0.16,1.13,0.24,1.7
	C245.99,245.66,246.53,245.66,247.4,245.66z M245.56,229.22c-0.7,0.11-1.3,0.2-2,0.31c0.12,0.66,0.23,1.22,0.34,1.85
	c0.69-0.07,1.22-0.12,1.88-0.18C245.7,230.54,245.64,229.95,245.56,229.22z M239.96,233.02c0.13,0.84,0.25,1.57,0.37,2.34
	c0.7-0.1,1.3-0.18,2.01-0.27c-0.07-0.67-0.13-1.19-0.19-1.79C241.41,233.2,240.74,233.12,239.96,233.02z M250.67,241.9
	c-0.1-0.89-0.17-1.54-0.25-2.33c-0.74,0.24-1.3,0.42-1.89,0.61c0.06,0.65,0.1,1.17,0.15,1.72C249.36,241.9,249.9,241.9,250.67,241.9
	z M253.24,217.56c0.17,0.7,0.33,1.34,0.49,1.99c0.7-0.08,1.22-0.13,1.81-0.2c0.07-0.77,0.12-1.44,0.19-2.18
	C254.86,217.3,254.12,217.42,253.24,217.56z M274.16,219.04c-0.94,0.26-1.6,0.44-2.23,0.61c0.08,1.33,0.47,2.13,2.02,1.55
	C274.01,220.58,274.08,219.9,274.16,219.04z M247.08,227.35c1.55,0.03,2.01-0.64,1.52-2.06c-0.69,0-1.34,0-2.06,0
	C246.74,226.05,246.91,226.71,247.08,227.35z M249.95,221.37c0.18,0.81,0.31,1.41,0.46,2.08c0.64-0.13,1.15-0.23,1.72-0.35
	c-0.07-0.68-0.12-1.2-0.17-1.74C251.28,221.37,250.73,221.37,249.95,221.37z M277.75,224.62c0-0.55,0-1.14,0-1.84
	c-0.68,0.08-1.28,0.15-1.87,0.22C275.8,224.4,276.19,225.13,277.75,224.62z M260.56,235.25c-0.77,0.05-1.36,0.09-2.03,0.13
	c0.17,0.76,0.31,1.36,0.45,2.02c0.67-0.11,1.18-0.2,1.75-0.29C260.68,236.48,260.64,236.02,260.56,235.25z M262.08,231.52
	c0.1,0.74,0.17,1.24,0.25,1.83c0.71-0.1,1.22-0.17,1.76-0.25c-0.04-0.59-0.07-1.05-0.1-1.59
	C263.38,231.52,262.86,231.52,262.08,231.52z M271.39,232.49c-0.04-0.56-0.07-1.07-0.12-1.7c-0.71,0.1-1.3,0.18-2,0.28
	c0.11,0.62,0.19,1.11,0.28,1.63C270.21,232.62,270.74,232.56,271.39,232.49z M251.66,236.16c0.04,0.72,0.08,1.32,0.12,1.99
	c0.69-0.1,1.21-0.17,1.85-0.26c-0.11-0.69-0.21-1.27-0.31-1.88C252.77,236.06,252.38,236.1,251.66,236.16z M262.32,238.71
	c0.11,0.75,0.18,1.27,0.25,1.76c1.57,0.14,1.96-0.45,1.46-1.76C263.46,238.71,263,238.71,262.32,238.71z M241.25,242.49
	c0.78-0.14,1.29-0.23,2.18-0.4c-0.47-0.67-0.7-1.19-1.1-1.49c-0.21-0.16-0.71,0.07-1.07,0.13
	C241.25,241.3,241.25,241.75,241.25,242.49z M272.58,228.92c0.88,0.02,1.82,0.3,1.65-1.04c-0.17-1.35-1.02-0.77-1.84-0.72
	C272.46,227.85,272.52,228.38,272.58,228.92z M268.25,236.3c-0.47-0.68-0.69-1.2-1.08-1.49c-0.22-0.16-0.71,0.05-1.15,0.1
	c0.05,0.56,0.09,1,0.15,1.59C266.8,236.45,267.3,236.4,268.25,236.3z M260.02,228.21c-0.93-0.1-1.78-0.14-1.55,1.08
	c0.05,0.27,0.65,0.66,0.96,0.63C260.82,229.77,260.06,228.88,260.02,228.21z M255.06,234.14c0.72-0.05,1.23-0.09,2.23-0.17
	c-0.54-0.75-0.81-1.28-1.23-1.64c-0.17-0.14-0.68,0.12-1,0.2C255.06,233.05,255.06,233.44,255.06,234.14z M266.34,220.28
	c-0.55,0.07-1.04,0.13-1.56,0.2c0.12,0.79-0.07,1.72,1.04,1.53C266.9,221.84,266.5,221.05,266.34,220.28z M262.36,223.85
	c-0.39,0.68-0.75,1.03-0.72,1.34c0.03,0.32,0.44,0.61,0.69,0.91c0.26-0.3,0.68-0.57,0.73-0.9
	C263.1,224.9,262.74,224.54,262.36,223.85z M247.91,232.95c-0.19,1.56,0.47,1.76,1.62,1.29
	C249.82,232.89,249.24,232.56,247.91,232.95z M244.7,238.51c0.5-0.56,1.62,0.16,1.5-1.1c-0.02-0.22-0.52-0.53-0.82-0.55
	C244.49,236.78,244.33,237.32,244.7,238.51z M270.83,225.4c-0.51-0.66-0.83-1.08-1.16-1.51c-0.26,0.32-0.71,0.63-0.73,0.96
	C268.9,225.89,269.64,225.63,270.83,225.4z M252.06,228.56c-0.4,0.59-0.8,0.91-0.79,1.22c0.02,0.29,0.49,0.8,0.72,0.77
	c0.31-0.03,0.78-0.44,0.83-0.75C252.86,229.52,252.45,229.16,252.06,228.56z M266.96,229.43c0.13-0.15,0.26-0.29,0.39-0.44
	c-0.34-0.38-0.69-1.1-1.01-1.08c-1.14,0.06-0.79,0.86-0.59,1.52C266.19,229.43,266.58,229.43,266.96,229.43z M259.05,220.79
	c-0.5,0.45-1.07,0.72-1.2,1.13c-0.17,0.53,0.3,0.85,0.88,0.71C259.69,222.39,259.34,221.74,259.05,220.79z M254.53,227.1
	c0.67-0.49,1.38-0.75,1.41-1.07c0.1-1.12-0.7-0.91-1.53-0.71C254.44,225.76,254.47,226.19,254.53,227.1z"
              />
              <path
                d="M651.85,485.7c0.1,1.27,0.21,2.55,0.31,3.82c0,0-0.01,0-0.01,0c-1.39,0.59-2.78,0.74-4.16-0.01l0.09,0.07
	c-0.22-0.89-0.43-1.78-0.64-2.63c-0.92-0.09-1.82-0.19-2.72-0.28c-0.17-0.15-0.34-0.31-0.51-0.46c-0.49-1.12-0.48-2.24-0.02-3.37
	c0.93-0.34,1.86-0.67,2.84-1.02c-0.04-0.74-0.09-1.48-0.13-2.22c-0.89,0.05-1.65,0.09-2.33,0.13c-0.11,1.07-0.21,2.12-0.32,3.18
	c-0.94,0.29-1.89,0.58-2.94,0.9c0,0.73,0,1.58,0,2.43c-0.19,0.16-0.38,0.32-0.58,0.49c-1,0.96-2.16,0.58-3.29,0.38l0.1,0.08
	c-0.42-0.96-0.83-1.91-1.28-2.95c-0.7,0-1.47,0-2.23,0c-0.15-0.19-0.3-0.38-0.45-0.57c-0.66-0.76-0.99-1.58-0.6-2.6
	c0.53-1.39,1.81-1.55,3.04-1.69c0-1.05,0-1.91,0-2.77c0.18-0.18,0.35-0.36,0.53-0.54c2.99-0.79,3.69-0.36,4.45,2.81
	c0.75-0.03,1.51-0.07,2.28-0.11c-0.06-0.9-0.11-1.69-0.16-2.47c-2.95,0.18-3.43-0.19-4-3.09c-0.91,0-1.85,0-2.79,0
	c-0.16-0.31-0.32-0.61-0.49-0.92c-0.08-1.05-0.17-2.11-0.25-3.16c0.06-0.06,0.13-0.13,0.19-0.19c1.02-0.24,2.04-0.47,3.06-0.71
	c-0.19-0.8-0.35-1.46-0.54-2.22c-0.82,0-1.58,0-2.35,0c-0.15-0.19-0.3-0.38-0.45-0.58c-0.78-0.8-0.93-1.74-0.63-2.78
	c0.23-0.31,0.47-0.62,0.7-0.94c1.15-0.74,2.28-0.39,3.41,0.04l-0.09-0.08c0.28,0.94,0.56,1.88,0.84,2.8
	c0.81-0.04,1.56-0.08,2.14-0.11c0.14-1.1,0.27-2.08,0.4-3.06c0.13-0.17,0.26-0.34,0.39-0.51c1.07-0.06,2.19-0.56,3.14,0.37
	c0,0-0.08-0.08-0.08-0.08c1.18,0.75,0.86,1.93,0.84,3.05c2.03,0.52,1.59,2.57,2.65,3.88c-0.72,0.35-1.26,0.61-1.81,0.88
	c0,0.77,0,1.54,0,2.32c2.18,0.07,2.81,0.68,2.95,2.81c0.1,1.42-0.19,1.63-2.66,1.89c0.08,0.38,0.19,0.75,0.23,1.12
	c0.04,0.39,0.01,0.79,0.01,1.36c0.33,0,0.61-0.01,0.89,0c2.09,0.12,2.71,0.76,2.69,2.79c0,0.4-0.08,0.79-0.13,1.19l0.07-0.07
	c-0.92,0.37-1.85,0.74-2.91,1.16c0.04,0.52,0.08,1.12,0.11,1.59c1.2,0.25,2.24,0.47,3.28,0.69L651.85,485.7z M643.3,465.33
	c0.05,3.26-0.37,3.75-3.4,3.96c0.19,0.82,0.39,1.63,0.57,2.41c0.81-0.07,1.5-0.12,1.97-0.16c0.26-1.09,0.22-2.28,0.79-2.96
	c0.56-0.67,1.74-0.81,2.67-1.19c-0.07-0.67-0.15-1.43-0.23-2.18C644.85,465.26,644.07,465.29,643.3,465.33z M646.66,475.04
	c-0.1-1-0.19-1.82-0.27-2.6c-0.93,0.08-1.7,0.15-2.44,0.21c0.05,0.83,0.11,1.6,0.16,2.39C644.89,475.04,645.66,475.04,646.66,475.04
	z M639.73,480.38c-0.75,0.05-1.48,0.09-2.3,0.14c0.11,0.89,0.19,1.56,0.27,2.28c0.81-0.09,1.48-0.17,2.19-0.25
	C639.84,481.78,639.79,481.16,639.73,480.38z"
              />
              <path
                d="M379.12,390.13c-0.08-0.99-0.16-1.99-0.25-2.98c-2.32,0.21-3.44-0.4-3.59-1.96c-0.12-1.25,0.81-2.4,2.05-2.52
	c1.52-0.15,2.37,0.98,2.5,3.37c0.83-0.07,1.67-0.13,2.43-0.19c0.3-1.02,0.48-2.08,0.93-3c0.18-0.38,0.94-0.61,1.46-0.66
	c1.36-0.13,2.11,0.84,2.56,3.13c0.76-0.07,1.53-0.14,2.3-0.21c-0.15-2.35,0.36-3.34,1.82-3.55c1.29-0.19,2.4,0.66,2.54,1.94
	c0.17,1.51-0.65,2.23-3.01,2.63c0.04,0.76,0.07,1.54,0.12,2.41c0.91,0.09,1.83,0.17,2.75,0.26c0.17,0.15,0.33,0.31,0.5,0.46
	c0.23,0.9,0.47,1.8,0.71,2.72c2.43-0.1,3.42,0.36,3.6,1.88c0.07,0.59,0.02,1.46-0.35,1.77c-0.78,0.66-1.8,1.04-2.53,1.43
	c-0.19,0.98-0.37,1.88-0.54,2.79l0.07-0.05c-1.01,0.33-2.02,0.65-2.98,0.96c-0.17,0.78-0.23,1.8-0.63,2.66
	c-0.21,0.46-0.99,0.8-1.56,0.9c-1.62,0.28-2.45-0.78-2.71-3.14c-1.1-0.14-2.16-0.28-3.22-0.42c-0.15-0.06-0.29-0.13-0.43-0.21
	c-0.1-0.97-0.2-1.94-0.29-2.83c-1,0.05-1.85,0.1-2.7,0.14c-0.14-0.19-0.29-0.37-0.43-0.56c-0.81-0.92-1.11-1.93-0.41-3.04
	c0.75-1.18,1.87-1.32,3.25-0.97c-0.2-0.88-0.37-1.68-0.51-2.28c-1.27-0.35-2.4-0.66-3.53-0.96L379.12,390.13z M388.35,397.52
	c0.08,0.85,0.14,1.53,0.21,2.21c0.84-0.05,1.53-0.09,2.01-0.12c0.25-1.1,0.21-2.28,0.76-2.97c0.55-0.68,1.7-0.86,2.52-1.22
	c0-0.75,0-1.51,0-2.27c-0.93,0.08-1.75,0.16-2.58,0.23C391.54,396.34,391.4,396.56,388.35,397.52z M390.18,392.46
	c-0.09-0.87-0.17-1.7-0.26-2.59c-0.77,0.09-1.52,0.19-2.33,0.28c0.04,0.8,0.08,1.55,0.12,2.31
	C388.57,392.46,389.34,392.46,390.18,392.46z M384.27,396.48c0.99-0.23,1.71-0.4,2.44-0.58c-0.09-0.79-0.17-1.45-0.24-2.11
	c-0.88,0.12-1.62,0.23-2.4,0.34C384.14,394.93,384.2,395.61,384.27,396.48z M386.24,388.89c-0.09-0.84-0.17-1.53-0.25-2.26
	c-0.84,0.11-1.58,0.21-2.36,0.32c0.09,0.76,0.17,1.38,0.25,2.06C384.63,388.96,385.33,388.93,386.24,388.89z"
              />
              <path
                d="M394.24,389.28c-0.17-0.15-0.33-0.31-0.5-0.46c-0.1-0.77-0.4-1.57-0.27-2.29c0.27-1.46,1.39-1.87,3.63-1.55l-0.06-0.06
	c0.5,0.94,0.99,1.89,1.58,3c0.47-0.04,1.22-0.11,1.79-0.16c0.2-1.23,0.38-2.29,0.55-3.35l-0.08,0.07c0.89-0.23,1.78-0.45,2.72-0.69
	c0.09-0.88,0.19-1.8,0.28-2.72c0.13-0.14,0.26-0.27,0.39-0.41c0.94-0.27,1.89-0.55,2.73-0.79c0.11-0.94,0.21-1.79,0.31-2.65
	c0.11-0.11,0.21-0.24,0.31-0.36c0.98-0.15,1.95-0.29,2.93-0.44c0.18,0.13,0.35,0.27,0.53,0.4c0.36,0.85,0.73,1.7,1.1,2.59
	c0.59,0,1.28,0,1.78,0c0.39-1.15,0.54-2.36,1.17-3.21c0.75-1.01,2.03-0.49,3.11-0.36c0.13,0.09,0.25,0.19,0.37,0.3
	c0.44,1.18,0.65,2.36,0.06,3.55c-0.09,0.14-0.18,0.28-0.27,0.42c-0.89,0.14-1.78,0.28-2.62,0.41c0,0.91,0,1.82,0,2.73
	c-0.3,0.24-0.61,0.49-0.91,0.73c-0.81,0.2-1.62,0.4-2.23,0.55c-0.27,1.07-0.49,1.96-0.71,2.84l0.07-0.08
	c-0.91,0.42-1.81,0.84-2.88,1.33c0.05,0.58,0.11,1.33,0.16,1.92c1.02,0.16,1.86,0.29,2.71,0.42l0.15,0.1
	c0.23,0.41,0.57,0.79,0.68,1.22c0.32,1.31-0.38,2.45-1.58,2.74c-1.27,0.31-2.34-0.28-2.74-1.59c-0.13-0.44-0.11-0.93-0.17-1.43
	c-0.78,0.08-1.56,0.15-2.45,0.24c-0.1,1.04-0.19,2.1-0.29,3.16c0,0,0.08-0.08,0.08-0.08c-1.12,0.17-2.23,0.35-3.35,0.52
	c-0.19-0.14-0.37-0.27-0.56-0.41c-0.35-0.93-0.7-1.86-1.05-2.8c-2.76-0.07-3.13-0.38-3.7-3.12
	C396.07,389.45,395.15,389.36,394.24,389.28z M401.95,391.41c1-0.13,1.81-0.23,2.6-0.33c-0.14-0.82-0.25-1.5-0.38-2.29
	c-0.78,0.05-1.52,0.1-2.22,0.15C401.95,389.82,401.95,390.52,401.95,391.41z M405.31,385.06c0.05,0.89,0.08,1.58,0.12,2.38
	c0.78-0.09,1.49-0.17,2.26-0.26c-0.18-0.83-0.33-1.49-0.49-2.24C406.58,384.98,406.05,385.02,405.31,385.06z M410.76,381.06
	c-0.92,0.07-1.67,0.13-2.43,0.18c0.15,0.78,0.27,1.4,0.39,2.04c0.73-0.08,1.42-0.15,2.16-0.22
	C410.84,382.43,410.81,381.88,410.76,381.06z"
              />
              <path
                d="M671.53,581.9c-0.17-0.08-0.34-0.16-0.52-0.23c-0.65-0.86-1.25-1.89-0.49-2.81c0.68-0.82,1.77-1.31,2.85-2.07
	c-0.03-0.39-0.07-1.08-0.12-1.85c-0.99,0-1.92,0-2.85,0c-0.17-0.3-0.33-0.61-0.5-0.91c-0.1-0.96-0.2-1.92-0.3-2.88
	c0.18-0.18,0.36-0.36,0.53-0.54c0.9-0.2,1.79-0.41,2.68-0.61c0.07-0.94,0.14-1.87,0.21-2.8c0.08-0.11,0.18-0.21,0.28-0.3
	c3.14-1.05,4.13-0.4,4.29,2.78c0.56,0.34,1.29,0.55,1.61,1.03c0.54,0.82,1.25,1.72,0.69,2.85c-2.26,0.48-2.68,0.14-3.05-2.58
	c-0.83,0.09-1.66,0.18-2.62,0.28c0.06,0.7,0.12,1.38,0.18,2.01c1,0.3,2.13,0.36,2.85,0.94c0.64,0.52,0.83,1.61,1.25,2.52
	c2.21,0.32,2.82,0.76,2.97,2.22c0.16,1.52-0.31,2.01-2.64,2.74c0,0.7,0,1.41,0,2.27c0.55,0.04,1.08,0.04,1.6,0.13
	c1.36,0.23,2.16,1.22,2.04,2.47c-0.11,1.16-0.94,1.9-2.28,1.98c-0.47,0.03-0.95-0.08-1.43-0.13c-0.15-0.14-0.31-0.28-0.46-0.41
	c-0.16-0.91-0.31-1.82-0.45-2.63c-0.99-0.12-1.98-0.23-2.97-0.35l0.09,0.07c-0.42-0.91-0.84-1.83-1.3-2.83
	C673.06,582.15,672.29,582.02,671.53,581.9z M677.29,580.38c-0.06-0.75-0.1-1.35-0.16-2.1c-0.77,0.05-1.43,0.1-2.24,0.16
	c0.06,0.8,0.11,1.46,0.16,2.19C675.8,580.55,676.42,580.48,677.29,580.38z"
              />
              <path
                d="M670.13,570.6c-0.18,0.18-0.36,0.36-0.53,0.54c-1.02,0.15-2.05,0.29-3.07,0.44c-0.16-0.31-0.31-0.61-0.47-0.92
	c-0.09-0.96-0.19-1.93-0.28-2.89c0.16-0.13,0.31-0.26,0.47-0.39c0.89-0.27,1.78-0.54,2.66-0.81c-0.1-0.76-0.2-1.44-0.29-2.1
	c-2.92-0.13-3.29-0.44-3.86-3.18c-0.93-0.08-1.86-0.16-2.79-0.24c-0.16-0.14-0.32-0.29-0.48-0.43c-0.04-1.12-0.09-2.24-0.13-3.35
	c0.19-0.16,0.39-0.33,0.58-0.49c0.98,0.05,1.95,0.11,2.93,0.16c0.09,0.09,0.18,0.17,0.28,0.24c0.33,0.84,0.65,1.69,1,2.59
	c0.65-0.07,1.47-0.17,1.98-0.22c0.21-1.26,0.39-2.3,0.57-3.34l-0.06,0.07c0.97-0.23,1.94-0.47,2.81-0.67
	c0.22-1.13,0.42-2.19,0.62-3.25c0,0-0.08,0.05-0.08,0.05c0.55,0.03,1.09,0.06,1.62,0.09c1.61,3.22,1.58,3.46-0.57,4.31
	c0,0.74,0,1.52,0,2.16c1.02,0.37,2.02,0.57,2.81,1.08c0.43,0.28,0.68,1.08,0.73,1.67c0.13,1.38-0.61,1.93-2.97,2.38
	c-0.1,0.93-0.21,1.87-0.32,2.8c-0.1,0.09-0.19,0.19-0.28,0.3c-0.87,0.27-1.74,0.55-2.54,0.8
	C670.36,568.82,670.24,569.71,670.13,570.6z M669.73,563.14c0.9-0.11,1.7-0.21,2.53-0.32c-0.12-0.83-0.23-1.5-0.34-2.27
	c-0.78,0.04-1.47,0.07-2.19,0.11C669.73,561.54,669.73,562.3,669.73,563.14z"
              />
              <path
                d="M379.94,520.68c-1,0.02-2,0.04-3,0.06c-0.18-0.19-0.36-0.38-0.54-0.57c-0.13-0.94-0.25-1.88-0.38-2.82
	c0.15-0.19,0.31-0.38,0.46-0.58c0.94-0.25,1.89-0.51,2.62-0.7c0.24-1,0.21-2.17,0.77-2.85c0.55-0.66,1.71-0.81,2.31-1.06
	c0.28-1.09,0.53-2.04,0.77-2.98l0.01,0.01c2.81-1.15,3.79-0.57,4.19,2.35c1.17,0.21,2.28,0.41,3.38,0.61
	c0.1,0.04,0.19,0.09,0.29,0.14c-0.01,1.06-0.01,2.12-0.02,3.18l0.08-0.08c-1.06,0.45-2.11,0.91-3.22,1.38
	c0.25,2.1-0.39,3.27-1.98,3.44c-1.52,0.15-2.35-0.82-2.69-3.19c-0.84,0.04-1.67,0.09-2.3,0.12c-0.14,1.12-0.27,2.1-0.4,3.09
	C380.17,520.4,380.06,520.54,379.94,520.68z M383.8,513.34c0.15,0.91,0.26,1.6,0.38,2.32c0.74-0.09,1.42-0.18,2.18-0.28
	c-0.11-0.75-0.22-1.44-0.33-2.2C385.31,513.23,384.63,513.28,383.8,513.34z"
              />
              <path
                d="M278.49,391.62c0.41,0.99,0.81,1.98,1.21,2.95c0.79,0.2,1.77,0.33,2.63,0.73c0.39,0.19,0.66,0.89,0.75,1.41
	c0.24,1.44-0.64,2.22-2.71,2.67c-0.33,1.1-0.53,2.17-0.98,3.12c-0.18,0.38-0.92,0.61-1.43,0.68c-1.53,0.21-2.37-0.74-2.77-2.73
	c-1.2-0.5-2.37-0.73-3.19-1.4c-1.02-0.83-0.39-2.05-0.12-3.12l-0.07,0.09c0.94-0.32,1.89-0.64,2.79-0.95
	c0.1-0.8,0.21-1.64,0.32-2.48c0.1-0.15,0.19-0.29,0.29-0.44c1.02-0.96,2.21-0.65,3.39-0.45L278.49,391.62z M276.15,396.41
	c0.06,0.68,0.12,1.35,0.18,2.1c0.9-0.1,1.64-0.19,2.37-0.28c-0.11-0.83-0.19-1.5-0.29-2.2
	C277.62,396.17,276.94,396.28,276.15,396.41z"
              />
              <path
                d="M404.77,182.45c-0.82,0.24-1.64,0.48-2.68,0.78c0.29,1.01,0.29,2.15-0.72,3c-0.91,0.77-1.92,0.57-2.93,0.34
	c-0.11-0.1-0.22-0.18-0.34-0.26c-0.67-1.09-0.85-2.21-0.29-3.41c0.07-0.1,0.13-0.19,0.19-0.29c0.87-0.3,1.75-0.6,2.61-0.89
	c-0.06-0.78-0.11-1.6-0.17-2.33c-0.9,0-1.68,0-2.45,0c-0.3-0.23-0.59-0.46-0.89-0.69c-0.05-0.23-0.12-0.46-0.16-0.7
	c-0.28-1.77,0.5-3.02,1.97-3.15c1.35-0.12,2.3,0.85,2.48,2.57c0.02,0.16-0.03,0.32-0.01,0.48c0.02,0.15,0.11,0.28,0.1,0.28
	c1,0,1.93,0,2.86,0c0.13,0.15,0.26,0.3,0.39,0.45c0.16,1.11,0.32,2.21,0.48,3.32C405.09,182.11,404.93,182.28,404.77,182.45z"
              />
              <path
                d="M659.5,535.76c0.16-0.2,0.32-0.4,0.47-0.6c0.99,0.03,1.98,0.06,2.97,0.08c0.15,0.12,0.29,0.25,0.44,0.37
	c0.28,0.88,0.56,1.76,0.84,2.67c2.42,0.11,3.33,0.68,3.47,2.22c0.14,1.58-0.65,2.3-2.91,2.64c-0.13,0.98-0.25,1.97-0.38,2.95
	c-0.14,0.18-0.29,0.36-0.43,0.54c-0.98,0.01-1.97,0.02-2.95,0.03c-0.18-0.19-0.36-0.38-0.54-0.57c-0.6-0.85-0.86-1.93-0.22-2.64
	c0.7-0.77,1.83-1.15,2.87-1.75c-0.05-0.54-0.12-1.31-0.18-1.95c-1-0.09-1.92-0.18-2.84-0.26c-0.18-0.17-0.37-0.33-0.55-0.5
	C659.13,537.91,658.97,536.84,659.5,535.76z"
              />
              <path
                d="M398.01,182.61c-0.06,0.1-0.12,0.2-0.19,0.29c-1.01,0.26-2.02,0.51-2.82,0.72c-0.07,1.12-0.13,2.1-0.19,3.07
	c-0.18,0.13-0.36,0.27-0.54,0.4c-0.98,0.04-1.96,0.08-2.94,0.12c0,0,0.05,0.07,0.05,0.07c-0.94-0.78-1.33-1.78-0.98-2.96
	c0.47-1.59,1.94-1.54,3.22-1.7c-0.18-0.91-0.33-1.66-0.47-2.41c-1-0.07-1.94-0.13-2.87-0.19c-0.11-0.09-0.22-0.18-0.32-0.29
	c-0.31-1.19-0.44-2.38,0.02-3.56c0,0-0.08,0.08-0.08,0.08c0.08-0.01,0.16-0.01,0.24-0.04c2.78-1.11,3.71-0.6,4.24,2.48
	c0.89,0,1.82,0,2.75,0c0.3,0.23,0.59,0.46,0.89,0.69C398.01,180.47,398.01,181.54,398.01,182.61z"
              />
              <path
                d="M389.95,179.75c0.1,0.11,0.21,0.2,0.32,0.29c0.71,0.87,0.88,1.85,0.39,2.85c-0.59,1.21-1.79,1.3-2.75,1.31
	c-0.16,1.14-0.3,2.12-0.44,3.09c-0.1,0.13-0.2,0.25-0.3,0.38c-1.02,0.01-2.03,0.03-3.05,0.04c0,0,0.05,0.07,0.05,0.07
	c-0.5-0.93-1-1.87-1.57-2.93c-0.68,0-1.62,0-2.56,0c-0.08-0.21-0.15-0.41-0.23-0.62c-0.69-1.1-0.86-2.24,0.02-3.28
	c0.8-0.95,1.86-1.12,2.99-0.52c0,0,0.2,0.1,0.2,0.1c0.47,0.93,0.93,1.86,1.41,2.81c0.59-0.05,1.21-0.1,1.6-0.13
	c0.23-1.12,0.44-2.15,0.65-3.17c0.07-0.04,0.15-0.09,0.21-0.14C387.89,179.36,388.91,179.24,389.95,179.75z"
              />
              <path
                d="M376.4,520.18c0.18,0.19,0.36,0.38,0.54,0.57c0.4,1.16,0.35,2.32-0.06,3.47c-0.04,0.08-0.09,0.16-0.14,0.23
	c-0.92,0.14-1.84,0.29-2.83,0.44c0.05,0.45,0.16,0.91,0.14,1.37c-0.08,1.49-1.05,2.37-2.45,2.28c-1.37-0.09-2.2-1.11-2.1-2.62
	c0.03-0.47,0.16-0.93,0.24-1.4c0,0-0.05,0.09-0.05,0.09c1.02-0.28,2.03-0.56,3.05-0.83c-0.11-0.68-0.22-1.36-0.34-2.1
	c-0.97,0-1.89,0-2.82,0c-0.19-0.3-0.38-0.61-0.56-0.91c-0.09-0.96-0.19-1.92-0.28-2.89c0.18-0.18,0.35-0.36,0.53-0.55
	c0.99-0.04,2.22-0.47,2.9-0.03c0.84,0.54,1.21,1.8,1.86,2.88C374.68,520.18,375.54,520.18,376.4,520.18z"
              />
              <path
                d="M358.58,525.45c-0.2-0.89-0.41-1.79-0.62-2.7c-0.69,0.05-1.39,0.09-2.22,0.15c0.01,0.26,0.03,0.53,0.04,0.81
	c0.09,2.34-0.74,3.09-3.11,2.83c-0.31-0.03-0.62-0.07-0.93-0.11l-0.09-0.04c-0.47-1.24-0.52-2.5-0.11-3.76l-0.09,0.1
	c1-0.3,2-0.61,2.79-0.84c0.14-0.94,0.27-1.76,0.4-2.58c0.05-0.08,0.09-0.17,0.13-0.25c0.97-1.14,2.24-0.76,3.45-0.67
	c0.14,0.15,0.27,0.3,0.41,0.44c0.2,0.88,0.41,1.76,0.6,2.58c0.92-0.05,1.77-0.1,2.61-0.15c0.09,0.22,0.18,0.45,0.27,0.67
	c0.91,1.69,0.94,2.48,0.07,3.36c-1.18,1.19-2.43,0.71-3.67,0.11L358.58,525.45z"
              />
              <path
                d="M642.65,460.81c-0.13,0.17-0.26,0.34-0.39,0.51c-1.05,0.86-2.21,0.6-3.38,0.37l0.09,0.08c-0.75-1.26-1.41-2.53-0.26-3.91
	c0.06-0.06,0.13-0.12,0.19-0.18c0.76-0.2,1.51-0.41,2.38-0.64c-0.04-0.62-0.09-1.3-0.15-2.05c-1.03,0-1.97,0-2.91,0
	c-0.16-0.31-0.32-0.61-0.48-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.52-0.52c2.98-0.8,3.64-0.39,4.26,2.42
	c1.02,0.39,1.99,0.64,2.81,1.14c0.37,0.23,0.55,0.96,0.6,1.49c0.15,1.56-0.49,2.12-2.8,2.57
	C642.79,459.1,642.72,459.95,642.65,460.81z"
              />
              <path
                d="M226.76,212.15c0.13-0.13,0.26-0.26,0.4-0.39c0.52-0.18,1.03-0.42,1.56-0.52c0.45-0.09,0.93-0.02,1.27-0.02
	c0.19-0.97,0.24-1.91,0.59-2.71c0.16-0.37,0.9-0.62,1.41-0.69c1.19-0.16,2.2,0.76,2.24,1.92c0.06,1.34-0.77,1.96-3.01,2.25
	c0.81,3.05-0.44,4.17-2.92,4.23c-0.2,1.07-0.4,2.12-0.6,3.18c-0.08,0.03-0.17,0.05-0.25,0.06c-0.37,0.13-0.73,0.34-1.11,0.39
	c-1.67,0.22-2.73-0.5-2.95-1.95c-0.23-1.46,0.52-2.45,2.15-2.83c0.3-0.07,0.61-0.07,0.96-0.11
	C226.58,214,226.67,213.08,226.76,212.15z"
              />
              <path
                d="M404.77,182.45c0.16-0.17,0.31-0.33,0.47-0.5c0.89-0.26,1.78-0.52,2.69-0.79c-0.1-0.73-0.21-1.55-0.28-2.09
	c-1.09-0.17-1.99-0.3-2.9-0.44c-0.13-0.15-0.26-0.3-0.39-0.45c-0.04-0.16-0.08-0.31-0.11-0.47c-0.3-1.94,0.33-3.08,1.8-3.26
	c1.29-0.16,2.18,0.72,2.53,2.54c0.03,0.15,0.06,0.31,0.06,0.3c1.1,0.3,2.2,0.45,3.13,0.92c0.42,0.21,0.66,1.08,0.69,1.66
	c0.09,1.53-0.86,2.2-3.26,2.38c0.06,0.46,0.2,0.92,0.17,1.36c-0.08,1.43-0.92,2.29-2.2,2.37c-1.38,0.08-2.3-0.78-2.46-2.33
	C404.68,183.25,404.75,182.85,404.77,182.45z"
              />
              <path
                d="M652.14,489.53c0,0,0.01,0,0.01,0c0.5-0.13,1.27-0.52,1.46-0.34c0.6,0.59,1.13,1.35,1.44,2.14
	c0.48,1.26,0.05,1.67-1.95,2.17c0,0.84,0,1.68,0,2.52c2.18-0.07,2.93,0.31,3.29,1.6c0.52,1.88-0.63,3.16-2.55,2.82
	c-0.39-0.07-0.75-0.26-1.13-0.39c-0.12-0.04-0.23-0.09-0.34-0.15c0-0.9,0-1.81,0-2.69c-1.16,0-2.08,0-2.99,0
	c-0.12-0.14-0.25-0.28-0.37-0.43c-0.19-1.11-0.39-2.22-0.58-3.34c0.19-0.17,0.38-0.34,0.57-0.51c0.9-0.24,1.8-0.49,2.4-0.65
	C651.67,491.27,651.91,490.4,652.14,489.53z"
              />
              <path
                d="M334.31,328.98c0.52-0.19,1.02-0.45,1.55-0.56c1.46-0.31,2.77,0.54,3.03,1.92c0.24,1.25-0.56,2.41-1.96,2.78
	c-0.37,0.1-0.78,0.06-1.1,0.08c-0.26,1.15-0.52,2.26-0.77,3.36l0.08-0.08c-0.76,0.12-1.55,0.44-2.27,0.33
	c-1.44-0.21-1.89-1.37-1.58-3.58c0.16-0.19,0.32-0.37,0.49-0.56c0.74-0.19,1.48-0.39,2.26-0.59c0.1-1.03,0.21-2.09,0.32-3.14
	C334.36,328.93,334.31,328.98,334.31,328.98z"
              />
              <path
                d="M369.02,520.76c0.19,0.3,0.38,0.61,0.56,0.91c0.66,0.96,0.6,1.94,0.11,2.94c0,0,0.05-0.09,0.05-0.09
	c-0.92,0.46-1.84,0.93-2.73,1.38c-0.23,0.95-0.49,2.04-0.76,3.12l0.09-0.08c-0.38,0.1-0.75,0.23-1.13,0.29
	c-1.41,0.24-2.42-0.32-2.82-1.53c-0.4-1.21,0.13-2.31,1.43-2.87c0.43-0.18,0.92-0.21,1.48-0.33c0.06-0.97,0.13-1.98,0.19-2.98
	c0.09-0.1,0.18-0.19,0.28-0.27C366.85,521.09,367.94,520.92,369.02,520.76z"
              />
              <path
                d="M365.76,521.25c-0.1,0.08-0.19,0.18-0.28,0.27c-1.03,0.96-2.2,0.64-3.37,0.39c-0.09-0.22-0.18-0.45-0.27-0.67
	c-0.2-0.79-0.4-1.58-0.57-2.27c-0.97-0.06-1.81-0.11-2.64-0.16c-0.14-0.15-0.27-0.3-0.41-0.44c-0.56-1.13-0.94-2.37,0.2-3.25
	c0.59-0.46,1.61-0.62,2.39-0.52c1.04,0.13,1.35,0.94,1.53,2.87c0.98,0.04,1.97,0.09,2.95,0.13c0.05,0.1,0.09,0.2,0.13,0.3
	C366.3,518.93,366.59,520.03,365.76,521.25z"
              />
              <path
                d="M655.08,522.15c-0.19-0.3-0.37-0.61-0.56-0.91c-0.07-1.04-0.15-2.07-0.22-3.11c0.06-0.09,0.12-0.17,0.18-0.25
	c1.06-0.12,2.12-0.23,3.18-0.35c0.17,0.12,0.33,0.23,0.5,0.35c0.36,0.85,0.72,1.7,1.17,2.77c0.12,0.01,0.49,0.01,0.86,0.05
	c1.83,0.2,2.45,0.95,2.29,2.81c-0.05,0.55-0.17,1.09-0.26,1.63c0,0,0.01-0.1,0.01-0.1c-0.54,0.04-1.07,0.11-1.61,0.13
	c-1.46,0.05-2.12-0.45-2.5-1.9c-0.09-0.35-0.08-0.73-0.12-1.11C656.92,522.15,656,522.15,655.08,522.15z"
              />
              <path
                d="M376.49,516.79c-0.15,0.19-0.31,0.38-0.46,0.58c-3.22,0.61-4.1-0.16-3.87-3.37l-0.09,0.08c0.83-0.94,1.86-1.41,3.04-1.22
	c0.15-1.12,0.29-2.18,0.44-3.24l-0.09,0.09c1.13-0.15,2.26-0.29,3.38-0.44c0,0-0.06-0.08-0.06-0.08c1.94,2.74,1.26,4.24-1.94,4.6
	C376.72,514.82,376.6,515.8,376.49,516.79z"
              />
              <path
                d="M411.07,376.83c-0.18-0.13-0.35-0.27-0.53-0.4c-0.19-0.91-0.39-1.82-0.55-2.6c-0.84-0.07-1.61-0.12-2.38-0.18
	c-0.15-0.07-0.31-0.14-0.46-0.22c-0.16-0.27-0.36-0.53-0.46-0.83c-0.54-1.58-0.01-2.83,1.36-3.28c1.32-0.43,2.5,0.36,2.93,1.97
	c0.08,0.3,0.07,0.62,0.11,1.06c0.48-0.02,0.94-0.09,1.4-0.06c1.64,0.13,2.54,1.11,2.41,2.61c-0.12,1.37-1.06,2.13-2.65,2.11
	C411.85,377.01,411.46,376.89,411.07,376.83z"
              />
              <path
                d="M383.66,400.56c0.14,0.07,0.28,0.14,0.43,0.21c0.42,0.9,0.84,1.8,1.3,2.79c2.3,0.16,3.22,0.79,3.19,2.32
	c-0.02,1.11-0.78,1.98-1.86,2.13c-1.54,0.21-2.32-0.67-2.75-3.05c-0.93,0-1.86,0-2.79,0c-0.14-0.11-0.27-0.22-0.41-0.33
	c-0.76-1.13-0.59-2.37-0.41-3.61c0.04-0.07,0.08-0.14,0.12-0.21C381.55,400.72,382.61,400.64,383.66,400.56z"
              />
              <path
                d="M537.2,336.51c0.17,0.16,0.34,0.31,0.51,0.47c0.14,0.97,0.27,1.93,0.41,2.9l0.08-0.09c-1.08,1.24-2.46,1.29-3.93,0.98
	l0.09,0.08c-0.35-1.04-0.7-2.07-1.03-3.07c-0.74-0.06-1.63-0.14-2.52-0.22l-0.17-0.05c-0.29-0.55-0.72-1.06-0.83-1.64
	c-0.29-1.48,0.98-2.76,2.51-2.6c0.53,0.05,1.04,0.25,1.57,0.38c0,0-0.09-0.07-0.09-0.07c0.25,0.96,0.5,1.92,0.74,2.83
	C535.48,336.43,536.34,336.47,537.2,336.51z"
              />
              <path
                d="M397.09,504.43c0.22,0.98,0.43,1.97,0.64,2.94c0.86-0.05,1.72-0.11,2.58-0.16c0,0-0.06-0.05-0.06-0.05
	c0.19,0.26,0.36,0.52,0.56,0.76c0.67,0.82,0.81,1.72,0.31,2.66c-0.51,0.96-1.39,1.24-2.43,1.13c-0.39-0.04-0.78-0.1-1.18-0.14
	l0.08,0.09c-0.34-1.06-0.68-2.13-0.95-2.95c-1.02-0.17-1.99-0.32-2.96-0.48l0.07,0.07c-0.7-1.01-1.26-2.15-0.33-3.2
	c0.5-0.55,1.42-0.82,2.2-0.99c0.47-0.1,1.03,0.26,1.55,0.41C397.18,504.51,397.09,504.43,397.09,504.43z"
              />
              <path
                d="M543.85,368.61c0.18,0.26,0.37,0.51,0.53,0.78c0.54,0.93,0.53,1.95-0.25,2.61c-0.59,0.5-1.55,0.87-2.29,0.8
	c-1.1-0.1-1.76-0.97-1.81-2.16c-0.01-0.31-0.03-0.61-0.05-1.1c-1.26,0.11-2.58,0.44-3.4-0.9c-0.64-1.04-0.23-1.97,0.2-2.92
	c0,0-0.09,0.08-0.09,0.08c0.98-0.14,1.96-0.28,2.93-0.42c0.17,0.13,0.34,0.26,0.51,0.39c0.28,0.86,0.55,1.72,0.78,2.42
	c0.96,0.15,2,0.31,3.03,0.47L543.85,368.61z"
              />
              <path
                d="M637.03,443.78c0.06-0.09,0.12-0.18,0.18-0.27c1.06-0.12,2.12-0.23,3.19-0.35c0.16,0.11,0.31,0.22,0.47,0.33
	c0.24,0.41,0.54,0.8,0.7,1.24c0.16,0.44,0.17,0.93,0.25,1.4c2.28,0.6,2.33,0.68,2.51,2.72c0.07,0.83,0.16,1.61-0.89,1.91
	c-0.95,0.27-2-0.1-2.39-1.03c-0.24-0.56-0.28-1.22-0.4-1.83c-1.01-0.12-2.02-0.24-3.02-0.35c-0.11-0.23-0.23-0.46-0.34-0.69
	C637.19,445.84,637.11,444.81,637.03,443.78z"
              />
              <path
                d="M228.31,222.79c0.11,0.61,0.29,1.22,0.31,1.84c0.05,1.46-1.11,2.58-2.54,2.51c-1.35-0.07-2.26-1.13-2.2-2.58
	c0.06-1.44,1-2.36,2.47-2.26c0.69,0.05,1.36,0.35,2.03,0.54C228.38,222.84,228.31,222.79,228.31,222.79z"
              />
              <path
                d="M637.27,446.86c0.11,0.23,0.23,0.46,0.34,0.69c0.86,0.94,0.81,2,0.36,3.1c-0.17,0.17-0.34,0.35-0.52,0.52
	c-1.1,0.71-2.21,0.48-3.32,0.02c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.01-0.16-2.03-0.24-3.04c0.1-0.11,0.2-0.21,0.31-0.31
	C635.07,447.19,636.17,447.03,637.27,446.86z"
              />
              <path
                d="M345.7,331.72c0.15-0.06,0.31-0.1,0.43-0.18c1.12-0.73,2.3-0.87,3.28,0.09c1.06,1.03,1,2.26,0.14,3.45
	c0,0,0.04-0.05,0.04-0.05c-1.02,1.05-2.22,0.8-3.43,0.48c-0.13-0.11-0.27-0.22-0.4-0.33c-0.48-1.18-0.56-2.36,0.01-3.53
	L345.7,331.72z"
              />
              <path
                d="M379.81,184.21c0.08,0.21,0.15,0.41,0.23,0.62c0.73,0.89,0.62,1.85,0.27,2.84c-0.16,0.18-0.32,0.36-0.49,0.54
	c-1.19,0.75-2.35,0.41-3.51-0.11l0.08,0.09c-0.65-1.35-0.5-2.67,0.16-3.97c0,0-0.08,0.09-0.08,0.09
	C377.58,184.27,378.7,184.24,379.81,184.21z"
              />
              <path
                d="M489.23,485.17c-0.18,0.18-0.35,0.37-0.53,0.55c-1.18,0.72-2.32,0.35-3.45-0.14l0.08,0.08c-0.69-1.42-0.55-2.8,0.19-4.15
	c0,0-0.05,0.08-0.05,0.08c0.95-0.06,1.91-0.12,2.86-0.18c0.28,0.29,0.57,0.58,0.85,0.88C489.21,483.26,489.22,484.22,489.23,485.17z
	"
              />
              <path
                d="M365.42,517.89c-0.04-0.1-0.08-0.2-0.13-0.3c-0.83-3.02-0.06-4.01,3.05-3.93c0.23,0.27,0.46,0.55,0.69,0.82
	c0.75,0.89,0.64,1.86,0.24,2.85c-0.18,0.18-0.35,0.36-0.53,0.55C367.64,518.57,366.53,518.33,365.42,517.89z"
              />
              <path
                d="M271.87,395.93c-0.42,0.22-0.82,0.47-1.26,0.64c-1,0.41-2,0.19-2.54-0.66c-0.41-0.65-0.59-1.63-0.48-2.4
	c0.15-1.04,1.08-1.52,2.1-1.59c0.54-0.04,1.09,0.11,1.64,0.17c0.17,0.18,0.34,0.35,0.51,0.53c0.44,1.14,0.54,2.27-0.05,3.4
	C271.8,396.02,271.87,395.93,271.87,395.93z"
              />
              <path
                d="M342.38,336.01c-0.45,0.14-0.9,0.34-1.36,0.42c-1.05,0.18-1.88-0.21-2.45-1.11c-0.52-0.82-0.51-1.67,0.03-2.48
	c0.58-0.88,1.43-1.26,2.47-1.07c0.47,0.08,0.92,0.27,1.37,0.4c0,0-0.09-0.05-0.09-0.05c0.18,1.13,0.36,2.25,0.54,3.38
	C342.71,335.67,342.54,335.84,342.38,336.01z"
              />
              <path
                d="M491.13,510.35l-0.04,0.04c0.54,1.14,1.16,2.27,0.52,3.59c-0.14,0.16-0.28,0.32-0.42,0.49c-1.23,0.77-2.42,0.41-3.61-0.1
	l0.08,0.08c-0.08-0.3-0.16-0.59-0.23-0.9c-0.45-2.07,0.43-3.21,2.51-3.26C490.34,510.28,490.74,510.33,491.13,510.35z"
              />
              <path
                d="M271.85,392.63c-0.17-0.18-0.34-0.35-0.51-0.53c-0.16-0.44-0.42-0.88-0.46-1.33c-0.12-1.48,0.79-2.51,2.17-2.58
	c1.46-0.06,2.53,1.03,2.45,2.57c-0.02,0.47-0.2,0.93-0.3,1.39c-0.1,0.15-0.19,0.29-0.29,0.44
	C273.89,392.6,272.87,392.61,271.85,392.63z"
              />
              <path
                d="M229.17,231.78c0.06,1.26-0.86,2.33-2.08,2.44c-1.27,0.11-2.44-1.01-2.45-2.34c0-1.22,1.04-2.31,2.22-2.33
	C228.08,229.53,229.11,230.54,229.17,231.78z"
              />
              <path
                d="M532.87,322.58c-0.15,0.15-0.29,0.3-0.44,0.45c-0.3,0.1-0.59,0.21-0.89,0.3c-1.15,0.35-2.13-0.02-2.61-1.04
	c-0.29-0.62-0.31-1.58-0.04-2.21c0.43-1.03,1.41-1.44,2.56-1.15c0.31,0.08,0.61,0.17,0.91,0.26c0.16,0.12,0.32,0.25,0.48,0.37
	C532.85,320.57,532.86,321.57,532.87,322.58z"
              />
              <path
                d="M397.09,384.97c-0.69-1.35-0.64-2.67,0.13-3.98l-0.08,0.07c0.08,0.01,0.16,0.03,0.24,0.02c3.04-0.78,4.21,0.36,3.48,3.4
	c0,0,0.08-0.07,0.08-0.07c-1.17,1.19-2.51,1.14-3.92,0.5L397.09,384.97z"
              />
              <path
                d="M244.42,250.09c0.19,0.18,0.38,0.36,0.57,0.54c0.16,0.44,0.41,0.87,0.45,1.32c0.16,1.55-0.83,2.65-2.29,2.63
	c-1.44-0.01-2.43-1.14-2.28-2.67c0.05-0.46,0.19-0.91,0.29-1.37c0,0-0.07,0.09-0.07,0.09C242.2,250.45,243.31,250.27,244.42,250.09z
	"
              />
              <path
                d="M534.29,300.97c0.16,0.12,0.32,0.25,0.47,0.37c0.02,0.97,0.04,1.95,0.06,2.92c-0.16,0.18-0.31,0.36-0.47,0.54
	c-1.18,0.68-2.34,0.34-3.49-0.09l0.08,0.08c-0.12-0.37-0.27-0.73-0.35-1.11c-0.41-1.96,0.77-3.22,2.78-2.96
	C533.69,300.75,533.99,300.88,534.29,300.97z"
              />
              <path
                d="M354.77,519.03c-0.04,0.09-0.08,0.17-0.13,0.25c-1.07,0-2.15,0-3.22,0c-0.13-0.14-0.26-0.29-0.39-0.43
	c-0.13-0.99-0.25-1.97-0.38-2.96c0.12-0.14,0.24-0.28,0.36-0.41c1.34-0.93,2.66-0.61,3.96,0.1l-0.08-0.08
	C354.85,516.68,354.81,517.86,354.77,519.03z"
              />
              <path
                d="M390.83,512.32c-0.09-0.05-0.19-0.1-0.29-0.14c-1.11-0.9-1.05-2.08-0.78-3.31c0,0-0.06,0.07-0.06,0.07
	c1.19-1.24,2.57-1.26,4.05-0.64l-0.07-0.07c0.43,1.12,0.79,2.24,0.3,3.43c-0.09,0.11-0.17,0.23-0.24,0.36
	C392.77,512.11,391.8,512.21,390.83,512.32z"
              />
              <path
                d="M331.79,332.66c-0.16,0.19-0.32,0.37-0.49,0.56c-1.08,0.78-2.22,0.65-3.38,0.22c-0.07-0.09-0.15-0.18-0.24-0.26
	c-0.11-0.29-0.26-0.58-0.33-0.88c-0.4-1.68,0.37-2.94,1.88-3.13c1.46-0.18,2.48,0.7,2.63,2.32
	C331.9,331.87,331.82,332.27,331.79,332.66z"
              />
              <path
                d="M369.02,514.48c-0.23-0.27-0.46-0.55-0.69-0.82c-0.1-0.47-0.27-0.94-0.28-1.4c-0.01-1.43,1.09-2.52,2.38-2.45
	c1.3,0.08,2.3,1.29,2.12,2.68c-0.07,0.54-0.33,1.06-0.5,1.59c0,0,0.09-0.08,0.09-0.08C371.12,514.15,370.07,514.32,369.02,514.48z"
              />
              <path
                d="M342.44,332.18c-1.1-0.86-1.13-2.06-1.09-3.3l-0.07,0.08c0.3-0.25,0.59-0.52,0.91-0.75c0.93-0.69,1.95-0.73,2.86-0.1
	c0.91,0.63,1.28,1.57,0.89,2.7c-0.1,0.3-0.16,0.61-0.24,0.91l0.08-0.08c-1.08,0.61-2.19,1-3.43,0.49L342.44,332.18z"
              />
              <path
                d="M350.36,381.66c1.25-0.64,2.49-0.33,3.73,0.06l-0.08-0.08c0.37,1.31,0.48,2.61-0.23,3.86l0.09-0.08
	c-0.3,0.07-0.59,0.14-0.89,0.21c-2.08,0.49-3.27-0.54-3.08-2.69c0.03-0.31,0.11-0.61,0.17-0.91
	C350.16,381.9,350.25,381.77,350.36,381.66z"
              />
              <path
                d="M351.03,518.85c0.13,0.14,0.26,0.29,0.39,0.43c0.43,1.14,0.45,2.28,0.03,3.42l0.09-0.1c-0.35,0.16-0.7,0.37-1.07,0.48
	c-1.98,0.62-3.19-0.25-3.33-2.37c-0.02-0.31,0.02-0.63,0.03-0.95c0.17-0.18,0.34-0.37,0.51-0.55
	C348.8,519.1,349.91,518.98,351.03,518.85z"
              />
              <path
                d="M379.94,520.68c0.11-0.14,0.23-0.28,0.34-0.43c0.28-0.14,0.55-0.33,0.85-0.4c2.26-0.54,3.45,0.58,3.13,2.92
	c-0.04,0.31-0.11,0.62-0.16,0.93c-0.03,0.08-0.07,0.16-0.11,0.24c-0.23,0.03-0.47,0.06-0.7,0.09
	C380.4,524.47,379.65,523.72,379.94,520.68z"
              />
              <path
                d="M535.68,275.51c0.18,0.15,0.36,0.3,0.53,0.44c-0.01,1-0.01,1.99-0.02,2.99c0,0,0.07-0.11,0.07-0.11
	c-1.2,1.2-2.55,1.06-3.96,0.43l0.08,0.08c0-0.08,0.02-0.17-0.01-0.23c-0.46-1.04-0.8-2.08,0.06-3.09
	C533.37,274.95,534.5,275.06,535.68,275.51z"
              />
              <path
                d="M320.29,37.58c-0.13,0.14-0.26,0.28-0.39,0.42c-1.16,0.63-2.31,0.4-3.47,0c0,0,0.05,0.04,0.05,0.04
	c-0.14-0.44-0.34-0.88-0.42-1.33c-0.33-1.91,0.95-3.11,2.9-2.76c0.3,0.05,0.59,0.18,0.89,0.28c0,0-0.08-0.09-0.08-0.09
	C320.55,35.2,320.71,36.34,320.29,37.58z"
              />
              <path
                d="M393.74,512.01c0.07-0.12,0.15-0.24,0.24-0.36c1.2-0.54,2.41-0.4,3.61,0l-0.08-0.09c0,0.08-0.02,0.17,0,0.24
	c1.05,3.02,0.27,4.1-2.9,4c-0.16-0.12-0.32-0.24-0.49-0.37C393.35,514.37,393.35,513.21,393.74,512.01z"
              />
              <path
                d="M278.58,391.7c-0.68-1.36-0.62-2.69,0.12-4l-0.08,0.07c0.45-0.11,0.89-0.25,1.34-0.32c1.04-0.15,1.85,0.28,2.39,1.15
	c0.5,0.81,0.46,1.66-0.08,2.44c-0.59,0.84-1.42,1.22-2.45,0.99c-0.45-0.1-0.89-0.28-1.33-0.42
	C278.49,391.62,278.58,391.7,278.58,391.7z"
              />
              <path
                d="M412.12,391.08c0,0-0.15-0.1-0.15-0.1c0-1.23,0.01-2.45,0.01-3.68l-0.07,0.08c1.12-0.17,2.24-0.35,3.37-0.52
	c0.18,0.17,0.35,0.35,0.53,0.52c0.91,2.7,0.13,4.03-2.3,3.88C413.05,391.23,412.59,391.14,412.12,391.08z"
              />
              <path
                d="M415.81,387.38c-0.18-0.17-0.35-0.35-0.53-0.52c-0.14-0.96-0.28-1.92-0.43-2.88c0.3-0.24,0.61-0.49,0.91-0.73
	c0.98-0.03,1.96-0.06,2.94-0.09c0.1,0.11,0.2,0.21,0.31,0.31c0.12,0.75,0.43,1.53,0.32,2.25
	C419.13,387.14,418.13,387.55,415.81,387.38z"
              />
              <path
                d="M394.13,515.44c0.16,0.12,0.32,0.24,0.49,0.37c0.04,1,0.08,2,0.11,2.99l0.08-0.08c-1.21,1.19-2.58,1.12-4.03,0.54l0.1,0.08
	c-0.66-1.31-0.59-2.62,0-3.93l-0.08,0.08C391.91,515.27,393.01,514.78,394.13,515.44z"
              />
              <path
                d="M347.69,519.23c-0.17,0.18-0.34,0.37-0.51,0.55c-1.17,0.71-2.32,0.29-3.47-0.05c0,0,0.09,0.08,0.09,0.08
	c-0.59-1.33-0.52-2.65,0.1-3.95c0,0-0.09,0.09-0.09,0.09c0.08,0.01,0.16,0.04,0.23,0.02C347.1,515.17,348.15,516.1,347.69,519.23z"
              />
              <path
                d="M357.2,337.92c0.65,1.31,0.66,2.62-0.05,3.91c0,0,0.08-0.08,0.08-0.08c-0.38,0.11-0.75,0.25-1.13,0.31
	c-1.76,0.32-2.93-0.66-2.91-2.48c0-0.39,0.14-0.78,0.22-1.17c0.13-0.15,0.26-0.3,0.38-0.45c1.17-0.59,2.33-0.35,3.49,0.04
	L357.2,337.92z"
              />
              <path
                d="M340.41,519.81c1.13-0.24,2.26-0.68,3.39,0c0,0-0.09-0.08-0.09-0.08c0.25,0.48,0.6,0.94,0.73,1.45
	c0.4,1.6-0.82,2.98-2.47,2.83c-0.53-0.05-1.05-0.27-1.58-0.41l0.1,0.06c-0.64-1.31-0.57-2.62,0.01-3.93L340.41,519.81z"
              />
              <path
                d="M390.05,504.96c0.27,1.36,0.52,2.71-0.34,3.98c0,0,0.06-0.07,0.06-0.07c-0.38,0.08-0.75,0.17-1.12,0.22
	c-1.77,0.23-2.87-0.75-2.81-2.55c0.01-0.39,0.13-0.77,0.2-1.16c0,0-0.08,0.08-0.08,0.08c1.29-1.1,2.71-0.99,4.17-0.43L390.05,504.96
	z"
              />
              <path
                d="M335.57,383.64c0.4-0.25,0.77-0.57,1.2-0.75c1.76-0.71,3.43,0.57,3.17,2.46c-0.08,0.61-0.39,1.2-0.59,1.79
	c0-0.01,0.07-0.09,0.07-0.09c-0.07-0.03-0.15-0.09-0.22-0.08c-3.14,0.57-4.07-0.33-3.55-3.42
	C335.65,383.56,335.57,383.64,335.57,383.64z"
              />
              <path
                d="M383.02,180.51c0,0-0.2-0.1-0.2-0.1c-0.01-1.27-0.03-2.53-0.04-3.8l-0.09,0.09c0.22-0.05,0.45-0.08,0.66-0.16
	c2.52-0.88,3.68-0.03,3.6,2.63c-0.01,0.23-0.03,0.46-0.05,0.7c-0.07,0.05-0.14,0.1-0.22,0.14
	C385.54,180.8,384.27,180.62,383.02,180.51z"
              />
              <path
                d="M517.03,226.56c-0.65-1.36-0.48-2.69,0.17-4c0,0-0.05,0.07-0.05,0.06c0.29-0.02,0.59,0,0.87-0.05
	c2.06-0.36,3.12,0.55,3.03,2.62c-0.01,0.31-0.08,0.62-0.12,0.92l0.08-0.08c-1.24,1.15-2.61,1.16-4.05,0.45L517.03,226.56z"
              />
              <path
                d="M539.67,68.92c0,0-0.05,0.09-0.05,0.09c-0.99,1.15-2.28,0.75-3.5,0.66c-0.12-0.07-0.23-0.14-0.34-0.22
	c0-1.22,0.01-2.43,0.01-3.65c0,0-0.09,0.07-0.09,0.07c0.23-0.04,0.46-0.05,0.68-0.11C539.12,65,540.23,66.07,539.67,68.92z"
              />
              <path
                d="M397.51,9.38c-0.1,0.11-0.21,0.22-0.33,0.31c-1,0.11-2.01,0.23-3.01,0.34c-0.28-0.26-0.57-0.51-0.85-0.77
	c-0.09-0.31-0.23-0.61-0.26-0.92c-0.13-1.48,0.7-2.59,2.01-2.74c1.22-0.14,2.35,0.93,2.45,2.35C397.56,8.43,397.52,8.9,397.51,9.38z
	"
              />
              <path
                d="M663.37,535.61c-0.15-0.12-0.29-0.25-0.44-0.37c-0.34-1.17-0.35-2.34,0.01-3.51c0,0-0.09,0.08-0.09,0.08
	c1.14-0.14,2.28-0.28,3.42-0.42l-0.07-0.07c0.15,0.28,0.31,0.55,0.44,0.84c0.97,2.12,0.18,3.48-2.08,3.56
	C664.17,535.74,663.77,535.65,663.37,535.61z"
              />
              <path
                d="M339.53,54.23c0.07-0.12,0.14-0.23,0.22-0.34c1.04-0.18,2.09-0.67,3.13,0c0,0-0.08-0.04-0.08-0.04
	c1.14,1.52,1.28,2.35,0.52,3.31c-0.97,1.22-2.15,1.02-3.38,0.47l0.08,0.08C339.17,56.65,339.22,55.46,339.53,54.23z"
              />
              <path
                d="M487.27,463.72c-0.1,0.12-0.21,0.24-0.33,0.34c-0.38,0.12-0.75,0.26-1.13,0.36c-1.06,0.25-2-0.11-2.42-1.05
	c-0.28-0.64-0.3-1.57-0.06-2.23c0.37-0.97,1.3-1.35,2.37-1.15c0.39,0.07,0.77,0.21,1.15,0.32c0,0-0.08-0.08-0.08-0.08
	C487.54,461.31,487.61,462.49,487.27,463.72z"
              />
              <path
                d="M372.58,184.83c-0.08-0.29-0.15-0.59-0.23-0.88c-0.59-2.31,0.67-3.59,3.03-3.06c0.23,0.05,0.45,0.12,0.68,0.19l-0.08-0.07
	c0.64,1.03,0.87,2.12,0.49,3.3c0,0,0.08-0.09,0.08-0.09c-1.21,1.28-2.6,1.17-4.06,0.54C372.49,184.74,372.58,184.83,372.58,184.83z"
              />
              <path
                d="M401.75,395.46c0.19,0.14,0.37,0.27,0.56,0.41c0.09,0.46,0.25,0.92,0.26,1.39c0.03,1.75-1.21,2.71-2.96,2.34
	c-0.38-0.08-0.75-0.2-1.12-0.31l0.08,0.09c-0.14-0.44-0.34-0.87-0.42-1.32C397.72,395.9,399.09,394.92,401.75,395.46z"
              />
              <path
                d="M343.32,386.05c-1.06-1.08-0.58-2.35-0.44-3.58l-0.08,0.09c0.52-0.17,1.02-0.39,1.55-0.5c1.01-0.21,2.01-0.01,2.44,0.98
	c0.28,0.65,0.33,1.56,0.11,2.23c-0.33,1.01-1.29,1.35-2.33,1.19c-0.46-0.07-0.88-0.31-1.33-0.48
	C343.23,385.98,343.32,386.05,343.32,386.05z"
              />
              <path
                d="M623.36,621.52c0.07,0.14,0.13,0.28,0.18,0.43c0.28,0.38,0.65,0.72,0.81,1.15c0.39,0.98,0.2,1.99-0.69,2.53
	c-0.6,0.36-1.49,0.51-2.18,0.37c-1.04-0.21-1.49-1.11-1.47-2.16c0.01-0.47,0.15-0.93,0.23-1.4c0,0-0.08,0.08-0.08,0.08
	C621.23,622.19,622.29,621.85,623.36,621.52z"
              />
              <path
                d="M505.24,422.44c0.09,0.06,0.17,0.12,0.25,0.18c0.16,1.08,0.32,2.17,0.48,3.25c-0.28,0.26-0.56,0.52-0.84,0.78
	c-0.54,0.04-1.09,0.16-1.63,0.11c-1.22-0.11-2.03-1-2.06-2.15c-0.03-1.22,0.86-2.2,2.17-2.29
	C504.15,422.29,504.7,422.4,505.24,422.44z"
              />
              <path
                d="M387.18,187.66c0.1-0.13,0.2-0.25,0.3-0.38c1.3-0.89,2.6-0.63,3.9,0c0,0-0.05-0.07-0.05-0.07
	c-0.03,1.18-0.06,2.36-0.09,3.54l0.08-0.08c-0.15,0.02-0.31,0.01-0.46,0.06C387.9,191.74,386.81,190.83,387.18,187.66z"
              />
              <path
                d="M291.44,333.24c0.41-0.24,0.79-0.53,1.22-0.73c1.11-0.52,2.02-0.14,2.73,0.76c0.6,0.77,0.62,1.63,0.11,2.45
	c-0.55,0.89-1.38,1.35-2.43,1.08c-1.13-0.29-1.75-1.07-1.77-2.25c-0.01-0.47,0.14-0.94,0.22-1.41L291.44,333.24z"
              />
              <path
                d="M380.78,404.63c0.14,0.11,0.27,0.22,0.41,0.33c0.09,0.47,0.24,0.94,0.25,1.41c0.02,1.78-1.22,2.73-3,2.32
	c-0.38-0.09-0.75-0.22-1.13-0.33l0.08,0.09c-0.11-0.37-0.24-0.74-0.32-1.11c-0.42-1.99,0.74-3.21,2.79-2.93
	C380.17,404.44,380.47,404.55,380.78,404.63z"
              />
              <path
                d="M407.15,373.43c0.15,0.07,0.31,0.14,0.46,0.22c0,1.07,0,2.15,0,3.22c-0.09,0.13-0.2,0.25-0.31,0.36
	c-1.19,0.58-2.37,0.33-3.56,0l0.09,0.08c0-0.08,0.04-0.17,0.01-0.23c-0.49-1.09-0.86-2.18,0.09-3.22
	C404.88,372.83,406.01,373.07,407.15,373.43z"
              />
              <path
                d="M324.55,380.67c0.34-0.18,0.68-0.37,1.02-0.56c0.94-0.52,1.84-0.39,2.6,0.3c0.77,0.69,0.95,1.59,0.57,2.57
	c-0.14,0.36-0.26,0.72-0.39,1.09c0,0,0.09-0.09,0.09-0.09c-0.3,0.06-0.61,0.12-0.91,0.18c-2.08,0.46-3.17-0.49-3.04-2.64
	c0.02-0.32,0.09-0.63,0.14-0.94L324.55,380.67z"
              />
              <path
                d="M335.65,383.56c-0.45,0.1-0.89,0.23-1.34,0.29c-1.51,0.19-2.7-0.82-2.69-2.25c0.01-1.23,1.05-2.28,2.26-2.27
	c1.39,0.01,2.44,1.29,2.19,2.76c-0.09,0.53-0.32,1.04-0.49,1.56C335.57,383.64,335.65,383.56,335.65,383.56z"
              />
              <path
                d="M445.55,113.39c0.17,0.15,0.33,0.31,0.5,0.46c0.14,0.97,0.28,1.94,0.41,2.91c0,0,0.09-0.11,0.09-0.11
	c-1.18,1.08-2.46,1.57-3.97,0.65c0,0,0.09,0.08,0.09,0.08c-1.06-1.08-0.63-2.36-0.46-3.6c0,0-0.08,0.1-0.08,0.1
	C443.27,113.71,444.41,113.55,445.55,113.39z"
              />
              <path
                d="M291.52,333.16c-0.45,0.1-0.89,0.25-1.35,0.31c-1.5,0.2-2.71-0.81-2.72-2.24c0-1.23,1.04-2.29,2.25-2.3
	c1.39,0,2.46,1.29,2.22,2.75c-0.09,0.53-0.32,1.04-0.49,1.56C291.44,333.24,291.52,333.16,291.52,333.16z"
              />
              <path
                d="M390.78,519.26c0.21,0.43,0.5,0.83,0.61,1.28c0.49,1.92-0.85,3.25-2.84,2.84c-0.38-0.08-0.76-0.19-1.14-0.29
	c0,0,0.1,0.09,0.1,0.09c-0.14-0.44-0.32-0.87-0.41-1.31c-0.36-1.8,0.82-3.06,2.65-2.84c0.38,0.05,0.75,0.2,1.12,0.31L390.78,519.26z
	"
              />
              <path
                d="M379.82,188.21c0.16-0.18,0.32-0.36,0.49-0.54c1.31-0.74,2.59-0.53,3.87,0.1c0,0-0.05-0.07-0.05-0.07
	c-0.03,1.14-0.05,2.28-0.08,3.42c0,0,0.09-0.09,0.09-0.09c-0.28,0.12-0.56,0.25-0.85,0.35c-2.11,0.73-3.32-0.05-3.48-2.24
	C379.79,188.83,379.82,188.52,379.82,188.21z"
              />
              <path
                d="M336.66,520.3c-0.15-0.44-0.32-0.88-0.43-1.33c-0.22-0.91-0.04-1.76,0.72-2.34c0.73-0.56,1.54-0.77,2.43-0.3
	c0.96,0.5,1.35,1.28,1.26,2.33c-0.04,0.39-0.15,0.77-0.23,1.16c0,0,0.08-0.08,0.08-0.08c-1.19,1.13-2.54,0.97-3.93,0.5L336.66,520.3
	z"
              />
              <path
                d="M353.79,337.97c-0.13,0.15-0.26,0.3-0.38,0.45c-1.34,0.88-2.63,0.63-3.91-0.14l0.07,0.08c0.01-1.11,0.02-2.22,0.03-3.33
	c0,0-0.04,0.05-0.04,0.05c0.36-0.14,0.71-0.31,1.08-0.41c2.02-0.52,3.14,0.31,3.24,2.37C353.89,337.35,353.82,337.66,353.79,337.97z
	"
              />
              <path
                d="M404.2,503.78c0.11,0.3,0.22,0.6,0.34,0.89c0.4,0.99,0.26,1.89-0.52,2.63c-0.8,0.75-1.73,0.7-2.68,0.33
	c-0.37-0.14-0.72-0.31-1.09-0.47c0,0,0.06,0.05,0.06,0.05c0.02-0.22,0.09-0.44,0.07-0.66c-0.27-2.41,0.41-3.15,2.83-3.08
	c0.24,0.01,0.47,0.03,0.71,0.05C404.02,503.6,404.12,503.69,404.2,503.78z"
              />
              <path
                d="M536.21,358.35c0,0-0.17,0.07-0.17,0.07c-0.51,0.22-1,0.54-1.53,0.65c-1.46,0.3-2.63-0.51-2.82-1.86
	c-0.19-1.37,0.69-2.42,2.19-2.54c0.47-0.03,0.95,0.09,1.42,0.15c0.13,0.12,0.26,0.24,0.4,0.37
	C535.88,356.24,536.05,357.29,536.21,358.35z"
              />
              <path
                d="M376.63,65.37l0.02,0.02c1.22-0.36,2.44-1.06,3.65,0.01l-0.06-0.05c1.09,1,0.62,2.23,0.49,3.41c0,0,0.06-0.04,0.06-0.04
	c-0.15,0.03-0.31,0.05-0.45,0.1c-2.93,1-4.04,0.12-3.77-3C376.59,65.68,376.62,65.53,376.63,65.37z"
              />
              <path
                d="M394.26,187.1c0.18-0.13,0.36-0.27,0.54-0.4c0.99-1.08,2.16-0.62,3.3-0.39c0.12,0.08,0.23,0.16,0.34,0.26
	c0.08,0.54,0.22,1.08,0.22,1.62c0,1.25-0.79,2.14-1.92,2.29c-1.06,0.14-2.17-0.61-2.44-1.75
	C394.19,188.21,394.27,187.64,394.26,187.1z"
              />
              <path
                d="M376.74,524.45c0.06-0.07,0.1-0.15,0.14-0.23c0.43-0.2,0.84-0.5,1.29-0.58c1.48-0.28,2.6,0.49,2.79,1.85
	c0.19,1.36-0.69,2.41-2.18,2.53c-0.47,0.04-0.95-0.06-1.42-0.1c-0.14-0.14-0.28-0.27-0.42-0.41
	C376.45,526.52,376.26,525.51,376.74,524.45z"
              />
              <path
                d="M384.16,68.26c0.13,0.08,0.25,0.18,0.37,0.28c0.07,0.38,0.15,0.77,0.2,1.15c0.15,1.01-0.1,1.86-1.02,2.42
	c-0.83,0.5-1.64,0.46-2.42-0.09c-0.95-0.68-1.19-1.61-0.84-2.69c0.07-0.22,0.23-0.4,0.36-0.61c0,0-0.06,0.04-0.06,0.04
	C381.75,67.7,382.93,67.85,384.16,68.26z"
              />
              <path
                d="M382.97,509.21C382.97,509.21,382.96,509.2,382.97,509.21c-1.4,0.76-2.79,0.72-4.18-0.02c0,0,0.06,0.08,0.06,0.08
	c-0.06-0.46-0.16-0.92-0.18-1.38c-0.08-1.53,0.78-2.52,2.18-2.54c1.3-0.02,2.21,0.83,2.29,2.21
	C383.16,508.1,383.02,508.65,382.97,509.21z"
              />
              <path
                d="M526.99,387.71c-0.03-0.61-0.13-1.22-0.06-1.81c0.14-1.3,1.2-2.09,2.5-1.95c1.25,0.13,2.13,1.23,1.99,2.48
	c-0.14,1.25-1.19,2.13-2.5,1.94c-0.68-0.1-1.32-0.46-1.98-0.71C526.93,387.66,526.99,387.71,526.99,387.71z"
              />
              <path
                d="M348.07,527.14c-0.53,0.07-1.05,0.18-1.58,0.2c-1.4,0.04-2.44-1.01-2.39-2.36c0.05-1.24,1.13-2.25,2.34-2.18
	c1.31,0.07,2.31,1.28,2.1,2.63c-0.09,0.61-0.36,1.2-0.55,1.8C348.01,527.23,348.07,527.14,348.07,527.14z"
              />
              <path
                d="M535.78,361.99c0.18,0.15,0.36,0.29,0.55,0.44c0.93,1.04,0.4,2.23,0.35,3.38c0,0,0.09-0.08,0.09-0.08
	c-0.51,0.18-1.01,0.42-1.54,0.52c-1.45,0.29-2.61-0.49-2.81-1.83c-0.19-1.3,0.71-2.37,2.18-2.53
	C534.99,361.84,535.39,361.95,535.78,361.99z"
              />
              <path
                d="M489.17,532.83c1.2-0.87,2.4-1.77,3.88-0.52c0,0-0.04-0.07-0.04-0.07c1.09,1,0.61,2.23,0.5,3.41c0,0,0.05-0.06,0.05-0.06
	c-0.3,0.11-0.59,0.24-0.9,0.33c-2.25,0.66-3.32-0.12-3.41-2.46c-0.01-0.24,0-0.48,0-0.71C489.25,532.74,489.17,532.83,489.17,532.83
	z"
              />
              <path
                d="M290.44,242.36c-0.03-0.61-0.12-1.22-0.06-1.83c0.12-1.32,1.13-2.1,2.45-1.97c1.25,0.12,2.14,1.22,2.01,2.46
	c-0.13,1.24-1.2,2.13-2.49,1.94c-0.68-0.1-1.32-0.44-1.98-0.67L290.44,242.36z"
              />
              <path
                d="M419.02,383.46c-0.11-0.09-0.22-0.2-0.31-0.31c-0.11-1.01-0.21-2.03-0.32-3.04c0.09-0.14,0.18-0.28,0.27-0.42
	c0.52-0.18,1.02-0.46,1.55-0.52c1.34-0.14,2.35,0.7,2.47,1.96c0.13,1.28-0.68,2.29-2.02,2.42
	C420.13,383.61,419.57,383.5,419.02,383.46z"
              />
              <path
                d="M358.51,525.38c0.83,1.09,1.54,2.2,0.85,3.64c-0.06,0.08-0.11,0.16-0.15,0.25c-0.13,0.08-0.27,0.16-0.4,0.24
	c-1.05,0.64-2.2,0.76-2.98-0.21c-0.46-0.57-0.66-1.6-0.51-2.34c0.25-1.29,1.37-1.56,2.58-1.45c0.23,0.02,0.46-0.03,0.69-0.05
	C358.58,525.45,358.51,525.38,358.51,525.38z"
              />
              <path
                d="M353.96,56.41c0.1,0.11,0.21,0.2,0.33,0.29c0.13,0.28,0.29,0.56,0.37,0.85c0.54,2.17-0.87,3.56-2.98,2.94
	c-0.44-0.13-0.84-0.36-1.26-0.54c0,0,0.07,0.07,0.07,0.07c0.02-0.07,0.08-0.15,0.07-0.22C350.16,56.68,350.85,56,353.96,56.41z"
              />
              <path
                d="M523.97,222.67c0.09-0.1,0.18-0.21,0.25-0.33c0.93-0.54,1.88-0.87,2.92-0.33c0.14,0.09,0.29,0.18,0.43,0.27
	c1.12,1,0.56,2.24,0.47,3.41c0,0,0.08-0.08,0.08-0.08c-0.15,0.03-0.32,0.03-0.46,0.08C524.72,226.8,523.56,225.85,523.97,222.67z"
              />
              <path
                d="M544.86,379.68c-0.53,0.12-1.06,0.31-1.6,0.35c-1.39,0.09-2.47-0.99-2.41-2.35c0.05-1.25,1.16-2.26,2.37-2.17
	c1.32,0.1,2.29,1.33,2.06,2.7c-0.09,0.54-0.33,1.05-0.51,1.57L544.86,379.68z"
              />
              <path
                d="M330.02,521.3c-0.33-0.54-0.77-1.04-0.96-1.63c-0.4-1.22,0.4-2.48,1.63-2.76c1.28-0.29,2.45,0.45,2.71,1.71
	c0.26,1.29-0.47,2.41-1.79,2.63c-0.54,0.09-1.11-0.02-1.67-0.04L330.02,521.3z"
              />
              <path
                d="M255.51,249.2c-0.11,0.13-0.22,0.25-0.33,0.38c-0.15,0.03-0.33,0.02-0.46,0.08c-1.03,0.5-2,0.46-2.82-0.38
	c-0.9-0.93-0.85-1.97-0.17-3.01c0,0-0.07,0.04-0.07,0.04c1.02-1.05,2.21-0.87,3.43-0.48l-0.09-0.08
	C255.69,246.82,255.8,247.98,255.51,249.2z"
              />
              <path
                d="M467.82,153.43c0.01,1.29-0.98,2.25-2.29,2.23c-1.19-0.02-2.13-0.9-2.2-2.08c-0.08-1.27,0.89-2.32,2.17-2.36
	C466.78,151.18,467.81,152.16,467.82,153.43z"
              />
              <path
                d="M325.2,55.73c0.12-0.13,0.24-0.26,0.36-0.39c0.97-0.17,1.95-0.35,2.92-0.52c0.17,0.19,0.35,0.39,0.52,0.58
	c0.19,0.51,0.5,1.01,0.53,1.53c0.07,1.27-0.75,2.14-1.95,2.25c-1.2,0.11-2.17-0.58-2.4-1.8C325.09,56.85,325.2,56.28,325.2,55.73z"
              />
              <path
                d="M342.88,382.47c-0.59,0.21-1.17,0.56-1.77,0.61c-1.3,0.1-2.3-0.92-2.3-2.19c0-1.17,0.89-2.13,2.07-2.21
	c1.29-0.1,2.4,0.8,2.4,2.08c0,0.6-0.32,1.2-0.49,1.81C342.79,382.56,342.88,382.47,342.88,382.47z"
              />
              <path
                d="M528.19,329.92c1.22-0.01,2.28,1.07,2.25,2.3c-0.02,1.23-1.12,2.27-2.34,2.22c-1.15-0.05-2.07-1-2.11-2.18
	C525.95,330.99,526.94,329.93,528.19,329.92z"
              />
              <path
                d="M359.21,529.26c0.04-0.09,0.09-0.17,0.15-0.25c0.53-0.13,1.06-0.33,1.6-0.37c1.3-0.1,2.36,0.79,2.47,2.01
	c0.11,1.18-0.9,2.34-2.1,2.43c-1.22,0.08-2.23-0.84-2.3-2.17C359.02,530.36,359.15,529.81,359.21,529.26z"
              />
              <path
                d="M227.45,219.46c0.09-0.01,0.17-0.04,0.25-0.06c0.19-0.14,0.36-0.32,0.57-0.43c1.02-0.53,2.01-0.49,2.83,0.37
	c0.75,0.78,0.82,1.76,0.29,2.64c-0.58,0.98-1.5,1.38-2.65,0.98c-0.15-0.05-0.29-0.12-0.44-0.18c0,0,0.07,0.05,0.08,0.05
	C227.07,221.99,227.15,220.76,227.45,219.46z"
              />
              <path
                d="M396.17,500.73c0.51-0.19,1.01-0.44,1.55-0.57c1.45-0.34,2.62,0.39,2.85,1.73c0.24,1.37-0.59,2.42-2.07,2.58
	c-0.46,0.05-0.94-0.03-1.41-0.04c0,0,0.09,0.09,0.1,0.09c-1.02-1.12-1.15-2.46-0.92-3.88C396.26,500.64,396.17,500.73,396.17,500.73
	z"
              />
              <path
                d="M340.39,523.6c0.27,0.38,0.6,0.74,0.79,1.16c0.4,0.89,0.17,1.71-0.52,2.36c-0.67,0.64-1.47,0.85-2.38,0.45
	c-0.9-0.4-1.31-1.1-1.28-2.04c0.01-0.55,0.11-1.09,0.17-1.63c0.04-0.08,0.09-0.17,0.14-0.25c1.06,0.01,2.12,0.01,3.18,0.02
	C340.49,523.66,340.39,523.6,340.39,523.6z"
              />
              <path
                d="M255.1,123.41c0.28-0.3,0.57-0.61,0.85-0.91c0.54-0.09,1.09-0.29,1.62-0.24c1.13,0.1,1.85,0.94,1.9,2.04
	c0.05,1.12-0.6,1.99-1.67,2.25c-1.01,0.25-2.11-0.26-2.49-1.29C255.11,124.69,255.17,124.03,255.1,123.41z"
              />
              <path
                d="M365.41,59.14c0.13,0.06,0.25,0.13,0.36,0.21c0.08,0.63,0.23,1.25,0.21,1.88c-0.05,1.22-0.98,2.09-2.14,2.09
	c-1,0-1.99-0.84-2.19-1.85c-0.21-1.11,0.5-2.29,1.66-2.49C363.99,58.87,364.71,59.07,365.41,59.14z"
              />
              <path
                d="M388.17,71.69c0,0,0.12,0.04,0.12,0.04c0.08,0.63,0.24,1.25,0.24,1.88c-0.02,1.25-0.9,2.12-2.06,2.16
	c-1.2,0.04-2.3-1.05-2.28-2.25c0.02-1.12,0.95-2.11,2.12-2.13C386.93,71.37,387.55,71.58,388.17,71.69z"
              />
              <path
                d="M404.26,380.67c-0.13,0.14-0.26,0.27-0.39,0.41c-0.38,0.11-0.75,0.23-1.13,0.31c-1.05,0.22-1.98-0.11-2.37-1.06
	c-0.26-0.64-0.28-1.55-0.02-2.19c0.38-0.95,1.3-1.31,2.35-1.11c0.38,0.08,0.76,0.19,1.14,0.29c0,0-0.09-0.08-0.09-0.08
	C404.41,378.3,404.59,379.45,404.26,380.67z"
              />
              <path
                d="M476.49,176.12c-0.45,0.12-0.9,0.3-1.36,0.35c-1.4,0.18-2.57-0.74-2.65-2.03c-0.08-1.28,1-2.33,2.41-2.3
	c0.46,0.01,0.92,0.16,1.38,0.24c0,0-0.09-0.08-0.09-0.09c0.71,1.11,0.76,2.31,0.51,3.55C476.63,175.95,476.56,176.03,476.49,176.12z
	"
              />
              <path
                d="M374.49,407.03c0.01,1.22-1.08,2.3-2.29,2.28c-1.14-0.02-2.1-0.96-2.16-2.13c-0.07-1.27,0.89-2.35,2.14-2.39
	C373.4,404.74,374.48,405.79,374.49,407.03z"
              />
              <path
                d="M342.38,336.01c0.17-0.17,0.34-0.33,0.51-0.5c0.96-0.11,1.92-0.22,2.88-0.33c0.13,0.11,0.27,0.22,0.4,0.33
	c0.14,0.37,0.34,0.73,0.41,1.12c0.26,1.51-0.5,2.62-1.86,2.79c-1.4,0.17-2.38-0.68-2.5-2.22
	C342.18,336.8,342.32,336.4,342.38,336.01z"
              />
              <path
                d="M351.01,515.48c-0.12,0.14-0.24,0.28-0.36,0.41c-0.54,0.13-1.08,0.36-1.62,0.38c-1.29,0.03-2.31-1.03-2.29-2.27
	c0.02-1.13,1-2.14,2.13-2.2c1.16-0.06,2.13,0.76,2.26,2.01C351.19,514.36,351.05,514.93,351.01,515.48z"
              />
              <path
                d="M353.79,385.51c0.49-0.23,0.96-0.53,1.48-0.68c1.35-0.41,2.62,0.38,2.86,1.7c0.21,1.18-0.69,2.39-1.91,2.56
	c-1.24,0.17-2.29-0.68-2.41-2.02c-0.05-0.54,0.05-1.09,0.08-1.64C353.87,385.42,353.79,385.51,353.79,385.51z"
              />
              <path
                d="M376.31,188.09c0.25,0.58,0.6,1.14,0.71,1.74c0.23,1.29-0.65,2.4-1.9,2.56c-1.08,0.13-2.22-0.71-2.44-1.82
	c-0.24-1.2,0.59-2.44,1.86-2.59c0.6-0.07,1.24,0.13,1.85,0.2C376.4,188.18,376.31,188.09,376.31,188.09z"
              />
              <path
                d="M343.32,386.05c0,0-0.08-0.08-0.08-0.08c0.13,0.45,0.29,0.9,0.4,1.36c0.33,1.48-0.34,2.65-1.63,2.91
	c-1.44,0.29-2.47-0.58-2.64-2.26c-0.03-0.31,0.04-0.63,0.07-0.94c0,0.01-0.07,0.09-0.07,0.09
	C340.34,385.57,341.85,385.88,343.32,386.05z"
              />
              <path
                d="M468.07,162.37c0.49-0.25,0.96-0.58,1.48-0.73c1.23-0.36,2.46,0.37,2.77,1.58c0.33,1.26-0.62,2.62-1.94,2.78
	c-1.23,0.15-2.32-0.73-2.41-2.07c-0.04-0.54,0.12-1.1,0.19-1.65L468.07,162.37z"
              />
              <path
                d="M372.07,387.37c0.57-0.25,1.13-0.6,1.73-0.73c1.2-0.27,2.4,0.65,2.58,1.87c0.17,1.19-0.77,2.39-1.99,2.52
	c-1.14,0.13-2.2-0.66-2.34-1.86c-0.07-0.62,0.07-1.25,0.12-1.88L372.07,387.37z"
              />
              <path
                d="M411.6,504.97c-0.02,1.2-0.93,2.15-2.1,2.19c-1.21,0.04-2.32-1.06-2.29-2.27c0.02-1.23,1.14-2.25,2.38-2.17
	C410.77,502.8,411.62,503.76,411.6,504.97z"
              />
              <path
                d="M390.13,505.02c-0.3-0.35-0.71-0.66-0.9-1.07c-0.59-1.31,0.01-2.61,1.32-3.02c1.12-0.35,2.3,0.22,2.68,1.3
	c0.45,1.31-0.22,2.64-1.59,2.91c-0.5,0.1-1.07-0.12-1.6-0.19C390.05,504.96,390.13,505.02,390.13,505.02z"
              />
              <path
                d="M476.49,176.12c0.07-0.08,0.14-0.17,0.2-0.27c0.51-0.2,1.01-0.52,1.54-0.59c1.37-0.19,2.37,0.64,2.5,1.94
	c0.12,1.2-0.64,2.22-1.8,2.42c-1.29,0.21-2.41-0.54-2.6-1.85C476.25,177.23,476.43,176.67,476.49,176.12z"
              />
              <path
                d="M328.36,384.08c0.57-0.27,1.11-0.63,1.71-0.78c1.2-0.31,2.43,0.57,2.64,1.79c0.21,1.2-0.69,2.41-1.92,2.59
	c-1.14,0.17-2.24-0.6-2.41-1.79c-0.09-0.61,0.04-1.26,0.07-1.89C328.45,383.99,328.36,384.08,328.36,384.08z"
              />
              <path
                d="M404.2,503.78c-0.09-0.1-0.18-0.19-0.29-0.27c-0.19-0.52-0.49-1.02-0.54-1.55c-0.11-1.29,0.84-2.31,2.1-2.37
	c1.2-0.06,2.15,0.73,2.3,1.92c0.17,1.32-0.59,2.33-1.94,2.46C405.3,504.03,404.75,503.86,404.2,503.78z"
              />
              <path
                d="M369.29,228.53c-0.22-0.58-0.57-1.15-0.64-1.75c-0.15-1.29,0.83-2.34,2.08-2.39c1.11-0.05,2.16,0.85,2.31,1.97
	c0.15,1.2-0.76,2.41-2,2.48c-0.6,0.03-1.22-0.25-1.83-0.38C369.2,228.45,369.29,228.53,369.29,228.53z"
              />
              <path
                d="M366.25,529.02c0.49-0.26,0.96-0.58,1.48-0.75c1.32-0.43,2.65,0.38,2.89,1.7c0.23,1.21-0.65,2.41-1.89,2.62
	c-1.23,0.2-2.34-0.66-2.48-1.99c-0.06-0.54,0.06-1.1,0.09-1.65C366.33,528.94,366.25,529.02,366.25,529.02z"
              />
              <path
                d="M534.76,344.17c-0.13,0.12-0.27,0.24-0.4,0.35c-0.38,0.12-0.75,0.3-1.13,0.36c-1.44,0.19-2.61-0.69-2.7-2
	c-0.09-1.3,1-2.37,2.43-2.32c0.47,0.02,0.93,0.19,1.39,0.28l-0.09-0.08C534.85,341.84,535.09,342.96,534.76,344.17z"
              />
              <path
                d="M379.04,390.07c0.37,0.5,0.87,0.96,1.07,1.52c0.42,1.18-0.38,2.42-1.62,2.72c-1.01,0.25-2.14-0.33-2.51-1.3
	c-0.46-1.2,0.08-2.53,1.3-2.87c0.57-0.16,1.23-0.01,1.84-0.01C379.12,390.13,379.04,390.07,379.04,390.07z"
              />
              <path
                d="M376.95,527.51c0.14,0.14,0.28,0.27,0.42,0.41c0.16,0.53,0.42,1.05,0.46,1.59c0.09,1.22-0.76,2.17-1.93,2.31
	c-1.31,0.15-2.37-0.7-2.47-2c-0.1-1.21,0.63-2.17,1.86-2.35C375.82,527.39,376.39,527.49,376.95,527.51z"
              />
              <path
                d="M485.53,481.53c-0.61,0.45-1.15,1.09-1.83,1.29c-1.14,0.34-2.23-0.43-2.53-1.55c-0.33-1.18,0.43-2.44,1.63-2.73
	c1.16-0.28,2.26,0.28,2.6,1.45c0.15,0.51,0.06,1.09,0.09,1.62C485.48,481.6,485.53,481.53,485.53,481.53z"
              />
              <path
                d="M350.36,381.66c-0.11,0.11-0.2,0.23-0.29,0.36c-0.52,0.19-1.03,0.48-1.56,0.55c-1.29,0.15-2.22-0.59-2.42-1.78
	c-0.19-1.19,0.52-2.29,1.66-2.54c1.25-0.28,2.43,0.44,2.69,1.75C350.53,380.53,350.39,381.11,350.36,381.66z"
              />
              <path
                d="M376.06,181.07c-0.23-0.59-0.59-1.16-0.67-1.76c-0.17-1.22,0.66-2.22,1.83-2.39c1.19-0.17,2.42,0.8,2.53,2
	c0.1,1.11-0.75,2.25-1.91,2.36c-0.61,0.06-1.24-0.18-1.86-0.29C375.98,181,376.06,181.07,376.06,181.07z"
              />
              <path
                d="M430.03,9.78c-0.18,0.29-0.36,0.58-0.54,0.87c-0.92,0-1.83,0-2.75,0c-0.15-0.18-0.29-0.35-0.44-0.53
	c-0.17-0.43-0.47-0.86-0.49-1.3c-0.08-1.27,0.73-2.21,1.87-2.33c1.11-0.12,2.12,0.65,2.36,1.88C430.12,8.82,430.04,9.31,430.03,9.78
	z"
              />
              <path
                d="M377.53,403.3c-0.05,1.19-0.98,2.12-2.15,2.14c-1.22,0.02-2.29-1.09-2.24-2.32c0.05-1.22,1.21-2.23,2.43-2.12
	C376.75,401.11,377.58,402.08,377.53,403.3z"
              />
              <path
                d="M364.92,221.86c0.5-0.23,0.97-0.55,1.5-0.68c1.26-0.33,2.45,0.43,2.72,1.66c0.26,1.19-0.63,2.42-1.87,2.61
	c-1.25,0.19-2.33-0.67-2.43-2.02c-0.04-0.54,0.11-1.1,0.17-1.65L364.92,221.86z"
              />
              <path
                d="M530.64,337.5l0.17,0.05c0.08,0.63,0.24,1.27,0.23,1.9c-0.02,1.26-0.94,2.14-2.12,2.14c-1.12,0-2.16-1.01-2.21-2.12
	c-0.05-1.12,0.86-2.18,2.05-2.23C529.38,337.21,530.01,337.41,530.64,337.5z"
              />
              <path
                d="M361.52,383.08c-0.02,1.2-1.12,2.26-2.31,2.21c-1.23-0.05-2.17-1.11-2.1-2.38c0.07-1.17,1-2.08,2.15-2.09
	C360.47,380.8,361.54,381.87,361.52,383.08z"
              />
              <path
                d="M364.87,339.31c0.05,1.21-1.05,2.33-2.26,2.31c-1.17-0.02-2.09-0.96-2.13-2.15c-0.04-1.21,0.8-2.18,1.98-2.29
	C363.67,337.07,364.83,338.08,364.87,339.31z"
              />
              <path
                d="M321.71,382.75c0.04,1.25-0.96,2.26-2.23,2.27c-1.21,0.01-2.12-0.83-2.21-2.02c-0.1-1.29,0.81-2.32,2.08-2.39
	C320.62,380.54,321.67,381.5,321.71,382.75z"
              />
              <path
                d="M326.19,519.92c-0.06,1.18-1.01,2.12-2.17,2.12c-1.21,0-2.29-1.14-2.22-2.35c0.06-1.12,1.09-2.1,2.22-2.11
	C325.28,517.57,326.25,518.62,326.19,519.92z"
              />
              <path
                d="M568.38,608.55c0.13,0.15,0.26,0.29,0.38,0.44c0.06,0.38,0.17,0.77,0.18,1.15c0.04,1.54-0.79,2.54-2.09,2.56
	c-1.27,0.02-2.21-1-2.22-2.48c0-0.29,0.18-0.59,0.28-0.88l0.08-0.17C565.95,608.03,567.17,608.3,568.38,608.55z"
              />
              <path
                d="M372.49,184.74c0.24,0.58,0.59,1.14,0.7,1.74c0.22,1.29-0.67,2.4-1.91,2.54c-1.08,0.12-2.22-0.74-2.43-1.84
	c-0.23-1.2,0.62-2.43,1.88-2.58c0.6-0.07,1.24,0.14,1.85,0.22C372.58,184.83,372.49,184.74,372.49,184.74z"
              />
              <path
                d="M553.45,602.76c0.22,0.58,0.56,1.15,0.64,1.75c0.18,1.28-0.75,2.36-1.99,2.45c-1.08,0.09-2.18-0.8-2.36-1.9
	c-0.19-1.2,0.68-2.4,1.94-2.51c0.6-0.05,1.23,0.18,1.85,0.28C553.53,602.83,553.45,602.76,553.45,602.76z"
              />
              <path
                d="M320.27,518.68c-1.18,0.05-2.3-1-2.32-2.2c-0.02-1.21,1.04-2.28,2.25-2.27c1.15,0.01,2.09,0.92,2.16,2.08
	C322.44,517.56,321.51,518.63,320.27,518.68z"
              />
              <path
                d="M417.42,177.14c1.26,0.04,2.24,1.09,2.17,2.33c-0.07,1.26-1.12,2.16-2.41,2.07c-1.19-0.08-2.05-1-2.04-2.2
	C415.14,178.08,416.15,177.11,417.42,177.14z"
              />
              <path
                d="M502.64,560.09c0.13,0.15,0.25,0.29,0.38,0.44c0.07,0.55,0.23,1.1,0.2,1.64c-0.07,1.24-0.98,2.09-2.14,2.09
	c-1.09,0-2.14-0.97-2.22-2.07c-0.09-1.19,0.88-2.3,2.15-2.35C501.56,559.81,502.1,560,502.64,560.09z"
              />
              <path
                d="M327.68,333.18c0.09,0.08,0.17,0.17,0.24,0.26c0.16,0.53,0.4,1.05,0.46,1.59c0.15,1.31-0.76,2.36-2.02,2.45
	c-1.22,0.09-2.19-0.7-2.33-1.89c-0.16-1.3,0.65-2.33,2-2.46C326.56,333.07,327.12,333.16,327.68,333.18z"
              />
              <path
                d="M238.5,171.52c-0.31-0.35-0.74-0.64-0.92-1.05c-0.59-1.35,0-2.63,1.34-3.06c1.21-0.39,2.48,0.32,2.77,1.55
	c0.32,1.35-0.47,2.57-1.9,2.77c-0.44,0.06-0.92-0.17-1.37-0.27C238.42,171.47,238.5,171.52,238.5,171.52z"
              />
              <path
                d="M404.84,370.4c-1.25-0.02-2.21-1.1-2.12-2.38c0.08-1.19,1.04-2.08,2.21-2.05c1.12,0.03,2.13,1.02,2.17,2.14
	C407.14,369.32,406.05,370.42,404.84,370.4z"
              />
              <path
                d="M441.74,110.1c0.13,0.12,0.27,0.25,0.4,0.37c0.6,1.14,0.63,2.27-0.01,3.41c0,0,0.08-0.1,0.08-0.1
	c-0.43,0.19-0.84,0.46-1.29,0.54c-1.48,0.27-2.56-0.5-2.74-1.85c-0.18-1.39,0.65-2.37,2.15-2.49
	C440.8,109.94,441.28,110.05,441.74,110.1z"
              />
              <path
                d="M235.37,182.46c-0.1-0.15-0.21-0.3-0.31-0.46c-0.04-0.99-0.09-1.97-0.13-2.96l-0.09,0.1c0.42-0.21,0.82-0.45,1.25-0.64
	c1.01-0.44,1.97-0.2,2.51,0.68c0.36,0.59,0.53,1.52,0.35,2.18c-0.25,0.99-1.15,1.48-2.21,1.43
	C236.29,182.77,235.83,182.58,235.37,182.46z"
              />
              <path
                d="M383.99,523.94c0.04-0.08,0.08-0.16,0.11-0.24c0.99-1.14,2.2-0.88,3.42-0.53c0,0-0.1-0.09-0.1-0.09
	c0.19,0.42,0.45,0.83,0.56,1.27c0.38,1.52-0.42,2.79-1.83,2.95c-1.25,0.15-2.36-0.84-2.45-2.26
	C383.68,524.69,383.89,524.31,383.99,523.94z"
              />
              <path
                d="M293.25,245.5c1.24-0.04,2.24,0.96,2.24,2.24c0,1.18-0.86,2.13-2.01,2.23c-1.17,0.1-2.33-0.92-2.4-2.12
	C291.01,246.65,292.04,245.54,293.25,245.5z"
              />
              <path
                d="M368.27,218.02c0.5-0.23,0.98-0.55,1.5-0.69c1.27-0.34,2.47,0.42,2.73,1.66c0.26,1.2-0.62,2.43-1.86,2.63
	c-1.25,0.2-2.34-0.67-2.45-2.02c-0.04-0.55,0.1-1.11,0.16-1.66L368.27,218.02z"
              />
              <path
                d="M343.31,512.61c0.66,1.04,0.75,2.16,0.5,3.34c0,0,0.09-0.09,0.09-0.09c-0.41,0.23-0.82,0.48-1.24,0.68
	c-1.01,0.48-1.99,0.23-2.53-0.63c-0.37-0.59-0.52-1.52-0.35-2.2c0.26-1.01,1.18-1.47,2.24-1.39c0.47,0.04,0.92,0.24,1.38,0.37
	L343.31,512.61z"
              />
              <path
                d="M544.77,379.76c0.57-0.27,1.11-0.64,1.71-0.8c1.19-0.31,2.43,0.59,2.64,1.81c0.2,1.14-0.53,2.23-1.69,2.53
	c-1.23,0.32-2.46-0.44-2.65-1.74c-0.09-0.61,0.04-1.26,0.08-1.89C544.86,379.68,544.77,379.76,544.77,379.76z"
              />
              <path
                d="M498.23,217.4c0.71,1.08,0.82,2.25,0.56,3.48l0.09-0.09c-0.41,0.22-0.82,0.45-1.24,0.65c-1,0.47-1.96,0.22-2.51-0.63
	c-0.37-0.58-0.53-1.49-0.37-2.16c0.23-1.01,1.14-1.47,2.19-1.42c0.46,0.02,0.92,0.17,1.38,0.26
	C498.32,217.48,498.23,217.4,498.23,217.4z"
              />
              <path
                d="M436.53,111.3c-1.24-0.01-2.2-1.05-2.16-2.33c0.04-1.18,0.93-2.1,2.09-2.14c1.21-0.05,2.29,0.99,2.31,2.2
	C438.8,110.24,437.74,111.32,436.53,111.3z"
              />
              <path
                d="M533.59,250.14c-0.06,0.08-0.12,0.17-0.17,0.26c-0.08,0.03-0.15,0.06-0.23,0.08c-1.71,0.53-2.66,0.45-3.15-0.58
	c-0.3-0.63-0.36-1.55-0.13-2.21c0.39-1.1,1.25-1.27,3.07-0.95c0.24,0.04,0.47,0.09,0.71,0.14c0,0,0.12,0.07,0.12,0.07
	C533.74,248.02,533.67,249.08,533.59,250.14z"
              />
              <path
                d="M282.53,221.93c-0.29-0.47-0.65-0.91-0.86-1.41c-0.49-1.19,0.09-2.52,1.24-2.95c1.28-0.48,2.67,0.28,2.95,1.61
	c0.27,1.29-0.44,2.4-1.76,2.63c-0.54,0.09-1.1,0.02-1.65,0.03C282.45,221.85,282.53,221.93,282.53,221.93z"
              />
              <path
                d="M361.07,511.2c0.57-0.26,1.11-0.63,1.71-0.76c1.19-0.27,2.4,0.66,2.57,1.87c0.17,1.19-0.77,2.38-1.98,2.52
	c-1.14,0.13-2.21-0.65-2.35-1.84c-0.07-0.61,0.09-1.25,0.14-1.87L361.07,511.2z"
              />
              <path
                d="M533.82,253.93c0.14,0.16,0.27,0.32,0.41,0.48c0.65,0.97,0.66,1.94,0.04,2.92c0,0,0.04-0.08,0.04-0.08
	c-0.4,0.25-0.77,0.61-1.21,0.73c-1.29,0.36-2.46-0.37-2.71-1.62c-0.26-1.3,0.46-2.44,1.78-2.63
	C532.71,253.66,533.28,253.86,533.82,253.93z"
              />
              <path
                d="M579.83,615.09l0.04-0.08c0.89-0.68,1.84-1,2.93-0.47c0.12,0.08,0.24,0.15,0.37,0.22c0.22,0.41,0.56,0.79,0.64,1.23
	c0.28,1.48-0.52,2.57-1.89,2.69c-1.37,0.13-2.42-0.79-2.43-2.24C579.48,615.99,579.71,615.54,579.83,615.09z"
              />
              <path
                d="M487.89,453.44c-1.24,0-2.23-1.05-2.19-2.33c0.03-1.18,0.93-2.12,2.09-2.18c1.21-0.06,2.31,0.97,2.34,2.2
	C490.16,452.35,489.09,453.45,487.89,453.44z"
              />
              <path
                d="M405.2,512.82c0.02,1.24-1,2.23-2.27,2.22c-1.3-0.01-2.22-0.98-2.17-2.29c0.04-1.19,0.93-2.07,2.12-2.11
	C404.14,510.6,405.19,511.58,405.2,512.82z"
              />
              <path
                d="M484.45,185.92c0.13,0.12,0.26,0.24,0.39,0.36c0.17,1.05,0.34,2.11,0.51,3.16c0,0,0.04-0.02,0.04-0.02
	c-0.58,0.23-1.15,0.56-1.76,0.69c-1.37,0.29-2.46-0.43-2.69-1.69c-0.25-1.38,0.6-2.47,2.08-2.6
	C483.5,185.77,483.98,185.88,484.45,185.92z"
              />
              <path
                d="M534.07,297.17c-0.06,0.08-0.12,0.17-0.16,0.25c-0.07,0.03-0.15,0.05-0.22,0.09c-1.08,0.55-2.13,0.64-3.02-0.32
	c-0.64-0.69-0.83-1.52-0.37-2.4c0.67-1.27,1.76-1.43,3.03-1.11c0.15,0.04,0.31,0,0.47,0c0.14,0.18,0.29,0.37,0.43,0.55
	C534.17,295.21,534.12,296.19,534.07,297.17z"
              />
              <path
                d="M337.31,523.64c-0.05,0.08-0.1,0.16-0.14,0.25c-0.49,0.24-0.95,0.61-1.47,0.7c-1.33,0.25-2.39-0.54-2.58-1.8
	c-0.19-1.27,0.58-2.35,1.89-2.53c0.54-0.07,1.1,0.02,1.65,0.04c0,0-0.09-0.07-0.09-0.07C337.2,521.28,337.97,522.3,337.31,523.64z"
              />
              <path
                d="M485.26,485.59c0.23,0.59,0.57,1.16,0.67,1.77c0.21,1.31-0.71,2.41-1.97,2.51c-1.1,0.08-2.21-0.85-2.35-1.96
	c-0.14-1.13,0.68-2.24,1.86-2.38c0.61-0.07,1.25,0.1,1.87,0.15C485.34,485.67,485.26,485.59,485.26,485.59z"
              />
              <path
                d="M434.05,103.81c0.09,0.12,0.18,0.24,0.28,0.35c0.19,0.43,0.51,0.85,0.56,1.3c0.13,1.29-0.84,2.39-2.07,2.46
	c-1.15,0.07-2.16-0.83-2.26-2.01c-0.11-1.31,0.72-2.3,2.1-2.38C433.11,103.5,433.58,103.71,434.05,103.81z"
              />
              <path
                d="M534.79,264.46c-0.95,1.02-2.04,1.21-3.17,0.38c-0.86-0.64-1.01-1.58-0.67-2.57c0.42-1.24,1.41-1.55,3.37-1.09
	c0,0-0.05-0.07-0.05-0.07c1.02,1.01,1.33,2.13,0.5,3.41L534.79,264.46z"
              />
              <path
                d="M520.93,226.11c0.52-0.16,1.03-0.42,1.57-0.46c1.33-0.11,2.3,0.78,2.37,2.06c0.06,1.29-0.86,2.28-2.14,2.31
	c-1.36,0.03-2.39-1.03-2.25-2.43c0.05-0.53,0.34-1.04,0.53-1.55C521.01,226.03,520.93,226.11,520.93,226.11z"
              />
              <path
                d="M354.96,515.58c-0.28-0.47-0.62-0.91-0.83-1.41c-0.48-1.18,0.1-2.5,1.25-2.93c1.18-0.44,2.49,0.19,2.86,1.39
	c0.41,1.34-0.32,2.6-1.72,2.84c-0.54,0.09-1.1,0.02-1.65,0.03C354.88,515.5,354.96,515.58,354.96,515.58z"
              />
              <path
                d="M378.86,213.14c-0.13,0.15-0.26,0.31-0.38,0.46c-0.54,0.11-1.08,0.31-1.62,0.32c-1.17,0.02-2.17-0.93-2.24-2.04
	c-0.08-1.11,0.85-2.17,1.99-2.27c1.2-0.11,2.14,0.62,2.31,1.87C378.98,212.02,378.88,212.59,378.86,213.14z"
              />
              <path
                d="M347.08,57.1c0.22,0.59,0.55,1.16,0.65,1.77c0.21,1.31-0.71,2.4-1.97,2.49c-1.1,0.08-2.2-0.85-2.34-1.97
	c-0.13-1.12,0.7-2.23,1.88-2.36c0.61-0.07,1.25,0.1,1.87,0.15C347.16,57.18,347.08,57.1,347.08,57.1z"
              />
              <path
                d="M430.54,100.52c0,0-0.05-0.06-0.05-0.06c0.08,0.71,0.26,1.42,0.23,2.12c-0.04,1.11-0.96,1.92-2.03,1.95
	c-1.09,0.03-2.18-0.96-2.25-2.05c-0.07-1.03,0.7-2.11,1.76-2.2C428.96,100.21,429.75,100.43,430.54,100.52z"
              />
              <path
                d="M348.66,385.41c1.26-0.04,2.33,0.98,2.31,2.22c-0.01,1.16-0.95,2.11-2.13,2.16c-1.3,0.05-2.3-0.92-2.28-2.22
	C346.56,386.37,347.46,385.45,348.66,385.41z"
              />
              <path
                d="M408.55,509.1c-0.05,1.24-1.1,2.16-2.38,2.09c-1.27-0.07-2.15-1.11-2.03-2.4c0.11-1.19,1.03-2.01,2.22-1.99
	C407.61,506.83,408.6,507.87,408.55,509.1z"
              />
              <path
                d="M334.48,517.43c-1.18-0.05-2.12-1-2.13-2.16c-0.01-1.24,1.06-2.27,2.32-2.22c1.29,0.05,2.18,1.09,2.08,2.42
	C336.65,516.68,335.73,517.48,334.48,517.43z"
              />
              <path
                d="M498.79,220.87c0.52-0.17,1.02-0.44,1.55-0.5c1.34-0.15,2.33,0.7,2.43,1.98c0.11,1.38-0.92,2.44-2.3,2.37
	c-1.26-0.07-2.19-1.07-2.07-2.37c0.05-0.53,0.31-1.05,0.47-1.57C498.88,220.79,498.79,220.87,498.79,220.87z"
              />
              <path
                d="M325.63,514c0.61-0.16,1.22-0.44,1.83-0.45c1.21-0.03,2.09,0.94,2.11,2.14c0.02,1.3-0.99,2.27-2.29,2.21
	c-1.18-0.05-2.16-0.99-2.1-2.17c0.03-0.61,0.35-1.21,0.54-1.82L325.63,514z"
              />
              <path
                d="M370.2,242.91c0.16,0.15,0.32,0.31,0.48,0.46c0.13,0.45,0.37,0.9,0.38,1.36c0.03,1.3-0.98,2.31-2.2,2.29
	c-1.07-0.02-1.95-0.82-2.07-1.9c-0.15-1.31,0.63-2.27,2.02-2.39C369.26,242.7,369.73,242.85,370.2,242.91z"
              />
              <path
                d="M236.02,149.89c0.1,0.06,0.19,0.12,0.28,0.19c0,1.05-0.01,2.09-0.01,3.14c0,0,0.07-0.11,0.07-0.11
	c-0.19,0.14-0.38,0.28-0.58,0.41c-0.86,0.57-1.74,0.77-2.59-0.01c-0.84-0.77-0.73-1.73-0.35-2.62c0.45-1.05,1.27-1.58,2.48-1.16
	C235.55,149.81,235.79,149.84,236.02,149.89z"
              />
              <path
                d="M399.77,518.88c-1.26-0.04-2.23-1.08-2.16-2.31c0.07-1.23,1.14-2.16,2.4-2.08c1.17,0.08,2.02,1,2.03,2.19
	C402.04,517.95,401.04,518.91,399.77,518.88z"
              />
              <path
                d="M351.65,526.37c0,0,0.09,0.04,0.09,0.04c0.18,0.35,0.43,0.69,0.53,1.06c0.37,1.42-0.36,2.64-1.66,2.88
	c-1.36,0.25-2.41-0.55-2.61-2.05c-0.05-0.38,0.05-0.78,0.08-1.17c0,0.01-0.07,0.09-0.07,0.09
	C348.98,525.94,350.2,525.67,351.65,526.37z"
              />
              <path
                d="M380.5,400.81c-0.04,0.07-0.09,0.14-0.12,0.21c-1.11,0.61-2.23,0.89-3.24-0.17c-0.65-0.68-0.85-1.51-0.39-2.39
	c0.66-1.29,1.77-1.42,3.03-1.12c0.15,0.03,0.31,0,0.47-0.01c0.14,0.19,0.29,0.37,0.43,0.56
	C380.61,398.85,380.55,399.83,380.5,400.81z"
              />
              <path
                d="M531.48,347.99c0.53-0.16,1.05-0.42,1.59-0.46c1.35-0.1,2.33,0.83,2.35,2.14c0.02,1.3-0.98,2.27-2.29,2.22
	c-1.28-0.05-2.24-1.1-2.1-2.4c0.06-0.54,0.35-1.05,0.53-1.58L531.48,347.99z"
              />
              <path
                d="M426.27,237.62c1.2,0.02,2.1,0.91,2.14,2.13c0.04,1.3-0.95,2.29-2.24,2.26c-1.18-0.03-2.13-0.96-2.17-2.12
	C423.96,238.64,425,237.6,426.27,237.62z"
              />
              <path
                d="M404.54,8.72c0,0,0.07-0.09,0.07-0.09c-0.67,0.23-1.32,0.58-2,0.66c-1.23,0.14-2.16-0.7-2.27-1.88
	c-0.1-1.08,0.59-2.05,1.63-2.29c1.13-0.26,2.36,0.38,2.6,1.49C404.71,7.28,404.56,8.02,404.54,8.72z"
              />
              <path
                d="M484.8,474.72c-0.13,0.15-0.26,0.3-0.39,0.45c-0.52,0.17-1.03,0.43-1.57,0.48c-1.27,0.13-2.19-0.61-2.38-1.78
	c-0.19-1.17,0.52-2.26,1.64-2.52c1.22-0.28,2.39,0.43,2.67,1.72C484.89,473.6,484.8,474.17,484.8,474.72z"
              />
              <path
                d="M318.62,59.64c0,0,0.07,0.03,0.07,0.03c0.09,0.55,0.23,1.1,0.25,1.66c0.05,1.43-0.8,2.4-2.06,2.42
	c-1.32,0.03-2.33-1.11-2.2-2.55c0.03-0.38,0.26-0.75,0.4-1.12c0,0-0.07,0.06-0.07,0.06C316.08,59.07,317.37,59.51,318.62,59.64z"
              />
              <path
                d="M489.68,203.56c0.15,0.14,0.3,0.28,0.45,0.41c0.14,0.45,0.37,0.9,0.39,1.35c0.07,1.29-0.93,2.33-2.15,2.34
	c-1.07,0.01-1.97-0.79-2.1-1.86c-0.16-1.3,0.6-2.26,2-2.39C488.73,203.37,489.21,203.51,489.68,203.56z"
              />
              <path
                d="M375.55,509.62c-0.52,0.2-1.02,0.5-1.55,0.57c-1.36,0.18-2.37-0.67-2.47-1.96c-0.1-1.3,0.83-2.34,2.12-2.37
	c1.27-0.02,2.3,0.96,2.25,2.26c-0.02,0.54-0.28,1.06-0.43,1.6C375.46,509.71,375.55,509.62,375.55,509.62z"
              />
              <path
                d="M538.21,596.68c0.12,0.11,0.24,0.22,0.37,0.33c0.16,0.53,0.41,1.04,0.45,1.58c0.09,1.19-0.75,2.16-1.91,2.3
	c-1.2,0.15-2.21-0.59-2.41-1.76c-0.22-1.28,0.54-2.37,1.85-2.54C537.1,596.52,537.66,596.65,538.21,596.68z"
              />
              <path
                d="M532.16,239.41c0.32,0.45,0.74,0.86,0.94,1.36c0.48,1.21-0.19,2.43-1.43,2.81c-1.13,0.34-2.31-0.29-2.63-1.41
	c-0.37-1.28,0.26-2.46,1.57-2.73c0.52-0.11,1.09,0.03,1.64,0.05C532.23,239.47,532.16,239.41,532.16,239.41z"
              />
              <path
                d="M363.75,229.52c-1.17-0.05-2.11-1-2.13-2.15c-0.01-1.23,1.05-2.26,2.31-2.22c1.28,0.04,2.19,1.09,2.08,2.4
	C365.9,228.76,364.97,229.57,363.75,229.52z"
              />
              <path
                d="M532.31,314.05c-0.06,1.12-1.11,2.1-2.23,2.09c-1.26-0.02-2.21-1.11-2.1-2.4c0.11-1.19,1.09-2.02,2.3-1.94
	C531.43,311.88,532.37,312.93,532.31,314.05z"
              />
              <path
                d="M334.31,328.98c0,0,0.05-0.05,0.05-0.05c-0.5,0.21-0.99,0.54-1.52,0.6c-1.25,0.14-2.35-0.88-2.38-2.08
	c-0.03-1.11,0.92-2.13,2.05-2.19c1.29-0.08,2.27,0.81,2.24,2.16C334.74,327.94,334.47,328.45,334.31,328.98z"
              />
              <path
                d="M516.95,226.47c0.21,0.59,0.52,1.16,0.62,1.76c0.21,1.31-0.69,2.39-1.95,2.47c-1.1,0.08-2.19-0.84-2.32-1.94
	c-0.14-1.19,0.83-2.3,2.11-2.36c0.54-0.02,1.08,0.1,1.62,0.16C517.03,226.56,516.95,226.47,516.95,226.47z"
              />
              <path
                d="M322.78,26.34c-0.39,0.26-0.74,0.62-1.17,0.76c-1.28,0.41-2.47-0.24-2.77-1.43c-0.32-1.27,0.4-2.43,1.65-2.67
	c1.34-0.25,2.51,0.65,2.58,2.06c0.02,0.44-0.23,0.9-0.36,1.34C322.72,26.41,322.78,26.34,322.78,26.34z"
              />
              <path
                d="M372.16,387.29c-0.52,0.19-1.02,0.47-1.55,0.54c-1.35,0.16-2.36-0.7-2.43-1.99c-0.08-1.3,0.87-2.33,2.14-2.33
	c1.26-0.01,2.27,0.99,2.21,2.28c-0.03,0.54-0.29,1.06-0.45,1.59C372.07,387.37,372.16,387.29,372.16,387.29z"
              />
              <path
                d="M413.98,181.01c1.25-0.01,2.28,1.05,2.23,2.27c-0.05,1.14-1.01,2.06-2.18,2.08c-1.29,0.02-2.24-0.96-2.19-2.27
	C411.88,181.9,412.78,181.02,413.98,181.01z"
              />
              <path
                d="M486.79,500.06c0.13,0.17,0.25,0.33,0.38,0.5c0.03,0.98,0.06,1.97,0.09,2.95c0,0,0.09-0.09,0.09-0.09
	c-0.49,0.23-0.96,0.52-1.48,0.66c-1.34,0.38-2.61-0.45-2.79-1.78c-0.16-1.18,0.78-2.3,2.07-2.38
	C485.69,499.88,486.24,500.01,486.79,500.06z"
              />
              <path
                d="M334.41,58.7c-1.21,0.04-2.13-0.79-2.23-2c-0.1-1.32,0.8-2.33,2.1-2.36c1.26-0.03,2.31,1,2.28,2.24
	C336.53,57.71,335.58,58.66,334.41,58.7z"
              />
              <path
                d="M357.14,341.83c0.5-0.22,0.98-0.5,1.5-0.65c1.29-0.37,2.48,0.31,2.79,1.54c0.3,1.17-0.56,2.44-1.79,2.66
	c-1.25,0.22-2.33-0.62-2.47-1.97c-0.05-0.54,0.03-1.1,0.05-1.65C357.23,341.75,357.14,341.83,357.14,341.83z"
              />
              <path
                d="M414.93,500.99c0.05,1.28-0.92,2.32-2.16,2.3c-1.09-0.01-2.12-1-2.18-2.09c-0.07-1.2,0.99-2.29,2.21-2.28
	C413.97,498.92,414.88,499.8,414.93,500.99z"
              />
              <path
                d="M450.22,123.78c0.23,0.59,0.56,1.16,0.66,1.76c0.22,1.31-0.68,2.4-1.94,2.5c-1.1,0.09-2.2-0.83-2.35-1.94
	c-0.15-1.2,0.79-2.34,2.08-2.42c0.54-0.03,1.09,0.11,1.63,0.18C450.3,123.87,450.22,123.78,450.22,123.78z"
              />
              <path
                d="M486.73,494.86c-0.01,1.12-1.01,2.15-2.13,2.18c-1.26,0.04-2.26-1.01-2.19-2.31c0.06-1.2,1.01-2.08,2.2-2.05
	C485.75,492.72,486.73,493.73,486.73,494.86z"
              />
              <path
                d="M369.72,65.79c-0.42,0.22-0.82,0.46-1.25,0.65c-0.92,0.4-1.73,0.15-2.37-0.54c-0.64-0.69-0.83-1.51-0.36-2.41
	c0.46-0.87,1.19-1.24,2.15-1.19c0.47,0.03,0.93,0.13,1.39,0.2c0,0-0.09-0.09-0.09-0.09c0.57,1.1,0.8,2.24,0.45,3.46L369.72,65.79z"
              />
              <path
                d="M394.73,518.8c0.53-0.15,1.05-0.4,1.59-0.44c1.33-0.09,2.31,0.85,2.33,2.14c0.01,1.29-0.99,2.26-2.28,2.2
	c-1.27-0.06-2.21-1.09-2.07-2.4c0.05-0.54,0.33-1.05,0.52-1.58C394.81,518.72,394.73,518.8,394.73,518.8z"
              />
              <path
                d="M667.74,545.77c-0.16-0.61-0.4-1.21-0.46-1.83c-0.11-1.28,0.77-2.24,1.95-2.25c1.04-0.01,2.07,1.06,2.12,2.21
	c0.05,1.18-0.88,2.16-2.08,2.11c-0.54-0.02-1.08-0.21-1.62-0.33C667.65,545.68,667.74,545.77,667.74,545.77z"
              />
              <path
                d="M288.54,335.01c0.03,1.11-0.94,2.15-2.06,2.21c-1.17,0.07-2.15-0.8-2.24-1.98c-0.1-1.3,0.84-2.32,2.13-2.32
	C287.52,332.93,288.51,333.88,288.54,335.01z"
              />
              <path
                d="M405.65,395.34c0,0-0.08,0.08-0.08,0.08c0.51-0.2,1.01-0.51,1.53-0.56c1.3-0.12,2.35,0.86,2.36,2.08
	c0.01,1.1-0.96,2.12-2.08,2.18c-1.25,0.06-2.25-0.89-2.19-2.22C405.21,396.38,405.49,395.86,405.65,395.34z"
              />
              <path
                d="M329.72,376.07c1.17-0.03,2.11,0.84,2.19,2.02c0.08,1.28-0.86,2.33-2.1,2.36c-1.19,0.02-2.28-1.07-2.24-2.26
	C327.61,377.07,328.6,376.1,329.72,376.07z"
              />
              <path
                d="M318.47,512.98c0.05,1.21-0.78,2.18-1.96,2.27c-1.21,0.1-2.35-0.96-2.35-2.17c0-1.13,0.99-2.12,2.13-2.14
	C317.48,510.91,318.41,511.78,318.47,512.98z"
              />
              <path
                d="M369.84,231.81c-0.5,0.23-0.98,0.49-1.49,0.68c-0.94,0.34-1.83,0.09-2.31-0.72c-0.33-0.55-0.47-1.36-0.35-1.99
	c0.18-0.96,0.99-1.42,1.96-1.44c0.55-0.01,1.1,0.12,1.64,0.19c0,0-0.09-0.08-0.09-0.08c0.18,1.15,0.37,2.3,0.55,3.45
	C369.75,231.89,369.84,231.81,369.84,231.81z"
              />
              <path
                d="M349.5,338.28c0.29,0.54,0.64,1.06,0.84,1.64c0.42,1.18-0.32,2.47-1.52,2.78c-1.15,0.3-2.3-0.33-2.63-1.43
	c-0.37-1.22,0.29-2.5,1.52-2.78c0.6-0.14,1.24-0.09,1.86-0.12C349.57,338.35,349.5,338.28,349.5,338.28z"
              />
              <path
                d="M335.07,336.55c0.5-0.22,0.98-0.49,1.5-0.64c1.31-0.37,2.47,0.29,2.79,1.52c0.3,1.17-0.56,2.43-1.79,2.65
	c-1.25,0.22-2.32-0.62-2.45-1.97c-0.05-0.54,0.03-1.1,0.04-1.65C335.15,336.47,335.07,336.55,335.07,336.55z"
              />
              <path
                d="M324.63,380.59c-0.52,0.18-1.03,0.46-1.56,0.52c-1.36,0.15-2.34-0.73-2.4-2.03c-0.06-1.3,0.89-2.31,2.17-2.3
	c1.26,0.01,2.26,1.01,2.18,2.31c-0.03,0.54-0.3,1.06-0.47,1.58C324.55,380.67,324.63,380.59,324.63,380.59z"
              />
              <path
                d="M457.25,141.97c-1.15,0.04-2.12-0.84-2.21-2.01c-0.1-1.29,0.81-2.32,2.08-2.36c1.11-0.03,2.16,0.89,2.26,1.99
	C459.49,140.76,458.45,141.93,457.25,141.97z"
              />
              <path
                d="M411.1,497.88c0.05,1.21-0.77,2.15-1.95,2.24c-1.25,0.1-2.35-0.84-2.4-2.07c-0.05-1.21,0.99-2.27,2.23-2.26
	C410.15,495.8,411.06,496.7,411.1,497.88z"
              />
              <path
                d="M403.89,498.47c-0.01,1.21-0.91,2.11-2.1,2.13c-1.16,0.01-2.16-0.92-2.22-2.06c-0.06-1.22,1.03-2.3,2.26-2.26
	C403,496.33,403.9,497.28,403.89,498.47z"
              />
              <path
                d="M290.37,242.29c0.37,0.5,0.85,0.96,1.09,1.52c0.5,1.19-0.15,2.52-1.31,2.88c-1.09,0.35-2.28-0.23-2.72-1.31
	c-0.46-1.14,0.14-2.43,1.39-2.84c0.51-0.16,1.09-0.13,1.63-0.19C290.44,242.36,290.37,242.29,290.37,242.29z"
              />
              <path
                d="M463.87,150.08c0.01,1.2-0.89,2.13-2.07,2.16c-1.16,0.03-2.17-0.88-2.24-2.03c-0.08-1.21,1-2.31,2.23-2.29
	C462.96,147.95,463.86,148.88,463.87,150.08z"
              />
              <path
                d="M417.71,495.35c0.18,0.52,0.44,1.02,0.51,1.55c0.2,1.35-0.65,2.39-1.91,2.49c-1.12,0.08-2.19-0.82-2.31-1.94
	c-0.13-1.21,0.83-2.27,2.16-2.29c0.54-0.01,1.09,0.17,1.63,0.27L417.71,495.35z"
              />
              <path
                d="M331.8,340.37c0.66-0.23,1.31-0.59,2-0.67c1.15-0.14,2.18,0.86,2.24,1.99c0.05,1.01-0.78,2.02-1.81,2.2
	c-1.18,0.21-2.27-0.51-2.42-1.72c-0.08-0.62,0.05-1.26,0.09-1.89L331.8,340.37z"
              />
              <path
                d="M325.72,513.93c-0.5,0.23-0.97,0.52-1.49,0.68c-1.28,0.4-2.48-0.28-2.8-1.52c-0.28-1.1,0.47-2.28,1.62-2.54
	c1.3-0.29,2.4,0.45,2.59,1.79c0.08,0.54,0,1.11,0,1.66C325.63,514,325.72,513.93,325.72,513.93z"
              />
              <path
                d="M317.81,379.54c-0.05,1.14-1.05,2.07-2.21,2.06c-1.3-0.01-2.21-1.04-2.09-2.36c0.11-1.18,1.1-2.04,2.27-1.95
	C316.92,377.38,317.87,378.43,317.81,379.54z"
              />
              <path
                d="M339.08,377.86c-0.04,1.2-1.01,2.1-2.19,2.06c-1.13-0.04-2.12-1.1-2.08-2.21c0.04-1.14,1.06-2.07,2.24-2.03
	C338.27,375.71,339.13,376.63,339.08,377.86z"
              />
              <path
                d="M442.68,117.38c0,0-0.09-0.08-0.09-0.08c0.19,0.6,0.48,1.18,0.57,1.79c0.18,1.21-0.62,2.23-1.75,2.39
	c-1.1,0.16-2.23-0.68-2.41-1.8c-0.17-1.06,0.65-2.22,1.81-2.39C441.41,117.21,442.05,117.34,442.68,117.38z"
              />
              <path
                d="M384.05,191.12c0.52-0.16,1.03-0.39,1.56-0.45c1.31-0.17,2.35,0.7,2.43,1.98c0.08,1.29-0.86,2.31-2.14,2.3
	c-1.24-0.02-2.23-1.05-2.14-2.32c0.04-0.53,0.25-1.06,0.38-1.58C384.14,191.04,384.05,191.12,384.05,191.12z"
              />
              <path
                d="M364.44,232.38c1.17,0.06,2.04,1.02,2.01,2.22c-0.03,1.2-0.94,2.1-2.13,2.09c-1.24-0.01-2.28-1.12-2.17-2.33
	C362.26,233.23,363.31,232.32,364.44,232.38z"
              />
              <path
                d="M286.14,224.95c-0.08-0.08-0.16-0.15-0.26-0.21c-0.18-0.52-0.47-1.03-0.51-1.56c-0.11-1.27,0.91-2.31,2.15-2.33
	c1.18-0.02,2.11,0.86,2.17,2.05c0.07,1.21-0.68,2.16-1.92,2.27C287.24,225.2,286.69,225.02,286.14,224.95z"
              />
              <path
                d="M326.88,344.67c-1.25-0.01-2.27-1.07-2.19-2.29c0.07-1.13,1.06-2.05,2.2-2.05c1.27,0,2.21,1.01,2.14,2.31
	C328.98,343.84,328.09,344.68,326.88,344.67z"
              />
              <path
                d="M481.04,182.89c0.13,0.53,0.34,1.05,0.38,1.58c0.08,1.29-0.9,2.3-2.15,2.31c-1.29,0.01-2.22-1-2.13-2.3
	c0.08-1.28,1.11-2.15,2.43-1.98c0.53,0.07,1.04,0.31,1.56,0.47C481.13,182.98,481.04,182.89,481.04,182.89z"
              />
              <path
                d="M660.53,626.31c0.12,0.45,0.31,0.9,0.36,1.36c0.14,1.43-0.85,2.52-2.21,2.5c-1.29-0.02-2.2-1.05-2.1-2.36
	c0.1-1.29,1.13-2.13,2.48-1.95c0.53,0.07,1.04,0.35,1.56,0.53C660.62,626.4,660.53,626.31,660.53,626.31z"
              />
              <path
                d="M426.91,99.25c-0.01,1.2-0.91,2.11-2.1,2.12c-1.15,0.01-2.15-0.93-2.21-2.06c-0.06-1.2,1.04-2.29,2.26-2.25
	C426.03,97.11,426.92,98.06,426.91,99.25z"
              />
              <path
                d="M457.04,235.67c-0.17-0.15-0.35-0.3-0.52-0.46c-0.17-0.52-0.46-1.03-0.49-1.56c-0.07-1.25,0.78-2.16,1.96-2.24
	c1.05-0.07,2.03,0.66,2.29,1.7c0.27,1.09-0.41,2.26-1.59,2.54C458.17,235.78,457.59,235.67,457.04,235.67z"
              />
              <path
                d="M413.85,491.98c0.19,0.59,0.49,1.17,0.55,1.78c0.12,1.22-0.74,2.17-1.92,2.26c-1.12,0.09-2.19-0.8-2.32-1.92
	c-0.13-1.12,0.72-2.19,1.91-2.27c0.61-0.04,1.24,0.15,1.86,0.23L413.85,491.98z"
              />
              <path
                d="M513.14,223.14c0.2,0.59,0.5,1.17,0.59,1.79c0.18,1.23-0.64,2.24-1.81,2.39c-1.12,0.15-2.24-0.71-2.42-1.84
	c-0.18-1.13,0.64-2.23,1.84-2.36c0.62-0.07,1.26,0.07,1.89,0.11C513.22,223.23,513.14,223.14,513.14,223.14z"
              />
              <path
                d="M352.1,346.1c-1.19,0.01-2.09-0.85-2.15-2.04c-0.06-1.28,0.89-2.3,2.14-2.29c1.13,0.01,2.12,0.93,2.19,2.05
	C354.37,345.03,353.35,346.1,352.1,346.1z"
              />
              <path
                d="M365.01,532.09c1.18-0.02,2.19,0.92,2.21,2.06c0.02,1.11-0.99,2.16-2.11,2.19c-1.18,0.03-2.13-0.89-2.16-2.09
	C362.91,533.03,363.78,532.12,365.01,532.09z"
              />
              <path
                d="M531.57,347.91c-0.5,0.23-0.97,0.51-1.49,0.67c-1.29,0.39-2.48-0.27-2.79-1.51c-0.28-1.1,0.48-2.28,1.62-2.53
	c1.3-0.28,2.39,0.45,2.58,1.8c0.07,0.54,0.01,1.1,0,1.65C531.48,347.99,531.57,347.91,531.57,347.91z"
              />
              <path
                d="M373.39,217.75c-1.11-0.03-2.11-1.04-2.12-2.14c-0.01-1.21,1.09-2.21,2.33-2.12c1.19,0.08,2.02,1.01,1.96,2.22
	C375.5,216.88,374.55,217.78,373.39,217.75z"
              />
              <path
                d="M386.03,505.37c-0.52,0.17-1.03,0.44-1.56,0.49c-1.35,0.13-2.32-0.74-2.37-2.04c-0.05-1.29,0.92-2.3,2.18-2.28
	c1.23,0.02,2.23,1.05,2.15,2.33c-0.04,0.53-0.31,1.05-0.47,1.58C385.95,505.45,386.03,505.37,386.03,505.37z"
              />
              <path
                d="M260.32,216.52c-0.18-0.17-0.36-0.33-0.54-0.5c-0.17-0.44-0.44-0.86-0.49-1.31c-0.15-1.47,0.78-2.52,2.13-2.5
	c1.33,0.02,2.31,1.15,2.14,2.54c-0.06,0.46-0.28,0.89-0.43,1.34c0,0,0.09-0.09,0.09-0.09C262.26,216.17,261.29,216.34,260.32,216.52
	z"
              />
              <path
                d="M395.23,524.5c0.05,1.2-0.77,2.16-1.95,2.25c-1.2,0.09-2.34-0.95-2.34-2.14c0-1.11,0.98-2.09,2.11-2.12
	C394.25,522.45,395.17,523.31,395.23,524.5z"
              />
              <path
                d="M323.37,388.32c-1.19,0.04-2.12-0.84-2.18-2.04c-0.06-1.2,0.78-2.17,1.95-2.27c1.21-0.1,2.36,0.95,2.36,2.16
	C325.49,387.29,324.51,388.28,323.37,388.32z"
              />
              <path
                d="M435.16,241.4c0.11-0.15,0.21-0.29,0.32-0.44c1.09-0.19,2.18-0.39,3.27-0.58c0.17,0.2,0.35,0.4,0.52,0.59
	c0.03,0.39,0.09,0.78,0.09,1.17c-0.02,1.5-0.76,2.39-2,2.44c-1.22,0.05-2.06-0.77-2.25-2.25
	C435.06,242.04,435.14,241.72,435.16,241.4z"
              />
              <path
                d="M535.23,387.71c-0.15-0.12-0.29-0.25-0.44-0.37c-0.18-0.51-0.49-1.02-0.53-1.55c-0.09-1.24,0.76-2.17,1.93-2.27
	c1.04-0.08,2.04,0.66,2.29,1.69c0.26,1.09-0.43,2.26-1.6,2.53C536.36,387.87,535.78,387.74,535.23,387.71z"
              />
              <path
                d="M356.22,531.48c0.05,1.13-0.85,2.13-2,2.24c-1.29,0.12-2.31-0.79-2.35-2.08c-0.04-1.19,0.79-2.15,1.96-2.25
	C355.06,529.28,356.17,530.25,356.22,531.48z"
              />
              <path
                d="M362.63,231.21c-0.01,1.21-0.92,2.12-2.11,2.13c-1.17,0.01-2.16-0.92-2.22-2.07c-0.06-1.22,1.03-2.3,2.27-2.26
	C361.75,229.05,362.64,230.01,362.63,231.21z"
              />
              <path
                d="M532.29,353.69c0.05,1.1-0.89,2.14-2.02,2.23c-1.17,0.09-2.15-0.74-2.26-1.92c-0.12-1.3,0.79-2.32,2.08-2.34
	C531.24,351.65,532.24,352.58,532.29,353.69z"
              />
              <path
                d="M459.57,18.45c-0.21-0.5-0.52-0.99-0.62-1.52c-0.26-1.39,0.52-2.38,1.84-2.49c1.2-0.1,2.23,0.69,2.38,1.82
	c0.15,1.16-0.83,2.35-2.08,2.39c-0.53,0.02-1.07-0.19-1.61-0.3C459.48,18.36,459.57,18.45,459.57,18.45z"
              />
              <path
                d="M483.9,467.96c-0.52,0.17-1.03,0.43-1.56,0.48c-1.35,0.12-2.31-0.77-2.35-2.06c-0.04-1.29,0.94-2.29,2.2-2.26
	c1.23,0.03,2.22,1.07,2.13,2.34c-0.04,0.53-0.31,1.05-0.48,1.57C483.83,468.03,483.9,467.96,483.9,467.96z"
              />
              <path
                d="M366.57,506.66c1.17,0.04,2.14,1.06,2.09,2.19c-0.05,1.12-1.11,2.09-2.24,2.06c-1.19-0.04-2.07-0.99-2.04-2.21
	C364.42,507.49,365.36,506.61,366.57,506.66z"
              />
              <path
                d="M385.01,529.23c-0.04,1.22-1.14,2.18-2.38,2.08c-1.18-0.09-2-1.03-1.96-2.23c0.04-1.18,0.94-2.08,2.11-2.1
	C383.99,526.97,385.05,528.02,385.01,529.23z"
              />
              <path
                d="M539,372.49c1.17,0,2.09,0.93,2.11,2.12c0.02,1.21-0.84,2.14-2.03,2.19c-1.24,0.05-2.33-1.01-2.28-2.22
	C536.85,373.46,537.87,372.49,539,372.49z"
              />
              <path
                d="M390.47,201.19c1.13-0.01,2.15,0.96,2.2,2.08c0.05,1.21-1.03,2.27-2.28,2.22c-1.18-0.05-2.05-0.98-2.03-2.18
	C388.38,202.12,389.3,201.19,390.47,201.19z"
              />
              <path
                d="M358.78,61.87c-0.04,1.22-0.95,2.1-2.15,2.09c-1.16-0.01-2.15-0.97-2.18-2.11c-0.03-1.22,1.08-2.28,2.32-2.21
	C357.95,59.7,358.82,60.66,358.78,61.87z"
              />
              <path
                d="M486.99,456.14c-0.42,0.37-0.78,0.84-1.26,1.07c-1.2,0.58-2.49,0.04-2.99-1.15c-0.48-1.14,0.17-2.5,1.38-2.88
	c1.21-0.38,2.41,0.3,2.74,1.61c0.11,0.45,0.05,0.95,0.08,1.42C486.94,456.21,486.99,456.14,486.99,456.14z"
              />
              <path
                d="M481.61,477.28c0.03,1.21-0.82,2.14-2.01,2.2c-1.24,0.06-2.34-0.99-2.3-2.2c0.04-1.11,1.05-2.09,2.18-2.1
	C480.65,475.17,481.58,476.08,481.61,477.28z"
              />
              <path
                d="M336.51,388.89c-0.05,1.12-1.12,2.1-2.24,2.06c-1.19-0.04-2.07-1-2.04-2.21c0.04-1.21,0.97-2.08,2.19-2.04
	C335.59,386.74,336.56,387.76,336.51,388.89z"
              />
              <path
                d="M532.77,360.94c0.02,1.12-0.99,2.16-2.12,2.18c-1.18,0.03-2.13-0.9-2.15-2.1c-0.02-1.23,0.85-2.13,2.08-2.15
	C531.74,358.85,532.75,359.8,532.77,360.94z"
              />
              <path
                d="M662.23,525.04c0,0-0.01,0.1-0.01,0.1c1.78-1.3,2.94-1.17,3.55,0.4c0.64,1.64-0.09,2.77-1.87,2.88
	c-0.39,0.02-0.78-0.04-1.18-0.06c-0.09-0.08-0.17-0.16-0.24-0.25C662.03,527.11,661.76,526.1,662.23,525.04z"
              />
              <path
                d="M368.66,382.32c0.01,1.11-1.02,2.13-2.16,2.14c-1.17,0.01-2.11-0.94-2.11-2.13c0-1.19,0.94-2.15,2.1-2.15
	C367.61,380.19,368.65,381.22,368.66,382.32z"
              />
              <path
                d="M365.01,221.77c-0.52,0.16-1.03,0.38-1.56,0.45c-1.31,0.18-2.36-0.72-2.41-1.99c-0.05-1.17,0.84-2.16,1.98-2.22
	c1.2-0.06,2.27,0.99,2.22,2.25c-0.02,0.53-0.22,1.06-0.33,1.59C364.92,221.86,365.01,221.77,365.01,221.77z"
              />
              <path
                d="M287.22,239.52c0.1,0.53,0.27,1.05,0.29,1.58c0.06,1.28-0.98,2.32-2.21,2.26c-1.17-0.05-2.05-1.03-2-2.21
	c0.06-1.27,1.12-2.18,2.42-2c0.53,0.07,1.05,0.29,1.57,0.44C287.31,239.6,287.22,239.52,287.22,239.52z"
              />
              <path
                d="M415.69,175.94c0.03,1.11-0.92,2.13-2.06,2.2c-1.19,0.07-2.14-0.77-2.23-1.97c-0.09-1.28,0.86-2.34,2.12-2.33
	C414.65,173.85,415.66,174.83,415.69,175.94z"
              />
              <path
                d="M292.2,235.71c-1.17,0.05-2.14-0.86-2.18-2.05c-0.04-1.19,0.86-2.18,2.02-2.22c1.11-0.04,2.18,0.95,2.23,2.06
	C294.32,234.61,293.33,235.66,292.2,235.71z"
              />
              <path
                d="M361.15,511.12c-0.52,0.16-1.03,0.4-1.56,0.47c-1.32,0.17-2.35-0.74-2.39-2.02c-0.04-1.18,0.85-2.16,2-2.22
	c1.21-0.05,2.29,0.99,2.23,2.26c-0.02,0.54-0.24,1.07-0.36,1.6C361.07,511.2,361.15,511.12,361.15,511.12z"
              />
              <path
                d="M408.95,516.3c-0.06,1.12-1.11,2.08-2.24,2.03c-1.17-0.05-2.05-1.03-2-2.22c0.05-1.18,1.04-2.1,2.19-2.04
	C408,514.12,409,515.21,408.95,516.3z"
              />
              <path
                d="M481.38,461.24c-1.15-0.02-2.16-1.04-2.14-2.16c0.02-1.11,1.07-2.13,2.19-2.12c1.17,0.01,2.09,0.97,2.08,2.17
	C483.5,460.33,482.56,461.26,481.38,461.24z"
              />
              <path
                d="M468.15,162.28c-0.52,0.16-1.03,0.4-1.57,0.46c-1.32,0.16-2.35-0.74-2.39-2.03c-0.03-1.18,0.86-2.16,2.01-2.21
	c1.21-0.05,2.28,1,2.22,2.26c-0.03,0.54-0.24,1.07-0.37,1.6C468.07,162.37,468.15,162.28,468.15,162.28z"
              />
              <path
                d="M339.94,57.63c0.19,0.6,0.48,1.18,0.56,1.79c0.16,1.22-0.68,2.21-1.85,2.34c-1.13,0.13-2.22-0.73-2.38-1.86
	c-0.16-1.13,0.66-2.21,1.87-2.32c0.62-0.06,1.26,0.08,1.89,0.13C340.02,57.71,339.94,57.63,339.94,57.63z"
              />
              <path
                d="M405.19,492.48c1.15,0.04,2.05,1.04,2.01,2.21c-0.04,1.17-1.01,2.09-2.17,2.04c-1.12-0.05-2.11-1.1-2.06-2.2
	C403.02,493.43,404.1,492.44,405.19,492.48z"
              />
              <path
                d="M535.26,369.15c1.15,0.06,2.03,1.02,2,2.2c-0.02,1.18-0.94,2.09-2.11,2.09c-1.23,0-2.27-1.11-2.16-2.31
	C533.09,370.01,534.14,369.09,535.26,369.15z"
              />
              <path
                d="M379.23,504.18c0.02,1.12-0.94,2.12-2.08,2.17c-1.18,0.05-2.13-0.8-2.21-1.99c-0.08-1.28,0.89-2.33,2.14-2.31
	C378.2,502.07,379.21,503.07,379.23,504.18z"
              />
              <path
                d="M324.52,332c-0.03,1.12-1.02,2.08-2.16,2.09c-1.27,0.01-2.22-1.02-2.12-2.31c0.09-1.17,1.07-2.05,2.23-1.98
	C323.58,329.86,324.55,330.89,324.52,332z"
              />
              <path
                d="M505.92,220.38c0.52-0.15,1.04-0.36,1.57-0.43c1.31-0.17,2.36,0.76,2.4,2.04c0.04,1.2-0.86,2.16-2.03,2.2
	c-1.24,0.04-2.28-1.01-2.2-2.29c0.03-0.54,0.22-1.07,0.34-1.6C506,220.3,505.92,220.38,505.92,220.38z"
              />
              <path
                d="M533.42,367.98c-0.02,1.18-0.93,2.09-2.1,2.1c-1.13,0-2.14-0.95-2.18-2.06c-0.05-1.19,1.06-2.28,2.27-2.22
	C532.56,365.84,533.44,366.79,533.42,367.98z"
              />
              <path
                d="M318.27,386.74c-0.05,1.14-1.1,2.08-2.25,2.04c-1.18-0.05-2.06-1.02-2.01-2.23c0.05-1.19,1.04-2.11,2.2-2.05
	C317.33,384.56,318.32,385.64,318.27,386.74z"
              />
              <path
                d="M379.37,530.86c1.1-0.04,2.18,0.96,2.22,2.06c0.05,1.1-0.95,2.16-2.07,2.2c-1.16,0.05-2.13-0.87-2.17-2.05
	C377.32,531.89,378.22,530.9,379.37,530.86z"
              />
              <path
                d="M479.94,482.39c1.14-0.02,2.11,0.94,2.13,2.12c0.01,1.19-0.9,2.14-2.07,2.15c-1.13,0.01-2.16-0.99-2.18-2.1
	C477.8,483.47,478.84,482.41,479.94,482.39z"
              />
              <path
                d="M511.9,410.68c-0.45,0.32-0.87,0.72-1.37,0.93c-1.2,0.51-2.45-0.11-2.84-1.35c-0.34-1.06,0.34-2.28,1.45-2.63
	c1.09-0.34,2.27,0.3,2.58,1.48c0.14,0.52,0.07,1.1,0.1,1.65C511.83,410.76,511.9,410.68,511.9,410.68z"
              />
              <path
                d="M374.43,533.47c0.01,1.12-0.97,2.11-2.11,2.15c-1.18,0.04-2.12-0.83-2.18-2.02c-0.07-1.28,0.91-2.32,2.17-2.28
	C373.42,531.35,374.43,532.37,374.43,533.47z"
              />
              <path
                d="M423.05,95.78c0.04,1.21-0.84,2.17-2.02,2.22c-1.15,0.04-2.19-0.91-2.24-2.05c-0.05-1.1,0.95-2.18,2.08-2.23
	C422.03,93.67,423.01,94.59,423.05,95.78z"
              />
              <path
                d="M382.27,207.73c-0.03,1.18-0.95,2.08-2.12,2.07c-1.14-0.01-2.13-0.97-2.16-2.09c-0.03-1.19,1.09-2.27,2.29-2.2
	C381.43,205.59,382.3,206.55,382.27,207.73z"
              />
              <path
                d="M415.84,368.68c1.1-0.04,2.17,0.95,2.22,2.05c0.05,1.1-0.94,2.15-2.06,2.2c-1.16,0.05-2.13-0.87-2.17-2.04
	C413.79,369.72,414.69,368.72,415.84,368.68z"
              />
              <path
                d="M445.01,120.58c1.16,0.04,2.06,0.98,2.05,2.17c0,1.19-0.9,2.11-2.08,2.13c-1.24,0.02-2.3-1.06-2.21-2.27
	C442.85,121.49,443.88,120.55,445.01,120.58z"
              />
              <path
                d="M309.2,511.66c1.17,0.03,2.08,1,2.05,2.2c-0.03,1.19-0.98,2.12-2.16,2.08c-1.14-0.03-2.14-1.07-2.1-2.19
	C307.02,512.63,308.07,511.63,309.2,511.66z"
              />
              <path
                d="M331.89,340.28c-0.52,0.17-1.04,0.42-1.57,0.49c-1.34,0.17-2.36-0.72-2.41-2.03c-0.04-1.2,0.84-2.17,2.01-2.23
	c1.23-0.06,2.31,0.97,2.26,2.25c-0.02,0.54-0.25,1.07-0.38,1.61C331.8,340.37,331.89,340.28,331.89,340.28z"
              />
              <path
                d="M347.23,344.43c0,1.18-0.95,2.12-2.11,2.11c-1.12-0.01-2.15-1.03-2.14-2.13c0.01-1.09,1.06-2.12,2.16-2.12
	C346.29,342.29,347.23,343.26,347.23,344.43z"
              />
              <path
                d="M329.93,521.21c0.11,0.53,0.3,1.06,0.31,1.6c0.05,1.29-1.01,2.3-2.26,2.24c-1.18-0.06-2.04-1.03-1.99-2.24
	c0.06-1.29,1.11-2.18,2.43-2c0.54,0.07,1.05,0.31,1.58,0.48C330.02,521.3,329.93,521.21,329.93,521.21z"
              />
              <path
                d="M484.96,193.18c0.13,0.1,0.25,0.2,0.38,0.3c0.19,0.51,0.48,1.01,0.53,1.54c0.12,1.23-0.7,2.18-1.86,2.29
	c-1.03,0.1-2.02-0.62-2.28-1.66c-0.24-0.96,0.35-2.15,1.37-2.44C483.68,193.04,484.34,193.18,484.96,193.18z"
              />
              <path
                d="M418.24,90.43c0.15,0.13,0.3,0.25,0.45,0.38c0.17,0.52,0.46,1.02,0.5,1.55c0.1,1.24-0.73,2.17-1.89,2.27
	c-1.04,0.09-2.03-0.63-2.28-1.66c-0.27-1.07,0.41-2.23,1.58-2.53C417.11,90.32,417.69,90.43,418.24,90.43z"
              />
              <path
                d="M321.81,343c-0.01,1.18-0.91,2.1-2.08,2.12c-1.13,0.02-2.14-0.92-2.2-2.04c-0.06-1.19,1.04-2.29,2.24-2.25
	C320.93,340.87,321.82,341.82,321.81,343z"
              />
              <path
                d="M332.85,511.86c0.02,1.19-0.86,2.12-2.03,2.16c-1.14,0.04-2.16-0.87-2.25-1.99c-0.09-1.19,0.98-2.31,2.19-2.3
	C331.92,509.75,332.83,510.68,332.85,511.86z"
              />
              <path
                d="M369.64,65.87c0.52-0.14,1.03-0.34,1.56-0.41c1.3-0.17,2.37,0.75,2.41,2.02c0.04,1.18-0.86,2.16-2.02,2.19
	c-1.23,0.04-2.26-1.01-2.18-2.29c0.03-0.53,0.21-1.06,0.32-1.59C369.72,65.79,369.64,65.87,369.64,65.87z"
              />
              <path
                d="M368.35,217.93c-0.52,0.16-1.03,0.4-1.57,0.47c-1.33,0.17-2.36-0.73-2.4-2.02c-0.04-1.19,0.85-2.17,2.01-2.22
	c1.22-0.06,2.29,0.98,2.24,2.26c-0.02,0.54-0.24,1.07-0.37,1.6C368.27,218.02,368.35,217.93,368.35,217.93z"
              />
              <path
                d="M418.6,376.14c-0.11-0.11-0.24-0.21-0.37-0.3c-0.18-0.52-0.48-1.02-0.53-1.55c-0.1-1.12,0.67-2.04,1.71-2.22
	c1.07-0.19,2.24,0.68,2.42,1.8c0.16,1-0.52,2.08-1.6,2.32C419.73,376.31,419.15,376.17,418.6,376.14z"
              />
              <path
                d="M252.21,109.08c-1.21,0.04-2.15-0.85-2.16-2.04c-0.01-1.17,0.9-2.17,2.03-2.22c1.16-0.06,2.11,0.86,2.14,2.06
	C254.26,108.08,253.39,109.04,252.21,109.08z"
              />
              <path
                d="M425.47,108.52c-1.16-0.03-2.08-1.03-2.02-2.21c0.05-1.08,0.94-1.97,1.99-2.01c1.1-0.04,2.19,1,2.2,2.1
	C427.64,107.52,426.59,108.55,425.47,108.52z"
              />
              <path
                d="M416.61,396.28c-0.02,1.1-1.05,2.1-2.17,2.08c-1.16-0.02-2.07-0.97-2.05-2.15c0.02-1.16,0.99-2.12,2.12-2.1
	C415.59,394.14,416.63,395.2,416.61,396.28z"
              />
              <path
                d="M480.9,470.07c0.04,1.08-0.95,2.14-2.05,2.18c-1.15,0.04-2.11-0.87-2.15-2.04c-0.04-1.16,0.87-2.15,2-2.19
	C479.79,467.98,480.85,468.97,480.9,470.07z"
              />
              <path
                d="M528.59,231.07c0.06,1.17-0.82,2.15-1.97,2.21c-1.11,0.06-2.16-0.89-2.22-2c-0.06-1.08,0.92-2.16,2.01-2.22
	C527.53,228.99,528.53,229.92,528.59,231.07z"
              />
              <path
                d="M350.42,59.96c0.3,0.44,0.66,0.86,0.89,1.34c0.57,1.2,0.07,2.47-1.08,2.95c-1.06,0.43-2.24-0.08-2.71-1.19
	c-0.48-1.13,0.1-2.38,1.38-2.8c0.51-0.16,1.07-0.16,1.6-0.23C350.48,60.02,350.42,59.96,350.42,59.96z"
              />
              <path
                d="M386.75,197.88c1.11,0.07,2.09,1.19,1.99,2.28c-0.1,1.14-1.21,2.05-2.33,1.92c-1.07-0.12-1.87-1.05-1.84-2.14
	C384.6,198.77,385.62,197.81,386.75,197.88z"
              />
              <path
                d="M326.29,22.66c0,0,0.07-0.1,0.07-0.1c-0.57,0.26-1.12,0.63-1.72,0.76c-1.21,0.26-2.25-0.53-2.43-1.69
	c-0.17-1.13,0.45-2.09,1.53-2.35c1.19-0.29,2.31,0.3,2.58,1.49C326.44,21.37,326.3,22.02,326.29,22.66z"
              />
              <path
                d="M363.04,238.34c0.02,1.18-0.94,2.16-2.1,2.14c-1.12-0.02-2.14-1.08-2.09-2.19c0.05-1.01,1.01-1.98,2.02-2.03
	C362.01,236.2,363.02,237.18,363.04,238.34z"
              />
              <path
                d="M555.42,374.91c-0.08,0.12-0.16,0.23-0.25,0.34c-0.54,0.11-1.07,0.28-1.62,0.31c-1.31,0.05-2.34-0.92-2.33-2.13
	c0.01-1.12,0.91-2.03,2.15-2.06c0.54-0.02,1.09,0.12,1.63,0.18c0,0-0.09-0.07-0.09-0.07C555.08,372.62,555.25,373.76,555.42,374.91z
	"
              />
              <path
                d="M383.17,205.93c-1.15-0.06-2.02-1.05-1.96-2.22c0.06-1.15,1.06-2.08,2.18-2.01c1.1,0.07,2.07,1.15,2.01,2.23
	C385.34,205.05,384.28,205.99,383.17,205.93z"
              />
              <path
                d="M275.54,184.63c0,1.1-1.02,2.11-2.13,2.12c-1.15,0.01-2.09-0.94-2.09-2.11c0-1.16,0.95-2.13,2.08-2.13
	C274.48,182.51,275.54,183.55,275.54,184.63z"
              />
              <path
                d="M267.5,215.99c-0.17-0.15-0.34-0.3-0.51-0.45c-0.15-0.44-0.4-0.87-0.44-1.32c-0.13-1.35,0.72-2.37,1.94-2.44
	c1.28-0.07,2.31,1.05,2.2,2.45c-0.04,0.46-0.22,0.91-0.33,1.37c0,0,0.07-0.06,0.07-0.06C269.46,215.69,268.48,215.84,267.5,215.99z"
              />
              <path
                d="M291.35,224.26c1.14-0.03,2.12,0.97,2.12,2.14c-0.01,1.08-0.84,1.99-1.91,2.08c-1.12,0.09-2.21-0.86-2.27-1.99
	C289.22,225.39,290.24,224.29,291.35,224.26z"
              />
              <path
                d="M350.81,537.51c-1.1-0.04-2.09-1.1-2.04-2.19c0.05-1.09,1.11-2.08,2.2-2.04c1.14,0.04,2.04,1.03,2,2.2
	C352.93,536.64,351.96,537.55,350.81,537.51z"
              />
              <path
                d="M542.8,602.18c-0.05,1.11-1.1,2.07-2.21,2.02c-1.15-0.05-2.04-1.03-1.99-2.19c0.05-1.15,1.05-2.09,2.16-2.03
	C541.84,600.02,542.84,601.11,542.8,602.18z"
              />
              <path
                d="M359.85,226.09c-1.15,0.04-2.14-0.92-2.15-2.09c0-1.08,0.83-2.01,1.88-2.12c1.1-0.11,2.22,0.85,2.3,1.98
	C361.96,224.96,360.97,226.05,359.85,226.09z"
              />
              <path
                d="M359.99,534.89c0.02,1.1-1,2.12-2.11,2.14c-1.15,0.02-2.1-0.92-2.11-2.09c-0.01-1.17,0.92-2.14,2.06-2.15
	C358.92,532.78,359.98,533.81,359.99,534.89z"
              />
              <path
                d="M296.78,57.7c-0.15,0.14-0.3,0.28-0.44,0.42c-0.61,0.12-1.23,0.36-1.83,0.33c-0.98-0.06-1.77-1.03-1.75-1.93
	c0.02-1.07,1.05-2.09,2.15-2.13c1.06-0.04,1.76,0.52,1.93,1.66C296.91,56.59,296.8,57.15,296.78,57.7z"
              />
              <path
                d="M389.97,176.18c-0.52,0.15-1.02,0.38-1.55,0.45c-1.3,0.18-2.36-0.71-2.41-1.97c-0.05-1.17,0.83-2.17,1.97-2.23
	c1.19-0.06,2.27,0.99,2.22,2.25c-0.02,0.53-0.2,1.06-0.31,1.58C389.89,176.27,389.97,176.18,389.97,176.18z"
              />
              <path
                d="M325.62,346.27c0.04,1.18-0.85,2.15-2.01,2.18c-1.12,0.04-2.16-0.93-2.2-2.04c-0.04-1.07,0.97-2.16,2.06-2.2
	C324.59,344.17,325.58,345.11,325.62,346.27z"
              />
              <path
                d="M312.5,507.84c1.13,0,2.08,0.97,2.08,2.12c0,1.17-0.93,2.11-2.08,2.11c-1.11,0-2.13-1.02-2.13-2.11
	C310.37,508.89,311.43,507.84,312.5,507.84z"
              />
              <path
                d="M352.03,512.07c-1.16-0.03-2.07-1.04-2.01-2.22c0.06-1.08,0.95-1.96,2.01-1.99c1.1-0.03,2.18,1.02,2.18,2.12
	C354.22,511.08,353.16,512.1,352.03,512.07z"
              />
              <path
                d="M403.67,517.92c1.13,0,2.08,0.97,2.08,2.12c0,1.17-0.93,2.11-2.08,2.11c-1.11,0-2.13-1.02-2.13-2.11
	C401.54,518.97,402.6,517.92,403.67,517.92z"
              />
              <path
                d="M412.03,365.34c1.1-0.03,2.18,1.03,2.17,2.13c-0.01,1.12-1.07,2.13-2.19,2.09c-1.07-0.04-1.94-0.91-2-1.99
	C409.95,366.38,410.88,365.37,412.03,365.34z"
              />
              <path
                d="M459.98,146.77c-0.02,1.17-0.98,2.1-2.13,2.07c-1.11-0.03-2.12-1.07-2.09-2.16c0.03-1.09,1.09-2.1,2.18-2.08
	C459.08,144.63,460,145.61,459.98,146.77z"
              />
              <path
                d="M250.37,380.12c-1.15,0-2.08-0.94-2.08-2.11c0-1.16,0.95-2.13,2.08-2.12c1.08,0,2.13,1.05,2.13,2.12
	C252.5,379.1,251.48,380.12,250.37,380.12z"
              />
              <path
                d="M262.8,191.05c-1.16-0.03-2.08-1.03-2.02-2.2c0.05-1.07,0.94-1.96,1.99-2c1.09-0.04,2.18,0.99,2.19,2.09
	C264.97,190.05,263.92,191.08,262.8,191.05z"
              />
              <path
                d="M365.24,386.18c-0.01,1.11-1.06,2.12-2.18,2.08c-1.16-0.04-2.06-1.05-1.99-2.23c0.06-1.07,0.96-1.95,2.01-1.98
	C364.18,384.03,365.25,385.08,365.24,386.18z"
              />
              <path
                d="M354.1,381.72c-0.27-0.39-0.62-0.74-0.8-1.17c-0.52-1.23,0.05-2.48,1.25-2.84c1.25-0.38,2.49,0.32,2.73,1.55
	c0.25,1.27-0.51,2.34-1.86,2.52c-0.45,0.06-0.93-0.09-1.4-0.14C354.01,381.64,354.1,381.72,354.1,381.72z"
              />
              <path
                d="M555.78,609.95c-1.12-0.01-2.13-1.06-2.1-2.17c0.04-1.11,1.13-2.11,2.23-2.04c1.05,0.07,1.91,0.98,1.94,2.05
	C557.88,608.98,556.94,609.96,555.78,609.95z"
              />
              <path
                d="M379,195.65c-1.19,0.01-2.12-0.91-2.13-2.11c-0.01-1.22,0.86-2.11,2.09-2.12c1.18-0.01,2.17,0.94,2.17,2.08
	C381.14,194.62,380.13,195.63,379,195.65z"
              />
              <path
                d="M523.82,388.48c0,0,0.08-0.08,0.08-0.08c-1.08,0.68-2.2,0.97-3.22-0.04c-0.66-0.65-0.87-1.47-0.4-2.37
	c0.76-1.46,2-1.25,3.27-0.96c0.07,0.02,0.16,0,0.23,0.01l-0.08-0.08C524.19,386.11,524.64,387.27,523.82,388.48z"
              />
              <path
                d="M380.23,502.46c-1.12,0.04-2.18-0.97-2.18-2.08c-0.01-1.1,1.07-2.15,2.16-2.12c1.05,0.03,1.95,0.91,2.01,1.98
	C382.3,501.41,381.39,502.42,380.23,502.46z"
              />
              <path
                d="M322.91,337.03c1.1,0.05,2.1,1.17,2.02,2.25c-0.08,1.13-1.18,2.06-2.3,1.95c-1.06-0.11-1.88-1.03-1.87-2.11
	C320.77,337.97,321.78,336.98,322.91,337.03z"
              />
              <path
                d="M322.52,436.93c0.06,1.1-0.94,2.18-2.05,2.21c-1.06,0.03-1.99-0.81-2.1-1.88c-0.12-1.16,0.74-2.23,1.89-2.33
	C321.36,434.83,322.46,435.8,322.52,436.93z"
              />
              <path
                d="M398.48,399.29c0.16,0.43,0.36,0.85,0.48,1.3c0.25,0.9,0.12,1.73-0.65,2.34c-0.75,0.6-1.72,0.76-2.39,0.16
	c-0.56-0.51-0.81-1.4-1.05-2.17c-0.09-0.3,0.21-0.73,0.34-1.11c0,0-0.07,0.05-0.07,0.05c1.04-0.86,2.24-0.65,3.43-0.48
	L398.48,399.29z"
              />
              <path
                d="M391.23,190.76c0.49-0.24,0.96-0.6,1.47-0.71c1.26-0.25,2.26,0.45,2.48,1.61c0.22,1.19-0.48,2.24-1.65,2.47
	c-1.22,0.24-2.43-0.59-2.54-1.86c-0.04-0.52,0.21-1.07,0.33-1.6C391.32,190.67,391.23,190.76,391.23,190.76z"
              />
              <path
                d="M340.04,511.1c0.04,1.21-0.84,2.16-2.03,2.19c-1.13,0.04-2.18-0.93-2.23-2.05c-0.05-1.13,0.92-2.14,2.08-2.18
	C339.07,509.03,340,509.89,340.04,511.1z"
              />
              <path
                d="M519.65,229.8c1.16,0.04,2.13,1.06,2.08,2.18c-0.05,1.12-1.11,2.08-2.24,2.04c-1.19-0.04-2.07-0.99-2.03-2.2
	C517.5,230.62,518.44,229.76,519.65,229.8z"
              />
              <path
                d="M478.13,460.83c1.15,0.05,2.05,1.07,1.98,2.24c-0.07,1.17-1.1,2.07-2.26,1.95c-1.03-0.11-1.93-1.09-1.91-2.11
	C475.95,461.82,477.04,460.79,478.13,460.83z"
              />
              <path
                d="M426.54,494.79c-1.15,0.04-2.14-0.92-2.14-2.09c0-1.07,0.82-2.01,1.88-2.11c1.1-0.11,2.21,0.84,2.29,1.97
	C428.65,493.67,427.67,494.75,426.54,494.79z"
              />
              <path
                d="M345.14,528.85c0.04,1.11-0.96,2.16-2.08,2.21c-1.18,0.04-2.14-0.86-2.19-2.05c-0.04-1.23,0.81-2.13,2.04-2.17
	C344.09,526.79,345.1,527.7,345.14,528.85z"
              />
              <path
                d="M492.76,550.45c-1.14,0.04-2.13-0.93-2.14-2.09c0-1.07,0.83-2.01,1.88-2.11c1.1-0.11,2.21,0.85,2.28,1.97
	C494.86,549.34,493.88,550.41,492.76,550.45z"
              />
              <path
                d="M494.85,216.12c-0.04,1.12-1.07,2.08-2.21,2.06c-1.19-0.03-2.08-0.96-2.05-2.17c0.03-1.2,0.95-2.07,2.16-2.04
	C493.92,214,494.89,215,494.85,216.12z"
              />
              <path
                d="M367.58,192.96c-1.13-0.06-2.07-1.14-1.98-2.25c0.1-1.11,1.23-2.06,2.31-1.93c1.05,0.13,1.86,1.07,1.83,2.14
	C369.72,192.09,368.72,193.03,367.58,192.96z"
              />
              <path
                d="M497.24,561c-1.11-0.01-2.13-1.06-2.09-2.16c0.04-1.1,1.14-2.1,2.22-2.03c1.05,0.07,1.91,0.98,1.94,2.04
	C499.34,560.02,498.39,561.01,497.24,561z"
              />
              <path
                d="M402.38,523.87c0.01,1.16-0.97,2.14-2.11,2.11c-1.11-0.03-2.11-1.1-2.04-2.2c0.06-1,1.03-1.96,2.02-2
	C401.37,521.73,402.37,522.72,402.38,523.87z"
              />
              <path
                d="M313.54,435.41c1.14,0.05,2.05,1.07,1.98,2.23c-0.07,1.07-0.95,1.93-2.01,1.96c-1.11,0.02-2.16-1-2.15-2.11
	C311.37,436.4,312.46,435.37,313.54,435.41z"
              />
              <path
                d="M432.12,243.29c-0.06,1.17-1.08,2.07-2.23,1.97c-1.02-0.09-1.93-1.07-1.93-2.08c0-1.09,1.07-2.13,2.16-2.11
	C431.27,241.11,432.18,242.12,432.12,243.29z"
              />
              <path
                d="M428.09,485.46c0.02,1.1-1.01,2.14-2.12,2.13c-1.06-0.01-1.95-0.87-2.03-1.94c-0.08-1.16,0.81-2.2,1.95-2.26
	C426.98,483.34,428.07,484.36,428.09,485.46z"
              />
              <path
                d="M528.57,62.98c-0.15-0.13-0.3-0.26-0.44-0.39c-0.12-0.38-0.3-0.75-0.34-1.13c-0.11-1.3,0.64-2.2,1.83-2.27
	c1.08-0.06,2.05,0.69,2.21,1.72c0.18,1.16-0.58,2.14-1.86,2.27C529.51,63.22,529.03,63.05,528.57,62.98z"
              />
              <path
                d="M417.88,390.3c1.09,0.03,2.1,1.13,2.04,2.21c-0.06,1.12-1.15,2.07-2.26,1.98c-1.06-0.09-1.89-1.01-1.89-2.07
	C415.77,391.26,416.76,390.27,417.88,390.3z"
              />
              <path
                d="M423.31,494.42c1.13-0.03,2.11,0.97,2.1,2.12c-0.01,1.07-0.84,1.98-1.9,2.07c-1.11,0.09-2.19-0.86-2.25-1.98
	C421.2,495.55,422.22,494.45,423.31,494.42z"
              />
              <path
                d="M431.91,488.76c0.05,1.11-0.93,2.16-2.04,2.18c-1.04,0.02-1.97-0.82-2.08-1.88c-0.11-1.15,0.75-2.2,1.89-2.3
	C430.76,486.67,431.85,487.65,431.91,488.76z"
              />
              <path
                d="M464.52,157.18c0.06,1.11-0.92,2.16-2.02,2.18c-1.03,0.02-1.97-0.82-2.08-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C463.38,155.11,464.46,156.07,464.52,157.18z"
              />
              <path
                d="M272.11,188.38c0.06,1.11-0.91,2.16-2.03,2.18c-1.03,0.02-1.97-0.82-2.07-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C270.96,186.3,272.05,187.27,272.11,188.38z"
              />
              <path
                d="M403.67,371.94c0.04,1.11-0.94,2.14-2.07,2.18c-1.18,0.04-2.13-0.85-2.17-2.04c-0.04-1.21,0.81-2.12,2.03-2.15
	C402.63,369.9,403.63,370.81,403.67,371.94z"
              />
              <path
                d="M346.23,377.05c0,1.14-0.98,2.12-2.11,2.08c-1.02-0.03-1.95-0.94-2.01-1.96c-0.07-1.08,0.92-2.18,2-2.21
	C345.23,374.92,346.23,375.91,346.23,377.05z"
              />
              <path
                d="M406.18,490.76c0.01-1.14,1.01-2.13,2.12-2.08c1.08,0.04,2.07,1.14,2,2.22c-0.07,1.02-1,1.93-2.02,1.96
	C407.16,492.89,406.18,491.9,406.18,490.76z"
              />
              <path
                d="M338.06,450.08c-0.05,1.15-1.07,2.07-2.2,1.97c-1.02-0.09-1.91-1.04-1.92-2.05c-0.01-1.09,1.03-2.13,2.12-2.11
	C337.19,447.92,338.12,448.94,338.06,450.08z"
              />
              <path
                d="M480.64,489.65c1.09-0.03,2.14,1,2.13,2.09c0,1.1-1.04,2.11-2.14,2.07c-1.05-0.04-1.92-0.91-1.98-1.96
	C478.59,490.7,479.51,489.68,480.64,489.65z"
              />
              <path
                d="M475.46,476.06c-1.12,0.03-2.11-0.95-2.1-2.09c0-1.14,1.01-2.13,2.12-2.09c0.98,0.04,1.94,0.99,2,1.98
	C477.54,474.98,476.58,476.03,475.46,476.06z"
              />
              <path
                d="M352.94,329.57c-0.01,1.15-1,2.1-2.13,2.06c-1.04-0.04-1.93-0.93-1.98-1.98c-0.05-1.09,0.95-2.17,2.03-2.19
	C351.97,327.43,352.95,328.43,352.94,329.57z"
              />
              <path
                d="M417.78,495.43c-0.19-0.51-0.44-1-0.54-1.53c-0.26-1.35,0.51-2.41,1.78-2.55c1.16-0.13,2.23,0.68,2.39,1.8
	c0.16,1.17-0.82,2.31-2.1,2.36c-0.53,0.02-1.07-0.11-1.61-0.16C417.71,495.35,417.78,495.43,417.78,495.43z"
              />
              <path
                d="M279.39,187.83c-0.05,1.12-1.08,2.07-2.21,2.03c-1.19-0.04-2.06-0.97-2.03-2.17c0.03-1.19,0.96-2.06,2.17-2.02
	C278.47,185.71,279.44,186.71,279.39,187.83z"
              />
              <path
                d="M412.31,512.22c-0.06,1.12-1.1,2.05-2.24,2.01c-1.19-0.05-2.05-0.99-2-2.19c0.05-1.2,0.97-2.04,2.19-1.99
	C411.41,510.09,412.37,511.11,412.31,512.22z"
              />
              <path
                d="M334.88,437.67c-1.12,0.03-2.11-0.94-2.11-2.08c0-1.14,1-2.13,2.11-2.09c0.98,0.03,1.94,0.98,2,1.98
	C336.95,436.59,335.99,437.64,334.88,437.67z"
              />
              <path
                d="M486.97,525.75c-1.18,0.02-2.11-0.89-2.13-2.07c-0.02-1.21,0.85-2.1,2.07-2.12c1.17-0.02,2.16,0.91,2.17,2.05
	C489.09,524.72,488.1,525.73,486.97,525.75z"
              />
              <path
                d="M435.25,484.92c0.06,1.11-0.91,2.16-2.03,2.18c-1.03,0.02-1.97-0.82-2.07-1.87c-0.12-1.14,0.75-2.2,1.88-2.3
	C434.11,482.84,435.2,483.81,435.25,484.92z"
              />
              <path
                d="M541.79,381.88c-0.02,1.11-1.06,2.09-2.17,2.04c-1.05-0.05-1.91-0.93-1.95-1.99c-0.05-1.15,0.89-2.16,2.02-2.17
	C540.77,379.75,541.81,380.79,541.79,381.88z"
              />
              <path
                d="M439.32,116.12c-0.05,1.16-1.07,2.07-2.21,1.97c-1.02-0.09-1.92-1.05-1.93-2.06c-0.01-1.09,1.05-2.13,2.14-2.11
	C438.45,113.95,439.37,114.96,439.32,116.12z"
              />
              <path
                d="M290.15,336.35c1.17-0.03,2.17,0.88,2.2,2.02c0.03,1.1-0.97,2.14-2.08,2.17c-1.18,0.03-2.12-0.87-2.16-2.05
	C288.07,337.27,288.93,336.38,290.15,336.35z"
              />
              <path
                d="M460.91,240.75c0.01,1.14-0.96,2.13-2.09,2.11c-1.1-0.02-2.08-1.07-2.03-2.18c0.05-1,0.99-1.95,1.98-2
	C459.88,238.63,460.9,239.62,460.91,240.75z"
              />
              <path
                d="M396.17,500.73c0,0,0.09-0.1,0.09-0.1c-0.5,0.21-0.99,0.54-1.51,0.6c-1.29,0.14-2.35-0.87-2.34-2.09
	c0.01-1.13,0.99-2.06,2.15-2.06c1.2,0,2.09,0.85,2.06,2.08C396.6,499.7,396.33,500.21,396.17,500.73z"
              />
              <path
                d="M341.15,339.22c1.15,0.04,2.12,1.05,2.07,2.16c-0.05,1.12-1.09,2.06-2.22,2.02c-1.19-0.04-2.06-0.97-2.02-2.18
	C339.01,340.04,339.94,339.18,341.15,339.22z"
              />
              <path
                d="M389.94,198.24c-1.14-0.03-2.06-1.04-1.99-2.2c0.06-1.06,0.95-1.94,1.99-1.98c1.09-0.04,2.15,0.99,2.15,2.09
	C392.09,197.26,391.05,198.27,389.94,198.24z"
              />
              <path
                d="M326.74,506.43c1.09,0.05,2.06,1.15,1.98,2.24c-0.08,1.13-1.16,2.04-2.27,1.93c-1.04-0.11-1.86-1.04-1.85-2.1
	C324.62,507.36,325.64,506.38,326.74,506.43z"
              />
              <path
                d="M384.88,196.53c0.06,1.11-0.92,2.17-2.03,2.19c-1.04,0.03-1.98-0.81-2.09-1.87c-0.12-1.15,0.74-2.21,1.88-2.31
	C383.72,194.45,384.82,195.41,384.88,196.53z"
              />
              <path
                d="M476.06,479.08c1.11-0.04,2.12,0.95,2.13,2.09c0.01,1.06-0.82,1.98-1.87,2.09c-1.11,0.11-2.19-0.82-2.26-1.95
	C473.99,480.22,474.98,479.12,476.06,479.08z"
              />
              <path
                d="M250.98,387.29c-1.12-0.06-2.06-1.14-1.96-2.25c0.09-1.02,1.06-1.93,2.06-1.93c1.13-0.01,2.09,0.99,2.06,2.14
	C253.12,386.41,252.11,387.35,250.98,387.29z"
              />
              <path
                d="M346.77,530.21c1.17-0.02,2.16,0.89,2.18,2.02c0.03,1.1-0.96,2.12-2.08,2.15c-1.18,0.03-2.12-0.86-2.14-2.04
	C344.7,531.13,345.56,530.24,346.77,530.21z"
              />
              <path
                d="M332.82,345.7c-0.04,1.18-0.99,2.06-2.17,2.02c-1.13-0.04-2.1-1.07-2.06-2.17c0.04-1.14,1.03-2.03,2.21-1.99
	C332.02,343.59,332.87,344.49,332.82,345.7z"
              />
              <path
                d="M312.21,381.46c1.17-0.03,2.16,0.87,2.2,2c0.04,1.1-0.94,2.13-2.07,2.16c-1.18,0.04-2.12-0.85-2.16-2.03
	C310.14,382.39,310.99,381.49,312.21,381.46z"
              />
              <path
                d="M392.15,528.53c-0.06,1.14-1.07,2.04-2.22,2c-1.19-0.05-2.05-0.98-2-2.17c0.04-1.19,0.98-2.03,2.18-1.99
	C391.25,526.41,392.2,527.41,392.15,528.53z"
              />
              <path
                d="M431.15,481.59c0.04,1.16-0.95,2.18-2.09,2.14c-1.02-0.03-1.98-0.98-2.01-2.01c-0.04-1.12,1.01-2.18,2.12-2.14
	C430.21,479.61,431.12,480.52,431.15,481.59z"
              />
              <path
                d="M396.91,529.8c-1.12-0.03-2.09-1.13-1.99-2.25c0.09-1.04,1.08-1.94,2.1-1.91c1.15,0.04,2.07,1.09,1.98,2.25
	C398.9,528.96,397.95,529.83,396.91,529.8z"
              />
              <path
                d="M652.37,626.58c0,0,0.04,0.04,0.03,0.03c0.36,0.48,0.89,0.92,1.06,1.46c0.34,1.13-0.5,2.27-1.66,2.45
	c-1.03,0.17-2.13-0.56-2.26-1.64c-0.08-0.69,0.08-1.42,0.13-2.13c0,0,0.01,0.05,0.01,0.05
	C650.58,626.73,651.48,626.65,652.37,626.58z"
              />
              <path
                d="M534.57,382.26c0.04,1.12-0.99,2.17-2.11,2.15c-1.06-0.03-1.96-0.92-1.99-2c-0.04-1.16,0.95-2.19,2.08-2.16
	C533.56,380.27,534.54,381.25,534.57,382.26z"
              />
              <path
                d="M333.76,510.12c-1.16-0.06-2.06-1.12-1.94-2.28c0.11-1.06,1.09-1.92,2.12-1.87c1.11,0.06,2.07,1.18,1.95,2.29
	C335.78,509.31,334.79,510.17,333.76,510.12z"
              />
              <path
                d="M378.15,536.76c0.04,1.12-0.99,2.17-2.11,2.14c-1.06-0.03-1.96-0.92-1.99-2c-0.04-1.16,0.95-2.19,2.09-2.16
	C377.13,534.78,378.11,535.75,378.15,536.76z"
              />
              <path
                d="M383.21,245.73c-1.16-0.05-2.07-1.1-1.96-2.27c0.1-1.06,1.07-1.93,2.11-1.89c1.13,0.04,2.08,1.14,1.97,2.27
	C385.22,244.89,384.24,245.77,383.21,245.73z"
              />
              <path
                d="M373.57,400.02c0.04,1.16-0.95,2.18-2.09,2.14c-1.02-0.03-1.98-0.98-2.01-2.01c-0.04-1.12,1.01-2.18,2.12-2.14
	C372.63,398.04,373.54,398.95,373.57,400.02z"
              />
              <path
                d="M415.66,508.38c-0.06,1.13-1.08,2.04-2.23,1.99c-1.19-0.05-2.05-0.98-2-2.18c0.05-1.19,0.98-2.03,2.19-1.98
	C414.77,506.26,415.72,507.27,415.66,508.38z"
              />
              <path
                d="M401.13,362.74c1.15,0.04,2.11,1.04,2.05,2.16c-0.05,1.12-1.08,2.05-2.22,2.01c-1.19-0.04-2.05-0.97-2.01-2.17
	C399,363.55,399.93,362.7,401.13,362.74z"
              />
              <path
                d="M487.81,509.22c0.01,1.2-0.87,2.1-2.06,2.12c-1.14,0.01-2.14-0.95-2.16-2.06c-0.02-1.13,0.94-2.09,2.11-2.1
	C486.9,507.16,487.8,508.03,487.81,509.22z"
              />
              <path
                d="M279.06,232.85c0.14,0.14,0.29,0.28,0.43,0.41c0.11,0.61,0.35,1.24,0.29,1.84c-0.1,1.08-1.09,1.8-2.17,1.75
	c-1.11-0.06-1.92-0.87-1.97-1.97c-0.05-1.13,0.6-1.96,1.77-2.12C277.95,232.69,278.51,232.81,279.06,232.85z"
              />
              <path
                d="M343.39,512.69c-0.18-0.51-0.43-1.01-0.52-1.53c-0.26-1.35,0.51-2.4,1.79-2.54c1.16-0.12,2.23,0.69,2.37,1.81
	c0.15,1.18-0.82,2.29-2.11,2.33c-0.53,0.02-1.07-0.1-1.61-0.16C343.31,512.61,343.39,512.69,343.39,512.69z"
              />
              <path
                d="M332.12,444.54c1.03-0.04,2,0.85,2.09,1.91c0.1,1.17-0.81,2.21-1.97,2.25c-1.03,0.03-2.01-0.84-2.11-1.89
	C330.03,445.7,331.02,444.58,332.12,444.54z"
              />
              <path
                d="M249.82,372.88c-1.16-0.04-2.07-1.09-1.97-2.25c0.09-1.06,1.06-1.94,2.09-1.91c1.1,0.04,2.09,1.16,1.98,2.27
	C251.83,372.04,250.85,372.92,249.82,372.88z"
              />
              <path
                d="M413.93,492.06c-0.18-0.51-0.42-1.01-0.52-1.53c-0.26-1.35,0.51-2.4,1.79-2.54c1.16-0.12,2.23,0.69,2.37,1.81
	c0.15,1.18-0.82,2.29-2.11,2.33c-0.53,0.02-1.07-0.1-1.61-0.16C413.85,491.98,413.93,492.06,413.93,492.06z"
              />
              <path
                d="M532.07,377.21c-1.05,0.02-1.99-0.85-2.07-1.92c-0.09-1.16,0.85-2.21,1.99-2.24c1.1-0.02,2.14,1.04,2.1,2.16
	C534.05,376.24,533.1,377.19,532.07,377.21z"
              />
              <path
                d="M520.18,589.69c-0.03,1.03-0.99,1.98-2.01,2.01c-1.14,0.03-2.13-0.98-2.09-2.14c0.03-1.07,0.94-1.98,1.99-2.01
	C519.17,587.51,520.22,588.57,520.18,589.69z"
              />
              <path
                d="M487.59,514.36c0.29,0.47,0.66,0.91,0.84,1.42c0.41,1.13-0.19,2.28-1.3,2.64c-1.24,0.4-2.48-0.29-2.73-1.53
	c-0.24-1.18,0.42-2.23,1.63-2.45c0.53-0.1,1.09,0,1.64,0.01C487.67,514.44,487.59,514.36,487.59,514.36z"
              />
              <path
                d="M315.07,516.92c0,1.19-0.9,2.1-2.09,2.1c-1.13,0-2.13-0.97-2.14-2.09c-0.01-1.14,0.96-2.08,2.13-2.08
	C314.18,514.85,315.07,515.72,315.07,516.92z"
              />
              <path
                d="M324.14,62.91c-1.12,0.07-2.19-0.94-2.19-2.07c0-1.02,0.92-2.01,1.95-2.07c1.03-0.07,2.02,0.78,2.15,1.84
	C326.19,61.76,325.3,62.83,324.14,62.91z"
              />
              <path
                d="M282.73,183.92c0,1.14-1.02,2.1-2.18,2.07c-1.21-0.03-2.09-0.98-2.02-2.19c0.06-1.12,0.89-1.91,2.03-1.94
	C281.74,181.83,282.73,182.76,282.73,183.92z"
              />
              <path
                d="M354.68,334.96c-1.02-0.03-1.96-1.01-1.96-2.05c0-1.04,0.94-2.02,1.96-2.05c1.14-0.03,2.14,1.02,2.07,2.18
	C356.68,334.09,355.72,334.99,354.68,334.96z"
              />
              <path
                d="M370.2,403.87c0.03,1.15-0.97,2.16-2.09,2.12c-1-0.03-1.95-1-1.98-2.01c-0.03-1.12,1-2.16,2.1-2.12
	C369.28,401.9,370.17,402.82,370.2,403.87z"
              />
              <path
                d="M371.68,212.18c0.02,1.21-0.89,2.13-2.1,2.12c-1.16-0.01-2.15-1.02-2.1-2.15c0.04-1.06,0.93-1.94,2.02-1.99
	C370.72,210.11,371.66,210.98,371.68,212.18z"
              />
              <path
                d="M489.25,532.74c-0.62,0.04-1.26,0.17-1.87,0.11c-1.21-0.11-2.01-1.2-1.82-2.33c0.18-1.08,1.1-1.8,2.22-1.73
	c1.22,0.07,2.06,0.99,1.92,2.24c-0.07,0.61-0.35,1.2-0.53,1.79C489.17,532.83,489.25,532.74,489.25,532.74z"
              />
              <path
                d="M357.07,237.08c-1.11-0.04-2.06-1.12-1.96-2.24c0.1-1.04,1.07-1.92,2.09-1.88c1.13,0.04,2.06,1.1,1.96,2.24
	C359.06,236.25,358.1,237.12,357.07,237.08z"
              />
              <path
                d="M525.41,235.04c-0.01,1.04-0.96,2.01-1.98,2.02c-1.14,0.02-2.13-1.04-2.04-2.19c0.08-1.06,1.05-1.95,2.09-1.91
	C524.51,233.01,525.42,234,525.41,235.04z"
              />
              <path
                d="M365.82,211.15c-1.05,0.03-2-0.86-2.06-1.93c-0.06-1.07,0.78-2.05,1.84-2.15c1.13-0.1,2.19,0.89,2.19,2.05
	C367.79,210.18,366.88,211.12,365.82,211.15z"
              />
              <path
                d="M506.3,411.45c1.02-0.03,1.99,0.85,2.08,1.89c0.1,1.15-0.81,2.2-1.96,2.24c-1.02,0.04-1.99-0.83-2.09-1.88
	C504.24,412.58,505.2,411.48,506.3,411.45z"
              />
              <path
                d="M481.09,496.89c1.04-0.05,2.01,0.84,2.08,1.91c0.07,1.07-0.75,2.05-1.81,2.17c-1.13,0.12-2.2-0.86-2.21-2.01
	C479.14,497.89,480.03,496.94,481.09,496.89z"
              />
              <path
                d="M506.47,225.93c-0.01,1.06-0.93,2-1.98,2.02c-1.15,0.02-2.12-1.02-2.04-2.19c0.07-1.07,1.05-1.95,2.09-1.9
	C505.59,223.91,506.48,224.87,506.47,225.93z"
              />
              <path
                d="M434.49,477.75c0.04,1.14-0.96,2.15-2.08,2.12c-1-0.03-1.94-0.98-1.97-1.99c-0.03-1.13,0.97-2.15,2.08-2.12
	C433.56,475.8,434.46,476.71,434.49,477.75z"
              />
              <path
                d="M413.39,400.06c0.02,1.15-1,2.15-2.13,2.1c-1.01-0.05-1.95-1.02-1.97-2.04c-0.02-1.11,1.05-2.15,2.15-2.1
	C412.49,398.07,413.38,399,413.39,400.06z"
              />
              <path
                d="M387.35,501.7c-1.17,0-2.14-0.97-2.1-2.11c0.04-1.16,1.03-2.04,2.22-1.98c1.12,0.06,1.92,0.87,1.95,1.98
	C389.46,500.79,388.56,501.7,387.35,501.7z"
              />
              <path
                d="M414.29,206.39c-0.15-0.6-0.4-1.2-0.43-1.8c-0.06-1.21,0.85-2.08,2.05-2.08c1.08,0,1.99,0.79,2.1,1.84
	c0.12,1.12-0.73,2.16-1.93,2.22c-0.61,0.03-1.24-0.15-1.86-0.23C414.22,206.33,414.29,206.39,414.29,206.39z"
              />
              <path
                d="M378.39,204.45c0.05,1.2-0.82,2.13-2.04,2.15c-1.17,0.02-2.15-0.93-2.14-2.08c0.01-1.06,0.86-1.94,1.95-2.02
	C377.38,202.43,378.34,203.26,378.39,204.45z"
              />
              <path
                d="M319.17,21.67c0.06,1.04-0.78,1.99-1.85,2.11c-1.21,0.13-2.22-0.7-2.28-1.88c-0.06-1.1,0.71-1.99,1.83-2.1
	C318.07,19.67,319.1,20.5,319.17,21.67z"
              />
              <path
                d="M367.13,181.62c1.02-0.04,1.99,0.82,2.09,1.86c0.12,1.14-0.79,2.19-1.93,2.25c-1.02,0.05-1.99-0.8-2.09-1.85
	C365.09,182.75,366.01,181.67,367.13,181.62z"
              />
              <path
                d="M392.61,76.91c0,1.13-1.01,2.1-2.18,2.08c-1.21-0.03-2.09-0.98-2.03-2.19c0.06-1.12,0.88-1.91,2.02-1.94
	C391.62,74.82,392.61,75.74,392.61,76.91z"
              />
              <path
                d="M233.43,200.68c-0.14-0.11-0.28-0.22-0.43-0.33c0-1.02,0-2.05,0.01-3.07c0,0-0.08,0.1-0.08,0.1
	c0.43-0.19,0.85-0.46,1.31-0.55c1.39-0.27,2.47,0.5,2.59,1.76c0.11,1.18-0.67,2.09-1.97,2.21
	C234.39,200.85,233.91,200.73,233.43,200.68z"
              />
              <path
                d="M236.74,243.81c0.17,0.44,0.42,0.87,0.5,1.33c0.21,1.34-0.66,2.45-1.91,2.5c-1.03,0.04-2.01-0.84-2.1-1.89
	c-0.11-1.24,0.91-2.29,2.23-2.22c0.46,0.02,0.92,0.24,1.37,0.37C236.82,243.89,236.74,243.81,236.74,243.81z"
              />
              <path
                d="M264.17,185.07c0.03-1.14,1.08-2.1,2.19-2c1.02,0.09,1.89,1.06,1.86,2.09c-0.02,1.01-0.96,1.97-1.96,2.01
	C265.15,187.21,264.15,186.21,264.17,185.07z"
              />
              <path
                d="M372.81,392.79c0.05,1.06-0.81,2.05-1.85,2.13c-1.13,0.1-2.19-0.91-2.18-2.06c0.01-1.06,0.94-2,1.98-2.02
	C371.8,390.82,372.76,391.73,372.81,392.79z"
              />
              <path
                d="M451.98,472.43c0.05,1.07-0.79,2.05-1.84,2.14c-1.13,0.1-2.19-0.9-2.19-2.05c0.01-1.05,0.93-2,1.97-2.03
	C450.97,470.46,451.93,471.37,451.98,472.43z"
              />
              <path
                d="M255.59,358c-1.15-0.03-2.07-1.08-1.98-2.23c0.09-1.05,1.05-1.94,2.08-1.91c1.1,0.03,2.08,1.14,1.99,2.24
	C257.59,357.15,256.62,358.03,255.59,358z"
              />
              <path
                d="M340.45,391.9c0.02,1.14-1,2.14-2.12,2.09c-1.01-0.05-1.94-1.02-1.95-2.03c-0.02-1.12,1.03-2.14,2.13-2.09
	C339.55,389.92,340.44,390.85,340.45,391.9z"
              />
              <path
                d="M329.74,436.19c0.07,1.13-0.89,2.15-2.05,2.19c-1.1,0.03-2.01-0.75-2.13-1.83c-0.13-1.2,0.69-2.19,1.89-2.28
	C328.65,434.17,329.67,435.03,329.74,436.19z"
              />
              <path
                d="M253.83,372.1c1.11,0.07,2.05,1.19,1.92,2.3c-0.12,1.05-1.12,1.9-2.14,1.83c-1.15-0.07-2.04-1.14-1.91-2.29
	C251.82,372.89,252.81,372.04,253.83,372.1z"
              />
              <path
                d="M265.9,393.04c-1.11-0.04-2.08-1.13-1.97-2.25c0.1-1.04,1.08-1.93,2.1-1.89c1.14,0.04,2.06,1.1,1.96,2.25
	C267.9,392.21,266.94,393.07,265.9,393.04z"
              />
              <path
                d="M683.01,595.13c0.3-0.04,0.64-0.01,0.9-0.13c0.87-0.4,1.57-0.17,2.07,0.58c0.49,0.76,0.75,1.61,0.22,2.46
	c-0.55,0.88-1.36,0.98-2.29,0.64c-0.29-0.11-0.6-0.17-0.9-0.26c0,0,0.1,0.08,0.1,0.08c0-1.15,0-2.3,0-3.44
	C683.1,595.07,683.01,595.13,683.01,595.13z"
              />
              <path
                d="M373.88,193.99c0,1.15-0.99,2.08-2.16,2.05c-1.21-0.03-2.08-0.97-2.01-2.17c0.06-1.11,0.89-1.89,2.02-1.92
	C372.91,191.92,373.88,192.83,373.88,193.99z"
              />
              <path
                d="M317.42,442.93c-1.02,0.06-2.02-0.79-2.14-1.83c-0.13-1.11,0.81-2.23,1.92-2.3c1.03-0.06,2.01,0.79,2.13,1.84
	C319.46,441.79,318.57,442.86,317.42,442.93z"
              />
              <path
                d="M423.24,388.52c0.03,1.12-1.02,2.16-2.13,2.11c-1.05-0.04-1.94-0.95-1.97-2.01c-0.03-1.15,0.98-2.17,2.1-2.13
	C422.25,386.53,423.22,387.51,423.24,388.52z"
              />
              <path
                d="M337.56,442.89c-0.05,1.06-1.01,1.97-2.05,1.95c-1.15-0.02-2.08-1.09-1.96-2.26c0.11-1.07,1.11-1.92,2.15-1.82
	C336.76,440.85,337.61,441.82,337.56,442.89z"
              />
              <path
                d="M357.28,338c-0.18-0.59-0.44-1.18-0.52-1.79c-0.14-1.23,0.71-2.17,1.92-2.24c1.11-0.06,2.04,0.66,2.21,1.73
	c0.19,1.14-0.61,2.22-1.83,2.33c-0.62,0.06-1.25-0.07-1.87-0.12C357.2,337.92,357.28,338,357.28,338z"
              />
              <path
                d="M368.12,335.61c-0.04,1.15-1.05,2.04-2.23,1.98c-1.2-0.07-2.05-1.04-1.94-2.23c0.1-1.08,0.97-1.87,2.08-1.86
	C367.21,333.5,368.16,334.46,368.12,335.61z"
              />
              <path
                d="M341.4,446.08c0.04,1.14-0.95,2.15-2.07,2.12c-1-0.03-1.94-0.98-1.97-1.99c-0.04-1.13,0.97-2.15,2.08-2.12
	C340.47,444.13,341.37,445.04,341.4,446.08z"
              />
              <path
                d="M433.63,494.26c-1.05-0.02-1.97-0.95-1.99-2.01c-0.02-1.16,1.03-2.16,2.17-2.07c1.05,0.08,1.91,1.07,1.86,2.13
	C435.62,493.36,434.67,494.28,433.63,494.26z"
              />
              <path
                d="M278.7,387.7c-0.47,0.28-0.91,0.62-1.41,0.82c-1.14,0.47-2.26-0.06-2.67-1.19c-0.4-1.1,0.18-2.27,1.31-2.64
	c1.08-0.36,2.23,0.27,2.55,1.46c0.14,0.52,0.1,1.08,0.14,1.63C278.62,387.77,278.7,387.7,278.7,387.7z"
              />
              <path
                d="M317.87,450.13c-1.03,0.05-2.01-0.82-2.12-1.86c-0.12-1.11,0.84-2.23,1.95-2.28c1.03-0.05,2,0.82,2.11,1.87
	C319.93,449.02,319.02,450.08,317.87,450.13z"
              />
              <path
                d="M486.1,447.97c0.05,1.12-0.96,2.18-2.07,2.16c-1.05-0.01-1.96-0.91-2.01-1.97c-0.05-1.15,0.92-2.19,2.05-2.17
	C485.07,446,486.05,446.96,486.1,447.97z"
              />
              <path
                d="M472.92,171.07c-0.06,1.2-1.03,2.04-2.25,1.94c-1.07-0.08-1.94-0.99-1.94-2.04c-0.01-1.15,0.97-2.09,2.16-2.06
	C472.12,168.93,472.98,169.86,472.92,171.07z"
              />
              <path
                d="M397.13,359.63c1.01-0.04,2,0.85,2.1,1.89c0.1,1.12-0.86,2.21-1.97,2.25c-1.04,0.04-1.99-0.83-2.09-1.89
	C395.07,360.73,395.99,359.67,397.13,359.63z"
              />
              <path
                d="M394.27,82.1c-1.15-0.03-2.07-1.08-1.98-2.23c0.09-1.05,1.05-1.94,2.08-1.91c1.1,0.03,2.08,1.14,1.99,2.24
	C396.26,81.25,395.3,82.12,394.27,82.1z"
              />
              <path
                d="M416.57,502.39c1.21-0.03,2.13,0.87,2.13,2.07c0,1.11-0.8,1.96-1.91,2.04c-1.18,0.09-2.21-0.79-2.28-1.94
	C414.45,503.41,415.39,502.42,416.57,502.39z"
              />
              <path
                d="M345.25,449.44c0.04,1.15-0.96,2.17-2.09,2.14c-1.01-0.03-1.96-0.99-2-2.01c-0.04-1.12,1-2.17,2.1-2.13
	C344.32,447.47,345.22,448.38,345.25,449.44z"
              />
              <path
                d="M371.96,505.04c-0.07,1.15-1.09,2.02-2.27,1.93c-1.19-0.09-2.02-1.09-1.89-2.28c0.12-1.08,1.02-1.84,2.12-1.82
	C371.08,502.9,372.02,503.9,371.96,505.04z"
              />
              <path
                d="M370.23,181.88c-1.06-0.06-1.94-1.02-1.92-2.07c0.02-1.15,1.11-2.12,2.23-1.99c1.06,0.12,1.87,1.11,1.79,2.18
	C372.24,181.06,371.27,181.94,370.23,181.88z"
              />
              <path
                d="M323.97,451.23c-0.03,1.15-1.03,2.06-2.21,2c-1.21-0.06-2.06-1.02-1.97-2.21c0.08-1.09,0.94-1.87,2.06-1.88
	C323.05,449.13,324,450.08,323.97,451.23z"
              />
              <path
                d="M371.16,331.54c-0.04,1.05-0.94,1.96-1.98,1.99c-1.1,0.03-2.12-1.01-2.09-2.13c0.04-1.01,1-1.97,1.99-2
	C370.21,329.37,371.2,330.4,371.16,331.54z"
              />
              <path
                d="M422.68,487.51c1.18,0.02,2.14,1.03,2.08,2.17c-0.06,1.15-1.11,2.04-2.28,1.96c-1.11-0.08-1.91-0.93-1.92-2.05
	C420.55,488.38,421.46,487.48,422.68,487.51z"
              />
              <path
                d="M316.71,451.97c-0.03,1.02-0.98,1.98-2,2.01c-1.13,0.03-2.12-0.98-2.09-2.14c0.03-1.06,0.94-1.97,1.98-2.01
	C315.71,449.8,316.75,450.85,316.71,451.97z"
              />
              <path
                d="M482.71,451.97c-0.03,1.02-0.97,1.96-1.97,1.99c-1.12,0.03-2.11-0.99-2.07-2.12c0.03-1.05,0.94-1.95,1.97-1.99
	C481.75,449.82,482.75,450.84,482.71,451.97z"
              />
              <path
                d="M340.16,451.29c1.02,0.04,1.95,1.02,1.95,2.06c0,1.04-0.95,2.01-1.97,2.04c-1.14,0.03-2.14-1.02-2.06-2.18
	C338.15,452.16,339.13,451.26,340.16,451.29z"
              />
              <path
                d="M334.95,453.86c-0.02,1.02-0.95,1.99-1.97,2.04c-1.13,0.05-2.14-0.95-2.12-2.1c0.02-1.06,0.9-1.99,1.95-2.04
	C333.91,451.7,334.97,452.74,334.95,453.86z"
              />
              <path
                d="M474.98,468.85c-1.04,0.03-1.99-0.84-2.07-1.9c-0.09-1.15,0.84-2.21,1.98-2.24c1.1-0.03,2.14,1.03,2.1,2.14
	C476.94,467.87,475.99,468.83,474.98,468.85z"
              />
              <path
                d="M523.78,385.03c-0.18-0.59-0.44-1.18-0.51-1.79c-0.14-1.23,0.71-2.17,1.92-2.24c1.11-0.06,2.04,0.66,2.21,1.74
	c0.19,1.14-0.61,2.22-1.83,2.33c-0.62,0.06-1.25-0.07-1.88-0.12C523.7,384.95,523.78,385.03,523.78,385.03z"
              />
              <path
                d="M320.53,455.22c0.03,1.12-0.99,2.14-2.1,2.1c-1.04-0.04-1.93-0.96-1.95-2c-0.03-1.14,0.98-2.15,2.09-2.11
	C319.56,453.24,320.51,454.21,320.53,455.22z"
              />
              <path
                d="M477.52,457.79c-1.02-0.03-1.96-1.02-1.96-2.05c0-1.04,0.94-2.02,1.96-2.05c1.14-0.03,2.14,1.02,2.07,2.18
	C479.52,456.93,478.56,457.83,477.52,457.79z"
              />
              <path
                d="M356.75,225.78c1.06,0.06,1.94,1.01,1.93,2.07c-0.02,1.16-1.1,2.13-2.23,2c-1.06-0.12-1.87-1.1-1.79-2.18
	C354.73,226.61,355.71,225.72,356.75,225.78z"
              />
              <path
                d="M455.46,136.41c0,1.14-1,2.09-2.17,2.06c-1.22-0.03-2.09-0.97-2.02-2.18c0.06-1.12,0.88-1.91,2.02-1.94
	C454.48,134.33,455.46,135.25,455.46,136.41z"
              />
              <path
                d="M373.45,383.9c-1.02,0-1.96-0.9-2.02-1.93c-0.07-1.12,0.91-2.18,2.01-2.18c1.03,0,1.96,0.89,2.02,1.94
	C375.54,382.87,374.58,383.9,373.45,383.9z"
              />
              <path
                d="M227.65,241.12c-1.15,0.02-2.14-0.97-2.1-2.11c0.04-1.06,0.92-1.9,2.03-1.92c1.14-0.02,1.98,0.73,2.06,1.85
	C229.72,240.14,228.85,241.1,227.65,241.12z"
              />
              <path
                d="M305.31,508.39c1.04,0,1.99,0.92,2.03,1.98c0.04,1.16-0.98,2.18-2.11,2.12c-1.03-0.06-1.93-1.05-1.91-2.09
	C303.33,509.35,304.28,508.39,305.31,508.39z"
              />
              <path
                d="M254.27,383.43c-1.02,0.04-1.98-0.83-2.08-1.87c-0.11-1.13,0.81-2.19,1.94-2.24c1.02-0.04,1.98,0.82,2.08,1.87
	C256.32,382.31,255.39,383.39,254.27,383.43z"
              />
              <path
                d="M421.61,105.11c-1.05-0.04-1.96-0.98-1.96-2.04c0-1.16,1.06-2.15,2.19-2.04c1.06,0.1,1.89,1.07,1.83,2.15
	C423.61,104.25,422.66,105.14,421.61,105.11z"
              />
              <path
                d="M332,528.35c-1.04,0.04-1.99-0.83-2.09-1.89c-0.1-1.15,0.82-2.21,1.96-2.25c1.01-0.04,2,0.85,2.1,1.89
	C334.08,527.22,333.11,528.31,332,528.35z"
              />
              <path
                d="M405.75,187.8c-0.02,1.2-0.98,2.09-2.18,2.03c-1.07-0.05-1.98-0.94-2.02-2c-0.05-1.15,0.91-2.13,2.1-2.14
	C404.87,185.68,405.77,186.58,405.75,187.8z"
              />
              <path
                d="M341.35,328.88c-0.62,0.02-1.26,0.12-1.87,0.04c-1.08-0.14-1.85-1.17-1.75-2.19c0.11-1.15,1.16-1.99,2.35-1.87
	c1.11,0.11,1.94,0.96,1.83,2.08c-0.07,0.69-0.41,1.35-0.62,2.02C341.28,328.96,341.35,328.88,341.35,328.88z"
              />
              <path
                d="M476.23,459.81c-0.12,1.04-1.1,1.89-2.11,1.83c-1.12-0.06-2.02-1.15-1.9-2.27c0.12-1.04,1.1-1.89,2.11-1.83
	C475.46,457.6,476.35,458.68,476.23,459.81z"
              />
              <path
                d="M616.34,622.4c0.16,0.18,0.33,0.36,0.49,0.53c0.31,1.08,0.7,2.28-0.47,3.01c-0.58,0.36-1.54,0.48-2.19,0.27
	c-1.31-0.43-1.27-1.65-1.14-2.8c0,0-0.07,0.07-0.07,0.07C613.83,622.29,615.06,622.27,616.34,622.4z"
              />
              <path
                d="M397.22,381c-0.48,0.27-0.93,0.58-1.43,0.79c-1.05,0.42-2.14-0.04-2.58-1.03c-0.48-1.1,0.04-2.31,1.18-2.72
	c0.99-0.36,2.17,0.14,2.52,1.18c0.19,0.58,0.16,1.24,0.23,1.86C397.15,381.07,397.22,381,397.22,381z"
              />
              <path
                d="M633.28,624.5c1.01,0,1.97,0.92,2.03,1.95c0.06,1.12-0.93,2.18-2.04,2.18c-1.04,0-1.96-0.89-2.02-1.95
	C631.19,625.53,632.15,624.5,633.28,624.5z"
              />
              <path
                d="M497.32,228.42c-1.05,0.04-2-0.85-2.07-1.92c-0.07-1.08,0.76-2.05,1.82-2.16c1.13-0.11,2.2,0.88,2.2,2.03
	C499.27,227.44,498.37,228.38,497.32,228.42z"
              />
              <path
                d="M362.51,64.99c-0.04,1.09-0.91,1.91-2.03,1.91c-1.21,0-2.1-0.97-1.99-2.17c0.1-1.1,1-1.86,2.13-1.79
	C361.72,63,362.55,63.9,362.51,64.99z"
              />
              <path
                d="M447.28,485.62c-1.02-0.04-1.94-1.02-1.94-2.05c0.01-1.04,0.94-2.01,1.96-2.03c1.13-0.02,2.13,1.03,2.05,2.18
	C449.28,484.76,448.31,485.66,447.28,485.62z"
              />
              <path
                d="M530.87,304.7c0.24,0.49,0.55,0.97,0.7,1.49c0.34,1.22-0.33,2.31-1.5,2.53c-1.11,0.21-2.14-0.42-2.41-1.46
	c-0.29-1.14,0.41-2.26,1.64-2.47c0.54-0.09,1.11-0.01,1.66-0.01C530.95,304.78,530.87,304.7,530.87,304.7z"
              />
              <path
                d="M337.78,529.38c0.01,1.12-0.8,1.97-1.92,2c-1.11,0.04-2.02-0.77-2.09-1.85c-0.08-1.16,0.87-2.15,2.05-2.13
	C336.93,527.43,337.77,528.27,337.78,529.38z"
              />
              <path
                d="M388.73,532.16c0.07,1.14-0.86,2.13-2.03,2.15c-1.09,0.03-1.99-0.74-2.11-1.81c-0.13-1.18,0.7-2.17,1.88-2.25
	C387.66,530.16,388.66,531.01,388.73,532.16z"
              />
              <path
                d="M448.14,469.53c-0.06,1.11-0.92,1.89-2.06,1.86c-1.12-0.03-1.96-0.87-1.96-1.97c0-1.19,0.98-2.08,2.19-1.98
	C447.42,467.53,448.2,468.43,448.14,469.53z"
              />
              <path
                d="M528.06,350.41c0.01,1.18-0.93,2.06-2.14,2.01c-1.1-0.05-1.91-0.89-1.92-1.98c-0.01-1.09,0.8-1.94,1.9-2
	C527.11,348.36,528.06,349.23,528.06,350.41z"
              />
              <path
                d="M281.54,406.2c-1.2-0.01-2.08-0.93-2.03-2.12c0.04-1.1,0.85-1.9,1.97-1.94c1.19-0.05,2.16,0.84,2.18,1.99
	C283.66,405.28,282.71,406.21,281.54,406.2z"
              />
              <path
                d="M264.36,347.92c0.06,1.12-0.72,2-1.84,2.08c-1.22,0.08-2.19-0.8-2.18-1.99c0.01-1.1,0.86-1.94,1.97-1.97
	C263.44,346.01,264.31,346.81,264.36,347.92z"
              />
              <path
                d="M423.92,501.7c1.05,0.06,1.94,1.01,1.92,2.06c-0.02,1.14-1.1,2.12-2.22,1.99c-1.06-0.12-1.86-1.09-1.78-2.17
	C421.92,502.51,422.88,501.64,423.92,501.7z"
              />
              <path
                d="M527.86,370.01c1.2-0.05,2.14,0.85,2.13,2.03c-0.01,1.1-0.82,1.93-1.94,1.97c-1.21,0.05-2.15-0.84-2.13-2.02
	C525.93,370.9,526.77,370.05,527.86,370.01z"
              />
              <path
                d="M442.18,484.33c-0.02,1.19-0.97,2.04-2.19,1.96c-1.1-0.07-1.91-0.94-1.89-2.03c0.02-1.18,0.99-2.04,2.19-1.96
	C441.4,482.37,442.2,483.24,442.18,484.33z"
              />
              <path
                d="M448.89,476.53c0.05,1.08-0.74,1.97-1.83,2.07c-1.21,0.11-2.18-0.72-2.23-1.9c-0.04-1.09,0.74-1.97,1.83-2.07
	C447.86,474.52,448.84,475.36,448.89,476.53z"
              />
              <path
                d="M530.7,379.17c0.02,1.1-0.77,1.95-1.87,2.02c-1.21,0.08-2.16-0.77-2.18-1.95c-0.02-1.09,0.78-1.95,1.87-2.03
	C529.73,377.13,530.68,377.99,530.7,379.17z"
              />
              <path
                d="M318.38,346.94c0.06,1.09-0.71,1.99-1.8,2.1c-1.21,0.13-2.21-0.7-2.27-1.87c-0.06-1.08,0.73-1.99,1.81-2.1
	C317.31,344.95,318.31,345.77,318.38,346.94z"
              />
              <path
                d="M472.88,463.54c0.06,1.08-0.71,1.98-1.8,2.1c-1.2,0.13-2.19-0.69-2.26-1.87c-0.06-1.08,0.71-1.99,1.8-2.1
	C471.81,461.55,472.81,462.38,472.88,463.54z"
              />
              <path
                d="M415.33,89.34c0.02,1.19-0.88,2.09-2.08,2.08c-1.17-0.01-2.11-0.97-2.07-2.11c0.04-1.06,0.9-1.9,1.98-1.96
	C414.37,87.3,415.3,88.15,415.33,89.34z"
              />
              <path
                d="M527.55,327c-1.21,0.04-2.16-0.88-2.14-2.06c0.02-1.08,0.88-1.94,1.98-1.98c1.21-0.04,2.15,0.87,2.13,2.06
	C529.5,326.14,528.68,326.96,527.55,327z"
              />
              <path
                d="M377.3,408.35c0.25,0.49,0.57,0.95,0.73,1.46c0.36,1.17-0.29,2.28-1.43,2.55c-1.18,0.28-2.29-0.4-2.53-1.53
	c-0.24-1.15,0.46-2.22,1.67-2.41c0.53-0.09,1.1,0.01,1.64,0.02C377.39,408.43,377.3,408.35,377.3,408.35z"
              />
              <path
                d="M383.42,494.5c1.03-0.06,2.02,0.81,2.11,1.87c0.1,1.14-0.87,2.21-2.01,2.21c-1.03,0-1.97-0.94-1.99-1.99
	C381.5,495.52,382.38,494.55,383.42,494.5z"
              />
              <path
                d="M527.85,596c0.05,1.09-0.74,1.99-1.84,2.09c-1.22,0.11-2.2-0.72-2.26-1.9c-0.05-1.09,0.74-1.99,1.83-2.1
	C526.79,593.98,527.79,594.82,527.85,596z"
              />
              <path
                d="M506.87,570.54c0.24,0.49,0.56,0.95,0.71,1.46c0.35,1.18-0.3,2.28-1.43,2.54c-1.18,0.27-2.28-0.4-2.52-1.54
	c-0.24-1.15,0.47-2.21,1.68-2.4c0.53-0.08,1.09,0.01,1.64,0.02C506.96,570.62,506.87,570.54,506.87,570.54z"
              />
              <path
                d="M258.24,119.82c0.5-0.38,0.94-0.87,1.5-1.11c1.03-0.44,2.13,0.08,2.55,1.06c0.42,0.99-0.01,2.18-0.93,2.61
	c-1.05,0.49-2.3,0.06-2.76-1.03c-0.2-0.49-0.2-1.07-0.29-1.6C258.3,119.76,258.24,119.82,258.24,119.82z"
              />
              <path
                d="M373.05,206.35c1.2,0.05,2.06,1,1.98,2.19c-0.08,1.09-0.93,1.88-2.04,1.89c-1.18,0.01-2.14-0.93-2.12-2.08
	C370.89,207.2,371.86,206.31,373.05,206.35z"
              />
              <path
                d="M529.5,364.76c0.03,1.11-0.78,1.98-1.91,2.04c-1.14,0.06-2.03-0.7-2.13-1.81c-0.1-1.2,0.81-2.17,2.02-2.17
	C528.6,362.83,529.47,363.66,529.5,364.76z"
              />
              <path
                d="M475.3,183.55c-1.2-0.06-2.05-1.03-1.95-2.22c0.1-1.18,1.09-1.97,2.3-1.84c1.08,0.11,1.89,1.02,1.85,2.08
	C477.47,182.73,476.48,183.62,475.3,183.55z"
              />
              <path
                d="M397.36,173.87c-0.02,1.18-1.01,2.07-2.22,1.99c-1.12-0.07-1.92-0.93-1.91-2.05c0.02-1.21,0.97-2.07,2.2-1.99
	C396.54,171.89,397.37,172.78,397.36,173.87z"
              />
              <path
                d="M261.07,351.89c-0.02,1.18-1,2.06-2.21,1.98c-1.12-0.07-1.92-0.93-1.9-2.04c0.02-1.2,0.97-2.06,2.2-1.98
	C260.26,349.92,261.09,350.8,261.07,351.89z"
              />
              <path
                d="M429.34,107.52c1.2-0.01,2.14,0.95,2.08,2.12c-0.06,1.08-0.94,1.91-2.05,1.92c-1.21,0.01-2.12-0.93-2.06-2.12
	C427.36,108.34,428.21,107.53,429.34,107.52z"
              />
              <path
                d="M338.01,347.17c-1.18,0.04-2.15-0.86-2.16-2.02c-0.01-1.15,0.94-2.07,2.13-2.05c1.11,0.02,1.95,0.8,2.02,1.9
	C340.07,346.19,339.21,347.13,338.01,347.17z"
              />
              <path
                d="M406.18,361.07c-0.05,1.09-0.94,1.91-2.06,1.89c-1.21-0.02-2.09-1-1.97-2.2c0.11-1.1,1.03-1.85,2.16-1.77
	C405.42,359.07,406.23,359.98,406.18,361.07z"
              />
              <path
                d="M425.85,377.23c0.03,1.1-0.78,1.97-1.9,2.03c-1.15,0.06-2.02-0.68-2.11-1.8c-0.1-1.2,0.8-2.16,2-2.16
	C424.94,375.31,425.81,376.14,425.85,377.23z"
              />
              <path
                d="M327.03,447.37c-0.01,1.2-0.98,2.07-2.2,2c-1.1-0.07-1.94-0.96-1.93-2.05c0.01-1.18,1.01-2.07,2.22-1.99
	C326.24,445.4,327.04,446.26,327.03,447.37z"
              />
              <path
                d="M342.19,60.99c1.19-0.04,2.13,0.88,2.1,2.05c-0.03,1.09-0.85,1.91-1.96,1.94c-1.21,0.04-2.12-0.85-2.09-2.04
	C340.26,61.86,341.1,61.03,342.19,60.99z"
              />
              <path
                d="M443.61,478.45c1.11,0.05,1.92,0.89,1.93,1.99c0.01,1.19-0.93,2.07-2.14,2.01c-1.1-0.05-1.93-0.9-1.93-1.99
	C441.45,479.29,442.41,478.4,443.61,478.45z"
              />
              <path
                d="M418.91,484.18c1.17,0.06,2.07,1.07,1.97,2.2c-0.1,1.05-1,1.86-2.08,1.86c-1.2,0-2.1-0.91-2.06-2.09
	C416.77,484.97,417.71,484.12,418.91,484.18z"
              />
              <path
                d="M392.75,495.89c-0.05,1.17-1.02,2.01-2.23,1.9c-1.09-0.09-1.87-0.97-1.83-2.06c0.05-1.17,1.03-2.02,2.23-1.91
	C392,493.92,392.79,494.81,392.75,495.89z"
              />
              <path
                d="M411.53,488.68c-1.11-0.04-1.93-0.85-1.96-1.94c-0.03-1.17,0.91-2.09,2.1-2.05c1.09,0.03,1.93,0.87,1.96,1.95
	C413.65,487.83,412.74,488.72,411.53,488.68z"
              />
              <path
                d="M487.48,441.99c1.09,0.04,1.93,0.87,1.95,1.96c0.03,1.19-0.89,2.08-2.1,2.03c-1.11-0.04-1.93-0.86-1.96-1.95
	C485.36,442.86,486.29,441.95,487.48,441.99z"
              />
              <path
                d="M321.18,441.98c1.11,0.02,1.95,0.83,1.99,1.92c0.05,1.18-0.87,2.1-2.08,2.08c-1.1-0.02-1.96-0.84-2-1.93
	C319.06,442.89,319.98,441.96,321.18,441.98z"
              />
              <path
                d="M408.14,366.36c-1.13-0.05-1.95-0.88-1.96-2c-0.01-1.2,0.93-2.1,2.15-2.05c1.1,0.05,1.96,0.92,1.98,2
	C410.33,365.49,409.35,366.41,408.14,366.36z"
              />
              <path
                d="M429.2,500.01c-0.06,1.05-1.03,1.95-2.06,1.92c-1.15-0.03-2.05-1.1-1.92-2.27c0.12-1.06,1.12-1.9,2.15-1.79
	C428.42,497.97,429.26,498.96,429.2,500.01z"
              />
              <path
                d="M284.5,189.31c-1.21,0.03-2.15-0.9-2.11-2.09c0.03-1.12,0.87-1.93,2-1.96c1.21-0.03,2.17,0.91,2.13,2.09
	C286.48,188.42,285.6,189.28,284.5,189.31z"
              />
              <path
                d="M449.62,135.04c-1.12,0.06-2.04-0.72-2.14-1.81c-0.1-1.17,0.83-2.17,2.02-2.17c1.12,0,1.98,0.83,2.01,1.93
	C451.55,134.12,450.75,134.98,449.62,135.04z"
              />
              <path
                d="M422.78,175.25c0.01,1.09-0.82,1.97-1.92,2.04c-1.22,0.07-2.18-0.8-2.19-1.99c-0.01-1.11,0.8-1.96,1.91-2.03
	C421.78,173.19,422.76,174.08,422.78,175.25z"
              />
              <path
                d="M330.37,443.42c0.05,1.08-0.74,1.97-1.83,2.07c-1.21,0.11-2.18-0.72-2.23-1.9c-0.04-1.09,0.74-1.97,1.83-2.07
	C329.34,441.41,330.32,442.25,330.37,443.42z"
              />
              <path
                d="M397.79,200.63c1.1,0.05,1.91,0.9,1.92,1.99c0,1.19-0.92,2.05-2.14,1.99c-1.11-0.05-1.91-0.88-1.92-1.98
	C395.65,201.46,396.59,200.57,397.79,200.63z"
              />
              <path
                d="M525.48,341.2c1.21-0.04,2.15,0.88,2.12,2.07c-0.03,1.11-0.85,1.93-1.98,1.97c-1.21,0.04-2.16-0.88-2.13-2.06
	C523.52,342.09,524.38,341.23,525.48,341.2z"
              />
              <path
                d="M277.8,213.12c0.04,1.13-0.74,1.98-1.87,2.05c-1.22,0.07-2.17-0.84-2.14-2.03c0.03-1.09,0.9-1.93,2-1.94
	C276.9,211.19,277.76,212.01,277.8,213.12z"
              />
              <path
                d="M424.64,180.62c-1.12,0.04-2.03-0.75-2.11-1.84c-0.09-1.17,0.85-2.16,2.05-2.14c1.12,0.01,1.97,0.85,1.99,1.96
	C426.58,179.73,425.77,180.58,424.64,180.62z"
              />
              <path
                d="M319.62,511.28c-1.16-0.04-2.08-1.05-2-2.18c0.08-1.15,1.09-1.98,2.28-1.88c1.09,0.1,1.87,0.95,1.86,2.05
	C321.75,510.44,320.81,511.33,319.62,511.28z"
              />
              <path
                d="M385.3,536.16c-0.03,1.11-0.87,1.94-2,1.94c-1.12,0-1.99-0.82-2.03-1.92c-0.04-1.19,0.92-2.12,2.13-2.06
	C384.51,534.18,385.32,535.05,385.3,536.16z"
              />
              <path
                d="M375,394.02c1.1,0.05,1.93,0.94,1.9,2.03c-0.03,1.19-1.02,2.04-2.24,1.92c-1.1-0.11-1.86-1.02-1.77-2.13
	C372.98,394.73,373.87,393.97,375,394.02z"
              />
              <path
                d="M369.11,539.57c-1.1-0.01-1.98-0.83-2.03-1.91c-0.06-1.17,0.87-2.12,2.07-2.11c1.11,0.01,1.97,0.82,2.02,1.91
	C371.23,538.63,370.31,539.57,369.11,539.57z"
              />
              <path
                d="M261,185.82c0.01,1.12-0.8,1.97-1.92,2c-1.11,0.03-2.02-0.77-2.09-1.85c-0.08-1.16,0.87-2.15,2.05-2.13
	C260.16,183.87,260.99,184.71,261,185.82z"
              />
              <path
                d="M526.86,335.93c0.05,1.09-0.72,1.98-1.82,2.07c-1.21,0.11-2.19-0.72-2.24-1.89c-0.05-1.08,0.74-1.98,1.82-2.08
	C525.82,333.92,526.81,334.76,526.86,335.93z"
              />
              <path
                d="M379.45,202.75c-1.2,0-2.1-0.91-2.06-2.09c0.03-1.1,0.83-1.91,1.94-1.97c1.19-0.06,2.17,0.81,2.2,1.96
	C381.57,201.79,380.63,202.74,379.45,202.75z"
              />
              <path
                d="M544.76,603.21c1.1,0,1.96,0.81,2.02,1.89c0.06,1.17-0.84,2.11-2.04,2.11c-1.11,0-1.96-0.8-2.02-1.89
	C542.66,604.14,543.56,603.21,544.76,603.21z"
              />
              <path
                d="M343.07,507.41c-0.04,1.11-0.9,1.92-2.03,1.91c-1.13-0.01-1.98-0.84-2-1.95c-0.02-1.19,0.95-2.1,2.16-2.03
	C342.31,505.41,343.11,506.3,343.07,507.41z"
              />
              <path
                d="M397.84,493.31c1.21-0.04,2.15,0.88,2.12,2.07c-0.03,1.11-0.85,1.93-1.98,1.97c-1.21,0.04-2.16-0.88-2.13-2.06
	C395.88,494.2,396.74,493.34,397.84,493.31z"
              />
              <path
                d="M436.96,486.14c1.2-0.03,2.12,0.87,2.09,2.05c-0.03,1.09-0.86,1.91-1.96,1.94c-1.2,0.03-2.12-0.88-2.09-2.05
	C435.03,486.99,435.86,486.17,436.96,486.14z"
              />
              <path
                d="M464.01,236.68c0.01,1.1-0.79,1.95-1.89,2.01c-1.21,0.07-2.16-0.79-2.17-1.97c-0.01-1.18,0.93-2.07,2.13-2.01
	C463.17,234.75,463.99,235.59,464.01,236.68z"
              />
              <path
                d="M393.79,201.27c-1.12-0.03-1.95-0.84-1.98-1.93c-0.04-1.18,0.89-2.1,2.09-2.07c1.1,0.03,1.95,0.86,1.99,1.94
	C395.92,200.38,395,201.3,393.79,201.27z"
              />
              <path
                d="M436.37,478.93c1.12,0.03,1.95,0.85,1.98,1.95c0.03,1.18-0.9,2.1-2.11,2.07c-1.1-0.03-1.96-0.88-1.99-1.96
	C434.22,479.81,435.16,478.89,436.37,478.93z"
              />
              <path
                d="M419.93,498.57c1.19-0.03,2.12,0.86,2.11,2.04c0,1.1-0.79,1.94-1.89,2.02c-1.18,0.09-2.19-0.76-2.25-1.91
	C417.84,499.58,418.76,498.6,419.93,498.57z"
              />
              <path
                d="M325.76,456.57c-1.21,0.03-2.14-0.89-2.11-2.09c0.03-1.11,0.86-1.93,1.99-1.95c1.21-0.03,2.16,0.9,2.12,2.08
	C327.73,455.69,326.87,456.54,325.76,456.57z"
              />
              <path
                d="M443,475.25c-1.11-0.01-1.96-0.81-2.01-1.9c-0.06-1.18,0.85-2.11,2.05-2.1c1.1,0.01,1.95,0.82,2.01,1.9
	C445.11,474.33,444.2,475.26,443,475.25z"
              />
              <path
                d="M423.93,482.46c-0.05,1.1-0.93,1.91-2.05,1.9c-1.22-0.01-2.1-0.99-1.98-2.19c0.11-1.1,1.03-1.86,2.15-1.78
	C423.16,480.46,423.98,481.36,423.93,482.46z"
              />
              <path
                d="M276.54,182.53c-1.13,0.01-1.98-0.79-2.02-1.9c-0.04-1.12,0.75-1.99,1.87-2.06c1.2-0.07,2.17,0.84,2.15,2.02
	C278.51,181.69,277.65,182.52,276.54,182.53z"
              />
              <path
                d="M531.5,290.56c-1.21-0.03-2.07-0.97-2-2.17c0.07-1.1,0.9-1.89,2.02-1.91c1.19-0.03,2.15,0.89,2.14,2.05
	C533.65,289.68,532.67,290.6,531.5,290.56z"
              />
              <path
                d="M331.07,430.48c1.12,0.04,1.94,0.91,1.93,2.02c-0.01,1.11-0.86,1.95-1.98,1.96c-1.2,0.01-2.14-0.96-2.05-2.14
	C329.04,431.24,329.96,430.44,331.07,430.48z"
              />
              <path
                d="M400.41,375.99c0.05,1.05-0.79,2.03-1.85,2.13c-1.03,0.1-2.03-0.75-2.14-1.81c-0.13-1.17,0.79-2.24,1.94-2.26
	C399.39,374.03,400.36,374.94,400.41,375.99z"
              />
              <path
                d="M368.05,235.63c1.21-0.06,2.14,0.79,2.17,1.98c0.03,1.19-0.86,2.09-2.07,2.09c-1.08,0-1.98-0.81-2.08-1.87
	C365.98,236.69,366.88,235.68,368.05,235.63z"
              />
              <path
                d="M377.67,197.31c0.02,1.09-0.81,1.97-1.91,2.05c-1.22,0.08-2.18-0.78-2.2-1.98c-0.02-1.11,0.78-1.97,1.89-2.05
	C376.66,195.26,377.65,196.13,377.67,197.31z"
              />
              <path
                d="M374.98,501.13c-0.02,1.1-0.88,1.93-1.99,1.94c-1.22,0.02-2.11-0.93-2.02-2.14c0.08-1.1,0.97-1.87,2.1-1.82
	C374.17,499.16,375,500.03,374.98,501.13z"
              />
              <path
                d="M363.96,538.06c-0.01,1.18-0.96,2.04-2.17,1.97c-1.1-0.07-1.9-0.91-1.89-2.01c0.01-1.18,0.96-2.05,2.17-1.97
	C363.16,536.11,363.97,536.97,363.96,538.06z"
              />
              <path
                d="M452.72,479.72c0.01,1.05-0.9,2-1.94,2.05c-1.13,0.05-2.13-0.98-2.07-2.14c0.05-1.06,1-1.96,2.04-1.93
	C451.79,477.73,452.71,478.68,452.72,479.72z"
              />
              <path
                d="M406.54,80.6c0.26,0.48,0.6,0.94,0.76,1.46c0.38,1.17-0.27,2.29-1.4,2.57c-1.17,0.3-2.3-0.37-2.56-1.5
	c-0.26-1.16,0.42-2.23,1.64-2.45c0.53-0.09,1.1,0,1.65,0C406.63,80.68,406.54,80.6,406.54,80.6z"
              />
              <path
                d="M523.17,590.69c0.25,0.49,0.59,0.95,0.75,1.46c0.36,1.17-0.29,2.29-1.43,2.56c-1.18,0.28-2.3-0.39-2.54-1.53
	c-0.25-1.16,0.45-2.23,1.67-2.43c0.53-0.09,1.1,0.01,1.65,0.02C523.25,590.77,523.17,590.69,523.17,590.69z"
              />
              <path
                d="M297.9,386.77c-1.03,0.02-1.99-0.89-2.05-1.94c-0.06-1.14,0.96-2.19,2.09-2.14c1.03,0.04,1.94,1.01,1.92,2.06
	C299.86,385.79,298.93,386.75,297.9,386.77z"
              />
              <path
                d="M439.63,475.09c1.19-0.02,2.12,0.92,2.07,2.09c-0.05,1.09-0.89,1.9-2,1.91c-1.2,0.02-2.12-0.91-2.07-2.09
	C437.67,475.93,438.53,475.11,439.63,475.09z"
              />
              <path
                d="M488.33,536.02c1.21-0.02,2.15,0.92,2.1,2.1c-0.04,1.08-0.92,1.92-2.02,1.94c-1.21,0.02-2.13-0.91-2.09-2.1
	C486.37,536.85,487.21,536.04,488.33,536.02z"
              />
              <path
                d="M532.31,279.27c0.23,0.49,0.54,0.97,0.68,1.49c0.34,1.23-0.33,2.31-1.49,2.53c-1.1,0.21-2.13-0.42-2.4-1.46
	c-0.29-1.14,0.42-2.25,1.64-2.46c0.54-0.09,1.1-0.01,1.65-0.01C532.39,279.35,532.31,279.27,532.31,279.27z"
              />
              <path
                d="M570.77,611.84c1.12,0.05,1.93,0.88,1.94,2c0.01,1.19-0.94,2.09-2.15,2.03c-1.1-0.05-1.95-0.92-1.96-2.01
	C568.59,612.68,569.55,611.78,570.77,611.84z"
              />
              <path
                d="M354.77,540.79c-1.04,0.02-1.98-0.87-2.03-1.94c-0.05-1.07,0.79-2.04,1.84-2.12c1.13-0.09,2.18,0.9,2.17,2.05
	C356.74,539.83,355.82,540.77,354.77,540.79z"
              />
              <path
                d="M493.8,438.36c-1.16-0.04-2.09-1.06-2.01-2.19c0.08-1.15,1.1-1.99,2.29-1.89c1.1,0.1,1.87,0.95,1.87,2.05
	C495.94,437.53,495.01,438.4,493.8,438.36z"
              />
              <path
                d="M520.89,390.78c-0.03,1.21-0.99,2.06-2.23,1.96c-1.1-0.08-1.93-0.99-1.9-2.07c0.03-1.18,1.04-2.05,2.25-1.96
	C520.12,388.79,520.92,389.67,520.89,390.78z"
              />
              <path
                d="M456.08,143.68c-0.04,1.09-0.92,1.91-2.03,1.91c-1.21-0.01-2.1-0.97-1.98-2.18c0.1-1.1,1.01-1.86,2.14-1.79
	C455.29,141.7,456.12,142.6,456.08,143.68z"
              />
              <path
                d="M354.67,390.64c0.05,1.14-0.9,2.12-2.07,2.13c-1.1,0.01-1.99-0.77-2.09-1.85c-0.11-1.19,0.72-2.16,1.92-2.23
	C353.63,388.62,354.62,389.48,354.67,390.64z"
              />
              <path
                d="M548.23,373.82c0.06,1.1-0.71,2-1.82,2.12c-1.21,0.13-2.22-0.71-2.29-1.89c-0.06-1.08,0.74-2.01,1.83-2.12
	C547.18,371.8,548.17,372.62,548.23,373.82z"
              />
              <path
                d="M435.21,112.82c0.04,1.09-0.73,1.97-1.83,2.06c-1.21,0.1-2.18-0.73-2.23-1.9c-0.04-1.08,0.75-1.97,1.83-2.07
	C434.18,110.8,435.17,111.64,435.21,112.82z"
              />
              <path
                d="M283.56,237.86c0.02,1.05-0.88,2.03-1.9,2.08c-1.13,0.06-2.16-0.97-2.11-2.11c0.04-1.05,0.99-1.97,2.03-1.96
	C282.6,235.86,283.54,236.81,283.56,237.86z"
              />
              <path
                d="M331.59,441.67c-1.1-0.05-1.93-0.9-1.94-1.99c-0.01-1.18,0.94-2.07,2.14-2.01c1.11,0.05,1.92,0.89,1.93,1.98
	C333.74,440.83,332.8,441.72,331.59,441.67z"
              />
              <path
                d="M490.55,442.16c-1.11,0.02-2-0.78-2.08-1.87c-0.08-1.17,0.83-2.14,2.03-2.16c1.2-0.01,2.12,0.92,2.07,2.11
	C492.52,441.34,491.67,442.14,490.55,442.16z"
              />
              <path
                d="M410.43,83.98c0.23,0.49,0.53,0.97,0.68,1.49c0.34,1.23-0.33,2.3-1.5,2.52c-1.1,0.21-2.13-0.42-2.4-1.46
	c-0.29-1.14,0.42-2.25,1.64-2.45c0.54-0.09,1.1-0.01,1.65-0.01C410.52,84.06,410.43,83.98,410.43,83.98z"
              />
              <path
                d="M331.15,450.62c0.05,1.14-0.89,2.11-2.06,2.12c-1.1,0.01-1.98-0.76-2.08-1.84c-0.11-1.18,0.72-2.15,1.91-2.23
	C330.12,448.61,331.1,449.46,331.15,450.62z"
              />
              <path
                d="M364.68,505.43c-0.01,1.09-0.84,1.93-1.93,1.97c-1.2,0.05-2.14-0.85-2.12-2.02c0.02-1.18,0.97-2.04,2.17-1.97
	C363.89,503.48,364.69,504.34,364.68,505.43z"
              />
              <path
                d="M526.7,355.63c1.12-0.03,2,0.77,2.06,1.88c0.06,1.11-0.73,2.01-1.84,2.09c-1.23,0.09-2.2-0.79-2.19-1.99
	C524.74,356.5,525.58,355.65,526.7,355.63z"
              />
              <path
                d="M326.54,440.24c-0.06,1.18-1.04,2.01-2.26,1.9c-1.09-0.1-1.89-1.01-1.83-2.09c0.06-1.17,1.07-2.02,2.27-1.9
	C325.81,438.25,326.59,439.15,326.54,440.24z"
              />
              <path
                d="M495.42,555.59c-0.02,1.04-0.94,1.97-1.98,1.99c-1.13,0.02-2.08-1.02-2-2.17c0.08-1.07,1.03-1.93,2.07-1.87
	C494.56,553.59,495.44,554.54,495.42,555.59z"
              />
              <path
                d="M403.01,491.14c0.06,1.06-0.77,2.04-1.8,2.14c-1.1,0.1-2.18-0.9-2.18-2.02c0-1.04,0.91-1.98,1.95-2.02
	C402,489.19,402.95,490.08,403.01,491.14z"
              />
              <path
                d="M478.63,488.39c-0.01,1.04-0.93,1.97-1.96,2.01c-1.13,0.03-2.09-1-2.02-2.16c0.07-1.06,1.02-1.94,2.05-1.89
	C477.74,486.41,478.63,487.35,478.63,488.39z"
              />
              <path
                d="M243.38,153.42c0.38-0.28,0.72-0.64,1.15-0.81c1.13-0.47,2.31,0.06,2.7,1.13c0.39,1.07-0.26,2.28-1.39,2.61
	c-1.1,0.32-2.18-0.36-2.43-1.61c-0.09-0.45,0.03-0.94,0.05-1.4C243.45,153.34,243.38,153.42,243.38,153.42z"
              />
              <path
                d="M406.04,236.93c0.38-0.28,0.71-0.65,1.13-0.82c1.14-0.47,2.31,0.03,2.71,1.1c0.4,1.06-0.23,2.28-1.37,2.62
	c-1.09,0.33-2.19-0.34-2.45-1.58c-0.09-0.45,0.02-0.94,0.04-1.4C406.11,236.85,406.04,236.93,406.04,236.93z"
              />
              <path
                d="M425.56,230.59c1.03,0.02,1.93,0.96,1.93,2.02c0,1.05-0.9,2-1.93,2.03c-1.13,0.03-2.13-1-2.06-2.14
	C423.57,231.46,424.53,230.57,425.56,230.59z"
              />
              <path
                d="M375.33,68.89c1.03-0.02,1.98,0.89,2.04,1.93c0.06,1.14-0.95,2.17-2.08,2.13c-1.02-0.04-1.92-1-1.92-2.05
	C373.39,69.85,374.31,68.9,375.33,68.89z"
              />
              <path
                d="M410.91,185.01c1.03,0.02,1.96,0.95,1.98,1.99c0.02,1.13-1.04,2.15-2.15,2.06c-1.02-0.08-1.88-1.06-1.84-2.11
	C408.94,185.89,409.87,184.99,410.91,185.01z"
              />
              <path
                d="M437.84,474.01c-0.02,1.04-0.94,1.97-1.98,1.99c-1.13,0.02-2.08-1.02-2-2.17c0.08-1.07,1.03-1.93,2.07-1.87
	C436.97,472.02,437.85,472.97,437.84,474.01z"
              />
              <path
                d="M327.46,328.34c-0.13,1.03-1.15,1.88-2.16,1.79c-1.14-0.11-1.97-1.22-1.77-2.37c0.18-1.06,1.22-1.82,2.24-1.64
	C326.82,326.3,327.59,327.31,327.46,328.34z"
              />
              <path
                d="M427.28,478.27c0.02,1.04-0.87,1.99-1.91,2.05c-1.03,0.06-1.99-0.81-2.06-1.87c-0.08-1.16,0.87-2.2,2-2.17
	C426.34,476.3,427.27,477.23,427.28,478.27z"
              />
              <path
                d="M430.45,498.08c-1.03-0.05-1.91-1.02-1.89-2.07c0.02-1.06,0.94-1.98,1.97-1.98c1.14,0,2.1,1.05,2.01,2.19
	C432.46,497.26,431.48,498.13,430.45,498.08z"
              />
              <path
                d="M425.85,209.32c-0.15-0.16-0.31-0.32-0.46-0.47c-0.15-0.45-0.41-0.89-0.42-1.35c-0.02-1.17,0.78-1.98,1.88-2.03
	c1.03-0.05,1.85,0.61,2.02,1.63c0.18,1.11-0.43,2.01-1.59,2.24C426.82,209.43,426.33,209.33,425.85,209.32z"
              />
              <path
                d="M397.71,370.92c-1.03,0.01-1.98-0.89-2.04-1.93c-0.06-1.13,0.95-2.17,2.08-2.13c1.03,0.04,1.92,1,1.91,2.05
	C399.65,369.97,398.74,370.91,397.71,370.92z"
              />
              <path
                d="M692.35,621.73c-0.05,1.14-0.91,1.88-2.05,1.79c-1.06-0.09-1.88-1.06-1.8-2.11c0.08-1.09,1-1.88,2.09-1.8
	C691.67,619.7,692.4,620.58,692.35,621.73z"
              />
              <path
                d="M254.53,359.67c0.06,1.04-0.78,2.02-1.83,2.12c-1.03,0.1-2.01-0.73-2.13-1.8c-0.13-1.17,0.78-2.23,1.92-2.25
	C253.52,357.73,254.47,358.63,254.53,359.67z"
              />
              <path
                d="M456.07,475.98c-0.04,1.04-0.99,1.95-2.02,1.95c-1.13-0.01-2.06-1.06-1.95-2.22c0.1-1.07,1.07-1.91,2.11-1.82
	C455.25,473.97,456.11,474.93,456.07,475.98z"
              />
              <path
                d="M447.64,129.85c0.04,1.05-0.81,2.04-1.84,2.12c-1.11,0.09-2.17-0.93-2.15-2.06c0.02-1.04,0.94-1.97,1.98-1.99
	C446.66,127.89,447.59,128.79,447.64,129.85z"
              />
              <path
                d="M255.02,367.04c-0.03,1.04-0.97,1.96-2,1.96c-1.13,0-2.07-1.05-1.97-2.2c0.09-1.07,1.06-1.92,2.09-1.84
	C254.18,365.04,255.05,365.99,255.02,367.04z"
              />
              <path
                d="M269.84,81.9c0,0-0.09,0.13-0.09,0.13c-0.63,0.1-1.26,0.3-1.88,0.27c-0.98-0.04-1.67-0.54-1.7-1.62
	c-0.03-1,0.9-2.09,1.88-2.15c1.03-0.07,1.69,0.45,1.83,1.47C269.96,80.62,269.86,81.27,269.84,81.9z"
              />
              <path
                d="M412.88,519.33c-0.01,1.07-0.86,1.92-1.95,1.95c-1.13,0.03-1.97-0.74-2.02-1.86c-0.06-1.2,0.85-2.12,2.05-2.07
	C412.04,517.39,412.89,518.27,412.88,519.33z"
              />
              <path
                d="M393.94,533.77c-1.09,0.05-2.02-0.75-2.11-1.81c-0.1-1.15,0.84-2.14,2.02-2.14c1.11,0,1.95,0.82,1.97,1.93
	C395.85,532.86,395.05,533.72,393.94,533.77z"
              />
              <path
                d="M533.87,378.46c0.02-1.03,0.94-1.96,1.97-1.98c1.13-0.02,2.07,1,1.99,2.16c-0.08,1.07-1.02,1.92-2.05,1.86
	C534.74,380.44,533.85,379.49,533.87,378.46z"
              />
              <path
                d="M336.57,349.09c-0.07,1.07-1,1.89-2.1,1.84c-1.12-0.06-1.9-0.93-1.84-2.07c0.06-1.12,0.95-1.91,2.07-1.84
	C335.79,347.09,336.64,348.02,336.57,349.09z"
              />
              <path
                d="M513.97,588.28c-1.02-0.04-1.91-0.99-1.9-2.04c0.01-1.05,0.92-1.98,1.95-1.99c1.13-0.02,2.11,1.03,2.02,2.16
	C515.96,587.44,514.99,588.32,513.97,588.28z"
              />
              <path
                d="M453.26,466.69c1.03-0.05,1.97,0.82,2.04,1.88c0.06,1.06-0.75,2.04-1.78,2.14c-1.1,0.11-2.18-0.88-2.19-2
	C451.33,467.68,452.23,466.74,453.26,466.69z"
              />
              <path
                d="M341.58,532.87c-0.06,1.11-0.9,1.88-2.03,1.85c-1.09-0.03-1.94-0.88-1.94-1.95c0-1.15,1-2.07,2.17-1.98
	C340.86,530.87,341.64,531.77,341.58,532.87z"
              />
              <path
                d="M315.95,444.81c-0.06,1.03-1.01,1.93-2.04,1.91c-1.13-0.02-2.03-1.08-1.91-2.24c0.11-1.07,1.08-1.88,2.12-1.78
	C315.16,442.81,316,443.78,315.95,444.81z"
              />
              <path
                d="M319.03,334.06c1.12,0.02,1.94,0.89,1.91,2.02c-0.03,1.14-0.87,1.93-2.02,1.9c-1.11-0.03-1.97-0.93-1.93-2.01
	C317.02,334.89,317.93,334.03,319.03,334.06z"
              />
              <path
                d="M280.14,407.97c0.02,1.14-0.79,1.97-1.92,1.98c-1.1,0.01-2-0.84-2.01-1.92c-0.02-1.07,0.86-1.97,1.95-1.99
	C279.28,406.02,280.13,406.84,280.14,407.97z"
              />
              <path
                d="M529.74,597.48c1.1,0.05,1.9,0.92,1.88,2.03c-0.02,1.12-0.85,1.92-1.98,1.92c-1.19,0-2.11-0.99-2.01-2.14
	C527.72,598.22,528.65,597.42,529.74,597.48z"
              />
              <path
                d="M385.24,410.02c-0.05,1.14-0.92,1.91-2.07,1.85c-1.1-0.06-1.95-0.97-1.89-2.05c0.06-1.07,1-1.91,2.09-1.86
	C384.49,408,385.29,408.89,385.24,410.02z"
              />
              <path
                d="M317.14,522.25c-1.1,0.04-2.01-0.76-2.09-1.83c-0.09-1.15,0.86-2.13,2.04-2.12c1.11,0.01,1.94,0.83,1.95,1.95
	C319.06,521.37,318.26,522.21,317.14,522.25z"
              />
              <path
                d="M256.92,388.32c0,1.08-0.88,1.95-1.98,1.95c-1.14,0.01-1.96-0.81-1.96-1.95c0-1.13,0.83-1.96,1.95-1.96
	C256.03,386.37,256.92,387.25,256.92,388.32z"
              />
              <path
                d="M371.79,7.62c-0.57,0.25-1.12,0.62-1.71,0.72c-1.15,0.19-2.07-0.55-2.19-1.62c-0.11-0.96,0.55-1.87,1.52-2.08
	c1.08-0.23,2.09,0.35,2.32,1.45c0.11,0.51-0.01,1.07-0.02,1.61C371.72,7.7,371.79,7.62,371.79,7.62z"
              />
              <path
                d="M483.73,525.51c1.09-0.04,2.03,0.8,2.08,1.88c0.05,1.08-0.8,1.99-1.9,2.04c-1.15,0.05-2.01-0.73-2.05-1.86
	C481.81,526.44,482.62,525.56,483.73,525.51z"
              />
              <path
                d="M469.01,460.34c-0.02,1.09-0.92,1.93-2.03,1.92c-1.14-0.02-1.95-0.86-1.92-2c0.02-1.13,0.88-1.95,2-1.92
	C468.17,458.36,469.04,459.26,469.01,460.34z"
              />
              <path
                d="M483.14,518.32c1.12,0.02,1.93,0.88,1.9,2.01c-0.03,1.13-0.87,1.93-2.01,1.89c-1.1-0.03-1.96-0.93-1.93-2
	C481.13,519.15,482.04,518.3,483.14,518.32z"
              />
              <path
                d="M465.84,464.17c-0.02,1.13-0.85,1.94-1.99,1.92c-1.1-0.02-1.97-0.91-1.95-1.98c0.02-1.07,0.93-1.94,2.02-1.93
	C465.04,462.19,465.86,463.04,465.84,464.17z"
              />
              <path
                d="M419.33,511.57c0.04,1.11-0.75,1.98-1.85,2.05c-1.2,0.07-2.17-0.85-2.14-2.02c0.03-1.08,0.89-1.92,1.99-1.93
	C418.45,509.66,419.29,510.46,419.33,511.57z"
              />
              <path
                d="M406.16,400.8c0,1.09-0.88,1.95-1.99,1.96c-1.14,0-1.97-0.82-1.97-1.96c0-1.13,0.84-1.97,1.96-1.96
	C405.26,398.84,406.15,399.72,406.16,400.8z"
              />
              <path
                d="M532.36,274.1c0.01,1.07-0.82,1.94-1.92,1.98c-1.13,0.04-1.98-0.71-2.05-1.82c-0.08-1.2,0.82-2.13,2.02-2.1
	C531.49,272.18,532.35,273.03,532.36,274.1z"
              />
              <path
                d="M415.96,515.47c0.01,1.11-0.79,1.94-1.91,1.97c-1.09,0.03-1.99-0.77-2.07-1.84c-0.08-1.15,0.87-2.12,2.04-2.1
	C415.14,513.53,415.95,514.36,415.96,515.47z"
              />
              <path
                d="M518.14,235.69c0.03,1.14-0.78,1.98-1.91,2c-1.1,0.02-2-0.83-2.03-1.91c-0.03-1.08,0.84-1.97,1.94-2
	C517.27,233.75,518.12,234.56,518.14,235.69z"
              />
              <path
                d="M312.11,441.32c0.02,1.03-0.87,1.98-1.91,2.04c-1.11,0.06-2.11-0.95-2.06-2.1c0.04-1.06,0.96-1.95,2-1.93
	C311.17,439.36,312.1,440.29,312.11,441.32z"
              />
              <path
                d="M533.75,600.56c1.03,0.02,1.95,0.95,1.97,1.98c0.02,1.12-1.04,2.14-2.14,2.05c-1.02-0.08-1.87-1.06-1.83-2.11
	C531.79,601.43,532.71,600.54,533.75,600.56z"
              />
              <path
                d="M296.32,245.85c-1.11-0.06-1.94-0.97-1.88-2.06c0.06-1.08,1-1.91,2.09-1.86c1.11,0.05,1.91,0.94,1.86,2.07
	C298.33,245.13,297.47,245.91,296.32,245.85z"
              />
              <path
                d="M467.78,469.46c-1.12,0.02-2.01-0.81-2.05-1.9c-0.03-1.08,0.83-1.98,1.93-2.02c1.13-0.03,1.99,0.78,2.02,1.91
	C469.72,468.58,468.91,469.43,467.78,469.46z"
              />
              <path
                d="M472.27,475.8c1.02-0.01,1.98,0.89,2.03,1.92c0.06,1.13-0.95,2.16-2.07,2.11c-1.03-0.04-1.91-0.99-1.9-2.04
	C470.33,476.74,471.23,475.81,472.27,475.8z"
              />
              <path
                d="M416.91,482.86c0,1.14-0.83,1.96-1.97,1.95c-1.11-0.01-1.99-0.88-1.98-1.96c0-1.08,0.9-1.96,1.99-1.96
	C416.07,480.89,416.91,481.73,416.91,482.86z"
              />
              <path
                d="M371.13,204.97c0.03,1.07-0.79,1.96-1.88,2.02c-1.13,0.06-2-0.68-2.09-1.78c-0.1-1.2,0.78-2.15,1.98-2.14
	C370.22,203.06,371.1,203.9,371.13,204.97z"
              />
              <path
                d="M312.39,341.73c1.08-0.06,2.02,0.74,2.12,1.79c0.11,1.14-0.82,2.14-1.99,2.15c-1.12,0.01-1.95-0.78-1.98-1.9
	C310.51,342.66,311.3,341.79,312.39,341.73z"
              />
              <path
                d="M315.59,507.84c-1.11-0.02-1.98-0.9-1.96-1.98c0.02-1.08,0.92-1.95,2.01-1.94c1.12,0.01,1.95,0.86,1.94,1.99
	C317.57,507.05,316.73,507.86,315.59,507.84z"
              />
              <path
                d="M382.77,176.61c-0.43,0.19-0.85,0.46-1.3,0.55c-1.3,0.29-2.32-0.34-2.56-1.49c-0.24-1.15,0.59-2.25,1.77-2.37
	c1.22-0.12,2.09,0.65,2.17,1.98c0.03,0.47-0.11,0.94-0.17,1.41C382.68,176.7,382.77,176.61,382.77,176.61z"
              />
              <path
                d="M585.85,621.87c-1.02,0.04-1.99-0.84-2.07-1.88c-0.08-1.13,0.9-2.17,2.03-2.16c1.03,0.01,1.93,0.94,1.94,2
	C587.76,620.88,586.87,621.83,585.85,621.87z"
              />
              <path
                d="M297.9,236.65c0.03,1.14-0.78,1.98-1.91,2c-1.1,0.02-2-0.83-2.03-1.91c-0.03-1.08,0.84-1.97,1.94-2
	C297.02,234.71,297.87,235.52,297.9,236.65z"
              />
              <path
                d="M420.69,509.77c-1.1-0.01-1.96-0.84-1.99-1.91c-0.03-1.16,0.95-2.09,2.12-2.02c1.09,0.06,1.88,0.93,1.85,2.04
	C422.64,508.99,421.82,509.78,420.69,509.77z"
              />
              <path
                d="M471.61,468.88c1.11,0.02,1.93,0.84,1.95,1.96c0.01,1.12-0.79,1.96-1.9,1.99c-1.19,0.04-2.14-0.91-2.08-2.07
	C469.62,469.68,470.51,468.87,471.61,468.88z"
              />
              <path
                d="M444.32,485.69c1.11,0.05,1.9,0.94,1.85,2.07c-0.06,1.13-0.92,1.9-2.06,1.84c-1.1-0.06-1.94-0.98-1.87-2.06
	C442.29,486.47,443.23,485.64,444.32,485.69z"
              />
              <path
                d="M372.35,199.22c1.09,0.05,1.89,0.91,1.87,2.02c-0.02,1.12-0.84,1.91-1.97,1.91c-1.18,0-2.11-0.99-2-2.13
	C370.34,199.96,371.27,199.16,372.35,199.22z"
              />
              <path
                d="M526.79,375.95c-0.05,1.14-0.91,1.91-2.06,1.85c-1.1-0.05-1.95-0.97-1.89-2.05c0.06-1.08,0.99-1.92,2.09-1.87
	C526.04,373.93,526.84,374.82,526.79,375.95z"
              />
              <path
                d="M495.12,429c0.06,1.14-0.72,2.02-1.85,2.07c-1.1,0.06-2.04-0.77-2.1-1.85c-0.06-1.08,0.78-2.01,1.87-2.07
	C494.17,427.08,495.06,427.87,495.12,429z"
              />
              <path
                d="M350.21,506.8c0.02,1.14-0.79,1.98-1.92,2c-1.11,0.02-2.01-0.83-2.03-1.92c-0.02-1.08,0.85-1.98,1.95-2
	C349.33,504.86,350.19,505.67,350.21,506.8z"
              />
              <path
                d="M336.17,431.72c0.02-1.03,0.94-1.96,1.97-1.98c1.13-0.02,2.07,1,1.99,2.16c-0.08,1.07-1.02,1.92-2.05,1.86
	C337.04,433.71,336.15,432.75,336.17,431.72z"
              />
              <path
                d="M551.55,370.18c-0.05,1.06-0.94,1.88-2.03,1.87c-1.2-0.01-2.06-0.98-1.94-2.17c0.11-1.08,1.02-1.84,2.13-1.76
	C550.78,368.21,551.6,369.12,551.55,370.18z"
              />
              <path
                d="M467.07,169.86c-1.03,0.03-1.96-0.85-2.01-1.91c-0.05-1.07,0.76-2.03,1.81-2.11c1.11-0.09,2.17,0.9,2.16,2.03
	C469.02,168.89,468.1,169.83,467.07,169.86z"
              />
              <path
                d="M370.37,197.99c0.04,1.05-0.8,2.03-1.83,2.11c-1.1,0.08-2.16-0.93-2.14-2.05c0.02-1.03,0.94-1.96,1.97-1.98
	C369.41,196.04,370.33,196.92,370.37,197.99z"
              />
              <path
                d="M325.75,432.88c0.04,1.11-0.74,1.98-1.84,2.04c-1.18,0.07-2.16-0.86-2.13-2.01c0.03-1.07,0.9-1.91,1.98-1.92
	C324.87,430.97,325.71,431.77,325.75,432.88z"
              />
              <path
                d="M424.38,386.44c-1.15,0.03-1.99-0.76-2.02-1.89c-0.03-1.13,0.78-2,1.9-2.02c1.1-0.03,2.01,0.82,2.05,1.9
	C426.34,385.51,425.48,386.41,424.38,386.44z"
              />
              <path
                d="M318.55,433.61c0.03,1.05-0.83,2.02-1.85,2.09c-1.11,0.07-2.15-0.95-2.12-2.07c0.03-1.03,0.96-1.95,1.99-1.96
	C317.61,431.65,318.52,432.54,318.55,433.61z"
              />
              <path
                d="M483.88,505.79c0.06,1.08-0.79,1.99-1.89,2.04c-1.14,0.06-2-0.72-2.05-1.85c-0.05-1.13,0.75-2.01,1.86-2.06
	C482.89,503.88,483.83,504.72,483.88,505.79z"
              />
              <path
                d="M279.08,191.02c0.05-1.08,0.98-1.92,2.08-1.88c1.11,0.04,1.92,0.93,1.88,2.05c-0.05,1.13-0.91,1.92-2.05,1.86
	C279.87,193.01,279.03,192.1,279.08,191.02z"
              />
              <path
                d="M349.25,452.59c0.02,1.14-0.79,1.98-1.93,1.99c-1.11,0.01-2.01-0.83-2.03-1.92c-0.02-1.08,0.85-1.98,1.95-2
	C348.38,450.64,349.23,451.46,349.25,452.59z"
              />
              <path
                d="M399.14,195.53c-0.05,1.13-0.91,1.9-2.06,1.85c-1.1-0.06-1.94-0.97-1.88-2.05c0.06-1.07,1-1.91,2.08-1.86
	C398.4,193.52,399.2,194.41,399.14,195.53z"
              />
              <path
                d="M357.42,506.06c0.03,1.05-0.82,2.02-1.85,2.09c-1.11,0.07-2.15-0.95-2.12-2.07c0.03-1.03,0.96-1.95,1.99-1.96
	C356.48,504.1,357.39,505,357.42,506.06z"
              />
              <path
                d="M517.48,394.81c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C516.53,392.82,517.48,393.77,517.48,394.81z"
              />
              <path
                d="M471.1,21.38c-0.29-0.46-0.63-0.89-0.84-1.37c-0.47-1.08-0.04-2.15,0.97-2.53c0.98-0.38,2.16,0.13,2.54,1.1
	c0.41,1.03-0.04,2.08-1.14,2.47c-0.5,0.18-1.06,0.18-1.59,0.27C471.03,21.31,471.1,21.38,471.1,21.38z"
              />
              <path
                d="M459.13,472.11c-0.03,1.08-0.97,1.99-2.01,1.96c-1.05-0.04-1.97-1.01-1.94-2.05c0.03-1.05,1-1.98,2.04-1.96
	C458.28,470.08,459.16,471.02,459.13,472.11z"
              />
              <path
                d="M348.55,445.47c-0.03,1.06-0.93,1.91-2.01,1.89c-1.12-0.02-1.91-0.85-1.88-1.98c0.02-1.12,0.86-1.93,1.97-1.9
	C347.7,443.51,348.58,444.42,348.55,445.47z"
              />
              <path
                d="M548.66,606.63c1.06,0.03,1.93,0.95,1.9,1.99c-0.03,1.05-0.94,1.9-2.01,1.88c-1.11-0.02-1.9-0.86-1.87-1.98
	C546.71,607.41,547.56,606.6,548.66,606.63z"
              />
              <path
                d="M527.23,236.41c1.07-0.01,1.94,0.88,1.94,1.97c0,1.08-0.89,1.99-1.94,1.98c-1.02-0.01-1.98-0.96-1.98-1.98
	C525.25,237.36,526.2,236.41,527.23,236.41z"
              />
              <path
                d="M364.48,196.71c-1.05-0.05-1.95-1.03-1.9-2.07c0.04-1.04,1.03-1.96,2.06-1.92c1.07,0.04,1.91,0.98,1.87,2.08
	C366.47,195.88,365.53,196.76,364.48,196.71z"
              />
              <path
                d="M404.04,529.24c-1.05-0.06-1.88-1.03-1.82-2.11c0.06-1.09,0.99-1.93,2.06-1.85c1.05,0.08,1.92,1.06,1.85,2.09
	C406.07,528.4,405.06,529.3,404.04,529.24z"
              />
              <path
                d="M466.29,471.38c-0.02,1.12-0.85,1.92-1.97,1.89c-1.08-0.03-1.95-0.92-1.92-1.97c0.02-1.06,0.93-1.92,2-1.9
	C465.52,469.41,466.31,470.25,466.29,471.38z"
              />
              <path
                d="M366.05,499.63c1.1,0.02,1.89,0.87,1.87,1.99c-0.03,1.12-0.86,1.91-1.97,1.87c-1.07-0.03-1.94-0.93-1.9-1.98
	C364.07,500.47,364.99,499.6,366.05,499.63z"
              />
              <path
                d="M317.53,339.97c-0.06,1.11-0.92,1.88-2.03,1.81c-1.08-0.06-1.91-0.98-1.85-2.04c0.06-1.04,1.01-1.88,2.07-1.83
	C316.81,337.98,317.59,338.86,317.53,339.97z"
              />
              <path
                d="M492.02,432.99c-0.03,1.05-0.93,1.9-2,1.88c-1.11-0.02-1.9-0.85-1.88-1.97c0.02-1.11,0.86-1.92,1.97-1.89
	C491.17,431.04,492.04,431.95,492.02,432.99z"
              />
              <path
                d="M327.48,347.73c1.05,0.06,1.94,1.03,1.89,2.06c-0.05,1.03-1.04,1.95-2.06,1.9c-1.05-0.04-1.9-1-1.85-2.08
	C325.49,348.52,326.41,347.67,327.48,347.73z"
              />
              <path
                d="M305.16,382.05c1.1,0.05,1.89,0.94,1.83,2.06c-0.06,1.13-0.91,1.89-2.04,1.83c-1.09-0.06-1.92-0.98-1.86-2.04
	C303.15,382.84,304.09,382,305.16,382.05z"
              />
              <path
                d="M310.38,506.52c0.04,1.09-0.82,2.05-1.88,2.1c-1.04,0.04-2.02-0.88-2.07-1.93c-0.05-1.05,0.84-2.03,1.9-2.08
	C309.39,504.55,310.34,505.43,310.38,506.52z"
              />
              <path
                d="M488.96,546.99c-1.02,0.06-2.03-0.84-2.1-1.87c-0.07-1.04,0.79-2.02,1.85-2.1c1.07-0.08,2,0.76,2.06,1.85
	C490.84,545.95,490.01,546.93,488.96,546.99z"
              />
              <path
                d="M459.89,479.24c0.01,1.02-0.93,1.99-1.96,2.01c-1.04,0.02-1.95-0.89-1.97-1.97c-0.01-1.09,0.86-2,1.92-2
	C458.92,477.27,459.88,478.21,459.89,479.24z"
              />
              <path
                d="M423.11,182.38c0.05,1.13-0.72,1.99-1.84,2.04c-1.08,0.05-2.01-0.78-2.07-1.85c-0.05-1.06,0.8-1.99,1.87-2.04
	C422.19,180.47,423.06,181.26,423.11,182.38z"
              />
              <path
                d="M564.4,99.87c-0.11-0.14-0.23-0.29-0.34-0.43c-0.15-0.28-0.37-0.54-0.42-0.84c-0.26-1.42,0.26-2.47,1.27-2.69
	c0.76-0.16,1.82,0.76,2.14,1.87c0.33,1.14-0.06,1.85-1.23,2.08C565.37,99.95,564.88,99.87,564.4,99.87z"
              />
              <path
                d="M288.77,215.84c-0.01,1.08-0.91,1.99-1.95,1.98c-1.03-0.01-1.98-0.98-1.98-2c0-1.02,0.96-1.97,1.99-1.97
	C287.9,213.84,288.78,214.74,288.77,215.84z"
              />
              <path
                d="M443.57,126.66c-0.05,1.03-1.04,1.95-2.06,1.91c-1.05-0.04-1.9-0.99-1.86-2.08c0.04-1.09,0.96-1.95,2.03-1.89
	C442.73,124.66,443.62,125.63,443.57,126.66z"
              />
              <path
                d="M458.7,155.94c-1.04,0.04-2.03-0.87-2.08-1.92c-0.05-1.05,0.83-2.03,1.9-2.09c1.06-0.06,2.01,0.82,2.05,1.91
	C460.62,154.92,459.75,155.89,458.7,155.94z"
              />
              <path
                d="M290.23,190.34c0.02,1.09-0.86,2.04-1.91,2.06c-1.04,0.02-2.01-0.91-2.04-1.96c-0.03-1.05,0.88-2.02,1.94-2.05
	C289.28,188.36,290.2,189.25,290.23,190.34z"
              />
              <path
                d="M442.34,464.11c1.11,0.02,1.91,0.87,1.88,2c-0.03,1.13-0.86,1.92-1.99,1.88c-1.09-0.03-1.95-0.93-1.92-1.99
	C440.35,464.94,441.27,464.09,442.34,464.11z"
              />
              <path
                d="M318.76,377.6c-1.05,0.01-1.95-0.9-1.96-1.98c-0.01-1.09,0.87-1.99,1.94-1.99c1.03,0,1.99,0.95,1.99,1.97
	C320.74,376.63,319.79,377.59,318.76,377.6z"
              />
              <path
                d="M327.94,375.14c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C327,373.15,327.94,374.1,327.94,375.14z"
              />
              <path
                d="M539.54,605.93c0,1.01-0.95,1.98-1.97,1.99c-1.04,0.01-1.94-0.9-1.95-1.97c0-1.09,0.86-1.98,1.93-1.98
	C538.59,603.97,539.54,604.91,539.54,605.93z"
              />
              <path
                d="M389.34,539.42c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C388.4,537.5,389.31,538.36,389.34,539.42z"
              />
              <path
                d="M393.73,493.91c-1.09-0.06-1.93-0.97-1.87-2.04c0.06-1.06,1-1.9,2.07-1.85c1.1,0.05,1.89,0.93,1.84,2.05
	C395.71,493.2,394.86,493.97,393.73,493.91z"
              />
              <path
                d="M249.18,365.62c-1.05,0.01-1.97-0.92-1.97-2c0-1.09,0.9-2.01,1.96-2.01c1.05,0,2,0.96,2,2
	C251.17,364.65,250.22,365.61,249.18,365.62z"
              />
              <path
                d="M308.24,438.11c0.07,1.05-0.77,1.98-1.84,2.04c-1.12,0.07-1.98-0.69-2.04-1.81c-0.06-1.1,0.72-2,1.8-2.06
	C307.22,436.23,308.17,437.07,308.24,438.11z"
              />
              <path
                d="M400.21,359.85c-1.05-0.06-1.88-1.03-1.83-2.11c0.06-1.09,0.99-1.93,2.05-1.86c1.05,0.07,1.92,1.05,1.86,2.09
	C402.23,359.01,401.23,359.91,400.21,359.85z"
              />
              <path
                d="M512.25,230.44c1.11-0.06,1.97,0.73,2.02,1.85c0.05,1.13-0.72,1.98-1.83,2.03c-1.08,0.05-2-0.79-2.06-1.84
	C510.33,231.42,511.19,230.49,512.25,230.44z"
              />
              <path
                d="M380.06,538.2c1.03-0.06,2.03,0.84,2.09,1.89c0.06,1.04-0.81,2.03-1.87,2.11c-1.06,0.08-2-0.79-2.06-1.88
	C378.17,539.21,379,538.26,380.06,538.2z"
              />
              <path
                d="M273.93,193.82c-1.02,0.02-2-0.91-2.03-1.93c-0.03-1.02,0.88-1.99,1.92-2.02c1.07-0.04,1.97,0.82,1.99,1.91
	C275.83,192.86,274.97,193.79,273.93,193.82z"
              />
              <path
                d="M340.89,439.04c-0.05,1.04-1.04,1.96-2.07,1.92c-1.05-0.04-1.91-1-1.87-2.09c0.04-1.09,0.97-1.96,2.04-1.9
	C340.05,437.02,340.94,437.99,340.89,439.04z"
              />
              <path
                d="M406.34,487.33c0.04,1.09-0.82,2.04-1.87,2.09c-1.03,0.04-2.02-0.88-2.07-1.92c-0.05-1.05,0.84-2.02,1.9-2.07
	C405.37,485.37,406.3,486.24,406.34,487.33z"
              />
              <path
                d="M343.98,458.39c-1.07,0.02-1.98-0.83-2.01-1.88c-0.03-1.04,0.85-1.96,1.9-1.99c1.1-0.03,1.95,0.77,1.97,1.88
	C345.87,457.53,345.09,458.37,343.98,458.39z"
              />
              <path
                d="M345.68,536.07c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C344.74,534.14,345.65,535,345.68,536.07z"
              />
              <path
                d="M472.36,456.41c0.03,1.07-0.83,1.96-1.92,1.99c-1.13,0.03-1.96-0.76-1.98-1.89c-0.02-1.13,0.78-1.98,1.89-2
	C471.41,454.49,472.33,455.35,472.36,456.41z"
              />
              <path
                d="M498.44,425.22c0.03,1.13-0.75,1.97-1.87,1.99c-1.08,0.02-1.99-0.82-2.02-1.88c-0.03-1.05,0.84-1.96,1.91-1.99
	C497.57,423.3,498.41,424.1,498.44,425.22z"
              />
              <path
                d="M484.3,204.39c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99c1.04,0.01,1.99,0.96,1.99,2
	C486.29,203.43,485.33,204.38,484.3,204.39z"
              />
              <path
                d="M451.44,465.23c0.06,1.09-0.78,2.06-1.84,2.12c-1.03,0.06-2.03-0.85-2.09-1.89c-0.07-1.05,0.81-2.03,1.87-2.1
	C450.44,463.28,451.38,464.14,451.44,465.23z"
              />
              <path
                d="M365.83,187.44c0.04,1.02-0.86,2-1.89,2.06c-1.05,0.06-1.97-0.81-2.01-1.89c-0.04-1.1,0.78-2.02,1.85-2.06
	C364.8,185.5,365.78,186.41,365.83,187.44z"
              />
              <path
                d="M462.5,468.22c0,1.08-0.92,2.01-1.97,2.01c-1.04-0.01-1.99-0.97-1.99-2.01c0-1.04,0.95-2,2-2
	C461.59,466.21,462.5,467.13,462.5,468.22z"
              />
              <path
                d="M374.96,540.68c0,1.03-0.96,1.99-1.99,2c-1.05,0.01-1.96-0.92-1.96-2c0-1.09,0.89-2,1.96-1.99
	C374.02,538.69,374.97,539.64,374.96,540.68z"
              />
              <path
                d="M331.11,62.37c-1.05-0.06-1.9-1.05-1.84-2.13c0.06-1.09,1.02-1.95,2.08-1.88c1.07,0.08,1.93,1.06,1.86,2.12
	C333.15,61.54,332.14,62.44,331.11,62.37z"
              />
              <path
                d="M365.68,539.44c1.07-0.05,2.01,0.79,2.07,1.85c0.06,1.07-0.78,1.98-1.87,2.04c-1.13,0.06-1.98-0.71-2.04-1.84
	C363.79,540.38,364.58,539.49,365.68,539.44z"
              />
              <path
                d="M390.37,537.58c-1.09-0.06-1.93-0.97-1.87-2.04c0.06-1.06,1-1.9,2.08-1.85c1.11,0.05,1.9,0.94,1.84,2.06
	C392.36,536.87,391.51,537.64,390.37,537.58z"
              />
              <path
                d="M501.82,421.34c0.05,1.13-0.73,2-1.85,2.04c-1.09,0.05-2.02-0.78-2.07-1.85c-0.05-1.07,0.79-1.99,1.88-2.05
	C500.89,419.43,501.77,420.22,501.82,421.34z"
              />
              <path
                d="M490.78,212.66c0.02,1.13-0.78,1.97-1.9,1.99c-1.1,0.02-1.99-0.83-2.02-1.9c-0.02-1.07,0.85-1.97,1.93-1.99
	C489.91,210.72,490.76,211.54,490.78,212.66z"
              />
              <path
                d="M428.69,472.48c1.04,0.04,1.95,1,1.92,2.03c-0.03,1.03-1.01,1.96-2.03,1.94c-1.05-0.02-1.92-0.97-1.89-2.05
	C426.72,473.31,427.62,472.44,428.69,472.48z"
              />
              <path
                d="M329.58,459.84c-1.08,0.02-1.99-0.82-2.02-1.88c-0.03-1.05,0.84-1.96,1.91-2c1.11-0.03,1.95,0.77,1.98,1.89
	C331.49,458.98,330.7,459.81,329.58,459.84z"
              />
              <path
                d="M402.29,191.54c0.03,1.05-0.84,1.95-1.91,1.98c-1.12,0.03-1.94-0.76-1.97-1.88c-0.02-1.12,0.77-1.97,1.87-1.99
	C401.34,189.63,402.26,190.5,402.29,191.54z"
              />
              <path
                d="M362.29,214.96c-1.05-0.07-1.89-1.05-1.83-2.14c0.06-1.09,1.03-1.95,2.08-1.87c1.07,0.08,1.93,1.07,1.86,2.12
	C364.33,214.13,363.33,215.03,362.29,214.96z"
              />
              <path
                d="M418.96,238.59c1.06-0.04,1.99,0.81,2.04,1.85c0.05,1.05-0.8,1.96-1.87,2.01c-1.12,0.05-1.96-0.72-2.01-1.84
	C417.08,239.51,417.87,238.64,418.96,238.59z"
              />
              <path
                d="M479.88,189.82c1.04-0.05,2.03,0.86,2.09,1.91c0.05,1.05-0.83,2.03-1.89,2.1c-1.05,0.07-2-0.81-2.06-1.9
	C477.97,190.84,478.82,189.88,479.88,189.82z"
              />
              <path
                d="M503,419.36c-1.05,0-1.95-0.94-1.94-2.02c0.01-1.09,0.91-1.99,1.98-1.98c1.04,0.01,1.98,0.97,1.97,2.01
	C504.99,418.41,504.03,419.36,503,419.36z"
              />
              <path
                d="M324.76,505.36c0.02,1.13-0.78,1.97-1.9,1.99c-1.1,0.02-1.99-0.83-2.02-1.9c-0.02-1.07,0.85-1.97,1.93-1.99
	C323.89,503.43,324.73,504.24,324.76,505.36z"
              />
              <path
                d="M484.37,532.74c1.06,0.04,1.93,0.96,1.89,2c-0.04,1.05-0.95,1.9-2.02,1.87c-1.11-0.03-1.89-0.87-1.86-1.99
	C482.42,533.5,483.26,532.7,484.37,532.74z"
              />
              <path
                d="M454.5,481.11c1.03-0.02,2,0.91,2.03,1.94c0.03,1.02-0.9,2-1.93,2.03c-1.04,0.04-1.96-0.86-1.99-1.94
	C452.58,482.05,453.43,481.13,454.5,481.11z"
              />
              <path
                d="M283.09,210.76c1.09,0.05,1.87,0.93,1.82,2.05c-0.06,1.12-0.91,1.88-2.03,1.82c-1.08-0.06-1.91-0.98-1.85-2.03
	C281.09,211.55,282.03,210.71,283.09,210.76z"
              />
              <path
                d="M458.64,464.78c0.04,1.09-0.82,2.04-1.87,2.09c-1.03,0.04-2.02-0.88-2.07-1.92c-0.05-1.05,0.84-2.02,1.9-2.07
	C457.67,462.82,458.6,463.69,458.64,464.78z"
              />
              <path
                d="M329.9,502.7c1.06-0.07,2,0.79,2.06,1.88c0.05,1.09-0.79,2.05-1.84,2.11c-1.03,0.06-2.03-0.85-2.09-1.9
	C327.97,503.75,328.85,502.77,329.9,502.7z"
              />
              <path
                d="M470.14,474.74c-0.02,1.13-0.86,1.92-1.99,1.89c-1.09-0.03-1.95-0.92-1.92-1.99c0.03-1.06,0.94-1.92,2.02-1.9
	C469.36,472.77,470.16,473.62,470.14,474.74z"
              />
              <path
                d="M374.48,327.74c-0.06,1.04-1.06,1.95-2.09,1.9c-1.05-0.06-1.9-1.02-1.84-2.11c0.05-1.09,0.99-1.95,2.06-1.88
	C373.66,325.71,374.54,326.69,374.48,327.74z"
              />
              <path
                d="M484.55,513.08c0,1.14-0.81,1.95-1.94,1.95c-1.1,0-1.98-0.87-1.98-1.94c0-1.07,0.89-1.95,1.97-1.96
	C483.71,511.12,484.54,511.95,484.55,513.08z"
              />
              <path
                d="M295.14,227.55c1.07-0.05,2.01,0.79,2.07,1.85c0.06,1.07-0.78,1.98-1.87,2.04c-1.13,0.06-1.98-0.71-2.04-1.84
	C293.25,228.48,294.04,227.6,295.14,227.55z"
              />
              <path
                d="M463.23,475.23c-0.03,1.06-0.93,1.91-2.02,1.88c-1.12-0.02-1.9-0.86-1.88-1.99c0.03-1.12,0.87-1.92,1.98-1.89
	C462.39,473.27,463.26,474.18,463.23,475.23z"
              />
              <path
                d="M409.51,523.46c-0.03,1.05-1,1.98-2.04,1.96c-1.05-0.02-1.94-0.98-1.91-2.06c0.03-1.09,0.95-1.99,2.02-1.95
	C408.64,521.44,409.54,522.4,409.51,523.46z"
              />
              <path
                d="M230.18,229.99c-1.07-0.02-1.94-0.91-1.93-1.96c0.02-1.05,0.92-1.92,1.98-1.91c1.11,0.01,1.9,0.84,1.89,1.97
	C232.12,229.21,231.3,230.01,230.18,229.99z"
              />
              <path
                d="M598.66,621.03c0.01,0.63,0.1,1.27,0,1.89c-0.17,1.04-1.08,1.62-2.11,1.49c-0.98-0.13-1.74-1.02-1.7-1.99
	c0.04-0.98,0.86-1.88,1.85-1.83c0.69,0.03,1.36,0.34,2.05,0.53C598.74,621.11,598.66,621.03,598.66,621.03z"
              />
              <path
                d="M417.18,403.42c0.01,1.01-0.93,1.98-1.96,2c-1.04,0.02-1.95-0.88-1.96-1.96c-0.01-1.09,0.85-1.99,1.92-1.99
	C416.21,401.47,417.17,402.4,417.18,403.42z"
              />
              <path
                d="M419.71,224.73c-0.01,0.63,0.07,1.27-0.04,1.88c-0.19,1.02-1.12,1.59-2.15,1.44c-0.98-0.15-1.72-1.05-1.66-2.02
	c0.06-0.97,0.9-1.86,1.88-1.79c0.69,0.05,1.36,0.37,2.04,0.57C419.79,224.8,419.71,224.73,419.71,224.73z"
              />
              <path
                d="M474.76,484.9c0.06,1.04-0.81,2.03-1.86,2.11c-1.06,0.08-2-0.78-2.06-1.88c-0.06-1.1,0.77-2.05,1.83-2.12
	C473.7,482.96,474.7,483.85,474.76,484.9z"
              />
              <path
                d="M409.68,483.66c-0.04,1.09-0.96,1.95-2.01,1.89c-1.03-0.06-1.93-1.04-1.89-2.06c0.04-1.03,1.03-1.94,2.05-1.9
	C408.9,481.64,409.72,482.56,409.68,483.66z"
              />
              <path
                d="M402.97,531.08c0,1.08-0.89,1.99-1.94,1.98c-1.02-0.01-1.98-0.97-1.98-1.98c0-1.02,0.94-1.97,1.98-1.97
	C402.1,529.11,402.97,529.99,402.97,531.08z"
              />
              <path
                d="M440.91,493.42c-1.07,0.02-1.98-0.83-2.01-1.88c-0.03-1.04,0.85-1.96,1.9-1.99c1.1-0.03,1.95,0.77,1.97,1.88
	C442.8,492.56,442.02,493.4,440.91,493.42z"
              />
              <path
                d="M440.88,470.01c-0.06,1.13-0.92,1.89-2.06,1.83c-1.09-0.06-1.93-0.98-1.86-2.05c0.06-1.07,1.01-1.9,2.09-1.84
	C440.15,468,440.94,468.89,440.88,470.01z"
              />
              <path
                d="M308.47,382.1c-1.07,0.02-1.98-0.82-2.01-1.87c-0.03-1.04,0.84-1.96,1.9-1.99c1.1-0.04,1.95,0.77,1.98,1.88
	C310.37,381.24,309.58,382.07,308.47,382.1z"
              />
              <path
                d="M523.41,379.78c-0.05,1.13-0.9,1.9-2.04,1.85c-1.09-0.05-1.94-0.96-1.88-2.04c0.05-1.07,0.99-1.91,2.07-1.86
	C522.67,377.78,523.46,378.66,523.41,379.78z"
              />
              <path
                d="M404.41,173.3c0.03,1.14-0.76,1.98-1.89,2c-1.1,0.02-2-0.82-2.03-1.89c-0.03-1.07,0.84-1.97,1.92-2
	C403.53,171.37,404.38,172.18,404.41,173.3z"
              />
              <path
                d="M252.3,251.57c0,1.14-0.81,1.95-1.94,1.95c-1.1,0-1.98-0.87-1.98-1.94c0-1.07,0.89-1.95,1.97-1.96
	C251.47,249.61,252.3,250.44,252.3,251.57z"
              />
              <path
                d="M386.89,494.67c-1.05,0.01-1.94-0.91-1.94-1.98c0-1.09,0.87-1.97,1.94-1.97c1.03,0.01,1.98,0.95,1.98,1.97
	C388.87,493.71,387.91,494.67,386.89,494.67z"
              />
              <path
                d="M312.75,448.66c-0.06,1.09-0.99,1.94-2.04,1.86c-1.04-0.08-1.92-1.06-1.85-2.09c0.06-1.03,1.06-1.92,2.08-1.86
	C311.99,446.63,312.81,447.57,312.75,448.66z"
              />
              <path
                d="M356.09,345.12c1.05-0.05,2,0.8,2.06,1.84c0.06,1.05-0.78,1.97-1.86,2.03c-1.12,0.06-1.97-0.71-2.02-1.83
	C354.21,346.05,355,345.17,356.09,345.12z"
              />
              <path
                d="M339.16,504.21c0,1.08-0.91,1.99-1.95,1.98c-1.02-0.01-1.98-0.97-1.98-1.99c0-1.02,0.96-1.98,1.99-1.98
	C338.28,502.23,339.16,503.12,339.16,504.21z"
              />
              <path
                d="M245.72,369.43c-1.04-0.05-1.87-1-1.82-2.08c0.05-1.09,0.95-1.92,2.02-1.85c1.04,0.06,1.92,1.03,1.86,2.05
	C247.72,368.58,246.72,369.48,245.72,369.43z"
              />
              <path
                d="M454.59,461.42c0.05,1.01-0.85,1.99-1.88,2.05c-1.05,0.06-1.96-0.79-2.01-1.88c-0.05-1.1,0.76-2.01,1.83-2.05
	C453.56,459.5,454.55,460.4,454.59,461.42z"
              />
              <path
                d="M459.72,459.07c1.04-0.05,2.04,0.88,2.07,1.91c0.02,1.03-0.94,2.01-1.98,2c-0.99,0-1.81-0.79-1.89-1.8
	C457.83,460.06,458.63,459.13,459.72,459.07z"
              />
              <path
                d="M376.41,495.06c1.04,0.05,1.93,1,1.89,2.03c-0.04,1.01-1.03,1.94-2.04,1.9c-1.05-0.04-1.89-0.97-1.85-2.05
	C374.44,495.85,375.33,495.01,376.41,495.06z"
              />
              <path
                d="M399.59,534.81c0.06,1.08-0.76,2.03-1.81,2.09c-1,0.06-2.01-0.85-2.08-1.86c-0.07-1.02,0.8-1.99,1.85-2.07
	C398.63,532.9,399.53,533.71,399.59,534.81z"
              />
              <path
                d="M313.7,502.72c0.03,1.11-0.85,2.02-1.92,2.01c-1.04-0.01-1.99-0.99-1.96-2.02c0.03-0.95,0.88-1.81,1.85-1.87
	C312.77,500.76,313.67,501.6,313.7,502.72z"
              />
              <path
                d="M453.33,486.94c0,1.1-0.92,2-1.98,1.95c-1.05-0.04-1.96-1.04-1.89-2.08c0.06-0.96,0.93-1.78,1.9-1.82
	C452.46,484.96,453.34,485.82,453.33,486.94z"
              />
              <path
                d="M353.24,63.68c1.02-0.03,2,0.89,2.03,1.9c0.03,1.01-0.89,1.99-1.91,2.03c-1.05,0.04-1.95-0.83-1.99-1.91
	C351.34,64.6,352.17,63.71,353.24,63.68z"
              />
              <path
                d="M437.84,125.17c-1.07,0.06-1.99-0.83-2-1.93c-0.01-1.03,0.71-1.86,1.72-1.96c1.05-0.1,2.07,0.74,2.16,1.79
	C439.79,124.11,438.89,125.12,437.84,125.17z"
              />
              <path
                d="M248.44,374.55c0.05,1.08-0.77,2.03-1.81,2.09c-1,0.06-2.01-0.85-2.07-1.87c-0.06-1.02,0.81-1.99,1.85-2.06
	C247.49,372.63,248.39,373.45,248.44,374.55z"
              />
              <path
                d="M334.77,457.25c-0.04-1.05,0.89-2,1.96-2c1.01-0.01,1.82,0.75,1.9,1.77c0.09,1.11-0.72,2.08-1.79,2.14
	C335.83,459.21,334.8,458.28,334.77,457.25z"
              />
              <path
                d="M359.29,214.83c1.07,0,1.93,0.87,1.93,1.96c0,1.08-0.89,1.98-1.93,1.97c-1.01-0.01-1.97-0.97-1.97-1.97
	C357.31,215.76,358.25,214.83,359.29,214.83z"
              />
              <path
                d="M313.22,455.75c0,1.1-0.92,2-1.98,1.95c-1.05-0.04-1.96-1.05-1.89-2.08c0.06-0.96,0.93-1.78,1.9-1.82
	C312.35,453.77,313.22,454.63,313.22,455.75z"
              />
              <path
                d="M559.63,613.17c-1.08,0-1.94-0.91-1.91-2.02c0.03-1.02,0.8-1.83,1.8-1.89c1.04-0.06,2.05,0.87,2.08,1.9
	C561.63,612.19,560.67,613.17,559.63,613.17z"
              />
              <path
                d="M253.24,139.52c0,1.1-0.9,2-1.97,1.97c-1.05-0.03-1.97-1.03-1.91-2.06c0.05-0.95,0.92-1.79,1.89-1.83
	C252.35,137.55,253.24,138.4,253.24,139.52z"
              />
              <path
                d="M394.25,540.73c-1.02-0.04-1.94-1.02-1.91-2.03c0.03-1.01,1.01-1.93,2.03-1.9c1.07,0.03,1.9,0.93,1.86,2.02
	C396.2,539.9,395.3,540.77,394.25,540.73z"
              />
              <path
                d="M448.05,11.96c-0.57,0.25-1.13,0.62-1.73,0.72c-1.04,0.18-1.93-0.5-2.14-1.45c-0.23-1.05,0.45-2.12,1.49-2.34
	c0.99-0.21,1.97,0.27,2.24,1.28c0.16,0.58,0.04,1.24,0.05,1.87C447.98,12.04,448.05,11.96,448.05,11.96z"
              />
              <path
                d="M344.72,442.31c0,1-0.96,1.96-1.97,1.97c-1.05,0.01-1.93-0.9-1.93-1.97c0-1.09,0.86-1.96,1.93-1.96
	C343.78,440.36,344.72,441.29,344.72,442.31z"
              />
              <path
                d="M413.31,526.57c-0.03,1.06-0.98,1.93-2.05,1.87c-1.1-0.06-1.89-0.97-1.79-2.09c0.09-1.03,0.88-1.76,1.89-1.76
	C412.42,524.59,413.34,525.52,413.31,526.57z"
              />
              <path
                d="M371.56,544.8c-0.03,1.06-0.98,1.92-2.04,1.87c-1.1-0.06-1.88-0.97-1.79-2.08c0.08-1.02,0.88-1.76,1.88-1.76
	C370.67,542.83,371.59,543.76,371.56,544.8z"
              />
              <path
                d="M370.77,248.29c-0.04-1.05,0.86-1.99,1.93-2c1.01-0.01,1.82,0.72,1.92,1.73c0.11,1.11-0.68,2.05-1.77,2.12
	C371.77,250.2,370.81,249.35,370.77,248.29z"
              />
              <path
                d="M286.12,250.41c-1.08,0-1.94-0.9-1.91-2.01c0.03-1.02,0.79-1.82,1.79-1.88c1.04-0.06,2.04,0.86,2.08,1.88
	C288.11,249.42,287.15,250.4,286.12,250.41z"
              />
              <path
                d="M467.05,478.42c0.07,1.05-0.81,2-1.89,2.03c-1.03,0.03-1.84-0.67-1.95-1.68c-0.12-1.11,0.64-2.07,1.72-2.16
	C465.99,476.52,466.99,477.37,467.05,478.42z"
              />
              <path
                d="M418.09,480.94c-1.14,0.01-1.95-0.83-1.91-1.98c0.03-1.03,0.78-1.82,1.79-1.88c1.07-0.06,2.04,0.81,2.07,1.87
	C420.07,480.02,419.18,480.93,418.09,480.94z"
              />
              <path
                d="M424.83,243.94c-0.03,1.07-0.97,1.93-2.06,1.88c-1.11-0.05-1.9-0.96-1.81-2.09c0.08-1.03,0.87-1.76,1.9-1.77
	C423.95,241.96,424.86,242.88,424.83,243.94z"
              />
              <path
                d="M257.62,209.34c1.08,0.03,1.89,0.94,1.83,2.05c-0.06,1.02-0.82,1.76-1.84,1.79c-1.06,0.02-1.99-0.87-1.99-1.91
	C255.62,210.22,256.57,209.31,257.62,209.34z"
              />
              <path
                d="M414.81,524.59c-1.11,0.05-1.96-0.76-1.97-1.88c-0.01-1.02,0.7-1.84,1.69-1.94c1.03-0.1,2.05,0.74,2.13,1.78
	C416.74,523.57,415.87,524.54,414.81,524.59z"
              />
              <path
                d="M433.61,9.79c-0.14-0.45-0.36-0.89-0.4-1.34c-0.09-1.19,0.62-1.95,1.78-1.98c1.17-0.04,1.95,0.71,1.92,1.86
	c-0.02,1.07-0.87,1.82-2.05,1.74c-0.45-0.03-0.9-0.24-1.34-0.37C433.53,9.7,433.61,9.79,433.61,9.79z"
              />
              <path
                d="M350.94,347.8c-0.01,1.01-1.01,1.98-2.03,1.94c-1.07-0.04-1.89-0.98-1.83-2.08c0.06-1.02,0.84-1.78,1.85-1.8
	C349.98,345.83,350.95,346.77,350.94,347.8z"
              />
              <path
                d="M468.93,483.81c-1.12-0.01-1.92-0.86-1.88-1.99c0.04-1.01,0.81-1.8,1.8-1.85c1.05-0.05,2.01,0.84,2.04,1.89
	C470.91,482.91,470,483.82,468.93,483.81z"
              />
              <path
                d="M335.07,65.39c-1.13,0.01-1.94-0.84-1.91-1.97c0.03-1.02,0.78-1.82,1.78-1.88c1.06-0.06,2.03,0.82,2.07,1.87
	C337.05,64.47,336.16,65.39,335.07,65.39z"
              />
              <path
                d="M526.07,368.64c0.01,1.05-0.91,1.95-1.98,1.93c-1.03-0.02-1.79-0.75-1.85-1.78c-0.07-1.1,0.74-2.02,1.82-2.06
	C525.11,366.69,526.06,367.59,526.07,368.64z"
              />
              <path
                d="M495.37,223.32c-0.03,1.06-0.98,1.92-2.06,1.87c-1.1-0.06-1.89-0.98-1.79-2.1c0.09-1.02,0.88-1.76,1.9-1.75
	C494.48,221.34,495.4,222.27,495.37,223.32z"
              />
              <path
                d="M530.87,299.61c-0.03,1.06-0.99,1.92-2.06,1.87c-1.1-0.06-1.89-0.98-1.79-2.1c0.09-1.02,0.88-1.76,1.9-1.75
	C529.99,297.63,530.91,298.56,530.87,299.61z"
              />
              <path
                d="M449.93,138.33c1.09-0.03,1.94,0.82,1.93,1.94c-0.01,1.02-0.73,1.81-1.73,1.89c-1.06,0.08-2.03-0.77-2.09-1.81
	C447.98,139.32,448.88,138.36,449.93,138.33z"
              />
              <path
                d="M435.86,120c0.06,1.05-0.83,1.99-1.9,2.01c-1.02,0.03-1.83-0.68-1.93-1.69c-0.12-1.1,0.66-2.06,1.73-2.14
	C434.81,118.09,435.8,118.95,435.86,120z"
              />
              <path
                d="M447.55,462.2c0.01,1.14-0.82,1.96-1.94,1.94c-1.08-0.03-1.96-0.96-1.9-2.02c0.05-0.96,0.89-1.78,1.87-1.83
	C446.68,460.24,447.54,461.07,447.55,462.2z"
              />
              <path
                d="M313.49,63.46c1.07,0.01,1.98,0.95,1.94,2.01c-0.04,1.07-1,1.92-2.09,1.85c-1.02-0.06-1.75-0.84-1.78-1.89
	C311.54,64.28,312.36,63.45,313.49,63.46z"
              />
              <path
                d="M362.43,381.13c-1.14,0.01-1.95-0.83-1.92-1.98c0.03-1.03,0.78-1.82,1.79-1.88c1.07-0.06,2.04,0.81,2.08,1.87
	C364.41,380.21,363.52,381.12,362.43,381.13z"
              />
              <path
                d="M503.69,569.3c-0.04,1.12-0.91,1.92-2.03,1.84c-0.98-0.07-1.78-0.9-1.81-1.86c-0.03-1.04,0.9-1.97,1.95-1.97
	C502.9,567.3,503.73,568.17,503.69,569.3z"
              />
              <path
                d="M426.44,510.82c0.06,1.11-0.78,2.04-1.85,2.06c-1.02,0.02-2.01-0.95-2.01-1.96c0-0.94,0.83-1.82,1.79-1.91
	C425.48,508.91,426.39,509.7,426.44,510.82z"
              />
              <path
                d="M324.23,458.27c0.06,1.14-0.73,2.01-1.85,2.03c-1.08,0.02-2-0.87-2-1.93c0-0.96,0.8-1.82,1.77-1.91
	C323.25,456.36,324.17,457.16,324.23,458.27z"
              />
              <path
                d="M434.41,497.25c1.05,0.02,1.96,0.97,1.92,1.99c-0.04,1.05-1.01,1.9-2.07,1.84c-1-0.06-1.73-0.85-1.75-1.87
	C432.49,498.1,433.32,497.23,434.41,497.25z"
              />
              <path
                d="M382.6,381.2c-0.04,1.06-1,1.91-2.08,1.85c-1.09-0.07-1.88-1-1.77-2.12c0.1-1.02,0.91-1.75,1.92-1.73
	C381.74,379.21,382.64,380.15,382.6,381.2z"
              />
              <path
                d="M247.03,383.8c-1.02,0.01-2.01-0.97-1.99-1.98c0.01-1.02,1.01-1.95,2.05-1.91c1.01,0.04,1.77,0.81,1.82,1.84
	C248.95,382.87,248.11,383.79,247.03,383.8z"
              />
              <path
                d="M465.12,456.86c0.05,1.04-0.84,1.97-1.91,1.99c-1.01,0.01-1.81-0.7-1.91-1.71c-0.1-1.09,0.69-2.05,1.75-2.12
	C464.09,454.95,465.07,455.83,465.12,456.86z"
              />
              <path
                d="M477.77,188.72c0,1.12-0.85,1.94-1.96,1.9c-0.97-0.04-1.81-0.84-1.86-1.8c-0.06-1.03,0.83-1.99,1.88-2.03
	C476.92,186.76,477.77,187.6,477.77,188.72z"
              />
              <path
                d="M574.47,615.28c1.07-0.02,2,0.89,1.99,1.95c-0.01,1.06-0.94,1.94-2.02,1.9c-1.03-0.04-1.78-0.78-1.83-1.82
	C572.55,616.19,573.37,615.3,574.47,615.28z"
              />
              <path
                d="M397.23,490.04c-1.07,0.02-1.98-0.88-1.97-1.93c0.01-1.04,0.97-1.94,2.01-1.9c0.99,0.04,1.76,0.82,1.81,1.82
	C399.13,489.16,398.33,490.02,397.23,490.04z"
              />
              <path
                d="M265.56,342.26c1.06,0.04,1.95,1.02,1.87,2.05c-0.08,1.05-1.06,1.88-2.13,1.78c-1.01-0.09-1.72-0.88-1.71-1.92
	C263.6,343.05,264.46,342.21,265.56,342.26z"
              />
              <path
                d="M427.88,176.71c-1.07-0.01-1.97-0.93-1.93-1.98c0.04-1.04,1.03-1.92,2.07-1.86c0.99,0.06,1.74,0.86,1.77,1.88
	C429.82,175.88,429,176.72,427.88,176.71z"
              />
              <path
                d="M437.5,497.24c-1.07,0-1.98-0.91-1.95-1.97c0.03-1.05,1-1.93,2.05-1.88c0.99,0.05,1.75,0.85,1.79,1.86
	C439.43,496.4,438.62,497.24,437.5,497.24z"
              />
              <path
                d="M521.94,238.95c-0.05,1.1-0.98,1.95-2.05,1.85c-0.95-0.08-1.79-0.98-1.81-1.91c-0.02-1.03,0.93-1.97,1.99-1.96
	C521.16,236.94,521.98,237.83,521.94,238.95z"
              />
              <path
                d="M296.67,75.94c1.06,0,1.98,0.92,1.96,1.97c-0.02,1.05-0.97,1.93-2.04,1.87c-1.01-0.05-1.76-0.81-1.8-1.84
	C294.75,76.82,295.57,75.95,296.67,75.94z"
              />
              <path
                d="M422.29,234.77c1.04-0.05,2.01,0.84,2.04,1.87c0.03,1.05-0.87,1.96-1.94,1.96c-1.02,0-1.8-0.72-1.88-1.74
	C420.43,235.77,421.23,234.83,422.29,234.77z"
              />
              <path
                d="M365.16,199.98c1.09,0.06,1.87,0.99,1.77,2.12c-0.09,1-0.91,1.77-1.9,1.77c-1.03-0.01-1.99-0.99-1.96-2.01
	C363.12,200.83,364.13,199.92,365.16,199.98z"
              />
              <path
                d="M389.79,546.56c0.07,1.04-0.81,1.98-1.88,2.02c-1.01,0.03-1.82-0.67-1.94-1.68c-0.12-1.1,0.64-2.06,1.71-2.15
	C388.72,544.66,389.72,545.52,389.79,546.56z"
              />
              <path
                d="M475.69,452.72c-0.05,1.05-1.03,1.9-2.09,1.82c-1.09-0.08-1.86-1.02-1.74-2.13c0.1-1.01,0.92-1.74,1.93-1.71
	C474.84,450.72,475.74,451.68,475.69,452.72z"
              />
              <path
                d="M432.01,116.83c-0.05,1.05-1.03,1.89-2.08,1.82c-1.08-0.08-1.85-1.02-1.74-2.12c0.1-1,0.93-1.73,1.92-1.71
	C431.16,114.84,432.07,115.8,432.01,116.83z"
              />
              <path
                d="M283.67,387.85c-1.13-0.03-1.92-0.9-1.86-2.04c0.06-1.03,0.83-1.79,1.85-1.82c1.08-0.03,2.01,0.86,2.02,1.93
	C285.68,386.99,284.77,387.88,283.67,387.85z"
              />
              <path
                d="M350.35,446.85c1.06-0.04,2.01,0.86,2.02,1.91c0.01,1.06-0.91,1.96-1.99,1.94c-1.04-0.02-1.8-0.74-1.86-1.78
	C348.45,447.81,349.26,446.89,350.35,446.85z"
              />
              <path
                d="M378.76,543.92c0.06,1.02-0.86,2.02-1.9,2.06c-0.98,0.04-1.84-0.72-1.95-1.71c-0.13-1.12,0.63-2.07,1.72-2.16
	C377.68,542.02,378.7,542.89,378.76,543.92z"
              />
              <path
                d="M385.97,543.36c-0.03,1.07-0.98,1.93-2.06,1.88c-1.11-0.06-1.89-0.97-1.8-2.1c0.09-1.04,0.87-1.76,1.9-1.76
	C385.08,541.38,386,542.3,385.97,543.36z"
              />
              <path
                d="M335.28,500.87c-0.01,1.1-0.92,1.98-1.99,1.93c-1.05-0.05-1.95-1.05-1.87-2.08c0.07-0.94,0.95-1.76,1.91-1.79
	C334.43,498.89,335.29,499.75,335.28,500.87z"
              />
              <path
                d="M391.16,544.75c-1.12-0.03-1.91-0.9-1.85-2.03c0.06-1.02,0.84-1.79,1.84-1.82c1.06-0.03,2.01,0.87,2.01,1.93
	C393.16,543.89,392.23,544.78,391.16,544.75z"
              />
              <path
                d="M344.36,501.55c1.12-0.03,1.96,0.81,1.95,1.95c-0.01,1.04-0.73,1.83-1.75,1.9c-1.09,0.08-2.05-0.75-2.11-1.83
	C342.39,502.53,343.29,501.58,344.36,501.55z"
              />
              <path
                d="M484.99,539.95c1.09-0.01,1.92,0.86,1.89,1.97c-0.03,1.02-0.77,1.8-1.77,1.86c-1.06,0.06-2.02-0.8-2.06-1.85
	C483.03,540.9,483.94,539.96,484.99,539.95z"
              />
              <path
                d="M400.36,542.15c-0.02,1.02-1.03,1.97-2.05,1.92c-1.07-0.05-1.88-1-1.81-2.1c0.07-1.02,0.86-1.77,1.87-1.78
	C399.43,540.18,400.38,541.12,400.36,542.15z"
              />
              <path
                d="M360.53,541.97c-0.05,1.06-1.02,1.9-2.1,1.83c-1.09-0.08-1.86-1.02-1.75-2.13c0.11-1.01,0.92-1.74,1.93-1.72
	C359.68,539.97,360.58,540.92,360.53,541.97z"
              />
              <path
                d="M454.55,152.71c-1.11,0.01-1.92-0.84-1.89-1.96c0.03-1.01,0.79-1.81,1.77-1.87c1.04-0.06,2.02,0.83,2.05,1.86
	C456.52,151.79,455.62,152.71,454.55,152.71z"
              />
              <path
                d="M419.73,229.57c-0.04-1.03,0.87-1.98,1.92-1.99c1-0.02,1.81,0.71,1.9,1.72c0.1,1.1-0.68,2.04-1.75,2.11
	C420.74,231.48,419.77,230.62,419.73,229.57z"
              />
              <path
                d="M473.3,178.18c-0.01,1.07-0.95,1.94-2.03,1.9c-1.12-0.04-1.91-0.93-1.83-2.07c0.07-1.03,0.85-1.78,1.87-1.79
	C472.39,176.21,473.31,177.12,473.3,178.18z"
              />
              <path
                d="M319.04,500.12c1.08,0.03,1.9,0.94,1.83,2.05c-0.06,1.03-0.82,1.77-1.84,1.79c-1.07,0.03-2-0.87-1.99-1.92
	C317.04,501,317.98,500.09,319.04,500.12z"
              />
              <path
                d="M326.19,499.65c1.09,0.01,1.91,0.89,1.87,2c-0.04,1.02-0.78,1.78-1.8,1.83c-1.06,0.05-2.01-0.83-2.03-1.88
	C324.22,500.57,325.15,499.64,326.19,499.65z"
              />
              <path
                d="M258.89,393.59c-1.12,0.07-1.98-0.73-2.01-1.86c-0.02-1.03,0.67-1.85,1.67-1.97c1.04-0.12,2.06,0.7,2.17,1.75
	C260.82,392.55,259.96,393.53,258.89,393.59z"
              />
              <path
                d="M503.22,229.82c-0.04,1.11-0.97,1.95-2.04,1.86c-0.94-0.08-1.8-0.97-1.82-1.9c-0.02-1.03,0.92-1.97,1.98-1.97
	C502.44,227.82,503.27,228.7,503.22,229.82z"
              />
              <path
                d="M401.41,540.21c-1.04-0.06-1.93-1.07-1.84-2.09c0.09-1.04,1.1-1.86,2.15-1.75c1,0.1,1.7,0.91,1.68,1.94
	C403.39,539.42,402.49,540.27,401.41,540.21z"
              />
              <path
                d="M322.12,350.4c-0.13,1.03-1.18,1.84-2.2,1.69c-1.08-0.16-1.78-1.13-1.6-2.24c0.16-0.99,1.02-1.66,2.03-1.57
	C321.41,348.37,322.25,349.37,322.12,350.4z"
              />
              <path
                d="M343.9,435.21c-0.05,1.1-0.98,1.93-2.05,1.83c-0.94-0.09-1.78-0.99-1.79-1.91c-0.01-1.02,0.95-1.95,1.99-1.94
	C343.16,433.22,343.96,434.09,343.9,435.21z"
              />
              <path
                d="M398.14,81.48c1.11-0.01,1.93,0.84,1.91,1.97c-0.02,1-0.8,1.83-1.77,1.89c-1.01,0.07-2.03-0.86-2.07-1.87
	C396.16,82.44,397.09,81.49,398.14,81.48z"
              />
              <path
                d="M378.82,5.85c-0.01,0.98-0.83,1.79-1.8,1.77c-0.93-0.02-1.83-0.91-1.83-1.81c0-0.92,0.87-1.71,1.87-1.71
	C378.14,4.1,378.83,4.78,378.82,5.85z"
              />
              <path
                d="M349.49,539.13c0.06,1.01-0.86,2.01-1.9,2.04c-0.98,0.03-1.83-0.71-1.93-1.71c-0.12-1.12,0.63-2.05,1.72-2.14
	C348.42,537.24,349.43,538.1,349.49,539.13z"
              />
              <path
                d="M290.06,253.75c-1.07,0.06-1.98-0.79-2-1.89c-0.02-1.03,0.68-1.84,1.68-1.94c1.05-0.11,2.06,0.71,2.16,1.75
	C291.99,252.68,291.1,253.68,290.06,253.75z"
              />
              <path
                d="M423.37,475.22c-0.03,1.04-0.98,1.91-2.03,1.86c-1.08-0.05-1.87-0.98-1.78-2.07c0.08-0.99,0.89-1.75,1.87-1.75
	C422.47,473.26,423.4,474.2,423.37,475.22z"
              />
              <path
                d="M251.57,390.5c1.05,0.01,1.99,0.95,1.95,1.97c-0.04,1.01-1.06,1.95-2.07,1.88c-0.97-0.06-1.75-0.88-1.78-1.88
	C249.64,391.35,250.47,390.49,251.57,390.5z"
              />
              <path
                d="M257.06,348.71c0.02,1.1-0.85,1.99-1.93,1.97c-1.03-0.02-1.97-1.01-1.92-2.02c0.04-0.94,0.89-1.77,1.86-1.82
	C256.18,346.78,257.04,347.59,257.06,348.71z"
              />
              <path
                d="M527.87,57.97c-0.04,0.93-0.96,1.71-1.95,1.67c-1.04-0.05-1.77-0.79-1.77-1.83c-0.01-1.01,0.79-1.85,1.76-1.85
	C526.89,55.97,527.91,57.01,527.87,57.97z"
              />
              <path
                d="M358.81,504.23c-1.03,0-1.99-0.98-1.95-1.99c0.03-1.02,1.03-1.92,2.07-1.87c1,0.05,1.75,0.83,1.78,1.86
	C360.73,503.33,359.88,504.23,358.81,504.23z"
              />
              <path
                d="M343.72,348.16c0.06,1.01-0.87,1.99-1.9,2.02c-0.99,0.03-1.81-0.71-1.92-1.71c-0.12-1.12,0.62-2.04,1.72-2.12
	C342.67,346.28,343.66,347.14,343.72,348.16z"
              />
              <path
                d="M512.15,582.79c0.07,1.02-0.85,2.01-1.89,2.05c-0.98,0.04-1.83-0.71-1.94-1.7c-0.13-1.12,0.62-2.06,1.71-2.15
	C511.08,580.9,512.09,581.76,512.15,582.79z"
              />
              <path
                d="M322.14,390.03c-0.01,1-1.02,1.95-2.03,1.91c-1.07-0.04-1.87-0.96-1.81-2.07c0.06-1.02,0.83-1.75,1.84-1.77
	C321.19,388.09,322.15,389.02,322.14,390.03z"
              />
              <path
                d="M431.77,472.55c-1.02,0.01-2-0.95-1.98-1.95c0.02-1.01,1-1.92,2.04-1.88c1.01,0.04,1.76,0.79,1.8,1.81
	C433.67,471.64,432.85,472.54,431.77,472.55z"
              />
              <path
                d="M335.08,374.47c-0.03,1.04-0.99,1.91-2.04,1.85c-1.07-0.06-1.86-0.99-1.77-2.08c0.09-1,0.9-1.74,1.88-1.74
	C334.19,372.5,335.11,373.44,335.08,374.47z"
              />
              <path
                d="M531.51,266.92c0,1.11-0.84,1.94-1.94,1.91c-1.06-0.03-1.94-0.98-1.87-2.01c0.06-0.94,0.91-1.76,1.86-1.8
	C530.64,264.97,531.5,265.81,531.51,266.92z"
              />
              <path
                d="M461.77,480.46c1.04,0.02,1.95,0.97,1.91,1.99c-0.04,1.04-1.01,1.9-2.06,1.83c-0.99-0.06-1.73-0.85-1.75-1.86
	C459.84,481.31,460.69,480.44,461.77,480.46z"
              />
              <path
                d="M353.5,502.74c0.02,1.1-0.84,1.98-1.92,1.96c-1.03-0.02-1.97-1-1.92-2.01c0.04-0.94,0.89-1.76,1.85-1.81
	C352.63,500.82,353.48,501.62,353.5,502.74z"
              />
              <path
                d="M338.15,410.13c0.02,1.1-0.85,1.99-1.92,1.97c-1.03-0.02-1.97-1-1.92-2.02c0.04-0.94,0.89-1.77,1.85-1.83
	C337.27,408.2,338.13,409.01,338.15,410.13z"
              />
              <path
                d="M423.09,514.76c0,1.1-0.88,1.98-1.95,1.95c-1.04-0.03-1.96-1.02-1.9-2.04c0.06-0.94,0.91-1.76,1.88-1.8
	C422.22,512.81,423.08,513.64,423.09,514.76z"
              />
              <path
                d="M389.79,380.53c-0.07,1.01-1.1,1.89-2.11,1.8c-1.07-0.1-1.82-1.05-1.7-2.15c0.11-1.02,0.9-1.7,1.93-1.67
	C388.97,378.55,389.86,379.5,389.79,380.53z"
              />
              <path
                d="M431.03,501.35c1.07,0.01,1.98,0.93,1.95,1.96c-0.03,1-1.06,1.93-2.06,1.87c-0.97-0.06-1.74-0.87-1.77-1.87
	C429.11,502.19,429.93,501.35,431.03,501.35z"
              />
              <path
                d="M448.78,456.47c1.04,0,1.97,0.94,1.94,1.96c-0.03,1.04-0.98,1.91-2.03,1.86c-0.99-0.05-1.74-0.82-1.78-1.83
	C446.87,457.35,447.7,456.47,448.78,456.47z"
              />
              <path
                d="M420.51,399.64c-0.02,1.01-1.04,1.96-2.04,1.91c-1.07-0.05-1.87-0.99-1.8-2.1c0.07-1.02,0.85-1.76,1.86-1.76
	C419.59,397.68,420.53,398.62,420.51,399.64z"
              />
              <path
                d="M251,110.74c0.03,0.99-0.93,1.99-1.93,2.02c-0.94,0.03-1.8-0.84-1.8-1.81c0-1.01,0.97-2.04,1.91-2.03
	C250.11,108.92,250.97,109.79,251,110.74z"
              />
              <path
                d="M455.65,15.06c-0.13-0.45-0.34-0.89-0.38-1.35c-0.11-1.19,0.62-2.01,1.75-2.05c1.15-0.04,1.99,0.76,1.96,1.88
	c-0.02,1.07-0.9,1.86-2.06,1.77c-0.46-0.03-0.91-0.22-1.36-0.34C455.57,14.97,455.65,15.06,455.65,15.06z"
              />
              <path
                d="M410.11,530.25c0.06,1.11-0.74,1.99-1.83,2.02c-1.06,0.02-1.98-0.87-1.98-1.91c0-0.93,0.81-1.81,1.75-1.9
	C409.11,528.34,410.04,529.16,410.11,530.25z"
              />
              <path
                d="M355.91,218.71c1.11-0.02,1.94,0.83,1.92,1.95c-0.02,1-0.79,1.83-1.77,1.9c-1.01,0.07-2.04-0.84-2.08-1.86
	C353.93,219.68,354.85,218.73,355.91,218.71z"
              />
              <path
                d="M271.27,181.2c0.04,1.01-0.9,1.99-1.92,2c-0.98,0.01-1.8-0.73-1.9-1.73c-0.11-1.12,0.65-2.04,1.74-2.1
	C270.23,179.31,271.22,180.18,271.27,181.2z"
              />
              <path
                d="M526.48,605.2c-1.09-0.02-1.89-0.91-1.83-2.02c0.06-1,0.84-1.78,1.82-1.8c1.04-0.03,1.99,0.88,1.99,1.91
	C528.46,604.33,527.53,605.22,526.48,605.2z"
              />
              <path
                d="M256.25,365.08c-1.07-0.06-1.86-1.02-1.77-2.12c0.08-1.02,0.87-1.74,1.89-1.74c1.05,0.01,1.99,0.95,1.95,1.97
	C258.29,364.21,257.26,365.14,256.25,365.08z"
              />
              <path
                d="M429.62,507c0.05,1.01-0.89,2-1.92,2.02c-0.99,0.02-1.82-0.73-1.92-1.73c-0.11-1.12,0.65-2.05,1.74-2.12
	C428.57,505.1,429.57,505.98,429.62,507z"
              />
              <path
                d="M342.45,500.19c-0.03,1.11-0.91,1.91-2,1.84c-0.96-0.06-1.78-0.89-1.81-1.84c-0.03-1.02,0.89-1.97,1.93-1.97
	C341.65,498.21,342.48,499.08,342.45,500.19z"
              />
              <path
                d="M380.65,549.33c-1.08,0-1.93-0.89-1.89-2c0.03-1.02,0.78-1.8,1.78-1.86c1.04-0.05,2.04,0.85,2.07,1.87
	C382.63,548.36,381.68,549.33,380.65,549.33z"
              />
              <path
                d="M379.44,76.21c-1.03,0-1.99-0.98-1.95-1.99c0.03-1.02,1.03-1.92,2.07-1.87c1,0.05,1.75,0.83,1.78,1.86
	C381.37,75.32,380.52,76.22,379.44,76.21z"
              />
              <path
                d="M395.56,405.36c0,1-0.99,1.96-2,1.94c-1.08-0.03-1.89-0.93-1.84-2.04c0.05-1.02,0.8-1.77,1.82-1.79
	C394.58,403.43,395.55,404.35,395.56,405.36z"
              />
              <path
                d="M236.38,240.05c-0.53,0.09-1.07,0.26-1.6,0.25c-1.14-0.01-1.93-0.88-1.9-1.98c0.03-1.12,0.91-1.92,2.02-1.85
	c1.08,0.07,1.9,0.97,1.8,2.08c-0.05,0.53-0.27,1.05-0.41,1.58C236.29,240.13,236.38,240.05,236.38,240.05z"
              />
              <path
                d="M396.97,546.17c-0.13,1.02-1.18,1.83-2.2,1.68c-1.08-0.16-1.77-1.12-1.6-2.23c0.16-0.99,1-1.65,2.02-1.56
	C396.26,544.15,397.1,545.16,396.97,546.17z"
              />
              <path
                d="M524.79,354.29c-0.02,1.11-0.88,1.92-1.98,1.86c-0.96-0.05-1.79-0.87-1.83-1.82c-0.05-1.02,0.86-1.98,1.9-2
	C523.96,352.32,524.81,353.19,524.79,354.29z"
              />
              <path
                d="M292.56,385.2c0,1.1-0.87,1.97-1.94,1.94c-1.03-0.03-1.95-1.02-1.89-2.03c0.06-0.94,0.91-1.75,1.87-1.79
	C291.71,383.27,292.56,384.08,292.56,385.2z"
              />
              <path
                d="M547.74,79.3c-0.15-0.69-0.41-1.37-0.42-2.06c-0.01-0.95,0.82-1.66,1.69-1.64c0.79,0.02,1.89,1.19,1.95,2.12
	c0.06,0.98-0.53,1.49-1.41,1.59c-0.62,0.07-1.26-0.06-1.89-0.1C547.66,79.23,547.74,79.3,547.74,79.3z"
              />
              <path
                d="M349.63,499.65c0,1.02-0.76,1.83-1.76,1.89c-1.06,0.06-2.03-0.85-2.03-1.9c0-1.04,1-1.97,2.04-1.9
	C348.86,497.8,349.64,498.65,349.63,499.65z"
              />
              <path
                d="M476.92,450.47c-1.03,0.04-2.03-0.94-2.01-1.96c0.03-0.95,0.88-1.79,1.87-1.83c1.05-0.04,1.85,0.66,1.94,1.69
	C478.82,449.49,478.01,450.43,476.92,450.47z"
              />
              <path
                d="M417.97,520.74c-1.09-0.06-1.88-1.02-1.75-2.14c0.12-1,0.99-1.74,1.98-1.67c0.96,0.07,1.8,0.95,1.8,1.9
	C420.01,519.9,419.03,520.8,417.97,520.74z"
              />
              <path
                d="M309.35,432.29c1.05-0.02,1.83,0.68,1.9,1.72c0.08,1.12-0.74,2.03-1.84,2.05c-0.96,0.01-1.86-0.8-1.95-1.75
	C307.37,433.27,308.26,432.31,309.35,432.29z"
              />
              <path
                d="M381.62,493.16c0.01,1.01-1.01,1.97-2.03,1.92c-1-0.05-1.76-0.87-1.76-1.89c-0.01-1.05,0.71-1.83,1.74-1.89
	C380.63,491.24,381.61,492.12,381.62,493.16z"
              />
              <path
                d="M525.26,361.49c-0.02,1.02-0.79,1.82-1.79,1.86c-1.07,0.04-2.02-0.88-1.99-1.93c0.03-1.04,1.03-1.95,2.07-1.86
	C524.53,359.63,525.28,360.48,525.26,361.49z"
              />
              <path
                d="M486.63,435.15c1.08-0.03,2.03,0.89,2,1.94c-0.03,0.94-0.91,1.81-1.86,1.85c-1.1,0.05-1.97-0.83-1.95-1.96
	C484.84,435.94,485.59,435.18,486.63,435.15z"
              />
              <path
                d="M385.15,77.29c0.05,1.04-0.64,1.88-1.65,1.99c-1.06,0.12-2.08-0.73-2.14-1.78c-0.06-1.03,0.88-2.02,1.93-2.02
	C384.28,75.48,385.09,76.27,385.15,77.29z"
              />
              <path
                d="M362.6,191.36c0,1.03-0.76,1.85-1.76,1.91c-1.03,0.06-2.05-0.89-2.05-1.91c0-1.04,0.98-1.94,2.05-1.89
	C361.87,189.53,362.6,190.32,362.6,191.36z"
              />
              <path
                d="M362.32,543.33c1.03-0.05,2.02,0.89,2.01,1.93c-0.01,0.96-0.84,1.81-1.81,1.87c-1.01,0.06-1.85-0.66-1.96-1.67
	C360.44,544.36,361.25,543.38,362.32,543.33z"
              />
              <path
                d="M428.81,373.57c-0.06,1.03-0.85,1.78-1.87,1.78c-1.07,0-2-0.97-1.92-2.01c0.07-1.05,1.12-1.91,2.15-1.78
	C428.16,371.68,428.87,372.55,428.81,373.57z"
              />
              <path
                d="M426.1,111.47c1.07,0.07,1.87,1.06,1.73,2.15c-0.12,1.01-0.98,1.72-1.98,1.65c-0.97-0.07-1.79-0.93-1.79-1.89
	C424.07,112.33,425.06,111.41,426.1,111.47z"
              />
              <path
                d="M408.1,405.83c-1.01,0.06-1.86-0.67-1.96-1.69c-0.12-1.15,0.65-2.06,1.77-2.1c0.98-0.04,1.9,0.72,2.01,1.67
	C410.05,404.74,409.16,405.77,408.1,405.83z"
              />
              <path
                d="M412.8,479.83c-0.05,1.04-1.08,1.91-2.12,1.8c-1-0.1-1.71-0.94-1.67-1.97c0.04-1.13,0.94-1.9,2.08-1.78
	C412.06,477.99,412.85,478.88,412.8,479.83z"
              />
              <path
                d="M423.08,371.98c-1.06-0.01-1.98-0.99-1.9-2.03c0.08-0.95,0.98-1.77,1.93-1.77c1.07,0,1.93,0.94,1.85,2.04
	C424.9,371.25,424.1,372,423.08,371.98z"
              />
              <path
                d="M479.34,515.3c1.03,0.04,1.77,0.8,1.78,1.85c0.01,1.12-0.86,1.98-1.96,1.92c-0.95-0.05-1.81-0.92-1.83-1.86
	C477.3,516.16,478.26,515.25,479.34,515.3z"
              />
              <path
                d="M396.49,352.62c1.09,0.01,2,0.96,1.92,2c-0.07,0.95-0.97,1.78-1.93,1.79c-1.09,0-1.94-0.91-1.88-2.03
	C394.66,353.34,395.44,352.62,396.49,352.62z"
              />
              <path
                d="M518.04,381.62c1-0.04,1.85,0.71,1.94,1.72c0.1,1.11-0.72,2.05-1.81,2.08c-0.98,0.03-1.88-0.75-1.98-1.72
	C516.08,382.69,516.99,381.66,518.04,381.62z"
              />
              <path
                d="M466.54,455c-1.02,0-1.81-0.75-1.87-1.79c-0.06-1.11,0.8-2.03,1.89-2.02c0.96,0.01,1.85,0.85,1.91,1.8
	C468.54,454.04,467.61,455,466.54,455z"
              />
              <path
                d="M532.28,606.49c-0.04,1.03-1.07,1.92-2.1,1.82c-1-0.09-1.72-0.93-1.69-1.95c0.03-1.13,0.92-1.91,2.06-1.8
	C531.53,604.65,532.32,605.54,532.28,606.49z"
              />
              <path
                d="M292.55,219.07c0.06,1.12-0.79,2.03-1.88,2.03c-0.96,0-1.86-0.84-1.92-1.79c-0.08-1.05,0.84-1.99,1.93-2
	C291.71,217.3,292.49,218.03,292.55,219.07z"
              />
              <path
                d="M361.3,196.67c1.03,0.04,1.77,0.81,1.79,1.86c0.01,1.12-0.88,1.99-1.97,1.93c-0.96-0.05-1.82-0.94-1.84-1.88
	C359.25,197.54,360.21,196.63,361.3,196.67z"
              />
              <path
                d="M375.37,548.03c-0.09,1.05-1.13,1.87-2.18,1.72c-1.01-0.14-1.68-0.98-1.61-2.01c0.08-1.12,1-1.87,2.13-1.71
	C374.69,546.16,375.45,547.08,375.37,548.03z"
              />
              <path
                d="M347.24,431.29c-0.01,1.02-0.77,1.84-1.76,1.89c-1.02,0.05-2.04-0.91-2.03-1.92c0.01-1.04,0.98-1.93,2.06-1.86
	C346.53,429.47,347.25,430.25,347.24,431.29z"
              />
              <path
                d="M357.77,244.13c-1.06-0.05-1.96-1.07-1.85-2.09c0.1-0.96,1.01-1.73,1.99-1.7c1.13,0.03,1.9,0.93,1.8,2.08
	C359.62,243.43,358.78,244.17,357.77,244.13z"
              />
              <path
                d="M247.72,390.96c-1.01,0-1.81-0.78-1.86-1.8c-0.06-1.15,0.75-2,1.89-1.99c0.98,0.01,1.86,0.83,1.92,1.78
	C249.73,389.97,248.77,390.97,247.72,390.96z"
              />
              <path
                d="M308.68,445.49c-0.04,1.06-1.04,1.93-2.1,1.83c-1.01-0.09-1.73-0.92-1.69-1.96c0.03-1.01,0.83-1.82,1.82-1.85
	C307.75,443.49,308.72,444.45,308.68,445.49z"
              />
              <path
                d="M320.88,521.74c0.98-0.01,1.81,0.77,1.88,1.78c0.07,1.12-0.76,2.02-1.86,2.02c-0.97,0-1.85-0.8-1.92-1.76
	C318.91,522.74,319.84,521.75,320.88,521.74z"
              />
              <path
                d="M353.3,376.35c-0.01,1.05-0.99,1.95-2.05,1.88c-1-0.07-1.74-0.89-1.73-1.91c0.01-1,0.79-1.84,1.77-1.89
	C352.32,374.37,353.31,375.31,353.3,376.35z"
              />
              <path
                d="M255.67,191.57c-1.07,0.01-2-0.93-1.95-1.98c0.05-0.95,0.92-1.8,1.88-1.82c1.08-0.03,1.95,0.88,1.9,1.99
	C257.46,190.78,256.68,191.55,255.67,191.57z"
              />
              <path
                d="M362.03,207.65c-1.01,0.06-1.85-0.66-1.95-1.67c-0.12-1.14,0.64-2.04,1.76-2.09c0.98-0.04,1.89,0.71,2.01,1.66
	C363.97,206.56,363.09,207.59,362.03,207.65z"
              />
              <path
                d="M417.3,363.64c-0.01,1.02-0.77,1.84-1.76,1.89c-1.02,0.05-2.04-0.91-2.03-1.92c0.01-1.04,0.98-1.93,2.06-1.86
	C416.58,361.81,417.3,362.59,417.3,363.64z"
              />
              <path
                d="M355.22,231.96c-0.03,1.05-1.03,1.93-2.09,1.83c-1-0.09-1.72-0.92-1.69-1.95c0.03-1,0.83-1.82,1.81-1.85
	C354.28,229.96,355.25,230.92,355.22,231.96z"
              />
              <path
                d="M479.76,526.01c-1.07,0.01-2-0.93-1.95-1.98c0.05-0.95,0.92-1.8,1.88-1.82c1.08-0.03,1.95,0.88,1.9,1.99
	C481.55,525.23,480.76,526,479.76,526.01z"
              />
              <path
                d="M281.02,209.33c0.01,1.03-0.74,1.85-1.74,1.92c-1.06,0.07-2.04-0.83-2.05-1.89c-0.01-1.04,0.98-1.98,2.03-1.93
	C280.23,207.49,281.01,208.32,281.02,209.33z"
              />
              <path
                d="M393.13,356.46c1.09,0.01,2,0.96,1.92,2c-0.07,0.95-0.97,1.78-1.93,1.79c-1.09,0-1.94-0.91-1.88-2.03
	C391.31,357.18,392.08,356.46,393.13,356.46z"
              />
              <path
                d="M421.13,367.13c-0.07,1.02-0.89,1.78-1.9,1.76c-1.05-0.02-1.99-1.03-1.9-2.05c0.09-1.06,1.11-1.86,2.19-1.72
	C420.56,365.25,421.21,366.06,421.13,367.13z"
              />
              <path
                d="M478.13,500.91c0.99,0.04,1.83,0.86,1.86,1.82c0.03,1.01-0.97,1.99-2,1.95c-1.09-0.04-1.89-0.97-1.79-2.09
	C476.29,501.56,477.08,500.87,478.13,500.91z"
              />
              <path
                d="M456.02,455.79c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C454.18,456.48,454.97,455.77,456.02,455.79z"
              />
              <path
                d="M477.31,493.7c1.05-0.01,1.83,0.71,1.89,1.75c0.07,1.12-0.77,2.04-1.86,2.04c-0.96,0-1.86-0.82-1.94-1.78
	C475.32,494.67,476.22,493.71,477.31,493.7z"
              />
              <path
                d="M512.45,396.77c1.04,0.04,1.77,0.81,1.79,1.86c0.01,1.12-0.88,1.99-1.97,1.93c-0.96-0.05-1.82-0.94-1.84-1.88
	C510.41,397.63,511.37,396.72,512.45,396.77z"
              />
              <path
                d="M301.12,382.8c-1.06-0.07-1.94-1.1-1.81-2.12c0.12-0.96,1.04-1.71,2.02-1.67c1.12,0.05,1.89,0.97,1.76,2.11
	C302.98,382.13,302.12,382.86,301.12,382.8z"
              />
              <path
                d="M478.57,508.09c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C476.74,508.79,477.52,508.07,478.57,508.09z"
              />
              <path
                d="M482.08,444.83c-0.06,1.02-0.86,1.8-1.87,1.79c-1.05,0-2-1-1.94-2.02c0.07-1.04,1.1-1.88,2.17-1.76
	C481.47,442.96,482.14,443.77,482.08,444.83z"
              />
              <path
                d="M417.53,97.83c1.08-0.03,2.03,0.89,2,1.94c-0.03,0.94-0.91,1.81-1.86,1.85c-1.1,0.05-1.97-0.83-1.95-1.96
	C415.74,98.61,416.49,97.85,417.53,97.83z"
              />
              <path
                d="M489.61,550.32c1.09,0.02,1.99,0.99,1.89,2.03c-0.09,0.95-1,1.77-1.95,1.76c-1.1-0.01-1.92-0.94-1.84-2.06
	C487.77,551.01,488.56,550.3,489.61,550.32z"
              />
              <path
                d="M442.4,131.89c1.03,0.04,1.77,0.82,1.78,1.87c0.01,1.12-0.88,1.99-1.98,1.92c-0.95-0.06-1.82-0.94-1.83-1.88
	C440.35,132.75,441.32,131.85,442.4,131.89z"
              />
              <path
                d="M259.52,357.14c0.99,0.02,1.79,0.83,1.82,1.84c0.04,1.13-0.82,2-1.93,1.96c-0.98-0.03-1.83-0.86-1.87-1.83
	C257.51,358.08,258.47,357.12,259.52,357.14z"
              />
              <path
                d="M386.41,550.62c-0.06,1.06-1.08,1.9-2.14,1.78c-1.01-0.12-1.71-0.95-1.65-1.99c0.05-1.01,0.87-1.8,1.86-1.8
	C385.53,548.6,386.48,549.58,386.41,550.62z"
              />
              <path
                d="M260.21,344.97c-0.04,1.02-1.07,1.92-2.1,1.83c-1-0.09-1.73-0.92-1.7-1.95c0.03-1.13,0.91-1.91,2.05-1.81
	C259.45,343.14,260.24,344.01,260.21,344.97z"
              />
              <path
                d="M391.63,548.13c1.04-0.03,2.01,0.94,1.97,1.97c-0.03,0.96-0.88,1.79-1.85,1.83c-1.11,0.04-1.96-0.83-1.93-1.95
	C389.85,548.97,390.66,548.15,391.63,548.13z"
              />
              <path
                d="M371.88,322.35c-1.03,0.06-2.05-0.89-2.05-1.91c0-0.96,0.82-1.81,1.81-1.88c1.04-0.07,1.87,0.61,1.99,1.64
	C373.75,321.31,372.96,322.29,371.88,322.35z"
              />
              <path
                d="M607.84,623.26c1.05-0.01,1.81,0.71,1.87,1.75c0.06,1.12-0.77,2.02-1.87,2.02c-0.95,0-1.85-0.83-1.92-1.78
	C605.85,624.21,606.75,623.27,607.84,623.26z"
              />
              <path
                d="M406.74,534.54c-0.05,1.02-0.83,1.8-1.84,1.81c-1.03,0.01-2.01-0.98-1.96-1.99c0.05-1.03,1.07-1.88,2.14-1.77
	C406.11,532.68,406.78,533.48,406.74,534.54z"
              />
              <path
                d="M393.8,363.66c1.05-0.02,1.83,0.69,1.91,1.73c0.08,1.12-0.75,2.05-1.84,2.06c-0.95,0.01-1.87-0.81-1.95-1.76
	C391.82,364.65,392.71,363.69,393.8,363.66z"
              />
              <path
                d="M299.33,234.75c-1.06,0.01-1.99-0.94-1.94-1.97c0.05-0.94,0.93-1.79,1.87-1.81c1.07-0.03,1.94,0.89,1.9,1.98
	C301.12,233.96,300.34,234.73,299.33,234.75z"
              />
              <path
                d="M406.12,546.7c1.02,0.02,1.96,1.05,1.86,2.05c-0.09,0.94-0.98,1.75-1.93,1.73c-1.07-0.01-1.91-0.95-1.82-2.04
	C404.31,547.46,405.16,546.67,406.12,546.7z"
              />
              <path
                d="M262.43,183.88c-1.04,0.04-2-0.9-1.97-1.93c0.02-0.93,0.89-1.8,1.82-1.85c1.06-0.05,1.95,0.85,1.93,1.93
	C264.19,183.04,263.41,183.84,262.43,183.88z"
              />
              <path
                d="M368.16,548.7c-0.06,1.04-1.09,1.89-2.12,1.77c-0.98-0.12-1.69-0.97-1.63-1.98c0.05-0.98,0.88-1.79,1.84-1.79
	C367.26,546.69,368.22,547.69,368.16,548.7z"
              />
              <path
                d="M349.61,459.77c0.03,1-0.71,1.84-1.68,1.92c-1.03,0.09-2.02-0.79-2.05-1.83c-0.03-1,0.96-1.98,1.96-1.94
	C348.78,457.97,349.59,458.8,349.61,459.77z"
              />
              <path
                d="M467.47,485.79c-0.03,1.03-1.03,1.91-2.06,1.82c-0.97-0.09-1.71-0.93-1.68-1.93c0.03-0.97,0.84-1.81,1.78-1.84
	C466.53,483.81,467.51,484.78,467.47,485.79z"
              />
              <path
                d="M261.55,338.92c1.02-0.06,2.02,0.89,2.01,1.92c-0.01,0.95-0.83,1.81-1.8,1.87c-0.99,0.06-1.85-0.66-1.96-1.66
	C259.68,339.97,260.49,338.98,261.55,338.92z"
              />
              <path
                d="M345.59,496.34c-0.03,1.03-1.03,1.92-2.06,1.82c-0.97-0.09-1.71-0.93-1.68-1.92c0.03-0.97,0.83-1.81,1.78-1.84
	C344.64,494.36,345.62,495.34,345.59,496.34z"
              />
              <path
                d="M438.52,460.81c1.04,0.03,1.88,1.01,1.79,2.07c-0.09,0.99-0.93,1.73-1.91,1.7c-0.95-0.04-1.8-0.88-1.83-1.82
	C436.53,461.75,437.51,460.78,438.52,460.81z"
              />
              <path
                d="M380.59,585.09c1.01-0.03,1.98,0.95,1.95,1.95c-0.03,0.94-0.88,1.78-1.83,1.82c-0.98,0.04-1.82-0.71-1.91-1.7
	C378.71,586.1,379.55,585.12,380.59,585.09z"
              />
              <path
                d="M427.75,378.76c1.02,0.05,1.93,1.09,1.81,2.09c-0.11,0.95-1.02,1.73-1.97,1.69c-1.06-0.04-1.89-1-1.78-2.08
	C425.92,379.48,426.79,378.71,427.75,378.76z"
              />
              <path
                d="M284.1,391.22c1.02-0.06,2.02,0.89,2.01,1.92c-0.01,0.95-0.83,1.81-1.8,1.87c-0.99,0.06-1.85-0.66-1.96-1.66
	C282.23,392.27,283.04,391.28,284.1,391.22z"
              />
              <path
                d="M411.99,531.82c0.97-0.05,1.84,0.71,1.94,1.7c0.11,1.09-0.71,2.05-1.79,2.09c-0.96,0.04-1.87-0.74-1.98-1.7
	C410.05,532.91,410.95,531.87,411.99,531.82z"
              />
              <path
                d="M365.09,239.6c0.95,0.02,1.77,0.85,1.8,1.83c0.04,1.1-0.82,1.99-1.91,1.95c-0.96-0.03-1.81-0.87-1.85-1.81
	C363.1,240.55,364.07,239.57,365.09,239.6z"
              />
              <path
                d="M264.04,387.77c0.05,1.04-0.89,1.99-1.95,1.97c-1-0.02-1.78-0.8-1.82-1.81c-0.04-1,0.69-1.87,1.67-1.97
	C262.96,385.84,263.99,386.73,264.04,387.77z"
              />
              <path
                d="M420.45,526.05c-0.01,1.03-0.99,1.94-2.02,1.87c-0.97-0.07-1.73-0.9-1.72-1.89c0.01-0.97,0.8-1.82,1.74-1.88
	C419.45,524.09,420.46,525.05,420.45,526.05z"
              />
              <path
                d="M333.14,352.78c0.03,1.04-0.93,1.97-1.99,1.93c-0.99-0.04-1.76-0.84-1.78-1.85c-0.02-0.99,0.74-1.86,1.7-1.94
	C332.09,350.83,333.11,351.75,333.14,352.78z"
              />
              <path
                d="M523.92,54.89c-0.05,0.93-1,1.75-1.93,1.66c-0.96-0.09-1.71-0.97-1.66-1.95c0.06-1.07,0.82-1.72,1.89-1.61
	C523.22,53.09,523.97,53.93,523.92,54.89z"
              />
              <path
                d="M520.53,599.95c-0.01-1.01,1-1.97,2-1.91c0.95,0.06,1.74,0.91,1.75,1.89c0.01,1-0.75,1.83-1.73,1.9
	C521.52,601.89,520.54,600.98,520.53,599.95z"
              />
              <path
                d="M411.31,362.39c-0.99-0.02-1.78-0.81-1.81-1.82c-0.04-1.1,0.84-2,1.91-1.97c0.95,0.03,1.82,0.89,1.86,1.83
	C413.31,361.46,412.37,362.41,411.31,362.39z"
              />
              <path
                d="M388.44,555.75c-0.97-0.06-1.79-0.93-1.8-1.88c-0.01-1.02,1-1.97,2.02-1.91c1.06,0.06,1.87,1.05,1.75,2.13
	C390.29,555.1,389.44,555.82,388.44,555.75z"
              />
              <path
                d="M269.97,333.24c-0.05,1-0.85,1.78-1.84,1.78c-1.04,0.01-1.98-0.96-1.91-1.99c0.06-0.93,0.94-1.78,1.88-1.79
	C269.15,331.23,270.02,332.16,269.97,333.24z"
              />
              <path
                d="M480.51,533.2c-1,0.03-1.83-0.71-1.91-1.72c-0.09-1.07,0.76-2.05,1.81-2.07c0.94-0.02,1.86,0.8,1.96,1.73
	C482.47,532.17,481.57,533.17,480.51,533.2z"
              />
              <path
                d="M351.2,457.87c-1.05,0.04-2.01-0.89-1.98-1.93c0.02-0.94,0.89-1.81,1.83-1.86c1.07-0.05,1.96,0.84,1.94,1.94
	C352.97,457.04,352.2,457.84,351.2,457.87z"
              />
              <path
                d="M526.2,328.73c0.05,1.09-0.8,1.99-1.87,1.97c-0.95-0.02-1.81-0.85-1.86-1.78c-0.05-1,0.9-1.99,1.91-1.98
	C525.32,326.94,526.15,327.77,526.2,328.73z"
              />
              <path
                d="M303.39,507.41c-0.05,1.05-1.06,1.9-2.11,1.78c-1-0.11-1.7-0.94-1.65-1.96c0.04-1,0.85-1.79,1.83-1.8
	C302.5,505.42,303.44,506.39,303.39,507.41z"
              />
              <path
                d="M381.24,556.23c-0.97-0.06-1.79-0.93-1.8-1.88c-0.01-1.02,1-1.97,2.02-1.91c1.06,0.06,1.87,1.05,1.75,2.13
	C383.09,555.58,382.24,556.3,381.24,556.23z"
              />
              <path
                d="M317,498.66c0.01,1.01-0.73,1.84-1.72,1.92c-1.05,0.08-2.04-0.82-2.05-1.86c-0.02-1.02,0.97-1.98,2-1.93
	C316.19,496.83,316.98,497.67,317,498.66z"
              />
              <path
                d="M251.73,188.19c-1.05-0.05-1.94-1.06-1.83-2.07c0.1-0.92,1.05-1.74,1.96-1.71c1.05,0.04,1.89,1.02,1.79,2.08
	C253.56,187.49,252.71,188.24,251.73,188.19z"
              />
              <path
                d="M350.63,410.84c-1.05-0.02-1.96-1-1.88-2.02c0.08-0.93,0.99-1.76,1.92-1.76c1.05,0.01,1.91,0.96,1.84,2.03
	C352.43,410.1,351.62,410.86,350.63,410.84z"
              />
              <path
                d="M309.87,336.51c-0.01-1.02,0.99-1.97,2.01-1.92c0.97,0.06,1.75,0.9,1.76,1.89c0.01,1.02-0.74,1.84-1.73,1.9
	C310.86,338.47,309.88,337.56,309.87,336.51z"
              />
              <path
                d="M473.05,443.53c0.95,0.02,1.77,0.85,1.8,1.83c0.04,1.1-0.82,1.99-1.91,1.95c-0.96-0.03-1.81-0.87-1.85-1.81
	C471.07,444.48,472.04,443.5,473.05,443.53z"
              />
              <path
                d="M387.04,557.72c0,0.99-0.77,1.82-1.74,1.87c-1.03,0.06-2-0.86-2-1.89c0.01-1,1.02-1.95,2.02-1.88
	C386.26,555.88,387.05,556.75,387.04,557.72z"
              />
              <path
                d="M408.64,535.66c0.98-0.04,1.83,0.73,1.92,1.72c0.1,1.09-0.73,2.04-1.8,2.07c-0.96,0.03-1.86-0.76-1.97-1.71
	C406.69,536.73,407.6,535.7,408.64,535.66z"
              />
              <path
                d="M328.99,389.28c0,1-0.75,1.83-1.73,1.89c-1.04,0.07-2.02-0.85-2.02-1.88c0-1.01,1-1.97,2.01-1.91
	C328.2,387.45,328.98,388.3,328.99,389.28z"
              />
              <path
                d="M312.92,352.78c-0.97-0.04-1.75-0.85-1.77-1.84c-0.02-1.07,0.88-1.98,1.92-1.93c0.93,0.05,1.79,0.92,1.81,1.84
	C314.91,351.88,313.95,352.82,312.92,352.78z"
              />
              <path
                d="M426.4,471.42c-0.04,1-0.84,1.79-1.82,1.8c-1.04,0.01-1.98-0.94-1.93-1.97c0.05-0.93,0.93-1.79,1.86-1.81
	C425.56,469.42,426.44,470.34,426.4,471.42z"
              />
              <path
                d="M391.85,488.5c0.07,1.11-0.77,2.01-1.87,2c-0.96-0.01-1.84-0.82-1.9-1.77c-0.07-1.01,0.88-2.01,1.91-2.02
	C390.96,486.71,391.8,487.51,391.85,488.5z"
              />
              <path
                d="M516.58,52.93c-0.1-0.3-0.19-0.6-0.29-0.9c-0.33-0.98-0.05-1.87,0.89-2.15c0.55-0.17,1.34,0.17,1.91,0.48
	c0.72,0.39,0.91,1.15,0.51,1.86c-0.46,0.84-1.19,1.27-2.2,0.9c-0.3-0.11-0.6-0.19-0.91-0.28C516.5,52.85,516.58,52.93,516.58,52.93z
	"
              />
              <path
                d="M265.04,335.09c1.02,0.02,1.96,1.04,1.87,2.04c-0.08,0.94-0.98,1.75-1.93,1.74c-1.07-0.01-1.91-0.94-1.82-2.03
	C263.23,335.86,264.09,335.07,265.04,335.09z"
              />
              <path
                d="M260.18,384.5c-0.01,1.03-0.99,1.94-2.02,1.87c-0.97-0.07-1.73-0.9-1.72-1.89c0.01-0.97,0.8-1.82,1.74-1.88
	C259.18,382.53,260.19,383.49,260.18,384.5z"
              />
              <path
                d="M286.75,194.51c-0.02,0.99-0.8,1.8-1.77,1.84c-1.03,0.04-1.99-0.9-1.97-1.92c0.03-1.01,1.05-1.94,2.05-1.85
	C286.01,192.67,286.78,193.54,286.75,194.51z"
              />
              <path
                d="M307.52,450.73c1.04,0.03,1.88,1.01,1.79,2.07c-0.09,0.99-0.93,1.73-1.91,1.7c-0.95-0.04-1.8-0.88-1.83-1.82
	C305.54,451.68,306.52,450.7,307.52,450.73z"
              />
              <path
                d="M343.95,438.24c-0.01-1.01,1-1.97,2-1.91c0.95,0.06,1.74,0.91,1.75,1.89c0.01,1-0.76,1.83-1.73,1.9
	C344.94,440.19,343.96,439.27,343.95,438.24z"
              />
              <path
                d="M471.51,489.18c-0.04,1.01-0.84,1.79-1.83,1.81c-1.06,0.01-1.99-0.94-1.94-1.97c0.05-0.94,0.93-1.79,1.87-1.81
	C470.68,487.17,471.55,488.08,471.51,489.18z"
              />
              <path
                d="M552.47,610.04c1.02,0.02,1.95,1.04,1.86,2.03c-0.08,0.94-0.97,1.74-1.92,1.73c-1.06-0.01-1.9-0.95-1.82-2.02
	C550.66,610.82,551.52,610.02,552.47,610.04z"
              />
              <path
                d="M411.33,544.67c0.05,1.02-0.89,1.98-1.92,1.96c-0.97-0.01-1.77-0.81-1.81-1.8c-0.04-0.98,0.69-1.85,1.65-1.96
	C410.24,542.76,411.28,543.65,411.33,544.67z"
              />
              <path
                d="M265.64,116.68c0.03,1-0.7,1.84-1.68,1.93c-1.03,0.09-2.03-0.79-2.06-1.82c-0.03-1,0.95-1.98,1.95-1.95
	C264.81,114.88,265.61,115.71,265.64,116.68z"
              />
              <path
                d="M417.1,529.92c-0.02,1.04-1.02,1.93-2.05,1.84c-0.98-0.08-1.72-0.92-1.7-1.92c0.02-0.98,0.82-1.82,1.78-1.86
	C416.14,527.94,417.12,528.9,417.1,529.92z"
              />
              <path
                d="M471.5,449.3c-0.02,1-0.8,1.81-1.78,1.85c-1.04,0.04-2-0.9-1.98-1.93c0.02-1.02,1.04-1.94,2.05-1.85
	C470.76,447.45,471.52,448.32,471.5,449.3z"
              />
              <path
                d="M322.39,496.32c1.04,0.03,1.89,1,1.8,2.06c-0.08,0.99-0.92,1.74-1.9,1.71c-0.95-0.03-1.8-0.87-1.84-1.81
	C320.41,497.27,321.38,496.29,322.39,496.32z"
              />
              <path
                d="M315.09,330.78c0.94-0.03,1.81,0.76,1.89,1.72c0.09,1.07-0.74,2.02-1.8,2.04c-0.94,0.02-1.84-0.78-1.94-1.71
	C313.15,331.84,314.07,330.81,315.09,330.78z"
              />
              <path
                d="M371.03,497.69c0.03,1.03-0.93,1.97-1.97,1.93c-0.98-0.04-1.76-0.85-1.78-1.85c-0.02-0.98,0.74-1.85,1.7-1.94
	C369.99,495.75,371.01,496.66,371.03,497.69z"
              />
              <path
                d="M242.73,373.38c-0.95,0.02-1.85-0.85-1.88-1.81c-0.02-0.94,0.85-1.87,1.8-1.89c0.96-0.03,1.83,0.82,1.85,1.81
	C244.52,372.48,243.7,373.35,242.73,373.38z"
              />
              <path
                d="M522.62,372.53c-0.03,0.95-0.9,1.8-1.84,1.81c-1.05,0.01-1.93-0.96-1.84-2.02c0.08-0.97,0.99-1.76,1.93-1.7
	C521.79,370.69,522.65,371.62,522.62,372.53z"
              />
              <path
                d="M298.65,223.8c0.92,0.04,1.79,0.94,1.79,1.86c0,1.04-1.01,1.95-2.03,1.84c-0.97-0.1-1.7-0.98-1.64-1.97
	C296.84,224.58,297.71,223.77,298.65,223.8z"
              />
              <path
                d="M354.4,224.72c-0.01,0.99-0.82,1.83-1.79,1.85c-0.95,0.02-1.85-0.82-1.91-1.77c-0.06-1.01,0.94-2.02,1.95-1.98
	C353.6,222.86,354.41,223.74,354.4,224.72z"
              />
              <path
                d="M426.87,518.23c-0.05,1.03-0.85,1.76-1.88,1.73c-0.98-0.03-1.84-0.87-1.85-1.83c-0.01-1.05,0.97-1.93,2.06-1.85
	C426.22,516.38,426.92,517.19,426.87,518.23z"
              />
              <path
                d="M312.99,36.85c0,0.94-0.84,1.8-1.8,1.83c-1.09,0.04-1.94-0.83-1.88-1.94c0.06-1.03,0.84-1.73,1.87-1.7
	C312.16,35.08,312.99,35.9,312.99,36.85z"
              />
              <path
                d="M684.04,627.35c0.94-0.06,1.84,0.73,1.92,1.69c0.08,0.96-0.65,1.88-1.6,2c-1.02,0.13-2.04-0.75-2.07-1.79
	C682.27,628.33,683.12,627.41,684.04,627.35z"
              />
              <path
                d="M506.26,581.64c-0.95-0.06-1.74-0.94-1.71-1.91c0.03-0.97,0.87-1.81,1.81-1.81c1,0,1.95,1.04,1.85,2.03
	C508.12,580.89,507.17,581.7,506.26,581.64z"
              />
              <path
                d="M416.1,475.73c-0.04,0.95-0.92,1.79-1.87,1.79c-1.05,0-1.92-0.99-1.81-2.05c0.1-0.97,1.01-1.75,1.95-1.67
	C415.29,473.87,416.14,474.82,416.1,475.73z"
              />
              <path
                d="M258.7,370.15c-0.03,0.94-0.91,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.82-2.02c0.09-0.96,0.99-1.75,1.93-1.68
	C257.88,368.3,258.73,369.23,258.7,370.15z"
              />
              <path
                d="M396.15,558.53c1.05-0.05,1.85,0.65,1.93,1.68c0.07,1.03-0.63,1.88-1.64,1.98c-1.07,0.11-2.08-0.76-2.1-1.81
	C394.32,559.42,395.15,558.57,396.15,558.53z"
              />
              <path
                d="M448,137.02c0.06,1.11-0.77,2-1.86,2c-0.96,0-1.84-0.81-1.91-1.76c-0.08-1.04,0.82-1.97,1.91-1.98
	C447.18,135.27,447.94,135.99,448,137.02z"
              />
              <path
                d="M390.87,561.03c0.02,0.99-0.77,1.85-1.74,1.9c-1.04,0.05-2.02-0.92-1.96-1.95c0.05-0.93,0.97-1.8,1.9-1.79
	C390.02,559.2,390.85,560.05,390.87,561.03z"
              />
              <path
                d="M432.12,369.63c-0.01,0.99-0.86,1.84-1.83,1.83c-0.95-0.01-1.83-0.91-1.82-1.86c0.01-0.94,0.92-1.84,1.86-1.83
	C431.29,367.77,432.13,368.64,432.12,369.63z"
              />
              <path
                d="M385.59,583.02c0.06,1.02-0.67,1.89-1.66,1.98c-1.05,0.1-2.06-0.81-2.06-1.87c0-0.93,0.88-1.84,1.82-1.88
	C384.66,581.22,385.53,582.04,385.59,583.02z"
              />
              <path
                d="M381.85,559.68c0.95,0,1.79,0.85,1.82,1.82c0.03,0.98-0.76,1.85-1.72,1.91c-1.02,0.06-2.01-0.9-1.97-1.93
	C380.02,560.55,380.93,559.68,381.85,559.68z"
              />
              <path
                d="M529.88,245.49c0.05,1-0.91,1.98-1.95,1.97c-1.01-0.01-1.78-0.77-1.83-1.79c-0.05-1.06,0.62-1.84,1.65-1.95
	C528.82,243.62,529.83,244.45,529.88,245.49z"
              />
              <path
                d="M409.09,563.06c-0.04,0.98-0.87,1.79-1.83,1.78c-0.94-0.01-1.81-0.87-1.83-1.81c-0.03-1.01,0.99-1.99,1.98-1.91
	C408.35,561.2,409.13,562.1,409.09,563.06z"
              />
              <path
                d="M455.49,222.92c0.06,0.93-0.78,1.87-1.74,1.96c-1.03,0.09-1.89-0.6-2.01-1.62c-0.13-1.15,0.65-2.05,1.79-2.07
	C454.53,221.17,455.43,221.97,455.49,222.92z"
              />
              <path
                d="M552.09,377.2c0.04,0.98-0.72,1.86-1.68,1.94c-1.03,0.08-2.02-0.85-2-1.89c0.02-0.92,0.91-1.82,1.83-1.83
	C551.19,375.41,552.04,376.23,552.09,377.2z"
              />
              <path
                d="M239.76,254.28c1.05-0.03,1.82,0.66,1.9,1.7c0.09,1.12-0.72,2.02-1.81,2.04c-1.03,0.02-2.01-0.96-1.97-1.96
	C237.92,255.11,238.77,254.31,239.76,254.28z"
              />
              <path
                d="M417.5,469.96c0.92-0.05,1.88,0.83,1.93,1.77c0.05,0.94-0.79,1.88-1.75,1.93c-0.97,0.05-1.85-0.76-1.9-1.75
	C415.73,470.93,416.54,470.01,417.5,469.96z"
              />
              <path
                d="M296.65,222.58c-0.02,0.96-0.88,1.79-1.87,1.8c-1.12,0.02-1.95-0.87-1.86-2c0.08-1.03,0.89-1.72,1.94-1.67
	C295.85,220.77,296.67,221.62,296.65,222.58z"
              />
              <path
                d="M403.7,478.39c1,0.01,1.86,0.83,1.88,1.79c0.02,1.02-0.99,1.96-2.04,1.88c-1.01-0.07-1.75-0.9-1.71-1.93
	C401.88,479.1,402.65,478.38,403.7,478.39z"
              />
              <path
                d="M401.91,563.82c-0.05,1.03-0.85,1.76-1.88,1.73c-0.98-0.03-1.84-0.87-1.85-1.83c-0.01-1.05,0.97-1.93,2.06-1.85
	C401.27,561.96,401.97,562.77,401.91,563.82z"
              />
              <path
                d="M385.93,563.05c0.91,0,1.82,0.88,1.85,1.81c0.03,1.02-0.96,1.98-1.97,1.91c-0.95-0.07-1.73-0.95-1.7-1.92
	C384.14,563.88,384.98,563.04,385.93,563.05z"
              />
              <path
                d="M428.28,366.21c0.02,0.97-0.76,1.84-1.72,1.9c-1.01,0.06-2-0.91-1.95-1.93c0.04-0.92,0.95-1.79,1.86-1.79
	C427.43,364.4,428.26,365.24,428.28,366.21z"
              />
              <path
                d="M381.75,579.86c-0.04,1-0.87,1.8-1.85,1.79c-0.96-0.01-1.83-0.87-1.85-1.82c-0.03-1.01,1.01-2,2.01-1.92
	C381.01,577.98,381.79,578.88,381.75,579.86z"
              />
              <path
                d="M530.99,260.12c-0.07,1.03-0.88,1.74-1.92,1.7c-0.98-0.04-1.83-0.91-1.82-1.86c0.01-1.05,1.01-1.91,2.1-1.8
	C530.38,258.25,531.06,259.06,530.99,260.12z"
              />
              <path
                d="M387.16,581.17c-0.96,0.02-1.87-0.81-1.93-1.76c-0.07-1.01,0.93-2.03,1.95-1.99c0.97,0.04,1.77,0.9,1.77,1.9
	C388.95,580.31,388.14,581.15,387.16,581.17z"
              />
              <path
                d="M387.71,588.36c-0.93,0.06-1.87-0.75-1.97-1.69c-0.1-1,0.84-2.04,1.86-2.04c0.95,0,1.79,0.83,1.82,1.81
	C389.45,587.42,388.67,588.3,387.71,588.36z"
              />
              <path
                d="M415.62,96.51c0.04,0.93-0.78,1.85-1.73,1.93c-0.96,0.08-1.86-0.69-1.95-1.66c-0.11-1.06,0.76-2.05,1.81-2.06
	C414.67,94.71,415.57,95.58,415.62,96.51z"
              />
              <path
                d="M366.19,68.32c-0.03,0.95-0.9,1.8-1.85,1.81c-1.05,0.01-1.93-0.96-1.83-2.03c0.09-0.97,0.99-1.76,1.93-1.7
	C365.37,66.47,366.22,67.41,366.19,68.32z"
              />
              <path
                d="M392.46,555.15c1.05,0.03,1.79,0.79,1.78,1.85c0,1.04-0.77,1.83-1.79,1.85c-1.05,0.03-2.03-0.96-1.96-1.98
	C390.56,555.92,391.47,555.13,392.46,555.15z"
              />
              <path
                d="M399.5,554.68c1.06-0.05,1.86,0.65,1.94,1.69c0.08,1.03-0.63,1.89-1.64,2c-1.07,0.11-2.09-0.77-2.11-1.81
	C397.67,555.58,398.5,554.73,399.5,554.68z"
              />
              <path
                d="M491.44,219.84c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.68c-0.1-1.07,0.77-2.06,1.82-2.06
	C490.5,218.03,491.41,218.9,491.44,219.84z"
              />
              <path
                d="M406.73,557.66c-0.97-0.03-1.82-0.91-1.82-1.86c0-1.03,1.04-1.98,2.05-1.88c0.97,0.1,1.72,1.01,1.66,1.99
	C408.57,556.91,407.73,557.69,406.73,557.66z"
              />
              <path
                d="M384.39,592.21c-0.97-0.05-1.76-0.92-1.73-1.91c0.02-0.98,0.86-1.83,1.81-1.84c1.02-0.01,1.98,1.03,1.89,2.04
	C386.27,591.44,385.33,592.26,384.39,592.21z"
              />
              <path
                d="M436.97,466.73c0.03,1.02-0.69,1.85-1.69,1.94c-1.03,0.09-2.05-0.8-2.09-1.82c-0.04-1.04,0.9-1.95,1.97-1.92
	C436.2,464.95,436.94,465.69,436.97,466.73z"
              />
              <path
                d="M530.32,292.29c0.04,0.95-0.78,1.86-1.75,1.93c-0.98,0.08-1.87-0.68-1.96-1.68c-0.1-1.08,0.77-2.07,1.83-2.07
	C529.37,290.48,530.28,291.35,530.32,292.29z"
              />
              <path
                d="M243.82,221.53c-1.03-0.04-1.78-0.84-1.77-1.88c0.01-1.06,0.75-1.81,1.81-1.83c1.1-0.02,2.02,0.93,1.94,1.98
	C245.71,220.76,244.79,221.57,243.82,221.53z"
              />
              <path
                d="M376.01,555.12c-0.02,1.03-0.8,1.79-1.82,1.8c-0.99,0.01-1.86-0.8-1.91-1.77c-0.05-1.05,0.9-1.96,1.99-1.91
	C375.31,553.29,376.03,554.07,376.01,555.12z"
              />
              <path
                d="M293.48,186.46c0.05,1.03-0.68,1.86-1.69,1.94c-1.06,0.08-2.07-0.83-2.06-1.86c0.01-0.96,0.86-1.79,1.86-1.81
	C292.64,184.71,293.43,185.43,293.48,186.46z"
              />
              <path
                d="M436.72,506.29c0.04,0.92-0.79,1.85-1.72,1.92c-0.95,0.07-1.85-0.69-1.95-1.66c-0.11-1.05,0.76-2.04,1.8-2.05
	C435.77,504.5,436.68,505.37,436.72,506.29z"
              />
              <path
                d="M377.22,588.93c0.93-0.04,1.88,0.82,1.95,1.75c0.08,1.03-0.87,2.03-1.93,2c-0.98-0.02-1.78-0.86-1.79-1.86
	C375.45,589.84,376.26,588.97,377.22,588.93z"
              />
              <path
                d="M397.61,553.25c-0.05,1.02-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C396.99,551.42,397.65,552.2,397.61,553.25z"
              />
              <path
                d="M402.99,550.84c1.06,0.02,1.8,0.77,1.81,1.83c0.01,1.04-0.75,1.84-1.77,1.88c-1.05,0.04-2.05-0.95-1.99-1.96
	C401.1,551.62,402,550.82,402.99,550.84z"
              />
              <path
                d="M438.55,128.58c1.04,0.04,1.77,0.82,1.76,1.88c-0.01,1.03-0.79,1.81-1.82,1.82c-1.06,0.01-2.02-0.99-1.93-2.01
	C436.65,129.32,437.57,128.54,438.55,128.58z"
              />
              <path
                d="M383,594.14c0.02,0.95-0.82,1.84-1.78,1.89c-0.98,0.06-1.84-0.71-1.92-1.71c-0.09-1.08,0.8-2.05,1.86-2.03
	C382.09,592.32,382.98,593.21,383,594.14z"
              />
              <path
                d="M409.93,550.07c0.94,0.01,1.84,0.89,1.87,1.83c0.03,1.04-0.96,2-2,1.93c-0.98-0.07-1.75-0.94-1.71-1.94
	C408.12,550.9,408.96,550.06,409.93,550.07z"
              />
              <path
                d="M404.16,568.68c-0.96,0-1.79-0.82-1.82-1.79c-0.03-0.96,0.76-1.86,1.7-1.93c0.99-0.07,2,0.92,1.97,1.92
	C405.97,567.83,405.1,568.68,404.16,568.68z"
              />
              <path
                d="M306.43,503.15c0.05,1.03-0.67,1.86-1.68,1.95c-1.06,0.08-2.07-0.83-2.06-1.86c0.01-0.96,0.85-1.79,1.85-1.81
	C305.59,501.4,306.38,502.12,306.43,503.15z"
              />
              <path
                d="M370.37,549.88c1.06,0.02,1.8,0.77,1.81,1.83c0.01,1.04-0.75,1.84-1.77,1.88c-1.05,0.04-2.05-0.95-1.99-1.96
	C368.47,550.66,369.37,549.86,370.37,549.88z"
              />
              <path
                d="M391.64,568.29c-0.01,0.95-0.88,1.82-1.84,1.84c-1.07,0.02-1.95-0.93-1.87-2.01c0.08-0.98,0.96-1.78,1.92-1.73
	C390.78,566.44,391.65,567.37,391.64,568.29z"
              />
              <path
                d="M379.38,551.17c0.03,1.02-0.68,1.85-1.68,1.95c-1.03,0.1-2.06-0.79-2.1-1.81c-0.05-1.04,0.88-1.95,1.96-1.93
	C378.61,549.4,379.35,550.13,379.38,551.17z"
              />
              <path
                d="M382.58,570.6c-0.96,0.01-1.8-0.81-1.84-1.79c-0.04-0.97,0.75-1.87,1.69-1.94c0.99-0.08,2.02,0.91,1.99,1.92
	C384.4,569.74,383.53,570.59,382.58,570.6z"
              />
              <path
                d="M239.23,187.74c0.01,0.98-0.79,1.83-1.75,1.87c-1.03,0.04-1.99-0.94-1.93-1.97c0.06-0.92,0.99-1.79,1.91-1.76
	C238.41,185.91,239.22,186.76,239.23,187.74z"
              />
              <path
                d="M369.76,317.33c-0.01,1-0.82,1.83-1.8,1.85c-0.96,0.02-1.86-0.82-1.92-1.77c-0.06-1.02,0.94-2.03,1.96-1.99
	C368.96,315.47,369.77,316.34,369.76,317.33z"
              />
              <path
                d="M527.02,319.66c-0.99,0.01-1.82-0.78-1.86-1.79c-0.04-0.99,0.74-1.89,1.7-1.96c1.01-0.08,2.04,0.91,2.02,1.93
	C528.86,318.79,527.99,319.65,527.02,319.66z"
              />
              <path
                d="M440.08,502.58c-0.03,0.94-0.91,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.82-2.02c0.09-0.96,0.99-1.75,1.93-1.68
	C439.26,500.74,440.11,501.66,440.08,502.58z"
              />
              <path
                d="M400.79,549.38c-0.04,1.02-1.05,1.9-2.09,1.81c-0.99-0.09-1.71-0.91-1.68-1.92c0.03-1.13,0.9-1.89,2.04-1.78
	C400.03,547.57,400.83,548.44,400.79,549.38z"
              />
              <path
                d="M366.88,391.4c-1.06-0.05-1.94-1.05-1.82-2.06c0.11-0.94,1.01-1.69,1.98-1.65c1.12,0.04,1.88,0.92,1.76,2.05
	C368.71,390.74,367.9,391.45,366.88,391.4z"
              />
              <path
                d="M487.6,549c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.67c-0.1-1.07,0.77-2.06,1.82-2.06
	C486.66,547.2,487.57,548.07,487.6,549z"
              />
              <path
                d="M409.4,357.24c-0.06,0.96-0.96,1.79-1.92,1.77c-1.07-0.02-1.91-1.01-1.79-2.09c0.11-0.98,1.04-1.75,1.99-1.66
	C408.61,355.35,409.46,356.33,409.4,357.24z"
              />
              <path
                d="M402.41,484.04c0.01,1.02-0.72,1.83-1.71,1.9c-1.03,0.07-2.04-0.83-2.05-1.85c-0.02-1.03,0.94-1.92,2.01-1.88
	C401.67,482.25,402.4,483,402.41,484.04z"
              />
              <path
                d="M328.27,461.79c-0.03,0.94-0.9,1.8-1.84,1.8c-1.04,0-1.92-0.97-1.83-2.02c0.09-0.96,0.99-1.75,1.93-1.69
	C327.46,459.95,328.3,460.88,328.27,461.79z"
              />
              <path
                d="M395.18,484.85c-0.04,0.98-0.88,1.79-1.84,1.79c-0.95-0.01-1.82-0.86-1.84-1.81c-0.03-1.01,1-2,1.99-1.92
	C394.43,482.98,395.22,483.88,395.18,484.85z"
              />
              <path
                d="M368.6,322.41c1.08-0.02,2.01,0.89,1.97,1.93c-0.04,0.94-0.91,1.78-1.86,1.81c-1.1,0.04-1.95-0.83-1.91-1.94
	C366.82,323.17,367.56,322.43,368.6,322.41z"
              />
              <path
                d="M347.07,324.36c1,0.01,1.86,0.82,1.89,1.78c0.03,1.02-0.98,1.96-2.03,1.9c-1.02-0.06-1.76-0.88-1.72-1.91
	C345.25,325.09,346.02,324.36,347.07,324.36z"
              />
              <path
                d="M508.26,231.11c-1.03-0.01-1.8-0.78-1.82-1.81c-0.02-1.05,0.71-1.82,1.75-1.87c1.09-0.05,2.04,0.87,1.98,1.92
	C510.12,230.31,509.24,231.12,508.26,231.11z"
              />
              <path
                d="M385.12,575.89c0.03,1-0.75,1.87-1.73,1.92c-1.04,0.06-2.03-0.9-1.99-1.94c0.04-0.94,0.95-1.81,1.89-1.81
	C384.25,574.06,385.09,574.9,385.12,575.89z"
              />
              <path
                d="M317,459.08c0.01,1.02-0.72,1.84-1.72,1.91c-1.03,0.07-2.04-0.84-2.06-1.86c-0.02-1.04,0.93-1.93,2.01-1.89
	C316.26,457.29,316.99,458.04,317,459.08z"
              />
              <path
                d="M392.31,575.41c0.03,1-0.75,1.87-1.73,1.92c-1.04,0.06-2.03-0.9-1.99-1.94c0.04-0.94,0.95-1.81,1.89-1.81
	C391.45,573.58,392.29,574.42,392.31,575.41z"
              />
              <path
                d="M443.46,458.78c0.05,0.94-0.77,1.86-1.73,1.94c-0.97,0.08-1.87-0.67-1.97-1.66c-0.11-1.07,0.76-2.07,1.8-2.08
	C442.5,456.98,443.41,457.85,443.46,458.78z"
              />
              <path
                d="M448.7,144.05c0.02,0.95-0.85,1.86-1.8,1.88c-0.97,0.02-1.83-0.82-1.85-1.81c-0.02-0.99,0.81-1.87,1.77-1.89
	C447.76,142.21,448.68,143.11,448.7,144.05z"
              />
              <path
                d="M460.3,486.43c0.01,1-0.98,1.95-2.01,1.9c-1-0.04-1.75-0.82-1.76-1.84c-0.01-1.04,0.69-1.82,1.71-1.88
	C459.3,484.53,460.28,485.4,460.3,486.43z"
              />
              <path
                d="M413.31,546.24c0.93,0.02,1.82,0.92,1.84,1.84c0.02,1.04-0.98,1.98-2.02,1.89c-0.97-0.08-1.73-0.96-1.68-1.95
	C411.49,547.04,412.35,546.22,413.31,546.24z"
              />
              <path
                d="M318.26,330.67c-0.98,0-1.81-0.86-1.81-1.85c0-0.99,0.85-1.85,1.82-1.85c0.94,0,1.84,0.92,1.83,1.86
	C320.1,329.79,319.22,330.67,318.26,330.67z"
              />
              <path
                d="M446.78,455.09c-0.04,0.95-0.97,1.82-1.91,1.78c-0.97-0.04-1.78-0.93-1.74-1.92c0.04-0.98,0.92-1.82,1.88-1.78
	C445.96,453.21,446.82,454.15,446.78,455.09z"
              />
              <path
                d="M452.07,456.17c-1.02-0.04-1.78-0.84-1.77-1.88c0.01-1.06,0.75-1.81,1.81-1.83c1.1-0.02,2.03,0.93,1.94,1.98
	C453.96,455.4,453.03,456.21,452.07,456.17z"
              />
              <path
                d="M443.94,213.25c-0.04,0.95-0.93,1.8-1.88,1.8c-1.06,0-1.93-0.98-1.82-2.05c0.1-0.97,1.01-1.76,1.96-1.68
	C443.12,211.39,443.98,212.34,443.94,213.25z"
              />
              <path
                d="M273.31,329.29c0.01,1-0.79,1.85-1.77,1.88c-1.05,0.04-2.02-0.94-1.95-1.98c0.06-0.94,1-1.8,1.93-1.77
	C272.49,327.45,273.31,328.3,273.31,329.29z"
              />
              <path
                d="M262.7,396.89c-0.97,0.06-1.86-0.75-1.91-1.74c-0.05-0.98,0.75-1.9,1.71-1.96c0.91-0.06,1.88,0.82,1.94,1.76
	C264.5,395.89,263.66,396.83,262.7,396.89z"
              />
              <path
                d="M295.49,191.79c-1.03,0.01-2-0.96-1.94-1.96c0.05-0.94,0.91-1.74,1.89-1.76c1.13-0.02,1.93,0.8,1.88,1.94
	C297.27,191.03,296.5,191.78,295.49,191.79z"
              />
              <path
                d="M313.12,329.33c-0.02,0.99-0.87,1.83-1.84,1.82c-0.95-0.01-1.82-0.91-1.81-1.87c0.01-0.94,0.93-1.84,1.86-1.83
	C312.3,327.47,313.13,328.34,313.12,329.33z"
              />
              <path
                d="M423.49,395.57c-0.03,0.98-0.85,1.81-1.81,1.81c-0.95,0-1.83-0.84-1.87-1.79c-0.04-1,0.97-2.01,1.97-1.94
	C422.72,393.71,423.52,394.6,423.49,395.57z"
              />
              <path
                d="M306.04,499.37c0.02-0.96,0.89-1.77,1.88-1.78c1.14-0.01,1.95,0.85,1.85,1.99c-0.08,1.03-0.9,1.73-1.94,1.67
	C306.87,501.2,306.02,500.31,306.04,499.37z"
              />
              <path
                d="M461.23,453.91c-0.05,0.96-0.92,1.77-1.91,1.77c-1.12-0.01-1.93-0.92-1.82-2.04c0.1-1.03,0.92-1.7,1.97-1.63
	C460.47,452.08,461.28,452.95,461.23,453.91z"
              />
              <path
                d="M251.87,350.73c1.03,0.03,1.76,0.77,1.79,1.81c0.03,1.11-0.83,1.96-1.93,1.91c-0.96-0.04-1.81-0.88-1.84-1.82
	C249.86,351.6,250.8,350.7,251.87,350.73z"
              />
              <path
                d="M356.14,452.19c-0.02,0.96-0.9,1.82-1.86,1.83c-1.08,0.01-1.95-0.94-1.86-2.03c0.08-0.98,0.98-1.78,1.94-1.72
	C355.3,450.33,356.16,451.26,356.14,452.19z"
              />
              <path
                d="M356.63,498.95c-0.01,0.96-0.87,1.8-1.85,1.83c-1.12,0.03-1.96-0.85-1.89-1.97c0.07-1.03,0.87-1.74,1.92-1.69
	C355.81,497.15,356.64,497.99,356.63,498.95z"
              />
              <path
                d="M377.9,596.14c0.95-0.06,1.85,0.74,1.93,1.71c0.08,0.98-0.65,1.89-1.62,2.01c-1.04,0.13-2.06-0.76-2.09-1.81
	C376.11,597.14,376.98,596.2,377.9,596.14z"
              />
              <path
                d="M392.53,349.31c0.99-0.05,1.9,0.7,2,1.66c0.11,1.04-0.8,2.01-1.89,2.02c-1.03,0-1.81-0.76-1.84-1.78
	C390.77,350.16,391.49,349.37,392.53,349.31z"
              />
              <path
                d="M481.73,543.86c0.96,0.05,1.77,0.96,1.73,1.94c-0.05,0.99-0.93,1.81-1.9,1.76c-0.95-0.05-1.8-0.98-1.75-1.93
	C479.86,544.69,480.82,543.81,481.73,543.86z"
              />
              <path
                d="M443.44,498.58c0.06,0.91-0.76,1.86-1.68,1.95c-0.95,0.09-1.86-0.66-1.97-1.62c-0.13-1.05,0.72-2.06,1.76-2.08
	C442.45,496.8,443.38,497.66,443.44,498.58z"
              />
              <path
                d="M404.17,545.4c0.05,1-0.93,1.98-1.96,1.97c-1.01-0.01-1.78-0.78-1.82-1.8c-0.04-1.05,0.63-1.84,1.66-1.94
	C403.11,543.53,404.12,544.36,404.17,545.4z"
              />
              <path
                d="M407.41,189.04c0.98-0.05,1.9,0.71,2,1.66c0.11,1.04-0.81,2.01-1.89,2.01c-1.03,0-1.81-0.76-1.83-1.79
	C405.65,189.88,406.37,189.1,407.41,189.04z"
              />
              <path
                d="M416.63,542.41c0.91,0,1.82,0.88,1.85,1.8c0.04,1.02-0.95,1.99-1.97,1.92c-0.95-0.07-1.73-0.94-1.7-1.91
	C414.85,543.25,415.69,542.41,416.63,542.41z"
              />
              <path
                d="M449.92,490.79c-0.01,1.02-0.75,1.82-1.75,1.87c-1.03,0.05-2.03-0.88-2.03-1.9c0.01-1.04,0.98-1.91,2.05-1.85
	C449.21,488.97,449.92,489.75,449.92,490.79z"
              />
              <path
                d="M400.86,402.77c0.92,0.04,1.8,0.95,1.8,1.87c0,1.04-1.01,1.96-2.04,1.85c-0.98-0.1-1.71-0.98-1.65-1.97
	C399.04,403.55,399.92,402.74,400.86,402.77z"
              />
              <path
                d="M274.43,403.04c1.03,0.04,1.76,0.78,1.78,1.81c0.02,1.12-0.83,1.95-1.94,1.9c-0.96-0.04-1.81-0.89-1.83-1.83
	C272.42,403.9,273.36,403,274.43,403.04z"
              />
              <path
                d="M547.08,612.46c-0.03,0.94-0.9,1.8-1.84,1.8c-1.05,0.01-1.92-0.96-1.83-2.02c0.09-0.96,0.99-1.75,1.92-1.69
	C546.26,610.62,547.11,611.54,547.08,612.46z"
              />
              <path
                d="M331.39,497.59c-0.05,1.02-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C330.77,495.75,331.44,496.54,331.39,497.59z"
              />
              <path
                d="M336.47,498.9c-1.01-0.01-1.78-0.78-1.82-1.8c-0.05-1.14,0.76-1.97,1.89-1.94c0.99,0.02,1.84,0.82,1.89,1.77
	C338.47,497.94,337.5,498.92,336.47,498.9z"
              />
              <path
                d="M309.78,333.21c-0.04,1.01-0.86,1.81-1.85,1.8c-0.96-0.01-1.84-0.86-1.87-1.82c-0.03-1.02,1-2.01,2.01-1.94
	C309.03,331.33,309.82,332.23,309.78,333.21z"
              />
              <path
                d="M467.63,445.86c0.02,0.97-0.76,1.84-1.72,1.9c-1.01,0.06-2-0.91-1.95-1.93c0.04-0.92,0.95-1.79,1.86-1.79
	C466.78,444.05,467.61,444.89,467.63,445.86z"
              />
              <path
                d="M411.86,405.43c1.09,0.02,1.97,0.96,1.88,2c-0.08,0.94-0.98,1.75-1.93,1.74c-1.1-0.01-1.91-0.91-1.84-2.02
	C410.05,406.11,410.82,405.41,411.86,405.43z"
              />
              <path
                d="M351.3,544.45c-1.03-0.04-1.78-0.83-1.77-1.86c0.01-1.05,0.75-1.8,1.8-1.82c1.09-0.02,2.01,0.92,1.93,1.97
	C353.18,543.68,352.27,544.48,351.3,544.45z"
              />
              <path
                d="M381.07,572.51c0.04,0.93-0.78,1.85-1.72,1.93c-0.96,0.08-1.86-0.69-1.96-1.66c-0.11-1.07,0.76-2.05,1.81-2.06
	C380.12,570.71,381.03,571.58,381.07,572.51z"
              />
              <path
                d="M409.63,170.8c1.04-0.02,1.81,0.67,1.89,1.7c0.08,1.11-0.72,2.01-1.82,2.02c-1.03,0.02-2-0.96-1.96-1.95
	C407.8,171.63,408.66,170.82,409.63,170.8z"
              />
              <path
                d="M374.87,334.82c0.01,0.95-0.85,1.81-1.83,1.85c-1.12,0.05-1.98-0.82-1.92-1.94c0.06-1.03,0.84-1.75,1.89-1.72
	C374.01,333.03,374.86,333.86,374.87,334.82z"
              />
              <path
                d="M299.22,194.87c-0.95-0.05-1.8-0.99-1.75-1.93c0.05-0.94,1.01-1.82,1.93-1.77c0.96,0.05,1.77,0.97,1.72,1.95
	C301.07,194.11,300.18,194.93,299.22,194.87z"
              />
              <path
                d="M309.08,345.66c0.93-0.04,1.88,0.82,1.95,1.75c0.08,1.03-0.87,2.03-1.93,2c-0.98-0.02-1.78-0.86-1.79-1.86
	C307.31,346.56,308.12,345.69,309.08,345.66z"
              />
              <path
                d="M524.08,346.99c0.04,0.95-0.79,1.86-1.75,1.93c-0.98,0.07-1.87-0.68-1.96-1.68c-0.1-1.08,0.77-2.07,1.83-2.07
	C523.14,345.18,524.04,346.05,524.08,346.99z"
              />
              <path
                d="M387.1,82.61c-0.96-0.01-1.83-0.87-1.85-1.82c-0.02-1.01,1.01-2,2.01-1.92c0.95,0.08,1.73,0.98,1.69,1.96
	C388.91,81.82,388.07,82.63,387.1,82.61z"
              />
              <path
                d="M405.68,539.79c0.99,0.04,1.82,0.87,1.84,1.82c0.02,1.01-0.97,1.96-2,1.92c-1.09-0.04-1.88-0.97-1.77-2.08
	C403.84,540.42,404.64,539.74,405.68,539.79z"
              />
              <path
                d="M353.68,446.81c-0.96-0.05-1.75-0.92-1.73-1.9c0.02-0.97,0.86-1.82,1.81-1.83c1.01-0.01,1.97,1.03,1.88,2.03
	C355.55,446.04,354.61,446.86,353.68,446.81z"
              />
              <path
                d="M423.99,110.35c-0.04,1.03-0.83,1.77-1.86,1.76c-0.99-0.02-1.85-0.85-1.87-1.81c-0.02-1.05,0.95-1.94,2.04-1.87
	C423.32,108.5,424.03,109.31,423.99,110.35z"
              />
              <path
                d="M475.34,492.31c-0.05,1.01-0.82,1.77-1.83,1.78c-1.03,0.01-2-0.97-1.94-1.96c0.06-1.03,1.06-1.86,2.13-1.75
	C474.72,490.48,475.39,491.26,475.34,492.31z"
              />
              <path
                d="M302.94,440.2c0.92,0.02,1.81,0.92,1.83,1.84c0.02,1.04-0.98,1.97-2.01,1.89c-0.96-0.08-1.72-0.97-1.68-1.95
	C301.13,441.01,301.99,440.18,302.94,440.2z"
              />
              <path
                d="M478.22,441.44c-0.04,1.02-0.81,1.77-1.82,1.78c-1.03,0.01-2-0.96-1.95-1.96c0.05-1.03,1.05-1.86,2.12-1.76
	C477.59,439.61,478.26,440.39,478.22,441.44z"
              />
              <path
                d="M351.81,441.63c-0.02,0.95-0.9,1.81-1.85,1.82c-1.06,0.01-1.94-0.95-1.85-2.03c0.08-0.97,0.98-1.77,1.93-1.71
	C350.98,439.78,351.83,440.7,351.81,441.63z"
              />
              <path
                d="M485.26,440.82c0.03,1-0.95,1.97-1.98,1.94c-1-0.03-1.77-0.8-1.8-1.82c-0.03-1.05,0.66-1.84,1.68-1.92
	C484.22,438.93,485.22,439.79,485.26,440.82z"
              />
              <path
                d="M452.59,147.37c0.04,0.95-0.78,1.86-1.74,1.94c-0.98,0.08-1.87-0.67-1.97-1.67c-0.11-1.08,0.76-2.07,1.82-2.08
	C451.63,145.56,452.54,146.43,452.59,147.37z"
              />
              <path
                d="M341.71,493c-0.04,0.94-0.92,1.79-1.86,1.78c-1.04-0.01-1.91-0.99-1.81-2.04c0.1-0.96,1.01-1.74,1.95-1.67
	C340.91,491.15,341.75,492.09,341.71,493z"
              />
              <path
                d="M392.79,84.02c0.04,1.01-0.71,1.88-1.69,1.96c-1.05,0.08-2.05-0.86-2.02-1.9c0.02-0.94,0.91-1.83,1.85-1.85
	C391.89,82.21,392.75,83.04,392.79,84.02z"
              />
              <path
                d="M543.31,609.18c0.06,0.91-0.74,1.86-1.68,1.98c-1.1,0.14-2.03-0.63-2.09-1.74c-0.06-1.06,0.59-1.85,1.62-1.97
	C542.23,607.33,543.24,608.14,543.31,609.18z"
              />
              <path
                d="M412.6,542.79c-0.96,0.02-1.87-0.81-1.93-1.76c-0.07-1.01,0.93-2.03,1.95-1.99c0.97,0.04,1.77,0.9,1.77,1.9
	C414.38,541.93,413.57,542.76,412.6,542.79z"
              />
              <path
                d="M388.28,572.04c0.04,0.94-0.78,1.85-1.74,1.93c-0.97,0.07-1.86-0.69-1.96-1.67c-0.1-1.07,0.77-2.06,1.82-2.06
	C387.33,570.23,388.24,571.1,388.28,572.04z"
              />
              <path
                d="M469.36,174.73c0.04,0.94-0.78,1.85-1.73,1.92c-0.96,0.07-1.85-0.69-1.95-1.67c-0.1-1.07,0.77-2.05,1.82-2.05
	C468.42,172.93,469.32,173.8,469.36,174.73z"
              />
              <path
                d="M293.97,193.75c0,1.02-0.74,1.83-1.74,1.89c-1.03,0.06-2.04-0.86-2.04-1.88c-0.01-1.04,0.95-1.92,2.03-1.87
	C293.25,191.95,293.97,192.71,293.97,193.75z"
              />
              <path
                d="M431.82,179.8c-1.02,0.04-2.02-0.91-2-1.91c0.02-0.94,0.86-1.76,1.84-1.81c1.13-0.05,1.95,0.75,1.93,1.88
	C433.58,178.98,432.83,179.76,431.82,179.8z"
              />
              <path
                d="M401.53,559.83c0.02-0.96,0.89-1.77,1.88-1.78c1.14-0.01,1.95,0.85,1.85,1.99c-0.08,1.03-0.9,1.73-1.94,1.67
	C402.36,561.66,401.51,560.77,401.53,559.83z"
              />
              <path
                d="M483,538.63c-0.06,1-0.91,1.78-1.89,1.74c-0.96-0.04-1.81-0.91-1.81-1.86c0-1.03,1.04-1.98,2.05-1.87
	C482.3,536.74,483.06,537.65,483,538.63z"
              />
              <path
                d="M372.37,492.03c0.91-0.07,1.89,0.81,1.96,1.74c0.07,0.95-0.76,1.89-1.72,1.96c-0.97,0.07-1.87-0.73-1.93-1.72
	C370.61,493.03,371.41,492.1,372.37,492.03z"
              />
              <path
                d="M404.16,193.09c0.92,0,1.82,0.88,1.85,1.81c0.03,1.02-0.96,1.98-1.97,1.91c-0.95-0.07-1.73-0.95-1.7-1.92
	C402.37,193.92,403.22,193.09,404.16,193.09z"
              />
              <path
                d="M270.69,340.31c0.02,0.93-0.82,1.83-1.76,1.88c-0.95,0.05-1.83-0.73-1.91-1.7c-0.08-1.06,0.81-2.03,1.85-2.01
	C269.79,338.5,270.68,339.39,270.69,340.31z"
              />
              <path
                d="M348.4,429.06c-0.97-0.05-1.76-0.92-1.73-1.91c0.02-0.98,0.86-1.83,1.81-1.84c1.02-0.01,1.98,1.03,1.89,2.04
	C350.28,428.3,349.34,429.11,348.4,429.06z"
              />
              <path
                d="M417.74,537.09c-0.01,1-0.82,1.84-1.8,1.85c-0.96,0.02-1.86-0.82-1.92-1.77c-0.06-1.02,0.94-2.03,1.96-1.99
	C416.95,535.23,417.75,536.1,417.74,537.09z"
              />
              <path
                d="M343.4,427.86c0.03,1.02-0.69,1.85-1.69,1.94c-1.03,0.09-2.05-0.8-2.09-1.82c-0.04-1.04,0.9-1.95,1.97-1.92
	C342.63,426.08,343.37,426.82,343.4,427.86z"
              />
              <path
                d="M310.25,340.29c0.03,0.99-0.76,1.86-1.72,1.92c-1.03,0.06-2.02-0.9-1.98-1.94c0.04-0.93,0.95-1.81,1.88-1.81
	C309.39,338.47,310.23,339.31,310.25,340.29z"
              />
              <path
                d="M402.86,198.86c-0.04,0.99-0.87,1.8-1.84,1.8c-0.96-0.01-1.83-0.86-1.86-1.81c-0.03-1.01,1-2,2-1.93
	C402.11,196.98,402.9,197.88,402.86,198.86z"
              />
              <path
                d="M446.53,494.93c-0.04,1-0.87,1.8-1.85,1.79c-0.96-0.01-1.83-0.87-1.85-1.82c-0.03-1.01,1.01-2,2.01-1.92
	C445.79,493.05,446.57,493.95,446.53,494.93z"
              />
              <path
                d="M484.71,433.66c0,0.97-0.85,1.8-1.86,1.8c-1.04,0-1.83-0.77-1.83-1.79c0-1.02,0.78-1.79,1.82-1.8
	C483.85,431.88,484.71,432.7,484.71,433.66z"
              />
              <path
                d="M357.41,193.4c1.04,0.02,1.8,0.81,1.78,1.84c-0.02,1.02-0.82,1.77-1.86,1.75c-1.01-0.02-1.84-0.86-1.81-1.83
	C355.54,194.17,356.41,193.38,357.41,193.4z"
              />
              <path
                d="M480.51,197.24c1.01,0.03,1.83,0.86,1.8,1.84c-0.03,0.96-0.91,1.77-1.91,1.75c-1.03-0.03-1.8-0.82-1.77-1.85
	C478.66,197.96,479.46,197.22,480.51,197.24z"
              />
              <path
                d="M413.89,201.53c-0.06,1.03-0.87,1.74-1.92,1.68c-1.01-0.05-1.8-0.92-1.74-1.89c0.06-0.97,0.95-1.74,1.95-1.69
	C413.21,199.69,413.94,200.5,413.89,201.53z"
              />
              <path
                d="M338.31,536.71c-0.06,0.94-1.01,1.79-1.92,1.72c-0.96-0.07-1.74-0.97-1.68-1.94c0.06-0.97,0.96-1.78,1.9-1.72
	C337.54,534.83,338.37,535.77,338.31,536.71z"
              />
              <path
                d="M523.54,339.83c0.02,0.95-0.84,1.85-1.79,1.87c-0.96,0.02-1.82-0.82-1.84-1.8c-0.02-0.98,0.81-1.86,1.77-1.88
	C522.61,338,523.53,338.89,523.54,339.83z"
              />
              <path
                d="M278.05,196.78c-0.96,0.05-1.84-0.76-1.89-1.74c-0.05-0.97,0.75-1.88,1.7-1.93c0.91-0.05,1.85,0.81,1.9,1.75
	C279.82,195.82,279,196.73,278.05,196.78z"
              />
              <path
                d="M406.68,202.14c-0.12,1.02-1,1.69-2.04,1.56c-0.99-0.12-1.74-1.06-1.61-2.02c0.14-0.99,1.05-1.68,2.07-1.56
	C406.13,200.24,406.81,201.11,406.68,202.14z"
              />
              <path
                d="M369.82,336.87c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C367.97,337.71,368.81,336.88,369.82,336.87z"
              />
              <path
                d="M491.11,425.65c0.06,0.97-0.76,1.88-1.72,1.92c-0.85,0.03-1.69-0.71-1.8-1.58c-0.12-0.98,0.62-1.93,1.59-2.03
	C490.11,423.86,491.05,424.67,491.11,425.65z"
              />
              <path
                d="M368.31,342.43c0.04,1.03-0.72,1.84-1.76,1.87c-0.99,0.03-1.89-0.78-1.93-1.74c-0.04-0.97,0.78-1.83,1.79-1.86
	C367.47,340.67,368.27,341.4,368.31,342.43z"
              />
              <path
                d="M373.72,177.6c-0.95,0.02-1.84-0.85-1.86-1.81c-0.02-0.94,0.86-1.86,1.8-1.87c0.95-0.02,1.82,0.83,1.83,1.81
	C375.51,176.72,374.69,177.58,373.72,177.6z"
              />
              <path
                d="M479.52,435.71c0.99,0.01,1.83,0.82,1.86,1.77c0.02,1.02-0.96,1.93-2.02,1.85c-1-0.07-1.73-0.89-1.69-1.9
	C477.71,436.41,478.48,435.69,479.52,435.71z"
              />
              <path
                d="M340.45,538.15c0.97,0.07,1.74,1.01,1.65,1.98c-0.09,0.87-0.91,1.63-1.77,1.63c-0.97,0-1.8-0.88-1.77-1.87
	C338.59,538.92,339.5,538.08,340.45,538.15z"
              />
              <path
                d="M474.07,438.29c-0.03,0.97-0.97,1.83-1.91,1.76c-0.96-0.08-1.76-1.04-1.66-2c0.09-0.87,0.92-1.64,1.78-1.64
	C473.24,436.41,474.11,437.33,474.07,438.29z"
              />
              <path
                d="M244.5,165.37c0.04,1.02-0.72,1.83-1.75,1.86c-0.99,0.03-1.88-0.77-1.92-1.73c-0.04-0.98,0.76-1.82,1.78-1.85
	C243.67,163.62,244.47,164.35,244.5,165.37z"
              />
              <path
                d="M399.75,475.26c0.91-0.05,1.85,0.83,1.89,1.76c0.05,0.96-0.78,1.86-1.73,1.91c-0.96,0.05-1.83-0.77-1.88-1.74
	C398,476.21,398.8,475.31,399.75,475.26z"
              />
              <path
                d="M462.85,166.29c-0.99-0.05-1.81-0.89-1.79-1.84c0.02-1.04,1.01-1.88,2.08-1.77c1.02,0.1,1.7,0.92,1.62,1.95
	C464.68,165.64,463.88,166.34,462.85,166.29z"
              />
              <path
                d="M410,95.05c-0.96,0.05-1.84-0.76-1.89-1.74c-0.05-0.97,0.75-1.88,1.7-1.93c0.91-0.05,1.85,0.81,1.9,1.75
	C411.78,94.09,410.96,95,410,95.05z"
              />
              <path
                d="M293.39,83.52c-0.97,0.04-1.85-0.8-1.86-1.79c-0.01-0.99,0.85-1.86,1.82-1.84c0.85,0.02,1.68,0.82,1.74,1.68
	C295.17,82.55,294.36,83.48,293.39,83.52z"
              />
              <path
                d="M266.49,84.08c0.05,1.03-0.66,1.85-1.66,1.94c-1.01,0.09-1.86-0.54-1.87-1.37c-0.01-0.84,1.08-2.12,1.87-2.2
	C265.66,82.37,266.45,83.14,266.49,84.08z"
              />
              <path
                d="M466.24,494.76c-0.95-0.03-1.78-0.93-1.75-1.89c0.03-0.93,0.96-1.81,1.87-1.78c0.95,0.04,1.77,0.93,1.73,1.9
	C468.06,493.97,467.19,494.79,466.24,494.76z"
              />
              <path
                d="M412.54,416.29c-0.98-0.01-1.84-0.82-1.87-1.76c-0.03-1.04,0.93-1.93,2-1.86c1.02,0.06,1.74,0.85,1.7,1.88
	C414.35,415.56,413.57,416.3,412.54,416.29z"
              />
              <path
                d="M370.96,414.3c-0.04-0.97,0.81-1.87,1.77-1.87c0.86,0,1.68,0.76,1.77,1.63c0.1,0.95-0.7,1.92-1.65,1.99
	C371.93,416.12,371,415.26,370.96,414.3z"
              />
              <path
                d="M273.52,249.62c0.02,0.95-0.83,1.84-1.77,1.85c-0.95,0.02-1.8-0.82-1.82-1.79c-0.02-0.97,0.81-1.84,1.76-1.86
	C272.6,247.81,273.5,248.69,273.52,249.62z"
              />
              <path
                d="M421.54,413.86c-0.06,0.98-0.98,1.8-1.94,1.71c-0.96-0.09-1.73-1.07-1.61-2.03c0.11-0.87,0.97-1.63,1.82-1.6
	C420.77,411.97,421.59,412.88,421.54,413.86z"
              />
              <path
                d="M340.18,411.72c1.04,0.04,1.78,0.84,1.73,1.88c-0.04,1.03-0.85,1.75-1.9,1.71c-1.01-0.04-1.82-0.9-1.77-1.87
	C338.3,412.46,339.18,411.68,340.18,411.72z"
              />
              <path
                d="M383.62,601.31c0.04,0.98-0.79,1.87-1.76,1.88c-0.85,0.01-1.68-0.75-1.77-1.62c-0.1-0.98,0.67-1.91,1.63-1.99
	C382.67,599.5,383.58,600.33,383.62,601.31z"
              />
              <path
                d="M469.11,443.9c-0.95-0.04-1.8-0.94-1.76-1.9c0.03-0.93,0.97-1.82,1.89-1.79c0.96,0.04,1.77,0.93,1.74,1.91
	C470.95,443.11,470.08,443.94,469.11,443.9z"
              />
              <path
                d="M370.71,411.35c-0.12,1.01-0.96,1.68-1.99,1.59c-0.98-0.09-1.77-0.98-1.71-1.92c0.07-1.06,1.07-1.84,2.16-1.69
	C370.18,409.47,370.83,410.31,370.71,411.35z"
              />
              <path
                d="M536.05,609.72c0.03,0.97-0.8,1.81-1.81,1.84c-1.04,0.03-1.84-0.72-1.87-1.74c-0.02-1.03,0.73-1.81,1.77-1.84
	C535.16,607.95,536.02,608.75,536.05,609.72z"
              />
              <path
                d="M417.56,410.54c0.06,0.94-0.74,1.83-1.72,1.92c-1.03,0.09-1.87-0.59-1.98-1.6c-0.13-1.12,0.65-2.02,1.78-2.04
	C416.63,408.81,417.5,409.58,417.56,410.54z"
              />
              <path
                d="M358.62,211.38c-1.04,0.02-1.84-0.73-1.86-1.75c-0.02-1.03,0.74-1.81,1.78-1.84c1.01-0.02,1.87,0.77,1.9,1.75
	C360.46,210.51,359.62,211.35,358.62,211.38z"
              />
              <path
                d="M421.09,533.05c-0.02,1.03-0.82,1.78-1.87,1.75c-1.01-0.03-1.85-0.88-1.82-1.85c0.03-0.97,0.9-1.78,1.91-1.75
	C420.37,531.22,421.12,532,421.09,533.05z"
              />
              <path
                d="M437.89,211.88c-0.98-0.05-1.81-0.89-1.79-1.84c0.02-1.04,1.01-1.88,2.08-1.77c1.02,0.1,1.7,0.92,1.62,1.95
	C439.73,211.23,438.93,211.92,437.89,211.88z"
              />
              <path
                d="M357.84,406.67c0.96-0.01,1.81,0.87,1.79,1.85c-0.03,0.98-0.92,1.82-1.87,1.76c-0.88-0.05-1.63-0.83-1.67-1.72
	C356.04,407.59,356.89,406.68,357.84,406.67z"
              />
              <path
                d="M479.59,542.41c-0.03,0.98-0.94,1.81-1.89,1.74c-0.96-0.07-1.75-1.01-1.66-1.98c0.08-0.86,0.92-1.63,1.77-1.63
	C478.78,540.55,479.62,541.43,479.59,542.41z"
              />
              <path
                d="M390.35,347.95c0.04,0.99-0.79,1.88-1.77,1.88c-0.95,0.01-1.82-0.9-1.79-1.87c0.02-0.87,0.8-1.69,1.66-1.76
	C389.39,346.13,390.31,346.97,390.35,347.95z"
              />
              <path
                d="M408.9,476.42c-0.03,0.96-0.92,1.78-1.91,1.76c-1.04-0.02-1.8-0.82-1.78-1.85c0.03-1.03,0.82-1.77,1.88-1.75
	C408.1,474.6,408.93,475.45,408.9,476.42z"
              />
              <path
                d="M395.46,571.61c-0.13,0.96-1.05,1.68-2.03,1.6c-1.1-0.09-1.84-1.08-1.64-2.17c0.18-0.98,1.07-1.6,2.1-1.45
	C394.87,569.73,395.6,570.67,395.46,571.61z"
              />
              <path
                d="M297.06,252.91c-0.97-0.06-1.77-0.99-1.7-1.96c0.08-0.97,1.05-1.78,1.98-1.66c0.85,0.11,1.59,0.97,1.58,1.84
	C298.92,252.09,298,252.96,297.06,252.91z"
              />
              <path
                d="M472.79,194.37c-0.97,0.01-1.82-0.87-1.8-1.85c0.02-0.98,0.92-1.83,1.88-1.77c0.86,0.05,1.65,0.86,1.69,1.73
	C474.6,193.45,473.75,194.36,472.79,194.37z"
              />
              <path
                d="M266.62,190.52c1.05-0.03,1.85,0.71,1.88,1.74c0.03,1.03-0.73,1.83-1.77,1.86c-1,0.03-1.89-0.79-1.92-1.75
	C264.78,191.39,265.61,190.55,266.62,190.52z"
              />
              <path
                d="M252.15,211.69c0.03,0.99-0.81,1.87-1.78,1.87c-0.95,0-1.82-0.91-1.78-1.88c0.03-0.87,0.81-1.69,1.67-1.75
	C251.21,209.87,252.12,210.71,252.15,211.69z"
              />
              <path
                d="M355.8,405.17c-0.03,0.98-0.93,1.82-1.89,1.76c-0.96-0.06-1.77-1.02-1.68-1.98c0.08-0.87,0.92-1.64,1.78-1.65
	C354.98,403.3,355.83,404.19,355.8,405.17z"
              />
              <path
                d="M392.79,582.43c0,1.03-0.8,1.8-1.84,1.79c-1-0.01-1.87-0.85-1.86-1.81c0.01-0.97,0.88-1.79,1.88-1.79
	C392.02,580.63,392.79,581.4,392.79,582.43z"
              />
              <path
                d="M510.82,402.55c-0.04,1.03-0.84,1.77-1.89,1.73c-1.01-0.04-1.84-0.9-1.8-1.87c0.04-0.97,0.92-1.76,1.93-1.73
	C510.12,400.72,510.86,401.51,510.82,402.55z"
              />
              <path
                d="M305.43,449.28c-0.01,0.99-0.86,1.83-1.82,1.82c-0.95-0.01-1.82-0.9-1.81-1.85c0.01-0.94,0.91-1.84,1.84-1.83
	C304.61,447.43,305.44,448.3,305.43,449.28z"
              />
              <path
                d="M400.92,569.12c1,0.06,1.79,0.93,1.73,1.9c-0.07,0.97-0.96,1.74-1.96,1.68c-1.03-0.06-1.76-0.89-1.7-1.91
	C399.05,569.76,399.86,569.06,400.92,569.12z"
              />
              <path
                d="M361.87,330.35c0.91-0.03,1.84,0.85,1.87,1.78c0.03,0.96-0.8,1.85-1.75,1.89c-0.96,0.03-1.82-0.79-1.85-1.77
	C360.1,331.27,360.91,330.38,361.87,330.35z"
              />
              <path
                d="M457.08,490.26c0.02,1.02-0.75,1.81-1.79,1.83c-1,0.02-1.87-0.8-1.89-1.76c-0.02-0.97,0.81-1.81,1.82-1.82
	C456.27,488.49,457.06,489.24,457.08,490.26z"
              />
              <path
                d="M462.54,448.18c1.03,0.05,1.76,0.82,1.74,1.84c-0.02,1.01-0.78,1.77-1.81,1.78c-1.08,0.01-1.98-0.92-1.89-1.96
	C460.68,448.88,461.56,448.13,462.54,448.18z"
              />
              <path
                d="M376.44,329.2c1,0.06,1.79,0.93,1.73,1.9c-0.07,0.97-0.96,1.74-1.96,1.68c-1.03-0.06-1.76-0.89-1.7-1.91
	C374.58,329.84,375.39,329.14,376.44,329.2z"
              />
              <path
                d="M464.26,489.63c-0.03,0.98-0.9,1.81-1.86,1.78c-0.95-0.03-1.8-0.94-1.77-1.89c0.03-0.93,0.97-1.82,1.88-1.79
	C463.47,487.76,464.29,488.65,464.26,489.63z"
              />
              <path
                d="M499.78,233.51c0.04,1.02-0.72,1.83-1.75,1.86c-0.99,0.03-1.88-0.77-1.92-1.73c-0.04-0.98,0.77-1.82,1.78-1.85
	C498.95,231.76,499.74,232.49,499.78,233.51z"
              />
              <path
                d="M450.1,451.14c0.03,0.95-0.81,1.84-1.75,1.87c-0.95,0.03-1.81-0.81-1.84-1.77c-0.03-0.96,0.79-1.85,1.74-1.88
	C449.17,449.33,450.08,450.2,450.1,451.14z"
              />
              <path
                d="M382.85,487.52c1.04,0.02,1.79,0.78,1.79,1.81c0,1.02-0.76,1.8-1.78,1.84c-1.07,0.03-2.02-0.91-1.95-1.94
	C380.99,488.26,381.86,487.49,382.85,487.52z"
              />
              <path
                d="M363.79,498.37c0.05,0.97-0.77,1.84-1.78,1.88c-1.05,0.05-1.86-0.69-1.9-1.72c-0.04-1.04,0.69-1.84,1.74-1.88
	C362.86,496.61,363.74,497.4,363.79,498.37z"
              />
              <path
                d="M430.43,122.11c0.92-0.07,1.87,0.82,1.9,1.77c0.03,0.87-0.7,1.73-1.55,1.85c-0.94,0.13-1.92-0.66-2.01-1.63
	C428.67,123.14,429.47,122.18,430.43,122.11z"
              />
              <path
                d="M338.36,354.16c-0.97-0.04-1.79-0.95-1.73-1.93c0.06-0.98,1-1.8,1.95-1.7c0.86,0.09,1.61,0.93,1.61,1.81
	C340.2,353.3,339.3,354.19,338.36,354.16z"
              />
              <path
                d="M403.81,86.86c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.81-0.94-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.69
	C403.15,85,403.87,85.81,403.81,86.86z"
              />
              <path
                d="M396.92,87.31c-0.05,0.95-0.93,1.75-1.9,1.74c-1.12-0.01-1.92-0.91-1.8-2.03c0.11-1.03,0.92-1.69,1.97-1.61
	C396.17,85.49,396.97,86.36,396.92,87.31z"
              />
              <path
                d="M418.49,172.21c-0.03,0.96-0.91,1.77-1.9,1.75c-1.03-0.02-1.8-0.82-1.77-1.84c0.03-1.02,0.82-1.76,1.87-1.74
	C417.69,170.39,418.52,171.23,418.49,172.21z"
              />
              <path
                d="M257.45,395.45c0.04,0.98-0.77,1.87-1.73,1.91c-0.93,0.04-1.85-0.82-1.9-1.77c-0.04-0.94,0.81-1.87,1.75-1.91
	C256.53,393.64,257.41,394.47,257.45,395.45z"
              />
              <path
                d="M403.58,351.94c0.91-0.06,1.86,0.81,1.91,1.74c0.05,0.95-0.76,1.87-1.71,1.92c-0.95,0.06-1.84-0.75-1.89-1.73
	C401.83,352.91,402.62,352,403.58,351.94z"
              />
              <path
                d="M316.97,352.44c0.96-0.06,1.86,0.8,1.88,1.78c0.02,0.99-0.83,1.86-1.8,1.85c-0.87-0.01-1.68-0.78-1.76-1.66
	C315.2,353.47,316.03,352.5,316.97,352.44z"
              />
              <path
                d="M404.73,575.83c-0.97,0.06-1.86-0.78-1.88-1.77c-0.02-0.98,0.83-1.87,1.8-1.86c0.86,0.01,1.69,0.79,1.77,1.66
	C406.5,574.83,405.69,575.78,404.73,575.83z"
              />
              <path
                d="M384.86,595.7c0.9-0.06,1.85,0.81,1.9,1.73c0.06,0.96-0.74,1.86-1.7,1.92c-0.95,0.06-1.83-0.75-1.89-1.72
	C383.12,596.68,383.92,595.76,384.86,595.7z"
              />
              <path
                d="M319.34,28.84c0.05,0.97-0.75,1.87-1.71,1.92c-0.92,0.05-1.85-0.8-1.9-1.74c-0.05-0.94,0.78-1.87,1.72-1.92
	C318.4,27.04,319.29,27.87,319.34,28.84z"
              />
              <path
                d="M421.94,520.15c0.99,0.02,1.84,0.83,1.86,1.78c0.02,1.02-0.99,1.94-2.04,1.86c-1.01-0.07-1.73-0.9-1.69-1.92
	C420.13,520.84,420.9,520.13,421.94,520.15z"
              />
              <path
                d="M580.4,620.26c0.04,1.03-0.72,1.84-1.76,1.87c-0.99,0.03-1.89-0.78-1.93-1.74c-0.04-0.97,0.78-1.83,1.79-1.86
	C579.57,618.5,580.36,619.23,580.4,620.26z"
              />
              <path
                d="M424.94,389.87c0.96-0.06,1.86,0.8,1.88,1.78c0.02,0.99-0.83,1.86-1.8,1.85c-0.87-0.01-1.68-0.78-1.76-1.66
	C423.17,390.9,424,389.93,424.94,389.87z"
              />
              <path
                d="M262.83,353.37c0.96-0.01,1.81,0.85,1.82,1.83c0.01,0.99-0.82,1.84-1.79,1.85c-0.95,0.01-1.83-0.87-1.84-1.83
	C261.01,354.29,261.9,353.38,262.83,353.37z"
              />
              <path
                d="M399.3,574.81c-0.04,0.96-0.94,1.77-1.93,1.74c-1.04-0.03-1.79-0.84-1.76-1.87c0.04-1.04,0.84-1.76,1.89-1.73
	C398.52,572.98,399.34,573.84,399.3,574.81z"
              />
              <path
                d="M284.61,252.36c-0.04,0.96-0.93,1.76-1.92,1.73c-1.03-0.04-1.79-0.84-1.75-1.86c0.04-1.03,0.84-1.75,1.89-1.72
	C283.84,250.54,284.65,251.38,284.61,252.36z"
              />
              <path
                d="M347.21,391.34c0.06,1.02-0.65,1.85-1.66,1.94c-1.05,0.09-2.07-0.81-2.06-1.83c0-0.95,0.85-1.78,1.84-1.81
	C346.37,389.6,347.15,390.31,347.21,391.34z"
              />
              <path
                d="M340.48,462.36c-0.98-0.05-1.82-0.91-1.8-1.86c0.02-1.04,1.02-1.89,2.1-1.77c1.02,0.11,1.7,0.93,1.62,1.96
	C342.32,461.71,341.51,462.42,340.48,462.36z"
              />
              <path
                d="M250.26,356.52c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.82-0.93-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.7
	C249.59,354.67,250.32,355.48,250.26,356.52z"
              />
              <path
                d="M301.59,240c0.03,0.98-0.78,1.86-1.75,1.89c-0.94,0.03-1.85-0.83-1.88-1.79c-0.03-0.94,0.83-1.86,1.77-1.9
	C300.69,238.18,301.56,239.02,301.59,240z"
              />
              <path
                d="M335.22,461.01c0.01,1.02-0.75,1.81-1.76,1.85c-0.98,0.03-1.87-0.74-1.95-1.69c-0.09-1.04,0.83-1.97,1.91-1.95
	C334.45,459.22,335.21,459.98,335.22,461.01z"
              />
              <path
                d="M434.72,125.44c0.96,0.05,1.76,0.97,1.71,1.94c-0.05,0.99-0.93,1.8-1.9,1.74c-0.96-0.05-1.78-0.97-1.73-1.93
	C432.85,126.27,433.8,125.39,434.72,125.44z"
              />
              <path
                d="M466.16,241.88c-0.95,0.06-1.84-0.74-1.9-1.71c-0.06-0.96,0.74-1.88,1.68-1.94c0.9-0.06,1.85,0.8,1.91,1.72
	C467.91,240.9,467.12,241.81,466.16,241.88z"
              />
              <path
                d="M375.83,325.59c-1.04-0.01-1.82-0.79-1.81-1.82c0.01-1.03,0.8-1.79,1.85-1.78c1.01,0.01,1.86,0.84,1.85,1.82
	C377.71,324.77,376.83,325.6,375.83,325.59z"
              />
              <path
                d="M337.78,323.62c-0.04,0.97-0.97,1.82-1.9,1.74c-0.96-0.08-1.74-1.04-1.64-2c0.09-0.87,0.93-1.62,1.78-1.62
	C336.98,321.75,337.82,322.65,337.78,323.62z"
              />
              <path
                d="M461.44,227.69c0.96-0.04,1.84,0.82,1.85,1.81c0,0.99-0.86,1.85-1.83,1.82c-0.88-0.03-1.67-0.8-1.73-1.68
	C459.65,228.65,460.46,227.72,461.44,227.69z"
              />
              <path
                d="M432.24,519.22c-0.95,0-1.82-0.9-1.79-1.87c0.03-0.87,0.81-1.69,1.66-1.76c0.95-0.07,1.87,0.77,1.9,1.75
	C434.05,518.33,433.21,519.21,432.24,519.22z"
              />
              <path
                d="M361.08,549.09c-0.01,0.97-0.86,1.81-1.81,1.81c-0.93-0.01-1.79-0.89-1.78-1.84c0.01-0.93,0.9-1.82,1.82-1.81
	C360.26,547.25,361.09,548.12,361.08,549.09z"
              />
              <path
                d="M403.53,99.08c0.96,0.06,1.77,1,1.69,1.98c-0.08,0.98-1.04,1.78-1.99,1.65c-0.85-0.11-1.59-0.97-1.57-1.84
	C401.67,99.92,402.61,99.02,403.53,99.08z"
              />
              <path
                d="M518.5,595.03c1.06-0.05,1.86,0.66,1.92,1.69c0.06,1.03-0.68,1.84-1.71,1.9c-1,0.05-1.89-0.73-1.95-1.7
	C516.69,595.94,517.49,595.08,518.5,595.03z"
              />
              <path
                d="M361.57,390.26c-0.01,0.98-0.84,1.82-1.81,1.82c-0.94,0-1.81-0.89-1.8-1.84c0-0.94,0.9-1.83,1.82-1.83
	C360.74,388.42,361.57,389.28,361.57,390.26z"
              />
              <path
                d="M289.33,389.35c-0.04,0.97-0.97,1.83-1.91,1.75c-0.96-0.08-1.76-1.04-1.65-2c0.09-0.88,0.92-1.63,1.78-1.63
	C288.51,387.47,289.37,388.39,289.33,389.35z"
              />
              <path
                d="M296.62,388.46c0.06,0.94-0.72,1.83-1.7,1.91c-1.03,0.09-1.87-0.57-1.99-1.58c-0.13-1.12,0.64-2.01,1.77-2.04
	C295.7,386.73,296.56,387.5,296.62,388.46z"
              />
              <path
                d="M377.9,576.57c0.05,1.03-0.69,1.84-1.72,1.89c-1,0.05-1.89-0.74-1.94-1.7c-0.05-0.97,0.75-1.83,1.75-1.88
	C377.04,574.83,377.85,575.54,377.9,576.57z"
              />
              <path
                d="M321,462.29c-0.04,0.97-0.97,1.83-1.92,1.74c-0.96-0.08-1.75-1.04-1.65-2.01c0.09-0.87,0.93-1.63,1.79-1.63
	C320.18,460.41,321.04,461.33,321,462.29z"
              />
              <path
                d="M301.98,386.3c1.01,0.01,1.84,0.85,1.82,1.82c-0.02,0.96-0.89,1.78-1.89,1.76c-1.04-0.01-1.81-0.8-1.79-1.82
	C300.15,387.04,300.94,386.28,301.98,386.3z"
              />
              <path
                d="M407.9,89.84c0.01,0.96-0.85,1.85-1.8,1.86c-0.97,0.01-1.81-0.83-1.83-1.81c-0.01-0.98,0.82-1.86,1.78-1.87
	C406.98,88.01,407.89,88.9,407.9,89.84z"
              />
              <path
                d="M427.55,359.04c0.01,0.94-0.86,1.83-1.79,1.84c-0.95,0.01-1.79-0.84-1.8-1.81c-0.01-0.97,0.83-1.83,1.78-1.84
	C426.66,357.22,427.54,358.1,427.55,359.04z"
              />
              <path
                d="M514.98,389.41c-1.04-0.01-1.82-0.79-1.81-1.82c0.01-1.03,0.8-1.79,1.85-1.78c1.01,0.01,1.86,0.84,1.85,1.82
	C516.86,388.59,515.98,389.42,514.98,389.41z"
              />
              <path
                d="M418.52,357.98c1.01-0.02,1.88,0.79,1.91,1.76c0.02,0.97-0.83,1.82-1.83,1.84c-1.05,0.02-1.84-0.73-1.86-1.76
	C416.71,358.77,417.47,358,418.52,357.98z"
              />
              <path
                d="M396.99,565.73c0.99,0.02,1.84,0.83,1.86,1.78c0.02,1.02-0.99,1.94-2.04,1.86c-1.01-0.07-1.73-0.9-1.69-1.92
	C395.17,566.43,395.95,565.71,396.99,565.73z"
              />
              <path
                d="M247.19,360.32c-0.04,0.96-0.94,1.77-1.93,1.74c-1.04-0.03-1.79-0.84-1.75-1.87c0.04-1.04,0.84-1.76,1.89-1.73
	C246.41,358.5,247.23,359.35,247.19,360.32z"
              />
              <path
                d="M308.92,389.2c-0.95,0.01-1.83-0.87-1.84-1.82c-0.01-0.94,0.88-1.85,1.81-1.86c0.96-0.01,1.81,0.85,1.82,1.83
	C310.72,388.34,309.89,389.2,308.92,389.2z"
              />
              <path
                d="M375.81,594.87c0.02,0.94-0.81,1.79-1.79,1.84c-1.03,0.05-1.83-0.65-1.91-1.66c-0.09-1.12,0.72-1.97,1.85-1.95
	C374.95,593.11,375.79,593.92,375.81,594.87z"
              />
              <path
                d="M363.07,554.25c-0.95-0.03-1.77-0.92-1.74-1.88c0.03-0.94,0.95-1.81,1.86-1.77c0.95,0.04,1.76,0.93,1.73,1.89
	C364.89,553.46,364.02,554.29,363.07,554.25z"
              />
              <path
                d="M351.53,465c-0.95-0.05-1.76-0.94-1.72-1.9c0.04-0.93,0.98-1.8,1.88-1.75c0.95,0.05,1.75,0.95,1.71,1.91
	C353.36,464.23,352.48,465.05,351.53,465z"
              />
              <path
                d="M346.47,463.74c-0.05,0.94-0.97,1.8-1.89,1.75c-0.96-0.05-1.76-0.94-1.71-1.91c0.04-0.97,0.93-1.8,1.87-1.75
	C345.67,461.88,346.51,462.81,346.47,463.74z"
              />
              <path
                d="M447.68,216.61c-0.03,0.97-0.97,1.83-1.91,1.76c-0.96-0.08-1.76-1.04-1.66-2c0.09-0.87,0.92-1.64,1.78-1.64
	C446.85,214.73,447.72,215.64,447.68,216.61z"
              />
              <path
                d="M422.3,464.21c-0.04-0.97,0.81-1.87,1.77-1.87c0.85,0,1.68,0.76,1.77,1.63c0.1,0.95-0.7,1.92-1.65,1.99
	C423.27,466.03,422.34,465.17,422.3,464.21z"
              />
              <path
                d="M388.28,595.53c-0.96-0.02-1.78-0.89-1.76-1.87c0.02-0.97,0.88-1.82,1.83-1.8c0.95,0.02,1.8,0.92,1.78,1.87
	C390.11,594.69,389.22,595.55,388.28,595.53z"
              />
              <path
                d="M528.54,250.98c1.01,0.05,1.82,0.92,1.76,1.89c-0.06,0.97-0.96,1.76-1.96,1.71c-1.03-0.05-1.78-0.87-1.72-1.9
	C526.67,251.64,527.48,250.92,528.54,250.98z"
              />
              <path
                d="M430.17,181.93c0.04,0.98-0.79,1.87-1.76,1.88c-0.85,0.01-1.68-0.75-1.78-1.62c-0.1-0.97,0.67-1.91,1.63-1.99
	C429.21,180.12,430.13,180.95,430.17,181.93z"
              />
              <path
                d="M431.37,362.33c0.05,0.97-0.8,1.89-1.76,1.9c-0.97,0.01-1.83-0.86-1.81-1.85c0.02-0.88,0.77-1.7,1.64-1.78
	C430.36,360.51,431.32,361.37,431.37,362.33z"
              />
              <path
                d="M476.54,506.7c-0.06,0.97-0.95,1.75-1.95,1.7c-1.03-0.05-1.77-0.87-1.71-1.9c0.06-1.03,0.86-1.74,1.92-1.69
	C475.8,504.87,476.6,505.73,476.54,506.7z"
              />
              <path
                d="M424.24,363.36c-0.13,0.96-1.04,1.67-2.02,1.59c-1.09-0.09-1.84-1.07-1.64-2.16c0.18-0.98,1.07-1.59,2.09-1.45
	C423.65,361.49,424.38,362.42,424.24,363.36z"
              />
              <path
                d="M415.78,555.23c0.04,0.96-0.76,1.86-1.71,1.91c-0.92,0.05-1.84-0.81-1.88-1.75c-0.04-0.94,0.79-1.86,1.72-1.9
	C414.85,553.44,415.74,554.27,415.78,555.23z"
              />
              <path
                d="M418.15,188.13c-0.97,0.04-1.85-0.8-1.86-1.79c-0.01-0.99,0.85-1.86,1.82-1.84c0.85,0.02,1.68,0.82,1.74,1.68
	C419.93,187.15,419.12,188.08,418.15,188.13z"
              />
              <path
                d="M529.5,285.21c-0.03,0.98-0.93,1.81-1.88,1.75c-0.96-0.06-1.75-1-1.66-1.97c0.08-0.86,0.91-1.64,1.76-1.64
	C528.67,283.35,529.52,284.23,529.5,285.21z"
              />
              <path
                d="M376.54,582.06c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C374.68,582.91,375.53,582.07,376.54,582.06z"
              />
              <path
                d="M429.73,467.37c-0.06,1.03-0.88,1.75-1.93,1.69c-1.01-0.06-1.82-0.93-1.75-1.9c0.06-0.97,0.96-1.75,1.96-1.7
	C429.06,465.51,429.78,466.32,429.73,467.37z"
              />
              <path
                d="M368.77,555.68c0.06,0.98-0.73,1.89-1.69,1.95c-0.93,0.06-1.87-0.79-1.93-1.73c-0.06-0.94,0.77-1.88,1.72-1.95
	C367.81,553.88,368.71,554.7,368.77,555.68z"
              />
              <path
                d="M396.82,479.09c0.96,0.06,1.76,0.98,1.7,1.95c-0.06,0.99-0.94,1.79-1.91,1.73c-0.95-0.06-1.78-0.99-1.72-1.94
	C394.95,479.9,395.91,479.03,396.82,479.09z"
              />
              <path
                d="M422.51,467.87c0.06,0.97-0.73,1.88-1.69,1.94c-0.92,0.06-1.86-0.78-1.92-1.72c-0.06-0.94,0.77-1.88,1.7-1.94
	C421.54,466.08,422.45,466.9,422.51,467.87z"
              />
              <path
                d="M449.64,218.05c0.91-0.06,1.87,0.82,1.92,1.75c0.05,0.95-0.77,1.88-1.73,1.93c-0.96,0.06-1.85-0.75-1.9-1.74
	C447.88,219.03,448.69,218.11,449.64,218.05z"
              />
              <path
                d="M415.31,468.39c0.04,0.96-0.77,1.85-1.72,1.9c-0.92,0.04-1.83-0.81-1.87-1.75c-0.04-0.93,0.8-1.86,1.73-1.9
	C414.38,466.59,415.27,467.43,415.31,468.39z"
              />
              <path
                d="M487.97,556.34c-0.04,0.97-0.97,1.82-1.9,1.74c-0.95-0.08-1.74-1.04-1.64-2c0.09-0.87,0.93-1.62,1.78-1.62
	C487.17,554.47,488.01,555.37,487.97,556.34z"
              />
              <path
                d="M499.8,566.1c0,1.02-0.76,1.8-1.78,1.83c-0.98,0.03-1.87-0.75-1.94-1.7c-0.08-1.04,0.84-1.97,1.93-1.94
	C499.05,564.31,499.8,565.07,499.8,566.1z"
              />
              <path
                d="M472.07,187.18c-0.94,0.01-1.82-0.86-1.82-1.82c-0.01-0.94,0.87-1.84,1.8-1.85c0.95-0.01,1.8,0.85,1.81,1.82
	C473.86,186.32,473.03,187.18,472.07,187.18z"
              />
              <path
                d="M396.15,578.66c-0.04,1.02-0.81,1.75-1.84,1.74c-0.98-0.01-1.84-0.82-1.86-1.77c-0.03-1.04,0.93-1.92,2.01-1.86
	C395.47,576.84,396.18,577.63,396.15,578.66z"
              />
              <path
                d="M457.64,224.58c1.05-0.01,1.84,0.75,1.85,1.78c0.01,1.03-0.77,1.81-1.81,1.82c-1,0.01-1.88-0.82-1.89-1.78
	C455.78,225.43,456.63,224.59,457.64,224.58z"
              />
              <path
                d="M431.79,508.65c1.05,0.02,1.8,0.8,1.78,1.84c-0.02,1.03-0.82,1.78-1.87,1.76c-1.01-0.02-1.85-0.87-1.82-1.84
	C429.91,509.43,430.78,508.62,431.79,508.65z"
              />
              <path
                d="M379.83,558.35c0.05,1.01-0.68,1.83-1.69,1.9c-1.06,0.07-2.05-0.83-2.02-1.85c0.02-0.95,0.87-1.76,1.85-1.77
	C379.01,556.61,379.79,557.33,379.83,558.35z"
              />
              <path
                d="M430.94,374.97c0.91-0.05,1.85,0.82,1.89,1.76c0.05,0.96-0.78,1.86-1.73,1.91c-0.96,0.05-1.83-0.77-1.88-1.74
	C429.19,375.92,429.99,375.02,430.94,374.97z"
              />
              <path
                d="M339,68.65c-0.95,0.06-1.84-0.75-1.89-1.72c-0.06-0.96,0.74-1.87,1.69-1.94c0.9-0.06,1.85,0.8,1.9,1.73
	C340.75,67.69,339.95,68.59,339,68.65z"
              />
              <path
                d="M401.41,243.79c1.05-0.05,1.86,0.66,1.92,1.69c0.06,1.03-0.68,1.84-1.71,1.9c-1,0.05-1.89-0.73-1.95-1.7
	C399.61,244.7,400.41,243.84,401.41,243.79z"
              />
              <path
                d="M402.76,261.76c-1.04,0.02-1.84-0.73-1.86-1.75c-0.02-1.03,0.74-1.81,1.78-1.84c1.01-0.02,1.87,0.77,1.9,1.75
	C404.6,260.89,403.77,261.74,402.76,261.76z"
              />
              <path
                d="M287.58,185.02c-1.03,0.02-1.82-0.72-1.86-1.74c-0.04-1.03,0.67-1.84,1.69-1.91c1.08-0.07,2.05,0.82,2.03,1.85
	C289.43,184.17,288.56,185,287.58,185.02z"
              />
              <path
                d="M393.05,566c-1.02-0.03-1.77-0.82-1.77-1.83c0.01-1.02,0.76-1.77,1.79-1.8c1.08-0.02,1.99,0.89,1.91,1.93
	C394.92,565.26,394.03,566.04,393.05,566z"
              />
              <path
                d="M415.88,242.53c0.95-0.02,1.82,0.83,1.84,1.81c0.02,0.99-0.8,1.86-1.77,1.88c-0.94,0.02-1.84-0.85-1.86-1.8
	C414.07,243.47,414.93,242.55,415.88,242.53z"
              />
              <path
                d="M347.92,66.34c0.01,0.96-0.86,1.84-1.81,1.86c-0.97,0.01-1.81-0.83-1.82-1.82c-0.01-0.99,0.82-1.85,1.78-1.86
	C347,64.5,347.91,65.4,347.92,66.34z"
              />
              <path
                d="M412.44,559.07c0.04,0.98-0.76,1.87-1.72,1.92c-0.93,0.04-1.86-0.81-1.9-1.76c-0.04-0.94,0.8-1.87,1.75-1.92
	C411.51,557.26,412.4,558.09,412.44,559.07z"
              />
              <path
                d="M244.97,378.65c0.04,0.98-0.76,1.87-1.72,1.92c-0.93,0.04-1.86-0.81-1.9-1.76c-0.04-0.94,0.8-1.87,1.75-1.92
	C244.04,376.84,244.93,377.67,244.97,378.65z"
              />
              <path
                d="M475.53,511.99c1.03,0.06,1.74,0.84,1.71,1.87c-0.03,1.02-0.8,1.76-1.83,1.75c-1.08,0-1.97-0.95-1.86-1.99
	C473.66,512.66,474.54,511.94,475.53,511.99z"
              />
              <path
                d="M494.1,564.57c-0.98-0.01-1.84-0.84-1.87-1.79c-0.03-1.04,0.95-1.93,2.02-1.86c1.02,0.07,1.74,0.87,1.7,1.9
	C495.92,563.85,495.13,564.59,494.1,564.57z"
              />
              <path
                d="M329.73,64.23c-0.02,0.96-0.9,1.79-1.9,1.77c-1.04-0.02-1.81-0.81-1.79-1.84c0.02-1.03,0.81-1.78,1.86-1.76
	C328.91,62.42,329.75,63.25,329.73,64.23z"
              />
              <path
                d="M430.22,514.32c-0.01,1.02-0.78,1.78-1.81,1.79c-0.98,0.01-1.86-0.79-1.91-1.74c-0.06-1.04,0.89-1.95,1.97-1.91
	C429.51,512.51,430.24,513.28,430.22,514.32z"
              />
              <path
                d="M283.44,261.27c-1.01-0.02-1.83-0.84-1.81-1.82c0.02-0.98,0.87-1.77,1.88-1.75c1.03,0.02,1.79,0.8,1.78,1.82
	C285.27,260.55,284.48,261.29,283.44,261.27z"
              />
              <path
                d="M238.99,140.71c0.03,0.97-0.75,1.85-1.72,1.89c-0.79,0.04-1.43-0.22-1.51-1.12c-0.09-1.04,0.92-2.58,1.64-2.55
	C238.17,138.95,238.97,139.84,238.99,140.71z"
              />
              <path
                d="M378.71,567.41c-1.03-0.01-1.81-0.78-1.81-1.79c0.01-1.01,0.8-1.77,1.83-1.77c1,0,1.84,0.82,1.84,1.79
	C380.57,566.6,379.72,567.41,378.71,567.41z"
              />
              <path
                d="M523.99,244c-1,0.06-1.88-0.7-1.95-1.67c-0.07-0.98,0.71-1.84,1.71-1.89c1.05-0.06,1.86,0.64,1.92,1.66
	C525.75,243.12,525.02,243.94,523.99,244z"
              />
              <path
                d="M326.55,526.65c0.06,1.02-0.66,1.84-1.69,1.9c-1,0.06-1.88-0.7-1.94-1.68c-0.06-0.98,0.72-1.83,1.72-1.89
	C325.68,524.92,326.48,525.62,326.55,526.65z"
              />
              <path
                d="M337.58,462.6c1,0.06,1.78,0.92,1.71,1.89c-0.07,0.97-0.95,1.73-1.95,1.67c-1.03-0.06-1.75-0.88-1.69-1.9
	C335.72,463.24,336.53,462.54,337.58,462.6z"
              />
              <path
                d="M424.23,529.11c0.03,0.97-0.78,1.8-1.79,1.83c-1.05,0.03-1.84-0.71-1.86-1.73c-0.03-1.02,0.73-1.81,1.76-1.84
	C423.34,527.35,424.2,528.13,424.23,529.11z"
              />
              <path
                d="M354.04,240.64c-1,0.02-1.86-0.79-1.88-1.75c-0.02-0.97,0.81-1.8,1.81-1.81c1.03-0.01,1.83,0.74,1.85,1.75
	C355.85,239.85,355.08,240.62,354.04,240.64z"
              />
              <path
                d="M357.04,213.54c-0.06,0.97-0.95,1.73-1.94,1.68c-1.03-0.06-1.76-0.87-1.69-1.9c0.06-1.02,0.87-1.72,1.92-1.67
	C356.33,211.7,357.11,212.56,357.04,213.54z"
              />
              <path
                d="M490.11,557.61c1,0.03,1.81,0.86,1.78,1.84c-0.03,0.96-0.9,1.76-1.9,1.73c-1.03-0.03-1.79-0.82-1.75-1.84
	C488.27,558.32,489.07,557.58,490.11,557.61z"
              />
              <path
                d="M565.3,614.64c-0.04,0.96-0.92,1.75-1.91,1.71c-1.03-0.04-1.78-0.83-1.74-1.86c0.04-1.02,0.84-1.74,1.88-1.71
	C564.54,612.82,565.34,613.66,565.3,614.64z"
              />
              <path
                d="M372.62,598.68c0.03,1.02-0.73,1.81-1.76,1.84c-1,0.03-1.87-0.77-1.89-1.73c-0.03-0.98,0.78-1.81,1.79-1.83
	C371.79,596.93,372.59,597.67,372.62,598.68z"
              />
              <path
                d="M320.21,19.78c-0.98-0.01-1.68-0.64-1.72-1.57c-0.03-0.91,1.1-1.95,2.06-1.82c0.9,0.12,1.39,0.72,1.45,1.61
	C322.08,18.97,321.22,19.79,320.21,19.78z"
              />
              <path
                d="M419.02,408.58c-1.03-0.01-1.81-0.78-1.81-1.79c0.01-1.01,0.8-1.77,1.83-1.77c1,0,1.84,0.82,1.84,1.79
	C420.88,407.78,420.03,408.59,419.02,408.58z"
              />
              <path
                d="M440.76,137.6c0.01,1.01-0.77,1.78-1.81,1.79c-1,0-1.85-0.81-1.86-1.78c0-0.97,0.84-1.79,1.84-1.79
	C439.97,135.83,440.76,136.59,440.76,137.6z"
              />
              <path
                d="M374.58,600.31c1.04-0.03,1.84,0.69,1.88,1.71c0.04,1.02-0.71,1.82-1.74,1.85c-1,0.03-1.87-0.75-1.91-1.72
	C372.77,601.19,373.57,600.35,374.58,600.31z"
              />
              <path
                d="M407.36,418.23c0.05-0.98,0.92-1.75,1.92-1.7c1.04,0.05,1.77,0.84,1.72,1.87c-0.05,1.02-0.85,1.74-1.89,1.69
	C408.1,420.05,407.31,419.21,407.36,418.23z"
              />
              <path
                d="M334.5,493.73c-0.05,0.97-0.92,1.74-1.92,1.7c-1.03-0.04-1.77-0.84-1.72-1.87c0.05-1.02,0.85-1.74,1.9-1.69
	C333.75,491.91,334.54,492.76,334.5,493.73z"
              />
              <path
                d="M414.83,421.69c-0.01,1-0.79,1.76-1.81,1.75c-1,0-1.82-0.81-1.81-1.78c0.01-0.97,0.84-1.76,1.84-1.75
	C414.07,419.92,414.84,420.68,414.83,421.69z"
              />
              <path
                d="M311.6,374.54c0.96,0.03,1.78,0.94,1.68,1.91c-0.1,1-0.7,1.58-1.69,1.66c-0.95,0.08-1.82-0.81-1.82-1.78
	C309.77,375.36,310.64,374.51,311.6,374.54z"
              />
              <path
                d="M435.92,372.99c-0.01,0.97-0.89,1.81-1.84,1.77c-0.95-0.04-1.75-0.95-1.67-1.93c0.06-0.87,0.86-1.64,1.71-1.66
	C435.07,371.16,435.93,372.02,435.92,372.99z"
              />
              <path
                d="M412.22,472.47c0.04,0.98-0.76,1.81-1.76,1.84c-1.04,0.03-1.84-0.69-1.87-1.71c-0.03-1.01,0.71-1.81,1.74-1.84
	C411.33,470.72,412.18,471.49,412.22,472.47z"
              />
              <path
                d="M399.96,581.94c0,1-0.78,1.76-1.8,1.76c-1,0-1.82-0.8-1.82-1.77c0-0.97,0.82-1.77,1.82-1.76
	C399.18,580.18,399.96,580.95,399.96,581.94z"
              />
              <path
                d="M433.85,513.55c0.01-0.98,0.89-1.88,1.82-1.77c0.95,0.12,1.6,0.66,1.68,1.68c0.08,0.96-0.76,1.87-1.71,1.9
	C434.7,515.4,433.84,514.52,433.85,513.55z"
              />
              <path
                d="M396.12,372.78c-0.02,1.01-0.81,1.74-1.84,1.72c-1-0.02-1.8-0.83-1.77-1.81c0.03-0.98,0.86-1.75,1.86-1.72
	C395.38,370.99,396.14,371.77,396.12,372.78z"
              />
              <path
                d="M391.29,481.46c-0.03,0.97-0.92,1.8-1.87,1.74c-0.96-0.06-1.73-0.98-1.64-1.95c0.08-0.85,0.91-1.63,1.74-1.63
	C390.48,479.62,391.32,480.49,391.29,481.46z"
              />
              <path
                d="M459.14,159.35c1.02,0.03,1.78,0.81,1.76,1.82c-0.02,1.01-0.81,1.75-1.85,1.73c-1.01-0.02-1.81-0.84-1.78-1.82
	C457.3,160.1,458.14,159.32,459.14,159.35z"
              />
              <path
                d="M626.17,625.27c1.02,0.05,1.76,0.85,1.72,1.86c-0.05,1.02-0.84,1.73-1.89,1.69c-1.01-0.04-1.79-0.87-1.74-1.85
	C624.31,625.98,625.17,625.22,626.17,625.27z"
              />
              <path
                d="M398.78,92.36c-1.02-0.05-1.76-0.86-1.7-1.87c0.06-1.02,0.86-1.72,1.9-1.67c1.01,0.05,1.78,0.89,1.72,1.87
	C400.64,91.66,399.78,92.41,398.78,92.36z"
              />
              <path
                d="M433.06,463.65c-0.12,0.99-0.99,1.66-2.02,1.54c-0.99-0.12-1.7-1.02-1.57-1.99c0.13-1,1.01-1.66,2.03-1.54
	C432.5,461.78,433.18,462.64,433.06,463.65z"
              />
              <path
                d="M293.67,253.14c0.95-0.07,1.85,0.76,1.85,1.74c0,1.02-0.56,1.63-1.52,1.82c-0.92,0.18-1.89-0.64-1.99-1.6
	C291.93,254.15,292.71,253.21,293.67,253.14z"
              />
              <path
                d="M387.96,485.57c-0.04,1.02-0.84,1.73-1.88,1.69c-1.01-0.04-1.79-0.87-1.74-1.85c0.05-0.98,0.91-1.74,1.9-1.7
	C387.27,483.76,388.01,484.56,387.96,485.57z"
              />
              <path
                d="M374.86,564.03c-1.02-0.01-1.79-0.79-1.78-1.79c0.01-0.99,0.81-1.75,1.82-1.74c1,0.01,1.81,0.81,1.79,1.79
	C376.69,563.26,375.87,564.05,374.86,564.03z"
              />
              <path
                d="M410.52,245.06c0.02,1.01-0.74,1.79-1.77,1.81c-1,0.02-1.85-0.77-1.86-1.74c-0.02-0.98,0.79-1.79,1.79-1.81
	C409.71,243.31,410.5,244.06,410.52,245.06z"
              />
              <path
                d="M284.08,245.18c-0.05,0.98-0.9,1.73-1.9,1.68c-1.02-0.05-1.75-0.85-1.7-1.86c0.05-1.01,0.86-1.72,1.89-1.67
	C283.37,243.38,284.13,244.2,284.08,245.18z"
              />
              <path
                d="M477.69,521.1c-0.06,1.02-0.86,1.72-1.9,1.67c-1.01-0.05-1.78-0.89-1.72-1.87c0.06-0.98,0.92-1.73,1.92-1.68
	C477.02,519.28,477.75,520.09,477.69,521.1z"
              />
              <path
                d="M427.57,525.33c0,0.97-0.83,1.76-1.83,1.76c-1.02-0.01-1.8-0.78-1.79-1.77c0.01-0.99,0.8-1.76,1.81-1.76
	C426.76,523.55,427.57,524.35,427.57,525.33z"
              />
              <path
                d="M455.3,448.69c1.02,0.03,1.78,0.81,1.76,1.82c-0.02,1.01-0.81,1.75-1.85,1.73c-1.01-0.02-1.81-0.84-1.78-1.82
	C453.46,449.44,454.3,448.66,455.3,448.69z"
              />
              <path
                d="M433.56,363.99c0.95-0.07,1.85,0.76,1.88,1.73c0.03,0.97-0.8,1.85-1.76,1.86c-0.83,0.01-1.67-0.77-1.75-1.62
	C431.83,364.99,432.61,364.05,433.56,363.99z"
              />
              <path
                d="M288.63,255.73c-0.04,1.01-0.83,1.72-1.87,1.68c-1.01-0.04-1.78-0.86-1.73-1.84c0.05-0.98,0.89-1.73,1.89-1.69
	C287.93,253.93,288.67,254.73,288.63,255.73z"
              />
              <path
                d="M243.71,364.42c-0.05,0.98-0.95,1.79-1.89,1.7c-0.96-0.09-1.75-1.04-1.61-1.98c0.15-1,0.81-1.53,1.78-1.58
	C242.93,362.5,243.76,363.45,243.71,364.42z"
              />
              <path
                d="M444.85,141.04c-0.04,0.97-0.9,1.75-1.89,1.71c-1.02-0.04-1.77-0.83-1.74-1.85c0.04-1.01,0.84-1.74,1.87-1.7
	C444.1,139.23,444.89,140.05,444.85,141.04z"
              />
              <path
                d="M375.27,587.63c0.04,0.98-0.76,1.81-1.76,1.84c-1.04,0.03-1.84-0.69-1.87-1.71c-0.03-1.01,0.71-1.81,1.74-1.84
	C374.38,585.89,375.24,586.65,375.27,587.63z"
              />
              <path
                d="M327.2,494.28c0.05,0.98-0.77,1.87-1.72,1.88c-0.96,0.01-1.83-0.85-1.79-1.82c0.04-1.02,0.66-1.59,1.61-1.74
	C326.25,492.45,327.15,493.3,327.2,494.28z"
              />
              <path
                d="M297.56,608.74c0.05,0.97-0.77,1.82-1.77,1.85c-0.96,0.03-1.7-0.6-1.79-1.52c-0.1-1.01,0.61-1.88,1.62-1.98
	C296.61,607,297.51,607.76,297.56,608.74z"
              />
              <path
                d="M367.03,494.37c0,0.97-0.87,1.84-1.8,1.8c-0.96-0.04-1.79-0.95-1.7-1.91c0.09-1.02,0.74-1.56,1.69-1.67
	C366.15,492.48,367.03,493.39,367.03,494.37z"
              />
              <path
                d="M278.05,260.29c-0.05,0.97-0.96,1.78-1.9,1.7c-0.95-0.08-1.72-1.03-1.61-1.98c0.09-0.84,0.96-1.62,1.77-1.6
	C277.27,258.43,278.1,259.33,278.05,260.29z"
              />
              <path
                d="M370.92,560.68c-1.01,0.06-1.85-0.68-1.92-1.66c-0.06-0.98,0.69-1.82,1.69-1.87c1.03-0.06,1.84,0.64,1.9,1.65
	C372.66,559.8,371.94,560.62,370.92,560.68z"
              />
              <path
                d="M403.32,578.04c0.04,1.01-0.71,1.81-1.74,1.84c-1,0.03-1.85-0.74-1.89-1.71c-0.04-0.98,0.75-1.81,1.76-1.84
	C402.49,576.3,403.28,577.02,403.32,578.04z"
              />
              <path
                d="M349.3,436.19c-0.94,0.05-1.83-0.81-1.82-1.78c0.01-1.02,0.6-1.61,1.56-1.78c0.92-0.17,1.88,0.68,1.95,1.64
	C351.06,435.22,350.26,436.14,349.3,436.19z"
              />
              <path
                d="M391.58,591.38c-1.02-0.05-1.75-0.86-1.7-1.86c0.06-1.01,0.86-1.72,1.89-1.67c1,0.05,1.76,0.87,1.71,1.86
	C393.43,590.69,392.58,591.44,391.58,591.38z"
              />
              <path
                d="M477.39,434.3c0.05,0.98-0.77,1.87-1.72,1.88c-0.96,0.01-1.83-0.85-1.79-1.82c0.04-1.02,0.66-1.59,1.61-1.74
	C476.44,432.47,477.35,433.32,477.39,434.3z"
              />
              <path
                d="M476.64,526.43c1,0.01,1.8,0.81,1.79,1.79c-0.01,0.97-0.84,1.76-1.85,1.74c-1.02-0.02-1.79-0.79-1.77-1.79
	C474.83,527.18,475.62,526.42,476.64,526.43z"
              />
              <path
                d="M276.22,73.98c-0.23,0.05-0.47,0.05-0.68,0.15c-0.85,0.38-1.79,0.6-2.32-0.32c-0.6-1.01,0.17-1.81,0.9-2.41
	c0.36-0.3,1.04-0.56,1.41-0.41c0.41,0.17,0.83,0.78,0.9,1.25c0.09,0.58-0.18,1.22-0.29,1.83C276.14,74.07,276.22,73.98,276.22,73.98
	z"
              />
              <path
                d="M476.61,197.44c-1.03,0-1.81-0.77-1.81-1.77c0-1,0.79-1.77,1.81-1.78c1,0,1.83,0.8,1.83,1.77
	C478.45,196.64,477.62,197.45,476.61,197.44z"
              />
              <path
                d="M431.15,129.35c0.95-0.07,1.85,0.75,1.88,1.72c0.04,0.97-0.8,1.85-1.76,1.86c-0.83,0.01-1.67-0.77-1.76-1.61
	C429.43,130.36,430.21,129.42,431.15,129.35z"
              />
              <path
                d="M315.51,25.8c0.01,1.02-0.79,1.79-1.83,1.77c-0.95-0.02-1.67-0.69-1.74-1.62c-0.07-1.02,0.68-1.85,1.71-1.89
	C314.69,24.02,315.5,24.78,315.51,25.8z"
              />
              <path
                d="M271.11,387.35c-0.13,0.99-1.01,1.65-2.01,1.53c-1.01-0.12-1.68-0.98-1.55-1.98c0.13-0.99,0.99-1.65,2.01-1.53
	C270.53,385.48,271.23,386.38,271.11,387.35z"
              />
              <path
                d="M320.01,494.87c-0.01,0.97-0.89,1.83-1.83,1.78c-0.96-0.05-1.78-0.97-1.68-1.93c0.11-1.02,0.77-1.55,1.72-1.65
	C319.15,492.96,320.02,493.9,320.01,494.87z"
              />
              <path
                d="M416.52,356.31c0.06,0.98-0.7,1.81-1.71,1.86c-1.04,0.05-1.84-0.65-1.89-1.66c-0.06-1.01,0.67-1.81,1.69-1.87
	C415.61,354.59,416.46,355.33,416.52,356.31z"
              />
              <path
                d="M508.22,41.63c-0.03,1.31-0.74,1.64-1.58,1.75c-1.02,0.13-2-0.61-1.98-1.62c0.01-0.56,0.31-1.26,0.73-1.61
	c0.25-0.21,1.04-0.02,1.45,0.23C507.42,40.72,507.86,41.28,508.22,41.63z"
              />
              <path
                d="M445.34,500.25c0.94-0.05,1.84,0.8,1.82,1.78c-0.02,1.01-0.59,1.62-1.55,1.79c-0.93,0.16-1.88-0.67-1.95-1.64
	C443.57,501.22,444.38,500.3,445.34,500.25z"
              />
              <path
                d="M314.37,323.67c0.94-0.03,1.82,0.84,1.79,1.82c-0.03,1.01-0.61,1.61-1.58,1.76c-0.93,0.14-1.86-0.7-1.92-1.67
	C312.59,324.61,313.42,323.7,314.37,323.67z"
              />
              <path
                d="M375.27,567.69c1-0.05,1.85,0.69,1.9,1.67c0.06,0.98-0.71,1.81-1.71,1.86c-1.04,0.05-1.84-0.65-1.89-1.67
	C373.52,568.55,374.25,567.75,375.27,567.69z"
              />
              <path
                d="M389.33,356.74c-1.03,0.02-1.82-0.71-1.84-1.72c-0.02-1,0.73-1.79,1.75-1.81c1-0.03,1.83,0.74,1.86,1.72
	C391.12,355.91,390.33,356.72,389.33,356.74z"
              />
              <path
                d="M408.03,571.98c-0.96,0.02-1.81-0.84-1.8-1.81c0.01-0.97,0.88-1.81,1.84-1.77c0.86,0.04,1.63,0.81,1.67,1.69
	C409.79,571.07,408.98,571.96,408.03,571.98z"
              />
              <path
                d="M238.12,175.35c0.5-0.2,0.99-0.48,1.51-0.59c1.16-0.24,2.15,0.44,2.22,1.52c0.07,0.97-0.37,1.68-1.37,1.91
	c-1.11,0.26-1.98-0.33-2.21-1.54c-0.09-0.45-0.05-0.93-0.07-1.39C238.2,175.27,238.12,175.35,238.12,175.35z"
              />
              <path
                d="M486.05,428.05c1.02,0.05,1.76,0.85,1.72,1.86c-0.05,1.02-0.84,1.73-1.89,1.69c-1.01-0.04-1.79-0.87-1.74-1.85
	C484.2,428.77,485.05,428.01,486.05,428.05z"
              />
              <path
                d="M378.43,607.01c-0.96-0.03-1.75-0.93-1.69-1.91c0.06-0.88,0.83-1.65,1.69-1.68c0.95-0.03,1.82,0.82,1.82,1.8
	C380.25,606.19,379.39,607.04,378.43,607.01z"
              />
              <path
                d="M357.02,546.05c-0.05,0.98-0.9,1.73-1.9,1.68c-1.02-0.05-1.75-0.86-1.7-1.86c0.05-1.01,0.86-1.72,1.89-1.67
	C356.31,544.24,357.07,545.06,357.02,546.05z"
              />
              <path
                d="M359.64,202.49c-0.06,1.01-0.85,1.71-1.89,1.66c-1.01-0.05-1.76-0.88-1.71-1.86c0.06-0.98,0.91-1.72,1.91-1.67
	C358.97,200.67,359.7,201.48,359.64,202.49z"
              />
              <path
                d="M474.08,497.64c1.02,0.06,1.74,0.87,1.68,1.88c-0.06,1.01-0.86,1.71-1.9,1.65c-1-0.06-1.76-0.89-1.69-1.87
	C472.23,498.31,473.08,497.58,474.08,497.64z"
              />
              <path
                d="M505.64,404.55c1.02,0,1.8,0.77,1.8,1.77c0,1-0.78,1.76-1.8,1.76c-1,0-1.82-0.79-1.82-1.77
	C503.82,405.34,504.64,404.55,505.64,404.55z"
              />
              <path
                d="M273.77,210.08c-0.01,1.01-0.79,1.77-1.82,1.76c-1-0.01-1.83-0.82-1.82-1.79c0.01-0.97,0.85-1.77,1.85-1.76
	C273,208.3,273.77,209.07,273.77,210.08z"
              />
              <path
                d="M426.79,122.37c-0.95,0.05-1.84-0.78-1.86-1.75c-0.02-0.97,0.83-1.84,1.78-1.83c0.85,0.01,1.66,0.78,1.73,1.64
	C428.53,121.4,427.75,122.32,426.79,122.37z"
              />
              <path
                d="M410.51,411.2c-0.04,1.01-0.83,1.72-1.87,1.68c-1.01-0.04-1.78-0.86-1.73-1.84c0.05-0.98,0.89-1.73,1.89-1.69
	C409.81,409.39,410.55,410.19,410.51,411.2z"
              />
              <path
                d="M381.7,413.68c0.04,0.97-0.79,1.86-1.74,1.87c-0.94,0.02-1.81-0.87-1.76-1.84c0.05-1.02,0.65-1.59,1.61-1.73
	C380.74,411.85,381.65,412.71,381.7,413.68z"
              />
              <path
                d="M419.7,538.42c1.03-0.03,1.83,0.71,1.86,1.72c0.03,1.01-0.73,1.8-1.75,1.83c-1,0.03-1.85-0.76-1.88-1.72
	C417.89,539.27,418.69,538.45,419.7,538.42z"
              />
              <path
                d="M334.67,414.27c-0.02,0.97-0.9,1.8-1.86,1.75c-0.96-0.05-1.74-0.97-1.66-1.94c0.07-0.86,0.88-1.63,1.73-1.64
	C333.84,412.43,334.69,413.31,334.67,414.27z"
              />
              <path
                d="M367.41,604.34c-1-0.02-1.8-0.83-1.78-1.81c0.02-0.98,0.86-1.75,1.86-1.72c1.02,0.03,1.78,0.81,1.76,1.81
	C369.23,603.62,368.44,604.36,367.41,604.34z"
              />
              <path
                d="M352.73,495.56c0,0.97-0.83,1.78-1.83,1.78c-1.03,0-1.81-0.76-1.81-1.77c0-1,0.78-1.77,1.81-1.78
	C351.89,493.79,352.73,494.59,352.73,495.56z"
              />
              <path
                d="M416.49,419.61c-1,0.05-1.86-0.71-1.92-1.68c-0.05-0.98,0.72-1.82,1.72-1.87c1.04-0.05,1.84,0.66,1.9,1.68
	C418.25,418.76,417.51,419.56,416.49,419.61z"
              />
              <path
                d="M424.9,536.4c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.74-1.82,1.77-1.82
	C424.13,534.6,424.92,535.39,424.9,536.4z"
              />
              <path
                d="M478.87,535.28c0.06,1.01-0.7,1.83-1.72,1.86c-1.04,0.03-1.88-0.75-1.83-1.75c0.05-1,0.59-1.62,1.61-1.74
	C477.94,533.53,478.8,534.28,478.87,535.28z"
              />
              <path
                d="M287.06,344.05c-0.95-0.01-1.78-0.89-1.75-1.85c0.04-0.96,0.94-1.82,1.88-1.7c0.96,0.13,1.56,0.7,1.61,1.71
	C288.85,343.18,288,344.06,287.06,344.05z"
              />
              <path
                d="M320.54,415.35c0.05,1.01-0.75,1.85-1.78,1.84c-1.02-0.01-1.65-0.54-1.79-1.55c-0.15-1,0.61-1.89,1.63-1.97
	C319.62,413.59,320.5,414.35,320.54,415.35z"
              />
              <path
                d="M405,473.04c-0.01,0.99-0.88,1.79-1.89,1.74c-0.95-0.04-1.66-0.73-1.71-1.66c-0.05-1.02,0.72-1.83,1.75-1.86
	C404.18,471.23,405.01,472.04,405,473.04z"
              />
              <path
                d="M299.19,337.73c-0.01,1.02-0.83,1.77-1.88,1.73c-0.94-0.04-1.66-0.74-1.71-1.66c-0.05-1.01,0.74-1.84,1.76-1.85
	C298.4,335.93,299.2,336.72,299.19,337.73z"
              />
              <path
                d="M494.45,421.81c0.06,0.96-0.75,1.86-1.7,1.9c-0.83,0.03-1.67-0.71-1.78-1.56c-0.12-0.97,0.61-1.9,1.56-2
	C493.48,420.05,494.39,420.85,494.45,421.81z"
              />
              <path
                d="M273.75,336.21c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.66-1.78-1.58c-0.1-1.01,0.64-1.87,1.66-1.93
	C272.89,334.45,273.72,335.18,273.75,336.21z"
              />
              <path
                d="M470.05,494.3c1.03-0.04,1.86,0.71,1.88,1.72c0.02,1.01-0.78,1.79-1.81,1.78c-0.95-0.01-1.67-0.67-1.74-1.6
	C468.28,495.18,469.02,494.35,470.05,494.3z"
              />
              <path
                d="M505.2,234.85c-1.03,0.02-1.84-0.77-1.83-1.77c0.01-0.91,0.72-1.65,1.64-1.72c1.04-0.08,1.88,0.63,1.93,1.65
	C507,234.01,506.23,234.82,505.2,234.85z"
              />
              <path
                d="M508.94,46.76c-0.17-0.61-0.46-1.21-0.45-1.82c0.01-0.49,0.27-1.17,0.64-1.4c0.37-0.22,1.05-0.06,1.54,0.09
	c0.35,0.11,0.64,0.47,0.89,0.77c0.84,0.99,0.48,2.04-0.81,2.25c-0.61,0.1-1.26,0.02-1.89,0.02
	C508.86,46.68,508.94,46.76,508.94,46.76z"
              />
              <path
                d="M404.55,406.22c0.95-0.05,1.82,0.78,1.81,1.77c-0.02,1.01-0.59,1.61-1.55,1.77c-0.92,0.16-1.87-0.67-1.94-1.63
	C402.79,407.18,403.59,406.27,404.55,406.22z"
              />
              <path
                d="M365.37,599.48c-0.01,0.96-0.88,1.8-1.83,1.76c-0.95-0.04-1.75-0.94-1.65-1.92c0.1-1,0.73-1.55,1.7-1.63
	C364.53,597.61,365.39,598.51,365.37,599.48z"
              />
              <path
                d="M366.84,407.83c-0.04,1.03-0.86,1.75-1.92,1.68c-0.92-0.06-1.65-0.8-1.67-1.7c-0.03-1,0.79-1.82,1.81-1.81
	C366.1,406.01,366.88,406.81,366.84,407.83z"
              />
              <path
                d="M410.82,351.51c0.95-0.03,1.81,0.82,1.78,1.8c-0.03,1.01-0.61,1.6-1.58,1.74c-0.93,0.14-1.85-0.7-1.91-1.66
	C409.05,352.45,409.87,351.55,410.82,351.51z"
              />
              <path
                d="M323.95,355.3c-1.04-0.04-1.79-0.86-1.72-1.88c0.08-1.02,0.93-1.73,1.97-1.63c0.91,0.09,1.63,0.86,1.62,1.76
	C325.83,354.53,324.97,355.33,323.95,355.3z"
              />
              <path
                d="M407.87,422.13c0.01,1-0.81,1.81-1.83,1.77c-1.02-0.04-1.62-0.6-1.73-1.6c-0.11-1.01,0.66-1.84,1.69-1.89
	C407.01,420.35,407.85,421.13,407.87,422.13z"
              />
              <path
                d="M310.98,497.33c-1.05-0.05-1.79-0.87-1.7-1.9c0.08-1.02,0.94-1.72,1.98-1.61c0.91,0.09,1.62,0.87,1.61,1.77
	C312.86,496.57,312,497.37,310.98,497.33z"
              />
              <path
                d="M328.57,531.87c-1.03-0.01-1.83-0.83-1.79-1.83c0.04-0.91,0.77-1.63,1.7-1.68c1.04-0.05,1.87,0.7,1.9,1.71
	C330.4,531.09,329.61,531.88,328.57,531.87z"
              />
              <path
                d="M514.61,239.55c0.01,0.96-0.84,1.82-1.79,1.81c-0.95-0.01-1.78-0.9-1.7-1.87c0.08-1.01,0.68-1.57,1.65-1.68
	C513.71,237.71,514.59,238.59,514.61,239.55z"
              />
              <path
                d="M427.41,125.99c0.95-0.01,1.8,0.85,1.78,1.81c-0.02,0.96-0.9,1.84-1.84,1.74c-0.97-0.1-1.57-0.68-1.65-1.68
	C425.63,126.89,426.46,126,427.41,125.99z"
              />
              <path
                d="M337.06,356.22c-0.02,0.96-0.9,1.79-1.85,1.74c-0.96-0.05-1.72-0.96-1.64-1.93c0.07-0.86,0.88-1.63,1.72-1.63
	C336.24,354.39,337.09,355.25,337.06,356.22z"
              />
              <path
                d="M495.89,230.46c0,0.97-0.86,1.81-1.81,1.78c-0.95-0.03-1.76-0.92-1.67-1.9c0.09-1.01,0.71-1.56,1.68-1.65
	C495.03,228.6,495.89,229.5,495.89,230.46z"
              />
              <path
                d="M504.25,576.47c-0.03,0.98-0.89,1.8-1.84,1.73c-0.95-0.07-1.76-1-1.65-1.94c0.13-1,0.78-1.53,1.74-1.61
	C503.43,574.57,504.28,575.5,504.25,576.47z"
              />
              <path
                d="M359.87,495.2c-0.06,1.01-0.92,1.73-1.95,1.63c-0.92-0.09-1.61-0.84-1.61-1.74c0-1,0.82-1.78,1.85-1.75
	C359.17,493.37,359.93,494.19,359.87,495.2z"
              />
              <path
                d="M410.02,198.17c-0.06,1.02-0.89,1.72-1.94,1.64c-0.92-0.07-1.62-0.81-1.63-1.72c-0.01-1,0.81-1.8,1.83-1.77
	C409.3,196.34,410.07,197.16,410.02,198.17z"
              />
              <path
                d="M371.2,604.18c1.04-0.05,1.87,0.71,1.89,1.73c0.02,1.02-0.78,1.8-1.82,1.79c-0.95-0.01-1.68-0.68-1.76-1.61
	C369.42,605.07,370.17,604.22,371.2,604.18z"
              />
              <path
                d="M514.64,591.7c1.04-0.06,1.88,0.68,1.91,1.7c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.65-1.78-1.58
	C512.88,592.62,513.62,591.76,514.64,591.7z"
              />
              <path
                d="M418.89,551.33c-0.06,1.01-0.91,1.74-1.94,1.65c-0.92-0.08-1.62-0.82-1.63-1.73c-0.01-1,0.81-1.78,1.83-1.77
	C418.18,549.5,418.95,550.32,418.89,551.33z"
              />
              <path
                d="M524.88,607.02c0.06,0.98-0.73,1.87-1.68,1.9c-0.95,0.03-1.83-0.82-1.81-1.78c0.02-1.02,0.62-1.59,1.58-1.75
	C523.91,605.22,524.82,606.04,524.88,607.02z"
              />
              <path
                d="M453.69,494.18c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.75-1.82,1.77-1.82
	C452.92,492.38,453.71,493.17,453.69,494.18z"
              />
              <path
                d="M371.88,591.56c-0.01,1-0.86,1.79-1.87,1.73c-1.02-0.07-1.6-0.64-1.69-1.65c-0.09-1,0.71-1.82,1.74-1.85
	C371.07,589.76,371.89,590.55,371.88,591.56z"
              />
              <path
                d="M411.83,243.01c-1.04,0-1.83-0.8-1.79-1.82c0.04-1.02,0.87-1.76,1.91-1.7c0.92,0.05,1.65,0.79,1.68,1.7
	C413.67,242.2,412.86,243.02,411.83,243.01z"
              />
              <path
                d="M391.79,362.22c-0.02,1.02-0.85,1.76-1.9,1.71c-0.93-0.05-1.66-0.77-1.69-1.68c-0.04-1.01,0.76-1.83,1.79-1.83
	C391.03,360.41,391.82,361.2,391.79,362.22z"
              />
              <path
                d="M457.02,157.99c0.01,0.96-0.84,1.82-1.8,1.8c-0.95-0.02-1.77-0.91-1.69-1.88c0.08-1.01,0.69-1.57,1.66-1.67
	C456.14,156.14,457.02,157.02,457.02,157.99z"
              />
              <path
                d="M395.49,258.9c0.94-0.02,1.8,0.86,1.75,1.83c-0.05,1.01-0.63,1.59-1.6,1.72c-0.94,0.13-1.84-0.72-1.89-1.69
	C393.71,259.81,394.54,258.92,395.49,258.9z"
              />
              <path
                d="M293.93,383.12c-1.03-0.06-1.77-0.91-1.68-1.92c0.09-0.92,0.83-1.59,1.77-1.59c1.04,0,1.83,0.79,1.8,1.81
	C295.8,382.44,294.98,383.18,293.93,383.12z"
              />
              <path
                d="M362.7,610.57c-0.04,0.98-0.93,1.82-1.87,1.68c-0.95-0.15-1.56-0.71-1.61-1.72c-0.05-0.96,0.82-1.83,1.77-1.82
	C361.93,608.71,362.74,609.6,362.7,610.57z"
              />
              <path
                d="M346.94,490.68c0.96-0.07,1.83,0.74,1.83,1.74c0,1.01-0.56,1.62-1.52,1.8c-0.92,0.18-1.88-0.65-1.97-1.6
	C345.2,491.66,345.98,490.74,346.94,490.68z"
              />
              <path
                d="M460.41,446.67c-0.02,1.01-0.84,1.75-1.89,1.7c-0.94-0.05-1.64-0.75-1.68-1.67c-0.04-1.01,0.75-1.82,1.77-1.82
	C459.64,444.87,460.43,445.67,460.41,446.67z"
              />
              <path
                d="M520.17,363.52c0.96-0.07,1.83,0.75,1.83,1.74c0,1.01-0.56,1.62-1.52,1.8c-0.91,0.18-1.88-0.65-1.97-1.6
	C518.42,364.5,519.21,363.59,520.17,363.52z"
              />
              <path
                d="M359.41,447.99c0.04,1-0.76,1.84-1.79,1.83c-1.02-0.02-1.64-0.55-1.78-1.56c-0.14-1.01,0.62-1.88,1.64-1.95
	C358.49,446.23,359.37,447,359.41,447.99z"
              />
              <path
                d="M409.04,104.32c-0.03,0.96-0.92,1.78-1.87,1.72c-0.95-0.06-1.72-0.98-1.63-1.95c0.08-0.85,0.9-1.62,1.73-1.62
	C408.24,102.48,409.07,103.36,409.04,104.32z"
              />
              <path
                d="M471.35,508.69c1.02,0,1.81,0.81,1.77,1.82c-0.04,0.92-0.74,1.62-1.68,1.67c-1.05,0.05-1.87-0.68-1.89-1.7
	C469.52,509.48,470.32,508.69,471.35,508.69z"
              />
              <path
                d="M429.02,519.72c1.02-0.05,1.88,0.74,1.89,1.74c0.01,0.9-0.7,1.68-1.61,1.77c-1.05,0.11-1.91-0.59-1.99-1.61
	C427.24,520.6,427.98,519.77,429.02,519.72z"
              />
              <path
                d="M374.51,580.46c0.02,1.01-0.77,1.8-1.81,1.78c-1.04-0.02-1.61-0.61-1.74-1.59c-0.13-1.01,0.64-1.85,1.66-1.9
	C373.66,578.71,374.49,579.46,374.51,580.46z"
              />
              <path
                d="M569.31,617.76c-0.04,0.96-0.95,1.77-1.89,1.7c-0.95-0.07-1.71-1-1.61-1.96c0.09-0.84,0.93-1.61,1.75-1.6
	C568.52,615.92,569.35,616.8,569.31,617.76z"
              />
              <path
                d="M237.76,252.82c-0.02,1.02-0.85,1.76-1.9,1.71c-0.93-0.05-1.66-0.77-1.69-1.68c-0.04-1.01,0.76-1.83,1.79-1.83
	C237,251.01,237.79,251.8,237.76,252.82z"
              />
              <path
                d="M436.9,134.19c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.66-1.78-1.58c-0.1-1.01,0.64-1.87,1.66-1.93
	C436.03,132.44,436.86,133.17,436.9,134.19z"
              />
              <path
                d="M406.35,470.93c-1.02,0.02-1.84-0.77-1.83-1.77c0.01-0.91,0.72-1.65,1.64-1.72c1.04-0.08,1.88,0.63,1.93,1.65
	C408.15,470.09,407.38,470.9,406.35,470.93z"
              />
              <path
                d="M381.71,247.52c-0.01,1.01-0.83,1.76-1.87,1.72c-0.94-0.04-1.65-0.73-1.69-1.65c-0.05-1.01,0.72-1.83,1.75-1.84
	C380.92,245.73,381.73,246.52,381.71,247.52z"
              />
              <path
                d="M360.11,249.32c0.06,1.01-0.71,1.83-1.75,1.84c-1.03,0.01-1.63-0.55-1.79-1.54c-0.16-1.01,0.58-1.88,1.6-1.95
	C359.22,247.59,360.06,248.3,360.11,249.32z"
              />
              <path
                d="M394.68,584.02c1.05-0.06,1.88,0.68,1.91,1.7c0.04,1.02-0.75,1.81-1.79,1.82c-0.95,0-1.69-0.65-1.78-1.58
	C392.91,584.95,393.66,584.08,394.68,584.02z"
              />
              <path
                d="M322.39,64.88c0.02,0.97-0.82,1.84-1.76,1.83c-0.95-0.02-1.8-0.91-1.73-1.87c0.07-1.01,0.7-1.56,1.65-1.69
	C321.51,63.03,322.37,63.89,322.39,64.88z"
              />
              <path
                d="M282.27,203.72c0.94,0,1.79,0.88,1.72,1.86c-0.06,1.01-0.66,1.58-1.63,1.7c-0.94,0.11-1.83-0.75-1.86-1.72
	C280.48,204.6,281.32,203.72,282.27,203.72z"
              />
              <path
                d="M411.24,568.12c-0.97-0.07-1.76-1.03-1.64-1.99c0.11-0.87,0.97-1.6,1.84-1.55c0.98,0.05,1.74,0.99,1.62,1.99
	C412.96,567.46,412.11,568.18,411.24,568.12z"
              />
              <path
                d="M392.96,376.67c-0.06,1.01-0.91,1.75-1.93,1.63c-1.01-0.11-1.56-0.72-1.61-1.72c-0.05-0.99,0.81-1.78,1.82-1.76
	C392.26,374.85,393.02,375.66,392.96,376.67z"
              />
              <path
                d="M240.58,225.24c-1.03,0.02-1.84-0.75-1.83-1.75c0.01-0.99,0.84-1.77,1.85-1.73c1.04,0.04,1.58,0.65,1.68,1.63
	C242.39,224.41,241.6,225.22,240.58,225.24z"
              />
              <path
                d="M394.36,477.69c-0.05,0.9-0.85,1.67-1.72,1.67c-0.97,0-1.82-0.91-1.76-1.88c0.06-0.88,0.86-1.66,1.74-1.67
	C393.58,475.79,394.42,476.69,394.36,477.69z"
              />
              <path
                d="M402.65,95.69c-1.03-0.04-1.78-0.86-1.7-1.87c0.08-1,0.93-1.71,1.96-1.61c0.91,0.09,1.6,0.84,1.59,1.75
	C404.49,94.95,403.66,95.73,402.65,95.69z"
              />
              <path
                d="M403.75,585.1c-0.02,0.97-0.94,1.81-1.89,1.72c-0.89-0.08-1.58-0.87-1.57-1.79c0.02-0.92,0.74-1.68,1.63-1.72
	C402.9,583.26,403.77,584.12,403.75,585.1z"
              />
              <path
                d="M396.06,475.5c-0.97,0.04-1.84-0.83-1.82-1.8c0.02-0.88,0.78-1.67,1.66-1.72c0.87-0.05,1.7,0.68,1.79,1.56
	C397.81,474.54,397.04,475.46,396.06,475.5z"
              />
              <path
                d="M365.62,246.93c0.98,0.06,1.78,1,1.66,1.97c-0.11,0.88-0.95,1.61-1.82,1.57c-0.97-0.04-1.75-0.97-1.65-1.97
	C363.91,247.62,364.76,246.88,365.62,246.93z"
              />
              <path
                d="M419.82,249.49c-0.85,0.04-1.68-0.71-1.77-1.58c-0.1-0.99,0.68-1.9,1.65-1.92c0.86-0.02,1.7,0.71,1.79,1.57
	C421.6,248.5,420.77,249.45,419.82,249.49z"
              />
              <path
                d="M407.11,581.23c0,0.98-0.9,1.83-1.87,1.77c-0.9-0.06-1.61-0.84-1.61-1.77c0-0.92,0.72-1.71,1.61-1.76
	C406.23,579.4,407.12,580.24,407.11,581.23z"
              />
              <path
                d="M401.06,469.98c-0.04,0.9-0.8,1.67-1.68,1.68c-0.96,0.02-1.82-0.87-1.78-1.84c0.04-0.97,0.98-1.78,1.94-1.66
	C400.44,468.26,401.1,469.06,401.06,469.98z"
              />
              <path
                d="M505.37,586.91c0.04-0.9,0.8-1.68,1.68-1.7c0.97-0.02,1.84,0.87,1.79,1.85c-0.04,0.88-0.83,1.68-1.7,1.71
	C506.19,588.8,505.33,587.9,505.37,586.91z"
              />
              <path
                d="M408.26,97.09c-0.01,0.92-0.73,1.69-1.62,1.73c-0.96,0.05-1.85-0.81-1.83-1.78c0.01-0.98,0.91-1.81,1.88-1.73
	C407.58,95.39,408.27,96.17,408.26,97.09z"
              />
              <path
                d="M360.56,628.64c0.05,0.96-0.8,1.86-1.76,1.85c-0.87-0.01-1.63-0.77-1.68-1.66c-0.05-0.92,0.6-1.71,1.5-1.82
	C359.56,626.88,360.51,627.68,360.56,628.64z"
              />
              <path
                d="M396.07,246.22c0.04,0.97-0.81,1.86-1.78,1.84c-0.87-0.02-1.64-0.78-1.68-1.68c-0.04-0.92,0.62-1.72,1.52-1.83
	C395.09,244.44,396.03,245.24,396.07,246.22z"
              />
              <path
                d="M440.7,509.65c0.04,0.98-0.82,1.87-1.79,1.85c-0.88-0.02-1.65-0.79-1.69-1.69c-0.04-0.92,0.64-1.73,1.53-1.84
	C439.72,507.86,440.66,508.67,440.7,509.65z"
              />
              <path
                d="M350.21,466.99c0.02,0.92-0.69,1.72-1.58,1.8c-0.96,0.09-1.88-0.75-1.9-1.73c-0.02-0.98,0.86-1.85,1.83-1.8
	C349.46,465.3,350.19,466.07,350.21,466.99z"
              />
              <path
                d="M286.72,380.34c0.97-0.07,1.87,0.76,1.87,1.74c0,0.88-0.73,1.69-1.61,1.77c-0.97,0.09-1.84-0.73-1.85-1.74
	C285.12,381.18,285.82,380.4,286.72,380.34z"
              />
              <path
                d="M467.56,509.08c-0.96,0.04-1.83-0.82-1.81-1.79c0.02-0.87,0.78-1.66,1.66-1.7c0.88-0.05,1.69,0.66,1.78,1.55
	C469.29,508.13,468.53,509.04,467.56,509.08z"
              />
              <path
                d="M411.45,465.29c0.03,1-0.76,1.8-1.79,1.79c-1.04-0.01-1.61-0.58-1.74-1.57c-0.13-1.01,0.62-1.85,1.64-1.91
	C410.59,463.54,411.42,464.29,411.45,465.29z"
              />
              <path
                d="M510.94,588.35c1.02,0.01,1.8,0.82,1.76,1.82c-0.04,1.01-0.86,1.73-1.91,1.66c-1.05-0.07-1.55-0.72-1.63-1.69
	C509.08,589.14,509.92,588.34,510.94,588.35z"
              />
              <path
                d="M442.13,507.66c-0.97,0.04-1.85-0.83-1.82-1.81c0.02-0.89,0.79-1.68,1.67-1.73c0.86-0.05,1.71,0.7,1.8,1.57
	C443.88,506.69,443.1,507.62,442.13,507.66z"
              />
              <path
                d="M418.63,464.67c-0.03,0.98-0.95,1.81-1.91,1.72c-0.89-0.09-1.59-0.89-1.57-1.81c0.02-0.92,0.76-1.69,1.65-1.73
	C417.78,462.81,418.66,463.68,418.63,464.67z"
              />
              <path
                d="M263.18,254.31c-0.05,1.01-0.88,1.72-1.93,1.64c-0.92-0.07-1.61-0.8-1.62-1.71c-0.01-1.01,0.79-1.79,1.81-1.77
	C262.46,252.49,263.23,253.31,263.18,254.31z"
              />
              <path
                d="M304.15,229.05c-0.01,0.92-0.74,1.7-1.63,1.74c-0.97,0.05-1.85-0.81-1.84-1.79c0.01-0.98,0.92-1.82,1.89-1.74
	C303.46,227.33,304.16,228.13,304.15,229.05z"
              />
              <path
                d="M521.31,358.14c-0.03,0.96-0.95,1.79-1.89,1.7c-0.89-0.09-1.57-0.86-1.55-1.78c0.02-0.92,0.74-1.67,1.63-1.7
	C520.46,356.32,521.33,357.17,521.31,358.14z"
              />
              <path
                d="M412.1,100.41c0.01,0.92-0.68,1.7-1.58,1.78c-0.96,0.08-1.87-0.76-1.88-1.74c-0.01-0.98,0.87-1.83,1.84-1.78
	C411.37,98.72,412.09,99.48,412.1,100.41z"
              />
              <path
                d="M315.45,358.14c-0.03,0.96-0.94,1.79-1.89,1.7c-0.89-0.08-1.57-0.86-1.55-1.78c0.02-0.92,0.74-1.67,1.63-1.7
	C314.61,356.32,315.48,357.17,315.45,358.14z"
              />
              <path
                d="M352.17,219.28c-0.86,0.04-1.69-0.71-1.78-1.59c-0.1-0.99,0.69-1.91,1.66-1.94c0.86-0.03,1.71,0.72,1.8,1.58
	C353.95,218.28,353.12,219.23,352.17,219.28z"
              />
              <path
                d="M420.2,462.55c-0.88-0.02-1.64-0.78-1.68-1.68c-0.04-1,0.79-1.88,1.76-1.85c0.87,0.03,1.66,0.81,1.7,1.69
	C422.03,461.68,421.17,462.57,420.2,462.55z"
              />
              <path
                d="M436.15,459.7c-0.06,1.01-0.92,1.74-1.94,1.62c-1.01-0.12-1.56-0.74-1.6-1.73c-0.04-0.99,0.82-1.77,1.83-1.74
	C435.46,457.87,436.21,458.68,436.15,459.7z"
              />
              <path
                d="M355.01,460.84c-1.02,0.02-1.87-0.76-1.8-1.76c0.07-0.97,0.56-1.63,1.61-1.72c1.03-0.09,1.87,0.64,1.93,1.64
	C356.81,460,356.03,460.82,355.01,460.84z"
              />
              <path
                d="M363.47,245.48c0.06,1-0.72,1.82-1.74,1.83c-1.03,0.01-1.63-0.54-1.77-1.53c-0.15-1.01,0.58-1.87,1.59-1.94
	C362.57,243.76,363.41,244.47,363.47,245.48z"
              />
              <path
                d="M358.29,623.28c-0.97-0.03-1.75-0.93-1.66-1.92c0.08-0.88,0.89-1.62,1.76-1.58c0.87,0.03,1.65,0.81,1.68,1.68
	C360.11,622.43,359.25,623.31,358.29,623.28z"
              />
              <path
                d="M573.13,620.94c0.06,0.96-0.78,1.86-1.74,1.86c-0.86,0-1.64-0.76-1.7-1.65c-0.06-0.92,0.59-1.72,1.48-1.84
	C572.11,619.2,573.07,619.99,573.13,620.94z"
              />
              <path
                d="M429.86,387.93c-0.04,0.9-0.81,1.68-1.69,1.69c-0.97,0.02-1.83-0.88-1.78-1.85c0.05-0.98,0.98-1.79,1.95-1.68
	C429.23,386.2,429.91,387.01,429.86,387.93z"
              />
              <path
                d="M417.87,457.32c0.06,0.99-0.78,1.9-1.74,1.88c-0.86-0.01-1.68-0.8-1.73-1.67c-0.06-0.98,0.79-1.88,1.76-1.88
	C417.02,455.65,417.82,456.43,417.87,457.32z"
              />
              <path
                d="M414.49,560.76c0.96,0.01,1.8,0.92,1.73,1.87c-0.07,0.86-0.89,1.63-1.74,1.63c-0.96,0-1.77-0.89-1.7-1.88
	C412.84,561.5,413.62,560.75,414.49,560.76z"
              />
              <path
                d="M425.53,171.44c0,0.92-0.71,1.7-1.6,1.76c-0.96,0.07-1.86-0.78-1.86-1.76c0-0.98,0.89-1.82,1.86-1.76
	C424.83,169.75,425.53,170.52,425.53,171.44z"
              />
              <path
                d="M314.69,390.69c0.02,0.92-0.67,1.71-1.56,1.79c-0.95,0.09-1.87-0.74-1.89-1.72c-0.02-0.98,0.85-1.84,1.82-1.79
	C313.95,389.01,314.67,389.77,314.69,390.69z"
              />
              <path
                d="M361.44,619.46c-0.97-0.01-1.81-0.92-1.74-1.89c0.06-0.87,0.88-1.64,1.74-1.65c0.96-0.01,1.78,0.89,1.72,1.89
	C363.1,618.7,362.3,619.46,361.44,619.46z"
              />
              <path
                d="M414.79,255.52c-0.06,0.96-1.03,1.77-1.96,1.64c-0.89-0.12-1.54-0.94-1.49-1.85c0.05-0.89,0.84-1.66,1.7-1.66
	C414,253.65,414.85,254.55,414.79,255.52z"
              />
              <path
                d="M549.29,614c0.97-0.01,1.83,0.89,1.77,1.87c-0.05,0.88-0.85,1.67-1.72,1.69c-0.96,0.02-1.8-0.88-1.76-1.87
	C547.64,614.79,548.42,614.01,549.29,614z"
              />
              <path
                d="M468.71,500.22c-0.04,0.9-0.8,1.66-1.67,1.67c-0.96,0.02-1.81-0.87-1.77-1.84c0.05-0.96,0.98-1.76,1.93-1.65
	C468.1,498.51,468.75,499.29,468.71,500.22z"
              />
              <path
                d="M558.04,615.03c0.01,1-0.8,1.8-1.82,1.76c-1.01-0.05-1.61-0.6-1.72-1.6c-0.11-1,0.67-1.84,1.69-1.88
	C557.2,613.26,558.03,614.03,558.04,615.03z"
              />
              <path
                d="M690.93,614.79c0.03,0.85-0.34,1.34-1.23,1.33c-0.96-0.02-1.77-0.9-1.71-1.86c0.05-0.84,0.91-1.7,1.67-1.67
	C690.43,612.62,690.95,613.53,690.93,614.79z"
              />
              <path
                d="M357.55,612.58c0.86-0.03,1.68,0.7,1.76,1.58c0.09,0.99-0.69,1.89-1.66,1.92c-0.85,0.02-1.69-0.73-1.78-1.58
	C355.77,613.55,356.58,612.62,357.55,612.58z"
              />
              <path
                d="M442.8,451.78c-0.05,0.89-0.84,1.65-1.7,1.65c-0.96,0-1.8-0.9-1.74-1.86c0.06-0.96,1.01-1.75,1.96-1.62
	C442.21,450.06,442.85,450.85,442.8,451.78z"
              />
              <path
                d="M415.62,448.48c0.86-0.03,1.67,0.71,1.75,1.59c0.09,0.99-0.7,1.89-1.66,1.91c-0.84,0.02-1.69-0.74-1.77-1.59
	C413.83,449.44,414.66,448.51,415.62,448.48z"
              />
              <path
                d="M368.18,608.03c1.02,0,1.83,0.81,1.75,1.81c-0.09,0.98-0.61,1.61-1.65,1.66c-1.04,0.06-1.86-0.68-1.88-1.69
	C366.36,608.82,367.16,608.03,368.18,608.03z"
              />
              <path
                d="M422.31,400.98c0.87-0.04,1.68,0.68,1.77,1.57c0.1,0.99-0.68,1.9-1.64,1.93c-0.85,0.03-1.69-0.71-1.79-1.57
	C420.54,401.95,421.35,401.02,422.31,400.98z"
              />
              <path
                d="M290.09,213.72c-1.03-0.05-1.77-0.88-1.64-1.9c0.13-0.99,0.7-1.57,1.74-1.58c1.02-0.01,1.81,0.8,1.79,1.79
	C291.95,213.04,291.13,213.78,290.09,213.72z"
              />
              <path
                d="M448.81,499.71c-1.03,0.01-1.83-0.77-1.81-1.77c0.02-0.99,0.85-1.76,1.87-1.71c1.04,0.05,1.57,0.67,1.67,1.65
	C450.63,498.89,449.83,499.7,448.81,499.71z"
              />
              <path
                d="M465.03,436.95c0.87-0.03,1.69,0.7,1.77,1.59c0.09,0.99-0.7,1.91-1.67,1.93c-0.86,0.02-1.7-0.73-1.79-1.59
	C463.25,437.92,464.06,436.99,465.03,436.95z"
              />
              <path
                d="M299.13,436.94c0.9,0.03,1.63,0.8,1.66,1.72c0.03,1-0.84,1.87-1.8,1.82c-0.87-0.04-1.65-0.85-1.68-1.73
	C297.28,437.78,298.15,436.91,299.13,436.94z"
              />
              <path
                d="M467.09,232.75c0.06,0.97-0.79,1.88-1.75,1.87c-0.87,0-1.65-0.76-1.71-1.66c-0.06-0.92,0.6-1.73,1.49-1.85
	C466.07,230.99,467.03,231.79,467.09,232.75z"
              />
              <path
                d="M460.86,493.34c0.04,0.98-0.82,1.87-1.79,1.85c-0.88-0.02-1.65-0.79-1.68-1.7c-0.04-0.92,0.64-1.73,1.54-1.83
	C459.88,491.55,460.81,492.36,460.86,493.34z"
              />
              <path
                d="M464.68,496.7c0.04,0.96-0.82,1.85-1.78,1.83c-0.88-0.02-1.62-0.77-1.66-1.68c-0.04-0.92,0.62-1.71,1.51-1.81
	C463.71,494.93,464.64,495.73,464.68,496.7z"
              />
              <path
                d="M375.1,610.83c-0.88-0.02-1.65-0.79-1.69-1.69c-0.04-1,0.81-1.89,1.77-1.86c0.88,0.03,1.67,0.81,1.71,1.7
	C376.93,609.95,376.07,610.85,375.1,610.83z"
              />
              <path
                d="M518.64,242.9c0.02,0.91-0.68,1.72-1.57,1.8c-0.96,0.09-1.88-0.75-1.9-1.73c-0.02-0.98,0.85-1.85,1.83-1.8
	C517.88,241.21,518.62,241.98,518.64,242.9z"
              />
              <path
                d="M486.78,209.48c0.07,1-0.69,1.83-1.72,1.85c-1.02,0.01-1.63-0.52-1.79-1.52c-0.16-1.01,0.56-1.88,1.57-1.96
	C485.87,207.76,486.72,208.47,486.78,209.48z"
              />
              <path
                d="M278.35,263.4c-0.04-1.01,0.74-1.82,1.76-1.82c1.02,0,1.85,0.8,1.78,1.79c-0.06,1-0.63,1.59-1.64,1.69
	C279.23,265.17,278.39,264.4,278.35,263.4z"
              />
              <path
                d="M484.09,552.89c0.01,0.92-0.7,1.71-1.59,1.79c-0.96,0.08-1.87-0.76-1.89-1.74c-0.01-0.98,0.87-1.84,1.85-1.79
	C483.35,551.19,484.08,551.97,484.09,552.89z"
              />
              <path
                d="M358.64,607.05c0.05,0.96-0.8,1.86-1.76,1.85c-0.87-0.01-1.63-0.76-1.68-1.66c-0.05-0.92,0.6-1.71,1.5-1.82
	C357.65,605.29,358.59,606.08,358.64,607.05z"
              />
              <path
                d="M314.49,30.99c0.89,0.03,1.64,0.81,1.66,1.72c0.03,0.99-0.84,1.88-1.79,1.83c-0.86-0.04-1.65-0.85-1.68-1.73
	C312.65,31.84,313.52,30.96,314.49,30.99z"
              />
              <path
                d="M364.02,608.15c-1.03-0.03-1.8-0.85-1.68-1.87c0.11-0.99,0.66-1.58,1.7-1.61c1.01-0.03,1.83,0.76,1.83,1.75
	C365.87,607.42,365.05,608.18,364.02,608.15z"
              />
              <path
                d="M417.62,433.75c-0.96,0.02-1.81-0.87-1.77-1.83c0.04-0.87,0.83-1.65,1.7-1.67c0.97-0.03,1.79,0.84,1.74,1.84
	C419.24,432.98,418.49,433.73,417.62,433.75z"
              />
              <path
                d="M480.24,549.48c0.04,1-0.8,1.88-1.76,1.85c-0.86-0.03-1.66-0.83-1.7-1.7c-0.05-0.97,0.81-1.86,1.78-1.84
	C479.43,547.81,480.2,548.58,480.24,549.48z"
              />
              <path
                d="M328.73,322.52c1.01-0.05,1.85,0.72,1.87,1.72c0.02,0.91-0.66,1.67-1.57,1.76c-1.03,0.11-1.89-0.59-1.98-1.58
	C326.97,323.41,327.71,322.57,328.73,322.52z"
              />
              <path
                d="M334.2,533.43c0.03,1-0.76,1.79-1.79,1.78c-1.04-0.01-1.61-0.59-1.74-1.58c-0.13-1.01,0.62-1.85,1.64-1.9
	C333.34,531.68,334.17,532.43,334.2,533.43z"
              />
              <path
                d="M379.72,325.15c0.88,0.02,1.64,0.79,1.67,1.69c0.04,1-0.8,1.88-1.76,1.84c-0.86-0.03-1.65-0.82-1.69-1.7
	C377.89,326.01,378.75,325.13,379.72,325.15z"
              />
              <path
                d="M365.38,330.12c-0.97,0.06-1.86-0.8-1.85-1.78c0.01-0.89,0.75-1.69,1.64-1.75c0.88-0.06,1.72,0.65,1.82,1.54
	C367.12,329.13,366.37,330.06,365.38,330.12z"
              />
              <path
                d="M407.11,415.09c0.06,0.98-0.79,1.89-1.76,1.88c-0.87,0-1.66-0.77-1.72-1.67c-0.05-0.91,0.61-1.74,1.5-1.86
	C406.09,413.32,407.05,414.12,407.11,415.09z"
              />
              <path
                d="M230.67,237.03c-0.96-0.02-1.76-0.94-1.68-1.93c0.07-0.88,0.9-1.64,1.76-1.61c0.87,0.03,1.66,0.81,1.7,1.69
	C232.5,236.16,231.64,237.05,230.67,237.03z"
              />
              <path
                d="M420.91,429.92c-0.96-0.02-1.8-0.96-1.71-1.91c0.07-0.86,0.91-1.63,1.77-1.62c0.96,0.01,1.77,0.92,1.69,1.91
	C422.58,429.18,421.76,429.94,420.91,429.92z"
              />
              <path
                d="M404.42,426.2c0.02,0.92-0.67,1.71-1.57,1.79c-0.96,0.09-1.87-0.75-1.89-1.72c-0.02-0.98,0.85-1.83,1.83-1.79
	C403.68,424.52,404.4,425.28,404.42,426.2z"
              />
              <path
                d="M303.03,234.47c0.97-0.07,1.87,0.76,1.87,1.74c0,0.88-0.73,1.69-1.61,1.77c-0.97,0.09-1.84-0.73-1.85-1.74
	C301.44,235.31,302.14,234.53,303.03,234.47z"
              />
              <path
                d="M360.3,601.52c0.97,0.03,1.8,0.97,1.71,1.93c-0.08,0.87-0.92,1.63-1.78,1.62c-0.97-0.02-1.78-0.94-1.69-1.93
	C358.61,602.25,359.43,601.49,360.3,601.52z"
              />
              <path
                d="M276.79,205.92c0.04,0.99-0.81,1.89-1.77,1.86c-0.87-0.03-1.66-0.83-1.7-1.7c-0.05-0.98,0.82-1.87,1.79-1.85
	C275.98,204.24,276.76,205.02,276.79,205.92z"
              />
              <path
                d="M409.93,423.77c1.02,0,1.83,0.81,1.75,1.81c-0.09,0.98-0.61,1.61-1.65,1.66c-1.04,0.06-1.86-0.68-1.88-1.69
	C408.11,424.56,408.91,423.77,409.93,423.77z"
              />
              <path
                d="M519.24,602c0.85-0.04,1.7,0.72,1.79,1.59c0.1,0.99-0.69,1.92-1.67,1.95c-0.87,0.03-1.71-0.71-1.81-1.59
	C517.46,602.99,518.27,602.05,519.24,602z"
              />
              <path
                d="M497.5,418.04c0.02,0.92-0.65,1.7-1.55,1.78c-0.95,0.09-1.87-0.74-1.89-1.7c-0.03-0.97,0.84-1.82,1.81-1.79
	C496.76,416.37,497.48,417.12,497.5,418.04z"
              />
              <path
                d="M353.55,423.36c0,0.92-0.7,1.69-1.59,1.75c-0.95,0.07-1.85-0.78-1.85-1.74c0-0.97,0.88-1.8,1.85-1.74
	C352.85,421.67,353.55,422.43,353.55,423.36z"
              />
              <path
                d="M420.2,422.73c-0.88-0.02-1.65-0.79-1.69-1.7c-0.04-1,0.81-1.89,1.77-1.85c0.88,0.03,1.66,0.82,1.7,1.7
	C422.03,421.86,421.17,422.75,420.2,422.73z"
              />
              <path
                d="M423.12,202.29c0.97,0.01,1.82,0.93,1.74,1.9c-0.06,0.87-0.89,1.65-1.75,1.65c-0.96,0-1.79-0.91-1.73-1.9
	C421.45,203.06,422.26,202.29,423.12,202.29z"
              />
              <path
                d="M345.87,543.24c0.04,1-0.79,1.87-1.75,1.83c-0.85-0.03-1.65-0.83-1.69-1.68c-0.05-0.96,0.81-1.84,1.78-1.82
	C345.08,541.59,345.83,542.34,345.87,543.24z"
              />
              <path
                d="M302.84,337.3c-0.04-1.01,0.73-1.81,1.74-1.81c1.01,0,1.84,0.8,1.78,1.78c-0.06,1-0.63,1.59-1.63,1.69
	C303.71,339.06,302.88,338.3,302.84,337.3z"
              />
              <path
                d="M519.41,376.6c-0.02,0.99-0.84,1.75-1.86,1.7c-1.04-0.05-1.57-0.66-1.66-1.64c-0.09-1.01,0.7-1.81,1.72-1.82
	C518.62,374.82,519.43,375.62,519.41,376.6z"
              />
              <path
                d="M692.71,628.68c0.05,0.94-0.62,1.72-1.52,1.79c-0.96,0.07-1.85-0.78-1.83-1.76c0.02-0.88,0.79-1.67,1.67-1.68
	C691.9,627.01,692.66,627.76,692.71,628.68z"
              />
              <path
                d="M416.26,4.92c1.78-0.03,2.34,0.38,2.32,1.31c-0.02,0.92-0.69,1.55-1.7,1.56c-1.01,0.02-2.07-0.86-1.79-1.7
	C415.29,5.52,416.04,5.13,416.26,4.92z"
              />
              <path
                d="M408.7,575.65c0.96-0.01,1.81,0.88,1.75,1.83c-0.05,0.85-0.86,1.63-1.7,1.65c-0.96,0.02-1.77-0.85-1.72-1.84
	C407.08,576.4,407.83,575.66,408.7,575.65z"
              />
              <path
                d="M259.24,333.56c0.03-0.91,0.77-1.65,1.65-1.66c0.96-0.02,1.82,0.85,1.77,1.81c-0.04,0.85-0.84,1.65-1.68,1.68
	C260.03,335.41,259.2,334.55,259.24,333.56z"
              />
              <path
                d="M275.31,330.69c1.02,0.01,1.83,0.85,1.77,1.84c-0.06,0.91-0.81,1.6-1.75,1.61c-1.04,0.01-1.81-0.79-1.74-1.84
	C273.64,331.37,274.37,330.69,275.31,330.69z"
              />
              <path
                d="M417.13,423.3c0.94,0,1.67,0.7,1.72,1.63c0.05,1.05-0.71,1.84-1.76,1.82c-0.94-0.02-1.69-0.71-1.74-1.63
	C415.29,424.13,416.11,423.3,417.13,423.3z"
              />
              <path
                d="M354.55,247.78c-0.96,0.03-1.7-0.61-1.78-1.55c-0.1-1.04,0.64-1.88,1.67-1.9c1.02-0.02,1.85,0.8,1.82,1.79
	C356.23,247.04,355.5,247.75,354.55,247.78z"
              />
              <path
                d="M422.21,547.28c0.06,0.95-0.58,1.72-1.5,1.83c-1.03,0.12-1.93-0.6-1.99-1.6c-0.07-1,0.74-1.84,1.76-1.85
	C421.42,545.65,422.15,546.34,422.21,547.28z"
              />
              <path
                d="M423.58,415.38c0.96-0.01,1.81,0.88,1.75,1.83c-0.05,0.85-0.86,1.63-1.7,1.65c-0.96,0.02-1.77-0.85-1.72-1.84
	C421.95,416.13,422.7,415.39,423.58,415.38z"
              />
              <path
                d="M256.9,182.46c0.01,0.96-0.87,1.81-1.82,1.76c-0.89-0.05-1.59-0.8-1.6-1.72c-0.01-0.92,0.67-1.68,1.56-1.75
	C256,180.68,256.9,181.5,256.9,182.46z"
              />
              <path
                d="M332.01,464.94c-0.04,1.01-0.88,1.75-1.9,1.65c-1-0.1-1.56-0.7-1.62-1.7c-0.06-0.98,0.78-1.78,1.79-1.77
	C331.3,463.13,332.06,463.93,332.01,464.94z"
              />
              <path
                d="M433.01,356.85c0.89,0.03,1.6,0.77,1.62,1.69c0.02,1-0.8,1.84-1.77,1.79c-0.86-0.05-1.63-0.84-1.65-1.7
	C431.18,357.66,432.04,356.81,433.01,356.85z"
              />
              <path
                d="M349.52,230.28c-0.95,0.04-1.82-0.82-1.8-1.78c0.02-0.87,0.77-1.64,1.65-1.69c0.87-0.05,1.68,0.65,1.77,1.54
	C351.24,229.34,350.48,230.24,349.52,230.28z"
              />
              <path
                d="M405.74,463.71c-1.02-0.05-1.76-0.87-1.64-1.89c0.12-0.99,0.69-1.56,1.72-1.58c1.01-0.02,1.81,0.79,1.79,1.78
	C407.59,463.02,406.77,463.76,405.74,463.71z"
              />
              <path
                d="M406.55,427.62c0.94-0.01,1.68,0.67,1.74,1.61c0.07,1.05-0.69,1.85-1.73,1.85c-0.95-0.01-1.7-0.69-1.76-1.6
	C404.72,428.47,405.52,427.63,406.55,427.62z"
              />
              <path
                d="M499.09,412.51c0.87-0.04,1.67,0.67,1.76,1.56c0.09,0.99-0.67,1.89-1.63,1.92c-0.85,0.03-1.69-0.71-1.78-1.56
	C497.32,413.48,498.13,412.55,499.09,412.51z"
              />
              <path
                d="M413.03,463.22c-0.96,0-1.68-0.67-1.73-1.61c-0.06-1.04,0.71-1.86,1.74-1.84c0.93,0.01,1.69,0.71,1.75,1.62
	C414.86,462.38,414.06,463.22,413.03,463.22z"
              />
              <path
                d="M243.99,383.95c1.01,0.05,1.75,0.89,1.63,1.9c-0.12,0.99-0.7,1.56-1.73,1.57c-1.02,0.01-1.81-0.79-1.78-1.78
	C242.14,384.65,242.98,383.9,243.99,383.95z"
              />
              <path
                d="M308.24,461.31c-1.02,0.03-1.84-0.75-1.83-1.73c0-0.98,0.83-1.77,1.84-1.73c1.03,0.04,1.58,0.63,1.68,1.62
	C310.03,460.49,309.27,461.29,308.24,461.31z"
              />
              <path
                d="M409.2,456.41c1.02,0.01,1.82,0.86,1.75,1.86c-0.07,0.91-0.82,1.59-1.76,1.6c-1.05,0.01-1.8-0.8-1.73-1.85
	C407.53,457.07,408.26,456.4,409.2,456.41z"
              />
              <path
                d="M368.49,595.36c0.01,1-0.79,1.79-1.81,1.75c-1.01-0.05-1.6-0.61-1.71-1.6c-0.11-0.99,0.67-1.83,1.68-1.87
	C367.66,593.59,368.48,594.36,368.49,595.36z"
              />
              <path
                d="M423.67,355.87c0.04,1.02-0.73,1.8-1.75,1.8c-1.02,0-1.84-0.8-1.77-1.78c0.07-0.99,0.64-1.59,1.64-1.69
	C422.79,354.1,423.63,354.87,423.67,355.87z"
              />
              <path
                d="M286.24,206.89c1.01-0.06,1.85,0.68,1.89,1.67c0.04,0.98-0.75,1.79-1.77,1.79c-1.03,0-1.61-0.57-1.74-1.55
	C284.49,207.79,285.22,206.95,286.24,206.89z"
              />
              <path
                d="M416.25,103.54c0,1-0.85,1.78-1.89,1.72c-0.95-0.05-1.63-0.78-1.63-1.72c0-0.94,0.69-1.67,1.63-1.73
	C415.38,101.75,416.24,102.54,416.25,103.54z"
              />
              <path
                d="M437.75,457.47c-1.03,0-1.84-0.83-1.77-1.83c0.06-0.91,0.81-1.61,1.75-1.62c1.03-0.01,1.81,0.8,1.75,1.84
	C439.43,456.79,438.71,457.47,437.75,457.47z"
              />
              <path
                d="M297.96,197.1c0.01,1-0.8,1.79-1.82,1.74c-1.01-0.05-1.6-0.61-1.71-1.6c-0.11-0.99,0.68-1.82,1.69-1.87
	C297.13,195.33,297.95,196.1,297.96,197.1z"
              />
              <path
                d="M360.1,455.2c0.04,0.98-0.76,1.79-1.77,1.79c-1.03-0.01-1.61-0.57-1.74-1.56c-0.13-1.01,0.6-1.85,1.62-1.91
	C359.23,453.47,360.07,454.21,360.1,455.2z"
              />
              <path
                d="M263.31,89.32c-0.37,0.12-0.73,0.25-1.11,0.35c-0.93,0.24-1.77,0.16-2.15-0.88c-0.27-0.75,0.7-2.28,1.54-2.4
	c0.83-0.11,1.4,0.34,1.56,1.13c0.12,0.61,0.06,1.25,0.08,1.88C263.23,89.4,263.31,89.32,263.31,89.32z"
              />
              <path
                d="M421.19,453.55c0.02,0.92-0.66,1.69-1.54,1.77c-0.94,0.08-1.85-0.74-1.88-1.7c-0.02-0.96,0.84-1.81,1.81-1.77
	C420.47,451.9,421.18,452.64,421.19,453.55z"
              />
              <path
                d="M403.12,435.18c-0.94-0.04-1.77-0.99-1.66-1.93c0.09-0.84,0.94-1.58,1.79-1.55c0.97,0.03,1.73,0.92,1.63,1.91
	C404.79,434.48,403.97,435.22,403.12,435.18z"
              />
              <path
                d="M369.42,396.61c0.06,0.95-0.58,1.72-1.5,1.83c-1.03,0.12-1.93-0.6-1.99-1.6c-0.06-1,0.74-1.84,1.76-1.84
	C368.63,394.99,369.37,395.67,369.42,396.61z"
              />
              <path
                d="M343.11,490.84c-0.95-0.06-1.75-1.02-1.63-1.95c0.11-0.84,0.96-1.56,1.81-1.52c0.98,0.05,1.71,0.94,1.6,1.94
	C344.79,490.19,343.97,490.89,343.11,490.84z"
              />
              <path
                d="M502.44,408.42c0.95-0.05,1.7,0.59,1.8,1.53c0.11,1.05-0.6,1.88-1.64,1.92c-0.95,0.04-1.72-0.61-1.83-1.52
	C500.65,409.36,501.42,408.47,502.44,408.42z"
              />
              <path
                d="M424.55,409.92c0,0.92-0.69,1.68-1.57,1.74c-0.95,0.07-1.84-0.77-1.85-1.73c-0.01-0.96,0.87-1.79,1.84-1.73
	C423.86,408.25,424.55,409,424.55,409.92z"
              />
              <path
                d="M279.94,241.88c0.06,0.95-0.79,1.85-1.74,1.85c-0.86,0-1.63-0.75-1.68-1.64c-0.05-0.92,0.58-1.71,1.48-1.82
	C278.93,240.14,279.88,240.93,279.94,241.88z"
              />
              <path
                d="M268.45,398.25c-0.05,1-0.87,1.72-1.91,1.64c-1.02-0.07-1.73-0.91-1.59-1.93c0.14-0.99,0.73-1.54,1.76-1.54
	C267.71,396.43,268.5,397.26,268.45,398.25z"
              />
              <path
                d="M420.09,106.85c0.03,0.98-0.76,1.79-1.78,1.78c-1.03-0.01-1.6-0.57-1.73-1.56c-0.13-1.01,0.61-1.85,1.63-1.9
	C419.22,105.11,420.06,105.86,420.09,106.85z"
              />
              <path
                d="M411.67,259.52c-0.02,1.01-0.84,1.77-1.86,1.7c-1.01-0.08-1.58-0.65-1.67-1.65c-0.08-0.98,0.73-1.8,1.74-1.82
	C410.91,257.73,411.69,258.51,411.67,259.52z"
              />
              <path
                d="M468.55,436.61c-0.95,0.06-1.84-0.78-1.83-1.75c0-0.87,0.74-1.65,1.62-1.72c0.89-0.07,1.69,0.6,1.8,1.5
	C470.25,435.65,469.52,436.55,468.55,436.61z"
              />
              <path
                d="M290.57,257.28c1.02-0.06,1.88,0.74,1.88,1.74c0,0.9-0.73,1.66-1.66,1.72c-1.04,0.07-1.87-0.71-1.86-1.74
	C288.93,258.06,289.62,257.33,290.57,257.28z"
              />
              <path
                d="M413.09,194.34c-0.05,0.95-0.77,1.62-1.73,1.62c-1.03,0-1.84-0.84-1.77-1.83c0.07-1,0.97-1.73,1.99-1.62
	C412.5,192.61,413.14,193.39,413.09,194.34z"
              />
              <path
                d="M423.41,435.39c-0.02,0.96-0.94,1.78-1.88,1.7c-0.89-0.08-1.56-0.85-1.55-1.77c0.02-0.92,0.72-1.66,1.61-1.7
	C422.57,433.58,423.43,434.43,423.41,435.39z"
              />
              <path
                d="M267.94,351.42c-0.03,0.96-0.94,1.78-1.88,1.69c-0.89-0.08-1.56-0.85-1.54-1.77c0.02-0.92,0.73-1.66,1.62-1.69
	C267.11,349.61,267.97,350.46,267.94,351.42z"
              />
              <path
                d="M377.37,489.93c-0.07,1-0.97,1.72-2,1.59c-0.92-0.11-1.56-0.89-1.49-1.84c0.06-0.94,0.79-1.62,1.74-1.61
	C376.64,488.09,377.44,488.93,377.37,489.93z"
              />
              <path
                d="M411.2,109.37c-0.84,0.04-1.67-0.69-1.77-1.56c-0.1-1,0.65-1.89,1.62-1.92c0.85-0.03,1.7,0.7,1.8,1.54
	C412.96,108.37,412.14,109.32,411.2,109.37z"
              />
              <path
                d="M473.81,517.87c-0.13,0.99-1.02,1.64-2.04,1.49c-0.99-0.14-1.64-1.05-1.43-2.06c0.21-0.99,0.87-1.46,1.88-1.39
	C473.22,515.98,473.94,516.89,473.81,517.87z"
              />
              <path
                d="M398.87,258.34c-1.01-0.01-1.8-0.81-1.77-1.79c0.04-0.99,0.88-1.74,1.89-1.67c1.04,0.07,1.55,0.7,1.63,1.68
	C400.7,257.56,399.9,258.35,398.87,258.34z"
              />
              <path
                d="M374.01,573.19c0.07,0.99-0.7,1.82-1.71,1.84c-1.02,0.02-1.63-0.51-1.78-1.51c-0.16-1.01,0.55-1.87,1.56-1.96
	C373.1,571.48,373.95,572.2,374.01,573.19z"
              />
              <path
                d="M418.33,437.45c0.96,0.01,1.8,0.92,1.72,1.86c-0.07,0.85-0.89,1.62-1.73,1.62c-0.95,0-1.75-0.88-1.69-1.87
	C416.69,438.18,417.46,437.44,418.33,437.45z"
              />
              <path
                d="M465.53,191.3c0.86-0.03,1.66,0.7,1.73,1.59c0.08,0.99-0.7,1.88-1.66,1.9c-0.84,0.01-1.68-0.74-1.76-1.59
	C463.74,192.25,464.57,191.32,465.53,191.3z"
              />
              <path
                d="M527.09,612.23c-0.95,0.06-1.84-0.78-1.83-1.75c0-0.87,0.74-1.65,1.62-1.72c0.89-0.07,1.69,0.6,1.79,1.5
	C528.79,611.27,528.06,612.17,527.09,612.23z"
              />
              <path
                d="M463.75,442.69c0.06,0.98-0.72,1.81-1.73,1.82c-1.02,0.01-1.62-0.53-1.76-1.52c-0.15-1.01,0.57-1.86,1.58-1.94
	C462.85,440.98,463.69,441.7,463.75,442.69z"
              />
              <path
                d="M393.36,257.3c0.02,0.92-0.66,1.69-1.55,1.77c-0.94,0.08-1.85-0.74-1.88-1.7c-0.02-0.96,0.84-1.81,1.81-1.76
	C392.64,255.64,393.35,256.38,393.36,257.3z"
              />
              <path
                d="M268.8,401.44c0.04-0.94,0.75-1.63,1.68-1.65c1-0.01,1.84,0.82,1.79,1.8c-0.04,1-0.91,1.73-1.94,1.63
	C269.4,403.14,268.76,402.4,268.8,401.44z"
              />
              <path
                d="M422.07,444.26c-0.9-0.04-1.6-0.82-1.58-1.75c0.02-0.93,0.74-1.67,1.65-1.67c0.97-0.01,1.83,0.91,1.74,1.86
	C423.79,443.56,422.92,444.31,422.07,444.26z"
              />
              <path
                d="M425.42,440.44c-0.91-0.05-1.61-0.83-1.58-1.77c0.02-0.93,0.77-1.68,1.67-1.68c0.98,0,1.83,0.93,1.73,1.88
	C427.15,439.74,426.29,440.49,425.42,440.44z"
              />
              <path
                d="M299.57,444.21c0.91,0.01,1.63,0.75,1.65,1.68c0.01,0.93-0.69,1.7-1.59,1.74c-0.96,0.05-1.85-0.84-1.8-1.8
	C297.87,444.96,298.69,444.2,299.57,444.21z"
              />
              <path
                d="M418.78,444.69c0.87-0.01,1.69,0.76,1.74,1.62c0.05,0.96-0.84,1.85-1.8,1.8c-0.9-0.04-1.6-0.82-1.59-1.74
	C417.14,445.43,417.87,444.69,418.78,444.69z"
              />
              <path
                d="M279.69,254.4c1.01,0.03,1.79,0.9,1.7,1.89c-0.08,0.89-0.86,1.57-1.78,1.55c-1.05-0.01-1.77-0.82-1.67-1.87
	C278.01,255.03,278.74,254.38,279.69,254.4z"
              />
              <path
                d="M445.96,447.8c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.64
	C445.15,446.13,445.94,446.92,445.96,447.8z"
              />
              <path
                d="M367.65,564.73c-0.87-0.02-1.67-0.83-1.7-1.7c-0.02-0.98,0.88-1.82,1.86-1.74c0.92,0.07,1.59,0.85,1.54,1.79
	C369.32,563.98,368.52,564.75,367.65,564.73z"
              />
              <path
                d="M354.2,490.01c0.95-0.03,1.67,0.62,1.76,1.56c0.1,1.05-0.62,1.86-1.67,1.88c-0.93,0.01-1.7-0.66-1.79-1.55
	C352.41,490.91,353.19,490.04,354.2,490.01z"
              />
              <path
                d="M385.54,353.57c-0.85,0.05-1.73-0.69-1.82-1.54c-0.11-0.94,0.74-1.88,1.71-1.88c0.91,0,1.65,0.73,1.67,1.65
	C387.13,352.74,386.45,353.52,385.54,353.57z"
              />
              <path
                d="M368.67,488.83c0.91,0.01,1.63,0.75,1.64,1.68c0.01,0.93-0.69,1.7-1.59,1.74c-0.96,0.04-1.85-0.85-1.8-1.81
	C366.97,489.58,367.79,488.82,368.67,488.83z"
              />
              <path
                d="M365.34,559.72c-0.05,0.86-0.88,1.64-1.74,1.64c-0.97-0.01-1.76-0.92-1.65-1.92c0.1-0.92,0.89-1.57,1.81-1.49
	C364.65,558.02,365.38,558.85,365.34,559.72z"
              />
              <path
                d="M366.53,613.67c-0.03,0.95-0.71,1.63-1.67,1.66c-1.02,0.02-1.85-0.79-1.81-1.77c0.04-0.99,0.92-1.75,1.93-1.66
	C365.91,611.98,366.56,612.72,366.53,613.67z"
              />
              <path
                d="M354.79,437.7c0.02,0.88-0.72,1.7-1.61,1.77c-0.98,0.08-1.83-0.77-1.8-1.79c0.03-0.92,0.79-1.67,1.7-1.65
	C353.96,436.04,354.76,436.83,354.79,437.7z"
              />
              <path
                d="M500.17,573.01c0.03,0.87-0.71,1.69-1.6,1.77c-0.99,0.08-1.83-0.75-1.79-1.77c0.03-0.93,0.77-1.65,1.68-1.65
	C499.34,571.37,500.14,572.14,500.17,573.01z"
              />
              <path
                d="M303.92,434.85c0.01,0.87-0.75,1.69-1.63,1.74c-0.98,0.06-1.81-0.79-1.76-1.81c0.04-0.92,0.81-1.64,1.71-1.62
	C303.12,433.19,303.9,433.98,303.92,434.85z"
              />
              <path
                d="M390.51,410.92c-0.96,0.02-1.67-0.63-1.74-1.58c-0.08-1.04,0.67-1.86,1.7-1.86c0.92,0,1.68,0.69,1.76,1.59
	C392.32,410.05,391.53,410.9,390.51,410.92z"
              />
              <path
                d="M368.88,310.03c0.04,0.93-0.64,1.73-1.54,1.81c-0.97,0.08-1.88-0.78-1.87-1.75c0.01-0.88,0.81-1.67,1.69-1.7
	C368.06,308.37,368.84,309.11,368.88,310.03z"
              />
              <path
                d="M401.56,350.72c-0.03,0.95-0.71,1.63-1.67,1.66c-1.02,0.02-1.85-0.79-1.81-1.77c0.04-0.99,0.92-1.75,1.93-1.66
	C400.93,349.03,401.58,349.77,401.56,350.72z"
              />
              <path
                d="M428.23,532.45c0.04,0.98-0.78,1.8-1.8,1.78c-0.96-0.02-1.64-0.69-1.68-1.64c-0.03-0.95,0.61-1.69,1.54-1.78
	C427.3,530.7,428.18,531.45,428.23,532.45z"
              />
              <path
                d="M414.04,454.27c0.02,0.95-0.64,1.67-1.58,1.74c-1.03,0.08-1.89-0.68-1.9-1.68c-0.02-0.98,0.84-1.79,1.84-1.75
	C413.32,452.62,414.02,453.34,414.04,454.27z"
              />
              <path
                d="M469.41,198.13c-0.86,0-1.69-0.8-1.72-1.67c-0.04-0.98,0.84-1.84,1.83-1.78c0.92,0.06,1.6,0.83,1.57,1.77
	C471.06,197.36,470.29,198.13,469.41,198.13z"
              />
              <path
                d="M311.6,354.72c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.63
	C310.8,353.04,311.59,353.84,311.6,354.72z"
              />
              <path
                d="M526.32,312.32c-0.87,0.02-1.66-0.73-1.71-1.63c-0.05-0.94,0.6-1.71,1.53-1.79c0.98-0.08,1.88,0.75,1.86,1.72
	C527.99,311.48,527.18,312.3,526.32,312.32z"
              />
              <path
                d="M302.48,500.1c0.02,0.87-0.74,1.69-1.62,1.75c-0.98,0.07-1.81-0.78-1.77-1.8c0.04-0.92,0.8-1.65,1.7-1.63
	C301.67,498.44,302.46,499.23,302.48,500.1z"
              />
              <path
                d="M373.04,313.31c-0.05,1-0.92,1.73-1.95,1.62c-0.94-0.09-1.57-0.83-1.52-1.79c0.04-0.94,0.75-1.63,1.69-1.64
	C372.26,311.5,373.09,312.33,373.04,313.31z"
              />
              <path
                d="M356.61,395.83c-0.87-0.02-1.67-0.83-1.7-1.7c-0.02-0.98,0.88-1.82,1.86-1.74c0.92,0.07,1.59,0.85,1.55,1.79
	C358.28,395.07,357.48,395.85,356.61,395.83z"
              />
              <path
                d="M468.69,187.48c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C467.04,188.23,467.79,187.48,468.69,187.48z"
              />
              <path
                d="M461.48,500.53c0.04,0.93-0.63,1.72-1.54,1.79c-0.96,0.08-1.87-0.78-1.85-1.74c0.02-0.87,0.81-1.66,1.69-1.67
	C460.69,498.88,461.44,499.61,461.48,500.53z"
              />
              <path
                d="M304.11,454.75c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C302.46,455.5,303.2,454.75,304.11,454.75z"
              />
              <path
                d="M289.01,195.87c0.94,0,1.65,0.68,1.7,1.63c0.06,1.05-0.7,1.84-1.74,1.82c-0.93-0.02-1.68-0.72-1.73-1.62
	C287.18,196.71,288,195.86,289.01,195.87z"
              />
              <path
                d="M429.12,353.5c0.91,0.01,1.65,0.76,1.66,1.69c0.02,0.94-0.69,1.71-1.59,1.76c-0.97,0.05-1.86-0.84-1.81-1.81
	C427.42,354.26,428.24,353.49,429.12,353.5z"
              />
              <path
                d="M295.79,215.28c0.04,0.99-0.79,1.8-1.81,1.77c-0.95-0.02-1.64-0.7-1.67-1.65c-0.03-0.94,0.62-1.69,1.55-1.77
	C294.87,213.54,295.76,214.29,295.79,215.28z"
              />
              <path
                d="M425.01,456.99c-0.03,0.91-0.78,1.67-1.66,1.68c-0.95,0-1.82-0.95-1.73-1.88c0.09-0.86,0.94-1.58,1.82-1.53
	C424.37,455.3,425.04,456.05,425.01,456.99z"
              />
              <path
                d="M356.23,412.37c-0.03,0.87-0.84,1.67-1.71,1.68c-0.97,0.01-1.79-0.91-1.69-1.91c0.08-0.92,0.89-1.61,1.8-1.54
	C355.52,410.68,356.26,411.49,356.23,412.37z"
              />
              <path
                d="M424.88,433.24c-0.86,0.05-1.73-0.7-1.82-1.56c-0.1-0.95,0.76-1.89,1.74-1.88c0.91,0,1.65,0.75,1.67,1.68
	C426.48,432.41,425.78,433.19,424.88,433.24z"
              />
              <path
                d="M347.34,414.53c-0.87,0.02-1.66-0.73-1.71-1.63c-0.05-0.94,0.6-1.71,1.53-1.79c0.98-0.08,1.88,0.75,1.86,1.72
	C349,413.69,348.2,414.5,347.34,414.53z"
              />
              <path
                d="M299.78,53.78c-0.67,0.18-1.34,0.5-2.02,0.5c-1.2,0.01-1.83-1.28-1.03-2.17c0.48-0.52,1.31-1.04,1.97-1.01
	c0.92,0.04,1.1,0.97,1.03,1.82c-0.03,0.31-0.03,0.62-0.04,0.93C299.69,53.86,299.78,53.78,299.78,53.78z"
              />
              <path
                d="M402.03,460.6c-0.87,0.02-1.67-0.75-1.71-1.65c-0.04-0.94,0.63-1.72,1.55-1.79c0.99-0.08,1.88,0.77,1.86,1.75
	C403.71,459.78,402.9,460.58,402.03,460.6z"
              />
              <path
                d="M470.92,501.53c0.93,0.03,1.63,0.74,1.65,1.68c0.03,1.04-0.76,1.81-1.81,1.76c-0.92-0.04-1.66-0.78-1.68-1.67
	C469.07,502.31,469.92,501.49,470.92,501.53z"
              />
              <path
                d="M297.3,505.67c-0.9-0.05-1.59-0.82-1.57-1.75c0.02-0.92,0.76-1.65,1.66-1.65c0.96,0,1.82,0.93,1.72,1.87
	C299.03,504.98,298.16,505.72,297.3,505.67z"
              />
              <path
                d="M437.34,181.19c0.05,0.98-0.76,1.81-1.78,1.8c-0.96-0.01-1.65-0.67-1.69-1.63c-0.04-0.95,0.59-1.7,1.52-1.8
	C436.4,179.45,437.29,180.19,437.34,181.19z"
              />
              <path
                d="M365.04,346.53c0.03,0.93-0.67,1.72-1.57,1.78c-0.97,0.06-1.87-0.81-1.84-1.78c0.03-0.88,0.84-1.66,1.72-1.66
	C364.26,344.86,365.01,345.6,365.04,346.53z"
              />
              <path
                d="M473.48,430.98c0.03,0.93-0.66,1.71-1.56,1.77c-0.96,0.06-1.86-0.81-1.83-1.77c0.03-0.87,0.83-1.64,1.71-1.65
	C472.71,429.32,473.45,430.05,473.48,430.98z"
              />
              <path
                d="M463.74,505.7c-0.86,0.05-1.73-0.7-1.82-1.56c-0.1-0.95,0.76-1.89,1.74-1.88c0.91,0,1.65,0.75,1.67,1.68
	C465.35,504.87,464.65,505.65,463.74,505.7z"
              />
              <path
                d="M354.3,430.62c-0.04,0.86-0.86,1.65-1.72,1.66c-0.96,0.01-1.77-0.91-1.67-1.9c0.09-0.92,0.88-1.58,1.8-1.51
	C353.6,428.93,354.34,429.75,354.3,430.62z"
              />
              <path
                d="M413.85,427.15c0.93,0.05,1.62,0.77,1.63,1.71c0.01,1.04-0.8,1.79-1.84,1.72c-0.92-0.06-1.65-0.8-1.65-1.7
	C411.98,427.91,412.85,427.1,413.85,427.15z"
              />
              <path
                d="M297.98,216.75c0.9,0.06,1.65,0.87,1.62,1.75c-0.02,0.97-0.98,1.8-1.93,1.68c-0.89-0.12-1.55-0.95-1.47-1.87
	C296.27,217.4,297.08,216.7,297.98,216.75z"
              />
              <path
                d="M328.87,429.06c0.03,0.88-0.71,1.71-1.6,1.78c-0.99,0.08-1.84-0.75-1.8-1.78c0.03-0.93,0.79-1.67,1.69-1.66
	C328.04,427.4,328.85,428.18,328.87,429.06z"
              />
              <path
                d="M336.1,428.31c0.05,0.98-0.77,1.81-1.79,1.79c-0.96-0.01-1.65-0.68-1.68-1.64c-0.04-0.95,0.6-1.7,1.53-1.79
	C335.16,426.57,336.05,427.32,336.1,428.31z"
              />
              <path
                d="M384.72,342.94c0.88,0,1.7,0.77,1.74,1.64c0.04,0.97-0.85,1.86-1.82,1.8c-0.9-0.05-1.61-0.83-1.59-1.77
	C383.07,343.7,383.81,342.95,384.72,342.94z"
              />
              <path
                d="M324.73,465.62c-0.02,0.92-0.77,1.68-1.65,1.69c-0.96,0.01-1.83-0.93-1.74-1.87c0.08-0.86,0.93-1.59,1.8-1.55
	C324.07,463.93,324.75,464.67,324.73,465.62z"
              />
              <path
                d="M368.18,69.92c0.91,0.01,1.64,0.76,1.66,1.69c0.01,0.94-0.69,1.71-1.6,1.75c-0.97,0.05-1.86-0.84-1.81-1.81
	C366.48,70.67,367.3,69.91,368.18,69.92z"
              />
              <path
                d="M355.59,425.02c0.91-0.05,1.69,0.64,1.76,1.56c0.07,0.92-0.58,1.74-1.47,1.85c-0.94,0.12-1.9-0.72-1.92-1.67
	C353.95,425.87,354.71,425.07,355.59,425.02z"
              />
              <path
                d="M357.16,70.71c-1.02,0.01-1.84-0.81-1.79-1.79c0.04-0.9,0.79-1.62,1.71-1.65c1.03-0.03,1.81,0.76,1.76,1.8
	C358.81,70.02,358.12,70.69,357.16,70.71z"
              />
              <path
                d="M401.07,97.9c-0.06,0.95-0.76,1.61-1.71,1.6c-1.02-0.01-1.82-0.85-1.75-1.83c0.07-0.99,0.97-1.72,1.98-1.6
	C400.5,96.18,401.13,96.95,401.07,97.9z"
              />
              <path
                d="M469.22,237.96c-0.87,0.02-1.67-0.75-1.72-1.64c-0.05-0.94,0.62-1.72,1.54-1.8c0.98-0.08,1.89,0.76,1.87,1.73
	C470.89,237.12,470.09,237.93,469.22,237.96z"
              />
              <path
                d="M425.98,424.36c-0.03,0.92-0.79,1.69-1.67,1.69c-0.96,0-1.83-0.95-1.73-1.9c0.09-0.87,0.95-1.6,1.82-1.55
	C425.32,422.66,426.01,423.42,425.98,424.36z"
              />
              <path
                d="M268.64,255.22c-0.87,0.02-1.66-0.74-1.7-1.64c-0.05-0.94,0.61-1.7,1.54-1.78c0.98-0.08,1.88,0.76,1.85,1.73
	C270.3,254.39,269.49,255.2,268.64,255.22z"
              />
              <path
                d="M343.01,467.82c-0.04,0.95-0.73,1.62-1.69,1.63c-1.02,0.01-1.83-0.82-1.78-1.8c0.05-1,0.94-1.73,1.95-1.63
	C342.42,466.12,343.05,466.87,343.01,467.82z"
              />
              <path
                d="M412.45,246.52c0.87-0.05,1.73,0.67,1.82,1.52c0.1,0.94-0.77,1.89-1.72,1.89c-0.88,0-1.64-0.76-1.67-1.67
	C410.86,247.33,411.53,246.58,412.45,246.52z"
              />
              <path
                d="M295.94,341.7c0.04,0.93-0.64,1.73-1.54,1.81c-0.97,0.08-1.88-0.78-1.87-1.75c0.01-0.88,0.81-1.67,1.69-1.7
	C295.13,340.04,295.9,340.78,295.94,341.7z"
              />
              <path
                d="M491.72,227.13c-0.02,0.92-0.77,1.69-1.65,1.7c-0.96,0.01-1.84-0.92-1.75-1.88c0.08-0.87,0.93-1.61,1.81-1.57
	C491.04,225.43,491.74,226.19,491.72,227.13z"
              />
              <path
                d="M369.63,250.34c0.91,0.01,1.64,0.76,1.65,1.69c0.01,0.94-0.7,1.71-1.6,1.75c-0.97,0.04-1.86-0.85-1.8-1.82
	C367.92,251.09,368.75,250.33,369.63,250.34z"
              />
              <path
                d="M432.96,522.88c0.91,0.01,1.65,0.76,1.66,1.69c0.02,0.94-0.69,1.71-1.59,1.76c-0.97,0.05-1.86-0.84-1.81-1.81
	C431.26,523.64,432.08,522.88,432.96,522.88z"
              />
              <path
                d="M433.47,138.31c0.01,0.87-0.75,1.69-1.63,1.74c-0.98,0.06-1.81-0.79-1.76-1.81c0.04-0.92,0.81-1.64,1.71-1.62
	C432.67,136.65,433.46,137.44,433.47,138.31z"
              />
              <path
                d="M401.9,417.34c0.87-0.05,1.73,0.67,1.82,1.52c0.1,0.94-0.77,1.89-1.72,1.89c-0.88,0-1.64-0.76-1.67-1.67
	C400.3,418.15,400.97,417.4,401.9,417.34z"
              />
              <path
                d="M631.39,375.67c0,0-0.07-0.15-0.07-0.15c-0.97-2.21-0.93-2.42,0.76-3.91c1.19,1.03,1.36,2.38,1.36,4.07
	C632.71,375.67,632.05,375.67,631.39,375.67z"
              />
              <path
                d="M646.05,629.18c0.01,0.88-0.75,1.7-1.63,1.75c-0.98,0.06-1.82-0.8-1.77-1.82c0.04-0.92,0.82-1.66,1.72-1.63
	C645.25,627.51,646.04,628.3,646.05,629.18z"
              />
              <path
                d="M340.32,371.96c0.94-0.01,1.66,0.65,1.73,1.6c0.08,1.05-0.66,1.85-1.71,1.85c-0.93,0-1.69-0.69-1.76-1.59
	C338.51,372.83,339.3,371.98,340.32,371.96z"
              />
              <path
                d="M259.29,377.26c0.02,0.87-0.74,1.69-1.62,1.75c-0.98,0.07-1.81-0.78-1.77-1.8c0.04-0.92,0.8-1.65,1.7-1.63
	C258.48,375.6,259.27,376.39,259.29,377.26z"
              />
              <path
                d="M360.78,422.63c0,0.94-0.67,1.66-1.61,1.71c-1.02,0.06-1.88-0.72-1.88-1.71c0-0.98,0.87-1.78,1.87-1.72
	C360.09,420.97,360.77,421.7,360.78,422.63z"
              />
              <path
                d="M423.45,122.93c0.93,0.05,1.62,0.77,1.63,1.71c0.01,1.04-0.8,1.79-1.84,1.72c-0.92-0.06-1.65-0.8-1.65-1.7
	C421.58,123.69,422.45,122.88,423.45,122.93z"
              />
              <path
                d="M352.06,488.62c-0.01,0.91-0.73,1.67-1.61,1.69c-0.94,0.02-1.82-0.89-1.75-1.82c0.07-0.84,0.9-1.57,1.76-1.55
	C351.39,486.98,352.07,487.69,352.06,488.62z"
              />
              <path
                d="M470.02,537.53c-0.93-0.06-1.57-0.82-1.51-1.77c0.06-0.93,0.84-1.61,1.75-1.54c0.87,0.07,1.62,0.92,1.55,1.78
	C471.75,536.86,470.9,537.58,470.02,537.53z"
              />
              <path
                d="M432.22,456.18c0.02,0.95-0.64,1.68-1.57,1.72c-0.91,0.05-1.72-0.63-1.81-1.52c-0.1-0.96,0.74-1.85,1.72-1.86
	C431.48,454.53,432.19,455.23,432.22,456.18z"
              />
              <path
                d="M487.18,422.73c-0.06,0.87-0.89,1.61-1.78,1.56c-0.94-0.05-1.59-0.79-1.54-1.75c0.05-0.94,0.82-1.63,1.73-1.57
	C486.48,421.02,487.24,421.87,487.18,422.73z"
              />
              <path
                d="M428.69,247.07c-0.04,0.99-0.9,1.73-1.92,1.63c-0.92-0.08-1.56-0.81-1.53-1.76c0.03-0.93,0.72-1.63,1.65-1.65
	C427.88,245.28,428.73,246.11,428.69,247.07z"
              />
              <path
                d="M365.57,622.5c-0.95,0.02-1.67-0.65-1.71-1.6c-0.05-0.97,0.59-1.71,1.53-1.78c0.99-0.08,1.89,0.75,1.88,1.72
	C367.25,621.71,366.47,622.48,365.57,622.5z"
              />
              <path
                d="M414.97,112.45c-0.95,0.01-1.65-0.63-1.72-1.58c-0.07-1.04,0.67-1.85,1.69-1.85c0.9,0,1.68,0.71,1.75,1.59
	C416.78,111.58,415.98,112.43,414.97,112.45z"
              />
              <path
                d="M384.04,608.57c0,0.85-0.78,1.67-1.64,1.72c-0.97,0.05-1.79-0.81-1.74-1.82c0.05-0.92,0.81-1.62,1.72-1.59
	C383.26,606.91,384.04,607.71,384.04,608.57z"
              />
              <path
                d="M360.06,185.81c-0.88,0.02-1.71-0.74-1.74-1.61c-0.03-0.86,0.76-1.69,1.63-1.73c0.91-0.03,1.67,0.68,1.7,1.62
	C361.67,185.06,360.99,185.79,360.06,185.81z"
              />
              <path
                d="M393.86,596.89c-0.04,0.95-0.72,1.62-1.66,1.63c-1,0.02-1.83-0.81-1.78-1.77c0.05-0.97,0.94-1.73,1.93-1.63
	C393.26,595.2,393.89,595.94,393.86,596.89z"
              />
              <path
                d="M426.99,538.02c0.88,0.02,1.67,0.81,1.69,1.67c0.02,0.96-0.89,1.78-1.88,1.7c-0.94-0.08-1.57-0.81-1.52-1.77
	C425.34,538.68,426.07,537.99,426.99,538.02z"
              />
              <path
                d="M475.7,539.33c-0.05,0.88-0.86,1.6-1.76,1.59c-1.04-0.02-1.75-0.85-1.62-1.91c0.12-0.96,0.87-1.56,1.83-1.47
	C475.04,537.64,475.76,538.46,475.7,539.33z"
              />
              <path
                d="M376.18,79.79c-0.95,0.05-1.67-0.59-1.74-1.54c-0.07-0.94,0.55-1.72,1.46-1.82c0.96-0.11,1.9,0.7,1.92,1.66
	C377.82,78.94,377.06,79.74,376.18,79.79z"
              />
              <path
                d="M415.72,116.23c0.93,0.02,1.64,0.75,1.65,1.7c0.01,0.95-0.69,1.68-1.64,1.7c-1.01,0.03-1.87-0.83-1.79-1.81
	C414,116.94,414.82,116.21,415.72,116.23z"
              />
              <path
                d="M435.85,246.42c0.07,0.85-0.65,1.68-1.53,1.77c-0.94,0.1-1.69-0.49-1.81-1.43c-0.13-1.03,0.58-1.88,1.59-1.91
	C434.96,244.83,435.79,245.57,435.85,246.42z"
              />
              <path
                d="M346.32,424.03c0.02,0.95-0.64,1.68-1.57,1.72c-0.91,0.05-1.72-0.63-1.81-1.52c-0.1-0.96,0.74-1.85,1.72-1.86
	C345.59,422.38,346.3,423.08,346.32,424.03z"
              />
              <path
                d="M463.76,173.36c-0.91,0.05-1.73-0.63-1.82-1.52c-0.1-0.97,0.72-1.87,1.72-1.87c0.94,0,1.65,0.7,1.68,1.65
	C465.36,172.58,464.7,173.31,463.76,173.36z"
              />
              <path
                d="M363.84,458.85c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C363.29,457.1,363.98,458,363.84,458.85z"
              />
              <path
                d="M279.02,250.88c-0.84,0.06-1.72-0.68-1.82-1.52c-0.11-0.93,0.74-1.87,1.7-1.87c0.91-0.01,1.64,0.71,1.67,1.63
	C280.6,250.04,279.92,250.82,279.02,250.88z"
              />
              <path
                d="M397.15,94.52c-0.05,0.96-0.79,1.63-1.74,1.57c-0.9-0.05-1.66-0.89-1.6-1.76c0.06-0.87,0.9-1.63,1.78-1.58
	C396.53,92.8,397.2,93.55,397.15,94.52z"
              />
              <path
                d="M252.73,345.43c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.72c0.03-0.86,0.87-1.65,1.75-1.63
	C252.06,343.72,252.75,344.46,252.73,345.43z"
              />
              <path
                d="M405.4,247.24c0.86,0.02,1.67,0.82,1.68,1.67c0.02,0.95-0.89,1.78-1.87,1.7c-0.93-0.08-1.56-0.81-1.51-1.77
	C403.75,247.91,404.49,247.21,405.4,247.24z"
              />
              <path
                d="M431.02,442.06c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C430.47,440.31,431.15,441.21,431.02,442.06z"
              />
              <path
                d="M424.57,117.45c0.01,0.93-0.66,1.65-1.59,1.71c-1,0.06-1.87-0.72-1.87-1.69c0-0.96,0.87-1.77,1.85-1.71
	C423.89,115.8,424.57,116.52,424.57,117.45z"
              />
              <path
                d="M452.63,503.02c-0.92,0-1.71-0.73-1.75-1.62c-0.05-0.98,0.83-1.83,1.83-1.78c0.93,0.05,1.61,0.79,1.6,1.74
	C454.29,502.33,453.58,503.02,452.63,503.02z"
              />
              <path
                d="M441.21,247.51c-0.84,0.06-1.72-0.68-1.82-1.51c-0.11-0.92,0.73-1.85,1.69-1.86c0.91-0.01,1.63,0.69,1.66,1.62
	C442.78,246.67,442.1,247.45,441.21,247.51z"
              />
              <path
                d="M429.14,420.47c0.01,0.85-0.77,1.66-1.63,1.71c-0.97,0.05-1.79-0.79-1.73-1.8c0.05-0.92,0.79-1.61,1.7-1.58
	C428.36,418.82,429.14,419.61,429.14,420.47z"
              />
              <path
                d="M416.69,443.22c0.03,0.88-0.72,1.67-1.62,1.73c-0.97,0.06-1.69-0.56-1.78-1.53c-0.09-1.05,0.67-1.88,1.69-1.86
	C415.87,441.58,416.66,442.35,416.69,443.22z"
              />
              <path
                d="M306.98,344.52c-0.05,0.95-0.78,1.62-1.73,1.59c-0.9-0.03-1.68-0.81-1.68-1.68c0-0.97,0.91-1.79,1.89-1.7
	C306.4,342.81,307.03,343.57,306.98,344.52z"
              />
              <path
                d="M456.52,443.36c-0.05,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.64-1.91c0.11-0.96,0.87-1.57,1.83-1.48
	C455.85,441.66,456.57,442.48,456.52,443.36z"
              />
              <path
                d="M437.55,367.18c0.9,0.03,1.69,0.8,1.7,1.68c0.02,0.98-0.88,1.79-1.89,1.71c-0.95-0.08-1.59-0.82-1.54-1.79
	C435.87,367.84,436.61,367.16,437.55,367.18z"
              />
              <path
                d="M264.74,208.85c0.89-0.01,1.7,0.73,1.75,1.61c0.06,0.97-0.8,1.82-1.81,1.78c-0.94-0.04-1.62-0.77-1.6-1.72
	C263.1,209.57,263.81,208.85,264.74,208.85z"
              />
              <path
                d="M411.8,575.26c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C413.45,574.52,412.66,575.28,411.8,575.26z"
              />
              <path
                d="M397.93,600.08c0.06,0.86-0.7,1.7-1.59,1.75c-0.94,0.06-1.68-0.62-1.73-1.57c-0.05-0.95,0.62-1.71,1.55-1.76
	C397.03,598.46,397.87,599.22,397.93,600.08z"
              />
              <path
                d="M406.81,454.7c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C406.07,453.08,406.77,453.76,406.81,454.7z"
              />
              <path
                d="M357.23,442.54c-0.89,0.05-1.71-0.63-1.81-1.51c-0.1-0.94,0.74-1.86,1.71-1.86c0.92,0,1.63,0.7,1.66,1.64
	C358.82,441.76,358.16,442.49,357.23,442.54z"
              />
              <path
                d="M368.95,575.44c0.89,0.03,1.67,0.82,1.68,1.69c0.01,0.97-0.91,1.78-1.9,1.69c-0.95-0.09-1.57-0.83-1.51-1.8
	C367.28,576.08,368.01,575.41,368.95,575.44z"
              />
              <path
                d="M391.9,342.24c0.89-0.01,1.7,0.73,1.75,1.61c0.06,0.97-0.8,1.82-1.81,1.78c-0.94-0.04-1.62-0.77-1.6-1.72
	C390.25,342.96,390.96,342.25,391.9,342.24z"
              />
              <path
                d="M391.2,607.75c0.06,0.86-0.69,1.69-1.57,1.75c-0.92,0.06-1.68-0.61-1.73-1.55c-0.05-0.94,0.61-1.71,1.53-1.76
	C390.3,606.14,391.14,606.89,391.2,607.75z"
              />
              <path
                d="M390.37,371.03c-0.95-0.06-1.59-0.81-1.52-1.78c0.07-0.95,0.84-1.63,1.76-1.55c0.89,0.07,1.63,0.92,1.57,1.79
	C392.11,370.37,391.27,371.09,390.37,371.03z"
              />
              <path
                d="M415.57,578.31c-0.88-0.05-1.64-0.88-1.59-1.74c0.05-0.87,0.89-1.62,1.76-1.57c0.91,0.05,1.59,0.81,1.54,1.75
	C417.25,577.69,416.5,578.37,415.57,578.31z"
              />
              <path
                d="M455.74,495.78c0.91,0,1.7,0.75,1.74,1.64c0.04,0.98-0.83,1.81-1.84,1.76c-0.95-0.05-1.61-0.78-1.58-1.74
	C454.08,496.48,454.8,495.78,455.74,495.78z"
              />
              <path
                d="M565.87,621.72c0.03,0.84-0.77,1.67-1.63,1.69c-0.91,0.03-1.65-0.69-1.67-1.61c-0.02-0.93,0.68-1.68,1.58-1.7
	C565,620.09,565.84,620.88,565.87,621.72z"
              />
              <path
                d="M405.64,440.74c-0.06,0.84-0.92,1.62-1.76,1.6c-0.97-0.02-1.73-0.94-1.61-1.93c0.11-0.91,0.91-1.54,1.83-1.45
	C404.98,439.05,405.7,439.89,405.64,440.74z"
              />
              <path
                d="M517.03,367.71c0.91,0.06,1.57,0.84,1.51,1.78c-0.06,0.94-0.82,1.6-1.75,1.53c-0.88-0.07-1.62-0.91-1.55-1.77
	C515.31,368.38,516.16,367.65,517.03,367.71z"
              />
              <path
                d="M469.94,181.99c0.01,0.86-0.77,1.67-1.63,1.72c-0.97,0.05-1.8-0.8-1.74-1.81c0.05-0.92,0.81-1.62,1.71-1.59
	C469.14,180.33,469.93,181.12,469.94,181.99z"
              />
              <path
                d="M442.25,365.05c0,0.93-0.72,1.66-1.63,1.65c-0.86-0.01-1.68-0.82-1.68-1.65c0-0.84,0.83-1.65,1.68-1.66
	C441.51,363.39,442.24,364.13,442.25,365.05z"
              />
              <path
                d="M483.85,426.57c-0.06,0.87-0.86,1.6-1.75,1.58c-1.03-0.02-1.74-0.85-1.61-1.9c0.11-0.95,0.87-1.56,1.82-1.46
	C483.19,424.88,483.91,425.71,483.85,426.57z"
              />
              <path
                d="M372.29,76.44c-0.95,0.02-1.67-0.65-1.71-1.6c-0.05-0.97,0.59-1.71,1.53-1.78c0.99-0.08,1.89,0.75,1.88,1.72
	C373.97,75.66,373.19,76.42,372.29,76.44z"
              />
              <path
                d="M423.93,545.24c-0.93,0.05-1.74-0.62-1.83-1.52c-0.1-0.98,0.72-1.87,1.73-1.88c0.94,0,1.66,0.7,1.69,1.65
	C425.55,544.46,424.88,545.19,423.93,545.24z"
              />
              <path
                d="M376.86,316.69c-0.06,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.63-1.92c0.12-0.96,0.87-1.57,1.84-1.47
	C376.2,314.99,376.92,315.81,376.86,316.69z"
              />
              <path
                d="M385.5,476.63c0.93,0.03,1.61,0.78,1.57,1.73c-0.03,0.94-0.77,1.64-1.7,1.6c-0.89-0.04-1.66-0.86-1.62-1.73
	C383.79,477.37,384.63,476.6,385.5,476.63z"
              />
              <path
                d="M364.54,592.27c-0.01,0.91-0.73,1.66-1.61,1.68c-0.94,0.02-1.82-0.89-1.75-1.82c0.07-0.84,0.9-1.57,1.77-1.54
	C363.87,590.62,364.55,591.34,364.54,592.27z"
              />
              <path
                d="M488.96,417.14c0.86,0.06,1.62,0.91,1.56,1.75c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.58-0.81-1.52-1.75
	C487.29,417.78,488.07,417.08,488.96,417.14z"
              />
              <path
                d="M485.72,218.43c-0.86-0.01-1.68-0.81-1.68-1.65c0-0.84,0.83-1.66,1.67-1.66c0.9-0.01,1.63,0.74,1.63,1.66
	C487.35,217.71,486.64,218.44,485.72,218.43z"
              />
              <path
                d="M426.82,451.19c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C425.09,451.86,425.94,451.13,426.82,451.19z"
              />
              <path
                d="M556.96,620.59c0.85-0.01,1.69,0.78,1.71,1.62c0.02,0.84-0.78,1.66-1.64,1.69c-0.91,0.02-1.65-0.69-1.66-1.62
	C555.35,621.35,556.05,620.6,556.96,620.59z"
              />
              <path
                d="M412.86,439.88c0.02,0.88-0.74,1.67-1.65,1.72c-0.97,0.05-1.7-0.58-1.77-1.55c-0.08-1.05,0.69-1.88,1.72-1.85
	C412.05,438.22,412.84,439,412.86,439.88z"
              />
              <path
                d="M402.68,593.98c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C404.33,593.24,403.55,593.99,402.68,593.98z"
              />
              <path
                d="M425.72,459.98c0.04-0.94,0.75-1.62,1.68-1.63c0.99-0.01,1.82,0.84,1.78,1.8c-0.05,0.99-0.93,1.72-1.95,1.61
	C426.3,461.67,425.68,460.93,425.72,459.98z"
              />
              <path
                d="M422.88,348.79c-0.03,0.86-0.86,1.63-1.73,1.6c-0.94-0.02-1.61-0.75-1.58-1.7c0.03-0.93,0.78-1.65,1.68-1.62
	C422.11,347.1,422.91,347.94,422.88,348.79z"
              />
              <path
                d="M407.75,445.44c-0.95,0-1.65-0.69-1.67-1.64c-0.02-0.95,0.65-1.69,1.58-1.74c0.99-0.05,1.87,0.8,1.83,1.77
	C409.45,444.69,408.65,445.44,407.75,445.44z"
              />
              <path
                d="M364.08,418.88c-0.04,0.95-0.76,1.63-1.71,1.61c-0.9-0.02-1.69-0.78-1.7-1.66c-0.02-0.97,0.88-1.8,1.87-1.73
	C363.49,417.17,364.13,417.91,364.08,418.88z"
              />
              <path
                d="M459.88,439.46c-0.02,0.89-0.8,1.64-1.71,1.66c-0.96,0.02-1.67-0.66-1.72-1.62c-0.05-1.06,0.75-1.85,1.79-1.78
	C459.15,437.78,459.9,438.58,459.88,439.46z"
              />
              <path
                d="M431.47,385.73c-0.85-0.02-1.66-0.83-1.69-1.68c-0.03-0.96,0.87-1.79,1.84-1.72c0.92,0.07,1.58,0.82,1.53,1.76
	C433.12,384.99,432.34,385.74,431.47,385.73z"
              />
              <path
                d="M399.37,598.01c-0.88,0-1.68-0.74-1.73-1.6c-0.05-0.95,0.84-1.81,1.81-1.76c0.92,0.05,1.59,0.79,1.57,1.73
	C401,597.32,400.3,598.01,399.37,598.01z"
              />
              <path
                d="M449.29,443.97c0.01,0.85-0.75,1.66-1.61,1.72c-0.97,0.06-1.79-0.78-1.74-1.78c0.04-0.92,0.78-1.61,1.69-1.59
	C448.51,442.33,449.28,443.1,449.29,443.97z"
              />
              <path
                d="M433.98,454.05c-0.89,0.04-1.7-0.65-1.78-1.52c-0.09-0.94,0.76-1.84,1.72-1.83c0.91,0.01,1.61,0.72,1.63,1.66
	C435.58,453.29,434.91,454.01,433.98,454.05z"
              />
              <path
                d="M416.41,249.9c0.87,0.01,1.67,0.77,1.7,1.62c0.03,0.94-0.87,1.81-1.82,1.74c-0.89-0.06-1.57-0.83-1.54-1.75
	C414.78,250.59,415.5,249.89,416.41,249.9z"
              />
              <path
                d="M428.05,426c0.91-0.05,1.68,0.62,1.75,1.53c0.07,0.91-0.57,1.72-1.45,1.82c-0.93,0.11-1.88-0.71-1.9-1.65
	C426.42,426.85,427.17,426.05,428.05,426z"
              />
              <path
                d="M443.55,146.48c0.92,0.02,1.61,0.75,1.62,1.68c0.01,0.94-0.68,1.66-1.61,1.68c-0.98,0.03-1.84-0.84-1.77-1.79
	C441.86,147.19,442.68,146.46,443.55,146.48z"
              />
              <path
                d="M494.71,571.61c-0.95,0.06-1.69-0.61-1.74-1.57c-0.05-0.96,0.62-1.72,1.55-1.77c0.88-0.04,1.73,0.71,1.78,1.58
	C496.36,570.72,495.61,571.55,494.71,571.61z"
              />
              <path
                d="M304.01,494.37c0.87-0.05,1.7,0.64,1.8,1.51c0.11,0.94-0.72,1.84-1.7,1.84c-0.93,0-1.63-0.69-1.65-1.62
	C302.44,495.16,303.09,494.43,304.01,494.37z"
              />
              <path
                d="M410.89,252.25c0.03,0.85-0.77,1.67-1.64,1.7c-0.92,0.03-1.66-0.68-1.68-1.61c-0.02-0.95,0.67-1.69,1.59-1.71
	C410.02,250.61,410.86,251.4,410.89,252.25z"
              />
              <path
                d="M381.63,81.47c0,0.86-0.82,1.66-1.7,1.67c-0.94,0.01-1.66-0.72-1.65-1.67c0-0.95,0.73-1.69,1.66-1.68
	C380.81,79.8,381.63,80.61,381.63,81.47z"
              />
              <path
                d="M368.27,571.62c-0.89,0.05-1.71-0.63-1.81-1.51c-0.1-0.94,0.74-1.86,1.71-1.86c0.92,0,1.63,0.7,1.66,1.64
	C369.86,570.84,369.2,571.57,368.27,571.62z"
              />
              <path
                d="M390.78,600.69c-0.02,0.88-0.8,1.64-1.7,1.66c-0.95,0.02-1.67-0.66-1.71-1.61c-0.05-1.05,0.75-1.84,1.78-1.78
	C390.04,599.01,390.79,599.81,390.78,600.69z"
              />
              <path
                d="M364.08,253c-0.12,0.96-0.87,1.56-1.85,1.45c-0.91-0.1-1.62-0.91-1.55-1.8c0.07-0.97,1.05-1.74,2.02-1.58
	C363.6,251.22,364.19,252.05,364.08,253z"
              />
              <path
                d="M415.71,349.25c-0.02,0.84-0.82,1.62-1.68,1.64c-0.92,0.02-1.64-0.66-1.68-1.59c-0.04-1.03,0.75-1.82,1.75-1.76
	C414.96,347.59,415.73,348.41,415.71,349.25z"
              />
              <path
                d="M554.86,618.94c-0.03,0.85-0.83,1.61-1.7,1.62c-1.02,0.01-1.75-0.81-1.65-1.85c0.09-0.93,0.85-1.57,1.76-1.51
	C554.14,617.28,554.89,618.09,554.86,618.94z"
              />
              <path
                d="M458.12,504.77c-0.04,0.94-0.76,1.62-1.69,1.6c-0.88-0.02-1.67-0.79-1.69-1.65c-0.02-0.95,0.89-1.79,1.85-1.72
	C457.51,503.07,458.15,503.82,458.12,504.77z"
              />
              <path
                d="M318.28,429.92c-0.05-0.95,0.59-1.7,1.52-1.78c0.98-0.08,1.89,0.75,1.87,1.71c-0.01,0.86-0.8,1.64-1.69,1.66
	C319.04,431.54,318.33,430.87,318.28,429.92z"
              />
              <path
                d="M419.64,581.67c-0.92-0.06-1.57-0.82-1.5-1.76c0.06-0.92,0.84-1.61,1.74-1.53c0.87,0.07,1.61,0.92,1.54,1.77
	C421.35,581,420.51,581.72,419.64,581.67z"
              />
              <path
                d="M356.89,253.3c-0.02,0.96-0.73,1.65-1.68,1.64c-0.9,0-1.7-0.75-1.73-1.62c-0.04-0.97,0.84-1.82,1.83-1.76
	C356.24,251.61,356.91,252.35,356.89,253.3z"
              />
              <path
                d="M401,430.13c-0.03,0.9-0.79,1.65-1.66,1.64c-0.94-0.01-1.8-0.96-1.7-1.87c0.09-0.84,0.94-1.55,1.81-1.49
	C400.38,428.47,401.04,429.2,401,430.13z"
              />
              <path
                d="M416.83,569.36c0.06,0.97-0.59,1.71-1.54,1.77c-0.89,0.05-1.73-0.68-1.79-1.56c-0.06-0.87,0.7-1.71,1.59-1.78
	C416,567.73,416.77,568.42,416.83,569.36z"
              />
              <path
                d="M349.67,546.49c-0.03,0.94-0.74,1.63-1.67,1.62c-0.88-0.01-1.68-0.77-1.71-1.63c-0.03-0.96,0.86-1.8,1.83-1.74
	C349.05,544.81,349.7,545.54,349.67,546.49z"
              />
              <path
                d="M450.94,505.13c0.02,0.96-0.65,1.68-1.59,1.72c-0.92,0.04-1.72-0.64-1.81-1.53c-0.09-0.97,0.74-1.86,1.74-1.86
	C450.21,503.47,450.92,504.18,450.94,505.13z"
              />
              <path
                d="M322.08,370.09c0.92-0.01,1.66,0.73,1.67,1.67c0.01,0.95-0.7,1.68-1.64,1.68c-0.89,0-1.71-0.79-1.71-1.66
	C320.39,370.93,321.21,370.1,322.08,370.09z"
              />
              <path
                d="M360.5,462.56c-0.06,0.87-0.9,1.6-1.78,1.55c-0.94-0.05-1.59-0.8-1.53-1.76c0.06-0.93,0.83-1.63,1.74-1.56
	C359.81,460.86,360.56,461.71,360.5,462.56z"
              />
              <path
                d="M432.47,416.32c0.05,0.86-0.72,1.68-1.6,1.73c-0.92,0.05-1.67-0.64-1.7-1.58c-0.04-0.95,0.64-1.7,1.56-1.74
	C431.58,414.7,432.43,415.47,432.47,416.32z"
              />
              <path
                d="M461.65,191.59c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C463.32,191,462.59,191.64,461.65,191.59z"
              />
              <path
                d="M532.76,613.51c0.06,0.86-0.69,1.69-1.57,1.75c-0.92,0.06-1.68-0.61-1.73-1.55c-0.05-0.94,0.61-1.71,1.53-1.76
	C531.85,611.9,532.7,612.64,532.76,613.51z"
              />
              <path
                d="M437.51,141.53c-0.04,0.96-0.76,1.64-1.72,1.62c-0.92-0.02-1.69-0.78-1.71-1.66c-0.02-0.98,0.88-1.81,1.88-1.74
	C436.9,139.82,437.55,140.57,437.51,141.53z"
              />
              <path
                d="M248.37,188.72c0.88-0.04,1.73,0.71,1.78,1.58c0.05,0.87-0.71,1.7-1.6,1.75c-0.95,0.05-1.69-0.62-1.73-1.57
	C246.77,189.52,247.43,188.76,248.37,188.72z"
              />
              <path
                d="M311.94,464.62c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C313.61,464.03,312.88,464.67,311.94,464.62z"
              />
              <path
                d="M416.37,190.62c-0.12,0.96-0.87,1.55-1.84,1.45c-0.9-0.09-1.61-0.91-1.55-1.79c0.07-0.97,1.05-1.73,2.01-1.57
	C415.9,188.85,416.49,189.67,416.37,190.62z"
              />
              <path
                d="M517.16,600.58c0.04,0.96-0.6,1.69-1.55,1.76c-1,0.07-1.89-0.76-1.86-1.73c0.03-0.87,0.82-1.64,1.71-1.66
	C516.39,598.94,517.12,599.63,517.16,600.58z"
              />
              <path
                d="M476.17,546.53c-0.06,0.86-0.86,1.59-1.75,1.58c-1.03-0.02-1.73-0.85-1.61-1.9c0.12-0.94,0.87-1.56,1.81-1.46
	C475.5,544.84,476.22,545.68,476.17,546.53z"
              />
              <path
                d="M427.62,545.25c0.9-0.03,1.64,0.7,1.66,1.62c0.02,0.94-0.67,1.67-1.59,1.69c-0.86,0.01-1.69-0.77-1.71-1.61
	C425.97,546.11,426.77,545.28,427.62,545.25z"
              />
              <path
                d="M391.55,169.05c0.89,0.06,1.64,0.91,1.59,1.78c-0.06,0.88-0.9,1.61-1.79,1.56c-0.95-0.05-1.6-0.8-1.54-1.77
	C389.86,169.67,390.63,168.98,391.55,169.05z"
              />
              <path
                d="M361.41,453.13c-1-0.02-1.79-0.89-1.7-1.85c0.08-0.87,0.87-1.57,1.76-1.56c1.02,0.01,1.74,0.82,1.66,1.86
	C363.06,452.52,362.36,453.15,361.41,453.13z"
              />
              <path
                d="M295.22,208.25c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.57-0.81-1.52-1.75c0.06-0.92,0.83-1.62,1.72-1.55
	C294.53,206.56,295.28,207.4,295.22,208.25z"
              />
              <path
                d="M511.62,389.73c0.93-0.01,1.64,0.65,1.71,1.58c0.08,1.04-0.65,1.84-1.68,1.84c-0.9,0-1.68-0.7-1.75-1.57
	C509.83,390.61,510.63,389.75,511.62,389.73z"
              />
              <path
                d="M406.72,351.65c-0.89-0.04-1.59-0.81-1.58-1.74c0.01-0.93,0.73-1.65,1.65-1.66c0.96-0.01,1.82,0.91,1.73,1.84
	C408.43,350.93,407.56,351.69,406.72,351.65z"
              />
              <path
                d="M480.72,430.25c0.03,0.95-0.61,1.68-1.54,1.76c-1.02,0.09-1.89-0.65-1.92-1.64c-0.03-0.97,0.81-1.8,1.8-1.78
	C479.99,428.62,480.69,429.31,480.72,430.25z"
              />
              <path
                d="M268.87,113c-0.05,0.84-0.89,1.61-1.73,1.6c-0.96-0.01-1.73-0.92-1.62-1.91c0.1-0.9,0.89-1.54,1.81-1.45
	C268.2,111.32,268.93,112.14,268.87,113z"
              />
              <path
                d="M367.92,588.23c-0.02,0.95-0.72,1.64-1.67,1.64c-0.89,0-1.69-0.75-1.73-1.62c-0.04-0.96,0.84-1.81,1.82-1.76
	C367.27,586.54,367.94,587.28,367.92,588.23z"
              />
              <path
                d="M254.52,343.21c-0.89,0.04-1.7-0.65-1.78-1.52c-0.09-0.94,0.76-1.84,1.72-1.83c0.91,0.01,1.61,0.72,1.63,1.66
	C256.12,342.45,255.45,343.17,254.52,343.21z"
              />
              <path
                d="M460.29,506.12c0.91,0,1.64,0.72,1.66,1.64c0.02,0.92-0.66,1.7-1.56,1.75c-0.95,0.06-1.85-0.82-1.82-1.77
	C458.61,506.89,459.42,506.12,460.29,506.12z"
              />
              <path
                d="M367.22,415.01c-0.03,0.86-0.86,1.63-1.73,1.6c-0.94-0.02-1.61-0.75-1.58-1.7c0.03-0.93,0.78-1.65,1.68-1.62
	C366.45,413.32,367.24,414.16,367.22,415.01z"
              />
              <path
                d="M362.62,444.19c0.02,0.94-0.64,1.66-1.57,1.71c-0.89,0.04-1.7-0.64-1.79-1.52c-0.09-0.94,0.75-1.84,1.71-1.84
	C361.89,442.55,362.6,443.26,362.62,444.19z"
              />
              <path
                d="M473.33,533.73c-0.93,0.04-1.73-0.65-1.81-1.55c-0.09-0.98,0.75-1.86,1.75-1.85c0.94,0.01,1.65,0.73,1.67,1.68
	C474.96,532.96,474.28,533.69,473.33,533.73z"
              />
              <path
                d="M511.4,598.96c-0.94,0-1.65-0.73-1.64-1.68c0.01-0.94,0.75-1.68,1.67-1.67c0.87,0.01,1.69,0.84,1.69,1.69
	C513.11,598.17,512.28,598.96,511.4,598.96z"
              />
              <path
                d="M436.69,360.01c0.91-0.05,1.66,0.61,1.74,1.54c0.08,0.94-0.53,1.7-1.44,1.81c-0.96,0.11-1.9-0.69-1.91-1.64
	C435.06,360.88,435.83,360.06,436.69,360.01z"
              />
              <path
                d="M392.98,605.66c-0.92,0.06-1.67-0.62-1.72-1.56c-0.05-0.94,0.62-1.71,1.54-1.75c0.86-0.05,1.71,0.7,1.77,1.57
	C394.62,604.78,393.87,605.61,392.98,605.66z"
              />
              <path
                d="M490.77,564.89c0.87,0,1.68,0.77,1.72,1.62c0.04,0.95-0.84,1.8-1.82,1.74c-0.93-0.05-1.59-0.78-1.56-1.72
	C489.14,565.59,489.85,564.89,490.77,564.89z"
              />
              <path
                d="M539.73,613.24c-0.06,0.95-0.76,1.6-1.71,1.6c-1-0.01-1.81-0.85-1.74-1.83c0.07-0.98,0.98-1.71,1.97-1.59
	C539.17,611.54,539.79,612.3,539.73,613.24z"
              />
              <path
                d="M389.8,340.89c-0.04,0.85-0.86,1.64-1.71,1.65c-0.96,0.01-1.76-0.9-1.66-1.89c0.09-0.91,0.88-1.57,1.79-1.5
	C389.1,339.21,389.84,340.03,389.8,340.89z"
              />
              <path
                d="M408.44,452.65c-1-0.03-1.79-0.89-1.7-1.85c0.08-0.87,0.87-1.57,1.76-1.56c1.02,0.01,1.74,0.82,1.66,1.86
	C410.09,452.04,409.38,452.67,408.44,452.65z"
              />
              <path
                d="M453.05,510.19c-0.89-0.02-1.68-0.85-1.66-1.71c0.02-0.86,0.86-1.65,1.74-1.64c0.94,0.02,1.63,0.75,1.61,1.72
	C454.72,509.51,453.99,510.22,453.05,510.19z"
              />
              <path
                d="M409.23,582.9c0.87,0.01,1.66,0.79,1.68,1.64c0.02,0.94-0.89,1.8-1.84,1.72c-0.89-0.07-1.56-0.85-1.52-1.76
	C407.59,583.59,408.32,582.89,409.23,582.9z"
              />
              <path
                d="M244.32,394.54c-0.86-0.02-1.66-0.85-1.64-1.69c0.02-0.84,0.86-1.64,1.71-1.62c0.91,0.02,1.61,0.76,1.59,1.7
	C245.97,393.85,245.23,394.56,244.32,394.54z"
              />
              <path
                d="M318.45,393.8c0.03,0.95-0.65,1.68-1.59,1.7c-0.87,0.02-1.7-0.75-1.73-1.61c-0.03-0.85,0.77-1.68,1.63-1.71
	C317.67,392.15,318.42,392.87,318.45,393.8z"
              />
              <path
                d="M562.06,618.3c0.07,0.86-0.65,1.68-1.53,1.78c-0.95,0.1-1.7-0.49-1.82-1.44c-0.13-1.04,0.59-1.89,1.6-1.92
	C561.17,616.7,561.99,617.44,562.06,618.3z"
              />
              <path
                d="M260.92,208.84c-0.92,0-1.63-0.73-1.63-1.67c0.01-0.92,0.75-1.67,1.65-1.66c0.86,0.01,1.68,0.84,1.67,1.68
	C262.61,208.05,261.79,208.84,260.92,208.84z"
              />
              <path
                d="M528.68,278.3c-0.05,0.96-0.77,1.63-1.73,1.6c-0.91-0.03-1.69-0.8-1.69-1.68c-0.01-0.98,0.91-1.8,1.9-1.71
	C528.09,276.58,528.73,277.34,528.68,278.3z"
              />
              <path
                d="M434.12,208.37c-0.94-0.01-1.64-0.74-1.61-1.7c0.02-0.95,0.77-1.67,1.69-1.64c0.87,0.03,1.68,0.86,1.66,1.72
	C435.83,207.61,435,208.39,434.12,208.37z"
              />
              <path
                d="M445.81,510.68c-0.88-0.05-1.65-0.85-1.63-1.7c0.02-0.95,0.96-1.77,1.92-1.66c0.91,0.1,1.53,0.88,1.46,1.82
	C447.48,510.09,446.76,510.73,445.81,510.68z"
              />
              <path
                d="M317.61,359.52c0.88,0.03,1.67,0.83,1.67,1.69c0.01,0.96-0.91,1.77-1.9,1.68c-0.94-0.09-1.56-0.82-1.5-1.79
	C315.94,360.16,316.68,359.48,317.61,359.52z"
              />
              <path
                d="M426.85,414.97c-0.89-0.05-1.57-0.82-1.55-1.74c0.02-0.93,0.74-1.63,1.65-1.63c0.96,0,1.81,0.92,1.7,1.85
	C428.57,414.28,427.69,415.02,426.85,414.97z"
              />
              <path
                d="M371.59,564.39c0.92,0.03,1.62,0.74,1.65,1.67c0.03,1.04-0.76,1.8-1.79,1.75c-0.92-0.04-1.65-0.77-1.67-1.66
	C369.75,565.18,370.6,564.36,371.59,564.39z"
              />
              <path
                d="M269.84,206.72c-0.01,0.85-0.8,1.64-1.67,1.67c-0.93,0.03-1.66-0.64-1.7-1.58c-0.05-1.03,0.73-1.84,1.74-1.79
	C269.07,205.06,269.85,205.87,269.84,206.72z"
              />
              <path
                d="M418.38,563.96c0.86,0.03,1.65,0.87,1.62,1.72c-0.03,0.86-0.86,1.62-1.73,1.6c-0.93-0.03-1.61-0.76-1.58-1.71
	C416.72,564.64,417.47,563.92,418.38,563.96z"
              />
              <path
                d="M371.1,584.57c-0.14,0.96-1.09,1.64-2.07,1.46c-0.89-0.16-1.46-0.97-1.34-1.91c0.13-1.02,1.02-1.66,2.03-1.45
	C370.59,582.84,371.22,583.7,371.1,584.57z"
              />
              <path
                d="M471.05,528.69c0,0.94-0.73,1.67-1.65,1.65c-0.87-0.01-1.69-0.82-1.69-1.67c0.01-0.85,0.84-1.66,1.7-1.66
	C470.33,527,471.05,527.75,471.05,528.69z"
              />
              <path
                d="M339.55,471.65c-0.04,0.92-0.79,1.62-1.7,1.57c-0.87-0.04-1.64-0.88-1.59-1.72c0.05-0.85,0.89-1.62,1.74-1.58
	C338.91,469.96,339.59,470.72,339.55,471.65z"
              />
              <path
                d="M503.54,237.03c-0.05,0.89-0.85,1.61-1.77,1.6c-1.05-0.01-1.76-0.85-1.64-1.91c0.11-0.96,0.87-1.57,1.83-1.48
	C502.87,235.33,503.59,236.15,503.54,237.03z"
              />
              <path
                d="M418.06,584.09c-0.14,0.87-1.01,1.53-1.89,1.42c-0.91-0.12-1.51-0.93-1.39-1.87c0.13-0.95,0.92-1.56,1.84-1.42
	C417.51,582.35,418.19,583.24,418.06,584.09z"
              />
              <path
                d="M481.26,207.91c-0.96,0.05-1.68-0.58-1.75-1.54c-0.07-0.95,0.55-1.72,1.46-1.83c0.97-0.11,1.91,0.7,1.92,1.66
	C482.92,207.07,482.15,207.86,481.26,207.91z"
              />
              <path
                d="M431.28,528.59c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C430.54,526.97,431.24,527.65,431.28,528.59z"
              />
              <path
                d="M356.38,206.51c-0.02,0.91-0.76,1.66-1.63,1.66c-0.94,0.01-1.81-0.93-1.72-1.85c0.08-0.84,0.92-1.56,1.79-1.52
	C355.73,204.85,356.4,205.57,356.38,206.51z"
              />
              <path
                d="M359.8,597.8c-0.87,0.01-1.63-0.73-1.67-1.63c-0.04-0.94,0.61-1.67,1.53-1.74c0.97-0.07,1.86,0.76,1.83,1.71
	C361.46,596.99,360.65,597.79,359.8,597.8z"
              />
              <path
                d="M366.24,254.22c0.92,0,1.63,0.7,1.65,1.63c0.02,0.92-0.66,1.69-1.55,1.74c-0.95,0.06-1.85-0.81-1.81-1.75
	C364.57,254.98,365.37,254.22,366.24,254.22z"
              />
              <path
                d="M354.45,351.18c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.71c0.03-0.86,0.87-1.65,1.74-1.63
	C353.79,349.48,354.47,350.22,354.45,351.18z"
              />
              <path
                d="M508.34,393.62c0.91,0.03,1.6,0.79,1.57,1.73c-0.03,0.94-0.77,1.63-1.7,1.59c-0.88-0.04-1.65-0.87-1.61-1.72
	C506.64,394.36,507.48,393.59,508.34,393.62z"
              />
              <path
                d="M333.4,67.58c-0.01,0.94-0.69,1.64-1.62,1.68c-1,0.04-1.85-0.76-1.84-1.72c0.02-0.96,0.91-1.75,1.89-1.68
	C332.75,65.92,333.41,66.65,333.4,67.58z"
              />
              <path
                d="M353.76,550.02c-0.14,0.87-1.01,1.53-1.89,1.42c-0.91-0.12-1.51-0.93-1.39-1.87c0.13-0.95,0.92-1.56,1.84-1.42
	C353.21,548.28,353.89,549.18,353.76,550.02z"
              />
              <path
                d="M354.26,616.5c0.92,0.01,1.62,0.71,1.63,1.64c0.01,0.92-0.68,1.68-1.57,1.72c-0.95,0.05-1.84-0.84-1.79-1.77
	C352.58,617.24,353.4,616.49,354.26,616.5z"
              />
              <path
                d="M410.42,431c0.93,0.01,1.63,0.69,1.67,1.63c0.05,1.04-0.71,1.82-1.74,1.79c-0.9-0.02-1.66-0.74-1.7-1.62
	C408.6,431.84,409.44,430.99,410.42,431z"
              />
              <path
                d="M509.03,237.92c-0.87,0.01-1.63-0.73-1.67-1.63c-0.04-0.94,0.61-1.67,1.53-1.74c0.97-0.07,1.86,0.76,1.83,1.71
	C510.69,237.11,509.88,237.91,509.03,237.92z"
              />
              <path
                d="M466.1,511.16c0,0.85-0.78,1.67-1.64,1.71c-0.97,0.05-1.79-0.82-1.73-1.82c0.05-0.91,0.82-1.62,1.72-1.59
	C465.31,509.5,466.1,510.3,466.1,511.16z"
              />
              <path
                d="M331.87,361.72c-0.89-0.04-1.59-0.81-1.58-1.73c0.01-0.93,0.72-1.65,1.64-1.66c0.96-0.01,1.83,0.9,1.74,1.84
	C333.58,361,332.71,361.76,331.87,361.72z"
              />
              <path
                d="M373.69,613.23c-0.05,0.96-0.77,1.63-1.73,1.6c-0.91-0.03-1.69-0.8-1.69-1.68c-0.01-0.98,0.9-1.8,1.9-1.71
	C373.1,611.51,373.74,612.27,373.69,613.23z"
              />
              <path
                d="M314.54,490.06c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C312.8,490.73,313.66,490,314.54,490.06z"
              />
              <path
                d="M422.95,451.45c-0.84,0.04-1.71-0.72-1.8-1.55c-0.09-0.93,0.77-1.85,1.73-1.84c0.92,0.01,1.63,0.73,1.64,1.66
	C424.54,450.64,423.84,451.41,422.95,451.45z"
              />
              <path
                d="M520.81,351.09c0.03,0.88-0.71,1.67-1.62,1.74c-0.97,0.07-1.7-0.55-1.78-1.52c-0.1-1.05,0.66-1.88,1.68-1.87
	C519.99,349.45,520.78,350.21,520.81,351.09z"
              />
              <path
                d="M406.1,257.84c-1.01,0.01-1.83-0.82-1.78-1.79c0.05-0.89,0.8-1.61,1.71-1.64c1.02-0.03,1.79,0.77,1.75,1.8
	C407.74,257.16,407.05,257.83,406.1,257.84z"
              />
              <path
                d="M385.59,603.05c0.86-0.05,1.72,0.67,1.81,1.52c0.09,0.92-0.78,1.87-1.72,1.87c-0.87,0-1.63-0.76-1.66-1.67
	C384,603.84,384.67,603.11,385.59,603.05z"
              />
              <path
                d="M677.02,631.58c-0.93,0.04-1.67-0.65-1.7-1.6c-0.03-0.96,0.65-1.7,1.57-1.73c0.87-0.03,1.71,0.74,1.75,1.6
	C678.68,630.72,677.91,631.55,677.02,631.58z"
              />
              <path
                d="M241.05,375.35c0.01,0.86-0.76,1.68-1.62,1.73c-0.98,0.06-1.8-0.78-1.75-1.79c0.04-0.92,0.8-1.63,1.7-1.61
	C240.25,373.7,241.04,374.49,241.05,375.35z"
              />
              <path
                d="M436.48,173.98c0.06,0.95-0.6,1.7-1.53,1.75c-0.88,0.05-1.71-0.68-1.77-1.55c-0.06-0.85,0.7-1.7,1.57-1.76
	C435.66,172.35,436.43,173.05,436.48,173.98z"
              />
              <path
                d="M385.81,360.76c-0.87-0.06-1.63-0.88-1.61-1.74c0.02-0.96,0.95-1.75,1.92-1.63c0.91,0.11,1.54,0.9,1.46,1.83
	C387.5,360.12,386.69,360.82,385.81,360.76z"
              />
              <path
                d="M351.58,235.64c0.05,0.92-0.61,1.7-1.5,1.79c-0.94,0.09-1.87-0.75-1.86-1.69c0-0.85,0.78-1.64,1.65-1.67
	C350.78,234.03,351.53,234.72,351.58,235.64z"
              />
              <path
                d="M360.73,256.57c0.02,0.96-0.64,1.68-1.58,1.73c-0.92,0.05-1.73-0.63-1.82-1.52c-0.1-0.97,0.73-1.86,1.73-1.86
	C359.99,254.91,360.71,255.62,360.73,256.57z"
              />
              <path
                d="M259.48,337.93c-0.12,0.94-0.84,1.54-1.8,1.47c-1.01-0.07-1.75-0.96-1.61-1.93c0.14-0.96,1.11-1.65,2.07-1.47
	C259.02,336.17,259.59,337,259.48,337.93z"
              />
              <path
                d="M363.14,491.27c-0.01,0.96-0.71,1.66-1.66,1.67c-0.92,0.01-1.71-0.71-1.76-1.6c-0.06-0.98,0.81-1.84,1.81-1.8
	C362.46,489.58,363.15,490.32,363.14,491.27z"
              />
              <path
                d="M438.92,175.75c0.86-0.01,1.68,0.74,1.73,1.6c0.06,0.94-0.81,1.8-1.79,1.76c-0.92-0.04-1.59-0.76-1.58-1.7
	C437.31,176.48,438.01,175.76,438.92,175.75z"
              />
              <path
                d="M323.3,324.85c-0.05,0.95-0.78,1.62-1.73,1.59c-0.9-0.03-1.68-0.81-1.68-1.68c0-0.97,0.91-1.79,1.89-1.7
	C322.71,323.14,323.35,323.89,323.3,324.85z"
              />
              <path
                d="M384.04,482.36c-0.13,0.89-0.96,1.54-1.88,1.45c-1.03-0.09-1.68-0.99-1.48-2.03c0.18-0.92,1.01-1.49,1.94-1.32
	C383.51,480.62,384.16,481.49,384.04,482.36z"
              />
              <path
                d="M431.69,448.99c0.02,0.95-0.68,1.68-1.6,1.69c-0.87,0.01-1.7-0.77-1.71-1.63c-0.02-0.84,0.79-1.68,1.65-1.7
	C430.93,447.33,431.68,448.06,431.69,448.99z"
              />
              <path
                d="M400.59,589.2c-0.03,0.95-0.71,1.63-1.66,1.65c-1.01,0.02-1.84-0.79-1.8-1.77c0.04-0.98,0.92-1.74,1.92-1.65
	C399.97,587.51,400.61,588.26,400.59,589.2z"
              />
              <path
                d="M425.95,550.76c0,0.95-0.72,1.67-1.66,1.66c-0.88-0.01-1.7-0.82-1.69-1.68c0.01-0.85,0.84-1.67,1.71-1.67
	C425.23,549.07,425.95,549.81,425.95,550.76z"
              />
              <path
                d="M397.22,592.89c0.04,0.95-0.58,1.69-1.51,1.78c-1.01,0.1-1.89-0.63-1.94-1.62c-0.05-0.97,0.79-1.81,1.78-1.8
	C396.48,591.27,397.18,591.95,397.22,592.89z"
              />
              <path
                d="M401.01,263.74c0.04,0.95-0.6,1.67-1.53,1.73c-0.97,0.06-1.86-0.77-1.83-1.72c0.03-0.84,0.84-1.62,1.7-1.63
	C400.25,262.11,400.97,262.81,401.01,263.74z"
              />
              <path
                d="M274.75,323.52c0.91-0.04,1.73,0.65,1.82,1.54c0.1,0.98-0.72,1.86-1.74,1.86c-0.95,0-1.66-0.7-1.68-1.66
	C273.13,324.31,273.81,323.57,274.75,323.52z"
              />
              <path
                d="M451.44,152.96c0.86,0.01,1.68,0.79,1.7,1.64c0.03,0.95-0.88,1.82-1.83,1.76c-0.89-0.06-1.58-0.84-1.55-1.76
	C449.8,153.66,450.53,152.95,451.44,152.96z"
              />
              <path
                d="M343.41,411.38c-0.94-0.02-1.62-0.69-1.65-1.64c-0.03-1.03,0.74-1.81,1.76-1.78c0.89,0.03,1.65,0.77,1.69,1.65
	C345.24,410.58,344.41,411.4,343.41,411.38z"
              />
              <path
                d="M347.29,351.7c-0.04,0.95-0.76,1.63-1.71,1.61c-0.9-0.02-1.69-0.78-1.7-1.66c-0.02-0.97,0.88-1.8,1.87-1.73
	C346.69,350,347.33,350.73,347.29,351.7z"
              />
              <path
                d="M413.03,233.96c0.05,0.97-0.58,1.7-1.53,1.78c-1,0.08-1.9-0.74-1.88-1.71c0.02-0.87,0.81-1.65,1.7-1.67
	C412.25,232.33,412.98,233.02,413.03,233.96z"
              />
              <path
                d="M404.23,465.83c0,0.98-0.87,1.76-1.88,1.7c-0.93-0.06-1.6-0.77-1.6-1.71c0.01-0.93,0.69-1.65,1.61-1.7
	C403.37,464.05,404.24,464.86,404.23,465.83z"
              />
              <path
                d="M438.7,448.62c-0.05,0.86-0.87,1.61-1.75,1.57c-0.93-0.04-1.59-0.78-1.54-1.73c0.04-0.92,0.81-1.63,1.7-1.58
	C437.98,446.94,438.75,447.78,438.7,448.62z"
              />
              <path
                d="M439.68,376.06c0.02,0.84-0.79,1.67-1.66,1.69c-0.92,0.02-1.65-0.7-1.66-1.63c-0.01-0.94,0.69-1.68,1.61-1.69
	C438.83,374.42,439.66,375.22,439.68,376.06z"
              />
              <path
                d="M272.85,265.76c-0.89-0.04-1.59-0.81-1.58-1.73c0.01-0.93,0.72-1.65,1.64-1.66c0.96-0.01,1.83,0.9,1.74,1.84
	C274.56,265.04,273.69,265.79,272.85,265.76z"
              />
              <path
                d="M320.95,355.69c0.85,0.02,1.66,0.82,1.67,1.66c0.02,0.94-0.89,1.77-1.86,1.69c-0.93-0.08-1.55-0.81-1.5-1.76
	C319.31,356.35,320.04,355.66,320.95,355.69z"
              />
              <path
                d="M291.09,204.81c-0.01,0.93-0.74,1.65-1.65,1.63c-0.86-0.02-1.67-0.84-1.65-1.68c0.02-0.84,0.85-1.64,1.7-1.63
	C290.4,203.14,291.1,203.88,291.09,204.81z"
              />
              <path
                d="M449.9,510.71c0.85-0.04,1.7,0.71,1.75,1.57c0.05,0.85-0.7,1.68-1.58,1.73c-0.91,0.05-1.66-0.63-1.71-1.56
	C448.32,511.53,449,510.75,449.9,510.71z"
              />
              <path
                d="M250.21,396.24c0,0.97-0.86,1.76-1.87,1.7c-0.93-0.05-1.61-0.77-1.6-1.71c0-0.93,0.69-1.66,1.61-1.71
	C249.33,394.47,250.2,395.28,250.21,396.24z"
              />
              <path
                d="M419.44,122.99c-0.91-0.04-1.68-0.82-1.67-1.7c0.01-0.97,0.93-1.79,1.92-1.69c0.93,0.09,1.56,0.86,1.5,1.82
	C421.13,122.39,420.4,123.03,419.44,122.99z"
              />
              <path
                d="M330.45,490.21c0.05,0.96-0.61,1.7-1.56,1.74c-0.9,0.04-1.72-0.7-1.76-1.58c-0.05-0.86,0.72-1.7,1.6-1.75
	C329.65,488.57,330.4,489.27,330.45,490.21z"
              />
              <path
                d="M308.92,326.14c0.02,0.96-0.65,1.69-1.59,1.74c-0.93,0.05-1.73-0.63-1.82-1.53c-0.1-0.98,0.73-1.87,1.74-1.87
	C308.18,324.48,308.89,325.19,308.92,326.14z"
              />
              <path
                d="M442.83,514.79c-0.86,0.02-1.64-0.72-1.69-1.61c-0.05-0.94,0.59-1.68,1.51-1.75c0.97-0.08,1.86,0.74,1.84,1.69
	C444.48,513.96,443.67,514.77,442.83,514.79z"
              />
              <path
                d="M335.96,488.1c0.93-0.03,1.66,0.61,1.74,1.54c0.1,1.04-0.61,1.85-1.64,1.87c-0.91,0.02-1.69-0.66-1.78-1.53
	C334.18,489.01,334.97,488.13,335.96,488.1z"
              />
              <path
                d="M397.55,410.18c-0.84,0.06-1.72-0.68-1.82-1.52c-0.11-0.93,0.74-1.87,1.7-1.87c0.91-0.01,1.64,0.71,1.67,1.63
	C399.12,409.34,398.44,410.12,397.55,410.18z"
              />
              <path
                d="M408.22,263.35c-0.01,0.94-0.7,1.64-1.64,1.65c-0.88,0.01-1.69-0.72-1.74-1.59c-0.06-0.95,0.82-1.82,1.79-1.78
	C407.55,261.68,408.23,262.41,408.22,263.35z"
              />
              <path
                d="M329.84,356.97c-0.04,0.89-0.82,1.63-1.74,1.63c-1.05,0-1.78-0.82-1.67-1.89c0.1-0.97,0.85-1.59,1.81-1.51
	C329.14,355.27,329.88,356.08,329.84,356.97z"
              />
              <path
                d="M367.71,628.01c0,0.85-0.82,1.65-1.7,1.66c-0.93,0.01-1.65-0.72-1.64-1.67c0-0.94,0.74-1.68,1.65-1.67
	C366.89,626.34,367.71,627.16,367.71,628.01z"
              />
              <path
                d="M356.68,625.45c-0.05,0.84-0.87,1.62-1.72,1.62c-0.96,0-1.74-0.91-1.64-1.89c0.09-0.9,0.88-1.55,1.8-1.47
	C356,623.78,356.73,624.59,356.68,625.45z"
              />
              <path
                d="M292.33,611.01c0.93-0.05,1.68,0.66,1.7,1.61c0.02,0.96-0.65,1.68-1.6,1.71c-0.92,0.03-1.72-0.7-1.74-1.59
	C290.67,611.86,291.43,611.06,292.33,611.01z"
              />
              <path
                d="M386.11,258.14c-0.05,0.95-0.79,1.62-1.73,1.57c-0.89-0.05-1.65-0.88-1.59-1.75c0.06-0.87,0.9-1.62,1.77-1.58
	C385.49,256.43,386.16,257.19,386.11,258.14z"
              />
              <path
                d="M417.11,197.54c0.03,0.84-0.76,1.67-1.63,1.7c-0.91,0.03-1.65-0.68-1.67-1.6c-0.02-0.93,0.67-1.68,1.58-1.71
	C416.25,195.91,417.09,196.7,417.11,197.54z"
              />
              <path
                d="M413.49,114.88c-0.03,0.91-0.79,1.66-1.67,1.66c-0.94-0.01-1.81-0.96-1.71-1.89c0.09-0.85,0.96-1.56,1.82-1.51
	C412.86,113.2,413.52,113.94,413.49,114.88z"
              />
              <path
                d="M355.78,464.62c0.93,0.05,1.6,0.81,1.55,1.77c-0.05,0.96-0.79,1.63-1.74,1.57c-0.9-0.05-1.65-0.89-1.6-1.76
	C354.05,465.33,354.9,464.58,355.78,464.62z"
              />
              <path
                d="M474.49,524.81c0.02,0.94-0.64,1.67-1.57,1.73c-1.02,0.08-1.88-0.69-1.9-1.67c-0.02-0.97,0.84-1.79,1.83-1.75
	C473.78,523.16,474.47,523.88,474.49,524.81z"
              />
              <path
                d="M283.44,198.36c-0.02,0.9-0.77,1.65-1.64,1.66c-0.94,0-1.81-0.94-1.72-1.85c0.08-0.84,0.93-1.56,1.8-1.51
	C282.81,196.7,283.47,197.42,283.44,198.36z"
              />
              <path
                d="M469.95,514.34c-0.03,0.89-0.81,1.63-1.73,1.64c-1.05,0.01-1.79-0.81-1.68-1.88c0.09-0.96,0.84-1.59,1.8-1.52
	C469.25,512.65,469.98,513.45,469.95,514.34z"
              />
              <path
                d="M419.77,352.49c0.05,0.95-0.58,1.68-1.5,1.78c-1,0.1-1.89-0.63-1.94-1.61c-0.05-0.96,0.79-1.8,1.77-1.8
	C419.02,350.87,419.72,351.55,419.77,352.49z"
              />
              <path
                d="M439.57,143.12c0.94-0.05,1.67,0.6,1.75,1.54c0.08,0.96-0.53,1.72-1.46,1.82c-1,0.11-1.91-0.67-1.93-1.65
	C437.92,143.97,438.68,143.17,439.57,143.12z"
              />
              <path
                d="M430.27,434.68c-0.02,0.95-0.76,1.65-1.7,1.62c-0.89-0.03-1.68-0.85-1.65-1.72c0.03-0.86,0.87-1.65,1.75-1.63
	C429.61,432.97,430.29,433.71,430.27,434.68z"
              />
              <path
                d="M420.74,114.07c0.02,0.94-0.64,1.67-1.57,1.73c-1.02,0.08-1.88-0.69-1.9-1.67c-0.02-0.97,0.84-1.79,1.83-1.75
	C420.03,112.42,420.73,113.14,420.74,114.07z"
              />
              <path
                d="M235.76,231.41c0,0.85-0.78,1.66-1.63,1.7c-0.98,0.05-1.78-0.79-1.72-1.8c0.05-0.92,0.8-1.6,1.71-1.57
	C234.99,229.77,235.76,230.56,235.76,231.41z"
              />
              <path
                d="M289.04,262.94c-0.05,0.87-0.85,1.6-1.75,1.59c-1.03-0.01-1.74-0.85-1.62-1.9c0.11-0.95,0.87-1.56,1.81-1.47
	C288.37,261.25,289.09,262.08,289.04,262.94z"
              />
              <path
                d="M259.39,400.57c-0.89-0.05-1.57-0.82-1.55-1.74c0.02-0.93,0.74-1.63,1.65-1.63c0.96,0,1.81,0.92,1.7,1.85
	C261.1,399.89,260.23,400.62,259.39,400.57z"
              />
              <path
                d="M359.08,487.96c-0.04,0.85-0.84,1.6-1.71,1.61c-1.02,0-1.74-0.82-1.63-1.86c0.1-0.93,0.86-1.57,1.77-1.49
	C358.38,486.29,359.12,487.11,359.08,487.96z"
              />
              <path
                d="M419.55,558.52c-0.05,0.87-0.85,1.6-1.75,1.59c-1.03-0.01-1.74-0.85-1.62-1.9c0.11-0.95,0.87-1.56,1.81-1.47
	C418.88,556.83,419.61,557.66,419.55,558.52z"
              />
              <path
                d="M266,329.76c0.01,0.86-0.75,1.68-1.62,1.74c-0.98,0.06-1.81-0.78-1.76-1.79c0.04-0.92,0.79-1.63,1.7-1.61
	C265.2,328.11,265.99,328.9,266,329.76z"
              />
              <path
                d="M543.82,616.35c-0.06,0.84-0.9,1.61-1.74,1.6c-0.96-0.02-1.73-0.92-1.62-1.91c0.1-0.91,0.89-1.53,1.81-1.45
	C543.16,614.68,543.88,615.5,543.82,616.35z"
              />
              <path
                d="M406.29,447.81c-0.12,0.96-0.87,1.55-1.84,1.45c-0.9-0.09-1.61-0.91-1.55-1.79c0.07-0.97,1.05-1.73,2.01-1.57
	C405.82,446.05,406.41,446.87,406.29,447.81z"
              />
              <path
                d="M364.92,445.91c0.93,0.06,1.58,0.84,1.52,1.79c-0.06,0.96-0.81,1.61-1.76,1.54c-0.9-0.07-1.64-0.91-1.57-1.78
	C363.19,446.59,364.04,445.85,364.92,445.91z"
              />
              <path
                d="M353.93,200.96c-0.89-0.04-1.58-0.81-1.57-1.72c0.01-0.93,0.71-1.63,1.64-1.64c0.96-0.01,1.82,0.9,1.72,1.83
	C355.64,200.26,354.77,201,353.93,200.96z"
              />
              <path
                d="M357.63,553.12c0.02,0.85-0.79,1.67-1.66,1.69c-0.92,0.02-1.66-0.7-1.67-1.64c-0.01-0.95,0.7-1.69,1.62-1.7
	C356.78,551.47,357.62,552.27,357.63,553.12z"
              />
              <path
                d="M576.94,624.5c-0.05,0.84-0.89,1.63-1.73,1.62c-0.96-0.01-1.75-0.93-1.64-1.91c0.1-0.91,0.89-1.56,1.81-1.48
	C576.26,622.81,576.99,623.64,576.94,624.5z"
              />
              <path
                d="M305.86,330.11c-0.05,0.98-0.94,1.72-1.95,1.61c-0.93-0.1-1.55-0.84-1.51-1.79c0.05-0.94,0.75-1.62,1.68-1.63
	C305.08,328.3,305.91,329.15,305.86,330.11z"
              />
              <path
                d="M307.42,517.59c0.03,0.96-0.63,1.67-1.56,1.69c-0.86,0.02-1.69-0.75-1.72-1.6c-0.03-0.84,0.76-1.67,1.61-1.7
	C306.67,515.95,307.39,516.65,307.42,517.59z"
              />
              <path
                d="M424.34,446c-0.05-0.94,0.59-1.68,1.52-1.75c0.97-0.08,1.86,0.74,1.84,1.7c-0.02,0.84-0.83,1.65-1.67,1.67
	C425.17,447.64,424.39,446.89,424.34,446z"
              />
              <path
                d="M479.02,202.82c0.03,0.85-0.76,1.68-1.63,1.71c-0.92,0.03-1.66-0.68-1.68-1.61c-0.02-0.95,0.67-1.69,1.59-1.71
	C478.15,201.18,478.99,201.97,479.02,202.82z"
              />
              <path
                d="M462.92,435.55c0.03,0.94-0.64,1.67-1.57,1.72c-0.9,0.05-1.71-0.64-1.8-1.52c-0.1-0.94,0.74-1.85,1.71-1.85
	C462.18,433.9,462.89,434.61,462.92,435.55z"
              />
              <path
                d="M453.17,447.19c-0.05,0.98-0.94,1.72-1.95,1.61c-0.93-0.1-1.55-0.84-1.51-1.79c0.05-0.94,0.75-1.62,1.68-1.63
	C452.39,445.38,453.22,446.23,453.17,447.19z"
              />
              <path
                d="M302.47,333.98c-0.06,0.87-0.87,1.59-1.76,1.57c-1.03-0.02-1.74-0.86-1.6-1.91c0.12-0.95,0.88-1.55,1.83-1.45
	C301.82,332.28,302.53,333.11,302.47,333.98z"
              />
              <path
                d="M411.93,445.4c0.93,0.05,1.61,0.79,1.59,1.75c-0.02,0.96-0.73,1.65-1.68,1.65c-1.02,0-1.84-0.88-1.73-1.86
	C410.2,446.04,411.02,445.36,411.93,445.4z"
              />
              <path
                d="M298.6,330.61c-0.06,0.87-0.9,1.6-1.78,1.55c-0.94-0.05-1.59-0.8-1.53-1.76c0.06-0.93,0.83-1.63,1.74-1.56
	C297.91,328.9,298.66,329.75,298.6,330.61z"
              />
              <path
                d="M369.57,380.2c-1.01-0.02-1.8-0.88-1.7-1.86c0.08-0.88,0.87-1.58,1.77-1.57c1.02,0.01,1.76,0.83,1.67,1.87
	C371.23,379.58,370.52,380.23,369.57,380.2z"
              />
              <path
                d="M343.88,355.46c0,0.94-0.71,1.66-1.64,1.66c-0.86-0.01-1.69-0.82-1.68-1.66c0-0.84,0.83-1.66,1.68-1.67
	C343.15,353.78,343.88,354.53,343.88,355.46z"
              />
              <path
                d="M300.73,605.02c-0.02,0.95-0.75,1.65-1.69,1.63c-0.89-0.02-1.68-0.84-1.64-1.71c0.03-0.86,0.87-1.65,1.74-1.64
	C300.06,603.32,300.75,604.06,300.73,605.02z"
              />
              <path
                d="M414.42,437.76c-0.95-0.02-1.64-0.74-1.64-1.7c0-0.95,0.71-1.68,1.64-1.7c1-0.02,1.85,0.85,1.78,1.83
	C416.14,437.08,415.33,437.79,414.42,437.76z"
              />
              <path
                d="M415.37,594.85c0.03,0.94-0.65,1.67-1.58,1.7c-0.86,0.02-1.69-0.75-1.72-1.6c-0.03-0.84,0.77-1.68,1.62-1.71
	C414.59,593.2,415.34,593.93,415.37,594.85z"
              />
              <path
                d="M284.19,332.05c-0.06,0.86-0.9,1.59-1.77,1.54c-0.92-0.05-1.57-0.81-1.52-1.75c0.06-0.92,0.83-1.61,1.72-1.55
	C283.49,330.35,284.25,331.2,284.19,332.05z"
              />
              <path
                d="M380.7,486.08c-0.06,0.89-0.86,1.6-1.78,1.58c-1.05-0.02-1.76-0.87-1.62-1.93c0.12-0.96,0.88-1.56,1.85-1.46
	C380.05,484.38,380.76,485.2,380.7,486.08z"
              />
              <path
                d="M475.15,551.97c0.89-0.01,1.63,0.73,1.64,1.65c0.01,0.93-0.7,1.66-1.62,1.66c-0.86,0-1.68-0.8-1.69-1.64
	C473.48,552.8,474.3,551.98,475.15,551.97z"
              />
              <path
                d="M364.35,631.95c-0.06,0.86-0.87,1.58-1.75,1.56c-1.02-0.02-1.72-0.86-1.59-1.9c0.12-0.94,0.89-1.55,1.81-1.44
	C363.7,630.27,364.42,631.11,364.35,631.95z"
              />
              <path
                d="M252.26,397.88c0.99-0.01,1.82,0.84,1.77,1.8c-0.05,0.89-0.8,1.6-1.71,1.63c-1.04,0.03-1.8-0.76-1.75-1.8
	C250.62,398.58,251.33,397.89,252.26,397.88z"
              />
              <path
                d="M470.4,521.59c-0.06,0.86-0.87,1.58-1.75,1.56c-1.02-0.02-1.72-0.86-1.59-1.9c0.12-0.94,0.88-1.55,1.81-1.44
	C469.74,519.9,470.46,520.74,470.4,521.59z"
              />
              <path
                d="M294.45,201c-0.02,0.92-0.76,1.63-1.67,1.6c-0.86-0.03-1.65-0.86-1.63-1.7c0.03-0.85,0.87-1.63,1.72-1.61
	C293.78,199.31,294.47,200.07,294.45,201z"
              />
              <path
                d="M423.51,581.72c0.85-0.04,1.7,0.71,1.75,1.57c0.05,0.85-0.7,1.68-1.58,1.73c-0.91,0.05-1.66-0.63-1.71-1.56
	C421.93,582.54,422.61,581.77,423.51,581.72z"
              />
              <path
                d="M334.18,469.91c-0.93,0.04-1.73-0.65-1.81-1.55c-0.09-0.98,0.75-1.86,1.75-1.85c0.94,0.01,1.65,0.73,1.67,1.68
	C335.81,469.14,335.13,469.87,334.18,469.91z"
              />
              <path
                d="M422.91,554.55c0.03,0.85-0.73,1.65-1.61,1.71c-0.94,0.06-1.67-0.57-1.75-1.51c-0.09-1.03,0.66-1.86,1.66-1.85
	C422.07,552.92,422.88,553.7,422.91,554.55z"
              />
              <path
                d="M437.95,520.66c0.05,0.92-0.61,1.7-1.5,1.79c-0.94,0.09-1.87-0.75-1.86-1.69c0-0.85,0.78-1.64,1.65-1.67
	C437.15,519.05,437.9,519.74,437.95,520.66z"
              />
              <path
                d="M361.48,556.38c-0.06,0.84-0.91,1.6-1.75,1.58c-0.96-0.02-1.72-0.93-1.6-1.92c0.11-0.91,0.9-1.53,1.82-1.43
	C360.83,554.71,361.55,555.53,361.48,556.38z"
              />
              <path
                d="M362.1,626.35c-0.94-0.03-1.61-0.72-1.63-1.66c-0.02-1.03,0.76-1.8,1.78-1.75c0.89,0.04,1.64,0.79,1.67,1.67
	C363.95,625.57,363.11,626.38,362.1,626.35z"
              />
              <path
                d="M502.9,581.93c0.91-0.05,1.69,0.63,1.76,1.54c0.07,0.91-0.57,1.73-1.46,1.84c-0.94,0.12-1.89-0.71-1.92-1.65
	C501.26,582.8,502.02,581.99,502.9,581.93z"
              />
              <path
                d="M414.72,587.79c-0.06,0.87-0.89,1.6-1.77,1.55c-0.93-0.05-1.59-0.8-1.53-1.75c0.05-0.93,0.82-1.63,1.73-1.56
	C414.01,586.1,414.77,586.94,414.72,587.79z"
              />
              <path
                d="M287.25,201.33c0.06,0.95-0.6,1.7-1.53,1.75c-0.88,0.05-1.71-0.68-1.77-1.55c-0.06-0.85,0.7-1.7,1.57-1.76
	C286.43,199.7,287.2,200.4,287.25,201.33z"
              />
              <path
                d="M515.95,380.64c-0.14,0.88-1.01,1.54-1.89,1.42c-0.92-0.12-1.52-0.93-1.39-1.88c0.13-0.95,0.93-1.56,1.85-1.42
	C515.4,378.89,516.09,379.79,515.95,380.64z"
              />
              <path
                d="M237.41,228.99c-0.91,0-1.63-0.73-1.62-1.66c0.01-0.92,0.75-1.66,1.64-1.65c0.85,0.01,1.67,0.84,1.66,1.67
	C239.09,228.19,238.27,228.99,237.41,228.99z"
              />
              <path
                d="M370.32,616.99c-0.01,0.95-0.7,1.65-1.64,1.66c-0.9,0.01-1.7-0.72-1.75-1.59c-0.06-0.96,0.81-1.82,1.8-1.78
	C369.65,615.32,370.33,616.04,370.32,616.99z"
              />
              <path
                d="M407.31,438.25c-0.96,0.02-1.68-0.65-1.72-1.61c-0.05-0.96,0.6-1.71,1.54-1.79c1-0.08,1.9,0.75,1.89,1.73
	C409,437.47,408.23,438.23,407.31,438.25z"
              />
              <path
                d="M395.87,349c-0.96,0.06-1.68-0.54-1.79-1.48c-0.12-1.03,0.57-1.87,1.59-1.92c0.98-0.05,1.85,0.77,1.84,1.73
	C397.5,348.19,396.77,348.94,395.87,349z"
              />
              <path
                d="M434.89,378.26c0.93,0.01,1.62,0.75,1.61,1.7c-0.01,0.94-0.75,1.66-1.67,1.63c-0.87-0.02-1.68-0.85-1.66-1.7
	C433.19,379.04,434.02,378.25,434.89,378.26z"
              />
              <path
                d="M311.53,520.92c0.05,0.86-0.76,1.74-1.63,1.72c-0.92-0.02-1.48-0.52-1.61-1.44c-0.13-0.93,0.55-1.76,1.45-1.84
	C310.61,519.28,311.49,520.05,311.53,520.92z"
              />
              <path
                d="M416.15,602.07c0.01,0.86-0.83,1.71-1.7,1.66c-0.92-0.05-1.47-0.56-1.57-1.49c-0.1-0.93,0.61-1.75,1.52-1.79
	C415.27,600.4,416.14,601.21,416.15,602.07z"
              />
              <path
                d="M419.2,598.19c0.04,0.95-0.67,1.72-1.61,1.72c-0.87,0-1.75-0.85-1.66-1.69c0.1-0.88,0.6-1.48,1.52-1.59
	C418.35,596.5,419.16,597.26,419.2,598.19z"
              />
              <path
                d="M522.64,333.14c-0.15,0.88-1.08,1.55-1.93,1.39c-0.89-0.16-1.51-1.08-1.28-1.98c0.23-0.89,0.83-1.36,1.75-1.28
	C522.07,331.35,522.78,332.29,522.64,333.14z"
              />
              <path
                d="M326.98,470.61c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.73,1.56
	C328.57,469.8,327.89,470.57,326.98,470.61z"
              />
              <path
                d="M379.28,614.09c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C380.88,613.27,380.19,614.05,379.28,614.09z"
              />
              <path
                d="M428,133.33c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C426.32,133.95,427.06,133.28,428,133.33z"
              />
              <path
                d="M454.18,437.69c-0.87,0.07-1.73-0.7-1.76-1.56c-0.04-0.84,0.79-1.74,1.63-1.7c0.9,0.04,1.47,0.54,1.59,1.45
	C455.76,436.81,455.08,437.62,454.18,437.69z"
              />
              <path
                d="M340.82,485.95c0.01,0.86-0.83,1.71-1.7,1.66c-0.92-0.05-1.47-0.56-1.57-1.49c-0.1-0.93,0.61-1.75,1.52-1.79
	C339.93,484.28,340.8,485.09,340.82,485.95z"
              />
              <path
                d="M389.95,261.21c-0.02,0.92-0.78,1.66-1.68,1.64c-0.86-0.03-1.67-0.86-1.65-1.71c0.02-0.88,0.81-1.62,1.72-1.61
	C389.29,259.54,389.97,260.26,389.95,261.21z"
              />
              <path
                d="M506.82,399.2c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C506.06,397.45,506.87,398.36,506.82,399.2z"
              />
              <path
                d="M250.6,403.44c0,0.85-0.85,1.69-1.72,1.63c-0.92-0.06-1.46-0.59-1.54-1.51c-0.09-0.91,0.65-1.73,1.54-1.77
	C249.74,401.76,250.59,402.58,250.6,403.44z"
              />
              <path
                d="M474.89,199.83c-0.04,0.92-0.82,1.66-1.72,1.61c-0.87-0.05-1.66-0.89-1.62-1.75c0.04-0.88,0.86-1.62,1.76-1.58
	C474.26,198.13,474.93,198.87,474.89,199.83z"
              />
              <path
                d="M381.8,334.15c-0.02,0.92-0.8,1.68-1.69,1.65c-0.87-0.03-1.67-0.86-1.66-1.72c0.02-0.88,0.82-1.63,1.73-1.62
	C381.13,332.46,381.83,333.19,381.8,334.15z"
              />
              <path
                d="M355.2,484.45c0.05,0.86-0.76,1.74-1.64,1.72c-0.92-0.02-1.49-0.52-1.62-1.44c-0.13-0.94,0.54-1.77,1.46-1.84
	C354.27,482.81,355.15,483.58,355.2,484.45z"
              />
              <path
                d="M422.79,574.53c0.91-0.05,1.71,0.71,1.69,1.64c-0.02,0.92-0.51,1.5-1.42,1.64c-0.88,0.13-1.76-0.61-1.84-1.5
	C421.13,575.46,421.91,574.57,422.79,574.53z"
              />
              <path
                d="M362.38,484.16c-0.07,0.88-0.94,1.61-1.81,1.52c-0.91-0.09-1.57-0.93-1.42-1.86c0.15-0.91,0.73-1.4,1.64-1.41
	C361.65,482.4,362.45,483.31,362.38,484.16z"
              />
              <path
                d="M408.19,595.72c-0.06,0.92-0.84,1.62-1.74,1.56c-0.87-0.06-1.64-0.91-1.58-1.76c0.06-0.88,0.88-1.59,1.78-1.54
	C407.59,594.03,408.24,594.77,408.19,595.72z"
              />
              <path
                d="M422.55,594.38c0.02,0.93-0.72,1.7-1.64,1.68c-0.87-0.02-1.72-0.87-1.61-1.72c0.11-0.88,0.63-1.46,1.54-1.56
	C421.73,592.68,422.53,593.46,422.55,594.38z"
              />
              <path
                d="M387.19,248.66c-0.92,0.04-1.7-0.71-1.67-1.65c0.03-0.93,0.54-1.49,1.44-1.63c0.86-0.14,1.76,0.64,1.83,1.52
	C388.86,247.76,388.08,248.62,387.19,248.66z"
              />
              <path
                d="M425.49,397.24c0.92-0.01,1.7,0.73,1.72,1.61c0.02,0.85-0.79,1.68-1.65,1.7c-0.89,0.02-1.65-0.72-1.67-1.64
	C423.86,397.97,424.55,397.25,425.49,397.24z"
              />
              <path
                d="M447.41,152.97c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C449.02,152.15,448.33,152.93,447.41,152.97z"
              />
              <path
                d="M507.66,595.84c-0.9,0.05-1.68-0.66-1.73-1.58c-0.05-0.95,0.61-1.68,1.56-1.73c0.9-0.04,1.72,0.68,1.77,1.55
	C509.3,594.94,508.53,595.79,507.66,595.84z"
              />
              <path
                d="M441.13,517.01c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C440.41,515.31,441.18,516.14,441.13,517.01z"
              />
              <path
                d="M448.26,516.32c-0.07,0.88-0.94,1.6-1.81,1.5c-0.9-0.1-1.56-0.95-1.4-1.85c0.16-0.91,0.74-1.38,1.64-1.4
	C447.54,514.56,448.33,515.48,448.26,516.32z"
              />
              <path
                d="M334.75,540.69c-0.01,0.94-0.78,1.69-1.7,1.62c-0.93-0.08-1.44-0.66-1.57-1.54c-0.12-0.85,0.73-1.73,1.6-1.76
	C333.99,538.99,334.76,539.76,334.75,540.69z"
              />
              <path
                d="M365.78,314.04c-0.05,0.85-0.9,1.62-1.76,1.58c-0.91-0.04-1.6-0.8-1.56-1.74c0.04-0.94,0.78-1.61,1.72-1.57
	C365.07,312.35,365.83,313.18,365.78,314.04z"
              />
              <path
                d="M297.46,379.17c-0.91,0.04-1.7-0.72-1.67-1.64c0.03-0.92,0.54-1.48,1.44-1.63c0.85-0.14,1.75,0.64,1.82,1.51
	C299.11,378.27,298.34,379.13,297.46,379.17z"
              />
              <path
                d="M339.63,404.68c0.85,0.01,1.67,0.87,1.63,1.71c-0.04,0.86-0.9,1.65-1.76,1.55c-0.9-0.11-1.42-0.65-1.47-1.56
	C337.98,405.47,338.76,404.67,339.63,404.68z"
              />
              <path
                d="M429.65,423.56c0.02-0.93,0.81-1.71,1.71-1.62c0.92,0.09,1.44,0.68,1.56,1.56c0.11,0.85-0.73,1.71-1.61,1.74
	C430.38,425.27,429.64,424.51,429.65,423.56z"
              />
              <path
                d="M515.89,606.2c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C514.24,607.12,515.02,606.24,515.89,606.2z"
              />
              <path
                d="M345.02,469.2c0.95-0.06,1.7,0.61,1.75,1.55c0.05,0.94-0.64,1.73-1.55,1.78c-0.86,0.05-1.73-0.72-1.79-1.57
	C343.37,470.1,344.13,469.26,345.02,469.2z"
              />
              <path
                d="M369.32,625.81c-0.93-0.03-1.64-0.81-1.55-1.76c0.09-0.93,0.63-1.45,1.55-1.53c0.86-0.07,1.73,0.78,1.73,1.64
	C371.05,625.02,370.19,625.84,369.32,625.81z"
              />
              <path
                d="M520.77,247.69c-0.87,0.01-1.7-0.82-1.68-1.67c0.02-0.86,0.89-1.69,1.75-1.6c0.91,0.1,1.44,0.63,1.51,1.55
	C522.43,246.91,521.7,247.68,520.77,247.69z"
              />
              <path
                d="M357.32,632.56c0,0.94-0.71,1.66-1.63,1.66c-0.89,0-1.69-0.77-1.69-1.64c0-0.89,0.76-1.64,1.67-1.65
	C356.61,630.91,357.32,631.61,357.32,632.56z"
              />
              <path
                d="M385.63,377.23c0.03,0.94-0.66,1.7-1.57,1.73c-0.86,0.03-1.71-0.74-1.75-1.6c-0.04-0.86,0.73-1.68,1.62-1.72
	C384.86,375.61,385.6,376.28,385.63,377.23z"
              />
              <path
                d="M433.24,446.82c-0.9,0.03-1.69-0.73-1.65-1.64c0.04-0.91,0.55-1.47,1.44-1.61c0.84-0.14,1.73,0.65,1.8,1.52
	C434.89,445.94,434.11,446.79,433.24,446.82z"
              />
              <path
                d="M513.72,604.5c0,0.91-0.77,1.69-1.67,1.62c-0.92-0.07-1.43-0.64-1.56-1.51c-0.12-0.83,0.71-1.72,1.57-1.75
	C512.93,602.81,513.72,603.6,513.72,604.5z"
              />
              <path
                d="M421.73,563.43c-0.92,0.03-1.7-0.72-1.66-1.66c0.03-0.92,0.55-1.49,1.45-1.62c0.86-0.13,1.75,0.65,1.82,1.53
	C423.4,562.54,422.61,563.4,421.73,563.43z"
              />
              <path
                d="M426.73,351.76c0.05,0.86-0.72,1.71-1.59,1.77c-0.9,0.06-1.69-0.65-1.75-1.58c-0.06-0.95,0.61-1.7,1.55-1.75
	C425.84,350.16,426.68,350.89,426.73,351.76z"
              />
              <path
                d="M395.2,611.33c-0.07,0.94-0.87,1.63-1.78,1.5c-0.92-0.13-1.4-0.75-1.47-1.62c-0.07-0.85,0.85-1.67,1.71-1.64
	C394.54,609.6,395.26,610.42,395.2,611.33z"
              />
              <path
                d="M317.47,466.26c0.02,0.94-0.72,1.7-1.64,1.69c-0.88-0.02-1.73-0.87-1.62-1.73c0.11-0.88,0.63-1.47,1.55-1.56
	C316.66,464.56,317.45,465.33,317.47,466.26z"
              />
              <path
                d="M421.91,587.24c-0.01,0.86-0.88,1.66-1.75,1.62c-0.94-0.05-1.62-0.83-1.52-1.78c0.1-0.93,0.66-1.44,1.57-1.5
	C421.07,585.52,421.92,586.39,421.91,587.24z"
              />
              <path
                d="M416.31,123.5c0.86-0.05,1.72,0.74,1.74,1.6c0.02,0.85-0.81,1.7-1.67,1.66c-0.91-0.05-1.46-0.55-1.57-1.47
	C414.71,124.39,415.42,123.55,416.31,123.5z"
              />
              <path
                d="M370.56,78.58c0.01,0.85-0.84,1.7-1.71,1.64c-0.92-0.06-1.46-0.57-1.55-1.5c-0.09-0.91,0.63-1.74,1.53-1.78
	C369.68,76.91,370.55,77.72,370.56,78.58z"
              />
              <path
                d="M372.46,621.97c-0.87-0.01-1.68-0.86-1.64-1.71c0.04-0.87,0.93-1.67,1.78-1.56c0.9,0.12,1.43,0.67,1.48,1.59
	C374.14,621.22,373.38,621.98,372.46,621.97z"
              />
              <path
                d="M358.64,377.53c-0.87,0.04-1.73-0.73-1.78-1.59c-0.05-0.87,0.72-1.7,1.61-1.75c0.94-0.05,1.69,0.63,1.73,1.58
	C360.25,376.71,359.55,377.49,358.64,377.53z"
              />
              <path
                d="M410.92,580.77c-0.02-0.95,0.69-1.68,1.63-1.68c0.91,0,1.7,0.76,1.71,1.64c0.01,0.86-0.8,1.69-1.67,1.71
	C411.7,582.45,410.93,581.69,410.92,580.77z"
              />
              <path
                d="M488.63,563.53c-0.04,0.86-0.88,1.64-1.75,1.62c-0.9-0.03-1.62-0.81-1.59-1.74c0.03-0.94,0.78-1.64,1.71-1.61
	C487.9,561.84,488.67,562.66,488.63,563.53z"
              />
              <path
                d="M471.26,548.62c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C469.61,549.54,470.4,548.66,471.26,548.62z"
              />
              <path
                d="M291.58,85.52c0.02,0.93-0.7,1.69-1.61,1.71c-0.87,0.01-1.7-0.78-1.72-1.63c-0.02-0.88,0.75-1.66,1.64-1.69
	C290.85,83.88,291.57,84.57,291.58,85.52z"
              />
              <path
                d="M582.34,625.32c-0.92-0.04-1.62-0.83-1.52-1.77c0.1-0.92,0.66-1.43,1.56-1.5c0.86-0.06,1.71,0.81,1.69,1.66
	C584.07,624.57,583.21,625.36,582.34,625.32z"
              />
              <path
                d="M407.54,588.5c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C406.82,586.81,407.59,587.64,407.54,588.5z"
              />
              <path
                d="M355.41,611.08c-0.06,0.92-0.85,1.64-1.75,1.57c-0.88-0.06-1.65-0.92-1.59-1.78c0.06-0.87,0.89-1.6,1.79-1.55
	C354.81,609.38,355.47,610.12,355.41,611.08z"
              />
              <path
                d="M363.35,411.58c0.01,0.85-0.82,1.7-1.68,1.64c-0.91-0.06-1.46-0.56-1.55-1.48c-0.1-0.9,0.63-1.73,1.51-1.78
	C362.47,409.92,363.33,410.72,363.35,411.58z"
              />
              <path
                d="M403.21,411.91c-0.04,0.85-0.89,1.62-1.75,1.6c-0.91-0.03-1.61-0.8-1.57-1.73c0.04-0.94,0.77-1.62,1.71-1.58
	C402.49,410.23,403.25,411.05,403.21,411.91z"
              />
              <path
                d="M241.69,382.67c-0.06,0.92-0.85,1.64-1.75,1.57c-0.88-0.06-1.65-0.92-1.59-1.78c0.06-0.87,0.89-1.6,1.79-1.55
	C241.08,380.97,241.75,381.72,241.69,382.67z"
              />
              <path
                d="M316.33,412.03c0.03,0.85-0.8,1.72-1.67,1.68c-0.92-0.04-1.47-0.55-1.58-1.46c-0.11-0.91,0.59-1.75,1.49-1.81
	C315.43,410.38,316.3,411.17,316.33,412.03z"
              />
              <path
                d="M553.7,627.72c-0.91,0.04-1.7-0.71-1.67-1.63c0.03-0.92,0.53-1.48,1.43-1.63c0.85-0.14,1.75,0.63,1.82,1.51
	C555.35,626.82,554.58,627.68,553.7,627.72z"
              />
              <path
                d="M404.83,599.41c0.03,0.93-0.68,1.7-1.59,1.72c-0.86,0.02-1.7-0.76-1.74-1.61c-0.03-0.87,0.74-1.67,1.63-1.7
	C404.07,597.78,404.8,598.47,404.83,599.41z"
              />
              <path
                d="M326.39,360.97c-0.15,0.88-1.08,1.55-1.93,1.39c-0.89-0.16-1.51-1.08-1.28-1.98c0.23-0.89,0.83-1.36,1.75-1.28
	C325.82,359.18,326.53,360.12,326.39,360.97z"
              />
              <path
                d="M426.2,344.85c0.03,0.85-0.79,1.71-1.66,1.67c-0.91-0.04-1.46-0.54-1.58-1.46c-0.11-0.9,0.6-1.74,1.48-1.8
	C425.31,343.21,426.17,343.99,426.2,344.85z"
              />
              <path
                d="M385.46,84.7c-0.06,0.85-0.93,1.6-1.79,1.55c-0.91-0.05-1.59-0.83-1.53-1.77c0.06-0.94,0.8-1.6,1.75-1.53
	C384.77,83.01,385.52,83.84,385.46,84.7z"
              />
              <path
                d="M324.42,66.38c0.91-0.05,1.71,0.71,1.69,1.64c-0.02,0.92-0.51,1.5-1.42,1.64c-0.88,0.13-1.76-0.61-1.84-1.5
	C322.76,67.31,323.55,66.43,324.42,66.38z"
              />
              <path
                d="M399.85,249.63c0.01,0.86-0.79,1.68-1.66,1.7c-0.89,0.02-1.65-0.73-1.67-1.65c-0.02-0.95,0.68-1.66,1.62-1.67
	C399.06,248.01,399.84,248.74,399.85,249.63z"
              />
              <path
                d="M388.8,413.02c0.01,0.86-0.84,1.71-1.72,1.65c-0.92-0.06-1.47-0.57-1.56-1.5c-0.09-0.93,0.62-1.74,1.53-1.78
	C387.92,411.35,388.79,412.17,388.8,413.02z"
              />
              <path
                d="M275.12,244.16c0.95,0.03,1.63,0.76,1.59,1.71c-0.03,0.92-0.81,1.66-1.71,1.62c-0.88-0.04-1.66-0.88-1.63-1.74
	C273.41,244.88,274.22,244.14,275.12,244.16z"
              />
              <path
                d="M317.74,323.3c-0.87-0.03-1.67-0.86-1.65-1.71c0.02-0.88,0.81-1.62,1.72-1.61c0.94,0.01,1.63,0.73,1.61,1.68
	C319.39,322.59,318.63,323.33,317.74,323.3z"
              />
              <path
                d="M434.38,437.99c0,0.85-0.85,1.69-1.72,1.63c-0.92-0.06-1.46-0.59-1.54-1.51c-0.09-0.91,0.65-1.73,1.54-1.77
	C433.52,436.31,434.37,437.13,434.38,437.99z"
              />
              <path
                d="M350.43,616.68c-0.87,0.01-1.69-0.82-1.67-1.67c0.02-0.86,0.89-1.68,1.74-1.59c0.89,0.1,1.43,0.63,1.5,1.55
	C352.07,615.9,351.34,616.67,350.43,616.68z"
              />
              <path
                d="M464.69,433.18c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.72,1.56
	C466.29,432.37,465.6,433.14,464.69,433.18z"
              />
              <path
                d="M365.48,453.15c0.85-0.03,1.71,0.79,1.71,1.64c0,0.85-0.85,1.69-1.71,1.62c-0.91-0.07-1.45-0.58-1.54-1.5
	C363.86,454,364.6,453.18,365.48,453.15z"
              />
              <path
                d="M350.02,71.11c-0.87,0.05-1.73-0.74-1.75-1.6c-0.02-0.85,0.82-1.73,1.68-1.68c0.91,0.06,1.47,0.56,1.58,1.48
	C351.63,70.25,350.94,71.05,350.02,71.11z"
              />
              <path
                d="M310.44,360.29c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C308.76,360.91,309.5,360.24,310.44,360.29z"
              />
              <path
                d="M330.9,537.44c-0.07,0.94-0.87,1.63-1.78,1.5c-0.92-0.13-1.4-0.75-1.47-1.62c-0.07-0.85,0.85-1.67,1.71-1.64
	C330.24,535.7,330.96,536.52,330.9,537.44z"
              />
              <path
                d="M255.91,255.01c-0.04,0.86-0.89,1.64-1.76,1.61c-0.91-0.03-1.62-0.81-1.58-1.75c0.03-0.94,0.78-1.63,1.72-1.59
	C255.18,253.31,255.95,254.14,255.91,255.01z"
              />
              <path
                d="M301.39,339.41c0.89-0.05,1.7,0.72,1.68,1.64c-0.02,0.91-0.51,1.49-1.42,1.63c-0.87,0.14-1.75-0.61-1.83-1.49
	C299.74,340.34,300.52,339.46,301.39,339.41z"
              />
              <path
                d="M433.59,430.69c0.06,0.95-0.64,1.72-1.56,1.74c-0.87,0.02-1.77-0.8-1.69-1.65c0.08-0.87,0.57-1.48,1.49-1.61
	C432.7,429.03,433.53,429.77,433.59,430.69z"
              />
              <path
                d="M464.91,184.42c0.87,0.03,1.67,0.91,1.61,1.76c-0.06,0.88-0.94,1.64-1.82,1.53c-0.92-0.12-1.42-0.69-1.45-1.61
	C463.22,185.16,464.01,184.38,464.91,184.42z"
              />
              <path
                d="M332.23,322.06c-0.87,0.03-1.72-0.78-1.72-1.64c0-0.86,0.86-1.71,1.72-1.63c0.91,0.08,1.45,0.6,1.54,1.52
	C333.86,321.25,333.15,322.03,332.23,322.06z"
              />
              <path
                d="M441.29,373.88c-0.91-0.03-1.62-0.82-1.53-1.75c0.09-0.92,0.65-1.43,1.54-1.51c0.85-0.07,1.7,0.79,1.7,1.63
	C443,373.11,442.15,373.91,441.29,373.88z"
              />
              <path
                d="M283.79,181.74c-0.86,0.04-1.72-0.73-1.77-1.58c-0.05-0.86,0.71-1.69,1.6-1.73c0.94-0.05,1.68,0.62,1.72,1.56
	C285.39,180.94,284.7,181.71,283.79,181.74z"
              />
              <path
                d="M396.79,609.01c-0.91,0.04-1.7-0.71-1.67-1.63c0.03-0.92,0.53-1.48,1.43-1.63c0.85-0.14,1.75,0.63,1.82,1.51
	C398.44,608.11,397.67,608.97,396.79,609.01z"
              />
              <path
                d="M420.22,126.84c0.87-0.01,1.71,0.83,1.69,1.69c-0.02,0.86-0.89,1.68-1.76,1.6c-0.92-0.08-1.45-0.62-1.51-1.55
	C418.57,127.64,419.31,126.85,420.22,126.84z"
              />
              <path
                d="M312.8,428.65c0.87,0.03,1.67,0.91,1.61,1.76c-0.06,0.88-0.94,1.64-1.82,1.53c-0.92-0.12-1.42-0.69-1.45-1.61
	C311.11,429.4,311.9,428.62,312.8,428.65z"
              />
              <path
                d="M311.47,414.26c0.9-0.04,1.7,0.72,1.68,1.65c-0.02,0.92-0.52,1.5-1.44,1.63c-0.88,0.13-1.76-0.62-1.83-1.51
	C309.81,415.18,310.6,414.3,311.47,414.26z"
              />
              <path
                d="M500.28,240.71c0.04,0.93-0.68,1.7-1.59,1.7c-0.86,0-1.73-0.83-1.64-1.68c0.09-0.87,0.6-1.46,1.5-1.58
	C499.42,239.04,500.24,239.8,500.28,240.71z"
              />
              <path
                d="M425.9,590.8c-0.13,0.93-0.98,1.58-1.88,1.38c-0.89-0.2-1.36-0.81-1.35-1.71c0.01-0.87,0.94-1.62,1.8-1.53
	C425.37,589.03,426.02,589.89,425.9,590.8z"
              />
              <path
                d="M591.31,622.93c0.02,0.85-0.78,1.68-1.65,1.71c-0.89,0.03-1.65-0.71-1.68-1.64c-0.02-0.95,0.66-1.67,1.61-1.68
	C590.51,621.31,591.29,622.05,591.31,622.93z"
              />
              <path
                d="M400.34,462.79c-0.05,0.86-0.9,1.63-1.77,1.6c-0.91-0.04-1.61-0.82-1.57-1.76c0.04-0.94,0.79-1.63,1.73-1.58
	C399.62,461.09,400.39,461.92,400.34,462.79z"
              />
              <path
                d="M423.99,569.05c-0.03,0.92-0.82,1.65-1.72,1.55c-0.92-0.1-1.41-0.69-1.51-1.56c-0.09-0.83,0.78-1.69,1.63-1.7
	C423.26,567.34,424.02,568.15,423.99,569.05z"
              />
              <path
                d="M401.23,453.34c-0.87-0.02-1.67-0.85-1.65-1.71c0.02-0.89,0.8-1.62,1.72-1.61c0.94,0.01,1.63,0.73,1.61,1.68
	C402.88,452.62,402.13,453.37,401.23,453.34z"
              />
              <path
                d="M402.01,251.12c0.86,0.01,1.68,0.87,1.64,1.72c-0.04,0.87-0.91,1.66-1.78,1.56c-0.92-0.1-1.43-0.65-1.48-1.57
	C400.35,251.9,401.12,251.11,402.01,251.12z"
              />
              <path
                d="M420.63,572.95c-0.07,0.95-0.87,1.64-1.79,1.5c-0.93-0.13-1.4-0.76-1.47-1.63c-0.07-0.85,0.85-1.68,1.72-1.65
	C419.99,571.21,420.7,572.02,420.63,572.95z"
              />
              <path
                d="M417.01,589.41c0.9-0.06,1.71,0.69,1.7,1.62c-0.01,0.92-0.49,1.5-1.41,1.65c-0.88,0.14-1.77-0.59-1.86-1.48
	C415.37,590.35,416.14,589.46,417.01,589.41z"
              />
              <path
                d="M249.62,349.3c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C248.86,347.55,249.67,348.45,249.62,349.3z"
              />
              <path
                d="M300.37,451.46c0.89,0.05,1.65,0.87,1.6,1.73c-0.05,0.85-0.9,1.62-1.77,1.58c-0.91-0.04-1.6-0.81-1.56-1.75
	C298.68,452.08,299.42,451.41,300.37,451.46z"
              />
              <path
                d="M369.54,416.67c0.89-0.04,1.7,0.73,1.67,1.65c-0.03,0.92-0.52,1.49-1.43,1.62c-0.87,0.13-1.75-0.62-1.82-1.5
	C367.89,417.58,368.67,416.71,369.54,416.67z"
              />
              <path
                d="M474.91,425.55c0.92-0.01,1.7,0.73,1.72,1.61c0.02,0.85-0.79,1.68-1.65,1.7c-0.89,0.02-1.65-0.72-1.67-1.64
	C473.28,426.28,473.97,425.56,474.91,425.55z"
              />
              <path
                d="M325.36,532.3c0.9-0.06,1.71,0.69,1.7,1.62c-0.01,0.92-0.49,1.5-1.41,1.65c-0.88,0.14-1.77-0.59-1.86-1.48
	C323.72,533.25,324.49,532.36,325.36,532.3z"
              />
              <path
                d="M432.01,535.6c0.02,0.86-0.78,1.7-1.65,1.73c-0.89,0.03-1.66-0.72-1.69-1.64c-0.03-0.95,0.67-1.68,1.61-1.7
	C431.19,533.97,431.99,534.72,432.01,535.6z"
              />
              <path
                d="M562.47,625.72c-0.07,0.88-0.94,1.6-1.81,1.5c-0.9-0.1-1.56-0.95-1.4-1.86c0.16-0.91,0.74-1.38,1.64-1.4
	C561.74,623.96,562.53,624.88,562.47,625.72z"
              />
              <path
                d="M528.13,271.02c-0.01,0.94-0.78,1.69-1.7,1.62c-0.93-0.08-1.45-0.66-1.57-1.54c-0.12-0.85,0.73-1.73,1.6-1.76
	C527.36,269.32,528.13,270.1,528.13,271.02z"
              />
              <path
                d="M323.04,364.66c-0.05,0.87-0.92,1.62-1.79,1.55c-0.92-0.08-1.58-0.9-1.45-1.83c0.13-0.92,0.71-1.41,1.62-1.44
	C322.28,362.9,323.09,363.81,323.04,364.66z"
              />
              <path
                d="M600.71,623.95c0.86,0.03,1.66,0.91,1.6,1.76c-0.06,0.88-0.93,1.64-1.81,1.52c-0.91-0.13-1.41-0.69-1.45-1.61
	C599.02,624.71,599.83,623.92,600.71,623.95z"
              />
              <path
                d="M358.12,599.9c0.02,0.86-0.78,1.7-1.65,1.73c-0.89,0.03-1.66-0.72-1.69-1.64c-0.03-0.95,0.67-1.68,1.61-1.7
	C357.29,598.27,358.1,599.02,358.12,599.9z"
              />
              <path
                d="M459.3,186.66c-0.07,0.88-0.93,1.6-1.8,1.51c-0.91-0.1-1.56-0.93-1.41-1.85c0.15-0.91,0.74-1.39,1.63-1.41
	C458.57,184.9,459.36,185.82,459.3,186.66z"
              />
              <path
                d="M423.52,252.49c-0.92-0.04-1.62-0.84-1.52-1.77c0.1-0.92,0.66-1.43,1.56-1.5c0.86-0.06,1.71,0.81,1.69,1.66
	C425.24,251.73,424.39,252.53,423.52,252.49z"
              />
              <path
                d="M426.21,573.98c-0.86-0.01-1.67-0.86-1.63-1.7c0.04-0.86,0.92-1.67,1.77-1.55c0.89,0.12,1.42,0.66,1.47,1.58
	C427.87,573.22,427.11,573.99,426.21,573.98z"
              />
              <path
                d="M472.29,543.03c0.03,0.85-0.8,1.72-1.68,1.69c-0.92-0.04-1.48-0.54-1.59-1.47c-0.11-0.93,0.58-1.76,1.49-1.82
	C471.38,541.37,472.26,542.16,472.29,543.03z"
              />
              <path
                d="M351.53,607.86c0,0.91-0.78,1.68-1.66,1.61c-0.91-0.07-1.42-0.64-1.55-1.51c-0.11-0.83,0.72-1.71,1.56-1.74
	C350.73,606.18,351.53,606.98,351.53,607.86z"
              />
              <path
                d="M442.22,570.92c-0.01,0.9-0.79,1.67-1.68,1.6c-0.91-0.08-1.42-0.65-1.54-1.52c-0.11-0.83,0.73-1.71,1.57-1.73
	C441.43,569.24,442.22,570.04,442.22,570.92z"
              />
              <path
                d="M447.77,568.79c0.86-0.03,1.67,0.77,1.64,1.65c-0.03,0.91-0.54,1.46-1.43,1.59c-0.86,0.13-1.72-0.63-1.79-1.5
	C446.13,569.7,446.93,568.82,447.77,568.79z"
              />
              <path
                d="M380.6,320.05c-0.06,0.87-0.92,1.6-1.78,1.52c-0.9-0.09-1.56-0.92-1.42-1.83c0.14-0.9,0.73-1.38,1.61-1.41
	C379.85,318.3,380.65,319.21,380.6,320.05z"
              />
              <path
                d="M445.39,567.09c-0.01,0.85-0.86,1.64-1.72,1.6c-0.9-0.04-1.6-0.85-1.5-1.76c0.1-0.91,0.66-1.41,1.55-1.48
	C444.55,565.38,445.4,566.25,445.39,567.09z"
              />
              <path
                d="M436.27,419.73c0.01,0.85-0.82,1.69-1.67,1.64c-0.9-0.06-1.45-0.56-1.55-1.47c-0.1-0.89,0.62-1.72,1.5-1.77
	C435.4,418.08,436.26,418.88,436.27,419.73z"
              />
              <path
                d="M330.72,473.68c-0.86-0.01-1.66-0.86-1.61-1.7c0.05-0.86,0.92-1.66,1.76-1.54c0.88,0.13,1.41,0.66,1.46,1.57
	C332.37,472.93,331.61,473.7,330.72,473.68z"
              />
              <path
                d="M380.75,256.31c-0.86,0.06-1.71-0.7-1.75-1.56c-0.03-0.83,0.8-1.73,1.63-1.68c0.89,0.05,1.46,0.54,1.58,1.45
	C382.33,255.43,381.65,256.24,380.75,256.31z"
              />
              <path
                d="M391.62,89.43c0.86-0.03,1.67,0.76,1.65,1.64c-0.03,0.91-0.54,1.46-1.43,1.6c-0.86,0.13-1.73-0.62-1.79-1.5
	C389.99,90.34,390.78,89.46,391.62,89.43z"
              />
              <path
                d="M400.03,106.6c-0.9-0.03-1.62-0.82-1.53-1.73c0.09-0.91,0.64-1.42,1.52-1.51c0.84-0.08,1.7,0.78,1.7,1.62
	C401.73,105.83,400.89,106.63,400.03,106.6z"
              />
              <path
                d="M435.5,412.57c0,0.91-0.78,1.68-1.67,1.61c-0.91-0.08-1.42-0.64-1.54-1.51c-0.11-0.83,0.72-1.71,1.56-1.74
	C434.71,410.89,435.5,411.68,435.5,412.57z"
              />
              <path
                d="M307.27,490.58c0.89,0.03,1.62,0.83,1.54,1.74c-0.08,0.91-0.62,1.43-1.52,1.5c-0.86,0.07-1.7-0.75-1.71-1.6
	C305.58,491.38,306.43,490.55,307.27,490.58z"
              />
              <path
                d="M427.15,565.17c-0.01,0.85-0.86,1.64-1.72,1.6c-0.9-0.04-1.6-0.85-1.5-1.76c0.1-0.91,0.66-1.41,1.55-1.48
	C426.32,563.46,427.16,564.33,427.15,565.17z"
              />
              <path
                d="M630.52,368.54c-0.07-0.05-0.14-0.11-0.21-0.17c0-1.29-0.01-2.57-0.01-3.86c0,0-0.01-0.01-0.01-0.01
	c0.73,0,1.57-0.02,1.72,0.89c0.15,0.92,0.15,1.89,0.01,2.8C631.98,368.38,631.04,368.42,630.52,368.54z"
              />
              <path
                d="M309.46,40.31c-0.03,1.38-0.81,2.06-1.76,1.98c-0.89-0.08-1.24-0.62-1.25-1.45c-0.01-1.07,0.67-1.93,1.54-1.67
	C308.63,39.36,309.11,40.02,309.46,40.31z"
              />
              <path
                d="M438.18,441.43c-0.05,0.87-0.9,1.61-1.77,1.53c-0.9-0.08-1.57-0.91-1.43-1.81c0.13-0.91,0.71-1.39,1.6-1.43
	C437.41,439.69,438.22,440.6,438.18,441.43z"
              />
              <path
                d="M423.02,601.62c0,0.91-0.78,1.68-1.66,1.61c-0.91-0.07-1.42-0.64-1.55-1.51c-0.12-0.83,0.72-1.71,1.56-1.74
	C422.23,599.94,423.02,600.74,423.02,601.62z"
              />
              <path
                d="M274.27,177.37c0.04,0.92-0.68,1.69-1.58,1.69c-0.86,0-1.72-0.83-1.63-1.67c0.09-0.87,0.6-1.45,1.5-1.57
	C273.41,175.71,274.23,176.47,274.27,177.37z"
              />
              <path
                d="M297.3,442.75c0.02,0.91-0.73,1.68-1.62,1.66c-0.86-0.02-1.69-0.87-1.59-1.71c0.11-0.87,0.63-1.44,1.52-1.54
	C296.47,441.07,297.28,441.86,297.3,442.75z"
              />
              <path
                d="M435.87,435.77c-0.86,0.03-1.7-0.78-1.69-1.63c0-0.84,0.87-1.69,1.71-1.61c0.89,0.09,1.44,0.6,1.52,1.51
	C437.49,434.95,436.77,435.74,435.87,435.77z"
              />
              <path
                d="M296.94,598.27c-0.03-1.27,0.71-2.14,1.56-2.08c0.9,0.06,1.46,0.59,1.55,1.51c0.09,0.9-0.68,1.73-1.59,1.7
	C297.53,599.37,296.97,598.91,296.94,598.27z"
              />
              <path
                d="M303.53,514.75c-0.34,0.26-0.85,0.87-1.48,1.05c-0.8,0.22-1.66-0.74-1.59-1.62c0.07-0.89,0.55-1.47,1.49-1.51
	C302.88,512.61,303.58,513.36,303.53,514.75z"
              />
              <path
                d="M479.92,423.22c-0.07,0.87-0.93,1.6-1.79,1.5c-0.9-0.1-1.55-0.94-1.4-1.84c0.15-0.9,0.74-1.38,1.63-1.4
	C479.19,421.47,479.98,422.39,479.92,423.22z"
              />
              <path
                d="M453.86,514.08c0.84,0.02,1.64,0.9,1.59,1.73c-0.06,0.87-0.92,1.63-1.78,1.51c-0.89-0.13-1.4-0.67-1.44-1.58
	C452.2,514.86,453.01,514.06,453.86,514.08z"
              />
              <path
                d="M431.94,203.46c-0.07,0.87-0.93,1.6-1.79,1.5c-0.9-0.1-1.55-0.94-1.4-1.84c0.15-0.9,0.74-1.38,1.63-1.39
	C431.21,201.71,432,202.63,431.94,203.46z"
              />
              <path
                d="M391.83,615.07c-0.01,0.9-0.79,1.67-1.68,1.6c-0.91-0.08-1.42-0.65-1.54-1.52c-0.11-0.83,0.73-1.71,1.57-1.73
	C391.05,613.38,391.84,614.18,391.83,615.07z"
              />
              <path
                d="M425,556.32c0.84-0.02,1.68,0.82,1.67,1.66c-0.01,0.85-0.86,1.67-1.72,1.58c-0.9-0.09-1.43-0.61-1.5-1.52
	C423.38,557.15,424.13,556.33,425,556.32z"
              />
              <path
                d="M387.42,371.58c0.87,0.03,1.61,0.86,1.52,1.75c-0.09,0.91-0.63,1.42-1.53,1.49c-0.86,0.07-1.7-0.77-1.69-1.62
	C385.72,372.37,386.58,371.55,387.42,371.58z"
              />
              <path
                d="M400.5,422.84c0.02,0.92-0.69,1.67-1.59,1.69c-0.85,0.01-1.69-0.78-1.71-1.61c-0.03-0.85,0.75-1.64,1.64-1.67
	C399.76,421.22,400.48,421.91,400.5,422.84z"
              />
              <path
                d="M367.8,422.69c-0.36,0.26-0.9,0.87-1.55,1.03c-0.83,0.21-1.72-0.8-1.61-1.66c0.11-0.9,0.67-1.45,1.58-1.52
	C367.09,420.47,367.88,421.36,367.8,422.69z"
              />
              <path
                d="M441.33,586.89c-0.26-0.37-0.87-0.91-1.04-1.56c-0.22-0.85,0.75-1.74,1.62-1.65c0.91,0.1,1.45,0.68,1.53,1.57
	C443.52,586.13,442.64,586.95,441.33,586.89z"
              />
              <path
                d="M384.17,4.02c0.92-0.03,1.63,0.31,1.66,1.29c0.03,0.9-0.51,1.49-1.47,1.55c-0.94,0.05-1.53-0.38-1.64-1.34
	C382.62,4.63,383.2,4.05,384.17,4.02z"
              />
              <path
                d="M300.52,185.91c-0.05,0.83-0.9,1.6-1.75,1.57c-0.91-0.03-1.59-0.78-1.55-1.72c0.04-0.94,0.76-1.6,1.7-1.55
	C299.79,184.26,300.56,185.08,300.52,185.91z"
              />
              <path
                d="M383.35,249.23c1.28,0,2.14,0.89,2.02,1.74c-0.13,0.89-0.66,1.45-1.59,1.5c-0.91,0.04-1.8-0.84-1.54-1.71
	C382.43,250.11,383.06,249.6,383.35,249.23z"
              />
              <path
                d="M409.88,593.44c-0.85-0.02-1.66-0.84-1.65-1.68c0.01-0.86,0.79-1.61,1.69-1.6c0.94,0.01,1.63,0.71,1.61,1.65
	C411.52,592.72,410.77,593.46,409.88,593.44z"
              />
              <path
                d="M436.84,427.63c-0.38,0.22-0.97,0.78-1.63,0.88c-0.86,0.13-1.64-0.94-1.45-1.79c0.2-0.88,0.76-1.41,1.7-1.37
	C436.36,425.38,437.04,426.29,436.84,427.63z"
              />
              <path
                d="M468.4,539.64c0.05,0.87-0.78,1.75-1.66,1.68c-0.92-0.07-1.48-0.59-1.54-1.53c-0.05-0.94,0.49-1.5,1.38-1.68
	C467.43,537.95,468.35,538.76,468.4,539.64z"
              />
              <path
                d="M376.07,252.92c-0.26-0.37-0.87-0.91-1.04-1.56c-0.22-0.85,0.75-1.74,1.62-1.65c0.91,0.1,1.45,0.68,1.53,1.57
	C378.27,252.16,377.38,252.99,376.07,252.92z"
              />
              <path
                d="M330.92,371.01c0.02,0.93-0.68,1.68-1.58,1.7c-0.85,0.02-1.69-0.76-1.73-1.6c-0.03-0.85,0.73-1.65,1.62-1.68
	C330.18,369.4,330.9,370.07,330.92,371.01z"
              />
              <path
                d="M328.27,69.74c1.29,0.1,2.08,1.03,1.89,1.87c-0.19,0.87-0.74,1.41-1.69,1.38c-0.91-0.03-1.74-0.96-1.42-1.81
	C327.3,70.56,327.97,70.09,328.27,69.74z"
              />
              <path
                d="M417.4,347.54c-0.71-0.88-1.36-1.37-1.57-2.01c-0.28-0.84,0.6-1.75,1.49-1.77c0.86-0.02,1.86,0.89,1.61,1.68
	C418.72,346.09,418.1,346.62,417.4,347.54z"
              />
              <path
                d="M396.48,253.51c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C395.71,251.88,396.5,252.66,396.48,253.51z"
              />
              <path
                d="M518.56,362.14c-0.87,0.73-1.35,1.38-1.99,1.6c-0.81,0.28-1.74-0.65-1.76-1.53c-0.02-0.86,0.9-1.85,1.69-1.6
	C517.15,360.81,517.66,361.44,518.56,362.14z"
              />
              <path
                d="M327.1,320.89c-0.88,0.72-1.37,1.36-2.01,1.58c-0.81,0.27-1.74-0.67-1.74-1.54c-0.01-0.87,0.92-1.84,1.71-1.59
	C325.71,319.55,326.21,320.18,327.1,320.89z"
              />
              <path
                d="M459.54,432.19c-0.87,0.73-1.35,1.38-1.99,1.6c-0.81,0.28-1.74-0.65-1.76-1.53c-0.02-0.86,0.9-1.85,1.69-1.6
	C458.13,430.86,458.64,431.49,459.54,432.19z"
              />
              <path
                d="M238.17,193.09c0.89,0.02,1.64,0.88,1.53,1.79c-0.12,0.93-0.7,1.46-1.62,1.44c-0.93-0.02-1.45-0.64-1.56-1.53
	C236.42,193.93,237.31,193.07,238.17,193.09z"
              />
              <path
                d="M323.24,491.04c-0.02,0.91-0.77,1.65-1.66,1.63c-0.85-0.02-1.66-0.84-1.65-1.68c0.01-0.87,0.8-1.61,1.7-1.6
	C322.58,489.4,323.26,490.1,323.24,491.04z"
              />
              <path
                d="M390.42,474.18c0.02,0.92-0.69,1.67-1.59,1.69c-0.85,0.01-1.69-0.78-1.71-1.61c-0.03-0.85,0.75-1.64,1.64-1.67
	C389.69,472.57,390.4,473.25,390.42,474.18z"
              />
              <path
                d="M271.17,197.29c-1.31-0.01-2.14-0.88-2.02-1.74c0.13-0.89,0.69-1.44,1.6-1.49c0.89-0.05,1.79,0.87,1.52,1.73
	C272.09,196.42,271.45,196.93,271.17,197.29z"
              />
              <path
                d="M458.86,511.66c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C458.09,510.03,458.87,510.81,458.86,511.66z"
              />
              <path
                d="M442.26,357.87c-0.89,0.7-1.39,1.34-2.03,1.55c-0.81,0.26-1.73-0.7-1.72-1.57c0.01-0.87,0.95-1.83,1.74-1.56
	C440.89,356.51,441.38,357.15,442.26,357.87z"
              />
              <path
                d="M403.84,109.75c-0.91-0.04-1.58-0.8-1.54-1.74c0.05-0.93,0.78-1.59,1.72-1.53c0.89,0.05,1.64,0.86,1.58,1.72
	C405.55,109.04,404.69,109.79,403.84,109.75z"
              />
              <path
                d="M306.28,376.89c-0.05,0.83-0.9,1.6-1.75,1.57c-0.91-0.03-1.59-0.78-1.55-1.72c0.04-0.94,0.76-1.6,1.7-1.55
	C305.55,375.23,306.32,376.05,306.28,376.89z"
              />
              <path
                d="M254.74,137.85c-0.71-0.88-1.36-1.37-1.57-2.01c-0.28-0.84,0.6-1.75,1.49-1.77c0.86-0.02,1.86,0.89,1.61,1.68
	C256.06,136.41,255.43,136.93,254.74,137.85z"
              />
              <path
                d="M482.75,558.49c0.94-0.05,1.67,0.6,1.72,1.53c0.05,0.94-0.62,1.69-1.54,1.74c-0.86,0.04-1.71-0.71-1.77-1.55
	C481.11,559.35,481.86,558.54,482.75,558.49z"
              />
              <path
                d="M254.63,406.58c-0.01,0.91-0.76,1.66-1.65,1.64c-0.85-0.01-1.66-0.83-1.66-1.67c0-0.86,0.79-1.61,1.69-1.61
	C253.95,404.94,254.64,405.64,254.63,406.58z"
              />
              <path
                d="M409.44,111.47c-0.01,0.84-0.83,1.64-1.68,1.64c-0.89,0-1.62-0.75-1.62-1.66c0-0.94,0.7-1.63,1.64-1.62
	C408.66,109.85,409.45,110.62,409.44,111.47z"
              />
              <path
                d="M366.25,487.13c0.02,0.85-0.76,1.67-1.62,1.7c-0.89,0.03-1.65-0.7-1.68-1.61c-0.03-0.94,0.64-1.65,1.59-1.67
	C365.44,485.54,366.23,486.26,366.25,487.13z"
              />
              <path
                d="M389.71,467.29c-0.02,0.88-0.91,1.69-1.77,1.56c-0.91-0.14-1.44-0.7-1.42-1.63c0.02-0.93,0.59-1.47,1.49-1.57
	C388.86,465.55,389.73,466.43,389.71,467.29z"
              />
              <path
                d="M256.07,401.11c0.91-0.03,1.7,0.69,1.74,1.55c0.03,0.85-0.74,1.68-1.61,1.72c-0.89,0.04-1.66-0.67-1.7-1.58
	C254.46,401.85,255.12,401.14,256.07,401.11z"
              />
              <path
                d="M332.69,488.04c-1.31,0.07-2.21-0.75-2.12-1.62c0.08-0.89,0.61-1.48,1.53-1.58c0.87-0.1,1.84,0.78,1.62,1.64
	C333.55,487.13,332.94,487.67,332.69,488.04z"
              />
              <path
                d="M478.91,555.13c0.94-0.05,1.67,0.6,1.72,1.53c0.05,0.94-0.62,1.69-1.54,1.74c-0.85,0.04-1.71-0.71-1.76-1.55
	C477.27,555.99,478.02,555.18,478.91,555.13z"
              />
              <path
                d="M419.93,605.37c0.05,0.87-0.77,1.75-1.65,1.69c-0.92-0.06-1.49-0.59-1.54-1.52c-0.05-0.94,0.48-1.51,1.37-1.68
	C418.96,603.68,419.88,604.49,419.93,605.37z"
              />
              <path
                d="M664.23,631.02c0.06,0.85-0.69,1.69-1.56,1.75c-0.9,0.07-1.68-0.62-1.74-1.53c-0.06-0.96,0.57-1.67,1.52-1.73
	C663.36,629.46,664.18,630.15,664.23,631.02z"
              />
              <path
                d="M429.7,554.63c-0.36,0.26-0.89,0.87-1.54,1.05c-0.83,0.22-1.73-0.78-1.62-1.65c0.11-0.9,0.66-1.46,1.57-1.53
	C428.97,552.42,429.77,553.3,429.7,554.63z"
              />
              <path
                d="M461.38,168.84c-0.36,0.26-0.89,0.88-1.54,1.05c-0.83,0.22-1.73-0.78-1.63-1.65c0.11-0.9,0.66-1.46,1.57-1.54
	C460.64,166.63,461.44,167.51,461.38,168.84z"
              />
              <path
                d="M399.98,435.66c0.91-0.05,1.72,0.64,1.78,1.51c0.05,0.85-0.7,1.69-1.56,1.75c-0.9,0.06-1.68-0.62-1.73-1.54
	C398.4,436.42,399.03,435.71,399.98,435.66z"
              />
              <path
                d="M384.28,339.13c-0.89,0.02-1.64-0.72-1.66-1.63c-0.02-0.94,0.66-1.64,1.61-1.65c0.9-0.01,1.69,0.73,1.7,1.6
	C385.94,338.29,385.14,339.11,384.28,339.13z"
              />
              <path
                d="M342.29,393.66c0.89,0.02,1.64,0.88,1.53,1.79c-0.12,0.93-0.7,1.46-1.62,1.44c-0.93-0.02-1.45-0.64-1.56-1.53
	C340.55,394.5,341.44,393.64,342.29,393.66z"
              />
              <path
                d="M351.08,394.62c-0.03,0.91-0.79,1.64-1.68,1.6c-0.86-0.03-1.64-0.86-1.62-1.71c0.02-0.87,0.82-1.59,1.72-1.57
	C350.44,392.97,351.11,393.68,351.08,394.62z"
              />
              <path
                d="M457.45,220.77c-1.31,0.07-2.21-0.75-2.12-1.62c0.08-0.89,0.61-1.48,1.53-1.58c0.87-0.1,1.84,0.78,1.62,1.64
	C458.31,219.86,457.7,220.4,457.45,220.77z"
              />
              <path
                d="M433.71,533.46c-0.89,0.02-1.64-0.72-1.66-1.63c-0.02-0.94,0.66-1.64,1.6-1.65c0.9-0.01,1.69,0.73,1.7,1.6
	C435.36,532.62,434.56,533.44,433.71,533.46z"
              />
              <path
                d="M380.12,478.77c0,0.87-0.87,1.71-1.74,1.6c-0.91-0.11-1.46-0.67-1.46-1.6c0-0.93,0.56-1.48,1.46-1.61
	C379.23,477.05,380.12,477.91,380.12,478.77z"
              />
              <path
                d="M232.87,215.4c0.95,0.01,1.62,0.71,1.6,1.66c-0.02,0.91-0.78,1.65-1.66,1.63c-0.85-0.02-1.65-0.85-1.64-1.69
	C231.18,216.13,231.97,215.39,232.87,215.4z"
              />
              <path
                d="M437.68,580.29c1.29,0.1,2.08,1.03,1.89,1.87c-0.19,0.87-0.74,1.41-1.69,1.38c-0.91-0.03-1.74-0.96-1.42-1.81
	C436.71,581.1,437.37,580.64,437.68,580.29z"
              />
              <path
                d="M363.71,442.02c-0.29-0.36-0.93-0.85-1.14-1.49c-0.28-0.86,0.6-1.78,1.5-1.75c0.92,0.03,1.48,0.58,1.63,1.46
	C365.83,441.1,365.01,441.98,363.71,442.02z"
              />
              <path
                d="M248.79,216.37c-0.37,0.25-0.91,0.86-1.56,1.02c-0.83,0.21-1.71-0.81-1.59-1.68c0.12-0.9,0.68-1.45,1.59-1.51
	C248.11,214.15,248.88,215.04,248.79,216.37z"
              />
              <path
                d="M282.16,409.77c0.96-0.06,1.71,0.6,1.72,1.55c0.01,0.94-0.49,1.49-1.39,1.64c-0.9,0.15-1.76-0.54-1.87-1.43
	C280.52,410.65,281.24,409.83,282.16,409.77z"
              />
              <path
                d="M381.62,346.91c0.96,0.01,1.65,0.72,1.58,1.67c-0.06,0.93-0.61,1.43-1.51,1.51c-0.89,0.08-1.72-0.69-1.75-1.55
	C379.92,347.67,380.7,346.91,381.62,346.91z"
              />
              <path
                d="M238.6,366.59c0.95,0,1.66,0.71,1.6,1.65c-0.05,0.93-0.59,1.44-1.49,1.53c-0.89,0.09-1.73-0.67-1.77-1.53
	C236.91,367.38,237.7,366.59,238.6,366.59z"
              />
              <path
                d="M452.62,440.25c-0.06,0.87-0.94,1.61-1.82,1.53c-0.93-0.08-1.59-0.9-1.46-1.84c0.13-0.95,0.75-1.38,1.65-1.4
	C451.89,438.53,452.68,439.38,452.62,440.25z"
              />
              <path
                d="M430.81,544.46c-0.93,0.01-1.66-0.73-1.62-1.67c0.04-0.94,0.56-1.45,1.48-1.54c0.94-0.08,1.75,0.62,1.78,1.52
	C432.49,543.64,431.7,544.45,430.81,544.46z"
              />
              <path
                d="M446.57,574.21c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C445.16,572.9,445.67,573.53,446.57,574.21z"
              />
              <path
                d="M669.71,629.04c0.92-0.02,1.73,0.74,1.71,1.62c-0.01,0.86-0.87,1.69-1.74,1.61c-0.9-0.08-1.48-0.57-1.55-1.52
	C668.07,629.79,668.75,629.06,669.71,629.04z"
              />
              <path
                d="M399.53,455.53c-0.02,0.94-0.77,1.65-1.7,1.6c-0.89-0.05-1.69-0.91-1.56-1.76c0.13-0.89,0.69-1.41,1.61-1.46
	C398.82,453.86,399.54,454.59,399.53,455.53z"
              />
              <path
                d="M426.97,580.91c-0.93,0.01-1.65-0.72-1.61-1.66c0.04-0.93,0.56-1.45,1.47-1.53c0.94-0.08,1.74,0.61,1.77,1.51
	C428.64,580.1,427.86,580.9,426.97,580.91z"
              />
              <path
                d="M526.09,250.08c-0.38,0.22-0.96,0.78-1.62,0.88c-0.86,0.13-1.62-0.92-1.44-1.78c0.19-0.88,0.76-1.4,1.68-1.37
	C525.6,247.84,526.29,248.75,526.09,250.08z"
              />
              <path
                d="M503.77,592.45c-0.93,0.02-1.67-0.71-1.65-1.65c0.03-0.94,0.54-1.46,1.46-1.56c0.93-0.1,1.75,0.6,1.81,1.49
	C505.44,591.6,504.66,592.43,503.77,592.45z"
              />
              <path
                d="M493.8,30.49c1.16,0.1,2.66,1.46,2.36,1.96c-0.28,0.47-0.89,0.93-1.41,1.01c-0.95,0.14-1.86-0.92-1.66-1.88
	C493.17,31.14,493.6,30.77,493.8,30.49z"
              />
              <path
                d="M545.91,618c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C544.27,618.76,544.94,618.03,545.91,618z"
              />
              <path
                d="M336.96,542.21c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.54c-0.89,0.09-1.73-0.66-1.77-1.53
	C335.28,543.01,336.07,542.21,336.96,542.21z"
              />
              <path
                d="M356.64,186.65c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.53c-0.89,0.09-1.73-0.66-1.77-1.53
	C354.95,187.45,355.74,186.65,356.64,186.65z"
              />
              <path
                d="M469.4,427.62c0.03,0.87-0.76,1.68-1.65,1.68c-0.93,0.01-1.66-0.74-1.61-1.68c0.04-0.94,0.57-1.45,1.49-1.53
	C468.56,426.01,469.37,426.72,469.4,427.62z"
              />
              <path
                d="M302.6,190.83c-0.93-0.04-1.63-0.83-1.54-1.77c0.09-0.95,0.66-1.42,1.58-1.47c0.92-0.05,1.72,0.74,1.71,1.62
	C304.33,190.08,303.48,190.87,302.6,190.83z"
              />
              <path
                d="M275.61,254.63c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C277.24,253.84,276.54,254.61,275.61,254.63z"
              />
              <path
                d="M429.08,586.4c0.05,0.86-0.72,1.73-1.6,1.7c-0.88-0.03-1.5-0.44-1.63-1.38c-0.13-0.96,0.48-1.71,1.43-1.79
	C428.21,584.85,429.03,585.51,429.08,586.4z"
              />
              <path
                d="M668.54,538.67c-0.56-0.43-1.37-0.76-1.61-1.33c-0.22-0.53-0.01-1.4,0.3-1.96c0.43-0.75,1.4-0.63,1.65,0.19
	c0.26,0.86,0.27,1.8,0.39,2.7C669.03,538.41,668.78,538.54,668.54,538.67z"
              />
              <path
                d="M286.3,266.64c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C284.9,265.33,285.4,265.95,286.3,266.64z"
              />
              <path
                d="M362.88,72.16c-0.03,0.86-0.88,1.62-1.76,1.57c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.44
	C362.12,70.49,362.91,71.28,362.88,72.16z"
              />
              <path
                d="M435.81,539.06c-0.04,0.86-0.88,1.6-1.77,1.54c-0.93-0.06-1.59-0.84-1.48-1.77c0.11-0.93,0.69-1.38,1.6-1.41
	C435.05,537.39,435.85,538.2,435.81,539.06z"
              />
              <path
                d="M505.64,242.44c-0.69-0.89-1.32-1.4-1.52-2.04c-0.26-0.83,0.65-1.72,1.52-1.72c0.85,0,1.81,0.93,1.55,1.72
	C506.98,241.04,506.35,241.54,505.64,242.44z"
              />
              <path
                d="M325.99,416.34c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C327.62,415.55,326.92,416.31,325.99,416.34z"
              />
              <path
                d="M442.08,543.86c0.89,0.01,1.65,0.85,1.55,1.75c-0.11,0.92-0.68,1.46-1.58,1.45c-0.92,0-1.45-0.6-1.57-1.49
	C440.36,544.73,441.23,543.85,442.08,543.86z"
              />
              <path
                d="M551.45,622.9c0.04,0.87-0.75,1.68-1.64,1.69c-0.93,0.01-1.66-0.73-1.62-1.67c0.04-0.94,0.56-1.45,1.48-1.54
	C550.61,621.3,551.42,622.01,551.45,622.9z"
              />
              <path
                d="M365.95,566.89c-0.04,0.93-0.82,1.64-1.74,1.57c-0.89-0.07-1.67-0.95-1.53-1.81c0.15-0.89,0.72-1.41,1.65-1.43
	C365.28,565.19,365.98,565.94,365.95,566.89z"
              />
              <path
                d="M312.01,69.33c-0.06,0.87-0.92,1.59-1.81,1.51c-0.93-0.08-1.58-0.88-1.45-1.82c0.13-0.94,0.74-1.37,1.64-1.38
	C311.29,67.63,312.07,68.47,312.01,69.33z"
              />
              <path
                d="M468.84,546.42c0.07,1.28-0.74,2.15-1.61,2.09c-0.91-0.07-1.46-0.59-1.53-1.51c-0.07-0.88,0.79-1.88,1.59-1.65
	C467.94,545.53,468.46,546.15,468.84,546.42z"
              />
              <path
                d="M347.51,375.35c-0.7-0.91-1.32-1.42-1.53-2.06c-0.25-0.79,0.73-1.71,1.58-1.69c0.88,0.01,1.77,0.91,1.5,1.74
	C348.84,373.98,348.21,374.47,347.51,375.35z"
              />
              <path
                d="M407.17,604.45c-0.93-0.06-1.61-0.86-1.5-1.81c0.11-0.95,0.71-1.4,1.62-1.43c0.9-0.03,1.71,0.8,1.67,1.67
	C408.92,603.74,408.04,604.5,407.17,604.45z"
              />
              <path
                d="M496.26,237.66c0,0.86-0.82,1.63-1.71,1.6c-0.93-0.03-1.62-0.78-1.54-1.72c0.08-0.93,0.63-1.42,1.54-1.47
	C495.47,236.03,496.27,236.78,496.26,237.66z"
              />
              <path
                d="M420.22,253.32c0.92,0,1.72,0.78,1.69,1.65c-0.03,0.86-0.9,1.67-1.77,1.58c-0.9-0.09-1.46-0.6-1.52-1.54
	C418.56,254.06,419.26,253.33,420.22,253.32z"
              />
              <path
                d="M416.81,237.22c-0.03,0.94-0.8,1.65-1.72,1.59c-0.88-0.06-1.68-0.94-1.55-1.79c0.14-0.9,0.71-1.41,1.63-1.45
	C416.12,235.53,416.83,236.27,416.81,237.22z"
              />
              <path
                d="M395.9,412.07c0.07,1.28-0.74,2.15-1.61,2.09c-0.91-0.07-1.46-0.59-1.53-1.51c-0.07-0.88,0.79-1.88,1.59-1.65
	C395,411.18,395.53,411.79,395.9,412.07z"
              />
              <path
                d="M410.33,600.62c-0.88-0.03-1.67-0.9-1.61-1.76c0.06-0.89,0.91-1.59,1.84-1.48c0.92,0.11,1.43,0.65,1.45,1.59
	C412.02,599.91,411.24,600.66,410.33,600.62z"
              />
              <path
                d="M354.72,603.87c-0.06,0.87-0.92,1.59-1.81,1.51c-0.93-0.08-1.58-0.88-1.45-1.82c0.13-0.94,0.74-1.37,1.64-1.38
	C354,602.17,354.78,603.01,354.72,603.87z"
              />
              <path
                d="M247.08,253.8c0.91,0.04,1.67,0.87,1.59,1.74c-0.08,0.88-0.96,1.62-1.84,1.49c-0.9-0.14-1.43-0.67-1.44-1.62
	C245.39,254.46,246.13,253.76,247.08,253.8z"
              />
              <path
                d="M340.82,359.62c-0.05,0.86-0.93,1.62-1.81,1.54c-0.93-0.08-1.59-0.88-1.48-1.82c0.12-0.95,0.73-1.39,1.64-1.41
	C340.06,357.91,340.87,358.77,340.82,359.62z"
              />
              <path
                d="M508.24,603.28c-0.69-0.91-1.31-1.43-1.52-2.07c-0.25-0.79,0.74-1.7,1.59-1.68c0.88,0.02,1.76,0.92,1.49,1.75
	C509.59,601.91,508.95,602.4,508.24,603.28z"
              />
              <path
                d="M440.04,565.84c-0.75-0.84-1.41-1.3-1.65-1.92c-0.32-0.82,0.52-1.75,1.41-1.82c0.85-0.06,1.88,0.8,1.67,1.6
	C441.3,564.36,440.7,564.9,440.04,565.84z"
              />
              <path
                d="M403.88,605.07c0.92-0.02,1.71,0.73,1.7,1.6c-0.01,0.86-0.83,1.65-1.72,1.58c-0.9-0.07-1.46-0.55-1.53-1.49
	C402.25,605.81,402.92,605.09,403.88,605.07z"
              />
              <path
                d="M448.71,563.28c-0.03,0.88-0.91,1.68-1.77,1.54c-0.89-0.15-1.43-0.71-1.4-1.64c0.03-0.93,0.6-1.46,1.5-1.55
	C447.89,561.54,448.74,562.42,448.71,563.28z"
              />
              <path
                d="M399.54,614.54c-0.87,0.72-1.35,1.36-1.98,1.58c-0.8,0.28-1.72-0.65-1.73-1.53c-0.01-0.86,0.9-1.84,1.69-1.58
	C398.15,613.22,398.66,613.85,399.54,614.54z"
              />
              <path
                d="M400.52,442.86c0.92-0.02,1.73,0.74,1.71,1.62c-0.01,0.86-0.87,1.69-1.74,1.61c-0.9-0.08-1.48-0.57-1.55-1.52
	C398.88,443.61,399.56,442.88,400.52,442.86z"
              />
              <path
                d="M440.29,446.08c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.43c0.91-0.03,1.71,0.76,1.68,1.63
	C442.03,445.38,441.18,446.13,440.29,446.08z"
              />
              <path
                d="M274.09,256.79c0.03,0.86-0.76,1.66-1.64,1.67c-0.93,0-1.65-0.73-1.6-1.67c0.04-0.93,0.56-1.44,1.48-1.52
	C273.26,255.2,274.06,255.9,274.09,256.79z"
              />
              <path
                d="M431.67,409.38c0.03,0.94-0.68,1.69-1.61,1.69c-0.89,0-1.73-0.81-1.65-1.67c0.08-0.9,0.6-1.45,1.52-1.54
	C430.9,407.77,431.64,408.42,431.67,409.38z"
              />
              <path
                d="M499.21,581.87c-0.89,0.02-1.7-0.74-1.71-1.6c0-0.88,0.79-1.63,1.71-1.58c0.91,0.05,1.46,0.53,1.54,1.46
	C500.83,581.09,500.15,581.84,499.21,581.87z"
              />
              <path
                d="M431.67,575.45c0.01,0.93-0.72,1.65-1.65,1.63c-0.89-0.03-1.7-0.85-1.59-1.71c0.11-0.88,0.64-1.42,1.56-1.48
	C430.94,573.82,431.66,574.51,431.67,575.45z"
              />
              <path
                d="M425.75,131.61c0,0.86-0.84,1.66-1.72,1.64c-0.93-0.02-1.64-0.8-1.56-1.75c0.07-0.95,0.64-1.43,1.56-1.49
	C424.94,129.96,425.75,130.73,425.75,131.61z"
              />
              <path
                d="M267.59,324.37c0.92-0.03,1.71,0.71,1.71,1.58c0,0.86-0.82,1.66-1.71,1.6c-0.9-0.06-1.47-0.54-1.54-1.48
	C265.97,325.11,266.63,324.39,267.59,324.37z"
              />
              <path
                d="M396.15,435.53c-0.93-0.05-1.61-0.83-1.51-1.77c0.1-0.94,0.68-1.4,1.59-1.43c0.91-0.03,1.71,0.76,1.68,1.63
	C397.88,434.82,397.03,435.58,396.15,435.53z"
              />
              <path
                d="M414.42,121.73c-0.86,0.73-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.63-1.75-1.51c-0.02-0.86,0.88-1.85,1.67-1.6
	C413.01,120.41,413.52,121.04,414.42,121.73z"
              />
              <path
                d="M285.83,326.28c0.92-0.02,1.71,0.72,1.7,1.59c-0.01,0.86-0.83,1.65-1.71,1.59c-0.9-0.07-1.46-0.54-1.54-1.48
	C284.21,327.03,284.87,326.31,285.83,326.28z"
              />
              <path
                d="M472.03,233.72c-0.26-0.37-0.86-0.91-1.02-1.56c-0.21-0.84,0.76-1.73,1.62-1.63c0.9,0.11,1.43,0.68,1.51,1.57
	C474.21,232.98,473.33,233.79,472.03,233.72z"
              />
              <path
                d="M279.64,384.21c-0.92,0-1.66-0.76-1.62-1.7c0.05-0.95,0.58-1.46,1.51-1.54c0.93-0.08,1.75,0.65,1.78,1.54
	C281.34,383.38,280.52,384.21,279.64,384.21z"
              />
              <path
                d="M379.39,258.49c-0.86,0.72-1.34,1.37-1.97,1.6c-0.8,0.29-1.73-0.64-1.75-1.51c-0.02-0.86,0.89-1.85,1.67-1.6
	C377.99,257.17,378.5,257.8,379.39,258.49z"
              />
              <path
                d="M427.84,406.07c0.01,0.93-0.75,1.69-1.67,1.66c-0.88-0.03-1.71-0.89-1.61-1.74c0.11-0.9,0.65-1.44,1.57-1.51
	C427.1,404.41,427.84,405.11,427.84,406.07z"
              />
              <path
                d="M462.7,515.05c-0.03,0.86-0.89,1.64-1.77,1.59c-0.93-0.05-1.62-0.84-1.52-1.79c0.1-0.95,0.68-1.41,1.6-1.45
	C461.93,513.36,462.72,514.17,462.7,515.05z"
              />
              <path
                d="M270.24,110.68c-0.88-0.02-1.66-0.85-1.61-1.7c0.05-0.89,0.86-1.58,1.8-1.48c0.92,0.09,1.42,0.62,1.45,1.55
	C271.9,109.97,271.16,110.7,270.24,110.68z"
              />
              <path
                d="M527.45,303.4c0,0.86-0.82,1.63-1.71,1.6c-0.93-0.03-1.62-0.78-1.54-1.72c0.08-0.93,0.63-1.42,1.54-1.47
	C526.66,301.77,527.46,302.52,527.45,303.4z"
              />
              <path
                d="M395.24,464.93c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C393.59,465.69,394.27,464.96,395.24,464.93z"
              />
              <path
                d="M416.13,229.93c0.02,0.87-0.76,1.66-1.66,1.66c-0.93,0-1.64-0.74-1.59-1.67c0.05-0.93,0.58-1.44,1.49-1.51
	C415.31,228.33,416.1,229.04,416.13,229.93z"
              />
              <path
                d="M296.78,436.11c-0.36,0.26-0.89,0.87-1.53,1.04c-0.82,0.22-1.7-0.78-1.6-1.64c0.1-0.9,0.65-1.45,1.56-1.52
	C296.05,433.91,296.85,434.8,296.78,436.11z"
              />
              <path
                d="M568.09,623.3c0.91,0.04,1.65,0.84,1.58,1.71c-0.08,0.87-0.93,1.59-1.82,1.46c-0.9-0.13-1.41-0.65-1.42-1.59
	C566.41,623.95,567.14,623.26,568.09,623.3z"
              />
              <path
                d="M473.37,557.97c-0.36,0.26-0.88,0.88-1.52,1.06c-0.82,0.23-1.71-0.76-1.63-1.62c0.09-0.9,0.64-1.45,1.54-1.54
	C472.61,555.78,473.42,556.66,473.37,557.97z"
              />
              <path
                d="M373.45,486.74c-0.03,0.86-0.89,1.64-1.77,1.59c-0.93-0.05-1.62-0.84-1.52-1.79c0.1-0.95,0.68-1.41,1.6-1.45
	C372.67,485.05,373.47,485.86,373.45,486.74z"
              />
              <path
                d="M362.98,261.33c-0.89,0.05-1.72-0.69-1.75-1.56c-0.03-0.87,0.76-1.65,1.67-1.62c0.91,0.03,1.48,0.49,1.58,1.42
	C364.58,260.51,363.91,261.28,362.98,261.33z"
              />
              <path
                d="M509.37,383.74c0.36-0.26,0.88-0.87,1.53-1.06c0.79-0.22,1.71,0.8,1.61,1.65c-0.11,0.89-0.63,1.46-1.55,1.52
	C510.07,385.91,509.3,385.08,509.37,383.74z"
              />
              <path
                d="M443.21,578.08c-0.87,0.71-1.35,1.36-1.99,1.57c-0.8,0.28-1.72-0.66-1.73-1.53c-0.01-0.86,0.91-1.84,1.69-1.58
	C441.82,576.75,442.32,577.38,443.21,578.08z"
              />
              <path
                d="M389.44,87.89c0.03,0.94-0.67,1.67-1.6,1.67c-0.89,0-1.72-0.79-1.64-1.66c0.08-0.89,0.59-1.44,1.51-1.53
	C388.67,86.29,389.4,86.94,389.44,87.89z"
              />
              <path
                d="M383.63,328.67c0.96,0.06,1.61,0.81,1.5,1.78c-0.11,0.95-0.72,1.4-1.61,1.45c-0.87,0.05-1.69-0.82-1.67-1.68
	C381.88,329.32,382.69,328.61,383.63,328.67z"
              />
              <path
                d="M464.74,519.99c-0.93-0.07-1.6-0.87-1.49-1.81c0.11-0.95,0.71-1.4,1.62-1.42c0.9-0.03,1.71,0.82,1.66,1.68
	C466.49,519.3,465.62,520.06,464.74,519.99z"
              />
              <path
                d="M371.43,630.88c0.06,1.28-0.75,2.15-1.62,2.08c-0.91-0.07-1.45-0.6-1.53-1.52c-0.07-0.88,0.8-1.88,1.6-1.65
	C370.53,629.98,371.06,630.61,371.43,630.88z"
              />
              <path
                d="M299.65,345.44c-0.36,0.25-0.9,0.86-1.55,1.02c-0.83,0.21-1.69-0.8-1.58-1.66c0.11-0.9,0.67-1.44,1.58-1.5
	C298.96,343.24,299.74,344.12,299.65,345.44z"
              />
              <path
                d="M436.36,356.36c-0.88,0.02-1.71-0.76-1.72-1.62c0-0.88,0.8-1.64,1.72-1.59c0.92,0.05,1.47,0.53,1.55,1.47
	C437.98,355.57,437.29,356.33,436.36,356.36z"
              />
              <path
                d="M352.97,210.24c0.04,0.94-0.66,1.68-1.59,1.68c-0.89,0-1.72-0.79-1.65-1.65c0.08-0.89,0.58-1.45,1.5-1.53
	C352.2,208.64,352.93,209.29,352.97,210.24z"
              />
              <path
                d="M350.41,221.28c0.04,0.86-0.77,1.7-1.65,1.71c-0.92,0.01-1.67-0.75-1.63-1.69c0.04-0.95,0.57-1.46,1.49-1.55
	C349.55,219.66,350.37,220.38,350.41,221.28z"
              />
              <path
                d="M438.39,527.89c0.04,0.94-0.67,1.69-1.6,1.69c-0.89,0-1.73-0.8-1.66-1.67c0.08-0.9,0.59-1.45,1.51-1.54
	C437.61,526.28,438.35,526.94,438.39,527.89z"
              />
              <path
                d="M300.09,201.85c-0.88,0.03-1.72-0.74-1.73-1.6c-0.01-0.88,0.79-1.65,1.71-1.6c0.91,0.04,1.48,0.52,1.56,1.46
	C301.71,201.04,301.03,201.81,300.09,201.85z"
              />
              <path
                d="M347.99,485.19c0.04,0.87-0.74,1.67-1.64,1.68c-0.93,0.01-1.65-0.72-1.61-1.66c0.04-0.93,0.56-1.45,1.48-1.53
	C347.15,483.6,347.95,484.3,347.99,485.19z"
              />
              <path
                d="M420.93,200.75c-0.03,0.87-0.86,1.61-1.75,1.56c-0.93-0.05-1.6-0.82-1.5-1.76c0.1-0.93,0.67-1.4,1.58-1.43
	C420.17,199.1,420.96,199.88,420.93,200.75z"
              />
              <path
                d="M400.04,601.69c0.92-0.02,1.73,0.73,1.72,1.62c-0.01,0.86-0.86,1.69-1.73,1.62c-0.9-0.08-1.48-0.56-1.55-1.51
	C398.39,602.45,399.07,601.71,400.04,601.69z"
              />
              <path
                d="M301.86,220.24c0.96-0.05,1.69,0.62,1.69,1.56c0,0.93-0.51,1.47-1.4,1.61c-0.89,0.14-1.75-0.56-1.84-1.44
	C300.22,221.1,300.95,220.29,301.86,220.24z"
              />
              <path
                d="M386.44,610.34c0.96,0.02,1.64,0.74,1.57,1.7c-0.07,0.94-0.64,1.43-1.54,1.5c-0.88,0.07-1.72-0.73-1.73-1.59
	C384.72,611.07,385.51,610.32,386.44,610.34z"
              />
              <path
                d="M387.07,471.08c-0.87,0.71-1.35,1.36-1.99,1.57c-0.8,0.28-1.72-0.66-1.73-1.53c-0.01-0.86,0.91-1.84,1.69-1.58
	C385.68,469.75,386.18,470.38,387.07,471.08z"
              />
              <path
                d="M278.37,203.78c-0.88-0.03-1.66-0.88-1.6-1.74c0.06-0.89,0.9-1.57,1.83-1.47c0.92,0.11,1.42,0.64,1.44,1.58
	C280.06,203.08,279.3,203.81,278.37,203.78z"
              />
              <path
                d="M445.54,527.74c-0.36,0.26-0.88,0.88-1.53,1.05c-0.82,0.23-1.71-0.76-1.62-1.63c0.09-0.9,0.64-1.45,1.55-1.54
	C444.79,525.55,445.59,526.43,445.54,527.74z"
              />
              <path
                d="M360.29,439.17c-0.71-0.88-1.34-1.37-1.55-2.01c-0.27-0.83,0.61-1.73,1.49-1.75c0.85-0.02,1.83,0.9,1.58,1.69
	C361.6,437.75,360.98,438.26,360.29,439.17z"
              />
              <path
                d="M375.73,614.65c0.97-0.06,1.72,0.61,1.73,1.56c0.01,0.95-0.5,1.49-1.4,1.65c-0.89,0.16-1.77-0.55-1.87-1.44
	C374.08,615.54,374.81,614.71,375.73,614.65z"
              />
              <path
                d="M392.06,468.77c0.96-0.05,1.71,0.63,1.71,1.59c0,0.95-0.52,1.49-1.42,1.63c-0.88,0.15-1.77-0.58-1.86-1.47
	C390.41,469.65,391.15,468.82,392.06,468.77z"
              />
              <path
                d="M352.24,468.77c0.96-0.05,1.71,0.63,1.71,1.59c0,0.95-0.52,1.49-1.42,1.63c-0.88,0.15-1.77-0.58-1.86-1.47
	C350.58,469.65,351.32,468.82,352.24,468.77z"
              />
              <path
                d="M426.78,348.01c0-0.95,0.73-1.65,1.69-1.58c0.92,0.06,1.46,0.6,1.57,1.49c0.11,0.85-0.72,1.7-1.6,1.73
	C427.52,349.68,426.78,348.95,426.78,348.01z"
              />
              <path
                d="M461.09,220.9c0.3,0.35,0.96,0.82,1.19,1.45c0.31,0.83-0.53,1.77-1.42,1.79c-0.94,0.02-1.48-0.51-1.66-1.39
	C459.03,221.91,459.82,220.99,461.09,220.9z"
              />
              <path
                d="M440.08,589.19c-0.03,0.88-0.91,1.68-1.77,1.54c-0.89-0.15-1.43-0.71-1.4-1.63c0.03-0.93,0.6-1.46,1.49-1.55
	C439.25,587.45,440.1,588.33,440.08,589.19z"
              />
              <path
                d="M365.55,582.87c-0.92,0.01-1.67-0.75-1.63-1.69c0.04-0.95,0.57-1.46,1.49-1.55c0.93-0.09,1.75,0.63,1.79,1.53
	C367.24,582.02,366.43,582.86,365.55,582.87z"
              />
              <path
                d="M465.56,523.97c0.95-0.01,1.66,0.7,1.61,1.65c-0.05,0.93-0.58,1.44-1.48,1.54c-0.89,0.09-1.73-0.66-1.77-1.53
	C463.87,524.77,464.67,523.98,465.56,523.97z"
              />
              <path
                d="M433.9,351.32c0.03,0.86-0.79,1.7-1.66,1.7c-0.92,0.01-1.66-0.76-1.62-1.7c0.04-0.95,0.58-1.46,1.5-1.54
	C433.05,349.7,433.87,350.43,433.9,351.32z"
              />
              <path
                d="M450.14,557.76c1.26,0.08,2.04,0.99,1.88,1.83c-0.17,0.87-0.71,1.4-1.64,1.39c-0.89-0.01-1.73-0.95-1.43-1.77
	C449.19,558.58,449.84,558.11,450.14,557.76z"
              />
              <path
                d="M360.38,565.05c-0.86-0.06-1.62-0.92-1.53-1.74c0.1-0.87,0.94-1.52,1.85-1.39c0.91,0.13,1.37,0.69,1.37,1.6
	C362.07,564.45,361.32,565.11,360.38,565.05z"
              />
              <path
                d="M436.92,422.62c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C437.64,424.78,436.86,423.94,436.92,422.62z"
              />
              <path
                d="M335.51,397.56c-1.36,0.01-2.17-0.78-2.06-1.65c0.12-0.88,0.65-1.43,1.58-1.45c0.95-0.03,1.79,0.75,1.54,1.64
	C336.41,396.72,335.78,397.22,335.51,397.56z"
              />
              <path
                d="M366.69,574.05c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C365.94,572.42,366.71,573.18,366.69,574.05z"
              />
              <path
                d="M448.35,576.02c0.87,0.03,1.63,0.89,1.51,1.77c-0.12,0.91-0.7,1.44-1.6,1.42c-0.91-0.02-1.44-0.63-1.53-1.51
	C446.63,576.85,447.51,575.99,448.35,576.02z"
              />
              <path
                d="M249,148.94c0.3,0.35,0.95,0.82,1.19,1.44c0.31,0.82-0.53,1.76-1.41,1.78c-0.93,0.02-1.48-0.51-1.65-1.38
	C246.96,149.93,247.73,149.02,249,148.94z"
              />
              <path
                d="M375.13,484.44c-0.93,0-1.64-0.72-1.58-1.65c0.05-0.92,0.56-1.43,1.48-1.5c0.93-0.07,1.71,0.62,1.74,1.51
	C376.79,483.66,376.02,484.44,375.13,484.44z"
              />
              <path
                d="M439.64,542.06c-0.1,1.31-0.98,2-1.89,1.85c-0.92-0.15-1.39-0.73-1.34-1.67c0.04-0.95,0.9-1.7,1.8-1.38
	C438.83,541.08,439.3,541.75,439.64,542.06z"
              />
              <path
                d="M439.83,439.11c-0.88-0.04-1.61-0.9-1.48-1.78c0.14-0.91,0.74-1.43,1.62-1.39c0.9,0.04,1.43,0.65,1.51,1.54
	C441.57,438.33,440.7,439.15,439.83,439.11z"
              />
              <path
                d="M277.38,265.83c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C275.24,266.74,276.04,265.88,277.38,265.83z"
              />
              <path
                d="M386.22,364.69c1.35,0.02,2.16,0.84,2.03,1.69c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C385.31,365.51,385.95,365.03,386.22,364.69z"
              />
              <path
                d="M434.67,588.14c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.54-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C436.01,586.73,435.37,587.23,434.67,588.14z"
              />
              <path
                d="M354.88,398.01c-0.05,0.93-0.8,1.58-1.74,1.48c-0.91-0.1-1.4-0.66-1.47-1.54c-0.07-0.87,0.74-1.61,1.65-1.6
	C354.26,396.36,354.93,397.07,354.88,398.01z"
              />
              <path
                d="M452.74,526.31c0.02,1.32-0.78,2.13-1.66,2.03c-0.91-0.1-1.42-0.68-1.49-1.56c-0.07-0.85,0.88-1.83,1.66-1.58
	C451.89,525.41,452.39,526.04,452.74,526.31z"
              />
              <path
                d="M432.43,582.75c-0.05,0.86-0.88,1.56-1.78,1.49c-0.92-0.07-1.58-0.86-1.44-1.78c0.14-0.91,0.71-1.36,1.62-1.37
	C431.72,581.08,432.48,581.89,432.43,582.75z"
              />
              <path
                d="M442.95,554.27c-1.28-0.01-2.1-0.86-1.98-1.72c0.12-0.89,0.67-1.43,1.58-1.48c0.87-0.05,1.77,0.88,1.51,1.71
	C443.86,553.42,443.23,553.92,442.95,554.27z"
              />
              <path
                d="M362.94,583.52c0.24,0.35,0.85,0.88,1,1.52c0.21,0.9-0.67,1.66-1.63,1.58c-0.93-0.07-1.45-0.63-1.52-1.54
	C360.72,584.2,361.56,583.46,362.94,583.52z"
              />
              <path
                d="M442.31,524.11c-0.93,0.71-1.44,1.34-2.07,1.52c-0.87,0.25-1.72-0.62-1.73-1.51c0-0.91,0.83-1.76,1.72-1.51
	C440.87,522.78,441.38,523.41,442.31,524.11z"
              />
              <path
                d="M410.26,348.21c-0.75-0.85-1.42-1.31-1.64-1.92c-0.31-0.88,0.49-1.71,1.44-1.75c0.94-0.04,1.88,0.74,1.66,1.58
	C411.56,346.77,410.94,347.29,410.26,348.21z"
              />
              <path
                d="M307.96,351.32c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C306.49,350.03,307.01,350.64,307.96,351.32z"
              />
              <path
                d="M313.28,522.56c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C312.37,523.38,313.01,522.89,313.28,522.56z"
              />
              <path
                d="M425.8,187.36c-1.38,0.07-2.21-0.66-2.13-1.55c0.07-0.89,0.57-1.46,1.51-1.52c0.96-0.07,1.82,0.65,1.62,1.56
	C426.65,186.48,426.05,187.01,425.8,187.36z"
              />
              <path
                d="M340.44,399.64c-0.36,0.26-0.89,0.86-1.53,1.03c-0.81,0.22-1.69-0.78-1.59-1.64c0.1-0.89,0.65-1.44,1.55-1.51
	C339.71,397.45,340.51,398.34,340.44,399.64z"
              />
              <path
                d="M369.58,483.68c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C368.67,481.66,369.5,482.35,369.58,483.68z"
              />
              <path
                d="M366.69,75.86c-0.36,0.28-0.86,0.93-1.49,1.12c-0.91,0.28-1.72-0.52-1.72-1.48c0-0.94,0.48-1.49,1.42-1.59
	C365.84,73.82,366.65,74.53,366.69,75.86z"
              />
              <path
                d="M316.59,365.24c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C315.12,363.94,315.64,364.55,316.59,365.24z"
              />
              <path
                d="M353.53,249.97c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C352,248.76,352.55,249.34,353.53,249.97z"
              />
              <path
                d="M449.43,523.4c-0.87,0.7-1.36,1.34-1.99,1.55c-0.8,0.27-1.7-0.66-1.71-1.54c0-0.86,0.92-1.82,1.69-1.56
	C448.07,522.06,448.56,522.7,449.43,523.4z"
              />
              <path
                d="M355.19,421.1c-0.89-0.01-1.66-0.81-1.62-1.66c0.04-0.89,0.84-1.57,1.76-1.49c0.92,0.08,1.41,0.6,1.46,1.52
	C356.84,420.4,356.12,421.12,355.19,421.1z"
              />
              <path
                d="M244.65,355.4c-0.72-0.86-1.37-1.34-1.57-1.96c-0.28-0.89,0.53-1.68,1.49-1.69c0.92-0.02,1.85,0.8,1.6,1.62
	C245.98,354,245.35,354.5,244.65,355.4z"
              />
              <path
                d="M449.02,436.65c-0.88,0.77-1.34,1.42-1.95,1.64c-0.85,0.3-1.75-0.5-1.81-1.39c-0.06-0.88,0.74-1.81,1.62-1.61
	C447.5,435.42,448.05,436.01,449.02,436.65z"
              />
              <path
                d="M391.05,248.56c0.89,0.05,1.63,0.87,1.55,1.72c-0.08,0.86-0.94,1.55-1.83,1.42c-0.9-0.13-1.39-0.67-1.39-1.59
	C389.37,249.17,390.1,248.51,391.05,248.56z"
              />
              <path
                d="M352.77,621.9c0.06,0.85-0.71,1.7-1.58,1.69c-0.89-0.01-1.49-0.44-1.63-1.36c-0.14-0.93,0.49-1.71,1.41-1.79
	C351.9,620.36,352.71,621.02,352.77,621.9z"
              />
              <path
                d="M396,425.17c0.26,0.34,0.87,0.85,1.04,1.48c0.24,0.89-0.62,1.67-1.57,1.63c-0.92-0.05-1.46-0.58-1.56-1.48
	C393.82,425.92,394.63,425.14,396,425.17z"
              />
              <path
                d="M320.09,467.85c0.28,0.33,0.94,0.8,1.14,1.42c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.01-1.49-0.51-1.65-1.38
	C317.95,468.75,318.75,467.89,320.09,467.85z"
              />
              <path
                d="M293.08,345.75c-0.97,0.65-1.51,1.24-2.16,1.39c-0.87,0.2-1.69-0.74-1.63-1.62c0.06-0.9,0.95-1.71,1.82-1.41
	C291.73,344.33,292.19,344.99,293.08,345.75z"
              />
              <path
                d="M424.71,599.65c-0.69-0.91-1.32-1.43-1.5-2.07c-0.24-0.83,0.71-1.63,1.64-1.6c0.96,0.03,1.77,0.84,1.47,1.73
	C426.11,598.33,425.44,598.8,424.71,599.65z"
              />
              <path
                d="M380.12,618.05c0.27,0.34,0.9,0.83,1.08,1.45c0.26,0.88-0.58,1.68-1.53,1.66c-0.93-0.02-1.47-0.55-1.6-1.44
	C377.96,618.86,378.77,618.06,380.12,618.05z"
              />
              <path
                d="M433.31,573.21c-0.86-0.06-1.62-0.92-1.53-1.74c0.1-0.87,0.94-1.52,1.85-1.39c0.91,0.13,1.37,0.69,1.37,1.6
	C435,572.6,434.25,573.27,433.31,573.21z"
              />
              <path
                d="M394.84,461.46c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.53-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C396.18,460.05,395.55,460.56,394.84,461.46z"
              />
              <path
                d="M527.4,263.81c0,0.88-0.86,1.7-1.72,1.58c-0.89-0.12-1.44-0.67-1.44-1.59c0-0.92,0.57-1.46,1.45-1.58
	C526.53,262.1,527.4,262.96,527.4,263.81z"
              />
              <path
                d="M463.12,521.82c-0.04,1.31-0.86,2.03-1.78,1.92c-0.93-0.11-1.41-0.65-1.41-1.59c0-0.96,0.81-1.73,1.72-1.45
	C462.28,520.9,462.77,521.54,463.12,521.82z"
              />
              <path
                d="M319.2,527.17c-0.09,1.31-0.96,2-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C318.38,526.2,318.85,526.87,319.2,527.17z"
              />
              <path
                d="M469.51,554.63c-0.36,0.26-0.89,0.86-1.53,1.03c-0.81,0.22-1.69-0.78-1.59-1.64c0.1-0.89,0.65-1.44,1.55-1.51
	C468.79,552.44,469.59,553.33,469.51,554.63z"
              />
              <path
                d="M414.88,610.89c-0.88-0.05-1.61-0.9-1.47-1.79c0.14-0.91,0.74-1.43,1.62-1.39c0.9,0.04,1.43,0.66,1.51,1.54
	C416.62,610.12,415.75,610.93,414.88,610.89z"
              />
              <path
                d="M384.63,615.85c-0.05,0.93-0.8,1.59-1.73,1.49c-0.91-0.1-1.41-0.66-1.48-1.53c-0.07-0.87,0.73-1.62,1.64-1.61
	C384,614.2,384.68,614.91,384.63,615.85z"
              />
              <path
                d="M444.44,180.26c-0.1,1.31-0.98,2-1.89,1.85c-0.92-0.15-1.39-0.73-1.34-1.67c0.04-0.95,0.9-1.7,1.8-1.38
	C443.63,179.29,444.1,179.95,444.44,180.26z"
              />
              <path
                d="M350.6,241.36c0.92-0.02,1.69,0.7,1.69,1.57c0,0.86-0.8,1.63-1.69,1.57c-0.9-0.06-1.45-0.54-1.53-1.46
	C348.99,242.11,349.66,241.39,350.6,241.36z"
              />
              <path
                d="M330.22,364.24c-0.36,0.28-0.85,0.93-1.49,1.12c-0.91,0.28-1.72-0.52-1.72-1.47c0-0.93,0.48-1.49,1.42-1.59
	C329.37,362.2,330.18,362.91,330.22,364.24z"
              />
              <path
                d="M432.04,548.5c0.24,0.35,0.84,0.88,0.99,1.51c0.21,0.9-0.67,1.64-1.62,1.57c-0.93-0.07-1.44-0.63-1.51-1.52
	C429.84,549.17,430.66,548.44,432.04,548.5z"
              />
              <path
                d="M383.17,475.37c-0.35,0.28-0.85,0.91-1.48,1.11c-0.8,0.26-1.75-0.69-1.68-1.56c0.07-0.88,0.58-1.47,1.49-1.58
	C382.35,473.24,383.18,474.07,383.17,475.37z"
              />
              <path
                d="M462.26,429.05c-0.38,0.22-0.96,0.78-1.61,0.88c-0.84,0.13-1.6-0.92-1.42-1.77c0.18-0.88,0.75-1.39,1.67-1.36
	C461.78,426.83,462.46,427.74,462.26,429.05z"
              />
              <path
                d="M442.76,521.82c-0.25-0.35-0.87-0.86-1.02-1.5c-0.23-0.9,0.63-1.65,1.59-1.6c0.93,0.05,1.45,0.61,1.54,1.5
	C444.96,521.11,444.14,521.87,442.76,521.82z"
              />
              <path
                d="M337.42,363.47c-0.06,0.86-0.89,1.55-1.78,1.48c-0.92-0.08-1.57-0.87-1.43-1.78c0.14-0.91,0.72-1.36,1.62-1.36
	C336.72,361.8,337.47,362.61,337.42,363.47z"
              />
              <path
                d="M439.43,575.12c-0.97,0.65-1.51,1.24-2.16,1.39c-0.87,0.2-1.69-0.74-1.63-1.62c0.06-0.9,0.95-1.71,1.82-1.41
	C438.08,573.69,438.54,574.35,439.43,575.12z"
              />
              <path
                d="M368.61,441.95c0.27,0.34,0.9,0.83,1.08,1.45c0.26,0.88-0.58,1.68-1.53,1.66c-0.93-0.02-1.47-0.55-1.6-1.44
	C366.44,442.76,367.25,441.96,368.61,441.95z"
              />
              <path
                d="M374.28,82.48c-0.37,0.26-0.91,0.86-1.56,1.02c-0.83,0.21-1.64-0.77-1.55-1.67c0.09-0.92,0.67-1.42,1.56-1.48
	C373.57,80.29,374.38,81.22,374.28,82.48z"
              />
              <path
                d="M497.48,410.72c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C495.96,409.51,496.5,410.08,497.48,410.72z"
              />
              <path
                d="M428.12,200.57c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C427.21,198.56,428.04,199.25,428.12,200.57z"
              />
              <path
                d="M354.17,557.01c-0.01,0.88-0.87,1.69-1.73,1.56c-0.89-0.13-1.43-0.68-1.43-1.6c0.01-0.92,0.58-1.45,1.46-1.57
	C353.31,555.29,354.18,556.15,354.17,557.01z"
              />
              <path
                d="M399.8,415.71c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C399.04,414.08,399.82,414.84,399.8,415.71z"
              />
              <path
                d="M431.75,568.1c-0.88,0.78-1.33,1.44-1.95,1.67c-0.85,0.31-1.77-0.5-1.84-1.39c-0.07-0.88,0.74-1.82,1.61-1.64
	C430.22,566.89,430.77,567.47,431.75,568.1z"
              />
              <path
                d="M270.74,260.38c-0.09,1.31-0.96,2-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C269.92,259.41,270.39,260.08,270.74,260.38z"
              />
              <path
                d="M434.35,577.25c0.27,0.34,0.91,0.83,1.09,1.46c0.26,0.89-0.59,1.7-1.54,1.68c-0.93-0.02-1.48-0.56-1.61-1.45
	C432.17,578.07,432.98,577.26,434.35,577.25z"
              />
              <path
                d="M404.59,346.92c-0.35,0.29-0.83,0.94-1.45,1.15c-0.9,0.3-1.73-0.47-1.75-1.41c-0.02-0.92,0.44-1.5,1.37-1.62
	C403.69,344.92,404.52,345.6,404.59,346.92z"
              />
              <path
                d="M386.92,617.28c0.87,0.03,1.63,0.89,1.51,1.77c-0.12,0.91-0.7,1.44-1.6,1.42c-0.91-0.02-1.44-0.62-1.53-1.51
	C385.21,618.12,386.08,617.26,386.92,617.28z"
              />
              <path
                d="M337.43,70.36c-0.09,1.31-0.96,2.01-1.87,1.86c-0.92-0.15-1.39-0.72-1.36-1.66c0.04-0.95,0.88-1.71,1.79-1.39
	C336.62,69.4,337.09,70.06,337.43,70.36z"
              />
              <path
                d="M441.28,430.31c-0.87,0.7-1.36,1.34-1.99,1.55c-0.8,0.27-1.7-0.66-1.71-1.54c0-0.86,0.92-1.82,1.69-1.56
	C439.91,428.98,440.4,429.61,441.28,430.31z"
              />
              <path
                d="M430.51,395.07c-0.02,0.86-0.83,1.59-1.72,1.55c-0.93-0.04-1.6-0.79-1.5-1.72c0.1-0.92,0.65-1.39,1.56-1.42
	C429.75,393.45,430.53,394.2,430.51,395.07z"
              />
              <path
                d="M345.75,618.4c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C346.47,620.55,345.69,619.71,345.75,618.4z"
              />
              <path
                d="M431.75,402.18c-0.91,0.73-1.4,1.36-2.02,1.56c-0.87,0.27-1.73-0.56-1.75-1.46c-0.03-0.89,0.8-1.77,1.68-1.55
	C430.28,400.9,430.8,401.5,431.75,402.18z"
              />
              <path
                d="M344.61,70.23c-0.02,0.86-0.83,1.59-1.73,1.54c-0.93-0.05-1.6-0.81-1.49-1.73c0.11-0.91,0.66-1.39,1.57-1.41
	C343.87,68.6,344.64,69.36,344.61,70.23z"
              />
              <path
                d="M459.3,519.17c-0.35,0.29-0.82,0.95-1.44,1.16c-0.9,0.31-1.74-0.46-1.77-1.4c-0.03-0.92,0.42-1.5,1.36-1.63
	C458.38,517.18,459.21,517.85,459.3,519.17z"
              />
              <path
                d="M494.13,414.55c-0.88,0.78-1.33,1.44-1.95,1.67c-0.85,0.31-1.77-0.5-1.84-1.39c-0.07-0.88,0.74-1.82,1.61-1.64
	C492.6,413.34,493.15,413.92,494.13,414.55z"
              />
              <path d="M663.11,517.33c0.38,1.22,0.7,2.26,1.02,3.29c-1.62,0.52-2.18,0.35-2.57-0.67C661.15,518.84,661.51,518.14,663.11,517.33z" />
              <path
                d="M534.5,615.36c1.25,0,2.1,0.87,1.99,1.72c-0.12,0.89-0.65,1.44-1.57,1.48c-0.88,0.05-1.78-0.85-1.52-1.69
	C533.6,616.23,534.22,615.73,534.5,615.36z"
              />
              <path
                d="M438.19,567.42c-0.05,1.31-0.9,2.03-1.81,1.92c-0.93-0.12-1.42-0.67-1.4-1.61c0.01-0.96,0.82-1.73,1.74-1.44
	C437.34,566.49,437.83,567.14,438.19,567.42z"
              />
              <path
                d="M347.54,481.3c0.96-0.65,1.49-1.25,2.13-1.4c0.88-0.21,1.69,0.7,1.64,1.59c-0.05,0.89-0.93,1.71-1.79,1.42
	C348.9,482.7,348.43,482.06,347.54,481.3z"
              />
              <path
                d="M407.26,611.57c-0.25-0.35-0.87-0.87-1.03-1.51c-0.23-0.91,0.64-1.67,1.6-1.61c0.94,0.06,1.46,0.61,1.55,1.51
	C409.46,610.84,408.63,611.61,407.26,611.57z"
              />
              <path
                d="M392.17,429.19c0.88,0.01,1.64,0.85,1.54,1.74c-0.11,0.92-0.68,1.45-1.57,1.45c-0.91,0-1.45-0.59-1.56-1.48
	C390.48,430.05,391.33,429.18,392.17,429.19z"
              />
              <path
                d="M346.49,8.88c0.65-0.46,1.16-1.03,1.78-1.21c0.49-0.14,1.29,0.01,1.65,0.35c0.53,0.49,0.11,1.64-0.66,1.99
	C348.21,10.48,347.26,10.43,346.49,8.88z"
              />
              <path
                d="M387.95,252.4c0.94,0.06,1.59,0.81,1.47,1.74c-0.12,0.92-0.7,1.38-1.59,1.41c-0.89,0.03-1.67-0.78-1.63-1.64
	C386.24,253.03,387.02,252.35,387.95,252.4z"
              />
              <path
                d="M503.44,403.15c0.03,0.86-0.75,1.66-1.64,1.63c-0.89-0.03-1.47-0.49-1.59-1.41c-0.12-0.93,0.54-1.68,1.48-1.74
	C502.62,401.58,503.41,402.26,503.44,403.15z"
              />
              <path
                d="M437.03,546.05c-0.88,0.77-1.35,1.43-1.96,1.65c-0.85,0.31-1.76-0.52-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C435.51,544.82,436.05,545.4,437.03,546.05z"
              />
              <path
                d="M450.45,567.9c-0.26-0.35-0.88-0.87-1.04-1.51c-0.23-0.91,0.63-1.67,1.59-1.62c0.94,0.05,1.46,0.61,1.56,1.5
	C452.65,567.17,451.82,567.94,450.45,567.9z"
              />
              <path
                d="M363.48,305.19c0.94,0.06,1.59,0.81,1.47,1.74c-0.12,0.92-0.7,1.38-1.59,1.41c-0.89,0.03-1.67-0.78-1.63-1.64
	C361.77,305.81,362.55,305.13,363.48,305.19z"
              />
              <path
                d="M392.45,436.18c1.36,0.02,2.17,0.85,2.04,1.71c-0.14,0.89-0.69,1.42-1.62,1.44c-0.95,0.01-1.79-0.8-1.52-1.69
	C391.54,437,392.18,436.51,392.45,436.18z"
              />
              <path
                d="M255.65,413.61c-0.88,0.77-1.34,1.43-1.96,1.66c-0.85,0.31-1.77-0.51-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C254.13,412.38,254.67,412.97,255.65,413.61z"
              />
              <path
                d="M366.18,321.16c-0.01,0.9-0.82,1.62-1.74,1.53c-0.92-0.09-1.45-0.63-1.43-1.56c0.01-0.93,0.53-1.46,1.46-1.53
	C365.41,319.54,366.19,320.27,366.18,321.16z"
              />
              <path
                d="M465.02,559.55c-1.28-0.01-2.1-0.86-1.98-1.72c0.12-0.89,0.67-1.43,1.58-1.48c0.87-0.05,1.77,0.88,1.51,1.71
	C465.93,558.69,465.3,559.19,465.02,559.55z"
              />
              <path
                d="M360.47,351.99c-1.36,0.01-2.18-0.79-2.07-1.67c0.12-0.89,0.65-1.43,1.59-1.47c0.96-0.03,1.8,0.75,1.55,1.65
	C361.37,351.14,360.73,351.64,360.47,351.99z"
              />
              <path
                d="M370.82,450.65c-0.92,0.74-1.4,1.37-2.03,1.57c-0.86,0.27-1.74-0.58-1.76-1.47c-0.03-0.89,0.8-1.78,1.69-1.56
	C369.34,449.36,369.86,449.96,370.82,450.65z"
              />
              <path
                d="M437.01,536.71c-0.25-0.35-0.87-0.87-1.03-1.51c-0.23-0.91,0.64-1.67,1.6-1.61c0.94,0.06,1.46,0.61,1.55,1.51
	C439.21,535.99,438.38,536.76,437.01,536.71z"
              />
              <path
                d="M468.21,532.67c-0.9,0.74-1.38,1.38-2,1.58c-0.86,0.28-1.74-0.55-1.77-1.44c-0.04-0.88,0.78-1.78,1.66-1.57
	C466.73,531.41,467.26,532.01,468.21,532.67z"
              />
              <path
                d="M380.46,339.73c1.36,0.02,2.17,0.85,2.04,1.71c-0.14,0.89-0.69,1.42-1.62,1.44c-0.95,0.01-1.79-0.8-1.52-1.69
	C379.54,340.56,380.19,340.07,380.46,339.73z"
              />
              <path
                d="M445.38,440.73c-0.06,0.86-0.89,1.55-1.78,1.48c-0.92-0.08-1.57-0.87-1.43-1.78c0.14-0.91,0.72-1.36,1.62-1.36
	C444.68,439.05,445.44,439.87,445.38,440.73z"
              />
              <path
                d="M620.54,628.09c-0.35,0.3-0.82,0.96-1.45,1.18c-0.9,0.31-1.75-0.46-1.78-1.41c-0.03-0.93,0.43-1.51,1.36-1.64
	C619.61,626.09,620.45,626.77,620.54,628.09z"
              />
              <path
                d="M312.76,322.1c-0.93,0.71-1.44,1.34-2.07,1.52c-0.87,0.25-1.72-0.62-1.73-1.51c0-0.91,0.83-1.76,1.72-1.51
	C311.32,320.77,311.82,321.4,312.76,322.1z"
              />
              <path
                d="M261.88,193.01c-0.92,0.71-1.42,1.34-2.04,1.52c-0.87,0.26-1.72-0.59-1.73-1.49c-0.01-0.9,0.81-1.76,1.7-1.52
	C260.44,191.7,260.95,192.32,261.88,193.01z"
              />
              <path
                d="M384.72,109.96c-0.91,0.73-1.4,1.36-2.02,1.56c-0.87,0.27-1.73-0.56-1.75-1.46c-0.03-0.89,0.8-1.77,1.68-1.54
	C383.26,108.68,383.78,109.28,384.72,109.96z"
              />
              <path
                d="M593.71,151.02l-0.11-0.21c0-0.98,0-1.95,0.01-2.93c0,0-0.03,0.08-0.03,0.08c0.67-0.35,1.26-0.25,1.52,0.49
	c0.3,0.86,0.48,1.77,0.83,3.14C594.93,151.33,594.32,151.17,593.71,151.02z"
              />
              <path
                d="M370.01,261.35c-0.68-0.91-1.31-1.42-1.49-2.06c-0.23-0.83,0.7-1.62,1.63-1.59c0.95,0.03,1.76,0.84,1.46,1.72
	C371.4,260.03,370.74,260.5,370.01,261.35z"
              />
              <path
                d="M376.74,375.66c0.73,0.86,1.39,1.33,1.6,1.95c0.29,0.88-0.52,1.7-1.48,1.73c-0.93,0.03-1.87-0.78-1.63-1.62
	C375.41,377.08,376.04,376.57,376.74,375.66z"
              />
              <path
                d="M430.31,593.74c-0.95,0.68-1.46,1.29-2.09,1.46c-0.89,0.23-1.7-0.64-1.68-1.54c0.02-0.91,0.86-1.73,1.75-1.46
	C428.91,592.39,429.4,593.02,430.31,593.74z"
              />
              <path
                d="M394.31,264.37c-0.87,0.77-1.33,1.43-1.94,1.66c-0.85,0.31-1.76-0.49-1.83-1.38c-0.07-0.87,0.73-1.81,1.6-1.62
	C392.79,263.16,393.34,263.73,394.31,264.37z"
              />
              <path
                d="M298.07,449.73c0.01,0.86-0.78,1.62-1.67,1.6c-0.93-0.02-1.63-0.75-1.55-1.68c0.07-0.92,0.6-1.42,1.51-1.47
	C297.28,448.13,298.06,448.85,298.07,449.73z"
              />
              <path
                d="M617.6,257.33C617.6,257.33,617.6,257.33,617.6,257.33c-0.95-1.09-0.61-2.26-0.24-3.43c0,0-0.08,0.07-0.08,0.07
	c0.52-0.03,1.04-0.06,1.77-0.11c0.09,0.93,0.25,1.75,0.24,2.57C619.28,257.66,618.61,257.88,617.6,257.33z"
              />
              <path
                d="M416.98,256.66c0.76,0.84,1.43,1.29,1.66,1.91c0.32,0.87-0.48,1.72-1.43,1.77c-0.93,0.05-1.89-0.73-1.67-1.57
	C415.7,258.12,416.32,257.59,416.98,256.66z"
              />
              <path
                d="M444.77,579.85c1.25,0,2.1,0.87,1.99,1.72c-0.12,0.89-0.65,1.44-1.57,1.48c-0.88,0.05-1.78-0.85-1.52-1.69
	C443.86,580.73,444.49,580.22,444.77,579.85z"
              />
              <path
                d="M466.94,565.16c-0.01,0.88-0.87,1.69-1.73,1.56c-0.89-0.13-1.43-0.68-1.43-1.6c0.01-0.92,0.58-1.45,1.46-1.57
	C466.08,563.45,466.95,564.31,466.94,565.16z"
              />
              <path
                d="M434.96,405.9c-0.36,0.25-0.9,0.85-1.55,1.01c-0.81,0.2-1.67-0.81-1.56-1.67c0.12-0.89,0.67-1.43,1.57-1.48
	C434.28,403.71,435.05,404.6,434.96,405.9z"
              />
              <path
                d="M303.84,593.99c-0.89,0.67-1.42,1.36-2.06,1.48c-0.87,0.17-1.46-0.49-1.54-1.42c-0.07-0.9,0.8-1.89,1.59-1.64
	C302.48,592.63,302.97,593.28,303.84,593.99z"
              />
              <path
                d="M411.06,604.6c0.92-0.02,1.69,0.7,1.69,1.57c0,0.86-0.8,1.63-1.69,1.57c-0.9-0.06-1.45-0.54-1.53-1.46
	C409.45,605.35,410.12,604.63,411.06,604.6z"
              />
              <path
                d="M399.6,448.36c-0.95,0.68-1.46,1.29-2.1,1.45c-0.89,0.23-1.7-0.65-1.68-1.55c0.03-0.9,0.87-1.73,1.75-1.46
	C398.2,447,398.69,447.63,399.6,448.36z"
              />
              <path
                d="M390.64,434.64c0,0.88-0.86,1.7-1.72,1.58c-0.89-0.12-1.44-0.67-1.44-1.59c0-0.92,0.57-1.46,1.45-1.58
	C389.77,432.93,390.65,433.78,390.64,434.64z"
              />
              <path
                d="M375.61,255.27c-0.88,0.77-1.35,1.43-1.96,1.65c-0.85,0.31-1.76-0.52-1.83-1.4c-0.06-0.88,0.75-1.82,1.63-1.62
	C374.09,254.04,374.63,254.62,375.61,255.27z"
              />
              <path
                d="M418.93,191.94c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C416.79,192.84,417.59,191.99,418.93,191.94z"
              />
              <path
                d="M443.59,561.49c-1.3-0.08-2.02-0.95-1.87-1.83c0.15-0.91,0.75-1.38,1.64-1.38c0.86,0,1.74,1.01,1.43,1.78
	C444.54,560.68,443.89,561.15,443.59,561.49z"
              />
              <path
                d="M357.03,558.53c0.28,0.33,0.94,0.79,1.14,1.41c0.29,0.88-0.52,1.7-1.47,1.71c-0.94,0.02-1.49-0.51-1.65-1.38
	C354.89,559.44,355.69,558.59,357.03,558.53z"
              />
              <path
                d="M449.39,530.16c0.01,1.32-0.79,2.12-1.67,2.02c-0.91-0.1-1.41-0.69-1.48-1.57c-0.07-0.85,0.89-1.83,1.67-1.57
	C448.54,529.26,449.04,529.88,449.39,530.16z"
              />
              <path
                d="M396.76,443.23c-0.72-0.87-1.38-1.35-1.58-1.97c-0.28-0.89,0.54-1.69,1.5-1.71c0.93-0.02,1.86,0.8,1.61,1.63
	C398.1,441.82,397.46,442.32,396.76,443.23z"
              />
              <path
                d="M434.36,564.77c-0.35,0.29-0.83,0.95-1.46,1.16c-0.9,0.3-1.74-0.47-1.76-1.42c-0.02-0.93,0.44-1.51,1.38-1.63
	C433.44,562.76,434.28,563.45,434.36,564.77z"
              />
              <path
                d="M443.64,541.14c0.36-0.26,0.87-0.88,1.52-1.06c0.78-0.23,1.7,0.78,1.61,1.63c-0.1,0.89-0.62,1.45-1.53,1.53
	C444.36,543.3,443.58,542.46,443.64,541.14z"
              />
              <path
                d="M430.84,588.3c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C429.93,589.12,430.57,588.63,430.84,588.3z"
              />
              <path
                d="M251.19,411.14c-0.36,0.26-0.88,0.87-1.52,1.05c-0.81,0.22-1.7-0.77-1.6-1.63c0.1-0.89,0.65-1.44,1.54-1.52
	C250.45,408.96,251.25,409.85,251.19,411.14z"
              />
              <path
                d="M454.41,560.41c0.67,0.92,1.28,1.44,1.45,2.08c0.22,0.85-0.72,1.61-1.65,1.57c-0.95-0.05-1.74-0.88-1.43-1.75
	C453,561.7,453.67,561.25,454.41,560.41z"
              />
              <path
                d="M321.41,532.43c-0.93,0.02-1.65-0.7-1.61-1.63c0.04-0.92,0.53-1.44,1.45-1.53c0.92-0.09,1.72,0.59,1.77,1.48
	C323.07,531.61,322.3,532.41,321.41,532.43z"
              />
              <path
                d="M400.57,608.92c0.9,0.01,1.66,0.78,1.62,1.65c-0.04,0.86-0.87,1.59-1.76,1.5c-0.9-0.09-1.42-0.6-1.46-1.52
	C398.93,609.6,399.63,608.9,400.57,608.92z"
              />
              <path
                d="M304.79,196.41c-0.01,0.86-0.81,1.6-1.7,1.57c-0.93-0.04-1.61-0.78-1.52-1.71c0.09-0.92,0.63-1.4,1.54-1.44
	C304.02,194.79,304.8,195.53,304.79,196.41z"
              />
              <path
                d="M497.94,405.49c1.38-0.04,2.21,0.72,2.12,1.6c-0.09,0.9-0.62,1.45-1.55,1.51c-0.95,0.06-1.82-0.71-1.6-1.61
	C497.07,406.36,497.69,405.84,497.94,405.49z"
              />
              <path
                d="M418.47,235.44c-0.68-0.91-1.31-1.42-1.49-2.06c-0.23-0.83,0.7-1.62,1.63-1.59c0.95,0.03,1.76,0.84,1.46,1.72
	C419.87,234.12,419.2,234.59,418.47,235.44z"
              />
              <path
                d="M521.97,610.89c-0.91,0.75-1.39,1.39-2.01,1.6c-0.86,0.28-1.75-0.56-1.78-1.45c-0.04-0.89,0.79-1.79,1.67-1.57
	C520.48,609.61,521.01,610.22,521.97,610.89z"
              />
              <path
                d="M358.03,433.93c-0.01,0.9-0.82,1.62-1.74,1.53c-0.92-0.09-1.45-0.63-1.43-1.56c0.01-0.93,0.53-1.46,1.46-1.53
	C357.25,432.3,358.03,433.03,358.03,433.93z"
              />
              <path
                d="M428.44,559.51c1.35,0.02,2.16,0.84,2.03,1.7c-0.14,0.88-0.68,1.41-1.61,1.42c-0.95,0.01-1.78-0.79-1.51-1.67
	C427.53,560.33,428.17,559.84,428.44,559.51z"
              />
              <path
                d="M422.39,198.97c-0.71-0.93-1.38-1.44-1.5-2.06c-0.17-0.85,0.43-1.47,1.38-1.55c0.96-0.08,1.84,0.67,1.61,1.56
	C423.72,197.54,423.09,198.05,422.39,198.97z"
              />
              <path
                d="M270.88,347.71c-0.1,0.85-0.63,1.36-1.57,1.36c-0.95,0-1.48-0.57-1.56-1.48c-0.08-0.92,0.75-1.65,1.71-1.57
	C270.33,346.08,270.92,346.74,270.88,347.71z"
              />
              <path
                d="M356.59,479.07c1.26,0.09,2.01,1,1.84,1.84c-0.17,0.87-0.73,1.38-1.65,1.36c-0.88-0.03-1.71-0.98-1.39-1.78
	C355.64,479.87,356.29,479.42,356.59,479.07z"
              />
              <path
                d="M439.37,408.71c-0.91,0.7-1.41,1.34-2.06,1.54c-0.81,0.25-1.71-0.76-1.6-1.64c0.11-0.91,0.71-1.59,1.58-1.42
	C437.94,407.32,438.46,408.01,439.37,408.71z"
              />
              <path
                d="M469.02,562.92c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C469.96,562.12,469.32,562.58,469.02,562.92z"
              />
              <path
                d="M477.51,560.96c-0.35,0.28-0.83,0.92-1.46,1.11c-0.9,0.28-1.71-0.5-1.71-1.44c0-0.92,0.46-1.48,1.39-1.58
	C476.65,558.95,477.46,559.64,477.51,560.96z"
              />
              <path
                d="M435.37,591.1c0.7,0.93,1.35,1.45,1.49,2.08c0.19,0.9-0.44,1.48-1.39,1.57c-0.96,0.08-1.89-0.71-1.65-1.57
	C433.99,592.53,434.64,592.03,435.37,591.1z"
              />
              <path
                d="M454.69,547.64c-0.07,1.33-0.51,1.91-1.46,1.98c-0.95,0.07-1.51-0.45-1.68-1.34c-0.16-0.87,0.68-1.85,1.55-1.66
	C453.73,546.77,454.27,547.37,454.69,547.64z"
              />
              <path
                d="M422.81,135.54c-0.85,0.77-1.31,1.43-1.94,1.67c-0.8,0.31-1.75-0.62-1.71-1.52c0.03-0.94,0.63-1.62,1.48-1.52
	C421.28,134.24,421.85,134.9,422.81,135.54z"
              />
              <path
                d="M436.48,559.05c0.28,0.34,0.93,0.84,1.11,1.47c0.26,0.86-0.67,1.67-1.63,1.59c-0.95-0.07-1.45-0.62-1.46-1.56
	C434.5,559.69,435.17,559.06,436.48,559.05z"
              />
              <path
                d="M465.1,536.66c-0.98,0.67-1.52,1.27-2.17,1.41c-0.9,0.2-1.68-0.72-1.54-1.65c0.14-0.92,0.75-1.53,1.64-1.3
	C463.66,535.29,464.16,535.94,465.1,536.66z"
              />
              <path
                d="M243.41,357.1c-0.95,0.71-1.46,1.35-2.09,1.5c-0.88,0.21-1.5-0.39-1.62-1.33c-0.12-0.94,0.66-1.84,1.56-1.63
	C241.91,355.81,242.44,356.42,243.41,357.1z"
              />
              <path
                d="M442.76,531.16c-0.87,0.76-1.32,1.42-1.93,1.64c-0.85,0.31-1.74-0.48-1.81-1.37c-0.06-0.88,0.72-1.8,1.59-1.61
	C441.26,529.96,441.8,530.53,442.76,531.16z"
              />
              <path
                d="M391.1,442.26c-0.36,0.28-0.86,0.91-1.5,1.11c-0.8,0.25-1.68-0.69-1.63-1.57c0.04-0.92,0.6-1.44,1.47-1.55
	C390.26,440.14,391.12,441,391.1,442.26z"
              />
              <path
                d="M461.07,560.47c1.34,0.01,2.14,0.82,2.01,1.68c-0.13,0.88-0.66,1.41-1.59,1.42c-0.94,0.01-1.77-0.79-1.51-1.66
	C460.17,561.29,460.8,560.81,461.07,560.47z"
              />
              <path
                d="M387.25,438.92c-0.36,0.28-0.87,0.9-1.51,1.09c-0.8,0.24-1.67-0.72-1.61-1.59c0.06-0.92,0.62-1.43,1.49-1.53
	C386.45,436.79,387.29,437.67,387.25,438.92z"
              />
              <path
                d="M223.15,197.65c-0.09-0.61-0.2-0.99-0.2-1.38c0-0.74,0.08-1.61,1.03-1.56c0.49,0.02,1.13,0.53,1.39,1
	c0.45,0.83,0.03,1.57-0.81,1.92C224.22,197.77,223.78,197.65,223.15,197.65z"
              />
              <path
                d="M351.12,474.16c-0.9,0.72-1.39,1.35-2,1.54c-0.87,0.27-1.71-0.55-1.74-1.45c-0.02-0.89,0.79-1.76,1.66-1.54
	C349.67,472.88,350.18,473.49,351.12,474.16z"
              />
              <path
                d="M257.37,411.44c-1.38,0.07-2.19-0.65-2.11-1.55c0.07-0.88,0.56-1.45,1.5-1.51c0.96-0.06,1.81,0.65,1.61,1.56
	C258.22,410.57,257.62,411.09,257.37,411.44z"
              />
              <path
                d="M443.17,435.52c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C444.42,434.01,443.8,434.56,443.17,435.52z"
              />
              <path
                d="M392.34,449.15c-0.96,0.65-1.51,1.31-2.15,1.4c-0.89,0.12-1.44-0.6-1.51-1.5c-0.07-0.87,0.91-1.85,1.69-1.55
	C391.01,447.74,391.47,448.39,392.34,449.15z"
              />
              <path
                d="M398.54,172.09c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C399.79,170.58,399.18,171.13,398.54,172.09z"
              />
              <path
                d="M450.09,537.3c0.08,1.27-0.72,2.14-1.57,2.08c-0.89-0.07-1.45-0.57-1.53-1.48c-0.08-0.86,0.77-1.86,1.56-1.65
	C449.2,536.42,449.72,537.03,450.09,537.3z"
              />
              <path
                d="M450.36,435.04c-0.77-0.88-1.43-1.34-1.64-1.96c-0.29-0.83,0.61-1.69,1.53-1.65c0.93,0.04,1.59,0.58,1.46,1.47
	C451.62,433.53,451,434.08,450.36,435.04z"
              />
              <path
                d="M347.69,231.95c-0.1,1.34-0.57,1.89-1.51,1.94c-0.95,0.05-1.5-0.49-1.64-1.38c-0.14-0.88,0.72-1.83,1.59-1.61
	C346.76,231.06,347.29,231.66,347.69,231.95z"
              />
              <path
                d="M415.13,128.98c-0.9,0.7-1.42,1.39-2.06,1.52c-0.86,0.17-1.48-0.47-1.59-1.41c-0.1-0.88,0.79-1.9,1.59-1.66
	C413.73,127.63,414.24,128.28,415.13,128.98z"
              />
              <path
                d="M386.33,171.51c-1.01,0.56-1.61,1.13-2.26,1.21c-0.86,0.11-1.58-1.02-1.33-1.86c0.26-0.86,0.92-1.47,1.78-1.15
	C385.12,169.94,385.53,170.68,386.33,171.51z"
              />
              <path
                d="M435.98,345.96c0.3,0.38,0.95,0.87,1.14,1.51c0.26,0.86-0.67,1.67-1.63,1.59c-0.95-0.07-1.47-0.62-1.45-1.56
	C434.06,346.56,434.62,346.07,435.98,345.96z"
              />
              <path
                d="M407.75,169.57c-0.98,0.67-1.51,1.28-2.14,1.41c-0.9,0.18-1.46-0.48-1.56-1.39c-0.1-0.91,0.75-1.79,1.63-1.54
	C406.31,168.23,406.8,168.85,407.75,169.57z"
              />
              <path
                d="M460.3,549.73c-0.71-0.93-1.38-1.44-1.5-2.06c-0.17-0.85,0.43-1.47,1.38-1.55c0.96-0.08,1.84,0.67,1.6,1.56
	C461.63,548.31,461,548.81,460.3,549.73z"
              />
              <path
                d="M306.48,356.47c0.72,0.92,1.36,1.42,1.55,2.05c0.25,0.86-0.68,1.65-1.63,1.57c-0.94-0.08-1.57-0.66-1.39-1.55
	C305.14,357.91,305.79,357.4,306.48,356.47z"
              />
              <path
                d="M346.34,360.96c-0.7-0.93-1.32-1.45-1.51-2.09c-0.24-0.84,0.75-1.74,1.62-1.62c0.91,0.13,1.56,0.75,1.39,1.62
	C347.71,359.5,347.04,360.03,346.34,360.96z"
              />
              <path
                d="M458.28,523.94c0.75,0.89,1.4,1.36,1.6,1.98c0.28,0.86-0.59,1.68-1.53,1.64c-0.94-0.04-1.59-0.62-1.46-1.49
	C456.98,525.44,457.62,524.9,458.28,523.94z"
              />
              <path
                d="M548.24,626.82c-0.09,0.96-0.61,1.5-1.55,1.51c-0.95,0.02-1.49-0.54-1.59-1.45c-0.1-0.91,0.73-1.68,1.67-1.61
	C547.73,625.35,548.18,625.92,548.24,626.82z"
              />
              <path
                d="M364.43,481.82c-1.29-0.07-2.02-0.92-1.88-1.8c0.14-0.91,0.74-1.38,1.62-1.4c0.85-0.02,1.73,0.98,1.44,1.76
	C365.36,481.01,364.73,481.48,364.43,481.82z"
              />
              <path
                d="M481.91,564.05c-1,0.65-1.55,1.25-2.2,1.39c-0.92,0.19-1.68-0.75-1.53-1.68c0.15-0.92,0.78-1.53,1.67-1.28
	C480.48,562.64,480.98,563.3,481.91,564.05z"
              />
              <path
                d="M455.91,167.09c-0.66-0.95-1.32-1.49-1.43-2.13c-0.14-0.87,0.53-1.46,1.44-1.56c0.85-0.09,1.82,0.86,1.55,1.67
	C457.26,165.7,456.63,166.19,455.91,167.09z"
              />
              <path
                d="M383.02,447.37c0.7,0.92,1.33,1.43,1.52,2.08c0.25,0.84-0.73,1.74-1.6,1.63c-0.92-0.11-1.57-0.74-1.41-1.6
	C381.66,448.84,382.33,448.3,383.02,447.37z"
              />
              <path
                d="M395.28,342.23c-0.69-0.92-1.32-1.44-1.5-2.08c-0.24-0.83,0.75-1.73,1.61-1.61c0.9,0.13,1.55,0.74,1.38,1.6
	C396.64,340.78,395.97,341.3,395.28,342.23z"
              />
              <path
                d="M384.42,442.15c-0.85,0.77-1.31,1.43-1.94,1.67c-0.8,0.31-1.74-0.62-1.71-1.52c0.03-0.94,0.63-1.62,1.48-1.52
	C382.89,440.86,383.46,441.52,384.42,442.15z"
              />
              <path
                d="M430.05,340.69c-0.91,0.75-1.38,1.41-2,1.59c-0.87,0.25-1.51-0.32-1.67-1.24c-0.16-0.91,0.57-1.86,1.48-1.69
	C428.49,339.47,429.05,340.05,430.05,340.69z"
              />
              <path
                d="M348.68,611.59c-0.87,0.74-1.35,1.4-1.98,1.62c-0.8,0.29-1.76-0.68-1.68-1.56c0.08-0.91,0.65-1.62,1.53-1.49
	C347.18,610.26,347.73,610.93,348.68,611.59z"
              />
              <path
                d="M474.62,564.58c-0.87,0.73-1.35,1.39-1.97,1.61c-0.8,0.28-1.71-0.67-1.65-1.56c0.06-0.93,0.68-1.59,1.52-1.47
	C473.14,563.24,473.69,563.92,474.62,564.58z"
              />
              <path
                d="M446.07,587.06c0.29,0.36,0.91,0.86,1.12,1.5c0.26,0.8-0.67,1.73-1.52,1.68c-0.89-0.06-1.45-0.55-1.55-1.46
	C444.02,587.91,444.82,587.06,446.07,587.06z"
              />
              <path
                d="M430.77,600.76c-0.87,0.76-1.33,1.41-1.94,1.62c-0.86,0.3-1.74-0.5-1.79-1.39c-0.06-0.88,0.73-1.79,1.61-1.6
	C429.27,599.54,429.81,600.12,430.77,600.76z"
              />
              <path
                d="M457.17,529.75c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C455.62,528.53,456.17,529.11,457.17,529.75z"
              />
              <path
                d="M464.1,553.1c-0.69-0.94-1.32-1.46-1.48-2.1c-0.22-0.86,0.74-1.63,1.68-1.52c0.94,0.11,1.55,0.72,1.34,1.6
	C465.49,551.69,464.82,552.19,464.1,553.1z"
              />
              <path
                d="M542.74,621.36c0.63,0.97,1.22,1.52,1.35,2.16c0.17,0.88-0.8,1.58-1.74,1.43c-0.94-0.15-1.49-0.81-1.25-1.65
	C541.27,622.68,541.97,622.22,542.74,621.36z"
              />
              <path
                d="M413.94,613.12c-0.91,0.7-1.41,1.34-2.06,1.54c-0.81,0.25-1.71-0.76-1.6-1.64c0.11-0.91,0.71-1.59,1.58-1.42
	C412.51,611.73,413.03,612.42,413.94,613.12z"
              />
              <path
                d="M342.85,324.65c-0.31-0.37-0.97-0.85-1.17-1.48c-0.27-0.84,0.67-1.68,1.6-1.63c0.95,0.06,1.48,0.59,1.48,1.54
	C344.77,324.03,344.2,324.52,342.85,324.65z"
              />
              <path
                d="M512.71,613.82c-0.64-0.98-1.23-1.53-1.37-2.18c-0.18-0.84,0.87-1.67,1.71-1.49c0.89,0.19,1.51,0.82,1.26,1.69
	C514.14,612.46,513.46,612.94,512.71,613.82z"
              />
              <path
                d="M459.26,558.68c-0.01,0.9-0.79,1.61-1.72,1.52c-0.92-0.09-1.44-0.62-1.43-1.54c0.01-0.92,0.52-1.46,1.45-1.53
	C458.49,557.06,459.26,557.78,459.26,558.68z"
              />
              <path
                d="M384.23,355.64c-0.9,0.72-1.39,1.35-2,1.54c-0.87,0.27-1.71-0.55-1.74-1.45c-0.02-0.89,0.79-1.76,1.66-1.54
	C382.78,354.36,383.29,354.97,384.23,355.64z"
              />
              <path
                d="M317.79,489.76c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C319.15,488.32,318.49,488.83,317.79,489.76z"
              />
              <path
                d="M396.18,618.28c-0.86,0.76-1.33,1.42-1.95,1.66c-0.8,0.3-1.77-0.65-1.71-1.54c0.07-0.91,0.63-1.63,1.51-1.52
	C394.66,616.96,395.22,617.62,396.18,618.28z"
              />
              <path
                d="M457.88,537.11c-0.97,0.68-1.5,1.31-2.13,1.43c-0.88,0.17-1.48-0.44-1.56-1.37c-0.08-0.94,0.7-1.8,1.61-1.56
	C456.44,535.77,456.94,536.4,457.88,537.11z"
              />
              <path
                d="M341.16,545.04c0.67,0.97,1.28,1.51,1.43,2.15c0.19,0.87-0.79,1.61-1.73,1.48c-0.95-0.13-1.54-0.77-1.3-1.65
	C339.72,546.4,340.41,545.93,341.16,545.04z"
              />
              <path
                d="M515.89,373.12c-0.87,0.73-1.35,1.39-1.97,1.61c-0.8,0.28-1.71-0.67-1.65-1.56c0.06-0.93,0.67-1.59,1.52-1.47
	C514.41,371.79,514.96,372.46,515.89,373.12z"
              />
              <path
                d="M376.69,625.55c-0.76-0.88-1.43-1.34-1.63-1.96c-0.29-0.87,0.58-1.7,1.52-1.67c0.95,0.03,1.6,0.61,1.49,1.48
	C377.99,624.03,377.34,624.58,376.69,625.55z"
              />
              <path
                d="M459.75,566.24c-0.35,0.28-0.83,0.92-1.46,1.11c-0.9,0.28-1.71-0.5-1.71-1.44c0-0.92,0.46-1.48,1.39-1.58
	C458.9,564.23,459.7,564.92,459.75,566.24z"
              />
              <path
                d="M504.18,595.91c0.71,0.92,1.35,1.42,1.52,2.05c0.24,0.87-0.66,1.64-1.61,1.56c-0.94-0.08-1.56-0.66-1.38-1.54
	C502.84,597.36,503.49,596.84,504.18,595.91z"
              />
              <path
                d="M253.21,206.38c1.36-0.05,2.18,0.69,2.1,1.57c-0.08,0.89-0.59,1.44-1.51,1.5c-0.94,0.06-1.81-0.69-1.59-1.58
	C252.36,207.25,252.96,206.73,253.21,206.38z"
              />
              <path
                d="M272.91,321.98c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C271.36,320.76,271.91,321.34,272.91,321.98z"
              />
              <path
                d="M463.84,529.1c-0.19,1.36-0.75,1.85-1.68,1.83c-0.95-0.03-1.46-0.61-1.52-1.53c-0.05-0.91,0.85-1.77,1.73-1.48
	C463,528.13,463.47,528.79,463.84,529.1z"
              />
              <path
                d="M292.34,378.22c-0.94,0.7-1.45,1.35-2.08,1.49c-0.87,0.21-1.49-0.39-1.6-1.32c-0.11-0.92,0.66-1.83,1.56-1.62
	C290.85,376.93,291.37,377.54,292.34,378.22z"
              />
              <path
                d="M523.65,319.53c0.69,0.93,1.32,1.43,1.48,2.06c0.23,0.89-0.65,1.63-1.61,1.55c-0.95-0.08-1.55-0.7-1.37-1.56
	C522.27,320.96,522.94,320.45,523.65,319.53z"
              />
              <path
                d="M324.96,485.07c0.71,0.91,1.34,1.41,1.52,2.03c0.25,0.89-0.62,1.65-1.59,1.57c-0.95-0.07-1.56-0.68-1.4-1.54
	C323.61,486.52,324.27,486.01,324.96,485.07z"
              />
              <path
                d="M373.45,479.56c-0.98,0.67-1.52,1.27-2.17,1.41c-0.9,0.2-1.68-0.72-1.54-1.65c0.14-0.92,0.75-1.53,1.64-1.3
	C372.01,478.19,372.51,478.84,373.45,479.56z"
              />
              <path
                d="M441.51,350.67c-0.9,0.69-1.41,1.32-2.05,1.51c-0.8,0.24-1.67-0.78-1.57-1.65c0.11-0.93,0.75-1.55,1.59-1.39
	C440.1,349.27,440.62,349.97,441.51,350.67z"
              />
              <path
                d="M465.91,179.4c-0.37,0.26-0.9,0.86-1.55,1.02c-0.83,0.2-1.63-0.77-1.54-1.66c0.09-0.92,0.68-1.4,1.55-1.47
	C465.21,177.23,466.01,178.15,465.91,179.4z"
              />
              <path
                d="M446.07,361.08c-0.86,0.75-1.32,1.41-1.94,1.65c-0.79,0.3-1.75-0.64-1.69-1.53c0.06-0.91,0.63-1.62,1.49-1.51
	C444.56,359.77,445.12,360.44,446.07,361.08z"
              />
              <path
                d="M420.71,343.69c-0.7-0.93-1.32-1.45-1.51-2.09c-0.24-0.84,0.75-1.74,1.62-1.62c0.91,0.13,1.56,0.75,1.39,1.62
	C422.09,342.22,421.41,342.75,420.71,343.69z"
              />
              <path
                d="M355.71,192c-0.94,0.73-1.44,1.39-2.07,1.55c-0.88,0.23-1.52-0.36-1.66-1.3c-0.14-0.94,0.62-1.87,1.54-1.67
	C354.17,190.72,354.72,191.33,355.71,192z"
              />
              <path
                d="M432.6,389.65c0.28,0.34,0.95,0.81,1.16,1.44c0.28,0.84-0.65,1.69-1.59,1.64c-0.94-0.05-1.47-0.57-1.5-1.52
	C430.65,390.34,431.28,389.7,432.6,389.65z"
              />
              <path
                d="M432.43,598.6c-1.36,0-2.15-0.78-2.03-1.65c0.12-0.88,0.64-1.42,1.58-1.44c0.94-0.02,1.78,0.76,1.53,1.64
	C433.33,597.77,432.7,598.27,432.43,598.6z"
              />
              <path
                d="M557.64,631.57c-0.67-0.94-1.32-1.49-1.43-2.13c-0.15-0.87,0.53-1.47,1.43-1.57c0.85-0.1,1.82,0.85,1.56,1.66
	C558.99,630.18,558.36,630.67,557.64,631.57z"
              />
              <path
                d="M393.5,442.84c0.75,0.89,1.43,1.36,1.6,1.98c0.23,0.87-0.35,1.5-1.3,1.63c-0.94,0.13-1.91-0.6-1.72-1.48
	C392.22,444.34,392.84,443.8,393.5,442.84z"
              />
              <path
                d="M442.45,590.36c0.73,0.89,1.38,1.38,1.59,2.02c0.27,0.82-0.68,1.77-1.54,1.69c-0.92-0.08-1.59-0.69-1.46-1.56
	C441.13,591.88,441.79,591.32,442.45,590.36z"
              />
              <path
                d="M470.87,229.13c-1,0.65-1.56,1.25-2.21,1.38c-0.92,0.19-1.68-0.75-1.52-1.69c0.15-0.92,0.78-1.53,1.67-1.28
	C469.45,227.72,469.94,228.38,470.87,229.13z"
              />
              <path
                d="M434.39,557.34c-1,0.65-1.55,1.24-2.19,1.37c-0.91,0.18-1.67-0.75-1.51-1.67c0.16-0.92,0.78-1.52,1.66-1.27
	C432.97,555.94,433.46,556.6,434.39,557.34z"
              />
              <path
                d="M355.62,262.59c-0.67-0.95-1.34-1.5-1.44-2.14c-0.14-0.87,0.54-1.48,1.45-1.57c0.86-0.09,1.83,0.86,1.56,1.68
	C356.98,261.19,356.34,261.69,355.62,262.59z"
              />
              <path
                d="M325.7,472.64c-0.92,0.76-1.4,1.41-2.02,1.6c-0.89,0.28-1.77-0.57-1.7-1.49c0.07-0.91,0.6-1.6,1.51-1.46
	C324.14,471.4,324.69,472,325.7,472.64z"
              />
              <path
                d="M441.86,540.04c-1.38,0.07-2.12-0.62-2.08-1.56c0.05-0.92,0.55-1.45,1.48-1.51c0.92-0.06,1.85,0.72,1.63,1.56
	C442.73,539.17,442.11,539.69,441.86,540.04z"
              />
              <path
                d="M443.5,206.1c-1,0.65-1.55,1.24-2.19,1.37c-0.91,0.18-1.67-0.75-1.51-1.67c0.16-0.92,0.78-1.52,1.66-1.27
	C442.09,204.7,442.58,205.35,443.5,206.1z"
              />
              <path
                d="M374.15,446.79c-0.9,0.73-1.37,1.37-1.99,1.56c-0.87,0.28-1.72-0.54-1.75-1.43c-0.03-0.88,0.78-1.77,1.65-1.55
	C372.69,445.53,373.21,446.12,374.15,446.79z"
              />
              <path
                d="M376.98,336.04c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C375.7,337.54,376.31,337,376.98,336.04z"
              />
              <path
                d="M461.01,533.22c-0.95,0.7-1.46,1.33-2.08,1.48c-0.9,0.22-1.48-0.43-1.61-1.33c-0.13-0.89,0.7-1.82,1.57-1.6
	C459.52,531.92,460.04,532.53,461.01,533.22z"
              />
              <path
                d="M390.94,464.71c-0.25-0.34-0.86-0.86-1.02-1.49c-0.22-0.9,0.63-1.64,1.58-1.59c0.93,0.05,1.43,0.61,1.52,1.49
	C393.12,464.01,392.31,464.76,390.94,464.71z"
              />
              <path
                d="M380.63,106.67c-0.96,0.69-1.48,1.33-2.1,1.46c-0.87,0.19-1.48-0.42-1.58-1.35c-0.09-0.93,0.68-1.81,1.59-1.58
	C379.16,105.35,379.67,105.97,380.63,106.67z"
              />
              <path
                d="M485.73,567.27c-0.95,0.71-1.46,1.35-2.09,1.5c-0.88,0.21-1.5-0.39-1.62-1.33c-0.12-0.94,0.66-1.84,1.56-1.63
	C484.23,565.98,484.76,566.59,485.73,567.27z"
              />
              <path
                d="M358.75,590.49c-0.32-0.36-1-0.82-1.23-1.44c-0.3-0.81,0.63-1.71,1.55-1.69c0.94,0.03,1.5,0.54,1.54,1.48
	C360.63,589.8,360.09,590.31,358.75,590.49z"
              />
              <path
                d="M447.44,549.34c-0.36,0.26-0.88,0.86-1.52,1.04c-0.8,0.22-1.68-0.76-1.59-1.62c0.1-0.89,0.64-1.43,1.53-1.51
	C446.7,547.17,447.51,548.05,447.44,549.34z"
              />
              <path
                d="M385.91,623.07c-0.99,0.65-1.54,1.25-2.17,1.36c-0.89,0.16-1.46-0.48-1.51-1.42c-0.05-0.94,0.75-1.77,1.65-1.51
	C384.5,621.69,384.98,622.33,385.91,623.07z"
              />
              <path
                d="M433.93,344.25c-0.99,0.67-1.53,1.28-2.18,1.42c-0.91,0.2-1.69-0.73-1.55-1.66c0.13-0.93,0.75-1.54,1.65-1.31
	C432.48,342.87,432.98,343.52,433.93,344.25z"
              />
              <path
                d="M454.3,580.9c-1.01,0.63-1.58,1.22-2.22,1.31c-0.91,0.14-1.44-0.56-1.5-1.47c-0.05-0.91,0.85-1.76,1.72-1.47
	C452.92,579.48,453.39,580.13,454.3,580.9z"
              />
              <path
                d="M237.65,205.91c-0.93,0.73-1.43,1.38-2.06,1.54c-0.88,0.23-1.51-0.36-1.65-1.29c-0.14-0.93,0.63-1.85,1.53-1.66
	C236.12,204.65,236.66,205.25,237.65,205.91z"
              />
              <path
                d="M450.8,521.17c-1.29-0.07-2.02-0.92-1.88-1.8c0.14-0.9,0.74-1.38,1.62-1.39c0.85-0.02,1.73,0.98,1.43,1.76
	C451.73,520.36,451.1,520.83,450.8,521.17z"
              />
              <path
                d="M352.98,475.21c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C351.71,476.7,352.32,476.16,352.98,475.21z"
              />
              <path
                d="M486.87,415.57c-0.96,0.65-1.51,1.31-2.15,1.4c-0.89,0.13-1.44-0.6-1.51-1.5c-0.07-0.87,0.91-1.85,1.69-1.55
	C485.53,414.15,486,414.81,486.87,415.57z"
              />
              <path
                d="M450.86,212.28c-0.1,1.34-0.58,1.89-1.52,1.93c-0.95,0.04-1.49-0.5-1.63-1.39c-0.13-0.88,0.72-1.82,1.6-1.6
	C449.94,211.39,450.45,212,450.86,212.28z"
              />
              <path
                d="M495.21,575.01c0.74,0.88,1.39,1.37,1.61,2c0.28,0.82-0.65,1.77-1.52,1.71c-0.93-0.07-1.6-0.67-1.48-1.54
	C493.91,576.54,494.56,575.97,495.21,575.01z"
              />
              <path
                d="M349.48,352.84c0.68,0.94,1.31,1.46,1.48,2.11c0.23,0.85-0.77,1.72-1.64,1.59c-0.92-0.13-1.55-0.77-1.37-1.63
	C348.09,354.28,348.77,353.76,349.48,352.84z"
              />
              <path
                d="M441.59,556.78c-0.97,0.68-1.5,1.29-2.14,1.45c-0.9,0.21-1.69-0.7-1.56-1.62c0.12-0.93,0.73-1.54,1.61-1.33
	C440.13,555.43,440.64,556.07,441.59,556.78z"
              />
              <path
                d="M449.35,546.61c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C450.29,545.8,449.64,546.27,449.35,546.61z"
              />
              <path
                d="M579.12,629.64c-0.71-0.86-1.34-1.34-1.56-1.97c-0.28-0.81,0.6-1.71,1.46-1.74c0.83-0.03,1.81,0.87,1.57,1.65
	C580.4,628.22,579.79,628.74,579.12,629.64z"
              />
              <path
                d="M440.85,549.4c-0.87,0.76-1.32,1.42-1.93,1.64c-0.85,0.31-1.74-0.48-1.81-1.37c-0.06-0.88,0.72-1.8,1.59-1.61
	C439.34,548.19,439.88,548.77,440.85,549.4z"
              />
              <path
                d="M436.45,415.43c0.36-0.26,0.87-0.87,1.51-1.06c0.77-0.23,1.68,0.78,1.6,1.62c-0.09,0.89-0.62,1.44-1.52,1.52
	C437.18,417.58,436.39,416.72,436.45,415.43z"
              />
              <path
                d="M391.04,414.28c0.66,0.95,1.31,1.5,1.42,2.14c0.14,0.86-0.53,1.47-1.44,1.55c-0.86,0.08-1.81-0.86-1.55-1.67
	C389.68,415.66,390.32,415.17,391.04,414.28z"
              />
              <path
                d="M469.11,566.38c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C467.83,567.87,468.44,567.33,469.11,566.38z"
              />
              <path
                d="M421.79,191.79c-0.66-0.97-1.27-1.52-1.41-2.16c-0.18-0.87,0.81-1.61,1.75-1.47c0.95,0.14,1.52,0.79,1.29,1.66
	C423.24,190.43,422.55,190.9,421.79,191.79z"
              />
              <path
                d="M344.64,481.82c-0.93,0.72-1.43,1.37-2.05,1.53c-0.87,0.23-1.5-0.37-1.64-1.28c-0.14-0.92,0.62-1.84,1.52-1.65
	C343.12,480.56,343.66,481.16,344.64,481.82z"
              />
              <path
                d="M365.45,180.63c-1,0.56-1.6,1.11-2.25,1.19c-0.87,0.11-1.55-0.97-1.32-1.84c0.24-0.89,0.92-1.46,1.75-1.15
	C364.24,179.04,364.65,179.8,365.45,180.63z"
              />
              <path
                d="M343.34,474.77c-0.05,0.82-0.51,1.39-1.47,1.45c-0.96,0.06-1.51-0.48-1.67-1.37c-0.15-0.87,0.67-1.7,1.6-1.69
	C342.67,473.17,343.31,473.8,343.34,474.77z"
              />
              <path
                d="M456.17,546.37c-0.68-0.94-1.3-1.46-1.44-2.08c-0.21-0.9,0.68-1.62,1.64-1.52c0.95,0.1,1.53,0.74,1.35,1.58
	C457.58,544.96,456.89,545.46,456.17,546.37z"
              />
              <path
                d="M453.46,533.49c0.06,1.27-0.75,2.13-1.6,2.05c-0.89-0.08-1.44-0.59-1.51-1.51c-0.07-0.86,0.8-1.86,1.58-1.63
	C452.58,532.59,453.09,533.21,453.46,533.49z"
              />
              <path
                d="M332.73,475.56c0.86-0.76,1.32-1.47,1.94-1.64c0.86-0.24,1.49,0.38,1.66,1.28c0.16,0.87-0.63,1.93-1.45,1.75
	C334.23,476.8,333.68,476.2,332.73,475.56z"
              />
              <path
                d="M462.27,570.81c-1.34-0.18-1.87-0.7-1.83-1.64c0.04-0.93,0.6-1.44,1.54-1.47c0.91-0.02,1.83,0.88,1.52,1.68
	C463.27,570,462.59,570.45,462.27,570.81z"
              />
              <path
                d="M446.79,557.62c-1.27,0-2.08-0.85-1.97-1.7c0.11-0.89,0.67-1.42,1.56-1.47c0.85-0.05,1.77,0.88,1.51,1.69
	C447.69,556.76,447.07,557.26,446.79,557.62z"
              />
              <path
                d="M450.58,584.34c0.07,1.27-0.73,2.13-1.59,2.06c-0.89-0.07-1.44-0.58-1.52-1.5c-0.07-0.86,0.79-1.86,1.57-1.64
	C449.69,583.45,450.21,584.06,450.58,584.34z"
              />
              <path
                d="M381.35,446.26c-0.91,0.75-1.39,1.4-2.01,1.58c-0.88,0.26-1.5-0.35-1.67-1.25c-0.17-0.89,0.6-1.86,1.49-1.68
	C379.8,445.04,380.35,445.62,381.35,446.26z"
              />
              <path
                d="M351.99,627.65c0.28,0.34,0.95,0.81,1.16,1.44c0.28,0.84-0.65,1.69-1.59,1.64c-0.94-0.05-1.47-0.57-1.5-1.52
	C350.03,628.34,350.67,627.7,351.99,627.65z"
              />
              <path
                d="M338.65,370.64c-0.98,0.67-1.51,1.27-2.14,1.4c-0.91,0.18-1.46-0.49-1.55-1.39c-0.09-0.91,0.76-1.79,1.64-1.54
	C337.22,369.28,337.71,369.91,338.65,370.64z"
              />
              <path
                d="M393.96,454.26c-0.66-0.97-1.27-1.52-1.41-2.16c-0.18-0.87,0.81-1.61,1.75-1.47c0.95,0.14,1.52,0.79,1.29,1.66
	C395.41,452.9,394.72,453.37,393.96,454.26z"
              />
              <path
                d="M457.17,569.69c-0.92,0.7-1.41,1.33-2.03,1.51c-0.88,0.25-1.7-0.58-1.71-1.48c-0.01-0.9,0.81-1.75,1.69-1.5
	C455.74,568.38,456.24,569,457.17,569.69z"
              />
              <path
                d="M453.59,557.42c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C454.94,555.98,454.29,556.49,453.59,557.42z"
              />
              <path
                d="M463.39,541.68c0.72,0.92,1.36,1.42,1.55,2.05c0.25,0.86-0.68,1.65-1.63,1.57c-0.94-0.08-1.57-0.66-1.39-1.55
	C462.05,543.13,462.7,542.62,463.39,541.68z"
              />
              <path
                d="M455.67,574.81c0.62,0.97,1.21,1.52,1.33,2.15c0.17,0.9-0.76,1.57-1.72,1.43c-0.96-0.14-1.49-0.82-1.27-1.65
	C454.17,576.14,454.89,575.68,455.67,574.81z"
              />
              <path
                d="M487.34,568.3c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.41,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C486.07,569.79,486.68,569.25,487.34,568.3z"
              />
              <path
                d="M382.65,261.36c0.1,1.31-0.65,2.16-1.53,2.11c-0.91-0.05-1.44-0.59-1.56-1.47c-0.11-0.84,0.76-1.87,1.55-1.66
	C381.75,260.51,382.28,261.1,382.65,261.36z"
              />
              <path
                d="M462.94,554.95c-1,0.65-1.56,1.25-2.19,1.36c-0.91,0.15-1.46-0.53-1.53-1.44c-0.07-0.91,0.81-1.78,1.69-1.5
	C461.53,553.55,462.01,554.19,462.94,554.95z"
              />
              <path
                d="M386.81,454.99c-0.66-0.95-1.32-1.49-1.43-2.13c-0.14-0.87,0.53-1.46,1.44-1.56c0.85-0.09,1.82,0.86,1.55,1.67
	C388.17,453.6,387.53,454.09,386.81,454.99z"
              />
              <path
                d="M363.13,570.77c-1,0.65-1.55,1.26-2.18,1.37c-0.91,0.16-1.46-0.52-1.54-1.43c-0.08-0.91,0.79-1.78,1.68-1.52
	C361.71,569.38,362.2,570.02,363.13,570.77z"
              />
              <path
                d="M452.31,539.01c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C451.03,540.51,451.64,539.97,452.31,539.01z"
              />
              <path
                d="M524.03,612.24c0.63,0.97,1.22,1.52,1.35,2.16c0.17,0.88-0.8,1.58-1.74,1.43c-0.94-0.15-1.49-0.81-1.25-1.65
	C522.56,613.56,523.26,613.11,524.03,612.24z"
              />
              <path
                d="M425.67,606.82c-0.77-0.87-1.44-1.32-1.65-1.93c-0.3-0.85,0.55-1.7,1.49-1.68c0.95,0.02,1.6,0.58,1.5,1.45
	C426.93,605.29,426.3,605.85,425.67,606.82z"
              />
              <path
                d="M387.5,462.19c-0.65-0.97-1.25-1.52-1.4-2.16c-0.19-0.84,0.85-1.68,1.7-1.51c0.89,0.18,1.52,0.8,1.28,1.67
	C388.91,460.81,388.23,461.3,387.5,462.19z"
              />
              <path
                d="M396.56,103.23c-1.29-0.08-2.01-0.95-1.86-1.82c0.15-0.9,0.75-1.37,1.63-1.37c0.85,0,1.72,1,1.41,1.77
	C397.5,102.43,396.86,102.89,396.56,103.23z"
              />
              <path
                d="M303.73,241.31c0.66,0.96,1.27,1.5,1.41,2.14c0.19,0.87-0.78,1.6-1.72,1.47c-0.94-0.13-1.53-0.77-1.29-1.63
	C302.3,242.67,302.98,242.2,303.73,241.31z"
              />
              <path
                d="M467.01,225.51c-0.91,0.76-1.39,1.42-2.01,1.61c-0.87,0.26-1.52-0.31-1.69-1.23c-0.17-0.92,0.56-1.87,1.47-1.7
	C465.44,224.3,466,224.88,467.01,225.51z"
              />
              <path
                d="M406.2,613.85c-0.02,0.84-0.46,1.42-1.41,1.52c-0.96,0.1-1.8-0.63-1.72-1.55c0.07-0.91,0.59-1.47,1.54-1.51
	C405.47,612.27,406.15,612.91,406.2,613.85z"
              />
              <path
                d="M441.96,421.4c-0.75-0.88-1.39-1.35-1.62-1.98c-0.29-0.8,0.64-1.78,1.5-1.71c0.91,0.07,1.6,0.65,1.48,1.52
	C443.23,419.87,442.59,420.43,441.96,421.4z"
              />
              <path
                d="M435.35,555.5c-0.71-0.93-1.35-1.43-1.53-2.07c-0.24-0.85,0.71-1.65,1.64-1.56c0.94,0.09,1.56,0.68,1.37,1.57
	C436.71,554.06,436.05,554.57,435.35,555.5z"
              />
              <path
                d="M348.66,551.49c0.73,0.91,1.41,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.33,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C347.39,552.99,348,552.45,348.66,551.49z"
              />
              <path
                d="M519.92,343.5c0.02,1.3-0.79,2.11-1.65,2.02c-0.9-0.1-1.41-0.68-1.48-1.55c-0.07-0.85,0.87-1.82,1.64-1.57
	C519.06,342.6,519.56,343.22,519.92,343.5z"
              />
              <path
                d="M270.89,204.48c-0.29-0.34-0.93-0.81-1.16-1.44c-0.29-0.77,0.6-1.78,1.44-1.75c0.88,0.02,1.48,0.49,1.61,1.4
	C272.91,203.56,272.18,204.41,270.89,204.48z"
              />
              <path
                d="M439.12,598.48c-0.67-0.95-1.34-1.5-1.44-2.14c-0.14-0.87,0.54-1.48,1.45-1.57c0.86-0.09,1.83,0.86,1.56,1.68
	C440.48,597.08,439.84,597.57,439.12,598.48z"
              />
              <path
                d="M447.36,209.45c-1,0.65-1.55,1.25-2.2,1.39c-0.92,0.19-1.68-0.75-1.53-1.68c0.15-0.92,0.78-1.53,1.67-1.28
	C445.93,208.04,446.43,208.71,447.36,209.45z"
              />
              <path
                d="M464.26,243.8c-0.02,0.93-0.46,1.51-1.41,1.62c-0.96,0.11-1.8-0.62-1.73-1.54c0.07-0.91,0.58-1.49,1.53-1.51
	C463.6,242.35,464.13,242.86,464.26,243.8z"
              />
              <path
                d="M432.87,250.23c-0.85,0.76-1.31,1.42-1.94,1.65c-0.8,0.3-1.73-0.63-1.69-1.52c0.04-0.93,0.64-1.6,1.49-1.5
	C431.36,248.94,431.92,249.6,432.87,250.23z"
              />
              <path
                d="M481.67,421.39c-0.69-0.87-1.32-1.37-1.53-2c-0.27-0.81,0.63-1.71,1.49-1.72c0.83-0.01,1.79,0.9,1.54,1.68
	C482.97,420,482.36,420.5,481.67,421.39z"
              />
              <path
                d="M416.95,130.2c0.66,0.95,1.33,1.51,1.43,2.15c0.13,0.86-0.53,1.48-1.46,1.56c-0.87,0.08-1.82-0.86-1.55-1.68
	C415.58,131.59,416.23,131.09,416.95,130.2z"
              />
              <path
                d="M456.09,522.47c0.03,1.3-0.77,2.12-1.63,2.03c-0.9-0.09-1.41-0.66-1.49-1.55c-0.07-0.85,0.85-1.83,1.63-1.58
	C455.24,521.58,455.74,522.2,456.09,522.47z"
              />
              <path
                d="M373.13,625.38c0.73,0.91,1.42,1.41,1.57,2.04c0.21,0.86-0.41,1.5-1.32,1.66c-0.87,0.15-1.89-0.7-1.68-1.55
	C371.86,626.89,372.47,626.34,373.13,625.38z"
              />
              <path
                d="M437.93,600.25c-0.94,0.67-1.48,1.34-2.11,1.45c-0.89,0.15-1.46-0.55-1.54-1.46c-0.08-0.88,0.86-1.87,1.65-1.59
	C436.56,598.87,437.04,599.51,437.93,600.25z"
              />
              <path
                d="M396.98,419.55c-0.98,0.69-1.51,1.3-2.15,1.46c-0.91,0.22-1.69-0.7-1.57-1.64c0.12-0.94,0.73-1.55,1.62-1.34
	C395.51,418.19,396.02,418.84,396.98,419.55z"
              />
              <path
                d="M348.02,477.98c-0.93,0.73-1.43,1.38-2.06,1.54c-0.88,0.23-1.51-0.36-1.65-1.29c-0.14-0.93,0.63-1.85,1.53-1.66
	C346.49,476.72,347.03,477.32,348.02,477.98z"
              />
              <path
                d="M437.46,205c-0.68-0.94-1.29-1.47-1.46-2.12c-0.22-0.84,0.79-1.71,1.65-1.57c0.9,0.15,1.54,0.77,1.34,1.63
	C438.84,203.58,438.17,204.09,437.46,205z"
              />
              <path
                d="M450.4,245.25c-0.96,0.64-1.53,1.29-2.16,1.38c-0.89,0.11-1.44-0.62-1.5-1.52c-0.06-0.87,0.94-1.84,1.71-1.53
	C449.08,243.82,449.54,244.48,450.4,245.25z"
              />
              <path
                d="M364.98,425.94c-0.88,0.72-1.37,1.36-2,1.57c-0.8,0.27-1.71-0.7-1.63-1.59c0.09-0.91,0.67-1.59,1.54-1.45
	C363.52,424.59,364.06,425.26,364.98,425.94z"
              />
              <path
                d="M446.25,533.94c0.08,1.27-0.72,2.14-1.57,2.08c-0.89-0.07-1.45-0.57-1.53-1.48c-0.08-0.86,0.77-1.86,1.56-1.65
	C445.36,533.06,445.88,533.67,446.25,533.94z"
              />
              <path
                d="M297.91,212.79c-1.38,0.07-2.12-0.62-2.08-1.56c0.05-0.92,0.55-1.45,1.48-1.51c0.92-0.06,1.85,0.72,1.63,1.56
	C298.77,211.92,298.16,212.44,297.91,212.79z"
              />
              <path
                d="M386.73,444.05c0.3,0.35,0.94,0.82,1.17,1.45c0.3,0.81-0.55,1.74-1.43,1.75c-0.92,0.01-1.46-0.52-1.62-1.39
	C384.7,445.01,385.48,444.11,386.73,444.05z"
              />
              <path
                d="M499.37,585.93c1.36-0.05,2.18,0.69,2.1,1.57c-0.08,0.89-0.59,1.44-1.51,1.5c-0.94,0.06-1.81-0.69-1.59-1.58
	C498.52,586.8,499.12,586.28,499.37,585.93z"
              />
              <path
                d="M459.6,538.33c0.69,0.93,1.35,1.45,1.47,2.07c0.16,0.86-0.45,1.47-1.4,1.54c-0.96,0.07-1.83-0.69-1.59-1.58
	C458.26,539.74,458.89,539.25,459.6,538.33z"
              />
              <path
                d="M294.38,181.11c1.27,0.03,2.03,0.89,1.91,1.76c-0.12,0.91-0.69,1.4-1.58,1.43c-0.86,0.03-1.76-0.92-1.48-1.72
	C293.46,181.95,294.09,181.47,294.38,181.11z"
              />
              <path
                d="M458.94,575.16c-0.74-0.9-1.44-1.39-1.57-2c-0.19-0.84,0.37-1.49,1.33-1.6c0.96-0.11,1.86,0.6,1.66,1.5
	C460.22,573.69,459.61,574.21,458.94,575.16z"
              />
              <path
                d="M464.28,422.95c0.29,0.36,0.92,0.85,1.14,1.48c0.27,0.79-0.64,1.74-1.5,1.7c-0.89-0.04-1.46-0.53-1.57-1.44
	C462.25,423.82,463.02,422.97,464.28,422.95z"
              />
              <path
                d="M453.28,573.24c-0.19,1.36-0.75,1.85-1.68,1.83c-0.95-0.02-1.46-0.61-1.52-1.53c-0.05-0.91,0.85-1.77,1.73-1.48
	C452.44,572.27,452.92,572.93,453.28,573.24z"
              />
              <path
                d="M260.16,368.36c-0.69-0.94-1.32-1.46-1.48-2.1c-0.22-0.86,0.74-1.63,1.68-1.52c0.94,0.11,1.55,0.72,1.34,1.6
	C261.55,366.96,260.88,367.45,260.16,368.36z"
              />
              <path
                d="M361.87,263.67c-0.86,0.76-1.32,1.43-1.95,1.66c-0.8,0.3-1.74-0.63-1.7-1.53c0.04-0.94,0.64-1.61,1.5-1.51
	C360.35,262.36,360.92,263.03,361.87,263.67z"
              />
              <path
                d="M486.65,225.29c-1.36,0-2.15-0.78-2.03-1.65c0.12-0.88,0.64-1.42,1.58-1.44c0.94-0.02,1.78,0.76,1.53,1.64
	C487.55,224.46,486.92,224.95,486.65,225.29z"
              />
              <path
                d="M538.62,622.46c-0.64-0.98-1.23-1.53-1.37-2.18c-0.18-0.84,0.87-1.67,1.71-1.49c0.89,0.19,1.51,0.82,1.26,1.69
	C540.05,621.1,539.37,621.57,538.62,622.46z"
              />
              <path
                d="M275.74,105.06c-0.94,0.67-1.48,1.34-2.11,1.45c-0.89,0.15-1.46-0.55-1.54-1.46c-0.08-0.88,0.86-1.87,1.65-1.59
	C274.37,103.67,274.86,104.32,275.74,105.06z"
              />
              <path
                d="M470.67,422.28c1.34,0.02,2.14,0.83,2.01,1.69c-0.14,0.88-0.67,1.4-1.6,1.41c-0.94,0.01-1.77-0.8-1.5-1.67
	C469.77,423.09,470.41,422.61,470.67,422.28z"
              />
              <path
                d="M444.63,366.29c0.72,0.9,1.4,1.4,1.55,2.03c0.21,0.86-0.4,1.49-1.31,1.65c-0.86,0.15-1.88-0.7-1.67-1.54
	C443.36,367.78,443.97,367.24,444.63,366.29z"
              />
              <path
                d="M242.1,389.79c-0.1,0.96-0.61,1.49-1.56,1.5c-0.95,0.01-1.49-0.54-1.59-1.46c-0.1-0.9,0.74-1.67,1.68-1.6
	C241.59,388.31,242.04,388.89,242.1,389.79z"
              />
              <path
                d="M298.24,204.08c-0.09,0.96-0.61,1.5-1.55,1.51c-0.95,0.02-1.49-0.54-1.59-1.45c-0.1-0.91,0.73-1.68,1.67-1.61
	C297.73,202.61,298.18,203.18,298.24,204.08z"
              />
              <path
                d="M449.39,553.8c-0.29-0.34-0.93-0.81-1.16-1.44c-0.29-0.77,0.6-1.78,1.44-1.75c0.88,0.02,1.48,0.49,1.61,1.4
	C451.41,552.88,450.69,553.73,449.39,553.8z"
              />
              <path
                d="M333.21,482.76c0.86-0.76,1.32-1.47,1.94-1.64c0.86-0.24,1.49,0.38,1.66,1.28c0.16,0.87-0.63,1.93-1.45,1.75
	C334.71,484,334.16,483.4,333.21,482.76z"
              />
              <path
                d="M393.77,423.64c-0.92,0.68-1.44,1.35-2.07,1.47c-0.88,0.16-1.46-0.52-1.55-1.42c-0.09-0.87,0.82-1.87,1.61-1.61
	C392.39,422.28,392.88,422.92,393.77,423.64z"
              />
              <path
                d="M400.68,342.98c0.07,1.27-0.74,2.13-1.59,2.06c-0.89-0.07-1.44-0.59-1.52-1.5c-0.07-0.86,0.79-1.86,1.58-1.63
	C399.79,342.09,400.31,342.71,400.68,342.98z"
              />
              <path
                d="M446.64,181.81c0.7,0.94,1.33,1.45,1.5,2.09c0.23,0.87-0.71,1.63-1.66,1.53c-0.95-0.1-1.56-0.7-1.35-1.58
	C445.27,183.22,445.93,182.72,446.64,181.81z"
              />
              <path
                d="M393.12,455.88c-0.92,0.74-1.41,1.4-2.04,1.57c-0.88,0.24-1.51-0.34-1.66-1.27c-0.15-0.93,0.6-1.86,1.51-1.68
	C391.57,454.64,392.12,455.23,393.12,455.88z"
              />
              <path
                d="M454.23,181.6c0.29,0.36,0.91,0.86,1.12,1.5c0.26,0.8-0.67,1.73-1.52,1.68c-0.89-0.06-1.45-0.55-1.55-1.46
	C452.18,182.45,452.97,181.6,454.23,181.6z"
              />
              <path
                d="M453.08,213.7c0.7,0.91,1.33,1.42,1.52,2.06c0.24,0.83-0.73,1.72-1.6,1.62c-0.91-0.11-1.56-0.73-1.39-1.59
	C451.73,215.16,452.4,214.63,453.08,213.7z"
              />
              <path
                d="M456.82,553.57c-0.65-0.97-1.3-1.52-1.39-2.15c-0.11-0.87,0.54-1.45,1.49-1.48c0.95-0.03,1.81,0.8,1.52,1.67
	C458.24,552.23,457.57,552.69,456.82,553.57z"
              />
              <path
                d="M514.1,248.29c-1.38,0.08-2.19-0.63-2.13-1.53c0.06-0.88,0.55-1.45,1.48-1.53c0.96-0.07,1.82,0.64,1.62,1.54
	C514.94,247.41,514.34,247.94,514.1,248.29z"
              />
              <path
                d="M300.99,245.72c0.25,0.34,0.87,0.85,1.04,1.47c0.24,0.88-0.62,1.66-1.56,1.62c-0.92-0.04-1.45-0.58-1.55-1.47
	C298.83,246.46,299.63,245.69,300.99,245.72z"
              />
              <path
                d="M325.77,366.31c0.3,0.35,0.95,0.81,1.19,1.43c0.31,0.81-0.53,1.75-1.41,1.77c-0.92,0.02-1.47-0.5-1.63-1.37
	C323.76,367.3,324.52,366.39,325.77,366.31z"
              />
              <path
                d="M437.44,387.07c-0.88,0.72-1.37,1.36-2,1.57c-0.8,0.27-1.71-0.7-1.63-1.59c0.09-0.91,0.67-1.59,1.54-1.45
	C435.98,385.72,436.51,386.39,437.44,387.07z"
              />
              <path
                d="M402.89,112.14c-0.93,0.71-1.44,1.32-2.08,1.53c-0.71,0.23-1.51-0.66-1.52-1.46c-0.01-0.8,0.75-1.73,1.46-1.53
	C401.41,110.87,401.94,111.46,402.89,112.14z"
              />
              <path
                d="M374.79,105.64c-0.75-0.87-1.39-1.33-1.62-1.95c-0.29-0.78,0.63-1.75,1.48-1.69c0.89,0.06,1.58,0.61,1.45,1.49
	C376.01,104.12,375.4,104.68,374.79,105.64z"
              />
              <path
                d="M508.62,606.22c0.75,0.87,1.4,1.34,1.63,1.96c0.29,0.8-0.62,1.76-1.49,1.7c-0.91-0.06-1.59-0.62-1.47-1.5
	C507.37,607.76,508,607.19,508.62,606.22z"
              />
              <path
                d="M320.33,368.29c-0.85,0.74-1.31,1.44-1.92,1.6c-0.84,0.23-1.47-0.37-1.62-1.27c-0.15-0.89,0.61-1.91,1.44-1.72
	C318.86,367.05,319.39,367.65,320.33,368.29z"
              />
              <path
                d="M344.83,608.36c-0.95,0.68-1.48,1.28-2.13,1.47c-0.71,0.21-1.49-0.73-1.48-1.52c0.01-0.79,0.82-1.7,1.52-1.47
	C343.39,607.05,343.9,607.66,344.83,608.36z"
              />
              <path
                d="M293.16,502.11c-0.27-0.34-0.95-0.85-1.08-1.46c-0.18-0.89,0.45-1.49,1.4-1.53c0.96-0.04,1.48,0.51,1.57,1.43
	C295.14,501.4,294.47,502.08,293.16,502.11z"
              />
              <path
                d="M338.73,476.68c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.54c-0.84,0.09-1.8-0.85-1.53-1.64
	C337.39,478.05,338.02,477.57,338.73,476.68z"
              />
              <path
                d="M224.1,214.61c-0.87,0.57-1.41,0.92-1.95,1.28c-0.35-0.65-0.87-1.27-1-1.96c-0.13-0.7,0.48-1.34,1.18-1.04
	C222.94,213.14,223.35,213.85,224.1,214.61z"
              />
              <path
                d="M509.87,388c-0.86,0.72-1.34,1.41-1.95,1.57c-0.85,0.21-1.47-0.39-1.6-1.3c-0.13-0.89,0.65-1.89,1.47-1.69
	C508.42,386.74,508.95,387.35,509.87,388z"
              />
              <path
                d="M491.56,575.89c-0.7-0.89-1.36-1.38-1.52-2c-0.22-0.87,0.4-1.47,1.28-1.63c0.85-0.15,1.86,0.69,1.65,1.51
	C492.82,574.41,492.22,574.94,491.56,575.89z"
              />
              <path
                d="M432.37,187.23c-0.75-0.87-1.39-1.34-1.62-1.96c-0.29-0.79,0.64-1.77,1.49-1.71c0.9,0.07,1.59,0.63,1.47,1.5
	C433.62,185.7,432.99,186.26,432.37,187.23z"
              />
              <path
                d="M474.35,422.08c-0.65-0.95-1.26-1.48-1.39-2.1c-0.19-0.89,0.71-1.59,1.66-1.48c0.95,0.11,1.5,0.77,1.3,1.6
	C475.78,420.71,475.1,421.19,474.35,422.08z"
              />
              <path
                d="M259.74,91.74c-0.05,1.36-0.8,2.06-1.74,1.83c-0.41-0.1-1.06-0.86-1-0.96c0.41-0.69,0.93-1.34,1.55-1.83
	C258.67,90.7,259.46,91.51,259.74,91.74z"
              />
              <path
                d="M309.36,365.64c-0.9,0.75-1.37,1.4-1.98,1.58c-0.87,0.26-1.49-0.34-1.66-1.23c-0.17-0.9,0.58-1.85,1.47-1.68
	C307.82,364.43,308.37,365.01,309.36,365.64z"
              />
              <path
                d="M468.14,572.37c-0.9,0.68-1.41,1.31-2.05,1.49c-0.81,0.23-1.66-0.76-1.54-1.64c0.12-0.91,0.72-1.55,1.58-1.37
	C466.74,570.98,467.25,571.67,468.14,572.37z"
              />
              <path
                d="M389.94,420.38c-1,0.63-1.57,1.28-2.2,1.34c-0.88,0.08-1.41-0.64-1.38-1.58c0.02-0.81,0.86-1.65,1.58-1.38
	C388.56,418.99,389.04,419.62,389.94,420.38z"
              />
              <path
                d="M425.01,140.31c-1.11-0.01-1.68-0.51-1.72-1.44c-0.03-0.92,0.48-1.52,1.45-1.55c0.94-0.03,1.53,0.49,1.57,1.43
	C426.35,139.7,425.79,140.2,425.01,140.31z"
              />
              <path
                d="M392.79,622.1c-0.84,0.75-1.3,1.45-1.91,1.63c-0.84,0.24-1.48-0.35-1.64-1.25c-0.16-0.89,0.59-1.91,1.42-1.73
	C391.3,620.88,391.84,621.47,392.79,622.1z"
              />
              <path
                d="M424.22,608.83c-0.9,0.69-1.41,1.37-2.04,1.49c-0.84,0.15-1.46-0.48-1.56-1.4c-0.09-0.86,0.79-1.87,1.58-1.62
	C422.84,607.49,423.33,608.13,424.22,608.83z"
              />
              <path
                d="M571.89,626.73c1.05,0.13,1.54,0.72,1.47,1.63c-0.07,0.93-0.67,1.44-1.62,1.36c-0.96-0.07-1.46-0.67-1.39-1.6
	C570.42,627.18,571.05,626.75,571.89,626.73z"
              />
              <path
                d="M360.3,474.73c0.66,0.98,1.24,1.52,1.4,2.17c0.19,0.74-0.72,1.56-1.49,1.48c-0.9-0.1-1.61-0.65-1.43-1.55
	C358.91,476.21,359.58,475.68,360.3,474.73z"
              />
              <path
                d="M497.39,244.49c-0.85,0.74-1.31,1.44-1.92,1.6c-0.84,0.23-1.47-0.37-1.62-1.27c-0.15-0.89,0.61-1.91,1.44-1.72
	C495.92,243.25,496.46,243.85,497.39,244.49z"
              />
              <path
                d="M354.64,596.79c-0.88,0.7-1.37,1.34-2,1.54c-0.79,0.25-1.68-0.74-1.6-1.61c0.09-0.93,0.73-1.55,1.55-1.42
	C353.22,595.41,353.74,596.11,354.64,596.79z"
              />
              <path
                d="M481.58,211.31c0.71,0.89,1.39,1.39,1.54,2.01c0.21,0.86-0.4,1.48-1.3,1.64c-0.85,0.15-1.87-0.7-1.67-1.52
	C480.31,212.8,480.92,212.26,481.58,211.31z"
              />
              <path
                d="M293.94,439.39c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C292.51,438.05,293.03,438.72,293.94,439.39z"
              />
              <path
                d="M368.74,476.19c-0.06,0.83-0.52,1.4-1.48,1.43c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C368.03,474.61,368.71,475.24,368.74,476.19z"
              />
              <path
                d="M327.61,477.64c-0.87-0.03-1.47-0.49-1.51-1.44c-0.03-0.93,0.49-1.51,1.45-1.55c0.96-0.05,1.49,0.5,1.58,1.43
	C329.21,476.92,328.54,477.61,327.61,477.64z"
              />
              <path
                d="M379.61,345.29c-0.92,0.74-1.42,1.46-2.04,1.57c-0.85,0.16-1.51-0.48-1.56-1.42c-0.04-0.95,0.58-1.62,1.43-1.53
	C378.07,343.97,378.63,344.64,379.61,345.29z"
              />
              <path
                d="M375.94,475.71c-0.06,0.83-0.52,1.4-1.48,1.44c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C375.22,474.13,375.91,474.76,375.94,475.71z"
              />
              <path
                d="M361.77,310.5c-0.05,0.89-0.44,1.52-1.42,1.58c-0.95,0.05-1.55-0.43-1.62-1.37c-0.06-0.93,0.44-1.53,1.39-1.6
	C361.09,309.03,361.6,309.57,361.77,310.5z"
              />
              <path
                d="M370.4,343.77c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.53c-0.84,0.09-1.8-0.85-1.53-1.64
	C369.06,345.14,369.69,344.65,370.4,343.77z"
              />
              <path
                d="M509.89,244.91c-1.03,0.02-1.62-0.48-1.67-1.41c-0.05-0.92,0.45-1.53,1.41-1.58c0.95-0.04,1.54,0.46,1.6,1.4
	C511.29,244.26,510.76,244.8,509.89,244.91z"
              />
              <path
                d="M331.3,390.56c0.66,0.95,1.27,1.47,1.41,2.09c0.2,0.89-0.69,1.59-1.65,1.49c-0.95-0.11-1.5-0.75-1.31-1.59
	C329.89,391.94,330.57,391.45,331.3,390.56z"
              />
              <path
                d="M348.9,73.47c-1,0.63-1.58,1.27-2.2,1.31c-0.85,0.06-1.46-0.62-1.39-1.57c0.07-0.93,0.74-1.55,1.59-1.36
	C347.52,71.98,348,72.7,348.9,73.47z"
              />
              <path
                d="M351.27,600.66c-0.88,0.69-1.38,1.32-2.01,1.51c-0.79,0.24-1.66-0.77-1.56-1.62c0.1-0.92,0.74-1.53,1.56-1.38
	C349.87,599.28,350.38,599.97,351.27,600.66z"
              />
              <path
                d="M390.42,427.4c-0.93,0.71-1.44,1.32-2.08,1.53c-0.71,0.23-1.51-0.66-1.52-1.46c-0.02-0.8,0.75-1.73,1.46-1.53
	C388.93,426.12,389.46,426.72,390.42,427.4z"
              />
              <path
                d="M378.08,417.69c-0.12,0.88-0.61,1.43-1.58,1.39c-0.95-0.04-1.49-0.6-1.45-1.54c0.03-0.94,0.62-1.44,1.56-1.45
	C377.47,416.09,378.11,416.8,378.08,417.69z"
              />
              <path
                d="M411.32,125.96c-0.98,0.64-1.53,1.24-2.16,1.35c-0.89,0.15-1.44-0.49-1.5-1.41c-0.05-0.93,0.76-1.76,1.64-1.5
	C409.92,124.58,410.4,125.22,411.32,125.96z"
              />
              <path
                d="M284.78,338.93c-0.12,0.96-0.64,1.47-1.58,1.47c-0.87,0-1.54-0.69-1.47-1.54c0.08-0.93,0.6-1.48,1.56-1.44
	C284.25,337.44,284.7,338.03,284.78,338.93z"
              />
              <path
                d="M265.8,262.68c0.64,0.93,1.28,1.47,1.39,2.1c0.15,0.86-0.52,1.44-1.41,1.53c-0.84,0.09-1.8-0.85-1.53-1.64
	C264.45,264.04,265.08,263.56,265.8,262.68z"
              />
              <path
                d="M233.55,242.37c-0.92,0.72-1.4,1.36-2.01,1.53c-0.88,0.24-1.48-0.37-1.62-1.27c-0.14-0.9,0.62-1.83,1.5-1.64
	C232.04,241.13,232.57,241.72,233.55,242.37z"
              />
              <path
                d="M347.92,604.49c-0.89,0.69-1.39,1.33-2.02,1.52c-0.79,0.24-1.67-0.77-1.57-1.63c0.1-0.92,0.74-1.54,1.57-1.39
	C346.52,603.1,347.03,603.8,347.92,604.49z"
              />
              <path
                d="M382.61,361.38c1.12,0.05,1.68,0.57,1.69,1.5c0.01,0.93-0.53,1.5-1.49,1.51c-0.95,0-1.51-0.53-1.52-1.48
	C381.27,361.96,381.87,361.49,382.61,361.38z"
              />
              <path
                d="M432.69,605.75c-0.88-0.03-1.47-0.5-1.5-1.45c-0.03-0.94,0.51-1.5,1.46-1.54c0.96-0.04,1.49,0.51,1.57,1.44
	C434.29,605.05,433.61,605.73,432.69,605.75z"
              />
              <path
                d="M345.04,362.5c-0.84,0.76-1.29,1.42-1.91,1.66c-0.78,0.3-1.73-0.62-1.7-1.5c0.04-0.93,0.62-1.59,1.47-1.5
	C343.53,361.22,344.09,361.87,345.04,362.5z"
              />
              <path
                d="M274.04,320.13c-0.64-0.94-1.27-1.48-1.38-2.11c-0.15-0.87,0.53-1.44,1.41-1.53c0.85-0.09,1.79,0.85,1.53,1.65
	C275.39,318.76,274.76,319.24,274.04,320.13z"
              />
              <path
                d="M455.65,428.83c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C454.22,427.49,454.74,428.16,455.65,428.83z"
              />
              <path
                d="M328.02,541.11c-0.88,0.71-1.37,1.35-2,1.55c-0.8,0.26-1.69-0.71-1.6-1.59c0.09-0.91,0.68-1.58,1.54-1.43
	C326.58,539.76,327.11,540.44,328.02,541.11z"
              />
              <path
                d="M393.3,336.82c-0.97,0.66-1.53,1.33-2.16,1.41c-0.87,0.1-1.43-0.6-1.43-1.54c-0.01-0.79,0.84-1.67,1.54-1.43
	C391.88,335.48,392.38,336.09,393.3,336.82z"
              />
              <path
                d="M527.15,296.29c-0.85,0.75-1.31,1.46-1.92,1.64c-0.85,0.25-1.48-0.36-1.65-1.26c-0.17-0.88,0.61-1.93,1.43-1.75
	C525.66,295.06,526.2,295.66,527.15,296.29z"
              />
              <path
                d="M408.49,116.57c0.69,0.92,1.31,1.42,1.48,2.05c0.23,0.88-0.65,1.62-1.6,1.54c-0.94-0.08-1.54-0.7-1.36-1.55
	C407.12,117.99,407.79,117.49,408.49,116.57z"
              />
              <path
                d="M361.84,429.83c-0.89,0.69-1.39,1.33-2.02,1.52c-0.79,0.24-1.67-0.77-1.57-1.63c0.1-0.92,0.74-1.54,1.57-1.39
	C360.43,428.44,360.95,429.14,361.84,429.83z"
              />
              <path
                d="M385.83,417.03c-0.95,0.63-1.51,1.27-2.13,1.35c-0.86,0.1-1.43-0.57-1.47-1.49c-0.04-0.88,0.9-1.8,1.69-1.51
	C384.53,415.61,384.98,416.27,385.83,417.03z"
              />
              <path
                d="M477.89,567.71c0.04,0.86-0.59,1.56-1.45,1.56c-0.94,0.01-1.54-0.48-1.58-1.42c-0.04-0.93,0.49-1.49,1.44-1.56
	C477.15,566.23,477.85,566.87,477.89,567.71z"
              />
              <path
                d="M347.92,628.18c-0.75-0.87-1.39-1.33-1.62-1.95c-0.29-0.78,0.63-1.75,1.48-1.69c0.89,0.06,1.58,0.61,1.45,1.49
	C349.14,626.67,348.53,627.22,347.92,628.18z"
              />
              <path
                d="M366.95,265.18c-0.69-0.93-1.31-1.43-1.47-2.05c-0.22-0.88,0.66-1.62,1.61-1.54c0.94,0.08,1.53,0.71,1.36,1.55
	C368.32,263.75,367.65,264.26,366.95,265.18z"
              />
              <path
                d="M358.67,567.36c-0.06,0.83-0.52,1.4-1.48,1.43c-0.96,0.03-1.49-0.51-1.56-1.44c-0.07-0.84,0.61-1.53,1.47-1.55
	C357.95,565.78,358.63,566.41,358.67,567.36z"
              />
              <path
                d="M420.84,612.59c-0.87,0.71-1.35,1.36-1.97,1.56c-0.79,0.26-1.68-0.7-1.61-1.57c0.07-0.92,0.7-1.55,1.52-1.42
	C419.41,611.25,419.93,611.93,420.84,612.59z"
              />
              <path
                d="M386.33,464.06c-1,0.63-1.58,1.27-2.2,1.31c-0.85,0.06-1.46-0.62-1.39-1.57c0.07-0.93,0.74-1.55,1.59-1.36
	C384.94,462.57,385.42,463.29,386.33,464.06z"
              />
              <path
                d="M434.76,398.01c-0.84,0.76-1.29,1.41-1.9,1.65c-0.78,0.3-1.72-0.62-1.68-1.48c0.03-0.92,0.62-1.58,1.46-1.49
	C433.25,396.74,433.81,397.38,434.76,398.01z"
              />
              <path
                d="M475.35,239.37c-0.95,0.68-1.48,1.28-2.13,1.47c-0.71,0.21-1.49-0.73-1.48-1.52c0.01-0.79,0.82-1.7,1.52-1.47
	C473.91,238.05,474.42,238.66,475.35,239.37z"
              />
              <path
                d="M655.43,629.74c0.65,0.94,1.3,1.48,1.4,2.12c0.14,0.86-0.52,1.46-1.43,1.55c-0.85,0.08-1.81-0.86-1.54-1.66
	C654.08,631.11,654.71,630.62,655.43,629.74z"
              />
              <path
                d="M427.51,252.09c0.64,0.99,1.22,1.54,1.37,2.19c0.18,0.76-0.73,1.55-1.51,1.46c-0.89-0.11-1.6-0.67-1.41-1.56
	C426.1,253.54,426.78,253.03,427.51,252.09z"
              />
              <path
                d="M288.35,89.48c-0.12,0.88-0.61,1.43-1.57,1.4c-0.95-0.04-1.49-0.6-1.46-1.54c0.03-0.94,0.61-1.45,1.56-1.45
	C287.73,87.88,288.38,88.59,288.35,89.48z"
              />
              <path
                d="M403.41,617.85c-1,0.62-1.56,1.2-2.19,1.29c-0.91,0.14-1.42-0.55-1.47-1.45c-0.04-0.92,0.82-1.74,1.69-1.45
	C402.05,616.44,402.51,617.09,403.41,617.85z"
              />
              <path
                d="M347.16,225.2c-0.12,0.96-0.64,1.47-1.58,1.47c-0.87,0-1.54-0.69-1.47-1.54c0.08-0.93,0.6-1.48,1.56-1.44
	C346.63,223.72,347.08,224.31,347.16,225.2z"
              />
              <path
                d="M375.51,441.52c0.87,0.11,1.41,0.63,1.37,1.57c-0.04,0.95-0.63,1.43-1.58,1.43c-0.87-0.01-1.53-0.71-1.45-1.56
	C373.95,442.03,374.48,441.53,375.51,441.52z"
              />
              <path
                d="M387.34,331.54c0.71,0.91,1.34,1.4,1.51,2.02c0.25,0.88-0.62,1.63-1.58,1.57c-0.94-0.07-1.55-0.67-1.39-1.53
	C386,332.98,386.65,332.46,387.34,331.54z"
              />
              <path
                d="M428.64,143.67c-0.98-0.06-1.53-0.59-1.51-1.52c0.01-0.93,0.56-1.49,1.52-1.48c0.95,0.02,1.5,0.57,1.49,1.51
	C430.14,143.13,429.56,143.62,428.64,143.67z"
              />
              <path
                d="M247.16,400.07c-0.88,0.7-1.38,1.33-2.02,1.53c-0.79,0.24-1.67-0.76-1.58-1.62c0.1-0.92,0.74-1.55,1.56-1.4
	C245.75,398.69,246.26,399.38,247.16,400.07z"
              />
              <path
                d="M304.02,348.26c-0.89,0.69-1.39,1.31-2.02,1.5c-0.81,0.24-1.64-0.72-1.55-1.61c0.1-0.91,0.7-1.54,1.55-1.38
	C302.62,346.9,303.13,347.57,304.02,348.26z"
              />
              <path
                d="M305.47,322.83c-0.9,0.69-1.4,1.32-2.04,1.51c-0.8,0.24-1.66-0.75-1.56-1.63c0.11-0.91,0.71-1.56,1.56-1.39
	C304.06,321.45,304.57,322.14,305.47,322.83z"
              />
              <path
                d="M479.86,210.07c-0.89,0.69-1.38,1.32-2.01,1.51c-0.79,0.24-1.66-0.77-1.56-1.62c0.1-0.92,0.74-1.53,1.56-1.38
	C478.47,208.69,478.98,209.38,479.86,210.07z"
              />
              <path
                d="M329.63,542.42c0.75,0.87,1.39,1.33,1.62,1.95c0.29,0.79-0.62,1.74-1.48,1.69c-0.9-0.06-1.58-0.61-1.45-1.48
	C328.41,543.94,329.02,543.38,329.63,542.42z"
              />
              <path
                d="M384.33,322.93c-0.06,0.94-0.45,1.57-1.42,1.62c-0.95,0.05-1.55-0.43-1.62-1.37c-0.06-0.93,0.44-1.52,1.39-1.6
	C383.65,321.5,384.14,322.06,384.33,322.93z"
              />
              <path
                d="M490.2,411.56c-0.88,0.71-1.36,1.34-1.99,1.54c-0.81,0.26-1.66-0.68-1.59-1.58c0.08-0.91,0.67-1.56,1.52-1.41
	C488.77,410.22,489.29,410.89,490.2,411.56z"
              />
              <path
                d="M609.63,207.96c0.29-0.11,0.59-0.22,1.09-0.4c0.35,0.73,0.79,1.44,1.03,2.22c0.11,0.36-0.07,1.12-0.29,1.19
	c-0.44,0.15-1.13,0.13-1.47-0.14c-1.07-0.83-0.82-1.91-0.28-2.96C609.71,207.88,609.63,207.96,609.63,207.96z"
              />
              <path
                d="M358.25,473.5c-0.9,0.69-1.4,1.32-2.04,1.51c-0.8,0.24-1.66-0.75-1.56-1.63c0.11-0.91,0.71-1.56,1.56-1.39
	C356.84,472.12,357.36,472.81,358.25,473.5z"
              />
              <path
                d="M386.8,111.86c0.27,0.35,0.95,0.85,1.08,1.47c0.19,0.89-0.46,1.49-1.41,1.53c-0.96,0.04-1.49-0.5-1.57-1.43
	C384.82,112.59,385.5,111.9,386.8,111.86z"
              />
              <path
                d="M299.48,614.28c-0.65-0.94-1.3-1.48-1.41-2.12c-0.14-0.86,0.53-1.45,1.42-1.55c0.85-0.09,1.81,0.86,1.54,1.66
	C300.83,612.9,300.19,613.39,299.48,614.28z"
              />
              <path
                d="M392.01,96.17c0.75,0.87,1.39,1.33,1.62,1.95c0.29,0.79-0.62,1.74-1.48,1.69c-0.9-0.06-1.58-0.61-1.45-1.48
	C390.79,97.69,391.4,97.13,392.01,96.17z"
              />
              <path
                d="M396.64,106.72c0.71,0.89,1.37,1.38,1.53,2c0.22,0.86-0.39,1.47-1.28,1.63c-0.84,0.15-1.86-0.69-1.66-1.51
	C395.39,108.2,395.99,107.67,396.64,106.72z"
              />
              <path
                d="M367.1,347.59c0.73,0.88,1.38,1.36,1.6,1.98c0.28,0.8-0.66,1.75-1.51,1.68c-0.91-0.07-1.58-0.65-1.45-1.52
	C365.83,349.1,366.46,348.55,367.1,347.59z"
              />
              <path
                d="M438.88,401.61c-0.98,0.66-1.54,1.32-2.17,1.39c-0.87,0.1-1.42-0.61-1.42-1.55c0-0.79,0.85-1.67,1.55-1.42
	C437.47,400.26,437.97,400.88,438.88,401.61z"
              />
              <path
                d="M345.28,615.42c-0.85,0.73-1.32,1.43-1.93,1.6c-0.84,0.23-1.47-0.37-1.62-1.28c-0.15-0.89,0.62-1.91,1.44-1.71
	C343.82,614.17,344.35,614.77,345.28,615.42z"
              />
              <path
                d="M361.68,579.36c-0.87-0.03-1.47-0.49-1.51-1.44c-0.03-0.93,0.49-1.51,1.45-1.55c0.96-0.05,1.49,0.5,1.58,1.43
	C363.28,578.65,362.61,579.33,361.68,579.36z"
              />
              <path
                d="M525.22,258.71c-0.65-0.95-1.24-1.49-1.39-2.13c-0.19-0.83,0.83-1.65,1.67-1.49c0.88,0.17,1.5,0.78,1.26,1.64
	C526.6,257.35,525.94,257.83,525.22,258.71z"
              />
              <path
                d="M440.8,383.17c-0.91,0.75-1.39,1.38-2.02,1.61c-0.73,0.26-1.54-0.56-1.57-1.39c-0.02-0.95,0.52-1.65,1.39-1.56
	C439.24,381.88,439.81,382.53,440.8,383.17z"
              />
              <path
                d="M355.57,594.82c-0.64-0.96-1.27-1.5-1.35-2.12c-0.12-0.87,0.52-1.44,1.45-1.47c0.93-0.03,1.79,0.8,1.51,1.64
	C356.98,593.48,356.33,593.94,355.57,594.82z"
              />
              <path
                d="M492.89,234.36c-0.97,0.66-1.5,1.27-2.13,1.39c-0.88,0.17-1.46-0.46-1.53-1.39c-0.07-0.93,0.72-1.78,1.62-1.53
	C491.48,233.01,491.97,233.64,492.89,234.36z"
              />
              <path
                d="M682.61,591.36c-0.17-1.16-1.05-2.46,0.77-3.26c1.07,0.52,1.64,1.45,1.35,2.62c-0.32,1.3-1.47,0.32-2.21,0.55
	C682.53,591.28,682.61,591.36,682.61,591.36z"
              />
              <path
                d="M353.92,256.98c-0.85,0.73-1.32,1.43-1.93,1.6c-0.84,0.23-1.47-0.37-1.62-1.28c-0.15-0.89,0.62-1.91,1.44-1.71
	C352.45,255.73,352.99,256.33,353.92,256.98z"
              />
              <path
                d="M263.16,257.71c0.85-0.75,1.31-1.46,1.93-1.63c0.85-0.24,1.48,0.37,1.65,1.27c0.16,0.87-0.62,1.92-1.44,1.74
	C264.66,258.95,264.11,258.35,263.16,257.71z"
              />
              <path
                d="M517.79,355.13c-0.95,0.63-1.51,1.27-2.13,1.35c-0.86,0.1-1.43-0.57-1.47-1.49c-0.04-0.88,0.9-1.8,1.69-1.51
	C516.49,353.71,516.94,354.37,517.79,355.13z"
              />
              <path
                d="M302.11,326.8c-0.94,0.64-1.49,1.24-2.13,1.38c-0.82,0.19-1.6-0.86-1.44-1.74c0.16-0.9,0.8-1.51,1.65-1.28
	C300.79,325.35,301.26,326.05,302.11,326.8z"
              />
              <path
                d="M462.76,578.29c-0.7-0.89-1.36-1.38-1.52-2c-0.22-0.87,0.4-1.47,1.29-1.62c0.85-0.15,1.86,0.69,1.65,1.51
	C464.03,576.81,463.43,577.34,462.76,578.29z"
              />
              <path
                d="M347.02,550.36c-0.9,0.75-1.37,1.41-1.98,1.59c-0.86,0.26-1.5-0.31-1.66-1.22c-0.17-0.9,0.56-1.85,1.46-1.68
	C345.47,549.17,346.02,549.74,347.02,550.36z"
              />
              <path
                d="M332.67,365.12c0.69,0.92,1.31,1.42,1.48,2.05c0.23,0.88-0.65,1.62-1.6,1.54c-0.94-0.08-1.54-0.7-1.36-1.55
	C331.31,366.55,331.97,366.04,332.67,365.12z"
              />
              <path
                d="M312.06,22.54c-0.85,0.65-1.35,1.26-1.97,1.44c-0.79,0.23-1.32-0.28-1.31-1.18c0.01-0.94,0.82-1.99,1.48-1.74
	C310.84,21.27,311.26,21.85,312.06,22.54z"
              />
              <path
                d="M359.6,467.53c0.67,0.99,1.31,1.55,1.4,2.19c0.13,0.9-0.62,1.42-1.51,1.47c-0.76,0.05-1.63-0.84-1.41-1.57
	C358.28,468.99,358.88,468.48,359.6,467.53z"
              />
              <path
                d="M266.14,203.4c-0.96,0.67-1.49,1.26-2.14,1.45c-0.71,0.2-1.47-0.74-1.46-1.53c0.02-0.79,0.83-1.69,1.53-1.45
	C264.71,202.07,265.21,202.68,266.14,203.4z"
              />
              <path
                d="M308.34,232.02c-0.85,0.74-1.32,1.45-1.94,1.62c-0.86,0.24-1.48-0.38-1.64-1.28c-0.16-0.87,0.64-1.92,1.45-1.73
	C306.87,230.77,307.4,231.37,308.34,232.02z"
              />
              <path
                d="M350.77,205.19c-0.64-0.96-1.27-1.5-1.35-2.12c-0.12-0.87,0.52-1.44,1.45-1.47c0.93-0.03,1.79,0.8,1.51,1.64
	C352.18,203.85,351.53,204.31,350.77,205.19z"
              />
              <path
                d="M486.8,232.44c-0.88-0.09-1.43-0.61-1.38-1.56c0.04-0.94,0.62-1.46,1.57-1.43c0.96,0.03,1.48,0.63,1.44,1.55
	C488.39,231.93,487.82,232.43,486.8,232.44z"
              />
              <path
                d="M442.71,405.02c-0.99,0.63-1.56,1.27-2.19,1.32c-0.87,0.07-1.39-0.63-1.36-1.56c0.03-0.8,0.86-1.63,1.56-1.36
	C441.34,403.65,441.82,404.27,442.71,405.02z"
              />
              <path
                d="M312.67,488.22c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C311.09,486.96,311.68,487.6,312.67,488.22z"
              />
              <path
                d="M442.04,381.55c-0.66-0.96-1.3-1.5-1.4-2.13c-0.14-0.88,0.54-1.49,1.45-1.47c0.89,0.02,1.6,0.61,1.44,1.5
	C443.41,380.08,442.74,380.61,442.04,381.55z"
              />
              <path
                d="M379.85,471.73c-0.99,0.62-1.57,1.26-2.2,1.31c-0.87,0.07-1.39-0.64-1.35-1.57c0.03-0.81,0.86-1.62,1.57-1.36
	C378.49,470.35,378.96,470.98,379.85,471.73z"
              />
              <path
                d="M383.21,467.83c-0.97,0.65-1.53,1.3-2.16,1.37c-0.88,0.1-1.4-0.6-1.4-1.53c0-0.81,0.82-1.65,1.53-1.4
	C381.81,466.49,382.3,467.11,383.21,467.83z"
              />
              <path
                d="M364.98,465.82c-0.94,0.69-1.47,1.36-2.09,1.46c-0.88,0.15-1.46-0.54-1.46-1.45c-0.01-0.92,0.59-1.6,1.46-1.46
	C363.51,464.46,364.04,465.13,364.98,465.82z"
              />
              <path
                d="M415.57,136.05c-0.9,0.75-1.38,1.46-1.99,1.59c-0.84,0.17-1.5-0.45-1.57-1.37c-0.07-0.94,0.55-1.61,1.39-1.54
	C414.02,134.78,414.59,135.41,415.57,136.05z"
              />
              <path
                d="M353.18,562.08c0.74,0.93,1.42,1.43,1.57,2.05c0.2,0.89-0.5,1.49-1.38,1.55c-0.91,0.06-1.61-0.51-1.49-1.41
	C351.96,563.64,352.56,563.08,353.18,562.08z"
              />
              <path
                d="M443.19,411.93c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C441.61,410.66,442.19,411.31,443.19,411.93z"
              />
              <path
                d="M278.95,67.29c0.76,1.96,0.41,3.04-0.9,3.12c-0.45,0.03-1.06-0.3-1.35-0.67c-0.16-0.21,0.07-0.95,0.34-1.22
	C277.54,68.06,278.19,67.77,278.95,67.29z"
              />
              <path
                d="M387.05,431.2c-0.91,0.72-1.42,1.41-2.03,1.54c-0.87,0.18-1.47-0.48-1.51-1.4c-0.04-0.93,0.53-1.62,1.4-1.52
	C385.53,429.89,386.08,430.54,387.05,431.2z"
              />
              <path
                d="M518.72,615.36c-1.04,0.55-1.66,1.15-2.27,1.14c-0.84-0.01-1.41-0.71-1.26-1.66c0.15-0.91,0.84-1.48,1.67-1.23
	C517.46,613.79,517.88,614.53,518.72,615.36z"
              />
              <path
                d="M356.07,72.84c-0.94,0.69-1.47,1.36-2.08,1.45c-0.85,0.13-1.48-0.54-1.48-1.46c0-0.93,0.64-1.58,1.49-1.45
	C354.61,71.47,355.13,72.15,356.07,72.84z"
              />
              <path
                d="M497.89,584.49c-0.99,0.62-1.57,1.26-2.2,1.31c-0.87,0.07-1.39-0.64-1.35-1.57c0.03-0.81,0.86-1.62,1.57-1.36
	C496.53,583.11,497,583.74,497.89,584.49z"
              />
              <path
                d="M425.92,337.59c-0.9,0.74-1.39,1.44-2,1.57c-0.86,0.19-1.48-0.43-1.54-1.37c-0.06-0.94,0.51-1.63,1.37-1.55
	C424.36,336.3,424.93,336.95,425.92,337.59z"
              />
              <path
                d="M345.99,622.6c-0.9,0.75-1.38,1.46-1.99,1.59c-0.84,0.17-1.5-0.45-1.57-1.37c-0.07-0.94,0.55-1.61,1.39-1.54
	C344.45,621.33,345.01,621.97,345.99,622.6z"
              />
              <path
                d="M448.47,595.81c-0.92,0.71-1.44,1.39-2.06,1.51c-0.88,0.17-1.47-0.5-1.49-1.42c-0.03-0.93,0.55-1.61,1.42-1.49
	C446.97,594.49,447.52,595.15,448.47,595.81z"
              />
              <path
                d="M365.95,464.09c-0.67-0.98-1.31-1.53-1.4-2.16c-0.13-0.89,0.61-1.46,1.5-1.44c0.9,0.02,1.57,0.64,1.38,1.53
	C367.3,462.64,366.65,463.15,365.95,464.09z"
              />
              <path
                d="M389.66,626.17c-0.97,0.65-1.52,1.31-2.13,1.37c-0.85,0.09-1.46-0.58-1.42-1.51c0.04-0.93,0.7-1.55,1.54-1.4
	C388.25,624.74,388.75,625.44,389.66,626.17z"
              />
              <path
                d="M379.81,455.45c-0.73-0.92-1.38-1.41-1.54-2.03c-0.22-0.89,0.44-1.53,1.33-1.58c0.87-0.05,1.64,0.49,1.54,1.39
	C381.07,453.88,380.44,454.45,379.81,455.45z"
              />
              <path
                d="M443,597.59c0.7,0.96,1.37,1.48,1.48,2.11c0.16,0.88-0.55,1.47-1.44,1.49c-0.92,0.02-1.58-0.6-1.44-1.48
	C441.7,599.09,442.34,598.56,443,597.59z"
              />
              <path
                d="M504.61,243.99c-0.89,0.75-1.36,1.38-1.98,1.62c-0.71,0.27-1.53-0.53-1.57-1.35c-0.05-0.95,0.5-1.64,1.35-1.58
	C503.03,242.72,503.61,243.36,504.61,243.99z"
              />
              <path
                d="M455.43,242.51c0.72,0.94,1.4,1.45,1.53,2.07c0.18,0.88-0.52,1.48-1.41,1.53c-0.92,0.05-1.6-0.55-1.47-1.44
	C454.17,244.04,454.79,243.49,455.43,242.51z"
              />
              <path
                d="M475.34,571.92c-0.96,0.66-1.51,1.32-2.13,1.41c-0.88,0.12-1.41-0.58-1.43-1.5c-0.02-0.8,0.79-1.66,1.5-1.43
	C473.92,570.61,474.42,571.21,475.34,571.92z"
              />
              <path
                d="M466.11,15.95c0.84-0.51,1.28-0.96,1.76-1.03c0.96-0.12,1.83,0.68,1.79,1.44c-0.04,0.74-0.98,1.57-1.65,1.27
	C467.4,17.36,466.96,16.73,466.11,15.95z"
              />
              <path
                d="M439.37,186.74c-0.67-0.98-1.31-1.53-1.4-2.16c-0.13-0.89,0.61-1.46,1.5-1.44c0.9,0.02,1.57,0.64,1.38,1.53
	C440.71,185.29,440.07,185.81,439.37,186.74z"
              />
              <path
                d="M383.94,458.81c-0.77-0.9-1.46-1.38-1.63-2c-0.24-0.89,0.46-1.45,1.33-1.6c0.77-0.14,1.69,0.6,1.55,1.37
	C385.06,457.23,384.52,457.8,383.94,458.81z"
              />
              <path
                d="M406.3,344.2c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C407.81,343.56,407.17,344.21,406.3,344.2z"
              />
              <path
                d="M365.06,266.96c-0.02,0.88-0.68,1.49-1.56,1.42c-0.84-0.07-1.3-0.56-1.38-1.37c-0.08-0.82,0.61-1.52,1.46-1.55
	C364.44,265.44,365.08,266.09,365.06,266.96z"
              />
              <path
                d="M403.61,265.08c0.58,1.01,1.16,1.58,1.22,2.21c0.09,0.9-0.63,1.44-1.52,1.35c-0.86-0.09-1.56-0.7-1.32-1.58
	C402.15,266.45,402.85,265.98,403.61,265.08z"
              />
              <path
                d="M593.51,627.78c-0.86,0.03-1.55-0.61-1.56-1.45c0-0.82,0.7-1.51,1.54-1.48c0.85,0.03,1.31,0.52,1.4,1.33
	C595,627.07,594.37,627.75,593.51,627.78z"
              />
              <path
                d="M318.37,536.13c-0.88,0.06-1.56-0.54-1.55-1.42c0.01-0.82,0.41-1.36,1.25-1.49c0.83-0.12,1.61,0.49,1.7,1.3
	C319.85,535.34,319.22,536.07,318.37,536.13z"
              />
              <path
                d="M441.78,603.54c-1,0.69-1.54,1.28-2.18,1.44c-0.75,0.19-1.44-0.73-1.37-1.58c0.07-0.85,0.65-1.44,1.39-1.31
	C440.25,602.22,440.79,602.86,441.78,603.54z"
              />
              <path
                d="M440.39,343.55c-0.05,0.83-0.88,1.65-1.58,1.35c-0.56-0.24-1.13-0.85-1.32-1.42c-0.24-0.72,0.66-1.51,1.47-1.5
	C439.78,341.98,440.44,342.71,440.39,343.55z"
              />
              <path
                d="M302.68,214.56c0.04,0.87-0.59,1.53-1.45,1.53c-0.85,0-1.55-0.69-1.48-1.51c0.07-0.81,0.52-1.31,1.35-1.41
	C301.95,213.07,302.64,213.69,302.68,214.56z"
              />
              <path
                d="M271.3,267.89c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C270.6,266.39,271.3,267.06,271.3,267.89z"
              />
              <path
                d="M433.13,170.61c-0.95,0.74-1.46,1.4-2.09,1.55c-0.74,0.17-1.33-0.39-1.45-1.23c-0.12-0.85,0.51-1.8,1.28-1.65
	C431.54,169.4,432.1,169.97,433.13,170.61z"
              />
              <path
                d="M397.66,267.93c-0.02,0.87-0.68,1.47-1.54,1.39c-0.82-0.08-1.28-0.57-1.36-1.37c-0.08-0.79,0.63-1.51,1.45-1.52
	C397.06,266.42,397.69,267.06,397.66,267.93z"
              />
              <path
                d="M379.87,625.32c1.38-0.03,1.95,0.46,2,1.38c0.06,0.91-0.42,1.53-1.38,1.57c-0.93,0.04-1.65-0.46-1.55-1.37
	C379.01,626.29,379.62,625.72,379.87,625.32z"
              />
              <path
                d="M347.73,212.66c1.38-0.02,1.94,0.48,1.99,1.4c0.05,0.91-0.43,1.52-1.39,1.56c-0.93,0.04-1.64-0.47-1.54-1.38
	C346.86,213.62,347.48,213.05,347.73,212.66z"
              />
              <path
                d="M372.76,439.68c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C372.26,438.21,372.68,438.8,372.76,439.68z"
              />
              <path
                d="M415.07,341.97c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C414.21,340.98,414.69,341.66,415.07,341.97z"
              />
              <path
                d="M358.33,580.23c0.86,0,1.51,0.67,1.44,1.54c-0.06,0.82-0.49,1.33-1.35,1.39c-0.86,0.06-1.57-0.57-1.59-1.41
	C356.81,580.93,357.49,580.23,358.33,580.23z"
              />
              <path
                d="M284.9,93.7c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C284.45,92.07,285.03,92.85,284.9,93.7z"
              />
              <path
                d="M331.62,477.17c0.59,1,1.17,1.57,1.24,2.2c0.1,0.91-0.62,1.44-1.51,1.36c-0.87-0.08-1.56-0.69-1.33-1.57
	C330.17,478.55,330.86,478.07,331.62,477.17z"
              />
              <path
                d="M373.62,344.11c-0.66-0.98-1.3-1.53-1.38-2.15c-0.12-0.89,0.62-1.44,1.5-1.42c0.89,0.02,1.56,0.64,1.36,1.53
	C374.96,342.68,374.32,343.19,373.62,344.11z"
              />
              <path
                d="M376.98,439.29c-0.06-0.82,0.6-1.54,1.44-1.57c0.85-0.02,1.34,0.43,1.49,1.24c0.15,0.84-0.45,1.61-1.28,1.68
	C377.8,440.73,377.04,440.11,376.98,439.29z"
              />
              <path
                d="M447.13,422.4c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C446.64,420.94,447.06,421.53,447.13,422.4z"
              />
              <path
                d="M323.67,537.62c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C322.72,536.72,323.25,537.34,323.67,537.62z"
              />
              <path
                d="M438.41,148.67c-1,0.67-1.54,1.26-2.18,1.41c-0.76,0.18-1.42-0.73-1.34-1.58c0.08-0.85,0.65-1.42,1.39-1.28
	C436.9,147.35,437.43,147.98,438.41,148.67z"
              />
              <path
                d="M298.41,389.84c0.77,0.9,1.46,1.37,1.62,1.98c0.24,0.88-0.46,1.49-1.32,1.58c-0.9,0.09-1.62-0.45-1.53-1.35
	C297.26,391.43,297.83,390.85,298.41,389.84z"
              />
              <path
                d="M504.82,390.42c0.28,0.37,0.89,0.89,1.08,1.52c0.21,0.72-0.73,1.55-1.51,1.47c-0.79-0.08-1.32-0.52-1.39-1.37
	C502.93,391.19,503.58,390.46,504.82,390.42z"
              />
              <path
                d="M387.25,265.29c-0.95,0.67-1.48,1.33-2.09,1.41c-0.84,0.11-1.47-0.55-1.45-1.47c0.02-0.92,0.66-1.56,1.5-1.42
	C385.82,263.91,386.32,264.58,387.25,265.29z"
              />
              <path
                d="M434.83,338.87c0.84-0.02,1.54,0.66,1.54,1.48c0,0.83-0.7,1.49-1.56,1.45c-0.86-0.04-1.3-0.53-1.38-1.35
	C433.34,339.58,433.97,338.89,434.83,338.87z"
              />
              <path
                d="M376.52,170.45c1.35-0.02,1.91,0.46,1.97,1.37c0.05,0.9-0.41,1.51-1.36,1.56c-0.92,0.04-1.62-0.47-1.53-1.36
	C375.66,171.4,376.27,170.84,376.52,170.45z"
              />
              <path
                d="M310.99,616.26c-1.29-0.03-1.91-0.7-1.82-1.56c0.08-0.81,0.53-1.29,1.36-1.34c0.81-0.05,1.69,0.69,1.49,1.43
	C311.85,615.41,311.25,615.91,310.99,616.26z"
              />
              <path
                d="M501.29,394.54c0.86-0.02,1.52,0.62,1.48,1.5c-0.04,0.82-0.47,1.34-1.31,1.43c-0.84,0.09-1.58-0.56-1.63-1.37
	C499.78,395.26,500.44,394.56,501.29,394.54z"
              />
              <path
                d="M452.85,589.43c-0.92-0.05-1.49-0.54-1.5-1.47c-0.01-0.93,0.53-1.47,1.48-1.5c0.96-0.02,1.49,0.54,1.5,1.46
	C454.34,588.84,453.83,589.38,452.85,589.43z"
              />
              <path
                d="M238.81,386.41c-1.02,0.67-1.55,1.26-2.18,1.39c-0.8,0.16-1.28-0.47-1.36-1.28c-0.09-0.85,0.57-1.7,1.36-1.52
	C237.27,385.15,237.8,385.73,238.81,386.41z"
              />
              <path
                d="M327.77,481.83c1.27,0.08,1.9,0.83,1.77,1.67c-0.12,0.81-0.59,1.28-1.45,1.28c-0.85,0-1.68-0.74-1.44-1.5
	C326.85,482.67,327.49,482.18,327.77,481.83z"
              />
              <path
                d="M307.48,624.41c1.29-0.04,1.93,0.6,1.89,1.47c-0.04,0.81-0.46,1.32-1.29,1.41c-0.82,0.09-1.73-0.6-1.56-1.35
	C306.65,625.31,307.23,624.77,307.48,624.41z"
              />
              <path
                d="M413.91,264.09c-1.05-0.01-1.6-0.51-1.63-1.44c-0.03-0.91,0.47-1.51,1.43-1.53c0.94-0.02,1.51,0.5,1.55,1.43
	C415.3,263.49,414.75,264,413.91,264.09z"
              />
              <path
                d="M376.07,262.58c-0.98,0.7-1.5,1.32-2.11,1.45c-0.92,0.19-1.47-0.48-1.48-1.4c-0.01-0.91,0.52-1.61,1.44-1.45
	C374.53,261.29,375.07,261.9,376.07,262.58z"
              />
              <path
                d="M255.15,334.58c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C254.7,332.95,255.28,333.73,255.15,334.58z"
              />
              <path
                d="M320.38,482.39c1.28-0.08,1.96,0.54,1.94,1.4c-0.01,0.81-0.4,1.37-1.24,1.45c-0.93,0.09-1.66-0.41-1.6-1.29
	C319.52,483.34,320.15,482.77,320.38,482.39z"
              />
              <path
                d="M305.8,226.63c-0.86,0.01-1.5-0.64-1.44-1.51c0.05-0.82,0.49-1.32,1.33-1.4c0.82-0.07,1.56,0.59,1.59,1.4
	C307.31,225.93,306.64,226.62,305.8,226.63z"
              />
              <path
                d="M427.42,611.97c-0.02,0.87-0.69,1.48-1.55,1.4c-0.83-0.08-1.29-0.57-1.37-1.37c-0.08-0.8,0.63-1.51,1.46-1.53
	C426.82,610.45,427.45,611.1,427.42,611.97z"
              />
              <path
                d="M497.87,398.38c0.87-0.06,1.55,0.56,1.54,1.44c-0.01,0.82-0.41,1.36-1.26,1.47c-0.84,0.11-1.61-0.5-1.68-1.31
	C496.39,399.17,497.04,398.43,497.87,398.38z"
              />
              <path
                d="M367.38,438.64c-0.66-0.98-1.3-1.53-1.38-2.15c-0.12-0.89,0.62-1.44,1.5-1.42c0.89,0.02,1.56,0.64,1.36,1.53
	C368.72,437.21,368.08,437.72,367.38,438.64z"
              />
              <path
                d="M501.86,594.66c-0.11,0.93-0.57,1.5-1.53,1.47c-0.94-0.03-1.49-0.57-1.47-1.5c0.02-0.93,0.58-1.46,1.52-1.46
	C501.35,593.18,501.77,593.78,501.86,594.66z"
              />
              <path
                d="M341.47,612.06c-0.95,0.73-1.45,1.39-2.08,1.54c-0.73,0.17-1.32-0.38-1.44-1.22c-0.12-0.85,0.5-1.78,1.27-1.64
	C339.88,610.86,340.45,611.43,341.47,612.06z"
              />
              <path
                d="M267.07,383.66c-0.18,1.32-0.69,1.84-1.63,1.82c-0.96-0.03-1.46-0.62-1.42-1.53c0.04-0.92,0.61-1.59,1.53-1.37
	C266.16,382.72,266.66,383.36,267.07,383.66z"
              />
              <path
                d="M305.12,615.17c-0.07,0.96-0.48,1.56-1.44,1.59c-0.93,0.02-1.51-0.49-1.54-1.41c-0.03-0.92,0.49-1.47,1.43-1.53
	C304.53,613.76,304.96,614.35,305.12,615.17z"
              />
              <path
                d="M541.55,627.51c-0.93,0.69-1.45,1.36-2.07,1.46c-0.87,0.15-1.45-0.52-1.46-1.44c-0.01-0.92,0.58-1.59,1.44-1.45
	C540.08,626.18,540.61,626.84,541.55,627.51z"
              />
              <path
                d="M434.04,145.12c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C433.08,144.21,433.62,144.83,434.04,145.12z"
              />
              <path
                d="M365.45,473.03c-0.94,0.68-1.47,1.34-2.09,1.44c-0.87,0.14-1.45-0.54-1.44-1.46c0-0.92,0.6-1.58,1.46-1.44
	C363.99,471.68,364.52,472.35,365.45,473.03z"
              />
              <path
                d="M388.28,96.6c-0.86-0.02-1.5-0.7-1.4-1.57c0.09-0.81,0.53-1.31,1.39-1.35c0.84-0.04,1.55,0.63,1.56,1.46
	C389.83,95.97,389.14,96.62,388.28,96.6z"
              />
              <path
                d="M371.07,473.77c-1.37,0-1.91-0.49-1.94-1.41c-0.03-0.91,0.45-1.5,1.39-1.53c0.93-0.03,1.6,0.52,1.5,1.4
	C371.94,472.84,371.32,473.38,371.07,473.77z"
              />
              <path
                d="M396.23,625.72c-0.06,0.82-0.89,1.62-1.59,1.31c-0.55-0.25-1.11-0.86-1.29-1.43c-0.23-0.71,0.7-1.49,1.49-1.47
	C395.66,624.15,396.29,624.87,396.23,625.72z"
              />
              <path
                d="M335.42,547.33c-0.22,1.32-0.72,1.84-1.67,1.77c-0.95-0.07-1.45-0.65-1.39-1.57c0.06-0.93,0.7-1.55,1.58-1.33
	C334.55,546.35,335.04,547.02,335.42,547.33z"
              />
              <path
                d="M506.42,605.21c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C505.72,603.72,506.42,604.39,506.42,605.21z"
              />
              <path
                d="M302.63,207.48c-1.03,0.65-1.58,1.21-2.22,1.34c-0.79,0.16-1.44-0.71-1.32-1.56c0.11-0.8,0.61-1.43,1.4-1.24
	C301.12,206.17,301.63,206.78,302.63,207.48z"
              />
              <path
                d="M475.43,226.84c1.24-0.04,1.92,0.66,1.9,1.5c-0.03,0.85-0.54,1.31-1.31,1.44c-0.75,0.13-1.74-0.67-1.57-1.38
	C474.6,227.76,475.18,227.22,475.43,226.84z"
              />
              <path
                d="M274.49,383.38c-0.97,0.65-1.52,1.29-2.12,1.35c-0.84,0.08-1.45-0.58-1.41-1.51c0.04-0.92,0.7-1.54,1.54-1.38
	C273.08,381.96,273.58,382.65,274.49,383.38z"
              />
              <path
                d="M475.44,206.89c-0.05,0.88-0.45,1.49-1.41,1.52c-0.93,0.03-1.51-0.48-1.55-1.39c-0.04-0.91,0.48-1.47,1.41-1.53
	C474.84,205.43,475.31,205.97,475.44,206.89z"
              />
              <path
                d="M257.09,328.42c0.67,0.95,1.3,1.48,1.41,2.09c0.15,0.87-0.51,1.49-1.42,1.48c-0.88-0.01-1.59-0.6-1.45-1.47
	C255.73,329.9,256.39,329.38,257.09,328.42z"
              />
              <path
                d="M405.41,120.94c0.31,0.36,1.01,0.84,1.17,1.44c0.22,0.87-0.38,1.5-1.33,1.58c-0.95,0.08-1.53-0.43-1.6-1.35
	C403.58,121.7,404.06,121.13,405.41,120.94z"
              />
              <path
                d="M381.82,437.2c-0.68-0.96-1.34-1.5-1.43-2.12c-0.14-0.88,0.59-1.45,1.47-1.45c0.9,0,1.57,0.61,1.4,1.49
	C383.13,435.74,382.5,436.26,381.82,437.2z"
              />
              <path
                d="M423.41,617.18c-1.3,0.09-1.96-0.51-1.96-1.38c0-0.81,0.4-1.34,1.23-1.47c0.8-0.13,1.76,0.53,1.63,1.28
	C424.19,616.25,423.64,616.8,423.41,617.18z"
              />
              <path
                d="M494.58,405.13c-0.84-0.01-1.51-0.71-1.47-1.52c0.04-0.8,0.79-1.47,1.61-1.39c0.83,0.09,1.27,0.59,1.31,1.41
	C496.09,404.51,495.45,405.15,494.58,405.13z"
              />
              <path
                d="M337.68,552.44c-0.84-0.02-1.51-0.71-1.47-1.52c0.04-0.8,0.79-1.47,1.61-1.39c0.83,0.09,1.27,0.59,1.31,1.41
	C339.18,551.82,338.54,552.46,337.68,552.44z"
              />
              <path
                d="M360.23,328.93c-1.02,0.64-1.58,1.23-2.2,1.32c-0.93,0.13-1.43-0.58-1.39-1.49c0.04-0.91,0.62-1.57,1.53-1.35
	C358.78,327.55,359.27,328.19,360.23,328.93z"
              />
              <path
                d="M543.76,632.08c-1.29,0.01-1.93-0.63-1.87-1.5c0.05-0.81,0.49-1.31,1.32-1.39c0.81-0.08,1.71,0.63,1.54,1.38
	C544.6,631.2,544.01,631.72,543.76,632.08z"
              />
              <path
                d="M379.74,352.41c-0.04,0.82-0.78,1.45-1.62,1.39c-0.83-0.06-1.46-0.81-1.32-1.65c0.13-0.8,0.61-1.28,1.47-1.27
	C379.11,350.88,379.78,351.59,379.74,352.41z"
              />
              <path
                d="M313,468.69c0.24,0.38,0.81,0.93,0.94,1.58c0.15,0.73-0.85,1.49-1.6,1.34c-0.77-0.15-1.26-0.63-1.28-1.47
	C311.05,469.32,311.77,468.63,313,468.69z"
              />
              <path
                d="M280.12,328.48c0.06,0.83-0.6,1.59-1.4,1.61c-0.79,0.02-1.72-0.78-1.5-1.49c0.18-0.57,0.75-1.2,1.3-1.44
	C279.24,326.85,280.06,327.65,280.12,328.48z"
              />
              <path
                d="M377.03,632.1c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C378.54,631.47,377.9,632.11,377.03,632.1z"
              />
              <path
                d="M299.4,257.99c-0.2,1.31-0.7,1.83-1.64,1.76c-0.93-0.07-1.44-0.65-1.37-1.56c0.06-0.9,0.69-1.53,1.57-1.32
	C298.55,257.02,299.03,257.68,299.4,257.99z"
              />
              <path
                d="M409.65,134.51c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C411.17,133.87,410.52,134.52,409.65,134.51z"
              />
              <path
                d="M308.22,610.92c0.09,1.26-0.56,1.97-1.41,1.99c-0.85,0.02-1.35-0.42-1.49-1.24c-0.15-0.84,0.48-1.77,1.27-1.64
	C307.24,610.14,307.82,610.68,308.22,610.92z"
              />
              <path
                d="M262.96,380.36c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C262.1,379.37,262.58,380.04,262.96,380.36z"
              />
              <path
                d="M415.64,614.4c0.71,0.94,1.38,1.46,1.49,2.08c0.16,0.87-0.55,1.47-1.43,1.49c-0.91,0.03-1.58-0.57-1.44-1.45
	C414.36,615.9,414.99,615.36,415.64,614.4z"
              />
              <path
                d="M247.91,407.16c-0.95,0.74-1.45,1.41-2.08,1.56c-0.74,0.18-1.33-0.39-1.45-1.23c-0.12-0.85,0.5-1.8,1.28-1.65
	C246.31,405.96,246.88,406.53,247.91,407.16z"
              />
              <path
                d="M294.04,446.73c-0.05,0.83-0.89,1.65-1.59,1.34c-0.55-0.24-1.12-0.86-1.31-1.43c-0.23-0.72,0.68-1.51,1.48-1.5
	C293.44,445.15,294.09,445.88,294.04,446.73z"
              />
              <path
                d="M368.85,468.81c-0.97,0.73-1.46,1.35-2.08,1.52c-0.79,0.22-1.31-0.38-1.44-1.18c-0.14-0.83,0.49-1.75,1.26-1.62
	C367.22,467.64,367.79,468.19,368.85,468.81z"
              />
              <path
                d="M375.4,467.98c0.05,1.24-0.63,1.93-1.47,1.93c-0.85,0-1.32-0.48-1.43-1.28c-0.12-0.84,0.53-1.73,1.32-1.58
	C374.46,467.17,375.01,467.74,375.4,467.98z"
              />
              <path
                d="M342.96,554.28c0.04,0.87-0.58,1.52-1.43,1.51c-0.83-0.01-1.67-0.75-1.43-1.48c0.19-0.55,0.77-1.16,1.31-1.36
	C342.19,552.67,342.93,553.43,342.96,554.28z"
              />
              <path
                d="M444.7,354.13c-0.06,0.83-0.9,1.64-1.6,1.33c-0.55-0.25-1.12-0.87-1.3-1.45c-0.23-0.72,0.7-1.51,1.5-1.48
	C444.13,352.55,444.77,353.28,444.7,354.13z"
              />
              <path
                d="M491.5,408.97c-0.86,0.01-1.5-0.64-1.44-1.51c0.05-0.82,0.49-1.32,1.33-1.4c0.82-0.07,1.56,0.59,1.59,1.4
	C493.01,408.27,492.34,408.96,491.5,408.97z"
              />
              <path
                d="M237.47,379.2c-0.1,0.92-0.55,1.5-1.52,1.48c-0.94-0.02-1.49-0.55-1.48-1.48c0.01-0.93,0.56-1.46,1.5-1.47
	C236.94,377.72,237.37,378.31,237.47,379.2z"
              />
              <path
                d="M357.07,355.75c-1.37-0.06-1.87-0.59-1.86-1.51c0.01-0.91,0.52-1.47,1.47-1.45c0.93,0.02,1.57,0.6,1.42,1.47
	C358,354.87,357.34,355.38,357.07,355.75z"
              />
              <path
                d="M259.49,258.49c-0.37,0.25-0.89,0.85-1.52,0.99c-0.75,0.17-1.63-0.83-1.36-1.53c0.22-0.55,0.82-1.13,1.38-1.32
	C258.73,256.38,259.49,257.23,259.49,258.49z"
              />
              <path
                d="M345.36,555.39c0.68,0.94,1.32,1.46,1.43,2.08c0.16,0.87-0.5,1.49-1.4,1.49c-0.88,0-1.6-0.58-1.46-1.46
	C344.02,556.88,344.68,556.34,345.36,555.39z"
              />
              <path
                d="M552.05,629.66c-0.05,1.32-0.47,1.91-1.43,1.98c-0.96,0.07-1.53-0.45-1.59-1.36c-0.06-0.91,0.43-1.65,1.36-1.54
	C551.04,628.8,551.61,629.39,552.05,629.66z"
              />
              <path
                d="M435.1,199.62c-0.06,0.82-0.89,1.62-1.59,1.31c-0.55-0.25-1.11-0.86-1.29-1.43c-0.23-0.71,0.7-1.49,1.49-1.47
	C434.53,198.06,435.16,198.77,435.1,199.62z"
              />
              <path
                d="M447.59,157.04c1.29-0.04,1.93,0.6,1.89,1.47c-0.04,0.81-0.46,1.32-1.29,1.41c-0.82,0.09-1.73-0.6-1.56-1.35
	C446.77,157.95,447.35,157.41,447.59,157.04z"
              />
              <path
                d="M460.88,579.75c-0.05,1.32-0.47,1.91-1.43,1.98c-0.96,0.07-1.53-0.44-1.59-1.36c-0.06-0.91,0.43-1.65,1.36-1.54
	C459.87,578.9,460.44,579.49,460.88,579.75z"
              />
              <path
                d="M385.67,630.21c-0.02,0.87-0.68,1.47-1.54,1.39c-0.82-0.08-1.28-0.57-1.36-1.37c-0.08-0.79,0.63-1.51,1.45-1.52
	C385.07,628.69,385.69,629.34,385.67,630.21z"
              />
              <path
                d="M534.88,625.63c-0.24-0.39-0.81-0.96-0.93-1.61c-0.14-0.75,0.85-1.47,1.62-1.32c0.79,0.15,1.26,0.65,1.26,1.49
	C536.85,625.01,536.09,625.72,534.88,625.63z"
              />
              <path
                d="M280.3,95.22c0.58,1.01,1.15,1.59,1.21,2.22c0.09,0.9-0.64,1.44-1.53,1.34c-0.86-0.09-1.55-0.71-1.31-1.59
	C278.84,96.58,279.53,96.11,280.3,95.22z"
              />
              <path
                d="M274.9,197.24c0.31,0.36,1.01,0.84,1.17,1.44c0.22,0.87-0.38,1.5-1.33,1.58c-0.95,0.08-1.53-0.43-1.6-1.35
	C273.06,198,273.54,197.42,274.9,197.24z"
              />
              <path
                d="M470.25,574.19c0.29,0.37,0.91,0.88,1.1,1.51c0.22,0.72-0.71,1.55-1.49,1.48c-0.8-0.07-1.32-0.51-1.4-1.35
	C468.38,574.99,469.01,574.25,470.25,574.19z"
              />
              <path
                d="M363.59,431.11c0.68,0.94,1.32,1.46,1.43,2.08c0.16,0.87-0.5,1.49-1.4,1.49c-0.88,0-1.6-0.58-1.46-1.46
	C362.25,432.6,362.91,432.07,363.59,431.11z"
              />
              <path
                d="M308.61,468.89c-0.64-1.02-1.21-1.58-1.32-2.21c-0.15-0.81,0.53-1.28,1.31-1.37c0.78-0.09,1.73,0.71,1.52,1.43
	C309.94,467.38,309.33,467.9,308.61,468.89z"
              />
              <path
                d="M306.92,589.98c-0.94,0.74-1.43,1.39-2.05,1.54c-0.73,0.18-1.31-0.38-1.43-1.21c-0.12-0.84,0.5-1.77,1.25-1.63
	C305.33,588.81,305.9,589.36,306.92,589.98z"
              />
              <path
                d="M408.9,615.68c0.29,0.41,0.93,0.94,1.03,1.55c0.14,0.88-0.52,1.44-1.46,1.44c-0.96,0-1.46-0.57-1.45-1.48
	C407.02,616.27,407.57,615.77,408.9,615.68z"
              />
              <path
                d="M478.42,235.51c-0.94,0.68-1.47,1.35-2.07,1.44c-0.85,0.12-1.47-0.54-1.47-1.46c0-0.92,0.64-1.56,1.49-1.43
	C476.97,234.15,477.49,234.82,478.42,235.51z"
              />
              <path
                d="M351.53,560.97c-0.99,0.63-1.56,1.27-2.18,1.32c-0.85,0.07-1.41-0.62-1.34-1.56c0.07-0.93,0.7-1.53,1.55-1.33
	C350.16,559.54,350.64,560.23,351.53,560.97z"
              />
              <path
                d="M299.63,510.94c0.04,0.98-0.57,1.59-1.46,1.55c-0.97-0.04-1.42-0.62-1.43-1.51c0-0.83,0.52-1.29,1.34-1.32
	C299.02,509.63,299.54,510.13,299.63,510.94z"
              />
              <path
                d="M431.68,196.47c-1.1,0.53-1.72,1.04-2.37,1.1c-0.79,0.06-1.33-0.93-1.13-1.75c0.2-0.82,0.84-1.32,1.57-1.08
	C430.36,194.94,430.8,195.64,431.68,196.47z"
              />
              <path
                d="M351.61,195.99c-0.04,0.87-0.72,1.47-1.59,1.37c-0.83-0.1-1.27-0.6-1.33-1.41c-0.06-0.8,0.67-1.5,1.49-1.5
	C351.04,194.45,351.65,195.12,351.61,195.99z"
              />
              <path
                d="M444.8,377.12c-0.28-0.38-0.89-0.9-1.07-1.54c-0.21-0.72,0.74-1.54,1.52-1.45c0.79,0.09,1.32,0.53,1.38,1.38
	C446.68,376.34,446.02,377.1,444.8,377.12z"
              />
              <path
                d="M367.87,375.45c-1.1,0.53-1.72,1.04-2.37,1.1c-0.79,0.06-1.33-0.93-1.13-1.75c0.2-0.82,0.84-1.32,1.57-1.08
	C366.54,373.92,366.98,374.62,367.87,375.45z"
              />
              <path
                d="M638.57,629.73c-0.02,0.87-0.7,1.48-1.57,1.41c-0.84-0.07-1.3-0.57-1.37-1.38c-0.08-0.82,0.62-1.52,1.47-1.54
	C637.96,628.2,638.59,628.85,638.57,629.73z"
              />
              <path
                d="M319.03,68.75c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C318.52,67.28,318.94,67.88,319.03,68.75z"
              />
              <path
                d="M380.65,372.49c0.24,0.37,0.82,0.91,0.96,1.55c0.16,0.76-0.76,1.44-1.58,1.35c-0.84-0.09-1.26-0.61-1.29-1.43
	C378.7,373.08,379.35,372.44,380.65,372.49z"
              />
              <path
                d="M293.8,412.55c0.24,0.38,0.81,0.93,0.94,1.58c0.15,0.73-0.85,1.49-1.6,1.34c-0.77-0.15-1.26-0.63-1.28-1.47
	C291.86,413.17,292.57,412.49,293.8,412.55z"
              />
              <path
                d="M401.1,117.06c0.78,0.9,1.47,1.37,1.62,1.97c0.21,0.85-0.38,1.48-1.32,1.56c-0.94,0.08-1.62-0.44-1.54-1.33
	C399.93,118.64,400.51,118.07,401.1,117.06z"
              />
              <path
                d="M529.18,617.68c-0.09,0.91-0.54,1.48-1.49,1.46c-0.93-0.02-1.47-0.56-1.47-1.47c0.01-0.91,0.56-1.45,1.49-1.45
	C528.67,616.22,529.1,616.8,529.18,617.68z"
              />
              <path
                d="M427.24,192.59c0.01,1.24-0.68,1.92-1.52,1.89c-0.85-0.03-1.3-0.51-1.4-1.32c-0.1-0.86,0.56-1.72,1.36-1.54
	C426.31,191.76,426.85,192.34,427.24,192.59z"
              />
              <path
                d="M406.78,115.36c-1.01,0.66-1.55,1.26-2.18,1.36c-0.92,0.15-1.44-0.55-1.42-1.46c0.02-0.91,0.59-1.58,1.5-1.38
	C405.3,114.01,405.81,114.64,406.78,115.36z"
              />
              <path
                d="M307,431c0.02,0.88-0.61,1.52-1.49,1.49c-0.85-0.03-1.33-0.51-1.45-1.3c-0.13-0.8,0.55-1.55,1.38-1.62
	C306.31,429.5,306.98,430.12,307,431z"
              />
              <path
                d="M238.5,359.46c0.29,0.38,0.96,0.87,1.08,1.48c0.17,0.87-0.45,1.46-1.39,1.49c-0.95,0.04-1.48-0.49-1.51-1.41
	C236.66,360.11,237.15,359.58,238.5,359.46z"
              />
              <path
                d="M376.18,451.76c-1.37-0.15-1.86-0.72-1.8-1.64c0.06-0.92,0.62-1.45,1.58-1.37c0.94,0.07,1.56,0.69,1.35,1.56
	C377.16,450.92,376.48,451.4,376.18,451.76z"
              />
              <path
                d="M295.75,508.2c-0.31,0.22-0.78,0.75-1.34,0.88c-0.85,0.2-1.5-0.51-1.47-1.39c0.03-0.83,0.47-1.31,1.31-1.34
	C295.11,506.31,295.77,506.94,295.75,508.2z"
              />
              <path
                d="M348.28,239.53c-0.06,0.88-0.46,1.48-1.42,1.5c-0.93,0.02-1.5-0.49-1.53-1.4c-0.03-0.91,0.49-1.47,1.42-1.52
	C347.69,238.06,348.16,238.61,348.28,239.53z"
              />
              <path
                d="M307.93,192.63c-0.04,0.86-0.78,1.6-1.56,1.31c-0.54-0.2-1.12-0.81-1.3-1.37c-0.24-0.72,0.63-1.48,1.44-1.47
	C307.36,191.1,307.98,191.76,307.93,192.63z"
              />
              <path
                d="M521.94,618.15c0.01,0.88-0.61,1.49-1.48,1.46c-0.83-0.03-1.65-0.8-1.38-1.52c0.21-0.55,0.81-1.13,1.36-1.32
	C521.23,616.51,521.93,617.29,521.94,618.15z"
              />
              <path
                d="M303.62,601.37c-0.12,0.83-1.01,1.55-1.69,1.18c-0.53-0.29-1.04-0.95-1.17-1.53c-0.16-0.74,0.82-1.45,1.6-1.37
	C303.17,599.74,303.74,600.52,303.62,601.37z"
              />
              <path
                d="M305.16,465.19c-1.36-0.15-1.84-0.72-1.78-1.63c0.06-0.91,0.62-1.44,1.57-1.37c0.93,0.07,1.55,0.69,1.34,1.55
	C306.14,464.35,305.46,464.83,305.16,465.19z"
              />
              <path
                d="M456.21,585.59c-0.92-0.05-1.49-0.54-1.5-1.47c-0.01-0.93,0.53-1.47,1.48-1.5c0.96-0.02,1.49,0.54,1.5,1.46
	C457.7,585,457.18,585.54,456.21,585.59z"
              />
              <path
                d="M316.45,371.99c-0.06,1.31-0.48,1.91-1.43,1.97c-0.95,0.07-1.53-0.45-1.58-1.37c-0.06-0.91,0.43-1.65,1.37-1.54
	C315.44,371.13,316.01,371.72,316.45,371.99z"
              />
              <path
                d="M359.81,415.56c-0.07,0.96-0.47,1.55-1.43,1.57c-0.93,0.02-1.5-0.49-1.53-1.4c-0.03-0.91,0.49-1.47,1.42-1.52
	C359.23,414.16,359.66,414.74,359.81,415.56z"
              />
              <path
                d="M308.92,618.73c-0.04,0.87-0.73,1.47-1.6,1.37c-0.84-0.09-1.28-0.6-1.34-1.41c-0.06-0.82,0.66-1.51,1.5-1.5
	C308.33,617.18,308.96,617.86,308.92,618.73z"
              />
              <path
                d="M447.95,429.18c-0.13,1.32-0.6,1.87-1.55,1.88c-0.96,0.01-1.49-0.54-1.49-1.46c0-0.92,0.53-1.62,1.46-1.44
	C447,428.27,447.53,428.89,447.95,429.18z"
              />
              <path
                d="M478,417.65c-1.38-0.06-1.89-0.59-1.88-1.51c0.01-0.91,0.53-1.48,1.48-1.46c0.93,0.02,1.58,0.59,1.43,1.47
	C478.92,416.77,478.27,417.28,478,417.65z"
              />
              <path
                d="M306.49,416.72c-1.01,0.65-1.56,1.24-2.19,1.34c-0.92,0.14-1.44-0.57-1.4-1.48c0.03-0.91,0.61-1.58,1.51-1.37
	C305.02,415.35,305.53,415.99,306.49,416.72z"
              />
              <path
                d="M316.06,318.4c-1.05,0.62-1.62,1.16-2.26,1.27c-0.78,0.13-1.41-0.77-1.27-1.61c0.14-0.8,0.66-1.4,1.44-1.19
	C314.6,317.04,315.09,317.66,316.06,318.4z"
              />
              <path
                d="M237.05,371.71c-0.22,1.32-0.72,1.84-1.67,1.77c-0.95-0.07-1.45-0.65-1.39-1.57c0.06-0.93,0.7-1.55,1.58-1.33
	C236.18,370.73,236.67,371.4,237.05,371.71z"
              />
              <path
                d="M399.52,116.03c-0.94,0.68-1.47,1.34-2.09,1.44c-0.87,0.14-1.45-0.54-1.44-1.46c0-0.92,0.6-1.58,1.46-1.44
	C398.06,114.68,398.59,115.35,399.52,116.03z"
              />
              <path
                d="M472.04,416.61c0.01,0.88-0.61,1.5-1.48,1.46c-0.83-0.03-1.65-0.8-1.38-1.52c0.2-0.55,0.8-1.14,1.36-1.32
	C471.32,414.98,472.03,415.75,472.04,416.61z"
              />
              <path
                d="M479.21,574.87c-0.97,0.73-1.47,1.34-2.09,1.51c-0.79,0.21-1.31-0.39-1.44-1.19c-0.14-0.83,0.5-1.75,1.27-1.61
	C477.59,573.69,478.16,574.24,479.21,574.87z"
              />
              <path
                d="M258.91,416.76c-0.17,1.32-0.67,1.85-1.61,1.83c-0.96-0.02-1.47-0.6-1.44-1.52c0.03-0.92,0.59-1.59,1.51-1.39
	C257.99,415.82,258.5,416.46,258.91,416.76z"
              />
              <path
                d="M390.64,118c-1.22-0.01-1.9-0.76-1.84-1.59c0.05-0.85,0.58-1.3,1.36-1.4c0.79-0.1,1.74,0.71,1.53,1.44
	C391.52,117.1,390.91,117.62,390.64,118z"
              />
              <path
                d="M372.85,100.03c-0.89,0.76-1.36,1.48-1.95,1.61c-0.83,0.18-1.5-0.43-1.57-1.35c-0.08-0.94,0.53-1.6,1.37-1.54
	C371.3,98.79,371.86,99.42,372.85,100.03z"
              />
              <path d="M554.63,81.59c-1,1.22-1.84,1.13-2.51,0.39c-0.75-0.82-0.72-1.72,0.56-2.63C553.3,80.08,553.93,80.8,554.63,81.59z" />
              <path
                d="M450.13,181.57c-1.05-0.04-1.63-0.51-1.66-1.45c-0.02-0.93,0.51-1.48,1.45-1.52c0.96-0.04,1.49,0.51,1.53,1.43
	C451.49,180.96,450.95,181.48,450.13,181.57z"
              />
              <path
                d="M450.98,591.78c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C450.48,590.3,450.9,590.9,450.98,591.78z"
              />
              <path
                d="M445.67,155.77c-0.36,0.26-0.87,0.87-1.5,1.03c-0.74,0.19-1.65-0.8-1.39-1.5c0.21-0.56,0.8-1.15,1.36-1.35
	C444.88,153.68,445.65,154.51,445.67,155.77z"
              />
              <path
                d="M304.07,621.02c0.86-0.02,1.52,0.62,1.48,1.5c-0.04,0.82-0.47,1.34-1.31,1.43c-0.84,0.09-1.58-0.56-1.63-1.37
	C302.56,621.75,303.23,621.04,304.07,621.02z"
              />
              <path
                d="M296.39,495.28c1.38-0.02,1.94,0.48,1.99,1.4c0.05,0.91-0.43,1.52-1.39,1.56c-0.93,0.04-1.64-0.47-1.54-1.38
	C295.52,496.24,296.13,495.68,296.39,495.28z"
              />
              <path
                d="M279.11,101.01c-0.95,0.75-1.45,1.42-2.07,1.58c-0.74,0.18-1.33-0.38-1.46-1.21c-0.13-0.85,0.49-1.8,1.26-1.66
	C277.49,99.82,278.06,100.38,279.11,101.01z"
              />
              <path
                d="M249.96,419.93c-0.59-1-1.17-1.58-1.24-2.21c-0.1-0.91,0.63-1.43,1.52-1.35c0.87,0.08,1.56,0.68,1.33,1.57
	C251.41,418.55,250.72,419.03,249.96,419.93z"
              />
              <path
                d="M322.35,317.66c-0.06,0.83-0.9,1.64-1.59,1.33c-0.55-0.25-1.12-0.87-1.3-1.44c-0.23-0.72,0.69-1.51,1.49-1.49
	C321.76,316.08,322.4,316.82,322.35,317.66z"
              />
              <path
                d="M453.38,161.74c-0.11,1.01-0.55,1.58-1.51,1.56c-0.93-0.02-1.47-0.56-1.46-1.47c0.01-0.91,0.56-1.44,1.49-1.45
	C452.87,160.36,453.25,160.99,453.38,161.74z"
              />
              <path
                d="M341.09,74.04c-0.11,0.93-0.56,1.48-1.51,1.45c-0.93-0.03-1.47-0.58-1.44-1.49c0.02-0.91,0.58-1.44,1.51-1.43
	C340.59,72.57,341.01,73.17,341.09,74.04z"
              />
              <path
                d="M300.27,617.28c0.67,0.95,1.3,1.48,1.41,2.09c0.15,0.87-0.51,1.49-1.42,1.48c-0.88-0.01-1.59-0.6-1.45-1.47
	C298.92,618.76,299.58,618.24,300.27,617.28z"
              />
              <path
                d="M383.35,371.54c-0.85,0.03-1.55-0.61-1.56-1.44c-0.01-0.81,0.7-1.51,1.52-1.48c0.84,0.03,1.3,0.51,1.4,1.32
	C384.81,370.82,384.2,371.51,383.35,371.54z"
              />
              <path
                d="M303.13,460.27c-0.98,0.7-1.5,1.32-2.11,1.45c-0.92,0.19-1.47-0.48-1.48-1.4c-0.01-0.91,0.52-1.61,1.44-1.45
	C301.59,458.98,302.13,459.6,303.13,460.27z"
              />
              <path
                d="M332.61,72.67c0.58,1.01,1.15,1.59,1.21,2.22c0.09,0.9-0.64,1.44-1.53,1.34c-0.86-0.09-1.55-0.71-1.31-1.59
	C331.14,74.03,331.84,73.56,332.61,72.67z"
              />
              <path
                d="M449.94,364.46c-1.05,0.62-1.62,1.16-2.26,1.27c-0.78,0.13-1.41-0.77-1.27-1.61c0.14-0.8,0.66-1.4,1.44-1.19
	C448.47,363.1,448.97,363.72,449.94,364.46z"
              />
              <path
                d="M451.89,187.35c-0.04,0.86-0.78,1.6-1.56,1.31c-0.54-0.2-1.12-0.81-1.3-1.37c-0.24-0.72,0.63-1.48,1.44-1.47
	C451.32,185.82,451.93,186.49,451.89,187.35z"
              />
              <path
                d="M518.9,250.13c0,0.84-0.69,1.49-1.55,1.46c-0.85-0.03-1.31-0.53-1.39-1.34c-0.09-0.87,0.54-1.56,1.4-1.58
	C518.2,248.64,518.9,249.3,518.9,250.13z"
              />
              <path
                d="M481.69,571.15c-0.11,0.93-0.56,1.49-1.52,1.46c-0.94-0.03-1.48-0.57-1.46-1.49c0.02-0.92,0.57-1.45,1.51-1.45
	C481.19,569.67,481.61,570.27,481.69,571.15z"
              />
              <path
                d="M442.56,427.72c-0.97-0.04-1.49-0.58-1.48-1.5c0.01-0.92,0.53-1.48,1.49-1.47c0.94,0.02,1.48,0.56,1.48,1.49
	C444.05,427.18,443.48,427.67,442.56,427.72z"
              />
              <path
                d="M392.98,107.57c-0.74-0.98-1.36-1.48-1.55-2.12c-0.22-0.71,0.71-1.53,1.5-1.46c0.79,0.07,1.47,0.53,1.33,1.34
	C394.15,105.98,393.6,106.54,392.98,107.57z"
              />
              <path
                d="M450.95,425.69c0.04,0.87-0.59,1.53-1.45,1.53c-0.85,0-1.55-0.69-1.48-1.51c0.07-0.81,0.52-1.31,1.35-1.41
	C450.22,424.2,450.91,424.82,450.95,425.69z"
              />
              <path
                d="M292.57,455.28c-0.24-0.39-0.8-0.95-0.93-1.6c-0.14-0.75,0.84-1.46,1.62-1.31c0.79,0.15,1.25,0.64,1.26,1.48
	C294.51,454.67,293.78,455.36,292.57,455.28z"
              />
              <path
                d="M373.96,453.54c0.09,1.26-0.57,1.96-1.42,1.98c-0.85,0.02-1.34-0.43-1.48-1.24c-0.14-0.84,0.49-1.77,1.28-1.63
	C372.99,452.75,373.56,453.3,373.96,453.54z"
              />
              <path
                d="M458.75,425.05c-0.94,0.68-1.47,1.35-2.08,1.43c-0.85,0.12-1.47-0.54-1.47-1.46c0.01-0.92,0.65-1.56,1.49-1.43
	C457.3,423.69,457.82,424.36,458.75,425.05z"
              />
              <path
                d="M566.47,628.29c-0.12,1.32-0.57,1.88-1.52,1.91c-0.96,0.03-1.5-0.52-1.51-1.44c-0.02-0.92,0.51-1.62,1.44-1.47
	C565.5,627.4,566.05,628.01,566.47,628.29z"
              />
              <path
                d="M339.53,424.67c-0.98,0.64-1.54,1.28-2.16,1.34c-0.86,0.08-1.41-0.61-1.36-1.54c0.05-0.93,0.68-1.54,1.53-1.35
	C338.15,423.25,338.64,423.94,339.53,424.67z"
              />
              <path
                d="M515.19,366.03c-1,0.68-1.54,1.28-2.19,1.44c-0.76,0.18-1.43-0.73-1.36-1.58c0.07-0.85,0.65-1.44,1.39-1.3
	C513.67,364.7,514.2,365.34,515.19,366.03z"
              />
              <path
                d="M398.24,623.05c-1.35-0.15-1.83-0.72-1.77-1.62c0.06-0.91,0.61-1.43,1.56-1.36c0.92,0.07,1.54,0.69,1.34,1.55
	C399.23,622.22,398.54,622.69,398.24,623.05z"
              />
              <path
                d="M357.83,77.41c-0.85-0.01-1.52-0.7-1.48-1.52c0.04-0.82,0.79-1.48,1.61-1.4c0.84,0.08,1.28,0.59,1.33,1.41
	C359.35,76.77,358.7,77.42,357.83,77.41z"
              />
              <path
                d="M468.82,420.18c-0.23,1.31-0.73,1.84-1.68,1.76c-0.95-0.08-1.45-0.66-1.38-1.58c0.07-0.93,0.71-1.54,1.59-1.32
	C467.95,419.19,468.44,419.87,468.82,420.18z"
              />
              <path
                d="M255.65,421c-0.02,0.87-0.7,1.48-1.57,1.41c-0.84-0.07-1.3-0.57-1.37-1.38c-0.08-0.82,0.62-1.52,1.47-1.54
	C255.04,419.47,255.68,420.12,255.65,421z"
              />
              <path
                d="M228.14,247.74c-0.88,0.02-1.51-0.6-1.47-1.48c0.03-0.82,0.46-1.33,1.3-1.43c0.83-0.09,1.56,0.54,1.62,1.37
	C229.63,247.05,229.01,247.72,228.14,247.74z"
              />
              <path
                d="M460.68,181.2c1.24,0.07,1.86,0.82,1.77,1.67c-0.09,0.85-0.62,1.28-1.42,1.34c-0.79,0.06-1.7-0.79-1.47-1.5
	C459.77,182.07,460.39,181.57,460.68,181.2z"
              />
              <path
                d="M384.95,422.62c0.25,0.38,0.83,0.92,0.98,1.56c0.17,0.72-0.83,1.51-1.58,1.38c-0.77-0.14-1.28-0.6-1.3-1.45
	C383.02,423.28,383.71,422.58,384.95,422.62z"
              />
              <path
                d="M370.82,457.87c0.02,0.88-0.61,1.52-1.49,1.49c-0.85-0.03-1.33-0.51-1.45-1.3c-0.13-0.8,0.55-1.55,1.38-1.62
	C370.12,456.38,370.8,456.99,370.82,457.87z"
              />
              <path
                d="M304.98,608.25c-0.91,0.72-1.4,1.4-2.02,1.53c-0.86,0.18-1.46-0.46-1.51-1.38c-0.04-0.93,0.53-1.61,1.39-1.51
	C303.47,606.96,304.02,607.61,304.98,608.25z"
              />
              <path
                d="M496.5,592.75c-0.85-0.01-1.52-0.7-1.48-1.53c0.04-0.82,0.79-1.48,1.62-1.4c0.84,0.08,1.28,0.59,1.33,1.42
	C498.02,592.12,497.37,592.77,496.5,592.75z"
              />
              <path
                d="M468.63,579.42c-0.95,0.74-1.45,1.4-2.07,1.55c-0.73,0.17-1.32-0.38-1.44-1.22c-0.12-0.85,0.5-1.78,1.27-1.64
	C467.04,578.23,467.6,578.8,468.63,579.42z"
              />
              <path
                d="M341.07,366.85c-0.06,0.87-0.77,1.45-1.64,1.33c-0.83-0.12-1.27-0.63-1.3-1.45c-0.03-0.82,0.7-1.49,1.54-1.47
	C340.52,365.29,341.13,365.98,341.07,366.85z"
              />
              <path
                d="M405.6,619.6c0.24,0.37,0.82,0.91,0.96,1.55c0.16,0.76-0.76,1.44-1.58,1.35c-0.84-0.09-1.26-0.61-1.29-1.43
	C403.65,620.19,404.3,619.56,405.6,619.6z"
              />
              <path
                d="M486.41,574.61c-1.05,0.63-1.61,1.18-2.25,1.29c-0.78,0.14-1.42-0.75-1.29-1.59c0.13-0.8,0.64-1.41,1.43-1.21
	C484.92,573.26,485.43,573.88,486.41,574.61z"
              />
              <path
                d="M531.04,622.27c-0.24-0.39-0.81-0.96-0.93-1.61c-0.14-0.75,0.85-1.47,1.62-1.32c0.79,0.15,1.26,0.65,1.26,1.49
	C533.01,621.65,532.25,622.36,531.04,622.27z"
              />
              <path
                d="M430.82,608.09c-0.09,0.91-0.54,1.48-1.49,1.46c-0.93-0.02-1.47-0.56-1.47-1.47c0.01-0.91,0.56-1.45,1.49-1.45
	C430.3,606.62,430.73,607.2,430.82,608.09z"
              />
              <path
                d="M298.41,456.84c-0.11,1.02-0.56,1.59-1.52,1.57c-0.94-0.02-1.48-0.56-1.47-1.48c0.01-0.92,0.56-1.44,1.5-1.46
	C297.9,455.45,298.27,456.09,298.41,456.84z"
              />
              <path
                d="M510.06,624.72c-0.7-0.88-1.37-1.39-1.51-2.01c-0.17-0.75,0.47-1.3,1.29-1.39c0.85-0.1,1.67,0.58,1.49,1.4
	C511.2,623.31,510.66,623.81,510.06,624.72z"
              />
              <path
                d="M443.95,409.41c-0.21-0.32-0.73-0.82-0.85-1.4c-0.18-0.84,0.68-1.68,1.45-1.4c0.55,0.2,1.14,0.82,1.33,1.38
	C446.12,408.73,445.21,409.47,443.95,409.41z"
              />
              <path
                d="M355.76,574.66c0.95-0.75,1.43-1.38,2.05-1.55c0.77-0.21,1.31,0.37,1.45,1.17c0.15,0.83-0.46,1.74-1.24,1.61
	C357.37,575.79,356.8,575.26,355.76,574.66z"
              />
              <path
                d="M357.31,585.65c-1.03,0.62-1.59,1.17-2.23,1.28c-0.78,0.14-1.41-0.75-1.28-1.58c0.13-0.79,0.64-1.4,1.42-1.2
	C355.85,584.3,356.34,584.92,357.31,585.65z"
              />
              <path
                d="M417.56,141.55c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.82,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.6,1.34,1.36
	C418.89,140.1,418.23,140.63,417.56,141.55z"
              />
              <path
                d="M366.65,427.36c0.7,0.91,1.42,1.45,1.45,2.03c0.03,0.44-0.73,1.15-1.29,1.36c-0.74,0.28-1.63-0.59-1.45-1.39
	C365.49,428.77,366.03,428.29,366.65,427.36z"
              />
              <path
                d="M376.53,309.26c-1.01,0.75-1.53,1.4-2.15,1.52c-0.74,0.14-1.32-0.43-1.37-1.29c-0.05-0.84,0.4-1.51,1.19-1.45
	C374.84,308.09,375.43,308.66,376.53,309.26z"
              />
              <path
                d="M383.77,102.73c-0.8,0.69-1.22,1.28-1.78,1.48c-0.77,0.26-1.58-0.51-1.62-1.33c-0.04-0.79,0.74-1.66,1.5-1.46
	C382.45,101.57,382.92,102.12,383.77,102.73z"
              />
              <path
                d="M355.8,571.73c-1.07,0.48-1.71,0.99-2.34,0.99c-0.79,0-1.17-0.71-1.07-1.55c0.11-0.86,0.92-1.47,1.71-1.12
	C354.64,570.31,355.01,570.93,355.8,571.73z"
              />
              <path
                d="M361.16,80.72c-0.23-0.37-0.86-0.94-0.89-1.55c-0.04-0.86,0.67-1.39,1.6-1.29c0.82,0.09,1.22,0.64,1.23,1.45
	C363.11,80.2,362.45,80.8,361.16,80.72z"
              />
              <path
                d="M456.57,190.55c-1.01,0.58-1.6,1.16-2.22,1.2c-0.75,0.06-1.24-0.59-1.2-1.43c0.04-0.84,0.8-1.55,1.59-1.27
	C455.29,189.25,455.72,189.83,456.57,190.55z"
              />
              <path
                d="M439.4,249.54c0.06,0.87-0.53,1.53-1.38,1.55c-0.82,0.02-1.68-0.71-1.46-1.43c0.17-0.56,0.73-1.18,1.27-1.4
	C438.57,247.96,439.34,248.7,439.4,249.54z"
              />
              <path
                d="M334.78,77.49c0.39-0.27,0.93-0.89,1.55-1.01c0.8-0.15,1.3,0.51,1.32,1.34c0.03,0.83-0.4,1.36-1.24,1.47
	C335.6,79.41,334.82,78.72,334.78,77.49z"
              />
              <path
                d="M347.75,245.34c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.5c-0.84-0.05-1.31-0.54-1.41-1.35
	C345.75,246.06,346.51,245.32,347.75,245.34z"
              />
              <path
                d="M523.33,316.65c-0.72-0.83-1.32-1.24-1.51-1.79c-0.28-0.79,0.42-1.57,1.26-1.59c0.81-0.01,1.51,0.42,1.45,1.2
	C524.49,315.09,523.9,315.67,523.33,316.65z"
              />
              <path
                d="M461.48,421.3c-0.05,0.82-0.87,1.61-1.57,1.31c-0.55-0.24-1.11-0.85-1.29-1.41c-0.23-0.71,0.67-1.47,1.47-1.46
	C460.92,419.76,461.53,420.46,461.48,421.3z"
              />
              <path
                d="M412.8,618.9c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C410.87,619.5,411.56,618.84,412.8,618.9z"
              />
              <path
                d="M382.12,420.54c-0.01,1.23-0.77,1.93-1.59,1.84c-0.85-0.09-1.28-0.61-1.28-1.44c0-0.84,0.5-1.5,1.29-1.37
	C381.17,419.67,381.72,420.28,382.12,420.54z"
              />
              <path
                d="M303.09,254.57c-1.04,0.62-1.6,1.16-2.24,1.27c-0.78,0.14-1.41-0.76-1.27-1.59c0.13-0.79,0.65-1.4,1.43-1.2
	C301.63,253.22,302.13,253.84,303.09,254.57z"
              />
              <path
                d="M348.07,362.19c1.03-0.67,1.58-1.26,2.2-1.36c0.77-0.12,1.28,0.49,1.3,1.33c0.02,0.85-0.53,1.47-1.26,1.39
	C349.68,363.48,349.12,362.86,348.07,362.19z"
              />
              <path
                d="M350.12,420.08c-1.03,0.63-1.58,1.19-2.21,1.31c-0.78,0.15-1.42-0.72-1.3-1.56c0.12-0.8,0.63-1.41,1.4-1.22
	C348.63,418.75,349.14,419.36,350.12,420.08z"
              />
              <path
                d="M525.77,621.49c0.02,0.87-0.59,1.5-1.45,1.47c-0.82-0.02-1.65-0.79-1.39-1.5c0.2-0.55,0.79-1.14,1.34-1.33
	C525.03,619.87,525.75,620.63,525.77,621.49z"
              />
              <path
                d="M312.72,621.9c0.06,0.87-0.53,1.53-1.38,1.55c-0.82,0.02-1.68-0.71-1.46-1.43c0.17-0.56,0.73-1.18,1.27-1.4
	C311.89,620.31,312.66,621.06,312.72,621.9z"
              />
              <path
                d="M330.02,317.06c-0.81,0.66-1.25,1.23-1.82,1.43c-0.71,0.24-1.57-0.63-1.58-1.4c-0.01-0.78,0.8-1.62,1.56-1.39
	C328.75,315.86,329.2,316.43,330.02,317.06z"
              />
              <path
                d="M343.95,601.04c-0.79,0.7-1.21,1.29-1.78,1.51c-0.71,0.27-1.59-0.56-1.63-1.34c-0.04-0.8,0.72-1.66,1.5-1.46
	C342.62,599.89,343.1,600.44,343.95,601.04z"
              />
              <path
                d="M524.3,280.65c0.23,0.31,0.77,0.77,0.92,1.33c0.21,0.81-0.62,1.68-1.38,1.44c-0.55-0.18-1.16-0.77-1.37-1.31
	C522.21,281.39,523.08,280.63,524.3,280.65z"
              />
              <path
                d="M380.9,359.7c-0.87,0.57-1.36,1.1-1.94,1.22c-0.79,0.17-1.53-0.7-1.46-1.49c0.06-0.8,0.93-1.57,1.66-1.28
	C379.71,358.37,380.11,358.97,380.9,359.7z"
              />
              <path
                d="M419.13,618.43c1.24,0.02,2.08,0.81,1.79,1.52c-0.22,0.55-0.85,1.13-1.42,1.29c-0.78,0.22-1.58-0.67-1.35-1.49
	C418.32,619.18,418.89,618.74,419.13,618.43z"
              />
              <path
                d="M302.13,492.81c-0.96,0.72-1.45,1.34-2.07,1.5c-0.78,0.21-1.3-0.38-1.43-1.18c-0.13-0.83,0.49-1.74,1.26-1.6
	C300.53,491.64,301.09,492.19,302.13,492.81z"
              />
              <path
                d="M372.58,373.25c1.23,0.01,2.07,0.8,1.78,1.51c-0.22,0.54-0.85,1.11-1.4,1.27c-0.78,0.22-1.57-0.67-1.34-1.48
	C371.78,373.99,372.35,373.55,372.58,373.25z"
              />
              <path
                d="M265.11,176.48c1.24,0.03,2.09,0.85,1.79,1.56c-0.23,0.55-0.87,1.13-1.44,1.29c-0.77,0.22-1.58-0.71-1.34-1.52
	C264.29,177.23,264.86,176.79,265.11,176.48z"
              />
              <path
                d="M513.72,358.3c0.08,1.28-0.59,2.12-1.37,1.89c-0.56-0.16-1.18-0.74-1.39-1.29c-0.28-0.72,0.58-1.61,1.39-1.44
	C512.92,357.58,513.41,358.1,513.72,358.3z"
              />
              <path
                d="M340.02,621.46c-0.65-1.03-1.22-1.58-1.35-2.22c-0.15-0.73,0.82-1.45,1.59-1.32c0.79,0.13,1.4,0.66,1.21,1.44
	C341.33,619.98,340.72,620.49,340.02,621.46z"
              />
              <path
                d="M517.65,623.42c-1.26,0.04-2.16-0.73-1.91-1.45c0.19-0.56,0.8-1.16,1.35-1.35c0.78-0.26,1.61,0.59,1.42,1.43
	C518.39,622.62,517.86,623.11,517.65,623.42z"
              />
              <path
                d="M463.36,419.37c-0.63-0.95-1.26-1.5-1.35-2.13c-0.11-0.78,0.57-1.25,1.39-1.29c0.86-0.04,1.6,0.69,1.37,1.52
	C464.61,418.03,464.04,418.49,463.36,419.37z"
              />
              <path
                d="M381.85,460.88c-0.34,0.27-0.81,0.89-1.42,1.09c-0.71,0.23-1.68-0.72-1.46-1.42c0.18-0.56,0.74-1.18,1.28-1.4
	C380.98,458.85,381.77,459.6,381.85,460.88z"
              />
              <path
                d="M353.01,356.68c1.26-0.09,1.94,0.53,1.94,1.38c-0.01,0.8-0.39,1.36-1.22,1.45c-0.93,0.1-1.65-0.4-1.6-1.27
	C352.15,357.64,352.78,357.06,353.01,356.68z"
              />
              <path
                d="M387.61,106.3c-0.86,0.59-1.34,1.13-1.91,1.27c-0.78,0.19-1.54-0.67-1.49-1.46c0.05-0.81,0.89-1.59,1.63-1.32
	C386.41,104.99,386.82,105.59,387.61,106.3z"
              />
              <path
                d="M532.24,630.03c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.83,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.59,1.34,1.36
	C533.58,628.58,532.92,629.11,532.24,630.03z"
              />
              <path
                d="M513.03,620.39c-0.3-0.37-0.98-0.87-1.13-1.5c-0.18-0.77,0.46-1.34,1.28-1.41c0.81-0.07,1.38,0.31,1.53,1.16
	C514.85,619.49,514.26,620.29,513.03,620.39z"
              />
              <path
                d="M299.18,417.46c-0.98,0.6-1.55,1.21-2.17,1.27c-0.73,0.07-1.26-0.55-1.24-1.4c0.01-0.84,0.75-1.57,1.55-1.32
	C297.87,416.19,298.31,416.76,299.18,417.46z"
              />
              <path
                d="M372.6,306.22c-1.03,0.56-1.62,1.13-2.25,1.17c-0.76,0.05-1.22-0.61-1.17-1.45c0.05-0.85,0.81-1.53,1.61-1.24
	C371.34,304.9,371.76,305.49,372.6,306.22z"
              />
              <path
                d="M512.37,377.04c-0.81,0.66-1.25,1.24-1.83,1.44c-0.71,0.24-1.58-0.64-1.59-1.41c-0.01-0.79,0.81-1.64,1.57-1.41
	C511.1,375.83,511.55,376.4,512.37,377.04z"
              />
              <path
                d="M376.27,86.81c-0.3-0.38-0.98-0.88-1.12-1.5c-0.18-0.77,0.47-1.34,1.29-1.41c0.81-0.07,1.38,0.31,1.53,1.17
	C378.1,85.92,377.5,86.71,376.27,86.81z"
              />
              <path
                d="M393.37,629.39c-0.8,0.68-1.23,1.27-1.8,1.48c-0.71,0.26-1.58-0.6-1.61-1.38c-0.03-0.8,0.77-1.65,1.54-1.44
	C392.07,628.21,392.54,628.77,393.37,629.39z"
              />
              <path
                d="M366.03,175.04c1.25-0.03,1.91,0.64,1.88,1.48c-0.02,0.84-0.54,1.29-1.3,1.43c-0.75,0.13-1.73-0.65-1.56-1.37
	C365.21,175.94,365.78,175.41,366.03,175.04z"
              />
              <path
                d="M525.78,289.69c-0.37,0.25-0.89,0.83-1.52,0.97c-0.75,0.16-1.61-0.83-1.33-1.52c0.22-0.54,0.83-1.11,1.39-1.29
	C525.07,287.6,525.8,288.42,525.78,289.69z"
              />
              <path
                d="M282.65,377.26c1.24,0.07,1.86,0.78,1.75,1.62c-0.1,0.8-0.55,1.27-1.39,1.29c-0.82,0.01-1.67-0.73-1.44-1.45
	C281.76,378.09,282.38,377.61,282.65,377.26z"
              />
              <path
                d="M436.63,609.47c-0.73-0.97-1.34-1.48-1.53-2.11c-0.21-0.71,0.71-1.5,1.5-1.43c0.79,0.07,1.45,0.52,1.31,1.33
	C437.81,607.89,437.25,608.45,436.63,609.47z"
              />
              <path
                d="M446.6,415.26c-0.03,0.86-0.75,1.6-1.53,1.32c-0.54-0.2-1.12-0.8-1.31-1.35c-0.24-0.71,0.62-1.48,1.42-1.48
	C446.01,413.74,446.63,414.4,446.6,415.26z"
              />
              <path
                d="M424.28,260.07c-0.64-0.91-1.19-1.39-1.34-1.98c-0.2-0.82,0.6-1.55,1.45-1.47c0.82,0.08,1.48,0.6,1.34,1.36
	C425.61,258.62,424.95,259.15,424.28,260.07z"
              />
              <path
                d="M460.21,173.53c0.7,0.9,1.43,1.43,1.47,2.01c0.03,0.44-0.73,1.15-1.28,1.37c-0.73,0.28-1.64-0.58-1.47-1.38
	C459.06,174.95,459.59,174.46,460.21,173.53z"
              />
              <path
                d="M306.28,629.22c0.04,1.23-0.69,1.95-1.52,1.89c-0.86-0.05-1.3-0.56-1.33-1.39c-0.03-0.84,0.45-1.52,1.24-1.42
	C305.31,628.37,305.88,628.97,306.28,629.22z"
              />
              <path
                d="M394.08,121.86c-0.65-0.89-1.21-1.36-1.36-1.94c-0.21-0.82,0.56-1.53,1.41-1.47c0.82,0.06,1.48,0.56,1.35,1.33
	C395.38,120.4,394.74,120.94,394.08,121.86z"
              />
              <path
                d="M344.08,628.45c1.24,0.07,1.83,0.79,1.74,1.64c-0.09,0.84-0.63,1.25-1.4,1.31c-0.78,0.06-1.68-0.78-1.45-1.48
	C343.18,629.29,343.8,628.8,344.08,628.45z"
              />
              <path
                d="M500.54,603.25c-0.2-0.33-0.71-0.84-0.82-1.42c-0.16-0.85,0.72-1.66,1.48-1.36c0.55,0.21,1.13,0.85,1.29,1.41
	C502.72,602.63,501.79,603.35,500.54,603.25z"
              />
              <path
                d="M374.95,464.78c0.95-0.75,1.43-1.38,2.05-1.55c0.77-0.21,1.31,0.37,1.45,1.17c0.15,0.83-0.46,1.74-1.24,1.61
	C376.57,465.91,376,465.37,374.95,464.78z"
              />
              <path
                d="M309.16,412.34c0.04,1.23-0.7,1.94-1.53,1.89c-0.85-0.06-1.3-0.57-1.32-1.4c-0.02-0.84,0.46-1.51,1.25-1.42
	C308.19,411.49,308.76,412.09,309.16,412.34z"
              />
              <path
                d="M280.54,617.85c0.42-0.45,0.68-0.92,1.06-1.08c0.52-0.22,1.17-0.34,1.69-0.21c0.19,0.05,0.41,1.14,0.19,1.4
	c-0.39,0.47-1.08,0.85-1.69,0.91C281.44,618.9,281.02,618.26,280.54,617.85z"
              />
              <path
                d="M370.3,463.66c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.49c-0.84-0.05-1.31-0.54-1.41-1.35
	C368.3,464.39,369.06,463.64,370.3,463.66z"
              />
              <path
                d="M481.42,410.65c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C479.49,411.25,480.18,410.59,481.42,410.65z"
              />
              <path
                d="M359.41,301.35c0.73,0.94,1.4,1.44,1.53,2.06c0.16,0.75-0.44,1.31-1.28,1.41c-0.86,0.1-1.66-0.57-1.49-1.42
	C358.29,302.82,358.8,302.31,359.41,301.35z"
              />
              <path
                d="M331.86,551.21c0.07,1.27-0.61,2.11-1.38,1.88c-0.56-0.17-1.17-0.75-1.38-1.29c-0.28-0.72,0.6-1.61,1.4-1.43
	C331.07,550.48,331.55,551,331.86,551.21z"
              />
              <path
                d="M322.31,410.18c0.23,0.33,0.78,0.8,0.92,1.38c0.2,0.83-0.61,1.58-1.44,1.49c-0.8-0.09-1.32-0.56-1.4-1.39
	C320.32,410.87,321.09,410.14,322.31,410.18z"
              />
              <path
                d="M328.58,548.26c-0.79,0.69-1.21,1.27-1.76,1.48c-0.7,0.26-1.57-0.55-1.61-1.31c-0.04-0.79,0.71-1.62,1.48-1.43
	C327.26,547.14,327.73,547.67,328.58,548.26z"
              />
              <path
                d="M351.87,591.63c-0.66-0.89-1.22-1.35-1.38-1.93c-0.22-0.83,0.54-1.54,1.4-1.49c0.82,0.05,1.49,0.55,1.36,1.31
	C353.16,590.15,352.52,590.69,351.87,591.63z"
              />
              <path
                d="M374.55,351.22c-0.72-0.85-1.31-1.27-1.49-1.83c-0.27-0.81,0.44-1.58,1.3-1.58c0.82,0,1.52,0.45,1.44,1.23
	C375.74,349.67,375.14,350.24,374.55,351.22z"
              />
              <path
                d="M310.45,27.64c0.63,0.91,1.17,1.4,1.31,1.98c0.19,0.76-0.75,1.71-1.43,1.44c-0.56-0.22-1.32-0.91-1.3-1.36
	C309.07,29.11,309.78,28.56,310.45,27.64z"
              />
              <path
                d="M374.3,434.36c1.24,0.07,1.86,0.78,1.75,1.62c-0.1,0.8-0.55,1.27-1.39,1.29c-0.82,0.01-1.67-0.73-1.44-1.45
	C373.41,435.19,374.03,434.71,374.3,434.36z"
              />
              <path
                d="M493.47,581.29c-0.37,0.29-0.88,0.95-1.5,1.08c-0.74,0.16-1.33-0.4-1.39-1.26c-0.06-0.84,0.35-1.36,1.17-1.52
	C492.56,579.44,493.36,580.07,493.47,581.29z"
              />
              <path
                d="M294.75,350.73c-0.65-0.89-1.2-1.35-1.35-1.92c-0.21-0.82,0.55-1.51,1.4-1.46c0.82,0.05,1.47,0.56,1.34,1.31
	C296.05,349.28,295.41,349.81,294.75,350.73z"
              />
              <path
                d="M345.84,595.88c0.23,0.33,0.77,0.82,0.91,1.4c0.19,0.82-0.7,1.7-1.45,1.42c-0.56-0.21-1.16-0.82-1.35-1.38
	C343.71,596.6,344.63,595.82,345.84,595.88z"
              />
              <path
                d="M348.38,591.49c0.71,0.89,1.43,1.41,1.48,1.98c0.03,0.43-0.71,1.15-1.26,1.36c-0.73,0.29-1.64-0.55-1.47-1.35
	C347.26,592.9,347.78,592.41,348.38,591.49z"
              />
              <path
                d="M515.49,346.36c0.24,0.37,0.8,0.92,0.94,1.55c0.15,0.73-0.84,1.48-1.58,1.33c-0.77-0.15-1.26-0.62-1.27-1.45
	C513.56,346.95,514.24,346.29,515.49,346.36z"
              />
              <path
                d="M263.31,261.85c-0.37,0.25-0.89,0.84-1.51,0.98c-0.75,0.17-1.62-0.82-1.35-1.51c0.22-0.55,0.82-1.12,1.38-1.3
	C262.58,259.76,263.32,260.58,263.31,261.85z"
              />
              <path
                d="M520.19,323.72c0.7,0.91,1.43,1.44,1.46,2.02c0.03,0.44-0.73,1.15-1.28,1.36c-0.73,0.28-1.64-0.58-1.46-1.39
	C519.04,325.13,519.57,324.64,520.19,323.72z"
              />
              <path
                d="M464.84,583.83c-0.37,0.25-0.89,0.83-1.52,0.97c-0.75,0.16-1.61-0.83-1.33-1.52c0.22-0.54,0.83-1.11,1.39-1.29
	C464.13,581.74,464.86,582.56,464.84,583.83z"
              />
              <path
                d="M294.15,263.58c-0.27-0.34-0.88-0.83-1.06-1.45c-0.22-0.72,0.65-1.48,1.45-1.45c0.82,0.03,1.29,0.5,1.38,1.3
	C296.02,262.85,295.42,263.53,294.15,263.58z"
              />
              <path
                d="M442.73,348.83c-0.63-0.95-1.26-1.5-1.35-2.12c-0.11-0.77,0.57-1.25,1.38-1.29c0.86-0.04,1.6,0.68,1.37,1.51
	C443.98,347.49,443.41,347.95,442.73,348.83z"
              />
              <path
                d="M481.16,232.1c-0.37,0.25-0.89,0.84-1.51,0.98c-0.75,0.17-1.62-0.82-1.35-1.51c0.22-0.55,0.82-1.12,1.38-1.3
	C480.43,230.01,481.17,230.83,481.16,232.1z"
              />
              <path
                d="M317.06,625.16c-0.82,0.63-1.27,1.19-1.84,1.37c-0.71,0.22-1.55-0.66-1.54-1.41c0.01-0.77,0.84-1.58,1.58-1.34
	C315.82,623.95,316.25,624.51,317.06,625.16z"
              />
              <path
                d="M461.99,587.46c-0.82,0.64-1.27,1.21-1.84,1.38c-0.78,0.23-1.56-0.58-1.56-1.39c0-0.8,0.81-1.63,1.57-1.4
	C460.72,586.24,461.17,586.81,461.99,587.46z"
              />
              <path
                d="M405.31,131.1c-0.23-0.37-0.86-0.94-0.89-1.55c-0.04-0.86,0.67-1.39,1.6-1.29c0.82,0.09,1.22,0.64,1.23,1.45
	C407.25,130.58,406.6,131.18,405.31,131.1z"
              />
              <path
                d="M261.38,406.19c-0.06,0.85-0.81,1.58-1.57,1.27c-0.54-0.21-1.1-0.84-1.26-1.39c-0.22-0.72,0.66-1.46,1.46-1.44
	C260.83,404.65,261.44,405.34,261.38,406.19z"
              />
              <path
                d="M506.06,610.97c0.23,0.31,0.79,0.77,0.95,1.34c0.23,0.84-0.51,1.55-1.38,1.53c-0.85-0.02-1.32-0.52-1.44-1.31
	C504.07,611.74,504.82,610.97,506.06,610.97z"
              />
              <path
                d="M585.25,630.86c-1.04,0.69-1.58,1.32-2.21,1.41c-0.73,0.1-1.29-0.5-1.29-1.35c0-0.84,0.49-1.47,1.26-1.37
	C583.64,629.62,584.2,630.21,585.25,630.86z"
              />
              <path
                d="M462.33,215.52c-1.02,0.57-1.62,1.14-2.24,1.18c-0.76,0.05-1.23-0.6-1.18-1.45c0.05-0.85,0.81-1.54,1.6-1.25
	C461.07,214.21,461.48,214.79,462.33,215.52z"
              />
              <path
                d="M587.62,626.52c0.09,1.28-0.6,2.14-1.37,1.92c-0.56-0.17-1.19-0.75-1.41-1.29c-0.29-0.71,0.59-1.63,1.39-1.47
	C586.82,625.79,587.31,626.32,587.62,626.52z"
              />
              <path
                d="M402.45,623.45c0.22,0.31,0.76,0.79,0.91,1.36c0.21,0.85-0.54,1.54-1.41,1.49c-0.84-0.05-1.31-0.54-1.41-1.35
	C400.45,624.18,401.21,623.43,402.45,623.45z"
              />
              <path
                d="M393.71,114.75c-0.72-0.96-1.33-1.46-1.49-2.07c-0.2-0.77,0.4-1.31,1.19-1.46c0.78-0.14,1.75,0.55,1.62,1.29
	C394.9,113.15,394.35,113.71,393.71,114.75z"
              />
              <path
                d="M530.53,624.77c-0.96,0.72-1.45,1.34-2.07,1.5c-0.78,0.21-1.3-0.38-1.43-1.18c-0.13-0.83,0.49-1.74,1.26-1.6
	C528.93,623.6,529.49,624.15,530.53,624.77z"
              />
              <path
                d="M297.85,616.12c-0.06,0.81-0.88,1.61-1.58,1.3c-0.54-0.24-1.1-0.85-1.28-1.42c-0.22-0.71,0.68-1.47,1.48-1.45
	C297.3,614.58,297.91,615.28,297.85,616.12z"
              />
              <path
                d="M439.36,388.59c0.65,0.78,1.22,1.21,1.4,1.76c0.25,0.8-0.48,1.55-1.31,1.58c-0.78,0.02-1.67-0.78-1.45-1.49
	C438.18,389.86,438.74,389.41,439.36,388.59z"
              />
              <path
                d="M323.9,481.43c-0.27-0.34-0.88-0.83-1.06-1.45c-0.22-0.72,0.65-1.48,1.45-1.45c0.82,0.03,1.29,0.5,1.38,1.3
	C325.77,480.69,325.17,481.38,323.9,481.43z"
              />
              <path
                d="M403.93,126.45c-0.86,0.59-1.34,1.13-1.91,1.27c-0.78,0.19-1.54-0.67-1.49-1.46c0.05-0.81,0.89-1.59,1.63-1.32
	C402.72,125.15,403.13,125.75,403.93,126.45z"
              />
              <path
                d="M485,227.89c-0.83,0.62-1.29,1.16-1.86,1.33c-0.72,0.21-1.54-0.68-1.51-1.43c0.02-0.77,0.86-1.57,1.6-1.32
	C483.78,226.65,484.21,227.22,485,227.89z"
              />
              <path
                d="M465.6,218.52c-0.04,0.81-0.81,1.48-1.64,1.36c-0.84-0.12-1.26-0.66-1.23-1.49c0.03-0.83,0.5-1.32,1.35-1.37
	C464.9,216.98,465.64,217.73,465.6,218.52z"
              />
              <path
                d="M509.4,617.62c-0.65-1.03-1.22-1.58-1.35-2.22c-0.15-0.73,0.82-1.45,1.6-1.32c0.79,0.13,1.4,0.66,1.21,1.44
	C510.71,616.15,510.1,616.65,509.4,617.62z"
              />
              <path
                d="M316.12,531.22c-0.8,0.69-1.22,1.29-1.78,1.48c-0.76,0.27-1.59-0.51-1.62-1.32c-0.04-0.79,0.74-1.66,1.5-1.46
	C314.79,530.07,315.27,530.61,316.12,531.22z"
              />
              <path
                d="M379.37,265.2c0.09,1.28-0.59,2.14-1.37,1.91c-0.56-0.17-1.19-0.75-1.41-1.29c-0.29-0.71,0.59-1.63,1.39-1.47
	C378.56,264.48,379.05,265,379.37,265.2z"
              />
              <path
                d="M293.48,392.42c-0.99,0.68-1.53,1.26-2.17,1.41c-0.76,0.18-1.41-0.71-1.34-1.56c0.07-0.85,0.63-1.41,1.37-1.27
	C291.97,391.13,292.5,391.74,293.48,392.42z"
              />
              <path
                d="M442.31,152.03c-0.82,0.65-1.28,1.21-1.85,1.4c-0.72,0.23-1.57-0.67-1.56-1.44c0.01-0.78,0.85-1.62,1.6-1.38
	C441.07,150.8,441.51,151.38,442.31,152.03z"
              />
              <path
                d="M340.59,605.28c-0.84,0.62-1.31,1.17-1.88,1.32c-0.78,0.21-1.55-0.63-1.52-1.43c0.03-0.8,0.86-1.62,1.6-1.36
	C339.35,604.01,339.78,604.6,340.59,605.28z"
              />
              <path
                d="M521.38,623.33c0.6,1.01,1.21,1.6,1.26,2.22c0.05,0.75-0.61,1.25-1.46,1.24c-0.85-0.02-1.58-0.79-1.3-1.6
	C520.07,624.63,520.66,624.2,521.38,623.33z"
              />
              <path
                d="M318.51,473.38c-0.79,0.7-1.2,1.28-1.75,1.49c-0.7,0.27-1.57-0.53-1.62-1.29c-0.05-0.8,0.67-1.62,1.47-1.44
	C317.17,472.27,317.64,472.8,318.51,473.38z"
              />
              <path
                d="M538.13,631.71c-1.07,0.48-1.7,0.98-2.33,0.98c-0.79,0-1.16-0.71-1.06-1.53c0.11-0.85,0.91-1.46,1.69-1.1
	C536.97,630.29,537.34,630.91,538.13,631.71z"
              />
              <path
                d="M364.97,392.85c0.09,1.27-0.59,2.12-1.36,1.9c-0.56-0.16-1.18-0.74-1.4-1.28c-0.28-0.71,0.58-1.62,1.38-1.45
	C364.16,392.13,364.65,392.64,364.97,392.85z"
              />
              <path
                d="M383.99,93.2c-0.21-0.31-0.74-0.8-0.87-1.38c-0.19-0.84,0.64-1.69,1.42-1.43c0.56,0.19,1.16,0.8,1.35,1.35
	C386.15,92.48,385.26,93.24,383.99,93.2z"
              />
              <path
                d="M362.17,271.09c-0.85,0.61-1.31,1.16-1.89,1.32c-0.74,0.2-1.54-0.69-1.5-1.46c0.03-0.78,0.88-1.58,1.62-1.32
	C360.96,269.83,361.38,270.42,362.17,271.09z"
              />
              <path
                d="M285.41,401.74c-1.26,0.04-2.16-0.73-1.91-1.45c0.19-0.56,0.8-1.16,1.35-1.35c0.78-0.26,1.61,0.59,1.42,1.43
	C286.14,400.94,285.62,401.42,285.41,401.74z"
              />
              <path
                d="M356.84,266.22c0.23,0.32,0.79,0.79,0.94,1.36c0.21,0.82-0.64,1.72-1.4,1.47c-0.56-0.19-1.18-0.78-1.39-1.34
	C354.72,267,355.62,266.2,356.84,266.22z"
              />
              <path
                d="M437.51,394.65c-0.33,0.28-0.8,0.9-1.41,1.11c-0.7,0.24-1.69-0.71-1.47-1.41c0.17-0.56,0.73-1.19,1.27-1.41
	C436.63,392.63,437.42,393.38,437.51,394.65z"
              />
              <path
                d="M482.33,222.72c-0.64-1.01-1.21-1.55-1.33-2.18c-0.15-0.8,0.5-1.27,1.29-1.36c0.78-0.09,1.72,0.68,1.53,1.4
	C483.64,221.21,483.04,221.73,482.33,222.72z"
              />
              <path
                d="M418.72,338.04c-0.94,0.75-1.42,1.41-2.04,1.56c-0.72,0.18-1.32-0.35-1.45-1.18c-0.13-0.84,0.47-1.76,1.24-1.64
	C417.11,336.89,417.68,337.43,418.72,338.04z"
              />
              <path
                d="M250.53,336.17c0.7,0.92,1.43,1.46,1.46,2.04c0.03,0.45-0.74,1.16-1.3,1.37c-0.74,0.28-1.65-0.6-1.46-1.41
	C249.36,337.59,249.9,337.1,250.53,336.17z"
              />
              <path
                d="M303.08,626.58c-0.99,0.68-1.53,1.27-2.16,1.42c-0.76,0.18-1.41-0.71-1.34-1.55c0.07-0.85,0.62-1.41,1.37-1.27
	C301.57,625.29,302.1,625.9,303.08,626.58z"
              />
              <path
                d="M472.13,223.7c0.28,0.35,0.91,0.82,1.12,1.44c0.24,0.72-0.6,1.48-1.41,1.48c-0.84,0-1.3-0.46-1.42-1.26
	C470.28,224.53,470.9,223.79,472.13,223.7z"
              />
              <path
                d="M493.11,586.79c0.23,0.31,0.77,0.77,0.92,1.33c0.21,0.81-0.62,1.68-1.38,1.44c-0.55-0.18-1.16-0.77-1.37-1.31
	C491.02,587.53,491.89,586.77,493.11,586.79z"
              />
              <path
                d="M469.48,221.5c-0.09,1.23-0.89,1.87-1.71,1.73c-0.85-0.14-1.23-0.7-1.18-1.52c0.05-0.83,0.59-1.46,1.38-1.28
	C468.59,220.58,469.1,221.22,469.48,221.5z"
              />
              <path
                d="M431.42,335.53c0.26,0.36,0.93,0.88,1.02,1.48c0.13,0.87-0.54,1.42-1.48,1.41c-0.84-0.02-1.27-0.52-1.35-1.33
	C429.54,336.23,430.17,335.55,431.42,335.53z"
              />
              <path
                d="M519.09,337.09c-0.34,0.27-0.82,0.89-1.43,1.08c-0.71,0.22-1.68-0.73-1.45-1.43c0.18-0.56,0.75-1.17,1.3-1.39
	C518.24,335.06,519.01,335.82,519.09,337.09z"
              />
              <path
                d="M490.18,577.78c-1.02,0.58-1.61,1.17-2.24,1.22c-0.75,0.06-1.24-0.6-1.21-1.45c0.04-0.85,0.81-1.57,1.6-1.28
	C488.89,576.47,489.32,577.05,490.18,577.78z"
              />
              <path
                d="M513.95,624.08c0.71,0.91,1.44,1.45,1.48,2.03c0.03,0.45-0.73,1.16-1.29,1.38c-0.72,0.29-1.65-0.6-1.48-1.4
	C512.79,625.51,513.33,625.01,513.95,624.08z"
              />
              <path
                d="M279.11,337.79c-0.63-0.95-1.26-1.5-1.35-2.12c-0.11-0.77,0.57-1.25,1.38-1.29c0.86-0.04,1.6,0.68,1.37,1.51
	C280.35,336.46,279.78,336.91,279.11,337.79z"
              />
              <path
                d="M412.79,170.83c-0.66-0.9-1.22-1.38-1.37-1.96c-0.22-0.82,0.57-1.56,1.42-1.49c0.82,0.06,1.49,0.57,1.36,1.34
	C414.1,169.35,413.45,169.89,412.79,170.83z"
              />
              <path
                d="M439.45,396.25c1.25-0.03,1.91,0.64,1.89,1.48c-0.02,0.84-0.54,1.29-1.3,1.43c-0.75,0.13-1.73-0.65-1.56-1.37
	C438.62,397.15,439.2,396.62,439.45,396.25z"
              />
              <path
                d="M530.91,631.95c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C529.62,630.68,530.06,631.27,530.91,631.95z"
              />
              <path
                d="M577.94,631.36c-0.9,0.65-1.37,1.21-1.95,1.37c-0.78,0.22-1.52-0.64-1.43-1.48c0.09-0.85,0.65-1.38,1.44-1.2
	C576.58,630.17,577.06,630.73,577.94,631.36z"
              />
              <path
                d="M342.54,626.51c-0.88,0.69-1.33,1.26-1.89,1.43c-0.75,0.22-1.59-0.67-1.5-1.45c0.1-0.82,0.64-1.39,1.44-1.24
	C341.15,625.35,341.63,625.9,342.54,626.51z"
              />
              <path
                d="M280.71,268.62c0.72,0.81,1.32,1.23,1.52,1.79c0.26,0.71-0.62,1.59-1.42,1.52c-0.81-0.07-1.45-0.52-1.3-1.36
	C279.61,269.99,280.14,269.5,280.71,268.62z"
              />
              <path
                d="M561.19,631.4c1.24,0.04,1.92,0.77,1.79,1.56c-0.13,0.8-0.65,1.23-1.46,1.24c-0.86,0.01-1.57-0.72-1.32-1.53
	C560.38,632.11,560.95,631.69,561.19,631.4z"
              />
              <path
                d="M306.38,582.89c-0.84,0.67-1.29,1.25-1.87,1.44c-0.72,0.24-1.49-0.65-1.43-1.49c0.06-0.87,0.64-1.4,1.4-1.27
	C305.04,581.67,305.52,582.27,306.38,582.89z"
              />
              <path
                d="M480.53,217.27c-0.9,0.65-1.38,1.2-1.96,1.36c-0.78,0.21-1.51-0.64-1.42-1.49c0.09-0.85,0.66-1.38,1.45-1.2
	C479.18,216.07,479.65,216.64,480.53,217.27z"
              />
              <path
                d="M398.02,125.14c-0.66-0.8-1.26-1.24-1.43-1.79c-0.24-0.81,0.32-1.36,1.14-1.49c0.82-0.14,1.75,0.59,1.57,1.34
	C399.17,123.78,398.63,124.27,398.02,125.14z"
              />
              <path
                d="M242.04,216.41c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.12
	C240.75,215.14,241.2,215.73,242.04,216.41z"
              />
              <path
                d="M446.72,601.15c0.72,0.81,1.32,1.22,1.53,1.78c0.27,0.72-0.61,1.59-1.41,1.53c-0.81-0.06-1.46-0.51-1.31-1.35
	C445.64,602.53,446.16,602.03,446.72,601.15z"
              />
              <path
                d="M297.49,628.5c0.72,0.81,1.32,1.22,1.53,1.78c0.27,0.71-0.61,1.59-1.41,1.53c-0.81-0.06-1.46-0.51-1.31-1.35
	C296.4,629.88,296.93,629.38,297.49,628.5z"
              />
              <path
                d="M315.94,484.67c-0.93,0.59-1.44,1.11-2.03,1.23c-0.8,0.16-1.46-0.73-1.32-1.58c0.14-0.86,0.76-1.32,1.52-1.1
	C314.67,483.38,315.1,483.98,315.94,484.67z"
              />
              <path
                d="M262.4,326.68c-0.9,0.65-1.36,1.21-1.93,1.36c-0.75,0.2-1.58-0.72-1.46-1.5c0.13-0.82,0.69-1.37,1.48-1.2
	C261.05,325.46,261.52,326.04,262.4,326.68z"
              />
              <path
                d="M472.37,582.82c-0.87,0.69-1.31,1.26-1.89,1.45c-0.78,0.25-1.53-0.56-1.48-1.42c0.05-0.86,0.58-1.41,1.38-1.26
	C470.96,581.68,471.46,582.22,472.37,582.82z"
              />
              <path
                d="M484.22,410.16c-0.59-0.85-1.14-1.34-1.26-1.91c-0.17-0.83,0.44-1.31,1.27-1.38c0.85-0.07,1.7,0.71,1.45,1.46
	C485.49,408.91,484.9,409.35,484.22,410.16z"
              />
              <path
                d="M324.77,545.1c-0.92,0.61-1.41,1.14-1.98,1.26c-0.78,0.16-1.54-0.76-1.39-1.55c0.16-0.82,0.75-1.34,1.52-1.15
	C323.48,543.81,323.92,544.41,324.77,545.1z"
              />
              <path
                d="M512.68,630.03c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C511.39,628.76,511.83,629.36,512.68,630.03z"
              />
              <path
                d="M469.21,586.75c-0.9,0.65-1.36,1.21-1.93,1.36c-0.75,0.2-1.58-0.72-1.46-1.5c0.13-0.82,0.69-1.37,1.48-1.2
	C467.87,585.54,468.33,586.11,469.21,586.75z"
              />
              <path d="M223.21,237.07c0-0.98,0-1.8,0-2.67c1.23-0.27,1.89,0.14,1.95,1.22C225.21,236.68,224.65,237.24,223.21,237.07z" />
              <path
                d="M485.53,234.96c-0.88,0.69-1.33,1.26-1.9,1.43c-0.75,0.22-1.59-0.68-1.49-1.46c0.1-0.82,0.64-1.39,1.44-1.24
	C484.15,233.8,484.63,234.35,485.53,234.96z"
              />
              <path
                d="M277.07,412.22c-0.93,0.59-1.44,1.11-2.03,1.23c-0.8,0.16-1.46-0.73-1.32-1.58c0.14-0.86,0.76-1.32,1.52-1.1
	C275.8,410.92,276.23,411.52,277.07,412.22z"
              />
              <path
                d="M507.17,382.81c-0.68-0.81-1.27-1.24-1.47-1.81c-0.26-0.75,0.58-1.54,1.43-1.48c0.83,0.06,1.45,0.53,1.28,1.37
	C508.3,381.46,507.75,381.95,507.17,382.81z"
              />
              <path
                d="M308.94,239.51c-0.93,0.57-1.44,1.09-2.02,1.19c-0.79,0.14-1.51-0.8-1.34-1.58c0.18-0.83,0.8-1.31,1.56-1.11
	C307.68,238.16,308.1,238.79,308.94,239.51z"
              />
              <path
                d="M278.69,623.05c-0.66-0.87-1.24-1.33-1.39-1.89c-0.2-0.75,0.72-1.52,1.52-1.44c0.87,0.08,1.37,0.67,1.2,1.44
	C279.91,621.72,279.33,622.18,278.69,623.05z"
              />
              <path
                d="M447.21,355.47c0.72,0.81,1.32,1.23,1.52,1.79c0.26,0.71-0.62,1.59-1.42,1.52c-0.81-0.07-1.45-0.52-1.3-1.36
	C446.12,356.84,446.65,356.35,447.21,355.47z"
              />
              <path
                d="M351.93,568.13c-0.93,0.6-1.43,1.13-2.02,1.26c-0.8,0.17-1.47-0.71-1.34-1.56c0.13-0.86,0.73-1.33,1.51-1.13
	C350.64,566.86,351.08,567.46,351.93,568.13z"
              />
              <path
                d="M473.05,590.21c-0.92,0.6-1.41,1.13-1.99,1.25c-0.78,0.16-1.54-0.77-1.39-1.55c0.16-0.82,0.76-1.34,1.53-1.14
	C471.76,588.92,472.19,589.52,473.05,590.21z"
              />
              <path
                d="M342.02,81.06c-0.81,0.72-1.22,1.32-1.77,1.53c-0.73,0.27-1.75-0.63-1.52-1.35c0.18-0.56,0.81-1.25,1.33-1.33
	C340.52,79.83,341.11,80.49,342.02,81.06z"
              />
              <path
                d="M337.43,592.79c-0.62-0.89-1.17-1.37-1.29-1.94c-0.17-0.81,0.4-1.34,1.22-1.44c0.78-0.1,1.71,0.74,1.49,1.46
	C338.67,591.45,338.1,591.91,337.43,592.79z"
              />
              <path
                d="M378.48,457.5c-0.84,0.61-1.3,1.15-1.87,1.3c-0.77,0.2-1.54-0.64-1.51-1.43c0.03-0.79,0.87-1.6,1.6-1.34
	C377.27,456.23,377.69,456.81,378.48,457.5z"
              />
              <path
                d="M380.99,430.17c-0.59-0.96-1.1-1.46-1.21-2.04c-0.16-0.84,0.7-1.65,1.46-1.35c0.54,0.21,1.27,0.93,1.24,1.37
	C382.43,428.72,381.72,429.25,380.99,430.17z"
              />
              <path
                d="M313.18,369.3c-1.11,0.59-1.71,1.14-2.35,1.18c-0.82,0.06-1.22-0.66-1.15-1.47c0.06-0.82,0.6-1.45,1.38-1.26
	C311.68,367.9,312.19,368.54,313.18,369.3z"
              />
              <path
                d="M256.61,428.19c-0.87,0.63-1.35,1.2-1.91,1.32c-0.81,0.16-1.31-0.48-1.44-1.26c-0.12-0.76,0.75-1.68,1.48-1.46
	C255.32,426.95,255.76,427.52,256.61,428.19z"
              />
              <path
                d="M236.72,364.89c-0.87,0.6-1.36,1.14-1.95,1.28c-0.8,0.19-1.5-0.68-1.37-1.53c0.13-0.82,0.68-1.39,1.47-1.19
	C235.45,363.61,235.9,364.22,236.72,364.89z"
              />
              <path
                d="M501.7,607.08c0.6,0.93,1.12,1.43,1.25,2.02c0.16,0.75-0.81,1.49-1.59,1.34c-0.83-0.16-1.34-0.75-1.11-1.53
	C500.4,608.35,501,607.92,501.7,607.08z"
              />
              <path
                d="M389.03,100.3c0.67,0.92,1.22,1.38,1.37,1.95c0.22,0.84-0.52,1.54-1.38,1.51c-0.84-0.03-1.47-0.56-1.37-1.31
	C387.73,101.83,388.36,101.29,389.03,100.3z"
              />
              <path
                d="M510.19,252.58c-0.63-0.88-1.19-1.35-1.31-1.92c-0.18-0.81,0.39-1.34,1.21-1.46c0.78-0.11,1.71,0.73,1.5,1.45
	C511.41,251.24,510.86,251.7,510.19,252.58z"
              />
              <path
                d="M431.23,189.26c-1.08,0.64-1.65,1.22-2.28,1.3c-0.8,0.09-1.25-0.59-1.22-1.41c0.03-0.82,0.52-1.48,1.31-1.33
	C429.66,187.93,430.19,188.55,431.23,189.26z"
              />
              <path
                d="M337.62,609.03c-1.02,0.67-1.56,1.27-2.18,1.37c-0.78,0.12-1.26-0.52-1.27-1.34c-0.01-0.82,0.47-1.48,1.24-1.38
	C336.03,607.77,336.58,608.37,337.62,609.03z"
              />
              <path
                d="M450.46,371.51c-0.81,0.66-1.24,1.23-1.81,1.41c-0.75,0.24-1.56-0.57-1.57-1.37c-0.01-0.79,0.79-1.63,1.54-1.41
	C449.19,370.31,449.64,370.87,450.46,371.51z"
              />
              <path
                d="M436.56,253.37c-0.83,0.71-1.25,1.3-1.81,1.49c-0.73,0.25-1.62-0.62-1.53-1.41c0.09-0.8,0.58-1.44,1.38-1.32
	C435.16,252.22,435.66,252.78,436.56,253.37z"
              />
              <path
                d="M476.38,585.89c-0.83,0.71-1.25,1.3-1.81,1.49c-0.73,0.25-1.62-0.62-1.53-1.41c0.09-0.8,0.58-1.44,1.38-1.32
	C474.99,584.75,475.49,585.3,476.38,585.89z"
              />
              <path
                d="M356.68,372.52c-0.86,0.62-1.33,1.18-1.91,1.33c-0.8,0.21-1.51-0.64-1.4-1.49c0.11-0.83,0.63-1.41,1.43-1.22
	C355.38,371.27,355.84,371.87,356.68,372.52z"
              />
              <path
                d="M609.61,628.3c1.01-0.69,1.54-1.31,2.16-1.42c0.77-0.13,1.26,0.51,1.29,1.32c0.02,0.82-0.44,1.48-1.22,1.39
	C611.22,629.53,610.66,628.94,609.61,628.3z"
              />
              <path
                d="M372.18,425.21c-1.02,0.7-1.54,1.31-2.17,1.43c-0.79,0.15-1.28-0.49-1.31-1.31c-0.03-0.83,0.44-1.5,1.21-1.42
	C370.55,423.97,371.12,424.56,372.18,425.21z"
              />
              <path
                d="M452.32,598.85c-1,0.73-1.51,1.35-2.13,1.49c-0.78,0.17-1.3-0.45-1.35-1.27c-0.05-0.82,0.39-1.51,1.17-1.45
	C450.65,597.66,451.23,598.24,452.32,598.85z"
              />
              <path
                d="M379.23,424.87c-0.87,0.6-1.36,1.14-1.95,1.28c-0.8,0.19-1.5-0.68-1.37-1.53c0.13-0.82,0.68-1.39,1.47-1.19
	C377.96,423.59,378.41,424.2,379.23,424.87z"
              />
              <path
                d="M499.34,598.57c-1.07,0.63-1.64,1.22-2.26,1.29c-0.79,0.09-1.24-0.59-1.21-1.41c0.03-0.82,0.52-1.47,1.31-1.32
	C497.8,597.24,498.33,597.86,499.34,598.57z"
              />
              <path
                d="M428.79,619.13c-1.03,0.66-1.58,1.26-2.2,1.35c-0.78,0.11-1.25-0.53-1.25-1.36c0-0.82,0.49-1.47,1.25-1.36
	C427.21,617.85,427.76,618.46,428.79,619.13z"
              />
              <path
                d="M457.18,592.71c-1.25,0.03-2.14-0.74-1.89-1.45c0.2-0.55,0.8-1.15,1.35-1.34c0.77-0.26,1.6,0.59,1.4,1.43
	C457.92,591.92,457.39,592.4,457.18,592.71z"
              />
              <path
                d="M332.9,598.32c-0.81,0.72-1.22,1.32-1.77,1.53c-0.73,0.27-1.75-0.63-1.52-1.35c0.18-0.56,0.81-1.25,1.33-1.33
	C331.41,597.1,332,597.76,332.9,598.32z"
              />
              <path
                d="M373.2,463.29c-0.66-0.98-1.34-1.55-1.35-2.12c0-0.44,0.78-1.1,1.34-1.27c0.76-0.23,1.57,0.65,1.36,1.46
	C374.4,461.91,373.86,462.37,373.2,463.29z"
              />
              <path
                d="M446.13,174.74c0.64,0.88,1.2,1.34,1.34,1.91c0.19,0.8-0.37,1.35-1.2,1.47c-0.79,0.11-1.72-0.69-1.51-1.43
	C444.92,176.1,445.48,175.63,446.13,174.74z"
              />
              <path
                d="M342.23,563.52c-0.62-0.89-1.17-1.37-1.29-1.94c-0.17-0.81,0.4-1.34,1.22-1.44c0.78-0.1,1.71,0.74,1.49,1.46
	C343.46,562.18,342.9,562.64,342.23,563.52z"
              />
              <path
                d="M345.39,77.48c-0.89,0.6-1.37,1.16-1.94,1.26c-0.82,0.14-1.31-0.5-1.41-1.29c-0.1-0.77,0.79-1.67,1.51-1.43
	C344.11,76.21,344.55,76.79,345.39,77.48z"
              />
              <path
                d="M400.53,628.9c-0.86,0.55-1.34,1.06-1.91,1.19c-0.75,0.17-1.49-0.72-1.42-1.48c0.07-0.77,0.96-1.54,1.64-1.25
	C399.39,627.59,399.77,628.18,400.53,628.9z"
              />
              <path
                d="M350.11,254.04c-1.07,0.63-1.64,1.22-2.26,1.29c-0.79,0.09-1.24-0.59-1.21-1.41c0.03-0.82,0.52-1.47,1.31-1.32
	C348.57,252.72,349.1,253.34,350.11,254.04z"
              />
              <path
                d="M340.37,558.23c-0.86,0.61-1.34,1.16-1.92,1.31c-0.8,0.2-1.51-0.66-1.39-1.51c0.12-0.82,0.65-1.4,1.45-1.21
	C339.07,556.97,339.53,557.58,340.37,558.23z"
              />
              <path
                d="M454.78,421.99c-0.84,0.61-1.3,1.15-1.87,1.3c-0.77,0.2-1.54-0.64-1.51-1.43c0.03-0.79,0.87-1.6,1.6-1.34
	C453.56,420.72,453.98,421.31,454.78,421.99z"
              />
              <path
                d="M425.3,622.96c-0.84,0.63-1.31,1.23-1.89,1.36c-0.8,0.19-1.33-0.39-1.43-1.23c-0.1-0.85,0.63-1.71,1.41-1.49
	C423.98,621.76,424.44,622.33,425.3,622.96z"
              />
              <path
                d="M334.99,599.89c0.55,0.92,1.07,1.44,1.15,2.02c0.11,0.81-0.48,1.31-1.32,1.34c-0.8,0.03-1.67-0.86-1.39-1.55
	C333.66,601.13,334.26,600.71,334.99,599.89z"
              />
              <path
                d="M516.16,254.04c-1.08,0.64-1.65,1.22-2.28,1.29c-0.8,0.09-1.25-0.59-1.22-1.42c0.03-0.82,0.52-1.48,1.32-1.33
	C514.6,252.71,515.13,253.33,516.16,254.04z"
              />
              <path
                d="M373.43,419.39c0.68,0.98,1.32,1.52,1.41,2.14c0.11,0.75-0.51,1.28-1.35,1.32c-0.86,0.04-1.61-0.65-1.4-1.49
	C372.24,420.79,372.78,420.32,373.43,419.39z"
              />
              <path
                d="M524.6,630.14c-0.21-0.31-0.74-0.79-0.88-1.36c-0.2-0.83,0.63-1.68,1.4-1.43c0.55,0.18,1.16,0.78,1.36,1.33
	C526.74,629.39,525.85,630.17,524.6,630.14z"
              />
              <path
                d="M338.17,596.04c0.64,0.88,1.2,1.34,1.34,1.91c0.19,0.8-0.37,1.35-1.2,1.47c-0.79,0.11-1.72-0.69-1.51-1.43
	C336.96,597.4,337.51,596.93,338.17,596.04z"
              />
              <path
                d="M363.45,355.21c-0.23-0.3-0.79-0.75-0.96-1.31c-0.24-0.81,0.51-1.57,1.35-1.55c0.84,0.02,1.31,0.52,1.45,1.3
	C365.43,354.42,364.69,355.18,363.45,355.21z"
              />
              <path
                d="M316.57,618.35c-0.86,0.56-1.35,1.07-1.92,1.19c-0.76,0.16-1.5-0.72-1.43-1.49c0.07-0.77,0.96-1.55,1.66-1.25
	C315.42,617.03,315.81,617.62,316.57,618.35z"
              />
              <path
                d="M336.23,554.85c-0.81,0.63-1.25,1.18-1.81,1.35c-0.73,0.22-1.53-0.61-1.52-1.39c0.01-0.77,0.83-1.61,1.55-1.37
	C335,553.64,335.43,554.2,336.23,554.85z"
              />
              <path
                d="M368.14,275.5c0.67,0.88,1.24,1.34,1.41,1.92c0.22,0.73-0.69,1.55-1.49,1.45c-0.83-0.1-1.39-0.64-1.22-1.44
	C366.97,276.87,367.52,276.39,368.14,275.5z"
              />
              <path
                d="M305.99,203.66c-1.08,0.64-1.65,1.22-2.28,1.3c-0.8,0.09-1.25-0.59-1.22-1.41c0.03-0.82,0.52-1.48,1.31-1.33
	C304.42,202.33,304.96,202.94,305.99,203.66z"
              />
              <path
                d="M470.47,205.03c-1.23-0.08-2.06-0.95-1.74-1.61c0.26-0.53,0.91-1.07,1.48-1.21c0.77-0.19,1.55,0.77,1.28,1.56
	C471.3,204.32,470.71,204.74,470.47,205.03z"
              />
              <path
                d="M258.79,204.13c-0.87,0.6-1.35,1.15-1.94,1.3c-0.8,0.2-1.5-0.67-1.38-1.52c0.12-0.82,0.66-1.4,1.46-1.2
	C257.51,202.85,257.96,203.46,258.79,204.13z"
              />
              <path
                d="M472.01,243.55c-1.1,0.61-1.69,1.17-2.33,1.22c-0.81,0.07-1.23-0.63-1.18-1.45c0.05-0.83,0.57-1.46,1.36-1.28
	C470.49,242.17,471,242.81,472.01,243.55z"
              />
              <path
                d="M349.63,206.97c-1.05,0.66-1.6,1.25-2.23,1.34c-0.79,0.11-1.26-0.55-1.24-1.38c0.01-0.82,0.5-1.48,1.28-1.35
	C348.06,205.67,348.6,206.29,349.63,206.97z"
              />
              <path
                d="M310.18,605.6c0.63,0.98,1.29,1.57,1.28,2.14c-0.01,0.43-0.81,1.07-1.37,1.22c-0.77,0.21-1.55-0.69-1.31-1.48
	C308.94,606.93,309.49,606.49,310.18,605.6z"
              />
              <path
                d="M450.93,418.7c-0.85,0.57-1.33,1.1-1.9,1.23c-0.76,0.18-1.51-0.69-1.46-1.47c0.06-0.78,0.92-1.57,1.63-1.29
	C449.75,417.39,450.15,417.99,450.93,418.7z"
              />
              <path
                d="M378.06,434.01c-0.69-0.96-1.38-1.51-1.39-2.08c-0.01-0.44,0.75-1.12,1.3-1.3c0.75-0.25,1.58,0.6,1.38,1.41
	C379.21,432.61,378.68,433.08,378.06,434.01z"
              />
              <path
                d="M368.86,96.99c-0.78,0.67-1.2,1.25-1.75,1.44c-0.72,0.26-1.55-0.55-1.58-1.32c-0.03-0.77,0.75-1.63,1.49-1.43
	C367.58,95.84,368.04,96.38,368.86,96.99z"
              />
              <path
                d="M335.57,587.55c-0.88,0.59-1.37,1.14-1.96,1.27c-0.8,0.18-1.49-0.69-1.36-1.54c0.13-0.82,0.69-1.39,1.48-1.17
	C334.3,586.26,334.75,586.87,335.57,587.55z"
              />
              <path
                d="M311.52,630.61c-0.21-0.32-0.73-0.81-0.85-1.38c-0.18-0.84,0.66-1.67,1.43-1.4c0.55,0.19,1.14,0.81,1.33,1.36
	C313.67,629.91,312.77,630.67,311.52,630.61z"
              />
              <path
                d="M370.86,434.5c-0.69-0.97-1.39-1.52-1.4-2.1c-0.01-0.44,0.75-1.12,1.3-1.31c0.76-0.26,1.59,0.59,1.39,1.42
	C372.02,433.09,371.49,433.56,370.86,434.5z"
              />
              <path
                d="M307.44,597.2c-0.85,0.57-1.32,1.09-1.89,1.23c-0.75,0.18-1.5-0.69-1.45-1.46c0.06-0.77,0.93-1.56,1.62-1.28
	C306.28,595.9,306.67,596.49,307.44,597.2z"
              />
              <path
                d="M409.03,622.39c0.63,0.99,1.25,1.55,1.31,2.17c0.07,0.73-0.56,1.26-1.4,1.27c-0.84,0.01-1.57-0.73-1.33-1.54
	C407.78,623.72,408.34,623.27,409.03,622.39z"
              />
              <path
                d="M246,113.6c1.34,0.92,1.56,1.41,0.91,2.16c-0.66,0.77-1.4,1.04-2.19-0.12c0.18-0.36,0.35-0.78,0.59-1.16
	C245.51,114.16,245.78,113.88,246,113.6z"
              />
              <path
                d="M291.17,616.68c-0.85,0.67-1.29,1.27-1.85,1.41c-0.81,0.2-1.34-0.42-1.5-1.2c-0.15-0.74,0.69-1.71,1.43-1.52
	C289.82,615.51,290.29,616.05,291.17,616.68z"
              />
              <path
                d="M493.59,241.56c-1.06,0.64-1.63,1.22-2.26,1.29c-0.79,0.09-1.24-0.58-1.22-1.41c0.03-0.82,0.52-1.47,1.3-1.32
	C492.04,240.24,492.57,240.86,493.59,241.56z"
              />
              <path
                d="M335.97,618.28c-0.64-0.94-1.16-1.41-1.3-1.98c-0.2-0.82,0.63-1.68,1.39-1.42c0.55,0.18,1.32,0.87,1.31,1.31
	C337.36,616.76,336.66,617.31,335.97,618.28z"
              />
              <path
                d="M474.04,577.36c0.29,0.36,1,0.88,1.05,1.46c0.04,0.43-0.7,1.17-1.24,1.35c-0.76,0.25-1.33-0.3-1.48-1.12
	C472.21,578.22,472.8,577.46,474.04,577.36z"
              />
              <path
                d="M314.01,612.99c-0.69-0.89-1.24-1.34-1.41-1.9c-0.24-0.8,0.56-1.71,1.32-1.49c0.56,0.16,1.36,0.8,1.37,1.24
	C315.31,611.41,314.65,612,314.01,612.99z"
              />
              <path
                d="M339.28,317.74c0.56,0.92,1.09,1.43,1.18,2.01c0.12,0.81-0.45,1.32-1.3,1.36c-0.8,0.04-1.68-0.84-1.41-1.53
	C337.97,319,338.56,318.58,339.28,317.74z"
              />
              <path
                d="M347.31,634.13c-0.43-1.11-0.28-1.89,0.63-2.14c0.49-0.14,1.2,0.09,1.65,0.39c0.27,0.19,0.37,0.85,0.3,1.26
	c-0.05,0.25-0.56,0.53-0.9,0.58C348.47,634.28,347.93,634.17,347.31,634.13z"
              />
              <path
                d="M344.82,215.95c0.69,0.89,1.26,1.34,1.43,1.9c0.25,0.82-0.49,1.56-1.34,1.55c-0.85-0.01-1.48-0.53-1.41-1.27
	C343.57,217.51,344.19,216.95,344.82,215.95z"
              />
              <path
                d="M432.14,615.17c-0.99,0.72-1.49,1.35-2.11,1.48c-0.78,0.17-1.29-0.45-1.34-1.27c-0.05-0.82,0.39-1.5,1.17-1.44
	C430.49,613.98,431.07,614.56,432.14,615.17z"
              />
              <path
                d="M402.62,337.42c0.56,0.92,1.09,1.43,1.18,2.01c0.12,0.81-0.45,1.32-1.3,1.36c-0.8,0.04-1.68-0.84-1.41-1.53
	C401.31,338.68,401.9,338.25,402.62,337.42z"
              />
              <path
                d="M517.4,630.62c-0.21-0.31-0.74-0.79-0.88-1.36c-0.2-0.83,0.63-1.68,1.4-1.43c0.55,0.18,1.16,0.78,1.36,1.33
	C519.54,629.87,518.65,630.65,517.4,630.62z"
              />
              <path
                d="M409.8,340.94c-0.73-0.94-1.45-1.47-1.49-2.05c-0.03-0.44,0.71-1.16,1.26-1.36c0.77-0.29,1.62,0.53,1.46,1.37
	C410.91,339.48,410.4,339.97,409.8,340.94z"
              />
              <path
                d="M489.34,238.25c-0.89,0.59-1.38,1.15-1.95,1.24c-0.81,0.13-1.3-0.51-1.39-1.31c-0.09-0.77,0.8-1.67,1.52-1.42
	C488.08,236.96,488.5,237.54,489.34,238.25z"
              />
              <path
                d="M369.34,270.26c-0.8,0.65-1.22,1.21-1.78,1.4c-0.73,0.24-1.54-0.58-1.55-1.36c-0.01-0.77,0.79-1.62,1.52-1.39
	C368.09,269.08,368.53,269.63,369.34,270.26z"
              />
              <path
                d="M479.52,582.23c-0.86,0.61-1.34,1.16-1.92,1.31c-0.8,0.2-1.51-0.66-1.39-1.51c0.12-0.82,0.65-1.4,1.45-1.21
	C478.23,580.96,478.69,581.57,479.52,582.23z"
              />
              <path
                d="M301.95,412.81c0.05,1.21-0.69,1.94-1.5,1.89c-0.85-0.05-1.3-0.55-1.33-1.37c-0.03-0.83,0.44-1.51,1.22-1.42
	C300.98,411.97,301.55,412.56,301.95,412.81z"
              />
              <path
                d="M473.65,410.76c0.7,0.96,1.35,1.47,1.47,2.08c0.14,0.74-0.47,1.29-1.3,1.36c-0.85,0.07-1.62-0.6-1.43-1.44
	C472.52,412.19,473.04,411.71,473.65,410.76z"
              />
              <path
                d="M435.11,611.63c-0.87,0.56-1.36,1.08-1.94,1.2c-0.78,0.16-1.51-0.72-1.44-1.5c0.07-0.78,0.96-1.55,1.67-1.26
	C433.94,610.3,434.34,610.9,435.11,611.63z"
              />
              <path
                d="M438.37,338.47c-0.7-0.84-1.3-1.27-1.46-1.83c-0.22-0.78,0.3-1.37,1.11-1.54c0.78-0.16,1.75,0.59,1.59,1.35
	C439.49,337.04,438.96,337.54,438.37,338.47z"
              />
              <path
                d="M570.82,632.2c-1.07,0.6-1.65,1.17-2.27,1.22c-0.79,0.07-1.22-0.62-1.17-1.43c0.05-0.81,0.56-1.44,1.33-1.28
	C569.32,630.85,569.83,631.48,570.82,632.2z"
              />
              <path
                d="M241.09,394.97c0.72,0.84,1.3,1.26,1.49,1.81c0.28,0.78-0.48,1.72-1.24,1.55c-0.57-0.13-1.4-0.73-1.43-1.16
	C239.87,396.59,240.51,395.97,241.09,394.97z"
              />
              <path
                d="M389.02,270.34c-0.65-0.9-1.2-1.37-1.36-1.95c-0.2-0.73,0.75-1.55,1.52-1.43c0.82,0.13,1.38,0.67,1.19,1.48
	C390.23,269,389.66,269.47,389.02,270.34z"
              />
              <path
                d="M455.68,595.14c-1.04,0.67-1.59,1.27-2.22,1.36c-0.79,0.12-1.26-0.54-1.26-1.36c0-0.83,0.48-1.48,1.26-1.37
	C454.09,593.86,454.64,594.46,455.68,595.14z"
              />
              <path
                d="M429.29,333.5c-1,0.73-1.51,1.35-2.13,1.49c-0.78,0.17-1.3-0.45-1.35-1.27c-0.05-0.82,0.39-1.51,1.17-1.45
	C427.61,332.31,428.2,332.89,429.29,333.5z"
              />
              <path
                d="M422.09,168.06c-1.03,0.68-1.58,1.28-2.2,1.38c-0.79,0.13-1.27-0.53-1.27-1.35c-0.01-0.83,0.47-1.49,1.25-1.38
	C420.49,166.79,421.04,167.4,422.09,168.06z"
              />
              <path
                d="M410.31,264.43c0.7,0.95,1.39,1.49,1.42,2.06c0.02,0.43-0.73,1.13-1.28,1.31c-0.77,0.26-1.59-0.55-1.4-1.38
	C409.18,265.85,409.7,265.36,410.31,264.43z"
              />
              <path
                d="M372.73,266.31c-0.78,0.71-1.19,1.3-1.74,1.51c-0.74,0.28-1.59-0.49-1.64-1.28c-0.05-0.78,0.7-1.66,1.47-1.48
	C371.39,265.19,371.87,265.72,372.73,266.31z"
              />
              <path
                d="M290.84,264.51c1.22,0.09,1.82,0.85,1.67,1.68c-0.15,0.81-0.71,1.37-1.47,1.12c-0.53-0.18-1.27-0.91-1.24-1.34
	C289.86,265.4,290.56,264.88,290.84,264.51z"
              />
              <path
                d="M480.8,576.31c0.73,0.93,1.4,1.43,1.53,2.04c0.16,0.75-0.44,1.31-1.27,1.41c-0.85,0.1-1.65-0.56-1.49-1.4
	C479.69,577.77,480.2,577.27,480.8,576.31z"
              />
              <path
                d="M445.67,400.66c-0.78,0.71-1.19,1.31-1.74,1.51c-0.74,0.28-1.59-0.49-1.64-1.28c-0.05-0.78,0.7-1.66,1.47-1.48
	C444.32,399.55,444.81,400.08,445.67,400.66z"
              />
              <path
                d="M265.25,402.21c-0.85,0.66-1.31,1.26-1.87,1.39c-0.81,0.19-1.33-0.44-1.48-1.22c-0.15-0.75,0.7-1.7,1.44-1.5
	C263.91,401.02,264.38,401.58,265.25,402.21z"
              />
              <path
                d="M334.51,596.61c-0.73-0.82-1.33-1.24-1.56-1.8c-0.28-0.69,0.6-1.59,1.39-1.56c0.84,0.03,1.44,0.53,1.32,1.34
	C335.58,595.18,335.06,595.69,334.51,596.61z"
              />
              <path
                d="M330.98,410.75c-0.79,0.7-1.19,1.3-1.75,1.5c-0.75,0.28-1.59-0.49-1.64-1.29c-0.05-0.78,0.72-1.66,1.47-1.47
	C329.65,409.63,330.13,410.16,330.98,410.75z"
              />
              <path
                d="M337.7,403.2c-0.82,0.64-1.26,1.21-1.82,1.38c-0.76,0.23-1.56-0.58-1.56-1.38c0-0.79,0.81-1.62,1.55-1.39
	C336.44,401.99,336.88,402.55,337.7,403.2z"
              />
              <path
                d="M344.2,229.04c-0.86,0.62-1.33,1.18-1.91,1.33c-0.8,0.21-1.51-0.64-1.4-1.49c0.11-0.83,0.63-1.41,1.43-1.22
	C342.9,227.8,343.36,228.4,344.2,229.04z"
              />
              <path
                d="M361.26,326.94c-0.54-0.92-1.06-1.44-1.14-2.02c-0.11-0.81,0.49-1.31,1.33-1.33c0.79-0.03,1.66,0.88,1.38,1.57
	C362.6,325.71,362,326.13,361.26,326.94z"
              />
              <path
                d="M388.83,326.37c-0.83,0.65-1.28,1.26-1.85,1.4c-0.8,0.21-1.34-0.37-1.46-1.19c-0.12-0.84,0.6-1.72,1.38-1.52
	C387.48,325.21,387.96,325.76,388.83,326.37z"
              />
              <path
                d="M366.54,304.87c-0.54-0.92-1.06-1.44-1.14-2.02c-0.11-0.81,0.49-1.31,1.33-1.33c0.79-0.03,1.66,0.88,1.38,1.57
	C367.88,303.64,367.28,304.05,366.54,304.87z"
              />
              <path
                d="M382.59,88.32c-0.81,0.68-1.25,1.29-1.82,1.45c-0.79,0.22-1.35-0.33-1.49-1.16c-0.14-0.84,0.56-1.74,1.34-1.56
	C381.21,87.19,381.71,87.73,382.59,88.32z"
              />
              <path
                d="M356.42,578.28c-0.85,0.67-1.29,1.28-1.85,1.41c-0.81,0.2-1.33-0.43-1.49-1.21c-0.16-0.75,0.68-1.71,1.42-1.52
	C355.07,577.12,355.54,577.66,356.42,578.28z"
              />
              <path
                d="M502.2,618.23c-0.61-0.96-1.2-1.48-1.25-2.05c-0.07-0.77,0.54-1.35,1.39-1.3c0.83,0.04,1.42,0.61,1.24,1.42
	C503.45,616.87,502.87,617.33,502.2,618.23z"
              />
              <path
                d="M340.04,577.8c0.66,0.9,1.23,1.37,1.35,1.94c0.17,0.81-0.41,1.37-1.25,1.4c-0.86,0.04-1.45-0.55-1.37-1.31
	C338.83,579.26,339.42,578.75,340.04,577.8z"
              />
              <path
                d="M453.8,367.77c-0.87,0.6-1.34,1.16-1.9,1.26c-0.81,0.15-1.28-0.5-1.39-1.27c-0.1-0.75,0.77-1.66,1.48-1.42
	C452.55,366.52,452.97,367.09,453.8,367.77z"
              />
              <path
                d="M350.38,261.25c-0.93,0.58-1.45,1.17-2.01,1.2c-0.79,0.04-1.37-0.66-1.13-1.45c0.15-0.48,0.85-1.14,1.25-1.1
	C349.03,259.96,349.51,260.6,350.38,261.25z"
              />
              <path
                d="M481.65,583.59c0.62,0.86,1.17,1.33,1.3,1.89c0.18,0.79-0.38,1.32-1.19,1.44c-0.78,0.11-1.69-0.7-1.48-1.42
	C480.46,584.93,481.01,584.46,481.65,583.59z"
              />
              <path
                d="M501.52,627.36c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C500.25,626.09,500.7,626.71,501.52,627.36z"
              />
              <path
                d="M379.36,368.72c-0.61-0.96-1.2-1.48-1.25-2.05c-0.07-0.77,0.54-1.35,1.39-1.3c0.83,0.04,1.42,0.61,1.24,1.42
	C380.61,367.35,380.03,367.81,379.36,368.72z"
              />
              <path
                d="M456.71,609.63c-0.97,0.59-1.49,1.15-2.05,1.19c-0.81,0.05-1.36-0.68-1.15-1.44c0.13-0.47,0.85-1.13,1.26-1.1
	C455.3,608.3,455.79,608.94,456.71,609.63z"
              />
              <path
                d="M375.85,428.63c-0.83,0.63-1.3,1.22-1.87,1.35c-0.8,0.18-1.32-0.4-1.41-1.22c-0.1-0.84,0.64-1.7,1.4-1.48
	C374.55,427.44,375.01,428,375.85,428.63z"
              />
              <path
                d="M479.01,409.06c-0.91,0.58-1.42,1.16-2,1.22c-0.81,0.09-1.3-0.55-1.25-1.4c0.05-0.85,0.61-1.43,1.4-1.25
	C477.73,407.76,478.18,408.39,479.01,409.06z"
              />
              <path
                d="M309.02,591.24c0.57,0.95,1.11,1.47,1.18,2.05c0.1,0.82-0.52,1.33-1.37,1.28c-0.84-0.05-1.41-0.65-1.26-1.42
	C307.69,592.59,308.32,592.13,309.02,591.24z"
              />
              <path
                d="M516.65,261.24c-0.9,0.59-1.39,1.15-1.94,1.22c-0.8,0.1-1.3-0.55-1.27-1.34c0.03-0.79,0.57-1.41,1.35-1.26
	C515.34,259.97,515.79,260.57,516.65,261.24z"
              />
              <path
                d="M453.52,407.44c-0.82,0.65-1.26,1.26-1.81,1.37c-0.77,0.16-1.31-0.42-1.35-1.24c-0.04-0.84,0.48-1.44,1.24-1.36
	C452.15,406.28,452.65,406.86,453.52,407.44z"
              />
              <path
                d="M330.24,609.66c-0.87,0.56-1.36,1.09-1.94,1.21c-0.78,0.16-1.45-0.73-1.29-1.56c0.15-0.81,0.72-1.35,1.49-1.13
	C329.04,608.35,329.46,608.98,330.24,609.66z"
              />
              <path
                d="M475.11,591.27c0.54,0.91,1.06,1.43,1.14,2.01c0.11,0.81-0.48,1.3-1.32,1.33c-0.79,0.03-1.65-0.87-1.37-1.55
	C473.79,592.49,474.38,592.08,475.11,591.27z"
              />
              <path
                d="M452.86,606.27c-0.92,0.59-1.43,1.15-1.99,1.22c-0.81,0.1-1.32-0.57-1.28-1.37c0.04-0.79,0.6-1.43,1.39-1.27
	C451.54,604.96,451.99,605.58,452.86,606.27z"
              />
              <path
                d="M287.3,407.4c-0.85,0.69-1.29,1.31-1.83,1.44c-0.8,0.18-1.34-0.46-1.4-1.24c-0.06-0.8,0.46-1.46,1.25-1.39
	C285.87,406.26,286.38,406.82,287.3,407.4z"
              />
              <path
                d="M294.99,327.26c-0.82,0.7-1.24,1.29-1.79,1.49c-0.73,0.25-1.73-0.67-1.48-1.38c0.19-0.55,0.83-1.22,1.35-1.3
	C293.52,326,294.1,326.67,294.99,327.26z"
              />
              <path
                d="M352.88,182.93c0.6,0.93,1.18,1.44,1.24,2c0.08,0.75-0.51,1.33-1.35,1.29c-0.82-0.04-1.4-0.59-1.23-1.39
	C351.65,184.28,352.23,183.81,352.88,182.93z"
              />
              <path
                d="M441.85,254.98c-0.65-0.97-1.23-1.47-1.29-2.03c-0.09-0.79,0.58-1.41,1.37-1.23c0.47,0.11,1.13,0.8,1.13,1.23
	C443.05,253.48,442.46,254.01,441.85,254.98z"
              />
              <path
                d="M314.61,576.42c0.64,0.87,1.21,1.33,1.32,1.89c0.17,0.79-0.41,1.33-1.22,1.37c-0.83,0.04-1.41-0.53-1.33-1.28
	C313.44,577.83,314.01,577.33,314.61,576.42z"
              />
              <path
                d="M332.28,575.02c-0.56-1.01-1.12-1.56-1.13-2.12c-0.01-0.77,0.67-1.38,1.47-1.11c0.47,0.16,1.06,0.89,1.03,1.31
	C333.61,573.63,332.97,574.11,332.28,575.02z"
              />
              <path
                d="M368.53,282.73c0.69,0.87,1.32,1.33,1.43,1.89c0.16,0.77-0.41,1.36-1.24,1.41c-0.84,0.05-1.46-0.46-1.36-1.27
	C367.43,284.19,367.97,283.67,368.53,282.73z"
              />
              <path
                d="M469.91,407.48c0.62,0.97,1.2,1.48,1.25,2.04c0.06,0.78-0.59,1.41-1.39,1.21c-0.47-0.12-1.11-0.82-1.1-1.25
	C468.68,408.95,469.28,408.43,469.91,407.48z"
              />
              <path
                d="M592.03,630.06c-0.88,0.7-1.33,1.31-1.88,1.44c-0.82,0.18-1.37-0.47-1.42-1.26c-0.05-0.81,0.49-1.47,1.29-1.39
	C590.59,628.89,591.1,629.46,592.03,630.06z"
              />
              <path
                d="M420.86,260.19c0.67,0.87,1.29,1.33,1.39,1.89c0.15,0.76-0.43,1.35-1.25,1.39c-0.82,0.04-1.44-0.47-1.34-1.28
	C419.75,261.62,420.29,261.12,420.86,260.19z"
              />
              <path
                d="M503.58,632.61c-0.67-0.89-1.25-1.35-1.38-1.91c-0.19-0.81,0.41-1.37,1.22-1.42c0.84-0.05,1.45,0.52,1.38,1.29
	C504.75,631.14,504.18,631.65,503.58,632.61z"
              />
              <path
                d="M335.27,627.34c-0.89,0.6-1.38,1.17-1.93,1.24c-0.8,0.11-1.3-0.54-1.28-1.33c0.02-0.79,0.56-1.42,1.34-1.27
	C333.95,626.08,334.4,626.68,335.27,627.34z"
              />
              <path
                d="M354.05,367.27c-0.65-0.96-1.24-1.46-1.31-2.02c-0.09-0.79,0.57-1.42,1.36-1.24c0.47,0.1,1.14,0.79,1.14,1.21
	C355.25,365.75,354.66,366.29,354.05,367.27z"
              />
              <path
                d="M339.51,574.55c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.41,0.62,1.22,1.42
	C340.77,573.21,340.19,573.66,339.51,574.55z"
              />
              <path
                d="M489.34,404.24c-0.83,0.73-1.24,1.32-1.79,1.52c-0.73,0.26-1.6-0.57-1.56-1.36c0.04-0.83,0.56-1.41,1.35-1.3
	C487.91,403.17,488.42,403.69,489.34,404.24z"
              />
              <path
                d="M244.71,188.92c-0.68-0.8-1.27-1.22-1.46-1.77c-0.26-0.73,0.57-1.51,1.4-1.46c0.81,0.05,1.43,0.5,1.27,1.33
	C245.81,187.59,245.28,188.07,244.71,188.92z"
              />
              <path
                d="M358.07,180.98c-0.84,0.65-1.31,1.26-1.87,1.37c-0.79,0.16-1.33-0.43-1.36-1.27c-0.03-0.85,0.49-1.47,1.28-1.37
	C356.69,179.78,357.19,180.38,358.07,180.98z"
              />
              <path
                d="M334.1,612.79c-0.8,0.67-1.24,1.28-1.8,1.44c-0.78,0.22-1.34-0.33-1.48-1.15c-0.14-0.83,0.56-1.73,1.33-1.55
	C332.75,611.66,333.24,612.21,334.1,612.79z"
              />
              <path
                d="M499.65,605.62c-0.85,0.66-1.31,1.27-1.88,1.4c-0.8,0.17-1.34-0.42-1.38-1.27c-0.04-0.85,0.48-1.48,1.27-1.39
	C498.24,604.43,498.75,605.02,499.65,605.62z"
              />
              <path
                d="M459.78,605.77c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C458.5,604.49,458.95,605.12,459.78,605.77z"
              />
              <path
                d="M447.96,406.12c-0.69-0.83-1.29-1.26-1.45-1.82c-0.22-0.78,0.3-1.36,1.11-1.53c0.76-0.16,1.73,0.59,1.58,1.34
	C449.07,404.7,448.55,405.2,447.96,406.12z"
              />
              <path
                d="M407.73,628.17c-0.83,0.67-1.33,1.4-1.79,1.37c-0.52-0.03-1.2-0.64-1.46-1.16c-0.31-0.64,0.63-1.67,1.35-1.49
	C406.4,627.03,406.87,627.57,407.73,628.17z"
              />
              <path
                d="M308.07,584.08c0.69,0.86,1.32,1.32,1.44,1.87c0.16,0.76-0.4,1.37-1.23,1.42c-0.84,0.05-1.46-0.45-1.37-1.26
	C306.97,585.53,307.51,585.02,308.07,584.08z"
              />
              <path
                d="M345.66,369.69c-0.8,0.7-1.22,1.33-1.78,1.49c-0.75,0.22-1.37-0.23-1.53-1.08c-0.16-0.86,0.46-1.71,1.29-1.57
	C344.22,368.64,344.74,369.14,345.66,369.69z"
              />
              <path
                d="M445.38,187.83c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C444.11,186.56,444.56,187.18,445.38,187.83z"
              />
              <path
                d="M309.28,602.35c-0.58-0.94-1.13-1.45-1.18-2.01c-0.07-0.76,0.54-1.32,1.36-1.26c0.79,0.06,1.38,0.61,1.2,1.4
	C310.53,601.03,309.95,601.47,309.28,602.35z"
              />
              <path
                d="M482.03,594.7c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.41,0.62,1.22,1.42
	C483.29,593.36,482.7,593.82,482.03,594.7z"
              />
              <path
                d="M331.68,603.74c0.66,0.87,1.21,1.33,1.38,1.9c0.22,0.72-0.69,1.53-1.47,1.43c-0.81-0.11-1.37-0.63-1.2-1.43
	C330.52,605.08,331.07,604.61,331.68,603.74z"
              />
              <path
                d="M312.74,575.11c-0.96,0.57-1.49,1.13-2.04,1.16c-0.81,0.04-1.33-0.7-1.11-1.45c0.14-0.47,0.85-1.11,1.26-1.08
	C311.37,573.77,311.85,574.41,312.74,575.11z"
              />
              <path
                d="M361.54,287.12c-0.67-0.89-1.25-1.35-1.38-1.91c-0.19-0.81,0.41-1.37,1.22-1.42c0.84-0.05,1.45,0.52,1.38,1.29
	C362.72,285.65,362.14,286.17,361.54,287.12z"
              />
              <path
                d="M341.18,592.05c0.71,0.81,1.3,1.22,1.51,1.78c0.26,0.71-0.61,1.55-1.42,1.49c-0.81-0.06-1.44-0.52-1.29-1.35
	C340.08,593.4,340.61,592.91,341.18,592.05z"
              />
              <path
                d="M430.43,327.83c0.69,0.87,1.32,1.33,1.43,1.89c0.16,0.77-0.41,1.36-1.24,1.41c-0.84,0.05-1.46-0.46-1.36-1.27
	C429.33,329.29,429.87,328.77,430.43,327.83z"
              />
              <path
                d="M511.7,369.98c-0.92,0.58-1.43,1.11-2.01,1.22c-0.8,0.16-1.45-0.71-1.32-1.55c0.14-0.86,0.74-1.31,1.51-1.1
	C510.43,368.71,510.87,369.3,511.7,369.98z"
              />
              <path
                d="M378,370.73c-0.98,0.55-1.52,1.11-2.07,1.12c-0.8,0.02-1.34-0.71-1.09-1.48c0.15-0.47,0.88-1.1,1.29-1.06
	C376.65,369.36,377.12,370.02,378,370.73z"
              />
              <path
                d="M330.32,629.2c0.6,0.92,1.17,1.42,1.22,1.98c0.08,0.75-0.5,1.32-1.34,1.28c-0.81-0.04-1.39-0.58-1.22-1.37
	C329.11,630.53,329.68,630.07,330.32,629.2z"
              />
              <path
                d="M469.67,593.81c-0.85,0.7-1.27,1.28-1.82,1.46c-0.74,0.24-1.58-0.6-1.52-1.37c0.07-0.81,0.57-1.39,1.37-1.26
	C468.26,592.73,468.76,593.25,469.67,593.81z"
              />
              <path
                d="M475.58,602.35c-0.59-0.92-1.12-1.42-1.2-1.98c-0.12-0.8,0.5-1.31,1.3-1.29c0.81,0.02,1.39,0.6,1.25,1.36
	C476.84,600.99,476.25,601.46,475.58,602.35z"
              />
              <path
                d="M500.34,612.98c-0.9,0.59-1.4,1.14-1.95,1.21c-0.8,0.1-1.29-0.56-1.26-1.35c0.03-0.79,0.58-1.41,1.36-1.25
	C499.03,611.69,499.48,612.3,500.34,612.98z"
              />
              <path
                d="M335.63,571.16c-0.66-0.87-1.23-1.32-1.36-1.87c-0.18-0.8,0.42-1.34,1.2-1.39c0.82-0.05,1.42,0.51,1.35,1.27
	C336.77,569.71,336.22,570.22,335.63,571.16z"
              />
              <path
                d="M316.98,482.4c-0.6-0.94-1.17-1.46-1.22-2.02c-0.07-0.76,0.53-1.33,1.36-1.28c0.8,0.05,1.4,0.6,1.22,1.4
	C318.22,481.06,317.65,481.52,316.98,482.4z"
              />
              <path
                d="M463.99,588.69c0.58,0.86,1.12,1.34,1.23,1.9c0.16,0.83-0.47,1.29-1.27,1.37c-0.8,0.07-1.69-0.77-1.44-1.48
	C462.7,589.92,463.29,589.5,463.99,588.69z"
              />
              <path
                d="M257.26,435.44c-0.87,0.57-1.37,1.15-1.93,1.21c-0.77,0.08-1.28-0.53-1.23-1.36c0.04-0.82,0.6-1.4,1.36-1.24
	C256.01,434.16,256.45,434.79,257.26,435.44z"
              />
              <path
                d="M316.22,590.82c0.56,0.92,1.09,1.43,1.15,2c0.09,0.79-0.53,1.3-1.34,1.25c-0.82-0.05-1.37-0.65-1.21-1.4
	C314.93,592.12,315.55,591.67,316.22,590.82z"
              />
              <path
                d="M305.1,362.58c-0.88,0.69-1.32,1.26-1.88,1.42c-0.75,0.22-1.59-0.65-1.5-1.43c0.1-0.81,0.63-1.39,1.42-1.24
	C303.71,361.45,304.2,361.98,305.1,362.58z"
              />
              <path
                d="M351.79,401.86c-0.88,0.57-1.38,1.14-1.94,1.2c-0.77,0.08-1.27-0.54-1.22-1.37c0.05-0.83,0.62-1.38,1.37-1.22
	C350.54,400.58,350.98,401.2,351.79,401.86z"
              />
              <path
                d="M498.17,631.25c-0.94,0.56-1.48,1.14-2.04,1.16c-0.78,0.02-1.36-0.69-1.09-1.48c0.16-0.47,0.88-1.12,1.27-1.07
	C496.84,629.92,497.31,630.57,498.17,631.25z"
              />
              <path
                d="M480.19,589.49c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C478.95,588.21,479.37,588.79,480.19,589.49z"
              />
              <path
                d="M363,301.54c-0.68-0.9-1.27-1.35-1.4-1.91c-0.19-0.81,0.4-1.38,1.22-1.44c0.85-0.06,1.46,0.52,1.4,1.29
	C364.18,300.04,363.6,300.57,363,301.54z"
              />
              <path
                d="M359.03,401.24c-0.85,0.64-1.32,1.25-1.88,1.36c-0.8,0.15-1.33-0.44-1.35-1.28c-0.02-0.85,0.5-1.46,1.29-1.36
	C357.66,400.04,358.16,400.63,359.03,401.24z"
              />
              <path
                d="M492.71,400.65c-0.91,0.61-1.4,1.14-1.96,1.26c-0.78,0.17-1.54-0.74-1.39-1.51c0.15-0.81,0.74-1.34,1.5-1.15
	C491.41,399.38,491.85,399.97,492.71,400.65z"
              />
              <path
                d="M344.63,568.85c-0.9,0.57-1.42,1.15-1.98,1.21c-0.8,0.08-1.29-0.56-1.24-1.4c0.05-0.84,0.61-1.42,1.4-1.24
	C343.37,567.55,343.81,568.18,344.63,568.85z"
              />
              <path
                d="M265.46,362.36c-0.84,0.65-1.31,1.26-1.87,1.37c-0.79,0.16-1.33-0.43-1.36-1.27c-0.03-0.85,0.49-1.47,1.28-1.37
	C264.08,361.16,264.58,361.76,265.46,362.36z"
              />
              <path
                d="M500.85,620.16c-0.92,0.61-1.41,1.17-1.97,1.25c-0.81,0.11-1.33-0.56-1.3-1.36c0.03-0.8,0.58-1.44,1.38-1.29
	C499.51,618.87,499.97,619.48,500.85,620.16z"
              />
              <path
                d="M460.66,592.54c0.7,0.8,1.29,1.21,1.49,1.76c0.25,0.71-0.61,1.53-1.41,1.48c-0.81-0.06-1.43-0.51-1.28-1.33
	C459.57,593.89,460.1,593.4,460.66,592.54z"
              />
              <path
                d="M291,181.25c-0.65-0.8-1.24-1.23-1.39-1.78c-0.22-0.8,0.33-1.33,1.14-1.45c0.81-0.12,1.72,0.59,1.54,1.33
	C292.14,179.92,291.6,180.39,291,181.25z"
              />
              <path
                d="M320.45,621.45c-0.93,0.57-1.43,1.08-2,1.18c-0.79,0.14-1.51-0.77-1.34-1.55c0.17-0.82,0.79-1.31,1.54-1.11
	C319.19,620.12,319.61,620.73,320.45,621.45z"
              />
              <path
                d="M490.57,584.94c-0.92,0.58-1.41,1.09-1.99,1.21c-0.81,0.16-1.45-0.69-1.31-1.53c0.14-0.85,0.74-1.3,1.5-1.09
	C489.31,583.69,489.74,584.27,490.57,584.94z"
              />
              <path
                d="M506.94,628.47c-0.66-0.8-1.25-1.23-1.41-1.78c-0.23-0.8,0.32-1.34,1.14-1.47c0.82-0.13,1.74,0.59,1.56,1.33
	C508.09,627.13,507.54,627.61,506.94,628.47z"
              />
              <path
                d="M362.04,373.94c-0.67-0.87-1.25-1.31-1.38-1.86c-0.19-0.79,0.41-1.34,1.19-1.4c0.82-0.06,1.43,0.5,1.36,1.26
	C363.17,372.49,362.61,373,362.04,373.94z"
              />
              <path
                d="M334.81,619.92c-0.8,0.72-1.2,1.32-1.76,1.53c-0.72,0.27-1.74-0.62-1.51-1.34c0.18-0.55,0.8-1.24,1.32-1.33
	C333.32,618.69,333.91,619.35,334.81,619.92z"
              />
              <path
                d="M359.27,361.33c-0.85,0.69-1.29,1.31-1.83,1.44c-0.8,0.18-1.34-0.46-1.4-1.23c-0.06-0.8,0.46-1.46,1.25-1.39
	C357.84,360.19,358.35,360.75,359.27,361.33z"
              />
              <path
                d="M597.39,631.63c-0.69-0.85-1.26-1.29-1.46-1.86c-0.24-0.69,0.66-1.56,1.42-1.48c0.81,0.08,1.39,0.59,1.24,1.39
	C598.5,630.25,597.97,630.74,597.39,631.63z"
              />
              <path
                d="M307.82,374.92c-0.68-0.88-1.26-1.32-1.4-1.88c-0.19-0.8,0.4-1.36,1.2-1.42c0.83-0.07,1.44,0.5,1.38,1.26
	C308.97,373.44,308.41,373.97,307.82,374.92z"
              />
              <path
                d="M486.7,581.75c-0.85,0.61-1.33,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.4,1.44-1.2
	C485.42,580.49,485.87,581.1,486.7,581.75z"
              />
              <path
                d="M434.28,331.2c0.7,0.84,1.27,1.27,1.47,1.84c0.25,0.7-0.63,1.55-1.41,1.49c-0.83-0.06-1.4-0.57-1.26-1.37
	C433.18,332.59,433.71,332.09,434.28,331.2z"
              />
              <path
                d="M427.12,628.28c-0.59-0.93-1.13-1.43-1.21-2c-0.12-0.8,0.5-1.32,1.32-1.3c0.82,0.02,1.4,0.61,1.27,1.37
	C428.39,626.91,427.79,627.38,427.12,628.28z"
              />
              <path
                d="M521.3,251.38c0.62,0.85,1.17,1.31,1.31,1.87c0.19,0.74-0.73,1.49-1.52,1.37c-0.8-0.13-1.38-0.65-1.16-1.45
	C520.07,252.62,520.65,252.19,521.3,251.38z"
              />
              <path
                d="M325.19,425.82c-0.9,0.6-1.38,1.16-1.93,1.23c-0.8,0.1-1.3-0.55-1.27-1.34c0.02-0.79,0.56-1.42,1.35-1.27
	C323.88,424.55,324.33,425.15,325.19,425.82z"
              />
              <path
                d="M448.28,409.39c0.66,0.88,1.22,1.34,1.39,1.92c0.22,0.73-0.7,1.54-1.48,1.44c-0.82-0.11-1.38-0.64-1.2-1.44
	C447.11,410.74,447.67,410.27,448.28,409.39z"
              />
              <path
                d="M342.67,234.25c0.68,0.87,1.25,1.31,1.44,1.89c0.24,0.72-0.66,1.55-1.45,1.47c-0.83-0.08-1.39-0.61-1.24-1.41
	C341.53,235.63,342.08,235.14,342.67,234.25z"
              />
              <path
                d="M508.06,619.24c-0.85,0.71-1.27,1.29-1.83,1.48c-0.74,0.25-1.59-0.6-1.53-1.39c0.07-0.82,0.58-1.4,1.38-1.27
	C506.65,618.15,507.15,618.67,508.06,619.24z"
              />
              <path
                d="M480.99,224.54c-0.91,0.61-1.4,1.14-1.98,1.27c-0.8,0.18-1.47-0.67-1.36-1.52c0.12-0.86,0.71-1.33,1.48-1.13
	C479.69,223.3,480.14,223.88,480.99,224.54z"
              />
              <path
                d="M338.99,567.33c-0.67-0.88-1.24-1.34-1.37-1.89c-0.18-0.8,0.41-1.35,1.21-1.41c0.83-0.05,1.44,0.52,1.37,1.28
	C340.15,565.87,339.58,566.39,338.99,567.33z"
              />
              <path
                d="M356.27,309.38c-0.66-0.8-1.25-1.23-1.41-1.78c-0.23-0.8,0.32-1.34,1.14-1.47c0.82-0.13,1.74,0.59,1.56,1.33
	C357.42,308.03,356.87,308.51,356.27,309.38z"
              />
              <path
                d="M451.27,362.47c-0.68-0.9-1.26-1.36-1.39-1.92c-0.19-0.81,0.4-1.38,1.23-1.43c0.85-0.05,1.46,0.53,1.4,1.3
	C452.46,360.99,451.88,361.51,451.27,362.47z"
              />
              <path
                d="M260.21,178.73c-0.87,0.58-1.36,1.12-1.94,1.26c-0.79,0.18-1.48-0.7-1.34-1.54c0.14-0.81,0.69-1.38,1.47-1.16
	C258.97,177.44,259.41,178.06,260.21,178.73z"
              />
              <path
                d="M420.07,336.25c-0.65-0.81-1.24-1.24-1.4-1.79c-0.23-0.8,0.33-1.34,1.15-1.46c0.82-0.13,1.73,0.6,1.55,1.34
	C421.23,334.91,420.68,335.39,420.07,336.25z"
              />
              <path
                d="M332.11,425.38c-0.88,0.57-1.38,1.14-1.94,1.2c-0.77,0.08-1.27-0.54-1.22-1.37c0.05-0.83,0.62-1.38,1.37-1.22
	C330.87,424.09,331.31,424.72,332.11,425.38z"
              />
              <path
                d="M254.38,193.6c-0.87,0.57-1.38,1.15-1.93,1.21c-0.77,0.08-1.27-0.53-1.23-1.37c0.04-0.83,0.61-1.39,1.36-1.23
	C253.13,192.33,253.57,192.95,254.38,193.6z"
              />
              <path
                d="M233.22,222.31c0.67,0.85,1.28,1.3,1.4,1.85c0.16,0.75-0.39,1.35-1.22,1.39c-0.82,0.04-1.43-0.46-1.34-1.25
	C232.13,223.74,232.66,223.23,233.22,222.31z"
              />
              <path
                d="M260.88,371.51c0.64,0.89,1.21,1.36,1.32,1.92c0.16,0.8-0.42,1.34-1.24,1.37c-0.84,0.03-1.42-0.55-1.34-1.3
	C259.69,372.93,260.27,372.43,260.88,371.51z"
              />
              <path
                d="M491.26,591.89c-0.85,0.7-1.27,1.28-1.82,1.46c-0.74,0.24-1.58-0.6-1.52-1.37c0.07-0.81,0.57-1.39,1.37-1.26
	C489.85,590.81,490.35,591.33,491.26,591.89z"
              />
              <path
                d="M412.7,333.09c0.69,0.89,1.32,1.36,1.43,1.92c0.15,0.77-0.43,1.37-1.27,1.41c-0.85,0.04-1.46-0.47-1.36-1.29
	C411.58,334.55,412.11,334.04,412.7,333.09z"
              />
              <path
                d="M319.75,614.1c-0.89,0.63-1.36,1.17-1.93,1.32c-0.8,0.2-1.49-0.61-1.4-1.45c0.1-0.84,0.65-1.35,1.43-1.17
	C318.42,612.93,318.89,613.48,319.75,614.1z"
              />
              <path
                d="M447.09,398.91c-0.64-0.88-1.19-1.35-1.35-1.92c-0.2-0.72,0.73-1.55,1.49-1.42c0.79,0.13,1.37,0.66,1.18,1.45
	C448.28,397.58,447.72,398.04,447.09,398.91z"
              />
              <path
                d="M407.47,335.47c-0.82,0.65-1.28,1.27-1.83,1.38c-0.78,0.17-1.32-0.42-1.36-1.25c-0.04-0.84,0.48-1.46,1.25-1.37
	C406.09,334.29,406.6,334.88,407.47,335.47z"
              />
              <path
                d="M348.08,564.61c-0.87,0.66-1.32,1.23-1.89,1.4c-0.79,0.23-1.52-0.56-1.45-1.42c0.06-0.86,0.6-1.39,1.4-1.23
	C346.7,563.47,347.19,564.02,348.08,564.61z"
              />
              <path
                d="M245.54,259.15c-0.85,0.68-1.29,1.29-1.83,1.41c-0.79,0.17-1.33-0.46-1.38-1.24c-0.05-0.8,0.47-1.45,1.25-1.37
	C244.13,258,244.63,258.57,245.54,259.15z"
              />
              <path
                d="M494.26,394.53c0.56,0.91,1.09,1.41,1.18,1.99c0.13,0.8-0.45,1.31-1.29,1.36c-0.79,0.04-1.66-0.82-1.4-1.52
	C492.97,395.79,493.55,395.36,494.26,394.53z"
              />
              <path
                d="M265.42,322.68c-0.87,0.57-1.38,1.15-1.93,1.21c-0.77,0.08-1.27-0.53-1.23-1.37c0.04-0.83,0.61-1.39,1.36-1.23
	C264.17,321.4,264.61,322.03,265.42,322.68z"
              />
              <path
                d="M327.06,593.5c0.66,0.8,1.25,1.23,1.4,1.78c0.21,0.79-0.35,1.33-1.15,1.46c-0.79,0.13-1.71-0.61-1.53-1.35
	C325.91,594.82,326.45,594.36,327.06,593.5z"
              />
              <path
                d="M330.24,589.33c0.66,0.88,1.22,1.34,1.39,1.92c0.22,0.73-0.7,1.54-1.48,1.44c-0.82-0.11-1.38-0.64-1.2-1.44
	C329.07,590.68,329.62,590.21,330.24,589.33z"
              />
              <path
                d="M404.39,632.24c-0.98,0.55-1.52,1.11-2.07,1.12c-0.8,0.02-1.34-0.71-1.09-1.48c0.15-0.47,0.89-1.1,1.29-1.06
	C403.05,630.88,403.51,631.53,404.39,632.24z"
              />
              <path
                d="M322.32,476.55c-0.83,0.67-1.33,1.4-1.79,1.37c-0.52-0.03-1.2-0.64-1.46-1.16c-0.31-0.64,0.63-1.67,1.35-1.49
	C320.99,475.4,321.46,475.94,322.32,476.55z"
              />
              <path
                d="M447.12,249.06c-0.88,0.7-1.32,1.31-1.88,1.44c-0.82,0.18-1.37-0.47-1.42-1.26c-0.05-0.81,0.49-1.47,1.29-1.4
	C445.67,247.9,446.18,248.47,447.12,249.06z"
              />
              <path
                d="M252.74,424.74c-0.83,0.66-1.34,1.38-1.8,1.36c-0.52-0.03-1.2-0.64-1.45-1.17c-0.3-0.64,0.64-1.67,1.36-1.48
	C251.42,423.58,251.88,424.12,252.74,424.74z"
              />
              <path
                d="M460.35,193.64c-0.83,0.68-1.27,1.26-1.84,1.45c-0.79,0.26-1.53-0.51-1.5-1.37c0.03-0.87,0.56-1.41,1.35-1.28
	C458.94,192.53,459.44,193.07,460.35,193.64z"
              />
              <path
                d="M309.63,578.88c-0.92,0.6-1.44,1.19-2,1.24c-0.81,0.07-1.38-0.63-1.15-1.43c0.14-0.48,0.83-1.16,1.22-1.13
	C308.24,577.6,308.74,578.23,309.63,578.88z"
              />
              <path
                d="M364.74,279.37c0.68,0.86,1.24,1.3,1.43,1.87c0.23,0.71-0.66,1.54-1.44,1.46c-0.82-0.08-1.39-0.6-1.23-1.4
	C363.62,280.74,364.15,280.26,364.74,279.37z"
              />
              <path
                d="M313.42,582.28c-0.88,0.57-1.39,1.15-1.96,1.21c-0.78,0.08-1.28-0.55-1.23-1.38c0.05-0.83,0.61-1.41,1.38-1.24
	C312.17,580.99,312.61,581.62,313.42,582.28z"
              />
              <path
                d="M259.95,424.14c-0.8,0.72-1.2,1.32-1.76,1.53c-0.72,0.27-1.74-0.62-1.51-1.34c0.18-0.55,0.8-1.24,1.32-1.33
	C258.46,422.92,259.05,423.58,259.95,424.14z"
              />
              <path
                d="M262.58,413.41c-0.87,0.56-1.36,1.09-1.94,1.21c-0.78,0.16-1.45-0.73-1.29-1.56c0.15-0.81,0.72-1.35,1.49-1.13
	C261.39,412.1,261.81,412.73,262.58,413.41z"
              />
              <path
                d="M477.14,221.12c-0.89,0.63-1.36,1.17-1.93,1.31c-0.81,0.2-1.49-0.61-1.39-1.46c0.1-0.84,0.66-1.35,1.44-1.16
	C475.82,219.94,476.28,220.49,477.14,221.12z"
              />
              <path
                d="M459.32,598.54c-0.84,0.6-1.31,1.15-1.88,1.3c-0.78,0.2-1.49-0.66-1.37-1.49c0.12-0.81,0.65-1.39,1.43-1.19
	C458.06,597.29,458.51,597.89,459.32,598.54z"
              />
              <path
                d="M277.88,319.51c0.69,0.81,1.27,1.23,1.46,1.79c0.24,0.71-0.64,1.53-1.44,1.45c-0.8-0.07-1.42-0.54-1.25-1.36
	C276.76,320.84,277.3,320.36,277.88,319.51z"
              />
              <path
                d="M364.76,93.81c-0.89,0.57-1.4,1.15-1.96,1.2c-0.78,0.08-1.28-0.55-1.23-1.39c0.05-0.84,0.62-1.4,1.38-1.23
	C363.51,92.52,363.95,93.15,364.76,93.81z"
              />
              <path
                d="M308.19,604.34c-0.9,0.59-1.42,1.17-1.99,1.23c-0.81,0.1-1.3-0.54-1.26-1.39c0.04-0.85,0.6-1.44,1.39-1.26
	C306.89,603.04,307.35,603.67,308.19,604.34z"
              />
              <path
                d="M326.27,629.71c-0.68-0.89-1.28-1.35-1.39-1.91c-0.15-0.77,0.45-1.35,1.26-1.38c0.81-0.03,1.44,0.48,1.33,1.29
	C327.38,628.27,326.85,628.78,326.27,629.71z"
              />
              <path
                d="M378.58,314.66c-0.69-0.8-1.28-1.23-1.48-1.79c-0.26-0.74,0.57-1.53,1.41-1.47c0.82,0.05,1.44,0.51,1.28,1.34
	C379.7,313.31,379.16,313.8,378.58,314.66z"
              />
              <path
                d="M429.12,151.34c-0.63-0.96-1.23-1.49-1.24-2.02c-0.01-0.43,0.64-1.13,1.11-1.25c0.79-0.19,1.46,0.43,1.39,1.22
	C430.32,149.85,429.74,150.36,429.12,151.34z"
              />
              <path
                d="M466.52,415.22c-0.62-0.87-1.17-1.34-1.29-1.9c-0.18-0.8,0.4-1.32,1.2-1.44c0.76-0.11,1.69,0.73,1.47,1.44
	C467.73,413.89,467.18,414.35,466.52,415.22z"
              />
              <path
                d="M443.33,395.75c-0.67-0.82-1.26-1.25-1.44-1.82c-0.25-0.75,0.59-1.52,1.44-1.45c0.82,0.07,1.43,0.54,1.26,1.37
	C444.47,394.42,443.92,394.9,443.33,395.75z"
              />
              <path
                d="M493.1,593.49c0.65,0.82,1.24,1.25,1.39,1.8c0.21,0.8-0.38,1.33-1.17,1.47c-0.79,0.13-1.72-0.64-1.53-1.38
	C491.93,594.81,492.48,594.35,493.1,593.49z"
              />
              <path
                d="M521.68,634.53c-0.61-0.89-1.16-1.36-1.28-1.93c-0.17-0.8,0.41-1.33,1.22-1.43c0.77-0.1,1.69,0.74,1.47,1.46
	C522.91,633.2,522.35,633.65,521.68,634.53z"
              />
              <path
                d="M373.89,280.78c-0.83,0.64-1.29,1.25-1.85,1.36c-0.78,0.16-1.32-0.43-1.35-1.27c-0.03-0.84,0.49-1.45,1.27-1.35
	C372.52,279.6,373.01,280.19,373.89,280.78z"
              />
              <path
                d="M326.01,393.38c-0.9,0.63-1.37,1.18-1.95,1.33c-0.8,0.2-1.5-0.63-1.4-1.48c0.1-0.85,0.66-1.36,1.45-1.18
	C324.67,392.19,325.14,392.75,326.01,393.38z"
              />
              <path d="M294.5,603.29c0.56-1.21,0.97-2.1,1.38-2.99c1.19,0.22,1.47,0.91,1.25,1.76C296.86,603.11,296.12,603.56,294.5,603.29z" />
              <path
                d="M367.46,82.54c-0.87,0.71-1.31,1.33-1.86,1.46c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.27-1.41
	C366,81.39,366.52,81.95,367.46,82.54z"
              />
              <path
                d="M237.54,391.65c0.56,0.94,1.1,1.46,1.16,2.04c0.09,0.81-0.53,1.31-1.37,1.26c-0.84-0.05-1.4-0.66-1.24-1.42
	C236.21,392.97,236.84,392.52,237.54,391.65z"
              />
              <path
                d="M303.53,491.05c-0.69-0.83-1.29-1.26-1.45-1.82c-0.22-0.78,0.3-1.36,1.11-1.53c0.76-0.16,1.73,0.59,1.58,1.34
	C304.64,489.63,304.12,490.13,303.53,491.05z"
              />
              <path
                d="M362.42,357.69c-0.86,0.59-1.34,1.14-1.92,1.28c-0.79,0.19-1.49-0.68-1.35-1.52c0.13-0.82,0.67-1.38,1.46-1.18
	C361.16,356.41,361.6,357.03,362.42,357.69z"
              />
              <path
                d="M365.93,297.65c-0.59-0.94-1.15-1.44-1.21-2c-0.08-0.76,0.53-1.32,1.35-1.27c0.79,0.05,1.39,0.59,1.21,1.39
	C367.16,296.32,366.59,296.77,365.93,297.65z"
              />
              <path
                d="M234.03,375.73c-0.85,0.69-1.29,1.31-1.83,1.43c-0.8,0.18-1.34-0.46-1.4-1.24c-0.06-0.8,0.46-1.46,1.25-1.39
	C232.61,374.59,233.12,375.15,234.03,375.73z"
              />
              <path
                d="M497.06,394.58c-0.54-0.92-1.05-1.44-1.12-2.01c-0.1-0.81,0.5-1.29,1.33-1.32c0.78-0.03,1.64,0.88,1.36,1.56
	C498.39,393.37,497.79,393.78,497.06,394.58z"
              />
              <path
                d="M506.45,245.6c0.61,0.86,1.16,1.34,1.3,1.91c0.18,0.74-0.76,1.51-1.55,1.37c-0.8-0.14-1.38-0.68-1.16-1.48
	C505.19,246.84,505.79,246.41,506.45,245.6z"
              />
              <path
                d="M275.56,271.35c-0.91,0.58-1.43,1.16-2,1.22c-0.81,0.09-1.3-0.56-1.25-1.41c0.05-0.85,0.61-1.43,1.4-1.25
	C274.27,270.04,274.73,270.68,275.56,271.35z"
              />
              <path
                d="M336.94,561.96c-0.83,0.65-1.28,1.26-1.84,1.38c-0.78,0.16-1.32-0.42-1.36-1.25c-0.04-0.84,0.48-1.45,1.26-1.37
	C335.56,560.78,336.06,561.37,336.94,561.96z"
              />
              <path
                d="M444.04,339.78c-0.93,0.57-1.44,1.09-2.03,1.19c-0.8,0.15-1.44-0.73-1.29-1.57c0.15-0.86,0.77-1.3,1.52-1.08
	C442.79,338.49,443.22,339.09,444.04,339.78z"
              />
              <path
                d="M430.46,624.47c-0.59-0.95-1.13-1.46-1.21-2.04c-0.11-0.82,0.51-1.35,1.34-1.31c0.83,0.03,1.43,0.63,1.28,1.4
	C431.78,623.09,431.16,623.56,430.46,624.47z"
              />
              <path
                d="M367.45,288.61c-0.92,0.6-1.42,1.16-1.98,1.23c-0.8,0.09-1.32-0.57-1.29-1.37c0.03-0.8,0.59-1.43,1.39-1.27
	C366.12,287.3,366.58,287.92,367.45,288.61z"
              />
              <path
                d="M449.49,610.11c-0.91,0.59-1.41,1.15-1.97,1.21c-0.8,0.09-1.31-0.57-1.27-1.36c0.04-0.79,0.59-1.42,1.38-1.26
	C448.18,608.81,448.62,609.42,449.49,610.11z"
              />
              <path
                d="M440.69,196.83c0.61,0.94,1.2,1.45,1.25,2.02c0.08,0.76-0.51,1.34-1.36,1.3c-0.83-0.04-1.41-0.59-1.24-1.4
	C439.46,198.19,440.03,197.72,440.69,196.83z"
              />
              <path
                d="M307.27,197.77c0.63,0.95,1.22,1.46,1.29,2.03c0.09,0.77-0.51,1.35-1.37,1.32c-0.85-0.03-1.43-0.58-1.26-1.39
	C306.05,199.15,306.63,198.67,307.27,197.77z"
              />
              <path
                d="M471.27,216.02c0.68,0.87,1.25,1.31,1.44,1.89c0.24,0.72-0.66,1.55-1.45,1.47c-0.83-0.08-1.39-0.61-1.24-1.41
	C470.13,217.4,470.67,216.91,471.27,216.02z"
              />
              <path
                d="M310.8,633.04c-1.04,0.59-1.55,1.05-2.12,1.17c-0.78,0.16-1.4-0.22-1.4-1.15c-0.01-0.83,0.47-1.49,1.25-1.39
	C309.16,631.75,309.72,632.36,310.8,633.04z"
              />
              <path
                d="M303.14,217.96c0.84-0.62,1.31-1.18,1.89-1.34c0.77-0.21,1.5,0.66,1.39,1.49c-0.1,0.82-0.63,1.4-1.43,1.21
	C304.43,219.19,303.97,218.6,303.14,217.96z"
              />
              <path
                d="M344.9,199.64c0.91-0.58,1.43-1.17,2-1.23c0.79-0.08,1.3,0.56,1.25,1.42c-0.05,0.85-0.6,1.43-1.41,1.24
	C346.18,200.94,345.73,200.31,344.9,199.64z"
              />
              <path
                d="M248.26,414.6c-0.92,0.59-1.43,1.11-2.01,1.22c-0.8,0.16-1.45-0.71-1.32-1.55c0.14-0.86,0.74-1.31,1.51-1.1
	C247,413.33,247.43,413.92,248.26,414.6z"
              />
              <path
                d="M467.95,200.33c-0.9,0.58-1.41,1.15-1.98,1.21c-0.8,0.08-1.29-0.55-1.24-1.4c0.05-0.85,0.62-1.41,1.39-1.24
	C466.69,199.03,467.14,199.67,467.95,200.33z"
              />
              <path
                d="M315.36,603.86c-0.89,0.58-1.4,1.16-1.97,1.23c-0.8,0.09-1.3-0.54-1.25-1.38c0.04-0.84,0.6-1.42,1.38-1.25
	C314.08,602.57,314.54,603.2,315.36,603.86z"
              />
              <path
                d="M288.02,414.64c-0.84,0.66-1.29,1.27-1.85,1.39c-0.79,0.17-1.34-0.42-1.37-1.26c-0.04-0.85,0.48-1.47,1.26-1.38
	C286.62,413.46,287.13,414.05,288.02,414.64z"
              />
              <path
                d="M307.64,391.29c-0.87,0.57-1.37,1.15-1.93,1.21c-0.77,0.08-1.28-0.53-1.23-1.36c0.04-0.82,0.6-1.4,1.36-1.24
	C306.39,390.02,306.83,390.64,307.64,391.29z"
              />
              <path
                d="M298.86,623.29c-0.91,0.61-1.4,1.14-1.96,1.26c-0.78,0.17-1.54-0.74-1.39-1.51c0.15-0.81,0.74-1.34,1.5-1.15
	C297.56,622.03,298,622.62,298.86,623.29z"
              />
              <path
                d="M249.21,342.09c-0.9,0.6-1.39,1.13-1.96,1.26c-0.81,0.18-1.47-0.65-1.35-1.5c0.12-0.85,0.7-1.32,1.47-1.12
	C247.92,340.88,248.37,341.45,249.21,342.09z"
              />
              <path
                d="M451.15,378.79c-0.92,0.6-1.44,1.19-1.99,1.23c-0.79,0.05-1.38-0.64-1.15-1.43c0.14-0.48,0.83-1.15,1.23-1.12
	C449.77,377.51,450.27,378.14,451.15,378.79z"
              />
              <path
                d="M442.32,610.88c-0.93,0.57-1.42,1.07-1.99,1.17c-0.8,0.14-1.5-0.75-1.34-1.53c0.17-0.81,0.77-1.3,1.52-1.1
	C441.06,609.58,441.48,610.18,442.32,610.88z"
              />
              <path
                d="M336.72,621.5c0.63,0.86,1.19,1.32,1.33,1.88c0.19,0.79-0.37,1.33-1.17,1.45c-0.77,0.12-1.7-0.68-1.5-1.41
	C335.54,622.85,336.08,622.38,336.72,621.5z"
              />
              <path
                d="M452.28,416.68c-0.6-0.96-1.17-1.48-1.21-2.05c-0.06-0.77,0.55-1.34,1.39-1.28c0.81,0.06,1.4,0.62,1.22,1.42
	C453.54,415.34,452.95,415.79,452.28,416.68z"
              />
              <path
                d="M448.31,389.69c-0.85,0.66-1.31,1.27-1.88,1.4c-0.8,0.17-1.34-0.42-1.38-1.27c-0.03-0.85,0.48-1.48,1.27-1.39
	C446.9,388.5,447.41,389.09,448.31,389.69z"
              />
              <path
                d="M278.85,417.14c-0.59-0.89-1.13-1.37-1.24-1.93c-0.16-0.8,0.42-1.31,1.23-1.41c0.76-0.09,1.67,0.77,1.44,1.47
	C280.09,415.84,279.53,416.28,278.85,417.14z"
              />
              <path
                d="M479.91,629.31c-0.88,0.56-1.39,1.14-1.94,1.19c-0.77,0.07-1.27-0.55-1.22-1.38c0.05-0.83,0.62-1.38,1.38-1.22
	C478.67,628.03,479.1,628.65,479.91,629.31z"
              />
              <path
                d="M360.85,276.03c0.69,0.86,1.31,1.3,1.43,1.86c0.16,0.76-0.39,1.36-1.22,1.41c-0.83,0.05-1.45-0.45-1.36-1.25
	C359.76,277.48,360.29,276.96,360.85,276.03z"
              />
              <path
                d="M346.62,590.17c-0.9,0.6-1.39,1.13-1.96,1.26c-0.8,0.18-1.47-0.65-1.35-1.5c0.12-0.85,0.7-1.32,1.47-1.12
	C345.33,588.95,345.77,589.52,346.62,590.17z"
              />
              <path
                d="M500.53,390.79c-0.6-0.95-1.14-1.46-1.22-2.03c-0.12-0.82,0.5-1.35,1.34-1.32c0.84,0.03,1.43,0.62,1.29,1.4
	C501.84,389.4,501.22,389.87,500.53,390.79z"
              />
              <path
                d="M334.83,580.14c-0.87,0.7-1.32,1.32-1.87,1.45c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.28-1.41
	C333.38,578.98,333.89,579.55,334.83,580.14z"
              />
              <path
                d="M391.46,109.33c-0.9,0.63-1.36,1.16-1.92,1.3c-0.76,0.19-1.55-0.7-1.42-1.46c0.13-0.8,0.69-1.35,1.47-1.17
	C390.13,108.13,390.58,108.69,391.46,109.33z"
              />
              <path
                d="M371.42,275.58c-0.71-0.83-1.29-1.25-1.5-1.81c-0.26-0.68,0.63-1.57,1.39-1.52c0.82,0.06,1.4,0.56,1.28,1.36
	C372.5,274.18,371.98,274.68,371.42,275.58z"
              />
              <path
                d="M552.75,633.99c0.35-0.78,0.5-1.44,0.9-1.87c0.22-0.24,0.96-0.26,1.28-0.06c0.4,0.25,0.82,0.82,0.81,1.24
	c-0.01,0.33-0.63,0.79-1.06,0.9C554.21,634.32,553.65,634.11,552.75,633.99z"
              />
              <path
                d="M383.3,98.96c0.83-0.62,1.3-1.17,1.87-1.33c0.75-0.21,1.49,0.66,1.38,1.49c-0.11,0.82-0.63,1.39-1.42,1.2
	C384.57,100.18,384.11,99.59,383.3,98.96z"
              />
              <path
                d="M274.64,343.41c-0.86,0.65-1.31,1.21-1.88,1.38c-0.8,0.23-1.51-0.55-1.44-1.4c0.06-0.85,0.6-1.37,1.39-1.22
	C273.28,342.28,273.76,342.82,274.64,343.41z"
              />
              <path
                d="M469.4,214.52c-0.78,0.66-1.21,1.28-1.77,1.44c-0.77,0.22-1.33-0.32-1.47-1.14c-0.14-0.83,0.54-1.72,1.31-1.55
	C468.05,213.4,468.54,213.94,469.4,214.52z"
              />
              <path
                d="M452.39,353.39c-0.97,0.59-1.49,1.15-2.05,1.19c-0.81,0.05-1.36-0.68-1.15-1.44c0.13-0.48,0.85-1.13,1.26-1.1
	C450.98,352.07,451.47,352.71,452.39,353.39z"
              />
              <path
                d="M446.61,202.22c-0.91,0.6-1.4,1.16-1.96,1.23c-0.8,0.1-1.31-0.56-1.28-1.35c0.03-0.79,0.58-1.43,1.37-1.27
	C445.29,200.94,445.74,201.55,446.61,202.22z"
              />
              <path
                d="M370.94,351.17c0.71,0.81,1.3,1.23,1.5,1.78c0.25,0.71-0.62,1.55-1.43,1.49c-0.81-0.06-1.44-0.52-1.28-1.36
	C369.83,352.52,370.37,352.03,370.94,351.17z"
              />
              <path
                d="M474.52,215.81c-0.68-0.82-1.26-1.25-1.45-1.81c-0.25-0.75,0.59-1.52,1.44-1.45c0.82,0.06,1.43,0.54,1.26,1.37
	C475.66,214.48,475.11,214.96,474.52,215.81z"
              />
              <path
                d="M503.14,625.39c-0.69-0.83-1.28-1.25-1.44-1.8c-0.22-0.77,0.31-1.34,1.1-1.52c0.75-0.17,1.72,0.59,1.57,1.33
	C504.24,623.98,503.73,624.48,503.14,625.39z"
              />
              <path
                d="M353.07,416.03c-0.87,0.71-1.31,1.33-1.87,1.45c-0.82,0.19-1.37-0.46-1.43-1.25c-0.06-0.81,0.47-1.48,1.28-1.41
	C351.61,414.88,352.13,415.44,353.07,416.03z"
              />
              <path
                d="M342.74,587.02c-0.85,0.61-1.32,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.39,1.44-1.2
	C341.47,585.77,341.92,586.37,342.74,587.02z"
              />
              <path
                d="M312.86,594.62c0.57,0.94,1.1,1.46,1.17,2.03c0.1,0.81-0.52,1.32-1.36,1.27c-0.83-0.05-1.4-0.65-1.25-1.41
	C311.54,595.95,312.17,595.49,312.86,594.62z"
              />
              <path
                d="M321.52,70.17c0.56,0.9,1.08,1.4,1.17,1.97c0.13,0.8-0.45,1.3-1.28,1.35c-0.78,0.05-1.65-0.82-1.39-1.51
	C320.23,71.42,320.81,71,321.52,70.17z"
              />
              <path
                d="M316.82,538.62c-0.91,0.58-1.43,1.16-2,1.22c-0.81,0.09-1.3-0.56-1.25-1.41c0.05-0.85,0.61-1.43,1.4-1.25
	C315.54,537.32,316,537.95,316.82,538.62z"
              />
              <path
                d="M656.68,503.5c0.02-1.08-1.03-2.27,0.33-3.21c1.15,1.24,1.15,1.24,1.4,3.06c-0.61,0.03-1.2,0.06-1.79,0.08
	C656.61,503.44,656.68,503.5,656.68,503.5z"
              />
              <path
                d="M480.89,596.65c-0.88,0.58-1.38,1.16-1.94,1.23c-0.79,0.09-1.29-0.53-1.25-1.37c0.04-0.83,0.59-1.41,1.36-1.25
	C479.61,595.38,480.07,596,480.89,596.65z"
              />
              <path
                d="M442.45,388.55c-0.57-0.86-1.12-1.34-1.23-1.91c-0.16-0.83,0.46-1.29,1.28-1.35c0.84-0.06,1.68,0.72,1.42,1.46
	C443.73,387.32,443.14,387.75,442.45,388.55z"
              />
              <path
                d="M260.66,431.59c-0.89,0.59-1.4,1.17-1.96,1.24c-0.8,0.1-1.3-0.53-1.26-1.38c0.04-0.84,0.59-1.43,1.37-1.26
	C259.38,430.31,259.83,430.93,260.66,431.59z"
              />
              <path
                d="M329.5,621.96c0.65,0.9,1.22,1.37,1.33,1.93c0.17,0.81-0.42,1.35-1.25,1.38c-0.85,0.03-1.44-0.55-1.35-1.31
	C328.29,623.41,328.87,622.9,329.5,621.96z"
              />
              <path
                d="M336.96,585.58c-0.62-0.88-1.17-1.35-1.3-1.92c-0.18-0.8,0.4-1.33,1.21-1.44c0.77-0.11,1.7,0.73,1.48,1.45
	C338.18,584.24,337.63,584.7,336.96,585.58z"
              />
              <path
                d="M244.2,348.09c-0.69-0.92-1.31-1.4-1.4-1.96c-0.13-0.78,0.47-1.36,1.29-1.39c0.84-0.03,1.45,0.5,1.33,1.32
	C245.34,346.63,244.8,347.13,244.2,348.09z"
              />
              <path
                d="M448.61,207.48c-0.68-0.91-1.29-1.38-1.39-1.94c-0.13-0.77,0.45-1.36,1.28-1.38c0.83-0.02,1.44,0.5,1.32,1.31
	C449.75,206.03,449.21,206.53,448.61,207.48z"
              />
              <path
                d="M450.08,348.03c-0.67-0.87-1.28-1.33-1.39-1.88c-0.15-0.76,0.42-1.35,1.23-1.38c0.81-0.04,1.43,0.47,1.33,1.27
	C451.18,346.6,450.65,347.1,450.08,348.03z"
              />
              <path
                d="M321.39,549.18c-0.97,0.58-1.5,1.14-2.05,1.17c-0.81,0.04-1.35-0.69-1.14-1.45c0.13-0.47,0.85-1.12,1.27-1.09
	C320,547.85,320.48,548.49,321.39,549.18z"
              />
              <path
                d="M443.68,605.02c0.65,0.81,1.24,1.24,1.38,1.79c0.21,0.8-0.37,1.32-1.17,1.45c-0.79,0.13-1.71-0.63-1.52-1.37
	C442.51,606.33,443.06,605.87,443.68,605.02z"
              />
              <path
                d="M253.45,432.07c-0.93,0.58-1.45,1.17-2.01,1.2c-0.79,0.04-1.37-0.66-1.13-1.45c0.15-0.48,0.85-1.14,1.25-1.1
	C252.1,430.78,252.58,431.42,253.45,432.07z"
              />
              <path
                d="M494.33,627.7c-0.83,0.64-1.29,1.26-1.84,1.37c-0.78,0.16-1.32-0.43-1.35-1.26c-0.03-0.84,0.49-1.45,1.26-1.36
	C492.96,626.52,493.45,627.11,494.33,627.7z"
              />
              <path
                d="M365.99,274.23c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C364.75,272.96,365.17,273.54,365.99,274.23z"
              />
              <path
                d="M343.56,245.34c-0.67-0.87-1.28-1.32-1.39-1.88c-0.15-0.76,0.42-1.35,1.23-1.38c0.81-0.04,1.43,0.47,1.33,1.27
	C344.65,243.91,344.13,244.41,343.56,245.34z"
              />
              <path
                d="M444.64,350.14c0.89-0.6,1.37-1.15,1.94-1.27c0.79-0.16,1.33,0.37,1.41,1.22c0.07,0.85-0.65,1.66-1.44,1.43
	C445.97,351.36,445.51,350.8,444.64,350.14z"
              />
              <path
                d="M329.06,614.78c0.62,0.86,1.17,1.33,1.3,1.89c0.18,0.79-0.38,1.32-1.19,1.44c-0.78,0.11-1.69-0.7-1.48-1.42
	C327.87,616.11,328.42,615.65,329.06,614.78z"
              />
              <path
                d="M453.87,604.26c-0.53-0.91-1.04-1.43-1.12-2c-0.1-0.8,0.5-1.28,1.32-1.32c0.77-0.03,1.63,0.88,1.35,1.55
	C455.19,603.05,454.6,603.46,453.87,604.26z"
              />
              <path
                d="M472.51,210.9c-0.87,0.58-1.36,1.13-1.92,1.23c-0.8,0.13-1.28-0.51-1.37-1.29c-0.09-0.76,0.8-1.65,1.5-1.41
	C471.28,209.62,471.7,210.2,472.51,210.9z"
              />
              <path
                d="M505.89,385.01c-0.85,0.61-1.33,1.16-1.9,1.31c-0.79,0.2-1.49-0.66-1.38-1.5c0.11-0.82,0.65-1.39,1.44-1.2
	C504.61,383.75,505.07,384.36,505.89,385.01z"
              />
              <path
                d="M344.27,212.72c-0.66-0.87-1.23-1.32-1.36-1.87c-0.18-0.8,0.42-1.34,1.2-1.39c0.82-0.05,1.42,0.51,1.35,1.27
	C345.41,211.27,344.85,211.78,344.27,212.72z"
              />
              <path
                d="M495.12,618.7c-0.65-0.97-1.24-1.46-1.3-2.02c-0.09-0.79,0.57-1.42,1.36-1.24c0.47,0.11,1.13,0.79,1.14,1.22
	C496.32,617.19,495.73,617.72,495.12,618.7z"
              />
              <path
                d="M306.53,210.88c-0.93,0.6-1.44,1.17-2,1.23c-0.81,0.1-1.33-0.57-1.3-1.38c0.04-0.8,0.6-1.45,1.4-1.28
	C305.19,209.56,305.64,210.18,306.53,210.88z"
              />
              <path
                d="M364.08,292.42c-0.9,0.61-1.39,1.17-1.95,1.25c-0.8,0.11-1.31-0.55-1.29-1.34c0.02-0.79,0.56-1.43,1.36-1.28
	C362.75,291.14,363.2,291.75,364.08,292.42z"
              />
              <path
                d="M425.2,147.97c-0.59-0.99-1.14-1.51-1.18-2.07c-0.07-0.81,0.62-1.39,1.4-1.18c0.47,0.13,1.12,0.84,1.1,1.26
	C426.48,146.52,425.86,147.03,425.2,147.97z"
              />
              <path
                d="M305,73.27c0.89-0.6,1.37-1.15,1.94-1.27c0.79-0.16,1.33,0.37,1.41,1.22c0.07,0.85-0.65,1.66-1.44,1.43
	C306.34,74.49,305.88,73.93,305,73.27z"
              />
              <path
                d="M484.38,243.92c-0.67-0.88-1.24-1.34-1.37-1.89c-0.18-0.8,0.41-1.35,1.21-1.41c0.83-0.05,1.44,0.52,1.37,1.28
	C485.54,242.46,484.98,242.98,484.38,243.92z"
              />
              <path
                d="M447.8,382.66c-0.89,0.58-1.4,1.16-1.97,1.22c-0.8,0.09-1.3-0.54-1.25-1.39c0.04-0.84,0.6-1.42,1.38-1.25
	C446.52,381.37,446.97,381.99,447.8,382.66z"
              />
              <path
                d="M240.86,347.99c0.58,0.97,1.13,1.5,1.17,2.07c0.06,0.81-0.63,1.38-1.41,1.16c-0.47-0.14-1.11-0.85-1.09-1.27
	C239.56,349.41,240.19,348.91,240.86,347.99z"
              />
              <path
                d="M318.68,543.84c-0.6-0.89-1.14-1.38-1.25-1.95c-0.16-0.81,0.42-1.32,1.24-1.41c0.77-0.09,1.69,0.77,1.45,1.48
	C319.93,542.52,319.36,542.97,318.68,543.84z"
              />
              <path
                d="M464.9,207.55c0.85-0.68,1.32-1.32,1.86-1.38c0.4-0.04,1.11,0.6,1.27,1.07c0.26,0.79-0.32,1.5-1.1,1.47
	C466.37,208.7,465.83,208.11,464.9,207.55z"
              />
              <path d="M224.22,244.36c-0.07-0.94-0.15-1.9-0.23-2.97c1.37-0.04,1.98,0.55,2,1.52C226.01,243.78,225.52,244.42,224.22,244.36z" />
              <path
                d="M418.12,623.57c-0.83,0.68-1.27,1.26-1.84,1.45c-0.79,0.26-1.53-0.51-1.5-1.37c0.03-0.87,0.56-1.41,1.35-1.28
	C416.71,622.47,417.22,623,418.12,623.57z"
              />
              <path
                d="M473.74,596.98c-0.85,0.66-1.31,1.28-1.87,1.4c-0.8,0.17-1.35-0.42-1.38-1.27c-0.04-0.85,0.47-1.48,1.27-1.39
	C472.32,595.79,472.84,596.38,473.74,596.98z"
              />
              <path
                d="M329.71,420.01c-0.59-0.92-1.12-1.42-1.2-1.98c-0.12-0.8,0.5-1.31,1.3-1.29c0.81,0.02,1.39,0.6,1.25,1.36
	C330.97,418.66,330.37,419.12,329.71,420.01z"
              />
              <path
                d="M338.18,576.48c-0.91,0.61-1.41,1.18-1.97,1.26c-0.81,0.11-1.33-0.55-1.3-1.35c0.02-0.8,0.57-1.44,1.37-1.29
	C336.84,575.2,337.3,575.81,338.18,576.48z"
              />
              <path
                d="M495.91,625.89c-0.68-0.89-1.26-1.34-1.4-1.9c-0.19-0.81,0.4-1.37,1.21-1.43c0.84-0.06,1.45,0.51,1.39,1.28
	C497.08,624.41,496.51,624.93,495.91,625.89z"
              />
              <path
                d="M259.67,131.71c-0.93,0.57-1.45,1.15-2,1.18c-0.77,0.03-1.36-0.66-1.11-1.45c0.15-0.47,0.85-1.12,1.25-1.08
	C258.34,130.41,258.81,131.05,259.67,131.71z"
              />
              <path
                d="M456.1,415.93c0.69,0.86,1.29,1.3,1.42,1.85c0.2,0.82-0.4,1.36-1.23,1.41c-0.83,0.05-1.49-0.45-1.41-1.26
	C454.93,417.36,455.5,416.85,456.1,415.93z"
              />
              <path
                d="M327.65,604.5c-0.67-0.88-1.28-1.35-1.38-1.91c-0.14-0.8,0.47-1.33,1.32-1.33c0.84-0.01,1.48,0.45,1.33,1.3
	C328.82,603.12,328.26,603.6,327.65,604.5z"
              />
              <path
                d="M234.7,383.09c-0.9,0.6-1.4,1.18-1.94,1.22c-0.76,0.05-1.36-0.61-1.14-1.39c0.13-0.47,0.8-1.13,1.2-1.11
	C233.34,381.84,233.82,382.45,234.7,383.09z"
              />
              <path
                d="M309.55,206.84c-0.85,0.66-1.3,1.28-1.84,1.37c-0.77,0.13-1.4-0.5-1.24-1.29c0.1-0.49,0.71-1.21,1.1-1.22
	C308.09,205.69,308.63,206.27,309.55,206.84z"
              />
              <path
                d="M290.34,506.5c-0.7-0.89-1.34-1.35-1.41-1.89c-0.1-0.76,0.53-1.4,1.35-1.21c0.48,0.11,1.15,0.72,1.17,1.13
	C291.46,505.04,290.89,505.57,290.34,506.5z"
              />
              <path
                d="M449.76,383.99c0.61,0.95,1.2,1.48,1.2,2.01c0,0.41-0.67,1.08-1.14,1.19c-0.78,0.18-1.44-0.42-1.33-1.22
	C448.57,385.42,449.14,384.93,449.76,383.99z"
              />
              <path
                d="M463.35,209.34c0.65,0.92,1.23,1.39,1.32,1.95c0.13,0.8-0.51,1.41-1.3,1.26c-0.48-0.09-1.17-0.75-1.17-1.16
	C462.19,210.84,462.77,210.3,463.35,209.34z"
              />
              <path
                d="M268.79,318.84c-0.92,0.58-1.45,1.16-1.99,1.19c-0.77,0.04-1.36-0.66-1.12-1.44c0.15-0.47,0.84-1.12,1.24-1.09
	C267.44,317.54,267.92,318.18,268.79,318.84z"
              />
              <path
                d="M300.26,431.62c-0.96,0.57-1.48,1.13-2.03,1.16c-0.8,0.04-1.34-0.68-1.12-1.44c0.14-0.47,0.85-1.12,1.25-1.08
	C298.88,430.3,299.36,430.94,300.26,431.62z"
              />
              <path
                d="M380.48,315.99c0.84-0.67,1.3-1.31,1.82-1.36c0.39-0.04,1.1,0.59,1.25,1.05c0.26,0.78-0.33,1.46-1.08,1.45
	C381.94,317.12,381.41,316.55,380.48,315.99z"
              />
              <path
                d="M389.42,632.72c0.08,1.25-0.41,1.52-1.15,1.52c-0.72,0-1.54,0.07-1.6-0.94c-0.05-0.83,0.5-1.43,1.3-1.35
	C388.54,632.02,389.07,632.53,389.42,632.72z"
              />
              <path
                d="M263.31,420.56c-0.96,0.59-1.47,1.15-2.02,1.19c-0.8,0.05-1.35-0.67-1.14-1.42c0.13-0.47,0.83-1.13,1.24-1.1
	C261.92,419.26,262.41,419.89,263.31,420.56z"
              />
              <path
                d="M381.18,97.55c-0.64-0.93-1.21-1.41-1.3-1.96c-0.12-0.8,0.55-1.38,1.3-1.23c0.46,0.09,1.14,0.75,1.14,1.16
	C382.34,96.05,381.76,96.59,381.18,97.55z"
              />
              <path
                d="M353.09,582.23c-0.88,0.73-1.31,1.36-1.86,1.49c-0.78,0.19-1.4-0.35-1.47-1.19c-0.07-0.83,0.41-1.48,1.24-1.4
	C351.57,581.19,352.11,581.7,353.09,582.23z"
              />
              <path
                d="M316.92,312.51c0.68,0.88,1.31,1.35,1.36,1.88c0.04,0.41-0.55,1.14-1.01,1.3c-0.79,0.28-1.46-0.33-1.46-1.09
	C315.82,314.04,316.37,313.49,316.92,312.51z"
              />
              <path
                d="M267.25,201.42c-0.55-0.92-1.09-1.44-1.15-2.01c-0.09-0.85,0.58-1.27,1.42-1.21c0.85,0.06,1.42,0.62,1.23,1.41
	C268.61,200.16,267.96,200.59,267.25,201.42z"
              />
              <path
                d="M505.16,377.68c-0.91,0.68-1.39,1.31-1.91,1.34c-0.41,0.03-1.11-0.62-1.25-1.09c-0.22-0.77,0.34-1.48,1.13-1.43
	C503.68,376.53,504.2,377.09,505.16,377.68z"
              />
              <path
                d="M292.93,495.36c-0.67-0.93-1.25-1.4-1.34-1.95c-0.14-0.81,0.51-1.41,1.29-1.27c0.48,0.08,1.17,0.74,1.19,1.15
	C294.07,493.82,293.5,494.37,292.93,495.36z"
              />
              <path
                d="M352.64,76.79c-0.99,0.59-1.51,1.12-2.07,1.18c-0.83,0.1-1.34-0.55-1.3-1.37c0.04-0.82,0.63-1.4,1.44-1.22
	C351.26,75.51,351.72,76.09,352.64,76.79z"
              />
              <path
                d="M281.74,176.79c-0.92,0.59-1.43,1.18-1.98,1.21c-0.78,0.05-1.37-0.64-1.13-1.42c0.14-0.48,0.83-1.13,1.23-1.1
	C280.39,175.51,280.87,176.13,281.74,176.79z"
              />
              <path
                d="M329.71,586.26c-0.59-0.91-1.14-1.4-1.23-1.97c-0.13-0.85,0.52-1.29,1.36-1.27c0.85,0.02,1.45,0.56,1.29,1.36
	C331.02,584.94,330.39,585.4,329.71,586.26z"
              />
              <path
                d="M321.2,628.33c-0.85,0.7-1.3,1.31-1.86,1.46c-0.78,0.2-1.38-0.3-1.45-1.18c-0.07-0.87,0.41-1.48,1.23-1.41
	C319.69,627.25,320.23,627.78,321.2,628.33z"
              />
              <path
                d="M450.5,391.2c0.59,0.95,1.15,1.46,1.21,2.02c0.08,0.8-0.59,1.38-1.37,1.18c-0.47-0.12-1.12-0.82-1.1-1.23
	C449.26,392.63,449.87,392.12,450.5,391.2z"
              />
              <path
                d="M501.15,247.91c-0.9,0.66-1.37,1.25-1.94,1.37c-0.8,0.16-1.35-0.38-1.38-1.25c-0.03-0.88,0.49-1.45,1.3-1.33
	C499.7,246.77,500.21,247.32,501.15,247.91z"
              />
              <path
                d="M509.06,257.93c0.85-0.67,1.31-1.32,1.84-1.37c0.39-0.04,1.1,0.59,1.26,1.06c0.26,0.78-0.32,1.48-1.09,1.46
	C510.53,259.07,509.99,258.49,509.06,257.93z"
              />
              <path
                d="M360.7,296.17c-0.91,0.64-1.39,1.22-1.94,1.29c-0.8,0.1-1.36-0.58-1.19-1.35c0.11-0.47,0.76-1.15,1.16-1.15
	C359.26,294.97,359.78,295.56,360.7,296.17z"
              />
              <path
                d="M436.89,616.49c-0.59-0.93-1.17-1.45-1.22-2.01c-0.06-0.79,0.6-1.29,1.44-1.23c0.84,0.06,1.43,0.59,1.21,1.41
	C438.18,615.21,437.58,615.65,436.89,616.49z"
              />
              <path
                d="M223.08,219.98c0.28,0.42,0.92,0.95,0.9,1.46c-0.02,0.54-0.55,1.37-1.03,1.53c-0.77,0.25-0.99-0.54-1-1.2
	C221.94,221.1,221.75,220.31,223.08,219.98z"
              />
              <path
                d="M310.37,526.29c0.62,0.89,1.18,1.37,1.28,1.93c0.15,0.84-0.48,1.32-1.31,1.31c-0.84,0-1.46-0.54-1.33-1.33
	C309.1,527.65,309.71,527.18,310.37,526.29z"
              />
              <path
                d="M315.91,630.41c0.63,0.91,1.23,1.4,1.3,1.95c0.11,0.8-0.53,1.32-1.37,1.3c-0.84-0.02-1.45-0.53-1.28-1.36
	C314.68,631.75,315.26,631.29,315.91,630.41z"
              />
              <path
                d="M252.76,258.86c-0.96,0.58-1.47,1.14-2.02,1.18c-0.8,0.05-1.34-0.67-1.13-1.43c0.13-0.47,0.84-1.12,1.24-1.09
	C251.37,257.55,251.85,258.19,252.76,258.86z"
              />
              <path
                d="M476.06,625.74c-0.85,0.66-1.3,1.28-1.84,1.37c-0.77,0.13-1.4-0.5-1.24-1.29c0.1-0.49,0.71-1.21,1.1-1.21
	C474.6,624.59,475.14,625.17,476.06,625.74z"
              />
              <path
                d="M472.35,606.2c-0.65-0.94-1.22-1.42-1.3-1.98c-0.13-0.81,0.55-1.39,1.31-1.24c0.47,0.09,1.16,0.76,1.16,1.18
	C473.52,604.69,472.94,605.23,472.35,606.2z"
              />
              <path
                d="M406.46,138.81c-0.57-0.97-1.11-1.49-1.15-2.04c-0.05-0.79,0.64-1.35,1.4-1.13c0.46,0.13,1.07,0.84,1.05,1.26
	C407.73,137.42,407.11,137.9,406.46,138.81z"
              />
              <path
                d="M472.7,629.73c-0.91,0.6-1.41,1.18-1.95,1.22c-0.77,0.05-1.36-0.61-1.14-1.39c0.13-0.47,0.81-1.14,1.2-1.11
	C471.34,628.48,471.83,629.09,472.7,629.73z"
              />
              <path
                d="M451.05,398.41c0.56,0.96,1.11,1.49,1.14,2.05c0.05,0.8-0.65,1.36-1.42,1.13c-0.46-0.14-1.09-0.86-1.05-1.28
	C449.76,399.78,450.39,399.29,451.05,398.41z"
              />
              <path
                d="M451.53,611.48c0.56,0.95,1.1,1.48,1.13,2.03c0.05,0.79-0.65,1.35-1.41,1.12c-0.46-0.14-1.07-0.86-1.03-1.27
	C450.26,612.83,450.88,612.35,451.53,611.48z"
              />
              <path
                d="M261.09,438.8c-0.92,0.57-1.44,1.15-1.98,1.17c-0.76,0.03-1.35-0.65-1.11-1.43c0.14-0.47,0.84-1.11,1.23-1.08
	C259.76,437.52,260.23,438.15,261.09,438.8z"
              />
              <path
                d="M323.65,601.13c-0.59-0.93-1.17-1.45-1.22-2.01c-0.06-0.79,0.6-1.29,1.44-1.23c0.84,0.06,1.43,0.59,1.21,1.41
	C324.94,599.86,324.33,600.29,323.65,601.13z"
              />
              <path
                d="M425.98,630.25c-0.95,0.59-1.47,1.15-2.02,1.19c-0.8,0.05-1.35-0.67-1.14-1.43c0.13-0.47,0.83-1.12,1.24-1.1
	C424.58,628.94,425.07,629.58,425.98,630.25z"
              />
              <path
                d="M306.33,250.27c-0.88,0.68-1.34,1.27-1.91,1.4c-0.79,0.18-1.36-0.35-1.41-1.22c-0.05-0.88,0.46-1.46,1.27-1.37
	C304.86,249.16,305.38,249.7,306.33,250.27z"
              />
              <path
                d="M346.04,250.71c-0.93,0.57-1.45,1.15-2,1.18c-0.77,0.03-1.36-0.66-1.11-1.45c0.15-0.47,0.85-1.12,1.25-1.08
	C344.71,249.41,345.18,250.05,346.04,250.71z"
              />
              <path
                d="M351.32,188.8c-0.92,0.58-1.45,1.16-1.99,1.19c-0.77,0.04-1.36-0.66-1.12-1.44c0.15-0.47,0.85-1.12,1.24-1.09
	C349.98,187.51,350.46,188.15,351.32,188.8z"
              />
              <path
                d="M455.06,361.95c0.59,0.94,1.17,1.47,1.17,2c0,0.41-0.67,1.07-1.14,1.17c-0.77,0.16-1.43-0.43-1.31-1.22
	C453.86,363.34,454.43,362.86,455.06,361.95z"
              />
              <path
                d="M439.4,329.22c-0.9,0.67-1.36,1.27-1.9,1.35c-0.79,0.12-1.38-0.54-1.22-1.32c0.1-0.48,0.72-1.18,1.12-1.18
	C437.92,328.06,438.45,328.64,439.4,329.22z"
              />
              <path
                d="M360.89,90.44c-0.92,0.57-1.44,1.15-1.98,1.17c-0.76,0.03-1.35-0.65-1.11-1.43c0.15-0.47,0.84-1.11,1.24-1.07
	C359.57,89.15,360.04,89.78,360.89,90.44z"
              />
              <path
                d="M315.42,583.61c0.6,0.96,1.19,1.49,1.19,2.02c0,0.41-0.68,1.08-1.15,1.18c-0.78,0.16-1.44-0.43-1.32-1.23
	C314.22,585.02,314.79,584.53,315.42,583.61z"
              />
              <path
                d="M484.72,599.89c-0.88,0.64-1.36,1.25-1.91,1.32c-0.79,0.1-1.4-0.55-1.21-1.35c0.11-0.49,0.76-1.19,1.15-1.19
	C483.29,598.69,483.81,599.29,484.72,599.89z"
              />
              <path
                d="M307.63,185.42c-0.91,0.58-1.42,1.17-1.96,1.2c-0.76,0.04-1.35-0.63-1.12-1.41c0.14-0.47,0.82-1.12,1.22-1.09
	C306.29,184.15,306.77,184.78,307.63,185.42z"
              />
              <path
                d="M346.85,367.94c-0.67-0.88-1.28-1.35-1.38-1.91c-0.14-0.8,0.47-1.33,1.32-1.33c0.84-0.01,1.48,0.45,1.33,1.3
	C348.02,366.56,347.46,367.04,346.85,367.94z"
              />
              <path
                d="M311.9,587.47c0.67,0.88,1.3,1.35,1.35,1.89c0.04,0.41-0.57,1.14-1.04,1.29c-0.77,0.25-1.47-0.32-1.44-1.11
	C310.8,588.97,311.35,588.43,311.9,587.47z"
              />
              <path
                d="M317.89,576.42c-0.54-0.99-1.1-1.54-1.07-2.07c0.02-0.41,0.73-1.04,1.2-1.11c0.76-0.11,1.41,0.49,1.25,1.29
	C319.16,575.08,318.57,575.53,317.89,576.42z"
              />
              <path
                d="M494.46,607.61c0.68,0.88,1.31,1.35,1.36,1.88c0.04,0.41-0.55,1.14-1.01,1.3c-0.79,0.28-1.46-0.33-1.46-1.09
	C493.37,609.14,493.92,608.6,494.46,607.61z"
              />
              <path
                d="M288.12,437.99c-0.66-0.86-1.25-1.31-1.38-1.87c-0.19-0.83,0.41-1.34,1.25-1.38c0.85-0.04,1.48,0.47,1.4,1.26
	C289.33,436.57,288.73,437.08,288.12,437.99z"
              />
              <path
                d="M354.18,327.63c-0.59-0.91-1.14-1.4-1.23-1.97c-0.13-0.85,0.52-1.29,1.36-1.27c0.85,0.02,1.45,0.56,1.29,1.36
	C355.49,326.31,354.86,326.76,354.18,327.63z"
              />
              <path
                d="M466.58,597.86c-0.94,0.58-1.45,1.12-2.02,1.19c-0.8,0.1-1.31-0.5-1.27-1.35c0.04-0.85,0.6-1.39,1.4-1.2
	C465.24,596.64,465.7,597.21,466.58,597.86z"
              />
              <path
                d="M338.94,417.46c-0.95,0.57-1.47,1.11-2.04,1.18c-0.8,0.09-1.3-0.51-1.25-1.36c0.05-0.85,0.62-1.38,1.41-1.19
	C337.62,416.22,338.07,416.8,338.94,417.46z"
              />
              <path
                d="M306.88,317.1c0.58,0.92,1.15,1.42,1.2,1.97c0.07,0.79-0.58,1.28-1.41,1.23c-0.83-0.05-1.41-0.58-1.22-1.39
	C305.59,318.37,306.19,317.94,306.88,317.1z"
              />
              <path
                d="M319.26,606.75c-0.86,0.68-1.3,1.27-1.85,1.41c-0.78,0.2-1.37-0.31-1.44-1.16c-0.07-0.84,0.4-1.46,1.22-1.38
	C317.77,605.68,318.3,606.2,319.26,606.75z"
              />
              <path
                d="M604.62,630.85c-0.7-0.84-1.34-1.27-1.46-1.82c-0.16-0.76,0.39-1.33,1.24-1.38c0.84-0.04,1.48,0.4,1.38,1.22
	C605.72,629.44,605.18,629.94,604.62,630.85z"
              />
              <path
                d="M291.67,497.56c-0.96,0.59-1.46,1.12-2.03,1.19c-0.81,0.11-1.33-0.52-1.29-1.32c0.04-0.8,0.59-1.4,1.4-1.22
	C290.31,496.34,290.76,496.9,291.67,497.56z"
              />
              <path
                d="M324.38,604.52c0.7,0.85,1.34,1.28,1.45,1.83c0.16,0.77-0.41,1.33-1.25,1.37c-0.83,0.04-1.47-0.4-1.38-1.23
	C323.27,605.94,323.82,605.44,324.38,604.52z"
              />
              <path
                d="M322,619.33c-0.7-0.83-1.35-1.27-1.46-1.81c-0.17-0.76,0.39-1.33,1.24-1.38c0.83-0.04,1.48,0.39,1.39,1.22
	C323.09,617.92,322.55,618.43,322,619.33z"
              />
              <path d="M223.05,230.15c0-1.15,0-2.14,0-3.26c1.19,0.21,1.77,0.69,1.73,1.74C224.74,229.57,224.15,229.94,223.05,230.15z" />
              <path
                d="M413.16,629.41c-0.7-0.84-1.34-1.27-1.46-1.82c-0.16-0.76,0.39-1.33,1.24-1.38c0.84-0.04,1.48,0.4,1.38,1.22
	C414.26,628,413.72,628.5,413.16,629.41z"
              />
              <path
                d="M291.19,450.35c-0.9,0.69-1.34,1.27-1.9,1.39c-0.79,0.18-1.38-0.38-1.43-1.2c-0.05-0.81,0.44-1.45,1.27-1.35
	C289.7,449.27,290.21,449.78,291.19,450.35z"
              />
              <path
                d="M371.57,85.69c-0.87,0.66-1.33,1.24-1.89,1.37c-0.79,0.18-1.36-0.35-1.41-1.2c-0.05-0.84,0.44-1.45,1.26-1.35
	C370.11,84.59,370.62,85.12,371.57,85.69z"
              />
              <path
                d="M435.87,618.34c-0.88,0.65-1.34,1.22-1.91,1.34c-0.79,0.17-1.35-0.37-1.39-1.22c-0.03-0.84,0.47-1.44,1.28-1.33
	C434.42,617.22,434.93,617.76,435.87,618.34z"
              />
              <path
                d="M422.19,626.93c-0.9,0.69-1.34,1.27-1.89,1.39c-0.79,0.18-1.38-0.38-1.43-1.2c-0.05-0.81,0.44-1.45,1.27-1.35
	C420.7,625.85,421.21,626.36,422.19,626.93z"
              />
              <path
                d="M347.98,588.14c-0.61-0.87-1.18-1.35-1.26-1.9c-0.12-0.8,0.48-1.29,1.33-1.29c0.86,0,1.43,0.57,1.31,1.32
	C349.27,586.82,348.64,587.28,347.98,588.14z"
              />
              <path
                d="M373.57,87.26c0.59,0.91,1.16,1.42,1.21,1.97c0.07,0.79-0.58,1.28-1.4,1.23c-0.83-0.05-1.41-0.58-1.22-1.38
	C372.29,88.53,372.89,88.1,373.57,87.26z"
              />
              <path d="M492.34,29.58c-0.74,0.19-1.39,0.58-1.89,0.44c-0.91-0.26-1.36-1-0.87-2.11C490.84,27.56,491.54,28.27,492.34,29.58z" />
              <path
                d="M364.29,402.44c-0.61-0.87-1.18-1.35-1.26-1.9c-0.12-0.8,0.48-1.29,1.33-1.29c0.86,0,1.43,0.57,1.31,1.32
	C365.59,401.12,364.96,401.58,364.29,402.44z"
              />
              <path
                d="M348.69,575.14c0.94-0.58,1.45-1.11,2.03-1.19c0.81-0.11,1.31,0.5,1.27,1.34c-0.04,0.85-0.61,1.39-1.4,1.21
	C350.03,576.37,349.57,575.79,348.69,575.14z"
              />
              <path
                d="M354.53,264.3c-0.94,0.63-1.42,1.17-1.98,1.27c-0.82,0.14-1.36-0.49-1.35-1.28c0.01-0.78,0.53-1.43,1.36-1.27
	C353.12,263.13,353.6,263.67,354.53,264.3z"
              />
              <path
                d="M395.25,630.92c0.64,0.86,1.23,1.32,1.33,1.87c0.15,0.8-0.45,1.31-1.29,1.32c-0.85,0.01-1.45-0.52-1.35-1.29
	C394,632.28,394.62,631.8,395.25,630.92z"
              />
              <path
                d="M522.6,269.53c-0.58-0.89-1.14-1.38-1.22-1.93c-0.11-0.81,0.51-1.28,1.35-1.25c0.86,0.02,1.42,0.59,1.28,1.35
	C523.91,268.23,523.28,268.68,522.6,269.53z"
              />
              <path
                d="M522.57,309.36c-0.7-0.83-1.35-1.27-1.46-1.81c-0.17-0.76,0.39-1.33,1.24-1.38c0.83-0.04,1.48,0.39,1.39,1.22
	C523.67,307.94,523.13,308.45,522.57,309.36z"
              />
              <path d="M300.18,49.56c0.64-0.8,1.18-1.48,1.7-2.13c1.17,0.41,1.22,1.1,0.84,1.8C302.2,50.21,301.38,50.16,300.18,49.56z" />
              <path
                d="M476.87,633.01c-0.9,0.6-1.33,1.09-1.83,1.18c-0.82,0.16-1.51-0.33-1.35-1.19c0.09-0.48,0.75-1.21,1.15-1.21
	C475.36,631.8,475.9,632.4,476.87,633.01z"
              />
              <path
                d="M323.07,610.56c-1,0.47-1.57,0.95-2.15,0.95c-0.81,0-1.23-0.66-1.09-1.49c0.14-0.86,0.79-1.28,1.54-1.03
	C321.9,609.18,322.29,609.81,323.07,610.56z"
              />
              <path
                d="M458.14,212.33c-1.02,0.46-1.59,0.92-2.16,0.92c-0.8,0-1.25-0.66-1.1-1.48c0.15-0.82,0.79-1.3,1.55-1.04
	C456.95,210.91,457.33,211.54,458.14,212.33z"
              />
              <path
                d="M513.54,634.28c-0.6-1.17-0.44-1.94,0.46-2.21c0.47-0.14,1.19,0.15,1.63,0.47c0.23,0.17,0.23,1.17,0.12,1.21
	C515.02,634.02,514.23,634.13,513.54,634.28z"
              />
              <path
                d="M516.48,340.56c-0.89,0.63-1.37,1.19-1.94,1.3c-0.8,0.16-1.35-0.41-1.35-1.25c-0.01-0.84,0.5-1.43,1.32-1.29
	C515.07,339.41,515.57,339.96,516.48,340.56z"
              />
              <path
                d="M321.89,596.02c-0.99,0.55-1.51,1.06-2.08,1.11c-0.81,0.07-1.31-0.56-1.23-1.38c0.07-0.81,0.66-1.37,1.45-1.16
	C320.58,594.73,321.02,595.31,321.89,596.02z"
              />
              <path
                d="M322.23,626.53c-0.59-0.9-1.17-1.41-1.22-1.96c-0.07-0.78,0.55-1.28,1.4-1.23c0.84,0.05,1.41,0.58,1.23,1.38
	C323.51,625.26,322.91,625.69,322.23,626.53z"
              />
              <path
                d="M346.14,583.01c-0.96,0.57-1.48,1.11-2.05,1.17c-0.8,0.09-1.3-0.52-1.24-1.37c0.06-0.85,0.63-1.37,1.42-1.18
	C344.82,581.77,345.27,582.35,346.14,583.01z"
              />
              <path
                d="M464.18,197.19c-0.94,0.58-1.45,1.12-2.03,1.19c-0.8,0.1-1.31-0.5-1.27-1.35c0.05-0.85,0.6-1.39,1.4-1.2
	C462.84,195.97,463.3,196.54,464.18,197.19z"
              />
              <path
                d="M448.1,343.01c-0.95,0.62-1.43,1.16-2,1.25c-0.82,0.13-1.35-0.5-1.34-1.3c0.02-0.79,0.55-1.42,1.37-1.25
	C446.7,341.83,447.18,342.37,448.1,343.01z"
              />
              <path
                d="M525.12,274.75c-0.87,0.66-1.33,1.24-1.89,1.37c-0.79,0.18-1.36-0.35-1.4-1.2c-0.04-0.84,0.44-1.45,1.26-1.35
	C523.66,273.65,524.17,274.18,525.12,274.75z"
              />
              <path
                d="M304.63,355.56c-0.98,0.58-1.49,1.1-2.05,1.17c-0.81,0.1-1.33-0.54-1.27-1.35c0.05-0.81,0.62-1.39,1.42-1.2
	C303.28,354.31,303.73,354.88,304.63,355.56z"
              />
              <path
                d="M327.66,620.81c-0.94,0.63-1.42,1.17-1.98,1.27c-0.82,0.14-1.36-0.49-1.35-1.28c0.01-0.78,0.53-1.43,1.36-1.27
	C326.25,619.65,326.73,620.19,327.66,620.81z"
              />
              <path
                d="M324.86,611.72c0.7,0.85,1.34,1.28,1.45,1.83c0.16,0.77-0.41,1.33-1.25,1.37c-0.83,0.04-1.47-0.4-1.38-1.23
	C323.75,613.13,324.29,612.63,324.86,611.72z"
              />
              <path
                d="M326.55,586.29c0.59,0.91,1.16,1.42,1.21,1.97c0.07,0.79-0.58,1.28-1.4,1.23c-0.83-0.05-1.41-0.58-1.22-1.38
	C325.26,587.57,325.86,587.13,326.55,586.29z"
              />
              <path
                d="M485.65,586.77c0.55,0.92,1.08,1.43,1.13,1.99c0.08,0.83-0.57,1.26-1.4,1.2c-0.83-0.06-1.4-0.62-1.22-1.39
	C484.29,588.03,484.93,587.61,485.65,586.77z"
              />
              <path
                d="M271.66,620.41c0.54,0.72,1.07,1.11,1.14,1.57c0.13,0.83-0.47,1.31-1.29,1.32c-0.81,0.01-1.48-0.48-1.35-1.27
	C270.25,621.53,270.95,621.14,271.66,620.41z"
              />
              <path
                d="M329.06,555.89c-1.02,0.46-1.59,0.92-2.16,0.92c-0.8,0-1.25-0.66-1.1-1.48c0.15-0.82,0.79-1.3,1.55-1.04
	C327.88,554.48,328.25,555.11,329.06,555.89z"
              />
              <path
                d="M371.3,291.96c-1.01,0.58-1.56,1.15-2.08,1.13c-0.39-0.02-1.03-0.82-1.02-1.25c0.02-0.43,0.72-1.19,1.1-1.17
	C369.83,290.69,370.33,291.29,371.3,291.96z"
              />
              <path
                d="M349.24,579.05c-0.92,0.63-1.39,1.17-1.95,1.28c-0.81,0.16-1.35-0.47-1.35-1.25c0-0.78,0.5-1.43,1.33-1.27
	C347.83,577.9,348.31,578.43,349.24,579.05z"
              />
              <path
                d="M479.29,622.09c-0.75,0.55-1.18,1.11-1.68,1.17c-0.8,0.1-1.35-0.46-1.31-1.31c0.04-0.84,0.63-1.36,1.42-1.18
	C478.22,620.88,478.59,621.47,479.29,622.09z"
              />
              <path
                d="M512.02,349.74c0.56,0.91,1.09,1.41,1.15,1.97c0.09,0.82-0.55,1.25-1.37,1.21c-0.83-0.05-1.4-0.6-1.22-1.37
	C510.69,351.01,511.31,350.58,512.02,349.74z"
              />
              <path
                d="M461.15,599.74c0.69,0.85,1.31,1.28,1.43,1.82c0.16,0.77-0.41,1.32-1.24,1.35c-0.83,0.04-1.46-0.41-1.36-1.22
	C460.04,601.14,460.58,600.64,461.15,599.74z"
              />
              <path
                d="M489.85,245.37c-0.74,0.57-1.16,1.13-1.65,1.21c-0.8,0.13-1.36-0.43-1.34-1.28c0.02-0.85,0.61-1.37,1.39-1.21
	C488.74,244.19,489.14,244.77,489.85,245.37z"
              />
              <path
                d="M314.06,70.74c0.65,0.84,1.28,1.32,1.33,1.84c0.03,0.4-0.61,1.12-1.08,1.24c-0.81,0.22-1.4-0.41-1.33-1.21
	C313.03,72.1,313.52,71.64,314.06,70.74z"
              />
              <path
                d="M318.53,599.79c-0.97,0.58-1.47,1.1-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C317.19,598.56,317.63,599.12,318.53,599.79z"
              />
              <path
                d="M345.4,575.76c-0.95,0.6-1.45,1.12-2.01,1.2c-0.81,0.12-1.32-0.51-1.29-1.31c0.03-0.79,0.57-1.4,1.38-1.22
	C344.04,574.56,344.49,575.11,345.4,575.76z"
              />
              <path
                d="M337.21,316.51c-1.02,0.54-1.59,1.08-2.12,1.04c-0.38-0.03-1-0.86-0.96-1.3c0.03-0.43,0.78-1.16,1.15-1.13
	C335.81,315.18,336.29,315.81,337.21,316.51z"
              />
              <path
                d="M487.19,628.6c-0.94,0.56-1.46,1.09-2.02,1.15c-0.8,0.09-1.29-0.51-1.23-1.35c0.06-0.84,0.62-1.37,1.41-1.17
	C485.89,627.36,486.33,627.94,487.19,628.6z"
              />
              <path d="M568.61,99.68c0.53,0.92,0.97,1.7,1.46,2.55c-0.83,0.71-1.51,0.55-2.06-0.15C567.38,101.3,567.29,100.52,568.61,99.68z" />
              <path
                d="M482.39,238.96c-0.94,0.57-1.45,1.1-2.01,1.16c-0.8,0.1-1.3-0.5-1.25-1.34c0.05-0.84,0.61-1.37,1.4-1.18
	C481.08,237.73,481.53,238.3,482.39,238.96z"
              />
              <path
                d="M450.02,617.11c-0.7,0.63-1.07,1.22-1.55,1.34c-0.8,0.2-1.38-0.33-1.43-1.17c-0.06-0.86,0.52-1.4,1.3-1.32
	C448.83,616.01,449.26,616.57,450.02,617.11z"
              />
              <path
                d="M379.99,99.76c-1.01,0.6-1.56,1.18-2.09,1.16c-0.43-0.01-1.1-0.68-1.19-1.15c-0.15-0.78,0.41-1.46,1.26-1.32
	C378.53,98.55,379.01,99.1,379.99,99.76z"
              />
              <path
                d="M323.2,554.06c-0.59-0.9-1.16-1.39-1.22-1.94c-0.07-0.77,0.53-1.28,1.38-1.23c0.83,0.04,1.4,0.57,1.22,1.36
	C324.46,552.79,323.86,553.23,323.2,554.06z"
              />
              <path
                d="M325,592.01c-0.89,0.61-1.37,1.16-1.93,1.27c-0.8,0.15-1.33-0.42-1.33-1.25c0-0.83,0.51-1.42,1.31-1.27
	C323.62,590.86,324.1,591.41,325,592.01z"
              />
              <path
                d="M289.28,349.57c-0.76,0.54-1.2,1.09-1.69,1.15c-0.79,0.09-1.35-0.47-1.29-1.33c0.05-0.84,0.66-1.35,1.44-1.16
	C288.21,348.34,288.58,348.94,289.28,349.57z"
              />
              <path
                d="M359.78,368.54c-0.95,0.69-1.44,1.3-1.97,1.34c-0.38,0.02-1.1-0.72-1.13-1.15c-0.02-0.43,0.61-1.24,0.99-1.27
	C358.2,367.42,358.77,367.98,359.78,368.54z"
              />
              <path
                d="M402.57,131.69c0.54,0.91,1.12,1.46,1.1,1.99c-0.01,0.4-0.76,1.04-1.23,1.09c-0.81,0.09-1.35-0.59-1.17-1.39
	C401.38,132.89,401.94,132.49,402.57,131.69z"
              />
              <path
                d="M492.04,250.56c-0.63-0.87-1.25-1.36-1.28-1.88c-0.02-0.4,0.64-1.11,1.11-1.21c0.81-0.19,1.4,0.43,1.3,1.25
	C493.11,249.22,492.6,249.68,492.04,250.56z"
              />
              <path
                d="M321.87,556.13c-0.94,0.7-1.43,1.32-1.95,1.36c-0.38,0.03-1.11-0.71-1.14-1.14c-0.03-0.43,0.59-1.25,0.98-1.28
	C320.28,555.03,320.85,555.58,321.87,556.13z"
              />
              <path
                d="M348.76,571.96c-0.97,0.58-1.47,1.09-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C347.42,570.73,347.87,571.29,348.76,571.96z"
              />
              <path
                d="M495.85,602.63c-0.98,0.59-1.52,1.16-2.06,1.16c-0.41,0-1.07-0.68-1.17-1.16c-0.18-0.81,0.42-1.45,1.25-1.31
	C494.43,601.42,494.92,601.99,495.85,602.63z"
              />
              <path
                d="M337.35,628.53c0.69,0.85,1.32,1.28,1.43,1.82c0.16,0.77-0.41,1.32-1.24,1.35c-0.83,0.04-1.46-0.41-1.36-1.22
	C336.24,629.93,336.78,629.43,337.35,628.53z"
              />
              <path
                d="M279.42,314.14c-0.88,0.63-1.34,1.19-1.9,1.31c-0.79,0.17-1.34-0.38-1.36-1.22c-0.02-0.83,0.47-1.43,1.28-1.31
	C278,313.02,278.5,313.55,279.42,314.14z"
              />
              <path
                d="M490.04,601.12c-0.63-0.93-1.21-1.42-1.27-1.98c-0.1-0.83,0.58-1.39,1.39-1.18c0.49,0.13,1.17,0.78,1.16,1.18
	C491.3,599.68,490.69,600.19,490.04,601.12z"
              />
              <path
                d="M493.67,620.9c-0.97,0.58-1.47,1.1-2.03,1.17c-0.81,0.1-1.31-0.52-1.27-1.33c0.05-0.8,0.6-1.38,1.4-1.2
	C492.33,619.67,492.78,620.23,493.67,620.9z"
              />
              <path d="M239,131.37c0.37-1.14,0.64-1.98,0.91-2.78c1.17-0.16,1.46,0.54,1.4,1.25C241.23,130.77,240.61,131.35,239,131.37z" />
              <path
                d="M295.73,627.11c-0.92,0.58-1.42,1.12-1.98,1.2c-0.8,0.12-1.31-0.48-1.28-1.31c0.03-0.83,0.57-1.39,1.37-1.21
	C294.4,625.91,294.86,626.47,295.73,627.11z"
              />
              <path
                d="M518.11,255.22c0.63,0.86,1.21,1.32,1.3,1.87c0.14,0.79-0.45,1.29-1.29,1.3c-0.85,0.01-1.43-0.53-1.33-1.29
	C516.86,256.56,517.47,256.09,518.11,255.22z"
              />
              <path
                d="M436.18,186.56c0.71,0.9,1.35,1.37,1.41,1.91c0.04,0.39-0.62,1.1-1.1,1.24c-0.77,0.24-1.51-0.24-1.45-1.1
	C435.08,188.05,435.61,187.53,436.18,186.56z"
              />
              <path
                d="M509.38,360.78c0.57,0.91,1.14,1.42,1.18,1.97c0.06,0.78-0.57,1.26-1.4,1.21c-0.82-0.05-1.4-0.58-1.2-1.38
	C508.09,362.04,508.7,361.61,509.38,360.78z"
              />
              <path
                d="M333.16,558.83c-0.87,0.65-1.32,1.22-1.87,1.34c-0.78,0.18-1.35-0.35-1.39-1.19c-0.04-0.83,0.45-1.44,1.25-1.33
	C331.72,557.72,332.22,558.25,333.16,558.83z"
              />
              <path
                d="M320.57,604.92c-0.7-0.83-1.34-1.26-1.46-1.79c-0.16-0.75,0.37-1.33,1.22-1.37c0.83-0.04,1.46,0.39,1.38,1.2
	C321.65,603.51,321.12,604.02,320.57,604.92z"
              />
              <path
                d="M238.44,260.07c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C237.01,258.83,237.48,259.39,238.44,260.07z"
              />
              <path
                d="M452.55,206.75c0.54,0.92,1.06,1.43,1.11,1.99c0.07,0.83-0.58,1.24-1.4,1.18c-0.83-0.06-1.38-0.62-1.19-1.39
	C451.21,207.99,451.84,207.58,452.55,206.75z"
              />
              <path
                d="M241.86,405.83c-0.71-0.9-1.36-1.36-1.44-1.9c-0.11-0.77,0.5-1.44,1.34-1.25c0.49,0.11,1.19,0.71,1.21,1.12
	C242.98,404.32,242.42,404.87,241.86,405.83z"
              />
              <path
                d="M521.77,258.53c0.73,0.89,1.35,1.32,1.47,1.87c0.17,0.81-0.42,1.32-1.28,1.34c-0.75,0.02-1.34-0.42-1.28-1.11
	C520.72,260.06,521.22,259.53,521.77,258.53z"
              />
              <path
                d="M390.57,327.95c0.66,0.78,1.23,1.16,1.35,1.66c0.2,0.79-0.31,1.49-1.13,1.42c-0.47-0.04-1.24-0.65-1.27-1.05
	C389.5,329.45,390.05,328.88,390.57,327.95z"
              />
              <path
                d="M519.15,329.65c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C517.72,328.41,518.19,328.97,519.15,329.65z"
              />
              <path
                d="M241.58,188.96c0.6,0.96,1.17,1.48,1.21,2.03c0.06,0.83-0.67,1.36-1.45,1.13c-0.47-0.14-1.12-0.83-1.1-1.24
	C240.27,190.36,240.9,189.87,241.58,188.96z"
              />
              <path
                d="M425.29,330.63c-0.95,0.56-1.47,1.08-2.03,1.14c-0.8,0.08-1.28-0.52-1.22-1.36c0.06-0.84,0.63-1.36,1.41-1.16
	C424,329.39,424.43,329.96,425.29,330.63z"
              />
              <path
                d="M487.95,595.76c-0.96,0.68-1.43,1.21-1.99,1.35c-0.7,0.17-1.23-0.35-1.31-1.07c-0.09-0.83,0.33-1.5,1.18-1.43
	C486.4,594.66,486.92,595.18,487.95,595.76z"
              />
              <path
                d="M651.36,482.38c0.75-0.16,1.49-0.19,1.48,0.85c-0.01,0.92,0.51,2.12-0.99,2.47c0,0,0.07,0.05,0.07,0.05
	c-0.86-1.05-0.99-2.2-0.49-3.44L651.36,482.38z"
              />
              <path
                d="M290.48,443.32c-0.97,0.59-1.51,1.17-2.05,1.17c-0.41,0-1.07-0.68-1.18-1.15c-0.19-0.81,0.42-1.45,1.24-1.32
	C289.05,442.11,289.54,442.68,290.48,443.32z"
              />
              <path
                d="M408.8,329.86c0.66,0.78,1.23,1.16,1.35,1.66c0.2,0.79-0.31,1.49-1.13,1.42c-0.47-0.04-1.24-0.65-1.27-1.05
	C407.73,331.37,408.29,330.8,408.8,329.86z"
              />
              <path
                d="M376.82,356.48c-0.92,0.58-1.42,1.12-1.99,1.2c-0.8,0.12-1.31-0.48-1.28-1.31c0.03-0.83,0.57-1.39,1.37-1.21
	C375.49,355.28,375.95,355.84,376.82,356.48z"
              />
              <path
                d="M327.91,580.92c-0.93,0.65-1.44,1.25-1.97,1.28c-0.41,0.02-1.11-0.6-1.25-1.06c-0.24-0.8,0.32-1.48,1.15-1.4
	C326.4,579.78,326.92,580.33,327.91,580.92z"
              />
              <path
                d="M421.6,140.97c0.56,0.99,1.1,1.52,1.12,2.08c0.03,0.83-0.71,1.33-1.48,1.08c-0.47-0.15-1.09-0.87-1.05-1.27
	C420.24,142.33,420.88,141.87,421.6,140.97z"
              />
              <path
                d="M302.47,373.84c-1.01,0.55-1.56,1.08-2.13,1.1c-0.82,0.03-1.37-0.66-1.08-1.46c0.16-0.46,0.89-1.05,1.3-1.01
	C301.09,372.53,301.57,373.15,302.47,373.84z"
              />
              <path
                d="M346.45,194.4c-0.65-0.79-1.21-1.19-1.32-1.69c-0.19-0.8,0.35-1.48,1.16-1.4c0.47,0.05,1.23,0.68,1.24,1.08
	C347.55,192.92,346.98,193.48,346.45,194.4z"
              />
              <path
                d="M263.81,427.74c-1,0.59-1.55,1.16-2.07,1.14c-0.38-0.01-1.04-0.81-1.03-1.24c0.01-0.43,0.71-1.19,1.09-1.18
	C262.33,426.48,262.84,427.08,263.81,427.74z"
              />
              <path
                d="M433.1,194.23c-0.71-0.9-1.33-1.34-1.43-1.89c-0.16-0.81,0.44-1.31,1.3-1.32c0.75-0.01,1.32,0.45,1.26,1.13
	C434.18,192.72,433.67,193.24,433.1,194.23z"
              />
              <path
                d="M508.55,373.75c-0.95,0.6-1.45,1.12-2.01,1.2c-0.81,0.12-1.32-0.51-1.29-1.31c0.03-0.79,0.57-1.4,1.38-1.22
	C507.18,372.55,507.64,373.1,508.55,373.75z"
              />
              <path
                d="M484.12,632.43c-1.04,0.58-1.6,1.14-2.12,1.11c-0.43-0.02-1.09-0.71-1.17-1.18c-0.12-0.77,0.46-1.45,1.3-1.28
	C482.69,631.18,483.16,631.75,484.12,632.43z"
              />
              <path
                d="M458.4,172.17c-0.9,0.6-1.38,1.15-1.95,1.25c-0.8,0.14-1.32-0.44-1.32-1.27c0.01-0.83,0.53-1.41,1.33-1.25
	C457.03,171.01,457.51,171.56,458.4,172.17z"
              />
              <path
                d="M488.52,622.77c0.68,0.85,1.31,1.29,1.41,1.83c0.15,0.77-0.41,1.31-1.25,1.34c-0.83,0.03-1.45-0.42-1.35-1.24
	C487.39,624.16,487.94,623.67,488.52,622.77z"
              />
              <path
                d="M293.09,432.09c-0.88,0.69-1.32,1.27-1.86,1.4c-0.78,0.19-1.37-0.36-1.43-1.17c-0.05-0.8,0.41-1.45,1.23-1.35
	C291.6,431.04,292.12,431.54,293.09,432.09z"
              />
              <path
                d="M479.35,605.62c-0.52-0.93-1.08-1.49-1.05-2.02c0.02-0.4,0.79-1.02,1.26-1.06c0.81-0.07,1.33,0.63,1.14,1.42
	C480.57,604.45,480.01,604.83,479.35,605.62z"
              />
              <path
                d="M436.6,197.54c-0.53-0.92-1.05-1.44-1.09-1.99c-0.07-0.82,0.59-1.23,1.41-1.17c0.85,0.06,1.37,0.65,1.18,1.4
	C437.96,196.32,437.32,196.73,436.6,197.54z"
              />
              <path
                d="M452.25,373.04c0.65,0.84,1.28,1.32,1.33,1.84c0.03,0.4-0.61,1.12-1.08,1.24c-0.81,0.22-1.4-0.41-1.33-1.21
	C451.22,374.4,451.72,373.93,452.25,373.04z"
              />
              <path
                d="M446.21,614.12c-1.01,0.61-1.54,1.19-2.07,1.18c-0.43-0.01-1.11-0.66-1.21-1.13c-0.16-0.78,0.4-1.47,1.24-1.33
	C444.74,612.93,445.23,613.48,446.21,614.12z"
              />
              <path
                d="M398.32,334.35c0.69,0.92,1.32,1.39,1.39,1.94c0.1,0.78-0.55,1.42-1.37,1.23c-0.48-0.11-1.18-0.74-1.18-1.15
	C397.15,335.84,397.73,335.31,398.32,334.35z"
              />
              <path
                d="M376.59,269.46c-0.89,0.68-1.34,1.25-1.89,1.37c-0.79,0.18-1.37-0.38-1.41-1.19c-0.04-0.79,0.43-1.44,1.26-1.33
	C375.12,268.38,375.62,268.89,376.59,269.46z"
              />
              <path
                d="M470.39,601.08c-0.92,0.63-1.43,1.23-1.96,1.26c-0.41,0.02-1.1-0.61-1.23-1.07c-0.23-0.79,0.33-1.47,1.15-1.38
	C468.92,599.96,469.43,600.5,470.39,601.08z"
              />
              <path
                d="M327.97,564.59c-0.66-0.86-1.3-1.34-1.34-1.86c-0.03-0.39,0.65-1.07,1.13-1.2c0.84-0.22,1.43,0.42,1.37,1.22
	C329.09,563.25,328.55,563.71,327.97,564.59z"
              />
              <path
                d="M312.86,535.4c-0.77,0.57-1.18,1.09-1.68,1.2c-0.83,0.18-1.38-0.37-1.38-1.21c0-0.83,0.55-1.39,1.38-1.2
	C311.68,534.3,312.09,534.82,312.86,535.4z"
              />
              <path
                d="M642.77,633c-0.8,0.57-1.21,1.05-1.71,1.18c-0.7,0.18-1.24-0.24-1.31-1c-0.08-0.85,0.42-1.44,1.23-1.36
	C641.48,631.87,641.93,632.41,642.77,633z"
              />
              <path
                d="M311.34,473.92c-0.95,0.65-1.42,1.16-1.98,1.29c-0.7,0.16-1.2-0.36-1.26-1.08c-0.07-0.8,0.33-1.48,1.18-1.38
	C309.84,472.81,310.34,473.33,311.34,473.92z"
              />
              <path
                d="M377.16,110.65c-0.75,0.62-1.13,1.16-1.63,1.29c-0.81,0.21-1.41-0.28-1.44-1.14c-0.04-0.85,0.48-1.42,1.32-1.27
	C375.92,109.61,376.35,110.11,377.16,110.65z"
              />
              <path
                d="M400.05,272.84c-0.6-0.86-1.13-1.29-1.19-1.77c-0.11-0.8,0.43-1.44,1.27-1.26c0.47,0.11,1.16,0.75,1.15,1.13
	C401.27,271.45,400.67,271.94,400.05,272.84z"
              />
              <path
                d="M289.91,269.86c-0.8,0.69-1.17,1.26-1.67,1.4c-0.8,0.22-1.52-0.25-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C288.35,268.82,288.93,269.36,289.91,269.86z"
              />
              <path
                d="M445.49,337.67c-0.66-0.9-1.26-1.37-1.33-1.9c-0.11-0.79,0.52-1.4,1.32-1.2c0.48,0.11,1.17,0.73,1.17,1.12
	C446.66,336.2,446.09,336.72,445.49,337.67z"
              />
              <path
                d="M481.43,627.01c-0.63-0.92-1.21-1.39-1.28-1.94c-0.1-0.81,0.55-1.38,1.35-1.17c0.48,0.12,1.15,0.76,1.15,1.15
	C482.64,625.57,482.05,626.08,481.43,627.01z"
              />
              <path
                d="M322.42,563.09c-0.72,0.63-1.08,1.19-1.56,1.32c-0.78,0.22-1.39-0.25-1.45-1.09c-0.06-0.83,0.43-1.41,1.26-1.29
	C321.17,562.1,321.61,562.58,322.42,563.09z"
              />
              <path
                d="M381.66,270.99c-0.54-0.99-1.04-1.51-1.09-2.07c-0.06-0.68,0.51-1.13,1.26-1.11c0.86,0.02,1.44,0.52,1.27,1.33
	C383,269.67,382.38,270.1,381.66,270.99z"
              />
              <path d="M300.2,634.03c-0.22-1.12,0.16-1.84,1.2-1.89c1.02-0.05,1.47,0.65,1.48,1.89C301.96,634.03,301.11,634.03,300.2,634.03z" />
              <path
                d="M490.29,392c0.28,0.32,0.85,0.7,0.97,1.18c0.2,0.79-0.26,1.49-1.13,1.38c-0.49-0.06-1.16-0.57-1.31-1.02
	C488.56,392.74,489.15,392.25,490.29,392z"
              />
              <path
                d="M434.46,623.76c0.59,0.87,1.11,1.32,1.17,1.81c0.09,0.81-0.49,1.44-1.32,1.23c-0.47-0.12-1.16-0.78-1.14-1.17
	C433.2,625.13,433.82,624.64,434.46,623.76z"
              />
              <path
                d="M268.15,271.79c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.1-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C267.07,270.61,267.45,271.19,268.15,271.79z"
              />
              <path
                d="M294.97,619.66c-0.82,0.7-1.2,1.26-1.69,1.4c-0.81,0.23-1.51-0.27-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C293.39,618.62,293.97,619.15,294.97,619.66z"
              />
              <path
                d="M264.54,268.73c-0.99,0.55-1.55,1.11-2.09,1.09c-0.41-0.02-1.04-0.72-1.12-1.2c-0.14-0.8,0.48-1.42,1.3-1.25
	C263.18,267.49,263.64,268.06,264.54,268.73z"
              />
              <path
                d="M245.34,192.4c0.6,0.86,1.12,1.28,1.19,1.76c0.12,0.8-0.42,1.43-1.25,1.25c-0.47-0.1-1.16-0.73-1.15-1.11
	C244.15,193.79,244.74,193.3,245.34,192.4z"
              />
              <path
                d="M286.35,373.11c0.59,0.8,1.08,1.22,1.23,1.74c0.19,0.69-0.67,1.52-1.32,1.26c-0.47-0.19-1.02-0.82-1.05-1.29
	C285.19,374.38,285.79,373.9,286.35,373.11z"
              />
              <path
                d="M257.62,442.44c-0.75,0.64-1.13,1.16-1.63,1.33c-0.67,0.23-1.55-0.59-1.33-1.26c0.17-0.49,0.74-1.11,1.21-1.17
	C256.29,441.29,256.81,441.9,257.62,442.44z"
              />
              <path
                d="M379.1,92.56c-0.8,0.55-1.23,1.06-1.74,1.16c-0.84,0.16-1.38-0.4-1.36-1.26c0.02-0.86,0.61-1.38,1.43-1.18
	C377.94,91.41,378.34,91.95,379.1,92.56z"
              />
              <path
                d="M315.13,477.48c-0.98,0.52-1.55,1.07-2.08,1.03c-0.41-0.03-1.03-0.77-1.07-1.24c-0.07-0.82,0.59-1.37,1.42-1.15
	C313.9,476.24,314.29,476.79,315.13,477.48z"
              />
              <path
                d="M488.91,397.29c-1.07,0.57-1.63,1.12-2.15,1.08c-0.4-0.03-1.05-0.81-1.03-1.22c0.02-0.42,0.74-1.12,1.15-1.12
	C487.4,396.04,487.91,396.63,488.91,397.29z"
              />
              <path
                d="M443.34,625.27c-0.81,0.5-1.25,0.98-1.75,1.04c-0.82,0.11-1.32-0.46-1.26-1.29c0.07-0.83,0.66-1.31,1.45-1.09
	C442.26,624.07,442.62,624.63,443.34,625.27z"
              />
              <path
                d="M451.04,339.29c-0.82,0.51-1.27,0.99-1.77,1.05c-0.83,0.11-1.34-0.45-1.27-1.3c0.07-0.85,0.67-1.32,1.46-1.1
	C449.95,338.08,450.31,338.64,451.04,339.29z"
              />
              <path
                d="M310.47,197.45c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C311.54,196.2,311.02,196.64,310.47,197.45z"
              />
              <path
                d="M650.13,632.26c-0.96,0.65-1.44,1.17-2,1.3c-0.7,0.16-1.21-0.37-1.27-1.09c-0.07-0.8,0.34-1.49,1.19-1.39
	C648.61,631.15,649.12,631.67,650.13,632.26z"
              />
              <path
                d="M483.92,612.28c0.66,0.84,1.29,1.3,1.34,1.81c0.04,0.38-0.62,1.06-1.09,1.18c-0.8,0.22-1.4-0.36-1.33-1.17
	C482.88,613.61,483.37,613.16,483.92,612.28z"
              />
              <path
                d="M287.18,427.06c0.66,0.84,1.29,1.3,1.34,1.81c0.04,0.38-0.62,1.06-1.09,1.18c-0.8,0.22-1.4-0.36-1.33-1.17
	C286.14,428.39,286.64,427.94,287.18,427.06z"
              />
              <path
                d="M352.74,272.79c-0.23-0.28-0.79-0.67-0.9-1.15c-0.18-0.8,0.31-1.48,1.17-1.36c0.48,0.07,1.14,0.6,1.27,1.05
	C354.52,272.14,353.95,272.64,352.74,272.79z"
              />
              <path
                d="M369.5,356.86c-0.77,0.59-1.17,1.13-1.68,1.25c-0.82,0.19-1.41-0.32-1.42-1.19c-0.01-0.85,0.53-1.42,1.37-1.25
	C368.28,355.78,368.71,356.3,369.5,356.86z"
              />
              <path
                d="M513.86,331.55c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C512.7,332.79,513.22,332.39,513.86,331.55z"
              />
              <path
                d="M265.5,196.32c-1.01,0.53-1.58,1.08-2.11,1.04c-0.41-0.03-1.03-0.74-1.1-1.22c-0.12-0.8,0.51-1.41,1.32-1.22
	C264.17,195.04,264.61,195.63,265.5,196.32z"
              />
              <path
                d="M468.79,626.57c-0.77,0.57-1.18,1.08-1.68,1.19c-0.82,0.18-1.38-0.37-1.37-1.2c0-0.83,0.55-1.38,1.38-1.2
	C467.62,625.47,468.03,625.99,468.79,626.57z"
              />
              <path
                d="M318.81,579.57c0.6,0.95,1.17,1.47,1.17,1.99c0,0.4-0.69,1.01-1.17,1.12c-0.8,0.19-1.44-0.4-1.33-1.2
	C317.56,580.94,318.15,580.48,318.81,579.57z"
              />
              <path
                d="M277.98,339.66c-0.96,0.59-1.49,1.16-2.03,1.17c-0.41,0-1.06-0.67-1.17-1.14c-0.19-0.8,0.4-1.44,1.23-1.31
	C276.57,338.48,277.05,339.04,277.98,339.66z"
              />
              <path
                d="M258.84,263.4c0.6,0.88,1.14,1.32,1.19,1.82c0.09,0.81-0.5,1.45-1.34,1.24c-0.48-0.12-1.17-0.79-1.15-1.17
	C257.57,264.76,258.2,264.27,258.84,263.4z"
              />
              <path
                d="M394.79,331.55c0.28,0.32,0.84,0.69,0.96,1.17c0.21,0.79-0.26,1.48-1.12,1.37c-0.48-0.06-1.15-0.56-1.3-1.01
	C393.08,332.29,393.67,331.8,394.79,331.55z"
              />
              <path
                d="M456.28,356.72c-0.8,0.57-1.23,1.05-1.74,1.18c-0.66,0.16-1.5-0.79-1.21-1.38c0.23-0.47,0.85-1.02,1.32-1.04
	C455.06,355.45,455.52,356.11,456.28,356.72z"
              />
              <path
                d="M520.54,264.16c-0.96,0.67-1.42,1.19-1.97,1.32c-0.71,0.17-1.28-0.4-1.17-1.09c0.08-0.48,0.61-1.22,0.99-1.26
	C518.92,263.09,519.48,263.61,520.54,264.16z"
              />
              <path
                d="M443.4,332.48c-0.8,0.55-1.23,1.06-1.74,1.16c-0.84,0.16-1.38-0.4-1.36-1.26c0.02-0.86,0.61-1.38,1.43-1.18
	C442.24,331.33,442.64,331.87,443.4,332.48z"
              />
              <path
                d="M293.38,376.07c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C294.57,374.59,293.98,375.12,293.38,376.07z"
              />
              <path
                d="M308.11,481.23c0.75-0.6,1.13-1.13,1.62-1.25c0.81-0.2,1.37,0.33,1.39,1.16c0.02,0.82-0.5,1.38-1.32,1.23
	C309.31,482.27,308.89,481.77,308.11,481.23z"
              />
              <path
                d="M282.22,433.06c0.75-0.63,1.19-1.29,1.61-1.28c0.47,0.01,1.1,0.54,1.34,1.01c0.3,0.59-0.52,1.56-1.18,1.41
	C283.47,434.08,283.03,433.6,282.22,433.06z"
              />
              <path
                d="M493.13,613.41c-0.81,0.7-1.18,1.26-1.67,1.4c-0.8,0.23-1.5-0.25-1.44-1.09c0.04-0.47,0.61-1.23,1.01-1.27
	C491.56,612.39,492.14,612.92,493.13,613.41z"
              />
              <path
                d="M328.88,579.04c-0.54-0.99-1.04-1.51-1.09-2.07c-0.06-0.68,0.51-1.13,1.26-1.11c0.86,0.02,1.44,0.52,1.27,1.33
	C330.22,577.72,329.6,578.16,328.88,579.04z"
              />
              <path
                d="M441.94,191.8c-0.76,0.59-1.16,1.12-1.66,1.24c-0.82,0.19-1.4-0.33-1.41-1.18c-0.01-0.84,0.52-1.41,1.36-1.24
	C440.73,190.72,441.15,191.24,441.94,191.8z"
              />
              <path
                d="M462.1,610.59c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C463.17,609.34,462.65,609.79,462.1,610.59z"
              />
              <path
                d="M489.24,610.64c-1.02,0.38-1.64,0.83-2.15,0.73c-0.38-0.08-0.86-0.88-0.86-1.35c0.01-0.86,0.76-1.24,1.52-0.94
	C488.21,609.27,488.52,609.86,489.24,610.64z"
              />
              <path
                d="M454.33,168.97c-0.7,0.61-1.08,1.19-1.56,1.3c-0.79,0.18-1.36-0.35-1.4-1.18c-0.05-0.85,0.53-1.38,1.31-1.29
	C453.16,167.87,453.58,168.42,454.33,168.97z"
              />
              <path
                d="M485.53,401.05c-1.03,0.6-1.56,1.16-2.08,1.14c-0.4-0.01-1.08-0.76-1.08-1.17c0.01-0.42,0.69-1.15,1.09-1.16
	C483.99,399.87,484.52,400.44,485.53,401.05z"
              />
              <path
                d="M343.49,221.96c-0.94,0.67-1.42,1.27-1.94,1.3c-0.42,0.02-1.12-0.57-1.26-1.02c-0.23-0.75,0.26-1.47,1.1-1.4
	C341.95,220.88,342.47,221.39,343.49,221.96z"
              />
              <path
                d="M332.06,567.41c-1.23-0.12-1.82-0.6-1.6-1.42c0.12-0.46,0.77-1.01,1.26-1.1c0.86-0.15,1.38,0.53,1.23,1.33
	C332.85,566.72,332.29,567.12,332.06,567.41z"
              />
              <path
                d="M465.05,606.87c-0.6-0.94-1.16-1.44-1.22-1.98c-0.08-0.83,0.61-1.37,1.39-1.14c0.48,0.14,1.15,0.8,1.13,1.19
	C466.33,605.46,465.71,605.95,465.05,606.87z"
              />
              <path
                d="M489.11,633.74c-0.64-0.92-1.22-1.4-1.28-1.95c-0.1-0.82,0.56-1.39,1.36-1.18c0.48,0.12,1.17,0.76,1.16,1.16
	C490.34,632.29,489.73,632.8,489.11,633.74z"
              />
              <path
                d="M416.43,329.66c0.24,0.28,0.81,0.65,0.93,1.14c0.2,0.79-0.26,1.49-1.13,1.38c-0.49-0.06-1.16-0.57-1.3-1.02
	C414.68,330.37,415.22,329.84,416.43,329.66z"
              />
              <path
                d="M486.99,601.72c0.27,0.36,0.89,0.85,0.92,1.38c0.02,0.38-0.72,1.1-1.15,1.13c-0.43,0.03-1.16-0.54-1.3-1
	C485.2,602.43,485.75,601.9,486.99,601.72z"
              />
              <path
                d="M453.1,344.06c-0.58-0.81-1.08-1.23-1.22-1.75c-0.19-0.68,0.62-1.4,1.34-1.29c0.73,0.11,1.23,0.6,1.06,1.31
	C454.16,342.84,453.66,343.26,453.1,344.06z"
              />
              <path
                d="M326.28,566.71c-0.81,0.52-1.25,1-1.75,1.07c-0.83,0.12-1.34-0.44-1.28-1.29c0.06-0.85,0.66-1.33,1.45-1.11
	C325.18,565.52,325.55,566.08,326.28,566.71z"
              />
              <path
                d="M319.04,567.38c-0.81,0.56-1.24,1.04-1.75,1.17c-0.66,0.16-1.5-0.8-1.21-1.39c0.23-0.47,0.86-1.02,1.33-1.03
	C317.84,566.11,318.28,566.76,319.04,567.38z"
              />
              <path
                d="M364.77,358.9c0.5,0.96,1.03,1.53,0.98,2.04c-0.04,0.38-0.8,0.93-1.28,0.98c-0.87,0.1-1.31-0.61-1.1-1.39
	C363.51,360.05,364.07,359.69,364.77,358.9z"
              />
              <path
                d="M344.65,402.6c-0.98,0.55-1.53,1.11-2.06,1.08c-0.41-0.02-1.03-0.71-1.12-1.18c-0.14-0.79,0.46-1.42,1.28-1.25
	C343.3,401.37,343.76,401.94,344.65,402.6z"
              />
              <path
                d="M316.08,428.29c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C317.29,426.91,316.69,427.41,316.08,428.29z"
              />
              <path
                d="M324.98,632.18c-1.06,0.44-1.64,0.89-2.2,0.87c-0.85-0.03-1.27-0.8-0.94-1.54c0.2-0.44,0.98-0.95,1.39-0.88
	C323.74,630.74,324.15,631.41,324.98,632.18z"
              />
              <path
                d="M256.59,262c-0.93,0.69-1.42,1.3-1.94,1.34c-0.37,0.03-1.09-0.7-1.12-1.13c-0.03-0.42,0.59-1.23,0.97-1.26
	C255.02,260.91,255.58,261.45,256.59,262z"
              />
              <path
                d="M447.35,192.91c-0.59-0.8-1.09-1.22-1.24-1.74c-0.2-0.67,0.61-1.41,1.33-1.31c0.73,0.1,1.24,0.58,1.07,1.3
	C448.41,191.68,447.91,192.1,447.35,192.91z"
              />
              <path
                d="M469.3,610.1c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C470.36,608.86,469.84,609.3,469.3,610.1z"
              />
              <path
                d="M307.06,483.66c0.27,0.34,0.81,0.74,0.91,1.23c0.16,0.81-0.36,1.47-1.21,1.32c-0.48-0.08-1.12-0.63-1.25-1.09
	C305.3,484.31,305.92,483.86,307.06,483.66z"
              />
              <path
                d="M371.15,172.17c-0.04,1.21-0.46,1.7-1.31,1.71c-0.75,0.01-1.3-0.45-1.19-1.13c0.07-0.49,0.62-1.23,1-1.26
	C370.16,171.43,370.73,171.96,371.15,172.17z"
              />
              <path
                d="M456.73,403.67c-0.72,0.58-1.12,1.15-1.61,1.24c-0.79,0.14-1.35-0.4-1.36-1.23c-0.01-0.84,0.57-1.37,1.35-1.24
	C455.6,402.53,456,403.1,456.73,403.67z"
              />
              <path
                d="M433.37,154.31c-0.7-0.8-1.26-1.16-1.38-1.64c-0.2-0.78,0.25-1.48,1.11-1.38c0.48,0.06,1.23,0.62,1.27,1
	C434.41,152.8,433.88,153.37,433.37,154.31z"
              />
              <path
                d="M493.38,387.46c0.6,0.82,1.12,1.24,1.21,1.74c0.15,0.8-0.42,1.44-1.21,1.33c-0.46-0.07-1.18-0.74-1.17-1.14
	C492.22,388.86,492.81,388.34,493.38,387.46z"
              />
              <path
                d="M285.39,425.8c-0.99,0.6-1.52,1.16-2.04,1.15c-0.38-0.01-1.03-0.79-1.02-1.22c0.01-0.43,0.7-1.19,1.07-1.18
	C283.92,424.57,284.43,425.16,285.39,425.8z"
              />
              <path
                d="M475.27,618.59c-0.77,0.61-1.16,1.11-1.68,1.29c-0.66,0.23-1.5-0.6-1.27-1.29c0.17-0.49,0.76-1.1,1.2-1.14
	C473.97,617.41,474.46,618.03,475.27,618.59z"
              />
              <path
                d="M317.02,558.34c0.47,0.86,1.02,1.4,0.95,1.83c-0.08,0.46-0.7,1.02-1.19,1.17c-0.64,0.19-1.45-0.79-1.18-1.42
	C315.81,559.43,316.35,559.07,317.02,558.34z"
              />
              <path
                d="M352.24,302.22c0.68,0.9,1.31,1.38,1.35,1.9c0.03,0.39-0.63,1.06-1.1,1.21c-0.77,0.23-1.47-0.27-1.41-1.1
	C351.12,303.68,351.66,303.17,352.24,302.22z"
              />
              <path
                d="M362.91,404.35c-0.92,0.63-1.43,1.23-1.96,1.26c-0.41,0.02-1.1-0.61-1.23-1.07c-0.23-0.79,0.33-1.47,1.15-1.38
	C361.44,403.22,361.95,403.76,362.91,404.35z"
              />
              <path
                d="M411.63,631.28c-0.95,0.66-1.41,1.18-1.97,1.31c-0.69,0.17-1.21-0.35-1.27-1.06c-0.08-0.8,0.31-1.48,1.16-1.4
	C410.11,630.19,410.62,630.7,411.63,631.28z"
              />
              <path
                d="M277.65,276.38c-0.66-0.74-1.19-1.11-1.4-1.61c-0.26-0.63,0.54-1.59,1.2-1.4c0.49,0.15,1.1,0.72,1.17,1.18
	C278.69,274.99,278.14,275.53,277.65,276.38z"
              />
              <path
                d="M508.39,353.63c0.67,0.85,1.3,1.31,1.35,1.82c0.04,0.38-0.63,1.06-1.09,1.19c-0.81,0.22-1.42-0.37-1.35-1.18
	C507.33,354.97,507.83,354.52,508.39,353.63z"
              />
              <path
                d="M459.15,179.48c-1.03,0.56-1.6,1.11-2.12,1.08c-0.42-0.03-1.07-0.72-1.14-1.18c-0.11-0.76,0.46-1.44,1.3-1.26
	C457.75,178.23,458.2,178.79,459.15,179.48z"
              />
              <path
                d="M357.13,272.94c0.64,0.94,1.24,1.44,1.26,1.97c0.01,0.4-0.67,1.04-1.16,1.17c-0.79,0.21-1.47-0.34-1.37-1.17
	C355.93,274.36,356.5,273.87,357.13,272.94z"
              />
              <path
                d="M482.17,404.82c-0.95,0.65-1.42,1.16-1.98,1.29c-0.7,0.16-1.2-0.36-1.26-1.08c-0.07-0.8,0.33-1.48,1.18-1.38
	C480.66,403.72,481.17,404.23,482.17,404.82z"
              />
              <path
                d="M454.81,176.3c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.11-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C453.73,175.12,454.1,175.7,454.81,176.3z"
              />
              <path
                d="M325.96,559.43c-0.96,0.59-1.49,1.17-2.03,1.17c-0.41,0-1.06-0.67-1.17-1.14c-0.19-0.8,0.4-1.44,1.23-1.31
	C324.55,558.24,325.03,558.8,325.96,559.43z"
              />
              <path
                d="M358.45,291.01c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C359.52,289.76,359,290.21,358.45,291.01z"
              />
              <path
                d="M313.86,545.61c0.83-0.56,1.28-1.1,1.76-1.14c0.77-0.06,1.42,0.52,1.22,1.33c-0.12,0.47-0.81,1.16-1.19,1.12
	C315.14,546.89,314.67,546.25,313.86,545.61z"
              />
              <path
                d="M474.99,405.51c-0.75,0.63-1.14,1.16-1.64,1.32c-0.68,0.22-1.55-0.6-1.32-1.26c0.17-0.49,0.75-1.1,1.21-1.16
	C473.67,404.35,474.18,404.96,474.99,405.51z"
              />
              <path
                d="M472.99,613.29c-0.58-0.85-1.09-1.28-1.17-1.79c-0.14-0.82,0.46-1.43,1.24-1.29c0.46,0.08,1.15,0.77,1.14,1.17
	C474.19,611.91,473.59,612.42,472.99,613.29z"
              />
              <path
                d="M262.54,436.46c-0.57-0.92-1.14-1.44-1.13-1.94c0.01-0.38,0.72-0.99,1.19-1.07c0.84-0.15,1.34,0.51,1.19,1.3
	C263.71,435.22,263.18,435.62,262.54,436.46z"
              />
              <path
                d="M283.08,633.43c-0.51-0.94-1.06-1.5-1.02-2.03c0.03-0.39,0.79-0.99,1.27-1.03c0.8-0.06,1.31,0.63,1.1,1.42
	C284.29,632.29,283.74,632.66,283.08,633.43z"
              />
              <path
                d="M286.79,626c0.47,0.85,1.02,1.4,0.95,1.83c-0.08,0.46-0.7,1.02-1.19,1.17c-0.64,0.19-1.45-0.79-1.18-1.41
	C285.58,627.09,286.12,626.73,286.79,626z"
              />
              <path
                d="M371.05,371.33c-0.95,0.58-1.48,1.16-2.01,1.16c-0.4,0-1.06-0.66-1.16-1.13c-0.18-0.79,0.4-1.43,1.22-1.3
	C369.65,370.15,370.13,370.71,371.05,371.33z"
              />
              <path
                d="M380.91,113.96c-0.76,0.54-1.19,1.09-1.68,1.14c-0.78,0.09-1.34-0.46-1.29-1.31c0.05-0.83,0.65-1.33,1.42-1.15
	C379.85,112.75,380.22,113.34,380.91,113.96z"
              />
              <path
                d="M512.97,344.54c-0.82,0.53-1.27,1.02-1.79,1.1c-0.84,0.12-1.37-0.44-1.31-1.31c0.06-0.87,0.67-1.35,1.48-1.13
	C511.85,343.34,512.23,343.9,512.97,344.54z"
              />
              <path
                d="M321.99,579.4c-0.65-0.73-1.23-1.1-1.36-1.59c-0.2-0.79,0.25-1.4,1.15-1.45c0.87-0.05,1.45,0.45,1.34,1.28
	C323.06,578.15,322.53,578.6,321.99,579.4z"
              />
              <path
                d="M433.22,257.69c-1.02,0.56-1.58,1.11-2.1,1.07c-0.42-0.03-1.06-0.71-1.13-1.17c-0.11-0.76,0.45-1.43,1.29-1.25
	C431.83,256.45,432.29,257.01,433.22,257.69z"
              />
              <path
                d="M344.75,259.65c-0.63-0.85-1.25-1.33-1.29-1.85c-0.03-0.4,0.63-1.1,1.09-1.21c0.8-0.19,1.39,0.41,1.29,1.22
	C345.79,258.32,345.3,258.77,344.75,259.65z"
              />
              <path
                d="M480.8,616.35c0.53,0.91,1.1,1.45,1.08,1.98c-0.01,0.39-0.76,1.02-1.23,1.07c-0.8,0.08-1.33-0.6-1.15-1.39
	C479.62,617.52,480.16,617.13,480.8,616.35z"
              />
              <path
                d="M309.72,190.5c-0.5-0.85-1.06-1.38-1-1.82c0.07-0.46,0.67-1.04,1.16-1.19c0.65-0.2,1.47,0.74,1.22,1.38
	C310.9,189.37,310.38,189.75,309.72,190.5z"
              />
              <path
                d="M426.44,328.49c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C427.65,327.11,427.05,327.6,426.44,328.49z"
              />
              <path
                d="M330.54,569.96c-1.03,0.6-1.56,1.16-2.08,1.14c-0.4-0.01-1.08-0.76-1.08-1.17c0.01-0.42,0.69-1.15,1.09-1.16
	C329,568.77,329.53,569.34,330.54,569.96z"
              />
              <path
                d="M440.95,619.82c-0.55-0.97-1.07-1.49-1.1-2.04c-0.05-0.85,0.69-1.32,1.45-1.08c0.47,0.15,1.11,0.86,1.07,1.25
	C442.31,618.48,441.67,618.94,440.95,619.82z"
              />
              <path
                d="M321.59,568.59c0.5,0.96,1.03,1.53,0.98,2.04c-0.04,0.38-0.8,0.93-1.28,0.98c-0.87,0.1-1.31-0.61-1.1-1.39
	C320.32,569.74,320.88,569.38,321.59,568.59z"
              />
              <path
                d="M416.69,632.77c-0.54-0.98-1.02-1.5-1.07-2.06c-0.06-0.68,0.5-1.12,1.25-1.1c0.85,0.02,1.43,0.52,1.26,1.32
	C418.02,631.46,417.41,631.89,416.69,632.77z"
              />
              <path
                d="M344.37,82.82c0.48,0.85,1.03,1.4,0.95,1.83c-0.08,0.46-0.69,1.02-1.18,1.17c-0.64,0.19-1.45-0.79-1.18-1.41
	C343.16,83.91,343.7,83.55,344.37,82.82z"
              />
              <path
                d="M292.35,630.83c-0.91,0.63-1.41,1.22-1.94,1.25c-0.4,0.02-1.09-0.6-1.22-1.06c-0.23-0.78,0.32-1.45,1.14-1.37
	C290.89,629.71,291.4,630.25,292.35,630.83z"
              />
              <path
                d="M377.61,363.67c-1.07,0.59-1.63,1.15-2.15,1.12c-0.41-0.03-1.09-0.79-1.07-1.21c0.01-0.42,0.73-1.14,1.15-1.15
	C376.05,362.43,376.58,363.02,377.61,363.67z"
              />
              <path
                d="M433.66,324.17c0.59,0.8,1.09,1.22,1.24,1.73c0.19,0.68-0.67,1.53-1.31,1.27c-0.47-0.19-1.03-0.82-1.06-1.28
	C432.5,325.44,433.1,324.96,433.66,324.17z"
              />
              <path
                d="M285.42,346.06c-0.71,0.6-1.1,1.17-1.58,1.27c-0.79,0.16-1.36-0.37-1.38-1.2c-0.02-0.85,0.55-1.37,1.33-1.26
	C284.28,344.94,284.69,345.5,285.42,346.06z"
              />
              <path
                d="M316.09,571.27c-1.01,0.55-1.56,1.1-2.09,1.06c-0.38-0.03-1-0.84-0.97-1.26c0.03-0.43,0.75-1.16,1.12-1.13
	C314.68,569.98,315.16,570.59,316.09,571.27z"
              />
              <path
                d="M291.68,457.61c-1,0.64-1.52,1.21-2.04,1.21c-0.4,0-1.1-0.71-1.11-1.12c-0.01-0.42,0.65-1.18,1.04-1.2
	C290.09,456.47,290.63,457.02,291.68,457.61z"
              />
              <path
                d="M487.86,619.29c-0.61-0.86-1.15-1.29-1.21-1.78c-0.11-0.8,0.44-1.45,1.28-1.27c0.48,0.11,1.17,0.75,1.16,1.14
	C489.09,617.89,488.48,618.4,487.86,619.29z"
              />
              <path
                d="M291.61,624.1c-1.01,0.53-1.58,1.06-2.1,1.02c-0.38-0.03-0.98-0.86-0.94-1.29c0.04-0.43,0.78-1.15,1.14-1.11
	C290.23,622.77,290.7,623.39,291.61,624.1z"
              />
              <path
                d="M375.22,278.64c-0.54-0.96-1.07-1.48-1.1-2.03c-0.05-0.84,0.68-1.31,1.44-1.07c0.47,0.15,1.1,0.85,1.06,1.24
	C376.56,277.3,375.92,277.76,375.22,278.64z"
              />
              <path
                d="M629.66,629.06c1.23,0.22,1.77,0.76,1.49,1.55c-0.16,0.45-0.9,1.01-1.33,0.97c-0.44-0.04-1.17-0.78-1.13-1.16
	C628.74,629.89,629.38,629.42,629.66,629.06z"
              />
              <path
                d="M352.69,369.35c-0.76,0.58-1.17,1.11-1.67,1.23c-0.82,0.19-1.39-0.34-1.4-1.19c-0.01-0.84,0.53-1.4,1.37-1.23
	C351.49,368.26,351.91,368.78,352.69,369.35z"
              />
              <path
                d="M324.39,315.48c-0.64-0.73-1.21-1.1-1.34-1.58c-0.2-0.78,0.24-1.39,1.13-1.43c0.87-0.05,1.43,0.45,1.33,1.27
	C325.45,314.24,324.93,314.68,324.39,315.48z"
              />
              <path
                d="M363.12,364.74c-0.95,0.66-1.45,1.26-1.97,1.28c-0.37,0.02-1.07-0.73-1.09-1.16c-0.02-0.43,0.62-1.22,1-1.24
	C361.58,363.6,362.12,364.16,363.12,364.74z"
              />
              <path
                d="M464.47,410.27c-0.8,0.53-1.24,1.01-1.74,1.09c-0.83,0.13-1.35-0.43-1.3-1.27c0.05-0.84,0.64-1.34,1.44-1.13
	C463.36,409.09,463.73,409.64,464.47,410.27z"
              />
              <path
                d="M446.72,621.25c-0.76,0.59-1.15,1.11-1.65,1.23c-0.81,0.19-1.39-0.33-1.4-1.17c-0.01-0.83,0.52-1.39,1.35-1.23
	C445.52,620.18,445.94,620.69,446.72,621.25z"
              />
              <path
                d="M261.91,200.33c-0.76,0.54-1.19,1.09-1.68,1.14c-0.78,0.09-1.34-0.46-1.29-1.31c0.05-0.83,0.65-1.33,1.42-1.15
	C260.85,199.12,261.22,199.71,261.91,200.33z"
              />
              <path
                d="M455.52,408.8c0.61,0.86,1.13,1.29,1.2,1.78c0.12,0.81-0.43,1.44-1.26,1.25c-0.47-0.11-1.17-0.74-1.16-1.12
	C454.31,410.2,454.91,409.7,455.52,408.8z"
              />
              <path
                d="M281.55,422.29c-0.95,0.68-1.44,1.28-1.96,1.32c-0.37,0.02-1.08-0.72-1.11-1.14c-0.02-0.42,0.61-1.23,0.99-1.25
	C279.99,421.18,280.54,421.73,281.55,422.29z"
              />
              <path
                d="M347.31,78.66c0.6,0.91,1.19,1.42,1.19,1.94c0,0.39-0.71,1.01-1.19,1.11c-0.85,0.18-1.39-0.47-1.25-1.28
	C346.14,79.93,346.68,79.51,347.31,78.66z"
              />
              <path
                d="M463.94,628.57c0.58,0.91,1.15,1.43,1.14,1.94c0,0.38-0.71,0.98-1.18,1.08c-0.84,0.16-1.35-0.48-1.2-1.28
	C462.79,629.82,463.31,629.41,463.94,628.57z"
              />
              <path
                d="M244.24,411.07c-0.76,0.6-1.15,1.14-1.65,1.26c-0.81,0.2-1.4-0.31-1.42-1.17c-0.02-0.84,0.5-1.41,1.34-1.25
	C243.02,410,243.45,410.51,244.24,411.07z"
              />
              <path
                d="M297.47,465.97c-0.51-0.94-1.06-1.5-1.02-2.03c0.03-0.39,0.79-0.99,1.27-1.03c0.8-0.06,1.31,0.63,1.1,1.42
	C298.68,464.82,298.13,465.2,297.47,465.97z"
              />
              <path
                d="M442.14,175.49c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C443.32,174.02,442.74,174.54,442.14,175.49z"
              />
              <path
                d="M490.81,605.04c0.28,0.33,0.84,0.72,0.96,1.21c0.19,0.81-0.3,1.37-1.19,1.41c-0.74,0.03-1.24-0.34-1.33-1.09
	C489.15,605.71,489.65,605.27,490.81,605.04z"
              />
              <path
                d="M245.09,421.81c0.89-0.67,1.34-1.25,1.88-1.37c0.81-0.18,1.42,0.44,1.29,1.23c-0.08,0.47-0.7,1.17-1.1,1.2
	C246.64,422.9,246.08,422.36,245.09,421.81z"
              />
              <path
                d="M486.64,621.1c-0.8,0.69-1.17,1.26-1.67,1.4c-0.8,0.22-1.52-0.25-1.45-1.11c0.04-0.48,0.63-1.24,1.03-1.28
	C485.09,620.06,485.67,620.6,486.64,621.1z"
              />
              <path
                d="M247.79,185.08c-0.54-0.98-1.02-1.5-1.07-2.06c-0.06-0.68,0.5-1.12,1.25-1.1c0.85,0.02,1.43,0.52,1.26,1.32
	C249.12,183.77,248.5,184.2,247.79,185.08z"
              />
              <path
                d="M357.69,280.2c0.59,0.87,1.12,1.32,1.17,1.81c0.09,0.81-0.49,1.44-1.32,1.23c-0.47-0.12-1.16-0.78-1.14-1.16
	C356.43,281.56,357.04,281.08,357.69,280.2z"
              />
              <path
                d="M271.99,314.94c-0.74,0.57-1.14,1.13-1.63,1.21c-0.79,0.13-1.35-0.42-1.33-1.26c0.01-0.84,0.6-1.36,1.38-1.21
	C270.88,313.78,271.27,314.35,271.99,314.94z"
              />
              <path
                d="M239.32,353.85c-0.99,0.58-1.54,1.15-2.06,1.13c-0.38-0.01-1.02-0.8-1.01-1.23c0.01-0.43,0.71-1.18,1.08-1.16
	C237.86,352.6,238.37,353.19,239.32,353.85z"
              />
              <path
                d="M398.67,132.24c-0.62-0.84-1.15-1.25-1.23-1.73c-0.14-0.79,0.38-1.44,1.21-1.28c0.47,0.09,1.17,0.7,1.18,1.09
	C399.83,130.82,399.25,131.33,398.67,132.24z"
              />
              <path
                d="M371.76,299c-0.95,0.66-1.45,1.26-1.97,1.28c-0.37,0.02-1.07-0.73-1.09-1.16c-0.02-0.43,0.62-1.22,1-1.24
	C370.22,297.87,370.76,298.42,371.76,299z"
              />
              <path
                d="M437.81,619.88c0.6,0.95,1.17,1.47,1.17,1.99c0,0.4-0.69,1.01-1.17,1.12c-0.8,0.19-1.44-0.4-1.33-1.2
	C436.56,621.25,437.15,620.79,437.81,619.88z"
              />
              <path
                d="M412.19,140.16c-0.83,0.51-1.28,1-1.78,1.06c-0.84,0.11-1.35-0.46-1.28-1.31c0.07-0.86,0.68-1.33,1.48-1.1
	C411.09,138.94,411.45,139.51,412.19,140.16z"
              />
              <path
                d="M233.55,369.09c-1,0.5-1.59,1.03-2.11,0.98c-0.4-0.04-1-0.8-1.03-1.27c-0.05-0.83,0.63-1.35,1.45-1.11
	C232.36,367.82,232.73,368.38,233.55,369.09z"
              />
              <path
                d="M478.27,607.46c-0.92,0.7-1.39,1.32-1.92,1.37c-0.37,0.03-1.1-0.69-1.14-1.12c-0.03-0.42,0.58-1.24,0.95-1.28
	C476.69,606.39,477.26,606.92,478.27,607.46z"
              />
              <path
                d="M465.86,610.33c0.58,0.92,1.16,1.44,1.15,1.95c0,0.38-0.71,0.99-1.19,1.08c-0.85,0.17-1.37-0.48-1.22-1.29
	C464.69,611.58,465.22,611.17,465.86,610.33z"
              />
              <path
                d="M343.93,202.47c0.28,0.32,0.84,0.69,0.96,1.17c0.21,0.79-0.26,1.48-1.12,1.37c-0.48-0.06-1.15-0.56-1.3-1.01
	C342.22,203.21,342.8,202.72,343.93,202.47z"
              />
              <path
                d="M472.11,622.58c-0.81,0.55-1.24,1.04-1.76,1.15c-0.66,0.15-1.49-0.81-1.2-1.4c0.23-0.47,0.86-1.01,1.33-1.02
	C470.91,621.3,471.36,621.95,472.11,622.58z"
              />
              <path
                d="M521.57,302.15c-0.54-0.96-1.07-1.48-1.1-2.03c-0.05-0.84,0.68-1.31,1.44-1.07c0.47,0.15,1.1,0.85,1.06,1.24
	C522.91,300.82,522.27,301.27,521.57,302.15z"
              />
              <path
                d="M301.95,183.79c-0.58-0.81-1.08-1.23-1.22-1.75c-0.19-0.68,0.62-1.4,1.34-1.29c0.73,0.11,1.23,0.6,1.06,1.31
	C303.01,182.57,302.51,182.99,301.95,183.79z"
              />
              <path
                d="M431.26,627.9c0.59,0.8,1.09,1.22,1.24,1.73c0.19,0.68-0.67,1.53-1.31,1.27c-0.47-0.19-1.03-0.82-1.06-1.28
	C430.1,629.18,430.7,628.7,431.26,627.9z"
              />
              <path
                d="M453.67,351.24c-0.5-0.85-1.06-1.38-1-1.82c0.07-0.46,0.67-1.04,1.16-1.19c0.65-0.2,1.47,0.74,1.22,1.38
	C454.85,350.12,454.33,350.49,453.67,351.24z"
              />
              <path
                d="M357.63,300.44c-1.01,0.53-1.58,1.08-2.11,1.04c-0.41-0.03-1.03-0.74-1.1-1.22c-0.12-0.8,0.51-1.41,1.32-1.22
	C356.3,299.17,356.74,299.75,357.63,300.44z"
              />
              <path
                d="M459.41,415.89c-0.66-0.91-1.26-1.37-1.34-1.92c-0.12-0.81,0.52-1.41,1.33-1.21c0.49,0.12,1.18,0.73,1.19,1.13
	C460.59,414.41,460.01,414.94,459.41,415.89z"
              />
              <path
                d="M457.04,616.82c-0.82,0.55-1.26,1.03-1.78,1.16c-0.66,0.17-1.45-0.76-1.17-1.41c0.21-0.46,0.85-1.01,1.3-1.01
	C455.83,615.56,456.28,616.2,457.04,616.82z"
              />
              <path
                d="M293.81,459.17c0.61,0.86,1.15,1.29,1.22,1.79c0.12,0.81-0.44,1.45-1.28,1.26c-0.48-0.11-1.18-0.75-1.17-1.13
	C292.6,460.57,293.2,460.07,293.81,459.17z"
              />
              <path
                d="M458.67,610.8c0.58,0.92,1.15,1.45,1.14,1.96c-0.01,0.38-0.72,0.99-1.2,1.08c-0.85,0.16-1.37-0.49-1.21-1.29
	C457.5,612.06,458.03,611.65,458.67,610.8z"
              />
              <path
                d="M303.66,78.99c-0.63-0.85-1.16-1.26-1.25-1.74c-0.14-0.8,0.38-1.46,1.22-1.29c0.48,0.09,1.19,0.71,1.19,1.09
	C304.83,77.56,304.25,78.08,303.66,78.99z"
              />
              <path
                d="M327.2,573.84c-1.05,0.59-1.61,1.14-2.13,1.11c-0.4-0.02-1.07-0.79-1.06-1.2c0.01-0.42,0.72-1.14,1.13-1.14
	C325.67,572.61,326.19,573.19,327.2,573.84z"
              />
              <path d="M609.92,200.86c0.31,0.89,0.62,1.78,0.97,2.77C608.72,203.49,608.47,202.95,609.92,200.86z" />
              <path
                d="M315.56,421.16c-0.71-0.88-1.33-1.32-1.43-1.85c-0.16-0.79,0.42-1.3,1.27-1.31c0.74-0.01,1.3,0.43,1.24,1.11
	C316.59,419.66,316.1,420.18,315.56,421.16z"
              />
              <path
                d="M309.46,246.5c-0.75,0.58-1.14,1.11-1.63,1.22c-0.81,0.19-1.37-0.33-1.38-1.17c-0.01-0.82,0.51-1.38,1.34-1.22
	C308.28,245.44,308.69,245.94,309.46,246.5z"
              />
              <path
                d="M519.87,316.66c0.6,0.88,1.14,1.32,1.19,1.82c0.09,0.81-0.5,1.45-1.34,1.24c-0.48-0.12-1.17-0.79-1.15-1.18
	C518.61,318.02,519.23,317.54,519.87,316.66z"
              />
              <path
                d="M499.88,379.83c0.59,0.8,1.08,1.22,1.23,1.74c0.19,0.69-0.67,1.52-1.32,1.26c-0.47-0.19-1.02-0.82-1.05-1.29
	C498.72,381.1,499.32,380.62,499.88,379.83z"
              />
              <path
                d="M319.5,587.29c0.28,0.33,0.84,0.72,0.96,1.21c0.19,0.81-0.3,1.37-1.19,1.41c-0.74,0.03-1.24-0.34-1.33-1.09
	C317.85,587.95,318.34,587.51,319.5,587.29z"
              />
              <path
                d="M324.5,584.82c-0.93,0.6-1.44,1.18-1.98,1.2c-0.4,0.01-1.07-0.63-1.19-1.09c-0.2-0.79,0.36-1.45,1.18-1.33
	C323.07,583.67,323.57,584.21,324.5,584.82z"
              />
              <path
                d="M322.6,416.97c0.6,0.86,1.12,1.28,1.19,1.76c0.12,0.8-0.42,1.43-1.25,1.25c-0.47-0.1-1.16-0.73-1.15-1.11
	C321.4,418.36,321.99,417.86,322.6,416.97z"
              />
              <path
                d="M477.41,241.38c0.23,0.28,0.79,0.66,0.91,1.15c0.19,0.8-0.31,1.48-1.16,1.36c-0.48-0.07-1.15-0.59-1.28-1.05
	C475.63,242.05,476.18,241.53,477.41,241.38z"
              />
              <path
                d="M362.64,317.88c-1,0.57-1.55,1.13-2.07,1.11c-0.38-0.02-1.02-0.81-1-1.24c0.02-0.43,0.72-1.17,1.09-1.15
	C361.2,316.62,361.69,317.22,362.64,317.88z"
              />
              <path
                d="M286.27,503.04c-0.53-0.84-1.02-1.29-1.08-1.79c-0.08-0.69,0.41-1.17,1.17-1.15c0.86,0.02,1.4,0.51,1.22,1.33
	C287.47,501.91,286.91,502.28,286.27,503.04z"
              />
              <path
                d="M485.09,606.9c-0.76,0.56-1.17,1.08-1.66,1.18c-0.82,0.18-1.36-0.37-1.36-1.19c0.01-0.81,0.54-1.37,1.37-1.19
	C483.93,605.8,484.33,606.32,485.09,606.9z"
              />
              <path
                d="M246.1,159.77c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C244.95,161.01,245.47,160.61,246.1,159.77z"
              />
              <path
                d="M372.3,365.62c0.57,0.92,1.14,1.44,1.13,1.95c-0.01,0.38-0.71,0.98-1.19,1.07c-0.84,0.16-1.35-0.49-1.2-1.29
	C371.15,366.86,371.67,366.46,372.3,365.62z"
              />
              <path
                d="M298.8,353.92c-0.6-0.86-1.11-1.28-1.18-1.76c-0.11-0.79,0.43-1.43,1.25-1.25c0.47,0.1,1.15,0.74,1.14,1.12
	C300.01,352.54,299.42,353.03,298.8,353.92z"
              />
              <path
                d="M507.59,366.8c-0.75,0.55-1.17,1.11-1.65,1.17c-0.78,0.1-1.34-0.44-1.31-1.28c0.03-0.84,0.62-1.35,1.4-1.18
	C506.51,365.62,506.89,366.2,507.59,366.8z"
              />
              <path
                d="M327.17,75.07c-0.92,0.7-1.38,1.31-1.9,1.36c-0.41,0.04-1.13-0.54-1.28-0.99c-0.25-0.75,0.22-1.48,1.06-1.43
	C325.61,74.04,326.14,74.53,327.17,75.07z"
              />
              <path
                d="M392.9,273.52c-0.57-0.79-1.07-1.21-1.2-1.72c-0.17-0.71,0.33-1.2,1.05-1.32c0.72-0.11,1.54,0.6,1.35,1.28
	C393.96,272.28,393.48,272.71,392.9,273.52z"
              />
              <path
                d="M284.47,418.61c-0.8,0.56-1.24,1.11-1.71,1.14c-0.78,0.06-1.41-0.54-1.19-1.34c0.12-0.45,0.82-0.99,1.28-1.01
	C283.26,417.39,283.71,418.01,284.47,418.61z"
              />
              <path
                d="M365.08,366.15c0.58,0.82,1.13,1.26,1.19,1.75c0.1,0.83-0.53,1.41-1.33,1.19c-0.45-0.12-1.01-0.79-1.03-1.24
	C363.89,367.42,364.49,366.95,365.08,366.15z"
              />
              <path
                d="M344.05,418.65c-0.54-0.86-1.06-1.32-1.09-1.81c-0.05-0.82,0.61-1.38,1.4-1.12c0.44,0.15,0.95,0.85,0.95,1.31
	C345.31,417.45,344.68,417.88,344.05,418.65z"
              />
              <path d="M390.24,4.3c0.89-0.31,1.81-0.72,2.3,0.49c0.08,0.21-0.51,0.96-0.87,1.03C390.66,5.99,390.02,5.56,390.24,4.3z" />
              <path
                d="M438.4,627.2c0.62,0.78,1.17,1.17,1.27,1.66c0.16,0.82-0.43,1.42-1.24,1.26c-0.45-0.09-1.05-0.72-1.1-1.17
	C437.28,628.53,437.86,628.03,438.4,627.2z"
              />
              <path
                d="M446.47,162.41c-0.82,0.55-1.25,1.01-1.77,1.14c-0.66,0.16-1.43-0.74-1.14-1.39c0.2-0.46,0.85-0.99,1.29-0.99
	C445.28,161.17,445.71,161.8,446.47,162.41z"
              />
              <path
                d="M386.15,317.47c0.59,0.79,1.14,1.21,1.22,1.71c0.13,0.83-0.52,1.41-1.3,1.25c-0.44-0.09-1.01-0.79-1.04-1.25
	C385,318.74,385.6,318.26,386.15,317.47z"
              />
              <path
                d="M259.96,444.15c0.58,0.79,1.13,1.22,1.2,1.72c0.12,0.83-0.53,1.41-1.31,1.23c-0.44-0.1-1-0.8-1.03-1.26
	C258.81,445.4,259.41,444.93,259.96,444.15z"
              />
              <path
                d="M459.36,246c0.54,0.78,1.12,1.26,1.09,1.69c-0.03,0.45-0.6,1.14-1.03,1.23c-0.77,0.16-1.41-0.42-1.28-1.24
	C458.22,247.19,458.77,246.77,459.36,246z"
              />
              <path
                d="M464.26,616.12c-0.86,0.53-1.31,0.97-1.83,1.07c-0.75,0.14-1.32-0.61-1.17-1.39c0.14-0.73,0.63-1.19,1.34-0.98
	C463.1,614.98,463.48,615.49,464.26,616.12z"
              />
              <path
                d="M373.09,107.52c-0.87,0.51-1.32,0.96-1.84,1.05c-0.74,0.13-1.3-0.63-1.15-1.4c0.15-0.73,0.64-1.19,1.35-0.96
	C371.94,106.36,372.32,106.88,373.09,107.52z"
              />
              <path
                d="M454.17,248.57c-0.85,0.52-1.31,1.01-1.81,1.06c-0.86,0.09-1.26-0.54-1.15-1.37c0.1-0.72,0.61-1.19,1.31-0.97
	C453.02,247.44,453.4,247.94,454.17,248.57z"
              />
              <path
                d="M368.34,362.33c0.62,0.78,1.17,1.17,1.27,1.66c0.16,0.82-0.43,1.42-1.24,1.26c-0.45-0.09-1.05-0.72-1.1-1.17
	C367.22,363.66,367.8,363.16,368.34,362.33z"
              />
              <path
                d="M471.45,615.69c-0.88,0.5-1.34,0.93-1.85,1.02c-0.74,0.12-1.29-0.65-1.13-1.42c0.16-0.73,0.66-1.17,1.36-0.94
	C470.32,614.52,470.69,615.04,471.45,615.69z"
              />
              <path
                d="M342.56,420.65c-0.78,0.6-1.23,1.21-1.65,1.2c-0.46-0.02-1.15-0.54-1.28-0.98c-0.23-0.79,0.35-1.41,1.17-1.33
	C341.29,419.59,341.73,420.1,342.56,420.65z"
              />
              <path
                d="M455.56,369.31c0.66,0.73,1.19,1.09,1.39,1.58c0.25,0.62-0.55,1.58-1.19,1.38c-0.48-0.15-1.08-0.71-1.16-1.17
	C454.54,370.68,455.09,370.15,455.56,369.31z"
              />
              <path
                d="M350.4,324.12c-0.59-0.82-1.14-1.25-1.2-1.74c-0.1-0.82,0.51-1.42,1.32-1.2c0.45,0.12,1.01,0.79,1.04,1.24
	C351.58,322.85,350.98,323.32,350.4,324.12z"
              />
              <path
                d="M447.42,628.19c-0.76,0.6-1.16,1.1-1.67,1.27c-0.66,0.22-1.48-0.59-1.25-1.28c0.16-0.48,0.75-1.09,1.19-1.12
	C446.14,627.03,446.63,627.64,447.42,628.19z"
              />
              <path
                d="M518.17,322.7c-0.91,0.4-1.48,0.89-1.9,0.78c-0.44-0.12-1.01-0.79-1.03-1.25c-0.04-0.83,0.68-1.31,1.46-1.04
	C517.17,321.35,517.48,321.97,518.17,322.7z"
              />
              <path
                d="M356.3,80.14c-0.86,0.53-1.31,0.97-1.83,1.07c-0.74,0.14-1.31-0.62-1.17-1.39c0.14-0.73,0.63-1.19,1.34-0.98
	C355.13,79.01,355.52,79.52,356.3,80.14z"
              />
              <path
                d="M450.51,165.74c-0.8,0.56-1.22,1.04-1.73,1.17c-0.66,0.16-1.49-0.77-1.21-1.37c0.22-0.47,0.84-1.01,1.31-1.03
	C449.3,164.5,449.75,165.14,450.51,165.74z"
              />
              <path
                d="M442.65,159.23c-0.82,0.56-1.29,1.14-1.73,1.11c-0.44-0.03-1.1-0.61-1.2-1.05c-0.17-0.78,0.37-1.42,1.22-1.26
	C441.44,158.12,441.86,158.64,442.65,159.23z"
              />
              <path
                d="M495.89,253.58c-0.63-0.78-1.2-1.18-1.29-1.66c-0.16-0.82,0.43-1.44,1.24-1.28c0.46,0.09,1.06,0.73,1.12,1.18
	C497.01,252.24,496.43,252.74,495.89,253.58z"
              />
              <path
                d="M370.98,287.9c0.77-0.62,1.16-1.17,1.64-1.27c0.82-0.17,1.44,0.41,1.3,1.21c-0.08,0.45-0.73,1.04-1.18,1.11
	C372.32,289,371.82,288.43,370.98,287.9z"
              />
              <path
                d="M450.81,252.24c-0.78,0.59-1.18,1.08-1.68,1.22c-0.71,0.2-1.21-0.27-1.28-1c-0.08-0.83,0.34-1.45,1.19-1.34
	C449.54,251.17,449.98,251.68,450.81,252.24z"
              />
              <path
                d="M310.35,566.05c0.58,0.78,1.13,1.19,1.2,1.69c0.13,0.82-0.51,1.4-1.28,1.23c-0.43-0.09-1-0.79-1.03-1.24
	C309.22,567.3,309.8,566.82,310.35,566.05z"
              />
              <path
                d="M371.88,362.03c-0.62-0.78-1.25-1.21-1.24-1.63c0.01-0.46,0.52-1.16,0.96-1.3c0.8-0.26,1.44,0.31,1.39,1.13
	C372.95,360.72,372.43,361.17,371.88,362.03z"
              />
              <path
                d="M287.68,494.24c-0.86,0.52-1.32,1.01-1.82,1.06c-0.86,0.09-1.27-0.54-1.16-1.38c0.1-0.73,0.62-1.19,1.32-0.97
	C286.52,493.11,286.9,493.62,287.68,494.24z"
              />
              <path
                d="M468.28,619.3c-0.78,0.6-1.23,1.21-1.65,1.2c-0.46-0.02-1.15-0.54-1.28-0.98c-0.23-0.79,0.35-1.41,1.17-1.33
	C467.01,618.24,467.45,618.75,468.28,619.3z"
              />
              <path
                d="M351.42,82.09c0.56,0.83,1.15,1.31,1.11,1.74c-0.04,0.45-0.64,1.09-1.09,1.19c-0.81,0.18-1.42-0.41-1.27-1.25
	C350.25,83.29,350.8,82.89,351.42,82.09z"
              />
              <path
                d="M272.99,408.77c-0.82,0.55-1.31,1.13-1.73,1.09c-0.46-0.05-1.12-0.62-1.21-1.07c-0.17-0.8,0.44-1.39,1.26-1.24
	C271.79,407.63,272.2,408.17,272.99,408.77z"
              />
              <path
                d="M478.62,614.91c-0.82,0.54-1.26,1-1.78,1.13c-0.66,0.16-1.42-0.76-1.13-1.4c0.2-0.46,0.85-0.99,1.3-0.98
	C477.44,613.66,477.87,614.3,478.62,614.91z"
              />
              <path
                d="M505.16,357.78c0.58,0.79,1.13,1.22,1.2,1.72c0.12,0.83-0.53,1.41-1.31,1.23c-0.44-0.1-1-0.8-1.03-1.26
	C504.01,359.03,504.61,358.56,505.16,357.78z"
              />
              <path
                d="M313.66,562.23c0.46,0.83,1,1.36,0.93,1.79c-0.08,0.45-0.71,1.09-1.15,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C312.4,563.3,312.99,562.94,313.66,562.23z"
              />
              <path
                d="M309.58,226.52c0.6,0.79,1.21,1.25,1.19,1.68c-0.02,0.45-0.57,1.13-1.01,1.25c-0.8,0.23-1.44-0.35-1.35-1.17
	C308.46,227.79,309,227.35,309.58,226.52z"
              />
              <path
                d="M440.23,256.94c-0.82,0.54-1.25,1.01-1.77,1.14c-0.65,0.16-1.43-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.99
	C439.04,255.7,439.48,256.33,440.23,256.94z"
              />
              <path
                d="M454.35,627.66c-0.75,0.63-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.42-1.31-1.23c0.09-0.47,0.72-1.09,1.18-1.15
	C453.02,626.52,453.53,627.12,454.35,627.66z"
              />
              <path
                d="M456.69,632.66c-0.57-0.81-1.17-1.29-1.14-1.71c0.04-0.45,0.61-1.12,1.06-1.23c0.81-0.2,1.42,0.4,1.3,1.22
	C457.85,631.43,457.29,631.85,456.69,632.66z"
              />
              <path
                d="M236.02,357.7c-0.81,0.56-1.23,1.03-1.74,1.15c-0.66,0.15-1.48-0.79-1.19-1.38c0.23-0.47,0.85-1,1.32-1.01
	C234.82,356.44,235.26,357.08,236.02,357.7z"
              />
              <path
                d="M445.71,195.02c-0.81,0.56-1.23,1.04-1.74,1.16c-0.66,0.16-1.48-0.78-1.2-1.37c0.23-0.47,0.84-1,1.31-1.02
	C444.5,193.77,444.95,194.41,445.71,195.02z"
              />
              <path
                d="M296.36,491.33c-0.66-0.73-1.18-1.1-1.39-1.59c-0.26-0.62,0.54-1.57,1.19-1.37c0.48,0.14,1.09,0.71,1.16,1.16
	C297.39,489.96,296.84,490.49,296.36,491.33z"
              />
              <path
                d="M624.49,631.21c-0.81,0.55-1.25,1.01-1.77,1.15c-0.65,0.17-1.43-0.75-1.15-1.39c0.2-0.46,0.84-1,1.29-1
	C623.29,629.97,623.73,630.6,624.49,631.21z"
              />
              <path
                d="M361.07,177.09c-0.86,0.51-1.32,0.99-1.82,1.04c-0.86,0.08-1.25-0.55-1.13-1.38c0.11-0.73,0.62-1.18,1.32-0.95
	C359.94,175.95,360.32,176.46,361.07,177.09z"
              />
              <path
                d="M519.19,313.09c-0.62-0.78-1.25-1.21-1.24-1.63c0.01-0.46,0.52-1.16,0.96-1.3c0.8-0.26,1.44,0.31,1.39,1.13
	C520.27,311.77,519.74,312.23,519.19,313.09z"
              />
              <path
                d="M427.21,165.87c0.54,0.78,1.12,1.26,1.09,1.69c-0.03,0.45-0.6,1.14-1.03,1.23c-0.77,0.16-1.41-0.42-1.28-1.24
	C426.07,167.06,426.62,166.64,427.21,165.87z"
              />
              <path
                d="M447.78,631.7c0.75-0.62,1.19-1.27,1.61-1.25c0.47,0.01,1.09,0.54,1.32,1c0.29,0.59-0.52,1.54-1.18,1.39
	C449.02,632.72,448.59,632.25,447.78,631.7z"
              />
              <path
                d="M285.88,618.83c0.58,0.78,1.13,1.21,1.2,1.7c0.12,0.82-0.53,1.39-1.3,1.22c-0.43-0.1-0.99-0.79-1.01-1.24
	C284.73,620.07,285.33,619.61,285.88,618.83z"
              />
              <path
                d="M452.14,622.34c-0.58-0.79-1.13-1.22-1.21-1.72c-0.13-0.84,0.5-1.31,1.32-1.27c0.72,0.04,1.22,0.56,1.06,1.27
	C453.19,621.14,452.7,621.56,452.14,622.34z"
              />
              <path
                d="M415.98,143.4c-0.81,0.56-1.29,1.15-1.71,1.11c-0.45-0.04-1.12-0.61-1.22-1.06c-0.18-0.8,0.42-1.4,1.24-1.26
	C414.78,142.28,415.19,142.81,415.98,143.4z"
              />
              <path
                d="M303.47,261.75c-0.82,0.54-1.26,1-1.78,1.13c-0.66,0.16-1.42-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.98
	C302.29,260.5,302.72,261.14,303.47,261.75z"
              />
              <path
                d="M461.59,627.05c-0.81,0.57-1.29,1.17-1.72,1.14c-0.46-0.03-1.14-0.6-1.25-1.04c-0.19-0.81,0.4-1.41,1.24-1.28
	C460.35,625.94,460.78,626.47,461.59,627.05z"
              />
              <path
                d="M304.79,524.93c0.78-0.62,1.17-1.16,1.65-1.26c0.83-0.18,1.43,0.41,1.29,1.21c-0.08,0.45-0.72,1.05-1.17,1.11
	C306.13,526.03,305.63,525.46,304.79,524.93z"
              />
              <path
                d="M275.79,381.22c-0.57-0.81-1.17-1.29-1.14-1.71c0.04-0.45,0.61-1.12,1.06-1.23c0.81-0.2,1.42,0.4,1.3,1.22
	C276.94,379.99,276.39,380.41,275.79,381.22z"
              />
              <path
                d="M308.73,425.47c0.66,0.72,1.26,1.08,1.38,1.56c0.2,0.82-0.42,1.43-1.18,1.36c-0.44-0.04-1.06-0.69-1.14-1.14
	C307.72,426.83,308.26,426.3,308.73,425.47z"
              />
              <path
                d="M287.2,447.15c-0.84,0.56-1.33,1.14-1.77,1.1c-0.45-0.04-1.11-0.63-1.2-1.08c-0.16-0.79,0.39-1.43,1.26-1.25
	C285.99,446.03,286.4,446.55,287.2,447.15z"
              />
              <path
                d="M239.64,400.89c-0.81,0.54-1.26,1.08-1.75,1.12c-0.81,0.07-1.37-0.59-1.13-1.37c0.13-0.44,0.82-0.98,1.26-0.98
	C238.46,399.66,238.89,400.29,239.64,400.89z"
              />
              <path
                d="M253.76,439.08c-0.74,0.63-1.13,1.21-1.6,1.3c-0.78,0.15-1.44-0.41-1.3-1.22c0.08-0.46,0.72-1.07,1.17-1.14
	C252.44,437.96,252.95,438.55,253.76,439.08z"
              />
              <path
                d="M403.52,332.47c-0.83,0.55-1.32,1.14-1.75,1.1c-0.46-0.05-1.13-0.63-1.22-1.08c-0.17-0.81,0.44-1.4,1.27-1.25
	C402.32,331.33,402.73,331.87,403.52,332.47z"
              />
              <path
                d="M454.83,381.99c-0.75,0.63-1.14,1.22-1.62,1.3c-0.79,0.14-1.46-0.43-1.3-1.24c0.09-0.46,0.73-1.08,1.19-1.14
	C453.51,380.85,454.02,381.45,454.83,381.99z"
              />
              <path
                d="M511.4,266.76c-0.55-0.77-1.03-1.19-1.15-1.69c-0.17-0.7,0.32-1.22,1.04-1.26c0.81-0.04,1.44,0.42,1.31,1.25
	C512.52,265.55,511.98,265.98,511.4,266.76z"
              />
              <path
                d="M317.87,553c-0.82,0.54-1.25,1.01-1.77,1.14c-0.65,0.16-1.43-0.75-1.14-1.4c0.2-0.46,0.85-0.99,1.29-0.99
	C316.68,551.76,317.12,552.39,317.87,553z"
              />
              <path
                d="M419.31,266.03c-0.8,0.56-1.24,1.11-1.71,1.14c-0.78,0.05-1.41-0.54-1.19-1.34c0.12-0.45,0.82-0.98,1.28-1.01
	C418.1,264.8,418.55,265.43,419.31,266.03z"
              />
              <path
                d="M347.86,398.69c-0.83,0.55-1.32,1.14-1.75,1.1c-0.46-0.05-1.13-0.63-1.22-1.08c-0.17-0.81,0.44-1.4,1.27-1.25
	C346.66,397.55,347.06,398.09,347.86,398.69z"
              />
              <path
                d="M315.2,398.04c-0.91,0.4-1.48,0.89-1.9,0.77c-0.44-0.12-1.01-0.79-1.03-1.25c-0.04-0.83,0.68-1.31,1.46-1.04
	C314.2,396.68,314.51,397.3,315.2,398.04z"
              />
              <path
                d="M507.99,346.78c0.46,0.83,1,1.36,0.93,1.78c-0.08,0.45-0.71,1.09-1.14,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C506.73,347.85,507.33,347.49,507.99,346.78z"
              />
              <path
                d="M362.21,397.33c-0.75,0.64-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.42-1.31-1.23c0.08-0.47,0.72-1.09,1.18-1.15
	C360.89,396.2,361.4,396.8,362.21,397.33z"
              />
              <path
                d="M350.96,268.46c-0.81,0.54-1.26,1.08-1.75,1.11c-0.81,0.06-1.36-0.59-1.13-1.37c0.13-0.44,0.83-0.98,1.27-0.98
	C349.78,267.23,350.22,267.85,350.96,268.46z"
              />
              <path
                d="M515.67,266.62c0.47,0.84,1.02,1.38,0.94,1.81c-0.08,0.46-0.68,1.02-1.17,1.16c-0.64,0.19-1.44-0.78-1.18-1.39
	C514.47,267.71,515,267.35,515.67,266.62z"
              />
              <path
                d="M438.21,172.01c-0.59-0.82-1.14-1.25-1.2-1.74c-0.1-0.82,0.51-1.42,1.32-1.2c0.45,0.12,1.01,0.79,1.04,1.24
	C439.39,170.74,438.79,171.21,438.21,172.01z"
              />
              <path
                d="M464.92,623.11c-0.77,0.62-1.21,1.24-1.63,1.23c-0.46-0.01-1.16-0.52-1.3-0.96c-0.24-0.79,0.33-1.42,1.15-1.35
	C463.63,622.07,464.08,622.57,464.92,623.11z"
              />
              <path
                d="M498.49,385.51c-0.8,0.56-1.22,1.04-1.73,1.17c-0.66,0.16-1.49-0.77-1.2-1.37c0.22-0.47,0.84-1.01,1.31-1.03
	C497.28,384.26,497.73,384.9,498.49,385.51z"
              />
              <path
                d="M342.81,215.19c-0.9,0.41-1.41,0.84-1.91,0.83c-0.88-0.01-1.26-0.75-0.94-1.48c0.19-0.41,0.95-0.85,1.39-0.79
	C341.78,213.81,342.13,214.48,342.81,215.19z"
              />
              <path
                d="M457.7,624c-0.8,0.55-1.25,1.1-1.72,1.13c-0.77,0.05-1.41-0.55-1.18-1.35c0.13-0.44,0.83-0.98,1.29-1
	C456.5,622.76,456.94,623.39,457.7,624z"
              />
              <path
                d="M301.66,465.78c0.46,0.83,1,1.36,0.93,1.78c-0.08,0.45-0.71,1.09-1.14,1.13c-0.76,0.07-1.38-0.55-1.17-1.36
	C300.4,466.85,300.99,466.49,301.66,465.78z"
              />
              <path
                d="M459.46,209.91c-0.52-0.85-1.09-1.36-1.03-1.78c0.07-0.45,0.68-1.08,1.14-1.16c0.82-0.14,1.39,0.49,1.21,1.31
	C460.68,208.76,460.11,209.14,459.46,209.91z"
              />
              <path
                d="M519.2,273.25c-0.54-0.86-1.06-1.31-1.09-1.8c-0.04-0.81,0.6-1.38,1.39-1.11c0.43,0.14,0.94,0.85,0.94,1.3
	C520.44,272.05,519.82,272.48,519.2,273.25z"
              />
              <path
                d="M450.51,624.29c-0.75,0.64-1.13,1.22-1.61,1.31c-0.79,0.15-1.46-0.41-1.31-1.23c0.08-0.47,0.72-1.09,1.18-1.15
	C449.18,623.16,449.69,623.76,450.51,624.29z"
              />
              <path
                d="M453.6,391.06c-0.59-0.79-1.08-1.2-1.23-1.72c-0.2-0.67,0.59-1.38,1.32-1.29c0.72,0.09,1.23,0.57,1.06,1.28
	C454.63,389.84,454.14,390.27,453.6,391.06z"
              />
              <path
                d="M520.39,304.06c-0.78,0.59-1.18,1.08-1.68,1.22c-0.71,0.2-1.21-0.27-1.28-1c-0.08-0.83,0.34-1.45,1.19-1.34
	C519.12,302.99,519.56,303.51,520.39,304.06z"
              />
              <path
                d="M481.97,610.91c-0.76,0.61-1.16,1.11-1.66,1.28c-0.66,0.22-1.48-0.58-1.25-1.27c0.16-0.48,0.75-1.09,1.19-1.13
	C480.68,609.75,481.17,610.36,481.97,610.91z"
              />
              <path
                d="M311.74,540.62c0.47,0.84,1.01,1.37,0.94,1.8c-0.08,0.45-0.68,1.01-1.16,1.16c-0.63,0.19-1.43-0.77-1.18-1.39
	C310.55,541.7,311.07,541.34,311.74,540.62z"
              />
              <path
                d="M305.48,472.62c-0.66-0.73-1.18-1.1-1.39-1.59c-0.26-0.62,0.54-1.57,1.19-1.37c0.48,0.14,1.09,0.71,1.16,1.16
	C306.51,471.25,305.96,471.78,305.48,472.62z"
              />
              <path
                d="M376.72,273.03c0.78-0.63,1.17-1.18,1.66-1.28c0.83-0.18,1.45,0.41,1.31,1.21c-0.08,0.45-0.73,1.05-1.19,1.12
	C378.07,274.13,377.57,273.56,376.72,273.03z"
              />
              <path
                d="M394.82,125.3c0.58,0.81,1.12,1.24,1.17,1.72c0.1,0.81-0.51,1.39-1.31,1.17c-0.44-0.12-0.99-0.78-1.01-1.22
	C393.66,126.55,394.25,126.09,394.82,125.3z"
              />
              <path
                d="M286.41,273.89c-0.79,0.57-1.26,1.16-1.68,1.13c-0.45-0.03-1.12-0.59-1.23-1.03c-0.19-0.78,0.39-1.39,1.21-1.27
	C285.19,272.79,285.61,273.31,286.41,273.89z"
              />
              <path
                d="M375.65,96.25c-0.76,0.61-1.19,1.23-1.61,1.22c-0.45-0.01-1.15-0.52-1.29-0.95c-0.24-0.78,0.32-1.41,1.13-1.34
	C374.38,95.21,374.82,95.72,375.65,96.25z"
              />
              <path
                d="M419.78,147.09c-0.95,0.38-1.54,0.86-1.93,0.72c-0.43-0.15-0.66-0.86-0.97-1.32c0.47-0.32,0.96-0.9,1.41-0.87
	C418.7,145.66,419.06,146.35,419.78,147.09z"
              />
              <path
                d="M438.79,155.8c-0.77,0.58-1.17,1.07-1.66,1.21c-0.7,0.19-1.2-0.28-1.27-1c-0.08-0.82,0.34-1.44,1.19-1.33
	C437.54,154.73,437.98,155.25,438.79,155.8z"
              />
              <path
                d="M342.08,207.59c-0.82,0.61-1.29,1.22-1.7,1.18c-0.45-0.04-0.85-0.68-1.27-1.06c0.39-0.42,0.72-1.09,1.17-1.17
	C340.68,206.47,341.21,207.05,342.08,207.59z"
              />
              <path
                d="M310.19,214.03c-0.84,0.51-1.29,0.99-1.79,1.04c-0.85,0.08-1.25-0.54-1.13-1.36c0.1-0.72,0.61-1.17,1.3-0.96
	C309.07,212.9,309.44,213.41,310.19,214.03z"
              />
              <path
                d="M407.21,268.74c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C406.2,270.08,406.67,269.62,407.21,268.74z"
              />
              <path
                d="M512.41,337.13c-0.76,0.61-1.19,1.23-1.61,1.22c-0.45-0.01-1.15-0.52-1.29-0.95c-0.24-0.78,0.32-1.41,1.13-1.34
	C511.13,336.09,511.57,336.59,512.41,337.13z"
              />
              <path
                d="M601.35,634.55c-0.58-0.81-1.12-1.23-1.18-1.71c-0.09-0.8,0.5-1.4,1.3-1.18c0.44,0.12,0.98,0.78,1.01,1.23
	C602.51,633.3,601.92,633.76,601.35,634.55z"
              />
              <path
                d="M257.15,269.14c-0.86,0.55-1.38,1.13-1.78,1.06c-0.45-0.07-0.8-0.74-1.19-1.15c0.42-0.39,0.8-1.04,1.26-1.09
	C255.84,267.92,256.32,268.54,257.15,269.14z"
              />
              <path
                d="M457.52,204.94c-0.9,0.49-1.37,0.94-1.87,0.99c-0.75,0.07-1.11-0.53-1.05-1.25c0.06-0.73,0.53-1.24,1.25-1.04
	C456.34,203.78,456.73,204.3,457.52,204.94z"
              />
              <path
                d="M462.87,202.52c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C461.86,203.86,462.33,203.4,462.87,202.52z"
              />
              <path
                d="M353.96,280.45c-0.68-0.8-1.33-1.22-1.32-1.64c0.01-0.44,0.56-1.1,1.01-1.26c0.69-0.25,1.27,0.29,1.23,1.01
	C354.85,279.06,354.43,279.54,353.96,280.45z"
              />
              <path
                d="M518.3,278.7c0.84-0.59,1.33-1.19,1.74-1.14c0.45,0.06,0.83,0.72,1.24,1.11c-0.4,0.4-0.77,1.06-1.22,1.13
	C519.65,279.86,519.15,279.27,518.3,278.7z"
              />
              <path
                d="M435.04,631.02c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C434.03,632.35,434.5,631.9,435.04,631.02z"
              />
              <path
                d="M348.57,302.5c-0.62-0.77-1.17-1.16-1.26-1.64c-0.15-0.8,0.42-1.42,1.22-1.26c0.45,0.09,1.04,0.72,1.09,1.17
	C349.67,301.18,349.1,301.67,348.57,302.5z"
              />
              <path
                d="M249.29,429.02c-0.91,0.5-1.38,0.95-1.89,1c-0.76,0.08-1.12-0.52-1.06-1.25c0.06-0.73,0.53-1.25,1.26-1.05
	C248.09,427.85,248.48,428.37,249.29,429.02z"
              />
              <path
                d="M305.9,479.6c-0.51-0.91-0.96-1.37-1.01-1.87c-0.06-0.71,0.51-1.29,1.21-1.06c0.47,0.15,1.04,0.79,1.07,1.24
	C307.2,478.32,306.56,478.77,305.9,479.6z"
              />
              <path
                d="M311.01,201.08c0.59,0.84,1.1,1.26,1.19,1.76c0.13,0.7-0.37,1.33-1.1,1.17c-0.48-0.1-1.13-0.68-1.19-1.12
	C309.84,202.48,310.44,201.96,311.01,201.08z"
              />
              <path
                d="M392.7,124.03c-0.94,0.4-1.45,0.8-1.96,0.79c-0.74-0.01-1.04-0.64-0.91-1.36c0.14-0.73,0.67-1.16,1.36-0.9
	C391.67,122.75,391.99,123.31,392.7,124.03z"
              />
              <path
                d="M444.07,632.37c-0.81,0.54-1.29,1.12-1.73,1.08c-0.44-0.04-1.08-0.63-1.17-1.07c-0.16-0.78,0.38-1.41,1.23-1.23
	C442.89,631.26,443.29,631.79,444.07,632.37z"
              />
              <path
                d="M308.81,222.84c-0.52-0.83-1.09-1.33-1.03-1.75c0.06-0.44,0.66-1.07,1.11-1.15c0.8-0.15,1.37,0.48,1.21,1.27
	C310,221.7,309.44,222.08,308.81,222.84z"
              />
              <path
                d="M460.88,201.09c-0.89,0.5-1.36,0.95-1.87,1c-0.75,0.07-1.11-0.52-1.05-1.24c0.06-0.73,0.53-1.24,1.25-1.05
	C459.7,199.93,460.08,200.46,460.88,201.09z"
              />
              <path
                d="M448.84,330.19c0.54,0.82,1.11,1.31,1.07,1.73c-0.05,0.44-0.63,1.07-1.08,1.17c-0.8,0.17-1.38-0.43-1.23-1.24
	C447.68,331.37,448.23,330.97,448.84,330.19z"
              />
              <path
                d="M277.8,632.25c-0.83,0.61-1.31,1.23-1.72,1.19c-0.46-0.04-0.86-0.68-1.28-1.06c0.39-0.42,0.73-1.1,1.18-1.18
	C276.39,631.12,276.91,631.7,277.8,632.25z"
              />
              <path
                d="M251.67,330.88c-0.8,0.61-1.2,1.12-1.69,1.25c-0.72,0.18-1.31-0.35-1.2-1.05c0.08-0.47,0.63-1.14,1.05-1.21
	C250.26,329.8,250.79,330.36,251.67,330.88z"
              />
              <path
                d="M428.29,263.18c-0.66-0.81-1.3-1.26-1.28-1.67c0.02-0.45,0.59-1.08,1.05-1.24c0.7-0.23,1.26,0.33,1.21,1.04
	C429.22,261.81,428.78,262.28,428.29,263.18z"
              />
              <path
                d="M375.8,282.21c0.58,0.78,1.18,1.23,1.16,1.65c-0.02,0.44-0.55,1.11-0.99,1.24c-0.79,0.23-1.41-0.35-1.32-1.15
	C374.69,283.46,375.23,283.03,375.8,282.21z"
              />
              <path
                d="M446.5,328.54c-0.83,0.59-1.25,1.09-1.76,1.2c-0.73,0.16-1.3-0.42-1.17-1.11c0.09-0.47,0.68-1.11,1.12-1.17
	C445.12,327.4,445.63,327.99,446.5,328.54z"
              />
              <path
                d="M344.74,196.77c-0.84,0.5-1.3,0.98-1.8,1.02c-0.87,0.08-1.23-0.56-1.12-1.38c0.1-0.72,0.62-1.17,1.31-0.94
	C343.63,195.63,344,196.15,344.74,196.77z"
              />
              <path
                d="M338.44,224.13c0.55,0.85,1.13,1.35,1.08,1.77c-0.06,0.44-0.74,1.12-1.12,1.11c-0.42-0.02-1.08-0.69-1.13-1.12
	C337.22,225.46,337.84,224.98,338.44,224.13z"
              />
              <path
                d="M267.04,407.13c-0.5-0.9-1.05-1.44-0.96-1.84c0.09-0.44,0.77-0.75,1.19-1.12c0.37,0.43,1.02,0.84,1.04,1.28
	C268.33,405.86,267.69,406.31,267.04,407.13z"
              />
              <path
                d="M355.16,85.44c0.59,0.84,1.1,1.26,1.19,1.76c0.13,0.7-0.37,1.33-1.1,1.17c-0.48-0.1-1.13-0.68-1.19-1.12
	C353.99,86.84,354.59,86.32,355.16,85.44z"
              />
              <path
                d="M346.69,407.58c-0.51-0.84-1.06-1.35-1-1.76c0.07-0.44,0.67-1.06,1.12-1.14c0.8-0.14,1.36,0.49,1.19,1.29
	C347.9,406.45,347.33,406.82,346.69,407.58z"
              />
              <path
                d="M466.19,408.1c-0.66-0.82-1.31-1.27-1.29-1.69c0.02-0.45,0.59-1.09,1.06-1.24c0.71-0.23,1.28,0.33,1.22,1.05
	C467.15,406.72,466.7,407.19,466.19,408.1z"
              />
              <path
                d="M460.59,406.8c-0.79,0.57-1.26,1.16-1.68,1.13c-0.45-0.03-1.12-0.59-1.23-1.03c-0.19-0.78,0.39-1.39,1.21-1.27
	C459.38,405.71,459.79,406.23,460.59,406.8z"
              />
              <path
                d="M421.04,327.18c-0.81,0.55-1.28,1.13-1.71,1.1c-0.44-0.03-1.09-0.61-1.18-1.05c-0.17-0.77,0.37-1.41,1.22-1.24
	C419.85,326.08,420.26,326.6,421.04,327.18z"
              />
              <path
                d="M461.06,619.98c-0.84,0.51-1.35,1.06-1.76,1c-0.45-0.07-1.09-0.68-1.15-1.12c-0.11-0.78,0.5-1.37,1.31-1.17
	C459.94,618.8,460.31,619.35,461.06,619.98z"
              />
              <path d="M689.77,608.73c-2.37-0.66-2.51-0.99-1.21-2.97C688.99,606.81,689.33,607.64,689.77,608.73z" />
              <path
                d="M264.82,441.81c-0.84,0.58-1.33,1.18-1.73,1.13c-0.45-0.06-0.83-0.71-1.24-1.1c0.4-0.41,0.76-1.07,1.21-1.14
	C263.47,440.64,263.97,441.24,264.82,441.81z"
              />
              <path
                d="M515.12,326.19c-0.85,0.57-1.28,1.06-1.79,1.15c-0.74,0.14-1.29-0.47-1.14-1.15c0.1-0.47,0.72-1.1,1.15-1.14
	C513.77,325.01,514.27,325.61,515.12,326.19z"
              />
              <path
                d="M234.4,256.63c-0.85,0.56-1.29,1.04-1.79,1.13c-0.74,0.13-1.27-0.48-1.12-1.15c0.11-0.47,0.72-1.09,1.16-1.13
	C233.08,255.44,233.57,256.05,234.4,256.63z"
              />
              <path
                d="M356.27,285.89c-0.83,0.56-1.32,1.15-1.75,1.11c-0.43-0.04-1.12-0.72-1.11-1.11c0-0.4,0.68-1.08,1.11-1.12
	C354.95,284.72,355.44,285.32,356.27,285.89z"
              />
              <path
                d="M351.54,295.13c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C350.54,296.47,351.01,296.01,351.54,295.13z"
              />
              <path
                d="M287.64,454.26c-0.77,0.58-1.17,1.07-1.67,1.2c-0.7,0.19-1.2-0.28-1.27-1.01c-0.07-0.82,0.35-1.44,1.19-1.33
	C286.4,453.2,286.83,453.71,287.64,454.26z"
              />
              <path
                d="M440.91,323.45c0.57,0.87,1.16,1.38,1.1,1.8c-0.06,0.44-0.75,1.07-1.17,1.08c-0.38,0-1.03-0.71-1.07-1.15
	C439.73,324.76,440.33,324.29,440.91,323.45z"
              />
              <path
                d="M315.51,191.71c-0.81,0.63-1.21,1.14-1.71,1.27c-0.73,0.19-1.21-0.32-1.26-1.06c-0.04-0.72,0.32-1.33,1.08-1.25
	C314.14,190.73,314.6,191.19,315.51,191.71z"
              />
              <path
                d="M520.57,284.09c0.57,0.88,1.17,1.4,1.11,1.81c-0.07,0.44-0.71,1.01-1.19,1.12c-0.74,0.16-1.23-0.48-1.1-1.17
	C519.47,285.35,519.98,284.93,520.57,284.09z"
              />
              <path
                d="M260.57,451.09c0.53,0.91,1.09,1.45,1.01,1.86c-0.09,0.43-0.75,0.97-1.23,1.05c-0.73,0.12-1.19-0.53-1.03-1.21
	C259.44,452.29,259.95,451.9,260.57,451.09z"
              />
              <path
                d="M298.23,410.12c-0.83,0.59-1.25,1.09-1.76,1.2c-0.73,0.16-1.3-0.42-1.17-1.11c0.09-0.47,0.68-1.12,1.12-1.17
	C296.85,408.97,297.36,409.56,298.23,410.12z"
              />
              <path
                d="M289.66,412.4c-0.68-0.8-1.33-1.22-1.32-1.64c0.01-0.44,0.56-1.1,1.01-1.26c0.69-0.25,1.27,0.29,1.23,1.01
	C290.55,411.01,290.13,411.49,289.66,412.4z"
              />
              <path
                d="M503.19,253.11c-0.69-0.8-1.34-1.23-1.33-1.65c0.01-0.45,0.56-1.1,1.02-1.27c0.7-0.25,1.29,0.29,1.25,1.01
	C504.1,251.7,503.67,252.19,503.19,253.11z"
              />
              <path
                d="M340.62,233.47c-0.96,0.39-1.56,0.86-1.95,0.72c-0.43-0.15-0.66-0.86-0.98-1.33c0.48-0.32,0.97-0.9,1.42-0.87
	C339.52,232.02,339.89,232.71,340.62,233.47z"
              />
              <path
                d="M344.28,189.33c-0.8,0.61-1.2,1.12-1.69,1.25c-0.72,0.18-1.31-0.35-1.2-1.05c0.08-0.47,0.63-1.13,1.05-1.21
	C342.86,188.24,343.39,188.8,344.28,189.33z"
              />
              <path
                d="M321.79,77.3c0.62,0.83,1.25,1.3,1.22,1.72c-0.03,0.44-0.63,1.05-1.1,1.19c-0.7,0.2-1.26-0.37-1.17-1.08
	C320.8,78.62,321.27,78.17,321.79,77.3z"
              />
              <path
                d="M310.4,233.72c0.57,0.87,1.16,1.38,1.1,1.8c-0.06,0.44-0.75,1.07-1.17,1.08c-0.38,0-1.03-0.71-1.07-1.15
	C309.22,235.03,309.81,234.56,310.4,233.72z"
              />
              <path
                d="M517.72,315.2c-0.87,0.55-1.39,1.13-1.8,1.07c-0.45-0.07-0.81-0.74-1.2-1.16c0.42-0.39,0.81-1.04,1.27-1.09
	C516.4,313.98,516.88,314.6,517.72,315.2z"
              />
              <path
                d="M522.97,293.12c-0.81,0.55-1.29,1.13-1.71,1.09c-0.45-0.04-1.11-0.62-1.2-1.06c-0.17-0.79,0.43-1.38,1.24-1.24
	C521.78,292,522.19,292.53,522.97,293.12z"
              />
              <path
                d="M339.75,238.52c0.53,0.91,1.09,1.45,1.01,1.86c-0.09,0.43-0.75,0.97-1.23,1.05c-0.73,0.12-1.19-0.53-1.03-1.21
	C338.61,239.72,339.12,239.33,339.75,238.52z"
              />
              <path
                d="M354.9,291.29c0.63,0.84,1.26,1.31,1.23,1.73c-0.03,0.44-0.64,1.06-1.11,1.2c-0.71,0.21-1.27-0.37-1.18-1.09
	C353.9,292.63,354.37,292.18,354.9,291.29z"
              />
              <path
                d="M347.36,185.58c-0.84,0.58-1.33,1.18-1.73,1.13c-0.45-0.06-0.83-0.71-1.24-1.1c0.4-0.41,0.76-1.07,1.21-1.14
	C346,184.41,346.5,185.01,347.36,185.58z"
              />
              <path d="M332.36,13.5c-1.01,2.2-1.59,2.27-3.13,0.3C330.37,13.69,331.29,13.6,332.36,13.5z" />
              <path
                d="M291.72,417.89c-0.87,0.55-1.39,1.13-1.8,1.07c-0.45-0.07-0.81-0.74-1.2-1.16c0.42-0.39,0.81-1.04,1.27-1.09
	C290.39,416.66,290.87,417.28,291.72,417.89z"
              />
              <path
                d="M241.41,418.33c0.85-0.59,1.34-1.2,1.75-1.15c0.46,0.06,0.83,0.72,1.25,1.12c-0.4,0.4-0.77,1.07-1.23,1.13
	C242.77,419.5,242.27,418.9,241.41,418.33z"
              />
              <path
                d="M458.83,368.97c-0.57-0.84-1.17-1.35-1.12-1.77c0.05-0.43,0.7-1.02,1.18-1.12c0.68-0.13,1.25,0.42,1.09,1.16
	C459.88,367.74,459.39,368.16,458.83,368.97z"
              />
              <path
                d="M285.08,485.28c0.57,0.74,1.05,1.11,1.14,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-0.99
	C284.07,486.56,284.59,486.08,285.08,485.28z"
              />
              <path
                d="M240.58,185.42c-0.46-0.91-0.88-1.39-0.91-1.88c-0.04-0.71,0.54-1.24,1.23-0.99c0.45,0.16,0.99,0.82,1,1.26
	C241.9,184.22,241.25,184.64,240.58,185.42z"
              />
              <path
                d="M362.62,84.76c0.46,0.95,0.91,1.47,0.86,1.93c-0.04,0.37-0.83,1.02-1.06,0.93c-0.48-0.18-1.1-0.74-1.13-1.18
	C361.25,86.04,361.92,85.59,362.62,84.76z"
              />
              <path
                d="M463.43,356.3c-0.98,0.37-1.53,0.78-1.98,0.68c-0.36-0.07-0.92-0.91-0.82-1.13c0.22-0.45,0.82-0.99,1.26-0.99
	C462.28,354.87,462.67,355.55,463.43,356.3z"
              />
              <path
                d="M349.51,87.7c-0.61,0.5-1.07,1.14-1.36,1.07c-0.49-0.12-0.88-0.68-1.31-1.06c0.43-0.38,0.81-0.96,1.3-1.08
	C348.43,86.56,348.9,87.2,349.51,87.7z"
              />
              <path
                d="M252.71,444.65c0.61,0.81,1.24,1.29,1.2,1.7c-0.05,0.45-0.71,0.83-1.11,1.25c-0.4-0.39-1.06-0.75-1.12-1.19
	C251.62,446,252.19,445.49,252.71,444.65z"
              />
              <path
                d="M460.95,390.32c-0.67-0.84-1.31-1.3-1.28-1.7c0.04-0.44,0.65-0.97,1.12-1.16c0.21-0.08,0.98,0.57,1.02,0.94
	C461.86,388.87,461.41,389.37,460.95,390.32z"
              />
              <path
                d="M449.41,174.6c-0.64-0.77-1.29-1.2-1.28-1.62c0.01-0.43,0.59-1.07,1.05-1.21c0.64-0.2,1.29,0.26,1.2,1.02
	C450.32,173.29,449.88,173.75,449.41,174.6z"
              />
              <path
                d="M384.79,117.29c-0.88,0.55-1.4,1.14-1.79,1.07c-0.45-0.09-0.79-0.76-1.18-1.18c0.42-0.38,0.82-1.02,1.27-1.07
	C383.5,116.07,383.97,116.68,384.79,117.29z"
              />
              <path
                d="M307,546.3c0.64-0.44,1.16-1.04,1.42-0.94c0.47,0.17,1.06,0.74,1.1,1.19c0.07,0.68-0.56,1.19-1.28,0.96
	C307.84,547.38,307.55,546.85,307,546.3z"
              />
              <path
                d="M245.41,425.47c-0.85,0.58-1.29,1.1-1.77,1.13c-0.34,0.03-0.93-0.55-1.05-0.95c-0.2-0.68,0.25-1.32,0.98-1.25
	C244.07,424.44,244.53,424.93,245.41,425.47z"
              />
              <path
                d="M299.54,483.99c0.56,0.82,1.15,1.31,1.1,1.72c-0.05,0.45-0.7,0.83-1.09,1.24c-0.41-0.4-1.09-0.77-1.15-1.21
	C298.35,485.33,298.96,484.83,299.54,483.99z"
              />
              <path
                d="M319.77,178.6c-0.57-0.75-1.13-1.18-1.17-1.65c-0.03-0.35,0.57-0.97,1-1.08c0.74-0.19,1.25,0.38,1.18,1.1
	C320.74,177.4,320.28,177.81,319.77,178.6z"
              />
              <path
                d="M265.54,448.98c-0.84,0.57-1.28,1.09-1.76,1.13c-0.34,0.03-0.93-0.54-1.04-0.95c-0.2-0.68,0.25-1.31,0.98-1.25
	C264.21,447.96,264.67,448.45,265.54,448.98z"
              />
              <path
                d="M306.83,257.65c-0.85,0.52-1.37,1.08-1.79,1.02c-0.42-0.06-1.06-0.76-1.04-1.15c0.02-0.4,0.72-1.04,1.14-1.06
	C305.57,256.44,306.03,257.05,306.83,257.65z"
              />
              <path
                d="M347.88,295.21c-0.59-0.74-1.07-1.1-1.16-1.54c-0.16-0.73,0.33-1.32,1.06-1.23c0.42,0.05,1.08,0.63,1.09,0.98
	C348.87,293.91,348.36,294.4,347.88,295.21z"
              />
              <path
                d="M311.43,560.66c-0.95,0.55-1.5,1.13-1.88,1.03c-0.45-0.12-0.92-0.77-1.04-1.28c-0.05-0.23,0.71-0.93,1.1-0.92
	C310.08,559.5,310.53,560.04,311.43,560.66z"
              />
              <path
                d="M258.39,449.6c-0.79,0.65-1.23,1.29-1.67,1.29c-0.43,0-1.08-0.59-1.24-1.05c-0.23-0.69,0.3-1.3,1.03-1.25
	C257.02,448.63,257.5,449.1,258.39,449.6z"
              />
              <path
                d="M516.94,287.93c0.7,0.89,1.26,1.3,1.32,1.78c0.04,0.34-0.58,1-0.97,1.06c-0.35,0.06-1.1-0.47-1.14-0.81
	C516.08,289.49,516.51,288.95,516.94,287.93z"
              />
              <path
                d="M290.68,490.65c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.97-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C289.61,489.22,289.97,489.91,290.68,490.65z"
              />
              <path
                d="M283.51,490.89c-0.88,0.51-1.41,1.06-1.8,0.97c-0.44-0.09-0.76-0.76-1.13-1.18c0.43-0.37,0.83-0.99,1.28-1.02
	C282.27,489.64,282.72,490.26,283.51,490.89z"
              />
              <path d="M342.68,634.09c-0.97,0-1.79,0-2.58,0c-0.29-1.1,0.22-1.57,1.02-1.66C342.02,632.33,342.63,632.75,342.68,634.09z" />
              <path
                d="M447.47,256.2c-0.85,0.66-1.26,1.21-1.75,1.3c-0.69,0.13-1.29-0.37-1.15-1.12c0.08-0.42,0.59-1.05,0.94-1.07
	C445.99,255.28,446.51,255.73,447.47,256.2z"
              />
              <path
                d="M233.31,392c-0.57-0.84-1.16-1.34-1.11-1.77c0.05-0.43,0.7-1.02,1.17-1.11c0.68-0.13,1.25,0.42,1.09,1.15
	C234.35,390.77,233.87,391.19,233.31,392z"
              />
              <path
                d="M278.87,310.29c0.9-0.53,1.38-1.04,1.83-1.02c0.37,0.01,1.06,0.75,1,0.99c-0.13,0.48-0.65,1.1-1.09,1.18
	C280.22,311.5,279.73,310.89,278.87,310.29z"
              />
              <path
                d="M487.95,390.2c-0.96,0.47-1.47,0.93-1.93,0.88c-0.38-0.04-1.03-0.83-0.95-1.05c0.19-0.48,0.74-1.07,1.19-1.12
	C486.65,388.87,487.11,389.52,487.95,390.2z"
              />
              <path
                d="M452.81,257.29c-0.52-0.79-0.97-1.19-1.02-1.63c-0.08-0.72,0.44-1.29,1.18-1.11c0.42,0.11,1.03,0.73,1,1.08
	C453.93,256.11,453.37,256.54,452.81,257.29z"
              />
              <path
                d="M243.46,174.28c-0.56-0.75-1.12-1.19-1.15-1.66c-0.02-0.35,0.58-0.97,1.01-1.08c0.74-0.18,1.25,0.39,1.17,1.11
	C244.44,173.09,243.98,173.49,243.46,174.28z"
              />
              <path
                d="M360.9,173.05c0.91-0.54,1.46-1.11,1.84-1.02c0.43,0.1,0.91,0.77,1,1.26c0.05,0.25-0.7,0.94-1.07,0.92
	C362.22,174.2,361.77,173.66,360.9,173.05z"
              />
              <path
                d="M267.02,423.61c-0.93,0.55-1.41,1.05-1.9,1.05c-0.33,0-0.93-0.69-0.93-1.07c0.01-0.38,0.61-1.08,0.94-1.07
	C265.63,422.53,266.1,423.04,267.02,423.61z"
              />
              <path
                d="M461.94,377.4c0.64-0.45,1.17-1.05,1.44-0.96c0.48,0.17,1.08,0.74,1.13,1.2c0.07,0.68-0.57,1.21-1.29,0.98
	C462.79,378.49,462.5,377.95,461.94,377.4z"
              />
              <path
                d="M314.68,184.99c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.97-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C313.6,183.56,313.96,184.25,314.68,184.99z"
              />
              <path
                d="M507.15,256.21c-0.58-0.8-1.08-1.21-1.19-1.7c-0.17-0.73,0.37-1.31,1.07-1.18c0.48,0.08,1.14,0.65,1.21,1.09
	C508.29,254.83,507.7,255.35,507.15,256.21z"
              />
              <path
                d="M358.44,84.88c-0.6-0.78-1.1-1.16-1.22-1.65c-0.18-0.71,0.33-1.31,1.03-1.19c0.48,0.08,1.15,0.61,1.22,1.04
	C359.54,83.49,358.97,84.02,358.44,84.88z"
              />
              <path
                d="M343.47,182.59c-0.93,0.38-1.53,0.85-1.91,0.72c-0.42-0.15-0.65-0.85-0.96-1.31c0.47-0.32,0.95-0.89,1.39-0.87
	C342.39,181.17,342.75,181.85,343.47,182.59z"
              />
              <path
                d="M509.06,341.19c-0.82,0.64-1.28,1.27-1.68,1.23c-0.45-0.04-0.86-0.67-1.28-1.04c0.38-0.42,0.71-1.08,1.15-1.17
	C507.65,340.12,508.18,340.67,509.06,341.19z"
              />
              <path
                d="M311.86,211.83c-0.59-0.87-1.11-1.31-1.14-1.77c-0.02-0.37,0.65-1.12,0.88-1.07c0.5,0.11,1.17,0.56,1.27,0.98
	C312.97,210.37,312.4,210.91,311.86,211.83z"
              />
              <path
                d="M331.34,314.99c-0.5-0.89-1.04-1.42-0.96-1.82c0.09-0.43,0.76-0.75,1.17-1.12c0.37,0.42,1.01,0.83,1.04,1.27
	C332.62,313.73,331.99,314.17,331.34,314.99z"
              />
              <path d="M305.5,43.61c1.04,1.58,0.83,2.02-1.09,2.34C304.33,44.66,304.33,44.66,305.5,43.61z" />
              <path
                d="M325.82,84.2c-0.52-0.95-1.07-1.51-0.97-1.89c0.11-0.43,0.8-0.85,1.29-0.95c0.23-0.04,0.88,0.73,0.85,1.1
	C326.97,82.91,326.44,83.34,325.82,84.2z"
              />
              <path
                d="M281.14,628.4c-0.9,0.5-1.44,1.05-1.83,0.96c-0.45-0.11-0.76-0.78-1.13-1.21c0.44-0.35,0.86-0.96,1.31-0.99
	C279.89,627.14,280.33,627.76,281.14,628.4z"
              />
              <path
                d="M373.09,296.8c-0.5-0.95-0.98-1.46-0.95-1.93c0.02-0.37,0.78-1.06,1.01-0.98c0.49,0.17,1.13,0.69,1.19,1.13
	C374.39,295.42,373.75,295.91,373.09,296.8z"
              />
              <path
                d="M490.66,252.65c-0.95,0.51-1.43,0.98-1.94,1c-0.74,0.04-1.19-0.6-0.96-1.29c0.13-0.41,0.76-0.94,1.12-0.91
	C489.35,251.49,489.79,252.03,490.66,252.65z"
              />
              <path
                d="M456.74,197.6c-0.84,0.56-1.33,1.16-1.73,1.1c-0.45-0.06-0.82-0.71-1.22-1.11c0.4-0.4,0.77-1.05,1.23-1.11
	C455.42,196.42,455.91,197.02,456.74,197.6z"
              />
              <path
                d="M503.95,343.38c0.57,0.88,1.08,1.34,1.09,1.81c0.01,0.37-0.7,1.1-0.93,1.04c-0.5-0.12-1.18-0.59-1.27-1.03
	C502.76,344.81,503.37,344.28,503.95,343.38z"
              />
              <path
                d="M456.21,250.01c0.6,0.91,1.2,1.43,1.14,1.84c-0.07,0.43-0.75,0.92-1.24,1.05c-0.24,0.06-0.96-0.66-0.95-1.03
	C455.15,251.4,455.65,250.92,456.21,250.01z"
              />
              <path
                d="M452.94,194.36c-0.82,0.65-1.28,1.3-1.69,1.26c-0.46-0.04-0.87-0.69-1.3-1.07c0.39-0.43,0.72-1.12,1.17-1.21
	C451.52,193.25,452.05,193.83,452.94,194.36z"
              />
              <path
                d="M459.45,392.58c-0.95,0.48-1.45,0.93-1.96,0.94c-0.76,0.01-1.15-0.66-0.9-1.34c0.15-0.41,0.79-0.94,1.14-0.89
	C458.21,391.36,458.62,391.92,459.45,392.58z"
              />
              <path
                d="M293.87,422.95c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C294.86,421.56,294.38,422.04,293.87,422.95z"
              />
              <path
                d="M270.26,482.66c-0.56-0.84-1.15-1.34-1.1-1.76c0.05-0.43,0.7-1.01,1.16-1.1c0.67-0.13,1.23,0.43,1.08,1.15
	C271.3,481.45,270.82,481.86,270.26,482.66z"
              />
              <path
                d="M309.2,539.24c-0.61,0.49-1.07,1.12-1.36,1.04c-0.49-0.12-0.87-0.68-1.29-1.06c0.43-0.38,0.81-0.94,1.3-1.06
	C308.14,538.1,308.6,538.74,309.2,539.24z"
              />
              <path
                d="M311.63,314.95c-0.85,0.55-1.29,1.05-1.79,1.11c-0.68,0.08-1.13-0.42-1.13-1.18c0.01-0.77,0.52-1.22,1.18-1.11
	C310.39,313.87,310.82,314.38,311.63,314.95z"
              />
              <path
                d="M268.45,358.5c-0.81,0.61-1.28,1.24-1.71,1.21c-0.43-0.02-1.04-0.63-1.18-1.1c-0.21-0.71,0.36-1.27,1.07-1.18
	C267.14,357.49,267.59,357.97,268.45,358.5z"
              />
              <path
                d="M602.87,176.26c1.26,0.31,2.18,1.59,1.71,2.51c-0.12,0.23-0.78,0.19-1.19,0.28c0,0,0.06,0.07,0.06,0.07
	c-0.16-0.98-0.32-1.96-0.48-2.94L602.87,176.26z"
              />
              <path
                d="M457.98,378.12c-0.8,0.63-1.24,1.25-1.67,1.24c-0.42-0.01-1.05-0.58-1.2-1.04c-0.22-0.68,0.31-1.27,1.02-1.19
	C456.64,377.18,457.11,377.63,457.98,378.12z"
              />
              <path
                d="M250.67,443.25c-0.84,0.56-1.29,1.07-1.77,1.1c-0.33,0.02-0.91-0.55-1.02-0.96c-0.19-0.68,0.27-1.3,0.99-1.22
	C249.36,442.21,249.81,442.7,250.67,443.25z"
              />
              <path
                d="M457.04,347.38c-0.49-0.92-0.96-1.42-0.94-1.89c0.02-0.35,0.78-1.02,1.03-0.94c0.48,0.15,1.13,0.69,1.17,1.11
	C458.34,346.07,457.69,346.54,457.04,347.38z"
              />
              <path
                d="M345.48,263.25c0.54,0.93,1.12,1.49,1.02,1.88c-0.12,0.43-0.82,0.87-1.32,0.96c-0.24,0.04-0.9-0.73-0.88-1.09
	C344.33,264.53,344.87,264.1,345.48,263.25z"
              />
              <path
                d="M232.2,251.41c-0.58-0.85-1.1-1.29-1.14-1.76c-0.02-0.35,0.65-1.1,0.89-1.05c0.5,0.09,1.21,0.54,1.3,0.96
	C233.34,249.96,232.75,250.51,232.2,251.41z"
              />
              <path
                d="M338.31,184.29c0.61,0.86,1.14,1.3,1.18,1.77c0.03,0.36-0.5,0.98-0.9,1.12c-0.7,0.24-1.31-0.26-1.3-0.96
	C337.3,185.72,337.78,185.24,338.31,184.29z"
              />
              <path
                d="M484.83,247.42c0.53,0.91,1.02,1.39,1.01,1.85c-0.01,0.37-0.74,1.07-0.98,1c-0.49-0.14-1.15-0.64-1.22-1.08
	C483.58,248.8,484.21,248.29,484.83,247.42z"
              />
              <path
                d="M282.53,496.18c0.59,0.9,1.17,1.4,1.1,1.8c-0.07,0.42-0.72,0.9-1.2,1.01c-0.24,0.06-0.93-0.64-0.93-1
	C281.5,497.54,281.99,497.08,282.53,496.18z"
              />
              <path
                d="M300.16,265.57c-0.94,0.53-1.42,1-1.93,1.03c-0.74,0.05-1.2-0.59-0.98-1.28c0.13-0.41,0.75-0.95,1.1-0.92
	C298.84,264.44,299.28,264.97,300.16,265.57z"
              />
              <path
                d="M484.85,394.02c-0.93,0.47-1.43,0.92-1.93,0.93c-0.75,0.01-1.14-0.65-0.89-1.33c0.15-0.4,0.78-0.93,1.12-0.88
	C483.63,392.81,484.04,393.37,484.85,394.02z"
              />
              <path
                d="M519.59,297.29c-1,0.37-1.55,0.78-2,0.69c-0.36-0.08-0.93-0.91-0.83-1.14c0.22-0.46,0.83-1,1.27-1
	C518.44,295.84,518.83,296.53,519.59,297.29z"
              />
              <path
                d="M369.24,104.1c-0.91,0.53-1.4,1.02-1.89,1.02c-0.35,0-0.91-0.62-1-1.04c-0.15-0.71,0.35-1.32,1.09-1.2
	C367.95,102.96,368.38,103.49,369.24,104.1z"
              />
              <path
                d="M330.82,78.67c-0.85,0.52-1.37,1.08-1.79,1.02c-0.42-0.06-1.06-0.76-1.04-1.15c0.02-0.4,0.72-1.04,1.14-1.06
	C329.56,77.46,330.02,78.07,330.82,78.67z"
              />
              <path
                d="M489.62,384.41c0.52,0.9,1,1.37,1,1.84c0,0.36-0.72,1.06-0.95,1c-0.49-0.14-1.14-0.63-1.21-1.06
	C488.4,385.78,489.01,385.28,489.62,384.41z"
              />
              <path
                d="M251.67,267.73c-0.64-0.85-1.27-1.33-1.23-1.74c0.05-0.42,0.7-0.95,1.19-1.09c0.25-0.07,1,0.6,1.01,0.96
	C252.65,266.33,252.17,266.82,251.67,267.73z"
              />
              <path
                d="M314.12,177.51c-0.79,0.53-1.31,1.11-1.59,1.01c-0.47-0.17-0.96-0.73-1.1-1.22c-0.06-0.22,0.71-0.97,1.06-0.95
	C312.95,176.38,313.38,176.95,314.12,177.51z"
              />
              <path
                d="M302.73,420.63c-0.9,0.5-1.44,1.05-1.83,0.96c-0.45-0.11-0.76-0.78-1.13-1.21c0.44-0.35,0.86-0.96,1.31-0.99
	C301.49,419.37,301.93,419.99,302.73,420.63z"
              />
              <path
                d="M496,376.49c0.61,0.88,1.24,1.4,1.17,1.79c-0.08,0.44-0.74,0.93-1.23,1.07c-0.24,0.06-0.96-0.65-0.97-1.01
	C494.96,377.87,495.46,377.39,496,376.49z"
              />
              <path
                d="M449.57,198.3c-0.85,0.59-1.34,1.21-1.74,1.15c-0.46-0.07-0.82-0.73-1.23-1.13c0.4-0.4,0.77-1.06,1.22-1.13
	C448.22,197.13,448.72,197.73,449.57,198.3z"
              />
              <path
                d="M353.86,177.63c-0.82,0.6-1.29,1.2-1.72,1.17c-0.42-0.03-1.02-0.64-1.15-1.11c-0.2-0.71,0.39-1.24,1.08-1.14
	C352.57,176.62,353.01,177.1,353.86,177.63z"
              />
              <path
                d="M422.1,148.13c0.61,0.81,1.23,1.28,1.19,1.68c-0.05,0.44-0.71,0.83-1.1,1.23c-0.4-0.39-1.05-0.74-1.12-1.18
	C421.01,149.47,421.57,148.96,422.1,148.13z"
              />
              <path
                d="M468.28,247.41c-0.9,0.51-1.45,1.06-1.84,0.97c-0.45-0.11-0.76-0.79-1.13-1.23c0.44-0.36,0.86-0.98,1.32-1
	C467.04,246.13,467.48,246.76,468.28,247.41z"
              />
              <path
                d="M349.12,179.8c0.48,0.86,1.02,1.39,0.94,1.79c-0.09,0.43-0.81,1.08-1.16,1.03c-0.43-0.06-1.03-0.75-1.06-1.19
	C347.81,181.03,348.46,180.58,349.12,179.8z"
              />
              <path
                d="M463.27,395.61c-0.87,0.64-1.31,1.18-1.79,1.23c-0.33,0.03-0.99-0.6-1.02-0.98c-0.03-0.39,0.5-1.14,0.82-1.16
	C461.77,394.67,462.3,395.13,463.27,395.61z"
              />
              <path
                d="M473.91,248.33c-0.69-0.86-1.34-1.33-1.3-1.73c0.04-0.45,0.66-0.99,1.14-1.18c0.22-0.08,1.01,0.58,1.04,0.95
	C474.83,246.84,474.38,247.36,473.91,248.33z"
              />
              <path
                d="M270.15,356.15c-0.59-0.74-1.07-1.09-1.17-1.53c-0.17-0.73,0.32-1.32,1.05-1.23c0.42,0.05,1.08,0.62,1.09,0.98
	C271.14,354.84,270.63,355.33,270.15,356.15z"
              />
              <path
                d="M264.6,454.47c0.5,0.91,0.98,1.39,0.96,1.86c-0.01,0.36-0.74,1.05-0.97,0.98c-0.49-0.15-1.13-0.65-1.19-1.08
	C263.36,455.82,263.98,455.33,264.6,454.47z"
              />
              <path
                d="M266.32,476.14c0.49,0.82,1,1.3,0.99,1.78c0,0.35-0.65,0.92-1.08,0.98c-0.75,0.11-1.23-0.48-1.07-1.21
	C265.26,477.25,265.74,476.89,266.32,476.14z"
              />
              <path
                d="M397.05,134.23c-0.86,0.57-1.32,1.08-1.8,1.11c-0.34,0.02-0.93-0.56-1.04-0.97c-0.19-0.69,0.27-1.32,1.01-1.24
	C395.71,133.18,396.17,133.68,397.05,134.23z"
              />
              <path
                d="M482.66,245.92c-0.8,0.64-1.24,1.26-1.64,1.23c-0.45-0.03-0.86-0.64-1.29-1c0.37-0.43,0.68-1.1,1.12-1.2
	C481.23,244.86,481.77,245.41,482.66,245.92z"
              />
              <path
                d="M257.48,458.68c-0.65-0.78-1.29-1.21-1.28-1.63c0.01-0.43,0.59-1.08,1.05-1.22c0.65-0.2,1.29,0.28,1.2,1.03
	C258.39,457.35,257.95,457.81,257.48,458.68z"
              />
              <path
                d="M456.09,396.44c-0.83,0.61-1.31,1.23-1.74,1.2c-0.43-0.03-1.04-0.66-1.17-1.14c-0.2-0.72,0.4-1.27,1.1-1.18
	C454.78,395.38,455.23,395.89,456.09,396.44z"
              />
              <path
                d="M517.03,308.12c-0.95,0.48-1.44,0.94-1.95,0.95c-0.76,0.02-1.16-0.65-0.91-1.33c0.15-0.41,0.78-0.94,1.13-0.9
	C515.78,306.91,516.19,307.46,517.03,308.12z"
              />
              <path
                d="M317.87,74.2c0.67,0.85,1.32,1.32,1.27,1.71c-0.05,0.45-0.68,0.98-1.17,1.15c-0.24,0.08-1-0.59-1.03-0.96
	C316.91,75.64,317.38,75.14,317.87,74.2z"
              />
              <path
                d="M275.55,420.79c-0.57-0.84-1.18-1.34-1.13-1.76c0.05-0.43,0.69-1.03,1.17-1.13c0.67-0.14,1.26,0.41,1.11,1.15
	C276.58,419.55,276.1,419.98,275.55,420.79z"
              />
              <path
                d="M284.8,441.9c-0.64-0.79-1.29-1.23-1.27-1.66c0.02-0.44,0.61-1.09,1.07-1.22c0.65-0.19,1.29,0.29,1.2,1.05
	C285.74,440.58,285.29,441.04,284.8,441.9z"
              />
              <path
                d="M452.64,333.3c0.55,0.84,1.14,1.34,1.08,1.76c-0.06,0.45-0.72,0.83-1.12,1.24c-0.41-0.41-1.09-0.8-1.14-1.25
	C451.42,334.63,452.04,334.14,452.64,333.3z"
              />
              <path
                d="M281.88,429.67c-0.86,0.53-1.32,1.01-1.82,1.07c-0.69,0.07-1.12-0.45-1.1-1.2c0.03-0.77,0.54-1.21,1.21-1.07
	C280.67,428.57,281.08,429.08,281.88,429.67z"
              />
              <path
                d="M308.82,572.21c-1.01,0.39-1.63,0.86-2.01,0.71c-0.4-0.16-0.74-0.91-0.76-1.41c-0.01-0.25,0.83-0.81,1.17-0.73
	C307.69,570.89,308.04,571.48,308.82,572.21z"
              />
              <path
                d="M340.88,193.21c-0.83,0.59-1.26,1.11-1.74,1.15c-0.34,0.03-0.93-0.53-1.06-0.94c-0.21-0.68,0.23-1.31,0.96-1.26
	C339.53,192.2,340,192.68,340.88,193.21z"
              />
              <path
                d="M246.72,333.35c0.57,0.8,1.06,1.2,1.16,1.69c0.15,0.72-0.41,1.29-1.07,1.17c-0.47-0.08-1.11-0.67-1.18-1.11
	C245.57,334.69,246.16,334.18,246.72,333.35z"
              />
              <path
                d="M377.54,118.3c-0.98,0.37-1.53,0.78-1.98,0.68c-0.36-0.07-0.92-0.91-0.82-1.13c0.22-0.45,0.82-0.99,1.26-0.99
	C376.39,116.87,376.78,117.55,377.54,118.3z"
              />
              <path
                d="M305.61,369.69c-0.88,0.55-1.39,1.13-1.79,1.05c-0.45-0.09-0.79-0.75-1.17-1.17c0.42-0.37,0.81-1,1.26-1.05
	C304.31,368.48,304.78,369.08,305.61,369.69z"
              />
              <path
                d="M308.98,531.93c-0.85,0.62-1.28,1.15-1.73,1.18c-0.36,0.02-1.1-0.63-1.06-0.88c0.08-0.48,0.53-1.15,0.95-1.27
	C307.51,530.86,308.06,531.42,308.98,531.93z"
              />
              <path
                d="M309.29,183.24c-0.64-0.77-1.29-1.2-1.28-1.62c0.01-0.43,0.59-1.07,1.05-1.21c0.64-0.2,1.29,0.26,1.2,1.02
	C310.2,181.93,309.77,182.39,309.29,183.24z"
              />
              <path
                d="M316.17,198.98c-0.88,0.6-1.32,1.13-1.79,1.15c-0.37,0.01-1.11-0.68-1.06-0.93c0.1-0.49,0.58-1.15,1.01-1.26
	C314.72,197.85,315.26,198.43,316.17,198.98z"
              />
              <path
                d="M501.18,374.29c-0.8,0.63-1.24,1.26-1.68,1.25c-0.43-0.01-1.06-0.59-1.21-1.05c-0.22-0.69,0.31-1.28,1.03-1.21
	C499.83,373.32,500.3,373.78,501.18,374.29z"
              />
              <path
                d="M461.34,374.28c-0.91,0.5-1.4,0.98-1.88,0.97c-0.35-0.01-1.01-0.77-0.94-1.02c0.13-0.48,0.68-1.13,1.1-1.16
	C460.03,373.04,460.51,373.66,461.34,374.28z"
              />
              <path
                d="M268.77,631.16c0.57,0.74,1.05,1.11,1.14,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-0.99
	C267.76,632.43,268.27,631.95,268.77,631.16z"
              />
              <path
                d="M337.68,216.95c0.59,0.88,1.18,1.4,1.12,1.8c-0.07,0.42-0.73,0.91-1.22,1.04c-0.23,0.06-0.94-0.66-0.93-1.01
	C336.65,218.3,337.14,217.83,337.68,216.95z"
              />
              <path
                d="M370.17,91.23c0.5,0.91,0.98,1.39,0.96,1.86c-0.01,0.36-0.74,1.05-0.97,0.98c-0.49-0.15-1.13-0.65-1.19-1.08
	C368.93,92.58,369.55,92.09,370.17,91.23z"
              />
              <path
                d="M479.51,396.22c0.69,0.75,1.36,1.16,1.35,1.57c0,0.44-0.59,1.15-1.01,1.23c-0.36,0.06-1.1-0.54-1.21-0.97
	C478.55,397.65,479.07,397.1,479.51,396.22z"
              />
              <path
                d="M608.41,634.08c-0.6-0.82-1.2-1.28-1.16-1.69c0.04-0.44,0.66-0.84,1.03-1.25c0.42,0.38,1.1,0.7,1.18,1.14
	C609.54,632.68,608.96,633.2,608.41,634.08z"
              />
              <path
                d="M299.36,361.08c-0.59-0.89-1.11-1.34-1.12-1.81c-0.01-0.37,0.68-1.12,0.91-1.06c0.5,0.11,1.17,0.58,1.26,1.01
	C300.5,359.61,299.92,360.15,299.36,361.08z"
              />
              <path
                d="M345.22,180.15c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C346.2,178.76,345.72,179.24,345.22,180.15z"
              />
              <path
                d="M312.24,551.31c-0.53-0.83-1-1.26-1.09-1.77c-0.14-0.75,0.47-1.27,1.13-1.12c0.47,0.11,1.09,0.72,1.13,1.16
	C313.45,550,312.83,550.49,312.24,551.31z"
              />
              <path
                d="M312.61,242.72c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.08c0.01-0.38,0.62-1.07,0.95-1.06
	C311.22,241.63,311.69,242.15,312.61,242.72z"
              />
              <path
                d="M316.43,182.55c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C317.41,181.16,316.93,181.64,316.43,182.55z"
              />
              <path
                d="M314.74,556.82c-0.87,0.56-1.4,1.16-1.8,1.08c-0.46-0.09-0.8-0.77-1.19-1.2c0.42-0.39,0.82-1.04,1.28-1.09
	C313.44,555.58,313.92,556.21,314.74,556.82z"
              />
              <path
                d="M339.51,198.69c0.64,0.87,1.28,1.35,1.23,1.76c-0.05,0.43-0.69,0.95-1.18,1.11c-0.23,0.07-0.98-0.61-0.99-0.97
	C338.55,200.12,339.02,199.62,339.51,198.69z"
              />
              <path
                d="M266.25,416.25c-0.84,0.64-1.31,1.28-1.69,1.23c-0.45-0.06-0.99-0.64-1.16-1.12c-0.08-0.23,0.59-1,0.96-1.03
	C264.82,415.28,265.32,415.76,266.25,416.25z"
              />
              <path
                d="M458.68,385.49c-0.86,0.57-1.37,1.16-1.76,1.09c-0.45-0.08-0.8-0.74-1.19-1.15c0.41-0.38,0.79-1.02,1.24-1.07
	C457.36,384.3,457.84,384.9,458.68,385.49z"
              />
              <path
                d="M236.62,221.9c-0.55-0.88-1.14-1.39-1.07-1.79c0.08-0.44,0.76-0.77,1.18-1.15c0.37,0.41,1,0.8,1.04,1.25
	C237.81,220.61,237.21,221.08,236.62,221.9z"
              />
              <path
                d="M355.24,175.59c-0.45-0.94-0.91-1.46-0.86-1.93c0.03-0.36,0.82-0.99,1.07-0.9c0.48,0.17,1.1,0.74,1.12,1.17
	C356.6,174.33,355.93,174.78,355.24,175.59z"
              />
              <path
                d="M262.9,460.11c-0.86,0.55-1.37,1.13-1.78,1.06c-0.45-0.07-0.8-0.74-1.19-1.15c0.42-0.39,0.8-1.04,1.25-1.09
	C261.59,458.89,262.07,459.51,262.9,460.11z"
              />
              <path
                d="M465.25,397.49c0.57,0.74,1.13,1.16,1.19,1.64c0.04,0.34-0.56,0.96-0.99,1.08c-0.75,0.21-1.26-0.35-1.2-1.08
	C464.3,398.69,464.76,398.28,465.25,397.49z"
              />
              <path
                d="M456.37,399.54c0.93-0.55,1.48-1.12,1.87-1.03c0.44,0.11,0.92,0.78,1.01,1.27c0.05,0.25-0.71,0.95-1.08,0.93
	C457.7,400.69,457.26,400.15,456.37,399.54z"
              />
              <path d="M616.71,239.75c0.41,1.14,0.72,2,1.09,3.01C615.76,242.45,615.62,242.06,616.71,239.75z" />
              <path
                d="M268.49,378.18c0.64,0.85,1.27,1.33,1.22,1.74c-0.05,0.43-0.69,0.94-1.17,1.1c-0.22,0.07-0.97-0.6-0.98-0.96
	C267.54,379.58,268,379.09,268.49,378.18z"
              />
              <path
                d="M346.17,317.24c0.66,0.84,1.3,1.3,1.27,1.7c-0.04,0.43-0.65,0.96-1.13,1.14c-0.22,0.08-0.99-0.58-1.01-0.93
	C345.26,318.67,345.71,318.17,346.17,317.24z"
              />
              <path
                d="M514.37,319.18c-0.97,0.48-1.49,0.94-1.95,0.89c-0.38-0.04-1.04-0.84-0.96-1.06c0.19-0.48,0.74-1.08,1.2-1.13
	C513.05,317.85,513.52,318.5,514.37,319.18z"
              />
              <path
                d="M265.64,429.16c0.57,0.74,1.13,1.16,1.19,1.64c0.04,0.34-0.56,0.96-0.99,1.08c-0.75,0.21-1.26-0.35-1.2-1.08
	C264.69,430.36,265.14,429.95,265.64,429.16z"
              />
              <path
                d="M269.83,625.75c-0.64,0.44-1.16,1.03-1.44,0.93c-0.47-0.16-0.8-0.76-1.19-1.17c0.46-0.34,0.88-0.87,1.38-0.96
	C268.87,624.51,269.28,625.19,269.83,625.75z"
              />
              <path
                d="M266.52,439.75c-0.62-0.87-1.25-1.36-1.19-1.76c0.06-0.43,0.7-0.93,1.19-1.09c0.22-0.07,0.95,0.62,0.97,0.99
	C267.5,438.35,267.03,438.83,266.52,439.75z"
              />
              <path
                d="M467.07,359.28c-0.6,0.51-1.05,1.15-1.35,1.09c-0.49-0.11-0.89-0.67-1.32-1.05c0.42-0.39,0.8-0.97,1.29-1.1
	C465.98,358.14,466.45,358.78,467.07,359.28z"
              />
              <path
                d="M502.25,361.62c0.46,0.95,0.91,1.47,0.86,1.93c-0.04,0.37-0.83,1.02-1.06,0.93c-0.48-0.18-1.09-0.74-1.14-1.19
	C500.88,362.91,501.56,362.46,502.25,361.62z"
              />
              <path
                d="M301.3,319.54c-0.92,0.53-1.4,1.03-1.86,1.01c-0.37-0.01-1.07-0.76-1-0.99c0.14-0.49,0.66-1.12,1.1-1.2
	C299.92,318.3,300.42,318.93,301.3,319.54z"
              />
              <path
                d="M286.64,463.14c-0.56-0.75-1.12-1.19-1.15-1.66c-0.02-0.35,0.58-0.97,1.01-1.08c0.74-0.18,1.25,0.39,1.17,1.11
	C287.62,461.96,287.16,462.36,286.64,463.14z"
              />
              <path
                d="M380.97,280.17c-0.92,0.55-1.4,1.06-1.87,1.05c-0.37-0.01-1.09-0.74-1.03-0.98c0.14-0.49,0.65-1.14,1.09-1.22
	C379.56,278.95,380.08,279.56,380.97,280.17z"
              />
              <path
                d="M463.99,363.15c-0.91,0.57-1.38,1.08-1.85,1.08c-0.37,0-1.1-0.73-1.04-0.97c0.13-0.5,0.63-1.15,1.07-1.23
	C462.56,361.96,463.09,362.56,463.99,363.15z"
              />
              <path
                d="M265.84,409.53c-0.97,0.54-1.52,1.1-1.9,1c-0.45-0.12-0.9-0.77-1.03-1.28c-0.05-0.22,0.73-0.91,1.11-0.9
	C264.49,408.37,264.93,408.9,265.84,409.53z"
              />
              <path
                d="M460.13,359.9c-0.82,0.65-1.28,1.3-1.69,1.26c-0.46-0.04-0.87-0.69-1.3-1.07c0.39-0.43,0.72-1.12,1.17-1.21
	C458.71,358.8,459.25,359.37,460.13,359.9z"
              />
              <path
                d="M476.44,403.54c-0.59-0.81-1.09-1.22-1.21-1.72c-0.17-0.73,0.38-1.32,1.08-1.21c0.48,0.08,1.15,0.67,1.22,1.1
	C477.59,402.14,477,402.67,476.44,403.54z"
              />
              <path
                d="M299.71,179.13c-0.84,0.43-1.42,0.95-1.69,0.82c-0.45-0.21-0.87-0.81-0.96-1.31c-0.04-0.23,0.8-0.9,1.15-0.83
	C298.67,177.89,299.03,178.49,299.71,179.13z"
              />
              <path
                d="M457.44,351.12c0.69,0.75,1.36,1.16,1.35,1.57c0,0.44-0.59,1.15-1.01,1.23c-0.36,0.06-1.1-0.54-1.21-0.97
	C456.48,352.55,456.99,352,457.44,351.12z"
              />
              <path
                d="M511.72,330.19c-0.81,0.61-1.27,1.22-1.7,1.2c-0.42-0.02-1.03-0.62-1.17-1.08c-0.21-0.7,0.36-1.25,1.06-1.16
	C510.42,329.2,510.87,329.67,511.72,330.19z"
              />
              <path
                d="M504.64,350.62c0.6,0.81,1.22,1.29,1.18,1.69c-0.05,0.45-0.71,0.82-1.11,1.23c-0.39-0.39-1.04-0.75-1.11-1.19
	C503.54,351.95,504.11,351.45,504.64,350.62z"
              />
              <path
                d="M318.83,188.13c-0.92,0.49-1.4,0.95-1.9,0.97c-0.75,0.03-1.16-0.63-0.92-1.3c0.14-0.41,0.76-0.96,1.1-0.91
	C317.58,186.95,318,187.5,318.83,188.13z"
              />
              <path
                d="M453.66,201.32c-0.79,0.61-1.18,1.12-1.66,1.25c-0.71,0.18-1.3-0.34-1.19-1.04c0.07-0.47,0.62-1.13,1.03-1.2
	C452.26,200.25,452.79,200.8,453.66,201.32z"
              />
              <path
                d="M461.87,404.74c-0.5-0.94-0.97-1.44-0.94-1.9c0.02-0.37,0.78-1.05,0.99-0.98c0.49,0.17,1.12,0.68,1.18,1.12
	C463.15,403.38,462.51,403.87,461.87,404.74z"
              />
              <path
                d="M339.43,178.86c-0.6,0.51-1.05,1.15-1.35,1.09c-0.49-0.11-0.89-0.67-1.32-1.05c0.42-0.39,0.8-0.97,1.29-1.1
	C338.34,177.72,338.81,178.36,339.43,178.86z"
              />
              <path
                d="M371.92,116.23c-0.49-0.81-1-1.3-0.99-1.77c0.01-0.36,0.66-0.94,1.08-0.99c0.73-0.1,1.22,0.48,1.07,1.22
	C372.98,115.13,372.5,115.48,371.92,116.23z"
              />
              <path
                d="M275.45,626.78c-0.48-0.82-0.99-1.31-0.97-1.78c0.01-0.36,0.67-0.93,1.09-0.98c0.73-0.09,1.22,0.5,1.05,1.23
	C276.52,625.69,276.04,626.04,275.45,626.78z"
              />
              <path
                d="M385.75,273.96c-0.58-0.8-1.08-1.2-1.19-1.7c-0.17-0.73,0.37-1.31,1.07-1.18c0.48,0.08,1.14,0.65,1.21,1.09
	C386.89,272.58,386.3,273.1,385.75,273.96z"
              />
              <path
                d="M617.31,631.67c-0.86,0.55-1.32,1.06-1.82,1.12c-0.7,0.08-1.15-0.47-1.13-1.22c0.02-0.78,0.53-1.25,1.22-1.13
	C616.07,630.53,616.49,631.06,617.31,631.67z"
              />
              <path
                d="M463.03,368.68c0.48,0.8,0.99,1.29,0.99,1.77c0,0.35-0.66,0.92-1.08,0.98c-0.74,0.11-1.22-0.48-1.06-1.21
	C461.97,369.78,462.45,369.42,463.03,368.68z"
              />
              <path
                d="M446,321.41c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.07c0.01-0.38,0.61-1.07,0.94-1.06
	C444.61,320.33,445.09,320.84,446,321.41z"
              />
              <path
                d="M265.03,465.2c-0.6-0.9-1.21-1.42-1.14-1.81c0.08-0.44,0.73-0.92,1.23-1.06c0.23-0.06,0.95,0.66,0.95,1.02
	C266.07,463.82,265.57,464.29,265.03,465.2z"
              />
              <path
                d="M259.29,270.67c0.53,0.91,1.02,1.39,1.01,1.86c0,0.36-0.73,1.07-0.96,1c-0.49-0.14-1.15-0.64-1.21-1.07
	C258.07,272.06,258.68,271.55,259.29,270.67z"
              />
              <path
                d="M397.96,327.27c0.54,0.92,1.03,1.41,1.02,1.88c0,0.36-0.73,1.08-0.97,1.01c-0.5-0.14-1.16-0.64-1.23-1.07
	C396.73,328.68,397.35,328.17,397.96,327.27z"
              />
              <path
                d="M393.22,130.91c-0.93,0.54-1.42,1.04-1.9,1.04c-0.33,0-0.93-0.7-0.92-1.07c0.01-0.38,0.61-1.07,0.94-1.06
	C391.83,129.83,392.3,130.34,393.22,130.91z"
              />
              <path
                d="M504.28,370.65c-0.88,0.55-1.4,1.14-1.79,1.07c-0.45-0.09-0.79-0.76-1.18-1.18c0.42-0.38,0.82-1.02,1.27-1.07
	C502.97,369.42,503.45,370.04,504.28,370.65z"
              />
              <path
                d="M281.4,279.5c-0.63-0.88-1.26-1.38-1.2-1.78c0.06-0.44,0.71-0.94,1.2-1.1c0.22-0.07,0.96,0.63,0.98,0.99
	C282.39,278.09,281.91,278.58,281.4,279.5z"
              />
              <path
                d="M405.27,330.1c-0.66-0.83-1.31-1.3-1.27-1.71c0.04-0.43,0.68-0.97,1.16-1.12c0.25-0.08,1.02,0.58,1.04,0.93
	C406.22,328.68,405.75,329.18,405.27,330.1z"
              />
              <path
                d="M249.93,436.12c-0.85,0.54-1.35,1.12-1.75,1.06c-0.45-0.07-0.79-0.73-1.18-1.14c0.41-0.39,0.79-1.03,1.24-1.08
	C248.63,434.91,249.11,435.52,249.93,436.12z"
              />
              <path
                d="M462.79,348.58c-0.87,0.64-1.3,1.19-1.78,1.23c-0.33,0.03-0.99-0.59-1.03-0.97c-0.03-0.39,0.49-1.14,0.81-1.17
	C461.28,347.64,461.81,348.1,462.79,348.58z"
              />
              <path
                d="M374.01,300.44c0.51,0.87,1.06,1.39,0.99,1.8c-0.07,0.43-0.77,1.08-1.15,1.05c-0.41-0.04-1.04-0.72-1.07-1.16
	C372.76,301.71,373.39,301.26,374.01,300.44z"
              />
              <path
                d="M379.36,305.8c-0.84,0.43-1.42,0.95-1.69,0.82c-0.45-0.21-0.87-0.81-0.96-1.31c-0.04-0.23,0.8-0.89,1.15-0.83
	C378.32,304.56,378.68,305.16,379.36,305.8z"
              />
              <path
                d="M416.08,163.31c0.57,0.74,1.04,1.11,1.13,1.55c0.15,0.73-0.34,1.32-1.08,1.2c-0.42-0.07-1.07-0.65-1.07-1
	C415.07,164.58,415.59,164.11,416.08,163.31z"
              />
              <path d="M508.67,634.04c-0.91,0-1.74,0-2.6,0c-0.13-1.06,0.32-1.52,1.12-1.59C508.12,632.38,508.62,632.87,508.67,634.04z" />
              <path
                d="M460.19,379.59c0.61,0.86,1.14,1.3,1.18,1.77c0.03,0.36-0.5,0.98-0.9,1.12c-0.7,0.24-1.31-0.26-1.3-0.96
	C459.18,381.01,459.66,380.53,460.19,379.59z"
              />
              <path
                d="M265.67,472.38c-0.54-0.87-1.1-1.38-1.04-1.79c0.07-0.44,0.73-0.78,1.14-1.17c0.39,0.41,1.05,0.79,1.1,1.24
	C266.91,471.07,266.29,471.54,265.67,472.38z"
              />
              <path
                d="M308.77,551.88c0.65,0.84,1.28,1.3,1.24,1.7c-0.04,0.43-0.65,0.96-1.12,1.13c-0.22,0.08-0.98-0.57-1-0.93
	C307.86,553.32,308.31,552.82,308.77,551.88z"
              />
              <path
                d="M412.46,329.61c-0.65-0.78-1.3-1.22-1.29-1.64c0.01-0.44,0.6-1.09,1.06-1.23c0.65-0.2,1.3,0.28,1.21,1.03
	C413.38,328.28,412.94,328.75,412.46,329.61z"
              />
              <path
                d="M256.43,324.95c-0.57-0.75-1.13-1.18-1.17-1.65c-0.03-0.35,0.57-0.97,1-1.08c0.74-0.19,1.25,0.38,1.18,1.1
	C257.4,323.75,256.94,324.16,256.43,324.95z"
              />
              <path
                d="M226.58,372.66c0.9-0.53,1.44-1.1,1.82-1.01c0.43,0.1,0.9,0.76,0.99,1.25c0.05,0.25-0.7,0.93-1.06,0.92
	C227.87,373.8,227.44,373.26,226.58,372.66z"
              />
              <path
                d="M382.43,277.94c-0.59-0.74-1.07-1.09-1.17-1.53c-0.17-0.73,0.32-1.32,1.05-1.23c0.42,0.05,1.08,0.62,1.09,0.98
	C383.42,276.63,382.91,277.12,382.43,277.94z"
              />
              <path
                d="M409,163.69c0.52,0.9,1,1.37,1,1.84c0,0.36-0.72,1.06-0.95,1c-0.49-0.14-1.14-0.63-1.21-1.06
	C407.79,165.06,408.4,164.56,409,163.69z"
              />
              <path
                d="M334.18,407.14c-0.87,0.52-1.33,1-1.83,1.05c-0.7,0.07-1.12-0.47-1.08-1.21c0.03-0.77,0.56-1.2,1.22-1.06
	C332.98,406.02,333.39,406.54,334.18,407.14z"
              />
              <path
                d="M297.95,323.68c-0.89,0.53-1.44,1.1-1.84,1.02c-0.45-0.1-0.78-0.79-1.16-1.23c0.44-0.37,0.86-1.01,1.32-1.04
	C296.68,322.39,297.14,323.04,297.95,323.68z"
              />
              <path
                d="M424.4,323.34c-0.88,0.54-1.35,1.04-1.83,1.06c-0.34,0.01-0.91-0.59-1.01-1c-0.17-0.7,0.32-1.31,1.04-1.21
	C423.09,322.25,423.53,322.76,424.4,323.34z"
              />
              <path
                d="M386.8,118.82c0.6,0.86,1.13,1.3,1.16,1.76c0.02,0.36-0.65,1.12-0.89,1.08c-0.51-0.1-1.2-0.55-1.31-0.98
	C385.66,120.3,386.25,119.74,386.8,118.82z"
              />
              <path
                d="M449.8,324.73c-0.85,0.56-1.29,1.07-1.78,1.1c-0.34,0.02-0.92-0.56-1.03-0.97c-0.19-0.69,0.27-1.31,1-1.23
	C448.49,323.69,448.94,324.18,449.8,324.73z"
              />
              <path
                d="M394.61,165.1c0.53,0.91,1.02,1.39,1.01,1.86c0,0.36-0.73,1.07-0.96,1c-0.49-0.14-1.15-0.64-1.21-1.07
	C393.38,166.5,394,165.99,394.61,165.1z"
              />
              <path
                d="M231.11,363.44c-0.67-0.79-1.32-1.21-1.31-1.63c0.01-0.44,0.56-1.09,1.01-1.25c0.69-0.24,1.26,0.29,1.22,1
	C232,362.06,231.58,362.54,231.11,363.44z"
              />
              <path
                d="M270.2,273.33c0.6,0.86,1.13,1.3,1.16,1.76c0.02,0.36-0.65,1.12-0.89,1.08c-0.51-0.1-1.2-0.55-1.31-0.98
	C269.06,274.81,269.65,274.25,270.2,273.33z"
              />
              <path
                d="M282.71,626.17c-0.56-0.9-1.16-1.44-1.08-1.85c0.08-0.42,0.79-0.88,1.29-0.98c0.25-0.05,0.93,0.69,0.91,1.04
	C283.8,624.87,283.29,625.31,282.71,626.17z"
              />
              <path
                d="M253.09,325.39c0.66,0.79,1.3,1.23,1.29,1.64c-0.01,0.43-0.57,1.07-1.02,1.23c-0.69,0.24-1.26-0.3-1.21-1.01
	C252.18,326.76,252.61,326.29,253.09,325.39z"
              />
              <path
                d="M492.54,384.09c-0.47-0.94-0.93-1.44-0.89-1.9c0.03-0.36,0.79-1.03,1-0.95c0.48,0.18,1.1,0.7,1.14,1.14
	C493.84,382.77,493.2,383.24,492.54,384.09z"
              />
              <path
                d="M517.68,275.39c-0.9,0.53-1.38,1.03-1.83,1.01c-0.37-0.01-1.06-0.75-1-0.98c0.14-0.48,0.65-1.11,1.09-1.19
	C516.33,274.17,516.83,274.79,517.68,275.39z"
              />
              <path
                d="M350.01,273.55c0.51,0.92,0.99,1.41,0.98,1.88c-0.01,0.36-0.75,1.05-0.98,0.99c-0.49-0.15-1.14-0.66-1.2-1.09
	C348.76,274.92,349.39,274.42,350.01,273.55z"
              />
              <path
                d="M238.72,409.75c-0.63-0.85-1.27-1.33-1.23-1.74c0.04-0.42,0.7-0.95,1.19-1.09c0.25-0.07,0.99,0.6,1,0.96
	C239.7,408.36,239.22,408.85,238.72,409.75z"
              />
              <path
                d="M263.64,467.21c-0.8,0.58-1.27,1.18-1.69,1.15c-0.43-0.03-1.11-0.67-1.12-1.07c-0.01-0.4,0.63-1.09,1.05-1.14
	C262.3,466.09,262.8,466.67,263.64,467.21z"
              />
              <path
                d="M467.53,366.47c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.39,0.79-0.96,1.28-1.09
	C466.45,365.35,466.93,365.98,467.53,366.47z"
              />
              <path
                d="M378.32,291.03c-0.87,0.62-1.3,1.15-1.77,1.19c-0.33,0.03-0.97-0.61-1-0.98c-0.03-0.38,0.5-1.12,0.83-1.14
	C376.84,290.08,377.35,290.54,378.32,291.03z"
              />
              <path
                d="M336.17,182.75c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.96-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C335,181.66,335.44,182.21,336.17,182.75z"
              />
              <path
                d="M268.91,445.22c-0.92,0.53-1.4,1.02-1.88,1.02c-0.33,0-0.92-0.69-0.91-1.07c0.01-0.38,0.61-1.06,0.94-1.05
	C267.55,444.14,268.01,444.65,268.91,445.22z"
              />
              <path
                d="M301.01,588.09c-0.1-0.11-0.26-0.21-0.28-0.33c-0.18-0.8-0.19-1.62,0.73-1.94c0.22-0.08,0.83,0.36,0.95,0.68
	C302.67,587.24,302.39,587.51,301.01,588.09z"
              />
              <path
                d="M321.77,184.19c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.97-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C320.61,183.1,321.04,183.65,321.77,184.19z"
              />
              <path
                d="M317.63,193.57c0.48,0.79,0.99,1.26,0.98,1.74c0,0.35-0.65,0.92-1.06,0.98c-0.73,0.11-1.22-0.47-1.06-1.19
	C316.58,194.65,317.06,194.29,317.63,193.57z"
              />
              <path
                d="M352.88,289.59c-0.87,0.62-1.3,1.16-1.77,1.2c-0.33,0.03-0.98-0.6-1-0.98c-0.03-0.38,0.5-1.12,0.82-1.14
	C351.41,288.64,351.92,289.1,352.88,289.59z"
              />
              <path
                d="M292.02,464.9c-0.76,0.53-1.27,1.12-1.55,1.03c-0.47-0.16-0.96-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.04-0.96
	C290.86,463.8,291.29,464.36,292.02,464.9z"
              />
              <path
                d="M499.97,253.55c0.48,0.79,0.99,1.26,0.98,1.74c0,0.35-0.65,0.92-1.06,0.98c-0.73,0.11-1.21-0.47-1.06-1.19
	C498.92,254.63,499.4,254.27,499.97,253.55z"
              />
              <path d="M451.8,9.69c1.17,0.15,1.88,0.23,2.7,0.34C453.41,11.96,453.22,11.96,451.8,9.69z" />
              <path
                d="M470.45,355.49c-0.92,0.53-1.4,1.02-1.88,1.02c-0.33,0-0.92-0.7-0.91-1.07c0.01-0.38,0.62-1.06,0.94-1.05
	C469.08,354.41,469.55,354.92,470.45,355.49z"
              />
              <path
                d="M299.95,364.87c0.57,0.73,1.05,1.08,1.14,1.52c0.15,0.72-0.33,1.3-1.06,1.19c-0.42-0.06-1.06-0.63-1.06-0.98
	C298.96,366.13,299.47,365.65,299.95,364.87z"
              />
              <path
                d="M387.56,165.59c0.44,1,0.87,1.53,0.81,2c-0.04,0.34-0.77,0.87-1.12,0.82c-0.39-0.06-1.01-0.71-0.97-1.04
	C386.33,166.89,386.88,166.47,387.56,165.59z"
              />
              <path d="M592.87,634.07c0.15-1.41,0.66-2.04,1.73-1.65c0.36,0.13,0.82,0.99,0.69,1.25C594.75,634.73,593.86,634.15,592.87,634.07z" />
              <path
                d="M514.43,302.39c-0.48-0.8-0.98-1.28-0.97-1.75c0.01-0.36,0.65-0.93,1.07-0.98c0.71-0.09,1.21,0.48,1.05,1.21
	C515.48,301.3,515,301.66,514.43,302.39z"
              />
              <path d="M429.07,634.13c-0.8,0-1.46,0-2.1,0c-0.49-1.07-0.1-1.71,0.84-1.79C428.7,632.26,429.23,632.81,429.07,634.13z" />
              <path
                d="M695.11,630.37c0.51,0.63,1.03,0.99,1.11,1.42c0.13,0.72-0.38,1.28-1.12,1.12c-0.39-0.09-0.91-0.68-0.94-1.07
	C694.14,631.45,694.66,631.02,695.11,630.37z"
              />
              <path
                d="M261.2,319.45c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.38,0.79-0.96,1.28-1.08
	C260.12,318.33,260.59,318.96,261.2,319.45z"
              />
              <path
                d="M271.31,474c-0.91,0.54-1.39,1.03-1.87,1.03c-0.33,0-0.92-0.69-0.92-1.06c0-0.38,0.61-1.06,0.93-1.05
	C269.94,472.93,270.41,473.44,271.31,474z"
              />
              <path
                d="M298.15,370.32c-0.59,0.5-1.04,1.14-1.33,1.07c-0.49-0.11-0.88-0.67-1.31-1.04c0.42-0.38,0.79-0.96,1.28-1.08
	C297.07,369.19,297.54,369.82,298.15,370.32z"
              />
              <path
                d="M273.31,415.95c-0.76,0.53-1.27,1.13-1.55,1.03c-0.47-0.16-0.97-0.7-1.11-1.18c-0.07-0.22,0.68-0.98,1.03-0.97
	C272.14,414.86,272.58,415.41,273.31,415.95z"
              />
              <path
                d="M229.16,365.59c-0.76,0.52-1.28,1.11-1.55,1.01c-0.47-0.16-0.95-0.71-1.09-1.19c-0.06-0.23,0.69-0.97,1.04-0.95
	C228.01,364.49,228.44,365.04,229.16,365.59z"
              />
              <path
                d="M322.24,397.44c-0.82,0.43-1.39,0.95-1.66,0.82c-0.45-0.21-0.87-0.8-0.95-1.29c-0.04-0.23,0.79-0.89,1.13-0.83
	C321.21,396.22,321.57,396.81,322.24,397.44z"
              />
              <path
                d="M350.56,308.19c-0.82,0.43-1.39,0.95-1.66,0.82c-0.45-0.21-0.87-0.8-0.95-1.29c-0.04-0.23,0.79-0.89,1.13-0.83
	C349.52,306.97,349.88,307.56,350.56,308.19z"
              />
              <path
                d="M518.43,282.57c-0.92,0.53-1.41,1.01-1.89,1.01c-0.33,0-0.91-0.7-0.9-1.08c0.01-0.38,0.62-1.06,0.95-1.04
	C517.07,281.48,517.54,281.99,518.43,282.57z"
              />
              <path d="M469.07,634.11c-0.87,0.06-1.59,0.1-2.34,0.15c-0.36-1.01-0.08-1.56,0.77-1.71C468.49,632.38,468.88,632.96,469.07,634.11z" />
              <path
                d="M506.63,332.39c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C505.55,333.69,506.06,333.25,506.63,332.39z"
              />
              <path
                d="M548.35,634.09c-0.81,0-1.63,0-2.54,0c0.04-1.03,0.52-1.57,1.29-1.47c0.56,0.07,1.05,0.6,1.58,0.93
	C548.57,633.73,548.46,633.91,548.35,634.09z"
              />
              <path
                d="M508.81,317.48c-0.55-0.75-1.09-1.17-1.13-1.64c-0.03-0.32,0.59-0.9,1.01-1c0.71-0.17,1.27,0.31,1.16,1.07
	C509.77,316.34,509.33,316.72,508.81,317.48z"
              />
              <path
                d="M645.7,461.1c0,0,0.08,0.08,0.08,0.08c-0.11-0.54-0.33-1.08-0.31-1.62c0.02-0.52,0.27-1.04,0.42-1.56
	c0.18,0.03,0.37,0.05,0.55,0.08c0.08,0.76,0.26,1.52,0.2,2.27C646.62,460.62,646.03,460.85,645.7,461.1z"
              />
              <path
                d="M468.5,373.7c-0.89,0.54-1.36,1.03-1.84,1.04c-0.32,0-0.91-0.68-0.91-1.05c0-0.38,0.6-1.06,0.92-1.05
	C467.14,372.65,467.61,373.15,468.5,373.7z"
              />
              <path
                d="M317.34,173.62c-0.9,0.53-1.37,1.02-1.84,1.02c-0.32,0-0.91-0.68-0.9-1.05c0.01-0.38,0.6-1.05,0.92-1.04
	C316,172.56,316.46,173.06,317.34,173.62z"
              />
              <path
                d="M344.77,296.05c0.49,0.78,0.91,1.18,0.96,1.61c0.08,0.77-0.51,1.21-1.2,1.03c-0.41-0.11-1-0.73-0.97-1.05
	C343.62,297.17,344.18,296.77,344.77,296.05z"
              />
              <path
                d="M310.7,78.15c-0.48-0.9-0.94-1.38-0.92-1.84c0.02-0.35,0.76-1,1-0.93c0.48,0.14,1.12,0.67,1.17,1.09
	C311.98,76.86,311.34,77.33,310.7,78.15z"
              />
              <path
                d="M296.76,269.31c-0.64,0.51-0.98,0.98-1.41,1.08c-0.72,0.17-1.25-0.31-1.27-1.04c-0.02-0.74,0.51-1.21,1.23-1.07
	C295.74,268.36,296.1,268.82,296.76,269.31z"
              />
              <path
                d="M341.76,247.5c-0.87,0.4-1.4,0.83-1.88,0.79c-0.33-0.03-0.82-0.72-0.85-1.14c-0.06-0.79,0.58-1.15,1.29-0.93
	C340.75,246.35,341.06,246.85,341.76,247.5z"
              />
              <path
                d="M230.24,380.04c-0.65,0.43-1.05,0.91-1.49,0.94c-0.74,0.05-1.21-0.54-0.97-1.25c0.13-0.39,0.76-0.86,1.14-0.84
	C229.31,378.93,229.67,379.51,230.24,380.04z"
              />
              <path
                d="M496.82,347.46c-0.54-0.73-1.09-1.16-1.12-1.62c-0.02-0.34,0.62-0.73,0.97-1.1c0.4,0.38,0.99,0.7,1.13,1.16
	C497.88,346.2,497.32,346.7,496.82,347.46z"
              />
              <path
                d="M688.08,631.11c0.47,0.63,1.08,1.14,0.99,1.4c-0.17,0.46-0.72,1.03-1.17,1.1c-0.72,0.11-1.18-0.51-0.97-1.23
	C687.05,631.97,687.56,631.67,688.08,631.11z"
              />
              <path
                d="M308.19,421.73c-0.57-0.87-1.17-1.38-1.1-1.78c0.07-0.41,0.75-0.89,1.23-0.99c0.25-0.05,0.94,0.65,0.92,1
	C309.23,420.42,308.73,420.87,308.19,421.73z"
              />
              <path
                d="M503.41,336.33c0.58,0.74,1.05,1.09,1.15,1.52c0.17,0.76-0.38,1.28-1.08,1.18c-0.42-0.06-1.08-0.63-1.08-0.97
	C502.39,337.59,502.91,337.12,503.41,336.33z"
              />
              <path
                d="M424.71,263.64c0.56,0.72,1.16,1.17,1.09,1.48c-0.1,0.47-0.66,0.84-1.02,1.25c-0.37-0.34-1.04-0.68-1.04-1.02
	C423.74,264.89,424.24,264.42,424.71,263.64z"
              />
              <path
                d="M465.7,384.79c-0.76,0.51-1.27,1.09-1.54,0.99c-0.46-0.17-0.94-0.72-1.07-1.2c-0.06-0.22,0.69-0.96,1.04-0.93
	C464.58,383.69,464.99,384.25,465.7,384.79z"
              />
              <path
                d="M455.77,329.63c0.52,0.78,1.04,1.23,1.05,1.69c0,0.34-0.64,0.91-1.05,0.98c-0.7,0.12-1.26-0.38-1.1-1.14
	C454.76,330.72,455.22,330.37,455.77,329.63z"
              />
              <path
                d="M458.24,338.41c-0.92,0.59-1.44,1.17-1.82,1.09c-0.44-0.09-0.91-0.72-1.06-1.21c-0.06-0.21,0.66-0.91,1.04-0.92
	C456.84,337.37,457.31,337.86,458.24,338.41z"
              />
              <path
                d="M291.53,354.42c-0.49-0.78-0.92-1.17-0.97-1.6c-0.09-0.76,0.48-1.23,1.19-1.03c0.41,0.12,1.02,0.72,0.98,1.03
	C292.67,353.29,292.11,353.69,291.53,354.42z"
              />
              <path
                d="M356.99,316.28c-0.57-0.72-1.17-1.16-1.11-1.47c0.1-0.47,0.65-0.85,1.01-1.27c0.37,0.34,1.04,0.67,1.05,1.02
	C357.95,315.01,357.45,315.49,356.99,316.28z"
              />
              <path
                d="M253.48,452.01c0.42,0.82,0.89,1.32,0.84,1.77c-0.04,0.34-0.71,0.82-1.14,0.86c-0.74,0.06-1.18-0.53-0.96-1.24
	C252.34,452.98,252.84,452.68,253.48,452.01z"
              />
              <path
                d="M243.46,340.53c-0.56-0.76-1.1-1.18-1.15-1.66c-0.03-0.32,0.6-0.92,1.02-1.02c0.72-0.17,1.28,0.32,1.17,1.08
	C244.43,339.38,243.98,339.76,243.46,340.53z"
              />
              <path
                d="M470.97,402.37c-0.96,0.5-1.44,0.94-1.94,0.96c-0.73,0.03-1.16-0.6-0.92-1.28c0.14-0.39,0.75-0.9,1.11-0.87
	C469.68,401.24,470.11,401.76,470.97,402.37z"
              />
              <path
                d="M466.57,351.95c-0.84,0.62-1.27,1.15-1.73,1.2c-0.32,0.03-0.97-0.59-1-0.96c-0.03-0.38,0.49-1.11,0.8-1.13
	C465.12,351.03,465.63,351.48,466.57,351.95z"
              />
              <path
                d="M340.86,173.3c0.67,0.86,1.22,1.28,1.27,1.74c0.04,0.33-0.57,0.98-0.96,1.04c-0.34,0.05-1.07-0.47-1.11-0.81
	C340,174.8,340.43,174.28,340.86,173.3z"
              />
              <path
                d="M333.5,419.71c0.62,0.84,1.15,1.25,1.18,1.71c0.03,0.36-0.61,1.11-0.85,1.07c-0.49-0.08-1.18-0.5-1.3-0.92
	C332.42,421.2,332.98,420.64,333.5,419.71z"
              />
              <path
                d="M302.08,81.04c-0.75,0.52-1.27,1.1-1.54,1.01c-0.46-0.16-0.94-0.71-1.08-1.19c-0.06-0.22,0.68-0.96,1.03-0.94
	C300.95,79.95,301.36,80.5,302.08,81.04z"
              />
              <path
                d="M381.24,287.56c-0.81,0.42-1.38,0.93-1.64,0.81c-0.44-0.21-0.85-0.8-0.93-1.28c-0.04-0.23,0.78-0.88,1.11-0.82
	C380.23,286.35,380.58,286.94,381.24,287.56z"
              />
              <path
                d="M338.43,211.5c-0.58,0.49-1.03,1.12-1.32,1.05c-0.48-0.11-0.86-0.67-1.28-1.04c0.42-0.38,0.79-0.94,1.27-1.06
	C337.38,210.38,337.84,211.01,338.43,211.5z"
              />
              <path
                d="M367.77,89.71c-0.59,0.57-0.88,1.09-1.3,1.22c-0.7,0.22-1.38-0.22-1.32-0.93c0.04-0.44,0.64-1.02,1.11-1.17
	C366.56,88.72,367.08,89.28,367.77,89.71z"
              />
              <path
                d="M274.43,280.05c-0.57-0.72-1.03-1.07-1.13-1.5c-0.17-0.75,0.35-1.27,1.07-1.15c0.42,0.07,1.07,0.62,1.07,0.94
	C275.43,278.81,274.92,279.27,274.43,280.05z"
              />
              <path
                d="M359.14,321.61c-0.64,0.51-0.98,0.98-1.41,1.08c-0.72,0.17-1.25-0.31-1.27-1.04c-0.02-0.74,0.51-1.21,1.23-1.07
	C358.12,320.66,358.48,321.12,359.14,321.61z"
              />
              <path d="M263.36,622.36c0.81-0.1,1.45-0.18,2.06-0.26c0.42,0.94,0.2,1.49-0.51,1.71C264.07,624.08,263.55,623.68,263.36,622.36z" />
              <path
                d="M245.84,432.83c-0.67,0.44-1.13,0.98-1.49,0.93c-0.41-0.06-0.74-0.66-1.11-1.02c0.43-0.38,0.8-0.94,1.3-1.07
	C244.8,431.6,245.26,432.28,245.84,432.83z"
              />
              <path
                d="M512.17,273.83c-0.56-0.76-1.1-1.18-1.15-1.66c-0.03-0.32,0.6-0.92,1.02-1.02c0.72-0.17,1.28,0.32,1.17,1.08
	C513.14,272.68,512.69,273.06,512.17,273.83z"
              />
              <path
                d="M304.51,481.75c-0.94,0.65-1.38,1.17-1.86,1.21c-0.34,0.02-0.98-0.59-1.02-0.96c-0.04-0.36,0.5-1.08,0.84-1.11
	C302.95,480.84,303.47,481.27,304.51,481.75z"
              />
              <path
                d="M382.46,634.05c-0.86,0-1.72,0-2.68,0c0.04-1,0.53-1.54,1.31-1.44c0.56,0.08,1.06,0.61,1.58,0.94
	C382.6,633.72,382.53,633.89,382.46,634.05z"
              />
              <path
                d="M308.1,255.34c-0.47-0.78-0.97-1.26-0.96-1.72c0.01-0.35,0.67-0.69,1.04-1.03c0.36,0.42,0.92,0.8,1.02,1.27
	C309.27,254.17,308.67,254.62,308.1,255.34z"
              />
              <path
                d="M493.92,374.97c-0.84,0.63-1.26,1.17-1.72,1.22c-0.32,0.03-0.97-0.58-1-0.95c-0.03-0.38,0.48-1.12,0.79-1.14
	C492.46,374.06,492.97,374.51,493.92,374.97z"
              />
              <path
                d="M283.82,451.08c-0.86,0.62-1.28,1.14-1.77,1.21c-0.68,0.1-1.26-0.4-1.1-1.14c0.09-0.41,0.62-1,0.96-1.01
	C282.39,450.13,282.88,450.59,283.82,451.08z"
              />
              <path
                d="M249.27,262.64c-0.91,0.54-1.37,1.02-1.87,1.06c-0.71,0.07-1.21-0.51-1-1.22c0.12-0.4,0.7-0.95,1.05-0.93
	C247.94,261.58,248.39,262.08,249.27,262.64z"
              />
              <path
                d="M282.85,443.88c-0.61,0.55-0.92,1.06-1.34,1.18c-0.72,0.21-1.37-0.27-1.29-0.97c0.05-0.44,0.68-1,1.15-1.14
	C281.66,442.86,282.17,443.43,282.85,443.88z"
              />
              <path
                d="M340.7,252.73c0.61,0.84,1.14,1.26,1.18,1.71c0.03,0.36-0.62,1.11-0.86,1.07c-0.49-0.08-1.17-0.51-1.3-0.93
	C339.62,254.21,340.18,253.65,340.7,252.73z"
              />
              <path
                d="M436.9,323.83c-0.48-0.9-0.94-1.38-0.92-1.84c0.02-0.35,0.76-1,1-0.93c0.48,0.14,1.12,0.67,1.17,1.09
	C438.18,322.54,437.54,323.01,436.9,323.83z"
              />
              <path
                d="M390.03,320.97c0.52,0.79,1.06,1.24,1.06,1.71c0,0.35-0.64,0.93-1.07,1c-0.7,0.11-1.27-0.38-1.11-1.16
	C389.02,322.08,389.48,321.72,390.03,320.97z"
              />
              <path
                d="M429.85,320.87c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C428.78,322.17,429.29,321.73,429.85,320.87z"
              />
              <path
                d="M507.63,263.28c-0.57-0.73-1.04-1.08-1.14-1.52c-0.17-0.76,0.36-1.28,1.08-1.17c0.43,0.07,1.09,0.63,1.08,0.96
	C508.65,262.02,508.13,262.49,507.63,263.28z"
              />
              <path
                d="M425.92,154.81c-0.51-0.77-0.95-1.15-1.01-1.59c-0.1-0.76,0.46-1.23,1.17-1.05c0.42,0.11,1.03,0.7,0.99,1.02
	C427.03,153.66,426.49,154.07,425.92,154.81z"
              />
              <path
                d="M501.64,341.92c-0.69,0.44-1.19,1-1.48,0.9c-0.47-0.15-1.07-0.71-1.12-1.15c-0.08-0.72,0.6-1.13,1.29-0.93
	C500.74,340.87,501.05,341.37,501.64,341.92z"
              />
              <path
                d="M492.75,257.5c-0.57-0.72-1.03-1.07-1.13-1.5c-0.17-0.75,0.35-1.27,1.07-1.15c0.42,0.07,1.07,0.62,1.07,0.94
	C493.76,256.26,493.25,256.72,492.75,257.5z"
              />
              <path
                d="M401.13,137.19c-0.87,0.67-1.33,1.3-1.72,1.25c-0.45-0.05-0.97-0.63-1.18-1.1c-0.08-0.18,0.58-0.96,0.95-1
	C399.64,136.29,400.15,136.73,401.13,137.19z"
              />
              <path
                d="M500.94,347.52c0.47,0.64,1.08,1.15,0.99,1.41c-0.17,0.47-0.72,1.03-1.18,1.1c-0.73,0.11-1.19-0.51-0.97-1.23
	C499.91,348.38,500.42,348.08,500.94,347.52z"
              />
              <path
                d="M275.67,484.29c-0.66,0.51-1.01,0.98-1.44,1.07c-0.72,0.15-1.25-0.33-1.25-1.07c-0.01-0.76,0.53-1.21,1.25-1.06
	C274.66,483.32,275.01,483.79,275.67,484.29z"
              />
              <path
                d="M509.11,324.7c-0.47-0.81-0.97-1.3-0.95-1.77c0.01-0.33,0.69-0.86,1.12-0.91c0.74-0.09,1.24,0.46,1.03,1.21
	C510.19,323.65,509.71,323.99,509.11,324.7z"
              />
              <path
                d="M266.59,376.82c-0.99,0.55-1.47,1.02-1.95,1.01c-0.34-0.01-0.92-0.67-0.92-1.03c0-0.36,0.6-1.01,0.94-1.01
	C265.14,375.79,265.62,376.27,266.59,376.82z"
              />
              <path
                d="M510.1,311.97c0.95-0.53,1.42-1,1.88-0.98c0.38,0.02,1.08,0.75,1.01,0.95c-0.17,0.49-0.65,1.1-1.1,1.18
	C511.51,313.19,511.01,312.59,510.1,311.97z"
              />
              <path
                d="M320.46,312.32c-0.56-0.84-1.07-1.28-1.1-1.75c-0.02-0.34,0.66-1.06,0.91-1.01c0.49,0.1,1.18,0.56,1.25,0.97
	C321.6,310.92,321.01,311.45,320.46,312.32z"
              />
              <path
                d="M417.26,324.04c-0.99,0.55-1.47,1.02-1.95,1.01c-0.34-0.01-0.92-0.67-0.92-1.03c0-0.36,0.6-1.01,0.94-1.01
	C415.81,323.01,416.29,323.49,417.26,324.04z"
              />
              <path
                d="M273.43,309.36c0.57,0.89,1.16,1.41,1.08,1.79c-0.09,0.42-0.76,0.89-1.25,0.99c-0.24,0.05-0.93-0.67-0.91-1.02
	C272.35,310.66,272.86,310.21,273.43,309.36z"
              />
              <path
                d="M435.46,259.08c0.49,0.79,0.92,1.19,0.97,1.63c0.08,0.77-0.51,1.23-1.21,1.04c-0.42-0.11-1.02-0.74-0.98-1.07
	C434.3,260.22,434.87,259.82,435.46,259.08z"
              />
              <path
                d="M506.16,325.29c0.52,0.79,1.06,1.25,1.06,1.71c0,0.35-0.64,0.92-1.07,0.99c-0.7,0.11-1.27-0.39-1.11-1.16
	C505.14,326.4,505.6,326.04,506.16,325.29z"
              />
              <path
                d="M434.29,168.49c-0.46-0.79-0.96-1.27-0.94-1.73c0.01-0.35,0.68-0.68,1.05-1.02c0.36,0.42,0.91,0.8,1,1.28
	C435.46,167.33,434.86,167.77,434.29,168.49z"
              />
              <path
                d="M263,277.41c-0.47-0.78-0.97-1.26-0.96-1.72c0.01-0.35,0.67-0.69,1.04-1.03c0.36,0.42,0.92,0.8,1.02,1.27
	C264.16,276.24,263.56,276.69,263,277.41z"
              />
              <path
                d="M470.98,362.72c-0.76,0.51-1.27,1.1-1.54,1c-0.46-0.17-0.94-0.71-1.07-1.19c-0.06-0.23,0.69-0.96,1.03-0.94
	C469.86,361.62,470.27,362.17,470.98,362.72z"
              />
              <path
                d="M403.28,166.3c-0.69,0.45-1.2,1.02-1.5,0.92c-0.47-0.16-1.08-0.72-1.13-1.17c-0.08-0.72,0.61-1.15,1.31-0.94
	C402.38,165.24,402.69,165.75,403.28,166.3z"
              />
              <path
                d="M379.8,119.45c0.49,0.79,0.92,1.19,0.97,1.63c0.08,0.77-0.51,1.23-1.21,1.04c-0.42-0.11-1.02-0.74-0.98-1.07
	C378.64,120.59,379.21,120.18,379.8,119.45z"
              />
              <path
                d="M403.28,139.14c0.5,0.77,0.93,1.16,0.99,1.6c0.1,0.77-0.49,1.22-1.18,1.05c-0.42-0.1-1.02-0.71-0.99-1.04
	C402.15,140.29,402.7,139.87,403.28,139.14z"
              />
              <path
                d="M396.81,276.46c-0.57-0.72-1.17-1.16-1.11-1.47c0.1-0.47,0.65-0.85,1.01-1.27c0.37,0.34,1.04,0.67,1.05,1.02
	C397.78,275.19,397.28,275.67,396.81,276.46z"
              />
              <path
                d="M322.93,174.73c-0.57-0.72-1.18-1.16-1.12-1.47c0.09-0.47,0.64-0.86,1-1.28c0.38,0.34,1.04,0.66,1.05,1.01
	C323.88,173.46,323.39,173.94,322.93,174.73z"
              />
              <path
                d="M505.44,258.55c-0.89,0.53-1.37,1.02-1.84,1.02c-0.32,0-0.91-0.68-0.9-1.05c0.01-0.38,0.6-1.05,0.92-1.04
	C504.1,257.49,504.56,257.99,505.44,258.55z"
              />
              <path
                d="M279.5,487.66c-0.65,0.5-1.11,1.1-1.41,1.03c-0.49-0.11-1.15-0.61-1.23-1.04c-0.14-0.7,0.5-1.19,1.21-1.04
	C278.49,486.69,278.85,487.16,279.5,487.66z"
              />
              <path
                d="M501.42,354.7c0.48,0.64,1.1,1.16,1,1.42c-0.17,0.47-0.73,1.05-1.19,1.12c-0.73,0.11-1.2-0.52-0.98-1.25
	C500.39,355.57,500.9,355.26,501.42,354.7z"
              />
              <path
                d="M498.73,368.82c-0.55-0.75-1.09-1.18-1.13-1.65c-0.03-0.32,0.59-0.9,1.01-1c0.71-0.17,1.26,0.32,1.15,1.07
	C499.69,367.68,499.25,368.06,498.73,368.82z"
              />
              <path
                d="M394.13,327.31c-0.54-0.73-1.09-1.16-1.12-1.62c-0.02-0.34,0.62-0.73,0.97-1.1c0.4,0.38,0.99,0.7,1.13,1.16
	C395.2,326.04,394.64,326.54,394.13,327.31z"
              />
              <path
                d="M270.41,466.83c-0.75,0.52-1.27,1.1-1.54,1.01c-0.46-0.16-0.94-0.71-1.08-1.19c-0.06-0.22,0.68-0.96,1.03-0.94
	C269.28,465.74,269.69,466.29,270.41,466.83z"
              />
              <path
                d="M360.64,170c-0.98,0.56-1.46,1.04-1.94,1.03c-0.34,0-0.93-0.66-0.93-1.02c0-0.36,0.59-1.01,0.93-1.02
	C359.18,168.98,359.66,169.45,360.64,170z"
              />
              <path
                d="M292.1,425c-0.59,0.49-0.97,1.04-1.35,1.05c-0.37,0-0.75-0.56-1.13-0.87c0.36-0.41,0.65-1.01,1.1-1.17
	C290.98,423.92,291.49,424.54,292.1,425z"
              />
              <path
                d="M496.38,260.47c-0.41-0.68-0.96-1.22-0.85-1.48c0.18-0.44,0.79-0.7,1.21-1.03c0.28,0.41,0.78,0.83,0.76,1.22
	C497.48,259.55,496.91,259.89,496.38,260.47z"
              />
              <path
                d="M287.28,177.69c-0.6-0.57-1.18-0.86-1.29-1.27c-0.18-0.72,0.32-1.3,1.06-1.21c0.41,0.05,0.98,0.58,1.05,0.96
	C288.16,176.54,287.67,177.01,287.28,177.69z"
              />
              <path
                d="M328.9,183.28c-0.63,0.56-0.95,1.06-1.37,1.16c-0.71,0.18-1.31-0.27-1.21-1.01c0.05-0.39,0.62-0.93,1.02-0.99
	C327.71,182.38,328.17,182.86,328.9,183.28z"
              />
              <path
                d="M292.35,484.74c0.49,0.63,1.03,1.03,1.02,1.4c-0.02,0.39-0.51,0.97-0.9,1.08c-0.71,0.18-1.27-0.31-1.18-1.05
	C291.34,485.75,291.84,485.39,292.35,484.74z"
              />
              <path
                d="M451.16,259.38c-0.56,0.52-1,1.18-1.25,1.11c-0.47-0.13-0.84-0.67-1.25-1.03c0.35-0.36,0.67-0.94,1.07-1
	C450.09,258.41,450.52,258.95,451.16,259.38z"
              />
              <path
                d="M279.7,447.88c-0.68,0.45-1.06,0.91-1.49,0.96c-0.73,0.08-1.24-0.45-1.03-1.16c0.11-0.37,0.73-0.83,1.13-0.83
	C278.68,446.85,279.06,447.38,279.7,447.88z"
              />
              <path
                d="M327.08,174.79c0.4,0.68,0.9,1.14,0.83,1.5c-0.07,0.39-0.63,0.92-1.03,0.97c-0.71,0.09-1.24-0.47-1.05-1.19
	C325.94,175.66,326.5,175.36,327.08,174.79z"
              />
              <path
                d="M389.12,127.83c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C388.1,126.79,388.48,127.33,389.12,127.83z"
              />
              <path
                d="M489.4,261.2c-0.53-0.67-1.08-1.06-1.06-1.42c0.03-0.38,0.58-0.99,0.93-1c0.35-0.02,0.96,0.53,1.03,0.91
	C490.37,260.04,489.86,260.49,489.4,261.2z"
              />
              <path
                d="M279.99,344.48c-0.54-0.61-1.2-1.06-1.13-1.34c0.11-0.48,0.68-0.86,1.05-1.28c0.35,0.37,0.94,0.7,0.98,1.1
	C280.94,343.35,280.43,343.8,279.99,344.48z"
              />
              <path
                d="M247.98,208.57c-0.59,0.5-0.96,1.07-1.35,1.08c-0.38,0.01-0.99-0.48-1.11-0.87c-0.22-0.71,0.31-1.27,1.01-1.22
	C246.95,207.59,247.35,208.11,247.98,208.57z"
              />
              <path
                d="M472.77,344.55c-0.65,0.43-1.1,0.96-1.46,0.91c-0.4-0.06-0.72-0.65-1.08-1.02c0.42-0.37,0.79-0.92,1.28-1.04
	C471.76,343.34,472.21,344.01,472.77,344.55z"
              />
              <path
                d="M354.47,311.62c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C353.69,310.39,353.96,311.01,354.47,311.62z"
              />
              <path
                d="M329.89,84.31c0.43,0.67,0.94,1.11,0.9,1.49c-0.04,0.39-0.62,0.73-0.97,1.09c-0.37-0.41-0.94-0.78-1.04-1.25
	C328.72,85.35,329.36,84.91,329.89,84.31z"
              />
              <path
                d="M442.63,258.72c0.45,0.72,0.97,1.17,0.91,1.53c-0.07,0.38-0.69,0.94-1.04,0.91c-0.36-0.02-0.93-0.64-0.95-1.02
	C441.52,259.79,442.09,259.39,442.63,258.72z"
              />
              <path
                d="M314.74,311.11c-0.73,0.47-1.17,0.97-1.53,0.92c-0.38-0.05-0.69-0.63-1.03-0.98c0.36-0.33,0.7-0.9,1.08-0.93
	C313.62,310.08,314.03,310.6,314.74,311.11z"
              />
              <path
                d="M380.5,126.79c0.46,0.67,0.94,1.06,0.97,1.48c0.05,0.74-0.55,1.2-1.24,0.97c-0.38-0.13-0.84-0.74-0.84-1.13
	C379.4,127.75,379.97,127.39,380.5,126.79z"
              />
              <path
                d="M270.95,447.05c0.51,0.62,1.16,1.1,1.09,1.39c-0.13,0.47-0.72,0.82-1.12,1.22c-0.33-0.38-0.91-0.74-0.93-1.14
	C269.96,448.13,270.49,447.7,270.95,447.05z"
              />
              <path
                d="M337.2,197.01c-0.57,0.51-0.92,1.08-1.3,1.1c-0.38,0.02-0.99-0.45-1.12-0.83c-0.24-0.7,0.28-1.26,0.97-1.22
	C336.17,196.08,336.57,196.58,337.2,197.01z"
              />
              <path
                d="M240.1,341.27c0.46,0.65,0.99,1.07,0.96,1.43c-0.03,0.39-0.55,0.96-0.94,1.04c-0.71,0.15-1.27-0.36-1.14-1.09
	C239.05,342.24,239.57,341.9,240.1,341.27z"
              />
              <path
                d="M295.55,354.47c0.45,0.66,0.95,1.07,0.97,1.49c0.02,0.68-0.5,1.26-1.23,1.01c-0.38-0.13-0.82-0.77-0.81-1.17
	C294.49,355.42,295.05,355.06,295.55,354.47z"
              />
              <path
                d="M410.99,145.71c0.43,0.67,0.95,1.12,0.91,1.51c-0.04,0.4-0.63,0.74-0.98,1.1c-0.38-0.42-0.95-0.79-1.06-1.27
	C409.79,146.77,410.45,146.32,410.99,145.71z"
              />
              <path
                d="M345.44,290.42c-0.71,0.49-1.13,1.01-1.49,0.98c-0.38-0.04-0.71-0.61-1.07-0.95c0.34-0.35,0.66-0.92,1.04-0.97
	C344.29,289.43,344.72,289.94,345.44,290.42z"
              />
              <path
                d="M331.55,172.52c-0.76,0.43-1.23,0.91-1.6,0.84c-0.38-0.07-0.66-0.68-0.99-1.05c0.38-0.31,0.76-0.87,1.15-0.87
	C330.48,171.43,330.87,171.98,331.55,172.52z"
              />
              <path
                d="M487,256.22c-0.67,0.55-1.05,1.1-1.41,1.09c-0.39-0.01-0.76-0.54-1.15-0.85c0.32-0.37,0.59-0.97,0.96-1.05
	C485.76,255.34,486.24,255.81,487,256.22z"
              />
              <path
                d="M492.82,341.05c0.51,0.57,1.01,0.89,1.12,1.31c0.19,0.72-0.33,1.27-1.01,1.18c-0.39-0.05-0.95-0.62-1-1.01
	C491.87,342.16,492.39,341.71,492.82,341.05z"
              />
              <path
                d="M514.11,295.26c-0.54-0.62-1.07-0.95-1.14-1.37c-0.13-0.73,0.41-1.26,1.13-1.1c0.4,0.08,0.92,0.64,0.95,1.03
	C515.09,294.19,514.57,294.61,514.11,295.26z"
              />
              <path
                d="M275.89,444.54c-0.74,0.45-1.2,0.95-1.57,0.89c-0.38-0.06-0.68-0.66-1.02-1.02c0.37-0.33,0.73-0.89,1.12-0.91
	C274.79,443.48,275.2,444.02,275.89,444.54z"
              />
              <path
                d="M461.82,341.75c-0.67,0.51-1.03,0.98-1.45,1.05c-0.72,0.12-1.28-0.38-1.13-1.11c0.08-0.39,0.69-0.88,1.1-0.91
	C460.71,340.76,461.13,341.27,461.82,341.75z"
              />
              <path
                d="M277.8,426.22c-0.66,0.49-1.03,0.96-1.46,1.03c-0.73,0.12-1.28-0.38-1.09-1.12c0.1-0.39,0.7-0.88,1.09-0.89
	C276.73,425.23,277.14,425.75,277.8,426.22z"
              />
              <path
                d="M343.06,261.7c-0.68,0.46-1.07,0.92-1.5,0.97c-0.74,0.09-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.84
	C342.03,260.66,342.41,261.2,343.06,261.7z"
              />
              <path
                d="M280.58,375.55c-0.58,0.5-0.95,1.06-1.33,1.07c-0.37,0.01-0.98-0.47-1.1-0.86c-0.22-0.71,0.31-1.25,1-1.2
	C279.57,374.59,279.96,375.1,280.58,375.55z"
              />
              <path
                d="M282.77,483.63c-0.63,0.47-1.06,1.01-1.42,0.98c-0.4-0.04-0.74-0.62-1.11-0.96c0.4-0.39,0.74-0.96,1.21-1.1
	C281.69,482.47,282.17,483.12,282.77,483.63z"
              />
              <path
                d="M479.02,389.23c0.49,0.7,1.02,1.13,0.98,1.49c-0.05,0.38-0.65,0.98-1,0.97c-0.36-0.01-0.95-0.6-1-0.98
	C477.97,390.35,478.52,389.93,479.02,389.23z"
              />
              <path
                d="M276.34,451.76c-0.69,0.44-1.08,0.88-1.51,0.92c-0.74,0.07-1.22-0.49-1-1.19c0.12-0.37,0.75-0.8,1.15-0.8
	C275.35,450.7,275.71,451.24,276.34,451.76z"
              />
              <path
                d="M470.47,378.27c-0.42-0.73-0.91-1.2-0.84-1.54c0.08-0.37,0.72-0.89,1.07-0.86c0.35,0.03,0.88,0.66,0.89,1.04
	C471.6,377.26,471.03,377.63,470.47,378.27z"
              />
              <path
                d="M461.59,254.91c-0.71,0.5-1.13,1.03-1.51,0.99c-0.39-0.03-0.72-0.62-1.08-0.96c0.35-0.35,0.67-0.94,1.05-0.99
	C460.43,253.92,460.87,254.43,461.59,254.91z"
              />
              <path
                d="M289.94,483.18c-0.63,0.45-1.06,0.98-1.42,0.94c-0.39-0.04-0.72-0.63-1.08-0.97c0.4-0.38,0.75-0.93,1.22-1.06
	C288.91,482.01,289.37,482.66,289.94,483.18z"
              />
              <path
                d="M476.23,340.22c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C474.99,339.31,475.46,339.79,476.23,340.22z"
              />
              <path
                d="M474.76,365.73c-0.7,0.5-1.11,1.03-1.48,0.99c-0.38-0.03-0.72-0.6-1.08-0.93c0.34-0.35,0.65-0.93,1.03-0.98
	C473.6,364.76,474.04,365.27,474.76,365.73z"
              />
              <path
                d="M464.32,346.38c-0.46-0.65-0.98-1.07-0.96-1.45c0.03-0.4,0.6-0.75,0.93-1.13c0.39,0.4,0.97,0.75,1.09,1.21
	C465.46,345.29,464.83,345.76,464.32,346.38z"
              />
              <path
                d="M320.25,83.17c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.9,1.07-0.94
	C319.13,82.15,319.55,82.68,320.25,83.17z"
              />
              <path
                d="M269.12,452.35c-0.6,0.5-0.99,1.06-1.39,1.07c-0.38,0.01-1-0.5-1.11-0.9c-0.21-0.72,0.32-1.29,1.04-1.21
	C268.1,451.35,268.48,451.87,269.12,452.35z"
              />
              <path
                d="M464.58,393.42c-0.48-0.66-1.11-1.19-1.01-1.45c0.17-0.47,0.78-0.77,1.2-1.15c0.31,0.4,0.85,0.8,0.85,1.2
	C465.63,392.41,465.08,392.8,464.58,393.42z"
              />
              <path
                d="M483.8,386.64c-0.6,0.48-0.99,1.03-1.36,1.02c-0.39-0.02-0.75-0.58-1.13-0.9c0.37-0.4,0.68-0.98,1.14-1.14
	C482.7,385.54,483.2,386.16,483.8,386.64z"
              />
              <path
                d="M297.21,482.43c-0.69,0.46-1.11,0.97-1.48,0.93c-0.4-0.04-0.99-0.54-1.06-0.93c-0.14-0.71,0.42-1.21,1.14-1.06
	C296.23,481.46,296.57,481.93,297.21,482.43z"
              />
              <path
                d="M337.13,170.22c0.51,0.59,1.14,1.04,1.08,1.32c-0.11,0.46-0.67,0.82-1.04,1.22c-0.34-0.36-0.91-0.7-0.95-1.09
	C336.18,171.3,336.69,170.87,337.13,170.22z"
              />
              <path
                d="M243.02,213.93c-0.41-0.68-0.96-1.22-0.85-1.48c0.18-0.44,0.79-0.7,1.21-1.03c0.28,0.41,0.78,0.83,0.76,1.22
	C244.12,213,243.55,213.35,243.02,213.93z"
              />
              <path
                d="M241.98,263.28c-0.59,0.5-1.07,1.15-1.32,1.07c-0.47-0.14-0.82-0.71-1.21-1.1c0.37-0.34,0.72-0.93,1.12-0.96
	C240.93,262.25,241.35,262.81,241.98,263.28z"
              />
              <path
                d="M511.81,286.61c0.71-0.49,1.13-1.01,1.49-0.97c0.38,0.04,0.71,0.61,1.06,0.95c-0.35,0.34-0.67,0.92-1.05,0.97
	C512.95,287.6,512.53,287.09,511.81,286.61z"
              />
              <path
                d="M327.73,311.36c-0.52-0.59-1.1-0.95-1.11-1.33c-0.01-0.41,0.42-1.06,0.81-1.2c0.74-0.26,1.27,0.33,1.26,1.01
	C328.68,310.26,328.18,310.68,327.73,311.36z"
              />
              <path
                d="M496.4,337.19c0.43,0.67,0.94,1.11,0.9,1.49c-0.04,0.39-0.63,0.73-0.97,1.09c-0.37-0.41-0.94-0.78-1.04-1.25
	C495.22,338.23,495.87,337.78,496.4,337.19z"
              />
              <path
                d="M345.89,171.27c-0.64,0.46-1.07,1.01-1.46,0.98c-0.38-0.03-0.96-0.59-1.04-0.99c-0.14-0.71,0.38-1.28,1.13-1.12
	C344.95,170.22,345.29,170.75,345.89,171.27z"
              />
              <path
                d="M334.67,81.62c-0.65,0.54-1,1.03-1.42,1.12c-0.72,0.14-1.31-0.34-1.19-1.07c0.07-0.39,0.67-0.92,1.07-0.97
	C333.51,80.65,333.96,81.16,334.67,81.62z"
              />
              <path
                d="M252.07,145.27c0.46,0.63,1.06,1.11,0.97,1.39c-0.13,0.45-0.72,0.77-1.11,1.14c-0.32-0.38-0.88-0.75-0.88-1.14
	C251.04,146.28,251.58,145.89,252.07,145.27z"
              />
              <path
                d="M278.82,456.46c-0.55-0.57-1.12-0.91-1.14-1.28c-0.02-0.38,0.43-1,0.8-1.14c0.68-0.24,1.28,0.2,1.25,0.93
	C279.71,455.39,279.25,455.79,278.82,456.46z"
              />
              <path
                d="M376.51,126.36c-0.46-0.65-0.98-1.06-0.95-1.43c0.03-0.38,0.55-0.95,0.95-1.04c0.72-0.16,1.26,0.37,1.14,1.09
	C377.56,125.4,377.04,125.74,376.51,126.36z"
              />
              <path
                d="M271.73,441.21c-0.57,0.55-0.88,1.08-1.3,1.19c-0.76,0.2-1.28-0.4-1.22-1.06c0.04-0.4,0.62-0.98,1.03-1.04
	C270.61,440.24,271.07,440.77,271.73,441.21z"
              />
              <path
                d="M285.32,422c0.64-0.52,0.98-1.01,1.4-1.1c0.72-0.16,1.3,0.33,1.17,1.06c-0.07,0.39-0.66,0.91-1.06,0.96
	C286.46,422.96,286.03,422.45,285.32,422z"
              />
              <path
                d="M311.13,394.42c-0.62,0.55-0.94,1.03-1.36,1.14c-0.7,0.17-1.29-0.27-1.19-1.01c0.06-0.39,0.61-0.92,1.01-0.97
	C309.96,393.52,310.41,394,311.13,394.42z"
              />
              <path
                d="M280.24,494.94c-0.75,0.41-1.21,0.88-1.58,0.81c-0.37-0.07-0.65-0.66-0.97-1.02c0.37-0.31,0.75-0.86,1.11-0.85
	C279.19,493.88,279.57,494.41,280.24,494.94z"
              />
              <path
                d="M465.11,337.74c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C464.02,336.59,464.5,337.23,465.11,337.74z"
              />
              <path
                d="M475.79,356.2c-0.44-0.72-0.94-1.18-0.87-1.53c0.07-0.37,0.7-0.91,1.05-0.88c0.35,0.02,0.89,0.64,0.91,1.02
	C476.89,355.17,476.33,355.55,475.79,356.2z"
              />
              <path
                d="M309.15,80.59c-0.64,0.45-1.07,1-1.44,0.95c-0.4-0.05-0.73-0.63-1.1-0.99c0.41-0.38,0.76-0.94,1.24-1.08
	C308.11,79.41,308.57,80.07,309.15,80.59z"
              />
              <path
                d="M463.36,252.56c-0.46-0.65-0.98-1.06-0.95-1.43c0.03-0.38,0.55-0.95,0.95-1.04c0.72-0.16,1.26,0.37,1.14,1.09
	C464.42,251.6,463.89,251.94,463.36,252.56z"
              />
              <path
                d="M477.41,394.62c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.09-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.84
	C476.39,393.58,476.77,394.12,477.41,394.62z"
              />
              <path
                d="M272.99,455.63c-0.76,0.42-1.22,0.89-1.58,0.82c-0.38-0.07-0.65-0.67-0.97-1.04c0.38-0.31,0.75-0.86,1.13-0.86
	C271.94,454.54,272.32,455.09,272.99,455.63z"
              />
              <path
                d="M351.59,169.01c0.52,0.64,1.03,0.99,1.09,1.41c0.11,0.74-0.46,1.23-1.16,1.07c-0.39-0.09-0.9-0.67-0.92-1.06
	C350.58,170.06,351.11,169.65,351.59,169.01z"
              />
              <path
                d="M316.42,79.93c-0.76,0.41-1.24,0.88-1.6,0.8c-0.38-0.08-0.64-0.68-0.96-1.05c0.38-0.31,0.76-0.84,1.15-0.85
	C315.38,78.84,315.75,79.39,316.42,79.93z"
              />
              <path
                d="M369.59,111.3c-0.63,0.45-1.06,0.98-1.42,0.94c-0.39-0.04-0.72-0.63-1.08-0.97c0.4-0.38,0.75-0.93,1.22-1.06
	C368.57,110.14,369.02,110.79,369.59,111.3z"
              />
              <path
                d="M470.23,394.93c-0.68,0.54-1.08,1.09-1.45,1.08c-0.39-0.01-0.76-0.57-1.14-0.88c0.33-0.37,0.61-0.98,1-1.04
	C469,394.01,469.47,394.5,470.23,394.93z"
              />
              <path
                d="M383.86,122.94c0.47,0.68,0.96,1.07,0.98,1.5c0.05,0.75-0.56,1.21-1.26,0.99c-0.39-0.13-0.85-0.75-0.85-1.15
	C382.75,123.9,383.33,123.54,383.86,122.94z"
              />
              <path
                d="M470.69,249.13c0.46,0.72,0.99,1.17,0.93,1.53c-0.07,0.38-0.69,0.93-1.05,0.92c-0.35-0.01-0.91-0.64-0.94-1.02
	C469.6,250.19,470.16,249.79,470.69,249.13z"
              />
              <path
                d="M316.34,206.12c-0.57,0.53-1.02,1.19-1.27,1.13c-0.48-0.13-0.85-0.68-1.27-1.05c0.36-0.36,0.69-0.96,1.09-1.01
	C315.26,205.14,315.7,205.68,316.34,206.12z"
              />
              <path
                d="M277.56,439.14c0.45,0.72,0.97,1.17,0.91,1.53c-0.07,0.38-0.69,0.94-1.04,0.91c-0.36-0.02-0.93-0.64-0.95-1.02
	C276.45,440.2,277.02,439.81,277.56,439.14z"
              />
              <path
                d="M301.78,429.16c-0.53-0.67-1.09-1.07-1.06-1.43c0.03-0.38,0.59-1.01,0.94-1.02c0.36-0.01,0.99,0.54,1.05,0.92
	C302.77,427.99,302.25,428.44,301.78,429.16z"
              />
              <path
                d="M510.54,299.07c-0.43-0.67-0.89-1.07-0.91-1.48c-0.03-0.74,0.57-1.18,1.25-0.94c0.38,0.13,0.82,0.76,0.8,1.14
	C511.67,298.16,511.09,298.5,510.54,299.07z"
              />
              <path
                d="M477.67,251.37c-0.52-0.61-1.16-1.06-1.1-1.34c0.11-0.47,0.68-0.84,1.05-1.25c0.34,0.37,0.92,0.71,0.96,1.1
	C478.62,250.28,478.1,250.71,477.67,251.37z"
              />
              <path
                d="M289.54,396.46c-0.66,0.49-1.02,0.96-1.44,1.03c-0.72,0.12-1.26-0.39-1.1-1.1c0.09-0.38,0.69-0.86,1.09-0.89
	C288.45,395.47,288.86,395.98,289.54,396.46z"
              />
              <path
                d="M329.36,396.33c-0.62,0.55-0.94,1.04-1.35,1.15c-0.7,0.18-1.29-0.27-1.2-1c0.05-0.39,0.61-0.92,1-0.98
	C328.19,395.44,328.64,395.91,329.36,396.33z"
              />
              <path
                d="M500.91,334.45c-0.62,0.53-0.97,1.08-1.35,1.1c-0.39,0.02-1.04-0.4-1.16-0.77c-0.23-0.68,0.22-1.27,0.97-1.2
	C499.8,333.61,500.21,334.03,500.91,334.45z"
              />
              <path
                d="M312.17,215.79c0.52,0.6,1.17,1.05,1.1,1.34c-0.11,0.47-0.68,0.83-1.06,1.24c-0.34-0.36-0.92-0.71-0.96-1.1
	C311.22,216.88,311.73,216.45,312.17,215.79z"
              />
              <path
                d="M432.14,263.31c0.41,0.68,0.85,1.09,0.87,1.51c0.03,0.7-0.52,1.21-1.22,0.95c-0.37-0.14-0.8-0.78-0.78-1.17
	C431.03,264.23,431.61,263.89,432.14,263.31z"
              />
              <path
                d="M459.81,333.12c0.49,0.63,1.03,1.03,1.02,1.4c-0.02,0.39-0.51,0.97-0.9,1.08c-0.71,0.18-1.27-0.31-1.18-1.05
	C458.8,334.12,459.31,333.76,459.81,333.12z"
              />
              <path
                d="M332.72,186.76c-0.66,0.5-1.06,1.03-1.42,1.01c-0.4-0.02-1.03-0.48-1.12-0.86c-0.17-0.7,0.34-1.23,1.07-1.13
	C331.67,185.84,332.04,186.29,332.72,186.76z"
              />
              <path
                d="M298.86,477.04c0.49,0.71,1.02,1.15,0.97,1.5c-0.05,0.38-0.66,0.95-1.02,0.95c-0.35,0-0.93-0.61-0.97-0.99
	C297.81,478.15,298.35,477.73,298.86,477.04z"
              />
              <path
                d="M437.81,314.93c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.36-0.34,0.69-0.9,1.07-0.94
	C436.7,313.91,437.11,314.44,437.81,314.93z"
              />
              <path
                d="M235.58,397.5c-0.68,0.46-1.07,0.93-1.5,0.98c-0.73,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C234.56,396.46,234.94,397,235.58,397.5z"
              />
              <path
                d="M509.57,308.52c-0.67,0.55-1.05,1.11-1.43,1.11c-0.39,0-0.77-0.55-1.16-0.86c0.32-0.38,0.59-0.99,0.97-1.06
	C508.33,307.63,508.81,308.11,509.57,308.52z"
              />
              <path
                d="M335.38,175.81c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C334.35,174.77,334.74,175.31,335.38,175.81z"
              />
              <path
                d="M264.6,315.56c-0.77,0.4-1.25,0.85-1.61,0.77c-0.38-0.08-0.63-0.69-0.94-1.06c0.39-0.3,0.77-0.83,1.16-0.83
	C263.57,314.44,263.93,315,264.6,315.56z"
              />
              <path
                d="M295.93,428.25c-0.55,0.53-0.89,1.11-1.26,1.14c-0.38,0.03-1.01-0.41-1.15-0.79c-0.26-0.69,0.23-1.27,0.93-1.25
	C294.87,427.36,295.28,427.83,295.93,428.25z"
              />
              <path
                d="M350.53,281.03c0.43,0.67,0.95,1.12,0.91,1.51c-0.04,0.4-0.63,0.74-0.98,1.1c-0.38-0.42-0.95-0.79-1.06-1.27
	C349.33,282.08,349.99,281.63,350.53,281.03z"
              />
              <path
                d="M445.37,170.99c-0.48-0.66-0.97-1.03-1.01-1.46c-0.08-0.75,0.51-1.21,1.21-1.01c0.39,0.11,0.87,0.72,0.87,1.1
	C446.44,170,445.88,170.37,445.37,170.99z"
              />
              <path
                d="M320.4,170.07c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C319.63,168.84,319.9,169.47,320.4,170.07z"
              />
              <path
                d="M381.77,310.12c-0.57-0.62-1.15-0.98-1.15-1.33c0-0.38,0.52-1.02,0.87-1.05c0.35-0.04,1.01,0.47,1.09,0.83
	C382.66,308.92,382.18,309.4,381.77,310.12z"
              />
              <path
                d="M505.88,265.6c-0.56,0.54-1,1.21-1.26,1.14c-0.49-0.12-0.86-0.66-1.29-1.03c0.36-0.36,0.68-0.96,1.08-1.02
	C504.77,264.63,505.23,265.17,505.88,265.6z"
              />
              <path
                d="M284.27,458.29c-0.65,0.53-0.98,1.01-1.41,1.1c-0.72,0.15-1.3-0.33-1.17-1.06c0.07-0.39,0.66-0.91,1.06-0.95
	C283.13,457.34,283.57,457.84,284.27,458.29z"
              />
              <path
                d="M414.88,149.11c0.5,0.6,1.13,1.06,1.06,1.33c-0.12,0.46-0.68,0.81-1.06,1.21c-0.33-0.36-0.9-0.71-0.93-1.1
	C413.92,150.18,414.44,149.75,414.88,149.11z"
              />
              <path
                d="M365.85,170.78c-0.42-0.7-0.92-1.16-0.86-1.54c0.06-0.4,0.65-0.72,1.01-1.08c0.36,0.43,0.92,0.83,1.01,1.31
	C367.08,169.75,366.41,170.18,365.85,170.78z"
              />
              <path
                d="M279.65,281.63c-0.63,0.45-1.06,1-1.44,0.97c-0.38-0.03-0.72-0.62-1.08-0.97c0.39-0.39,0.74-0.97,1.2-1.09
	C278.6,280.47,279.06,281.12,279.65,281.63z"
              />
              <path
                d="M261.26,624.88c0.45,0.7,0.96,1.16,0.9,1.51c-0.07,0.38-0.7,0.93-1.05,0.91c-0.37-0.03-0.94-0.64-0.96-1.01
	C260.13,625.92,260.71,625.53,261.26,624.88z"
              />
              <path
                d="M327.69,169.21c-0.77,0.4-1.25,0.86-1.61,0.78c-0.38-0.08-0.63-0.69-0.94-1.07c0.39-0.3,0.78-0.83,1.16-0.83
	C326.66,168.09,327.03,168.65,327.69,169.21z"
              />
              <path
                d="M496.3,364.13c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C495.21,362.98,495.69,363.62,496.3,364.13z"
              />
              <path
                d="M472.63,396.93c0.47,0.68,0.96,1.07,0.98,1.5c0.05,0.75-0.56,1.21-1.26,0.99c-0.39-0.13-0.85-0.75-0.85-1.15
	C471.52,397.89,472.1,397.53,472.63,396.93z"
              />
              <path
                d="M269.81,459.58c-0.61,0.49-1.01,1.05-1.38,1.03c-0.4-0.02-0.77-0.58-1.15-0.91c0.38-0.41,0.69-0.99,1.16-1.16
	C268.69,458.46,269.2,459.09,269.81,459.58z"
              />
              <path
                d="M342.53,314.39c0.48,0.7,1,1.14,0.95,1.49c-0.05,0.38-0.65,0.95-1,0.94c-0.35-0.01-0.92-0.6-0.96-0.98
	C341.48,315.49,342.02,315.07,342.53,314.39z"
              />
              <path
                d="M363.49,99.43c0.56,0.61,1.11,0.93,1.2,1.35c0.16,0.74-0.41,1.27-1.12,1.15c-0.4-0.07-0.94-0.63-0.99-1.02
	C362.54,100.54,363.06,100.1,363.49,99.43z"
              />
              <path
                d="M273.25,478.56c-0.42-0.73-0.92-1.2-0.84-1.55c0.08-0.38,0.73-0.9,1.08-0.87c0.36,0.04,0.9,0.67,0.91,1.05
	C274.41,477.56,273.82,477.93,273.25,478.56z"
              />
              <path
                d="M264.09,368.12c0.47,0.71,1,1.15,0.95,1.51c-0.06,0.38-0.67,0.96-1.02,0.94c-0.36-0.01-0.94-0.61-0.98-1
	C263.01,369.22,263.56,368.81,264.09,368.12z"
              />
              <path
                d="M470.24,371.32c-0.52-0.58-1.08-0.94-1.1-1.32c-0.02-0.4,0.42-1.05,0.8-1.18c0.73-0.26,1.26,0.33,1.25,1
	C471.18,370.24,470.68,370.65,470.24,371.32z"
              />
              <path
                d="M488.3,330.49c0.5,0.6,1.13,1.06,1.06,1.33c-0.12,0.46-0.68,0.81-1.06,1.21c-0.33-0.36-0.9-0.71-0.93-1.1
	C487.34,331.55,487.85,331.13,488.3,330.49z"
              />
              <path
                d="M238.46,346.61c-0.73,0.47-1.17,0.98-1.55,0.94c-0.38-0.05-0.7-0.64-1.04-1c0.36-0.34,0.71-0.91,1.09-0.94
	C237.34,345.58,237.76,346.11,238.46,346.61z"
              />
              <path
                d="M249.86,203.06c0.52,0.64,1.05,1,1.11,1.42c0.12,0.75-0.47,1.24-1.18,1.08c-0.39-0.09-0.91-0.68-0.93-1.07
	C248.84,204.12,249.38,203.71,249.86,203.06z"
              />
              <path
                d="M325.78,187.2c-0.64,0.51-1.02,1.05-1.4,1.06c-0.4,0-1.03-0.45-1.14-0.83c-0.21-0.71,0.28-1.27,1.03-1.18
	C324.7,186.3,325.1,186.75,325.78,187.2z"
              />
              <path
                d="M371.71,168.64c0.64-0.52,0.97-1,1.39-1.09c0.71-0.15,1.28,0.32,1.15,1.04c-0.07,0.39-0.65,0.9-1.04,0.95
	C372.84,169.59,372.4,169.09,371.71,168.64z"
              />
              <path
                d="M273.63,628.83c-0.56,0.54-1,1.21-1.25,1.15c-0.49-0.12-0.87-0.66-1.29-1.03c0.36-0.37,0.67-0.97,1.08-1.03
	C272.53,627.87,272.98,628.4,273.63,628.83z"
              />
              <path
                d="M275.15,437.29c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.36-0.34,0.69-0.9,1.07-0.94
	C274.03,436.27,274.45,436.8,275.15,437.29z"
              />
              <path
                d="M514.34,279.46c-0.68,0.45-1.06,0.91-1.49,0.96c-0.73,0.08-1.24-0.45-1.03-1.16c0.11-0.37,0.73-0.83,1.13-0.83
	C513.33,278.43,513.71,278.96,514.34,279.46z"
              />
              <path
                d="M474.29,358.64c-0.7,0.47-1.13,0.98-1.5,0.94c-0.4-0.04-1-0.55-1.08-0.94c-0.14-0.72,0.43-1.22,1.15-1.08
	C473.29,357.65,473.63,358.12,474.29,358.64z"
              />
              <path
                d="M504.3,330.84c-0.7,0.45-1.1,0.91-1.55,0.95c-0.75,0.07-1.25-0.51-1.03-1.22c0.12-0.38,0.77-0.83,1.17-0.81
	C503.29,329.77,503.67,330.32,504.3,330.84z"
              />
              <path
                d="M306.84,178c-0.64,0.57-0.96,1.07-1.38,1.18c-0.72,0.18-1.33-0.28-1.23-1.02c0.05-0.39,0.63-0.94,1.03-1
	C305.64,177.09,306.1,177.58,306.84,178z"
              />
              <path
                d="M370.09,118.54c-0.65,0.43-1.1,0.96-1.46,0.91c-0.4-0.06-0.72-0.65-1.08-1.02c0.42-0.37,0.79-0.92,1.28-1.04
	C369.08,117.33,369.52,118.01,370.09,118.54z"
              />
              <path
                d="M448.78,317.47c-0.58,0.52-1.04,1.17-1.29,1.1c-0.48-0.14-0.84-0.69-1.24-1.07c0.37-0.35,0.7-0.95,1.1-0.99
	C447.71,316.46,448.14,317.01,448.78,317.47z"
              />
              <path
                d="M462.88,331.97c-0.45-0.64-0.98-1.06-0.95-1.43c0.03-0.39,0.59-0.74,0.92-1.11c0.38,0.39,0.95,0.74,1.07,1.2
	C464,330.9,463.38,331.36,462.88,331.97z"
              />
              <path
                d="M348.09,279.33c-0.69,0.51-1.09,1.05-1.46,1.02c-0.39-0.03-0.74-0.58-1.1-0.91c0.33-0.36,0.63-0.94,1.01-1
	C346.9,278.39,347.35,278.88,348.09,279.33z"
              />
              <path
                d="M508.36,270.33c-0.55-0.62-1.09-0.96-1.16-1.38c-0.13-0.74,0.42-1.27,1.14-1.12c0.4,0.08,0.93,0.65,0.96,1.04
	C509.34,269.24,508.81,269.67,508.36,270.33z"
              />
              <path
                d="M336.6,205.81c-0.53-0.62-1.19-1.07-1.12-1.36c0.12-0.48,0.69-0.85,1.07-1.26c0.35,0.37,0.93,0.71,0.97,1.12
	C337.56,204.69,337.04,205.13,336.6,205.81z"
              />
              <path
                d="M440.47,316.54c0.46,0.67,0.97,1.08,0.98,1.51c0.02,0.69-0.51,1.27-1.25,1.02c-0.39-0.13-0.83-0.78-0.82-1.18
	C439.4,317.5,439.96,317.13,440.47,316.54z"
              />
              <path
                d="M380.03,169.53c-0.44-0.67-0.91-1.07-0.93-1.5c-0.03-0.74,0.58-1.19,1.27-0.95c0.38,0.14,0.83,0.76,0.81,1.15
	C381.16,168.61,380.57,168.95,380.03,169.53z"
              />
              <path
                d="M280.9,462.21c-0.71,0.49-1.13,1.01-1.49,0.97c-0.38-0.04-0.71-0.61-1.06-0.95c0.35-0.34,0.67-0.92,1.05-0.97
	C279.76,461.21,280.19,461.73,280.9,462.21z"
              />
              <path
                d="M302.13,476.18c-0.46-0.65-0.99-1.07-0.96-1.45c0.03-0.39,0.56-0.96,0.96-1.05c0.72-0.16,1.28,0.37,1.15,1.11
	C303.2,475.21,302.67,475.55,302.13,476.18z"
              />
              <path
                d="M280.83,435.33c0.49,0.63,1.02,1.02,1.01,1.38c-0.02,0.38-0.51,0.96-0.89,1.06c-0.7,0.19-1.26-0.31-1.16-1.04
	C279.84,436.33,280.34,435.97,280.83,435.33z"
              />
              <path
                d="M268.67,278.93c-0.71,0.48-1.14,0.99-1.51,0.95c-0.38-0.05-0.7-0.62-1.05-0.97c0.35-0.34,0.68-0.91,1.06-0.96
	C267.54,277.92,267.96,278.44,268.67,278.93z"
              />
              <path
                d="M475.49,349.02c-0.53-0.67-1.09-1.07-1.06-1.43c0.03-0.38,0.59-1.01,0.94-1.02c0.36-0.01,0.99,0.54,1.05,0.92
	C476.48,347.85,475.95,348.31,475.49,349.02z"
              />
              <path
                d="M248.29,268.33c0.45,0.72,0.96,1.16,0.9,1.52c-0.06,0.37-0.68,0.92-1.03,0.9c-0.35-0.02-0.9-0.63-0.93-1.01
	C247.21,269.39,247.76,268.99,248.29,268.33z"
              />
              <path
                d="M263.83,474.28c-0.64,0.56-1.03,1.16-1.37,1.14c-0.38-0.03-0.87-0.59-1.01-1.01c-0.07-0.22,0.49-0.93,0.78-0.93
	C262.65,473.46,263.09,473.87,263.83,474.28z"
              />
              <path
                d="M414.54,271.27c-0.52-0.61-1.08-0.98-1.08-1.35c0-0.39,0.47-0.99,0.86-1.11c0.7-0.21,1.29,0.25,1.22,1
	C415.5,270.23,415.01,270.61,414.54,271.27z"
              />
              <path
                d="M325.07,180.17c-0.72,0.46-1.16,0.97-1.54,0.92c-0.41-0.05-1-0.59-1.07-0.98c-0.12-0.73,0.46-1.22,1.2-1.06
	C324.09,179.15,324.42,179.64,325.07,180.17z"
              />
              <path
                d="M341.47,302.94c-0.58-0.62-1.18-0.98-1.18-1.34c0-0.38,0.53-1.03,0.89-1.07c0.35-0.04,1.01,0.47,1.1,0.84
	C342.37,301.72,341.88,302.2,341.47,302.94z"
              />
              <path
                d="M433.2,317.28c0.5,0.65,1,1.01,1.05,1.43c0.09,0.74-0.48,1.23-1.18,1.04c-0.39-0.1-0.88-0.69-0.9-1.08
	C432.16,318.3,432.71,317.91,433.2,317.28z"
              />
              <path
                d="M374.21,121.76c-0.61,0.49-1.01,1.05-1.41,1.06c-0.38,0-0.99-0.52-1.1-0.92c-0.2-0.72,0.33-1.29,1.06-1.19
	C373.2,120.76,373.58,121.28,374.21,121.76z"
              />
              <path
                d="M497.53,354.31c-0.51-0.68-1.06-1.09-1.03-1.46c0.03-0.38,0.62-0.99,0.96-1c0.36,0,0.97,0.56,1.03,0.94
	C498.54,353.15,498.01,353.6,497.53,354.31z"
              />
              <path
                d="M240.61,415.22c-0.72,0.47-1.15,0.98-1.52,0.94c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.91,1.07-0.94
	C239.49,414.2,239.91,414.72,240.61,415.22z"
              />
              <path
                d="M468.06,349.74c-0.41-0.69-0.91-1.15-0.85-1.52c0.06-0.4,0.64-0.72,1-1.07c0.36,0.43,0.9,0.82,1,1.29
	C469.26,348.73,468.61,349.15,468.06,349.74z"
              />
              <path
                d="M359.63,162.68c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C358.39,161.77,358.86,162.25,359.63,162.68z"
              />
              <path
                d="M453.44,327.79c-0.64,0.52-1.02,1.07-1.42,1.07c-0.4,0.01-1.04-0.46-1.16-0.84c-0.21-0.72,0.29-1.29,1.04-1.2
	C452.35,326.88,452.75,327.34,453.44,327.79z"
              />
              <path
                d="M259.33,464.16c-0.68,0.48-1.19,1.06-1.45,0.96c-0.47-0.17-0.79-0.74-1.17-1.15c0.4-0.3,0.79-0.82,1.2-0.84
	C258.27,463.13,258.67,463.66,259.33,464.16z"
              />
              <path
                d="M347.86,165.91c0.44,0.65,0.97,1.09,0.93,1.46c-0.04,0.39-0.63,0.72-0.98,1.08c-0.36-0.41-0.91-0.77-1.01-1.24
	C346.73,166.93,347.36,166.49,347.86,165.91z"
              />
              <path
                d="M485.68,384.26c-0.46-0.64-0.99-1.07-0.96-1.44c0.03-0.39,0.61-0.74,0.96-1.1c0.37,0.4,0.93,0.76,1.04,1.22
	C486.78,383.22,486.17,383.67,485.68,384.26z"
              />
              <path
                d="M406.9,145.54c-0.44-0.67-0.91-1.07-0.93-1.5c-0.03-0.74,0.58-1.19,1.27-0.95c0.38,0.14,0.83,0.76,0.81,1.15
	C408.03,144.62,407.45,144.96,406.9,145.54z"
              />
              <path
                d="M439.18,163.04c-0.6,0.48-0.99,1.03-1.36,1.02c-0.39-0.02-0.75-0.58-1.13-0.9c0.37-0.4,0.68-0.98,1.14-1.14
	C438.08,161.94,438.57,162.56,439.18,163.04z"
              />
              <path
                d="M427.46,319.41c-0.59,0.51-0.97,1.08-1.36,1.09c-0.38,0.01-1-0.48-1.13-0.88c-0.22-0.72,0.3-1.29,1.02-1.24
	C426.43,318.43,426.82,318.94,427.46,319.41z"
              />
              <path
                d="M326.43,420.43c0.49,0.7,1.02,1.12,0.97,1.48c-0.05,0.38-0.64,0.96-0.99,0.95s-0.93-0.59-0.97-0.97
	C325.4,421.54,325.94,421.12,326.43,420.43z"
              />
              <path
                d="M510.29,276.07c-0.73,0.48-1.17,1-1.54,0.95c-0.39-0.05-0.71-0.64-1.06-0.99c0.36-0.34,0.7-0.92,1.1-0.95
	C509.16,275.04,509.58,275.57,510.29,276.07z"
              />
              <path
                d="M361.3,97.43c-0.68,0.54-1.08,1.09-1.45,1.08c-0.39-0.01-0.76-0.57-1.14-0.89c0.33-0.37,0.61-0.98,1-1.04
	C360.08,96.51,360.55,97,361.3,97.43z"
              />
              <path
                d="M420.2,320.26c-0.7,0.34-1.16,0.76-1.58,0.73c-0.73-0.05-1.13-0.7-0.81-1.36c0.17-0.36,0.87-0.74,1.25-0.67
	C419.43,319.03,419.7,319.65,420.2,320.26z"
              />
              <path
                d="M506.91,319.68c-0.66,0.5-1.06,1.03-1.42,1.01c-0.4-0.02-1.03-0.48-1.12-0.86c-0.17-0.7,0.34-1.23,1.07-1.13
	C505.86,318.76,506.23,319.21,506.91,319.68z"
              />
              <path
                d="M332.27,179.63c-0.7,0.49-1.08,0.96-1.51,1c-0.72,0.08-1.29-0.45-1.09-1.18c0.1-0.39,0.75-0.85,1.16-0.87
	C331.2,178.58,331.6,179.12,332.27,179.63z"
              />
              <path
                d="M389.35,274.11c0.56,0.62,1.14,0.98,1.14,1.34c0,0.38-0.52,1.02-0.87,1.05c-0.35,0.04-1.01-0.47-1.09-0.83
	C388.46,275.31,388.95,274.83,389.35,274.11z"
              />
              <path
                d="M289.47,369.6c0.49,0.63,1.02,1.02,1.01,1.38c-0.02,0.38-0.51,0.96-0.89,1.06c-0.7,0.19-1.26-0.31-1.16-1.04
	C288.48,370.59,288.97,370.23,289.47,369.6z"
              />
              <path
                d="M498.12,358.55c0.53,0.61,1.09,0.97,1.1,1.34c0,0.39-0.47,1-0.85,1.12c-0.69,0.22-1.29-0.24-1.23-0.99
	C497.17,359.6,497.65,359.22,498.12,358.55z"
              />
              <path
                d="M322.37,191.25c-0.58,0.52-0.94,1.11-1.34,1.13c-0.38,0.02-1.02-0.46-1.15-0.85c-0.24-0.71,0.26-1.3,0.99-1.26
	C321.31,190.3,321.72,190.81,322.37,191.25z"
              />
              <path
                d="M354.84,168.09c-0.42-0.66-0.93-1.11-0.88-1.47c0.05-0.39,0.6-0.93,0.99-0.99c0.72-0.12,1.24,0.43,1.09,1.15
	C355.95,167.19,355.4,167.5,354.84,168.09z"
              />
              <path
                d="M495.27,372.52c-0.48-0.66-0.97-1.03-1.01-1.46c-0.08-0.75,0.51-1.21,1.21-1.01c0.39,0.11,0.87,0.72,0.87,1.1
	C496.34,371.53,495.78,371.9,495.27,372.52z"
              />
              <path
                d="M336.73,190.23c-0.71,0.35-1.17,0.77-1.6,0.74c-0.75-0.05-1.14-0.72-0.82-1.38c0.18-0.37,0.88-0.75,1.26-0.68
	C335.95,188.98,336.22,189.61,336.73,190.23z"
              />
              <path
                d="M349.77,174.37c-0.68,0.46-1.07,0.92-1.51,0.97c-0.74,0.08-1.25-0.46-1.04-1.18c0.11-0.38,0.74-0.84,1.14-0.83
	C348.75,173.34,349.13,173.87,349.77,174.37z"
              />
              <path
                d="M495.07,349.77c-0.62,0.45-1.04,0.99-1.42,0.96c-0.38-0.03-0.71-0.62-1.07-0.96c0.39-0.38,0.73-0.95,1.18-1.07
	C494.03,348.62,494.49,349.26,495.07,349.77z"
              />
              <path
                d="M452.88,320.89c-0.6,0.49-0.99,1.04-1.39,1.05c-0.38,0-0.98-0.51-1.08-0.91c-0.19-0.71,0.34-1.26,1.05-1.18
	C451.88,319.9,452.26,320.42,452.88,320.89z"
              />
              <path
                d="M468.93,380.76c-0.56,0.54-1,1.21-1.25,1.15c-0.49-0.12-0.87-0.66-1.29-1.03c0.36-0.37,0.67-0.97,1.08-1.03
	C467.83,379.79,468.28,380.33,468.93,380.76z"
              />
              <path
                d="M443.04,166.43c-0.63,0.47-1.04,1.03-1.41,1c-0.4-0.03-0.75-0.61-1.12-0.94c0.39-0.4,0.72-0.97,1.19-1.12
	C441.95,165.29,442.43,165.93,443.04,166.43z"
              />
              <path
                d="M296.85,471.42c0.64-0.52,0.97-1,1.39-1.09c0.71-0.15,1.28,0.32,1.15,1.04c-0.07,0.39-0.65,0.9-1.04,0.95
	C297.98,472.37,297.55,471.87,296.85,471.42z"
              />
              <path
                d="M429.77,158.01c-0.46-0.64-0.98-1.05-0.95-1.42c0.03-0.38,0.54-0.94,0.94-1.03c0.71-0.16,1.24,0.36,1.12,1.08
	C430.81,157.06,430.29,157.4,429.77,158.01z"
              />
              <path
                d="M399.58,325.33c0.59-0.54,1.05-1.21,1.31-1.14c0.49,0.13,0.85,0.69,1.27,1.08c-0.37,0.36-0.7,0.96-1.11,1.01
	C400.68,326.33,400.23,325.78,399.58,325.33z"
              />
              <path
                d="M271.22,434.08c-0.57,0.51-1.02,1.15-1.27,1.08c-0.47-0.13-0.82-0.68-1.22-1.06c0.36-0.35,0.69-0.93,1.08-0.98
	C270.17,433.08,270.59,433.62,271.22,434.08z"
              />
              <path
                d="M238.5,200.46c0.51,0.57,1.01,0.89,1.12,1.31c0.19,0.72-0.33,1.27-1.01,1.18c-0.39-0.05-0.95-0.62-1-1.01
	C237.56,201.57,238.07,201.12,238.5,200.46z"
              />
              <path
                d="M269.34,412.43c-0.57,0.54-0.88,1.08-1.31,1.19c-0.76,0.2-1.28-0.4-1.22-1.07c0.04-0.4,0.63-0.98,1.04-1.04
	C268.22,411.45,268.68,411.99,269.34,412.43z"
              />
              <path
                d="M294.43,469.41c-0.57-0.57-1.12-0.87-1.21-1.28c-0.16-0.7,0.33-1.27,1.05-1.16c0.4,0.06,0.94,0.58,1,0.97
	C295.33,468.3,294.84,468.75,294.43,469.41z"
              />
              <path
                d="M266.51,629.71c-0.72,0.47-1.15,0.98-1.52,0.93c-0.38-0.05-0.69-0.63-1.04-0.98c0.35-0.34,0.69-0.91,1.07-0.94
	C265.4,628.69,265.81,629.21,266.51,629.71z"
              />
              <path
                d="M345.74,270.74c0.56,0.6,1.09,0.92,1.18,1.34c0.15,0.73-0.39,1.26-1.1,1.13c-0.4-0.07-0.93-0.62-0.98-1.01
	C344.8,271.84,345.31,271.41,345.74,270.74z"
              />
              <path
                d="M471.98,350.12c0.52,0.62,1.17,1.09,1.1,1.37c-0.12,0.47-0.71,0.83-1.1,1.24c-0.34-0.37-0.92-0.73-0.95-1.13
	C471,351.22,471.53,350.78,471.98,350.12z"
              />
              <path
                d="M405.35,274.68c-0.71,0.35-1.17,0.77-1.6,0.74c-0.75-0.05-1.14-0.72-0.82-1.38c0.18-0.37,0.88-0.75,1.26-0.68
	C404.57,273.43,404.84,274.06,405.35,274.68z"
              />
              <path
                d="M408.21,323.02c0.52,0.64,1.05,1,1.11,1.42c0.12,0.75-0.47,1.24-1.18,1.08c-0.39-0.09-0.91-0.68-0.93-1.07
	C407.19,324.08,407.73,323.67,408.21,323.02z"
              />
              <path
                d="M281.89,326.23c-0.52-0.61-1.16-1.06-1.1-1.34c0.11-0.47,0.68-0.84,1.05-1.25c0.34,0.37,0.92,0.71,0.96,1.1
	C282.85,325.13,282.33,325.56,281.89,326.23z"
              />
              <path
                d="M252.54,179.25c-0.58,0.54-0.9,1.06-1.32,1.17c-0.76,0.19-1.28-0.41-1.2-1.08c0.05-0.4,0.64-0.97,1.05-1.03
	C251.44,178.25,251.89,178.8,252.54,179.25z"
              />
              <path
                d="M512.92,304.7c-0.62,0.54-0.98,1.09-1.37,1.11c-0.39,0.02-1.05-0.41-1.17-0.78c-0.23-0.69,0.22-1.28,0.98-1.22
	C511.81,303.85,512.21,304.28,512.92,304.7z"
              />
              <path
                d="M363.4,165.92c-0.62,0.49-1.03,1.06-1.4,1.04c-0.4-0.02-0.78-0.59-1.16-0.92c0.38-0.41,0.7-1,1.17-1.17
	C362.26,164.78,362.77,165.42,363.4,165.92z"
              />
              <path
                d="M290.52,324.06c-0.7,0.49-1.08,0.96-1.51,1c-0.72,0.08-1.29-0.45-1.09-1.18c0.1-0.39,0.75-0.85,1.16-0.87
	C289.46,323.01,289.86,323.55,290.52,324.06z"
              />
              <path
                d="M503.58,323.43c-0.69,0.55-1.08,1.1-1.45,1.08c-0.4-0.02-0.77-0.56-1.16-0.88c0.33-0.37,0.62-0.97,1-1.05
	C502.34,322.51,502.81,322.99,503.58,323.43z"
              />
              <path
                d="M273.35,349.16c0.51,0.62,1.16,1.1,1.09,1.39c-0.13,0.47-0.72,0.82-1.12,1.22c-0.33-0.38-0.91-0.74-0.93-1.14
	C272.36,350.24,272.89,349.81,273.35,349.16z"
              />
              <path
                d="M346.67,304.81c-0.68,0.51-1.04,0.99-1.47,1.06c-0.72,0.11-1.3-0.4-1.14-1.13c0.09-0.39,0.71-0.88,1.12-0.91
	C345.56,303.8,345.98,304.33,346.67,304.81z"
              />
              <path
                d="M327.99,189.16c0.54,0.65,1.1,1.03,1.09,1.38c-0.01,0.38-0.56,1-0.9,1.02c-0.35,0.03-0.98-0.5-1.05-0.87
	C327.05,190.35,327.55,189.89,327.99,189.16z"
              />
              <path
                d="M293.41,273.31c-0.78,0.42-1.26,0.89-1.62,0.81c-0.39-0.08-0.66-0.69-0.98-1.06c0.39-0.31,0.78-0.85,1.17-0.86
	C292.34,272.19,292.73,272.75,293.41,273.31z"
              />
              <path
                d="M384.13,130.15c0.56,0.6,1.09,0.92,1.18,1.34c0.15,0.73-0.39,1.26-1.1,1.13c-0.4-0.07-0.93-0.62-0.98-1.01
	C383.18,131.25,383.69,130.82,384.13,130.15z"
              />
              <path
                d="M253.57,273.19c-0.73,0.48-1.17,1-1.54,0.95c-0.39-0.05-0.71-0.64-1.06-0.99c0.36-0.34,0.7-0.92,1.1-0.95
	C252.44,272.16,252.86,272.69,253.57,273.19z"
              />
              <path
                d="M273,468.43c0.44,0.71,0.94,1.15,0.88,1.5c-0.06,0.37-0.67,0.92-1.01,0.9c-0.35-0.02-0.9-0.62-0.92-0.99
	C271.93,469.49,272.47,469.1,273,468.43z"
              />
              <path
                d="M469.57,321.34c0.51,0.71,0.99,1.09,1.03,1.51c0.03,0.3-0.57,0.98-0.79,0.93c-0.43-0.09-1.04-0.49-1.12-0.86
	C468.63,322.57,469.13,322.09,469.57,321.34z"
              />
              <path
                d="M259.3,630.27c-0.74,0.42-1.19,0.9-1.55,0.83c-0.37-0.07-0.65-0.66-0.96-1.02c0.37-0.31,0.73-0.85,1.1-0.87
	C258.25,629.21,258.63,629.75,259.3,630.27z"
              />
              <path d="M282.69,64.33c-0.14,1.41-0.54,2.04-1.79,1.76C281.16,65.06,281.16,65.06,282.69,64.33z" />
              <path
                d="M369.19,166.91c-0.5-0.65-1.05-1.05-1.02-1.42c0.03-0.39,0.6-0.74,0.93-1.11c0.36,0.34,0.96,0.65,1.01,1.03
	C370.15,165.78,369.64,166.23,369.19,166.91z"
              />
              <path
                d="M506.42,272.83c-0.75,0.41-1.21,0.87-1.57,0.8c-0.37-0.08-0.63-0.67-0.94-1.04c0.37-0.3,0.75-0.84,1.12-0.84
	C505.39,271.74,505.76,272.29,506.42,272.83z"
              />
              <path
                d="M334.61,208.24c-0.73,0.44-1.21,0.95-1.56,0.87c-0.36-0.08-0.76-0.71-0.82-1.14c-0.03-0.24,0.64-0.84,0.92-0.8
	C333.57,207.23,333.94,207.71,334.61,208.24z"
              />
              <path
                d="M456.83,260.5c-0.55-0.68-1.13-1.08-1.1-1.44c0.03-0.39,0.57-0.86,0.99-1.03c0.17-0.07,0.88,0.52,0.89,0.84
	C457.64,259.29,457.23,259.73,456.83,260.5z"
              />
              <path
                d="M467.94,386.6c0.5,0.66,1.05,1.07,1.02,1.44c-0.02,0.39-0.6,0.74-0.94,1.11c-0.37-0.35-0.98-0.66-1.02-1.05
	C466.95,387.74,467.48,387.29,467.94,386.6z"
              />
              <path
                d="M478.6,345.16c-0.51-0.66-1.05-1.06-1.02-1.41c0.03-0.38,0.58-0.98,0.91-0.99c0.35-0.01,0.95,0.53,1.01,0.9
	C479.56,344.01,479.06,344.45,478.6,345.16z"
              />
              <path
                d="M277.04,431.96c0.47,0.7,0.98,1.14,0.93,1.49c-0.06,0.37-0.65,0.92-1,0.92c-0.34-0.01-0.9-0.6-0.93-0.98
	C276.01,433.04,276.54,432.64,277.04,431.96z"
              />
              <path
                d="M286.93,467.21c0.56,0.68,1.13,1.08,1.11,1.44c-0.03,0.39-0.57,0.86-0.99,1.04c-0.17,0.07-0.88-0.52-0.89-0.83
	C286.13,468.43,286.54,467.99,286.93,467.21z"
              />
              <path
                d="M485.61,344.44c-0.41-0.71-0.9-1.17-0.83-1.53c0.07-0.38,0.68-0.65,1.05-0.97c0.31,0.38,0.86,0.76,0.87,1.14
	C486.7,343.44,486.14,343.82,485.61,344.44z"
              />
              <path
                d="M235.02,350.61c-0.64,0.51-1.04,1.08-1.41,1.06c-0.38-0.02-1-0.61-1.01-0.97c-0.01-0.36,0.56-0.99,0.94-1.04
	C233.9,349.61,234.35,350.15,235.02,350.61z"
              />
              <path
                d="M319.7,202.4c-0.64,0.47-1.07,1.02-1.43,0.98c-0.38-0.04-0.97-0.65-0.97-1c0-0.36,0.6-0.95,0.99-0.99
	C318.65,201.36,319.07,201.92,319.7,202.4z"
              />
              <path d="M255.47,95.21c0.74,1.03,0.53,1.73-0.74,2.11C253.93,96.25,255.04,95.84,255.47,95.21z" />
              <path
                d="M322.21,164.44c0.54,0.64,1.11,1.02,1.11,1.39c-0.01,0.39-0.57,0.77-0.89,1.16c-0.38-0.33-0.99-0.61-1.06-0.99
	C321.3,165.63,321.79,165.16,322.21,164.44z"
              />
              <path
                d="M486.92,371.79c0.64-0.49,1.07-1.06,1.42-1.02c0.39,0.04,1,0.64,1.01,1c0,0.36-0.6,0.97-0.99,1.02
	C488,372.83,487.57,372.27,486.92,371.79z"
              />
              <path
                d="M456.74,324.2c-0.76,0.39-1.24,0.85-1.59,0.77c-0.37-0.09-0.63-0.68-0.93-1.05c0.38-0.3,0.77-0.82,1.15-0.82
	C455.73,323.09,456.09,323.64,456.74,324.2z"
              />
              <path
                d="M495.15,325.73c-0.51-0.64-1.06-1.04-1.04-1.4c0.02-0.38,0.58-0.74,0.91-1.11c0.36,0.33,0.96,0.63,1.02,1.01
	C496.1,324.59,495.59,325.04,495.15,325.73z"
              />
              <path
                d="M299.32,424.66c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C298.28,423.63,298.64,424.12,299.32,424.66z"
              />
              <path
                d="M491.16,362.23c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C492.05,360.96,491.57,361.45,491.16,362.23z"
              />
              <path
                d="M256.43,198.33c-0.51-0.66-1.05-1.06-1.02-1.41c0.03-0.38,0.58-0.98,0.91-0.99c0.35-0.01,0.95,0.53,1.01,0.9
	C257.39,197.18,256.89,197.62,256.43,198.33z"
              />
              <path
                d="M480.74,351.02c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.84,1.12-0.86
	C479.69,349.94,480.07,350.48,480.74,351.02z"
              />
              <path
                d="M486.35,351.67c-0.44-0.76-0.93-1.24-0.86-1.59c0.08-0.37,0.69-0.77,1.12-0.85c0.22-0.04,0.82,0.64,0.78,0.94
	C487.35,350.6,486.87,350.97,486.35,351.67z"
              />
              <path
                d="M334.99,231.2c-0.44-0.69-0.95-1.14-0.89-1.5c0.06-0.38,0.66-0.68,1.02-1.01c0.33,0.37,0.89,0.73,0.91,1.11
	C336.05,230.17,335.5,230.57,334.99,231.2z"
              />
              <path
                d="M250.64,629.43c0.46,0.67,0.99,1.1,0.95,1.47c-0.04,0.38-0.63,0.71-0.98,1.06c-0.34-0.36-0.93-0.7-0.96-1.08
	C249.61,630.5,250.16,630.08,250.64,629.43z"
              />
              <path
                d="M398.65,321.98c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C397.61,320.95,397.97,321.44,398.65,321.98z"
              />
              <path d="M229.37,165.85c1.48,0.88,1.07,1.69,0,2.58C228.5,167.52,229.2,166.68,229.37,165.85z" />
              <path
                d="M280.42,348.69c0.56,0.68,1.05,1.03,1.12,1.45c0.05,0.3-0.51,1.01-0.72,0.98c-0.44-0.06-1.07-0.43-1.17-0.79
	C279.55,349.98,280.03,349.48,280.42,348.69z"
              />
              <path
                d="M486.42,324.68c0.72-0.44,1.2-0.96,1.54-0.89c0.36,0.08,0.77,0.7,0.84,1.13c0.04,0.24-0.63,0.85-0.91,0.81
	C487.47,325.68,487.1,325.2,486.42,324.68z"
              />
              <path
                d="M424.84,164.32c-0.72,0.44-1.17,0.92-1.53,0.87c-0.37-0.06-0.66-0.64-0.98-1c0.36-0.32,0.71-0.87,1.08-0.89
	C423.77,163.28,424.17,163.8,424.84,164.32z"
              />
              <path
                d="M418.66,152.46c0.53,0.63,1.09,1.01,1.08,1.37c-0.01,0.39-0.56,0.76-0.87,1.14c-0.37-0.32-0.98-0.6-1.05-0.97
	C417.76,153.64,418.25,153.17,418.66,152.46z"
              />
              <path
                d="M397.47,141.3c-0.65,0.56-1.05,1.15-1.4,1.12c-0.38-0.03-0.87-0.57-1.02-1c-0.07-0.21,0.5-0.91,0.81-0.92
	C396.27,140.48,396.71,140.9,397.47,141.3z"
              />
              <path
                d="M476.48,360.48c0.55,0.63,1.11,1,1.11,1.35c-0.01,0.37-0.53,0.99-0.88,1.03c-0.34,0.03-0.97-0.48-1.05-0.85
	C475.58,361.67,476.06,361.21,476.48,360.48z"
              />
              <path
                d="M510.22,288.97c0.48,0.7,1,1.12,0.96,1.47c-0.05,0.37-0.63,0.94-0.98,0.94c-0.34,0-0.91-0.59-0.95-0.96
	C509.21,290.07,509.73,289.65,510.22,288.97z"
              />
              <path
                d="M336.85,86.52c-0.42-0.72-0.9-1.18-0.83-1.53c0.08-0.37,0.7-0.89,1.04-0.86c0.35,0.03,0.87,0.65,0.89,1.02
	C337.96,85.51,337.4,85.88,336.85,86.52z"
              />
              <path
                d="M319.47,408.12c-0.68,0.55-1.09,1.13-1.43,1.1c-0.38-0.04-0.85-0.6-0.99-1.03c-0.07-0.21,0.52-0.9,0.82-0.9
	C318.29,407.27,318.72,407.7,319.47,408.12z"
              />
              <path
                d="M495.57,356.9c-0.65,0.48-1.08,1.03-1.45,1c-0.38-0.04-0.98-0.65-0.98-1.01c0-0.36,0.6-0.98,0.98-1.01
	C494.5,355.86,494.92,356.42,495.57,356.9z"
              />
              <path
                d="M486.46,375.49c-0.68,0.55-1.09,1.13-1.43,1.1c-0.38-0.04-0.85-0.6-0.99-1.03c-0.07-0.21,0.52-0.9,0.82-0.9
	C485.28,374.64,485.71,375.07,486.46,375.49z"
              />
              <path
                d="M291.25,470.6c0.42,0.7,0.91,1.16,0.85,1.53c-0.07,0.38-0.67,0.66-1.04,0.98c-0.32-0.38-0.87-0.75-0.88-1.13
	C290.18,471.61,290.74,471.23,291.25,470.6z"
              />
              <path
                d="M472.06,326.2c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.59,0.96-0.97,1
	C473.13,327.22,472.7,326.67,472.06,326.2z"
              />
              <path
                d="M335.85,238.4c-0.51-0.64-1.06-1.04-1.04-1.4c0.02-0.38,0.58-0.74,0.91-1.11c0.36,0.33,0.96,0.63,1.02,1.01
	C336.79,237.26,336.28,237.71,335.85,238.4z"
              />
              <path
                d="M339.09,294.29c0.69-0.49,1.11-1,1.47-0.97c0.38,0.04,0.7,0.6,1.05,0.94c-0.34,0.34-0.65,0.92-1.02,0.96
	C340.22,295.27,339.79,294.76,339.09,294.29z"
              />
              <path
                d="M467.74,326.53c-0.61,0.54-0.98,1.13-1.35,1.13c-0.38,0-1.03-0.55-1.06-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C466.56,325.6,467.04,326.11,467.74,326.53z"
              />
              <path
                d="M263.15,633.25c-0.63,0.49-0.96,0.95-1.26,0.94c-0.46-0.02-0.91-0.38-1.36-0.6c0.33-0.37,0.61-0.96,1-1.05
	C261.9,632.45,262.39,632.89,263.15,633.25z"
              />
              <path
                d="M498.35,321.92c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C499.24,320.65,498.76,321.15,498.35,321.92z"
              />
              <path
                d="M680.89,631.82c0.43,0.67,0.95,1.12,0.9,1.5c-0.05,0.35-0.71,0.85-1.09,0.83c-0.33-0.01-0.91-0.65-0.9-0.99
	C679.82,632.77,680.37,632.41,680.89,631.82z"
              />
              <path
                d="M350.91,161.81c0.48,0.7,1,1.12,0.96,1.47c-0.05,0.37-0.63,0.94-0.98,0.94c-0.34,0-0.91-0.59-0.95-0.96
	C349.9,162.91,350.42,162.5,350.91,161.81z"
              />
              <path
                d="M257.57,276.42c-0.7,0.42-1.17,0.93-1.53,0.86c-0.37-0.07-0.91-0.73-0.88-1.09c0.03-0.35,0.68-0.91,1.06-0.9
	C256.58,275.29,256.95,275.88,257.57,276.42z"
              />
              <path
                d="M284.68,465.2c-0.6,0.54-0.97,1.14-1.33,1.13c-0.39,0-1.04-0.54-1.07-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C283.52,464.27,283.99,464.79,284.68,465.2z"
              />
              <path
                d="M475.46,373.05c-0.74,0.44-1.18,0.92-1.54,0.86c-0.38-0.07-0.66-0.64-0.99-1c0.36-0.32,0.71-0.87,1.09-0.89
	C474.39,372,474.78,372.54,475.46,373.05z"
              />
              <path
                d="M297.18,83.35c0.47,0.68,1,1.11,0.96,1.49c-0.04,0.39-0.64,0.72-0.99,1.08c-0.35-0.36-0.95-0.71-0.98-1.1
	C296.13,84.44,296.69,84.01,297.18,83.35z"
              />
              <path
                d="M459.17,325.92c0.44,0.71,0.94,1.15,0.88,1.5c-0.06,0.37-0.67,0.92-1.01,0.9c-0.35-0.02-0.9-0.62-0.92-0.99
	C458.11,326.97,458.65,326.59,459.17,325.92z"
              />
              <path
                d="M296.28,361.65c0.44,0.7,0.95,1.16,0.9,1.53c-0.06,0.38-0.66,0.69-1.03,1.03c-0.33-0.38-0.91-0.74-0.93-1.13
	C295.2,362.7,295.76,362.3,296.28,361.65z"
              />
              <path
                d="M506.67,295.72c-0.4-0.73-0.87-1.2-0.79-1.54c0.09-0.37,0.73-0.87,1.07-0.83c0.35,0.04,0.86,0.68,0.86,1.05
	C507.81,294.75,507.24,295.1,506.67,295.72z"
              />
              <path
                d="M467.58,339.58c0.44,0.7,0.95,1.16,0.9,1.53c-0.06,0.38-0.66,0.69-1.03,1.03c-0.33-0.38-0.91-0.74-0.93-1.13
	C466.5,340.63,467.07,340.23,467.58,339.58z"
              />
              <path
                d="M499.09,325.94c0.4,0.73,0.88,1.2,0.8,1.54c-0.09,0.37-0.72,0.87-1.07,0.83c-0.34-0.04-0.86-0.67-0.86-1.04
	C497.96,326.92,498.52,326.56,499.09,325.94z"
              />
              <path
                d="M288.66,278.74c-0.54-0.69-1.03-1.04-1.09-1.46c-0.05-0.3,0.53-1,0.74-0.97c0.44,0.07,1.06,0.44,1.15,0.8
	C289.55,277.46,289.07,277.96,288.66,278.74z"
              />
              <path
                d="M244.84,631.47c-0.68,0.43-1.14,0.94-1.49,0.88c-0.37-0.07-0.91-0.7-0.88-1.05c0.03-0.35,0.66-0.89,1.04-0.9
	C243.86,630.38,244.24,630.95,244.84,631.47z"
              />
              <path d="M231.42,157.1c0.18-1.11,0.28-1.77,0.4-2.51C233.33,155.55,233.29,155.96,231.42,157.1z" />
              <path
                d="M392.05,139.57c-0.42-0.72-0.92-1.19-0.86-1.56c0.07-0.38,0.69-0.66,1.07-0.99c0.32,0.39,0.87,0.77,0.88,1.16
	C393.15,138.56,392.58,138.94,392.05,139.57z"
              />
              <path
                d="M506.17,312.48c-0.7,0.49-1.11,1.01-1.46,0.98c-0.38-0.04-0.71-0.6-1.06-0.93c0.34-0.34,0.65-0.91,1.03-0.97
	C505.02,311.51,505.45,312.01,506.17,312.48z"
              />
              <path
                d="M491.83,326.63c0.43,0.71,0.93,1.18,0.87,1.55c-0.07,0.38-0.69,0.67-1.06,1c-0.32-0.38-0.88-0.76-0.89-1.15
	C490.74,327.65,491.31,327.26,491.83,326.63z"
              />
              <path
                d="M308.75,172.86c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C307.69,173.91,308.23,173.52,308.75,172.86z"
              />
              <path
                d="M507.46,302.93c-0.44-0.72-0.93-1.17-0.87-1.52c0.07-0.37,0.69-0.9,1.04-0.88c0.34,0.02,0.87,0.64,0.89,1.01
	C508.54,301.9,507.99,302.28,507.46,302.93z"
              />
              <path
                d="M278.18,306.86c-0.61,0.54-0.97,1.12-1.33,1.11c-0.38,0-1.02-0.54-1.05-0.89c-0.03-0.35,0.5-1,0.87-1.07
	C277.02,305.95,277.48,306.45,278.18,306.86z"
              />
              <path
                d="M342,167.85c-0.65,0.48-1.07,1.02-1.43,0.98c-0.38-0.04-0.97-0.64-0.97-0.99c0-0.36,0.6-0.96,0.97-0.99
	C340.94,166.81,341.36,167.37,342,167.85z"
              />
              <path
                d="M311.91,424.57c-0.41-0.71-0.9-1.17-0.83-1.53c0.07-0.38,0.68-0.65,1.05-0.97c0.31,0.38,0.86,0.76,0.87,1.14
	C313,423.58,312.44,423.95,311.91,424.57z"
              />
              <path
                d="M492.92,367.86c-0.62,0.51-1.01,1.07-1.37,1.05c-0.38-0.02-1-0.58-1.02-0.93c-0.01-0.36,0.54-0.98,0.92-1.04
	C491.81,366.89,492.26,367.42,492.92,367.86z"
              />
              <path
                d="M273.89,423.14c-0.66,0.47-1.09,1.02-1.46,0.98c-0.38-0.04-0.98-0.66-0.97-1.01c0.01-0.36,0.61-0.97,0.99-1
	C272.82,422.07,273.24,422.64,273.89,423.14z"
              />
              <path
                d="M500.41,260.91c0.44,0.68,0.96,1.13,0.91,1.5c-0.05,0.38-0.65,0.69-1.01,1.02c-0.33-0.37-0.9-0.72-0.92-1.1
	C499.37,261.96,499.92,261.55,500.41,260.91z"
              />
              <path
                d="M389.78,134.74c-0.65,0.48-1.09,1.04-1.44,1c-0.39-0.05-1-0.66-0.99-1.02c0-0.36,0.62-0.96,1.01-1
	C388.71,133.68,389.13,134.25,389.78,134.74z"
              />
              <path
                d="M385.04,137.35c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C383.98,138.4,384.52,138.01,385.04,137.35z"
              />
              <path
                d="M274.34,430.26c-0.63,0.51-1.03,1.07-1.39,1.05c-0.38-0.02-0.99-0.6-1-0.95c-0.01-0.35,0.55-0.97,0.93-1.03
	C273.24,429.28,273.68,429.8,274.34,430.26z"
              />
              <path
                d="M388.7,143.42c-0.41-0.76-0.83-1.2-0.82-1.62c0.01-0.31,0.7-0.92,0.88-0.85c0.43,0.16,0.98,0.63,1.01,1.01
	C389.8,142.32,389.23,142.73,388.7,143.42z"
              />
              <path
                d="M229.62,385.62c0.43,0.77,0.92,1.25,0.84,1.61c-0.08,0.37-0.69,0.77-1.12,0.86c-0.2,0.04-0.8-0.65-0.77-0.96
	C228.62,386.7,229.1,386.32,229.62,385.62z"
              />
              <path
                d="M493.07,264.34c-0.44-0.75-0.93-1.24-0.86-1.59c0.08-0.37,0.69-0.77,1.12-0.85c0.22-0.04,0.82,0.64,0.78,0.94
	C494.07,263.27,493.59,263.64,493.07,264.34z"
              />
              <path
                d="M294.3,366.93c-0.69,0.51-1.12,1.07-1.47,1.02c-0.37-0.05-0.82-0.64-0.93-1.08c-0.06-0.22,0.56-0.88,0.86-0.87
	C293.18,366.03,293.59,366.47,294.3,366.93z"
              />
              <path
                d="M313.1,225.93c-0.52-0.64-1.07-1.02-1.06-1.39c0.01-0.39,0.57-0.76,0.88-1.13c0.37,0.33,0.97,0.61,1.04,0.99
	C314.02,224.76,313.52,225.22,313.1,225.93z"
              />
              <path
                d="M270.52,427.09c-0.7,0.42-1.17,0.93-1.53,0.86c-0.37-0.07-0.91-0.73-0.88-1.09c0.03-0.35,0.68-0.91,1.06-0.9
	C269.54,425.96,269.91,426.55,270.52,427.09z"
              />
              <path
                d="M471.56,330.05c-0.65,0.47-1.08,1.01-1.44,0.97c-0.38-0.04-0.97-0.65-0.96-1c0.01-0.36,0.6-0.95,0.98-0.99
	C470.5,329,470.92,329.56,471.56,330.05z"
              />
              <path
                d="M483.1,253.32c-0.68,0.45-1.13,0.98-1.49,0.93c-0.38-0.05-0.95-0.69-0.93-1.04c0.02-0.36,0.64-0.94,1.02-0.96
	C482.06,252.23,482.46,252.8,483.1,253.32z"
              />
              <path
                d="M490.54,339c-0.6,0.54-0.97,1.14-1.33,1.13c-0.39,0-1.04-0.54-1.07-0.9c-0.03-0.36,0.5-1.01,0.88-1.09
	C489.36,338.07,489.84,338.58,490.54,339z"
              />
              <path
                d="M365.21,163.57c-0.43-0.75-0.86-1.18-0.85-1.6c0-0.31,0.68-0.93,0.86-0.86c0.43,0.15,1,0.6,1.04,0.98
	C366.3,162.45,365.74,162.88,365.21,163.57z"
              />
              <path
                d="M348.78,286.57c-0.7,0.49-1.11,1.01-1.47,0.98c-0.38-0.04-0.71-0.6-1.06-0.93c0.34-0.34,0.65-0.91,1.03-0.97
	C347.63,285.6,348.06,286.1,348.78,286.57z"
              />
              <path
                d="M423.1,269.04c-0.63,0.5-1.03,1.07-1.39,1.04c-0.38-0.02-0.99-0.6-1-0.96c-0.01-0.35,0.56-0.97,0.93-1.02
	C421.99,268.05,422.43,268.58,423.1,269.04z"
              />
              <path
                d="M296.48,475.35c-0.76,0.39-1.24,0.85-1.59,0.77c-0.37-0.09-0.63-0.68-0.93-1.05c0.38-0.3,0.77-0.82,1.15-0.82
	C295.47,474.24,295.83,474.79,296.48,475.35z"
              />
              <path
                d="M343.5,268.77c-0.68,0.52-1.08,1.05-1.43,1.02c-0.38-0.03-0.73-0.57-1.09-0.89c0.33-0.35,0.62-0.93,0.99-1
	C342.32,267.84,342.77,268.32,343.5,268.77z"
              />
              <path
                d="M289.25,476.01c-0.75,0.43-1.24,0.93-1.58,0.85c-0.37-0.09-0.76-0.72-0.82-1.15c-0.03-0.24,0.65-0.83,0.93-0.79
	C288.21,474.98,288.57,475.46,289.25,476.01z"
              />
              <path
                d="M303.9,268.45c-0.65,0.56-1.05,1.15-1.4,1.12c-0.38-0.03-0.87-0.57-1.02-1c-0.07-0.21,0.5-0.91,0.81-0.92
	C302.7,267.64,303.14,268.05,303.9,268.45z"
              />
              <path
                d="M485.76,368.36c-0.68,0.51-1.12,1.07-1.47,1.03c-0.37-0.05-0.82-0.64-0.93-1.07c-0.06-0.22,0.56-0.88,0.85-0.87
	C484.64,367.47,485.05,367.91,485.76,368.36z"
              />
              <path
                d="M335.75,222.87c-0.75,0.33-1.29,0.77-1.63,0.65c-0.37-0.13-0.8-0.83-0.73-1.2c0.07-0.34,0.79-0.8,1.16-0.75
	C334.92,221.62,335.2,222.25,335.75,222.87z"
              />
              <path
                d="M490.3,378.98c-0.65,0.48-1.08,1.03-1.45,1c-0.38-0.04-0.98-0.65-0.98-1.01c0-0.36,0.6-0.98,0.98-1.01
	C489.22,377.93,489.64,378.49,490.3,378.98z"
              />
              <path
                d="M334.87,168.68c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.85,1.12-0.86
	C333.82,167.61,334.2,168.15,334.87,168.68z"
              />
              <path
                d="M282.75,317.6c-0.66,0.46-1.1,0.99-1.45,0.94c-0.38-0.05-0.95-0.67-0.93-1.03c0.01-0.35,0.63-0.93,1.01-0.95
	C281.73,316.54,282.13,317.1,282.75,317.6z"
              />
              <path
                d="M491.75,353.53c-0.72,0.49-1.17,1.03-1.52,0.98c-0.37-0.06-0.81-0.66-0.91-1.09c-0.05-0.22,0.58-0.87,0.88-0.85
	C490.63,352.6,491.03,353.05,491.75,353.53z"
              />
              <path
                d="M497.01,331.49c-0.66,0.47-1.09,1.02-1.46,0.98c-0.38-0.04-0.98-0.66-0.97-1.01c0.01-0.36,0.61-0.97,0.99-1
	C495.95,330.43,496.36,330.99,497.01,331.49z"
              />
              <path
                d="M317.37,399.55c0.56,0.68,1.05,1.03,1.12,1.45c0.05,0.3-0.51,1.01-0.72,0.98c-0.44-0.06-1.07-0.43-1.17-0.79
	C316.5,400.85,316.97,400.34,317.37,399.55z"
              />
              <path
                d="M277.97,346.87c-0.67,0.46-1.12,1.01-1.47,0.95c-0.39-0.06-0.97-0.68-0.95-1.04c0.01-0.36,0.64-0.94,1.03-0.97
	C276.93,345.79,277.34,346.36,277.97,346.87z"
              />
              <path
                d="M320.66,422.63c-0.63,0.48-1.05,1.03-1.41,1c-0.38-0.04-0.99-0.63-0.98-0.98c0-0.36,0.59-0.96,0.97-1.01
	C319.59,421.6,320.02,422.15,320.66,422.63z"
              />
              <path
                d="M228.56,358.19c-0.66,0.55-1,1.05-1.42,1.13c-0.28,0.05-0.98-0.51-0.95-0.75c0.05-0.44,0.41-1.07,0.77-1.17
	C227.3,357.3,227.81,357.78,228.56,358.19z"
              />
              <path
                d="M239.89,423.6c-0.5-0.64-1.04-1.03-1.02-1.4c0.02-0.39,0.58-0.74,0.9-1.11c0.36,0.34,0.96,0.64,1.02,1.02
	C240.84,422.47,240.33,422.92,239.89,423.6z"
              />
              <path
                d="M452.13,313.58c-0.6,0.54-0.96,1.12-1.32,1.11c-0.38,0-1.02-0.54-1.05-0.88c-0.03-0.35,0.49-1,0.86-1.08
	C450.97,312.66,451.44,313.16,452.13,313.58z"
              />
              <path
                d="M423.38,316.08c-0.69,0.5-1.13,1.06-1.48,1.01c-0.37-0.05-0.82-0.65-0.92-1.08c-0.05-0.23,0.57-0.88,0.86-0.86
	C422.27,315.17,422.67,315.62,423.38,316.08z"
              />
              <path
                d="M472.3,337.1c-0.61,0.54-0.98,1.13-1.34,1.12c-0.39-0.01-1.04-0.55-1.07-0.91c-0.03-0.36,0.51-1.01,0.89-1.09
	C471.14,336.15,471.61,336.67,472.3,337.1z"
              />
              <path
                d="M286.85,280.96c-0.67,0.55-1.08,1.14-1.43,1.1c-0.38-0.04-0.86-0.6-1-1.03c-0.07-0.21,0.52-0.9,0.82-0.91
	C285.66,280.12,286.1,280.54,286.85,280.96z"
              />
              <path
                d="M472.54,333.4c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.58,0.96-0.97,1
	C473.61,334.42,473.18,333.87,472.54,333.4z"
              />
              <path
                d="M253.86,628.57c-0.41-0.77-0.83-1.21-0.82-1.63c0.01-0.32,0.7-0.93,0.88-0.86c0.43,0.17,0.97,0.63,1.01,1.02
	C254.96,627.46,254.4,627.87,253.86,628.57z"
              />
              <path
                d="M307.77,564.84c-0.75,0.41-1.19,0.83-1.61,0.81c-0.29-0.01-0.82-0.71-0.75-0.97c0.12-0.42,0.62-0.98,0.99-1.01
	C306.75,563.65,307.13,564.26,307.77,564.84z"
              />
              <path d="M599.8,161.29c-2.02-0.35-1.58-1.42-1.06-2.54C599.05,159.5,599.36,160.23,599.8,161.29z" />
              <path
                d="M332.39,215.28c0.72-0.44,1.2-0.96,1.54-0.89c0.36,0.08,0.77,0.7,0.84,1.13c0.04,0.24-0.63,0.85-0.91,0.81
	C333.44,216.27,333.07,215.8,332.39,215.28z"
              />
              <path
                d="M231.65,354.37c-0.61,0.54-0.98,1.13-1.35,1.13c-0.38,0-1.03-0.55-1.06-0.9c-0.03-0.36,0.5-1.02,0.88-1.09
	C230.48,353.43,230.95,353.94,231.65,354.37z"
              />
              <path
                d="M468.47,333.98c-0.69,0.43-1.16,0.96-1.51,0.89c-0.38-0.07-0.93-0.72-0.91-1.07c0.03-0.36,0.67-0.91,1.06-0.92
	C467.47,332.86,467.85,333.44,468.47,333.98z"
              />
              <path
                d="M485.33,334.56c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C484.27,335.61,484.81,335.22,485.33,334.56z"
              />
              <path
                d="M429,313.93c0.54,0.65,1.09,1.03,1.08,1.38c-0.02,0.37-0.55,0.98-0.9,1.01c-0.34,0.03-0.96-0.5-1.03-0.87
	C428.08,315.1,428.57,314.65,429,313.93z"
              />
              <path
                d="M444.95,314.3c-0.69,0.43-1.16,0.96-1.51,0.89c-0.38-0.07-0.93-0.72-0.9-1.07c0.03-0.36,0.67-0.91,1.06-0.92
	C443.96,313.18,444.34,313.77,444.95,314.3z"
              />
              <path
                d="M275.64,457.4c0.45,0.71,0.95,1.16,0.89,1.51c-0.06,0.37-0.67,0.91-1.02,0.89c-0.34-0.01-0.88-0.62-0.91-1
	C274.58,458.45,275.12,458.06,275.64,457.4z"
              />
              <path
                d="M277.26,478.98c0.47,0.7,0.98,1.12,0.93,1.47c-0.05,0.37-0.63,0.94-0.97,0.94c-0.35-0.01-0.92-0.59-0.95-0.96
	C276.23,480.08,276.76,479.67,277.26,478.98z"
              />
              <path d="M632.72,634.24c-0.17-1.2,0.21-1.63,0.94-1.68c0.84-0.06,1.27,0.4,1.3,1.52C634.23,634.14,633.52,634.19,632.72,634.24z" />
              <path
                d="M491.02,346.35c-0.65,0.48-1.09,1.04-1.44,1c-0.39-0.05-1-0.66-0.99-1.02c0-0.36,0.62-0.96,1.01-1
	C489.95,345.29,490.38,345.86,491.02,346.35z"
              />
              <path
                d="M269.82,419.81c-0.67,0.46-1.12,1.01-1.47,0.96c-0.39-0.06-0.97-0.68-0.95-1.04c0.01-0.36,0.64-0.94,1.03-0.97
	C268.78,418.72,269.18,419.3,269.82,419.81z"
              />
              <path
                d="M302.59,314.17c0.51,0.65,1.06,1.04,1.04,1.41c-0.02,0.39-0.59,0.74-0.91,1.11c-0.36-0.33-0.96-0.63-1.02-1.01
	C301.64,315.31,302.15,314.86,302.59,314.17z"
              />
              <path
                d="M229.35,631.33c0.43,0.71,0.93,1.18,0.86,1.54c-0.07,0.38-0.68,0.67-1.06,0.99c-0.32-0.38-0.88-0.76-0.88-1.15
	C228.27,632.35,228.83,631.97,229.35,631.33z"
              />
              <path
                d="M493.9,335.23c-0.63,0.52-1.02,1.09-1.38,1.07c-0.39-0.02-1.02-0.59-1.04-0.95c-0.01-0.36,0.55-1,0.94-1.06
	C492.77,334.24,493.23,334.78,493.9,335.23z"
              />
              <path
                d="M290.2,479.27c0.63-0.48,1.05-1.04,1.4-1c0.38,0.04,0.98,0.63,0.99,0.98c0,0.36-0.58,0.96-0.97,1
	C291.27,480.29,290.84,479.74,290.2,479.27z"
              />
              <path
                d="M378.49,298.43c-0.68,0.45-1.13,0.98-1.49,0.93c-0.38-0.05-0.95-0.69-0.93-1.04c0.02-0.36,0.64-0.94,1.02-0.96
	C377.46,297.34,377.86,297.91,378.49,298.43z"
              />
              <path
                d="M244.97,438.42c0.43,0.71,0.92,1.16,0.86,1.53c-0.06,0.38-0.64,0.68-0.99,1.02c-0.32-0.37-0.86-0.74-0.88-1.13
	C243.94,439.47,244.47,439.07,244.97,438.42z"
              />
              <path
                d="M331.96,192.52c0.47,0.7,0.98,1.12,0.93,1.47c-0.05,0.37-0.63,0.94-0.97,0.94c-0.35-0.01-0.92-0.59-0.95-0.96
	C330.93,193.62,331.46,193.21,331.96,192.52z"
              />
              <path
                d="M285.89,479.61c-0.67,0.55-1.08,1.14-1.43,1.1c-0.38-0.04-0.86-0.6-1-1.03c-0.07-0.21,0.52-0.9,0.82-0.91
	C284.7,478.77,285.14,479.19,285.89,479.61z"
              />
              <path
                d="M383.07,282c0.47,0.68,1,1.11,0.96,1.49c-0.04,0.39-0.64,0.72-0.99,1.08c-0.35-0.36-0.95-0.71-0.98-1.1
	C382.03,283.09,382.58,282.67,383.07,282z"
              />
              <path
                d="M510.97,283.36c-0.75,0.42-1.21,0.89-1.56,0.82c-0.38-0.08-0.65-0.66-0.96-1.02c0.37-0.31,0.74-0.84,1.12-0.86
	C509.92,282.28,510.3,282.83,510.97,283.36z"
              />
              <path
                d="M433.73,161.17c-0.51-0.65-1.07-1.05-1.05-1.42c0.02-0.39,0.59-0.76,0.92-1.13c0.37,0.34,0.98,0.64,1.04,1.03
	C434.7,160.01,434.18,160.47,433.73,161.17z"
              />
              <path
                d="M353.32,91.15c-0.78,0.43-1.21,0.85-1.64,0.85c-0.26,0-0.74-0.65-0.73-0.99c0.01-0.32,0.57-0.9,0.84-0.87
	C352.23,90.19,352.61,90.64,353.32,91.15z"
              />
              <path
                d="M487.58,366.02c-0.41-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.9,0.83-0.84c0.42,0.15,0.98,0.58,1.03,0.95
	C488.64,364.93,488.09,365.34,487.58,366.02z"
              />
              <path
                d="M477.6,315.19c-0.76,0.46-1.18,0.89-1.61,0.9c-0.26,0.01-0.76-0.63-0.76-0.97c0.01-0.33,0.55-0.92,0.82-0.9
	C476.48,314.26,476.88,314.71,477.6,315.19z"
              />
              <path
                d="M347.21,161.52c-0.51-0.56-1.07-0.92-1.03-1.18c0.07-0.42,0.53-0.78,0.83-1.17c0.34,0.27,0.95,0.54,0.96,0.82
	C347.99,160.43,347.57,160.86,347.21,161.52z"
              />
              <path
                d="M497.37,267.56c-0.5-0.57-1.05-0.94-1-1.2c0.08-0.42,0.56-0.76,0.87-1.14c0.33,0.28,0.94,0.57,0.94,0.85
	C498.17,266.5,497.74,266.92,497.37,267.56z"
              />
              <path
                d="M305.64,266.21c-0.52-0.67-1.08-1.08-1.04-1.43c0.04-0.37,0.6-0.81,1.02-0.96c0.17-0.06,0.83,0.53,0.84,0.83
	C306.45,265.07,306.04,265.49,305.64,266.21z"
              />
              <path
                d="M243.41,198.08c-0.69,0.46-1.13,0.98-1.49,0.93c-0.35-0.05-0.77-0.66-0.85-1.08c-0.04-0.22,0.58-0.84,0.85-0.81
	C242.35,197.17,242.73,197.61,243.41,198.08z"
              />
              <path
                d="M503.47,283.85c-0.42,0.46-0.77,1.08-0.99,1.03c-0.44-0.08-0.8-0.54-1.2-0.84c0.34-0.36,0.63-0.86,1.04-1.01
	C502.55,282.96,502.99,283.49,503.47,283.85z"
              />
              <path
                d="M296.68,395.77c-0.69,0.47-1.13,0.99-1.48,0.94c-0.35-0.05-0.77-0.65-0.85-1.07c-0.05-0.22,0.58-0.84,0.85-0.81
	C295.61,394.86,295.99,395.31,296.68,395.77z"
              />
              <path
                d="M307.1,557.68c-0.64,0.36-1.01,0.56-1.38,0.77c-0.21-0.44-0.52-0.87-0.59-1.33c-0.03-0.16,0.65-0.65,0.79-0.57
	C306.32,556.76,306.61,557.18,307.1,557.68z"
              />
              <path d="M422.22,634.1c-1.12,0-1.84,0-2.62,0C420.36,632.27,420.63,632.25,422.22,634.1z" />
              <path
                d="M336.91,252.78c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.84c0.42,0.15,0.99,0.58,1.03,0.95
	C337.97,251.68,337.42,252.1,336.91,252.78z"
              />
              <path
                d="M490.4,315.2c-0.38-0.81-0.77-1.26-0.74-1.68c0.02-0.28,0.65-0.76,0.95-0.72c0.34,0.04,0.89,0.6,0.86,0.87
	C491.42,314.1,490.95,314.47,490.4,315.2z"
              />
              <path
                d="M419.19,162.76c-0.47-0.97-0.87-1.42-0.83-1.84c0.02-0.29,0.64-0.53,0.99-0.79c0.28,0.4,0.74,0.78,0.79,1.21
	C420.17,161.67,419.69,162.05,419.19,162.76z"
              />
              <path d="M424.66,5.71c-0.89,1.08-0.89,1.08-1.87-0.12C423.75,5.23,423.75,5.23,424.66,5.71z" />
              <path
                d="M240.67,428.01c0.37,0.64,0.78,1.05,0.78,1.46c0,0.28-0.57,0.56-0.89,0.84c-0.3-0.37-0.76-0.7-0.84-1.11
	C239.67,428.95,240.2,428.58,240.67,428.01z"
              />
              <path
                d="M447.85,263.48c-0.8,0.4-1.25,0.79-1.67,0.77c-0.26-0.01-0.71-0.67-0.68-1.01c0.03-0.32,0.61-0.86,0.87-0.82
	C446.8,262.47,447.15,262.94,447.85,263.48z"
              />
              <path d="M284.96,615.16c0.67-1.2,0.67-1.2,2-1.61C286.91,614.7,286.22,615,284.96,615.16z" />
              <path
                d="M280.57,284.97c0.72-0.49,1.12-0.94,1.54-0.97c0.27-0.02,0.82,0.56,0.82,0.89c0.01,0.34-0.49,0.98-0.75,0.98
	C281.75,285.85,281.33,285.42,280.57,284.97z"
              />
              <path
                d="M337.68,244.13c-0.7,0.48-1.14,1.01-1.48,0.95c-0.37-0.06-0.79-0.64-0.89-1.06c-0.05-0.21,0.57-0.83,0.87-0.82
	C336.59,243.22,336.98,243.66,337.68,244.13z"
              />
              <path
                d="M497.65,314.68c-0.41-0.72-0.82-1.14-0.82-1.55c0-0.3,0.66-0.9,0.83-0.84c0.42,0.15,0.98,0.59,1.02,0.95
	C498.72,313.59,498.16,314,497.65,314.68z"
              />
              <path
                d="M494.29,315.63c0.52,0.68,1.07,1.09,1.03,1.44c-0.04,0.37-0.6,0.8-1.02,0.96c-0.17,0.06-0.83-0.54-0.83-0.84
	C493.47,316.77,493.88,316.35,494.29,315.63z"
              />
              <path
                d="M374.39,128.87c-0.42,0.46-0.77,1.08-0.99,1.03c-0.44-0.08-0.8-0.54-1.2-0.84c0.34-0.36,0.63-0.86,1.04-1.01
	C373.47,127.97,373.92,128.51,374.39,128.87z"
              />
              <path
                d="M236.43,633.61c-0.49-0.77-1.02-1.24-0.95-1.58c0.08-0.37,0.67-0.78,1.1-0.87c0.22-0.05,0.83,0.58,0.81,0.88
	C237.38,632.45,236.94,632.85,236.43,633.61z"
              />
              <path
                d="M295.74,175.38c-0.7,0.54-1.06,1.01-1.48,1.07c-0.26,0.04-0.84-0.51-0.87-0.84c-0.03-0.33,0.42-0.99,0.69-1
	C294.5,174.59,294.94,174.99,295.74,175.38z"
              />
              <path d="M677.55,566.37c-1.18-0.69-1.35-1.43-0.65-2.58C677.88,564.53,677.92,565.31,677.55,566.37z" />
              <path
                d="M412.25,160.27c0.46,0.57,0.94,0.92,1.01,1.35c0.04,0.27-0.63,0.96-0.8,0.91c-0.43-0.14-0.91-0.54-1.05-0.95
	C411.32,161.34,411.84,160.89,412.25,160.27z"
              />
              <path
                d="M261.15,279.78c-0.49,0.36-0.95,0.89-1.16,0.8c-0.41-0.16-0.69-0.67-1.02-1.03c0.4-0.29,0.77-0.74,1.21-0.81
	C260.4,278.7,260.74,279.32,261.15,279.78z"
              />
              <path
                d="M486.85,355.94c0.49,0.68,0.96,1.05,1,1.46c0.03,0.31-0.57,0.98-0.76,0.94c-0.43-0.11-1.02-0.49-1.1-0.87
	C485.91,357.13,486.42,356.66,486.85,355.94z"
              />
              <path
                d="M483.05,379.29c-0.64,0.55-1.02,1.14-1.35,1.11c-0.38-0.03-0.86-0.55-1.01-0.97c-0.07-0.2,0.48-0.89,0.79-0.91
	C481.87,378.49,482.31,378.9,483.05,379.29z"
              />
              <path
                d="M502.51,269.44c-0.63,0.52-0.96,1-1.37,1.08c-0.27,0.05-0.95-0.5-0.92-0.72c0.05-0.43,0.4-1.08,0.74-1.17
	C501.29,268.54,501.78,269.03,502.51,269.44z"
              />
              <path
                d="M474.52,252.4c0.41,0.61,0.92,1.07,0.84,1.31c-0.14,0.41-0.63,0.81-1.06,0.94c-0.17,0.05-0.84-0.64-0.8-0.91
	C473.57,253.32,474.06,252.97,474.52,252.4z"
              />
              <path
                d="M361.56,160.17c-0.51-0.67-0.98-1.03-1.03-1.44c-0.03-0.3,0.55-0.99,0.74-0.95c0.43,0.1,1.03,0.47,1.12,0.84
	C362.47,158.96,361.98,159.44,361.56,160.17z"
              />
              <path
                d="M354.93,318.35c-0.64,0.55-1.02,1.14-1.35,1.11c-0.38-0.03-0.86-0.55-1.01-0.97c-0.07-0.2,0.48-0.89,0.79-0.91
	C353.76,317.56,354.19,317.96,354.93,318.35z"
              />
              <path
                d="M400.46,279.65c-0.42-0.73-0.92-1.2-0.84-1.54c0.09-0.37,0.68-0.75,1.11-0.85c0.19-0.04,0.78,0.64,0.75,0.94
	C401.43,278.61,400.96,278.97,400.46,279.65z"
              />
              <path
                d="M365.45,123.9c-0.47-0.97-0.87-1.42-0.83-1.84c0.02-0.29,0.64-0.53,0.99-0.79c0.28,0.4,0.74,0.78,0.79,1.21
	C366.43,122.8,365.95,123.19,365.45,123.9z"
              />
              <path
                d="M472.71,320.43c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.84c0.42,0.15,0.99,0.58,1.03,0.95
	C473.77,319.34,473.22,319.76,472.71,320.43z"
              />
              <path
                d="M451.29,306.46c-0.58,0.45-0.93,0.94-1.34,0.98c-0.28,0.03-0.96-0.64-0.9-0.8c0.15-0.43,0.54-0.89,0.96-1.05
	C450.22,305.51,450.68,306.03,451.29,306.46z"
              />
              <path
                d="M274.78,492.64c-0.43-0.47-0.91-0.76-0.93-1.07c-0.02-0.33,0.41-0.69,0.64-1.03c0.36,0.3,0.86,0.54,1.04,0.92
	C275.62,491.65,275.13,492.11,274.78,492.64z"
              />
              <path
                d="M304.49,83c0.37,0.66,0.79,1.08,0.78,1.5c-0.01,0.29-0.61,0.56-0.95,0.84c-0.3-0.38-0.77-0.73-0.85-1.16
	C303.42,83.94,303.98,83.57,304.49,83z"
              />
              <path
                d="M323.74,307.86c-0.41-0.61-0.86-1-0.89-1.43c-0.02-0.28,0.57-0.6,0.89-0.9c0.33,0.35,0.83,0.67,0.94,1.08
	C324.73,306.87,324.2,307.28,323.74,307.86z"
              />
              <path
                d="M503.2,276.92c-0.74,0.39-1.17,0.8-1.58,0.78c-0.3-0.02-0.86-0.71-0.78-0.91c0.15-0.41,0.63-0.93,1.01-0.97
	C502.18,275.8,502.56,276.38,503.2,276.92z"
              />
              <path
                d="M380.5,293.08c0.4,0.73,0.81,1.15,0.8,1.57c-0.01,0.3-0.67,0.89-0.84,0.83c-0.42-0.16-0.97-0.6-1.01-0.97
	C379.42,294.15,379.98,293.75,380.5,293.08z"
              />
              <path
                d="M465.51,320.91c-0.42-0.72-0.83-1.13-0.83-1.55c0-0.3,0.65-0.91,0.83-0.85c0.42,0.15,0.99,0.58,1.03,0.95
	C466.57,319.82,466.02,320.24,465.51,320.91z"
              />
              <path
                d="M448.31,310.36c-0.69,0.46-1.13,0.99-1.49,0.93c-0.35-0.05-0.77-0.65-0.86-1.07c-0.05-0.23,0.58-0.84,0.85-0.82
	C447.24,309.45,447.62,309.89,448.31,310.36z"
              />
              <path
                d="M284.06,471.1c0.4,0.73,0.81,1.15,0.8,1.57c-0.01,0.3-0.67,0.89-0.84,0.83c-0.42-0.16-0.97-0.6-1.01-0.97
	C282.97,472.17,283.53,471.77,284.06,471.1z"
              />
              <path
                d="M345.12,163.86c-0.62,0.53-0.95,1.01-1.37,1.09c-0.27,0.05-0.95-0.49-0.93-0.72c0.05-0.43,0.39-1.08,0.74-1.17
	C343.9,162.97,344.39,163.46,345.12,163.86z"
              />
              <path
                d="M250.94,450.13c-0.72,0.51-1.14,1.04-1.49,1c-0.37-0.05-0.79-0.59-0.95-1.01c-0.06-0.16,0.54-0.82,0.84-0.82
	C249.75,449.3,250.17,449.7,250.94,450.13z"
              />
              <path
                d="M336.42,163.04c0.52,0.68,1.07,1.09,1.03,1.44c-0.04,0.37-0.6,0.8-1.02,0.96c-0.17,0.06-0.83-0.54-0.83-0.84
	C335.6,164.18,336.01,163.76,336.42,163.04z"
              />
              <path
                d="M491.09,319.47c0.43,0.73,0.94,1.2,0.86,1.53c-0.09,0.37-0.67,0.76-1.11,0.86c-0.18,0.04-0.78-0.63-0.75-0.94
	C490.13,320.52,490.59,320.15,491.09,319.47z"
              />
              <path
                d="M315.07,164.96c0.49,0.68,0.96,1.05,1,1.46c0.03,0.31-0.57,0.98-0.76,0.94c-0.43-0.11-1.02-0.49-1.1-0.87
	C314.13,166.16,314.63,165.69,315.07,164.96z"
              />
              <path
                d="M272.03,374.76c0.41,0.61,0.92,1.07,0.84,1.31c-0.14,0.41-0.63,0.81-1.06,0.94c-0.17,0.05-0.84-0.64-0.8-0.91
	C271.08,375.68,271.57,375.32,272.03,374.76z"
              />
              <path
                d="M337.28,260.01c-0.35-0.82-0.72-1.28-0.68-1.7c0.03-0.28,0.67-0.74,0.98-0.69c0.34,0.05,0.88,0.63,0.83,0.9
	C338.34,258.95,337.85,259.3,337.28,260.01z"
              />
              <path
                d="M322.5,87.71c-0.41-0.72-0.82-1.14-0.82-1.55c0-0.3,0.66-0.9,0.83-0.84c0.42,0.15,0.99,0.59,1.02,0.95
	C323.57,86.63,323.01,87.04,322.5,87.71z"
              />
              <path
                d="M485.28,361.26c-0.76,0.46-1.18,0.89-1.61,0.9c-0.26,0.01-0.76-0.63-0.76-0.97c0.01-0.33,0.55-0.92,0.82-0.9
	C484.16,360.32,484.55,360.77,485.28,361.26z"
              />
              <path
                d="M313.56,230.22c0.43,0.73,0.93,1.2,0.85,1.54c-0.09,0.37-0.68,0.75-1.11,0.85c-0.19,0.04-0.78-0.64-0.75-0.94
	C312.59,231.26,313.06,230.9,313.56,230.22z"
              />
              <path
                d="M475.42,309.17c-0.42-0.49-1-0.93-0.92-1.12c0.16-0.41,0.66-0.69,1.02-1.03c0.27,0.33,0.75,0.66,0.76,0.99
	C476.28,308.35,475.81,308.68,475.42,309.17z"
              />
              <path
                d="M279.86,288.62c-0.45,0.48-0.84,1.1-1.02,1.05c-0.44-0.14-0.78-0.59-1.16-0.91c0.31-0.31,0.59-0.8,0.94-0.86
	C278.94,287.84,279.33,288.29,279.86,288.62z"
              />
              <path
                d="M499.81,296.33c-0.51-0.54-1.06-0.88-1.02-1.14c0.06-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C500.57,295.25,500.16,295.67,499.81,296.33z"
              />
              <path
                d="M506.2,288.55c-0.53-0.72-1.08-1.14-1.03-1.47c0.05-0.37,0.61-0.8,1.03-0.93c0.2-0.06,0.84,0.52,0.85,0.82
	C507.05,287.38,506.64,287.79,506.2,288.55z"
              />
              <path
                d="M456.05,316.99c-0.8,0.4-1.3,0.86-1.65,0.76c-0.35-0.1-0.7-0.72-0.76-1.15c-0.03-0.19,0.66-0.73,0.96-0.69
	C455,315.97,455.35,316.44,456.05,316.99z"
              />
              <path
                d="M265,282.98c-0.48,0.42-0.92,1-1.11,0.93c-0.42-0.16-0.71-0.65-1.06-1c0.33-0.28,0.66-0.76,1.01-0.78
	C264.16,282.11,264.5,282.59,265,282.98z"
              />
              <path
                d="M478.6,369.29c-0.8,0.4-1.3,0.86-1.65,0.76c-0.35-0.1-0.7-0.72-0.76-1.15c-0.03-0.19,0.66-0.73,0.96-0.69
	C477.56,368.27,477.9,368.74,478.6,369.29z"
              />
              <path
                d="M496.32,297.64c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.07,1.02c-0.26-0.35-0.71-0.7-0.72-1.05
	C495.43,298.44,495.91,298.11,496.32,297.64z"
              />
              <path
                d="M483.4,339.93c-0.78,0.44-1.19,0.85-1.61,0.86c-0.29,0-0.89-0.64-0.83-0.83c0.13-0.41,0.56-0.97,0.92-1.01
	C482.25,338.91,482.67,339.42,483.4,339.93z"
              />
              <path
                d="M449.44,297.92c0.5,0.72,0.96,1.09,0.93,1.41c-0.03,0.34-0.53,0.64-0.83,0.96c-0.34-0.36-0.85-0.68-0.95-1.1
	C448.53,298.9,449.05,298.48,449.44,297.92z"
              />
              <path
                d="M343.17,155.51c0.45,0.57,0.92,0.92,0.98,1.33c0.04,0.27-0.62,0.93-0.8,0.88c-0.41-0.13-0.88-0.53-1.02-0.93
	C342.25,156.55,342.75,156.11,343.17,155.51z"
              />
              <path
                d="M507.15,279.77c-0.74,0.54-1.15,1.07-1.49,1.02c-0.38-0.05-0.82-0.57-0.97-0.98c-0.07-0.18,0.52-0.84,0.82-0.85
	C505.91,278.94,506.34,279.34,507.15,279.77z"
              />
              <path
                d="M273.71,462.71c-0.76,0.47-1.16,0.89-1.58,0.91c-0.3,0.01-0.92-0.61-0.86-0.8c0.12-0.42,0.53-0.98,0.89-1.04
	C272.52,461.73,272.96,462.23,273.71,462.71z"
              />
              <path
                d="M370.54,125.66c-0.46,0.39-0.87,0.95-1.09,0.88c-0.42-0.13-0.73-0.62-1.09-0.95c0.38-0.31,0.72-0.76,1.15-0.87
	C369.74,124.67,370.11,125.25,370.54,125.66z"
              />
              <path
                d="M333.87,303.25c-0.23-0.36-0.66-0.75-0.63-1.09c0.03-0.32,0.55-0.6,0.86-0.89c0.32,0.37,0.83,0.7,0.9,1.11
	c0.07,0.38-0.32,0.85-0.5,1.28C334.29,303.52,334.08,303.39,333.87,303.25z"
              />
              <path d="M375.06,634.11c-0.79,0-1.54,0-2.41,0C373.9,632.64,374.19,632.65,375.06,634.11z" />
              <path
                d="M315.57,214.74c-0.4-0.6-0.84-0.99-0.87-1.41c-0.02-0.26,0.69-0.87,0.86-0.8c0.4,0.16,0.83,0.6,0.93,1
	C316.55,213.79,316.03,214.18,315.57,214.74z"
              />
              <path
                d="M500.42,303.42c-0.43-0.47-1.02-0.88-0.96-1.09c0.13-0.41,0.62-0.72,0.96-1.06c0.29,0.32,0.79,0.62,0.81,0.95
	C501.25,302.55,500.79,302.91,500.42,303.42z"
              />
              <path
                d="M275.87,467.52c-0.39-0.79-0.78-1.22-0.76-1.63c0.02-0.29,0.69-0.83,0.9-0.76c0.41,0.14,0.94,0.62,0.97,0.98
	C277.01,466.46,276.44,466.85,275.87,467.52z"
              />
              <path
                d="M454.53,302.63c-0.48,0.43-0.91,1.02-1.09,0.95c-0.42-0.16-0.73-0.64-1.08-0.99c0.33-0.28,0.65-0.76,1-0.79
	C453.68,301.78,454.03,302.26,454.53,302.63z"
              />
              <path
                d="M390.71,149.02c-0.46,0.47-0.87,1.09-1.04,1.03c-0.44-0.15-0.76-0.61-1.14-0.95c0.32-0.3,0.61-0.79,0.96-0.84
	C389.81,148.22,390.19,148.67,390.71,149.02z"
              />
              <path
                d="M493.3,301.48c0.33,0.53,0.77,0.92,0.73,1.24c-0.05,0.34-0.56,0.61-0.86,0.91c-0.32-0.37-0.78-0.71-0.9-1.13
	C492.22,302.3,492.84,301.92,493.3,301.48z"
              />
              <path
                d="M477.99,337.97c-0.45-0.76-0.86-1.16-0.87-1.57c0-0.3,0.63-0.89,0.83-0.83c0.42,0.12,0.98,0.54,1.04,0.91
	C479.06,336.82,478.51,337.25,477.99,337.97z"
              />
              <path
                d="M444.69,303.19c0.72-0.47,1.12-0.9,1.53-0.92c0.27-0.02,0.8,0.56,0.8,0.87c0,0.34-0.49,0.96-0.74,0.95
	C445.85,304.06,445.44,303.64,444.69,303.19z"
              />
              <path
                d="M497.77,274.62c-0.4-0.47-0.89-0.79-0.9-1.11c-0.01-0.34,0.45-0.69,0.71-1.04c0.38,0.32,0.88,0.58,1.07,0.99
	C498.74,273.64,498.17,274.12,497.77,274.62z"
              />
              <path
                d="M498.2,306.01c-0.48,0.42-0.92,1-1.11,0.93c-0.42-0.16-0.71-0.65-1.06-1c0.33-0.28,0.66-0.76,1.01-0.78
	C497.36,305.15,497.71,305.63,498.2,306.01z"
              />
              <path
                d="M283.87,305.31c0.42,0.46,0.92,0.76,0.93,1.07c0.01,0.35-0.43,0.72-0.67,1.08c-0.39-0.31-0.89-0.56-1.11-0.96
	C282.94,306.34,283.48,305.84,283.87,305.31z"
              />
              <path
                d="M275.25,473.12c0.8-0.41,1.3-0.88,1.64-0.78c0.36,0.1,0.7,0.71,0.78,1.15c0.03,0.18-0.65,0.73-0.96,0.69
	C276.31,474.12,275.95,473.66,275.25,473.12z"
              />
              <path
                d="M489.76,307.73c-0.4-0.51-0.97-0.99-0.88-1.17c0.19-0.41,0.7-0.67,1.08-0.99c0.25,0.35,0.71,0.7,0.71,1.04
	C490.66,306.95,490.17,307.27,489.76,307.73z"
              />
              <path
                d="M444.32,307.36c-0.71,0.24-1.19,0.56-1.59,0.48c-0.26-0.05-0.67-0.88-0.56-1.02c0.25-0.34,0.78-0.65,1.19-0.64
	C443.62,306.18,443.87,306.78,444.32,307.36z"
              />
              <path
                d="M495.14,270.06c-0.51,0.4-0.95,0.95-1.17,0.87c-0.4-0.14-0.66-0.66-0.99-1.02c0.33-0.27,0.67-0.75,0.99-0.74
	C494.31,269.19,494.64,269.67,495.14,270.06z"
              />
              <path
                d="M428.75,267.3c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C427.72,268.22,428.26,267.85,428.75,267.3z"
              />
              <path d="M666.28,531.4c0.29-0.88-0.9-2.02,0.64-2.8c0.27,1.11,0.83,2.14-0.71,2.73C666.21,531.32,666.28,531.4,666.28,531.4z" />
              <path
                d="M469.41,308.52c-0.52,0.37-0.99,0.9-1.18,0.82c-0.4-0.19-0.65-0.7-0.97-1.07c0.36-0.25,0.72-0.71,1.07-0.7
	C468.64,307.57,468.94,308.08,469.41,308.52z"
              />
              <path
                d="M245.88,266.48c-0.77,0.46-1.17,0.88-1.59,0.89c-0.3,0.01-0.9-0.62-0.85-0.81c0.12-0.42,0.54-0.98,0.91-1.03
	C244.7,265.48,245.14,265.99,245.88,266.48z"
              />
              <path
                d="M233.36,262.61c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.06,1.02c-0.26-0.35-0.72-0.7-0.72-1.05
	C232.48,263.41,232.97,263.08,233.36,262.61z"
              />
              <path
                d="M454.8,263.11c-0.59,0.3-1.17,0.77-1.33,0.65c-0.36-0.26-0.52-0.81-0.75-1.24c0.38-0.18,0.81-0.56,1.14-0.49
	C454.18,262.1,454.4,262.63,454.8,263.11z"
              />
              <path
                d="M494.82,309.85c-0.44,0.42-0.82,1-1.04,0.95c-0.43-0.11-0.76-0.58-1.13-0.9c0.36-0.33,0.68-0.8,1.1-0.92
	C493.98,308.92,494.37,309.47,494.82,309.85z"
              />
              <path
                d="M324.76,193c0.47,0.74,0.89,1.14,0.91,1.54c0.01,0.29-0.6,0.9-0.81,0.85c-0.42-0.11-1-0.52-1.06-0.88
	C323.74,194.17,324.26,193.73,324.76,193z"
              />
              <path
                d="M326.28,400.78c-0.76,0.35-1.22,0.75-1.6,0.68c-0.41-0.08-0.74-0.58-1.11-0.9c0.3-0.31,0.58-0.83,0.9-0.86
	C324.82,399.66,325.21,400.1,326.28,400.78z"
              />
              <path
                d="M308.22,86.51c0.41,0.51,0.98,0.98,0.9,1.15c-0.2,0.42-0.69,0.69-1.07,1.02c-0.26-0.35-0.72-0.7-0.72-1.05
	C307.33,87.3,307.82,86.98,308.22,86.51z"
              />
              <path
                d="M266.32,310.08c0.42,0.77,0.82,1.19,0.81,1.61c-0.01,0.29-0.66,0.85-0.88,0.78c-0.41-0.13-0.96-0.58-1-0.95
	C265.22,311.18,265.77,310.78,266.32,310.08z"
              />
              <path
                d="M484.11,347.2c-0.81,0.41-1.24,0.8-1.65,0.77c-0.31-0.02-0.87-0.69-0.8-0.87c0.16-0.41,0.62-0.92,1-0.96
	C482.99,346.11,483.39,346.65,484.11,347.2z"
              />
              <path
                d="M471.47,382.53c0.43,0.79,0.92,1.29,0.82,1.61c-0.11,0.37-0.73,0.71-1.17,0.78c-0.2,0.03-0.75-0.64-0.71-0.94
	C470.46,383.57,470.93,383.23,471.47,382.53z"
              />
              <path
                d="M248.12,627.5c-0.58,0.47-0.92,0.94-1.34,1.01c-0.26,0.05-0.93-0.6-0.87-0.79c0.12-0.42,0.51-0.9,0.91-1.04
	C247.05,626.6,247.5,627.09,248.12,627.5z"
              />
              <path
                d="M307.21,311.68c-0.46,0.47-0.87,1.09-1.04,1.03c-0.44-0.15-0.76-0.61-1.14-0.95c0.32-0.3,0.61-0.79,0.96-0.84
	C306.32,310.88,306.7,311.34,307.21,311.68z"
              />
              <path
                d="M472.46,313.12c-0.51-0.54-1.06-0.88-1.02-1.14c0.06-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C473.22,312.04,472.81,312.47,472.46,313.12z"
              />
              <path
                d="M465.02,313.73c-0.4-0.78-0.79-1.21-0.77-1.62c0.01-0.27,0.61-0.76,0.91-0.73c0.33,0.03,0.9,0.56,0.87,0.82
	C465.98,312.62,465.53,313,465.02,313.73z"
              />
              <path
                d="M478.2,255.89c0.42,0.46,0.92,0.76,0.93,1.07c0.01,0.35-0.43,0.72-0.67,1.08c-0.39-0.31-0.89-0.56-1.11-0.96
	C477.27,256.92,477.82,256.42,478.2,255.89z"
              />
              <path
                d="M369.9,158.26c-0.51,0.4-0.95,0.95-1.17,0.87c-0.4-0.14-0.66-0.66-0.99-1.02c0.33-0.27,0.67-0.75,0.99-0.74
	C369.07,157.39,369.4,157.86,369.9,158.26z"
              />
              <path
                d="M311.49,85.1c-0.45-0.76-0.86-1.16-0.87-1.57c0-0.3,0.63-0.89,0.83-0.83c0.42,0.12,0.99,0.55,1.04,0.91
	C312.55,83.96,312.01,84.38,311.49,85.1z"
              />
              <path
                d="M321.71,157.52c0.39,0.53,0.93,1.03,0.84,1.19c-0.22,0.4-0.72,0.65-1.11,0.96c-0.24-0.36-0.68-0.73-0.67-1.08
	C320.79,158.28,321.29,157.98,321.71,157.52z"
              />
              <path
                d="M354.36,157.97c0.44,0.78,0.94,1.27,0.84,1.59c-0.11,0.37-0.71,0.72-1.15,0.8c-0.2,0.04-0.76-0.63-0.73-0.93
	C353.37,159.03,353.83,158.67,354.36,157.97z"
              />
              <path
                d="M236.04,404.77c-0.8,0.41-1.29,0.86-1.62,0.77c-0.37-0.11-0.73-0.69-0.81-1.11c-0.04-0.19,0.65-0.75,0.95-0.72
	C234.97,403.76,235.33,404.22,236.04,404.77z"
              />
              <path
                d="M311.71,251.22c-0.51-0.54-1.06-0.88-1.02-1.14c0.07-0.41,0.52-0.76,0.81-1.14c0.34,0.26,0.94,0.51,0.95,0.79
	C312.47,250.14,312.06,250.57,311.71,251.22z"
              />
              <path d="M397.22,4.24c0.85-0.08,1.42-0.13,2.09-0.19c-0.05,0.4,0,0.7-0.12,0.82C398.55,5.47,397.95,5.48,397.22,4.24z" />
              <path
                d="M393.94,145.66c-0.71,0.24-1.19,0.56-1.59,0.48c-0.26-0.05-0.67-0.88-0.56-1.02c0.25-0.34,0.78-0.66,1.19-0.64
	C393.24,144.48,393.49,145.08,393.94,145.66z"
              />
              <path
                d="M469.06,314.32c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C468.02,315.24,468.57,314.88,469.06,314.32z"
              />
              <path
                d="M341.31,160.66c-0.75,0.46-1.16,0.89-1.58,0.91c-0.26,0.01-0.75-0.6-0.75-0.94c0-0.31,0.52-0.9,0.79-0.88
	C340.19,159.77,340.58,160.2,341.31,160.66z"
              />
              <path
                d="M501.57,314.87c0.49,0.74,1.01,1.19,0.95,1.52c-0.07,0.37-0.65,0.77-1.08,0.87c-0.2,0.05-0.81-0.57-0.8-0.86
	C500.66,316,501.09,315.61,501.57,314.87z"
              />
              <path
                d="M364.71,153.69c0.33,0.53,0.77,0.92,0.73,1.24c-0.05,0.34-0.56,0.61-0.86,0.91c-0.32-0.37-0.78-0.71-0.9-1.13
	C363.62,154.51,364.24,154.13,364.71,153.69z"
              />
              <path
                d="M278.42,353.85c-0.44,0.48-0.81,1.11-1,1.06c-0.44-0.12-0.79-0.57-1.17-0.88c0.3-0.31,0.57-0.81,0.92-0.88
	C277.48,353.1,277.88,353.53,278.42,353.85z"
              />
              <path
                d="M285.94,353.31c-0.77,0.48-1.17,0.9-1.58,0.91c-0.31,0.01-0.94-0.62-0.89-0.79c0.13-0.42,0.53-0.97,0.91-1.05
	C284.72,352.31,285.17,352.82,285.94,353.31z"
              />
              <path
                d="M400.35,316.42c0.65,0.93,1.11,1.3,1.08,1.62c-0.03,0.34-0.53,0.64-0.83,0.96c-0.34-0.36-0.86-0.68-0.95-1.09
	C399.57,317.56,400,317.1,400.35,316.42z"
              />
              <path
                d="M395.06,318.17c-0.99,0.8-1.42,1.34-1.63,1.27c-0.42-0.14-0.71-0.63-1.06-0.98c0.39-0.3,0.74-0.77,1.17-0.85
	C393.91,317.53,394.38,317.9,395.06,318.17z"
              />
              <path
                d="M318.18,164.03c-0.4-0.78-0.79-1.21-0.77-1.62c0.01-0.27,0.61-0.76,0.91-0.73c0.33,0.03,0.9,0.56,0.87,0.82
	C319.15,162.91,318.7,163.29,318.18,164.03z"
              />
              <path
                d="M253.14,199.23c0.43,0.79,0.92,1.29,0.82,1.61c-0.11,0.37-0.73,0.71-1.17,0.78c-0.2,0.03-0.75-0.64-0.71-0.94
	C252.13,200.28,252.6,199.93,253.14,199.23z"
              />
              <path
                d="M250.6,323.81c-0.5,0.4-0.95,0.96-1.14,0.88c-0.41-0.17-0.69-0.67-1.02-1.03c0.34-0.27,0.68-0.74,1.03-0.75
	C249.79,322.91,250.12,323.4,250.6,323.81z"
              />
              <path
                d="M315.36,238.88c-0.45,0.4-0.85,0.97-1.07,0.91c-0.42-0.12-0.75-0.6-1.11-0.93c0.37-0.32,0.71-0.78,1.14-0.89
	C314.54,237.91,314.93,238.47,315.36,238.88z"
              />
              <path
                d="M390.85,162.21c0.37,0.64,0.8,1.05,0.79,1.46c-0.01,0.28-0.6,0.56-0.92,0.83c-0.3-0.37-0.76-0.71-0.85-1.12
	C389.81,163.14,390.35,162.77,390.85,162.21z"
              />
              <path
                d="M413.09,320.79c-0.8,0.42-1.23,0.82-1.64,0.8c-0.31-0.01-0.89-0.68-0.82-0.85c0.15-0.41,0.6-0.94,0.98-0.98
	C411.95,319.71,412.36,320.25,413.09,320.79z"
              />
              <path
                d="M410.34,154.36c0.51-0.37,0.87-0.83,1.21-0.82c0.32,0.01,0.64,0.51,0.95,0.79c-0.34,0.34-0.63,0.85-1.04,0.96
	C411.24,155.36,410.83,154.78,410.34,154.36z"
              />
              <path
                d="M326.96,201.73c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.55-0.88,0.81-0.85
	C325.82,200.81,326.21,201.24,326.96,201.73z"
              />
              <path
                d="M377.82,164.65c-0.72,0.5-1.09,0.94-1.5,0.98c-0.29,0.03-0.94-0.55-0.9-0.73c0.1-0.42,0.47-1.01,0.82-1.09
	C376.58,163.73,377.05,164.21,377.82,164.65z"
              />
              <path
                d="M235.21,413.04c-0.35-0.57-0.77-0.95-0.72-1.26c0.05-0.32,0.56-0.57,0.87-0.84c0.28,0.32,0.77,0.63,0.77,0.96
	C236.13,412.2,235.64,412.52,235.21,413.04z"
              />
              <path
                d="M399.91,146.02c-0.5-0.71-0.95-1.07-0.98-1.47c-0.02-0.3,0.55-0.95,0.74-0.91c0.43,0.09,1.02,0.46,1.11,0.82
	C400.86,144.79,400.36,145.26,399.91,146.02z"
              />
              <path
                d="M478.07,322.05c-0.45,0.43-0.82,1.01-1.05,0.96c-0.43-0.09-0.78-0.55-1.17-0.86c0.35-0.34,0.66-0.82,1.08-0.95
	C477.17,321.13,477.58,321.66,478.07,322.05z"
              />
              <path
                d="M483.93,322.96c-0.51-0.57-1.06-0.95-1.01-1.19c0.1-0.42,0.51-0.88,0.91-1.05c0.18-0.08,0.92,0.49,0.91,0.77
	C484.74,321.91,484.31,322.32,483.93,322.96z"
              />
              <path
                d="M405.87,321.32c-0.81,0.39-1.24,0.76-1.64,0.73c-0.3-0.03-0.85-0.7-0.78-0.88c0.17-0.4,0.63-0.9,1-0.93
	C404.78,320.21,405.17,320.76,405.87,321.32z"
              />
              <path
                d="M383.55,165.58c-0.47-0.6-0.99-1.02-0.92-1.25c0.12-0.41,0.56-0.84,0.97-0.99c0.18-0.07,0.88,0.55,0.86,0.82
	C384.43,164.57,383.98,164.96,383.55,165.58z"
              />
              <path
                d="M376.85,133.41c-0.35-0.66-0.76-1.1-0.74-1.51c0.02-0.28,0.78-0.81,0.97-0.72c0.39,0.19,0.78,0.68,0.85,1.11
	C377.98,132.53,377.39,132.88,376.85,133.41z"
              />
              <path
                d="M332.26,152.96c0.38,0.53,0.9,1,0.81,1.18c-0.19,0.39-0.69,0.64-1.06,0.94c-0.24-0.35-0.68-0.7-0.66-1.04
	C331.36,153.72,331.84,153.42,332.26,152.96z"
              />
              <path
                d="M285,321.84c-0.31-0.53-0.71-0.91-0.66-1.23c0.05-0.32,0.54-0.58,0.84-0.86c0.31,0.36,0.75,0.69,0.86,1.11
	C286.08,321.05,285.46,321.41,285,321.84z"
              />
              <path
                d="M362.67,119.13c-0.49,0.39-0.82,0.86-1.14,0.86c-0.32,0-0.64-0.47-0.97-0.74c0.32-0.35,0.58-0.85,0.98-0.98
	C361.75,118.2,362.18,118.74,362.67,119.13z"
              />
              <path
                d="M430.62,165.22c-0.5-0.71-0.95-1.07-0.98-1.47c-0.02-0.3,0.55-0.95,0.74-0.91c0.43,0.09,1.02,0.46,1.11,0.82
	C431.57,163.98,431.07,164.46,430.62,165.22z"
              />
              <path
                d="M330.75,165.07c-0.43,0.46-0.76,1.06-1,1.02c-0.44-0.07-0.81-0.51-1.22-0.79c0.34-0.35,0.62-0.86,1.03-1
	C329.8,164.21,330.24,164.72,330.75,165.07z"
              />
              <path
                d="M460.1,320.1c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.99c-0.06-0.18,0.52-0.82,0.83-0.83
	C458.9,319.26,459.31,319.67,460.1,320.1z"
              />
              <path
                d="M474.57,378.72c0.41,0.76,0.8,1.18,0.79,1.59c-0.01,0.28-0.66,0.84-0.86,0.77c-0.41-0.13-0.95-0.58-0.99-0.94
	C473.48,379.8,474.03,379.4,474.57,378.72z"
              />
              <path
                d="M301.31,176.24c-0.39-0.78-0.77-1.2-0.75-1.61c0.02-0.29,0.68-0.82,0.88-0.75c0.41,0.14,0.93,0.6,0.96,0.97
	C302.43,175.19,301.87,175.57,301.31,176.24z"
              />
              <path
                d="M292.47,321.51c-0.5-0.56-1.05-0.94-0.99-1.17c0.1-0.41,0.5-0.87,0.9-1.04c0.18-0.08,0.9,0.49,0.9,0.76
	C293.27,320.46,292.85,320.87,292.47,321.51z"
              />
              <path
                d="M312.45,163.41c-0.51,0.33-0.88,0.77-1.19,0.72c-0.33-0.05-0.6-0.53-0.89-0.83c0.36-0.31,0.68-0.76,1.09-0.88
	C311.65,162.37,312.02,162.96,312.45,163.41z"
              />
              <path
                d="M261.57,176c-0.35-0.56-0.78-0.95-0.73-1.26c0.05-0.32,0.57-0.57,0.88-0.85c0.28,0.32,0.78,0.64,0.79,0.96
	C262.51,175.17,262.01,175.49,261.57,176z"
              />
              <path
                d="M462.54,325c-0.53-0.71-1.07-1.12-1.02-1.45c0.05-0.36,0.6-0.79,1.01-0.92c0.2-0.06,0.83,0.51,0.83,0.8
	C463.37,323.84,462.97,324.24,462.54,325z"
              />
              <path
                d="M252.38,321.13c-0.32-0.52-0.74-0.9-0.7-1.23c0.04-0.32,0.55-0.57,0.86-0.86c0.31,0.36,0.77,0.69,0.87,1.1
	C253.45,320.35,252.84,320.71,252.38,321.13z"
              />
              <path
                d="M397.83,164.12c-0.4-0.63-0.86-1.08-0.78-1.31c0.15-0.39,0.65-0.64,1-0.95c0.28,0.32,0.81,0.68,0.77,0.94
	C398.77,163.2,398.3,163.54,397.83,164.12z"
              />
              <path d="M580.45,117.39c0.49,0.88,0.89,1.58,1.37,2.43C580.13,119.09,580.13,119.09,580.45,117.39z" />
              <path
                d="M406.62,162.17c-0.74,0.54-1.1,0.99-1.51,1.04c-0.27,0.03-0.84-0.48-0.86-0.79c-0.03-0.33,0.4-0.96,0.67-0.98
	C405.33,161.4,405.78,161.78,406.62,162.17z"
              />
              <path
                d="M481.47,318.17c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C480.22,317.36,480.65,317.75,481.47,318.17z"
              />
              <path
                d="M312.31,408.87c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.98c-0.06-0.18,0.53-0.82,0.83-0.83
	C311.11,408.03,311.52,408.44,312.31,408.87z"
              />
              <path
                d="M487.2,316.47c0.42,0.61,0.85,0.98,0.88,1.39c0.02,0.26-0.66,0.87-0.83,0.8c-0.39-0.14-0.83-0.57-0.94-0.96
	C486.24,317.46,486.75,317.06,487.2,316.47z"
              />
              <path
                d="M326.96,161.95c-0.82,0.41-1.25,0.8-1.67,0.78c-0.26-0.01-0.72-0.63-0.7-0.96c0.02-0.31,0.57-0.85,0.83-0.83
	C325.84,160.99,326.21,161.43,326.96,161.95z"
              />
              <path
                d="M481.91,325.49c-0.48,0.4-0.88,0.94-1.11,0.88c-0.42-0.11-0.75-0.59-1.11-0.92c0.37-0.32,0.71-0.79,1.14-0.89
	C481.06,324.5,481.44,325.07,481.91,325.49z"
              />
              <path
                d="M326.21,154.79c-0.47,0.44-0.84,1.01-1.07,0.95c-0.4-0.09-0.72-0.57-1.07-0.89c0.3-0.29,0.59-0.79,0.91-0.81
	C325.31,154.01,325.68,154.44,326.21,154.79z"
              />
              <path
                d="M408.92,317.44c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C408.1,316.38,408.48,316.98,408.92,317.44z"
              />
              <path
                d="M362.68,131.85c0.68,1.03,1.18,1.51,1.08,1.7c-0.19,0.38-0.7,0.6-1.08,0.89c-0.23-0.35-0.62-0.7-0.63-1.06
	C362.04,132.98,362.37,132.57,362.68,131.85z"
              />
              <path
                d="M372.6,162.66c-0.49-0.56-1.02-0.93-0.97-1.16c0.09-0.41,0.49-0.86,0.88-1.03c0.17-0.08,0.89,0.48,0.89,0.75
	C373.39,161.63,372.98,162.03,372.6,162.66z"
              />
              <path
                d="M332.74,160.14c0.38,0.53,0.91,1.01,0.82,1.2c-0.19,0.4-0.7,0.65-1.08,0.96c-0.24-0.35-0.69-0.71-0.67-1.05
	C331.83,160.92,332.32,160.61,332.74,160.14z"
              />
              <path
                d="M232.44,627.58c0.38,0.55,0.83,0.93,0.79,1.23c-0.05,0.31-0.58,0.76-0.9,0.76c-0.27,0-0.75-0.55-0.75-0.87
	C231.57,628.39,232.04,628.07,232.44,627.58z"
              />
              <path
                d="M380.14,153.76c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C379.32,152.83,379.64,153.33,380.14,153.76z"
              />
              <path
                d="M416.55,316.56c-0.89,0.7-1.29,1.22-1.51,1.15c-0.41-0.13-0.71-0.6-1.05-0.93c0.37-0.3,0.71-0.77,1.13-0.85
	C415.46,315.87,415.9,316.26,416.55,316.56z"
              />
              <path
                d="M326.41,407.65c-0.53,0.34-0.91,0.78-1.23,0.74c-0.34-0.05-0.62-0.55-0.92-0.85c0.37-0.32,0.7-0.77,1.12-0.9
	C325.57,406.58,325.96,407.19,326.41,407.65z"
              />
              <path
                d="M461.77,317.67c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C462.63,316.68,462.19,317.06,461.77,317.67z"
              />
              <path
                d="M381.18,160.73c-0.74,0.54-1.1,0.99-1.51,1.04c-0.27,0.03-0.84-0.48-0.86-0.79c-0.03-0.33,0.4-0.96,0.67-0.98
	C379.9,159.96,380.35,160.34,381.18,160.73z"
              />
              <path
                d="M427.69,160.38c-0.47,0.42-0.85,0.98-1.08,0.92c-0.43-0.1-0.76-0.57-1.14-0.88c0.36-0.33,0.68-0.81,1.11-0.92
	C426.81,159.43,427.21,159.98,427.69,160.38z"
              />
              <path
                d="M237.06,419.1c-0.47,0.33-0.85,0.75-1.01,0.68c-0.34-0.15-0.65-0.52-0.79-0.87c-0.05-0.12,0.51-0.68,0.71-0.64
	C236.32,418.33,236.6,418.72,237.06,419.1z"
              />
              <path
                d="M388.11,160.05c-0.46,0.44-0.83,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C387.2,159.29,387.58,159.71,388.11,160.05z"
              />
              <path
                d="M374.38,144.31c-0.37-0.5-0.81-0.83-0.79-1.15c0.02-0.33,0.47-0.64,0.74-0.96c0.34,0.33,0.81,0.62,0.96,1.02
	C375.35,143.42,374.79,143.84,374.38,144.31z"
              />
              <path
                d="M321.36,199.76c-0.48-0.74-0.99-1.2-0.91-1.52c0.08-0.37,0.66-0.75,1.08-0.85c0.19-0.05,0.78,0.58,0.76,0.87
	C322.27,198.67,321.84,199.04,321.36,199.76z"
              />
              <path
                d="M307.99,168.44c-0.45-0.59-0.9-0.94-0.95-1.35c-0.03-0.27,0.62-0.91,0.78-0.85c0.4,0.14,0.85,0.53,0.99,0.92
	C308.89,167.39,308.41,167.82,307.99,168.44z"
              />
              <path
                d="M240.8,628.36c-0.58,0.35-0.93,0.7-1.3,0.73c-0.24,0.02-0.77-0.54-0.72-0.68c0.13-0.38,0.48-0.89,0.79-0.93
	C239.88,627.45,240.25,627.94,240.8,628.36z"
              />
              <path
                d="M389.33,316.69c-0.35-0.65-0.75-1.08-0.73-1.49c0.01-0.27,0.78-0.8,0.95-0.71c0.39,0.19,0.77,0.68,0.84,1.1
	C390.44,315.82,389.86,316.17,389.33,316.69z"
              />
              <path
                d="M353.13,143.84c0.35,0.56,0.77,0.95,0.73,1.26c-0.05,0.32-0.57,0.56-0.88,0.84c-0.28-0.32-0.78-0.64-0.78-0.96
	C352.2,144.66,352.69,144.34,353.13,143.84z"
              />
              <path
                d="M247.25,327.92c-0.48,0.38-0.89,0.9-1.12,0.83c-0.41-0.13-0.72-0.6-1.07-0.94c0.38-0.3,0.72-0.76,1.15-0.85
	C246.44,326.92,246.8,327.49,247.25,327.92z"
              />
              <path
                d="M349.64,313.98c0.4,0.45,0.89,0.75,0.9,1.06c0,0.34-0.43,0.69-0.68,1.04c-0.38-0.31-0.86-0.57-1.08-0.97
	C348.69,314.98,349.24,314.49,349.64,313.98z"
              />
              <path
                d="M484.8,314.53c-0.8,0.41-1.23,0.79-1.64,0.77c-0.26-0.01-0.7-0.63-0.68-0.95c0.02-0.31,0.56-0.84,0.82-0.82
	C483.7,313.59,484.07,314.02,484.8,314.53z"
              />
              <path
                d="M312.95,404.85c0.51-0.37,0.85-0.82,1.19-0.82c0.32,0,0.63,0.48,0.95,0.75c-0.33,0.34-0.61,0.84-1.01,0.95
	C313.85,405.81,313.44,405.26,312.95,404.85z"
              />
              <path
                d="M328.7,196.38c0.41,0.76,0.8,1.18,0.79,1.59c-0.01,0.28-0.66,0.84-0.86,0.77c-0.41-0.13-0.95-0.58-0.99-0.94
	C327.61,197.46,328.16,197.06,328.7,196.38z"
              />
              <path
                d="M225.3,249.02c0.32,0.54,0.66,0.89,0.67,1.25c0.01,0.2-0.5,0.44-0.77,0.66c-0.25-0.32-0.62-0.61-0.71-0.96
	C224.45,249.77,224.91,249.46,225.3,249.02z"
              />
              <path
                d="M481.6,354.12c0.79-0.41,1.27-0.87,1.62-0.78c0.35,0.09,0.69,0.7,0.77,1.13c0.03,0.18-0.64,0.73-0.94,0.69
	C482.65,355.11,482.3,354.65,481.6,354.12z"
              />
              <path
                d="M402.2,159.09c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C401.38,158.04,401.76,158.64,402.2,159.09z"
              />
              <path
                d="M484.44,327.37c0.51,0.7,0.96,1.06,1,1.46c0.03,0.3-0.53,0.95-0.72,0.91c-0.43-0.09-1.02-0.44-1.13-0.81
	C483.5,328.61,484,328.13,484.44,327.37z"
              />
              <path
                d="M457.89,314.32c-0.44-0.61-0.95-1.03-0.87-1.26c0.13-0.4,0.57-0.81,0.98-0.96c0.17-0.06,0.85,0.57,0.82,0.84
	C458.78,313.34,458.33,313.71,457.89,314.32z"
              />
              <path
                d="M228.79,252.29c0.38,0.49,0.84,0.82,0.83,1.13c-0.01,0.33-0.46,0.65-0.72,0.97c-0.35-0.32-0.83-0.59-0.99-0.99
	C227.83,253.23,228.38,252.79,228.79,252.29z"
              />
              <path
                d="M409.42,158.57c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.8,1.1-0.93
	C408.56,157.54,408.96,158.13,409.42,158.57z"
              />
              <path
                d="M268.89,172.91c0.4,0.5,0.95,0.98,0.87,1.13c-0.21,0.4-0.7,0.66-1.07,0.98c-0.25-0.35-0.69-0.69-0.69-1.04
	C268,173.67,268.48,173.37,268.89,172.91z"
              />
              <path
                d="M468.73,254.57c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C467.61,253.5,468.01,254.03,468.73,254.57z"
              />
              <path
                d="M480.03,383.43c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C478.78,382.62,479.21,383.01,480.03,383.43z"
              />
              <path
                d="M338.59,313.85c-0.41-0.64-0.85-1.03-0.86-1.45c-0.01-0.27,0.7-0.87,0.88-0.8c0.4,0.16,0.83,0.61,0.94,1.02
	C339.61,312.86,339.07,313.26,338.59,313.85z"
              />
              <path
                d="M354.71,152.24c-0.49,0.4-0.81,0.88-1.12,0.87c-0.34-0.01-0.67-0.47-1-0.74c0.33-0.35,0.61-0.83,1.01-0.99
	C353.78,151.3,354.22,151.84,354.71,152.24z"
              />
              <path
                d="M478.81,329.43c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.97
	C477.69,328.36,478.09,328.89,478.81,329.43z"
              />
              <path
                d="M305.48,422.39c0.17,0.42,0.54,0.87,0.47,1.25c-0.07,0.42-0.47,0.9-0.85,1.08c-0.19,0.09-0.94-0.44-0.95-0.71
	c-0.02-0.41,0.39-0.84,0.62-1.26C305,422.63,305.24,422.51,305.48,422.39z"
              />
              <path
                d="M425.48,310.71c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C424.38,311.96,424.84,311.6,425.48,310.71z"
              />
              <path
                d="M674.54,634.08c-0.64,0-1.27,0-2.39,0c0.58-0.68,0.88-1.33,1.19-1.33c0.49-0.01,0.98,0.42,1.47,0.66
	C674.72,633.64,674.63,633.86,674.54,634.08z"
              />
              <path
                d="M386.93,312.17c-0.78,0.4-1.27,0.86-1.61,0.77c-0.35-0.09-0.7-0.7-0.77-1.13c-0.03-0.19,0.64-0.74,0.93-0.69
	C385.89,311.18,386.24,311.64,386.93,312.17z"
              />
              <path
                d="M400.52,150.57c0.4,0.47,0.86,0.78,0.86,1.1c0,0.33-0.44,0.67-0.68,1c-0.36-0.31-0.85-0.57-1.01-0.96
	C399.6,151.53,400.13,151.08,400.52,150.57z"
              />
              <path
                d="M433.97,311.53c-0.78,0.48-1.18,0.89-1.6,0.91c-0.27,0.01-0.78-0.56-0.78-0.88c0-0.32,0.49-0.91,0.75-0.91
	C432.76,310.66,433.17,311.07,433.97,311.53z"
              />
              <path
                d="M328.79,159.22c-0.38-0.51-0.84-0.86-0.82-1.17c0.02-0.34,0.49-0.65,0.76-0.98c0.35,0.34,0.83,0.63,0.99,1.04
	C329.79,158.29,329.22,158.72,328.79,159.22z"
              />
              <path
                d="M465.07,258.23c-0.44,0.42-0.79,0.98-1.02,0.93c-0.42-0.09-0.76-0.54-1.13-0.84c0.34-0.33,0.64-0.81,1.05-0.93
	C464.2,257.33,464.6,257.86,465.07,258.23z"
              />
              <path
                d="M332.57,199.73c0.53,0.7,1.07,1.1,1.03,1.44c-0.04,0.36-0.59,0.8-1,0.92c-0.2,0.06-0.84-0.5-0.85-0.79
	C331.75,200.9,332.15,200.49,332.57,199.73z"
              />
              <path
                d="M479.03,376.23c-0.44,0.45-0.79,1.04-1.02,0.99c-0.43-0.08-0.8-0.53-1.19-0.83c0.34-0.35,0.64-0.84,1.05-0.98
	C478.1,375.35,478.53,375.86,479.03,376.23z"
              />
              <path
                d="M347.04,311.76c-0.48,0.42-0.9,0.98-1.09,0.91c-0.41-0.15-0.71-0.62-1.06-0.96c0.33-0.27,0.64-0.74,0.99-0.76
	C346.2,310.92,346.54,311.38,347.04,311.76z"
              />
              <path
                d="M316.85,86.89c-0.73,0.51-1.1,0.94-1.51,0.98c-0.26,0.03-0.8-0.51-0.82-0.81c-0.02-0.33,0.43-0.95,0.69-0.95
	C315.63,86.09,316.05,86.47,316.85,86.89z"
              />
              <path
                d="M358.96,131.35c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C359.68,130.44,359.27,130.82,358.96,131.35z"
              />
              <path
                d="M360.87,152.94c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C361.6,152.04,361.19,152.41,360.87,152.94z"
              />
              <path
                d="M289.44,356.51c-0.43,0.46-0.8,1.06-0.99,1.01c-0.42-0.12-0.75-0.56-1.12-0.87c0.29-0.31,0.56-0.81,0.89-0.86
	C288.52,355.74,288.91,356.17,289.44,356.51z"
              />
              <path
                d="M479.41,312.39c-0.4-0.63-0.86-1.08-0.78-1.31c0.15-0.39,0.65-0.64,1-0.95c0.28,0.32,0.81,0.67,0.77,0.94
	C480.34,311.47,479.87,311.81,479.41,312.39z"
              />
              <path d="M443.15,8.02c-0.97,0.81-1.57,0.75-2.17-0.11C441.67,7.19,442.27,7.3,443.15,8.02z" />
              <path
                d="M439.69,309.72c0.43,0.62,0.88,1.01,0.91,1.42c0.02,0.27-0.67,0.88-0.86,0.82c-0.41-0.14-0.85-0.58-0.97-0.98
	C438.71,310.74,439.23,310.33,439.69,309.72z"
              />
              <path
                d="M244.1,331.58c-0.7,0.55-1.11,1.07-1.33,1c-0.41-0.13-0.71-0.6-1.05-0.93c0.37-0.3,0.72-0.77,1.13-0.85
	C243.15,330.76,243.55,331.22,244.1,331.58z"
              />
              <path
                d="M383.13,142.63c-0.8,0.48-1.21,0.9-1.63,0.92c-0.27,0.01-0.79-0.57-0.79-0.88c0-0.33,0.49-0.92,0.76-0.92
	C381.9,141.75,382.32,142.16,383.13,142.63z"
              />
              <path
                d="M415.33,156.6c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C414.43,157.62,414.96,157.11,415.33,156.6z"
              />
              <path
                d="M377.06,157.43c-0.45,0.43-0.74,0.92-1.07,0.95c-0.32,0.03-0.69-0.42-1.03-0.66c0.29-0.37,0.52-0.89,0.9-1.07
	C376.06,156.56,376.53,157.07,377.06,157.43z"
              />
              <path
                d="M481.9,261.94c-0.35-0.76-0.75-1.23-0.67-1.58c0.09-0.41,0.61-0.73,0.94-1.08c0.29,0.31,0.78,0.59,0.82,0.92
	C483.01,260.52,482.58,260.89,481.9,261.94z"
              />
              <path
                d="M487.62,310.73c-0.49,0.34-0.93,0.83-1.15,0.74c-0.4-0.15-0.67-0.64-1-0.99c0.39-0.28,0.76-0.71,1.19-0.77
	C486.89,309.68,487.21,310.27,487.62,310.73z"
              />
              <path
                d="M340.92,89.62c-0.43-0.45-0.92-0.74-0.94-1.06c-0.02-0.34,0.41-0.71,0.65-1.06c0.38,0.3,0.88,0.53,1.08,0.93
	C341.8,88.61,341.29,89.09,340.92,89.62z"
              />
              <path
                d="M407.61,149.98c0.5,0.54,1.05,0.89,1,1.13c-0.08,0.41-0.47,0.87-0.85,1.05c-0.18,0.08-0.91-0.46-0.92-0.72
	C406.84,151.03,407.25,150.62,407.61,149.98z"
              />
              <path
                d="M382.61,135.56c-0.78,0.41-1.2,0.81-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C381.51,134.52,381.91,135.04,382.61,135.56z"
              />
              <path
                d="M337.26,157.48c-0.49,0.41-0.8,0.9-1.13,0.91c-0.33,0.01-0.67-0.45-1.01-0.71c0.31-0.36,0.57-0.88,0.96-1.03
	C336.29,156.57,336.74,157.1,337.26,157.48z"
              />
              <path
                d="M293.82,90.23c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C294.69,89.23,294.24,89.61,293.82,90.23z"
              />
              <path
                d="M335.12,89.05c-0.84,0.39-1.28,0.76-1.7,0.73c-0.26-0.02-0.7-0.65-0.67-0.98c0.03-0.31,0.59-0.83,0.86-0.8
	C334.02,88.07,334.38,88.52,335.12,89.05z"
              />
              <path
                d="M469.18,261.58c-0.49,0.42-0.82,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C468.25,260.76,468.63,261.21,469.18,261.58z"
              />
              <path
                d="M405.55,155.08c-0.46,0.41-0.77,0.9-1.07,0.9c-0.34,0-0.68-0.45-1.02-0.7c0.32-0.36,0.58-0.83,0.97-1.01
	C404.61,154.18,405.06,154.7,405.55,155.08z"
              />
              <path
                d="M460.71,263.31c-0.35-0.51-0.78-0.86-0.76-1.18c0.03-0.33,0.49-0.62,0.77-0.93c0.33,0.34,0.79,0.64,0.93,1.05
	C461.71,262.45,461.14,262.85,460.71,263.31z"
              />
              <path
                d="M331.73,398.49c0.43,0.62,0.88,1.01,0.91,1.42c0.02,0.27-0.67,0.88-0.86,0.82c-0.41-0.14-0.85-0.58-0.97-0.98
	C330.74,399.51,331.26,399.1,331.73,398.49z"
              />
              <path
                d="M424.1,157.19c-0.8,0.4-1.23,0.78-1.63,0.76c-0.3-0.02-0.86-0.69-0.79-0.86c0.16-0.4,0.61-0.92,0.99-0.95
	C423.01,156.11,423.4,156.64,424.1,157.19z"
              />
              <path
                d="M455.58,309.53c-0.77,0.52-1.15,0.96-1.56,1c-0.28,0.02-0.83-0.52-0.85-0.83c-0.02-0.33,0.44-0.96,0.71-0.97
	C454.31,308.71,454.75,309.1,455.58,309.53z"
              />
              <path
                d="M383.97,157.13c-0.51,0.36-0.88,0.81-1.18,0.77c-0.34-0.04-0.63-0.52-0.94-0.81c0.35-0.33,0.66-0.78,1.08-0.92
	C383.12,156.11,383.52,156.69,383.97,157.13z"
              />
              <path
                d="M330.8,205.09c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.54-0.87,0.81-0.85
	C329.65,204.16,330.04,204.59,330.8,205.09z"
              />
              <path
                d="M502.04,309.02c-0.42,0.45-0.75,1.05-0.98,1.01c-0.43-0.07-0.8-0.5-1.2-0.78c0.33-0.35,0.61-0.85,1.01-0.99
	C501.1,308.17,501.54,308.67,502.04,309.02z"
              />
              <path
                d="M487.71,263.63c-0.81,0.43-1.23,0.83-1.65,0.82c-0.26,0-0.74-0.61-0.72-0.93c0.01-0.32,0.54-0.87,0.81-0.85
	C486.56,262.7,486.95,263.13,487.71,263.63z"
              />
              <path
                d="M320.43,90.28c-0.49,0.42-0.82,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C319.5,89.46,319.88,89.91,320.43,90.28z"
              />
              <path
                d="M439.3,262.71c0.39,0.65,0.81,1.06,0.81,1.48c0,0.27-0.73,0.84-0.92,0.76c-0.4-0.17-0.81-0.63-0.9-1.05
	C438.25,263.65,438.8,263.27,439.3,262.71z"
              />
              <path
                d="M461.29,310.46c-0.45-0.59-0.96-0.99-0.89-1.22c0.12-0.4,0.54-0.82,0.94-0.97c0.17-0.07,0.85,0.54,0.83,0.81
	C462.14,309.48,461.7,309.85,461.29,310.46z"
              />
              <path
                d="M313.44,90.9c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.86-0.89c0.32-0.28,0.63-0.77,0.96-0.79
	C312.62,89.97,312.94,90.47,313.44,90.9z"
              />
              <path
                d="M334.75,310.46c-0.4-0.62-0.83-1.01-0.84-1.41c-0.01-0.27,0.69-0.85,0.86-0.78c0.39,0.16,0.81,0.6,0.91,1
	C335.74,309.5,335.22,309.88,334.75,310.46z"
              />
              <path
                d="M481.1,331.32c0.48,0.59,1.02,0.99,0.96,1.23c-0.11,0.41-0.54,0.86-0.94,1.01c-0.19,0.07-0.9-0.52-0.89-0.79
	C480.26,332.35,480.69,331.95,481.1,331.32z"
              />
              <path
                d="M339.35,265.39c-0.42,0.44-0.77,1.01-0.99,0.97c-0.42-0.08-0.78-0.52-1.16-0.81c0.34-0.34,0.62-0.82,1.03-0.96
	C338.45,264.52,338.87,265.03,339.35,265.39z"
              />
              <path
                d="M342.14,307.46c0.33,0.54,0.76,0.92,0.72,1.24c-0.04,0.33-0.54,0.6-0.84,0.9c-0.32-0.36-0.79-0.68-0.91-1.1
	C341.06,308.3,341.67,307.91,342.14,307.46z"
              />
              <path
                d="M386.9,145.95c-0.45,0.43-0.82,1.01-1.05,0.96c-0.43-0.09-0.78-0.55-1.17-0.86c0.35-0.34,0.66-0.82,1.08-0.95
	C385.99,145.03,386.41,145.56,386.9,145.95z"
              />
              <path
                d="M481.49,358.2c-0.84,0.42-1.28,0.81-1.7,0.79c-0.27-0.01-0.73-0.64-0.71-0.97c0.02-0.31,0.57-0.86,0.85-0.83
	C480.35,357.23,480.73,357.67,481.49,358.2z"
              />
              <path
                d="M491.29,267c-0.53,0.37-1,0.88-1.21,0.79c-0.39-0.17-0.63-0.68-0.93-1.05c0.35-0.25,0.71-0.71,1.04-0.69
	C490.52,266.08,490.82,266.58,491.29,267z"
              />
              <path
                d="M443.1,266.22c0.37,0.52,0.89,0.98,0.8,1.17c-0.18,0.39-0.68,0.63-1.04,0.93c-0.24-0.34-0.67-0.7-0.65-1.03
	C442.21,266.97,442.69,266.67,443.1,266.22z"
              />
              <path
                d="M281.82,476.59c-0.47,0.42-0.85,0.98-1.08,0.92c-0.43-0.1-0.76-0.57-1.14-0.88c0.36-0.33,0.68-0.81,1.11-0.92
	C280.94,475.65,281.34,476.19,281.82,476.59z"
              />
              <path d="M293.17,634.22c0.69-1.62,1.45-1.44,2.2-0.81c0.01,0.22,0.01,0.45,0.02,0.67C294.66,634.13,293.91,634.18,293.17,634.22z" />
              <path
                d="M482.69,268.58c-0.31-0.53-0.71-0.91-0.66-1.23c0.05-0.32,0.54-0.58,0.84-0.86c0.31,0.36,0.75,0.69,0.86,1.11
	C483.77,267.79,483.16,268.15,482.69,268.58z"
              />
              <path
                d="M435.76,308.91c-0.36-0.52-0.79-0.87-0.77-1.19c0.03-0.33,0.5-0.63,0.78-0.94c0.33,0.35,0.8,0.65,0.94,1.06
	C436.78,308.03,436.2,308.44,435.76,308.91z"
              />
              <path
                d="M270.78,307.81c-0.5,0.36-0.93,0.88-1.16,0.8c-0.41-0.14-0.71-0.63-1.05-0.97c0.39-0.3,0.76-0.75,1.19-0.83
	C269.98,306.76,270.34,307.36,270.78,307.81z"
              />
              <path
                d="M435.91,266.67c0.38,0.53,0.91,1.01,0.82,1.2c-0.19,0.4-0.7,0.65-1.08,0.96c-0.24-0.35-0.69-0.71-0.67-1.05
	C434.99,267.44,435.48,267.14,435.91,266.67z"
              />
              <path
                d="M364.8,116.64c-0.45-0.62-0.96-1.05-0.89-1.28c0.13-0.41,0.58-0.82,0.99-0.97c0.18-0.06,0.86,0.57,0.83,0.85
	C365.71,115.65,365.25,116.03,364.8,116.64z"
              />
              <path
                d="M290.82,93.92c-0.46-0.43-1.07-0.8-1.02-0.99c0.11-0.41,0.56-0.74,0.87-1.1c0.29,0.29,0.78,0.55,0.83,0.87
	C291.54,93.02,291.13,93.4,290.82,93.92z"
              />
              <path
                d="M483.79,307.25c-0.46,0.39-0.85,0.92-1.07,0.86c-0.41-0.12-0.72-0.58-1.08-0.9c0.36-0.31,0.69-0.77,1.1-0.87
	C482.97,306.29,483.34,306.84,483.79,307.25z"
              />
              <path
                d="M331.69,219.38c-0.48,0.42-0.8,0.92-1.11,0.92c-0.35,0-0.7-0.46-1.05-0.72c0.32-0.36,0.59-0.85,1-1.03
	C330.72,218.46,331.18,218.99,331.69,219.38z"
              />
              <path
                d="M317.77,307.32c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.79,1.1-0.93
	C316.91,306.29,317.31,306.88,317.77,307.32z"
              />
              <path
                d="M359.11,155.5c-0.75,0.51-1.13,0.95-1.54,0.99c-0.27,0.03-0.82-0.51-0.83-0.82c-0.02-0.33,0.43-0.95,0.7-0.96
	C357.86,154.69,358.29,155.08,359.11,155.5z"
              />
              <path
                d="M376.3,150.41c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C375.48,149.47,375.8,149.97,376.3,150.41z"
              />
              <path
                d="M336.47,150.41c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C335.65,149.47,335.97,149.97,336.47,150.41z"
              />
              <path
                d="M326.74,214.85c0.35,0.56,0.78,0.95,0.73,1.26c-0.05,0.32-0.57,0.57-0.88,0.84c-0.28-0.32-0.78-0.64-0.78-0.96
	C325.8,215.68,326.3,215.36,326.74,214.85z"
              />
              <path
                d="M357.22,94.21c-0.82,0.46-1.24,0.86-1.66,0.87c-0.27,0-0.76-0.6-0.76-0.92c0.01-0.32,0.52-0.9,0.8-0.88
	C356.02,93.3,356.42,93.72,357.22,94.21z"
              />
              <path
                d="M365.91,128.29c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C365,129.31,365.53,128.8,365.91,128.29z"
              />
              <path
                d="M396.99,157.26c-0.34-0.73-0.74-1.17-0.66-1.52c0.09-0.4,0.59-0.71,0.91-1.06c0.28,0.3,0.76,0.58,0.79,0.9
	C398.05,155.91,397.63,156.27,396.99,157.26z"
              />
              <path
                d="M486.76,269.55c0.38,0.57,0.83,0.95,0.79,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.63-0.76-0.96
	C485.87,270.39,486.35,270.06,486.76,269.55z"
              />
              <path
                d="M293.31,359.97c-0.48,0.42-0.8,0.92-1.11,0.92c-0.35,0-0.7-0.46-1.05-0.72c0.32-0.36,0.59-0.85,1-1.03
	C292.34,359.05,292.79,359.58,293.31,359.97z"
              />
              <path
                d="M458.37,305.79c-0.5,0.41-0.83,0.89-1.14,0.88c-0.35-0.02-0.68-0.48-1.02-0.75c0.33-0.35,0.62-0.84,1.03-1
	C457.43,304.84,457.87,305.38,458.37,305.79z"
              />
              <path
                d="M381.1,149.85c0.49-0.4,0.91-0.94,1.12-0.87c0.39,0.14,0.67,0.63,0.99,0.97c-0.32,0.27-0.64,0.74-0.96,0.75
	C381.93,150.7,381.6,150.24,381.1,149.85z"
              />
              <path
                d="M323.32,205.57c-0.52,0.37-0.97,0.88-1.18,0.8c-0.39-0.16-0.63-0.67-0.93-1.03c0.34-0.25,0.69-0.71,1.01-0.69
	C322.56,204.66,322.86,205.15,323.32,205.57z"
              />
              <path
                d="M505.42,305.37c-0.49,0.38-0.91,0.91-1.14,0.84c-0.42-0.13-0.73-0.61-1.08-0.95c0.38-0.31,0.73-0.77,1.16-0.86
	C504.6,304.36,504.97,304.94,505.42,305.37z"
              />
              <path
                d="M464.5,304.11c0.33,0.53,0.75,0.9,0.71,1.23c-0.04,0.32-0.53,0.59-0.83,0.89c-0.32-0.36-0.78-0.68-0.89-1.09
	C463.44,304.94,464.04,304.56,464.5,304.11z"
              />
              <path
                d="M481.19,371.03c0.46,0.73,0.88,1.12,0.89,1.52c0.01,0.29-0.6,0.89-0.8,0.84c-0.42-0.11-0.99-0.51-1.05-0.87
	C480.17,372.19,480.69,371.76,481.19,371.03z"
              />
              <path
                d="M300.6,272.66c-0.8,0.48-1.21,0.9-1.63,0.92c-0.27,0.01-0.79-0.57-0.79-0.88c0-0.33,0.49-0.92,0.76-0.92
	C299.37,271.78,299.78,272.2,300.6,272.66z"
              />
              <path
                d="M337.93,306.64c-0.44-0.61-0.95-1.03-0.87-1.26c0.13-0.4,0.57-0.81,0.98-0.96c0.17-0.06,0.85,0.57,0.82,0.84
	C338.82,305.67,338.37,306.03,337.93,306.64z"
              />
              <path
                d="M340.14,272.68c-0.5,0.4-0.83,0.87-1.16,0.87c-0.33,0-0.65-0.48-0.98-0.74c0.32-0.35,0.59-0.85,0.99-1
	C339.2,271.73,339.63,272.28,340.14,272.68z"
              />
              <path
                d="M280.26,467.97c0.4,0.77,0.78,1.19,0.77,1.6c-0.01,0.28-0.67,0.82-0.88,0.75c-0.41-0.13-0.94-0.6-0.97-0.96
	C279.15,469.03,279.71,468.64,280.26,467.97z"
              />
              <path
                d="M418.25,274.47c-0.4-0.62-0.83-1.01-0.84-1.41c-0.01-0.27,0.69-0.85,0.86-0.78c0.39,0.16,0.81,0.6,0.91,1
	C419.23,273.51,418.71,273.9,418.25,274.47z"
              />
              <path
                d="M269.31,372.8c-0.51,0.37-0.97,0.89-1.16,0.81c-0.4-0.17-0.65-0.67-0.97-1.03c0.35-0.25,0.7-0.7,1.04-0.69
	C268.53,371.88,268.84,372.38,269.31,372.8z"
              />
              <path
                d="M239.54,336.74c-0.37-0.5-0.82-0.85-0.81-1.16c0.02-0.34,0.48-0.65,0.75-0.97c0.34,0.34,0.82,0.62,0.97,1.03
	C240.53,335.83,239.97,336.26,239.54,336.74z"
              />
              <path
                d="M246.09,273.71c-0.53,0.37-0.99,0.89-1.2,0.81c-0.39-0.16-0.64-0.67-0.94-1.04c0.34-0.25,0.7-0.72,1.03-0.7
	C245.3,272.79,245.61,273.28,246.09,273.71z"
              />
              <path
                d="M412.59,273.7c-0.78,0.44-1.18,0.85-1.58,0.85c-0.31-0.01-0.91-0.63-0.85-0.81c0.14-0.41,0.56-0.94,0.93-1
	C411.43,272.68,411.85,273.2,412.59,273.7z"
              />
              <path
                d="M479.97,303.94c-0.52,0.37-0.88,0.83-1.19,0.8c-0.34-0.03-0.64-0.52-0.96-0.8c0.35-0.33,0.66-0.8,1.08-0.94
	C479.08,302.93,479.49,303.51,479.97,303.94z"
              />
              <path
                d="M440.1,303.83c-0.46,0.41-0.77,0.9-1.07,0.9c-0.34,0-0.68-0.45-1.02-0.7c0.32-0.36,0.58-0.83,0.97-1.01
	C439.16,302.93,439.6,303.45,440.1,303.83z"
              />
              <path
                d="M491.74,274.2c-0.55,0.36-0.93,0.81-1.25,0.77c-0.31-0.04-0.78-0.6-0.76-0.9c0.02-0.3,0.57-0.79,0.87-0.78
	C490.93,273.29,491.25,273.78,491.74,274.2z"
              />
              <path
                d="M282.27,370.31c0.42,0.5,0.98,0.94,0.91,1.13c-0.16,0.41-0.65,0.69-1,1.02c-0.27-0.33-0.73-0.65-0.75-0.98
	C281.42,371.15,281.88,370.81,282.27,370.31z"
              />
              <path
                d="M274.11,303.87c-0.5,0.41-0.83,0.89-1.14,0.88c-0.35-0.02-0.68-0.48-1.02-0.75c0.33-0.35,0.62-0.84,1.03-1
	C273.17,302.92,273.61,303.46,274.11,303.87z"
              />
              <path
                d="M224.99,362.08c-0.51,0.44-0.83,0.93-1.16,0.94c-0.32,0.01-0.87-0.47-0.9-0.77c-0.03-0.3,0.45-0.87,0.76-0.91
	C224.02,361.3,224.42,361.73,224.99,362.08z"
              />
              <path
                d="M485.97,302.17c0.39,0.5,0.86,0.83,0.85,1.16c-0.01,0.34-0.47,0.66-0.74,1c-0.36-0.33-0.85-0.61-1.01-1.01
	C484.99,303.12,485.55,302.67,485.97,302.17z"
              />
              <path
                d="M280.09,304.34c-0.4-0.49-0.97-0.96-0.89-1.12c0.19-0.4,0.68-0.66,1.05-0.98c0.26,0.33,0.71,0.67,0.71,1
	C280.96,303.56,280.48,303.88,280.09,304.34z"
              />
              <path
                d="M313.26,170.6c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C312.14,169.53,312.54,170.06,313.26,170.6z"
              />
              <path
                d="M302.27,396.49c-0.39-0.78-0.77-1.2-0.75-1.61c0.02-0.29,0.68-0.82,0.88-0.75c0.41,0.14,0.93,0.6,0.96,0.97
	C303.39,395.43,302.83,395.82,302.27,396.49z"
              />
              <path
                d="M231.4,394.16c-0.55,0.36-0.94,0.8-1.22,0.74c-0.33-0.07-0.58-0.57-0.86-0.89c0.32-0.28,0.64-0.76,0.97-0.77
	C230.59,393.23,230.91,393.73,231.4,394.16z"
              />
              <path d="M672.07,546.35c0.29,0.89,0.49,1.52,0.86,2.63C671.42,547.67,671.42,547.67,672.07,546.35z" />
              <path
                d="M342.77,277.38c-0.47-0.6-0.99-1.02-0.92-1.25c0.12-0.41,0.56-0.84,0.97-0.99c0.18-0.07,0.88,0.55,0.86,0.82
	C343.64,276.37,343.19,276.76,342.77,277.38z"
              />
              <path
                d="M295.74,277.85c-0.46-0.6-0.98-1-0.91-1.23c0.12-0.4,0.55-0.83,0.96-0.98c0.17-0.07,0.87,0.54,0.85,0.81
	C296.61,276.85,296.16,277.23,295.74,277.85z"
              />
              <path
                d="M460.68,303.14c-0.47-0.44-1.09-0.81-1.03-1c0.11-0.42,0.57-0.75,0.88-1.11c0.3,0.29,0.79,0.55,0.84,0.88
	C461.42,302.23,461,302.61,460.68,303.14z"
              />
              <path
                d="M250.12,277.09c-0.52,0.36-0.89,0.81-1.2,0.78c-0.34-0.04-0.63-0.53-0.95-0.82c0.36-0.33,0.67-0.79,1.1-0.93
	C249.25,276.06,249.65,276.65,250.12,277.09z"
              />
              <path
                d="M352.41,136.64c0.38,0.57,0.83,0.95,0.78,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.64-0.76-0.96
	C351.52,137.47,352,137.14,352.41,136.64z"
              />
              <path
                d="M223.25,633.16c-0.46,0.45-0.75,0.95-1.07,0.96c-0.35,0.02-0.72-0.41-1.08-0.64c0.3-0.37,0.54-0.88,0.93-1.05
	C222.25,632.34,222.71,632.82,223.25,633.16z"
              />
              <path
                d="M237.45,213.31c-0.8,0.41-1.22,0.8-1.62,0.78c-0.3-0.02-0.87-0.67-0.81-0.85c0.15-0.41,0.6-0.92,0.97-0.96
	C236.33,212.24,236.73,212.77,237.45,213.31z"
              />
              <path
                d="M304.64,169.64c0.41,0.62,0.89,1.05,0.81,1.29c-0.13,0.39-0.59,0.78-0.99,0.91c-0.17,0.05-0.82-0.59-0.78-0.85
	C303.73,170.57,304.19,170.22,304.64,169.64z"
              />
              <path d="M221.65,208.06c-0.7-0.46-0.73-1-0.2-1.56c0.09-0.1,0.55-0.05,0.63,0.07C222.49,207.18,222.51,207.74,221.65,208.06z" />
              <path
                d="M407.76,276.56c0.38,0.48,0.86,0.81,0.84,1.12c-0.01,0.34-0.48,0.66-0.74,0.99c-0.36-0.33-0.83-0.61-1.02-1.02
	C406.77,277.49,407.35,277.03,407.76,276.56z"
              />
              <path
                d="M381.23,300.55c0.37,0.52,0.9,1.03,0.81,1.17c-0.23,0.39-0.72,0.62-1.11,0.92c-0.23-0.35-0.66-0.72-0.64-1.06
	C380.31,301.26,380.81,300.98,381.23,300.55z"
              />
              <path
                d="M331.2,212.07c-0.44,0.47-0.81,1.08-1,1.02c-0.43-0.12-0.76-0.57-1.14-0.88c0.3-0.31,0.57-0.82,0.9-0.87
	C330.28,211.3,330.67,211.74,331.2,212.07z"
              />
              <path
                d="M393.63,277.61c0.42,0.61,0.85,0.98,0.88,1.39c0.02,0.26-0.66,0.87-0.83,0.8c-0.39-0.14-0.83-0.57-0.94-0.96
	C392.67,278.6,393.18,278.2,393.63,277.61z"
              />
              <path
                d="M386.34,280.86c-0.44-0.75-0.85-1.15-0.85-1.55c0-0.29,0.62-0.88,0.82-0.82c0.42,0.12,0.97,0.54,1.03,0.9
	C387.39,279.72,386.86,280.14,386.34,280.86z"
              />
              <path
                d="M482.09,298.84c0.4,0.47,0.86,0.78,0.86,1.1c0,0.33-0.44,0.67-0.68,1c-0.36-0.31-0.85-0.57-1.01-0.96
	C481.18,299.79,481.71,299.35,482.09,298.84z"
              />
              <path
                d="M340.62,279.91c-0.51,0.39-0.95,0.92-1.17,0.84c-0.4-0.15-0.66-0.65-0.97-1.01c0.33-0.26,0.67-0.74,1-0.73
	C339.8,279.01,340.13,279.49,340.62,279.91z"
              />
              <path
                d="M397.9,148.61c-0.48,0.42-0.9,0.98-1.09,0.91c-0.41-0.15-0.71-0.62-1.06-0.96c0.33-0.27,0.64-0.74,0.99-0.76
	C397.06,147.78,397.41,148.24,397.9,148.61z"
              />
              <path
                d="M252.82,460.75c0.5-0.39,0.83-0.85,1.15-0.84c0.34,0.01,0.66,0.47,0.98,0.73c-0.32,0.34-0.6,0.82-0.99,0.96
	C253.75,461.68,253.33,461.15,252.82,460.75z"
              />
              <path
                d="M489.41,298.37c0.34,0.52,0.77,0.87,0.74,1.19c-0.03,0.32-0.5,0.61-0.78,0.91c-0.32-0.34-0.79-0.65-0.91-1.05
	C488.4,299.22,488.97,298.83,489.41,298.37z"
              />
              <path
                d="M253.94,280.39c-0.5,0.39-0.84,0.85-1.14,0.83c-0.34-0.02-0.65-0.49-0.98-0.77c0.34-0.34,0.63-0.81,1.04-0.96
	C253.04,279.42,253.46,279.98,253.94,280.39z"
              />
              <path
                d="M362.73,111.71c-1.08,0.69-1.56,1.18-1.76,1.09c-0.38-0.18-0.59-0.7-0.87-1.08c0.35-0.23,0.69-0.64,1.05-0.66
	C361.55,111.05,361.98,111.38,362.73,111.71z"
              />
              <path
                d="M292.39,278.71c0.64,0.96,1.07,1.32,1.04,1.65c-0.03,0.33-0.52,0.62-0.81,0.93c-0.33-0.35-0.86-0.66-0.94-1.07
	C291.61,279.88,292.04,279.42,292.39,278.71z"
              />
              <path
                d="M277.27,299.69c-0.46,0.44-0.84,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C276.36,298.92,276.73,299.35,277.27,299.69z"
              />
              <path
                d="M290.57,364.02c-0.65,0.33-1.04,0.69-1.43,0.68c-0.35-0.01-0.68-0.43-1.02-0.66c0.29-0.37,0.51-0.88,0.89-1.05
	C289.2,362.9,289.67,363.41,290.57,364.02z"
              />
              <path
                d="M499.63,280.61c-0.49,0.4-0.92,0.94-1.12,0.87c-0.41-0.15-0.69-0.64-1.02-0.99c0.34-0.26,0.67-0.72,1.01-0.74
	C498.82,279.74,499.15,280.22,499.63,280.61z"
              />
              <path
                d="M236.28,338.17c0.38,0.57,0.83,0.95,0.79,1.26c-0.05,0.32-0.58,0.57-0.9,0.85c-0.27-0.32-0.75-0.63-0.76-0.96
	C235.4,339.01,235.87,338.68,236.28,338.17z"
              />
              <path
                d="M358.06,148.49c-0.55,0.36-0.94,0.81-1.23,0.76c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C357.24,147.55,357.57,148.05,358.06,148.49z"
              />
              <path
                d="M473.31,391.13c-0.72,0.5-1.09,0.94-1.5,0.98c-0.29,0.03-0.94-0.55-0.9-0.73c0.1-0.42,0.47-1.01,0.82-1.09
	C472.07,390.22,472.54,390.7,473.31,391.13z"
              />
              <path
                d="M457.87,298.49c-0.45,0.44-0.84,1.02-1.03,0.96c-0.42-0.13-0.73-0.58-1.09-0.9c0.31-0.29,0.59-0.78,0.92-0.82
	C456.98,297.69,457.35,298.14,457.87,298.49z"
              />
              <path
                d="M270.97,283.74c-0.48-0.74-0.99-1.2-0.91-1.52c0.08-0.37,0.66-0.75,1.08-0.85c0.19-0.05,0.78,0.58,0.76,0.87
	C271.89,282.64,271.45,283.01,270.97,283.74z"
              />
              <path
                d="M403.76,146.59c0.52,0.56,1.09,0.92,1.04,1.16c-0.08,0.42-0.49,0.9-0.88,1.07c-0.19,0.08-0.94-0.46-0.94-0.74
	C402.97,147.67,403.39,147.25,403.76,146.59z"
              />
              <path
                d="M363.81,126.57c-0.71,0.32-1.16,0.7-1.51,0.62c-0.4-0.09-0.71-0.58-1.06-0.9c0.3-0.29,0.58-0.77,0.91-0.82
	C362.45,125.44,362.81,125.88,363.81,126.57z"
              />
              <path
                d="M505.01,298.15c-0.82,0.46-1.24,0.86-1.66,0.87c-0.27,0-0.76-0.6-0.76-0.92c0.01-0.32,0.52-0.9,0.8-0.88
	C503.81,297.23,504.21,297.65,505.01,298.15z"
              />
              <path
                d="M390.21,283.96c-0.37-0.5-0.82-0.85-0.81-1.16c0.02-0.34,0.48-0.65,0.75-0.97c0.34,0.34,0.82,0.62,0.97,1.03
	C391.2,283.05,390.64,283.47,390.21,283.96z"
              />
              <path
                d="M462.7,298.12c0.48-0.39,0.9-0.93,1.1-0.86c0.39,0.14,0.66,0.62,0.97,0.96c-0.31,0.27-0.63,0.74-0.94,0.74
	C463.5,298.96,463.18,298.5,462.7,298.12z"
              />
              <path
                d="M224.5,368.18c0.37,0.52,0.84,0.89,0.8,1.2c-0.04,0.33-0.54,0.61-0.85,0.91c-0.31-0.31-0.83-0.6-0.86-0.94
	C223.57,369.05,224.08,368.7,224.5,368.18z"
              />
              <path
                d="M344.7,283.35c-0.78,0.41-1.2,0.8-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C343.6,282.31,344,282.83,344.7,283.35z"
              />
              <path
                d="M337.37,299.45c-0.4-0.64-0.88-1.09-0.79-1.32c0.15-0.39,0.62-0.78,1.04-0.9c0.17-0.05,0.81,0.62,0.77,0.89
	C338.32,298.52,337.85,298.86,337.37,299.45z"
              />
              <path
                d="M337.43,283.84c-0.45,0.46-0.84,1.07-1.02,1.01c-0.43-0.14-0.76-0.58-1.13-0.9c0.31-0.29,0.6-0.77,0.94-0.82
	C336.53,283.07,336.91,283.5,337.43,283.84z"
              />
              <path
                d="M290.42,284.33c-0.53,0.34-0.91,0.78-1.23,0.74c-0.34-0.05-0.62-0.55-0.92-0.85c0.37-0.32,0.7-0.77,1.12-0.9
	C289.58,283.27,289.97,283.87,290.42,284.33z"
              />
              <path
                d="M478.13,297.88c-0.42-0.51-0.89-0.84-0.88-1.15c0.01-0.33,0.49-0.65,0.76-0.97c0.32,0.28,0.85,0.53,0.9,0.86
	C478.96,296.93,478.5,297.32,478.13,297.88z"
              />
              <path
                d="M275.23,284.23c0.18,0.41,0.54,0.85,0.48,1.22c-0.07,0.41-0.46,0.89-0.84,1.07c-0.18,0.09-0.93-0.44-0.94-0.7
	c-0.01-0.41,0.39-0.83,0.61-1.25C274.77,284.46,275,284.34,275.23,284.23z"
              />
              <path
                d="M318.95,208.03c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C317.86,209.27,318.31,208.92,318.95,208.03z"
              />
              <path
                d="M268.81,286.25c-0.52,0.34-0.9,0.77-1.21,0.73c-0.34-0.05-0.61-0.54-0.91-0.84c0.36-0.32,0.69-0.76,1.11-0.89
	C267.98,285.19,268.36,285.79,268.81,286.25z"
              />
              <path
                d="M280.63,295.85c-0.46,0.44-0.84,1.02-1.06,0.97c-0.41-0.09-0.73-0.57-1.08-0.88c0.3-0.29,0.58-0.8,0.9-0.82
	C279.72,295.08,280.09,295.51,280.63,295.85z"
              />
              <path
                d="M481.97,365.36c-0.83,0.44-1.26,0.84-1.68,0.83c-0.27,0-0.75-0.62-0.73-0.94c0.01-0.32,0.55-0.88,0.82-0.86
	C480.8,364.42,481.2,364.85,481.97,365.36z"
              />
              <path
                d="M360.18,103.53c0.41,0.51,0.89,0.84,0.88,1.15c-0.01,0.33-0.49,0.64-0.76,0.96c-0.32-0.28-0.86-0.53-0.9-0.85
	C359.35,104.48,359.8,104.09,360.18,103.53z"
              />
              <path
                d="M452.83,293.92c0.34,0.66,0.77,1.11,0.69,1.44c-0.09,0.4-0.61,0.71-0.95,1.05c-0.29-0.31-0.78-0.6-0.81-0.94
	C451.73,295.16,452.19,294.81,452.83,293.92z"
              />
              <path
                d="M386.99,287.79c-0.43-0.45-0.92-0.74-0.94-1.06c-0.02-0.34,0.41-0.71,0.65-1.06c0.38,0.3,0.88,0.54,1.08,0.93
	C387.86,286.78,387.35,287.26,386.99,287.79z"
              />
              <path
                d="M341.58,287c-0.74,0.52-1.15,1.03-1.49,0.98c-0.37-0.06-0.8-0.58-0.95-0.99c-0.06-0.18,0.52-0.82,0.83-0.83
	C340.38,286.15,340.79,286.56,341.58,287z"
              />
              <path
                d="M267.24,366.97c-0.32-0.54-0.73-0.93-0.68-1.26c0.05-0.33,0.56-0.59,0.87-0.88c0.31,0.37,0.77,0.71,0.88,1.13
	C268.35,366.16,267.72,366.53,267.24,366.97z"
              />
              <path
                d="M327.16,208.93c-0.52,0.37-0.97,0.88-1.18,0.8c-0.39-0.16-0.63-0.67-0.93-1.03c0.34-0.25,0.69-0.71,1.01-0.69
	C326.4,208.02,326.7,208.51,327.16,208.93z"
              />
              <path
                d="M476.7,387.47c-0.84,0.42-1.28,0.81-1.7,0.79c-0.27-0.01-0.73-0.64-0.71-0.97c0.02-0.31,0.57-0.86,0.85-0.83
	C475.55,386.5,475.93,386.94,476.7,387.47z"
              />
              <path
                d="M461.03,294.7c-0.5,0.41-0.82,0.89-1.14,0.89c-0.31,0-0.85-0.49-0.87-0.79c-0.01-0.31,0.46-0.86,0.77-0.89
	C460.1,293.87,460.48,294.33,461.03,294.7z"
              />
              <path
                d="M294.24,287.64c-0.55,0.36-0.94,0.81-1.23,0.75c-0.33-0.06-0.57-0.58-0.85-0.9c0.32-0.28,0.63-0.77,0.97-0.78
	C293.42,286.7,293.75,287.2,294.24,287.64z"
              />
              <path
                d="M498.83,286.63c0.42,0.44,0.92,0.72,0.93,1.03c0.02,0.34-0.41,0.7-0.65,1.06c-0.39-0.3-0.88-0.53-1.11-0.92
	C497.92,287.66,498.45,287.15,498.83,286.63z"
              />
              <path
                d="M285.69,289.83c-0.34-0.64-0.78-1.08-0.71-1.42c0.09-0.4,0.61-0.7,0.94-1.04c0.29,0.3,0.79,0.59,0.81,0.91
	C286.76,288.61,286.3,288.97,285.69,289.83z"
              />
              <path
                d="M262.65,127.88c-0.78,0.41-1.2,0.81-1.62,0.8c-0.28,0-0.84-0.66-0.78-0.86c0.13-0.4,0.58-0.93,0.94-0.96
	C261.55,126.84,261.95,127.36,262.65,127.88z"
              />
              <path
                d="M378.63,147.69c-0.48-0.45-1.1-0.82-1.05-1.02c0.12-0.42,0.57-0.75,0.89-1.12c0.3,0.29,0.81,0.56,0.85,0.89
	C379.38,146.76,378.96,147.15,378.63,147.69z"
              />
              <path
                d="M271.82,290.66c-0.42-0.45-0.91-0.74-0.92-1.05c-0.02-0.34,0.41-0.7,0.64-1.05c0.37,0.3,0.87,0.53,1.06,0.92
	C272.69,289.66,272.18,290.14,271.82,290.66z"
              />
              <path
                d="M285.59,366.5c0.42,0.48,0.98,0.88,0.92,1.08c-0.13,0.4-0.61,0.69-0.94,1.03c-0.27-0.31-0.74-0.61-0.76-0.93
	C284.79,367.35,285.23,367.01,285.59,366.5z"
              />
              <path
                d="M378.98,139.37c-0.52,0.36-0.98,0.87-1.19,0.78c-0.38-0.17-0.62-0.68-0.92-1.04c0.34-0.25,0.7-0.7,1.02-0.68
	C378.22,138.45,378.52,138.94,378.98,139.37z"
              />
              <path
                d="M383.53,292.17c-0.35-0.76-0.75-1.23-0.67-1.58c0.09-0.41,0.61-0.73,0.94-1.08c0.29,0.31,0.78,0.59,0.82,0.92
	C384.64,290.75,384.21,291.12,383.53,292.17z"
              />
              <path
                d="M275.53,294.02c-0.37-0.5-0.81-0.83-0.79-1.15c0.02-0.33,0.47-0.64,0.74-0.96c0.34,0.33,0.81,0.62,0.96,1.02
	C276.5,293.12,275.95,293.54,275.53,294.02z"
              />
              <path
                d="M337.93,290.95c-0.51,0.39-0.85,0.86-1.16,0.84c-0.35-0.02-0.66-0.5-0.99-0.77c0.34-0.34,0.64-0.82,1.05-0.97
	C337.02,289.97,337.44,290.53,337.93,290.95z"
              />
              <path
                d="M351.65,156.08c-0.5,0.4-0.83,0.87-1.16,0.87c-0.33,0-0.65-0.48-0.98-0.74c0.32-0.35,0.59-0.86,0.99-1
	C350.72,155.13,351.15,155.68,351.65,156.08z"
              />
              <path
                d="M364.44,109.4c-0.49-0.56-1.02-0.93-0.97-1.16c0.09-0.41,0.49-0.86,0.88-1.03c0.17-0.08,0.89,0.48,0.89,0.75
	C365.24,108.37,364.82,108.77,364.44,109.4z"
              />
              <path
                d="M503.12,289.58c0.39,0.65,0.81,1.06,0.81,1.48c0,0.27-0.73,0.84-0.92,0.76c-0.4-0.17-0.81-0.63-0.9-1.05
	C502.06,290.52,502.62,290.15,503.12,289.58z"
              />
              <path
                d="M291.16,291.33c-0.49,0.42-0.81,0.9-1.14,0.9c-0.31,0-0.86-0.49-0.87-0.78c-0.01-0.31,0.45-0.86,0.76-0.9
	C290.23,290.51,290.61,290.96,291.16,291.33z"
              />
              <path
                d="M443.5,299.69c-0.51,0.45-0.83,0.95-1.13,0.93c-0.34-0.01-0.66-0.48-0.99-0.76c0.28-0.31,0.53-0.82,0.85-0.88
	C442.54,298.94,442.93,299.36,443.5,299.69z"
              />
              <path
                d="M284.98,361.69c-0.43-0.51-0.91-0.83-0.9-1.14c0.01-0.32,0.5-0.63,0.78-0.94c0.3,0.28,0.81,0.53,0.85,0.85
	C285.75,360.76,285.33,361.14,284.98,361.69z"
              />
              <path
                d="M356.24,140.01c0.37,0.56,0.82,0.93,0.77,1.24c-0.04,0.32-0.57,0.56-0.88,0.84c-0.27-0.31-0.74-0.62-0.75-0.94
	C355.37,140.84,355.84,140.51,356.24,140.01z"
              />
              <path
                d="M281,356.21c0.42,0.49,0.9,0.81,0.89,1.11c-0.01,0.33-0.48,0.89-0.76,0.89c-0.33,0-0.86-0.45-0.92-0.77
	C280.16,357.15,280.62,356.76,281,356.21z"
              />
              <path
                d="M301.9,167.67c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.31,0.56-0.78,0.88-0.79
	C301.1,166.75,301.42,167.25,301.9,167.67z"
              />
              <path
                d="M245.6,279.63c0.37,0.59,0.73,0.93,0.76,1.3c0.02,0.25-0.54,0.79-0.68,0.74c-0.37-0.13-0.88-0.48-0.92-0.8
	C244.71,280.57,245.19,280.19,245.6,279.63z"
              />
              <path
                d="M334.75,144.32c-0.37-0.56-0.81-0.94-0.77-1.24c0.05-0.32,0.57-0.57,0.89-0.85c0.27,0.32,0.74,0.63,0.75,0.95
	C335.62,143.5,335.15,143.82,334.75,144.32z"
              />
              <path
                d="M282.98,364.33c-0.55,0.39-0.87,0.77-1.24,0.82c-0.22,0.03-0.77-0.51-0.73-0.69c0.09-0.38,0.44-0.91,0.74-0.95
	C282.05,363.47,282.43,363.94,282.98,364.33z"
              />
              <path
                d="M338.01,140.48c-0.41-0.5-0.87-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.31,0.28,0.83,0.53,0.88,0.85
	C338.83,139.55,338.38,139.92,338.01,140.48z"
              />
              <path
                d="M360.32,143.35c0.35,0.6,0.7,0.96,0.72,1.33c0.01,0.24-0.57,0.76-0.71,0.71c-0.37-0.14-0.86-0.51-0.89-0.82
	C359.41,144.26,359.89,143.91,360.32,143.35z"
              />
              <path
                d="M229.83,348.48c-0.45-0.49-0.95-0.8-0.95-1.12c0-0.31,0.48-0.83,0.8-0.87c0.28-0.03,0.83,0.44,0.88,0.75
	C230.59,347.56,230.17,347.94,229.83,348.48z"
              />
              <path
                d="M331.3,225.21c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C330.4,226.02,330.88,225.7,331.3,225.21z"
              />
              <path
                d="M492.41,281.41c-0.59,0.35-0.94,0.71-1.3,0.73c-0.24,0.01-0.75-0.58-0.69-0.75c0.13-0.37,0.51-0.85,0.83-0.89
	C491.54,280.47,491.89,280.98,492.41,281.41z"
              />
              <path
                d="M466.96,279.96c-0.54,0.36-0.91,0.8-1.2,0.75c-0.32-0.06-0.8-0.59-0.78-0.87c0.03-0.31,0.55-0.77,0.88-0.79
	C466.16,279.03,466.48,279.53,466.96,279.96z"
              />
              <path
                d="M398.67,282.12c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.74,0.94-0.74
	C397.83,281.24,398.16,281.7,398.67,282.12z"
              />
              <path
                d="M332.61,147.04c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.3,0.56-0.77,0.88-0.78
	C331.8,146.12,332.12,146.62,332.61,147.04z"
              />
              <path
                d="M375.16,136.12c-0.53,0.42-0.86,0.88-1.19,0.88c-0.31,0-0.62-0.5-0.92-0.78c0.28-0.31,0.53-0.83,0.84-0.86
	C374.22,135.33,374.6,135.76,375.16,136.12z"
              />
              <path
                d="M350.89,149.19c-0.57,0.37-0.95,0.82-1.24,0.77c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.28,0.63-0.74,0.97-0.77
	C350.03,148.29,350.37,148.77,350.89,149.19z"
              />
              <path
                d="M327.18,224.47c-0.42-0.56-0.9-0.94-0.85-1.23c0.05-0.32,0.54-0.67,0.92-0.81c0.13-0.05,0.7,0.49,0.68,0.74
	C327.91,223.54,327.54,223.88,327.18,224.47z"
              />
              <path
                d="M328.17,149.61c0.34,0.55,0.77,0.93,0.72,1.24c-0.05,0.31-0.56,0.56-0.86,0.84c-0.28-0.31-0.77-0.62-0.77-0.94
	C327.26,150.43,327.74,150.11,328.17,149.61z"
              />
              <path
                d="M355.76,132.81c0.37,0.56,0.82,0.93,0.77,1.24c-0.04,0.32-0.57,0.56-0.88,0.84c-0.27-0.31-0.74-0.62-0.75-0.94
	C354.89,133.64,355.36,133.31,355.76,132.81z"
              />
              <path d="M628.1,330.17c0.05,0.55,0.1,1.11,0.19,2.07C627.33,331.43,626.9,330.93,628.1,330.17z" />
              <path
                d="M302.77,520.22c0.38,0.57,0.76,0.91,0.81,1.3c0.03,0.25-0.53,0.84-0.65,0.8c-0.38-0.14-0.91-0.48-0.95-0.79
	C301.94,521.19,302.4,520.79,302.77,520.22z"
              />
              <path
                d="M351.93,131.84c-0.41-0.5-0.88-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.32,0.28,0.83,0.53,0.89,0.85
	C352.74,130.91,352.29,131.29,351.93,131.84z"
              />
              <path
                d="M346.41,151.8c0.35,0.54,0.79,0.91,0.74,1.21c-0.05,0.32-0.59,0.81-0.86,0.78c-0.32-0.04-0.81-0.55-0.82-0.87
	C345.46,152.61,345.97,152.29,346.41,151.8z"
              />
              <path
                d="M387.36,152.87c-0.52,0.44-0.84,0.94-1.14,0.92c-0.34-0.02-0.66-0.49-0.98-0.76c0.28-0.31,0.53-0.81,0.86-0.87
	C386.4,152.1,386.79,152.53,387.36,152.87z"
              />
              <path
                d="M339.45,152.28c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C338.55,153.08,339.04,152.76,339.45,152.28z"
              />
              <path
                d="M356.13,126.66c-0.5,0.46-0.81,0.98-1.11,0.96c-0.32-0.02-0.74-0.48-0.87-0.84c-0.07-0.18,0.41-0.79,0.65-0.8
	C355.16,125.99,355.53,126.34,356.13,126.66z"
              />
              <path
                d="M305.27,163.89c-0.59,0.33-0.96,0.69-1.32,0.69c-0.24,0-0.73-0.6-0.67-0.77c0.13-0.36,0.53-0.84,0.86-0.86
	C304.43,162.92,304.76,163.44,305.27,163.89z"
              />
              <path
                d="M297.58,283.65c-0.48,0.42-0.8,0.92-1.1,0.9c-0.33-0.01-0.87-0.48-0.89-0.77c-0.02-0.3,0.45-0.83,0.78-0.89
	C296.67,282.84,297.05,283.29,297.58,283.65z"
              />
              <path
                d="M295.65,315.16c0.43,0.5,0.91,0.83,0.9,1.14c0,0.32-0.5,0.62-0.77,0.94c-0.31-0.28-0.81-0.53-0.86-0.84
	C294.87,316.08,295.3,315.71,295.65,315.16z"
              />
              <path
                d="M257.12,316.19c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.75,0.94-0.74
	C256.29,315.31,256.61,315.78,257.12,316.19z"
              />
              <path
                d="M265.7,124.01c-0.54,0.35-0.91,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C264.91,123.11,265.23,123.59,265.7,124.01z"
              />
              <path
                d="M410.91,312.26c0.38,0.59,0.84,0.99,0.78,1.29c-0.06,0.32-0.59,0.64-0.97,0.75c-0.14,0.04-0.66-0.54-0.64-0.79
	C410.13,313.15,410.51,312.82,410.91,312.26z"
              />
              <path
                d="M419.31,312.84c-0.58,0.36-0.97,0.79-1.3,0.74c-0.31-0.04-0.55-0.58-0.81-0.9c0.32-0.26,0.64-0.73,0.95-0.73
	C418.48,311.95,418.79,312.42,419.31,312.84z"
              />
              <path
                d="M462.62,282.52c0.3,0.62,0.62,1,0.62,1.38c0,0.23-0.63,0.71-0.78,0.64c-0.36-0.16-0.82-0.58-0.82-0.89
	C461.64,283.34,462.16,283.03,462.62,282.52z"
              />
              <path
                d="M359.48,123.05c-0.54,0.36-0.92,0.8-1.21,0.75c-0.32-0.06-0.8-0.59-0.78-0.88c0.03-0.3,0.56-0.77,0.88-0.78
	C358.68,122.13,358.99,122.62,359.48,123.05z"
              />
              <path
                d="M393.82,311.68c-0.59,0.33-1.02,0.77-1.32,0.69c-0.3-0.08-0.59-0.65-0.63-1.04c-0.02-0.18,0.59-0.66,0.8-0.61
	C393.05,310.83,393.33,311.24,393.82,311.68z"
              />
              <path
                d="M395.32,159.64c-0.53,0.41-0.87,0.88-1.2,0.87c-0.31-0.01-0.61-0.5-0.92-0.79c0.28-0.3,0.54-0.82,0.85-0.85
	C394.38,158.84,394.75,159.27,395.32,159.64z"
              />
              <path
                d="M489.06,285.25c-0.59,0.35-0.94,0.71-1.3,0.73c-0.24,0.01-0.75-0.58-0.69-0.75c0.13-0.37,0.51-0.85,0.83-0.89
	C488.18,284.31,488.53,284.81,489.06,285.25z"
              />
              <path
                d="M481.38,278.51c-0.58,0.36-0.93,0.73-1.29,0.75c-0.24,0.01-0.76-0.57-0.71-0.73c0.12-0.37,0.49-0.85,0.82-0.9
	C480.48,277.58,480.85,278.08,481.38,278.51z"
              />
              <path
                d="M263.52,308.04c-0.5,0.46-0.82,0.98-1.11,0.96c-0.32-0.02-0.74-0.48-0.87-0.84c-0.07-0.18,0.41-0.79,0.65-0.8
	C262.55,307.37,262.92,307.72,263.52,308.04z"
              />
              <path
                d="M270.8,486.96c0.38,0.5,0.76,0.8,0.82,1.16c0.04,0.2-0.44,0.49-0.69,0.75c-0.3-0.29-0.7-0.54-0.84-0.89
	C270.03,487.81,270.45,487.44,270.8,486.96z"
              />
              <path
                d="M331.98,306.11c-0.58,0.36-0.96,0.79-1.29,0.75c-0.31-0.04-0.55-0.57-0.82-0.89c0.31-0.27,0.63-0.74,0.94-0.74
	C331.14,305.23,331.46,305.7,331.98,306.11z"
              />
              <path
                d="M472.29,257.47c-0.51,0.45-0.83,0.95-1.13,0.94c-0.34-0.01-0.66-0.48-0.99-0.75c0.28-0.31,0.52-0.82,0.85-0.88
	C471.32,256.71,471.72,257.13,472.29,257.47z"
              />
              <path
                d="M472.77,304.5c-0.52,0.44-0.84,0.94-1.14,0.92c-0.34-0.02-0.66-0.49-0.98-0.76c0.28-0.31,0.53-0.81,0.86-0.87
	C471.81,303.73,472.2,304.16,472.77,304.5z"
              />
              <path
                d="M384.9,303.64c0.43,0.5,0.91,0.83,0.9,1.14c0,0.32-0.5,0.62-0.77,0.94c-0.31-0.28-0.81-0.53-0.86-0.84
	C384.12,304.57,384.55,304.19,384.9,303.64z"
              />
              <path
                d="M300.91,87.02c0.44,0.49,0.95,0.82,0.94,1.12c-0.01,0.32-0.49,0.83-0.82,0.87c-0.27,0.03-0.81-0.46-0.86-0.78
	C300.13,87.93,300.56,87.56,300.91,87.02z"
              />
              <path
                d="M265.75,303.14c0.37,0.59,0.73,0.93,0.76,1.3c0.02,0.25-0.54,0.79-0.68,0.74c-0.37-0.13-0.88-0.48-0.92-0.8
	C264.86,304.08,265.34,303.71,265.75,303.14z"
              />
              <path
                d="M303.56,408.24c0.41,0.5,0.87,0.83,0.86,1.14c-0.01,0.32-0.48,0.63-0.75,0.95c-0.32-0.28-0.85-0.52-0.89-0.84
	C302.75,409.17,303.19,408.8,303.56,408.24z"
              />
              <path
                d="M326.5,88.46c0.38,0.55,0.84,0.94,0.78,1.23c-0.06,0.32-0.59,0.77-0.92,0.77c-0.27,0-0.74-0.56-0.75-0.89
	C325.6,89.26,326.08,88.95,326.5,88.46z"
              />
              <path
                d="M481.85,285.53c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C480.82,284.74,481.26,285.18,481.85,285.53z"
              />
              <path
                d="M475.03,262.11c-0.45-0.49-0.95-0.8-0.95-1.12c0-0.31,0.48-0.83,0.8-0.87c0.28-0.03,0.83,0.44,0.88,0.75
	C475.79,261.18,475.37,261.57,475.03,262.11z"
              />
              <path
                d="M337.37,93.47c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.86,0.53,0.88,0.85
	C338.3,92.59,337.8,92.94,337.37,93.47z"
              />
              <path
                d="M487.29,279.17c-0.34-0.62-0.77-1.05-0.69-1.34c0.09-0.32,0.64-0.59,1.03-0.68c0.14-0.03,0.62,0.59,0.57,0.84
	C488.13,278.35,487.72,278.64,487.29,279.17z"
              />
              <path
                d="M288.26,302.44c-0.55,0.39-0.87,0.77-1.24,0.82c-0.22,0.03-0.77-0.51-0.73-0.69c0.09-0.38,0.44-0.91,0.74-0.95
	C287.33,301.57,287.71,302.04,288.26,302.44z"
              />
              <path
                d="M478.91,265.47c-0.34-0.55-0.76-0.94-0.71-1.25c0.05-0.31,0.61-0.77,0.89-0.74c0.32,0.04,0.79,0.57,0.79,0.88
	C479.86,264.69,479.36,264.99,478.91,265.47z"
              />
              <path
                d="M476.13,300.83c-0.57,0.37-0.95,0.81-1.25,0.76c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.27,0.64-0.74,0.98-0.76
	C475.28,299.93,475.62,300.41,476.13,300.83z"
              />
              <path
                d="M466.58,288.29c-0.4-0.56-0.79-0.88-0.83-1.24c-0.03-0.26,0.5-0.85,0.62-0.81c0.39,0.12,0.91,0.43,0.98,0.75
	C467.42,287.29,466.96,287.7,466.58,288.29z"
              />
              <path
                d="M460.53,287.53c-0.53,0.4-0.85,0.8-1.22,0.86c-0.23,0.04-0.8-0.48-0.76-0.66c0.08-0.38,0.41-0.93,0.71-0.98
	C459.56,286.68,459.96,287.15,460.53,287.53z"
              />
              <path
                d="M465.8,265.56c-0.54,0.35-0.91,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C465.01,264.66,465.32,265.14,465.8,265.56z"
              />
              <path
                d="M322.72,211.5c0.42,0.53,0.82,0.83,0.89,1.19c0.04,0.25-0.44,0.86-0.59,0.83c-0.39-0.09-0.94-0.37-1.02-0.69
	C321.93,212.54,322.37,212.11,322.72,211.5z"
              />
              <path
                d="M270.03,300.46c-0.48,0.41-0.8,0.88-1.12,0.88c-0.31,0-0.84-0.48-0.85-0.77c-0.01-0.31,0.45-0.85,0.75-0.89
	C269.12,299.64,269.49,300.09,270.03,300.46z"
              />
              <path
                d="M358.8,115.81c-0.56,0.37-0.96,0.84-1.25,0.78c-0.31-0.06-0.64-0.59-0.72-0.97c-0.04-0.18,0.53-0.71,0.75-0.67
	C357.95,115.02,358.27,115.41,358.8,115.81z"
              />
              <path
                d="M458.6,266.09c-0.59,0.33-1.02,0.77-1.32,0.69c-0.3-0.08-0.59-0.65-0.63-1.04c-0.02-0.18,0.59-0.66,0.8-0.61
	C457.83,265.24,458.11,265.66,458.6,266.09z"
              />
              <path
                d="M241.66,278.67c-0.37-0.56-0.81-0.94-0.77-1.24c0.05-0.32,0.57-0.57,0.89-0.85c0.27,0.32,0.73,0.63,0.75,0.95
	C242.53,277.85,242.06,278.17,241.66,278.67z"
              />
              <path
                d="M237.07,268.35c-0.34-0.55-0.76-0.94-0.71-1.25c0.05-0.31,0.61-0.77,0.89-0.74c0.32,0.04,0.79,0.57,0.79,0.88
	C238.02,267.56,237.52,267.87,237.07,268.35z"
              />
              <path
                d="M416.11,276.89c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C415.09,276.1,415.52,276.55,416.11,276.89z"
              />
              <path
                d="M484.8,287.79c0.38,0.59,0.84,0.99,0.78,1.29c-0.06,0.32-0.59,0.64-0.97,0.75c-0.14,0.04-0.66-0.54-0.64-0.79
	C484.02,288.67,484.41,288.35,484.8,287.79z"
              />
              <path
                d="M468.33,270.05c-0.32-0.61-0.66-0.98-0.66-1.36c0-0.24,0.61-0.74,0.75-0.67c0.36,0.16,0.84,0.55,0.85,0.87
	C469.29,269.2,468.78,269.53,468.33,270.05z"
              />
              <path
                d="M463.14,276.41c-0.51,0.44-0.8,0.86-1.16,0.93c-0.24,0.05-0.85-0.44-0.82-0.61c0.07-0.39,0.36-0.92,0.68-1.03
	C462.11,275.62,462.55,276.07,463.14,276.41z"
              />
              <path
                d="M385.39,297.71c-0.54,0.36-0.91,0.8-1.2,0.75c-0.32-0.06-0.8-0.59-0.78-0.87c0.03-0.31,0.55-0.77,0.88-0.79
	C384.58,296.79,384.91,297.28,385.39,297.71z"
              />
              <path
                d="M272.27,297.87c-0.41-0.5-0.87-0.83-0.86-1.13c0.01-0.33,0.48-0.64,0.75-0.95c0.31,0.28,0.84,0.53,0.88,0.85
	C273.08,296.94,272.64,297.32,272.27,297.87z"
              />
              <path
                d="M486.43,296.27c-0.54,0.35-0.92,0.79-1.23,0.75c-0.3-0.04-0.76-0.59-0.74-0.89c0.02-0.3,0.56-0.78,0.86-0.77
	C485.65,295.38,485.96,295.86,486.43,296.27z"
              />
              <path
                d="M446.86,296.03c-0.57,0.37-0.95,0.81-1.25,0.76c-0.33-0.06-0.59-0.56-0.88-0.87c0.32-0.27,0.64-0.74,0.98-0.76
	C446.01,295.13,446.34,295.61,446.86,296.03z"
              />
              <path d="M255.83,634.03c-0.83,0-1.47,0-2.29,0C254.68,632.73,254.68,632.73,255.83,634.03z" />
              <path
                d="M358.32,108.55c-0.53,0.4-0.85,0.8-1.22,0.86c-0.23,0.04-0.8-0.48-0.76-0.66c0.08-0.38,0.41-0.93,0.71-0.98
	C357.36,107.71,357.76,108.17,358.32,108.55z"
              />
              <path d="M588.39,634.13c-1.11,0-1.77,0-2.5,0C586.76,632.66,586.77,632.66,588.39,634.13z" />
              <path
                d="M492.37,294.31c0.44,0.49,0.95,0.81,0.95,1.12c-0.01,0.32-0.49,0.83-0.81,0.87c-0.27,0.03-0.82-0.46-0.87-0.77
	C491.59,295.22,492.02,294.85,492.37,294.31z"
              />
              <path
                d="M356.31,100.17c0.42,0.53,0.82,0.83,0.89,1.19c0.04,0.25-0.44,0.86-0.59,0.83c-0.39-0.09-0.94-0.37-1.02-0.69
	C355.52,101.22,355.96,100.79,356.31,100.17z"
              />
              <path
                d="M464.37,290.98c-0.58,0.36-0.99,0.82-1.28,0.75c-0.31-0.07-0.63-0.61-0.7-1c-0.03-0.18,0.55-0.69,0.78-0.65
	C463.54,290.16,463.84,290.57,464.37,290.98z"
              />
              <path
                d="M465.01,273.89c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.85,0.52,0.88,0.85
	C465.94,273,465.45,273.35,465.01,273.89z"
              />
              <path
                d="M455.91,292.59c-0.34-0.55-0.77-0.93-0.72-1.24c0.05-0.32,0.56-0.56,0.86-0.84c0.28,0.32,0.76,0.63,0.77,0.95
	C456.83,291.77,456.34,292.09,455.91,292.59z"
              />
              <path
                d="M495.85,292.83c-0.4-0.5-0.87-0.83-0.86-1.14c0.01-0.32,0.51-0.86,0.78-0.85c0.33,0.01,0.86,0.46,0.9,0.78
	C496.71,291.91,496.24,292.29,495.85,292.83z"
              />
              <path
                d="M283.99,292.21c-0.58,0.36-0.97,0.79-1.3,0.74c-0.31-0.04-0.55-0.58-0.81-0.9c0.32-0.26,0.64-0.73,0.95-0.73
	C283.16,291.32,283.48,291.79,283.99,292.21z"
              />
              <path d="M538.92,634.23c0.64-1.39,1.37-1.45,2.43-0.15C540.32,634.15,539.62,634.19,538.92,634.23z" />
              <path
                d="M448.7,290.95c0.44,0.49,0.95,0.81,0.95,1.12c-0.01,0.32-0.49,0.83-0.81,0.87c-0.27,0.03-0.82-0.46-0.87-0.77
	C447.92,291.87,448.35,291.49,448.7,290.95z"
              />
              <path
                d="M481.33,294.05c-0.34-0.61-0.69-0.97-0.7-1.34c-0.01-0.25,0.58-0.76,0.72-0.7c0.37,0.15,0.85,0.52,0.88,0.85
	C482.26,293.15,481.76,293.5,481.33,294.05z"
              />
              <path
                d="M489.8,292.42c-0.58,0.36-0.99,0.82-1.28,0.75c-0.31-0.07-0.63-0.61-0.7-1c-0.03-0.18,0.55-0.69,0.78-0.65
	C488.97,291.6,489.27,292.01,489.8,292.42z"
              />
              <path
                d="M484.52,274.55c-0.48,0.41-0.8,0.88-1.12,0.88c-0.31,0-0.84-0.48-0.85-0.77c-0.01-0.31,0.45-0.85,0.75-0.89
	C483.61,273.73,483.98,274.18,484.52,274.55z"
              />
              <path
                d="M441.63,294.05c-0.4-0.56-0.79-0.88-0.83-1.24c-0.03-0.26,0.5-0.85,0.62-0.81c0.39,0.12,0.91,0.43,0.98,0.75
	C442.46,293.05,442.01,293.46,441.63,294.05z"
              />
              <path d="M461.81,634.04c-0.89,0-1.57,0-2.37,0C460.49,632.77,460.49,632.77,461.81,634.04z" />
              <path
                d="M439.6,296.57c-0.5,0.43-0.78,0.84-1.13,0.92c-0.23,0.05-0.82-0.43-0.8-0.6c0.06-0.38,0.35-0.91,0.66-1.01
	C438.6,295.79,439.03,296.23,439.6,296.57z"
              />
              <path d="M499.42,634.21c0.64-1.33,1.33-1.33,2.24-0.12C500.76,634.14,500.09,634.18,499.42,634.21z" />
              <path
                d="M275.06,357.75c-0.5,0.44-0.82,0.92-1.11,0.91c-0.33-0.01-0.65-0.47-0.97-0.73c0.28-0.31,0.51-0.8,0.84-0.86
	C274.11,357.01,274.49,357.42,275.06,357.75z"
              />
              <path
                d="M268.41,292.13c0.36,0.59,0.8,0.99,0.74,1.29c-0.07,0.31-0.59,0.61-0.97,0.72c-0.13,0.04-0.63-0.54-0.6-0.8
	C267.63,292.98,268.02,292.67,268.41,292.13z"
              />
              <path
                d="M387.51,292.61c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C386.73,293.63,387.16,293.21,387.51,292.61z"
              />
              <path
                d="M475.44,293.47c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C474.41,292.72,474.82,293.13,475.44,293.47z"
              />
              <path
                d="M458.03,272.02c0.42,0.44,0.9,0.74,0.87,0.95c-0.06,0.37-0.44,0.7-0.69,1.04c-0.29-0.22-0.83-0.44-0.83-0.66
	C457.38,272.98,457.72,272.61,458.03,272.02z"
              />
              <path d="M332.78,634.06c0.98-1.16,1.64-1.12,2.72,0C334.42,634.06,333.75,634.06,332.78,634.06z" />
              <path
                d="M458.53,279.18c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C457.75,280.19,458.18,279.78,458.53,279.18z"
              />
              <path
                d="M294.85,294.81c-0.46,0.35-0.83,0.8-1,0.73c-0.34-0.14-0.68-0.51-0.81-0.87c-0.05-0.14,0.51-0.73,0.69-0.69
	C294.1,294.07,294.39,294.44,294.85,294.81z"
              />
              <path
                d="M251.06,457.33c-0.34,0.32-0.63,0.71-0.72,0.67c-0.3-0.14-0.58-0.41-0.74-0.7c-0.05-0.09,0.37-0.6,0.51-0.58
	C250.41,456.78,250.67,457.06,251.06,457.33z"
              />
              <path
                d="M426.72,272.56c-0.63,0.34-1,0.68-1.36,0.67c-0.25,0-0.76-0.6-0.7-0.72c0.17-0.35,0.54-0.8,0.87-0.83
	C425.83,271.64,426.17,272.12,426.72,272.56z"
              />
              <path
                d="M333.54,293.85c0.31,0.59,0.64,0.96,0.63,1.32c0,0.23-0.59,0.69-0.77,0.62c-0.36-0.13-0.85-0.54-0.85-0.84
	C332.55,294.65,333.07,294.34,333.54,293.85z"
              />
              <path
                d="M348.9,140.79c0.43,0.49,0.93,0.8,0.92,1.1c-0.01,0.31-0.49,0.81-0.81,0.84c-0.27,0.03-0.79-0.45-0.84-0.76
	C348.13,141.68,348.56,141.31,348.9,140.79z"
              />
              <path
                d="M480.72,271.4c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C479.69,270.64,480.1,271.06,480.72,271.4z"
              />
              <path
                d="M285.25,100.41c-0.43,0.38-0.78,0.85-0.95,0.79c-0.35-0.11-0.72-0.46-0.86-0.81c-0.06-0.15,0.45-0.77,0.64-0.74
	C284.44,99.7,284.76,100.06,285.25,100.41z"
              />
              <path
                d="M389.93,155.18c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C389.1,156.1,389.56,155.72,389.93,155.18z"
              />
              <path
                d="M250.4,197.57c-0.59,0.39-0.96,0.84-1.27,0.8c-0.3-0.04-0.65-0.54-0.77-0.9c-0.05-0.15,0.49-0.69,0.72-0.67
	C249.46,196.84,249.8,197.2,250.4,197.57z"
              />
              <path
                d="M441.03,271.1c-0.48,0.45-0.79,0.96-1.08,0.95c-0.31-0.01-0.73-0.47-0.85-0.82c-0.06-0.18,0.4-0.78,0.62-0.78
	C440.09,270.44,440.46,270.78,441.03,271.1z"
              />
              <path
                d="M271.99,361.81c-0.63,0.34-1.06,0.75-1.37,0.68c-0.3-0.07-0.57-0.62-0.65-1c-0.03-0.14,0.57-0.61,0.81-0.56
	C271.15,360.99,271.45,361.38,271.99,361.81z"
              />
              <path
                d="M264.77,298.09c-0.19-0.37-0.52-0.75-0.52-1.12c0-0.22,0.53-0.44,0.82-0.65c0.25,0.34,0.64,0.66,0.71,1.04
	c0.06,0.34-0.24,0.74-0.38,1.12C265.19,298.34,264.98,298.22,264.77,298.09z"
              />
              <path
                d="M240.89,271.68c-0.33-0.54-0.74-0.92-0.69-1.22c0.05-0.3,0.6-0.75,0.88-0.71c0.31,0.04,0.78,0.56,0.77,0.86
	C241.84,270.92,241.34,271.21,240.89,271.68z"
              />
              <path
                d="M469.87,297.24c0.63-0.31,1.07-0.69,1.39-0.61c0.29,0.07,0.56,0.64,0.61,1.02c0.02,0.15-0.59,0.6-0.83,0.54
	C470.68,298.1,470.39,297.69,469.87,297.24z"
              />
              <path
                d="M275.9,172.18c0.44,0.5,0.93,0.82,0.93,1.13c0,0.3-0.46,0.71-0.81,0.86c-0.15,0.06-0.75-0.41-0.75-0.64
	C275.26,173.15,275.59,172.78,275.9,172.18z"
              />
              <path
                d="M353.93,111.3c0.27,0.59,0.58,0.97,0.56,1.33c-0.01,0.22-0.53,0.41-0.82,0.62c-0.22-0.35-0.58-0.68-0.62-1.05
	C353.02,111.99,353.5,111.72,353.93,111.3z"
              />
              <path
                d="M461.28,268.14c0.38,0.58,0.82,0.97,0.77,1.26c-0.06,0.31-0.57,0.63-0.94,0.74c-0.13,0.04-0.66-0.53-0.63-0.77
	C460.52,269.01,460.9,268.68,461.28,268.14z"
              />
              <path
                d="M390.88,288.84c0.4,0.44,0.87,0.75,0.82,0.95c-0.07,0.36-0.45,0.66-0.7,0.99c-0.28-0.22-0.79-0.45-0.79-0.67
	C390.23,289.76,390.57,289.4,390.88,288.84z"
              />
              <path
                d="M283.42,174.53c-0.59-1.08-0.93-1.45-0.94-1.82c0-0.22,0.52-0.45,0.8-0.68c0.26,0.34,0.67,0.65,0.74,1.02
	C284.08,173.38,283.75,173.77,283.42,174.53z"
              />
              <path
                d="M264.53,291.15c-0.38-0.57-0.75-0.89-0.78-1.25c-0.02-0.25,0.51-0.81,0.64-0.76c0.37,0.13,0.86,0.45,0.93,0.77
	C265.37,290.19,264.91,290.58,264.53,291.15z"
              />
              <path
                d="M437.79,288.37c0.33,0.54,0.67,0.88,0.7,1.25c0.01,0.21-0.49,0.46-0.75,0.69c-0.26-0.32-0.65-0.6-0.74-0.96
	C436.95,289.14,437.4,288.82,437.79,288.37z"
              />
              <path
                d="M290.62,300.25c-0.59-1.08-0.93-1.45-0.94-1.82c0-0.22,0.52-0.45,0.8-0.68c0.26,0.34,0.67,0.65,0.74,1.02
	C291.28,299.09,290.95,299.48,290.62,300.25z"
              />
              <path
                d="M336.76,276.64c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C335.71,275.92,336.14,276.32,336.76,276.64z"
              />
              <path
                d="M331.47,298.78c-0.51,0.41-0.84,0.86-1.16,0.86c-0.31,0-0.61-0.48-0.91-0.75c0.27-0.3,0.52-0.81,0.82-0.84
	C330.54,298.01,330.91,298.43,331.47,298.78z"
              />
              <path
                d="M475.54,269.3c-0.33-0.59-0.68-0.94-0.69-1.3c-0.01-0.22,0.56-0.7,0.74-0.65c0.36,0.12,0.87,0.51,0.88,0.8
	C476.49,268.46,475.99,268.78,475.54,269.3z"
              />
              <path
                d="M283.12,298.16c0.45,0.52,0.97,0.85,0.94,1.14c-0.03,0.31-0.52,0.69-0.89,0.81c-0.17,0.06-0.75-0.43-0.74-0.66
	C282.45,299.09,282.79,298.74,283.12,298.16z"
              />
              <path
                d="M450.05,267.85c-0.3-0.6-0.63-0.97-0.63-1.34c0-0.22,0.6-0.67,0.78-0.61c0.36,0.13,0.84,0.55,0.84,0.84
	C451.04,267.05,450.53,267.36,450.05,267.85z"
              />
              <path
                d="M287.22,95.4c0.41,0.53,0.81,0.82,0.86,1.17c0.04,0.25-0.44,0.84-0.58,0.81c-0.38-0.09-0.92-0.37-0.99-0.68
	C286.45,96.41,286.88,96,287.22,95.4z"
              />
              <path
                d="M342.04,141.25c0.38,0.56,0.75,0.88,0.78,1.23c0.02,0.24-0.51,0.77-0.67,0.73c-0.38-0.1-0.89-0.43-0.95-0.75
	C341.13,142.19,341.62,141.8,342.04,141.25z"
              />
              <path
                d="M294.38,94.93c0.43,0.5,0.83,0.79,0.9,1.14c0.05,0.24-0.41,0.85-0.55,0.83c-0.38-0.07-0.94-0.34-1.02-0.64
	C293.64,95.96,294.06,95.53,294.38,94.93z"
              />
              <path
                d="M298.58,171.73c-0.56,0.37-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.61-0.73,0.95-0.75
	C297.73,170.87,298.06,171.33,298.58,171.73z"
              />
              <path
                d="M493.19,288.16c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C492.14,287.43,492.57,287.84,493.19,288.16z"
              />
              <path
                d="M371.8,140.09c-0.62,0.35-1.03,0.77-1.34,0.71c-0.3-0.06-0.6-0.6-0.69-0.97c-0.03-0.14,0.55-0.63,0.79-0.59
	C370.93,139.29,371.24,139.67,371.8,140.09z"
              />
              <path
                d="M342.61,95.69c-0.51,0.32-0.84,0.67-1.2,0.71c-0.2,0.02-0.68-0.62-0.62-0.75c0.16-0.34,0.53-0.68,0.89-0.8
	C341.85,94.79,342.18,95.28,342.61,95.69z"
              />
              <path
                d="M435.02,301.64c-0.31-0.56-0.64-0.92-0.65-1.28c0-0.22,0.51-0.45,0.79-0.67c0.25,0.33,0.63,0.64,0.7,1
	C435.9,300.89,435.43,301.2,435.02,301.64z"
              />
              <path
                d="M452.03,289.45c-0.33-0.59-0.68-0.94-0.69-1.3c-0.01-0.22,0.57-0.7,0.74-0.65c0.36,0.12,0.87,0.51,0.88,0.8
	C452.98,288.61,452.48,288.94,452.03,289.45z"
              />
              <path
                d="M331.9,139.74c-0.52,0.39-0.82,0.78-1.18,0.84c-0.22,0.04-0.78-0.47-0.74-0.65c0.08-0.37,0.4-0.91,0.7-0.96
	C330.95,138.91,331.34,139.37,331.9,139.74z"
              />
              <path
                d="M328.71,143.52c-0.47,0.4-0.75,0.8-1.11,0.88c-0.21,0.05-0.8-0.53-0.76-0.66c0.14-0.37,0.45-0.77,0.81-0.93
	C327.82,142.73,328.22,143.17,328.71,143.52z"
              />
              <path
                d="M307.81,94.67c0.5-0.36,0.81-0.74,1.18-0.8c0.19-0.03,0.73,0.59,0.67,0.74c-0.14,0.36-0.5,0.73-0.86,0.85
	C308.63,95.52,308.27,95.05,307.81,94.67z"
              />
              <path
                d="M473.03,264.86c-0.58,0.35-0.96,0.76-1.28,0.72c-0.3-0.04-0.53-0.57-0.8-0.88c0.31-0.26,0.63-0.72,0.94-0.71
	C472.21,263.99,472.52,264.44,473.03,264.86z"
              />
              <path
                d="M368.43,143.73c-0.54,0.43-0.83,0.83-1.2,0.91c-0.24,0.05-0.84-0.42-0.81-0.56c0.07-0.38,0.35-0.94,0.64-1
	C367.38,143,367.81,143.4,368.43,143.73z"
              />
              <path
                d="M467.81,300.09c0.35,0.52,0.78,0.89,0.73,1.18c-0.05,0.31-0.57,0.79-0.84,0.76c-0.32-0.04-0.8-0.53-0.81-0.84
	C466.88,300.89,467.38,300.58,467.81,300.09z"
              />
              <path
                d="M345.17,139.73c-0.39-0.49-0.85-0.82-0.83-1.12c0.02-0.31,0.5-0.83,0.77-0.83c0.32,0.01,0.84,0.46,0.88,0.76
	C346.02,138.84,345.55,139.2,345.17,139.73z"
              />
              <path
                d="M317.64,93.82c-0.9,0.75-1.26,1.22-1.44,1.16c-0.36-0.13-0.62-0.54-0.92-0.83c0.33-0.27,0.62-0.69,0.99-0.77
	C316.61,93.3,317.02,93.6,317.64,93.82z"
              />
              <path
                d="M494.7,278.4c-0.44-0.48-0.94-0.78-0.94-1.09c0-0.3,0.47-0.81,0.78-0.85c0.27-0.03,0.81,0.43,0.86,0.73
	C495.46,277.49,495.04,277.86,494.7,278.4z"
              />
              <path
                d="M328.13,302.58c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C327.1,301.83,327.51,302.24,328.13,302.58z"
              />
              <path
                d="M297.94,90.88c0.35,0.52,0.78,0.89,0.74,1.18c-0.05,0.31-0.57,0.79-0.84,0.77c-0.32-0.03-0.79-0.53-0.81-0.84
	C297.01,91.69,297.5,91.37,297.94,90.88z"
              />
              <path
                d="M320.08,302.09c0.14,0.37,0.44,0.77,0.39,1.11c-0.06,0.37-0.44,0.69-0.69,1.04c-0.29-0.21-0.82-0.43-0.83-0.65
	c-0.01-0.37,0.32-0.75,0.5-1.13C319.67,302.34,319.87,302.21,320.08,302.09z"
              />
              <path
                d="M395.19,286c-0.41,0.43-0.71,0.93-0.91,0.9c-0.37-0.07-0.79-0.4-0.96-0.73c-0.08-0.15,0.38-0.84,0.57-0.83
	C394.26,285.35,394.63,285.69,395.19,286z"
              />
              <path
                d="M355.54,119.7c-0.43,0.33-0.81,0.78-0.98,0.72c-0.35-0.14-0.6-0.56-0.89-0.86c0.34-0.26,0.65-0.64,1.02-0.72
	C354.88,118.79,355.18,119.31,355.54,119.7z"
              />
              <path
                d="M268.78,120.08c-0.54,0.39-0.85,0.77-1.2,0.82c-0.23,0.03-0.78-0.5-0.74-0.66c0.1-0.37,0.42-0.87,0.74-0.94
	C267.85,119.23,268.24,119.7,268.78,120.08z"
              />
              <path
                d="M227.58,351.29c-0.64,0.32-1.02,0.65-1.37,0.63c-0.25-0.01-0.74-0.63-0.67-0.75c0.18-0.35,0.56-0.78,0.89-0.81
	C226.72,350.35,227.04,350.83,227.58,351.29z"
              />
              <path d="M627.48,634.09c-0.61,0-1.23,0-1.95,0C626.42,633.01,626.42,633.01,627.48,634.09z" />
              <path
                d="M305.16,549.19c0.36,0.46,0.79,0.79,0.73,0.98c-0.11,0.35-0.49,0.61-0.76,0.91c-0.26-0.24-0.76-0.51-0.73-0.71
	C304.43,550.02,304.8,549.7,305.16,549.19z"
              />
              <path
                d="M310.09,260.99c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C309.24,260.11,309.57,260.58,310.09,260.99z"
              />
              <path
                d="M441.15,284.53c0.33,0.54,0.67,0.88,0.7,1.25c0.01,0.21-0.49,0.46-0.75,0.69c-0.26-0.32-0.65-0.6-0.74-0.96
	C440.31,285.3,440.76,284.98,441.15,284.53z"
              />
              <path
                d="M432.81,304.44c-0.47,0.34-0.86,0.77-1.02,0.69c-0.34-0.15-0.66-0.53-0.78-0.89c-0.05-0.14,0.52-0.71,0.72-0.66
	C432.08,303.66,432.36,304.05,432.81,304.44z"
              />
              <path
                d="M230.31,260.6c-0.44,0.38-0.71,0.78-1.06,0.86c-0.19,0.05-0.76-0.51-0.72-0.64c0.12-0.36,0.43-0.74,0.77-0.91
	C229.45,259.84,229.85,260.27,230.31,260.6z"
              />
              <path
                d="M319.34,215.37c0.43,0.5,0.83,0.79,0.9,1.14c0.05,0.24-0.41,0.85-0.55,0.83c-0.38-0.07-0.94-0.34-1.02-0.64
	C318.59,216.4,319.01,215.97,319.34,215.37z"
              />
              <path
                d="M389.96,308.28c-0.57,0.35-0.98,0.79-1.26,0.72c-0.3-0.08-0.6-0.62-0.67-0.99c-0.03-0.17,0.55-0.67,0.77-0.62
	C389.16,307.46,389.45,307.86,389.96,308.28z"
              />
              <path
                d="M328.59,404.87c-0.39-0.56-0.85-0.94-0.8-1.23c0.06-0.32,0.55-0.65,0.91-0.78c0.13-0.05,0.67,0.5,0.65,0.75
	C329.32,403.97,328.96,404.31,328.59,404.87z"
              />
              <path
                d="M415.33,309.68c-0.47,0.36-0.85,0.81-1.03,0.74c-0.35-0.14-0.69-0.52-0.82-0.88c-0.05-0.15,0.51-0.74,0.7-0.7
	C414.55,308.92,414.85,309.3,415.33,309.68z"
              />
              <path
                d="M345.87,144.6c0.38,0.56,0.75,0.88,0.78,1.23c0.02,0.24-0.51,0.77-0.67,0.73c-0.38-0.1-0.89-0.43-0.95-0.75
	C344.97,145.54,345.46,145.16,345.87,144.6z"
              />
              <path
                d="M493.87,284.38c0.56-0.35,0.94-0.76,1.26-0.73c0.3,0.03,0.55,0.55,0.82,0.85c-0.31,0.27-0.61,0.75-0.92,0.75
	C494.71,285.25,494.39,284.79,493.87,284.38z"
              />
              <path
                d="M455.42,285.39c-0.34-0.59-0.68-0.95-0.7-1.31c-0.01-0.24,0.57-0.75,0.7-0.7c0.36,0.15,0.83,0.51,0.86,0.83
	C456.32,284.51,455.84,284.86,455.42,285.39z"
              />
              <path
                d="M250.49,284.29c-0.49,0.34-0.88,0.78-1.05,0.71c-0.35-0.15-0.68-0.54-0.81-0.91c-0.05-0.14,0.54-0.72,0.74-0.67
	C249.74,283.49,250.03,283.89,250.49,284.29z"
              />
              <path
                d="M309.2,401.9c0.48-0.44,0.75-0.85,1.11-0.95c0.21-0.06,0.81,0.41,0.79,0.59c-0.05,0.38-0.32,0.94-0.62,1.03
	C310.21,402.65,309.78,402.22,309.2,401.9z"
              />
              <path
                d="M259.21,313.44c-0.37-0.56-0.73-0.89-0.76-1.25c-0.02-0.23,0.52-0.75,0.69-0.71c0.38,0.1,0.9,0.45,0.94,0.75
	C260.12,312.53,259.62,312.9,259.21,313.44z"
              />
              <path
                d="M290.58,313c0.48-0.35,0.79-0.72,1.15-0.78c0.19-0.03,0.71,0.57,0.65,0.72c-0.14,0.35-0.49,0.71-0.84,0.83
	C291.37,313.83,291.02,313.37,290.58,313z"
              />
              <path
                d="M395.5,314.52c0.63-0.31,1.07-0.69,1.39-0.61c0.29,0.07,0.56,0.64,0.61,1.02c0.02,0.15-0.59,0.6-0.83,0.54
	C396.3,315.37,396.02,314.97,395.5,314.52z"
              />
              <path
                d="M307.32,398.39c-0.43,0.38-0.78,0.85-0.95,0.79c-0.35-0.11-0.72-0.46-0.86-0.81c-0.06-0.15,0.45-0.77,0.64-0.74
	C306.52,397.68,306.84,398.04,307.32,398.39z"
              />
              <path
                d="M485.19,281.79c-0.54,0.39-0.85,0.77-1.2,0.82c-0.23,0.03-0.78-0.5-0.74-0.66c0.1-0.37,0.42-0.87,0.74-0.94
	C484.26,280.94,484.65,281.41,485.19,281.79z"
              />
              <path
                d="M339.84,146.14c-0.5,0.44-0.82,0.92-1.11,0.91c-0.33-0.01-0.65-0.47-0.97-0.73c0.28-0.31,0.51-0.8,0.84-0.86
	C338.88,145.4,339.27,145.81,339.84,146.14z"
              />
              <path
                d="M360.68,137.25c-0.48,0.43-0.75,0.84-1.11,0.93c-0.22,0.06-0.81-0.41-0.79-0.58c0.05-0.38,0.32-0.95,0.61-1.03
	C359.67,136.48,360.1,136.92,360.68,137.25z"
              />
              <path
                d="M289.67,316.9c-0.56,0.35-0.97,0.8-1.25,0.73c-0.31-0.07-0.61-0.61-0.68-0.98c-0.03-0.18,0.55-0.68,0.76-0.63
	C288.87,316.1,289.17,316.49,289.67,316.9z"
              />
              <path
                d="M371.86,155.56c-0.44-0.48-0.94-0.78-0.94-1.09c0-0.3,0.47-0.81,0.78-0.85c0.27-0.03,0.81,0.43,0.86,0.73
	C372.62,154.65,372.2,155.03,371.86,155.56z"
              />
              <path
                d="M232.77,342.32c0.44,0.48,0.93,0.8,0.93,1.1c-0.01,0.31-0.49,0.81-0.8,0.84c-0.27,0.03-0.8-0.45-0.85-0.76
	C232,343.21,232.43,342.84,232.77,342.32z"
              />
              <path
                d="M322.16,220.04c0.48-0.44,0.75-0.85,1.11-0.95c0.21-0.06,0.81,0.41,0.79,0.59c-0.05,0.38-0.32,0.94-0.62,1.03
	C323.16,220.79,322.73,220.36,322.16,220.04z"
              />
              <path
                d="M371.36,146.06c0.35,0.52,0.78,0.89,0.73,1.18c-0.05,0.31-0.57,0.79-0.84,0.76c-0.32-0.04-0.8-0.53-0.81-0.84
	C370.43,146.86,370.93,146.55,371.36,146.06z"
              />
              <path
                d="M365.3,147.54c-0.54,0.46-0.87,0.95-1.16,0.93c-0.33-0.03-0.71-0.47-0.89-0.82c-0.06-0.12,0.43-0.72,0.68-0.73
	C364.29,146.9,364.66,147.23,365.3,147.54z"
              />
              <path
                d="M226.39,629.58c-0.62,0.33-1.03,0.74-1.34,0.67c-0.29-0.06-0.56-0.61-0.64-0.98c-0.03-0.14,0.56-0.6,0.79-0.56
	C225.56,628.79,225.86,629.17,226.39,629.58z"
              />
              <path
                d="M317.73,233.65c0.26,0.59,0.57,0.98,0.55,1.35c-0.01,0.22-0.55,0.41-0.85,0.62c-0.23-0.35-0.59-0.69-0.63-1.06
	C316.78,234.33,317.28,234.06,317.73,233.65z"
              />
              <path
                d="M317.29,220.68c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C316.44,219.81,316.77,220.27,317.29,220.68z"
              />
              <path
                d="M305.01,535.72c-0.39,0.37-0.61,0.73-0.78,0.71c-0.31-0.05-0.58-0.32-0.87-0.49c0.17-0.22,0.33-0.6,0.51-0.61
	C304.17,535.3,304.48,535.51,305.01,535.72z"
              />
              <path
                d="M335.26,136c-0.56,0.35-0.97,0.8-1.25,0.73c-0.31-0.07-0.61-0.61-0.68-0.98c-0.03-0.18,0.54-0.68,0.76-0.63
	C334.45,135.19,334.74,135.59,335.26,136z"
              />
              <path
                d="M331.59,232.42c0.45,0.52,0.97,0.85,0.94,1.14c-0.03,0.31-0.52,0.69-0.89,0.81c-0.17,0.06-0.75-0.43-0.74-0.66
	C330.91,233.35,331.25,233,331.59,232.42z"
              />
              <path
                d="M257.79,283.54c-0.56,0.36-0.93,0.8-1.22,0.75c-0.33-0.06-0.58-0.54-0.87-0.84c0.32-0.27,0.62-0.73,0.95-0.75
	C256.94,282.67,257.27,283.13,257.79,283.54z"
              />
              <path
                d="M319.95,224.65c-0.19-0.36-0.51-0.73-0.51-1.09c0-0.21,0.52-0.43,0.8-0.64c0.24,0.34,0.62,0.65,0.68,1.02
	c0.06,0.32-0.24,0.71-0.38,1.07C320.35,224.89,320.15,224.77,319.95,224.65z"
              />
              <path d="M628.14,338.8c0-0.7,0-1.41,0-2.59c0.96,1.09,0.79,1.84,0.56,2.59C628.51,338.8,628.33,338.8,628.14,338.8z" />
              <path
                d="M349.71,134.83c-0.58,0.34-0.97,0.76-1.28,0.71c-0.3-0.05-0.53-0.57-0.79-0.89c0.32-0.25,0.63-0.71,0.95-0.71
	C348.9,133.95,349.21,134.41,349.71,134.83z"
              />
              <path
                d="M343.68,149.49c-0.5,0.44-0.81,0.93-1.1,0.92c-0.33-0.01-0.65-0.47-0.98-0.73c0.27-0.31,0.51-0.8,0.83-0.87
	C342.72,148.76,343.11,149.17,343.68,149.49z"
              />
              <path
                d="M393.28,151.34c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C392.46,152.26,392.92,151.88,393.28,151.34z"
              />
              <path
                d="M338.55,132.02c-0.46,0.38-0.76,0.78-1.12,0.86c-0.2,0.04-0.78-0.53-0.73-0.67c0.13-0.37,0.45-0.77,0.8-0.92
	C337.67,131.22,338.06,131.67,338.55,132.02z"
              />
              <path
                d="M299.41,278.71c0.44,0.5,0.93,0.82,0.93,1.13c0,0.3-0.46,0.71-0.81,0.86c-0.15,0.06-0.75-0.41-0.75-0.64
	C298.78,279.68,299.1,279.31,299.41,278.71z"
              />
              <path
                d="M371.32,132.72c-0.55,0.42-0.85,0.81-1.22,0.88c-0.24,0.04-0.83-0.44-0.8-0.58c0.08-0.38,0.38-0.92,0.67-0.98
	C370.28,131.97,370.7,132.38,371.32,132.72z"
              />
              <path
                d="M367.85,149.9c0.41,0.48,0.89,0.79,0.88,1.08c-0.01,0.32-0.47,0.87-0.74,0.87c-0.32,0-0.84-0.43-0.91-0.75
	C367.03,150.82,367.48,150.44,367.85,149.9z"
              />
              <path
                d="M331.26,132.61c-0.42,0.33-0.83,0.79-0.95,0.72c-0.34-0.18-0.57-0.58-0.84-0.9c0.29-0.22,0.57-0.61,0.86-0.61
	C330.6,131.83,330.86,132.25,331.26,132.61z"
              />
              <path
                d="M328.86,230.61c-0.46,0.28-0.79,0.65-1.06,0.61c-0.28-0.05-0.49-0.49-0.73-0.76c0.32-0.26,0.6-0.63,0.97-0.74
	C328.19,229.67,328.51,230.21,328.86,230.61z"
              />
              <path
                d="M240.4,328.07c-0.96,0.68-1.39,1.12-1.52,1.04c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C239.35,327.55,239.73,327.81,240.4,328.07z"
              />
              <path
                d="M218.16,629.12c0.36,0.43,0.76,0.69,0.77,0.96c0.01,0.3-0.34,0.61-0.53,0.92c-0.31-0.26-0.74-0.48-0.89-0.81
	C217.44,630,217.85,629.6,218.16,629.12z"
              />
              <path
                d="M333.65,127.67c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C332.82,128.45,333.29,128.1,333.65,127.67z"
              />
              <path d="M615.67,233.27c0.4,0.72,0.67,1.22,1.02,1.85C615.49,234.87,615.49,234.87,615.67,233.27z" />
              <path
                d="M242.11,325.59c-0.3-0.5-0.65-0.82-0.62-1.1c0.04-0.29,0.45-0.53,0.69-0.79c0.27,0.32,0.66,0.6,0.76,0.96
	C243,324.85,242.51,325.17,242.11,325.59z"
              />
              <path
                d="M246.33,320.74c-0.44,0.32-0.86,0.76-0.98,0.69c-0.34-0.19-0.55-0.6-0.81-0.93c0.3-0.21,0.6-0.59,0.88-0.58
	C245.69,319.94,245.94,320.36,246.33,320.74z"
              />
              <path
                d="M247.65,316.79c0.43-0.33,0.85-0.79,0.97-0.72c0.34,0.19,0.56,0.6,0.83,0.92c-0.29,0.22-0.58,0.61-0.86,0.6
	C248.32,317.58,248.06,317.16,247.65,316.79z"
              />
              <path
                d="M307.26,158.86c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C306.43,159.64,306.9,159.29,307.26,158.86z"
              />
              <path
                d="M407.07,309.05c0.32,0.46,0.7,0.76,0.68,1.05c-0.01,0.28-0.41,0.55-0.64,0.83c-0.29-0.3-0.69-0.56-0.81-0.91
	C406.24,309.83,406.7,309.48,407.07,309.05z"
              />
              <path
                d="M412.83,280.71c-0.36,0.39-0.68,0.92-0.82,0.88c-0.37-0.12-0.65-0.49-0.97-0.75c0.24-0.27,0.46-0.71,0.73-0.75
	C412.05,280.05,412.37,280.42,412.83,280.71z"
              />
              <path
                d="M389.33,301.03c-0.42,0.33-0.83,0.79-0.95,0.72c-0.34-0.18-0.57-0.58-0.84-0.9c0.29-0.22,0.57-0.61,0.86-0.61
	C388.66,300.25,388.92,300.67,389.33,301.03z"
              />
              <path
                d="M304.93,575c-0.07,0.59-0.14,1.17-0.21,1.76c-0.22-0.02-0.43-0.04-0.65-0.05c0.03-0.59,0.06-1.17,0.08-1.76
	C304.41,574.96,304.67,574.98,304.93,575z"
              />
              <path d="M487.51,25.77c-1.14,0.7-1.57,0.34-1.52-1.01C486.62,25.17,487.07,25.47,487.51,25.77z" />
              <path
                d="M334.38,95.04c0.33,0.46,0.77,0.85,0.7,1.02c-0.15,0.35-0.57,0.58-0.88,0.85c-0.21-0.3-0.58-0.6-0.57-0.89
	C333.63,95.74,334.03,95.47,334.38,95.04z"
              />
              <path
                d="M443.78,273.54c0.33,0.46,0.77,0.85,0.7,1.02c-0.15,0.35-0.57,0.58-0.88,0.85c-0.21-0.3-0.58-0.6-0.57-0.89
	C443.03,274.24,443.43,273.97,443.78,273.54z"
              />
              <path
                d="M291.17,98.88c0.34,0.45,0.78,0.83,0.72,1c-0.14,0.35-0.56,0.58-0.86,0.87c-0.21-0.29-0.59-0.58-0.59-0.87
	C290.44,99.6,290.83,99.32,291.17,98.88z"
              />
              <path
                d="M256.69,142.38c-0.95,0.67-1.37,1.1-1.5,1.03c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C255.66,141.85,256.04,142.12,256.69,142.38z"
              />
              <path
                d="M297.95,290.95c-0.44,0.34-0.84,0.8-0.97,0.73c-0.34-0.18-0.56-0.58-0.83-0.9c0.29-0.22,0.58-0.62,0.86-0.62
	C297.28,290.16,297.54,290.59,297.95,290.95z"
              />
              <path d="M226.42,179.19c0.15-0.83,0.25-1.4,0.38-2.13c0.32,0.27,0.6,0.39,0.62,0.53C227.46,178.16,227.43,178.17,226.42,179.19z" />
              <path
                d="M308.3,33.08c-0.21,0.39-0.42,0.79-0.65,1.16c-0.01,0.02-0.39-0.11-0.39-0.15c0.05-0.45,0.15-0.89,0.23-1.33
	C307.76,32.87,308.03,32.97,308.3,33.08z"
              />
              <path
                d="M438.09,274.8c-0.96,0.68-1.39,1.12-1.52,1.04c-0.33-0.19-0.52-0.61-0.77-0.94c0.3-0.21,0.58-0.57,0.89-0.59
	C437.05,274.28,437.43,274.55,438.09,274.8z"
              />
              <path
                d="M275.09,373.34c-0.38-0.45-0.85-0.8-0.79-0.97c0.12-0.35,0.47-0.74,0.82-0.86c0.17-0.06,0.8,0.46,0.77,0.64
	C275.83,372.51,275.46,372.83,275.09,373.34z"
              />
              <path
                d="M278.8,361.16c-0.4,0.37-0.71,0.85-0.92,0.81c-0.38-0.08-0.7-0.46-1.05-0.72c0.32-0.28,0.61-0.68,0.98-0.78
	C278.03,360.41,278.37,360.84,278.8,361.16z"
              />
              <path
                d="M364.28,140.75c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C364.54,140.33,364.41,140.54,364.28,140.75z"
              />
              <path
                d="M323.7,139.47c0.39,0.43,0.8,0.69,0.8,0.95c0,0.26-0.39,0.72-0.64,0.74c-0.26,0.02-0.71-0.35-0.77-0.61
	C323.04,140.3,323.4,139.97,323.7,139.47z"
              />
              <path
                d="M332.22,279.56c0.36,0.46,0.81,0.84,0.75,0.98c-0.16,0.34-0.53,0.66-0.89,0.78c-0.15,0.05-0.71-0.49-0.67-0.67
	C331.49,280.3,331.86,280.02,332.22,279.56z"
              />
              <path
                d="M223.36,353.52c0.21,0.57,0.49,0.96,0.43,1.28c-0.07,0.35-0.45,0.64-0.7,0.95c-0.3-0.28-0.7-0.52-0.85-0.86
	C222.16,354.75,222.63,354.38,223.36,353.52z"
              />
              <path
                d="M452.49,281.43c-0.62,0.16-1.04,0.38-1.38,0.31c-0.21-0.05-0.31-0.56-0.45-0.87c0.37-0.16,0.74-0.45,1.11-0.44
	C451.96,280.43,452.14,280.93,452.49,281.43z"
              />
              <path
                d="M274.57,366.09c-0.36-0.45-0.8-0.8-0.73-0.96c0.13-0.34,0.49-0.69,0.83-0.8c0.16-0.05,0.74,0.47,0.71,0.65
	C275.3,365.33,274.93,365.62,274.57,366.09z"
              />
              <path
                d="M305.7,543.17c-0.55,0.29-0.84,0.54-1.14,0.55c-0.15,0.01-0.44-0.44-0.43-0.67c0.02-0.23,0.38-0.63,0.52-0.6
	C304.94,542.52,305.19,542.81,305.7,543.17z"
              />
              <path
                d="M239.43,281.73c-0.53,0.26-0.88,0.57-1.15,0.51c-0.26-0.06-0.44-0.5-0.65-0.78c0.28-0.21,0.57-0.59,0.85-0.58
	C238.75,280.89,238.99,281.31,239.43,281.73z"
              />
              <path
                d="M405.55,281.82c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C405.8,281.4,405.68,281.61,405.55,281.82z"
              />
              <path
                d="M444.3,280.55c0.33,0.44,0.71,0.74,0.69,1c-0.02,0.27-0.45,0.51-0.69,0.76c-0.28-0.29-0.66-0.54-0.8-0.88
	C443.44,281.29,443.93,280.94,444.3,280.55z"
              />
              <path
                d="M279.35,368.67c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C279.6,368.25,279.48,368.46,279.35,368.67z"
              />
              <path
                d="M222.54,347c0.33,0.42,0.78,0.78,0.71,0.95c-0.12,0.34-0.53,0.57-0.82,0.84c-0.21-0.28-0.58-0.55-0.58-0.83
	C221.86,347.69,222.23,347.41,222.54,347z"
              />
              <path
                d="M272.18,369.15c-0.38,0.18-0.77,0.49-1.15,0.48c-0.21,0-0.41-0.54-0.62-0.83c0.35-0.23,0.68-0.59,1.05-0.65
	c0.34-0.05,0.73,0.24,1.1,0.38C272.43,368.74,272.3,368.95,272.18,369.15z"
              />
              <path
                d="M437.15,280.74c0.52,1.03,0.85,1.38,0.79,1.65c-0.06,0.27-0.51,0.44-0.79,0.66c-0.22-0.29-0.59-0.56-0.61-0.86
	C436.52,281.85,436.81,281.49,437.15,280.74z"
              />
              <path
                d="M303.37,529.65c-0.28-0.47-0.53-0.71-0.51-0.92c0.02-0.23,0.3-0.43,0.47-0.65c0.18,0.2,0.46,0.39,0.49,0.62
	C303.86,528.91,303.62,529.16,303.37,529.65z"
              />
              <path
                d="M118.08,631.67c-0.42,0.33-0.66,0.63-0.95,0.68c-0.17,0.03-0.61-0.39-0.58-0.54c0.06-0.31,0.35-0.58,0.55-0.87
	C117.38,631.15,117.64,631.35,118.08,631.67z"
              />
              <path
                d="M477.92,282.58c-0.42,0.31-0.76,0.74-0.97,0.68c-0.36-0.1-0.64-0.5-0.96-0.77c0.34-0.25,0.65-0.62,1.03-0.7
	C477.22,281.75,477.52,282.21,477.92,282.58z"
              />
              <path
                d="M325.52,227.28c-0.71,0.29-1.08,0.57-1.41,0.54c-0.3-0.03-0.77-0.37-0.8-0.63c-0.03-0.25,0.34-0.73,0.61-0.8
	C324.17,226.34,324.52,226.7,325.52,227.28z"
              />
              <path
                d="M470.74,283.01c-0.41,0.34-0.75,0.8-0.96,0.74c-0.38-0.09-0.68-0.49-1.02-0.76c0.34-0.26,0.65-0.66,1.03-0.74
	C470,282.2,470.33,282.66,470.74,283.01z"
              />
              <path
                d="M225.7,344.94c-0.36-0.39-0.77-0.63-0.79-0.89c-0.01-0.28,0.36-0.58,0.56-0.87c0.33,0.25,0.66,0.51,0.99,0.76
	C226.25,344.22,226.04,344.5,225.7,344.94z"
              />
              <path
                d="M366.77,135.58c0.38,0.47,0.86,0.88,0.79,1.01c-0.18,0.35-0.56,0.69-0.93,0.81c-0.15,0.05-0.73-0.51-0.69-0.7
	C366.01,136.34,366.4,136.04,366.77,135.58z"
              />
              <path
                d="M473.35,277.67c0.21,0.59,0.5,0.99,0.44,1.31c-0.07,0.36-0.46,0.65-0.72,0.98c-0.31-0.29-0.72-0.53-0.88-0.88
	C472.13,278.94,472.61,278.55,473.35,277.67z"
              />
              <path
                d="M326.95,137.64c-0.36-0.38-0.83-0.72-0.78-0.86c0.13-0.34,0.5-0.59,0.78-0.87c0.24,0.25,0.66,0.48,0.69,0.75
	C327.66,136.91,327.27,137.2,326.95,137.64z"
              />
              <path
                d="M324.26,146.64c0.35,0.47,0.71,0.77,0.77,1.11c0.03,0.19-0.54,0.7-0.69,0.65c-0.35-0.12-0.71-0.46-0.85-0.8
	C323.43,147.46,323.88,147.09,324.26,146.64z"
              />
              <path
                d="M230.26,340.49c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C229.98,340.06,230.12,340.27,230.26,340.49z"
              />
              <path
                d="M242.36,283.66c0.28,0.51,0.62,0.87,0.56,1.13c-0.06,0.27-0.52,0.45-0.81,0.66c-0.21-0.29-0.58-0.58-0.58-0.87
	C241.55,284.32,241.97,284.07,242.36,283.66z"
              />
              <path
                d="M449.89,284.81c-1.02,0.52-1.39,0.85-1.78,0.86c-0.21,0.01-0.43-0.52-0.65-0.81c0.34-0.24,0.66-0.63,1.03-0.69
	C448.8,284.13,449.18,284.46,449.89,284.81z"
              />
              <path
                d="M316.79,226.74c0.39,0.49,0.77,0.79,0.85,1.16c0.04,0.18-0.57,0.72-0.75,0.67c-0.35-0.11-0.72-0.48-0.86-0.83
	C315.97,227.57,316.43,227.2,316.79,226.74z"
              />
              <path
                d="M556.93,85.4c-0.28-0.36-0.55-0.71-0.83-1.07c0.13-0.1,0.27-0.2,0.4-0.31c0.27,0.36,0.54,0.72,0.81,1.08
	C557.18,85.21,557.06,85.3,556.93,85.4z"
              />
              <path
                d="M342.84,135.11c-0.99,0.5-1.35,0.82-1.73,0.83c-0.21,0-0.43-0.51-0.64-0.8c0.33-0.24,0.64-0.62,1-0.68
	C341.78,134.43,342.14,134.77,342.84,135.11z"
              />
              <path
                d="M441.43,278.37c-0.43,0.38-0.7,0.79-0.97,0.8c-0.26,0-0.74-0.4-0.75-0.64c-0.02-0.27,0.35-0.74,0.62-0.79
	C440.59,277.68,440.93,278.05,441.43,278.37z"
              />
              <path
                d="M402.3,285.3c-0.44,0.38-0.7,0.78-0.96,0.78c-0.28,0-0.55-0.39-0.83-0.6c0.23-0.27,0.43-0.7,0.7-0.75
	C401.46,284.67,401.8,285.01,402.3,285.3z"
              />
              <path
                d="M303.46,91.44c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C304.87,92.29,304.5,91.97,303.46,91.44z"
              />
              <path
                d="M344.62,90.5c0.37,0.47,0.73,0.76,0.8,1.11c0.04,0.17-0.54,0.7-0.71,0.64c-0.34-0.11-0.7-0.46-0.83-0.8
	C343.83,91.3,344.27,90.95,344.62,90.5z"
              />
              <path
                d="M225.06,377.56c-0.37-0.39-0.85-0.73-0.79-0.87c0.13-0.34,0.5-0.59,0.78-0.87c0.24,0.25,0.65,0.48,0.67,0.75
	C225.75,376.82,225.37,377.12,225.06,377.56z"
              />
              <path
                d="M293.63,167.31c0.33,0.44,0.71,0.74,0.69,1c-0.02,0.27-0.45,0.51-0.69,0.76c-0.28-0.29-0.66-0.54-0.8-0.88
	C292.77,168.05,293.26,167.7,293.63,167.31z"
              />
              <path
                d="M330.57,94.06c-0.4-0.47-0.79-0.76-0.9-1.12c-0.05-0.16,0.55-0.74,0.73-0.69c0.36,0.09,0.74,0.46,0.89,0.8
	C331.37,93.21,330.92,93.59,330.57,94.06z"
              />
              <path
                d="M447.65,278.73c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C448.37,277.99,447.98,278.29,447.65,278.73z"
              />
              <path
                d="M474.65,286.62c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C474.9,286.2,474.77,286.41,474.65,286.62z"
              />
              <path
                d="M235.56,333.41c-0.36-0.38-0.83-0.73-0.78-0.86c0.13-0.34,0.51-0.58,0.78-0.86c0.24,0.25,0.65,0.48,0.68,0.75
	C236.27,332.69,235.88,332.98,235.56,333.41z"
              />
              <path
                d="M261.53,286.79c-0.4,0.37-0.71,0.85-0.92,0.81c-0.38-0.08-0.7-0.46-1.05-0.72c0.32-0.28,0.61-0.68,0.98-0.78
	C260.75,286.03,261.1,286.47,261.53,286.79z"
              />
              <path
                d="M301.33,286.8c-0.47,0.35-0.75,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C300.53,286.12,300.84,286.48,301.33,286.8z"
              />
              <path
                d="M322.13,93.55c0.57-0.4,0.88-0.76,1.23-0.81c0.22-0.03,0.68,0.41,0.7,0.66c0.02,0.26-0.37,0.78-0.58,0.78
	C323.12,94.18,322.76,93.87,322.13,93.55z"
              />
              <path
                d="M321.16,150.46c0.35,0.5,0.72,0.81,0.77,1.17c0.03,0.18-0.58,0.69-0.75,0.62c-0.34-0.12-0.68-0.5-0.8-0.85
	C320.32,151.24,320.78,150.9,321.16,150.46z"
              />
              <path
                d="M259.59,471.03c-0.42,0.34-0.69,0.69-0.85,0.64c-0.31-0.09-0.55-0.4-0.83-0.61c0.19-0.21,0.4-0.6,0.58-0.6
	C258.79,470.47,259.08,470.75,259.59,471.03z"
              />
              <path
                d="M253.5,288.36c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.76,0.44,0.73,0.62
	C254.19,287.57,253.84,287.88,253.5,288.36z"
              />
              <path
                d="M455.68,277.48c-0.36,0.18-0.72,0.49-1.07,0.48c-0.23-0.01-0.43-0.5-0.65-0.78c0.34-0.24,0.65-0.6,1.02-0.67
	c0.32-0.06,0.7,0.23,1.05,0.37C455.91,277.08,455.79,277.28,455.68,277.48z"
              />
              <path
                d="M333.23,286.34c0.21,0.58,0.49,0.97,0.43,1.29c-0.07,0.35-0.45,0.64-0.7,0.96c-0.3-0.28-0.7-0.52-0.85-0.86
	C332.04,287.57,332.51,287.2,333.23,286.34z"
              />
              <path
                d="M302.76,95.34c-0.38,0.35-0.69,0.81-0.88,0.77c-0.36-0.08-0.67-0.44-1-0.69c0.31-0.27,0.58-0.66,0.93-0.75
	C302.01,94.61,302.34,95.03,302.76,95.34z"
              />
              <path
                d="M327.21,95.83c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C326.39,96.55,326.86,96.21,327.21,95.83z"
              />
              <path
                d="M344.67,132.42c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.75,0.43,0.73,0.62
	C345.37,131.62,345.01,131.93,344.67,132.42z"
              />
              <path
                d="M319.99,96.29c0.33,0.47,0.72,0.78,0.68,1.03c-0.04,0.26-0.48,0.65-0.75,0.65c-0.25-0.01-0.66-0.44-0.68-0.7
	C319.23,97.01,319.63,96.73,319.99,96.29z"
              />
              <path
                d="M225.77,384.63c-0.32-0.36-0.64-0.56-0.65-0.78c-0.01-0.23,0.27-0.49,0.42-0.73c0.27,0.21,0.61,0.38,0.78,0.65
	C226.37,383.88,226.03,384.23,225.77,384.63z"
              />
              <path
                d="M444.96,289.85c-0.38-0.45-0.85-0.8-0.79-0.97c0.12-0.35,0.47-0.74,0.82-0.86c0.17-0.06,0.8,0.46,0.77,0.64
	C445.68,289.02,445.32,289.34,444.96,289.85z"
              />
              <path
                d="M312.7,99.08c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C313.47,98.33,313.07,98.62,312.7,99.08z"
              />
              <path
                d="M352.62,99.26c-0.36-0.44-0.76-0.72-0.74-0.97c0.02-0.27,0.44-0.7,0.69-0.7c0.26,0,0.7,0.39,0.74,0.66
	C353.34,98.49,352.95,98.8,352.62,99.26z"
              />
              <path
                d="M305.49,97.75c0.36,0.38,0.83,0.72,0.78,0.86c-0.12,0.34-0.5,0.59-0.77,0.87c-0.25-0.24-0.66-0.47-0.69-0.74
	C304.79,98.49,305.17,98.19,305.49,97.75z"
              />
              <path
                d="M299.41,99.05c-0.4,0.42-0.7,0.92-0.87,0.87c-0.37-0.1-0.68-0.46-1.01-0.71c0.26-0.25,0.48-0.65,0.78-0.71
	C298.58,98.46,298.92,98.79,299.41,99.05z"
              />
              <path
                d="M422.48,274.82c0.21,0.58,0.49,0.97,0.43,1.29c-0.07,0.35-0.45,0.64-0.7,0.96c-0.3-0.28-0.7-0.52-0.85-0.86
	C421.29,276.06,421.76,275.68,422.48,274.82z"
              />
              <path
                d="M340.65,129.02c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C341.36,128.28,340.97,128.58,340.65,129.02z"
              />
              <path
                d="M479.17,289.61c-1.02,0.52-1.39,0.85-1.78,0.86c-0.21,0.01-0.43-0.52-0.65-0.81c0.34-0.24,0.66-0.63,1.03-0.69
	C478.07,288.92,478.45,289.26,479.17,289.61z"
              />
              <path
                d="M296.96,163.47c0.33,0.47,0.72,0.78,0.68,1.03c-0.04,0.26-0.48,0.65-0.75,0.65c-0.25-0.01-0.66-0.44-0.68-0.7
	C296.2,164.19,296.6,163.91,296.96,163.47z"
              />
              <path
                d="M347.89,128.6c-0.42-0.45-0.82-0.72-0.96-1.08c-0.06-0.15,0.52-0.76,0.71-0.72c0.36,0.08,0.77,0.42,0.94,0.76
	C348.66,127.71,348.22,128.13,347.89,128.6z"
              />
              <path
                d="M471.29,290.46c-0.38,0.17-0.75,0.48-1.13,0.47c-0.21-0.01-0.4-0.53-0.6-0.82c0.34-0.22,0.66-0.58,1.03-0.64
	c0.33-0.05,0.72,0.23,1.08,0.36C471.54,290.04,471.42,290.25,471.29,290.46z"
              />
              <path
                d="M258.35,290.72c-0.48,0.31-0.79,0.67-1.06,0.63c-0.27-0.04-0.5-0.46-0.74-0.71c0.27-0.23,0.52-0.63,0.8-0.65
	C257.61,289.98,257.89,290.37,258.35,290.72z"
              />
              <path
                d="M469.24,276.88c-0.4-0.47-0.79-0.76-0.9-1.12c-0.05-0.16,0.55-0.74,0.73-0.69c0.36,0.09,0.74,0.46,0.89,0.8
	C470.04,276.03,469.59,276.41,469.24,276.88z"
              />
              <path
                d="M429.29,274.79c0.36,0.39,0.78,0.63,0.79,0.89c0.02,0.28-0.35,0.58-0.55,0.87c-0.33-0.25-0.73-0.45-0.96-0.78
	C428.52,275.68,428.96,275.24,429.29,274.79z"
              />
              <path
                d="M330.6,291.59c-0.47,0.35-0.75,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C329.8,290.92,330.11,291.28,330.6,291.59z"
              />
              <path
                d="M287.4,169.12c-0.5,0.32-0.83,0.69-1.09,0.64c-0.26-0.05-0.64-0.52-0.62-0.78c0.02-0.25,0.48-0.65,0.75-0.65
	C286.7,168.33,286.97,168.75,287.4,169.12z"
              />
              <path
                d="M302.92,274.76c0.34,0.49,0.7,0.8,0.75,1.15c0.03,0.18-0.57,0.67-0.73,0.61c-0.33-0.13-0.67-0.49-0.78-0.83
	C302.1,275.52,302.55,275.19,302.92,274.76z"
              />
              <path
                d="M302.46,101.57c0.33,0.47,0.71,0.78,0.67,1.03c-0.04,0.27-0.49,0.66-0.74,0.65c-0.25-0.01-0.67-0.44-0.69-0.7
	C301.69,102.29,302.09,102.01,302.46,101.57z"
              />
              <path
                d="M336.69,125.95c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C337.47,125.2,337.06,125.49,336.69,125.95z"
              />
              <path
                d="M329.62,237.78c-0.51,0.29-0.85,0.65-1.11,0.59c-0.26-0.06-0.62-0.54-0.59-0.8c0.03-0.25,0.51-0.62,0.77-0.61
	C328.96,236.97,329.21,237.39,329.62,237.78z"
              />
              <path
                d="M333.48,240.79c-0.41,0.33-0.73,0.78-0.94,0.73c-0.37-0.09-0.67-0.48-1-0.74c0.33-0.26,0.63-0.64,1.01-0.73
	C332.76,239.99,333.08,240.44,333.48,240.79z"
              />
              <path
                d="M312.44,19.21c0.4-0.19,0.8-0.38,1.2-0.57c0.08,0.2,0.25,0.57,0.23,0.58c-0.38,0.21-0.79,0.38-1.2,0.56
	C312.59,19.6,312.52,19.4,312.44,19.21z"
              />
              <path
                d="M477.5,275.14c-0.43,0.46-0.71,0.96-0.91,0.93c-0.39-0.06-0.72-0.42-1.08-0.65c0.22-0.29,0.41-0.8,0.65-0.82
	C476.52,274.56,476.91,274.88,477.5,275.14z"
              />
              <path
                d="M345.16,124.53c-0.35,0.14-0.72,0.43-1.03,0.38c-0.37-0.07-0.68-0.44-1.01-0.67c0.22-0.27,0.43-0.77,0.66-0.77
	c0.35-0.01,0.71,0.31,1.07,0.49C344.94,124.14,345.05,124.33,345.16,124.53z"
              />
              <path
                d="M111.04,632.39c-0.48,0.3-0.79,0.64-1.06,0.61c-0.26-0.03-0.48-0.44-0.72-0.68c0.26-0.22,0.51-0.6,0.78-0.61
	C110.3,631.7,110.58,632.06,111.04,632.39z"
              />
              <path
                d="M352.24,123.89c-0.58,0.25-0.96,0.55-1.31,0.52c-0.23-0.02-0.42-0.53-0.62-0.81c0.35-0.22,0.69-0.57,1.06-0.62
	C351.56,122.95,351.82,123.44,352.24,123.89z"
              />
              <path
                d="M319.07,242.01c-0.48,0.34-0.79,0.73-1.06,0.69c-0.26-0.03-0.67-0.49-0.66-0.75c0.01-0.26,0.45-0.68,0.71-0.69
	C318.34,241.25,318.62,241.65,319.07,242.01z"
              />
              <path
                d="M296.48,103.45c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C296.2,103.02,296.34,103.23,296.48,103.45z"
              />
              <path
                d="M281.88,104.66c-0.49,0.26-0.83,0.59-1.1,0.55c-0.28-0.05-0.5-0.46-0.75-0.71c0.33-0.25,0.63-0.62,0.99-0.7
	C281.19,103.76,281.49,104.25,281.88,104.66z"
              />
              <path
                d="M403.56,313.13c0.42,0.45,0.83,0.72,0.96,1.08c0.06,0.15-0.52,0.76-0.71,0.72c-0.36-0.08-0.76-0.42-0.94-0.76
	C402.79,314.01,403.23,313.6,403.56,313.13z"
              />
              <path
                d="M353.12,106.49c-0.38-0.45-0.76-0.73-0.85-1.08c-0.04-0.17,0.53-0.72,0.7-0.67c0.34,0.1,0.71,0.44,0.86,0.77
	C353.89,105.67,353.46,106.04,353.12,106.49z"
              />
              <path
                d="M282.83,313.61c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C284.24,314.46,283.86,314.13,282.83,313.61z"
              />
              <path
                d="M238.97,274.56c-0.56,0.24-0.94,0.54-1.29,0.51c-0.22-0.02-0.41-0.52-0.61-0.8c0.34-0.22,0.67-0.56,1.04-0.61
	C238.31,273.63,238.57,274.11,238.97,274.56z"
              />
              <path
                d="M300.11,399.12c-0.42,0.32-0.71,0.7-0.99,0.7c-0.28,0-0.56-0.38-0.84-0.59c0.28-0.29,0.52-0.71,0.85-0.82
	C299.33,398.35,299.68,398.8,300.11,399.12z"
              />
              <path
                d="M292.18,398.61c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C291.36,399.33,291.83,398.99,292.18,398.61z"
              />
              <path
                d="M452.01,273.89c-0.44,0.33-0.73,0.7-1,0.7c-0.29-0.01-0.56-0.39-0.85-0.6c0.28-0.29,0.53-0.7,0.87-0.81
	C451.21,273.11,451.57,273.55,452.01,273.89z"
              />
              <path
                d="M253.07,312.79c-0.48,0.31-0.79,0.67-1.06,0.63c-0.27-0.04-0.5-0.46-0.74-0.71c0.27-0.23,0.52-0.63,0.8-0.65
	C252.33,312.05,252.61,312.44,253.07,312.79z"
              />
              <path
                d="M299.92,312.09c-0.41,0.44-0.68,0.91-0.87,0.89c-0.37-0.05-0.69-0.4-1.03-0.62c0.2-0.28,0.4-0.78,0.62-0.79
	C298.98,311.54,299.36,311.84,299.92,312.09z"
              />
              <path
                d="M231.71,401.4c-0.47,0.29-0.82,0.66-1.05,0.6c-0.26-0.07-0.58-0.55-0.56-0.83c0.02-0.25,0.5-0.66,0.73-0.63
	C231.1,400.58,231.33,401,231.71,401.4z"
              />
              <path
                d="M314.92,244.97c0.36,0.51,0.73,0.83,0.79,1.19c0.03,0.19-0.6,0.7-0.77,0.64c-0.34-0.12-0.7-0.51-0.81-0.86
	C314.07,245.76,314.54,245.42,314.92,244.97z"
              />
              <path
                d="M401.08,311.22c-0.36,0.12-0.73,0.34-1.09,0.32c-0.31-0.02-0.61-0.31-0.91-0.48c0.23-0.32,0.4-0.75,0.71-0.92
	c0.16-0.09,0.57,0.29,0.87,0.45C400.81,310.79,400.94,311,401.08,311.22z"
              />
              <path
                d="M434.34,292.29c0.34,0.45,0.73,0.74,0.71,1.01c-0.01,0.28-0.42,0.54-0.66,0.81c-0.26-0.25-0.7-0.48-0.73-0.75
	C433.64,293.08,434.02,292.76,434.34,292.29z"
              />
              <path
                d="M332.71,273.4c-0.41,0.32-0.69,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.58c0.27-0.28,0.51-0.69,0.84-0.8
	C331.94,272.65,332.29,273.08,332.71,273.4z"
              />
              <path
                d="M334.38,247.92c-0.61,0.4-0.95,0.76-1.32,0.8c-0.22,0.03-0.69-0.45-0.7-0.72c-0.01-0.27,0.41-0.79,0.63-0.78
	C333.36,247.24,333.73,247.58,334.38,247.92z"
              />
              <path
                d="M262.17,294.19c-0.48,0.26-0.8,0.58-1.07,0.54c-0.28-0.04-0.5-0.45-0.74-0.69c0.32-0.25,0.61-0.61,0.97-0.7
	C261.5,293.3,261.79,293.79,262.17,294.19z"
              />
              <path
                d="M316.01,159.31c-0.73,0.34-1.09,0.67-1.4,0.62c-0.36-0.05-0.68-0.43-1.01-0.67c0.21-0.28,0.43-0.8,0.64-0.8
	C314.61,158.47,314.98,158.79,316.01,159.31z"
              />
              <path
                d="M291.83,106.11c0.34,0.45,0.73,0.74,0.71,1.01c-0.01,0.28-0.42,0.54-0.66,0.81c-0.26-0.25-0.7-0.48-0.73-0.75
	C291.13,106.9,291.51,106.58,291.83,106.11z"
              />
              <path
                d="M287.75,310.69c-0.3-0.48-0.66-0.8-0.63-1.07c0.04-0.28,0.46-0.51,0.72-0.76c0.24,0.27,0.66,0.53,0.67,0.81
	C288.52,309.94,288.11,310.23,287.75,310.69z"
              />
              <path
                d="M422.51,308.74c-0.4,0.42-0.67,0.89-0.87,0.87c-0.36-0.04-0.68-0.39-1.02-0.61c0.2-0.29,0.38-0.8,0.59-0.81
	C421.58,308.19,421.95,308.49,422.51,308.74z"
              />
              <path
                d="M317.8,154.3c0.35,0.5,0.72,0.81,0.77,1.17c0.03,0.18-0.58,0.69-0.75,0.62c-0.34-0.12-0.68-0.5-0.8-0.85
	C316.96,155.08,317.42,154.74,317.8,154.3z"
              />
              <path
                d="M468.25,293.99c-0.61,0.4-0.95,0.76-1.32,0.8c-0.22,0.03-0.69-0.45-0.7-0.72c-0.01-0.27,0.41-0.79,0.63-0.78
	C467.24,293.3,467.61,293.64,468.25,293.99z"
              />
              <path
                d="M256.23,308.87c-0.47,0.35-0.76,0.74-1.02,0.72c-0.28-0.02-0.53-0.42-0.8-0.65c0.24-0.25,0.47-0.67,0.74-0.71
	C255.42,308.2,255.74,308.56,256.23,308.87z"
              />
              <path
                d="M428.67,309.5c-0.37-0.47-0.76-0.76-0.84-1.12c-0.04-0.19,0.54-0.75,0.69-0.7c0.37,0.12,0.75,0.46,0.93,0.81
	C429.53,308.62,429.05,309.03,428.67,309.5z"
              />
              <path
                d="M301.95,309.23c-0.16-0.3-0.51-0.65-0.44-0.89c0.07-0.27,0.54-0.62,0.79-0.59c0.25,0.03,0.59,0.49,0.62,0.78
	c0.03,0.34-0.23,0.71-0.37,1.07C302.35,309.48,302.15,309.36,301.95,309.23z"
              />
              <path
                d="M315.25,252.24c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C314.61,253.09,314.96,252.74,315.25,252.24z"
              />
              <path
                d="M307.78,307.85c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C309.19,308.7,308.81,308.37,307.78,307.85z"
              />
              <path
                d="M321.45,403.33c0.35,0.47,0.8,0.85,0.73,1.01c-0.15,0.35-0.54,0.71-0.89,0.8c-0.19,0.05-0.78-0.53-0.73-0.69
	C320.66,404.09,321.05,403.8,321.45,403.33z"
              />
              <path
                d="M395.86,306.47c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C395.22,307.31,395.58,306.96,395.86,306.47z"
              />
              <path
                d="M335.17,255.05c-1.01,0.52-1.37,0.83-1.74,0.84c-0.21,0-0.42-0.5-0.63-0.78c0.33-0.24,0.63-0.61,0.99-0.66
	C334.1,254.4,334.46,254.72,335.17,255.05z"
              />
              <path
                d="M286.56,296.56c-0.37-0.46-0.82-0.83-0.76-1c0.14-0.35,0.51-0.72,0.86-0.83c0.18-0.05,0.78,0.49,0.75,0.67
	C287.33,295.77,286.95,296.07,286.56,296.56z"
              />
              <path
                d="M326.47,296.48c-0.36-0.39-0.77-0.63-0.79-0.89c-0.01-0.28,0.36-0.58,0.56-0.87c0.33,0.25,0.66,0.51,0.99,0.76
	C327.02,295.76,326.8,296.04,326.47,296.48z"
              />
              <path
                d="M312.07,258.14c-0.37-0.46-0.75-0.75-0.82-1.1c-0.04-0.19,0.53-0.74,0.68-0.69c0.36,0.12,0.74,0.45,0.91,0.79
	C312.9,257.28,312.44,257.68,312.07,258.14z"
              />
              <path
                d="M472.26,273.05c-0.37-0.46-0.82-0.83-0.76-1c0.14-0.35,0.51-0.72,0.86-0.83c0.18-0.05,0.78,0.49,0.75,0.67
	C473.03,272.26,472.65,272.56,472.26,273.05z"
              />
              <path
                d="M308.03,405.57c-0.44,0.36-0.73,0.77-1,0.76c-0.26-0.01-0.71-0.44-0.71-0.69c-0.01-0.26,0.39-0.72,0.66-0.76
	C307.24,404.85,307.55,405.24,308.03,405.57z"
              />
              <path
                d="M278.66,108.34c-0.6,0.36-0.94,0.69-1.31,0.72c-0.22,0.01-0.65-0.46-0.65-0.72c0-0.26,0.43-0.74,0.65-0.72
	C277.72,107.64,278.06,107.98,278.66,108.34z"
              />
              <path
                d="M431.1,271.86c0.73-0.35,1.1-0.69,1.41-0.64c0.37,0.05,0.69,0.44,1.03,0.68c-0.22,0.29-0.44,0.82-0.65,0.82
	C432.51,272.71,432.13,272.38,431.1,271.86z"
              />
              <path
                d="M307.28,271.96c-0.41,0.32-0.68,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.57c0.27-0.29,0.5-0.69,0.83-0.81
	C306.51,271.21,306.86,271.64,307.28,271.96z"
              />
              <path
                d="M424.77,306.07c-0.35-0.45-0.75-0.75-0.72-1c0.03-0.27,0.47-0.68,0.73-0.68c0.25,0,0.68,0.42,0.71,0.69
	C425.52,305.33,425.12,305.62,424.77,306.07z"
              />
              <path
                d="M250.45,157.76c-0.43,0.32-0.7,0.69-0.97,0.69c-0.28,0-0.55-0.37-0.82-0.58c0.27-0.28,0.5-0.69,0.84-0.81
	C249.67,157,250.02,157.44,250.45,157.76z"
              />
              <path
                d="M258.87,303.9c0.14,0.36,0.4,0.72,0.38,1.06c-0.03,0.29-0.37,0.75-0.61,0.78c-0.25,0.03-0.72-0.32-0.79-0.58
	c-0.06-0.24,0.28-0.59,0.44-0.9C258.48,304.14,258.68,304.02,258.87,303.9z"
              />
              <path
                d="M349.79,108.29c0.39,0.41,0.81,0.65,0.82,0.91c0.01,0.26-0.36,0.74-0.6,0.76c-0.26,0.02-0.73-0.32-0.8-0.59
	C349.16,109.14,349.51,108.79,349.79,108.29z"
              />
              <path
                d="M255.44,175.21c-0.42,0.37-0.68,0.79-0.94,0.79c-0.28-0.01-0.74-0.4-0.76-0.65c-0.02-0.26,0.37-0.72,0.64-0.78
	C254.62,174.52,254.95,174.9,255.44,175.21z"
              />
              <path
                d="M233.72,269.99c0.37,0.42,0.78,0.69,0.78,0.94c0,0.26-0.39,0.72-0.64,0.74c-0.26,0.02-0.72-0.35-0.77-0.61
	C233.04,270.81,233.41,270.48,233.72,269.99z"
              />
              <path
                d="M246.85,201.43c-0.42,0.32-0.71,0.7-0.99,0.7c-0.28,0-0.56-0.38-0.84-0.59c0.28-0.29,0.52-0.71,0.85-0.82
	C246.06,200.66,246.42,201.1,246.85,201.43z"
              />
              <path
                d="M393.15,304.29c-0.42,0.37-0.68,0.79-0.94,0.79c-0.28-0.01-0.74-0.4-0.76-0.66c-0.02-0.26,0.37-0.72,0.64-0.78
	C392.34,303.59,392.67,303.98,393.15,304.29z"
              />
              <path
                d="M448.07,270.47c-0.6,0.39-0.93,0.75-1.29,0.79c-0.22,0.02-0.68-0.44-0.69-0.7c-0.01-0.26,0.4-0.78,0.62-0.77
	C447.08,269.81,447.44,270.14,448.07,270.47z"
              />
              <path
                d="M454.07,271.09c-0.37-0.44-0.82-0.77-0.77-0.94c0.11-0.34,0.46-0.7,0.79-0.83c0.16-0.06,0.75,0.43,0.73,0.62
	C454.77,270.29,454.42,270.6,454.07,271.09z"
              />
              <path
                d="M242.64,204.27c0.36,0.38,0.83,0.72,0.78,0.86c-0.12,0.34-0.5,0.59-0.77,0.87c-0.25-0.24-0.66-0.47-0.69-0.74
	C241.93,205.01,242.31,204.71,242.64,204.27z"
              />
              <path
                d="M348.36,120.51c-0.5,0.25-0.83,0.58-1.1,0.53c-0.29-0.06-0.52-0.46-0.77-0.71c0.33-0.25,0.64-0.61,1.01-0.71
	C347.66,119.59,347.96,120.09,348.36,120.51z"
              />
              <path
                d="M274.43,111.18c0.32,0.44,0.7,0.73,0.68,0.98c-0.02,0.27-0.44,0.5-0.68,0.74c-0.27-0.28-0.65-0.53-0.78-0.86
	C273.6,111.91,274.07,111.57,274.43,111.18z"
              />
              <path
                d="M334.89,268.49c0.35,0.47,0.8,0.85,0.73,1.01c-0.15,0.35-0.54,0.71-0.89,0.8c-0.19,0.05-0.78-0.53-0.73-0.69
	C334.1,269.25,334.49,268.96,334.89,268.49z"
              />
              <path
                d="M335.4,262.43c-0.42,0.31-0.76,0.74-0.97,0.68c-0.36-0.1-0.64-0.5-0.96-0.77c0.34-0.25,0.65-0.62,1.03-0.7
	C334.7,261.59,335.01,262.06,335.4,262.43z"
              />
              <path
                d="M242,436.51c-0.38,0.3-0.65,0.65-0.8,0.61c-0.29-0.09-0.55-0.37-0.71-0.65c-0.04-0.08,0.36-0.58,0.51-0.56
	C241.3,435.96,241.57,436.23,242,436.51z"
              />
              <path
                d="M291.64,172.11c-0.6,0.39-0.93,0.75-1.29,0.79c-0.22,0.02-0.68-0.44-0.69-0.7c-0.01-0.26,0.4-0.78,0.62-0.77
	C290.65,171.44,291.01,171.77,291.64,172.11z"
              />
              <path d="M136.38,633.77c-0.67,0.05-1.13,0.08-1.61,0.11c0-0.31-0.06-0.52,0.01-0.61C135.45,632.46,135.87,632.78,136.38,633.77z" />
              <path
                d="M324.07,299.47c-0.48,0.26-0.8,0.58-1.07,0.53c-0.28-0.05-0.49-0.45-0.74-0.69c0.32-0.25,0.61-0.6,0.97-0.69
	C323.4,298.58,323.69,299.07,324.07,299.47z"
              />
              <path
                d="M240.23,209.34c-0.36,0.18-0.72,0.49-1.07,0.48c-0.23-0.01-0.43-0.5-0.65-0.78c0.34-0.24,0.65-0.6,1.02-0.67
	c0.33-0.06,0.72,0.21,1.08,0.33C240.48,208.92,240.35,209.13,240.23,209.34z"
              />
              <path
                d="M271.07,117.02c-0.37-0.39-0.86-0.75-0.8-0.88c0.14-0.35,0.52-0.59,0.81-0.88c0.25,0.25,0.66,0.49,0.69,0.77
	C271.79,116.28,271.4,116.58,271.07,117.02z"
              />
              <path
                d="M351.42,116.49c-0.41,0.32-0.69,0.69-0.96,0.69c-0.27,0-0.55-0.37-0.82-0.57c0.27-0.29,0.5-0.69,0.83-0.81
	C350.65,115.74,351.01,116.17,351.42,116.49z"
              />
              <path
                d="M262.94,301.4c-0.53,0.26-0.88,0.57-1.15,0.51c-0.26-0.06-0.44-0.5-0.65-0.78c0.28-0.21,0.57-0.59,0.85-0.58
	C262.26,300.56,262.5,300.98,262.94,301.4z"
              />
              <path
                d="M297.46,117.62c-0.55,0.24-0.92,0.54-1.16,0.46c-0.23-0.07-0.43-0.53-0.46-0.84c-0.01-0.14,0.47-0.49,0.63-0.44
	C296.77,116.89,297,117.21,297.46,117.62z"
              />
              <path
                d="M344.19,116.92c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C343.41,116.35,343.69,116.63,344.19,116.92z"
              />
              <path
                d="M428.94,301.55c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C428.36,300.72,428.54,301.08,428.94,301.55z"
              />
              <path
                d="M143.33,633.28c-0.49,0.24-0.77,0.5-1,0.46c-0.18-0.03-0.36-0.42-0.39-0.67c-0.02-0.11,0.36-0.4,0.5-0.37
	C142.69,632.74,142.9,632.98,143.33,633.28z"
              />
              <path
                d="M254.41,302.47c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.04,0.32-0.25,0.68-0.39,1.02C254.79,302.68,254.6,302.58,254.41,302.47z"
              />
              <path
                d="M396.3,300.22c-0.48,0.35-0.73,0.65-1.04,0.71c-0.17,0.04-0.62-0.36-0.6-0.5c0.06-0.31,0.3-0.76,0.53-0.8
	C395.46,299.59,395.79,299.93,396.3,300.22z"
              />
              <path
                d="M295.26,301.91c-0.48,0.36-0.79,0.77-1.02,0.72c-0.27-0.05-0.55-0.46-0.66-0.78c-0.04-0.11,0.39-0.57,0.59-0.56
	C294.46,301.32,294.75,301.61,295.26,301.91z"
              />
              <path
                d="M279.25,115.42c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C278.34,114.82,278.7,115.15,279.25,115.42z"
              />
              <path
                d="M330.01,266.11c0.51-0.27,0.8-0.54,1.1-0.55c0.2,0,0.61,0.47,0.56,0.57c-0.13,0.3-0.42,0.68-0.69,0.72
	C330.75,266.89,330.47,266.48,330.01,266.11z"
              />
              <path
                d="M269.58,140.44c0.33,0.5,0.71,0.84,0.66,1.08c-0.05,0.25-0.5,0.51-0.82,0.58c-0.13,0.03-0.56-0.42-0.53-0.6
	C268.95,141.18,269.25,140.92,269.58,140.44z"
              />
              <path
                d="M286.44,114.83c-0.5,0.33-0.83,0.72-1.08,0.67c-0.26-0.05-0.52-0.49-0.61-0.8c-0.04-0.13,0.41-0.57,0.6-0.54
	C285.66,114.2,285.94,114.49,286.44,114.83z"
              />
              <path
                d="M229.85,266.41c0.51,0.98,0.83,1.32,0.77,1.57c-0.06,0.26-0.49,0.43-0.76,0.64c-0.21-0.27-0.58-0.54-0.6-0.83
	C229.24,267.47,229.53,267.13,229.85,266.41z"
              />
              <path
                d="M290.26,118.06c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C289.41,117.44,289.76,117.79,290.26,118.06z"
              />
              <path
                d="M283.05,118.68c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C282.33,117.93,282.61,118.33,283.05,118.68z"
              />
              <path
                d="M310.44,268.22c-0.54,0.28-0.85,0.54-1.16,0.55c-0.19,0.01-0.6-0.46-0.55-0.58c0.12-0.3,0.42-0.72,0.67-0.74
	C309.66,267.44,309.95,267.84,310.44,268.22z"
              />
              <path
                d="M292.57,113.34c0.35,0.44,0.74,0.72,0.73,0.98c-0.02,0.27-0.43,0.52-0.67,0.78c-0.24-0.24-0.66-0.47-0.69-0.74
	C291.91,114.1,292.27,113.79,292.57,113.34z"
              />
              <path
                d="M226.18,263.61c0.26,0.45,0.55,0.74,0.57,1.05c0.01,0.18-0.4,0.39-0.63,0.59c-0.23-0.28-0.55-0.53-0.65-0.84
	C225.43,264.27,225.83,263.99,226.18,263.61z"
              />
              <path
                d="M300.79,113.71c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C300.05,112.96,300.33,113.34,300.79,113.71z"
              />
              <path
                d="M347.58,113.08c-0.48,0.36-0.78,0.75-1.02,0.72c-0.27-0.04-0.55-0.44-0.67-0.75c-0.04-0.1,0.39-0.57,0.59-0.56
	C346.77,112.5,347.06,112.78,347.58,113.08z"
              />
              <path
                d="M102.94,633.76c-0.38-0.43-0.7-0.65-0.78-0.94c-0.04-0.15,0.42-0.63,0.51-0.6c0.3,0.11,0.61,0.35,0.74,0.63
	C103.48,632.99,103.18,633.31,102.94,633.76z"
              />
              <path
                d="M423.94,296.89c0.4,0.46,0.82,0.73,0.8,0.96c-0.02,0.25-0.42,0.54-0.72,0.66c-0.11,0.04-0.55-0.33-0.56-0.52
	C423.46,297.71,423.7,297.41,423.94,296.89z"
              />
              <path
                d="M258.8,297.98c-0.52,0.27-0.83,0.54-1.13,0.54c-0.2,0-0.62-0.48-0.58-0.57c0.14-0.31,0.43-0.7,0.7-0.74
	C258.04,297.18,258.33,297.59,258.8,297.98z"
              />
              <path
                d="M274.56,120.13c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.05,0.32-0.23,0.69-0.37,1.04C274.96,120.36,274.76,120.24,274.56,120.13z"
              />
              <path
                d="M312.62,304.9c-0.39-0.43-0.81-0.68-0.81-0.94c0-0.27,0.39-0.54,0.61-0.82c0.26,0.22,0.69,0.41,0.74,0.68
	C313.21,304.08,312.88,304.41,312.62,304.9z"
              />
              <path
                d="M400.31,303.8c-0.52,0.23-0.83,0.47-1.11,0.45c-0.2-0.02-0.57-0.5-0.53-0.58c0.16-0.28,0.45-0.64,0.73-0.67
	C399.61,302.97,399.87,303.39,400.31,303.8z"
              />
              <path
                d="M282.61,111.58c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C281.7,110.99,282.06,111.31,282.61,111.58z"
              />
              <path
                d="M431.4,298.17c-0.38-0.41-0.79-0.66-0.79-0.91c0-0.28,0.37-0.55,0.58-0.83c0.28,0.21,0.72,0.39,0.78,0.65
	C432.03,297.33,431.68,297.67,431.4,298.17z"
              />
              <path
                d="M391.38,298.18c-0.28-0.46-0.63-0.78-0.59-1.04c0.04-0.27,0.45-0.48,0.71-0.72c0.23,0.26,0.64,0.52,0.65,0.79
	C392.15,297.46,391.75,297.74,391.38,298.18z"
              />
              <path
                d="M231.4,409.2c-0.18-0.38-0.37-0.62-0.39-0.88c-0.01-0.12,0.32-0.26,0.5-0.39c0.16,0.22,0.37,0.43,0.44,0.67
	C231.97,408.71,231.68,408.9,231.4,409.2z"
              />
              <path
                d="M301.28,120.99c-0.55,0.23-0.91,0.52-1.15,0.44c-0.22-0.08-0.41-0.53-0.43-0.83c-0.01-0.13,0.47-0.47,0.63-0.42
	C300.61,120.26,300.83,120.58,301.28,120.99z"
              />
              <path
                d="M289.51,111.08c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C288.8,110.37,289.04,110.7,289.51,111.08z"
              />
              <path
                d="M341.14,121.11c-0.53,0.31-0.88,0.67-1.11,0.61c-0.26-0.07-0.5-0.51-0.57-0.83c-0.03-0.13,0.44-0.55,0.63-0.51
	C340.4,120.44,340.66,120.76,341.14,121.11z"
              />
              <path
                d="M305.6,305.56c-0.33-0.46-0.63-0.71-0.68-1c-0.03-0.18,0.37-0.63,0.48-0.6c0.31,0.08,0.73,0.3,0.8,0.55
	C306.26,304.74,305.9,305.07,305.6,305.56z"
              />
              <path
                d="M296.72,110.43c-0.55,0.24-0.87,0.49-1.17,0.46c-0.21-0.02-0.6-0.52-0.55-0.6c0.17-0.3,0.47-0.67,0.76-0.7
	C295.98,109.57,296.25,110.01,296.72,110.43z"
              />
              <path
                d="M303.62,110.06c-0.3,0.12-0.61,0.35-0.89,0.32c-0.2-0.02-0.57-0.51-0.52-0.59c0.17-0.28,0.46-0.62,0.75-0.66
	c0.3-0.04,0.64,0.24,0.97,0.38C303.83,109.7,303.73,109.88,303.62,110.06z"
              />
              <path
                d="M300.81,406.21c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C299.93,405.62,300.28,405.94,300.81,406.21z"
              />
              <path
                d="M294.13,121.18c-0.48,0.39-0.73,0.71-1.04,0.79c-0.18,0.04-0.66-0.34-0.64-0.47c0.05-0.31,0.27-0.78,0.51-0.84
	C293.21,120.58,293.57,120.91,294.13,121.18z"
              />
              <path
                d="M291.29,306.81c-0.38-0.41-0.79-0.66-0.79-0.91c0-0.28,0.37-0.55,0.58-0.83c0.28,0.21,0.72,0.39,0.78,0.65
	C291.91,305.97,291.57,306.31,291.29,306.81z"
              />
              <path
                d="M417.43,306.56c-0.26-0.55-0.6-0.93-0.51-1.14c0.11-0.26,0.55-0.45,0.88-0.5c0.13-0.02,0.5,0.48,0.45,0.67
	C418.18,305.87,417.84,306.1,417.43,306.56z"
              />
              <path
                d="M411.39,306.38c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C410.68,305.67,410.92,306,411.39,306.38z"
              />
              <path
                d="M226.32,257.39c-0.33,0.29-0.55,0.48-0.77,0.67c-0.19-0.29-0.46-0.56-0.53-0.88c-0.03-0.12,0.42-0.51,0.61-0.48
	C225.85,256.73,226.03,257.08,226.32,257.39z"
              />
              <path
                d="M404.17,306.93c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C403.39,306.36,403.67,306.64,404.17,306.93z"
              />
              <path
                d="M245.59,445.68c0.48,0.93,0.73,1.19,0.69,1.41c-0.03,0.23-0.34,0.41-0.52,0.62c-0.2-0.26-0.51-0.5-0.58-0.8
	C245.13,446.67,245.35,446.36,245.59,445.68z"
              />
              <path
                d="M324.4,106.88c0.36,0.45,0.67,0.69,0.74,0.97c0.04,0.18-0.33,0.64-0.45,0.62c-0.31-0.06-0.74-0.26-0.82-0.51
	C323.79,107.75,324.12,107.4,324.4,106.88z"
              />
              <path
                d="M284.92,122.01c0.44-0.33,0.73-0.73,0.98-0.7c0.27,0.03,0.68,0.46,0.68,0.72c-0.01,0.26-0.42,0.67-0.7,0.7
	C285.64,122.76,285.35,122.36,284.92,122.01z"
              />
              <path
                d="M285.67,107.72c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C284.96,107.01,285.2,107.34,285.67,107.72z"
              />
              <path
                d="M254.94,294.46c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C254.16,293.89,254.44,294.17,254.94,294.46z"
              />
              <path
                d="M310.55,157.1c-0.32-0.46-0.7-0.77-0.67-1.03c0.03-0.27,0.46-0.49,0.71-0.73c0.23,0.26,0.63,0.51,0.65,0.78
	C311.26,156.38,310.88,156.66,310.55,157.1z"
              />
              <path
                d="M296.01,309.17c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C295.28,308.43,295.57,308.83,296.01,309.17z"
              />
              <path
                d="M428.44,294.34c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C427.88,293.54,428.05,293.89,428.44,294.34z"
              />
              <path
                d="M289.78,403.76c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C289.2,402.93,289.38,403.28,289.78,403.76z"
              />
              <path
                d="M295.82,401.98c0.4,0.46,0.82,0.73,0.8,0.96c-0.02,0.25-0.42,0.54-0.72,0.66c-0.11,0.04-0.55-0.33-0.56-0.52
	C295.34,402.79,295.58,402.49,295.82,401.98z"
              />
              <path
                d="M243.86,180.58c-0.12-0.3-0.35-0.61-0.33-0.9c0.02-0.19,0.48-0.55,0.58-0.5c0.29,0.15,0.66,0.43,0.69,0.71
	c0.04,0.32-0.23,0.68-0.36,1.02C244.24,180.8,244.05,180.69,243.86,180.58z"
              />
              <path
                d="M299.15,107.42c-0.39-0.48-0.81-0.78-0.78-1.01c0.04-0.26,0.46-0.55,0.78-0.66c0.12-0.04,0.58,0.37,0.57,0.56
	C299.7,106.62,299.43,106.92,299.15,107.42z"
              />
              <path
                d="M278.55,121.73c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C278.05,122.56,278.3,122.26,278.55,121.73z"
              />
              <path
                d="M395.82,293.2c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C394.98,292.58,395.32,292.93,395.82,293.2z"
              />
              <path
                d="M299.92,159.65c0.37,0.46,0.69,0.69,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C299.3,160.53,299.64,160.17,299.92,159.65z"
              />
              <path
                d="M346.86,106.25c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C346.3,105.44,346.47,105.79,346.86,106.25z"
              />
              <path
                d="M307.22,105.98c-0.47,0.29-0.75,0.57-1.04,0.59c-0.19,0.01-0.61-0.42-0.57-0.53c0.11-0.3,0.37-0.69,0.63-0.74
	C306.46,105.26,306.76,105.64,307.22,105.98z"
              />
              <path
                d="M330.06,244.77c-0.53,0.24-0.89,0.53-1.13,0.46c-0.22-0.07-0.42-0.52-0.45-0.82c-0.01-0.14,0.46-0.49,0.62-0.44
	C329.39,244.06,329.61,244.37,330.06,244.77z"
              />
              <path
                d="M294.07,161.21c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C293.34,160.46,293.61,160.85,294.07,161.21z"
              />
              <path
                d="M328.63,104.33c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C328.06,103.52,328.24,103.87,328.63,104.33z"
              />
              <path
                d="M288.12,102.79c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C287.33,103.47,287.74,103.21,288.12,102.79z"
              />
              <path
                d="M272.72,123.1c-0.47,0.39-0.76,0.8-1,0.77c-0.28-0.04-0.57-0.42-0.72-0.72c-0.04-0.09,0.37-0.58,0.57-0.58
	C271.88,122.56,272.19,122.83,272.72,123.1z"
              />
              <path
                d="M297.94,124.54c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C297.05,123.96,297.4,124.28,297.94,124.54z"
              />
              <path
                d="M343.06,102.93c-0.55,0.28-0.91,0.62-1.15,0.55c-0.25-0.07-0.47-0.54-0.51-0.86c-0.02-0.14,0.47-0.52,0.65-0.48
	C342.36,102.22,342.6,102.55,343.06,102.93z"
              />
              <path
                d="M291.1,125.72c-0.35,0.13-0.72,0.39-1.05,0.36c-0.3-0.03-0.56-0.38-0.84-0.59c0.21-0.28,0.38-0.72,0.64-0.79
	c0.24-0.06,0.58,0.28,0.88,0.44C290.85,125.34,290.97,125.53,291.1,125.72z"
              />
              <path
                d="M283.72,125.82c-0.42,0.35-0.7,0.76-0.94,0.74c-0.27-0.02-0.71-0.42-0.72-0.67c-0.01-0.25,0.38-0.69,0.65-0.73
	C282.95,125.12,283.26,125.5,283.72,125.82z"
              />
              <path
                d="M275.45,125.58c0.37,0.46,0.69,0.69,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C274.83,126.46,275.17,126.1,275.45,125.58z"
              />
              <path
                d="M349.3,101.09c0.3,0.52,0.66,0.88,0.59,1.11c-0.08,0.26-0.53,0.48-0.85,0.54c-0.14,0.02-0.54-0.45-0.5-0.63
	C348.61,101.81,348.93,101.56,349.3,101.09z"
              />
              <path
                d="M251.27,291.74c-0.35,0.13-0.72,0.39-1.05,0.36c-0.3-0.03-0.56-0.38-0.84-0.59c0.21-0.28,0.38-0.72,0.64-0.79
	c0.24-0.06,0.58,0.28,0.88,0.44C251.02,291.36,251.15,291.55,251.27,291.74z"
              />
              <path
                d="M310.41,101.85c-0.44,0.35-0.72,0.74-0.98,0.73c-0.26-0.01-0.68-0.43-0.69-0.68c0-0.26,0.39-0.69,0.65-0.73
	C309.64,101.14,309.95,101.52,310.41,101.85z"
              />
              <path
                d="M268.51,126.3c0.24,0.54,0.49,0.86,0.48,1.17c-0.01,0.2-0.52,0.58-0.63,0.53c-0.3-0.16-0.67-0.48-0.69-0.76
	C267.66,127.01,268.1,126.74,268.51,126.3z"
              />
              <path
                d="M322.81,100.57c0.51-0.27,0.8-0.54,1.1-0.55c0.2,0,0.61,0.47,0.56,0.57c-0.13,0.3-0.42,0.68-0.69,0.72
	C323.56,101.34,323.27,100.94,322.81,100.57z"
              />
              <path
                d="M253.5,153.96c-0.49,0.3-0.77,0.58-1.06,0.6c-0.19,0.01-0.63-0.43-0.59-0.53c0.11-0.3,0.38-0.71,0.64-0.75
	C252.72,153.24,253.03,153.62,253.5,153.96z"
              />
              <path
                d="M431.52,290.36c-0.54,0.23-0.85,0.48-1.14,0.45c-0.21-0.02-0.59-0.51-0.54-0.59c0.17-0.29,0.46-0.65,0.74-0.68
	C430.81,289.52,431.08,289.95,431.52,290.36z"
              />
              <path
                d="M330.92,100.96c-0.28-0.46-0.63-0.78-0.59-1.04c0.04-0.27,0.45-0.48,0.71-0.72c0.23,0.26,0.64,0.52,0.65,0.79
	C331.69,100.25,331.29,100.52,330.92,100.96z"
              />
              <path
                d="M301.95,128.34c-0.6,0.14-0.95,0.32-1.24,0.26c-0.19-0.04-0.46-0.59-0.39-0.67c0.2-0.26,0.56-0.56,0.83-0.54
	C301.37,127.41,301.56,127.86,301.95,128.34z"
              />
              <path
                d="M339.2,99.35c-0.41,0.38-0.65,0.79-0.91,0.8c-0.26,0.02-0.74-0.35-0.76-0.59c-0.02-0.26,0.32-0.72,0.58-0.78
	C338.36,98.73,338.7,99.07,339.2,99.35z"
              />
              <path
                d="M293.87,129.5c-0.39-0.48-0.81-0.78-0.78-1.01c0.04-0.26,0.46-0.55,0.78-0.66c0.12-0.04,0.58,0.37,0.57,0.56
	C294.43,128.7,294.15,128.99,293.87,129.5z"
              />
              <path
                d="M287.56,129.14c-0.41,0.37-0.67,0.78-0.91,0.78c-0.27-0.01-0.73-0.38-0.76-0.63c-0.02-0.25,0.35-0.7,0.62-0.76
	C286.75,128.48,287.08,128.84,287.56,129.14z"
              />
              <path
                d="M345.1,99.5c-0.13-0.31-0.36-0.62-0.34-0.91c0.02-0.2,0.49-0.56,0.59-0.51c0.3,0.15,0.67,0.44,0.71,0.72
	c0.04,0.32-0.25,0.68-0.39,1.02C345.48,99.71,345.29,99.6,345.1,99.5z"
              />
              <path
                d="M398.89,289.27c-0.49,0.3-0.77,0.58-1.06,0.6c-0.19,0.01-0.63-0.43-0.59-0.53c0.11-0.3,0.38-0.71,0.64-0.75
	C398.11,288.55,398.42,288.93,398.89,289.27z"
              />
              <path
                d="M326.46,128.47c0.28,0.48,0.55,0.76,0.58,1.05c0.02,0.18-0.41,0.59-0.52,0.56c-0.3-0.1-0.73-0.36-0.76-0.6
	C325.72,129.25,326.11,128.95,326.46,128.47z"
              />
              <path
                d="M280.4,129.87c-0.48,0.36-0.78,0.75-1.02,0.72c-0.27-0.04-0.55-0.44-0.67-0.75c-0.04-0.1,0.39-0.57,0.59-0.56
	C279.59,129.29,279.89,129.58,280.4,129.87z"
              />
              <path
                d="M273.17,130.35c-0.47,0.35-0.77,0.74-1,0.71c-0.26-0.04-0.54-0.44-0.66-0.74c-0.04-0.1,0.38-0.56,0.58-0.55
	C272.39,129.78,272.67,130.06,273.17,130.35z"
              />
              <path
                d="M290.72,165.27c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C290.15,164.46,290.33,164.81,290.72,165.27z"
              />
              <path
                d="M265.09,130.14c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C264.3,130.82,264.71,130.56,265.09,130.14z"
              />
              <path
                d="M267.87,152.65c-0.52,0.23-0.83,0.47-1.11,0.45c-0.2-0.02-0.57-0.5-0.53-0.58c0.16-0.28,0.45-0.64,0.73-0.67
	C267.17,151.82,267.43,152.24,267.87,152.65z"
              />
              <path
                d="M682.45,581.89c0,0.49,0,0.98,0,1.47c-0.12,0.02-0.24,0.03-0.37,0.05c-0.08-0.5-0.16-0.99-0.23-1.49
	C682.05,581.91,682.25,581.9,682.45,581.89z"
              />
              <path
                d="M297.48,132.84c-0.26-0.54-0.58-0.91-0.5-1.12c0.1-0.25,0.54-0.44,0.86-0.5c0.12-0.02,0.48,0.47,0.43,0.65
	C298.2,132.17,297.87,132.39,297.48,132.84z"
              />
              <path
                d="M329.32,277.43c-0.55,0.22-0.87,0.46-1.16,0.43c-0.21-0.02-0.58-0.52-0.53-0.6c0.18-0.29,0.48-0.64,0.76-0.67
	C328.61,276.58,328.87,277.01,329.32,277.43z"
              />
              <path
                d="M326.71,288.45c-0.55,0.23-0.91,0.52-1.15,0.44c-0.22-0.08-0.41-0.53-0.43-0.83c-0.01-0.13,0.47-0.47,0.63-0.42
	C326.04,287.73,326.26,288.04,326.71,288.45z"
              />
              <path
                d="M322.13,230.78c-0.99,0.56-1.33,0.91-1.57,0.85c-0.27-0.07-0.44-0.51-0.65-0.78c0.28-0.21,0.54-0.57,0.83-0.6
	C321.08,230.22,321.43,230.49,322.13,230.78z"
              />
              <path
                d="M246.37,287.04c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C245.58,287.73,245.99,287.47,246.37,287.04z"
              />
              <path
                d="M349.76,95.22c-0.63,0.17-1.06,0.42-1.29,0.3c-0.23-0.12-0.32-0.63-0.31-0.96c0-0.12,0.56-0.37,0.74-0.29
	C349.18,94.39,349.36,94.75,349.76,95.22z"
              />
              <path
                d="M314.92,152.19c-0.55,0.22-0.87,0.46-1.16,0.43c-0.21-0.02-0.58-0.52-0.53-0.6c0.18-0.29,0.48-0.64,0.76-0.67
	C314.22,151.34,314.48,151.77,314.92,152.19z"
              />
              <path
                d="M275.06,152.1c-0.49,0.26-0.78,0.52-1.07,0.52c-0.2,0-0.59-0.46-0.55-0.55c0.13-0.3,0.41-0.67,0.67-0.71
	C274.33,151.33,274.62,151.73,275.06,152.1z"
              />
              <path
                d="M291.4,132.65c-0.47,0.3-0.8,0.67-1.04,0.62c-0.27-0.06-0.65-0.5-0.63-0.76c0.01-0.24,0.47-0.62,0.74-0.63
	C290.73,131.88,290.99,132.29,291.4,132.65z"
              />
              <path
                d="M284.21,132.98c-0.41,0.37-0.67,0.78-0.91,0.78c-0.27-0.01-0.73-0.38-0.76-0.63c-0.02-0.25,0.35-0.7,0.62-0.76
	C283.39,132.31,283.73,132.68,284.21,132.98z"
              />
              <path
                d="M322.96,132.31c0.36,0.45,0.67,0.69,0.74,0.97c0.04,0.18-0.33,0.64-0.45,0.62c-0.31-0.06-0.74-0.26-0.82-0.51
	C322.35,133.18,322.68,132.83,322.96,132.31z"
              />
              <path
                d="M277.04,133.94c-0.55,0.24-0.87,0.49-1.17,0.46c-0.21-0.02-0.6-0.52-0.55-0.6c0.17-0.3,0.47-0.67,0.76-0.7
	C276.31,133.09,276.58,133.52,277.04,133.94z"
              />
              <path
                d="M270.1,134.14c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C269.22,133.55,269.57,133.87,270.1,134.14z"
              />
              <path
                d="M211.98,630.7c-0.45,0.29-0.72,0.58-1.02,0.62c-0.16,0.02-0.58-0.47-0.54-0.56c0.15-0.28,0.41-0.58,0.7-0.68
	C211.28,630.03,211.57,630.38,211.98,630.7z"
              />
              <path
                d="M433.99,285.12c0.27,0.47,0.61,0.8,0.57,1.06c-0.04,0.26-0.47,0.46-0.73,0.69c-0.23-0.27-0.62-0.53-0.63-0.81
	C433.2,285.81,433.61,285.55,433.99,285.12z"
              />
              <path
                d="M262.93,135.08c-0.55,0.28-0.91,0.62-1.15,0.55c-0.25-0.07-0.47-0.54-0.51-0.86c-0.02-0.14,0.47-0.52,0.66-0.48
	C262.23,134.37,262.47,134.7,262.93,135.08z"
              />
              <path
                d="M234.4,277.2c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C233.91,278.03,234.15,277.73,234.4,277.2z"
              />
              <path
                d="M256.87,149.93c-0.52,0.29-0.88,0.65-1.1,0.58c-0.27-0.09-0.5-0.52-0.57-0.84c-0.03-0.13,0.46-0.54,0.66-0.5
	C256.16,149.23,256.4,149.56,256.87,149.93z"
              />
              <path d="M667.49,634.07c-0.85,0-1.39,0-2.08,0C666.35,633.44,666.35,633.44,667.49,634.07z" />
              <path
                d="M233.38,336.15c-0.47,0.39-0.76,0.8-1,0.77c-0.28-0.04-0.57-0.42-0.72-0.72c-0.04-0.09,0.37-0.58,0.57-0.58
	C232.54,335.61,232.84,335.88,233.38,336.15z"
              />
              <path
                d="M226.1,337.07c-0.59,0.14-0.92,0.32-1.21,0.25c-0.18-0.04-0.45-0.57-0.38-0.66c0.19-0.25,0.55-0.55,0.82-0.53
	C225.55,336.15,225.73,336.6,226.1,337.07z"
              />
              <path
                d="M295.25,135.86c-0.54,0.23-0.85,0.48-1.14,0.45c-0.21-0.02-0.59-0.51-0.54-0.59c0.17-0.29,0.46-0.65,0.74-0.68
	C294.53,135.02,294.8,135.44,295.25,135.86z"
              />
              <path
                d="M263.15,148.37c0.28,0.51,0.56,0.8,0.58,1.11c0.01,0.19-0.46,0.61-0.57,0.57c-0.31-0.12-0.72-0.41-0.75-0.67
	C262.37,149.14,262.77,148.84,263.15,148.37z"
              />
              <path
                d="M287.42,135.42c0.29,0.48,0.64,0.81,0.6,1.06c-0.05,0.26-0.49,0.46-0.76,0.68c-0.21-0.27-0.59-0.54-0.59-0.82
	C286.66,136.09,287.06,135.83,287.42,135.42z"
              />
              <path
                d="M270.57,147.88c0.12,0.31,0.35,0.63,0.32,0.93c-0.02,0.2-0.5,0.55-0.6,0.5c-0.29-0.15-0.66-0.45-0.69-0.72
	c-0.04-0.32,0.24-0.68,0.39-1.03C270.18,147.67,270.37,147.78,270.57,147.88z"
              />
              <path
                d="M409.46,284.73c-0.51,0.31-0.86,0.68-1.08,0.61c-0.27-0.08-0.52-0.5-0.6-0.82c-0.03-0.12,0.45-0.55,0.65-0.52
	C408.73,284.05,408.98,284.38,409.46,284.73z"
              />
              <path
                d="M328.06,284.35c0.52-0.28,0.82-0.55,1.13-0.56c0.2,0,0.62,0.48,0.57,0.58c-0.14,0.3-0.43,0.7-0.7,0.73
	C328.83,285.13,328.53,284.72,328.06,284.35z"
              />
              <path
                d="M318.12,148.33c-0.57,0.24-0.94,0.55-1.19,0.47c-0.24-0.07-0.44-0.54-0.47-0.86c-0.01-0.14,0.48-0.5,0.65-0.45
	C317.41,147.58,317.64,147.91,318.12,148.33z"
              />
              <path
                d="M434.2,278.94c-0.45,0.33-0.75,0.72-1.01,0.69c-0.26-0.02-0.66-0.47-0.66-0.72c0.01-0.26,0.42-0.68,0.68-0.7
	C433.48,278.2,433.76,278.6,434.2,278.94z"
              />
              <path
                d="M278.45,148.27c-0.51,0.26-0.8,0.53-1.1,0.53c-0.2,0-0.61-0.47-0.56-0.56c0.14-0.3,0.42-0.69,0.69-0.73
	C277.7,147.48,277.99,147.89,278.45,148.27z"
              />
              <path
                d="M307.7,279.05c-0.45,0.37-0.73,0.78-0.94,0.74c-0.27-0.04-0.55-0.41-0.69-0.7c-0.04-0.09,0.35-0.56,0.55-0.56
	C306.9,278.53,307.19,278.79,307.7,279.05z"
              />
              <path
                d="M222.79,340.9c-0.51,0.29-0.87,0.64-1.08,0.57c-0.27-0.09-0.5-0.51-0.57-0.82c-0.03-0.12,0.46-0.53,0.65-0.49
	C222.09,340.21,222.33,340.54,222.79,340.9z"
              />
              <path
                d="M281.14,136.88c-0.44,0.35-0.72,0.74-0.98,0.73c-0.26-0.01-0.68-0.43-0.69-0.68c0-0.26,0.39-0.69,0.65-0.73
	C280.37,136.16,280.68,136.55,281.14,136.88z"
              />
              <path
                d="M284.55,148.46c-0.37-0.46-0.69-0.7-0.76-1c-0.04-0.19,0.34-0.67,0.46-0.65c0.32,0.06,0.79,0.27,0.87,0.51
	C285.19,147.57,284.84,147.93,284.55,148.46z"
              />
              <path
                d="M292.63,146.82c-0.5,0.26-0.79,0.52-1.1,0.54c-0.17,0.01-0.56-0.44-0.52-0.56c0.11-0.29,0.4-0.7,0.63-0.71
	C291.9,146.07,292.17,146.45,292.63,146.82z"
              />
              <path
                d="M319.78,138.1c-0.37-0.46-0.78-0.75-0.75-0.98c0.04-0.25,0.45-0.52,0.75-0.63c0.11-0.04,0.54,0.36,0.53,0.55
	C320.3,137.32,320.04,137.61,319.78,138.1z"
              />
              <path
                d="M263.42,142.07c-0.47,0.39-0.71,0.71-1.02,0.79c-0.18,0.05-0.67-0.34-0.65-0.48c0.04-0.31,0.27-0.77,0.51-0.85
	C262.5,141.46,262.86,141.79,263.42,142.07z"
              />
              <path
                d="M429.96,282.01c0.37,0.46,0.69,0.7,0.76,0.99c0.04,0.18-0.34,0.65-0.46,0.63c-0.32-0.06-0.76-0.26-0.84-0.51
	C429.34,282.89,429.68,282.53,429.96,282.01z"
              />
              <path
                d="M303.5,282c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C303,282.83,303.25,282.53,303.5,282z"
              />
              <path
                d="M273.01,136.6c0.3,0.52,0.66,0.88,0.59,1.11c-0.08,0.26-0.53,0.48-0.85,0.54c-0.14,0.02-0.54-0.45-0.5-0.63
	C272.31,137.31,272.64,137.07,273.01,136.6z"
              />
              <path
                d="M265.62,139.35c-0.26-0.53-0.53-0.83-0.53-1.15c0-0.19,0.48-0.59,0.6-0.54c0.3,0.12,0.71,0.43,0.73,0.69
	C266.42,138.61,266.01,138.89,265.62,139.35z"
              />
              <path
                d="M260.23,146.11c-0.54,0.28-0.9,0.63-1.12,0.55c-0.26-0.09-0.49-0.52-0.55-0.84c-0.02-0.13,0.47-0.52,0.67-0.48
	C259.53,145.4,259.77,145.73,260.23,146.11z"
              />
              <path
                d="M267.25,145.58c-0.53,0.31-0.88,0.67-1.11,0.61c-0.26-0.07-0.5-0.51-0.57-0.83c-0.03-0.13,0.44-0.55,0.63-0.51
	C266.51,144.91,266.77,145.23,267.25,145.58z"
              />
              <path
                d="M425.71,280.41c-0.13-0.31-0.37-0.63-0.35-0.93c0.01-0.2,0.5-0.57,0.61-0.52c0.3,0.14,0.69,0.45,0.72,0.73
	c0.04,0.32-0.25,0.69-0.4,1.04C426.1,280.63,425.9,280.52,425.71,280.41z"
              />
              <path
                d="M273.27,143.81c0.41,0.47,0.83,0.74,0.82,0.98c-0.02,0.25-0.43,0.55-0.73,0.67c-0.11,0.05-0.57-0.34-0.57-0.53
	C272.77,144.63,273.02,144.33,273.27,143.81z"
              />
              <path
                d="M259.55,138.85c-0.47,0.32-0.78,0.7-1.04,0.67c-0.26-0.03-0.65-0.48-0.63-0.73c0.01-0.25,0.44-0.66,0.7-0.67
	C258.84,138.11,259.11,138.5,259.55,138.85z"
              />
              <path
                d="M319.42,144.23c0.52-0.28,0.82-0.55,1.13-0.56c0.2,0,0.62,0.48,0.57,0.58c-0.14,0.3-0.43,0.7-0.7,0.73
	C320.19,145.02,319.89,144.61,319.42,144.23z"
              />
              <path
                d="M299.35,138.94c-0.45,0.37-0.68,0.67-0.98,0.75c-0.16,0.04-0.62-0.32-0.6-0.46c0.04-0.3,0.25-0.75,0.48-0.81
	C298.49,138.36,298.83,138.68,299.35,138.94z"
              />
              <path
                d="M292.17,139.9c-0.5,0.28-0.84,0.62-1.09,0.56c-0.25-0.06-0.6-0.53-0.57-0.79c0.03-0.24,0.51-0.6,0.76-0.58
	C291.53,139.11,291.77,139.52,292.17,139.9z"
              />
              <path
                d="M280.49,145.11c-0.26-0.53-0.53-0.83-0.53-1.15c0-0.19,0.48-0.59,0.6-0.54c0.3,0.12,0.71,0.43,0.73,0.69
	C281.3,144.36,280.88,144.64,280.49,145.11z"
              />
              <path
                d="M288.83,143.54c-0.43,0.37-0.69,0.76-0.95,0.77c-0.26,0-0.71-0.39-0.73-0.63c-0.01-0.26,0.35-0.71,0.61-0.76
	C288.01,142.88,288.33,143.24,288.83,143.54z"
              />
              <path
                d="M419.99,280.37c-0.43,0.34-0.72,0.74-0.97,0.71c-0.27-0.03-0.69-0.45-0.69-0.71c0-0.25,0.41-0.68,0.68-0.71
	C419.26,279.63,419.56,280.03,419.99,280.37z"
              />
              <path
                d="M284.05,139.26c0.29,0.51,0.65,0.87,0.59,1.11c-0.07,0.25-0.53,0.47-0.85,0.54c-0.12,0.03-0.51-0.46-0.47-0.65
	C283.38,139.96,283.7,139.71,284.05,139.26z"
              />
              <path
                d="M296,143.19c-0.62,0.16-1.04,0.42-1.26,0.3c-0.22-0.12-0.32-0.61-0.31-0.94c0-0.12,0.55-0.37,0.72-0.29
	C295.43,142.38,295.61,142.73,296,143.19z"
              />
              <path
                d="M276.62,139.95c0.42,0.47,0.85,0.75,0.83,1c-0.02,0.26-0.44,0.56-0.75,0.69c-0.12,0.05-0.59-0.34-0.59-0.54
	C276.11,140.79,276.37,140.48,276.62,139.95z"
              />
              <path
                d="M421.74,301.6c-0.47,0.38-0.71,0.69-1.01,0.77c-0.17,0.04-0.64-0.33-0.62-0.47c0.04-0.31,0.26-0.77,0.49-0.83
	C420.85,301.02,421.2,301.34,421.74,301.6z"
              />
              <path
                d="M325.83,281.09c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.61c0.27-0.22,0.52-0.53,0.83-0.62
	C325.24,280.42,325.51,280.8,325.83,281.09z"
              />
              <path d="M579.07,633.55c0.49,0,0.98,0,1.67,0c-0.55,1.03-1.11,0.68-1.65,0.5C579.08,633.88,579.08,633.71,579.07,633.55z" />
              <path
                d="M412.79,633.61c0.48,0,0.95,0,1.43,0c0.02,0.12,0.04,0.25,0.05,0.37c-0.48,0.07-0.96,0.15-1.44,0.22
	C412.81,634,412.8,633.81,412.79,633.61z"
              />
              <path
                d="M267.58,485.18c-0.28,0.03-0.52,0.13-0.66,0.05c-0.16-0.09-0.23-0.33-0.34-0.5c0.25-0.08,0.5-0.2,0.75-0.21
	C267.4,484.52,267.47,484.88,267.58,485.18z"
              />
              <path
                d="M423.01,284.41c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C423.56,283.65,423.25,283.97,423.01,284.41z"
              />
              <path
                d="M416.6,284.28c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C415.95,283.57,416.21,283.94,416.6,284.28z"
              />
              <path
                d="M303.25,155.62c0.37,0.42,0.69,0.65,0.76,0.93c0.04,0.18-0.31,0.66-0.43,0.64c-0.32-0.05-0.77-0.24-0.86-0.49
	C302.63,156.5,302.98,156.13,303.25,155.62z"
              />
              <path
                d="M317.61,101.43c-0.52,0.3-0.85,0.65-1.09,0.6c-0.25-0.06-0.48-0.49-0.56-0.8c-0.03-0.12,0.43-0.52,0.61-0.49
	C316.88,100.79,317.13,101.09,317.61,101.43z"
              />
              <path
                d="M235.04,286.34c-0.31-0.4-0.66-0.66-0.62-0.82c0.08-0.3,0.39-0.54,0.6-0.8c0.22,0.19,0.63,0.4,0.62,0.57
	C235.62,285.59,235.33,285.87,235.04,286.34z"
              />
              <path
                d="M335.85,103.17c-0.45,0.39-0.67,0.71-0.97,0.8c-0.17,0.05-0.66-0.3-0.65-0.44c0.03-0.3,0.23-0.76,0.46-0.83
	C334.93,102.62,335.29,102.92,335.85,103.17z"
              />
              <path
                d="M321.51,104.51c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.55-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C320.92,103.84,321.19,104.22,321.51,104.51z"
              />
              <path
                d="M314.43,105.22c-0.49,0.33-0.81,0.71-1.02,0.66c-0.27-0.06-0.52-0.46-0.63-0.77c-0.04-0.1,0.4-0.53,0.6-0.51
	C313.67,104.62,313.94,104.9,314.43,105.22z"
              />
              <path
                d="M339.82,106.5c-0.36,0.39-0.58,0.78-0.74,0.76c-0.31-0.05-0.6-0.32-0.89-0.5c0.16-0.23,0.31-0.63,0.5-0.65
	C338.98,106.07,339.3,106.3,339.82,106.5z"
              />
              <path
                d="M331.61,108.04c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C332.24,107.41,331.93,107.64,331.61,108.04z"
              />
              <path
                d="M427.61,286.97c-0.43,0.26-0.76,0.58-0.89,0.51c-0.28-0.15-0.53-0.46-0.64-0.77c-0.04-0.09,0.45-0.56,0.61-0.51
	C426.98,286.27,427.2,286.6,427.61,286.97z"
              />
              <path
                d="M318.18,108.53c-0.33,0.31-0.57,0.69-0.72,0.66c-0.31-0.06-0.57-0.35-0.85-0.55c0.25-0.23,0.47-0.55,0.77-0.66
	C317.52,107.92,317.81,108.26,318.18,108.53z"
              />
              <path
                d="M310.98,109.04c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C310.35,108.42,310.62,108.76,310.98,109.04z"
              />
              <path
                d="M239.98,289.04c-0.5,0.18-0.87,0.42-0.99,0.33c-0.25-0.18-0.36-0.55-0.54-0.84c0.25-0.14,0.57-0.46,0.73-0.4
	C239.46,288.26,239.63,288.62,239.98,289.04z"
              />
              <path d="M215.77,634.04c-0.64,0.03-1.09,0.05-1.7,0.08C214.83,633.18,214.83,633.18,215.77,634.04z" />
              <path
                d="M343.58,109.78c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.62c0.27-0.22,0.52-0.53,0.83-0.62
	C343,109.12,343.27,109.5,343.58,109.78z"
              />
              <path
                d="M336.45,110.3c-0.34,0.4-0.55,0.81-0.7,0.79c-0.32-0.04-0.61-0.3-0.92-0.47c0.16-0.24,0.29-0.65,0.47-0.67
	C335.6,109.9,335.93,110.12,336.45,110.3z"
              />
              <path
                d="M328.3,110.27c0.32,0.39,0.67,0.65,0.63,0.82c-0.07,0.3-0.38,0.54-0.59,0.8c-0.22-0.19-0.64-0.39-0.63-0.55
	C327.74,111.03,328.01,110.75,328.3,110.27z"
              />
              <path
                d="M313.86,113.32c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C314.48,112.69,314.18,112.92,313.86,113.32z"
              />
              <path
                d="M307.66,112.7c-0.34,0.4-0.55,0.81-0.7,0.79c-0.32-0.04-0.61-0.3-0.92-0.47c0.16-0.24,0.29-0.65,0.47-0.67
	C306.81,112.3,307.14,112.52,307.66,112.7z"
              />
              <path
                d="M243.85,292.05c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C243.27,291.28,243.49,291.67,243.85,292.05z"
              />
              <path
                d="M340.3,114.03c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C339.72,113.26,339.94,113.65,340.3,114.03z"
              />
              <path
                d="M322.3,291.4c0.35,0.46,0.75,0.75,0.71,0.98c-0.04,0.25-0.45,0.53-0.75,0.63c-0.12,0.04-0.56-0.37-0.54-0.56
	C321.74,292.16,322.02,291.88,322.3,291.4z"
              />
              <path
                d="M310.95,275.33c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.56-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C310.37,274.66,310.63,275.04,310.95,275.33z"
              />
              <path
                d="M333.11,114.45c-0.5,0.24-0.81,0.49-1.1,0.48c-0.19-0.01-0.36-0.41-0.53-0.63c0.28-0.19,0.54-0.48,0.85-0.55
	C332.48,113.72,332.72,114.09,333.11,114.45z"
              />
              <path
                d="M336.17,116.8c0.35,0.41,0.67,0.64,0.75,0.93c0.04,0.14-0.43,0.61-0.54,0.57c-0.29-0.1-0.6-0.36-0.73-0.64
	C335.59,117.53,335.91,117.22,336.17,116.8z"
              />
              <path
                d="M231.71,275.03c-0.6,0.22-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C230.99,274.29,231.22,274.61,231.71,275.03z"
              />
              <path
                d="M333.93,121.58c-0.52,0.3-0.85,0.65-1.09,0.6c-0.25-0.06-0.48-0.49-0.56-0.8c-0.03-0.12,0.43-0.52,0.61-0.49
	C333.19,120.94,333.45,121.24,333.93,121.58z"
              />
              <path
                d="M303.98,123.24c0.38,0.42,0.7,0.65,0.8,0.95c0.04,0.13-0.41,0.62-0.54,0.59c-0.3-0.09-0.62-0.34-0.75-0.62
	C303.42,124.01,303.73,123.68,303.98,123.24z"
              />
              <path
                d="M280.14,169.62c-0.51,0.25-0.82,0.5-1.13,0.5c-0.16,0-0.34-0.41-0.5-0.64c0.27-0.19,0.53-0.48,0.83-0.53
	C279.51,168.92,279.75,169.28,280.14,169.62z"
              />
              <path
                d="M330.57,125.24c-0.45,0.39-0.67,0.71-0.97,0.8c-0.17,0.05-0.66-0.3-0.65-0.44c0.03-0.3,0.23-0.76,0.46-0.83
	C329.66,124.7,330.01,125,330.57,125.24z"
              />
              <path
                d="M308.38,125.94c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C307.55,127.14,307.83,126.86,308.38,125.94z"
              />
              <path
                d="M197.48,631.89c-0.38,0.26-0.62,0.56-0.85,0.55c-0.2-0.01-0.55-0.37-0.54-0.56c0.01-0.22,0.34-0.57,0.54-0.58
	C196.87,631.29,197.1,631.6,197.48,631.89z"
              />
              <path
                d="M312.96,130.73c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C312.3,130.03,312.56,130.39,312.96,130.73z"
              />
              <path
                d="M246.61,294.3c0.38,0.42,0.71,0.63,0.78,0.91c0.05,0.18-0.3,0.67-0.42,0.65c-0.32-0.04-0.76-0.22-0.87-0.47
	C246.01,295.19,246.35,294.81,246.61,294.3z"
              />
              <path
                d="M305.8,131.13c-0.49,0.33-0.81,0.71-1.02,0.66c-0.27-0.06-0.52-0.46-0.63-0.77c-0.04-0.1,0.4-0.53,0.6-0.51
	C305.03,130.53,305.3,130.82,305.8,131.13z"
              />
              <path
                d="M308.86,133.13c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C308.03,134.34,308.31,134.06,308.86,133.13z"
              />
              <path
                d="M319.41,294.84c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C318.59,296.05,318.87,295.76,319.41,294.84z"
              />
              <path
                d="M302.31,135.22c-0.32,0.28-0.58,0.65-0.73,0.61c-0.31-0.09-0.55-0.4-0.83-0.62c0.27-0.22,0.52-0.52,0.83-0.62
	C301.73,134.55,302,134.93,302.31,135.22z"
              />
              <path
                d="M203.93,630.52c0.25,0.41,0.56,0.77,0.5,0.86c-0.18,0.26-0.52,0.4-0.79,0.59c-0.12-0.24-0.36-0.5-0.34-0.72
	C203.32,631.03,203.63,630.85,203.93,630.52z"
              />
              <path
                d="M312.69,136.49c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C311.87,137.7,312.15,137.41,312.69,136.49z"
              />
              <path
                d="M305.61,137.44c0.3,0.4,0.67,0.71,0.61,0.83c-0.14,0.29-0.44,0.55-0.74,0.69c-0.08,0.04-0.57-0.41-0.54-0.57
	C305,138.1,305.3,137.85,305.61,137.44z"
              />
              <path
                d="M317.61,140.97c-0.53,0.31-0.82,0.57-1.13,0.61c-0.16,0.02-0.5-0.39-0.5-0.61c0-0.22,0.35-0.64,0.5-0.62
	C316.79,140.39,317.07,140.66,317.61,140.97z"
              />
              <path
                d="M399.66,296.44c-0.53,0.31-0.82,0.57-1.13,0.61c-0.16,0.02-0.5-0.39-0.5-0.61c0-0.22,0.35-0.64,0.5-0.62
	C398.84,295.86,399.13,296.13,399.66,296.44z"
              />
              <path
                d="M310.51,142.03c-0.3,0.09-0.62,0.3-0.9,0.25c-0.31-0.05-0.58-0.32-0.86-0.5c0.15-0.24,0.3-0.66,0.47-0.67
	c0.31-0.01,0.63,0.21,0.95,0.34C310.27,141.65,310.39,141.84,310.51,142.03z"
              />
              <path
                d="M219.95,359.93c-0.3-0.46-0.6-0.72-0.56-0.92c0.03-0.2,0.39-0.4,0.64-0.48c0.09-0.03,0.42,0.3,0.41,0.44
	C220.4,359.23,220.2,359.46,219.95,359.93z"
              />
              <path
                d="M301.29,142.53c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C302.04,143.11,301.72,142.79,301.29,142.53z"
              />
              <path
                d="M314.21,145.12c-0.5,0.26-0.79,0.52-1.1,0.54c-0.16,0.01-0.35-0.4-0.53-0.62c0.26-0.2,0.51-0.51,0.8-0.57
	C313.56,144.43,313.81,144.78,314.21,145.12z"
              />
              <path
                d="M297.55,299.02c-0.29-0.41-0.64-0.74-0.58-0.85c0.15-0.28,0.46-0.52,0.76-0.64c0.09-0.04,0.55,0.42,0.51,0.57
	C298.17,298.38,297.86,298.62,297.55,299.02z"
              />
              <path
                d="M251.57,298.65c-0.51,0.3-0.85,0.65-1.07,0.58c-0.26-0.08-0.49-0.49-0.58-0.8c-0.03-0.1,0.44-0.51,0.64-0.48
	C250.85,297.99,251.1,298.31,251.57,298.65z"
              />
              <path
                d="M270.89,154.91c0.25,0.44,0.56,0.77,0.49,0.92c-0.13,0.28-0.45,0.53-0.75,0.62c-0.12,0.04-0.58-0.45-0.54-0.59
	C270.19,155.56,270.51,155.33,270.89,154.91z"
              />
              <path
                d="M298.88,145.3c0.28,0.48,0.56,0.76,0.58,1.07c0.01,0.17-0.4,0.37-0.63,0.55c-0.21-0.26-0.51-0.51-0.59-0.8
	C298.21,145.95,298.56,145.69,298.88,145.3z"
              />
              <path
                d="M309.92,149.79c-0.3-0.41-0.6-0.66-0.66-0.95c-0.03-0.16,0.44-0.6,0.54-0.56c0.3,0.13,0.6,0.39,0.74,0.67
	C310.59,149.08,310.22,149.39,309.92,149.79z"
              />
              <path
                d="M295.85,151.01c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C296.4,150.25,296.09,150.57,295.85,151.01z"
              />
              <path
                d="M288.42,151.78c-0.29-0.5-0.64-0.84-0.58-1.07c0.06-0.24,0.52-0.45,0.83-0.51c0.12-0.02,0.5,0.44,0.46,0.61
	C289.07,151.1,288.77,151.34,288.42,151.78z"
              />
              <path
                d="M228.67,333.83c-0.38-0.42-0.71-0.65-0.8-0.94c-0.05-0.14,0.42-0.63,0.53-0.59c0.3,0.09,0.62,0.35,0.75,0.63
	C229.22,333.07,228.92,333.39,228.67,333.83z"
              />
              <path
                d="M693.85,626.17c-0.26-0.38-0.52-0.76-0.78-1.14c0.14-0.09,0.28-0.19,0.41-0.28c0.25,0.39,0.5,0.77,0.76,1.16
	C694.1,625.99,693.97,626.08,693.85,626.17z"
              />
              <path
                d="M282.29,151.39c-0.53,0.27-0.88,0.6-1.1,0.52c-0.25-0.09-0.47-0.51-0.54-0.82c-0.02-0.11,0.46-0.49,0.66-0.45
	C281.6,150.7,281.83,151.02,282.29,151.39z"
              />
              <path
                d="M315.73,299.21c0.33,0.27,0.74,0.48,0.71,0.62c-0.05,0.31-0.32,0.58-0.5,0.87c-0.26-0.22-0.59-0.41-0.74-0.69
	C315.14,299.89,315.48,299.56,315.73,299.21z"
              />
              <path
                d="M256.66,157.95c-0.3-0.41-0.6-0.66-0.66-0.95c-0.03-0.16,0.44-0.6,0.54-0.56c0.3,0.13,0.6,0.39,0.74,0.67
	C257.33,157.23,256.96,157.55,256.66,157.95z"
              />
              <path
                d="M283.5,165.75c-0.5,0.26-0.79,0.52-1.1,0.54c-0.16,0.01-0.35-0.4-0.53-0.62c0.26-0.2,0.51-0.5,0.8-0.57
	C282.85,165.07,283.1,165.42,283.5,165.75z"
              />
              <path
                d="M232.67,329.25c-0.6,0.22-0.93,0.43-1.24,0.41c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.54
	C231.95,328.52,232.18,328.83,232.67,329.25z"
              />
              <path
                d="M307.62,152.7c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C306.99,152.08,307.26,152.43,307.62,152.7z"
              />
              <path
                d="M259.85,154.18c-0.39-0.44-0.81-0.7-0.79-0.94c0.01-0.25,0.4-0.56,0.69-0.68c0.12-0.05,0.6,0.32,0.6,0.51
	C260.34,153.37,260.09,153.68,259.85,154.18z"
              />
              <path
                d="M250.91,165.15c-0.6,0.21-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C250.18,164.41,250.41,164.72,250.91,165.15z"
              />
              <path
                d="M247.4,303.35c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C248.06,302.71,247.75,302.94,247.4,303.35z"
              />
              <path
                d="M311.85,264.41c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C312.6,264.99,312.27,264.67,311.85,264.41z"
              />
              <path
                d="M413.43,303.35c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C414.08,302.71,413.78,302.94,413.43,303.35z"
              />
              <path
                d="M300.45,153.33c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.43-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C299.69,152.73,300.02,153.06,300.45,153.33z"
              />
              <path
                d="M324.34,233.36c0.38,0.42,0.71,0.63,0.78,0.91c0.05,0.18-0.3,0.67-0.42,0.65c-0.32-0.04-0.76-0.22-0.87-0.47
	C323.75,234.25,324.09,233.87,324.34,233.36z"
              />
              <path
                d="M293.25,153.81c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.43-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C292.49,153.21,292.82,153.54,293.25,153.81z"
              />
              <path
                d="M296.15,156.34c0.32,0.39,0.67,0.65,0.63,0.82c-0.07,0.3-0.38,0.54-0.59,0.8c-0.22-0.19-0.64-0.39-0.63-0.55
	C295.59,157.1,295.86,156.81,296.15,156.34z"
              />
              <path
                d="M285.34,153.29c0.24,0.64,0.48,0.96,0.42,1.21c-0.07,0.3-0.35,0.62-0.62,0.78c-0.1,0.06-0.66-0.33-0.65-0.48
	C284.52,154.49,284.8,154.21,285.34,153.29z"
              />
              <path
                d="M321.25,237.19c0.4,0.43,0.82,0.69,0.8,0.92c-0.02,0.27-0.4,0.58-0.69,0.71c-0.12,0.05-0.6-0.32-0.6-0.51
	C320.75,238,321.01,237.7,321.25,237.19z"
              />
              <path
                d="M242.36,317.29c-0.34,0.3-0.59,0.67-0.75,0.63c-0.31-0.08-0.56-0.38-0.83-0.59c0.26-0.22,0.49-0.53,0.8-0.62
	C241.73,316.67,242,317.01,242.36,317.29z"
              />
              <path
                d="M325.28,240.61c0.3,0.4,0.67,0.71,0.61,0.83c-0.14,0.29-0.44,0.55-0.74,0.69c-0.08,0.04-0.57-0.4-0.54-0.57
	C324.67,241.26,324.97,241.02,325.28,240.61z"
              />
              <path
                d="M285.79,162.75c-0.26-0.41-0.59-0.73-0.52-0.87c0.13-0.28,0.44-0.54,0.73-0.63c0.12-0.04,0.58,0.43,0.54,0.56
	C286.45,162.12,286.14,162.35,285.79,162.75z"
              />
              <path
                d="M330.23,258.06c0.31,0.4,0.66,0.66,0.62,0.83c-0.08,0.3-0.38,0.53-0.6,0.8c-0.22-0.19-0.64-0.4-0.62-0.56
	C329.66,258.82,329.94,258.53,330.23,258.06z"
              />
              <path
                d="M298.38,306.29c-0.31-0.45-0.6-0.71-0.65-1.01c-0.03-0.17,0.38-0.61,0.5-0.58c0.31,0.08,0.75,0.32,0.79,0.56
	C299.06,305.5,298.69,305.82,298.38,306.29z"
              />
              <path
                d="M289.92,158.08c-0.49,0.26-0.78,0.53-1.08,0.52c-0.19,0-0.37-0.4-0.56-0.61c0.27-0.21,0.52-0.5,0.83-0.58
	C289.27,157.38,289.53,157.74,289.92,158.08z"
              />
              <path
                d="M250.43,305.68c0.41-0.36,0.64-0.69,0.92-0.76c0.16-0.04,0.64,0.43,0.61,0.52c-0.12,0.3-0.37,0.62-0.65,0.76
	C251.18,306.26,250.85,305.94,250.43,305.68z"
              />
              <path
                d="M254.27,161.31c-0.6,0.22-0.93,0.42-1.24,0.4c-0.15-0.01-0.41-0.48-0.37-0.7c0.04-0.22,0.44-0.58,0.58-0.53
	C253.54,160.57,253.77,160.88,254.27,161.31z"
              />
              <path d="M358.39,4.18c0.35,0,0.69,0,1.04,0c0,0.1,0,0.2,0,0.31c-0.35,0-0.69,0-1.04,0C358.39,4.38,358.39,4.28,358.39,4.18z" />
              <path
                d="M278.83,155.07c-0.33,0.31-0.57,0.69-0.72,0.66c-0.31-0.06-0.57-0.35-0.85-0.55c0.25-0.23,0.47-0.55,0.77-0.66
	C278.17,154.47,278.46,154.81,278.83,155.07z"
              />
              <path
                d="M319.67,249.34c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C319.09,248.58,319.31,248.96,319.67,249.34z"
              />
              <path
                d="M330.68,251.69c-0.41,0.36-0.63,0.68-0.92,0.75c-0.17,0.04-0.65-0.42-0.61-0.51c0.12-0.3,0.37-0.62,0.65-0.77
	C329.92,251.1,330.25,251.42,330.68,251.69z"
              />
              <path
                d="M282.72,158.65c-0.52,0.21-0.84,0.44-1.13,0.41c-0.19-0.02-0.33-0.43-0.5-0.66c0.29-0.18,0.58-0.45,0.89-0.49
	C282.14,157.89,282.36,158.27,282.72,158.65z"
              />
              <path
                d="M231.03,282.95c-0.24-0.43-0.53-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C231.73,282.32,231.41,282.54,231.03,282.95z"
              />
              <path
                d="M279.54,162.6c-0.47,0.15-0.88,0.38-0.98,0.28c-0.22-0.21-0.29-0.59-0.43-0.9c0.28-0.07,0.59-0.24,0.83-0.17
	C279.16,161.88,279.28,162.23,279.54,162.6z"
              />
              <path
                d="M230.54,307.37c-0.33,0.31-0.52,0.64-0.73,0.65c-0.2,0.01-0.58-0.28-0.6-0.47c-0.02-0.21,0.25-0.58,0.46-0.63
	C229.87,306.87,230.14,307.15,230.54,307.37z"
              />
              <path
                d="M274.63,158.21c0.45,0.5,0.76,0.72,0.86,1.01c0.04,0.13-0.32,0.53-0.54,0.58c-0.19,0.04-0.63-0.21-0.65-0.38
	C274.25,159.13,274.45,158.8,274.63,158.21z"
              />
              <path
                d="M249.02,309.93c-0.29,0.1-0.61,0.31-0.87,0.25c-0.29-0.06-0.53-0.37-0.79-0.57c0.2-0.2,0.37-0.52,0.6-0.56
	c0.22-0.04,0.49,0.21,0.74,0.33C248.8,309.57,248.91,309.75,249.02,309.93z"
              />
              <path
                d="M366.12,633.67c0.4,0,0.8,0,1.19,0c0.01,0.11,0.03,0.22,0.04,0.33c-0.39,0.06-0.79,0.12-1.18,0.18
	C366.16,634.01,366.14,633.84,366.12,633.67z"
              />
              <path
                d="M311.64,282.56c-0.34,0.25-0.67,0.6-0.77,0.55c-0.26-0.15-0.41-0.48-0.61-0.73c0.22-0.15,0.45-0.45,0.65-0.43
	C311.14,281.97,311.33,282.29,311.64,282.56z"
              />
              <path
                d="M319.37,257.16c-0.35-0.29-0.75-0.55-0.72-0.63c0.11-0.28,0.38-0.5,0.58-0.75c0.2,0.18,0.52,0.34,0.56,0.55
	C319.83,256.54,319.57,256.79,319.37,257.16z"
              />
              <path
                d="M267.74,158.82c0.26,0.4,0.59,0.78,0.53,0.86c-0.2,0.26-0.54,0.41-0.82,0.61c-0.13-0.25-0.38-0.51-0.36-0.74
	C267.11,159.33,267.43,159.15,267.74,158.82z"
              />
              <path
                d="M228.22,278.89c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C227.61,278.37,227.84,278.64,228.22,278.89z"
              />
              <path
                d="M234.57,311.15c-0.42,0.12-0.73,0.31-0.92,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C234.18,310.29,234.35,310.74,234.57,311.15z"
              />
              <path
                d="M227.45,311.22c-0.41,0.33-0.65,0.67-0.83,0.64c-0.22-0.04-0.44-0.36-0.53-0.61c-0.04-0.09,0.27-0.46,0.42-0.45
	C226.75,310.8,227,311,227.45,311.22z"
              />
              <path
                d="M322.91,245.63c-0.29,0.1-0.61,0.31-0.87,0.25c-0.29-0.06-0.53-0.37-0.79-0.57c0.2-0.2,0.37-0.52,0.6-0.56
	c0.22-0.04,0.49,0.21,0.74,0.33C322.7,245.28,322.81,245.46,322.91,245.63z"
              />
              <path
                d="M260.4,161.23c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C261.11,160.6,260.71,160.91,260.4,161.23z"
              />
              <path
                d="M245.71,313.31c-0.34,0.36-0.58,0.75-0.71,0.72c-0.31-0.08-0.56-0.36-0.83-0.56c0.21-0.2,0.39-0.51,0.63-0.56
	C245,312.86,245.28,313.1,245.71,313.31z"
              />
              <path
                d="M238.84,314.38c-0.53,0.21-0.81,0.42-1.09,0.41c-0.25-0.02-0.48-0.28-0.72-0.43c0.2-0.27,0.37-0.6,0.64-0.78
	C237.76,313.52,238.11,313.86,238.84,314.38z"
              />
              <path
                d="M237.74,306.87c-0.29,0.31-0.45,0.63-0.67,0.67c-0.21,0.04-0.46-0.22-0.7-0.35c0.17-0.28,0.3-0.61,0.54-0.8
	C236.99,306.34,237.37,306.65,237.74,306.87z"
              />
              <path
                d="M245.02,306.24c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C244.4,305.72,244.63,305.99,245.02,306.24z"
              />
              <path
                d="M230.35,313.82c0.32,0.37,0.63,0.57,0.64,0.78c0,0.22-0.29,0.44-0.45,0.66c-0.22-0.17-0.57-0.31-0.63-0.52
	C229.85,314.53,230.12,314.26,230.35,313.82z"
              />
              <path
                d="M322.02,286.24c-0.45-0.62-0.74-0.84-0.73-1.05c0.01-0.21,0.32-0.4,0.5-0.6c0.23,0.22,0.58,0.4,0.66,0.67
	C322.52,285.46,322.25,285.77,322.02,286.24z"
              />
              <path
                d="M306.24,286.17c0.55-0.21,0.87-0.47,1.11-0.4c0.29,0.08,0.51,0.42,0.76,0.65c-0.22,0.2-0.42,0.52-0.65,0.55
	C307.26,286.99,307.01,286.69,306.24,286.17z"
              />
              <path
                d="M419.48,288.18c-0.29-0.38-0.65-0.74-0.6-0.82c0.17-0.27,0.5-0.45,0.78-0.66c0.15,0.24,0.42,0.48,0.42,0.71
	C420.07,287.63,419.76,287.84,419.48,288.18z"
              />
              <path
                d="M316.25,261.03c-0.33-0.3-0.66-0.46-0.7-0.67c-0.04-0.22,0.22-0.48,0.35-0.72c0.29,0.18,0.62,0.31,0.84,0.55
	C316.8,260.25,316.49,260.64,316.25,261.03z"
              />
              <path
                d="M413.1,288.34c-0.45,0.12-0.75,0.3-0.94,0.22c-0.21-0.08-0.32-0.41-0.47-0.63c0.3-0.14,0.59-0.36,0.89-0.39
	C412.7,287.53,412.86,287.95,413.1,288.34z"
              />
              <path
                d="M233.9,303.61c-0.31,0.27-0.51,0.57-0.74,0.59c-0.2,0.02-0.43-0.28-0.65-0.44c0.2-0.25,0.37-0.58,0.63-0.72
	C233.25,302.99,233.58,303.35,233.9,303.61z"
              />
              <path
                d="M272.33,163.19c-0.4,0.22-0.79,0.52-0.88,0.45c-0.24-0.19-0.37-0.54-0.53-0.83c0.24-0.13,0.52-0.38,0.73-0.34
	C271.86,162.52,272.03,162.85,272.33,163.19z"
              />
              <path
                d="M233.95,319.06c-0.08-0.3-0.28-0.62-0.22-0.89c0.07-0.29,0.38-0.52,0.58-0.77c0.19,0.2,0.5,0.38,0.54,0.6
	c0.04,0.21-0.21,0.47-0.33,0.71C234.33,318.83,234.14,318.95,233.95,319.06z"
              />
              <path
                d="M228.43,318.38c-0.94,0.48-1.22,0.74-1.4,0.68c-0.22-0.06-0.51-0.43-0.48-0.62c0.03-0.2,0.39-0.47,0.63-0.49
	C227.44,317.92,227.74,318.12,228.43,318.38z"
              />
              <path
                d="M405.94,288.77c-0.31,0.27-0.57,0.64-0.71,0.59c-0.3-0.09-0.54-0.39-0.81-0.6c0.27-0.21,0.51-0.51,0.81-0.6
	C405.37,288.11,405.63,288.49,405.94,288.77z"
              />
              <path
                d="M407.42,302.75c-0.34,0.36-0.58,0.75-0.71,0.72c-0.31-0.08-0.56-0.36-0.83-0.57c0.21-0.2,0.39-0.51,0.63-0.56
	C406.71,302.3,406.98,302.55,407.42,302.75z"
              />
              <path
                d="M247.41,175.15c0.2,0.42,0.44,0.68,0.4,0.89c-0.04,0.22-0.36,0.39-0.56,0.58c-0.19-0.26-0.47-0.5-0.55-0.8
	C246.67,175.71,247.06,175.47,247.41,175.15z"
              />
              <path
                d="M238.25,320.34c0.22,0.37,0.5,0.63,0.46,0.83c-0.04,0.23-0.37,0.41-0.57,0.61c-0.22-0.25-0.5-0.48-0.62-0.78
	C237.49,320.92,237.9,320.64,238.25,320.34z"
              />
              <path
                d="M231.98,321.75c-0.29,0.31-0.45,0.63-0.67,0.67c-0.21,0.04-0.46-0.22-0.7-0.35c0.17-0.28,0.3-0.61,0.54-0.8
	C231.24,321.21,231.61,321.53,231.98,321.75z"
              />
              <path
                d="M258.13,164.55c-0.29,0.08-0.6,0.27-0.87,0.22c-0.29-0.06-0.52-0.34-0.78-0.53c0.19-0.19,0.36-0.5,0.58-0.54
	c0.22-0.04,0.48,0.19,0.72,0.31C257.89,164.18,258.01,164.37,258.13,164.55z"
              />
              <path
                d="M232.65,289.55c-0.42,0.12-0.73,0.31-0.91,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C232.26,288.7,232.43,289.15,232.65,289.55z"
              />
              <path
                d="M240.43,303.7c-0.32-0.29-0.64-0.44-0.68-0.64c-0.04-0.21,0.21-0.46,0.34-0.69c0.28,0.17,0.56,0.34,0.84,0.5
	C240.8,303.1,240.66,303.33,240.43,303.7z"
              />
              <path
                d="M327.25,261.99c0.23,0.44,0.49,0.72,0.44,0.92c-0.05,0.21-0.41,0.35-0.63,0.53c-0.16-0.22-0.45-0.44-0.45-0.66
	C326.61,262.57,326.93,262.36,327.25,261.99z"
              />
              <path
                d="M292.92,606.9c0.2-0.31,0.4-0.62,0.6-0.92c0.08,0.05,0.16,0.1,0.25,0.15c-0.19,0.31-0.38,0.62-0.58,0.94
	C293.1,607.01,293.01,606.96,292.92,606.9z"
              />
              <path
                d="M235.99,325.23c-0.51,0.29-0.79,0.56-1.09,0.59c-0.15,0.02-0.49-0.39-0.49-0.6c0-0.22,0.34-0.62,0.49-0.6
	C235.21,324.66,235.49,324.93,235.99,325.23z"
              />
              <path
                d="M228.66,325.75c-0.36,0.26-0.7,0.62-0.8,0.56c-0.27-0.15-0.43-0.49-0.63-0.75c0.23-0.16,0.47-0.46,0.67-0.44
	C228.14,325.14,228.34,325.46,228.66,325.75z"
              />
              <path
                d="M319.39,288.97c-0.37,0.28-0.65,0.63-0.79,0.58c-0.27-0.1-0.46-0.41-0.69-0.64c0.21-0.16,0.42-0.45,0.64-0.45
	C318.78,288.45,319,288.71,319.39,288.97z"
              />
              <path
                d="M312.12,289.82c-0.4,0.22-0.67,0.49-0.88,0.45c-0.2-0.04-0.48-0.42-0.46-0.62c0.02-0.2,0.39-0.5,0.6-0.48
	C311.59,289.18,311.78,289.51,312.12,289.82z"
              />
              <path
                d="M302.3,301.1c-0.31,0.34-0.57,0.74-0.67,0.71c-0.31-0.11-0.55-0.39-0.82-0.6c0.21-0.2,0.4-0.53,0.64-0.58
	C301.64,300.59,301.91,300.87,302.3,301.1z"
              />
              <path
                d="M309.43,301.07c-0.42,0.12-0.73,0.31-0.91,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C309.04,300.21,309.21,300.67,309.43,301.07z"
              />
              <path
                d="M229.3,301.11c-0.28-0.33-0.58-0.52-0.6-0.73c-0.01-0.23,0.24-0.47,0.38-0.7c0.27,0.2,0.59,0.36,0.77,0.62
	C229.92,300.38,229.57,300.73,229.3,301.11z"
              />
              <path
                d="M263.68,155.75c0.22,0.37,0.5,0.63,0.46,0.83c-0.04,0.23-0.37,0.41-0.57,0.61c-0.22-0.25-0.5-0.48-0.62-0.78
	C262.92,156.33,263.34,156.06,263.68,155.75z"
              />
              <path
                d="M225.3,329.47c-0.32,0.3-0.5,0.62-0.73,0.65c-0.21,0.03-0.46-0.26-0.69-0.4c0.19-0.28,0.34-0.61,0.6-0.79
	C224.57,328.87,224.93,329.22,225.3,329.47z"
              />
              <path
                d="M321.1,110.77c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C320.55,111.51,320.83,111.24,321.1,110.77z"
              />
              <path
                d="M403.46,299.8c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C402.67,299.23,402.95,299.5,403.46,299.8z"
              />
              <path
                d="M237.3,299.67c-0.31,0.32-0.48,0.65-0.7,0.69c-0.21,0.04-0.48-0.22-0.72-0.35c0.18-0.29,0.31-0.63,0.56-0.82
	C236.52,299.12,236.91,299.44,237.3,299.67z"
              />
              <path
                d="M305.09,290.37c-0.58,0.21-0.89,0.42-1.2,0.4c-0.15-0.01-0.4-0.47-0.36-0.68c0.04-0.22,0.43-0.57,0.56-0.53
	C304.39,289.66,304.62,289.96,305.09,290.37z"
              />
              <path
                d="M423.31,289.75c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C422.76,290.49,423.04,290.22,423.31,289.75z"
              />
              <path
                d="M222.22,333.63c-0.44,0.28-0.69,0.58-0.9,0.55c-0.23-0.03-0.42-0.37-0.63-0.57c0.22-0.19,0.42-0.5,0.66-0.53
	C221.55,333.05,221.8,333.35,222.22,333.63z"
              />
              <path
                d="M251.42,172.05c-0.35,0.33-0.56,0.66-0.75,0.66c-0.22-0.01-0.44-0.31-0.66-0.49c0.17-0.22,0.32-0.56,0.53-0.62
	C250.74,171.56,251.01,171.83,251.42,172.05z"
              />
              <path
                d="M303.05,150.2c-0.35-0.31-0.77-0.57-0.74-0.66c0.11-0.29,0.39-0.52,0.6-0.78c0.21,0.19,0.54,0.36,0.58,0.58
	C303.53,149.54,303.26,149.81,303.05,150.2z"
              />
              <path
                d="M182.57,632.17c0.09,0.29,0.28,0.59,0.25,0.86c-0.03,0.23-0.3,0.56-0.5,0.59c-0.18,0.03-0.54-0.25-0.6-0.45
	c-0.05-0.18,0.2-0.45,0.32-0.68C182.22,632.39,182.4,632.28,182.57,632.17z"
              />
              <path
                d="M276.17,166.28c-0.31,0.32-0.48,0.65-0.7,0.69c-0.21,0.04-0.48-0.22-0.72-0.35c0.18-0.29,0.31-0.63,0.56-0.82
	C275.39,165.72,275.77,166.04,276.17,166.28z"
              />
              <path
                d="M243.61,299.91c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C244.31,299.27,243.92,299.58,243.61,299.91z"
              />
              <path
                d="M219.62,344.54c-0.4,0.34-0.6,0.65-0.82,0.66c-0.21,0.01-0.43-0.29-0.65-0.46c0.17-0.21,0.31-0.55,0.51-0.59
	C218.88,344.09,219.15,344.33,219.62,344.54z"
              />
              <path
                d="M268.93,166.92c-0.34,0.25-0.67,0.6-0.77,0.55c-0.26-0.15-0.41-0.48-0.61-0.73c0.22-0.15,0.45-0.45,0.65-0.43
	C268.43,166.33,268.62,166.64,268.93,166.92z"
              />
              <path
                d="M417.76,298.46c-0.35,0.33-0.58,0.69-0.72,0.66c-0.28-0.07-0.52-0.35-0.77-0.54c0.19-0.19,0.36-0.5,0.58-0.53
	C417.07,298.01,417.33,298.25,417.76,298.46z"
              />
              <path
                d="M306.89,145.81c-0.47,0.15-0.88,0.38-0.98,0.28c-0.22-0.21-0.29-0.59-0.43-0.9c0.28-0.07,0.59-0.24,0.83-0.17
	C306.51,145.09,306.63,145.43,306.89,145.81z"
              />
              <path
                d="M327.28,271.1c-0.08-0.3-0.24-0.61-0.22-0.9c0.02-0.26,0.23-0.51,0.36-0.76c0.29,0.19,0.63,0.34,0.83,0.59
	c0.06,0.08-0.26,0.46-0.4,0.7C327.66,270.86,327.47,270.98,327.28,271.1z"
              />
              <path
                d="M219.4,353.16c-0.49-0.92-0.75-1.18-0.7-1.37c0.05-0.19,0.41-0.45,0.61-0.42c0.19,0.02,0.47,0.35,0.49,0.56
	C219.83,352.2,219.63,352.49,219.4,353.16z"
              />
              <path
                d="M306.16,297.26c-0.94,0.48-1.22,0.74-1.4,0.68c-0.22-0.06-0.51-0.43-0.48-0.62c0.03-0.2,0.39-0.47,0.63-0.49
	C305.18,296.8,305.47,297.01,306.16,297.26z"
              />
              <path
                d="M324.95,115.98c-0.28-0.46-0.56-0.73-0.58-1.03c-0.01-0.17,0.39-0.37,0.6-0.56c0.21,0.26,0.51,0.49,0.58,0.79
	C325.6,115.33,325.26,115.59,324.95,115.98z"
              />
              <path
                d="M317.89,116.44c-0.24-0.43-0.54-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C318.58,115.82,318.26,116.03,317.89,116.44z"
              />
              <path
                d="M310.78,117.09c-0.25-0.35-0.55-0.58-0.54-0.8c0.01-0.21,0.33-0.42,0.52-0.62c0.24,0.23,0.54,0.43,0.68,0.71
	C311.49,116.45,311.09,116.76,310.78,117.09z"
              />
              <path
                d="M233.18,296.42c-0.32,0.28-0.53,0.59-0.74,0.59c-0.22,0-0.45-0.29-0.67-0.46c0.21-0.25,0.39-0.56,0.66-0.71
	C232.52,295.79,232.84,296.15,233.18,296.42z"
              />
              <path
                d="M303.5,116.07c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C302.96,116.82,303.26,116.5,303.5,116.07z"
              />
              <path
                d="M313.43,272.35c-0.29-0.38-0.65-0.74-0.6-0.82c0.17-0.27,0.5-0.45,0.78-0.66c0.15,0.24,0.42,0.48,0.42,0.71
	C314.02,271.8,313.72,272,313.43,272.35z"
              />
              <path
                d="M404.96,296.08c0.4-0.35,0.61-0.67,0.9-0.75c0.15-0.04,0.63,0.42,0.59,0.51c-0.11,0.3-0.36,0.6-0.63,0.75
	C405.7,296.65,405.38,296.33,404.96,296.08z"
              />
              <path
                d="M482.5,21.94c0.15,0.23,0.31,0.46,0.46,0.68c-0.07,0.07-0.15,0.14-0.22,0.21c-0.21-0.2-0.42-0.4-0.63-0.6
	C482.24,22.13,482.37,22.04,482.5,21.94z"
              />
              <path
                d="M227.51,271.86c-0.49,0.21-0.78,0.46-0.98,0.4c-0.22-0.06-0.36-0.4-0.54-0.62c0.24-0.16,0.47-0.44,0.71-0.44
	C226.9,271.2,227.1,271.51,227.51,271.86z"
              />
              <path
                d="M253.7,167.29c0.29,0.32,0.48,0.52,0.66,0.72c-0.24,0.22-0.48,0.43-0.72,0.65c-0.17-0.21-0.47-0.41-0.48-0.62
	C253.16,167.83,253.45,167.62,253.7,167.29z"
              />
              <path
                d="M240.35,296.29c-0.44,0.12-0.75,0.32-0.94,0.24c-0.21-0.09-0.32-0.44-0.47-0.68c0.3-0.16,0.6-0.38,0.92-0.44
	C239.95,295.39,240.12,295.87,240.35,296.29z"
              />
              <path
                d="M316.69,133.78c-0.31,0.27-0.57,0.63-0.71,0.59c-0.3-0.09-0.54-0.39-0.81-0.6c0.27-0.21,0.51-0.51,0.81-0.6
	C316.12,133.12,316.38,133.5,316.69,133.78z"
              />
              <path
                d="M265.76,171.03c-0.42,0.12-0.73,0.31-0.92,0.23c-0.21-0.09-0.31-0.43-0.45-0.66c0.29-0.15,0.58-0.37,0.89-0.42
	C265.37,170.18,265.54,170.63,265.76,171.03z"
              />
              <path
                d="M421.08,294.66c-0.4,0.22-0.79,0.52-0.88,0.45c-0.24-0.19-0.37-0.54-0.53-0.83c0.24-0.13,0.52-0.38,0.73-0.34
	C420.62,293.99,420.78,294.33,421.08,294.66z"
              />
              <path
                d="M300.95,293.11c0.31,0.38,0.65,0.64,0.61,0.8c-0.07,0.29-0.37,0.53-0.57,0.78c-0.22-0.18-0.62-0.38-0.61-0.55
	C300.4,293.85,300.68,293.57,300.95,293.11z"
              />
              <path
                d="M320.09,129.82c-0.39,0.35-0.61,0.67-0.89,0.73c-0.16,0.04-0.63-0.41-0.6-0.5c0.12-0.3,0.36-0.6,0.63-0.75
	C319.36,129.24,319.68,129.56,320.09,129.82z"
              />
              <path
                d="M316.06,126.78c-0.38,0.3-0.67,0.66-0.81,0.61c-0.28-0.1-0.48-0.43-0.71-0.66c0.22-0.17,0.43-0.47,0.66-0.48
	C315.43,126.24,315.67,126.52,316.06,126.78z"
              />
              <path
                d="M247.64,169.07c-0.49,0.18-0.85,0.41-0.96,0.32c-0.24-0.18-0.42-0.52-0.48-0.82c-0.02-0.09,0.52-0.45,0.66-0.38
	C247.12,168.32,247.29,168.66,247.64,169.07z"
              />
              <path
                d="M308.96,293.89c-0.48,0.1-0.81,0.28-0.98,0.18c-0.19-0.11-0.36-0.53-0.28-0.71c0.08-0.19,0.5-0.38,0.7-0.32
	C308.6,293.1,308.7,293.47,308.96,293.89z"
              />
              <path
                d="M323.32,126.09c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C322.54,125.53,322.82,125.79,323.32,126.09z"
              />
              <path
                d="M188.61,632.56c0.4-0.25,0.64-0.52,0.84-0.49c0.22,0.03,0.4,0.34,0.6,0.52c-0.2,0.2-0.39,0.53-0.62,0.56
	C189.25,633.17,189.01,632.86,188.61,632.56z"
              />
              <path
                d="M328.92,119.32c-0.24-0.43-0.53-0.75-0.47-0.89c0.13-0.27,0.45-0.52,0.74-0.61c0.12-0.04,0.57,0.45,0.52,0.58
	C329.62,118.7,329.3,118.91,328.92,119.32z"
              />
              <path
                d="M228.54,292.24c0.26,0.38,0.56,0.62,0.54,0.83c-0.02,0.22-0.35,0.41-0.55,0.61c-0.21-0.2-0.56-0.39-0.58-0.61
	C227.94,292.86,228.26,292.62,228.54,292.24z"
              />
              <path
                d="M321.73,117.99c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C321.19,118.74,321.49,118.42,321.73,117.99z"
              />
              <path
                d="M311.15,122.65c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C310.69,123.3,310.95,123.03,311.15,122.65z"
              />
              <path
                d="M318.55,121.81c0.27,0.46,0.55,0.74,0.57,1.04c0.01,0.17-0.39,0.36-0.61,0.55c-0.2-0.26-0.5-0.49-0.57-0.79
	C317.89,122.45,318.24,122.19,318.55,121.81z"
              />
              <path
                d="M302.99,401.43c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C302.53,402.09,302.79,401.82,302.99,401.43z"
              />
              <path
                d="M401.86,291.69c0.37,0.41,0.69,0.63,0.79,0.92c0.04,0.13-0.4,0.61-0.52,0.58c-0.29-0.08-0.61-0.34-0.73-0.61
	C401.32,292.44,401.62,292.12,401.86,291.69z"
              />
              <path
                d="M326.68,122.26c-0.5,0.3-0.78,0.56-1.09,0.6c-0.15,0.02-0.49-0.38-0.49-0.6c0-0.22,0.34-0.63,0.49-0.61
	C325.9,121.69,326.18,121.96,326.68,122.26z"
              />
              <path
                d="M236.56,292.58c-0.34,0.29-0.55,0.61-0.76,0.61c-0.23,0-0.46-0.3-0.69-0.47c0.22-0.26,0.41-0.58,0.68-0.73
	C235.88,291.94,236.21,292.3,236.56,292.58z"
              />
              <path
                d="M293.58,406.97c-0.58,0.21-0.89,0.42-1.2,0.4c-0.15-0.01-0.4-0.47-0.36-0.68c0.04-0.22,0.43-0.57,0.56-0.53
	C292.88,406.26,293.1,406.56,293.58,406.97z"
              />
              <path
                d="M273.07,170.51c-0.49,0.18-0.85,0.41-0.96,0.32c-0.24-0.18-0.42-0.52-0.48-0.82c-0.02-0.09,0.52-0.45,0.66-0.38
	C272.55,169.75,272.72,170.09,273.07,170.51z"
              />
              <path
                d="M307.58,119.29c0.25,0.37,0.41,0.6,0.56,0.83c-0.28,0.19-0.57,0.38-0.85,0.56c-0.14-0.24-0.41-0.49-0.38-0.72
	C306.93,119.76,307.27,119.59,307.58,119.29z"
              />
              <path
                d="M314.51,118.81c0.37,0.29,0.79,0.55,0.76,0.63c-0.12,0.3-0.38,0.55-0.59,0.81c-0.21-0.19-0.53-0.36-0.59-0.59
	C314.05,119.46,314.31,119.19,314.51,118.81z"
              />
              <path
                d="M324.51,273.3c0.27,0.42,0.48,0.64,0.53,0.89c0.02,0.13-0.3,0.46-0.4,0.43c-0.26-0.06-0.48-0.27-0.71-0.42
	C324.07,273.96,324.22,273.73,324.51,273.3z"
              />
              <path
                d="M315.12,293.66c-0.25-0.43-0.46-0.65-0.5-0.9c-0.02-0.13,0.31-0.45,0.41-0.42c0.26,0.07,0.47,0.29,0.7,0.44
	C315.57,293.02,315.41,293.24,315.12,293.66z"
              />
              <path
                d="M318.19,274.7c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.41-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C317.51,274.08,317.74,274.38,318.19,274.7z"
              />
              <path
                d="M226.51,391.04c-0.12,0.07-0.24,0.14-0.36,0.2c-0.05-0.13-0.17-0.28-0.14-0.39c0.02-0.09,0.22-0.14,0.34-0.21
	C226.4,390.77,226.45,390.91,226.51,391.04z"
              />
              <path
                d="M413.76,295.18c-0.34,0.27-0.54,0.54-0.65,0.51c-0.23-0.08-0.41-0.31-0.61-0.48c0.14-0.15,0.29-0.44,0.42-0.43
	C413.16,294.8,413.39,294.99,413.76,295.18z"
              />
              <path
                d="M322.59,251.7c0.27,0.42,0.48,0.64,0.53,0.89c0.02,0.13-0.3,0.46-0.4,0.43c-0.26-0.06-0.48-0.27-0.71-0.42
	C322.15,252.37,322.3,252.14,322.59,251.7z"
              />
              <path
                d="M319.99,270.54c0.4-0.1,0.67-0.23,0.91-0.2c0.12,0.02,0.2,0.35,0.3,0.53c-0.25,0.11-0.49,0.28-0.74,0.3
	C320.35,271.18,320.22,270.87,319.99,270.54z"
              />
              <path
                d="M237.22,426.1c-0.22,0.23-0.36,0.38-0.5,0.53c-0.13-0.15-0.3-0.28-0.37-0.46c-0.03-0.09,0.15-0.37,0.19-0.36
	C236.74,425.85,236.93,425.96,237.22,426.1z"
              />
              <path
                d="M257.82,172.26c-0.29-0.42-0.44-0.65-0.6-0.88c0.23-0.16,0.45-0.37,0.71-0.44c0.1-0.03,0.43,0.3,0.41,0.43
	C258.29,171.62,258.08,171.84,257.82,172.26z"
              />
              <path
                d="M312.91,296.77c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.42-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C312.23,296.16,312.46,296.45,312.91,296.77z"
              />
              <path
                d="M226.08,297.38c-0.39,0.09-0.66,0.23-0.91,0.19c-0.12-0.02-0.2-0.34-0.29-0.53c0.24-0.11,0.48-0.29,0.73-0.3
	C225.73,296.73,225.86,297.05,226.08,297.38z"
              />
              <path
                d="M409.17,292.7c-0.25-0.43-0.46-0.65-0.5-0.9c-0.02-0.13,0.31-0.45,0.41-0.42c0.26,0.07,0.47,0.29,0.7,0.44
	C409.62,292.06,409.46,292.28,409.17,292.7z"
              />
              <path
                d="M218.71,337.6c-0.38,0.14-0.62,0.29-0.84,0.28c-0.14-0.01-0.25-0.29-0.37-0.45c0.22-0.14,0.43-0.34,0.67-0.39
	C218.26,337.01,218.43,337.3,218.71,337.6z"
              />
              <path
                d="M317.52,267.34c-0.48,0.32-0.69,0.55-0.94,0.59c-0.16,0.03-0.55-0.27-0.53-0.33c0.07-0.26,0.23-0.61,0.43-0.67
	C316.68,266.86,316.97,267.1,317.52,267.34z"
              />
              <path
                d="M417.05,291.33c-0.47,0.31-0.67,0.53-0.9,0.57c-0.16,0.02-0.36-0.22-0.54-0.34c0.15-0.22,0.27-0.55,0.47-0.62
	C416.25,290.87,416.53,291.1,417.05,291.33z"
              />
              <path
                d="M410.6,299.16c-0.53,0.23-0.83,0.47-1.03,0.4c-0.2-0.07-0.35-0.42-0.43-0.67c-0.02-0.06,0.37-0.36,0.52-0.33
	C409.92,298.6,410.13,298.84,410.6,299.16z"
              />
              <path
                d="M324.51,266.98c-0.37,0.19-0.6,0.38-0.83,0.4c-0.13,0.01-0.28-0.27-0.43-0.42c0.2-0.17,0.38-0.4,0.61-0.48
	C323.96,266.43,324.17,266.7,324.51,266.98z"
              />
              <path
                d="M217.88,330.27c-0.24,0.19-0.48,0.47-0.54,0.43c-0.22-0.13-0.37-0.37-0.54-0.56c0.18-0.11,0.37-0.31,0.54-0.3
	C217.5,329.84,217.65,330.07,217.88,330.27z"
              />
              <path
                d="M221.53,326.36c-0.47,0.31-0.68,0.54-0.92,0.58c-0.16,0.03-0.54-0.27-0.52-0.33c0.07-0.25,0.22-0.59,0.42-0.66
	C220.71,325.89,221,326.13,221.53,326.36z"
              />
              <path
                d="M320.81,263.65c-0.51,0.22-0.79,0.45-0.98,0.38c-0.2-0.07-0.34-0.4-0.41-0.64c-0.02-0.06,0.35-0.35,0.5-0.32
	C320.16,263.11,320.37,263.34,320.81,263.65z"
              />
              <path
                d="M224.91,322.52c-0.48,0.32-0.69,0.55-0.94,0.59c-0.16,0.03-0.55-0.27-0.53-0.34c0.07-0.26,0.23-0.61,0.43-0.67
	C224.07,322.04,224.36,322.28,224.91,322.52z"
              />
              <path
                d="M265.21,163.84c-0.53,0.23-0.83,0.47-1.02,0.41c-0.2-0.06-0.36-0.41-0.43-0.67c-0.02-0.06,0.36-0.37,0.52-0.34
	C264.52,163.29,264.74,163.52,265.21,163.84z"
              />
              <path
                d="M96.35,633.33c-0.29,0.32-0.45,0.61-0.57,0.59c-0.26-0.04-0.49-0.23-0.74-0.35c0.13-0.17,0.24-0.45,0.38-0.47
	C95.67,633.07,95.93,633.21,96.35,633.33z"
              />
              <path
                d="M220.55,319.27c-0.23,0.18-0.45,0.46-0.55,0.42c-0.24-0.09-0.42-0.33-0.62-0.5c0.23-0.15,0.44-0.36,0.69-0.41
	C220.17,318.75,220.35,319.06,220.55,319.27z"
              />
              <path
                d="M223.5,308.38c-0.39,0.11-0.65,0.26-0.89,0.23c-0.13-0.02-0.23-0.32-0.34-0.5c0.24-0.13,0.46-0.32,0.71-0.36
	C223.09,307.74,223.24,308.05,223.5,308.38z"
              />
              <path
                d="M225.8,304.92c-0.16-0.19-0.44-0.43-0.41-0.5c0.12-0.22,0.35-0.39,0.54-0.57c0.16,0.22,0.31,0.45,0.47,0.67
	C226.22,304.65,226.03,304.77,225.8,304.92z"
              />
              <path
                d="M324.03,259.67c-0.34,0.27-0.54,0.54-0.65,0.51c-0.23-0.08-0.41-0.31-0.61-0.48c0.14-0.15,0.29-0.44,0.42-0.43
	C323.43,259.29,323.66,259.48,324.03,259.67z"
              />
              <path
                d="M228.95,286.03c-0.46,0.32-0.66,0.55-0.89,0.58c-0.16,0.03-0.37-0.21-0.55-0.33c0.15-0.22,0.26-0.55,0.46-0.63
	C228.14,285.59,228.42,285.82,228.95,286.03z"
              />
              <path
                d="M314.66,286.32c-0.23-0.31-0.44-0.5-0.5-0.72c-0.03-0.11,0.33-0.44,0.39-0.42c0.23,0.11,0.45,0.3,0.59,0.51
	C315.18,285.75,314.91,286,314.66,286.32z"
              />
              <path
                d="M321.43,277.4c0.09,0.24,0.25,0.5,0.23,0.73c-0.01,0.13-0.39,0.37-0.5,0.33c-0.22-0.1-0.49-0.34-0.53-0.56
	c-0.05-0.28,0.11-0.59,0.18-0.89C321.02,277.14,321.23,277.27,321.43,277.4z"
              />
              <path
                d="M224.15,315.3c-0.46,0.32-0.66,0.55-0.89,0.58c-0.16,0.03-0.37-0.21-0.55-0.33c0.15-0.22,0.26-0.55,0.46-0.63
	C223.34,314.86,223.63,315.09,224.15,315.3z"
              />
              <path
                d="M314.83,278.54c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.42-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C314.15,277.92,314.38,278.21,314.83,278.54z"
              />
              <path
                d="M241.93,310.21c-0.55,0.22-0.85,0.45-1.03,0.38c-0.21-0.08-0.32-0.43-0.47-0.66c0.19-0.12,0.41-0.37,0.58-0.34
	C241.25,309.63,241.45,309.87,241.93,310.21z"
              />
              <path
                d="M327.31,255.99c-0.52,0.21-0.76,0.38-0.99,0.37c-0.15,0-0.44-0.35-0.41-0.4c0.13-0.23,0.33-0.52,0.54-0.56
	C326.63,255.37,326.86,255.66,327.31,255.99z"
              />
              <path
                d="M326.75,633.51c0.25,0.16,0.41,0.26,0.57,0.36c-0.15,0.12-0.29,0.28-0.46,0.35c-0.09,0.03-0.25-0.1-0.37-0.16
	C326.56,633.89,326.64,633.73,326.75,633.51z"
              />
              <path
                d="M326.15,247.88c0.17,0.48,0.34,0.75,0.32,0.99c-0.01,0.14-0.41,0.39-0.52,0.34c-0.24-0.11-0.4-0.38-0.59-0.58
	C325.56,248.45,325.76,248.26,326.15,247.88z"
              />
              <path
                d="M318.49,281.56c-0.23,0.34-0.36,0.67-0.46,0.66c-0.26-0.02-0.5-0.19-0.75-0.31c0.1-0.19,0.18-0.5,0.31-0.53
	C317.83,281.34,318.1,281.47,318.49,281.56z"
              />
              <path
                d="M261.27,166.62c0.08,0.31,0.25,0.64,0.2,0.92c-0.04,0.23-0.33,0.48-0.57,0.58c-0.11,0.05-0.51-0.21-0.52-0.35
	c-0.02-0.24,0.15-0.51,0.25-0.76C260.85,166.88,261.06,166.75,261.27,166.62z"
              />
              <path
                d="M478.63,70.73c-0.17,0.14-0.33,0.28-0.5,0.43c-0.86,0.31-1.72,0.63-2.62,0.96c0.08,0.59,0.18,1.28,0.27,1.92
	c0.93,0,1.8,0,2.66,0c0.16,0.31,0.32,0.61,0.48,0.92c0.25,0.85,0.49,1.7,0.75,2.59c0.77-0.04,1.69-0.1,2.61-0.15
	c0.08,0.16,0.15,0.33,0.23,0.49c0.35,0.98,0.71,1.97,1.08,3.02c2.68-0.34,3.36,0.22,3.63,3.23c0.01,0.08,0.04,0.15,0.06,0.23
	c-0.07,0.06-0.13,0.12-0.19,0.18c-1.03,0.45-2.05,0.9-3.15,1.38c0,0.79,0,1.64,0,2.49c-0.24,0.31-0.48,0.62-0.72,0.94
	c-0.84,0-1.68,0-2.54,0c0,0.97,0,1.74,0,2.33c1.13,0.19,2.16,0.37,3.2,0.55c0.17,0.08,0.35,0.16,0.52,0.24
	c0.02,1.04,0.04,2.08,0.05,3.12c-0.17,0.15-0.33,0.3-0.5,0.46c-0.89,0.25-1.78,0.51-2.66,0.76c0.06,0.76,0.12,1.52,0.17,2.07
	c1.02,0.23,2.18,0.19,2.86,0.73c0.69,0.55,0.92,1.68,1.39,2.64c0.78,0,1.64,0,2.51,0c0.18,0.19,0.36,0.37,0.54,0.56
	c0.35,1.11,0.48,2.22-0.01,3.32c-0.12,0.16-0.23,0.32-0.35,0.48c-1.02,0-2.03,0-3.05,0l0.07,0.06c-0.49-0.93-0.98-1.85-1.54-2.93
	c-0.67,0-1.59,0-2.52,0c-0.17-0.3-0.34-0.61-0.51-0.91c-0.18-0.83-0.36-1.66-0.53-2.45c-0.95,0-1.88,0-2.81,0
	c-0.17-0.3-0.33-0.61-0.5-0.91c-0.16-0.9-0.33-1.81-0.48-2.63c-0.89,0.06-1.81,0.13-2.72,0.19c-0.16-0.19-0.32-0.38-0.49-0.57
	c-0.37-0.85-0.73-1.69-1.14-2.64c-0.55,0.05-1.22,0.11-1.78,0.17c-0.13,1.06-0.25,2.05-0.37,3.05c-0.11,0.15-0.22,0.31-0.33,0.46
	c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.61-0.93-0.89-2.05-0.11-2.85c0.68-0.7,1.78-0.99,2.68-1.46
	c0.1-0.82,0.21-1.81,0.33-2.8c0.11-0.16,0.23-0.32,0.34-0.48c0.92-0.08,1.84-0.16,2.8-0.24c-0.09-0.87-0.17-1.7-0.25-2.42
	c-1.01-0.09-1.93-0.16-2.86-0.24c-0.17-0.15-0.33-0.3-0.5-0.45c-0.49-1.12-0.46-2.25-0.04-3.39c0.94-0.31,1.88-0.62,2.83-0.93
	c0-0.7,0-1.47,0-2.3c-0.9,0.04-1.71,0.08-2.44,0.12c-0.11,1.07-0.22,2.12-0.33,3.17c-1.11,0.44-2.2,0.99-3.42,0.38
	c0,0,0.06,0.05,0.06,0.05c-0.49-0.94-0.99-1.88-1.57-2.99c-0.42,0.05-1.09,0.12-1.83,0.21c0,1.02,0,1.89,0,2.76
	c-0.23,0.31-0.47,0.62-0.7,0.94c-0.92,0-1.84,0-2.75-0.01c-0.15-0.19-0.3-0.39-0.45-0.58c-0.69-0.75-0.99-1.58-0.6-2.6
	c0.53-1.4,1.81-1.55,3.05-1.69c0-1.05,0-1.91,0-2.77c0.17-0.18,0.35-0.35,0.52-0.53c2.25-0.83,3.74-0.7,4.43,2.8
	c0.75-0.04,1.51-0.07,2.2-0.1c0.06-0.97,0.11-1.83,0.16-2.69c0.14-0.18,0.28-0.36,0.42-0.54c0.95-0.27,1.9-0.54,2.86-0.81
	c-0.17-2.93,0.16-3.33,2.96-3.7c0.13-1.04,0.25-2.03,0.37-3.03c0.12-0.17,0.25-0.34,0.37-0.51
	C478.14,66.92,478.78,67.48,478.63,70.73z M473.01,85.22c1.09,0.17,2.18,0.03,2.7,0.5c0.73,0.66,1.05,1.78,1.59,2.78
	c0.65-0.07,1.4-0.16,2.14-0.24c-0.14-0.86-0.25-1.54-0.35-2.19c-2.98-0.1-3.29-0.37-3.96-3.39c-0.74,0.13-1.48,0.27-2.21,0.4
	C472.95,83.92,472.98,84.62,473.01,85.22z M472.14,75.66c0.07,0.93,0.13,1.75,0.19,2.56c0.91,0,1.81,0,2.73,0
	c-0.16-0.92-0.3-1.75-0.44-2.56C473.78,75.66,472.95,75.66,472.14,75.66z M476.24,81.51c0.81-0.16,1.61-0.31,2.43-0.47
	c-0.06-0.91-0.12-1.67-0.17-2.38c-0.96,0.09-1.8,0.17-2.61,0.24C476.02,79.83,476.13,80.66,476.24,81.51z M477.34,93.3
	c0,0.97,0,1.8,0,2.66c0.93-0.2,1.75-0.38,2.56-0.56c-0.14-0.81-0.25-1.48-0.38-2.23C478.7,93.22,478.01,93.26,477.34,93.3z
	 M482.37,84.5c0-0.81,0-1.64,0-2.55c-0.81,0.15-1.54,0.28-2.28,0.42c0.06,0.92,0.12,1.68,0.17,2.42
	C481,84.68,481.63,84.6,482.37,84.5z M473.32,90.07c0.07,0.82,0.14,1.59,0.21,2.35c0.89-0.08,1.71-0.16,2.54-0.24
	c-0.13-0.77-0.25-1.45-0.36-2.11C474.89,90.07,474.14,90.07,473.32,90.07z"
              />
              <path
                d="M618.26,347.74c-0.06,0.08-0.12,0.16-0.18,0.23c-1.05,0.17-2.11,0.33-3.16,0.5c-0.19-0.21-0.38-0.42-0.56-0.63
	c-0.36-0.83-0.73-1.66-1.12-2.55c-0.55,0.08-1.22,0.17-1.7,0.24c-0.15,1.04-0.27,1.94-0.4,2.84c-0.14,0.19-0.29,0.38-0.43,0.57
	c-0.98,0.01-1.96,0.02-2.95,0.03c-0.18-0.19-0.36-0.38-0.54-0.57c-0.09-0.9-0.58-2.07-0.19-2.62c0.58-0.81,1.79-1.18,2.65-1.69
	c0.13-0.85,0.27-1.76,0.41-2.67c0.15-0.2,0.31-0.4,0.46-0.59c0.9-0.1,1.81-0.21,2.71-0.32c-0.08-0.84-0.16-1.66-0.23-2.31
	c-1.02-0.09-1.93-0.17-2.84-0.26c-0.18-0.16-0.36-0.32-0.53-0.48c-0.52-1.14-0.48-2.28,0.03-3.42l-0.08,0.09
	c0.93-0.33,1.87-0.65,2.84-0.99c0.05-0.74,0.1-1.59,0.15-2.43c0.15-0.19,0.3-0.38,0.45-0.56c1.07-0.83,2.26-0.49,3.43-0.35
	c0.12,0.06,0.24,0.12,0.35,0.19c0.19,0.91,0.38,1.83,0.55,2.68c0.9,0,1.76,0,2.61,0c0.18,0.3,0.37,0.61,0.55,0.91
	c0.54,0.87,0.87,1.92,0.12,2.64c-0.79,0.75-1.92,1.12-2.94,1.69c0.09,0.57,0.2,1.35,0.29,1.96c1.07,0.11,2.07,0.21,3.07,0.31
	c0.16,0.2,0.32,0.4,0.48,0.61c0.03,0.97,0.06,1.94,0.09,2.9c-0.24,0.31-0.47,0.63-0.71,0.94c-0.84,0-1.69,0-2.52,0
	C618.35,345.86,618.3,346.8,618.26,347.74z M613.98,334.46c0.06,0.91,0.1,1.68,0.15,2.44c0.88-0.07,1.64-0.12,2.43-0.18
	c-0.14-0.89-0.27-1.71-0.4-2.54C615.42,334.28,614.79,334.36,613.98,334.46z M614.65,343.81c1-0.09,1.76-0.15,2.56-0.22
	c-0.17-0.83-0.31-1.5-0.43-2.09c-0.84,0-1.54,0-2.33,0C614.51,342.24,614.57,342.94,614.65,343.81z"
              />
              <path
                d="M517.97,147.42c-0.18-0.2-0.36-0.4-0.53-0.61c-0.37-0.83-0.73-1.65-1.1-2.49c-0.81-0.08-1.72-0.16-2.63-0.25
	c-0.17-0.15-0.33-0.3-0.5-0.45c-0.16-0.91-0.31-1.82-0.47-2.71c-0.89-0.06-1.89-0.13-2.89-0.2c-0.16-0.14-0.32-0.29-0.48-0.43
	c-0.05-1.11-0.09-2.23-0.14-3.34c0.2-0.17,0.39-0.34,0.59-0.51c0.76-0.11,1.53-0.22,2.27-0.32c0.08-0.93,0.15-1.77,0.23-2.62
	c0.15-0.19,0.29-0.38,0.44-0.56c0.94-0.26,1.88-0.53,2.78-0.78c0.04-0.78,0.09-1.63,0.13-2.49c0.15-0.19,0.3-0.37,0.45-0.56
	c1.08-0.84,2.28-0.48,3.45-0.35c0.11,0.06,0.22,0.12,0.33,0.18c0.19,0.91,0.38,1.82,0.56,2.69c0.91,0,1.76,0,2.61,0
	c0.17,0.3,0.33,0.61,0.5,0.91c0.56,0.76,0.94,1.71,0.28,2.42c-0.74,0.8-1.8,1.31-2.91,2.07c0.02,0.43,0.06,1.19,0.09,1.71
	c1.15,0.19,2.15,0.36,3.15,0.52c0.13,0.06,0.26,0.12,0.38,0.19c0.05,1.06,0.11,2.13,0.16,3.19c-0.24,0.31-0.47,0.62-0.71,0.94
	c-0.83,0-1.66,0-2.39,0c-0.21,1.15-0.37,2.04-0.53,2.92c-0.06,0.09-0.12,0.18-0.18,0.27C519.93,146.98,518.95,147.2,517.97,147.42z
	 M516.27,140c-0.06-1.02-0.12-2.04-0.18-3.1c-0.95,0.16-1.64,0.28-2.32,0.39c0,0.81,0,1.58,0,2.2c0.92,0.18,1.73,0.34,2.53,0.49
	c1.62,0.28,0.97,1.92,1.6,2.77c0.82-0.09,1.57-0.18,2.32-0.27c-0.14-0.85-0.26-1.52-0.37-2.19C518.59,140.2,517.42,140.1,516.27,140
	z M519.25,135.35c0-0.72,0-1.45,0-2.17c-0.95,0.05-1.72,0.09-2.5,0.13c0.11,0.83,0.22,1.59,0.32,2.35
	C517.84,135.55,518.53,135.45,519.25,135.35z"
              />
              <path
                d="M540.34,199.7c-0.16-0.31-0.32-0.61-0.48-0.92c-0.08-1.05-0.17-2.1-0.25-3.15c0.06-0.07,0.13-0.14,0.19-0.21
	c0.97-0.21,1.95-0.42,2.77-0.6c0.13-0.95,0.24-1.85,0.36-2.75c0.16-0.17,0.31-0.33,0.47-0.5c1.15-0.73,2.29-0.39,3.43,0.03
	l-0.1-0.08c0.28,0.94,0.56,1.87,0.82,2.73c0.92,0.09,1.76,0.17,2.6,0.25c0.15,0.14,0.31,0.28,0.46,0.41
	c0.57,0.94,0.79,1.91,0.22,2.93c-0.69,1.22-1.93,1.13-3.14,1.12c0.24,0.95,0.44,1.76,0.65,2.59c0.97,0,1.84,0,2.71,0
	c0.18,0.19,0.36,0.37,0.54,0.56c0,1.1,0,2.19,0.01,3.29c-0.13,0.17-0.26,0.35-0.39,0.52c-2.5,0.55-3.7,0.42-4.22-2.88
	c-0.92,0-1.86,0-2.8,0c-0.17-0.3-0.33-0.61-0.5-0.91c-0.18-0.87-0.37-1.73-0.52-2.44C542.16,199.7,541.25,199.7,540.34,199.7z
	 M546.75,198.21c-0.19-0.9-0.35-1.7-0.51-2.49c-0.83,0.11-1.51,0.21-2.14,0.3c0.09,0.92,0.16,1.72,0.23,2.47
	C545.12,198.41,545.81,198.32,546.75,198.21z"
              />
              <path
                d="M556.34,179.49c0.18,0.19,0.36,0.38,0.55,0.57c0.29,1.08,0.56,2.16,0.01,3.25c-0.15,0.19-0.29,0.37-0.44,0.56
	c-0.9,0.11-1.79,0.22-2.53,0.32c-0.16,1.05-0.31,2.08-0.46,3.11c0,0,0.08-0.09,0.08-0.09c-0.93,0.34-1.87,0.68-2.85,1.05
	c-0.06,0.69-0.14,1.54-0.21,2.39c-0.11,0.16-0.23,0.32-0.34,0.48c-1.04,0.92-2.22,0.64-3.39,0.41c0,0,0.1,0.08,0.1,0.08
	c-0.39-0.93-0.79-1.87-1.2-2.85c-0.68-0.11-1.45-0.23-2.22-0.35c-0.16-0.07-0.31-0.14-0.47-0.21c-0.67-0.82-1.09-1.68-0.63-2.79
	c0.56-1.35,1.83-1.48,3.01-1.65c0-1.04,0-1.89,0-2.74c0.18-0.18,0.35-0.36,0.53-0.54c2.99-0.79,3.7-0.35,4.4,2.72
	c0.76,0,1.52,0,2.22,0c0.05-0.98,0.1-1.84,0.14-2.69c0.15-0.19,0.3-0.37,0.45-0.56C554.16,179.8,555.25,179.65,556.34,179.49z
	 M546.74,184.96c0.08,0.81,0.16,1.5,0.23,2.24c0.78-0.07,1.48-0.12,2.24-0.19c-0.06-0.79-0.12-1.47-0.18-2.28
	C548.23,184.81,547.56,184.88,546.74,184.96z"
              />
              <path
                d="M551.8,125.85c-0.06,0.07-0.13,0.14-0.19,0.21c-0.87,0.34-1.74,0.68-2.65,1.03c0.05,0.69,0.12,1.51,0.17,2.26
	c0.99-0.05,1.86-0.09,2.73-0.14c0.16,0.3,0.32,0.61,0.49,0.91c0.3,0.77,0.61,1.54,0.93,2.36c0.88,0,1.74,0,2.61,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.36,1.11,0.47,2.23,0,3.34c-0.11,0.15-0.22,0.31-0.33,0.46c-1.02,0-2.04,0-3.06,0
	c0,0,0.07,0.06,0.07,0.06c-0.49-0.93-0.97-1.87-1.53-2.93c-0.69,0-1.62,0-2.55,0c-0.16-0.3-0.33-0.61-0.49-0.91
	c-0.18-0.83-0.35-1.67-0.52-2.44c-0.96,0-1.9,0-2.83,0c-0.16-0.31-0.32-0.61-0.49-0.92c-0.09-0.96-0.19-1.93-0.28-2.89
	c0.16-0.13,0.31-0.26,0.47-0.4c0.87-0.26,1.75-0.51,2.67-0.78c0-0.71,0-1.46,0-2.21c0.24-0.31,0.48-0.63,0.71-0.94
	c1.11-0.67,2.21-0.5,3.32-0.01c0.16,0.07,0.33,0.15,0.49,0.22C551.97,123.74,551.88,124.8,551.8,125.85z"
              />
              <path
                d="M529.79,84.11c0.16,0.31,0.32,0.61,0.48,0.92c0.58,0.75,0.95,1.7,0.29,2.41c-0.73,0.8-1.79,1.3-2.97,2.12
	c0,0.48,0,1.35,0,2.22c-0.23,0.31-0.47,0.62-0.7,0.94c-0.31-0.01-0.62-0.03-0.93-0.03c-2.39,0.02-3.09-0.78-2.89-3.33
	c0.08-0.17,0.16-0.35,0.23-0.52c0.89-0.4,1.79-0.8,2.76-1.23c0-0.61,0-1.36,0-2.04c-1.01-0.1-1.9-0.2-2.8-0.29
	c-0.11-0.06-0.22-0.12-0.33-0.18c-0.33-0.92-0.65-1.84-0.95-2.67c-0.85-0.08-1.7-0.16-2.54-0.24c-0.17-0.18-0.35-0.37-0.52-0.55
	c-0.1-0.95-0.2-1.89-0.3-2.84c0.11-0.16,0.23-0.31,0.34-0.47c1.1-0.18,2.21-0.36,3.31-0.54c0.15,0.18,0.31,0.36,0.46,0.55
	c0.2,0.83,0.41,1.66,0.6,2.44c0.89,0,1.75,0,2.61,0c0.16,0.31,0.32,0.61,0.48,0.92c0.25,0.84,0.5,1.68,0.77,2.57
	C527.96,84.2,528.87,84.15,529.79,84.11z"
              />
              <path
                d="M500.8,79.79c0.13,0.07,0.25,0.15,0.37,0.24c0.19,0.89,0.38,1.79,0.56,2.65c0.9,0,1.73,0,2.57,0c0.2,0.3,0.4,0.6,0.6,0.91
	c0.49,0.78,0.89,1.73,0.23,2.44c-0.74,0.79-1.8,1.29-2.97,2.09c0,0.49,0,1.35,0,2.22c-0.23,0.31-0.47,0.62-0.7,0.94
	c-3.22,0.01-3.56-0.25-4.18-3.1c-0.93-0.08-1.86-0.16-2.78-0.23c-0.16-0.14-0.32-0.29-0.48-0.43c-0.47-1.16-0.44-2.31,0.02-3.46
	l-0.08,0.09c0.94-0.31,1.88-0.62,2.82-0.93c0.07-0.81,0.15-1.66,0.22-2.5c0.12-0.17,0.25-0.34,0.38-0.5
	C498.44,79.36,499.63,79.64,500.8,79.79z M500.58,84.17c-0.85,0.11-1.56,0.21-2.32,0.31c0.1,0.82,0.18,1.49,0.26,2.2
	c0.79-0.11,1.46-0.2,2.21-0.3C500.68,85.61,500.63,84.94,500.58,84.17z"
              />
              <path
                d="M553.07,179.95c-0.15,0.19-0.3,0.37-0.45,0.56c-2.38,0.64-3.71,0.39-4.2-2.89c-0.91,0-1.85,0-2.78,0
	c-0.17-0.3-0.33-0.61-0.5-0.91c-0.08-1.04-0.16-2.08-0.24-3.12c0.06-0.08,0.12-0.16,0.18-0.24c1.06-0.13,2.12-0.25,3.18-0.38
	c0.17,0.13,0.35,0.25,0.52,0.38c0.38,0.91,0.75,1.82,1.12,2.7c0.71,0,1.41,0,2.11,0c-0.05-3.25,0.24-3.59,3.05-3.97
	c0.12-1.04,0.23-2.03,0.34-3.03c0.12-0.17,0.24-0.33,0.36-0.5c1.01,0.02,2.03,0.04,3.04,0.06c0.15,0.13,0.29,0.26,0.44,0.39
	c0.16,0.98,0.32,1.96,0.48,2.94c-0.19,0.16-0.37,0.32-0.56,0.48c-0.84,0.29-1.68,0.58-2.42,0.83c-0.11,0.95-0.23,1.93-0.35,2.9
	c-0.13,0.17-0.25,0.33-0.38,0.5c-0.92,0.11-1.83,0.21-2.62,0.3C553.29,177.99,553.18,178.97,553.07,179.95z"
              />
              <path
                d="M633.91,450.7c0.08,0.16,0.15,0.33,0.23,0.49c0.87,0.99,1.07,2.09,0.48,3.29c-0.17,0.17-0.34,0.35-0.51,0.52
	c-0.78,0.24-1.57,0.49-2.39,0.74c0,0.91,0,1.76,0,2.61c-0.23,0.31-0.46,0.62-0.69,0.94c-0.31-0.01-0.62-0.04-0.93-0.04
	c-2.39,0.02-3.07-0.76-2.91-3.32c0.07-0.15,0.14-0.31,0.21-0.46c0.95-0.43,1.91-0.87,2.9-1.32c-0.06-0.63-0.12-1.29-0.17-1.81
	c-1.05-0.09-1.89-0.16-2.74-0.24c-0.17-0.18-0.33-0.35-0.5-0.53c-0.62-0.9-0.64-1.86-0.28-2.86c0.1-0.15,0.2-0.3,0.31-0.44
	c1.08-0.93,2.34-0.49,3.54-0.45c0.1,0.06,0.19,0.12,0.28,0.18c0.18,0.95,0.36,1.9,0.54,2.8
	C632.24,450.78,633.07,450.74,633.91,450.7z"
              />
              <path
                d="M482.51,77.88c-0.08-0.16-0.15-0.33-0.23-0.49c-0.08-1.02-0.16-2.03-0.24-3.05c0.09-0.11,0.19-0.21,0.3-0.3
	c0.91-0.22,1.83-0.43,2.82-0.67c0-0.74,0-1.5,0-2.26c0.23-0.31,0.45-0.62,0.68-0.93c1.15-0.73,2.29-0.4,3.43,0.03
	c0,0-0.09-0.08-0.09-0.08c0.16,1.14,0.32,2.28,0.48,3.41c-0.14,0.13-0.28,0.25-0.42,0.38c-0.88,0.27-1.75,0.54-2.7,0.82
	c0.09,0.65,0.19,1.31,0.3,2.08c0.97,0,1.9,0,2.83,0c0.17,0.18,0.34,0.36,0.5,0.54c0.36,1.12,0.46,2.24,0,3.36
	c-0.1,0.15-0.21,0.3-0.31,0.45c-2.75,0.52-3.87,0.27-4.19-2.77C484.57,78.22,483.54,78.05,482.51,77.88z"
              />
              <path
                d="M605.23,320.62c-0.15-0.19-0.3-0.38-0.45-0.58c-0.96-0.96-0.92-2.05-0.42-3.21c0.16-0.17,0.32-0.34,0.48-0.5
	c1.15-0.74,2.29-0.4,3.42,0.04c0,0-0.09-0.08-0.09-0.08c0.29,0.93,0.57,1.86,0.84,2.72c0.91,0.09,1.75,0.17,2.58,0.25
	c0.16,0.15,0.33,0.3,0.49,0.44c1.06,2.82,0.44,3.81-2.52,4.14c-0.12,1.01-0.24,1.99-0.36,2.98c-0.13,0.18-0.26,0.35-0.4,0.53
	c-0.45,0.08-0.91,0.21-1.36,0.23c-1.55,0.09-2.49-0.72-2.63-2.26c-0.04-0.46,0.04-0.93,0.07-1.39c0.14-0.13,0.28-0.26,0.42-0.38
	c0.87-0.3,1.73-0.61,2.71-0.95c-0.1-0.58-0.22-1.24-0.35-1.97C606.78,320.62,606.01,320.62,605.23,320.62z"
              />
              <path
                d="M513.21,143.63c0.17,0.15,0.33,0.3,0.5,0.45c1.03,2.83,0.43,3.77-2.54,4.16c-0.12,1.03-0.24,2.02-0.36,3
	c-0.11,0.16-0.23,0.31-0.34,0.47c-1.01,0.01-2.02,0.02-3.02,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.25-0.79-0.51-1.59-0.76-2.36
	c-0.94,0-1.87,0-2.8,0c-0.16-0.31-0.31-0.61-0.47-0.92c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19
	c1.06-0.1,2.12-0.19,3.18-0.29c0.15,0.09,0.31,0.18,0.46,0.28c0.39,0.9,0.78,1.81,1.21,2.79c0.59-0.04,1.26-0.09,1.82-0.13
	c0.08-1.1,0.15-2.09,0.22-3.09c0.19-0.16,0.37-0.32,0.56-0.48C511.27,143.64,512.24,143.63,513.21,143.63z"
              />
              <path
                d="M568.32,149.72c0.14,0.19,0.29,0.37,0.43,0.56c-0.05,0.98-0.11,1.95-0.16,2.93c-0.06,0.06-0.13,0.12-0.19,0.18
	c-0.98,0.25-1.97,0.49-2.95,0.74c-0.16-0.18-0.31-0.37-0.47-0.56c-0.36-0.85-0.72-1.7-1.13-2.67c-0.54,0.05-1.23,0.11-1.8,0.16
	c-0.13,1.05-0.25,2.05-0.37,3.04c-0.12,0.16-0.24,0.33-0.37,0.49c-1,0.01-2,0.02-3,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-1.03-2.74-0.42-3.65,2.59-4.06c0.07-1.03,0.15-2.02,0.22-3.02c0.19-0.16,0.37-0.32,0.56-0.48c0.99,0.07,2.21-0.2,2.89,0.29
	c0.73,0.53,0.95,1.77,1.32,2.54C566.35,149.48,567.34,149.6,568.32,149.72z"
              />
              <path
                d="M556.06,136.84c0.11-0.15,0.22-0.31,0.33-0.46c1.1-0.18,2.21-0.37,3.31-0.55c0.18,0.19,0.36,0.38,0.54,0.57
	c0.38,1.14,0.43,2.27-0.06,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.9,0.66-2.93,1.01c0,0.71,0,1.58,0,2.44
	c-0.19,0.16-0.38,0.32-0.58,0.49c-0.97,0.98-2.15,0.53-3.27,0.45c-0.37-0.94-0.75-1.88-1.16-2.94c-0.7,0-1.49,0-2.28,0
	c-0.15-0.2-0.29-0.4-0.44-0.6c-0.96-1.01-1.09-2.12-0.23-3.22c0.78-0.99,1.84-1.17,3.01-0.67c0.22,0.09,0.45,0.15,0.68,0.22
	l-0.07-0.06c0.28,0.94,0.56,1.89,0.84,2.86c0.7-0.07,1.38-0.14,1.97-0.2C555.9,138.51,555.98,137.68,556.06,136.84z"
              />
              <path
                d="M449.5,73.98c0.13-0.17,0.25-0.34,0.38-0.5c1.05-0.8,2.23-0.52,3.39-0.39c0,0-0.09-0.08-0.09-0.08c0.39,1,0.79,2,1.15,2.94
	c0.8,0,1.66,0,2.52,0c0.15,0.31,0.31,0.61,0.46,0.92c0.57,0.73,0.76,1.49,0.38,2.39c-0.56,1.35-1.85,1.44-3.06,1.65
	c0,1.01,0,1.86,0,2.71c-0.15,0.16-0.3,0.32-0.46,0.49c-1.14,0.41-2.29,0.84-3.35-0.13c-1.03-0.95-0.99-2.09-0.45-3.27
	c0,0-0.05,0.07-0.05,0.07c0.95-0.44,1.91-0.89,2.92-1.36c-0.05-0.6-0.11-1.26-0.15-1.8c-1.05-0.09-1.89-0.16-2.73-0.24
	c-0.17-0.18-0.34-0.36-0.52-0.55C449.72,75.88,449.61,74.93,449.5,73.98z"
              />
              <path
                d="M546.28,100.98c-0.15,0.13-0.3,0.26-0.46,0.39c-0.91,0.33-1.82,0.65-2.73,0.98c0.12,0.68,0.24,1.35,0.35,1.92
	c0.94,0,1.8,0,2.65,0c0.16,0.31,0.32,0.61,0.48,0.92c0.77,0.9,0.67,1.86,0.23,2.86c-0.18,0.18-0.35,0.36-0.53,0.54
	c-0.92,0.58-1.88,0.58-2.87,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.34-0.92-0.68-1.84-0.99-2.69c-0.84-0.07-1.69-0.14-2.53-0.22
	c-0.17-0.18-0.34-0.36-0.51-0.54c-1.02-2.74-0.42-3.64,2.6-4.06c0.07-1.03,0.15-2.03,0.22-3.03c0.18-0.15,0.37-0.31,0.55-0.46
	c0.98-0.01,1.95-0.02,2.93-0.03l-0.09-0.08C546.33,98.45,546.51,99.65,546.28,100.98z"
              />
              <path
                d="M518.97,78.31c-0.11,0.16-0.23,0.31-0.34,0.47c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.53
	c-1.03-2.74-0.42-3.65,2.6-4.07c0.07-1.03,0.15-2.03,0.22-3.03c0.18-0.15,0.37-0.31,0.55-0.46c0.97,0.01,1.95,0.02,2.92,0.02
	c0.14,0.13,0.29,0.26,0.43,0.38c0.28,0.88,0.55,1.76,0.76,2.42c1.04,0.11,2.04,0.22,3.04,0.33c0.15,0.19,0.3,0.39,0.45,0.58
	c-0.06,0.98-0.11,1.95-0.17,2.93c-0.06,0.06-0.13,0.12-0.19,0.18c-0.84,0.82-1.9,0.77-2.95,0.74c-0.15-0.18-0.31-0.36-0.46-0.55
	c-0.38-0.89-0.76-1.78-1.16-2.7c-0.62,0.06-1.29,0.13-1.78,0.18C519.2,76.36,519.08,77.34,518.97,78.31z"
              />
              <path
                d="M501.19,159.63c-0.16-0.07-0.33-0.15-0.49-0.22c-0.89-1.08-1.19-2.24-0.22-3.4c0.88-1.06,2.02-1.05,3.21-0.55
	c0.14,0.06,0.3,0.1,0.44,0.15c0,0-0.07-0.06-0.07-0.06c0.28,0.95,0.56,1.89,0.84,2.87c0.71-0.07,1.38-0.14,1.97-0.2
	c0.09-0.99,0.17-1.83,0.25-2.67c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.21-0.35,3.32-0.53c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.28-0.05,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.91,0.65-2.94,1.01c0,0.71,0,1.58,0,2.46
	c-0.19,0.16-0.38,0.32-0.57,0.48c-1,0.95-2.16,0.57-3.3,0.39c0,0,0.1,0.08,0.1,0.08c-0.39-0.93-0.79-1.87-1.2-2.85
	C502.73,159.87,501.96,159.75,501.19,159.63z"
              />
              <path
                d="M488.39,106.61c0.12-0.16,0.23-0.32,0.35-0.48c1.11-0.17,2.21-0.34,3.32-0.52c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.28-0.05,3.4l0.08-0.09c-0.97,0.34-1.94,0.68-2.94,1.03c0,0.76,0,1.6,0,2.44c-0.18,0.15-0.37,0.31-0.55,0.46
	c-1.01,0.94-2.17,0.57-3.31,0.4c0,0,0.1,0.08,0.1,0.08c-0.39-0.94-0.78-1.87-1.19-2.85c-0.69-0.1-1.46-0.22-2.24-0.33
	c-0.18-0.08-0.35-0.16-0.53-0.25c-0.86-1.09-1.14-2.25-0.18-3.4c0.88-1.05,2.02-1.04,3.2-0.54c0.14,0.06,0.29,0.1,0.44,0.16
	c0,0-0.07-0.06-0.07-0.06c0.28,0.95,0.56,1.89,0.84,2.87c0.71-0.07,1.39-0.13,1.98-0.19C488.24,108.3,488.32,107.45,488.39,106.61z"
              />
              <path
                d="M487.76,135.43c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.43-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09
	c0.96-0.3,1.92-0.6,2.84-0.89c0.08-0.84,0.17-1.7,0.25-2.55c0.1-0.15,0.2-0.3,0.31-0.44c1.02-0.89,2.23-0.51,3.38-0.51
	c0.36,1.01,0.72,2.02,1.08,3.05c0.8-0.04,1.66-0.08,2.52-0.12c0.15,0.31,0.31,0.61,0.46,0.92c0.81,1,0.79,2.05-0.01,3
	c-0.8,0.95-1.85,1.09-2.99,0.6c-0.22-0.09-0.44-0.17-0.66-0.25l0.08,0.06c-0.27-0.94-0.55-1.88-0.82-2.83
	c-0.74,0.07-1.42,0.13-2.02,0.19c-0.08,0.93-0.16,1.78-0.23,2.64c-0.11,0.15-0.21,0.31-0.32,0.46
	C490.11,135.86,488.93,135.64,487.76,135.43z"
              />
              <path
                d="M573.65,163.53c-0.07,0.06-0.13,0.12-0.2,0.18c-1.03,0.45-2.06,0.9-3.15,1.37c0,0.8,0,1.65,0,2.49
	c-0.24,0.31-0.48,0.63-0.72,0.94c-0.89-0.08-1.77-0.17-2.66-0.25c-0.16-0.07-0.31-0.14-0.47-0.21c-0.67-0.83-1.09-1.68-0.63-2.79
	c0.57-1.35,1.83-1.48,3.02-1.65c0-1.04,0-1.89,0-2.74c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43
	c0.16,0.31,0.32,0.61,0.49,0.92C573.2,161.73,573.42,162.63,573.65,163.53z"
              />
              <path
                d="M564.57,269.29c-0.16,0.15-0.33,0.3-0.49,0.44c-0.88,0.25-1.76,0.51-2.54,0.73c-0.11,0.87-0.22,1.76-0.34,2.64
	c-0.17,0.17-0.34,0.34-0.51,0.52c-1.09,0.71-2.2,0.48-3.31,0.07c-0.08-0.18-0.16-0.36-0.25-0.53c-0.09-1.06-0.17-2.12-0.26-3.17
	c0.07-0.06,0.13-0.12,0.19-0.18c0.96-0.21,1.93-0.43,2.9-0.65c0-0.77,0-1.53,0-2.28c0.25-0.31,0.5-0.63,0.74-0.94
	c1.14-0.74,2.27-0.41,3.39,0.05l-0.09-0.08C564.2,267.04,564.39,268.17,564.57,269.29z"
              />
              <path
                d="M491.12,134.96c0.11-0.15,0.21-0.31,0.32-0.46c1.03,0,2.05,0,3.08,0l-0.08-0.06c0.48,1.01,0.97,2.01,1.4,2.92
	c0.92,0.15,1.96,0.31,2.99,0.48c0.09,0.03,0.18,0.07,0.27,0.11c0.05,1.08,0.11,2.16,0.16,3.25c-0.23,0.31-0.45,0.62-0.68,0.93
	c-0.89-0.08-1.79-0.17-2.68-0.25c-0.16-0.07-0.31-0.14-0.47-0.21c-0.4-0.9-0.79-1.79-1.18-2.66c-0.83-0.07-1.75-0.14-2.66-0.22
	c-0.15-0.13-0.3-0.27-0.45-0.4C490.74,137.25,490.67,136.11,491.12,134.96z"
              />
              <path
                d="M548.23,122.47c-0.24,0.31-0.48,0.63-0.71,0.94c-3.22,0.01-3.55-0.24-4.18-3.1c-0.95-0.08-1.88-0.16-2.8-0.24
	c-0.15-0.13-0.3-0.26-0.44-0.4c-0.4-1.14-0.48-2.28-0.03-3.43c0.11-0.15,0.21-0.31,0.32-0.46c1.02,0.07,2.05,0.15,3.07,0.22
	l0.1,0.09c0.42,0.86,0.83,1.73,1.22,2.53c0.89,0.15,1.93,0.32,2.98,0.49c0.15,0.07,0.31,0.14,0.46,0.21
	C548.22,120.37,548.23,121.42,548.23,122.47z"
              />
              <path
                d="M455.12,90.81c-0.23,0.31-0.45,0.62-0.68,0.93c-0.91,0-1.82,0-2.73,0c-0.15-0.18-0.31-0.36-0.46-0.54
	c-0.36-0.85-0.73-1.71-1.1-2.59c-0.84-0.07-1.77-0.14-2.71-0.22c-0.15-0.13-0.29-0.26-0.44-0.39c-0.04-1.13-0.08-2.26-0.12-3.39
	c0.19-0.16,0.37-0.31,0.56-0.47c3.12-0.4,3.63-0.03,4.02,2.79c1.12,0.18,2.17,0.35,3.22,0.52c0.09,0.03,0.19,0.07,0.28,0.11
	C455.02,88.65,455.07,89.73,455.12,90.81z"
              />
              <path
                d="M507.45,155.09c-0.11,0.15-0.22,0.31-0.33,0.46c-1.02,0-2.04,0-3.06,0l0.07,0.06c-0.49-0.94-0.97-1.88-1.52-2.93
	c-0.7,0-1.64,0-2.57,0c-0.16-0.31-0.32-0.61-0.47-0.92c-0.1-0.96-0.2-1.92-0.3-2.88c0.18-0.18,0.35-0.36,0.53-0.54
	c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.31,0.31,0.61,0.47,0.92c0.31,0.77,0.62,1.54,0.95,2.36c0.87,0,1.74,0,2.6,0
	c0.17,0.18,0.34,0.36,0.51,0.54C507.81,152.86,507.92,153.97,507.45,155.09z"
              />
              <path
                d="M466.15,96.98c0.17,0.18,0.34,0.36,0.51,0.54c0.36,1.11,0.47,2.23,0,3.34c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.99-0.03-2.2,0.28-2.91-0.18c-0.77-0.5-1.06-1.71-1.61-2.69c-0.73,0-1.65,0-2.58,0c-0.16-0.31-0.31-0.61-0.47-0.92
	c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19c1.06-0.1,2.12-0.19,3.18-0.29c0.15,0.09,0.31,0.18,0.46,0.28
	c0.39,0.89,0.77,1.78,1.22,2.82C464.31,96.98,465.23,96.98,466.15,96.98z"
              />
              <path
                d="M566.46,168.06c0.16,0.07,0.31,0.14,0.47,0.21c0.01,1.05,0.02,2.1,0.03,3.15c-0.24,0.31-0.49,0.63-0.73,0.94
	c-3.21,0.02-3.54-0.24-4.16-3.11c-0.95-0.08-1.88-0.15-2.81-0.23c-0.15-0.13-0.29-0.26-0.44-0.39c-0.04-1.13-0.07-2.26-0.11-3.4
	c0.18-0.15,0.36-0.31,0.54-0.46c0.98,0.06,1.95,0.13,2.93,0.19c0,0,0.1,0.08,0.1,0.08c0.42,0.86,0.83,1.73,1.21,2.53
	C564.38,167.71,565.42,167.89,566.46,168.06z"
              />
              <path
                d="M539.8,195.43c-0.06,0.07-0.13,0.14-0.19,0.21c-0.98,0.24-1.97,0.48-2.95,0.72c-0.16-0.19-0.33-0.38-0.49-0.57
	c-0.36-0.84-0.73-1.69-1.1-2.56c-0.82-0.07-1.75-0.14-2.68-0.22c-0.15-0.14-0.31-0.27-0.46-0.41c-0.04-1.12-0.08-2.25-0.13-3.37
	c0.19-0.16,0.38-0.32,0.57-0.49c0.99,0.07,2.21-0.2,2.89,0.29c0.73,0.52,0.95,1.76,1.29,2.48c1.03,0.18,2.01,0.35,2.99,0.52
	c0.17,0.08,0.33,0.15,0.5,0.23C539.97,193.32,539.89,194.38,539.8,195.43z"
              />
              <path
                d="M569.36,160.35c-0.17,0.17-0.34,0.35-0.51,0.52c-1.06,0.72-2.17,0.43-3.28,0.19c-0.09-0.22-0.18-0.44-0.27-0.66
	c-0.18-0.75-0.35-1.5-0.51-2.18c-0.91-0.08-1.77-0.16-2.63-0.23c-0.18-0.19-0.35-0.38-0.53-0.57c-0.11-0.94-0.21-1.88-0.32-2.83
	c0.12-0.16,0.24-0.33,0.37-0.49c1.1-0.18,2.2-0.35,3.3-0.53c0.16,0.18,0.31,0.37,0.47,0.56c0.2,0.83,0.41,1.67,0.6,2.43
	c0.89,0,1.75,0,2.61,0c0.08,0.16,0.15,0.33,0.23,0.49C569.75,158.05,569.94,159.14,569.36,160.35z"
              />
              <path
                d="M506.49,144.55c-0.15-0.09-0.31-0.18-0.46-0.28c-0.2-0.87-0.41-1.74-0.61-2.63c-0.88,0-1.64,0-2.4,0
	c-0.15-0.19-0.3-0.38-0.45-0.57c-0.82-0.82-0.87-1.81-0.66-2.86c0.23-0.28,0.47-0.57,0.7-0.85c1.15-0.72,2.29-0.45,3.43,0.03
	l-0.09-0.08c0.28,0.93,0.57,1.87,0.83,2.74c0.91,0.08,1.75,0.16,2.59,0.23c0.16,0.14,0.32,0.29,0.48,0.43
	c0.15,0.98,0.3,1.96,0.45,2.93c-0.19,0.16-0.37,0.32-0.56,0.48C508.76,145.04,507.62,144.73,506.49,144.55z"
              />
              <path
                d="M511.29,155.11c-0.17-0.18-0.35-0.37-0.52-0.55c-0.1-0.95-0.2-1.9-0.3-2.84c0.11-0.16,0.23-0.31,0.34-0.47
	c1.1-0.18,2.21-0.36,3.31-0.54c0.18,0.19,0.36,0.38,0.54,0.57c0.17,0.83,0.34,1.66,0.5,2.42c0.91,0,1.76,0,2.61,0
	c0.19,0.3,0.37,0.61,0.56,0.91c0.96,2.51,0.14,3.81-2.43,3.84c-0.31,0-0.63,0-0.94,0c-0.1-0.07-0.19-0.15-0.28-0.23
	c-0.28-1-0.55-1.99-0.79-2.85C512.94,155.27,512.11,155.19,511.29,155.11z"
              />
              <path
                d="M619.27,485.24c-0.16-0.15-0.32-0.29-0.48-0.44c-0.42-1.12-0.5-2.24-0.03-3.36c0.12-0.17,0.24-0.33,0.36-0.5
	c0.83-0.11,1.66-0.22,2.43-0.31c0.08-0.95,0.16-1.79,0.23-2.62c0.12-0.16,0.24-0.33,0.37-0.49c1.08-0.87,2.3-0.52,3.49-0.41
	c0.11,0.06,0.21,0.12,0.31,0.18c0.16,1.19,0.68,2.38,0.04,3.57c-0.09,0.14-0.17,0.28-0.26,0.42c-0.84,0.16-1.69,0.32-2.65,0.5
	c0,0.81,0,1.67,0,2.53c-0.2,0.17-0.4,0.35-0.6,0.52C621.51,485.77,620.38,485.43,619.27,485.24z"
              />
              <path
                d="M462.35,94.17c-0.15-0.09-0.31-0.18-0.46-0.28c-0.2-0.87-0.41-1.74-0.61-2.63c-0.88,0-1.64,0-2.4,0
	c-0.15-0.19-0.3-0.38-0.45-0.57c-0.81-0.81-0.88-1.8-0.65-2.85c0.23-0.29,0.46-0.58,0.69-0.86c1.08-0.67,2.19-0.43,3.31-0.14
	c0.15,0.19,0.31,0.39,0.46,0.58c0.1,0.76,0.19,1.52,0.28,2.22c1.02,0.1,1.87,0.19,2.72,0.28c0.15,0.13,0.3,0.27,0.45,0.4
	c1.15,2.95,0.19,4.25-2.87,3.9C462.66,94.21,462.51,94.19,462.35,94.17z"
              />
              <path
                d="M568.36,146c-0.22,0.31-0.45,0.62-0.67,0.93c-3.43,0.07-3.57-0.42-4.19-3.11c-0.94-0.08-1.87-0.15-2.8-0.23
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.43-1.16-0.42-2.32,0.02-3.48c0,0-0.08,0.09-0.08,0.09c1.11-0.5,2.21-1.08,3.43-0.3
	c0.09,0.08,0.18,0.16,0.28,0.23c0.34,0.88,0.67,1.76,0.93,2.43c1.05,0.12,2.02,0.22,3,0.33c0.16,0.2,0.32,0.41,0.48,0.61
	C568.32,144.05,568.34,145.03,568.36,146z"
              />
              <path
                d="M613.95,460.5c-0.17-0.08-0.33-0.15-0.5-0.23c-0.74-0.84-0.98-1.8-0.68-2.89c0.24-0.31,0.49-0.62,0.73-0.94
	c1.14-0.68,2.27-0.49,3.39,0.03l-0.07-0.06c0.28,0.93,0.56,1.86,0.81,2.7c0.93,0.1,1.76,0.18,2.59,0.27
	c0.16,0.14,0.32,0.29,0.48,0.43c0.15,0.97,0.3,1.94,0.45,2.91c-0.2,0.17-0.4,0.34-0.6,0.51c-0.99,1-2.15,0.55-3.27,0.37l0.09,0.08
	c-0.4-0.93-0.8-1.86-1.23-2.84C615.49,460.74,614.72,460.62,613.95,460.5z"
              />
              <path
                d="M477,99.46c0.17,0.3,0.33,0.61,0.5,0.91c0.73,0.91,0.64,1.88,0.21,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	c-0.92,0.57-1.89,0.58-2.88,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.34-0.92-0.67-1.85-0.98-2.69c-0.85-0.07-1.69-0.14-2.54-0.22
	c-0.17-0.18-0.34-0.36-0.51-0.54c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c1.1-0.19,2.2-0.38,3.3-0.57
	c0.16,0.19,0.32,0.38,0.49,0.57c0.21,0.87,0.42,1.73,0.62,2.55C475.33,99.54,476.17,99.5,477,99.46z"
              />
              <path
                d="M525.24,193.5c-0.16-0.11-0.33-0.22-0.49-0.33c-0.19-0.83-0.39-1.65-0.57-2.45c-0.84-0.13-1.6-0.25-2.36-0.37
	c-0.18-0.09-0.35-0.17-0.53-0.26c-0.73-0.84-0.94-1.81-0.67-2.88c0.25-0.31,0.5-0.63,0.75-0.94c1.14-0.74,2.27-0.39,3.39,0.05
	l-0.09-0.08c0.29,0.93,0.57,1.86,0.84,2.73c0.91,0.09,1.74,0.17,2.58,0.25c0.15,0.14,0.31,0.28,0.46,0.41
	c0.11,0.97,0.22,1.94,0.33,2.91c-0.11,0.16-0.23,0.32-0.34,0.48C527.54,193.91,526.39,193.73,525.24,193.5z"
              />
              <path
                d="M609.65,337.46c0.18,0.16,0.36,0.32,0.53,0.48c0.12,0.96,0.24,1.92,0.35,2.88c-0.15,0.2-0.31,0.4-0.46,0.59
	c-1.02,0.77-2.15,0.61-3.28,0.36c-0.16-0.15-0.33-0.3-0.49-0.44c-0.2-0.8-0.4-1.6-0.59-2.36c-0.84-0.12-1.58-0.22-2.33-0.33
	c-0.19-0.09-0.37-0.18-0.56-0.27c-0.85-1.1-1.11-2.27-0.14-3.4c0.88-1.04,2.02-1.03,3.2-0.54c0.14,0.06,0.29,0.11,0.44,0.16
	c0,0-0.07-0.05-0.07-0.05c0.28,0.93,0.56,1.85,0.82,2.72C607.99,337.33,608.82,337.39,609.65,337.46z"
              />
              <path
                d="M528.56,189.65c-0.15-0.14-0.31-0.28-0.46-0.41c-0.39-1.11-0.52-2.23-0.04-3.35c0.13-0.18,0.26-0.35,0.39-0.53
	c0.83-0.1,1.67-0.2,2.43-0.3c0.08-0.95,0.14-1.8,0.21-2.64c0.12-0.16,0.24-0.33,0.37-0.49c1.1-0.16,2.21-0.33,3.31-0.49
	c0.17,0.18,0.34,0.36,0.51,0.54c0.35,1.11,0.49,2.21,0.01,3.32c-0.07,0.14-0.14,0.27-0.22,0.39c-0.88,0.16-1.77,0.32-2.68,0.49
	c0,0.89,0,1.73,0,2.57c-0.19,0.16-0.38,0.32-0.57,0.49C530.83,190.16,529.68,189.85,528.56,189.65z"
              />
              <path
                d="M532.88,153.19c-0.17-0.18-0.35-0.37-0.52-0.55c-1.02-2.73-0.42-3.64,2.55-4.06c0.12-1.03,0.23-2.02,0.35-3.01
	c0.12-0.17,0.25-0.34,0.37-0.51c1.01,0.01,2.02,0.03,3.03,0.04c0.15,0.14,0.31,0.27,0.46,0.41c0.15,0.99,0.31,1.97,0.46,2.96
	c-0.16,0.14-0.33,0.28-0.49,0.42c-0.85,0.31-1.71,0.62-2.49,0.91c-0.11,0.9-0.23,1.89-0.34,2.89c-0.12,0.15-0.24,0.3-0.36,0.45
	C534.88,153.15,533.88,153.17,532.88,153.19z"
              />
              <path
                d="M551.61,202.13c-0.18-0.19-0.36-0.37-0.54-0.56c-1.01-2.72-0.42-3.62,2.53-4.05c0.13-1.04,0.24-2.02,0.36-3
	c0.13-0.17,0.26-0.35,0.38-0.52c1,0,2,0,3.01,0c0.17,0.16,0.35,0.31,0.52,0.47c0.14,0.97,0.28,1.93,0.42,2.9
	c-0.18,0.16-0.37,0.33-0.55,0.49c-0.83,0.31-1.67,0.62-2.44,0.9c-0.11,0.9-0.22,1.87-0.34,2.84c-0.13,0.17-0.25,0.34-0.38,0.5
	C553.59,202.11,552.6,202.12,551.61,202.13z"
              />
              <path
                d="M611.56,395.05c-0.18,0.16-0.37,0.33-0.55,0.49c-0.84,0.3-1.68,0.6-2.46,0.88c-0.1,0.91-0.22,1.89-0.33,2.87
	c-0.12,0.16-0.24,0.33-0.36,0.49c-1,0.01-2.01,0.02-3.01,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.14-0.97-0.29-1.93-0.43-2.9
	c0.18-0.15,0.36-0.29,0.54-0.44c0.86-0.28,1.72-0.56,2.42-0.79c0.12-0.99,0.23-1.97,0.35-2.95c0.12-0.17,0.25-0.34,0.37-0.51
	C611.05,391.29,611.68,391.83,611.56,395.05z"
              />
              <path
                d="M518.46,70.72c-0.18,0.15-0.37,0.31-0.55,0.46c-1,0.91-2.16,0.6-3.29,0.43c-0.14-0.13-0.29-0.26-0.43-0.38
	c-0.19-0.89-0.38-1.77-0.54-2.52c-0.92,0-1.69,0-2.47,0c-0.14-0.19-0.28-0.37-0.43-0.56c-0.94-0.97-0.92-2.07-0.43-3.23
	c0.15-0.16,0.3-0.32,0.46-0.49c1.15-0.64,2.29-0.51,3.43,0.02c0,0-0.07-0.06-0.07-0.06c0.27,0.94,0.55,1.88,0.8,2.72
	c0.94,0.1,1.78,0.19,2.61,0.28c0.14,0.13,0.29,0.26,0.43,0.38C518.14,68.75,518.3,69.73,518.46,70.72z"
              />
              <path
                d="M620.7,459.81c-0.16-0.14-0.32-0.29-0.48-0.43c-0.47-1.16-0.44-2.31,0.02-3.46l-0.08,0.09c0.95-0.31,1.9-0.63,2.84-0.94
	c0.08-0.8,0.17-1.65,0.25-2.5c0.1-0.15,0.2-0.3,0.31-0.44c1-0.85,2.19-0.56,3.34-0.53c0.17,0.18,0.33,0.35,0.5,0.53
	c0.37,1.12,0.46,2.24,0.01,3.36c-0.07,0.15-0.14,0.31-0.21,0.46c-0.89,0.11-1.78,0.21-2.69,0.32c0,0.94,0,1.78,0,2.62
	c-0.2,0.16-0.39,0.32-0.59,0.49C622.95,460.33,621.82,459.98,620.7,459.81z"
              />
              <path
                d="M514.18,71.22c0.14,0.13,0.29,0.26,0.43,0.38c1.17,2.93,0.22,4.24-2.86,3.9c-0.16-0.02-0.31-0.04-0.47-0.06
	c-0.15-0.09-0.3-0.18-0.46-0.27c-0.18-0.91-0.36-1.82-0.51-2.57c-0.89-0.11-1.65-0.2-2.42-0.3c-0.31-0.21-0.61-0.43-0.92-0.64
	c-0.44-0.97-0.48-1.93-0.03-2.91c0.16-0.17,0.31-0.33,0.47-0.5c1.09-0.67,2.2-0.41,3.32-0.12c0.14,0.19,0.28,0.37,0.43,0.56
	c0.1,0.76,0.2,1.53,0.3,2.23C512.48,71.04,513.33,71.13,514.18,71.22z"
              />
              <path
                d="M620.55,463.23c0.2-0.17,0.4-0.34,0.6-0.51c0.97,0.01,1.94,0.02,2.92,0.02c0.15,0.14,0.31,0.28,0.46,0.42
	c1.09,2.8,0.47,3.8-2.49,4.15c-0.13,1.02-0.25,2-0.38,2.99c-0.13,0.16-0.26,0.33-0.39,0.49c-0.99,0.02-1.98,0.03-2.97,0.05
	c-0.18-0.19-0.35-0.37-0.53-0.56c-0.14-0.96-0.28-1.92-0.42-2.87c0.15-0.14,0.3-0.27,0.46-0.41c0.87-0.3,1.74-0.59,2.56-0.87
	C620.42,465.23,620.49,464.23,620.55,463.23z"
              />
              <path
                d="M512.59,93.28c0.2-0.17,0.4-0.34,0.6-0.51c0.97,0.01,1.94,0.01,2.91,0.02c0.16,0.14,0.32,0.29,0.47,0.43
	c0.15,0.97,0.3,1.95,0.45,2.92c-0.2,0.17-0.39,0.34-0.59,0.5c-0.83,0.29-1.66,0.59-2.39,0.84c-0.11,0.94-0.23,1.91-0.35,2.88
	c-0.13,0.17-0.25,0.33-0.38,0.5c-0.45,0.08-0.9,0.22-1.36,0.24c-1.99,0.11-2.72-0.92-2.58-3.63c0.16-0.14,0.32-0.29,0.48-0.43
	c0.87-0.29,1.74-0.59,2.54-0.86C512.46,95.26,512.52,94.27,512.59,93.28z"
              />
              <path
                d="M447.44,84.15c-0.19,0.16-0.37,0.31-0.56,0.47c-0.98,0.9-2.12,0.62-3.25,0.43c-0.16-0.14-0.32-0.28-0.48-0.42
	c-0.45-1.16-0.43-2.31,0.02-3.47c0,0-0.08,0.09-0.08,0.09c0.95-0.31,1.91-0.61,2.94-0.94c0-0.82,0-1.68,0-2.55
	c0.18-0.15,0.36-0.3,0.55-0.44c1.08-0.17,2.16-0.33,3.24-0.5c0.17,0.18,0.34,0.36,0.52,0.55c0.38,1.13,0.48,2.27-0.03,3.39
	c0,0,0.05-0.07,0.05-0.07c-0.94,0.33-1.89,0.65-2.93,1.01C447.44,82.42,447.44,83.29,447.44,84.15z"
              />
              <path
                d="M626.92,448.29c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.04,0.02-3.06,0.03c-0.17-0.18-0.33-0.35-0.5-0.53
	c-0.59-0.94-0.89-2.06-0.11-2.85c0.69-0.7,1.79-0.99,2.68-1.45c0.1-0.83,0.21-1.82,0.33-2.81c0.11-0.16,0.23-0.32,0.34-0.48
	c3.5-0.38,4.1,0.14,3.96,3.4c-0.16,0.14-0.33,0.28-0.49,0.42c-0.86,0.31-1.72,0.62-2.51,0.9
	C627.15,446.29,627.04,447.29,626.92,448.29z"
              />
              <path
                d="M648.99,492.93c-0.19,0.17-0.38,0.34-0.57,0.51c-0.85,0.29-1.7,0.59-2.44,0.84c-0.11,0.95-0.23,1.94-0.34,2.93
	c-0.11,0.15-0.22,0.31-0.33,0.46c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.35-0.36-0.52-0.54c-0.61-0.93-0.88-2.05-0.11-2.85
	c0.68-0.7,1.78-0.99,2.68-1.45c0.1-0.82,0.21-1.81,0.33-2.8c0.11-0.16,0.23-0.32,0.34-0.48c1.02,0,2.04,0,3.06,0
	c0,0-0.09-0.07-0.09-0.07C649.01,490.45,649.21,491.63,648.99,492.93z"
              />
              <path
                d="M529.98,167.63c0.12-0.17,0.24-0.33,0.36-0.5c1.02,0.02,2.03,0.04,3.05,0.05c0.14,0.13,0.28,0.26,0.43,0.39
	c0.16,0.98,0.32,1.96,0.48,2.95c-0.19,0.16-0.37,0.31-0.56,0.47c-0.84,0.29-1.68,0.58-2.4,0.84c-0.12,0.95-0.24,1.92-0.36,2.9
	c-0.13,0.17-0.25,0.34-0.38,0.5c-0.47,0.09-0.93,0.24-1.4,0.26c-1.95,0.09-2.66-0.93-2.55-3.62c0.17-0.16,0.35-0.32,0.52-0.48
	c0.86-0.29,1.72-0.59,2.48-0.84C529.75,169.6,529.87,168.62,529.98,167.63z"
              />
              <path
                d="M564.54,312.97c-0.15-0.13-0.3-0.27-0.45-0.4c-0.04-1.13-0.08-2.25-0.12-3.38c0.19-0.16,0.38-0.32,0.57-0.48
	c0.81-0.12,1.63-0.24,2.31-0.34c0.1-0.97,0.18-1.8,0.26-2.63c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.21-0.36,3.32-0.54
	c0.18,0.19,0.35,0.37,0.53,0.56c0,1.1,0,2.2,0.01,3.3c-0.08,0.17-0.15,0.34-0.23,0.52c-0.89,0.11-1.78,0.22-2.68,0.34
	c0,0.94,0,1.78,0,2.62c-0.19,0.16-0.37,0.32-0.56,0.48C566.81,313.46,565.67,313.15,564.54,312.97z"
              />
              <path
                d="M619.11,480.94c-0.12,0.17-0.24,0.33-0.36,0.5c-1.04,0.9-2.22,0.63-3.39,0.4l0.09,0.08c-0.75-1.26-1.39-2.53-0.28-3.93
	c0.07-0.06,0.15-0.11,0.22-0.17c0.8-0.22,1.6-0.44,2.32-0.64c0.08-0.87,0.16-1.71,0.24-2.54c0.11-0.16,0.23-0.31,0.34-0.47
	c1.1-0.18,2.21-0.35,3.31-0.53c0.18,0.19,0.36,0.37,0.54,0.56c0,1.1,0,2.21,0,3.31c-0.12,0.16-0.24,0.33-0.37,0.49
	c-0.83,0.12-1.66,0.25-2.46,0.37C619.25,479.26,619.18,480.1,619.11,480.94z"
              />
              <path
                d="M518.93,81.62c0.17,0.18,0.35,0.37,0.52,0.55c0,1.11,0,2.22,0,3.32c-0.12,0.16-0.23,0.32-0.35,0.48
	c-0.84,0.12-1.68,0.24-2.47,0.35c-0.07,0.89-0.15,1.74-0.22,2.6c-0.12,0.16-0.23,0.32-0.35,0.48c-1.01,0.88-2.17,0.68-3.34,0.45
	c-0.16-0.14-0.31-0.28-0.47-0.42c-0.41-1.13-0.47-2.27-0.04-3.41c0.16-0.07,0.32-0.15,0.49-0.22c0.8-0.22,1.61-0.44,2.32-0.63
	c0.09-0.88,0.18-1.71,0.26-2.55c0.1-0.15,0.21-0.3,0.31-0.45C516.71,81.98,517.82,81.8,518.93,81.62z"
              />
              <path
                d="M530.59,175.23c0.13-0.17,0.25-0.34,0.38-0.5c0.88-0.27,1.77-0.54,2.56-0.78c0.06-0.96,0.13-1.96,0.2-2.96
	c0.19-0.16,0.37-0.31,0.56-0.47c0.46-0.07,0.93-0.19,1.39-0.19c1.96,0,2.56,0.9,2.44,3.59c-0.15,0.12-0.29,0.24-0.44,0.36
	c-0.9,0.32-1.79,0.64-2.54,0.91c-0.12,0.99-0.24,1.96-0.35,2.92c-0.11,0.14-0.23,0.29-0.34,0.43c-1,0.02-2.01,0.04-3.01,0.07
	c-0.18-0.18-0.35-0.37-0.53-0.55C530.81,177.12,530.7,176.18,530.59,175.23z"
              />
              <path
                d="M548.61,216.58c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02c0.15,0.13,0.3,0.27,0.45,0.4
	c0.16,0.98,0.31,1.96,0.47,2.94c-0.19,0.16-0.37,0.31-0.56,0.47c-0.85,0.29-1.7,0.58-2.44,0.83c-0.11,0.98-0.23,1.97-0.34,2.96
	c-0.1,0.15-0.2,0.3-0.31,0.44c-0.38,0.05-0.76,0.12-1.15,0.16c-1.89,0.18-2.8-0.57-2.91-2.4c-0.02-0.39,0.04-0.78,0.07-1.16
	c0.14-0.13,0.28-0.26,0.42-0.38c0.91-0.3,1.81-0.6,2.59-0.86C548.46,218.52,548.53,217.55,548.61,216.58z"
              />
              <path
                d="M565.47,326.83c0.17,0.18,0.34,0.36,0.51,0.54c0.4,1.14,0.45,2.27-0.04,3.4c0,0,0.08-0.09,0.08-0.09
	c-0.95,0.33-1.91,0.65-2.94,1c0,0.72,0,1.59,0,2.46c-0.19,0.16-0.38,0.32-0.57,0.48c-0.99,0.92-2.13,0.6-3.26,0.42
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.28-1.18-0.73-2.37,0.25-3.46c0.06-0.06,0.13-0.12,0.2-0.18c0.8-0.22,1.61-0.44,2.32-0.64
	c0.09-0.88,0.18-1.71,0.26-2.55c0.1-0.15,0.21-0.3,0.31-0.45C563.15,326.52,564.33,326.79,565.47,326.83z"
              />
              <path
                d="M530.01,164.22c-0.16-0.14-0.32-0.28-0.48-0.42c-0.45-1.16-0.43-2.31,0.02-3.47c0,0-0.08,0.09-0.08,0.09
	c0.95-0.31,1.91-0.62,2.85-0.92c0.08-0.81,0.15-1.66,0.23-2.51c0.11-0.15,0.22-0.31,0.32-0.46c1.11-0.17,2.22-0.35,3.33-0.52
	c0.17,0.18,0.34,0.35,0.51,0.53c0.39,1.14,0.43,2.28-0.04,3.41c0,0,0.08-0.09,0.08-0.09c-0.97,0.33-1.95,0.66-2.94,1
	c0,0.79,0,1.63,0,2.47c-0.19,0.15-0.38,0.31-0.57,0.46C532.27,164.73,531.13,164.4,530.01,164.22z"
              />
              <path
                d="M549.36,224.17c0.1-0.15,0.2-0.3,0.31-0.44c0.89-0.29,1.78-0.57,2.59-0.83c0.06-0.93,0.12-1.94,0.19-2.96
	c0.19-0.16,0.37-0.31,0.56-0.47c0.46-0.07,0.93-0.18,1.39-0.19c1.95,0,2.56,0.89,2.45,3.57c-0.16,0.13-0.31,0.26-0.47,0.4
	c-0.89,0.32-1.79,0.64-2.52,0.9c-0.12,1-0.24,1.97-0.35,2.93c-0.11,0.15-0.21,0.3-0.32,0.46c-1.01,0.01-2.03,0.02-3.04,0.03
	c-0.17-0.18-0.34-0.35-0.5-0.53C549.03,226.13,549.01,225.16,549.36,224.17z"
              />
              <path
                d="M489.85,81.18c0.1-0.15,0.21-0.3,0.31-0.45c1.11-0.18,2.22-0.36,3.33-0.55c0.17,0.18,0.35,0.37,0.52,0.55
	c0.39,1.14,0.44,2.27-0.05,3.4c0,0,0.08-0.09,0.08-0.09c-0.95,0.33-1.9,0.66-2.86,0.99c-0.06,0.73-0.12,1.59-0.18,2.44
	c-0.12,0.17-0.25,0.34-0.37,0.51c-1.04,0.9-2.21,0.64-3.39,0.39l0.09,0.08c-0.75-1.26-1.43-2.52-0.26-3.91
	c0.06-0.06,0.13-0.12,0.19-0.18c0.8-0.22,1.6-0.45,2.31-0.65C489.68,82.84,489.77,82.01,489.85,81.18z"
              />
              <path
                d="M562.14,324.01c-0.17-0.18-0.34-0.36-0.5-0.53c-0.1-0.95-0.2-1.9-0.3-2.86c0.11-0.15,0.22-0.31,0.33-0.46
	c0.88-0.28,1.77-0.57,2.57-0.82c0.06-0.94,0.13-1.94,0.2-2.95c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02
	c0.15,0.13,0.3,0.27,0.45,0.4c1.09,2.8,0.48,3.78-2.51,4.17c-0.12,1.03-0.24,2.02-0.36,3.01c-0.11,0.15-0.22,0.31-0.33,0.46
	C564.17,323.99,563.15,324,562.14,324.01z"
              />
              <path
                d="M526.89,92.7c0.23-0.31,0.47-0.62,0.7-0.94c1.07-0.67,2.18-0.4,3.29-0.14c0.15,0.2,0.3,0.39,0.46,0.59
	c0.03,0.97,0.05,1.94,0.08,2.91c-0.23,0.31-0.45,0.62-0.68,0.93c-0.92,0-1.84-0.01-2.76-0.01c-0.15-0.2-0.29-0.4-0.44-0.6
	C526.73,94.68,526.62,93.74,526.89,92.7z"
              />
              <path
                d="M457.31,76.87c-0.15-0.31-0.31-0.61-0.46-0.92c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52
	c1.04-0.14,2.08-0.29,3.12-0.43c0.19,0.3,0.38,0.61,0.56,0.91c0.64,0.92,0.61,1.88,0.16,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	C459.37,76.57,458.34,76.72,457.31,76.87z"
              />
              <path
                d="M501.46,91.26c0.23-0.31,0.47-0.62,0.7-0.94c1.07-0.67,2.17-0.42,3.28-0.15c0.16,0.2,0.32,0.4,0.47,0.6
	c0.03,0.97,0.06,1.93,0.09,2.9c-0.24,0.31-0.48,0.63-0.71,0.94c-0.89-0.07-1.78-0.14-2.68-0.21c-0.31-0.23-0.61-0.46-0.92-0.69
	C501.62,92.9,501.54,92.08,501.46,91.26z"
              />
              <path
                d="M546.28,108.57c0.18-0.18,0.35-0.36,0.53-0.54c1.03-0.14,2.06-0.27,3.09-0.41c0.2,0.3,0.4,0.61,0.6,0.91
	c0.62,0.92,0.61,1.88,0.14,2.86c-0.18,0.18-0.35,0.36-0.53,0.54c-1.05,0.68-2.15,0.47-3.26,0.19c-0.09-0.22-0.19-0.45-0.28-0.67
	C546.48,110.49,546.38,109.53,546.28,108.57z"
              />
              <path
                d="M560.72,265.94c-0.25,0.31-0.5,0.63-0.74,0.94c-0.91,0-1.82,0-2.72,0c-0.16-0.2-0.31-0.39-0.47-0.59
	c-0.79-0.79-0.93-1.73-0.62-2.76c0.23-0.31,0.47-0.63,0.7-0.94c1.06-0.67,2.16-0.43,3.27-0.16c0.16,0.21,0.33,0.41,0.49,0.62
	C560.66,264.01,560.69,264.98,560.72,265.94z"
              />
              <path
                d="M633.57,483.68c0.15,0.19,0.3,0.38,0.45,0.57c0.02,0.97,0.04,1.94,0.07,2.92c-0.15,0.16-0.3,0.32-0.45,0.48
	c-0.96,0.06-1.93,0.13-2.89,0.19c-0.16-0.07-0.31-0.14-0.47-0.21c-0.81-0.85-0.95-1.86-0.74-2.96c0.25-0.29,0.49-0.58,0.74-0.87
	C631.35,483.13,632.46,483.38,633.57,483.68z"
              />
              <path
                d="M626.93,411.85c0.17,0.15,0.33,0.3,0.5,0.45c0.15,0.97,0.29,1.94,0.44,2.9c-0.2,0.17-0.4,0.35-0.6,0.52
	c-0.97,0.94-2.1,0.6-3.21,0.41c-0.16-0.15-0.32-0.29-0.48-0.44c-0.42-1.11-0.5-2.23-0.03-3.35c0.12-0.17,0.25-0.34,0.37-0.51
	C624.92,411.85,625.93,411.85,626.93,411.85z"
              />
              <path
                d="M542.45,97.59c-0.18,0.15-0.37,0.31-0.55,0.46c-1,0.92-2.15,0.59-3.29,0.43c-0.14-0.13-0.29-0.26-0.43-0.39
	c-0.41-1.14-0.48-2.28-0.04-3.43c0.11-0.16,0.22-0.31,0.32-0.47c1.02,0,2.04,0.01,3.05,0.01c0.16,0.15,0.33,0.3,0.49,0.44
	C542.16,95.63,542.31,96.61,542.45,97.59z"
              />
              <path
                d="M604.83,316.34c-0.16,0.17-0.32,0.34-0.48,0.5c-0.96,0.06-1.92,0.12-2.89,0.19c-0.17-0.08-0.34-0.16-0.51-0.23
	c-0.84-1-0.99-2.09-0.45-3.28c0.17-0.18,0.34-0.37,0.52-0.55c1.07-0.67,2.18-0.38,3.28-0.11c0.15,0.19,0.29,0.38,0.44,0.57
	C604.77,314.39,604.8,315.36,604.83,316.34z"
              />
              <path
                d="M554.35,194c-0.13,0.17-0.26,0.35-0.38,0.52c-1.02,0.84-2.17,0.67-3.33,0.41c-0.15-0.14-0.31-0.28-0.46-0.41
	c-0.41-1.13-0.49-2.26-0.03-3.39c0.11-0.16,0.23-0.32,0.34-0.48c1.01,0,2.02,0,3.04,0c0.17,0.15,0.34,0.31,0.51,0.46
	C554.13,192.07,554.24,193.03,554.35,194z"
              />
              <path
                d="M490.65,127.76c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.03,0.02-3.05,0.03c-0.17-0.18-0.34-0.36-0.52-0.54
	c-0.14-0.98-0.28-1.96-0.42-2.94c0.17-0.13,0.33-0.25,0.5-0.38c1.09-0.17,2.19-0.33,3.28-0.5c0.17,0.17,0.33,0.35,0.5,0.52
	C491.01,125.51,491.1,126.63,490.65,127.76z"
              />
              <path
                d="M626.3,440.64c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.18,0.68-3.35,0.45c-0.15-0.14-0.31-0.27-0.46-0.41
	c-0.39-1.13-0.49-2.25-0.03-3.39c0.12-0.16,0.23-0.33,0.35-0.49c1.01,0,2.02,0,3.03,0c0.17,0.15,0.34,0.31,0.51,0.46
	C626.1,438.71,626.2,439.67,626.3,440.64z"
              />
              <path
                d="M489.12,113.4c0.18-0.15,0.37-0.31,0.55-0.46c0.97,0,1.95,0,2.92,0c0.15,0.14,0.3,0.27,0.46,0.41
	c0.15,0.98,0.31,1.96,0.46,2.95c-0.19,0.15-0.38,0.3-0.56,0.46c-0.99,0.94-2.15,0.58-3.28,0.43c-0.14-0.13-0.29-0.25-0.43-0.38
	C489.19,115.67,489.15,114.54,489.12,113.4z"
              />
              <path
                d="M535.62,145.06c-0.12,0.17-0.25,0.34-0.37,0.51c-1.01,0.86-2.15,0.71-3.31,0.43c-0.16-0.15-0.33-0.3-0.49-0.45
	c-0.05-1.1-0.09-2.21-0.14-3.31c0.2-0.18,0.4-0.36,0.6-0.53c0.97,0.01,1.94,0.01,2.9,0.02c0.16,0.15,0.32,0.29,0.49,0.44
	C535.4,143.12,535.51,144.09,535.62,145.06z"
              />
              <path
                d="M540.13,365.77c-0.17-0.13-0.34-0.26-0.51-0.39c-0.01-1.16-0.02-2.32-0.02-3.47c0.1-0.15,0.2-0.29,0.29-0.44
	c3.54-0.31,4.11,0.17,3.99,3.36l0.08-0.09C542.91,365.93,541.6,366.15,540.13,365.77z"
              />
              <path
                d="M645.01,489.58c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44c-0.15-0.13-0.3-0.27-0.45-0.4
	c-0.04-1.13-0.09-2.25-0.13-3.38c0.19-0.16,0.38-0.32,0.58-0.49c0.97-0.01,1.94-0.02,2.9-0.02c0.17,0.15,0.34,0.31,0.51,0.46
	C644.81,487.65,644.91,488.62,645.01,489.58z"
              />
              <path
                d="M507.82,162.35c0.19-0.16,0.38-0.32,0.57-0.48c0.97,0.01,1.94,0.01,2.92,0.02c0.15,0.13,0.3,0.27,0.45,0.4
	c0.61,0.91,0.69,1.89,0.34,2.91c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44c-0.15-0.13-0.3-0.27-0.45-0.4
	C507.9,164.61,507.86,163.48,507.82,162.35z"
              />
              <path
                d="M562.52,334.62c0.19-0.16,0.38-0.32,0.57-0.48c0.97-0.01,1.94-0.01,2.91-0.02c0.17,0.15,0.33,0.3,0.5,0.45
	c0.12,0.97,0.23,1.93,0.35,2.9c-0.13,0.18-0.27,0.36-0.4,0.53c-1.03,0.82-2.18,0.63-3.34,0.4c-0.15-0.13-0.3-0.27-0.45-0.4
	C562.6,336.87,562.56,335.74,562.52,334.62z"
              />
              <path
                d="M635.77,468.94c-0.06,0.07-0.13,0.13-0.19,0.19c-0.86,0.33-1.72,0.67-2.55,0.99c-0.1,0.8-0.22,1.7-0.34,2.6
	c-0.17,0.17-0.34,0.35-0.51,0.52c-1.03,0.15-2.05,0.3-3.08,0.45c-0.16-0.31-0.31-0.61-0.47-0.92c-0.16-0.74-0.32-1.48-0.48-2.19
	c-0.86-0.07-1.76-0.14-2.66-0.22c-0.18-0.18-0.35-0.37-0.53-0.55c-1.02-2.78-0.25-3.91,2.63-3.85c0.24,0,0.48-0.01,0.71-0.01
	c0.16,0.19,0.32,0.37,0.48,0.56c0.21,0.88,0.42,1.75,0.63,2.62c0.74-0.11,1.42-0.21,1.93-0.28c0.13-1,0.24-1.88,0.36-2.77
	c0.16-0.17,0.31-0.33,0.47-0.5c1.08-0.66,2.19-0.42,3.31-0.13c0.15,0.19,0.3,0.38,0.45,0.58
	C635.88,466.99,635.83,467.96,635.77,468.94z"
              />
              <path
                d="M634.1,455.01c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43c0.16,0.31,0.32,0.61,0.48,0.92
	c0.22,0.9,0.45,1.8,0.67,2.7c-0.07,0.06-0.13,0.12-0.19,0.18c-1.03,0.45-2.06,0.9-3.16,1.38c0,0.8,0,1.64,0,2.49
	c-0.23,0.31-0.47,0.62-0.7,0.94c-0.91,0-1.82-0.01-2.73-0.01c-0.15-0.2-0.31-0.4-0.46-0.6c-0.82-0.77-0.93-1.71-0.63-2.74
	c0.23-0.31,0.46-0.62,0.69-0.94c0.79-0.24,1.59-0.47,2.39-0.71C634.1,456.72,634.1,455.87,634.1,455.01z"
              />
              <path
                d="M632.18,473.24c0.17-0.17,0.34-0.35,0.51-0.52c1.05-0.14,2.09-0.28,3.14-0.43c0.16,0.3,0.32,0.61,0.49,0.92
	c0.75,0.9,0.65,1.87,0.22,2.86c-0.18,0.18-0.35,0.36-0.53,0.54c-1.05,0.71-2.17,0.43-3.28,0.19c-0.09-0.22-0.18-0.44-0.27-0.66
	C632.38,475.17,632.28,474.21,632.18,473.24z"
              />
              <path
                d="M493.04,113.36c-0.15-0.14-0.3-0.27-0.46-0.41c-0.46-1.16-0.45-2.32,0.02-3.47l-0.08,0.09c1.05-0.31,2.1-0.61,3.14-0.92
	c-0.13-0.67-0.3-1.51-0.45-2.26c-0.92-0.08-1.78-0.15-2.64-0.23c-0.17-0.18-0.35-0.37-0.52-0.55c-0.24-0.87-0.47-1.75-0.7-2.6
	c-0.81-0.06-1.71-0.14-2.61-0.21c-0.18-0.19-0.36-0.38-0.54-0.56c-0.11-0.94-0.22-1.89-0.33-2.83c0.13-0.17,0.27-0.35,0.4-0.52
	c0.87-0.29,1.74-0.57,2.39-0.79c0.15-1.03,0.29-1.95,0.43-2.87c0.15-0.19,0.3-0.39,0.45-0.58c0.91-0.1,1.81-0.2,2.56-0.28
	c0.12-1.05,0.24-2.03,0.36-3c0.14-0.19,0.28-0.37,0.42-0.56c3.1-0.43,3.62-0.08,4.25,2.89c0.85,0,1.71,0,2.58,0
	c0.31,0.23,0.61,0.46,0.92,0.69c0,1.1,0,2.19-0.01,3.29c-0.06,0.09-0.12,0.18-0.18,0.27c-1.01,0.25-2.02,0.51-3.02,0.76
	c0.16,0.76,0.3,1.43,0.45,2.17c0.86,0,1.7,0,2.54,0c0.18,0.3,0.37,0.6,0.55,0.91c0.26,0.85,0.51,1.71,0.73,2.45
	c0.86,0,1.72,0,2.58,0c0.17,0.3,0.34,0.61,0.51,0.91c0.3,0.77,0.6,1.53,0.93,2.36c0.87,0,1.73,0,2.59,0
	c0.18,0.19,0.35,0.37,0.53,0.56c0.41,1.14,0.47,2.27-0.06,3.4l0.08-0.09c-0.99,0.35-1.89,1.4-3.05,0.37
	c-1.64-0.25-1.18-1.77-1.57-2.81c-0.75,0.07-1.42,0.13-2.12,0.2c0.05,0.96,0.08,1.72,0.11,2.29c1.27,0.12,2.42,0.23,3.58,0.33
	c0.1,0.96,0.21,1.92,0.3,2.8c1.03,0.11,1.87,0.19,2.72,0.28c0.16,0.14,0.32,0.29,0.49,0.43c0.12,0.96,0.25,1.93,0.37,2.89
	c-0.14,0.19-0.29,0.38-0.43,0.57c-1.05,0.86-2.2,0.61-3.36,0.33c0,0,0.09,0.08,0.09,0.08c-0.43-0.97-0.87-1.95-1.33-2.98
	c-0.69,0.07-1.37,0.14-2.08,0.21c0,1,0,1.86,0,2.73c-0.23,0.31-0.47,0.62-0.7,0.94c-0.78,0-1.57,0-2.25,0
	c-0.14,1.05-0.27,1.96-0.39,2.87c-0.17,0.17-0.34,0.34-0.51,0.52c-1.12,0.71-2.23,0.5-3.35,0c-0.07-0.15-0.14-0.31-0.21-0.46
	c-0.08-0.97-0.17-1.94-0.25-2.9c0.15-0.12,0.29-0.24,0.44-0.36c0.88-0.27,1.77-0.54,2.68-0.82c-0.08-0.7-0.16-1.38-0.24-2.05
	c-3.04-0.24-3.34-0.49-3.94-3.24C494.89,113.52,493.97,113.44,493.04,113.36z M492.35,99.4c0.05,0.93,0.1,1.68,0.14,2.42
	c0.87-0.07,1.64-0.13,2.4-0.19c-0.07-3.17,0.32-3.64,3.32-4c-0.14-0.77-0.29-1.53-0.43-2.27c-0.8,0-1.55,0-2.31,0
	C495.63,98.52,495.33,98.9,492.35,99.4z M499.31,111.92c0.16-1.01,0.02-2.07,0.49-2.61c0.65-0.76,1.75-1.13,2.81-1.75
	c-0.03-0.51-0.09-1.26-0.14-2c-0.92,0.08-1.7,0.15-2.48,0.23c0.28,2.9-0.17,3.43-3.29,3.95c0.12,0.77,0.24,1.55,0.36,2.33
	C497.84,112.01,498.61,111.96,499.31,111.92z M500.71,112.93c0.06,0.98,0.1,1.75,0.14,2.55c0.93-0.11,1.76-0.2,2.58-0.29
	c-0.14-0.83-0.25-1.52-0.38-2.26C502.33,112.93,501.63,112.93,500.71,112.93z M496.05,102.57c0.15,0.85,0.28,1.59,0.41,2.31
	c0.83-0.08,1.52-0.15,2.35-0.23c-0.05-0.72-0.09-1.41-0.14-2.08C497.77,102.57,496.93,102.57,496.05,102.57z"
              />
              <path
                d="M491.53,94.66c-0.15,0.19-0.3,0.39-0.45,0.58c-0.85,0.27-1.7,0.54-2.46,0.78c-0.11,0.93-0.23,1.9-0.35,2.87
	c-0.13,0.17-0.27,0.35-0.4,0.52c-0.45,0.09-0.9,0.23-1.36,0.25c-1.96,0.11-2.71-0.96-2.58-3.63c0.17-0.15,0.33-0.3,0.5-0.46
	c0.87-0.32,1.74-0.63,2.64-0.96c-0.11-0.58-0.22-1.2-0.33-1.8c-0.83-0.13-1.6-0.24-2.37-0.36c-0.17-0.08-0.35-0.16-0.52-0.24
	c-0.72-0.85-0.96-1.8-0.66-2.88c0.24-0.31,0.48-0.62,0.72-0.94c1.15-0.74,2.28-0.39,3.41,0.04l-0.09-0.08
	c0.29,0.93,0.57,1.86,0.84,2.72c0.91,0.09,1.75,0.17,2.58,0.25c0.16,0.15,0.33,0.3,0.49,0.44C491.28,92.74,491.41,93.7,491.53,94.66
	z"
              />
              <path
                d="M462.24,87.42c-0.15-0.19-0.31-0.39-0.46-0.58c-0.78-0.79-0.93-1.73-0.63-2.77c0.23-0.31,0.47-0.62,0.7-0.94
	c1.14-0.67,2.27-0.52,3.4,0.02c0,0-0.06-0.05-0.06-0.05c0.28,0.93,0.56,1.86,0.83,2.73c0.9,0.07,1.73,0.14,2.57,0.21
	c0.17,0.15,0.33,0.3,0.5,0.45c0.1,0.97,0.2,1.93,0.3,2.9c-0.11,0.16-0.23,0.32-0.34,0.48c-1.02,0.88-2.19,0.67-3.35,0.44
	c-0.15-0.13-0.3-0.27-0.45-0.4c-0.2-0.81-0.4-1.63-0.61-2.5C463.78,87.42,463.01,87.42,462.24,87.42z"
              />
              <path
                d="M457.78,87.84c-0.94-0.09-1.88-0.18-2.81-0.27c-0.09-0.04-0.18-0.08-0.28-0.11c-0.98-0.99-1.04-2.13-0.52-3.35
	c0.15-0.16,0.3-0.32,0.46-0.49c1.08-0.66,2.2-0.42,3.32-0.14c0.15,0.19,0.3,0.39,0.45,0.58c0.03,0.97,0.05,1.94,0.08,2.91
	C458.24,87.26,458.01,87.55,457.78,87.84z"
              />
              <path
                d="M528.06,142.66c-0.37-0.92-0.74-1.84-1.13-2.8c-0.72-0.13-1.54-0.28-2.37-0.42c-0.12-0.07-0.25-0.14-0.38-0.19
	c-1.42-2.66-0.85-3.76,2.43-4.52c-0.03-0.74-0.07-1.5-0.1-2.2c-1.08,0-2.01,0-2.94,0c-0.17-0.3-0.33-0.61-0.5-0.91
	c-0.1-0.96-0.2-1.92-0.29-2.89c0.18-0.18,0.35-0.36,0.53-0.54c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.3,0.32,0.61,0.49,0.91
	c0.26,0.86,0.51,1.71,0.7,2.34c0.95,0.19,2.08,0.1,2.66,0.62c0.73,0.65,1.01,1.81,1.5,2.79c0.6-0.08,1.29-0.18,1.96-0.27
	c0.08-0.92,0.16-1.77,0.24-2.62c0.11-0.16,0.23-0.31,0.34-0.47c1.11-0.17,2.22-0.34,3.33-0.51c0.17,0.18,0.33,0.35,0.5,0.53
	c0.37,1.12,0.46,2.24,0.01,3.36c-0.1,0.15-0.2,0.3-0.31,0.44c-0.84,0.12-1.69,0.23-2.49,0.34c-0.07,0.89-0.14,1.75-0.21,2.6
	c-0.12,0.17-0.25,0.34-0.37,0.51c-0.96,0.29-1.92,0.58-2.87,0.87c0,0.78,0,1.62,0,2.46c-0.2,0.18-0.4,0.36-0.6,0.53
	C530.33,143.23,529.17,142.74,528.06,142.66z M530.49,135.65c-0.9,0.17-1.63,0.31-2.45,0.46c0.1,0.77,0.19,1.44,0.27,2.03
	c0.9,0,1.66,0,2.46,0C530.68,137.33,530.59,136.57,530.49,135.65z"
              />
              <path
                d="M523.3,128.19c-0.18,0.18-0.35,0.36-0.53,0.54c-0.92,0.57-1.89,0.58-2.87,0.2c-0.11-0.06-0.22-0.13-0.33-0.18
	c-0.32-0.93-0.64-1.85-0.97-2.78c-0.76,0.04-1.52,0.08-2.14,0.11c-0.12,1.06-0.23,2.04-0.35,3.02c-0.15,0.19-0.3,0.37-0.45,0.56
	c-0.9,0.11-1.81,0.23-2.59,0.32c-0.11,1-0.22,1.97-0.32,2.95c-0.15,0.19-0.29,0.38-0.44,0.56c-0.81,0.14-1.62,0.27-2.5,0.42
	c0,0.86,0,1.69,0,2.52c-0.2,0.17-0.39,0.34-0.59,0.51c-1,0.99-2.15,0.55-3.28,0.37c0,0,0.09,0.08,0.09,0.08
	c-0.79-1.25-1.37-2.52-0.35-3.94c0.1-0.05,0.19-0.1,0.28-0.16c0.75-0.21,1.51-0.43,2.28-0.64c0.08-0.82,0.16-1.68,0.24-2.53
	c0.13-0.17,0.26-0.35,0.39-0.52c0.94-0.28,1.88-0.55,2.78-0.82c0.05-0.8,0.1-1.65,0.16-2.5c0.15-0.19,0.29-0.37,0.44-0.56
	c0.94-0.26,1.89-0.52,2.9-0.8c-0.03-0.19-0.1-0.49-0.1-0.8c-0.02-1.99,0.68-2.72,2.64-2.77c0.39-0.01,0.78,0.03,1.18,0.04
	c0.17,0.19,0.33,0.38,0.5,0.58c0.19,0.83,0.39,1.66,0.61,2.6c0.71-0.11,1.44-0.23,2.07-0.33c0-0.88,0-1.58,0-2.28
	c0.24-0.31,0.49-0.63,0.73-0.94c1.1-0.67,2.2-0.5,3.31-0.01c0.17,0.08,0.33,0.15,0.5,0.23c-0.08,1.05-0.17,2.11-0.25,3.16
	c-0.06,0.07-0.13,0.14-0.19,0.21c-0.9,0.35-1.8,0.7-2.48,0.96C523.55,126.54,523.42,127.37,523.3,128.19z"
              />
              <path
                d="M540.05,192.27c-0.17-0.08-0.33-0.15-0.5-0.23c-0.78-0.85-0.93-1.85-0.71-2.94c0.25-0.3,0.5-0.59,0.76-0.89
	c1.12-0.66,2.23-0.49,3.35,0c0.16,0.07,0.31,0.14,0.47,0.21c0,1.05,0,2.11,0,3.16c-0.16,0.17-0.31,0.33-0.47,0.5
	C541.98,192.14,541.02,192.2,540.05,192.27z"
              />
              <path
                d="M545.14,176.71c0.17,0.3,0.33,0.61,0.5,0.91c0.73,0.91,0.65,1.87,0.21,2.86c-0.18,0.18-0.35,0.36-0.53,0.54
	c-1.05,0.67-2.16,0.46-3.27,0.18c-0.09-0.22-0.18-0.44-0.27-0.66c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52
	C543.05,177,544.09,176.86,545.14,176.71z"
              />
              <path
                d="M538.15,131.11c-0.17-0.18-0.33-0.35-0.5-0.53c-0.62-0.9-0.64-1.86-0.28-2.86c0.1-0.15,0.2-0.3,0.31-0.44
	c0.89-0.29,1.78-0.57,2.59-0.84c0.06-0.93,0.13-1.95,0.2-2.96c0.18-0.16,0.36-0.31,0.55-0.47c0.46-0.06,0.93-0.18,1.39-0.18
	c1.96-0.01,2.56,0.89,2.45,3.57c-0.16,0.13-0.31,0.26-0.47,0.4c-0.86,0.3-1.72,0.61-2.7,0.95c0.09,0.59,0.2,1.33,0.31,2.07
	c0.94-0.04,1.8-0.09,2.66-0.13c0.16,0.31,0.32,0.61,0.49,0.92c0.75,0.9,0.65,1.87,0.22,2.87c-0.17,0.18-0.35,0.35-0.52,0.53
	c-0.92,0.57-1.89,0.58-2.87,0.2c-0.09-0.06-0.18-0.12-0.27-0.18c-0.35-0.92-0.69-1.84-1.02-2.7
	C539.85,131.25,539,131.18,538.15,131.11z"
              />
              <path
                d="M544.85,134c0.17-0.18,0.35-0.35,0.52-0.53c1.04-0.14,2.09-0.28,3.13-0.42c0.16,0.3,0.33,0.61,0.49,0.91
	c0.56,0.76,0.94,1.71,0.28,2.42c-0.74,0.79-1.8,1.3-2.97,2.1c0,0.49,0,1.36,0,2.23c-0.23,0.31-0.45,0.62-0.68,0.93
	c-0.31-0.01-0.62-0.04-0.93-0.04c-2.39,0.03-3.08-0.75-2.91-3.32c0.07-0.15,0.14-0.31,0.21-0.46c0.94-0.43,1.89-0.87,2.86-1.31
	C544.85,135.7,544.85,134.85,544.85,134z"
              />
              <path
                d="M516.1,92.78c-0.43-1.12-0.48-2.25-0.04-3.38c0.12-0.16,0.23-0.32,0.35-0.48c0.83-0.1,1.67-0.21,2.43-0.3
	c0.09-0.96,0.17-1.8,0.25-2.64c0.12-0.16,0.23-0.32,0.35-0.48c1.07-0.88,2.29-0.54,3.48-0.42c0.11,0.06,0.22,0.12,0.33,0.18
	c0.16,1.19,0.68,2.37,0.04,3.57c-0.08,0.17-0.16,0.35-0.23,0.52c-0.86,0.12-1.72,0.24-2.69,0.37c0,0.85,0,1.71,0,2.58
	c-0.2,0.16-0.4,0.33-0.59,0.49c-0.96,0.96-2.1,0.6-3.21,0.43C516.42,93.07,516.26,92.92,516.1,92.78z"
              />
              <path
                d="M491.15,91.77c-0.16-0.15-0.33-0.3-0.49-0.44c-0.42-1.11-0.5-2.23-0.03-3.35c0.12-0.17,0.25-0.34,0.37-0.51
	c1.01,0.01,2.02,0.02,3.03,0.03c0.16,0.14,0.32,0.29,0.48,0.43c0.12,0.97,0.25,1.93,0.37,2.9c-0.14,0.19-0.28,0.37-0.42,0.56
	C493.43,92.19,492.3,92.04,491.15,91.77z"
              />
              <path
                d="M542,177.15c-0.17,0.17-0.34,0.34-0.51,0.52c-1.14,0.77-2.27,0.42-3.39-0.05l0.09,0.08c-0.17-1.13-0.34-2.27-0.51-3.4
	c0.15-0.12,0.29-0.24,0.44-0.36c0.88-0.26,1.75-0.52,2.58-0.77c0.03-0.74,0.07-1.49,0.1-2.24c0.23-0.31,0.47-0.63,0.7-0.94
	c0.88-0.21,1.76-0.43,2.67-0.65c-0.11-0.72-0.22-1.45-0.34-2.25c-0.87,0-1.64,0-2.41,0c-0.16-0.2-0.32-0.41-0.48-0.61
	c-0.36-0.84-0.72-1.67-1.08-2.51c-0.84-0.07-1.76-0.15-2.68-0.22c-0.15-0.13-0.3-0.26-0.44-0.4c-0.42-1.16-0.42-2.32,0.02-3.48
	l-0.08,0.09c1.02-0.3,2.04-0.6,3.12-0.91c-0.12-0.69-0.28-1.54-0.41-2.25c-0.99-0.09-1.83-0.16-2.67-0.24
	c-0.17-0.18-0.34-0.35-0.51-0.53c-0.11-0.96-0.21-1.92-0.32-2.88c0.12-0.15,0.24-0.3,0.36-0.45c1.08-0.88,2.32-0.48,3.52-0.42
	c0.1,0.06,0.2,0.12,0.29,0.18c0.18,0.92,0.35,1.83,0.52,2.69c0.93,0,1.79,0,2.65,0c0.16,0.3,0.32,0.61,0.49,0.91
	c0.25,0.84,0.49,1.68,0.72,2.45c0.81,0,1.72,0,2.63,0c0.16,0.3,0.33,0.61,0.49,0.91c1.08,2.53,0.54,3.39-2.55,4.13
	c0,0.78,0,1.56,0,2.33c0.88-0.1,1.66-0.19,2.44-0.28c-0.18-2.95,0.45-3.64,3.42-3.74c0.08,0,0.16-0.03,0.24-0.04
	c0.18,0.18,0.36,0.35,0.54,0.53c0.26,0.89,0.51,1.78,0.76,2.64c0.87,0.1,1.72,0.19,2.57,0.29c0.15,0.14,0.31,0.27,0.46,0.41
	c0.63,0.91,0.7,1.88,0.34,2.91c-0.12,0.17-0.24,0.33-0.36,0.5c-0.94,0.29-1.88,0.59-2.87,0.89c0.22,3.05-0.55,3.84-3.52,3.45
	c-0.08-0.01-0.16-0.03-0.24-0.05c-0.17-0.13-0.35-0.25-0.52-0.38c-0.21-0.82-0.41-1.63-0.62-2.43c-0.76,0-1.43,0-2.08,0
	c-0.17,1.01-0.32,1.91-0.48,2.81c-0.06,0.08-0.12,0.16-0.18,0.24c-0.86,0.32-1.73,0.64-2.56,0.95
	C542.23,175.35,542.12,176.25,542,177.15z M543.44,162.23c-0.07-1.01-0.12-1.75-0.18-2.49c-0.91,0.11-1.67,0.2-2.44,0.28
	c0.15,0.83,0.28,1.58,0.42,2.41C541.95,162.37,542.62,162.31,543.44,162.23z M551.47,168.84c-0.16-0.78-0.3-1.45-0.46-2.24
	c-0.79,0.05-1.54,0.1-2.32,0.15c0.08,0.85,0.14,1.51,0.19,2.09C549.87,168.84,550.63,168.84,551.47,168.84z"
              />
              <path
                d="M557.8,154.08c0.17,0.18,0.34,0.36,0.51,0.54c0,1.12,0,2.24,0,3.36c-0.1,0.15-0.21,0.3-0.31,0.45
	c-1.01,0.01-2.03,0.02-3.04,0.03c-0.17-0.18-0.34-0.36-0.51-0.54c-0.1-0.95-0.19-1.9-0.29-2.85c0.11-0.15,0.22-0.31,0.32-0.46
	C555.58,154.44,556.69,154.26,557.8,154.08z"
              />
              <path
                d="M560.7,143.59c0.16,0.98,0.31,1.96,0.47,2.93c-0.19,0.16-0.37,0.32-0.56,0.48c-0.99,0.91-2.13,0.61-3.26,0.42
	c-0.15-0.14-0.3-0.27-0.45-0.41c-0.05-1.12-0.09-2.25-0.14-3.37c0.19-0.16,0.38-0.32,0.58-0.49c0.97,0.01,1.94,0.01,2.92,0.02
	C560.4,143.32,560.55,143.45,560.7,143.59z"
              />
              <path
                d="M546.29,140.72c1.08-0.69,2.2-0.39,3.32-0.15c0.15,0.2,0.29,0.4,0.44,0.6c0.03,0.97,0.06,1.94,0.09,2.91
	c-0.23,0.31-0.46,0.62-0.69,0.94c-0.92,0-1.85-0.01-2.77-0.01c-0.15-0.19-0.29-0.38-0.44-0.57c-0.76-0.8-0.94-1.74-0.63-2.78
	C545.84,141.34,546.07,141.03,546.29,140.72z"
              />
              <path
                d="M546.86,112.13c0.89,0.92,1.03,1.94,0.35,3.01c-0.63,1-1.6,1.21-2.7,1.05c-0.31-0.05-0.63-0.06-0.95-0.09
	c0,0-0.1-0.09-0.1-0.09c-0.27-1.02-0.53-2.03-0.8-3.04c-0.77,0.08-1.44,0.15-2.03,0.21c-0.08,0.93-0.16,1.77-0.24,2.62
	c-0.11,0.15-0.21,0.31-0.32,0.46c-1.02,0.9-2.2,0.67-3.38,0.46c-0.14-0.13-0.29-0.25-0.43-0.38c-0.02-1.16-0.03-2.32-0.05-3.48
	c0.96-0.27,1.91-0.54,2.82-0.8c0.08-0.86,0.16-1.71,0.23-2.56c0.11-0.15,0.22-0.31,0.33-0.46c1.08-0.9,2.32-0.5,3.52-0.43
	c0.1,0.06,0.2,0.12,0.29,0.18c0.19,0.95,0.37,1.9,0.55,2.8c0.95-0.04,1.79-0.08,2.62-0.12C546.67,111.68,546.77,111.9,546.86,112.13
	z"
              />
              <path
                d="M539.6,109.02c-0.11,0.15-0.22,0.31-0.33,0.46c-1.01,0.01-2.02,0.02-3.03,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.95-0.2-1.9-0.3-2.85c0.11-0.15,0.22-0.31,0.33-0.46c1.11-0.18,2.22-0.35,3.32-0.53c0.17,0.18,0.34,0.36,0.51,0.54
	C539.96,106.79,540.07,107.91,539.6,109.02z"
              />
              <path
                d="M498.09,131.09c-0.15-0.31-0.31-0.61-0.46-0.92c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.5-0.52
	c1.05-0.14,2.09-0.29,3.14-0.43c0.15,0.31,0.31,0.61,0.46,0.92c0.8,0.89,0.67,1.87,0.24,2.86c-0.17,0.18-0.35,0.35-0.52,0.53
	C500.15,130.79,499.12,130.94,498.09,131.09z"
              />
              <path
                d="M498.84,137.84c-1.56-2.59-0.95-3.82,2.34-4.46c0-0.89,0-1.81,0-2.74c0.17-0.18,0.35-0.35,0.52-0.53
	c0.3-0.08,0.6-0.18,0.9-0.24c1.94-0.4,2.99,0.33,3.29,2.27c0.06,0.38,0.06,0.77,0.09,1.16c-0.09,0.05-0.19,0.11-0.29,0.16
	c-1.01,0.47-2.01,0.94-3.08,1.45c0,0.77,0,1.61,0,2.45c-0.23,0.28-0.47,0.57-0.7,0.85c-0.94-0.09-1.87-0.18-2.81-0.26
	C499.02,137.91,498.93,137.87,498.84,137.84z"
              />
              <path
                d="M540.54,120.07c0.16,0.98,0.31,1.96,0.47,2.95c-0.18,0.16-0.36,0.31-0.55,0.47c-0.86,0.29-1.71,0.57-2.45,0.82
	c-0.11,0.98-0.22,1.98-0.34,2.97c-0.1,0.15-0.2,0.3-0.31,0.44c-1.02,0.01-2.03,0.02-3.05,0.03c-0.17-0.18-0.34-0.36-0.51-0.54
	c-1.01-2.74-0.42-3.63,2.6-4.07c0.07-1.03,0.14-2.03,0.22-3.03c0.18-0.16,0.36-0.31,0.55-0.47c0.97,0.01,1.95,0.01,2.92,0.02
	C540.24,119.81,540.39,119.94,540.54,120.07z"
              />
              <path
                d="M565.56,161.06c0.9,0.91,1.04,1.93,0.37,3.02c-0.63,1-1.6,1.21-2.71,1.05c-0.31-0.05-0.63-0.06-0.95-0.09
	c0,0-0.1-0.09-0.1-0.09c-0.27-1.02-0.53-2.03-0.8-3.05c-0.77,0.08-1.44,0.15-2.13,0.22c0,0.93,0,1.78,0,2.64
	c-0.18,0.15-0.36,0.31-0.54,0.46c-0.99,0.9-2.14,0.6-3.27,0.43c-0.15-0.14-0.31-0.27-0.46-0.41c-0.43-1.16-0.42-2.32,0.02-3.48
	l-0.08,0.09c0.96-0.3,1.91-0.6,2.84-0.89c0.08-0.84,0.17-1.7,0.25-2.55c0.1-0.15,0.21-0.3,0.31-0.45c1.11-0.19,2.21-0.37,3.32-0.56
	c0.18,0.19,0.35,0.38,0.53,0.57c0.17,0.84,0.34,1.68,0.48,2.41c0.93,0,1.79,0,2.65,0C565.38,160.62,565.47,160.84,565.56,161.06z"
              />
              <path
                d="M632.74,476.8c1.42,2.53,0.8,3.68-2.47,4.27c0,0.88,0,1.81,0,2.73c-0.25,0.29-0.49,0.58-0.74,0.87
	c-0.16-0.02-0.31-0.05-0.47-0.04c-2.79,0.17-3.5-0.54-3.34-3.34c0.09-0.14,0.17-0.28,0.26-0.42c0.9-0.41,1.8-0.81,2.89-1.3
	c0-0.73,0-1.67,0-2.61c0.1-0.12,0.21-0.22,0.33-0.32c1.09-0.16,2.18-0.33,3.27-0.49C632.56,476.35,632.65,476.57,632.74,476.8z"
              />
              <path
                d="M629.2,476.62c-0.12,0.1-0.23,0.21-0.33,0.32c-0.9,0.79-1.89,0.71-2.93,0.34c-0.1-0.06-0.21-0.12-0.31-0.18
	c-0.33-0.92-0.66-1.84-0.95-2.67c-0.85-0.08-1.69-0.16-2.53-0.24c-0.18-0.19-0.36-0.37-0.54-0.56c-0.11-0.95-0.22-1.9-0.33-2.85
	c0.13-0.16,0.26-0.33,0.39-0.49c1.1-0.16,2.2-0.33,3.31-0.49c0.18,0.18,0.35,0.37,0.53,0.55c0.17,0.83,0.35,1.66,0.51,2.41
	c0.92,0,1.77,0,2.63,0c0.16,0.31,0.31,0.61,0.47,0.92C629.89,474.64,629.85,475.62,629.2,476.62z"
              />
              <path
                d="M538.84,189.1c-0.23,0-0.47-0.02-0.7-0.01c-2.56,0.11-3.3-0.71-3.07-3.4c0.08-0.13,0.16-0.26,0.22-0.39
	c0.91-0.42,1.81-0.83,2.86-1.31c-0.04-0.52-0.09-1.19-0.14-1.78c-1.01-0.08-1.87-0.15-2.73-0.23c-0.17-0.18-0.34-0.36-0.51-0.54
	c-0.1-0.96-0.2-1.92-0.3-2.89c0.11-0.14,0.23-0.29,0.34-0.43c1.04-0.85,2.23-0.56,3.4-0.43c0,0-0.09-0.08-0.09-0.08
	c0.39,0.99,0.79,1.99,1.17,2.94c0.8,0,1.65,0,2.51,0c0.09,0.22,0.18,0.44,0.27,0.66c1.44,2.52,0.81,3.7-2.46,4.27
	c0,0.88,0,1.81,0,2.73C539.34,188.51,539.09,188.8,538.84,189.1z"
              />
              <path
                d="M546.24,144.43c0.15,0.19,0.29,0.38,0.44,0.57c0.03,0.97,0.05,1.94,0.08,2.92c-0.22,0.31-0.45,0.62-0.67,0.93
	c-0.78,0-1.56,0-2.24,0c-0.15,1.04-0.28,1.94-0.41,2.85c-0.18,0.18-0.35,0.36-0.53,0.54c-0.92,0.58-1.88,0.58-2.87,0.2
	c-0.1-0.06-0.19-0.12-0.29-0.18c-0.84-1-0.73-2.19-0.68-3.37c0.16-0.14,0.33-0.28,0.49-0.42c0.9-0.25,1.79-0.51,2.72-0.77
	c-0.11-0.66-0.21-1.34-0.31-1.96c-1.01-0.08-1.94-0.15-2.87-0.23c-0.15-0.14-0.31-0.27-0.46-0.41c-0.04-1.12-0.08-2.25-0.12-3.37
	c0.19-0.16,0.38-0.33,0.57-0.49c0.98,0.07,2.21-0.2,2.89,0.29c0.73,0.53,0.95,1.77,1.41,2.74c0.24-0.04,0.63-0.18,1.01-0.15
	C545.02,144.17,545.63,144.32,546.24,144.43z"
              />
              <path
                d="M624.07,462.74c-0.05-1.12-0.1-2.25-0.15-3.37c0.2-0.16,0.39-0.32,0.59-0.49c0.08,0,0.16,0,0.23-0.01
	c2.89-0.36,3.43,0.05,3.78,2.86c1.06,0.1,2.1,0.2,3.14,0.3c0.15,0.2,0.31,0.4,0.46,0.6c0.02,0.97,0.04,1.95,0.06,2.92
	c-0.16,0.17-0.31,0.33-0.47,0.5c-0.97,0.15-1.95,0.29-2.92,0.44c-0.16-0.19-0.32-0.37-0.48-0.56c-0.36-0.85-0.73-1.69-1.11-2.57
	c-0.82-0.06-1.75-0.14-2.67-0.21C624.38,463.02,624.22,462.88,624.07,462.74z"
              />
              <path
                d="M510.82,108.09c-0.18-0.19-0.35-0.37-0.53-0.56c-0.25-0.79-0.5-1.58-0.75-2.36c-0.92,0-1.84,0-2.77,0
	c-0.17-0.3-0.34-0.61-0.51-0.91c-0.17-0.83-0.34-1.66-0.5-2.45c-0.95,0-1.88,0-2.81,0c-0.18-0.3-0.37-0.6-0.55-0.91
	c0.01-0.98,0.02-1.95,0.03-2.93c0.06-0.09,0.12-0.18,0.18-0.27c0.87-0.3,1.73-0.59,2.68-0.91c0-0.67,0-1.42,0-2.17
	c0.24-0.31,0.48-0.63,0.71-0.94c1.12-0.76,2.25-0.35,3.38-0.02c0.16,1.13,0.31,2.25,0.47,3.38c-0.16,0.14-0.32,0.29-0.48,0.43
	c-0.89,0.26-1.78,0.52-2.65,0.77c0.05,0.77,0.1,1.54,0.13,2.1c1.02,0.22,2.17,0.17,2.86,0.72c0.7,0.55,0.93,1.68,1.41,2.65
	c0.78,0,1.65,0,2.52,0c0.17,0.18,0.35,0.37,0.52,0.55c0,1.1,0.01,2.2,0.01,3.3c-0.12,0.17-0.25,0.34-0.37,0.5
	C512.81,108.06,511.81,108.07,510.82,108.09z"
              />
              <path
                d="M514.17,104.24c-0.17-0.18-0.35-0.37-0.52-0.55c-0.11-0.95-0.22-1.89-0.33-2.84c0.13-0.17,0.25-0.33,0.38-0.5
	c0.88-0.27,1.75-0.55,2.55-0.79c0.06-0.94,0.13-1.93,0.19-2.93c0.2-0.17,0.39-0.34,0.59-0.5c0.97,0,1.95,0.01,2.92,0.01
	c0.15,0.14,0.31,0.28,0.46,0.42c0.15,0.99,0.3,1.97,0.45,2.96c-0.16,0.14-0.32,0.27-0.48,0.41c-0.85,0.31-1.71,0.62-2.5,0.91
	c-0.11,0.89-0.22,1.89-0.34,2.88c-0.12,0.16-0.24,0.32-0.36,0.49C516.17,104.22,515.17,104.23,514.17,104.24z"
              />
              <path
                d="M541.51,169.98c-0.23,0.31-0.47,0.63-0.7,0.94c-3.22,0-3.55-0.25-4.18-3.12c-0.95-0.08-1.88-0.15-2.81-0.23
	c-0.14-0.13-0.28-0.26-0.43-0.39c-0.05-1.13-0.1-2.26-0.15-3.39c0.19-0.15,0.38-0.31,0.57-0.46c0.97,0.01,1.95,0.01,2.92,0.02
	c0.15,0.13,0.3,0.26,0.44,0.4c0.27,0.88,0.54,1.76,0.75,2.42c1.03,0.11,2.03,0.21,3.02,0.31c0.16,0.2,0.32,0.41,0.48,0.61
	C541.45,168.04,541.48,169.01,541.51,169.98z"
              />
              <path
                d="M538.61,98.48c1.11,2.79,0.5,3.78-2.5,4.17c-0.12,1.03-0.24,2.02-0.36,3.01c-0.11,0.15-0.22,0.31-0.33,0.46
	c-0.46,0.08-0.92,0.2-1.38,0.22c-1.75,0.1-2.37-0.56-2.92-3.03c-1.02-0.11-2.01-0.21-3-0.32c-0.09-0.22-0.18-0.44-0.27-0.66
	c-0.08-1.06-0.17-2.11-0.25-3.17c0.07-0.06,0.13-0.12,0.19-0.19c0.97-0.22,1.93-0.43,2.93-0.65c0-0.76,0-1.51,0-2.26
	c0.23-0.31,0.45-0.62,0.68-0.93c1.13-0.76,2.26-0.34,3.4-0.03c0.23,0.88,0.47,1.76,0.72,2.69c0.84,0.09,1.75,0.19,2.65,0.29
	C538.33,98.22,538.47,98.35,538.61,98.48z M534.7,101.58c-0.21-0.9-0.37-1.56-0.52-2.22c-0.77,0.06-1.39,0.11-2.12,0.17
	c0.15,0.82,0.28,1.53,0.41,2.25C533.27,101.71,533.89,101.66,534.7,101.58z"
              />
              <path
                d="M527.81,98.98c-0.06,0.06-0.13,0.12-0.19,0.19c-0.85,0.34-1.71,0.68-2.54,1.01c-0.1,0.79-0.22,1.69-0.34,2.59
	c-0.17,0.17-0.34,0.35-0.51,0.52c-0.92,0.57-1.89,0.58-2.88,0.2c-0.1-0.06-0.19-0.12-0.29-0.18c-0.82-1.01-0.73-2.19-0.67-3.38
	c0.16-0.14,0.32-0.27,0.48-0.41c0.89-0.25,1.79-0.5,2.71-0.76c-0.11-0.67-0.21-1.35-0.31-1.96c-1.01-0.08-1.93-0.16-2.85-0.24
	c-0.15-0.14-0.31-0.28-0.46-0.42c-0.05-1.12-0.1-2.24-0.16-3.36c0.2-0.16,0.4-0.33,0.59-0.49c0.08,0,0.16,0,0.23-0.01
	c2.88-0.37,3.44,0.06,3.78,2.87c1.06,0.11,2.1,0.21,3.15,0.32c0.15,0.2,0.29,0.4,0.44,0.6C527.92,97.03,527.86,98.01,527.81,98.98z"
              />
              <path
                d="M497.35,127.28c-1.12,0.78-2.24,0.38-3.37,0.01c-0.23-0.89-0.47-1.78-0.71-2.7c-0.83-0.06-1.73-0.13-2.64-0.2
	c-0.17-0.17-0.33-0.35-0.5-0.52c-1.02-2.75-0.42-3.65,2.59-4.08c0.07-1.03,0.14-2.03,0.21-3.03c0.19-0.15,0.37-0.3,0.56-0.46
	c0.46-0.07,0.93-0.19,1.39-0.19c1.95,0,2.56,0.9,2.44,3.59c-0.15,0.12-0.29,0.24-0.44,0.36c-0.86,0.31-1.73,0.62-2.71,0.97
	c0.08,0.57,0.19,1.32,0.3,2.07c0.94-0.05,1.81-0.09,2.67-0.14c0.07,0.15,0.14,0.31,0.21,0.46c0.86,1.01,1.07,2.11,0.49,3.33
	C497.69,126.93,497.52,127.11,497.35,127.28z"
              />
              <path
                d="M538.53,141.73c-0.98,0.92-2.11,0.62-3.23,0.43c-0.16-0.15-0.32-0.29-0.49-0.44c-0.41-1.11-0.5-2.23-0.04-3.35
	c0.12-0.17,0.25-0.34,0.37-0.51c0.84-0.1,1.68-0.2,2.43-0.29c0.1-0.96,0.19-1.8,0.27-2.65c0.1-0.15,0.2-0.3,0.31-0.44
	c1.08-0.93,2.34-0.49,3.55-0.45c0.09,0.06,0.18,0.12,0.27,0.18c0.15,1.21,0.61,2.42,0.02,3.63c-0.07,0.15-0.14,0.31-0.21,0.46
	c-0.89,0.11-1.78,0.21-2.68,0.32c0,0.94,0,1.79,0,2.63C538.91,141.4,538.72,141.57,538.53,141.73z"
              />
              <path
                d="M521.04,103.31c0.1,0.06,0.2,0.12,0.29,0.18c0.18,0.92,0.36,1.84,0.52,2.68c0.93,0,1.79,0,2.65,0
	c0.16,0.31,0.31,0.61,0.47,0.92c0.24,0.85,0.49,1.71,0.74,2.6c0.77-0.05,1.7-0.1,2.63-0.16c0.15,0.31,0.31,0.61,0.46,0.92
	c1.07,2.47,0.5,3.39-2.55,4.11c0,0.77,0,1.54,0,2.1c1.16,0.19,2.22,0.36,3.27,0.53c0.15,0.07,0.3,0.13,0.44,0.2
	c0.05,0.85,0.1,1.7,0.15,2.47c1.06,0.11,1.92,0.2,2.77,0.3c0.14,0.13,0.29,0.25,0.43,0.38c1.17,2.93,0.22,4.24-2.86,3.9
	c-0.16-0.02-0.31-0.04-0.47-0.06c-0.15-0.09-0.3-0.18-0.46-0.27c-0.18-0.84-0.37-1.68-0.54-2.49c-0.85-0.14-1.62-0.26-2.38-0.39
	c-0.17-0.08-0.33-0.15-0.5-0.23c-0.38-0.91-0.77-1.82-1.21-2.88c-0.58,0.04-1.32,0.1-2.1,0.16c0,1.02,0,1.87,0,2.73
	c-0.24,0.31-0.49,0.63-0.73,0.94c-0.89,0-1.79,0.01-2.68,0.01c-0.17-0.19-0.33-0.38-0.5-0.58c-0.36-0.84-0.73-1.68-1.11-2.58
	c-0.64,0-1.4,0-1.96,0c-0.21,1.06-0.14,2.24-0.68,2.95c-0.53,0.69-1.68,0.91-2.48,1.29c-0.12,0.85-0.26,1.76-0.39,2.67
	c-0.15,0.19-0.29,0.37-0.44,0.56c-0.91,0.11-1.83,0.22-2.6,0.32c-0.11,1.03-0.22,2.01-0.33,3c-0.13,0.17-0.26,0.35-0.39,0.52
	c-0.98-0.03-2.19,0.28-2.89-0.18c-0.76-0.5-1.05-1.71-1.59-2.69c-0.73,0-1.65,0-2.58,0c-0.15-0.31-0.31-0.61-0.46-0.92
	c-0.09-0.96-0.19-1.93-0.28-2.89c0.17-0.17,0.34-0.34,0.51-0.52c0.88-0.21,1.75-0.41,2.64-0.62c0-0.77,0-1.51,0-2.25
	c0.23-0.31,0.47-0.62,0.7-0.94c1.15-0.74,2.28-0.4,3.41,0.04c0,0-0.09-0.08-0.09-0.08c0.27,0.97,0.54,1.94,0.83,2.97
	c0.73-0.12,1.55-0.26,2.1-0.36c0.16-1.11,0.3-2.03,0.43-2.95c0.14-0.19,0.29-0.38,0.43-0.57c0.92-0.09,1.85-0.18,2.61-0.25
	c0.12-1.08,0.23-2.08,0.34-3.07c0.12-0.17,0.25-0.34,0.37-0.51c0.91-0.08,1.83-0.17,2.52-0.23c0.18-1.11,0.35-2.15,0.52-3.2
	l-0.07,0.08c0.99-0.34,1.97-0.68,3.04-1.05c-0.09-0.63-0.21-1.44-0.3-2.11c-0.99-0.08-1.83-0.16-2.67-0.23
	c-0.18-0.19-0.36-0.38-0.55-0.57c-0.1-0.94-0.2-1.88-0.31-2.83c0.12-0.16,0.24-0.32,0.36-0.49
	C518.61,102.84,519.85,103.23,521.04,103.31z M524.73,113.28c-0.06-0.92-0.11-1.71-0.16-2.52c-0.9,0.14-1.64,0.25-2.37,0.37
	c0.14,0.82,0.26,1.5,0.4,2.32C523.29,113.39,523.95,113.34,524.73,113.28z M505.18,123.61c0.15,0.78,0.28,1.45,0.42,2.13
	c0.87-0.06,1.62-0.1,2.37-0.15c-0.08-0.91-0.15-1.69-0.22-2.47C506.92,123.28,506.16,123.43,505.18,123.61z M518.92,115.13
	c0.07,0.77,0.14,1.54,0.2,2.25c0.88-0.08,1.66-0.15,2.53-0.23c-0.14-0.77-0.28-1.49-0.4-2.14
	C520.4,115.06,519.71,115.09,518.92,115.13z"
              />
              <path
                d="M514.99,114.35c-0.12,0.17-0.25,0.34-0.37,0.51c-1.02,0.85-2.16,0.68-3.32,0.43c-0.16-0.14-0.32-0.29-0.49-0.43
	c-0.47-1.16-0.44-2.31,0.02-3.46c0,0-0.08,0.09-0.08,0.09c0.94-0.31,1.88-0.62,2.81-0.94c0.08-0.81,0.16-1.65,0.24-2.5
	c0.12-0.17,0.25-0.34,0.37-0.5c1-0.85,2.17-0.55,3.3-0.51c0.18,0.19,0.36,0.38,0.55,0.57c0.4,1.14,0.48,2.27-0.06,3.39
	c0,0,0.07-0.08,0.07-0.08c-0.93,0.35-1.85,0.69-2.84,1.06C515.14,112.66,515.06,113.5,514.99,114.35z"
              />
              <path
                d="M529.55,124.13c0.15,0.09,0.3,0.18,0.46,0.27c0.39,0.89,0.78,1.78,1.24,2.82c0.73,0,1.64,0,2.56,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.38,1.11,0.49,2.22,0,3.34c-0.11,0.16-0.23,0.31-0.34,0.47c-0.46,0.08-0.91,0.21-1.38,0.24
	c-1.75,0.1-2.38-0.57-2.93-3.11c-0.92,0-1.84,0-2.77,0c-0.16-0.3-0.32-0.61-0.49-0.91c-0.08-1.05-0.17-2.1-0.25-3.15
	c0.06-0.07,0.13-0.14,0.19-0.21C527.43,124.32,528.49,124.22,529.55,124.13z"
              />
              <path
                d="M542.92,152.24c0.18-0.18,0.35-0.36,0.53-0.54c0.91-0.21,1.81-0.41,2.65-0.6c0-0.83,0-1.54,0-2.25
	c0.22-0.31,0.45-0.62,0.67-0.93c1.08-0.67,2.19-0.43,3.31-0.14c0.15,0.19,0.31,0.39,0.46,0.58c0.02,0.97,0.04,1.95,0.06,2.92
	c-0.14,0.13-0.28,0.25-0.42,0.38c-0.88,0.26-1.75,0.53-2.7,0.81c0.05,0.36,0.18,0.81,0.15,1.25c-0.03,0.45-0.22,0.89-0.34,1.34
	c-0.18,0.18-0.35,0.36-0.53,0.54c-1.02,0.15-2.03,0.29-3.05,0.44c-0.16-0.3-0.32-0.61-0.49-0.91
	C543.12,154.16,543.02,153.2,542.92,152.24z"
              />
              <path
                d="M546.76,155.6c0.18-0.18,0.35-0.36,0.53-0.54c3.01-0.78,3.72-0.34,4.38,2.87c0.9,0,1.83,0,2.77,0
	c0.17,0.18,0.34,0.36,0.51,0.54c0.41,1.14,0.46,2.28-0.04,3.4c0,0,0.08-0.09,0.08-0.09c-1.04,0.63-2.12,1.04-3.35,0.54
	c-0.18-0.18-0.36-0.35-0.54-0.53c-0.26-0.79-0.51-1.59-0.77-2.39c-0.92,0-1.84,0-2.77,0c-0.16-0.3-0.33-0.61-0.49-0.91
	C546.96,157.52,546.86,156.56,546.76,155.6z"
              />
              <path
                d="M550.53,148.36c-0.15-0.19-0.31-0.39-0.46-0.58c-0.78-0.8-0.93-1.73-0.62-2.77c0.23-0.31,0.46-0.62,0.69-0.94
	c1.12-0.77,2.26-0.34,3.39-0.03c0.24,0.88,0.47,1.75,0.72,2.69c0.83,0.09,1.74,0.19,2.64,0.28c0.15,0.14,0.3,0.27,0.45,0.41
	c1.09,2.81,0.47,3.8-2.51,4.17c-0.12,1.03-0.24,2.02-0.36,3.02c-0.11,0.15-0.22,0.31-0.32,0.46c-0.38,0.06-0.76,0.13-1.15,0.17
	c-1.87,0.18-2.8-0.59-2.9-2.42c-0.02-0.39,0.05-0.78,0.07-1.16c0.14-0.13,0.28-0.25,0.42-0.38c0.87-0.3,1.74-0.6,2.73-0.94
	c-0.11-0.58-0.23-1.24-0.36-1.98C552.09,148.36,551.31,148.36,550.53,148.36z"
              />
              <path
                d="M529.98,117.4c-0.15-0.07-0.3-0.13-0.44-0.2c-0.93-1.07-1.23-2.22-0.27-3.4c0.87-1.07,2.01-1.07,3.21-0.56
	c0.14,0.06,0.3,0.09,0.45,0.14c0,0-0.05-0.06-0.05-0.06c0.26,0.94,0.53,1.88,0.77,2.72c0.95,0.1,1.79,0.19,2.63,0.28
	c0.14,0.13,0.29,0.25,0.43,0.38c0.16,0.98,0.32,1.97,0.48,2.95c-0.18,0.16-0.36,0.31-0.55,0.47c-1,0.91-2.16,0.6-3.29,0.43
	c-0.14-0.13-0.29-0.25-0.43-0.38c-0.19-0.88-0.38-1.77-0.53-2.47C531.48,117.59,530.73,117.5,529.98,117.4z"
              />
              <path
                d="M536.26,112.9c-1.08,0.51-2.17,0.99-3.39,0.43c0,0,0.05,0.06,0.05,0.06c-0.49-0.94-0.98-1.89-1.52-2.93
	c-0.71,0-1.65,0-2.59,0c-0.15-0.31-0.31-0.61-0.46-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.51-0.52
	c3-0.8,3.71-0.35,4.38,2.85c0.9,0,1.84,0,2.77,0c0.17,0.18,0.34,0.36,0.51,0.54C536.24,110.65,536.25,111.77,536.26,112.9z"
              />
              <path
                d="M528.13,103c0.97,0.93,0.91,2,0.44,3.13c-0.17,0.17-0.34,0.35-0.51,0.52c-1.03,0.15-2.05,0.3-3.08,0.45
	c-0.16-0.31-0.31-0.61-0.47-0.92c-0.1-0.96-0.19-1.93-0.29-2.89c0.17-0.17,0.34-0.35,0.51-0.52c1.04-0.14,2.09-0.29,3.13-0.43
	C527.95,102.56,528.04,102.78,528.13,103z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <Link to={"/about"} target="_blank">
          <h3 className="about">About Me</h3>
        </Link>
      </div>
      <div>
        <Link to={"/portfolios"} target="_blank">
          <h3 className="myPortfolio">Portfolio</h3>
        </Link>
      </div>
    </div>
  );
}

export default Home;
